import { useSorting } from "../../../_common/hooks";
import XTable, {
  IXTableColumnHeader,
  SortDirection,
  XTableCell,
} from "../../../_common/components/core/XTable";

import "../../style/components/SharedProfileEvidencePagesTable.scss";
import { FC } from "react";
import EmptyCardWithAction from "../../../_common/components/EmptyCardWithAction";
import { Evidence, EvidenceCategory } from "../../types/evidence";
import { ExternalLink } from "../../../_common/components/ExternalLink";
import CircledIcon from "../../../_common/components/CircledIcon";

export const evidenceCategoryToStr = (cat: EvidenceCategory): string => {
  switch (cat) {
    case EvidenceCategory.Privacy:
      return "Privacy";
    case EvidenceCategory.Security:
      return "Security";
    case EvidenceCategory.ToS:
      return "Terms of service";
    case EvidenceCategory.BugBounty:
      return "Bug bounty";
    case EvidenceCategory.Certifications:
      return "Certifications";
    case EvidenceCategory.Other:
      return "Other";
  }
};

export const evidenceCategoryToExampleUrl = (cat: EvidenceCategory): string => {
  const baseUrl = "https://www.example.com";
  switch (cat) {
    case EvidenceCategory.Privacy:
      return baseUrl + "/privacy";
    case EvidenceCategory.Security:
      return baseUrl + "/security";
    case EvidenceCategory.ToS:
      return baseUrl + "/terms";
    case EvidenceCategory.BugBounty:
      return baseUrl + "/bounty";
    case EvidenceCategory.Certifications:
      return baseUrl + "/certifications";
    case EvidenceCategory.Other:
      return baseUrl + "/other";
  }
};

interface ISharedProfileEvidencePagesTableProps {
  evidencePages: Evidence[];
  isOwnSharedProfile: boolean;
  vendorId: number;
}

const evidencePagesColumnHeaders: IXTableColumnHeader[] = [
  {
    id: "category",
    text: "Page category",
    sortable: true,
    startingSortDir: SortDirection.ASC,
    className: "category",
  },
  {
    id: "link",
    text: "Link",
    sortable: true,
    startingSortDir: SortDirection.DESC,
  },
];

const SharedProfileEvidencePagesTable: FC<
  ISharedProfileEvidencePagesTableProps
> = ({ isOwnSharedProfile, evidencePages, vendorId }) => {
  const [sortedEvidencePages, sortedBy, onSortChange] = useSorting<
    Evidence,
    "category" | "link"
  >(evidencePages, "category", SortDirection.DESC, {
    category: {
      orderFuncs: [(a) => a.category, (a) => a.url],
      sortDirsAsc: ["asc"],
      sortDirsDesc: ["desc"],
    },
    link: {
      orderFuncs: [(a) => a.url, (a) => a.category],
      sortDirsAsc: ["asc"],
      sortDirsDesc: ["desc"],
    },
  });

  const rows = sortedEvidencePages.map((e) => {
    return {
      id: `${e.category}:${e.url}`,
      cells: [
        <XTableCell key="category" className="category-cell">
          {evidenceCategoryToStr(e.category)}
        </XTableCell>,
        <XTableCell key="url" className="url-cell">
          <ExternalLink
            url={e.url}
            trackInfo={{
              eventName: "VerifiedVendor_EvidencePageClicked",
              properties: {
                isMySharedProfile: isOwnSharedProfile,
                url: e.url,
                vendorId: vendorId,
              },
            }}
          />
        </XTableCell>,
      ],
    };
  });

  return (
    <div className="shared-profile-evidence-pages-card">
      {rows.length > 0 && (
        <XTable
          className="shared-profile-evidence-pages-table"
          sortedBy={sortedBy}
          onSortChange={onSortChange}
          columnHeaders={evidencePagesColumnHeaders}
          iconOptions
          rows={rows}
        />
      )}
      {rows.length === 0 && isOwnSharedProfile && (
        <EmptyCardWithAction
          iconJSX={<CircledIcon iconClass="cr-icon-external-link" />}
          emptyText={"You have not added any security and privacy pages"}
          emptySubText={"Get started by adding a page."}
        />
      )}
    </div>
  );
};

export default SharedProfileEvidencePagesTable;
