import IconsSvg from "../images/icons.svg";
import { FC } from "react";

// TODO KW - We should configure the icons.svg to contain all the icons in a single svg with each icon in a symbol e.g.
/*
<svg>
    <symbol id="icon1">...</symbol>
    <symbol id="icon2">...</symbol>
</svg>
*/

interface ISvgIconProps {
  iconName: string;
}

// Display a standard upguard icon from the common set
const SvgIcon: FC<ISvgIconProps> = ({ iconName }) => {
  return (
    <svg className="svg-icon">
      <use xlinkHref={`${IconsSvg}#${iconName}`}></use>
    </svg>
  );
};

export default SvgIcon;
