import ScoresChart from "./ScoresChart";
import userbaseApi from "../api/userbase.api";

import { FC } from "react";

interface OrgScoresChartProps {}

const OrgScoresChart: FC<OrgScoresChartProps> = ({}) => {
  const { data, isLoading } = userbaseApi.useGetUserBaseSummaryV1Query();

  return <ScoresChart data={data} isLoading={isLoading} />;
};

export default OrgScoresChart;
