import {
  PopupPosition,
  SidePopupV2,
} from "../../_common/components/DismissablePopup";
import { LabelColor } from "../../_common/types/label";
import PillLabel, { PillContentAlign } from "./PillLabel";

export const ManagedVendorAssessmentPill = (
  isQnContext = false,
  position: PopupPosition = "top"
) => {
  // old: managed-pill
  return (
    <PillLabel
      className="managed-vendor-assessment-pill"
      color={LabelColor.Bahia}
      align={PillContentAlign.Center}
    >
      Managed{" "}
      <SidePopupV2
        initDismissed
        popupHoverable
        position={position}
        width={225}
        text={
          <>
            {isQnContext
              ? `This questionnaire is being managed by an UpGuard
            security analyst as part of a managed risk assessment request.`
              : `This risk assessment is being managed by an UpGuard security
            analyst.`}
            <br />
            <a
              onClick={() =>
                window.open(
                  "https://help.upguard.com/en/articles/4610246-what-is-a-managed-vendor",
                  "_blank"
                )
              }
            >
              Learn more -&gt;
            </a>
          </>
        }
      >
        <i className={"cr-icon-info"} />
      </SidePopupV2>
    </PillLabel>
  );
};
