import {
  RemediationRequestStatus,
  RemediationRequestWithMeta,
} from "../../types/remediation";
import PillLabel from "../../../vendorrisk/components/PillLabel";
import { LabelColor } from "../../types/label";
import React, { useMemo } from "react";
import { RiskStatus } from "../../types/risks";

export const remediationStatusToPill = (
  status: RemediationRequestStatus,
  archived: boolean
): React.ReactNode => {
  if (archived) {
    return (
      <PillLabel className={"status-pill"} color={LabelColor.Grey}>
        Archived
      </PillLabel>
    );
  }

  switch (status) {
    case RemediationRequestStatus.Draft:
      return (
        <PillLabel className={"status-pill"} color={LabelColor.Grey}>
          Draft
        </PillLabel>
      );
    case RemediationRequestStatus.Open:
      return (
        <PillLabel className={"status-pill"} color={LabelColor.Blue}>
          In progress
        </PillLabel>
      );
    case RemediationRequestStatus.AwaitingReview:
      return (
        <PillLabel className={"status-pill"} color={LabelColor.Orange}>
          Awaiting Review
        </PillLabel>
      );
    case RemediationRequestStatus.Closed:
      return (
        <PillLabel className={"status-pill"} color={LabelColor.Green}>
          Completed
        </PillLabel>
      );
    default:
      console.warn(`Unknown remediation status: ${status}`);
      return (
        <PillLabel className={"status=pill"} color={LabelColor.Red}>
          UNKNOWN
        </PillLabel>
      );
  }
};

// useRemediationProgress returns a memo of the progress of a remediation request
// with 3 fields, progress %, total risks and risks fully resolved
export const useRemediationProgress = (
  details?: RemediationRequestWithMeta
) => {
  const [progress, total, resolved] = useMemo<[number, number, number]>(() => {
    if (details?.risks) {
      const total = details.risks.length;
      const resolved = details.risks.reduce((prev, risk) => {
        return (
          prev +
          (risk.riskStatus == RiskStatus.Waived ||
          risk.riskStatus == RiskStatus.Closed
            ? 1
            : 0)
        );
      }, 0);

      return [
        total > 0 ? Math.floor((resolved / total) * 100) : 100,
        total,
        resolved,
      ];
    }

    return [0, 0, 0];
  }, [details]);
  return [progress, total, resolved];
};
