import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import IconButton, { HoverLocation } from "./IconButton";
import {
  $getRoot,
  $getSelection,
  $isRangeSelection,
  $setSelection,
  CLEAR_EDITOR_COMMAND,
  COMMAND_PRIORITY_CRITICAL,
  FORMAT_TEXT_COMMAND,
  KEY_DOWN_COMMAND,
  RangeSelection,
  SELECTION_CHANGE_COMMAND,
} from "lexical";
import {
  $isListNode,
  INSERT_ORDERED_LIST_COMMAND,
  INSERT_UNORDERED_LIST_COMMAND,
  ListNode,
  REMOVE_LIST_COMMAND,
} from "@lexical/list";
import { memo, useCallback, useEffect, useState } from "react";
import { $getNearestNodeOfType, mergeRegister } from "@lexical/utils";
import { ListNodeTagType } from "@lexical/list/LexicalListNode";
import { $isLinkNode, TOGGLE_LINK_COMMAND } from "@lexical/link";
import { $isAtNodeEnd } from "@lexical/selection";
import { Surface, SurfacePosition } from "./Surface";
import TextField, { TextFieldData } from "./TextField";
import Button from "./core/Button";
import { trim } from "lodash";

type supportedBlockTypes = "ul" | "ol";
type supportedStyles = "bold" | "italic";

interface RichTextEditV2ToolbarProps {
  disallowLinks?: boolean;
  disabled?: boolean;
}

// RichTextEditV2Toolbar is a static toolbar for manipulating the content within a LexicalComposer context
const RichTextEditV2Toolbar: React.FC<RichTextEditV2ToolbarProps> = ({
  disallowLinks,
  disabled,
}) => {
  const [editor] = useLexicalComposerContext();

  // Maintain editor information
  const [hasContent, setHasContent] = useState(true);

  // Maintain some base selection information
  const [isSelection, setIsSelection] = useState(false);
  const [isTextSelection, setIsTextSelection] = useState(false);

  // Maintain some specific selection information
  const [isLinkSelection, setIsLinkSelection] = useState(false);
  const [listSelectionType, setListSelectionType] = useState<
    ListNodeTagType | undefined
  >(undefined);
  const [isBold, setIsBold] = useState(false);
  const [isItalic, setIsItalic] = useState(false);

  const [linkSurfaceActive, setLinkSurfaceActive] = useState(false);
  const [linkValue, setLinkValue] = useState<TextFieldData>({
    value: "",
    isValid: false,
  });

  // Reset the link value when link popup closes
  useEffect(() => {
    if (!linkSurfaceActive) {
      setLinkValue({ value: "", isValid: false });
    }
  }, [linkSurfaceActive]);

  // Callback for when the Lexical context detects a selection change
  const handleSelectionChange = useCallback(() => {
    const selection = $getSelection();
    if ($isRangeSelection(selection)) {
      setIsSelection(true);
      setIsTextSelection(selection.getTextContent().length > 0);

      setIsLinkSelection(getIsLinkSelection(selection) !== undefined);
      setListSelectionType(getExistingListTypeForSelection(selection));
      setIsBold(selection.hasFormat("bold"));
      setIsItalic(selection.hasFormat("italic"));
    } else {
      setIsSelection(false);
      setIsTextSelection(false);
      setListSelectionType(undefined);
      setIsBold(false);
      setIsItalic(false);
    }

    // Treat single node with no text as no content for clearing purposes
    const root = $getRoot();
    setHasContent(
      !(
        root.getChildren().length === 0 ||
        (root.getChildren().length === 1 &&
          trim(root.getChildren()[0].getTextContent()) === "")
      )
    );
  }, [editor]);

  // Register callbacks for the Lexical context
  useEffect(() => {
    return mergeRegister(
      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        () => {
          handleSelectionChange();
          return false;
        },
        COMMAND_PRIORITY_CRITICAL
      ),
      editor.registerCommand(
        KEY_DOWN_COMMAND,
        () => {
          handleSelectionChange();
          return false;
        },
        COMMAND_PRIORITY_CRITICAL
      ),
      editor.registerCommand(
        CLEAR_EDITOR_COMMAND,
        () => {
          setHasContent(false);
          return false;
        },
        COMMAND_PRIORITY_CRITICAL
      )
    );
  }, [editor, handleSelectionChange]);

  const formatSelectedBlock = useCallback(
    (requestedBlockType: supportedBlockTypes) => {
      switch (requestedBlockType) {
        case "ul":
          if (listSelectionType) {
            editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
            setListSelectionType(undefined);
          }

          if (listSelectionType !== "ul") {
            editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, undefined);
            setListSelectionType("ul");
          }
          break;
        case "ol":
          if (listSelectionType) {
            editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
            setListSelectionType(undefined);
          }

          if (listSelectionType !== "ol") {
            editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND, undefined);
            setListSelectionType("ol");
          }
          break;
      }
    },
    [editor, listSelectionType]
  );

  const styleSelectedText = useCallback(
    (requestedStyleType: supportedStyles) => {
      switch (requestedStyleType) {
        case "bold":
          editor.dispatchCommand(FORMAT_TEXT_COMMAND, "bold");
          setIsBold(!isBold);
          break;
        case "italic":
          editor.dispatchCommand(FORMAT_TEXT_COMMAND, "italic");
          setIsItalic(!isItalic);
          break;
      }
    },
    [editor, isBold, isItalic]
  );

  const addLinkToSelection = useCallback(() => {
    // Check if we should add protocol
    const addPrefix =
      !linkValue.value.startsWith("https://") &&
      !linkValue.value.startsWith("http://");

    editor.dispatchCommand(TOGGLE_LINK_COMMAND, {
      url: `${addPrefix ? "https://" : ""}${linkValue.value}`,
      rel: "nofollow noreferrer",
      target: "_blank",
    });

    setLinkSurfaceActive(false);
    setLinkValue({ value: "", isValid: false });
  }, [editor, linkValue]);

  const removeLinkFromSelection = useCallback(() => {
    editor.dispatchCommand(TOGGLE_LINK_COMMAND, null);
    setIsLinkSelection(false);
    setIsTextSelection(false);
  }, [editor]);

  const clearEditorContent = useCallback(() => {
    editor.dispatchCommand(CLEAR_EDITOR_COMMAND, undefined);
  }, [editor]);

  return (
    <div className={"rick-text-edit-v2-toolbar"}>
      <IconButton
        hoverLocation={HoverLocation.Top}
        disabled={disabled || !isSelection}
        icon={<div className="cr-icon-bold" />}
        hoverText={"Bold"}
        onClick={() => styleSelectedText("bold")}
        active={isBold}
        hoverMicro
      />
      <IconButton
        hoverLocation={HoverLocation.Top}
        disabled={disabled || !isSelection}
        icon={<div className="cr-icon-italic" />}
        hoverText={"Italic"}
        onClick={() => styleSelectedText("italic")}
        active={isItalic}
        hoverMicro
      />
      <IconButton
        hoverLocation={HoverLocation.Top}
        disabled={disabled || !isSelection}
        icon={<div className="cr-icon-list-bullet" />}
        hoverText={"Bullet list"}
        onClick={() => formatSelectedBlock("ul")}
        active={listSelectionType === "ul"}
        hoverMicro
      />
      <IconButton
        hoverLocation={HoverLocation.Top}
        disabled={disabled || !isSelection}
        icon={<div className="cr-icon-list-number" />}
        hoverText={"Numbered list"}
        onClick={() => formatSelectedBlock("ol")}
        active={listSelectionType === "ol"}
        hoverMicro
      />
      {!disallowLinks && (
        <>
          <span className={"vertical-divider"} />
          <div style={{ position: "relative" }}>
            <IconButton
              hoverLocation={HoverLocation.Top}
              disabled={
                isLinkSelection
                  ? false
                  : disabled || !isSelection || !isTextSelection
              }
              icon={<div className={"cr-icon-link"} />}
              hoverText={isLinkSelection ? "Edit link" : "Add link"}
              active={isLinkSelection}
              hoverMicro
              onClick={() => {
                editor.update(() => {
                  const selection = $getSelection();

                  // Check if this is an existing link and if so pre-fill the link editor
                  if (isLinkSelection) {
                    if ($isRangeSelection(selection)) {
                      const linkNode = getIsLinkSelection(
                        selection as RangeSelection
                      );
                      if (linkNode) {
                        setLinkValue({
                          value: linkNode.getURL(),
                          isValid: true,
                        });
                      }
                    }
                  }

                  setLinkSurfaceActive(true);

                  $setSelection(selection);
                });
              }}
            />
            <Surface
              active={linkSurfaceActive}
              classNames={"link-surface"}
              onClickOutside={() => setLinkSurfaceActive(false)}
              position={SurfacePosition.Bottom}
            >
              <form
                onSubmit={
                  linkValue.isValid
                    ? (ev) => {
                        ev.preventDefault();
                        addLinkToSelection();
                        return false;
                      }
                    : (ev) => {
                        ev.preventDefault();
                        return false;
                      }
                }
              >
                <TextField
                  required
                  type={"url-without-protocol"}
                  placeholder={"https://example.com"}
                  value={linkValue.value}
                  onChanged={(value, isValid) =>
                    setLinkValue({ value, isValid })
                  }
                />
                <div className={"link-surface-actions"}>
                  <Button tertiary onClick={() => setLinkSurfaceActive(false)}>
                    Cancel
                  </Button>
                  <Button
                    type={"submit"}
                    disabled={!linkValue.isValid}
                    onClick={(ev) => {
                      ev.preventDefault();
                      addLinkToSelection();
                      return false;
                    }}
                  >
                    Save
                  </Button>
                </div>
              </form>
            </Surface>
          </div>
          <IconButton
            hoverLocation={HoverLocation.Top}
            disabled={disabled || !isLinkSelection}
            icon={<div className="cr-icon-link-broken" />}
            hoverText={"Remove link"}
            hoverMicro
            onClick={removeLinkFromSelection}
          />
        </>
      )}
      <span className={"vertical-divider"} />
      <IconButton
        hoverLocation={HoverLocation.Top}
        disabled={disabled || !hasContent}
        icon={<div className="cr-icon-trash-2" />}
        hoverText={"Clear"}
        onClick={() => clearEditorContent()}
        hoverMicro
      />
    </div>
  );
};

const getExistingListTypeForSelection = (rangeSelection: RangeSelection) => {
  let existingListType: ListNodeTagType | undefined = undefined;

  const anchorNode = rangeSelection.anchor.getNode();
  const element =
    anchorNode.getKey() === "root"
      ? anchorNode
      : anchorNode.getTopLevelElementOrThrow();

  if ($isListNode(element)) {
    const parentList = $getNearestNodeOfType(anchorNode, ListNode);
    existingListType = parentList ? parentList.getTag() : element.getTag();
  }

  return existingListType;
};

const getSelectedNode = (rangeSelection: RangeSelection) => {
  const anchor = rangeSelection.anchor;
  const focus = rangeSelection.focus;
  const anchorNode = rangeSelection.anchor.getNode();
  const focusNode = rangeSelection.focus.getNode();
  if (anchorNode === focusNode) {
    return anchorNode;
  }
  const isBackward = rangeSelection.isBackward();
  if (isBackward) {
    return $isAtNodeEnd(focus) ? anchorNode : focusNode;
  } else {
    return $isAtNodeEnd(anchor) ? focusNode : anchorNode;
  }
};

const getIsLinkSelection = (rangeSelection: RangeSelection) => {
  const node = getSelectedNode(rangeSelection);
  const parent = node.getParent();

  if ($isLinkNode(parent)) {
    return parent;
  } else if ($isLinkNode(node)) {
    return node;
  }

  return undefined;
};

export default memo(RichTextEditV2Toolbar);
