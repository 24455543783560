import { FC } from "react";
import ReportCard from "../../_common/components/ReportCard";
import Button from "../../_common/components/core/Button";
import { useDefaultHistory } from "../../_common/types/router";
import { dashboardUrl, permissionsUrl } from "../UserBaseAppRouter";
import OrgScopeCategories from "./OrgScopeCategories";
import userbaseApi from "../api/userbase.api";

import "./OrgScopeCategoriesOverview.scss";
import LoadingBanner from "../../_common/components/core/LoadingBanner";
import { useLocaleCompare } from "../../_common/hooks";

const OrgScopeCategoriesOverview: FC = ({}) => {
  const history = useDefaultHistory();

  const onViewAll = () => {
    history.push(permissionsUrl, {
      backContext: {
        backTo: dashboardUrl,
        backToText: `Back to Dashboard`,
      },
    });
  };

  // Get the org permissions
  const { data, isLoading } =
    userbaseApi.useGetUserBaseScopeCategoriesV1Query();
  const scopesToUse = data?.categories ?? [];

  const { caseInsensitiveCompare } = useLocaleCompare();

  const sortedCategories = scopesToUse.slice().sort(caseInsensitiveCompare);

  const permissionHeader = `Permission categories (${sortedCategories.length})`;

  return (
    <ReportCard newStyles className="userbase-org-scope-categories-overview">
      <div className="header">
        <span>{permissionHeader}</span>
        <div>
          <Button tertiary onClick={onViewAll}>
            View all <div className="cr-icon-arrow-right" />
          </Button>
        </div>
      </div>
      <div className={"content"}>
        {isLoading && <LoadingBanner />}
        {!isLoading && <OrgScopeCategories categories={sortedCategories} />}
      </div>
    </ReportCard>
  );
};

export default OrgScopeCategoriesOverview;
