import { FC, useCallback } from "react";
import { DiffSide } from "./nodes/baseNode";
import { SurveyViewerDocumentStyles } from "./SurveyViewerDocument";
import { GetIconForFilename } from "../../vendorrisk/helpers/icons";
import IconButton, {
  HoverColor,
  HoverLocation,
} from "../../_common/components/IconButton";
import { useAppDispatch } from "../../_common/types/reduxHooks";
import { downloadContentLibraryDocumentFile } from "../../contentlibrary/api/downloadDocument";

interface ISurveyViewerContentLibraryDocumentProps {
  uuid: string;
  documentName: string;
  documentVersion: number;
  filename: string;
  description: string;
  disabled: boolean;
  onDelete: () => void;
  surveyId?: number;
  publicSurvey?: boolean;
  diffSide?: DiffSide;
}

const SurveyViewerContentLibraryDocument: FC<
  ISurveyViewerContentLibraryDocumentProps
> = ({
  uuid,
  documentName,
  documentVersion,
  filename,
  description,
  diffSide,
  disabled,
  onDelete,
  surveyId,
  publicSurvey,
}) => {
  const dispatch = useAppDispatch();
  const downloadDocument = useCallback(() => {
    if (!surveyId) {
      return;
    }

    dispatch(
      downloadContentLibraryDocumentFile(
        uuid,
        documentVersion,
        surveyId,
        publicSurvey
      )
    );
  }, [surveyId, uuid, dispatch, publicSurvey, documentVersion]);

  const openInContentLibrary = useCallback(() => {
    window.open(`/contentlibrary/document/${uuid}`);
  }, [uuid]);

  return (
    <SurveyViewerDocumentStyles diffSide={diffSide}>
      <div className={"type-icon"}>
        <img src={GetIconForFilename(filename)} />
      </div>
      <div className="filename-and-description">
        <div
          className="filename downloadable"
          onClick={disabled ? downloadDocument : openInContentLibrary}
        >
          {documentName}
        </div>
        {description && <div className="description">{description}</div>}
      </div>
      <div className="actions">
        {disabled ? (
          <>
            <IconButton
              icon={<div className={"cr-icon-export"} />}
              onClick={downloadDocument}
              hoverText="Download"
              popupDelay={0}
              hoverLocation={HoverLocation.Top}
            />
          </>
        ) : (
          <>
            <IconButton
              icon={<div className="cr-icon-external-link" />}
              onClick={openInContentLibrary}
              hoverText="View in Content Library"
              popupDelay={0}
              hoverLocation={HoverLocation.Top}
            />
            <IconButton
              icon={<div className="cr-icon-trash" />}
              onClick={onDelete}
              hoverText="Remove"
              popupDelay={0}
              hoverColor={HoverColor.Red}
              hoverLocation={HoverLocation.Top}
            />
          </>
        )}
      </div>
    </SurveyViewerDocumentStyles>
  );
};

export default SurveyViewerContentLibraryDocument;
