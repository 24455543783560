import { Component } from "react";
import PropTypes from "prop-types";
import Button from "../../../_common/components/core/Button";

import LoadingBanner from "../../../_common/components/core/LoadingBanner";

import {
  fetchSystemLabelsForOrg,
  updateSystemLabelsForOrg,
} from "../../reducers/adminActions";
import { closeModal } from "../../../_common/reducers/commonActions";
import ColorCheckbox from "../../../vendorrisk/components/ColorCheckbox";

import "../../styles/EditOrganisationSystemLabelsModal.scss";
import {
  addDefaultSuccessAlert,
  addDefaultUnknownErrorAlert,
} from "../../../_common/reducers/messageAlerts.actions";

export const EditOrganisationSystemLabelsModalName =
  "EditOrganisationSystemLabelsModal";

class EditOrganisationSystemLabelsModal extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    modalData: PropTypes.object.isRequired,
  };

  state = {
    labels: null,
    addedLabelIds: [],
    removedLabelIds: [],
    updateLoading: false,
  };

  UNSAFE_componentWillMount() {
    this.props
      .dispatch(fetchSystemLabelsForOrg(this.props.modalData.orgId))
      .then((labels) => this.setState({ labels }))
      .catch((e) => {
        this.props.dispatch(
          addDefaultUnknownErrorAlert(
            "Error fetching labels for org. Please try again later."
          )
        );
        this.props.dispatch(closeModal());
      });
  }

  onToggleLabelId = (labelId) =>
    this.setState(({ labels, addedLabelIds, removedLabelIds }) => {
      const addedLabelIdsIdx = addedLabelIds.indexOf(labelId);
      if (addedLabelIdsIdx > -1) {
        const newAddedLabelIds = [...addedLabelIds];
        newAddedLabelIds.splice(addedLabelIdsIdx, 1);
        return { addedLabelIds: newAddedLabelIds };
      }

      const removedLabelIdsIdx = removedLabelIds.indexOf(labelId);
      if (removedLabelIdsIdx > -1) {
        const newRemovedLabelIds = [...removedLabelIds];
        newRemovedLabelIds.splice(removedLabelIdsIdx, 1);
        return { removedLabelIds: newRemovedLabelIds };
      }

      // Find the label in the default set and determine if we need to add or remove
      for (let i = 0; i < labels.length; i++) {
        if (labels[i].id === labelId) {
          if (labels[i].enabled) {
            return { removedLabelIds: [...removedLabelIds, labelId] };
          } else {
            return { addedLabelIds: [...addedLabelIds, labelId] };
          }
        }
      }
    });

  onSubmit = () => {
    this.setState({ updateLoading: true });
    this.props
      .dispatch(
        updateSystemLabelsForOrg(
          this.props.modalData.orgId,
          this.state.addedLabelIds,
          this.state.removedLabelIds
        )
      )
      .then(() => {
        this.props.dispatch(
          addDefaultSuccessAlert(
            `Successfully edited system labels for ${this.props.modalData.orgName}`
          )
        );
        this.props.dispatch(closeModal());
      })
      .catch(() => {
        this.props.dispatch(
          addDefaultUnknownErrorAlert("Error editing system labels")
        );
        this.setState({ updateLoading: false });
      });
  };

  isChecked = (label) => {
    if (this.state.addedLabelIds.indexOf(label.id) > -1) {
      return true;
    }
    if (this.state.removedLabelIds.indexOf(label.id) > -1) {
      return false;
    }

    return label.enabled;
  };

  render() {
    return (
      <div className="modal-content" id="edit_organisation_system_labels_modal">
        <h2>
          Edit system labels for
          <br />
          {this.props.modalData.orgName}
        </h2>
        <p>
          Warning: Disabling an existing system label for this org will remove
          that label from any vendors.
        </p>
        {!this.state.labels ? (
          <LoadingBanner tight />
        ) : (
          this.state.labels.map((l) => (
            <ColorCheckbox
              key={l.id}
              color={l.colour}
              checked={this.isChecked(l)}
              label={l.name}
              disabled={this.state.updateLoading || l.name === "In-Use"} // Restrict In-Use label from being toggled as this would break functionality for users.
              onClick={() => this.onToggleLabelId(l.id)}
            />
          ))
        )}
        <Button
          primary
          loading={this.state.updateLoading}
          onClick={this.onSubmit}
        >
          Save
        </Button>
      </div>
    );
  }
}

export default EditOrganisationSystemLabelsModal;
