import { DefaultThunkDispatch } from "../../_common/types/redux";
import { FetchCyberRiskUrl, ResponseError } from "../../_common/api";
import { LogError } from "../../_common/helpers";
import {
  addDefaultUnknownErrorAlert,
  addSimpleErrorAlert,
} from "../../_common/reducers/messageAlerts.actions";
import { DefaultRootState } from "react-redux";
import { refreshVendorListsAfterChange } from "./vendors.actions";
import { escapeRegExp } from "../../_common/helpers/string.helpers";

export const MultiSetAttributeSeparator = "|:|";

export const attributeValueToUserValue = (v: string) =>
  v.replace(new RegExp(escapeRegExp(MultiSetAttributeSeparator), "g"), ", ");

export enum VendorAttributeDefinitionType {
  Date = "date",
  Text = "text",
  Numeric = "numeric",
  Set = "set",
  MultiSet = "multiset",
}

export interface VendorAttributeDefinition {
  id: number;
  name: string;
  type: VendorAttributeDefinitionType;
  metadata: {
    allowedValues?: string[];
  };
  usageCount: number;
  position: number;
}

// for attributes we need to use a special convention of prepending "attr$$" to
// the name of the column so that the backend knows to look for attributes when
// sorting that column
export const VendorAttributeSortByPrefix = "attr$$";

// sort two definitions by their position first and then by their ID
export const compareDefinitions = (
  a: VendorAttributeDefinition,
  b: VendorAttributeDefinition
): number => {
  if (a.position !== b.position) {
    return a.position < b.position ? -1 : 1;
  }
  return a.id < b.id ? -1 : 1;
};

export interface VendorAttribute {
  id: number;
  name: string;
  attributeDefinitionId: number;
  value?: string;
}

// sort two attributes by their definition ID
export const compareAttributes = (
  a: VendorAttribute,
  b: VendorAttribute
): number => (a.attributeDefinitionId < b.attributeDefinitionId ? -1 : 1);

export const SET_VENDOR_ATTRIBUTE_DEFINITIONS =
  "SET_VENDOR_ATTRIBUTE_DEFINITIONS";
export const setVendorAttributeDefinitions = ({
  active: attributeDefinitions,
  hasDeleted,
}: VendorAttributeDefinitionsResponse) => {
  return {
    type: SET_VENDOR_ATTRIBUTE_DEFINITIONS,
    attributeDefinitions,
    hasDeleted,
  };
};

interface VendorAttributeDefinitionsResponse {
  active?: VendorAttributeDefinition[];
  hasDeleted: boolean;
}

// Store a reference to an ongoing promise that we can return to consumers
let prom: Promise<VendorAttributeDefinitionsResponse> | undefined = undefined;
let promOrgId: number | undefined = undefined;

export const fetchVendorAttributeDefinitions = (reset = false) => {
  return async (
    dispatch: DefaultThunkDispatch,
    getState: () => DefaultRootState
  ) => {
    if (reset || getState().common?.userData?.currentOrgID !== promOrgId) {
      promOrgId = getState().common?.userData?.currentOrgID;
      prom = undefined;
    }
    if (prom) {
      return prom;
    }

    let json: VendorAttributeDefinitionsResponse;

    try {
      prom = FetchCyberRiskUrl<VendorAttributeDefinitionsResponse>(
        "vendor_attribute_definitions/v1/",
        {},
        { method: "GET" },
        dispatch,
        getState
      );

      json = await prom;
    } catch (e) {
      LogError("error fetching vendor attribute definitions", e);
      dispatch(
        addDefaultUnknownErrorAlert(
          "Error retrieving vendor attribute definitions"
        )
      );

      throw e;
    }

    // Set redux state
    dispatch(setVendorAttributeDefinitions(json));

    return json;
  };
};

interface UpdateVendorAttributesDefinitionsRequestV1 {
  attributeDefinitions: VendorAttributeDefinition[];
}

export const updateVendorAttributeDefinitions = (
  update: UpdateVendorAttributesDefinitionsRequestV1
) => {
  return async (
    dispatch: DefaultThunkDispatch,
    getState: () => DefaultRootState
  ) => {
    let json: VendorAttributeDefinitionsResponse;
    try {
      json = await FetchCyberRiskUrl<VendorAttributeDefinitionsResponse>(
        "vendor_attribute_definitions/v1/",
        {},
        { method: "PUT", body: JSON.stringify(update) },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("error updating vendor attribute definitions", e);
      if (
        e instanceof ResponseError &&
        e.response.status === 422 &&
        e.json.error
      ) {
        dispatch(addSimpleErrorAlert("Error - " + e.json.error));
      } else {
        dispatch(
          addDefaultUnknownErrorAlert(
            "Error updating vendor attribute definitions"
          )
        );
      }

      throw e;
    }

    dispatch(setVendorAttributeDefinitions(json));
  };
};

interface UpdateVendorAttributesRequestV1 {
  attributes: VendorAttribute[];
  vendorId: number;
}

export const updateVendorAttributes = (
  update: UpdateVendorAttributesRequestV1,
  refreshVendorLists = true
) => {
  return async (
    dispatch: DefaultThunkDispatch,
    getState: () => DefaultRootState
  ) => {
    try {
      await FetchCyberRiskUrl(
        "vendor_attributes/v1/",
        {},
        { method: "PUT", body: JSON.stringify(update) },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("error updating vendor attributes", e);

      throw e;
    }

    if (refreshVendorLists) {
      await dispatch(refreshVendorListsAfterChange([update.vendorId]));
    }
  };
};

export const searchAvailableAttributeValues = (
  attributeDefinitionId: number,
  searchTerm: string
) => {
  return async (
    dispatch: DefaultThunkDispatch,
    getState: any
  ): Promise<string[]> => {
    let resp: string[];
    try {
      resp = await FetchCyberRiskUrl<string[]>(
        "vendor_attributes/available_values/v1/",
        {
          attribute_definition_id: attributeDefinitionId,
          prefix: searchTerm,
        },
        null,
        dispatch,
        getState,
        undefined,
        undefined
      );
    } catch (e) {
      console.error(e);
      throw e;
    }

    return resp;
  };
};

interface BulkUpdateVendorAttributesV1Request {
  attributes: VendorAttribute[];
  updateNote: boolean;
  note: string;
  vendorIds: number[];
}

export const bulkUpdateVendorAttributes = (
  update: BulkUpdateVendorAttributesV1Request,
  refreshVendorLists = true
) => {
  return async (
    dispatch: DefaultThunkDispatch,
    getState: () => DefaultRootState
  ) => {
    try {
      await FetchCyberRiskUrl(
        "vendor_attributes/bulk/v1/",
        {},
        { method: "PUT", body: JSON.stringify(update) },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("error bulk updating vendor attributes", e);

      throw e;
    }

    if (refreshVendorLists) {
      await dispatch(refreshVendorListsAfterChange(update.vendorIds));
    }
  };
};
