import { IdentityBreach } from "../../api/types";
import { FC } from "react";
import { LabelColor } from "../../../_common/types/label";
import { getSeverityIconForBreach } from "../../../vendorrisk/components/email_exposures/EmailExposures";
import PillLabel from "../../../vendorrisk/components/PillLabel";

interface IdentityBreachSeverityIconProps {
  breach: IdentityBreach;
}

const IdentityBreachSeverityIcon: FC<IdentityBreachSeverityIconProps> = ({
  breach,
}) => {
  if (breach.archived) {
    return (
      <PillLabel className="archived-pill-label" color={LabelColor.Grey}>
        Archived
      </PillLabel>
    );
  }
  return getSeverityIconForBreach(breach.riskScore, breach.isDarkWebBreach);
};

export default IdentityBreachSeverityIcon;
