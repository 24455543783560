import "../style/components/VerticalProgress.scss";
import { ReactNode } from "react";

export interface IVerticalProgressStep {
  id: string | number;
  name: string;
  stepDescription?: ReactNode;
  rightContent?: ReactNode;
  completed?: boolean;
  current?: boolean;
}

export interface IVerticalProgressProps {
  steps: IVerticalProgressStep[];
  showRightContent?: boolean;
}

const VerticalProgress = ({
  steps,
  showRightContent,
}: IVerticalProgressProps) => {
  return (
    <div
      className={`vertical-progress ${
        showRightContent ? "show-right-content" : ""
      }`}
    >
      {steps.map((s) => (
        <div
          className={`progress-item ${s.completed ? "filled-line" : ""}`}
          key={s.id}
        >
          <div
            className={`progress-circle ${
              s.completed || s.current ? "highlight" : ""
            }`}
          >
            {s.current ? (
              <div className="progress-circle-circle" />
            ) : s.completed ? (
              <div className="cr-icon-check" />
            ) : null}
          </div>
          <div className="step-name">
            {s.name}
            {s.stepDescription && (
              <div className="step-description">{s.stepDescription}</div>
            )}
          </div>
          {showRightContent && s.rightContent && (
            <div className="right-content">{s.rightContent}</div>
          )}
        </div>
      ))}
    </div>
  );
};

export default VerticalProgress;
