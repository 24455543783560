export enum EvidenceCategory {
  Security = "security",
  Privacy = "privacy",
  ToS = "tos",
  Certifications = "certifications",
  BugBounty = "bug_bounty",
  Other = "other",
}

export enum EvidenceSource {
  UpGuard = "upguard",
  VerifiedVendor = "verified_vendor",
  VendorRisk = "vendor_risk",
}

export interface Evidence {
  category: EvidenceCategory;
  url: string;
  source: EvidenceSource;
  createdAt?: string;
}

export interface VendorEvidencePage extends Evidence {
  note?: string;
  createdByName?: string;
  createdByEmail?: string;
  createdByAvatar?: string;
  updatedAt?: string;
  updatedByName?: string;
  updatedByEmail?: string;
  updatedByAvatar?: string;
}

export interface RiskAssessmentEvidencePage extends VendorEvidencePage {
  selected: boolean;
}
