import BaseAPI from "../../_common/rtkQueryApi";
import { SeverityInt } from "../../_common/types/severity";
import { Filters } from "../components/filter/types";
import { VulnsResult } from "../types/vulns";

export enum VendorTagTypes {
  vulns = "vulns",
}

// Peer param lists:
interface VendorVulnsV1Params {
  // params from api/vendorrisk/modulerouter/vendor_vulns_v1.go

  vendor_id?: number;
  is_subsidiary?: boolean;
  customer?: boolean;

  cve_names?: string[];
  cpe_names?: string[];
  exclude_verified?: boolean;
  exclude_unverified?: boolean;
  only_kev?: boolean;
  exclude_unignored?: boolean;
  exclude_ignored?: boolean;
  excluded_cvss_severities?: SeverityInt[];
  epss_score_range_lower?: number;
  epss_score_range_upper?: number;

  // api/filtering/website_filtering.go
  website_label_ids?: number[];
  website_label_ids_match_all?: boolean;
  website_label_ids_do_not_match?: boolean;
  website_include_unlabeled?: boolean;
  website_portfolio_ids?: number[];

  appguard_cloud_providers?: string[];
  appguard_cloud_connection_uuids?: string[];

  search_string?: string;

  // this allows the interface to be used as an RTKQ query param
  [key: string]: any;
}
interface VendorVulnsV1Response {
  status: "OK";
  result: VulnsResult;
}

export type ParentSelector = {
  vendorId?: number;
  isSubsidiary?: boolean;
};

export function toVendorVulnsParams(
  sel: ParentSelector,
  filters: Filters
): VendorVulnsV1Params {
  if (sel.isSubsidiary && sel.vendorId === undefined) {
    sel.vendorId = 0;
  }

  return {
    vendor_id: sel.vendorId,
    is_subsidiary: sel.isSubsidiary,
    customer: !(sel.vendorId || sel.isSubsidiary),
    website_label_ids: filters.websiteLabelIds,
    cve_names: filters.vulnsCveNames,
    cpe_names: filters.vulnsSoftware.map((cpe) => cpe.name),
    exclude_verified: filters.vulnsExcludeVerified,
    exclude_unverified: filters.vulnsExcludeUnverified,
    only_kev: filters.vulnsOnlyKEV,
    exclude_unignored: filters.vulnsExcludeUnignored,
    exclude_ignored: filters.vulnsExcludeIgnored,
    excluded_cvss_severities: filters.vulnsExcludedSeverities,
    epss_score_range_lower: filters.vulnsEPSSScores?.lowerVal,
    epss_score_range_upper: filters.vulnsEPSSScores?.upperVal,
    website_label_ids_match_all: filters.websiteLabelIdsMatchAll,
    website_label_ids_do_not_match: filters.websiteLabelIdsDoNotMatch,
    website_include_unlabeled: filters.websiteIncludeUnlabeled,
  };
}

const VendorVulnAPI = BaseAPI.enhanceEndpoints({
  addTagTypes: [...Object.values(VendorTagTypes)],
}).injectEndpoints({
  endpoints: (builder) => ({
    getVendorVulnsV1: builder.query<VendorVulnsV1Response, VendorVulnsV1Params>(
      {
        query: (params) => ({
          url: "/vendor/vulns/v1",
          method: "GET",
          params,
        }),
      }
    ),
  }),
});

export default VendorVulnAPI;
