import {
  ADD_NEW_RISK_NODE_TO_TREE,
  CLEAR_ANSWERS_PENDING_SAVE,
  CLOSE_RIGHT_PANEL_HEADER,
  DELETE_RISK_NODE_FROM_TREE,
  EDIT_MANUAL_RISK_NODE,
  RESET_STATE,
  SET_ACTIVE_NODE,
  SET_ADD_RISK_MODAL_STATE,
  SET_ANSWER,
  SET_ANSWERS,
  SET_ANSWERS_AND_SWAP,
  SET_CURRENT_FILTER,
  SET_CURRENT_FILTERED_ITEM,
  SET_EDIT_STATE,
  SET_EXCEL_SOURCE_DOC,
  SET_FILTER_NODESET_DEFINITIONS,
  SET_GPT_SOURCES,
  SET_LATEST_GPT_SUGGESTIONS,
  SET_LOCK_STATE,
  SET_NODE_TREE,
  SET_QUESTION_COMMENTS,
  SET_RIGHT_PANEL_STATE,
  SET_SCROLL_NEXT_UNANSWERED_QUESTION,
  SET_SCROLL_TARGET_NODE,
  SET_SECONDARY_ANSWERS,
  SET_SIDEBAR_EXPANDED_STATE,
  SurveyViewerActions,
  UPDATE_GPT_ACCEPT_REJECT_SUGGESTION,
  UPDATE_GPT_USER_PREFERENCES,
} from "./actions";
import {
  AnswersChanged,
  AnswersForNodes,
  checkIfAnyConditional,
  deleteNodeFromTree,
  filterAnsweredGptSuggestions,
  FilteredNodeData,
  findNodeAndParentByID,
  getAllSectionNodeChildrenAnswered,
  getAnswersChanged,
  getNextUnansweredQuestion,
  getNodeSummaryTreeWithNodes,
  getNodeVisibilities,
  insertNodeIntoTree,
  NodeChildrenAnswerState,
  NodeSummaryAndNode,
  VisiblityForNodes,
} from "../surveyViewer.helpers";
import { isEqual as _isEqual } from "lodash";
import { SurveyLockState } from "./apiActions";
import {
  ICorrespondenceMessage,
  SurveyQuestionMessageMeta,
} from "../../_common/types/correspondenceMessage";
import { GENERAL_GROUP_ID } from "../../_common/components/surveydetails/surveyDetails.helpers";
import {
  GptAutofillCacheStatus,
  SurveyIDWithPublic,
  SurveyIDWithPublicKey,
} from "./autofill.actions";
import {
  ExternalDocument,
  GptUserPreferences,
} from "../components/types/autofill.types";
import { Reducer } from "redux";
import { NodeTypeIconType } from "../../survey_builder/components/NodeTypeIcon";
import { NodeType } from "../../survey_builder/types/types";
import { Severity } from "../../_common/types/severity";
import { IVendorRiskWaiver } from "../../_common/types/vendorRiskWaivers";

type SurveyQuestionMessagesMap = {
  [questionId: string]: ICorrespondenceMessage<SurveyQuestionMessageMeta>[];
};

export enum surveyViewerRightPanelMode {
  Comments,
  Automation,
  GptAutofillSuggestions,
  CommentsPrivateOnly,
}

export interface SurveyViewerState {
  // The node id that is currently being viewed. Setting this will update the highlighting in the sidebar
  activeNodeId?: string;

  // The node tree of the questionnaire
  nodeTree: NodeSummaryAndNode | undefined;

  // Whether there are any conditionally visible nodes in the node tree. If not we can skip certain processing.
  isAnyNodeConditional: boolean;

  // The current answers for each node
  answers: AnswersForNodes;

  // The answers for the left side of the diff in diff mode
  leftAnswers: AnswersForNodes;

  // Store the calculated visibilities for all nodes
  nodeVisibilities: VisiblityForNodes;

  // Store the calculated node visibilities for our left answers if we are diffing
  leftNodeVisibilities: VisiblityForNodes;

  // Store the derived answer state for all section nodes
  nodeChildrenAnswered: NodeChildrenAnswerState;

  // Store the derived answer state for left answers when diffing
  leftNodeChildrenAnswered: NodeChildrenAnswerState;

  // If we are diffing also need to store if answers have changed between our versions or not
  answersChanged: AnswersChanged;

  // The to-scroll-ro node id, changing this will trigger a scroll action
  scrollTargetNodeId?: string;

  // Any answers we haven't yet sent to the backend for saving
  answersPendingSaving: AnswersForNodes;

  // Current lock state for the survey
  lock: SurveyLockState;

  // Current expand state for the sidebar items
  expandedNodes: {
    [nodeId: string]: boolean;
  };

  // Current filtered node lists for the current survey
  filteredNodeData: FilteredNodeData;

  // The current question we are viewing the right panel for, and whether the panel is active
  rightPanel: {
    mode: surveyViewerRightPanelMode;
    questionId: string | undefined;
    active: boolean;
  };

  // Comments for each question
  questionComments: SurveyQuestionMessagesMap;

  // If any edits have been made to the answers
  isEdited: boolean;

  autofillSourceDocs: Record<string, ExternalDocument>;
  platformSurveySources: Record<string, SurveyIDWithPublic>;
  gptUserPreference?: GptUserPreferences;

  gptAutofill?: GptAutofillCacheStatus;
  latestGptSuggestions?: string[];

  addRiskModalState:
    | {
        active: false;
      }
    | {
        active: true;
        parentID: string;
        editRiskId?: number;
        editNodeId?: string;
      };

  adjustmentsForRisks: Record<string, IVendorRiskWaiver>;
}

export enum SurveyViewFilterType {
  None = "",
  Autofilled = "autofilled",
  Unanswered = "unanswered",
  RisksRaised = "risks_raised",
}

export const surveyViewerInitialState: SurveyViewerState = {
  activeNodeId: undefined,
  nodeTree: undefined,
  isAnyNodeConditional: false,
  answers: {},
  leftAnswers: {},
  nodeVisibilities: {},
  leftNodeVisibilities: {},
  nodeChildrenAnswered: {},
  leftNodeChildrenAnswered: {},
  answersChanged: { totalChanged: 0, answers: {} },
  scrollTargetNodeId: undefined,
  answersPendingSaving: {},
  lock: {
    isLocked: false,
    lockedBy: "",
  },
  expandedNodes: {},
  filteredNodeData: {
    activeFilter: SurveyViewFilterType.None,
    currentSelectedItem: -1,
    numQuestions: 0,
    nodeSets: {
      [SurveyViewFilterType.Autofilled]: [],
      [SurveyViewFilterType.Unanswered]: [],
      [SurveyViewFilterType.RisksRaised]: [],
    },
    setIndexes: {
      [SurveyViewFilterType.Autofilled]: {},
      [SurveyViewFilterType.Unanswered]: {},
      [SurveyViewFilterType.RisksRaised]: {},
    },
  },
  rightPanel: {
    mode: surveyViewerRightPanelMode.Comments,
    active: false,
    questionId: undefined,
  },
  questionComments: {},
  isEdited: false,
  autofillSourceDocs: {},
  platformSurveySources: {},
  addRiskModalState: {
    active: false,
  },
  adjustmentsForRisks: {},
};

export const surveyViewerReducer: Reducer<SurveyViewerState> = (
  state = surveyViewerInitialState,
  untypedAction
) => {
  const action = untypedAction as SurveyViewerActions;

  switch (action.type) {
    case RESET_STATE:
      return surveyViewerInitialState;
    case SET_NODE_TREE:
      const isAnyNodeConditional = checkIfAnyConditional(action.nodeTree);

      const leftNodeVisibilities = getNodeVisibilities(
        action.nodeTree,
        state.leftAnswers
      );
      const rightNodeVisible = getNodeVisibilities(
        action.nodeTree,
        state.answers,
        state.gptAutofill
      );

      return {
        ...state,
        nodeTree: action.nodeTree,
        isAnyNodeConditional: isAnyNodeConditional,
        nodeVisibilities: rightNodeVisible,
        leftNodeVisibilities,
        answersChanged: getAnswersChanged(
          action.nodeTree,
          state.leftAnswers,
          state.answers,
          leftNodeVisibilities,
          rightNodeVisible
        ),
      };

    case SET_ANSWERS:
      // Adjust the GPT suggestions if necessary (existing answers can be loaded/updated after gpt autofill data loaded)
      let gptAutofill = state.gptAutofill;
      if (gptAutofill?.suggestions && !gptAutofill.overrideAnswers) {
        gptAutofill = {
          ...gptAutofill,
          suggestions:
            filterAnsweredGptSuggestions(
              gptAutofill.suggestions,
              action.answers
            ) || {},
        };
      }

      const visibilities = getNodeVisibilities(
        state.nodeTree,
        action.answers,
        gptAutofill
      );

      return {
        ...state,
        answers: action.answers,
        nodeVisibilities: visibilities,
        nodeChildrenAnswered: getAllSectionNodeChildrenAnswered(
          state.nodeTree,
          action.answers,
          visibilities,
          gptAutofill?.suggestions
        ),
        answersChanged: getAnswersChanged(
          state.nodeTree,
          state.leftAnswers,
          action.answers,
          state.leftNodeVisibilities,
          visibilities
        ),
        gptAutofill: gptAutofill,
      };

    case SET_ANSWER: {
      const prevAnswer = state.answers[action.nodeId];

      const newAnswerState = {
        ...state.answers,
        [action.nodeId]: action.answer,
      };

      const newAnswersPendingSaving = {
        ...state.answersPendingSaving,
        [action.nodeId]: action.answer,
      };

      let visibilitiesForAnswer = state.nodeVisibilities;
      let nodeChildrenAnswered = state.nodeChildrenAnswered;

      // Check if we need to recalculate visibilities and if all children are answered
      // If this is a non-zero length string answer and the previous answer was a non-zero length string, we can skip
      // if we are accepting a suggestion we DO NOT need to recalculate visibilities
      if (
        (typeof prevAnswer !== "string" ||
          typeof action.answer !== "string" ||
          prevAnswer.length === 0 ||
          action.answer.length === 0) &&
        !action.acceptingSuggestion
      ) {
        if (state.isAnyNodeConditional) {
          visibilitiesForAnswer = getNodeVisibilities(
            state.nodeTree,
            newAnswerState
            // if we are setting an answer we are in edit mode so never check secondary answers for a diff
          );
        }

        nodeChildrenAnswered = getAllSectionNodeChildrenAnswered(
          state.nodeTree,
          newAnswerState,
          visibilitiesForAnswer,
          state.gptAutofill?.suggestions
        );
      }

      return {
        ...state,
        answers: newAnswerState,
        nodeVisibilities: visibilitiesForAnswer,
        nodeChildrenAnswered: nodeChildrenAnswered,
        answersPendingSaving: newAnswersPendingSaving,
        isEdited: true,
      };
    }
    case SET_ACTIVE_NODE:
      // Don't set active node if scrolling to target
      if (state.scrollTargetNodeId) {
        return state;
      }

      return {
        ...state,
        activeNodeId: action.nodeId,
      };

    case SET_SCROLL_TARGET_NODE:
      return {
        ...state,
        scrollTargetNodeId: action.nodeId,
        activeNodeId:
          action.setActiveNode && action.nodeId
            ? action.nodeId
            : state.activeNodeId,
      };

    case CLEAR_ANSWERS_PENDING_SAVE:
      const newAnswersPendingSave = { ...state.answersPendingSaving };

      // Clear out any answer we saved that still has the same value (assume it saved OK)
      for (const k in action.answers) {
        const currentAnswer = newAnswersPendingSave[k];
        const answerJustSaved = action.answers[k];

        if (_isEqual(currentAnswer, answerJustSaved)) {
          delete newAnswersPendingSave[k];
        }
      }

      // If any answers got missed, they will be saved on the next save call

      return {
        ...state,
        answersPendingSaving: newAnswersPendingSave,
      };

    case SET_LOCK_STATE:
      return {
        ...state,
        lock: action.lockDetails,
      };

    case SET_SIDEBAR_EXPANDED_STATE:
      const stateChanges: { [nodeId: string]: boolean } = {};
      action.nodeIds.forEach((nId) => {
        stateChanges[nId] = action.state;
      });

      return {
        ...state,
        expandedNodes: {
          ...state.expandedNodes,
          ...stateChanges,
        },
      };

    case SET_SCROLL_NEXT_UNANSWERED_QUESTION:
      // Check if we've loaded the nodes yet, if not just return the state as-is
      if (!state.nodeTree) {
        return {
          ...state,
        };
      }

      const nextQuestionNodeId = getNextUnansweredQuestion(
        state.nodeTree,
        state.answers,
        state.nodeVisibilities,
        true,
        state.gptAutofill?.suggestions
      );

      return {
        ...state,
        scrollTargetNodeId: nextQuestionNodeId,
        activeNodeId: nextQuestionNodeId,
      };

    case SET_RIGHT_PANEL_STATE:
      return {
        ...state,
        rightPanel: {
          mode: action.mode,
          questionId: action.questionId,
          active: action.active,
        },
      };

    case CLOSE_RIGHT_PANEL_HEADER:
      return {
        ...state,
        rightPanel: {
          ...state.rightPanel,
          active: false,
        },
      };

    case SET_QUESTION_COMMENTS:
      let messageMap = {} as SurveyQuestionMessagesMap;

      if (action.questionId) {
        messageMap = { ...state.questionComments };
        messageMap[action.questionId] = action.messages;
      } else {
        action.messages.forEach((m) => {
          const groupId =
            m.meta && m.meta.questionId ? m.meta.questionId : GENERAL_GROUP_ID;
          const messagesForGroup = messageMap[groupId] ?? [];
          messagesForGroup.push(m);
          messageMap[groupId] = messagesForGroup;
        });
      }

      return {
        ...state,
        questionComments: messageMap,
      };

    case SET_EDIT_STATE:
      return {
        ...state,
        isEdited: action.editState,
      };

    case SET_SECONDARY_ANSWERS:
      const leftVisibilities = getNodeVisibilities(
        state.nodeTree,
        action.answers
      );
      return {
        ...state,
        leftAnswers: action.answers,
        leftNodeVisibilities: leftVisibilities,
        leftNodeChildrenAnswered: getAllSectionNodeChildrenAnswered(
          state.nodeTree,
          action.answers,
          leftVisibilities
        ),
        answersChanged: getAnswersChanged(
          state.nodeTree,
          action.answers,
          state.answers,
          leftVisibilities,
          state.nodeVisibilities
        ),
      };

    case SET_ANSWERS_AND_SWAP:
      const swapVisibilities = getNodeVisibilities(
        state.nodeTree,
        action.answers
      );

      return {
        ...state,
        answers: action.answers,
        nodeVisibilities: swapVisibilities,
        nodeChildrenAnswered: getAllSectionNodeChildrenAnswered(
          state.nodeTree,
          action.answers,
          swapVisibilities
        ),
        leftAnswers: state.answers,
        leftNodeVisibilities: state.nodeVisibilities,
        leftNodeChildrenAnswered: state.nodeChildrenAnswered,
        answersChanged: getAnswersChanged(
          state.nodeTree,
          state.answers,
          action.answers,
          state.nodeVisibilities,
          swapVisibilities
        ),
      };

    case SET_GPT_SOURCES:
      const docs = action.docs.reduce(
        (prev, d) => {
          prev[d.uuid] = d;
          return prev;
        },
        {} as Record<string, ExternalDocument>
      );

      const surveys = action.surveys.reduce(
        (prev, s) => {
          prev[SurveyIDWithPublicKey(s)] = s;
          return prev;
        },
        {} as Record<string, SurveyIDWithPublic>
      );

      return {
        ...state,
        autofillSourceDocs: docs,
        platformSurveySources: surveys,
      };

    case SET_EXCEL_SOURCE_DOC:
      return {
        ...state,
        autofillSourceDocs: {
          ...state.autofillSourceDocs,
          [action.doc.uuid]: action.doc,
        },
      };

    case "SET_GPT_AUTOFILL_CACHE": {
      const nodeVisibilities = getNodeVisibilities(
        state.nodeTree,
        state.answers,
        action.cache
      );
      const nodeChildrenAnswered = getAllSectionNodeChildrenAnswered(
        state.nodeTree,
        state.answers,
        nodeVisibilities,
        action.cache.suggestions
      );

      return {
        ...state,
        nodeVisibilities,
        gptAutofill: action.cache,
        nodeChildrenAnswered: nodeChildrenAnswered,
      };
    }

    case "UPDATE_GPT_AUTOFILL_CACHE": {
      if (!state.gptAutofill) {
        return state;
      }

      const newSuggestions = {
        ...state.gptAutofill?.suggestions,
        ...action.newSuggestions,
      };
      const newCache: GptAutofillCacheStatus = {
        ...state.gptAutofill,
        suggestions: newSuggestions,
        jobFinished: action.done,
        jobProgress: action.progress,
        overrideAnswers: action.overrideAnswers,
      };

      const newVisibilities = getNodeVisibilities(
        state.nodeTree,
        state.answers,
        newCache
      );
      const nodeChildrenAnswered = getAllSectionNodeChildrenAnswered(
        state.nodeTree,
        state.answers,
        newVisibilities,
        newSuggestions
      );

      return {
        ...state,
        nodeVisibilities: newVisibilities,
        gptAutofill: newCache,
        nodeChildrenAnswered: nodeChildrenAnswered,
      };
    }

    case SET_LATEST_GPT_SUGGESTIONS:
      return {
        ...state,
        latestGptSuggestions: action.newSuggestionQuestionIDs,
      };

    case UPDATE_GPT_ACCEPT_REJECT_SUGGESTION: {
      const suggestion = Object.values(
        state.gptAutofill?.suggestions ?? {}
      ).find((s) => s.id == action.suggestionID);

      if (!suggestion || !state.gptAutofill) {
        return state;
      }

      // also need to update the answered state after this
      const suggestions = {
        ...state.gptAutofill.suggestions,
        [suggestion.questionID]: {
          ...suggestion,
          used: action.accept,
          rejected: !action.accept,
        },
      };

      const cache = {
        ...state.gptAutofill,
        suggestions,
      };

      const newVisibilities = getNodeVisibilities(
        state.nodeTree,
        state.answers,
        cache
      );
      const nodeChildrenAnswered = getAllSectionNodeChildrenAnswered(
        state.nodeTree,
        state.answers,
        newVisibilities,
        suggestions
      );

      return {
        ...state,
        gptAutofill: cache,
        nodeVisibilities: newVisibilities,
        nodeChildrenAnswered: nodeChildrenAnswered,
      };
    }

    case UPDATE_GPT_USER_PREFERENCES:
      return {
        ...state,
        gptUserPreference: action.preferences,
      };

    case SET_CURRENT_FILTER:
      return {
        ...state,
        filteredNodeData: {
          ...state.filteredNodeData,
          activeFilter: action.filter,
        },
      };

    case SET_CURRENT_FILTERED_ITEM:
      return {
        ...state,
        filteredNodeData: {
          ...state.filteredNodeData,
          currentSelectedItem: action.index,
        },
      };

    case SET_FILTER_NODESET_DEFINITIONS:
      return {
        ...state,
        filteredNodeData: {
          ...state.filteredNodeData,
          nodeSets: action.nodeSets,
          setIndexes: action.nodeSetIndexes,
          numQuestions: action.numQuestions,
        },
      };

    case SET_ADD_RISK_MODAL_STATE:
      return {
        ...state,
        addRiskModalState: action.newState
          ? {
              active: true,
              parentID: action.parentID,
              editRiskId: action.editRiskId,
              editNodeId: action.editNodeId,
            }
          : {
              active: false,
            },
      };

    case ADD_NEW_RISK_NODE_TO_TREE: {
      if (!state.nodeTree) {
        return state;
      }

      const nodeSummary = getNodeSummaryTreeWithNodes("", action.newRiskNode);
      nodeSummary.nodeType = NodeTypeIconType.Risk;
      nodeSummary.titleOrMainText = action.newRiskNode.name ?? "";

      const newNodeTree = { ...state.nodeTree };

      if (nodeSummary.node.customRiskParentId) {
        insertNodeIntoTree(
          newNodeTree,
          nodeSummary,
          nodeSummary.node.customRiskParentId
        );
      } else {
        return state;
      }

      // make sure the parent has the manual risk flag set
      const parentNodeAndParent = findNodeAndParentByID(
        newNodeTree,
        nodeSummary.node.customRiskParentId ?? ""
      );

      if (parentNodeAndParent) {
        parentNodeAndParent.node.node.hasCustomRisk = true;
        if (
          parentNodeAndParent.parent?.children &&
          parentNodeAndParent.nodeIdx != undefined
        ) {
          parentNodeAndParent.parent.children[parentNodeAndParent.nodeIdx] = {
            ...parentNodeAndParent.node,
          };
        }
      }

      const newVisibilities = getNodeVisibilities(newNodeTree, state.answers);

      return {
        ...state,
        // note - want to reset the node tree here to trigger a rerender
        nodeTree: newNodeTree,
        nodeVisibilities: newVisibilities,
      };
    }

    case DELETE_RISK_NODE_FROM_TREE: {
      if (!state.nodeTree) {
        return state;
      }

      const newNodeTree = { ...state.nodeTree };

      deleteNodeFromTree(newNodeTree, action.nodeId);

      // find the parent node and make sure we mark it as no longer having a risk attached
      const nodeAndParent = findNodeAndParentByID(
        newNodeTree,
        action.parentNodeId
      );
      if (nodeAndParent) {
        nodeAndParent.node.node.hasCustomRisk = false;
        if (
          nodeAndParent.parent?.children &&
          nodeAndParent.nodeIdx != undefined
        ) {
          nodeAndParent.parent.children[nodeAndParent.nodeIdx] = {
            ...nodeAndParent.node,
          };
        }
      }

      return {
        ...state,
        // note - want to reset the node tree here to trigger a rerender
        nodeTree: newNodeTree,
      };
    }

    case EDIT_MANUAL_RISK_NODE: {
      if (!state.nodeTree) {
        return state;
      }

      const newNodeTree = { ...state.nodeTree };

      const nodeAndParent = findNodeAndParentByID(newNodeTree, action.nodeId);
      if (nodeAndParent && nodeAndParent.node.node.type == NodeType.Risk) {
        nodeAndParent.node.titleOrMainText = action.newRiskName;
        nodeAndParent.node.node.customRiskID = action.newRiskId;
        nodeAndParent.node.node.name = action.newRiskName;
        nodeAndParent.node.node.nameSanitised = action.newRiskName;
        nodeAndParent.node.node.mainTextSanitised = action.newRiskImpact;
        nodeAndParent.node.node.mainText = action.newRiskImpact;
        nodeAndParent.node.node.severity = action.newRiskSeverity as Severity;
        nodeAndParent.node.node.impact = action.newRiskImpact;

        if (
          nodeAndParent.parent?.children &&
          nodeAndParent.nodeIdx != undefined
        ) {
          nodeAndParent.parent.children[nodeAndParent.nodeIdx] = {
            ...nodeAndParent.node,
          };
        }
      }

      return {
        ...state,
        nodeTree: newNodeTree,
      };
    }

    case "SET_RISK_ADJUSTMENTS":
      return {
        ...state,
        adjustmentsForRisks: action.adjustments.reduce(
          (map, aj) => {
            map[aj.riskID] = aj;
            return map;
          },
          {} as Record<string, IVendorRiskWaiver>
        ),
      };

    default:
      return state;
  }
};
