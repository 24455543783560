import { FC } from "react";
import { useDropzone } from "react-dropzone";
import classnames from "classnames";
import LoadingIcon from "./core/LoadingIcon";
import "../style/components/DragDropUploadV2.scss";
import { DragDropUploadBaseProps } from "./DragDropUpload";

interface DragDropUploadV2Props extends DragDropUploadBaseProps {
  hasError?: boolean;
}

const DragDropUploadV2: FC<DragDropUploadV2Props> = ({
  onFileSelected,
  onFileRejected,
  maxFileSize,
  acceptedFileTypeFilters,
  loading,
  hasError,
  multiple,
  onSelectionChange,
  disabled,
}) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    multiple: multiple,
    maxSize: maxFileSize,
    accept: acceptedFileTypeFilters,
    onDrop: (acceptedFiles, rejectedFiles) => {
      if (acceptedFiles.length > 0) {
        if (onFileSelected) onFileSelected(acceptedFiles[0]);
      } else if (rejectedFiles.length > 0) {
        if (onFileRejected) onFileRejected(rejectedFiles[0]);
      }

      if (onSelectionChange) onSelectionChange(acceptedFiles, rejectedFiles);
    },
  });

  return (
    <div className={"dragdropupload-v2"}>
      <div
        className={classnames("inner-container", {
          disabled: loading || disabled,
          drag_active: isDragActive,
        })}
      >
        <div {...getRootProps()} className={"drag-drop-zone"}>
          {loading && <LoadingIcon size={32} />}
          {!loading && (
            <>
              <input {...getInputProps()} />
              <div className={"desc-text-container"}>
                <i className={"cr-icon-upload"} />
                <span className={"desc-text"}>
                  Click or drag here to upload
                </span>
              </div>
            </>
          )}
        </div>
      </div>
      {hasError && (
        <div className={"upload-error"}>
          <i className={"cr-icon-risk"} />
          We encountered an error processing your file, please try again
        </div>
      )}
    </div>
  );
};

export default DragDropUploadV2;
