import React, { ReactElement, ReactNode } from "react";
import classNames from "classnames";
import "../../style/components/core/DropDown.scss";

// Higher-order event listener that call's both the component's onClick and the function passed in
// with the same parameters
const spyOnClick =
  (fn: any, comp: any) =>
  (...args: any) => {
    // Avoid disposing of event information
    // https://facebook.github.io/react/docs/events.html#event-pooling
    args[0].persist();
    comp.props.onClick(...args);

    fn(...args);
  };

export interface IDropDownProps {
  // Element for the dropdown to base off
  action: React.ReactNode;
  id: string;
  // menu items - use <div className={"actions-opt"}> for auto-styling
  children: ReactNode | ReactNode[];
  custom?: string;
  // pullRight
  right?: boolean;
  open?: boolean;
  disabled?: boolean;
  stopPropagationOnOpen?: boolean;
}

interface IDropDownState {
  showMenu: boolean;
}

class DropDown extends React.Component<IDropDownProps, IDropDownState> {
  domNode: React.RefObject<any>;

  static defaultProps = {
    id: "",
    open: undefined,
    custom: undefined,
    disabled: false,
    stopPropagationOnOpen: false,
    right: false,
  };

  constructor(props: IDropDownProps) {
    super(props);

    this.toggleMenuAction = this.toggleMenuAction.bind(this);
    this.handleDocumentBlur = this.handleDocumentBlur.bind(this);
    this.handleBlur = this.handleBlur.bind(this);

    this.domNode = React.createRef();

    this.state = {
      showMenu: false,
    };
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.handleDocumentBlur);
  }

  onKeyUp = (evt: any) => {
    const key = evt.key.toLowerCase();
    if (key === " " || key === "enter") {
      this.toggleMenuAction(evt);
    }
  };

  toggleMenuAction(evt: any) {
    if (this.props.stopPropagationOnOpen) {
      evt.stopPropagation();
    }

    // We are acting as a controlled drop-down, return;
    if (this.props.open !== undefined) return;
    if (this.state.showMenu) {
      document.removeEventListener("click", this.handleDocumentBlur);
    } else {
      document.addEventListener("click", this.handleDocumentBlur);
    }
    this.setState({ showMenu: !this.state.showMenu });
  }

  handleDocumentBlur(evt: any) {
    /* eslint-disable react/no-find-dom-node */
    /* istanbul ignore next */
    if (this.domNode.current && this.domNode.current.contains(evt.target))
      return;
    /* eslint-enable react/no-find-dom-node */
    this.handleBlur();
  }

  handleBlur() {
    if (this.props.open !== undefined) return;
    this.setState({ showMenu: false });
  }

  render() {
    const { action, children, custom, disabled, open, right, id } = this.props;
    const { showMenu } = this.state;

    // Allow for the dropdown to be controlled by it's parent vs it's state
    const showMenuUI = open === undefined ? showMenu : open;

    const dropdownContent = React.Children.map(children, (c) => {
      // Listen for when our child is clicked
      const onClickChild = spyOnClick(this.handleBlur, c);
      return React.cloneElement(c as ReactElement, { onClick: onClickChild });
    });

    const elClassName = classNames("custom-dropdown", custom, { disabled });
    const dropdownTextClassName = classNames("dropdown-text", {
      hover: showMenuUI,
    });
    const dropdownClassName = classNames("dropdown-menu", {
      open: showMenuUI,
      right: right,
    });

    return (
      <div
        className={elClassName}
        id={id}
        onKeyUp={this.onKeyUp}
        ref={this.domNode}
      >
        <span
          className={dropdownTextClassName}
          tabIndex={0}
          onClick={this.toggleMenuAction}
        >
          {action}
        </span>
        {showMenuUI && (
          <div className={dropdownClassName} tabIndex={0}>
            {dropdownContent}
          </div>
        )}
      </div>
    );
  }
}

export default DropDown;
