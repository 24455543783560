import BaseAPI, { vendorDataTag } from "../../_common/rtkQueryApi";
import {
  DisplayableManagedVendorAssessment,
  IManagedVendorPageOpts,
  IManagedVendorRequestSearchResult,
  ManagedVendorLicenseUsed,
  ManagedVendorMetaV2,
} from "../../_common/types/thirdPartyMangedVendors";
import { IVendorContactResponse } from "../../_common/types/vendorContact";
import moment from "moment";
import { ITimelineEvent } from "../../_common/types/apiTimeline";
import { IUserMiniMap } from "../../_common/types/user";
import { mapTimelineStatuses } from "../../_common/reducers/surveyDetails.actions";
import { ICorrespondenceMessage } from "../../_common/types/correspondenceMessage";
import {
  invalidateManagedVendorLatestUnstartedRequestTag,
  invalidateVendorAssessmentStreamListTag,
} from "./vendorAssessmentAPI";
import {
  fetchAdditionalEvidenceForVendor,
  fetchAdditionalEvidenceForVendorResp,
} from "./additionalEvidence.actions";
import {
  fetchVendorSummaryAndCloudscans,
  fetchVendorWatchStatus,
} from "./cyberRiskActions";
import { produce } from "immer";
import { surveyType } from "../../_common/types/surveyTypes";

export const managedVendorsLicenseTags = "ManagedVendorsLicenseTags" as const;
export const managedVendorsSearchResultTag =
  "ManagedVendorsSearchResultTag" as const;
export const managedVendorsListTag = "managedVendorsListTag" as const;
export const managedVendorRequestTag = "managedVendorRequest" as const;
export const managedVendorCorrespondenceTag =
  "managedVendorCorrespondence" as const;
export const managedVendorAssessmentEvidenceTag =
  "managedVendorAssessmentEvidenceTag" as const;
export const managedVendorSurveyTypeTag = "managedVendorSurveyTypeTag" as const;

export type managedVendorContact =
  | {
      name: string;
      title: string;
      email: string;
    }
  | {
      existingId: number;
    };

export interface createVendorManagementRequestV3 {
  contacts: managedVendorContact[];
  meta: ManagedVendorMetaV2;
  vendorID: number;
  labels?: number[];
  tier?: number;
  portfolios?: number[];
  evidenceIDs?: number[];
  publicEvidenceIDs?: number[];
  businessContactID: number;
  messageSubject: string;
  messageBody: string;
}

export interface managedVendorListReq {
  page_num?: number;
  page_size?: number;
  sort_by?: string;
  sort_dir?: string;
  name_prefix?: string;
  tab?: "completed" | "inProgress" | "legacy";
}

export interface managedVendorListResp {
  managedAssessments: DisplayableManagedVendorAssessment[];
  paging: IManagedVendorPageOpts;
  filterText: string;
  totals: {
    all: number;
    inProgress: number;
    completed: number;
    legacy: number;
  };
}

export interface managedVendorHistoryResp {
  timeline: ITimelineEvent[];
  users: IUserMiniMap;
}

const ManagedVendorsAPI = BaseAPI.enhanceEndpoints({
  addTagTypes: [
    managedVendorsLicenseTags,
    managedVendorsSearchResultTag,
    managedVendorsListTag,
    managedVendorRequestTag,
    managedVendorCorrespondenceTag,
    managedVendorAssessmentEvidenceTag,
    managedVendorSurveyTypeTag,
  ],
}).injectEndpoints({
  endpoints: (builder) => ({
    getManagedVendorLicenses: builder.query<
      { licenses: ManagedVendorLicenseUsed[] },
      void
    >({
      query: () => ({
        url: "/managedvendors/licenses/v2",
        method: "GET",
      }),
      providesTags: (result) =>
        result ? [{ type: managedVendorsLicenseTags }] : [],
    }),

    searchManagedVendors: builder.query<
      { vendors: IManagedVendorRequestSearchResult[]; totalMatches: number },
      { query: string }
    >({
      query: ({ query }) => ({
        url: "managedvendors/search/v1",
        method: "GET",
        params: { query },
      }),
      providesTags: (result, _error, { query }) =>
        result ? [{ type: managedVendorsLicenseTags, id: query }] : [],
      extraOptions: {},
    }),

    getInfoForNewRequest: builder.query<
      {
        contacts: IVendorContactResponse[];
        labels: number[];
        tier?: number;
        name?: string;
        portfolios: number[];
      },
      { vendorId: number }
    >({
      query: ({ vendorId }) => ({
        url: "managedvendors/vendorinfo/v2",
        method: "GET",
        params: { vendor_id: vendorId },
      }),
      providesTags: (result, _error, { vendorId }) =>
        result ? [{ type: vendorDataTag, id: vendorId }] : [],
    }),

    createVendorManagementRequestV3: builder.mutation<
      { newId: number },
      createVendorManagementRequestV3
    >({
      query: (args) => ({
        url: "managedvendors/v3",
        method: "POST",
        body: JSON.stringify(args),
      }),
      invalidatesTags: (result, _error, args) =>
        result
          ? [
              { type: vendorDataTag, id: args.vendorID },
              { type: managedVendorsLicenseTags },
              { type: managedVendorsListTag },
            ]
          : [],
      onQueryStarted: (arg, { dispatch, queryFulfilled }) => {
        queryFulfilled.then(() => {
          // get the vendor details again in case the watch status has changed
          dispatch(fetchVendorWatchStatus(arg.vendorID, true));
        });
      },
    }),

    getManagedVendorAssessmentListForOrg: builder.query<
      managedVendorListResp,
      managedVendorListReq
    >({
      query: (args) => ({
        url: "managedvendors/v2",
        method: "GET",
        params: {
          ...args,
          is_v3: args.tab != "legacy",
        },
      }),
      providesTags: (result, _error, args) =>
        result
          ? [
              ...result.managedAssessments.map((ma) => ({
                type: managedVendorRequestTag,
                id: ma.id,
              })),
              ...result.managedAssessments.map((ma) => ({
                type: vendorDataTag,
                id: ma.vendorID,
              })),
              { type: managedVendorsListTag, id: JSON.stringify(args) },
              { type: managedVendorsListTag, id: "PARTIAL-LIST" },
            ]
          : [],
      onQueryStarted: (_arg, { dispatch, queryFulfilled }) => {
        // upsert all our managed assessments
        queryFulfilled.then((result) => {
          result.data.managedAssessments.forEach((ma) => {
            dispatch(
              ManagedVendorsAPI.util.upsertQueryData(
                "getManagedVendorAssessmentDetails",
                { requestId: ma.id },
                { result: ma }
              )
            );
          });
        });
      },
    }),

    getManagedVendorAssessmentDetails: builder.query<
      { result: DisplayableManagedVendorAssessment },
      { requestId: number }
    >({
      query: (args) => ({
        url: "managedvendors/assessment/v2",
        method: "GET",
        params: {
          request_id: args.requestId,
        },
      }),
      providesTags: (result, _error, args) =>
        result
          ? [
              { type: vendorDataTag, id: result.result.vendorID },
              { type: managedVendorRequestTag, id: args.requestId },
            ]
          : [],
    }),

    updateMetaAnswersV3: builder.mutation<
      void,
      { meta: ManagedVendorMetaV2; requestID: number }
    >({
      query: (args) => ({
        url: "managedvendors/meta/v3",
        method: "PUT",
        body: JSON.stringify(args),
      }),
      onQueryStarted: (arg, { dispatch, queryFulfilled }) => {
        const patch = dispatch(
          ManagedVendorsAPI.util.updateQueryData(
            "getManagedVendorAssessmentDetails",
            { requestId: arg.requestID },
            (draft) => {
              draft.result.metaV2 = arg.meta;
              draft.result.lastUpdated = moment().format();
            }
          )
        );

        queryFulfilled.catch(() => patch.undo());
      },
    }),

    deleteManagedVendorRequest: builder.mutation<void, { requestID: number }>({
      query: (args) => ({
        url: "managedvendors/v2",
        method: "DELETE",
        params: {
          request_id: args.requestID,
        },
      }),
      invalidatesTags: (result, _error, args) =>
        result
          ? [
              { type: managedVendorRequestTag, id: args.requestID },
              { type: managedVendorsListTag },
            ]
          : [],
    }),

    updateSelectedEvidence: builder.mutation<
      void,
      {
        requestID: number;
        evidenceIDs: number[];
        publicEvidenceIDs: number[];
      }
    >({
      query: (args) => ({
        url: "managedvendors/evidence/v3",
        method: "PUT",
        body: JSON.stringify(args),
      }),
      onQueryStarted: (arg, { dispatch, queryFulfilled }) => {
        const patch = dispatch(
          ManagedVendorsAPI.util.updateQueryData(
            "getManagedVendorAssessmentDetails",
            { requestId: arg.requestID },
            (draft) => {
              draft.result.publicEvidenceIDs = arg.publicEvidenceIDs;
              draft.result.additionalEvidenceIDs = arg.evidenceIDs;
              draft.result.lastUpdated = moment().format();
            }
          )
        );

        queryFulfilled.catch(() => {
          patch.undo();
        });
      },
    }),

    editVendorManagementContacts: builder.mutation<
      void,
      { requestID: number; contacts: managedVendorContact[] }
    >({
      query: (args) => ({
        url: "managedvendors/edit/contact/v3",
        method: "PUT",
        body: JSON.stringify(args),
      }),
      // need to fully refresh request to get contact metadata
      invalidatesTags: (result, _error, arg) =>
        result ? [{ type: managedVendorRequestTag, id: arg.requestID }] : [],
    }),

    editBusinessContact: builder.mutation<
      void,
      { requestID: number; contactID: number }
    >({
      query: (args) => ({
        url: "managedvendors/businesscontact/v3",
        method: "PUT",
        params: {
          request_id: args.requestID,
          contact_id: args.contactID,
        },
      }),
      invalidatesTags: (result, _error, arg) =>
        result ? [{ type: managedVendorRequestTag, id: arg.requestID }] : [],
    }),

    getHistory: builder.query<managedVendorHistoryResp, { requestID: number }>({
      query: (args) => ({
        url: "managedvendors/history/v1",
        method: "GET",
        params: {
          request_id: args.requestID,
        },
      }),
      transformResponse: (value: managedVendorHistoryResp) => {
        value.timeline = mapTimelineStatuses(value.timeline);
        return value;
      },
      providesTags: (result, _error, args) =>
        result ? [{ type: managedVendorRequestTag, id: args.requestID }] : [],
    }),

    assignVendorAssessmentToManagedRequest: builder.mutation<
      void,
      { vendorID: number; requestID: number; assessmentID: number }
    >({
      query: (args) => ({
        url: "cyberresearch/managedvendor/assign/v2",
        method: "PUT",
        params: {
          request_id: args.requestID,
          vendor_assessment_id: args.assessmentID,
        },
      }),
      onQueryStarted: (args, { dispatch, queryFulfilled }) => {
        queryFulfilled.then((_) => {
          dispatch(invalidateVendorAssessmentStreamListTag(args.vendorID));
          dispatch(
            invalidateManagedVendorLatestUnstartedRequestTag(args.vendorID)
          );
        });
      },
    }),

    unassignVendorAssessmentToManagedRequest: builder.mutation<
      void,
      { vendorID: number; requestID: number }
    >({
      query: (args) => ({
        url: "cyberresearch/managedvendor/unassign/v1",
        method: "PUT",
        params: {
          request_id: args.requestID,
        },
      }),
      onQueryStarted: (args, { dispatch, queryFulfilled }) => {
        queryFulfilled.then((_) => {
          dispatch(invalidateVendorAssessmentStreamListTag(args.vendorID));
          dispatch(
            invalidateManagedVendorLatestUnstartedRequestTag(args.vendorID)
          );
        });
      },
    }),

    getCorrespondence: builder.query<
      { messages: ICorrespondenceMessage[]; users: IUserMiniMap },
      { requestID: number }
    >({
      query: (args) => ({
        url: "managedvendors/message/v2",
        method: "GET",
        params: {
          request_id: args.requestID,
        },
      }),
      providesTags: (result, _error, args) =>
        result
          ? [{ type: managedVendorCorrespondenceTag, id: args.requestID }]
          : [],
    }),

    postCorrespondence: builder.mutation<
      { message: ICorrespondenceMessage },
      {
        requestID: number;
        content: string;
        private?: boolean;
        parentID?: number;
      }
    >({
      query: (args) => ({
        url: "managedvendors/message/v2",
        method: "POST",
        params: {
          request_id: args.requestID,
          content: args.content,
          private: args.private,
          parent_id: args.parentID,
        },
      }),
      invalidatesTags: (result, _error, arg) =>
        result
          ? [{ type: managedVendorCorrespondenceTag, id: arg.requestID }]
          : [],
    }),

    editCorrespondence: builder.mutation<
      { message: ICorrespondenceMessage },
      { requestID: number; content: string; messageID: number }
    >({
      query: (args) => ({
        url: "managedvendors/message/v2",
        method: "PUT",
        params: {
          request_id: args.requestID,
          message_id: args.messageID,
          content: args.content,
        },
      }),
      invalidatesTags: (result, _error, arg) =>
        result
          ? [{ type: managedVendorCorrespondenceTag, id: arg.requestID }]
          : [],
    }),

    deleteAdditionalEvidenceForRequest: builder.mutation<
      void,
      { vendorID: number; evidenceID: number }
    >({
      query: (args) => ({
        url: "vendor/evidence/managed/v1/",
        method: "DELETE",
        params: {
          evidence_id: args.evidenceID,
        },
      }),
      invalidatesTags: (result, _err, args) =>
        result
          ? [{ type: managedVendorAssessmentEvidenceTag, id: args.vendorID }]
          : [],
    }),

    // getAdditionalEvidenceForRequest
    // gets additional evidence for a vendor during the managed assessment flow
    // this should only be used in the context of that flow as it bypasses watch checks
    getAdditionalEvidenceForRequest: builder.query<
      fetchAdditionalEvidenceForVendorResp,
      { vendorID: number }
    >({
      query: (args) => ({
        url: "vendor/evidencelist/managed/v1",
        method: "GET",
        params: {
          vendor_id: args.vendorID,
        },
      }),
      transformResponse: (result: fetchAdditionalEvidenceForVendorResp) => {
        return produce(result, (draft) => {
          draft.evidence.active = draft.evidence.active.map((e) => ({
            ...e,
            kind: "evidence",
          }));
          draft.evidence.archived = draft.evidence.archived.map((e) => ({
            ...e,
            kind: "evidence",
          }));
          draft.sharedAdditionalEvidence.active =
            draft.sharedAdditionalEvidence.active.map((e) => ({
              ...e,
              kind: "shared_evidence",
            }));
          draft.sharedAdditionalEvidence.archived =
            draft.sharedAdditionalEvidence.archived.map((e) => ({
              ...e,
              kind: "shared_evidence",
            }));
        });
      },
      providesTags: (result) =>
        result
          ? [
              { type: managedVendorAssessmentEvidenceTag, id: result.vendorID },
              { type: vendorDataTag, id: result.vendorID },
            ]
          : [],
    }),

    // uploadAdditionalEvidenceForRequest
    // uploads a new additional evidence file as part of the managed vendor request flow
    // This should only be used in the context of the new request flow as it bypassed watch checks
    uploadAdditionalEvidenceForRequest: builder.mutation<
      { id: number; isWatched: boolean },
      {
        file: File;
        vendorID: number;
        documentTypeID: number;
        commentary: string;
        name: string;
      }
    >({
      query: (args) => {
        const data = new FormData();
        data.append("file", args.file);

        return {
          url: "vendor/managed_vendors/new_document/v1/",
          method: "POST",
          body: data,
          params: {
            vendor_id: args.vendorID,
            document_type_id: args.documentTypeID,
            name: args.name,
            commentary: args.commentary,
          },
        };
      },
      invalidatesTags: (result, _, args) =>
        result
          ? [{ type: managedVendorAssessmentEvidenceTag, id: args.vendorID }]
          : [],
      onQueryStarted: (args, { dispatch, queryFulfilled }) => {
        queryFulfilled.then((result) => {
          // if this vendor is watched then we want to refresh a couple of things
          if (result.data.isWatched) {
            dispatch(fetchAdditionalEvidenceForVendor(args.vendorID, true));
            dispatch(
              fetchVendorSummaryAndCloudscans(
                args.vendorID,
                true,
                false,
                false,
                false
              )
            );
          }
        });
      },
    }),

    getSurveyTypeListForAnalyst: builder.query<
      {
        surveyTypes: surveyType[];
      },
      void
    >({
      query: () => ({
        url: "survey/types/analyst/v1",
        method: "GET",
      }),
      providesTags: [managedVendorSurveyTypeTag],
    }),
  }),
});

export default ManagedVendorsAPI;
