import { FC, useEffect, useMemo, useRef, useState } from "react";
import { useAppDispatch } from "../types/reduxHooks";
import {
  OrgAccessVendors,
  usePermissions,
  UserBreachsightEnabled,
  UserVendorRiskEnabled,
  UserVendorRiskWrite,
  UserWriteUsers,
} from "../permissions";
import {
  Link,
  matchPath,
  Route,
  Router,
  Switch,
  useHistory,
  useLocation,
} from "react-router-dom";
import RouterTriggeredModal from "../../vendorrisk/components/modals/RouterTriggeredModal";
import HotjarWrapper from "../components/HotjarWrapper";
import HubspotWidgetWrapper from "../components/HubspotWidgetWrapper";
import TermsAndConditionsUpdateMessage from "../components/TermsAndConditionsUpdateMessage";
import "../style/components/AppContentContainer.scss";
import {
  getCurrentOrgFromUserData,
  isExpiredTrial,
  userCurrentOrgIsDistributor,
  userOrgIsFreeOrg,
} from "../helpers/userOrg.helpers";
import { AssuranceType, organisationAccountType } from "../types/organisations";
import {
  fetchOrgFreeTrialEligibility,
  retrieveOnboardingCalendarForUser,
} from "../reducers/freeTrial.actions";
import {
  fetchUserData,
  fetchUserInitiatedExports,
  loadInitialActivityStream,
  reloadCompleteActivityStream,
  setCyberRiskAuth,
  setTPVMSessionDetails,
  switchUserOrg,
} from "../reducers/commonActions";
import { hasPermissionFromUserData } from "../selectors/permissions";
import { fetchVendorImport } from "../../vendorrisk/reducers/vendorImport.actions";
import { fetchCustomerLimitData } from "../../vendorrisk/reducers/cyberRiskActions";
import TopLevelNavigation, {
  topLevelNavigationItem,
} from "../components/navigation/TopLevelNavigation";
import CommonRouter from "./CommonRouter";
import ErrorBoundary from "../components/ErrorBoundary";
import classnames from "classnames";
import ProductSpecificNavigation from "../components/navigation/ProductSpecificNavigation";
import { History } from "history";
import { product } from "../types/products";
import IconButton, { HoverLocation } from "../components/IconButton";
import DropdownV2, { DropdownV2Handle } from "../components/core/DropdownV2";
import ExpandableNavigationContainer from "../components/navigation/ExpandableNavigationContainer";
import HelpAndSupportDropdown from "../components/HelpAndSupportDropdown";
import { trackEvent } from "../tracking";
import { isAdmin } from "../../vendorrisk/helpers/roles";
import filledChevron from "../images/filled-chevron.svg";
import { MultiProductUserMenu } from "../components/navigation/MultiProductUserMenu";
import NamedRoleInviteModal from "../../vendorrisk/components/modals/NamedRoleInviteModal";
import { fetchOrgUserLimits } from "../../vendorrisk/reducers/org.actions";
import { SidePopupV2 } from "../components/DismissablePopup";
import { useManagedOrgID, useManagedVendorID, useVendorWords } from "../hooks";
import LoadingBanner from "../components/core/LoadingBanner";
import {
  useCurrentUser,
  useCurrentUserTasks,
} from "../selectors/commonSelectors";
import { ContactUsModal } from "../../vendorrisk/components/modals/ContactUsModal";
import PLGOnboardingFlow from "./PLGOnboardingFlow";
import OnboardingSteps, { shouldShowOnboardingScreen } from "./OnboardingSteps";
import { getUserLastActive } from "../session";

interface NavigationRouterWrapperProps {
  history: History;
}

// NavigationRouter - router to handle routes where standard navigation is hidden
export const NavigationRouter: FC<NavigationRouterWrapperProps> = (props) => {
  // Routes that should not show the standard site navigation components (Top Level Nav, Product Specific Nav, Action Bar)
  const routesWithNonStandardNavigation = [
    "/surveys/:id/answers",
    "/vendors/surveys/:id/answers",
    "/vendor/:vendorId/surveys/:id/answers",
    "/trustpage/surveys/:id/answers",
    "/sharedassessments/:vendorId/surveys/:id/answers",
    "/vendor/:vendorId/sharedassessment/surveys/:id/answers",
    "/vendor/:vendorId/sharedassessment/surveys/:id/byassessment/:assessmentId/answers",
    "/vendor/:vendorId/sharedassets/:sharedAssetOrgId/sharedassessment/surveys/:id/byassessment/:assessmentId/answers",
    "/vendor/:vendorId/sharedassets/vendor/surveys/:id/answers",
    "/vendor/:vendorId/sharedassets/:sharedAssetOrgId/surveys/:id/answers",
    "/analysts/tpvm/:orgId/:vendorId/surveys/:id/answers",
    "/analysts/tpvm/:orgId/:vendorId/sharedassessment/surveys/:id/answers",
    "/analysts/tpvm/:orgId/:vendorId/sharedassessment/surveys/:id/byassessment/:assessmentId/answers",
    "/analysts/tpvm/:orgId/:vendorId/questionnairepreview",
    "/analysts/tpvm/:orgId/:vendorId/:id/presendreview",
    "/questionnairepreview",
    "/quickstart",
  ];

  return (
    <Router history={props.history}>
      <Switch>
        {/* Routes with custom (or no) nav */}
        <Route path={routesWithNonStandardNavigation}>
          <AppContentContainer showNavigation={false} />
        </Route>
        <Route path="*">
          <AppContentContainer showNavigation={true} />
        </Route>
      </Switch>
    </Router>
  );
};

interface AppContainerProps {
  showNavigation?: boolean;
}

const AppContentContainer: FC<AppContainerProps> = (props) => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const location = useLocation();

  const userData = useCurrentUser();
  const currentOrg = getCurrentOrgFromUserData(userData);
  const permissions = usePermissions();

  const isUserAdmin = isAdmin(userData.currentOrgRoles);
  const currentOrgIsFreeOrg = userOrgIsFreeOrg(currentOrg);
  const currentOrgIsDistributor = userCurrentOrgIsDistributor(userData);

  const showSettings = isUserAdmin || currentOrgIsFreeOrg;

  const userTaskData = useCurrentUserTasks();

  const userTaskDataLoading = !userTaskData;

  const vendorWords = useVendorWords();

  const breachriskRoutes = [
    "/breachrisk",
    "/breachsight",
    "/breachrisk",
    "/dataleaks",
    "/detected_products",
    "/email_exposures",
    "/ips",
    "/riskwaivers",
    "/risk_profile",
    "/selfremediation",
    "/subsidiaries",
    "/summaryreport/breachsight",
    "/typosquatting",
    "/vulns",
    "/webscans",
    "/tasks/breachrisk",
  ];

  const vendorRiskRoutes = [
    "/concentrationrisk",
    "/managedvendors",
    "/remediation",
    "/sharedassessments",
    "/summaryreport/vendorrisk",
    "/surveybuilder/library",
    "/surveycollaboration",
    "/surveys",
    "/vendor/",
    "/vendorcomparison",
    "/vendordataleaks",
    "/vendorlist",
    "/vendor_portfolio",
    "/tasks/vendorrisk",
  ];

  const userRiskRoutes = ["/userbase", "/tasks/userrisk"];

  const trustExchangeRoutes = [
    "/trustpage",
    "/contentlibrary",
    "/vendors",
    "/tasks/trustexchange",
  ];

  const analystRoutes = ["/analysts"];

  const selectedProductMemo: product = useMemo<product>(() => {
    if (
      breachriskRoutes.some((route) => location?.pathname?.startsWith(route))
    ) {
      return "breachsight";
    } else if (
      vendorRiskRoutes.some((route) => location?.pathname?.startsWith(route))
    ) {
      return "vendor_risk";
    } else if (
      userRiskRoutes.some((route) => location?.pathname?.startsWith(route))
    ) {
      return "user_risk";
    } else if (
      trustExchangeRoutes.some((route) => location?.pathname?.startsWith(route))
    ) {
      return "trust_exchange";
    } else if (
      analystRoutes.some((route) => location?.pathname?.startsWith(route))
    ) {
      return "analyst_portal";
    }

    return undefined;
  }, [location?.pathname]);

  useEffect(() => {
    if (selectedProductMemo) {
      setProductSpecificNavOpen(true);
    }
  }, [selectedProductMemo]);

  const selectedItemMemo: topLevelNavigationItem =
    useMemo<topLevelNavigationItem>(() => {
      if (selectedProductMemo) {
        return selectedProductMemo;
      }

      if (
        matchPath(location?.pathname, "/home") ||
        matchPath(location?.pathname, "/homesettings") ||
        location?.pathname === "/"
      ) {
        return "home";
      }

      if (matchPath(location?.pathname, "/reportexports")) {
        return "reports";
      }

      if (matchPath(location?.pathname, "/breachnewsfeed")) {
        return "incidents_and_news";
      }

      return undefined;
    }, [location?.pathname, selectedProductMemo]);

  // Keep a ref to the mainContentArea so we can reset the scroll to the top when we change pages
  const mainContentAreaRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    mainContentAreaRef.current?.scrollTo(0, 0);

    // If the user invite modal is open, and we navigate away, close the modal)
    // This is required for the modal flow which navigates to the roles page
    if (userInviteModalOpen) {
      setUserInviteModalOpen(false);
    }

    // Set the TPVM session details when the path changes
    dispatch(setTPVMSessionDetails(location.pathname));
  }, [location?.pathname]);

  const [topLevelNavOpen, setTopLevelNavOpen] = useState(false);
  const [productSpecificNavOpen, setProductSpecificNavOpen] =
    useState(!!selectedProductMemo);

  const [userInviteModalOpen, setUserInviteModalOpen] = useState(false);
  const [helpDropdownOpen, setHelpDropdownOpen] = useState(false);
  const [userDropdownOpen, setUserDropdownOpen] = useState(false);
  const [contactUsModalOpen, setContactUsModalOpen] = useState(false);

  const [onboardingStepsDismissed, setOnboardingStepsDismissed] =
    useState(false);

  const onSettingsRoute = matchPath(location?.pathname, "/settings");

  const onManagedVendorRoute = matchPath<{ orgId: string; vendorId: string }>(
    location?.pathname,
    "/analysts/tpvm/:orgId/:vendorId"
  );
  // Get the managed Org and vendor IDs from Redux
  const managedOrgId = useManagedOrgID();
  const managedVendorId = useManagedVendorID();

  useEffect(() => {
    if (userData.currentOrgID) {
      const currentOrg = getCurrentOrgFromUserData(userData);
      if (currentOrg) {
        const isExpired = isExpiredTrial(currentOrg);
        const isTrial =
          currentOrg.accountType === organisationAccountType.trial &&
          currentOrg.trialExpiresOn;
        if (currentOrg.isVendorTrial || isTrial) {
          dispatch(retrieveOnboardingCalendarForUser(userData.id));
        }

        if (!isExpired) {
          dispatch(loadInitialActivityStream(false));
          dispatch(fetchUserInitiatedExports(false));

          if (
            userData.orgPermissions.includes(OrgAccessVendors) &&
            hasPermissionFromUserData(userData, UserVendorRiskWrite)
          ) {
            dispatch(fetchVendorImport());
          }

          if (hasPermissionFromUserData(userData, UserVendorRiskEnabled)) {
            dispatch(fetchCustomerLimitData());
          }
        }

        // kick off free-trial eligibility check
        if (currentOrg && currentOrg.accountType == "free") {
          dispatch(
            fetchOrgFreeTrialEligibility(
              userData.id,
              userData.currentOrgID,
              false
            )
          );
        }
      }
    }

    // Auto-refresh the activity stream every 1 minute
    const autoRefreshEvery = 1 * 60 * 1000;
    const autoRefreshInterval = setInterval(() => {
      const userLastActive = getUserLastActive();
      if (userLastActive > new Date().getTime() - autoRefreshEvery) {
        // refresh the activity stream for the current user so we can accurately display the tasks badge
        dispatch(reloadCompleteActivityStream());
      }
    }, autoRefreshEvery);

    dispatch(setTPVMSessionDetails(location.pathname));

    return () => {
      clearInterval(autoRefreshInterval);
    };
  }, []);

  let managedVendorSessionLoading = false;

  // If we are on a managed vendor route, we need to check if the managed vendor info on the path matches Redux
  // Otherwise, the VendorOverlayWrapper can perform a Vendor lookup and store it in the wrong place in Redux
  // Once we know the TPVM session is persisted, we can continue
  if (onManagedVendorRoute) {
    // Grab the OrgId and VendorId from the path
    const pathManagedOrgId = parseInt(onManagedVendorRoute.params.orgId);
    const pathManagedVendorId = parseInt(onManagedVendorRoute.params.vendorId);

    if (pathManagedOrgId > 0 && pathManagedVendorId > 0) {
      // We are definitely on a managed vendor route - we won't render the CommonRouter unless Redux state matches
      managedVendorSessionLoading =
        pathManagedOrgId !== managedOrgId ||
        pathManagedVendorId !== managedVendorId;
    }
  }

  // Forward refs for HelpAndSupport and UserMenu dropdowns
  // We'll use these to ensure only one is open at a time
  const helpDropdownRef = useRef<DropdownV2Handle>(null);
  const userDropdownRef = useRef<DropdownV2Handle>(null);

  // If this is an active PLG trial, and the user hasn't gone through the onboarding, show the onboarding flow
  const isPLGFreeTrial = currentOrg?.isFreeTrial;
  const trialExpired = isExpiredTrial(currentOrg);

  if (
    isPLGFreeTrial &&
    !trialExpired &&
    // those who have completed the flow will have at least the 1 free completed task
    !userData.plgOnboarding.completedTasks &&
    userData.assuranceType === AssuranceType.None
  ) {
    return (
      <PLGOnboardingFlow
        firstName={userData.firstName}
        lastName={userData.lastName}
        userEmail={userData.emailAddress}
        userPermissionBreachSight={hasPermissionFromUserData(
          userData,
          UserBreachsightEnabled
        )}
        userPermissionVendorRisk={hasPermissionFromUserData(
          userData,
          UserVendorRiskEnabled
        )}
        userPermissionWriteUsers={hasPermissionFromUserData(
          userData,
          UserWriteUsers
        )}
      />
    );
  }

  // Check if we should show the regular onboarding flow
  if (
    !onboardingStepsDismissed &&
    userData.assuranceType === AssuranceType.None &&
    !userData.plgOnboarding.completedTasks &&
    !isPLGFreeTrial &&
    shouldShowOnboardingScreen(userData.onboardingSteps || {})
  ) {
    return (
      <OnboardingSteps
        dispatch={dispatch}
        history={history}
        dismissOnboardingSteps={() => {
          setOnboardingStepsDismissed(true);

          // refresh user data only after the steps have been dismissed or they will update while open
          dispatch(fetchUserData());
        }}
        onboardingSteps={userData.onboardingSteps}
      />
    );
  }

  return (
    <>
      <div
        id="app-content-container"
        className={classnames({ "nav-open": props.showNavigation })}
      >
        {props.showNavigation && (
          <>
            <div id="navigation-container">
              <ExpandableNavigationContainer
                className="top-level-navigation-container"
                isOpen={topLevelNavOpen}
                onToggleNavigationOpen={(isOpen) => setTopLevelNavOpen(isOpen)}
                expanderToolTipWidth={topLevelNavOpen ? 130 : 140}
                expanderToolTip={
                  topLevelNavOpen ? "Collapse menu" : "Keep menu open"
                }
              >
                <TopLevelNavigation
                  open={topLevelNavOpen}
                  selectedItem={selectedItemMemo}
                />
              </ExpandableNavigationContainer>
              {selectedProductMemo && (
                <ExpandableNavigationContainer
                  className={"product-specific-navigation-container"}
                  isOpen={productSpecificNavOpen}
                  onToggleNavigationOpen={(isOpen) =>
                    setProductSpecificNavOpen(isOpen)
                  }
                  disableFader={true}
                  fullContainerClickableWhenClosed={true}
                  expanderToolTipWidth={productSpecificNavOpen ? 130 : 120}
                  expanderToolTip={
                    productSpecificNavOpen ? "Collapse menu" : "Expand menu"
                  }
                >
                  <ProductSpecificNavigation
                    open={productSpecificNavOpen}
                    selectedProduct={selectedProductMemo}
                    openContactUsModal={() => setContactUsModalOpen(true)}
                  />
                </ExpandableNavigationContainer>
              )}
            </div>
          </>
        )}
        <div
          id="app-content"
          className={classnames(
            { "top-level-nav-open": props.showNavigation && topLevelNavOpen },
            {
              "product-level-nav-open":
                props.showNavigation &&
                selectedProductMemo &&
                productSpecificNavOpen,
            },
            {
              "product-level-nav-shown":
                props.showNavigation && selectedProductMemo,
            },
            { "nav-shown": props.showNavigation }
          )}
        >
          {props.showNavigation && (
            <div id="user-actions-bar">
              <div
                className={classnames("user-actions-bar-icon", {
                  open: helpDropdownOpen,
                })}
              >
                <SidePopupV2
                  className="help-and-support-dropdown-tooltip"
                  text={helpDropdownOpen ? undefined : "Help & Support"} // Don't show hover text if the dropdown is open
                  position="bottom"
                  micro={true}
                  width={105}
                  transitionTimeout={{
                    appear: 250,
                    enter: 0,
                    exit: 0,
                  }}
                  popupDelay={600}
                >
                  <HelpAndSupportDropdown
                    dropDownRef={helpDropdownRef}
                    dispatch={dispatch}
                    history={history}
                    userInNonFreeAccount={
                      userData.currentOrgID > 0 &&
                      getCurrentOrgFromUserData(userData)?.accountType !==
                        organisationAccountType.free
                    }
                    popupItem={
                      <div className="help-support-btn">
                        <div className="cr-icon-message-question-checkmark" />
                      </div>
                    }
                    orgHasMultiProductNav={true}
                    onActiveChange={(active) => {
                      setHelpDropdownOpen(active);

                      if (active && userDropdownOpen) {
                        userDropdownRef.current?.setOpen(false);
                      }
                    }}
                  />
                </SidePopupV2>
              </div>
              {showSettings && (
                <div className="user-actions-bar-icon">
                  <IconButton
                    key="invite-user"
                    icon={<div className="cr-icon-single-user-add-plus" />}
                    onClick={() => {
                      setUserInviteModalOpen(true);
                    }}
                    hoverText={"Invite users"}
                    hoverLocation={HoverLocation.Bottom}
                    hoverMicro={true}
                  />
                </div>
              )}
              {showSettings && (
                <div
                  className={classnames("user-actions-bar-icon", {
                    open:
                      onSettingsRoute && !userDropdownOpen && !helpDropdownOpen,
                  })}
                >
                  <Link
                    to={"/settings"}
                    onClick={() => {
                      trackEvent("navigation menu item clicked", {
                        pathTo: "/settings",
                        linkName: "Settings",
                      });
                    }}
                  >
                    <IconButton
                      key="settings"
                      icon={<div className="cr-icon-settings" />}
                      hoverText={"Settings"}
                      hoverLocation={HoverLocation.Bottom}
                      hoverMicro={true}
                    />
                  </Link>
                </div>
              )}
              <div className="user-actions-divider" />
              <DropdownV2
                ref={userDropdownRef}
                className="user-menu-dropdown"
                popupItem={
                  <div className="user-details">
                    <div className="name-and-org">
                      <div
                        className="org"
                        title={getCurrentOrgFromUserData(userData)?.name}
                      >
                        {getCurrentOrgFromUserData(userData)?.name}
                      </div>
                      <div className="name" title={userData?.emailAddress}>
                        {userData?.emailAddress}
                      </div>
                    </div>
                    <img
                      src={filledChevron}
                      alt="chevron"
                      className={classnames("user-menu-dropdown-chevron", {
                        open: userDropdownOpen,
                      })}
                    />
                  </div>
                }
                onActiveChange={(active) => {
                  setUserDropdownOpen(active);

                  if (active && helpDropdownOpen) {
                    helpDropdownRef.current?.setOpen(false);
                  }
                }}
              >
                <MultiProductUserMenu
                  userData={userData}
                  onLogoutClick={() => {
                    dispatch(setCyberRiskAuth({ userLogout: true }, null));
                  }}
                  onSwitchOrgClick={(orgId: number) => {
                    dispatch(switchUserOrg(orgId, history));
                  }}
                />
              </DropdownV2>
            </div>
          )}
          <div id="main-content-area" ref={mainContentAreaRef}>
            <div id="main-content-area-inner">
              {managedVendorSessionLoading || userTaskDataLoading ? (
                <LoadingBanner />
              ) : (
                <ErrorBoundary pathname={history?.location?.pathname}>
                  <CommonRouter
                    history={history}
                    dispatch={dispatch}
                    userData={userData}
                    permissions={permissions.permissions}
                    userTaskData={userTaskData}
                  />
                  {/* Add a div to include some bottom padding after the content (I can't get this to work without adding the div) */}
                  <div className="main-content-area-inner-bottom-padding" />
                </ErrorBoundary>
              )}
            </div>
          </div>
        </div>
      </div>
      <RouterTriggeredModal />
      <HotjarWrapper />
      <HubspotWidgetWrapper />
      <TermsAndConditionsUpdateMessage />
      <NamedRoleInviteModal
        active={userInviteModalOpen}
        onClose={() => {
          setUserInviteModalOpen(false);
          dispatch(fetchOrgUserLimits());
        }}
        adminRolesOnly={false}
        isDistributorView={currentOrgIsDistributor}
        returnTo={{
          backTo: location?.pathname,
          backToText: "Back",
        }}
      />
      {/* Vendor Limit Increase contact modal */}
      <ContactUsModal
        active={contactUsModalOpen}
        onClose={() => setContactUsModalOpen(false)}
        onContactSent={() => {
          setContactUsModalOpen(true);
        }}
        title={"Increase your " + vendorWords.singular + " limit"}
        message={
          "Let us know if you'd like to add more " +
          vendorWords.plural +
          " and we'll be in touch."
        }
        team="customer success"
        onwardTopic="expand"
        onwardMessage="Increase monitored vendor limit."
      />
    </>
  );
};
