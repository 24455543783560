import InfoBanner, { BannerType } from "../InfoBanner";
import { liquidSyntaxGuideURL } from "../../views/CreateIntegration";
import XTable, { XTableCell } from "../../../_common/components/core/XTable";
import ClipboardIcon from "../../images/clipboard-icon.svg";
import {
  GetExampleMessageVariables,
  ToClipboard,
} from "./ConfigureSlackMessageFormatsStep";
import { addMessageAlert } from "../../../_common/reducers/messageAlerts.actions";
import { IOrgAlertDefinition } from "../../reducers/org.actions";
import { useAppDispatch } from "../../../_common/types/reduxHooks";

interface IMessageCustomizationHelpProps {
  selectedTestJSON: any;
  selectedTestUUID: string;
  notificationsByUUID: Record<string, IOrgAlertDefinition | undefined>;
}

const MessageCustomizationHelp = ({
  notificationsByUUID,
  selectedTestJSON,
  selectedTestUUID,
}: IMessageCustomizationHelpProps) => {
  let messageVariables = GetExampleMessageVariables(
    notificationsByUUID,
    selectedTestUUID
  );

  const dispatch = useAppDispatch();

  const arrayVariables = messageVariables.filter((v) => {
    return v.example === "array";
  });
  messageVariables = messageVariables.filter((v) => {
    return v.example !== "array";
  });
  messageVariables.push(...arrayVariables);

  return (
    <>
      <div className={"section-title noline"}>
        <div className="main-title left-padding">
          How do I customize this notification?
        </div>
      </div>

      <div className={"payload-line noline"}>
        <InfoBanner
          className={"messaging-info"}
          type={BannerType.INFO}
          message={
            "UpGuard uses Liquid, an open-source template language to handle variables in messaging. View our help article to find out more."
          }
          linkText={"Learn more"}
          linkURL={liquidSyntaxGuideURL}
        />
      </div>

      <div className={"section-title noline"}>
        <div className="title left-padding">Variables</div>
      </div>
      <div className={"payload-line noline"}>
        <div className={"instruction-text nospacing"}>
          {
            "Your message can use dynamic content from each trigger by using the variables outlined below. Simply copy and paste variables into your message to use them."
          }
          {
            "These messages have full support for the liquid template language. Consult the documentation for details on using more advanced types such as arrays."
          }
        </div>
      </div>
      <div className={"noline nospacing"}>
        <XTable
          className={"var-table"}
          columnHeaders={[
            { id: "name", text: "Name" },
            {
              id: "example",
              text: "Sample value",
            },
            { id: "liquid", text: "Variable" },
            { id: "icon", text: "" },
          ]}
          rows={messageVariables.map((v) => {
            return {
              id: v.name,
              cells: [
                <XTableCell key="name" className={"var-name-cell"}>
                  {v.name}
                </XTableCell>,
                <XTableCell key="sample" className="var-sample-cell">
                  {v.example != "array" ? v.example : <i>{"Array"}</i>}
                </XTableCell>,
                <XTableCell key="var" className={"var-path-cell"}>
                  {v.example != "array" && (
                    <div className={"liquid-example"}>{v.path}</div>
                  )}
                  {v.example == "array" && (
                    <div className={"array-example"}>
                      {"View example payload data to see structure"}
                    </div>
                  )}
                </XTableCell>,
                <XTableCell key="icon">
                  <div className={"icon"}>
                    <img
                      src={ClipboardIcon}
                      onClick={() => {
                        ToClipboard(v.path);
                        dispatch(
                          addMessageAlert({
                            message: `Value copied to clipboard`,
                            type: BannerType.INFO,
                            hideDelay: 3000,
                          })
                        );
                      }}
                    />
                  </div>
                </XTableCell>,
              ],
            };
          })}
        />
      </div>
      <div className={"section-title noline"}>
        <div className="title left-padding">Example payload data</div>
      </div>
      <div className={"payload-line"}>
        <div className={"instruction-text"}>
          Use the example below to see the available fields and their formats.
        </div>
        <div className={"sample-data"}>
          {selectedTestUUID && selectedTestJSON && (
            <>{JSON.stringify(selectedTestJSON, null, "  ")}</>
          )}
          {(!selectedTestUUID || !selectedTestJSON) && (
            <>{"no trigger selected"}</>
          )}
        </div>
      </div>
    </>
  );
};

export default MessageCustomizationHelp;
