import { useLayoutEffect } from "react";
import { setScrollTargetNode } from "../reducers/actions";
import { useAppDispatch, useAppSelector } from "../../_common/types/reduxHooks";
import { debounce } from "lodash";

interface SurveyViewerContentScrollerProps {
  // Ref of parent containing the .questionnaire-content element
  parentRef: React.RefObject<HTMLDivElement>;
}

const surveyContentClass = ".questionnaire-content";
const surveyQuestionClass = ".question-answer-node";

// Component for triggering programmatic scrolling, seperated to reduce DOM tree re-renders.
const SurveyViewerContentScroller = (
  props: SurveyViewerContentScrollerProps
) => {
  const targetNodeId = useAppSelector(
    (state) => state.surveyViewer.scrollTargetNodeId
  );
  const dispatch = useAppDispatch();

  useLayoutEffect(
    function scrollToTargetNode() {
      const parentElement = props.parentRef.current;
      const parentContent =
        parentElement?.querySelector<HTMLDivElement>(surveyContentClass);
      const targetNode = parentElement?.querySelector<HTMLDivElement>(
        `${surveyQuestionClass}[data-node-id="${targetNodeId}"]`
      );

      if (targetNode && parentContent) {
        const scrollEventHandler = debounce(
          function () {
            // Remove the event handler because we only want to do this once
            parentContent.removeEventListener("scroll", scrollEventHandler);
            dispatch(setScrollTargetNode(undefined, false));
          },
          800,
          { leading: false, trailing: true }
        );

        // This timeout provides a little extra time to complete rendering
        // layout / etc.
        // When network latency is involved, scrollTo would stall part-way
        // and fail to reach the target question node.
        setTimeout(
          () =>
            parentContent.scrollTo({
              top: targetNode.offsetTop - 200,
              left: 0,
              behavior: "smooth",
            }),
          350
        );

        // Make sure we only clear the target once scrolling has finished.
        //
        // scrollEventHandler uses a trailing debounce to achieve this
        //
        // The scroll event will fire many, many times in fast succession
        // but the debounced event handler will ignore all of those until
        // 850ms has elapsed after a call, and finally execute the handler
        // logic only one time at the end.
        // Because the debounced function _technically_ runs many times
        // (many scroll events occur), we cannot use the "once" option here
        parentContent.addEventListener("scroll", scrollEventHandler);

        return () => {
          scrollEventHandler.cancel();
          parentContent.removeEventListener("scroll", scrollEventHandler);
        };
      }
      return () => {};
    },
    [targetNodeId]
  );

  return <></>;
};

export default SurveyViewerContentScroller;
