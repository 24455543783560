import { LabelColor } from "../../../_common/types/label";
import { FC, useEffect, useState } from "react";
import {
  OptionType,
  SelectV2Multi,
} from "../../../_common/components/SelectV2";
import { SlidePanelSection } from "./SlidePanelSection";
import ColorCheckbox from "../ColorCheckbox";
import Icon from "../../../_common/components/core/Icon";
import { ValueType } from "react-select";
import PillLabel from "../PillLabel";
import { DefaultThunkDispatch } from "../../../_common/types/redux";
import { searchAssessmentAuthorList } from "../../reducers/vendorAssessment.actions";
import { IVendorWords } from "../../../_common/constants";
import { VendorAssessmentAuthor } from "../../types/vendorAssessments";
import { SidePopupV2 } from "../../../_common/components/DismissablePopup";

export interface AuthorFilterChangeObj {
  selectedValues?: number[];
  doNotMatch?: boolean;
}

interface IVendorAssessmentAuthorFilterProps {
  title?: string;
  selectedValues: number[];
  doNotMatch?: boolean;
  onChange: (changeObj: AuthorFilterChangeObj) => void;
  dispatch: DefaultThunkDispatch;
  vendorWords: IVendorWords;
  startExpanded?: boolean;
}

const VendorAssessmentAuthorFilter: FC<IVendorAssessmentAuthorFilterProps> = ({
  title = "Assessment author",
  onChange,
  selectedValues = [],
  dispatch,
  doNotMatch = false,
  startExpanded = false,
  vendorWords,
}) => {
  const [expanded, setExpanded] = useState(
    startExpanded || selectedValues.length > 0
  );
  const [valueMap, setValueMap] = useState<{
    [id: number]: string;
  }>({});
  const [availableValues, setAvailableValues] = useState<
    VendorAssessmentAuthor[]
  >([]);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    setIsLoading(true);
    dispatch(searchAssessmentAuthorList()).then((values) => {
      if (!values) {
        values = [];
      }
      setAvailableValues(values);
      setIsLoading(false);
      const map = {} as {
        [id: number]: string;
      };
      for (let idx = 0; idx < values.length; idx++) {
        map[values[idx].id] = values[idx].name;
      }
      setValueMap(map);
    });
  }, []);

  const activeRadioButton = doNotMatch ? "not" : "any";

  const selectOnChange = (selectedValues: ValueType<OptionType, true>) => {
    const changeObj: AuthorFilterChangeObj = {
      selectedValues: selectedValues
        ? selectedValues.map(({ value }) => value as number)
        : [],
      doNotMatch: activeRadioButton === "not",
    };

    onChange(changeObj);
  };

  const removeClick = (value: number) => {
    const changeObj: AuthorFilterChangeObj = {
      selectedValues: selectedValues.filter((v) => v !== value),
      doNotMatch: activeRadioButton === "not",
    };
    onChange(changeObj);
  };

  const selectValue: OptionType[] = [
    ...selectedValues.map((v) => {
      const label = valueMap[v];
      return { value: v, label: label };
    }),
  ];

  const customStyles = {
    option: (provided: any, state: any) => {
      if (state.data.value === "") {
        return { ...provided, fontStyle: "italic" };
      }
      return { ...provided };
    },
  };

  return (
    <SlidePanelSection
      title={title}
      expanded={expanded}
      toggleExpand={() => setExpanded(!expanded)}
    >
      <div className="filter-label-options">
        <div className="label-filter-checkbox">
          <ColorCheckbox
            radio
            label="Match any"
            onClick={() =>
              onChange({
                doNotMatch: false,
                selectedValues: selectedValues,
              })
            }
            checked={activeRadioButton === "any"}
          />
          <SidePopupV2
            className="help-icon"
            text={`Show ${vendorWords.plural} that were last assessed by any of these authors`}
            position={"left"}
          >
            <Icon name="info" />
          </SidePopupV2>
        </div>
        <div className="label-filter-checkbox">
          <ColorCheckbox
            radio
            label="Do not include"
            onClick={() =>
              onChange({
                doNotMatch: true,
                selectedValues: selectedValues,
              })
            }
            checked={activeRadioButton === "not"}
          />
          <SidePopupV2
            className="help-icon"
            text={`Show ${vendorWords.plural} whose last assessment was not performed by any of these authors`}
            position={"left"}
          >
            <Icon name="info" />
          </SidePopupV2>
        </div>
      </div>
      <SelectV2Multi
        isMulti
        placeholder={`Type to search...`}
        options={[
          { label: "No value", value: "" },
          ...availableValues.map((value) => ({
            label: value.name,
            value: value.id,
          })),
        ]}
        value={selectValue}
        onChange={selectOnChange}
        controlShouldRenderValue={false}
        captureMenuScroll={false}
        isLoading={isLoading}
        styles={customStyles}
      />
      <div className={"value-list"}>
        {selectedValues.map((v) => (
          <PillLabel
            key={v}
            color={LabelColor.Blue}
            removeable
            onRemoveClick={() => removeClick(v)}
            className={"attribute-value"}
            capitalized={false}
          >
            {v && valueMap[v] ? valueMap[v] : "No value"}
          </PillLabel>
        ))}
      </div>
    </SlidePanelSection>
  );
};

export default VendorAssessmentAuthorFilter;
