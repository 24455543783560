import { grabTPVMSession } from "../../_common/reducers/commonActions";
import { FetchCyberRiskUrl } from "../../_common/api";
import { LogError } from "../../_common/helpers";
import { SET_VENDOR_ASSESSMENT_RISK_HOSTNAMES } from "./cyberRiskActions";
import { DefaultAction, DefaultThunkDispatch } from "../../_common/types/redux";
import { ITpvmSession } from "../../_common/types/thirdPartyMangedVendors";
import {
  AssessmentRiskHostnames,
  VendorAssessmentAuthor,
  VendorAssessmentClassificationMap,
} from "../types/vendorAssessments";
import moment from "moment";
import { addDefaultUnknownErrorAlert } from "../../_common/reducers/messageAlerts.actions";
import { DefaultRootState } from "react-redux";
import { Filters } from "../components/filter/types";
import VendorAssessmentApi from "./vendorAssessmentAPI";

export const setVendorAssessmentRiskHostnames = (
  vendorId: number,
  riskHostnames: AssessmentRiskHostnames,
  tpvmSession: ITpvmSession
) => ({
  type: SET_VENDOR_ASSESSMENT_RISK_HOSTNAMES,
  vendorId,
  riskHostnames,
  isManagementAnalystSession: tpvmSession && tpvmSession.tpvm,
  managedOrgId: tpvmSession ? tpvmSession.tpvm_o : 0,
});

export const SET_VENDOR_ASSESSMENT_CLASSIFICATIONS =
  "SET_VENDOR_ASSESSMENT_CLASSIFICATIONS";

export const SET_VENDOR_ASSESSMENT_AUTHORS = "SET_VENDOR_ASSESSMENT_AUTHORS";

export const setVendorAssessmentClassifications = (
  classifications: VendorAssessmentClassificationMap
) => {
  return {
    type: SET_VENDOR_ASSESSMENT_CLASSIFICATIONS,
    classifications,
  };
};

export const setVendorAssessmentAuthors = (
  authors: VendorAssessmentAuthor[]
) => {
  return {
    type: SET_VENDOR_ASSESSMENT_AUTHORS,
    authors,
  };
};

export interface AssessmentDraftUpdate {
  commentary: string;
  includeWebsiteRisks: boolean;
  includeWaivers: boolean;
  editInProgressAt: string;
  withCustomer: boolean;
  customerCommentary: string;
  hideIntroduction: boolean;
  hideBackground: boolean;
  hideCommentary: boolean;
  hideConclusion: boolean;
}

export const updateAssessmentDraftData =
  (
    vendorID: number,
    assessmentID: number,
    update: Partial<AssessmentDraftUpdate>
  ): DefaultAction =>
  async (dispatch, getState) => {
    VendorAssessmentApi.endpoints.getVendorAssessmentData.select({
      vendorID,
      versionID: assessmentID,
    })(getState() as any);
    const { data } =
      VendorAssessmentApi.endpoints.getVendorAssessmentData.select({
        vendorID,
        versionID: assessmentID,
      })(getState() as any);
    const assessment = data?.assessment;

    if (!!assessment) {
      // update redux to update UI
      const patch = dispatch(
        VendorAssessmentApi.util.updateQueryData(
          "getVendorAssessmentData",
          { vendorID, versionID: assessmentID },
          (draft) => {
            if (update.includeWebsiteRisks != undefined) {
              draft.assessment.includeWebsiteRisks = update.includeWebsiteRisks;
            }
            if (update.includeWaivers != undefined) {
              draft.assessment.includeWaivers = update.includeWaivers;
            }
            if (update.commentary != undefined) {
              draft.assessment.commentary = update.commentary;
            }
            if (update.customerCommentary != undefined) {
              draft.assessment.customerCommentary = update.customerCommentary;
            }
            draft.assessment.updatedAt = moment().format();
          }
        )
      );

      try {
        await FetchCyberRiskUrl(
          "vendor/assessment/draft/v1/",
          {
            assessment_id: assessmentID,
            include_website_risks:
              update?.includeWebsiteRisks ?? assessment.includeWebsiteRisks,
            include_waivers:
              update?.includeWaivers ?? assessment.includeWaivers,
            update_assessment_commentary: !!update.commentary,
            update_customer_commentary: !!update.customerCommentary,
          },
          {
            method: "POST",
            body: JSON.stringify({
              assessment_commentary: update?.commentary ?? "",
              customer_commentary: update?.customerCommentary ?? "",
            }),
          },
          dispatch,
          getState
        );
      } catch (e) {
        patch.undo();
        LogError("Error updating assessment draft data", e);
        throw e;
      }
    } else {
      dispatch(addDefaultUnknownErrorAlert("error updating assessment state"));
    }
  };

export const refreshEvidenceForLatestAssessment =
  (vendorID: number): DefaultAction =>
  async (dispatch) => {
    // TODO - make sure this works properly
    dispatch(
      VendorAssessmentApi.util.invalidateTags([
        { type: "VendorAssessmentLatestDraft", id: vendorID },
      ])
    );
  };

export const fetchVendorAssessmentRiskHostnames =
  (
    datastoreVendorId: number,
    assessmentId: number,
    riskId: string
  ): DefaultAction =>
  async (dispatch, getState) => {
    const loadedRiskHostnames = getVendorAssessmentRiskHostnamesFromState(
      getState(),
      datastoreVendorId,
      assessmentId,
      riskId
    );
    if (loadedRiskHostnames && !loadedRiskHostnames.hostnamesSnapshotPending) {
      return;
    }

    try {
      const risk = await FetchCyberRiskUrl<AssessmentRiskHostnames>(
        "vendor/assessment/risks/hostnames/v1/",
        {
          assessment_id: assessmentId,
          risk_id: riskId,
        },
        {
          method: "GET",
        },
        dispatch,
        getState
      );

      const tpvmSession = grabTPVMSession(getState);
      dispatch(
        setVendorAssessmentRiskHostnames(datastoreVendorId, risk, tpvmSession)
      );

      // Check if the snapshot is still pending for this risk. If
      // it is, make another request in 5 seconds to see if the
      // snapshot is ready. This state should only last for a short
      // period immediately after a risk assessment is published.
      if (risk?.hostnamesSnapshotPending) {
        setTimeout(
          () =>
            dispatch(
              fetchVendorAssessmentRiskHostnames(
                datastoreVendorId,
                assessmentId,
                riskId
              )
            ),
          5000
        );
      }
    } catch (e) {
      LogError("error retrieving vendor assessment risk hostnames", e);
      throw e;
    }
  };

export const getVendorAssessmentRiskHostnamesFromState = (
  state: DefaultRootState,
  datastoreVendorId: number,
  assessmentId: number,
  riskId: string
): AssessmentRiskHostnames | undefined => {
  const tpvmSession = grabTPVMSession(() => state);

  if (tpvmSession && tpvmSession.tpvm) {
    return state.cyberRisk.managedVendorData[tpvmSession.tpvm_o as any][
      tpvmSession.tpvm_v as any
    ].assessmentRiskHostnames?.[assessmentId]?.[riskId];
  } else {
    return state.cyberRisk.vendors[datastoreVendorId]
      ?.assessmentRiskHostnames?.[assessmentId]?.[riskId];
  }
};

export const fetchAssessmentBreakdown = (filters?: Filters) => {
  return async (
    dispatch: DefaultThunkDispatch,
    getState: () => DefaultRootState
  ) => {
    const { filters: currentFilters } = getState().cyberRisk.customerData;
    const newFilters = filters || currentFilters;
    let json: VendorAssessmentClassificationMap;

    try {
      json = await FetchCyberRiskUrl<VendorAssessmentClassificationMap>(
        "assessment_breakdown/v2/",
        {
          vendor_label_ids: newFilters.vendorLabelIds,
          vendor_label_ids_match_all: newFilters.vendorLabelIdsMatchAll,
          vendor_label_ids_do_not_match: newFilters.vendorLabelIdsDoNotMatch,
          include_unlabeled: newFilters.includeUnlabeled,
          min_score: newFilters.minScore,
          max_score: newFilters.maxScore,
          name_prefix: newFilters.namePrefix,
          vendor_tiers: newFilters.vendorTiers,
          // exclude vendor classifications on purpose
          // vendor_assessment_classifications: newFilters.vendorAssessmentClassifications,
          vendor_assessment_authors: newFilters.vendorAssessmentAuthors,
          vendor_assessment_authors_not:
            newFilters.vendorAssessmentAuthorDoNotMatch,
          vendor_reassessment_start: newFilters.vendorReassessmentStartDate,
          vendor_reassessment_end: newFilters.vendorReassessmentEndDate,
          vendor_reassessment_before: newFilters.vendorReassessmentDateBefore,
          vendor_reassessment_between: newFilters.vendorReassessmentDateBetween,
          portfolio_ids: newFilters.portfolioIds,
          // since the attribute filters are complex we serialise them to json
          attributes: JSON.stringify(newFilters.selectedAttributes),
          survey_type_ids: newFilters.vendorSurveyTypes,
          evidence_type_ids: newFilters.vendorEvidenceTypes,
          fourth_party_product_uuids: newFilters.fourthPartyProductUUIDs,
        },
        { method: "GET" },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("error fetching vendor assessment breakdown", e);
      dispatch(
        addDefaultUnknownErrorAlert(
          "Error retrieving vendor assessment breakdown"
        )
      );

      throw e;
    }

    // Set redux state
    dispatch(setVendorAssessmentClassifications(json));

    return json;
  };
};

export const searchAssessmentAuthorList = () => {
  return async (
    dispatch: DefaultThunkDispatch,
    getState: () => DefaultRootState
  ): Promise<VendorAssessmentAuthor[]> => {
    if (getState().cyberRisk.vendorAssessmentAuthors) {
      return getState().cyberRisk.vendorAssessmentAuthors || [];
    }

    let resp: VendorAssessmentAuthor[];

    try {
      resp = await FetchCyberRiskUrl<VendorAssessmentAuthor[]>(
        "assessment_author/v2/",
        {},
        null,
        dispatch,
        getState,
        undefined,
        undefined
      );
    } catch (e) {
      console.error(e);
      throw e;
    }

    dispatch(setVendorAssessmentAuthors(resp));
    return resp;
  };
};

export type VendorAssessmentCopyUpdateType =
  | "intro"
  | "commentary"
  | "background"
  | "conclusion"
  | "customerCommentary";

interface UpdateCopyForVendorAssessmentV1Req {
  id: number;
  updateType: string;
  text: string;
}

export const UpdateCopyForVendorAssessmentV1 =
  (req: UpdateCopyForVendorAssessmentV1Req): DefaultAction =>
  async (dispatch, getState) => {
    try {
      await FetchCyberRiskUrl(
        "vendor/assessment/edit/copy/v1",
        {},
        { method: "PUT", body: JSON.stringify(req) },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("error updating vendor assessment copy", e);
      throw e;
    }
  };

export const downloadUpGuardPublishedAssessment =
  (vendorID: number, uuid: string): DefaultAction =>
  async (dispatch, getState) => {
    try {
      await FetchCyberRiskUrl(
        "vendor/assessment/upguardpublished/v1",
        {
          vendor_id: vendorID,
          uuid,
        },
        null,
        dispatch,
        getState
      );
    } catch (e) {
      console.error(e);
      dispatch(
        addDefaultUnknownErrorAlert(
          "An unknown error occurred downloading this file. Please contact UpGuard Support."
        )
      );
    }
  };
