export enum JobStatus {
  Created,
  Pending,
  Complete,
  Error,
  Cancelled,
}

export interface Job<TJobMeta, TJobResult> {
  id: number;
  jobType: string;
  jobMeta: TJobMeta;
  result?: TJobResult;
  status: JobStatus;
  errorCount: number;
  progress: number;
  log: string[];
  dismissed: boolean;
  createdBy?: string;
  createdAt: string;
  startedAt?: string;
  completedAt?: string;
  scheduledJobId?: number;
}

// Vendor Import Meta's

export interface VendorImportJobMeta {
  orgId: number;
  userId: number;
  vendorUpdateDetails: {
    [domain: string]: {
      labels: string[];
      tier?: number;
      vendorName: string;
      vendorWebless: boolean;
    };
  };
  overwriteExistingLabels: boolean;
  overwriteExistingTiers: boolean;
}

export interface VendorImportProcessedItem {
  vendorName: string;
  labelsApplied?: string[];
  tierApplied?: number;
  tierCleared?: boolean;
  alreadyWatched: boolean;
  primaryHostname: string;
  datastoreVendorId: number;
  requestedHostnames: string[];
  watchLimitExceeded: boolean;
  portfolioIDs?: number[];
  attributesApplied?: { [key: number]: string };
  attributesCleared?: number[];
  vendorWebless: boolean;
}

export interface VendorImportPostCompletionChanges {
  vendorsDeleted: { [vendorId: number]: boolean };
}

export interface VendorImportJobResult {
  failedHosts: string[];
  processedItems: VendorImportProcessedItem[];
  postCompletionChanges?: VendorImportPostCompletionChanges;
}

// Bulk Upload Custom Domains

export interface BulkUploadCustomDomainsJobMeta {
  orgId: number;
  orgName: string;
  userId: number;
  domains: string[];
  ips: string[];
}

export interface BulkUploadCustomDomainsJobResult {
  failedDomains: string[];
  addedDomains: string[];
  existingDomains: string[];
  failedIPs: string[];
  addedIPs: string[];
  existingIPs: string[];
  orgName: string;
  orgId: number;
}
