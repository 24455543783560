import {
  conditionalRefreshActivityStreamForOrgUser,
  grabTPVMSession,
} from "../../_common/reducers/commonActions";
import { FetchCyberRiskUrl } from "../../_common/api";
import { LogError } from "../../_common/helpers";
import {
  addDocumentForDetailedAdditionalEvidence,
  getVendorData,
  setAdditionalEvidenceForVendor,
} from "./cyberRiskActions";
import { DefaultAction } from "../../_common/types/redux";
import { get as _get } from "lodash";
import { addDefaultUnknownErrorAlert } from "../../_common/reducers/messageAlerts.actions";
import {
  IAdditionalEvidenceDocument,
  AdditionalEvidenceHistory,
  AdditionalEvidenceSummary,
  AdditionalEvidenceType,
  IAdditionalEvidenceHistoryDocumentMap,
  IDocumentRequest,
  SharedAdditionalEvidence,
  AdditionalEvidenceUser,
} from "../types/additionalEvidence";
import { IUserMiniMap } from "../../_common/types/user";
import { IRecipient } from "../../_common/types/vendorContact";

export interface fetchAdditionalEvidenceForVendorResp {
  status: string;
  vendorID: number;
  evidence: {
    active: AdditionalEvidenceSummary[];
    archived: AdditionalEvidenceSummary[];
  };
  sharedAdditionalEvidence: {
    active: SharedAdditionalEvidence[];
    archived: SharedAdditionalEvidence[];
  };
}

export const fetchAdditionalEvidenceForVendor = (
  vendorId: number,
  force: boolean
): DefaultAction => {
  return async (dispatch, getState) => {
    const tpvmSession = grabTPVMSession(getState);
    const vendor = getVendorData(getState, vendorId, false, tpvmSession);

    if (!force) {
      const existing = _get(vendor, "evidence");
      if (existing && !existing.error) {
        return;
      }
    }

    dispatch(
      setAdditionalEvidenceForVendor(
        true,
        vendorId,
        null,
        null,
        null,
        tpvmSession
      )
    );
    let json: fetchAdditionalEvidenceForVendorResp;
    try {
      json = await FetchCyberRiskUrl(
        "vendor/evidencelist/v1/",
        {
          vendor_id: vendorId,
        },
        null,
        dispatch,
        getState
      );
    } catch (e) {
      LogError(`Error fetching additional evidence list for vendor`, e);
      dispatch(
        setAdditionalEvidenceForVendor(
          false,
          vendorId,
          {
            errorText: `Error fetching additional evidence for vendor.`,
            actionText: "Try again",
            actionOnClick: () =>
              dispatch(fetchAdditionalEvidenceForVendor(vendorId, true)),
          },
          null,
          null,
          tpvmSession
        )
      );
      return;
    }

    if (!json) {
      dispatch(
        setAdditionalEvidenceForVendor(
          false,
          vendorId,
          {
            errorText: `Error fetching additional evidence for vendor.`,
            actionText: "Try again",
            actionOnClick: () =>
              dispatch(fetchAdditionalEvidenceForVendor(vendorId, true)),
          },
          null,
          null,
          tpvmSession
        )
      );
      return;
    }
    dispatch(
      setAdditionalEvidenceForVendor(
        false,
        vendorId,
        null,
        {
          active: json.evidence.active.map((e) => ({ ...e, kind: "evidence" })),
          archived: json.evidence.archived.map((e) => ({
            ...e,
            kind: "evidence",
          })),
        },
        {
          active: json.sharedAdditionalEvidence.active.map((e) => ({
            ...e,
            kind: "shared_evidence",
          })),
          archived: json.sharedAdditionalEvidence.archived.map((e) => ({
            ...e,
            kind: "shared_evidence",
          })),
        },
        tpvmSession
      )
    );

    // kick off call to update the activity stream
    dispatch(conditionalRefreshActivityStreamForOrgUser());
  };
};

export const uploadAndCreateNewAdditionalEvidenceDocument = (
  file: File,
  vendorId?: number,
  evidenceId?: number,
  documentTypeId?: number,
  commentary?: string,
  name?: string,
  showInRiskProfile = false,
  complete = false
): DefaultAction<{ id: number } | IAdditionalEvidenceDocument> => {
  return async (dispatch, getState) => {
    const tpvmSession = grabTPVMSession(getState);

    // Now try uploading the file to the back-end, returning a new additional evidence instance to wrap it.
    const data = new FormData();
    data.append("file", file);

    let json: {
      newDocument: IAdditionalEvidenceDocument;
      newEvidenceId: number;
      evidence: {
        active: AdditionalEvidenceSummary[];
        archived: AdditionalEvidenceSummary[];
      };
    };

    try {
      json = await FetchCyberRiskUrl(
        "vendor/evidence/new_document/v1/",
        {
          vendor_id: (evidenceId ?? 0) > 0 ? undefined : vendorId,
          evidence_id: evidenceId,
          document_type_id: documentTypeId,
          commentary,
          name,
          complete,
          show_in_risk_profile: showInRiskProfile,
        },
        {
          method: "POST",
          body: data,
        },
        dispatch,
        getState
      );
    } catch (e) {
      const error = e as Error;
      if (!error.toString().includes("File type is not allowed")) {
        LogError("error posting new additional evidence document file", e);
      }

      throw e;
    }

    dispatch(
      setAdditionalEvidenceForVendor(
        false,
        vendorId,
        null,
        json.evidence,
        null,
        tpvmSession
      )
    );

    if (evidenceId && !!json.newDocument) {
      dispatch(
        addDocumentForDetailedAdditionalEvidence(
          vendorId,
          evidenceId,
          json.newDocument,
          tpvmSession
        )
      );

      // kick off call to update the activity stream
      dispatch(conditionalRefreshActivityStreamForOrgUser());
      // update additional evidence type usage counts
      dispatch(fetchAdditionalEvidenceTypes(true));

      return json.newDocument;
    }
    return { id: json.newEvidenceId };
  };
};

export const SET_ORG_ADDITIONAL_EVIDENCE_TYPES =
  "SET_ORG_ADDITIONAL_EVIDENCE_TYPES";

const setOrgAdditionalEvidenceTypes = (types: AdditionalEvidenceType[]) => ({
  type: SET_ORG_ADDITIONAL_EVIDENCE_TYPES,
  types,
});

export const fetchAdditionalEvidenceTypes =
  (force = false): DefaultAction =>
  async (dispatch, getState) => {
    if (!force && getState().cyberRisk.additionalEvidenceTypes) {
      return;
    }
    let json: { additionalEvidenceTypes: AdditionalEvidenceType[] };
    try {
      json = await FetchCyberRiskUrl(
        "vendor/evidence/types/",
        {},
        {},
        dispatch,
        getState
      );
    } catch (e) {
      LogError("error getting additional evidence types", e);
      dispatch(
        addDefaultUnknownErrorAlert(
          "Error fetching organisation additional error types"
        )
      );
      throw e;
    }

    dispatch(setOrgAdditionalEvidenceTypes(json.additionalEvidenceTypes));
  };

export interface updateAdditionalEvidenceType {
  id?: number;
  name: string;
}

export const updateAdditionalEvidenceTypes =
  (newTypes: updateAdditionalEvidenceType[]): DefaultAction =>
  async (dispatch, getState) => {
    try {
      await FetchCyberRiskUrl(
        "vendor/evidence/types/",
        {},
        { method: "POST", body: JSON.stringify({ types: newTypes }) },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("error updating additional evidence types", e);
      dispatch(
        addDefaultUnknownErrorAlert("Error updating additional evidence types")
      );
      throw e;
    }
  };

export const createAdditionalEvidenceRequest =
  (
    vendorID: number,
    evidenceToRequest: IDocumentRequest[],
    recipients: IRecipient[],
    emailSubject: string,
    emailBody: string,
    isAnalystRequest: boolean,
    analystName: string
  ): DefaultAction =>
  async (dispatch, getState) => {
    try {
      await FetchCyberRiskUrl(
        "vendor/evidence/request/v1",
        {},
        {
          method: "POST",
          body: JSON.stringify({
            VendorId: vendorID,
            EvidenceToRequest: evidenceToRequest,
            Recipients: recipients,
            EmailSubject: emailSubject,
            EmailBody: emailBody,
            IsAnalystRequest: isAnalystRequest,
            AnalystName: analystName,
          }),
        },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("error creating additional evidence request", e);
      dispatch(
        addDefaultUnknownErrorAlert(
          "Error creating additional evidence request"
        )
      );
      throw e;
    }
  };

export const fetchAdditionalEvidenceHistory =
  (
    vendorID: number,
    additionalEvidenceID: number,
    force = false
  ): DefaultAction =>
  async (dispatch, getState) => {
    if (
      !force &&
      getState().cyberRisk.vendors[vendorID]?.additionalEvidenceHistories?.[
        additionalEvidenceID
      ]?.history
    ) {
      return;
    }
    let json: {
      additionalEvidenceHistory: AdditionalEvidenceHistory[];
      additionalEvidenceHistoryUsers: IUserMiniMap;
      additionalEvidenceHistoryDocuments: IAdditionalEvidenceHistoryDocumentMap;
      additionalEvidenceHistoryRequestRecipients: IUserMiniMap[];
    };
    try {
      json = await FetchCyberRiskUrl(
        "vendor/evidence/history/v1/",
        {
          evidence_id: additionalEvidenceID,
        },
        {
          method: "GET",
        },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("error getting additional evidence history", e);
      dispatch(
        addDefaultUnknownErrorAlert("Error getting additional evidence history")
      );
      throw e;
    }

    dispatch(
      setVendorAdditionalEvidenceHistory(
        vendorID,
        additionalEvidenceID,
        json.additionalEvidenceHistory,
        json.additionalEvidenceHistoryUsers,
        json.additionalEvidenceHistoryDocuments,
        json.additionalEvidenceHistoryRequestRecipients
      )
    );
  };

export const SET_VENDOR_ADDITIONAL_EVIDENCE_HISTORY =
  "SET_VENDOR_ADDITIONAL_EVIDENCE_HISTORY";

const setVendorAdditionalEvidenceHistory = (
  vendorID: number,
  additionalEvidenceID: number,
  additionalEvidenceHistory: AdditionalEvidenceHistory[],
  additionalEvidenceHistoryUsers: IUserMiniMap,
  additionalEvidenceHistoryDocuments: IAdditionalEvidenceHistoryDocumentMap,
  additionalEvidenceHistoryRequestRecipients: IUserMiniMap[]
) => ({
  type: SET_VENDOR_ADDITIONAL_EVIDENCE_HISTORY,
  vendorID,
  additionalEvidenceID,
  additionalEvidenceHistory,
  additionalEvidenceHistoryUsers,
  additionalEvidenceHistoryDocuments,
  additionalEvidenceHistoryRequestRecipients,
});

export const archiveAdditionalEvidenceForVendor = (
  vendorId: number,
  evidenceId: number,
  archive: boolean,
  isShared = false
): DefaultAction => {
  return async (dispatch, getState) => {
    const tpvmSession = grabTPVMSession(getState);
    dispatch(
      setAdditionalEvidenceForVendor(
        true,
        vendorId,
        null,
        null,
        null,
        tpvmSession
      )
    );

    const endpoint = isShared
      ? "vendor/sharedevidence/archive/v1"
      : "vendor/evidence/archive/v1";

    try {
      await FetchCyberRiskUrl(
        endpoint,
        {
          evidence_id: evidenceId,
          archive: archive,
        },
        { method: "PUT" },
        dispatch,
        getState
      );
    } catch (e) {
      LogError(`Error archiving/unarchiving additional evidence for vendor`, e);
      throw e;
    } finally {
      // we set the evidence as loading up to so always re-fetch the evidence,
      // even in case of failure, so that the table reload properly
      dispatch(fetchAdditionalEvidenceForVendor(vendorId, true));
    }

    // kick off call to update the activity stream
    dispatch(conditionalRefreshActivityStreamForOrgUser());
  };
};

export const SET_ADDITIONAL_EVIDENCE_REQUEST_USERS =
  "SET_ADDITIONAL_EVIDENCE_REQUEST_USERS";

export const setAdditionalEvidenceRequestUsers = (
  vendorId: number,
  evidenceId: number,
  users: AdditionalEvidenceUser[]
) => ({
  type: SET_ADDITIONAL_EVIDENCE_REQUEST_USERS,
  vendorId,
  evidenceId,
  users,
});

export const fetchAdditionalEvidenceRequestUsers = (
  vendorId: number,
  evidenceId: number
): DefaultAction => {
  return async (dispatch, getState) => {
    let data: AdditionalEvidenceUser[] = [];

    try {
      data = await FetchCyberRiskUrl<AdditionalEvidenceUser[]>(
        "vendor/evidence/users/v1",
        {
          evidence_id: evidenceId,
        },
        { method: "GET" },
        dispatch,
        getState
      );
    } catch (e) {
      // Only log the error as it's not critical if this info displays or not
      LogError(`Error fetching additional evidence users for ${evidenceId}`, e);
    } finally {
      dispatch(setAdditionalEvidenceRequestUsers(vendorId, evidenceId, data));
    }
  };
};
