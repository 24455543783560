import { DefaultThunkDispatchProp } from "../../../_common/types/redux";
import Modal, { BaseModalProps } from "../../../_common/components/ModalV2";
import { FC, useEffect, useState } from "react";
import Button from "../../../_common/components/core/Button";
import "../../style/components/shared_profile/NdaSigningModal.scss";
import {
  signVerifiedVendorNda,
  SignVerifiedVendorNdaV1Response,
} from "../../reducers/verifiedVendors.actions";
import { addDefaultUnknownErrorAlert } from "../../../_common/reducers/messageAlerts.actions";
import { trackEvent } from "../../../_common/tracking";
import { fetchUserData } from "../../../_common/reducers/commonActions";
import { getSurveyListV2 } from "../../reducers/survey.actions";
import RichTextEditV2 from "../../../_common/components/RichTextEditV2";
import { invalidateVendorAssessmentDraftsForVendor } from "../../reducers/vendorAssessmentAPI";

interface NdaSigningModalProps
  extends DefaultThunkDispatchProp,
    BaseModalProps {
  vendorName: string;
  vendorId?: number;
  ndaId?: number;
  ndaText: string;
  isPreview?: boolean;
  onAccepted?: (resp: SignVerifiedVendorNdaV1Response) => void;
}

const NdaSigningModal: FC<NdaSigningModalProps> = ({
  dispatch,
  active,
  onClose,
  vendorName,
  vendorId = 0,
  ndaId,
  ndaText,
  isPreview,
  onAccepted,
}) => {
  const [isSaving, setIsSaving] = useState(false);

  const save = () => {
    setIsSaving(true);

    dispatch(signVerifiedVendorNda(vendorId, ndaId ?? 0))
      .then((r) => {
        setIsSaving(false);

        // Re-get the user data in the background to ensure we get the latest value for hasSharedAssessmentsAccess
        dispatch(fetchUserData());

        // Re-get the shared surveys
        dispatch(getSurveyListV2(true, vendorId));

        if (!r.requiresRequestApproval) {
          // Re-get the risk assessment in the background to ensure we pick up the changed status
          dispatch(invalidateVendorAssessmentDraftsForVendor(vendorId));
        }

        if (onAccepted) {
          onAccepted(r);
        }
      })
      .catch(() => {
        dispatch(addDefaultUnknownErrorAlert("Error signing NDA"));
        setIsSaving(false);
      });
  };

  useEffect(() => {
    if (active && !isPreview) {
      trackEvent("SharedProfile_NdaViewed");
    }
  }, [active]);

  return (
    <Modal
      headerContent={`NDA for ${vendorName}`}
      className={"nda-signing-modal"}
      footerClassName={"nda-signing-modal-footer"}
      active={active}
      onClose={onClose}
      footerContent={
        <>
          <div className={"btn-group"}>
            {isPreview && (
              <Button onClick={onClose}>
                <i className={"cr-icon-noeye"} />
                Close preview
              </Button>
            )}
            {!isPreview && (
              <p className={"qualifier"}>
                UpGuard is not a party to this agreement. By using this NDA, you
                agree to indemnify UpGuard against all claims, loss, damages,
                expense, and liability (&quot;losses&quot;) that may arise out
                of this NDA.
              </p>
            )}
          </div>
          <div className={"btn-group"}>
            <Button
              tertiary
              onClick={() => {
                trackEvent("SharedProfile_NdaDeclined");
                onClose();
              }}
              disabled={isSaving || isPreview}
            >
              Decline
            </Button>
            <Button
              filledPrimary
              onClick={save}
              loading={isSaving}
              disabled={isPreview}
            >
              I accept
            </Button>
          </div>
        </>
      }
    >
      <RichTextEditV2 readOnly value={ndaText} />
    </Modal>
  );
};

export default NdaSigningModal;
