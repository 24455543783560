import { vsaqQuestion, vsaqQuestionType } from "./vsaqTypes";
import { Survey } from "../reducers/reducer";
import { Node, NodeType } from "../types/types";
import {
  convertConditionalToVsaqCond,
  convertVsaqConditional,
} from "./vsaqConditionalLogic";
import { SurveyFramework } from "../types/frameworks";
import { SurveyUsageType } from "../../_common/types/surveyTypes";

// Used to convert VSAQ-formatted JSON into the Survey format used in the survey builder.
// This should convert to a format ready to be inserted directly into the redux store.
export const convertVsaqStructureToSurvey = (
  surveyId: string,
  rootQuestion: vsaqQuestion,
  trackHistory: boolean
): Survey => {
  const survey: Survey = {
    surveyId,
    name: "",
    rootNodeId: rootQuestion.id,
    nodes: {},
    framework: SurveyFramework.NONE,
    includeTOC: false,
    customNumbering: false,
    saving: false,
    validationErrors: {
      invalidNodeIds: [],
      errors: {},
    },
    trackHistory,
    actionHistory: [],
    actionHistoryUpdatedAt: 0,
    isPublished: false,
    draftInProgress: true,
    enabled: false,
    usageType: SurveyUsageType.Security,
  };

  const answerIdsToNodeIds: { [answerId: string]: string } = {};
  const questionsMap: { [qId: string]: vsaqQuestion | undefined } = {};

  processQuestion(rootQuestion, answerIdsToNodeIds, questionsMap, survey.nodes);

  return survey;
};

export const processQuestion = (
  q: vsaqQuestion,
  answerIdsToNodeIds: { [answerId: string]: string },
  questionsMap: { [qId: string]: vsaqQuestion | undefined },
  allNodes: { [nodeId: string]: Node | undefined },
  parentId?: string
) => {
  let node: Node;
  questionsMap[q.id] = q;

  switch (q.type) {
    case vsaqQuestionType.Block: {
      node = {
        type: NodeType.Section,
        nodeId: q.id,
        parentId,
        controls: q.controls,
        title: q.text,
        titleSanitised: q.textSanitised,
        mainTextSanitised: "",
        mainText: "",
        childNodeIds: [],
        skipNumbering: q.skipNumbering,
      };

      // Special case with block types - if the first item in the block is an "info" section,
      // use that as the main text.
      let hasInfoSection = false;
      if (
        q.items &&
        q.items.length > 0 &&
        q.items[0].type === vsaqQuestionType.Info
      ) {
        hasInfoSection = true;
        node.mainText = q.items[0].text;

        if (!q.controls || q.controls.length === 0) {
          node.controls = q.items[0].controls;
        }
      }

      if (q.items) {
        for (let i = hasInfoSection ? 1 : 0; i < q.items.length; i++) {
          node.childNodeIds.push({
            id: q.items[i].id,
            type: getNodeTypeForVsaqQuestionType(q.items[i]),
          });
          processQuestion(
            q.items[i],
            answerIdsToNodeIds,
            questionsMap,
            allNodes,
            q.id
          );
        }
      }

      break;
    }

    case vsaqQuestionType.Info:
    case vsaqQuestionType.Tip: {
      if (q.warn !== "yes") {
        // Just make this into an info block
        node = {
          type: NodeType.Info,
          nodeId: q.id,
          parentId,
          controls: q.controls,
          mainText: q.text,
          mainTextSanitised: q.textSanitised,
          skipNumbering: true, // Always skip numbering for info blocks
        };
        break;
      }

      node = {
        type: NodeType.Risk,
        nodeId: q.id,
        parentId: parentId,
        controls: q.controls,
        mainText: q.text,
        mainTextSanitised: q.textSanitised,
        name: q.name || "",
        nameSanitised: q.nameSanitised || "",
        why: q.why || "",
        riskCategory: q.riskCategory || "",
        severity: q.severity,
        askForCompensatingControls: q.why !== undefined,
        skipNumbering: q.skipNumbering,
        impact: "",
        passName: "",
        customRiskID: (q.customRiskId ?? 0) > 0 ? q.customRiskId : undefined,
        customRiskParentId: q.customRiskParentId,
      };
      break;
    }

    case vsaqQuestionType.RadioGroup:
    case vsaqQuestionType.CheckGroup: {
      node = {
        type: NodeType.Select,
        nodeId: q.id,
        parentId,
        controls: q.controls,
        mainText: q.text,
        mainTextSanitised: q.textSanitised,
        radio: q.type === vsaqQuestionType.RadioGroup,
        answers: [],
        skipNumbering: q.skipNumbering,
        allowNotes: !!q.allowNotes,
        hasCustomRisk: q.hasCustomRisk,
      };

      if (q.choices) {
        for (let i = 0; i < q.choices.length; i++) {
          const entries = Object.entries(q.choices[i]);
          for (let j = 0; j < entries.length; j++) {
            const [id, text] = entries[j];
            node.answers.push({ id, text });
            answerIdsToNodeIds[id] = q.id;
          }
        }

        // Link the notes answer ID to the node ID
        if (!!q.allowNotes) {
          answerIdsToNodeIds[q.id + "-notes"] = q.id;
        }
      }

      break;
    }

    case vsaqQuestionType.Line:
    case vsaqQuestionType.Box: {
      node = {
        type: NodeType.InputText,
        nodeId: q.id,
        parentId,
        controls: q.controls,
        mainText: q.text,
        mainTextSanitised: q.textSanitised,
        multiLine: q.type === vsaqQuestionType.Box,
        skipNumbering: q.skipNumbering,
        hasCustomRisk: q.hasCustomRisk,
      };

      break;
    }

    case vsaqQuestionType.Upload: {
      node = {
        type: NodeType.Upload,
        nodeId: q.id,
        parentId,
        controls: q.controls,
        mainText: q.text,
        mainTextSanitised: q.textSanitised,
        skipNumbering: q.skipNumbering,
      };

      break;
    }
  }

  if (q.cond) {
    node.conditionalExpression = convertVsaqConditional(
      answerIdsToNodeIds,
      q.cond
    );
  }

  node.customNumber = q.customNumber;

  allNodes[node.nodeId] = node;
};

// Used to convert the Survey type (the format used in the survey builder) to VSAQ-formatted JSON.
export const convertSurveyToVsaqStructure = (survey: Survey): vsaqQuestion => {
  const processNode = (node: Node): vsaqQuestion => {
    const vsaqQ: vsaqQuestion = {
      id: node.nodeId,
      type: vsaqQuestionType.Block,
      text: node.mainText,
      cond: node.conditionalExpression
        ? convertConditionalToVsaqCond(node.conditionalExpression)
        : undefined,
      controls: node.controls,
    };

    switch (node.type) {
      case NodeType.Section:
        vsaqQ.type = vsaqQuestionType.Block;

        // For Section types, the text of the block should actually be the title.
        // We use the mainText to populate an info item as the first child.
        vsaqQ.text = node.title;
        vsaqQ.items = [];

        if (node.mainText) {
          vsaqQ.items.push({
            id: node.nodeId + "-info",
            type: vsaqQuestionType.Info,
            text: node.mainText,
          });
        }

        // Now find each of its children and add them to the items array.
        for (let i = 0; i < node.childNodeIds.length; i++) {
          const childNode = survey.nodes[node.childNodeIds[i].id];
          if (!childNode) {
            throw new Error(
              `child node with ID ${node.childNodeIds[i].id} not found`
            );
          }

          vsaqQ.items.push(processNode(childNode));
        }

        break;

      case NodeType.InputText:
        if (node.multiLine) {
          vsaqQ.type = vsaqQuestionType.Box;
        } else {
          vsaqQ.type = vsaqQuestionType.Line;
        }

        break;

      case NodeType.Select:
        if (node.radio) {
          vsaqQ.type = vsaqQuestionType.RadioGroup;
        } else {
          vsaqQ.type = vsaqQuestionType.CheckGroup;
        }

        vsaqQ.choices = [];

        for (let i = 0; i < node.answers.length; i++) {
          vsaqQ.choices.push({
            [node.answers[i].id]: node.answers[i].text,
          });
        }

        break;

      case NodeType.Upload:
        vsaqQ.type = vsaqQuestionType.Upload;
        break;

      case NodeType.Risk:
        vsaqQ.type = vsaqQuestionType.Tip;
        vsaqQ.warn = "yes";
        vsaqQ.severity = node.severity;
        vsaqQ.riskCategory = node.riskCategory;
        vsaqQ.impact = node.impact;
        vsaqQ.name = node.name;
        vsaqQ.passName = node.passName;

        if (node.why) {
          vsaqQ.why = node.why;
        }

        break;
    }

    return vsaqQ;
  };

  const rootNode = survey.nodes[survey.rootNodeId];
  if (!rootNode) {
    throw new Error("root node not found");
  }

  return processNode(rootNode);
};

const getNodeTypeForVsaqQuestionType = (q: vsaqQuestion) => {
  switch (q.type) {
    case vsaqQuestionType.Block:
      return NodeType.Section;
    case vsaqQuestionType.Info:
    case vsaqQuestionType.Tip: {
      if (q.warn !== "yes") {
        return NodeType.Section;
      } else {
        return NodeType.Risk;
      }
    }
    case vsaqQuestionType.RadioGroup:
    case vsaqQuestionType.CheckGroup:
      return NodeType.Select;
    case vsaqQuestionType.Line:
    case vsaqQuestionType.Box:
      return NodeType.InputText;
    case vsaqQuestionType.Upload:
      return NodeType.Upload;
  }
};
