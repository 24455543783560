export enum Interval {
  DAILY = "d",
  WEEKLY = "w",
  MONTHLY = "m",
  QUARTERLY = "q",
  YEARLY = "y",
}

export const IntervalToName = (i: Interval): string => {
  switch (i) {
    case Interval.DAILY:
      return "Daily";
    case Interval.WEEKLY:
      return "Weekly";
    case Interval.MONTHLY:
      return "Monthly";
    case Interval.QUARTERLY:
      return "Quarterly";
    case Interval.YEARLY:
      return "Yearly";
  }
  return "";
};

export const IntervalSortOrder = (i: Interval): number => {
  switch (i) {
    case Interval.DAILY:
      return 1;
    case Interval.WEEKLY:
      return 2;
    case Interval.MONTHLY:
      return 3;
    case Interval.QUARTERLY:
      return 4;
    case Interval.YEARLY:
      return 5;
  }
  return -1;
};
