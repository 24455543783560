import { DisplayableScheduledReportRecipient } from "../../../_common/types/exportReport";
import { FC, useCallback, useEffect, useState } from "react";
import moment from "moment/moment";
import { UserEmailAddress } from "../../../_common/types/user";
import { DefaultThunkDispatchProp } from "../../../_common/types/redux";
import { fetchOrgUserEmailAddresses } from "../../reducers/org.actions";

import { ExportConfigurationOptionsStyles } from "./ExportConfiguration";
import ColorCheckbox from "../ColorCheckbox";
import { ScheduleFrequencyPicker } from "../modals/ExportReportModal";
import { CreatableV2, OptionType } from "../../../_common/components/SelectV2";
import { validateEmail } from "../../../_common/helpers";
import { toLower } from "lodash";
import { ValueType } from "react-select";
import PillLabel from "../PillLabel";
import { LabelColor } from "../../../_common/types/label";
import { appConnect } from "../../../_common/types/reduxHooks";
import { SidePopupV2 } from "../../../_common/components/DismissablePopup";

export interface ReportDeliveryState {
  scheduleExport: boolean;
  scheduleInterval: string;
  dayOfWeek: string;
  date: string;
  time: number;
  emailReport: boolean;
  emailRecipients: DisplayableScheduledReportRecipient[];
}

export const reportDeliveryStateValidationErr = (s: ReportDeliveryState) =>
  s.emailReport && s.emailRecipients.length === 0
    ? "At least one recipient must be specified."
    : undefined;

export const useReportDeliveryState = (): [
  ReportDeliveryState,
  (newState: Partial<ReportDeliveryState>) => void,
] => {
  const [state, setStateInner] = useState<ReportDeliveryState>({
    scheduleExport: false,
    scheduleInterval: "m",
    dayOfWeek: "Monday",
    date: moment().format("YYYY-MM-DD"),
    time: 9,
    emailReport: false,
    emailRecipients: [],
  });

  const setState = useCallback(
    (newState: Partial<ReportDeliveryState>) =>
      setStateInner((state) => ({
        ...state,
        ...newState,
      })),
    []
  );

  return [state, setState];
};

interface IReportDeliveryStepOwnProps {
  reportDeliveryState: ReportDeliveryState;
  setReportDeliveryState: (newState: Partial<ReportDeliveryState>) => void;
}

interface IReportDeliveryStepConnectedProps {
  orgUserEmailAddresses?: UserEmailAddress[];
}

type IReportDeliveryStepProps = IReportDeliveryStepOwnProps &
  IReportDeliveryStepConnectedProps &
  DefaultThunkDispatchProp;

const ReportDeliveryStep: FC<IReportDeliveryStepProps> = ({
  reportDeliveryState,
  setReportDeliveryState,
  orgUserEmailAddresses,
  dispatch,
}) => {
  useEffect(() => {
    dispatch(fetchOrgUserEmailAddresses());
  }, [dispatch]);

  const onSelectEmail = useCallback(
    (selectedOptions: ValueType<OptionType, true>) => {
      if (selectedOptions) {
        setReportDeliveryState({
          emailRecipients: selectedOptions.map(
            (o) =>
              ({
                emailAddress: toLower(o.value.toString()),
                unsubscribedAll: false,
                unsubscribed: false,
              }) as DisplayableScheduledReportRecipient
          ),
        });
      } else {
        setReportDeliveryState({ emailRecipients: [] });
      }
    },
    [setReportDeliveryState]
  );

  const onRemoveEmail = useCallback(
    (email: DisplayableScheduledReportRecipient) =>
      setReportDeliveryState({
        emailRecipients: reportDeliveryState.emailRecipients.filter(
          (e) => e.emailAddress !== email.emailAddress
        ),
      }),
    [reportDeliveryState.emailRecipients]
  );

  return (
    <ExportConfigurationOptionsStyles className="report-delivery-step">
      <div className="export-config-option">
        <div className="title-desc">
          Frequency
          <div className="desc">
            How often do you want to receive this report?
          </div>
        </div>
        <div className="export-config-option-children">
          <div className="export-config-option">
            <div className="checkbox-radio-opt-container">
              <ColorCheckbox
                radio
                checked={!reportDeliveryState.scheduleExport}
                onClick={() =>
                  setReportDeliveryState({ scheduleExport: false })
                }
                label="Generate once"
              />
            </div>
          </div>
          <div className="export-config-option">
            <div className="checkbox-radio-opt-container">
              <ColorCheckbox
                radio
                checked={reportDeliveryState.scheduleExport}
                onClick={() => setReportDeliveryState({ scheduleExport: true })}
                label="Set up recurring report generation"
              />
            </div>
          </div>
          {reportDeliveryState.scheduleExport && (
            <div className="export-config-option">
              <div className="checkbox-radio-opt-container">
                <ScheduleFrequencyPicker
                  interval={reportDeliveryState.scheduleInterval}
                  onChangeInterval={(scheduleInterval) =>
                    setReportDeliveryState({ scheduleInterval })
                  }
                  dayOfWeek={reportDeliveryState.dayOfWeek}
                  onChangeDayOfWeek={(dayOfWeek) =>
                    setReportDeliveryState({ dayOfWeek })
                  }
                  date={reportDeliveryState.date}
                  onChangeDate={(date) => setReportDeliveryState({ date })}
                  time={reportDeliveryState.time}
                  onChangeTime={(time) => setReportDeliveryState({ time })}
                />
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="export-config-option">
        <div className="title-desc">
          Delivery
          <div className="desc">
            How would you like to receive this{" "}
            {reportDeliveryState.scheduleExport && "recurring "}report?
          </div>
        </div>
        <div className="export-config-option-children">
          <div className="export-config-option">
            <div className="checkbox-radio-opt-container">
              <ColorCheckbox
                radio
                checked={!reportDeliveryState.emailReport}
                onClick={() => setReportDeliveryState({ emailReport: false })}
                label="Save to reports only"
              />
            </div>
          </div>
          <div className="export-config-option">
            <div className="checkbox-radio-opt-container">
              <ColorCheckbox
                radio
                checked={reportDeliveryState.emailReport}
                onClick={() => setReportDeliveryState({ emailReport: true })}
                label="Send report via email and save to reports"
              />
            </div>
          </div>
        </div>
      </div>

      {reportDeliveryState.emailReport && (
        <div className="export-config-option">
          <div className="title-desc">
            Email recipients
            <div className="desc">
              Type to search or add in a new email recipient.
            </div>
          </div>
          <div>
            <CreatableV2
              className={"email-select"}
              isLoading={!orgUserEmailAddresses}
              options={orgUserEmailAddresses?.map((e) => ({
                label: e.emailAddress,
                value: e.emailAddress,
              }))}
              value={reportDeliveryState.emailRecipients.map((e) => ({
                value: e.emailAddress,
                label: e.emailAddress,
              }))}
              isClearable
              isMulti
              isSearchable
              controlShouldRenderValue={false}
              onChange={onSelectEmail}
              isValidNewOption={validateEmail}
              placeholder={"Enter an email address"}
            />
            <div className={"label-list"}>
              {reportDeliveryState.emailRecipients.map((e) =>
                e.unsubscribed || e.unsubscribedAll ? (
                  <SidePopupV2
                    className={"unsubscribed popup"}
                    key={e.emailAddress}
                    text={
                      <>
                        <h3>This recipient has unsubscribed</h3>
                        <div>
                          {`${e.emailAddress} has unsubscribed from `}
                          {e.unsubscribedAll ? `all reports ` : `these emails `}
                          {`and will not receive this reports export until they resubscribe.`}
                        </div>
                      </>
                    }
                  >
                    <PillLabel
                      key={e.emailAddress}
                      color={LabelColor.Red}
                      removeable
                      large
                      constrained
                      capitalized={false}
                      onRemoveClick={() => onRemoveEmail(e)}
                    >
                      {e.emailAddress}
                    </PillLabel>
                  </SidePopupV2>
                ) : (
                  <PillLabel
                    key={e.emailAddress}
                    color={LabelColor.Blue}
                    removeable
                    large
                    constrained
                    capitalized={false}
                    onRemoveClick={() => onRemoveEmail(e)}
                  >
                    {e.emailAddress}
                  </PillLabel>
                )
              )}
            </div>
          </div>
        </div>
      )}
    </ExportConfigurationOptionsStyles>
  );
};

export default appConnect<
  IReportDeliveryStepConnectedProps,
  never,
  IReportDeliveryStepOwnProps
>((state) => {
  const newProps: IReportDeliveryStepConnectedProps = {
    orgUserEmailAddresses: state.cyberRisk.orgUserEmailAddresses?.data,
  };

  return newProps;
})(ReportDeliveryStep);
