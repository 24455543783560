import { FC, memo, useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import {
  BaseModalProps,
  transitionTimeout,
} from "../../../_common/components/ModalV2";
import VendorOnboardingFirstTimeSetupModal from "./VendorOnboardingFirstTimeSetupModal";

const VendorOnboardingFirstTimeModalName = "VendorOnboardingFirstTime";

const supportedModalComponents: Record<string, FC<BaseModalProps>> = {
  [VendorOnboardingFirstTimeModalName]: VendorOnboardingFirstTimeSetupModal,
};

const modalHashRegex = /#modal=([A-Za-z]+)/;

// RouterTriggeredModal should be included near the root of the component tree, but inside the router.
// This component watches the 'hash' part of the URL for a directive that should open a modal.
// If the hash part matches #modal=ModalName we look for that modal name in the supportedModalComponents
// and render it.
const RouterTriggeredModal: FC = () => {
  const { hash, pathname, state, search } = useLocation();
  const history = useHistory();
  const [modalComponentName, setModalComponentName] = useState<
    string | undefined
  >();
  const [active, setActive] = useState(false);

  const closeModal = () => {
    // Remove the hash part of the URL
    history.replace({
      pathname,
      state,
      search,
    });
  };

  useEffect(() => {
    // Check if we have a modal=ModalComponentName in the URL hash
    const matches = modalHashRegex.exec(hash);
    if (matches && matches.length === 2) {
      const componentName = matches[1];

      if (supportedModalComponents[componentName]) {
        setModalComponentName(componentName);
        // Set active in the next render so we get the transition
        setTimeout(() => setActive(true), 0);
      }
    } else if (active) {
      setActive(false);
      // Wait until the component has disappeared before unsetting the props state
      setTimeout(() => setModalComponentName(undefined), transitionTimeout);
    }
  }, [hash]);

  const ModalComponent = modalComponentName
    ? supportedModalComponents[modalComponentName]
    : undefined;

  return ModalComponent !== undefined ? (
    <ModalComponent active={active} onClose={closeModal} />
  ) : null;
};

export default memo(RouterTriggeredModal);
