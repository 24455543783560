import { FC, memo, ReactNode, useState } from "react";

import DropdownV2, {
  DropdownAutoscroller,
} from "../../../_common/components/core/DropdownV2";
import classnames from "classnames";
import {
  currentDomainPortfolioLocalStorageKey,
  currentVendorPortfolioLocalStorageKey,
  Portfolio,
  PortfolioType,
} from "../../reducers/portfolios.actions";
import { DefaultThunkDispatchProp } from "../../../_common/types/redux";
import { setCustomerDataFiltersAndRefreshData } from "../../reducers/cyberRiskActions";
import { isEqual } from "lodash";
import "../../style/components/portfolios/PortfolioFilterDropdown.scss";
import {
  clearLocalStorageItem,
  setLocalStorageItemString,
} from "../../../_common/session";
import PillLabel from "../PillLabel";
import { LabelColor } from "../../../_common/types/label";
import {
  UserBreachsightWrite,
  UserVendorRiskWrite,
  UserWriteOwnOrganisation,
} from "../../../_common/permissions";
import { getVendorWords } from "../../../_common/constants";
import { useHistory } from "react-router-dom";
import { useModalV2 } from "../../../_common/components/ModalV2";
import CreateNewPortfolioModal from "./CreateNewPortfolioModal";
import { locationState } from "../../../_common/types/router";
import { AssuranceType } from "../../../_common/types/organisations";
import { Filters } from "../filter/types";
import { domainPortfolioHelpURL, vendorPortfolioHelpURL } from "./helpers";
import { appConnect } from "../../../_common/types/reduxHooks";

interface IPortfolioFilterDropdownConnectedProps {
  portfolios?: Portfolio[];
  grandTotalItems?: number;
  portfolioLimit?: number;
  filteredPortfolioIds: number[];
  canCreatePortfolios: boolean;
  assuranceType: AssuranceType;
}

interface IPortfolioFilterDropdownOwnProps {
  portfolioType: PortfolioType;
  renderPopupItem: (
    currentPortfolioName: string,
    dropdownActive: boolean
  ) => ReactNode;
}

type IPortfolioFilterDropdownProps = IPortfolioFilterDropdownConnectedProps &
  IPortfolioFilterDropdownOwnProps &
  DefaultThunkDispatchProp;

const PortfolioFilterDropdown: FC<IPortfolioFilterDropdownProps> = ({
  portfolioType,
  portfolios,
  grandTotalItems = 0,
  portfolioLimit = 0,
  renderPopupItem,
  filteredPortfolioIds,
  dispatch,
  canCreatePortfolios,
  assuranceType,
}) => {
  const history = useHistory<locationState>();
  const [openCreatePortfolioModal, createPortfolioModal] = useModalV2(
    CreateNewPortfolioModal
  );
  const [dropdownActive, setDropdownActive] = useState(false);

  if (!portfolios) {
    return null;
  }

  let currentPortfolioName = "All Portfolios";

  if (filteredPortfolioIds.length === 1) {
    const portfolio = portfolios.find((p) => p.id === filteredPortfolioIds[0]);
    currentPortfolioName = portfolio?.name || "";
  } else if (filteredPortfolioIds.length > 1) {
    currentPortfolioName = "Multiple Portfolios (Filter Applied)";
  } else if (portfolios.length === 1) {
    // User only has access to one portfolio. Just use that name.
    currentPortfolioName = portfolios[0].name;
  }

  const selectPortfolio = (portfolioIds: number[]) => {
    if (isEqual(portfolioIds, filteredPortfolioIds)) {
      return;
    }

    const localStorageKey =
      portfolioType === PortfolioType.Vendor
        ? currentVendorPortfolioLocalStorageKey
        : portfolioType === PortfolioType.Domain
          ? currentDomainPortfolioLocalStorageKey
          : "";

    if (portfolioIds.length === 1) {
      // Save this in local storage so we can persist it between reloads and sessions.
      setLocalStorageItemString(localStorageKey, portfolioIds[0].toString());
    } else {
      // Clear the setting in local storage if needed
      clearLocalStorageItem(localStorageKey);
    }

    const newFilters: Partial<Filters> | undefined =
      portfolioType === PortfolioType.Vendor
        ? {
            portfolioIds: portfolioIds,
          }
        : portfolioType === PortfolioType.Domain
          ? {
              domainPortfolioIds: portfolioIds,
            }
          : undefined;

    dispatch(
      setCustomerDataFiltersAndRefreshData(
        newFilters,
        portfolioType === PortfolioType.Domain,
        portfolioType === PortfolioType.Vendor
      )
    );
  };

  const vendorWords = getVendorWords(assuranceType);

  return (
    <>
      <DropdownV2
        className="portfolio-dropdown"
        popupItem={renderPopupItem(currentPortfolioName, dropdownActive)}
        onActiveChange={setDropdownActive}
        forceLeftAlign
      >
        <DropdownAutoscroller>
          <h3>Switch Portfolio</h3>
          {portfolios.length === 1 ? (
            <>
              <div className="no-portfolios">
                &quot;{portfolios[0].name}&quot; is currently your only
                portfolio.
                {canCreatePortfolios &&
                portfolioType === PortfolioType.Vendor ? (
                  <>
                    {" "}
                    To start using more portfolios, select{" "}
                    {vendorWords.singularIndefiniteArticle} on the{" "}
                    {vendorWords.vendorsPageTitle} screen and add it to a new
                    portfolio.
                  </>
                ) : canCreatePortfolios &&
                  portfolioType === PortfolioType.Domain ? (
                  <>
                    {" "}
                    To start using more portfolios, select a domain and add it
                    to a new portfolio.
                  </>
                ) : undefined}
              </div>
              <div className="dropdown-item spaced selected">
                {portfolios[0].name}{" "}
                <PillLabel color={LabelColor.Blue}>
                  {portfolios[0].numItems}
                </PillLabel>
              </div>
            </>
          ) : (
            <>
              <div
                className={classnames("dropdown-item spaced", {
                  selected: filteredPortfolioIds.length === 0,
                })}
                onClick={() => selectPortfolio([])}
              >
                All Portfolios{" "}
                <PillLabel
                  color={
                    filteredPortfolioIds.length === 0
                      ? LabelColor.Blue
                      : LabelColor.Grey
                  }
                >
                  {grandTotalItems}
                </PillLabel>
              </div>
              {portfolios.map((p) => (
                <div
                  key={p.id}
                  className={classnames("dropdown-item spaced", {
                    selected:
                      filteredPortfolioIds.length === 1 &&
                      filteredPortfolioIds[0] === p.id,
                  })}
                  onClick={() => selectPortfolio([p.id])}
                >
                  {p.name}{" "}
                  <PillLabel
                    color={
                      filteredPortfolioIds.length === 1 &&
                      filteredPortfolioIds[0] === p.id
                        ? LabelColor.Blue
                        : LabelColor.Grey
                    }
                  >
                    {p.numItems || 0}
                  </PillLabel>
                </div>
              ))}
            </>
          )}
          <hr />
          {canCreatePortfolios && (
            <>
              {(portfolioLimit === 0 || portfolios.length < portfolioLimit) && (
                <div
                  className="dropdown-item"
                  onClick={() =>
                    openCreatePortfolioModal({
                      portfolioType,
                    })
                  }
                >
                  <div className="icon-wrap">
                    <div className="cr-icon-plus" />
                  </div>{" "}
                  Add portfolio
                </div>
              )}
              <div
                className="dropdown-item"
                onClick={() =>
                  history.push(
                    portfolioType === PortfolioType.Vendor
                      ? `/settings/vendors`
                      : portfolioType === PortfolioType.Domain
                        ? `/settings/domains`
                        : "",
                    {
                      backContext: {
                        backTo: history.location.pathname,
                        backToText: "Back",
                      },
                    }
                  )
                }
              >
                <div className="icon-wrap">
                  <div className="cr-icon-cog2" />
                </div>{" "}
                Manage portfolios
              </div>
            </>
          )}

          <div
            className="dropdown-item"
            onClick={() =>
              window.open(
                portfolioType === PortfolioType.Vendor
                  ? vendorPortfolioHelpURL
                  : domainPortfolioHelpURL,
                "_blank"
              )
            }
          >
            <div className="icon-wrap">
              <div className="cr-icon-help" />
            </div>{" "}
            Help
          </div>
        </DropdownAutoscroller>
      </DropdownV2>
      {createPortfolioModal}
    </>
  );
};

export default appConnect<
  IPortfolioFilterDropdownConnectedProps,
  never,
  IPortfolioFilterDropdownOwnProps
>((state, props) => {
  const connectedProps: IPortfolioFilterDropdownConnectedProps = {
    filteredPortfolioIds: [],
    canCreatePortfolios: false,
    assuranceType: state.common.userData.assuranceType,
  };

  if (props.portfolioType === PortfolioType.Vendor) {
    connectedProps.portfolios = state.cyberRisk.vendorPortfolios?.portfolios;
    connectedProps.grandTotalItems =
      state.cyberRisk.vendorPortfolios?.grandTotalItems;
    connectedProps.portfolioLimit = state.cyberRisk.vendorPortfolios?.limit;
    connectedProps.filteredPortfolioIds =
      state.cyberRisk.customerData.filters.portfolioIds;
    connectedProps.canCreatePortfolios =
      state.common.userData.userPermissions.includes(UserVendorRiskWrite) &&
      state.common.userData.userPermissions.includes(UserWriteOwnOrganisation);
  } else if (props.portfolioType === PortfolioType.Domain) {
    connectedProps.portfolios = state.cyberRisk.domainPortfolios?.portfolios;
    connectedProps.grandTotalItems =
      state.cyberRisk.domainPortfolios?.grandTotalItems;
    connectedProps.portfolioLimit = state.cyberRisk.domainPortfolios?.limit;
    connectedProps.filteredPortfolioIds =
      state.cyberRisk.customerData.filters.domainPortfolioIds;
    connectedProps.canCreatePortfolios =
      state.common.userData.userPermissions.includes(UserBreachsightWrite) &&
      state.common.userData.userPermissions.includes(UserWriteOwnOrganisation);
  }

  return connectedProps;
})(memo(PortfolioFilterDropdown));
