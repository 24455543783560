import BaseAPI from "../rtkQueryApi";

export interface RemediationRequestVendorHostnamesWithRiskV1Request {
  requestId: number;
  riskId: string;
}

export interface RemediationRequestRiskHostnameDetails {
  website: string;
  score: number;
  enabled: boolean;
  remediated: boolean;
  waived: boolean;
  moved: boolean;
}

export interface RemediationRequestVendorHostnamesWithRiskV1Response {
  riskHostnameDetails: RemediationRequestRiskHostnameDetails[];
}

export enum RemediationRequestsTagTypes {
  vendorHostnamesWithRisk = "vendorHostnamesWithRisk",
}

const RemediationRequestsAPI = BaseAPI.enhanceEndpoints({
  addTagTypes: [...Object.values(RemediationRequestsTagTypes)],
}).injectEndpoints({
  endpoints: (builder) => ({
    getVendorHostnamesWithRiskV1: builder.query<
      RemediationRequestVendorHostnamesWithRiskV1Response,
      RemediationRequestVendorHostnamesWithRiskV1Request
    >({
      providesTags: [RemediationRequestsTagTypes.vendorHostnamesWithRisk],
      query: ({ requestId, riskId }) => ({
        url: "/remediationrequest/vendorhostnameswithrisk/v1/",
        method: "GET",
        params: {
          request_id: requestId,
          risk_id: riskId,
        },
      }),
    }),
  }),
});

export default RemediationRequestsAPI;
