import ModalV2, { BaseModalProps } from "../../_common/components/ModalV2";
import ContentLibraryAPI, {
  startOnboardingSurveyDocumentMigrationV1Req,
  surveyAttachmentToMigrate,
} from "../api/contentLibraryAPI";
import { FC, useCallback, useMemo, useState } from "react";
import "../styles/MigrateSurveyAttachmentsModal.scss";
import XTable, {
  IXTableColumnHeader,
  IXTableRow,
  XTableCell,
} from "../../_common/components/core/XTable";
import { useSelectableRows } from "../../_common/hooks";
import { LogError, pluralise } from "../../_common/helpers";
import Button from "../../_common/components/core/Button";
import { GetIconForFilename } from "../../vendorrisk/helpers/icons";
import moment from "moment/moment";
import IconButton, { HoverLocation } from "../../_common/components/IconButton";
import { openAttachmentUrlFunc } from "../../_common/components/surveydetails/SurveyDetailsDocumentsCard";
import TextField from "../../_common/components/TextField";
import DocumentTypeSelector from "./DocumentTypeSelector";
import { SidePopupV2 } from "../../_common/components/DismissablePopup";
import { useAppDispatch } from "../../_common/types/reduxHooks";
import {
  addDefaultSuccessAlert,
  addDefaultUnknownErrorAlert,
} from "../../_common/reducers/messageAlerts.actions";

interface IMigrateSurveyAttachmentsModalProps extends BaseModalProps {
  surveyAttachmentsToMigrate: surveyAttachmentToMigrate[];
}

const columns: IXTableColumnHeader[] = [
  {
    id: "document",
    text: "Document",
  },
  {
    id: "actions",
    text: "",
  },
  {
    id: "name",
    text: "Name and type",
  },
];

const MigrateSurveyAttachmentsModal: FC<
  IMigrateSurveyAttachmentsModalProps
> = ({ active, onClose, surveyAttachmentsToMigrate }) => {
  const dispatch = useAppDispatch();
  const [submitting, setSubmitting] = useState(false);
  const [selectedSurveyAttachments, setSelectedSurveyAttachments] = useState(
    () =>
      new Set<number>(
        surveyAttachmentsToMigrate.map((a) => a.surveyAttachmentID)
      )
  );

  const [documentProperties, setDocumentProperties] = useState<
    Record<
      string,
      {
        name: string;
        type?: string;
      }
    >
  >(() =>
    surveyAttachmentsToMigrate.reduce(
      (
        prev: Record<
          string,
          {
            name: string;
            type?: string;
          }
        >,
        next
      ) => {
        prev[next.surveyAttachmentID] = {
          name: next.filename,
        };
        return prev;
      },
      {}
    )
  );
  const setPropertiesForDocument = useCallback(
    (
      id: number,
      props: {
        name?: string;
        type?: string;
      }
    ) =>
      setDocumentProperties((documentProperties) => ({
        ...documentProperties,
        [id]: {
          ...documentProperties[id],
          ...props,
        },
      })),
    []
  );

  const rows: IXTableRow<number>[] = useMemo(() => {
    return surveyAttachmentsToMigrate.map((a) => {
      const viewQuestionnaire = () =>
        window.open(`/vendors/surveys/${a.surveyID}`);

      const downloadAttachment = openAttachmentUrlFunc(
        a.gcsObjectName,
        a.surveyAttachmentName,
        a.surveyID,
        false
      );

      const documentProps = documentProperties[a.surveyAttachmentID];

      return {
        id: a.surveyAttachmentID,
        selected: selectedSurveyAttachments.has(a.surveyAttachmentID),
        cells: [
          <XTableCell key="document" className="document-cell">
            <div className="document-cell-inner">
              <img
                className="file-type"
                alt="File type icon"
                src={GetIconForFilename(a.filename)}
              />
              <div className="doc-name-meta">
                <div className="doc-name">{a.filename}</div>
                <div className="doc-meta">
                  <span>Used in:</span> {a.surveyName}
                  <br />
                  <span>Uploaded on:</span> {moment(a.usedAt).format("LL")}
                </div>
              </div>
            </div>
          </XTableCell>,
          <XTableCell key="actions" className="actions-cell shrink-cell">
            <IconButton
              icon={<div className="cr-icon-eye" />}
              onClick={viewQuestionnaire}
              hoverText="View questionnaire"
              hoverLocation={HoverLocation.Top}
              hoverMicro
            />
            <IconButton
              icon={<div className="cr-icon-export-thin" />}
              onClick={downloadAttachment}
              hoverText="Download file"
              hoverLocation={HoverLocation.Top}
              hoverMicro
            />
          </XTableCell>,
          <XTableCell key="name" className="name-cell">
            <TextField
              placeholder="Document name"
              value={documentProps.name}
              onChanged={(val) =>
                setPropertiesForDocument(a.surveyAttachmentID, { name: val })
              }
              minLength={2}
              maxLength={500}
            />
            <DocumentTypeSelector
              selectedDocumentType={documentProps.type}
              setSelectedDocumentType={(val) =>
                setPropertiesForDocument(a.surveyAttachmentID, { type: val })
              }
              placeholder="Select type (required)"
              usePortal
            />
          </XTableCell>,
        ],
      };
    });
  }, [
    surveyAttachmentsToMigrate,
    selectedSurveyAttachments,
    documentProperties,
    setPropertiesForDocument,
  ]);

  const xTableSelectableProps = useSelectableRows(
    selectedSurveyAttachments,
    setSelectedSurveyAttachments,
    rows
  );

  const validationError = useMemo(() => {
    if (selectedSurveyAttachments.size === 0) {
      return "Please select at least one document.";
    }

    for (const id in documentProperties) {
      if (!selectedSurveyAttachments.has(parseInt(id))) {
        continue;
      }

      const props = documentProperties[id];

      if (props.name.length < 2) {
        return "Please enter a name for each document.";
      }

      if (!props.type) {
        return "Please select a type for each document.";
      }
    }

    return undefined;
  }, [selectedSurveyAttachments, documentProperties]);

  const [startMigration] =
    ContentLibraryAPI.useStartContentLibrarySurveyDocumentMigrationMutation();

  const doSubmit = useCallback(async () => {
    if (validationError) {
      return;
    }

    setSubmitting(true);

    const docs: startOnboardingSurveyDocumentMigrationV1Req["docsToMigrate"] =
      [];

    for (const id in documentProperties) {
      if (!selectedSurveyAttachments.has(parseInt(id))) {
        continue;
      }

      const props = documentProperties[id];
      docs.push({
        surveyAttachmentID: parseInt(id),
        newDocumentName: props.name,
        newDocumentType: props.type!,
      });
    }

    try {
      await startMigration({
        docsToMigrate: docs,
      }).unwrap();
      dispatch(
        addDefaultSuccessAlert(
          "We have started adding previously uploaded documents to your Content Library. They will appear as they are added."
        )
      );
      onClose();
    } catch (e) {
      LogError("error starting survey attachment migration", e);
      setSubmitting(false);
      dispatch(
        addDefaultUnknownErrorAlert(
          "An error occurred while adding these documents. Please try again or contact UpGuard Support."
        )
      );
    }
  }, [
    validationError,
    selectedSurveyAttachments,
    documentProperties,
    dispatch,
    startMigration,
    onClose,
  ]);

  return (
    <ModalV2
      active={active}
      onClose={onClose}
      className="migrate-survey-attachments-modal"
      containerClassName="migrate-survey-attachments-modal-container"
      headerContent={
        <>
          <h2>
            Add previously uploaded documents (
            {surveyAttachmentsToMigrate.length})
          </h2>
          <p className="subheading">
            These documents were used in questionnaires.
          </p>
        </>
      }
      footerClassName="migrate-survey-attachments-modal-footer"
      footerContent={
        <>
          <div className="num-docs">
            {selectedSurveyAttachments.size}{" "}
            {pluralise(selectedSurveyAttachments.size, "document", "documents")}{" "}
            selected
          </div>
          <Button tertiary onClick={onClose}>
            Cancel
          </Button>
          <SidePopupV2 text={validationError} noWrap popupDelay={0}>
            <Button
              filledPrimary
              disabled={!!validationError}
              onClick={doSubmit}
              loading={submitting}
            >
              Add selected documents to Content Library
            </Button>
          </SidePopupV2>
        </>
      }
    >
      <div className="migrate-table-container">
        <XTable
          rows={rows}
          columnHeaders={columns}
          selectable
          stickyColumnHeaders
          {...xTableSelectableProps}
        />
      </div>
    </ModalV2>
  );
};

export default MigrateSurveyAttachmentsModal;
