import { useState } from "react";
import ConfettiExplosion from "react-confetti-explosion";

export const ConfettiFullPage = () => {
  const [confettiExploding, setConfettiExploding] = useState(true);
  let widths = ["0", "25vw", "50vw", "75vw", "100vw"];
  if (window.innerWidth < 800) {
    widths = ["0", "50vw", "100vw"];
  }
  function afterConfetti() {
    setConfettiExploding(false);
  }
  return (
    <>
      {confettiExploding &&
        widths.map((w) => {
          return (
            <div
              style={{
                position: "fixed",
                top: 0,
                left: w,
                height: 0,
                width: 0,
              }}
              key={w}
            >
              <ConfettiExplosion
                particleSize={16}
                onComplete={afterConfetti}
                zIndex={1000001}
                duration={4000}
                force={0.4}
                width={1500}
                colors={[
                  "#f18aaf",
                  "#ed3b63",
                  "#6ef9a3",
                  "#e7fb43",
                  "#062af8",
                  "#8b85fa",
                ]}
              />
            </div>
          );
        })}
    </>
  );
};
