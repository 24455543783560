import { FC } from "react";
import { useBack } from "../../_common/types/router";
import { useRouteMatch } from "react-router-dom";

import { AppRouteParams } from "../UserBaseNavItems";

import UserBaseApplicationHeader from "../components/header/UserBaseApplicationHeader";
import AppPermissions from "../components/AppPermissions";
import { appPermissionsUrl, appUrlPrefix } from "../UserBaseAppRouter";

const ApplicationPermissionsView: FC = ({}) => {
  const match = useRouteMatch<AppRouteParams>();
  const appName = match.params.appName;

  const { backAction, backText } = useBack();

  return (
    <div className="app-permissions">
      <UserBaseApplicationHeader
        breadcrumbs={[
          { text: "Applications", to: `/userbase/applications` },
          {
            text: appName,
            to: `${appUrlPrefix}/${appName}`,
          },
          {
            text: "Permissions",
            to: appPermissionsUrl(appName),
          },
        ]}
        backAction={backAction}
        backText={backText}
        title={"Permissions"}
      />

      <AppPermissions />
    </div>
  );
};

export default ApplicationPermissionsView;
