import { UserRouteParams } from "../UserBaseNavItems";
import { useDefaultHistory } from "../../_common/types/router";
import UserBaseAPI from "../api/userbase.api";
import ReportCard from "../../_common/components/ReportCard";
import Button from "../../_common/components/core/Button";
import LoadingBanner from "../../_common/components/core/LoadingBanner";
import { FC } from "react";
import { useRouteMatch } from "react-router-dom";
import { userRiskProfileUrl, userSummaryUrl } from "../UserBaseAppRouter";
import SeverityIcon from "../../_common/components/SeverityIcon";
import { pluralise } from "../../_common/helpers";
import { SeverityAsString, SeverityInt } from "../../_common/types/severity";
import "./UserRisksOverview.scss";

const UserRisksOverview: FC = ({}) => {
  const match = useRouteMatch<UserRouteParams>();
  const history = useDefaultHistory();

  const userUUID = match.params.userUUID;

  const { data: userSummary } = UserBaseAPI.useGetUserBaseUserSummaryV1Query({
    userUUID: userUUID,
  });

  const { data, isLoading } = UserBaseAPI.useGetUserBaseUserRiskProfileV1Query({
    userUUID: userUUID,
  });

  const onViewAll = () => {
    history.push(userRiskProfileUrl(userUUID), {
      backContext: {
        backTo: userSummaryUrl(userUUID),
        backToText: `Back to ${userSummary?.user?.name}`,
      },
    });
  };

  if (!isLoading && !data?.risks) {
    return <></>;
  }

  const risksToUse = data?.risks ?? [];

  // Count the number of risks per severity
  const severityMap: Map<SeverityInt, number> = new Map();
  risksToUse.forEach((risk) => {
    // We don't want to count pass risks
    if (risk.severity > SeverityInt.InfoSeverity && !risk.isWaived) {
      const severity = risk.severity;
      const numRisks = severityMap.get(severity) ?? 0;
      severityMap.set(severity, numRisks + 1);
    }
  });

  // Put map into an array for easier processing
  const risksBySeverity = Array.from(severityMap.entries()).map(
    ([severity, numRisks]) => {
      return {
        severity,
        numRisks,
      };
    }
  );

  // Sort the risks by severity
  risksBySeverity.sort((a, b) => b.severity - a.severity);

  return (
    <ReportCard newStyles className="userbase-user-risks-overview">
      <div className="header">
        <span>Risks</span>
        <div className={"header-link"}>
          <Button tertiary onClick={onViewAll}>
            View all <div className="cr-icon-arrow-right" />
          </Button>
        </div>
      </div>
      {isLoading && <LoadingBanner />}
      {!isLoading && (
        <div className={"content"}>
          <div className={"subtitle"}>Current risks by severity</div>
          <div className={"breakdown"}>
            {risksBySeverity.map((r) => {
              const numberOfRisksToDisplay =
                r.numRisks === 0
                  ? "-"
                  : `${r.numRisks} ${pluralise(r.numRisks, "risk", "risks")}`;

              return (
                <div key={r.severity} className={"risk-severity"}>
                  <SeverityIcon severity={SeverityAsString(r.severity)} label />
                  {numberOfRisksToDisplay}
                </div>
              );
            })}
          </div>
        </div>
      )}
    </ReportCard>
  );
};

export default UserRisksOverview;
