import { FC, useState } from "react";
import ReportCard from "../../_common/components/ReportCard";
import { ScoreType } from "../../vendorrisk/components/risk_profile/ScoreTypeSelector";
import ColorGrade from "../../vendorrisk/components/executive_summary/ColorGrade";
import Score from "../../vendorrisk/components/Score";
import {
  DateStringToUnixDateWithoutTimezone,
  LocalisedDayMonthFormat,
} from "../../_common/helpers";
import { IScore } from "../../_common/types/score";
import { TimeControls } from "../../vendorrisk/components/executive_summary/TimeControls";
import { appConnect, useAppDispatch } from "../../_common/types/reduxHooks";
import { get as _get } from "lodash";
import moment from "moment";
import "./ScoresChart.scss";
import { factCategoryMeta } from "../../_common/factCategoryHelpers";
import RatingChart from "../../vendorrisk/components/executive_summary/RatingChart";
import LoadingBanner from "../../_common/components/core/LoadingBanner";
import EmptyCard from "../../vendorrisk/components/EmptyCard";

const userBaseDataDaysKey = "userbaseDataDays";
const defaultUserbaseDataDays = 31;

const overallScoreColor = "#7217c2";

interface IScoreChartsConnectedProps {
  dataDays: number;
}

// Don't care what data type is passed in, but please have these fields
interface ISummaryData {
  scores: IScore[];
  adjustedScores: IScore[];
  categoryScores: Record<string, IScore[]>;
  adjustedCategoryScores: Record<string, IScore[]>;
}

interface IScoreChartsOwnProps {
  isLoading: boolean;
  data: ISummaryData | undefined;
}

type IScoreChartsProps = IScoreChartsConnectedProps & IScoreChartsOwnProps;

const ScoresChart: FC<IScoreChartsProps> = ({ isLoading, data, dataDays }) => {
  const dispatch = useAppDispatch();

  const [scoreType, _] = useState(ScoreType.AdjustedAll);

  let scoresToDisplay: IScore[] =
    (scoreType === ScoreType.AdjustedAll
      ? data?.adjustedScores
      : data?.scores) ?? [];
  let categoryScoresToDisplay: Record<string, IScore[]> =
    (scoreType === ScoreType.AdjustedAll
      ? data?.adjustedCategoryScores
      : data?.categoryScores) ?? ({} as Record<string, IScore[]>);

  scoresToDisplay = scoresToDisplay.slice(-dataDays);
  categoryScoresToDisplay = Object.entries(categoryScoresToDisplay).reduce(
    (acc, [scoreCategory, scores]) => ({
      ...acc,
      [scoreCategory]: scores.slice(-dataDays),
    }),
    {}
  );

  const days = scoresToDisplay.map((s) =>
    moment(s.time, "YYYY-MM-DDTHH:mm:ss[Z]").format("YYYY-MM-DD")
  );

  const latestScore = scoresToDisplay.at(-1);

  const hasValidScores =
    scoresToDisplay.some((s) => s.overall_score >= 0) ||
    Object.values(categoryScoresToDisplay).some((scores) =>
      scores.some((s) => s.overall_score >= 0)
    );
  const hasScoreHistory =
    scoresToDisplay.filter((s) => s.overall_score >= 0).length > 1 ||
    Object.values(categoryScoresToDisplay).some(
      (scores) => scores.filter((s) => s.overall_score >= 0).length > 1
    );

  const datasets = Object.entries(categoryScoresToDisplay).map(
    ([scoreCategory, scores]) => ({
      label: factCategoryMeta[scoreCategory].name,
      color: factCategoryMeta[scoreCategory].color,
      data: scores.map((s) => s.overall_score),
      nopoints: false,
    })
  );
  datasets.push({
    label: "Overall",
    color: overallScoreColor,
    data: scoresToDisplay.map((s) => s.overall_score),
    nopoints: false,
  });

  return (
    <ReportCard className="userbase-scores-chart" newStyles>
      <div className="header">
        <div className="header-left">Risk Rating</div>
        <div className="header-right">
          {hasScoreHistory && (
            <TimeControls
              dispatch={dispatch}
              dataDays={dataDays}
              dataDaysKey={userBaseDataDaysKey}
            />
          )}
        </div>
      </div>
      {isLoading && <LoadingBanner></LoadingBanner>}
      {!isLoading && hasValidScores && (
        <>
          <div className="top-container">
            <div className={"score-container"}>
              {latestScore && (
                <>
                  <div className="date">
                    {LocalisedDayMonthFormat(
                      DateStringToUnixDateWithoutTimezone(
                        latestScore.time,
                        false
                      ),
                      true
                    )}
                  </div>
                  <ColorGrade score={latestScore.overall_score} />
                  <Score
                    colored
                    large
                    outOf={950}
                    score={latestScore.overall_score}
                  />
                </>
              )}
            </div>
            <div className={"legend-container"}>
              {hasScoreHistory && (
                <>
                  <div>
                    <div
                      className="legend-box"
                      style={{ borderBottomColor: overallScoreColor }}
                    />
                    Overall risk rating
                  </div>
                  {Object.keys(categoryScoresToDisplay).map((scoreCategory) => (
                    <div key={scoreCategory}>
                      <div
                        className="legend-box"
                        style={{
                          borderBottomColor:
                            factCategoryMeta[scoreCategory].color,
                        }}
                      />
                      {factCategoryMeta[scoreCategory].name} risk rating
                    </div>
                  ))}
                </>
              )}
            </div>
          </div>
          {hasScoreHistory && (
            <div className="report-content chart-container">
              <RatingChart
                xValues={days}
                unit={"days"}
                datasets={datasets}
                scoringChanges={[]}
                chartUnit={"day"}
              />
            </div>
          )}
        </>
      )}
      {!isLoading && !hasValidScores && (
        <EmptyCard text="There is no risk rating yet" />
      )}
    </ReportCard>
  );
};

export default appConnect<
  IScoreChartsConnectedProps,
  never,
  IScoreChartsOwnProps
>((state) => {
  return {
    dataDays: _get(
      state.cyberRisk.scoring,
      userBaseDataDaysKey,
      defaultUserbaseDataDays
    ),
  };
})(ScoresChart);
