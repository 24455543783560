import { trackEvent } from "../../_common/tracking";
import { FetchCyberRiskUrl } from "../../_common/api";
import { LogError } from "../../_common/helpers";
import { updateStateAfterLabelsChange } from "./domains.actions";
import { DefaultThunkDispatch } from "../../_common/types/redux";
import { DefaultRootState } from "react-redux";

export interface DomainLabelsUpdateRequest {
  labelsToAdd?: number[];
  labelsToRemove?: number[];
  hostnames: string[];
  datastoreVendorId?: number;
  isSubsidiary?: boolean;
}

export const setLabelsForWebsites = (
  hostnames: string[],
  addedLabelIds: number[],
  removedLabelIds: number[],
  datastoreVendorId?: number,
  isSubsidiary = false,
  clearDomainDetailsCache = true
) => {
  return async (
    dispatch: DefaultThunkDispatch,
    getState: () => DefaultRootState
  ) => {
    if (
      hostnames.length === 0 ||
      (addedLabelIds.length === 0 && removedLabelIds.length === 0)
    ) {
      return;
    }

    const updateRequestBody: DomainLabelsUpdateRequest = {
      labelsToAdd: addedLabelIds,
      labelsToRemove: removedLabelIds,
      hostnames: hostnames,
      datastoreVendorId: datastoreVendorId,
      isSubsidiary: isSubsidiary,
    };

    try {
      await FetchCyberRiskUrl(
        "websitelabels/v1",
        {},
        { method: "PUT", body: JSON.stringify(updateRequestBody) },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("error setting labels for hostnames", e);
      throw e;
    }

    trackEvent("BreachSight_SetLabels", {
      hostnames: JSON.stringify(hostnames),
    });

    // Refresh the redux state after change
    updateStateAfterLabelsChange(
      dispatch,
      getState(),
      datastoreVendorId,
      isSubsidiary,
      clearDomainDetailsCache,
      hostnames,
      addedLabelIds,
      removedLabelIds
    );
  };
};
