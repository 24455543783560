import { IVendorContactResponse } from "./vendorContact";
import { LabelColor } from "./label";
import { SortDirection } from "../components/core/XTable";
import PillLabel from "../../vendorrisk/components/PillLabel";
import { UserEmailAddress } from "./user";
import moment from "moment";
import {
  SurveyAsEvidence,
  VendorAssessmentStatus,
} from "../../vendorrisk/types/vendorAssessments";
import { JobStatus } from "../../vendorrisk/types/jobs";
import { SurveyStatus } from "./survey";

export enum ManagedVendorServiceLevel {
  evidenceChasing = "evidence_chasing",
  riskAssessment = "risk_assessment",
  managedRemediation = "managed_remediation",
  v3 = "v3",
}

export const ManagedVendorServiceLevelLabel = (
  sl: ManagedVendorServiceLevel
) => {
  switch (sl) {
    case ManagedVendorServiceLevel.evidenceChasing:
      return "Evidence Chasing";
    case ManagedVendorServiceLevel.managedRemediation:
      return "Risk Remediation";
    case ManagedVendorServiceLevel.riskAssessment:
      return "Risk Assessment";
    default:
      return "";
  }
};

export enum ManagedVendorServiceStatusType {
  New = "new",
  NotStarted = "not_started",
  OnHold = "on_hold",
  Blocked = "blocked",
  InProgress = "in_progress",
  Complete = "complete",
}

export interface IManagedVendorRequestSearchResult {
  id: number;
  cloudscanVendorId: number;
  name: string;
  primaryHostname: string;
  cstarScore: number;
  isManaged: boolean;
  isWatched: boolean;
  pendingAssessment: boolean;
}

export interface IMangedVendorRequestSearchResults {
  query: string;
  loading: boolean;
  results: IManagedVendorRequestSearchResult[];
  totalMatches: number;
}

export interface ITpvmSession {
  tpvm: boolean;
  tpvm_o: string;
  tpvm_v: string;
}

export interface ITPMVCount {
  evidenceChasing: number;
  riskAssessment: number;
  managedRemediation: number;
}

export interface IManagedVendorPageArgs {
  pageNum: number;
  pageSize: number;
  sortBy: string;
  sortDir: SortDirection;
}

export interface IManagedVendorPageOpts extends IManagedVendorPageArgs {
  totalPages: number;
  totalItems?: number;
  totalFilteredItems?: number;
}

export const getServiceLevelPill = (status: ManagedVendorServiceLevel) => {
  switch (status) {
    case ManagedVendorServiceLevel.evidenceChasing:
      return <PillLabel color={LabelColor.Orange}>Evidence Chasing</PillLabel>;
    case ManagedVendorServiceLevel.riskAssessment:
      return <PillLabel color={LabelColor.Blue}>Risk Assessment</PillLabel>;
    case ManagedVendorServiceLevel.v3:
      return <PillLabel color={LabelColor.Blue}>Risk Assessment</PillLabel>;
    case ManagedVendorServiceLevel.managedRemediation:
      return <PillLabel color={LabelColor.Green}>Risk Remediation</PillLabel>;
  }
  return <PillLabel color={LabelColor.Grey}>Unknown</PillLabel>;
};

export const getServiceStatusPill = (
  status: ManagedVendorServiceStatusType,
  includeTooltip = false
) => {
  switch (status) {
    case ManagedVendorServiceStatusType.Blocked:
      return (
        <PillLabel
          color={LabelColor.Red}
          popupContent={
            includeTooltip
              ? "Our analyst has marked this service as blocked. Please check the analyst note for more information."
              : undefined
          }
        >
          Blocked
        </PillLabel>
      );
    case ManagedVendorServiceStatusType.OnHold:
      return (
        <PillLabel
          color={LabelColor.Orange}
          popupContent={
            includeTooltip
              ? "Our analyst has put this service on hold. Please check the analyst note for more information."
              : undefined
          }
        >
          On hold
        </PillLabel>
      );
    case ManagedVendorServiceStatusType.New:
      return <PillLabel color={LabelColor.LightBlue}>New</PillLabel>;
    case ManagedVendorServiceStatusType.NotStarted:
      return <PillLabel color={LabelColor.Grey}>Not Started</PillLabel>;
    case ManagedVendorServiceStatusType.InProgress:
      return <PillLabel color={LabelColor.Orange}>In Progress</PillLabel>;
    case ManagedVendorServiceStatusType.Complete:
      return <PillLabel color={LabelColor.Green}>Completed</PillLabel>;
  }
  return <PillLabel color={LabelColor.DarkGrey}>Not Set</PillLabel>;
};

// ****************** V2 types below *****************

export interface ManagedVendorLicense {
  id: number;
  orgId: number;
  startsAt: string;
  endsAt: string;
  evidenceChasing: number;
  riskAssessment: number;
  managedRemediation: number;
  version3Slots: number;
}

export interface ManagedVendorLicenseUsed extends ManagedVendorLicense {
  evidenceChasingUsed: number;
  riskAssessmentUsed: number;
  managedRemediationUsed: number;
  version3SlotsUsed: number;
}

export interface ManagedVendorLicenseUsedWithOrg
  extends ManagedVendorLicenseUsed {
  orgName: string;
}

export type ManagedVendorQuota = Omit<
  ManagedVendorLicenseUsed,
  "id" | "ordId" | "startsAt" | "endsAt"
>;

export const TotalQuota = (
  licenses: ManagedVendorLicenseUsed[]
): ManagedVendorQuota =>
  licenses
    .filter((l) => moment().isBefore(moment(l.endsAt)))
    .reduce(
      (quota, l) => {
        quota.evidenceChasing += l.evidenceChasing;
        quota.evidenceChasingUsed += l.evidenceChasingUsed;
        quota.riskAssessment += l.riskAssessment;
        quota.riskAssessmentUsed += l.riskAssessmentUsed;
        quota.managedRemediation += l.managedRemediation;
        quota.managedRemediationUsed += l.managedRemediationUsed;
        quota.version3Slots += l.version3Slots;
        quota.version3SlotsUsed += l.version3SlotsUsed;
        return quota;
      },
      {
        evidenceChasing: 0,
        riskAssessment: 0,
        managedRemediation: 0,
        version3Slots: 0,
        evidenceChasingUsed: 0,
        riskAssessmentUsed: 0,
        managedRemediationUsed: 0,
        version3SlotsUsed: 0,
      }
    ) as ManagedVendorQuota;

export const RemainingQuota = (quota: ManagedVendorQuota) => ({
  evidenceChasing:
    quota.evidenceChasing > quota.evidenceChasingUsed
      ? quota.evidenceChasing - quota.evidenceChasingUsed
      : 0,
  riskAssessment:
    quota.riskAssessment > quota.riskAssessmentUsed
      ? quota.riskAssessment - quota.riskAssessmentUsed
      : 0,
  managedRemediation:
    quota.managedRemediation > quota.managedRemediationUsed
      ? quota.managedRemediation - quota.managedRemediationUsed
      : 0,
  version3SlotsUsed:
    quota.version3Slots > quota.version3SlotsUsed
      ? quota.version3Slots - quota.version3SlotsUsed
      : 0,
});

export interface AssessmentMetaQuestion {
  index: number;
  mandatory: boolean;
  question: string;
}
export type AssessmentMetaQuestions = { [idx: string]: AssessmentMetaQuestion };

// special case for surveys since we want this to be a drop down of the customers available surveys
export const ManagedVendorSurveyMetaQn = "survey";

export type ManagedVendorAssessmentMeta = { [question: string]: string };
export interface ManagedVendorAssessment {
  id: number;
  orgId: number;
  watchlistId: number;
  analystId?: number;
  contactId: number;
  licenseId: number;
  assessmentId?: number;
  assessmentStreamId?: number;
  serviceLevel: ManagedVendorServiceLevel;
  withCustomer: boolean;
  meta: ManagedVendorAssessmentMeta;
  metaV2: ManagedVendorMetaV2;
  startedAt?: string;
  finishedAt?: string;
  createdAt: string;
  analystNote?: string;
  serviceStatus: ManagedVendorServiceStatusType;
}

export interface ManagedVendorAssessmentExtra extends ManagedVendorAssessment {
  vendorID: number;
  vendorName: string;
  vendorPrimaryHostName: string;
  vendorTier?: number;
  vendorTierName?: string;
  orgName: string;
  reRequestDate?: string;
}

export type ManagedVendorAssessmentAnalystAction =
  | "Assign analyst"
  | "Start assessment"
  | "Send questionnaire"
  | "Chase up questionnaire"
  | "Review questionnaire"
  | "Complete assessment"
  | "Request remediation"
  | "Chase remediation";

export interface ManagedVendorEvidenceSummary {
  id: number;
  name: string;
  type: string;
  isPublic: boolean;
  filename: string;
  uploadedAt: string;
  numRisks: number;
  documentId: number;
  documentUUID: string;
}

export interface DisplayableManagedVendorAssessment
  extends ManagedVendorAssessmentExtra {
  score: number;
  contact?: IVendorContactResponse;
  analyst?: UserEmailAddress;
  requester?: UserEmailAddress;
  lastUpdated: string;
  assessmentStatus: VendorAssessmentStatus;
  surveyStatusTexts: string[];
  surveysInAssessment?: SurveyAsEvidence[];
  surveyActionRequired: boolean;
  remediationStatus: string;
  remediationActionRequired: boolean;
  analystActionRequired?: ManagedVendorAssessmentAnalystAction;
  contacts: IVendorContactResponse[];
  additionalEvidence: ManagedVendorEvidenceSummary[];
  businessContact?: UserEmailAddress;
  additionalEvidenceIDs?: number[];
  publicEvidenceIDs?: number[];
  workflowSurveyId?: number;
  messageCount: number;
  unreadCount: number;
}

export type AssessmentCorrespondenceType =
  | "AnalystCustomer"
  | "AnalystVendor"
  | "AnalystOnly"
  | "CustomerOnly";

export interface AssessmentCorrespondence {
  id: number;
  managedAssessmentId: number;
  parentId?: number;
  children?: AssessmentCorrespondence[];
  type: AssessmentCorrespondenceType;
  content: string;
  UserId: number;
  createdAt: string;
  updatedAt?: string;
  deletedAt?: string;
}

export type ManagedVendorAssessmentAction =
  | "Created"
  | "Started"
  | "AnalystAssigned"
  | "ServiceLevelChanged"
  | "OnHold"
  | "WithCustomer"
  | "Completed"
  | "ContactChanged"
  | "VendorAssessmentAssigned";

export interface ManagedVendorAssessmentHistory {
  id: number;
  requestId: number;
  at: string;
  type: ManagedVendorAssessmentAction;
  value: string;
  userId?: number;
}

export interface DisplayableManagedVendorAssessmentHistory
  extends ManagedVendorAssessmentHistory {
  userName: string;
  userEmail: string;
  userAvatar: string;
}

export interface ManagedVendorAssessmentRequestVendorInfo {
  contacts?: IVendorContactResponse[];
  labels?: number[];
  tier?: number;
  name?: string;
  loading: boolean;
}

export interface ManagedVendorMetaV2 {
  completionDate: string;
  additionalNotes: string;
  purpose: string;
  products: string;
  systems: string;
  data: string;
  dataLevel: string;
  otherDetails: string;
  documentNotes: string;
}

export const EmptyV2Meta = () => ({
  completionDate: "",
  additionalNotes: "",
  purpose: "",
  products: "",
  systems: "",
  data: "",
  dataLevel: "",
  otherDetails: "",
  documentNotes: "",
});

export type metaSetter = (
  key: keyof ManagedVendorMetaV2,
  val: string,
  valid: boolean
) => void;

export type ManagedVendorMetaWithValid = Record<
  keyof ManagedVendorMetaV2,
  { val: string; valid: boolean }
>;

export const EmptyV2MetaWithValid = (): ManagedVendorMetaWithValid => ({
  data: { val: "", valid: false },
  additionalNotes: { val: "", valid: false },
  purpose: { val: "", valid: false },
  completionDate: { val: "", valid: false },
  dataLevel: { val: "", valid: false },
  otherDetails: { val: "", valid: false },
  products: { val: "", valid: false },
  systems: { val: "", valid: false },
  documentNotes: { val: "", valid: true }, // optional field
});

export const MetaV2WithValidFromMeta = (
  meta: ManagedVendorMetaV2
): ManagedVendorMetaWithValid =>
  Object.entries(meta).reduce((prev, [key, val]) => {
    prev[key as keyof ManagedVendorMetaV2] = { val, valid: true };
    return prev;
  }, EmptyV2MetaWithValid());

export const MetaV2FromMetaWithValid = (
  meta: ManagedVendorMetaWithValid
): ManagedVendorMetaV2 =>
  Object.entries(meta).reduce((prev, [key, val]) => {
    prev[key as keyof ManagedVendorMetaV2] = val.val;
    return prev;
  }, EmptyV2Meta());

// ****************** V3 types below *****************

export interface ManagedVendorAssessmentV3Progress {
  questionnaireId?: number;
  questionnaireStatusText?: string;
  questionnaireStatus?: SurveyStatus;
  questionnaireArchived?: boolean;
  autoFillProgress: number;
  numQuestions: number;
  numAnswers: number;
  numSuggestions: number;
  autofillJobId?: number;
  autoFillStatus?: JobStatus;
}

export const getServiceStatusPillV3 = (
  status: ManagedVendorServiceStatusType,
  includeTooltip = false
) => {
  switch (status) {
    case ManagedVendorServiceStatusType.Blocked:
      return (
        <PillLabel
          color={LabelColor.Red}
          popupContent={
            includeTooltip ? (
              <>
                Our analyst has marked this service as blocked. Please check{" "}
                <b>Messages</b> for more information.
              </>
            ) : undefined
          }
        >
          Blocked
        </PillLabel>
      );
    case ManagedVendorServiceStatusType.OnHold:
      return (
        <PillLabel
          color={LabelColor.Violet}
          popupContent={
            includeTooltip
              ? "Our analyst has put this service on hold. Please check the analyst note for more information."
              : undefined
          }
        >
          On hold
        </PillLabel>
      );
    case ManagedVendorServiceStatusType.New:
      return <PillLabel color={LabelColor.LightBlue}>New</PillLabel>;
    case ManagedVendorServiceStatusType.NotStarted:
      return <PillLabel color={LabelColor.Blue}>Not started</PillLabel>;
    case ManagedVendorServiceStatusType.InProgress:
      return <PillLabel color={LabelColor.Yellow}>In progress</PillLabel>;
    case ManagedVendorServiceStatusType.Complete:
      return <PillLabel color={LabelColor.Green}>Completed</PillLabel>;
  }
};
