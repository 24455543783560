import ModalV2, {
  BaseModalProps,
  useModalV2,
} from "../../../_common/components/ModalV2";
import { SupportedMergeTag } from "../../../_common/types/exportConfig";
import { FC, ReactNode, useMemo } from "react";
import Button from "../../../_common/components/core/Button";

import "../../style/components/reporting/MergeTagsModal.scss";
import { SidePopupV2 } from "../../../_common/components/DismissablePopup";
import { addDefaultSuccessAlert } from "../../../_common/reducers/messageAlerts.actions";
import { useAppDispatch } from "../../../_common/types/reduxHooks";

export const useMergeTagsModal = (
  supportedMergeTags: SupportedMergeTag[] | undefined,
  nounToGenerate?: string
): [(() => void) | undefined, ReactNode] => {
  const [openModal, modal] = useModalV2(MergeTagsModal);

  const openMergeTagsModal = useMemo(() => {
    if (!supportedMergeTags || supportedMergeTags.length === 0) {
      return undefined;
    }

    return () =>
      openModal({
        supportedMergeTags,
        nounToGenerate,
      });
  }, [openModal, supportedMergeTags]);

  return [openMergeTagsModal, modal];
};

interface IMergeTagsModalProps extends BaseModalProps {
  supportedMergeTags: SupportedMergeTag[];
  nounToGenerate?: string;
}

const MergeTagsModal: FC<IMergeTagsModalProps> = ({
  supportedMergeTags,
  nounToGenerate = "report",
  active,
  onClose,
}) => {
  const dispatch = useAppDispatch();
  const copyTagToClipboard = (tag: string) => {
    navigator.clipboard.writeText(tag);
    dispatch(addDefaultSuccessAlert("Copied merge tag to clipboard."));
    onClose();
  };

  return (
    <ModalV2
      active={active}
      onClose={onClose}
      className="merge-tags-modal"
      headerContent="Supported merge tags"
      footerContent={
        <div>
          <Button onClick={onClose}>Close</Button>
        </div>
      }
    >
      <p>
        Insert any of the following merge tags in text content in this report.
        When the {nounToGenerate} is generated, the tags will be replaced with
        the correct data.
      </p>
      <table className="merge-tags-table">
        <tbody>
          {supportedMergeTags.map((t) => (
            <tr key={t.tag}>
              <td className="tag-cell">
                {t.tag}
                <SidePopupV2
                  text="Copy to clipboard"
                  inline
                  noWrap
                  className="copy-popup-container"
                >
                  <div
                    className="cr-icon-copy"
                    onClick={() => copyTagToClipboard(t.tag)}
                  />
                </SidePopupV2>
              </td>
              <td className="value-cell">{t.description}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </ModalV2>
  );
};

export default MergeTagsModal;
