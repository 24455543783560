import { FC, useEffect, useState } from "react";
import { SurveyViewerType } from "./SurveyViewer";
import { SurveyUsageType } from "../../_common/types/surveyTypes";
import Button from "../../_common/components/core/Button";
import classnames from "classnames";
import { ISurveyVersion } from "../../_common/types/survey";
import {
  ButtonWithDropdownV2,
  DropdownItem,
  DropdownSeparator,
} from "../../_common/components/core/DropdownV2";
import { formatDate } from "../../_common/helpers";
import moment from "moment";

import { sortBy as _sortBy } from "lodash";
import { trackEvent } from "../../_common/tracking";
import { DiffSide } from "./nodes/baseNode";
import { surveyViewerRightPanelMode } from "../reducers/reducer";
import { appConnect } from "../../_common/types/reduxHooks";

interface SurveyViewerTopMessageBarOwnProps {
  viewerType: SurveyViewerType;
  isPublicSurvey: boolean;
  isArchived?: boolean;
  isDraft?: boolean;
  vendorName?: string;
  usageType?: SurveyUsageType;
  onGoBack: () => void;
  versions?: ISurveyVersion[];
  selectedAnswerId?: number;
  onSelectVersion: (id: number) => void;
  selectedLeftAnswerId?: number;
  onSelectAnswersForCompare: (id: number, side: DiffSide) => void;
  redlinesEnabled: boolean;
  leftPanelActive: boolean;
  versionLoading: boolean;
  diffLoading: boolean;
  onCompare?: () => void; // if undefined assume that we can't compare versions and don't sure button
  orgName: string;
  isImportedSurvey: boolean;
  isAnalystWorkflowSurvey: boolean;
}

interface SurveyViewerTopMessageBarConnectedProps {
  rightPanelActive: boolean;
  rightPanelMode: surveyViewerRightPanelMode;
}

type SurveyViewerTopMessageBarProps = SurveyViewerTopMessageBarOwnProps &
  SurveyViewerTopMessageBarConnectedProps;

// Simple component to display messages at the top of the survye viewer
const SurveyViewerTopMessageBar: FC<SurveyViewerTopMessageBarProps> = ({
  viewerType,
  isPublicSurvey,
  isArchived,
  isDraft,
  vendorName,
  usageType,
  onGoBack,
  redlinesEnabled,
  versions,
  selectedAnswerId,
  onSelectVersion,
  selectedLeftAnswerId,
  onSelectAnswersForCompare,
  leftPanelActive,
  rightPanelActive,
  rightPanelMode,
  diffLoading,
  versionLoading,
  onCompare,
  orgName,
  isImportedSurvey,
  isAnalystWorkflowSurvey,
}) => {
  const getMessageContent = () => {
    let message;
    if (viewerType === SurveyViewerType.Preview) {
      message =
        usageType === SurveyUsageType.Relationship ? (
          <>
            {rightPanelMode === surveyViewerRightPanelMode.Automation && (
              <>
                This preview of your <u>{isDraft ? "draft" : "published"}</u>{" "}
                relationship questionnaire is used to test your automation
                rules.
              </>
            )}
            {rightPanelMode !== surveyViewerRightPanelMode.Automation && (
              <>
                This is a preview of your{" "}
                <u>{isDraft ? "draft" : "published"}</u> relationship
                questionnaire.
              </>
            )}
          </>
        ) : (
          "You’re viewing an interactive preview of this questionnaire template"
        );
    } else if (
      viewerType === SurveyViewerType.Viewing &&
      !isPublicSurvey &&
      !isArchived
    ) {
      if (isImportedSurvey) {
        message = "Viewing the answers to this questionnaire";
      } else if (usageType === SurveyUsageType.Relationship) {
        message =
          "You’re viewing the recipients' answers to this questionnaire.";
      } else {
        message = `You’re viewing ${
          vendorName ? `${vendorName}'s` : "the recipients'"
        } answers to this questionnaire.`;
      }
    } else if (
      viewerType === SurveyViewerType.Viewing &&
      !isPublicSurvey &&
      isArchived
    ) {
      message =
        "This questionnaire has been archived. You are viewing the latest submitted answers to this questionnaire.";
    } else if (viewerType == SurveyViewerType.Editing) {
      if (isImportedSurvey) {
        message = `Continue answering questionnaire`;
      } else if (isAnalystWorkflowSurvey) {
        message = `Review identified risks and gaps`;
      } else {
        message = `Continue answering questionnaire for ${orgName}`;
      }
    } else if (viewerType == SurveyViewerType.Review) {
      message = `Review identified risks and gaps for ${orgName}`;
    }

    if (message) {
      return <div className={"message-content"}>{message}</div>;
    } else {
      return undefined;
    }
  };

  const content = getMessageContent();

  const classes = classnames("survey-viewer-top-message-bar", "has-content", {
    "diff-mode": viewerType == SurveyViewerType.Diff,
    "single-content":
      viewerType == SurveyViewerType.Editing ||
      viewerType == SurveyViewerType.Preview ||
      viewerType == SurveyViewerType.Review,
    "right-panel-active": rightPanelActive,
    "left-panel-active": leftPanelActive,
  });

  interface versionSelection {
    version: number;
    answerId: number;
    titleText: string; // text to show if this selected
    text: string; // text to show in menu
    currentVersion: boolean;
    selected: boolean;
    onSelectVersion?: () => void;
  }
  const [versionSelections, setVersionSelections] = useState<
    versionSelection[]
  >([]);

  useEffect(() => {
    if (versions) {
      let hasPublished = false;

      setVersionSelections(
        _sortBy(versions, (v) => moment(v.lastUpdated).unix())
          .filter((v) => v.shared)
          .reverse()
          .map((v) => {
            // check if this is the latest (ie current) version of the survey
            const isCurrentVersion = !hasPublished && v.shared;
            // once we have the latest published version this turns hasPublished to true meaning the above is false on the next iteration
            hasPublished ||= isCurrentVersion;

            const selected =
              v.answerId == selectedAnswerId ||
              v.answerId == selectedLeftAnswerId;

            return {
              version: v.version,
              answerId: v.answerId,
              currentVersion: isCurrentVersion,
              text: `Version ${v.version} ${
                isCurrentVersion && viewerType == SurveyViewerType.Diff
                  ? "(Current)"
                  : ""
              } - ${formatDate(v.lastUpdated)}`,
              titleText: `Version ${v.version} ${
                isCurrentVersion ? "(Current)" : ""
              } - ${formatDate(v.lastUpdated)}`,
              selected,
              onSelectVersion: selected
                ? undefined
                : () => onSelectVersion(v.answerId),
            };
          })
      );
    }
  }, [versions, selectedAnswerId, selectedLeftAnswerId, viewerType]);

  // store our list of previous and future versions from versions for switching versions in div mode
  const [previousVersions, setPreviousVersions] = useState<versionSelection[]>(
    []
  );
  const [futureVersions, setFutureVersions] = useState<versionSelection[]>([]);
  useEffect(() => {
    if (versionSelections.length > 0) {
      setPreviousVersions(
        versionSelections.filter(
          (v) => v.version < (rightVersion?.version ?? 0)
        )
      );
      setFutureVersions(
        versionSelections.filter((v) => v.version > (leftVersion?.version ?? 0))
      );
    }
  }, [versionSelections]);

  const hasPublishedVersion = versionSelections.some((v) => v.currentVersion);

  const leftVersion = versionSelections.find(
    (v) => v.answerId == selectedLeftAnswerId
  );
  const rightVersion = versionSelections.find(
    (v) => v.answerId == selectedAnswerId
  );

  return (
    <div className={classes}>
      <div className={"left-content"}>
        {viewerType !== SurveyViewerType.Preview && (
          <Button leftArrow tertiary onClick={() => onGoBack()}>
            Go back
          </Button>
        )}
      </div>
      {viewerType == SurveyViewerType.Diff ? (
        <div className={"version-selection-container"}>
          <div className={"version-selection"}>
            <ButtonWithDropdownV2
              dropdownProps={{
                className: "version-dropdown",
              }}
              text={
                <>
                  {leftVersion?.titleText ?? ""}{" "}
                  <i className={"cr-icon-chevron rotate-90"} />
                </>
              }
              loading={versionLoading}
              overrideLoadingColor={"white"}
            >
              {previousVersions.map((v) => (
                <DropdownItem
                  key={v.answerId}
                  onClick={
                    v.selected
                      ? undefined
                      : () => {
                          trackEvent("QuestionniareChangeVersionSelection", {
                            side: "left",
                          });
                          onSelectAnswersForCompare(v.answerId, "left");
                        }
                  }
                  selected={v.selected}
                >
                  {v.text}
                </DropdownItem>
              ))}
              {previousVersions.length == 0 && (
                <h3 className={"versions-label"}>No previous versions</h3>
              )}
            </ButtonWithDropdownV2>
            <ButtonWithDropdownV2
              dropdownProps={{
                className: "version-dropdown",
              }}
              text={
                <>
                  {rightVersion?.titleText ?? ""}{" "}
                  <i className={"cr-icon-chevron rotate-90"} />
                </>
              }
              loading={versionLoading}
              overrideLoadingColor={"white"}
            >
              {futureVersions.map((v) => (
                <DropdownItem
                  key={v.answerId}
                  onClick={
                    v.selected
                      ? undefined
                      : () => {
                          trackEvent("QuestionniareChangeVersionSelection", {
                            side: "right",
                          });
                          onSelectAnswersForCompare(v.answerId, "right");
                        }
                  }
                  selected={v.selected}
                >
                  {v.text}
                </DropdownItem>
              ))}
              {futureVersions.length == 0 && (
                <h3 className={"versions-label"}>No future versions</h3>
              )}
            </ButtonWithDropdownV2>
          </div>
        </div>
      ) : (
        <div className={"content-div"}>{content}</div>
      )}
      {redlinesEnabled &&
        versionSelections.length > 0 &&
        viewerType != SurveyViewerType.Diff && (
          <ButtonWithDropdownV2
            dropdownProps={{
              className: "version-dropdown single",
            }}
            text={
              <>
                {versionSelections.find((v) => v.selected)?.titleText ?? ""}{" "}
                <i className="cr-icon-chevron rotate-90" />
              </>
            }
            loading={versionLoading}
            disabled={diffLoading}
            overrideLoadingColor={"white"}
          >
            {hasPublishedVersion && (
              <>
                <h3 className={"version-label"}>Current Version</h3>
                <DropdownItem
                  onClick={versionSelections[0].onSelectVersion}
                  selected={versionSelections[0].selected}
                >
                  {versionSelections[0].text}
                </DropdownItem>
              </>
            )}
            {versionSelections.length > 1 && (
              <>
                <h3 className={"version-label"}>Previous Versions</h3>
                {versionSelections.slice(1).map((v) => (
                  <DropdownItem
                    key={v.answerId}
                    onClick={v.onSelectVersion}
                    selected={v.selected}
                  >
                    {`${v.text}`}
                  </DropdownItem>
                ))}
              </>
            )}
            {onCompare && (
              <>
                <DropdownSeparator />
                <DropdownItem onClick={onCompare}>
                  <i className={"cr-icon-compare"} /> Show changes
                </DropdownItem>
              </>
            )}
          </ButtonWithDropdownV2>
        )}
    </div>
  );
};

export default appConnect<
  SurveyViewerTopMessageBarConnectedProps,
  never,
  SurveyViewerTopMessageBarOwnProps
>((state) => ({
  rightPanelActive: state.surveyViewer.rightPanel.active,
  rightPanelMode: state.surveyViewer.rightPanel.mode,
}))(SurveyViewerTopMessageBar);
