import Button from "../../../_common/components/core/Button";
import ModalV2 from "../../../_common/components/ModalV2";
import "../../style/components/MonitorVendorModal.scss";
//import vendorsIconSrc from "../../images/add-vendor-icon.svg";
import { getVendorWords } from "../../../_common/constants";
import { AssuranceType } from "../../../_common/types/organisations";
import { updateVendorWatchStatusWithRefresh } from "../../reducers/cyberRiskActions";
import { useMemo, useState } from "react";
import { DefaultThunkDispatch } from "../../../_common/types/redux";
import { addDefaultUnknownErrorAlert } from "../../../_common/reducers/messageAlerts.actions";
import { contactSupport } from "../../../_common/helpers";
import {
  SelfSignupBetaAccess,
  useHasOrgEntitlement,
} from "../../../_common/permissions";
import { useHistory } from "react-router-dom";
import { locationState } from "../../../_common/types/router";
import { History } from "history";

interface MonitorVendorModalProps {
  dispatch: DefaultThunkDispatch;
  isActive: boolean;
  onClose: () => void;
  onSuccess: () => void;
  assuranceType: AssuranceType;
  vendorID?: number;
  vendorName?: string;
  vendorPrimaryHostname?: string;
  assessedVendorsOnly?: boolean;
  vendorWatchLimit: number;
  vendorWatchCount: number;
}

// MonitorVendorModal
// Provides a modal implementation to allow the reporting workflow to monitor a vendor prior to continuing with
// the report-specification steps.
const MonitorVendorModal = ({
  dispatch,
  assuranceType,
  isActive,
  onClose,
  onSuccess,
  vendorName,
  vendorID,
  vendorPrimaryHostname,
  assessedVendorsOnly,
  vendorWatchLimit,
  vendorWatchCount,
}: MonitorVendorModalProps) => {
  const [loading, setLoading] = useState(false);
  const atOrOverWatchLimit = useMemo(() => {
    return vendorWatchLimit > 0 && vendorWatchCount >= vendorWatchLimit;
  }, [vendorWatchLimit, vendorWatchCount]);

  const vendorWords = getVendorWords(assuranceType);
  const history = useHistory<locationState>();
  const selfSignupBetaAccess = useHasOrgEntitlement(SelfSignupBetaAccess);

  let title = `Monitor ${vendorWords.singular}`;
  if (atOrOverWatchLimit) {
    title = `Increase your ${vendorWords.singular} limit`;
  }

  const overVendorMessage = (
    history: History<locationState>,
    selfSignupBetaAccess: boolean
  ) =>
    selfSignupBetaAccess ? (
      <>
        <p>{`A ${vendorWords.singular} must be monitored before you generate a report, and you've reached your ${vendorWords.singular} limit. Upgrade your plan to add more ${vendorWords.plural}.`}</p>
        <Button
          className="butt-support"
          onClick={() => history.push("settings/billing")}
        >
          Upgrade Plan
        </Button>
      </>
    ) : (
      <>
        <p>{`A ${vendorWords.singular} must be monitored before you generate a report, and you've reached your ${vendorWords.singular} limit. Contact us to add more ${vendorWords.plural}.`}</p>
        <Button className="butt-support" onClick={() => contactSupport()}>
          Contact us
        </Button>
      </>
    );
  const initialTextLine = (
    <>
      {!atOrOverWatchLimit && (
        <p>
          {`To generate a report for ${vendorName}, you need to add them to your monitored ${vendorWords.plural}. Monitoring a ${vendorWords.singular} helps you continuously track their security posture.`}
        </p>
      )}
      {atOrOverWatchLimit && overVendorMessage(history, selfSignupBetaAccess)}
    </>
  );

  const monitorVendor = () => {
    setLoading(true);
    dispatch(
      updateVendorWatchStatusWithRefresh(
        vendorID,
        true,
        history,
        [],
        vendorName,
        vendorPrimaryHostname
      )
    )
      .then(() => onSuccess())
      .catch(() => {
        dispatch(
          addDefaultUnknownErrorAlert(
            `An error occurred attempting to monitor ${vendorWords.singular} ${vendorName}. Please contact Sales directly at sales@upguard.com.`
          )
        );
      })
      .finally(() => setLoading(false));
  };

  return (
    <ModalV2
      className={"monitor-vendor-modal"}
      footerClassName={"monitor-vendor-modal-footer"}
      active={isActive}
      onClose={onClose}
      headerClassName={"monitor-vendor-modal-header"}
      footerContent={
        <>
          <Button
            filledPrimary
            loading={loading}
            arrow={true}
            disabled={!vendorID || !vendorPrimaryHostname || atOrOverWatchLimit}
            onClick={() => {
              monitorVendor();
            }}
          >
            {!assessedVendorsOnly &&
              `Monitor ${vendorWords.singular} and continue`}
            {!!assessedVendorsOnly &&
              `Monitor ${vendorWords.singular} and conduct risk assessment`}
          </Button>
        </>
      }
    >
      <div>
        <h2>{title}</h2>
        <div>{initialTextLine}</div>
        {!atOrOverWatchLimit && (
          <div className={"link"}>
            <a
              href={
                "https://help.upguard.com/en/articles/3777081-what-is-the-vendors-section"
              }
              target="_blank"
              rel="noopener noreferrer"
            >{`Learn more about monitoring ${vendorWords.plural}`}</a>
            <i className={"cr-icon-arrow-right"} />
          </div>
        )}
      </div>
    </ModalV2>
  );
};

export default MonitorVendorModal;
