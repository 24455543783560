import { castDraft, Draft, produce } from "immer";

// produceArray
// adds or removes the given element to the array using product
export const produceArray = <T>(array: T[], item: T): T[] =>
  produce(array, (draft) => {
    const idx = draft.indexOf(castDraft(item));
    if (idx < 0) {
      draft.push(castDraft(item));
    } else {
      draft.splice(idx, 1);
    }
  });

// produceArrayWithFind
// adds or removes an item from the array based on the predicate function
export const produceArrayWithFind = <T>(
  array: T[],
  item: T,
  predicate: (item: T, arrayItem: Draft<T>) => boolean
): T[] =>
  produce(array, (draft) => {
    const idx = draft.findIndex((arrayItem) => predicate(item, arrayItem));
    if (idx < 0) {
      draft.push(castDraft(item));
    } else {
      draft.splice(idx, 1);
    }
  });
