import { useEffect, useState } from "react";
import { SlidePanelSection } from "./SlidePanelSection";
import LoadingIcon from "../../../_common/components/core/LoadingIcon";
import { DefaultThunkDispatchProp } from "../../../_common/types/redux";

import { getVendorWords } from "../../../_common/constants";
import { VendorAssessmentClassificationMap } from "../../types/vendorAssessments";
import VendorAssessmentClassificationChoiceSet from "../VendorAssessment/VendorAssessmentClassificationChoiceSet";
import { fetchAssessmentBreakdown } from "../../reducers/vendorAssessment.actions";
import { AssuranceType } from "../../../_common/types/organisations";
import { appConnect } from "../../../_common/types/reduxHooks";

interface VendorAssessmentClassificationFilterOwnProps {
  assuranceType: AssuranceType;
  selectedAssessmentClassifications: number[];
  onChange: (classifications: number[]) => void;
  title?: string;
  startExpanded?: boolean;
}

interface VendorAssessmentClassificationFilterConnectedProps {
  classifications: VendorAssessmentClassificationMap;
}

type VendorAssessmentClassificationFilterProps =
  VendorAssessmentClassificationFilterOwnProps &
    VendorAssessmentClassificationFilterConnectedProps &
    DefaultThunkDispatchProp;

const VendorAssessmentClassificationFilter = (
  props: VendorAssessmentClassificationFilterProps
) => {
  const [expanded, setExpanded] = useState(
    props.startExpanded || props.selectedAssessmentClassifications.length > 0
  );

  // Initial load
  useEffect(() => {
    if (Object.keys(props.classifications).length === 0) {
      props.dispatch(fetchAssessmentBreakdown()).catch(() => {
        // Handled elsewhere
      });
    }
  }, []);

  const vendorWords = getVendorWords(props.assuranceType);

  return (
    <SlidePanelSection
      title={
        props.title
          ? props.title
          : `Filter by ${vendorWords.singular} assessment status`
      }
      toggleExpand={() => setExpanded(!expanded)}
      expanded={expanded}
    >
      {Object.keys(props.classifications).length === 0 && (
        <LoadingIcon size={32} />
      )}
      {Object.keys(props.classifications).length > 0 && (
        <VendorAssessmentClassificationChoiceSet
          multiSelect
          classifications={props.classifications}
          selectedClassifications={props.selectedAssessmentClassifications}
          onSelectionChange={(selected) => props.onChange(selected)}
        />
      )}
    </SlidePanelSection>
  );
};

export default appConnect<
  VendorAssessmentClassificationFilterConnectedProps,
  never,
  VendorAssessmentClassificationFilterOwnProps
>((state) => {
  return {
    classifications: state.cyberRisk.vendorAssessmentClassifications ?? {},
  };
})(VendorAssessmentClassificationFilter);
