import { Component } from "react";
import PropTypes from "prop-types";

import Button from "./core/Button";
import { closeModal } from "../reducers/commonActions";

export const YesNoModalName = "YesNoModalName";

class YesNoModal extends Component {
  static propTypes = {
    modalData: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
  };

  state = { loading: false };

  onYesClick = () => {
    this.setState({ loading: true });

    // Using Promise.resolve to handle "thenable" promises as well as regular synchronous functions.
    // Returning true from the buttonAction will ensure the closeModal action is not called, such as
    // for opening another modal as a result of the action.
    Promise.resolve(this.props.modalData.yesButtonAction())
      .then((noClose = false) => {
        if (!noClose) {
          this.props.dispatch(closeModal());
        }
      })
      .catch(() => {
        // On error returned from promise, take us out of loading state but keep modal open.
        this.setState({ loading: false });
      });
  };

  onNoClick = () => {
    this.setState({ loading: true });

    // Using Promise.resolve to handle "thenable" promises as well as regular synchronous functions.
    // Returning true from the buttonAction will ensure the closeModal action is not called, such as
    // for opening another modal as a result of the action.
    Promise.resolve(this.props.modalData.noButtonAction())
      .then((noClose = false) => {
        if (!noClose) {
          this.props.dispatch(closeModal());
        }
      })
      .catch(() => {
        // On error returned from promise, take us out of loading state but keep modal open.
        this.setState({ loading: false });
      });
  };

  render() {
    const { title, description, yesText, noText, hideCancel } =
      this.props.modalData;
    return (
      <div className="modal-content">
        <h2>{title}</h2>
        {description ? <div>{description}</div> : <p />}
        <form>
          <div className="btn-group no-margin">
            {!hideCancel && (
              <Button
                disabled={this.state.loading}
                onClick={() => this.props.dispatch(closeModal())}
              >
                Cancel
              </Button>
            )}
            <Button disabled={this.state.loading} onClick={this.onNoClick}>
              {noText || "No"}
            </Button>
            <Button
              primary
              loading={this.state.loading}
              disabled={this.state.loading}
              onClick={this.onYesClick}
            >
              {yesText || "Yes"}
            </Button>
          </div>
        </form>
      </div>
    );
  }
}

export default YesNoModal;
