import { FC, ReactNode, useEffect, useState } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import Icon from "./core/Icon";
import "../style/components/ExpandableItem.scss";

interface ExpandableItemProps {
  header: ReactNode;
  children: ReactNode;
  startExpanded?: boolean;
  expanded?: boolean;
  toggleExpand?: () => void;
}

const ExpandableItem: FC<ExpandableItemProps> = ({
  header,
  children,
  startExpanded,
  expanded,
  toggleExpand,
}) => {
  const [expandedInternal, setExpandedInternal] = useState(!!startExpanded);

  // Respond to parent changing expand state if managed externally
  useEffect(() => {
    if (expanded === true || expanded === false) {
      setExpandedInternal(expanded);
    }
  }, [expanded]);

  const expandToggle = () => {
    if (toggleExpand) {
      // Managed externally, so notify
      toggleExpand();
    } else {
      setExpandedInternal(!expandedInternal);
    }
  };

  return (
    <div className={"expandable-item"}>
      <div onClick={expandToggle} className="expandable-item-header">
        <a className="expandable-item-header-link">
          {header}
          <Icon
            name={"chevron"}
            direction={expandedInternal ? 0 : 180}
            className="expandable-item-header-icon"
          />
        </a>
      </div>
      <TransitionGroup>
        {expandedInternal && (
          <CSSTransition key={"expand-row"} timeout={250} classNames="expand">
            <div className={"expandable-item-content"}>{children}</div>
          </CSSTransition>
        )}
      </TransitionGroup>
    </div>
  );
};

export default ExpandableItem;
