import ReportCard from "./ReportCard";
import "../style/components/ScoreProjection.scss";
import ColorGrade from "../../vendorrisk/components/executive_summary/ColorGrade";
import Score from "../../vendorrisk/components/Score";
import ScoreSpinner from "../images/score-icon-spinner.svg";
import ScoreShield from "../images/score-icon-shield.svg";
import LoadingIcon from "./core/LoadingIcon";

interface IScoreProjectionRequestRemediationProps {
  nothingSelected: boolean;
  working: boolean;
  numRisksSelected: number;
  numAffectedAssets: number;
  projectedScore: number;
  scoreDiff: number;
  surveyScoresNotMerged: boolean;
  /**
   * If this is set, it will be displayed on the result title
   */
  scoreOwner?: string;
}

const ScoreProjectionRequestRemediation: React.FC<
  IScoreProjectionRequestRemediationProps
> = (props) => {
  const { scoreOwner } = props;
  const resultTitle = scoreOwner
    ? `Score impact for ${scoreOwner}`
    : "Score impact";
  return (
    <ReportCard className={"score-projection-request-remediation"}>
      <div className={"title"}>Your remediation plan</div>
      <div className={"body"}>
        <div className={"boxes-row"}>
          <div className={"small-box"}>
            <div className={"label"}>Risks selected</div>
            <div className={"value"}>
              {props.nothingSelected ? "-" : `${props.numRisksSelected}`}
            </div>
          </div>
          <div className={"small-box"}>
            <div className={"label"}>Affected assets</div>
            <div className={"value"}>
              {props.nothingSelected || props.numAffectedAssets < 0 ? (
                "-"
              ) : props.working ? (
                <LoadingIcon size={16} />
              ) : (
                `${props.numAffectedAssets}`
              )}
            </div>
          </div>
        </div>
        <div className={"impact"}>
          <div className={"results-title"}>{resultTitle}</div>
          {!props.nothingSelected && !props.working && (
            <div className={"results"}>
              <div className={"heading"}>Potential new score</div>
              <div className={"new-score"}>
                <ColorGrade score={props.projectedScore} />
                <Score colored large score={props.projectedScore} />
                <div className={"score-text-addendum"}>{`(${
                  props.scoreDiff >= 0 ? "+" : ""
                }${props.scoreDiff})`}</div>
              </div>
              <div className={"description"}>
                {props.scoreDiff === 0
                  ? `Based on the risks and assets you’ve selected, the score will likely remain the same if these risks are remediated${
                      props.surveyScoresNotMerged ? "*" : ""
                    }.`
                  : `Based on the risks and assets you’ve selected the score will
likely ${props.scoreDiff > 0 ? "increase" : "decrease"} by ${
                      props.scoreDiff
                    } points${
                      props.surveyScoresNotMerged ? "*" : ""
                    } if all risks are remediated.`}
              </div>
              {props.surveyScoresNotMerged && (
                <div className={"description"}>
                  {"* Due to your account settings we cannot take into consideration questionnaire risks when determining " +
                    "the projected score change."}
                </div>
              )}
            </div>
          )}
          {props.working && (
            <div className={"results-empty"}>
              <img src={ScoreSpinner} className={"spin"} />
              <img src={ScoreShield} className={"up"} />
              <div className={"heading"}>Calculating score impact</div>
              <div className={"description"}>
                Our proprietary scoring algorithm not only scores each risk
                individually but also measures how they interact. That’s why it
                can take time for us to calculate the score impact.
              </div>
            </div>
          )}
          {props.nothingSelected && !props.working && (
            <div className={"results-empty"}>
              <img src={ScoreSpinner} />
              <img src={ScoreShield} className={"up"} />
              <div className={"heading"}>Calculate your score impact</div>
              <div className={"description"}>
                Select a risk and its associated assets to begin calculating the
                impact of the risks on the score.
              </div>
            </div>
          )}
        </div>
      </div>
    </ReportCard>
  );
};

export default ScoreProjectionRequestRemediation;
