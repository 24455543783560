import { FC, useContext, useEffect } from "react";
import { useLocation, useRouteMatch } from "react-router-dom";
import { LogError } from "../../_common/helpers";
import { ignoredPathsForPageviews, pathReplacers } from "./PageviewTracker";
import { GetAnonymousTrackingID } from "../../_common/components/Auth0Wrapper.v2";
import { BasenameContext } from "../../_common/contexts";
import { appendParams } from "../helpers/util";

interface IPublicPageviewTrackerOwnProps {
  uuid?: string;
  event_type?: string;
}

const defaultProps: IPublicPageviewTrackerOwnProps = {
  uuid: GetAnonymousTrackingID(),
  event_type: "pageView",
};

const PublicPageviewTracker: FC<IPublicPageviewTrackerOwnProps> = (props) => {
  const { uuid, event_type } =
    props as Required<IPublicPageviewTrackerOwnProps>;
  const { params, path } = useRouteMatch();
  const { hash, search } = useLocation();
  const referrer = document.referrer;
  const url = document.URL;
  const basename = useContext(BasenameContext);
  // Construct the full path, defending against potential double slashes "//"
  const fullPath =
    "/" +
    [
      ...basename.split("/").filter(Boolean),
      ...path.split("/").filter(Boolean),
    ].join("/");

  useEffect(
    () => {
      // First check if this is an ignored path
      if (
        ignoredPathsForPageviews.some((checkPath) => path.startsWith(checkPath))
      ) {
        return;
      }

      let replacedPath = fullPath;
      if (params) {
        // See if we should replace the path with any of the path replacers
        for (let i = 0; i < pathReplacers.length; i++) {
          const [pathMatch, replaceFunc] = pathReplacers[i];
          if (fullPath.startsWith(pathMatch)) {
            replacedPath = replaceFunc(fullPath, params);
            break;
          }
        }
      }

      const sendPageEvent = async () => {
        const analyticsPath = "/api/ae/p/u/v1";
        const params = {
          path: replacedPath,
          url: url,
          referrer: referrer,
          hash: hash,
          search: search,
          unique_id: uuid,
          evt_type: event_type,
        };

        const fetchUrl = appendParams(analyticsPath, params);

        await fetch(fetchUrl, { method: "PUT" });
      };

      try {
        sendPageEvent();
      } catch (e) {
        LogError("Error logging event", e);
        // don't pass this up as we don't want user to see errors for this
      }
    },
    // We're purposely not including params in the dependency array because it changes
    // signature on every render. Including "url" ensures new page views are tracked when the params change.
    [fullPath, url, referrer, hash, search]
  );

  return null;
};

PublicPageviewTracker.defaultProps = defaultProps;

export default PublicPageviewTracker;
