import { History } from "history";
import {
  GetQuestionnaireStatusItem,
  ISurveyListItemResponse,
  SurveyStatus,
} from "../../_common/types/survey";

import XTable, {
  IXTableColumnHeader,
  IXTableRow,
  SortDirection,
  XTableCell,
} from "../../_common/components/core/XTable";
import { usePagination, useSorting } from "../../_common/hooks";
import PillLabel from "../../vendorrisk/components/PillLabel";
import moment from "moment/moment";
import { UserAvatarAndName } from "../../_common/components/UserAvatar";

import "../style/VendorSurveyList.scss";
import EmptyCardWithAction from "../../_common/components/EmptyCardWithAction";
import SurveyName from "../../vendorrisk/components/surveys/SurveyName";
import { LabelColor } from "../../_common/types/label";
import { useEffect, useState } from "react";
import TabButtons from "../../_common/components/TabButtons";
import { CorrespondenceIconButton } from "../../vendorrisk/components/CorrespondenceIconButton";
import { appConnect } from "../../_common/types/reduxHooks";
import { SurveyUsageType } from "../../_common/types/surveyTypes";
import {
  tasksRouterUrlPrefix,
  trustExchangeTaskUrlPrefix,
  vendorRiskTaskUrlPrefix,
} from "../../_common/routers/TasksRouter";

export type SurveyListTab = "active" | "archived";

interface IVendorSurveyListOwnProps {
  history: History;
  surveyIds?: number[];
  hideDueDate?: boolean;
  showVendorName?: boolean;
  isCollaboratorPortal?: boolean;
  isLoading?: boolean;
}

interface IIVendorSurveyListConnectedProps {
  surveys?: ISurveyListItemResponse[];
}

type IVendorSurveyListProps = IVendorSurveyListOwnProps &
  IIVendorSurveyListConnectedProps;

export const VendorSurveyListFC = ({
  history,
  surveys,
  hideDueDate = false,
  showVendorName = false,
  isCollaboratorPortal = false,
  isLoading = false,
}: IVendorSurveyListProps) => {
  const [activeTabId, setActiveTabId] = useState<SurveyListTab>("active");

  useEffect(() => {
    if (!surveys) {
      return;
    }

    // switch automatically to the archived tab if there are only archived surveys
    const numActive = surveys.filter((s) => !s.archived).length;
    const numArchived = surveys.filter((s) => s.archived).length;
    if (numActive === 0 && numArchived > 0) {
      setActiveTabId("archived");
    }
  }, [surveys]);

  const activeSurveys = surveys?.filter((s) => !s.archived);
  const archivedSurveys = surveys?.filter((s) => s.archived);

  const displayedSurveys =
    activeTabId === "active" ? activeSurveys : archivedSurveys;

  const vendorSurveyListColumnHeaders: IXTableColumnHeader[] = [
    {
      id: "name",
      text: "Name",
      sortable: true,
      startingSortDir: SortDirection.ASC,
    },
  ];

  // We either show the vendor name, OR the requesting company
  if (showVendorName) {
    vendorSurveyListColumnHeaders.push({
      id: "vendor",
      text: "Vendor",
      sortable: true,
      startingSortDir: SortDirection.ASC,
    });
  } else {
    vendorSurveyListColumnHeaders.push({
      id: "company",
      text: "Requested by",
      sortable: true,
      startingSortDir: SortDirection.ASC,
    });
  }

  vendorSurveyListColumnHeaders.push(
    ...[
      {
        id: "status",
        text: "Status",
        sortable: true,
        startingSortDir: SortDirection.ASC,
      },
      { id: "progress", text: "Progress", sortable: false },
    ]
  );

  if (!hideDueDate) {
    vendorSurveyListColumnHeaders.push({
      id: "due",
      text: "Date due",
      sortable: true,
      startingSortDir: SortDirection.DESC,
    });
  }

  vendorSurveyListColumnHeaders.push(
    ...[
      {
        id: "created",
        text: "Created",
        sortable: true,
        startingSortDir: SortDirection.DESC,
      },
      { id: "sentby", text: "Sent by", sortable: false },
      { id: "messages", text: "Messages", sortable: false },
    ]
  );

  const [sortedSurveys, sortedBy, onSortChange] = useSorting<
    ISurveyListItemResponse,
    "company" | "vendor" | "name" | "status" | "due" | "created"
  >(displayedSurveys ?? [], "created", SortDirection.DESC, {
    company: {
      orderFuncs: [(s) => s.fromCompany],
      sortDirsAsc: ["asc", "desc"],
      sortDirsDesc: ["desc", "desc"],
    },
    vendor: {
      orderFuncs: [(s) => s.vendorName],
      sortDirsAsc: ["asc", "desc"],
      sortDirsDesc: ["desc", "desc"],
    },
    name: {
      orderFuncs: [(s) => s.type, (s) => s.dueDate],
      sortDirsAsc: ["asc", "desc"],
      sortDirsDesc: ["desc", "desc"],
    },
    status: {
      orderFuncs: [
        (s) => GetQuestionnaireStatusItem(s.status, s.dueDate, true).text,
        (s) => s.dueDate,
      ],
      sortDirsAsc: ["asc", "desc"],
      sortDirsDesc: ["desc", "desc"],
    },
    due: {
      orderFuncs: [(s) => s.dueDate],
      sortDirsAsc: ["asc"],
      sortDirsDesc: ["desc"],
    },
    created: {
      orderFuncs: [(s) => s.dateCreated],
      sortDirsAsc: ["asc"],
      sortDirsDesc: ["desc"],
    },
  });

  const [currentPageSurveys, currentPage, totalPages, onPageChange] =
    usePagination(sortedSurveys, 20);

  const rows: IXTableRow[] = currentPageSurveys.map((s) => {
    const statusItem = GetQuestionnaireStatusItem(s.status, s.dueDate, true);

    const cells: JSX.Element[] = [
      <XTableCell key="name">
        <SurveyName survey={s} />
      </XTableCell>,
    ];

    if (showVendorName) {
      cells.push(<XTableCell key="vendor">{s.vendorName}</XTableCell>);
    } else {
      cells.push(<XTableCell key="company">{s.fromCompany}</XTableCell>);
    }

    cells.push(
      <XTableCell key="status">
        <PillLabel color={statusItem.labelColor}>{statusItem.text}</PillLabel>
        {s.archived && <PillLabel color={LabelColor.Red}>Archived</PillLabel>}
      </XTableCell>,
      <XTableCell key="progress">
        {s.status === SurveyStatus.Sent &&
        (s.numQuestions == 0 || s.numAnswers == 0)
          ? "0%"
          : s.numQuestions > 0
            ? `${Math.floor((s.numAnswers / s.numQuestions) * 100)}%`
            : ""}
      </XTableCell>
    );

    if (!hideDueDate) {
      cells.push(
        <XTableCell key="due">
          {s.dueDate ? moment(s.dueDate).format("ll") : ""}
        </XTableCell>
      );
    }

    // Set the URL prefix (for opening the questionnaire and coming back to the list)
    // For a collaborator, use the /surveycollaboration route
    // Otherwise, use the /tasks/<product>/questionnaire route, depending on the usage type
    const urlPrefix = isCollaboratorPortal
      ? "/surveycollaboration"
      : s.usageType === SurveyUsageType.Relationship
        ? `/${tasksRouterUrlPrefix}/${vendorRiskTaskUrlPrefix}/questionnaires`
        : `/${tasksRouterUrlPrefix}/${trustExchangeTaskUrlPrefix}/questionnaires`;

    cells.push(
      ...[
        <XTableCell key="created">
          {moment(s.dateCreated).format("ll")}
        </XTableCell>,
        <XTableCell key="sentby">
          {s.fromUser && (
            <UserAvatarAndName
              avatar={s.fromUser?.avatar}
              name={s.fromUser.name}
            />
          )}
        </XTableCell>,
        <XTableCell key="messages" className="messages">
          <CorrespondenceIconButton
            totalMessages={s.messages}
            haveUnreadMessages={s.unreadMessages > 0}
            onClick={() =>
              history.push(`${urlPrefix}/${s.id}`, {
                openComments: true,
                backContext: {
                  backTo: "${urlPrefix}",
                  backToText: "Back to Questionnaires",
                },
              })
            }
          />
        </XTableCell>,
      ]
    );

    return {
      id: s.id,
      onClick: () =>
        history.push(`${urlPrefix}/${s.id}`, {
          backContext: {
            goBack: true,
            backToText: "Back to Questionnaires",
          },
        }),
      cells: cells,
    };
  });

  const activeCount = activeSurveys ? ` (${activeSurveys.length})` : "";
  const archivedCount = archivedSurveys ? ` (${archivedSurveys.length})` : "";

  return (
    <div className={"vendor-survey-list"}>
      <TabButtons
        tabs={[
          {
            id: "active",
            text: `Active${activeCount}`,
          },
          {
            id: "archived",
            text: `Archived${archivedCount}`,
          },
        ]}
        activeTabId={activeTabId}
        onChangeTab={(tabId) => setActiveTabId(tabId)}
      />
      <XTable
        columnHeaders={vendorSurveyListColumnHeaders}
        loading={!surveys || isLoading}
        sortedBy={sortedBy}
        onSortChange={onSortChange}
        rows={rows}
        pagination={{
          currentPage,
          totalPages,
          onPageChange,
          hidePaginationIfSinglePage: true,
        }}
        emptyContent={
          <EmptyCardWithAction
            emptyText="No questionnaires"
            emptySubText="No questionnaires to answer."
          />
        }
      />
    </div>
  );
};

export default appConnect<
  IIVendorSurveyListConnectedProps,
  never,
  IVendorSurveyListOwnProps
>((state, props) => {
  let surveys: ISurveyListItemResponse[] | undefined;

  if (props.surveyIds) {
    surveys = [];

    for (let i = 0; i < props.surveyIds.length; i++) {
      const surveyId = props.surveyIds[i];
      const survey = state.common.surveys[surveyId]?.survey;
      if (survey) {
        surveys.push(survey);
      }
    }
  }

  return {
    surveys,
  };
})(VendorSurveyListFC);
