import { Component } from "react";
import { DefaultThunkDispatch } from "../../../_common/types/redux";
import { History } from "history";
import LoadingBanner from "../../../_common/components/core/LoadingBanner";
import { OptionType, SelectV2 } from "../../../_common/components/SelectV2";
import LoadingIcon from "../../../_common/components/core/LoadingIcon";
import Button from "../../../_common/components/core/Button";
import InfoBanner, { BannerType } from "../InfoBanner";
import { fetchOAuth2MessagingServiceChannelList } from "../../reducers/oauth.actions";
import { addMessageAlert } from "../../../_common/reducers/messageAlerts.actions";
import { MESSAGING_SERVICE_SLACK } from "../../views/CreateIntegration";

interface IChannel {
  name: string;
}
export interface ISlackChannelData {
  channels?: IChannel[];
  loading: boolean;
  error?: string;
}

interface ISelectSlackDestinationStepProps {
  history: History;
  dispatch: DefaultThunkDispatch;
  loading: boolean;
  slackChannelData?: ISlackChannelData;
  description?: string;
  selectedChannel?: string;
  setDescription: (name: string) => void;
  setSelectedChannel: (channel: string) => void;
}

class SelectSlackDestinationStep extends Component<ISelectSlackDestinationStepProps> {
  static defaultProps = {
    description: "",
    slackChannelData: { loading: true } as ISlackChannelData,
  };

  constructor(props: ISelectSlackDestinationStepProps) {
    super(props);
  }

  refreshSlackChannelList = async () => {
    try {
      await this.props.dispatch(
        fetchOAuth2MessagingServiceChannelList(
          MESSAGING_SERVICE_SLACK,
          false,
          true
        )
      );
    } catch (e) {
      this.props.dispatch(
        addMessageAlert({
          message: `Failed to refresh channel list: "${e}"`,
          type: BannerType.ERROR,
        })
      );
    }
  };

  render() {
    const selectedChannel = this.props.selectedChannel
      ? this.props.selectedChannel
      : "";

    let channelsHaveChanged = !!selectedChannel;
    const channelOptions = [] as OptionType[];
    if (
      this.props.slackChannelData &&
      !this.props.slackChannelData.loading &&
      !this.props.slackChannelData.error &&
      this.props.slackChannelData.channels
    ) {
      this.props.slackChannelData.channels.map((channel) => {
        if (channel.name == selectedChannel) {
          channelsHaveChanged = false;
        }
        channelOptions.push({ value: channel.name, label: "#" + channel.name });
      });
    }

    if (channelOptions.length == 0) {
      channelsHaveChanged = false;
    }

    return (
      <div className="section-step">
        <div id={"destination"}>
          {this.props.loading && <LoadingBanner />}
          {!this.props.loading && (
            <>
              <div className={"title-bar"}>
                <div className="header">Name & destination</div>
              </div>
              <div className={"body"}>
                <div className={"field"}>
                  <div className="field-desc">
                    Name of the integration*
                    <p>Please provide a name for this integration.</p>
                  </div>
                  <div className="field-input">
                    <input
                      type="text"
                      name="description"
                      value={this.props.description}
                      placeholder="Name of integration"
                      onChange={(e) => {
                        this.props.setDescription(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className={"field"}>
                  <div className="field-desc">
                    Select Slack channel
                    <p>Select the channel that messages will be posted to.</p>
                  </div>
                  <div className="field-input">
                    <SelectV2
                      placeholder="Select Slack channel"
                      options={channelOptions}
                      value={
                        selectedChannel
                          ? {
                              value: selectedChannel,
                              label: "#" + selectedChannel,
                            }
                          : undefined
                      }
                      onChange={(selected) => {
                        if (selected) {
                          this.props.setSelectedChannel(
                            selected.value as string
                          );
                        }
                      }}
                      className={"channel-select"}
                      isDisabled={
                        !this.props.slackChannelData ||
                        this.props.slackChannelData.loading
                      }
                    />
                    <div className={"under-select"}>
                      {(!this.props.slackChannelData ||
                        this.props.slackChannelData.loading) && (
                        <LoadingIcon
                          color={"grey"}
                          className={"loading-spinner"}
                        />
                      )}
                      <Button
                        tertiary
                        className={"reset-button"}
                        disabled={
                          !this.props.slackChannelData ||
                          this.props.slackChannelData.loading
                        }
                        onClick={this.refreshSlackChannelList}
                      >
                        {this.props.slackChannelData &&
                          !this.props.slackChannelData.loading && (
                            <div className="cr-icon-redo" />
                          )}
                        {"Refresh channel list"}
                      </Button>
                      {channelsHaveChanged && (
                        <span className={"list-warning"}>
                          {
                            "* The channel list seems to have changed. Please select a new channel."
                          }
                        </span>
                      )}
                    </div>
                    <InfoBanner
                      className={"small-info"}
                      message={"Can’t find a private channel?"}
                      subItems={[
                        "If you can’t see a private channel in this list, invite @UpGuard to the Slack channel first and refresh this list to see it.",
                      ]}
                    />
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    );
  }
}

export default SelectSlackDestinationStep;
