import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../_common/types/reduxHooks";
import { getFiltersFromState } from "../reducers/filters.actions";
import DomainsAPI from "../reducers/domains.api";
import XTable, {
  IIconOption,
  IXTableColumnHeader,
  IXTableRow,
  SortDirection,
  XTableCell,
} from "../../_common/components/core/XTable";
import { TourHighlightFromPLGContent } from "../../_common/components/TourHighlight";
import EllipsizedText from "../../_common/components/EllipsizedText";
import PillLabel from "../components/PillLabel";
import DomainSourceLabelList from "../components/domains/DomainSourceLabelList";
import ColorGrade, {
  ColorGradeSize,
} from "../components/executive_summary/ColorGrade";
import Score from "../components/Score";
import DateTimeFormat from "../../_common/components/core/DateTimeFormat";
import { LabelClassification, LabelColor } from "../../_common/types/label";
import ReportCard from "../../_common/components/ReportCard";
import {
  removeCustomerCloudscan,
  scanHostname,
} from "../reducers/cyberRiskActions";
import LabelList from "../components/LabelList";
import { useManagedOrgID, useSortingWithPagination } from "../../_common/hooks";
import { setLabelsForWebsites } from "../reducers/domainLabels.actions";
import { Domain } from "../../_common/types/domains";
import { produce } from "immer";
import { difference, intersectionWith, union } from "lodash";
import DomainIPActionBar from "../components/DomainIPActionBar";
import SearchEmptyCard from "../../_common/components/SearchEmptyCard";
import { useModalV2 } from "../../_common/components/ModalV2";
import ConfirmationModalV2 from "../../_common/components/modals/ConfirmationModalV2";
import { addSimpleErrorAlert } from "../../_common/reducers/messageAlerts.actions";
import { HoverColor } from "../../_common/components/IconButton";
import {
  OrgAccessDomainPortfolios,
  useBasicPermissions,
} from "../../_common/permissions";
import "../style/components/WebscanTableCard.scss";

export const useOnRemoveCustomerCloudscan = (vendorId?: number) => {
  const dispatch = useAppDispatch();

  const [removingCustomerCloudscan, setRemovingCustomerCloudscan] =
    useState(false);

  const [openConfirmationModal, confirmationModal] =
    useModalV2(ConfirmationModalV2);

  const onRemoveCustomerCloudscan = useCallback(
    (hostname: string) =>
      openConfirmationModal({
        title: "Remove this domain?",
        description: vendorId ? (
          <p>
            The domain will no longer appear in this vendor&apos;s list or
            contribute to their score.
          </p>
        ) : (
          <p>
            The domain will no longer appear in your list or contribute to your
            score.
          </p>
        ),
        buttonText: "Remove domain",
        dangerousAction: true,
        filledDangerousAction: true,
        buttonAction: () => {
          setRemovingCustomerCloudscan(true);
          dispatch(removeCustomerCloudscan(hostname, vendorId))
            .catch((e) => {
              dispatch(addSimpleErrorAlert(e.message));
            })
            .finally(() => {
              setRemovingCustomerCloudscan(false);
            });
        },
      }),
    [vendorId]
  );

  return [
    removingCustomerCloudscan,
    confirmationModal,
    onRemoveCustomerCloudscan,
  ] as const;
};

interface WebscansTableCardV2Props {
  vendorId?: number;
  isSubsidiary?: boolean;
  isVendor?: boolean;
  primaryHostname?: string;
  filterText: string;
  activeOnly: boolean;
  inactiveOnly: boolean;
  onOpenCloudscanPanel: (scan: string) => void;
  onFilterClear: () => void;
  userHasWriteVendorInfoPerm: boolean;
  userHasWritePermsInDomainPortfolios: number[];
  overMaxDomains: boolean;
}

const WebscansTableCardV2: FC<WebscansTableCardV2Props> = ({
  filterText,
  isSubsidiary,
  isVendor,
  primaryHostname,
  vendorId,
  activeOnly,
  inactiveOnly,
  onOpenCloudscanPanel,
  onFilterClear,
  userHasWriteVendorInfoPerm,
  userHasWritePermsInDomainPortfolios,
  overMaxDomains,
}) => {
  const dispatch = useAppDispatch();
  const permissions = useBasicPermissions();
  const showDomainPortfolios =
    !isVendor &&
    !isSubsidiary &&
    !!permissions.orgPermissions[OrgAccessDomainPortfolios];

  const rescanHost = useCallback(
    (hostname: string) => {
      dispatch(scanHostname(hostname, !isSubsidiary && !isVendor, null));
    },
    [dispatch, isVendor, isSubsidiary]
  );

  const filters = useAppSelector((state) =>
    getFiltersFromState(state, vendorId, isSubsidiary)
  );
  const allCloudscans = useAppSelector((state) => state.cyberRisk.webscans);
  const IsMVAAnalyst = !!useManagedOrgID();

  const [
    removingCustomerCloudscan,
    confirmationModal,
    onRemoveCustomerCloudscan,
  ] = useOnRemoveCustomerCloudscan(vendorId);

  const { data: activeDomains, isFetching: activeDomainsLoading } =
    DomainsAPI.useGetDomainsQuery({
      // only send the filter text to the backend if we have > maxDomains
      // otherwise perform the filtering locally
      search_string: filterText.length > 5 && overMaxDomains ? filterText : "",
      vendor_id: vendorId,
      is_customer: !vendorId && !isSubsidiary,
      is_subsidiary: isSubsidiary,
      website_label_ids: filters.websiteLabelIds,
      website_label_ids_match_all: filters.websiteLabelIdsMatchAll,
      website_label_ids_do_not_match: filters.websiteLabelIdsDoNotMatch,
      website_include_unlabeled: filters.websiteIncludeUnlabeled,
      website_portfolio_ids:
        !vendorId && !isSubsidiary ? filters.domainPortfolioIds : undefined,
      risk_ids: filters.riskIds,
      appguard_cloud_providers: filters.cloudConnectionProviderTypes,
      appguard_cloud_connection_uuids: filters.cloudConnectionUUIDs,
      active: true,
    });

  const { data: inactiveDomains, isFetching: inactiveDomainsLoading } =
    DomainsAPI.useGetDomainsQuery({
      // only send the filter text to the backend if we have > maxDomains
      // otherwise perform the filtering locally
      search_string: filterText.length > 5 && overMaxDomains ? filterText : "",
      vendor_id: vendorId,
      is_customer: !vendorId && !isSubsidiary,
      is_subsidiary: isSubsidiary,
      website_label_ids: filters.websiteLabelIds,
      website_label_ids_match_all: filters.websiteLabelIdsMatchAll,
      website_label_ids_do_not_match: filters.websiteLabelIdsDoNotMatch,
      website_include_unlabeled: filters.websiteIncludeUnlabeled,
      website_portfolio_ids:
        !vendorId && !isSubsidiary ? filters.domainPortfolioIds : undefined,
      risk_ids: filters.riskIds,
      appguard_cloud_providers: filters.cloudConnectionProviderTypes,
      appguard_cloud_connection_uuids: filters.cloudConnectionUUIDs,
      active: false,
    });

  const domainsLoading = activeOnly
    ? activeDomainsLoading
    : inactiveOnly
      ? inactiveDomainsLoading
      : activeDomainsLoading || inactiveDomainsLoading;

  const domainsToUse = useMemo(() => {
    if (activeOnly && activeDomains) {
      return activeDomains.domains;
    } else if (inactiveOnly && inactiveDomains) {
      return inactiveDomains.domains;
    } else if (!activeOnly && !inactiveOnly) {
      return [
        ...(activeDomains?.domains ?? []),
        ...(inactiveDomains?.domains ?? []),
      ];
    }

    return [];
  }, [activeOnly, inactiveOnly, activeDomains, inactiveOnly]);

  // if we have less than maxDomains filter our domains by subString locally
  const filteredDomains = useMemo(() => {
    if (!overMaxDomains && filterText != "") {
      const filterLower = filterText.toLocaleLowerCase();
      return domainsToUse.filter((d) =>
        d.hostname.toLocaleLowerCase().includes(filterLower)
      );
    }
    return domainsToUse;
  }, [overMaxDomains, filterText, domainsToUse]);

  const [isInitialSort, setIsInitialSort] = useState(true);

  // apply paging and sorting
  const [
    currentPage,
    sortedBy,
    onSortChange,
    pageNumber,
    totalPages,
    setCurrentPage,
  ] = useSortingWithPagination<
    Domain,
    "identity" | "url" | "score" | "date_scanned"
  >(
    filteredDomains ?? [],
    overMaxDomains ? "identity" : "url",
    SortDirection.ASC,
    {
      identity: { orderFuncs: [() => 1] },
      url: {
        orderFuncs: [
          (d) =>
            // if this is the "initial sort", ie the page is just loaded and no sort has been explicitly selected
            // we want to start with the primary domain at the top of the list
            isInitialSort
              ? d.hostname == primaryHostname
                ? ""
                : d.hostname
              : d.hostname,
        ],
      },
      score: { orderFuncs: [(d) => d.score, (d) => d.hostname] },
      date_scanned: { orderFuncs: [(d) => d.scanned_at, (d) => d.hostname] },
    },
    100
  );

  // negate sorting if we are over max domains
  useEffect(() => {
    if (overMaxDomains) {
      onSortChange("identity", SortDirection.ASC);
    }
  }, [overMaxDomains, onSortChange]);

  const [selectedDomains, setSelectedDomains] = useState<number[]>([]);

  const visibleSelectedScans = useMemo(
    () =>
      intersectionWith(
        currentPage,
        selectedDomains,
        (scan, id) => scan.hostnameID == id
      ),
    [currentPage, selectedDomains]
  );

  const onSelectClick = useCallback(
    (hostnameID: number) =>
      setSelectedDomains((prev) =>
        produce(prev, (draft) => {
          const idx = draft.indexOf(hostnameID);
          if (idx > -1) {
            draft.splice(idx, 1);
          } else {
            draft.push(hostnameID);
          }
        })
      ),
    []
  );

  const onSelectToggle = useCallback(() => {
    if (visibleSelectedScans.length >= (currentPage.length ?? 0)) {
      onSelectNone();
    } else {
      onSelectAll();
    }
  }, [currentPage, selectedDomains]);

  const onSelectAll = useCallback(
    () =>
      setSelectedDomains((prev) =>
        produce(prev, (draft) => {
          return union(draft, currentPage.map((d) => d.hostnameID) ?? []);
        })
      ),
    [currentPage, visibleSelectedScans]
  );

  const onSelectNone = useCallback(
    () =>
      setSelectedDomains(
        difference(
          visibleSelectedScans.map((d) => d.hostnameID),
          currentPage.map((d) => d.hostnameID) ?? []
        )
      ),
    [currentPage, visibleSelectedScans]
  );

  type headerType = "url" | "score" | "date_scanned" | "portfolios" | "labels";
  const columnHeaders: IXTableColumnHeader<headerType>[] = [
    { id: "url", text: "Domain", sortable: !overMaxDomains },
    { id: "score", text: "Score", sortable: !overMaxDomains },
    {
      id: "date_scanned",
      text: true ? "Scanned on" : "Most recent scan",
      sortable: !overMaxDomains,
    },
    {
      id: "portfolios" as const,
      text: "Portfolio",
      sortable: false,
      hide: !showDomainPortfolios,
    },
    { id: "labels", text: "Labels", sortable: false },
  ];

  const updateDomainLabels = useCallback(
    (items: Domain[], addedLabelIds: number[], removedLabelIds: number[]) => {
      return dispatch(
        setLabelsForWebsites(
          items.map((i) => i.hostname),
          addedLabelIds,
          removedLabelIds,
          vendorId,
          isSubsidiary
        )
      );
    },
    []
  );

  const getUpdateLabelsModalHeader = (items: Domain[]) => {
    if (!items || items.length === 0) {
      return undefined;
    } else if (items.length === 1) {
      return `Update labels for ${items[0].hostname}`;
    } else {
      return `Update labels for ${items.length} domains`;
    }
  };

  const rows: IXTableRow<number>[] = useMemo(() => {
    return currentPage.map((scan, idx) => {
      const unscanned = !scan.enabled || (!scan.score && !scan.scanned_at);
      const isRescanning = !!allCloudscans[scan.hostname]?.rescanning;

      const labels = (scan.labels || []).map((label) => ({
        id: label.id,
        name: label.name,
        color: label.colour,
        bordered: true,
        removeable: false,
      }));

      const cells = [
        <XTableCell key="url" className="url-cell">
          <TourHighlightFromPLGContent
            onboardingTaskId={"Checklist_BreachSight_Rescan"}
            highlightIndex={0}
            position={"left"}
            visible={idx === 0}
          >
            <div className="url-cell-wrapper">
              <div className="url">
                <EllipsizedText
                  text={scan.hostname}
                  popupClassName="ellipsized-table-url"
                >
                  <div>{scan.hostname}</div>
                </EllipsizedText>
              </div>
              {scan.hostname == primaryHostname && (
                <PillLabel color={LabelColor.Blue} bordered>
                  Primary domain
                </PillLabel>
              )}
              {unscanned && (
                <PillLabel color={LabelColor.Grey} bordered>
                  Inactive
                </PillLabel>
              )}
              <DomainSourceLabelList sources={scan.sources} />
            </div>
          </TourHighlightFromPLGContent>
        </XTableCell>,
        <XTableCell key="score" className="cstar-score-cell">
          {!unscanned && (
            <div className="grade-with-score">
              {(scan?.score ?? 0) >= 0 && (
                <>
                  <ColorGrade size={ColorGradeSize.Small} score={scan.score} />
                  <Score score={scan.score} small colored />
                </>
              )}
            </div>
          )}
        </XTableCell>,
        <XTableCell key="date_scanned" className="date-scanned-cell">
          {!unscanned && (
            <span className="caps-time">
              <DateTimeFormat dateTime={scan.scanned_at} dateOnly />
            </span>
          )}
        </XTableCell>,
        <XTableCell
          key="portfolios"
          className="portfolio-cell"
          hide={!showDomainPortfolios}
        >
          {scan.portfolios?.map((p) => p.name).join(", ")}
        </XTableCell>,
        <XTableCell key="labels" className="labels-cell">
          <LabelList
            labels={labels}
            maxWidth={300}
            onLabelsUpdated={
              userHasWriteVendorInfoPerm && !IsMVAAnalyst
                ? (_availableLabels, addedLabelIds, removedLabelIds) =>
                    updateDomainLabels([scan], addedLabelIds, removedLabelIds)
                : undefined
            }
            updateLabelsHeader={getUpdateLabelsModalHeader([scan])}
            classification={LabelClassification.WebsiteLabel}
          />
        </XTableCell>,
      ];

      const iconOptions: IIconOption[] = unscanned
        ? [
            {
              id: "rescan",
              hoverText: "Rescan",
              icon: <i className="cr-icon-redo" />,
              disabled: isRescanning,
              onClick: () => rescanHost(scan.hostname),
            },
          ]
        : [];

      if (scan.removable) {
        iconOptions.push({
          id: "remove",
          hoverText: "Remove",
          hoverColor: HoverColor.Red,
          icon: <i className="cr-icon-trash" />,
          disabled: isRescanning,
          onClick: () => onRemoveCustomerCloudscan(scan.hostname),
        });
      }

      return {
        id: scan.hostnameID,
        cells,
        iconOptions,
        selected: selectedDomains.includes(scan.hostnameID),
        onClick: unscanned
          ? undefined
          : () => onOpenCloudscanPanel(scan.hostname),
        selectionDisable:
          userHasWritePermsInDomainPortfolios &&
          userHasWritePermsInDomainPortfolios.length > 0 &&
          !scan.portfolios?.find(
            (p) => userHasWritePermsInDomainPortfolios?.includes(p.id)
          ),
        selectionDisabledHelpText: `You have read-only access to this domain's ${
          scan.portfolios?.length === 1 ? "portfolio" : "portfolios"
        }.`,
      };
    });
  }, [
    currentPage,
    primaryHostname,
    selectedDomains,
    onOpenCloudscanPanel,
    allCloudscans,
    IsMVAAnalyst,
    userHasWriteVendorInfoPerm,
    onRemoveCustomerCloudscan,
    showDomainPortfolios,
  ]);

  return (
    <ReportCard
      newStyles
      id={"cyber_risk_websites"}
      className={"webscan-table-card"}
    >
      <XTable<number, headerType>
        selectable={
          !IsMVAAnalyst &&
          (userHasWriteVendorInfoPerm ||
            (userHasWritePermsInDomainPortfolios &&
              userHasWritePermsInDomainPortfolios.length > 0))
        }
        iconOptions
        className="webscan-table"
        loading={domainsLoading}
        sortedBy={sortedBy}
        onSortChange={(columnId, newSortDir) => {
          onSortChange(columnId, newSortDir);
          setIsInitialSort(false);
        }}
        onSelectClick={onSelectClick}
        onSelectToggle={onSelectToggle}
        onSelectAllClick={onSelectAll}
        onSelectNoneClick={onSelectNone}
        stickyColumnHeaders
        columnHeaders={columnHeaders}
        rows={rows}
        pagination={{
          totalPages: totalPages,
          hidePaginationIfSinglePage: true,
          onPageChange: setCurrentPage,
          currentPage: pageNumber,
        }}
      />

      {(filteredDomains.length ?? 0) > 0 &&
        !domainsLoading &&
        (filteredDomains.length ?? 0) == 0 && (
          <SearchEmptyCard searchItemText="domains" onClear={onFilterClear} />
        )}
      {!((filteredDomains.length ?? 0) > 0) &&
        !(domainsLoading || removingCustomerCloudscan) && (
          <SearchEmptyCard searchItemText="domains" />
        )}
      <DomainIPActionBar
        vendorId={vendorId}
        isSubsidiary={isSubsidiary}
        selectedDomains={visibleSelectedScans}
        onCancel={onSelectNone}
        onDeselectAll={() => setSelectedDomains([])}
      />
      {confirmationModal}
    </ReportCard>
  );
};

export default WebscansTableCardV2;
