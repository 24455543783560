import { Component } from "react";
import { sortBy as _sortBy } from "lodash";
import {
  IPAddressDetails,
  IPSource,
  ipToInt,
} from "../../_common/types/ipAddresses";
import { DefaultThunkDispatchProp } from "../../_common/types/redux";

import LoadingBanner from "../../_common/components/core/LoadingBanner";
import { IPAddressInfoTable } from "./IPInfoTable";
import ColorGrade, { ColorGradeSize } from "./executive_summary/ColorGrade";
import Score from "./Score";
import Pagination from "../../_common/components/core/Pagination";
import {
  fetchCustomerIPAddressDetails,
  fetchVendorIPAddressDetails,
} from "../reducers/ipAddresses.actions";
import "../style/components/IPAddressPanel.scss";
import InfoBanner, { BannerType } from "./InfoBanner";
import ScrollableDiv from "./ScrollableDiv";
import classnames from "classnames";
import { NumberWithCommas } from "../../_common/helpers";
import {
  getUserPermissionsForVendorFromState,
  UserBreachsightWrite,
  UserVendorRiskWrite,
} from "../../_common/permissions";
import { appConnect } from "../../_common/types/reduxHooks";
import IpSourceLabelList from "./ip_addresses/IpSourceLabelList";
import InfoTable, {
  InfoTableRow,
  InfoTableStyling,
} from "../../_common/components/InfoTable";
import CloudProviderLogo from "../../appguard/components/CloudProviderLogo";

interface IIPAddressPanelOwnProps {
  ip: string;
  vendorId?: number;
  isSubsidiary: boolean;
  onClickDomain: (domain: string) => void;
  onClickIPRange: (ipRangeID: number, customRangeID?: number) => void;
}

interface IIPAddressPanelConnectedProps {
  ipDetails?: IPAddressDetails;
  userHasWriteVendorInfoPermission: boolean;
}

type IIPAddressPanelProps = IIPAddressPanelConnectedProps &
  IIPAddressPanelOwnProps &
  DefaultThunkDispatchProp;

class IPAddressPanel extends Component<IIPAddressPanelProps> {
  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps: Readonly<IIPAddressPanelProps>) {
    const { ip, vendorId, isSubsidiary } = this.props;
    if (
      prevProps.ip !== ip ||
      prevProps.vendorId !== vendorId ||
      prevProps.isSubsidiary !== isSubsidiary
    ) {
      this.fetchData();
    }
  }

  fetchData = () => {
    if (this.props.vendorId) {
      this.props.dispatch(
        fetchVendorIPAddressDetails(
          this.props.ip,
          this.props.vendorId,
          this.props.isSubsidiary
        )
      );
    } else {
      this.props.dispatch(fetchCustomerIPAddressDetails(this.props.ip));
    }
  };

  render() {
    const {
      dispatch,
      ipDetails,
      onClickDomain,
      onClickIPRange,
      vendorId,
      isSubsidiary,
      userHasWriteVendorInfoPermission,
    } = this.props;

    if (!ipDetails) {
      return <LoadingBanner />;
    }

    return (
      <ScrollableDiv newStyles>
        <div className="ip-panel-content">
          <div className="ip-address">{ipDetails.ip}</div>
          <div className="section-title">IP address details</div>
          <IPAddressInfoTable
            dispatch={dispatch}
            vendorId={vendorId}
            isSubsidiary={isSubsidiary}
            ip={ipDetails}
            onClickIPRange={onClickIPRange}
            showUpdateLabel={userHasWriteVendorInfoPermission}
          />
          <div className="section-title">IP address scorecard</div>
          <InfoBanner
            type={BannerType.WARNING}
            message={
              <>
                This IP is sourced from DNS records, so it does not receive its
                own score. To view scoring and risks relating to this IP, click
                on a domain below.
              </>
            }
          />
          <div className="section-title">
            Domains ({NumberWithCommas(ipDetails.domains?.length || 0)})
          </div>
          {!!ipDetails.domains && (
            <HostScoreList
              hosts={ipDetails.domains.map((d) => ({
                host: d.domain,
                score: d.score,
                onClick: () => onClickDomain(d.domain),
              }))}
              isCustomer={!vendorId}
              hostsAreIPs={false}
            />
          )}
          {!!ipDetails.cloudConnections && (
            <>
              <div className="section-title">
                Cloud Connections ({ipDetails.cloudConnections.length})
              </div>
              <InfoTable
                styling={InfoTableStyling.New}
                bordered
                borderedFirstRow
              >
                {ipDetails.cloudConnections.map((d) => (
                  <InfoTableRow
                    key={d.connectionName}
                    rowClass={"cloud-connection-row"}
                    value={
                      <>
                        <CloudProviderLogo
                          provider={d.connectionProviderType}
                        />
                        <div>{d.connectionName}</div>
                      </>
                    }
                  />
                ))}
              </InfoTable>
            </>
          )}
        </div>
      </ScrollableDiv>
    );
  }
}

export default appConnect<
  IIPAddressPanelConnectedProps,
  never,
  IIPAddressPanelOwnProps
>((state: any, props) => {
  let ipDetails: IPAddressDetails;
  if (props.isSubsidiary && props.vendorId) {
    ipDetails =
      state.cyberRisk.subsidiaries[props.vendorId]?.ipAddressDetails?.[
        props.ip
      ];
  } else if (props.vendorId) {
    ipDetails =
      state.cyberRisk.vendors[props.vendorId]?.ipAddressDetails?.[props.ip];
  } else {
    ipDetails = state.cyberRisk.customerData.ipAddressDetails?.[props.ip];
  }

  const isBreachSight = !props.vendorId || props.isSubsidiary;
  const isVendor = !!props.vendorId && !props.isSubsidiary;
  const userPerms = getUserPermissionsForVendorFromState(
    state,
    isVendor ? props.vendorId || 0 : 0
  );
  const userHasWriteVendorInfoPermission =
    (isBreachSight && !!userPerms[UserBreachsightWrite]) ||
    (isVendor && !!userPerms[UserVendorRiskWrite]);

  return {
    ipDetails,
    userHasWriteVendorInfoPermission,
  };
})(IPAddressPanel);

export interface HostScore {
  host: string;
  sources?: IPSource[];
  score: number | null;
  onClick: () => void;
}

interface IHostScoreListProps {
  hosts: HostScore[];
  pageSize: number;
  isCustomer: boolean;
  hostsAreIPs?: boolean;
}

interface IHostScoreListState {
  currentPage: number;
}

export class HostScoreList extends Component<
  IHostScoreListProps,
  IHostScoreListState
> {
  static defaultProps = {
    pageSize: 10,
  };
  constructor(props: IHostScoreListProps) {
    super(props);
    this.state = { currentPage: 1 };
  }

  render() {
    const { hosts, pageSize, isCustomer } = this.props;
    const { currentPage } = this.state;
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    const totalPages = Math.ceil(hosts.length / pageSize);
    let sortedHosts: HostScore[];

    // If hosts are IPs, convert them to an int when sorting
    if (this.props.hostsAreIPs === true) {
      sortedHosts = _sortBy(hosts.slice(startIndex, endIndex), (host) =>
        ipToInt(host.host)
      );
    } else {
      sortedHosts = _sortBy(
        hosts.slice(startIndex, endIndex),
        (host) => host.host
      );
    }

    return (
      <div className="host-score-list">
        <div className={classnames({ "min-height-wrapper": totalPages > 1 })}>
          {sortedHosts.map((h) => (
            <div
              key={h.host}
              className="host-score"
              onClick={() => h.onClick()}
            >
              {!h.sources ? (
                <div className="host host-only">{h.host}</div>
              ) : (
                <div className="host-source">
                  <div className="host">{h.host}</div>
                  <div className="source">
                    <IpSourceLabelList
                      sources={h.sources}
                      isCustomer={isCustomer}
                    />
                  </div>
                </div>
              )}
              {h.score !== null && (
                <div className="grade-score">
                  <ColorGrade score={h.score} size={ColorGradeSize.Small} />{" "}
                  <Score score={h.score} colored />
                </div>
              )}
              <i className="cr-icon-chevron" />
            </div>
          ))}
        </div>
        {totalPages > 1 && (
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={(p: number) => this.setState({ currentPage: p })}
          />
        )}
      </div>
    );
  }
}
