import SlidePanelSection from "../../../vendorrisk/components/filter/SlidePanelSection";
import {
  OrgAccessUserBase,
  useHasOrgEntitlement,
} from "../../../_common/permissions";
import {
  OptionType,
  SelectV2Multi,
} from "../../../_common/components/SelectV2";
import ColorCheckbox from "../../../vendorrisk/components/ColorCheckbox";
import { OptionsType } from "react-select";
import LabelList from "../../../vendorrisk/components/LabelList";
import { getColorForKey } from "../../../vendorrisk/components/PillLabel";
import { LabelColor } from "../../../_common/types/label";

interface ListFilterProps<T extends string = string> {
  title: string;
  options: OptionsType<OptionType<T>>;
  selectedValues: T[];
  excluded: boolean;
  onChange: (selectedValues: T[], exclude: boolean) => void;
  isLoading: boolean;
  useColoredPills?: boolean;
}

const ListFilter = <T extends string = string>({
  title,
  options,
  selectedValues,
  excluded,
  onChange,
  isLoading,
  useColoredPills = false,
}: ListFilterProps<T>) => {
  const hasUserRisk = useHasOrgEntitlement(OrgAccessUserBase);

  if (!hasUserRisk) {
    return <></>;
  }

  const selectedOptions = selectedValues
    .map((value) =>
      options.find(
        (opt) => opt.value.toLocaleLowerCase() === value.toLocaleLowerCase()
      )
    )
    .filter(Boolean) as OptionsType<OptionType<T>>;
  const displayLabels = selectedOptions.map(({ label, value }) => ({
    id: value,
    name: label,
    color: useColoredPills ? getColorForKey(label) : LabelColor.Blue,
    removeable: true,
    large: true,
    constrained: true,
    onRemoveClick: () =>
      onChange(
        selectedValues.filter(
          (selectedValue) =>
            selectedValue.toLocaleLowerCase() !== value.toLocaleLowerCase()
        ),
        excluded
      ),
  }));

  return (
    <SlidePanelSection title={title}>
      <div className="checkbox-group">
        <ColorCheckbox
          radio
          label="Match any"
          onClick={() => onChange(selectedValues, false)}
          checked={!excluded}
        />
        <ColorCheckbox
          radio
          label="Do not include"
          onClick={() => onChange(selectedValues, true)}
          checked={excluded}
        />
      </div>
      <SelectV2Multi
        isLoading={isLoading}
        isMulti
        options={options}
        menuPlacement={"bottom"}
        controlShouldRenderValue={false}
        value={selectedOptions}
        onChange={(values) => {
          onChange(values ? values.map((v) => v.value) : [], excluded);
        }}
      />
      <LabelList labels={displayLabels} />
    </SlidePanelSection>
  );
};

export default ListFilter;
