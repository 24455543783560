import { FC } from "react";
import { getColorForKey } from "../../vendorrisk/components/PillLabel";
import LabelList from "../../vendorrisk/components/LabelList";

interface TeamsPillsProps {
  teams: string[];
}

const TeamsPills: FC<TeamsPillsProps> = ({ teams }) => {
  const teamLabels = teams.map((team) => ({
    id: team,
    color: getColorForKey(team),
    name: team,
    capitalized: true,
  }));

  // sort team labels by name
  teamLabels.sort((a, b) =>
    a.name.toLocaleLowerCase().localeCompare(b.name.toLocaleLowerCase())
  );

  return (
    <LabelList labels={teamLabels} maxNumber={3} popupPosition={"bottom"} />
  );
};

export default TeamsPills;
