import {
  get as _get,
  find as _find,
  debounce as _debounce,
  isEqual as _isEqual,
} from "lodash";
import { FetchCyberRiskUrl } from "../../_common/api";
import { LogError } from "../../_common/helpers";
import { fetchBreachSightCounts } from "../../vendorrisk/reducers/cyberRiskActions";
import {
  addDefaultSuccessAlert,
  addDefaultUnknownErrorAlert,
} from "../../_common/reducers/messageAlerts.actions";
import { shouldBypassSearch } from "../../vendorrisk/helpers/util";

export const CLEARALLDATA = "ANALYSTAPP_CLEARALLDATA";
export const SET_CUSTOMERS_LOADING = "ANALYSTAPP_SET_CUSTOMERS_LOADING";
export const SET_CUSTOMERS = "ANALYSTAPP_SET_CUSTOMERS";
export const SET_SEARCH_RESULTS = "ANALYSTAPP_SET_SEARCH_RESULTS";
export const SET_SEARCH_RESULT_COUNTS = "ANALYSTAPP_SET_SEARCH_RESULT_COUNTS";
export const SET_HIGHLIGHTED_SEARCH_RESULTS =
  "ANALYSTAPP_SET_HIGHLIGHTED_SEARCH_RESULTS";
export const REMOVE_SEARCH_RESULT_BY_ID =
  "ANALYSTAPP_REMOVE_SEARCH_RESULT_BY_ID";
export const SET_PUBLISHED_FINDINGS = "ANALYSTAPP_SET_PUBLISHED_FINDINGS";
export const SET_UNPUBLISHED_FINDINGS = "ANALYSTAPP_SET_UNPUBLISHED_FINDINGS";
export const SET_SCRATCH_REQUESTS = "ANALYSTAPP_SET_SCRATCH_REQUESTS";
export const SET_SCRATCH_REQUESTS_LOADING =
  "ANALYSTAPP_SET_SCRATCH_REQUESTS_LOADING";
export const SET_SEARCH_REQUEST_RESULT_COUNTS =
  "ANALYSTAPP_SET_SEARCH_REQUEST_RESULT_COUNTS";
export const SET_SCRATCH_SEARCH_RESULTS =
  "ANALYSTAPP_SET_SCRATCH_SEARCH_RESULTS";
export const SET_SCRATCH_SEARCH_RESULT_COUNTS =
  "ANALYSTAPP_SET_SCRATCH_SEARCH_RESULT_COUNTS";
export const SET_SCRATCH_HIGHLIGHTED_SEARCH_RESULTS =
  "ANALYSTAPP_SET_SCRATCH_HIGHLIGHTED_SEARCH_RESULTS";
export const REMOVE_SCRATCH_SEARCH_RESULT_BY_ID =
  "ANALYSTAPP_REMOVE_SCRATCH_SEARCH_RESULT_BY_ID";
export const SET_UNPUBLISHED_FINDINGS_FOR_GROUP =
  "ANALYSTAPP_SET_UNPUBLISHED_FINDINGS_FOR_GROUP";
export const SET_DOMAINS_WITH_FINDINGS = "ANALYSTAPP_SET_DOMAINS_WITH_FINDINGS";
export const SET_DOMAIN_UNPUBLISHED_FINDINGS =
  "ANALYSTAPP_SET_DOMAIN_UNPUBLISHED_FINDINGS";
export const CLEAR_DOMAIN_UNPUBLISHED_FINDINGS =
  "ANALYSTAPP_CLEAR_DOMAIN_UNPUBLISHED_FINDINGS";
export const SET_VENDOR_SEARCH = "ANALYSTAPP_SET_VENDOR_SEARCH";

export const setCustomersLoading = (loading) => {
  return {
    type: SET_CUSTOMERS_LOADING,
    loading,
  };
};

export const setCustomers = (results) => {
  return {
    type: SET_CUSTOMERS,
    results,
  };
};

export const setScratchRequests = (results) => {
  return {
    type: SET_SCRATCH_REQUESTS,
    results,
  };
};

export const setScratchRequestsLoading = (loading) => {
  return {
    type: SET_SCRATCH_REQUESTS_LOADING,
    loading,
  };
};

export const clearAnalystAppState = () => {
  return { type: CLEARALLDATA };
};

export const setSearchResults = (orgId, searchResults) => {
  return {
    type: SET_SEARCH_RESULTS,
    orgId,
    searchResults,
  };
};

export const setScratchSearchResults = (groupId, searchResults) => {
  return {
    type: SET_SCRATCH_SEARCH_RESULTS,
    groupId,
    searchResults,
  };
};

export const setSearchResultCounts = (orgId, searchResultCounts) => {
  return {
    type: SET_SEARCH_RESULT_COUNTS,
    orgId,
    searchResultCounts,
  };
};

export const setScratchSearchResultCounts = (groupId, searchResultCounts) => {
  return {
    type: SET_SCRATCH_SEARCH_RESULT_COUNTS,
    groupId,
    searchResultCounts,
  };
};

export const setRequestSearchResultCounts = (requestID, results) => {
  return {
    type: SET_SEARCH_REQUEST_RESULT_COUNTS,
    requestID,
    results,
  };
};

export const setHighlightedSearchResults = (orgId, searchResults) => {
  return {
    type: SET_HIGHLIGHTED_SEARCH_RESULTS,
    orgId,
    searchResults,
  };
};

export const setScratchHighlightedSearchResults = (groupId, searchResults) => {
  return {
    type: SET_SCRATCH_HIGHLIGHTED_SEARCH_RESULTS,
    groupId,
    searchResults,
  };
};

export const removeSearchResultById = (orgId, searchResultId) => {
  return {
    type: REMOVE_SEARCH_RESULT_BY_ID,
    orgId,
    searchResultId,
  };
};

export const removeScratchSearchResultById = (groupId, searchResultId) => {
  return {
    type: REMOVE_SCRATCH_SEARCH_RESULT_BY_ID,
    groupId,
    searchResultId,
  };
};

export const setPublishedFindings = (orgId, findings) => {
  return {
    type: SET_PUBLISHED_FINDINGS,
    orgId,
    findings,
  };
};

export const setUnpublishedFindings = (orgId, findings) => {
  return {
    type: SET_UNPUBLISHED_FINDINGS,
    orgId,
    findings,
  };
};

export const setUnpublishedFindingsForSearchGroup = (groupId, findings) => {
  return {
    type: SET_UNPUBLISHED_FINDINGS_FOR_GROUP,
    groupId,
    findings,
  };
};

export const setUnpublishedFindingsForDomain = (domainName, findings) => {
  return {
    type: SET_DOMAIN_UNPUBLISHED_FINDINGS,
    domainName,
    findings,
  };
};

export const setDomainsWithFindings = (data) => {
  return {
    type: SET_DOMAINS_WITH_FINDINGS,
    data,
  };
};

export const clearAllDomainFindings = () => {
  return { type: CLEAR_DOMAIN_UNPUBLISHED_FINDINGS };
};

export const setVendorSearch = (data) => {
  return {
    type: SET_VENDOR_SEARCH,
    data,
  };
};

export const fetchCustomerList = (forceRefresh = false, background = false) => {
  return async (dispatch, getState) => {
    const { customers } = getState().analystPortal;
    let json;

    if (!forceRefresh && customers && !customers.loading && customers.result) {
      return customers.result;
    }

    if (background === false) {
      dispatch(setCustomersLoading(true));
    }

    try {
      json = await FetchCyberRiskUrl(
        "breachsight/analyst/customerlist/v1/",
        null,
        null,
        dispatch,
        getState
      );
      // date format ISO8601: YYYY-MM-DD[THH:mm:ssZ]
    } catch (e) {
      dispatch(setCustomersLoading(false));
      LogError("error retrieving analyst customer list", e);
      json = null;
    }

    if (!json || json.status !== "OK") {
      dispatch(
        setCustomers({
          loading: false,
          error: {
            errorText: "Error fetching BreachSight customer list",
            actionText: "Retry",
            actionOnClick: () => dispatch(fetchCustomerList(forceRefresh)),
          },
          result: null,
          searchEnabled: false,
        })
      );
      return null;
    }

    dispatch(
      setCustomers({
        loading: false,
        error: null,
        result: json.customers,
        searchEnabled: json.searchEnabled,
      })
    );

    return json.customers;
  };
};

export const setCustomerArchived = (orgID, archived) => {
  return async (dispatch, getState) => {
    try {
      await FetchCyberRiskUrl(
        "breachsight/analyst/customer/archive/v1/",
        { org_id: orgID, archived },
        { method: "POST" },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("error setting breachsight customer archived", e);

      throw e;
    }

    // Update this customer in state
    const customers = [...getState().analystPortal.customers.result];
    let updated = false;
    for (let i = 0; i < customers.length; i++) {
      if (customers[i].id === orgID) {
        customers[i].archived = archived;
        updated = true;
        break;
      }
    }

    if (updated) {
      dispatch(setCustomers({ result: customers }));
    }
  };
};

export const setCustomerReportDueDate = (orgID, dueDate) => {
  return async (dispatch, getState) => {
    try {
      await FetchCyberRiskUrl(
        "breachsight/analyst/customer/duedate/v1/",
        { org_id: orgID, due_date: dueDate },
        { method: "POST" },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("error setting breachsight customer report dude date", e);

      throw e;
    }

    // Update this customer in state
    const customers = [...getState().analystPortal.customers.result];
    let updated = false;
    for (let i = 0; i < customers.length; i++) {
      if (customers[i].id === orgID) {
        customers[i].scanDue = dueDate;
        updated = true;
        break;
      }
    }

    if (updated) {
      dispatch(setCustomers({ result: customers }));
    }
  };
};

export const createNewRequest = (orgId, groupId, searchTypesAndKeywords) => {
  return async (dispatch, getState) => {
    try {
      await FetchCyberRiskUrl(
        "breachsight/analyst/request",
        { org_id: orgId, group_id: groupId },
        {
          method: "POST",
          body: JSON.stringify(searchTypesAndKeywords),
        },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("error creating new breachsight request", e);
      console.error(`Error creating new breachsight request: ${e}`);

      throw e;
    }
  };
};

export const createNewRequestGroup = (
  groupName,
  scratchAnalystId,
  searchTypesAndKeywords
) => {
  return async (dispatch, getState) => {
    try {
      await FetchCyberRiskUrl(
        "breachsight/analyst/scratch_group",
        { name: groupName, scratch_analyst_id: scratchAnalystId },
        {
          method: "POST",
          body: JSON.stringify(searchTypesAndKeywords),
        },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("error creating new breachsight request", e);
      console.error(`Error creating new breachsight request: ${e}`);

      throw e;
    }
  };
};

export const deleteSearchRequestGroup = (groupID) => {
  return async (dispatch, getState) => {
    try {
      await FetchCyberRiskUrl(
        "breachsight/analyst/scratch_group",
        { group_id: groupID },
        { method: "DELETE" },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("error deleting request group", e);
      console.error(`Error deleting exploratory search: ${e}`);

      throw e;
    }
  };
};

export const getSearchResults = (orgId, searchType = null, reset = false) => {
  return async (dispatch, getState) => {
    let curResults = getState().analystPortal.searchResults[orgId];

    if (curResults && curResults.loading) {
      // Don't request more yet
      return;
    }

    let setSearchResultsObj = { loading: true, searchType };
    let resetCursor = false;
    if (reset || !curResults || searchType !== curResults.searchType) {
      // Also reset the results if the search type has changed
      setSearchResultsObj.results = [];
      resetCursor = true;
    }
    dispatch(setSearchResults(orgId, setSearchResultsObj));

    const opts = { org_id: orgId };
    if (searchType) {
      opts.search_type = searchType;
    }
    if (!resetCursor && curResults && curResults.nextPageCursor) {
      opts.cursor = curResults.nextPageCursor;
    }

    let json;
    try {
      json = await FetchCyberRiskUrl(
        "breachsight/analyst/searchresult/list",
        opts,
        {},
        dispatch,
        getState
      );
    } catch (e) {
      LogError("error fetching analyst search results", e);
      dispatch(addDefaultUnknownErrorAlert("Error fetching search results"));
      dispatch(setSearchResults(orgId, { loading: false }));

      return;
    }

    setSearchResultsObj = { loading: false };

    if (json) {
      // Get the current state again
      curResults = getState().analystPortal.searchResults[orgId] || {
        results: [],
      };

      if (json.results && json.results.length > 0) {
        setSearchResultsObj.results = [...curResults.results, ...json.results];
      }

      setSearchResultsObj.nextPageCursor = json.nextPageCursor;
    }

    dispatch(setSearchResults(orgId, setSearchResultsObj));
  };
};

export const getSearchResultsForScratchGroup = (
  groupId,
  searchType = null,
  reset = false
) => {
  return async (dispatch, getState) => {
    let curResults = getState().analystPortal.scratchSearchResults[groupId];

    if (curResults && curResults.loading) {
      // Don't request more yet
      return;
    }

    let setSearchResultsObj = { loading: true, searchType };
    let resetCursor = false;
    if (reset || !curResults || searchType !== curResults.searchType) {
      // Also reset the results if the search type has changed
      setSearchResultsObj.results = [];
      resetCursor = true;
    }
    dispatch(setScratchSearchResults(groupId, setSearchResultsObj));

    const opts = { group_id: groupId };
    if (searchType) {
      opts.search_type = searchType;
    }
    if (!resetCursor && curResults && curResults.nextPageCursor) {
      opts.cursor = curResults.nextPageCursor;
    }

    let json;
    try {
      json = await FetchCyberRiskUrl(
        "breachsight/analyst/searchresult/scratch_list",
        opts,
        {},
        dispatch,
        getState
      );
    } catch (e) {
      LogError("error fetching analyst search results", e);
      dispatch(addDefaultUnknownErrorAlert("Error fetching search results"));
      dispatch(setScratchSearchResults(groupId, { loading: false }));

      return;
    }

    setSearchResultsObj = { loading: false };

    if (json) {
      // Get the current state again
      curResults = getState().analystPortal.scratchSearchResults[groupId] || {
        results: [],
      };

      if (json.results && json.results.length > 0) {
        setSearchResultsObj.results = [...curResults.results, ...json.results];
      }

      setSearchResultsObj.nextPageCursor = json.nextPageCursor;
    }

    dispatch(setScratchSearchResults(groupId, setSearchResultsObj));
  };
};

export const getHighlightedSearchResults = (orgId, searchType = null) => {
  return async (dispatch, getState) => {
    const opts = {
      org_id: orgId,
      highlighted_only: true,
    };

    if (searchType) {
      opts.search_type = searchType;
    }

    let json;
    try {
      json = await FetchCyberRiskUrl(
        "breachsight/analyst/searchresult/list",
        opts,
        {},
        dispatch,
        getState
      );
    } catch (e) {
      LogError("error getting highlighted search results", e);
      dispatch(addDefaultUnknownErrorAlert("Error fetching search results"));

      return;
    }

    dispatch(
      setHighlightedSearchResults(orgId, { results: json.results || [] })
    );
  };
};

export const getHighlightedSearchResultsForScratchGroup = (
  groupId,
  searchType = null
) => {
  return async (dispatch, getState) => {
    const opts = {
      group_id: groupId,
      highlighted_only: true,
    };

    if (searchType) {
      opts.search_type = searchType;
    }

    let json;
    try {
      json = await FetchCyberRiskUrl(
        "breachsight/analyst/searchresult/scratch_list",
        opts,
        {},
        dispatch,
        getState
      );
    } catch (e) {
      LogError("error getting highlighted search results", e);
      dispatch(addDefaultUnknownErrorAlert("Error fetching search results"));

      return;
    }

    dispatch(
      setScratchHighlightedSearchResults(groupId, {
        results: json.results || [],
      })
    );
  };
};

export const getSearchResultCounts = (orgId) => {
  return async (dispatch, getState) => {
    let json;
    try {
      json = await FetchCyberRiskUrl(
        "breachsight/analyst/searchresult/counts",
        { org_id: orgId },
        {},
        dispatch,
        getState
      );
    } catch (e) {
      LogError("error fetching analyst search result counts", e);
      dispatch(
        addDefaultUnknownErrorAlert("Error fetching total search result counts")
      );
      return;
    }

    dispatch(
      setSearchResultCounts(orgId, {
        loading: false,
        error: false,
        data: {
          totalCount: Object.values(json.searchTypeCounts).reduce(
            (acc, searchTypeCount) => acc + searchTypeCount,
            0
          ),
          totalCounts: json.searchTypeCounts,
          totalIgnoredCount: json.ignoredCount,
        },
      })
    );
  };
};

export const getSearchResultCountsForScratchGroup = (groupId) => {
  return async (dispatch, getState) => {
    let json;
    try {
      json = await FetchCyberRiskUrl(
        "breachsight/analyst/searchresult/scratch_counts",
        { group_id: groupId },
        {},
        dispatch,
        getState
      );
    } catch (e) {
      LogError("error fetching analyst search result counts", e);
      dispatch(
        addDefaultUnknownErrorAlert("Error fetching total search result counts")
      );
      dispatch(
        setScratchSearchResultCounts(groupId, {
          loading: false,
          error: true,
          data: {
            totalCount: 0,
            totalCounts: 0,
            totalIgnoredCount: 0,
          },
        })
      );
      return;
    }

    dispatch(
      setScratchSearchResultCounts(groupId, {
        loading: false,
        error: false,
        data: {
          totalCount: Object.values(json.searchTypeCounts).reduce(
            (acc, searchTypeCount) => acc + searchTypeCount,
            0
          ),
          totalCounts: json.searchTypeCounts,
          totalIgnoredCount: json.ignoredCount,
        },
      })
    );
  };
};

export const getSearchResultCountsByGroup = (groupID) => {
  return async (dispatch, getState) => {
    let json;
    try {
      dispatch(setRequestSearchResultCounts(groupID, { loading: true }));
      json = await FetchCyberRiskUrl(
        "breachsight/analyst/searchresult/scratch_group_counts",
        { group_id: groupID },
        {},
        dispatch,
        getState
      );
    } catch (e) {
      LogError("error fetching result counts by search request", e);
      dispatch(
        addDefaultUnknownErrorAlert("Error fetching total search result counts")
      );
      dispatch(
        setRequestSearchResultCounts(groupID, { loading: false, error: true })
      );
      return;
    }

    dispatch(
      setRequestSearchResultCounts(groupID, {
        loading: false,
        error: false,
        data: {
          total: json.totalResults,
          ignored: json.totalIgnoredResults,
          findings: json.totalFindings,
        },
      })
    );
  };
};

export const setSearchResultIgnored = (orgId, groupId, searchResultId) => {
  return async (dispatch, getState) => {
    try {
      await FetchCyberRiskUrl(
        "breachsight/analyst/searchresult/mark_ignored",
        {},
        {
          method: "POST",
          body: {
            org_id: orgId,
            group_id: groupId,
            search_result_ids: [searchResultId],
          },
        },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("error setting search result to ignored", e);
      dispatch(
        addDefaultUnknownErrorAlert(
          "Error marking this search result as not a finding"
        )
      );
      throw e;
    }

    // Success - remove this search result ID from state.
    if (groupId > 0) {
      dispatch(removeScratchSearchResultById(groupId, searchResultId));
      dispatch(getSearchResultCountsForScratchGroup(groupId));
    } else if (orgId > 0) {
      dispatch(removeSearchResultById(orgId, searchResultId));
      dispatch(getSearchResultCounts(orgId));
    }
  };
};

export const setSearchResultHighlighted = (
  orgId,
  searchResultId,
  highlighted
) => {
  return async (dispatch, getState) => {
    try {
      await FetchCyberRiskUrl(
        "breachsight/analyst/searchresult/mark_highlighted",
        {
          org_id: orgId,
          search_result_id: searchResultId,
          highlight: highlighted,
        },
        { method: "POST" },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("error setting search result to demo highlighted", e);
      dispatch(
        addDefaultUnknownErrorAlert(
          "Error marking this search result as highlighted"
        )
      );
      throw e;
    }

    dispatch(
      addDefaultSuccessAlert(
        highlighted
          ? "Successfully highlighted search result."
          : "Succcessfully unhighlighted search result."
      )
    );

    const searchResults = [
      ..._get(getState().analystPortal, `searchResults.${orgId}.results`, []),
      ..._get(
        getState().analystPortal,
        `highlightedSearchResults.${orgId}.results`,
        []
      ),
    ];

    const searchResult = _find(
      searchResults,
      (r) => r.searchResultId === searchResultId
    );

    // Success - remove this search result ID from state.
    dispatch(removeSearchResultById(orgId, searchResultId));

    if (searchResult && highlighted) {
      // Add this search result back into the highlighted results
      searchResult.highlighted = true;
      dispatch(
        setHighlightedSearchResults(orgId, {
          results: [
            ..._get(
              getState().analystPortal,
              `highlightedSearchResults.${orgId}.results`,
              []
            ),
            searchResult,
          ],
        })
      );
    } else if (searchResult && !highlighted) {
      // Add this search result back into the unhighlighted results
      searchResult.highlighted = false;
      dispatch(
        setSearchResults(orgId, {
          results: [
            ..._get(
              getState().analystPortal,
              `searchResults.${orgId}.results`,
              []
            ),
            searchResult,
          ],
        })
      );
    }
  };
};

export const listS3BucketObjects = (bucketUrl) => {
  return async (dispatch, getState) => {
    let json;
    try {
      json = await FetchCyberRiskUrl(
        "breachsight/analyst/s3objects",
        { bucket: bucketUrl },
        { method: "GET" },
        dispatch,
        getState
      );
    } catch (e) {
      throw e;
    }

    if (json && json.status === "ERROR") {
      throw new Error(json.error);
    }

    if (!json || json.status !== "OK") {
      throw new Error("error listing s3 bucket objects");
    }

    return json.results;
  };
};

export const listGCSBucketObjects = (bucketName) => {
  return async (dispatch, getState) => {
    let json;
    try {
      json = await FetchCyberRiskUrl(
        "breachsight/analyst/gcsobjects",
        { bucket: bucketName },
        { method: "GET" },
        dispatch,
        getState
      );
    } catch (e) {
      throw e;
    }

    if (json && json.status === "ERROR") {
      throw new Error(json.error);
    }

    if (!json || json.status !== "OK") {
      throw new Error("error listing gcs bucket objects");
    }

    return json.results;
  };
};

export const setAllSearchResultsIgnored = (orgId, groupId) => {
  return async (dispatch, getState) => {
    // Get a list of all the search result IDs we need to mark as ignored
    let searchResultIds = [];
    let highlightedSearchResultIds = [];

    if (groupId > 0) {
      searchResultIds = (
        getState().analystPortal.scratchSearchResults[groupId] || {
          results: [],
        }
      ).results.map((r) => r.searchResultId);
      highlightedSearchResultIds = (
        getState().analystPortal.scratchHighlightedSearchResults[groupId] || {
          results: [],
        }
      ).results.map((r) => r.searchResultId);
    } else if (orgId > 0) {
      searchResultIds = (
        getState().analystPortal.searchResults[orgId] || { results: [] }
      ).results.map((r) => r.searchResultId);
      highlightedSearchResultIds = (
        getState().analystPortal.highlightedSearchResults[orgId] || {
          results: [],
        }
      ).results.map((r) => r.searchResultId);
    }

    try {
      await FetchCyberRiskUrl(
        "breachsight/analyst/searchresult/mark_ignored",
        {},
        {
          method: "POST",
          body: {
            org_id: orgId,
            group_id: groupId,
            search_result_ids: [
              ...searchResultIds,
              ...highlightedSearchResultIds,
            ],
          },
        },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("error setting all search results to ignored", e);
      dispatch(addDefaultUnknownErrorAlert("Error discarding search results"));
      throw e;
    }

    // Success - remove all search results from state
    if (groupId > 0) {
      dispatch(setScratchSearchResults(groupId, { results: [] }));
      dispatch(setScratchHighlightedSearchResults(groupId, { results: [] }));
      dispatch(getSearchResultCountsForScratchGroup(groupId));
    } else if (orgId > 0) {
      dispatch(setSearchResults(orgId, { results: [] }));
      dispatch(setHighlightedSearchResults(orgId, { results: [] }));
      dispatch(getSearchResultCounts(orgId));
    }
  };
};

export const clearAllSearchResults = (orgId) => {
  return async (dispatch, getState) => {
    try {
      await FetchCyberRiskUrl(
        "breachsight/analyst/searchresult/delete_all",
        { org_id: orgId },
        { method: "DELETE" },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("error clearing all breachsight search results", e);
      throw e;
    }
  };
};

export const clearAllResultsForScratchGroup = (groupId) => {
  return async (dispatch, getState) => {
    try {
      await FetchCyberRiskUrl(
        "breachsight/analyst/searchresult/scratch_delete_all",
        { group_id: groupId },
        { method: "DELETE" },
        dispatch,
        getState
      );
    } catch (e) {
      LogError(
        "error clearing all breachsight search results for search group",
        e
      );
      throw e;
    }
  };
};

export const createNewFinding = (orgId, groupId, fields) => {
  return async (dispatch, getState) => {
    let json;

    const opts = {
      org_id: orgId,
      group_id: groupId,
      case_name: fields.caseName,
      type: fields.findingType,
      attribution: fields.attribution,
      significance: fields.significance,
      severity: fields.severity,
      url: fields.url,
      domains: fields.domain, // single company assignment
    };

    if (fields.image && fields.image !== "removed") {
      opts.image = fields.image;
    }

    try {
      json = await FetchCyberRiskUrl(
        "breachsight/analyst/finding",
        {},
        {
          method: "POST",
          body: opts,
        },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("error creating new finding", e);
      dispatch(addDefaultUnknownErrorAlert("Error creating new finding"));
      throw e;
    }

    if (orgId > 0) {
      // Add the returned finding to the org
      const findings = _get(
        getState().analystPortal,
        `findings.${orgId}.unpublished`
      );
      if (findings) {
        findings.unshift(json.finding);
        dispatch(setUnpublishedFindings(orgId, findings));
      }
    }
    if (fields.domain && fields.domain !== "") {
      // Add the returned finding to each of the selected domains
      const domain = fields.domain.trim();
      const findings = getState().analystPortal.domainFindings[domain]
        ? getState().analystPortal.domainFindings[domain].unpublished
        : null;
      if (findings) {
        findings.unshift(json.finding);
        dispatch(setUnpublishedFindings(orgId, findings));
      }
      dispatch(fetchDomainsWithFindings());
    } else if (groupId > 0) {
      // Add the returned finding to the group
      const findings = _get(
        getState().analystPortal,
        `scratchFindings.${groupId}.unpublished`
      );
      if (findings) {
        findings.unshift(json.finding);
        dispatch(setUnpublishedFindingsForSearchGroup(groupId, findings));
      }
    }
  };
};

export const createNewFindingFromSearchResult = (
  orgId,
  groupId,
  searchResultId,
  fields
) => {
  return async (dispatch, getState) => {
    let json;

    const opts = {
      org_id: orgId,
      group_id: groupId,
      search_result_uuid: searchResultId,
      case_name: fields.caseName,
      type: fields.findingType,
      attribution: fields.attribution,
      significance: fields.significance,
      severity: fields.severity,
      url: fields.url,
      domains: fields.domain, // single company assignment
    };

    if (fields.image && fields.image !== "removed") {
      opts.image = fields.image;
    }

    try {
      json = await FetchCyberRiskUrl(
        "breachsight/analyst/finding",
        {},
        {
          method: "POST",
          body: opts,
        },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("error creating new finding from search result", e);
      dispatch(
        addDefaultUnknownErrorAlert(
          "Error creating new finding from search result"
        )
      );
      throw e;
    }

    if (orgId > 0) {
      // Remove this search result from the state and add the new finding to the org
      dispatch(removeSearchResultById(orgId, searchResultId));
      const findings = _get(
        getState().analystPortal,
        `findings.${orgId}.unpublished`
      );
      if (findings) {
        findings.unshift(json.finding);
        dispatch(setUnpublishedFindings(orgId, findings));
      }
      dispatch(getSearchResultCounts(orgId));
    }
    if (groupId > 0) {
      // Remove this search result from the state since it's been dealt with
      dispatch(removeScratchSearchResultById(groupId, searchResultId));
      dispatch(getSearchResultCountsForScratchGroup(groupId));
    }

    if (fields.domain && fields.domain !== "") {
      // Add the returned finding to the assigned domain
      const domain = fields.domain.trim();
      const findings = getState().analystPortal.domainFindings[domain]
        ? getState().analystPortal.domainFindings[domain].unpublished
        : null;
      if (findings) {
        findings.unshift(json.finding);
        dispatch(setUnpublishedFindings(orgId, findings));
      }
      dispatch(fetchDomainsWithFindings());
    } else if (groupId > 0) {
      // Add the returned finding to the group
      const findings = _get(
        getState().analystPortal,
        `scratchFindings.${groupId}.unpublished`
      );
      if (findings) {
        findings.unshift(json.finding);
        dispatch(setUnpublishedFindingsForSearchGroup(groupId, findings));
      }
    }
  };
};

export const editFinding = (
  orgId,
  groupId,
  companyDomain,
  findingId,
  fields
) => {
  return async (dispatch, getState) => {
    let json;

    const opts = {
      finding_id: findingId,
      case_name: fields.caseName,
      type: fields.findingType,
      attribution: fields.attribution,
      significance: fields.significance,
      severity: fields.severity,
      url: fields.url,
    };

    // add the correct ownership information to the request org, group or domain (company)
    if (orgId && orgId > 0) {
      opts["org_id"] = orgId;
    }
    if (groupId && groupId > 0) {
      opts["group_id"] = groupId;
    }
    if (companyDomain && companyDomain !== "") {
      opts["domains"] = companyDomain;
    }

    if (fields.domain) {
      opts["domains"] = fields.domain;
    }

    if (fields.org_id > 0) {
      opts["org_id"] = fields.org_id;
    } else if (fields.org_id === 0) {
      delete opts.org_id;
    }

    if (fields.image) {
      opts.image = fields.image;
    }

    try {
      json = await FetchCyberRiskUrl(
        "breachsight/analyst/finding",
        {},
        {
          method: "PUT",
          body: opts,
        },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("error updating existing finding", e);
      dispatch(addDefaultUnknownErrorAlert("Error updating existing finding"));
      throw e;
    }

    // Replace the existing finding with the new updated finding, and make sure all lists ae updated appropriately
    if (
      orgId &&
      orgId > 0 &&
      fields.org_id &&
      fields.org_id > 0 &&
      orgId === fields.org_id
    ) {
      // case 1. the finding is owned by orgId and will remain there
      const findings = _get(
        getState().analystPortal,
        `findings.${orgId}.unpublished`
      );
      if (findings) {
        dispatch(
          setUnpublishedFindings(
            orgId,
            findings.map((finding) => {
              if (finding.finding_id === findingId) {
                json.finding.date_detected = finding.date_detected;
                return json.finding;
              }
              return finding;
            })
          )
        );
      }
    } else if (
      groupId &&
      groupId > 0 &&
      (!fields.domain || fields.domain === "") &&
      (!fields.org_id || fields.org_id === 0)
    ) {
      // case 2. the finding is owned by a group and has not moved
      const findings = _get(
        getState().analystPortal,
        `scratchFindings.${groupId}.unpublished`
      );
      if (findings) {
        dispatch(
          setUnpublishedFindingsForSearchGroup(
            groupId,
            findings.map((finding) => {
              if (finding.finding_id === findingId) {
                json.finding.date_detected = finding.date_detected;
                return json.finding;
              }
              return finding;
            })
          )
        );
      }
    } else if (
      (!fields.org_id || fields.org_id === 0) &&
      companyDomain &&
      companyDomain !== "" &&
      fields.domain &&
      fields.domain !== "" &&
      companyDomain === fields.domain
    ) {
      // case 3. the finding is owned by a domain and has not moved
      const findings = getState().analystPortal.domainFindings[companyDomain]
        ? getState().analystPortal.domainFindings[companyDomain].unpublished
        : null;
      if (findings) {
        dispatch(
          setUnpublishedFindingsForDomain(
            companyDomain,
            findings.map((finding) => {
              if (finding.finding_id === findingId) {
                json.finding.date_detected = finding.date_detected;
                return json.finding;
              }
              return finding;
            })
          )
        );
      }
    } else if (groupId && groupId > 0 && fields.org_id && fields.org_id > 0) {
      // case 4. the finding has moved from a group to a customer
      const findings = _get(
        getState().analystPortal,
        `scratchFindings.${groupId}.unpublished`
      );
      if (findings) {
        dispatch(
          setUnpublishedFindingsForSearchGroup(
            groupId,
            findings.filter((finding) => {
              if (finding.finding_id === findingId) {
                return false;
              }
              return true;
            })
          )
        );
      }
      dispatch(fetchFindings(false, fields.org_id, 0, null));
    } else if (
      groupId &&
      groupId > 0 &&
      fields.domain &&
      fields.domain !== ""
    ) {
      // case 5. the finding has moved from a group to a company
      const findings = _get(
        getState().analystPortal,
        `scratchFindings.${groupId}.unpublished`
      );
      if (findings) {
        dispatch(
          setUnpublishedFindingsForSearchGroup(
            groupId,
            findings.filter((finding) => {
              if (finding.finding_id === findingId) {
                return false;
              }
              return true;
            })
          )
        );
      }
      dispatch(fetchDomainsWithFindings());
      dispatch(fetchFindings(false, 0, 0, fields.domain));
    } else if (
      companyDomain &&
      companyDomain !== "" &&
      fields.org_id &&
      fields.org_id > 0
    ) {
      // case 6. the finding has moved from a company to a customer
      const findings = getState().analystPortal.domainFindings[companyDomain]
        ? getState().analystPortal.domainFindings[companyDomain].unpublished
        : null;
      if (findings) {
        dispatch(
          setUnpublishedFindingsForDomain(
            companyDomain,
            findings.filter((finding) => {
              if (finding.finding_id === findingId) {
                return false;
              }
              return true;
            })
          )
        );
      }
      dispatch(fetchDomainsWithFindings());
      dispatch(fetchFindings(false, fields.org_id, 0, null));
    } else if (
      companyDomain &&
      companyDomain !== "" &&
      fields.domain &&
      fields.domain !== "" &&
      companyDomain !== fields.domain
    ) {
      // case 7. the finding has moved from a company to another company
      const findings = getState().analystPortal.domainFindings[companyDomain]
        ? getState().analystPortal.domainFindings[companyDomain].unpublished
        : null;
      if (findings) {
        dispatch(
          setUnpublishedFindingsForDomain(
            companyDomain,
            findings.filter((finding) => {
              if (finding.finding_id === findingId) {
                return false;
              }
              return true;
            })
          )
        );
      }
      dispatch(fetchDomainsWithFindings());
      dispatch(fetchFindings(false, 0, 0, fields.domain));
    } else if (
      orgId &&
      orgId > 0 &&
      (!fields.org_id || fields.org_id === 0) &&
      (!companyDomain || companyDomain === "") &&
      fields.domain &&
      fields.domain !== ""
    ) {
      // case 8. the finding has moved from a customer to a company
      const findings = _get(
        getState().analystPortal,
        `findings.${orgId}.unpublished`
      );
      if (findings) {
        dispatch(
          setUnpublishedFindings(
            orgId,
            findings.filter((finding) => {
              if (finding.finding_id === findingId) {
                return false;
              }
              return true;
            })
          )
        );
      }
      dispatch(fetchDomainsWithFindings());
      dispatch(fetchFindings(false, 0, 0, fields.domain));
    }
  };
};

export const deleteFinding = (orgId, groupId, findingId, companyDomain) => {
  return async (dispatch, getState) => {
    const opts = { finding_id: findingId, domain: companyDomain };

    try {
      await FetchCyberRiskUrl(
        "breachsight/analyst/finding",
        opts,
        { method: "DELETE" },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("error deleting finding", e);
      dispatch(addDefaultUnknownErrorAlert("Error deleting finding"));
      throw e;
    }

    // Remove the finding from various finding lists
    if (groupId > 0) {
      dispatch(fetchScratchRequestGroupsList(true, false));
      const findings = _get(
        getState().analystPortal,
        `scratchFindings.${groupId}.unpublished`
      );
      if (findings) {
        dispatch(
          setUnpublishedFindingsForSearchGroup(
            groupId,
            findings.filter((finding) => {
              if (finding.finding_id === findingId) {
                return false;
              }
              return true;
            })
          )
        );
      }
    }
    if (orgId > 0) {
      const findings = _get(
        getState().analystPortal,
        `findings.${orgId}.unpublished`
      );
      if (findings) {
        dispatch(
          setUnpublishedFindings(
            orgId,
            findings.filter((finding) => {
              if (finding.finding_id === findingId) {
                return false;
              }
              return true;
            })
          )
        );
      }
    }
    if (companyDomain !== "") {
      dispatch(fetchDomainsWithFindings());
      const findings = getState().analystPortal.domainFindings[companyDomain]
        ? getState().analystPortal.domainFindings[companyDomain].unpublished
        : null;
      if (findings) {
        dispatch(
          setUnpublishedFindingsForDomain(
            companyDomain,
            findings.filter((finding) => {
              if (finding.finding_id === findingId) {
                return false;
              }
              return true;
            })
          )
        );
      }
    }
  };
};

export const retractFinding = (findingId) => {
  return async (dispatch, getState) => {
    try {
      await FetchCyberRiskUrl(
        "breachsight/analyst/retract-finding",
        { finding_id: findingId },
        { method: "POST" },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("error retracting finding", e);
      dispatch(addDefaultUnknownErrorAlert("Error retracting finding"));
    }
  };
};

export const fetchFindings = (published, orgId, groupId, companyDomain) => {
  return async (dispatch, getState) => {
    let json;
    if (
      (!orgId || orgId === 0) &&
      (!groupId || groupId === 0) &&
      (!companyDomain || companyDomain === "")
    ) {
      LogError("fetchFindings - must supply orgId, groupId or domain name");
      return [];
    }
    try {
      json = await FetchCyberRiskUrl(
        "breachsight/analyst/finding",
        {
          published,
          org_id: orgId,
          group_id: groupId,
          domain_name: companyDomain,
        },
        {},
        dispatch,
        getState
      );
    } catch (e) {
      LogError("error fetching findings", e);
      dispatch(
        addDefaultUnknownErrorAlert("Error fetching findings for this customer")
      );
      return [];
    }

    const findings = json || [];

    if (published) {
      dispatch(setPublishedFindings(orgId, findings));
    } else {
      if (companyDomain && companyDomain !== "") {
        dispatch(setUnpublishedFindingsForDomain(companyDomain, findings));
      } else if (groupId > 0) {
        dispatch(setUnpublishedFindingsForSearchGroup(groupId, findings));
      } else if (orgId > 0) {
        dispatch(setUnpublishedFindings(orgId, findings));
      }
    }
    return findings;
  };
};

export const publishFindings = (orgId, findingIds) => {
  return async (dispatch, getState) => {
    const opts = {
      org_id: orgId,
      finding_ids: findingIds,
    };

    try {
      await FetchCyberRiskUrl(
        "breachsight/analyst/publish",
        null,
        {
          method: "POST",
          body: opts,
        },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("error publishing finding", e);
      dispatch(addDefaultUnknownErrorAlert("Error publishing finding"));
      throw e;
    }

    // get the list of published and unpublished findings again. presumably it has changed enough to warant this expense..
    dispatch(fetchFindings(false, orgId));

    if (_get(getState().analystPortal, `findings.${orgId}.unpublished`)) {
      dispatch(fetchFindings(true, orgId));
    }
  };
};

export const fetchScratchRequestGroupsList = (
  forceRefresh = false,
  background = false
) => {
  return async (dispatch, getState) => {
    const groups = getState().analystPortal.scratchRequestGroups;
    let json;

    if (!forceRefresh && groups && !groups.loading && groups.result) {
      return groups.result;
    }

    if (background === false) {
      dispatch(setScratchRequestsLoading(true));
    }

    try {
      json = await FetchCyberRiskUrl(
        "breachsight/analyst/request_group_list",
        null,
        null,
        dispatch,
        getState
      );
      // date format ISO8601: YYYY-MM-DD[THH:mm:ssZ]
    } catch (e) {
      dispatch(setScratchRequestsLoading(false));
      LogError("error retrieving scratch request list", e);
      json = null;
    }

    if (!json || json.status !== "OK") {
      dispatch(
        setScratchRequests({
          loading: false,
          error: {
            errorText:
              "Error fetching BreachSight scratch searches for analyst",
            actionText: "Retry",
            actionOnClick: () =>
              dispatch(fetchScratchRequestGroupsList(forceRefresh)),
          },
          groups: null,
          scratchID: 0,
        })
      );
      return null;
    }

    dispatch(
      setScratchRequests({
        loading: false,
        error: null,
        groups: json.groups,
        scratchID: json.scratchID,
      })
    );

    json.groups.forEach((group) => {
      dispatch(getSearchResultCountsByGroup(group.id));
    });

    return json.groups;
  };
};

export const validateCompanyDomains = (domains) => {
  return async (dispatch, getState) => {
    if (!domains || domains === "") {
      return { valid: true };
    }
    let json;

    try {
      json = await FetchCyberRiskUrl(
        "breachsight/vendor/check_domains/v1",
        { domain_list: domains },
        null,
        dispatch,
        getState
      );
    } catch (e) {
      LogError("error performing domain validation", e);
      json = null;
    }

    if (!json || json.status !== "OK") {
      json.error = "Error validating company domains";
      return json;
    }
    return json;
  };
};

export const fetchDomainsWithFindings = () => {
  return async (dispatch, getState) => {
    let json;

    try {
      dispatch(
        setDomainsWithFindings({ loading: true, error: null, domains: [] })
      );
      json = await FetchCyberRiskUrl(
        "breachsight/analyst/domains_with_findings",
        {},
        {},
        dispatch,
        getState
      );
    } catch (e) {
      LogError("error fetching domains with findings", e);
      dispatch(
        setDomainsWithFindings({
          loading: false,
          error: {
            errorText: "Error fetching domains with unpublished findings",
            actionText: "Retry",
            actionOnClick: () => dispatch(fetchDomainsWithFindings()),
          },
          domains: [],
        })
      );
      dispatch(
        addDefaultUnknownErrorAlert(
          "Error fetching domains with unpublished findings"
        )
      );
      return [];
    }

    dispatch(
      setDomainsWithFindings({
        loading: false,
        error: null,
        domains: json.domains,
      })
    );
    return json.domains;
  };
};

export const linkFindingsToOrg = (findings, orgId) => {
  let _findings = findings;
  return async (dispatch, getState) => {
    let json;

    const affectedGroups = {};
    const affectedOrgs = {};
    const affectedDomains = {};
    const transferredFindings = [];
    affectedOrgs[orgId] = true;

    for (let i = 0; i < _findings.length; i++) {
      //
      // for each finding, determine which cached finding lists are going to be affected by
      // the re-attachment of this finding to a new org
      //

      const finding = _findings[i];
      if (finding.group_id) {
        affectedGroups[finding.group_id] = true;
      }
      if (finding.org_id) {
        affectedOrgs[finding.org_id] = true;
      }
      if (finding.companyDomains) {
        const domains = finding.companyDomains.split(",");
        for (let i = 0; i < domains.length; i++) {
          affectedDomains[domains[i].trim()] = true;
        }
      }
      transferredFindings.push(finding.finding_id);

      //
      // now perform the org re-attach for the finding
      //

      try {
        json = await FetchCyberRiskUrl(
          "breachsight/analyst/link_finding",
          { finding_id: finding.finding_id, org_id: orgId },
          { method: "PUT" },
          dispatch,
          getState
        );
      } catch (e) {
        LogError("error linking finding to orgs", e);
        dispatch(
          addDefaultUnknownErrorAlert("Error assigning finding to customer")
        );
        return;
      }
    } // for finding

    const promises = [];

    //
    // update the cached unpublished findings for any affected orgs, including
    // the list (summary) of customers to update all cached finding counts.
    //

    promises.push(dispatch(fetchCustomerList(true, false)));
    for (var orgID in affectedOrgs) {
      promises.push(dispatch(fetchFindings(false, orgID, 0, "")));
    }

    //
    // update cached unpublished finding lists for each affected group. basically we just
    // delete each of the transferred findings from the finding list of each affected group, and then
    // re-load the list of groups to update all cached finding counts.
    //

    let numGroups = 0;
    for (var groupID in affectedGroups) {
      numGroups = numGroups + 1;
      const findings = _get(
        getState().analystPortal,
        `scratchFindings.${groupID}.unpublished`
      );
      if (findings) {
        dispatch(
          setUnpublishedFindingsForSearchGroup(
            groupID,
            findings.filter((finding) => {
              for (let k = 0; k < transferredFindings.length; k++) {
                if (finding.finding_id === transferredFindings[k]) {
                  return false;
                }
              }
              return true;
            })
          )
        );
      }
    }
    if (numGroups > 0) {
      await dispatch(fetchScratchRequestGroupsList(true, false));
    }

    //
    // lastly, update cached unpublished finding lists for each affected domain. basically we just
    // delete each of the transferred findings from the finding list of each affected domain, and then
    // re-load the list (summary) of domains (companies) that have unpublished findings.
    //

    let numDomains = 0;
    for (var domain in affectedDomains) {
      numDomains = numDomains + 1;
      const findings = getState().analystPortal.domainFindings[domain]
        ? getState().analystPortal.domainFindings[domain].unpublished
        : null;
      if (findings) {
        dispatch(
          setUnpublishedFindingsForDomain(
            domain,
            findings.filter((finding) => {
              for (let k = 0; k < transferredFindings.length; k++) {
                if (finding.finding_id === transferredFindings[k]) {
                  return false;
                }
              }
              return true;
            })
          )
        );
      }
    }
    if (numDomains > 0) {
      promises.push(dispatch(fetchDomainsWithFindings()));
    }

    return Promise.all(promises);
  };
};

export const linkFindingsToDomains = (findings, domains) => {
  let _findings = findings;
  return async (dispatch, getState) => {
    let json;

    const affectedGroups = {};
    const affectedOrgs = {};
    const affectedDomains = {};
    const transferredFindings = [];

    for (let i = 0; i < _findings.length; i++) {
      //
      // for each finding, determine which cached finding lists are going to be affected by
      // the re-attachment of this finding to new domains
      //

      const finding = _findings[i];
      if (finding.group_id) {
        affectedGroups[finding.group_id] = true;
      }
      if (finding.org_id) {
        affectedOrgs[finding.org_id] = true;
      }
      if (finding.companyDomains) {
        const existingDomains = finding.companyDomains.split(",");
        for (let i = 0; i < existingDomains.length; i++) {
          affectedDomains[existingDomains[i].trim()] = true;
        }
      }
      for (let i = 0; i < domains.length; i++) {
        affectedDomains[domains[i].trim()] = true;
      }
      transferredFindings.push(finding.finding_id);

      //
      // now perform the domain re-attach for the finding
      //

      try {
        json = await FetchCyberRiskUrl(
          "breachsight/analyst/domains_finding_link",
          { finding_id: finding.finding_id, domains: domains.join(",") },
          { method: "PUT" },
          dispatch,
          getState
        );
      } catch (e) {
        LogError("error linking finding to domains", e);
        dispatch(
          addDefaultUnknownErrorAlert("Error assigning finding to company")
        );
        return;
      }
    }

    //
    // update the cached unpublished findings for any affected orgs. basically we just
    // delete each of the transferred findings from the finding list of each affected org, and then
    // re-load the list of customers (orgs) to update all cached finding counts.
    //

    const promises = [];
    let numOrgs = 0;
    for (var orgID in affectedOrgs) {
      numOrgs = numOrgs + 1;
      const findings = _get(
        getState().analystPortal,
        `findings.${orgID}.unpublished`
      );
      if (findings) {
        dispatch(
          setUnpublishedFindings(
            orgID,
            findings.filter((finding) => {
              for (let k = 0; k < transferredFindings.length; k++) {
                if (finding.finding_id === transferredFindings[k]) {
                  return false;
                }
              }
              return true;
            })
          )
        );
      }
    }
    if (numOrgs > 0) {
      promises.push(dispatch(fetchCustomerList(true, false)));
    }

    //
    // update cached unpublished finding lists for affected groups. basically we just
    // delete each of the transferred findings from the finding list of each affected group, and then
    // re-load the list of groups to update all cached finding counts.
    //

    let numGroups = 0;
    for (var groupID in affectedGroups) {
      numGroups = numGroups + 1;
      const findings = _get(
        getState().analystPortal,
        `scratchFindings.${groupID}.unpublished`
      );
      if (findings) {
        dispatch(
          setUnpublishedFindingsForSearchGroup(
            groupID,
            findings.filter((finding) => {
              for (let k = 0; k < transferredFindings.length; k++) {
                if (finding.finding_id === transferredFindings[k]) {
                  return false;
                }
              }
              return true;
            })
          )
        );
      }
    }
    if (numGroups.length > 0) {
      promises.push(dispatch(fetchScratchRequestGroupsList(true, false)));
    }

    //
    // finally,, for each affected domain (company) we re-load the list of unpublished findings for
    // that domain and re-load the list of companies that have findings attached.
    //

    let numDomains = 0;
    for (var domain in affectedDomains) {
      promises.push(dispatch(fetchFindings(false, 0, 0, domain)));
      numDomains = numDomains + 1;
    }
    if (numDomains > 0) {
      promises.push(dispatch(fetchDomainsWithFindings()));
    }

    return Promise.all(promises);
  };
};

export const VENDOR_SEARCH_RESPONSE_LIMIT = 30;

export const runFetchVendorSearchResults = async (
  query,
  domainSearch,
  getState,
  dispatch
) => {
  let json;

  try {
    json = await FetchCyberRiskUrl(
      "vendorsearch/v1/",
      {
        srch: query,
        domain_srch: domainSearch,
        consider_empty_cloudscan_vendors: true,
        limit: VENDOR_SEARCH_RESPONSE_LIMIT,
        vendor_label_ids: [],
        include_unlabeled: true,
      },
      null,
      dispatch,
      getState
    );
  } catch (e) {
    LogError("Error retrieving search results", e);
  }

  // Only update the results the query still matches what's in the state
  if (
    json &&
    json.status === "OK" &&
    query === getState().analystPortal.vendorSearch.query
  ) {
    dispatch(
      setVendorSearch({
        loading: false,
        results: { vendors: json.vendors },
      })
    );
  }
};

// debounce the actual fetch used in the action below so we wait until the user has not typed a character for more than half a second
const debouncedRunFetchVendorSearchResults = _debounce(
  runFetchVendorSearchResults,
  500
);

export const fetchVendorSearchResults = (q, domainSearch, force = false) => {
  return async (dispatch, getState) => {
    const prevVendorSearch = getState().cyberRisk.vendorSearch;
    const prevQuery = prevVendorSearch.query;
    const prevDomainQuery = prevVendorSearch.domainSearch;
    const query = q.toLowerCase().trim();

    // If the query hasn't changed, leave it alone
    if (!force && prevQuery === query && prevDomainQuery === domainSearch)
      return;

    if (shouldBypassSearch(query)) {
      // Don't kick off a huge search for a 1 character query. We'll just filter the frontend.
      // Kill off any previous loading state or ajax search results though.
      dispatch(
        setVendorSearch({
          loading: false,
          query,
          domainSearch,
          results: {},
        })
      );
      return;
    }

    // If the prevQuery is a substring of the new query,
    // check if we have less than the Limit for each of the sections in the search results
    if (!force && query.startsWith(prevQuery)) {
      const filterFunc = (vendor) =>
        (vendor.display_name || vendor.name).toLowerCase().startsWith(query);
      let newVendors = prevVendorSearch.results.vendors || [];
      let newWatchedVendors = prevVendorSearch.results.watchedVendors || [];

      if (
        newVendors.length > 0 &&
        newVendors.length < VENDOR_SEARCH_RESPONSE_LIMIT
      ) {
        newVendors = newVendors.filter(filterFunc);
        newWatchedVendors = newWatchedVendors.filter(filterFunc);
        // Vendors results were under the response limit and now filtered on the frontend,
        // so we needn't call the API again
        dispatch(
          setVendorSearch({
            query,
            domainSearch,
            results: {
              vendors: newVendors,
              watchedVendors: newWatchedVendors,
              vendorsTotal: newVendors.length,
            },
          })
        );
        return;
      }
    }

    dispatch(
      setVendorSearch({
        query,
        domainSearch,
        loading: true,
      })
    );

    // Kick off a new search since we haven't filtered both, or either, of the previous search results
    debouncedRunFetchVendorSearchResults(
      query,
      domainSearch,
      getState,
      dispatch
    );
  };
};
