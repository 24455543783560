import { ILabel } from "../../../_common/types/label";
import ColorCheckbox from "../ColorCheckbox";
import PillLabel from "../PillLabel";

import "../../style/components/LabelChoiceSet.scss";

interface LabelChoiceSetProps {
  labels: ILabel[];
  selectedLabels: number[];
  onLabelSelected: (id: number, isSelected: boolean) => void;
}

const LabelChoiceSet = (props: LabelChoiceSetProps) => {
  const labelDisplays = props.labels.map((l) => (
    <LabelDisplay
      key={l.id}
      label={l}
      isSelected={props.selectedLabels.indexOf(l.id) !== -1}
      onSelected={(isSelected) => {
        props.onLabelSelected(l.id, isSelected);
      }}
    />
  ));

  return <div className={"label-choice-set"}>{labelDisplays}</div>;
};

export default LabelChoiceSet;

interface LabelChoiceProps {
  label: ILabel;
  isSelected: boolean;
  onSelected: (isSelected: boolean) => void;
}

const LabelDisplay = (props: LabelChoiceProps) => {
  const { label, isSelected, onSelected } = props;
  return (
    <div className={"label-display"}>
      <ColorCheckbox
        label={<PillLabel color={label.colour}>{label.name}</PillLabel>}
        checked={isSelected}
        onClick={() => {
          onSelected(!isSelected);
        }}
      />
      {label.description && <p>{label.description}</p>}
    </div>
  );
};

export const sortLabels = (labels: ILabel[]): ILabel[] => {
  return labels.sort((a, b) => {
    // "In-Use" should be the very first
    if (a.name === "In-Use") return -1;
    if (b.name === "In-Use") return 1;

    return a.name.localeCompare(b.name);
  });
};
