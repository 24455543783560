// checks that the org entitlements contain the required entitlement
import { IUserData } from "./types/redux";
import { DisableChatGPT } from "./permissions";
import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "./types/reduxStore";

// ChatGPT features should be displayed if:
// - the user doesn't belong to an org, OR
// - the user belongs to an org that hasn't explicitly disabled the feature
export const shouldDisplayChatGPT = (userData: IUserData): boolean => {
  if (!userData.currentOrgID) return true;
  return !userData.orgPermissions.includes(DisableChatGPT);
};

export const chatGPTAccessSelector = createSelector(
  [(state: RootState) => state.common.userData],
  (userData) => {
    return {
      shouldDisplayChatGPT: shouldDisplayChatGPT(userData),
    };
  }
);
