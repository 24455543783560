// Top level report types
export const BreachSight = "BreachSight";
export const VendorRisk = "VendorRisk";

// BreachSight report types
export const BreachSightOverview = "BreachSightOverview";
export const BreachSightGeolocation = "BreachSightGeolocation";
export const BreachSightRiskBreakdown = "BreachSightRiskBreakdown";
export const BreachSightCompetitorAnalysis = "BreachSightCompetitorAnalysis";

export const BreachSightSubsidiariesAnalysis =
  "BreachSightSubsidiariesAnalysis";
export const BreachSightSubsidiariesGeolocation =
  "BreachSightSubsidiariesGeolocation";
export const BreachSightSubsidiariesScoreDistribution =
  "BreachSightSubsidiariesScoreDistribution";
export const BreachSightSubsidiariesRatings = "BreachSightSubsidiariesRatings";
export const BreachSightSubsidiariesRiskBreakdown =
  "BreachSightSubsidiariesRiskBreakdown";
export const BreachSightSubsidiariesOverview =
  "BreachSightSubsidiariesOverview";
export const BreachSightSubsidiariesCompetitors =
  "BreachSightSubsidiariesCompetitors";

// VendorRisk report types
export const VendorRiskOverview = "VendorRiskOverview";
export const VendorRiskGeolocation = "VendorRiskGeolocation";
export const VendorRiskRatingsBreakdown = "VendorRiskRatingsBreakdown";
export const VendorRiskQuestionnaireActivity =
  "VendorRiskQuestionnaireActivity";
export const VendorRiskSupplyChain = "VendorRiskSupplyChain";
export const VendorRiskRiskMatrix = "VendorRiskRiskMatrix";
export const VendorRiskAssessmentSummary = "VendorRiskAssessmentSummary";
