import { FetchCyberRiskUrl } from "../../_common/api";
import { LogError } from "../../_common/helpers";
import {
  conditionalRefreshActivityStreamForOrgUser,
  grabTPVMSession,
} from "../../_common/reducers/commonActions";
import { SET_ORG_DEFAULT_TEXTS } from "./cyberRiskActions";
import { DefaultAction } from "../../_common/types/redux";
import {
  DefaultTextType,
  MergeTag,
  OrgDefaultTexts,
  SystemDefaultTexts,
} from "../../_common/types/orgDefaultTexts";

interface IOrganisationTemplateTextsResponse {
  DefaultTexts: OrgDefaultTexts;
  UnknownMergeTags: { [key in DefaultTextType]: string };
}

export const fetchOrganisationDefaultTexts = (
  force = false,
  isManagementAnalystSession = false,
  managedOrgId?: number
): DefaultAction<OrgDefaultTexts> => {
  return async (dispatch, getState) => {
    if (!force) {
      let defaultTexts: OrgDefaultTexts | undefined;
      if (!isManagementAnalystSession && getState().cyberRisk.orgDefaultTexts) {
        defaultTexts = getState().cyberRisk.orgDefaultTexts;
      }

      if (
        isManagementAnalystSession &&
        managedOrgId &&
        getState().cyberRisk.managedVendorData[managedOrgId] &&
        getState().cyberRisk.managedVendorData[managedOrgId].orgDefaultTexts
      ) {
        defaultTexts =
          getState().cyberRisk.managedVendorData[managedOrgId].orgDefaultTexts;
      }

      if (defaultTexts) {
        return defaultTexts;
      }
    }

    let json: IOrganisationTemplateTextsResponse;
    try {
      json = await FetchCyberRiskUrl(
        "account/default_text/v1",
        null,
        null,
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error fetching organisation default texts", e);

      throw e;
    }

    dispatch(
      setOrgDefaultTexts(
        json.DefaultTexts,
        isManagementAnalystSession,
        managedOrgId
      )
    );

    return json.DefaultTexts;
  };
};

export const setOrgDefaultTexts = (
  defaultTexts: OrgDefaultTexts,
  isManagementAnalystSession?: boolean,
  managedOrgId?: number
) => {
  return {
    type: SET_ORG_DEFAULT_TEXTS,
    defaultTexts,
    isManagementAnalystSession,
    managedOrgId,
  };
};

export const setOrganisationDefaultText = (
  defaultTextType: DefaultTextType,
  value: string,
  hideSection = false
): DefaultAction => {
  return async (dispatch, getState) => {
    try {
      await FetchCyberRiskUrl(
        "account/default_text/v1",
        { default_text_type: defaultTextType, hide_section: hideSection },
        { method: "PUT", body: value },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error setting organisation default text", e);

      throw e;
    }

    dispatch(
      setOrgDefaultTexts({
        [defaultTextType]: {
          textType: defaultTextType,
          defaultText: value,
          hideSection,
        },
      })
    );

    // kick off call to update the activity stream
    dispatch(conditionalRefreshActivityStreamForOrgUser());
  };
};

export const SET_SYSTEM_DEFAULT_TEXTS = "SET_SYSTEM_DEFAULT_TEXTS";
export const setSystemDefaultTexts = (texts: SystemDefaultTexts) => ({
  type: SET_SYSTEM_DEFAULT_TEXTS,
  texts,
});

export const fetchSystemDefaultTexts =
  (force = false): DefaultAction =>
  async (dispatch, getState) => {
    if (!force && getState().cyberRisk.systemDefaultTexts) {
      return;
    }

    let json: SystemDefaultTexts;
    try {
      json = await FetchCyberRiskUrl(
        "account/default_template_texts/v1",
        {},
        {},
        dispatch,
        getState
      );
    } catch (e) {
      LogError("error getting system default texts", e);
      throw e;
    }

    dispatch(setSystemDefaultTexts(json));
  };

interface getOrgVendorMergeTagsResp {
  tags: MergeTag[];
}

export const SET_ORG_VENDOR_MERGE_TAGS = "SET_ORG_VENDOR_MERGE_TAGS";
export const SetOrgVendorMergeTags = (
  tags: MergeTag[],
  managedOrgId?: number
) => ({
  type: SET_ORG_VENDOR_MERGE_TAGS,
  tags,
  managedOrgId,
});

export const fetchOrgVendorMergeTags =
  (force = false): DefaultAction =>
  async (dispatch, getState) => {
    const tpvm = grabTPVMSession(getState);

    if (!force) {
      if (
        tpvm.tpvm &&
        getState().cyberRisk.managedVendorData[tpvm.tpvm_o as any]
          ?.orgVendorMergeTags
      ) {
        return;
      } else if (getState().cyberRisk.orgVendorMergeTags) {
        return;
      }
    }

    let resp: getOrgVendorMergeTagsResp;
    try {
      resp = await FetchCyberRiskUrl(
        "account/vendor_merge_tags/v1",
        {},
        {},
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error getting organisation vendor merge tags", e);
      throw e;
    }

    dispatch(SetOrgVendorMergeTags(resp.tags, tpvm.tpvm_o as any));
  };
