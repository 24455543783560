import { FC, useEffect, useState } from "react";
import { createPortal } from "react-dom";
import "../style/components/FullPageConfetti.scss";

interface FullPageConfettiProps {
  fireKey?: string | number; //changing the key will trigger a new fire
  fireTime: number;
}

// based on https://codepen.io/azuracura/pen/wvLxVYR

const FullPageConfetti: FC<FullPageConfettiProps> = ({ fireKey, fireTime }) => {
  const [isFiring, setIsFiring] = useState(false);

  useEffect(() => {
    if (fireKey != undefined) {
      setIsFiring(true);
      setTimeout(() => setIsFiring(false), fireTime);
    }
    return () => setIsFiring(false);
  }, [fireKey, fireTime]);

  if (!isFiring) {
    return <></>;
  }

  return (
    <div>
      {createPortal(
        <div className={"full-page-confetti"}>
          {Array(150)
            .fill(0)
            .map((_, i) => (
              <div key={i} className={`confetti-${i}`} />
            ))}
        </div>,
        document.body
      )}
    </div>
  );
};

export default FullPageConfetti;
