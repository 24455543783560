import { fetchSurveyLockStatus, SurveyLockState } from "../reducers/apiActions";
import { DefaultThunkDispatchProp } from "../../_common/types/redux";

import { useInterval } from "../../_common/hooks";
import { setAnswers, setLockState } from "../reducers/actions";
import { useEffect, useState } from "react";
import {
  fetchPublicQuestionnaire,
  fetchQuestionnaire,
} from "../../vendorrisk/reducers/questionnaireAnswers.actions";
import {
  addDefaultSuccessAlert,
  addDefaultUnknownErrorAlert,
} from "../../_common/reducers/messageAlerts.actions";
import { getAnswersForNodes } from "../surveyViewer.helpers";
import { appConnect } from "../../_common/types/reduxHooks";

interface SurveyViewerLockCheckerOwnProps {
  surveyId: number;
  isPublicSurvey: boolean;
}

interface SurveyViewerLockCheckerConnectedProps {
  lockState: SurveyLockState;
}

type SurveyViewerLockCheckerProps = SurveyViewerLockCheckerOwnProps &
  SurveyViewerLockCheckerConnectedProps &
  DefaultThunkDispatchProp;

// Invisible component for managing survey lock state
const SurveyViewerLockChecker = (props: SurveyViewerLockCheckerProps) => {
  const [checkForLockState, setCheckForLockState] = useState(false);

  // Respond to start/stop checking for lock state if needed
  useEffect(() => {
    setCheckForLockState(props.lockState.isLocked);
  }, [props.lockState]);

  // Start the lock check loop
  useInterval(() => {
    if (props.surveyId && checkForLockState) {
      props
        .dispatch(fetchSurveyLockStatus(props.surveyId, props.isPublicSurvey))
        .then((statusResp) => {
          if (props.lockState.isLocked && !statusResp.isLocked) {
            // No longer locked, so get latest answers
            const prom = props.isPublicSurvey
              ? props.dispatch(fetchPublicQuestionnaire(props.surveyId, false))
              : props.dispatch(fetchQuestionnaire(props.surveyId, false));
            prom
              .then((qResp) => {
                // Transform answers
                const answersForNodes = getAnswersForNodes(
                  qResp.answers,
                  qResp.structure[0]
                );
                props.dispatch(setAnswers(answersForNodes));

                // Finally, set lock state + notify user
                props.dispatch(setLockState(statusResp));
                setCheckForLockState(false);

                props.dispatch(
                  addDefaultSuccessAlert("Questionnaire is now unlocked")
                );
              })
              .catch(() => {
                props.dispatch(
                  addDefaultUnknownErrorAlert(
                    "Error retrieving latest answers",
                    ["Please reload the questionnaire."]
                  )
                );
              });
          }
        });
    }
  }, 60000);
  return <></>;
};

export default appConnect<
  SurveyViewerLockCheckerConnectedProps,
  never,
  SurveyViewerLockCheckerOwnProps
>((state) => {
  return {
    lockState: state.surveyViewer.lock,
  };
})(SurveyViewerLockChecker);
