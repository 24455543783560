import { Component } from "react";
import PropTypes from "prop-types";
import { sortBy as _sortBy } from "lodash";
import Button from "../../../_common/components/core/Button";
import { closeModal } from "../../../_common/reducers/commonActions";
import { NumberWithCommas } from "../../../_common/helpers";
import "../../style/components/EmailExposureNotificationModal.scss";
import TrackedButton from "../../../_common/components/TrackedButton";
import TextField from "../../../_common/components/TextField";
import moment from "moment";
import Icon from "../../../_common/components/core/Icon";
import LabelList from "../LabelList";
import ModalForm from "./ModalForm";
import { emailExposureSeverityNumberToLabelColor } from "../email_exposures/IdentityBreachesCard";
import { replaceVariablesInDefaultTexts } from "../../../_common/types/orgDefaultTexts";
import RichTextEditV2 from "../../../_common/components/RichTextEditV2";

export const EmailExposureNotificationModalName =
  "EmailExposureNotificationModal";

class EmailExposureNotificationModal extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    modalData: PropTypes.shape({
      sender: PropTypes.string.isRequired,
      breach: PropTypes.object.isRequired,
      totalRecipients: PropTypes.number.isRequired,
      recipientDomain: PropTypes.string.isRequired,
      recipientName: PropTypes.string.isRequired,
      onSend: PropTypes.func.isRequired,
      defaultSubject: PropTypes.string.isRequired,
      defaultMessage: PropTypes.string.isRequired,
      orgName: PropTypes.string.isRequired,
    }).isRequired,
  };

  constructor(props) {
    super(props);

    const [subject, message] = replaceVariablesInDefaultTexts(
      [props.modalData.defaultSubject, props.modalData.defaultMessage],
      {
        "{{BreachName}}": props.modalData.breach.Title,
        "{{AccountName}}": props.modalData.orgName,
      }
    );

    this.state = {
      loading: false,
      subject,
      message,
      isSubjectValid: true,
    };
  }

  getSortedDataClasses = (dataClasses) =>
    _sortBy(dataClasses, "Severity", (dc) => -1 * dc.Class.length).reverse();

  render() {
    const { breach, totalRecipients, recipientDomain, recipientName } =
      this.props.modalData;

    let recipientCountInfo = "";
    if (totalRecipients > 1) {
      recipientCountInfo = ` (and ${NumberWithCommas(totalRecipients - 1)} ${
        totalRecipients === 2 ? "other account" : "others"
      })`;
    }

    return (
      <>
        <div className="modal-header">
          <h2>Notify employees about {breach.Title}</h2>
        </div>
        <div className="modal-content email-exposure-modal">
          <ModalForm>
            <div className="form-section">
              <div className="form-section-desc">
                <span>To</span>
                <p>
                  This email will be sent to{" "}
                  {totalRecipients === 1
                    ? "1 recipient"
                    : `${NumberWithCommas(totalRecipients)} recipients`}
                  .
                </p>
              </div>
              <div className="form-section-input">
                <input
                  type="text"
                  placeholder={
                    recipientName + "@" + recipientDomain + recipientCountInfo
                  }
                  disabled
                />
              </div>
            </div>
            <div className="form-section">
              <div className="form-section-desc">Subject</div>
              <div className="form-section-input">
                <TextField
                  name="subject"
                  value={this.state.subject}
                  onChanged={(v, isValid) =>
                    this.setState({ subject: v, isSubjectValid: isValid })
                  }
                  disabled={this.state.loading}
                  maxLength={300}
                  required
                />
              </div>
            </div>
            <div className="form-section">
              <div className="form-section-desc">Message</div>
              <div className="form-section-input">
                <RichTextEditV2
                  value={this.state.message}
                  onChange={(v) => this.setState({ message: v })}
                />
              </div>
            </div>
            <div className="form-section">
              <div className="form-section-desc">Breach</div>
              <div className="form-section-input">
                <span>{breach.Title}</span>
                <p>
                  Date of breach:{" "}
                  {breach.BreachDate
                    ? moment(breach.BreachDate).format("ll")
                    : "Unknown"}
                </p>
              </div>
            </div>
            <div className="form-section">
              <div className="form-section-desc">
                Accounts and data in breach
              </div>
              <div className="form-section-input">
                {NumberWithCommas(breach.PwnCount)}
                <LabelList
                  maxWidth={250}
                  labels={this.getSortedDataClasses(breach.DataClasses).map(
                    (dc) => ({
                      id: dc.Class,
                      name: dc.Class,
                      bordered: true,
                      color:
                        emailExposureSeverityNumberToLabelColor[dc.Severity],
                    })
                  )}
                />
              </div>
            </div>
            <div className="form-section">
              <div className="form-section-desc">Description</div>
              <div className="form-section-input">
                <p dangerouslySetInnerHTML={{ __html: breach.Description }} />
              </div>
            </div>
          </ModalForm>
        </div>
        <div className="modal-footer">
          <Button danger onClick={() => this.props.dispatch(closeModal())}>
            Cancel
          </Button>
          <TrackedButton
            eventName="IdentityBreachNotificationSent"
            eventProperties={{ totalRecipients }}
            loading={this.state.loading}
            disabled={this.state.loading || !this.state.isSubjectValid}
            onClick={async () => {
              this.setState({ loading: true });
              await this.props.modalData.onSend(
                this.state.subject,
                this.state.message
              );
              this.setState({ loading: false });
            }}
          >
            <Icon name="email" />
            Send {NumberWithCommas(totalRecipients)}{" "}
            {totalRecipients > 1 ? "Emails" : "Email"}
          </TrackedButton>
        </div>
      </>
    );
  }
}

export default EmailExposureNotificationModal;
