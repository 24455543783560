export enum SurveyFramework {
  NONE = "NONE",
  ISO27001_2013 = "ISO",
  ISO27001_2022 = "ISO27001:2022",
  NISTCSF = "NISTCSF",
  PCIDSS = "PCIDSS",
  COBIT5 = "COBIT5",
  NISTSP = "NISTSP",
  ISA624432009 = "ISA624432009",
  ISA624432013 = "ISA624432013",
  GDPR = "GDPR",
  CIS = "CIS",
}

export const SurveyFrameworkDisplayNames = {
  [SurveyFramework.ISO27001_2013]: "ISO27001:2013",
  [SurveyFramework.ISO27001_2022]: "ISO27001:2022",
  [SurveyFramework.NISTCSF]: "NIST CSF",
  [SurveyFramework.PCIDSS]: "PCI DSS",
  [SurveyFramework.COBIT5]: "COBIT 5",
  [SurveyFramework.NISTSP]: "NIST SP",
  [SurveyFramework.ISA624432009]: "ISA-62443 2009",
  [SurveyFramework.ISA624432013]: "ISA-62443 2013",
  [SurveyFramework.GDPR]: "GDPR",
  [SurveyFramework.CIS]: "CIS",
  [SurveyFramework.NONE]: "No framework",
};

// Frameworks here are internal only, so should not appear in survey builder as other frameworks here do.
// All PrivateSurveyFramework values are accessible to the admin Questionnaire import tool.
// Once a framework gets wider release, move this into the SurveyFramework enum.
enum internalSurveyFramework {
  UPGUARD_2024 = "UPGUARD:2024",
  NISTCSF2_0 = "NISTCSF2.0",
}

export type PrivateSurveyFramework = SurveyFramework | internalSurveyFramework;

export const PrivateSurveyFrameworkDisplayNames = {
  [internalSurveyFramework.UPGUARD_2024]: "UpGuard 2024",
  [internalSurveyFramework.NISTCSF2_0]: "NIST CSF 2.0",
  ...SurveyFrameworkDisplayNames,
};
