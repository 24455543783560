import { get as _get } from "lodash";
import { FetchCyberRiskUrl, ResponseError } from "../../_common/api";
import { LogError } from "../../_common/helpers";
import {
  setAutomationQuestionsForSurveyType,
  setAutomationRecipe,
  setAutomationRecipeEnabledFlag,
  setSurveyTypeAutomationList,
  setSurveyTypeAutomationEnabledFlag,
  removeRecipeFromAutomationList,
  setAutosaveStatus,
  setSyntaxStatus,
  setAutomationRecipeState,
  setEditsExistOnAutomation,
} from "./cyberRiskActions";
import { DefaultAction } from "../../_common/types/redux";
import {
  IAutomationQuestion,
  IAutomationRecipe,
  IAutomationWeights,
} from "../types/automation";

interface fetchAutomationForSurveyTypeResp {
  status: string;
  surveyTypeId: number;
  draftChangesExist: boolean;
  numDraftDeletions: number;
  publishedAutomation: IAutomationRecipe[];
  draftAutomation: IAutomationRecipe[];
}

export const fetchAutomationForSurveyType = (
  surveyTypeId: number,
  force = false,
  quietly = false
): DefaultAction => {
  return async (dispatch, getState) => {
    if (!force) {
      const existing = _get(
        getState().cyberRisk,
        `questionnaireAutomation[${surveyTypeId}]`
      );
      if (existing && !existing.error) {
        return existing;
      }
    }

    if (!quietly) {
      dispatch(
        setSurveyTypeAutomationList(surveyTypeId, true, null, false, 0, null)
      );
    }

    let json: fetchAutomationForSurveyTypeResp;
    try {
      json = await FetchCyberRiskUrl(
        "survey/automation/list/v1/",
        {
          qn_type_id: surveyTypeId,
        },
        null,
        dispatch,
        getState
      );
    } catch (e) {
      LogError(`Error fetching automation list for questionnaire type`, e);
      const error: IError = {
        errorText: `Error fetching automation list: ${e}`,
        actionText: "Try again",
        actionOnClick: () =>
          dispatch(fetchAutomationForSurveyType(surveyTypeId, true)),
      };
      dispatch(
        setSurveyTypeAutomationList(
          surveyTypeId,
          false,
          error,
          false,
          0,
          null,
          null
        )
      );
      return;
    }

    if (!json) {
      LogError(
        `Empty response fetching automation list for questionnaire type`
      );
      const error: IError = {
        errorText: `Error fetching automation list: empty response from server`,
        actionText: "Try again",
        actionOnClick: () =>
          dispatch(fetchAutomationForSurveyType(surveyTypeId, true)),
      };
      dispatch(
        setSurveyTypeAutomationList(
          surveyTypeId,
          false,
          error,
          false,
          0,
          null,
          null
        )
      );
      return;
    }
    dispatch(
      setSurveyTypeAutomationList(
        surveyTypeId,
        false,
        null,
        json.draftChangesExist,
        json.numDraftDeletions,
        json.publishedAutomation,
        json.draftAutomation
      )
    );
  };
};

interface fetchAutomationRecipeResp {
  status: string;
  recipe: IAutomationRecipe;
}

export const fetchAutomationRecipe = (
  recipeUUID: string,
  force = false
): DefaultAction<IAutomationRecipe> => {
  return async (dispatch, getState) => {
    if (!force) {
      const existing = _get(
        getState().cyberRisk,
        `automationRecipes[${recipeUUID}]`
      );
      if (existing && !existing.error) {
        return existing.data;
      }
    }

    dispatch(setAutomationRecipe(recipeUUID, true, null, null));
    let json: fetchAutomationRecipeResp;
    try {
      json = await FetchCyberRiskUrl(
        "survey/automation/v1/",
        {
          recipe_uuid: recipeUUID,
        },
        null,
        dispatch,
        getState
      );
    } catch (e) {
      LogError(`Error fetching automation recipe details`, e);
      const error: IError = {
        errorText: `Error fetching automation details: ${e}`,
        actionText: "Try again",
        actionOnClick: () => dispatch(fetchAutomationRecipe(recipeUUID, true)),
      };
      dispatch(setAutomationRecipe(recipeUUID, false, error, null));
      return;
    }

    if (!json) {
      LogError(`Empty response fetching automation entry details`);
      const error: IError = {
        errorText: `Error fetching automation details: empty response from server`,
        actionText: "Try again",
        actionOnClick: () => dispatch(fetchAutomationRecipe(recipeUUID, true)),
      };
      dispatch(setAutomationRecipe(recipeUUID, false, error, null));
      return;
    }
    dispatch(setAutomationRecipe(recipeUUID, false, null, json.recipe));
    return json.recipe;
  };
};

export const createNewAutomationRecipe = (
  surveyTypeId: number
): DefaultAction<string> => {
  return async (dispatch, getState) => {
    let json: fetchAutomationRecipeResp;
    try {
      json = await FetchCyberRiskUrl(
        "survey/automation/new/v1/",
        {
          survey_type_id: surveyTypeId,
        },
        { method: "PUT" },
        dispatch,
        getState
      );
    } catch (e) {
      LogError(`Error creating new automation recipe`, e);
      throw `Error creating new automation instance: ${e}`;
    }

    if (!json) {
      LogError(`Empty response fetching automation entry details`);
      throw `Failed to create new automation instance: empty response from server`;
    }

    dispatch(setAutomationRecipe(json.recipe.uuid, false, null, json.recipe));
    return json.recipe.uuid;
  };
};

export const createDuplicateAutomationRecipe = (
  surveyTypeId: number,
  recipeUUID: string
): DefaultAction<string> => {
  return async (dispatch, getState) => {
    let json: fetchAutomationRecipeResp;
    try {
      json = await FetchCyberRiskUrl(
        "survey/automation/duplicate/v1/",
        {
          recipe_uuid: recipeUUID,
        },
        { method: "PUT" },
        dispatch,
        getState
      );
    } catch (e) {
      LogError(`Error creating duplicate automation recipe`, e);
      throw `Error creating duplicate automation instance: ${e}`;
    }

    if (!json) {
      LogError(`Empty response fetching automation entry details`);
      throw `Failed to create duplicate automation instance: empty response from server`;
    }

    dispatch(fetchAutomationForSurveyType(surveyTypeId, true, true));
    dispatch(setAutomationRecipe(json.recipe.uuid, false, null, json.recipe));
    return json.recipe.uuid;
  };
};

export const createNewDraftAutomationRecipe = (
  masterUUID: string,
  existingDraftUUID?: string
): DefaultAction<string> => {
  return async (dispatch, getState) => {
    let json: fetchAutomationRecipeResp;
    try {
      json = await FetchCyberRiskUrl(
        "survey/automation/draft/v1/",
        {
          recipe_uuid: masterUUID,
          existing_draft_uuid: existingDraftUUID
            ? existingDraftUUID
            : undefined,
        },
        { method: "PUT" },
        dispatch,
        getState
      );
    } catch (e) {
      LogError(`Error creating new automation recipe draft`, e);
      throw `Error creating new automation draft: ${e}`;
    }

    if (!json) {
      LogError(`Empty response creating automation draft`);
      throw `Error to creating new automation draft: empty response from server`;
    }

    dispatch(setAutomationRecipe(json.recipe.uuid, false, null, json.recipe));
    return json.recipe.uuid;
  };
};

export const revertCurrentAutomationDrafts = (
  surveyTypeId: number
): DefaultAction => {
  return async (dispatch, getState) => {
    let json: fetchAutomationRecipeResp;
    try {
      json = await FetchCyberRiskUrl(
        "survey/automation/revert/v1/",
        {
          qn_type_id: surveyTypeId,
        },
        { method: "POST" },
        dispatch,
        getState
      );
    } catch (e) {
      LogError(`Error reverting automation for survey type ${surveyTypeId}`, e);
      throw `Error reverting draft automation rules: ${e}`;
    }
    if (!json) {
      LogError(`Empty response reverting automation drafts`);
      throw `Error to reverting draft automation rules: empty response from server`;
    }
    dispatch(fetchAutomationForSurveyType(surveyTypeId, true, false));
  };
};

interface enableAutomationRecipeResp {
  status: string;
  uuid: string;
  enable: boolean;
}

export const enableDisableAutomationRecipe = (
  recipeUUID: string,
  surveyTypeId: number,
  enable: boolean
): DefaultAction => {
  return async (dispatch, getState) => {
    let json: enableAutomationRecipeResp;
    try {
      json = await FetchCyberRiskUrl(
        "survey/automation/enable/v1/",
        {
          recipe_uuid: recipeUUID,
          enable: enable,
        },
        { method: "POST" },
        dispatch,
        getState
      );
    } catch (e) {
      LogError(
        `Error ${enable ? "enabling" : "disabling"} automation instance`,
        e
      );
      const error: IError = {
        errorText: `Error ${
          enable ? "enabling" : "disabling"
        } automation instance`,
        actionText: "Try again",
        actionOnClick: () =>
          dispatch(
            enableDisableAutomationRecipe(recipeUUID, surveyTypeId, enable)
          ),
      };
      dispatch(setSurveyTypeAutomationList(surveyTypeId, false, error, null));
      throw error.errorText;
    }

    if (!json) {
      LogError(
        `Empty response ${
          enable ? "enabling" : "disabling"
        } automation instance`
      );
      const error: IError = {
        errorText: `Error ${
          enable ? "enabling" : "disabling"
        } automation instance: $empty response from server`,
        actionText: "Try again",
        actionOnClick: () =>
          dispatch(
            enableDisableAutomationRecipe(recipeUUID, surveyTypeId, enable)
          ),
      };
      dispatch(setSurveyTypeAutomationList(surveyTypeId, false, error, null));
      dispatch(setAutomationRecipe(recipeUUID, false, error, null));
      throw error.errorText;
    }
    dispatch(
      setSurveyTypeAutomationEnabledFlag(surveyTypeId, recipeUUID, enable)
    );
    dispatch(setAutomationRecipeEnabledFlag(recipeUUID, enable));
  };
};

export const setSortOrder = (
  uuid: string,
  sortOrder: number
): DefaultAction => {
  return async (dispatch, getState) => {
    try {
      await FetchCyberRiskUrl(
        "survey/automation/reorder/v1/",
        {
          recipe_uuid: uuid,
          sort_order: sortOrder,
        },
        { method: "PUT" },
        dispatch,
        getState
      );
    } catch (e) {
      LogError(`Error reordering automation rules`, e);
      const error: IError = {
        errorText: `Error reordering automation rules`,
        actionText: "Try again",
        actionOnClick: () => dispatch(setSortOrder(uuid, sortOrder)),
      };
      throw error.errorText;
    }
  };
};

export const saveAutomationRecipe = (
  recipe: IAutomationRecipe,
  quietly = false
): DefaultAction => {
  return async (dispatch, getState) => {
    if (!quietly) {
      dispatch(setAutomationRecipeState(recipe.uuid, true, null));
    }
    const rr = {
      ...recipe,
    };
    if (rr.customAttributeId == 0) {
      delete rr.customAttributeId;
    }
    const body = {
      recipe: rr,
    };
    let json: fetchAutomationRecipeResp;
    try {
      json = await FetchCyberRiskUrl(
        "survey/automation/v1/",
        {},
        {
          method: "PUT",
          body: JSON.stringify(body),
        },
        dispatch,
        getState
      );
    } catch (e) {
      const error: IError = {
        errorText: quietly ? `${e}` : `Error saving automation instance: ${e}`,
        actionText: "Try again",
        actionOnClick: () => dispatch(saveAutomationRecipe(recipe, quietly)),
      };
      if (!quietly) {
        dispatch(setAutomationRecipeState(recipe.uuid, false, error));
      }
      throw error.errorText;
    }

    if (!json) {
      const e = "empty response from server";
      LogError(`Empty response saving automation instance`);
      const error: IError = {
        errorText: quietly ? `${e}` : `Error saving automation instance: ${e}`,
        actionText: "Try again",
        actionOnClick: () => dispatch(saveAutomationRecipe(recipe, quietly)),
      };
      if (!quietly) {
        dispatch(setAutomationRecipeState(recipe.uuid, false, error));
      }
      throw error.errorText;
    }
    if (!quietly) {
      dispatch(setAutomationRecipe(recipe.uuid, false, null, json.recipe));
    }
  };
};

export const autosaveAutomationRecipe = (
  recipe: IAutomationRecipe
): DefaultAction => {
  return async (dispatch, getState) => {
    if (!recipe.uuid) {
      console.error("Attempt to autosave empty recipe.");
      return;
    }
    dispatch(setAutosaveStatus(recipe.uuid, true, null));
    const rr = {
      ...recipe,
    };
    if (rr.customAttributeId == 0) {
      delete rr.customAttributeId;
    }
    const body = {
      recipe: rr,
    };
    try {
      await FetchCyberRiskUrl(
        "survey/automation/edits/v1/",
        {},
        {
          method: "PUT",
          body: JSON.stringify(body),
        },
        dispatch,
        getState
      );
    } catch (e) {
      const error: IError = {
        errorText: `Error saving automation instance: ${e}`,
        actionText: "Try again",
        actionOnClick: () => dispatch(autosaveAutomationRecipe(recipe)),
      };
      dispatch(setAutosaveStatus(recipe.uuid, false, e));
      throw error.errorText;
    }
    dispatch(setEditsExistOnAutomation(recipe.uuid, recipe.surveyTypeId, true));
    dispatch(setAutosaveStatus(recipe.uuid, false, null));
  };
};

export const resetDraftEditsForRecipe = (recipeUUID: string): DefaultAction => {
  return async (dispatch, getState) => {
    dispatch(setAutosaveStatus(recipeUUID, true, null));
    try {
      await FetchCyberRiskUrl(
        "survey/automation/edits/v1/",
        { recipe_uuid: recipeUUID },
        {
          method: "DELETE",
        },
        dispatch,
        getState
      );
    } catch (e) {
      const error: IError = {
        errorText: `Error resetting edits for automation: ${e}`,
        actionText: "Try again",
        actionOnClick: () => dispatch(resetDraftEditsForRecipe(recipeUUID)),
      };
      dispatch(setAutosaveStatus(recipeUUID, false, e));
      throw error.errorText;
    }
    dispatch(setAutosaveStatus(recipeUUID, false, null));
  };
};

export const cancelAutomationRecipe = (
  recipeUUID: string,
  surveyTypeId: number
): DefaultAction => {
  return async (dispatch, getState) => {
    dispatch(setAutosaveStatus(recipeUUID, true, null));
    try {
      await FetchCyberRiskUrl(
        "survey/automation/cancel/v1/",
        { recipe_uuid: recipeUUID },
        { method: "DELETE" },
        dispatch,
        getState
      );
    } catch (e) {
      const error: IError = {
        errorText: `Error cancelling automation instance: ${e}`,
        actionText: "Try again",
        actionOnClick: () =>
          dispatch(cancelAutomationRecipe(recipeUUID, surveyTypeId)),
      };
      dispatch(setAutosaveStatus(recipeUUID, false, e));
      throw error.errorText;
    }
    await dispatch(fetchAutomationForSurveyType(surveyTypeId, true, true));
    dispatch(setAutosaveStatus(recipeUUID, false, null));
  };
};

interface enableAutomationRecipeResp {
  status: string;
  uuid: string;
  enabled: boolean;
  recipe: IAutomationRecipe;
}

interface syntaxCheckResp {
  status: string;
  error?: string;
}

export const checkAutomationSyntax = (
  recipeUUID: string,
  expression: string,
  weights?: IAutomationWeights
): DefaultAction => {
  return async (dispatch, getState) => {
    let json: syntaxCheckResp = {} as syntaxCheckResp;
    try {
      json = await FetchCyberRiskUrl(
        "survey/automation/syntax/v1/",
        { expression: expression },
        {
          method: "POST",
          body: JSON.stringify({
            weights: weights || ({} as IAutomationWeights),
          }),
        },
        dispatch,
        getState
      );
    } catch (e) {
      dispatch(setSyntaxStatus(recipeUUID, null));
    }
    dispatch(setSyntaxStatus(recipeUUID, json.error));
  };
};

interface deleteAutomationRecipeResp {
  status: string;
}

export const deleteAutomationRecipe = (
  surveyTypeId: number,
  recipeUUID: string
): DefaultAction => {
  return async (dispatch, getState) => {
    let json: deleteAutomationRecipeResp;
    try {
      json = await FetchCyberRiskUrl(
        "survey/automation/v1/",
        {
          recipe_uuid: recipeUUID,
        },
        { method: "DELETE" },
        dispatch,
        getState
      );
    } catch (e) {
      LogError(`Error deleting automation instance`, e);
      const error: IError = {
        errorText: `Error deleting automation instance: ${e}`,
        actionText: "Try again",
        actionOnClick: () =>
          dispatch(deleteAutomationRecipe(surveyTypeId, recipeUUID)),
      };
      throw error.errorText;
    }

    if (!json) {
      LogError(`Empty response deleting automation instance`);
      const error: IError = {
        errorText: `Error deleting automation instance: empty response from server`,
        actionText: "Try again",
        actionOnClick: () =>
          dispatch(deleteAutomationRecipe(surveyTypeId, recipeUUID)),
      };
      throw error.errorText;
    }
    dispatch(removeRecipeFromAutomationList(surveyTypeId, recipeUUID));
    dispatch(setAutomationRecipe(recipeUUID, false, null, null, true));
  };
};

interface fetchQuestionsForSurveyTypeResp {
  status: string;
  surveyTypeId: number;
  questions: IAutomationQuestion[];
}

export const fetchQuestionsForSurveyType = (
  surveyTypeId: number,
  force = false
): DefaultAction => {
  return async (dispatch, getState) => {
    if (!surveyTypeId) {
      console.error("Invalid surveyTypeId: ${surveyTypeId}");
      return;
    }
    if (!force) {
      const existing = _get(
        getState().cyberRisk,
        `automationQuestions[${surveyTypeId}]`
      );
      if (existing && !existing.error && !existing.loading) {
        return existing.data;
      }
    }

    dispatch(
      setAutomationQuestionsForSurveyType(surveyTypeId, null, true, null)
    );
    let json: fetchQuestionsForSurveyTypeResp;
    try {
      json = await FetchCyberRiskUrl(
        "survey/automation/questions/v1/",
        {
          qn_type_id: surveyTypeId,
        },
        null,
        dispatch,
        getState
      );
    } catch (e) {
      LogError(`Error fetching question list for questionnaire type`, e);
      const error: IError = {
        errorText: `Error fetching question list for questionnaire: ${e}`,
        actionText: "Try again",
        actionOnClick: () =>
          dispatch(fetchQuestionsForSurveyType(surveyTypeId, true)),
      };
      dispatch(
        setAutomationQuestionsForSurveyType(surveyTypeId, null, false, error)
      );
      return;
    }

    if (!json) {
      LogError(`Empty response fetching question list for questionnaire type`);
      const error: IError = {
        errorText: `Error fetching question list: empty response from server`,
        actionText: "Try again",
        actionOnClick: () =>
          dispatch(fetchQuestionsForSurveyType(surveyTypeId, true)),
      };
      dispatch(
        setAutomationQuestionsForSurveyType(surveyTypeId, null, false, error)
      );
      return;
    }
    dispatch(
      setAutomationQuestionsForSurveyType(
        surveyTypeId,
        json.questions,
        false,
        null
      )
    );
    return json.questions;
  };
};

export const uploadAutomationWeightsExcelFile =
  (recipeUUID: string, file: File): DefaultAction<IAutomationWeights> =>
  async (dispatch, getState) => {
    let json: { weights: IAutomationWeights };

    try {
      const data = new FormData();
      data.append("file", file);

      json = await FetchCyberRiskUrl(
        "survey/automation/weights_xlsx/v1",
        {
          recipe_uuid: recipeUUID,
        },
        {
          body: data,
          method: "POST",
        },
        dispatch,
        getState
      );
    } catch (e) {
      const error = e as ResponseError;
      if (
        !error.message.includes("[INCCORECT WORKBOOK]") ||
        !error.message.includes("[WORKBOOK MANAGLED]")
      ) {
        LogError("error with file upload", e);
      }
      throw e;
    }
    return json.weights;
  };
