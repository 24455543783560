import { getDefaultFilters } from "./defaults";
import { ICyberRiskState } from "../../_common/types/redux";
import { SortDirection } from "../../_common/components/core/XTable";
import { ReportType } from "../../_common/types/exportReport";

export const cyberRiskInitialState: ICyberRiskState = {
  customerData: {
    summary: { loading: true },
    surveys: {
      security: undefined,
      relationship: undefined,
    },
    riskHostnames: {},
    vendors: {
      loading: true,
      paging: {
        pageNum: 1,
        pageSize: 30,
        sortBy: "",
        sortDir: SortDirection.ASC,
      },
    },
    searchVendors: {
      loading: false,
    },
    lookups: {
      loading: true,
      paging: {
        pageNum: 1,
        pageSize: 30,
        sortBy: "days_left",
        sortDir: "desc",
      },
    },
    filters: getDefaultFilters(),
    availableFilterOptions: {
      vulns: {
        loading: true,
        vulnsCveNames: [],
        vulnsSoftware: [],
      },
      portfolioVulns: {
        loading: true,
        vulnsCveNames: [],
        risksByCVE: {},
      },
    },
    exposures: {
      accounts_loading: false,
      breaches_loading: false,
      account_data: [],
      breach_data: [],
      vip_account_data: [],
      monitoredDomains: [],
      prepagedAccountData: {},
      prepagedPageNumber: 0,
      prepagedSortedBy: "num_breaches",
      prepagedSortedDesc: true,
      breachAccountsSortedBy: "highest_breach_risk",
      breachAccountsSortedDesc: true,
      notificationStatuses: {},
      breachHistory: {},
    },
    vulns: { loading: true },
    ipAddresses: undefined,
    ipAddressDetails: {},
    acceptedRisks: undefined,
    concentrationRisk: undefined,
    concentrationRiskLoading: false,
    sharedAssessment: undefined,
    subsidiaries: undefined,
    riskProfileIncludingSubsidiaries: undefined,
    remediationRequestIdsLoading: true,
    selfRemediationRequestIdsLoading: true,
    subsidiaryRemediationRequestIdsLoading: true,
  },
  allVendors: {
    loading: false,
    error: undefined,
    data: undefined,
  },
  allVendorRisks: {
    loading: false,
    error: undefined,
    data: undefined,
  },
  vendors: {},
  subsidiaries: {},
  vendorBreakdown: null,
  vendorSearch: {
    query: "",
    loading: false,
    results: {},
  },
  vendorPortfolioRiskProfile: {
    loading: false,
    error: undefined,
    data: undefined,
  },
  vendorPortfolioKEVCounts: {
    loading: false,
    error: undefined,
    counts: undefined,
  },
  riskVendors: {},
  riskVendorWebsites: {},
  domains: {},
  webscans: {},
  vulns: {},
  vulnsByCPE: {},
  vulnRiskIDsByCVE: {},
  customerVulnsByCPEByHostnames: {},
  changes: { active: false },
  scoring: {},
  tour: {
    tourSteps: [],
    enabled: false,
  },
  tableSorts: {
    vendorList: {
      selectedHeader: "Vendor",
      sortOrder: "asc",
    },
    vendorListSummary: {
      selectedHeader: "Cyber Trend",
      sortOrder: "asc",
    },
  },
  tablePages: {},
  plans: {},
  orgActions: {},
  orgUsers: { loading: true },
  orgUserEmailAddresses: { loading: false },
  orgUserInvites: { loading: true },
  orgApi: { loading: true },
  orgNotificationSettings: undefined,
  orgFlags: undefined,
  orgDefaultTexts: undefined,
  vendorPortfolios: undefined,
  domainPortfolios: undefined,
  userNotificationSettings: null,
  featureFlags: [],
  availableLabels: [],
  reportConfig: undefined,
  reports: {
    [ReportType.ReportTypeBreachSightGeolocation]: undefined,
    [ReportType.ReportTypeBreachSightSubsidiariesGeolocation]: undefined,
    [ReportType.ReportTypeVendorRiskGeolocation]: undefined,
    [ReportType.ReportTypeBreachSightCompetitorAnalysis]: undefined,
    [ReportType.ReportTypeVendorRiskRiskMatrix]: undefined,
  },
  dataLeaks: {
    summaries: {
      keyword: {
        filtered: {},
        unfiltered: {},
      },
      service: {
        filtered: {},
        unfiltered: {},
      },
    },
  },
  dataLeakStatistics: {},
  dataLeakScanResults: {
    lookupData: {
      services: {},
      keywords: [],
    },
  },
  vendorDataLeaks: undefined,
  exportSections: {},
  auditTrailFilters: {
    startDate: null,
    endDate: null,
    eventTypes: null,
    userIds: null,
    apiKeyIds: null,
    searchText: "",
    offset: 0,
  },
  managedVendorRequestSearch: {
    query: "",
    loading: false,
    results: [],
    totalMatches: 0,
  },
  managedVendorData: {},
  vendorAssetSharingSettings: undefined,
  vendorGeolocationByCountry: {},
  vendorTiers: undefined,
  vendorClassifications: undefined,
  questionnaireAnswers: {},
  vendorImport: undefined,
  oauth2Connections: {},
  whoisData: {},
  reverseWhoisResults: {},
  oauthConnections: {},
  orgManagedAssessments: {
    assessmentList: {
      loading: true,
      managedAssessments: [],
      paging: {
        pageNum: 1,
        pageSize: 20,
        totalPages: 1,
        sortBy: "requestedDate",
        sortDir: SortDirection.ASC,
      },
    },
    assessmentDetails: {},
    licenses: {
      loading: true,
      licenses: [],
    },
  },
  vendorsFreeTrial: {},
  questionnaireAutomation: {},
  automationQuestions: {},
  automationRecipes: {},
};
