import * as React from "react";
import ColorCheckbox from "./ColorCheckbox";

import "../style/components/NestedCheckbox.scss";

export interface ChildCheckboxPropType {
  id: string;
  title: string;
  description?: string;
  value: boolean;
  disabled?: boolean;
}

export interface CheckboxPropType {
  id: string;
  title: string;
  description?: string;
  value: boolean;
  disabled: boolean;
  children?: ChildCheckboxPropType[];
}

export interface NestedCheckboxProps {
  checkbox: CheckboxPropType;
  onClick: (id: string, subSectionId?: string) => void;
}

/**
 * Displays a checkbox with optional nested children
 */
export const NestedCheckbox: React.VFC<NestedCheckboxProps> = ({
  checkbox,
  onClick,
}) => (
  <div className="checkbox-group">
    <ColorCheckbox
      className={checkbox.description ? "checkbox-with-desc" : ""}
      label={
        checkbox.description ? (
          <div className="title-desc">
            <div className="title">{checkbox.title}</div>
            <div className="desc">{checkbox.description}</div>
          </div>
        ) : (
          checkbox.title
        )
      }
      checked={checkbox.value}
      disabled={checkbox.disabled}
      onClick={() => onClick(checkbox.id)}
    />
    {checkbox.children && (
      <div className="checkbox-group-children">
        {checkbox.children.map((subSection) => (
          <ColorCheckbox
            key={subSection.id}
            className={subSection.description ? "checkbox-with-desc" : ""}
            label={
              subSection.description ? (
                <div className="title-desc">
                  <div className="title">{subSection.title}</div>
                  <div className="desc">{subSection.description}</div>
                </div>
              ) : (
                subSection.title
              )
            }
            checked={subSection.value}
            disabled={subSection.disabled}
            onClick={() => onClick(checkbox.id, subSection.id)}
          />
        ))}
      </div>
    )}
  </div>
);
