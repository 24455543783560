import { RiskDetail } from "./vendor";
import { RiskClassification } from "./risk/classification";
import { Severity, SeverityInt } from "./severity";
import { ILabel } from "./label";
import { SurveyRiskVisibility } from "./survey";
import { SaaSUser } from "../../userbase/api/types";
import { SecurityProfileDocumentID } from "../../vendorrisk/types/securityProfile";

export enum VendorSummaryRiskType {
  Cloudscan = "cloudscan",
  Survey = "survey",
  Evidence = "evidence",
  SaaS = "saas",
  Other = "other",
  AppguardPackageVuln = "appguard_package_vuln",
  AppguardRepoConfig = "appguard_repo_config",
  SecurityProfile = "security_profile",
}

export interface VendorSummaryRiskVendor {
  id: number;
  name: string;
  primary_hostname: string;
  cstar_score: number;
  num_failed: number;
  date_detected?: string;
  labels?: ILabel[];
  ip_countries?: string[];
  is_waived?: boolean;
  partially_waived?: boolean;
  is_watched?: boolean;
}

export interface CheckSummaryCloudscan {
  cloudscanId: number;
  hostname: string;
  expected: string;
  actual: string;
}

export interface VendorSummaryRiskSurvey {
  surveyId: number;
  publicSurvey: boolean;
  surveyName: string;
  surveyType: string;
  surveyLastSent?: string;
  surveyLastSentBy?: string;
  inRemediation: boolean;
  why: string;
  explanation: string;
  author?: string;
  avatar?: string;
  sharedAt?: string;
  answerId: number;
  remediationRequestId?: number;
  active?: boolean;
  isWaived: boolean;
  categoryTitle?: string;
}

export interface AdditionalEvidenceRiskEvidence {
  id: number;
  name: string;
  commentary: string;
  documentId: number;
  filename: string;
  virusScanned: boolean;
  virusSafe: boolean;
  vendorId: number;
  updatedAt: string;
}

export enum DiffStatus {
  Pass = "PASS",
  Fail = "FAIL",
  Unknown = "UNKNOWN",
  Waived = "WAIVED",
}

export interface CloudscanCheckResultDiff {
  hostname: string;
  property: string;
  expected: string;
  dateA: string;
  dateB: string;
  statusA: DiffStatus;
  statusB: DiffStatus;
  metaValueA: string;
  metaValueB: string;
  prevProvisionalID?: string;
}

export interface VendorCheckResultDiff {
  expected: string;
  statusA: DiffStatus;
  statusB: DiffStatus;
  metaValueA: string;
  metaValueB: string;
  prevProvisionalID?: string;
}

export interface VendorSummaryRisk {
  id: string;
  title: string;
  riskType: VendorSummaryRiskType;
  description: string;
  factCategory: string;
  prevFactCategory?: string;
  categoryTitle: string;
  classification?: RiskClassification;
  severity: SeverityInt;
  passed: boolean;
  dateDetected?: string;

  numFailedCloudscans: number;
  failedCloudscans?: CheckSummaryCloudscan[];
  passedCloudscans?: CheckSummaryCloudscan[];
  failedVendors?: VendorSummaryRiskVendor[];
  surveys?: VendorSummaryRiskSurvey[];
  additionalEvidences?: AdditionalEvidenceRiskEvidence[];
  cloudscanDiffs?: CloudscanCheckResultDiff[];
  vendorDiff?: VendorCheckResultDiff;
  saasUsers?: SaaSUser[];
  saasAppName?: string;
  manifests?: RepoManifestRisk[];
  repositories?: RepositoryRisk[];
  securityProfiles?: SecurityProfileRisk[];

  controls?: Record<string, string[] | undefined>;

  numFailedVendors: number;
  vendorMeta?: string;
  isWaived?: boolean;
  isPartiallyWaived?: boolean;
  baseSeverity?: SeverityInt;
  baseId?: string;

  knownExploitedVulnCount?: number;
  isVerifiedVuln?: boolean;

  summary?: string;
  riskDetails?: string;
  recommendedRemediation?: string;
}

export interface SurveyAnswerRisk {
  active: boolean;
  category: string;
  categoryTitle: string;
  controls?: any[];
  createdAt: string;
  explanation: string;
  inRemediation: boolean;
  remediationRequestId?: number;
  name: string;
  publicSurvey: boolean;
  riskId: string;
  severity: Severity;
  surveyId: number;
  surveyLastSent: string;
  surveyName: string;
  text: string;
  why: string;
  author: string;
  avatar: string;
  surveyLastSentBy?: string;
  isCustom: boolean;
  riskVisibility: SurveyRiskVisibility;
}

export interface VendorSummaryResult {
  risks: RiskDetail;
  // TODO sort this out
}

export interface VendorSummary {
  error: any;
  loading: boolean;
  result: VendorSummaryResult;
}

export interface IHostnameRisk {
  hostname: string;
  riskId: string;
  pass: boolean;
  scannedAt: string;
  dateDetected: string;
  score: number;
}

export interface RepoManifestRisk {
  repoUUID: string;
  repoName: string;
  manifestUUID: string;
  manifestLocation: string;
  packageVersionUniqueNames: string[];
  createdAt: string;
}

export interface RepositoryRisk {
  repoName: string;
  uuid: string;
  firstDetected: string;
}

export interface SecurityProfileRisk {
  source: SecurityProfileDocumentID;
  compensatingControl: string;
  citations: string[];
}
