import { FC } from "react";
import "../style/components/CyberTrendScore.scss";

// SVG icons for the triangles that point up, down, or neutral, used by the CyberTrendScore component.
const trendScoreIcons = {
  positive: (
    <svg
      className="trend-score-icon green"
      viewBox="0 0 30 16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <polygon points="16 0, 0 16, 30 16" />
    </svg>
  ),
  negative: (
    <svg
      className="trend-score-icon red"
      viewBox="0 0 30 16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <polygon points="0 0, 30 0, 16 16" />
    </svg>
  ),
  zero: (
    <svg
      className="trend-score-icon grey"
      viewBox="0 0 30 16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <polygon points="13 0, 0 8, 13 16" />
      <polygon points="17 0, 30 8, 17 16" />
    </svg>
  ),
};

const getTrendScoreIcon = (trend: number) => {
  if (trend > 0) {
    return trendScoreIcons.positive;
  } else if (trend < 0) {
    return trendScoreIcons.negative;
  }
  return trendScoreIcons.zero;
};

const roundTrendScore = (trend: number) => {
  let str = "";
  if (trend >= 0) {
    str += "+";
  }

  str += Math.round(trend * 10) / 10;
  return str;
};

interface ICyberTrendScoreProps {
  trend: number;
}

/**
 * <CyberTrendScore />
 * Renders a cyber trend score with the correct up/own arrow next to it
 * @param trend {number} - the score, positive or negative
 */
const CyberTrendScore: FC<ICyberTrendScoreProps> = ({ trend }) => (
  <div className={"trend-score"}>
    {getTrendScoreIcon(trend)}
    {roundTrendScore(trend)}
  </div>
);

export default CyberTrendScore;
