import { ReactNode } from "react";
import "../style/components/ButtonGroup.scss";

export interface IButtonGroupProps {
  className?: string;
  children: ReactNode;
}

const ButtonGroup = (props: IButtonGroupProps) => {
  return (
    <div className={`button-group ${props.className || ""}`}>
      {props.children}
    </div>
  );
};

export default ButtonGroup;
