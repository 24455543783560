import { FC } from "react";
import XTable, {
  IXTableRow,
  SortDirection,
  XTableCell,
} from "../../_common/components/core/XTable";
import { AppUser, Scope } from "../api/types";
import ScopeRiskLevelDisplay from "./ScopeRiskLevelDisplay";
import { useSorting } from "../../_common/hooks";
import UserBaseAPI from "../api/userbase.api";
import "./UserPermissionsForApp.scss";
import ReportCard from "../../_common/components/ReportCard";
import ScrollableDiv from "../../vendorrisk/components/ScrollableDiv";
import { CheckItemSlidePanelSection } from "../../vendorrisk/components/filter/SlidePanelSection";
import { pluralise } from "../../_common/helpers";
import SlidePanelKVTable from "./SlidePanelKVTable";
import PillLabel from "../../vendorrisk/components/PillLabel";
import ColorGrade, {
  ColorGradeSize,
} from "../../vendorrisk/components/executive_summary/ColorGrade";
import Score from "../../vendorrisk/components/Score";
import DateTimeFormat from "../../_common/components/core/DateTimeFormat";
import UserApprovalPill from "./UserApprovalPill";
import Button from "../../_common/components/core/Button";
import { userSummaryUrl } from "../UserBaseAppRouter";
import { useHistory } from "react-router-dom";

interface UserPermissionsForAppProps {
  appName: string;
  user: AppUser;
}

const UserPermissionsForApp: FC<UserPermissionsForAppProps> = ({
  appName,
  user,
}) => {
  const history = useHistory();

  const { data, isLoading } = UserBaseAPI.useGetUserBaseAppUserScopesV1Query({
    app: appName,
    userUUID: user.uuid,
  });

  const [sortedScopes, sortedBy, onSortChange] = useSorting<
    Scope,
    "exposure_level" | "permission"
  >(data?.scopes ?? [], "exposure_level", SortDirection.DESC, {
    exposure_level: {
      orderFuncs: [(s: Scope) => s.riskLevel, (s: Scope) => s.description],
      sortDirsAsc: [SortDirection.ASC, SortDirection.ASC],
      sortDirsDesc: [SortDirection.DESC, SortDirection.ASC],
    },
    permission: {
      orderFuncs: [(s: Scope) => s.description, (s: Scope) => s.riskLevel],
      sortDirsAsc: [SortDirection.ASC, SortDirection.ASC],
      sortDirsDesc: [SortDirection.DESC, SortDirection.ASC],
    },
  });

  const scopeIntoXTableRow = (s: Scope): IXTableRow<string> => {
    return {
      id: s.externalID,
      className: "permission-row",
      cells: [
        <XTableCell className="description-with-subtext" key="permission">
          <div className="description-main-text">{s.description}</div>
          <div className="description-subtext">{s.name}</div>
        </XTableCell>,
        <XTableCell className="risk-level" key="exposure_level">
          <ScopeRiskLevelDisplay riskLevel={s.riskLevel} />
        </XTableCell>,
      ],
    };
  };

  const permissionsTitle = isLoading
    ? "Permissions"
    : `${pluralise(sortedScopes.length, "Permission", "Permissions")} (${
        sortedScopes.length
      })`;

  return (
    <ReportCard newStyles className="userbase-user-app-permissions-panel">
      <div className="title-and-buttons">
        <div className="saas-user-title">{user.name}</div>
        <div className="saas-user-title-buttons">
          <Button
            arrow
            onClick={() => history.push(userSummaryUrl(user?.uuid ?? ""))}
          >
            View user profile
          </Button>
        </div>
      </div>
      <div className="content">
        <ScrollableDiv newStyles>
          <CheckItemSlidePanelSection
            title={permissionsTitle}
            startExpanded={true}
          >
            <XTable
              className="permission-list"
              sortedBy={sortedBy}
              onSortChange={onSortChange}
              loading={isLoading}
              columnHeaders={[
                { id: "permission", text: "Description", sortable: true },
                {
                  id: "exposure_level",
                  text: "Exposure level",
                  sortable: true,
                },
              ]}
              rows={sortedScopes.map(scopeIntoXTableRow)}
            />
          </CheckItemSlidePanelSection>
          <CheckItemSlidePanelSection
            title={"User overview"}
            startExpanded={true}
          >
            <SlidePanelKVTable
              rows={[
                { key: "Role", value: user.roles?.at(0) ?? "-" },
                {
                  key: "Team",
                  value: <PillLabel>{user?.teams?.at(0) ?? "-"}</PillLabel>,
                },
                {
                  key: "User Rating",
                  value: (
                    <div className="score-container">
                      <ColorGrade
                        score={user?.rating}
                        size={ColorGradeSize.Small}
                      />
                      <Score score={user.rating} small />
                    </div>
                  ),
                },
                {
                  key: "Exposure Level",
                  value: (
                    <ScopeRiskLevelDisplay riskLevel={user.highestRiskLevel} />
                  ),
                },
                {
                  key: "First detected",
                  value: (
                    <DateTimeFormat dateTime={user.firstDetected} dateOnly />
                  ),
                },
                {
                  key: "User status",
                  value: (
                    <UserApprovalPill
                      approved={user.approved}
                      waived={user.waived}
                    />
                  ),
                },
              ]}
            />
          </CheckItemSlidePanelSection>
        </ScrollableDiv>
      </div>
    </ReportCard>
  );
};

export default UserPermissionsForApp;
