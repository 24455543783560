import { UserEmailAddress } from "../../_common/types/user";
import { DefaultAction, DefaultThunkDispatch } from "../../_common/types/redux";
import { DefaultRootState } from "react-redux";
import { FetchCyberRiskUrl } from "../../_common/api";
import {
  INotificationConfigCategory,
  NotificationConditionalLogic,
  NotificationConditionMode,
  NotificationParameters,
  NotificationType,
} from "../../_common/types/notifications";
import { LogError } from "../../_common/helpers";
import { addDefaultUnknownErrorAlert } from "../../_common/reducers/messageAlerts.actions";
import {
  getOrgUsers,
  setAllOrgNotifications,
  setOrgNotificationSettings,
  setOrgUserLimits,
} from "./cyberRiskActions";
import { IOrganisationUserCountAndLimits } from "../../_common/types/organisations";
import { LabelClassification } from "../../_common/types/label";
import UsersAPI, { UsersTagTypes } from "../../_common/api/usersAPI";

export const SET_ORG_USER_EMAIL_ADDRESSES = "SET_ORG_USER_EMAIL_ADDRESSES";
export const setOrgUserEmailAddresses = (orgUserEmails: {
  data: UserEmailAddress[] | null;
  loading: boolean;
}) => ({
  type: SET_ORG_USER_EMAIL_ADDRESSES,
  orgUserEmails,
});

interface fetchOrgUserEmailAddressesResponse {
  status: string;
  userEmails: UserEmailAddress[];
}

export const fetchOrgUserEmailAddresses =
  (force = false) =>
  async (
    dispatch: DefaultThunkDispatch,
    getState: () => DefaultRootState
  ): Promise<void> => {
    if (
      !force &&
      getState().cyberRisk.orgUserEmailAddresses.data &&
      !getState().cyberRisk.orgUserEmailAddresses.loading
    ) {
      return; // Return cached
    } else if (getState().cyberRisk.orgUserEmailAddresses.loading) {
      return; // Waiting for load
    }

    dispatch(setOrgUserEmailAddresses({ data: null, loading: true }));
    dispatch(
      UsersAPI.util.invalidateTags([UsersTagTypes.orgUserEmailAddresses])
    );

    let json: fetchOrgUserEmailAddressesResponse;
    try {
      json = await FetchCyberRiskUrl(
        "organisation/users/emails/v1",
        {},
        { method: "GET" },
        dispatch,
        getState
      );
    } catch (e) {
      console.error(e);
      throw e;
    }

    dispatch(
      setOrgUserEmailAddresses({ data: json.userEmails, loading: false })
    );
  };

export interface IOrgAlertDefinition {
  id: number;
  uuid: string;
  notificationType: NotificationType;
  parameters: NotificationParameters;
  conditionalLogic?: NotificationConditionalLogic;
  isZapier: boolean;
  hasIntegration: boolean;
  headline: string;
  headlineVarsReplaced: string;
  description: string;
  descriptionVarsReplaced: string;

  testJSON: Record<string, any>;
  defaultPayloadScript: string;
  exampleLiquidTextMessage: string;
  templateVariablesList: Record<string, any>;
}

export interface ICreateableDefinition {
  notificationType: NotificationType;
  defaultParameters: NotificationParameters;
  supportedConditionalModes?: NotificationConditionMode[];
  supportedLabelClassifications?: LabelClassification[];
  headline: string;
  description: string;
  category: string[2];
}

export interface orgNotificationSettings {
  orgDefinitions: INotificationConfigCategory<IOrgAlertDefinition>[];
  createableDefinitions: ICreateableDefinition[];
}

export const fetchOrgNotificationSettings = (
  force = false
): DefaultAction<orgNotificationSettings | undefined> => {
  return async (dispatch, getState) => {
    const { orgNotificationSettings } = getState().cyberRisk;
    if (!force && orgNotificationSettings) {
      return orgNotificationSettings;
    }

    let json: orgNotificationSettings;

    try {
      json = await FetchCyberRiskUrl<orgNotificationSettings>(
        "alerts/org_defs/v1/",
        {},
        null,
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error fetching org notifications data", e);
      dispatch(
        addDefaultUnknownErrorAlert(
          "Error fetching list of configured notifications"
        )
      );
      return undefined;
    }

    dispatch(setOrgNotificationSettings(json));

    return json;
  };
};

export interface allOrgNotifications {
  definitions: INotificationConfigCategory<IOrgAlertDefinition>[];
  definitionsByUUID: Record<string, IOrgAlertDefinition | undefined>;
}

export const fetchAllOrgNotifications = (
  forced = false
): DefaultAction<allOrgNotifications | undefined> => {
  return async (dispatch, getState) => {
    if (!forced && getState().cyberRisk.allOrgNotifications) {
      return getState().cyberRisk.allOrgNotifications;
    }

    let resp;
    try {
      resp = await FetchCyberRiskUrl<allOrgNotifications>(
        "alerts/org_defs_all/v1",
        null,
        null,
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error fetching all org notification", e);
      dispatch(
        addDefaultUnknownErrorAlert("Error fetching possible notifications")
      );
      return undefined;
    }

    dispatch(setAllOrgNotifications(resp));

    return resp;
  };
};

export interface AlertDefinitionCreateRequest {
  notificationType: NotificationType;
  parameters: NotificationParameters;
  conditions?: NotificationConditionalLogic;
}

export const createOrgNotifications = (
  notificationDefs: AlertDefinitionCreateRequest[]
): DefaultAction => {
  return async (dispatch, getState) => {
    // We simply post our notificationDefs array to the endpoint.
    try {
      await FetchCyberRiskUrl(
        "alerts/org_defs/v1/",
        null,
        {
          method: "POST",
          body: JSON.stringify(notificationDefs),
        },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error creating org notifications", e);
      throw e;
    }
  };
};

export const deleteOrgNotifications = (
  notificationIds: number[]
): DefaultAction => {
  return async (dispatch, getState) => {
    try {
      await FetchCyberRiskUrl(
        "alerts/org_defs/v1/",
        { notificationdef_ids: notificationIds },
        {
          method: "DELETE",
        },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error deleting org notifications", e);
      throw e;
    }
  };
};

export interface UpdateAlertDefinitionRequest {
  id: number;
  parameters: NotificationParameters;
  conditions?: NotificationConditionalLogic;
}

export const updateOrgNotifications = (
  updateRequests: UpdateAlertDefinitionRequest[]
): DefaultAction => {
  return async (dispatch, getState) => {
    try {
      await FetchCyberRiskUrl(
        "alerts/org_defs/v1/",
        null,
        {
          method: "PUT",
          body: JSON.stringify(updateRequests),
        },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error updating org notifications", e);
      throw e;
    }
  };
};

export const fetchOrgUserLimits =
  (): DefaultAction<IOrganisationUserCountAndLimits> =>
  async (dispatch, getstate) => {
    let json: IOrganisationUserCountAndLimits;
    try {
      json = await FetchCyberRiskUrl(
        "account/userlimits/v1",
        null,
        null,
        dispatch,
        getstate
      );
    } catch (e) {
      LogError("Error getting org user limits", e);
      throw e;
    }

    dispatch(setOrgUserLimits(json));

    return json;
  };

export const SET_ORG_MFA_STATUS = "SET_ORG_MFA_STATUS";

const setOrgMFAStatus = (enabled: boolean) => ({
  type: SET_ORG_MFA_STATUS,
  enabled,
});

export const fetchOrgMFAStatus =
  (force = false): DefaultAction<boolean> =>
  async (dispatch, getState) => {
    const status = getState().cyberRisk.orgMFAStatus;
    if (!force && status) {
      return status;
    }

    let json: { status: string; mfaEnabled: boolean };
    try {
      json = await FetchCyberRiskUrl(
        "organisation/mfa/v1",
        null,
        null,
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error getting org MFA status", e);
      dispatch(addDefaultUnknownErrorAlert("Error getting MFA status"));
      throw e;
    }

    dispatch(setOrgMFAStatus(json.mfaEnabled));

    return json.mfaEnabled;
  };

export const putOrgMFAStatus =
  (enabled: boolean): DefaultAction =>
  async (dispatch, getState) => {
    try {
      await FetchCyberRiskUrl(
        "organisation/mfa/v1",
        { enabled },
        { method: "PUT" },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error setting org MFA status", e);
      dispatch(addDefaultUnknownErrorAlert("Error setting MFA status"));
      throw e;
    }

    dispatch(setOrgMFAStatus(enabled));
    dispatch(getOrgUsers());
  };
