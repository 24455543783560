import { FC } from "react";
import "../../style/components/risk_profile/RiskName.scss";
import classnames from "classnames";
import { Severity } from "../../../_common/types/severity";
import { AdjustedSeverityIcon } from "../../../_common/components/SeverityIcon";

interface RiskNameProps {
  severity?: Severity;
  baseSeverity?: Severity;
  riskName: string;
  riskCategoryGroupName?: string;
  isWaived?: boolean;
}

const RiskName: FC<RiskNameProps> = ({
  severity,
  baseSeverity,
  riskName,
  riskCategoryGroupName,
  isWaived,
}) => {
  return (
    <div className={classnames("risk-name", { waived: isWaived })}>
      {severity !== undefined && (
        <AdjustedSeverityIcon severity={severity} baseSeverity={baseSeverity} />
      )}
      <div className={"name-container"}>
        <div className={"name"}>{riskName}</div>
        {riskCategoryGroupName && (
          <div className={"category-group-name"}>{riskCategoryGroupName}</div>
        )}
      </div>
    </div>
  );
};

export default RiskName;
