import { VendorSummaryRisk } from "../../types/vendorSummary";
import classnames from "classnames";
import ReportCard from "../ReportCard";
import { IVendorRiskWaiver, WaiverType } from "../../types/vendorRiskWaivers";
import React, { memo, useEffect, useState } from "react";
import TabButtons from "../TabButtons";
import "../../style/components/SurveyDetailsRisksCard.scss";
import SearchBox from "../SearchBox";
import XTable, {
  IIconOption,
  IXTableColumnHeader,
  SortDirection,
  XTableCell,
} from "../core/XTable";
import { usePagination, useSorting } from "../../hooks";
import { SeverityAsString, SeverityInt } from "../../types/severity";
import { LabelColor } from "../../types/label";
import { AdjustedSeverityIcon } from "../SeverityIcon";
import PillLabel from "../../../vendorrisk/components/PillLabel";
import { History, Location, LocationDescriptor } from "history";
import EmptyCardWithAction from "../EmptyCardWithAction";
import SearchEmptyCard from "../SearchEmptyCard";
import { remediationLocationState } from "../../../vendorrisk/views/RequestRemediationV2";
import { vendorUrlPrefix } from "../../helpers";
import ColorCheckbox from "../../../vendorrisk/components/ColorCheckbox";
import { IWithPermissionsProps, withPermissions } from "../../permissions";
import CompensatingControlInfo from "../../../vendorrisk/components/questionnaires/CompensatingControlInfo";
import ManageRiskButton from "../../../vendorrisk/components/risk_profile/ManageRiskButton";
import { HoverLocation } from "../IconButton";
import { Link } from "react-router-dom";
import InfoBanner, {
  BannerType,
} from "../../../vendorrisk/components/InfoBanner";
import { SurveyRiskVisibility, SurveyStatus } from "../../types/survey";
import { surveyStatusIn } from "./SurveyDetailsStatusDropdown";

// Flattened version of VendorSummaryRisk for a single survey
interface surveyAnswerRiskWithWaiver extends VendorSummaryRisk {
  riskWaiverId?: number;
  adjustmentId?: number;
  active?: boolean;
  inRemediation?: boolean;
  remediationRequestId?: number;
  explanation: string;
  why: string;
}

export const getRisksList = (
  inRisks: VendorSummaryRisk[],
  riskWaiversAndAdjustments: IVendorRiskWaiver[] | undefined,
  includePassedChecks: boolean
): surveyAnswerRiskWithWaiver[] => {
  const risks: surveyAnswerRiskWithWaiver[] = [];
  const riskIDsToWaiverIDs = (
    riskWaiversAndAdjustments?.filter(
      (w) => w.waiverType === WaiverType.RiskWaiver
    ) || []
  ).reduce((prev: Record<string, number | undefined>, next) => {
    prev[next.riskID] = next.id;
    return prev;
  }, {});

  const riskIDsToAdjustmentIDs = (
    riskWaiversAndAdjustments?.filter(
      (w) => w.waiverType === WaiverType.SeverityAdjustment
    ) || []
  ).reduce((prev: Record<string, number | undefined>, next) => {
    prev[next.riskID] = next.id;
    return prev;
  }, {});

  for (let j = 0; j < inRisks.length; j++) {
    const risk = inRisks[j];

    const surveyRisk = risk.surveys?.length === 1 ? risk.surveys[0] : undefined;
    if (!surveyRisk) {
      // TODO throw error if not single instance?
      continue;
    }

    if (!surveyRisk.active) {
      if (includePassedChecks && risk.title != "") {
        risks.push({
          ...risk,
          severity: SeverityInt.PassSeverity,
          inRemediation: surveyRisk.inRemediation,
          remediationRequestId: surveyRisk.remediationRequestId,
          active: surveyRisk.active,
          explanation: surveyRisk.explanation,
          why: surveyRisk.why,
        });
      }
    } else {
      const riskWaiverId = riskIDsToWaiverIDs[risk.baseId ?? risk.id];
      const adjustmentId = risk.baseId
        ? riskIDsToAdjustmentIDs[risk.baseId]
        : undefined;
      risks.push({
        ...risk,
        riskWaiverId: riskWaiverId,
        adjustmentId: adjustmentId,
        inRemediation: surveyRisk.inRemediation,
        remediationRequestId: surveyRisk.remediationRequestId,
        active: surveyRisk.active,
        explanation: surveyRisk.explanation,
        why: surveyRisk.why,
      });
    }
  }

  return risks;
};

const countPassingChecks = (risks: surveyAnswerRiskWithWaiver[]): number => {
  let count = 0;
  for (let j = 0; j < risks.length; j++) {
    const risk = risks[j];
    if (!risk.active && risk.title != "") {
      count = count + 1;
    }
  }

  return count;
};

type tabId = "all" | "notinremediation" | "inremediation" | "waived";

interface ISurveyDetailsRisksCardProps extends IWithPermissionsProps {
  history: History;
  location: Location;
  surveyId: number;
  vendorId: number;
  surveyRisks: VendorSummaryRisk[];
  surveyRisksAreFromPreSendAutofill?: boolean;
  riskWaivers?: IVendorRiskWaiver[];
  userHasWaivePermission: boolean;
  userHasRemediationPermission: boolean;
  isManagementAnalystSession: boolean;
  managedOrgId: number;
  getSurveyPath?: (
    editMode: boolean,
    surveyId?: number,
    questionId?: string
  ) => LocationDescriptor;
  title?: string;
  publicSurvey?: boolean;
  archivedSurvey?: boolean;
  publicSurveyNotIncludedInProfile?: boolean;
  riskVisibility?: SurveyRiskVisibility;
  isVendorPortal?: boolean;
  isAnalystWorkflowSurvey?: boolean;
  surveyStatus: SurveyStatus;
}

const SurveyDetailsRisksCard = ({
  history,
  location,
  surveyId,
  vendorId,
  surveyRisks,
  surveyRisksAreFromPreSendAutofill,
  riskWaivers,
  userHasWaivePermission,
  userHasRemediationPermission,
  isManagementAnalystSession,
  managedOrgId,
  getSurveyPath,
  title = "Risks identified",
  publicSurvey = false,
  archivedSurvey = false,
  publicSurveyNotIncludedInProfile,
  riskVisibility = SurveyRiskVisibility.Visible,
  isVendorPortal,
  isAnalystWorkflowSurvey,
  surveyStatus,
}: ISurveyDetailsRisksCardProps) => {
  const [includePassedRisks, setIncludePassedRisks] = useState(false);

  // Maintain our list of risks in state, and update it whenever the props change
  const [risks, setRisks] = useState(() =>
    getRisksList(surveyRisks, riskWaivers, includePassedRisks)
  );
  useEffect(
    () => setRisks(getRisksList(surveyRisks, riskWaivers, includePassedRisks)),
    [surveyRisks, riskWaivers, includePassedRisks]
  );

  const [currentTab, setCurrentTab] = useState<tabId>("all");
  const [searchText, setSearchText] = useState("");
  const trimmedSearchText = searchText.trim().toLowerCase();

  const [expandedRiskIds, setExpandedRiskIds] = useState<
    Record<string, boolean | undefined>
  >({});

  const toggleExpandRow = (rowId: string | number) =>
    setExpandedRiskIds({
      ...expandedRiskIds,
      [rowId]: !expandedRiskIds[rowId],
    });

  // TODO: GT - support partial risk visibility for table entries.

  const filteredRisks =
    currentTab === "all" && trimmedSearchText === ""
      ? risks
      : risks.filter((risk) => {
          if (
            trimmedSearchText &&
            risk.title.toLowerCase().indexOf(trimmedSearchText) === -1 &&
            risk.categoryTitle.toLowerCase().indexOf(trimmedSearchText) === -1
          ) {
            return false;
          }

          if (
            currentTab === "notinremediation" &&
            (!!risk.riskWaiverId || risk.inRemediation)
          ) {
            return false;
          }

          if (
            currentTab === "inremediation" &&
            (!!risk.riskWaiverId || !risk.inRemediation)
          ) {
            return false;
          }

          if (currentTab === "waived" && !risk.riskWaiverId) {
            return false;
          }

          return true;
        });

  const [sortedRisks, sortedBy, onSortChange] = useSorting<
    surveyAnswerRiskWithWaiver,
    "severity" | "finding" | "risk"
  >(filteredRisks, "severity", SortDirection.DESC, {
    severity: {
      orderFuncs: [(risk) => risk.severity, (risk) => risk.title],
      sortDirsDesc: ["desc", "asc"],
      sortDirsAsc: ["asc", "asc"],
    },
    finding: {
      orderFuncs: [(risk) => risk.title],
      sortDirsDesc: ["desc"],
      sortDirsAsc: ["asc"],
    },
    risk: {
      orderFuncs: [(risk) => risk.categoryTitle, (risk) => risk.title],
      sortDirsDesc: ["desc", "asc"],
      sortDirsAsc: ["asc", "asc"],
    },
  });

  const [currentPageItems, currentPage, totalPages, onPageChange] =
    usePagination(sortedRisks, 10);

  const columnHeaders: IXTableColumnHeader[] = [
    {
      id: "severity",
      text: "Severity",
      sortable: true,
      startingSortDir: SortDirection.DESC,
    },
    {
      id: "finding",
      text: "Finding",
      sortable: true,
      startingSortDir: SortDirection.ASC,
    },
    {
      id: "status",
      text: "",
      sortable: false,
    },
    {
      id: "action",
      text: "",
    },
  ];
  if (!isVendorPortal) {
    columnHeaders.splice(2, 0, {
      id: "risk",
      text: "Risk",
      sortable: true,
      startingSortDir: SortDirection.ASC,
    });
  }

  if (riskVisibility === SurveyRiskVisibility.HideSeverity) {
    columnHeaders.shift();
  }

  const tabs = [
    { id: "all", text: "View all" },
    { id: "notinremediation", text: "Not in remediation" },
    { id: "inremediation", text: "In remediation" },
    { id: "waived", text: "Waived risks" },
  ];

  const getRiskStatus = (
    risk: surveyAnswerRiskWithWaiver
  ): React.ReactNode[] => {
    const nodes = [];
    if (risk.riskWaiverId) {
      nodes.push(
        <PillLabel
          key={"waived"}
          color={LabelColor.Grey}
          onClick={(e) => {
            e.stopPropagation();
            history.push(
              `${vendorUrlPrefix(
                vendorId,
                isManagementAnalystSession,
                managedOrgId
              )}/riskwaivers?vendorRiskWaiverId=${risk.riskWaiverId}`,
              {
                backContext: {
                  backTo: location.pathname,
                  backToText: "Back to Questionnaire",
                },
              }
            );
          }}
        >
          Waived &gt;
        </PillLabel>
      );
    }

    if (risk.inRemediation) {
      nodes.push(
        <PillLabel
          key={"remediation"}
          color={LabelColor.Green}
          onClick={(e) => {
            e.stopPropagation();
            history.push(
              `${vendorUrlPrefix(
                vendorId,
                isManagementAnalystSession,
                managedOrgId
              )}/remediation/${risk.remediationRequestId}`,
              {
                backContext: {
                  backTo: location.pathname,
                  backToText: "Back to Questionnaire",
                },
              }
            );
          }}
        >
          In Remediation &gt;
        </PillLabel>
      );
    }

    if (risk.baseSeverity) {
      nodes.push(
        <PillLabel
          key={"adjusted"}
          color={LabelColor.TrendyPink}
          onClick={(e) => {
            e.stopPropagation();
            history.push(
              `${vendorUrlPrefix(
                vendorId,
                isManagementAnalystSession,
                managedOrgId
              )}/riskwaivers/severityadjustments?vendorRiskWaiverId=${
                risk.adjustmentId
              }`,
              {
                backContext: {
                  backTo: location.pathname,
                  backToText: "Back to Questionnaire",
                },
              }
            );
          }}
        >
          Adjusted &gt;
        </PillLabel>
      );
    }

    if (!risk.active) {
      nodes.push(
        <PillLabel key={"pass"} color={LabelColor.Green}>
          Passed
        </PillLabel>
      );
    }

    return nodes;
  };

  const numPassedChecks = countPassingChecks(risks);

  const publicDisableRiskActionText =
    publicSurvey && publicSurveyNotIncludedInProfile ? (
      <>
        Risks from this Shared Questionnaire must be factored into your Risk
        Profile in order to be remediated, waived or adjusted.
        <br />
        <br />
        To enable, toggle on &quot;Include in Risk Profile&quot; at the top of
        this page.
      </>
    ) : undefined;

  return (
    <ReportCard newStyles className="survey-details-risks-card">
      <div className="header">
        {title}
        <div className="header-right">
          {numPassedChecks > 0 && (
            <ColorCheckbox
              small
              color="blue"
              className="checkbox-right"
              checked={includePassedRisks}
              onClick={() => setIncludePassedRisks(!includePassedRisks)}
              label="Show passed checks"
            />
          )}
        </div>
      </div>
      <div>
        {surveyRisksAreFromPreSendAutofill && (
          <div className={"infobanner-container"}>
            <InfoBanner
              type={BannerType.INFO}
              message={
                <p>
                  These risks have been identified based on current
                  questionnaire answers. Review the answers in the questionnaire
                  and provide compensating controls where possible.
                </p>
              }
            />
          </div>
        )}
        <div className="tabs-and-search">
          {!isVendorPortal && (
            <TabButtons
              onChangeTab={(tabId) => setCurrentTab(tabId as tabId)}
              tabs={tabs}
              activeTabId={currentTab}
            />
          )}
          <SearchBox
            value={searchText}
            onChanged={(val) => setSearchText(val)}
            placeholder="Search for risk"
          />
        </div>
        {currentPageItems.length > 0 ? (
          <XTable
            columnHeaders={columnHeaders}
            sortedBy={sortedBy}
            onSortChange={onSortChange}
            pagination={{
              currentPage,
              totalPages,
              onPageChange,
              hidePaginationIfSinglePage: true,
            }}
            expandableRows
            onExpandToggle={toggleExpandRow}
            iconOptions
            rows={currentPageItems.map((risk) => {
              const statuses = isVendorPortal ? [] : getRiskStatus(risk);
              const cells = [
                <XTableCell key="severity" className="sev-cell shrink-cell">
                  <AdjustedSeverityIcon
                    severity={SeverityAsString(risk.severity)}
                    baseSeverity={
                      risk.baseSeverity
                        ? SeverityAsString(risk.baseSeverity)
                        : undefined
                    }
                  />
                </XTableCell>,
                <XTableCell key="finding">{risk.title}</XTableCell>,
                <XTableCell key="status" className="shrink-cell">
                  {statuses}
                </XTableCell>,
                <XTableCell
                  key={"action"}
                  className={"shrink-cell"}
                  showContentOnlyOnHover
                >
                  {!isVendorPortal &&
                  (!isAnalystWorkflowSurvey ||
                    isManagementAnalystSession ||
                    surveyStatusIn(surveyStatus, [SurveyStatus.Complete])) ? (
                    <ManageRiskButton
                      disable={!!publicDisableRiskActionText}
                      dropdownPopup={publicDisableRiskActionText}
                      autoCloseOnMouseLeave
                      hideRemediate={
                        !!risk.riskWaiverId ||
                        archivedSurvey ||
                        !risk.active ||
                        !userHasRemediationPermission ||
                        !!risk.remediationRequestId ||
                        (publicSurvey && publicSurveyNotIncludedInProfile)
                      }
                      onRemediate={() => {
                        history.push(
                          `${vendorUrlPrefix(
                            vendorId,
                            isManagementAnalystSession,
                            managedOrgId
                          )}/remediation/add`,
                          {
                            surveyIdsWithRisk: !publicSurvey ? [surveyId] : [],
                            publicSurveyIdsWithRisk: publicSurvey
                              ? [surveyId]
                              : [],
                            riskId: risk.id,
                            riskType: "survey",
                            backContext: {
                              backTo: location.pathname,
                              backToText: "Back to Questionnaire",
                            },
                          } as remediationLocationState
                        );
                      }}
                      hideAdjust={
                        !!risk.riskWaiverId ||
                        archivedSurvey ||
                        !risk.active ||
                        !userHasWaivePermission ||
                        !!risk.adjustmentId ||
                        (publicSurvey && publicSurveyNotIncludedInProfile)
                      }
                      onAdjust={() => {
                        history.push(
                          `${vendorUrlPrefix(
                            vendorId,
                            isManagementAnalystSession,
                            managedOrgId
                          )}/riskwaivers/severityadjustments/add?initialRiskId=${
                            risk.id
                          }&initialSeverity=${SeverityAsString(
                            risk.severity
                          )}&${
                            publicSurvey
                              ? "initialPublicSurveyId"
                              : "initialSurveyId"
                          }=${surveyId}`,
                          {
                            backContext: {
                              backTo: location.pathname,
                              backToText: "Back to Questionnaire",
                            },
                          }
                        );
                      }}
                      hideWaive={
                        !!risk.riskWaiverId ||
                        archivedSurvey ||
                        !risk.active ||
                        !userHasWaivePermission ||
                        !!risk.riskWaiverId ||
                        (publicSurvey && publicSurveyNotIncludedInProfile)
                      }
                      onWaive={() => {
                        history.push(
                          `${vendorUrlPrefix(
                            vendorId,
                            isManagementAnalystSession,
                            managedOrgId
                          )}/riskwaivers/add?initialRiskId=${
                            risk.id
                          }&initialSeverity=${SeverityAsString(
                            risk.severity
                          )}&${
                            publicSurvey
                              ? "initialPublicSurveyId"
                              : "initialSurveyId"
                          }=${surveyId}`,
                          {
                            backContext: {
                              backTo: location.pathname,
                              backToText: "Back to Questionnaire",
                            },
                          }
                        );
                      }}
                    />
                  ) : (
                    <></>
                  )}
                </XTableCell>,
              ];
              if (!isVendorPortal) {
                cells.splice(
                  2,
                  0,
                  <XTableCell key="risk">
                    {isVendorPortal ? risk.description : risk.categoryTitle}
                  </XTableCell>
                );
              }

              if (riskVisibility === SurveyRiskVisibility.HideSeverity) {
                cells.shift();
              }

              const options: IIconOption[] = [];
              if (getSurveyPath && risk.active) {
                options.push({
                  id: "view",
                  icon: (
                    <Link
                      to={getSurveyPath(
                        false,
                        surveyId,
                        risk.baseId ?? risk.id
                      )}
                    >
                      <div className="cr-icon-eye" />
                    </Link>
                  ),
                  hoverLocation: HoverLocation.Top,
                  hoverText: "View in questionnaire",
                });
              }

              return {
                id: risk.id,
                className: classnames("", {
                  "waived-row": risk.riskWaiverId,
                  passed: !risk.active,
                }),
                onClick: () => toggleExpandRow(risk.id),
                expanded: !!expandedRiskIds[risk.id],
                iconOptions: options,
                expandContent: (
                  <div className="risk-expand-content">
                    <div className="content-header">Overview</div>
                    {risk.description ? (
                      <div
                        className="overview-desc"
                        dangerouslySetInnerHTML={{
                          __html: risk.description,
                        }}
                      />
                    ) : (
                      <div className="overview-desc">
                        <em>No overview</em>
                      </div>
                    )}
                    {risk.active && (
                      <>
                        <div className="content-header">
                          Compensating control information
                        </div>
                        <CompensatingControlInfo risk={risk} />
                      </>
                    )}
                  </div>
                ),
                cells,
              };
            })}
          />
        ) : trimmedSearchText ? (
          <SearchEmptyCard
            searchItemText="risks"
            onClear={() => setSearchText("")}
          />
        ) : (
          <EmptyCardWithAction
            emptyText={
              currentTab === "all"
                ? "No risks flagged in questionnaire"
                : currentTab === "notinremediation"
                  ? "No active risks"
                  : currentTab === "inremediation"
                    ? "No risks in remediation"
                    : "No waived risks"
            }
            emptySubText={
              currentTab === "all"
                ? "Great news. No risks have been flagged in the submitted questionnaire."
                : currentTab === "notinremediation"
                  ? "There are no active risks that are not waived or in remediation."
                  : currentTab === "inremediation"
                    ? "There are no active risks in remediation."
                    : "No risks have been waived for this questionnaire."
            }
          />
        )}
      </div>
    </ReportCard>
  );
};

export default memo(withPermissions(SurveyDetailsRisksCard));
