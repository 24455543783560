import { FetchCyberRiskUrl } from "../../_common/api";
import { LogError } from "../../_common/helpers";
import {
  setExportItems,
  setExportItemsArchived,
  setExportItemsRenamed,
  setExportItemsViewed,
} from "../../_common/reducers/commonActions";
import { ExportItem } from "../../_common/types/exportReport";
import { ItemIdName } from "../components/modals/RenameReportsModal";
import { produce } from "immer";
import { DefaultAction } from "../../_common/types/redux";

export const renameReportExports = (
  items: ItemIdName[],
  isArchivedView: boolean
): DefaultAction<void> => {
  return async (dispatch, getState) => {
    try {
      await FetchCyberRiskUrl(
        "exports/v1",
        {},
        {
          method: "PATCH",
          body: JSON.stringify({ items }, (key: string, value: any) => {
            if (key === "orig") return undefined;
            return value;
          }),
        },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error renaming report exports", e);
      throw e;
    }

    const exportItems = isArchivedView
      ? getState().common.exportItemsArchived
      : getState().common.exportItems;
    const newExportItems = produce(exportItems, (draftItems: any) => {
      if (draftItems.data) {
        items.forEach((renamed) => {
          const found = draftItems.data.find(
            (item: ExportItem) => item.id === renamed.id
          );
          if (found) {
            found.filename = renamed.name;
          }
        });
      }
      return draftItems;
    });
    dispatch(setExportItemsRenamed(newExportItems, isArchivedView));
  };
};

export const downloadReportExports = (
  items: ExportItem[],
  isArchive: boolean
): DefaultAction<void> => {
  return async (dispatch): Promise<void> => {
    dispatch(setExportItemsViewed(items, isArchive));
  };
};

export const archiveReportExports = (
  items: ExportItem[],
  isArchive: boolean
): DefaultAction<void> => {
  return async (dispatch, getState): Promise<void> => {
    const itemIds = items.map((i) => i.id);

    try {
      await FetchCyberRiskUrl(
        "exports/archive/v1",
        {
          ids: itemIds,
          archive: isArchive,
        },
        { method: "PUT" },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error updating report exports", e);
      throw e;
    }

    if (isArchive) {
      items.forEach((i: ExportItem) => (i.archivedAt = Date.now().toString()));
      dispatch(
        setExportItems(
          getState().common.exportItems.data.filter(
            (i: ExportItem) => itemIds.indexOf(i.id) === -1
          )
        )
      );

      dispatch(
        setExportItemsArchived([
          ...getState().common.exportItemsArchived.data,
          ...items,
        ])
      );
    } else {
      items.forEach((i: ExportItem) => (i.archivedAt = undefined));
      dispatch(
        setExportItems([...getState().common.exportItems.data, ...items])
      );

      dispatch(
        setExportItemsArchived(
          getState().common.exportItemsArchived.data.filter(
            (i: ExportItem) => itemIds.indexOf(i.id) === -1
          )
        )
      );
    }
  };
};

export const deleteReportExports = (
  items: ExportItem[]
): DefaultAction<void> => {
  return async (dispatch, getState): Promise<void> => {
    const itemIds = items.map((i) => i.id);

    try {
      await FetchCyberRiskUrl(
        "exports/v1",
        {
          ids: itemIds,
        },
        { method: "DELETE" },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error deleting report exports", e);
      throw e;
    }

    dispatch(
      setExportItems(
        getState().common.exportItems.data.filter(
          (i: ExportItem) => itemIds.indexOf(i.id) === -1
        )
      )
    );

    dispatch(
      setExportItemsArchived(
        getState().common.exportItemsArchived.data.filter(
          (i: ExportItem) => itemIds.indexOf(i.id) === -1
        )
      )
    );
  };
};
