import { FC } from "react";
import BreachTypeIconMalware from "../../../vendorrisk/images/id-breach-icon-malware.svg";
import BreachTypeIconPaste from "../../../vendorrisk/images/id-breach-icon-paste.svg";
import { IdentityBreach } from "../../api/types";
import { BreachType } from "../../../_common/types/emailExposures";
import CompanyLogo from "../../../_common/components/CompanyLogo";
import { CircleImg } from "../../../_common/components/CircleImg";

export const getBreachDomain = (breach: IdentityBreach) => {
  if (breach.domain && breach.domain !== "") {
    return breach.domain;
  }
  if (breach.title && breach.title.includes(".")) {
    return breach.title;
  }
  if (breach.name && breach.name.includes(".")) {
    return breach.name;
  }
  return "";
};

interface IdentityBreachDetailsProps {
  breach: IdentityBreach;
}

const IdentityBreachDetails: FC<IdentityBreachDetailsProps> = ({ breach }) => {
  if (breach.breachType === BreachType.Company) {
    return <CompanyLogo name={breach.title} domain={getBreachDomain(breach)} />;
  }

  return (
    <div className="breach-type-icon-container">
      <CircleImg
        imgSrc={
          breach.breachType === BreachType.Infostealer
            ? BreachTypeIconMalware
            : BreachTypeIconPaste
        }
        className="breach-type-icon"
      />
      <div className="breach-type-label">{breach.title}</div>
    </div>
  );
};

export default IdentityBreachDetails;
