import Button from "../../../_common/components/core/Button";
import Modal from "../../../_common/components/ModalV2";
import { useState } from "react";
import ColorCheckbox from "../ColorCheckbox";
import PillLabel from "../PillLabel";
import { LabelColor } from "../../../_common/types/label";

import "../../style/components/NewVendorChoiceModal.scss";
import { getVendorWords } from "../../../_common/constants";
import {
  IWithPermissionsProps,
  withPermissions,
} from "../../../_common/permissions";
import InfoBanner, { BannerType } from "../InfoBanner";
import { AssuranceType } from "../../../_common/types/organisations";

interface INewVendorChoiceModalOwnProps {
  isShowing: boolean;
  onClose: () => void;
  onNextStep: (isMonitor: boolean) => void;
  assuranceType: AssuranceType;
  vendorName: string;
  vendorPrimaryHostname: string;
  lookupsLeft: number;
  watchSlotsLeft: number;
  canUseLookup: boolean;
  canWatch: boolean;
}

type INewVendorChoiceModalProps = INewVendorChoiceModalOwnProps &
  IWithPermissionsProps;

const NewVendorChoiceModal = (props: INewVendorChoiceModalProps) => {
  const [isWatch, setIsWatch] = useState(props.canWatch);

  const isConfirmButtonDisabled = isWatch
    ? !props.canWatch
    : !props.canUseLookup;

  const hasInfiniteWatches = props.canWatch && !props.watchSlotsLeft;
  const hasInfiniteLookups = props.canUseLookup && !props.lookupsLeft;

  const vendorWords = getVendorWords(props.assuranceType);

  const userHasWritePermissions =
    props.userHasVendorRiskWrite || props.editablePortfolioIds.length > 0;

  return (
    <Modal
      verticalCenter
      headerContent={props.vendorName}
      headerClassName={"new-vendor-choice-modal-header"}
      subHeaderContent={props.vendorPrimaryHostname}
      className={"new-vendor-choice-modal"}
      active={props.isShowing}
      onClose={() => props.onClose()}
      footerContent={
        <div className={"btn-group"}>
          <Button tertiary onClick={() => props.onClose()}>
            Cancel
          </Button>
          <Button
            primary
            onClick={() => props.onNextStep(isWatch)}
            disabled={isConfirmButtonDisabled || !userHasWritePermissions}
          >
            {isWatch ? "Next step" : "Generate"}
          </Button>
        </div>
      }
    >
      <div>
        {!userHasWritePermissions && (
          <InfoBanner
            type={BannerType.WARNING}
            message={`This ${vendorWords.singular} is not already monitored and you do not
            have permission to monitor new ${vendorWords.plural}.`}
          />
        )}
        <ColorCheckbox
          radio
          checked={isWatch}
          disabled={!props.canWatch || !userHasWritePermissions}
          onClick={() => setIsWatch(true)}
          label={
            <div>
              Monitor {props.vendorName}{" "}
              {!hasInfiniteWatches && (
                <PillLabel color={LabelColor.Blue}>
                  {props.watchSlotsLeft} remaining
                </PillLabel>
              )}
              <p onClick={() => setIsWatch(true)}>
                Continuously monitor {props.vendorName} and see changes in their
                risk profile as they occur.
              </p>
            </div>
          }
        />

        <ColorCheckbox
          radio
          checked={!isWatch}
          disabled={!props.canUseLookup || !userHasWritePermissions}
          onClick={() => setIsWatch(false)}
          label={
            <div>
              Generate snapshot{" "}
              {!hasInfiniteLookups && (
                <PillLabel color={LabelColor.Blue}>
                  {props.lookupsLeft} remaining
                </PillLabel>
              )}
              <p onClick={() => setIsWatch(false)}>
                Access a snapshot for this {vendorWords.singular} for the next
                30 days without monitoring them long-term.
              </p>
            </div>
          }
        />
      </div>
    </Modal>
  );
};

export default withPermissions(NewVendorChoiceModal);
