import { RepositoryRisk } from "../../../_common/types/vendorSummary";
import { FC } from "react";
import ColorCheckbox from "../ColorCheckbox";
import CheckboxList from "../../../_common/components/core/CheckboxList";

export interface RepoRiskAssetSelection {
  isAllSelected: boolean;
  selectedUuids: string[];
}

interface RepoRiskAssetSelectorProps {
  repos: RepositoryRisk[];
  selection: RepoRiskAssetSelection;
  onChange: (selection: RepoRiskAssetSelection) => void;
}

const RepoRiskAssetSelector: FC<RepoRiskAssetSelectorProps> = ({
  repos,
  selection,
  onChange,
}) => {
  return (
    <div className={"repo-risk-asset-selector asset-selector"}>
      <div className={"all-assets-selection"}>
        <h4 className={"overview-header"}>Select Repositories</h4>
        <ColorCheckbox
          radio
          color="blue"
          checked={selection.isAllSelected}
          label="All repositories"
          onClick={() =>
            onChange({
              isAllSelected: true,
              selectedUuids: repos.map((r) => r.uuid),
            })
          }
        />
        <ColorCheckbox
          radio
          color="blue"
          checked={!selection.isAllSelected}
          label="Selected repositories"
          onClick={() =>
            onChange({
              isAllSelected: false,
              selectedUuids: [],
            })
          }
        />
      </div>
      <div className={"asset-selection"}>
        <h4 className={"overview-header"}>Repositories</h4>
        <CheckboxList
          items={repos.map((r) => {
            return {
              id: r.uuid,
              label: r.repoName,
            };
          })}
          readonly={selection.isAllSelected}
          selectedItems={selection.selectedUuids}
          onChange={(selection) =>
            onChange({
              isAllSelected: false,
              selectedUuids: selection as string[],
            })
          }
        />
      </div>
    </div>
  );
};

export default RepoRiskAssetSelector;
