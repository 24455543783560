import { ContentLibraryDocument } from "../types/contentLibrary.types";
import { IUserMiniMap } from "../../_common/types/user";
import { FC, useMemo, useState } from "react";
import XTable, {
  IXTableColumnHeader,
  IXTableRow,
  XTableCell,
} from "../../_common/components/core/XTable";
import { UserAvatarAndName } from "../../_common/components/UserAvatar";
import moment from "moment/moment";
import { GetIconForFilename } from "../../vendorrisk/helpers/icons";
import LoadingIcon from "../../_common/components/core/LoadingIcon";
import "../styles/DocumentVersionsSection.scss";

interface IContentLibraryDocumentVersionsSectionProps {
  document: ContentLibraryDocument;
  sharedUsers: IUserMiniMap;
  downloadingVersion?: number;
  downloadDocumentVersion: (version: number) => Promise<void>;
}

const ContentLibraryDocumentVersionsSection: FC<
  IContentLibraryDocumentVersionsSectionProps
> = ({
  document,
  sharedUsers,
  downloadingVersion,
  downloadDocumentVersion,
}) => {
  const [versionsExpanded, setVersionsExpanded] = useState(false);

  const columnHeaders: IXTableColumnHeader[] = useMemo(
    () => [
      {
        id: "version",
        text: "Version",
        sortable: false,
      },
      {
        id: "updated_by",
        text: "Updated by",
        sortable: false,
      },
      {
        id: "date",
        text: "Date",
        sortable: false,
      },
      {
        id: "document",
        text: "Document",
        sortable: false,
      },
    ],
    []
  );

  const rows: IXTableRow[] = useMemo(
    () =>
      document.versions.map((v, i) => {
        const updatedByUser = sharedUsers[v.linkedBy];
        const downloadDisabled =
          typeof downloadingVersion !== "undefined" || !v.virusSafe;
        return {
          id: v.version,
          cells: [
            <XTableCell key="version">
              V{v.version} {i === 0 && "(current)"}
            </XTableCell>,
            <XTableCell key="updated_by">
              <UserAvatarAndName
                large
                name={updatedByUser?.name ?? ""}
                avatar={updatedByUser?.avatar ?? ""}
                email={updatedByUser?.email ?? ""}
              />
            </XTableCell>,
            <XTableCell key="date">
              {moment(v.createdAt).format("LLL")}
            </XTableCell>,
            <XTableCell key="document">
              <div
                className={`document-cell ${
                  downloadDisabled ? "disabled" : ""
                }`}
                onClick={
                  !downloadDisabled
                    ? () => downloadDocumentVersion(v.version)
                    : undefined
                }
              >
                <img
                  className="file-type-icon"
                  alt="File type icon"
                  src={GetIconForFilename(v.filename)}
                />
                <div className="file-name">{v.filename}</div>
                <div className="i-container">
                  {downloadingVersion === v.version ? (
                    <LoadingIcon />
                  ) : v.virusSafe ? (
                    <div className="cr-icon-export-thin" />
                  ) : undefined}
                </div>
              </div>
            </XTableCell>,
          ],
        };
      }),
    [document, sharedUsers, downloadingVersion, downloadDocumentVersion]
  );

  return (
    <>
      <div
        className="clibrary-document-versions-header"
        onClick={() => setVersionsExpanded(!versionsExpanded)}
      >
        <div>Versions</div>
        <div
          className={`cr-icon-chevron ${versionsExpanded ? "rot270" : "rot90"}`}
        />
      </div>
      {versionsExpanded && (
        <div className="clibrary-document-versions-section">
          <XTable
            className="versions-table"
            columnHeaders={columnHeaders}
            rows={rows}
          />
        </div>
      )}
    </>
  );
};

export default ContentLibraryDocumentVersionsSection;
