import LoadingBanner from "../../../_common/components/core/LoadingBanner";
import TextField from "../../../_common/components/TextField";
import { OrganisationSenderEmailV1Resp } from "../../reducers/orgEmailSenderSettings.actions";
import ColorCheckbox from "../ColorCheckbox";
import { useEffect, useState } from "react";
import { isEmailDomainVerified } from "../OrgSenderEmailSettingsCard";

interface SelectEmailIntegrationStepProps {
  loading: boolean;
  toEmailAddress: string;
  setToEmailAddress: (s: string) => void;
  fromName: string;
  setFromName: (s: string) => void;
  fromEmailAddress?: string;
  setFromEmailAddress: (s?: string) => void;
  description?: string;
  setDescription: (name: string) => void;
  orgAccessCustomEmailDomain: boolean;
  customEmailDomainsLoading: boolean;
  customEmailDomains?: OrganisationSenderEmailV1Resp;
}

const SelectEmailIntegrationStep = ({
  fromEmailAddress,
  fromName,
  loading,
  setFromEmailAddress,
  setFromName,
  setToEmailAddress,
  toEmailAddress,
  description,
  setDescription,
  orgAccessCustomEmailDomain,
  customEmailDomains,
  customEmailDomainsLoading,
}: SelectEmailIntegrationStepProps) => {
  // if we have a 'from email address' we are already using a custom domain
  const [useCustomDomain, setUseCustomDomain] = useState(!!fromEmailAddress);
  const [customDomain, setCustomDomain] = useState("");
  const [customDomainVerified, setCustomDomainVerified] = useState(false);
  const [customPrefix, setCustomPrefix] = useState("");

  useEffect(() => {
    if (fromEmailAddress) {
      const parts = fromEmailAddress.split("@");
      if (parts.length == 2) {
        setCustomPrefix(parts[0]);
        setCustomDomain(parts[1]);
      }
    }
  }, []);

  useEffect(() => {
    if (
      customEmailDomains &&
      customEmailDomains.emailDomains &&
      customEmailDomains.emailDomains.length > 0
    ) {
      setCustomDomain(customEmailDomains.emailDomains[0].emailDomain);
      if (isEmailDomainVerified(customEmailDomains)) {
        setCustomDomainVerified(true);
      }
    }
  }, [customEmailDomains]);

  useEffect(() => {
    if (customEmailDomains && useCustomDomain) {
      const splits =
        customEmailDomains.currentDefaultSenderEmailAddress?.fromEmailAddress.split(
          "@"
        );

      setCustomPrefix(splits && splits.length === 2 ? splits[0] : "");
      if (!fromName || fromName.length === 0) {
        setFromName(
          customEmailDomains.currentDefaultSenderEmailAddress?.fromName ?? ""
        );
      }
    }
  }, [useCustomDomain]);

  // we only allow the prefix to get set so need to add on the domain when set in the options
  const setCustomFromAddress = (prefix: string) => {
    setFromEmailAddress(prefix + "@" + customDomain);
    setCustomPrefix(prefix);
  };

  const noCustomDomains =
    !customEmailDomains ||
    !customEmailDomains.emailDomains ||
    customEmailDomains.emailDomains.length === 0;

  return (
    <div className={"section-step"}>
      <div className={"destination"}>
        {loading && <LoadingBanner />}
        {!loading && (
          <>
            <div className={"title-bar"}>
              <div className="header">Name & destination</div>
            </div>
            <div className={"body"}>
              <div className={"field"}>
                <div className="field-desc">
                  Name of the integration*
                  <p>
                    Please provide a name for this integration so you can
                    distinguish it later in the integrations list.
                  </p>
                </div>
                <TextField
                  className={"field-input"}
                  value={description ?? ""}
                  onChanged={setDescription}
                  placeholder={"Name of integration"}
                />
              </div>
              <div className={"field"}>
                <div className="field-desc">
                  Destination email address*
                  <p>
                    Provide an email address that these notifications will be
                    sent to.
                  </p>
                </div>
                <TextField
                  className={"field-input"}
                  value={toEmailAddress}
                  onChanged={setToEmailAddress}
                  placeholder={"Destination email address"}
                  type={"email"}
                />
              </div>
              <div className={"field"}>
                <div className="field-desc">
                  From email domain*
                  <p>Notifications will be sent from this email domain.</p>
                </div>
                <div className={"field-input"}>
                  {customEmailDomainsLoading ? (
                    <LoadingBanner />
                  ) : (
                    <>
                      <ColorCheckbox
                        radio
                        checked={!useCustomDomain}
                        onClick={() => {
                          setUseCustomDomain(false);
                          setFromEmailAddress(undefined);
                        }}
                        label={"Send from cyber-risk.upguard.com"}
                      />
                      <ColorCheckbox
                        radio
                        checked={useCustomDomain}
                        onClick={() => {
                          setUseCustomDomain(true);
                          setCustomFromAddress(customPrefix);
                        }}
                        label={
                          noCustomDomains
                            ? "Send from custom domain"
                            : `Send from ${customDomain}`
                        }
                        disabled={
                          noCustomDomains ||
                          !orgAccessCustomEmailDomain ||
                          !customDomainVerified
                        }
                        helpPopup={
                          !orgAccessCustomEmailDomain
                            ? "Custom sending domains are available with the co-branding feature. To enable this feature please contact support@upguard.com."
                            : noCustomDomains
                              ? "You can set up a custom sending domain for your account via the Co-branding tab in Settings."
                              : !customDomainVerified
                                ? "Your custom email domain is currently unverified. Go to the Co-branding tab in Settings to complete the set up."
                                : undefined
                        }
                      />
                    </>
                  )}
                </div>
              </div>
              <div className={"field"}>
                <div className="field-desc">
                  From name*
                  <p>The name emails will appear to be from.</p>
                </div>
                <TextField
                  className={"field-input"}
                  value={fromName}
                  onChanged={setFromName}
                  placeholder={"From name"}
                />
              </div>
              {useCustomDomain && (
                <div className={"field"}>
                  <div className="field-desc">
                    From email address*
                    <p>Notifications will be sent from this email address.</p>
                  </div>
                  <div className={"field-input custom-email-input"}>
                    <TextField
                      value={customPrefix}
                      onChanged={setCustomFromAddress}
                      placeholder={"From address"}
                    />
                    <TextField
                      value={"@" + customDomain}
                      readonly
                      onChanged={() => undefined}
                    />
                  </div>
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default SelectEmailIntegrationStep;
