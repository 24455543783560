import { FC, useState } from "react";
import RangeSlider, { IRangeSliderValues } from "./RangeSlider";
import SlidePanelSection from "./SlidePanelSection";

interface IRangeFilterProps extends IRangeSliderValues {
  min: number;
  max: number;
  onChange: (change: IRangeSliderValues) => void;
  title: string;
  startExpanded?: boolean;
}

const RangeFilter: FC<IRangeFilterProps> = ({
  min,
  max,
  lowerVal,
  upperVal,
  onChange,
  title,
  startExpanded,
}) => {
  const [expanded, setExpanded] = useState(
    startExpanded || lowerVal !== undefined || upperVal !== undefined
  );

  return (
    <SlidePanelSection
      title={title}
      expanded={expanded}
      toggleExpand={() => setExpanded(!expanded)}
    >
      <RangeSlider
        lowerVal={lowerVal}
        upperVal={upperVal}
        onChange={onChange}
        min={min}
        max={max}
      />
    </SlidePanelSection>
  );
};

export default RangeFilter;
