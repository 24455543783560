export const clamp = (num: number | null, min: number, max: number) => {
  if (num === null) return num;
  return Math.min(Math.max(num, min), max);
};

// returns a formatted number showing a maximum number of decimals, excluding redundant decimals.
// eg, it returns '0.23', '1.34'. But not '23.0'
export function formatDecimalPlaces(
  maxDecimals: number,
  value: number
): string {
  return parseFloat(value.toFixed(maxDecimals)).toString();
}
