import { ReactNode } from "react";
import "../../style/components/ModalForm.scss";

export interface IModalFormProps {
  className?: string;
  children: ReactNode;
}

// ModalForm is a simple component that provides styles for forms inside modals.
const ModalForm = (props: IModalFormProps) => (
  <div className={`modal-form ${props.className || ""}`}>{props.children}</div>
);

export default ModalForm;
