import React, { FC } from "react";
import UpGuardLogoSrc from "../../_common/images/upguard-logo-black.svg";
import Button from "../../_common/components/core/Button";
import "../style/SurveyViewerIntro.scss";
import { contactSupport } from "../../_common/helpers";
import { SurveyUsageType } from "../../_common/types/surveyTypes";
import InfoBanner, { BannerType } from "../../vendorrisk/components/InfoBanner";
import ReactMarkdown from "react-markdown";
import classNames from "classnames";
import PillLabel from "../../vendorrisk/components/PillLabel";
import { LabelColor } from "../../_common/types/label";
import { DefaultThunkDispatchProp } from "../../_common/types/redux";

import useSurveyViewerTipsModal from "./modals/SurveyViewerTipsModal";
import {
  getNumSuggested,
  getNumUnansweredQuestions,
  SectionNodeAnswerState,
} from "../surveyViewer.helpers";
import { appConnect } from "../../_common/types/reduxHooks";
import { SurveyViewerType } from "./SurveyViewer";

interface BannerProps {
  icon: React.ReactNode;
  text: string;
  subText?: string;
  onClick: () => void;
  recommended?: boolean;
  warning?: boolean;
  disabled?: boolean;
}

const Banner: FC<BannerProps> = ({
  icon,
  onClick,
  recommended,
  warning,
  text,
  subText,
  disabled,
}) => {
  return (
    <div
      onClick={!disabled ? onClick : undefined}
      className={classNames("intro-banner", { recommended, warning, disabled })}
    >
      <div className={"left-section"}>{icon}</div>
      <div className={"outer"}>
        <div className={"top-row"}>
          <span className={"text"}>{text}</span>
        </div>
        {subText && <span className={"sub-text"}>{subText}</span>}
      </div>
      <div className={"right-section"}>
        {recommended && (
          <PillLabel color={LabelColor.Green}>Recommended</PillLabel>
        )}
        <i className={"chevron cr-icon-chevron"} />
      </div>
    </div>
  );
};

interface SurveyViewerIntroOwnProps {
  orgName: string;
  logoUrl?: string;
  usageType: SurveyUsageType;
  userMessage?: string;
  onStartQuestionnaire: () => void;
  vendorName?: string;
  onOpenAutofill: () => void;
  autofillHasRun: boolean;
  gptAutofillEnabled: boolean;
  isImportedSurvey: boolean;
  viewerType: SurveyViewerType;
  rootNodeId: string;
  dontRecommendAutofill: boolean;
  isSystemSurvey: boolean;
}

interface SurveyViewerIntroConnectedProps {
  isAnswers: boolean;
  rootNodeAnswerState: SectionNodeAnswerState;
}

type SurveyViewerIntroProps = SurveyViewerIntroOwnProps &
  SurveyViewerIntroConnectedProps &
  DefaultThunkDispatchProp;

const SurveyViewerIntro = ({
  orgName,
  logoUrl,
  usageType,
  userMessage,
  onStartQuestionnaire,
  isAnswers,
  vendorName,
  onOpenAutofill,
  autofillHasRun,
  rootNodeAnswerState,
  gptAutofillEnabled,
  isImportedSurvey,
  viewerType,
  dontRecommendAutofill,
  isSystemSurvey,
}: SurveyViewerIntroProps) => {
  const logo = (
    <div className={"logo"}>
      {logoUrl && <img src={logoUrl} />}
      {!logoUrl && <img src={UpGuardLogoSrc} />}
    </div>
  );

  const [openTipsModal, tipsModal] = useSurveyViewerTipsModal(
    orgName,
    usageType,
    isImportedSurvey
  );

  if (userMessage) {
    userMessage = userMessage.replace(/<br>/g, "\n\n");
    userMessage = userMessage.replace(/<br\/>/g, "\n\n");
    userMessage = userMessage.replace(/<\/br>/g, "\n\n");
    userMessage = userMessage.replace(/<br \/>/g, "\n\n");
  }

  if (usageType === SurveyUsageType.Relationship) {
    return (
      <div className={"survey-viewer-intro"}>
        {logo}
        <h3>
          {orgName} has invited you to answer a relationship questionnaire in
          regards to {vendorName ? vendorName : "your vendor"}
        </h3>
        {userMessage && (
          <InfoBanner
            message={`Message from ${orgName}:`}
            subItems={[<ReactMarkdown key={"message"} source={userMessage} />]}
          />
        )}
        <p>
          Your answers will be automatically saved as you go, so you can exit
          this questionnaire and resume editing over multiple sessions.{" "}
        </p>
        <p>
          If you have any questions regarding the content of this questionnaire,
          please contact {orgName} via the Messages section on the previous
          screen. You can also contact{" "}
          <a onClick={contactSupport}>UpGuard support</a> for help with any
          technical issues.
        </p>
        <Button filledPrimary onClick={onStartQuestionnaire} arrow>
          {isAnswers ? "Go to next question" : "Start Questionnaire"}
        </Button>
      </div>
    );
  } else {
    return (
      <div className={"survey-viewer-intro"}>
        {logo}
        {isImportedSurvey ? (
          <h3>Imported questionnaire</h3>
        ) : (
          <h3>{orgName} has invited you to answer a questionnaire</h3>
        )}
        {userMessage && (
          <InfoBanner
            type={BannerType.CHAT}
            message={`Message from ${orgName}:`}
            subItems={[
              <ReactMarkdown
                key={"message"}
                source={'"' + userMessage + '"'}
              />,
            ]}
          />
        )}
        <Banner
          icon={<i className={"cr-icon-bulb"} />}
          text={"Tips for answering questionnaires"}
          onClick={openTipsModal}
        />
        {gptAutofillEnabled && !dontRecommendAutofill && (
          <>
            {autofillHasRun && getNumSuggested(rootNodeAnswerState) > 0 ? (
              <Banner
                icon={<i className={"cr-icon-question-alert"} />}
                text={`You have ${getNumSuggested(
                  rootNodeAnswerState
                )} autofilled answers and ${getNumUnansweredQuestions(
                  rootNodeAnswerState
                )} unanswered questions to review`}
                subText={
                  "Review autofilled answers, fill in any gaps, and then return it to the sender."
                }
                onClick={onStartQuestionnaire}
                warning
              />
            ) : (
              <Banner
                icon={<i className={"cr-icon-stars green-pulse"} />}
                text={"Get AI-generated answers"}
                subText={
                  isSystemSurvey
                    ? "Do you have a SOC 2 report, information security policy or past questionnaire? Use those documents to answer this questionnaire automatically."
                    : "Do you have relevant documentation already? Use those documents to answer this questionnaire automatically."
                }
                onClick={onOpenAutofill}
                recommended
                disabled={
                  viewerType !== SurveyViewerType.Editing &&
                  viewerType !== SurveyViewerType.Review
                }
              />
            )}
          </>
        )}
        <Button
          className={"continue-btn"}
          tertiary
          downArrow
          onClick={onStartQuestionnaire}
        >
          Continue answering{!autofillHasRun && " without extra help"}
        </Button>
        {tipsModal}
      </div>
    );
  }
};

export default appConnect<
  SurveyViewerIntroConnectedProps,
  never,
  SurveyViewerIntroOwnProps
>((state, props) => {
  return {
    isAnswers: Object.keys(state.surveyViewer.answers).length > 0,
    firstQuestionNodeId:
      state.surveyViewer.nodeTree?.children?.[0].nodeId ?? "",
    rootNodeAnswerState:
      state.surveyViewer.nodeChildrenAnswered[props.rootNodeId],
  };
})(SurveyViewerIntro);
