import ColorCheckbox from "../ColorCheckbox";
import LoadingBanner from "../../../_common/components/core/LoadingBanner";
import Button from "../../../_common/components/core/Button";
import {
  fetchRiskVendorWebsites,
  getRiskVendorWebsites,
} from "../../reducers/vendorRiskPortfolio.actions";
import { useEffect, useState } from "react";
import "../../style/components/risk_waivers/RiskHostnameSelector.scss";
import { appConnect, useAppDispatch } from "../../../_common/types/reduxHooks";

export interface RiskHostnameSelection {
  allSelected: boolean;
  includeFuture: boolean;
  selected: string[];
  customDomains?: string[]; // Only for BreachSight waivers
}

interface IRiskHostnameSelectorOwnProps {
  riskId: string;
  vendorId: number;
  selection: RiskHostnameSelection;
  onSelectionChanged: (sel: RiskHostnameSelection) => void;
  readonly?: boolean;
  skipWaiverId?: number;
}

interface IRiskHostnameSelectorConnectedProps {
  hostnames: { hostnamesWithRisk: string[] } | null;
  loading: boolean;
}

const RiskHostnameSelector = (
  props: IRiskHostnameSelectorOwnProps & IRiskHostnameSelectorConnectedProps
) => {
  const [isShowingMore, setIsShowingMore] = useState(false);
  const dispatch = useAppDispatch();

  const { riskId, vendorId, skipWaiverId } = props;
  useEffect(() => {
    dispatch(
      fetchRiskVendorWebsites(
        riskId,
        vendorId,
        false,
        false,
        true,
        skipWaiverId
      )
    );
  }, [riskId, vendorId, skipWaiverId]);

  // Check if we need to bubble domain selection after receiving domain list for a risk
  useEffect(() => {
    const hosts = props.hostnames?.hostnamesWithRisk || [];

    if (
      hosts.length > 0 &&
      props.selection.allSelected &&
      props.selection.selected.length === 0
    ) {
      props.onSelectionChanged({
        ...props.selection,
        selected: hosts,
      });
    }
  }, [props.selection, props.hostnames]);

  const clicked = (id: string, selected: boolean) => {
    let newSelected = [...props.selection.selected];
    if (selected) {
      newSelected.push(id);
    } else {
      newSelected = newSelected.filter((s) => s !== id);
    }
    props.onSelectionChanged({ ...props.selection, selected: newSelected });
  };

  const hosts = props.hostnames?.hostnamesWithRisk || [];

  const shownHosts =
    hosts.length > 10 && !isShowingMore ? hosts.slice(0, 10) : hosts;

  const displays = shownHosts.map((host) => {
    const isSelected = props.selection.selected.indexOf(host) !== -1;
    return (
      <li key={host}>
        <ColorCheckbox
          color="blue"
          label={host}
          checked={isSelected}
          onClick={() => clicked(host, !isSelected)}
          disabled={props.readonly || props.selection.allSelected}
        />
      </li>
    );
  });

  return (
    <div className={"risk-hostname-selector"}>
      <div className="all-items-selection">
        <h4>Select domains & IPs</h4>
        <div className={"all-websites-selection-container"}>
          <ColorCheckbox
            radio
            color="blue"
            checked={props.selection.allSelected}
            label="All domains & IPs"
            onClick={() =>
              props.onSelectionChanged({
                ...props.selection,
                allSelected: true,
                selected: hosts,
              })
            }
            disabled={props.readonly}
          />
          <ColorCheckbox
            radio
            color="blue"
            checked={!props.selection.allSelected}
            label="Selected domains & IPs"
            onClick={() =>
              props.onSelectionChanged({
                ...props.selection,
                allSelected: false,
                includeFuture: false,
                selected: [],
              })
            }
            disabled={props.readonly}
          />
        </div>
        {props.selection.allSelected && (
          <div>
            <div className="all-websites-selection-description">
              This risk will be waived for all currently known domains & IPs
            </div>
            <ColorCheckbox
              className={"future-chk"}
              color="blue"
              checked={props.selection.includeFuture}
              label="Include new domains & IPs we detect in the future"
              onClick={() => {
                const newVal = !props.selection.includeFuture;
                props.onSelectionChanged({
                  ...props.selection,
                  includeFuture: newVal,
                });
              }}
            />
          </div>
        )}
        {!props.selection.allSelected && (
          <div>
            <div className="all-websites-selection-description">
              This risk will only ever be waived for the selected specific
              domains & IPs.
            </div>
          </div>
        )}
      </div>
      <div className={"items"}>
        <h4>Domains & IPs</h4>
        {!props.hostnames || props.loading ? (
          <LoadingBanner tight />
        ) : (
          <ul>
            {displays}
            {shownHosts.length !== hosts.length && (
              <li>
                <Button tertiary onClick={() => setIsShowingMore(true)}>
                  Show {hosts.length - shownHosts.length} more...
                </Button>
              </li>
            )}
          </ul>
        )}
      </div>
    </div>
  );
};

export default appConnect<
  IRiskHostnameSelectorConnectedProps,
  never,
  IRiskHostnameSelectorOwnProps
>((state, props) => {
  const riskVendorWebsites = getRiskVendorWebsites(
    state,
    props.riskId,
    props.vendorId,
    false,
    false,
    props.skipWaiverId,
    ""
  );

  return {
    loading: riskVendorWebsites.loading,
    hostnames: riskVendorWebsites.data,
  };
})(RiskHostnameSelector);
