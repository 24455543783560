import { DefaultThunkDispatch } from "../../_common/types/redux";
import { IOrganisationGroup } from "../../admin_portal/reducers/organisationGroups.actions";
import { FetchCyberRiskUrl } from "../../_common/api";
import { DefaultRootState } from "react-redux";
import { conditionalRefreshActivityStreamForOrgUser } from "../../_common/reducers/commonActions";

export interface OrganisationVendorSharingRequest {
  id: number;
  orgId: number;
  requestedByOrgId: number;
  requestedByOrgName: string;
  datastoreVendorId: number;
  vendorName: string;
  vendorPrimaryHostname: string;
  grantedAt?: string;
  rejectedAt?: string;
  requestedAt: string;
}

export interface OrganisationVendorSharedWithOthers {
  datastoreVendorId: number;
  vendorName: string;
  vendorPrimaryHostname: string;
  sharedWithOrgs: {
    id: number;
    name: string;
  }[];
}

export interface OrganisationVendorSharingSettings {
  organisationGroup: IOrganisationGroup;
  activeVendorSharingRequests: OrganisationVendorSharingRequest[];
  otherVendorSharingRequests: OrganisationVendorSharingRequest[];
  sharedWithOthers: OrganisationVendorSharedWithOthers[];
}

export const SET_VENDOR_ASSET_SHARING_SETTINGS =
  "CYBERRISK_SET_VENDOR_ASSET_SHARING_SETTINGS";

const setVendorAssetSharingSettings = (
  settings: OrganisationVendorSharingSettings
) => ({
  type: SET_VENDOR_ASSET_SHARING_SETTINGS,
  settings,
});

export const fetchVendorAssetSharingSettings = (force: boolean) => {
  return async (
    dispatch: DefaultThunkDispatch,
    getState: () => DefaultRootState
  ): Promise<OrganisationVendorSharingSettings> => {
    if (!force) {
      const { vendorAssetSharingSettings } = getState().cyberRisk;
      if (vendorAssetSharingSettings) {
        return vendorAssetSharingSettings;
      }
    }

    let settings: OrganisationVendorSharingSettings;

    try {
      settings = await FetchCyberRiskUrl<OrganisationVendorSharingSettings>(
        "account/vendorsharing/v1/",
        null,
        null,
        dispatch,
        getState
      );
    } catch (e) {
      console.error(e);
      throw e;
    }

    dispatch(setVendorAssetSharingSettings(settings));

    return settings;
  };
};

export const acceptRejectOrganisationVendorSharingRequest = (
  requestId: number,
  accept: boolean,
  denyMessage?: string
) => {
  return async (
    dispatch: DefaultThunkDispatch,
    getState: () => DefaultRootState
  ): Promise<void> => {
    const opts: {
      request_id: number;
      accept: boolean;
      deny_message?: string;
    } = {
      request_id: requestId,
      accept,
    };

    if (!accept) {
      opts.deny_message = denyMessage;
    }

    try {
      await FetchCyberRiskUrl(
        "account/vendorsharing/request/v1/",
        opts,
        { method: "PUT" },
        dispatch,
        getState
      );
    } catch (e) {
      console.error(e);
      throw e;
    }

    // Refresh the activity stream if necessary
    dispatch(conditionalRefreshActivityStreamForOrgUser());
  };
};

export const revokeOrganisationVendorSharingRequest = (
  datastoreVendorId: number,
  orgId: number,
  denyMessage: string
) => {
  return async (
    dispatch: DefaultThunkDispatch,
    getState: () => DefaultRootState
  ): Promise<void> => {
    try {
      await FetchCyberRiskUrl(
        "account/vendorsharing/revoke/v1/",
        {
          datastore_vendor_id: datastoreVendorId,
          org_id: orgId,
          deny_message: denyMessage,
        },
        { method: "PUT" },
        dispatch,
        getState
      );
    } catch (e) {
      console.error(e);
      throw e;
    }
  };
};
