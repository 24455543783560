import { LabelColor } from "../../../_common/types/label";
import { FC, useEffect, useState } from "react";
import {
  OptionType,
  SelectV2Multi,
} from "../../../_common/components/SelectV2";
import { SlidePanelSection } from "./SlidePanelSection";
import ColorCheckbox from "../ColorCheckbox";
import Icon from "../../../_common/components/core/Icon";
import {
  fetchVendorAttributeDefinitions,
  searchAvailableAttributeValues,
  VendorAttributeDefinition,
  VendorAttributeDefinitionType,
} from "../../reducers/vendorAttributes.actions";
import { ValueType } from "react-select";
import PillLabel from "../PillLabel";
import {
  DefaultThunkDispatch,
  DefaultThunkDispatchProp,
} from "../../../_common/types/redux";
import DatePicker from "../../../_common/components/DatePicker";
import moment from "moment";
import classnames from "classnames";
import LoadingIcon from "../../../_common/components/core/LoadingIcon";
import { VendorAttributeFilter } from "./types";
import { IVendorWords } from "../../../_common/constants";
import { appConnect } from "../../../_common/types/reduxHooks";
import { SidePopupV2 } from "../../../_common/components/DismissablePopup";

// select value for the "No value" option
const noValueValue = "";

interface onChangeObj {
  attributeDefinitionId: number;
  selectedValues?: string[];
  doNotMatch?: boolean;
  matchAll?: boolean;
  startDate?: string;
  endDate?: string;
  before?: boolean;
  between?: boolean;
}

interface ISelectVendorAttributeFilterProps {
  attributeDefinition: VendorAttributeDefinition;
  selectedValues: string[];
  doNotMatch?: boolean;
  matchAll?: boolean;
  onChange: (changeObj: onChangeObj) => void;
  dispatch: DefaultThunkDispatch;
  startExpanded?: boolean;
  vendorWords: IVendorWords;
}

const SelectVendorAttributeFilter: FC<ISelectVendorAttributeFilterProps> = ({
  attributeDefinition,
  onChange,
  selectedValues = [],
  dispatch,
  doNotMatch = false,
  matchAll = false,
  startExpanded = false,
  vendorWords,
}) => {
  const [expanded, setExpanded] = useState(
    startExpanded || selectedValues.length > 0
  );
  const [availableValues, setAvailableValues] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (
      attributeDefinition.type === VendorAttributeDefinitionType.Set ||
      attributeDefinition.type === VendorAttributeDefinitionType.MultiSet
    ) {
      setAvailableValues(attributeDefinition.metadata?.allowedValues ?? []);
      return;
    }

    setIsLoading(true);
    dispatch(searchAvailableAttributeValues(attributeDefinition.id, "")).then(
      (values) => {
        setAvailableValues(values);
        setIsLoading(false);
      }
    );
  }, []);

  const activeRadioButton = doNotMatch ? "not" : matchAll ? "all" : "any";

  const selectOnChange = (selectedValues: ValueType<OptionType, true>) => {
    const changeObj: onChangeObj = {
      selectedValues: selectedValues
        ? selectedValues.map(({ value }) => value as string)
        : [],
      attributeDefinitionId: attributeDefinition.id,
      doNotMatch: activeRadioButton === "not",
      // we can't be in matchAll state if the No value option has been selected since it doesn't make sense as a filter
      matchAll:
        activeRadioButton === "all" &&
        !selectedValues?.some((v) => v.value === noValueValue),
    };

    onChange(changeObj);
  };

  const removeClick = (value: string) => {
    const changeObj: onChangeObj = {
      selectedValues: selectedValues.filter((v) => v !== value),
      attributeDefinitionId: attributeDefinition.id,
      doNotMatch: activeRadioButton === "not",
      matchAll: activeRadioButton === "all",
    };
    onChange(changeObj);
  };

  const selectValue: OptionType[] = [
    ...selectedValues.map((v) => ({ value: v, label: v })),
  ];

  const customStyles = {
    option: (provided: any, state: any) => {
      if (state.data.value === noValueValue) {
        return { ...provided, fontStyle: "italic" };
      }
      return { ...provided };
    },
  };

  return (
    <SlidePanelSection
      title={attributeDefinition.name}
      expanded={expanded}
      toggleExpand={() => setExpanded(!expanded)}
    >
      <div className="filter-label-options">
        <div className="label-filter-checkbox">
          <ColorCheckbox
            radio
            label="Match any"
            onClick={() =>
              onChange({
                attributeDefinitionId: attributeDefinition.id,
                doNotMatch: false,
                matchAll: false,
                selectedValues: selectedValues,
              })
            }
            checked={activeRadioButton === "any"}
          />
          <SidePopupV2
            className="help-icon"
            text={`Show ${vendorWords.plural} that include any of these specific attribute values`}
            position={"left"}
          >
            <Icon name="info" />
          </SidePopupV2>
        </div>
        {attributeDefinition.type ===
          VendorAttributeDefinitionType.MultiSet && (
          <div className="label-filter-checkbox">
            <ColorCheckbox
              radio
              label="Match all"
              onClick={() =>
                onChange({
                  attributeDefinitionId: attributeDefinition.id,
                  doNotMatch: false,
                  // we can't be in matchAll state if the No value option has been selected since it doesn't make sense as a filter
                  matchAll: !selectedValues.some((v) => v === noValueValue),
                  selectedValues: selectedValues,
                })
              }
              checked={activeRadioButton === "all"}
              disabled={selectedValues.some((v) => v === noValueValue)}
            />
            <SidePopupV2
              className="help-icon"
              text={`Show ${vendorWords.plural} that include all of these specific attribute values`}
              position={"left"}
            >
              <Icon name="info" />
            </SidePopupV2>
          </div>
        )}
        <div className="label-filter-checkbox">
          <ColorCheckbox
            radio
            label="Do not include"
            onClick={() =>
              onChange({
                attributeDefinitionId: attributeDefinition.id,
                doNotMatch: true,
                matchAll: false,
                selectedValues: selectedValues,
              })
            }
            checked={activeRadioButton === "not"}
          />
          <SidePopupV2
            className="help-icon"
            text={`Show ${vendorWords.plural} that do not include these specific attribute values`}
            position={"left"}
          >
            <Icon name="info" />
          </SidePopupV2>
        </div>
      </div>
      <SelectV2Multi
        isMulti
        placeholder={`Type to search...`}
        options={[
          { label: "No value", value: noValueValue },
          ...availableValues.map((value) => ({ label: value, value: value })),
        ]}
        value={selectValue}
        onChange={selectOnChange}
        controlShouldRenderValue={false}
        captureMenuScroll={false}
        isLoading={isLoading}
        styles={customStyles}
      />
      <div className={"value-list"}>
        {selectedValues.map((v) => (
          <PillLabel
            key={v}
            color={LabelColor.Blue}
            removeable
            onRemoveClick={() => removeClick(v)}
            className={"attribute-value"}
            capitalized={false}
          >
            {v ? v : "No value"}
          </PillLabel>
        ))}
      </div>
    </SlidePanelSection>
  );
};

interface IDateVendorAttributeFilterProps {
  attributeDefinition: VendorAttributeDefinition;
  startDate?: string;
  endDate?: string;
  before?: boolean;
  between?: boolean;
  onChange: (changeObj: onChangeObj) => void;
  startExpanded?: boolean;
  vendorWords: IVendorWords;
}

const DateVendorAttributeFilter: FC<IDateVendorAttributeFilterProps> = ({
  attributeDefinition,
  onChange,
  startDate,
  endDate,
  before,
  between,
  startExpanded = false,
}) => {
  const [expanded, setExpanded] = useState(
    startExpanded || !!startDate || !!endDate
  );
  const activeRadioButton = before ? "before" : between ? "between" : "after";

  const selectedDates = (startDate: string, endDate: string) => {
    const changeObj: onChangeObj = {
      attributeDefinitionId: attributeDefinition.id,
      startDate: startDate ? startDate : undefined,
      endDate: endDate ? endDate : undefined,
      before: before,
      between: between,
    };

    onChange(changeObj);
  };

  return (
    <SlidePanelSection
      title={attributeDefinition.name}
      expanded={expanded}
      toggleExpand={() => setExpanded(!expanded)}
    >
      <div className={"filter-date-options"}>
        <ColorCheckbox
          radio
          label="Before"
          onClick={() =>
            onChange({
              attributeDefinitionId: attributeDefinition.id,
              before: true,
              between: false,
              startDate: undefined,
              endDate: undefined,
            })
          }
          checked={activeRadioButton === "before"}
        />
        <ColorCheckbox
          radio
          label="Between"
          onClick={() =>
            onChange({
              attributeDefinitionId: attributeDefinition.id,
              before: false,
              between: true,
              startDate: undefined,
              endDate: undefined,
            })
          }
          checked={activeRadioButton === "between"}
        />
        <ColorCheckbox
          radio
          label="After"
          onClick={() =>
            onChange({
              attributeDefinitionId: attributeDefinition.id,
              before: false,
              between: false,
              startDate: undefined,
              endDate: undefined,
            })
          }
          checked={activeRadioButton === "after"}
        />
      </div>
      <div className={"filter-date-options"}>
        {activeRadioButton === "before" && (
          <div className={classnames("date-picker-container", "single")}>
            <DatePicker
              placeholder={"yyyy-mm-dd"}
              onChange={(e: any) => {
                selectedDates(
                  "",
                  e.target.value
                    ? moment(e.target.value).format("YYYY-MM-DD")
                    : ""
                );
              }}
              value={endDate ?? ""}
            />
          </div>
        )}
        {activeRadioButton === "after" && (
          <div className={classnames("date-picker-container", "single")}>
            <DatePicker
              placeholder={"yyyy-mm-dd"}
              onChange={(e: any) => {
                selectedDates(
                  e.target.value
                    ? moment(e.target.value).format("YYYY-MM-DD")
                    : "",
                  ""
                );
              }}
              value={startDate ?? ""}
            />
          </div>
        )}
        {activeRadioButton === "between" && (
          <>
            <div className={classnames("date-picker-container", "double")}>
              <DatePicker
                placeholder={"yyyy-mm-dd"}
                onChange={(e: any) => {
                  selectedDates(
                    e.target.value
                      ? moment(e.target.value).format("YYYY-MM-DD")
                      : "",
                    endDate ?? ""
                  );
                }}
                value={startDate ?? ""}
                max={endDate ? endDate : undefined}
              />
            </div>
            <div className={classnames("date-picker-container", "double")}>
              <DatePicker
                placeholder={"yyyy-mm-dd"}
                onChange={(e: any) => {
                  selectedDates(
                    startDate ?? "",
                    e.target.value
                      ? moment(e.target.value).format("YYYY-MM-DD")
                      : ""
                  );
                }}
                value={endDate ?? ""}
                min={startDate ? startDate : undefined}
              />
            </div>
          </>
        )}
      </div>
    </SlidePanelSection>
  );
};

interface IVendorAttributesFilterOwnProps {
  onChange: (changeObj: onChangeObj) => void;
  selectedAttributes: {
    [key: number]: VendorAttributeFilter;
  };
  startExpanded?: boolean;
  vendorWords: IVendorWords;
}

interface IVendorAttributesFilterConnectedProps {
  attributesDefinitions?: VendorAttributeDefinition[];
}

type IVendorAttributesFilterProps = IVendorAttributesFilterOwnProps &
  IVendorAttributesFilterConnectedProps &
  DefaultThunkDispatchProp;

export const VendorAttributesFilter: FC<IVendorAttributesFilterProps> = ({
  attributesDefinitions,
  selectedAttributes,
  onChange,
  dispatch,
  startExpanded = false,
  vendorWords,
}) => {
  useEffect(() => {
    if (attributesDefinitions === undefined) {
      dispatch(fetchVendorAttributeDefinitions());
    }
  }, [attributesDefinitions, dispatch]);

  if (attributesDefinitions === undefined) return <LoadingIcon size={32} />;

  return (
    <>
      {attributesDefinitions.map((def) => {
        switch (def.type) {
          case VendorAttributeDefinitionType.Date:
            return (
              <DateVendorAttributeFilter
                key={def.id}
                attributeDefinition={def}
                startDate={selectedAttributes[def.id]?.startDate}
                endDate={selectedAttributes[def.id]?.endDate}
                before={selectedAttributes[def.id]?.before ?? false}
                between={selectedAttributes[def.id]?.between ?? false}
                onChange={onChange}
                startExpanded={startExpanded}
                vendorWords={vendorWords}
              />
            );
          default:
            return (
              <SelectVendorAttributeFilter
                key={def.id}
                attributeDefinition={def}
                selectedValues={
                  selectedAttributes[def.id]?.selectedValues ?? []
                }
                doNotMatch={selectedAttributes[def.id]?.doNotMatch ?? false}
                matchAll={selectedAttributes[def.id]?.matchAll ?? false}
                onChange={onChange}
                dispatch={dispatch}
                startExpanded={startExpanded}
                vendorWords={vendorWords}
              />
            );
        }
      })}
    </>
  );
};

export const VendorAttributesFilterContainer = appConnect<
  IVendorAttributesFilterConnectedProps,
  never,
  IVendorAttributesFilterOwnProps
>((state) => {
  return {
    attributesDefinitions: state.cyberRisk.vendorAttributeDefinitions,
  };
})(VendorAttributesFilter);
