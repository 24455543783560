import "../style/components/LockedText.scss";
import classnames from "classnames";
import { SidePopupV2 } from "./DismissablePopup";

export interface ILockedTextProps {
  text: string;
  className?: string;
  popupText?: string;
  popupClassName?: string;
}

export const LockedText = ({
  text,
  className,
  popupText,
  popupClassName,
}: ILockedTextProps) => {
  return (
    <div className={classnames("locked-text", className)}>
      {text}
      {!!popupText ? (
        <SidePopupV2
          text={popupText}
          position="top"
          className={classnames("locked-text-popup", popupClassName)}
          noWrap
        >
          <div className="cr-icon-lock" />
        </SidePopupV2>
      ) : (
        <div className="cr-icon-lock" />
      )}
    </div>
  );
};
