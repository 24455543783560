import { FC } from "react";
import {
  ButtonWithDropdownV2,
  DropdownItem,
} from "../../../_common/components/core/DropdownV2";
import ColorCheckbox from "../ColorCheckbox";
import "../../style/components/risk_profile/ScoreTypeSelector.scss";

import {
  hasOrgPermission,
  OrgAccessCustomerInfo,
} from "../../../_common/permissions";
import { appConnect } from "../../../_common/types/reduxHooks";

export enum ScoreEntityType {
  Vendor,
  Subsidiary,
  Customer,
  UserBase,
}

export enum ScoreType {
  AdjustedAll,
  AdjustedPublicOnly,
  Base,
}

interface ScoreTypeSelectorOwnProps {
  entityType: ScoreEntityType;
  scoreType: ScoreType;
  onScoreTypeChange: (val: ScoreType) => void;
  disableAll?: boolean;
  accountCanAddCustomDomainsIPsToVendors: boolean;
}

interface ScoreTypeSelectorConnectedProps {
  canUsePublicWaivers: boolean;
}

type ScoreTypeSelectorProps = ScoreTypeSelectorOwnProps &
  ScoreTypeSelectorConnectedProps;

const ScoreTypeSelector: FC<ScoreTypeSelectorProps> = ({
  entityType,
  scoreType,
  onScoreTypeChange,
  disableAll,
  accountCanAddCustomDomainsIPsToVendors,
  canUsePublicWaivers,
}) => {
  let scoreTypeText = "";
  if (entityType === ScoreEntityType.Customer) {
    switch (scoreType) {
      case ScoreType.AdjustedAll:
        scoreTypeText = "Internal rating";
        break;
      case ScoreType.AdjustedPublicOnly:
        scoreTypeText = "Public rating (adjusted)";
        break;
      default:
        scoreTypeText = "Public rating";
    }
  } else {
    switch (scoreType) {
      case ScoreType.AdjustedAll:
        scoreTypeText = "Adjusted rating";
        break;
      default:
        scoreTypeText = "Base rating";
    }
  }

  return (
    <ButtonWithDropdownV2
      buttonProps={{
        tertiary: false,
        primary: false,
      }}
      dropdownProps={{
        className: "score-type-selector",
      }}
      text={`Display | ${scoreTypeText}`}
      showChevron
      disabled={disableAll}
    >
      {entityType === ScoreEntityType.Customer && (
        <>
          <DropdownItem
            selected={scoreType === ScoreType.AdjustedAll}
            onClick={() => onScoreTypeChange(ScoreType.AdjustedAll)}
          >
            <ScoreTypeDropdownItemContent
              text={"Internal rating"}
              description={
                "Includes impact of all active risk waivers and custom domains & IPs you have added. This is only visible to users in your organization."
              }
              selected={scoreType === ScoreType.AdjustedAll}
            />
          </DropdownItem>
          {canUsePublicWaivers && (
            <DropdownItem
              selected={scoreType === ScoreType.AdjustedPublicOnly}
              onClick={() => onScoreTypeChange(ScoreType.AdjustedPublicOnly)}
            >
              <ScoreTypeDropdownItemContent
                text={"Public rating + Public risk waivers"}
                description={
                  "Automated scanning score including the impact of public risk waivers. This score is visible to other users of the CyberRisk platform."
                }
                selected={scoreType === ScoreType.AdjustedPublicOnly}
              />
            </DropdownItem>
          )}
          <DropdownItem
            selected={scoreType === ScoreType.Base}
            onClick={() => onScoreTypeChange(ScoreType.Base)}
          >
            <ScoreTypeDropdownItemContent
              text={"Public rating"}
              description={
                "Automated scanning score for your organization visible to other users of the CyberRisk platform."
              }
              selected={scoreType === ScoreType.Base}
            />
          </DropdownItem>
        </>
      )}
      {entityType === ScoreEntityType.Vendor && (
        <>
          <DropdownItem
            selected={scoreType === ScoreType.AdjustedAll}
            onClick={() => onScoreTypeChange(ScoreType.AdjustedAll)}
          >
            <ScoreTypeDropdownItemContent
              text={"Adjusted rating"}
              description={`Includes impact of all active risk modifications${
                accountCanAddCustomDomainsIPsToVendors
                  ? " and domains & IPs you have added."
                  : "."
              }`}
              selected={scoreType === ScoreType.AdjustedAll}
            />
          </DropdownItem>
          <DropdownItem
            selected={scoreType === ScoreType.Base}
            onClick={() => onScoreTypeChange(ScoreType.Base)}
          >
            <ScoreTypeDropdownItemContent
              text={"Base rating"}
              description={
                "Includes raw automated scanning and questionnaire ratings."
              }
              selected={scoreType === ScoreType.Base}
            />
          </DropdownItem>
        </>
      )}
      {entityType === ScoreEntityType.UserBase && (
        <>
          <DropdownItem
            selected={scoreType === ScoreType.AdjustedAll}
            onClick={() => onScoreTypeChange(ScoreType.AdjustedAll)}
          >
            <ScoreTypeDropdownItemContent
              text={"Adjusted rating"}
              description={`Includes impact of all active risk modifications.`}
              selected={scoreType === ScoreType.AdjustedAll}
            />
          </DropdownItem>
          <DropdownItem
            selected={scoreType === ScoreType.Base}
            onClick={() => onScoreTypeChange(ScoreType.Base)}
          >
            <ScoreTypeDropdownItemContent
              text={"Base rating"}
              description={"Includes raw automated scanning rating."}
              selected={scoreType === ScoreType.Base}
            />
          </DropdownItem>
        </>
      )}
    </ButtonWithDropdownV2>
  );
};

interface ScoreTypeDropdownItemContent {
  selected: boolean;
  text: string;
  description: string;
}

const ScoreTypeDropdownItemContent: FC<ScoreTypeDropdownItemContent> = ({
  selected,
  text,
  description,
}) => {
  return (
    <div className={"score-type-dropdown-item-content"}>
      <ColorCheckbox radio checked={selected} label={text} />
      <p>{description}</p>
    </div>
  );
};

export default appConnect<
  ScoreTypeSelectorConnectedProps,
  never,
  ScoreTypeSelectorOwnProps
>((state) => {
  const isOrgVerifiedVendor =
    state.common.userData.orgList?.find(
      (o) => o.id === state.common.userData.currentOrgID
    )?.isVerifiedVendor ?? false;

  return {
    canUsePublicWaivers:
      isOrgVerifiedVendor &&
      hasOrgPermission(state.common.permissions, OrgAccessCustomerInfo),
  };
})(ScoreTypeSelector);
