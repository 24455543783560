import { FC, ReactNode } from "react";
import "../style/components/InfoCard.scss";

export interface IInfoCardProps {
  icon?: ReactNode;
  heading: string;
  description?: ReactNode;
  action?: ReactNode;
  onDismiss?: () => void;
}

const InfoCard: FC<IInfoCardProps> = ({
  icon,
  heading,
  description,
  action,
  onDismiss,
}) => {
  return (
    <div className="info-card">
      {icon && <div className="info-card-icn">{icon}</div>}
      <div className="header-desc">
        <h5>{heading}</h5>
        {description && <div className="desc">{description}</div>}
      </div>
      {action && <div className="action">{action}</div>}
      {onDismiss && <div className="dismiss-icn icon-x" onClick={onDismiss} />}
    </div>
  );
};

export default InfoCard;
