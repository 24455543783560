import { Component } from "react";
import PropTypes from "prop-types";

export default class Icon extends Component {
  static propTypes = {
    className: PropTypes.string,
    name: PropTypes.string.isRequired,
    direction: PropTypes.number,
  };

  static defaultProps = {
    className: "",
    direction: 0,
  };

  static directions = [90, 180, 270, Infinity, -Infinity];

  static getDirection(dir) {
    if (Icon.directions.indexOf(dir) >= 0) {
      return ` rotate-${dir}`;
    }
    return false;
  }

  render() {
    let cls = `icon-${this.props.name}`;
    const dir = Icon.getDirection(this.props.direction);
    cls = dir ? cls + dir : cls;
    cls = this.props.className ? `${cls} ${this.props.className}` : cls;

    return <span {...this.props} className={cls} />;
  }
}
