import Button, { IButtonProps } from "./core/Button";
import { trackEvent } from "../tracking";

export interface ITrackedButtonProps extends IButtonProps {
  eventName: string;
  eventProperties?: Record<string, unknown>;
}

const TrackedButton = ({
  eventName,
  eventProperties,
  onClick,
  ...otherProps
}: ITrackedButtonProps) => {
  return (
    <Button
      onClick={(e) => {
        trackEvent(eventName, eventProperties || {});
        if (onClick) {
          onClick(e);
        }
      }}
      {...otherProps}
    />
  );
};

export default TrackedButton;
