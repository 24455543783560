const findingTypes = [
  { id: "credentials", text: "Credentials" },
  { id: "cloudstorage", text: "Cloud storage" },
  { id: "sass", text: "SAAS" },
  { id: "sasspotential", text: "SAAS Potential" },
  { id: "tld", text: "TLD" },
  { id: "personal_data", text: "Personal data" },
  { id: "business_data", text: "Business data" },
  { id: "devices", text: "Devices" },
  { id: "undetermined", text: "Undetermined" },
];

export default findingTypes;
