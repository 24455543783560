import awsLogo from "../images/logo-aws.svg";
import gcpLogo from "../images/logo-gcp.svg";
import azureLogo from "../images/logo-azure.svg";
import { CloudProviderType } from "../api/types";

export function AwsLogo() {
  return <img className={"provider-logo"} src={awsLogo} />;
}

export function GcpLogo() {
  return <img className={"provider-logo"} src={gcpLogo} />;
}

export function AzureLogo() {
  return <img className={"provider-logo"} src={azureLogo} />;
}

export default function CloudProviderLogo({
  provider,
}: {
  provider: CloudProviderType;
}) {
  switch (provider) {
    case "aws":
      return <AwsLogo />;
    case "gcp":
      return <GcpLogo />;
    case "azure":
      return <AzureLogo />;
  }
}
