import { useState } from "react";
import ModalV2 from "./ModalV2";
import Button from "./core/Button";
import InfoBanner, { BannerType } from "../../vendorrisk/components/InfoBanner";

import "../style/components/TrialAccountRevertedModal.scss";
import { ContactSalesModal } from "./HelpAndSupportDropdown";
import {
  fetchUserData,
  setOnboardingStepComplete,
} from "../reducers/commonActions";
import { OnboardingStepName } from "../types/onboardingStepsMeta";
import { DefaultThunkDispatchProp } from "../../_common/types/redux";

interface ITrialAccountRevertedModal extends DefaultThunkDispatchProp {
  active: boolean;
  onClose: () => void;
}

const TrialAccountRevertedModal = (props: ITrialAccountRevertedModal) => {
  const { active, onClose, dispatch } = props;
  const [contactSalesModalActive, setContactSalesModalActive] = useState(false);
  const dismissMainModal = async () => {
    onClose();
    await dispatch(
      setOnboardingStepComplete(OnboardingStepName.TrialAccountRevertedToFree)
    );
    dispatch(fetchUserData());
  };

  return (
    <>
      <ContactSalesModal
        dispatch={dispatch}
        active={contactSalesModalActive}
        onClose={() => setContactSalesModalActive(false)}
      />
      <ModalV2
        className="trial-account-reverted-modal"
        active={active}
        onClose={dismissMainModal}
        headerContent="Thank you for completing a trial with UpGuard."
        footerContent={
          <>
            <Button
              onClick={() => {
                dismissMainModal();
                setContactSalesModalActive(true);
              }}
            >
              Contact Sales
            </Button>
            <Button onClick={() => dismissMainModal()}>
              <div className="cr-icon-check" /> Okay
            </Button>
          </>
        }
      >
        <div>
          <p>
            We&apos;ve reverted your account back to a free account. With this
            account you will still be able to:
          </p>
          <ul>
            <li>Publish and manage your Trust Page</li>
            <li>Answer security questionnaires</li>
            <li>Respond to remediation requests</li>
          </ul>
          <InfoBanner
            type={BannerType.INFO}
            message="Want to keep using the full version?"
            subItems={[
              "If you would like to continue using the full version of UpGuard, please reach out to your account manager, or contact sales below.",
            ]}
          />
        </div>
      </ModalV2>
    </>
  );
};

export default TrialAccountRevertedModal;
