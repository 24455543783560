import { Interval } from "./asyncSchedule";
import { IPSource } from "./ipAddresses";
import {
  HostnameVulnsFilter,
  WatchlistFilter,
  SubsidiariesFilter,
} from "../../vendorrisk/components/filter/types";
import { AvailableExportConfig } from "./exportConfig";

export enum ReportType {
  ReportTypeBreachSight = "BreachSight",
  ReportTypeVendorRisk = "VendorRisk",
  // BreachSight report types,
  ReportTypeBreachSightOverview = "BreachSightOverview",
  ReportTypeBreachSightGeolocation = "BreachSightGeolocation",
  ReportTypeBreachSightSubsidiariesGeolocation = "BreachSightSubsidiariesGeolocation",
  ReportTypeBreachSightRiskBreakdown = "BreachSightRiskBreakdown",
  ReportTypeBreachSightCompetitorAnalysis = "BreachSightCompetitorAnalysis",
  ReportTypeBreachSightSubsidiariesCompetitorAnalysis = "BreachSightSubsidiariesCompetitors",
  ReportTypeBreachSightSubsidiariesCompetitorAnalysisSubsOnly = "BreachSightSubsidiariesCompetitorsSubsOnly",

  // VendorRisk report types,
  ReportTypeVendorRiskOverview = "VendorRiskOverview",
  ReportTypeVendorRiskGeolocation = "VendorRiskGeolocation",
  ReportTypeVendorRiskRatingsBreakdown = "VendorRiskRatingsBreakdown",
  ReportTypeVendorRiskQuestionnaireActivity = "VendorRiskQuestionnaireActivity",
  ReportTypeVendorRiskSupplyChain = "VendorRiskSupplyChain",
  ReportTypeVendorRiskRiskMatrix = "VendorRiskRiskMatrix",
}

export enum ExportType {
  CustomerOrVendorReports = "CustomerOrVendorReports",
  ExecSummaryReports = "ExecSummaryReports",
  PortfolioRiskProfileReport = "PortfolioRiskProfileReport",
  RiskProfileReport = "RiskProfileReport",
  RiskProfileIncludingSubsidiariesReport = "RiskProfileIncludingSubsidiariesReport",
  VendorSummaryReport = "VendorSummaryReport",
  WebsitesReport = "WebsitesReport",
  IPsReport = "IPsReport",
  VendorListReport = "VendorListReport",
  VendorAssessmentReport = "VendorAssessmentReport",
  QuestionnairesReport = "QuestionnairesReport",
  VulnerabilitiesReport = "VulnerabilitiesReport",
  EmailExposuresReport = "EmailExposuresReport",
  AdditionalEvidenceReport = "AdditionalEvidenceReport",
  DetailedVendorSummaryReport = "DetailedVendorSummaryReport", // REMOVED: Kept here to support the reports library list for old exports
  DetailedVendorReportV2 = "DetailedVendorReportV2",
  ManagedVendorRiskAssessmentReport = "ManagedVendorRiskAssessmentReport",
  DetailedBreachsightReportV2 = "DetailedBreachsightReportV2",
  TyposquatMonitoredDomainsReport = "TyposquattingMonitoredDomainsReport",
  TyposquatPermutationsReport = "TyposquattingPermutationsReport",
  AuditLogReport = "AuditLogReport",
  RemediationRequests = "RemediationRequestReport",
  DetailedBreachsightSummaryReport = "DetailedBreachsightSummaryReport", // REMOVED: Kept here to support the reports library list for old exports
  VendorComparisonReport = "VendorComparisonReport",
  ChangesViewReport = "ChangesViewReport",
  ComplianceReport = "ComplianceReport",
  BoardReport = "BoardReport",
  BoardSummaryReport = "BoardSummaryReport", // REMOVED: Kept here to support the reports library list for old exports
  BoardSummaryReportV2 = "BoardSummaryReportV2",
  RemediationRequestDetails = "RemediationRequestDetails",
  DataLeaksSummaryReport = "DataLeaksSummaryReport",
  RiskAssessmentSummaryReport = "VendorRiskAssessmentSummary",
  QuestionnaireDetailsReport = "QuestionnaireDetailsReport",
  RiskWaiversExport = "RiskWaivers",
  ManagedVendorsExport = "ManagedVendors",
  ManagedVendorsAnalystExport = "ManagedVendorsAnalyst",
  FourthPartiesExport = "FourthParties",
  SubsidiariesExport = "Subsidiaries",
  BreachSightExecutiveSummaryExport = "BreachsightExecutiveSummary",
  VendorRiskExecutiveSummaryExport = "VendorRiskExecutiveSummary",
  BreachsightSubsidiariesReportV2 = "BreachsightSubsidiariesReportV2",
  DetectedProductsExport = "DetectedProducts",
  MVAAnalystReportExport = "MVAAnalystReportExport",
  ThreatMonitoringReport = "ThreatMonitoringReport",
  UserRiskUsersExport = "UserRiskUsers",
  UserRiskApplicationsExport = "UserRiskApplications",
}

export enum ExportFiletype {
  PDF = "pdf",
  XLSX = "xlsx",
  PPTX = "pptx",
}

export const ExportFiletypesBoth = new Set([
  ExportFiletype.PDF,
  ExportFiletype.XLSX,
]);
export const ExportFiletypePDF = new Set([ExportFiletype.PDF]);
export const ExportFiletypeXLSX = new Set([ExportFiletype.XLSX]);

export enum ExportStatus {
  StatusPending,
  StatusComplete,
  StatusError,
}

export enum SectionStatus {
  NONE = "",
  DEFAULT = "DEFAULT",
  LOCKED = "LOCKED",
  LOCKEDFILTER = "LOCKEDFILTER",
  REQUIRED = "REQUIRED",
  ONEREQUIRED = "ONEREQUIRED",
  TRUNCATED = "TRUNCATED", // currently unused 19/01/21
}

export type SectionByFileType = Record<ExportFiletype, AvailableSection>;

export interface AvailableSection {
  id: string;
  title: string;
  description: string;
  warning?: string;
  status: SectionStatus;
  children?: AvailableSection[];
  value: boolean;
}

export interface ExportSectionData {
  sectionsByFiletype: {
    [t in ExportFiletype]: AvailableSection[];
  };
  availableConfigByFiletype: {
    [t in ExportFiletype]: AvailableExportConfig;
  };
}

export interface ExportSection {
  data?: ExportSectionData;
  loading: boolean;
  error?: never;
}

export interface Section {
  ID: string;
  Value: boolean;
  Children: Section[];
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ExportMeta {}

export interface RiskProfileReportExportMeta extends ExportMeta {
  orgId: number;
  tzOffset?: number;
  vendorId?: number;
  vendorName?: string;
  customer?: boolean;
  subsidiary?: boolean;
  websiteLabelIds?: number[];
  riskCategoryFilter?: string;
  numDays?: number;
  graphDays?: number;
  fetchCloudscanMeta?: boolean;
  sections: Section[];
}

export interface ComplianceReportExportMeta extends ExportMeta {
  orgId: number;
  tzOffset?: number;
  vendorId?: number;
  vendorName?: string;
  customer?: boolean;
  subsidiary?: boolean;
  websiteLabelIds?: number[];
  securityFramework: string;
  sections: Section[];
}

export interface VendorSummaryReportExportMeta extends ExportMeta {
  orgId: number;
  tzOffset?: number;
  vendorId?: number;
  vendorName?: string;
  numDays?: number;
  graphDays?: number;
}

export interface PortfolioRiskProfileReportExportMeta extends ExportMeta {
  orgId: number;
  tzOffset?: number;
  vendorLabelIds?: number[];
  vendorLabelIdsMatchAll?: boolean;
  vendorLabelIdsDoNotMatch?: boolean;
  includeUnlabeled?: boolean;
  namePrefix?: string;
  vendorIds?: number[];
  categoryFilter?: string[];
  riskName?: string[];
  vulnsFilter?: HostnameVulnsFilter;
  sections: Section[];
}

export interface WebsitesReportExportMeta extends ExportMeta {
  orgId: number;
  tzOffset?: number;
  vendorId?: number;
  vendorName?: string;
  customer?: boolean;
  subsidiary?: boolean;
  websiteLabelIds?: number[];
  includeIPAddresses?: boolean;
}

export interface IPsReportFilters {
  Sources: IPSource[];
  Owners: string[];
  ASNs: string[];
  Countries: string[];
  Services: string[];
}

export interface IPsReportExportMeta extends ExportMeta {
  orgId: number;
  tzOffset?: number;
  vendorId?: number;
  vendorName?: string;
  customer?: boolean;
  subsidiary?: boolean;
  filters?: IPsReportFilters;
}

export interface VendorListReportExportMeta extends ExportMeta {
  orgId: number;
  tzOffset?: number;
  vendorLabelIds?: number[];
  vendorLabelIdsMatchAll?: boolean;
  vendorLabelIdsDoNotMatch?: boolean;
  minScore?: number;
  maxScore?: number;
  includeUnlabeled?: boolean;
  numDays?: number;
}

export interface VendorAssessmentReportExportMeta extends ExportMeta {
  orgId: number;
  tzOffset?: number;
  vendorAssessmentId: number;
  vendorId: number;
  vendorName: string;
}

export interface QuestionnairesReportExportMeta extends ExportMeta {
  orgId: number;
  tzOffset?: number;
  userId: number;
  vendorId?: number;
  vendorName?: string;
  customer?: boolean;
  vendorLabelIds?: number[];
  vendorLabelIdsMatchAll?: boolean;
  vendorLabelIdsDoNotMatch?: boolean;
  minScore?: number;
  maxScore?: number;
  includeUnlabeled?: boolean;
}

export interface VulnerabilitiesReportExportMeta extends ExportMeta {
  orgId: number;
  tzOffset?: number;
  vendorId?: number;
  vendorName?: string;
  customer?: boolean;
  subsidiary?: boolean;
  websiteLabelIds?: number[];
  filters?: HostnameVulnsFilter;
}

export interface EmailExposuresReportExportMeta extends ExportMeta {
  orgId: number;
  tzOffset?: number;
  useVersion1Export?: boolean;
  breachId?: number;
  includeIgnoredEmailAddresses?: boolean;
}

export interface DetailedVendorSummaryReportExportMeta extends ExportMeta {
  orgId: number;
  tzOffset?: number;
  userId: number;
  vendorId: number;
  vendorName: string;
  includeRiskAssessment?: boolean;
  useRisksPresentInRiskAssessment?: boolean;
  includeAutomatedScanningRisks?: boolean;
  includeQuestionnaireRisks?: boolean;
  includeAdditionalEvidenceRisks?: boolean;
  includeRiskDetails?: boolean;
  reportType?: string;
}

export interface RiskProfileIncludingSubsidiariesExportMeta extends ExportMeta {
  orgId: number;
  tzOffset: number;
  customer?: boolean;
  isSubsidiary?: boolean;
  vendorId?: number;
  sections: Section[];
  filterByVendorIDs: number[];
  filterByRiskIDs: string[];
  filterByRiskCategories: string[];
}

export interface ExecSummaryReportExportMeta extends ExportMeta {
  orgId: number;
  tzOffset?: number;
  reportSections: ReportType[];
  websiteLabelIDs?: number[];
  watchlistFilter?: WatchlistFilter;
  isIncludingSubsidiaries?: boolean;
  subsidiariesFilter?: SubsidiariesFilter;
}

export interface RemediationRequestExportMeta extends ExportMeta {
  orgId: number;
  userId: number;
  tzOffset: number;
  vendorId?: number;
  vendorName?: string;
  isForSubsidiaries: boolean;
  isSubsidiary: boolean;
  isSelfRemediation: boolean;
  includeInProgress: boolean;
  includeAwaitingReview: boolean;
  includeCompleted: boolean;
  includeArchived: boolean;
  labelIDs: number[];
  vendorLabelIdsMatchAll: boolean;
  vendorLabelIdsDoNotMatch: boolean;
  minScore?: number;
  maxScore?: number;
  includeUnlabeled: boolean;
}

export interface RemediationRequestDetailsExportMeta extends ExportMeta {
  ordId: number;
  remediationRequestId: number;
  includeRisks: boolean;
  includeRiskDetails: boolean;
  includeAssets: boolean;
  includeUnremediatedRisks: boolean;
  includePartiallyRemediatedRisks: boolean;
  includeRemediatedRisks: boolean;
  includeWaivedRisks: boolean;
  includeTimeline: boolean;
  isSelfRemediation: boolean;
}

export interface ExportItemGenerateOutput {
  xlsxExceededMaxRows: boolean;
}

export interface ExportItem {
  id: number;
  userId: number;
  orgId?: number;
  filename: string;
  exportType: ExportType;
  exportFiletype: ExportFiletype;
  exportMeta?: ExportMeta;
  cannedReportName?: string;
  status: ExportStatus;
  read: boolean;
  createdAt: string;
  completedAt?: string;
  archivedAt?: string;
  deletedAt?: string;
  scheduledExport?: number;
  reportType?: string;
  generateOutput?: ExportItemGenerateOutput;
}

export interface DisplayableScheduledReportRecipient {
  emailReceiverID?: number;
  scheduledReportEmailRecipientID?: number;
  emailAddress: string;
  unsubscribedAll: boolean;
  unsubscribed: boolean;
}

export interface DisplayableExportSchedule {
  scheduleID: number;
  exportScheduleID: number;
  orgID?: number;
  userID?: number;
  exportType: ExportType;
  exportFileType: ExportFiletype;
  exportFilename: string;
  exportMeta: ExportMeta;
  cannedReportName?: string;
  emailRecipients?: DisplayableScheduledReportRecipient[];
  interval: Interval;
  period: number;
  firstRun: string;
  lastRun?: string;
  nextRun?: string;
  expiresAt?: string;
  enabled: boolean;
}

export interface VendorComparisonReportExportMeta extends ExportMeta {
  orgId: number;
  tzOffset?: number;
  vendorsIds: number[];
}

export interface RiskWaiversExportMeta extends ExportMeta {
  orgId: number;
  userId: number;
  tzOffset?: number;
  customer?: boolean;
  sections?: Section[];
  domainPortfolioIDs?: number[];
  websiteLabelIDs?: number[];
}

export interface BreachsightExecutiveSummaryExportMeta extends ExportMeta {
  sections: Section[];
  includeSubsidiaries: boolean;
  includeSubsidiariesOnly: boolean;
  // + more not needed yet
}

export interface VendorRiskExecutiveSummaryExportMeta extends ExportMeta {
  sections: Section[];
  // + more not needed yet
}
