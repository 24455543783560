import ColorCheckbox from "../../vendorrisk/components/ColorCheckbox";
import DatePicker from "./DatePicker";
import "../style/components/DateSelector.scss";
import moment from "moment";
import { useState } from "react";

export enum DateRangeSelectorType {
  oneDay = 1,
  sevenDays = 7,
  thirtyDays = 30,
  year = 365,
  custom = 10000,
}

export const dateRangeSelectorStrings = new Map<DateRangeSelectorType, string>([
  [DateRangeSelectorType.oneDay, "Last 24 hours"],
  [DateRangeSelectorType.sevenDays, "Last 7 days"],
  [DateRangeSelectorType.thirtyDays, "Last 30 days"],
  [DateRangeSelectorType.year, "Within the last year"],
  [DateRangeSelectorType.custom, "Custom date range"],
]);

export interface DateRangeSelection {
  type: DateRangeSelectorType;
  startCustomDate?: moment.Moment;
  endCustomDate?: moment.Moment;
}

interface IDateRangeSelectorProps {
  dayOptions: DateRangeSelectorType[];
  allowCustomDateRange: boolean;
  value: DateRangeSelection;
  onChanged: (value: DateRangeSelection) => void;
  maxDate?: string;
}

// Common component to allow selecting from a pre-defined set of date ranges
// or optionally a custom date range.
const DateRangeSelector = (props: IDateRangeSelectorProps) => {
  const { type, startCustomDate, endCustomDate } = props.value;

  // Keep track of selection internally to restrict the available date selection for a custom range
  const [selectedStartDate, setSelectedStartDate] = useState(startCustomDate);
  const [selectedEndDate, setSelectedEndDate] = useState(endCustomDate);

  const selectionChanged = (selected: DateRangeSelectorType) => {
    props.onChanged({ ...props.value, type: selected });
  };

  const startDateChanged = (date: moment.Moment) => {
    props.onChanged({ ...props.value, startCustomDate: date });
    setSelectedStartDate(date);
  };

  const endDateChanged = (date: moment.Moment) => {
    props.onChanged({ ...props.value, endCustomDate: date });
    setSelectedEndDate(date);
  };

  const dateOptions = props.dayOptions.map((o) => (
    <ColorCheckbox
      key={o}
      checked={type === o}
      radio
      label={dateRangeSelectorStrings.get(o)}
      onClick={() => selectionChanged(o)}
    />
  ));

  // If allowing custom dates, show as last
  if (props.allowCustomDateRange) {
    dateOptions.push(
      <ColorCheckbox
        key={DateRangeSelectorType.custom}
        checked={type === DateRangeSelectorType.custom}
        radio
        label={dateRangeSelectorStrings.get(DateRangeSelectorType.custom)}
        onClick={() => selectionChanged(DateRangeSelectorType.custom)}
      />
    );
  }

  return (
    <div className={"date-selector"}>
      <div className={"date-selector-type-selection"}>{dateOptions}</div>
      {type === DateRangeSelectorType.custom && (
        <div className={"date-selector-custom-date"}>
          <DatePicker
            placeholder={"Start date"}
            onChange={(e: any) => startDateChanged(moment(e.target.value))}
            max={
              selectedEndDate
                ? selectedEndDate.format("YYYY-MM-DD")
                : props.maxDate
            }
            value={
              selectedStartDate
                ? selectedStartDate.format("YYYY-MM-DD")
                : undefined
            }
          />
          <DatePicker
            placeholder={"End date"}
            onChange={(e: any) => endDateChanged(moment(e.target.value))}
            min={
              selectedStartDate
                ? selectedStartDate.format("YYYY-MM-DD")
                : undefined
            }
            max={props.maxDate}
            value={
              selectedEndDate ? selectedEndDate.format("YYYY-MM-DD") : undefined
            }
          />
        </div>
      )}
    </div>
  );
};

DateRangeSelector.defaultProps = {
  dayOptions: [
    DateRangeSelectorType.oneDay,
    DateRangeSelectorType.sevenDays,
    DateRangeSelectorType.thirtyDays,
  ],
  allowCustomDateRange: true,
  value: {
    startCustomDate: null,
    endCustomDate: null,
  },
};

export default DateRangeSelector;
