import { Component } from "react";
import { DefaultThunkDispatch } from "../../../_common/types/redux";
import { History } from "history";
import LoadingBanner from "../../../_common/components/core/LoadingBanner";
import { fetchJiraProjectList } from "../../reducers/oauth.actions";
import { addMessageAlert } from "../../../_common/reducers/messageAlerts.actions";
import { OptionType, SelectV2 } from "../../../_common/components/SelectV2";
import LoadingIcon from "../../../_common/components/core/LoadingIcon";
import Button from "../../../_common/components/core/Button";
import { BannerType } from "../InfoBanner";
import { JiraProjectsList } from "../../../_common/types/integration";

interface ISelectJiraDestinationStepProps {
  history: History;
  dispatch: DefaultThunkDispatch;
  loading: boolean;
  description?: string;
  selectedJiraAccount: string;
  selectedJiraProject?: string;
  selectedJiraProjectId?: string;
  jiraProjectsList: JiraProjectsList;
  setDescription: (name: string) => void;
  setSelectedProject: (project: string, id: string) => void;
}

//
// SelectJiraDestinationStep
// Represents Step 2 in the Jira interation workflow. Assuming that a connection to a Jira account has previously been made
// via OAuth2, it allows the user to give the integration a name (description) and to select the Jira project to target when
// a new issue is created.
//
class SelectJiraDestinationStep extends Component<ISelectJiraDestinationStepProps> {
  static defaultProps = {
    description: "",
    selectedJiraProject: "",
  };

  constructor(props: ISelectJiraDestinationStepProps) {
    super(props);
  }

  //
  // refreshJiraProjectsList
  // Allows the user to manually request that the list of known Jira projects for the current account be refreshed. A user
  // may do this if they are fiddling with Jira config at the sme time as creating a Jira integration instance.
  //
  refreshJiraProjectsList = async () => {
    try {
      await this.props.dispatch(fetchJiraProjectList(false, true));
    } catch (e) {
      this.props.dispatch(
        addMessageAlert({
          message: `Failed to refresh Jira projects list: "${e}"`,
          type: BannerType.ERROR,
        })
      );
    }
  };

  render() {
    let selectedProject = this.props.selectedJiraProject
      ? this.props.selectedJiraProject
      : "";
    let selectedProjectId = this.props.selectedJiraProjectId
      ? this.props.selectedJiraProjectId
      : 0;

    let projectsHaveChanged = !!selectedProject;
    const projectOptions = [] as OptionType[];
    if (
      this.props.jiraProjectsList &&
      !this.props.jiraProjectsList.loading &&
      !this.props.jiraProjectsList.error &&
      this.props.jiraProjectsList.projects
    ) {
      this.props.jiraProjectsList.projects.map((project) => {
        const label = `${project.name} (${project.key})`;
        if (project.id == selectedProjectId) {
          projectsHaveChanged = false;
          selectedProjectId = project.id;
          selectedProject = label;
        }
        projectOptions.push({ value: project.id, label: label });
      });
    }

    if (projectOptions.length == 0) {
      projectsHaveChanged = false;
    }

    return (
      <div className="section-step">
        <div id={"destination"}>
          {this.props.loading && <LoadingBanner />}
          {!this.props.loading && (
            <>
              <div className={"title-bar"}>
                <div className="header">Name & destination</div>
              </div>
              <div className={"body"}>
                <div className={"field"}>
                  <div className="field-desc">
                    Name of the integration*
                    <p>Please provide a name for this integration.</p>
                  </div>
                  <div className="field-input">
                    <input
                      type="text"
                      name="description"
                      value={this.props.description}
                      placeholder="Name of integration"
                      onChange={(e) => {
                        this.props.setDescription(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className={"field"}>
                  <div className="field-desc">
                    Select Jira project*
                    <p>
                      Select the project in which new Jira issues will be
                      created.
                    </p>
                  </div>
                  <div className="field-input">
                    <SelectV2
                      placeholder="Select Jira project"
                      options={projectOptions}
                      value={
                        selectedProject
                          ? {
                              value: selectedProjectId,
                              label: selectedProject,
                            }
                          : undefined
                      }
                      onChange={(selected) => {
                        if (selected) {
                          this.props.setSelectedProject(
                            selected.label,
                            selected.value as string
                          );
                        }
                      }}
                      className={"channel-select"}
                      isDisabled={
                        !this.props.jiraProjectsList ||
                        this.props.jiraProjectsList.loading
                      }
                    />
                    <div className={"under-select"}>
                      {(!this.props.jiraProjectsList ||
                        this.props.jiraProjectsList.loading) && (
                        <LoadingIcon
                          color={"grey"}
                          className={"loading-spinner"}
                        />
                      )}
                      <Button
                        tertiary
                        className={"reset-button"}
                        disabled={
                          !this.props.jiraProjectsList ||
                          this.props.jiraProjectsList.loading
                        }
                        onClick={this.refreshJiraProjectsList}
                      >
                        {this.props.jiraProjectsList &&
                          !this.props.jiraProjectsList.loading && (
                            <div className="cr-icon-redo" />
                          )}
                        {`Refresh projects list from ${this.props.selectedJiraAccount}`}
                      </Button>
                      {projectsHaveChanged && (
                        <div className={"list-warning"}>
                          {
                            "** The projects list seems to have changed. Please select a new Jira project."
                          }
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    );
  }
}

export default SelectJiraDestinationStep;
