import { FC, useState } from "react";
import { uniqBy } from "lodash";

import PillLabel from "./PillLabel";

import "../style/components/LabelList.scss";
import UpdateLabelsModal from "./labels/UpdateLabelsModal";
import {
  ILabel,
  LabelClassification,
  LabelColor,
} from "../../_common/types/label";
import {
  PopupPosition,
  SidePopupV2,
} from "../../_common/components/DismissablePopup";

// Allow space for a "+XX" label
const morePillLabelWidth = 40;
const labelMinSpace = 14;
const labelLetterWidth = 6.2;
const addLabelWidth = 18.2;

interface ILabelListProps {
  labels?: any[];
  maxWidth?: number;
  maxNumber?: number;
  onLabelsUpdated?: (
    availableLabels: ILabel[],
    addedLabelIds: number[],
    removedLabelIds: number[]
  ) => Promise<void>;
  updateLabelsHeader?: string;
  updateLabelsText?: string;
  classification?: LabelClassification;
  showSystemLabels?: boolean;
  popupPosition?: PopupPosition;
}

const LabelList: FC<ILabelListProps> = ({
  labels = [],
  maxWidth = 0,
  maxNumber = 0,
  onLabelsUpdated,
  updateLabelsHeader,
  updateLabelsText,
  classification,
  showSystemLabels = false,
  popupPosition,
}) => {
  const [isShowingUpdateLabelModal, setIsShowingUpdateLabelModal] =
    useState(false);

  // Each PillLabel takes up about 14px + (number of letters * 6.2px) of space.
  // Start with the maxWidth and add labels till we've run out of space
  // Take into account the add label button if enabled
  let widthLeft =
    maxWidth -
    morePillLabelWidth -
    (onLabelsUpdated !== undefined ? addLabelWidth : 0);
  let displayedLabels = [];
  let rest = [];
  let mustShowLabels: ILabel[] = [];

  if (maxWidth > 0) {
    for (let i = 0; i < labels.length; i++) {
      const labelWidth =
        labelMinSpace + labels[i].name.length * labelLetterWidth;
      widthLeft -= labelWidth;
      // If there is still enough room for the "+XX" pill, or it's the last label and there's still room for it,
      // display it
      if (
        widthLeft > morePillLabelWidth ||
        (i === labels.length - 1 && widthLeft > 0)
      ) {
        displayedLabels.push(labels[i]);
      } else {
        rest.push(labels[i]);
      }
    }
  } else if (maxNumber > 0) {
    displayedLabels = labels?.slice(0, maxNumber);
    rest = labels?.slice(maxNumber);
  } else {
    displayedLabels = labels;
  }

  displayedLabels = uniqBy(displayedLabels, "id");
  rest = uniqBy(rest, "id");
  mustShowLabels = uniqBy(mustShowLabels, "id");

  return (
    <div className="label-list">
      {displayedLabels.concat(mustShowLabels).map((label) => (
        <PillLabel
          key={label.id}
          color={label.color || label.colour}
          bordered={label.bordered}
          className={label.className}
          onClick={label.onClick}
          removeable={label.removeable}
          large={label.large}
          constrained={label.constrained}
          onRemoveClick={label.onRemoveClick}
          capitalized={label.capitalized ?? false}
        >
          {label.name}
        </PillLabel>
      ))}
      {rest.length > 0 && (
        <SidePopupV2
          className={"label-rest-container"}
          popupClassName={"label-rest-popup"}
          popupHoverable
          popupStyle={"light"}
          width={200}
          position={popupPosition}
          text={
            <div>
              {rest.map((label) => (
                <PillLabel
                  key={label.id}
                  color={label.color || label.colour}
                  bordered={label.bordered}
                  capitalized={label.capitalized ?? false}
                >
                  {label.name}
                </PillLabel>
              ))}
            </div>
          }
        >
          <PillLabel key="+" color={LabelColor.Grey} bordered>
            {rest.length} more
          </PillLabel>
        </SidePopupV2>
      )}
      {onLabelsUpdated !== undefined && classification !== undefined && (
        <PillLabel
          key="add-label"
          className="add-label"
          color={LabelColor.Grey}
          onClick={(e) => {
            e.stopPropagation();
            setIsShowingUpdateLabelModal(true);
          }}
          bordered
        >
          {updateLabelsText !== undefined ? (
            updateLabelsText
          ) : (
            <>{labels.length > 0 ? "+" : "+ Add label"}</>
          )}
          <span onClick={(e) => e.stopPropagation()}>
            <UpdateLabelsModal
              active={isShowingUpdateLabelModal}
              showSystemLabels={showSystemLabels}
              classification={classification}
              header={updateLabelsHeader}
              selectedItems={[
                {
                  labels,
                },
              ]}
              onClose={() => setIsShowingUpdateLabelModal(false)}
              onUpdate={onLabelsUpdated}
            />
          </span>
        </PillLabel>
      )}
    </div>
  );
};

export default LabelList;
