export const factCategories = {
  WebsiteSec: "website_sec",
  EmailSec: "email_sec",
  NetworkSec: "network_sec",
  Phishing: "phishing",
  BrandProtect: "brand_protect",
  Vulns: "vulns",
  EmailExposures: "emailexposures",
  DataLeaks: "dataleaks",
  QuestionnaireRisks: "questionnaire_risks",
  AdditionalEvidenceRisks: "additional_evidence",
  SaaSRisks: "saas",
  AppguardPackageVulns: "appguard_package_vuln",
  AppguardRepoConfig: "appguard_repo_config",
  Website: "website_sec_v2",
  Email: "email_sec_v2",
  Network: "network_sec_v2",
  BrandReputation: "brand_protect_v2",
  IPDomainReputation: "ip_domain_reputation",
  Encryption: "encryption",
  DNS: "dns",
  DataLeakage: "data_leakage",
  VulnerabilityManagement: "patch_management",
  AttackSurface: "attack_surface",
};

// oldCategories are categories that may be displayed
// until an org/vendor is rescored with the new category algorithm.
export const oldCategories = [
  factCategories.WebsiteSec,
  factCategories.NetworkSec,
  factCategories.BrandProtect,
  factCategories.EmailSec,
  factCategories.Phishing,
];

// defaultShownCategories are categories introduced
// in the v5 scoring algorithm.
export const defaultShownCategories = [
  factCategories.Website,
  factCategories.IPDomainReputation,
  factCategories.Encryption,
  factCategories.VulnerabilityManagement,
  factCategories.AttackSurface,
  factCategories.Network,
  factCategories.Email,
  factCategories.DataLeakage,
  factCategories.DNS,
  factCategories.BrandReputation,
];

// taken from categories.go
export const defaultCategoryWeights = {
  [factCategories.Website]: 19,
  [factCategories.IPDomainReputation]: 19,
  [factCategories.Encryption]: 17,
  [factCategories.VulnerabilityManagement]: 13,
  [factCategories.AttackSurface]: 11,
  [factCategories.Network]: 8,
  [factCategories.Email]: 7,
  [factCategories.DataLeakage]: 3,
  [factCategories.DNS]: 2,
  [factCategories.BrandReputation]: 1,
};
