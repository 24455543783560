import { FC } from "react";
import IdAndNameFilter from "./IdAndNameFilter";
import { getVendorAssetTypes } from "../../reducers/filters.actions";
import { RootState } from "../../../_common/types/reduxStore";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../_common/types/reduxHooks";

interface VendorSurveyTypesFilterProps {
  selected: number[];
  onChanged: (selectedIds: number[]) => void;
}

const VendorSurveyTypesFilter: FC<VendorSurveyTypesFilterProps> = ({
  selected,
  onChanged,
}) => {
  const dispatch = useAppDispatch();

  const selectedData = useAppSelector((state: RootState) => {
    return state.cyberRisk.vendorCurrentSurveyTypes;
  });

  return (
    <IdAndNameFilter
      title={"Questionnaire types"}
      getValues={() => dispatch(getVendorAssetTypes())}
      values={selectedData}
      selected={selected}
      onChanged={onChanged}
    />
  );
};

export default VendorSurveyTypesFilter;
