import { Account, Breach } from "../../../_common/types/emailExposures";
import { FC, useState } from "react";
import { useConfirmationModalV2 } from "../../../_common/components/modals/ConfirmationModalV2";
import { NumberWithCommas, pluralise } from "../../../_common/helpers";
import { fetchOrganisationDefaultTexts } from "../../reducers/orgDefaultTexts.actions";
import { openModal } from "../../../_common/reducers/commonActions";
import { EmailExposureNotificationModalName } from "../modals/EmailExposureNotificationModal";
import { DefaultTextType } from "../../../_common/types/orgDefaultTexts";
import { SidePopupV2 } from "../../../_common/components/DismissablePopup";
import Button from "../../../_common/components/core/Button";
import Icon from "../../../_common/components/core/Icon";
import { MAX_NOTIFICATION_SIZE } from "./EmailExposures";
import ModalV2, {
  BaseModalProps,
  useModalV2,
} from "../../../_common/components/ModalV2";
import { useAppDispatch } from "../../../_common/types/reduxHooks";

interface IExcludeAccountsModalProps extends BaseModalProps {
  numNotified: number;
  onSendAndExclude: () => Promise<void>;
  onSendAll: () => Promise<void>;
}

const ExcludeAccountsModal: FC<IExcludeAccountsModalProps> = ({
  active,
  onClose,
  numNotified,
  onSendAndExclude,
  onSendAll,
}) => {
  const [onSendAndExcludeLoading, setOnSendAndExcludeLoading] = useState(false);
  const [onSendAllLoading, setOnSendAllLoading] = useState(false);

  return (
    <ModalV2
      active={active}
      onClose={onClose}
      headerContent={`${numNotified} ${pluralise(
        numNotified,
        "account has",
        "accounts have"
      )} already been notified`}
      footerContent={
        <>
          <div className="btn-group left">
            <Button tertiary onClick={onClose}>
              Cancel
            </Button>
          </div>
          <div className="btn-group">
            <Button
              primary
              disabled={onSendAndExcludeLoading}
              loading={onSendAllLoading}
              onClick={async () => {
                setOnSendAllLoading(true);
                await onSendAll();
                onClose();
              }}
            >
              No, keep {pluralise(numNotified, "it", "them")}
            </Button>
            <Button
              filledPrimary
              disabled={onSendAllLoading}
              loading={onSendAndExcludeLoading}
              onClick={async () => {
                setOnSendAndExcludeLoading(true);
                await onSendAndExclude();
                onClose();
              }}
            >
              Yes, exclude from notification
            </Button>
          </div>
        </>
      }
    >
      <p>
        Do you want to exclude{" "}
        {pluralise(numNotified, "this account", "these accounts")} from this
        email notification?
      </p>
    </ModalV2>
  );
};

interface INotifyEmployeesButtonProps {
  isPaidOrg: boolean;
  actionBarSubmitting: boolean;
  selectedAccounts: Account[];

  currentOrgName: string;
  currentUserEmail: string;
  breach: Breach;
  onSendNotification: (
    subject: string,
    message: string,
    specificAccountIDs?: number[]
  ) => Promise<void>;
}

export const NotifyEmployeesButton: FC<INotifyEmployeesButtonProps> = ({
  actionBarSubmitting,
  selectedAccounts,
  isPaidOrg,
  currentOrgName,
  currentUserEmail,
  breach,
  onSendNotification,
}) => {
  const dispatch = useAppDispatch();
  const [openConfirmationModal, confirmationModal] = useConfirmationModalV2();
  const [openExcludeNotifyModal, excludeNotifyModal] =
    useModalV2(ExcludeAccountsModal);
  const [loadingDefaultTexts, setLoadingDefaultTexts] = useState(false);

  const totalSelected = selectedAccounts.length;
  let totalSelectedIgnored = 0;
  const alreadyNotified: Account[] = [];
  const notNotified: Account[] = [];
  for (let i = 0; i < selectedAccounts.length; i++) {
    if (selectedAccounts[i].ignored) {
      totalSelectedIgnored += 1;
    } else if (
      selectedAccounts[i].notifiedBreachIDs &&
      selectedAccounts[i].notifiedBreachIDs.includes(breach.ID)
    ) {
      alreadyNotified.push(selectedAccounts[i]);
    } else {
      notNotified.push(selectedAccounts[i]);
    }
  }

  let notifyDisabledReason;
  if (!isPaidOrg) {
    notifyDisabledReason = "This feature is not available for trial accounts";
  } else if (totalSelectedIgnored > 0) {
    notifyDisabledReason = "Cannot notify accounts that are marked as ignored";
  } else if (totalSelected > MAX_NOTIFICATION_SIZE) {
    notifyDisabledReason = `Notifications may only be sent for up to ${NumberWithCommas(
      MAX_NOTIFICATION_SIZE
    )} accounts.`;
  }

  const openNotificationModal = async (filteredAccounts: Account[]) => {
    setLoadingDefaultTexts(true);
    const defaultTexts = await dispatch(fetchOrganisationDefaultTexts());
    setLoadingDefaultTexts(false);

    if (filteredAccounts.length === 0) {
      // Shouldn't be possible but just to make sure
      return;
    }

    const exampleAccount = filteredAccounts[0];

    dispatch(
      openModal(
        EmailExposureNotificationModalName,
        {
          orgName: currentOrgName,
          sender: currentUserEmail,
          breach,
          totalRecipients: filteredAccounts.length,
          recipientDomain: exampleAccount.domain,
          recipientName: exampleAccount.name,
          onSend: (subject: string, message: string) =>
            onSendNotification(
              subject,
              message,
              filteredAccounts.map((a) => a.id)
            ),
          defaultSubject:
            defaultTexts[DefaultTextType.IdentityBreachSubject]?.defaultText,
          defaultMessage:
            defaultTexts[DefaultTextType.IdentityBreachMessage]?.defaultText,
        },
        true
      )
    );
  };

  const onClick = () => {
    if (alreadyNotified.length === totalSelected) {
      openConfirmationModal({
        title: `Selected accounts have already been notified`,
        description: "Do you want to notify them again?",
        buttonAction: () => openNotificationModal(selectedAccounts),
        buttonText: "Notify again",
        filledPrimaryAction: true,
      });
    } else if (alreadyNotified.length > 0) {
      openExcludeNotifyModal({
        numNotified: alreadyNotified.length,
        onSendAll: () => openNotificationModal(selectedAccounts),
        onSendAndExclude: () => openNotificationModal(notNotified),
      });
    } else {
      openNotificationModal(selectedAccounts);
    }
  };

  return (
    <>
      <SidePopupV2 text={notifyDisabledReason}>
        <Button
          className="action-bar-submit"
          filledPrimary
          disabled={!!notifyDisabledReason || actionBarSubmitting}
          loading={loadingDefaultTexts}
          onClick={onClick}
        >
          <Icon name="email" /> Notify Employees
        </Button>
      </SidePopupV2>
      {confirmationModal}
      {excludeNotifyModal}
    </>
  );
};
