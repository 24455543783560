import { FC, useState } from "react";
import { SlidePanelSection } from "./SlidePanelSection";
import {
  OptionType,
  SelectV2Multi,
} from "../../../_common/components/SelectV2";
import LabelList from "../LabelList";
import { DataLeaksClassificationNames } from "../../../_common/types/dataLeaks";
import { ValueType } from "react-select";
import { DataLeaksFilterOptionType } from "./types";

interface IDataLeaksClassificationFilterProps {
  selected?: DataLeaksFilterOptionType[];
  onChange: (opts: DataLeaksFilterOptionType[]) => void;
  title?: string;
  startExpanded?: boolean;
}

const DataLeaksClassificationFilter: FC<
  IDataLeaksClassificationFilterProps
> = ({
  selected = [],
  onChange,
  title = "Type to search classifications",
  startExpanded = false,
}) => {
  const [expanded, setExpanded] = useState(
    startExpanded || (selected && selected.length > 0)
  );

  const selectOnChange = (selectedOptions: ValueType<OptionType, true>) => {
    onChange((selectedOptions ?? []) as DataLeaksFilterOptionType[]);
  };

  const options = Object.entries(DataLeaksClassificationNames).map(
    ([id, name]) => ({
      value: id,
      label: name,
    })
  );

  const selectValue = selected.map(
    (sel) => options.find(({ value }) => value === sel.value) as OptionType
  );

  const selectedClassifications = selectValue.map(({ value, label }) => ({
    id: value,
    name: label,
    removeable: true,
    large: true,
    constrained: true,
    onRemoveClick: () =>
      selectOnChange(selectValue.filter((option) => value !== option.value)),
  }));

  return (
    <SlidePanelSection
      title="Filter by Classification"
      expanded={expanded}
      toggleExpand={() => setExpanded(!expanded)}
    >
      <SelectV2Multi
        placeholder={title}
        options={options}
        value={selectValue}
        onChange={selectOnChange}
        controlShouldRenderValue={false}
      />
      <LabelList labels={selectedClassifications} />
    </SlidePanelSection>
  );
};

export default DataLeaksClassificationFilter;
