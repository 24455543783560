import { get as _get, merge } from "lodash";
import {
  getLocalStorageItem,
  getLocalStorageItemString,
} from "../../_common/session";
import { produce } from "immer";
import {
  CLEAR_ALL_DATA,
  CLEAR_CLOUDSCAN_DATA,
  CLEAR_CUSTOMER_DATA,
  CLEAR_EXPOSURES_PAGED_ACCOUNT_DATA,
  CLEAR_ORG_NOTIFICATIONS_SETTINGS,
  CLEAR_VENDORS_DATA,
  DELETE_AUTOMATION_TEST_RESULTS,
  MOVE_IN_SURVEYTYPE_AUTOMATION_LIST,
  REMOVE_FROM_SURVEYTYPE_AUTOMATION_LIST,
  REMOVE_REMEDIATION_RISK_ID,
  REMOVE_REVERSE_WHOIS_LOOKUP_DATA,
  REMOVE_VENDOR_DETAILED_ADDITIONAL_EVIDENCE_DOCUMENT,
  REPLACE_WHOIS_OVERRIDE_DATA,
  RESET_CUSTOMER_VULNS_BY_CPE_BY_HOSTNAMES,
  RESET_REPORTS_DATA,
  RESET_VENDORS_PAGE_NUM,
  SET_ALL_ORG_NOTIFICATIONS,
  SET_ALL_VENDOR_RISKS,
  SET_ALL_VENDORS,
  SET_AUDIT_TRAIL_FILTERS,
  SET_AUTOMATION_LIST_RECIPE_EDITS_EXIST,
  SET_AUTOMATION_RECIPE,
  SET_AUTOMATION_RECIPE_AUTOSAVE_STATE,
  SET_AUTOMATION_RECIPE_ENABLED,
  SET_AUTOMATION_RECIPE_STATE,
  SET_AUTOMATION_RECIPE_SYNTAX_STATE,
  SET_AUTOMATION_TEST_RESULTS,
  SET_AUTOMATION_TEST_SUBMISSIONS,
  SET_AVAILABLE_LABELS,
  SET_CHANGES_ACTIVE,
  SET_CHANGES_DATA,
  SET_CHANGES_END,
  SET_CHANGES_LOADING,
  SET_CHANGES_START,
  SET_CLOUDSCAN_DATA,
  SET_CLOUDSCAN_ERROR,
  SET_CUSTOMER_DATA,
  SET_CUSTOMER_DATA_FILTER_OPTIONS,
  SET_CUSTOMER_DATA_FILTERS,
  SET_CUSTOMER_RISK_HOSTNAMES,
  SET_CUSTOMER_VULNS_BY_CPE_BY_HOSTNAMES,
  SET_CUSTOMERDATA_SURVEYS,
  SET_DATA_LEAKS_PAGESORT,
  SET_DATA_LEAKS_SCANRESULTS,
  SET_DATA_LEAKS_SERVICES,
  SET_DATA_LEAKS_STATISTICS,
  SET_DATA_LEAKS_SUMMARY,
  SET_DATA_LEAKS_TAB,
  SET_EVIDENCE_TAB,
  SET_EXPOSURE_NOTIFICATION_STATUS,
  SET_EXPOSURES_ACCOUNT_DATA,
  SET_EXPOSURES_ACCOUNTS_LOADING,
  SET_EXPOSURES_ACCOUNTS_SEARCHDATA,
  SET_EXPOSURES_AVAILABLE_ASSIGNEES,
  SET_EXPOSURES_BREACH_DATA,
  SET_EXPOSURES_MONITORED_DOMAINS,
  SET_EXPOSURES_PAGE_DETAILS,
  SET_EXPOSURES_PAGE_NUM,
  SET_EXPOSURES_PAGED_ACCOUNT_DATA,
  SET_EXPOSURES_VIP_ACCOUNT_DATA,
  SET_MANAGED_VENDORS_REQUEST_SEARCH,
  SET_OAUTH_CONNECTION_DATA,
  SET_OAUTH_CONNECTION_LOADING,
  SET_OAUTH_ERROR,
  SET_OAUTH_JIRA_COMPONENTS,
  SET_OAUTH_JIRA_ISSUETYPES,
  SET_OAUTH_JIRA_POSTING_STATUS,
  SET_OAUTH_JIRA_PROJECTS,
  SET_OAUTH_MESSAGING_CHANNELS,
  SET_OAUTH_MESSAGING_POPULATED_DATA,
  SET_OAUTH_MESSAGING_POPULATED_EXPECTED,
  SET_OAUTH_MESSAGING_POSTING_STATUS,
  SET_OAUTH_REQUESTURL_DATA,
  SET_ORG_API,
  SET_ORG_DEFAULT_TEXTS,
  SET_ORG_INTEGRATIONS_SETTINGS,
  SET_ORG_LOGO_URL,
  SET_ORG_NOTIFICATIONS_SETTINGS,
  SET_ORG_USER_INVITES,
  SET_ORG_USER_LIMITS,
  SET_ORG_USERS,
  SET_ORGANISATION_FLAGS,
  SET_PLANS,
  SET_REPORTS_CONFIG,
  SET_REPORTS_DATA,
  SET_REVERSE_WHOIS_LOOKUP_DATA,
  SET_REVERSE_WHOIS_LOOKUP_LOADING,
  SET_SCORING_PERIOD,
  SET_SURVEY_TYPES,
  SET_SURVEYTYPE_AUTOMATION_ENABLED,
  SET_SURVEYTYPE_AUTOMATION_LIST,
  SET_SURVEYTYPE_AUTOMATION_QUESTIONS,
  SET_TABLE_PAGE,
  SET_TABLE_SORT,
  SET_TOUR_STEPS,
  SET_TYPOSQUAT_DOMAIN_DIRTY,
  SET_TYPOSQUAT_HISTORY_DATA,
  SET_USER_NOTIFICATIONS_SETTINGS,
  SET_VENDOR_ADDITIONAL_EVIDENCE,
  SET_VENDOR_ASSESSMENT_EVIDENCE_DATA,
  SET_VENDOR_ASSESSMENT_RISK_HOSTNAMES,
  SET_VENDOR_BREAKDOWN_DATA,
  SET_VENDOR_CONTACTS_PAGING,
  SET_VENDOR_DATA,
  SET_VENDOR_DETAILED_ADDITIONAL_EVIDENCE,
  SET_VENDOR_DETAILED_ADDITIONAL_EVIDENCE_ARCHIVED,
  SET_VENDOR_DETAILED_ADDITIONAL_EVIDENCE_NEWDOCUMENT,
  SET_VENDOR_DETAILED_ADDITIONAL_EVIDENCE_VIRUS_SCANS,
  SET_VENDOR_DOCUMENTS_PAGING,
  SET_VENDOR_FILTER_OPTIONS,
  SET_VENDOR_LABELS,
  SET_VENDOR_MGT_ATTACHMENTS,
  SET_VENDOR_MGT_DATA,
  SET_VENDOR_MGT_DOCUMENTS,
  SET_VENDOR_SEARCH,
  SET_VENDOR_SUBDOMAINS_STARTED,
  SET_VENDOR_SURVEYS_DATA,
  SET_VENDOR_TECHNOLOGIES,
  SET_VENDOR_TECHNOLOGY_SORTING,
  SET_VENDORS_DATA,
  SET_VULN_DATA,
  SET_VULN_RISKS_IDS_BY_CVE,
  SET_VULNS_BY_CPE,
  SET_WEBHOOK_TEST_RESPONSE,
  SET_WHOIS_DATA,
  SET_WHOIS_OVERRIDE_DATA,
  SET_WHOIS_OVERRIDE_LOADING,
  SWAP_IN_SURVEYTYPE_AUTOMATION_LIST,
  TOGGLE_TOUR,
  TOGGLE_VENDOR_ASSESSMENT_ADDITIONAL_SELECTED,
  TOGGLE_VENDOR_ASSESSMENT_EVIDENCE_PAGE_SELECTED,
  TOGGLE_VENDOR_ASSESSMENT_SURVEY_SELECTED,
  UPDATE_REVERSE_WHOIS_LOOKUP_DATA,
} from "./cyberRiskActions";

import {
  CLEAR_RISK_VENDOR_WEBSITES,
  SET_RISK_VENDOR_WEBSITES,
  SET_RISK_VENDORS,
  SET_VENDOR_PORTFOLIO_KEV_COUNTS,
  SET_VENDOR_PORTFOLIO_RISK_PROFILE,
} from "./vendorRiskPortfolio.actions";
import { CLEAR_EXPORT_SECTIONS, SET_EXPORT_SECTIONS } from "./export.actions";
import {
  SET_CUSTOMER_IP_DETAILS,
  SET_CUSTOMER_IP_RANGE_DOMAINS,
  SET_VENDOR_IP_DETAILS,
  SET_VENDOR_IP_RANGE_DOMAINS,
} from "./ipAddresses.actions";
import {
  SET_SINGLE_VENDOR_DATA_LEAKS_RESULT,
  SET_VENDOR_DATA_LEAKS_RESULT,
} from "./vendorDataLeaks.actions";
import {
  SET_VENDOR_ASSESSMENT_RISK_WAIVERS,
  SET_VENDOR_RISK_WAIVERS,
} from "./vendorRiskWaiver.actions";
import {
  SET_ORG_MFA_STATUS,
  SET_ORG_USER_EMAIL_ADDRESSES,
} from "./org.actions";
import { SET_VENDOR_ASSET_SHARING_SETTINGS } from "./manageVendorAssetSharing.actions";
import { CLEAR_DOMAINS, SET_DOMAINS } from "./domains.actions";
import { SET_GEOLOCATION_VENDORS_BY_COUNTRY } from "./ipGeolocation.actions";
import { getDefaultFilters } from "./defaults";
import {
  SET_SUBSIDIARY_FILTERS,
  SET_VENDOR_ASSET_TYPES,
  SET_VENDOR_FILTERS,
} from "./filters.actions";
import { CLEAR_SUBSIDIARY_DATA } from "./subsidiary.actions";
import { SET_VENDOR_COMPARISON_VENDORS } from "./vendorComparison.actions";
import { SET_VENDOR_TIERS } from "./vendorTiers.actions";
import {
  SET_VENDOR_ASSESSMENT_AUTHORS,
  SET_VENDOR_ASSESSMENT_CLASSIFICATIONS,
} from "./vendorAssessment.actions";
import { SET_QUESTIONNAIRE_ANSWERS } from "./questionnaireAnswers.actions";
import { SET_VENDOR_IMPORT } from "./vendorImport.actions";
import { SET_VENDORS_MINI } from "./vendorFourthParties.actions";
import {
  currentDomainPortfolioLocalStorageKey,
  currentVendorPortfolioLocalStorageKey,
  SET_DOMAIN_PORTFOLIOS,
  SET_VENDOR_PORTFOLIOS,
} from "./portfolios.actions";
import {
  newManagedAssessmentDetailsState,
  SET_ASSESSMENT_REQUEST_VENDOR_INFO,
  SET_MANAGED_ASSESSMENT_DETAILS,
  SET_MANAGED_VENDOR_ASSESSMENTS_LIST,
  SET_MANAGED_VENDOR_LICENSES,
} from "./managedVendors.actions";
import { SET_VENDOR_ATTRIBUTE_DEFINITIONS } from "./vendorAttributes.actions";
import {
  SET_DATA_LEAKS_SCANRESULTS_CUSTOM_DATE_RANGE,
  SET_DATA_LEAKS_SUMMARY_TAB,
} from "./dataLeaks.actions";
import { SET_VENDOR_EVIDENCE_PAGES } from "./vendorEvidencePages.actions";
import {
  SET_CUSTOMER_SURVEYS_DATA_V2,
  SET_RESEND_DUE_SURVEYS,
  SET_VENDOR_SURVEYS_DATA_V2,
} from "./survey.actions";
import {
  SET_ADDITIONAL_EVIDENCE_REQUEST_USERS,
  SET_ORG_ADDITIONAL_EVIDENCE_TYPES,
  SET_VENDOR_ADDITIONAL_EVIDENCE_HISTORY,
} from "./additionalEvidence.actions";
import { SET_VENDOR_MGT_CONTACTS } from "./vendorContacts.actions";
import { SET_VENDOR_FREE_TRIAL_INFO } from "./vendorFreeTrial.actions";
import { getExportSectionsKey } from "./cyberRiskSelectors";
import { ReportType } from "../../_common/types/exportReport";
import { cyberRiskInitialState } from "./cyberRiskReducer.initialState";
import {
  SET_CANNED_EXPORT_CONFIGS,
  SET_STARRED_EXPORT_TYPE,
  SET_STARRED_EXPORT_TYPES,
} from "./cannedExports.actions";
import {
  SET_ORG_VENDOR_MERGE_TAGS,
  SET_SYSTEM_DEFAULT_TEXTS,
} from "./orgDefaultTexts.actions";
import {
  CLEAR_EXPOSURES_HISTORY,
  INCREMENT_COMMENT_COUNT_ON_BREACH,
  SET_ASSIGNEE_FOR_EMAIL_EXPOSURES_BREACH,
  SET_EXPOSURES_HISTORY,
} from "./emailExposures.actions";
import { SET_DETECTED_PRODUCTS } from "./detectedProducts.actions";

export const getHydratedCyberRiskInitialState = () => {
  // Include the paging stored in localStorage if it exists
  const pagingFromLocalStore = getLocalStorageItem("vendorsPaging");
  const paging = pagingFromLocalStore.sortBy
    ? pagingFromLocalStore
    : cyberRiskInitialState.customerData.vendors.paging;

  const scoring = getLocalStorageItem("cyberRiskScoringPeriods");

  const selectedVendorPortfolioIdStr = getLocalStorageItemString(
    currentVendorPortfolioLocalStorageKey
  );
  let selectedVendorPortfolioIds = [];
  if (selectedVendorPortfolioIdStr) {
    selectedVendorPortfolioIds = [parseInt(selectedVendorPortfolioIdStr)];
  }

  const selectedDomainPortfolioIdStr = getLocalStorageItemString(
    currentDomainPortfolioLocalStorageKey
  );
  let selectedDomainPortfolioIds = [];
  if (selectedDomainPortfolioIdStr) {
    selectedDomainPortfolioIds = [parseInt(selectedDomainPortfolioIdStr)];
  }

  return {
    ...cyberRiskInitialState,
    scoring,
    customerData: {
      ...cyberRiskInitialState.customerData,
      filters: {
        ...cyberRiskInitialState.customerData.filters,
        portfolioIds: selectedVendorPortfolioIds,
        domainPortfolioIds: selectedDomainPortfolioIds,
      },
      vendors: {
        ...cyberRiskInitialState.customerData.vendors,
        paging: {
          ...cyberRiskInitialState.customerData.vendors.paging,
          ...paging,
        },
      },
    },
  };
};

const hydratedInitialState = getHydratedCyberRiskInitialState();

/**
 * @param state {ICyberRiskState}
 * @param action {object}
 * */
function cyberRiskReducer(state = hydratedInitialState, action) {
  switch (action.type) {
    case SET_TOUR_STEPS:
      return {
        ...state,
        tour: {
          ...state.tour,
          tourSteps: action.tourSteps,
        },
      };
    case TOGGLE_TOUR:
      return {
        ...state,
        tour: {
          ...state.tour,
          enabled: action.enabled,
        },
      };
    case SET_CUSTOMER_DATA:
      return {
        ...state,
        customerData: {
          ...state.customerData,
          ...action.data,
        },
      };
    case CLEAR_ALL_DATA:
      return {
        ...cyberRiskInitialState,
      };
    case CLEAR_CUSTOMER_DATA:
      return {
        ...state,
        customerData: { ...cyberRiskInitialState.customerData },
        changes: { ...cyberRiskInitialState.changes },
        reports: {
          [ReportType.ReportTypeBreachSightGeolocation]: undefined,
          [ReportType.ReportTypeBreachSightSubsidiariesGeolocation]: undefined,
          [ReportType.ReportTypeVendorRiskGeolocation]: undefined,
          [ReportType.ReportTypeBreachSightCompetitorAnalysis]: undefined,
          [ReportType.ReportTypeVendorRiskRiskMatrix]: undefined,
        },
        reportConfig: null,
      };
    case CLEAR_VENDORS_DATA:
      return {
        ...state,
        vendors: {},
      };
    case SET_CUSTOMER_DATA_FILTERS:
      return {
        ...state,
        customerData: {
          ...state.customerData,
          filters: {
            ...state.customerData.filters,
            ...action.filters,
          },
        },
      };
    case RESET_VENDORS_PAGE_NUM:
      return {
        ...state,
        customerData: {
          ...state.customerData,
          vendors: {
            ...state.customerData.vendors,
            paging: {
              ...state.customerData.vendors.paging,
              pageNum: 1,
            },
          },
        },
      };
    case SET_CUSTOMER_DATA_FILTER_OPTIONS:
      return {
        ...state,
        customerData: {
          ...state.customerData,
          availableFilterOptions: {
            ...state.customerData.availableFilterOptions,
            ...action.filterOptions,
          },
        },
      };
    case SET_VENDOR_SURVEYS_DATA: {
      const isManagementAnalystSession =
        action.tpvmSession && action.tpvmSession.tpvm;
      const managedOrgId = action.tpvmSession ? action.tpvmSession.tpvm_o : 0;

      if (isManagementAnalystSession) {
        const existingOrgData = state.managedVendorData[managedOrgId]
          ? state.managedVendorData[managedOrgId]
          : {};

        return {
          ...state,
          managedVendorData: {
            ...state.managedVendorData,
            [managedOrgId]: {
              ...existingOrgData,
              [action.vendorId]: {
                ...existingOrgData[action.vendorId],
                surveys: {
                  ...(existingOrgData[action.vendorId]
                    ? existingOrgData[action.vendorId].surveys
                    : {}),
                  [action.usageType]: action.data,
                },
              },
            },
          },
        };
      }

      return {
        ...state,
        vendors: {
          ...state.vendors,
          [action.vendorId]: {
            ...state.vendors[action.vendorId],
            surveys: {
              ...(state.vendors[action.vendorId]
                ? state.vendors[action.vendorId].surveys
                : {}),
              [action.usageType]: action.data,
            },
          },
        },
      };
    }

    case SET_VENDOR_SURVEYS_DATA_V2:
      const isManagementAnalystSession =
        action.tpvmSession && action.tpvmSession.tpvm;
      const managedOrgId = action.tpvmSession ? action.tpvmSession.tpvm_o : 0;

      if (isManagementAnalystSession) {
        const existingOrgData = state.managedVendorData[managedOrgId]
          ? state.managedVendorData[managedOrgId]
          : {};

        return {
          ...state,
          managedVendorData: {
            ...state.managedVendorData,
            [managedOrgId]: {
              ...existingOrgData,
              [action.vendorId]: {
                ...existingOrgData[action.vendorId],
                surveyList: {
                  ...(existingOrgData[action.vendorId]
                    ? existingOrgData[action.vendorId].surveyList
                    : {}),
                  ...action.data,
                },
              },
            },
          },
        };
      }

      return {
        ...state,
        vendors: {
          ...state.vendors,
          [action.vendorId]: {
            ...state.vendors[action.vendorId],
            surveyList: {
              ...(state.vendors[action.vendorId]
                ? state.vendors[action.vendorId].surveyList
                : {}),
              ...action.data,
            },
          },
        },
      };

    case SET_VENDOR_DATA:
      if (action.isSubsidiary) {
        return {
          ...state,
          subsidiaries: {
            ...state.subsidiaries,
            [action.vendorId]: {
              filters: getDefaultFilters(),
              ...state.subsidiaries[action.vendorId],
              ...action.data,
            },
          },
        };
      }
      if (action.isManagementAnalystSession) {
        const existingOrgData = state.managedVendorData[action.managedOrgId]
          ? state.managedVendorData[action.managedOrgId]
          : {};
        return {
          ...state,
          managedVendorData: {
            ...state.managedVendorData,
            [action.managedOrgId]: {
              ...existingOrgData,
              name: action.orgName ? action.orgName : existingOrgData.name,
              entitlements: !!action.orgEntitlements
                ? action.orgEntitlements
                : !!existingOrgData.entitlements
                  ? existingOrgData.entitlements
                  : [],
              groupEntitlements: !!action.orgGroupEntitlements
                ? action.orgGroupEntitlements
                : !!existingOrgData.groupEntitlements
                  ? existingOrgData.groupEntitlements
                  : [],
              [action.vendorId]: {
                ...existingOrgData[action.vendorId],
                ...action.data,
              },
            },
          },
        };
      }
      return {
        ...state,
        vendors: {
          ...state.vendors,
          [action.vendorId]: {
            filters: getDefaultFilters(),
            ...state.vendors[action.vendorId],
            ...action.data,
          },
        },
      };

    case SET_VENDOR_LABELS:
      return {
        ...state,
        vendors: {
          ...state.vendors,
          [action.vendorId]: {
            ...state.vendors[action.vendorId],
            labels: action.labels,
          },
        },
      };
    case SET_VENDOR_TECHNOLOGIES:
      if (action.isManagementAnalystSession) {
        let existingOrgData = state.managedVendorData[action.managedOrgId];
        if (!existingOrgData) {
          existingOrgData = {};
        }
        return {
          ...state,
          managedVendorData: {
            ...state.managedVendorData,
            [action.managedOrgId]: {
              ...existingOrgData,
              [action.vendorId]: {
                ...state.managedVendorData[action.managedOrgId][
                  action.vendorId
                ],
                technologies: action.technologies,
              },
            },
          },
        };
      }
      return {
        ...state,
        vendors: {
          ...state.vendors,
          [action.vendorId]: {
            ...state.vendors[action.vendorId],
            technologies: action.technologies,
          },
        },
      };
    case SET_VENDOR_TECHNOLOGY_SORTING:
      if (action.isManagementAnalystSession) {
        let existingOrgData = state.managedVendorData[action.managedOrgId];
        if (!existingOrgData) {
          existingOrgData = {};
        }
        return {
          ...state,
          managedVendorData: {
            ...state.managedVendorData,
            [action.managedOrgId]: {
              ...existingOrgData,
              [action.vendorId]: {
                ...state.managedVendorData[action.managedOrgId][
                  action.vendorId
                ],
                technologySorting: action.technologySorting,
              },
            },
          },
        };
      }
      return {
        ...state,
        vendors: {
          ...state.vendors,
          [action.vendorId]: {
            ...state.vendors[action.vendorId],
            technologySorting: action.technologySorting,
          },
        },
      };
    case SET_VENDOR_FILTER_OPTIONS:
      let currentVendorData;

      if (action.isSubsidiary) {
        currentVendorData = state.subsidiaries[action.vendorId];
      } else if (action.isManagementAnalystSession) {
        currentVendorData = _get(
          state,
          `managedVendorData.${action.managedOrgId}.${action.vendorId}`,
          null
        );
      } else {
        currentVendorData = state.vendors[action.vendorId];
      }

      if (!currentVendorData) {
        currentVendorData = {};
      }

      if (action.isSubsidiary) {
        return {
          ...state,
          subsidiaries: {
            ...state.subsidiaries,
            [action.vendorId]: {
              ...currentVendorData,
              availableFilterOptions: {
                ...currentVendorData.availableFilterOptions,
                ...action.filterOptions,
              },
            },
          },
        };
      }

      if (action.isManagementAnalystSession) {
        let existingOrgData = state.managedVendorData[action.managedOrgId];
        if (!existingOrgData) {
          existingOrgData = {};
        }
        return {
          ...state,
          managedVendorData: {
            ...state.managedVendorData,
            [action.managedOrgId]: {
              ...existingOrgData,
              [action.vendorId]: {
                ...currentVendorData,
                availableFilterOptions: {
                  ...currentVendorData.availableFilterOptions,
                  ...action.filterOptions,
                },
              },
            },
          },
        };
      }

      return {
        ...state,
        vendors: {
          ...state.vendors,
          [action.vendorId]: {
            ...currentVendorData,
            availableFilterOptions: {
              ...currentVendorData.availableFilterOptions,
              ...action.filterOptions,
            },
          },
        },
      };
    case SET_VENDOR_MGT_DATA:
      if (action.isManagementAnalystSession) {
        const existingOrgData = state.managedVendorData[action.managedOrgId];
        return {
          ...state,
          managedVendorData: {
            ...state.managedVendorData,
            [action.managedOrgId]: {
              ...existingOrgData,
              [action.vendorId]: {
                ...existingOrgData[action.vendorId],
                mgtlists: action.data.mgtlists,
              },
            },
          },
        };
      } else {
        return {
          ...state,
          vendors: {
            ...state.vendors,
            [action.vendorId]: {
              ...state.vendors[action.vendorId],
              mgtlists: action.data.mgtlists,
            },
          },
        };
      }
    case SET_VENDOR_MGT_DOCUMENTS:
      if (action.isManagementAnalystSession) {
        const existingOrgData = state.managedVendorData[action.managedOrgId];
        return {
          ...state,
          managedVendorData: {
            ...state.managedVendorData,
            [action.managedOrgId]: {
              ...existingOrgData,
              [action.vendorId]: {
                ...existingOrgData[action.vendorId],
                mgtlists: {
                  ...existingOrgData[action.vendorId].mgtlists,
                  allDocuments: action.data.documents,
                },
              },
            },
          },
        };
      } else {
        const mgtlists = state.vendors[action.vendorId]
          ? state.vendors[action.vendorId].mgtlists
          : {};
        return {
          ...state,
          vendors: {
            ...state.vendors,
            [action.vendorId]: {
              ...state.vendors[action.vendorId],
              mgtlists: {
                ...mgtlists,
                allDocuments: action.data.documents,
              },
            },
          },
        };
      }
    case SET_VENDOR_MGT_ATTACHMENTS:
      const mgtlists = state.vendors[action.vendorId]
        ? state.vendors[action.vendorId].mgtlists
        : {};
      return {
        ...state,
        vendors: {
          ...state.vendors,
          [action.vendorId]: {
            ...state.vendors[action.vendorId],
            mgtlists: {
              ...mgtlists,
              attachments: action.data.attachments,
            },
          },
        },
      };
    case SET_VENDOR_MGT_CONTACTS:
      if (action.isManagementAnalystSession) {
        const existingOrgData =
          state.managedVendorData[action.managedOrgId] ?? {};
        return {
          ...state,
          managedVendorData: {
            ...state.managedVendorData,
            [action.managedOrgId]: {
              ...existingOrgData,
              [action.vendorId]: {
                ...existingOrgData[action.vendorId],
                mgtlists: {
                  ...existingOrgData[action.vendorId]?.mgtlists,
                  contacts: action.data.contacts,
                },
              },
            },
          },
        };
      } else {
        const mgtlists = state.vendors[action.vendorId]
          ? state.vendors[action.vendorId].mgtlists
          : {};

        return {
          ...state,
          vendors: {
            ...state.vendors,
            [action.vendorId]: {
              ...state.vendors[action.vendorId],
              mgtlists: {
                ...mgtlists,
                contacts: action.data.contacts,
              },
            },
          },
        };
      }
    case SET_VENDORS_DATA:
      return {
        ...state,
        vendors: merge(action.vendors, state.vendors),
      };
    case SET_DOMAINS:
      const newDomains = {
        ...state.domains,
      };

      if (action.domains) {
        newDomains[action.key] = action.domains;
      } else {
        delete newDomains[action.key];
      }

      return {
        ...state,
        domains: newDomains,
      };
    case CLEAR_DOMAINS:
      return {
        ...state,
        domains: {},
        riskVendorWebsites: {},
        webscans: {},
        customerData: {
          ...state.customerData,
          riskHostnames: {},
        },
      };
    case SET_CLOUDSCAN_DATA:
      return {
        ...state,
        webscans: {
          ...state.webscans,
          [action.webscanId]: {
            ...state.webscans[action.webscanId],
            ...action.data,
          },
        },
      };
    case SET_CLOUDSCAN_ERROR:
      return {
        ...state,
        webscans: {
          ...state.webscans,
          [action.webscanId]: {
            ...state.webscans[action.webscanId],
            result: {
              ...state.webscans[action.webscanId].result,
              ...action.data,
            },
          },
        },
      };
    case CLEAR_CLOUDSCAN_DATA:
      return {
        ...state,
        webscans: {},
      };
    case SET_VULN_DATA:
      return {
        ...state,
        vulns: {
          ...state.vulns,
          [action.cveName]: action.data,
        },
      };
    case SET_VULN_RISKS_IDS_BY_CVE:
      return {
        ...state,
        vulnRiskIDsByCVE: {
          ...state.vulnRiskIDsByCVE,
          [action.cveName]: action.data,
        },
      };
    case SET_VULNS_BY_CPE:
      return {
        ...state,
        vulnsByCPE: {
          ...state.vulnsByCPE,
          [action.cpeName]: action.data,
        },
      };
    case SET_CUSTOMER_VULNS_BY_CPE_BY_HOSTNAMES:
      return {
        ...state,
        customerVulnsByCPEByHostnames: {
          ...state.customerVulnsByCPEByHostnames,
          [action.cpeName]: {
            ...state.customerVulnsByCPEByHostnames[action.cpeName],
            [action.hostsHash]: action.data,
          },
        },
      };
    case RESET_CUSTOMER_VULNS_BY_CPE_BY_HOSTNAMES:
      return {
        ...state,
        customerVulnsByCPEByHostnames: {},
      };
    case SET_TABLE_SORT:
      return {
        ...state,
        tableSorts: {
          ...state.tableSorts,
          [action.tableName]: action.sortObj,
        },
      };
    case SET_TABLE_PAGE:
      return {
        ...state,
        tablePages: {
          ...state.tablePages,
          [action.tableName]: action.pageIndex,
        },
      };
    case SET_VENDOR_SEARCH:
      return {
        ...state,
        vendorSearch: {
          ...state.vendorSearch,
          ...action.data,
        },
      };
    case SET_SURVEY_TYPES:
      if (action.isManagementAnalystSession && action.managedOrgId > 0) {
        let existingOrgData = state.managedVendorData[action.managedOrgId];
        if (!existingOrgData) {
          existingOrgData = {
            surveyTypes: action.surveyTypes,
          };
        }
        return {
          ...state,
          managedVendorData: {
            ...state.managedVendorData,
            [action.managedOrgId]: {
              ...existingOrgData,
              surveyTypes: {
                ...existingOrgData.surveyTypes,
                ...action.surveyTypes,
              },
              surveyRiskVisibility: action.surveyRiskVisibility,
            },
          },
        };
      } else {
        return {
          ...state,
          customerData: {
            ...state.customerData,
            surveyTypes: {
              ...state.customerData.surveyTypes,
              ...action.surveyTypes,
            },
            surveyRiskVisibility: action.surveyRiskVisibility,
          },
        };
      }
    case SET_PLANS:
      return {
        ...state,
        plans: action.plans,
      };
    case SET_ORG_API:
      return {
        ...state,
        orgApi: action.orgApi,
      };
    case SET_ORG_USERS:
      return {
        ...state,
        orgUsers: {
          ...state.orgUsers,
          ...action.orgUsers,
        },
      };
    case SET_ORG_USER_EMAIL_ADDRESSES:
      return {
        ...state,
        orgUserEmailAddresses: action.orgUserEmails,
      };
    case SET_ORG_USER_INVITES:
      return {
        ...state,
        orgUserInvites: {
          ...state.orgUserInvites,
          ...action.orgUserInvites,
        },
      };
    case SET_AVAILABLE_LABELS:
      return {
        ...state,
        availableLabels: action.labels,
      };
    case SET_CHANGES_ACTIVE: {
      let overrides = {};
      if (state.changes.active && !action.active) {
        // If going inactive, clear the data we have (but keep the startDate stuff)
        overrides = {
          endDate: null,
          endDateIndex: null,
          data: null,
          loading: null,
        };
      }
      return {
        ...state,
        changes: {
          ...state.changes,
          ...overrides,
          active: action.active,
        },
      };
    }
    case SET_CHANGES_START:
      return {
        ...state,
        changes: {
          ...state.changes,
          startDate: action.startDate,
          startDateIndex: action.startDateIndex,
        },
      };
    case SET_CHANGES_END:
      if (action.endDateIndex === state.changes.startDateIndex) {
        // Don't set it if it's the same as the start index
        return state;
      }
      return {
        ...state,
        changes: {
          ...state.changes,
          endDate: action.endDate,
          endDateIndex: action.endDateIndex,
        },
      };
    case SET_CHANGES_LOADING:
      return {
        ...state,
        changes: {
          ...state.changes,
          loading: action.loading,
        },
      };
    case SET_CHANGES_DATA:
      return {
        ...state,
        changes: {
          ...state.changes,
          data: action.data,
        },
      };
    case SET_SCORING_PERIOD:
      return {
        ...state,
        changes: {
          ...state.changes,
          startDate: null,
          startDateIndex: null,
        },
        scoring: {
          ...state.scoring,
          [action.page]: action.days,
        },
      };
    case SET_EXPOSURES_ACCOUNTS_LOADING:
      return {
        ...state,
        customerData: {
          ...state.customerData,
          exposures: {
            ...state.customerData.exposures,
            accounts_loading: action.loading,
            accounts_context: action.context,
          },
        },
      };
    case SET_EXPOSURES_ACCOUNTS_SEARCHDATA:
      return {
        ...state,
        customerData: {
          ...state.customerData,
          exposures: {
            ...state.customerData.exposures,
            searchData: action.searchData,
          },
        },
      };
    case SET_EXPOSURES_ACCOUNT_DATA:
      return {
        ...state,
        customerData: {
          ...state.customerData,
          exposures: {
            ...state.customerData.exposures,
            account_data: action.data,
          },
        },
      };
    case SET_EXPOSURES_PAGED_ACCOUNT_DATA:
      return {
        ...state,
        customerData: {
          ...state.customerData,
          exposures: {
            ...state.customerData.exposures,
            prepagedAccountData: {
              ...state.customerData.exposures.prepagedAccountData,
              [action.breachID]: {
                ...state.customerData.exposures.prepagedAccountData[
                  action.breachID
                ],
                [action.pageNum]: action.data,
              },
            },
          },
        },
      };
    case CLEAR_EXPOSURES_PAGED_ACCOUNT_DATA:
      if (action.breachID >= 0) {
        // breachID has been specified. only clear page data for that breach
        return {
          ...state,
          customerData: {
            ...state.customerData,
            exposures: {
              ...state.customerData.exposures,
              prepagedAccountData: {
                ...state.customerData.exposures.prepagedAccountData,
                [action.breachID]: {},
              },
            },
          },
        };
      } else if (action.breachID == -1) {
        // clear everything *except* breach 0 (the non-breach specific results)
        let zeroBreach = state.customerData.exposures.prepagedAccountData
          ? state.customerData.exposures.prepagedAccountData[0]
          : {};
        return {
          ...state,
          customerData: {
            ...state.customerData,
            exposures: {
              ...state.customerData.exposures,
              prepagedAccountData: {
                [0]: zeroBreach,
              },
            },
          },
        };
      } else {
        // breachID is undefined - clear everything!
        return {
          ...state,
          customerData: {
            ...state.customerData,
            exposures: {
              ...state.customerData.exposures,
              prepagedAccountData: {},
            },
          },
        };
      }
    case SET_EXPOSURES_MONITORED_DOMAINS:
      return {
        ...state,
        customerData: {
          ...state.customerData,
          exposures: {
            ...state.customerData.exposures,
            monitoredDomains: action.domains,
          },
        },
      };
    case SET_EXPOSURES_AVAILABLE_ASSIGNEES:
      return {
        ...state,
        customerData: {
          ...state.customerData,
          exposures: {
            ...state.customerData.exposures,
            availableAssignees: action.availableAssignees,
          },
        },
      };
    case SET_EXPOSURES_PAGE_DETAILS:
      let sorting = {
        prepagedSortedBy: action.sortedBy,
        prepagedSortedDesc: action.sortedDesc,
      };
      if (action.breachID > 0) {
        sorting = {
          breachAccountsSortedBy: action.sortedBy,
          breachAccountsSortedDesc: action.sortedDesc,
        };
      }
      return {
        ...state,
        customerData: {
          ...state.customerData,
          exposures: {
            ...state.customerData.exposures,
            prepagedAccountData: {
              ...state.customerData.exposures.prepagedAccountData,
              [action.breachID]: {
                ...state.customerData.exposures.prepagedAccountData[
                  action.breachID
                ],
                totalNumber: action.totalCount,
                totalVip: action.totalVip,
                totalNumberNotNotified: action.totalNotNotifiedCount,
              },
            },
            ...sorting,
            prepagedPageNumber: action.pageNum,
            prepagedBreachID: action.breachID,
          },
        },
      };
    case SET_EXPOSURES_PAGE_NUM:
      return {
        ...state,
        customerData: {
          ...state.customerData,
          exposures: {
            ...state.customerData.exposures,
            prepagedPageNumber: action.pageNum,
          },
        },
      };
    case SET_EXPOSURES_BREACH_DATA:
      return {
        ...state,
        customerData: {
          ...state.customerData,
          exposures: {
            ...state.customerData.exposures,
            breach_data: action.data,
            breach_assignee_shared_users: action.assigneeSharedUsers,
          },
        },
      };
    case SET_EXPOSURES_VIP_ACCOUNT_DATA:
      return {
        ...state,
        customerData: {
          ...state.customerData,
          exposures: {
            ...state.customerData.exposures,
            vip_account_data: action.data,
          },
        },
      };
    case SET_EXPOSURE_NOTIFICATION_STATUS:
      return {
        ...state,
        customerData: {
          ...state.customerData,
          exposures: {
            ...state.customerData.exposures,
            notificationStatuses: {
              ...state.customerData.exposures.notificationStatuses,
              [action.breachId]: action.status,
            },
          },
        },
      };
    case SET_ASSIGNEE_FOR_EMAIL_EXPOSURES_BREACH: {
      const newExposures = produce(
        state.customerData.exposures,
        (exposuresData) => {
          // Find the breach and update the assignee
          for (let i = 0; i < exposuresData.breach_data.length; i++) {
            if (exposuresData.breach_data[i].ID === action.breachId) {
              exposuresData.breach_data[i].AssigneeUserID = action.assigneeUser
                ? action.assigneeUser.id
                : null;
              break;
            }
          }

          // Also ensure this user is reflected in the breach_assignee_shared_users map
          if (action.assigneeUser) {
            exposuresData.breach_assignee_shared_users[action.assigneeUser.id] =
              action.assigneeUser;
          }
        }
      );

      return {
        ...state,
        customerData: {
          ...state.customerData,
          exposures: newExposures,
        },
      };
    }
    case SET_EXPOSURES_HISTORY:
      return {
        ...state,
        customerData: {
          ...state.customerData,
          exposures: {
            ...state.customerData.exposures,
            breachHistory: {
              ...state.customerData.exposures.breachHistory,
              [action.breachId]: action.history,
            },
          },
        },
      };
    case INCREMENT_COMMENT_COUNT_ON_BREACH: {
      const newExposures = produce(
        state.customerData.exposures,
        (exposuresData) => {
          // Find the breach and update the comment count
          for (let i = 0; i < exposuresData.breach_data.length; i++) {
            if (exposuresData.breach_data[i].ID === action.breachId) {
              exposuresData.breach_data[i].NumComments += 1;
              break;
            }
          }
        }
      );

      return {
        ...state,
        customerData: {
          ...state.customerData,
          exposures: newExposures,
        },
      };
    }
    case CLEAR_EXPOSURES_HISTORY:
      return {
        ...state,
        customerData: {
          ...state.customerData,
          exposures: {
            ...state.customerData.exposures,
            breachHistory: {},
          },
        },
      };
    case SET_CUSTOMERDATA_SURVEYS:
      return {
        ...state,
        customerData: {
          ...state.customerData,
          surveys: {
            ...state.customerData.surveys,
            [action.usageType]: action.result,
          },
        },
      };
    case SET_CUSTOMER_SURVEYS_DATA_V2:
      return {
        ...state,
        customerData: {
          ...state.customerData,
          surveyList: {
            ...state.customerData.surveyList,
            ...action.data,
          },
        },
      };
    case SET_RESEND_DUE_SURVEYS:
      if (!action.vendorId) {
        return {
          ...state,
          customerData: {
            ...state.customerData,
            resendDueSurveys: action.result,
          },
        };
      } else {
        const isManagementAnalystSession =
          action.tpvmSession && action.tpvmSession.tpvm;
        const managedOrgId = action.tpvmSession ? action.tpvmSession.tpvm_o : 0;

        if (isManagementAnalystSession) {
          const existingOrgData = state.managedVendorData[managedOrgId]
            ? state.managedVendorData[managedOrgId]
            : {};

          return {
            ...state,
            managedVendorData: {
              ...state.managedVendorData,
              [managedOrgId]: {
                ...existingOrgData,
                [action.vendorId]: {
                  ...existingOrgData[action.vendorId],
                  resendDueSurveys: action.result,
                },
              },
            },
          };
        }

        return {
          ...state,
          vendors: {
            ...state.vendors,
            [action.vendorId]: {
              ...state.vendors[action.vendorId],
              resendDueSurveys: action.result,
            },
          },
        };
      }

    case SET_VENDOR_BREAKDOWN_DATA:
      return {
        ...state,
        customerData: {
          ...state.customerData,
          vendorBreakdown: action.vendorBreakdown,
        },
      };
    case SET_ORG_INTEGRATIONS_SETTINGS:
      return {
        ...state,
        orgIntegrationSettings: {
          ...action.settings,
        },
      };
    case SET_ORG_NOTIFICATIONS_SETTINGS:
      return {
        ...state,
        orgNotificationSettings: {
          ...action.settings,
        },
      };
    case CLEAR_ORG_NOTIFICATIONS_SETTINGS:
      return {
        ...state,
        orgNotificationSettings: null,
      };
    case SET_USER_NOTIFICATIONS_SETTINGS:
      return {
        ...state,
        userNotificationSettings: action.settings,
      };
    case SET_ALL_ORG_NOTIFICATIONS:
      return {
        ...state,
        allOrgNotifications: action.settings,
      };
    case REMOVE_REMEDIATION_RISK_ID: {
      const remediationRiskIds = [
        ...state.vendors[action.vendorId].remediationRiskIds,
      ];
      const idx = remediationRiskIds.indexOf(action.riskId);
      remediationRiskIds.splice(idx, 1);
      return {
        ...state,
        vendors: {
          ...state.vendors,
          [action.vendorId]: {
            ...state.vendors[action.vendorId],
            remediationRiskIds,
          },
        },
      };
    }
    case SET_ORGANISATION_FLAGS: {
      return {
        ...state,
        orgFlags: {
          ...state.orgFlags,
          ...action.orgFlags,
        },
      };
    }
    case SET_WEBHOOK_TEST_RESPONSE:
      return {
        ...state,
        webhookTestResponse: action.response,
      };

    case SET_TYPOSQUAT_HISTORY_DATA:
      return {
        ...state,
        customerData: {
          ...state.customerData,
          typosquat_history: {
            ...state.customerData.typosquat_history,
            [action.domain]: action.data,
          },
        },
      };
    case SET_TYPOSQUAT_DOMAIN_DIRTY:
      return {
        ...state,
        customerData: {
          ...state.customerData,
          typosquat: {
            ...state.customerData.typosquat,
            results: {
              ...state.customerData.typosquat.results,
              domains: state.customerData.typosquat.results.domains.map((d) => {
                if (d.hostname === action.hostname) {
                  d.isDirty = action.isDirty;
                }
                return d;
              }),
            },
          },
        },
      };
    case SET_REPORTS_CONFIG:
      return {
        ...state,
        reportConfig: action.data,
      };
    case SET_REPORTS_DATA:
      return {
        ...state,
        reports: {
          ...state.reports,
          [action.reportType]: action.data,
        },
      };
    case RESET_REPORTS_DATA:
      return {
        ...state,
        reportConfig: null,
        reports: {},
      };
    case SET_WHOIS_OVERRIDE_DATA:
      return {
        ...state,
        whoisOverride: {
          ...state.whoisOverride,
          [action.hostname]: action.data,
        },
      };
    case SET_WHOIS_OVERRIDE_LOADING:
      return {
        ...state,
        whoisOverride: {
          ...state.whoisOverride,
          [action.hostname]: {
            ...state.whoisOverride[action.hostname],
            loading: action.loading,
            error: null,
          },
        },
      };
    case REPLACE_WHOIS_OVERRIDE_DATA:
      return {
        ...state,
        whoisOverride: {
          ...state.whoisOverride,
          [action.hostname]: {
            ...state.whoisOverride[action.hostname],
            data: {
              ...state.whoisOverride[action.hostname].data,
              override: action.data,
            },
          },
        },
      };
    case SET_WHOIS_DATA:
      return {
        ...state,
        whoisData: {
          ...state.whoisData,
          [action.hostname]: action.data,
        },
      };
    case SET_REVERSE_WHOIS_LOOKUP_DATA:
      return {
        ...state,
        reverseWhoisResults: {
          ...state.reverseWhoisResults,
          [action.hostname]: action.data,
        },
      };
    case SET_REVERSE_WHOIS_LOOKUP_LOADING:
      return {
        ...state,
        reverseWhoisResults: {
          ...state.reverseWhoisResults,
          [action.hostname]: {
            ...state.reverseWhoisResults[action.hostname],
            loading: action.loading,
            disabled: action.loading,
          },
        },
      };
    case REMOVE_REVERSE_WHOIS_LOOKUP_DATA:
      let existingDomains =
        state.reverseWhoisResults[action.hostname].searchResults.domains || [];
      existingDomains = existingDomains.filter((d) => {
        //return !action.removedHostnames.includes(d.domain);
        if (action.removedHostnames.includes(d.domain)) {
          d.processed = true;
          d.vendorPrimaryHostname = action.selectedVendorDomain;
          d.datastoreVendorID = action.selectedVendorId;
          d.vendorName = action.selectedVendorName;
          d.watched = false;
          d.customer = false;
          d.isInSubsidiaryHierarchy =
            action.hostname != action.selectedVendorDomain
              ? true
              : !!state.reverseWhoisResults[action.hostname]
                  .subsidiaryHierarchy;
        }
        return d;
      });
      return {
        ...state,
        reverseWhoisResults: {
          ...state.reverseWhoisResults,
          [action.hostname]: {
            ...state.reverseWhoisResults[action.hostname],
            searchResults: {
              ...state.reverseWhoisResults[action.hostname].searchResults,
              domains: existingDomains,
            },
          },
        },
      };
    case UPDATE_REVERSE_WHOIS_LOOKUP_DATA:
      existingDomains =
        state.reverseWhoisResults[action.hostname].searchResults.domains || [];
      existingDomains = existingDomains.map((d) => {
        if (action.updatedHostnames.includes(d.domain)) {
          d.vendorName = action.newVendorDetails.vendorName;
          d.datastoreVendorID = action.newVendorDetails.datastoreVendorID;
          d.customer = action.newVendorDetails.customer;
          d.watched = action.newVendorDetails.watched;
          d.isInSubsidiaryHierarchy =
            action.newVendorDetails.isInSubsidiaryHierarchy;
          d.placeholder = false;
        }
        return d;
      });
      return {
        ...state,
        reverseWhoisResults: {
          ...state.reverseWhoisResults,
          [action.hostname]: {
            ...state.reverseWhoisResults[action.hostname],
            searchResults: {
              ...state.reverseWhoisResults[action.hostname].searchResults,
              domains: existingDomains,
            },
          },
        },
      };

    case TOGGLE_VENDOR_ASSESSMENT_SURVEY_SELECTED:
      if (action.isManagementAnalystSession) {
        let existingEvidence;
        let existingOrgData = state.managedVendorData[action.managedOrgId];
        if (!existingOrgData) {
          existingOrgData = {};
        }
        existingEvidence = _get(
          existingOrgData,
          `[${action.vendorId}].assessment.data.evidence`,
          { surveys: [] }
        );
        const existingSurveys = action.shared
          ? existingEvidence[action.idx].publicSurveys
          : existingEvidence[action.idx].surveys;

        const surveys = existingSurveys.map((s) => ({
          ...s,
          selected: action.surveyId === s.id ? !s.selected : s.selected,
        }));

        const evidenceState = action.shared
          ? {
              publicSurveys: surveys,
            }
          : {
              surveys,
            };

        return {
          ...state,
          managedVendorData: {
            ...state.managedVendorData,
            [action.managedOrgId]: {
              ...existingOrgData,
              [action.vendorId]: {
                ...existingOrgData[action.vendorId],
                assessment: {
                  ...existingOrgData[action.vendorId].assessment,
                  data: {
                    ...existingOrgData[action.vendorId].assessment.data,
                    evidence: {
                      ...existingEvidence,
                      [action.idx]: {
                        ...existingEvidence[action.idx],
                        ...evidenceState,
                      },
                    },
                  },
                },
              },
            },
          },
        };
      } else {
        const existingEvidence =
          state.vendors[action.vendorId].assessment.data.evidence;

        const existingSurveys = action.shared
          ? existingEvidence[action.idx].publicSurveys
          : existingEvidence[action.idx].surveys;

        const surveys = existingSurveys.map((s) => ({
          ...s,
          selected: action.surveyId === s.id ? !s.selected : s.selected,
        }));

        const evidenceState = action.shared
          ? {
              publicSurveys: surveys,
            }
          : {
              surveys,
            };

        return {
          ...state,
          vendors: {
            ...state.vendors,
            [action.vendorId]: {
              ...state.vendors[action.vendorId],
              assessment: {
                ...state.vendors[action.vendorId].assessment,
                data: {
                  ...state.vendors[action.vendorId].assessment.data,
                  evidence: {
                    ...existingEvidence,
                    [action.idx]: {
                      ...existingEvidence[action.idx],
                      ...evidenceState,
                    },
                  },
                },
              },
            },
          },
        };
      }

    case TOGGLE_VENDOR_ASSESSMENT_ADDITIONAL_SELECTED:
      if (action.isManagementAnalystSession) {
        let existingEvidence;
        let existingOrgData = state.managedVendorData[action.managedOrgId];
        if (!existingOrgData) {
          existingOrgData = {};
        }
        existingEvidence = _get(
          existingOrgData,
          `[${action.vendorId}].assessment.data.evidence`,
          { additional: [] }
        );
        const existingAdditional = action.shared
          ? existingEvidence[action.idx].publicAdditional
          : existingEvidence[action.idx].additional;

        const additional = existingAdditional.map((a) => ({
          ...a,
          selected: action.evidenceId === a.id ? !a.selected : a.selected,
        }));

        const evidenceState = action.shared
          ? {
              publicAdditional: additional,
            }
          : {
              additional,
            };

        return {
          ...state,
          managedVendorData: {
            ...state.managedVendorData,
            [action.managedOrgId]: {
              ...existingOrgData,
              [action.vendorId]: {
                ...existingOrgData[action.vendorId],
                assessment: {
                  ...existingOrgData[action.vendorId].assessment,
                  data: {
                    ...existingOrgData[action.vendorId].assessment.data,
                    evidence: {
                      ...existingEvidence,
                      [action.idx]: {
                        ...existingEvidence[action.idx],
                        ...evidenceState,
                      },
                    },
                  },
                },
              },
            },
          },
        };
      } else {
        const existingEvidence =
          state.vendors[action.vendorId].assessment.data.evidence;
        const existingAdditional = action.shared
          ? existingEvidence[action.idx].publicAdditional
          : existingEvidence[action.idx].additional;

        const additional = existingAdditional.map((a) => ({
          ...a,
          selected: action.evidenceId === a.id ? !a.selected : a.selected,
        }));

        const evidenceState = action.shared
          ? {
              publicAdditional: additional,
            }
          : {
              additional,
            };

        return {
          ...state,
          vendors: {
            ...state.vendors,
            [action.vendorId]: {
              ...state.vendors[action.vendorId],
              assessment: {
                ...state.vendors[action.vendorId].assessment,
                data: {
                  ...state.vendors[action.vendorId].assessment.data,
                  evidence: {
                    ...existingEvidence,
                    [action.idx]: {
                      ...existingEvidence[action.idx],
                      ...evidenceState,
                    },
                  },
                },
              },
            },
          },
        };
      }

    case TOGGLE_VENDOR_ASSESSMENT_EVIDENCE_PAGE_SELECTED:
      if (action.isManagementAnalystSession) {
        let existingEvidence;
        let existingOrgData = state.managedVendorData[action.managedOrgId];
        if (!existingOrgData) {
          existingOrgData = {};
        }
        existingEvidence = _get(
          existingOrgData,
          `[${action.vendorId}].assessment.data.evidence`,
          {}
        );
        const existingEvidencePages = existingEvidence[action.idx].pages;

        const evidencePages = existingEvidencePages.map((ev) => ({
          ...ev,
          selected:
            action.category === ev.category &&
            action.url.toLowerCase() === ev.url.toLowerCase()
              ? !ev.selected
              : ev.selected,
        }));

        const evidenceState = { pages: evidencePages };

        return {
          ...state,
          managedVendorData: {
            ...state.managedVendorData,
            [action.managedOrgId]: {
              ...existingOrgData,
              [action.vendorId]: {
                ...existingOrgData[action.vendorId],
                assessment: {
                  ...existingOrgData[action.vendorId].assessment,
                  data: {
                    ...existingOrgData[action.vendorId].assessment.data,
                    evidence: {
                      ...existingEvidence,
                      [action.idx]: {
                        ...existingEvidence[action.idx],
                        ...evidenceState,
                      },
                    },
                  },
                },
              },
            },
          },
        };
      } else {
        const existingEvidence =
          state.vendors[action.vendorId].assessment.data.evidence;
        const existingEvidencePages = existingEvidence[action.idx].pages;

        const evidencePages = existingEvidencePages.map((ev) => ({
          ...ev,
          selected:
            action.category === ev.category &&
            action.url.toLowerCase() === ev.url.toLowerCase()
              ? !ev.selected
              : ev.selected,
        }));

        const evidenceState = { pages: evidencePages };

        return {
          ...state,
          vendors: {
            ...state.vendors,
            [action.vendorId]: {
              ...state.vendors[action.vendorId],
              assessment: {
                ...state.vendors[action.vendorId].assessment,
                data: {
                  ...state.vendors[action.vendorId].assessment.data,
                  evidence: {
                    ...existingEvidence,
                    [action.idx]: {
                      ...existingEvidence[action.idx],
                      ...evidenceState,
                    },
                  },
                },
              },
            },
          },
        };
      }

    case SET_VENDOR_ASSESSMENT_EVIDENCE_DATA:
      let oldSurveys;
      let oldPublicSurveys;
      let oldAdditionalEvidence;
      let oldPublicAdditionalEvidence;
      if (action.isManagementAnalystSession) {
        oldSurveys =
          state.managedVendorData[action.managedOrgId][action.vendorId]
            .assessment.data.evidence[action.idx].surveys;
        oldPublicSurveys =
          state.managedVendorData[action.managedOrgId][action.vendorId]
            .assessment.data.evidence[action.idx].publicSurveys;
        oldAdditionalEvidence =
          state.managedVendorData[action.managedOrgId][action.vendorId]
            .assessment.data.evidence[action.idx].additional;
        oldPublicAdditionalEvidence =
          state.managedVendorData[action.managedOrgId][action.vendorId]
            .assessment.data.evidence[action.idx].publicAdditional;
      } else {
        oldSurveys =
          state.vendors[action.vendorId].assessment.data.evidence[action.idx]
            .surveys;
        oldPublicSurveys =
          state.vendors[action.vendorId].assessment.data.evidence[action.idx]
            .publicSurveys;
        oldAdditionalEvidence =
          state.vendors[action.vendorId].assessment.data.evidence[action.idx]
            .additional;
        oldPublicAdditionalEvidence =
          state.vendors[action.vendorId].assessment.data.evidence[action.idx]
            .publicAdditional;
      }
      if (!oldAdditionalEvidence) {
        oldAdditionalEvidence = [];
      }
      if (!oldPublicAdditionalEvidence) {
        oldPublicAdditionalEvidence = [];
      }
      if (!oldSurveys) {
        oldSurveys = [];
      }
      if (!oldPublicSurveys) {
        oldPublicSurveys = [];
      }

      const newSurveys = oldSurveys.map((s) => ({
        ...s,
        selected: action.selectedSurveys.includes(s.id.toString()),
      }));
      const newPublicSurveys = oldPublicSurveys.map((s) => ({
        ...s,
        selected: action.selectedPublicSurveys.includes(s.id.toString()),
      }));
      const newAdditionalEvidence = oldAdditionalEvidence.map((ae) => ({
        ...ae,
        selected: action.selectedAdditionalEvidence.includes(ae.id.toString()),
      }));
      const newPublicAdditionalEvidence = oldPublicAdditionalEvidence.map(
        (ae) => ({
          ...ae,
          selected: action.selectedPublicAdditionalEvidence.includes(ae.id),
        })
      );
      if (action.isManagementAnalystSession) {
        let existingOrgData = state.managedVendorData[action.managedOrgId];
        if (!existingOrgData) {
          existingOrgData = {};
        }
        const newEvidence = produce(
          state.managedVendorData[action.managedOrgId][action.vendorId]
            .assessment.data.evidence,
          (newEvidence) => {
            newEvidence[action.idx].surveys = newSurveys;
            newEvidence[action.idx].publicSurveys = newPublicSurveys;
            newEvidence[action.idx].additional = newAdditionalEvidence;
            newEvidence[action.idx].publicAdditional =
              newPublicAdditionalEvidence;
          }
        );
        return {
          ...state,
          managedVendorData: {
            ...state.managedVendorData,
            [action.managedOrgId]: {
              ...existingOrgData,
              [action.vendorId]: {
                ...existingOrgData[action.vendorId],
                assessment: {
                  ...existingOrgData[action.vendorId].assessment,
                  data: {
                    ...existingOrgData[action.vendorId].assessment.data,
                    evidence: newEvidence,
                  },
                },
              },
            },
          },
        };
      }

      const newEvidence = produce(
        state.vendors[action.vendorId].assessment.data.evidence,
        (newEvidence) => {
          newEvidence[action.idx].surveys = newSurveys;
          newEvidence[action.idx].publicSurveys = newPublicSurveys;
          newEvidence[action.idx].additional = newAdditionalEvidence;
          newEvidence[action.idx].publicAdditional =
            newPublicAdditionalEvidence;
        }
      );
      return {
        ...state,
        vendors: {
          ...state.vendors,
          [action.vendorId]: {
            ...state.vendors[action.vendorId],
            assessment: {
              ...state.vendors[action.vendorId].assessment,
              data: {
                ...state.vendors[action.vendorId].assessment.data,
                evidence: newEvidence,
              },
            },
          },
        },
      };

    case SET_ALL_VENDORS:
      return {
        ...state,
        allVendors: {
          loading: action.loading,
          error: action.error,
          data: action.data,
        },
      };

    case SET_ALL_VENDOR_RISKS:
      return {
        ...state,
        allVendorRisks: {
          loading: action.loading,
          error: action.error,
          data: action.data,
        },
      };

    // Vendor Portfolio Risk Profile
    case SET_VENDOR_PORTFOLIO_RISK_PROFILE:
      return {
        ...state,
        vendorPortfolioRiskProfile: {
          loading: action.loading,
          error: action.error,
          data: action.data,
        },
      };
    case SET_VENDOR_PORTFOLIO_KEV_COUNTS:
      return {
        ...state,
        vendorPortfolioKEVCounts: {
          loading: action.loading,
          error: action.error,
          counts: action.counts,
        },
      };
    case SET_RISK_VENDORS:
      return {
        ...state,
        riskVendors: {
          ...state.riskVendors,
          [action.riskId]: {
            loading: action.loading,
            error: action.error,
            data: action.data,
          },
        },
      };

    case SET_RISK_VENDOR_WEBSITES:
      return {
        ...state,
        riskVendorWebsites: {
          ...state.riskVendorWebsites,
          [`${action.riskId}/${action.vendorId}/${action.isSubsidiary}/${action.includeWaivers}/${action.skipWaiverId}/${action.filtersHash}`]:
            {
              loading: action.loading,
              error: action.error,
              data: action.data,
            },
        },
      };

    case CLEAR_RISK_VENDOR_WEBSITES:
      const newState = {};

      const searchString = `/${action.vendorId}/${action.isSubsidiary}`;

      Object.keys(state.riskVendorWebsites).forEach((k) => {
        if (!k.endsWith(searchString)) {
          newState[`${k}`] = state.riskVendorWebsites[`${k}`];
        }
      });

      return {
        ...state,
        riskVendorWebsites: newState,
      };

    case SET_CUSTOMER_RISK_HOSTNAMES:
      return {
        ...state,
        customerData: {
          ...state.customerData,
          riskHostnames: {
            ...state.customerData.riskHostnames,
            [action.riskId]: {
              loading: action.loading,
              error: action.error,
              data: action.data,
              websiteLabelIds: action.websiteLabelIds,
              domainPortfolioIds: action.domainPortfolioIds,
              skipWaiverId: action.skipWaiverId,
              includeWaived: action.includeWaived,
              cloudConnectionProviderTypes: action.cloudConnectionProviderTypes,
              cloudConnectionUUIDs: action.cloudConnectionUUIDs,
            },
          },
        },
      };

    case SET_DATA_LEAKS_SUMMARY:
      // We store data leaks summaries grouped by keyword OR by service.
      // They may also be filtered or unfiltered
      // So we have keyword.filtered, keyword.unfiltered, service.filtered and service.unfiltered combinations
      // Each combination has a collection of summaries, which are keyed by date in the format "startDate_endDate"
      // eg "2022-01-01_2022-12-31"
      const category = action.isByService ? "service" : "keyword";
      const subcategory = action.isFiltered ? "filtered" : "unfiltered";

      return {
        ...state,
        dataLeaks: {
          ...state.dataLeaks,
          summaries: {
            ...state.dataLeaks.summaries,
            [category]: {
              ...state.dataLeaks.summaries[category],
              [subcategory]: {
                ...state.dataLeaks.summaries[category][subcategory],
                [action.dateKey]: action.data,
              },
            },
          },
        },
      };

    case SET_DATA_LEAKS_STATISTICS:
      return {
        ...state,
        dataLeakStatistics: {
          ...state.dataLeakStatistics,
          [action.days]: action.data,
        },
      };

    case SET_DATA_LEAKS_SCANRESULTS:
      return {
        ...state,
        dataLeakScanResults: {
          ...state.dataLeakScanResults,
          [action.days]: action.data,
        },
      };
    case SET_DATA_LEAKS_SCANRESULTS_CUSTOM_DATE_RANGE:
      return {
        ...state,
        dataLeakScanResults: {
          ...state.dataLeakScanResults,
          customDateRange: {
            startDate: action.data.startDate,
            endDate: action.data.endDate,
          },
        },
      };
    case SET_DATA_LEAKS_SUMMARY_TAB: {
      const tabStateName =
        action.data.tabType === "keyword" ? "keywordTab" : "serviceTab";

      return {
        ...state,
        dataLeaks: {
          ...state.dataLeaks,
          [tabStateName]: action.data.tab,
        },
      };
    }
    case SET_DATA_LEAKS_SERVICES:
      return {
        ...state,
        dataLeakScanResults: {
          ...state.dataLeakScanResults,
          lookupData: action.data,
        },
      };
    case SET_DATA_LEAKS_TAB:
      return {
        ...state,
        dataLeakStatistics: {
          ...state.dataLeakStatistics,
          tab: action.data.tab,
        },
      };
    case SET_DATA_LEAKS_PAGESORT:
      return {
        ...state,
        dataLeakStatistics: {
          ...state.dataLeakStatistics,
          currentOverviewPage: action.data.pageNum,
          currentOverviewSort: action.data.sortedBy,
        },
      };
    case SET_EVIDENCE_TAB:
      if (action.data.vendorId) {
        if (action.isManagementAnalystSession) {
          const existingOrgData = state.managedVendorData[action.managedOrgId];
          return {
            ...state,
            managedVendorData: {
              ...state.managedVendorData,
              [action.managedOrgId]: {
                ...existingOrgData,
                [action.data.vendorId]: {
                  ...existingOrgData[action.data.vendorId],
                  evidence_tab: action.data.tab,
                },
              },
            },
          };
        }
        return {
          ...state,
          vendors: {
            ...state.vendors,
            [action.data.vendorId]: {
              ...state.vendors[action.data.vendorId],
              evidence_tab: action.data.tab,
            },
          },
        };
      } else {
        return {
          ...state,
          customerData: {
            ...state.customerData,
            evidence_tab: action.data.tab,
          },
        };
      }
    case SET_VENDOR_ADDITIONAL_EVIDENCE:
      if (action.isManagementAnalystSession) {
        const existingOrgData = state.managedVendorData[action.managedOrgId];

        const evidenceList = {
          ...existingOrgData[action.data.vendorId].evidence,
          loading: action.data.loading,
          error: action.data.error,
        };
        if (!action.data.loading && !action.data.error) {
          evidenceList.data = action.data.evidence;
          if (action.data.sharedAdditionalEvidence) {
            evidenceList.sharedAdditionalEvidence =
              action.data.sharedAdditionalEvidence;
          }
        }

        return {
          ...state,
          managedVendorData: {
            ...state.managedVendorData,
            [action.managedOrgId]: {
              ...existingOrgData,
              [action.data.vendorId]: {
                ...existingOrgData[action.data.vendorId],
                evidence: evidenceList,
              },
            },
          },
        };
      }

      const evidenceList = {
        ...(state.vendors[action.data.vendorId]?.evidence ?? {}),
        loading: action.data.loading,
        error: action.data.error,
      };
      if (!action.data.loading && !action.data.error) {
        evidenceList.data = action.data.evidence;
        if (action.data.sharedAdditionalEvidence) {
          evidenceList.sharedAdditionalEvidence =
            action.data.sharedAdditionalEvidence;
        }
      }

      return {
        ...state,
        vendors: {
          ...state.vendors,
          [action.data.vendorId]: {
            ...state.vendors[action.data.vendorId],
            evidence: evidenceList,
          },
        },
      };

    case SET_VENDOR_EVIDENCE_PAGES:
      if (action.isManagementAnalystSession) {
        const existingOrgData = state.managedVendorData[action.managedOrgId];

        const evidencePages = {
          ...existingOrgData[action.data.vendorId].evidencePages,
          loading: action.data.loading,
          error: action.data.error,
        };
        if (!action.data.loading && !action.data.error) {
          evidencePages.data = action.data.evidencePages;
        }

        return {
          ...state,
          managedVendorData: {
            ...state.managedVendorData,
            [action.managedOrgId]: {
              ...existingOrgData,
              [action.data.vendorId]: {
                ...existingOrgData[action.data.vendorId],
                evidencePages: evidencePages,
              },
            },
          },
        };
      }

      const evidencePages = {
        ...state.vendors[action.data.vendorId].evidencePages,
        loading: action.data.loading,
        error: action.data.error,
      };
      if (!action.data.loading && !action.data.error) {
        evidencePages.data = action.data.evidencePages;
      }

      return {
        ...state,
        vendors: {
          ...state.vendors,
          [action.data.vendorId]: {
            ...state.vendors[action.data.vendorId],
            evidencePages: evidencePages,
          },
        },
      };

    case SET_VENDOR_DETAILED_ADDITIONAL_EVIDENCE:
      const newDetailedEvidence = {
        loading: action.data.loading,
        saving: action.data.saving,
        error: action.data.error,
      };

      let existingData = _get(
        state,
        `vendors[${action.data.vendorId}].detailedEvidence[${action.data.evidenceId}].data`
      );
      if (action.isManagementAnalystSession) {
        existingData = _get(
          state.managedVendorData,
          `[${action.managedOrgId}][${action.data.vendorId}].detailedEvidence[${action.data.evidenceId}].data`
        );
      }
      if (!action.data.loading && !action.data.error && !action.data.saving) {
        newDetailedEvidence["data"] = action.data.evidence;
      } else if (action.data.saving) {
        newDetailedEvidence["data"] = existingData;
      }

      if (action.isManagementAnalystSession) {
        const existingOrgData = state.managedVendorData[action.managedOrgId];
        return {
          ...state,
          managedVendorData: {
            ...state.managedVendorData,
            [action.managedOrgId]: {
              ...existingOrgData,
              [action.data.vendorId]: {
                ...existingOrgData[action.data.vendorId],
                detailedEvidence: {
                  ...existingOrgData[action.data.vendorId].detailedEvidence,
                  [action.data.evidenceId]: newDetailedEvidence,
                },
              },
            },
          },
        };
      }
      return {
        ...state,
        vendors: {
          ...state.vendors,
          [action.data.vendorId]: {
            ...state.vendors[action.data.vendorId],
            detailedEvidence: {
              ...state.vendors[action.data.vendorId].detailedEvidence,
              [action.data.evidenceId]: newDetailedEvidence,
            },
          },
        },
      };

    case SET_VENDOR_DETAILED_ADDITIONAL_EVIDENCE_VIRUS_SCANS: {
      const cache = {};
      let existingDocuments = _get(
        state,
        `vendors[${action.data.vendorId}].detailedEvidence[${action.data.evidenceId}].data.documents`,
        null
      );
      if (action.isManagementAnalystSession) {
        existingDocuments = _get(
          state.managedVendorData,
          `[${action.managedOrgId}][${action.data.vendorId}].detailedEvidence[${action.data.evidenceId}].data.documents`
        );
      }

      if (!action.data.documents || !existingDocuments) {
        return {
          ...state,
        };
      }
      for (let i = 0; i < action.data.documents.length; i++) {
        cache[action.data.documents[i].id] = action.data.documents[i];
      }
      const newDocuments = [];
      for (let i = 0; i < existingDocuments.length; i++) {
        const doc = existingDocuments[i];
        doc.virusScanned = cache[doc.id].virusScanned;
        doc.virusSafe = cache[doc.id].virusSafe;
        newDocuments.push(doc);
      }

      if (action.isManagementAnalystSession) {
        const existingOrgData = state.managedVendorData[action.managedOrgId];
        return {
          ...state,
          managedVendorData: {
            ...state.managedVendorData,
            [action.managedOrgId]: {
              ...existingOrgData,
              [action.data.vendorId]: {
                ...existingOrgData[action.data.vendorId],
                detailedEvidence: {
                  ...existingOrgData[action.data.vendorId].detailedEvidence,
                  [action.data.evidenceId]: {
                    ...existingOrgData[action.data.vendorId].detailedEvidence[
                      action.data.evidenceId
                    ],
                    data: {
                      ...existingOrgData[action.data.vendorId].detailedEvidence[
                        action.data.evidenceId
                      ].data,
                      documents: newDocuments,
                    },
                  },
                },
              },
            },
          },
        };
      }
      return {
        ...state,
        vendors: {
          ...state.vendors,
          [action.data.vendorId]: {
            ...state.vendors[action.data.vendorId],
            detailedEvidence: {
              ...state.vendors[action.data.vendorId].detailedEvidence,
              [action.data.evidenceId]: {
                ...state.vendors[action.data.vendorId].detailedEvidence[
                  action.data.evidenceId
                ],
                data: {
                  ...state.vendors[action.data.vendorId].detailedEvidence[
                    action.data.evidenceId
                  ].data,
                  documents: newDocuments,
                },
              },
            },
          },
        },
      };
    }
    case SET_VENDOR_DETAILED_ADDITIONAL_EVIDENCE_NEWDOCUMENT: {
      let existingDocuments =
        _get(
          state,
          `vendors[${action.data.vendorId}].detailedEvidence[${action.data.evidenceId}].data.documents`,
          []
        ) ?? [];
      if (action.isManagementAnalystSession) {
        existingDocuments = _get(
          state.managedVendorData,
          `[${action.managedOrgId}][${action.data.vendorId}].detailedEvidence[${action.data.evidenceId}].data.documents`
        );
      }
      existingDocuments.unshift(action.data.document);

      if (action.isManagementAnalystSession) {
        const existingOrgData = state.managedVendorData[action.managedOrgId];
        return {
          ...state,
          managedVendorData: {
            ...state.managedVendorData,
            [action.managedOrgId]: {
              ...existingOrgData,
              [action.data.vendorId]: {
                ...existingOrgData[action.data.vendorId],
                detailedEvidence: {
                  ...existingOrgData[action.data.vendorId].detailedEvidence,
                  [action.data.evidenceId]: {
                    ...existingOrgData[action.data.vendorId].detailedEvidence[
                      action.data.evidenceId
                    ],
                    data: {
                      ...existingOrgData[action.data.vendorId].detailedEvidence[
                        action.data.evidenceId
                      ].data,
                      documents: existingDocuments,
                    },
                  },
                },
              },
            },
          },
        };
      }
      return {
        ...state,
        vendors: {
          ...state.vendors,
          [action.data.vendorId]: {
            ...state.vendors[action.data.vendorId],
            detailedEvidence: {
              ...state.vendors[action.data.vendorId].detailedEvidence,
              [action.data.evidenceId]: {
                ...state.vendors[action.data.vendorId].detailedEvidence[
                  action.data.evidenceId
                ],
                data: {
                  ...state.vendors[action.data.vendorId].detailedEvidence[
                    action.data.evidenceId
                  ].data,
                  documents: existingDocuments,
                },
              },
            },
          },
        },
      };
    }
    case SET_VENDOR_DETAILED_ADDITIONAL_EVIDENCE_ARCHIVED:
      let existingDetails = _get(
        state,
        `vendors[${action.data.vendorId}].detailedEvidence[${action.data.evidenceId}].data`,
        null
      );
      if (action.isManagementAnalystSession) {
        existingDetails = _get(
          state.managedVendorData,
          `[${action.managedOrgId}][${action.data.vendorId}].detailedEvidence[${action.data.evidenceId}].data`
        );
      }
      if (!existingDetails) {
        return {
          ...state,
        };
      }

      if (action.isManagementAnalystSession) {
        const existingOrgData = state.managedVendorData[action.managedOrgId];
        return {
          ...state,
          managedVendorData: {
            ...state.managedVendorData,
            [action.managedOrgId]: {
              ...existingOrgData,
              [action.data.vendorId]: {
                ...existingOrgData[action.data.vendorId],
                detailedEvidence: {
                  ...existingOrgData[action.data.vendorId].detailedEvidence,
                  [action.data.evidenceId]: {
                    ...existingOrgData[action.data.vendorId].detailedEvidence[
                      action.data.evidenceId
                    ],
                    data: {
                      ...existingOrgData[action.data.vendorId].detailedEvidence[
                        action.data.evidenceId
                      ].data,
                      archived: action.data.archived,
                    },
                  },
                },
              },
            },
          },
        };
      }
      return {
        ...state,
        vendors: {
          ...state.vendors,
          [action.data.vendorId]: {
            ...state.vendors[action.data.vendorId],
            detailedEvidence: {
              ...state.vendors[action.data.vendorId].detailedEvidence,
              [action.data.evidenceId]: {
                ...state.vendors[action.data.vendorId].detailedEvidence[
                  action.data.evidenceId
                ],
                data: {
                  ...state.vendors[action.data.vendorId].detailedEvidence[
                    action.data.evidenceId
                  ].data,
                  archived: action.data.archived,
                },
              },
            },
          },
        },
      };

    case REMOVE_VENDOR_DETAILED_ADDITIONAL_EVIDENCE_DOCUMENT:
      let existingDocs = _get(
        state,
        `vendors[${action.data.vendorId}].detailedEvidence[${action.data.evidenceId}].data.documents`,
        []
      );
      if (action.isManagementAnalystSession) {
        existingDocs = _get(
          state.managedVendorData,
          `[${action.managedOrgId}][${action.data.vendorId}].detailedEvidence[${action.data.evidenceId}].data.documents`
        );
      }
      const newDocs = [];
      for (let i = 0; i < existingDocs.length; i++) {
        if (existingDocs[i].id !== action.data.documentId) {
          newDocs.push(existingDocs[i]);
        }
      }

      if (action.isManagementAnalystSession) {
        const existingOrgData = state.managedVendorData[action.managedOrgId];
        return {
          ...state,
          managedVendorData: {
            ...state.managedVendorData,
            [action.managedOrgId]: {
              ...existingOrgData,
              [action.data.vendorId]: {
                ...existingOrgData[action.data.vendorId],
                detailedEvidence: {
                  ...existingOrgData[action.data.vendorId].detailedEvidence,
                  [action.data.evidenceId]: {
                    ...existingOrgData[action.data.vendorId].detailedEvidence[
                      action.data.evidenceId
                    ],
                    data: {
                      ...existingOrgData[action.data.vendorId].detailedEvidence[
                        action.data.evidenceId
                      ].data,
                      documents: newDocs,
                    },
                  },
                },
              },
            },
          },
        };
      }
      return {
        ...state,
        vendors: {
          ...state.vendors,
          [action.data.vendorId]: {
            ...state.vendors[action.data.vendorId],
            detailedEvidence: {
              ...state.vendors[action.data.vendorId].detailedEvidence,
              [action.data.evidenceId]: {
                ...state.vendors[action.data.vendorId].detailedEvidence[
                  action.data.evidenceId
                ],
                data: {
                  ...state.vendors[action.data.vendorId].detailedEvidence[
                    action.data.evidenceId
                  ].data,
                  documents: newDocs,
                },
              },
            },
          },
        },
      };

    case SET_VENDOR_DOCUMENTS_PAGING:
      if (action.isManagementAnalystSession) {
        const existingOrgData = state.managedVendorData[action.managedOrgId];
        return {
          ...state,
          managedVendorData: {
            ...state.managedVendorData,
            [action.managedOrgId]: {
              ...existingOrgData,
              [action.vendorId]: {
                ...existingOrgData[action.vendorId],
                mgtlists: {
                  ...existingOrgData[action.vendorId].mgtlists,
                  allDocuments: {
                    ...existingOrgData[action.vendorId].mgtlists.allDocuments,
                    paging: action.data.paging,
                  },
                },
              },
            },
          },
        };
      }
      return {
        ...state,
        vendors: {
          ...state.vendors,
          [action.vendorId]: {
            ...state.vendors[action.vendorId],
            mgtlists: {
              ...state.vendors[action.vendorId].mgtlists,
              allDocuments: {
                ...state.vendors[action.vendorId].mgtlists.allDocuments,
                paging: action.data.paging,
              },
            },
          },
        },
      };
    case SET_VENDOR_CONTACTS_PAGING:
      if (action.isManagementAnalystSession) {
        const existingOrgData = state.managedVendorData[action.managedOrgId];
        return {
          ...state,
          managedVendorData: {
            ...state.managedVendorData,
            [action.managedOrgId]: {
              ...existingOrgData,
              [action.vendorId]: {
                ...existingOrgData[action.vendorId],
                mgtlists: {
                  ...existingOrgData[action.vendorId].mgtlists,
                  contacts: {
                    ...existingOrgData[action.vendorId].mgtlists.contacts,
                    paging: action.data.paging,
                  },
                },
              },
            },
          },
        };
      }
      return {
        ...state,
        vendors: {
          ...state.vendors,
          [action.vendorId]: {
            ...state.vendors[action.vendorId],
            mgtlists: {
              ...state.vendors[action.vendorId].mgtlists,
              contacts: {
                ...state.vendors[action.vendorId].mgtlists.contacts,
                paging: action.data.paging,
              },
            },
          },
        },
      };

    // Report exports
    case CLEAR_EXPORT_SECTIONS:
      return {
        ...state,
        exportSections: {},
      };
    case SET_EXPORT_SECTIONS:
      const {
        exportType,
        filtersHash,
        vendorId,
        isSubsidiary,
        loading,
        error,
        data,
      } = action;
      const key = getExportSectionsKey(
        exportType,
        vendorId,
        isSubsidiary,
        filtersHash
      );
      return {
        ...state,
        exportSections: {
          ...state.exportSections,
          [key]: {
            loading,
            error,
            data,
          },
        },
      };

    case SET_CANNED_EXPORT_CONFIGS:
      return {
        ...state,
        cannedExportConfigs: {
          default: action.defaultCannedConfigs,
          custom: action.customCannedConfigs,
        },
      };

    case SET_STARRED_EXPORT_TYPES:
      return {
        ...state,
        starredExportTypes: action.starredExportTypes,
      };

    case SET_STARRED_EXPORT_TYPE:
      const newStarredExportTypes = [...(state.starredExportTypes ?? [])];

      const curIdx = newStarredExportTypes.findIndex(
        (e) =>
          (!!e.customCannedExportUUID &&
            e.customCannedExportUUID ===
              action.starredExportType.customCannedExportUUID) ||
          (!!e.defaultCannedConfigID &&
            e.defaultCannedConfigID ===
              action.starredExportType.defaultCannedConfigID) ||
          (!!e.otherReportID &&
            e.otherReportID === action.starredExportType.otherReportID)
      );

      if (action.starred && curIdx === -1) {
        newStarredExportTypes.push(action.starredExportType);
      } else if (!action.starred && curIdx > -1) {
        newStarredExportTypes.splice(curIdx, 1);
      } else {
        return state;
      }

      return {
        ...state,
        starredExportTypes: newStarredExportTypes,
      };

    case SET_AUDIT_TRAIL_FILTERS:
      return {
        ...state,
        auditTrailFilters: action.filters,
      };
    case SET_ORG_LOGO_URL:
      return {
        ...state,
        orgLogoUrl: action.url,
      };
    case SET_MANAGED_VENDORS_REQUEST_SEARCH:
      return {
        ...state,
        managedVendorRequestSearch: {
          ...state.managedVendorRequestSearch,
          ...action.data,
        },
      };

    // IP addresses
    case SET_VENDOR_IP_DETAILS:
      const vendorsKey = action.isSubsidiary ? "subsidiaries" : "vendors";
      let currentIpDetails =
        state[vendorsKey][action.vendorId] &&
        state[vendorsKey][action.vendorId].ipAddressDetails
          ? state[vendorsKey][action.vendorId].ipAddressDetails
          : {};
      return {
        ...state,
        [vendorsKey]: {
          ...state[vendorsKey],
          [action.vendorId]: {
            ...state[vendorsKey][action.vendorId],
            ipAddressDetails: {
              ...currentIpDetails,
              [action.ipDetails.ip]: action.ipDetails,
            },
          },
        },
      };
    case SET_CUSTOMER_IP_DETAILS:
      return {
        ...state,
        customerData: {
          ...state.customerData,
          ipAddressDetails: {
            ...state.customerData.ipAddressDetails,
            [action.ipDetails.ip]: action.ipDetails,
          },
        },
      };

    case SET_VENDOR_IP_RANGE_DOMAINS:
      const k = action.isSubsidiary ? "subsidiaries" : "vendors";
      let currentRanges =
        state[k][action.vendorId] && state[k][action.vendorId].ipRangeDomains
          ? state[k][action.vendorId].ipRangeDomains
          : {};
      return {
        ...state,
        [k]: {
          ...state[k],
          [action.vendorId]: {
            ...state[k][action.vendorId],
            ipRangeDomains: {
              ...currentRanges,
              [action.rangeKey]: action.domains,
            },
          },
        },
      };

    case SET_CUSTOMER_IP_RANGE_DOMAINS:
      return {
        ...state,
        customerData: {
          ...state.customerData,
          ipRangeDomains: {
            ...state.customerData.ipRangeDomains,
            [action.rangeKey]: action.domains,
          },
        },
      };

    case SET_ORG_DEFAULT_TEXTS: {
      if (action.isManagementAnalystSession) {
        let existingOrgData = state.managedVendorData[action.managedOrgId];
        if (!existingOrgData) {
          existingOrgData = {};
        }
        return {
          ...state,
          managedVendorData: {
            ...state.managedVendorData,
            [action.managedOrgId]: {
              ...existingOrgData,
              orgDefaultTexts: {
                ...existingOrgData.orgDefaultTexts,
                ...action.defaultTexts,
              },
            },
          },
        };
      }

      return {
        ...state,
        orgDefaultTexts: {
          ...state.orgDefaultTexts,
          ...action.defaultTexts,
        },
      };
    }

    case SET_ORG_USER_LIMITS:
      return {
        ...state,
        orgUserLimits: {
          ...action.orgUserLimits,
        },
      };

    case SET_ORG_MFA_STATUS:
      return {
        ...state,
        orgMFAStatus: action.enabled,
      };

    case SET_VENDOR_DATA_LEAKS_RESULT: {
      const allFindings = {
        ...(state.vendorDataLeaks ? state.vendorDataLeaks.allFindings : {}),
      };

      const currentPageFindingIds = [];
      for (let i = 0; i < action.result.vendorDataLeaks.length; i++) {
        currentPageFindingIds.push(action.result.vendorDataLeaks[i].findingId);
        allFindings[action.result.vendorDataLeaks[i].findingId] =
          action.result.vendorDataLeaks[i];
      }

      return {
        ...state,
        vendorDataLeaks: {
          monitoredVendorLimit: action.result.monitoredVendorLimit,
          numVendorsMonitored: action.result.numMonitored,
          contractStartDate: action.result.contractStartDate,
          totalResults: action.result.totalResults,
          pageOffset: action.result.offset,
          pageLimit: action.result.limit,
          searchTerm: action.result.search,
          currentPageFindingIds,
          allFindings,
        },
      };
    }

    case SET_SINGLE_VENDOR_DATA_LEAKS_RESULT: {
      return {
        ...state,
        vendorDataLeaks: {
          ...state.vendorDataLeaks,
          allFindings: {
            ...(state.vendorDataLeaks ? state.vendorDataLeaks.allFindings : {}),
            [action.result.findingId]: action.result,
          },
        },
      };
    }

    case SET_VENDOR_RISK_WAIVERS: {
      if (action.tpvmSession && action.tpvmSession.tpvm) {
        return {
          ...state,
          managedVendorData: {
            ...state.managedVendorData,
            [action.tpvmSession.tpvm_o]: {
              ...state.managedVendorData[action.tpvmSession.tpvm_o],
              [action.tpvmSession.tpvm_v]: {
                ...state.managedVendorData[action.tpvmSession.tpvm_o][
                  action.tpvmSession.tpvm_v
                ],
                riskWaivers: action.waiversResp?.privateWaivers ?? [],
                publicWaivers: action.waiversResp?.publicWaivers ?? [],
              },
            },
          },
        };
      } else {
        return {
          ...state,
          vendors: {
            ...state.vendors,
            [action.vendorId]: {
              ...state.vendors[action.vendorId],
              riskWaivers: action.waiversResp?.privateWaivers ?? [],
              publicWaivers: action.waiversResp?.publicWaivers ?? [],
            },
          },
        };
      }
    }

    case SET_VENDOR_ASSESSMENT_RISK_WAIVERS: {
      if (action.tpvmSession && action.tpvmSession.tpvm) {
        return {
          ...state,
          managedVendorData: {
            ...state.managedVendorData,
            [action.tpvmSession.tpvm_o]: {
              ...state.managedVendorData[action.tpvmSession.tpvm_o],
              [action.tpvmSession.tpvm_v]: {
                ...state.managedVendorData[action.tpvmSession.tpvm_o][
                  action.tpvmSession.tpvm_v
                ],
                assessmentRiskWaivers: {
                  ...state.managedVendorData[action.tpvmSession.tpvm_o][
                    action.tpvmSession.tpvm_v
                  ].assessmentRiskWaivers,
                  [action.assessmentId]: action.waivers,
                },
              },
            },
          },
        };
      } else {
        return {
          ...state,
          vendors: {
            ...state.vendors,
            [action.vendorId]: {
              ...state.vendors[action.vendorId],
              assessmentRiskWaivers: {
                ...state.vendors[action.vendorId].assessmentRiskWaivers,
                [action.assessmentId]: action.waivers,
              },
            },
          },
        };
      }
    }

    case SET_VENDOR_ASSESSMENT_RISK_HOSTNAMES: {
      if (action.isManagementAnalystSession) {
        const vendors =
          state.managedVendorData[action.managedOrgId][action.vendorId];
        const assessments = vendors.assessmentRiskHostnames || {};
        const risks = assessments[action.riskHostnames.assessmentID] || {};

        return {
          ...state,
          managedVendorData: {
            ...state.managedVendorData,
            [action.managedOrgId]: {
              ...state.managedVendorData[action.managedOrgId],
              [action.vendorId]: {
                ...vendors,
                assessmentRiskHostnames: {
                  ...assessments,
                  [action.riskHostnames.assessmentID]: {
                    ...risks,
                    [action.riskHostnames.riskID]: action.riskHostnames,
                  },
                },
              },
            },
          },
        };
      } else {
        const assessments =
          state.vendors[action.vendorId].assessmentRiskHostnames || {};
        const risks = assessments[action.riskHostnames.assessmentID] || {};

        return {
          ...state,
          vendors: {
            ...state.vendors,
            [action.vendorId]: {
              ...state.vendors[action.vendorId],
              assessmentRiskHostnames: {
                ...assessments,
                [action.riskHostnames.assessmentID]: {
                  ...risks,
                  [action.riskHostnames.riskID]: action.riskHostnames,
                },
              },
            },
          },
        };
      }
    }

    case SET_VENDOR_ASSET_SHARING_SETTINGS:
      return {
        ...state,
        vendorAssetSharingSettings: action.settings,
      };

    case SET_GEOLOCATION_VENDORS_BY_COUNTRY:
      return {
        ...state,
        vendorGeolocationByCountry: {
          ...state.vendorGeolocationByCountry,
          [action.countryCode]: action.result,
        },
      };

    case SET_VENDOR_FILTERS: {
      return {
        ...state,
        vendors: {
          ...state.vendors,
          [action.vendorId]: {
            ...state.vendors[action.vendorId],
            filters: action.filters,
          },
        },
      };
    }

    case SET_SUBSIDIARY_FILTERS: {
      return {
        ...state,
        subsidiaries: {
          ...state.subsidiaries,
          [action.vendorId]: {
            ...state.subsidiaries[action.vendorId],
            filters: action.filters,
          },
        },
      };
    }

    case CLEAR_SUBSIDIARY_DATA: {
      return {
        ...state,
        subsidiaries: {},
      };
    }

    case SET_VENDOR_COMPARISON_VENDORS: {
      return {
        ...state,
        vendorComparisonVendorIds: action.vendorIds,
      };
    }

    case SET_VENDOR_TIERS: {
      return {
        ...state,
        vendorTiers: action.tiers,
      };
    }

    case SET_VENDOR_ASSESSMENT_CLASSIFICATIONS: {
      return {
        ...state,
        vendorAssessmentClassifications: action.classifications,
      };
    }

    case SET_VENDOR_ASSESSMENT_AUTHORS: {
      return {
        ...state,
        vendorAssessmentAuthors: action.authors,
      };
    }

    case SET_VENDOR_ATTRIBUTE_DEFINITIONS: {
      return {
        ...state,
        vendorAttributeDefinitions: action.attributeDefinitions,
        hasDeletedVendorAttributeDefinitions: action.hasDeleted,
      };
    }

    case SET_QUESTIONNAIRE_ANSWERS: {
      return {
        ...state,
        questionnaireAnswers: {
          ...state.questionnaireAnswers,
          [`${action.id}_${action.version}`]: action.questionnaire,
        },
      };
    }

    case SET_VENDOR_IMPORT: {
      return {
        ...state,
        vendorImport: action.importDetails,
      };
    }

    case SET_OAUTH_CONNECTION_DATA: {
      return {
        ...state,
        oauthConnections: {
          ...state.oauthConnections,
          [action.service]: {
            ...state.oauthConnections[action.service],
            loading: action.loading,
            error: action.error,
            connections: action.connections
              ? action.connections
              : state.oauthConnections[action.service]
                ? state.oauthConnections[action.service].connections
                : null,
          },
        },
      };
    }
    case SET_OAUTH_CONNECTION_LOADING:
      return {
        ...state,
        oauthConnections: {
          ...state.oauthConnections,
          [action.service]: {
            ...state.oauthConnections[action.service],
            loading: action.loading,
          },
        },
      };
    case SET_OAUTH_REQUESTURL_DATA: {
      return {
        ...state,
        oauthConnections: {
          ...state.oauthConnections,
          [action.service]: {
            ...state.oauthConnections[action.service],
            error: action.error,
            requestUrl: action.url,
            requestUUID: action.uuid,
          },
        },
      };
    }
    case SET_OAUTH_ERROR: {
      return {
        ...state,
        oauthConnections: {
          ...state.oauthConnections,
          [action.service]: {
            ...state.oauthConnections[action.service],
            error: action.error,
          },
        },
      };
    }
    case SET_OAUTH_MESSAGING_CHANNELS: {
      return {
        ...state,
        oauthConnections: {
          ...state.oauthConnections,
          [action.service]: {
            ...state.oauthConnections[action.service],
            channels: action.channels,
            channels_loading: action.loading,
            error: action.error,
          },
        },
      };
    }
    case SET_OAUTH_MESSAGING_POSTING_STATUS: {
      return {
        ...state,
        oauthConnections: {
          ...state.oauthConnections,
          [action.service]: {
            ...state.oauthConnections[action.service],
            posting: action.posting,
            postingError: action.error,
          },
        },
      };
    }
    case SET_OAUTH_JIRA_POSTING_STATUS: {
      return {
        ...state,
        oauthConnections: {
          ...state.oauthConnections,
          ["Jira"]: {
            ...state.oauthConnections["Jira"],
            posting: action.posting,
            postingError: action.error,
          },
        },
      };
    }
    case SET_OAUTH_MESSAGING_POPULATED_DATA: {
      const populated = state.oauthConnections[action.service]
        ? state.oauthConnections[action.service].populatedMessages
        : {};
      return {
        ...state,
        oauthConnections: {
          ...state.oauthConnections,
          [action.service]: {
            ...state.oauthConnections[action.service],
            populatedMessages: {
              ...populated,
              [action.integrationId]: {
                ...populated[action.integrationId],
                [action.uuid]: {
                  ...populated[action.integrationId][action.uuid],
                  text: action.populatedText,
                  error: action.error,
                },
              },
            },
          },
        },
      };
    }
    case SET_OAUTH_MESSAGING_POPULATED_EXPECTED: {
      const populated =
        state.oauthConnections[action.service] &&
        state.oauthConnections[action.service].populatedMessages
          ? state.oauthConnections[action.service].populatedMessages
          : {
              [action.integrationId]: {
                [action.uuid]: {},
              },
            };
      return {
        ...state,
        oauthConnections: {
          ...state.oauthConnections,
          [action.service]: {
            ...(state.oauthConnections[action.service]
              ? state.oauthConnections[action.service]
              : {}),
            populatedMessages: {
              ...populated,
              [action.integrationId]: {
                ...(populated[action.integrationId]
                  ? populated[action.integrationId]
                  : {}),
                [action.uuid]: {
                  ...(populated[action.integrationId] &&
                  populated[action.integrationId][action.uuid]
                    ? populated[action.integrationId][action.uuid]
                    : {}),
                  expected: action.expected,
                },
              },
            },
          },
        },
      };
    }
    case SET_OAUTH_JIRA_PROJECTS: {
      return {
        ...state,
        oauthConnections: {
          ...state.oauthConnections,
          ["Jira"]: {
            ...state.oauthConnections["Jira"],
            projects: action.projects,
            projects_loading: action.loading,
            error: action.error,
          },
        },
      };
    }
    case SET_OAUTH_JIRA_ISSUETYPES: {
      return {
        ...state,
        oauthConnections: {
          ...state.oauthConnections,
          ["Jira"]: {
            ...state.oauthConnections["Jira"],
            issueTypes: {
              ...state.oauthConnections["Jira"].issueTypes,
              [action.projectId]: {
                issueTypes: action.issueTypes,
                loading: action.loading,
                error: action.error,
              },
            },
          },
        },
      };
    }
    case SET_OAUTH_JIRA_COMPONENTS: {
      return {
        ...state,
        oauthConnections: {
          ...state.oauthConnections,
          ["Jira"]: {
            ...state.oauthConnections["Jira"],
            components: {
              ...state.oauthConnections["Jira"].components,
              [action.projectId]: {
                components: action.components,
                loading: action.loading,
                error: action.error,
              },
            },
          },
        },
      };
    }

    case SET_VENDORS_MINI: {
      return {
        ...state,
        vendorsMini: action.vendors,
      };
    }

    case SET_VENDOR_PORTFOLIOS: {
      return {
        ...state,
        vendorPortfolios: {
          portfolios: action.portfolios,
          grandTotalItems: action.grandTotalItems,
          limit: action.limit,
        },
      };
    }

    case SET_DOMAIN_PORTFOLIOS: {
      return {
        ...state,
        domainPortfolios: {
          portfolios: action.portfolios,
          grandTotalItems: action.grandTotalItems,
          limit: action.limit,
        },
      };
    }

    case SET_MANAGED_VENDOR_LICENSES: {
      return {
        ...state,
        orgManagedAssessments: {
          ...state.orgManagedAssessments,
          licenses: {
            loading: action.loading,
            licenses:
              action.licenses ?? state.orgManagedAssessments.licenses.licenses,
          },
        },
      };
    }

    case SET_MANAGED_VENDOR_ASSESSMENTS_LIST: {
      return {
        ...state,
        orgManagedAssessments: {
          ...state.orgManagedAssessments,
          assessmentList: {
            ...state.orgManagedAssessments.assessmentList,
            loading: action.loading,
            ...action.data,
          },
        },
      };
    }

    case SET_MANAGED_ASSESSMENT_DETAILS: {
      const theseDeats =
        state.orgManagedAssessments.assessmentDetails[action.requestId] ??
        newManagedAssessmentDetailsState();

      return {
        ...state,
        orgManagedAssessments: {
          ...state.orgManagedAssessments,
          assessmentDetails: {
            ...state.orgManagedAssessments.assessmentDetails,
            [action.requestId]: {
              ...theseDeats,
              ...action.details,
            },
          },
        },
      };
    }

    case SET_ASSESSMENT_REQUEST_VENDOR_INFO: {
      return {
        ...state,
        orgManagedAssessments: {
          ...state.orgManagedAssessments,
          newRequestVendorInfo: action.info,
        },
      };
    }

    case SET_VENDOR_FREE_TRIAL_INFO:
      return {
        ...state,
        vendorsFreeTrial: {
          ...state.vendorsFreeTrial,
          [action.vendorId]: {
            loading: action.loading,
            error: action.error,
            data: action.data,
          },
        },
      };

    case SET_VENDOR_SUBDOMAINS_STARTED:
      // note: we only set the subdomainScanStarted flag if a) the value to be set is undefined (the flag is being reset) or if the
      // existing value is undefined or false. This means that once the flag is set to true, it can only be reset by setting a value of undefined.
      if (action.isSubsidiary) {
        return {
          ...state,
          subsidiaries: {
            ...state.subsidiaries,
            [action.vendorId]: {
              filters: getDefaultFilters(),
              ...state.subsidiaries[action.vendorId],
              subdomainScanStarted:
                !state.subsidiaries[action.vendorId] ||
                !state.subsidiaries[action.vendorId].subdomainScanStarted ||
                action.flag === undefined
                  ? action.flag
                  : state.subsidiaries[action.vendorId].subdomainScanStarted,
            },
          },
        };
      }
      if (action.isManagementAnalystSession) {
        const existingOrgData = state.managedVendorData[action.managedOrgId]
          ? state.managedVendorData[action.managedOrgId]
          : {};
        return {
          ...state,
          managedVendorData: {
            ...state.managedVendorData,
            [action.managedOrgId]: {
              ...existingOrgData,
              [action.vendorId]: {
                ...existingOrgData[action.vendorId],
                subdomainScanStarted:
                  !existingOrgData[action.vendorId] ||
                  !existingOrgData[action.vendorId].subdomainScanStarted ||
                  action.flag === undefined
                    ? action.flag
                    : existingOrgData[action.vendorId].subdomainScanStarted,
              },
            },
          },
        };
      }
      return {
        ...state,
        vendors: {
          ...state.vendors,
          [action.vendorId]: {
            filters: getDefaultFilters(),
            ...state.vendors[action.vendorId],
            subdomainScanStarted:
              !state.vendors[action.vendorId] ||
              !state.vendors[action.vendorId].subdomainScanStarted ||
              action.flag === undefined
                ? action.flag
                : state.vendors[action.vendorId].subdomainScanStarted,
          },
        },
      };
    case SET_SURVEYTYPE_AUTOMATION_LIST:
      return {
        ...state,
        questionnaireAutomation: {
          ...state.questionnaireAutomation,
          [action.surveyTypeId]: {
            error: action.error,
            loading: action.loading,
            draftChangesExist: action.draftChangesExist,
            numDraftDeletions: action.numDraftDeletions,
            published: action.published,
            draft: action.draft,
          },
        },
      };
    case SET_AUTOMATION_LIST_RECIPE_EDITS_EXIST:
      let existingDraft = [
        ...(state.questionnaireAutomation?.[action.surveyTypeId]?.draft ?? []),
      ].map((r) => {
        if (r.uuid == action.recipeUUID) {
          return {
            ...r,
            editsExist: action.exist,
          };
        } else {
          return r;
        }
      });
      return {
        ...state,
        questionnaireAutomation: {
          ...state.questionnaireAutomation,
          [action.surveyTypeId]: {
            ...state.questionnaireAutomation[action.surveyTypeId],
            error: action.error,
            loading: action.loading,
            draft: existingDraft,
          },
        },
      };
    case SWAP_IN_SURVEYTYPE_AUTOMATION_LIST:
      const ll = [
        ...state.questionnaireAutomation?.[action.surveyTypeId]?.draft,
      ];
      if (!ll || ll.length == 0) {
        return { ...state };
      }
      const temp = ll[action.aIdx];
      const temp_so = temp.sortOrder;
      temp.sortOrder = ll[action.bIdx].sortOrder;
      ll[action.bIdx].sortOrder = temp_so;
      ll[action.aIdx] = ll[action.bIdx];
      ll[action.bIdx] = temp;
      return {
        ...state,
        questionnaireAutomation: {
          ...state.questionnaireAutomation,
          [action.surveyTypeId]: {
            ...state.questionnaireAutomation[action.surveyTypeId],
            draft: ll,
          },
        },
      };
    case MOVE_IN_SURVEYTYPE_AUTOMATION_LIST: {
      const ll = [
        ...state.questionnaireAutomation?.[action.surveyTypeId]?.draft,
      ];
      if (!ll || ll.length == 0) {
        return { ...state };
      }

      // set draft and parent temporarily so that we know updates have occurred
      const firstItem = {
        ...ll[action.aIdx],
        draft: true,
        parent: ll[action.aIdx].parent ? ll[action.aIdx].parent : 666,
      };
      ll.splice(action.bIdx, 0, firstItem);
      let toRemove = action.aIdx;
      if (action.bIdx < action.aIdx) {
        toRemove = toRemove + 1;
      }
      ll.splice(toRemove, 1);

      return {
        ...state,
        questionnaireAutomation: {
          ...state.questionnaireAutomation,
          [action.surveyTypeId]: {
            ...state.questionnaireAutomation[action.surveyTypeId],
            draft: ll,
          },
        },
      };
    }
    case SET_SURVEYTYPE_AUTOMATION_ENABLED:
      const automationList =
        state.questionnaireAutomation &&
        state.questionnaireAutomation[action.surveyTypeId] &&
        state.questionnaireAutomation[action.surveyTypeId].published
          ? state.questionnaireAutomation[action.surveyTypeId].published
          : [];
      if (automationList.length == 0) {
        return {
          ...state,
        };
      }
      const newAutomationList = [];
      for (let idx = 0; idx < automationList.length; idx++) {
        if (automationList[idx].uuid == action.recipeUUID) {
          newAutomationList.push({
            ...automationList[idx],
            enabled: action.enabled,
          });
        } else {
          newAutomationList.push(automationList[idx]);
        }
      }
      return {
        ...state,
        questionnaireAutomation: {
          ...state.questionnaireAutomation,
          [action.surveyTypeId]: {
            ...state.questionnaireAutomation[action.surveyTypeId],
            published: newAutomationList,
          },
        },
      };
    case SET_AUTOMATION_RECIPE:
      if (action.remove) {
        const recipes = {
          ...state.automationRecipes,
        };
        delete recipes[action.recipeUUID];
        return {
          ...state,
          automationRecipes: recipes,
        };
      }
      const syntax =
        state.automationRecipes?.[action.recipeUUID]?.autosave?.syntax;
      return {
        ...state,
        automationRecipes: {
          ...state.automationRecipes,
          [action.recipeUUID]: {
            error: action.error,
            loading: action.loading,
            data: action.data
              ? action.data
              : state.automationRecipes?.[action.recipeUUID]?.data
                ? state.automationRecipes?.[action.recipeUUID]?.data
                : undefined,
            autosave: {
              syntax: syntax,
            },
          },
        },
      };
    case SET_AUTOMATION_RECIPE_STATE:
      return {
        ...state,
        automationRecipes: {
          ...state.automationRecipes,
          [action.recipeUUID]: {
            error: action.error,
            loading: action.loading,
          },
        },
      };
    case SET_AUTOMATION_RECIPE_ENABLED:
      const recipe =
        state.automationRecipes &&
        state.automationRecipes[action.recipeUUID] &&
        state.automationRecipes[action.recipeUUID].data
          ? state.automationRecipes[action.recipeUUID].data
          : {};
      if (!recipe.uuid) {
        return {
          ...state,
        };
      }
      return {
        ...state,
        automationRecipes: {
          ...state.automationRecipes,
          [action.recipeUUID]: {
            ...state.automationRecipes[action.recipeUUID],
            data: {
              ...recipe,
              enabled: action.enabled,
            },
          },
        },
      };
    case SET_SURVEYTYPE_AUTOMATION_QUESTIONS:
      return {
        ...state,
        automationQuestions: {
          ...state.automationQuestions,
          [action.surveyTypeId]: {
            error: action.error,
            loading: action.loading,
            data: action.data,
          },
        },
      };
    case REMOVE_FROM_SURVEYTYPE_AUTOMATION_LIST:
      const list = state.questionnaireAutomation[action.surveyTypeId]
        ? [...state.questionnaireAutomation[action.surveyTypeId].draft]
        : [];
      let idx = list.findIndex((i) => i.uuid === action.recipeUUID);
      if (idx > -1) {
        list.splice(idx, 1);
      }
      return {
        ...state,
        questionnaireAutomation: {
          ...state.questionnaireAutomation,
          [action.surveyTypeId]: {
            ...state.questionnaireAutomation[action.surveyTypeId],
            draft: list,
            numDraftDeletions:
              state.questionnaireAutomation[action.surveyTypeId]
                .numDraftDeletions + 1,
          },
        },
      };
    case SET_AUTOMATION_RECIPE_AUTOSAVE_STATE:
      return {
        ...state,
        automationRecipes: {
          ...state.automationRecipes,
          [action.recipeUUID]: {
            ...state.automationRecipes?.[action.recipeUUID],
            autosave: {
              ...state.automationRecipes?.[action.recipeUUID]?.autosave,
              loading: action.loading,
              error: action.error,
            },
          },
        },
      };
    case SET_AUTOMATION_RECIPE_SYNTAX_STATE:
      return {
        ...state,
        automationRecipes: {
          ...state.automationRecipes,
          [action.recipeUUID]: {
            ...state.automationRecipes[action.recipeUUID],
            autosave: {
              ...state.automationRecipes[action.recipeUUID].autosave,
              syntax: action.error,
            },
          },
        },
      };
    case SET_AUTOMATION_TEST_SUBMISSIONS:
      return {
        ...state,
        automationTestSubmissions: {
          ...state.automationTestSubmissions,
          [action.surveyTypeID]: {
            loading: action.loading,
            error: action.error,
            data: action.data,
          },
        },
      };
    case SET_AUTOMATION_TEST_RESULTS:
      return {
        ...state,
        automatedTestResults: {
          ...state.automatedTestResults,
          [action.surveyTypeID]: {
            ...state.automatedTestResults?.[action.surveyTypeID],
            [action.surveyID]: {
              loading: action.loading,
              error: action.error,
              mappedResults: action.mappedResults,
              appliedResults: action.appliedResults,
            },
          },
        },
      };
    case DELETE_AUTOMATION_TEST_RESULTS:
      const testResults = {
        ...state.automatedTestResults,
      };
      delete testResults[action.surveyTypeID];
      return {
        ...state,
        automatedTestResults: {
          ...testResults,
        },
      };
    case SET_ORG_ADDITIONAL_EVIDENCE_TYPES:
      return {
        ...state,
        additionalEvidenceTypes: action.types,
      };

    case SET_VENDOR_ASSET_TYPES:
      return {
        ...state,
        vendorCurrentSurveyTypes: action.surveyTypes,
        vendorCurrentEvidenceTypes: action.evidenceTypes,
      };

    case SET_VENDOR_ADDITIONAL_EVIDENCE_HISTORY: {
      return {
        ...state,
        vendors: {
          ...state.vendors,
          [action.vendorID]: {
            ...state.vendors?.[action.vendorID],
            additionalEvidenceHistories: {
              ...state.vendors?.[action.vendorID]?.additionalEvidenceHistories,
              [action.additionalEvidenceID]: {
                ...state.vendors?.[action.vendorID]
                  ?.additionalEvidenceHistories?.[action.additionalEvidenceID],
                history: action.additionalEvidenceHistory,
                historyUsers: action.additionalEvidenceHistoryUsers,
                historyDocuments: action.additionalEvidenceHistoryDocuments,
                historyRequestRecipients:
                  action.additionalEvidenceHistoryRequestRecipients,
              },
            },
          },
        },
      };
    }

    case SET_ORG_VENDOR_MERGE_TAGS: {
      if (action.managedOrgId) {
        return {
          ...state,
          managedVendorData: {
            ...state.managedVendorData,
            [action.managedOrgId]: {
              ...state.managedVendorData[action.managedOrgId],
              orgVendorMergeTags: action.tags,
            },
          },
        };
      } else {
        return {
          ...state,
          orgVendorMergeTags: action.tags,
        };
      }
    }

    case SET_SYSTEM_DEFAULT_TEXTS: {
      return {
        ...state,
        systemDefaultTexts: action.texts,
      };
    }

    case SET_ADDITIONAL_EVIDENCE_REQUEST_USERS: {
      return {
        ...state,
        vendors: {
          ...state.vendors,
          [action.vendorId]: {
            ...state.vendors[action.vendorId],
            additionalEvidenceUsers: {
              ...state.vendors[action.vendorId].additionalEvidenceUsers,
              [action.evidenceId]: action.users,
            },
          },
        },
      };
    }

    case SET_DETECTED_PRODUCTS:
      return {
        ...state,
        detectedProducts: action.data,
      };

    default:
      return state;
  }
}

export default cyberRiskReducer;
