import { FC } from "react";

import { AppScopeUser } from "../api/types";
import XTable, {
  IXTableRow,
  SortDirection,
  XTableCell,
} from "../../_common/components/core/XTable";

import { useHistory, useRouteMatch } from "react-router-dom";
import UserBaseAPI from "../api/userbase.api";
import { AppRouteParams } from "../UserBaseNavItems";
import TeamsPills from "./TeamsPills";
import { useSortingWithPagination } from "../../_common/hooks";
import UserApprovalPill from "./UserApprovalPill";
import "./AppPermissionsExpandedTable.scss";
import SecurityRatingDisplay, { RatingSize } from "./SecurityRatingDisplay";

const PAGE_SIZE = 50;
const NAME_COL = "name_col";
const ROLE_COL = "role_col";
const TEAM_COL = "team_col";
const SECURITY_RATING_COL = "rating_col";
const STATUS_COL = "status_col";
interface AppPermissionsExpandedTableProps {
  scopeId: string;
}

const AppPermissionsExpandedTable: FC<AppPermissionsExpandedTableProps> = ({
  scopeId,
}) => {
  const history = useHistory();
  const match = useRouteMatch<AppRouteParams>();
  const appName = match.params.appName;

  const { data, isLoading } = UserBaseAPI.useGetUserBaseAppScopeUsersV1Query({
    appName,
    scopeId: scopeId,
  });

  const dataToUse = data?.users ?? [];

  const [
    sortedUsers,
    sortedBy,
    onSortChange,
    currentPage,
    totalPages,
    onPageChange,
  ] = useSortingWithPagination<
    AppScopeUser,
    | typeof NAME_COL
    | typeof ROLE_COL
    | typeof TEAM_COL
    | typeof SECURITY_RATING_COL
    | typeof STATUS_COL
  >(
    dataToUse,
    NAME_COL,
    SortDirection.ASC,
    {
      [NAME_COL]: {
        orderFuncs: [(p) => p.name.toLocaleLowerCase() || p.email],
        sortDirsAsc: [SortDirection.ASC],
        sortDirsDesc: [SortDirection.DESC],
      },
      [ROLE_COL]: {
        orderFuncs: [(p) => p.roles.join(",")],
        sortDirsAsc: [SortDirection.ASC],
        sortDirsDesc: [SortDirection.DESC],
      },
      [TEAM_COL]: {
        orderFuncs: [(p) => p.teams.join(",")],
        sortDirsAsc: [SortDirection.ASC],
        sortDirsDesc: [SortDirection.DESC],
      },
      [SECURITY_RATING_COL]: {
        orderFuncs: [(p) => p.adjustedRating],
        sortDirsAsc: [SortDirection.ASC],
        sortDirsDesc: [SortDirection.DESC],
      },
      [STATUS_COL]: {
        orderFuncs: [(p) => p.approved],
        sortDirsAsc: [SortDirection.ASC],
        sortDirsDesc: [SortDirection.DESC],
      },
    },
    PAGE_SIZE
  );

  const rows = sortedUsers.map((u: AppScopeUser): IXTableRow<string> => {
    const onClick = () => {
      history.push(`/userbase/user/${u.uuid}/summary`, {
        from: "permissions",
        backContext: {
          backTo: `/userbase/application/${appName}/permissions`,
          backToText: "Back to Permissions",
        },
      });
    };

    return {
      id: u.email,
      className: "app-permissions-user-row",
      onClick: onClick,
      cells: [
        <XTableCell key={NAME_COL}>{u.name || u.email}</XTableCell>,
        <XTableCell key={ROLE_COL}>
          {u.roles ? u.roles.join(", ") : " "}
        </XTableCell>,
        <XTableCell key={TEAM_COL}>
          <TeamsPills teams={u.teams} />
        </XTableCell>,
        <XTableCell key={SECURITY_RATING_COL}>
          <SecurityRatingDisplay
            rating={u.adjustedRating}
            size={RatingSize.Small}
          />
        </XTableCell>,
        <XTableCell key={STATUS_COL}>
          <UserApprovalPill approved={u.approved} waived={u.waived} />
        </XTableCell>,
      ],
      iconOptions: [
        {
          id: "click",
          icon: <div className={"cr-icon-chevron"} />,
          disabled: false,
          onClick: onClick,
        },
      ],
    };
  });

  if (!isLoading && data?.users === undefined) {
    return <></>;
  }

  return (
    <div className="application-permissions-expanded-table">
      <XTable
        className="app-permissions-user-list"
        loading={isLoading}
        columnHeaders={[
          { id: NAME_COL, text: "Name", sortable: true },
          { id: ROLE_COL, text: "Role", sortable: true },
          { id: TEAM_COL, text: "Team", sortable: true },
          { id: SECURITY_RATING_COL, text: "User rating", sortable: true },
          { id: STATUS_COL, text: "Status", sortable: true },
        ]}
        rows={rows}
        sortedBy={sortedBy}
        onSortChange={onSortChange}
        iconOptions
        pagination={{
          currentPage: currentPage,
          totalPages: totalPages,
          onPageChange: onPageChange,
          hidePaginationIfSinglePage: true,
        }}
      />
    </div>
  );
};

export default AppPermissionsExpandedTable;
