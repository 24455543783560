import { Component } from "react";
import classnames from "classnames";
import { DefaultThunkDispatch } from "../../../_common/types/redux";
import { History } from "history";
import LoadingBanner from "../../../_common/components/core/LoadingBanner";
import ToggleWithLabel from "../ToggleWithLabel";

interface IEnableIntegrationStepProps {
  history: History;
  dispatch: DefaultThunkDispatch;
  loading: boolean;
  isNewIntegration: boolean;
  newIntegrationEnabledFlag: boolean;
  enabled: boolean;
  enableRunning: boolean;
  enableDisableNewIntegration: () => void;
  enableDisable: () => void;
}

class SelectSlackDestinationStep extends Component<IEnableIntegrationStepProps> {
  static defaultProps = {};

  constructor(props: IEnableIntegrationStepProps) {
    super(props);
  }

  render() {
    return (
      <div className="section-step">
        <div id={"enable-disable"}>
          {this.props.loading && <LoadingBanner />}
          {!this.props.loading && (
            <>
              <div className={"title-bar"}>
                <div className="header">Enable/disable integration</div>
              </div>
              <div className={"body"}>
                <div className={"field"}>
                  <div className="field-desc">
                    Would you like to enable this integration?
                    <p>
                      {
                        "Enable this integration once you have verified the message received at the destination is as expected."
                      }
                    </p>
                  </div>
                  <div className="field-input-enable integration-status">
                    <>
                      {this.props.enableRunning && (
                        <div className="background-busy">
                          <LoadingBanner tight />
                        </div>
                      )}
                      {!this.props.enableRunning && (
                        <ToggleWithLabel
                          name={"enable"}
                          selected={
                            this.props.isNewIntegration
                              ? this.props.newIntegrationEnabledFlag
                              : !!this.props.enabled
                          }
                          disabled={this.props.enableRunning}
                          onClick={
                            this.props.isNewIntegration
                              ? this.props.enableDisableNewIntegration
                              : this.props.enableDisable
                          }
                        />
                      )}
                      <div
                        className={classnames("label", {
                          "label-greyed": this.props.enableRunning,
                        })}
                      >
                        {!this.props.enableRunning &&
                          !this.props.isNewIntegration && (
                            <>
                              {"This integration is currently "}
                              <span
                                className={
                                  this.props.enableRunning
                                    ? "greyed"
                                    : this.props.enabled
                                      ? "enabled"
                                      : "disabled"
                                }
                              >
                                {this.props.enabled ? "enabled" : "disabled"}
                              </span>
                            </>
                          )}
                        {!this.props.enableRunning &&
                          this.props.isNewIntegration && (
                            <>
                              {"This integration should be "}
                              <span
                                className={
                                  this.props.enableRunning
                                    ? "greyed"
                                    : this.props.newIntegrationEnabledFlag
                                      ? "enabled"
                                      : "disabled"
                                }
                              >
                                {this.props.newIntegrationEnabledFlag
                                  ? "enabled"
                                  : "disabled"}
                              </span>
                              {" immediately upon completion."}
                            </>
                          )}
                        {this.props.enableRunning && (
                          <span className={"label-greyed"}>
                            {`${
                              this.props.enabled ? "disabling" : "enabling"
                            } integration`}
                          </span>
                        )}
                      </div>
                    </>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    );
  }
}

export default SelectSlackDestinationStep;
