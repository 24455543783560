import {
  INewPortfolio,
  IPortfolioUpdateItem,
  Portfolio,
  PortfolioType,
} from "../../reducers/portfolios.actions";
import { ChangeEvent, FC, memo, useCallback, useEffect, useState } from "react";
import "../../style/components/portfolios/PortfolioSelection.scss";
import ColorCheckbox from "../ColorCheckbox";
import PillLabel from "../PillLabel";
import { LabelColor } from "../../../_common/types/label";
import Button from "../../../_common/components/core/Button";
import FocusInput from "../FocusInput";
import IconButton, { HoverColor } from "../../../_common/components/IconButton";
import {
  PortfolioValidationErrorType,
  portfolioNameMaxLen,
  validatePortfolios,
} from "./helpers";
import classnames from "classnames";
import { SidePopupV2 } from "../../../_common/components/DismissablePopup";
import { useModalV2 } from "../../../_common/components/ModalV2";
import CreateNewPortfolioModal from "./CreateNewPortfolioModal";

// From a list of items, get the portfolio ids that should be selected (all items have it)
// and the ids that should be indeterminate (some items have it).
const getSelectedAndIndeterminatePortfolioIdsFromItems = (
  items: IPortfolioSelectionSelectedItem[]
) => {
  const portfolioIdToItemIds: Record<string, (number | string)[]> = {};

  for (let i = 0; i < items.length; i++) {
    const { portfolios, id: itemId } = items[i];
    if (portfolios) {
      for (let j = 0; j < portfolios.length; j++) {
        const { id: portfolioId } = portfolios[j];
        if (portfolioIdToItemIds[portfolioId]) {
          portfolioIdToItemIds[portfolioId].push(itemId);
        } else {
          portfolioIdToItemIds[portfolioId] = [itemId];
        }
      }
    }
  }

  const selectedPortfolioIds: number[] = [];
  const indeterminatePortfolioIds: number[] = [];

  Object.entries(portfolioIdToItemIds).forEach(([portfolioId, itemIds]) => {
    if (itemIds.length === items.length) {
      // All items have this portfolio ID, it should show as selected
      selectedPortfolioIds.push(parseInt(portfolioId));
    } else {
      // Only some items have this portfolio, it should be indeterminate
      indeterminatePortfolioIds.push(parseInt(portfolioId));
    }
  });

  return [selectedPortfolioIds, indeterminatePortfolioIds];
};

export interface IPortfolioSelectionSelectedItem {
  id: number | string;
  name: string;
  portfolios?: Portfolio[];
}

interface IPortfolioSelectionProps {
  portfolioType: PortfolioType;
  portfolios: Portfolio[];
  portfolioLimit: number;
  canCreatePortfolios: boolean;
  createPortfoliosViaModal?: boolean;
  selectedItems: IPortfolioSelectionSelectedItem[];
  disabledPortfolioIds: number[];
  onSetUpdateItems: (
    items: IPortfolioUpdateItem[],
    newPortfolios: INewPortfolio[],
    valid: boolean,
    validationErrors: PortfolioValidationErrorType[]
  ) => void;
  hideCreateNew?: boolean;
}

// PortfolioSelection takes a list of items (domains or vendors), and pre-selects portfolios for them. When the
// user changes the selection, this component calls back with a list of 'update items' that can
// be sent to the API as requested changes. Update items each contain a list of item IDs and
// the portfolio IDs to be assigned to them. Leaving indeterminate checkboxes alone will result
// in no changes to which items are assigned to that portfolio.
const PortfolioSelection: FC<IPortfolioSelectionProps> = ({
  portfolioType,
  portfolios: originalPortfolios,
  portfolioLimit,
  canCreatePortfolios,
  createPortfoliosViaModal = false,
  selectedItems,
  disabledPortfolioIds,
  onSetUpdateItems,
  hideCreateNew,
}) => {
  const [portfolios, setPortfolios] = useState(originalPortfolios);
  const [selectedPortfolioIds, setSelectedPortfolioIds] = useState<number[]>(
    []
  );
  const [indeterminatePortfolioIds, setIndeterminatePortfolioIds] = useState<
    number[]
  >([]);

  useEffect(() => {
    // Reset the portfolios state when portfolios are updated
    setPortfolios(originalPortfolios);
  }, [originalPortfolios]);

  useEffect(() => {
    // Update the selection state when the item selection changes
    const [selectedPortfolioIds, indeterminatePortfolioIds] =
      getSelectedAndIndeterminatePortfolioIdsFromItems(selectedItems);
    setSelectedPortfolioIds(selectedPortfolioIds);
    setIndeterminatePortfolioIds(indeterminatePortfolioIds);
  }, [selectedItems]);

  useEffect(() => {
    // Whenever we update state, send back the required update items to the parent component.
    const newPortfolios: INewPortfolio[] = portfolios
      .filter((p) => p.id < 0)
      .map((p) => ({
        tempId: p.id,
        name: p.name,
      }));

    const updateMap: Record<string, (number | string)[]> = {};
    let valid = true;

    for (let i = 0; i < selectedItems.length; i++) {
      const { id: itemId, portfolios } = selectedItems[i];

      const portfolioIdsForItem = [];
      if (portfolios) {
        for (let j = 0; j < portfolios.length; j++) {
          const { id: portfolioId } = portfolios[j];
          if (
            (selectedPortfolioIds.indexOf(portfolioId) > -1 ||
              indeterminatePortfolioIds.indexOf(portfolioId) > -1) &&
            disabledPortfolioIds.indexOf(portfolioId) === -1
          ) {
            portfolioIdsForItem.push(portfolioId);
          }
        }
      }

      // Now make sure all selected portfolios are in the list
      for (let j = 0; j < selectedPortfolioIds.length; j++) {
        const portfolioId = selectedPortfolioIds[j];
        if (portfolioIdsForItem.indexOf(portfolioId) === -1) {
          portfolioIdsForItem.push(portfolioId);
        }
      }

      if (portfolioIdsForItem.length === 0) {
        // Can't have zero portfolios for any item - this is not a valid selection.
        valid = false;
        continue;
      }

      portfolioIdsForItem.sort();
      const updateMapKey = portfolioIdsForItem.join(",");
      if (updateMap[updateMapKey]) {
        updateMap[updateMapKey].push(itemId);
      } else {
        updateMap[updateMapKey] = [itemId];
      }
    }

    const updateItems: IPortfolioUpdateItem[] = Object.entries(updateMap).map(
      ([key, itemIds]) => ({
        portfolioIds: key.split(",").map((v) => parseInt(v)),
        itemIds,
      })
    );

    // Finally check if all the new portfolios have non-empty, unique names
    const validationErrors = validatePortfolios(...portfolios);
    valid = valid && validationErrors.length === 0;

    onSetUpdateItems(updateItems, newPortfolios, valid, validationErrors);
  }, [
    portfolios,
    selectedPortfolioIds,
    indeterminatePortfolioIds,
    selectedItems,
    disabledPortfolioIds,
    onSetUpdateItems,
  ]);

  const onSelectPortfolio = (portfolioId: number) => {
    // If ths is an indeterminate checkbox, set it to off
    const indeterminateIdx = indeterminatePortfolioIds.indexOf(portfolioId);
    if (indeterminateIdx > -1) {
      const newIndeterminates = [...indeterminatePortfolioIds];
      newIndeterminates.splice(indeterminateIdx, 1);
      setIndeterminatePortfolioIds(newIndeterminates);
      return;
    }

    // Otherwise toggle the state within selectedPortfolioIds
    const selectedIdx = selectedPortfolioIds.indexOf(portfolioId);
    const newSelected = [...selectedPortfolioIds];
    if (selectedIdx > -1) {
      newSelected.splice(selectedIdx, 1);
    } else {
      newSelected.push(portfolioId);
    }
    setSelectedPortfolioIds(newSelected);
  };

  const addNewPortfolio = () => {
    // Create a new dummy portfolio with a negative ID
    let lowestNewPortfolioId = 0;
    for (let i = 0; i < portfolios.length; i++) {
      if (portfolios[i].id < lowestNewPortfolioId) {
        lowestNewPortfolioId = portfolios[i].id;
      }
    }

    const newPortfolioId = lowestNewPortfolioId - 1;

    setPortfolios([
      ...portfolios,
      {
        id: newPortfolioId,
        name: "",
        type: portfolioType,
        isDefault: false,
        numItems: 0,
      },
    ]);

    // And set the new portfolio to selected
    onSelectPortfolio(newPortfolioId);
  };

  const updatePortfolioName = (portfolioId: number, newName: string) => {
    const newPortfolios = [...portfolios];
    const idx = newPortfolios.findIndex((p) => p.id === portfolioId);
    if (idx === -1) {
      return;
    }

    newPortfolios[idx] = { ...newPortfolios[idx], name: newName };

    setPortfolios(newPortfolios);
  };

  const removePortfolio = (portfolioId: number) => {
    const newPortfolios = [...portfolios];
    const idx = newPortfolios.findIndex((p) => p.id === portfolioId);
    if (idx === -1) {
      return;
    }

    // Make sure the portfolio is unselected
    onSelectPortfolio(portfolioId);

    newPortfolios.splice(idx, 1);
    setPortfolios(newPortfolios);
  };

  const reachedPortfolioLimit =
    portfolioLimit > 0 && portfolios.length >= portfolioLimit;

  // Support adding new portfolios via a modal rather than at the same time as editing the selection
  const [_openAddPortfolioModal, addPortfolioModal] = useModalV2(
    CreateNewPortfolioModal
  );
  const openAddPortfolioModal = useCallback(() => {
    _openAddPortfolioModal({
      portfolioType,
    });
  }, [_openAddPortfolioModal, portfolioType]);

  return (
    <div className="portfolio-selection">
      {portfolios.map((p) => (
        <div className="p-row" key={p.id}>
          {p.id > 0 ? (
            <ColorCheckbox
              label={
                <>
                  {p.name}
                  {p.isDefault && (
                    <PillLabel color={LabelColor.Blue}>Default</PillLabel>
                  )}
                </>
              }
              disabled={disabledPortfolioIds.indexOf(p.id) > -1}
              checked={selectedPortfolioIds.indexOf(p.id) > -1}
              indeterminate={indeterminatePortfolioIds.indexOf(p.id) > -1}
              onClick={() => onSelectPortfolio(p.id)}
            />
          ) : (
            <>
              <ColorCheckbox label={""} disabled checked />
              <FocusInput
                type="text"
                placeholder="Type a name for this portfolio"
                value={p.name}
                maxLength={portfolioNameMaxLen}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  updatePortfolioName(p.id, e.target.value)
                }
              />
              <IconButton
                icon={<div className="cr-icon-trash" />}
                hoverColor={HoverColor.Red}
                onClick={() => removePortfolio(p.id)}
              />
            </>
          )}
        </div>
      ))}
      {!hideCreateNew && (
        <SidePopupV2
          className="add-portfolio-btn-wrapper"
          position={"bottom"}
          text={
            reachedPortfolioLimit
              ? `Your account is limited to ${portfolioLimit} portfolios. Please get in touch with support to discuss options for increasing your limit.`
              : !canCreatePortfolios
                ? `Get in touch with an account administrator to add more portfolios.`
                : undefined
          }
        >
          <Button
            className={classnames("add-portfolio", {
              empty: !portfolios.length,
            })}
            filledSecondary
            disabled={reachedPortfolioLimit || !canCreatePortfolios}
            onClick={
              createPortfoliosViaModal ? openAddPortfolioModal : addNewPortfolio
            }
          >
            <span className="cr-icon-plus" />
            Add portfolio
          </Button>
        </SidePopupV2>
      )}
      {addPortfolioModal}
    </div>
  );
};

export default memo(PortfolioSelection);
