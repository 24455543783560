import { FC, MouseEventHandler, ReactNode, useEffect, useState } from "react";

import { FreeTrialUseCases } from "../_common/components/navigation/FreeTrialNavContent";
import classnames from "classnames";

import "./style/FreeTrialRegister.scss";
import TextField from "../_common/components/TextField";
import Button from "../_common/components/core/Button";
import MailIcon from "./images/mail.svg";
import InfoBanner, { BannerType } from "../vendorrisk/components/InfoBanner";
import { getCountryInfo } from "../_common/reducers/freeTrial.actions";
import { SelectV2 } from "../_common/components/SelectV2";
import UGLogo from "../registration/images/ug_logo.svg";
import LoadingBanner from "../_common/components/core/LoadingBanner";
import Tick from "../_common/images/free-trial/tick.svg";
import PublicPageviewTracker from "../vendorrisk/components/PublicPageviewTracker";
import { validateEmail } from "../_common/helpers";
import { createUUID } from "../survey_builder/helpers";
import GmailLogo from "../_common/images/gmail_logo.svg";
import OutlookLogo from "../_common/images/outlook_logo.svg";
import { appConnect } from "../_common/types/reduxHooks";
import {
  GetAnonymousTrackingID,
  SendAnonymousEvent,
} from "../_common/components/Auth0Wrapper.v2";
import { GetQueryParams } from "../_common/query";
import { appendParams } from "../vendorrisk/helpers/util";

// Importable style container to ensure CSS is imported
export const FreeTrialRegisterStyles: FC<{
  children?: ReactNode;
}> = ({ children }) => <div id={"free_trial_register"}>{children}</div>;

interface IHubspotMeetingProps {
  calendarId: string;
  emailAddress: string;
  firstName: string;
  lastName: string;
  onMeetingBooked: () => void;
}

export const HubspotMeeting: FC<IHubspotMeetingProps> = ({
  calendarId,
  emailAddress,
  firstName,
  lastName,
  onMeetingBooked,
}) => {
  const [meetingBooked, setMeetingBooked] = useState(false);

  useEffect(() => {
    loadScript(
      "https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js",
      document.head
    );

    const hubspotListenerFunc: (this: Window, ev: MessageEvent<any>) => any = (
      event
    ) => {
      if (
        event.origin == "https://meetings.hubspot.com" &&
        event.data &&
        event.data.meetingBookSucceeded
      ) {
        setMeetingBooked(true);
        onMeetingBooked();
      }
      return true;
    };

    // Add a listener to receive the meeting booking event from the HubSpot calendar
    window.addEventListener("message", hubspotListenerFunc);

    return () => {
      window.removeEventListener("message", hubspotListenerFunc);
    };
  }, []);

  const loadScript = function (src: string, target: any) {
    const tag = document.createElement("script");
    tag.async = false;
    tag.src = src;
    target.appendChild(tag);
  };

  const src = appendParams(`https://meetings.hubspot.com/${calendarId}`, {
    embed: "true",
    firstName: firstName,
    lastName: lastName,
    email: emailAddress,
  });

  return (
    <div
      className={classnames("meetings-iframe-container", {
        completed: meetingBooked,
      })}
      data-src={src}
    ></div>
  );
};

interface ISelectItem {
  value: string;
  label: string;
}

interface IFreeTrialRegisterOwnProps {
  freeTrialSignupEnabled: boolean;
  freeTrialDays: string;
  freeTrialMeetingRequired: boolean;
  freeTrialUseCaseRequired: boolean;
}

const FreeTrialRegister: FC<IFreeTrialRegisterOwnProps> = ({
  freeTrialSignupEnabled,
  freeTrialMeetingRequired,
  freeTrialUseCaseRequired,
}) => {
  const [loading, setLoading] = useState(false);
  const [prefilled, setPrefilled] = useState(false);
  const [showThankyou, setShowThankyou] = useState(false);
  const [useCases, setUseCases] = useState("");
  const [otherText, setOtherText] = useState("");
  const [howDid, setHowDid] = useState<ISelectItem>({} as ISelectItem);
  const [page, setPage] = useState(1);

  const [_region, setRegion] = useState("");
  const [calendar, setCalendar] = useState("");
  const [meetingBooked, setMeetingBooked] = useState(false);
  const [pageError, setPageError] = useState<null | ReactNode>(null);
  const [registrationError, setRegistrationError] = useState(false);
  const [leadUUID] = useState(createUUID());

  const {
    utm_source,
    utm_medium,
    utm_content,
    utm_campaign,
    utm_term,
    vendor_report_domain,
    email_address,
    first_name,
    last_name,
  } = GetQueryParams(window.location.search);

  const [email, setEmail] = useState({
    email: !!email_address ? email_address : "",
    valid: !!email_address ? validateEmail(email_address) : false,
  });
  const [firstName, setFirstName] = useState(!!first_name ? first_name : "");
  const [lastName, setLastName] = useState(!!last_name ? last_name : "");

  const handleError = (error: string) => {
    switch (error) {
      case "FREE_EMAIL_PROVIDER":
        setPageError(
          "We do not support signing up with free email providers. Please enter an email address that uses a domain your company owns."
        );
        break;
      case "EDU_EMAIL_PROVIDER":
        setPageError(
          <p>
            Sorry, we do not currently support signing up with educational
            domains. Please <a href="https://upguard.com/demo">book a demo</a>{" "}
            with our sales team to get started.
          </p>
        );
        break;
      case "INACTIVE_EMAIL":
        setPageError(
          "The confirmation email could not be delivered to the specific address. Please check the email address and try again."
        );
        break;
      case "USER_EXISTS":
        setPageError(
          "The email address you have entered is already registered."
        );
        break;
      case "CANNOT_SCAN_DOMAIN":
        setPageError(
          `Unfortunately we cannot execute an initial scan on your email domain (${
            email.email.split("@")[1]
          }) to automatically start your free trial. Please contact sales@upguard.com to get in touch with someone who can help.`
        );
        break;
      case "ORG_EXISTS":
        setPageError(
          `It looks like your company (${
            email.email.split("@")[1]
          }) already has an account. Please get in touch with support@upguard.com to see if we can help you get access.`
        );
        break;
      case "INELIGIBLE":
        setPageError(
          `Sorry, but your company is ineligible for a free trial. Please get in touch with support@upguard.com to see if we can help you get access.`
        );
        break;
      case "CANNOT_SCAN_VENDOR_REPORT_DOMAIN":
        setPageError(
          `The hostname for the requested vendor report is invalid. Please check the URL and try again.`
        );
        break;
    }
  };

  const validateUserDetails = async () => {
    if (!email.email || !email.valid || firstName == "" || lastName == "") {
      return;
    }
    setLoading(true);

    try {
      const country = await getCountryInfo();

      const fetchUrl = buildPathWithParamsAndTracking(
        "/api/accounts/validate_trial/v1",
        {
          email: email.email,
          country: country,
          how_did: howDid.value,
          first_name: firstName,
          last_name: lastName,
          unique_id: leadUUID,
          vendor_report_domain: vendor_report_domain
            ? vendor_report_domain
            : "",
        }
      );

      const result = await fetch(fetchUrl, {
        method: "POST",
      });

      if (result.status === 200) {
        const { region, calendar }: { region: string; calendar: string } =
          await result.json();
        setRegion(region);
        setCalendar(calendar);
        setPageError(null);

        await SendAnonymousEvent(
          email.email,
          GetAnonymousTrackingID(),
          "SelfSignUp_UserProvidedNameAndEmail"
        );

        // Navigate to the next required step, or complete registration if nothing else is required
        // If the user is signing up for a vendor report, complete the registration immediately

        // TODO: for PLG onboarding, when live I think we'll want to just forward them to
        // registerForTrial(); from about here.

        if (!!vendor_report_domain) {
          await registerForTrial();
        } else if (freeTrialUseCaseRequired === true) {
          setPage(2);
        } else if (freeTrialMeetingRequired === true) {
          setPage(3);
        } else {
          // We don't need any more steps - attempt to register the trial
          await registerForTrial();
        }
      } else if (result.status === 422) {
        const { error }: { error: string } = await result.json();
        handleError(error);
      } else {
        console.error(`got unexpected status code: ${result.status}`);
        setPageError(
          "An unknown error occurred. Please contact support@upguard.com."
        );
      }
    } catch (e) {
      console.error(e);
      setPageError(
        "An unknown error occurred. Please contact support@upguard.com."
      );
    }
    setLoading(false);
  };

  const buildPathWithParamsAndTracking = (
    path: string,
    params: Record<string, string>
  ): string => {
    const trackingParams = {
      utm_source,
      utm_medium,
      utm_content,
      utm_campaign,
      utm_term,
    };
    Object.entries(trackingParams).forEach(([param, value]) => {
      if (value?.length > 0) {
        params[param] = value;
      }
    });
    return appendParams(path, params);
  };

  const registerForTrial = async () => {
    setLoading(true);

    try {
      const country = await getCountryInfo();

      const params = {
        email: email.email,
        country: country,
        how_did: howDid.value,
        use_cases: useCases,
        use_case_other: otherText,
        vendor_report_domain: vendor_report_domain ? vendor_report_domain : "",
        first_name: firstName,
        last_name: lastName,
        unique_id: leadUUID,
      };

      const fetchUrl = buildPathWithParamsAndTracking(
        "/api/accounts/register_trial/v1",
        params
      );
      let result = await fetch(fetchUrl, { method: "POST" });

      // Sometimes there is a timeout when we request a rescan from webscan, particularly if we are creating a vendor
      // for this domain for the first time.  In this specific case, call the endpoint one more time to see if we
      // now have a vendor
      let errorResult: string | undefined = undefined;
      if (result.status === 422) {
        const { error }: { error: string } = await result.json();
        // Check the error response code
        if (error === "SCAN_TIMEOUT") {
          result = await fetch(fetchUrl, { method: "POST" });
          if (result.status === 422) {
            const { error }: { error: string } = await result.json();
            errorResult = error;
          }
        } else {
          errorResult = error;
        }
      }

      // Handle the response
      if (result.status === 204) {
        setShowThankyou(true);
      } else if (result.status === 422) {
        if (errorResult) {
          handleError(errorResult);
        }

        setRegistrationError(true);
      } else {
        console.error(`got unexpected status code: ${result.status}`);
        setRegistrationError(true);
      }
    } catch (e) {
      console.error(e);
      setRegistrationError(true);
    }
    setLoading(false);
  };

  const contactSupport: MouseEventHandler = (e) => {
    e.preventDefault();
    window.open("mailto:support@upguard.com");
  };

  const howDidYouHearAboutUpGuardOptions = [
    "Google",
    "Gartner or G2",
    "From a friend or colleague",
    "Social media",
    "News outlet",
    "Events",
    "I've used UpGuard as a vendor",
    "Other (eg, other search engines or other comparison sites)",
  ] as string[];

  const howOptions = howDidYouHearAboutUpGuardOptions.map((str) => {
    return { value: str, label: str } as ISelectItem;
  });

  const onMeetingBooked = () => {
    setMeetingBooked(true);
    registerForTrial();
  };

  const handleUseCaseEnteredClick = () => {
    // Go to meeting booking if this step is enabled
    if (freeTrialMeetingRequired) {
      setPage(3);
    } else {
      // No meeting required - let's try and register the trial
      registerForTrial();
    }
  };

  const handleEmailChanged = (email: string, valid: boolean) => {
    setEmail({ email, valid });

    // All the page errors are around the entered email address.
    // Disable form submission on error, and clear the error
    // when the email changes.
    setPageError("");
  };

  const startTrialText = () => {
    return !!vendor_report_domain
      ? "Sign up for your free security report"
      : "Start your free trial";
  };

  useEffect(() => {
    // If all required fields are given as query params, let's fill them out and submit the form
    // The signup forms on https://upguard.com use this functionality
    if (!!email_address && !!first_name && !!last_name) {
      if (email.valid) {
        setPrefilled(true);
        validateUserDetails();
      } else {
        setPageError("Please enter a valid email address and try again.");
      }
    }
  }, []);

  const openInNewTab = (url: string) => {
    window.open(url, "_blank", "noopener noreferrer");
  };

  return (
    <FreeTrialRegisterStyles>
      <div className="free-trial-left">
        <div className={"img-box"}>
          <img src={UGLogo} width={"200"} />
        </div>

        <div className={"free-trial-nav"}>
          <div className={"container-middle"}>
            {!!vendor_report_domain ? (
              <>
                <div className={"container-header"}>
                  Download your free Security Report
                </div>
                <div className={"container-content"}>
                  <p>
                    {
                      "You'll receive a detailed security performance report covering 13 risk factors such as email security, SSL, DNS health, and common vulnerabilities."
                    }
                  </p>
                  <div className={"checklist"}>
                    <div>
                      <img src={Tick} />
                      <span>{"14-day fully functional UpGuard trial"}</span>
                    </div>
                    <div>
                      <img src={Tick} />
                      <span>
                        {"Get complete visibility into your security posture"}
                      </span>
                    </div>
                    <div>
                      <img src={Tick} />
                      <span>
                        {"Access security reports for 10m+ companies"}
                      </span>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className={"container-header"}>
                  {"Reduce your cybersecurity risk"}
                </div>
                <div className={"container-content"}>
                  <p>
                    Join thousands of security-conscious companies using UpGuard
                    to manage their attack surface and vendor risk management
                    programs.
                  </p>
                  <div className={"checklist"}>
                    <div>
                      <img src={Tick} />
                      <span>
                        {
                          "Empower your team with data-driven security ratings you can rely on"
                        }
                      </span>
                    </div>
                    <div>
                      <img src={Tick} />
                      <span>
                        {
                          "Streamline risk management and remediation with proven, auditable workflows"
                        }
                      </span>
                    </div>
                    <div>
                      <img src={Tick} />
                      <span>
                        {
                          "Leverage powerful AI features to enhance your security posture"
                        }
                      </span>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
          <div
            className={"container-footer"}
          >{`©${new Date().getFullYear()} UpGuard, Inc.`}</div>
        </div>
      </div>
      <div className="free-trial-right">
        <div className="free-trial-main">
          {!freeTrialSignupEnabled ? (
            <>
              <h2>
                Sorry, we&apos;re not currently accepting signups via this page.
              </h2>
              <p>
                Please <a href="https://www.upguard.com/demo">book a demo</a>{" "}
                with our sales team instead.
              </p>
            </>
          ) : registrationError ? (
            <>
              <h2>We&apos;re sorry, something went wrong</h2>
              {/* Show either specific error if we have one or a generic error if we couldn't complete the signup */}
              {pageError ? (
                <p className={"thankyou"}>{pageError}</p>
              ) : (
                <p className={"thankyou"}>
                  Unfortunately, there was a problem setting up your account.
                  Please contact support@upguard.com to complete your free trial
                  registration.
                </p>
              )}
            </>
          ) : showThankyou ? (
            <>
              <h2>Please check your email</h2>
              <p className={"thankyou"}>
                You should have an email from us with a link to set up your
                account. If you don&apos;t receive an email within the next 30
                minutes, please{" "}
                <a href="#" onClick={contactSupport}>
                  contact support
                </a>
                .
              </p>
              <div className={"button-bar"}>
                <Button
                  className={"email-button"}
                  onClick={() => {
                    openInNewTab(
                      "https://mail.google.com/mail/u/0/#search/upguard"
                    );
                  }}
                >
                  <img src={GmailLogo} width={"26.32"} />
                  {"Open Gmail"}
                </Button>
                <Button
                  className={"email-button email-button-right"}
                  onClick={() => {
                    openInNewTab(
                      "https://outlook.live.com/mail/search?q=upguard"
                    );
                  }}
                >
                  <img src={OutlookLogo} width={"26.32"} />
                  {"Open Outlook"}
                </Button>
              </div>
            </>
          ) : page == 1 ? (
            prefilled && loading ? (
              // when the user has already prefilled the form, we don't want to show
              // them a different form with the same data.
              <LoadingBanner />
            ) : (
              <div className="free-trial-main-content">
                <h2>{startTrialText()}</h2>
                <p className="trial-sub-header">
                  Get 14 days for free, no credit card required.
                </p>
                <div className="field-label">Work email address*</div>
                <div className="field-with-icon">
                  <img src={MailIcon} />
                  <TextField
                    value={email.email}
                    type="email"
                    hideMessageTexts
                    onChanged={(email, valid) =>
                      handleEmailChanged(email, valid)
                    }
                    placeholder="you@company-email.com"
                  />
                </div>
                <div className="field-label">First name*</div>
                <TextField
                  value={firstName}
                  required={true}
                  type="text"
                  hideMessageTexts
                  onChanged={(value) => setFirstName(value)}
                  placeholder="First name"
                />
                <div className="field-label">Last name*</div>
                <TextField
                  value={lastName}
                  required={true}
                  type="text"
                  hideMessageTexts
                  onChanged={(value) => setLastName(value)}
                  placeholder="Last name"
                />
                <div className="field-label">
                  How did you hear about UpGuard?
                </div>
                <SelectV2
                  placeholder={"Select an option"}
                  isClearable={false}
                  isSearchable={false}
                  options={howOptions}
                  value={howDid.value ? howDid : undefined}
                  onChange={(value) => {
                    setHowDid(value as ISelectItem);
                  }}
                />
                {pageError && pageError != "" && (
                  <InfoBanner
                    type={BannerType.WARNING}
                    message={pageError}
                    subItems={[]}
                  />
                )}
                <div className={"button-container"}>
                  <Button
                    filledPrimary
                    type="button"
                    className={"next-button"}
                    disabled={
                      !email.email ||
                      !email.valid ||
                      !firstName ||
                      !lastName ||
                      !!pageError
                    }
                    loading={loading}
                    onClick={() => validateUserDetails()}
                  >
                    {!!vendor_report_domain ? "Sign Up" : "Next"}{" "}
                    <div className="cr-icon-arrow-right" />
                  </Button>
                </div>
                <div className="trial-footer">
                  By signing up, you agree to our{" "}
                  <a
                    href="https://www.upguard.com/company/terms-and-conditions"
                    rel={"noreferrer"}
                    target="_blank"
                  >
                    Website Terms and Conditions
                  </a>
                  ,{" "}
                  <a
                    target={"_blank"}
                    rel={"noreferrer"}
                    href={
                      "https://www.upguard.com/company/platform-terms-and-conditions"
                    }
                  >
                    Platform Terms and Conditions
                  </a>{" "}
                  and{" "}
                  <a
                    target={"_blank"}
                    rel={"noreferrer"}
                    href={"https://www.upguard.com/company/privacy"}
                  >
                    Privacy Policy
                  </a>
                </div>
              </div>
            )
          ) : page == 2 ? (
            <div className="free-trial-main-content">
              <h2>{startTrialText()}</h2>
              <div className="field-label">
                What are your organization&apos;s cybersecurity goals?
              </div>
              <FreeTrialUseCases
                initialSelections={useCases}
                initialOther={otherText}
                onSetOther={setOtherText}
                onSetUseCases={setUseCases}
              />
              <div className={"button-container"}>
                <Button
                  className={"back-button"}
                  disabled={loading}
                  onClick={() => setPage(1)}
                >
                  <div className="cr-icon-arrow-right rot180" />
                  Back
                </Button>
                <Button
                  filledPrimary
                  type="button"
                  className={"next-button"}
                  disabled={
                    !email.email ||
                    !email.valid ||
                    !howDid ||
                    !(
                      (useCases.indexOf("None of") >= 0 && !!otherText) ||
                      (useCases && useCases.indexOf("None of") < 0)
                    )
                  }
                  loading={loading}
                  onClick={() => handleUseCaseEnteredClick()}
                >
                  {freeTrialMeetingRequired ? "Next" : "Sign Up"}{" "}
                  <div className="cr-icon-arrow-right" />
                </Button>
              </div>
            </div>
          ) : (
            <div className="free-trial-main-content">
              <form onSubmit={registerForTrial}>
                <h2>{startTrialText()}</h2>
                <div className="description">
                  Lastly, book in a time with one of our product specialists to
                  ensure you&apos;re getting the most out of your trial.
                </div>
                <div className={"calendar-container"}>
                  {!meetingBooked && (
                    <HubspotMeeting
                      calendarId={calendar}
                      emailAddress={email.email}
                      firstName={firstName}
                      lastName={lastName}
                      onMeetingBooked={onMeetingBooked}
                    />
                  )}
                  {meetingBooked && !pageError && !showThankyou && (
                    <LoadingBanner />
                  )}
                </div>
                <div className={"button-container"}>
                  <Button
                    className={"back-button"}
                    disabled={loading || meetingBooked}
                    onClick={() => {
                      if (freeTrialUseCaseRequired) {
                        setPage(2);
                      } else {
                        setPage(1);
                      }
                    }}
                  >
                    <div className="cr-icon-arrow-right rot180" />
                    Back
                  </Button>
                </div>
              </form>
            </div>
          )}
        </div>
      </div>
      <PublicPageviewTracker uuid={leadUUID} />
    </FreeTrialRegisterStyles>
  );
};

export default appConnect()(FreeTrialRegister);
