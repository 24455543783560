import { IPAddress, IPRange, ipToInt } from "../../_common/types/ipAddresses";
import PillLabel, { getColorForKey } from "./PillLabel";
import { NumberWithCommas } from "../../_common/helpers";
import "../style/components/IPInfoTable.scss";
import { LabelClassification } from "../../_common/types/label";
import { DefaultThunkDispatch } from "../../_common/types/redux";
import {
  IpRangeToUpdate,
  setIpAddressAndIpAddressDetailState,
  setIpRangeState,
  updateIpLabels,
} from "../reducers/ipLabels.actions";
import LabelList from "./LabelList";
import { adjustLabels } from "../reducers/domains.actions";
import IpSourceLabelList from "./ip_addresses/IpSourceLabelList";

interface IIPAddressInfoTableProps {
  dispatch: DefaultThunkDispatch;
  vendorId?: number;
  isSubsidiary: boolean;
  ip: IPAddress;
  onClickIPRange?: (ipRangeID: number, customRangeID?: number) => void;
  showUpdateLabel?: boolean;
  showLabels?: boolean;
}

export const IPAddressInfoTable = ({
  dispatch,
  vendorId,
  isSubsidiary,
  ip,
  onClickIPRange,
  showUpdateLabel = true,
  showLabels = true,
}: IIPAddressInfoTableProps) => {
  return (
    <div className="ip-info">
      <div className="ip-info-row">
        <div className="ip-info-label">Source</div>
        <div className="ip-info-value">
          <IpSourceLabelList sources={ip.sources} isCustomer={!vendorId} />
        </div>
      </div>
      {!!ip.country && (
        <div className="ip-info-row">
          <div className="ip-info-label">Owner</div>
          <div className="ip-info-value">{ip.owner}</div>
        </div>
      )}

      {!!ip.rangeStart && !!ip.rangeEnd && (
        <div className="ip-info-row">
          <div className="ip-info-label">IP range</div>
          <div className="ip-info-value">
            {onClickIPRange ? (
              <a onClick={() => onClickIPRange(ip.ipRangeID, ip.customRangeID)}>
                {ip.rangeStart} <i className="cr-icon-arrow-right" />{" "}
                {ip.rangeEnd}
              </a>
            ) : (
              <>
                {ip.rangeStart} <i className="cr-icon-arrow-right" />{" "}
                {ip.rangeEnd}
              </>
            )}
          </div>
        </div>
      )}

      {!!ip.country && (
        <div className="ip-info-row">
          <div className="ip-info-label">Country</div>
          <div className="ip-info-value">{ip.country}</div>
        </div>
      )}

      {!!ip.asn && (
        <>
          <div className="ip-info-row">
            <div className="ip-info-label">Autonomous System (AS)</div>
            <div className="ip-info-value">{ip.asName}</div>
          </div>
          <div className="ip-info-row">
            <div className="ip-info-label">ASN</div>
            <div className="ip-info-value">AS{ip.asn}</div>
          </div>
        </>
      )}

      {!!ip.services && (
        <div className="ip-info-row">
          <div className="ip-info-label">Services</div>
          <div className="ip-info-value">
            {ip.services.map((s) => (
              <PillLabel key={s} color={getColorForKey(s)}>
                {s}
              </PillLabel>
            ))}
          </div>
        </div>
      )}
      {showLabels && (
        <>
          <div className="ip-info-row">
            <div className="ip-info-label">Labels</div>
            <div className="ip-info-value">
              <>
                <LabelList
                  labels={ip.labels}
                  maxWidth={300}
                  classification={LabelClassification.WebsiteLabel}
                  updateLabelsHeader={`Update labels for ${ip.ip}`}
                  onLabelsUpdated={
                    showUpdateLabel
                      ? (availableLabels, addedLabelIds, removedLabelIds) => {
                          return dispatch(
                            updateIpLabels({
                              labelsToAdd: addedLabelIds,
                              labelsToRemove: removedLabelIds,
                              ipRanges: [
                                {
                                  start: ip.ip,
                                  end: ip.ip,
                                } as IpRangeToUpdate,
                              ],
                              datastoreVendorId: vendorId,
                              isSubsidiary: isSubsidiary,
                            })
                          ).then(() => {
                            const updatedIpAddresses = adjustLabels<IPAddress>(
                              [ip],
                              () => true,
                              availableLabels,
                              addedLabelIds,
                              removedLabelIds
                            );

                            dispatch(
                              setIpAddressAndIpAddressDetailState(
                                updatedIpAddresses[0],
                                vendorId,
                                isSubsidiary
                              )
                            );
                          });
                        }
                      : undefined
                  }
                />
              </>
            </div>
          </div>
          {ip.portfolios && ip.portfolios.length > 0 && (
            <div className="ip-info-row">
              <div className="ip-info-label">Portfolio</div>
              <div className="ip-info-value">
                {ip.portfolios.map((p) => p.name).join(", ")}
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

interface IIPRangeInfoTableProps {
  dispatch: DefaultThunkDispatch;
  vendorId?: number;
  isSubsidiary: boolean;
  range: IPRange;
  showUpdateLabel?: boolean;
}

export const IPRangeInfoTable = ({
  dispatch,
  vendorId,
  isSubsidiary,
  range,
  showUpdateLabel = true,
}: IIPRangeInfoTableProps) => {
  return (
    <div className="ip-range-info">
      <div className="ip-info-row">
        <div className="ip-info-label">Owner</div>
        <div className="ip-info-value">{range.owner}</div>
      </div>

      <div className="ip-info-row">
        <div className="ip-info-label">Registrant Country</div>
        <div className="ip-info-value">{range.country}</div>
      </div>

      {!!range.asn && (
        <>
          <div className="ip-info-row">
            <div className="ip-info-label">Autonomous System (AS)</div>
            <div className="ip-info-value">{range.asName}</div>
          </div>
          <div className="ip-info-row">
            <div className="ip-info-label">ASN</div>
            <div className="ip-info-value">AS{range.asn}</div>
          </div>
        </>
      )}

      <div className="ip-info-row">
        <div className="ip-info-label">IPs in range</div>
        <div className="ip-info-value">
          {NumberWithCommas(ipToInt(range.end) - ipToInt(range.start) + 1)}
        </div>
      </div>
      <div className="ip-info-row">
        <div className="ip-info-label">Labels</div>
        <div className="ip-info-value">
          <>
            <LabelList
              labels={range.labels}
              maxWidth={300}
              classification={LabelClassification.WebsiteLabel}
              updateLabelsHeader={`Update labels for ${range.start} -> ${range.end}`}
              onLabelsUpdated={
                showUpdateLabel
                  ? (availableLabels, addedLabelIds, removedLabelIds) =>
                      dispatch(
                        updateIpLabels({
                          labelsToAdd: addedLabelIds,
                          labelsToRemove: removedLabelIds,
                          ipRanges: [
                            {
                              start: range.start,
                              end: range.end,
                            } as IpRangeToUpdate,
                          ],
                          datastoreVendorId: vendorId,
                          isSubsidiary: isSubsidiary,
                        })
                      ).then(() => {
                        const updatedRanges = adjustLabels<IPRange>(
                          [range],
                          () => true,
                          availableLabels,
                          addedLabelIds,
                          removedLabelIds
                        );

                        dispatch(
                          setIpRangeState(
                            updatedRanges[0],
                            vendorId,
                            isSubsidiary
                          )
                        );
                      })
                  : undefined
              }
            />
          </>
        </div>
      </div>
      {range.portfolios && range.portfolios.length > 0 && (
        <div className="ip-info-row">
          <div className="ip-info-label">Portfolio</div>
          <div className="ip-info-value">
            {range.portfolios.map((p) => p.name).join(", ")}
          </div>
        </div>
      )}
    </div>
  );
};
