import * as React from "react";
import { CheckboxPropType, NestedCheckbox } from "./NestedCheckbox";

interface CheckboxGroupProps {
  checkboxes: CheckboxPropType[];
  onClick: (id: string, subSectionId?: string) => void;
}

export const CheckboxGroup: React.VFC<CheckboxGroupProps> = ({
  checkboxes,
  onClick,
}) => (
  <div>
    {checkboxes.map((checkbox) => (
      <NestedCheckbox key={checkbox.id} checkbox={checkbox} onClick={onClick} />
    ))}
  </div>
);
