import { DefaultThunkDispatch } from "../../_common/types/redux";
import { DefaultRootState } from "react-redux";
import { FetchCyberRiskUrl } from "../../_common/api";
import { LogError } from "../../_common/helpers";
import { conditionalRefreshActivityStreamForOrgUser } from "../../_common/reducers/commonActions";
import { IVendorHierarchy } from "../../_common/types/vendor";
import { setCustomerData } from "./cyberRiskActions";
import { ILabel } from "../../_common/types/label";
export const CLEAR_SUBSIDIARY_DATA = "CLEAR_SUBSIDIARY_DATA";
export const clearSubsidiaryData = () => {
  return {
    type: CLEAR_SUBSIDIARY_DATA,
  };
};

export interface SubsidiaryLabelUpdateRequest {
  allLabels: ILabel[];
  labelsToAdd?: number[];
  labelsToRemove?: number[];
  vendorIds: number[];
}

export const updateSubsidiaryLabels = (
  updateRequest: SubsidiaryLabelUpdateRequest
) => {
  return async (
    dispatch: DefaultThunkDispatch,
    getState: () => DefaultRootState
  ): Promise<void> => {
    try {
      await FetchCyberRiskUrl<void>(
        "subsidiarylabels/v1/",
        {
          add_labels: updateRequest.labelsToAdd,
          remove_labels: updateRequest.labelsToRemove,
          vendor_ids: updateRequest.vendorIds,
        },
        {
          method: "PUT",
        },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("error updating subsidiary labels", e);

      throw e;
    }

    // kick off call to update the activity stream
    dispatch(conditionalRefreshActivityStreamForOrgUser());

    // Update subsidiary labels in state
    const subsidiaries = getState().cyberRisk.customerData.subsidiaries;
    if (subsidiaries) {
      const newSubsidiaries = adjustSubsidiaryLabels(
        subsidiaries,
        (i) => updateRequest.vendorIds.indexOf(i.vendorId) > -1,
        updateRequest.allLabels,
        updateRequest.labelsToAdd || [],
        updateRequest.labelsToRemove || []
      );

      dispatch(setSubsidiaryState(newSubsidiaries));
    }
  };
};

export const adjustSubsidiaryLabels = (
  subsidiary: IVendorHierarchy,
  shouldApplyLabelChangeFunc: (item: IVendorHierarchy) => boolean,
  allLabels: ILabel[],
  addedLabelIds: number[],
  removedLabelIds: number[]
) => {
  const labelsToAdd = allLabels.filter(
    (l) => addedLabelIds.indexOf(l.id) !== -1
  );
  const dupedSubsidiary = { ...subsidiary };

  if (shouldApplyLabelChangeFunc(subsidiary)) {
    // Remove labels
    if (removedLabelIds.length > 0 && dupedSubsidiary.labels) {
      dupedSubsidiary.labels = dupedSubsidiary.labels.filter(
        (l) => removedLabelIds.indexOf(l.id) === -1
      );
    }
    // Add labels
    if (labelsToAdd.length > 0) {
      if (dupedSubsidiary.labels) {
        dupedSubsidiary.labels = [...dupedSubsidiary.labels, ...labelsToAdd];
      } else {
        dupedSubsidiary.labels = [...labelsToAdd];
      }
    }
  }

  if (dupedSubsidiary && dupedSubsidiary.children) {
    for (let i = 0; i < dupedSubsidiary.children.length; i++) {
      const newChild = adjustSubsidiaryLabels(
        dupedSubsidiary.children[i],
        shouldApplyLabelChangeFunc,
        allLabels,
        addedLabelIds,
        removedLabelIds
      );
      dupedSubsidiary.children[i] = newChild;
    }
  }

  return dupedSubsidiary;
};

export const setSubsidiaryState = (subsidiary: IVendorHierarchy) => {
  return (dispatch: DefaultThunkDispatch): void => {
    dispatch(
      setCustomerData({
        subsidiaries: subsidiary,
      })
    );
  };
};

// getSubsidiaryName - retrieve the name of a vendor from a hierarchy, given its vendorID
export const getSubsidiaryName = (
  vendorId: number,
  subsidiaries?: IVendorHierarchy
): string | undefined => {
  if (!subsidiaries) {
    return undefined;
  }
  if (subsidiaries.vendorId == vendorId) {
    return subsidiaries.name;
  }
  if (subsidiaries.children) {
    for (let i = 0; i < subsidiaries.children.length; i++) {
      const name = getSubsidiaryName(vendorId, subsidiaries.children[i]);
      if (name) {
        return name;
      }
    }
  }
  return undefined;
};
