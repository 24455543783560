import PillLabel from "../../vendorrisk/components/PillLabel";

import { App } from "../api/types";
import { FC } from "react";

import "./AppStatusDisplay.scss";
import classnames from "classnames";
import { statusColor, statusDisplayText, statusIcon } from "../helpers/apps";

export interface AppStatusProps {
  app: App;
  large?: boolean;
}

const AppStatusDisplay: FC<AppStatusProps> = ({ app, large = false }) => {
  const icon = statusIcon(app);
  const text = statusDisplayText(app);
  const color = statusColor(app);

  return (
    <PillLabel color={color} large={large}>
      <div className={"app-status"}>
        <i className={classnames("app-status-icon", icon)} />
        <div>{text}</div>
      </div>
    </PillLabel>
  );
};

export default AppStatusDisplay;
