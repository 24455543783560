import XTable, { IXTableProps } from "../../_common/components/core/XTable";

interface IPaginatedTableProps extends IXTableProps {
  pageSize: number;
  page: number;
  onPageChange: (page: number) => void;
  totalRows: number;
  prePagedRows: boolean;
  onSelectToggleCurrentPage: (
    selectableRowIDs: (string | number)[],
    select: boolean
  ) => void;
  onSelectAllCurrentPage: (selectableRowIDs: (string | number)[]) => void;
  onSelectNoneCurrentPage: (selectableRowIDs: (string | number)[]) => void;
}

/**
 * <PaginatedTable />
 * Creates a table from an array of rows, ensuring they are paginated correctly.
 * See the propTypes for a description of the required parameters.
 */
const PaginatedTable = (props: IPaginatedTableProps) => {
  const {
    rows,
    pageSize,
    page,
    onPageChange,
    totalRows,
    prePagedRows,
    onSelectToggleCurrentPage,
    onSelectAllCurrentPage,
    onSelectNoneCurrentPage,
    ...xTableProps
  } = props;

  let { onSelectToggle, onSelectAllClick, onSelectNoneClick } = props;

  // Original - uses _chunk.
  /*
  const chunkedRows = _chunk(rows, pageSize);
  const curPage = page < chunkedRows.length ? page : (chunkedRows.length - 1);

  return (
    <div className={className}>
      <XTable
        rows={chunkedRows.length > 0 ? chunkedRows[curPage] : []}
        pagination={(chunkedRows.length > 1) ? {
          currentPage: curPage + 1,
          totalPages: chunkedRows.length,
          onPageChange
        } : null}
        {...xTableProps}
      />
    </div>
  );
  */

  // GT: not using chunk - roll your own (makes sure data is used in-place)
  let numRows = 0;
  if (prePagedRows) {
    numRows = totalRows;
  } else {
    numRows = rows.length;
  }
  let numPages = Math.trunc(numRows / pageSize);
  if (numRows % pageSize > 0) {
    numPages = numPages + 1;
  }
  let currPage = page;
  if (currPage >= numPages) {
    currPage = numPages - 1;
  }
  let chunkedRows = [];
  if (prePagedRows) {
    chunkedRows = rows;
  } else {
    const offset = currPage * pageSize;
    chunkedRows = rows.slice(offset, offset + pageSize);
  }

  // if caller provided the page-specific select all/none functions,
  // wire them through to XTable
  const selectableRowIDs = chunkedRows
    .filter((r) => !r.selectionDisabled)
    .map((r) => r.id);
  if (onSelectToggleCurrentPage) {
    onSelectToggle = (select) =>
      onSelectToggleCurrentPage(selectableRowIDs, select);
  }
  if (onSelectAllCurrentPage) {
    onSelectAllClick = () => onSelectAllCurrentPage(selectableRowIDs);
  }
  if (onSelectNoneCurrentPage) {
    onSelectNoneClick = () => onSelectNoneCurrentPage(selectableRowIDs);
  }

  return (
    <XTable
      rows={chunkedRows}
      pagination={
        numPages > 1
          ? {
              currentPage: currPage + 1,
              totalPages: numPages,
              onPageChange,
            }
          : undefined
      }
      onSelectToggle={onSelectToggle}
      onSelectAllClick={onSelectAllClick}
      onSelectNoneClick={onSelectNoneClick}
      {...xTableProps}
    />
  );
};

PaginatedTable.defaultProps = {
  prePagedRows: false,
  totalRows: 0,
  onSelectToggleCurrentPage: null,
  onSelectAllCurrentPage: null,
  onSelectNoneCurrentPage: null,
};

export default PaginatedTable;
