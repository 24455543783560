import { PureComponent } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { throttle as _throttle } from "lodash";

import "../style/components/ScrollableDiv.scss";

/**
 * <ScrollableDiv />
 * ScrollableDiv wraps an inner scrollable content and fades in a shadow at the top to indicate the content has been scrolled.
 */
class ScrollableDiv extends PureComponent {
  static propTypes = {
    children: PropTypes.any.isRequired,
    newStyles: PropTypes.bool,
    leaveSpaceAtBottom: PropTypes.bool,
  };

  static defaultProps = {
    newStyles: false,
    leaveSpaceAtBottom: false,
  };

  state = { shadowOpacity: 0, distanceFromTop: 0 };

  componentDidMount() {
    // Set the distance from the top so it can be adjusted to fit the height of the page.
    if (this.scrollableDiv) {
      this.setState({
        distanceFromTop:
          this.scrollableDiv.getBoundingClientRect().top +
          (this.props.leaveSpaceAtBottom
            ? 136 // Bottom button group height
            : 0),
      });
    }

    // On scroll, find the offset and if it's under 100px, use that value to calculate the percentage opacity.
    // Throttle this function to run at most every 50ms
    if (this.scrollableContent) {
      this.scrollListener = this.scrollableContent.addEventListener(
        "scroll",
        _throttle((evt) => {
          const scrolled = evt.target.scrollTop;

          if (scrolled < 100) {
            this.setState({ shadowOpacity: scrolled / 100 });
          } else if (this.state.shadowOpacity < 1) {
            this.setState({ shadowOpacity: 1 });
          }
        }, 50)
      );
    }
  }

  componentWillUnmount() {
    document.removeEventListener(this.scrollableContent, this.scrollListener);
  }

  render() {
    const divStyle = {};
    if (this.state.distanceFromTop) {
      divStyle.height = `calc(100vh - ${this.state.distanceFromTop}px)`;
    }

    return (
      <div
        className={classnames("scrollable-div", {
          "new-styles": this.props.newStyles,
        })}
        style={divStyle}
        ref={(ref) => (this.scrollableDiv = ref)}
      >
        <div
          className={classnames("scrollable-shadow", {
            "new-styles": this.props.newStyles,
          })}
          style={{ opacity: this.state.shadowOpacity }}
        />
        <div
          className={classnames("scrollable-content", {
            "new-styles": this.props.newStyles,
          })}
          ref={(ref) => (this.scrollableContent = ref)}
        >
          {this.props.children}
        </div>
      </div>
    );
  }
}

export default ScrollableDiv;
