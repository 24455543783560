import { ILabel, LabelClassification } from "../../../_common/types/label";
import { FC, useState } from "react";
import {
  OptionType,
  SelectV2Multi,
} from "../../../_common/components/SelectV2";
import { SlidePanelSection } from "./SlidePanelSection";
import ColorCheckbox from "../ColorCheckbox";
import Icon from "../../../_common/components/core/Icon";
import LabelList from "../LabelList";
import { StylesConfig, ValueType } from "react-select";
import { appConnect } from "../../../_common/types/reduxHooks";
import { useVendorWords } from "../../../_common/hooks";
import { SidePopupV2 } from "../../../_common/components/DismissablePopup";

const UNLABELED_LABEL = {
  id: "unlabeled",
  name: "Unlabelled",
};

interface onChangeObj {
  selectedOptions?: number[];
  includeUnlabeled?: boolean;
  matchAll?: boolean;
  doNotMatch?: boolean;
}

interface ILabelsFilterOwnProps {
  selectedOptions: number[];
  includeUnlabeled?: boolean;
  onChange: (changeObj: onChangeObj) => void;
  supportedClassifications: LabelClassification[];
  unlabeledSupported?: boolean;
  matchAll?: boolean;
  matchAllSupported?: boolean;
  doNotMatch?: boolean;
  title?: string;
  startExpanded?: boolean;
}

interface ILabelsFilterConnectedProps {
  availableLabels: ILabel[];
}

type ILabelsFilterProps = ILabelsFilterOwnProps & ILabelsFilterConnectedProps;

export const LabelsFilter: FC<ILabelsFilterProps> = ({
  onChange,
  availableLabels,
  selectedOptions = [],
  matchAll = false,
  matchAllSupported = false,
  supportedClassifications,
  includeUnlabeled = false,
  unlabeledSupported = false,
  doNotMatch = false,
  title = "Filter by label",
  startExpanded = false,
}) => {
  const [expanded, setExpanded] = useState(
    startExpanded || selectedOptions.length > 0
  );

  const activeRadioButton = matchAll ? "all" : doNotMatch ? "not" : "any";

  const selectOnChange = (selectedOptions: ValueType<OptionType, true>) => {
    const includeUnlabeled =
      selectedOptions?.some(({ value }) => value === UNLABELED_LABEL.id) ??
      false;

    const changeObj: onChangeObj = {
      includeUnlabeled,
      selectedOptions: selectedOptions
        ? selectedOptions
            .filter(({ value }) => value !== UNLABELED_LABEL.id)
            .map(({ value }) => value as number)
        : [],
    };

    if (includeUnlabeled && matchAllSupported) {
      // matchAll is incompatible with the Unlabeled filter, so always turn it off if includeUnlabeled is true.
      changeObj.matchAll = false;
    }

    onChange(changeObj);
  };

  const supportedLabels = [
    ...availableLabels.filter(({ classification }) =>
      supportedClassifications.includes(classification)
    ),
  ];

  const options = (() => {
    const options = supportedLabels
      .map(({ id, name }) => ({
        value: id,
        label: name,
      }))
      .sort((a, b) => {
        return a.label.toLowerCase().localeCompare(b.label.toLowerCase());
      });

    return [
      ...(unlabeledSupported
        ? [{ value: UNLABELED_LABEL.id, label: UNLABELED_LABEL.name }]
        : []),
      ...options,
    ];
  })();

  const selectValue: OptionType[] = [
    ...selectedOptions.map(
      (id) => options.find(({ value }) => value === id) as OptionType
    ),
    ...(unlabeledSupported && includeUnlabeled
      ? [options[0]] // The "Unlabelled" label will always be at the front
      : []),
  ];

  const selectedLabels = selectValue.map(({ value }) => ({
    ...[
      ...supportedLabels,
      ...(unlabeledSupported ? [UNLABELED_LABEL] : []),
    ].find((label) => value === label.id),
    removeable: true,
    large: true,
    constrained: true,
    onRemoveClick: () =>
      selectOnChange(selectValue.filter((option) => value !== option.value)),
  }));

  const customStyles: StylesConfig<OptionType, true> = {
    option: (provided, state) => {
      if (state.label === "Unlabelled") {
        const color = "#8BABE1";
        return { ...provided, color };
      }
      return { ...provided };
    },
  };

  const vendorWords = useVendorWords();

  const filterTarget = supportedClassifications.includes(
    LabelClassification.WebsiteLabel
  )
    ? "assets"
    : vendorWords.plural;

  return (
    <SlidePanelSection
      title={title}
      expanded={expanded}
      toggleExpand={() => setExpanded(!expanded)}
    >
      {matchAllSupported && (
        <div className="filter-label-options">
          <div className="label-filter-checkbox">
            <ColorCheckbox
              radio
              label="Match any"
              onClick={() => onChange({ matchAll: false, doNotMatch: false })}
              checked={activeRadioButton === "any"}
            />
            <SidePopupV2
              className="help-icon"
              text={`Show ${filterTarget} that include any of these specific labels`}
              position={"left"}
            >
              <Icon name="info" />
            </SidePopupV2>
          </div>

          <div className="label-filter-checkbox">
            <ColorCheckbox
              radio
              label="Match all"
              onClick={() => onChange({ matchAll: true, doNotMatch: false })}
              checked={activeRadioButton === "all"}
              disabled={includeUnlabeled}
            />
            <SidePopupV2
              className="help-icon"
              text={
                includeUnlabeled
                  ? `Unavailable when ${UNLABELED_LABEL.name} is selected`
                  : `Show ${filterTarget} that include all of these specific labels`
              }
              position={"left"}
            >
              <Icon name="info" />
            </SidePopupV2>
          </div>

          <div className="label-filter-checkbox">
            <ColorCheckbox
              radio
              label="Do not include"
              onClick={() => onChange({ matchAll: false, doNotMatch: true })}
              checked={activeRadioButton === "not"}
            />
            <SidePopupV2
              className="help-icon"
              text={`Show ${filterTarget} that do not include these specific labels`}
              position={"left"}
            >
              <Icon name="info" />
            </SidePopupV2>
          </div>
        </div>
      )}
      <SelectV2Multi
        placeholder="Type to search labels"
        options={options}
        value={selectValue}
        onChange={selectOnChange}
        controlShouldRenderValue={false}
        captureMenuScroll={false}
        styles={customStyles}
      />
      <LabelList labels={selectedLabels} />
    </SlidePanelSection>
  );
};

export const LabelsFilterContainer = appConnect<
  ILabelsFilterConnectedProps,
  never,
  ILabelsFilterOwnProps
>((state) => {
  return {
    availableLabels: state.cyberRisk.availableLabels || [],
  };
})(LabelsFilter);
