import { DefaultThunkDispatch } from "../../_common/types/redux";
import { FetchCyberRiskUrl } from "../../_common/api";
import { LogError } from "../../_common/helpers";
import { addDefaultUnknownErrorAlert } from "../../_common/reducers/messageAlerts.actions";
import { DefaultRootState } from "react-redux";

export interface OrgVendorItem {
  datastoreVendorID: number;
  vendorName: string;
  vendorPrimaryHostname: string;
  vendorScore: number;
  isWatched: boolean;
  isLookup: boolean;
  isSubsidiary: boolean;
}

export interface OrgVendorFilter {
  namePrefix: string;
  lookupsIncluded: boolean;
  subsidiariesIncluded: boolean;
}

export interface ListVendorsV1Resp {
  vendors: OrgVendorItem[];
  total: number;
  filters: OrgVendorFilter;
}

// Fetch vendors for the current org
export const fetchOrgVendors = (
  namePrefix = "",
  pageNum = 1,
  pageSize = 400,
  includeLookups = true,
  includeSubsidiaries = true,
  vendorIds = [] as number[]
) => {
  return async (
    dispatch: DefaultThunkDispatch,
    getState: () => DefaultRootState
  ) => {
    let resp: ListVendorsV1Resp;
    try {
      resp = await FetchCyberRiskUrl<ListVendorsV1Resp>(
        "org_vendors/v1/",
        {
          name_prefix: namePrefix,
          page_num: pageNum,
          page_size: pageSize,
          include_lookups: includeLookups,
          include_subsidiaries: includeSubsidiaries,
          vendor_ids:
            vendorIds && vendorIds.length > 0 ? vendorIds.join(",") : undefined,
        },
        { method: "GET" },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("error retrieving vendor list", e);
      dispatch(addDefaultUnknownErrorAlert("Error retrieving vendor list"));

      throw e;
    }

    return resp;
  };
};
