import { FC } from "react";
import IdAndNameFilter from "./IdAndNameFilter";
import { getVendorAssetTypes } from "../../reducers/filters.actions";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../_common/types/reduxHooks";

interface VendorEvidenceTypesFilterProps {
  selected: number[];
  onChanged: (selectedIds: number[]) => void;
}

const VendorEvidenceTypesFilter: FC<VendorEvidenceTypesFilterProps> = ({
  selected,
  onChanged,
}) => {
  const dispatch = useAppDispatch();

  const selectedData = useAppSelector((state) => {
    return state.cyberRisk.vendorCurrentEvidenceTypes;
  });

  return (
    <IdAndNameFilter
      title={"Evidence types"}
      getValues={() => dispatch(getVendorAssetTypes())}
      values={selectedData}
      selected={selected}
      onChanged={onChanged}
    />
  );
};

export default VendorEvidenceTypesFilter;
