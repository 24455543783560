import { OptionType } from "../components/SelectV2";

export interface IJiraOptionValue {
  iconUrl: string;
  id: string;
  name: string;
  value: string;
  children: IJiraOptionValue[];
}

export interface IJiraSchema {
  type: string;
  items: string;
  custom: string;
  system: string;
  customId: number;
}

export interface IJiraField {
  details: {
    id: string;
    key: string;
    name: string;
    hasDefaultValue: boolean;
    defaultValue: IJiraOptionValue | null;
    allowedValues: IJiraOptionValue[] | null;
    required: boolean;
    custom: boolean;
    navigable: boolean;
    clauseNames: string[] | null;
    schema: IJiraSchema;
  };
  atomicType: string;
  isArray: boolean;
  editingSuggestion: string;
}

export interface IJiraIssueType {
  id: string;
  description: string;
  name: string;
  fields: IJiraField[];
}

export interface IJiraUser {
  accountId: string;
  displayName: string;
  emailAddress: string;
  avatar: string;
  accountType: string;
  active: boolean;
}

export interface IJiraComponent {
  id: string;
  name: string;
  description: string;
}

export interface IJiraUsersResponse {
  status: string;
  service: string;
  projectID: string;
  users: IJiraUser[];
}

export interface IJiraComponentsResponse {
  status: string;
  service: string;
  projectID: string;
  components: IJiraComponent[];
}

export interface IJiraIssue {
  id: string;
  key: string;
  summary: string;
  status: string;
}

export interface IJiraIssuesResponse {
  status: string;
  service: string;
  projectID: string;
  issues: IJiraIssue[];
}

export interface IJiraGroup {
  name: string;
  groupId: string;
  html: string;
}

export interface IJiraGroupsResponse {
  status: string;
  service: string;
  projectID: string;
  groups: IJiraGroup[];
}

export interface IJiraVersion {
  id: string;
  name: string;
  description: string;
}

export interface IJiraVersionsResponse {
  status: string;
  service: string;
  projectID: string;
  versions: IJiraVersion[];
}

const typesUsingSelect = [
  "user",
  "user-assignee",
  "group",
  "epiclink",
  "issuelink",
  "version",
];

const typesUsingMultiSelect = ["component"];

export const SerialiseJiraFieldValue = (type: string, value: any) => {
  if (typesUsingSelect.includes(type)) {
    // serialise OptionType for user select values
    value = `${value.value}|${value.label}`;
  }
  if (typesUsingMultiSelect.includes(type)) {
    // serialise OptionTypes for multi select values
    // multiselect values are encoded as | separate list of value{label} strings
    if (!Array.isArray(value)) {
      value = `${value.value}{${value.label}}`;
    } else {
      value = value.map((v: OptionType) => `${v.value}{${v.label}}`).join("$$");
    }
  }
  return `${type}|${value}`;
};

export const DeserialiseJiraFieldValue = (
  value: string
): OptionType | string => {
  if (value == "" || value == undefined || value == null) {
    return value;
  }
  // deserialise OptionType for user select value
  const sep = value.indexOf("|");
  const type = value.substr(0, sep);
  const val = value.substr(sep + 1);
  if (typesUsingSelect.includes(type)) {
    // deserialise a user/user-assignee into an OptionType object
    const sep = val.indexOf("|");
    return {
      value: val.substr(0, sep),
      label: val.substr(sep + 1),
    } as OptionType;
  }
  return val;
};

export const DeserialiseJiraFieldToValueOnly = (value: string): string => {
  if (value == "" || value == undefined || value == null) {
    return "";
  }
  // deserialise OptionType for user select value
  const sep = value.indexOf("|");
  const type = value.substr(0, sep);
  const val = value.substr(sep + 1);
  if (typesUsingSelect.includes(type)) {
    // deserialise a user/user-assignee into an OptionType object
    const sep = val.indexOf("|");
    return val.substr(0, sep);
  }
  return val;
};

export const DeserialiseJiraFieldToMultiValue = (
  value: string
): OptionType[] => {
  if (value == "" || value == undefined || value == null) {
    return [];
  }
  // deserialise a components into OptionType objects
  const sep = value.indexOf("|");
  const type = value.substr(0, sep);
  const val = value.substr(sep + 1);
  if (!typesUsingMultiSelect.includes(type)) return [];
  // multiselect values are encoded as | separate list of value{label} strings
  const parts = val.split("$$");
  return parts.map((p) => {
    p = p.replace(/\}$/, "");
    const curlyIdx = p.indexOf("{");
    const value = p.substr(0, curlyIdx);
    const label = p.substr(curlyIdx + 1);
    return { value, label } as OptionType;
  });
};
