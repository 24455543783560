import { SurveyEvidence } from "../../types/vendorAssessments";
import XTable, {
  IXTableRow,
  XTableCell,
} from "../../../_common/components/core/XTable";
import { FC, memo } from "react";
import PillLabel from "../PillLabel";
import {
  ISurveyListItemResponse,
  SurveyStatus,
  TimelineStatusMap,
} from "../../../_common/types/survey";
import Score from "../Score";
import ColorGrade, { ColorGradeSize } from "../executive_summary/ColorGrade";
import { formatDate } from "../../../_common/helpers";
import "../../style/components/vendor_assessment/VendorAssessmentV3SurveysTable.scss";
import { usePagination } from "../../../_common/hooks";
import { LabelColor } from "../../../_common/types/label";
import {
  ISharedAssessment,
  SharedAssessmentAccessResult,
} from "../../types/sharedAssessment";
import Button from "../../../_common/components/core/Button";

interface VendorAssessmentV3SurveysTableProps {
  surveys: SurveyEvidence[];
  selectable: boolean;
  onSelect?: (id: number, isPublic: boolean) => void;
  onClick: (id: number, isPublic: boolean) => void;
  sharedAssessment?: ISharedAssessment;
  sharedAccessRequest?: () => void;
  orgHasSurveyScoresEnabled: boolean;
}

const VendorAssessmentV3SurveysTable: FC<
  VendorAssessmentV3SurveysTableProps
> = (props) => {
  const [items, page, numPages, setPage] = usePagination(props.surveys, 10);

  const getRows = (): IXTableRow[] => {
    const rows: IXTableRow[] = items.map((s) => {
      let score = s.score;
      if (s.numTotalRisks == 0) {
        score = -1;
      }

      const cells = [
        <XTableCell key={"name"} className={"name-cell"}>
          {s.name}{" "}
          <PillLabel
            className={"status-pill"}
            color={TimelineStatusMap[s.status].labelColor}
          >
            {TimelineStatusMap[s.status].text}
          </PillLabel>
          {s.isPublic && (
            <PillLabel className="status-pill" color={LabelColor.Fuchsia}>
              Shared
            </PillLabel>
          )}
        </XTableCell>,
        <XTableCell key={"risks"}>
          {s.numRisks > 0
            ? `${s.numRisks} ${
                s.numWaived > 0 ? "(" + s.numWaived + " waived)" : ""
              }`
            : "-"}
        </XTableCell>,
      ];

      if (props.orgHasSurveyScoresEnabled) {
        cells.push(
          <XTableCell key={"score"}>
            <div className="grade-with-score">
              <ColorGrade size={ColorGradeSize.Small} score={score * 950} />
              <Score score={Math.floor(score * 950)} small />
            </div>
          </XTableCell>
        );
      }

      cells.push(
        <XTableCell key={"date"}>
          {s.status === SurveyStatus.Complete && s.dateCompleted
            ? formatDate(s.dateCompleted)
            : "-"}
        </XTableCell>,
        <XTableCell key={"access"}></XTableCell>
      );

      return {
        id: `${s.id}:${s.isPublic}`,
        selected: props.selectable && s.selected,
        iconOptions: [
          {
            icon: <i className={"cr-icon-chevron"} />,
            id: "chevron",
            onClick: () => props.onClick(s.id, s.isPublic),
            disabled: !s.accessible,
          },
        ],
        onClick: () => props.onClick(s.id, s.isPublic),
        cells: cells,
      };
    });

    // Include shared questionnaires that require an access request, or have a pending access request
    if (
      props.sharedAssessment &&
      props.sharedAssessment.questionnaires &&
      (props.sharedAssessment.orgLevelStatus ===
        SharedAssessmentAccessResult.MustRequest ||
        props.sharedAssessment?.orgLevelStatus ===
          SharedAssessmentAccessResult.NdaRequired ||
        props.sharedAssessment.orgLevelStatus ===
          SharedAssessmentAccessResult.PendingRequest)
    ) {
      const accessPending =
        props.sharedAssessment.orgLevelStatus ===
        SharedAssessmentAccessResult.PendingRequest;

      const getCells = (s: ISurveyListItemResponse) => {
        const cells = [
          <XTableCell key={"name"} className={"name-cell"}>
            {s.name}{" "}
            <PillLabel className="status-pill" color={LabelColor.Fuchsia}>
              Shared
            </PillLabel>
          </XTableCell>,
          <XTableCell key={"risks"}>-</XTableCell>,
        ];

        if (props.orgHasSurveyScoresEnabled) {
          cells.push(<XTableCell key={"score"}>-</XTableCell>);
        }

        cells.push(
          <XTableCell key={"date"}>-</XTableCell>,
          <XTableCell key={"access"} className={"access-cell"}>
            {" "}
            {accessPending ? (
              "Access pending"
            ) : (
              <Button tertiary onClick={props.sharedAccessRequest}>
                <span className={"cr-icon-lock"} />
                {props.sharedAssessment?.orgLevelStatus ===
                  SharedAssessmentAccessResult.NdaRequired && "View NDA"}
                {props.sharedAssessment?.orgLevelStatus ===
                  SharedAssessmentAccessResult.MustRequest && "Request access"}
              </Button>
            )}
          </XTableCell>
        );

        return cells;
      };

      rows.push(
        ...props.sharedAssessment.questionnaires.map((s): IXTableRow => {
          return {
            id: `${s.id}`,
            selectionDisabled: true,
            cells: getCells(s),
          };
        })
      );
    }

    return rows;
  };

  const getColumnHeaders = () => {
    const headers = [
      {
        text: "Name",
        id: "name",
      },
      {
        text: "Risks",
        id: "risks",
      },
    ];

    if (props.orgHasSurveyScoresEnabled) {
      headers.push({
        text: "Score",
        id: "score",
      });
    }

    headers.push(
      {
        text: "Date completed",
        id: "date",
      },
      {
        text: "",
        id: "access",
      }
    );

    return headers;
  };
  return (
    <XTable
      className={"vendor-assessment-surveys-table"}
      columnHeaders={getColumnHeaders()}
      selectable={props.selectable}
      onSelectClick={
        props.onSelect
          ? (rowId) => {
              const parts = (rowId as string).split(":");
              const id = parseInt(parts[0]);
              const isPublic = parts[1] == "true";
              props.onSelect?.(id, isPublic);
            }
          : undefined
      }
      iconOptions
      pagination={{
        currentPage: page,
        hidePaginationIfSinglePage: true,
        onPageChange: setPage,
        totalPages: numPages,
      }}
      rows={getRows()}
    />
  );
};

export default memo(VendorAssessmentV3SurveysTable);
