import { Severity } from "./severity";

export enum CVSSInt {
  ZeroCVSS = 0,
  OneCVSS = 10,
  TwoCVSS = 20,
  ThreeCVSS = 30,
  FourCVSS = 40,
  FiveCVSS = 50,
  SixCVSS = 60,
  SevenCVSS = 70,
  EightCVSS = 80,
  NineCVSS = 90,
  TenCVSS = 100,
}

// Whenever this is updated, the back-end equivalent should be as well:
// notifications/notifications_engine/handlers_vulns.go:CVSS.Severity
export function CVSSToSeverity(c: CVSSInt): Severity {
  if (c < CVSSInt.FourCVSS) {
    return Severity.Low;
  } else if (c < CVSSInt.SevenCVSS) {
    return Severity.Medium;
  } else if (c < CVSSInt.NineCVSS) {
    return Severity.High;
  } else {
    return Severity.Critical;
  }
}

export function CVSSToString(c: CVSSInt): string {
  return `${(c / 10).toString()}.0`;
}
