import { FC, useState } from "react";
import "../style/components/CompanyLogo.scss";

interface CompanyLogoProps {
  domain?: string;
  name?: string;
  displayName?: boolean;
  displayDefault?: boolean;
  additional?: number;
  hoverShadow?: boolean;
  defaultGrey?: boolean;

  // optional category for the company
  // it will be displayed underneath the name
  // it will not be displayed if displayName is false
  category?: string;
  displayCategory?: boolean;
}

//
// CompanyLogo
// Implements a react component that renders a company's logo as supplied from the good folks at clearbit.
// The only thing that we need is a valid domain for the company!
//
export const CompanyLogo: FC<CompanyLogoProps> = ({
  domain = "",
  name,
  displayName = true,
  displayDefault = true,
  additional = 0,
  hoverShadow = false,
  defaultGrey = false,
  category = "",
  displayCategory = false,
}) => {
  const [imageFound, setImageFound] = useState(true);

  const onIMGNotFound = () => setImageFound(false);

  let element = <div />;

  let displayedName = name;
  if (!displayName) {
    displayedName = "";
  }

  if (additional == 0) {
    // display a company logo or a circle with the start of the name if 'displayDefault' is set to true
    if (domain === '""') {
      domain = "";
    }

    let hoverClass = "";
    if (hoverShadow) {
      hoverClass = "hover-shadow";
    }

    if (domain && domain !== "" && imageFound) {
      const myurl = `/api/companylogo/${domain}`;

      element = (
        <div className={"logo-base"}>
          {imageFound && (
            <>
              <div
                className={`circle-logo ${hoverClass}`}
                style={{ backgroundImage: `url("${myurl}")` }}
              />
              {/* Display a dummy img so we can still get a hook on image load error */}
              <img
                style={{ display: "none" }}
                src={myurl}
                onError={onIMGNotFound}
              />
            </>
          )}
        </div>
      );
    } else if (displayDefault) {
      let missingName = "";
      if (name) {
        missingName = name.toUpperCase().slice(0, 3);
      }
      element = (
        <div
          className={`${hoverClass} company-name text-center circle-logo ${
            defaultGrey ? "grey-bg" : "coloured-bg"
          }`}
        >
          {missingName}
        </div>
      );
    }
  } else {
    // display a special "+n" logo for additional items in a list of company logos
    element = (
      <div className="base" title={`+${additional} additional`}>
        <div className="company-name text-center circle-logo grey-bg">{`+${additional}`}</div>
      </div>
    );
  }

  let displayedCategory = category;
  if (!displayCategory) {
    displayedCategory = "";
  }

  const nameElement = (
    <div className={"company-name-block"}>
      <div className="company-label">{displayedName}</div>
      {displayedCategory && (
        <div className="company-category">{displayedCategory}</div>
      )}
    </div>
  );

  return (
    <div className="company-logo">
      {element}
      {displayedName ? nameElement : <></>}
    </div>
  );
};

export default CompanyLogo;
