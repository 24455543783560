import { FC, useCallback, useEffect, useReducer, useState } from "react";
import ModalV2, { BaseModalProps } from "../../_common/components/ModalV2";
import Button, { TooltipButton } from "../../_common/components/core/Button";
import DragDropUpload from "../../_common/components/DragDropUpload";
import TextField, {
  useTextWithValid,
} from "../../_common/components/TextField";
import DatePicker from "../../_common/components/DatePicker";
import moment from "moment/moment";
import "../style/ImportQuestionnaireModal.scss";
import { useAppDispatch, useAppSelector } from "../../_common/types/reduxHooks";
import { chatGPTAccessSelector } from "../../_common/chatgpt";
import InfoBanner, { BannerType } from "../../vendorrisk/components/InfoBanner";
import SurveyImportAPI from "../api/surveyImportAPI";
import { addDefaultUnknownErrorAlert } from "../../_common/reducers/messageAlerts.actions";
import LoadingBanner from "../../_common/components/core/LoadingBanner";
import {
  ExcelConfigReducer,
  ExcelConfigStateInitial,
  excelConfigValidationError,
  ExcelExtractorConfigs,
} from "../../survey_viewer/components/types/autofill.types";
import { ConfigureDocumentStep } from "../../survey_viewer/components/modals/GptAutofillConfigureSourceModal";
import { LogError } from "../../_common/helpers";
import { useDefaultHistory } from "../../_common/types/router";
import { trimFileExtension } from "../../_common/helpers/string.helpers";
import { trackEvent } from "../../_common/tracking";

export const ImportQuestionnaireAcceptedFileTypes = [
  ".xlsx",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
];

interface IImportQuestionnaireModalConfigureStepProps extends BaseModalProps {
  surveyImportUUID?: string;
  surveyName?: string;
  fromCompany?: string;
  dueDate?: string;
}

const ImportQuestionnaireModalConfigureStep: FC<
  IImportQuestionnaireModalConfigureStepProps
> = ({
  active,
  onClose,
  surveyImportUUID,
  surveyName,
  fromCompany,
  dueDate,
}) => {
  const dispatch = useAppDispatch();
  const history = useDefaultHistory();
  const [msgDismissed, setMsgDismissed] = useState(false);

  const [pollingInterval, setPollingInterval] = useState<number | undefined>(
    undefined
  );
  const { data } = SurveyImportAPI.useGetImportedSurveyStatusQuery(
    {
      uuid: surveyImportUUID ?? "",
    },
    {
      skip: !surveyImportUUID,
      pollingInterval,
    }
  );

  const autoDetectJobInProgress =
    data &&
    data.autodetectColumnsJobValid &&
    !data.autodetectColumnsJobFailed &&
    data.autodetectColumnsJobProgress < 1;

  useEffect(() => {
    // Keep polling the job status while it's still in progress
    if (autoDetectJobInProgress) {
      setPollingInterval(3000);
    } else {
      setPollingInterval(undefined);
    }
  }, [autoDetectJobInProgress]);

  const [configState, configReducer] = useReducer(
    ExcelConfigReducer,
    ExcelConfigStateInitial
  );
  useEffect(() => {
    if (data && !autoDetectJobInProgress) {
      // Once we have fully loaded data, we can initialize the config state
      configReducer({
        type: "FROM_STATE",
        state: data.excelConfig.reduce((prev: ExcelExtractorConfigs, next) => {
          prev[next.sheetName] = next;
          return prev;
        }, {}),
      });
    }
  }, [data, autoDetectJobInProgress]);

  const validationError = excelConfigValidationError(configState, true);

  const [createImportedSurveyStep2] =
    SurveyImportAPI.useCreateImportedSurveyStep2Mutation();
  const [submitLoading, setSubmitLoading] = useState(false);
  const onSubmit = useCallback(async () => {
    if (validationError) {
      return;
    }

    try {
      setSubmitLoading(true);
      const { surveyID } = await createImportedSurveyStep2({
        surveyImportUUID: surveyImportUUID ?? "",
        surveyName: surveyName ?? "",
        fromCompany: fromCompany ?? "",
        dueDate: dueDate || undefined,
        documentConfig: Object.values(configState.sheets),
      }).unwrap();
      history.push(`/vendors/surveys/${surveyID}`);
    } catch (e) {
      setSubmitLoading(false);
      LogError("error importing questionnaire", e);
      dispatch(addDefaultUnknownErrorAlert("Error importing questionnaire"));
    }
  }, [
    validationError,
    surveyImportUUID,
    surveyName,
    fromCompany,
    dueDate,
    configState,
    createImportedSurveyStep2,
    dispatch,
    history,
  ]);

  const closeWithConfirmation = () => {
    if (
      window.confirm(
        "Are you sure you want to leave? Your progress will be lost."
      )
    ) {
      onClose();
    }
  };

  if (!data || autoDetectJobInProgress) {
    return (
      <ModalV2
        active={active}
        disallowClose
        className="import-questionnaire-modal-configure-loading"
      >
        <div>
          <h4>Importing questionnaire</h4>
          <LoadingBanner tight />
          <div className="loading-sub">
            Sit tight while we process your questionnaire - it won&apos;t take
            long.
          </div>
        </div>
      </ModalV2>
    );
  }

  return (
    <ModalV2
      active={active}
      onClose={closeWithConfirmation}
      headerClassName="import-questionnaire-modal-configure-header"
      className="import-questionnaire-modal-configure"
      headerContent="Edit Q&A columns"
      subHeaderContent="Specify which columns contain questions, requirements, and answer options based on the sheet preview."
      footerContent={
        <>
          <Button tertiary onClick={closeWithConfirmation}>
            Cancel
          </Button>
          <TooltipButton
            tooltipContent={validationError}
            disabled={!!validationError}
            loading={submitLoading}
            filledPrimary
            onClick={onSubmit}
          >
            Set Q&A columns
          </TooltipButton>
        </>
      }
    >
      {data?.autodetectColumnsJobValid &&
      data.autodetectColumnsJobProgress === 1 &&
      !msgDismissed ? (
        <InfoBanner
          type={BannerType.SUCCESS}
          onDismiss={() => setMsgDismissed(true)}
          message="We were able to automatically process your questionnaire"
          subItems={[
            "Please review the selection of sheets and columns to include, and rows to exclude.",
          ]}
        />
      ) : data?.autodetectColumnsJobFailed && !msgDismissed ? (
        <InfoBanner
          type={BannerType.INFO}
          onDismiss={() => setMsgDismissed(true)}
          message="We were unable to automatically process your questionnaire"
          subItems={[
            "Specify which sheets and columns to include and which rows to exclude.",
          ]}
        />
      ) : undefined}
      <ConfigureDocumentStep
        canSetInvalidRows
        canSetQuestionNumberColumn
        singleQuestionAnswerColumns
        configState={configState}
        configReducer={configReducer}
      />
    </ModalV2>
  );
};

interface IImportQuestionnaireModalProps extends BaseModalProps {
  file?: File;
}

const ImportQuestionnaireModal: FC<IImportQuestionnaireModalProps> = ({
  active,
  onClose,
  file,
}) => {
  const dispatch = useAppDispatch();
  const { shouldDisplayChatGPT } = useAppSelector(chatGPTAccessSelector);

  const [currentStep, setCurrentStep] = useState<"upload" | "configure">(
    "upload"
  );
  const [surveyImportUUID, setSurveyImportUUID] = useState<string | undefined>(
    undefined
  );

  const [selectedFile, setSelectedFile] = useState(file);
  const [
    documentName,
    documentNameValid,
    documentNameOnChanged,
    documentNameHasChanged,
  ] = useTextWithValid();
  const [fromCompany, fromCompanyValid, fromCompanyOnChanged] =
    useTextWithValid();
  const [dueDate, setDueDate] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (selectedFile && !documentNameHasChanged) {
      const filename = trimFileExtension(selectedFile.name);
      documentNameOnChanged(filename, filename.length >= 2);
    }
  }, [selectedFile, documentNameHasChanged, documentNameOnChanged]);

  const [createImportedSurveyStep1] =
    SurveyImportAPI.useCreateImportedSurveyStep1Mutation();

  const [submitDocumentLoading, setSubmitDocumentLoading] = useState(false);
  const onSubmitDocumentUpload = useCallback(
    async (acceptedTerms: boolean) => {
      if (!selectedFile) {
        return;
      }

      setSubmitDocumentLoading(true);
      try {
        const { surveyImportUUID } = await createImportedSurveyStep1({
          file: selectedFile,
          aiTermsAccepted: acceptedTerms,
        }).unwrap();
        setSubmitDocumentLoading(false);
        setSurveyImportUUID(surveyImportUUID);
        setCurrentStep("configure");
      } catch (e) {
        setSubmitDocumentLoading(false);
        throw e;
      }
    },
    [createImportedSurveyStep1, selectedFile]
  );

  const onSubmitUploadStep = useCallback(async () => {
    if (!selectedFile) {
      return;
    }

    try {
      await onSubmitDocumentUpload(shouldDisplayChatGPT);
    } catch (e) {
      console.error(e);
      dispatch(
        addDefaultUnknownErrorAlert("Error submitting document for processing")
      );
    }
  }, [dispatch, shouldDisplayChatGPT, selectedFile, onSubmitDocumentUpload]);

  const onCloseTrackEvent = () => {
    trackEvent("QuestionnaireImportExport_ImportModalClosed");
    onClose();
  };

  return (
    <>
      <ModalV2
        active={active && currentStep === "upload"}
        className="import-questionnaire-modal-upload"
        headerContent="Import questionnaire"
        subHeaderContent="Answer imported questionnaires using UpGuard's autofill tools and export them in the same format they were uploaded in."
        headerClassName="import-questionnaire-modal-header"
        onClose={onCloseTrackEvent}
        footerContent={
          <>
            <Button tertiary onClick={onCloseTrackEvent}>
              Cancel
            </Button>
            <Button
              filledPrimary
              loading={submitDocumentLoading}
              disabled={
                !documentNameValid || !fromCompanyValid || !selectedFile
              }
              onClick={onSubmitUploadStep}
            >
              Next
            </Button>
          </>
        }
      >
        <>
          <DragDropUpload
            maxFileSize={50000000}
            acceptedFileTypeFilters={ImportQuestionnaireAcceptedFileTypes}
            onFileSelected={setSelectedFile}
            selectedFile={selectedFile}
            additionalText=".xlsx files accepted only"
          />

          <label htmlFor="name">Name</label>
          <TextField
            name="name"
            value={documentName}
            onChanged={documentNameOnChanged}
            placeholder="Questionnaire name"
            required
            minLength={2}
            maxLength={500}
          />

          <label htmlFor="requestedby">Requested by</label>
          <TextField
            name="requestedby"
            value={fromCompany}
            onChanged={fromCompanyOnChanged}
            placeholder="Company name"
            required
            minLength={2}
            maxLength={500}
          />

          <label htmlFor="duedate">Due date (optional)</label>
          <DatePicker
            name="duedate"
            min={moment().format("YYYY-MM-DD")}
            placeholder="Select due date"
            value={dueDate}
            onChange={(e) => setDueDate(e.target.value)}
            canClear
            showCalendarIcon
          />
        </>
      </ModalV2>
      <ImportQuestionnaireModalConfigureStep
        active={active && currentStep === "configure"}
        onClose={onCloseTrackEvent}
        surveyImportUUID={surveyImportUUID}
        surveyName={documentName}
        fromCompany={fromCompany}
        dueDate={dueDate}
      />
    </>
  );
};

export default ImportQuestionnaireModal;
