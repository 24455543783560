import vendorSecurityProfileAPI from "../../reducers/vendorSecurityProfileAPI";
import StatusIcon from "./StatusIcon";
import { AdjustedSeverityIcon } from "../../../_common/components/SeverityIcon";
import { SeverityAsString } from "../../../_common/types/severity";
import { useHistory, useLocation } from "react-router-dom";
import { FC, useCallback } from "react";
import { OpenSidePanel } from "../DomainsAndIPsPanelGroup";
import { locationState } from "../../../_common/types/router";
import PillLabel from "../PillLabel";
import { LabelColor } from "../../../_common/types/label";
import "../../style/components/securityprofile/CheckResultLine.scss";
import { ControlState } from "../../types/securityProfile";

interface CheckResultLineProps {
  vendorId: number;
  controlId: string;
  checkId: string;
}

const CheckResultLine: FC<CheckResultLineProps> = ({
  vendorId,
  controlId,
  checkId,
}) => {
  const { data: controlData } =
    vendorSecurityProfileAPI.useGetVendorControlDataQuery({
      vendorId: vendorId,
      controlId: controlId,
    });

  const { data: securityProfile } =
    vendorSecurityProfileAPI.useGetSecurityProfileQuery({
      vendorId: vendorId,
    });

  // find the check for this and then find the relevant evidence which will either be
  // a scanning risk or document evidence
  const check = controlData?.control.checks.find((c) => c.id == checkId);
  const checkState = securityProfile?.controlStates[checkId];
  const scanningRisk = securityProfile?.scanningChecks[checkId];
  const documentCheck = securityProfile?.documentChecks[checkId];

  const history = useHistory();
  const location = useLocation<locationState>();

  const openCheckPanel = useCallback(
    () =>
      OpenSidePanel(
        history,
        {
          vendorId,
          checkId,
        },
        controlData?.control.text,
        location
      ),
    [vendorId, checkId, controlData, history, location]
  );

  if (!check) {
    return <></>;
  }

  return (
    <div className={"check-result-line"} onClick={openCheckPanel}>
      <div className={"icon"}>
        {scanningRisk ? (
          !scanningRisk.passed ? (
            <AdjustedSeverityIcon
              severity={SeverityAsString(scanningRisk.severity)}
              baseSeverity={
                scanningRisk.baseSeverity
                  ? SeverityAsString(scanningRisk.baseSeverity)
                  : undefined
              }
            />
          ) : (
            <StatusIcon controlState={ControlState.Passed} />
          )
        ) : (
          <StatusIcon controlState={checkState ?? ControlState.Unknown} />
        )}
      </div>
      <div className={"check-text"}>
        <div className={"header-text"}>{scanningRisk?.title ?? check.text}</div>
        {checkState == ControlState.Passed && (
          <PillLabel color={LabelColor.Green}>Check passed</PillLabel>
        )}
        {(documentCheck?.length ?? 0) > 0 && (
          <PillLabel color={LabelColor.Blue}>
            {documentCheck?.length} citation
            {(documentCheck?.length ?? 0) != 1 && "s"}
          </PillLabel>
        )}
      </div>
      <i className={"cr-icon-chevron "} />
    </div>
  );
};

export default CheckResultLine;
