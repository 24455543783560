import UserAvatar from "../../_common/components/UserAvatar";
import "../style/components/UserDisplay.scss";
import classnames from "classnames";

interface IUserDisplayProps {
  name?: string;
  avatar?: string;
  email?: string;
  largeAvatar?: boolean;
}

const UserDisplay = (props: IUserDisplayProps) => {
  const classes = classnames("avatar-container", {
    "large-avatar": props.largeAvatar,
  });

  return (
    <div className="user-display">
      <div className={classes}>
        <UserAvatar
          name={props.name}
          avatar={props.avatar ?? ""}
          large={props.largeAvatar}
        />
      </div>
      <div className="detail-container">
        <div className="name">{props.name}</div>
        <div className="email">{props.email}</div>
      </div>
    </div>
  );
};

UserDisplay.defaultProps = {
  name: "User",
};

export default UserDisplay;
