import {
  Fragment,
  FC,
  useEffect,
  useMemo,
  useReducer,
  useState,
  useCallback,
} from "react";
import {
  DateAttributeOperator,
  filterNotificationConfigCategories,
  InitialConditionalLogic,
  INotificationConfigCategory,
  NotificationConditionalLogic,
  NotificationConditionalLogicReducer,
  NotificationConditionMode,
  NotificationConditionOperator,
  NotificationParameters,
  NotificationType,
} from "../../_common/types/notifications";
import {
  AlertDefinitionCreateRequest,
  createOrgNotifications,
  deleteOrgNotifications,
  fetchAllOrgNotifications,
  fetchOrgNotificationSettings,
  ICreateableDefinition,
  IOrgAlertDefinition,
  UpdateAlertDefinitionRequest,
  updateOrgNotifications,
} from "../reducers/org.actions";
import XTable, {
  IIconOption,
  IXTableColumnHeader,
  IXTableRow,
  XTableCell,
} from "../../_common/components/core/XTable";
import PillLabel from "./PillLabel";
import { ILabel, LabelColor } from "../../_common/types/label";
import { DefaultThunkDispatchProp } from "../../_common/types/redux";
import ReportCard from "../../_common/components/ReportCard";
import Button from "../../_common/components/core/Button";
import SearchBox from "../../_common/components/SearchBox";
import EmptyCardWithAction from "../../_common/components/EmptyCardWithAction";
import "../style/components/OrgNotificationConfigV2.scss";
import { HoverColor } from "../../_common/components/IconButton";
import {
  addDefaultSuccessAlert,
  addDefaultUnknownErrorAlert,
  addSimpleErrorAlert,
} from "../../_common/reducers/messageAlerts.actions";
import ConfirmationModalV2 from "../../_common/components/modals/ConfirmationModalV2";
import EditCustomNotificationModal from "./modals/EditCustomNotificationModal";
import {
  fetchAvailableLabels,
  fetchOrgIntegrationSettings,
} from "../reducers/cyberRiskActions";
import { fetchVendorTiers, VendorTier } from "../reducers/vendorTiers.actions";
import { omit as _omit, sortBy as _sortBy, toLower } from "lodash";
import SearchEmptyCard from "../../_common/components/SearchEmptyCard";
import {
  hideVendorRisk,
  OrgAccessDomainPortfolios,
  OrgAccessVendorPortfolios,
} from "../../_common/permissions";
import DismissableBanner from "../../_common/components/DismissableBanner";
import { SeverityAsString } from "../../_common/types/severity";
import { Portfolio } from "../reducers/portfolios.actions";
import InfoBanner, { BannerType } from "./InfoBanner";
import { IIntegration } from "../../_common/types/integration";
import {
  fetchVendorAttributeDefinitions,
  VendorAttributeDefinition,
} from "../reducers/vendorAttributes.actions";
import * as Permissions from "../../_common/permissions";
import CircledIcon from "../../_common/components/CircledIcon";
import { fetchUserActivityStreamSettings } from "../../_common/reducers/activityStreamSettings.actions";
import { appConnect } from "../../_common/types/reduxHooks";
import { SidePopupV2 } from "../../_common/components/DismissablePopup";
import { CVSSInt, CVSSToString } from "../../_common/types/cvss";

const managedByZapierNotification =
  "The state of this notification is managed via Zapier";

const renderDescription = (
  description: string,
  params: NotificationParameters,
  attributes: VendorAttributeDefinition[]
) => {
  // Split the string into nodes, so we have discrete nodes for any
  // parameter variables that appear.
  const theParts: string[] = [];

  let desc = description;
  while (true) {
    const paramStartIdx = desc.indexOf("{{");
    if (paramStartIdx === -1) {
      theParts.push(desc);
      break;
    }

    const paramEndIdx = desc.indexOf("}}") + 2;

    theParts.push(desc.slice(0, paramStartIdx));
    theParts.push(desc.slice(paramStartIdx, paramEndIdx));
    desc = desc.slice(paramEndIdx);
  }

  return (
    <span>
      {theParts.map((part, i) => (
        <Fragment key={i}>
          {part === "{{Threshold}}" ? (
            <span className="variable-highlight">{params.Threshold}</span>
          ) : part === "{{InDays}}" ? (
            <span className="variable-highlight">{params.InDays}</span>
          ) : part === "{{Severity}}" ? (
            <span className="variable-highlight">
              {SeverityAsString(params.Severity ?? 0)}
            </span>
          ) : part === "{{CVSS}}" ? (
            <span className="variable-highlight">
              {CVSSToString(params.CVSS ?? CVSSInt.ZeroCVSS)}
            </span>
          ) : part === "{{Attribute}}" ? (
            <span className="variable-highlight">
              {attributes.find((a) => a.id === params.Attribute)?.name}
            </span>
          ) : (
            part
          )}
        </Fragment>
      ))}
    </span>
  );
};

interface IConditionsPillOwnProps {
  conditions: NotificationConditionalLogic;
}

interface IConditionsPillConnectedProps {
  vendorTiers?: VendorTier[];
  domainPortfolios?: Portfolio[];
  vendorPortfolios?: Portfolio[];
  availableLabels?: ILabel[];
  attributeDefinitions?: VendorAttributeDefinition[];
}

type IConditionsPillProps = IConditionsPillOwnProps &
  IConditionsPillConnectedProps;

interface IConditionalLogicDescriptionProps {
  conditions: NotificationConditionalLogic;
  vendorTiers?: VendorTier[];
  domainPortfolios?: Portfolio[];
  vendorPortfolios?: Portfolio[];
  availableLabels?: ILabel[];
  attributeDefinitions?: VendorAttributeDefinition[];
  multiLine?: boolean;
}
// ConditionalLogicDescription
// Create text that describes the logic for a conditional notification definition.
// The default is to create a multi-line description, but if multiLine is false the description
// is generated as a single sentence.
export function ConditionalLogicDescription({
  conditions,
  vendorTiers = [],
  domainPortfolios = [],
  vendorPortfolios = [],
  availableLabels = [],
  attributeDefinitions = [],
  multiLine = true,
}: IConditionalLogicDescriptionProps) {
  const parts: string[] = [];
  let modeOperator = conditions.modeOperator
    ? conditions.modeOperator.toString()
    : "";
  if (!multiLine) {
    modeOperator = " " + toLower(modeOperator);
  }
  if (conditions.modes.includes(NotificationConditionMode.Tiers)) {
    let desc = "tier matches ";
    const usedTiers = _sortBy(
      vendorTiers.filter((t) => conditions.tiers?.includes(t.id)),
      (t) => t.tier
    ).map((t) => t.name);
    desc += usedTiers.join(", ");
    parts.push(desc);
  }

  if (conditions.modes.includes(NotificationConditionMode.VendorPortfolios)) {
    const portfolioNames = vendorPortfolios
      .filter((p) => conditions.portfolioIDs?.includes(p.id))
      .map((p) => p.name);

    parts.push("portfolio matches " + portfolioNames.join(", "));
  }

  if (conditions.modes.includes(NotificationConditionMode.DomainPortfolios)) {
    const portfolioNames = domainPortfolios
      .filter((p) => conditions.domainPortfolioIDs?.includes(p.id))
      .map((p) => p.name);

    parts.push("portfolio matches " + portfolioNames.join(", "));
  }

  if (conditions.modes.includes(NotificationConditionMode.Labels)) {
    const usedLabelNames = availableLabels
      .filter((l) => conditions.labels?.includes(l.id))
      .map((l) => l.name);
    let desc = "label matches ";
    if (conditions.labelOperator === NotificationConditionOperator.All) {
      desc += "all of ";
    } else {
      desc += "any of ";
    }
    desc += '"';
    desc += usedLabelNames.slice(0, -1).join(`", "`);
    if (usedLabelNames.length > 1) {
      desc += `" and "`;
    }
    desc += `${usedLabelNames[usedLabelNames.length - 1]}"`;
    parts.push(desc);
  }

  if (conditions.modes.includes(NotificationConditionMode.Attributes)) {
    let desc = "attribute matches ";
    if (conditions.labelOperator === NotificationConditionOperator.All) {
      desc += "all of ";
    } else {
      desc += "any of ";
    }
    desc += Object.entries(conditions.attributes ?? {})
      .map(([attrDefId, attributeState]) => {
        const attributeName = attributeDefinitions?.find(
          (def) => def.id === parseInt(attrDefId)
        )?.name;
        if (!attributeName) return;

        let attrCondition = attributeName;
        attrCondition += attributeState.matchesNone
          ? " is none of "
          : attributeState.matchesAll
            ? " has all of "
            : " is any of ";

        const values = attributeState.selectedValues ?? [];
        if (attributeState.includesEmpty) values.push("no value");
        attrCondition += values?.join(",") ?? "";
        return attrCondition;
      })
      .map((c) => `"${c}"`)
      .join(", ");

    desc += Object.entries(conditions.dateAttributes ?? {})
      .map(([attrDefId, attributeState]) => {
        const attributeName = attributeDefinitions?.find(
          (def) => def.id === parseInt(attrDefId)
        )?.name;
        if (!attributeName) return;

        let attrCondition = attributeName;
        switch (attributeState.operator) {
          case DateAttributeOperator.Before:
            attrCondition += " is before " + attributeState.endDate;
            break;
          case DateAttributeOperator.Between:
            attrCondition +=
              " is between " +
              attributeState.startDate +
              " and " +
              attributeState.endDate;
            break;
          case DateAttributeOperator.After:
            attrCondition += " is after " + attributeState.startDate;
            break;
        }
        return attrCondition;
      })
      .map((c) => `"${c}"`)
      .join(", ");

    parts.push(desc);
  }

  return parts.map((p, i) => {
    if (i == 0) {
      p = multiLine ? `When ${p}` : `*When ${p}`;
    } else {
      p = `${modeOperator} ${p}`;
      if (multiLine) {
        return (
          <Fragment key={p}>
            <br />
            <span>{p}</span>
          </Fragment>
        );
      }
    }

    return <span key={p}>{p}</span>;
  });
}

const ConditionsPillInner: FC<IConditionsPillProps> = ({
  conditions,
  vendorTiers = [],
  domainPortfolios = [],
  vendorPortfolios = [],
  availableLabels = [],
  attributeDefinitions = [],
}) => (
  <PillLabel
    className={"custom-conditions-pill"}
    popupClassName={"custom-conditions-popup"}
    color={LabelColor.Orange}
    popupContent={
      <ConditionalLogicDescription
        conditions={conditions}
        vendorTiers={vendorTiers}
        domainPortfolios={domainPortfolios}
        vendorPortfolios={vendorPortfolios}
        availableLabels={availableLabels}
        attributeDefinitions={attributeDefinitions}
        multiLine
      />
    }
  >
    Conditional*
  </PillLabel>
);

export const ConditionsPill = appConnect<
  IConditionsPillConnectedProps,
  never,
  IConditionsPillOwnProps
>((state) => {
  return {
    vendorTiers: state.cyberRisk.vendorTiers,
    domainPortfolios: state.cyberRisk.domainPortfolios?.portfolios,
    vendorPortfolios: state.cyberRisk.vendorPortfolios?.portfolios,
    availableLabels: state.cyberRisk.availableLabels,
    attributeDefinitions: state.cyberRisk.vendorAttributeDefinitions,
  };
})(ConditionsPillInner);

interface IOrgNotificationTableProps {
  loading: boolean;
  definitions: INotificationConfigCategory<IOrgAlertDefinition>[];
  onEditClick?: (def: IOrgAlertDefinition) => void;
  onDeleteClick?: (def: IOrgAlertDefinition) => void;
  vendorAttributes: VendorAttributeDefinition[];
  vendorTiers?: VendorTier[];
  domainPortfolios?: Portfolio[];
  vendorPortfolios?: Portfolio[];
  availableLabels?: ILabel[];
  attributeDefinitions?: VendorAttributeDefinition[];
}

const OrgNotificationTable = (props: IOrgNotificationTableProps) => {
  const headers: IXTableColumnHeader[] = [
    {
      id: "description",
      text: "",
      className: "description-header-cell",
    },
    {
      id: "trigger",
      text: "Trigger",
    },
    {
      id: "conditions",
      text: "",
      className: "conditions-header-cell",
    },
  ];

  const getRowsFromDefs = (
    category: string,
    subCategory: string,
    defs: IOrgAlertDefinition[]
  ) =>
    defs.map((def, i) => {
      const cells = [];

      if (i == 0) {
        cells.push(
          <XTableCell
            key={"description"}
            className={"description-cell"}
            rowSpan={defs.length}
          >
            <div className={"description-div"}>
              <h3>
                {category} {subCategory != "" && <>&gt;</>} {subCategory}
              </h3>
            </div>
          </XTableCell>
        );
      }

      const description = renderDescription(
        def.headline,
        def.parameters,
        props.vendorAttributes
      );

      cells.push(
        <XTableCell key={"trigger"} className={"trigger-cell"}>
          {def.isZapier ? (
            <SidePopupV2 text={managedByZapierNotification} position={"left"}>
              {description}
            </SidePopupV2>
          ) : (
            description
          )}
          <div className={"conditional-desc"}>
            {def.conditionalLogic && (
              <ConditionalLogicDescription
                conditions={def.conditionalLogic}
                vendorTiers={props.vendorTiers}
                domainPortfolios={props.domainPortfolios}
                vendorPortfolios={props.vendorPortfolios}
                availableLabels={props.availableLabels}
                attributeDefinitions={props.attributeDefinitions}
              />
            )}
          </div>
        </XTableCell>,
        <XTableCell key={"custom"} className={"custom-cell"}>
          {def.hasIntegration && (
            <PillLabel
              color={LabelColor.Blue}
              popupContent={"This custom notification triggers an integration"}
            >
              Integration
            </PillLabel>
          )}
          {def.conditionalLogic && (
            <ConditionsPill conditions={def.conditionalLogic} />
          )}
        </XTableCell>
      );

      const iconOptions: IIconOption[] = [];
      if (props.onEditClick) {
        iconOptions.push({
          id: "edit",
          icon: <span className={"cr-icon-pencil"} />,
          onClick: () =>
            props.onEditClick ? props.onEditClick(def) : undefined,
        });
      }
      if (props.onDeleteClick) {
        iconOptions.push({
          id: "delete",
          icon: <span className={"cr-icon-trash"} />,
          onClick: () =>
            props.onDeleteClick ? props.onDeleteClick(def) : undefined,
          hoverColor: HoverColor.Red,
        });
      }

      return {
        id: def.id,
        key: def.id,
        cells,
        iconOptions,
      };
    });

  const rows: IXTableRow[] = [];
  props.definitions.forEach((cat) =>
    cat.subcategories.forEach((subcat) =>
      rows.push(
        ...getRowsFromDefs(cat.category, subcat.subcategory, subcat.options)
      )
    )
  );

  return (
    <XTable
      className={"notifications-table"}
      rows={rows}
      columnHeaders={headers}
      iconOptions
      loading={props.loading}
      numLoadingRows={5}
      hideColumnHeaders={!props.loading && rows.length === 0}
    />
  );
};

interface IOrgNotificationConfigV2ConnectedProps {
  loading: boolean;
  orgDefinitions: INotificationConfigCategory<IOrgAlertDefinition>[];
  createableDefinitions: ICreateableDefinition[];
  orgSupportsDomainPortfolios: boolean;
  orgSupportsVendorPortfolios: boolean;
  availableLabels: ILabel[];
  vendorTiers: VendorTier[];
  hasVendorRisk: boolean;
  domainPortfolios: Portfolio[];
  vendorPortfolios: Portfolio[];
  integrations: IIntegration[];
  attributeDefinitions: VendorAttributeDefinition[];
}

type IOrgNotificationConfigV2Props = IOrgNotificationConfigV2ConnectedProps &
  DefaultThunkDispatchProp;

const OrgNotificationConfigV2: FC<IOrgNotificationConfigV2Props> = ({
  loading,
  orgDefinitions,
  createableDefinitions,
  orgSupportsDomainPortfolios,
  orgSupportsVendorPortfolios,
  availableLabels,
  vendorTiers,
  hasVendorRisk,
  domainPortfolios,
  vendorPortfolios,
  integrations,
  attributeDefinitions,
  dispatch,
}) => {
  // fetch data
  useEffect(() => {
    dispatch(fetchOrgNotificationSettings());
    dispatch(fetchAvailableLabels());
    dispatch(fetchOrgIntegrationSettings());
    if (hasVendorRisk) {
      dispatch(fetchVendorTiers());
      dispatch(fetchVendorAttributeDefinitions());
    }
  }, [dispatch, hasVendorRisk]);

  const [saving, setSaving] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [customSearchText, setCustomSearchText] = useState("");
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [editModalOpen, _setEditModalOpen] = useState(false);
  const setEditModalOpen = useCallback((isOpen: boolean) => {
    _setEditModalOpen(isOpen);
    if (!isOpen) {
      // So the next time the creation modal is opened, the selected notification type is blank (as it is when you
      // open the modal after a page refresh).
      setEditingType(undefined);
    }
  }, []);
  const [editingDefinitionId, setEditingDefinitionId] = useState(0);
  const [editingDefinitionHasIntegration, setEditingDefinitionHasIntegration] =
    useState(false);
  const [
    editingDefinitionIsLastIntegration,
    setEditingDefinitionIsLastIntegration,
  ] = useState(false);
  const [editingParams, setEditingParams] = useState<NotificationParameters>(
    {}
  );
  const [editingType, setEditingType] = useState<NotificationType>();
  const [editingConditions, conditionsDispatch] = useReducer(
    NotificationConditionalLogicReducer,
    InitialConditionalLogic()
  );

  const customSearchTextLower = customSearchText.toLowerCase();

  const { filteredOrgDefs, filteredZapierDefs } = useMemo(() => {
    const { orgDefs, zapierDefs } = filterNotificationConfigCategories(
      orgDefinitions,
      (def) => {
        let shouldInclude = false;

        if (def.conditionalLogic) {
          const usedTiers = vendorTiers
            .filter((t) => def.conditionalLogic?.tiers?.includes(t.id))
            .map((t) => t.name.toLowerCase());

          if (usedTiers.some((t) => t.includes(customSearchTextLower))) {
            shouldInclude = true;
          } else {
            const usedLabelNames = availableLabels
              .filter((l) => def.conditionalLogic?.labels?.includes(l.id))
              .map((l) => l.name.toLowerCase());
            if (usedLabelNames.some((l) => l.includes(customSearchTextLower))) {
              shouldInclude = true;
            }
          }
        }

        if (!shouldInclude) {
          shouldInclude =
            def.headline.toLowerCase().indexOf(customSearchTextLower) > -1 ||
            def.description.toLowerCase().indexOf(customSearchTextLower) > -1;
        }

        return [shouldInclude, def.isZapier ? "zapierDefs" : "orgDefs"];
      }
    );

    return {
      filteredOrgDefs: orgDefs ?? [],
      filteredZapierDefs: zapierDefs ?? [],
    };
  }, [customSearchTextLower, orgDefinitions, availableLabels, vendorTiers]);

  const onDeleteClick = (def: IOrgAlertDefinition) => {
    setEditingDefinitionId(def.id);
    setEditingDefinitionHasIntegration(def.hasIntegration);
    // if this def has an integration check if it's the last/only definition in at least one integration
    setEditingDefinitionIsLastIntegration(
      integrations.some(
        (i) => i.uuids.includes(def.uuid) && i.uuids.length == 1
      )
    );

    setDeleteModalOpen(true);
  };

  const doDelete = () => {
    setDeleting(true);
    return dispatch(deleteOrgNotifications([editingDefinitionId]))
      .then(() => {
        dispatch(addDefaultSuccessAlert("Notification deleted"));
        dispatch(fetchOrgNotificationSettings(true));
        dispatch(fetchAllOrgNotifications(true));
        dispatch(fetchOrgIntegrationSettings(true));
        setDeleting(false);
      })
      .catch(() => {
        dispatch(addDefaultUnknownErrorAlert("Error deleting notifications"));
        setDeleting(false);
      });
  };

  const onEditClick = (def: IOrgAlertDefinition) => {
    setEditingDefinitionId(def.id);
    setEditingDefinitionHasIntegration(def.hasIntegration);
    setEditingDefinitionIsLastIntegration(
      integrations.some(
        (i) => i.uuids.includes(def.uuid) && i.uuids.length == 1
      )
    );
    // find the definition we are editing and load the state
    setEditingParams(def.parameters);
    setEditingType(def.notificationType);
    conditionsDispatch({
      type: "load",
      data: def.conditionalLogic,
    });
    setEditModalOpen(true);
  };

  const onNewClick = () => {
    setEditingDefinitionId(0);
    setEditingDefinitionHasIntegration(false);
    setEditingDefinitionIsLastIntegration(false);
    conditionsDispatch({ type: "init" });
    setEditModalOpen(true);
  };

  const doSave = () => {
    setSaving(true);
    let prom: Promise<void>;
    if (!editingType) {
      throw new Error("invalid notification type"); // Should never happen, just need it for static type checking.
    } else if (editingDefinitionId === 0) {
      // new request
      const def: AlertDefinitionCreateRequest = {
        notificationType: editingType,
        parameters: editingParams,
      };
      if (!editingConditions.cleared) {
        def.conditions = _omit(editingConditions, "cleared");
      }
      prom = dispatch(createOrgNotifications([def]));
    } else {
      const def: UpdateAlertDefinitionRequest = {
        id: editingDefinitionId,
        parameters: editingParams,
      };
      if (!editingConditions.cleared) {
        def.conditions = _omit(editingConditions, "cleared");
      }
      prom = dispatch(updateOrgNotifications([def]));
    }

    return prom
      .then(() => {
        dispatch(fetchAllOrgNotifications(true));
        dispatch(
          addDefaultSuccessAlert(
            `Custom notification successfully ${
              editingDefinitionId === 0 ? "created" : "updated"
            }`
          )
        );
        conditionsDispatch({ type: "clear" });
        setSaving(false);
        dispatch(fetchOrgNotificationSettings(true));
        dispatch(fetchUserActivityStreamSettings(true));
        setEditModalOpen(false);
      })
      .catch((e) => {
        if (e.message.includes("[EXISTS]")) {
          dispatch(
            addSimpleErrorAlert(
              `Error ${
                editingDefinitionId === 0 ? "creating" : "updating"
              } custom notification`,
              [
                "A custom notification of this type, with these conditions, already exists",
              ]
            )
          );
        } else {
          dispatch(
            addDefaultUnknownErrorAlert(
              `Error ${
                editingDefinitionId === 0 ? "creating" : "updating"
              } custom notification`
            )
          );
        }
        setSaving(false);
      });
  };

  const createNotifButtonLabel = "Create notification";

  return (
    <>
      <DismissableBanner
        localStorageKey={"org-notifications-v2"}
        message={"Manage your organization's custom notifications"}
        subItems={[
          "Some notification types support additional parameters and filtering options to suit your specific needs. Create custom notifications below to set these up for use by any member of your account.",
        ]}
        linkURL={
          "https://help.upguard.com/en/articles/4995221-how-to-create-custom-notifications"
        }
        linkText={"View support article"}
      />
      <SearchBox
        value={customSearchText}
        onChanged={(val) => setCustomSearchText(val)}
        placeholder={"Search for notifications"}
      />
      <ReportCard newStyles className={"custom-notification-config-card"}>
        <div className={"header"}>
          Custom notifications
          <Button filledPrimary onClick={onNewClick} loading={loading}>
            {createNotifButtonLabel}
          </Button>
        </div>
        <OrgNotificationTable
          loading={loading}
          definitions={filteredOrgDefs}
          onDeleteClick={onDeleteClick}
          onEditClick={onEditClick}
          vendorAttributes={attributeDefinitions}
          vendorTiers={vendorTiers}
          domainPortfolios={domainPortfolios}
          vendorPortfolios={vendorPortfolios}
          availableLabels={availableLabels}
          attributeDefinitions={attributeDefinitions}
        />
        {orgDefinitions.length === 0 && !customSearchText && !loading && (
          <EmptyCardWithAction
            emptyText={"You have no custom notifications"}
            emptySubText={
              "Any custom notifications that you create will appear here."
            }
            iconJSX={<CircledIcon iconClass={"cr-icon-magnifying-glass"} />}
            onActionClick={onNewClick}
            actionButtonText={createNotifButtonLabel}
            buttonFilledPrimary={true}
          />
        )}
        {filteredOrgDefs.length === 0 && customSearchText && !loading && (
          <SearchEmptyCard
            onClear={() => {
              setCustomSearchText("");
            }}
            searchItemText="notifications"
          />
        )}
      </ReportCard>
      {filteredZapierDefs.length > 0 && (
        <ReportCard newStyles className={"custom-notification-config-card"}>
          <div className={"header"}>
            <div className={"header-with-subtext"}>
              <div>Zapier notifications</div>
              <div className={"subtext"}>
                These notifications are used by Zapier to trigger actions and
                workflows. They are created and managed by Zapier when you
                create or edit workflows.
              </div>
            </div>
          </div>
          <OrgNotificationTable
            loading={loading}
            definitions={filteredZapierDefs}
            vendorAttributes={attributeDefinitions}
          />
        </ReportCard>
      )}
      <ConfirmationModalV2
        title={"Delete custom notification?"}
        buttonAction={() => doDelete()}
        active={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        dangerousAction
        buttonText={"Delete"}
        description={
          <>
            <p>Are you sure you want to delete this custom notification?</p>
            {editingDefinitionHasIntegration && (
              <InfoBanner
                type={BannerType.WARNING}
                message={
                  editingDefinitionIsLastIntegration
                    ? `There are one or more integrations with this custom notification as their only trigger. If you proceed with deleting this notification, the affected integration(s) will be deleted.`
                    : `This custom notification is a trigger for at least one integration. If you delete this custom notification, the affected integration(s) will no longer fire for this trigger.`
                }
              />
            )}
          </>
        }
      />
      <EditCustomNotificationModal
        active={editModalOpen}
        onClose={() => setEditModalOpen(false)}
        onSave={doSave}
        onDelete={() => {
          setEditModalOpen(false);
          setDeleteModalOpen(true);
        }}
        isNew={editingDefinitionId === 0}
        createableDefinitions={createableDefinitions}
        selectedType={editingType}
        onSelectType={(type) => setEditingType(type)}
        params={editingParams}
        setParams={(params) => setEditingParams(params)}
        conditions={editingConditions}
        conditionsDispatch={conditionsDispatch}
        availableLabels={availableLabels}
        vendorTiers={vendorTiers}
        orgSupportsDomainPortfolios={orgSupportsDomainPortfolios}
        orgSupportsVendorPortfolios={orgSupportsVendorPortfolios}
        domainPortfolios={domainPortfolios}
        vendorPortfolios={vendorPortfolios}
        saving={saving}
        deleting={deleting}
        attributeDefinitions={attributeDefinitions}
        dispatch={dispatch}
      />
    </>
  );
};

export default appConnect<IOrgNotificationConfigV2ConnectedProps>((state) => {
  const { orgNotificationSettings, orgIntegrationSettings } = state.cyberRisk;

  const orgHasVendorRisk = state.common.userData.orgPermissions.includes(
    Permissions.OrgAccessVendors
  );

  const loading = !orgNotificationSettings;
  const createableDefinitions = orgNotificationSettings?.createableDefinitions;
  return {
    loading,
    orgDefinitions: orgNotificationSettings?.orgDefinitions ?? [],
    createableDefinitions: createableDefinitions ?? [],
    availableLabels: state.cyberRisk.availableLabels ?? [],
    vendorTiers: state.cyberRisk.vendorTiers ?? [],
    hasVendorRisk:
      orgHasVendorRisk &&
      !hideVendorRisk(
        state.common.userData.userPermissions,
        state.common.userData.vendorPortfolioSpecificPermissions
      ),
    orgSupportsDomainPortfolios: state.common.userData.orgPermissions.includes(
      OrgAccessDomainPortfolios
    ),
    orgSupportsVendorPortfolios: state.common.userData.orgPermissions.includes(
      OrgAccessVendorPortfolios
    ),
    domainPortfolios: state.cyberRisk.domainPortfolios?.portfolios ?? [],
    vendorPortfolios: state.cyberRisk.vendorPortfolios?.portfolios ?? [],
    integrations: orgIntegrationSettings?.integrations ?? [],
    attributeDefinitions: state.cyberRisk.vendorAttributeDefinitions ?? [],
  };
})(OrgNotificationConfigV2);
