import {
  RepositoryRisk,
  VendorSummaryRisk,
} from "../../_common/types/vendorSummary";
import XTable, {
  IXTableColumnHeader,
  IXTableRow,
  SortDirection,
  XTableCell,
} from "../../_common/components/core/XTable";
import { useSorting } from "../../_common/hooks";
import { formatDateAsLocal } from "../../_common/helpers";
import EmptyCardWithAction from "../../_common/components/EmptyCardWithAction";
import { FC } from "react";
import { Link } from "react-router-dom";
import ManageRiskButton from "../../vendorrisk/components/risk_profile/ManageRiskButton";
import { WaivedAssets } from "../../vendorrisk/reducers/risks.actions";
import PillLabel from "../../vendorrisk/components/PillLabel";
import { LabelColor } from "../../_common/types/label";

interface RiskRepositoriesProps {
  risk: VendorSummaryRisk;
  showWaived?: boolean;
  waivedAssets?: WaivedAssets;
}

const RiskRepositories: FC<RiskRepositoriesProps> = ({
  risk,
  showWaived,
  waivedAssets,
}) => {
  const columnHeaders: IXTableColumnHeader[] = [
    {
      id: "repo",
      text: "Repository",
      sortable: true,
    },
    {
      id: "first_detected",
      text: "First Detected",
      sortable: true,
    },
    {
      id: "status",
      text: "Status",
      sortable: false,
    },
    {
      id: "actions",
      text: "",
      sortable: false,
    },
  ];

  const filteredRepos =
    risk.repositories?.filter((r) => {
      if (showWaived) {
        return true;
      }

      return waivedAssets?.assetsWaived[r.repoName] === undefined;
    }) ?? [];

  const [sortedDependencies, sortedBy, onSortChange] = useSorting<
    RepositoryRisk,
    "repo" | "first_detected"
  >(filteredRepos, "repo", SortDirection.DESC, {
    repo: {
      orderFuncs: [(r) => r.repoName.toLocaleLowerCase()],
      sortDirsAsc: [SortDirection.ASC],
      sortDirsDesc: [SortDirection.DESC],
    },
    first_detected: {
      orderFuncs: [(r) => r.firstDetected],
      sortDirsAsc: [SortDirection.ASC],
      sortDirsDesc: [SortDirection.DESC],
    },
  });

  const getRepoPath = (uuid: string) =>
    `/appguard/repositories/${uuid}/overview`;

  const rows: IXTableRow[] = sortedDependencies.map((r) => {
    const isWaived = waivedAssets?.assetsWaived[r.repoName] !== undefined;
    const isPendingWaived =
      waivedAssets?.assetsPendingWaiver[r.repoName] !== undefined;

    return {
      id: r.uuid,
      cells: [
        <XTableCell key="repo">
          <Link to={getRepoPath(r.uuid)}>{r.repoName}</Link>
        </XTableCell>,
        <XTableCell key="first_detected">
          {formatDateAsLocal(r.firstDetected)}
        </XTableCell>,
        <XTableCell key="status">
          {isWaived && <PillLabel>Waived</PillLabel>}
          {isPendingWaived && (
            <PillLabel color={LabelColor.Orange}>Waiver pending</PillLabel>
          )}
        </XTableCell>,
        <XTableCell key={"actions"} showContentOnlyOnHover>
          <ManageRiskButton
            onRemediate={() => {
              // TODO just show dummy button for now
            }}
            onWaive={() => {
              // TODO just show dummy button for now
            }}
            onAdjust={() => {
              // TODO just show dummy button for now
            }}
          />
        </XTableCell>,
      ],
    };
  });

  return (
    <XTable
      className={"risk-repositories"}
      columnHeaders={columnHeaders}
      rows={rows}
      emptyContent={<EmptyCardWithAction emptyText={"No repositories"} />}
      sortedBy={sortedBy}
      onSortChange={onSortChange}
    />
  );
};

export default RiskRepositories;
