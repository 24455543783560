import {
  IPRangeSourceDescriptionsCustomer,
  IPRangeSourceDescriptionsVendor,
  IPSource,
  IPSourceColors,
  IPSourceDescriptionsCustomer,
  IPSourceDescriptionsVendor,
} from "../../../_common/types/ipAddresses";
import { SidePopupV2 } from "../../../_common/components/DismissablePopup";
import PillLabel from "../PillLabel";
import "./IpSourceLabel.scss";

interface IpSourceLabelProps {
  source: IPSource;
  isCustomer: boolean;
  isRange?: boolean;
}

const IpSourceLabel = ({ source, isCustomer, isRange }: IpSourceLabelProps) => {
  const desc = isCustomer
    ? isRange
      ? IPRangeSourceDescriptionsCustomer[source]
      : IPSourceDescriptionsCustomer[source]
    : isRange
      ? // @ts-ignore
        IPRangeSourceDescriptionsVendor[source]
      : // @ts-ignore
        IPSourceDescriptionsVendor[source];

  return (
    <SidePopupV2 className="ip-source-label" text={desc}>
      <PillLabel color={IPSourceColors[source]}>{source}</PillLabel>
    </SidePopupV2>
  );
};

export default IpSourceLabel;
