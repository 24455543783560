import React, {
  CSSProperties,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import Select, {
  InputActionMeta,
  components as defaultComponents,
  SelectComponentsConfig,
  StylesConfig,
  Theme,
} from "react-select";
import Creatable from "react-select/creatable";
import classnames from "classnames";

import "../style/components/SelectV2.scss";
import { Option } from "react-select/src/filters";
import { Props as CreatableProps } from "react-select/src/Creatable";
import { Props as SelectProps } from "react-select/src/Select";

const colors = {
  primary: "#1757C2",
  primary75: "#F3F7FC",
  primary50: "#F3F7FC",
  primary25: "#F3F7FC",
  neutral90: "#2C3238",
  neutral70: "#6B6F74",
  neutral50: "#95989B",
  neutral30: "#C0C1C3",
  neutral10: "#EAEAEB",
  neutral5: "#F4F5F5",
};

const filterOption = (opt: Option, filter: string) => {
  if (opt.data.__isNew__) {
    return true;
  }
  return opt.label.toLowerCase().includes(filter.toLowerCase());
};

export type OptionType<IDType extends string | number = string | number> = {
  label: string;
  value: IDType;
};

export interface ICreatableV2Props<
  IsMulti extends boolean = true,
  optionType extends OptionType = OptionType,
> extends CreatableProps<optionType, IsMulti> {
  className?: string;
  innerRef?: React.Ref<any>;
  staticEditable?: boolean; // If true, display the field as if it's a static div, but editable on hover.
  createHintText?: string; // If provided, show a hint that you can start typing to create a new entry
  usePortal?: boolean; // If true, mount menu in a portal on document.body. Useful when used inside scrollable areas.
}

export const CreatableV2 = <
  IsMulti extends boolean = true,
  optionType extends OptionType = OptionType,
>(
  props: ICreatableV2Props<IsMulti, optionType>
) => {
  const {
    className,
    createHintText,
    onInputChange: _onInputChange,
    usePortal,
    ...otherProps
  } = props;
  const [hasInputVal, setHasInputVal] = useState(false);

  const onInputChange = useCallback(
    (val: string, actionMeta: InputActionMeta) => {
      setHasInputVal(val.length > 0);
      _onInputChange?.(val, actionMeta);
    },
    [_onInputChange]
  );

  const theme = useCallback(
    (theme: Theme) => ({
      ...theme,
      colors: {
        ...theme.colors,
        ...colors,
      },
    }),
    []
  );

  const styles = useMemo(() => {
    const styles: StylesConfig<optionType, IsMulti> = {
      ...otherProps.styles,
      control: (provided: CSSProperties) => ({
        ...provided,
        minHeight: "32px",
      }),
    };

    if (usePortal) {
      styles.menuPortal = (base) => ({ ...base, zIndex: 999999 });
    }

    return styles;
  }, [otherProps.styles, usePortal]);

  // Override the default MenuList component so we can add in a hint to start typing to create a new entry
  const components: SelectComponentsConfig<optionType, IsMulti> =
    useMemo(() => {
      return {
        MenuList: (props) => {
          return (
            <>
              {createHintText && !hasInputVal && (
                <div className="create-hint">{createHintText}</div>
              )}
              <defaultComponents.MenuList {...props} />
            </>
          );
        },
      };
    }, [createHintText, hasInputVal]);

  return (
    <Creatable<optionType, IsMulti>
      ref={props.innerRef}
      className={classnames("ug-select", className, {
        "static-editable": props.staticEditable,
      })}
      classNamePrefix="ug-select"
      theme={theme}
      filterOption={filterOption}
      menuPlacement="auto"
      {...otherProps}
      onInputChange={onInputChange}
      styles={styles}
      components={components}
      menuPortalTarget={usePortal ? document.body : undefined}
    />
  );
};

export interface ISelectV2Props<
  IsMulti extends boolean = false,
  optionType extends OptionType = OptionType,
> extends SelectProps<optionType, IsMulti> {
  className?: string;
  staticEditable?: boolean; // If true, display the field as if it's a static div, but editable on hover.
  usePortal?: boolean; // If true, mount menu in a portal on document.body. Useful when used inside scrollable areas.
}

export const SelectV2 = <
  IsMulti extends boolean = false,
  optionType extends OptionType = OptionType,
>(
  props: ISelectV2Props<IsMulti, optionType>
) => {
  const { className, usePortal, ...otherProps } = props;

  const theme = useCallback(
    (theme: Theme) => ({
      ...theme,
      colors: {
        ...theme.colors,
        ...colors,
      },
    }),
    []
  );

  const styles = useMemo(() => {
    const styles: StylesConfig<optionType, IsMulti> = {
      ...otherProps.styles,
      control: (provided: CSSProperties) => ({
        ...provided,
        minHeight: "32px",
      }),
    };

    if (usePortal) {
      styles.menuPortal = (base) => ({ ...base, zIndex: 999999 });
    }

    return styles;
  }, [otherProps.styles, usePortal]);

  return (
    <Select
      className={classnames("ug-select", className, {
        "static-editable": props.staticEditable,
      })}
      classNamePrefix="ug-select"
      theme={theme}
      filterOption={filterOption}
      menuPlacement="auto"
      {...otherProps}
      styles={styles}
      menuPortalTarget={usePortal ? document.body : undefined}
    />
  );
};

export const SelectV2Multi = <optionType extends OptionType = OptionType>(
  props: ISelectV2Props<true, optionType>
) => <SelectV2<true, optionType> isMulti {...props} />;

interface ISelectV2AsyncProps<SearchItemType, IsMulti extends boolean = false>
  extends ISelectV2Props<IsMulti> {
  // Callback for creating options for items from response
  getOptionFromItem: (item: SearchItemType) => OptionType;
  minChar: number;

  // Callback to trigger when a search is required
  getItems: (searchValue: string) => Promise<SearchItemType[]>;

  // Used to maintain selected options when resetting for new search. Use instead of .value
  selectedOptions: OptionType[];
}

// Wrapper for SelectV2 to manage async operations
export const SelectV2Async = <SearchItemType, IsMulti extends boolean = false>(
  props: ISelectV2AsyncProps<SearchItemType, IsMulti>
) => {
  const isMounted = useRef(false);
  const latestSearchTerm = useRef("");
  const latestTimeoutRef = useRef(0);

  const [options, setOptions] = useState(props.selectedOptions);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  const searchChanged = (val: string) => {
    const timeoutRef = window.setTimeout(() => {
      if (timeoutRef !== latestTimeoutRef.current || !isMounted) {
        // Still typing (or unmounted) so don't fire request
        return;
      }

      setIsLoading(true);
      latestSearchTerm.current = val;

      if (val.length < props.minChar) {
        if (isMounted) {
          const options = [] as OptionType[];
          setOptions(options);
          setIsLoading(false);
        }
        return;
      }

      props
        .getItems(val)
        .then((data) => {
          // Check we are responding to latest fired search term, and the component is still mounted in the DOM
          if (latestSearchTerm.current === val && isMounted) {
            setIsLoading(false);
            const options = data.map((i) => props.getOptionFromItem(i));
            setOptions(options);
          }
        })
        .catch(() => {
          setIsLoading(false);
          console.error("failed getting data for SelectV2Async");
        });
    }, 500);

    latestTimeoutRef.current = timeoutRef;
  };

  return (
    <SelectV2
      {...props}
      onInputChange={(val) => searchChanged(val)}
      options={options}
      isLoading={isLoading}
      loadingMessage={() => "... searching ..."}
      value={props.selectedOptions as any}
      noOptionsMessage={() =>
        latestSearchTerm.current ? "Nothing found" : "Start typing to search"
      }
    />
  );
};
