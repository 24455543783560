import ModalV2, { BaseModalProps } from "../../../_common/components/ModalV2";
import React, { useState } from "react";
import Button from "../../../_common/components/core/Button";
import DragDropUpload from "../../../_common/components/DragDropUpload";
import { addSimpleErrorAlert } from "../../../_common/reducers/messageAlerts.actions";
import { uploadSurveyExcelFile } from "../../reducers/apiActions";
import { Answers } from "../../../vendorrisk/reducers/questionnaireAnswers.actions";
import InfoBanner, {
  BannerType,
  SubItemType,
} from "../../../vendorrisk/components/InfoBanner";
import { getCyberRiskAuth } from "../../../_common/session";
import "../../style/ExcelUploadModal.scss";
import { ResponseError } from "../../../_common/api";
import { Steps } from "../../../_common/components/StepsWithSections";
import { contactSupport } from "../../../_common/helpers";
import { useAppDispatch } from "../../../_common/types/reduxHooks";

interface ExcelUploadModalProps extends BaseModalProps {
  surveyID: number;
  isPublic: boolean;
  onSuccess: (answers: Answers) => void;
  hasAnswers: boolean;
  alreadyExported: boolean;
  onReviewClick: () => void;
  senderOrgName: string;
}

const AcceptedDocumentTypes = [
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  ".xlsx",
];

const SupportArticleURL =
  "https://help.upguard.com/en/articles/7002550-how-to-answer-a-questionnaire-offline";

type uploadError = "unknown" | "managled" | "wrong_workbook" | "bad_upload";

const ExcelUploadModal: React.FC<ExcelUploadModalProps> = (props) => {
  const dispatch = useAppDispatch();

  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState<File | undefined>(undefined);

  const [step, setStep] = useState(props.alreadyExported ? 2 : 1);
  const [error, setError] = useState<uploadError | undefined>(undefined);

  const onDownload = () => {
    const { apiKey, token } = getCyberRiskAuth();
    window.open(
      `/api/survey/excel/v1?apikey=${apiKey}&token=${token}&survey_id=${props.surveyID}&is_public=${props.isPublic}`
    );
    setStep(2);
  };

  const onClose = () => {
    setFile(undefined);
    setStep(1);
    setLoading(false);
    setError(undefined);
    props.onClose();
  };

  const onSubmit = () => {
    if (!file) {
      return;
    }

    setLoading(true);

    dispatch(uploadSurveyExcelFile(props.surveyID, props.isPublic, file))
      .then((answers) => props.onSuccess(answers))
      .then(() => setStep(3))
      .catch((e: ResponseError) => {
        setFile(undefined);
        if (e.message.includes("[INCCORECT WORKBOOK]")) {
          setError("wrong_workbook");
        } else if (e.message.includes("[WORKBOOK MANAGLED]")) {
          setError("managled");
        } else {
          setError("unknown");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getNextButton = () => {
    switch (step) {
      case 1:
        return (
          <Button filledPrimary onClick={() => setStep(2)} arrow>
            Continue
          </Button>
        );
      case 2:
        return (
          <Button
            filledPrimary
            onClick={onSubmit}
            disabled={!file}
            loading={loading}
            arrow
          >
            Continue
          </Button>
        );
      case 3:
        return (
          <>
            <Button onClick={onClose}>Go to questionnaire</Button>
            <Button
              filledPrimary
              onClick={() => {
                onClose();
                props.onReviewClick();
              }}
            >
              Review and submit
            </Button>
          </>
        );
      default:
        return <></>;
    }
  };

  const getBackButton = () => {
    switch (step) {
      case 2:
        return (
          <Button onClick={() => setStep(1)} leftArrow disabled={loading}>
            Previous
          </Button>
        );
      default:
        return <></>;
    }
  };

  const getError = () => {
    if (error == undefined) {
      return <></>;
    }
    let message = "";
    let subItems: React.ReactNode[] = [];

    switch (error) {
      case "bad_upload":
        message = "We were unable to upload your file.";
        subItems = [
          "Make sure you are uploading an .xlsx file. Please try again",
        ];
        break;
      case "wrong_workbook":
        message = "We were unable to upload your file.";
        subItems = [
          " Please ensure you have selected the correct file and try again.",
        ];
        break;
      case "managled":
        message = "We were unable to upload your file.";
        subItems = [
          "The workbook appears to have been modified in a way that has caused an error.",
          "Please ensure you have followed the instructions and only modified the answers column",
        ];
        break;
      case "unknown":
        message = "An unknown error has occurred.";
        subItems = [
          "Please try again",
          <Button key={1} tertiary onClick={contactSupport}>
            Contact support
          </Button>,
        ];
    }

    return (
      <InfoBanner
        type={BannerType.ERROR}
        className={"wrong-file-banner"}
        message={message}
        subItems={subItems}
        subItemType={SubItemType.PARAGRAPH}
      />
    );
  };

  return (
    <ModalV2
      active={props.active}
      onClose={onClose}
      disallowClose={loading}
      className={"excel-upload-modal"}
      headerClassName={"excel-upload-modal-header"}
      headerContent={
        <div className={"excel-upload-header"}>
          <h2 className={"title"}>Import answers</h2>
        </div>
      }
      footerContent={
        <>
          <div className={"footer-left"}>
            {getBackButton()}
            {step != 3 && (
              <Button tertiary onClick={() => window.open(SupportArticleURL)}>
                View support article
              </Button>
            )}
          </div>
          {getNextButton()}
        </>
      }
    >
      <Steps
        steps={[
          {
            id: "export",
            text: "Export",
            onClick: () => setStep(1),
          },
          {
            id: "import",
            text: "Import",
            onClick: step >= 2 ? () => setStep(2) : undefined,
          },
          {
            id: "review",
            text: "Review",
            onClick: step >= 3 ? () => setStep(3) : undefined,
          },
        ]}
        currentStep={step}
      />
      {step == 1 && (
        <div className={"download-step"}>
          <div className={"info"}>
            Export this questionnaire in .XLSX format, add your answers, and
            then import the completed file. We&apos;ll extract the answers and
            fill the questionnaire for you.
          </div>
          <Button onClick={onDownload}>
            {"Export "}
            <i className={"cr-icon-export"} />
          </Button>
        </div>
      )}
      {step == 2 && (
        <div className={"upload-step"}>
          <div className={"upload-text"}>
            Upload the questionnaire file and we&apos;ll extract the answers for
            you to fill this questionnaire. For best results, make sure you:
            <ul>
              <li>
                Are uploading answers for this questionnaire and not a different
                questionnaire
              </li>
              <li>
                Have followed the instructions on how to respond to the
                questionnaire.
              </li>
            </ul>
          </div>
          <DragDropUpload
            onFileSelected={(f) => {
              setFile(f);
              setError(undefined);
            }}
            onFileRejected={() =>
              dispatch(
                addSimpleErrorAlert("File must be .xlsx file under 50 MB")
              )
            }
            maxFileSize={50000000}
            loading={loading}
            acceptedFileTypeFilters={AcceptedDocumentTypes}
            selectedFile={file}
            clickText={"Click to upload your questionnaire answers"}
            doNotKeepState
          />
          {getError()}
          {file && !error && props.hasAnswers && (
            <InfoBanner
              type={BannerType.INFO}
              message={
                "Uploading a new file will replace any existing answers in this questionnaire."
              }
            />
          )}
        </div>
      )}
      {step == 3 && (
        <div className={"success-step"}>
          <i className={"cr-icon-check"} />
          <div className={"body"}>
            We were able to extract the answers from your file. Before
            submitting your response to {props.senderOrgName} please first:
            <ul>
              <li>Review your responses within the questionnaire form.</li>
              <li>Answer all sections of the questionnaire.</li>
              <li>Attach any relevant files or documents.</li>
            </ul>
          </div>
        </div>
      )}
    </ModalV2>
  );
};

export default ExcelUploadModal;
