import {
  ContactDisplay,
  ContactDisplayExisting,
  ContactDisplayNew,
} from "./ContactSelect";
import { IVendorContactResponse } from "../../../_common/types/vendorContact";
import { FC, useCallback, useState } from "react";
import { produceArrayWithFind } from "../../../_common/helpers/array.helpers";
import CustomSelect, {
  Option,
  SelectItemType,
} from "../../../_common/components/CustomSelect";
import Button from "../../../_common/components/core/Button";
import { useModalV2 } from "../../../_common/components/ModalV2";
import EditVendorContactDetailsModal from "../modals/EditVendorContactDetailsModal";
import { UserEmailAddress } from "../../../_common/types/user";
import "../../style/components/contacts/ContactSelectV2.scss";
import UserAvatar, {
  UserAvatarAndName,
} from "../../../_common/components/UserAvatar";
import { getGravatarURL } from "../../../_common/helpers";
import { useDefaultHistory } from "../../../_common/types/router";
import IconButton from "../../../_common/components/IconButton";
import classNames from "classnames";
import { useAppDispatch } from "../../../_common/types/reduxHooks";
import { addSimpleErrorAlert } from "../../../_common/reducers/messageAlerts.actions";

export const useSelectedContacts = (
  initialSelectedContacts: ContactDisplayExisting[]
) => {
  const [selectedContacts, setSelectedContacts] = useState(
    initialSelectedContacts
  );
  const [newContacts, setNewContacts] = useState<ContactDisplayNew[]>([]);

  const selectContact = useCallback(
    (contact: ContactDisplay) => {
      if (contact.isNewContact) {
        setNewContacts((prev) =>
          produceArrayWithFind(
            prev,
            contact,
            (item, arrayItem) => item.emailAddress == arrayItem.emailAddress
          )
        );
      } else {
        setSelectedContacts((prev) =>
          produceArrayWithFind(
            prev,
            contact,
            (item, arrayItem) => item.emailAddress == arrayItem.emailAddress
          )
        );
      }
    },
    [setSelectedContacts, setNewContacts]
  );

  return [
    selectedContacts,
    newContacts,
    selectContact,
    setSelectedContacts,
    setNewContacts,
  ] as const;
};

export type selectContactFunk = (contact: ContactDisplay) => void;

export interface ContactSelectV2Props {
  existingSelectedContacts: ContactDisplay[];
  newContacts?: ContactDisplay[];
  selectContacts: (contact: ContactDisplay) => void;
  readonly: boolean;
  vendorContacts?: IVendorContactResponse[];
  orgContacts?: UserEmailAddress[];
  maxSelectedContacts?: number;
  showDetails?: boolean;
  vendorId?: number;
  allowNewContact?: boolean;
}

const ContactSelectV2: FC<ContactSelectV2Props> = (props) => {
  const history = useDefaultHistory();
  const dispatch = useAppDispatch();

  const [openNewContactModal, newContactModal] = useModalV2(
    EditVendorContactDetailsModal
  );

  const [searchText, setSearchText] = useState("");

  const shouldShowContact = useCallback(
    (name: string, email: string) => {
      const searchLower = searchText.toLocaleLowerCase();
      return (
        (!searchText ||
          name.toLocaleLowerCase().indexOf(searchLower) !== -1 ||
          email.toLocaleLowerCase().indexOf(searchLower) !== -1) &&
        !props.existingSelectedContacts.find((c) => c.emailAddress == email)
      );
    },
    [searchText, props.existingSelectedContacts]
  );

  const combinedContacts = [
    ...props.existingSelectedContacts,
    ...(props.newContacts ?? []),
  ];

  const canAddContact =
    !props.readonly &&
    (props.maxSelectedContacts
      ? combinedContacts.length < props.maxSelectedContacts
      : true);

  const headerOption: Option = {
    id: "header",
    type: SelectItemType.Group,
    className: "contacts-header",
    hideIfEmpty: true,
    title:
      "Search existing" +
      (props.allowNewContact && canAddContact ? " or add a new contact.." : ""),
    items: [],
  };

  const options: Option[] = [headerOption];

  if (props.vendorContacts) {
    headerOption.items?.push(
      ...props.vendorContacts
        .filter((v) => shouldShowContact(v.name ?? "", v.emailAddress ?? ""))
        .map((v) => {
          const contact: ContactDisplay = {
            name: v.name ?? "",
            emailAddress: v.emailAddress ?? "",
            avatar: "",
            isNewContact: false,
            existingId: v.id,
            isVendorContact: true,
            title: v.title,
            notes: v.notes,
            phoneNumber: v.phoneMobile,
          };

          const option: Option = {
            id: v.id,
            type: SelectItemType.Option,
            content: (
              <UserAvatarAndName
                name={v.name}
                email={v.emailAddress}
                avatar={getGravatarURL(v.emailAddress)}
              />
            ),
            onClick: () => {
              props.selectContacts(contact);
            },
          };

          return option;
        })
    );
  }

  if (props.orgContacts) {
    headerOption.items?.push(
      ...props.orgContacts
        .filter((c) => shouldShowContact(c.name, c.emailAddress))
        .map((c) => {
          const contact: ContactDisplay = {
            name: c.name,
            isNewContact: false,
            isVendorContact: false,
            emailAddress: c.emailAddress,
            avatar: c.avatar,
            existingId: c.id,
          };

          const option: Option = {
            type: SelectItemType.Option,
            id: c.id,
            content: (
              <UserAvatarAndName
                name={c.name}
                avatar={c.avatar}
                email={c.emailAddress}
              />
            ),
            onClick: () => props.selectContacts(contact),
          };

          return option;
        })
    );
  }

  if (props.allowNewContact) {
    options.push({
      id: "add",
      type: SelectItemType.Option,
      className: "new-button",
      content: (
        <div className={"add-new-container"}>
          <Button tertiary>
            <i className={"cr-icon-plus"} /> Add new contact{" "}
          </Button>
        </div>
      ),
      onClick: () => {
        if (props.vendorId) {
          openNewContactModal({
            dontSubmit: true,
            onNewContact: (c) => {
              if (
                props.newContacts?.some(
                  (contact) => c.emailAddress == contact.emailAddress
                ) ||
                props.vendorContacts?.some(
                  (contact) => contact.emailAddress == c.emailAddress
                )
              ) {
                dispatch(
                  addSimpleErrorAlert(
                    `Contact with email ${c.emailAddress} already exists`
                  )
                );
                return;
              }
              props.selectContacts({
                phoneNumber: c.phoneMobile,
                notes: c.notes,
                title: c.title,
                isNewContact: true,
                avatar: getGravatarURL(c.emailAddress),
                isVendorContact: true,
                name: c.name,
                emailAddress: c.emailAddress,
              });
            },
            vendorId: props.vendorId ?? 0,
          });
        } else {
          history.push("/settings/users", {
            backContext: {
              goBack: true,
              backToText: "Back",
            },
          });
        }
      },
    });
  }

  return (
    <div className={"contact-select-v2-container"}>
      {!props.readonly && (
        <CustomSelect
          className={"contact-select-v2"}
          placeholder={`Select ${
            props.allowNewContact ? "or add " : ""
          }contact`}
          items={options}
          disabled={!canAddContact}
          onSearchChange={setSearchText}
          emptyText={"No contacts available"}
        />
      )}
      <div className={"selected-contacts"}>
        <div
          className={classNames("contact-grid", {
            detailed: !!props.showDetails,
          })}
        >
          {combinedContacts.map((c) => (
            <>
              <div className={"user-name-avatar"}>
                <UserAvatar
                  avatar={
                    c.avatar != "" ? c.avatar : getGravatarURL(c.emailAddress)
                  }
                  name={c.name}
                />
                <div
                  className={classNames("user-fields", "name-fields", {
                    detailed: !!props.showDetails,
                  })}
                >
                  <div className={"user-name"}>{c.name}</div>
                  {!!props.showDetails && (
                    <div className={"light"}>{c.title}</div>
                  )}
                </div>
              </div>
              <div
                className={classNames("user-fields", "email-fields", {
                  detailed: !!props.showDetails,
                })}
              >
                <div className={"light"}>{c.emailAddress}</div>
                {!!props.showDetails && (
                  <div className={"light"}>{c.phoneNumber}</div>
                )}
              </div>
              {!!props.showDetails && <div className={"notes"}>{c.notes}</div>}
              <div className={"buttons"}>
                {!props.readonly && (
                  <IconButton
                    icon={<i className={"cr-icon-trash"} />}
                    onClick={() => props.selectContacts(c)}
                  />
                )}
              </div>
            </>
          ))}
        </div>
        {combinedContacts.length == 0 && props.readonly && (
          <div className={"no-contacts"}>No contacts selected </div>
        )}
      </div>
      {newContactModal}
    </div>
  );
};

export default ContactSelectV2;
