import { Component } from "react";
import PropTypes from "prop-types";

import DateTimeFormat from "../../../_common/components/core/DateTimeFormat";

import LoadingBanner from "../../../_common/components/core/LoadingBanner";

import Tree, { TreeNode } from "rc-tree";
import "rc-tree/assets/index.css";

import merge from "lodash/merge";

import { listS3BucketObjects } from "../../reducers/actions";

import "../../style/S3ObjectsModal.scss";
import AmazonLogo from "../../images/amazon-64.png";
export const S3ObjectsModalName = "S3ObjectsModal";

class S3ObjectsModal extends Component {
  static propTypes = {
    modalData: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
  };

  state = {
    loading: true,
    error: null,
    objects: [],
  };

  componentDidMount() {
    this.props
      .dispatch(listS3BucketObjects(this.props.modalData.url))
      .then((objects) => {
        this.setState({
          loading: false,
          error: null,
          objects,
        });
      })
      .catch((err) => {
        this.setState({
          loading: false,
          error: err.toString(),
          objects: null,
        });
      });
  }

  formatFileSize(bytes) {
    const i = Math.floor(Math.log(bytes) / Math.log(1024));
    return (
      (bytes / Math.pow(1024, i)).toFixed(2) * 1 +
      " " +
      ["B", "kB", "MB", "GB", "TB"][i]
    );
  }

  onNodeSelected = (key, info) => {
    if (info.node.isLeaf()) {
      const url = `/api/breachsight/analyst/s3object?bucket=${encodeURIComponent(
        this.props.modalData.url
      )}&key=${encodeURIComponent(key)}&apikey=${encodeURIComponent(
        this.props.modalData.apiKey
      )}&token=${encodeURIComponent(this.props.modalData.token)}`;
      var link = document.createElement("a");
      link.download = info.node.props.label;
      link.href = url;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  renderTree() {
    let tree = this.state.objects.reduce((tree, object) => {
      // create the root node for this object
      let branch = {
        type: "tree",
        children: {},
      };
      let curNode = branch;

      // iterate through the folders and add each folder as a child of the previous one
      object.key.split("/").forEach((dir) => {
        curNode.children[dir] = {
          type: "branch",
          children: {},
        };
        curNode = curNode.children[dir];
      });

      // set the last node as a leaf
      curNode.type = "leaf";
      curNode.key = object.key;
      curNode.size = object.size;
      curNode.modified = object.last_modified;

      // merge our new branch into the tree
      merge(tree, branch);
      return tree;
    }, {});

    return this.renderNode(tree);
  }

  renderNode(node, key) {
    if (key === "") {
      return null;
    }

    if (node.type === "tree") {
      return (
        <Tree onSelect={this.onNodeSelected}>
          {Object.keys(node.children).map((key) => {
            return this.renderNode(node.children[key], key);
          })}
        </Tree>
      );
    }

    if (node.type === "branch") {
      return (
        <TreeNode title={key} key={key}>
          {Object.keys(node.children).map((key) => {
            return this.renderNode(node.children[key], key);
          })}
        </TreeNode>
      );
    }

    if (node.type === "leaf") {
      return (
        <TreeNode
          title={
            <span className="leaf">
              <span className="leafName">{key}</span>
              <span className="leafSize">{this.formatFileSize(node.size)}</span>
              <span className="leafModified">
                <DateTimeFormat dateTime={node.modified} dateOnly />
              </span>
            </span>
          }
          key={node.key}
          label={key}
        />
      );
    }
  }

  render() {
    if (this.state.loading) {
      return (
        <div className="modal-content s3-objects-modal">
          <img src={AmazonLogo} alt="s3" />
          <h2>{this.props.modalData.url}</h2>
          <LoadingBanner />
        </div>
      );
    }

    if (this.state.error) {
      return (
        <div className="modal-content s3-objects-modal">
          <img src={AmazonLogo} alt="s3" />
          <h2>{this.props.modalData.url}</h2>
          <span>{this.state.error}</span>
        </div>
      );
    }

    if (!this.state.objects || this.state.objects.length === 0) {
      return (
        <div className="modal-content s3-objects-modal">
          <img src={AmazonLogo} alt="s3" />
          <h2>{this.props.modalData.url}</h2>
          <span>No files found.</span>
        </div>
      );
    }

    return (
      <div className="modal-content s3-objects-modal">
        <span>
          <img src={AmazonLogo} alt="s3" />
          <h2>{this.props.modalData.url}</h2>
        </span>
        <p>
          {
            "Single click on any file to download. If the download fails it's likely that the file has private permissions."
          }
        </p>
        {this.renderTree()}
      </div>
    );
  }
}

export default S3ObjectsModal;
