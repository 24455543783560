import { FC } from "react";
import NavLink from "../_common/components/NavLink";
import { pageDescriptions } from "../_common/pageDescriptions";
import { ClickNavLinkFunc } from "../_common/components/navigation/BreachSightNavItems";

interface ThreatMonitoringNavItemsProps {
  pathname: string;
  clickNavLinkFunc: ClickNavLinkFunc;
}

export const ThreatMonitoringBreachsightNavItems: FC<
  ThreatMonitoringNavItemsProps
> = ({ pathname, clickNavLinkFunc }) => {
  const rootPath = "/breachrisk/threatmonitoring";
  const p = (p: string) => `${rootPath}${p}`;

  return (
    <>
      <NavLink
        icon="threat-monitoring"
        text="Threat Monitoring"
        onClick={clickNavLinkFunc(rootPath, "Threat Monitoring")}
        to={rootPath}
        hoverText={pageDescriptions.ThreatMonitoring}
        hasOrgPermission={true}
        hasUserPermission={true}
      />
      {pathname.startsWith(rootPath) && (
        <>
          <NavLink
            icon="cyberrisk"
            text={"Analytics"}
            isSubMenuItem
            hoverText={pageDescriptions.ThreatMonitoringAnalytics}
            onClick={clickNavLinkFunc(p("/analytics"), "Analytics")}
            to={p("/analytics")}
            hasOrgPermission={true}
            hasUserPermission={true}
          />
          <NavLink
            icon="cyberrisk"
            text={"Dark Web"}
            isSubMenuItem
            hoverText={pageDescriptions.ThreatMonitoringDarkWeb}
            onClick={clickNavLinkFunc(p("/darkweb"), "Dark Web")}
            to={p("/darkweb")}
            hasOrgPermission={true}
            hasUserPermission={true}
          />
        </>
      )}
    </>
  );
};

interface ThreatMonitoringNavItemsNewProps
  extends ThreatMonitoringNavItemsProps {
  disabled: boolean;
}
export function ThreatMonitoringBreachsightNavItemsNew({
  pathname,
  disabled,
  clickNavLinkFunc,
}: ThreatMonitoringNavItemsNewProps) {
  const rootPath = "/breachrisk/threatmonitoring";
  const p = (p: string) => `${rootPath}${p}`;

  return (
    <>
      <NavLink
        text="Threat Monitoring"
        className="unstyled"
        disabled={disabled}
        onClick={clickNavLinkFunc(rootPath, "Threat Monitoring")}
        to={rootPath}
        hoverText={pageDescriptions.ThreatMonitoring}
        hasOrgPermission={true}
        hasUserPermission={true}
      />
      {pathname.startsWith(rootPath) && (
        <>
          <NavLink
            text={"Analytics"}
            className="unstyled"
            isSubMenuItem
            disabled={disabled}
            hoverText={pageDescriptions.ThreatMonitoringAnalytics}
            onClick={clickNavLinkFunc(p("/analytics"), "Analytics")}
            to={p("/analytics")}
            hasOrgPermission={true}
            hasUserPermission={true}
          />
          <NavLink
            text={"Dark Web"}
            className="unstyled"
            isSubMenuItem
            disabled={disabled}
            hoverText={pageDescriptions.ThreatMonitoringDarkWeb}
            onClick={clickNavLinkFunc(p("/darkweb"), "Dark Web")}
            to={p("/darkweb")}
            hasOrgPermission={true}
            hasUserPermission={true}
          />
        </>
      )}
    </>
  );
}

export default ThreatMonitoringBreachsightNavItems;
