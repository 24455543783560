import PageHeader from "../../_common/components/PageHeader";
import { FC, useCallback, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { DefaultRouteProps } from "../../_common/types/router";
import userbaseApi from "../api/userbase.api";
import RiskSummaryTable from "../../vendorrisk/components/RiskSummaryTable";
import OrgScoresChart from "../components/OrgScoresChart";
import {
  useHasOrgEntitlement,
  useHasUserOrPortfolioPermissions,
  UserUserBaseWrite,
} from "../../_common/permissions";
import { IRisk } from "../../vendorrisk/components/RiskSummaryDetails";
import {
  createRemediationRequestUrl,
  createRiskModificationUrl,
} from "../UserBaseAppRouter";
import { CreateRiskModificationLocationState } from "./CreateRiskModificationView";
import { trackEvent } from "../../_common/tracking";
import "./RiskProfileView.scss";
import { RiskProfileRiskTypes } from "../../_common/constants";
import DomainsAndIPsPanelGroup, {
  OpenSidePanel,
  GetVulnPanel,
} from "../../vendorrisk/components/DomainsAndIPsPanelGroup";
import UserBaseAPI from "../api/userbase.api";
import {
  RemediationRequestStatus,
  RemediationRequestWithMeta,
  UserInRemediation,
} from "../../_common/types/remediation";
import * as Permissions from "../../_common/permissions";

export const computeUsersInRemediation = (
  requests: RemediationRequestWithMeta[],
  filterByUserUUID?: string
): Record<string, UserInRemediation> => {
  const usersUnderRemediation: Record<string, UserInRemediation> = {};
  requests
    .filter(
      (remReq) =>
        remReq.status === RemediationRequestStatus.Open ||
        remReq.status === RemediationRequestStatus.AwaitingReview
    )
    .forEach((remReq) => {
      remReq.risks.forEach((risk) => {
        const affectedUserUUIDs = risk.saasUsers
          .filter((u) => !filterByUserUUID || u.userUUID === filterByUserUUID)
          .map((u) => u.userUUID);
        if (!affectedUserUUIDs.length) {
          return;
        }

        const riskUnderRemediation = usersUnderRemediation[risk.checkId];
        if (riskUnderRemediation) {
          riskUnderRemediation.remediationRequestIds = [
            ...new Set([
              ...riskUnderRemediation.remediationRequestIds,
              remReq.id,
            ]),
          ];
          riskUnderRemediation.userUUIDs = [
            ...new Set([
              ...riskUnderRemediation.userUUIDs,
              ...affectedUserUUIDs,
            ]),
          ];
        } else {
          usersUnderRemediation[risk.checkId] = {
            checkId: risk.checkId,
            remediationRequestIds: [remReq.id],
            userUUIDs: affectedUserUUIDs,
          };
        }
      });
    });
  return usersUnderRemediation;
};

type IRiskProfileViewProps = DefaultRouteProps;

const RiskProfileView: FC<IRiskProfileViewProps> = ({ location }) => {
  const history = useHistory();

  const [includePassedRisks, setIncludePassedRisks] = useState(false);
  const [showWaivedRisks, setShowWaivedRisks] = useState(false);

  const userHasUserBaseWrite =
    useHasUserOrPortfolioPermissions(UserUserBaseWrite);

  const orgHasUserBaseRemediationRequests = useHasOrgEntitlement(
    Permissions.OrgAccessUserBaseRemediationRequests
  );

  const { data: risksData, isLoading: isRisksLoading } =
    userbaseApi.useGetUserBaseRisksV1Query({});
  const { data: riskWaiverData, isLoading: isRiskWaiversLoading } =
    userbaseApi.useGetUserBaseRiskWaiversV1Query();
  const { data: remediationsData, isLoading: isRemediationsLoading } =
    UserBaseAPI.useGetUserBaseRemediationsV1Query(undefined, {
      skip: !orgHasUserBaseRemediationRequests,
    });

  const pageHeaderBackAction = useMemo(
    () =>
      location.state?.backContext?.goBack
        ? history.goBack
        : location.state?.backContext
          ? () =>
              history.push(
                location.state.backContext?.backTo ?? "",
                location.state.backContext?.backToContext
              )
          : undefined,
    [history, location.state?.backContext]
  );

  const getUrlPrefix = useCallback(() => "/userbase", []);

  const onCreateWaiverForRisk = useCallback(
    (risk: IRisk) => {
      const context: CreateRiskModificationLocationState = { riskId: risk.id };

      history.push(createRiskModificationUrl, {
        backContext: {
          goBack: true,
          backToText: `Back to Risk Profile`,
        },
        ...context,
      });
    },
    [history]
  );

  const onIncludePassedRisk = (val: boolean) => {
    setIncludePassedRisks(val);
    trackEvent("UserBase_IncludePassedRisks_Toggled", { value: val });
  };

  const onShowWaivedRisks = (val: boolean) => {
    setShowWaivedRisks(val);
    trackEvent("UserBase_ShowWaivedRisks_Toggled", { value: val });
  };

  const onRequestSaaSRemediation = useCallback(
    (risk: IRisk) => {
      history.push(createRemediationRequestUrl, {
        backContext: {
          goBack: true,
          backToText: `Back to Risk Profile`,
        },
        riskId: risk.id,
        riskType: RiskProfileRiskTypes.SaaS,
      });
    },
    [createRemediationRequestUrl]
  );

  interface domainsAndIPsPanelGroupRiskParams {
    riskPanel?: JSX.Element;
    getVulnPanel?: GetVulnPanel;
  }

  const [selectedRiskParams, setSelectedRiskParams] = useState<
    domainsAndIPsPanelGroupRiskParams | undefined
  >(undefined);

  const onSelectRisk = (riskId: string | undefined) => {
    if (!riskId) {
      return;
    }

    setSelectedRiskParams(undefined);
    OpenSidePanel(history, { risk: riskId });
  };

  const onSelectedRiskParamsChange = useCallback(
    (riskPanel?: JSX.Element, getVulnPanel?: GetVulnPanel) => {
      setSelectedRiskParams({
        riskPanel: riskPanel,
        getVulnPanel: getVulnPanel,
      });
    },
    []
  );

  const usersUnderRemediation = useMemo(() => {
    return computeUsersInRemediation(remediationsData?.requests ?? []);
  }, [remediationsData?.requests]);

  const isLoading =
    isRisksLoading || isRiskWaiversLoading || isRemediationsLoading;

  return (
    <div id="risk_profile">
      <PageHeader
        history={history}
        title="Risk Profile"
        backAction={pageHeaderBackAction}
        backText={location.state?.backContext?.backToText ?? undefined}
        infoSectionPageKey={"userbaseRiskProfile"}
        infoSection={
          <p>
            Risk Profile summarizes the user-related risks in your organization.
            You can monitor your security rating over time, drill into
            individual risks and manage them to improve your security posture.
          </p>
        }
      />
      <OrgScoresChart />
      <RiskSummaryTable
        className={"user-base-risk-profile"}
        history={history}
        loading={isLoading}
        title="Risk details"
        risks={risksData?.risks ?? []}
        filterActive={false}
        urlPrefix={getUrlPrefix}
        canManageVendorRiskWaivers={userHasUserBaseWrite}
        userHasWriteRiskWaiversPermission={userHasUserBaseWrite}
        userHasWriteRemediationPermission={userHasUserBaseWrite}
        hideShowWaivedRisksOption={false}
        hideShowPassedChecksOption={false}
        includePassedRisks={includePassedRisks}
        setIncludePassedRisks={onIncludePassedRisk}
        onCreateRiskWaiver={onCreateWaiverForRisk}
        showWaivedRisks={showWaivedRisks}
        onShowWaivedRisks={onShowWaivedRisks}
        customerRiskWaivers={riskWaiverData?.waivers}
        onRequestRemediationForRisk={
          orgHasUserBaseRemediationRequests
            ? onRequestSaaSRemediation
            : undefined
        }
        usersInRemediation={usersUnderRemediation}
        assetsColTitle={"Users affected"}
        onSelectRisk={onSelectRisk}
        onSelectedRiskParamsChange={onSelectedRiskParamsChange}
      />
      <DomainsAndIPsPanelGroup
        history={history}
        location={location}
        isSubsidiary={false}
        isVendorPortal={false}
        riskPanel={selectedRiskParams?.riskPanel}
        getVulnPanel={selectedRiskParams?.getVulnPanel}
      />
    </div>
  );
};

export default RiskProfileView;
