import { ComponentType } from "react";
import withRequireEntitlement from "./WithRequireEntitlement";
import withRequirePermission from "./WithRequirePermission";

// withRequireEntitlementAndPermission stops a component being included in the React-DOM if the current org
// does not have the specified entitlement or the user doesn't have the specified permission
const withRequireEntitlementAndPermission = <BaseProps extends object>(
  BaseComponent: ComponentType<BaseProps>,
  requiredEntitlement: string | string[],
  requiredPermission: string | string[]
): ComponentType<BaseProps> => {
  return withRequirePermission(
    withRequireEntitlement(BaseComponent, requiredEntitlement),
    requiredPermission
  );
};

export default withRequireEntitlementAndPermission;
