import classnames from "classnames";
import { setScoringPeriod } from "../../reducers/cyberRiskActions";
import { DefaultThunkDispatch } from "../../../_common/types/redux";
import "../../style/components/executive_summary/TimeControls.scss";

export interface ITimeControlsProp {
  dispatch: DefaultThunkDispatch;
  dataDays: number;
  dataDaysKey: string;
}

export const TimeControls = (props: ITimeControlsProp): JSX.Element => {
  return (
    <>
      <div className="time-controls">
        <div
          className={classnames({ active: props.dataDays === 30 })}
          onClick={() =>
            props.dispatch(setScoringPeriod(30, props.dataDaysKey))
          }
        >
          1 month
        </div>
        <div
          className={classnames({ active: props.dataDays === 90 })}
          onClick={() =>
            props.dispatch(setScoringPeriod(90, props.dataDaysKey))
          }
        >
          3 months
        </div>
        <div
          className={classnames({
            active: props.dataDays === 365,
          })}
          onClick={() =>
            props.dispatch(setScoringPeriod(365, props.dataDaysKey))
          }
        >
          12 months
        </div>
      </div>
    </>
  );
};
