import { useState } from "react";
import { DefaultThunkDispatch } from "../../types/redux";
import ModalV2 from "../ModalV2";
import { contactSupport } from "../../helpers";
import classnames from "classnames";
import { addDefaultUnknownErrorAlert } from "../../reducers/messageAlerts.actions";
import { markOnboardingMeetingBooked } from "../../reducers/freeTrial.actions";
import LoadingBanner from "../core/LoadingBanner";
import { HubspotMeeting } from "../../../registration/FreeTrialRegister";
import "../../style/components/BookOnboardingCallModal.scss";

interface IBookOnboardingCallModalProps {
  dispatch: DefaultThunkDispatch;
  active: boolean;
  onClose: () => void;
  emailAddress: string;
  firstName: string;
  lastName: string;
  calendar?: string;
}

export const BookOnboardingCallModal = (
  props: IBookOnboardingCallModalProps
) => {
  const {
    dispatch,
    onClose,
    active,
    emailAddress,
    firstName,
    lastName,
    calendar,
  } = props;
  const [showThankyou, setShowThankyou] = useState(false);
  const [meetingBooked, setMeetingBooked] = useState(false);
  const [inError, setInError] = useState(false);

  const resetState = () => {
    setInError(false);
    setMeetingBooked(false);
    setShowThankyou(false);
  };

  const onMeetingBooked = async () => {
    setInError(false);
    setMeetingBooked(true);

    try {
      await dispatch(markOnboardingMeetingBooked());
      setShowThankyou(true);
      return;
    } catch (e) {
      console.error(e);
    }
    dispatch(
      addDefaultUnknownErrorAlert(
        "An unknown error occurred. Please contact support@upguard.com."
      )
    );
    setInError(true);
    setMeetingBooked(false);
  };

  return (
    <ModalV2
      className={classnames("free-trial-meeting-modal", {
        "free-trial-meeting-modal-shrunk": inError || showThankyou,
      })}
      active={active}
      onClose={() => {
        onClose();
        resetState();
      }}
    >
      <div className="simple-modal-form">
        <div>
          {inError && (
            <>
              <h2>Booking failed</h2>
              <p className={"thankyou"}>
                {`Sorry, it looks like something went wrong with our booking
                system. Please `}
                <a href="#" onClick={contactSupport}>
                  contact support
                </a>
                to arrange your onboarding call, or you can close this window
                and try again.
              </p>
            </>
          )}
          {showThankyou && (
            <>
              <h2>Meeting booked!</h2>
              <p className={"thankyou"}>
                You should receive a meeting invitation by email very shortly.
              </p>
              <p className={"thankyou"}>
                {`We are looking forward to meeting with you and helping you
                leverage UpGuard to meet your cybersecurity needs. If you have
                any questions, or need to change your meeting time, please `}
                <a href="#" onClick={contactSupport}>
                  contact support
                </a>
                .
              </p>
            </>
          )}
          {!showThankyou && !inError && (
            <>
              <h2>Book your free onboarding call</h2>
              <div className="description">
                Book a time with one of our product specialists to ensure
                you&apos;re getting the most out of your trial.
              </div>
              <div className={"calendar-container"}>
                {!meetingBooked && calendar && (
                  <HubspotMeeting
                    calendarId={calendar}
                    emailAddress={emailAddress}
                    firstName={firstName}
                    lastName={lastName}
                    onMeetingBooked={onMeetingBooked}
                  />
                )}
                {meetingBooked && !showThankyou && <LoadingBanner />}
              </div>
            </>
          )}
        </div>
      </div>
    </ModalV2>
  );
};
