import React, {
  FocusEventHandler,
  KeyboardEvent,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { debounce as _debounce, isEqual as _isEqual } from "lodash";
import TextareaAutosize from "react-autosize-textarea";
import classNames from "classnames";
import { CSSTransition, TransitionGroup } from "react-transition-group";

import "../style/components/TextField.scss";
import {
  validateEmail,
  validatePhone,
  validateUrl,
  validateUrlWithProtocol,
} from "../helpers";
import classnames from "classnames";

// useTextWithValid
// effect to easily have a val and valid field with func that can be passed into onChanged
// for use with text field
export const useTextWithValid = (initialValue = "", initialValid = false) => {
  const [val, setVal] = useState(initialValue);
  const [isValid, setValid] = useState(initialValid);
  const [hasChanged, setHasChanged] = useState(false);

  const onChanged = useCallback((val: string, valid: boolean) => {
    setVal(val);
    setValid(valid);
    setHasChanged(true);
  }, []);

  return [val, isValid, onChanged, hasChanged] as const;
};

// similar to useTextWithValid, but with a clear function.
// Clearing sets the value to the fallback value and resets hasChanged to false
export function useResettableValidText(
  initialValue: string | null,
  fallbackValue: string
) {
  const [value, setValue] = useState(initialValue);
  const [isValid, setIsValid] = useState(true);
  const [hasChanged, setHasChanged] = useState(false);

  const clearValue = () => {
    setValue(fallbackValue);
    setIsValid(true);
    setHasChanged(false);
  };

  // onChanged is compatible with TextField's onChange prop
  const onChanged = (newValue: string, isNewValueValid: boolean) => {
    setValue(newValue);
    setIsValid(isNewValueValid);
    setHasChanged(true);
  };

  return [value, isValid, hasChanged, onChanged, clearValue] as const;
}

export type textSetter = (val: string, valid: boolean) => void;
export type textWithValid = { val: string; valid: boolean };

export enum MinLengthType {
  contactName = 3,
}

export enum MaxLengthType {
  none = 0,
  contactName = 50,
  label = 100,
  reasonableLength = 300,
  longerReasonableLength = 1000,
  message = 4000,
}

const RENDER_ERROR_DELAY = 1500;

export interface TextFieldData {
  value: string;
  isValid: boolean;
}

export interface ITextEditProps {
  value: string;
  suffix?: string;

  // Debounced callback triggered on input change
  onChanged: (val: string, valid: boolean) => void;

  type?:
    | "email"
    | "text"
    | "number"
    | "url"
    | "url-without-protocol"
    | "phone"
    | "password";
  placeholder?: string;
  name?: string;

  disabled?: boolean;
  readonly?: boolean;
  required?: boolean;

  // Optional max length.
  maxLength?: MaxLengthType | number;

  // Optional min length
  minLength?: number;

  /**
   * Optionally allow more text to be typed than maxLength.
   *
   * If true, error text will display if length is exceeded.
   *
   * Default false.
   */
  allowTextOverflow?: boolean;

  // Always show character counter, instead of only when over ~80% maxLength. Default false.
  showCharCountAlways?: boolean;

  // Hide character counter always. Default false.
  hideCharCount?: boolean;

  // Display as textarea. Default false.
  multiLine?: boolean;

  // If true use the standard <textarea> control for multi line text.
  // The <TextareaAutosize> control has considerably worse performance. Defaults false.
  useStandardTextArea?: boolean;

  // Available rows for textarea to expand. Default 25.
  maxRows?: number;

  // Optional array of additional error messages to display.
  // These will be displayed after the info and any internal error messages.
  errorTexts?: string[];

  // Optional info text string to display. This will always appear first.
  infoText?: string;

  className?: string;
  inputClassname?: string;

  autoFocus?: boolean;

  onFocus?: FocusEventHandler<HTMLTextAreaElement>;
  onBlur?: FocusEventHandler<HTMLTextAreaElement>;

  // Trigger blur() on enter keypress. Not compatible with multiLine.
  enterKeyShouldBlur?: boolean;

  // Hide the error and info text display (and container, including char count)
  hideMessageTexts?: boolean;

  debounceTime?: number;

  errorsTimeout?: number;

  maxNumber?: number;
  minNumber?: number;
  autoClampNumber?: boolean;

  // if true, display error text right from the off
  renderErrorsOnStart?: boolean;

  // If true, display the field as if it's a static div, but editable on hover.
  staticEditable?: boolean;

  inputColor?: "orange";

  requiredErrorText?: string; // If set, overrides REQUIRED_ERROR_TEXT

  pattern?: RegExp; // Regex pattern for input validation

  inputMode?:
    | "none"
    | "text"
    | "tel"
    | "url"
    | "email"
    | "numeric"
    | "decimal"
    | "search";

  id?: string;
}

const REQUIRED_ERROR_TEXT = "This is a required field";
const MAX_LENGTH_ERROR_TEXT = "Too long";
const MIN_LENGTH_ERROR_TEXT = "Too short";
const INVALID_EMAIL_ADDRESS_ERROR_TEXT = "Invalid email address";
const MAX_NUM_ERROR = "Number too large";
const MIN_NUM_ERROR = "Number too small";
const URL_ERROR = "Invalid URL";
const PHONE_ERROR = "Invalid phone number";

interface IErrorState {
  isRequiredError: boolean;
  isMaxLengthError: boolean;
  isMinLengthError: boolean;
  isEmailError: boolean;
  isMaxNumError: boolean;
  isMinNumError: boolean;
  isCustomError: boolean;
  isUrlError: boolean;
  isPhoneError: boolean;
}

const TextField = React.forwardRef(function TextField(
  {
    name,
    type = "text",
    value,
    suffix,
    errorTexts = [],
    maxRows = 25,
    required = false,
    inputClassname,
    disabled = false,
    placeholder,
    multiLine = false,
    useStandardTextArea = false,
    readonly = false,
    allowTextOverflow = false,
    maxLength = MaxLengthType.none,
    minLength,
    showCharCountAlways = false,
    hideCharCount = false,
    hideMessageTexts,
    debounceTime = 0,
    onChanged,
    onBlur,
    autoFocus = false,
    infoText,
    className,
    errorsTimeout = 250,
    maxNumber,
    minNumber,
    autoClampNumber,
    renderErrorsOnStart = false,
    staticEditable,
    inputColor,
    requiredErrorText = REQUIRED_ERROR_TEXT,
    enterKeyShouldBlur,
    pattern,
    inputMode,
    id,
  }: ITextEditProps,
  ref: React.Ref<any>
) {
  const [val, setVal] = useState(value || "");

  const [isEdited, setIsEdited] = useState(false);

  // Error rendering
  const [isRequiredError, setIsRequiredError] = useState(false);
  const [isMaxLengthError, setIsMaxLengthError] = useState(false);
  const [isMinLengthError, setIsMinLengthError] = useState(false);
  const [isEmailError, setIsEmailError] = useState(false);
  const [isMaxNumError, setIsMaxNumError] = useState(false);
  const [isMinNumError, setIsMinNumError] = useState(false);
  const [isCustomError, setIsCustomError] = useState(false);
  const [isUrlError, setIsUrlError] = useState(false);
  const [isPhoneError, setIsPhoneError] = useState(false);

  // Render errors when not initial render AND not just reset
  const [renderErrors, setRenderErrors] = useState(renderErrorsOnStart);

  // Track initial render so we don't display any errors then
  const initialRender = useRef(true);

  // Manage current error state seperate from rendering, and update display on a delay to minimise jank
  const errorState = useRef({
    isRequiredError: false,
    isMaxLengthError: false,
    isMinLengthError: false,
    isEmailError: false,
    isMaxNumError: false,
    isMinNumError: false,
    isCustomError: false,
  } as IErrorState);

  const setErrorState = (newState: Partial<IErrorState>) => {
    Object.assign(errorState.current, newState);

    Object.entries(newState).forEach(([k, val]) => {
      let func: React.Dispatch<SetStateAction<boolean>>;

      const key: keyof IErrorState = k as any;

      switch (key) {
        case "isRequiredError":
          func = setIsRequiredError;
          break;
        case "isMaxLengthError":
          func = setIsMaxLengthError;
          break;
        case "isMinLengthError":
          func = setIsMinLengthError;
          break;
        case "isEmailError":
          func = setIsEmailError;
          break;
        case "isMaxNumError":
          func = setIsMaxNumError;
          break;
        case "isMinNumError":
          func = setIsMinNumError;
          break;
        case "isCustomError":
          func = setIsCustomError;
          break;
        case "isUrlError":
          func = setIsUrlError;
          break;
        case "isPhoneError":
          func = setIsPhoneError;
          break;
        default:
          return;
      }

      if (func && val !== undefined) {
        if (val) {
          // Delay rendering a new error, and re-check before we display
          setTimeout(() => {
            if (errorState.current[key]) {
              func(val);
            }
          }, 256);
        } else {
          // Clear error immediately
          func(val);
        }
      }
    });
  };

  const isInternalValid = () => {
    let valid = true;

    Object.entries(errorState.current).forEach(([key, isError]) => {
      if (key !== "isCustomError" && valid && isError) {
        valid = false;
      }
    });

    return valid;
  };

  const debouncedNotify = useCallback(
    _debounce(onChanged, debounceTime, {
      leading: true,
    }),
    [debounceTime, onChanged]
  );

  // Debounce rendering errors if user is still typing
  const debouncedRenderErrors = useCallback(
    _debounce(() => setRenderErrors(true), RENDER_ERROR_DELAY),
    []
  );

  function validate(newValue: string): string {
    // Number validation (do this first if we need to edit the entered value)
    if (type === "number") {
      const cleanValue = newValue.replace(/\D/g, "");
      const parsed = parseInt(cleanValue);

      if (cleanValue === val) {
        newValue = val;
      } else if (maxNumber && parsed > maxNumber) {
        if (autoClampNumber) {
          newValue = maxNumber.toString();
        } else {
          setErrorState({ isMaxNumError: true });
        }
      } else if (minNumber && parsed < minNumber) {
        if (autoClampNumber) {
          newValue = minNumber.toString();
        } else {
          setErrorState({ isMinNumError: true });
        }
      } else {
        newValue = cleanValue;
        setErrorState({ isMaxNumError: false, isMinNumError: false });
      }
    }

    // Required
    if (required && newValue.length === 0) {
      setErrorState({ isRequiredError: true });
    } else {
      setErrorState({ isRequiredError: false });
    }

    // Max length
    if (allowTextOverflow && maxLength && newValue.length > maxLength) {
      setErrorState({ isMaxLengthError: true });
    } else {
      setErrorState({ isMaxLengthError: false });
    }

    // Min length
    if (minLength && newValue.length > 0 && minLength > newValue.length) {
      setErrorState({ isMinLengthError: true });
    } else {
      setErrorState({ isMinLengthError: false });
    }

    // Email type validation
    if (type === "email") {
      if (newValue.length > 0 && !validateEmail(newValue)) {
        setErrorState({ isEmailError: true });
      } else {
        setErrorState({ isEmailError: false });
      }
    }

    // Url validation
    if (type === "url" || type === "url-without-protocol") {
      const validateUrlFunc =
        type === "url" ? validateUrlWithProtocol : validateUrl;

      if (newValue.length > 0 && !validateUrlFunc(newValue)) {
        setErrorState({ isUrlError: true });
      } else {
        setErrorState({ isUrlError: false });
      }
    }

    if (type == "phone") {
      if (newValue.length > 0 && !validatePhone(newValue)) {
        setErrorState({ isPhoneError: true });
      } else {
        setErrorState({ isPhoneError: false });
      }
    }
    return newValue;
  }

  const changed = (newVal: string | undefined) => {
    // Internal validation
    const isControlEdited = isEdited || newVal !== val;

    if (newVal === undefined) {
      newVal = "";
    }

    if (!disabled && isControlEdited) {
      const valToSet = validate(newVal.toString());
      if (valToSet !== "") {
        if (val === "") {
          // Hide errors if just starting typing
          setRenderErrors(false);
        }
        debouncedRenderErrors();
      } else {
        setRenderErrors(true);
      }
      setVal(valToSet);
      debounceTime > 0
        ? debouncedNotify(valToSet, isInternalValid())
        : onChanged(valToSet, isInternalValid());

      setIsEdited(true);
    } else {
      setIsRequiredError(false);
      setIsMaxLengthError(false);
    }
  };

  // Respond to external value change
  useEffect(() => {
    if (value !== val) {
      changed(value);

      if (value === "") {
        // On reset, hide errors initially
        setRenderErrors(false);
      }
    }
  }, [value]);

  // Respond to configuration changes that have flow-on effects (except for when in initial render)
  useEffect(() => {
    if (!initialRender.current && value !== val) {
      changed(value);
    } else {
      // Perform initial validation if renderErrorsOnStart specified.
      if (renderErrorsOnStart) {
        validate(val);
      }
      initialRender.current = false;
    }

    setErrorState({
      isCustomError: errorTexts ? errorTexts.length > 0 : false,
    });
    return () => {
      setErrorState({});
    };
  }, [value, required, disabled, maxLength, errorTexts, renderErrorsOnStart]);

  // If readonly mode, just render value
  if (readonly) {
    return <span>{value}</span>;
  }

  const commonProps = {
    value: val,
    name: name,
    placeholder: placeholder,
    autoFocus,
    onChange: (e: any) => {
      if (
        pattern &&
        e.target.value &&
        !new RegExp(pattern).test(e.target.value)
      ) {
        e.preventDefault();
        return;
      }

      changed(e.target.value);
    },
    onBlur: (e: any) => {
      setRenderErrors(true); // When tabbing away, show errors immediately
      changed(e.target.value);
      if (onBlur) {
        onBlur(e);
      }
    },
    onKeyUp:
      !multiLine && enterKeyShouldBlur
        ? (e: KeyboardEvent) => {
            if (e.key === "Enter") {
              (e.target as any).blur?.();
            }
          }
        : undefined,
    required: required,
    disabled: disabled,
    maxLength: !allowTextOverflow && maxLength ? maxLength : undefined,
    className: classNames(inputClassname, {
      error: renderErrors && (!isInternalValid() || errorTexts?.length > 0),
    }),
    inputMode: type === "number" ? "numeric" : inputMode,
    id: id,
  };

  let inputType = type;
  switch (type) {
    case "number":
      inputType = "text";
      if (!pattern) {
        pattern = RegExp("^[0-9]*$");
      }
      break;
    case "url-without-protocol":
    case "phone":
      inputType = "text";
      break;
    case "password":
      inputType = "password";
      break;
  }

  let input: JSX.Element;

  if (multiLine) {
    if (useStandardTextArea) {
      input = <textarea ref={ref} {...commonProps} />;
    } else {
      input = (
        <TextareaAutosize
          ref={ref}
          maxRows={maxRows}
          {...commonProps}
          async={true}
        />
      );
    }
  } else if (suffix && suffix.length > 0) {
    input = (
      <label data-value={suffix} className="suffix-label">
        <input ref={ref} type={inputType} {...commonProps} />
      </label>
    );
  } else {
    input = <input ref={ref} type={inputType} {...commonProps} />;
  }

  const messages: JSX.Element[] = [];

  if (renderErrors) {
    if (isRequiredError) {
      messages.push(getErrorDisplay(requiredErrorText));
    }
    if (isMinLengthError) {
      messages.push(
        getErrorDisplay(`${MIN_LENGTH_ERROR_TEXT} (minimum ${minLength})`)
      );
    }
    if (isMaxLengthError) {
      messages.push(getErrorDisplay(MAX_LENGTH_ERROR_TEXT));
    }
    if (isEmailError) {
      messages.push(getErrorDisplay(INVALID_EMAIL_ADDRESS_ERROR_TEXT));
    }
    if (isMaxNumError) {
      messages.push(getErrorDisplay(MAX_NUM_ERROR));
    }
    if (isMinNumError) {
      messages.push(getErrorDisplay(MIN_NUM_ERROR));
    }
    if (isUrlError) {
      messages.push(getErrorDisplay(URL_ERROR));
    }
    if (isPhoneError) {
      messages.push(getErrorDisplay(PHONE_ERROR));
    }

    if (errorTexts && isCustomError) {
      messages.push(...errorTexts.map((e) => getErrorDisplay(e)));
    }
  }

  if (infoText) {
    messages.unshift(
      <CSSTransition
        key={"info-text"}
        timeout={errorsTimeout}
        classNames="fade-transition"
      >
        <div className={"info-text"}>{infoText}</div>
      </CSSTransition>
    );
  }

  const showCharCount =
    !hideCharCount &&
    (showCharCountAlways || (maxLength && maxLength * 0.8 < val.length));

  return (
    <div
      className={classnames("text-field", className, inputColor, {
        "with-suffix": suffix && suffix.length > 0,
        "static-editable": staticEditable,
      })}
    >
      {input}
      {staticEditable && (
        <div className="static-editable-edit cr-icon-pencil-2" />
      )}
      {!hideMessageTexts && (
        <TransitionGroup>
          {messages.length > 0 && (
            <CSSTransition
              key={"messages"}
              timeout={250}
              classNames={"fade-transition"}
            >
              <div className={"text-field-additional"}>
                <div className={"messages"}>
                  <TransitionGroup>{messages}</TransitionGroup>
                </div>
                <TransitionGroup>
                  {showCharCount && (
                    <CSSTransition
                      key={"char-count"}
                      timeout={250}
                      classNames="fade-transition"
                    >
                      <div className={"char-count"}>
                        <span
                          className={
                            maxLength && val.length > maxLength
                              ? "error-text"
                              : undefined
                          }
                        >
                          {maxLength ? (
                            <>
                              {maxLength - val.length} of {maxLength} characters
                              remaining
                            </>
                          ) : undefined}
                        </span>
                      </div>
                    </CSSTransition>
                  )}
                </TransitionGroup>
              </div>
            </CSSTransition>
          )}
        </TransitionGroup>
      )}
    </div>
  );
});

const getErrorDisplay = (errorText: string) => {
  return (
    <CSSTransition key={errorText} timeout={250} classNames="fade-transition">
      <div className={"error-text"}>
        <i className={"icon-info"} />
        {errorText}
      </div>
    </CSSTransition>
  );
};

export default TextField;
