import { SelectNode } from "../../../survey_builder/types/types";
import AnswerOption from "../AnswerOption";
import { SelectAnswers } from "../../surveyViewer.helpers";
import { BaseNodeContentProps } from "./baseNode";
import { DiffTextBox } from "./InputNodeContent";
import TextFieldWithActions from "../../../_common/components/TextFieldWithActions";
import { useEnsureExternalLinksInNewTab } from "./NodeHeading";

interface SelectNodeContentProps extends BaseNodeContentProps {
  node: SelectNode;
}

const SelectNodeContent = (props: SelectNodeContentProps) => {
  const currentAnswers =
    (props.suggestedAnswer as SelectAnswers) ??
    (props.answer as SelectAnswers) ??
    {};
  const otherAnswers = (props.otherAnswer as SelectAnswers) ?? {};
  const notesAnswerId = props.node.nodeId + "-notes";

  const options = props.node.answers.map((a, idx) => {
    const answerForOption = currentAnswers[a.id] ?? "";
    const otherAnswerForOption = otherAnswers[a.id] ?? "";

    return (
      a.id != notesAnswerId && (
        <AnswerOption
          key={a.id}
          idx={idx}
          optionText={a.text}
          radio={props.node.radio}
          selected={answerForOption === "checked"}
          disabled={props.disabled}
          diffSide={
            answerForOption != otherAnswerForOption ? props.diffSide : undefined
          }
          isSuggestion={!!props.suggestedAnswer}
          onClick={() => {
            // if we are looking at a suggestion, then we still use that as the base as the user is 'editing' this answer
            const newAnswers = { ...currentAnswers };

            newAnswers[a.id] = !!props.suggestedAnswer
              ? "checked"
              : answerForOption === "checked"
                ? ""
                : "checked";

            if (props.node.radio) {
              for (const k in newAnswers) {
                if (k !== a.id && k !== notesAnswerId) {
                  newAnswers[k] = "";
                }
              }
            }

            props.onAnswerChanged(props.node.nodeId, newAnswers);
          }}
        />
      )
    );
  });

  const mainEl = useEnsureExternalLinksInNewTab();

  return (
    <div className={"question-answer-node-content"} ref={mainEl}>
      <div className={"answer-section"}>
        <div className={"answers"}>{options}</div>
        {props.gptSuggestionBox}
      </div>
      {props.node.allowNotes && (
        <div className={"notes"}>
          <div className={"node-name"}>
            {"Add additional information (optional)"}
          </div>
          {props.diffSide ? (
            <DiffTextBox
              answer={currentAnswers[notesAnswerId]}
              otherAnswer={otherAnswers[notesAnswerId]}
              side={props.diffSide}
              multiline={true}
            />
          ) : (
            <TextFieldWithActions
              value={currentAnswers[notesAnswerId] as string}
              onChanged={(val) => {
                const newAnswers = { ...currentAnswers };
                newAnswers[notesAnswerId] = val;
                props.onAnswerChanged(props.node.nodeId, newAnswers);
              }}
              multiLine={true}
              placeholder={!props.disabled ? "Type your answer here..." : ""}
              disabled={props.disabled}
              actionButtons={props.actionButtons}
              usePerformantTextField
            />
          )}
        </div>
      )}
    </div>
  );
};

export default SelectNodeContent;
