import { FC, memo, useCallback, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../types/reduxHooks";
import {
  addMessageAlert,
  EMessageAlertPosition,
} from "../reducers/messageAlerts.actions";
import { BannerType } from "../../vendorrisk/components/InfoBanner";
import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../types/reduxStore";
import { OnboardingStepName } from "../types/onboardingStepsMeta";
import { setOnboardingStepComplete } from "../reducers/commonActions";

// This component is mounted in the main Navigation component, and is used to show
// a message when the user has not seen a new terms and conditions update.
// Currently this is based on the TermsConditionsMar24 onboarding step, which has
// its visibility handled by onboarding_steps_v1.go on the backend.

const shouldShowTermsAndConditionsUpdateMessageSelector = createSelector(
  [(state: RootState) => state.common.userData?.onboardingSteps],
  (onboardingSteps) =>
    !!onboardingSteps?.[OnboardingStepName.TermsConditionsMar24]
);

const TermsAndConditionsUpdateMessage: FC = () => {
  const shouldShow = useAppSelector(
    shouldShowTermsAndConditionsUpdateMessageSelector
  );
  const dispatch = useAppDispatch();

  const onMessageDismissed = useCallback(() => {
    dispatch(
      setOnboardingStepComplete(OnboardingStepName.TermsConditionsMar24)
    );
  }, [dispatch]);

  // Show the terms message on mount if the user hasn't seen it yet.
  useEffect(() => {
    if (!shouldShow) {
      return;
    }

    dispatch(
      addMessageAlert({
        type: BannerType.INFO,
        position: EMessageAlertPosition.Bottom,
        onDismiss: onMessageDismissed,
        noDismissOnClearAll: true,
        message: "Terms and conditions have been updated",
        subMessages: [
          <div key="1">
            We have updated our{" "}
            <a
              href="https://www.upguard.com/company/platform-terms-and-conditions"
              rel="noreferrer"
              target="_blank"
            >
              Platform Terms & Conditions
            </a>{" "}
            and{" "}
            <a
              href="https://www.upguard.com/company/subprocessors"
              rel="noreferrer"
              target="_blank"
            >
              Subprocessors
            </a>{" "}
            to better reflect the services we provide.
          </div>,
        ],
      })
    );
  }, [shouldShow, dispatch, onMessageDismissed]);

  return null;
};

export default memo(TermsAndConditionsUpdateMessage);
