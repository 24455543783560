import { disableSentry } from "./sentry";

/**
 * Cleans up and reloads the page.
 */
function reload() {
  // Browser is reloading, but in-flight requests can cause spammy errors in the lame-duck application:
  // don't send these to Sentry.
  disableSentry();
  window.location.reload();
}

export default reload;
