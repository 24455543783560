import { FC } from "react";
import DropdownButton, {
  DropdownItemContentProps,
} from "../../../_common/components/DropdownButton";

interface ManageRiskButtonProps {
  disable?: boolean;
  dropdownPopup?: React.ReactNode;
  multiRiskText?: boolean;
  autoCloseOnMouseLeave?: boolean;

  hideRemediate?: boolean;
  hideAdjust?: boolean;
  hideWaive?: boolean;
  hideMarkAsRemediated?: boolean;

  onRemediate?: () => void;
  onAdjust?: () => void;
  onWaive?: () => void;
  onMarkAsRemediated?: () => void;
}

const ManageRiskButton: FC<ManageRiskButtonProps> = ({
  disable,
  dropdownPopup,
  multiRiskText,
  autoCloseOnMouseLeave,
  hideRemediate,
  hideAdjust,
  hideWaive,
  hideMarkAsRemediated,
  onRemediate,
  onAdjust,
  onWaive,
  onMarkAsRemediated,
}) => {
  if (
    (hideRemediate || !onRemediate) &&
    (hideAdjust || !onAdjust) &&
    (hideWaive || !onWaive) &&
    (hideMarkAsRemediated || !onMarkAsRemediated) &&
    !disable
  ) {
    return <></>;
  }

  const items: DropdownItemContentProps[] = [
    {
      headerText: "Request remediation",
      description: multiRiskText ? "Address risks" : "Address this risk",
      hide: hideRemediate,
      onClick: onRemediate,
      className: "manage-risk-button-dropdown-item-content",
    },
    {
      headerText: multiRiskText ? "Adjust a risk" : "Adjust this risk",
      description: multiRiskText
        ? "Modify severity of a risk"
        : "Modify the risk severity",
      hide: hideAdjust,
      onClick: onAdjust,
      className: "manage-risk-button-dropdown-item-content",
    },
    {
      headerText: multiRiskText ? "Waive a risk" : "Waive this risk",
      description: multiRiskText ? "Dismiss a risk" : "Dismiss this risk",
      hide: hideWaive,
      onClick: onWaive,
      className: "manage-risk-button-dropdown-item-content",
    },
    {
      headerText: "Mark as remediated",
      description: "Confirm this risk is resolved",
      hide: hideMarkAsRemediated,
      onClick: onMarkAsRemediated,
      className: "manage-risk-button-dropdown-item-content",
    },
  ];

  return (
    <DropdownButton
      title={multiRiskText ? "Manage risks" : "Manage risk"}
      dropdownPopup={dropdownPopup}
      disable={disable}
      autoCloseOnMouseLeave={autoCloseOnMouseLeave}
      classname={"manage-risk-button"}
      items={items}
    ></DropdownButton>
  );
};

export default ManageRiskButton;
