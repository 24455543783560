import { get as _get } from "lodash";
import { FetchCyberRiskUrl } from "../../_common/api";
import { LogError } from "../../_common/helpers";
import {
  setAutomationTestResults,
  setTestSubmissionsList,
} from "./cyberRiskActions";
import {
  DefaultAction,
  ITestResult,
  ITestSubmissionDetails,
} from "../../_common/types/redux";
import { IAutomationRecipe } from "../types/automation";

interface fetchSubmittedSurveysForSurveyTypeResp {
  status: string;
  surveyTypeId: number;
  surveys: ITestSubmissionDetails[];
}

export const fetchSubmittedSurveysForSurveyType = (
  surveyTypeId: number,
  force = false
): DefaultAction => {
  return async (dispatch, getState) => {
    if (!force) {
      const existing = _get(
        getState().cyberRisk,
        `automationTestSubmissions[${surveyTypeId}]`
      );
      if (existing && !existing.error) {
        return existing;
      }
    }

    dispatch(setTestSubmissionsList(surveyTypeId, true, null));
    let json: fetchSubmittedSurveysForSurveyTypeResp;
    try {
      json = await FetchCyberRiskUrl(
        "survey/automation/test_submissions/v1/",
        {
          survey_type_id: surveyTypeId,
        },
        null,
        dispatch,
        getState
      );
    } catch (e) {
      LogError(`Error fetching automation list for questionnaire type`, e);
      const error: IError = {
        errorText: `Error fetching automation list: ${e}`,
        actionText: "Try again",
        actionOnClick: () =>
          dispatch(fetchSubmittedSurveysForSurveyType(surveyTypeId, true)),
      };
      dispatch(setTestSubmissionsList(surveyTypeId, false, error, null));
      return;
    }

    if (!json) {
      LogError(
        `Empty response fetching automation list for questionnaire type`
      );
      const error: IError = {
        errorText: `Error fetching automation list: empty response from server`,
        actionText: "Try again",
        actionOnClick: () =>
          dispatch(fetchSubmittedSurveysForSurveyType(surveyTypeId, true)),
      };
      dispatch(setTestSubmissionsList(surveyTypeId, false, error, null));
      return;
    }
    dispatch(setTestSubmissionsList(surveyTypeId, false, null, json.surveys));
  };
};

interface executeAutomationOnSurveyInstanceResp {
  status: string;
  result: string;
  mappedResults: ITestResult[];
  appliedResults: {
    [attr: string]: ITestResult;
  };
}

export const executeAutomationOnSurveyInstance = (
  recipe: IAutomationRecipe | undefined,
  surveyTypeId: number,
  surveyId: number,
  surveyAnswers: string,
  draftVersions: boolean,
  allRecipes = false
): DefaultAction => {
  return async (dispatch, getState) => {
    dispatch(
      setAutomationTestResults(surveyTypeId, surveyId, true, null, null, null)
    );
    let json: executeAutomationOnSurveyInstanceResp;
    try {
      json = await FetchCyberRiskUrl(
        "survey/automation/execute/v1/",
        {
          recipe_uuid: recipe ? recipe.uuid : "",
        },
        {
          method: "POST",
          body: JSON.stringify({
            recipe: recipe,
            answers: surveyAnswers,
            surveyTypeID: surveyTypeId,
            allRecipes: allRecipes,
            draftVersions: draftVersions,
          }),
        },
        dispatch,
        getState
      );
    } catch (e) {
      LogError(`Error executing automation instance`, e);
      const error: IError = {
        errorText: `Error executing automation: ${e}`,
        actionText: "Try again",
        actionOnClick: () => {
          dispatch(
            executeAutomationOnSurveyInstance(
              recipe,
              surveyTypeId,
              surveyId,
              surveyAnswers,
              draftVersions,
              allRecipes
            )
          );
        },
      };
      dispatch(
        setAutomationTestResults(
          surveyTypeId,
          surveyId,
          false,
          error,
          null,
          null
        )
      );
      return;
    }

    if (!json) {
      LogError(
        `Empty response fetching automation list for questionnaire type`
      );
      const error: IError = {
        errorText: `Error fetching automation list: empty response from server`,
        actionText: "Try again",
        actionOnClick: () =>
          dispatch(
            executeAutomationOnSurveyInstance(
              recipe,
              surveyTypeId,
              surveyId,
              surveyAnswers,
              draftVersions,
              allRecipes
            )
          ),
      };
      dispatch(
        setAutomationTestResults(
          surveyTypeId,
          surveyId,
          false,
          error,
          null,
          null
        )
      );
      return;
    }
    dispatch(
      setAutomationTestResults(
        surveyTypeId,
        surveyId,
        false,
        null,
        json.mappedResults,
        json.appliedResults
      )
    );
  };
};
