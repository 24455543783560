import Search from "../images/search.svg";
import DomainSearch from "../images/search-app.svg";
import IconError from "../images/search-app-error.svg";
import Button from "./core/Button";

import "../style/components/SearchEmptyCard.scss";
import { IVendorWords } from "../constants";
import { validateUrl } from "../helpers";
import {
  fetchCustomerLimitData,
  postAddVendorRequestAndGetVendorId,
} from "../../vendorrisk/reducers/cyberRiskActions";
import { refreshVendorRiskMatrix } from "../../vendorrisk/reducers/reportsActions";
import { useState } from "react";
import { DefaultThunkDispatch } from "../types/redux";
import { IWatchlistPaging } from "../types/vendor";
import { TimerProgressBar } from "./ProgressBar";

interface ISearchEmptyCardWithDomainScanProps {
  dispatch: DefaultThunkDispatch;
  searchString: string;
  onScanStartStop: (scanning: boolean) => void;
  vendorWords: IVendorWords;
  fetchVendors: (opts?: {
    namePrefix?: string;
    pageOpts?: Partial<IWatchlistPaging>;
  }) => void;
  onClear?: () => void;
  pageOpts: Partial<IWatchlistPaging>;
}

/* Displays an empty search card with a button to trigger a clear callback or a domain search. */
const SearchEmptyCardWithDomainScan = (
  props: ISearchEmptyCardWithDomainScanProps
) => {
  const [isVendorScanning, setIsVendorScanning] = useState(false);
  const [vendorScanError, setVendorScanError] = useState("");

  const onAddByUrl = async (vendorUrl: string) => {
    if (isVendorScanning) {
      return;
    }

    vendorUrl = props.searchString.trim().toLowerCase();

    if (!validateUrl(vendorUrl)) {
      setVendorScanError("Invalid URL entered");
      setIsVendorScanning(false);
      props.onScanStartStop(false);
      return;
    }

    setVendorScanError("");
    setIsVendorScanning(true);

    props
      .dispatch(postAddVendorRequestAndGetVendorId(vendorUrl))
      .then((vendorId: number) => {
        props.onScanStartStop(false);
        if (!vendorId) {
          setVendorScanError("Failed to finding vendor from URL");
          setIsVendorScanning(false);
        } else {
          setIsVendorScanning(false);

          // Update limit data and risk matrix
          props.dispatch(fetchCustomerLimitData());
          props.dispatch(refreshVendorRiskMatrix());

          // On success, try to search for vendors again..
          props.fetchVendors({
            namePrefix: vendorUrl,
            pageOpts: { ...props.pageOpts },
          });
        }
      })
      .catch(() => {
        setVendorScanError("Error finding vendor from URL");
        setIsVendorScanning(false);
        props.onScanStartStop(false);
      });
  };

  const cancelSearch = () => {
    setIsVendorScanning(false);
    props.onScanStartStop(false);
  };

  let searchText = `No ${props.vendorWords.plural} found`;
  if (isVendorScanning) {
    searchText = `Attempting to scan ${props.searchString}`;
  } else if (!isVendorScanning && vendorScanError) {
    searchText = `We couldn't find a ${props.vendorWords.singular} from "${props.searchString}"`;
  }
  const subText = `Try searching or scanning by URL to identify the ${props.vendorWords.singular}.`;

  return (
    <div className="search-empty-card">
      {!!vendorScanError && !isVendorScanning && (
        <img src={IconError} alt="search" className={"search-empty-icon"} />
      )}
      {!vendorScanError && !isVendorScanning && (
        <img src={Search} alt="search" className={"search-empty-icon"} />
      )}
      {!vendorScanError && !!isVendorScanning && (
        <img src={DomainSearch} alt="search" className={"search-empty-icon"} />
      )}
      <div className="empty-search-text">{searchText}</div>
      {isVendorScanning && (
        <div className={"progress-bar-container"}>
          <div
            className={"msg"}
          >{`UpGuard is scanning this domain and searching for the associated ${props.vendorWords.singular}. This should only take a minute.`}</div>
          <TimerProgressBar
            isRunning={true}
            randomiseProgressPerTick
            shouldCompleteOnStop
            shouldRestartAfterCompletion
          />
          <Button onClick={() => cancelSearch()}>{"Cancel"}</Button>
        </div>
      )}
      {!isVendorScanning && vendorScanError && (
        <div
          className={"scan-error"}
        >{`It doesn’t look like this is an active domain. Try checking the domain and trying again.`}</div>
      )}
      {!isVendorScanning && (
        <>
          {!vendorScanError && (
            <div className="empty-search-subtext">{subText}</div>
          )}
          {props.onClear && (
            <Button
              disabled={isVendorScanning}
              onClick={() => {
                props.onClear && props.onClear();
              }}
            >
              {"Clear search"}
            </Button>
          )}
          <Button
            className={"scan-button"}
            filledPrimary
            loading={isVendorScanning}
            onClick={() => {
              props.onScanStartStop(true);
              onAddByUrl(props.searchString);
            }}
          >
            {`+ Attempt to scan "${props.searchString}"`}
          </Button>
        </>
      )}
    </div>
  );
};

SearchEmptyCardWithDomainScan.defaultProps = {
  searchItemText: "items",
  clearText: "Clear search",
  hideSearch: false,
};

export default SearchEmptyCardWithDomainScan;
