import { FC } from "react";
import "../../style/components/automation/NameAutomationStep.scss";
import LoadingBanner from "../../../_common/components/core/LoadingBanner";
import ReportCard from "../../../_common/components/ReportCard";
import ColorCheckbox from "../ColorCheckbox";
import DropdownV2, {
  DropdownItem,
} from "../../../_common/components/core/DropdownV2";
import Button from "../../../_common/components/core/Button";
import classnames from "classnames";
import {
  AutomationAttributeTypes,
  DataEntryErrorAttribute,
  EditQuestionnairePages,
} from "../../views/EditQuestionnaireAutomation";
import {
  VendorAttributeDefinition,
  VendorAttributeDefinitionType,
} from "../../reducers/vendorAttributes.actions";
import TextField, {
  MaxLengthType,
} from "../../../_common/components/TextField";

interface INameAutomationStepProps {
  loading: boolean;
  isEditing: boolean;
  title: string;
  description: string;
  isAdvancedRecipe: boolean;
  attributeType: string;
  customAttributeName: string;
  vendorAttributeDefinitions?: VendorAttributeDefinition[];
  setAttributeTypeFunc(name: string): void;
  setTitleFunc(name: string): void;
  setDescriptionFunc(name: string): void;
  setAdvancedRecipe(advanced: boolean): void;
  setCustomAttributeFunc(id: number, name: string): void;
  setUsingSimpleRulesFunc(using: boolean): void;
  setDataEntryErrorFunc: (
    page: number,
    id: string,
    attribute: DataEntryErrorAttribute,
    msg: string
  ) => void;
  deleteDataEntryErrorFunc: (
    page: number,
    id: string,
    attribute: DataEntryErrorAttribute
  ) => void;
  retrieveDataEntryErrorFunc: (
    page: number,
    id: string,
    attribute: DataEntryErrorAttribute
  ) => string | undefined;
  userHasWriteAutomationEnabled: boolean;
}

const NameAutomationStep: FC<INameAutomationStepProps> = ({
  loading,
  isEditing,
  title,
  description,
  isAdvancedRecipe,
  attributeType,
  customAttributeName,
  setTitleFunc,
  setDescriptionFunc,
  setAdvancedRecipe,
  setAttributeTypeFunc,
  setCustomAttributeFunc,
  setUsingSimpleRulesFunc,
  retrieveDataEntryErrorFunc,
  vendorAttributeDefinitions,
  userHasWriteAutomationEnabled,
}) => {
  if (loading) {
    return <LoadingBanner />;
  }

  const isAttribute = (a: string): boolean => {
    return attributeType == a;
  };

  const titleError =
    retrieveDataEntryErrorFunc(
      EditQuestionnairePages.EditAutomationName,
      "title",
      DataEntryErrorAttribute.None
    ) ?? "";

  const filteredVendorAttributes = vendorAttributeDefinitions?.filter(
    (d) => d.type != VendorAttributeDefinitionType.Date
  );

  const noCustomAttributes =
    !filteredVendorAttributes || filteredVendorAttributes.length == 0;
  !filteredVendorAttributes || filteredVendorAttributes.length == 0;

  return (
    <div className="name-automation-step">
      <ReportCard newStyles className="report-card">
        <div className={"header"}>
          <div className={"header-title"}>Define automation rule</div>
        </div>
        <div className={"body"}>
          <div className={"field"}>
            <div className="field-desc">Title</div>
            <div className="field-input">
              <TextField
                key={"title"}
                value={title}
                onChanged={(val) => setTitleFunc(val)}
                readonly={!isEditing || !userHasWriteAutomationEnabled}
                placeholder="e.g. Automatically assign a vendor label based on data center"
                errorTexts={!!titleError ? [titleError] : undefined}
              />
            </div>
          </div>
          <div className={"field"}>
            <div className="field-desc">Description</div>
            <div className="field-input">
              <TextField
                key={"description"}
                value={
                  isEditing
                    ? description
                    : !!description
                      ? description
                      : "(not set)"
                }
                maxLength={MaxLengthType.longerReasonableLength}
                onChanged={(val) => setDescriptionFunc(val)}
                readonly={!isEditing || !userHasWriteAutomationEnabled}
                placeholder={
                  "Provide an optional description about this automation"
                }
                multiLine={true}
              />
            </div>
          </div>
          <div className={"field"}>
            <div className="field-desc">
              Select an action the automation will perform
            </div>
            <div
              className={classnames("field-input bordered", {
                selected: isAttribute(AutomationAttributeTypes.Tier),
                clickable: userHasWriteAutomationEnabled && isEditing,
              })}
              onClick={
                userHasWriteAutomationEnabled && isEditing
                  ? () => {
                      setAttributeTypeFunc(AutomationAttributeTypes.Tier);
                      setCustomAttributeFunc(0, "");
                    }
                  : undefined
              }
            >
              <div className={"left"}>
                <ColorCheckbox
                  label={"Assign a tier to vendor"}
                  radio
                  disabled={!isEditing || !userHasWriteAutomationEnabled}
                  checked={isAttribute(AutomationAttributeTypes.Tier)}
                  onClick={() => {
                    setAttributeTypeFunc(AutomationAttributeTypes.Tier);
                    setCustomAttributeFunc(0, "");
                  }}
                />
              </div>
              <div className={"right"}>
                <ColorCheckbox
                  disabled={
                    !isEditing ||
                    !userHasWriteAutomationEnabled ||
                    !isAttribute(AutomationAttributeTypes.Tier)
                  }
                  label={"Use advanced method"}
                  checked={isAdvancedRecipe}
                  onClick={() => {
                    setAdvancedRecipe(!isAdvancedRecipe);
                    setUsingSimpleRulesFunc(isAdvancedRecipe);
                  }}
                />
              </div>
            </div>
            <div
              className={classnames("field-input bordered", {
                selected: isAttribute(AutomationAttributeTypes.Labels),
                clickable: isEditing && userHasWriteAutomationEnabled,
              })}
              onClick={
                isEditing && userHasWriteAutomationEnabled
                  ? () => {
                      setAttributeTypeFunc(AutomationAttributeTypes.Labels);
                      setCustomAttributeFunc(0, "");
                      setAdvancedRecipe(false);
                      setUsingSimpleRulesFunc(true);
                    }
                  : undefined
              }
            >
              <ColorCheckbox
                label={"Assign a label to vendor"}
                disabled={!isEditing || !userHasWriteAutomationEnabled}
                radio
                checked={isAttribute(AutomationAttributeTypes.Labels)}
                onClick={() => {
                  setAttributeTypeFunc(AutomationAttributeTypes.Labels);
                  setCustomAttributeFunc(0, "");
                  setAdvancedRecipe(false);
                  setUsingSimpleRulesFunc(true);
                }}
              />
            </div>
            <div
              className={classnames("field-input bordered", {
                selected: isAttribute(AutomationAttributeTypes.Portfolios),
                clickable: userHasWriteAutomationEnabled,
              })}
              onClick={
                isEditing && userHasWriteAutomationEnabled
                  ? () => {
                      setAttributeTypeFunc(AutomationAttributeTypes.Portfolios);
                      setCustomAttributeFunc(0, "");
                      setAdvancedRecipe(false);
                      setUsingSimpleRulesFunc(true);
                    }
                  : undefined
              }
            >
              <ColorCheckbox
                label={"Assign a portfolio to vendor"}
                radio
                disabled={!isEditing || !userHasWriteAutomationEnabled}
                checked={isAttribute(AutomationAttributeTypes.Portfolios)}
                onClick={() => {
                  setAttributeTypeFunc(AutomationAttributeTypes.Portfolios);
                  setCustomAttributeFunc(0, "");
                  setAdvancedRecipe(false);
                  setUsingSimpleRulesFunc(true);
                }}
              />
            </div>
            <div
              className={classnames("field-input bordered", {
                selected: isAttribute(AutomationAttributeTypes.Custom),
                clickable:
                  userHasWriteAutomationEnabled &&
                  isEditing &&
                  !noCustomAttributes,
              })}
              onClick={
                isEditing &&
                userHasWriteAutomationEnabled &&
                !noCustomAttributes
                  ? () => {
                      setAttributeTypeFunc(AutomationAttributeTypes.Custom);
                      setAdvancedRecipe(false);
                      setUsingSimpleRulesFunc(true);
                    }
                  : undefined
              }
            >
              <div className={"left"}>
                <ColorCheckbox
                  label={"Assign a custom attribute to vendor"}
                  radio
                  disabled={
                    noCustomAttributes ||
                    !isEditing ||
                    !userHasWriteAutomationEnabled
                  }
                  checked={isAttribute(AutomationAttributeTypes.Custom)}
                  onClick={
                    !noCustomAttributes
                      ? () => {
                          setAttributeTypeFunc(AutomationAttributeTypes.Custom);
                          setAdvancedRecipe(false);
                          setUsingSimpleRulesFunc(true);
                        }
                      : undefined
                  }
                />
              </div>
              <div className={"right"}>
                {!noCustomAttributes && (
                  <DropdownV2
                    disabled={
                      !isEditing ||
                      !userHasWriteAutomationEnabled ||
                      !isAttribute(AutomationAttributeTypes.Custom)
                    }
                    popupItem={
                      <Button
                        disabled={
                          !isEditing ||
                          !isAttribute(AutomationAttributeTypes.Custom)
                        }
                      >
                        {customAttributeName != ""
                          ? customAttributeName
                          : "Select custom attribute"}
                        <div className="cr-icon-chevron" />
                      </Button>
                    }
                  >
                    {filteredVendorAttributes?.map((a) => {
                      return (
                        <DropdownItem
                          key={a.id}
                          onClick={() => {
                            setCustomAttributeFunc(a.id, a.name);
                          }}
                        >
                          {a.name}
                        </DropdownItem>
                      );
                    })}
                  </DropdownV2>
                )}
                {noCustomAttributes && (
                  <div className={"cta"}>
                    Your account has no custom vendor attributes defined
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </ReportCard>
    </div>
  );
};

export default NameAutomationStep;
