import { getAllNodesMatchingTypesResult } from "../helpers";
import { NodeTypeIconType } from "../components/NodeTypeIcon";

export enum Operator {
  And = "&&",
  Or = "||",
}

export const getOperatorLabel = (op: Operator): string => {
  switch (op) {
    case Operator.And:
      return "AND";
    case Operator.Or:
      return "OR";
  }
};

export enum Comparator {
  Equals = "=",
  NotEquals = "!=",
  Visible = "^",
  NotVisible = "!^",
}

export const getComparatorLabel = (op: Comparator): string => {
  switch (op) {
    case Comparator.Equals:
      return "IS";
    case Comparator.NotEquals:
      return "IS NOT";
    case Comparator.Visible:
      return "IS VISIBLE";
    case Comparator.NotVisible:
      return "IS NOT VISIBLE";
  }
};

export interface SingleAnswerComparison {
  nodeId: string;
  comparator: Comparator;
  answerId: string;
}

// Expression represents a parenthetical part of a logical expression.
// These can be nested within each other.
// For example, to express the condition "q1 == a1 || (q2 == a2 && q3 != a3 && ^q4)", you
// could build an Expression that looks like:
//
// const expr: Expression = [
//   {
//     nodeId: "q1",
//     comparator: Comparator.Equals,
//     answerId: "a1",
//   },
//   Operator.Or,
//   [
//     {
//       nodeId: "q2",
//       comparator: Comparator.Equals,
//       answerId: "a1",
//     },
//     Operator.And,
//     {
//       nodeId: "q3",
//       comparator: Comparator.NotEquals,
//       answerId: "a3",
//     },
//     Operator.And,
//     {
//       nodeId: "q4",
//       comparator: Comparator.Visible,
//       answerId: "",
//     },
//   ],
// ];

export type Expression = SingleAnswerComparison | Operator | Expression[];

// Checks if an expression is valid, ie. that all fields have been filled out.
export const conditionalExpressionIsValid = (
  expr: Expression[] | undefined,
  allSelectQuestions: getAllNodesMatchingTypesResult
): boolean => {
  // No expression is always valid
  if (!expr) {
    return true;
  }

  // Check each expression to ensure it's filled out
  for (let i = 0; i < expr.length; i++) {
    if (Array.isArray(expr[i])) {
      const isValid = conditionalExpressionIsValid(
        expr[i] as Expression[],
        allSelectQuestions
      );
      if (!isValid) {
        return false;
      }
    } else if (typeof expr[i] === "object") {
      const thisExpr = expr[i] as SingleAnswerComparison;

      // Node ID must be specified, and it must exist before this question in the tree
      if (!thisExpr.nodeId || !allSelectQuestions.asMap[thisExpr.nodeId]) {
        return false;
      }

      if (
        thisExpr.comparator === Comparator.Equals ||
        thisExpr.comparator === Comparator.NotEquals
      ) {
        // The answerID must be a valid answer of the specified node ID.
        if (!thisExpr.answerId) {
          return false;
        }

        const referencedNode = allSelectQuestions.asMap[thisExpr.nodeId];
        if (
          referencedNode?.nodeType !== NodeTypeIconType.SelectCheckbox &&
          referencedNode?.nodeType !== NodeTypeIconType.SelectRadio
        ) {
          return false;
        }

        const nodeAnswers = referencedNode.answers || [];
        let foundAnswerId = false;
        for (let j = 0; j < nodeAnswers.length; j++) {
          if (nodeAnswers[j].id === thisExpr.answerId) {
            foundAnswerId = true;
            break;
          }
        }

        if (!foundAnswerId) {
          return false;
        }
      }
    }
  }

  return true;
};

// Takes an expression and extracts all the question identifiers (nodeIds) that are referenced in the expression.
export const extractQuestionIdentifiers = (
  expr: Expression[] | undefined,
  identifiers: string[]
): string[] => {
  // No expression is always valid
  if (!expr) {
    return identifiers;
  }

  // Check each expression to ensure it's filled out
  for (let i = 0; i < expr.length; i++) {
    if (Array.isArray(expr[i])) {
      identifiers = extractQuestionIdentifiers(
        expr[i] as Expression[],
        identifiers
      );
    } else if (typeof expr[i] === "object") {
      const thisExpr = expr[i] as SingleAnswerComparison;
      if (thisExpr.nodeId) {
        identifiers.push(thisExpr.nodeId);
      }
    }
  }
  return identifiers;
};

// Returns the number of conditions in an expression, checks recursively.
export const numConditionsInExpression = (expr?: Expression[]): number => {
  if (!expr) {
    return 0;
  }

  let total = 0;

  for (let i = 0; i < expr.length; i++) {
    if (Array.isArray(expr[i])) {
      total += numConditionsInExpression(expr[i] as Expression[]);
    } else if (typeof expr[i] === "object") {
      total += 1;
    }
  }

  return total;
};
