import { FC } from "react";
import userbaseApi from "../../api/userbase.api";
import ListFilter from "./ListFilter";
import {
  OrgAccessUserBase,
  UserUserBaseEnabled,
} from "../../../_common/permissions";
import withRequireEntitlementAndPermission from "../../../_common/hocs/WithRequireEntitlementAndPermission";

interface UserRisksTeamsFilterProps {
  selectedTeams: string[];
  excluded: boolean;
  onChange: (selectedTeams: string[], exclude: boolean) => void;
  title?: string;
}

const UserRisksTeamsFilter: FC<UserRisksTeamsFilterProps> = ({
  selectedTeams,
  excluded,
  onChange,
  title = "Teams",
}) => {
  const { data, isLoading } = userbaseApi.useGetUserBaseTeamsV1Query();

  return (
    <ListFilter
      title={title}
      isLoading={isLoading}
      options={
        data?.teams.map((team) => {
          return {
            value: team.name.toLowerCase(),
            label: team.name,
          };
        }) ?? []
      }
      selectedValues={selectedTeams}
      excluded={excluded}
      onChange={(selectedValues, exclude) => onChange(selectedValues, exclude)}
      useColoredPills={true}
    />
  );
};

export default withRequireEntitlementAndPermission(
  UserRisksTeamsFilter,
  OrgAccessUserBase,
  UserUserBaseEnabled
);
