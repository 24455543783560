import {
  FC,
  Fragment,
  memo,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import ContentLibraryAPI from "../api/contentLibraryAPI";
import {
  ContentLibraryDocument,
  GetDocumentsSortByCol,
} from "../types/contentLibrary.types";
import "../styles/ContentLibrarySuggestedDocuments.scss";
import { GetIconForFilename } from "../../vendorrisk/helpers/icons";
import Button from "../../_common/components/core/Button";
import moment from "moment/moment";
import IconButton, { HoverLocation } from "../../_common/components/IconButton";
import { useAppDispatch } from "../../_common/types/reduxHooks";
import { downloadContentLibraryDocumentFile } from "../api/downloadDocument";

interface IContentLibrarySuggestedDocumentsProps {
  addedDocumentUUIDs: string[];
  onAddDocument: (doc: ContentLibraryDocument) => void;
  searchQuery: string;
  onlyFetchWhenVisible: boolean;
}

// Matches validation on the contentlibrary/list/v1 endpoint
const searchQueryMinLen = 3;
const searchQueryMaxLen = 2000;

const ContentLibrarySuggestedDocuments: FC<
  IContentLibrarySuggestedDocumentsProps
> = ({
  addedDocumentUUIDs,
  onAddDocument,
  searchQuery: originalSearchQuery,
  onlyFetchWhenVisible,
}) => {
  const dispatch = useAppDispatch();
  const [seen, setSeen] = useState(false);
  const suggestedDocsRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (!onlyFetchWhenVisible || seen) {
      // No need to set up a visibility observer once it's been seen
      return;
    }

    const observer = new IntersectionObserver((entries) => {
      if (entries.length > 0 && entries[0].isIntersecting) {
        setSeen(true);
      }
    });
    observer.observe(suggestedDocsRef.current!);

    return () => observer.disconnect();
  }, [onlyFetchWhenVisible, seen]);

  const searchQuery = originalSearchQuery
    .trim()
    .substring(0, searchQueryMaxLen);

  // We only want to find suggestions when the component becomes visible in the viewport.
  const shouldFetchResults =
    searchQuery.length >= searchQueryMinLen && (!onlyFetchWhenVisible || seen);

  const { data } = ContentLibraryAPI.useGetContentLibraryDocumentsListQuery(
    {
      sortBy: {
        col: GetDocumentsSortByCol.Updated,
        desc: true,
      },
      filterBy: {
        searchQuery,
        documentTypes: [],
        archived: false,
        sharedProfileOnly: false,
      },
      limit: 3,
      offset: 0,
    },
    {
      skip: !shouldFetchResults,
    }
  );

  const filteredDocs: ContentLibraryDocument[] = useMemo(
    () =>
      data
        ? data.documents.filter((doc) => !addedDocumentUUIDs.includes(doc.uuid))
        : [],
    [data, addedDocumentUUIDs]
  );

  const viewInContentLibrary = (docUUID: string) =>
    window.open(`/contentlibrary/document/${docUUID}`, "_blank");

  return (
    <div className="content-library-suggested-documents" ref={suggestedDocsRef}>
      {filteredDocs.length > 0 ? (
        <>
          <h5>Suggestions</h5>
          <div className="doc-grid">
            {filteredDocs.map((doc) => (
              <Fragment key={doc.uuid}>
                <div className="doc-filetype">
                  <img
                    className="file-type"
                    alt="File type icon"
                    src={GetIconForFilename(doc.versions[0].filename)}
                  />
                </div>
                <div>
                  <div
                    className="doc-name"
                    onClick={() => viewInContentLibrary(doc.uuid)}
                  >
                    {doc.name}
                  </div>
                  <div className="doc-updated">
                    Last updated {moment(doc.updatedAt).format("ll")}
                  </div>
                </div>
                <div className="doc-icons">
                  <IconButton
                    icon={<div className="cr-icon-external-link" />}
                    onClick={() => viewInContentLibrary(doc.uuid)}
                    hoverText="View in Content Library"
                    hoverLocation={HoverLocation.Top}
                    hoverMicro
                  />
                  <IconButton
                    icon={<div className="cr-icon-export-thin" />}
                    onClick={() =>
                      dispatch(downloadContentLibraryDocumentFile(doc.uuid))
                    }
                    hoverText="Download"
                    hoverLocation={HoverLocation.Top}
                    hoverMicro
                  />
                  <Button onClick={() => onAddDocument(doc)}>
                    <div className="cr-icon-plus" /> Add
                  </Button>
                </div>
              </Fragment>
            ))}
          </div>
        </>
      ) : undefined}
    </div>
  );
};

export default memo(ContentLibrarySuggestedDocuments);
