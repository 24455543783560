import { Component } from "react";
import PropTypes from "prop-types";

import Button from "../../../_common/components/core/Button";
import { closeModal } from "../../../_common/reducers/commonActions";
import { addCustomerCloudscan } from "../../reducers/cyberRiskActions";
import { trackEvent } from "../../../_common/tracking";
import { isIPAddress } from "../../../_common/helpers";
import InfoBanner, { BannerType } from "../InfoBanner";
import {
  addDefaultWarningAlert,
  addSimpleErrorAlert,
} from "../../../_common/reducers/messageAlerts.actions";

export const AddCustomCloudscanModalName = "AddCustomCloudscanModalName";

class AddCustomCloudscanModal extends Component {
  static propTypes = {
    modalData: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
  };

  state = { loading: false, url: "" };

  onSubmit = (evt) => {
    evt.preventDefault();

    trackEvent("AddCustomCloudscan");

    this.setState({ loading: true });

    this.props
      .dispatch(
        addCustomerCloudscan(this.state.url, this.props.modalData.vendorId || 0)
      )
      .then(() => {
        this.props.dispatch(closeModal());
      })
      .catch((e) => {
        this.setState({ loading: false });
        if (typeof e === "string") {
          this.props.dispatch(addDefaultWarningAlert(e));
        } else {
          this.props.dispatch(
            addSimpleErrorAlert(`Error adding domain: ${e.message}`)
          );
        }
      });
  };

  render() {
    const cannotAddIPAddress = isIPAddress(this.state.url);
    return (
      <form onSubmit={this.onSubmit}>
        <div className="modal-header">
          <h2>Add a domain</h2>
        </div>
        <div className="modal-content">
          {this.props.modalData.vendorId ? (
            <p>If this vendor has domains not listed, add them here.</p>
          ) : (
            <p>If your company has domains not listed, add them here.</p>
          )}
          <input
            name="website"
            type="text"
            required
            placeholder="your-domain.com"
            onChange={(evt) => this.setState({ url: evt.target.value })}
            value={this.state.url}
          />
        </div>
        <div className="modal-footer">
          {cannotAddIPAddress ? (
            <div className="validation-container">
              <InfoBanner
                type={BannerType.WARNING}
                message={
                  <>
                    Go to&nbsp;
                    <a
                      onClick={() => {
                        if (this.props.modalData.vendorId) {
                          this.props.history.push(
                            `/vendor/${this.props.modalData.vendorId}/ips`
                          );
                        } else {
                          this.props.history.push("/ips");
                        }
                        this.props.dispatch(closeModal());
                      }}
                    >
                      IP Addresses
                    </a>
                    &nbsp;to add an IP address
                  </>
                }
              />
            </div>
          ) : (
            <>
              <Button
                id="cloudscan-cancel"
                onClick={() => this.props.dispatch(closeModal())}
                tertiary
              >
                Cancel
              </Button>
              <Button
                id="cloudscan-submit"
                type="submit"
                filledPrimary
                loading={this.state.loading}
                disabled={this.state.loading || cannotAddIPAddress}
              >
                Add
              </Button>
            </>
          )}
        </div>
      </form>
    );
  }
}

export default AddCustomCloudscanModal;
