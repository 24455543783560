import LoadingIcon from "./LoadingIcon";

import "../../style/components/core/LoadingBanner.scss";

interface LoadingBannerProps {
  tight?: boolean;
  size?: number;
}

/**
 * <LoadingBanner />
 * Simple full width, centered loading component.
 * @param tight - Optional - if true, removes top and bottom margin
 * @param size - Optional - size of icon. Defaults to 50
 */
const LoadingBanner = ({ tight, size = 50 }: LoadingBannerProps) => (
  <div className={tight ? "loading-tight" : "loading-large"}>
    <LoadingIcon size={size} />
  </div>
);

export default LoadingBanner;
