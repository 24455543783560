import { BannerType } from "../../vendorrisk/components/InfoBanner";
import { createUUID } from "../../survey_builder/helpers";
import { ReactNode } from "react";

export enum EMessageAlertPosition {
  Top,
  Bottom,
}

export interface IMessageAlert {
  // Unique id for the message alert. Defaults to a UUID.
  id?: string;

  // Message to display
  message: ReactNode;

  // Where the message is displayed (top-right or bottom-right). Default to bottom-right.
  position?: EMessageAlertPosition;

  // Type of message. Defaults to error.
  type?: BannerType;

  // Optional sub-messages to display in list format.
  subMessages?: ReactNode[];

  // Optional link text
  linkText?: string;

  // Optional url
  linkUrl?: string;

  // Optional hide delay (in ms)
  hideDelay?: number;

  // Optional callback when alert dismissed
  onDismiss?: () => void;

  // Do not dismiss when clearAllMessageAlerts is called
  noDismissOnClearAll?: boolean;
}

export const ADD_MESSAGE_ALERT = "ADD_MESSAGE_ALERT";
// Add a custom message alert
// Defaults to id: UUID, type: error and positioned at the bottom
export const addMessageAlert = (alert: IMessageAlert) => {
  if (!alert.id) {
    alert.id = createUUID();
  }

  if (!alert.type) {
    alert.type = BannerType.ERROR;
  }

  if (!alert.position) {
    alert.position = EMessageAlertPosition.Bottom;
  }

  return {
    type: ADD_MESSAGE_ALERT,
    alert: alert,
  };
};

export const CLEAR_MESSAGE_ALERT = "CLEAR_MESSAGE_ALERT";
export const clearMessageAlert = (alertId: string) => {
  return {
    type: CLEAR_MESSAGE_ALERT,
    alertId: alertId,
  };
};

export const CLEAR_ALL_MESSAGE_ALERTS = "CLEAR_ALL_MESSAGE_ALERTS";
export const clearAllMessageAlerts = () => {
  return {
    type: CLEAR_ALL_MESSAGE_ALERTS,
  };
};

// Simple error for general usage (validation fail etc)
// Auto-hides after 10secs
export const addSimpleErrorAlert = (
  errorText: string,
  subMessages: string[] = [],
  id?: string
) => {
  const alert: IMessageAlert = {
    id: id ?? createUUID(),
    message: errorText,
    type: BannerType.ERROR,
    position: EMessageAlertPosition.Bottom,
    subMessages: subMessages,
    hideDelay: 10000,
  };

  return {
    type: ADD_MESSAGE_ALERT,
    alert: alert,
  };
};

// Standard error block for unknown/unexpected
export const addDefaultUnknownErrorAlert = (
  errorText: string,
  extraSubMessages: string[] = []
) => {
  const alert: IMessageAlert = {
    id: createUUID(),
    message: errorText,
    subMessages: [
      "We're sorry, something went wrong. If the issue persists please contact support.",
      ...extraSubMessages,
    ],
    type: BannerType.ERROR,
    position: EMessageAlertPosition.Bottom,
  };

  return {
    type: ADD_MESSAGE_ALERT,
    alert: alert,
  };
};

// Simple success alert
export const addDefaultSuccessAlert = (
  successText: ReactNode,
  subMessages: ReactNode[] = [],
  hideDelay = 7000
) => {
  const alert: IMessageAlert = {
    id: createUUID(),
    message: successText,
    type: BannerType.SUCCESS,
    hideDelay,
    position: EMessageAlertPosition.Bottom,
    subMessages: subMessages,
  };

  return {
    type: ADD_MESSAGE_ALERT,
    alert: alert,
  };
};

export const addDefaultWarningAlert = (
  warningText: string,
  subMessages: string[] = [],
  hideDelay = 7000
) => {
  const alert: IMessageAlert = {
    id: createUUID(),
    message: warningText,
    type: BannerType.WARNING,
    hideDelay,
    position: EMessageAlertPosition.Bottom,
    subMessages: subMessages,
  };

  return {
    type: ADD_MESSAGE_ALERT,
    alert: alert,
  };
};
