export interface BreachDataClass {
  Class: string;
  Severity: number;
}

export enum BreachSource {
  HIBP = "hibp",
  HIBEX = "hibex",
}

export enum BreachNotificationStatus {
  EmailExposureNotificationError = "error",
  EmailExposureNotificationSending = "sending",
  EmailExposureNotificationSent = "sent",
}

export enum BreachType {
  Company = "Company",
  Infostealer = "Infostealer",
  Paste = "Paste",
}

export interface Breach {
  ID: number;
  Title: string;
  Name: string;
  Domain: string;
  BreachDate?: string;
  DataClasses?: BreachDataClass[];
  PwnCount: number;
  RiskScore: number;
  Description: string;
  IsBreach: boolean;
  Source: BreachSource;
  PasteURL?: string;
  PublishedDate: string;
  OrgCount: number;
  Archived: boolean;
  NotifiedAt?: string;
  NotificationStatus?: BreachNotificationStatus;
  VipCount: number;
  HIBEXBreachSourceType?: string;
  HIBEXReferenceURL?: string;
  AssigneeUserID?: number;
  NumComments: number;
  BreachType: BreachType;
}

export interface Account {
  id: number;
  breachIDs?: number[];
  breaches?: Breach[];
  dateLastBreach?: string;
  domain: string;
  highestBreachRisk: number;
  ignored: boolean;
  isVip: boolean;
  name: string;
  orgId: number;
  numBreaches: number;
  notificationIDs: number[];
  notifiedBreachIDs: number[];
  blocked: boolean;
  metadata?: AccountMetadata[];
}

export interface AccountMetadata {
  passwordExposed?: boolean;
  userAgent?: string;
  deviceSerialNumber?: string;
  webAddress?: string;
  dateRegistered?: string;
}

export interface AccountsInfo {
  totalNumber: number;
  totalNumberNotNotified: number;
  totalVip: number;
}

export type AccountPages = Record<number, Account[]>;

export type PagedAccounts = AccountsInfo & AccountPages;

export interface VIPAccount {
  id: number;
  orgId: number;
  name: string;
  domain: string;
  numBreaches: number;
  dateLastBreach?: string;
  breachIDs?: number[];
  highestBreachRisk?: number;
}

export enum HistoryItemType {
  Comment = "comment",
  NewAssignee = "new_assignee",
  VIPsTagged = "vips_tagged",
  AccountsNotified = "accounts_notified",
  AccountsIgnored = "accounts_ignored",
  ArchiveStatusChanged = "archive_status_changed",
}

export type HistoryItem = {
  id: number;
  organisationID: number;
  breachID: number;
  userID?: number;
  createdAt: string;
} & (
  | {
      itemType: HistoryItemType.Comment;
      itemMeta: {
        text: string;
      };
    }
  | {
      itemType: HistoryItemType.NewAssignee;
      itemMeta: {
        userID?: number;
      };
    }
  | {
      itemType: HistoryItemType.VIPsTagged;
      itemMeta: {
        vip: boolean;
        num: number;
      };
    }
  | {
      itemType: HistoryItemType.AccountsNotified;
      itemMeta: {
        num: number;
      };
    }
  | {
      itemType: HistoryItemType.AccountsIgnored;
      itemMeta: {
        ignored: boolean;
        num: number;
      };
    }
  | {
      itemType: HistoryItemType.ArchiveStatusChanged;
      itemMeta: {
        archived: boolean;
      };
    }
);
