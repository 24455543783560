import { Component } from "react";
import PropTypes from "prop-types";

import Button from "../../../_common/components/core/Button";
import { closeModal } from "../../../_common/reducers/commonActions";
import "../../style/components/BreachSightMessageModal.scss";
import TextareaAutosize from "react-autosize-textarea";
import { addSimpleErrorAlert } from "../../../_common/reducers/messageAlerts.actions";

export const BreachSightMessageModalName = "BreachSightMessageModalName";

class BreachSightMessageModal extends Component {
  static propTypes = {
    modalData: PropTypes.shape({
      onSubmitPromise: PropTypes.func.isRequired,
      newStatus: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    }).isRequired,
    dispatch: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      reasonMessage: "",
    };
  }

  onSubmit = (evt) => {
    evt.preventDefault();
    this.setState({
      loading: true,
    });

    this.props.modalData
      .onSubmitPromise(this.props.modalData.newStatus, this.state.reasonMessage)
      .then(() => {
        this.setState({
          loading: false,
        });

        this.props.dispatch(closeModal());
      })
      .catch((e) => {
        this.props.dispatch(addSimpleErrorAlert(e.message));
      });
  };

  render() {
    let titleText = this.props.modalData.title;
    let verifyText = "Please approve this status change.";
    let messageInputText = `Optionally, add a message about this change:`;
    return (
      <div className="modal-content breachsight-message-modal">
        <h3 className="center-text">{titleText}</h3>
        <p>{verifyText}</p>
        <form onSubmit={this.onSubmit}>
          <p>{messageInputText}</p>
          <TextareaAutosize
            //value={value}
            onChange={(evt) => {
              this.setState({ reasonMessage: evt.target.value });
            }}
            //placeholder={placeholder}
            //autoFocus={autofocus}
          />
          <Button
            className="apply-button"
            primary
            onClick={this.onSubmit}
            disabled={this.state.loading}
            loading={this.state.loading}
          >
            Apply
          </Button>
        </form>
      </div>
    );
  }
}

export default BreachSightMessageModal;
