import "../style/components/Footer.scss";
import { FC } from "react";
import { createPortal } from "react-dom";

const Footer: FC = () =>
  createPortal(
    <footer id="foot">
      <div id="footer-inner">
        <a
          target="_blank"
          rel="noreferrer noopener"
          href="https://www.upguard.com"
        >
          Home
        </a>
        <span>|</span>
        <a
          target="_blank"
          rel="noreferrer noopener"
          href="https://www.upguard.com/news"
        >
          News
        </a>
        <span>|</span>
        <a
          target="_blank"
          rel="noreferrer noopener"
          href="https://help.upguard.com/en/"
        >
          Support
        </a>
        <span>|</span>
        <a
          target="_blank"
          rel="noreferrer noopener"
          href="https://www.upguard.com/company/platform-terms-and-conditions"
        >
          Terms of Service
        </a>
        {/* <span>|</span>
      <a target="_blank" rel="noreferrer noopener" href="https://www.upguard.com/privacy">Privacy</a> */}
      </div>
    </footer>,
    document.body
  );

export default Footer;
