import {
  DisplayableManagedVendorAssessment,
  IManagedVendorPageArgs,
  IManagedVendorPageOpts,
  ManagedVendorLicenseUsed,
  ManagedVendorLicenseUsedWithOrg,
  ManagedVendorServiceStatusType,
} from "../../_common/types/thirdPartyMangedVendors";
import { DefaultAction } from "../../_common/types/redux";
import { isEqual as _isEqual } from "lodash";
import { FetchCyberRiskUrl } from "../../_common/api";
import { LogError } from "../../_common/helpers";
import { ManagedAssessmentDetailsState } from "../../vendorrisk/reducers/managedVendors.actions";
import { SortDirection } from "../../_common/components/core/XTable";
import { ManagedVendorFilterV2 } from "../components/filter/TprmsFilter.helpers";
import { getTPMVSession } from "../../_common/reducers/commonActions";

export const SET_MANAGED_SERVICE_FILTERS =
  "ANALYSTAPP_SET_MANAGED_SERVICE_FILTERS";
const setManagedServiceFilters = (filter: ManagedVendorFilterV2) => ({
  type: SET_MANAGED_SERVICE_FILTERS,
  filter,
});

export const MANAGEDVENDORREQUESTS_PAGESIZE = 50;

export const setFilterAndRefreshList =
  (filters: Partial<ManagedVendorFilterV2>): DefaultAction =>
  async (dispatch, getState) => {
    const state = getState().analystPortal.managedServiceRequests;
    const newFilters = { ...state.filter, ...filters };

    dispatch(setManagedServiceFilters(newFilters));
    const paging = state.paging
      ? {
          ...state.paging,
          // Reset the page number when the filter changes
          pageNum: 1,
        }
      : {
          sortBy: "requestedDate",
          sortDir: SortDirection.ASC,
          pageNum: 1,
          pageSize: MANAGEDVENDORREQUESTS_PAGESIZE,
        };

    dispatch(getAnalystManagedVendorsList(paging, newFilters, true));
  };

export const refreshManagedVendorsList =
  (): DefaultAction => async (dispatch, getState) => {
    const state = getState().analystPortal.managedServiceRequests;
    const filter = state.filter;

    const paging = state.paging ?? {
      sortBy: "requestedDate",
      sortDir: SortDirection.ASC,
      pageNum: 1,
      pageSize: MANAGEDVENDORREQUESTS_PAGESIZE,
    };

    return dispatch(getAnalystManagedVendorsList(paging, filter, true));
  };

export const SET_MANAGED_SERVICE_LIST = "ANALYSTAPP_SET_MANAGED_SERVICE_LIST";
const setManagedServiceList = (
  loading: boolean,
  requests?: DisplayableManagedVendorAssessment[],
  error?: IError,
  paging?: IManagedVendorPageOpts
) => ({
  type: SET_MANAGED_SERVICE_LIST,
  loading,
  requests,
  error,
  paging,
});

export const SET_MANAGED_SERVICE_CUSTOMER_NAMES =
  "ANALYSTAPP_SET_MANAGED_SERVICE_CUSTOMER_NAMES";
export const setManagedServiceCustomerNames = (names: string[]) => ({
  type: SET_MANAGED_SERVICE_CUSTOMER_NAMES,
  names,
});

interface getAnalystManagedVendorsListResp {
  assessments: DisplayableManagedVendorAssessment[];
  paging: IManagedVendorPageOpts;
  customerNames: string[];
  status: string;
}

export const getAnalystManagedVendorsList =
  (
    paging: IManagedVendorPageArgs,
    filter: ManagedVendorFilterV2,
    force = false
  ): DefaultAction =>
  async (dispatch, getState) => {
    // try to see what's currently in the cache
    const localFilter = { ...filter };
    const currentState = getState().analystPortal.managedServiceRequests;

    if (!force) {
      if (
        currentState &&
        currentState.paging &&
        currentState.filter &&
        currentState.paging.pageNum === paging.pageNum &&
        currentState.paging.pageSize === paging.pageSize &&
        currentState.paging.sortBy === paging.sortBy &&
        currentState.paging.sortDir === paging.sortDir &&
        currentState.filter.namePrefix === localFilter.namePrefix &&
        _isEqual(
          currentState.filter.includedServiceStatuses.sort(),
          localFilter.includedServiceStatuses.sort()
        ) &&
        _isEqual(currentState.filter.orgNames, localFilter.orgNames) &&
        _isEqual(
          currentState.filter.excludedServiceLevels,
          localFilter.excludedServiceLevels
        )
      ) {
        return;
      }
    }

    dispatch(setManagedServiceList(true));
    let json: getAnalystManagedVendorsListResp;
    try {
      json = await FetchCyberRiskUrl(
        "cyberresearch/managedvendors/v2",
        {
          page_num: paging.pageNum,
          page_size: paging.pageSize,
          sort_by: paging.sortBy,
          sort_dir: paging.sortDir,
          name_prefix:
            localFilter.namePrefix && localFilter.namePrefix.length >= 2
              ? localFilter.namePrefix
              : undefined,
          customer_names: localFilter.orgNames
            ? localFilter.orgNames.map((option) => {
                return option.value;
              })
            : undefined,
          excluded_service_levels: localFilter.excludedServiceLevels,
          included_service_statuses: localFilter.includedServiceStatuses,
        },
        null,
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error fetching analyst managed service list", e);

      dispatch(
        setManagedServiceList(false, undefined, {
          errorText: "Error fetching managed service list for analyst",
          actionText: "retry",
          actionOnClick: () =>
            dispatch(getAnalystManagedVendorsList(paging, filter, force)),
        })
      );

      throw e;
    }

    dispatch(
      setManagedServiceList(false, json.assessments, undefined, json.paging)
    );
    dispatch(setManagedServiceCustomerNames(json.customerNames));
  };

export const SET_SINGLE_REQUEST = "ANALYSTAPP_SET_SINGLE_REQUEST";
const setSingleRequest = (data: {
  [id: number]: Partial<ManagedAssessmentDetailsState>;
}) => ({
  type: SET_SINGLE_REQUEST,
  data,
});

export const SET_ORG_LICENSES = "ANALYSTAPP_SET_ORG_LICENSES";
const setOrgLicenses = (
  orgId: number,
  loading: boolean,
  licenses?: ManagedVendorLicenseUsed[]
) => ({
  type: SET_ORG_LICENSES,
  orgId,
  loading,
  licenses,
});

export const SET_ALL_LICENSES = "ANALYSTAPP_SET_ALL_LICENSES";
const setAllLicenses = (
  loading: boolean,
  licenses?: ManagedVendorLicenseUsedWithOrg[]
) => ({
  type: SET_ALL_LICENSES,
  loading,
  licenses,
});

export const analystGetSingleRequest =
  (request_id: number, force = false): DefaultAction =>
  async (dispatch, getState) => {
    const tpvmState = getTPMVSession(getState);
    if (!tpvmState.tpvm) {
      return;
    }
    if (
      !force &&
      request_id in getState().analystPortal.managedServiceRequests.requests &&
      getState().analystPortal.managedServiceRequests.requests[request_id]
        .details
    ) {
      return;
    }

    dispatch(setSingleRequest({ [request_id]: { detailsLoading: true } }));

    let json: { result: DisplayableManagedVendorAssessment };
    try {
      json = await FetchCyberRiskUrl(
        "cyberresearch/managedvendor/request/v2",
        { request_id },
        null,
        dispatch,
        getState
      );
    } catch (e) {
      LogError("error getting single request", e);
      throw e;
    }

    dispatch(
      setSingleRequest({
        [json.result.id]: { details: json.result, detailsLoading: false },
      })
    );
  };

export const analystDeleteManagedService =
  (request_id: number): DefaultAction =>
  async (dispatch, getState) => {
    try {
      await FetchCyberRiskUrl(
        "cyberresearch/managedvendor/v2",
        { request_id },
        { method: "DELETE" },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error deleting managed service as analyst", e);
      throw e;
    }

    dispatch(analystGetSingleRequest(request_id, true));
    dispatch(refreshManagedVendorsList());
  };

export const analystAssignAnalyst =
  (request_id: number): DefaultAction =>
  async (dispatch, getState) => {
    try {
      await FetchCyberRiskUrl(
        "cyberresearch/managedvendor/analyst/v2",
        { request_id },
        { method: "PUT" },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error updating analyst", e);
      throw e;
    }

    dispatch(analystGetSingleRequest(request_id, true));
  };

export const analystUpdateCompletedDate =
  (request_id: number, completed_date: string): DefaultAction =>
  async (dispatch, getState) => {
    try {
      await FetchCyberRiskUrl(
        "cyberresearch/managedvendor/complete/v2",
        { request_id, completed_date },
        { method: "PUT" },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error marking complete", e);
      throw e;
    }

    dispatch(analystGetSingleRequest(request_id, true));
  };

export const getLatestUnstartedRequestForVendor =
  (vendor_id: number): DefaultAction<number | undefined> =>
  async (dispatch, getState) => {
    let json: { result: number | null };
    try {
      json = await FetchCyberRiskUrl(
        "cyberresearch/managedvendor/requestforvendor/v2",
        { vendor_id },
        null,
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error getting latest unstarted request id for vendor", e);
      throw e;
    }

    return json.result ?? undefined;
  };

export const analystAssignVendorAssessmentToManagedAssessment =
  (vendor_assessment_id: number, request_id: number): DefaultAction =>
  async (dispatch, getState) => {
    try {
      await FetchCyberRiskUrl(
        "cyberresearch/managedvendor/assign/v2",
        { vendor_assessment_id, request_id },
        { method: "PUT" },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error assigning vendor assessment to managed assessment", e);
      throw e;
    }

    dispatch(analystGetSingleRequest(request_id, true));
  };

// attempts to find the latest managed vendor assessment for the given vendor and refreshes it if it can find it
export const refreshLatestVendorAssessmentForVendor =
  (vendorId: number): DefaultAction =>
  async (dispatch, getState) => {
    const assessments =
      getState().analystPortal.managedServiceRequests.requests;
    const vendorAssessment = Object.values(assessments).find(
      (s) =>
        s.details && s.details.vendorID == vendorId && !s.details.finishedAt
    );

    if (vendorAssessment?.details) {
      dispatch(analystGetSingleRequest(vendorAssessment.details.id, true));
    }
  };

export const analystGetAllLicensesForOrg =
  (orgId: number, force: boolean): DefaultAction<ManagedVendorLicenseUsed[]> =>
  async (dispatch, getState) => {
    const currentLicense = getState().analystPortal.licenses[orgId];
    if (currentLicense?.licenses && !force) {
      return currentLicense.licenses;
    }

    dispatch(setOrgLicenses(orgId, true));

    let json: { state: string; licenses: ManagedVendorLicenseUsed[] };
    try {
      json = await FetchCyberRiskUrl(
        "cyberresearch/managedvendor/license/v2",
        { org_id: orgId },
        null,
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error fetching org licenses as analyst", e);
      throw e;
    }

    dispatch(setOrgLicenses(orgId, false, json.licenses));
    return json.licenses;
  };

export const analystGetAllLicenses =
  (force = false): DefaultAction<ManagedVendorLicenseUsedWithOrg[]> =>
  async (dispatch, getState) => {
    const currentLicense = getState().analystPortal.allLicenses;
    if (
      currentLicense?.licenses &&
      currentLicense.licenses.length != 0 &&
      !force
    ) {
      return currentLicense.licenses;
    }

    dispatch(setAllLicenses(true));

    let json: { state: string; licenses: ManagedVendorLicenseUsedWithOrg[] };
    try {
      json = await FetchCyberRiskUrl(
        "cyberresearch/managedvendor/license/all/v2",
        null,
        null,
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error fetching all licenses as analyst", e);
      throw e;
    }

    dispatch(setAllLicenses(false, json.licenses));
    return json.licenses;
  };

interface UpdateManagedVendorAssessmentRequestBody {
  requestId: number;
  analystNote?: string;
  serviceStatus: ManagedVendorServiceStatusType;
}

export const updateAssessmentAnalystNoteAndServiceStatus =
  (
    details: DisplayableManagedVendorAssessment,
    serviceStatus?: ManagedVendorServiceStatusType,
    analystNote?: string
  ): DefaultAction =>
  async (dispatch, getState) => {
    const body: UpdateManagedVendorAssessmentRequestBody = {
      requestId: details.id,
      analystNote: analystNote ?? details.analystNote,
      serviceStatus: serviceStatus ?? details.serviceStatus,
    };

    try {
      await FetchCyberRiskUrl(
        "cyberresearch/managedvendor/update/v1",
        {},
        { method: "PUT", body: JSON.stringify(body) },
        dispatch,
        getState
      );
    } catch (e) {
      LogError(
        "Error updating managed assessment service status and/or analyst note",
        e
      );

      throw e;
    }

    const currentRequestState =
      getState().analystPortal.managedServiceRequests.requests[details.id];

    if (
      currentRequestState.details?.serviceStatus ===
        ManagedVendorServiceStatusType.Complete ||
      serviceStatus === ManagedVendorServiceStatusType.Complete
    ) {
      // If setting to/from complete, trigger a full reload
      dispatch(analystGetSingleRequest(details.id, true));
    } else {
      dispatch(
        setSingleRequest({
          [details.id]: {
            ...currentRequestState,
            details: {
              ...details,
              serviceStatus: serviceStatus ?? details.serviceStatus,
              analystNote: analystNote ?? details.analystNote,
            },
          },
        })
      );
    }
  };
