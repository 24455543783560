import DashboardStat, { DashboardStatToShow } from "./DashboardStat";
import { FC } from "react";
import { SingleStatSize } from "./SingleStat";
import "./TitleStat.scss";

interface TitleStatProps {
  statToShow: DashboardStatToShow;
}

const TitleStat: FC<TitleStatProps> = ({ statToShow }) => {
  let title = "";
  switch (statToShow) {
    case DashboardStatToShow.AppCount:
      title = "Applications";
      break;
    case DashboardStatToShow.UserCount:
      title = "Users";
      break;
    case DashboardStatToShow.PermissionCount:
      title = "Permissions";
      break;
  }

  return (
    <div className="title-and-stat">
      {title}
      <DashboardStat statToShow={statToShow} statSize={SingleStatSize.Small} />
    </div>
  );
};

export default TitleStat;
