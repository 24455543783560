import { FC, useEffect, useState } from "react";
import { DefaultThunkDispatchProp } from "../../../_common/types/redux";
import { fetchAllVendorRisks } from "../../reducers/cyberRiskActions";
import { SlidePanelSection } from "./SlidePanelSection";
import {
  OptionType,
  SelectV2Multi,
} from "../../../_common/components/SelectV2";
import LabelList from "../LabelList";
import { ValueType } from "react-select";
import { VendorSummaryRiskType } from "../../../_common/types/vendorSummary";

import { RiskMini } from "../../../_common/types/risks";
import { getRisksList } from "../../reducers/filters.actions";
import { appConnect } from "../../../_common/types/reduxHooks";

interface IRiskFilterOwnProps {
  selectedOptions: string[];
  onChange: (vals: string[]) => void;
  riskCategories?: string[];
  customerRisks?: boolean; // indicates should use customer risks rather than vendors
  vendorId?: number; // only show risks for this vendor instead of all vendor risks
  isSubsidiary?: boolean;
  scanningRisksOnly?: boolean;
  title?: string;
  startExpanded?: boolean;
}

interface IRiskFilterConnectedProps {
  loading: boolean;
  error?: IError;
  allVendorRisks?: RiskMini[];
}

type RiskFilterProps = IRiskFilterOwnProps &
  IRiskFilterConnectedProps &
  DefaultThunkDispatchProp;

interface RiskFilterOptionType extends OptionType {
  value: string;
  factCategory: string;
}

export const RiskFilter: FC<RiskFilterProps> = ({
  selectedOptions,
  onChange,
  loading,
  error,
  allVendorRisks,
  riskCategories = [],
  dispatch,
  scanningRisksOnly,
  title = "Filter by finding",
  startExpanded = false,
  customerRisks,
  vendorId,
  isSubsidiary,
}) => {
  const [expanded, setExpanded] = useState(
    startExpanded || selectedOptions.length > 0
  );

  useEffect(() => {
    if (!loading && !error && !allVendorRisks) {
      if (customerRisks || vendorId || isSubsidiary) {
        dispatch(getRisksList(false, vendorId, isSubsidiary));
      } else {
        dispatch(fetchAllVendorRisks());
      }
    }
  }, [loading, error, allVendorRisks, dispatch]);

  const selectOnChange = (selectedOptions: ValueType<OptionType, true>) => {
    onChange(
      selectedOptions ? selectedOptions.map(({ value }) => value as string) : []
    );
  };

  const options =
    allVendorRisks && allVendorRisks
      ? allVendorRisks
          .filter(
            (r) =>
              !r.passed &&
              (!scanningRisksOnly ||
                r.riskType == VendorSummaryRiskType.Cloudscan)
          )
          .map(({ id, title, factCategory }) => ({
            value: id,
            label: title,
            factCategory: factCategory,
          }))
      : [];

  const selectValue = selectedOptions
    .map(
      (id) => options.find(({ value }) => value === id) as RiskFilterOptionType
    )
    .filter((value) => !!value);

  const displayLabels = selectValue.map(({ value, label }) => ({
    id: value,
    name: label,
    color: "blue",
    removeable: true,
    large: true,
    constrained: true,
    onRemoveClick: () =>
      selectOnChange(selectValue.filter((option) => value !== option.value)),
  }));

  return (
    <SlidePanelSection
      title={title}
      expanded={expanded}
      toggleExpand={() => setExpanded(!expanded)}
    >
      <SelectV2Multi
        placeholder="Type to search findings"
        options={options}
        value={selectValue}
        onChange={selectOnChange}
        isLoading={loading}
        controlShouldRenderValue={false}
        filterOption={(opt, filter) =>
          (riskCategories.length === 0 ||
            riskCategories.includes(opt.data.factCategory)) &&
          opt.label.toLowerCase().includes(filter.toLowerCase())
        }
        // need to force a re-render of the underlying select component to update the memorize cache of filters
        key={riskCategories.length}
      />
      <LabelList labels={displayLabels} />
    </SlidePanelSection>
  );
};

export const RiskFilterContainer = appConnect<
  IRiskFilterConnectedProps,
  never,
  IRiskFilterOwnProps
>((state, props) => {
  const risksList = props.customerRisks
    ? state.cyberRisk.customerData?.riskList
    : !!props.vendorId
      ? props.isSubsidiary
        ? state.cyberRisk.subsidiaries[props.vendorId]?.riskList
        : state.cyberRisk.vendors[props.vendorId]?.riskList
      : state.cyberRisk.allVendorRisks;

  return {
    loading: risksList?.loading ?? false,
    error: risksList?.error,
    allVendorRisks: props.customerRisks
      ? state.cyberRisk.customerData?.riskList?.risks
      : !!props.vendorId
        ? props.isSubsidiary
          ? state.cyberRisk.subsidiaries[props.vendorId]?.riskList?.risks
          : state.cyberRisk.vendors[props.vendorId]?.riskList?.risks
        : (state.cyberRisk.allVendorRisks.data?.risks as RiskMini[]),
  };
})(RiskFilter);
