import { FC } from "react";

import "../../style/components/surveys/SurveyName.scss";

interface SurveyNameProps {
  survey: {
    name?: string;
    type?: string;
  };
  contentAfterName?: React.ReactNode;
}

const SurveyName: FC<SurveyNameProps> = ({ survey, contentAfterName }) => {
  let surveyName = survey.name;
  let showType = false;

  if (!survey.name) {
    // No name so just use the type (or blank) as the name
    surveyName = survey.type ?? "";
  } else if (survey.name && survey.name.trim() !== survey.type?.trim()) {
    // Name and type don't match, so show both
    showType = true;
  }
  return (
    <div className={"survey-name-and-type"}>
      <div className={"survey-name"}>
        {surveyName}
        {contentAfterName}
      </div>
      {showType && survey.type && (
        <div className={"survey-type"}>{survey.type}</div>
      )}
    </div>
  );
};

export default SurveyName;
