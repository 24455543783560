import { FC, ReactNode, useState } from "react";
import Icon from "./core/Icon";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import classnames from "classnames";

interface AccordionItem {
  id: string;
  header: ReactNode;
  content: ReactNode;
  startExpanded?: boolean;
  className?: string;
}

interface AccordionProps {
  items: AccordionItem[];
}

const Accordion: FC<AccordionProps> = ({ items }) => {
  const [expanded, setExpanded] = useState(
    items.find((i) => i.startExpanded)?.id
  );

  return (
    <div className={"accordion"}>
      {items.map((item) => (
        <div
          key={item.id}
          className={classnames("expandable-item", item.className)}
        >
          <div className={"expandable-item-header"}>
            <a
              onClick={() =>
                setExpanded(expanded == item.id ? undefined : item.id)
              }
              className="expandable-item-header-link"
            >
              {item.header}
              <Icon
                name={"chevron"}
                direction={expanded == item.id ? 0 : 180}
                className="expandable-item-header-icon"
              />
            </a>
          </div>
          <TransitionGroup>
            {expanded == item.id && (
              <CSSTransition
                key={"expand-row"}
                timeout={250}
                classNames="expand"
              >
                <div className={"expandable-item-content"}>{item.content}</div>
              </CSSTransition>
            )}
          </TransitionGroup>
        </div>
      ))}
    </div>
  );
};

export default Accordion;
