import classnames from "classnames";
import { INotificationConfigCategory } from "../../../_common/types/notifications";
import { IOrgAlertDefinition } from "../../reducers/org.actions";

const getTriggerSelectionRows = (
  notifications: INotificationConfigCategory<IOrgAlertDefinition>[],
  selectedUUIDs: {
    [uuid: string]: boolean;
  },
  selectedTestUUID: string,
  setSelectedTestNotification: (notification: IOrgAlertDefinition) => void
) => {
  const rows = [];
  rows.push(
    <div key="title" className={"section-title"}>
      <div className={"main-title triggers-column"}>{"Triggers"}</div>
    </div>
  );

  notifications.forEach((n) =>
    n.subcategories.forEach((subcat) =>
      subcat.options.forEach((n) => {
        const selected = selectedTestUUID == n.uuid;
        const last = false;
        if (selectedUUIDs[n.uuid]) {
          rows.push(
            <div
              key={n.uuid}
              className={classnames("trigger-row", {
                selected: selected,
                clickable: true,
                last: last,
              })}
              onClick={() => setSelectedTestNotification(n)}
            >
              <div className={"nameval"}>{n.headlineVarsReplaced}</div>
            </div>
          );
        }
      })
    )
  );
  return rows;
};

interface ISelectableSidebarTriggersListProps {
  notifications: INotificationConfigCategory<IOrgAlertDefinition>[];
  selectedUUIDs: {
    [uuid: string]: boolean;
  };
  selectedTestUUID: string;
  setSelectedTestNotification: (notification: IOrgAlertDefinition) => void;
}

const SelectableSidebarTriggersList = ({
  notifications,
  selectedUUIDs,
  selectedTestUUID,
  setSelectedTestNotification,
}: ISelectableSidebarTriggersListProps): JSX.Element => {
  return (
    <div className={"trigger-list"}>
      {getTriggerSelectionRows(
        notifications,
        selectedUUIDs,
        selectedTestUUID,
        setSelectedTestNotification
      )}
    </div>
  );
};

export default SelectableSidebarTriggersList;
