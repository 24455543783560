import { FC, Fragment, ReactNode } from "react";
import classnames from "classnames";
import "../../style/components/pageHeader/MetadataSection.scss";

export interface MetadataSectionProps {
  metadataClassName?: string;
  metadataWidgets?: ReactNode[];
}

const MetadataSection: FC<MetadataSectionProps> = ({
  metadataClassName,
  metadataWidgets,
}) => {
  return (
    <div className={classnames("header-metadata-section", metadataClassName)}>
      {metadataWidgets?.map((w, idx) => (
        <Fragment key={idx}>
          <div className={"metadata-widget"}>{w}</div>
          {idx !== metadataWidgets.length - 1 && (
            <div className={"metadata-divider"}></div>
          )}
        </Fragment>
      ))}
    </div>
  );
};

export default MetadataSection;
