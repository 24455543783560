import ModalV2, { BaseModalProps } from "../../../_common/components/ModalV2";
import { IRisk } from "../RiskSummaryDetails";
import { IVendorContactResponse } from "../../../_common/types/vendorContact";
import { FC, useEffect, useMemo, useReducer, useState } from "react";
import { IStep, Steps } from "../../../_common/components/StepsWithSections";
import Button from "../../../_common/components/core/Button";
import RiskMini from "../RiskMini";
import {
  SelectSurveyCheck,
  SurveyCheckReducer,
} from "../../views/remediation_request/survey.reducer";
import {
  createNewRemediationRequest,
  fetchCustomerOrVendorRiskWebsites,
  fetchSurveysRemediationMini,
  IAdditionalEvidenceCheckToAdd,
  IAdditionalEvidenceDocumentToAdd,
  ICloudscanCheckAndWebsite,
  ISurveyCheckToAdd,
} from "../../reducers/remediation.actions";
import {
  CloudscanCheckAndWebsiteAction,
  CloudscanCheckAndWebsiteReducer,
  ICloudscanCheckAndWebsiteReducer,
  SelectCloudscanCheckAndWebsite,
} from "../../views/remediation_request/cloudscan.reducer";
import {
  AdditionalEvidenceCheckReducer,
  AdditionalEvidenceDocumentReducer,
  IAdditionalEvidenceCheckReducer,
  IAdditionalEvidenceDocumentReducer,
} from "../../views/remediation_request/additional_evidence.reducer";
import { VendorSummaryRiskType } from "../../../_common/types/vendorSummary";
import { useRiskWebsites } from "../../reducers/remediationRequest.selector";
import { RiskSurveyTable } from "../../views/remediation_request/SurveyRiskTable";
import TwoColumnDisplay, {
  TwoColumnDisplayRow,
} from "../../../_common/components/TwoColumnDisplay";
import TextField, {
  useTextWithValid,
} from "../../../_common/components/TextField";
import RichTextEditV2 from "../../../_common/components/RichTextEditV2";
import {
  getDocumentsFromSelectedRisks,
  shareSurveyAfterCreate,
} from "../../views/RequestRemediationV2";
import { existingUser } from "../ContactSelector";
import { getSingleVendorObject } from "../../../_common/selectors/vendorSelectors";
import { fetchVendorMgtContacts } from "../../reducers/vendorContacts.actions";
import ColorCheckbox from "../ColorCheckbox";
import moment from "moment";
import DatePicker from "../../../_common/components/DatePicker";
import { useSchedule } from "../../../_common/components/remediationDetails/UpdateDueDateModal";
import { DownloadEvidenceFromRiskEvidence } from "../../views/AdditionalEvidenceDocumentsTab";
import { DocumentLink } from "../DocumentLink";
import "../../style/components/RequestRemediationModal.scss";
import {
  DefaultTextType,
  OrgDefaultTexts,
  replaceVariablesInDefaultTexts,
} from "../../../_common/types/orgDefaultTexts";
import { fetchOrganisationDefaultTexts } from "../../reducers/orgDefaultTexts.actions";
import { RiskSummaryWithAssets } from "../../views/remediation_request/ReviewAndSendStep";
import { formatDate } from "../../../_common/helpers";
import { trackEvent } from "../../../_common/tracking";
import {
  createVendorContact,
  fetchVendorSummaryAndCloudscans,
} from "../../reducers/cyberRiskActions";
import {
  editRemediationRequestRisks,
  fetchRemediationRequestDetails,
  getRemediationRequestDetailsForVendorAssessmentFromState,
  IRemediationRequestBaseRequest,
} from "../../../_common/reducers/remediationRequest.actions";
import { ISurveyMini } from "../../../_common/types/survey";
import {
  OrgAccessSurveys,
  hasOrgPermission,
} from "../../../_common/permissions";
import {
  addDefaultSuccessAlert,
  addDefaultUnknownErrorAlert,
} from "../../../_common/reducers/messageAlerts.actions";
import {
  CloudscanInRemediation,
  RemediationRequest,
  RemediationRequestRisk,
} from "../../../_common/types/remediation";
import { RiskSource, RiskStatus } from "../../../_common/types/risks";
import LoadingIcon from "../../../_common/components/core/LoadingIcon";
import CVEMiniList, { GetCPEFromRiskID, IsCPERisk } from "../CVEMiniList";
import classNames from "classnames";
import ContactSelect, { ContactDisplay } from "../contacts/ContactSelect";
import InfoBanner, { BannerType } from "../InfoBanner";
import { appConnect, useAppDispatch } from "../../../_common/types/reduxHooks";
import { IVendorSummary } from "../../../_common/types/redux";
import {
  invalidateVendorAssessmentDraftsForVendor,
  invalidateVendorAssessmentRisks,
} from "../../reducers/vendorAssessmentAPI";

interface RequestRemediationModalOwnProps extends BaseModalProps {
  vendorID: number;
  vendorName: string;
  orgName: string;
  managedOrgID?: number;
  risk: IRisk;
  hostname?: string;
  surveyId?: number;
  isPublicSurvey?: boolean;
  vendorAssessmentID: number;
  onAddAnotherRisk: (
    risk: IRisk,
    hostname?: string,
    surveyId?: number,
    isPublicSurvey?: boolean
  ) => void;
}

interface RequestRemediationModalConnectedProps {
  contacts: IVendorContactResponse[];
  defaultTexts?: OrgDefaultTexts;
  canAccessSurveys: boolean;
  remediationSurveys: ISurveyMini[];
  existingRequest?: RemediationRequest;
}

type RequestRemediationModalProps = RequestRemediationModalOwnProps &
  RequestRemediationModalConnectedProps;

const RequestRemediationModal: FC<RequestRemediationModalProps> = (props) => {
  const dispatch = useAppDispatch();

  const [cloudscansInRemediation, setCloudscansInRemediation] = useState<
    Record<string, CloudscanInRemediation>
  >({});
  // data loading
  useEffect(() => {
    // load risk websites if needed
    if (props.risk.riskType == VendorSummaryRiskType.Cloudscan) {
      dispatch(
        fetchCustomerOrVendorRiskWebsites(
          props.risk.id,
          props.vendorID,
          false,
          false,
          [] // TODO
        )
      );
    }
    dispatch(
      fetchVendorSummaryAndCloudscans(
        props.vendorID,
        false,
        false,
        false,
        false,
        { websiteLabelIds: [] },
        true
      )
    ).then((summary) => {
      summary = summary as IVendorSummary;
      setCloudscansInRemediation(
        summary?.result?.cloudscansInRemediation ?? {}
      );
    });
    dispatch(fetchVendorMgtContacts(props.vendorID));
    dispatch(
      fetchOrganisationDefaultTexts(
        false,
        !!props.managedOrgID,
        props.managedOrgID
      )
    );
    if (props.canAccessSurveys) {
      dispatch(fetchSurveysRemediationMini(props.vendorID));
    }
  }, [props.risk, props.vendorID]);

  const [checksAndWebsites, checksAndWebsitesDispatch] =
    useReducer<ICloudscanCheckAndWebsiteReducer>(
      CloudscanCheckAndWebsiteReducer,
      {} as Record<string, ICloudscanCheckAndWebsite>
    );

  const [surveyChecks, surveyChecksDispatch] = useReducer(
    SurveyCheckReducer,
    {} as Record<string, ISurveyCheckToAdd>
  );

  const [additionalEvidenceChecks, additionalEvidenceChecksDispatch] =
    useReducer<IAdditionalEvidenceCheckReducer>(
      AdditionalEvidenceCheckReducer,
      {} as Record<string, IAdditionalEvidenceCheckToAdd>
    );

  const [additionalEvidenceDocuments, additionalEvidenceDocumentsDispatch] =
    useReducer<IAdditionalEvidenceDocumentReducer>(
      AdditionalEvidenceDocumentReducer,
      {} as Record<number, IAdditionalEvidenceDocumentToAdd>
    );

  const riskWebsites = useRiskWebsites(props.risk.id, {
    isSelfRemediation: false,
    vendorID: props.vendorID,
    isSubsidiary: false,
  });

  const remediatedWebsites = useMemo(
    () => cloudscansInRemediation[props.risk.id],
    [cloudscansInRemediation, props.risk.id]
  );

  const filteredHostnames = useMemo(
    () =>
      remediatedWebsites?.isAllWebsites
        ? []
        : riskWebsites?.data?.hostnamesWithRisk
            ?.filter((c) => !remediatedWebsites?.websites[c])
            .map((c) => c),
    [riskWebsites, remediatedWebsites]
  );

  // initiate our checks based on the risk data passed to the modal
  useEffect(() => {
    if (props.risk.riskType == VendorSummaryRiskType.Cloudscan) {
      // if there are already domains under remediation filter them out
      const action: CloudscanCheckAndWebsiteAction = props.hostname
        ? {
            type: "setCheckWebsites",
            checkId: props.risk.id,
            websites: [props.hostname],
          }
        : filteredHostnames &&
            filteredHostnames.length <
              (riskWebsites?.data?.hostnamesWithRisk?.length ?? 0)
          ? {
              type: "setCheckWebsites",
              checkId: props.risk.id,
              websites: filteredHostnames,
            }
          : {
              type: "selectAll",
              checkId: props.risk.id,
              selectAll: true,
            };
      checksAndWebsitesDispatch(action);
    } else if (props.risk.riskType == VendorSummaryRiskType.Survey) {
      // if a survey ID has been passed just set that survey, otherwise pick all that are not currently in remediation
      const surveyIds =
        props.risk.surveys
          ?.filter(
            (s) =>
              !s.publicSurvey &&
              (!s.inRemediation ||
                s.remediationRequestId == props.existingRequest?.details.id) &&
              !s.isWaived
          )
          .map((s) => s.surveyId) ?? [];
      const publicIds =
        props.risk.surveys
          ?.filter(
            (s) =>
              s.publicSurvey &&
              (!s.inRemediation ||
                s.remediationRequestId == props.existingRequest?.details.id) &&
              !s.isWaived
          )
          .map((s) => s.surveyId) ?? [];
      surveyChecksDispatch({
        type: "setCheckSurveys",
        riskId: props.risk.id,
        surveys:
          props.surveyId && !props.isPublicSurvey
            ? [props.surveyId]
            : !props.surveyId
              ? surveyIds
              : [],
        publicSurveys:
          props.surveyId && props.isPublicSurvey
            ? [props.surveyId]
            : !props.surveyId
              ? publicIds
              : [],
      });
    } else if (props.risk.riskType == VendorSummaryRiskType.Evidence) {
      additionalEvidenceChecksDispatch({
        type: "addCheckID",
        riskId: props.risk.id,
      });
    }
  }, [filteredHostnames, riskWebsites]);

  // if we have an existing request add in all the risks for it too so when we submit the edit request
  // we have the existing risks
  useEffect(() => {
    if (props.existingRequest) {
      props.existingRequest.details.risks
        .filter(
          (risk) =>
            risk.riskStatus == RiskStatus.Open ||
            risk.riskStatus == RiskStatus.Partial ||
            risk.riskStatus == RiskStatus.Draft
        )
        .forEach((risk) => {
          // if the risk is the same one that we are adding now skip this step so we don't revert it to the saved state
          if (risk.checkId == props.risk.id) {
            return;
          }
          if (risk.source === RiskSource.Cloudscan) {
            if (risk.isAllWebsites || !risk.websites?.length) {
              checksAndWebsitesDispatch({
                type: "selectAll",
                checkId: risk.checkId,
                selectAll: true,
              });
            } else {
              checksAndWebsitesDispatch({
                type: "setCheckWebsites",
                checkId: risk.checkId,
                websites: risk.websites,
              });
            }
            if (risk.metadata) {
              checksAndWebsitesDispatch({
                type: "setSelectedCVEs",
                checkId: risk.checkId,
                selectedCVEs: risk.metadata.cveNames,
                websites: risk.websites,
              });
            }
          } else if (risk.source === RiskSource.Survey) {
            surveyChecksDispatch({
              type: "setCheckSurveys",
              riskId: risk.checkId,
              surveys: risk.surveys
                .filter((s) => !!s.surveyId)
                .map((s) => s.surveyId ?? 0),
              publicSurveys: risk.surveys
                .filter((s) => !!s.publicSurveyId)
                .map((s) => s.publicSurveyId ?? 0),
            });
          } else if (risk.source === RiskSource.AdditionalEvidence) {
            additionalEvidenceChecksDispatch({
              type: "addCheckID",
              riskId: risk.checkId,
            });
          }
        });
    }
  }, []);

  // additional state
  const [requestName, requestNameValid, setRequestName] = useTextWithValid(""); // TODO - this should use the template name
  const [message, setMessage] = useState(""); // TODO - this should use the template message
  // set the message and name to default when default texts load
  useEffect(() => {
    if (props.defaultTexts) {
      let defaultTitle =
        props.defaultTexts[DefaultTextType.RemediationRequestTitle]
          ?.defaultText || "";
      let defaultEmailMessage =
        props.defaultTexts[DefaultTextType.RemediationRequestMessage]
          ?.defaultText || "";

      [defaultTitle, defaultEmailMessage] = replaceVariablesInDefaultTexts(
        [defaultTitle, defaultEmailMessage],
        {
          "{{VendorName}}": props.vendorName,
          "{{AccountName}}": props.orgName,
        }
      );

      // if (!loadDraftId) { TODO - don't do this if we are editing a draft
      setRequestName(defaultTitle, true);
      setMessage(defaultEmailMessage);
      // }
    }
  }, []);

  const [
    dueDate,
    reminderDate,
    reminderSelection,
    setDueDate,
    setReminderDate,
    setReminderSelectionType,
    ,
  ] = useSchedule();

  const [currentStep, setCurrentStep] = useState(1);
  const steps: IStep[] = [
    {
      id: "assets",
      text: "Select assets",
      onClick: () => setCurrentStep(1),
    },
    {
      id: "configure",
      text: "Configure",
      onClick: currentStep > 1 ? () => setCurrentStep(2) : undefined,
    },
    {
      id: "review",
      text: "Review",
      onClick: currentStep > 2 ? () => setCurrentStep(3) : undefined,
    },
  ];

  const checkAndWebsite = SelectCloudscanCheckAndWebsite(
    checksAndWebsites,
    props.risk.id
  );

  const surveyCheck = SelectSurveyCheck(surveyChecks, props.risk.id);

  const isPublicSurveySelected = (surveyCheck.publicSurveys?.length ?? 0) > 0;

  const documentWithRisks = getDocumentsFromSelectedRisks(
    [props.risk],
    additionalEvidenceChecks
  );

  const [selectedContacts, setSelectedContacts] = useState(
    [] as ContactDisplay[]
  );

  const canGoNext = () => {
    if (currentStep == 1) {
      // make sure at least one risk/asset is selected
      if (props.risk.riskType == VendorSummaryRiskType.Evidence) {
        return true; //
      } else if (props.risk.riskType == VendorSummaryRiskType.Cloudscan) {
        // make sure at least one clouscan risk is selected and we are not still loading the websites
        return (
          (checkAndWebsite.selectAll || checkAndWebsite.websites.length > 0) &&
          !(riskWebsites?.loading ?? true)
        );
      } else if (props.risk.riskType == VendorSummaryRiskType.Survey) {
        return (
          (surveyCheck.publicSurveys?.length ?? 0) > 0 ||
          (surveyCheck.surveys?.length ?? 0) > 0
        );
      }
    } else if (currentStep == 2) {
      return (
        selectedContacts.length > 0 &&
        selectedContacts.every(
          (c) => !c.isChecking && !c.emailDomainNotAllowed
        ) &&
        requestNameValid
      );
    }
    return true;
  };

  const [loading, setLoading] = useState(false);
  const onSubmit = () => {
    trackEvent("RequestRemediation");

    setLoading(true);

    let prom = Promise.resolve<any>([]);

    // Create new contacts if we need to
    prom = prom.then(() =>
      Promise.all(
        newContacts.map((c) =>
          dispatch(
            createVendorContact(props.vendorID, {
              name: c.name,
              title: c.title,
              emailAddress: c.emailAddress,
            })
          )
        )
      )
    );

    const sendingtoEmails = selectedContacts.map((c) => c.emailAddress);

    const cloudscanChecksAndWebsites = Object.values(checksAndWebsites);
    const surveyRisks = Object.values(surveyChecks);
    surveyRisks.forEach((sr) => {
      if (sr.riskID.indexOf("|") !== -1) {
        // Get the base risk id
        const parts = sr.riskID.split("|");
        if (parts.length !== 2) {
          throw new Error(`invalid risk id: ${sr.riskID}`);
        }
        sr.riskID = parts[0];
      }
    });
    const additionalEvidenceRisks = Object.values(additionalEvidenceChecks);
    additionalEvidenceRisks.forEach((aer) => {
      if (aer.riskID.indexOf("|") !== -1) {
        // Get the base risk id
        const parts = aer.riskID.split("|");
        if (parts.length !== 2) {
          throw new Error(`invalid risk id: ${aer.riskID}`);
        }
        aer.riskID = parts[0];
      }
    });

    // get the selected evidence documents to include in the request, making
    // sure to remove any documents that are no longer valid e.g. because there
    // are no longer any selected risks that relate to those documents
    const additionalEvidenceDocumentsToAdd = Object.values(
      additionalEvidenceDocuments
    );

    const request: IRemediationRequestBaseRequest = {
      title: requestName,
      inviteEmails: sendingtoEmails,
      emailMessage: message,
      cloudscanChecksAndWebsites,
      surveyChecksToAdd: surveyRisks,
      additionalEvidenceChecksToAdd: additionalEvidenceRisks,
      additionalEvidenceDocumentsToAdd,
      dueDate: dueDate ? moment(dueDate).utc(true).format() : undefined,
      reminderDate: reminderDate
        ? moment(reminderDate).utc(true).format()
        : undefined,
      saasChecksToAdd: [],
    };

    if (props.existingRequest) {
      // edit mode
      prom = prom.then(() =>
        dispatch(
          editRemediationRequestRisks({
            requestId: props.existingRequest?.details.id ?? 0,
            cloudscanChecksAndWebsites,
            surveyChecks: surveyRisks,
            additionalEvidenceChecks: additionalEvidenceRisks,
            additionalEvidenceDocumentsToAdd,
          })
        )
      );
    } else {
      prom = prom.then(() =>
        dispatch(
          createNewRemediationRequest({
            ...request,
            vendorId: props.vendorID,
            isSubsidiary: false,
            isDraft: false,
            vendorAssessmentId: props.vendorAssessmentID,
          })
        )
      );
    }

    const newContacts = selectedContacts.filter((c) => c.isNewContact);

    // if we are creating a new request and it's for a survey we need to share the survey with the users
    if (
      props.risk.riskType == VendorSummaryRiskType.Survey &&
      surveyCheck.surveys
    ) {
      const existingUsers = [
        ...props.contacts
          .filter((u) => u.emailAddress)
          .map(
            (u) =>
              ({
                name: u.name ?? "",
                email: u.emailAddress,
                isInvite: false,
                alreadyAdded: false,
                selected: !!selectedContacts.find(
                  (c) => c.emailAddress == u.emailAddress
                ),
              }) as existingUser
          ),
      ];

      prom = shareSurveyAfterCreate(
        prom,
        dispatch,
        props.remediationSurveys,
        surveyCheck.surveys,
        props.vendorName,
        props.orgName,
        newContacts,
        existingUsers,
        props.existingRequest
      );
    }

    // refresh our vendor assessment data and close
    prom
      .then(() => {
        dispatch(invalidateVendorAssessmentDraftsForVendor(props.vendorID));
        dispatch(invalidateVendorAssessmentRisks(props.vendorAssessmentID));
        dispatch(fetchVendorSummaryAndCloudscans(props.vendorID, true));
        if (props.existingRequest) {
          dispatch(
            fetchRemediationRequestDetails(
              props.existingRequest.details.id,
              true
            )
          );
        }
        dispatch(
          addDefaultSuccessAlert(
            `Remediation request ${
              !!props.existingRequest ? "updated" : "created"
            }`
          )
        );
        // if we added new vendor contacts refresh our contacts
        if (newContacts.length > 0) {
          dispatch(fetchVendorMgtContacts(props.vendorID, true));
        }
        props.onClose();
      })
      .catch(() => {
        dispatch(
          addDefaultUnknownErrorAlert("Error creating remediation request")
        );
      })
      .finally(() => setLoading(false));
  };

  const selectedRisks = props.existingRequest?.details.risks.reduce(
    (map, risk) => {
      map[risk.checkId] = risk;
      return map;
    },
    {} as Record<string, RemediationRequestRisk>
  );

  const assetText =
    props.risk.riskType == VendorSummaryRiskType.Cloudscan
      ? checkAndWebsite.selectAll
        ? "All assets included"
        : riskWebsites?.data?.hostnamesWithRisk
          ? `${checkAndWebsite.websites.length} / ${filteredHostnames?.length} Assets included`
          : "Assets included"
      : props.risk.riskType == VendorSummaryRiskType.Survey
        ? `${
            (surveyCheck.surveys?.length ?? 0) +
            (surveyCheck.publicSurveys?.length ?? 0)
          }/${props.risk.surveys?.filter(
            (s) =>
              (!s.inRemediation ||
                s.remediationRequestId == props.existingRequest?.details.id) &&
              !s.isWaived
          )?.length} Assets included`
        : "All documents";

  return (
    <ModalV2
      className={"request-remediation-modal"}
      active={props.active}
      onClose={props.onClose}
      headerContent={`Remediation request from ${props.vendorName}`}
      footerContent={
        <>
          <div className={"footer-left"}>
            {currentStep == 1 && (
              <Button
                onClick={() =>
                  props.onAddAnotherRisk(
                    props.risk,
                    props.hostname,
                    props.surveyId,
                    props.isPublicSurvey
                  )
                }
                disabled={!canGoNext()}
              >
                <i className={"cr-icon-plus"} />
                Add another risk
              </Button>
            )}
            {currentStep > 1 && (
              <Button leftArrow onClick={() => setCurrentStep((s) => s - 1)}>
                Previous
              </Button>
            )}
          </div>
          <div>
            <Button tertiary onClick={props.onClose} disabled={loading}>
              Cancel
            </Button>
            <Button
              loading={loading}
              filledPrimary
              arrow={currentStep < 3 && !props.existingRequest}
              disabled={!canGoNext()}
              onClick={
                currentStep < 3 && !props.existingRequest
                  ? () => setCurrentStep((s) => s + 1)
                  : onSubmit
              }
            >
              {props.existingRequest
                ? "Update request"
                : currentStep < 3
                  ? "Next"
                  : "Submit request"}
            </Button>
          </div>
        </>
      }
    >
      {!props.existingRequest && (
        <Steps steps={steps} currentStep={currentStep} />
      )}
      {currentStep == 1 && (
        <div
          className={classNames("remediation-modal-step-1", {
            existing: !!props.existingRequest,
          })}
        >
          <RiskMini risk={props.risk} assetText={assetText} />
          <div className={"asset-selection"}>
            {props.risk.riskType == VendorSummaryRiskType.Cloudscan && (
              <div className={"cloudscan-selection"}>
                <div className={"mini-header"}>domains & ips</div>
                <div className={"asset-box"}>
                  {(riskWebsites?.loading ?? true) && <LoadingIcon size={12} />}
                  {filteredHostnames?.map((hostname) => (
                    <ColorCheckbox
                      key={hostname}
                      label={hostname}
                      checked={
                        checkAndWebsite.selectAll ||
                        checkAndWebsite.websites.includes(hostname)
                      }
                      onClick={() => {
                        // if all are selected and we select a single hostname select all other hostnames
                        if (!riskWebsites?.data?.hostnamesWithRisk) {
                          return;
                        }
                        if (checkAndWebsite.selectAll) {
                          const hostnames = [
                            ...riskWebsites.data.hostnamesWithRisk,
                          ];
                          const idx = hostnames.indexOf(hostname);
                          hostnames.splice(idx, 1);
                          checksAndWebsitesDispatch({
                            type: "setCheckWebsites",
                            checkId: props.risk.id,
                            websites: hostnames,
                          });
                        } else {
                          const hostnames = [...checkAndWebsite.websites];
                          const idx = hostnames.indexOf(hostname);
                          if (idx == -1) {
                            hostnames.push(hostname);
                          } else {
                            hostnames.splice(idx, 1);
                          }
                          checksAndWebsitesDispatch({
                            type: "setCheckWebsites",
                            checkId: props.risk.id,
                            websites: hostnames,
                          });
                        }
                      }}
                    />
                  ))}
                </div>
                {IsCPERisk(props.risk.id) && (
                  <CVEMiniList
                    cpeName={GetCPEFromRiskID(props.risk.id)}
                    isCustomer={false}
                    hostnames={riskWebsites?.data?.hostnamesWithRisk}
                    selectable
                    selectedCVENames={checkAndWebsite.metadata?.CVENames}
                    onSelectionChange={(cves) =>
                      checksAndWebsitesDispatch({
                        type: "setSelectedCVEs",
                        checkId: props.risk.id,
                        selectedCVEs: cves ?? null,
                      })
                    }
                  />
                )}
              </div>
            )}
            {props.risk.riskType == VendorSummaryRiskType.Survey && (
              <div>
                <RiskSurveyTable
                  riskSurveys={
                    props.risk.surveys?.filter((s) => !s.publicSurvey) ?? []
                  }
                  selectedSurveys={surveyCheck.surveys ?? []}
                  onSelectRiskSurvey={(surveyIDs) => {
                    surveyChecksDispatch({
                      type: "setCheckSurveys",
                      riskId: props.risk.id,
                      surveys: surveyIDs,
                      publicSurveys: undefined,
                    });
                  }}
                  selectedRisk={selectedRisks?.[props.risk.id] ?? undefined}
                  requestId={props.existingRequest?.details.id}
                />
                <RiskSurveyTable
                  riskSurveys={
                    props.risk.surveys?.filter((s) => s.publicSurvey) ?? []
                  }
                  selectedSurveys={surveyCheck.publicSurveys ?? []}
                  onSelectRiskSurvey={(surveyIDs) => {
                    surveyChecksDispatch({
                      type: "setCheckSurveys",
                      riskId: props.risk.id,
                      surveys: undefined,
                      publicSurveys: surveyIDs,
                    });
                  }}
                  selectedRisk={selectedRisks?.[props.risk.id] ?? undefined}
                  requestId={props.existingRequest?.details.id}
                />
              </div>
            )}
          </div>
        </div>
      )}
      {currentStep == 2 && (
        <div className={"configure-step"}>
          <TwoColumnDisplay>
            <TwoColumnDisplayRow
              label={"Request name"}
              content={
                <TextField
                  required
                  maxLength={100}
                  value={requestName}
                  onChanged={setRequestName}
                />
              }
            />
            <TwoColumnDisplayRow
              label={"Add documents"}
              content={
                <div className={"document-selection"}>
                  <div className={"desc"}>
                    The risks selected have documents attached. All documents
                    selected will be attached to this remediation request and
                    will be visible to your recipients.
                  </div>
                  {documentWithRisks?.map((doc) => {
                    const selected =
                      !!additionalEvidenceDocuments[doc.document.id];
                    return (
                      <DocumentLink
                        key={doc.document.id}
                        evidenceName={doc.document.name}
                        filename={doc.document.filename}
                        virusSafe={doc.document.virusSafe}
                        selectable
                        isSelected={selected}
                        onSelectToggle={() => {
                          additionalEvidenceDocumentsDispatch({
                            type: selected
                              ? "removeEvidenceID"
                              : "addEvidenceID",
                            evidenceId: doc.document.id,
                          });
                        }}
                        onDownload={() =>
                          DownloadEvidenceFromRiskEvidence(
                            doc.document,
                            false,
                            dispatch
                          )
                        }
                      />
                    );
                  })}
                </div>
              }
              hide={props.risk.riskType != VendorSummaryRiskType.Evidence}
            />
            <TwoColumnDisplayRow
              label={"Recipients"}
              content={
                <>
                  <ContactSelect
                    onSelectionChanged={setSelectedContacts}
                    initiallySelectedEmailAddresses={selectedContacts
                      .filter((c) => !c.isNewContact)
                      .map((c) => c.emailAddress)}
                    initialNewContacts={selectedContacts.filter(
                      (c) => c.isNewContact
                    )}
                    vendorContacts={props.contacts}
                    validateVerifiedVendorID={
                      isPublicSurveySelected ? props.vendorID : undefined
                    }
                    vendorName={props.vendorName}
                  />
                  {isPublicSurveySelected &&
                    selectedContacts.some((c) => c.emailDomainNotAllowed) && (
                      <InfoBanner
                        type={BannerType.ERROR}
                        message={`Email domain must be one of ${props.vendorName}'s domains`}
                      />
                    )}
                </>
              }
            />
            <TwoColumnDisplayRow
              label={"Schedule"}
              content={
                <div className={classNames("schedule", { colapsed: !dueDate })}>
                  <ColorCheckbox
                    label={"Add a due date"}
                    checked={!!dueDate}
                    onClick={() => {
                      if (!dueDate) {
                        setDueDate(moment().add(60, "days").format());
                      } else {
                        setDueDate(undefined);
                      }
                    }}
                  />
                  <p className={"indent"}>
                    Select a date this needs to be completed.
                  </p>
                  {!!dueDate && (
                    <div className={"due-date-inner indent"}>
                      <DatePicker
                        onChange={(e) => setDueDate(e.target.value)}
                        min={moment().format()}
                        value={dueDate}
                      />
                    </div>
                  )}
                  {!!dueDate && (
                    <div className={"reminder"}>
                      <ColorCheckbox
                        label={"Add a reminder"}
                        checked={reminderSelection != "none"}
                        onClick={() =>
                          reminderSelection === "none"
                            ? setReminderSelectionType("30b")
                            : setReminderSelectionType("none")
                        }
                        disabled={!dueDate}
                      />
                      <p className={"indent"}>
                        We’ll remind the recipient of your request on the
                        specified date.
                      </p>
                      {reminderSelection !== "none" && (
                        <div className={"reminder-inner indent"}>
                          <ColorCheckbox
                            label={"30 days before due date"}
                            checked={reminderSelection === "30b"}
                            onClick={() => setReminderSelectionType("30b")}
                            radio
                          />
                          <ColorCheckbox
                            label={"7 days before due date"}
                            checked={reminderSelection === "7b"}
                            onClick={() => setReminderSelectionType("7b")}
                            radio
                          />
                          <ColorCheckbox
                            label={"Choose custom reminder date"}
                            checked={reminderSelection === "custom"}
                            onClick={() => setReminderSelectionType("custom")}
                            radio
                          />
                          <DatePicker
                            className={"reminder-picker indent"}
                            onChange={(evt) =>
                              setReminderDate(evt.target.value)
                            }
                            min={moment().format()}
                            disabled={reminderSelection !== "custom"}
                            value={reminderDate}
                            placeholder={"Select reminder date"}
                          />
                        </div>
                      )}
                    </div>
                  )}
                </div>
              }
            />
            <TwoColumnDisplayRow
              label={"Message"}
              content={
                <div className={"message-row"}>
                  <div className={"desc"}>
                    Include a custom message with your request.
                  </div>
                  <RichTextEditV2
                    value={message}
                    onChange={(s) => setMessage(s)}
                  />
                </div>
              }
            />
          </TwoColumnDisplay>
        </div>
      )}
      {currentStep == 3 && (
        <div className={"review-step"}>
          <div className={"risks-and-assets"}>
            <div className={"title"}>Risks and assets</div>
            <RiskSummaryWithAssets
              risk={props.risk}
              cloudscanCheck={checksAndWebsites[props.risk.id]}
              surveyCheck={surveyChecks[props.risk.id]}
              evidenceCheck={additionalEvidenceChecks[props.risk.id]}
              riskWebsiteOptions={{
                vendorID: props.vendorID,
                isSelfRemediation: false,
                isSubsidiary: false,
              }}
            />
          </div>
          <TwoColumnDisplay>
            <TwoColumnDisplayRow
              label={"Documents"}
              hide={props.risk.riskType != VendorSummaryRiskType.Evidence}
              content={
                <div className={"documents-review"}>
                  {documentWithRisks?.map((doc) => {
                    return (
                      <DocumentLink
                        key={doc.document.id}
                        evidenceName={doc.document.name}
                        filename={doc.document.filename}
                        virusSafe={doc.document.virusSafe}
                        onDownload={() =>
                          DownloadEvidenceFromRiskEvidence(
                            doc.document,
                            false,
                            dispatch
                          )
                        }
                      />
                    );
                  })}
                </div>
              }
            />
            <TwoColumnDisplayRow label={"Request name"} content={requestName} />
            <TwoColumnDisplayRow
              label={"Recipients"}
              content={
                <div className={"recipients"}>
                  {selectedContacts.map((r) => (
                    <div className="recipient" key={r.emailAddress}>
                      {!!r.name && (
                        <div className="recipient-name">{r.name}</div>
                      )}
                      <div className="recipient-email">{r.emailAddress}</div>
                    </div>
                  ))}
                </div>
              }
            />
            <TwoColumnDisplayRow
              label={"Due date"}
              content={formatDate(dueDate)}
              hide={!dueDate}
            />
            <TwoColumnDisplayRow
              label={"Reminder date"}
              content={formatDate(reminderDate)}
              hide={!reminderDate}
            />
            <TwoColumnDisplayRow
              label={"Message"}
              content={<RichTextEditV2 value={message} readOnly />}
            />
          </TwoColumnDisplay>
        </div>
      )}
    </ModalV2>
  );
};

export default appConnect<
  RequestRemediationModalConnectedProps,
  never,
  RequestRemediationModalOwnProps
>((state, props) => {
  const vendorData = getSingleVendorObject(
    state,
    props.vendorID,
    props.managedOrgID
  );

  const result: RequestRemediationModalConnectedProps = {
    contacts: vendorData?.mgtlists?.contacts?.result ?? [],
    defaultTexts:
      props.managedOrgID &&
      state.cyberRisk.managedVendorData[props.managedOrgID]
        ? state.cyberRisk.managedVendorData[props.managedOrgID].orgDefaultTexts
        : state.cyberRisk.orgDefaultTexts,
    remediationSurveys: vendorData?.remediationSurveys ?? [],
    canAccessSurveys: hasOrgPermission(
      state.common.permissions,
      OrgAccessSurveys
    ),
    existingRequest: getRemediationRequestDetailsForVendorAssessmentFromState(
      props.vendorAssessmentID,
      state
    ),
  };
  return result;
})(RequestRemediationModal);
