import { MergeTag } from "../../../_common/types/orgDefaultTexts";
import { FC, ReactElement, useState } from "react";
import ReportCard from "../../../_common/components/ReportCard";
import Button from "../../../_common/components/core/Button";
import "../../style/components/vendor_assessment/VendorAssessmentAutofillClassificationsSummaryCard.scss";
import LoadingBanner from "../../../_common/components/core/LoadingBanner";
import ToggleSwitch from "../../../_common/components/core/ToggleSwitch";
import {
  AutofillSummarySection,
  VendorAssessmentNarrativeSection,
} from "../../types/vendorAssessments";
import VendorAssessmentV3TextCard from "./VendorAssessmentV3TextCard";
import {
  RiskClassification,
  RiskClassificationGroup,
  RiskClassificationString,
} from "../../../_common/types/risk/classification";
import Score from "../Score";
import ColorGrade, { ColorGradeSize } from "../executive_summary/ColorGrade";
import classnames from "classnames";
import { formatDateAsLocal } from "../../../_common/helpers";
import { VendorSummaryRisk } from "../../../_common/types/vendorSummary";
import {
  IVendorRiskWaiver,
  VendorRiskWaiverRiskType,
} from "../../../_common/types/vendorRiskWaivers";
import SeverityIcon from "../../../_common/components/SeverityIcon";
import { Severity, SeverityInt } from "../../../_common/types/severity";

interface VendorAssessmentAutofillClassificationsSummaryCardProps {
  vendorName: string;
  narrativeSections?: VendorAssessmentNarrativeSection[];
  title: string;
  onSave?: (type: string, val: string) => Promise<void>;
  mergeTags: MergeTag[];
  readonly: boolean;
  autofillRunning?: boolean;
  goToTemplates?: () => void;
  loading?: boolean;
  sectionHidden?: boolean;
  onToggleHideSection?: (type: string, hidden: boolean) => void;
  author: string;
  publishedDate?: string;
  overallRating?: number;
  overallRatingDescription?: string;
  automatedScanRating?: number;
  evidenceRating?: number;
  risks?: VendorSummaryRisk[];
  waivers?: IVendorRiskWaiver[];
}

// VendorAssessmentAutofillClassificationsSummaryCard
// This component displays all risk classification sections included on an autofill assessment.
const VendorAssessmentAutofillClassificationsSummaryCard: FC<
  VendorAssessmentAutofillClassificationsSummaryCardProps
> = (props) => {
  const [editing, setEditing] = useState(false);

  const [saving, _setSaving] = useState(false);

  const getSections = () => {
    const sections: ReactElement[] = [];

    if (props.narrativeSections) {
      const classifications = Object.values(RiskClassification) as string[];

      // Filter out sections that are not in the classifications list, then sort by the classifications enum order
      const sortedSections = [
        ...props.narrativeSections
          .filter(
            (s) =>
              !(props.readonly && s.hidden) &&
              classifications.includes(s.section)
          )
          .sort((a, b) => {
            return (
              classifications.indexOf(a.section) -
              classifications.indexOf(b.section)
            );
          }),
      ];

      sortedSections.forEach((ns) => {
        const criticalRisks = props.risks?.filter(
          (r) =>
            r.severity === SeverityInt.CriticalSeverity &&
            !r.isWaived &&
            RiskClassificationGroup(r.classification) === ns.section
        ).length;
        const highRisks = props.risks?.filter(
          (r) =>
            r.severity === SeverityInt.HighSeverity &&
            !r.isWaived &&
            RiskClassificationGroup(r.classification) === ns.section
        ).length;
        const mediumRisks = props.risks?.filter(
          (r) =>
            r.severity === SeverityInt.MediumSeverity &&
            !r.isWaived &&
            RiskClassificationGroup(r.classification) === ns.section
        ).length;
        const lowRisks = props.risks?.filter(
          (r) =>
            r.severity === SeverityInt.LowSeverity &&
            !r.isWaived &&
            RiskClassificationGroup(r.classification) === ns.section
        ).length;

        // In view mode, show visible sections.  In edit mode, show all sections.
        if (!ns.hidden || editing) {
          sections.push(
            <div className="risk-classification-section" key={ns.section}>
              <div className="risk-classification-section-card">
                <VendorAssessmentV3TextCard
                  copyType={ns.section}
                  title={RiskClassificationString(
                    ns.section as RiskClassification,
                    "",
                    true
                  )}
                  text={ns.content}
                  onSave={props.onSave}
                  mergeTags={props.mergeTags}
                  orgHasCustomTemplatesAccess={false}
                  loading={props.loading}
                  sectionHidden={ns.hidden}
                  onToggleHideSection={props.onToggleHideSection}
                  noReportCard={true}
                  textEditModeOverride={editing}
                  hideSectionToggle={!editing}
                  hideEditButton={true}
                />
              </div>
              {!ns.hidden && (
                <div className="risk-classification-side-section">
                  <div className="risk-classification-risk-breakdown">
                    <div className="ratings-header">
                      CURRENT RISKS BY SEVERITY
                    </div>
                    <div className="risk-totals">
                      <div className="risk-total">
                        <div className="risk-total-label">Critical</div>
                        <div className="risk-severity">
                          <SeverityIcon
                            severity={Severity.Critical}
                            greyed={criticalRisks == 0}
                          />
                          <div className="risk-total-value">
                            {criticalRisks}
                          </div>
                        </div>
                      </div>
                      <div className="risk-total">
                        <div className="risk-total-label">High</div>
                        <div className="risk-severity">
                          <SeverityIcon
                            severity={Severity.High}
                            greyed={highRisks == 0}
                          />
                          <div className="risk-total-value">{highRisks}</div>
                        </div>
                      </div>
                      <div className="risk-total">
                        <div className="risk-total-label">Medium</div>
                        <div className="risk-severity">
                          <SeverityIcon
                            severity={Severity.Medium}
                            greyed={mediumRisks == 0}
                          />
                          <div className="risk-total-value">{mediumRisks}</div>
                        </div>
                      </div>
                      <div className="risk-total">
                        <div className="risk-total-label">Low</div>
                        <div className="risk-severity">
                          <SeverityIcon
                            severity={Severity.Low}
                            greyed={lowRisks == 0}
                          />
                          <div className="risk-total-value">{lowRisks}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          );
        }
      });
    }

    return sections;
  };

  return (
    <ReportCard
      newStyles
      className={"vendor-assessment-autofill-classifications-summary-card"}
    >
      <div className={"header"}>
        {props.title}
        <div className={"header-right"}>
          {!props.readonly && !editing && (
            <div className="section-toggle">
              <ToggleSwitch
                name={"section-toggle-autofill-summary"}
                disabled={props.readonly || props.autofillRunning}
                selected={!props.sectionHidden}
                onClick={
                  !props.readonly
                    ? () => {
                        setEditing(false);
                        props.onToggleHideSection?.(
                          AutofillSummarySection,
                          !props.sectionHidden
                        );
                      }
                    : undefined
                }
              />
              <div className="section-toggle-label">Include section</div>
            </div>
          )}
          {!props.readonly && (
            <Button
              loading={saving}
              disabled={props.sectionHidden || props.autofillRunning}
              onClick={() => {
                setEditing((val) => !val);
              }}
            >
              <i className={editing ? "cr-icon-eye" : "cr-icon-pencil"} />
              {editing ? "Preview" : "Edit"}
            </Button>
          )}
        </div>
      </div>
      {props.loading ? (
        <LoadingBanner />
      ) : (
        !props.sectionHidden && (
          <div className="vendor-assessment-autofill-classifications-summary-content">
            <div className="intro-text">
              The following summarizes the findings of the risk assessment
              conducted on this vendor by {props.author} and published on{" "}
              {formatDateAsLocal(props.publishedDate ?? new Date())}.
            </div>
            <div className="ratings-container">
              <div className="ratings-item">
                <div className="ratings-header">OVERALL SECURITY RATING</div>
                <div className="overall-rating-score">
                  <ColorGrade score={props.overallRating} />
                  <Score
                    colored={true}
                    large={true}
                    outOf={950}
                    score={props.overallRating}
                  />
                </div>
                <div className="ratings-description">
                  {props.vendorName + " has " + props.overallRatingDescription}
                </div>
              </div>
              <div
                className={classnames(
                  "individual-ratings-container",
                  "ratings-item"
                )}
              >
                <div className="headers">
                  <div className="ratings-header">
                    AUTOMATED SCAN RATING
                    {props.waivers?.some(
                      (w) => w.riskType === VendorRiskWaiverRiskType.Cloudscan
                    ) && "*"}
                  </div>
                  <div className="ratings-header">
                    EVIDENCE RATING
                    {props.waivers?.some(
                      (w) => w.riskType === VendorRiskWaiverRiskType.Survey
                    ) && "*"}
                  </div>
                </div>
                <div className="ratings">
                  <div className="individual-rating">
                    <ColorGrade
                      score={props.automatedScanRating}
                      size={ColorGradeSize.Small}
                    />
                    <Score score={props.automatedScanRating} small={true} />
                  </div>
                  <div className="individual-rating">
                    <ColorGrade
                      score={props.evidenceRating}
                      size={ColorGradeSize.Small}
                    />
                    <Score score={props.evidenceRating} small={true} />
                  </div>
                </div>
                <div className="ratings-description">
                  The overall risk rating is calculated from the results of
                  automated scanning and questionnaires
                </div>
                {props.waivers?.some(
                  (w) =>
                    w.riskType === VendorRiskWaiverRiskType.Cloudscan ||
                    w.riskType === VendorRiskWaiverRiskType.Survey
                ) && (
                  <div className="waived-risk-text">
                    *This score has been impacted by waived risks
                  </div>
                )}
              </div>
            </div>
            <div className={"sections"}>{getSections()}</div>
          </div>
        )
      )}
    </ReportCard>
  );
};

export default VendorAssessmentAutofillClassificationsSummaryCard;
