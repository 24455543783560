export const MaxFileSizeB = 100_000_000;
export const MaxFileSizeDisplay = `${MaxFileSizeB / 1_000_000}MB`;

export const AcceptedDocumentFileExtensions = [
  ".jpg",
  ".jpeg",
  ".png",
  ".pdf",
  ".docx",
  ".xlsx",
  ".csv",
  ".pptx",
  ".txt",
];

export const AcceptedDocumentMimeTypes = [
  "text/csv",
  "text/x-csv",
  "text/plain",
  "image/jpeg",
  "image/png",
  "application/pdf",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation",
];

export const AcceptedFileTypes = [
  ...AcceptedDocumentFileExtensions,
  ...AcceptedDocumentMimeTypes,
];
