import { Component } from "react";
import PropTypes from "prop-types";

import Button from "../../../_common/components/core/Button";
import { setCustomerReportDueDate } from "../../reducers/actions";
import { closeModal } from "../../../_common/reducers/commonActions";
import "../../style/SetDueDateModal.scss";
import DatePicker from "../../../_common/components/DatePicker";
import { addDefaultUnknownErrorAlert } from "../../../_common/reducers/messageAlerts.actions";

export const SetDueDateModalName = "SetDueDateModalName";

/**
 * <SetDueDateModal />
 */
class SetDueDateModal extends Component {
  static propTypes = {
    modalData: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
  };

  state = { loading: false };

  UNSAFE_componentWillMount() {
    let bsDate = new Date();
    if (this.props.modalData.breachSightDueDate) {
      bsDate = new Date(this.props.modalData.breachSightDueDate);
    }

    if (this.props.modalData.customerCadence == "yearly") {
      bsDate.setMonth(bsDate.getMonth() + 12);
    } else {
      bsDate.setMonth(bsDate.getMonth() + 1);
    }

    this.setState({
      breachSightDueDate: bsDate.toISOString().split("T")[0],
      customerID: this.props.modalData.customerID,
      customerName: this.props.modalData.customerName,
    });
  }

  onClick = () => {
    this.setState({ loading: true });
    this.props
      .dispatch(
        setCustomerReportDueDate(
          this.state.customerID,
          this.state.breachSightDueDate
        )
      )
      .then(this.setState({ loading: false }))
      .then(this.props.dispatch(closeModal()))
      .catch(() => {
        this.setState({ loading: false });
        this.props.dispatch(
          addDefaultUnknownErrorAlert("Error setting due date of next review")
        );
      });
  };

  onChangeBreachSightDueDate = (e) => {
    const val = e.target.value;
    this.setState({
      breachSightDueDate: val,
    });
  };

  render() {
    const { customerName, customerCadence } = this.props.modalData;
    let { breachSightDueDate } = this.state;

    return (
      <div className="modal-content">
        <h2>Review Completed</h2>
        <div>Enter the due date for the next review of {customerName}.</div>
        <form>
          <div className="btn-group no-margin gap">
            <DatePicker
              name="breachSightDueDate"
              required
              value={breachSightDueDate}
              onChange={this.onChangeBreachSightDueDate}
            />
          </div>
          <div className="btn-group no-margin">
            <Button
              primary
              loading={this.state.loading}
              disabled={this.state.loading}
              onClick={this.onClick}
            >
              OK
            </Button>
            <Button
              disabled={this.state.loading}
              onClick={() => this.props.dispatch(closeModal())}
            >
              Cancel
            </Button>
          </div>
        </form>
      </div>
    );
  }
}

export default SetDueDateModal;
