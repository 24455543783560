import { useState } from "react";
import { XTableCell } from "../../../_common/components/core/XTable";
import { IXTableColumnHeader } from "../../../_common/components/core/XTable";
import { DetectedProduct } from "../../../_common/types/detectedProducts";
import PaginatedTable from "../PaginatedTable";
import { useDefaultHistory } from "../../../_common/types/router";
import "../../style/components/DetectedProductsMiniTable.scss";
import { OpenSidePanel } from "../DomainsAndIPsPanelGroup";

interface IDetectedProductMiniTableProps {
  products: DetectedProduct[];
  currentPanelName: string;
  userCanAccessVendorRisk?: boolean;
}

const DetectedProductsMiniTable = (props: IDetectedProductMiniTableProps) => {
  const history = useDefaultHistory();
  const pageSize = 10;
  const [currentPage, setCurrentPage] = useState(0);
  const title =
    "Products " + (props.products?.length ? `(${props.products?.length})` : "");

  const columnHeaders: IXTableColumnHeader[] = [
    {
      id: "name",
      text: title,
      className: "title-header",
    },
    {
      id: "vendor",
      text: "Vendor",
      className: "column-header",
    },
  ];

  return (
    <PaginatedTable
      columnHeaders={columnHeaders}
      className={"detected-products-mini-table"}
      pageSize={pageSize}
      page={currentPage}
      onPageChange={(p: number) => setCurrentPage(p - 1)}
      rows={props.products?.map((product) => {
        return {
          id: product.uuid,
          onClick: () =>
            OpenSidePanel(
              history,
              { productUUID: product.uuid },
              props.currentPanelName
            ),
          cells: [
            <XTableCell className={"product-cell"} key="hostname">
              {product.tech_name}
            </XTableCell>,
            <XTableCell
              className={"vendor-cell"}
              key="vendor"
              onClick={() => {
                props.userCanAccessVendorRisk
                  ? history.push(`/vendor/${product.vendor_id}`, {
                      backContext: {
                        goBack: true,
                        backToText: `Back to ${props.currentPanelName}`,
                      },
                    })
                  : undefined;
              }}
            >
              <div className="vendor-name-wrapper">
                <div className="vendor-name">{product.vendor?.name}</div>
                {product.vendor?.primary_hostname && (
                  <div className="vendor-hostname">
                    {product.vendor?.primary_hostname}
                  </div>
                )}
              </div>
            </XTableCell>,
            <XTableCell className={"chevron-cell"} key={"chevron"}>
              <i className={"cr-icon-chevron"} />
            </XTableCell>,
          ],
        };
      })}
    />
  );
};

export default DetectedProductsMiniTable;
