import "../style/components/ActivityFeed.scss";
import { IUserMini } from "../types/user";
import { FC, forwardRef, ReactNode, useMemo } from "react";
import moment from "moment/moment";
import UserAvatar from "./UserAvatar";
import { SidePopupV2 } from "./DismissablePopup";
import classNames from "classnames";

const defaultCombineItemsOccurringWithinSecs = 60 * 60 * 24; // one day

export interface IActivityFeedItemStatusChangeProps {
  prevStatus: ReactNode;
  currentStatus: ReactNode;
}

export const ActivityFeedItemStatusChange: FC<
  IActivityFeedItemStatusChangeProps
> = ({ prevStatus, currentStatus }) => {
  return (
    <div className="activity-feed-item-status-change">
      {prevStatus}
      <div className="cr-icon-arrow-right" />
      {currentStatus}
    </div>
  );
};

export interface IActivityFeedItem {
  id: number;
  createdBy?: IUserMini;
  activity: string;
  text?: string;
  additionalInfo?: ReactNode;
  createdAt: string;
}

export interface IActivityFeedItemGroup {
  id: number;
  createdBy?: IUserMini;
  items: IActivityFeedItem[];
}

interface IActivityFeedProps {
  items: IActivityFeedItem[];
  preContent?: ReactNode; // Add an optional node to the top of the items
  datesDescending?: boolean;
  combineItemsOccurringWithinSecs?: number;
}

// groupActivityFeedItems gets a list of activity feed items and separates
// them into groups for display. Adjacent items are grouped when they:
// - share the same createdBy (or both have none)
// - occurred less than combineItemsOccurringWithinSecs apart
export const groupActivityFeedItems = (
  items: IActivityFeedItem[],
  datesDescending: boolean,
  combineItemsOccurringWithinSecs: number
): IActivityFeedItemGroup[] => {
  const groupedItems: IActivityFeedItemGroup[] = [];

  let curGroup: IActivityFeedItemGroup | undefined;

  // First make sure the items are sorted how we expect them by date
  const sortedItems = [...items].sort((a, b) =>
    datesDescending
      ? Date.parse(b.createdAt) - Date.parse(a.createdAt)
      : Date.parse(a.createdAt) - Date.parse(b.createdAt)
  );

  sortedItems.forEach((item) => {
    let startNewGroup =
      !curGroup ||
      !!curGroup.createdBy !== !!item.createdBy ||
      curGroup.createdBy?.id !== item.createdBy?.id;

    if (!startNewGroup && curGroup) {
      const prevItem = curGroup.items[curGroup.items.length - 1];
      let timeDiff = moment(prevItem.createdAt).diff(item.createdAt, "seconds");

      if (!datesDescending) {
        timeDiff = -timeDiff;
      }

      if (timeDiff > combineItemsOccurringWithinSecs) {
        startNewGroup = true;
      }
    }

    if (startNewGroup) {
      if (curGroup) {
        groupedItems.push(curGroup);
      }

      curGroup = {
        id: item.id,
        createdBy: item.createdBy,
        items: [item],
      };
    } else if (curGroup) {
      curGroup.items.push(item);
    }
  });

  if (curGroup) {
    groupedItems.push(curGroup);
  }

  return groupedItems;
};

const ActivityFeed = forwardRef<HTMLDivElement, IActivityFeedProps>(
  function ActivityFeed(
    {
      items,
      preContent,
      datesDescending = false,
      combineItemsOccurringWithinSecs = defaultCombineItemsOccurringWithinSecs,
    },
    ref
  ) {
    // If showing in descending order, we need to reverse all the items
    // then re-reverse them by using flex-direction: column-reverse.
    // This is so the component stays scrolled to the bottom when new items are added.
    const reverseItems = !datesDescending;

    const groupedItems = useMemo(() => {
      const groupedItems = groupActivityFeedItems(
        items,
        datesDescending,
        combineItemsOccurringWithinSecs
      );

      if (reverseItems) {
        groupedItems.reverse();
      }

      return groupedItems;
    }, [items, datesDescending, reverseItems, combineItemsOccurringWithinSecs]);

    const now = moment();

    return (
      <div
        ref={ref}
        className={classNames("activity-feed", { reversed: reverseItems })}
      >
        {!reverseItems && <div className="activity-feed-pre">{preContent}</div>}
        {groupedItems.map((item) => (
          <div key={item.id} className="activity-feed-group">
            <div className="avatar-col">
              {item.createdBy && <UserAvatar avatar={item.createdBy.avatar} />}
            </div>
            <div className="items-col">
              {item.items.map((subItem) => {
                const mo = moment(subItem.createdAt);
                let moFromNow;
                if (mo.isAfter(now)) {
                  // Sometimes you can temporarily get a timestamp a few seconds in the future if the client
                  // browser is a few seconds behind our db. Make future timestamps just now.
                  moFromNow = now.fromNow();
                } else {
                  moFromNow = mo.fromNow();
                }

                return (
                  <div key={subItem.id} className="activity-item">
                    <div className="activity-item-head">
                      {subItem.createdBy && (
                        <>
                          <strong>{subItem.createdBy.name}</strong>{" "}
                        </>
                      )}
                      {subItem.activity}
                      <SidePopupV2
                        className="datetime"
                        text={mo.format("lll")}
                        micro
                        noWrap
                        popupDelay={250}
                      >
                        <span>{moFromNow}</span>
                      </SidePopupV2>
                    </div>
                    {subItem.text && (
                      <div className="activity-item-comment">
                        {subItem.text}
                      </div>
                    )}
                    {subItem.additionalInfo && (
                      <div className="activity-item-additional">
                        {subItem.additionalInfo}
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        ))}
        {reverseItems && <div className="activity-feed-pre">{preContent}</div>}
      </div>
    );
  }
);

export default ActivityFeed;
