import { Component, PureComponent } from "react";
import {
  DisplayableExportSchedule,
  ExportFiletype,
  ExportItem,
} from "../../../_common/types/exportReport";
import XTable, {
  IIconOption,
  ISortedBy,
  IXTableColumnHeader,
  IXTableRow,
  SortDirection,
  XTableCell,
} from "../../../_common/components/core/XTable";
import _ from "lodash";
import { IntervalToName } from "../../../_common/types/asyncSchedule";
import { getReportMeta } from "./ReportExportsList";
import FileTypeIconPDF from "../../../_common/images/file-type-icon-pdf.svg";
import FileTypeIconExcel from "../../../_common/images/file-type-icon-excel.svg";
import FileTypeIconPPTX from "../../../_common/images/file-type-icon-pptx.svg";

import "../../style/components/reporting/ReportScheduleList.scss";
import SvgIcon from "../../../_common/components/SvgIcon";
import { userReportExportStatuses } from "../../views/reporting/ReportExportsV2";
import moment from "moment";
import EmptyCardWithAction from "../../../_common/components/EmptyCardWithAction";

import ClockRefresh from "../../../_common/images/clock-refresh.svg";
import { AssuranceType } from "../../../_common/types/organisations";
import classNames from "classnames";
import PillLabel from "../PillLabel";
import { LabelColor } from "../../../_common/types/label";

interface IReportScheduleSubListProps {
  exports: ExportItem[];
  loading: boolean;
  onDownloadItem: (item: ExportItem) => void;
  onArchiveItems: (items: ExportItem[]) => void;
  onDeleteItems: (items: ExportItem[]) => void;
  selectedIds: number[];
  onSelectAll: (select: boolean, ids: number[]) => void;
  onSelect: (id: string | number, newSelectedState: boolean) => void;
}

class ReportScheduleSubList extends PureComponent<IReportScheduleSubListProps> {
  columnHeaders: IXTableColumnHeader[] = [
    {
      id: "filename",
      text: "Filename",
      startingSortDir: SortDirection.ASC,
    },
    {
      id: "requested",
      text: "Requested",
      startingSortDir: SortDirection.ASC,
    },
  ];

  selectAll = (select: boolean) =>
    this.props.onSelectAll(
      select,
      this.props.exports.map((item) => item.id)
    );

  getRows = (items: ExportItem[]): IXTableRow[] => {
    if (this.props.loading) {
      return [];
    }

    return items.map((item) => {
      const iconOptions = [];

      if (
        item.status === userReportExportStatuses.Complete ||
        item.status === userReportExportStatuses.Error
      ) {
        iconOptions.push({
          id: "delete",
          hoverText: "Delete",
          icon: <i className="cr-icon-trash" />,
          onClick: () => this.props.onDeleteItems([item]),
        });

        iconOptions.push({
          id: "archive",
          hoverText: !item.archivedAt ? "Archive" : "Unarchive",
          icon: !item.archivedAt ? (
            <i className={"cr-icon-archive"} />
          ) : (
            <SvgIcon iconName="jump-arrow-left" />
          ),

          onClick: () => this.props.onArchiveItems([item]),
        });
      }

      if (item.status === userReportExportStatuses.Complete) {
        iconOptions.push({
          id: "download",
          hoverText: "Download",
          icon: <i className="cr-icon-download" />,
          onClick: () => this.props.onDownloadItem(item),
        });
      }

      return {
        id: item.id,
        iconOptions,
        selected:
          this.props.selectedIds &&
          this.props.selectedIds.indexOf(item.id) !== -1,
        cells: [
          <XTableCell
            className={"filename-cell"}
            key={"filename"}
            onClick={
              item.status === userReportExportStatuses.Complete
                ? () => this.props.onDownloadItem(item)
                : undefined
            }
          >
            <div
              className={classNames("scheduled-filename", {
                active: item.status === userReportExportStatuses.Complete,
              })}
            >
              <img
                className={"file-icon"}
                src={
                  item.exportFiletype === ExportFiletype.PDF
                    ? FileTypeIconPDF
                    : item.exportFiletype === ExportFiletype.PPTX
                      ? FileTypeIconPPTX
                      : FileTypeIconExcel
                }
                alt={
                  item.exportFiletype === ExportFiletype.PDF
                    ? "pdf"
                    : item.exportFiletype === ExportFiletype.PPTX
                      ? "pptx"
                      : "excel"
                }
              />
              <a>{item.filename}</a>
              {item.status === userReportExportStatuses.Error && (
                <PillLabel color={LabelColor.Red}>
                  <i className="cr-icon-warning" />
                  Error producing report
                </PillLabel>
              )}
            </div>
          </XTableCell>,
          <XTableCell className={"requested-cell"} key={"requested"}>
            {moment(item.createdAt).format("HH:mm MMM DD, YYYY")}
          </XTableCell>,
        ],
      };
    });
  };

  render() {
    return (
      <>
        <XTable
          className={"report-schedule-sub-list"}
          rows={this.getRows(this.props.exports)}
          columnHeaders={this.columnHeaders}
          iconOptions
          loading={this.props.loading}
          selectable
          onSelectClick={this.props.onSelect}
          onSelectAllClick={() => this.selectAll(true)}
          onSelectNoneClick={() => this.selectAll(false)}
          onSelectToggle={(select) => this.selectAll(select)}
        />
      </>
    );
  }
}

interface IReportScheduleListProps {
  loading: boolean;
  scheduledExports: DisplayableExportSchedule[];
  exportsForSchedule: {
    [key: number]: ExportItem[];
  };
  orgName?: string;
  onDownloadItem: (item: ExportItem) => void;
  onArchiveItems: (items: ExportItem[]) => void;
  onDeleteItems: (items: ExportItem[]) => void;
  selectedIds: number[];
  onSelectAll: (select: boolean, ids: number[]) => void;
  onSelect: (id: string | number, newSelectedState: boolean) => void;
  toggleRow: (rowId: string | number) => void;
  expandedRows: { [rowId: string]: true | undefined };
  sortedBy: ISortedBy;
  onSortChange: (columnId: string, direction: SortDirection) => void;
  onClickRow: (item: DisplayableExportSchedule) => void;
  pagination: {
    currentPage: number;
    totalPages: number;
    onPageChange: (newPage: number) => void;
  };
  assuranceType: AssuranceType;
}

interface IReportScheduleListState {
  filterText: string;
}

export default class ReportScheduleList extends Component<
  IReportScheduleListProps,
  IReportScheduleListState
> {
  constructor(props: IReportScheduleListProps) {
    super(props);

    this.state = {
      filterText: "",
    };
  }

  columnHeaders: IXTableColumnHeader[] = [
    {
      id: "report",
      text: "Report",
      sortable: true,
      startingSortDir: SortDirection.ASC,
    },
    {
      id: "org",
      text: "Organization",
      sortable: true,
      startingSortDir: SortDirection.ASC,
    },
    {
      id: "frequency",
      text: "Frequency",
      sortable: true,
      startingSortDir: SortDirection.ASC,
    },
    {
      id: "next",
      text: "Next scheduled",
      sortable: true,
      startingSortDir: SortDirection.ASC,
    },
    {
      id: "last",
      text: "Last generated",
      sortable: false,
    },
  ];

  getRows = (): IXTableRow[] => {
    return this.props.scheduledExports.map((item) => {
      const vendorName = _.get(
        item.exportMeta,
        "vendorName",
        this.props.orgName
      );

      const { reportName } = getReportMeta(item, this.props.assuranceType);
      const nextRun = moment(item.nextRun);
      const date = nextRun.format("HH:mm MMM DD, YYYY");
      const expanded = !!this.props.expandedRows[item.exportScheduleID];
      let expandContent = null;
      const nextRunText =
        nextRun.diff(moment()) < 0 ? "within the next hour" : `at ${date}`;

      const iconOptions: IIconOption[] = [];

      iconOptions.push({
        id: "edit",
        hoverText: "Edit schedule",
        icon: <i className="cr-icon-pencil" />,
        onClick: () => this.props.onClickRow(item),
      });

      const exports = _.get(
        this.props.exportsForSchedule,
        item.exportScheduleID,
        []
      );

      if (expanded) {
        expandContent =
          exports.length > 0 ? (
            <ReportScheduleSubList
              exports={exports}
              onArchiveItems={this.props.onArchiveItems}
              onDeleteItems={this.props.onDeleteItems}
              onDownloadItem={this.props.onDownloadItem}
              onSelectAll={this.props.onSelectAll}
              onSelect={this.props.onSelect}
              selectedIds={this.props.selectedIds}
              loading={this.props.loading}
            />
          ) : (
            <EmptyCardWithAction
              emptyText={"This scheduled report has has not run yet"}
              emptySubText={
                <>
                  {`This scheduled report has been recently set up and has not run yet. `}
                  <br />
                  {`The first scheduled report will be generated ${nextRunText}.`}
                </>
              }
              iconSrc={ClockRefresh}
            />
          );
      }

      const completedExports = exports.filter((e) => !!e.completedAt);
      const lastCompletedExport =
        completedExports.length === 0
          ? null
          : completedExports.sort((a, b) =>
              moment(a.createdAt).isAfter(moment(b.createdAt)) ? -1 : 1
            )[0];

      return {
        id: item.exportScheduleID,
        expanded,
        expandContent,
        iconOptions,
        onClick: () => this.props.onClickRow(item),
        cells: [
          <XTableCell key={"report"} className={"report-cell"}>
            <div>
              <img
                className={"file-icon"}
                src={
                  item.exportFileType === ExportFiletype.PDF
                    ? FileTypeIconPDF
                    : item.exportFileType === ExportFiletype.PPTX
                      ? FileTypeIconPPTX
                      : FileTypeIconExcel
                }
                alt={
                  item.exportFileType === ExportFiletype.PDF
                    ? "pdf"
                    : item.exportFileType === ExportFiletype.PPTX
                      ? "pptx"
                      : "excel"
                }
              />
              <h3>{reportName}</h3>
            </div>
          </XTableCell>,
          <XTableCell key={"org"} className={"org-cell"}>
            <p>{vendorName}</p>
          </XTableCell>,
          <XTableCell key={"frequency"} className={"frequency-cell"}>
            <div>
              <p>{IntervalToName(item.interval)}</p>
            </div>
          </XTableCell>,
          <XTableCell key={"next"} className={"next-cell"}>
            <div>
              <p>{date}</p>
            </div>
          </XTableCell>,
          <XTableCell key={"last"} className={"last-cell"}>
            {lastCompletedExport !== null && (
              <span
                onClick={(e) => {
                  e.stopPropagation();
                  this.props.onDownloadItem(lastCompletedExport);
                }}
              >
                {moment(lastCompletedExport.completedAt).format(
                  "HH:mm MMM DD, YYYY"
                )}
              </span>
            )}
          </XTableCell>,
        ],
      };
    });
  };

  render() {
    return (
      <XTable
        className={"report-schedule-list"}
        rows={this.getRows()}
        columnHeaders={this.columnHeaders}
        expandableRows
        onExpandToggle={this.props.toggleRow}
        loading={this.props.loading}
        sortedBy={this.props.sortedBy}
        onSortChange={this.props.onSortChange}
        iconOptions
        pagination={
          this.props.pagination.totalPages > 1
            ? this.props.pagination
            : undefined
        }
      />
    );
  }
}
