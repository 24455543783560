import { FC } from "react";
import RiskUserSelection from "../RiskUserSelection";

interface ISelectUsersStepProps {
  riskId: string;
  selectedUserUUIDs: string[];
  onSelectionChange: (selectedUUIDs: string[]) => void;
}

const SelectUsersStep: FC<ISelectUsersStepProps> = ({
  riskId,
  selectedUserUUIDs,
  onSelectionChange,
}) => {
  return (
    <RiskUserSelection
      riskId={riskId}
      selectedUserUUIDs={selectedUserUUIDs}
      onSelectionChange={onSelectionChange}
      headerTitle={"Select users to remediate"}
      className={"userrisk-remediation"}
    />
  );
};

export default SelectUsersStep;
