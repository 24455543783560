import { DefaultThunkDispatchProp } from "../../_common/types/redux";
import {
  AnswersForNodes,
  NodeSummaryAndNode,
  SectionNodeAnswerState,
  SelectAnswers,
  VisiblityForNodes,
} from "../surveyViewer.helpers";
import ActionBar from "../../_common/components/ActionBar";
import Button, { TooltipButton } from "../../_common/components/core/Button";
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { SurveyLockState, updateSurveyAnswers } from "../reducers/apiActions";
import {
  addDefaultSuccessAlert,
  addDefaultUnknownErrorAlert,
} from "../../_common/reducers/messageAlerts.actions";
import ConfirmationModalV2 from "../../_common/components/modals/ConfirmationModalV2";
import QuestionnaireProgressBar from "./QuestionnaireProgressBar";
import IncompleteSubmissionModal from "./modals/IncompleteSubmissionModal";
import {
  setCurrentCommentQuestion,
  setScrollTargetNode,
} from "../reducers/actions";
import classnames from "classnames";

import InfoBanner, { BannerType } from "../../vendorrisk/components/InfoBanner";
import { SurveyUsageType } from "../../_common/types/surveyTypes";
import { countUnreadMessages } from "../../_common/components/correspondence/correspondence.helpers";
import { surveyViewerRightPanelMode } from "../reducers/reducer";
import { SurveyViewerType } from "./SurveyViewer";
import ToggleSwitch from "../../_common/components/core/ToggleSwitch";
import { trackEvent } from "../../_common/tracking";
import { Answers } from "../../vendorrisk/reducers/questionnaireAnswers.actions";
import ExcelUploadModal from "./modals/ExcelUploadModal";
import {
  ISurveyVersion,
  SurveyRiskVisibility,
} from "../../_common/types/survey";
import { IAutomationRecipe } from "../../vendorrisk/types/automation";
import SubmittedModal from "./modals/SubmittedModal";
import {
  fetchOwnSharedAssessment,
  fetchOwnSharedAssessmentAccess,
  setAutomationTestResults,
  setPrefilledSurveyPublished,
} from "../../vendorrisk/reducers/cyberRiskActions";
import { fetchAutomationRecipe } from "../../vendorrisk/reducers/questionnaireAutomation.actions";
import { executeAutomationOnSurveyInstance } from "../../vendorrisk/reducers/questionnaireAutomationTest.actions";
import useSurveyViewerTipsModal from "./modals/SurveyViewerTipsModal";
import { useModalV2 } from "../../_common/components/ModalV2";
import SurveyViewerToolsModal from "./modals/SurveyViewerToolsModal";
import {
  GptAutofillCacheStatus,
  markAllGptSuggestionsAsUsed,
} from "../reducers/autofill.actions";
// @ts-ignore
import Coffee from "../images/coffeecup-animated.gif";
import { throttle } from "lodash";
import { appConnect } from "../../_common/types/reduxHooks";
import { downloadSurveyImportExcelFile } from "../../vendor_portal/api/surveyImportDownload";
import SendQuestionnaireToVendorModal from "./modals/SendQuestionnaireToVendorModal";
import { IVendorContactResponse } from "../../_common/types/vendorContact";
import { ContactDisplayExisting } from "../../vendorrisk/components/contacts/ContactSelect";
import {
  BulkContactInfo,
  getSurveyListV2,
  sendExistingSurvey,
  SendExistingSurveyReq,
} from "../../vendorrisk/reducers/survey.actions";
import { fetchSurveyDetails } from "../../_common/reducers/surveyDetails.actions";
import { fetchPrefilledSurveyDetails } from "../../_common/reducers/commonActions";
import { useManagedOrgID } from "../../_common/hooks";

interface SurveyViewerActionBarOwnProps {
  orgName: string;
  vendorId?: number;
  vendorName?: string;
  vendorContacts?: IVendorContactResponse[];
  surveyId: number;
  nodeTree: NodeSummaryAndNode;
  viewerType: SurveyViewerType;
  onClose: (addedToSharedProfile?: boolean) => void;
  isPublicSurvey: boolean;
  usageType: SurveyUsageType;
  shouldShowComments: boolean;
  onCompare?: () => void; // if undefined assume that we can't compare versions and don't sure button
  redlinesEnabled: boolean; // dev toggle
  changedAnswersOnly: boolean;
  setChangedAnswersOnly: (val: boolean) => void;
  versionLoading: boolean;
  diffLoading: boolean;
  setAnswersFromUpload: (answers: Answers) => void;
  surveyLatestVersion?: ISurveyVersion;
  shouldDisplayChatGptFeatures: boolean;
  automation?: IAutomationRecipe;
  automationRunAll?: boolean;
  isAssessmentDraft?: boolean;
  previewTypeId?: string;
  onExitAutofillLoading?: () => void;
  onOpenGptAutofillModal: () => void;
  toolsModalOpen: boolean;
  setToolsModalOpen: (val: boolean) => void;
  surveyImportUUID?: string;
  managedAssessmentId?: number;
}

interface SurveyViewerActionBarConnectedProps {
  answers: AnswersForNodes;
  rootNodeAnswerState: SectionNodeAnswerState;
  leftRootNodeAnswerState: SectionNodeAnswerState;
  nodeVisibilities: VisiblityForNodes;
  lockState: SurveyLockState;
  isDraftSavePending: boolean;
  rightPanelActive: boolean;
  isShowingComments: boolean;
  unreadCommentCount: number;
  isEdited: boolean;
  totalAnswersChanged: number;
  testExecuting?: boolean;
  answersPendingSave?: AnswersForNodes;
  gptAutofillCache?: GptAutofillCacheStatus;
}

type SurveyViewerActionBarProps = SurveyViewerActionBarOwnProps &
  SurveyViewerActionBarConnectedProps &
  DefaultThunkDispatchProp;

const SurveyViewerActionBar = (props: SurveyViewerActionBarProps) => {
  const [isSaving, setIsSaving] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [isShowingSubmittedModal, setIsShowingSubmittedModal] = useState(false);

  const [isShowingConfirmSubmit, setIsShowingConfirmSubmission] =
    useState(false);
  const [isShowingConfirmEmptySubmit, setIsShowingConfirmEmptySubmit] =
    useState(false);
  const [
    isShowingConfirmIncompleteSubmit,
    setIsShowingConfirmIncompleteSubmit,
  ] = useState(false);

  const managedOrgID = useManagedOrgID();

  // support for sending the questionnaire to the vendor (customer/analyst is editing suggested answers prior to sending)
  const [openSendModal, sendModal] = useModalV2(SendQuestionnaireToVendorModal);

  const [
    isShowingConfirmQuestionnaireSend,
    setIsShowingConfirmQuestionnaireSend,
  ] = useState(false);

  const [isLowWidth, setIsLowWidth] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  useLayoutEffect(() => {
    let observer: ResizeObserver;
    if (ref.current) {
      const observeFunc = throttle((entries: ResizeObserverEntry[]) => {
        const width = entries[0].contentRect.width;
        setIsLowWidth(width < 870);
      }, 50);

      observer = new ResizeObserver(observeFunc);
      observer.observe(ref.current);
    }

    return () => {
      if (observer) {
        observer.disconnect();
      }
    };
  }, []);

  const shouldDisable = !props.surveyId;

  // Use a ref so we can refer to this in the timeout callback
  const isDraftAutoSavingRef = useRef(props.isDraftSavePending);
  useEffect(() => {
    isDraftAutoSavingRef.current = props.isDraftSavePending;
  }, [props.isDraftSavePending]);

  // Recurse until auto-save is completed
  const waitForDraftSave = async (): Promise<any> => {
    if (isDraftAutoSavingRef.current) {
      // Delay until auto-save draft saved
      await new Promise((r) => setTimeout(r, 2000));
      return waitForDraftSave();
    } else {
      return;
    }
  };

  const exportImportedSurvey = useCallback(async () => {
    if (!props.surveyImportUUID) {
      return;
    }

    await props.dispatch(
      downloadSurveyImportExcelFile(props.surveyImportUUID, true)
    );
  }, [props.surveyImportUUID, props.dispatch]);

  const submitQuestionnaire = useCallback(async () => {
    setIsSubmitting(true);

    await waitForDraftSave();

    try {
      let answers = props.answers;
      // if we have pending autofill responses then we need to accept all our unaccepted suggestions first
      // we need to use the answers returned by this otherwise the answers from props will override them immediately
      const numPendingSuggestions = Object.values(
        props.gptAutofillCache?.suggestions ?? {}
      ).filter((s) => !s.used && !s.rejected && !s.noSuggestion).length;

      if (props.gptAutofillCache && numPendingSuggestions > 0) {
        trackEvent("SurveyViewer/submitWithPendingSuggestions", {
          numPendingSuggestions,
        });
        answers = await props.dispatch(
          markAllGptSuggestionsAsUsed(props.surveyId, props.isPublicSurvey)
        );
      }

      await props.dispatch(
        updateSurveyAnswers(
          props.surveyId,
          answers,
          true,
          false,
          props.isPublicSurvey
        )
      );

      if (props.isPublicSurvey) {
        await props.dispatch(setPrefilledSurveyPublished(props.surveyId, true));
        await Promise.all([
          props.dispatch(fetchOwnSharedAssessmentAccess(true)),
          props.dispatch(fetchOwnSharedAssessment(true)),
        ]);
        trackEvent("VerifiedVendor_PublishQuestionnaire");
      }

      // If we've saved an imported survey, we now want to immediately export it
      if (props.surveyImportUUID) {
        await exportImportedSurvey();
      }

      setIsShowingSubmittedModal(true);
    } catch (e) {
      props.dispatch(
        addDefaultUnknownErrorAlert("Error submitting questionnaire")
      );
      setIsSubmitting(false);
    }
  }, [
    props.gptAutofillCache,
    props.dispatch,
    props.surveyId,
    props.answers,
    props.isPublicSurvey,
    props.onClose,
    props.surveyImportUUID,
    exportImportedSurvey,
  ]);

  const sendQuestionnaire = useCallback(
    async (
      selectedVendorContacts: ContactDisplayExisting[],
      dueDate: string,
      reminderDate: string,
      message: string,
      subject: string,
      riskVisibility: SurveyRiskVisibility
    ) => {
      setIsSending(true);
      await waitForDraftSave();

      try {
        let answers = props.answers;
        // if we have pending autofill responses then we need to accept all our unaccepted suggestions first
        // we need to use the answers returned by this otherwise the answers from props will override them immediately
        const numPendingSuggestions = Object.values(
          props.gptAutofillCache?.suggestions ?? {}
        ).filter((s) => !s.used && !s.rejected && !s.noSuggestion).length;

        if (props.gptAutofillCache && numPendingSuggestions > 0) {
          answers = await props.dispatch(
            markAllGptSuggestionsAsUsed(props.surveyId, props.isPublicSurvey)
          );
        }

        // apply all changes to the survey answers
        await props.dispatch(
          updateSurveyAnswers(
            props.surveyId,
            answers,
            false,
            false,
            props.isPublicSurvey,
            true
          )
        );

        // grab our vendor contacts

        const contacts: BulkContactInfo = {
          vendorContacts: [],
        };
        selectedVendorContacts.map((contact) => {
          contacts.vendorContacts.push({
            name: contact.name,
            email: contact.emailAddress,
            jobTitle: contact.title,
          });
        });

        // now send the sucker
        const req: SendExistingSurveyReq = {
          emailText: message,
          emailSubject: subject,
          dueDate: dueDate,
          recipientReminderDate: !!reminderDate ? reminderDate : undefined,
          resendDate: undefined,
          riskVisibility: riskVisibility,
          existingSurveyId: props.surveyId,
          contacts: contacts,
          isPrefilledAnalystSurvey: true,
        };
        await props.dispatch(
          sendExistingSurvey(
            req,
            props.managedAssessmentId ?? 0,
            props.vendorId ?? 0
          )
        );
        setIsShowingConfirmQuestionnaireSend(true);
      } catch (e) {
        props.dispatch(
          addDefaultUnknownErrorAlert("Error sending questionnaire")
        );
      } finally {
        setIsSending(false);
      }
    },
    [
      props.gptAutofillCache,
      props.dispatch,
      props.surveyId,
      props.answers,
      props.isPublicSurvey,
      props.onClose,
      props.surveyImportUUID,
      exportImportedSurvey,
    ]
  );

  const saveDraft = useCallback(async () => {
    setIsSaving(true);

    await waitForDraftSave();

    try {
      await props.dispatch(
        updateSurveyAnswers(
          props.surveyId,
          props.answers,
          false,
          false,
          props.isPublicSurvey
        )
      );

      if (props.isPublicSurvey) {
        await props.dispatch(
          fetchPrefilledSurveyDetails(props.surveyId, false, true)
        );
      } else {
        await props.dispatch(fetchSurveyDetails(props.surveyId, true));

        if (!!props.vendorId && managedOrgID) {
          await props.dispatch(getSurveyListV2(true, props.vendorId));
        }
      }

      props.dispatch(addDefaultSuccessAlert("Questionnaire draft saved"));
      props.onClose();
    } catch (e) {
      props.dispatch(addDefaultUnknownErrorAlert("Error saving questionnaire"));
      setIsSaving(false);
    }
  }, [
    props.dispatch,
    props.surveyId,
    props.answers,
    props.isPublicSurvey,
    props.onClose,
  ]);

  const classes = classnames("survey-viewer-action-bar", {
    "is-right-panel-active": props.rightPanelActive,
    "diff-mode": props.viewerType == SurveyViewerType.Diff,
    // "gpt-overlay": props.viewerType == SurveyViewerType.AutofillLoading,
  });

  const messagesButton =
    props.shouldShowComments &&
    props.viewerType != SurveyViewerType.Diff &&
    // Don't show messages for imported surveys
    !props.surveyImportUUID ? (
      <TooltipButton
        tooltipContent={isLowWidth ? "Messages" : undefined}
        unreadCount={props.unreadCommentCount}
        popupNoWrap
        onClick={() =>
          props.dispatch(
            setCurrentCommentQuestion(undefined, !props.isShowingComments)
          )
        }
        iconOnly={isLowWidth}
      >
        <i className={classnames("cr-icon-chat-messages")} />
        {!isLowWidth ? "Messages" : undefined}
      </TooltipButton>
    ) : (
      <></>
    );

  const executeAutomation = async () => {
    if (!!props.automation || props.automationRunAll) {
      try {
        props.dispatch(
          setAutomationTestResults(
            parseInt(props.previewTypeId || "", 10),
            props.surveyId || 0,
            true,
            null,
            null
          )
        );
        let recipe: IAutomationRecipe | undefined = undefined;
        if (props.automation) {
          recipe = await props.dispatch(
            fetchAutomationRecipe(props.automation?.uuid, true)
          );
        }

        // collect up the leaves of the answers structure
        const answers = {} as { [key: string]: string };
        if (!!props.answersPendingSave) {
          Object.keys(props.answersPendingSave).map((key) => {
            if (props.answersPendingSave?.[key]) {
              switch (typeof props.answersPendingSave[key]) {
                case "string":
                  const value = props.answersPendingSave[key]?.toString();
                  if (value && value.length > 0) {
                    answers[key] = value;
                  }
                  break;
                default:
                  // SelectAnswers
                  const subAnswers = props.answersPendingSave[
                    key
                  ] as SelectAnswers;
                  Object.keys(subAnswers).map((subKey) => {
                    const value = subAnswers[subKey]?.toString();
                    if (value && value.length > 0) {
                      answers[subKey] = value;
                    }
                  });
              }
            }
          });
          const ansStr = JSON.stringify(answers);
          await props.dispatch(
            executeAutomationOnSurveyInstance(
              recipe,
              parseInt(props.previewTypeId || "", 10),
              props.surveyId || 0,
              ansStr,
              props.isAssessmentDraft || false,
              props.automationRunAll
            )
          );
        }
      } catch (e) {
        props.dispatch(
          addDefaultUnknownErrorAlert(`failed to execute automation rule: ${e}`)
        );
        props.dispatch(
          setAutomationTestResults(
            parseInt(props.previewTypeId || "", 10),
            props.surveyId || 0,
            false,
            e,
            null
          )
        );
      }
    }
  };

  const onSubmit = useCallback(() => {
    if (
      props.rootNodeAnswerState.suggested +
        props.rootNodeAnswerState.optionalSuggested >
      0
    ) {
      // Always show the confirm incomplete submit modal when any suggestions are pending
      setIsShowingConfirmIncompleteSubmit(true);
      return;
    }

    if (props.surveyImportUUID) {
      // For imported surveys, we don't need to confirm submission, just submit and export.
      submitQuestionnaire();
    } else if (
      props.surveyLatestVersion &&
      props.surveyLatestVersion.shared &&
      !props.isEdited
    ) {
      // firstly, check whether the user is attempting to submit a set of answers that haven't changed since the last submission.
      // this means a) the latest version of the answers have submitted set to true, and the user has not made any edits this session.
      setIsShowingConfirmEmptySubmit(true);
    } else if (
      props.rootNodeAnswerState.questionCount ===
      props.rootNodeAnswerState.answeredCount
    ) {
      setIsShowingConfirmSubmission(true);
    } else {
      setIsShowingConfirmIncompleteSubmit(true);
    }
  }, [
    props.isEdited,
    props.rootNodeAnswerState,
    props.surveyImportUUID,
    props.surveyLatestVersion,
    submitQuestionnaire,
  ]);

  const onSendToVendor = useCallback(() => {
    // Always show the confirm incomplete submit modal when any suggestions are pending
    openSendModal({
      vendorName: props.vendorName,
      rootNodeAnswerState: props.rootNodeAnswerState,
      nodeTree: props.nodeTree,
      answers: props.answers,
      nodeVisibilities: props.nodeVisibilities,
      onSubmit: sendQuestionnaire,
      onGoToQuestion: (nodeId) =>
        props.dispatch(setScrollTargetNode(nodeId, true)),
      suggestions: props.gptAutofillCache?.suggestions,
      vendorContacts: props.vendorContacts,
    });
    return;
  }, [
    props.vendorContacts,
    props.vendorName,
    props.rootNodeAnswerState,
    props.nodeTree,
    props.answers,
    props.nodeVisibilities,
    props.gptAutofillCache,
    sendQuestionnaire,
  ]);

  const [openExcelUploadModal, excelUploadModal] = useModalV2(ExcelUploadModal);
  const onOpenExcelUploadModal = useCallback(
    (alreadyExported: boolean) =>
      openExcelUploadModal({
        alreadyExported,
        hasAnswers: props.rootNodeAnswerState.answeredCount > 0,
        surveyID: props.surveyId,
        isPublic: props.isPublicSurvey,
        onSuccess: props.setAnswersFromUpload,
        onReviewClick: onSubmit,
        senderOrgName: props.orgName,
      }),
    [
      onSubmit,
      openExcelUploadModal,
      props.isPublicSurvey,
      props.orgName,
      props.rootNodeAnswerState.answeredCount,
      props.setAnswersFromUpload,
      props.surveyId,
    ]
  );

  const [openTipsModal, tipsModal] = useSurveyViewerTipsModal(
    props.orgName,
    props.usageType,
    !!props.surveyImportUUID
  );

  return (
    <ActionBar active={true} className={classes} noMaxWidth>
      <div className={"action-bar-inner"} ref={ref}>
        <div className={"left-content"}>
          {props.viewerType == SurveyViewerType.Diff ? (
            <div className={"changed-toggle"}>
              <ToggleSwitch
                name={"changed_only"}
                onClick={() => {
                  if (!props.changedAnswersOnly) {
                    trackEvent("QuestionnareVersionChangesOnly");
                  }

                  props.setChangedAnswersOnly(!props.changedAnswersOnly);
                }}
                selected={props.changedAnswersOnly}
              />
              {`Show changed responses only (${props.totalAnswersChanged})`}
            </div>
          ) : (
            <QuestionnaireProgressBar
              rootNodeAnswerState={props.rootNodeAnswerState}
            />
          )}
          {props.viewerType == SurveyViewerType.Editing && (
            <div className={"left-buttons"}>
              {props.viewerType === SurveyViewerType.Editing &&
                props.usageType == SurveyUsageType.Security &&
                !props.lockState.isLocked && (
                  <TooltipButton
                    tooltipContent={
                      "Answer this questionnaire faster using AI Autofill and other tools."
                    }
                    onClick={() => props.setToolsModalOpen(true)}
                    iconOnly={isLowWidth}
                  >
                    <i className={"cr-icon-tool"} />
                    {!isLowWidth ? "Tools" : undefined}
                  </TooltipButton>
                )}
              {props.viewerType === SurveyViewerType.Editing &&
                !props.lockState.isLocked && (
                  <TooltipButton
                    tooltipContent={
                      "First time here? Explore our handy tips for answering a questionnaire."
                    }
                    onClick={openTipsModal}
                    iconOnly={isLowWidth}
                  >
                    <i className={"cr-icon-bulb"} />
                    {!isLowWidth ? "Tips" : undefined}
                  </TooltipButton>
                )}
            </div>
          )}
          {/*{!props.lockState.isLocked &&*/}
          {/*  props.viewerType == SurveyViewerType.Editing &&*/}
          {/*  props.usageType === SurveyUsageType.Security &&*/}
          {/*  props.surveyImportExportEnabled && (*/}
          {/*    <div className={"upload-button-container"}>*/}
          {/*      <IconButton*/}
          {/*        className={"upload-btn"}*/}
          {/*        hoverText={"Import answers"}*/}
          {/*        hoverLocation={HoverLocation.Top}*/}
          {/*        icon={*/}
          {/*          <>*/}
          {/*            <Dot color="green" />*/}
          {/*            <div className={"cr-icon-upload"} />*/}
          {/*          </>*/}
          {/*        }*/}
          {/*        onClick={() => props.setUploadModalOpen(true)}*/}
          {/*      />*/}
          {/*    </div>*/}
          {/*  )}*/}
        </div>
        <div className={"middle-content"}>
          {props.viewerType == SurveyViewerType.Diff && (
            <QuestionnaireProgressBar
              rootNodeAnswerState={props.leftRootNodeAnswerState}
            />
          )}
        </div>
        <div className={"right-content"}>
          {/*<Button*/}
          {/*  tertiary*/}
          {/*  disabled={shouldDisable || isSaving || isSubmitting}*/}
          {/*  onClick={() => {}}*/}
          {/*>*/}
          {/* TODO + Invite collaborator*/}
          {/*</Button>*/}
          {props.viewerType == SurveyViewerType.Diff && (
            <QuestionnaireProgressBar
              rootNodeAnswerState={props.rootNodeAnswerState}
            />
          )}
          {props.onCompare &&
            props.redlinesEnabled &&
            props.viewerType != SurveyViewerType.Diff && (
              <Button
                loading={props.diffLoading}
                disabled={props.versionLoading}
                onClick={props.onCompare}
              >
                <i className={"cr-icon-compare"} /> Show changes
              </Button>
            )}
          {!props.lockState.isLocked &&
            props.viewerType != SurveyViewerType.AutofillLoading && (
              <>
                {messagesButton}
                {props.isEdited &&
                  !props.automation &&
                  !props.automationRunAll && (
                    <TooltipButton
                      tooltipContent={isLowWidth ? "Save draft" : undefined}
                      loading={isSaving}
                      disabled={shouldDisable || isSubmitting}
                      onClick={saveDraft}
                      popupNoWrap
                      iconOnly={isLowWidth}
                    >
                      <span className={"cr-icon-save"} />
                      {!isLowWidth ? "Save draft" : undefined}
                    </TooltipButton>
                  )}
                {props.viewerType == SurveyViewerType.Preview &&
                  (props.automation || props.automationRunAll) && (
                    <Button
                      filledPrimary
                      onClick={executeAutomation}
                      loading={props.testExecuting}
                    >
                      <i className={"cr-icon-play"} /> Run test
                    </Button>
                  )}
                {props.viewerType == SurveyViewerType.Editing ? (
                  <>
                    {props.surveyImportUUID ? (
                      <Button
                        loading={isSubmitting}
                        disabled={shouldDisable || isSaving}
                        onClick={onSubmit}
                      >
                        <i className={"cr-icon-export-thin"} />
                        Export
                      </Button>
                    ) : (
                      <Button
                        loading={isSubmitting}
                        disabled={shouldDisable || isSaving}
                        filledPrimary
                        onClick={onSubmit}
                      >
                        <i className={"cr-icon-check"} />
                        Submit
                      </Button>
                    )}
                  </>
                ) : props.viewerType == SurveyViewerType.Review ? (
                  <Button
                    arrow
                    loading={isSending}
                    disabled={shouldDisable || isSaving}
                    filledPrimary
                    onClick={onSendToVendor}
                  >
                    {/*<i className={"cr-icon-mail"} />*/}
                    Send questionnaire
                  </Button>
                ) : props.viewerType === SurveyViewerType.Viewing &&
                  props.surveyImportUUID ? (
                  // Allow read-only users to export imported surveys when in viewing mode
                  <>
                    <Button onClick={exportImportedSurvey}>
                      <i className={"cr-icon-export-thin"} />
                      Export
                    </Button>
                  </>
                ) : undefined}
              </>
            )}
          {props.lockState.isLocked && (
            <div className={"locked-container"}>
              <InfoBanner
                type={BannerType.WARNING}
                message={`Currently locked by ${props.lockState.lockedBy}`}
              />
              {messagesButton}
              <Button onClick={() => props.onClose(false)}>Close</Button>
            </div>
          )}
        </div>
        <ConfirmationModalV2
          title={"Are you ready to submit this questionnaire?"}
          description={
            props.isPublicSurvey
              ? `This will publish your responses to your Trust Page. Please ensure you have finished editing this questionnaire before submitting.`
              : `This will share your responses with ${props.orgName}. Please ensure you have finished editing this questionnaire before submitting.`
          }
          buttonText={"Submit questionnaire"}
          buttonAction={submitQuestionnaire}
          active={isShowingConfirmSubmit}
          onClose={() => setIsShowingConfirmSubmission(false)}
        />
        <ConfirmationModalV2
          dangerousAction
          title={"You haven't made any changes to this questionnaire"}
          description={
            <>
              <p>
                Your responses haven&apos;t changed from the last time you
                submitted this questionnaire. Are you sure you would like to
                continue?
              </p>
              {!props.isPublicSurvey && !props.surveyImportUUID && (
                <p>
                  By continuing, a new version will be created, the sender (
                  {props.orgName}) will be notified, and the status will change
                  to &apos;Awaiting Review&apos;.
                </p>
              )}
            </>
          }
          buttonText={"Continue"}
          buttonAction={() => setIsShowingConfirmIncompleteSubmit(true)}
          active={isShowingConfirmEmptySubmit}
          onClose={() => setIsShowingConfirmEmptySubmit(false)}
        />
        <SubmittedModal
          active={isShowingSubmittedModal}
          onDone={props.onClose}
          isImportedSurvey={!!props.surveyImportUUID}
          surveyType={props.usageType}
        />
        <SubmittedModal
          active={isShowingConfirmQuestionnaireSend}
          onDone={props.onClose}
          isImportedSurvey={false}
          surveyType={props.usageType}
          alternateTitle={"Questionnaire sent"}
          alternateText={
            "Your questionnaire has been successfully sent to the vendor"
          }
        />
        <IncompleteSubmissionModal
          orgName={props.orgName}
          vendorName={props.vendorName}
          rootNodeAnswerState={props.rootNodeAnswerState}
          nodeTree={props.nodeTree}
          answers={props.answers}
          nodeVisibilities={props.nodeVisibilities}
          usageType={props.usageType}
          onSubmit={submitQuestionnaire}
          active={isShowingConfirmIncompleteSubmit}
          onClose={() => setIsShowingConfirmIncompleteSubmit(false)}
          onGoToQuestion={(nodeId) =>
            props.dispatch(setScrollTargetNode(nodeId, true))
          }
          suggestions={props.gptAutofillCache?.suggestions}
          isPublicSurvey={props.isPublicSurvey}
          surveyImportUUID={props.surveyImportUUID}
        />
        {excelUploadModal}
        {tipsModal}
        {sendModal}
        <SurveyViewerToolsModal
          shouldDisplayChatGPT={props.shouldDisplayChatGptFeatures}
          onClickAutofill={props.onOpenGptAutofillModal}
          onClickExport={() => onOpenExcelUploadModal(false)}
          onClickImport={() => onOpenExcelUploadModal(true)}
          active={props.toolsModalOpen}
          onClose={() => props.setToolsModalOpen(false)}
          isImportedSurvey={!!props.surveyImportUUID}
        />
      </div>
    </ActionBar>
  );
};

export default appConnect<
  SurveyViewerActionBarConnectedProps,
  never,
  SurveyViewerActionBarOwnProps
>((state, props) => {
  let unreadCommentCount = 0;

  for (const groupId in state.surveyViewer.questionComments) {
    unreadCommentCount =
      unreadCommentCount +
      countUnreadMessages(state.surveyViewer.questionComments[groupId]);
  }

  const surveyTypeId = props.automation?.surveyTypeId || 0;
  const testResults =
    state.cyberRisk.automatedTestResults?.[surveyTypeId]?.[props.surveyId];

  return {
    answers: state.surveyViewer.answers,
    rootNodeAnswerState:
      state.surveyViewer.nodeChildrenAnswered[props.nodeTree.nodeId],
    leftRootNodeAnswerState:
      state.surveyViewer.leftNodeChildrenAnswered[props.nodeTree.nodeId],
    nodeVisibilities: state.surveyViewer.nodeVisibilities,
    lockState: state.surveyViewer.lock,
    isDraftSavePending:
      Object.keys(state.surveyViewer.answersPendingSaving).length > 0,
    rightPanelActive: state.surveyViewer.rightPanel.active,
    isShowingComments:
      state.surveyViewer.rightPanel.active &&
      state.surveyViewer.rightPanel.mode ===
        surveyViewerRightPanelMode.Comments,
    unreadCommentCount,
    isEdited: state.surveyViewer.isEdited,
    totalAnswersChanged: state.surveyViewer.answersChanged.totalChanged,
    testExecuting: testResults?.loading || false,
    answersPendingSave: state.surveyViewer.answersPendingSaving,
    gptAutofillCache: state.surveyViewer.gptAutofill,
  };
})(SurveyViewerActionBar);
