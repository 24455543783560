import { IVendorHierarchy } from "../../../_common/types/vendor";
import { DefaultThunkDispatchProp } from "../../../_common/types/redux";
import { FC, useEffect, useState } from "react";
import { fetchBreachsightSubsidiaries } from "../../reducers/breachsightSubsidiaries.actions";
import {
  OptionType,
  SelectV2Multi,
} from "../../../_common/components/SelectV2";
import { ValueType } from "react-select";
import { SlidePanelSection } from "./SlidePanelSection";
import LabelList from "../LabelList";
import { appConnect } from "../../../_common/types/reduxHooks";

export interface subsidiaryFilterSubsidiary {
  id: number;
  vendorId: number;
  name: string;
}

const extract = (hierarchy: IVendorHierarchy) => {
  const results: subsidiaryFilterSubsidiary[] = [];

  if (!hierarchy) {
    return results;
  }

  if (hierarchy.latestScore >= 0) {
    // Only add scored subsidiaries for filter purposes
    results.push({
      id: hierarchy.vendorId,
      vendorId: hierarchy.vendorId,
      name: hierarchy.name,
    });
  }

  if (hierarchy.children) {
    for (let i = 0; i < hierarchy.children.length; i++) {
      const sub = extract(hierarchy.children[i]);
      results.push(...sub);
    }
  }

  return results;
};

export const extractSubsidiaries = (
  hierarchy: IVendorHierarchy,
  includeMe = false
) => {
  const subs = extract(hierarchy);
  let root = null;
  if (!includeMe) {
    const rem = subs.splice(0, 1);
    root = rem[0];
  }

  subs.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));

  return { subs, root };
};

interface ISubsidiaryFilterOwnProps {
  selectedOptions?: number[];
  onChange: (opts: number[]) => void;
  includeRootVendor?: boolean;
  showRootVendorAsDefault?: boolean;
  title?: string;
  startExpanded?: boolean;
}

interface ISubsidiaryFilterConnectedProps {
  subsidiaries?: IVendorHierarchy;
  loading: boolean;
  error?: unknown;
}

type ISubsidiaryFilterProps = ISubsidiaryFilterOwnProps &
  ISubsidiaryFilterConnectedProps &
  DefaultThunkDispatchProp;

const SubsidiaryFilter: FC<ISubsidiaryFilterProps> = ({
  selectedOptions = [],
  onChange,
  subsidiaries,
  loading,
  error,
  includeRootVendor = false,
  showRootVendorAsDefault = false,
  dispatch,
  title = "Filter by organization",
  startExpanded = false,
}) => {
  const [expanded, setExpanded] = useState(
    startExpanded || selectedOptions.length > 0
  );
  const [defaultOptionForOnChange, setDefaultOptionForOnChange] = useState<
    number | undefined
  >(undefined);

  useEffect(() => {
    if (!error && !subsidiaries) {
      dispatch(fetchBreachsightSubsidiaries());
    } else if (subsidiaries) {
      const { root } = extractSubsidiaries(subsidiaries, includeRootVendor);

      // make a note of the root subsidiary if it isnt selectable. we're going to treat it as
      // a selection if anything else is selected.
      if (!includeRootVendor && showRootVendorAsDefault && !!root) {
        setDefaultOptionForOnChange(root.id);
      }
    }
  }, [
    subsidiaries,
    error,
    includeRootVendor,
    showRootVendorAsDefault,
    dispatch,
  ]);

  const selectOnChange = (selectedOptions: ValueType<OptionType, true>) => {
    const selected: number[] = selectedOptions
      ? selectedOptions.map(({ value }) => value as number)
      : [];

    // anything selected? if so, add the root subsidiary to the set (if it's not selectable)
    if (selected.length > 0 && !!defaultOptionForOnChange) {
      selected.push(defaultOptionForOnChange);
    }

    onChange(selected);
  };

  let options: OptionType[] = [];
  const selectValue: OptionType[] = [];
  const displayLabels: {
    id: number;
    name: string;
    color: string;
    removeable: boolean;
    large: boolean;
    constrained: boolean;
    onRemoveClick?: () => void;
  }[] = [];

  if (subsidiaries) {
    const { subs, root } = extractSubsidiaries(subsidiaries, includeRootVendor);

    options = subs.map(({ id, name }) => ({ value: id, label: name }));
    const defaultOption =
      !includeRootVendor && showRootVendorAsDefault && !!root
        ? { value: root.id, label: root.name }
        : null;

    // just in case the option does not exist for this iteration of the filter (sets can change)
    for (let i = 0; i < selectedOptions.length; i++) {
      for (let j = 0; j < options.length; j++) {
        if (options[j].value === selectedOptions[i]) {
          selectValue.push(options[j]);
          break;
        }
      }
    }

    if (selectValue.length > 0 && defaultOption) {
      displayLabels.push({
        id: defaultOption.value,
        name: defaultOption.label,
        color: "grey",
        removeable: false,
        large: true,
        constrained: true,
      });
    }

    displayLabels.push(
      ...selectValue.map(({ value, label }) => ({
        id: value as number,
        name: label,
        color: "blue",
        removeable: true,
        large: true,
        constrained: true,
        onRemoveClick: () =>
          selectOnChange(
            selectValue.filter((option) => value !== option.value)
          ),
      }))
    );
  }

  return (
    <SlidePanelSection
      title={title}
      expanded={expanded}
      toggleExpand={() => setExpanded(!expanded)}
    >
      <SelectV2Multi
        placeholder="Type to search subsidiaries"
        options={options}
        value={selectValue}
        onChange={selectOnChange}
        isLoading={loading}
        controlShouldRenderValue={false}
      />
      <LabelList labels={displayLabels} />
    </SlidePanelSection>
  );
};

export default appConnect<
  ISubsidiaryFilterConnectedProps,
  never,
  ISubsidiaryFilterOwnProps
>((state) => {
  return {
    loading:
      (state.cyberRisk.customerData as any).subsidiaries_loading || false,
    error: (state.cyberRisk.customerData as any).subsidiaries_error,
    subsidiaries: state.cyberRisk.customerData.subsidiaries,
  };
})(SubsidiaryFilter);
