import PillLabel, { getColorForKey } from "./PillLabel";
import PropTypes from "prop-types";

CPELabel.propTypes = {
  cpe: PropTypes.object.isRequired,
};
export function CPELabel({ cpe }) {
  return (
    <PillLabel
      key={cpe.title}
      color={getColorForKey(cpe.product)}
      bordered={true}
    >
      {cpe.title}
    </PillLabel>
  );
}
