import { useLayoutEffect, useRef } from "react";
import { DefaultThunkDispatchProp } from "../../_common/types/redux";
import { FlattenedNodeSummaryMap } from "../surveyViewer.helpers";
import { appConnect } from "../../_common/types/reduxHooks";

interface SurveyViewerSidebarScrollerOwnProps {
  // Ref of parent containing the .survey-viewer-sidebar element
  parentRef: React.RefObject<HTMLDivElement>;
  flattenedNodeMap: FlattenedNodeSummaryMap;
  sidebarCollapsed: boolean;
}

interface SurveyViewerSidebarScrollerConnectedProps {
  activeNodeId?: string;
  expandedNodeStates: { [nodeId: string]: boolean };
}

type SurveyViewerSidebarScrollerProps = SurveyViewerSidebarScrollerOwnProps &
  SurveyViewerSidebarScrollerConnectedProps &
  DefaultThunkDispatchProp;

const sidebarClass = ".survey-viewer-sidebar";
const sidebarItemClass = ".sidebar-item";

// NOTE: The filter part (65px) is not always shown but scrolling a little earlier is better than scrolling too late
const stickyHeaderHeight = 130;

// Component for triggering programmatic scrolling in the sidebar, seperated to reduce DOM tree re-renders.
const SurveyViewerSidebarScroller = (
  props: SurveyViewerSidebarScrollerProps
) => {
  // Keep a ref of where we last scrolled to, as we don't want to re-trigger if it doesn't change
  const activeNodeIdRef = useRef(props.activeNodeId);

  useLayoutEffect(() => {
    if (
      props.activeNodeId &&
      props.parentRef.current &&
      props.activeNodeId !== activeNodeIdRef.current &&
      !props.sidebarCollapsed
    ) {
      activeNodeIdRef.current = props.activeNodeId;
      // If any parent is collapsed, we need to find the lowest-order parent that is visible
      // So, recurse back up the chain to find the first parent in the chain that is collapsed
      const nodeToScrollTo = getSidebarNodeIdToScrollTo(
        props.flattenedNodeMap,
        props.activeNodeId,
        props.expandedNodeStates
      );

      const elem = props.parentRef.current.querySelector(
        `${sidebarItemClass}[data-node-id="${nodeToScrollTo}"]`
      );
      if (elem && props.parentRef && props.parentRef.current) {
        let scrollTo = (elem as any).offsetTop;

        const containerElem =
          props.parentRef.current.querySelector(sidebarClass);

        if (containerElem) {
          const movementOffset = containerElem.clientHeight / 2;
          const newScrollTo = scrollTo - movementOffset;

          if (scrollTo < containerElem.scrollTop + stickyHeaderHeight) {
            scrollTo = Math.max(newScrollTo, 0);
          } else if (
            scrollTo >
            containerElem.scrollTop +
              (containerElem.clientHeight - stickyHeaderHeight)
          ) {
            scrollTo = Math.min(newScrollTo, containerElem.scrollHeight);
          } else {
            scrollTo = undefined;
          }
        }

        if (scrollTo !== undefined) {
          containerElem?.scrollTo({
            top: scrollTo,
            left: 0,
            behavior: "smooth",
          });
        }
      }
    }
  }, [
    props.activeNodeId,
    props.flattenedNodeMap,
    props.expandedNodeStates,
    props.sidebarCollapsed,
  ]);

  return <></>;
};

export default appConnect<
  SurveyViewerSidebarScrollerConnectedProps,
  never,
  SurveyViewerSidebarScrollerOwnProps
>((state) => {
  return {
    activeNodeId: state.surveyViewer.activeNodeId,
    expandedNodeStates: state.surveyViewer.expandedNodes,
  };
})(SurveyViewerSidebarScroller);

const getSidebarNodeIdToScrollTo = (
  flattenedNodeMap: FlattenedNodeSummaryMap,
  activeNodeId: string,
  expandedNodeStates: { [nodeId: string]: boolean }
) => {
  let nodeIdToScrollTo = activeNodeId;
  const activeNode = flattenedNodeMap[activeNodeId];
  if (activeNode && activeNode.parentIdMap) {
    const parents = Object.keys(activeNode.parentIdMap);
    for (let i = parents.length - 1; i >= 0; i--) {
      if (!expandedNodeStates[parents[i]]) {
        nodeIdToScrollTo = parents[i];
      }
    }
  }

  return nodeIdToScrollTo;
};
