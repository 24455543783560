import { LabelColor } from "../types/label";
import React, { useEffect, useRef, useState } from "react";
import PillLabel from "../../vendorrisk/components/PillLabel";
import "../style/components/TextAreaWithPills.scss";

export interface IToken {
  value: string;
  color?: LabelColor;
  popupMsg?: string;
  immutable?: boolean;
}

interface ITextFieldWithPillsProps {
  onNewTokens: (tokens: string[]) => void;
  onRemovedToken: (idx: number) => void;
  tokens: IToken[];
  delimiters: string[];
  placeholder?: string;
  defaultColor?: LabelColor;
  inputDisabled?: boolean;
  disableAutoScroll?: boolean;
}

const TextAreaWithPills = (props: ITextFieldWithPillsProps): JSX.Element => {
  const [state, setState] = useState({ value: "" });
  const [wrapperId] = useState(
    Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15)
  );
  const inputEl = useRef<HTMLInputElement>(null);
  // replace new line in delimiter with the word Enter as we will
  // be checking for the keyPress event key property
  const delimiters = props.delimiters.map((c) => (c === "\n" ? "Enter" : c));

  const onKeyPress = (event: React.KeyboardEvent) => {
    if (
      event.key === "Backspace" &&
      state.value === "" &&
      props.tokens.length > 0
    ) {
      event.preventDefault();
      if (!props.tokens[props.tokens.length - 1].immutable) {
        props.onRemovedToken(props.tokens.length - 1);
      }
      setState({ value: "" });
    } else if (delimiters.includes(event.key)) {
      if (state.value.length > 0) {
        props.onNewTokens([state.value.trim()]);
        setState({ value: "" });
      }
      event.preventDefault();
    }
  };

  const onPaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
    const pastedText = event.clipboardData?.getData("text/plain");
    if (!pastedText) return;

    const tokens = pastedText
      .split(new RegExp(props.delimiters.join("|")))
      .map((t) => t.trim())
      .filter((t) => t);
    if (!tokens || tokens.length <= 1) return;

    props.onNewTokens(tokens.map((t) => t.trim()));
    setState({ value: "" });
    event.preventDefault();
  };

  const onBlur = (_: React.SyntheticEvent) => {
    if (state.value.length > 0) {
      props.onNewTokens([state.value.trim()]);
      setState({ value: "" });
    }
  };

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState({ value: event.target.value });
  };

  const focusTextInput = () => inputEl?.current?.focus();

  useEffect(() => {
    if (!props.disableAutoScroll) {
      inputEl?.current?.scrollIntoView();
    }
  }, [props.disableAutoScroll, inputEl]);

  const popupClassname = "textarea-popup";

  return (
    <div className={"textarea-wrapper"} id={wrapperId}>
      <div className="textarea-with-pills" onClick={focusTextInput}>
        {props.tokens.map((token, idx) => {
          const id = `${wrapperId}-${idx}`;
          return (
            <PillLabel
              className={"token"}
              key={idx}
              color={token.color || props.defaultColor}
              removeable={!token.immutable}
              onRemoveClick={() => props.onRemovedToken(idx)}
              capitalized={false}
              popupContent={token.popupMsg}
              popupClassName={popupClassname}
              id={id}
            >
              {token.value}
            </PillLabel>
          );
        })}
        <input
          type="text"
          value={state.value}
          onChange={onChange}
          onKeyDown={onKeyPress}
          placeholder={props.tokens.length === 0 ? props.placeholder : ""}
          ref={inputEl}
          onBlur={onBlur}
          onPaste={onPaste}
          disabled={props.inputDisabled}
        />
      </div>
    </div>
  );
};

TextAreaWithPills.defaultProps = {
  placeholder: "",
  defaultColor: LabelColor.Blue,
};

export default TextAreaWithPills;
