import Card from "../../../_common/components/core/Card";
import { History } from "history";
import LoadingBanner from "../../../_common/components/core/LoadingBanner";
import ScrollableDiv from "../ScrollableDiv";
import "../../style/components/ProductPanel.scss";
import BackArrow from "../../../_common/components/BackArrow";
import { DetectedProduct } from "../../../_common/types/detectedProducts";
import ColorGrade from "../executive_summary/ColorGrade";
import { ColorGradeSize } from "../executive_summary/ColorGrade";
import DetectedProductInfoTable from "./DetectedProductInfoTable";
import { useEffect, useState } from "react";
import { useAppSelector } from "../../../_common/types/reduxHooks";

interface IProductPanelProps {
  onClickBack?: () => void;
  loading?: boolean;
  error?: IError;
  history: History;
  productUUID?: string;
  userCanAccessVendorRisk: boolean;
}

const ProductPanel = (props: IProductPanelProps) => {
  const detectedProducts = useAppSelector(
    (state) => state.cyberRisk.detectedProducts?.technologies
  );
  const [product, setProduct] = useState<DetectedProduct>();

  useEffect(() => {
    if (!!detectedProducts && props.productUUID) {
      const detectedProduct = detectedProducts.find((t) => {
        return t.uuid == props.productUUID;
      });
      setProduct(detectedProduct);
    }
  }, [detectedProducts, props.productUUID]);

  if (props.error) {
    const { errorText, actionText, actionOnClick } = props.error;
    return (
      <Card error className="product-slide-panel-error-card">
        <div className="card-content">
          <div>{errorText}</div>
          {actionText && (
            <a className="btn btn-default" onClick={actionOnClick}>
              {actionText}
            </a>
          )}
        </div>
      </Card>
    );
  }

  if (props.loading) {
    return (
      <div className="loading-overlay show">
        <LoadingBanner />
      </div>
    );
  }

  const goToVendorPage = (vendorId?: number, productUUID?: string) => {
    if (!vendorId) {
      return;
    }
    props.history.push(`/vendor/${vendorId}`, {
      from: "detected_products",
      backContext: {
        backTo:
          "/detected_products" + (productUUID && `?productUUID=${productUUID}`),
        backToText: "Back to Detected Products",
      },
    });
  };

  return (
    <div className="product-panel">
      {!!props.onClickBack && (
        <BackArrow popup={"Back"} onClick={props.onClickBack} />
      )}
      <ScrollableDiv newStyles>
        <div className="product-info-card slide-panel-section">
          <h2 title="Product Info">Product Info</h2>
          <div className="product-info-row">
            <div className="product-info-label">Product</div>
            <div className="product-info-value">{product?.tech_name}</div>
          </div>
          <div className="product-info-row">
            <div className="product-info-label">Vendor</div>
            <div className="vendor-value">
              <div
                className={
                  props.userCanAccessVendorRisk
                    ? "vendor-hostname clickable"
                    : "vendor-hostname"
                }
                onClick={
                  props.userCanAccessVendorRisk
                    ? () =>
                        goToVendorPage(
                          product?.vendor?.vendor_id,
                          props.productUUID
                        )
                    : undefined
                }
              >
                {product?.vendor?.primary_hostname}
              </div>
              <div className="score-grade">
                <ColorGrade
                  score={product?.vendor?.score}
                  size={ColorGradeSize.Small}
                />
              </div>
              <div className="score">{product?.vendor?.score}</div>
            </div>
          </div>
          <div className="product-info-row">
            <div className="product-info-label">Category</div>
            <div className="product-info-value">{product?.tag}</div>
          </div>
          <div className="product-info-row">
            <div className="product-info-label">Description</div>
            <div className="product-info-value">{product?.description}</div>
          </div>
        </div>
        {!!product && (
          <DetectedProductInfoTable
            history={props.history}
            product={product}
            className={"domains-table"}
          />
        )}
      </ScrollableDiv>
    </div>
  );
};

export default ProductPanel;
