import { FC, HTMLProps, useEffect, useRef } from "react";

const FocusInput: FC<HTMLProps<HTMLInputElement>> = (props) => {
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    inputRef.current?.focus();
  }, []);

  return <input {...props} ref={inputRef} />;
};

export default FocusInput;
