import { FC } from "react";
import TwoColumnDisplay, {
  TwoColumnDisplayRow,
} from "../../../_common/components/TwoColumnDisplay";
import ColorCheckbox from "../ColorCheckbox";
import NominatedApproverInput from "./NominatedApproverInput";
import { NominatedApproverFeature } from "../../../_common/api/approversAPI";
import TextField, {
  TextFieldData,
} from "../../../_common/components/TextField";
import Button from "../../../_common/components/core/Button";
import DatePicker from "../../../_common/components/DatePicker";
import moment from "moment";

export enum ApproverType {
  Self = "self",
  Other = "other",
}

export enum ExpireType {
  Never = "never",
  Other = "other",
}

interface BreachSightWaiverDetailsStepProps {
  requireApprovalsRiskWaivers: boolean;
  hideApproval?: boolean;
  approverType: ApproverType;
  approverEmail: TextFieldData;
  onSetApprover: (approverType: ApproverType, email: TextFieldData) => void;
  justification: TextFieldData;
  onSetJustification: (justification: TextFieldData) => void;
  canUsePublicWaivers: boolean;
  isPublic: boolean;
  onSetPublic: (v: boolean) => void;
  disablePublicText?: string;
  expireType: ExpireType;
  expireDate?: string;
  onSetExpire: (eType: ExpireType, eDate?: string) => void;
}

const BreachSightWaiverDetailsStep: FC<BreachSightWaiverDetailsStepProps> = ({
  requireApprovalsRiskWaivers,
  hideApproval,
  approverType,
  approverEmail,
  onSetApprover,
  justification,
  onSetJustification,
  canUsePublicWaivers,
  isPublic,
  onSetPublic,
  disablePublicText,
  expireType,
  expireDate,
  onSetExpire,
}) => {
  return (
    <TwoColumnDisplay classname={"breachsight-waiver-details-step"}>
      <TwoColumnDisplayRow
        hide={requireApprovalsRiskWaivers || hideApproval}
        label={"Does this risk waiver require approval?"}
        description={
          "If there is no approver, the risk waiver will become active immediately."
        }
        content={
          <div>
            <ColorCheckbox
              radio
              color="blue"
              label="No."
              checked={approverType === ApproverType.Self}
              onClick={() =>
                onSetApprover(ApproverType.Self, {
                  value: "",
                  isValid: false,
                })
              }
            />
            <ColorCheckbox
              radio
              color="blue"
              label="Yes, I would like to nominate a person to approve this risk waiver."
              checked={approverType === ApproverType.Other}
              onClick={() =>
                onSetApprover(ApproverType.Other, {
                  value: "",
                  isValid: false,
                })
              }
            />
          </div>
        }
      />
      <TwoColumnDisplayRow
        hide={approverType !== ApproverType.Other}
        label="Approval"
        description={
          // For BreachSight, if the Approval field is shown then it is always required
          // Users could choose to self-approve if approval is optional - in that case
          // this field would not be shown
          "Approvers will receive an email asking them to approve or reject the changes. Any risk changes will not be reflected until the waiver is approved."
        }
        content={
          <NominatedApproverInput
            type={NominatedApproverFeature.BreachSightRiskWaivers}
            value={approverEmail.value}
            onChange={(value, isValid) =>
              onSetApprover(ApproverType.Other, {
                value,
                isValid,
              })
            }
            required={true}
          />
        }
      />
      <TwoColumnDisplayRow
        label={"Justification"}
        description={
          "Please provide a justification for the waiver. Describe any compensating controls or other reasons why this is not a risk."
        }
        content={
          <TextField
            maxLength={4000}
            allowTextOverflow={true}
            placeholder="Enter your justification here"
            required
            multiLine
            value={justification.value}
            onChanged={(value, isValid) =>
              onSetJustification({ value, isValid })
            }
          />
        }
      />
      <TwoColumnDisplayRow
        hide={!canUsePublicWaivers}
        label={"Make this waiver Public"}
        description={
          <>
            <p>
              Anyone viewing your page in Vendor Risk will see your waiver and
              the justification you’ve provided.
            </p>
            <Button
              tertiary
              onClick={() =>
                window.open(
                  "https://help.upguard.com/en/articles/7065952-how-to-use-public-risk-waivers-in-breachsight"
                )
              }
              arrow
            >
              View support article
            </Button>
          </>
        }
        content={
          <>
            <ColorCheckbox
              color="blue"
              radio
              checked={!isPublic}
              onClick={() => onSetPublic(false)}
              label="No, this risk waiver should be private."
            />
            <ColorCheckbox
              color="blue"
              radio
              checked={isPublic}
              onClick={() => onSetPublic(true)}
              disabled={disablePublicText !== undefined}
              label="Yes, this risk waiver should be public."
            />
            {disablePublicText !== undefined && (
              <div className={"public-disable-text"}>{disablePublicText}</div>
            )}
          </>
        }
      />
      <TwoColumnDisplayRow
        label={"Does this risk waiver expire?"}
        description={`If a risk waiver has an expiry date, when it expires the waived
                    risk will be shown on your risk profile and impact your
                    security rating.`}
        content={
          <>
            <ColorCheckbox
              color="blue"
              radio
              checked={expireType === ExpireType.Never}
              onClick={() => onSetExpire(ExpireType.Never)}
              label="No, this risk waiver doesn’t expire."
            />
            <ColorCheckbox
              color="blue"
              radio
              checked={expireType === ExpireType.Other}
              onClick={() => onSetExpire(ExpireType.Other)}
              label="Yes, this risk waiver should expire."
            />
            {expireType === ExpireType.Other && (
              <div className={"date-picker-container"}>
                <label>Set Expiration date</label>
                <DatePicker
                  value={expireDate}
                  onChange={(e) =>
                    onSetExpire(ExpireType.Other, e.target.value)
                  }
                  min={moment().add(1, "d").format("YYYY-MM-DD")}
                />
              </div>
            )}
          </>
        }
      />
    </TwoColumnDisplay>
  );
};

export default BreachSightWaiverDetailsStep;
