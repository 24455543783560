import {
  createContext,
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from "react";
import SlidePanel from "../../../_common/components/SlidePanel";
import { Breach, BreachType } from "../../../_common/types/emailExposures";
import { FilterPanelStyles } from "../filter/FilterPanel";
import Button from "../../../_common/components/core/Button";
import Icon from "../../../_common/components/core/Icon";
import DateFilter, {
  IDateFilterState,
  isDateFilterActive,
} from "../filter/DateFilter";
import { OptionType } from "../../../_common/components/SelectV2";
import SelectFilter from "../filter/SelectFilter";
import { SeverityInt } from "../../../_common/types/severity";
import SeverityFilter from "../filter/SeverityFilter";
import { IRangeSliderValues } from "../filter/RangeSlider";
import RangeFilter from "../filter/RangeFilter";
import { usePrevious } from "../../helpers/util";
import SlidePanelSection from "../filter/SlidePanelSection";
import ColorCheckbox from "../ColorCheckbox";

export interface IdentityBreachesFilterState {
  filterActive: boolean;

  breachDate: IDateFilterState;
  publishedDate: IDateFilterState;
  dataClasses: string[];
  excludedSeverities: SeverityInt[];
  numEmployees: IRangeSliderValues;
  numVIPs: IRangeSliderValues;
  darkWebOnly: boolean;
  noDarkWeb: boolean;
  excludedBreachTypes: BreachType[];
}

export const IdentityBreachesFilterInitialState: IdentityBreachesFilterState = {
  filterActive: false,
  breachDate: {},
  publishedDate: {},
  dataClasses: [],
  excludedSeverities: [],
  numEmployees: {},
  numVIPs: {},
  darkWebOnly: false,
  noDarkWeb: false,
  excludedBreachTypes: [],
};

export const IdentityBreachesFilterReducer = (
  state: IdentityBreachesFilterState,
  partialUpdate: Partial<IdentityBreachesFilterState>
) => {
  const newState = { ...state, ...partialUpdate };

  newState.filterActive =
    isDateFilterActive(newState.breachDate) ||
    isDateFilterActive(newState.publishedDate) ||
    newState.dataClasses.length > 0 ||
    newState.excludedSeverities.length > 0 ||
    newState.numEmployees.lowerVal !== undefined ||
    newState.numEmployees.upperVal !== undefined ||
    newState.numVIPs.lowerVal !== undefined ||
    newState.numVIPs.upperVal !== undefined ||
    newState.darkWebOnly ||
    newState.noDarkWeb ||
    newState.excludedBreachTypes.length > 0;

  return newState;
};

// Provide a context that we can put on the top level EmailExposures class component - this is
// so we can maintain the filter state at that level in the hierarchy. We're using context here because
// hooks are not supported by class components.

export const IdentityBreachesFilterContext = createContext<{
  filterState: IdentityBreachesFilterState;
  setFilterState: (newState: IdentityBreachesFilterState) => void;
}>({
  filterState: IdentityBreachesFilterInitialState,
  setFilterState: () => undefined,
});

export const IdentityBreachesFilterProvider: FC<PropsWithChildren> = ({
  children,
}) => {
  const [filterState, setFilterState] = useState(
    IdentityBreachesFilterInitialState
  );
  const context = useMemo(
    () => ({
      filterState,
      setFilterState,
    }),
    [filterState, setFilterState]
  );

  return (
    <IdentityBreachesFilterContext.Provider value={context}>
      {children}
    </IdentityBreachesFilterContext.Provider>
  );
};

interface IDarkWebFilterProps {
  darkWebOnly: boolean;
  noDarkWeb: boolean;
  setDarkWebFilter: (obj: { darkWebOnly: boolean; noDarkWeb: boolean }) => void;
}

const DarkWebFilter: FC<IDarkWebFilterProps> = ({
  darkWebOnly,
  noDarkWeb,
  setDarkWebFilter,
}) => {
  const [expanded, setExpanded] = useState(darkWebOnly || noDarkWeb);

  return (
    <SlidePanelSection
      title="Dark web"
      expanded={expanded}
      toggleExpand={() => setExpanded(!expanded)}
    >
      <ColorCheckbox
        radio
        checked={darkWebOnly}
        onClick={() =>
          setDarkWebFilter({
            darkWebOnly: true,
            noDarkWeb: false,
          })
        }
        label="Show dark web breaches only"
      />
      <ColorCheckbox
        radio
        checked={noDarkWeb}
        onClick={() =>
          setDarkWebFilter({
            darkWebOnly: false,
            noDarkWeb: true,
          })
        }
        label="Exclude dark web breaches"
      />
      <ColorCheckbox
        radio
        checked={!darkWebOnly && !noDarkWeb}
        onClick={() =>
          setDarkWebFilter({
            darkWebOnly: false,
            noDarkWeb: false,
          })
        }
        label="Include dark web breaches"
      />
    </SlidePanelSection>
  );
};

interface IBreachTypeFilterProps {
  excludedBreachTypes: BreachType[];
  setBreachTypeFilter: (obj: { excludedBreachTypes: BreachType[] }) => void;
}

const BreachTypeFilter: FC<IBreachTypeFilterProps> = ({
  excludedBreachTypes,
  setBreachTypeFilter,
}) => {
  const [expanded, setExpanded] = useState(excludedBreachTypes.length > 0);

  const onClickBreachTypeCheckbox = useCallback(
    (breachType: BreachType) => {
      const newExcluded = [...excludedBreachTypes];
      const pos = newExcluded.indexOf(breachType);
      if (pos === -1) {
        newExcluded.push(breachType);
      } else {
        newExcluded.splice(pos, 1);
      }

      setBreachTypeFilter({ excludedBreachTypes: newExcluded });
    },
    [excludedBreachTypes]
  );

  return (
    <SlidePanelSection
      title="Breach type"
      expanded={expanded}
      toggleExpand={() => setExpanded(!expanded)}
    >
      <ColorCheckbox
        label="Company"
        checked={!excludedBreachTypes.includes(BreachType.Company)}
        onClick={() => onClickBreachTypeCheckbox(BreachType.Company)}
      />
      <ColorCheckbox
        label="Infostealer"
        checked={!excludedBreachTypes.includes(BreachType.Infostealer)}
        onClick={() => onClickBreachTypeCheckbox(BreachType.Infostealer)}
      />
      <ColorCheckbox
        label="Paste"
        checked={!excludedBreachTypes.includes(BreachType.Paste)}
        onClick={() => onClickBreachTypeCheckbox(BreachType.Paste)}
      />
    </SlidePanelSection>
  );
};

export interface IIdentityBreachesCardFilterPanelProps {
  unfilteredBreaches: Breach[];
  panelOpen: boolean;
  onPanelClose: () => void;
}

const IdentityBreachesCardFilterPanel: FC<
  IIdentityBreachesCardFilterPanelProps
> = ({ unfilteredBreaches, panelOpen, onPanelClose }) => {
  const {
    filterState: externalFilterState,
    setFilterState: setExternalFilterState,
  } = useContext(IdentityBreachesFilterContext);
  const [filterState, filterReducer] = useReducer(
    IdentityBreachesFilterReducer,
    externalFilterState
  );

  const prevExternalFilterActive = usePrevious(
    externalFilterState.filterActive
  );
  useEffect(() => {
    // Make sure we fully reset our internal state if the external one has been reset
    if (prevExternalFilterActive && !externalFilterState.filterActive) {
      filterReducer(IdentityBreachesFilterInitialState);
    }
  }, [prevExternalFilterActive, externalFilterState]);

  const applyState = useCallback(() => {
    setExternalFilterState(filterState);
    onPanelClose();
  }, [setExternalFilterState, filterState, onPanelClose]);

  const resetState = useCallback(() => {
    setExternalFilterState(IdentityBreachesFilterInitialState);
    filterReducer(IdentityBreachesFilterInitialState);
    onPanelClose();
  }, [setExternalFilterState, onPanelClose]);

  const dataClassOpts: OptionType<string>[] = useMemo(() => {
    const uniqueDataClasses = new Set<string>();
    unfilteredBreaches.forEach(
      (breach) =>
        breach.DataClasses?.forEach((c) => uniqueDataClasses.add(c.Class))
    );

    const asArray = Array.from(uniqueDataClasses);
    asArray.sort((a, b) => a.localeCompare(b));

    return asArray.map((c) => ({
      value: c,
      label: c,
    }));
  }, [unfilteredBreaches]);

  const [maxNumEmployees, maxNumVIPs] = useMemo(() => {
    let maxNumEmployees = 0;
    let maxNumVIPs = 0;

    unfilteredBreaches.forEach((b) => {
      if (b.OrgCount > maxNumEmployees) {
        maxNumEmployees = b.OrgCount;
      }
      if (b.VipCount > maxNumVIPs) {
        maxNumVIPs = b.VipCount;
      }
    });

    return [maxNumEmployees, maxNumVIPs];
  }, [unfilteredBreaches]);

  return (
    <SlidePanel
      onClose={onPanelClose}
      active={panelOpen}
      title="Filter by"
      newStyles
      dimContent
    >
      <FilterPanelStyles
        buttons={
          <>
            <Button onClick={resetState} danger>
              <Icon name="refresh" />
              Reset
            </Button>
            <Button onClick={applyState}>
              <Icon name="checkmark" />
              Apply
            </Button>
          </>
        }
      >
        <DateFilter
          title="Date of breach"
          state={filterState.breachDate}
          onChange={(breachDate) =>
            filterReducer({
              breachDate,
            })
          }
        />
        <DateFilter
          title="Published date"
          state={filterState.publishedDate}
          onChange={(publishedDate) =>
            filterReducer({
              publishedDate,
            })
          }
        />
        <BreachTypeFilter
          excludedBreachTypes={filterState.excludedBreachTypes}
          setBreachTypeFilter={filterReducer}
        />
        <SelectFilter<string>
          title="Data exposed"
          options={dataClassOpts}
          selectedOptionIDs={filterState.dataClasses}
          onChange={(dataClasses) =>
            filterReducer({
              dataClasses,
            })
          }
        />
        <SeverityFilter
          title="Severity"
          excludedSeverities={filterState.excludedSeverities}
          onChange={(excludedSeverities) =>
            filterReducer({
              excludedSeverities,
            })
          }
        />
        {maxNumEmployees > 0 && (
          <RangeFilter
            min={0}
            max={maxNumEmployees}
            lowerVal={filterState.numEmployees.lowerVal}
            upperVal={filterState.numEmployees.upperVal}
            onChange={(numEmployees) => filterReducer({ numEmployees })}
            title="Number of employees involved"
          />
        )}
        {maxNumVIPs > 0 && (
          <RangeFilter
            min={0}
            max={maxNumVIPs}
            lowerVal={filterState.numVIPs.lowerVal}
            upperVal={filterState.numVIPs.upperVal}
            onChange={(numVIPs) => filterReducer({ numVIPs })}
            title="Number of VIPs involved"
          />
        )}
        <DarkWebFilter
          darkWebOnly={filterState.darkWebOnly}
          noDarkWeb={filterState.noDarkWeb}
          setDarkWebFilter={filterReducer}
        />
      </FilterPanelStyles>
    </SlidePanel>
  );
};

export default IdentityBreachesCardFilterPanel;
