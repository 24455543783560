import ReportCard from "../../../_common/components/ReportCard";
import { IAdditionalEvidenceDocumentToAdd } from "../../reducers/remediation.actions";
import { AdditionalEvidenceDocumentAction } from "./additional_evidence.reducer";
import { Dispatch } from "react";
import { AdditionalEvidenceRiskEvidence } from "../../../_common/types/vendorSummary";
import { DocumentWithRisks } from "../RequestRemediationV2";
import { SelectableDocument } from "../../components/SelectableDocument";

interface ShareDocumentsStepProps {
  documentsWithRisks: DocumentWithRisks[];
  selectedAdditionalEvidenceDocuments: Record<
    number,
    IAdditionalEvidenceDocumentToAdd
  >;
  additionalEvidenceDocumentsDispatch: Dispatch<AdditionalEvidenceDocumentAction>;
  onDownloadDocument: (e: AdditionalEvidenceRiskEvidence) => void;
}

export const ShareDocumentsStep = ({
  documentsWithRisks,
  selectedAdditionalEvidenceDocuments,
  additionalEvidenceDocumentsDispatch,
  onDownloadDocument,
}: ShareDocumentsStepProps) => {
  return (
    <div>
      <ReportCard newStyles className="add-documents-card">
        <div className="header">Share documents</div>
        <div className="report-card-content">
          The selected risks relate to specific documents. Choose whether to
          share them as part of this remediation request.
          {documentsWithRisks.map((docWithRisks) => {
            const selected =
              !!selectedAdditionalEvidenceDocuments[docWithRisks.document.id];
            return (
              <SelectableDocument
                key={docWithRisks.document.id}
                docWithRisks={docWithRisks}
                selected={selected}
                onToggle={() =>
                  additionalEvidenceDocumentsDispatch({
                    type: selected ? "removeEvidenceID" : "addEvidenceID",
                    evidenceId: docWithRisks.document.id,
                  })
                }
                onDownload={() => onDownloadDocument(docWithRisks.document)}
              />
            );
          })}
        </div>
      </ReportCard>
    </div>
  );
};
