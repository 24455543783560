export const getSearchParamAsNumber = (search: string, name: string) => {
  const params = new URLSearchParams(search);
  const idString = params.get(name);

  if (idString) {
    try {
      return parseInt(idString, 10);
    } catch {
      return undefined;
    }
  } else {
    return undefined;
  }
};

export const getSearchParamAsString = (search: string, name: string) => {
  const params = new URLSearchParams(search);
  return params.get(name);
};
