import { DefaultAction } from "../types/redux";
import { FetchCyberRiskUrl } from "../api";
import { LogError } from "../helpers";

// trackPageViewBackend
// tracks a page view event in the backend by calling the analytics API
export const trackPageViewBackend =
  (
    path: string,
    url: string,
    referrer?: string,
    hash?: string,
    search?: string
  ): DefaultAction =>
  async (dispatch, getState) => {
    try {
      await FetchCyberRiskUrl(
        // the path for the pageview is obfuscated to avoid being caught in AdBlockers
        // blocking all requests to /api/analytics/*, like the EasyPrivacy list (https://easylist.to/#easyprivacy)
        "ae/p/v1",
        { path, url, referrer, hash, search },
        { method: "PUT" },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error logging page view", e);
      // don't pass this up as we don't want user to see errors for this
    }
  };

// trackTimeOnPageBackend
// tracks the time the user spent on a page event in the backend by calling the analytics API
export const trackTimeOnPageBackend =
  (path: string, url: string, milliseconds: number): DefaultAction =>
  async (dispatch, getState) => {
    try {
      await FetchCyberRiskUrl(
        // the path for the time on page is obfuscated to avoid being caught in AdBlockers
        // blocking all requests to /api/analytics/*, like the EasyPrivacy list (https://easylist.to/#easyprivacy)
        "ae/top/v1",
        { path, url, milliseconds },
        { method: "PUT" },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error logging time on page event", e);
      // don't pass this up as we don't want user to see errors for this
    }
  };

// trackEventBackend
// tracks an event in the backend by calling the analytics API
export const trackEventBackend =
  (eventName: string, properties?: Record<string, any>): DefaultAction =>
  async (dispatch, getState) => {
    try {
      await FetchCyberRiskUrl(
        // the path for the events is obfuscated to avoid being caught in AdBlockers
        // blocking all requests to /api/analytics/*, like the EasyPrivacy list (https://easylist.to/#easyprivacy)
        "ae/e/v1",
        null,
        {
          method: "PUT",
          body: JSON.stringify({
            e: eventName,
            p: properties ?? {},
          }),
        },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error logging event", e);
      // don't pass this up as we don't want user to see errors for this
    }
  };
