import { FC } from "react";
import NavLink from "../_common/components/NavLink";
import appguardApi from "./api/appguard.api";
import { ClickNavLinkFunc } from "../_common/components/navigation/BreachSightNavItems";
import classnames from "classnames";
import { pageDescriptions } from "../_common/pageDescriptions";

interface AppGuardNavItemsProps {
  pathname: string;
  navLinkClickFunc: (path: string, linkName: string, _?: number) => () => void;
}

const AppGuardNavItems: FC<AppGuardNavItemsProps> = ({
  pathname,
  navLinkClickFunc,
}) => {
  const { data } = appguardApi.useGetCloudGitConnectionsV1Query();

  return (
    <>
      <div className="subtitle" id="appguard_subtitle">
        Code2Cloud
      </div>
      {!data?.connections?.length && (
        <NavLink
          icon="tools"
          text="Setup"
          onClick={navLinkClickFunc("/appguard/setup", "Setup")}
          to="/appguard/setup"
        />
      )}
      {!!data?.connections?.length && (
        <>
          <NavLink
            icon="analytics"
            text="Executive Summary"
            onClick={navLinkClickFunc("/appguard/summary", "Executive Summary")}
            to="/appguard/summary"
          />
          <NavLink
            icon="focus"
            text="Risk Profile"
            onClick={navLinkClickFunc("/appguard/risk_profile", "Risk Profile")}
            to="/appguard/risk_profile"
          />
          <NavLink
            icon="risk-waivers"
            text="Risk Modifications"
            onClick={navLinkClickFunc(
              "/appguard/risk_modifications",
              "Risk Modifications"
            )}
            to="/appguard/risk_modifications"
          />
          <NavLink
            icon="bug"
            text="Vulnerabilities"
            onClick={navLinkClickFunc(
              "/appguard/vulnerabilities",
              "Vulnerabilities"
            )}
            to="/appguard/vulnerabilities"
          />
          <NavLink
            icon="radar"
            text="Repositories"
            onClick={navLinkClickFunc("/appguard/repositories", "Repositories")}
            to="/appguard/repositories"
          />
          {(pathname === "/appguard/repositories" ||
            pathname === "/appguard/settings") && (
            <NavLink
              isSubMenuItem
              icon="spanner"
              text="Settings"
              onClick={navLinkClickFunc("/appguard/settings", "Settings")}
              to="/appguard/settings"
            />
          )}
        </>
      )}
      <NavLink
        icon="radar"
        text="Cloud Assets"
        onClick={navLinkClickFunc("/appguard/cloud-assets", "Cloud Assets")}
        to="/appguard/cloud-assets"
      />
    </>
  );
};

export const AppGuardBreachsightCloudNavItems: FC<AppGuardNavItemsProps> = ({
  pathname,
  navLinkClickFunc,
}) => {
  return (
    <>
      <NavLink
        icon="code2cloud"
        text="Cloud"
        onClick={navLinkClickFunc("/breachsight/cloud", "Cloud")}
        to="/breachsight/cloud"
      />
      {pathname === "/breachsight/cloud/setup" && (
        <NavLink
          isSubMenuItem
          icon="spanner"
          text="Setup"
          onClick={navLinkClickFunc("/breachsight/cloud/setup", "Setup")}
          to="/breachsight/cloud/setup"
        />
      )}
    </>
  );
};

interface AppGuardBreachsightCloudNavItemsNewProps {
  clickNavLinkFunc: ClickNavLinkFunc;
  disabled: boolean;
}

export function AppGuardBreachsightCloudNavItemsNew({
  clickNavLinkFunc,
  disabled,
}: AppGuardBreachsightCloudNavItemsNewProps) {
  return (
    <NavLink
      className={classnames("unstyled")}
      disabled={disabled}
      text="Cloud"
      hoverText={pageDescriptions.BreachsightCloud}
      onClick={clickNavLinkFunc("/breachsight/cloud/", "Cloud")}
      to="/breachsight/cloud"
    />
  );
}

export default AppGuardNavItems;
