import { FC, useMemo, useState } from "react";
import {
  RiskClassification,
  RiskClassificationGroup,
  RiskClassificationString,
} from "../types/risk/classification";
import CustomSelect, {
  GroupItem,
  OptionItem,
  SelectItemType,
} from "./CustomSelect";

interface ClassificationSelectorProps {
  selectedClassification?: RiskClassification;
  onSelectClassification: (val: RiskClassification | undefined) => void;
}

const ClassificationSelector: FC<ClassificationSelectorProps> = (props) => {
  const [searchVal, setSearchVal] = useState("");

  const options = useMemo(
    () =>
      Object.values(
        Object.values(RiskClassification)
          .filter(
            (r) =>
              !searchVal ||
              r.toLocaleLowerCase().includes(searchVal.toLocaleLowerCase())
          )
          .reduce(
            (prev, c) => {
              const option: OptionItem = {
                id: c,
                content: RiskClassificationString(c, "", true),
                type: SelectItemType.Option,
                onClick: () => props.onSelectClassification(c),
              };

              const groupName = RiskClassificationString(
                RiskClassificationGroup(c),
                "",
                true
              );

              if (!(groupName in prev)) {
                prev[groupName] = {
                  title: groupName,
                  id: groupName,
                  // description: groupName,
                  type: SelectItemType.Group,
                  items: [],
                };
              }

              prev[groupName].items?.push(option);

              return prev;
            },
            {} as Record<string, GroupItem>
          )
      ).sort((a, b) => a.title.localeCompare(b.title)),
    [searchVal]
  );

  return (
    <CustomSelect
      items={options}
      placeholder="Select a category for this risk"
      selectedOption={props.selectedClassification}
      onClearOptions={() => props.onSelectClassification(undefined)}
      onSearchChange={setSearchVal}
    />
  );
};

export default ClassificationSelector;
