import { ComponentProps, useEffect, useRef, useState } from "react";
import { SidePopupV2 } from "./DismissablePopup";
import { debounce } from "lodash";
import "../style/components/EllipsizedText.scss";
import classNames from "classnames";

/**
 * Omit the "inline" prop inherited from SidePopupV2 because
 * that prevents the content from ellipsizing
 */
export interface EllipsizedTextProps
  extends Omit<ComponentProps<typeof SidePopupV2>, "inline"> {
  /**
   * This component requires a single child element
   */
  children: JSX.Element | string;
}

function computeContentRequiresPopup(element: HTMLElement | null): boolean {
  if (!element) return false;

  // Precaution in case `style=...` was being used
  const originalWidthStyle = element.style.width;
  element.style.width = "max-content";
  const maxBounds = element.getBoundingClientRect();
  element.style.width = originalWidthStyle;

  const cssBounds = element.getBoundingClientRect();

  return maxBounds.width > cssBounds.width;
}

/**
 * EllipsizedText allows you to show popup content on hover when an element is
 * being ellipsized by CSS - also on resize. Ideally suited to single-line ellipses.
 *
 * ❗
 * If you have issues, review the following:
 *
 * → Check Storybook stories (various options to try out): `frontends/_common/components/EllipsizedText.stories.tsx`
 *
 * → CSS Tricks demo (flexbox): https://css-tricks.com/flexbox-truncated-text/#aa-demo
 * ```
 *
 * ℹ️
 * CSS rules for `overflow: hidden`, `text-overflow: ellipsis` and
 * `white-space: nowrap` will be applied to the immediate child element, whatever
 * element that happens to be.
 *
 * 🛑
 * This component makes a reasonably strong attempt to take accurate measurements
 * but there may be some situations or browsers where sub-pixel rounding is
 * responsible for the component to misbehave.
 */
export default function EllipsizedText({
  children,
  text,
  className,
  popupClassName,
  ...props
}: EllipsizedTextProps) {
  const ref = useRef<HTMLDivElement>(null);
  const [shouldDisplayPopup, setShouldDisplayPopup] = useState<boolean>(false);

  useEffect(function handleResize() {
    if (!ref.current) return;

    const observer = new ResizeObserver(
      debounce<ResizeObserverCallback>(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.target instanceof HTMLElement) {
              const result = computeContentRequiresPopup(entry.target);
              setShouldDisplayPopup(result);
            }
          });
        },
        100,
        { leading: false, trailing: true }
      )
    );

    const current = ref.current;
    observer.observe(current);
    return () => observer.unobserve(current);
  }, []);

  return (
    <SidePopupV2
      className={classNames("ellipsized-text-container", className)}
      popupClassName={classNames("ellipsized-text-popup", popupClassName)}
      popupHoverable
      // Control whether any popup should display
      text={shouldDisplayPopup ? text : undefined}
      // Apply sensible default props
      // generally try to fit the whole content without wrapping...
      width="max-content"
      // Smother overrides from any supplied props
      {...props}
    >
      <div className="ellipsized-text" ref={ref}>
        {children}
      </div>
    </SidePopupV2>
  );
}
