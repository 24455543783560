import {
  ManagedVendorServiceLevel,
  ManagedVendorServiceStatusType,
} from "../../../_common/types/thirdPartyMangedVendors";

export interface ManagedVendorFilterV2 {
  namePrefix?: string;
  orgNames?: { value: string; label: string }[];
  excludedServiceLevels?: ManagedVendorServiceLevel[];
  includedServiceStatuses: ManagedVendorServiceStatusType[];
  analystUsers?: { value: string; label: string }[];
}

export const DefaultManagedVendorServiceStatusTypes = [
  ManagedVendorServiceStatusType.Blocked,
  ManagedVendorServiceStatusType.OnHold,
  ManagedVendorServiceStatusType.New,
  ManagedVendorServiceStatusType.NotStarted,
  ManagedVendorServiceStatusType.InProgress,
];

export const DefaultManagedVendorFilterV2: ManagedVendorFilterV2 = {
  orgNames: [],
  excludedServiceLevels: [],
  includedServiceStatuses: DefaultManagedVendorServiceStatusTypes,
  analystUsers: [],
};
