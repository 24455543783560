import { Component, FormEventHandler } from "react";
import classnames from "classnames";
import Button from "../../../_common/components/core/Button";
import LoadingBanner from "../../../_common/components/core/LoadingBanner";
import { closeModal } from "../../../_common/reducers/commonActions";
import {
  editOrg,
  fetchOrgs,
  fetchOrgsForParent,
  getConfig,
} from "../../reducers/adminActions";
import DatePicker from "../../../_common/components/DatePicker";
import ColorCheckbox from "../../../vendorrisk/components/ColorCheckbox";
import OrgConfig, {
  defaultFreeOrgConfig,
  defaultOrgConfig,
  UserLimitConfig,
  validateOrgConfig,
} from "../OrgConfig";

import "../../styles/NewOrganisationModal.scss";
import moment from "moment";
import {
  AssuranceType,
  IOrganisationWithDistributor,
  IOrgConfig,
  IOrgFlags,
  organisationAccountType,
  organisationUserLimitType,
} from "../../../_common/types/organisations";
import {
  addDefaultSuccessAlert,
  addDefaultUnknownErrorAlert,
  addMessageAlert,
  addSimpleErrorAlert,
  EMessageAlertPosition,
} from "../../../_common/reducers/messageAlerts.actions";
import { DefaultThunkDispatchProp } from "../../../_common/types/redux";
import { BannerType } from "../../../vendorrisk/components/InfoBanner";
import { SelectV2 } from "../../../_common/components/SelectV2";
import { titleCase } from "./NewOrganisationModal";

export const EditOrganisationModalName = "EditOrganisationModalName";

const TRIAL_LASTS_FOR_DAYS = 14;

const assuranceTypeOptions = [
  {
    label: "N/A",
    value: AssuranceType.None,
  },
  {
    label: "Assurance",
    value: AssuranceType.Assurance,
  },
  {
    label: "MSSP",
    value: AssuranceType.MSSP,
  },
];

interface IEditOrganisationModalProps extends DefaultThunkDispatchProp {
  modalData: {
    org: IOrganisationWithDistributor;
    orgFlags: IOrgFlags;
    parentOrgID?: number;
    accountsFilter: {
      showEnabled: boolean;
      showDisabled: boolean;
      showAccountTypes: string[];
    };
    regionalBucketAliases: string[];
  };
}

interface IEditOrganisationModalState {
  entitlements_loading: boolean;
  loading: boolean;
  isDistributor: boolean;
  orgConfig: IOrgConfig;
  regionalAliases: [];
  accountType: string;
  accountPlan: string;
  enabled: boolean;
  trialExpiresOn?: string;
  mfaenabled: boolean;
  overrideParentRemoval?: boolean;
}

/**
 * <EditOrganisationModal />
 * Modal form popped up from the super admin page to allow a super admin to edit an organisation
 */
class EditOrganisationModal extends Component<
  IEditOrganisationModalProps,
  IEditOrganisationModalState
> {
  constructor(props: IEditOrganisationModalProps) {
    super(props);

    const state: IEditOrganisationModalState = {
      entitlements_loading: true,
      loading: false,
      isDistributor: props.modalData.org.isDistributor,
      orgConfig: {} as IOrgConfig,
      regionalAliases: [],
      accountType: props.modalData.org.accountType,
      accountPlan: props.modalData.org.plan ?? "",
      enabled: props.modalData.org.active,
      trialExpiresOn: this.formatDate(props.modalData.org.trialExpiresOn ?? ""),
      mfaenabled: props.modalData.org.isMFAEnabled ?? false,
    };

    this.state = state;
  }

  componentDidMount() {
    this.props
      .dispatch(getConfig(this.props.modalData.org.id))
      .then((orgConfig) => {
        this.setState({
          entitlements_loading: false,
          orgConfig: orgConfig,
        });
      })
      .catch((err) => {
        this.props.dispatch(closeModal());
        this.props.dispatch(
          addDefaultUnknownErrorAlert(
            "Error occurred fetching entitlements for org",
            [err]
          )
        );
      });
  }

  name: HTMLInputElement | null = null;

  onConfigChange = (orgConfig: IOrgConfig) => {
    this.setState({ orgConfig: orgConfig });
  };

  onChangeUserLimitType = (limitType: organisationUserLimitType) =>
    this.setState(({ orgConfig }) => ({
      orgConfig: {
        ...orgConfig,
        userLimitType: limitType,
      },
    }));

  onChangeUserLimit = (limit: number) =>
    this.setState(({ orgConfig }) => ({
      orgConfig: {
        ...orgConfig,
        userLimit: limit,
      },
    }));

  onSetInternalOnly = (internalOnly: boolean) =>
    this.setState(({ orgConfig }) => ({
      orgConfig: {
        ...orgConfig,
        isInternalOnly: internalOnly,
      },
    }));

  onSetTrial = () => {
    this.setState((state) => {
      let { trialExpiresOn } = state;
      if (trialExpiresOn == null) {
        const defaultTrialExpiresOn = new Date();
        defaultTrialExpiresOn.setDate(
          defaultTrialExpiresOn.getDate() + TRIAL_LASTS_FOR_DAYS
        );
        trialExpiresOn = this.formatDate(defaultTrialExpiresOn);
      }

      return {
        accountType: organisationAccountType.trial,
        accountPlan: "",
        isDistributor: false,
        trialExpiresOn: trialExpiresOn,
        overrideParentRemoval: undefined,
        // Reset the orgConfig if we're switching away from Free accounts
        orgConfig:
          state.accountType === organisationAccountType.free
            ? {
                ...defaultOrgConfig,
                mainHostname: state.orgConfig.mainHostname,
                isVerifiedVendor: state.orgConfig.isVerifiedVendor,
              }
            : state.orgConfig,
      };
    });
  };

  onSetFree = () => {
    this.setState((state) => ({
      accountType: organisationAccountType.free,
      accountPlan: "",
      isDistributor: false,
      overrideParentRemoval: undefined,
      orgConfig: {
        ...defaultFreeOrgConfig,
        mainHostname: state.orgConfig.mainHostname,
        isVerifiedVendor: true, // Free orgs must be verified vendors
      },
    }));
  };

  onSetDistributor = () => {
    this.setState((state) => ({
      accountType: organisationAccountType.distributor,
      accountPlan: "",
      isDistributor: true,
      overrideParentRemoval: undefined,
      // Reset the orgConfig if we're switching away from Free accounts
      orgConfig:
        state.accountType === organisationAccountType.free
          ? {
              ...defaultOrgConfig,
              mainHostname: state.orgConfig.mainHostname,
              isVerifiedVendor: state.orgConfig.isVerifiedVendor,
            }
          : state.orgConfig,
    }));
  };
  onSetPandemic = () => {
    // For pandemic accounts, we want to ensure Data Leaks and Fourth Party Risks are disabled by default
    let orgConfig = { ...this.state.orgConfig };
    if (this.state.accountType === organisationAccountType.free) {
      orgConfig = {
        ...defaultOrgConfig,
        mainHostname: this.state.orgConfig.mainHostname,
        isVerifiedVendor: this.state.orgConfig.isVerifiedVendor,
      };
    }
    delete orgConfig.breachSight.dataExposures;
    delete orgConfig.breachSight.emailExposures;
    orgConfig.vendorRisk.numReports = 0;
    orgConfig.vendorRisk.vendorFourthParty.enabled = false;
    orgConfig.vendorRisk.riskAssessments.enabled = false;

    this.setState({
      accountType: organisationAccountType.paid,
      accountPlan: "pandemic",
      isDistributor: false,
      orgConfig,
      overrideParentRemoval: undefined,
    });
  };
  onSetPaid = () => {
    this.setState((state) => ({
      accountType: organisationAccountType.paid,
      accountPlan: "",
      isDistributor: false,
      overrideParentRemoval: this.parentIdWillBeUnset() ? false : undefined,
      // Reset the orgConfig if we're switching away from Free accounts
      orgConfig:
        state.accountType === organisationAccountType.free
          ? {
              ...defaultOrgConfig,
              mainHostname: this.state.orgConfig.mainHostname,
              isVerifiedVendor: this.state.orgConfig.isVerifiedVendor,
            }
          : state.orgConfig,
    }));
  };

  parentIdWillBeUnset = () =>
    (this.props.modalData.org.parentID ?? 0) > 0 &&
    this.props.modalData.org.accountType === organisationAccountType.trial;

  onSetTrialExpiresOn = (e: any) => {
    const dateVal = e.target.value;
    const expiry = dateVal ? this.formatDate(dateVal) : undefined;
    this.setState({
      trialExpiresOn: expiry,
    });
  };

  onChangeOverrideParentRemoval = () => {
    this.setState((state) => ({
      overrideParentRemoval: !state.overrideParentRemoval,
    }));
  };

  formatDate = (date: Date | string) => {
    let d = moment(date).format("YYYY-MM-DD");
    if (d == "Invalid date") {
      d = "";
    }
    return d;
  };

  onSubmit: FormEventHandler<HTMLFormElement> = (evt) => {
    evt.preventDefault();
    const name = this.name?.value ?? "";

    if (!name) {
      this.props.dispatch(
        addSimpleErrorAlert("One of the mandatory fields is empty", [])
      );
      return;
    }

    const { accountType } = this.state;
    let { trialExpiresOn } = this.state;
    if (this.state.enabled) {
      if (!this.state.isDistributor) {
        const validation = validateOrgConfig(this.state.orgConfig);
        if (validation !== "") {
          this.props.dispatch(addSimpleErrorAlert(validation));
          return;
        }
      }

      if (accountType === organisationAccountType.trial && !trialExpiresOn) {
        this.props.dispatch(
          addSimpleErrorAlert("Trial accounts must have an expiry date defined")
        );
        return;
      }

      // dont set a trial date unless the account type is correct
      if (accountType !== organisationAccountType.trial) {
        trialExpiresOn = undefined;
      }

      if (!accountType) {
        this.props.dispatch(
          addSimpleErrorAlert("An account type must be selected")
        );
        return;
      }
    }

    const config = this.state.orgConfig;
    config.name = name;
    config.enabled = this.state.enabled;
    config.accountPlan = this.state.accountPlan;
    config.accountType = this.state.accountType;
    config.mfaEnabled = this.state.mfaenabled;
    config.trialExpiresOn = this.state.trialExpiresOn;

    this.setState({ loading: true });
    this.props
      .dispatch(
        editOrg(
          this.props.modalData.org.id,
          this.state.orgConfig,
          this.state.overrideParentRemoval
        )
      )
      .then(() => {
        if (
          typeof this.props.modalData.parentOrgID !== "undefined" &&
          this.props.modalData.parentOrgID >= 0
        ) {
          this.props.dispatch(
            fetchOrgsForParent(
              this.props.modalData.accountsFilter.showEnabled,
              this.props.modalData.accountsFilter.showDisabled,
              this.props.modalData.accountsFilter.showAccountTypes
            )
          );
        } else {
          this.props.dispatch(
            fetchOrgs(
              this.props.modalData.accountsFilter.showEnabled,
              this.props.modalData.accountsFilter.showDisabled,
              this.props.modalData.accountsFilter.showAccountTypes
            )
          );
        }

        this.props.dispatch(closeModal());
        this.props.dispatch(addDefaultSuccessAlert("Organization saved"));
      })
      .catch((err) => {
        this.setState({ loading: false });
        this.props.dispatch(
          addMessageAlert({
            message: err.message,
            type: BannerType.ERROR,
            position: EMessageAlertPosition.Bottom,
          })
        );
      });
  };

  onToggleEnabledDisabled = () => {
    this.setState((state) => {
      return {
        enabled: !state.enabled,
      };
    });
  };

  onToggleMFAEnabled = () => {
    this.setState((state) => {
      return {
        mfaenabled: !state.mfaenabled,
      };
    });
  };

  render() {
    const { name } = this.props.modalData.org;

    if (this.state.entitlements_loading) {
      return (
        <div id="edit-org-modal">
          <div className="modal-content">
            <LoadingBanner tight />
          </div>
        </div>
      );
    }

    return (
      <div id="edit-org-modal">
        <div className="modal-content">
          {!this.state.isDistributor && <h2>Edit account</h2>}
          <form onSubmit={this.onSubmit}>
            <label htmlFor="name">Account Name</label>
            <input
              type="text"
              name="name"
              required
              defaultValue={name}
              ref={(ref) => (this.name = ref)}
            />
            <div className="radiolist">
              <ColorCheckbox
                label="Account Enabled"
                checked={this.state.enabled}
                onClick={this.onToggleEnabledDisabled}
              />
              {this.state.enabled && (
                <ColorCheckbox
                  label="Internal only"
                  className={"internal_check"}
                  checked={this.state.orgConfig.isInternalOnly}
                  onClick={() =>
                    this.onSetInternalOnly(!this.state.orgConfig.isInternalOnly)
                  }
                />
              )}
            </div>
            {!this.state.enabled && (
              <div className="warning">
                Warning: all registered email exposure domains will be removed
                from the account once it is disabled.
              </div>
            )}
            <div className="vertspacer" />
            <div
              className={classnames("", {
                "disabled-org-no-display": !this.state.enabled,
              })}
            >
              <label htmlFor="accountType">Account Type</label>
              <div
                className={classnames("radiolist", {
                  "disabled-org": !this.state.enabled,
                })}
              >
                <ColorCheckbox
                  radio
                  label="Demo/Trial"
                  checked={
                    this.state.accountType === organisationAccountType.trial
                  }
                  onClick={this.onSetTrial}
                  disabled={!this.state.enabled}
                />
                <div className="spacer" />
                <ColorCheckbox
                  radio
                  label="Free"
                  checked={
                    this.state.accountType === organisationAccountType.free
                  }
                  onClick={this.onSetFree}
                />
                <div className="spacer" />
                <ColorCheckbox
                  radio
                  label="Paid"
                  checked={
                    this.state.accountType === organisationAccountType.paid &&
                    this.state.accountPlan !== "pandemic"
                  }
                  onClick={this.onSetPaid}
                  disabled={!this.state.enabled}
                />
                <div className="spacer" />
                <ColorCheckbox
                  radio
                  label="Pandemic"
                  checked={this.state.accountPlan === "pandemic"}
                  onClick={this.onSetPandemic}
                  disabled={!this.state.enabled}
                />
                <div className="spacer" />
                <ColorCheckbox
                  radio
                  label="Distributor"
                  checked={
                    this.state.accountType ===
                    organisationAccountType.distributor
                  }
                  onClick={this.onSetDistributor}
                  disabled={!this.state.enabled}
                />
                <br />
                <div className="spacer" />
              </div>
              <div className="vertspacer" />
              {this.state.overrideParentRemoval != null && (
                <>
                  {!this.state.overrideParentRemoval && (
                    <div className="left-align">
                      Moving an organisation from trial to paid will remove it
                      from this distributor, submitting this form will mean you
                      lose access to <b>{this.props.modalData.org.name}</b>.
                    </div>
                  )}
                  <ColorCheckbox
                    label="Keep distributor account"
                    checked={this.state.overrideParentRemoval}
                    onClick={this.onChangeOverrideParentRemoval}
                  />
                  <div className="vertspacer" />
                </>
              )}
              {this.state.accountType === organisationAccountType.trial && (
                <div
                  className={classnames("", {
                    "disabled-org": !this.state.enabled,
                  })}
                >
                  <label htmlFor="trialExpiresOn">Trial Expires On</label>
                  <DatePicker
                    name="trialExpiresOn"
                    value={this.state.trialExpiresOn}
                    onChange={this.onSetTrialExpiresOn}
                    disabled={!this.state.enabled}
                    className={classnames("", {
                      "disabled-org": !this.state.enabled,
                    })}
                  />
                  {this.state.trialExpiresOn &&
                    // @ts-ignore
                    this.state.trialExpiresOn < new Date() && (
                      <span className="red-text">NOTE: TRIAL HAS EXPIRED</span>
                    )}
                  <div className="vertspacer" />
                </div>
              )}
              <div className="spacer" />
              {this.state.accountType &&
                this.state.accountType !==
                  organisationAccountType.distributor && (
                  <>
                    <label htmlFor="companyDomain">Company Domain</label>
                    <input
                      type="text"
                      name="companyDomain"
                      required
                      placeholder="companydomain.com"
                      value={this.state.orgConfig.mainHostname}
                      onChange={(e) => {
                        const val = e.target.value;
                        this.setState(({ orgConfig }) => {
                          return {
                            orgConfig: {
                              ...orgConfig,
                              mainHostname: val,
                            },
                          };
                        });
                      }}
                    />
                    {this.state.orgConfig.mainHostname && (
                      <div style={{ marginTop: "20px", marginBottom: "20px" }}>
                        <ColorCheckbox
                          label={
                            "Official Account of " +
                            this.state.orgConfig.mainHostname
                          }
                          disabled={
                            this.state.accountType ===
                            organisationAccountType.free
                          }
                          checked={this.state.orgConfig.isVerifiedVendor}
                          onClick={() =>
                            this.setState((state) => {
                              return {
                                orgConfig: {
                                  ...state.orgConfig,
                                  isVerifiedVendor:
                                    !state.orgConfig.isVerifiedVendor,
                                },
                              };
                            })
                          }
                        />
                        {this.state.orgConfig.isVerifiedVendor && (
                          <div className="left-align">
                            {"Please double check that this is the official account for " +
                              this.state.orgConfig.mainHostname +
                              ". Any shared assessments published from this account will appear as though they are from " +
                              this.state.orgConfig.mainHostname +
                              ". There can only be one official account for a domain."}
                          </div>
                        )}
                      </div>
                    )}
                    <UserLimitConfig
                      userLimit={this.state.orgConfig.userLimit}
                      setUserLimit={this.onChangeUserLimit}
                      userLimitType={this.state.orgConfig.userLimitType}
                      setUserLimitType={this.onChangeUserLimitType}
                    />
                    <label key="label" htmlFor="customerEmail">
                      Configure Product Features
                    </label>
                    <OrgConfig
                      orgConfig={this.state.orgConfig}
                      onChange={this.onConfigChange}
                      allowDistributor={false}
                      isDistributor={this.state.isDistributor}
                      isFreeAccount={
                        this.state.accountType === organisationAccountType.free
                      }
                      isPaidAccount={
                        this.state.accountType === organisationAccountType.paid
                      }
                      key="orgConfig"
                      enabled={this.state.enabled}
                    />
                    <div className="vertspacer" />
                    <label htmlFor="assuranceType">Assurance Type</label>
                    <SelectV2
                      name={"assuranceType"}
                      options={assuranceTypeOptions}
                      value={
                        assuranceTypeOptions.find(
                          (a) => a.value === this.state.orgConfig.assuranceType
                        ) ?? assuranceTypeOptions[0]
                      }
                      onChange={(v) => {
                        this.setState(({ orgConfig }) => {
                          return {
                            orgConfig: {
                              ...orgConfig,
                              assuranceType: v?.value,
                            },
                          };
                        });
                        this.setState({});
                      }}
                    />
                  </>
                )}
              <label htmlFor="breachSightCadence" style={{ marginTop: "20px" }}>
                Regional File Storage
              </label>
              <div>
                <div>
                  <ColorCheckbox
                    radio
                    disabled
                    label="Default (multi-region/us)"
                    checked={
                      this.state.orgConfig.selectedBucketAlias === "default"
                    }
                  />
                </div>
                {this.props.modalData.regionalBucketAliases.map((alias) => {
                  return (
                    <div key={"div_" + alias}>
                      <ColorCheckbox
                        radio
                        disabled
                        label={titleCase(alias)}
                        checked={
                          this.state.orgConfig.selectedBucketAlias === alias
                        }
                      />
                    </div>
                  );
                })}
              </div>
            </div>
            <Button
              type="submit"
              primary
              loading={this.state.loading}
              disabled={this.state.loading}
            >
              Save
            </Button>
          </form>
        </div>
      </div>
    );
  }
}

export default EditOrganisationModal;
