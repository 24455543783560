import {
  ICorrespondenceMessage,
  SurveyQuestionMessageMeta,
} from "../../types/correspondenceMessage";
import { MessageGroup } from "../MessagesPanel";
import { separateQuestionNumberFromQuestion } from "./SurveyDetailsUnansweredQuestionsCard";
import NodeTypeIcon, {
  nodeTypeIconTypeToNodeType,
  vsaqQuestionTypeToNodeTypeIconType,
} from "../../../survey_builder/components/NodeTypeIcon";
import { getSurveyEditorPath } from "../../views/SurveyDetails";
import Button, { TooltipButton } from "../core/Button";
import { Link } from "react-router-dom";
import { Location } from "history";
import { vsaqQuestionType } from "../../../survey_builder/vsaq/vsaqTypes";
import { getDefaultTextForNodeType } from "../../../survey_viewer/surveyViewer.helpers";

// Any messages not specific to questions get assigned to the general group
export const GENERAL_GROUP_ID = "general";

// NOTE: A group of messages (in context of a survey) is either "general" or per-question.
// For the UI, we'll display messages in groups.

// Construct a message group per-question, and a "general" group for non-question-specific messages
export const getGroupsForMessages = (
  messages: ICorrespondenceMessage<SurveyQuestionMessageMeta>[],
  addNewSurveyMessage: (
    parentId: number | undefined,
    content: string,
    isPrivate: boolean,
    questionId?: string
  ) => Promise<any>,
  alwaysIncludeGeneralGroup?: boolean
) => {
  let groups: MessageGroup[] = [];

  const groupIdQuestionNumMap: { [groupId: string]: string } = {};

  for (const m of messages) {
    if (m.meta && m.meta.questionId) {
      let group = groups.find((g) => g.id === m.meta?.questionId);

      const [questionNumber, questionText] = m.meta.questionNumber
        ? [m.meta.questionNumber, m.meta.questionName || ""]
        : separateQuestionNumberFromQuestion(m.meta.questionName ?? "");

      const nodeTypeIconType = vsaqQuestionTypeToNodeTypeIconType(
        m.meta.questionType
      );

      if (!group) {
        group = {
          id: m.meta.questionId,
          hideReply: true,
          messages: [],
          headerContent: (
            <NodeTypeIcon
              nodeType={nodeTypeIconType}
              questionNumber={
                m.meta.questionType === vsaqQuestionType.Tip
                  ? "Risk"
                  : questionNumber
              }
              severity={m.meta.severity}
            />
          ),
          content: (
            <div
              dangerouslySetInnerHTML={{
                __html:
                  questionText ||
                  getDefaultTextForNodeType(
                    nodeTypeIconTypeToNodeType(
                      vsaqQuestionTypeToNodeTypeIconType(m.meta.questionType)
                    )
                  ),
              }}
            />
          ),
          onAddGroupMessage: (parentId, content, isPrivate) =>
            addNewSurveyMessage(
              parentId,
              content,
              isPrivate,
              m.meta?.questionId
            ),
        };

        groupIdQuestionNumMap[m.meta.questionId] = questionNumber;

        groups.push(group);
      }

      group.messages.push(m);
    } else {
      let group = groups.find((g) => g.id === GENERAL_GROUP_ID);

      if (!group) {
        group = {
          id: GENERAL_GROUP_ID,
          hideReply: false,
          messages: [],
          headerContent: "General",
          onAddGroupMessage: addNewSurveyMessage,
        };
        groups.push(group);
      }

      group.messages.push(m);
    }
  }

  // Put general group at start
  // Then sort by question number
  groups = groups.sort((a, b) => {
    if (a.id === GENERAL_GROUP_ID) {
      return -1;
    } else if (b.id === GENERAL_GROUP_ID) {
      return 1;
    } else {
      // Sort by question num
      const aQuestionNum = groupIdQuestionNumMap[a.id];
      const bQuestionNum = groupIdQuestionNumMap[b.id];

      return compareQuestionNum(aQuestionNum, bQuestionNum);
    }
  });

  // Add a general group if needed
  if (
    alwaysIncludeGeneralGroup &&
    !groups.find((g) => g.id === GENERAL_GROUP_ID)
  ) {
    groups.unshift({
      id: GENERAL_GROUP_ID,
      hideReply: false,
      messages: [],
      headerContent: "General",
      onAddGroupMessage: addNewSurveyMessage,
    });
  }

  return groups;
};

// Compare two question number stirngs e.g. "1.1.10.2" vs "1.1.3.0" numerically
// such that "1.1.3.0" is sorted before "1.1.10.2"
const compareQuestionNum = (
  aQeustionNum: string,
  bQuestionNum: string
): number => {
  if (aQeustionNum.indexOf(".") === -1) {
    return -1;
  } else if (bQuestionNum.indexOf(".") === -1) {
    return 1;
  }

  const aParts = aQeustionNum.split(".").filter((p) => p !== "");
  const bParts = bQuestionNum.split(".").filter((p) => p !== "");

  const aPartsAsNums = aParts.map((a) => parseInt(a, 10));
  const bPartsAsNums = bParts.map((b) => parseInt(b, 10));

  if (aPartsAsNums.some((a) => Number.isNaN(a))) {
    return -1;
  } else if (bPartsAsNums.some((b) => Number.isNaN(b))) {
    return 1;
  }

  const highestMult = Math.max(aPartsAsNums.length, bPartsAsNums.length);
  const aPartsAsNumsMult = aPartsAsNums.map(
    (a, idx) => a * Math.pow(10, highestMult - idx)
  );
  const bPartsAsNumsMult = bPartsAsNums.map(
    (b, idx) => b * Math.pow(10, highestMult - idx)
  );

  const aVal = aPartsAsNumsMult.reduce((total, a) => total + a, 0);
  const bVal = bPartsAsNumsMult.reduce((total, b) => total + b, 0);

  return aVal > bVal ? 1 : -1;
};

// Get question number + display text for a message group
export const getQuestionPartsForGroup = (group: MessageGroup) => {
  if (group.id === GENERAL_GROUP_ID) {
    return ["General", "General"];
  }

  if (!group.messages || group.messages.length === 0) {
    return ["", ""];
  }

  const m = group.messages[0];

  if (!m.meta) {
    return ["", ""];
  }

  return separateQuestionNumberFromQuestion(m.meta.questionName ?? "");
};

// Get group title
export const getGroupTitle = (
  group: MessageGroup,
  surveyId: number,
  location: Location,
  isVendorPortal: boolean
) => {
  if (group.id === GENERAL_GROUP_ID) {
    return undefined;
  } else {
    const m = group.messages[0];
    const [_, questionText] = getQuestionPartsForGroup(group);

    return !m.meta.isQuestionHidden ? (
      <Link
        to={getSurveyEditorPath({
          surveyId: surveyId,
          publicSurvey: false,
          editMode: isVendorPortal,
          location: location,
          questionId: m.meta.questionId,
          showComments: true,
        })}
      >
        <Button className={"question-link-btn"} link>
          <div dangerouslySetInnerHTML={{ __html: questionText }} />
        </Button>
      </Link>
    ) : (
      <TooltipButton
        className={"question-link-btn"}
        disabled
        link
        popupPosition={"bottom"}
        tooltipContent={
          "This question is no longer visible in the questionnaire"
        }
      >
        <div dangerouslySetInnerHTML={{ __html: questionText }} />
      </TooltipButton>
    );
  }
};
