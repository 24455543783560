import { FC } from "react";
import IconButton from "../../_common/components/IconButton";
import "../style/components/DocumentLink.scss";
import { GetIconForFilename } from "../helpers/icons";
import ColorCheckbox from "./ColorCheckbox";

interface DocumentLinkProps {
  evidenceName: string;
  filename: string;
  virusSafe: boolean;
  label?: string;
  onDownload: () => void;
  selectable?: boolean;
  isSelected?: boolean;
  onSelectToggle?: () => void;
  onView?: () => void;
}

export const DocumentLink: FC<DocumentLinkProps> = ({
  evidenceName,
  filename,
  virusSafe,
  label,
  onDownload,
  selectable,
  isSelected,
  onSelectToggle,
  onView,
}) => (
  <div className="document-link">
    {selectable && (
      <ColorCheckbox checked={isSelected} onClick={onSelectToggle} />
    )}
    <div className="filename">
      <img alt="File type icon" src={GetIconForFilename(filename)} />
      <a onClick={onDownload}>{label ?? evidenceName}</a>
    </div>
    <div className={"icons"}>
      {!!onView && (
        <IconButton icon={<i className={"cr-icon-eye"} />} onClick={onView} />
      )}
      {virusSafe && (
        <IconButton
          icon={<span className="cr-icon-export-thin" />}
          onClick={onDownload}
        />
      )}
    </div>
  </div>
);
