import { AssuranceType } from "../../../_common/types/organisations";
import TwoColumnDisplay, {
  TwoColumnDisplayRow,
} from "../../../_common/components/TwoColumnDisplay";
import TextField from "../../../_common/components/TextField";
import DatePicker from "../../../_common/components/DatePicker";
import moment from "moment";
import { Severity } from "../../../_common/types/severity";
import { WaiverType } from "../../../_common/types/vendorRiskWaivers";
import SeveritySelector from "../SeveritySelector";
import "../../style/components/risk_waivers/WaiverDetailsStep.scss";
import { SeverityChangeDisplay } from "../../../_common/components/SeverityIcon";
import { NominatedApproverFeature } from "../../../_common/api/approversAPI";
import NominatedApproverInput, {
  NominatedApproverInputProps,
} from "./NominatedApproverInput";
import { useCallback } from "react";

export const isWaiverDetailsValid = (
  approverEmailValid: boolean,
  justification: string
) => {
  switch (true) {
    case !approverEmailValid:
    case justification.length === 0:
      return false;
    default:
      return true;
  }
};

interface IWaiverDetailsStepProps {
  waiverType: WaiverType;
  approverRequired?: boolean;
  approverEmail: string;
  justification: string;
  onJustificationChanged: (value: string, valid: boolean) => void;
  onApproverChanged: (approverEmail: string, valid: boolean) => void;
  expiry?: string;
  onExpiryChanged: (expiry: string | undefined) => void;
  assuranceType: AssuranceType;
  hideApproverSettings?: boolean;
  origSeverity: Severity;
  severity?: Severity;
  onSeverityChanged: (severity: Severity) => void;
  hideSeverities?: Severity[];
}

const WaiverDetailsStep = (props: IWaiverDetailsStepProps) => {
  const handleApproverChanged = useCallback<
    NominatedApproverInputProps["onChange"]
  >(
    (email, valid) => {
      if (email) props.onApproverChanged(email, valid);
      // if email is empty, then it's valid if NOT required (optional)
      else props.onApproverChanged(email, !props.approverRequired);
    },
    [props.approverRequired, props.onApproverChanged]
  );

  return (
    <TwoColumnDisplay classname={"waiver-details-step"}>
      <TwoColumnDisplayRow
        hide={props.waiverType !== WaiverType.SeverityAdjustment}
        label={"Adjust severity"}
        content={
          <div className={"severity-selector-container"}>
            <SeverityChangeDisplay
              baseSeverity={props.origSeverity}
              alwaysShowArrow
            />
            <SeveritySelector
              severity={props.severity}
              onChange={props.onSeverityChanged}
              hideSeverities={props.hideSeverities}
            />
          </div>
        }
      />
      <TwoColumnDisplayRow
        label={"Justification"}
        content={
          <TextField
            required
            multiLine
            placeholder={"Enter your justification here"}
            value={props.justification}
            onChanged={props.onJustificationChanged}
            maxLength={4000}
            allowTextOverflow
          />
        }
      />
      <TwoColumnDisplayRow
        label={"Expiration date"}
        description={`Optional`}
        content={
          <DatePicker
            value={props.expiry}
            onChange={(e: any) => {
              if (e.target.value && e.target.value.length > 0) {
                props.onExpiryChanged(e.target.value);
              } else {
                props.onExpiryChanged(undefined);
              }
            }}
            min={moment().add(1, "d").format("YYYY-MM-DD")}
            canClear
          />
        }
      />
      <TwoColumnDisplayRow
        hide={props.hideApproverSettings}
        label="Approval"
        description={`${
          props.approverRequired ? "" : "Optional - "
        }Approvers will receive an email asking them to approve or reject the changes. Any risk changes will not be reflected until the ${
          props.waiverType === WaiverType.SeverityAdjustment
            ? "adjustment"
            : "waiver"
        } is approved.`}
        content={
          <NominatedApproverInput
            type={
              props.waiverType === WaiverType.SeverityAdjustment
                ? NominatedApproverFeature.VendorRiskRiskAdjustments
                : NominatedApproverFeature.VendorRiskRiskWaivers
            }
            value={props.approverEmail}
            onChange={handleApproverChanged}
            required={props.approverRequired}
          />
        }
      />
    </TwoColumnDisplay>
  );
};

export default WaiverDetailsStep;
