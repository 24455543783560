import { DefaultThunkDispatch } from "../../../_common/types/redux";
import { ILabel, LabelClassification } from "../../../_common/types/label";
import { useEffect, useRef, useState } from "react";
import ColorCheckbox from "../ColorCheckbox";
import PillLabel from "../PillLabel";
import Modal, { BaseModalProps } from "../../../_common/components/ModalV2";
import Button from "../../../_common/components/core/Button";
import SearchBox from "../../../_common/components/SearchBox";
import { addNewLabel } from "../../reducers/cyberRiskActions";
import "../../style/components/UpdateLabelsModal.scss";
import {
  addDefaultSuccessAlert,
  addDefaultUnknownErrorAlert,
} from "../../../_common/reducers/messageAlerts.actions";
import { sortLabels } from "./LabelChoiceSet";
import { getVendorWords } from "../../../_common/constants";
import { AssuranceType } from "../../../_common/types/organisations";
import { IItemWithLabelsAndPortfolios } from "../../../_common/types/labelsAndPortfolios";
import { appConnect } from "../../../_common/types/reduxHooks";
import { SidePopupV2 } from "../../../_common/components/DismissablePopup";

interface IUpdateLabelsModalConnectedProps {
  availableLabels: ILabel[];
  assuranceType: AssuranceType;
}

interface IUpdateLabelsModalDispatchProps {
  addSuccessAlert: (message: string) => void;
  addErrorAlert: (message: string) => void;
  addNewLabel: (
    label: string,
    classification: LabelClassification
  ) => Promise<ILabel>;
}

interface IUpdateLabelsModalOwnProps extends BaseModalProps {
  classification: LabelClassification;
  selectedItems: IItemWithLabelsAndPortfolios[];
  onUpdate: (
    availableLabels: ILabel[],
    addedLabelIds: number[],
    removedLabelIds: number[]
  ) => Promise<void>;
  showSystemLabels?: boolean;
  header?: string;
  subHeader?: string;
  saveButtonText?: string;
  enableSaveWhenNoChanges?: boolean;
}

type IUpdateLabelsModalProps = IUpdateLabelsModalOwnProps &
  IUpdateLabelsModalConnectedProps &
  IUpdateLabelsModalDispatchProps;

const UpdateLabelsModal = (props: IUpdateLabelsModalProps) => {
  const [isSaving, setIsSaving] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [addedLabelIds, setAddedLabelIds] = useState<number[]>([]);
  const [removedLabelIds, setRemovedLabelIds] = useState<number[]>([]);
  const [searchValue, setSearchValue] = useState("");
  const [filteredLabels, setFilteredLabels] = useState<ILabel[]>([
    ...props.availableLabels,
  ]);

  const isMounted = useRef(true);

  // Clean up when hiding
  useEffect(() => {
    if (!props.active) {
      setAddedLabelIds([]);
      setRemovedLabelIds([]);
      setSearchValue("");
    }
  }, [props.active]);

  // Update for label management changes and search changes
  useEffect(() => {
    setFilteredLabels(
      props.availableLabels.filter(
        (l) =>
          l.name
            .toLocaleLowerCase()
            .indexOf(searchValue.toLocaleLowerCase()) !== -1
      )
    );
  }, [searchValue, props.availableLabels]);

  // After dispose, set isMounted to false - use this to avoid state updates after unMount
  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const save = () => {
    setIsSaving(true);

    props
      .onUpdate(props.availableLabels, addedLabelIds, removedLabelIds)
      .then(() => {
        props.addSuccessAlert("Updated labels");
        // Handle closing steps if the component is still mounted
        if (isMounted.current) {
          setIsSaving(false);
          props.onClose();
        }
      })
      .catch(() => {
        props.addErrorAlert("Error updating labels");
        setIsSaving(false);
      });
  };

  const addLabel = () => {
    setIsAdding(true);
    props
      .addNewLabel(searchValue, props.classification)
      .then((label: ILabel) => {
        setIsAdding(false);
        setSearchValue("");
        setAddedLabelIds([...addedLabelIds, label.id]);
      })
      .catch(() => {
        setIsAdding(false);
        props.addErrorAlert("Error adding new label");
      });
  };

  const buildLabelDisplays = (labels: ILabel[]) => {
    return labels.map((l) => {
      let isAnySelected = false;
      let isAllSelected = props.selectedItems.length > 0;

      // Get initial selection state per label
      props.selectedItems.forEach((i) => {
        const selectedForItem = i.labels
          ? i.labels.some((el) => el.id === l.id)
          : false;
        isAnySelected = isAnySelected || selectedForItem;
        isAllSelected = isAllSelected && selectedForItem;
      });

      // If not initially selected, check if we are adding it
      if (!isAllSelected && addedLabelIds.indexOf(l.id) !== -1) {
        isAllSelected = true;
      }

      // If initially selected (at least partially), see if we are removing it
      if (
        (isAnySelected || isAllSelected) &&
        removedLabelIds.indexOf(l.id) !== -1
      ) {
        isAnySelected = false;
        isAllSelected = false;
      }

      return (
        <div key={l.id}>
          <ColorCheckbox
            label={
              <SidePopupV2 text={l.description} position={"left"}>
                <PillLabel color={l.colour}>{l.name}</PillLabel>
              </SidePopupV2>
            }
            checked={isAllSelected}
            indeterminate={isAnySelected && !isAllSelected}
            onClick={() => {
              if (isAllSelected) {
                // Switch off
                setAddedLabelIds(addedLabelIds.filter((id) => id !== l.id));
                setRemovedLabelIds([...removedLabelIds, l.id]);
              } else {
                // Switch on
                setRemovedLabelIds(removedLabelIds.filter((id) => id !== l.id));
                setAddedLabelIds([...addedLabelIds, l.id]);
              }
            }}
          />
        </div>
      );
    });
  };

  const systemLabelDisplays = buildLabelDisplays(
    sortLabels(
      filteredLabels.filter(
        (l) => l.classification === LabelClassification.SystemLabel
      )
    )
  );
  const labelDisplays = buildLabelDisplays(
    sortLabels(
      filteredLabels.filter(
        (l) => l.classification !== LabelClassification.SystemLabel
      )
    )
  );

  const isNoExactLabelMatch =
    searchValue.length > 0 &&
    filteredLabels.find(
      (l) => l.name.toLocaleLowerCase() === searchValue.toLocaleLowerCase()
    ) === undefined;

  const vendorWords = getVendorWords(props.assuranceType);

  return (
    <Modal
      headerContent={props.header ?? "Update labels"}
      subHeaderContent={props.subHeader}
      className={"update-labels-modal"}
      active={props.active}
      onClose={() => props.onClose()}
      footerContent={
        <div className={"btn-group"}>
          <Button
            tertiary
            onClick={() => props.onClose()}
            disabled={isSaving || isAdding}
          >
            Cancel
          </Button>
          <Button
            filledPrimary
            onClick={save}
            loading={isSaving}
            disabled={
              isAdding ||
              (!props.enableSaveWhenNoChanges &&
                addedLabelIds.length === 0 &&
                removedLabelIds.length === 0)
            }
          >
            {props.saveButtonText ?? "Update labels"}
          </Button>
        </div>
      }
    >
      <div>
        <SearchBox
          onChanged={(val) => setSearchValue(val)}
          value={searchValue}
          placeholder={"Type to search or add a custom label"}
          maxLength={100}
        />
        {isNoExactLabelMatch && searchValue.length > 2 && (
          <Button
            tertiary
            disabled={isSaving}
            loading={isAdding}
            onClick={addLabel}
            className={"add-label-btn"}
          >
            + Create new label {`'${searchValue}'`}
          </Button>
        )}
        {filteredLabels.length > 0 && (
          <div className={"label-sets"}>
            {systemLabelDisplays.length > 0 && (
              <>
                <div
                  className={"label-header"}
                >{`${vendorWords.singularTitleCase} Relationship`}</div>
                <div className={"label-set"}>{systemLabelDisplays}</div>
                {labelDisplays.length > 0 && (
                  <div className={"label-header"}>Custom</div>
                )}
              </>
            )}

            <div className={"label-set"}>{labelDisplays}</div>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default appConnect<
  IUpdateLabelsModalConnectedProps,
  IUpdateLabelsModalDispatchProps,
  IUpdateLabelsModalOwnProps
>(
  (state, props) => {
    const systemLabels =
      props.showSystemLabels && state.cyberRisk.availableLabels
        ? state.cyberRisk.availableLabels.filter(
            (l) => l.classification === LabelClassification.SystemLabel
          )
        : [];

    const classificationCustomLabels = state.cyberRisk.availableLabels
      ? state.cyberRisk.availableLabels.filter(
          (l) => l.classification === props.classification
        )
      : [];

    return {
      availableLabels: [...systemLabels, ...classificationCustomLabels].sort(
        (a, b) => a.name.localeCompare(b.name)
      ),
      assuranceType: state.common.userData.assuranceType,
    };
  },
  (dispatch: DefaultThunkDispatch) => {
    return {
      addSuccessAlert: (message: string) =>
        dispatch(addDefaultSuccessAlert(message)),
      addErrorAlert: (message: string) =>
        dispatch(addDefaultUnknownErrorAlert(message)),
      addNewLabel: (label: string, classification: LabelClassification) =>
        dispatch(addNewLabel(label, classification)),
    };
  }
)(UpdateLabelsModal);
