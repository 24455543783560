import React, {
  FC,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { createPortal } from "react-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import Button from "./core/Button";
import Icon from "./core/Icon";
import classnames from "classnames";

import "../style/components/Modal.scss";

export const transitionTimeout = 250;

export interface BaseModalProps {
  active: boolean;
  onClose(): void;
}

type openModalProps<ModalProps> = Omit<ModalProps, keyof BaseModalProps>;

// Context provided by modals to allow components within the sub tree to determine whether they are
// inside a modal.
export const IsInsideModalContext = React.createContext(false);

// useModalV2 is a hook to provide a way to easily call up a single ModalV2 component
// with the correct props, on demand. This must be called with a reference to a functional component
// that has at least the "active" and "onClose" props defined on its interface.
// The two values returned by this function are
// 1. a method for calling up the modal with all necessary props
// 2. the rendered modal component
export const useModalV2 = <ModalProps extends BaseModalProps>(
  ModalComponent: FC<ModalProps>,
  onCloseCallback?: () => void
): [(props: openModalProps<ModalProps>) => void, ReactNode] => {
  const [active, setActive] = useState(false);
  const [modalPropsState, setModalPropsState] = useState<
    openModalProps<ModalProps> | undefined
  >();

  const openModal = useCallback((props: openModalProps<ModalProps>) => {
    setModalPropsState(props);

    // Set active in the next render so we get the transition
    setTimeout(() => setActive(true), 0);
  }, []);

  const modal: ReactNode = useMemo(
    () =>
      modalPropsState !== undefined ? (
        <ModalComponent
          {...(modalPropsState as ModalProps)}
          active={active}
          onClose={() => {
            setActive(false);
            onCloseCallback?.();
            // Wait until the component has disappeared before unsetting the props state
            setTimeout(() => setModalPropsState(undefined), transitionTimeout);
          }}
        />
      ) : null,
    [modalPropsState, active, ModalComponent, onCloseCallback]
  );

  return [openModal, modal];
};

export interface IModalProps {
  // Controls whether to hide/show the modal
  active: boolean;

  // If true, this modal is mounted inline underneath its parent's component.
  // Otherwise it is mounted below the document body via a portal.
  mountInline?: boolean;

  // Content for the header. A string will be put in an <h2> element.
  headerContent?: string | JSX.Element;

  // If showing header content as string, then optionally specify content for a sub-header description.
  subHeaderContent?: string;

  // Content for the footer. Use <div className="btn-group"> for automatic button positioning/styling.
  footerContent?: JSX.Element;

  children?: ReactNode;

  // Callback when close button is pressed
  onClose?: () => void;

  // Optional className for content container
  className?: string;

  // Optional className for footer content
  footerClassName?: string;

  // Optional className for header content
  headerClassName?: string;

  // Optional className for outer container
  containerClassName?: string;

  // If true, we won't show the close button or auto close when clicking outside of the modal.
  disallowClose?: boolean;

  // if true, we dont close the modal when clicking outside of the modal
  disallowClickOutside?: boolean;

  // If true, we won't show the close 'X' in the top right
  hideCloseButton?: boolean;

  // If true, centre the model vertically on the page
  verticalCenter?: boolean;

  // Describes how the modal should treat content when it would make the modal taller than the viewport
  // If not defined, the modal will allow content to be taller than the viewport, and will scroll the
  // viewport normally.
  // Other configuration options:
  // scrollable="content": fit the modal fully within the viewport and scroll the content of the modal
  scroll?: "content";

  // Callback for form submit (e.g. via pressing return)
  onFormDefaultSubmit?: (event: React.FormEvent<HTMLFormElement>) => void;

  // Set pixel widths for modals that are commonly used.
  // We have no standardised modal widths in the design system (yet) but when we do they should settle near these values.
  width?: 600 | 800 | 1000;
}

// Simple modal container that is not attached to Redux state. Utilises V2 styles from existing modal component.
const Modal: FC<IModalProps> = (props) => {
  const mountElement = useRef(document.createElement("div"));

  useEffect(() => {
    if (!props.disallowClose) {
      document.addEventListener("keydown", escListener);
    }

    if (!props.mountInline) {
      document.body.appendChild(mountElement.current);
    }

    if (props.active) {
      document.body.classList.add("no-scroll-modal-v2");
    }

    return () => {
      if (!props.disallowClose) {
        document.removeEventListener("keydown", escListener);
      }

      if (!props.mountInline) {
        document.body.removeChild(mountElement.current);
      }

      if (props.active) {
        document.body.classList.remove("no-scroll-modal-v2");
      }
    };
  }, [props.disallowClose, props.mountInline, props.active]);

  // Close the modal when ESC key is pressed
  const escListener = (evt: any) => {
    if (evt.which === 27 && props.active && props.onClose) {
      props.onClose();
    }
  };

  // Close modal if click outside the modal surface
  const clickOutside = (evt: any) => {
    if (
      evt.target.className.indexOf("full-page-modal v2") !== -1 &&
      props.onClose
    ) {
      props.onClose();
    }
  };

  const showCloseButton = !props.disallowClose && !props.hideCloseButton;
  const scrollContent = props.scroll === "content";

  const containerClasses = classnames(
    "modal-content-container",
    props.containerClassName,
    {
      "scroll-content": scrollContent,
      "vertical-center": props.verticalCenter,
      closable: showCloseButton,
      "empty-content": !props.children,
      "width-600": props.width === 600,
      "width-800": props.width === 800,
      "width-1000": props.width === 1000,
    }
  );

  const noFooter =
    props.footerClassName === undefined && props.footerContent === undefined;

  const modalContentClasses = classnames("modal-content", props.className, {
    "no-footer": noFooter,
  });

  const modal = (
    <IsInsideModalContext.Provider value={true}>
      <TransitionGroup>
        {props.active && (
          <CSSTransition timeout={250} classNames="fade-transition">
            <div
              className={"full-page-modal v2"}
              onMouseDown={
                props.disallowClose || props.disallowClickOutside
                  ? undefined
                  : clickOutside
              }
            >
              <div className={containerClasses}>
                {showCloseButton && (
                  <Button
                    aria-label="Close modal"
                    className={"modal-close"}
                    tertiary
                    onClick={
                      props.onClose
                        ? (ev) => {
                            if (props.onClose) {
                              ev.stopPropagation();
                              props.onClose();
                            }
                          }
                        : undefined
                    }
                  >
                    <Icon name="x" />
                  </Button>
                )}
                <form onSubmit={props.onFormDefaultSubmit}>
                  <div
                    className={`modal-header ${
                      props.headerClassName ? props.headerClassName : ""
                    }`}
                  >
                    {typeof props.headerContent === "string" ? (
                      <>
                        <h2>{props.headerContent}</h2>
                        {props.subHeaderContent && (
                          <p>{props.subHeaderContent}</p>
                        )}
                      </>
                    ) : (
                      props.headerContent
                    )}
                  </div>
                  <div className={modalContentClasses}>{props.children}</div>
                  {!noFooter && (
                    <div
                      className={`modal-footer ${
                        props.footerClassName ? props.footerClassName : ""
                      }`}
                    >
                      {props.footerContent}
                    </div>
                  )}
                </form>
              </div>
            </div>
          </CSSTransition>
        )}
      </TransitionGroup>
    </IsInsideModalContext.Provider>
  );

  if (!props.mountInline) {
    return <>{createPortal(modal, mountElement.current)}</>;
  }

  return modal;
};

export default Modal;
