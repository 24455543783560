import ColorCheckbox from "../../vendorrisk/components/ColorCheckbox";
import "../style/AnswerOption.scss";
import classnames from "classnames";
import { DiffSide } from "./nodes/baseNode";

interface AnswerOptionProps {
  idx: number;
  optionText: string;
  radio: boolean;
  selected: boolean;
  disabled: boolean;
  onClick: () => void;
  diffSide?: DiffSide;
  isSuggestion: boolean;
}

const AnswerOption = ({
  idx,
  optionText,
  radio,
  selected,
  disabled,
  onClick,
  diffSide,
  isSuggestion,
}: AnswerOptionProps) => {
  const classes = classnames("answer-option", {
    selected: selected && !isSuggestion,
    disabled: disabled,
    green: diffSide == "right",
    red: diffSide == "left",
    orange: isSuggestion && selected,
  });

  const answerOption = (
    <div className={classes}>
      <div className={"id-container"}>
        <div className={"id"}>{getOptionId(idx)}</div>
      </div>
      <div
        className={"text"}
        dangerouslySetInnerHTML={{ __html: optionText }}
      />
    </div>
  );

  return (
    <div className={"answer-option-container"}>
      <ColorCheckbox
        color={
          selected && isSuggestion
            ? "orange"
            : selected
              ? diffSide == "left"
                ? "red"
                : diffSide == "right"
                  ? "green"
                  : undefined
              : undefined
        }
        checked={selected}
        disabled={disabled}
        label={answerOption}
        radio={radio}
        onClick={!disabled ? onClick : undefined}
      />
    </div>
  );
};

export default AnswerOption;

const availableOptionIds = [
  "a",
  "b",
  "c",
  "d",
  "e",
  "f",
  "g",
  "h",
  "i",
  "j",
  "k",
  "l",
  "m",
  "n",
  "o",
  "p",
  "q",
  "r",
  "s",
  "t",
  "u",
  "v",
  "w",
  "x",
  "y",
  "z",
];

const getOptionId = (idx: number) => {
  if (idx >= availableOptionIds.length) {
    return (
      availableOptionIds[availableOptionIds.length - 1] +
      (idx - availableOptionIds.length)
    );
  } else {
    return availableOptionIds[idx];
  }
};
