import {
  VendorAssessment,
  VendorAssessmentEvidence,
  VendorAssessmentKeyRisk,
  VendorAssessmentStatus,
} from "../../types/vendorAssessments";
import { MergeTag } from "../../../_common/types/orgDefaultTexts";
import { FC, useCallback } from "react";
import VendorAssessmentV3TextCard from "./VendorAssessmentV3TextCard";
import VendorAssessmentV3EvidenceUsedCard from "./VendorAssessmentV3EvidenceUsedCard";
import VendorAssessmentV3RisksCard from "./VendorAssessmentV3RisksCard";
import {
  VendorAssessmentAnalystMode,
  VendorAssessmentCopyMap,
} from "../../views/VendorAssessmentV3";
import "../../style/components/vendor_assessment/VendorAssessmentV3AssessmentCard.scss";
import { IRisk } from "../RiskSummaryDetails";
import { RemediationRequestWithMeta } from "../../../_common/types/remediation";
import { WaiverType } from "../../../_common/types/vendorRiskWaivers";
import InfoBanner, { BannerType } from "../InfoBanner";
import { GetVulnPanel } from "../../../vendorrisk/components/DomainsAndIPsPanelGroup";

interface VendorAssessmentV3AssessmentCardProps {
  assessment: VendorAssessment;
  evidence: VendorAssessmentEvidence;
  managedOrgId?: number;
  readonly: boolean;
  risksReadOnly: boolean;
  numCurrentActiveRiskWaivers: number;
  numUnapprovedRiskWaivers: number;
  numPendingPublicRiskWaivers: number;
  mergeTags: MergeTag[];
  onEditText?: (type: string, text: string) => void;
  onSaveCopy?: (type: string, text: string) => Promise<void>;
  assessmentCopy: VendorAssessmentCopyMap;
  urlPrefix: string;
  goToWaivers: () => void;
  goToSurvey: (id: number, isPublic: boolean) => void;
  goToAdditionalEvidence: (id: number, isPublic: boolean) => void;
  goToEvidencePage: (url: string, category: string) => void;
  goToTemplates?: () => void;
  includeRiskWaivers: boolean;
  onRequestRemediationForRisk: (
    risk: IRisk,
    hostname?: string,
    surveyId?: number,
    isPublicSurvey?: boolean
  ) => void;
  onCreateVendorRiskWaiverForRisk: (
    risk: IRisk,
    hostname?: string,
    surveyId?: number,
    isPublicSurvey?: boolean,
    waiverType?: WaiverType
  ) => void;
  onOpenCloudscanPanel: (scan: string, risk: string) => void;
  orgHasCustomTemplatesAccess: boolean;
  orgHasSurveyScoresEnabled: boolean;
  remediationRequest?: RemediationRequestWithMeta;
  goToRemediationRequest?: () => void;
  analystMode?: VendorAssessmentAnalystMode;
  loading?: boolean;
  onToggleKeyRisk?: (risk: VendorAssessmentKeyRisk, selected: boolean) => void;
  keyRisks?: VendorAssessmentKeyRisk[];
  onToggleHideSection?: (type: string, hidden: boolean) => void;
  scoringAndRisksCalcComplete: boolean;
  onOpenDomainsModal: () => void;
  onSelectRisk?: (riskId: string | undefined) => void;
  onSelectedRiskParamsChange?: (
    riskPanel: JSX.Element | undefined,
    getVulnPanel?: GetVulnPanel
  ) => void;
  onOpenCVEPanelFromRiskPanel?: (
    riskId: string,
    riskTitle: string,
    vuln: string,
    verified?: boolean
  ) => void;
  onOpenCloudscanPanelFromRiskPanel?: (
    id: string,
    riskTitle: string,
    risk?: string
  ) => void;
  onClickAssetInVulnPanel?: (currentPanelTitle: string, asset: string) => void;
}

const VendorAssessmentV3AssessmentCard: FC<
  VendorAssessmentV3AssessmentCardProps
> = ({
  assessment,
  evidence,
  managedOrgId,
  numCurrentActiveRiskWaivers,
  numUnapprovedRiskWaivers,
  numPendingPublicRiskWaivers,
  mergeTags,
  onEditText,
  onSaveCopy,
  readonly,
  risksReadOnly,
  assessmentCopy,
  goToWaivers,
  goToSurvey,
  goToAdditionalEvidence,
  goToEvidencePage,
  urlPrefix,
  goToTemplates,
  includeRiskWaivers,
  onRequestRemediationForRisk,
  onCreateVendorRiskWaiverForRisk,
  onOpenCloudscanPanel,
  orgHasCustomTemplatesAccess,
  orgHasSurveyScoresEnabled,
  remediationRequest,
  goToRemediationRequest,
  analystMode,
  loading,
  onToggleKeyRisk,
  keyRisks,
  onToggleHideSection,
  scoringAndRisksCalcComplete,
  onOpenDomainsModal,
  onSelectRisk,
  onSelectedRiskParamsChange,
  onOpenCVEPanelFromRiskPanel,
  onOpenCloudscanPanelFromRiskPanel,
  onClickAssetInVulnPanel,
}) => {
  const published = assessment.status === VendorAssessmentStatus.Published;
  const waiverCount = published
    ? assessment.numWaivers ?? 0
    : numCurrentActiveRiskWaivers;

  const saveCopy = useCallback(
    (type: string, value: string) => {
      if (onSaveCopy) {
        return onSaveCopy(type, value);
      }

      return Promise.resolve();
    },
    [onSaveCopy]
  );

  return (
    <div className={"vendor-assessment-v3-assessment-card"}>
      {!scoringAndRisksCalcComplete && !readonly && (
        <InfoBanner
          type={BannerType.INFO}
          message={
            "We are calculating the score for this assessment. You'll be able to publish it once this is complete."
          }
        />
      )}
      {"intro" in assessmentCopy && (
        <VendorAssessmentV3TextCard
          copyType={"intro"}
          hideEditButton={readonly}
          hideSectionToggle={readonly}
          goToTemplates={goToTemplates}
          title="Introduction"
          text={assessmentCopy["intro"] ?? ""}
          mergeTags={mergeTags}
          setText={onEditText ? (val) => onEditText("intro", val) : undefined}
          onSave={saveCopy}
          orgHasCustomTemplatesAccess={orgHasCustomTemplatesAccess}
          loading={loading}
          sectionHidden={assessment.hideIntroduction}
          onToggleHideSection={onToggleHideSection}
        />
      )}
      {"background" in assessmentCopy && (
        <VendorAssessmentV3TextCard
          copyType={"background"}
          hideEditButton={readonly}
          hideSectionToggle={readonly}
          goToTemplates={goToTemplates}
          title="Vendor background"
          text={assessmentCopy["background"] ?? ""}
          mergeTags={mergeTags}
          setText={
            onEditText ? (val) => onEditText("background", val) : undefined
          }
          onSave={saveCopy}
          orgHasCustomTemplatesAccess={orgHasCustomTemplatesAccess}
          loading={loading}
          sectionHidden={assessment.hideBackground}
          onToggleHideSection={onToggleHideSection}
        />
      )}
      <VendorAssessmentV3EvidenceUsedCard
        evidence={evidence}
        assessment={assessment}
        goToAdditionalEvidence={goToAdditionalEvidence}
        goToEvidencePage={goToEvidencePage}
        goToSurvey={goToSurvey}
        goToWaivers={goToWaivers}
        numRiskWaivers={waiverCount}
        numPendingPublicRiskWaivers={numPendingPublicRiskWaivers}
        numUnapprovedRiskWaivers={numUnapprovedRiskWaivers}
        orgHasSurveyScoresEnabled={orgHasSurveyScoresEnabled}
        onOpenDomains={onOpenDomainsModal}
      />
      <VendorAssessmentV3RisksCard
        readonly={readonly || risksReadOnly}
        urlPrefix={urlPrefix}
        assessmentId={assessment.id}
        isPublishedAssessment={
          assessment.status === VendorAssessmentStatus.Published
        }
        vendorId={assessment.datastoreVendorID}
        managedOrgId={managedOrgId}
        includeRiskWaivers={includeRiskWaivers}
        onRequestRemediationForRisk={onRequestRemediationForRisk}
        onCreateVendorRiskWaiverForRisk={onCreateVendorRiskWaiverForRisk}
        onOpenCloudscanPanel={onOpenCloudscanPanel}
        remediationRequest={remediationRequest}
        goToRemediationRequest={goToRemediationRequest}
        onToggleKeyRisk={onToggleKeyRisk}
        keyRisks={keyRisks}
        displayKeyRisks={!(readonly && (keyRisks?.length ?? 0) == 0)}
        enableKeyRisks={!readonly}
        calculatingRisks={
          !assessment.includeAllHostnames && !scoringAndRisksCalcComplete
        }
        isManagedAssessment={!!assessment.managedAssessmentId || !!managedOrgId}
        onSelectRisk={onSelectRisk}
        onSelectedRiskParamsChange={onSelectedRiskParamsChange}
        onOpenCVEPanelFromRiskPanel={onOpenCVEPanelFromRiskPanel}
        onOpenCloudscanPanelFromRiskPanel={onOpenCloudscanPanelFromRiskPanel}
        onClickAssetInVulnPanel={onClickAssetInVulnPanel}
      />
      {"commentary" in assessmentCopy && (
        <VendorAssessmentV3TextCard
          copyType={"commentary"}
          hideEditButton={readonly}
          hideSectionToggle={readonly}
          goToTemplates={goToTemplates}
          title="Assessment findings"
          text={assessmentCopy["commentary"] ?? ""}
          mergeTags={mergeTags}
          setText={
            onEditText ? (val) => onEditText("commentary", val) : undefined
          }
          onSave={saveCopy}
          orgHasCustomTemplatesAccess={orgHasCustomTemplatesAccess}
          loading={loading}
          sectionHidden={assessment.hideCommentary}
          onToggleHideSection={onToggleHideSection}
        />
      )}
      {"conclusion" in assessmentCopy && (
        <VendorAssessmentV3TextCard
          copyType={"conclusion"}
          hideEditButton={readonly}
          hideSectionToggle={readonly}
          goToTemplates={goToTemplates}
          title="Conclusion"
          text={assessmentCopy["conclusion"] ?? ""}
          mergeTags={mergeTags}
          setText={
            onEditText ? (val) => onEditText("conclusion", val) : undefined
          }
          onSave={saveCopy}
          orgHasCustomTemplatesAccess={orgHasCustomTemplatesAccess}
          loading={loading}
          sectionHidden={assessment.hideConclusion}
          onToggleHideSection={onToggleHideSection}
        />
      )}
      {(analystMode !== VendorAssessmentAnalystMode.None ||
        !!assessment.customerCommentary) && (
        <VendorAssessmentV3TextCard
          copyType={"customerCommentary"}
          hideEditButton={analystMode !== VendorAssessmentAnalystMode.Open}
          hideSectionToggle={analystMode !== VendorAssessmentAnalystMode.Open}
          goToTemplates={goToTemplates}
          title="Customer Commentary"
          text={assessmentCopy["customerCommentary"] ?? ""}
          mergeTags={mergeTags}
          setText={
            onEditText
              ? (val) => {
                  onEditText("customerCommentary", val);
                }
              : undefined
          }
          onSave={saveCopy}
          orgHasCustomTemplatesAccess={orgHasCustomTemplatesAccess}
          loading={loading}
          onToggleHideSection={onToggleHideSection}
        />
      )}
    </div>
  );
};

export default VendorAssessmentV3AssessmentCard;
