import {
  clearedCloudConnectionsFilters,
  clearedDataLeaksFilters,
  clearedSubsidiaryFilters,
  clearedSurveyStatusFilters,
  clearedUserRiskFilters,
  clearedVendorFilters,
  clearedVulnsFilters,
  clearedWebsiteFilters,
  FilterTypes,
} from "./types";
import { DefaultThunkDispatchProp } from "../../../_common/types/redux";
import { FC } from "react";
import { setCustomerDataFiltersAndRefreshData } from "../../reducers/cyberRiskActions";
import {
  getFiltersFromState,
  setVendorDataFiltersAndRefreshData,
} from "../../reducers/filters.actions";

import { isFilterActive } from "./selectors";
import { appConnect } from "../../../_common/types/reduxHooks";
import classnames from "classnames";
import { MultiProductNavContentLeftClass } from "../../../_common/helpers/navigation.helpers";

interface IFilterBarOwnProps {
  isCustomer?: boolean;
  vendorId?: number;
  isSubsidiary?: boolean;
  onClearFilter?: () => void;
  supportedFilters: FilterTypes[];
}

interface IFilterBarConnectedProps {
  active: boolean;
}

type IFilterBarProps = IFilterBarOwnProps &
  IFilterBarConnectedProps &
  DefaultThunkDispatchProp;

export const FilterBar: FC<IFilterBarProps> = ({
  dispatch,
  isCustomer = false,
  onClearFilter,
  active,
  supportedFilters,
  vendorId,
  isSubsidiary = false,
}) => (
  <div
    id="filter_view_frame"
    className={classnames(
      active ? "active" : "",
      MultiProductNavContentLeftClass
    )}
    onClick={() => {
      let clearedFilters = {
        riskCategories: [] as string[],
        vendorIds: [] as number[],
        riskIds: [] as string[],
      };
      if (
        supportedFilters.includes(FilterTypes.WEBSITE_LABEL) ||
        supportedFilters.includes(FilterTypes.WEBSITE_LABEL_ADVANCED)
      ) {
        clearedFilters = { ...clearedFilters, ...clearedWebsiteFilters };
      }
      if (
        supportedFilters.includes(FilterTypes.SUBSIDIARY_LABEL) ||
        supportedFilters.includes(FilterTypes.SUBSIDIARY_COUNTRY) ||
        supportedFilters.includes(FilterTypes.SUBSIDIARY_SCORE)
      ) {
        clearedFilters = { ...clearedFilters, ...clearedSubsidiaryFilters };
      }
      if (
        supportedFilters.includes(FilterTypes.VENDOR_LABEL) ||
        supportedFilters.includes(FilterTypes.SCORE) ||
        supportedFilters.includes(FilterTypes.VENDOR_TIER) ||
        supportedFilters.includes(FilterTypes.VENDOR_ASSESSMENT_STATUS) ||
        supportedFilters.includes(FilterTypes.VENDOR_ASSESSMENT_AUTHOR) ||
        supportedFilters.includes(FilterTypes.VENDOR_REASSESSMENT_DATE) ||
        supportedFilters.includes(FilterTypes.VENDOR_PORTFOLIO) ||
        supportedFilters.includes(FilterTypes.VENDOR_ATTRIBUTES) ||
        supportedFilters.includes(FilterTypes.VENDOR_SURVEY_TYPES) ||
        supportedFilters.includes(FilterTypes.VENDOR_EVIDENCE_TYPES) ||
        supportedFilters.includes(FilterTypes.VENDOR_DATE_ADDED)
      ) {
        clearedFilters = { ...clearedFilters, ...clearedVendorFilters };
      }
      if (
        supportedFilters.includes(FilterTypes.VULNS_CVE_NAME) ||
        supportedFilters.includes(FilterTypes.VULNS_SOFTWARE) ||
        supportedFilters.includes(FilterTypes.VULNS_VERIFIED_STATUS) ||
        supportedFilters.includes(FilterTypes.VULNS_CVSS_SEVERITY) ||
        supportedFilters.includes(FilterTypes.VULNS_EPSS_SEVERITY)
      ) {
        clearedFilters = { ...clearedFilters, ...clearedVulnsFilters };
      }

      if (supportedFilters.includes(FilterTypes.SURVEY_STATUS)) {
        clearedFilters = { ...clearedFilters, ...clearedSurveyStatusFilters };
      }

      if (isCustomer) {
        if (
          supportedFilters.includes(FilterTypes.DATALEAKS_SOURCE) ||
          supportedFilters.includes(FilterTypes.DATALEAKS_KEYWORD) ||
          supportedFilters.includes(FilterTypes.DATALEAKS_CLASSIFICATION) ||
          supportedFilters.includes(FilterTypes.DATALEAKS_REPORTED_DATE)
        ) {
          clearedFilters = { ...clearedFilters, ...clearedDataLeaksFilters };
        }

        if (
          supportedFilters.includes(
            FilterTypes.APPGUARD_CLOUD_CONNECTION_PROVIDER_TYPE
          ) ||
          supportedFilters.includes(FilterTypes.APPGUARD_CLOUD_CONNECTION_UUID)
        ) {
          clearedFilters = {
            ...clearedFilters,
            ...clearedCloudConnectionsFilters,
          };
        }

        if (
          supportedFilters.includes(FilterTypes.USERRISK_USER_UUIDS) ||
          supportedFilters.includes(FilterTypes.USERRISK_APPS) ||
          supportedFilters.includes(FilterTypes.USERRISK_TEAMS) ||
          supportedFilters.includes(FilterTypes.USERRISK_ROLES) ||
          supportedFilters.includes(FilterTypes.USERRISK_APP_STATUS) ||
          supportedFilters.includes(FilterTypes.USERRISK_APP_APPROVED_TEAMS) ||
          supportedFilters.includes(FilterTypes.USERRISK_APP_APPROVED_ROLES) ||
          supportedFilters.includes(FilterTypes.USERRISK_APP_FIRST_DETECTED) ||
          supportedFilters.includes(FilterTypes.USERRISK_APP_RISK_LEVEL) ||
          supportedFilters.includes(FilterTypes.USERRISK_SCOPE_RISK_LEVEL) ||
          supportedFilters.includes(FilterTypes.USERRISK_SCOPE_READWRITE) ||
          supportedFilters.includes(FilterTypes.USERRISK_USER_SCORES) ||
          supportedFilters.includes(FilterTypes.USERRISK_APP_SCORES)
        ) {
          clearedFilters = {
            ...clearedFilters,
            ...clearedUserRiskFilters,
          };
        }

        dispatch(setCustomerDataFiltersAndRefreshData(clearedFilters));
      }

      if (vendorId) {
        dispatch(
          setVendorDataFiltersAndRefreshData(
            vendorId,
            isSubsidiary,
            clearedFilters
          )
        );
      }

      if (onClearFilter) {
        onClearFilter();
      }
    }}
  >
    <div className="regular-text">Filters active</div>
    <div className="hover-text">Clear filters</div>
  </div>
);

export const FilterBarContainer = appConnect<
  IFilterBarConnectedProps,
  never,
  IFilterBarOwnProps
>((state, props) => {
  const filters = getFiltersFromState(
    state,
    props.vendorId,
    props.isSubsidiary
  );

  return {
    active: isFilterActive(filters, props.supportedFilters),
  };
})(FilterBar);
