import classnames from "classnames";
import { FC, useState } from "react";
import XTable, {
  ISortedBy,
  IXTableColumnHeader,
  IXTableRow,
  SortDirection,
  XTableCell,
} from "../../_common/components/core/XTable";
import { sortBy as _sortBy } from "lodash";
import PillLabel from "./PillLabel";
import LoadingIcon from "../../_common/components/core/LoadingIcon";
import "../style/views/AdditionalEvidenceDocumentsTab.scss";
import { DocumentTypes } from "../views/AdditionalEvidenceDocumentsTab";
import { LabelColor } from "../../_common/types/label";
import { getCyberRiskAuth } from "../../_common/session";
import { trackEvent } from "../../_common/tracking";
import { formatDateAsLocal } from "../../_common/helpers";
import { DefaultThunkDispatch } from "../../_common/types/redux";
import { History } from "history";
import { useConfirmationModalV2 } from "../../_common/components/modals/ConfirmationModalV2";
import { getVendorWords } from "../../_common/constants";
import { conditionalRefreshActivityStreamForOrgUser } from "../../_common/reducers/commonActions";
import {
  deleteAdditionalEvidenceForVendor,
  fetchVendorSummaryAndCloudscans,
} from "../reducers/cyberRiskActions";
import { addDefaultUnknownErrorAlert } from "../../_common/reducers/messageAlerts.actions";
import { AssuranceType } from "../../_common/types/organisations";
import UserAvatar from "../../_common/components/UserAvatar";

import {
  AdditionalEvidenceSummary,
  getAdditionalEvidenceSummaryStatus,
  isAdditionalEvidenceExpired,
  SharedAdditionalEvidence,
  AdditionalEvidenceStatus,
  AdditionalEvidenceRequestStatus,
} from "../types/additionalEvidence";
import { archiveAdditionalEvidenceForVendor } from "../reducers/additionalEvidence.actions";
import { archiveSharedDocumentForVendor } from "../reducers/vendorDocuments.actions";
import { GetIconForFilename } from "../helpers/icons";
import { invalidateVendorAssessmentDraftsForVendor } from "../reducers/vendorAssessmentAPI";
import ManualRisksAPI, { ManualRiskListTag } from "../reducers/manualRisksAPI";

export const DocumentTypePill = ({ doctype }: { doctype: string }) => {
  const docLabelMeta = DocumentTypes(doctype);
  return <PillLabel color={docLabelMeta.color}>{docLabelMeta.label}</PillLabel>;
};

export const IncludePill = ({ include }: { include: boolean }) => {
  if (include) {
    return (
      <PillLabel color={LabelColor.Blue} className={"pill"}>
        {"Included"}
      </PillLabel>
    );
  } else {
    return (
      <PillLabel color={LabelColor.Grey} className={"pill"}>
        {"Not included"}
      </PillLabel>
    );
  }
};

export const AdditionalEvidenceStatusPill = ({
  status,
}: {
  status: AdditionalEvidenceStatus;
}) => {
  switch (status) {
    case "virus":
      return (
        <PillLabel color={LabelColor.Red} className={"pill"}>
          {"Virus detected"}
        </PillLabel>
      );
    case "scanning":
      return (
        <PillLabel color={LabelColor.Grey} className={"pill"}>
          <div className={"scanning"}>
            <LoadingIcon />
            {"Virus scanning"}
          </div>
        </PillLabel>
      );
    case "archived":
      return (
        <PillLabel color={LabelColor.Orange} className={"pill"}>
          {"Archived"}
        </PillLabel>
      );
    case "active":
      return (
        <PillLabel color={LabelColor.Green} className={"pill"}>
          {"Active"}
        </PillLabel>
      );
    case "requested":
      return (
        <PillLabel color={LabelColor.Orange} className={"pill"}>
          {"Requested"}
        </PillLabel>
      );
    case "ready to review":
      return (
        <PillLabel color={LabelColor.Blue} className={"pill"}>
          {"Ready to review"}
        </PillLabel>
      );
    case "in review":
      return (
        <PillLabel color={LabelColor.Blue} className={"pill"}>
          {"In review"}
        </PillLabel>
      );
    case "complete":
      return (
        <PillLabel color={LabelColor.Green} className={"pill"}>
          {"Complete"}
        </PillLabel>
      );
    case "expired":
      return (
        <PillLabel color={LabelColor.Red} className={"pill"}>
          EXPIRED
        </PillLabel>
      );
    case "unknown":
      return (
        <PillLabel color={LabelColor.Grey} className={"pill"}>
          UNKNOWN
        </PillLabel>
      );
  }
};

const getStatus = (evidence: AdditionalEvidenceSummary) => {
  const status = getAdditionalEvidenceSummaryStatus(evidence);

  return <AdditionalEvidenceStatusPill status={status} />;
};

interface AdditionalEvidenceListTableProps {
  dispatch: DefaultThunkDispatch;
  history: History;
  loading: boolean;
  userHasWritePermission: boolean;
  evidence: (AdditionalEvidenceSummary | SharedAdditionalEvidence)[];
  mayHaveSharedAssets: boolean;
  stickyColumnHeaders?: boolean;
  mode?: string;
  downloadEvidence: (evidence: AdditionalEvidenceSummary) => void;
  displayEvidenceDetails: (evidence: AdditionalEvidenceSummary) => void;
  displaySharedAssetsDetails: (orgID: number) => void;
  displaySharedProfileDetails: () => void;
  vendorId: number;
  vendorName: string;
  assuranceType: AssuranceType;
  userHasVendorAssessmentPermission: boolean;
}

const AdditionalEvidenceListTable: FC<AdditionalEvidenceListTableProps> = ({
  dispatch,
  loading,
  userHasWritePermission,
  evidence,
  stickyColumnHeaders = false,
  // mode = "active",
  downloadEvidence,
  displayEvidenceDetails,
  displaySharedAssetsDetails,
  displaySharedProfileDetails,
  vendorId,
  vendorName,
  assuranceType,
  userHasVendorAssessmentPermission,
}) => {
  const [openConfirmationModal, confirmationModalComponent] =
    useConfirmationModalV2();

  const [sortedBy, setSortedBy] = useState<ISortedBy>({
    columnId: "created_at",
    direction: SortDirection.ASC,
  });

  const downloadSharedProfileDocument = (gcsObjectName: string) => {
    const { apiKey, token } = getCyberRiskAuth();
    const url = `/api/sharedassessment/documents/v1/?apikey=${encodeURIComponent(
      apiKey
    )}&token=${encodeURIComponent(token)}&vendor_id=${encodeURIComponent(
      vendorId
    )}&object=${encodeURIComponent(gcsObjectName)}`;
    window.open(url, "_blank");
    trackEvent("VerifiedVendor_DownloadDocument");
  };

  const deleteEvidence = (evidence: AdditionalEvidenceSummary) => {
    const vendorWords = getVendorWords(assuranceType);

    if (evidence.deletable) {
      openConfirmationModal({
        title: "Delete additional evidence",
        description:
          "Are you sure you want to completely remove this additional evidence?",
        buttonText: "Yes, delete",
        dangerousAction: true,
        cancelText: "Cancel",
        buttonAction: async () => {
          try {
            await dispatch(
              deleteAdditionalEvidenceForVendor(
                evidence.datastoreVendorID,
                evidence.id
              )
            ).then(() => {
              if (userHasVendorAssessmentPermission) {
                dispatch(
                  invalidateVendorAssessmentDraftsForVendor(
                    evidence.datastoreVendorID
                  )
                );
              }
              // update the vendor summary data
              dispatch(
                fetchVendorSummaryAndCloudscans(
                  evidence.datastoreVendorID,
                  true
                )
              );
              // refresh the activity stream for the current user so we can accurately display the tasks badge
              dispatch(conditionalRefreshActivityStreamForOrgUser());

              // Refresh the additional evidence risks
              dispatch(ManualRisksAPI.util.invalidateTags([ManualRiskListTag]));
            });
          } catch (e) {
            dispatch(
              addDefaultUnknownErrorAlert(
                `An error occurred removing the selected evidence from this ${vendorWords.singular}`
              )
            );
            throw e;
          }
        },
      });
    } else {
      openConfirmationModal({
        title: "Delete additional evidence",
        description: `Sorry, but this evidence is currently referenced by a published ${vendorWords.singular} assessment. As such, it cannot be deleted.`,
        buttonText: "OK",
        hideCancel: true,
        buttonAction: async () => {
          // left empty
        },
      });
    }
  };

  const archiveEvidence = (
    evidence: AdditionalEvidenceSummary | SharedAdditionalEvidence,
    archive: boolean
  ) => {
    let noun = "archiving";
    if (!archive) {
      noun = "unarchiving";
    }

    const vendorWords = getVendorWords(assuranceType);

    const archiveFn = () => {
      if (
        evidence.kind === "shared_evidence" &&
        evidence.isSharedProfileSupportingDocumentation
      ) {
        return archiveSharedDocumentForVendor(
          evidence.datastoreVendorID,
          evidence.gcsObjectName,
          archive
        );
      }
      return archiveAdditionalEvidenceForVendor(
        evidence.datastoreVendorID,
        evidence.id,
        archive,
        evidence.kind === "shared_evidence"
      );
    };

    if (archive) {
      const description = `Are you sure you want to archive this evidence? It will no longer be available for further risk assessments${
        evidence.kind === "evidence"
          ? ` and any identified risks will not be shown in the ${vendorWords.singular}'s risk profile`
          : ""
      }.`;

      openConfirmationModal({
        title: "Archive additional evidence",
        description: description,
        buttonText: "Yes, archive",
        dangerousAction: true,
        buttonAction: async () => {
          try {
            await dispatch(archiveFn()).then(() => {
              // update the draft vendor assessment data
              if (userHasVendorAssessmentPermission) {
                dispatch(
                  invalidateVendorAssessmentDraftsForVendor(
                    evidence.datastoreVendorID
                  )
                );
              }
              // update the vendor summary data
              dispatch(
                fetchVendorSummaryAndCloudscans(
                  evidence.datastoreVendorID,
                  true
                )
              );
              // refresh the activity stream for the current user so we can accurately display the tasks badge
              dispatch(conditionalRefreshActivityStreamForOrgUser());
            });
          } catch (e) {
            console.error(e);
            dispatch(
              addDefaultUnknownErrorAlert(
                `An error occurred ${noun} the selected evidence`
              )
            );
            throw e;
          }
        },
      });
    } else {
      try {
        dispatch(archiveFn()).then(() => {
          // update the draft vendor assessment data
          if (userHasVendorAssessmentPermission) {
            dispatch(
              invalidateVendorAssessmentDraftsForVendor(
                evidence.datastoreVendorID
              )
            );
          }
          // update the vendor summary data
          dispatch(
            fetchVendorSummaryAndCloudscans(evidence.datastoreVendorID, true)
          );
        });
      } catch (e) {
        console.error(e);
        dispatch(
          addDefaultUnknownErrorAlert(
            `An error occurred ${noun} the selected evidence`
          )
        );
        throw e;
      }
    }
  };

  const getSortedEvidence = () => {
    // sort by name first so that when we stable sort it stays sorted by name second
    let sortedEvidence = _sortBy(evidence, (ev) => ev.name.toLowerCase());
    switch (sortedBy.columnId) {
      case "document_type":
        sortedEvidence = _sortBy(sortedEvidence, ["documentTypeName"]);
        break;
      case "total_risks":
        sortedEvidence = _sortBy(sortedEvidence, (ev) =>
          ev.kind == "evidence" || !ev.isSharedProfileSupportingDocumentation
            ? ev.totalRisks
            : 0
        );
        break;
      case "include_in_risk_profile":
        sortedEvidence = _sortBy(sortedEvidence, (e) =>
          e.includeInRiskProfile ? "included" : "not included"
        );
        break;
      case "created_at":
        sortedEvidence = _sortBy(sortedEvidence, (e) => {
          if (
            e.requestStatus === AdditionalEvidenceRequestStatus.Requested ||
            e.requestStatus === AdditionalEvidenceRequestStatus.Review
          ) {
            return !e.initialDocumentUploadedAt
              ? null
              : new Date(e.initialDocumentUploadedAt);
          } else {
            return !e.createdAt ? null : new Date(e.createdAt);
          }
        });
        break;
      case "expiry_date":
        sortedEvidence = _sortBy(sortedEvidence, (e) =>
          e.expiry ? new Date(e.expiry) : null
        );
        break;
      case "source":
        sortedEvidence = _sortBy(sortedEvidence, (e) =>
          e.kind === "shared_evidence"
            ? e.isSharedProfileSupportingDocumentation
              ? vendorName
              : e.orgName
            : e.uploadedByName
        );
        break;
      default:
        sortedEvidence = [...sortedEvidence];
        break;
    }

    if (sortedBy.direction !== "asc") {
      sortedEvidence.reverse();
    }
    return sortedEvidence;
  };

  const getRows = (): IXTableRow[] => {
    return getSortedEvidence().map((evidence) => {
      const isEvidenceRequest = evidence.requestStatus !== "";
      const isEvidenceRequestRequested =
        evidence.requestStatus === AdditionalEvidenceRequestStatus.Requested;
      const iconOptions = [];
      if (
        evidence.kind == "shared_evidence" &&
        evidence.mustRequest &&
        userHasWritePermission
      ) {
        iconOptions.push({
          id: "request",
          hoverText: "Request access",
          icon: <div className={"cr-icon-lock"} />,
          onClick: evidence.isSharedProfileSupportingDocumentation
            ? displaySharedProfileDetails
            : () => displaySharedAssetsDetails(evidence.orgId),
        });
      }
      if (
        userHasWritePermission &&
        (evidence.kind === "evidence" ||
          (!evidence.isSharedEvidence &&
            !evidence.isSharedProfileSupportingDocumentation))
      ) {
        iconOptions.push({
          id: "delete",
          hoverText: "Delete",
          icon: <div className="cr-icon-trash" />,
          onClick: () => deleteEvidence(evidence as AdditionalEvidenceSummary),
        });
      }
      if (
        evidence.virusScanned &&
        evidence.virusSafe &&
        (evidence.kind === "evidence" || !evidence.mustRequest)
      ) {
        iconOptions.push({
          id: "download",
          hoverText: "Download",
          icon: <div className="cr-icon-download" />,
          onClick:
            evidence.kind === "shared_evidence" &&
            !!evidence.isSharedProfileSupportingDocumentation
              ? () => downloadSharedProfileDocument(evidence.gcsObjectName)
              : () => downloadEvidence(evidence as AdditionalEvidenceSummary),
        });
      }

      if (userHasWritePermission) {
        if (!evidence.archived) {
          iconOptions.push({
            id: "archive",
            hoverText: "Archive",
            icon: <div className="cr-icon-archive" />,
            onClick: () => archiveEvidence(evidence, true),
          });
        } else {
          iconOptions.push({
            id: "archive",
            hoverText: "Unarchive",
            icon: <div className="cr-icon-archive" />,
            onClick: () => archiveEvidence(evidence, false),
          });
        }
      }

      const cells = [];
      const evidenceName = isEvidenceRequestRequested ? "-" : evidence.name;

      cells.push(
        <XTableCell
          key={"name"}
          title={evidenceName}
          className={classnames("name-cell", {
            "virus-detected": evidence.virusScanned && !evidence.virusSafe,
          })}
        >
          <div className={"name-cell-content"}>
            <div className={"icon-placeholder"}>
              {GetIconForFilename(evidence.filename) && (
                <img src={GetIconForFilename(evidence.filename)} />
              )}
            </div>
            <span className={"name-text"}>{evidenceName}</span>
          </div>
        </XTableCell>,
        <XTableCell key={"document_type"} className={"document-type"}>
          {evidence.kind === "shared_evidence" &&
          evidence.isSharedProfileSupportingDocumentation ? (
            <PillLabel color={LabelColor.Grey}>
              Supporting Documentation
            </PillLabel>
          ) : (
            <DocumentTypePill doctype={evidence.documentTypeName} />
          )}
        </XTableCell>,
        <XTableCell key={"total_risks"} className={"total-risks"}>
          {isEvidenceRequestRequested ? "-" : <>{evidence.totalRisks}</>}
        </XTableCell>,
        <XTableCell key={"include_in_risk_profile"} className={"include"}>
          {isEvidenceRequestRequested
            ? "-"
            : evidence.includeInRiskProfile
              ? "Yes"
              : "No"}
        </XTableCell>,
        <XTableCell key={"status"} className={"status"}>
          {getStatus(evidence as AdditionalEvidenceSummary)}
        </XTableCell>,
        <XTableCell key={"source"} className={"source"}>
          {isEvidenceRequestRequested ? (
            "-"
          ) : evidence.kind === "shared_evidence" ? (
            evidence.isSharedProfileSupportingDocumentation ? (
              <>
                {vendorName}{" "}
                <PillLabel color={LabelColor.Grey}>Vendor</PillLabel>
              </>
            ) : (
              evidence.orgName
            )
          ) : (
            <UserAvatar
              avatar={evidence.uploadedByAvatar}
              name={evidence.uploadedByName}
            />
          )}
        </XTableCell>,
        <XTableCell key={"created_at"} className="date-due">
          {!isEvidenceRequest
            ? formatDateAsLocal(evidence.createdAt)
            : evidence.initialDocumentUploadedAt
              ? formatDateAsLocal(evidence.initialDocumentUploadedAt)
              : "-"}
        </XTableCell>,
        <XTableCell
          key={"expiry_date"}
          className={classnames({
            expiry_date: true,
            expired: isAdditionalEvidenceExpired(
              evidence as AdditionalEvidenceSummary
            ),
          })}
        >
          {evidence.expiry ? formatDateAsLocal(evidence.expiry) : "-"}
        </XTableCell>
      );

      return {
        id: `${evidence.kind}_${
          evidence.kind === "shared_evidence" &&
          evidence.isSharedProfileSupportingDocumentation
            ? "profile"
            : ""
        }_${evidence.id}`,
        className:
          evidence.kind === "shared_evidence" && evidence.mustRequest
            ? "disabled-row"
            : "",
        onClick:
          evidence.kind === "shared_evidence" &&
          (evidence.mustRequest ||
            evidence.isSharedProfileSupportingDocumentation)
            ? undefined
            : () => {
                displayEvidenceDetails(evidence as AdditionalEvidenceSummary);
              },
        iconOptions,
        cells: cells,
      };
    });
  };

  const columnHeaders: IXTableColumnHeader[] = [];

  columnHeaders.push(
    {
      id: "name",
      text: "Document Name",
      sortable: true,
      startingSortDir: SortDirection.ASC,
    },
    {
      id: "document_type",
      text: "Type",
      sortable: true,
      startingSortDir: SortDirection.ASC,
    },
    {
      id: "total_risks",
      text: "Risks",
      sortable: true,
      startingSortDir: SortDirection.ASC,
    },
    {
      id: "include_in_risk_profile",
      text: "Include in risk profile",
      sortable: true,
      startingSortDir: SortDirection.ASC,
    },
    {
      id: "status",
      text: "Status",
      sortable: false,
      startingSortDir: SortDirection.ASC,
    },
    {
      id: "source",
      text: "Source",
      sortable: true,
      startingSortDir: SortDirection.ASC,
    },
    {
      id: "created_at",
      text: "Date added",
      sortable: true,
      startingSortDir: SortDirection.DESC,
    },
    {
      id: "expiry_date",
      text: "Expiry Date",
      sortable: true,
      startingSortDir: SortDirection.DESC,
    }
    // TODO - do I need a column for request access?
  );

  return (
    <>
      <XTable
        className={`evidence-list-table`}
        loading={loading}
        sortedBy={sortedBy}
        onSortChange={(columnId, direction) =>
          setSortedBy({ columnId, direction })
        }
        columnHeaders={columnHeaders}
        rows={getRows()}
        stickyColumnHeaders={stickyColumnHeaders}
        iconOptions
      />
      {confirmationModalComponent}
    </>
  );
};

export default AdditionalEvidenceListTable;
