import { PageTitleSectionProps } from "../../../_common/components/pageHeader/PageTitleSection";
import { IdentityBreachRouteParams } from "../../UserBaseNavItems";
import UserBaseAPI from "../../api/userbase.api";
import PageHeaderV2, {
  PageHeaderV2Props,
} from "../../../_common/components/pageHeader/PageHeaderV2";
import { FC, ReactNode } from "react";
import { useRouteMatch } from "react-router-dom";
import { HeaderActionGroup } from "../../../_common/components/pageHeader/MainSection";
import {
  NumberWithCommas,
  cloudscanSeverityNumberToText,
  pluralise,
} from "../../../_common/helpers";
import IdentityBreachSeverityIcon from "../IdentityBreaches/IdentityBreachSeverity";
import IdentityBreachDataClasses from "../IdentityBreaches/IdentityBreachDataClasses";
import "./UserBaseIdentityBreachHeader.scss";
import IdentityBreachDate from "../IdentityBreaches/IdentityBreachDate";

interface UserBaseIdentityBreachHeaderProps extends PageTitleSectionProps {}

const UserBaseIdentityBreachHeader: FC<UserBaseIdentityBreachHeaderProps> = ({
  ...restProps
}) => {
  const match = useRouteMatch<IdentityBreachRouteParams>();

  const breachID = Number(match.params.breachID);

  const { data } = UserBaseAPI.useGetUserBaseIdentityBreachSummaryV1Query({
    breachID: breachID,
  });

  const breach = data?.breach;
  const breachName = breach?.name ?? "Identity Breach";
  const breachUsers = breach?.exposuresCount ?? 0;
  const breachSeverityText = cloudscanSeverityNumberToText(
    breach?.riskScore ?? 1
  );

  const nameWidgets: ReactNode[] = [];
  const metadataWidgets: ReactNode[] = [];
  const actionGroups: HeaderActionGroup[] = [];

  const exposedUserText = `${NumberWithCommas(breachUsers)} ${pluralise(
    breachUsers,
    "user",
    "users"
  )} involved`;

  if (breach) {
    metadataWidgets.push(
      <>
        <IdentityBreachSeverityIcon breach={breach} />
        <div className="header-label">{breachSeverityText}</div>
      </>,
      <>
        <div className={`header-icon cr-icon-users-empty`} />
        <div className="header-label">{exposedUserText}</div>
      </>,
      <>
        <div className={`header-icon cr-icon-clock`} />
        <div className="header-label">
          <IdentityBreachDate breach={breach} />
        </div>
      </>,
      <IdentityBreachDataClasses breach={breach} maxWidth={250} />
    );
  }

  const pageHeaderV2Props: PageHeaderV2Props = {
    ...restProps,
    name: breachName,
    className: "userbase-identity-breaches-header",
    nameWidgets,
    actionGroups,
    metadataWidgets,
  };

  return (
    <>
      <PageHeaderV2 {...pageHeaderV2Props} />
    </>
  );
};

export default UserBaseIdentityBreachHeader;
