import { Component } from "react";
import {
  DomainScore,
  IPAddress,
  IPRange,
  ipRangeKey,
  IPSource,
} from "../../_common/types/ipAddresses";
import { DefaultThunkDispatchProp } from "../../_common/types/redux";

import LoadingBanner from "../../_common/components/core/LoadingBanner";
import { IPRangeInfoTable } from "./IPInfoTable";
import {
  fetchCustomerIPAddresses,
  fetchCustomerIPRangeDomains,
  fetchVendorIPAddresses,
  fetchVendorIPRangeDomains,
  ipAddressesState,
} from "../reducers/ipAddresses.actions";
import "../style/components/IPRangePanel.scss";
import TabButtons from "../../_common/components/TabButtons";
import { HostScoreList } from "./IPAddressPanel";
import ScrollableDiv from "./ScrollableDiv";
import { NumberWithCommas } from "../../_common/helpers";
import Button from "../../_common/components/core/Button";
import {
  getUserPermissionsForVendorFromState,
  UserBreachsightWrite,
  UserVendorRiskWrite,
} from "../../_common/permissions";
import { appConnect } from "../../_common/types/reduxHooks";

interface IIPRangePanelOwnProps {
  vendorId?: number;
  isSubsidiary: boolean;
  rangeId?: number;
  customRangeId?: number;
  onClickDomain: (domain: string) => void;
  onClickScoredIP: (ip: string) => void;
  onClickUnscoredIP: (ip: string) => void;
  onDeleteCustomRange?: (range: IPRange) => void;
  onEditCustomRange?: (range: IPRange) => void;
}

interface IIPRangePanelConnectedProps {
  ipRange?: IPRange;
  ipRangeIPAddresses?: IPAddress[];
  ipRangeDomains?: DomainScore[];
  userHasWriteVendorInfoPermission: boolean;
}

type IIPRangePanelProps = IIPRangePanelConnectedProps &
  IIPRangePanelOwnProps &
  DefaultThunkDispatchProp;

enum tab {
  ips = "ips",
  domains = "domains",
}

interface IIPRangePanelState {
  currentTab: tab;
}

class IPRangePanel extends Component<IIPRangePanelProps, IIPRangePanelState> {
  constructor(props: IIPRangePanelProps) {
    super(props);
    this.state = {
      currentTab: tab.ips,
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps: Readonly<IIPRangePanelProps>) {
    const { ipRange, vendorId, isSubsidiary } = this.props;
    if (
      prevProps.ipRange?.id !== ipRange?.id ||
      prevProps.ipRange?.customID !== ipRange?.customID ||
      prevProps.vendorId !== vendorId ||
      prevProps.isSubsidiary !== isSubsidiary
    ) {
      this.fetchData();
    }
  }

  fetchData = () => {
    if (!this.props.ipRange) {
      if (this.props.vendorId) {
        this.props.dispatch(
          fetchVendorIPAddresses(this.props.vendorId, this.props.isSubsidiary)
        );
      } else {
        this.props.dispatch(fetchCustomerIPAddresses());
      }
      return;
    }
    if (this.props.vendorId) {
      this.props.dispatch(
        fetchVendorIPRangeDomains(
          this.props.ipRange,
          this.props.vendorId,
          this.props.isSubsidiary
        )
      );
    } else {
      this.props.dispatch(fetchCustomerIPRangeDomains(this.props.ipRange));
    }
  };

  render() {
    const {
      dispatch,
      ipRange,
      ipRangeIPAddresses,
      ipRangeDomains,
      onClickDomain,
      onClickScoredIP,
      onClickUnscoredIP,
      onEditCustomRange,
      onDeleteCustomRange,
      vendorId,
      isSubsidiary,
      userHasWriteVendorInfoPermission,
    } = this.props;

    const { currentTab } = this.state;

    if (!ipRange || !ipRangeIPAddresses) {
      return <LoadingBanner />;
    }

    return (
      <ScrollableDiv newStyles>
        <div className="ip-range-panel-content">
          <div className="ip-range-header">
            <div className="ip-range-title">
              <div className="ip-range-start-end">
                <div className="ip-range-start">{ipRange.start}</div>
                <div className="ip-range-end">{ipRange.end}</div>
              </div>
              <div className="ip-range-connector" />
            </div>
            <div className="ip-range-actions">
              {onEditCustomRange && !!ipRange.customID && (
                <Button onClick={() => onEditCustomRange(ipRange)}>
                  <i className="cr-icon-edit-doc" /> Edit range
                </Button>
              )}
              {onDeleteCustomRange && !!ipRange.customID && (
                <Button tertiary onClick={() => onDeleteCustomRange(ipRange)}>
                  <i className="cr-icon-trash" /> Stop monitoring
                </Button>
              )}
            </div>
          </div>
          <div className="section-title">IP range details</div>
          <IPRangeInfoTable
            dispatch={dispatch}
            vendorId={vendorId}
            isSubsidiary={isSubsidiary}
            range={ipRange}
            showUpdateLabel={userHasWriteVendorInfoPermission}
          />
          <TabButtons
            onChangeTab={(id) => this.setState({ currentTab: id as tab })}
            tabs={[
              {
                id: tab.ips,
                text: `IP addresses (${NumberWithCommas(
                  ipRangeIPAddresses.length
                )})`,
              },
              {
                id: tab.domains,
                text: `Domains${
                  ipRangeDomains
                    ? ` (${NumberWithCommas(ipRangeDomains.length)})`
                    : ""
                }`,
              },
            ]}
            activeTabId={currentTab}
          />
          {currentTab === tab.ips && (
            <HostScoreList
              hosts={ipRangeIPAddresses.map((ip) => ({
                host: ip.ip,
                sources: ip.sources,
                score: ip.score,
                onClick: () =>
                  ip.sources.includes(IPSource.DNS)
                    ? onClickUnscoredIP(ip.ip)
                    : onClickScoredIP(ip.ip),
              }))}
              isCustomer={!vendorId}
              hostsAreIPs={true}
            />
          )}
          {currentTab === tab.domains &&
            (ipRangeDomains ? (
              <HostScoreList
                hosts={ipRangeDomains.map((d) => ({
                  host: d.domain,
                  score: d.score,
                  onClick: () => onClickDomain(d.domain),
                }))}
                isCustomer={!vendorId}
                hostsAreIPs={false}
              />
            ) : (
              <LoadingBanner />
            ))}
        </div>
      </ScrollableDiv>
    );
  }
}

const getIPsMatchingRange = (ips: IPAddress[], range: IPRange): IPAddress[] =>
  ips.filter((ip) =>
    !!range.customID
      ? ip.customRangeID === range.customID
      : ip.ipRangeID === range.id && !ip.customRangeID
  );

export default appConnect<
  IIPRangePanelConnectedProps,
  never,
  IIPRangePanelOwnProps
>((state: any, props) => {
  const isBreachSight = !props.vendorId || props.isSubsidiary;
  const isVendor = !!props.vendorId && !props.isSubsidiary;
  const userPerms = getUserPermissionsForVendorFromState(
    state,
    isVendor ? props.vendorId || 0 : 0
  );
  const userHasWriteVendorInfoPermission =
    (isBreachSight && !!userPerms[UserBreachsightWrite]) ||
    (isVendor && !!userPerms[UserVendorRiskWrite]);

  let ipsState: ipAddressesState;

  if (props.isSubsidiary && props.vendorId) {
    ipsState = state.cyberRisk.subsidiaries[props.vendorId]?.ipAddresses;
  } else if (props.vendorId) {
    ipsState = state.cyberRisk.vendors[props.vendorId]?.ipAddresses;
  } else {
    ipsState = state.cyberRisk.customerData.ipAddresses;
  }

  const ipRange = ipsState?.ipRanges?.find((r) =>
    props.customRangeId
      ? r.customID === props.customRangeId
      : r.id === props.rangeId
  );

  if (!ipRange) {
    return {
      ...props,
      userHasWriteVendorInfoPermission,
    };
  }

  let ipRangeDomains: DomainScore[];

  if (props.isSubsidiary && props.vendorId) {
    ipRangeDomains =
      state.cyberRisk.subsidiaries[props.vendorId]?.ipRangeDomains?.[
        ipRangeKey(ipRange)
      ];
  } else if (props.vendorId) {
    ipRangeDomains =
      state.cyberRisk.vendors[props.vendorId]?.ipRangeDomains?.[
        ipRangeKey(ipRange)
      ];
  } else {
    ipRangeDomains =
      state.cyberRisk.customerData.ipRangeDomains?.[ipRangeKey(ipRange)];
  }

  const ipRangeIPAddresses = ipsState?.ipAddresses
    ? getIPsMatchingRange(ipsState.ipAddresses, ipRange)
    : [];

  return {
    ipRange,
    ipRangeIPAddresses,
    ipRangeDomains,
    userHasWriteVendorInfoPermission,
  };
})(IPRangePanel);
