import { FC, useState } from "react";
import {
  OptionType,
  SelectV2Multi,
} from "../../../_common/components/SelectV2";
import { ValueType } from "react-select";
import { SlidePanelSection } from "./SlidePanelSection";
import LabelList from "../LabelList";
import {
  factCategories,
  newFactCategoryMeta,
} from "../../../_common/factCategoryHelpers";

interface IRiskCategoryFilterProps {
  selectedOptions?: string[];
  onChange: (opts: string[]) => void;
  accountHasSurveysEnabled: boolean;
  accountHasAdditionalEvidenceEnabled: boolean;
  title?: string;
  startExpanded?: boolean;
}

const RiskCategoryFilter: FC<IRiskCategoryFilterProps> = ({
  selectedOptions = [],
  onChange,
  accountHasAdditionalEvidenceEnabled,
  accountHasSurveysEnabled,
  title = "Filter by risk category",
  startExpanded = false,
}) => {
  const [expanded, setExpanded] = useState(
    startExpanded || selectedOptions.length > 0
  );

  const selectOnChange = (selectedOptions: ValueType<OptionType, true>) => {
    onChange(
      selectedOptions ? selectedOptions.map(({ value }) => value as string) : []
    );
  };

  const options = Object.entries(newFactCategoryMeta)
    .filter(([key, value]) => {
      if (!value.showInFilter) {
        return false;
      }

      if (
        key === factCategories.QuestionnaireRisks &&
        !accountHasSurveysEnabled
      ) {
        return false;
      }

      if (
        key === factCategories.AdditionalEvidenceRisks &&
        !accountHasAdditionalEvidenceEnabled
      ) {
        return false;
      }

      return true;
    })
    .map(([key, value]) => ({
      value: key,
      label: value.name,
    }));

  const selectValue = selectedOptions.map(
    (id) => options.find(({ value }) => value === id) as OptionType
  );

  const displayLabels = selectValue.map(({ value, label }) => ({
    id: value,
    name: label,
    color: "blue",
    removeable: true,
    large: true,
    constrained: true,
    onRemoveClick: () =>
      selectOnChange(selectValue.filter((option) => value !== option.value)),
  }));

  return (
    <SlidePanelSection
      title={title}
      expanded={expanded}
      toggleExpand={() => setExpanded(!expanded)}
    >
      <SelectV2Multi
        placeholder="Type to search risk categories"
        options={options}
        value={selectValue}
        onChange={selectOnChange}
        controlShouldRenderValue={false}
      />
      <LabelList labels={displayLabels} />
    </SlidePanelSection>
  );
};

export default RiskCategoryFilter;
