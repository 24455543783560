import { get as _get, merge as _merge } from "lodash";
import { LogError } from "./helpers";

let userLastActive = new Date().getTime();

export const updateUserLastActive = () => {
  userLastActive = new Date().getTime();
};

export const getUserLastActive = () => userLastActive;

export const getLocalStorageItem = (name, allowReturnUndefined = false) => {
  try {
    const res = JSON.parse(localStorage.getItem(name));
    if (res !== null && res !== undefined) {
      return res;
    }
  } catch (err) {
    LogError(`Error getting ${name} from localStorage`, err);
  }

  return allowReturnUndefined ? undefined : {};
};

export const getLocalStorageItemString = (name) => {
  return localStorage.getItem(name);
};

export const setLocalStorageItem = (name, obj) => {
  try {
    const item = JSON.stringify(obj);
    localStorage.setItem(name, item);
  } catch (err) {
    LogError(`Error setting ${name} in localStorage`, err);
  }
};

export const setLocalStorageItemString = (name, value) => {
  localStorage.setItem(name, value);
};

export const mergeLocalStorageItem = (name, obj) => {
  const item = getLocalStorageItem(name);
  // Lodash merge mutates item
  _merge(item, obj);
  setLocalStorageItem(name, item);
};

export const clearLocalStorageItem = (name) => {
  localStorage.setItem(name, null);
  localStorage.removeItem(name);
};

// Initialise an event listener for when a localStorage item changes.
// Callback will be called with the new value of the item.
// The callback will only get called if another window makes a change, not this one.
export const watchLocalStorageItem = (name, callback) => {
  window.addEventListener("storage", (e) => {
    if (e.key === name) {
      let item;
      if (e.newValue == null) {
        callback(null);
        return;
      }

      try {
        item = JSON.parse(e.newValue);
      } catch (err) {
        LogError("Error parsing json of localStorage item", err);
        return;
      }

      callback(item);
    }
  });
};

export const getCyberRiskAuth = () => {
  try {
    return JSON.parse(localStorage.getItem("cyberRiskAuth")) || {};
  } catch (e) {
    return {};
  }
};

//
// Session implementation for Auth0-based applications
// ---------------------------------------------------
//
// This JS file provides helper functions for maintaining a valid set of session data for am Auth0-based
// react application. The entire session as currently stored can be retrieved using function getCRSession().
// The session is primarily used to store the Authed session token for the application, and this token
// is added to the session using function PersistAuthTokenToCRSession().
//
// In addition to the CR token that forms the basis for an application session, the actual Auth0 lock wrapper
// (class VendorAuth) also uses the session to store its identity information during the login and verification processes.
// functions PersistAuth0CRSessionAttributes() and GetAuth0CRSessionAttribute() are used specifically for this purpose.
//
// When the session expires, the function RemoveAllAuthFromCRSession() is used to clear out both the CR token related
// data ad all the Auth0 related identity information.
//
// In addition to auth-related information, general attributes can also be persisted int he session temporarily
// using functions SetCRSessionAttribute(), GetCRSessionAttribute() and RemoveCRSessionAttribute(). These functions are used
// for the purpose of backing up and restoring the react application state when processing the expiry of the current
// session. This application-specific logic can use specialised helpers BackupStateToCRSession() and RestoreStateFromCRSession()
// for this purpose (these ar ebuilt on top of the generic attribute storage functions).
//

// get the complete set of all stored session information
export const GetCRSession = () => {
  const dat = sessionStorage.getItem("CRToken");
  if (dat) {
    try {
      const session = JSON.parse(dat);
      if (session) {
        return session;
      }
    } catch (err) {
      LogError("GetCRSession() - session data is malformed JSON", err);
    }
  }
  return {};
};

// clean out all stored session information
export const RemoveCRSession = () => {
  sessionStorage.removeItem("CRToken");
  // logToConsole("RemoveCRSession() - CR Token removed.");
};

// store the auth token information (token & apiKey) to the session, leavung all other data untouched
export const PersistAuthTokenToCRSession = (apiKey, token) => {
  // for CR without an appliance, the CR token becomes the session identifier
  let session = GetCRSession();
  session = {
    ...session,
    auth: {
      ...session.auth,
      token,
      apiKey,
    },
  };
  const dat = JSON.stringify(session);
  sessionStorage.setItem("CRToken", dat);
};

//
// persist a specific (named) Auth0 attribute to the session. The attribute will be stored in a specifically
// 'auth0' area of the session that will be cleaned up in-tota when auth-related info is cleaned from the session
//
export const PersistAuth0CRSessionAttributes = (values) => {
  // set an auth0 attribute in the auth section of the session
  const session = GetCRSession();
  _merge(session, { auth: { auth0: { ...values } } });
  const dat = JSON.stringify(session);
  sessionStorage.setItem("CRToken", dat);
};

//
// retrieve a specific (named) Auth0 attribute from the session. The attribute will have been stored in a specifically
// 'auth0' area of the session by function PersistAuth0CRSessionAttributes()
//
export const GetAuth0CRSessionAttribute = (name) => {
  // set an auth0 attribute in the auth section of the session
  const session = GetCRSession();
  return _get(session, `auth.auth0.${name}`, null);
};

//
// remove a specific (named) Auth0 attribute from the session. The attribute will have been stored in a specifically
// 'auth0' area of the session by function PersistAuth0CRSessionAttributes()
//
export const RemoveAuth0CRSessionAttributes = (names) => {
  // set an auth0 attribute in the auth section of the session
  const session = GetCRSession();
  if (session.auth && session.auth.auth0) {
    names.map((name) => {
      delete session.auth.auth0[name];
      return null;
    });
  }
  const dat = JSON.stringify(session);
  sessionStorage.setItem("CRToken", dat);
};

//
// remove all auth-related information the session. This includes all attributes added with functions
// PersistAuthTokenToCRSession() and PersistAuth0CRSessionAttributes()
//
export const RemoveAllAuthFromCRSession = () => {
  const session = GetCRSession();
  delete session.auth;
  const dat = JSON.stringify(session);
  sessionStorage.setItem("CRToken", dat);
};

//
// set an arbitrary name/value pair on the session. This value will *not* be associated with the session auth
// data and as such will not be cleaned up by a call to RemoveAllAuthFromCRSession()
//
export const SetCRSessionAttribute = (name, value) => {
  const session = GetCRSession();
  session[name] = value;
  const dat = JSON.stringify(session);
  // logToConsole(`SetCRSessionAttribute() - stored session: ${dat}`);
  sessionStorage.setItem("CRToken", dat);
};

// retrieve an arbitrary name/value pair on the session, set using function SetCRSessionAttribute()
export const GetCRSessionAttribute = (name) => {
  const session = GetCRSession();
  const val = session[name];
  return val;
};

// remove an arbitrary name/value pair on the session, set using function SetCRSessionAttribute()
export const RemoveCRSessionAttribute = (name) => {
  const session = GetCRSession();
  delete session[name];
  const dat = JSON.stringify(session);
  sessionStorage.setItem("CRToken", dat);
};

//
// check whether a session exists, and whether that session contains auth information. pecifically we are
// checking here for the cr auth token. This function should be used to determine whether we are using the
// session to manage CR Tokens or whether we are an appliance-centric instance of the application.
//
export const CRSessionAuthed = () => {
  const session = GetCRSession();
  const token = _get(session, "auth.token", null);
  return token != null;
};
