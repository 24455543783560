import { Portfolio, PortfolioType } from "../../reducers/portfolios.actions";
import { PortfolioSpecificPermissions } from "../../types/roles";
import { FC, memo, useCallback, useMemo } from "react";

import {
  UserBreachsightWrite,
  UserVendorRiskWrite,
} from "../../../_common/permissions";
import { IPortfolioSelectionSelectedItem } from "./PortfolioSelection";
import { useModalV2 } from "../../../_common/components/ModalV2";
import DomainIPPortfolioSelectionModal, {
  DomainsOrIPs,
} from "./DomainIPPortfolioSelectionModal";
import VendorPortfolioSelectionModal from "./VendorPortfolioSelectionModal";
import "../../style/components/portfolios/EditablePortfolioList.scss";
import { appConnect } from "../../../_common/types/reduxHooks";

interface IEditablePortfolioListOwnProps {
  itemId: number | string;
  itemName: string;
  portfolios: Portfolio[];
  portfolioType: PortfolioType;
}

interface IEditablePortfolioListConnectedProps {
  userHasGlobalBreachSightWrite: boolean;
  userHasGlobalVendorRiskWrite: boolean;
  domainPortfolioSpecificPermissions: PortfolioSpecificPermissions;
  vendorPortfolioSpecificPermissions: PortfolioSpecificPermissions;
}

type IEditablePortfolioListProps = IEditablePortfolioListOwnProps &
  IEditablePortfolioListConnectedProps;

const EditablePortfolioList: FC<IEditablePortfolioListProps> = ({
  itemId,
  itemName,
  portfolios,
  portfolioType,
  userHasGlobalBreachSightWrite,
  userHasGlobalVendorRiskWrite,
  domainPortfolioSpecificPermissions,
  vendorPortfolioSpecificPermissions,
}) => {
  const [openDomainPortfolioSelectionModal, domainPortfolioSelectionModal] =
    useModalV2(DomainIPPortfolioSelectionModal);
  const [openVendorPortfolioSelectionModal, vendorPortfolioSelectionModal] =
    useModalV2(VendorPortfolioSelectionModal);

  const canEditPortfolioList = useMemo(() => {
    // User can edit the portfolio list if they have global write perm, or write perm in any of the provided portfolios
    if (portfolioType === PortfolioType.Vendor) {
      const editablePortfolios = Object.entries(
        vendorPortfolioSpecificPermissions
      )
        .filter(([, perms]) => perms?.includes(UserVendorRiskWrite))
        .map(([portfolioId]) => parseInt(portfolioId));

      return (
        userHasGlobalVendorRiskWrite ||
        !!portfolios.find((p) => editablePortfolios.includes(p.id))
      );
    } else {
      const editablePortfolios = Object.entries(
        domainPortfolioSpecificPermissions
      )
        .filter(([, perms]) => perms?.includes(UserBreachsightWrite))
        .map(([portfolioId]) => parseInt(portfolioId));

      return (
        userHasGlobalBreachSightWrite ||
        !!portfolios.find((p) => editablePortfolios.includes(p.id))
      );
    }
  }, [
    portfolios,
    portfolioType,
    userHasGlobalBreachSightWrite,
    userHasGlobalVendorRiskWrite,
    vendorPortfolioSpecificPermissions,
    domainPortfolioSpecificPermissions,
  ]);

  const selectedItems = useMemo(() => {
    const items: IPortfolioSelectionSelectedItem[] = [
      {
        id: itemId,
        name: itemName,
        portfolios,
      },
    ];
    return items;
  }, [itemId, itemName, portfolios]);

  const onClickEdit = useCallback(() => {
    if (portfolioType === PortfolioType.Vendor) {
      openVendorPortfolioSelectionModal({
        selectedVendors: selectedItems,
      });
    } else {
      openDomainPortfolioSelectionModal({
        selectedItems,
        domainsOrIPs: DomainsOrIPs.Domains,
      });
    }
  }, [
    selectedItems,
    portfolioType,
    openVendorPortfolioSelectionModal,
    openDomainPortfolioSelectionModal,
  ]);

  return (
    <>
      <div
        className={`editable-portfolio-list ${
          canEditPortfolioList ? "clickable" : ""
        }`}
        onClick={canEditPortfolioList ? onClickEdit : undefined}
      >
        {portfolios.map((p) => p.name).join(", ")}
        {canEditPortfolioList && <div className="cr-icon-pencil" />}
      </div>
      {domainPortfolioSelectionModal}
      {vendorPortfolioSelectionModal}
    </>
  );
};

export default appConnect<
  IEditablePortfolioListConnectedProps,
  never,
  IEditablePortfolioListOwnProps
>((state) => {
  return {
    userHasGlobalBreachSightWrite:
      state.common.userData.userPermissions.includes(UserBreachsightWrite),
    userHasGlobalVendorRiskWrite:
      state.common.userData.userPermissions.includes(UserVendorRiskWrite),
    domainPortfolioSpecificPermissions:
      state.common.userData.domainPortfolioSpecificPermissions,
    vendorPortfolioSpecificPermissions:
      state.common.userData.vendorPortfolioSpecificPermissions,
  };
})(memo(EditablePortfolioList));
