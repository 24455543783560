import ReportCard from "../ReportCard";
import "../../style/components/SurveyDetailsScoreProjectionCard.scss";
import ScoreProjection from "../ScoreProjection";
import LoadingBanner from "../core/LoadingBanner";

export interface ISurveyDetailsScoreProjectionCardProps {
  isVendorPortal: boolean;
  vendorName: string;
  initialScore: number;
  currentScore: number;
  projectedScore: number;
  loading: boolean;
}

const SurveyDetailsScoreProjectionCard = (
  props: ISurveyDetailsScoreProjectionCardProps
) => {
  return (
    <ReportCard newStyles className="survey-details-projection-card">
      <div className="header">{`${props.vendorName}'s Security Posture`}</div>
      <div className={"details-container"}>
        {!props.loading && (
          <ScoreProjection
            stacked={false}
            isSelfRemediation={false}
            isQuestionnaire={true}
            isVendorPortal={props.isVendorPortal}
            vendorName={props.vendorName}
            initialScore={props.initialScore}
            currentScore={props.currentScore}
            projectedScore={props.projectedScore}
          />
        )}
        {props.loading && <LoadingBanner />}
      </div>
    </ReportCard>
  );
};

export default SurveyDetailsScoreProjectionCard;
