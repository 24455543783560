import { Component } from "react";
import PropTypes from "prop-types";

import Button from "../../../_common/components/core/Button";
import ColorCheckbox from "../ColorCheckbox";
import PillLabel from "../PillLabel";
import { closeModal } from "../../../_common/reducers/commonActions";
import "../../style/components/BreachSightCloseReasonModal.scss";
import { breachSightStatusMap } from "../../constants/breachsight";
import TextareaAutosize from "react-autosize-textarea";
import { addSimpleErrorAlert } from "../../../_common/reducers/messageAlerts.actions";

export const BreachSightCloseReasonModalName =
  "BreachSightCloseReasonModalName";

const labelClosedStatusMap = {
  ClosedRiskAccepted: "Accept risk",
  ClosedFixedUnverified: "Fixed",
  ClosedNoIssue: "Not an issue",
};

class BreachSightCloseReasonModal extends Component {
  static propTypes = {
    modalData: PropTypes.shape({
      reasonList: PropTypes.array.isRequired,
      onSubmitPromise: PropTypes.func.isRequired,
    }).isRequired,
    dispatch: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      selectedReason: "",
      reasonMessage: "",
    };
  }

  onSubmit = (evt) => {
    const reason = this.state.selectedReason;
    evt.preventDefault();
    this.setState({
      loading: true,
    });

    this.props.modalData
      .onSubmitPromise(reason, this.state.reasonMessage)
      .then(() => {
        this.setState({
          loading: false,
        });

        this.props.dispatch(closeModal());
      })
      .catch((e) => {
        this.props.dispatch(addSimpleErrorAlert(e.message));
      });
  };

  getReasonCheckboxes = (reasonList) => {
    return reasonList.map((reason) => (
      <ColorCheckbox
        key={reason.id}
        color="blue"
        radio
        checked={this.state.selectedReason == reason.id}
        onClick={() => this.setState({ selectedReason: reason.id })}
        label={
          <div className="pill-label-container">
            <PillLabel
              key={reason.id + "_pill"}
              color={breachSightStatusMap[reason.id].labelColor}
            >
              {labelClosedStatusMap[reason.id]}
            </PillLabel>
            {<div className="pill-desc">{reason.desc}</div>}
          </div>
        }
      />
    ));
  };

  render() {
    let allowedReasons = this.props.modalData.reasonList;

    let titleText = "Close Finding";

    let promptText = `Please select the reason for closing this finding:`;

    let messageInputText = `Optionally, add more information about why you are closing this finding:`;
    return (
      <div className="modal-content breachsight-close-reason-modal">
        <h3 className="center-text">{titleText}</h3>
        <p>{promptText}</p>
        <form onSubmit={this.onSubmit}>
          <div className="cols">
            <div className="update-radio">
              {this.getReasonCheckboxes(allowedReasons)}
              <p>{messageInputText}</p>
              <TextareaAutosize
                //value={value}
                onChange={(evt) => {
                  this.setState({ reasonMessage: evt.target.value });
                }}
                //placeholder={placeholder}
                //autoFocus={autofocus}
              />
              <Button
                className="apply-button"
                primary
                onClick={this.onSubmit}
                disabled={
                  this.state.loading ||
                  !allowedReasons.find(
                    (r) => r.id === this.state.selectedReason
                  )
                }
                loading={this.state.loading}
              >
                Apply
              </Button>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default BreachSightCloseReasonModal;
