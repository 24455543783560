import { ChangeEvent, FC, useState } from "react";
import { isNumber as _isNumber } from "lodash";
import classnames from "classnames";

export interface IRangeSliderValues {
  lowerVal?: number;
  upperVal?: number;
}

interface IRangeSliderProps extends IRangeSliderValues {
  onChange: (opts: IRangeSliderValues) => void;
  min: number;
  max: number;
}

const RangeSlider: FC<IRangeSliderProps> = ({
  lowerVal,
  upperVal,
  onChange,
  min,
  max,
}) => {
  const [minValueVisible, setMinValueVisible] = useState(false);
  const [maxValueVisible, setMaxValueVisible] = useState(false);

  const onChangeLower = (e: ChangeEvent<HTMLInputElement>) => {
    const newLowerVal = parseInt(e.target.value);
    const upper = _isNumber(upperVal) ? upperVal : max + 1;

    if (newLowerVal >= upper) {
      return;
    }

    onChange({
      lowerVal: newLowerVal !== min - 1 ? newLowerVal : undefined,
      upperVal,
    });
  };

  const onChangeUpper = (e: ChangeEvent<HTMLInputElement>) => {
    const newUpperVal = parseInt(e.target.value);
    const lower = _isNumber(lowerVal) ? lowerVal : min - 1;
    if (newUpperVal <= lower) {
      return;
    }

    onChange({
      lowerVal,
      upperVal: newUpperVal !== max + 1 ? newUpperVal : undefined,
    });
  };

  const reset = () =>
    onChange({
      lowerVal: undefined,
      upperVal: undefined,
    });

  let lowerValPct = 0;
  let upperValPct = 100;

  if (_isNumber(lowerVal)) {
    lowerValPct = ((lowerVal - min) / (max - min)) * 100;
  }

  if (_isNumber(upperVal)) {
    upperValPct = ((upperVal - min) / (max - min)) * 100;
  }

  const minValue = _isNumber(lowerVal) ? lowerVal : min - 1;

  const maxValue = _isNumber(upperVal) ? upperVal : max + 1;

  return (
    <div className="range-slider-container">
      <div className="range-slider">
        <div className="bg-line" />
        {(_isNumber(lowerVal) || _isNumber(upperVal)) && (
          <div
            className="bg-line highlight"
            style={{
              left: `${lowerValPct}%`,
              right: `${100 - upperValPct}%`,
            }}
          />
        )}
        <div className="hover-values-container">
          {minValue !== min - 1 && (
            <div
              className={classnames("hover-value", "left", {
                visible: minValueVisible,
              })}
              style={{ left: `${lowerValPct}%` }}
            >
              {minValue}
            </div>
          )}
          {maxValue !== max + 1 && (
            <div
              className={classnames("hover-value", "right", {
                visible: maxValueVisible,
              })}
              style={{ right: `${100 - upperValPct}%` }}
            >
              {maxValue}
            </div>
          )}
        </div>
        <input
          type="range"
          value={minValue}
          min={min - 1}
          max={max + 1}
          onInput={onChangeLower}
          onChange={onChangeLower}
          onMouseEnter={() => setMinValueVisible(true)}
          onMouseLeave={() => setMinValueVisible(false)}
        />
        <input
          type="range"
          value={maxValue}
          min={min - 1}
          max={max + 1}
          onInput={onChangeUpper}
          onChange={onChangeUpper}
          onMouseEnter={() => setMaxValueVisible(true)}
          onMouseLeave={() => setMaxValueVisible(false)}
        />
      </div>
      <div className="num-displays">
        <div
          className={classnames("num-display", {
            highlight: _isNumber(lowerVal),
          })}
        >
          Min: {_isNumber(lowerVal) ? lowerVal : "all"}
        </div>
        {(_isNumber(lowerVal) || _isNumber(upperVal)) && (
          <div className="reset-link" onClick={reset}>
            Reset
          </div>
        )}
        <div
          className={classnames("num-display", {
            highlight: _isNumber(upperVal),
          })}
        >
          Max: {_isNumber(upperVal) ? upperVal : "all"}
        </div>
      </div>
    </div>
  );
};

export default RangeSlider;
