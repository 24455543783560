import { Component } from "react";
import { DefaultThunkDispatch } from "../../../_common/types/redux";
import { History } from "history";
import Button from "../../../_common/components/core/Button";
import { BannerType } from "../InfoBanner";
import SelectableSidebarTriggersList from "./SelectableSidebarTriggersList";
import { INotificationConfigCategory } from "../../../_common/types/notifications";
import XTable, { XTableCell } from "../../../_common/components/core/XTable";
import ClipboardIcon from "../../images/clipboard-icon.svg";
import {
  OptionType,
  SelectV2,
  SelectV2Async,
  SelectV2Multi,
} from "../../../_common/components/SelectV2";
import {
  getProjectComponents,
  postIssueToOAuth2Jira,
  searchAccountIssues,
  searchAccountUserGroups,
  searchAccountUsers,
  searchAssignableUsers,
  searchProjectVersions,
} from "../../reducers/oauth.actions";
import {
  IJiraIssue,
  IJiraField,
  IJiraGroup,
  IJiraIssueType,
  IJiraUser,
  IJiraVersion,
  IJiraComponent,
  DeserialiseJiraFieldValue,
  DeserialiseJiraFieldToValueOnly,
  DeserialiseJiraFieldToMultiValue,
} from "../../../_common/types/jira";
import {
  addDefaultSuccessAlert,
  addDefaultUnknownErrorAlert,
  addMessageAlert,
} from "../../../_common/reducers/messageAlerts.actions";
import { liquidSyntaxGuideURL } from "../../views/CreateIntegration";
import {
  GetExampleMessageVariables,
  ToClipboard,
} from "./ConfigureSlackMessageFormatsStep";
import Icon from "../../../_common/components/core/Icon";
import ConfirmationModalV2 from "../../../_common/components/modals/ConfirmationModalV2";
import { IOrgAlertDefinition } from "../../reducers/org.actions";

// use to store the issue type as a Jira field value
export const ISSUE_TYPE_FIELD = "IssueType";

// used to manipulate these special 'known' fields to insert default liquid templates
export const SUMMARY_FIELD = "summary";
export const DESCRIPTION_FIELD = "description";

interface IConfigureJiraIssueFieldsStepProps {
  history: History;
  dispatch: DefaultThunkDispatch;
  loading: boolean;
  selectedProjectId: string;
  selectedProjectName: string;
  issueFieldValues?: {
    [uuid: string]: {
      [key: string]: string;
    };
  };
  issueTypes: {
    issueTypes?: IJiraIssueType[] | null;
    loading: boolean;
    error?: string | null;
  };
  notifications: INotificationConfigCategory<IOrgAlertDefinition>[];
  notificationsByUUID: Record<string, IOrgAlertDefinition | undefined>;
  selectedUUIDs: {
    [uuid: string]: boolean;
  };
  selectedTestUUID: string;
  selectedTestJSON: any;
  setSelectedTestNotification: (notification: IOrgAlertDefinition) => void;
  validateJiraTarget: () => {
    valid: boolean;
    msg: string;
  };
  setSelectedFieldValue: (
    uuid: string,
    type: string,
    key: string,
    value: any
  ) => void;
}

interface IConfigureJiraIssueFieldsStepState {
  testJiraLoading: boolean;
  accountUsers: {
    prefix: string;
    loading: boolean;
    users?: IJiraUser[] | null;
    error?: string;
  };
  assignableUsers: {
    prefix: string;
    projectID: string;
    loading: boolean;
    users?: IJiraUser[] | null;
    error?: string;
  };
  accordianOpen: boolean;
  sendTestIssueModalOpen: boolean;
  components: {
    loading: boolean;
    components?: IJiraComponent[] | null;
    error?: string;
  };
}

export const PreprocessIssueFields = (fields: IJiraField[]): IJiraField[] => {
  const mandatoryFields = [] as IJiraField[];
  const otherFields = [] as IJiraField[];
  const summaryDescriptionFields = [] as IJiraField[];

  fields.map((field) => {
    if (field.details.key == "summary") {
      summaryDescriptionFields.unshift(field);
    } else if (field.details.key == "description") {
      summaryDescriptionFields.push(field);
    } else if (field.details.required) {
      mandatoryFields.push(field);
    } else {
      otherFields.push(field);
    }
  });

  const sortFields = (a: IJiraField, b: IJiraField) => {
    const nameA = a.details.name.toUpperCase(); // ignore upper and lowercase
    const nameB = b.details.name.toUpperCase(); // ignore upper and lowercase
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }

    // names must be equal
    return 0;
  };

  mandatoryFields.sort(sortFields);
  otherFields.sort(sortFields);

  return [...summaryDescriptionFields, ...mandatoryFields, ...otherFields];
};

//
// ConfigureJiraIssueFieldsStep
// Represents Step 3 in the Jira integration workflow. Assuming that a Jira project instance has been selected in step 2, it allows
// the user to configure the type of Jira issue to be created in this project when a trigger fires. Once an issue type has been selected,
// the set of fields that are supported by the so-called "Creation Screen" for that issue type can be configured by the user. Note that this
// set of fields may be completely different for each issue type, and can be customized by the admin of the project.
//
// Despite a plethora of field types being available to creation screens for Jira issues, cyber-risk only supports a limited set of
// field types at this stage. Basically we support, strings, numbers and anything that can be entered using a single selection control.
// Only (free-text) string and number fields can be conditionally configured using liquid templates at the moment.
//
class ConfigureJiraIssueFieldsStep extends Component<
  IConfigureJiraIssueFieldsStepProps,
  IConfigureJiraIssueFieldsStepState
> {
  static defaultProps = {
    issueFieldValues: {},
    webhookTestResponse: null,
  };

  constructor(props: IConfigureJiraIssueFieldsStepProps) {
    super(props);
    this.state = {
      testJiraLoading: false,
      accordianOpen: true,
      sendTestIssueModalOpen: false,
      components: { loading: true },
    } as IConfigureJiraIssueFieldsStepState;
  }

  componentDidMount() {
    this.setState({ components: { loading: true } });
    this.props
      .dispatch(getProjectComponents(this.props.selectedProjectId, true))
      .then((components) =>
        this.setState({ components: { components, loading: false } })
      )
      .catch((e) => {
        this.setState({
          components: { components: [], error: e, loading: false },
        });
        this.props.dispatch(
          addDefaultUnknownErrorAlert("Error fetching components")
        );
      });
  }

  //
  // missingMandatoryFields
  // Determines whether there are any mandatory issue fields that have not yet been assigned a value by the user.
  // This is used before we allow the user to send a test to Jira,
  // @see testJiraIssue(), testMandatoryFields()
  //
  missingMandatoryFields = (
    fields: IJiraField[],
    values: { [key: string]: string }
  ): string[] => {
    const missingFields = [] as string[];
    fields.map((fld) => {
      if (fld.details.required) {
        if (!values[fld.details.key]) {
          missingFields.push(fld.details.name);
        }
      }
    });
    return missingFields;
  };

  //
  // testMandatoryFields
  // Uses missingMandatoryFields()  to determine whether the Jira test button can be enabled for the user.
  //
  testMandatoryFields = (fields: IJiraField[]) => {
    if (!!!this.props.selectedTestUUID) {
      return true;
    }
    const fieldValues =
      this.props.issueFieldValues &&
      this.props.issueFieldValues[this.props.selectedTestUUID]
        ? this.props.issueFieldValues[this.props.selectedTestUUID]
        : {};
    const deserialisedFieldValues = {} as { [key: string]: string };
    Object.keys(fieldValues).map((key) => {
      deserialisedFieldValues[key] = DeserialiseJiraFieldToValueOnly(
        fieldValues[key]
      );
    });
    return (
      this.missingMandatoryFields(fields, deserialisedFieldValues).length > 0
    );
  };

  //
  // testJiraIssue
  // Calls the back-end to post a new Jira Issue request using the fields selected/entered by the user. Mandatory fields are
  // checked for completeness before the back end request is made. Liquidization of the free-text fields is performed on
  // the back-end just before the Jira API is called.
  //
  testJiraIssue = async (fields: IJiraField[]) => {
    const testUUID = this.props.selectedTestUUID;
    const fieldValues = this.props.issueFieldValues
      ? this.props.issueFieldValues[testUUID]
      : {};
    const deserialisedFieldValues = {} as { [key: string]: string };
    if (Object.keys(fieldValues).length == 0) {
      this.props.dispatch(
        addMessageAlert({
          message: `No issue attributes have been set`,
          type: BannerType.WARNING,
        })
      );
      return;
    }
    Object.keys(fieldValues).map((key) => {
      deserialisedFieldValues[key] = DeserialiseJiraFieldToValueOnly(
        fieldValues[key]
      );
    });
    const missingMandatoryFields = this.missingMandatoryFields(
      fields,
      deserialisedFieldValues
    );
    if (missingMandatoryFields.length > 0) {
      this.props.dispatch(
        addMessageAlert({
          message: `Mandatory fields are not set: ${JSON.stringify(
            missingMandatoryFields
          )}`,
          type: BannerType.WARNING,
        })
      );
      return;
    }

    this.setState({ testJiraLoading: true });

    try {
      await this.props.dispatch(
        postIssueToOAuth2Jira(
          testUUID,
          this.props.selectedProjectId,
          deserialisedFieldValues
        )
      );
      this.setState({ testJiraLoading: false });
      this.props.dispatch(
        addDefaultSuccessAlert("Successfully created a new issue in Jira")
      );
    } catch (e) {
      this.setState({ testJiraLoading: false });
      this.props.dispatch(
        addMessageAlert({
          message: `Test Jira issue creation failed: "${e}"`,
          type: BannerType.ERROR,
        })
      );
    }
  };

  //
  // getSelectedIssueType
  // For the currently selected notification trigger (by uuid), determine the currently selected issue type
  // and extract the value from props
  //
  getSelectedIssueType = () => {
    return this.props.issueFieldValues &&
      this.props.issueFieldValues[this.props.selectedTestUUID]
      ? DeserialiseJiraFieldValue(
          this.props.issueFieldValues[this.props.selectedTestUUID][
            ISSUE_TYPE_FIELD
          ]
        )
      : "";
  };

  //
  // buildSelectField
  // Given the definition for a Jira field whose suggested edit method is a selection list, and whose type is just a
  // generic type (usually 'option') use the list of AllowedValues defined by the field to render an appropriate input control.
  //
  buildSelectField = (field: IJiraField): any => {
    const options = [] as OptionType[];
    const selectedValue =
      this.props.issueFieldValues &&
      this.props.issueFieldValues[this.props.selectedTestUUID]
        ? DeserialiseJiraFieldValue(
            this.props.issueFieldValues[this.props.selectedTestUUID][
              field.details.key
            ]
          )
        : undefined;

    let selectedOption = undefined;
    if (field.details.allowedValues) {
      field.details.allowedValues.map((opt) => {
        const option = {
          value: opt.id,
          label: opt.name ? opt.name : opt.value ? opt.value : opt.id,
        } as OptionType;
        options.push(option);
        if (selectedValue == opt.id) {
          selectedOption = option;
        }
      });
    }

    return (
      <SelectV2
        id={field.details.key}
        key={field.details.key}
        isClearable
        placeholder={"Select value"}
        options={options}
        value={selectedOption}
        onChange={(selected) => {
          this.props.setSelectedFieldValue(
            this.props.selectedTestUUID,
            field.atomicType,
            field.details.key,
            selected ? selected.value : null
          );
        }}
        className={"channel-select"}
        isDisabled={false}
      />
    );
  };

  //
  // buildEpicSelectField
  // Given the definition for a Jira field whose suggested edit method is a selection list, and whose type is an epic-link,
  // render an appropriate input control for epic selection that utilises a back-end query to the Jira API, and which uses
  // search text entered by the user to limit the set of selectable epics.
  //
  buildEpicSelectField = (field: IJiraField): any => {
    return (
      <SelectV2Async<IJiraIssue, false>
        id={field.details.key}
        key={field.details.key}
        isClearable
        placeholder={"Search for epic"}
        getItems={(val) =>
          this.props.dispatch(
            searchAccountIssues(this.props.selectedProjectId, true, val)
          )
        }
        minChar={1}
        getOptionFromItem={this.getEpicOption}
        onChange={(selectedOption) => {
          this.props.setSelectedFieldValue(
            this.props.selectedTestUUID,
            field.atomicType,
            field.details.key,
            selectedOption
          );
        }}
        // @ts-ignore
        selectedOptions={
          this.props.issueFieldValues &&
          this.props.issueFieldValues[this.props.selectedTestUUID] &&
          this.props.issueFieldValues?.[this.props.selectedTestUUID]?.[
            field.details.key
          ]
            ? [
                DeserialiseJiraFieldValue(
                  this.props.issueFieldValues[this.props.selectedTestUUID][
                    field.details.key
                  ]
                ),
              ]
            : []
        }
      />
    );
  };

  //
  // buildIssueSelectField
  // Given the definition for a Jira field whose suggested edit method is a selection list, and whose type is an issue-link,
  // render an appropriate input control for issue selection that utilises a back-end query to the Jira API, and which uses
  // search text entered by the user to limit the set of selectable issues..
  //
  buildIssueSelectField = (field: IJiraField): any => {
    return (
      <SelectV2Async<IJiraIssue, false>
        id={field.details.key}
        key={field.details.key}
        isClearable
        placeholder={"Search for issue"}
        getItems={(val) =>
          this.props.dispatch(
            searchAccountIssues(this.props.selectedProjectId, false, val)
          )
        }
        minChar={1}
        getOptionFromItem={this.getIssueOption}
        onChange={(selectedOption) => {
          this.props.setSelectedFieldValue(
            this.props.selectedTestUUID,
            field.atomicType,
            field.details.key,
            selectedOption
          );
        }}
        // @ts-ignore
        selectedOptions={
          this.props.issueFieldValues &&
          this.props.issueFieldValues[this.props.selectedTestUUID] &&
          this.props.issueFieldValues[this.props.selectedTestUUID][
            field.details.key
          ]
            ? [
                DeserialiseJiraFieldValue(
                  this.props.issueFieldValues[this.props.selectedTestUUID][
                    field.details.key
                  ]
                ),
              ]
            : []
        }
      />
    );
  };

  //
  // buildUserSelectField
  // Given the definition for a Jira field whose suggested edit method is a selection list, and whose type is a user,
  // render an appropriate input control for user selection that utilises a back-end query to the Jira API, and which uses
  // search text entered to limit the set of selectable users.
  //
  buildUserSelectField = (field: IJiraField): any => {
    return (
      <SelectV2Async<IJiraUser, false>
        id={field.details.key}
        key={field.details.key}
        isClearable
        placeholder={"Search for user"}
        getItems={(val) => this.props.dispatch(searchAccountUsers(val))}
        minChar={2}
        getOptionFromItem={this.getUserOption}
        onChange={(selectedOption) => {
          this.props.setSelectedFieldValue(
            this.props.selectedTestUUID,
            field.atomicType,
            field.details.key,
            selectedOption
          );
        }}
        // @ts-ignore
        selectedOptions={
          this.props.issueFieldValues &&
          this.props.issueFieldValues[this.props.selectedTestUUID] &&
          this.props.issueFieldValues[this.props.selectedTestUUID][
            field.details.key
          ]
            ? [
                DeserialiseJiraFieldValue(
                  this.props.issueFieldValues[this.props.selectedTestUUID][
                    field.details.key
                  ]
                ),
              ]
            : []
        }
      />
    );
  };

  //
  // buildAssignableUserSelectField
  // Given the definition for a Jira field whose suggested edit method is a selection list, and whose type is an assignable-user,
  // render an appropriate input control for user selection that utilises a back-end query to the Jira API, and which uses
  // search text entered to limit the set of selectable users.
  //
  buildAssignableUserSelectField = (field: IJiraField): any => {
    return (
      <SelectV2Async<IJiraUser, false>
        id={field.details.key}
        key={field.details.key}
        isClearable
        placeholder={"Search for user"}
        getItems={(val) =>
          this.props.dispatch(
            searchAssignableUsers(this.props.selectedProjectId, val)
          )
        }
        minChar={2}
        getOptionFromItem={this.getUserOption}
        onChange={(selectedOption) => {
          this.props.setSelectedFieldValue(
            this.props.selectedTestUUID,
            field.atomicType,
            field.details.key,
            selectedOption
          );
        }}
        // @ts-ignore
        selectedOptions={
          this.props.issueFieldValues &&
          this.props.issueFieldValues[this.props.selectedTestUUID] &&
          this.props.issueFieldValues[this.props.selectedTestUUID][
            field.details.key
          ]
            ? [
                DeserialiseJiraFieldValue(
                  this.props.issueFieldValues[this.props.selectedTestUUID][
                    field.details.key
                  ]
                ),
              ]
            : []
        }
      />
    );
  };

  //
  // buildAssignableUserSelectField
  // Given the definition for a Jira field whose suggested edit method is a selection list, and whose type is a user group
  // render an appropriate input control for group selection that utilises a back-end query to the Jira API, and which uses
  // search text entered to limit the set of selectable groups.
  //
  buildUserGroupSelectField = (field: IJiraField): any => {
    return (
      <SelectV2Async<IJiraGroup, false>
        id={field.details.key}
        key={field.details.key}
        isClearable
        placeholder={"Search for user group"}
        getItems={(val) => this.props.dispatch(searchAccountUserGroups(val))}
        minChar={2}
        getOptionFromItem={this.getUserGroupOption}
        onChange={(selectedOption) => {
          this.props.setSelectedFieldValue(
            this.props.selectedTestUUID,
            field.atomicType,
            field.details.key,
            selectedOption
          );
        }}
        // @ts-ignore
        selectedOptions={
          this.props.issueFieldValues &&
          this.props.issueFieldValues[this.props.selectedTestUUID] &&
          this.props.issueFieldValues[this.props.selectedTestUUID][
            field.details.key
          ]
            ? [
                DeserialiseJiraFieldValue(
                  this.props.issueFieldValues[this.props.selectedTestUUID][
                    field.details.key
                  ]
                ),
              ]
            : []
        }
      />
    );
  };

  //
  // buildVersionSelectField
  // Given the definition for a Jira field whose suggested edit method is a selection list, and whose type is a project version
  // render an appropriate input control for version selection that utilises a back-end query to the Jira API, and which uses
  // search text entered to limit the set of selectable versions.
  //
  buildVersionSelectField = (field: IJiraField): any => {
    return (
      <SelectV2Async<IJiraVersion, false>
        id={field.details.key}
        key={field.details.key}
        isClearable
        placeholder={"Search for project version"}
        getItems={(val) =>
          this.props.dispatch(
            searchProjectVersions(this.props.selectedProjectId, val)
          )
        }
        minChar={1}
        getOptionFromItem={this.getVersionOption}
        onChange={(selectedOption) => {
          this.props.setSelectedFieldValue(
            this.props.selectedTestUUID,
            field.atomicType,
            field.details.key,
            selectedOption
          );
        }}
        // @ts-ignore
        selectedOptions={
          this.props.issueFieldValues &&
          this.props.issueFieldValues[this.props.selectedTestUUID] &&
          this.props.issueFieldValues[this.props.selectedTestUUID][
            field.details.key
          ]
            ? [
                DeserialiseJiraFieldValue(
                  this.props.issueFieldValues[this.props.selectedTestUUID][
                    field.details.key
                  ]
                ),
              ]
            : []
        }
      />
    );
  };

  //
  // buildComponentsSelectField
  // Given the definition for a Jira field whose suggested edit method is a multi select, and whose type is components
  // render an appropriate input control for components selection.
  //
  buildComponentsSelectField = (field: IJiraField): any => {
    return (
      <SelectV2Multi
        id={field.details.key}
        key={field.details.key}
        isClearable
        isLoading={this.state.components.loading}
        isDisabled={!!this.state.components.error}
        placeholder={"Search for components"}
        options={this.state.components?.components?.map((c) => ({
          label: c.name,
          value: c.id,
        }))}
        onChange={(selectedOption) => {
          this.props.setSelectedFieldValue(
            this.props.selectedTestUUID,
            field.atomicType,
            field.details.key,
            selectedOption
          );
        }}
        value={
          this.props.issueFieldValues &&
          this.props.issueFieldValues[this.props.selectedTestUUID] &&
          this.props.issueFieldValues[this.props.selectedTestUUID][
            field.details.key
          ]
            ? DeserialiseJiraFieldToMultiValue(
                this.props.issueFieldValues[this.props.selectedTestUUID][
                  field.details.key
                ]
              )
            : []
        }
      />
    );
  };

  //
  // getUserOption
  // Used to munge a user search result from the Jira API into something that can be used by a selection list
  //
  getUserOption = (item: IJiraUser): OptionType => {
    let label = `${item.displayName}`;
    if (item.emailAddress) {
      label += ` (${item.emailAddress})`;
    }
    return {
      label: label,
      value: item.accountId,
    };
  };

  //
  // getIssueOption
  // Used to munge an issue search result from the Jira API into something that can be used by a selection list
  //
  getIssueOption = (item: IJiraIssue): OptionType => {
    return {
      label: `${item.summary} (${item.key})`,
      value: item.id,
    };
  };

  //
  // getEpicOption
  // Used to munge an issue search result from the Jira API into something that can be used by a selection list
  //
  getEpicOption = (item: IJiraIssue): OptionType => {
    return {
      label: `${item.summary} (${item.key})`,
      value: item.key,
    };
  };

  //
  // getUserGroupOption
  // Used to munge a user group search result from the Jira API into something that can be used by a selection list
  //
  getUserGroupOption = (item: IJiraGroup): OptionType => {
    return {
      label: item.name,
      value: item.name,
    };
  };

  //
  // getUserGroupOption
  // Used to munge a product version search result from the Jira API into something that can be used by a selection list
  //
  getVersionOption = (item: IJiraVersion): OptionType => {
    return {
      label: item.name,
      value: item.id,
    };
  };

  //
  // renderFieldInputControls
  // Given the set of valid fields for the create screen of the selected issue type, render a list of input
  // controls to allow the user to enter valuers for each. All fieds except the selection of the issue type itself
  // are handled by this function.
  //
  renderFieldInputControls = (fields: IJiraField[]): any[] => {
    const controls = [] as any[];

    fields.map((field) => {
      const value =
        this.props.issueFieldValues &&
        this.props.issueFieldValues[this.props.selectedTestUUID]
          ? DeserialiseJiraFieldValue(
              this.props.issueFieldValues[this.props.selectedTestUUID][
                field.details.key
              ]
            )
          : "";

      const supportedField =
        field.editingSuggestion == "textfield" ||
        field.editingSuggestion == "url" ||
        field.editingSuggestion == "textarea" ||
        field.editingSuggestion == "float" ||
        field.editingSuggestion == "select" ||
        field.editingSuggestion == "userpicker" ||
        field.editingSuggestion == "radiobuttons" ||
        field.editingSuggestion == "grouppicker" ||
        field.editingSuggestion == "version" ||
        (field.editingSuggestion == "multiselect" &&
          field.atomicType == "component");

      controls.push(
        <>
          <div className={"section-title noline noline-top"}>
            <div className="title  left-padding">
              <span>{field.details.name}</span>
              {field.details.required && <span>*</span>}
            </div>
          </div>
          <div className={"payload-line noline noline-top"}>
            {field.editingSuggestion == "textarea" && (
              <div className={"jirafieldentry"}>
                <textarea
                  key={field.details.key}
                  id={field.details.key}
                  onChange={(e) => {
                    this.props.setSelectedFieldValue(
                      this.props.selectedTestUUID,
                      field.atomicType,
                      field.details.key,
                      e.target.value
                    );
                  }}
                  value={value ? (value as string) : ""}
                />
              </div>
            )}
            {(field.editingSuggestion == "textfield" ||
              field.editingSuggestion == "url" ||
              field.editingSuggestion == "float") && (
              <div className={"jirafieldentry"}>
                <input
                  type="text"
                  id={field.details.key}
                  key={field.details.key}
                  value={value ? (value as string) : ""}
                  onChange={(e) => {
                    this.props.setSelectedFieldValue(
                      this.props.selectedTestUUID,
                      field.atomicType,
                      field.details.key,
                      e.target.value
                    );
                  }}
                />
              </div>
            )}
            {field.editingSuggestion == "userpicker" && (
              <div className={"jirafieldentry"}>
                {this.buildUserSelectField(field)}
              </div>
            )}
            {field.editingSuggestion == "grouppicker" && (
              <div className={"jirafieldentry"}>
                {this.buildUserGroupSelectField(field)}
              </div>
            )}
            {field.editingSuggestion == "version" && (
              <div className={"jirafieldentry"}>
                {this.buildVersionSelectField(field)}
              </div>
            )}
            {field.editingSuggestion == "radiobuttons" && (
              <div className={"jirafieldentry"}>
                {this.buildSelectField(field)}
              </div>
            )}
            {field.editingSuggestion == "select" &&
              field.atomicType != "user" &&
              field.atomicType != "user-assignee" &&
              field.atomicType != "epiclink" &&
              field.atomicType != "issuelink" && (
                <div className={"jirafieldentry"}>
                  {this.buildSelectField(field)}
                </div>
              )}
            {field.editingSuggestion == "select" &&
              field.atomicType == "user" && (
                <div className={"jirafieldentry"}>
                  {this.buildUserSelectField(field)}
                </div>
              )}
            {field.editingSuggestion == "select" &&
              field.atomicType == "user-assignee" && (
                <div className={"jirafieldentry"}>
                  {this.buildAssignableUserSelectField(field)}
                </div>
              )}
            {field.editingSuggestion == "select" &&
              field.atomicType == "epiclink" && (
                <div className={"jirafieldentry"}>
                  {this.buildEpicSelectField(field)}
                </div>
              )}
            {field.editingSuggestion == "select" &&
              field.atomicType == "issuelink" && (
                <div className={"jirafieldentry"}>
                  {this.buildIssueSelectField(field)}
                </div>
              )}
            {field.editingSuggestion == "multiselect" &&
              field.atomicType == "component" && (
                <div className={"jirafieldentry"}>
                  {this.buildComponentsSelectField(field)}
                </div>
              )}
            {!supportedField && (
              <div className={"jirafieldentry"}>
                {`Suggested editing type '${field.editingSuggestion}' not supported`}
              </div>
            )}
          </div>
        </>
      );
    });
    return controls;
  };

  render() {
    {
      const { valid } = this.props.validateJiraTarget();

      // get the example variable values that are displayed in the 'how to liquid' table
      const messageVariables = GetExampleMessageVariables(
        this.props.notificationsByUUID,
        this.props.selectedTestUUID
      );

      // determine what the fields are for the currently selected issue type (for the current trigger)
      const selectedIssueTypeID = this.getSelectedIssueType();
      const issueTypeOptions = [] as OptionType[];
      let selectedIssueType = undefined;
      if (selectedIssueTypeID == "") {
        issueTypeOptions.push({
          value: "",
          label: "Select type (required)",
        } as OptionType);
        selectedIssueType = issueTypeOptions[0];
      }
      let selectedIssueFields = [] as IJiraField[];
      if (
        this.props.issueTypes &&
        !this.props.issueTypes.loading &&
        !this.props.issueTypes.error &&
        this.props.issueTypes.issueTypes
      ) {
        this.props.issueTypes.issueTypes.map((issueType) => {
          const option = {
            value: issueType.id,
            label: issueType.name,
          } as OptionType;
          issueTypeOptions.push(option);
          if (issueType.id == selectedIssueTypeID) {
            selectedIssueType = option;
            selectedIssueFields = issueType.fields;
          }
        });
      }
      const fields = selectedIssueFields
        ? PreprocessIssueFields(selectedIssueFields)
        : [];

      return (
        <>
          <div className="section-step">
            <div className="section-step nobackground">
              <div id={"payload"}>
                <div className={"title-bar"}>
                  <div className="header">Review content</div>
                </div>
                <div className={"body"}>
                  {(!this.props.selectedUUIDs ||
                    Object.keys(this.props.selectedUUIDs).length == 0) && (
                    <div className={"no-triggers"}>
                      <span>
                        {
                          "No triggers have been selected. Please return to the 'Triggers' section and select at least one notification trigger for this integration."
                        }
                      </span>
                    </div>
                  )}
                  {this.props.selectedUUIDs &&
                    Object.keys(this.props.selectedUUIDs).length > 0 && (
                      <>
                        <SelectableSidebarTriggersList
                          notifications={this.props.notifications}
                          selectedUUIDs={this.props.selectedUUIDs}
                          selectedTestUUID={this.props.selectedTestUUID}
                          setSelectedTestNotification={
                            this.props.setSelectedTestNotification
                          }
                        />
                        <div className={"payload-content no-left-padding"}>
                          <div className={"section-title section-left-padding"}>
                            <div className="main-title">Content</div>
                          </div>
                          <div className={"jira-fields-pane"}>
                            <div
                              className={
                                "section-title noline noline-top top-padding"
                              }
                            >
                              <div className="title left-padding">
                                Jira Project
                              </div>
                            </div>
                            <div className={"payload-line noline noline-top"}>
                              <div className={"message-channel"}>
                                {this.props.selectedProjectName}
                              </div>
                            </div>
                            <div className={"section-title noline noline-top"}>
                              <div className="title  left-padding">
                                <span>Issue type</span>
                                <span>*</span>
                              </div>
                            </div>
                            <div className={"payload-line noline noline-top"}>
                              <div>
                                <SelectV2
                                  key={ISSUE_TYPE_FIELD}
                                  placeholder="Select target issue type"
                                  options={issueTypeOptions}
                                  value={selectedIssueType}
                                  onChange={(selected) => {
                                    if (selected) {
                                      this.props.setSelectedFieldValue(
                                        this.props.selectedTestUUID,
                                        "issue-type",
                                        ISSUE_TYPE_FIELD,
                                        selected.value
                                      );
                                    }
                                  }}
                                  className={"channel-select"}
                                  isDisabled={
                                    !!!this.props.issueTypes ||
                                    this.props.issueTypes.loading ||
                                    !!this.props.issueTypes.error
                                  }
                                />
                              </div>
                            </div>
                            {selectedIssueType && selectedIssueType.value && (
                              <>
                                {this.renderFieldInputControls(fields)}
                                <div className={"payload-line"}>
                                  <div className={"button-row"}>
                                    <div />
                                    <Button
                                      className={"send-button"}
                                      onClick={() => {
                                        this.setState({
                                          sendTestIssueModalOpen: true,
                                        });
                                      }}
                                      loading={this.state.testJiraLoading}
                                      disabled={
                                        this.state.testJiraLoading ||
                                        !valid ||
                                        !this.props.selectedTestUUID ||
                                        this.testMandatoryFields(fields)
                                      }
                                    >
                                      <div className="cr-icon-message" />{" "}
                                      {"Test create issue"}
                                    </Button>
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </>
                    )}
                </div>
              </div>
            </div>
          </div>
          {selectedIssueType && selectedIssueType.value && (
            <div className="section-step">
              <div className="section-step nobackground">
                <div id={"payload_help"}>
                  <div
                    className={"title-bar"}
                    onClick={() => {
                      this.setState((state) => {
                        return { accordianOpen: !state.accordianOpen };
                      });
                    }}
                  >
                    <div className="header">
                      <div className={"text"}>
                        How do I customize these notifications?
                      </div>
                      <div className={"subtext"}>
                        A quick guide on our templating language and variables
                        you can use to customize your message content.
                      </div>
                    </div>
                    <div className={"accordion"}>
                      <Icon
                        name="chevron"
                        direction={this.state.accordianOpen ? 0 : 180}
                      />
                    </div>
                  </div>
                  {this.state.accordianOpen && (
                    <div className={"body"}>
                      <div className={"field"}>
                        <div className="field-desc">
                          Variables
                          <p>
                            Your message can use dynamic content by using the
                            variables listed here. Simply copy and paste
                            variables into your message to use them.
                          </p>
                          <p>
                            These messages have full support for the open-source
                            Liquid template language.
                          </p>
                          <p>
                            Consult the documentation for details on using more
                            advanced types such as arrays.
                          </p>
                          <p>
                            <a
                              href={`${liquidSyntaxGuideURL}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Learn more{" "}
                              <i className="btn-arrow icon-arrow rotate-90" />
                            </a>
                          </p>
                        </div>
                        <div className="field-input">
                          <XTable
                            className={"var-table"}
                            columnHeaders={[
                              { id: "name", text: "Name" },
                              {
                                id: "example",
                                text: "Sample value",
                              },
                              { id: "liquid", text: "Variable" },
                              { id: "icon", text: "" },
                            ]}
                            rows={messageVariables.map((v) => {
                              return {
                                id: v.name,
                                cells: [
                                  <XTableCell
                                    key="name"
                                    className={"var-name-cell"}
                                  >
                                    {v.name}
                                  </XTableCell>,
                                  <XTableCell
                                    key="sample"
                                    className="var-sample-cell"
                                  >
                                    {v.example != "array" ? (
                                      v.example
                                    ) : (
                                      <i>{"Array"}</i>
                                    )}
                                  </XTableCell>,
                                  <XTableCell
                                    key="var"
                                    className={"var-path-cell"}
                                  >
                                    {v.example != "array" && (
                                      <div className={"liquid-example"}>
                                        {v.path}
                                      </div>
                                    )}
                                    {v.example == "array" && (
                                      <div className={"array-example"}>
                                        {
                                          "View example payload data to see structure"
                                        }
                                      </div>
                                    )}
                                  </XTableCell>,
                                  <XTableCell
                                    key="icon"
                                    className={"var-copy-cell"}
                                  >
                                    <div className={"icon"}>
                                      <img
                                        src={ClipboardIcon}
                                        onClick={() => {
                                          ToClipboard(v.path);
                                          this.props.dispatch(
                                            addMessageAlert({
                                              message: `Value copied to clipboard`,
                                              type: BannerType.INFO,
                                              hideDelay: 3000,
                                            })
                                          );
                                        }}
                                      />
                                    </div>
                                  </XTableCell>,
                                ],
                              };
                            })}
                          />
                        </div>
                      </div>
                      <div className={"field"}>
                        <div className="field-desc">
                          Example payload data
                          <p>
                            Use this example to see the available fields and
                            their formats.
                          </p>
                        </div>
                        <div className="field-input">
                          <div className={"sample-data"}>
                            {this.props.selectedTestUUID &&
                              this.props.selectedTestJSON && (
                                <>
                                  {JSON.stringify(
                                    this.props.selectedTestJSON,
                                    null,
                                    "  "
                                  )}
                                </>
                              )}
                            {(!this.props.selectedTestUUID ||
                              !this.props.selectedTestJSON) && (
                              <>{"no trigger selected"}</>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
          <ConfirmationModalV2
            active={this.state.sendTestIssueModalOpen}
            verticalCenter
            descriptionClassName={"jira-test-confirmation-modal"}
            title={<h2>Send Test Issue to Jira</h2>}
            description={`This will create a new issue in the Jira project "${this.props.selectedProjectName}".`}
            buttonText={"Proceed"}
            cancelText={"Cancel"}
            buttonAction={async () => {
              this.testJiraIssue(fields);
            }}
            onClose={() => {
              this.setState({ sendTestIssueModalOpen: false });
            }}
          />
        </>
      );
    }
  }
}

export default ConfigureJiraIssueFieldsStep;
