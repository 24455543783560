import { Portfolio, PortfolioType } from "../../reducers/portfolios.actions";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import LoadingBanner from "../../../_common/components/core/LoadingBanner";

import XTable, {
  IXTableColumnHeader,
  IXTableRow,
  XTableCell,
} from "../../../_common/components/core/XTable";
import PillLabel from "../PillLabel";
import { LabelColor } from "../../../_common/types/label";
import { AssuranceType } from "../../../_common/types/organisations";
import { getVendorWords } from "../../../_common/constants";
import { appConnect } from "../../../_common/types/reduxHooks";

interface ISelectPortfoliosStepOwnProps {
  portfolioType: PortfolioType;
  initialSelectedPortfolioIDs?: number[];
  onSelectionChange: (portfolioIDs: number[]) => void;
}

interface ISelectPortfoliosStepConnectedProps {
  portfolios?: Portfolio[];
  assuranceType: AssuranceType;
}

type ISelectPortfoliosStepProps = ISelectPortfoliosStepOwnProps &
  ISelectPortfoliosStepConnectedProps;

const SelectPortfoliosStep: FC<ISelectPortfoliosStepProps> = ({
  portfolioType,
  portfolios,
  onSelectionChange,
  assuranceType,
  initialSelectedPortfolioIDs = [],
}) => {
  const [selectedPortfolios, setSelectedPortfolios] = useState(
    initialSelectedPortfolioIDs
  );

  useEffect(() => {
    if (portfolios && initialSelectedPortfolioIDs.length === 0) {
      // Select all portfolios by default
      setSelectedPortfolios((portfolios ?? []).map((p) => p.id));
    }
  }, [
    portfolios,
    // Purposely omitting initialSelectedPortfolioIDs as we only want to use once
  ]);

  useEffect(() => {
    // Notify parent whenever portfolio selection changes
    onSelectionChange(selectedPortfolios);
  }, [
    selectedPortfolios,
    // Purposely omitting onSelectionChange as its signature will probably change
  ]);

  const selectAll = useCallback(
    () => setSelectedPortfolios((portfolios ?? []).map((p) => p.id)),
    [portfolios]
  );

  const selectNone = useCallback(() => setSelectedPortfolios([]), []);

  const selectToggle = useCallback(
    (all: boolean) => (all ? selectAll() : selectNone()),
    [selectAll, selectNone]
  );

  const togglePortfolio = useCallback(
    (portfolioID: number) =>
      setSelectedPortfolios((selectedPortfolios) => {
        let newPortfolios = [...selectedPortfolios];
        if (newPortfolios.includes(portfolioID)) {
          newPortfolios = newPortfolios.filter((p) => p !== portfolioID);
        } else {
          newPortfolios.push(portfolioID);
        }
        return newPortfolios;
      }),
    []
  );

  const onSelectClick = useCallback(
    (rowId: number | string) => togglePortfolio(rowId as number),
    [togglePortfolio]
  );

  const columnHeaders = useMemo<IXTableColumnHeader[]>(
    () => [
      {
        id: "name",
        text: "Portfolio",
      },
      {
        id: "vendors",
        text:
          portfolioType === PortfolioType.Domain
            ? "Assets"
            : getVendorWords(assuranceType).pluralTitleCase,
      },
    ],
    [portfolioType, assuranceType]
  );

  const portfolioRows = useMemo<IXTableRow[]>(
    () =>
      (portfolios ?? []).map((p) => ({
        id: p.id,
        selected: selectedPortfolios.includes(p.id),
        onClick: () => togglePortfolio(p.id),
        cells: [
          <XTableCell key="name" className={"portfolio-cell"}>
            {p.name}
          </XTableCell>,
          <XTableCell key="vendors" className={"vendors-cell"}>
            <PillLabel
              color={
                selectedPortfolios.includes(p.id)
                  ? LabelColor.Blue
                  : LabelColor.Grey
              }
            >
              {p.numItems || 0}
            </PillLabel>
          </XTableCell>,
        ],
      })),
    [portfolios, selectedPortfolios, togglePortfolio]
  );

  if (!portfolios) {
    return <LoadingBanner />;
  }

  return (
    <XTable
      className="portfolios-list"
      expandableRows={false}
      loading={portfolios.length == 0}
      selectable={true}
      onSelectNoneClick={selectNone}
      onSelectAllClick={selectAll}
      onSelectToggle={selectToggle}
      onSelectClick={onSelectClick}
      columnHeaders={columnHeaders}
      rows={portfolioRows}
    />
  );
};

export default appConnect<
  ISelectPortfoliosStepConnectedProps,
  never,
  ISelectPortfoliosStepOwnProps
>((state, props) => {
  return {
    assuranceType: state.common.userData.assuranceType,
    portfolios:
      props.portfolioType === PortfolioType.Vendor
        ? state.cyberRisk.vendorPortfolios?.portfolios
        : state.cyberRisk.domainPortfolios?.portfolios,
  };
})(SelectPortfoliosStep);
