import React, { FC, useEffect, useMemo, useState } from "react";
import LoadingBanner from "../../../_common/components/core/LoadingBanner";
import {
  IAutomationOutputMapping,
  IAutomationQuestion,
  IAutomationRangeRule,
  IAutomationRecipe,
  IAutomationWeights,
} from "../../types/automation";
import ReportCard from "../../../_common/components/ReportCard";
import {
  DataEntryErrorAttribute,
  EditQuestionnairePages,
  QuestionTypes,
} from "../../views/EditQuestionnaireAutomation";
import "../../style/components/automation/DefineAdvancedRulesStep.scss";
import ColorCheckbox from "../ColorCheckbox";
import classnames from "classnames";
import XTable, {
  IXTableRow,
  XTableCell,
} from "../../../_common/components/core/XTable";

import {
  DefaultThunkDispatch,
  DefaultThunkDispatchProp,
  ITestResults,
  ITestSubmissionDetails,
} from "../../../_common/types/redux";
import { IVendorTier } from "../../../_common/types/vendorTier";
import {
  fetchVendorTiers,
  VendorTier,
} from "../../reducers/vendorTiers.actions";
import VendorTierBadge from "../vendor_tiers/VendorTierBadge";
import { checkAutomationSyntax } from "../../reducers/questionnaireAutomation.actions";
import { fetchSubmittedSurveysForSurveyType } from "../../reducers/questionnaireAutomationTest.actions";
import { SelectV2 } from "../../../_common/components/SelectV2";
import { appConnect } from "../../../_common/types/reduxHooks";
import { SidePopupV2 } from "../../../_common/components/DismissablePopup";
import TextField from "../../../_common/components/TextField";

export const DEFAULT_WEIGHTED_CALCULATION = "SUMALL()";
export const EXAMPLE_WEIGHTED_CALCULATION = "SUM([q1], [q2], ..., [qn])";

interface IDefineAdvancedRulesStepOwnProps {
  dispatch: DefaultThunkDispatch;
  loading: boolean;
  isEditing: boolean;
  surveyTypeId: number;
  recipeUUID: string;
  automation?: IAutomationRecipe;
  weights?: IAutomationWeights;
  questions?: IAutomationQuestion[];
  usingCustomFormula: boolean;
  customFormula: string;
  mapping: IAutomationOutputMapping;
  setMappingFunc: (mapping: IAutomationOutputMapping) => void;
  setCustomFormulaFunc: (f: string) => void;
  setUsingCustomFormulaFunc: (using: boolean) => void;
  setDataEntryErrorFunc: (
    page: number,
    id: string,
    attribute: DataEntryErrorAttribute,
    msg: string
  ) => void;
  deleteDataEntryErrorFunc: (
    page: number,
    id: string,
    attribute: DataEntryErrorAttribute
  ) => void;
  retrieveDataEntryErrorFunc: (
    page: number,
    id: string,
    attribute: DataEntryErrorAttribute
  ) => string | undefined;
  userHasWriteAutomationEnabled: boolean;
  inputText: {
    [key: string]: string;
  };
  setInputTextFunc: (page: number, id: string, value: string) => void;
}

interface IDefineAdvancedRulesStepConnectedProps {
  loading: boolean;
  error?: IError | null;
  tiers?: VendorTier[];
  syntaxError?: string;
  testSurveys?: ITestSubmissionDetails[];
  executionResults?: ITestResults;
}

type IDefineAdvancedRulesStepProps = DefaultThunkDispatchProp &
  IDefineAdvancedRulesStepOwnProps &
  IDefineAdvancedRulesStepConnectedProps;

const DefineAdvancedRulesStep: FC<IDefineAdvancedRulesStepProps> = ({
  dispatch,
  loading,
  isEditing,
  recipeUUID,
  surveyTypeId,
  weights,
  questions,
  tiers,
  usingCustomFormula,
  customFormula,
  mapping,
  setMappingFunc,
  setCustomFormulaFunc,
  setUsingCustomFormulaFunc,
  setDataEntryErrorFunc,
  retrieveDataEntryErrorFunc,
  deleteDataEntryErrorFunc,
  userHasWriteAutomationEnabled,
  syntaxError,
  inputText,
  setInputTextFunc,
}) => {
  const [selectedQuestionId, setSelectedQuestionId] = useState("");
  const [selectedQuestionAlias, setSelectedQuestionAlias] = useState("");
  const questionCache = useMemo(() => {
    const cache = {} as {
      [qId: string]: IAutomationQuestion;
    };
    questions?.map((q) => {
      cache[q.id] = q;
    });
    return cache;
  }, [questions]);
  useEffect(() => {
    dispatch(fetchVendorTiers());
  }, []);

  useEffect(() => {
    dispatch(fetchSubmittedSurveysForSurveyType(surveyTypeId, false));
  }, [surveyTypeId]);

  const setMappingMaxMinForTier = (
    tier: number,
    val: string,
    maxmin: string
  ) => {
    setInputTextFunc(
      EditQuestionnairePages.EditAutomationAdvancedRules,
      "tier__" + tier + `_${maxmin}`,
      val
    );
    const strTierVal = tier.toString(10);
    if (mapping) {
      const regexp = /^-?\d+(\.\d{1,2})?$/;
      const v = regexp.test(val) ? parseFloat(val) : NaN;
      const set = (attr: string, val: string, range: IAutomationRangeRule) => {
        if (attr == "max") {
          range.max = val.trim();
        } else {
          range.min = val.trim();
        }
      };
      if (val.trim() != "" && isNaN(v)) {
        // not a number error
        setDataEntryErrorFunc(
          EditQuestionnairePages.EditAutomationAdvancedRules,
          `${tier}`,
          maxmin == "max"
            ? DataEntryErrorAttribute.Max
            : DataEntryErrorAttribute.Min,
          "Range values must be a number"
        );
        return;
      } else {
        // remove existing error
        deleteDataEntryErrorFunc(
          EditQuestionnairePages.EditAutomationAdvancedRules,
          `${tier}`,
          maxmin == "max"
            ? DataEntryErrorAttribute.Max
            : DataEntryErrorAttribute.Min
        );
        // create our new mapping data, setting 0 as the default as we know this is a set of TIER ranges and untiered should be
        // the default case
        const newMapping: IAutomationOutputMapping = {
          ...mapping,
          default: "0",
        };
        // set the value in the mapping!
        newMapping.rangeRules = newMapping.rangeRules
          ? newMapping.rangeRules
          : ([] as IAutomationRangeRule[]);
        let found = false;
        for (let idx = 0; idx < newMapping.rangeRules.length; idx++) {
          if (strTierVal == newMapping.rangeRules[idx].output) {
            found = true;
            set(maxmin, val.trim(), newMapping.rangeRules[idx]);
            break;
          }
        }
        if (!found) {
          const range = {
            output: strTierVal,
          } as IAutomationRangeRule;
          set(maxmin, val.trim(), range);
          newMapping.rangeRules.push(range);
        }
        setMappingFunc(newMapping);
      }
    }
  };

  // index the range rules so that we know which one is for which tier
  const tierRanges = useMemo<{
    [tier: string]: IAutomationRangeRule;
  }>(() => {
    const ranges: {
      [tier: string]: IAutomationRangeRule;
    } = {};
    if (mapping && mapping.rangeRules) {
      mapping.rangeRules.map((rr) => {
        ranges[rr.output] = rr;
      });
    }
    // check that they are all there in case we missed some
    if (tiers) {
      tiers.map((t) => {
        if (t.isActive && !t.isArchived) {
          const tierStr = t.tier.toString(10);
          if (!ranges[tierStr]) {
            ranges[tierStr] = {
              output: tierStr,
              min: "",
              max: "",
            } as IAutomationRangeRule;
          }
        }
      });
    }
    return ranges;
  }, [mapping, tiers]);

  const getRangeRows = () => {
    const rows = [] as IXTableRow[];
    tiers
      ?.filter((t) => t.tier != 0 && t.isActive && !t.isArchived)
      .map((t) => {
        const errorMin =
          retrieveDataEntryErrorFunc(
            EditQuestionnairePages.EditAutomationAdvancedRules,
            `${t.tier}`,
            DataEntryErrorAttribute.Min
          ) ?? "";
        const errorMax =
          retrieveDataEntryErrorFunc(
            EditQuestionnairePages.EditAutomationAdvancedRules,
            `${t.tier}`,
            DataEntryErrorAttribute.Max
          ) ?? "";

        const minVal =
          inputText["tier__" + t.tier + "_min"] != undefined
            ? inputText["tier__" + t.tier + "_min"]
            : tierRanges?.[t.tier.toString(10)]?.min || "";
        const maxVal =
          inputText["tier__" + t.tier + "_max"] != undefined
            ? inputText["tier__" + t.tier + "_max"]
            : tierRanges?.[t.tier.toString(10)]?.max || "";

        rows.push({
          id: t.id,
          cells: [
            <XTableCell key={"tier"} className={"tier-cell"}>
              <div className={"tier-name"}>
                <VendorTierBadge tier={t.tier} />
                <div className={"name"}>{t.name}</div>
              </div>
            </XTableCell>,
            <XTableCell key={"ident"} className={"range-call"}>
              <div className={"range-bounds"}>
                <div className={"minmax"}>{"min (≥)"}</div>
                <SidePopupV2 text={errorMin}>
                  <TextField
                    id={"min_" + t.id}
                    name={"min_" + t.id}
                    type={"number"}
                    className={classnames("mapping-range-val", {
                      error: errorMin,
                    })}
                    value={minVal || ""}
                    disabled={!isEditing || !userHasWriteAutomationEnabled}
                    onChanged={(val) => {
                      setMappingMaxMinForTier(t.tier, val, "min");
                    }}
                  />
                </SidePopupV2>
                <div className={"minmax"}>{"max (<)"}</div>
                <SidePopupV2 text={errorMax}>
                  <TextField
                    id={"max_" + t.id}
                    name={"max_" + t.id}
                    type={"number"}
                    className={classnames("mapping-range-val", {
                      error: errorMax,
                    })}
                    value={maxVal || ""}
                    disabled={!isEditing || !userHasWriteAutomationEnabled}
                    onChanged={(val) => {
                      setMappingMaxMinForTier(t.tier, val, "max");
                    }}
                  />
                </SidePopupV2>
              </div>
            </XTableCell>,
          ] as React.ReactNode[],
        });
      });
    return rows;
  };

  const convertHtml = (str: string): string => {
    const divContainer = document.createElement("div");
    divContainer.innerHTML = str;
    return divContainer.textContent || divContainer.innerText || "";
  };

  const getQnRows = () => {
    const iconOptions = [];
    if (selectedQuestionAlias) {
      iconOptions.push({
        id: "copy",
        hoverText: userHasWriteAutomationEnabled
          ? "Copy to clipboard"
          : undefined,
        icon: <div className="cr-icon-copy" />,
        disabled: !isEditing || !userHasWriteAutomationEnabled,
        onClick: () => {
          if (isEditing && userHasWriteAutomationEnabled) {
            navigator.clipboard.writeText("[" + selectedQuestionAlias + "]");
          }
        },
      });
    }
    return [
      {
        id: "qn",
        iconOptions: iconOptions,
        cells: [
          <XTableCell key={"qn"} className={"qn-cell"}>
            <SelectV2
              placeholder={"Select a question"}
              className={"question-select"}
              stopPropagation
              isClearable={false}
              isDisabled={!isEditing || !userHasWriteAutomationEnabled}
              options={[
                ...(questions
                  ?.filter((q) => q.questionType != QuestionTypes.Section)
                  .map((q) => ({
                    value: q.id,
                    label: convertHtml(q.questionText),
                  })) ?? []),
              ]}
              value={
                questionCache?.[selectedQuestionId]
                  ? {
                      value: questionCache?.[selectedQuestionId].id,
                      label: convertHtml(
                        questionCache?.[selectedQuestionId].questionText
                      ),
                    }
                  : null
              }
              onChange={(selected) => {
                if (selected) {
                  setSelectedQuestionAlias(
                    weights
                      ? weights[selected.value].questionIDAlias
                      : selected.value
                  );
                  setSelectedQuestionId(selected.value);
                }
              }}
            />
          </XTableCell>,
          <XTableCell key={"ident"} className={"ident-cell"}>
            {selectedQuestionAlias
              ? "[" + selectedQuestionAlias + "]"
              : "(select)"}
          </XTableCell>,
        ] as React.ReactNode[],
      },
    ] as IXTableRow[];
  };

  if (loading) {
    return <LoadingBanner />;
  }

  return (
    <div className="define-advanced-rules-step">
      <ReportCard newStyles className="report-card">
        <div className={"header"}>
          <div className="header-inner">
            <div className={"header-left"}>
              <div className={"header-title"}>Configure definitions</div>
            </div>
          </div>
          <div className={"info-section"}>
            The advanced method of tiering recipe works by assigning weights to
            each answer in a questionnaire. The weights for each answer are
            combined together, and the attribute (tier) will be assigned a value
            based on how the final result compares to the set of ranges defined
            by you. To learn more about configuring automations read our{" "}
            <a
              onClick={() =>
                window.open(
                  "https://help.upguard.com/en/articles/4995218-how-to-use-the-questionnaire-builder"
                )
              }
            >
              support article
            </a>
            .
          </div>
        </div>
        <div className={"bottom-section"}>
          <div className={"pane"}>
            <div className={"column left-column"}>
              <h2>{"Combining all answer weights"}</h2>
              <div className={"desc"}>
                Specify how each of the weights defined in the previous step
                will be combined to calculate a final weight of a questionnaire
                instance. You can select our default or specify your own.
              </div>
            </div>
            <div className={"column right-column"}>
              <div className={"field"}>
                <div
                  className={classnames("field-input bordered", {
                    selected: !usingCustomFormula,
                    clickable: isEditing && userHasWriteAutomationEnabled,
                  })}
                  onClick={
                    isEditing && userHasWriteAutomationEnabled
                      ? () => {
                          setUsingCustomFormulaFunc(false);
                        }
                      : undefined
                  }
                >
                  <div className={"left"}>
                    <ColorCheckbox
                      label={"Sum the weights of all questions"}
                      radio
                      disabled={!isEditing || !userHasWriteAutomationEnabled}
                      checked={!usingCustomFormula}
                      onClick={() => {
                        if (isEditing && userHasWriteAutomationEnabled) {
                          setUsingCustomFormulaFunc(false);
                        }
                      }}
                    />
                    <div className={"formula"}>
                      <i>{EXAMPLE_WEIGHTED_CALCULATION}</i>
                    </div>
                  </div>
                </div>
              </div>
              <div className={"field"}>
                <div
                  className={classnames("field-input bordered", {
                    selected: usingCustomFormula,
                    clickable: isEditing && userHasWriteAutomationEnabled,
                  })}
                  onClick={
                    isEditing && userHasWriteAutomationEnabled
                      ? () => {
                          setUsingCustomFormulaFunc(true);
                        }
                      : undefined
                  }
                >
                  <div className={"left"}>
                    <ColorCheckbox
                      label={"Create custom definition"}
                      radio
                      disabled={!isEditing || !userHasWriteAutomationEnabled}
                      checked={usingCustomFormula}
                      onClick={() => {
                        if (isEditing && userHasWriteAutomationEnabled) {
                          setUsingCustomFormulaFunc(true);
                        }
                      }}
                    />
                    {usingCustomFormula && (
                      <div className={"left"}>
                        <p>
                          We support a basic macro format with limited
                          functions: MAX, SUM, MIN, AVG, MEDIAN. References to
                          questions or sections are done via the{" "}
                          <i>alias name</i> for that question, with syntax{" "}
                          <i>[alias]</i>. See our documentation for more detail.
                        </p>

                        <SidePopupV2 text={syntaxError}>
                          <textarea
                            className={syntaxError ? "error" : undefined}
                            id={"custom_calculation"}
                            onChange={(e) => {
                              setCustomFormulaFunc(e.target.value);
                              dispatch(
                                checkAutomationSyntax(
                                  recipeUUID,
                                  e.target.value,
                                  weights
                                )
                              );
                            }}
                            value={customFormula || ""}
                            disabled={
                              !isEditing || !userHasWriteAutomationEnabled
                            }
                            placeholder={EXAMPLE_WEIGHTED_CALCULATION}
                          />
                        </SidePopupV2>
                        {isEditing && userHasWriteAutomationEnabled && (
                          <>
                            <p>
                              Use the selector below to determine the reference
                              for a specific question to be used in your weight
                              function.
                            </p>
                            <div className={"questions-box"}>
                              <XTable
                                iconOptions
                                columnHeaders={[
                                  { id: "question", text: "Question" },
                                  {
                                    id: "alias",
                                    text: "Identifier",
                                  },
                                ]}
                                rows={getQnRows()}
                              />
                            </div>
                          </>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={"pane"}>
            <div className={"column left-column"}>
              <h2>{"Range definitions"}</h2>
              <div className={"desc"}>
                Use this area to specify the ranges that will determine the
                tier. Note that if no ranges match, the result will default to
                &quot;Untiered&quot;.
              </div>
              <div className={"example"}>
                <div className={"txt"}>
                  <b>Example</b>: Assign vendor the following tier when the sum
                  of all answers falls within the range:
                </div>
                <table>
                  <tbody>
                    <tr>
                      <th>Tier</th>
                      <th colSpan={4}>Condition</th>
                    </tr>
                    <tr>
                      <td>1</td>
                      <td>min:</td>
                      <td>70</td>
                      <td />
                      <td />
                    </tr>
                    <tr>
                      <td>2</td>
                      <td>min:</td>
                      <td>50,</td>
                      <td>max:</td>
                      <td>70</td>
                    </tr>
                    <tr>
                      <td>3</td>
                      <td>min:</td>
                      <td>30,</td>
                      <td>max:</td>
                      <td>50</td>
                    </tr>
                    <tr>
                      <td>4</td>
                      <td>max:</td>
                      <td>30</td>
                      <td />
                      <td />
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className={"column right-column"}>
              <h2> </h2>
              <div className={"ranges-table"}>
                <XTable
                  iconOptions
                  columnHeaders={[
                    { id: "tier", text: "Tier" },
                    {
                      id: "cond",
                      text: "Condition",
                    },
                  ]}
                  rows={getRangeRows()}
                ></XTable>
              </div>
            </div>
          </div>
        </div>
      </ReportCard>
    </div>
  );
};

export default appConnect<
  IDefineAdvancedRulesStepConnectedProps,
  never,
  IDefineAdvancedRulesStepOwnProps
>((state, props) => {
  const tiers = state.cyberRisk.vendorTiers
    ? state.cyberRisk.vendorTiers
    : null;

  const recipes = state.cyberRisk.automationRecipes;

  let syntaxError: string | undefined;
  let testSurveys: ITestSubmissionDetails[] | undefined;
  let executionResults: ITestResults | undefined;
  if (recipes) {
    const recipe = recipes[props.recipeUUID];
    if (recipe) {
      syntaxError =
        recipe && recipe.autosave ? recipe.autosave.syntax : undefined;

      testSurveys =
        state.cyberRisk.automationTestSubmissions?.[props.surveyTypeId]?.data;
      executionResults =
        state.cyberRisk.automatedTestResults?.[props.surveyTypeId];
    }
  }

  const connectedProps: IDefineAdvancedRulesStepConnectedProps = {
    loading: props.loading,
    error: null,
    tiers: tiers ? tiers : ([] as IVendorTier[]),
    syntaxError: syntaxError,
    testSurveys: testSurveys,
    executionResults: executionResults,
  };
  return connectedProps;
})(DefineAdvancedRulesStep);
