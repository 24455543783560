import memoize from "memoize-one";
import {
  UserBreachsightWrite,
  UserVendorRiskWrite,
} from "../../../_common/permissions";
import { isEqual } from "lodash";
import { Portfolio } from "../../reducers/portfolios.actions";

export const portfolioNameMaxLen = 50;

export const vendorPortfolioHelpURL =
  "https://help.upguard.com/en/articles/5998850-how-to-use-portfolios-to-segment-your-vendors";
export const domainPortfolioHelpURL =
  "https://help.upguard.com/en/articles/7054632-how-to-use-asset-portfolios-to-segment-your-domains";

// Retrieve the list of vendor portfolios that the user only has read access to.
export const getDisabledVendorPortfolioIds = memoize(
  (portfolioSpecificPermissions: Record<string, string[] | undefined>) => {
    const disabledPortfolioIds: number[] = [];

    if (portfolioSpecificPermissions) {
      Object.entries(portfolioSpecificPermissions).forEach(
        ([portfolioId, perms]) => {
          if (!perms?.includes(UserVendorRiskWrite)) {
            disabledPortfolioIds.push(parseInt(portfolioId));
          }
        }
      );
    }

    return disabledPortfolioIds;
  },
  (newArgs, lastArgs) => {
    return isEqual(newArgs[0], lastArgs[0]);
  }
);

// Retrieve the list of domain portfolios that the user only has read access to.
export const getDisabledDomainPortfolioIds = memoize(
  (portfolioSpecificPermissions: Record<string, string[] | undefined>) => {
    const disabledPortfolioIds: number[] = [];

    if (portfolioSpecificPermissions) {
      Object.entries(portfolioSpecificPermissions).forEach(
        ([portfolioId, perms]) => {
          if (!perms?.includes(UserBreachsightWrite)) {
            disabledPortfolioIds.push(parseInt(portfolioId));
          }
        }
      );
    }

    return disabledPortfolioIds;
  },
  (newArgs, lastArgs) => {
    return isEqual(newArgs[0], lastArgs[0]);
  }
);

export enum PortfolioValidationErrorType {
  NameBlank = "Cannot create a portfolio with no name.",
  NameTooLong = `Portfolio name cannot exceed ${portfolioNameMaxLen} characters.`,
  DuplicateName = "Cannot create a portfolio with a duplicate name.",
}

export const validatePortfolios = (
  ...portfolios: Portfolio[]
): PortfolioValidationErrorType[] => {
  const validationErrors = new Set<PortfolioValidationErrorType>();
  const validUniqueNames = new Set<string>();

  for (const p of portfolios) {
    // Deliberately written to match original backend query parameter
    // validation logic, which historically has not trimmed whitespace
    // for checking portfolio name max length.
    if (p.name.length > portfolioNameMaxLen) {
      validationErrors.add(PortfolioValidationErrorType.NameTooLong);
    }

    const name = p.name.toLowerCase().trim();
    if (name === "") {
      validationErrors.add(PortfolioValidationErrorType.NameBlank);
    }

    if (validUniqueNames.has(name)) {
      validationErrors.add(PortfolioValidationErrorType.DuplicateName);
    } else {
      validUniqueNames.add(name);
    }
  }

  return Array.from(validationErrors);
};
