import {
  CSSProperties,
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import classnames from "classnames";
import "../style/components/TextFieldPerformant.scss";
import { useDebounce } from "../hooks";
import classNames from "classnames";

export interface TextFieldPerformantProps {
  value: string;
  onChanged: (val: string) => void;

  // Height for the input field
  // Default to 150 for multiline, 32 for single line
  height?: number;

  className?: string;
  multiLine?: boolean;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  inputColor?: "orange";
}

// TextFieldPerformant is a more performant and stripped down version of <TextField>.
// The input field is absolute positioned.
const TextFieldPerformant: FC<TextFieldPerformantProps> = ({
  value,
  onChanged,
  height,
  className,
  multiLine,
  placeholder,
  required,
  disabled,
  inputColor,
}) => {
  const [internalVal, setInternalVal] = useState(value);

  // Keep a ref of whether the user is typing or not
  const isTypingRef = useRef(false);

  // Debounce the value change callback
  const debouncedChange = useDebounce(
    (v: string) => {
      onChanged(v);
      isTypingRef.current = false;
    },
    500,
    [onChanged]
  );

  // Respond to value changed by parent
  // Only apply if not actively typing
  useEffect(() => {
    if (!isTypingRef.current) {
      setInternalVal(value);
    }
  }, [value]);

  const style: CSSProperties = useMemo(() => {
    return {
      height: height ? height : multiLine ? 150 : 32,
    };
  }, [height, multiLine]);

  const commonProps = {
    value: internalVal,
    className: classnames(inputColor),
    placeholder,
    required,
    disabled,
    onChange: useCallback((ev: any) => {
      setInternalVal(ev.target.value);
      isTypingRef.current = true;
      debouncedChange(ev.target.value);
    }, []),
    style,
  };

  return (
    <div
      className={classNames("text-field-performant", className)}
      style={style}
    >
      {multiLine && <textarea {...commonProps} />}
      {!multiLine && <input type={"text"} {...commonProps} />}
    </div>
  );
};

export default TextFieldPerformant;
