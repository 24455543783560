import { ISurveyCheckToAdd } from "../../reducers/remediation.actions";
import { Reducer } from "react";

export type SurveyCheckAction =
  | { type: "init" }
  | { type: "removeCheckID"; riskId: string }
  | {
      type: "setCheckSurveys";
      riskId: string;
      surveys?: number[];
      publicSurveys?: number[];
    };

export type ISurveyCheckReducer = Reducer<
  Record<string, ISurveyCheckToAdd>,
  SurveyCheckAction
>;

export const SelectSurveyCheck = (
  store: Record<string, ISurveyCheckToAdd>,
  id: string
): ISurveyCheckToAdd => {
  return (
    store[id] ?? {
      riskID: "",
      surveys: [],
      publicSurveys: [],
    }
  );
};

export const SurveyCheckReducer: ISurveyCheckReducer = (
  state: Record<string, ISurveyCheckToAdd>,
  action: SurveyCheckAction
): Record<string, ISurveyCheckToAdd> => {
  const newState = { ...state };
  switch (action.type) {
    case "init":
      return {};
    case "setCheckSurveys":
      if (!newState[action.riskId]) {
        newState[action.riskId] = {
          riskID: action.riskId,
          surveys: action.surveys ?? [],
          publicSurveys: action.publicSurveys ?? [],
        };
      } else {
        newState[action.riskId].surveys =
          action.surveys ?? newState[action.riskId].surveys;
        newState[action.riskId].publicSurveys =
          action.publicSurveys ?? newState[action.riskId].publicSurveys;
      }

      // remove the check if no surveys are selected
      if (
        !newState[action.riskId].surveys?.length &&
        !newState[action.riskId].publicSurveys?.length
      ) {
        delete newState[action.riskId];
      }

      return newState;
    case "removeCheckID":
      delete newState[action.riskId];
      return newState;
    default:
      return newState;
  }
};
