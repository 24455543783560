import PropTypes from "prop-types";
import Icon from "./core/Icon";

import "../style/components/Select.scss";

const Select = ({ children, ...props }) => (
  <div className="styled-select">
    <select {...props}>{children}</select>
    <Icon name="chevron" />
  </div>
);

Select.propTypes = {
  children: PropTypes.any.isRequired,
};

export default Select;
