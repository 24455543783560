import { trackEventBackend } from "./reducers/analytics";
import { DefaultThunkDispatch } from "./types/redux";

// Keep a global reference to our redux store so we can access the
// dispatch function without having to pass it through everywhere.
let globalDispatch: DefaultThunkDispatch;

export const setGlobalDispatch = (dispatch: DefaultThunkDispatch) => {
  globalDispatch = dispatch;
};

export const trackEvent = (
  eventName: string,
  properties?: Record<string, any>
) => {
  if (!globalDispatch) {
    console.warn("trackEvent was called but no global dispatch is present", {
      eventName,
      properties,
    });
    return;
  }

  globalDispatch(trackEventBackend(eventName, properties));
};
