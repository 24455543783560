import classNames from "classnames";
import { FC } from "react";
import "../../style/components/securityprofile/StatusIcon.scss";
import {
  ControlState,
  ControlStateIcon,
  ControlStateText,
} from "../../types/securityProfile";

interface StatusIconProps {
  controlState: ControlState;
  statusCount?: number;
  onClick?: () => void;
}

const StatusIcon: FC<StatusIconProps> = ({
  controlState,
  statusCount,
  onClick,
}) => (
  <>
    <div
      onClick={onClick}
      className={classNames("status-icon", controlState, {
        clickable: !!onClick,
      })}
    >
      <div
        className={ControlStateIcon[controlState]}
        aria-label={ControlStateText[controlState]}
      />
    </div>
    {statusCount && <span className="status-count">{statusCount}</span>}
  </>
);

export default StatusIcon;
