import UserAvatar from "../../_common/components/UserAvatar";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import "./../style/components/CommentDisplay.scss";
import { formatDateAsLocal } from "../../_common/helpers";
import Button from "../../_common/components/core/Button";
import classNames from "classnames";

export enum CommentDisplayStyling {
  Default,
  Shaded,
}

interface ICommentDisplayProps {
  name?: string;
  avatar?: string;
  date?: string;
  comment?: string;
  isPlaceholderComment?: boolean;
  styling?: CommentDisplayStyling;
}

const CommentDisplay = (props: ICommentDisplayProps) => {
  const [showMore, setShowMore] = useState(false);
  const [retainShowMore, setRetainShowMore] = useState(false);
  const [isExpanded, setExpanded] = useState(false);
  const commentRef = useRef<HTMLDivElement>(null);

  const adjustCommentExpand = () => {
    if (commentRef.current) {
      setRetainShowMore(false);
      setShowMore(
        commentRef.current.scrollHeight > commentRef.current.clientHeight
      );
    }
  };

  // Add/remove window resize listener
  useEffect(() => {
    const handleResize = () => {
      adjustCommentExpand();
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Initial render
  useLayoutEffect(() => {
    adjustCommentExpand();
  }, []);

  // Subsequent renders
  useLayoutEffect(() => {
    setTimeout(() => {
      adjustCommentExpand();
    }, 251);
  });

  const commentDisplayClasses = classNames("comment-display", {
    shaded: props.styling === CommentDisplayStyling.Shaded,
  });

  const commentClasses = classNames("comment", {
    placeholder: props.isPlaceholderComment,
    expanded: isExpanded,
  });

  return (
    <div className={commentDisplayClasses}>
      <div className="avatar-container">
        <UserAvatar name={props.name} avatar={props.avatar} />
      </div>
      <div className="detail-container">
        <div className="comment-header">
          {props.name && <div className="name">{props.name}</div>}
          {props.date && (
            <div className="date">{formatDateAsLocal(props.date)}</div>
          )}
        </div>
        <div ref={commentRef} className={commentClasses}>
          {props.comment}
        </div>
        {(showMore || isExpanded || retainShowMore) && (
          <Button
            tertiary
            className={"more"}
            onClick={() => {
              setRetainShowMore(true);
              setExpanded(!isExpanded);
            }}
          >
            {isExpanded || retainShowMore ? "Show less..." : "Show more..."}
          </Button>
        )}
      </div>
    </div>
  );
};

CommentDisplay.defaultProps = {
  name: "User",
  avatar: "",
  comment: "",
  isPlaceholderComment: false,
  date: undefined,
  styling: CommentDisplayStyling.Default,
};

export default CommentDisplay;
