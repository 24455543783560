import PageHeader from "../../_common/components/PageHeader";
import Button from "../../_common/components/core/Button";
import {
  DefaultRouteProps,
  useDefaultHistory,
} from "../../_common/types/router";
import { getAuthFromLocalStorage } from "../../_common/reducers/commonActions";

import { FC, memo, useEffect, useState } from "react";
import {
  fetchExistingOAuthConnections,
  fetchNewOAuth2RequestURL,
  GOOGLE_SERVICE,
  MICROSOFT_CLIENT_CREDENTIALS_SERVICE,
  MICROSOFT_SERVICE,
} from "../../vendorrisk/reducers/oauth.actions";
import { popupCenter } from "../../_common/helpers";
import {
  appConnect,
  useAppDispatch,
  useAppSelector,
} from "../../_common/types/reduxHooks";
import { getOAuthConnectionData } from "../../vendorrisk/reducers/oauth.selectors";
import { OAuthState } from "../../_common/types/oauth";
import "./AdminView.scss";
import userbaseApi from "../api/userbase.api";
import {
  addDefaultSuccessAlert,
  addDefaultUnknownErrorAlert,
} from "../../_common/reducers/messageAlerts.actions";
import { canAccessSystemAdmin } from "../../_common/selectors/permissions";
import { isAdmin } from "../../vendorrisk/helpers/roles";
import { useModalV2 } from "../../_common/components/ModalV2";
import Microsoft365ClientCredentialsModal from "../components/Admin/Microsoft365ClientCredentialsModal";
import { ScanStatus } from "../api/types";
import { SidePopupV2 } from "../../_common/components/DismissablePopup";
import { usePollForScan } from "../helpers/scanPoller";

interface IAdminViewConnectedProps {
  googleConnections?: OAuthState;
  microsoftConnections?: OAuthState;
  microsoftClientCredentialsConnections?: OAuthState;
}

type IAdminViewOwnProps = DefaultRouteProps;

type IAdminViewProps = IAdminViewConnectedProps & IAdminViewOwnProps;

const AdminView: FC<IAdminViewProps> = ({
  googleConnections,
  microsoftConnections,
  microsoftClientCredentialsConnections,
}) => {
  const [loading, setLoading] = useState(false);
  const history = useDefaultHistory();
  const dispatch = useAppDispatch();

  const [openM365ClientCredentialsModal, m365ClientCredentialsModal] =
    useModalV2(Microsoft365ClientCredentialsModal);

  const userData = useAppSelector((state) => state.common.userData);
  const isUserAdmin = isAdmin(userData.currentOrgRoles);
  const isUserSystemAdmin = useAppSelector((state) =>
    canAccessSystemAdmin(state)
  );

  const { data: scanData } = userbaseApi.useGetUserRiskScanStatusV1Query();

  const [startScanMutation] = userbaseApi.useStartUserRiskScanV1Mutation();

  const pollForScan = usePollForScan();
  const silentPollForScan = usePollForScan(undefined, undefined, true);

  useEffect(() => {
    // force fetch the oauth connections including the inactive ones
    // we'll use the presence of inactive tokens as a signal that the
    // customer connected some oauth tokens in the past and as such
    // the scanning should not use the demo data
    // this is also safeguarded in the start scan endpoint in the backend
    dispatch(
      fetchExistingOAuthConnections(GOOGLE_SERVICE, "", true, true, true)
    );
    dispatch(
      fetchExistingOAuthConnections(MICROSOFT_SERVICE, "", true, true, true)
    );
    dispatch(
      fetchExistingOAuthConnections(
        MICROSOFT_CLIENT_CREDENTIALS_SERVICE,
        "",
        true,
        true,
        true
      )
    );
  }, [dispatch]);

  const oauthLoading =
    (googleConnections?.loading ||
      microsoftConnections?.loading ||
      microsoftClientCredentialsConnections?.loading) ??
    true;

  const hasValidGoogle = !!googleConnections?.connections?.filter(
    (c) => !c.invalid
  ).length;
  const hasInvalidGoogle = !!googleConnections?.connections?.filter(
    (c) => c.invalid
  ).length;
  const hasValidMicrosoft = !!microsoftConnections?.connections?.filter(
    (c) => !c.invalid
  ).length;
  const hasInvalidMicrosoft = !!microsoftConnections?.connections?.filter(
    (c) => c.invalid
  ).length;
  const hasValidMicrosoftClientCredentials =
    !!microsoftClientCredentialsConnections?.connections?.filter(
      (c) => !c.invalid
    ).length;
  const hasInvalidMicrosoftClientCredentials =
    !!microsoftClientCredentialsConnections?.connections?.filter(
      (c) => c.invalid
    ).length;

  const hasValidTokens =
    hasValidGoogle || hasValidMicrosoft || hasValidMicrosoftClientCredentials;
  const hasInvalidTokens =
    hasInvalidGoogle ||
    hasInvalidMicrosoft ||
    hasInvalidMicrosoftClientCredentials;
  const hasToken = hasValidTokens || hasInvalidTokens;

  const showSetupButton = isUserAdmin && !oauthLoading && !hasValidTokens;
  const showFetchButtons = isUserSystemAdmin && !oauthLoading && hasValidTokens;
  // only show the scan button if:
  // - user is admin
  // - we are not currently waiting for an oauth flow completion
  // - there are valid oauth tokens or there were never any
  //   tokens (in which case we simulate the scan using demo data)
  const showScanButton =
    isUserAdmin && !oauthLoading && (hasValidTokens || !hasToken);

  const fetchData = (service: string): void => {
    setLoading(true);
    const auth = getAuthFromLocalStorage();
    window.open(
      `/api/userbase/fetch_data/v1?&apikey=${encodeURIComponent(
        auth.apiKey
      )}&token=${encodeURIComponent(auth.token)}&service=${encodeURIComponent(
        service
      )}`
    );
    setLoading(false);
  };

  // This function triggers a scan similarly to the call above but
  // it is meant for SuperAdmins and provide a more detailed error message.
  // Later on once things stabilise we'll make a rescan button available
  // to customers.
  const startScan = (): void => {
    setLoading(true);
    startScanMutation()
      .unwrap()
      .then(() => {
        // if there are no valid tokens we don't need to show a message as either there is nothing
        // to scan with or we used the demo data to simulate an immediate scan
        if (hasValidTokens) {
          dispatch(
            addDefaultSuccessAlert(
              "Scan started. Depending on the size of the organization results will be available in a few minutes."
            )
          );
        }
      })
      .then(() => pollForScan())
      .catch((e) => {
        console.error(e);
        let errMsg = "Failed to start scan";
        if (e.status === 422 && e.message) {
          errMsg += ` - ${e.message}`;
        }
        // start polling for scan status silently because the failure
        // might be due to someone else kicking off a scan underneath us
        silentPollForScan();
        dispatch(addDefaultUnknownErrorAlert(errMsg));
      })
      .finally(() => setLoading(false));
  };

  const fetchDriveActivities = (): void => {
    setLoading(true);
    const auth = getAuthFromLocalStorage();
    window.open(
      `/api/userbase/fetch_activities/v1?&apikey=${encodeURIComponent(
        auth.apiKey
      )}&token=${encodeURIComponent(auth.token)}`
    );
    setLoading(false);
  };

  const fetchDriveFiles = (): void => {
    setLoading(true);
    const auth = getAuthFromLocalStorage();
    window.open(
      `/api/userbase/fetch_files/v1?&apikey=${encodeURIComponent(
        auth.apiKey
      )}&token=${encodeURIComponent(auth.token)}`
    );
    setLoading(false);
  };

  const startAuthFlow = async (service: string): Promise<void> => {
    setLoading(true);

    const { url }: { url: string } = await dispatch(
      fetchNewOAuth2RequestURL(service)
    );

    popupCenter(url, service, 800, 800);

    // While the user follows the prompts in the popup window, we'll sit here polling for OAuth2 data
    // until it's done.
    const timer = window.setInterval(async () => {
      try {
        const resp = await dispatch(
          fetchExistingOAuthConnections(service, "", true, true, true)
        );

        if (resp && resp.connections.length > 0) {
          window.clearInterval(timer);
          setLoading(false);
          history.push("/userbase/admin");
          pollForScan();
        }

        // TODO cap the time for how long we wait, in case there's any kind of problem with the oauth flow
      } catch (e) {
        console.log(`Caught an exception: ${JSON.stringify(e)}`);
      }
    }, 1000);
  };

  const scanLoading =
    loading ||
    oauthLoading ||
    (scanData &&
      (scanData.status === ScanStatus.Queued ||
        scanData.status === ScanStatus.Started));
  const canStartScan =
    !loading &&
    !oauthLoading &&
    scanData &&
    (scanData.status === ScanStatus.None ||
      scanData.status === ScanStatus.Completed ||
      scanData.status === ScanStatus.Errored);

  return (
    <div className="userbase-admin">
      <PageHeader
        history={history}
        title="Admin"
        infoSection={<>UserRisk Admin.</>}
      />

      {showSetupButton && (
        <div className={"buttons-row"}>
          <Button
            key="start-google-oauth"
            loading={loading}
            onClick={() => startAuthFlow("Google")}
          >
            Create Google OAuth Connection
          </Button>
          <Button
            key="start-microsoft-oauth"
            loading={loading}
            onClick={() => startAuthFlow("Microsoft365")}
          >
            Create Microsoft 365 OAuth Connection
          </Button>
          <Button
            key="start-microsoft-client-credentials-oauth"
            loading={loading}
            onClick={() => openM365ClientCredentialsModal({})}
          >
            Create Microsoft 365 OAuth Connection without user
          </Button>
        </div>
      )}
      <div className={"buttons-row"}>
        {showScanButton && (
          <SidePopupV2
            text={!canStartScan ? "A scan is already in progress" : null}
            micro
          >
            <Button
              key="start-scan"
              loading={scanLoading}
              disabled={!canStartScan}
              onClick={() => startScan()}
            >
              Start Scan
            </Button>
          </SidePopupV2>
        )}
        {showFetchButtons && (
          <Button
            key="start-fetch"
            loading={loading}
            onClick={() =>
              fetchData(
                hasValidGoogle
                  ? "Google"
                  : hasValidMicrosoft
                    ? "Microsoft"
                    : hasValidMicrosoftClientCredentials
                      ? "MicrosoftClientCredentials"
                      : ""
              )
            }
          >
            Fetch User and Token Data
          </Button>
        )}
        {showFetchButtons && hasValidGoogle && (
          <>
            <Button
              key="start-fetch-activities"
              loading={loading}
              onClick={fetchDriveActivities}
            >
              Fetch Google Drive Activities
            </Button>
            <Button
              key="start-fetch-files"
              loading={loading}
              onClick={fetchDriveFiles}
            >
              Fetch Google Drive Files
            </Button>
          </>
        )}
      </div>
      {m365ClientCredentialsModal}
    </div>
  );
};

export default appConnect<IAdminViewConnectedProps, never, IAdminViewOwnProps>(
  (state): IAdminViewConnectedProps => {
    return {
      googleConnections: getOAuthConnectionData(state, GOOGLE_SERVICE),
      microsoftConnections: getOAuthConnectionData(state, MICROSOFT_SERVICE),
      microsoftClientCredentialsConnections: getOAuthConnectionData(
        state,
        MICROSOFT_CLIENT_CREDENTIALS_SERVICE
      ),
    };
  }
)(memo(AdminView));
