import PropTypes from "prop-types";

import "../style/components/ExclaimLabel.scss";

const ExclaimLabel = ({ color, children, triangle, inverted }) => (
  <div className={`exclaim-label ${color} ${inverted ? "inverted" : ""}`}>
    {triangle ? (
      <svg className="exclaim-triangle" viewBox="0 0 48 48">
        <path d="M 24 4 L 43 37 L 5 37 z" />
        <text x="20" y="40">
          !
        </text>
      </svg>
    ) : (
      <span className="exclaim">!</span>
    )}
    {children}
  </div>
);

ExclaimLabel.propTypes = {
  color: PropTypes.string,
  children: PropTypes.any,
  triangle: PropTypes.bool,
  inverted: PropTypes.bool,
};

ExclaimLabel.defaultProps = {
  color: "orange",
  triangle: false,
  children: null,
  inverted: false,
};

export default ExclaimLabel;
