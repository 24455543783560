import React, { useEffect, useState } from "react";
import ModalV2, { BaseModalProps } from "../../../_common/components/ModalV2";
import Button from "../../../_common/components/core/Button";
import { Steps } from "../../../_common/components/StepsWithSections";
import {
  IVendorRiskWaiverCreateRequest,
  WaiverType,
} from "../../../_common/types/vendorRiskWaivers";
import {
  VendorSummaryRisk,
  VendorSummaryRiskType,
} from "../../../_common/types/vendorSummary";
import { getVendorWords } from "../../../_common/constants";
import { createVendorRiskWaiver } from "../../reducers/vendorRiskWaiver.actions";
import {
  fetchCustomerVendorsData,
  fetchVendorSummaryAndCloudscans,
} from "../../reducers/cyberRiskActions";
import {
  fetchVendorRiskBreakdownReport,
  fetchVendorRiskOverviewReport,
} from "../../reducers/reportsActions";
import { fetchVendorPortfolioRiskProfile } from "../../reducers/vendorRiskPortfolio.actions";
import { clearDomains } from "../../reducers/domains.actions";
import { getSurveyListV2 } from "../../reducers/survey.actions";
import { surveyListPageLimit } from "../../views/Questionnaires";
import {
  addDefaultSuccessAlert,
  addDefaultUnknownErrorAlert,
} from "../../../_common/reducers/messageAlerts.actions";
import { trackEvent } from "../../../_common/tracking";
import { TextFieldData } from "../../../_common/components/TextField";
import {
  AssetSelection,
  getRiskAssetText,
  isAssetSelectionValid,
  RiskAssetSelectionForWaiver,
} from "../risk_waivers/SelectRiskTable";
import WaiverDetailsStep, {
  isWaiverDetailsValid,
} from "../risk_waivers/WaiverDetailsStep";
import ConfirmStep from "../risk_waivers/ConfirmStep";
import {
  AssuranceType,
  OrgFlagType,
} from "../../../_common/types/organisations";
import { calculateWaivedAssetsForRisks } from "../../reducers/risks.actions";
import "../../style/components/risk_waivers/EditVendorRiskWaiverModal.scss";
import moment from "moment";
import { Severity, SeverityAsString } from "../../../_common/types/severity";
import RiskMini from "../RiskMini";
import { useAppDispatch } from "../../../_common/types/reduxHooks";
import { invalidateVendorAssessmentRisks } from "../../reducers/vendorAssessmentAPI";
import OrganisationFlagsAPI from "../../../_common/api/organisationFlagsAPI";
import LoadingBanner from "../../../_common/components/core/LoadingBanner";

interface CreateVendorRiskWaiverModalOwnProps extends BaseModalProps {
  vendorId: number;
  vendorName: string;
  risk: VendorSummaryRisk;
  website?: string;
  surveyId?: number;
  isPublicSurvey?: boolean;
  riskHostnames?: string[];
  vendorAssessmentId?: number;
  managedOrgId?: number;
  isManagementAnalystSession?: boolean;
  assuranceType: AssuranceType;
  waiverType: WaiverType;
}

type CreateVendorRiskWaiverModalProps = CreateVendorRiskWaiverModalOwnProps;

const CreateVendorRiskWaiverModal: React.VFC<
  CreateVendorRiskWaiverModalProps
> = ({
  active,
  risk,
  isPublicSurvey,
  onClose,
  surveyId,
  vendorId,
  website,
  riskHostnames,
  vendorAssessmentId,
  assuranceType,
  managedOrgId,
  isManagementAnalystSession,
  waiverType,
}) => {
  const dispatch = useAppDispatch();
  const [currentStep, setCurrentStep] = useState(0);
  const [isSaving, setIsSaving] = useState(false);
  const [selection, setSelection] = useState<AssetSelection>(() => {
    let surveyIds: number[] = [];
    let publicSurveyIds: number[] = [];
    if (risk.riskType === VendorSummaryRiskType.Survey) {
      if (!isPublicSurvey) {
        if (surveyId) {
          surveyIds = [surveyId];
        } else {
          surveyIds = risk.surveys
            ? risk.surveys.filter((s) => !s.publicSurvey).map((s) => s.surveyId)
            : [];
        }
      } else {
        if (surveyId) {
          publicSurveyIds = [surveyId];
        } else {
          publicSurveyIds = risk.surveys
            ? risk.surveys.filter((s) => s.publicSurvey).map((s) => s.surveyId)
            : [];
        }
      }
    }

    return {
      selectedQuestionnaires: surveyIds,
      selectedPublicQuestionnaires: publicSurveyIds,
      allQuestionnairesSelected: false, // Always false for new waivers
      hostnameSelection: {
        allSelected: !website,
        includeFuture: false,
        selected: website ? [website] : riskHostnames ?? [],
      },
    };
  });
  const [expiryDate, setExpiryDate] = useState<string | undefined>(undefined);
  const [justification, setJustification] = useState<TextFieldData>({
    value: "",
    isValid: false,
  });
  const [adjustedSeverity, setAdjustedSeverity] = useState<
    Severity | undefined
  >(undefined);

  const [approverRequired, setApproverRequired] = useState(false);
  const [approverEmail, setApproverEmail] = useState<TextFieldData>({
    value: "",
    isValid: false,
  });

  const { data: orgFlags } =
    OrganisationFlagsAPI.useGetOrganisationFlagsV1Query();

  useEffect(
    function setInitialStatesWhenDataLoaded() {
      if (!orgFlags) return;

      const requireApprovalsRiskWaivers =
        orgFlags[OrgFlagType.VendorRiskRequireApprovalsRiskWaivers];
      const requireApprovalsRiskAdjustments =
        orgFlags[OrgFlagType.VendorRiskRequireApprovalsRiskAdjustments];

      const isApproverRequired =
        waiverType === WaiverType.SeverityAdjustment
          ? requireApprovalsRiskAdjustments
          : requireApprovalsRiskWaivers;

      setApproverEmail({ value: "", isValid: !isApproverRequired });
      setApproverRequired(isApproverRequired);

      // Now we are in a safe, known state, we can show the modal content and
      // permit the user proceed.
      setCurrentStep(1);
    },
    [orgFlags]
  );

  const vendorWords = getVendorWords(assuranceType);

  const submit = async () => {
    setIsSaving(true);

    const waiver: IVendorRiskWaiverCreateRequest = {
      datastoreVendorID: vendorId,
      riskID: risk.baseId ?? risk.id,
      riskType: risk.riskType,
      isAllDomains:
        selection.hostnameSelection.allSelected &&
        selection.hostnameSelection.includeFuture,
      domains: selection.hostnameSelection.selected,
      surveys: selection.selectedQuestionnaires,
      publicSurveys: selection.selectedPublicQuestionnaires,
      approverEmail: approverEmail.value,
      justification: justification.value,
      expiresAt: expiryDate ? moment(expiryDate) : undefined,
      adjustedSeverity: adjustedSeverity,
    };

    await dispatch(createVendorRiskWaiver(waiver))
      .then(() => {
        dispatch(fetchVendorSummaryAndCloudscans(vendorId, true));
        dispatch(fetchVendorRiskOverviewReport(true));
        dispatch(fetchVendorRiskBreakdownReport(true));
        dispatch(fetchVendorPortfolioRiskProfile());
        dispatch(fetchCustomerVendorsData(true));
        dispatch(clearDomains());
        if (risk.riskType == VendorSummaryRiskType.Survey) {
          dispatch(getSurveyListV2(true, vendorId, 0, surveyListPageLimit));
        }
        if (vendorAssessmentId) {
          dispatch(invalidateVendorAssessmentRisks(vendorAssessmentId));
        }

        let successMsg = `Risk ${
          waiverType === WaiverType.SeverityAdjustment ? "adjustment" : "waiver"
        } for ${vendorWords.singular} created successfully.`;
        if (!waiver.approverEmail) {
          successMsg += ` It might take a few seconds for the ${vendorWords.singular} security rating to update.`;
        }

        dispatch(addDefaultSuccessAlert(successMsg));
        trackEvent("VendorRiskWaiver_Created");
        onClose();
      })
      .catch(() => {
        dispatch(
          addDefaultUnknownErrorAlert(
            `Failed to create ${vendorWords.singularTitleCase} Risk Waiver. Please contact upguard support.`
          )
        );
      })
      .finally(() => setIsSaving(false));
  };

  const waivedRiskAssets = calculateWaivedAssetsForRisks([risk], false, []);

  let headerText = "Create Vendor Risk Waiver";
  if (waiverType === WaiverType.SeverityAdjustment) {
    headerText = "Create Vendor Risk Adjustment";
  }

  return (
    <ModalV2
      width={1000}
      className={"create-vendor-risk-waiver-modal"}
      active={active}
      onClose={onClose}
      headerContent={headerText}
      disallowClose={isSaving}
      footerClassName={"create-vendor-risk-waiver-modal-footer"}
      footerContent={
        <>
          {currentStep == 1 && (
            <>
              <div className={"btn-group"}>
                <Button tertiary onClick={onClose} disabled={isSaving}>
                  Cancel
                </Button>
                <Button
                  filledPrimary
                  arrow
                  disabled={
                    risk ? !isAssetSelectionValid(risk, selection) : true
                  }
                  onClick={() => setCurrentStep(2)}
                >
                  Next
                </Button>
              </div>
            </>
          )}
          {currentStep == 2 && (
            <>
              <div className={"btn-group"}>
                <Button onClick={() => setCurrentStep(1)} leftArrow>
                  Previous
                </Button>
              </div>
              <div className={"btn-group"}>
                <Button tertiary onClick={() => onClose()} disabled={isSaving}>
                  Cancel
                </Button>
                <Button
                  filledPrimary
                  arrow
                  onClick={() => setCurrentStep(3)}
                  disabled={
                    !isWaiverDetailsValid(
                      approverEmail.isValid,
                      justification.value
                    )
                  }
                >
                  Next
                </Button>
              </div>
            </>
          )}
          {currentStep == 3 && (
            <>
              <div className={"btn-group"}>
                <Button onClick={() => setCurrentStep(2)} leftArrow>
                  Previous
                </Button>
              </div>
              <div className={"btn-group"}>
                <Button tertiary onClick={() => onClose()} disabled={isSaving}>
                  Cancel
                </Button>
                <Button
                  filledPrimary
                  onClick={submit}
                  loading={isSaving}
                  disabled={
                    !isWaiverDetailsValid(
                      approverEmail.isValid,
                      justification.value
                    ) || !isAssetSelectionValid(risk, selection)
                  }
                >
                  Save changes
                </Button>
              </div>
            </>
          )}
        </>
      }
    >
      {currentStep === 0 ? (
        <LoadingBanner />
      ) : (
        <>
          <Steps
            steps={[
              {
                id: "1",
                text: "Select assets",
                onClick: () => setCurrentStep(1),
              },
              {
                id: "2",
                text: "Configure",
                onClick: () => setCurrentStep(2),
                disabled: !isAssetSelectionValid(risk, selection),
              },
              {
                id: "3",
                text: "Review",
                onClick: () => setCurrentStep(3),
                disabled: !isWaiverDetailsValid(
                  approverEmail.isValid,
                  justification.value
                ),
              },
            ]}
            currentStep={currentStep}
          />
          {currentStep === 1 && (
            <div>
              <RiskMini
                risk={risk}
                assetText={getRiskAssetText(
                  risk,
                  true,
                  selection,
                  waivedRiskAssets
                )}
              />
              <RiskAssetSelectionForWaiver
                risk={risk}
                selected
                selection={selection}
                vendorId={vendorId}
                onSelectionChange={setSelection}
                managedOrgId={managedOrgId}
                isVendorManagementAnalyst={isManagementAnalystSession}
              />
            </div>
          )}
          {currentStep === 2 && (
            <WaiverDetailsStep
              waiverType={waiverType}
              approverRequired={approverRequired}
              approverEmail={approverEmail.value}
              justification={justification.value}
              onJustificationChanged={(val) =>
                setJustification({ value: val, isValid: val.length > 0 })
              }
              onApproverChanged={(approverEmail: string, valid: boolean) => {
                setApproverEmail({ value: approverEmail, isValid: valid });
              }}
              expiry={expiryDate}
              onExpiryChanged={setExpiryDate}
              assuranceType={assuranceType}
              origSeverity={SeverityAsString(risk.severity)}
              severity={adjustedSeverity}
              onSeverityChanged={setAdjustedSeverity}
              hideSeverities={[SeverityAsString(risk.severity)]}
            />
          )}
          {currentStep === 3 && (
            <ConfirmStep
              waiverType={waiverType}
              risk={risk}
              selectedAssets={selection}
              justification={justification.value}
              assuranceType={assuranceType}
              expiry={expiryDate}
              approverEmail={approverEmail.value}
              adjustedSeverity={adjustedSeverity}
            />
          )}
        </>
      )}
    </ModalV2>
  );
};

export default CreateVendorRiskWaiverModal;
