interface RiskProfileDisplayPrefs {
  tab: "only" | "with_subsidiaries";
  passedRisks: boolean;
  waivedRisks: boolean;
}

const riskProfileDisplayPrefsDefaults: RiskProfileDisplayPrefs = {
  tab: "only",
  passedRisks: false,
  waivedRisks: false,
};

const getRiskProfileInstanceSessionStorageKey = (
  vendorId?: number,
  isSubsidiary?: boolean
) => {
  return `riskProfile_${vendorId || "org"}_${isSubsidiary || "false"}`;
};

export const getRiskProfileDisplayPrefs = (
  vendorId?: number,
  isSubsidiary?: boolean
) => {
  const existingJSON = sessionStorage.getItem(
    getRiskProfileInstanceSessionStorageKey(vendorId, isSubsidiary)
  );

  if (existingJSON) {
    try {
      return JSON.parse(existingJSON) as RiskProfileDisplayPrefs;
    } catch (e) {
      // Ignore - we'll just return the defaults
    }
  }

  return riskProfileDisplayPrefsDefaults;
};

export const setRiskProfileDisplayPrefs = (
  newPrefs: Partial<RiskProfileDisplayPrefs>,
  vendorId?: number,
  isSubsidiary?: boolean
) => {
  const existingPrefs = getRiskProfileDisplayPrefs(vendorId, isSubsidiary);

  const prefs = {
    ...existingPrefs,
    ...newPrefs,
  };

  sessionStorage.setItem(
    getRiskProfileInstanceSessionStorageKey(vendorId, isSubsidiary),
    JSON.stringify(prefs)
  );
};
