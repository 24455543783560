import { RiskAssessmentEvidencePage } from "../../types/evidence";
import XTable, { XTableCell } from "../../../_common/components/core/XTable";
import { FC, memo } from "react";
import { evidenceCategoryToStr } from "../shared_profile/SharedProfileEvidencePagesTable";
import "../../style/components/vendor_assessment/VendorAssessmentPagesTable.scss";
import { usePagination } from "../../../_common/hooks";

interface VendorAssessmentV3PagesTableProps {
  pages: RiskAssessmentEvidencePage[];
  selectable: boolean;
  onSelect?: (category: string, url: string) => void;
  onClick: (category: string, url: string) => void;
}

const VendorAssessmentV3PagesTable: FC<VendorAssessmentV3PagesTableProps> = ({
  onClick,
  onSelect,
  pages,
  selectable,
}) => {
  const [items, page, numPages, setPage] = usePagination(pages, 10);

  return (
    <XTable
      className={"vendor-assessment-pages-table"}
      columnHeaders={[
        { id: "category", text: "Page category" },
        { id: "link", text: "Link" },
      ]}
      selectable={selectable}
      onSelectClick={
        onSelect
          ? (id) => {
              const parts = (id as string).split("<>");
              onSelect(parts[1], parts[0]);
            }
          : undefined
      }
      pagination={{
        currentPage: page,
        totalPages: numPages,
        onPageChange: setPage,
        hidePaginationIfSinglePage: true,
      }}
      iconOptions
      rows={items.map((p) => ({
        id: `${p.url}<>${p.category}`,
        selected: selectable && p.selected,
        onClick: () => onClick(p.url, p.category),
        iconOptions: [
          {
            icon: <i className={"cr-icon-chevron"} />,
            id: "chevron",
            onClick: () => onClick(p.url, p.category),
          },
        ],
        cells: [
          <XTableCell key={"category"} className={"category-cell"}>
            {evidenceCategoryToStr(p.category)}
          </XTableCell>,
          <XTableCell key={"link"}>{p.url}</XTableCell>,
        ],
      }))}
    />
  );
};

export default memo(VendorAssessmentV3PagesTable);
