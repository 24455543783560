import PropTypes from "prop-types";
import classnames from "classnames";

import LoadingBanner from "./LoadingBanner";

import "../../style/components/core/Card.scss";

export const CardRow = ({ children }) => (
  <div className="card-row">{children}</div>
);

const Card = ({
  children,
  loading,
  loadingOverlay,
  error,
  id,
  className,
  onClick,
}) => (
  <div className={`card ${className}`} id={id} onClick={onClick}>
    <div
      className={classnames({
        "card-loading": loading || loadingOverlay,
        "card-error": error,
      })}
    >
      {children}
      <div
        className={classnames(["loading-overlay", { show: loadingOverlay }])}
      >
        <LoadingBanner tight />
      </div>
    </div>
  </div>
);

Card.propTypes = {
  children: PropTypes.any.isRequired,
  className: PropTypes.string,
  loading: PropTypes.bool,
  loadingOverlay: PropTypes.bool,
  error: PropTypes.bool,
  id: PropTypes.string,
  onClick: PropTypes.func,
};

Card.defaultProps = {
  loading: false,
  loadingOverlay: false,
  error: false,
  id: null,
  className: "",
  onClick: (x) => x,
};

export default Card;
