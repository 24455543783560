import { useModalV2 } from "../../_common/components/ModalV2";
import FeatureIntroModal from "../../_common/components/modals/FeatureIntroModal";
import { useCallback } from "react";
import IntroStep1 from "../images/content-library-intro-step-1.svg";
import IntroStep2 from "../images/content-library-intro-step-2.svg";
import IntroStep3 from "../images/content-library-intro-step-3.svg";
import IntroStep4 from "../images/content-library-intro-step-4.svg";

export const useContentLibraryFeatureIntroModal = (onComplete?: () => void) => {
  const [openModal, modalComponent] = useModalV2(FeatureIntroModal);

  const openFeatureIntroModal = useCallback(() => {
    openModal({
      trackingName: "ContentLibrary",
      onComplete,
      steps: [
        {
          title: "Introducing the Content Library",
          description: (
            <p>
              A central place for all your document uploads from across your
              workspace.
            </p>
          ),
          image: IntroStep1,
        },
        {
          title: "Control what you share",
          description: (
            <p>
              The Content Library brings all of your source material together in
              one place, making it easy to share with your team and your
              customers.
            </p>
          ),
          image: IntroStep2,
        },
        {
          title: "AI Autofill powered by Excel questionnaires",
          description: (
            <p>
              Add past Excel questionnaires to your Content Library to generate
              answers for you with AI Autofill.
            </p>
          ),
          image: IntroStep3,
        },
        {
          title: "Smart attachment suggestions",
          description: (
            <p>
              The Content Library makes it easier for you to attach documents
              when requested in questionnaires.
            </p>
          ),
          image: IntroStep4,
        },
      ],
    });
  }, [openModal, onComplete]);

  return [openFeatureIntroModal, modalComponent] as const;
};
