import { useCallback, useMemo, useState } from "react";
import CustomSelect, {
  Option,
  SelectItemType,
} from "../../../_common/components/CustomSelect";
import { OrgFlagType } from "../../../_common/types/organisations";
import { OrganisationUserEmailOrInvite } from "../../../_common/hooks/useOrganisationUsersAndInvites";
import LoadingBanner from "../../../_common/components/core/LoadingBanner";
import TextField from "../../../_common/components/TextField";
import UserDisplay from "../UserDisplay";
import "../../style/components/risk_waivers/NominatedApproverInput.scss";
import { useAppSelector } from "../../../_common/types/reduxHooks";
import classNames from "classnames";
import { SidePopupV2 } from "../../../_common/components/DismissablePopup";
import { SharedProperties } from "../../../_common/types/utils";
import ApproversAPI, {
  Approver,
  NominatedApproverFeature,
} from "../../../_common/api/approversAPI";
import OrganisationFlagsAPI from "../../../_common/api/organisationFlagsAPI";
import UserDisplayV2 from "../UserDisplayV2";
import { Link } from "react-router-dom";

const NominatedApproverTypeOrgFlagMapping: {
  [NominatedApproverFeature.BreachSightRiskWaivers]: OrgFlagType;
  [NominatedApproverFeature.VendorRiskRiskWaivers]: OrgFlagType;
  [NominatedApproverFeature.VendorRiskRiskAdjustments]: OrgFlagType;
} = {
  [NominatedApproverFeature.BreachSightRiskWaivers]:
    OrgFlagType.BreachSightNominateApprovers,
  [NominatedApproverFeature.VendorRiskRiskWaivers]:
    OrgFlagType.VendorRiskNominateApprovers,
  [NominatedApproverFeature.VendorRiskRiskAdjustments]:
    OrgFlagType.VendorRiskNominateApprovers,
};

export function matchesNominatedApproverSearch(
  search: string,
  approver: SharedProperties<OrganisationUserEmailOrInvite, Approver>
) {
  if (search) {
    const searchLower = search.toLocaleLowerCase();
    return (
      approver.name?.toLocaleLowerCase().includes(searchLower) ||
      approver.email.toLocaleLowerCase().includes(searchLower)
    );
  }
  return true;
}

interface NominatedApproverSelectProps {
  className?: string;
  value: string;
  type: NominatedApproverFeature;
  onSelectApprover: (email: string) => void;
}

function NominatedApproverSelect({
  className,
  value,
  type,
  onSelectApprover,
}: NominatedApproverSelectProps) {
  const { data: { approvers } = {} } = ApproversAPI.useListApproversV1Query();

  const [search, setSearch] = useState("");

  const filteredApprovers = useMemo(
    () =>
      approvers?.filter(
        (approver) =>
          approver.feature === type &&
          matchesNominatedApproverSearch(search, approver)
      ),
    [approvers, search]
  );

  const selectedApprover = useMemo(
    () => approvers?.find((a) => a.email === value),
    [approvers, value]
  );

  const handleDeleteSelectedApprover = useCallback(() => {
    onSelectApprover("");
  }, []);

  const currentUserEmail = useAppSelector(
    (state) => state.common.userData.emailAddress
  );

  const disabled = !!value;

  return (
    <div className={classNames(className, "nominated-approver-select")}>
      <CustomSelect
        disabled={disabled}
        placeholder={disabled ? "" : "Select an approver"}
        items={
          filteredApprovers?.slice(0, 5).map<Option>((approver) => {
            const isCurrentUser = approver.email === currentUserEmail;

            const userDisplay = (
              <UserDisplay
                avatar={approver.avatar}
                name={approver.name ?? ""}
                email={
                  !!approver.inviteId
                    ? `${approver.email} (invited)`
                    : approver.email
                }
              />
            );

            return {
              type: SelectItemType.Option,
              // Deliberately don't want to use `approver-invite` here
              // as it will look disabled in contact list.
              className: classNames("approver-option", {
                "approver-option-disabled": isCurrentUser,
              }),
              id: approver.id,
              onClick: isCurrentUser
                ? undefined // Current user is never allowed to self-approve
                : () => onSelectApprover(approver.email),
              content: isCurrentUser ? (
                <SidePopupV2
                  text={
                    <>
                      You cannot nominate yourself as an approver. Speak to your
                      admin or adjust this in{" "}
                      <Link to="/settings/approvals">Settings</Link>.
                    </>
                  }
                  position="top"
                  popupHoverable
                >
                  {userDisplay}
                </SidePopupV2>
              ) : (
                userDisplay
              ),
            };
          }) || []
        }
        onSearchChange={setSearch}
      />
      {selectedApprover ? (
        <UserDisplayV2
          avatar={selectedApprover.avatar ?? ""}
          name={selectedApprover.name ?? ""}
          email={
            !!selectedApprover.inviteId
              ? `${selectedApprover.email} (invited)`
              : selectedApprover.email
          }
          onDelete={handleDeleteSelectedApprover}
        />
      ) : (
        !!value && (
          // Current selected approver is not a nominated approver!?
          // This could theoretically occur if a nominated approver user/invite
          // is removed from an organisation - when any user/invite is removed,
          // the nominated approver records are cleaned up.
          <UserDisplayV2
            email={value}
            onDelete={handleDeleteSelectedApprover}
          />
        )
      )}
    </div>
  );
}

export interface NominatedApproverInputProps {
  type: NominatedApproverFeature;
  value: string;
  onChange: (email: string, emailValid: boolean) => void;
  required?: boolean;
}

export default function NominatedApproverInput({
  type,
  value,
  onChange,
  required,
}: NominatedApproverInputProps) {
  const { data: orgFlags } =
    OrganisationFlagsAPI.useGetOrganisationFlagsV1Query();
  const nominateApproversFlag = NominatedApproverTypeOrgFlagMapping[type];

  const handleSelectApprover = useCallback(
    (email: string) => onChange(email, !!email),
    []
  );

  if (!orgFlags) return <LoadingBanner />;

  const mustSelectFromNominations = !!orgFlags[nominateApproversFlag];

  return mustSelectFromNominations ? (
    <NominatedApproverSelect
      className="nominated-approver-input"
      type={type}
      value={value}
      onSelectApprover={handleSelectApprover}
    />
  ) : (
    <TextField
      className="nominated-approver-input"
      type="email"
      placeholder="Enter the approver's email address"
      value={value}
      required={required}
      onChanged={onChange}
    />
  );
}
