import { FC, useCallback, useEffect, useMemo, useState } from "react";
import ManageSettingsCard from "./ManageSettingsCardStyles";
import Button from "../../../_common/components/core/Button";
import ContentLibraryAPI from "../../../contentlibrary/api/contentLibraryAPI";
import { ErrorCardWithAction } from "../../../_common/components/EmptyCardWithAction";
import LoadingBanner from "../../../_common/components/core/LoadingBanner";
import PillLabel from "../PillLabel";
import { LabelColor } from "../../../_common/types/label";
import TextField from "../../../_common/components/TextField";
import { produce } from "immer";
import IconButton, { HoverColor } from "../../../_common/components/IconButton";
import { v4 as uuidv4 } from "uuid";
import InfoBanner, { BannerType } from "../InfoBanner";
import { DocumentType } from "../../../contentlibrary/types/contentLibrary.types";
import { useAppDispatch } from "../../../_common/types/reduxHooks";
import { addDefaultUnknownErrorAlert } from "../../../_common/reducers/messageAlerts.actions";
import { commaSeparatedList, pluralise } from "../../../_common/helpers";
import { SidePopupV2 } from "../../../_common/components/DismissablePopup";

export const typeNameMinLen = 2;
export const typeNameMaxLen = 50;

interface editedDocumentType {
  id: string;
  originalTypeName: string;
  newTypeName: string;
  isCustom: boolean;
  isNew: boolean;
  numDocuments: number;
}

const ManageContentLibraryDocumentTypesCard: FC = () => {
  const dispatch = useAppDispatch();
  const [editMode, setEditMode] = useState(false);
  const [changed, setChanged] = useState(false);
  const [saving, setSaving] = useState(false);

  const [editedTypes, setEditedTypes] = useState<editedDocumentType[]>([]);

  const {
    data: documentTypes,
    isLoading,
    isFetching,
    error,
    refetch,
  } = ContentLibraryAPI.useGetContentLibraryDocumentTypesListQuery();

  const setEditedTypesFromDocumentTypes = useCallback(() => {
    if (documentTypes) {
      const docTypes = documentTypes.types.map((t) => ({
        id: t.name,
        originalTypeName: t.name,
        newTypeName: t.name,
        isCustom: t.isCustom,
        isNew: false,
        numDocuments: t.numDocuments,
      }));

      // Sort the default ones to the start
      docTypes.sort((a, b) => {
        if (a.isCustom === b.isCustom) {
          return 0;
        }

        return a.isCustom ? 1 : -1;
      });

      setEditedTypes(docTypes);
      setChanged(false);
      setEditMode(false);
    }
  }, [documentTypes]);

  // Reset the edited state if we reload the type list
  useEffect(setEditedTypesFromDocumentTypes, [setEditedTypesFromDocumentTypes]);

  const updateTypeName = useCallback(
    (id: string, newTypeName: string) => {
      setChanged(true);
      setEditedTypes(
        produce(editedTypes, (draft) => {
          for (let i = 0; i < draft.length; i++) {
            if (draft[i].id === id) {
              draft[i].newTypeName = newTypeName;
              break;
            }
          }
        })
      );
    },
    [editedTypes]
  );

  const createType = useCallback(() => {
    setChanged(true);
    setEditedTypes(
      produce(editedTypes, (draft) => {
        draft.push({
          id: uuidv4(),
          originalTypeName: "",
          newTypeName: "",
          isCustom: true,
          isNew: true,
          numDocuments: 0,
        });
      })
    );
  }, [editedTypes]);

  const deleteType = useCallback(
    (id: string) => {
      setChanged(true);
      setEditedTypes(
        produce(editedTypes, (draft) => {
          draft.splice(
            draft.findIndex((t) => t.id === id),
            1
          );
        })
      );
    },
    [editedTypes]
  );

  const [validationErrors, anyDeletedWithItems] = useMemo(() => {
    const validationErrors: Record<string, true> = {};

    const originalCustomDocumentTypes = (documentTypes?.types ?? []).reduce(
      (prev: Record<string, DocumentType>, next) => {
        if (next.isCustom) {
          prev[next.name] = next;
        }
        return prev;
      },
      {}
    );

    editedTypes.forEach((t, i) => {
      if (!t.isCustom) {
        // Default types can't be edited so can't have errors
        return;
      }

      delete originalCustomDocumentTypes[t.originalTypeName];

      if (t.newTypeName.length < typeNameMinLen) {
        validationErrors[
          `All document types must be at least ${typeNameMinLen} characters.`
        ] = true;
      } else if (t.newTypeName.length > typeNameMaxLen) {
        validationErrors[
          `All document types must be at most ${typeNameMaxLen} characters.`
        ] = true;
      } else {
        const thisTypeName = t.newTypeName.toLowerCase().trim();
        const dupeIndex = editedTypes.findIndex(
          (t) => t.newTypeName.toLowerCase().trim() === thisTypeName
        );
        if (dupeIndex > -1 && dupeIndex !== i) {
          validationErrors[`Document types must be unique.`] = true;
        }
      }
    });

    // Any items remaining in originalCustomDocumentTypes have been marked for deletion -
    // see if any have documents assigned to them
    const anyDeletedWithItems = Object.values(originalCustomDocumentTypes)
      .filter((t) => t.numDocuments > 0)
      .map((t) => t.name);

    return [Object.keys(validationErrors), anyDeletedWithItems];
  }, [editedTypes, documentTypes]);

  const cancelChanges = useCallback(() => {
    setEditedTypesFromDocumentTypes();
    setChanged(false);
    setEditMode(false);
  }, [setEditedTypesFromDocumentTypes]);

  const [multiUpdateDocumentTypes] =
    ContentLibraryAPI.useMultiUpdateContentLibraryDocumentTypesMutation();

  const saveChanges = useCallback(async () => {
    // We need to gather a list of updates, creates and deletes based on the difference between the original portfolios
    // and the new list.
    if (!documentTypes || validationErrors.length > 0) {
      return;
    }

    const originalDocumentTypeMap = documentTypes.types.reduce(
      (prev: Record<string, DocumentType | undefined>, next: DocumentType) => {
        prev[next.name] = next;
        return prev;
      },
      {}
    );

    const editedDocumentTypeMap = editedTypes.reduce(
      (
        prev: Record<string, editedDocumentType | undefined>,
        next: editedDocumentType
      ) => {
        prev[next.id] = next;
        return prev;
      },
      {}
    );

    const deletedDocumentTypeNames: string[] = [];
    const updatedDocumentTypes: editedDocumentType[] = [];
    const createdDocumentTypeNames: string[] = [];

    // First run through the original document types and see if any have been deleted
    Object.keys(originalDocumentTypeMap).forEach((name) => {
      if (!editedDocumentTypeMap[name]) {
        deletedDocumentTypeNames.push(name);
      }
    });

    // Next run through the edited document types and see if any have been created or updated
    editedTypes.forEach((editedType) => {
      if (!editedType.isCustom) {
        return;
      }

      if (editedType.isNew) {
        createdDocumentTypeNames.push(editedType.newTypeName);
      } else if (editedType.newTypeName !== editedType.originalTypeName) {
        updatedDocumentTypes.push(editedType);
      }
    });

    // Now do the actual updates
    setSaving(true);

    try {
      await multiUpdateDocumentTypes({
        deletedDocumentTypeNames,
        updatedDocumentTypes,
        createdDocumentTypeNames,
      });
    } catch (e) {
      console.error(e);
      dispatch(
        addDefaultUnknownErrorAlert(
          "An error occurred while updating document types. Please contact UpGuard Support."
        )
      );
    }

    setSaving(false);
  }, [
    dispatch,
    documentTypes,
    validationErrors,
    editedTypes,
    multiUpdateDocumentTypes,
  ]);

  if (!isFetching && error) {
    return <ErrorCardWithAction action={refetch} actionText="Try again" />;
  }

  return (
    <ManageSettingsCard className="manage-content-library-documents">
      <div className="header">
        Document Types
        <div className="header-right">
          {editMode ? (
            <>
              <Button tertiary onClick={cancelChanges}>
                Cancel changes
              </Button>
              <SidePopupV2
                position={"left"}
                text={
                  validationErrors.length > 0
                    ? validationErrors.join(" ")
                    : undefined
                }
              >
                <Button
                  primary
                  disabled={!changed || validationErrors.length > 0}
                  loading={saving}
                  onClick={saveChanges}
                >
                  Save changes
                </Button>
              </SidePopupV2>
            </>
          ) : !isLoading ? (
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                setEditMode(true);
              }}
            >
              Edit
            </a>
          ) : undefined}
        </div>
      </div>
      {isLoading ? (
        <LoadingBanner />
      ) : (
        <>
          <div className="card-content half-grid">
            <div>
              <p>
                Create document types to organize your Content Library files and
                more easily respond to customer requests.
              </p>
            </div>
            <div className="edit-grid">
              {editedTypes.map((t) => (
                <div className="edit-grid-row" key={t.id}>
                  <div className="first-col">
                    {!t.isCustom ? (
                      <PillLabel color={LabelColor.Blue}>Default</PillLabel>
                    ) : t.isNew ? (
                      <PillLabel color={LabelColor.Green}>New</PillLabel>
                    ) : undefined}
                  </div>
                  <div className="input-col">
                    <TextField
                      placeholder="Enter a name for this document type"
                      disabled={!t.isCustom || saving || !editMode}
                      hideCharCount={!t.isCustom || saving || !editMode}
                      value={t.newTypeName}
                      onChanged={(newName) => updateTypeName(t.id, newName)}
                      maxLength={typeNameMaxLen}
                    />
                  </div>
                  <div className="actions-col">
                    <div className="actions-icon-container">
                      {editMode && (
                        <IconButton
                          icon={<div className="cr-icon-trash" />}
                          onClick={() => deleteType(t.id)}
                          disabled={!t.isCustom || saving}
                          hoverText={
                            !t.isCustom
                              ? "Default document types cannot be deleted."
                              : ""
                          }
                          hoverColor={HoverColor.Red}
                        />
                      )}
                    </div>
                  </div>
                  <div className="right-meta">{t.numDocuments} in use</div>
                </div>
              ))}
              {editMode && (
                <Button
                  tertiary
                  onClick={createType}
                  className="add-btn"
                  disabled={saving}
                >
                  + Add new document type
                </Button>
              )}
              {anyDeletedWithItems.length > 0 && (
                <InfoBanner
                  type={BannerType.WARNING}
                  message={
                    <>
                      {anyDeletedWithItems.length === 1 ? (
                        <>
                          Document type &apos;{anyDeletedWithItems[0]}&apos; is
                          currently in use.
                        </>
                      ) : (
                        <>
                          Document types{" "}
                          {commaSeparatedList(
                            anyDeletedWithItems.map((name) => `'${name}'`)
                          )}{" "}
                          are currently in use.
                        </>
                      )}{" "}
                      If you delete{" "}
                      {pluralise(anyDeletedWithItems.length, "it", "them")}, any
                      associated documents will revert to document type:
                      Unknown.
                    </>
                  }
                />
              )}
            </div>
          </div>
        </>
      )}
    </ManageSettingsCard>
  );
};

export default ManageContentLibraryDocumentTypesCard;
