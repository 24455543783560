import userbaseApi, { UserBaseTagTypes } from "../api/userbase.api";
import { ScanStatus } from "../api/types";
import { useAppDispatch } from "../../_common/types/reduxHooks";
import {
  addDefaultSuccessAlert,
  addDefaultUnknownErrorAlert,
  addDefaultWarningAlert,
} from "../../_common/reducers/messageAlerts.actions";

export const DefaultPollMaxRetries = 8;
export const DefaultPollFreqMs = 10000;

// usePollForScan returns a function that can be used to kick off
// polling for the result of a scan
export const usePollForScan = (
  pollFreqMs = DefaultPollFreqMs,
  maxRetries = DefaultPollMaxRetries,
  silent = false
): (() => void) => {
  const dispatch = useAppDispatch();
  const [checkScanStatus] = userbaseApi.useLazyGetUserRiskScanStatusV1Query();

  const pollForScanUpdate = (retriesRemaining: number) => {
    setTimeout(() => {
      checkScanStatus()
        .unwrap()
        .then((data) => {
          if (data?.status === ScanStatus.Completed) {
            dispatch(
              userbaseApi.util.invalidateTags(
                Object.keys(UserBaseTagTypes) as UserBaseTagTypes[]
              )
            );

            if (!silent) {
              dispatch(addDefaultSuccessAlert(`Scan completed`));
            }
          } else if (data?.status === ScanStatus.Errored) {
            if (!silent) {
              dispatch(
                addDefaultUnknownErrorAlert(`Error encountered during scan`)
              );
            }
          } else {
            if (retriesRemaining === 0) {
              if (!silent) {
                dispatch(
                  addDefaultWarningAlert(`Scan ongoing`, [
                    "Check back later for the result",
                  ])
                );
              }
              return;
            }

            pollForScanUpdate(retriesRemaining - 1);
          }
        });
    }, pollFreqMs);
  };

  return () => {
    // init the polling on a timer
    pollForScanUpdate(maxRetries);
    // fetch the status immediately and return the promise
    // so that we update the scan status on the cache right away
    return checkScanStatus();
  };
};
