import { FC, ReactNode, Ref, useEffect, useState } from "react";
import DropdownV2, {
  DropdownItem,
  DropdownSeparator,
  DropdownSubheader,
  DropdownV2Handle,
  HoverSubmenuItem,
} from "./core/DropdownV2";
import { DefaultThunkDispatch } from "../types/redux";
import { FetchCyberRiskUrl } from "../api";
import { DefaultRootState } from "react-redux";
import { History } from "history";
import ModalV2, { BaseModalProps, useModalV2 } from "./ModalV2";
import ModalForm from "../../vendorrisk/components/modals/ModalForm";
import TextField, { MaxLengthType } from "./TextField";
import Button from "./core/Button";
import "../style/components/HelpAndSupportDropdown.scss";
import { sendSalesNotifyRequest } from "../reducers/commonActions";
import {
  addDefaultSuccessAlert,
  addDefaultUnknownErrorAlert,
} from "../reducers/messageAlerts.actions";
import { contactSupport } from "../helpers";
import { breachSightProductDisplayName } from "../types/products";

interface IReleaseNoteSummary {
  title: string;
  blurb: string;
  url: string;
  screenshotURL: string;
  createdAt: string;
}

const fetchReleaseNotes = () => {
  return async (
    dispatch: DefaultThunkDispatch,
    getState: () => DefaultRootState
  ): Promise<IReleaseNoteSummary[]> => {
    try {
      return await FetchCyberRiskUrl<IReleaseNoteSummary[]>(
        "releasenotes/v1/",
        {},
        null,
        dispatch,
        getState
      );
    } catch (e) {
      throw e;
    }
  };
};

interface IContactSalesModalProps extends BaseModalProps {
  dispatch: DefaultThunkDispatch;
}

export const ContactSalesModal: FC<IContactSalesModalProps> = ({
  dispatch,
  active,
  onClose,
}) => {
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const onSubmit = () => {
    setLoading(true);
    dispatch(sendSalesNotifyRequest(message))
      .then(() => {
        dispatch(
          addDefaultSuccessAlert(
            "Message sent. A representative from UpGuard will be in contact with you soon."
          )
        );
        setLoading(false);
        onClose();
      })
      .catch((e) => {
        console.error(e);
        setLoading(false);
        dispatch(
          addDefaultUnknownErrorAlert(
            "An error occurred sending your message. Please contact us directly at sales@upguard.com."
          )
        );
      });
  };

  return (
    <ModalV2
      active={active}
      onClose={onClose}
      className="contact-sales-modal"
      headerContent="Contact UpGuard"
      footerContent={
        <>
          <Button tertiary onClick={onClose}>
            Cancel
          </Button>
          <Button
            primary
            disabled={!message}
            onClick={onSubmit}
            loading={loading}
          >
            Send message
          </Button>
        </>
      }
    >
      <ModalForm>
        <div className="form-section">
          <div className="form-section-desc">
            <span>Send a message to UpGuard</span>
            <p>What would you like to know?</p>
          </div>
          <div className="form-section-input">
            <TextField
              value={message}
              onChanged={(val) => setMessage(val)}
              multiLine
              placeholder="Enter a message"
              maxLength={MaxLengthType.longerReasonableLength}
            />
          </div>
        </div>
      </ModalForm>
    </ModalV2>
  );
};

interface IHelpAndSupportDropdownProps {
  dispatch: DefaultThunkDispatch;
  history: History;
  popupItem: ReactNode;
  userInNonFreeAccount: boolean;
  orgHasMultiProductNav?: boolean;
  onActiveChange?: (active: boolean) => void;
  dropDownRef?: Ref<DropdownV2Handle>;
}

// This is intended to be used as a direct child of DropdownV2
const HelpAndSupportDropdown: FC<IHelpAndSupportDropdownProps> = ({
  dispatch,
  history,
  userInNonFreeAccount,
  popupItem,
  orgHasMultiProductNav,
  onActiveChange,
  dropDownRef,
}) => {
  const [releaseNotes, setReleaseNotes] = useState<IReleaseNoteSummary[]>([]);
  const [openContactSalesModal, contactSalesModal] =
    useModalV2(ContactSalesModal);

  useEffect(() => {
    dispatch(fetchReleaseNotes())
      .then((releaseNotes) => setReleaseNotes(releaseNotes))
      .catch((e) => {
        console.error(e);
      });
  }, [dispatch]);

  return (
    <>
      <DropdownV2
        ref={dropDownRef}
        className="bottom-dropdown"
        popupItem={popupItem}
        onActiveChange={onActiveChange}
      >
        <>
          {orgHasMultiProductNav && (
            <>
              <DropdownSubheader className="help-and-support-dropdown-header">
                Help & Support
              </DropdownSubheader>
              <DropdownSeparator />
            </>
          )}
          {userInNonFreeAccount && (
            <>
              {!orgHasMultiProductNav && ( // Hide quick start for multi-product nav
                <DropdownItem onClick={() => history.push("/quickstart")}>
                  <div className="cr-icon-lightning" />
                  Quick start guide
                </DropdownItem>
              )}
              <DropdownItem
                onClick={() =>
                  window.open(
                    "https://help.upguard.com/en/collections/2186718-the-upguard-platform"
                  )
                }
              >
                The UpGuard Platform
              </DropdownItem>
              <DropdownItem
                onClick={() =>
                  window.open(
                    "https://help.upguard.com/en/collections/1911024-upguard-breachsight"
                  )
                }
              >
                UpGuard {`${breachSightProductDisplayName}`}
              </DropdownItem>
              <DropdownItem
                onClick={() =>
                  window.open(
                    "https://help.upguard.com/en/collections/2186255-upguard-vendor-risk"
                  )
                }
              >
                UpGuard VendorRisk
              </DropdownItem>
              {releaseNotes.length > 0 ? (
                <HoverSubmenuItem itemText="New releases">
                  {releaseNotes.map((r) => (
                    <DropdownItem
                      key={r.createdAt}
                      onClick={() => window.open(r.url)}
                    >
                      <div title={r.title} className="new-release-item">
                        {r.title}
                      </div>
                    </DropdownItem>
                  ))}
                  <DropdownSeparator />
                  <DropdownItem
                    onClick={() => window.open("https://upguard.com/releases")}
                  >
                    View all
                  </DropdownItem>
                </HoverSubmenuItem>
              ) : (
                <DropdownItem
                  onClick={() => window.open("https://upguard.com/releases")}
                >
                  New releases
                </DropdownItem>
              )}
              <DropdownSeparator />
            </>
          )}
          <DropdownItem
            onClick={() =>
              openContactSalesModal({
                dispatch,
              })
            }
          >
            Contact Us
          </DropdownItem>
          <DropdownItem onClick={contactSupport}>Contact support</DropdownItem>
        </>
      </DropdownV2>
      {contactSalesModal}
    </>
  );
};

export default HelpAndSupportDropdown;
