import { FC } from "react";
import ReportCard from "../../_common/components/ReportCard";
import UserBaseAPI from "../api/userbase.api";
import LoadingBanner from "../../_common/components/core/LoadingBanner";
import Doughnut from "./Doughnut";
import Button from "../../_common/components/core/Button";
import { useDefaultHistory } from "../../_common/types/router";
import { applicationsUrl, dashboardUrl } from "../UserBaseAppRouter";
import { pluralise } from "../../_common/helpers";
import { AppStatus, DashboardStats } from "../api/types";
import "./OrgAppsOverview.scss";
import { hexColorForAppStatus } from "../helpers/apps";

const OrgAppsOverview: FC = ({}) => {
  const history = useDefaultHistory();
  const { data, isLoading } = UserBaseAPI.useGetUserBaseDashboardStatsV1Query();

  const onViewApps = () => {
    history.push(applicationsUrl, {
      backContext: {
        backTo: dashboardUrl,
        backToText: `Back to Dashboard`,
      },
    });
  };

  const noStats: DashboardStats = {
    numUsers: 0,
    numApps: 0,
    numPermissions: 0,
    appStatusCounts: [],
  };

  const stats = data?.stats ?? noStats;

  const doughnutData = [
    {
      label: "Approved",
      count: getAppStatusCount(stats, AppStatus.ApprovedAppStatus),
      hexColor: hexColorForAppStatus(AppStatus.ApprovedAppStatus),
    },
    {
      label: "Not Approved",
      count: getAppStatusCount(stats, AppStatus.NotApprovedAppStatus),
      hexColor: hexColorForAppStatus(AppStatus.NotApprovedAppStatus),
    },
    {
      label: "In Review",
      count: getAppStatusCount(stats, AppStatus.InReviewAppStatus),
      hexColor: hexColorForAppStatus(AppStatus.InReviewAppStatus),
    },
    {
      label: "Needs Review",
      count: getAppStatusCount(stats, AppStatus.NeedsReviewAppStatus),
      hexColor: hexColorForAppStatus(AppStatus.NeedsReviewAppStatus),
    },
  ].filter((d) => d.count > 0); // only display non-empty entries

  return (
    <ReportCard newStyles className="userbase-org-apps-overview">
      <div className="header">
        <span>Apps</span>
        <div>
          <Button tertiary onClick={onViewApps}>
            View all <div className="cr-icon-arrow-right" />
          </Button>
        </div>
      </div>
      {isLoading && <LoadingBanner />}
      {!isLoading && (
        <div className={"content"}>
          <Doughnut
            data={doughnutData}
            totalLabel={pluralise(stats.numApps, "App", "Apps")}
          />
        </div>
      )}
    </ReportCard>
  );
};

function getAppStatusCount(
  stats: DashboardStats,
  appStatus: AppStatus
): number {
  // Look for the app status in the appStatusCounts array
  const appStatusCount = (stats.appStatusCounts ?? []).find(
    (status) => status.appStatus === appStatus
  );
  return appStatusCount?.count ?? 0;
}
export default OrgAppsOverview;
