import { locationState } from "../../_common/types/router";
import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";

import { RouteComponentProps } from "react-router";
import {
  cloneDeep as _cloneDeep,
  debounce as _debounce,
  get as _get,
  noop,
} from "lodash";

import "../style/views/EditQuestionnaireAutomation.scss";
import PageHeader from "../../_common/components/PageHeader";
import { Location } from "history";
import {
  autosaveAutomationRecipe,
  cancelAutomationRecipe,
  fetchAutomationForSurveyType,
  fetchAutomationRecipe,
  fetchQuestionsForSurveyType,
  saveAutomationRecipe,
} from "../reducers/questionnaireAutomation.actions";
import {
  IAnswerWeights,
  IAutomationOutputMapping,
  IAutomationQuestion,
  IAutomationRecipe,
  IAutomationSimpleRule,
  IAutomationTest,
  IAutomationTestOperator,
  IAutomationTreeNode,
  IAutomationTreeNodeOperator,
  IAutomationWeight,
  IAutomationWeights,
} from "../types/automation";
import { Steps } from "../../_common/components/StepsWithSections";
import NameAutomationStep from "../components/automation/NameAutomationStep";
import DefineRulesStep, {
  operatorIsForStrings,
} from "../components/automation/DefineRulesStep";
import DefineAdvancedRulesStep from "../components/automation/DefineAdvancedRulesStep";
import DefineAdvancedWeightsStep from "../components/automation/DefineAdvancedWeightsStep";
import {
  fetchVendorAttributeDefinitions,
  VendorAttributeDefinition,
  VendorAttributeDefinitionType,
} from "../reducers/vendorAttributes.actions";
import {
  OrgAccessRelationshipQuestionnaireAutomation,
  WriteQuestionnaireAutomation,
} from "../../_common/permissions";
import EmptyCard from "../components/EmptyCard";
import Button from "../../_common/components/core/Button";
import Icon from "../../_common/components/core/Icon";
import ActionBar from "../../_common/components/ActionBar";
import {
  addDefaultUnknownErrorAlert,
  addDefaultSuccessAlert,
} from "../../_common/reducers/messageAlerts.actions";
import LoadingBanner from "../../_common/components/core/LoadingBanner";
import Autosave from "../components/automation/Autosave";
import { createUUID } from "../../survey_builder/helpers";
import { setAutomationRecipe } from "../reducers/cyberRiskActions";
import { appConnect, useAppDispatch } from "../../_common/types/reduxHooks";
import { ILabel, LabelClassification } from "../../_common/types/label";
import { sortLabels } from "../components/labels/LabelChoiceSet";

const autosaveInterval = 2000;
const autosaveDebounceDelayMs = 1000;

const MISSING_ANSWER_ERROR = "An answer is required";
const MISSING_QUESTION_ERROR = "A question is required";
const MISSING_OPERATOR_ERROR = "An operator is required";

export const AutomationAttributeTypes = {
  Tier: "tier",
  Labels: "label",
  Portfolios: "portfolio",
  Custom: "custom",
};

export const QuestionTypes = {
  Section: "Section",
  SingleSelect: "Single select",
  MultiSelect: "Multi select",
  FreeText: "Free text",
  Upload: "File upload",
};

export enum EditQuestionnairePages {
  EditAutomationName = 1,
  EditAutomationRules = 2,
  EditAutomationAdvancedWeights = 3,
  EditAutomationAdvancedRules = 4,
}

export enum DataEntryErrorAttribute {
  None = "",
  Question = "qn",
  Operator = "op",
  Action = "action",
  ActionQuestion = "action_question",
  Answer = "ans",
  CustomAttribute = "cust",
  NoneWeight = "nonewgt",
  Max = "max",
  Min = "min",
}

export interface IDataEntryErrors {
  automationId: number;
  [page: number]: {
    [key: string]: string;
  };
}

interface IEditQuestionnaireAutomationOwnProps
  extends RouteComponentProps<{
    surveyTypeId: string;
    recipeUUID: string;
    isEditing: string;
  }> {
  location: Location<locationState>;
}

interface IEditQuestionnaireAutomationConnectedProps {
  surveyTypeId: number;
  recipeUUID: string;
  isEditing: boolean;
  automation?: IAutomationRecipe;
  vendorAttributeDefinitions?: VendorAttributeDefinition[];
  questions?: IAutomationQuestion[];
  loading: boolean;
  autosaveLoading?: boolean;
  autosaveError?: string;
  syntaxError?: string;
  orgHasAutomationEnabled: boolean;
  userHasWriteAutomationEnabled: boolean;
  labels: ILabel[];
}

type EditQuestionnaireAutomationProps = IEditQuestionnaireAutomationOwnProps &
  IEditQuestionnaireAutomationConnectedProps;

// floating point number pattern
const numericRegexp = /^[+-]?([0-9]*[.])?[0-9]+$/;

const EditQuestionnaireAutomation: FC<EditQuestionnaireAutomationProps> = ({
  history,
  location,
  loading,
  surveyTypeId,
  recipeUUID,
  isEditing,
  vendorAttributeDefinitions,
  automation,
  questions,
  orgHasAutomationEnabled,
  userHasWriteAutomationEnabled,
  autosaveLoading,
  autosaveError,
  syntaxError,
  labels,
}) => {
  const dispatch = useAppDispatch();
  const [currentStep, setCurrentStep] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [automationSaving, setAutomationSaving] = useState(false);
  const [automationCanceling, setAutomationCanceling] = useState(false);
  const [dataEntryErrors, setDataEntryErrors] = useState(
    {} as IDataEntryErrors
  );
  const [textEntries, setTextEntries] = useState(
    {} as {
      [page: number]: {
        [key: string]: string;
      };
    }
  );

  const updatesExist = useRef(false);
  const editingRecipe = useRef({} as IAutomationRecipe);
  const [, setForceUpdate] = useState(0);

  const setUpdatesExist = (exist: boolean) => {
    updatesExist.current = exist;
  };
  const setEditingRecipe = (recipe: IAutomationRecipe) => {
    // we need to directly update the recipe,
    // doing a deep copy to unlink it from state store
    editingRecipe.current = _cloneDeep(recipe);
  };

  const vendorAttributeDefinitionCache = useMemo(() => {
    const cache = {} as {
      [id: number]: VendorAttributeDefinition;
    };
    vendorAttributeDefinitions?.forEach((a) => {
      cache[a.id] = a;
    });
    return cache;
  }, [vendorAttributeDefinitions]);

  const automationCustomAttribute = useMemo(() => {
    if (automation?.customAttributeId) {
      return vendorAttributeDefinitionCache[automation?.customAttributeId];
    }
    return undefined;
  }, [automation, vendorAttributeDefinitionCache]);

  // On initial load, get the selected automation instance, the set of custom attributes defined by the org and
  // the set of questions defined by the survey type.
  useEffect(() => {
    try {
      // Also make sure we have loaded the org flags
      if (recipeUUID) {
        dispatch(fetchAutomationRecipe(recipeUUID));
      }
      dispatch(fetchVendorAttributeDefinitions());
      if (surveyTypeId) {
        dispatch(fetchQuestionsForSurveyType(surveyTypeId));
        dispatch(fetchAutomationForSurveyType(surveyTypeId, false, true));
      }
    } catch (e) {
      dispatch(
        addDefaultUnknownErrorAlert(
          `Error retrieving data for automation: ${e}`
        )
      );
    }
  }, [recipeUUID, surveyTypeId]);

  // when we load the recipe details set up our state ready for editing. this includes any existing errors (or incomplete data) for the simple rules
  // that may have been saved when last edited
  useEffect(() => {
    setEditingRecipe(automation ? automation : ({} as IAutomationRecipe));

    // when we have the recipe details, make sure all the initial data entry errors are initialised.
    // We need to update the errors even if the automation id is not the same as previous
    // This is because this effect cleanup function wipes dataEntryErrors, which is also necessary when
    // switching the automation type.
    // The two factors combined make it preferable to always re-check any time when `automation` is updated.
    if (automation) {
      const e: IDataEntryErrors = { ...dataEntryErrors };
      e.automationId = automation ? automation?.id : 0;
      automation?.userConstructs?.simpleRules?.map((rule) => {
        if (
          rule.targetValue &&
          automationCustomAttribute &&
          automationCustomAttribute.type ==
            VendorAttributeDefinitionType.Numeric &&
          !numericRegexp.test(rule.targetValue)
        ) {
          _setDataEntryError(
            e,
            EditQuestionnairePages.EditAutomationRules,
            rule.uuid,
            DataEntryErrorAttribute.Action,
            getNumericErrorForAttributeType(automationCustomAttribute.name)
          );
        } else if (
          !rule.setTargetFromAnswer &&
          automation.targetAttribute === AutomationAttributeTypes.Labels &&
          !labels.some((label) => label.name === rule.targetValue)
        ) {
          _setDataEntryError(
            e,
            EditQuestionnairePages.EditAutomationRules,
            rule.uuid,
            DataEntryErrorAttribute.Action,
            getErrorForAttributeType()
          );
        } else if (!rule.setTargetFromAnswer && !rule.targetValue) {
          _setDataEntryError(
            e,
            EditQuestionnairePages.EditAutomationRules,
            rule.uuid,
            DataEntryErrorAttribute.Action,
            getErrorForAttributeType()
          );
        } else if (rule.setTargetFromAnswer && !rule?.setTargetFromAnswerId) {
          _setDataEntryError(
            e,
            EditQuestionnairePages.EditAutomationRules,
            rule.uuid,
            DataEntryErrorAttribute.ActionQuestion,
            getQuestionErrorForAttributeType()
          );
        } else {
          _deleteDataEntryError(
            e,
            EditQuestionnairePages.EditAutomationRules,
            rule.uuid,
            DataEntryErrorAttribute.Action
          );
        }
        initialiseNodeDataEntryErrors(rule.logic, e);
      });
      setDataEntryErrors(e);
    }
    return () => {
      setDataEntryErrors({} as IDataEntryErrors);
    };
  }, [automation]);

  // at startup (see useEffect([automation]) this recursive function generates existing data entry errors for a specific
  // rule node (the root node includes all rule test definitions)
  const initialiseNodeDataEntryErrors = (
    node: IAutomationTreeNode,
    errorStore: IDataEntryErrors
  ) => {
    node?.tests?.map((t) => {
      if (!t.questionId) {
        _setDataEntryError(
          errorStore,
          EditQuestionnairePages.EditAutomationRules,
          t.uuid,
          DataEntryErrorAttribute.Question,
          MISSING_QUESTION_ERROR
        );
      } else {
        _deleteDataEntryError(
          errorStore,
          EditQuestionnairePages.EditAutomationRules,
          t.uuid,
          DataEntryErrorAttribute.Question
        );
      }
      if (!t.value && !t.notAnswered) {
        _setDataEntryError(
          errorStore,
          EditQuestionnairePages.EditAutomationRules,
          t.uuid,
          DataEntryErrorAttribute.Answer,
          MISSING_ANSWER_ERROR
        );
      } else {
        _deleteDataEntryError(
          errorStore,
          EditQuestionnairePages.EditAutomationRules,
          t.uuid,
          DataEntryErrorAttribute.Answer
        );
      }
    });
    node?.branches?.map((b) => {
      initialiseNodeDataEntryErrors(b, errorStore);
    });
  };

  const getErrorForAttributeType = () => {
    if (editingRecipe.current && editingRecipe.current.targetAttribute !== "") {
      let error = `A ${editingRecipe.current.targetAttribute} is required`;
      if (
        editingRecipe.current?.targetAttribute ==
        AutomationAttributeTypes.Custom
      ) {
        error = `A value for ${editingRecipe.current.customAttributeName} is required`;
      }
      return error;
    }
    return "A target value is required";
  };

  const getQuestionErrorForAttributeType = () => {
    return MISSING_QUESTION_ERROR;
  };

  const getNumericErrorForAttributeType = (attributeName: string) => {
    return `Attribute '${attributeName}' must be a numeric value`;
  };

  const setInputText = (page: number, id: string, value: string) => {
    const deets = textEntries[page] || {};
    setTextEntries({
      ...textEntries,
      [page]: {
        ...deets,
        [id]: value,
      },
    });
  };

  const clearAllInputTextForPage = (page: number) => {
    delete textEntries[page];
  };

  // here we have a bunch of setters that set a specific attribute on the recipe instance being edited
  const setRecipeTitle = (name: string) => {
    setEditingRecipe({
      ...editingRecipe.current,
      name: name,
    });
    setUpdatesExist(true);
    setForceUpdate(new Date().valueOf());
  };
  const setRecipeDescription = (desc: string) => {
    setEditingRecipe({
      ...editingRecipe.current,
      description: desc,
    });
    setUpdatesExist(true);
    setForceUpdate(new Date().valueOf());
  };
  const setIsAdvancedRecipe = (advanced: boolean) => {
    setEditingRecipe({
      ...editingRecipe.current,
      isWeighted: advanced,
    });
    setUpdatesExist(true);
    setForceUpdate(new Date().valueOf());
  };
  const setRecipeAttribute = (attributeName: string) => {
    if (attributeName != editingRecipe.current.targetAttribute) {
      const simpleRules: IAutomationSimpleRule[] =
        editingRecipe.current.userConstructs?.simpleRules ?? [];

      setEditingRecipe({
        ...editingRecipe.current,
        targetAttribute: attributeName,
        userConstructs: {
          ...editingRecipe.current.userConstructs,
          simpleRules: simpleRules,
        },
      });

      for (let idx = 0; idx < simpleRules.length; idx++) {
        simpleRules[idx].targetValue = "";
        simpleRules[idx].setTargetFromAnswer = false;
        simpleRules[idx].setTargetFromAnswerId = undefined;
        deleteDataEntryError(
          EditQuestionnairePages.EditAutomationRules,
          simpleRules[idx].uuid,
          DataEntryErrorAttribute.ActionQuestion
        );
        setDataEntryError(
          EditQuestionnairePages.EditAutomationRules,
          simpleRules[idx].uuid,
          DataEntryErrorAttribute.Action,
          getErrorForAttributeType()
        );
      }

      setUpdatesExist(true);
      setForceUpdate(new Date().valueOf());
    }
  };
  // set details of the custom attribute selected, when 'custom attribute' is the target of the automation
  const setCustomAttribute = (id: number, name: string) => {
    setCustomAttributeID(id, name);
  };
  const setUsingCustomFormula = (using: boolean) => {
    setEditingRecipe({
      ...editingRecipe.current,
      userConstructs: {
        ...editingRecipe.current.userConstructs,
        usingCustomExpression: using,
      },
    });
    setUpdatesExist(true);
    setForceUpdate(new Date().valueOf());
  };
  const setUsingSimpleRules = (using: boolean) => {
    setEditingRecipe({
      ...editingRecipe.current,
      userConstructs: {
        ...editingRecipe.current.userConstructs,
        usingSimpleRules: using,
      },
    });
    setUpdatesExist(true);
    setForceUpdate(new Date().valueOf());
  };
  const setCustomFormula = (formula: string) => {
    setEditingRecipe({
      ...editingRecipe.current,
      userConstructs: {
        ...editingRecipe.current.userConstructs,
        masterExpression: formula,
      },
    });
    setUpdatesExist(true);
    setForceUpdate(new Date().valueOf());
  };
  const setCustomAttributeID = (id: number, name: string) => {
    const simpleRules: IAutomationSimpleRule[] =
      editingRecipe.current.userConstructs?.simpleRules ?? [];
    const changed = !(id === editingRecipe.current.customAttributeId);

    setEditingRecipe({
      ...editingRecipe.current,
      customAttributeId: id,
      customAttributeName: name,
      userConstructs: {
        ...editingRecipe.current.userConstructs,
        simpleRules: simpleRules,
      },
    });

    if (changed) {
      for (let idx = 0; idx < simpleRules.length; idx++) {
        simpleRules[idx].targetValue = "";
        simpleRules[idx].setTargetFromAnswer = false;
        simpleRules[idx].setTargetFromAnswerId = undefined;
        deleteDataEntryError(
          EditQuestionnairePages.EditAutomationRules,
          simpleRules[idx].uuid,
          DataEntryErrorAttribute.ActionQuestion
        );
        setDataEntryError(
          EditQuestionnairePages.EditAutomationRules,
          simpleRules[idx].uuid,
          DataEntryErrorAttribute.Action,
          getErrorForAttributeType()
        );
      }
    }

    setUpdatesExist(true);
    setForceUpdate(new Date().valueOf());
  };
  const setRecipeMappingDefinition = (mapping: IAutomationOutputMapping) => {
    setEditingRecipe({
      ...editingRecipe.current,
      mapping: {
        ...mapping,
      },
    });
    setUpdatesExist(true);
    setForceUpdate(new Date().valueOf());
  };
  const setRecipeWeightDefinitions = (
    defns: IAutomationWeights,
    clearEdits: boolean
  ) => {
    setEditingRecipe({
      ...editingRecipe.current,
      isWeighted: true,
      weights: {
        ...defns,
      },
    });
    if (clearEdits) {
      clearAllInputTextForPage(
        EditQuestionnairePages.EditAutomationAdvancedWeights
      );
    }
    setUpdatesExist(true);
    setForceUpdate(new Date().valueOf());
  };

  const findRuleByUUID = (
    simpleRules: IAutomationSimpleRule[],
    ruleUUID: string
  ): { index: number; rule: IAutomationSimpleRule | undefined } => {
    for (let idx = 0; idx < simpleRules.length; idx++) {
      if (simpleRules[idx].uuid == ruleUUID) {
        return { index: idx, rule: simpleRules[idx] };
      }
    }
    return { index: -1, rule: undefined };
  };

  const findTreeNodeByUUID = (
    root: IAutomationTreeNode,
    nodeUUID: string
  ): IAutomationTreeNode | undefined => {
    if (root.uuid == nodeUUID) {
      return root;
    } else {
      for (let branchIdx = 0; branchIdx < root.branches.length; branchIdx++) {
        const found = findTreeNodeByUUID(root.branches[branchIdx], nodeUUID);
        if (found) {
          return found;
        }
      }
    }
    return undefined;
  };

  const findTestByUUID = (
    root: IAutomationTreeNode,
    testUUID: string
  ): IAutomationTest | undefined => {
    for (let testIdx = 0; testIdx < root.tests.length; testIdx++) {
      if (root.tests[testIdx].uuid == testUUID) {
        return root.tests[testIdx];
      }
    }
    for (let branchIdx = 0; branchIdx < root?.branches?.length; branchIdx++) {
      const test = findTestByUUID(root.branches[branchIdx], testUUID);
      if (test) {
        return test;
      }
    }
    return undefined;
  };

  // adds a new simple rule to end of the exiting set. the new rule contains a single test instance and data entry errors
  // are created to reflect the initial case where the rule action, the test question and test answer have not been entered
  const addSimpleRuleAfter = (idx: number) => {
    const simpleRules: IAutomationSimpleRule[] =
      editingRecipe.current.userConstructs?.simpleRules ?? [];
    const rule: IAutomationSimpleRule = {
      uuid: createUUID(),
      targetValue: "",
      setTargetFromAnswer: false,
      setTargetFromAnswerId: "",
      logic: {
        uuid: createUUID(),
        operator: IAutomationTreeNodeOperator.AND,
        tests: [
          {
            uuid: createUUID(),
            questionId: "",
            operator: IAutomationTestOperator.EQ,
            value: "",
          },
        ],
      } as IAutomationTreeNode,
    };
    setDataEntryError(
      EditQuestionnairePages.EditAutomationRules,
      rule.logic.tests[0].uuid,
      DataEntryErrorAttribute.Question,
      MISSING_QUESTION_ERROR
    );
    setDataEntryError(
      EditQuestionnairePages.EditAutomationRules,
      rule.uuid,
      DataEntryErrorAttribute.Action,
      getErrorForAttributeType()
    );
    if (idx < 0) {
      simpleRules.unshift(rule);
    } else if (idx >= simpleRules.length) {
      simpleRules.push(rule);
    } else {
      simpleRules.splice(idx + 1, 0, rule);
    }

    setDataEntryError(
      EditQuestionnairePages.EditAutomationRules,
      rule.logic.tests[0].uuid,
      DataEntryErrorAttribute.Answer,
      MISSING_ANSWER_ERROR
    );
    setDataEntryError(
      EditQuestionnairePages.EditAutomationRules,
      rule.logic.tests[0].uuid,
      DataEntryErrorAttribute.Question,
      MISSING_QUESTION_ERROR
    );

    setEditingRecipe({
      ...editingRecipe.current,
      userConstructs: {
        ...editingRecipe.current.userConstructs,
        simpleRules: simpleRules,
      },
    });
    setUpdatesExist(true);
    setForceUpdate(new Date().valueOf());
  };

  // removes a rule instance by uuid
  const removeSimpleRule = (ruleUUID: string) => {
    const simpleRules: IAutomationSimpleRule[] =
      editingRecipe.current.userConstructs?.simpleRules ?? [];

    const { index, rule } = findRuleByUUID(simpleRules, ruleUUID);
    if (index >= 0) {
      simpleRules.splice(index, 1);
    }
    if (rule) {
      cleanupErrorsForBranch(rule.logic);
      deleteDataEntryError(
        EditQuestionnairePages.EditAutomationRules,
        rule.uuid,
        DataEntryErrorAttribute.Action
      );
    }

    setEditingRecipe({
      ...editingRecipe.current,
      userConstructs: {
        ...editingRecipe.current.userConstructs,
        simpleRules: simpleRules,
      },
    });
    setUpdatesExist(true);
    setForceUpdate(new Date().valueOf());
  };

  // sets the over-arching operator for a simple rule, being the operator at the root of the test tree node for the
  // rule itself. this operator is used to combine the result of tests and branches (subsets of other tests)
  const setSimpleRuleBranchOperator = (
    ruleUUID: string,
    branchUUID: string,
    op: IAutomationTreeNodeOperator
  ) => {
    const simpleRules: IAutomationSimpleRule[] =
      editingRecipe.current.userConstructs?.simpleRules ?? [];

    const { rule } = findRuleByUUID(simpleRules, ruleUUID);
    if (rule) {
      const node = findTreeNodeByUUID(rule.logic, branchUUID);
      if (node) {
        node.operator = op;
      }
    }

    setEditingRecipe({
      ...editingRecipe.current,
      userConstructs: {
        ...editingRecipe.current.userConstructs,
        simpleRules: simpleRules,
      },
    });
    setUpdatesExist(true);
    setForceUpdate(new Date().valueOf());
  };

  // sets the target value for a simple test, being the action (if you ike) that results from this particular rule
  const setSimpleRuleTargetValue = (
    ruleUUID: string,
    targetValue: string,
    isNumeric: boolean
  ) => {
    const simpleRules: IAutomationSimpleRule[] =
      editingRecipe.current.userConstructs?.simpleRules ?? [];

    // make sure we are trimmed
    targetValue = targetValue.trim();

    const { rule } = findRuleByUUID(simpleRules, ruleUUID);
    if (rule) {
      rule.targetValue = targetValue;
    }

    // if numeric attribute value required, check string and raise error..
    if (
      targetValue.length > 0 &&
      isNumeric &&
      !numericRegexp.test(targetValue)
    ) {
      // data entry is not numeric. we need to raise a data entry error
      setDataEntryError(
        EditQuestionnairePages.EditAutomationRules,
        ruleUUID,
        DataEntryErrorAttribute.Action,
        getNumericErrorForAttributeType(
          editingRecipe.current.customAttributeName ?? ""
        )
      );
    } else if (targetValue == "") {
      setDataEntryError(
        EditQuestionnairePages.EditAutomationRules,
        ruleUUID,
        DataEntryErrorAttribute.Action,
        getErrorForAttributeType()
      );
    } else {
      deleteDataEntryError(
        EditQuestionnairePages.EditAutomationRules,
        ruleUUID,
        DataEntryErrorAttribute.Action
      );
    }

    setEditingRecipe({
      ...editingRecipe.current,
      userConstructs: {
        ...editingRecipe.current.userConstructs,
        simpleRules: simpleRules,
      },
    });
    setUpdatesExist(true);
    setForceUpdate(new Date().valueOf());
  };

  const setSimpleRuleTargetUsesAnswer = (
    ruleUUID: string,
    useAnswer: boolean
  ) => {
    const simpleRules: IAutomationSimpleRule[] =
      editingRecipe.current.userConstructs?.simpleRules ?? [];
    const { rule } = findRuleByUUID(simpleRules, ruleUUID);
    if (rule) {
      rule.setTargetFromAnswer = useAnswer;
    }

    if (useAnswer) {
      deleteDataEntryError(
        EditQuestionnairePages.EditAutomationRules,
        ruleUUID,
        DataEntryErrorAttribute.Action
      );
      if (!rule?.setTargetFromAnswerId) {
        setDataEntryError(
          EditQuestionnairePages.EditAutomationRules,
          ruleUUID,
          DataEntryErrorAttribute.ActionQuestion,
          getQuestionErrorForAttributeType()
        );
      }
    } else {
      deleteDataEntryError(
        EditQuestionnairePages.EditAutomationRules,
        ruleUUID,
        DataEntryErrorAttribute.ActionQuestion
      );
      if (
        !!rule?.targetValue &&
        automationCustomAttribute?.type ==
          VendorAttributeDefinitionType.Numeric &&
        !numericRegexp.test(rule?.targetValue)
      ) {
        setDataEntryError(
          EditQuestionnairePages.EditAutomationRules,
          ruleUUID,
          DataEntryErrorAttribute.Action,
          getNumericErrorForAttributeType(automationCustomAttribute.name)
        );
      } else if (!rule?.targetValue) {
        setDataEntryError(
          EditQuestionnairePages.EditAutomationRules,
          ruleUUID,
          DataEntryErrorAttribute.Action,
          getErrorForAttributeType()
        );
      }
    }

    setEditingRecipe({
      ...editingRecipe.current,
      userConstructs: {
        ...editingRecipe.current.userConstructs,
        simpleRules: simpleRules,
      },
    });
    setUpdatesExist(true);
    setForceUpdate(new Date().valueOf());
  };

  const setSimpleRuleTargetAnswerId = (ruleUUID: string, answerId: string) => {
    const simpleRules: IAutomationSimpleRule[] =
      editingRecipe.current.userConstructs?.simpleRules ?? [];
    const { rule } = findRuleByUUID(simpleRules, ruleUUID);
    if (rule) {
      rule.setTargetFromAnswerId = answerId;
    }
    if (!answerId) {
      setDataEntryError(
        EditQuestionnairePages.EditAutomationRules,
        ruleUUID,
        DataEntryErrorAttribute.ActionQuestion,
        getQuestionErrorForAttributeType()
      );
    } else {
      deleteDataEntryError(
        EditQuestionnairePages.EditAutomationRules,
        ruleUUID,
        DataEntryErrorAttribute.ActionQuestion
      );
    }
    setEditingRecipe({
      ...editingRecipe.current,
      userConstructs: {
        ...editingRecipe.current.userConstructs,
        simpleRules: simpleRules,
      },
    });
    setUpdatesExist(true);
    setForceUpdate(new Date().valueOf());
  };

  // given a simple rule instance which has a set of tests defined (as a structured tree of tests), add a new test to a
  // specific node in the tree of tests (by uuid of the tree node). the new test has an empty question and answer, and
  // so data entry errors are also created for these
  const addTestToRuleLogic = (ruleUUID: string, nodeUUID: string) => {
    const simpleRules: IAutomationSimpleRule[] =
      editingRecipe.current.userConstructs?.simpleRules ?? [];

    const newTest: IAutomationTest = {
      uuid: createUUID(),
      questionId: "",
      operator: IAutomationTestOperator.EQ,
      value: "",
    };

    const { rule } = findRuleByUUID(simpleRules, ruleUUID);
    if (rule) {
      addTest(rule.logic, nodeUUID, newTest);
    }

    setDataEntryError(
      EditQuestionnairePages.EditAutomationRules,
      newTest.uuid,
      DataEntryErrorAttribute.Question,
      "A question is required"
    );
    setEditingRecipe({
      ...editingRecipe.current,
      userConstructs: {
        ...editingRecipe.current.userConstructs,
        simpleRules: simpleRules,
      },
    });
    setUpdatesExist(true);
    setForceUpdate(new Date().valueOf());
  };

  // given a simple rule instance which has a set of tests defined (as a structured tree of tests), add a new branch to a
  // specific node in the tree of tests (by uuid of the tree node). the new branch contains a single test that has itself
  // an empty question and answer, and so data entry errors are also created for these
  const addBranchToRuleLogic = (ruleUUID: string, nodeUUID: string) => {
    const simpleRules: IAutomationSimpleRule[] =
      editingRecipe.current.userConstructs?.simpleRules ?? [];

    const newBranch: IAutomationTreeNode = {
      uuid: createUUID(),
      operator: IAutomationTreeNodeOperator.AND,
      tests: [
        {
          uuid: createUUID(),
          questionId: "",
          operator: IAutomationTestOperator.EQ,
          value: "",
        },
      ] as IAutomationTest[],
      branches: [] as IAutomationTreeNode[],
    };

    const { rule } = findRuleByUUID(simpleRules, ruleUUID);
    if (rule) {
      addBranch(rule.logic, nodeUUID, newBranch);
    }

    setDataEntryError(
      EditQuestionnairePages.EditAutomationRules,
      newBranch.tests[0].uuid,
      DataEntryErrorAttribute.Question,
      MISSING_QUESTION_ERROR
    );
    setEditingRecipe({
      ...editingRecipe.current,
      userConstructs: {
        ...editingRecipe.current.userConstructs,
        simpleRules: simpleRules,
      },
    });
    setUpdatesExist(true);
    setForceUpdate(new Date().valueOf());
  };

  // given a specific test in the structured tree of tests for a simple rule (identified by uuid), set the selected questionId
  // for that test.
  const setQuestionForSimpleRuleTest = (
    ruleUUID: string,
    testUUID: string,
    questionId: string,
    isMultiSelect: boolean
  ) => {
    const simpleRules: IAutomationSimpleRule[] =
      editingRecipe.current.userConstructs?.simpleRules ?? [];

    const { rule } = findRuleByUUID(simpleRules, ruleUUID);
    if (rule) {
      const test = findTestByUUID(rule.logic, testUUID);
      if (test) {
        test.questionId = questionId;
        test.isMultiSelect = isMultiSelect;
        test.value = "";
        test.notAnswered = false;
        test.operator = IAutomationTestOperator.EQ;
        setDataEntryError(
          EditQuestionnairePages.EditAutomationRules,
          testUUID,
          DataEntryErrorAttribute.Answer,
          MISSING_ANSWER_ERROR
        );
      }
    }

    if (questionId == "") {
      setDataEntryError(
        EditQuestionnairePages.EditAutomationRules,
        testUUID,
        DataEntryErrorAttribute.Question,
        MISSING_QUESTION_ERROR
      );
    } else {
      deleteDataEntryError(
        EditQuestionnairePages.EditAutomationRules,
        testUUID,
        DataEntryErrorAttribute.Question
      );
    }

    setEditingRecipe({
      ...editingRecipe.current,
      userConstructs: {
        ...editingRecipe.current.userConstructs,
        simpleRules: simpleRules,
      },
    });
    setUpdatesExist(true);
    setForceUpdate(new Date().valueOf());
  };

  const setOperatorForSimpleRuleTest = (
    ruleUUID: string,
    testUUID: string,
    operator: IAutomationTestOperator
  ) => {
    const simpleRules: IAutomationSimpleRule[] =
      editingRecipe.current.userConstructs?.simpleRules ?? [];

    const { rule } = findRuleByUUID(simpleRules, ruleUUID);
    if (rule) {
      const test = findTestByUUID(rule.logic, testUUID);
      if (test) {
        test.operator = operator;
        setDataEntryError(
          EditQuestionnairePages.EditAutomationRules,
          testUUID,
          DataEntryErrorAttribute.Operator,
          MISSING_OPERATOR_ERROR
        );
        if (operatorIsForStrings(operator)) {
          test.notAnswered = false;
        }
      }
    }

    if (operator.length == 0) {
      setDataEntryError(
        EditQuestionnairePages.EditAutomationRules,
        testUUID,
        DataEntryErrorAttribute.Operator,
        MISSING_OPERATOR_ERROR
      );
    } else {
      deleteDataEntryError(
        EditQuestionnairePages.EditAutomationRules,
        testUUID,
        DataEntryErrorAttribute.Operator
      );
    }

    setEditingRecipe({
      ...editingRecipe.current,
      userConstructs: {
        ...editingRecipe.current.userConstructs,
        simpleRules: simpleRules,
      },
    });
    setUpdatesExist(true);
    setForceUpdate(new Date().valueOf());
  };

  // given a specific test in the structured tree of tests for a simple rule (identified by uuid), set the selected answer
  // value for that test.
  const setValueForSimpleRuleTest = (
    ruleUUID: string,
    testUUID: string,
    value: string,
    noAnswer: boolean
  ) => {
    const simpleRules: IAutomationSimpleRule[] =
      editingRecipe.current.userConstructs?.simpleRules ?? [];

    const { rule } = findRuleByUUID(simpleRules, ruleUUID);
    if (rule) {
      const test = findTestByUUID(rule.logic, testUUID);
      if (test) {
        test.value = value;
        test.notAnswered = noAnswer;
        if (value == "" && !noAnswer) {
          setDataEntryError(
            EditQuestionnairePages.EditAutomationRules,
            testUUID,
            DataEntryErrorAttribute.Answer,
            MISSING_ANSWER_ERROR
          );
        } else {
          deleteDataEntryError(
            EditQuestionnairePages.EditAutomationRules,
            testUUID,
            DataEntryErrorAttribute.Answer
          );
        }
      }
    }

    setEditingRecipe({
      ...editingRecipe.current,
      userConstructs: {
        ...editingRecipe.current.userConstructs,
        simpleRules: simpleRules,
      },
    });
    setUpdatesExist(true);
    setForceUpdate(new Date().valueOf());
  };

  // given a simple rule instance which has a set of tests defined (as a structured tree of tests), remove a specific branch (node)
  // from the tree of tests (by uuid of the tree node).
  const removeBranchForRule = (ruleUUID: string, branchUUID: string) => {
    const simpleRules: IAutomationSimpleRule[] =
      editingRecipe.current.userConstructs?.simpleRules ?? [];

    const { rule } = findRuleByUUID(simpleRules, ruleUUID);
    if (rule) {
      removeBranch(rule.logic, branchUUID, true);
    }

    setEditingRecipe({
      ...editingRecipe.current,
      userConstructs: {
        ...editingRecipe.current.userConstructs,
        simpleRules: simpleRules,
      },
    });
    setUpdatesExist(true);
    setForceUpdate(new Date().valueOf());
  };

  // given a simple rule instance which has a set of tests defined (as a structured tree of tests), add a test instance to a
  // specific node in the tree of tests (by uuid of the tree node). the root of the tree is passed in and processed recursively
  // to find the target node
  const addTest = (
    root: IAutomationTreeNode,
    toNodeUUID: string,
    test: IAutomationTest
  ): boolean => {
    const node = findTreeNodeByUUID(root, toNodeUUID);
    if (node) {
      if (!!!node.tests) {
        node.tests = [] as IAutomationTest[];
      }
      node.tests?.push(test);
      return true;
    }
    return false;
  };

  // given a simple rule instance which has a set of tests defined (as a structured tree of tests), add a branch instance to a
  // specific node in the tree of tests (by uuid of the tree node). the root of the tree is passed in and processed recursively
  // to find the target node
  const addBranch = (
    root: IAutomationTreeNode,
    toNodeUUID: string,
    branch: IAutomationTreeNode
  ): boolean => {
    const node = findTreeNodeByUUID(root, toNodeUUID);
    if (node) {
      if (!!!node.branches) {
        node.branches = [] as IAutomationTreeNode[];
      }
      node.branches.push(branch);
      return true;
    }
    return false;
  };

  // given a simple rule instance which has a set of tests defined (as a structured tree of tests), remove a specific test instance
  // (identified by uuid) from its node in the tree of tests. the root of the tree is passed in and processed recursively
  // to find the test in question
  const removeTest = (
    root: IAutomationTreeNode,
    testUUID: string
  ): IAutomationTest | undefined => {
    let found: IAutomationTest | undefined = undefined;
    for (let testIdx = 0; testIdx < root.tests?.length; testIdx++) {
      if (root.tests?.[testIdx].uuid == testUUID) {
        found = root.tests?.[testIdx];
        root.tests?.splice(testIdx, 1);
      }
    }
    if (found == undefined) {
      for (let branchIdx = 0; branchIdx < root.branches?.length; branchIdx++) {
        found = removeTest(root.branches?.[branchIdx], testUUID);
        if (
          found != undefined &&
          root.branches?.[branchIdx].tests?.length == 0 &&
          root.branches?.[branchIdx].branches?.length == 0
        ) {
          root.branches?.splice(branchIdx, 1);
        }
        if (found != undefined) {
          break;
        }
      }
    }

    return found;
  };

  const cleanupErrorsForBranch = (node: IAutomationTreeNode) => {
    node?.tests?.map((t) => {
      cleanupTestErrors(t.uuid);
    });
    node?.branches?.map((b) => {
      cleanupErrorsForBranch(b);
    });
  };

  const cleanupTestErrors = (testUUID: string) => {
    deleteDataEntryError(
      EditQuestionnairePages.EditAutomationRules,
      testUUID,
      DataEntryErrorAttribute.Answer
    );
    deleteDataEntryError(
      EditQuestionnairePages.EditAutomationRules,
      testUUID,
      DataEntryErrorAttribute.Question
    );
  };

  // given a simple rule instance which has a set of tests defined (as a structured tree of tests), remove a specific branch in the tree
  // (identified by uuid). the root of the tree is passed in and processed recursively to find the branch in question
  const removeBranch = (
    root: IAutomationTreeNode,
    branchUUID: string,
    beingDeleted: boolean
  ): IAutomationTreeNode | undefined => {
    let found: IAutomationTreeNode | undefined = undefined;
    for (let branchIdx = 0; branchIdx < root.branches?.length; branchIdx++) {
      if (root.branches?.[branchIdx].uuid == branchUUID) {
        found = root.branches?.[branchIdx];
        root.branches?.splice(branchIdx, 1);
        if (beingDeleted) {
          for (let testIdx = 0; testIdx < found?.tests?.length; testIdx++) {
            cleanupTestErrors(found?.tests?.[testIdx]?.uuid);
          }
        }
      } else {
        found = removeBranch(
          root.branches?.[branchIdx],
          branchUUID,
          beingDeleted
        );
        if (
          found != undefined &&
          root.branches?.length == 0 &&
          root.tests?.length == 0
        ) {
          root.branches?.splice(branchIdx, 1);
        }
      }
      if (found != undefined) {
        break;
      }
    }
    return found;
  };

  // uses the removeTest function above to remove a specific test instance (by uuid) from a specific simple rule (by uuid)
  const removeTestForRule = (ruleUUID: string, testUUID: string) => {
    const simpleRules: IAutomationSimpleRule[] =
      editingRecipe.current.userConstructs?.simpleRules ?? [];

    const { rule } = findRuleByUUID(simpleRules, ruleUUID);
    if (rule) {
      if (removeTest(rule.logic, testUUID)) {
        cleanupTestErrors(testUUID);
      }
    }

    setEditingRecipe({
      ...editingRecipe.current,
      userConstructs: {
        ...editingRecipe.current.userConstructs,
        simpleRules: simpleRules,
      },
    });
    setUpdatesExist(true);
    setForceUpdate(new Date().valueOf());
  };

  // given a specific test (identified by uuid), move it from its current branch in the test tree to another branch
  const moveTestFromNodeToNode = (
    testUUID: string,
    fromRuleUUID: string,
    toRuleUUID: string,
    toNodeUUID: string
  ): void => {
    const simpleRules: IAutomationSimpleRule[] =
      editingRecipe.current.userConstructs?.simpleRules ?? [];

    // find the test to move
    let existingTest: IAutomationTest | undefined = undefined;
    const { index, rule } = findRuleByUUID(simpleRules, fromRuleUUID);
    if (rule) {
      existingTest = removeTest(rule.logic, testUUID);
      if (
        (rule.logic.branches?.length ?? 0) == 0 &&
        (rule.logic.tests?.length ?? 0) == 0
      ) {
        simpleRules.splice(index, 1);
      }
    }

    // add it to our new node
    if (existingTest != undefined) {
      const { rule } = findRuleByUUID(simpleRules, toRuleUUID);
      if (rule) {
        addTest(rule.logic, toNodeUUID, existingTest);
      }
    }

    setEditingRecipe({
      ...editingRecipe.current,
      userConstructs: {
        ...editingRecipe.current.userConstructs,
        simpleRules: simpleRules,
      },
    });
    setUpdatesExist(true);
    setForceUpdate(new Date().valueOf());
  };

  // given a specific branch (identified by uuid) in the testing tree, move that branch from its current location (node/branch)
  // in the test tree to another location (node/branch)
  const moveBranchFromNodeToNode = (
    branchUUID: string,
    fromRuleUUID: string,
    toRuleUUID: string,
    toNodeUUID: string
  ): void => {
    const simpleRules: IAutomationSimpleRule[] =
      editingRecipe.current.userConstructs?.simpleRules ?? [];

    // find the branch to move
    let existingBranch: IAutomationTreeNode | undefined = undefined;
    const { index, rule } = findRuleByUUID(simpleRules, fromRuleUUID);
    if (rule) {
      existingBranch = removeBranch(rule.logic, branchUUID, false);
      if (
        (rule.logic.branches?.length ?? 0 == 0) &&
        (rule.logic.tests?.length ?? 0 == 0)
      ) {
        simpleRules.splice(index, 1);
      }
    }

    // add it to our new node
    if (existingBranch != undefined) {
      const { rule } = findRuleByUUID(simpleRules, toRuleUUID);
      if (rule) {
        addBranch(rule.logic, toNodeUUID, existingBranch);
      }
    }

    setEditingRecipe({
      ...editingRecipe.current,
      userConstructs: {
        ...editingRecipe.current.userConstructs,
        simpleRules: simpleRules,
      },
    });
    setUpdatesExist(true);
    setForceUpdate(new Date().valueOf());
  };

  const moveSimpleRuleFromIdxToIdx = (fromIdx: number, toIdx: number) => {
    const simpleRules: IAutomationSimpleRule[] =
      editingRecipe.current.userConstructs?.simpleRules ?? [];

    if (
      fromIdx < 0 ||
      fromIdx >= simpleRules.length ||
      toIdx < 0 ||
      toIdx >= simpleRules.length
    ) {
      console.error(`Error: indexes outside bounds of rule list`);
      return;
    }

    const rule = simpleRules[fromIdx];
    simpleRules.splice(fromIdx, 1);
    simpleRules.splice(toIdx, 0, rule);

    setEditingRecipe({
      ...editingRecipe.current,
      userConstructs: {
        ...editingRecipe.current.userConstructs,
        simpleRules: simpleRules,
      },
    });
    setUpdatesExist(true);
    setForceUpdate(new Date().valueOf());
  };

  // store whether we have a data entry error for a specific field on a specific page, and what the error message is
  const _setDataEntryError = (
    errorStore: IDataEntryErrors,
    pageNum: number,
    id: string,
    attribute: DataEntryErrorAttribute | string,
    msg: string
  ): void => {
    const page = errorStore[pageNum] || {};
    const key =
      attribute != DataEntryErrorAttribute.None ? id + "_" + attribute : id;
    if (msg != "") {
      page[key] = msg;
    } else {
      delete page[key];
    }
    errorStore[pageNum] = page;
  };

  // store whether we have a data entry error for a specific field on a specific page, and what the error message is
  const _deleteDataEntryError = (
    errorStore: IDataEntryErrors,
    pageNum: number,
    id: string,
    attribute: DataEntryErrorAttribute | string
  ): void => {
    const key =
      attribute != DataEntryErrorAttribute.None ? id + "_" + attribute : id;
    const page = errorStore[pageNum] || {};
    delete page[key];
    errorStore[pageNum] = page;
  };

  // store whether we have a data entry error for a specific field on a specific page, and what the error message is
  const setDataEntryError = (
    pageNum: number,
    id: string,
    attribute: DataEntryErrorAttribute | string,
    msg: string
  ) => {
    const e: IDataEntryErrors = { ...dataEntryErrors };
    _setDataEntryError(e, pageNum, id, attribute, msg);
    setDataEntryErrors(e);
  };

  // store whether we have a data entry error for a specific field on a specific page, and what the error message is
  const deleteDataEntryError = (
    pageNum: number,
    id: string,
    attribute: DataEntryErrorAttribute | string
  ) => {
    const e: IDataEntryErrors = { ...dataEntryErrors };
    _deleteDataEntryError(e, pageNum, id, attribute);
    setDataEntryErrors(e);
  };

  // store whether we have a data entry error for a specific field on a specific page, and what the error message is
  const retrieveDataEntryError = (
    pageNum: number,
    id: string,
    attribute: DataEntryErrorAttribute | string
  ): string | undefined => {
    const e = {
      ...dataEntryErrors,
    };
    const page = e[pageNum] || {};
    const key =
      attribute != DataEntryErrorAttribute.None ? id + "_" + attribute : id;
    return page[key];
  };

  // toggle the flag indication whether we are using the advanced (weighted) recipe type
  const toggleUsingAdvancedRecipe = (isAdvanced: boolean) => {
    // if we are switching to/from advanced, then clear out data entry errors for page 2 (the overlapping page)
    if (isAdvanced != editingRecipe.current.isWeighted) {
      const e = {
        ...dataEntryErrors,
      };
      delete e[EditQuestionnairePages.EditAutomationRules];
      setDataEntryErrors(e);
    }
    if (isAdvanced && editingRecipe && !editingRecipe.current.weights) {
      // set the initial values of weight definitions for this automation based on the questionnaire definitions
      setInitialAutomationWeights();
    } else {
      setIsAdvancedRecipe(isAdvanced);
    }
  };

  // wrapper around the setRecipeTitle function checks the mandatory condition and raises a data entry error if required
  const setTitleString = (title: string) => {
    setRecipeTitle(title);
    if (title == "") {
      setDataEntryError(
        EditQuestionnairePages.EditAutomationName,
        "title",
        DataEntryErrorAttribute.None,
        "A title is required"
      );
    } else {
      deleteDataEntryError(
        EditQuestionnairePages.EditAutomationName,
        "title",
        DataEntryErrorAttribute.None
      );
    }
  };

  // for an automation recipe that has newly become 'weighted' create a set of initial weight definitions based on the questions
  // defined for the questionnaire
  const setInitialAutomationWeights = () => {
    let weights: IAutomationWeights = {};
    if (editingRecipe.current?.weights) {
      weights = {
        ...editingRecipe.current.weights,
      };
    } else if (questions) {
      questions.map((q) => {
        const w: IAutomationWeight = {
          questionText: q.questionText,
          answerWeights: {} as IAnswerWeights,
          function: "sum",
          questionIDAlias: q.id,
          questionType: q.questionType,
          weightNone: 0,
        };
        q.answers?.map((a) => {
          if (w.answerWeights) {
            w.answerWeights[a.answerId] = 0;
          }
        });
        weights[q.id] = w;
      });
    }
    setRecipeWeightDefinitions(weights, true);
  };

  const backTo = (isCancel: boolean) => {
    if (isCancel) {
      // do more cancelly type shenanigens?
    }
    if (location.state && location.state.backContext?.backToContext) {
      history.push(location.state.backContext?.backTo ?? "", {
        ...location.state.backContext?.backToContext,
        noRemoveWhispers: true,
        selectedTab: "automation",
        isEditing: isEditing,
        surveyTypeId: surveyTypeId,
      });
    } else {
      history.push("/settings/relationship_questionnaire", {
        noRemoveWhispers: true,
        selectedTab: "automation",
        isEditing: isEditing,
        surveyTypeId: surveyTypeId,
      });
    }
  };

  // save the current automation being edited
  const saveAutomation = async (
    explicitSave: boolean,
    quietly: boolean,
    close: boolean
  ): Promise<boolean> => {
    if (explicitSave) {
      setAutomationSaving(true);
    }
    try {
      await dispatch(saveAutomationRecipe(editingRecipe.current));
      if (!quietly) {
        await dispatch(
          fetchAutomationForSurveyType(surveyTypeId, true, quietly)
        );
      }
      setUpdatesExist(false);
      if (close) {
        backTo(false);
      }
      if (explicitSave) {
        setAutomationSaving(false);
      }
      if (!quietly) {
        dispatch(addDefaultSuccessAlert("Automation rule saved"));
      }
      return true;
    } catch (e) {
      if (explicitSave) {
        setAutomationSaving(false);
      }
      if (!quietly) {
        dispatch(addDefaultUnknownErrorAlert(`Error saving recipe: ${e}`));
      }
      return false;
    }
  };

  const debouncedAutosave = useCallback(
    _debounce(() => {
      if (updatesExist.current && !autosaveLoading && !automationCanceling) {
        dispatch(autosaveAutomationRecipe(editingRecipe.current)).then(() => {
          dispatch(
            setAutomationRecipe(
              editingRecipe.current.uuid,
              false,
              null,
              editingRecipe.current
            )
          );
          setUpdatesExist(false);
        });
      }
    }, autosaveDebounceDelayMs),
    []
  );

  // run our autosave every 30 seconds if there's been a change
  useEffect(() => {
    const interval = setInterval(() => {
      if (isEditing) {
        debouncedAutosave();
      }
    }, autosaveInterval);
    return () => clearInterval(interval);
  }, [recipeUUID, isEditing, debouncedAutosave]);

  const openTestRecipe = async (surveyTypeId: number, recipeUUID: string) => {
    const url = `/questionnairepreview?type_id=${surveyTypeId}&automationUUID=${recipeUUID}&draft=${isEditing}`;
    window.open(url, "_blank");
  };

  const cancelAutomation = async (recipeUUID: string) => {
    setAutomationCanceling(true);
    try {
      await dispatch(cancelAutomationRecipe(recipeUUID, surveyTypeId));
      backTo(true);
    } catch (e) {
      dispatch(
        addDefaultUnknownErrorAlert(`Error cancelling automation: ${e}`)
      );
    }
    setAutomationCanceling(false);
  };

  // determine if the next button can be enabled or not
  const canProgress = () => {
    let can = false;
    if (
      dataEntryErrors[currentPage] &&
      Object.keys(dataEntryErrors[currentPage]).length > 0
    ) {
      return false;
    }
    switch (currentPage) {
      case 1:
        can =
          editingRecipe.current.name != "" &&
          editingRecipe.current.targetAttribute != "" &&
          (editingRecipe.current.targetAttribute !=
            AutomationAttributeTypes.Custom ||
            (!!editingRecipe.current.customAttributeId &&
              editingRecipe.current.customAttributeId > 0));
        break;
      case 2:
        can = true;
        break;
      case 3:
        can = true;
        break;
      case 4:
        // is always advanced rules
        can =
          !editingRecipe.current.userConstructs?.usingCustomExpression ||
          (editingRecipe.current.userConstructs?.masterExpression != "" &&
            !syntaxError);
        break;
    }
    return can;
  };

  // getSteps
  // Builds the definitions of the steps required for each of the supported integration types. These definitions
  // are passed to the Steps component during rendering.
  const getSteps = () => {
    const canprogress = canProgress();
    const steps = [
      {
        id: "type",
        text: "Define rule",
        onClick: () => {
          setCurrentStep(1);
          setCurrentPage(1);
        },
        disabled: false,
      },
    ];

    if (editingRecipe.current.isWeighted) {
      steps.push({
        id: "weights",
        text: isEditing ? "Configure weights" : "Configured weights",
        onClick: () => {
          setCurrentStep(2);
          setCurrentPage(3);
        },
        disabled: currentStep < 2 && !canprogress,
      });
      steps.push({
        id: "rules",
        text: isEditing ? "Configure calculation" : "Configured calculation",
        onClick: () => {
          setCurrentStep(3);
          setCurrentPage(4);
        },
        disabled: currentStep < 4 && !canprogress,
      });
    } else {
      steps.push({
        id: "rules",
        text: "Configure logic",
        onClick: () => {
          setCurrentStep(2);
          setCurrentPage(2);
        },
        disabled: currentStep < 2 && !canprogress,
      });
    }
    return steps;
  };

  const title = automation?.isNew
    ? "Add new automation rule"
    : isEditing
      ? "Edit Automation Rule"
      : "Automation Rule Summary";

  const renderActionBar = () => {
    let onBack: () => void = noop;
    let nextButton = null;
    let previewButton = null;

    const cancelButton = !isEditing ? (
      <Button
        className="next"
        onClick={() => {
          backTo(true);
        }}
      >
        Go Back
      </Button>
    ) : (
      <Button
        tertiary
        className="next"
        disabled={automationSaving || autosaveLoading || automationCanceling}
        loading={automationCanceling}
        onClick={() => {
          cancelAutomation(recipeUUID);
        }}
      >
        Cancel
      </Button>
    );

    switch (currentPage) {
      case 1: {
        nextButton = (
          <Button
            filledPrimary
            className="next"
            disabled={!canProgress()}
            onClick={() => {
              editingRecipe.current.isWeighted
                ? setCurrentPage(3)
                : setCurrentPage(2);
              setCurrentStep(currentStep + 1);
            }}
            loading={false}
          >
            Next <Icon name="arrow" direction={90} />
          </Button>
        );
        break;
      }
      case 2: {
        // Enter information
        onBack = () => {
          setCurrentStep(currentStep - 1);
          setCurrentPage(1);
        };
        previewButton = (
          <Button
            className="next"
            onClick={async () => {
              dispatch(
                setAutomationRecipe(recipeUUID, false, null, automation)
              );
              openTestRecipe(surveyTypeId, recipeUUID);
            }}
            disabled={!canProgress()}
          >
            <div className="cr-icon-play" />
            <span> {"Test"}</span>
          </Button>
        );
        nextButton = (
          <Button
            filledPrimary
            className="next submit"
            onClick={
              isEditing
                ? () => saveAutomation(true, false, true)
                : () => backTo(true)
            }
            disabled={!canProgress()}
            loading={automationSaving}
          >
            {isEditing && <span>Save automation</span>}
            {!isEditing && <span>{"Close"}</span>}
          </Button>
        );
        break;
      }
      case 3:
        onBack = () => {
          setCurrentStep(currentStep - 1);
          setCurrentPage(1);
        };
        nextButton = (
          <Button
            filledPrimary
            className="next"
            onClick={() => {
              setCurrentStep(currentStep + 1);
              setCurrentPage(4);
            }}
            disabled={!canProgress()}
            loading={automationSaving}
          >
            <span>{"Next"}</span>
            <Icon name="arrow" direction={90} />
          </Button>
        );
        break;
      case 4: {
        // Enter information
        onBack = () => {
          setCurrentStep(currentStep - 1);
          setCurrentPage(3);
        };
        previewButton = (
          <Button
            className="next"
            onClick={() => openTestRecipe(surveyTypeId, recipeUUID)}
            disabled={!canProgress()}
          >
            <div className="cr-icon-play" />
            <span> {"Test"}</span>
          </Button>
        );
        nextButton = (
          <Button
            filledPrimary
            className="next submit"
            onClick={() => {
              if (isEditing) {
                saveAutomation(true, false, true);
              } else {
                backTo(true);
              }
            }}
            disabled={!canProgress()}
            loading={automationSaving}
          >
            <>
              {isEditing && <span>Save automation</span>}
              {!isEditing && <span>{"Close"}</span>}
            </>
          </Button>
        );
        // isLastPage = true;
        break;
      }
    }
    return (
      <ActionBar active newStyles>
        <div className={"left"}>
          {!!onBack && currentStep != 1 && (
            <Button className="prev" onClick={() => onBack()}>
              <Icon name="arrow" direction={270} /> {"Previous"}
            </Button>
          )}
        </div>
        <div className={"middle"}>
          <Autosave
            saving={autosaveLoading ?? false}
            error={autosaveError}
            recipeUUID={recipeUUID}
            showIcon={true}
          />
        </div>
        <div className={"right"}>
          {cancelButton}
          {previewButton}
          {nextButton}
        </div>
      </ActionBar>
    );
  };

  if (!orgHasAutomationEnabled) {
    return (
      <EmptyCard
        text={"Relationship questionnaire automation is not available"}
      />
    );
  }
  if (loading || automationSaving) {
    return <LoadingBanner />;
  }

  return (
    <div className={"edit-questionnaire-automation"}>
      <PageHeader
        history={history}
        title={title}
        breadcrumbs={[
          { text: "Settings", to: "/settings" },
          { text: "Vendor Management", to: "/settings/vendors" },
          {
            text: "Relationship Questionnaire and Automations",
            to: "/settings/relationship_questionnaire",
          },
          { text: "Automation Rule" },
        ]}
        backAction={() => {
          backTo(true);
        }}
        backText={
          location.state && location.state.backContext
            ? location.state.backContext.backToText
            : "Back"
        }
        infoSection={
          <p>
            Set up automation rules using simple or complex conditions to
            categorize vendors by tiers, labels, portfolios, and set custom
            attributes based on questionnaire responses. Or, use an advanced
            method to tier vendors using a weighted scoring system.
          </p>
        }
        infoSectionButtons={
          <Button
            tertiary
            onClick={() =>
              window.open(
                "https://help.upguard.com/en/articles/8073950-how-to-use-automation-to-apply-tiers-labels-portfolios-and-custom-attributes-to-your-vendors"
              )
            }
          >
            Learn more about automation <div className="cr-icon-arrow-right" />
          </Button>
        }
        infoSectionPageKey="infoSection_editAutomation"
      />
      {isEditing && <Steps steps={getSteps()} currentStep={currentStep} />}
      <div className="section">
        {currentStep === 1 && (
          <NameAutomationStep
            loading={loading}
            isEditing={isEditing}
            title={editingRecipe.current?.name || ""}
            description={editingRecipe.current?.description || ""}
            isAdvancedRecipe={editingRecipe.current?.isWeighted || false}
            customAttributeName={
              editingRecipe.current?.customAttributeName || ""
            }
            attributeType={
              editingRecipe.current?.targetAttribute ||
              AutomationAttributeTypes.Tier
            }
            vendorAttributeDefinitions={vendorAttributeDefinitions}
            setTitleFunc={setTitleString}
            setAdvancedRecipe={toggleUsingAdvancedRecipe}
            setUsingSimpleRulesFunc={setUsingSimpleRules}
            setDescriptionFunc={setRecipeDescription}
            setAttributeTypeFunc={setRecipeAttribute}
            setCustomAttributeFunc={setCustomAttribute}
            setDataEntryErrorFunc={setDataEntryError}
            deleteDataEntryErrorFunc={deleteDataEntryError}
            retrieveDataEntryErrorFunc={retrieveDataEntryError}
            userHasWriteAutomationEnabled={userHasWriteAutomationEnabled}
          />
        )}
        {!editingRecipe.current.isWeighted && currentStep === 2 && (
          <DefineRulesStep
            loading={loading}
            isEditing={isEditing}
            surveyTypeId={surveyTypeId}
            questions={questions}
            setDataEntryErrorFunc={setDataEntryError}
            deleteDataEntryErrorFunc={deleteDataEntryError}
            retrieveDataEntryErrorFunc={retrieveDataEntryError}
            userHasWriteAutomationEnabled={userHasWriteAutomationEnabled}
            automation={editingRecipe.current}
            addNewRuleAfter={addSimpleRuleAfter}
            removeRule={removeSimpleRule}
            setOperatorForBranch={setSimpleRuleBranchOperator}
            setTargetValueForRule={setSimpleRuleTargetValue}
            setTargetValueUsesAnswer={setSimpleRuleTargetUsesAnswer} // (ruleUUID: string, useAnswer: boolean) => void;
            setTargetValueAnswerId={setSimpleRuleTargetAnswerId} // (ruleUUID: string, answerId: string) => void;
            setQuestionForTest={setQuestionForSimpleRuleTest}
            setOperatorForTest={setOperatorForSimpleRuleTest}
            setValueForTest={setValueForSimpleRuleTest}
            addTestToRuleNode={addTestToRuleLogic}
            addBranchToRule={addBranchToRuleLogic}
            removeBranchForRule={removeBranchForRule}
            removeTestForRule={removeTestForRule}
            moveTestFromNodeToNode={moveTestFromNodeToNode}
            moveBranchFromNodeToNode={moveBranchFromNodeToNode}
            moveRule={moveSimpleRuleFromIdxToIdx}
            automationCustomAttribute={automationCustomAttribute}
          />
        )}
        {editingRecipe.current.isWeighted && currentStep === 2 && (
          <DefineAdvancedWeightsStep
            loading={loading}
            isEditing={isEditing}
            recipeUUID={recipeUUID}
            surveyTypeId={surveyTypeId}
            questions={questions}
            weights={editingRecipe.current?.weights || undefined}
            setWeightsFunc={setRecipeWeightDefinitions}
            setDataEntryErrorFunc={setDataEntryError}
            deleteDataEntryErrorFunc={deleteDataEntryError}
            retrieveDataEntryErrorFunc={retrieveDataEntryError}
            userHasWriteAutomationEnabled={userHasWriteAutomationEnabled}
            inputText={
              textEntries[
                EditQuestionnairePages.EditAutomationAdvancedWeights
              ] || {}
            }
            setInputTextFunc={setInputText}
            saveAutomationFunc={
              isEditing
                ? async () => {
                    dispatch(
                      autosaveAutomationRecipe(editingRecipe.current)
                    ).then(() => {
                      setUpdatesExist(false);
                    });
                  }
                : async () => {}
            }
          />
        )}
        {editingRecipe.current.isWeighted && currentStep === 3 && (
          <DefineAdvancedRulesStep
            dispatch={dispatch}
            loading={loading}
            isEditing={isEditing}
            surveyTypeId={surveyTypeId}
            automation={editingRecipe.current}
            recipeUUID={editingRecipe.current?.uuid || ""}
            weights={editingRecipe.current?.weights || undefined}
            mapping={editingRecipe.current?.mapping || undefined}
            questions={questions}
            usingCustomFormula={
              editingRecipe.current.userConstructs?.usingCustomExpression
            }
            customFormula={
              editingRecipe.current.userConstructs?.masterExpression
            }
            setMappingFunc={setRecipeMappingDefinition}
            setCustomFormulaFunc={setCustomFormula}
            setUsingCustomFormulaFunc={setUsingCustomFormula}
            setDataEntryErrorFunc={setDataEntryError}
            deleteDataEntryErrorFunc={deleteDataEntryError}
            retrieveDataEntryErrorFunc={retrieveDataEntryError}
            userHasWriteAutomationEnabled={userHasWriteAutomationEnabled}
            inputText={
              textEntries[EditQuestionnairePages.EditAutomationAdvancedRules] ||
              {}
            }
            setInputTextFunc={setInputText}
          />
        )}
      </div>
      <div className="actions">{renderActionBar()}</div>
    </div>
  );
};

export default appConnect<
  IEditQuestionnaireAutomationConnectedProps,
  never,
  IEditQuestionnaireAutomationOwnProps
>((state, props) => {
  const surveyTypeId = parseInt(props.match.params.surveyTypeId);
  const recipeUUID = props.match.params.recipeUUID;
  const isEditing = props.match.params.isEditing;

  const automationState = recipeUUID
    ? _get(state.cyberRisk, `automationRecipes[${recipeUUID}]`, undefined)
    : undefined;
  const automation = automationState?.data ?? ({} as IAutomationRecipe);
  const vendorAttributeDefinitions = state.cyberRisk.vendorAttributeDefinitions;
  const automationQuestions = _get(
    state.cyberRisk,
    `automationQuestions[${surveyTypeId}]`,
    undefined
  );
  const questions = automationQuestions?.data ?? undefined;
  const orgPerms = state.common.userData.orgPermissions;
  const userPerms = state.common.userData.userPermissions;

  const orgHasAutomationEnabled = orgPerms.includes(
    OrgAccessRelationshipQuestionnaireAutomation
  );
  const userHasWriteAutomationEnabled = userPerms.includes(
    WriteQuestionnaireAutomation
  );

  let loading = automationState?.loading || false;
  if (automationQuestions) {
    loading = loading || automationQuestions.loading;
  }

  const autosaveLoading = automationState?.autosave?.loading || false;
  const autosaveError = automationState?.autosave?.error || undefined;

  const syntaxError =
    automationState && automationState.autosave
      ? automationState.autosave.syntax
      : undefined;

  const systemLabels = state.cyberRisk.availableLabels
    ? state.cyberRisk.availableLabels.filter(
        (l) => l.classification === LabelClassification.SystemLabel
      )
    : [];

  const classificationCustomLabels = state.cyberRisk.availableLabels
    ? state.cyberRisk.availableLabels.filter(
        (l) => l.classification === LabelClassification.VendorLabel
      )
    : [];

  const allLabels = sortLabels([
    ...systemLabels,
    ...classificationCustomLabels,
  ]);

  const ret: IEditQuestionnaireAutomationConnectedProps = {
    surveyTypeId,
    recipeUUID,
    isEditing: isEditing.toLowerCase().trim() === "true",
    loading: loading,
    automation: automation,
    questions: questions,
    vendorAttributeDefinitions: vendorAttributeDefinitions,
    orgHasAutomationEnabled,
    userHasWriteAutomationEnabled,
    autosaveLoading,
    autosaveError,
    syntaxError,
    labels: allLabels,
  };
  return ret;
})(EditQuestionnaireAutomation);
