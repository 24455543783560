import PropTypes from "prop-types";
import { memo } from "react";
import "../style/components/EmptyCard.scss";

const EmptyCard = ({ className, text, buttons }) => (
  <div className={`empty-card ${className}`}>
    <div className="empty-card-text">{text}</div>
    {buttons && <div className="empty-card-buttons">{buttons}</div>}
  </div>
);

EmptyCard.propTypes = {
  className: PropTypes.string,
  text: PropTypes.any.isRequired,
  buttons: PropTypes.element,
};

EmptyCard.defaultProps = {
  className: "",
  buttons: null,
};

export default memo(EmptyCard);
