import { Component } from "react";
import { DefaultThunkDispatch } from "../../../_common/types/redux";
import { History } from "history";
import Button from "../../../_common/components/core/Button";
import InfoBanner, { BannerType, SubItemType } from "../InfoBanner";
import SelectableSidebarTriggersList from "./SelectableSidebarTriggersList";
import { INotificationConfigCategory } from "../../../_common/types/notifications";
import { IBasicAuthParams, IWebHook } from "../../../_common/types/webhooks";
import { executeWebhookTest } from "../../reducers/integrations.actions";
import {
  addDefaultSuccessAlert,
  addMessageAlert,
} from "../../../_common/reducers/messageAlerts.actions";
import {
  liquidSyntaxGuideURL,
  supportArticleURL,
} from "../../views/CreateIntegration";
import { IStringMap } from "../../../_common/types/integration";
import { IOrgAlertDefinition } from "../../reducers/org.actions";

interface IConfigureWebhookPayloadsStepProps {
  history: History;
  dispatch: DefaultThunkDispatch;
  loading: boolean;
  notifications: INotificationConfigCategory<IOrgAlertDefinition>[];
  selectedUUIDs: {
    [uuid: string]: boolean;
  };
  webhook?: IWebHook;
  selectedTestUUID: string;
  selectedTestJSON: any;
  selectedPayloadTemplate: string;
  setSelectedTestNotification: (notification: IOrgAlertDefinition) => void;
  validateDefineWebhookTarget: () => {
    valid: boolean;
    msg: string;
  };
  webhookTestResponse?: {
    response: {
      status: string;
      status_code: number | string;
      proto: string;
    };
  };
  prepareIntegrationParams: () => {
    id?: number;
    webhookID?: number;
    integrationType: number;
    uuids: string[];
    httpHeaders: IStringMap;
    urlParams: IStringMap;
    basicAuth: IBasicAuthParams;
    enabled: boolean;
    selectedChannel?: string;
  };
  resetPayloadTemplateToDefault: () => void;
  onChangePayloadTemplate: (template: string) => void;
}

interface IConfigureWebhookPayloadsStepState {
  testWebhookLoading: boolean;
}

class ConfigureWebhookPayloadsStep extends Component<
  IConfigureWebhookPayloadsStepProps,
  IConfigureWebhookPayloadsStepState
> {
  static defaultProps = {
    webhookTestResponse: null,
  };

  constructor(props: IConfigureWebhookPayloadsStepProps) {
    super(props);
    this.state = {
      testWebhookLoading: false,
    } as IConfigureWebhookPayloadsStepState;
  }

  testWebhook = () => {
    this.setState({ testWebhookLoading: true });

    const { webhookID, httpHeaders, urlParams, basicAuth } =
      this.props.prepareIntegrationParams();
    const testUUID = this.props.selectedTestUUID;
    this.props
      .dispatch(
        executeWebhookTest(
          webhookID ?? 0,
          [testUUID],
          this.props.webhook?.webhookURL ?? "",
          httpHeaders,
          urlParams,
          basicAuth as IStringMap,
          this.props.selectedPayloadTemplate,
          this.props.webhook?.ignoreSSLCertCheck
        )
      )
      .then(({ status, status_code }) => {
        this.setState({ testWebhookLoading: false });
        if (status_code === "n/a") {
          this.props.dispatch(
            addMessageAlert({
              message: status,
              type: BannerType.WARNING,
            })
          );
        } else {
          this.props.dispatch(
            addDefaultSuccessAlert(
              `Received ${status_code} response from the webhook URL`
            )
          );
        }
      });
  };

  render() {
    const { valid } = this.props.validateDefineWebhookTarget();
    return (
      <div className={"configure-webhook-step-container"}>
        <InfoBanner
          type={BannerType.INFO}
          message={
            "How to define and test a custom payload template for this integration"
          }
          linkText={"View support article"}
          linkURL={supportArticleURL}
          subItemType={SubItemType.NUMBER}
          subItems={[
            "Select a notification trigger you would like to customize.",
            "Review the example payload to see the available data fields and formats.",
            "The default payload structure for the notification is shown under 'Payload template' using the Liquid templating language. Edit this template to customize your payload.",
            "Click the 'Send Test message' button to send the payload to your webhook using the sample data.",
            "A HTTP POST message will be sent to your webhook and the response received will be displayed in the 'Response' section.",
          ]}
        />
        <div className="section-step">
          <div className="section-step nobackground">
            <div id={"payload"}>
              <div className={"title-bar"}>
                <div className="header">Review payload by trigger type</div>
              </div>
              <div className={"body"}>
                {(!this.props.selectedUUIDs ||
                  Object.keys(this.props.selectedUUIDs).length == 0) && (
                  <div className={"no-triggers"}>
                    <span>
                      {
                        "No triggers have been selected. Please return to the 'Triggers' section and select at least one notification trigger for this integration."
                      }
                    </span>
                  </div>
                )}
                {this.props.selectedUUIDs &&
                  Object.keys(this.props.selectedUUIDs).length > 0 && (
                    <>
                      <SelectableSidebarTriggersList
                        notifications={this.props.notifications}
                        selectedUUIDs={this.props.selectedUUIDs}
                        selectedTestUUID={this.props.selectedTestUUID}
                        setSelectedTestNotification={
                          this.props.setSelectedTestNotification
                        }
                      />
                      <div className={"payload-content"}>
                        <div className={"section-title noline"}>
                          <div className="title">Webhook URL</div>
                        </div>
                        <div className={"payload-line"}>
                          <div className={"webhook-url"}>
                            {this.props.webhook?.webhookURL}
                          </div>
                        </div>
                        <div className={"section-title noline"}>
                          <div className="title">Example payload data</div>
                        </div>
                        <div className={"payload-line"}>
                          <div className={"instruction-text"}>
                            Use the example below to see the available fields
                            and their formats.
                          </div>
                          <div className={"sample-data"}>
                            {this.props.selectedTestUUID &&
                              this.props.selectedTestJSON && (
                                <>
                                  {JSON.stringify(
                                    this.props.selectedTestJSON,
                                    null,
                                    "  "
                                  )}
                                </>
                              )}
                            {(!this.props.selectedTestUUID ||
                              !this.props.selectedTestJSON) && (
                              <>{"no trigger selected"}</>
                            )}
                          </div>
                        </div>
                        <div className={"section-title noline"}>
                          <div className="title">{"Payload template"}</div>
                        </div>
                        <div className={"payload-line"}>
                          <div className={"instruction-text"}>
                            <>
                              {
                                "Define the structure of your outgoing webhook call for this trigger. "
                              }
                              {"The template syntax is Liquid-format (see the "}
                              <a
                                href={`${liquidSyntaxGuideURL}`}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {"support article"}
                              </a>
                              {" for details)."}
                            </>
                          </div>
                          <div className={"terminal"}>
                            <textarea
                              id={"payload-template"}
                              onChange={(e) =>
                                this.props.onChangePayloadTemplate(
                                  e.target.value
                                )
                              }
                              value={this.props.selectedPayloadTemplate}
                            />
                          </div>
                          <div className={"button-row"}>
                            <Button
                              tertiary
                              className={"reset-button"}
                              onClick={() =>
                                this.props.resetPayloadTemplateToDefault()
                              }
                            >
                              <div className="cr-icon-redo" />
                              {"Reset payload template to default"}
                            </Button>
                            <Button
                              className={"send-button"}
                              onClick={this.testWebhook}
                              loading={this.state.testWebhookLoading}
                              disabled={
                                this.state.testWebhookLoading ||
                                !valid ||
                                !this.props.selectedTestUUID
                              }
                            >
                              <div className="cr-icon-message" />{" "}
                              {"Send test message"}
                            </Button>
                          </div>
                        </div>
                        <div className={"section-title noline"}>
                          <div className="title">Response</div>
                        </div>
                        <div className={"payload-line"}>
                          <div className={"sample-data response"}>
                            {this.props.webhookTestResponse &&
                              this.props.webhookTestResponse.response && (
                                <>
                                  {`Status: ${this.props.webhookTestResponse.response.status}\nStatusCode: ${this.props.webhookTestResponse.response.status_code}\nProto: ${this.props.webhookTestResponse.response.proto}\n\n\n`}
                                </>
                              )}
                          </div>
                        </div>
                      </div>
                    </>
                  )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ConfigureWebhookPayloadsStep;
