import {
  DefaultThunkDispatchProp,
  ITestLog,
  ITestLogEntry,
  ITestResult,
} from "../../_common/types/redux";
import { FC, ReactNode, useEffect, useMemo, useState } from "react";
import "../style/SurveyViewerAutomationResultsPanel.scss";
import LoadingBanner from "../../_common/components/core/LoadingBanner";
import { IAutomationRecipe } from "../../vendorrisk/types/automation";

import VendorTierBadge from "../../vendorrisk/components/vendor_tiers/VendorTierBadge";
import {
  fetchVendorTiers,
  VendorTier,
} from "../../vendorrisk/reducers/vendorTiers.actions";
import {
  fetchVendorPortfolios,
  Portfolio,
} from "../../vendorrisk/reducers/portfolios.actions";
import { AutomationAttributeTypes } from "../../vendorrisk/views/EditQuestionnaireAutomation";
import { OptionType, SelectV2 } from "../../_common/components/SelectV2";
import { getVendorWords, IVendorWords } from "../../_common/constants";
import { fetchAutomationRecipe } from "../../vendorrisk/reducers/questionnaireAutomation.actions";
import { setRightPanelState } from "../reducers/actions";
import { surveyViewerRightPanelMode } from "../reducers/reducer";
import { addDefaultUnknownErrorAlert } from "../../_common/reducers/messageAlerts.actions";
import PillLabel from "../../vendorrisk/components/PillLabel";
import { LabelColor } from "../../_common/types/label";
import { appConnect } from "../../_common/types/reduxHooks";

interface SurveyViewerAutomationResultsPanelOwnProps {
  surveyId: number;
  surveyTypeId: number;
  automation?: IAutomationRecipe;
  automationList: IAutomationRecipe[];
  automationUUID?: string;
  setSelectedAutomationUUID: (u: string) => void;
}
interface QuestionnaireAnswersPreviewConnectedProps {
  loading?: boolean;
  error?: IError;
  vendorWords: IVendorWords;
  tiers?: VendorTier[];
  portfolios?: Portfolio[];
  automationLogs?: ITestLogEntry[];
  appliedResults?: { [p: string]: ITestResult };
}

const NORESULT = "--";

type SurveyViewerAutomationResultsPanelProps =
  SurveyViewerAutomationResultsPanelOwnProps &
    QuestionnaireAnswersPreviewConnectedProps &
    DefaultThunkDispatchProp;

const SurveyViewerAutomationResultsPanel: FC<
  SurveyViewerAutomationResultsPanelProps
> = ({
  automation,
  automationList,
  automationLogs,
  vendorWords,
  tiers,
  portfolios,
  dispatch,
  loading,
  error,
  setSelectedAutomationUUID,
  automationUUID,
  appliedResults,
}) => {
  useEffect(() => {
    dispatch(fetchVendorTiers());
    dispatch(fetchVendorPortfolios());
  }, []);

  const calculateInitialSelection = (): number => {
    if (automationUUID == "all") {
      return -1;
    }
    for (let idx = 0; idx < automationList.length; idx++) {
      if (automationList[idx].uuid == automationUUID) {
        return idx;
      }
    }
    return 0;
  };

  const [selectedResult, setSelectedResult] = useState(
    automationUUID == "all" ? -1 : calculateInitialSelection()
  );
  const indexedTiers = useMemo((): { [key: number]: VendorTier } => {
    const index = {} as { [key: number]: VendorTier };
    tiers?.map((t) => {
      if (!t.isArchived && t.isActive) {
        index[t.tier] = t;
      }
    });
    return index;
  }, [tiers]);

  const indexedPortfolios = useMemo((): { [key: number]: Portfolio } => {
    const index = {} as { [key: number]: Portfolio };
    portfolios?.map((p) => {
      index[p.id] = p;
    });
    return index;
  }, [portfolios]);

  const automationOptions = useMemo((): OptionType[] | undefined => {
    const automationOptions = [] as OptionType[];
    automationOptions.push({
      value: -1,
      label: "All rules",
    });
    if (automationList) {
      automationList?.forEach((res, idx) => {
        automationOptions.push({
          value: idx,
          label: res.name,
        });
      });
    }
    return automationOptions;
  }, [automationList]);

  useEffect(() => {
    if (automationList && automation) {
      automationList.forEach((a, idx) => {
        if (a.uuid == automationUUID) {
          setSelectedResult(idx);
        }
      });
    }
  }, [automationList, automation]);

  // for a specific rule that ran, determine the final result (if there was one)
  const getResultStringForFinalResult = (
    testResult: ITestResult
  ): ReactNode => {
    let result: ReactNode = <></>;
    switch (testResult.targetAttribute) {
      case AutomationAttributeTypes.Tier:
        const tierNum = parseInt(testResult.finalValue ?? "-1", 10);
        const tierName = indexedTiers?.[tierNum]?.name || "";
        const tierExists = indexedTiers?.[tierNum];

        result = (
          <div className={"result-string"}>
            <div
              className={"result-title"}
            >{`Assign ${vendorWords.singular} tier: `}</div>
            <div className={"result-content tier-result"}>
              {tierExists && tierNum >= 0 && <VendorTierBadge tier={tierNum} />}
              <span>{tierName}</span>
            </div>
          </div>
        );
        break;
      case AutomationAttributeTypes.Portfolios:
        const portfolioId = parseInt(testResult.finalValue || "-1", 10);
        const portfolioName =
          indexedPortfolios?.[portfolioId]?.name ||
          `${portfolioId} doesnt match?`;
        result = (
          <div className={"result-string"}>
            <div
              className={"result-title"}
            >{`Assign ${vendorWords.singular} portfolio:`}</div>
            <div className={"result-content"}>
              {portfolioId > 0 ? portfolioName : NORESULT}
            </div>
          </div>
        );
        break;
      case AutomationAttributeTypes.Labels:
        result = (
          <div className={"result-string"}>
            <div
              className={"result-title"}
            >{`Assign ${vendorWords.singular} label:`}</div>
            <div className={"result-content"}>
              {!!testResult && (
                <PillLabel color={LabelColor.Blue}>
                  {testResult.finalValue}
                </PillLabel>
              )}
              {!testResult && <div>{NORESULT}</div>}
            </div>
          </div>
        );
        break;
      case AutomationAttributeTypes.Custom:
        result = (
          <div className={"result-string"}>
            <div
              className={"result-title"}
            >{`Assign attribute '${testResult.customAttributeName}':`}</div>
            <div className={"result-content"}>
              <span>{testResult ? testResult.finalValue : NORESULT}</span>
            </div>
          </div>
        );
        break;
    }
    return result;
  };

  // for a specific rule that ran, determine the final result (if there was one)
  const getResultStringForLog = (
    log: ITestLog,
    entry: ITestLogEntry
  ): ReactNode => {
    let result: ReactNode = <></>;
    switch (entry.targetAttribute) {
      case AutomationAttributeTypes.Tier:
        const tierNum = parseInt(log.mappingResult ?? "-1", 10);
        //const tierName = indexedTiers?.[tierNum]?.name || "";
        const tierExists = indexedTiers?.[tierNum];
        return (
          <div className={"tier-result"}>
            {tierExists && tierNum >= 0 && (
              <span>
                {`Tier -> `}
                <VendorTierBadge tier={tierNum} />
              </span>
            )}
            {(!tierExists || tierNum < 0) && <span>{NORESULT}</span>}
          </div>
        );
        break;
      case AutomationAttributeTypes.Portfolios:
        const portfolioId = parseInt(log.mappingResult || "-1", 10);
        const portfolioName =
          indexedPortfolios?.[portfolioId]?.name ||
          `${portfolioId} doesnt match?`;
        result = (
          <div className={"portfolio-result"}>
            {portfolioId > 0 && <span>{`Portfolio -> ${portfolioName}`}</span>}
            {portfolioId == 0 && <span>{NORESULT}</span>}
          </div>
        );
        break;
      case AutomationAttributeTypes.Labels:
        result = (
          <div className={"label-result"}>
            {!!log && (
              <span>
                {"Label -> "}
                <PillLabel color={LabelColor.Blue}>
                  {log.mappingResult}
                </PillLabel>
              </span>
            )}
            {!log && <span>{NORESULT}</span>}
          </div>
        );
        break;
      case AutomationAttributeTypes.Custom:
        result = (
          <div className={"label-result"}>
            {!!log && (
              <span>{`${entry.customAttributeName} -> ${log.mappingResult}`}</span>
            )}
            {!log && <span>{NORESULT}</span>}
          </div>
        );
        break;
    }
    return result;
  };

  const buildResultsList = (): ReactNode => {
    if (!appliedResults || Object.keys(appliedResults).length == 0) {
      return <div>{NORESULT}</div>;
    }
    if (appliedResults && Object.keys(appliedResults).length == 1) {
      const key = Object.keys(appliedResults)[0];
      const desc = getResultStringForFinalResult(appliedResults[key]);
      return desc;
    } else {
      const list: ReactNode[] = [];
      Object.keys(appliedResults).forEach((r: string) => {
        const desc = getResultStringForFinalResult(appliedResults[r]);
        list.push(desc);
      });
      return <>{list}</>;
    }
  };

  const buildLogList = (): ReactNode => {
    if (!automationLogs || automationLogs.length == 0) {
      return <div>{NORESULT}</div>;
    }
    const list: ReactNode[] = [];
    automationLogs.forEach((log: ITestLogEntry) => {
      const subs: ReactNode[] = [];
      log.mappedFinalResults.forEach((r: ITestLog) => {
        const value = !!r.mappingResult ? getResultStringForLog(r, log) : <></>;
        subs.push(
          <li className={"subli"}>
            {!log.isWeighted && (
              <div>{`${log.ruleIndex}.${r.expressionResult}`}</div>
            )}
            {!!log.isWeighted && <div>{`Value = ${r.expressionResult}`}</div>}
            <div>{value}</div>
          </li>
        );
      });
      const desc = (
        <div className={"log-item"}>
          <div className={"log-name"}>{`${
            log.ruleIndex ? log.ruleIndex : "1"
          }. ${log.ruleName}`}</div>
          <ul>{subs}</ul>
        </div>
      );
      list.push(desc);
    });
    return <div>{list}</div>;
  };

  return (
    <div className="survey-viewer-automation-panel">
      <div className={"title"}>Test automation</div>
      <div className={"description"}>
        Use this tool to test your automation is working as intended for your
        relationship questionnaire.
        <br />
        <br />
        Select the automation you would like to test and fill out the
        questionnaire. Then select <b>Run test</b> and review the result when
        it’s confirmed.
      </div>
      {(!automationList || automationList.length == 0) && (
        <div className={"subheading"}>No Enabled Automation Rules Found</div>
      )}
      {automationList && automationList.length > 0 && (
        <>
          <div className={"subheading"}>Automation Rule</div>
          <SelectV2
            value={
              automationList && automationList.length > 0
                ? ({
                    value: selectedResult,
                    label:
                      selectedResult < 0
                        ? "All rules"
                        : automationList?.[selectedResult]?.name,
                  } as OptionType)
                : undefined
            }
            onChange={(selectedOption) => {
              const idx = selectedOption?.value as number;
              setSelectedResult(idx);
              if (idx >= 0) {
                const selected = automationList[idx];
                setSelectedAutomationUUID(selected.uuid);
                if (selected) {
                  dispatch(fetchAutomationRecipe(selected.uuid))
                    .then(() => {
                      dispatch(
                        setRightPanelState(
                          surveyViewerRightPanelMode.Automation,
                          undefined,
                          true
                        )
                      );
                    })
                    .catch((e) => {
                      dispatch(
                        addDefaultUnknownErrorAlert(
                          `Error retrieving automation definition: ${e}`
                        )
                      );
                    });
                }
              } else {
                setSelectedAutomationUUID("all");
              }
            }}
            options={automationOptions}
            isLoading={!automationOptions}
            controlShouldRenderValue={true}
          />

          {(!automation && automationUUID != "all") || loading ? (
            <LoadingBanner />
          ) : (
            <>
              {!error && (
                <>
                  {/* Provide the 'final results' box'*/}
                  <div className={"subheading"}>Final Result</div>
                  {/* this is temporary content.. */}
                  <div className={"automation-name"}>{buildResultsList()}</div>
                  {/* Provide the results logs box*/}
                  {!error && (
                    <>
                      <div className={"subheading"}>Results Log</div>
                      <div className={"result withlist"}>{buildLogList()}</div>
                    </>
                  )}
                </>
              )}
              {!!error && (
                <>
                  <div className={"subheading"}>Results Log</div>
                  <div className={"result error"}>
                    <div className={"tier-name"}>{error.errorText}</div>
                  </div>
                </>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default appConnect<
  QuestionnaireAnswersPreviewConnectedProps,
  never,
  SurveyViewerAutomationResultsPanelOwnProps
>((state, props) => {
  const testResults =
    state.cyberRisk.automatedTestResults?.[props.surveyTypeId]?.[
      props.surveyId
    ];
  const tiers = state.cyberRisk.vendorTiers;
  const portfolios = state.cyberRisk.vendorPortfolios?.portfolios;
  const vendorWords = getVendorWords(state.common.userData.assuranceType);

  const result: QuestionnaireAnswersPreviewConnectedProps = {
    loading: testResults ? testResults.loading : false,
    error: testResults?.error,
    tiers: tiers || undefined,
    portfolios: portfolios || undefined,
    automationLogs: testResults?.mappedResults,
    appliedResults: testResults?.appliedResults,
    vendorWords: vendorWords,
  };
  return result;
})(SurveyViewerAutomationResultsPanel);
