import { FetchCyberRiskUrl } from "../../_common/api";
import { LogError } from "../../_common/helpers";
import { addDefaultUnknownErrorAlert } from "../../_common/reducers/messageAlerts.actions";
import {
  conditionalRefreshActivityStreamForOrgUser,
  setModalData,
} from "../../_common/reducers/commonActions";
import {
  fetchOrgIntegrationSettings,
  setWebhookTestResponse,
} from "./cyberRiskActions";
import {
  IIntegration,
  IntegrationType,
  IStringMap,
  JiraFields,
  WebhookResponse,
} from "../../_common/types/integration";
import { DefaultAction } from "../../_common/types/redux";
import { fetchAllOrgNotifications } from "./org.actions";

export const createIntegration = (
  integrationType: IntegrationType,
  uniqueId: string,
  description: string,
  uuids: string[],
  webhookURL: string,
  httpHeaders: IStringMap,
  urlParams: IStringMap,
  basicAuthParams: IStringMap,
  payloadTemplates: IStringMap,
  ignoreCerts = false,
  selectedChannel?: string,
  selectedJiraProject?: string,
  selectedJiraProjectId?: string,
  jiraIssueFieldValues?: JiraFields,
  toEmailAddress?: string,
  fromEmailName?: string,
  fromEmailAddress?: string,
  emailSubjects?: IStringMap
): DefaultAction<number> => {
  return async (dispatch, getState) => {
    let json: IIntegration;
    const body = {
      type: integrationType,
      unique_id: uniqueId,
      description,
      uuids,
      webhook_url: webhookURL,
      http_headers: httpHeaders ? httpHeaders : {},
      url_params: urlParams ? urlParams : {},
      basic_auth_params: basicAuthParams ? basicAuthParams : {},
      ignore_cert_check: ignoreCerts,
      payload_templates: payloadTemplates,
      channel: selectedChannel,
      jiraProject: selectedJiraProject,
      jiraProjectId: selectedJiraProjectId,
      jiraIssueFieldValues: jiraIssueFieldValues,
      toEmailAddress,
      fromEmailName,
      fromEmailAddress,
      emailSubjects,
    };

    try {
      json = await FetchCyberRiskUrl(
        "integration/create/v1",
        {},
        {
          method: "POST",
          body: JSON.stringify(body),
        },
        dispatch,
        getState
      );
    } catch (e: any) {
      LogError("error creating integration for org", e);
      dispatch(addDefaultUnknownErrorAlert(e.message));
      throw e;
    }

    dispatch(fetchAllOrgNotifications(true));
    await dispatch(fetchOrgIntegrationSettings(true));

    // kick off call to update the activity stream
    dispatch(conditionalRefreshActivityStreamForOrgUser());

    return json.id;
  };
};

export const updateIntegration = (
  id: number,
  integrationType: IntegrationType,
  identifier: string,
  description: string,
  uuidsList: string[],
  webhookURL: string,
  httpHeaders: IStringMap,
  urlParams: IStringMap,
  basicAuthParams: IStringMap,
  enabled: boolean,
  payloadTemplates: IStringMap,
  ignoreCerts = false,
  selectedChannel?: string,
  selectedJiraProject?: string,
  selectedJiraProjectId?: string,
  jiraIssueFieldValues?: JiraFields,
  toEmailAddress?: string,
  fromEmailName?: string,
  fromEmailAddress?: string,
  emailSubjects?: IStringMap
): DefaultAction => {
  return async (dispatch, getState) => {
    try {
      // create the post body
      const body = {
        id,
        type: integrationType,
        unique_id: identifier,
        description,
        uuids: uuidsList,
        webhook_url: webhookURL,
        http_headers: httpHeaders ? httpHeaders : {},
        url_params: urlParams ? urlParams : {},
        basic_auth_params: basicAuthParams ? basicAuthParams : {},
        enabled,
        ignore_cert_check: ignoreCerts,
        payload_templates: payloadTemplates,
        channel: selectedChannel,
        jiraProject: selectedJiraProject,
        jiraProjectId: selectedJiraProjectId,
        jiraIssueFieldValues: jiraIssueFieldValues,
        toEmailAddress,
        fromEmailName,
        fromEmailAddress,
        emailSubjects,
      };

      await FetchCyberRiskUrl(
        "integration/v1/",
        {},
        {
          method: "POST",
          body: JSON.stringify(body),
        },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error updating integration for org", e);
      dispatch(addDefaultUnknownErrorAlert("Error saving integration"));
      throw e;
    }
    dispatch(fetchAllOrgNotifications(true));
    await dispatch(fetchOrgIntegrationSettings(true));

    // kick off call to update the activity stream
    dispatch(conditionalRefreshActivityStreamForOrgUser());
  };
};

export const enableIntegration = (
  id: number,
  enable = true
): DefaultAction<number> => {
  return async (dispatch, getState) => {
    try {
      await FetchCyberRiskUrl(
        "integration/enable/v1/",
        {
          id,
          enable,
        },
        { method: "POST" },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error enabling/disabling integration for org", e);
      dispatch(
        addDefaultUnknownErrorAlert(
          enable ? "Error enabling integration" : "Error disabling integration"
        )
      );
      throw e;
    }
    await dispatch(fetchOrgIntegrationSettings(true));

    // kick off call to update the activity stream
    dispatch(conditionalRefreshActivityStreamForOrgUser());
    return id;
  };
};

export interface TestWebhookV1Resp {
  response: WebhookResponse;
  status: string;
}

export const executeWebhookTest = (
  webhookID: number,
  uuidsList: string[],
  webhookURL: string,
  httpHeaders: IStringMap,
  urlParams: IStringMap,
  basicAuthParams: IStringMap,
  payloadTemplate = "",
  ignoreCerts = false
): DefaultAction<WebhookResponse> => {
  return async (dispatch, getState) => {
    let json: TestWebhookV1Resp;
    try {
      // create the post body
      const body = {
        webhook_id: webhookID,
        uuids: uuidsList,
        webhook_url: webhookURL,
        http_headers: httpHeaders,
        url_params: urlParams,
        basic_auth_params: basicAuthParams,
        ignore_cert_check: ignoreCerts,
        payload_template: payloadTemplate,
      };

      dispatch(setModalData("webhookTestResponse", {}));
      dispatch(setWebhookTestResponse({}));
      json = await FetchCyberRiskUrl(
        "integration/test/v1/",
        {},
        {
          method: "POST",
          body: JSON.stringify(body),
        },
        dispatch,
        getState
      );
    } catch (e: any) {
      LogError("Error testing integration webhook", e);
      let status = "Failed to connect. Please review connection settings";
      if (
        e.toString().includes("parse liquid template") ||
        e.toString().includes("render liquid template")
      ) {
        status = e.toString();
      }
      dispatch(
        setModalData("webhookTestResponse", {
          response: {
            status: status,
            status_code: "n/a",
            proto: "n/a",
          },
        })
      );
      return {
        status: status,
        status_code: "n/a",
        proto: "n/a",
      };
    }
    dispatch(setModalData("webhookTestResponse", json));
    dispatch(setWebhookTestResponse(json));
    return json.response;
  };
};

export const executeEmailTest =
  (
    integrationID: number,
    uuid: string,
    payloadTemplate: string,
    subjectTemplate: string
  ): DefaultAction<string> =>
  async (dispatch, getState) => {
    let json: { status: string };

    try {
      const body = {
        integrationID,
        uuid,
        payloadTemplate,
        subjectTemplate,
      };

      json = await FetchCyberRiskUrl<{ status: string }>(
        "integration/test/email/v1",
        {},
        {
          method: "POST",
          body: JSON.stringify(body),
        },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error testing email integration", e);
      throw e;
    }

    return json.status;
  };
