import { Filters, WebsiteStatusFilter } from "../components/filter/types";
import { DefaultManagedVendorServiceStatusTypes } from "../../analyst_portal/components/filter/TprmsFilter.helpers";
import { ScopeRiskLevel } from "../../userbase/api/types";

// Get initial empty default state for app-wide filters
export const getDefaultFilters = (): Filters => {
  return {
    includeUnlabeled: false,
    minScore: undefined,
    maxScore: undefined,
    vendorLabelIds: [],
    websiteLabelIds: [],
    websiteLabelIdsDoNotMatch: false,
    websiteLabelIdsMatchAll: false,
    websiteIncludeUnlabeled: false,
    domainPortfolioIds: [],
    vendorIds: [],
    riskName: "",
    riskCategories: [],
    dataLeaksClassifications: [],
    dataLeaksSources: [],
    dataLeaksKeywords: [],
    vulnsCveNames: [],
    vulnsSoftware: [],
    vulnsExcludeVerified: false,
    vulnsExcludeUnverified: false,
    vulnsExcludeUnignored: false,
    vulnsExcludeIgnored: true,
    vulnsExcludedSeverities: [],
    vulnsEPSSScores: {},
    typosquatReasonIds: [],
    typosquatTypeIds: [],
    typosquatReasonIdsDoNotMatch: false,
    typosquatTypeIdsDoNotMatch: false,
    typosquatMaliciousActivities: [],
    typosquatMaliciousActivitiesDoNotMatch: false,
    riskIds: [],
    websiteStatus: WebsiteStatusFilter.All,
    vendorLabelIdsMatchAll: false,
    vendorLabelIdsDoNotMatch: false,
    subsidiaryLabelIds: [],
    subsidiaryCountries: [],
    subsidiaryIds: [],
    subsidiaryMinScore: undefined,
    subsidiaryMaxScore: undefined,
    vendorTiers: [],
    vendorDateAddedStartDate: "",
    vendorDateAddedEndDate: "",
    vendorDateAddedDateBefore: false,
    vendorDateAddedDateBetween: false,
    vendorAssessmentClassifications: [],
    vendorAssessmentAuthors: [],
    vendorAssessmentAuthorDoNotMatch: false,
    vendorReassessmentStartDate: "",
    vendorReassessmentEndDate: "",
    vendorReassessmentDateBefore: false,
    vendorReassessmentDateBetween: false,
    portfolioIds: [],
    selectedSurveyStatuses: [],
    selectedSurveyAttributes: [],
    selectedAttributes: {},
    vulnsOnlyKEV: false,
    assessedVendorsOnly: false,
    vendorSurveyTypes: [],
    vendorEvidenceTypes: [],
    orgNames: [],
    excludedServiceLevels: undefined,
    includedServiceStatuses: DefaultManagedVendorServiceStatusTypes,
    detectedProductCategory: [],
    detectedProductVendorMonitored: false,
    detectedProductVendorUnmonitored: false,
    fourthPartyProductUUIDs: [],
    cloudConnectionProviderTypes: [],
    cloudConnectionUUIDs: [],
    userRiskUserUUIDs: [],
    userRiskExcludeUsers: false,
    userRiskAppNames: [],
    userRiskExcludeApps: false,
    userRiskTeams: [],
    userRiskExcludeTeams: false,
    userRiskRoles: [],
    userRiskExcludeRoles: false,
    userRiskAppStatuses: [],
    userRiskExcludeStatuses: false,
    userRiskMinFirstDetected: undefined,
    userRiskMaxFirstDetected: undefined,
    userRiskFirstDetectedBefore: false,
    userRiskFirstDetectedBetween: false,
    userRiskMinRiskLevel: ScopeRiskLevel.LowScopeRiskLevel,
    userRiskMaxRiskLevel: ScopeRiskLevel.HighScopeRiskLevel,
    userRiskScopeReadOnly: true,
    userRiskScopeReadWrite: true,
    userRiskScopeMinRiskLevel: ScopeRiskLevel.LowScopeRiskLevel,
    userRiskScopeMaxRiskLevel: ScopeRiskLevel.HighScopeRiskLevel,
    userRiskUserMinScore: 0,
    userRiskUserMaxScore: 950,
    userRiskAppMinScore: 0,
    userRiskAppMaxScore: 950,
  };
};

// Get a filter object with the given filters applied, and the rest set to default
export const makeFilter = (f: Partial<Filters>): Filters => {
  return {
    ...getDefaultFilters(),
    ...f,
  };
};
