import { PortfolioSpecificRole } from "../../vendorrisk/types/roles";
import { RemediationType } from "./remediation";
import { RiskAssetType } from "../../vendorrisk/reducers/customerAcceptedRisks.actions";
import { WaiverType } from "./vendorRiskWaivers";

export type IUserMiniMap = {
  [userId: number]: IUserMini | undefined;
};

export interface IUserMini {
  id: number;
  sub: string;
  name: string;
  avatar: string;
  email: string;
}

export enum InviteType {
  Survey = "Survey",
  Organisation = "Organisation",
  RemediationRequest = "RemediationRequest",
  RiskAcceptanceApproval = "RiskAcceptanceApproval",
  VerifiedVendorAccess = "VerifiedVendorAccess",
  AdditionalEvidence = "AdditionalEvidence",
  TrustExchangeSignUp = "TrustExchangeSignUp",
  CustomerFreeTrial = "CustomerFreeTrial",
}

export interface Invite {
  id: number;
  inviteType: InviteType;
  recipientEmail: string;
  claimed: boolean;
  cancelled: boolean;
  expires: string;
  isExpired?: boolean;
}

export interface UserEmailAddress {
  id: number;
  name: string;
  emailAddress: string;
  avatar: string;
}

export interface IUser {
  id: number;
  openId: string;
  trackingId: string;
  emailAddress: string;
  name: string;
  firstName: string;
  lastName: string;
  profile: string;
  isSuperAdmin: boolean;
  isAnalyst: boolean;
  lastOrgId: number;
  lastLoggedIn: string;
  settings: unknown;
  createdAt: string;
  updatedAt: string;
  system_roles: string[];
  portfolioSpecificRoles: PortfolioSpecificRole[];
  mfaEnabled: boolean;
}

export interface IOrgUser extends IUser {
  roles: string[];
  namedRoleId?: number;
  namedRole?: string;
  orgId: number;
  avatar: string;
  isDeleted: boolean;
  ssoEnabled: boolean;
  userMFAStatus: boolean;
}

export interface IOrgUserWithFree extends IOrgUser {
  isFreeUser: boolean;
}

export interface IOrgUserInvite {
  id: number;
  email: string;
  roles: string[];
  expires: string;
  namedRoleId?: number;
  isFreeUser: boolean;
}

export const UserEmailAddressToSharedUser = (
  userEmailAddress: UserEmailAddress | undefined
): IUserMini | undefined => {
  if (userEmailAddress === undefined) {
    return undefined;
  } else {
    return {
      id: userEmailAddress.id,
      sub: "",
      name: userEmailAddress.name,
      avatar: userEmailAddress.avatar,
      email: userEmailAddress.emailAddress,
    };
  }
};

export const SharedUserToUserEmailAddress = (
  sharedUser: IUserMini | undefined
): UserEmailAddress | undefined => {
  if (sharedUser === undefined) {
    return undefined;
  } else {
    return {
      id: sharedUser.id,
      name: sharedUser.name,
      avatar: sharedUser.avatar,
      emailAddress: sharedUser.email,
    };
  }
};

export interface OrgCollaboratorUser {
  id: number;
  emailAddress: string;
  name: string;
  avatar: string;
  numSurveys: number;
  lastLoggedIn?: string;
}

export interface IUserTaskData {
  currentOrgRemediationRequestIDsByTypeMap?: {
    [key in RemediationType]?: number[];
  };
  otherOrgRemediationRequestIDsByTypeMap?: {
    [key in RemediationType]?: number[];
  };
  currentOrgRiskAcceptanceIDsByTypeMap?: { [key in RiskAssetType]?: number[] };
  otherOrgRiskAcceptanceIDsByTypeMap?: { [key in RiskAssetType]?: number[] };
  currentOrgVendorRiskWaiverIDsByTypeMap?: { [key in WaiverType]?: number[] };
  otherOrgVendorRiskWaiverIDsByTypeMap?: { [key in WaiverType]?: number[] };
}
