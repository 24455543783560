import { DefaultAction } from "../../_common/types/redux";
import {
  AssessmentCorrespondence,
  AssessmentMetaQuestions,
  DisplayableManagedVendorAssessment,
  IManagedVendorPageOpts,
  ManagedVendorAssessmentHistory,
  ManagedVendorAssessmentMeta,
  ManagedVendorAssessmentRequestVendorInfo,
  ManagedVendorLicenseUsed,
  ManagedVendorServiceLevel,
} from "../../_common/types/thirdPartyMangedVendors";
import { LogError } from "../../_common/helpers";
import { FetchCyberRiskUrl } from "../../_common/api";
import { setCustomerData } from "./cyberRiskActions";
import { cyberRiskInitialState } from "./cyberRiskReducer.initialState";
import { cloneDeep as _cloneDeep } from "lodash";

export interface ManagedAssessmentDetailsState {
  details?: DisplayableManagedVendorAssessment;
  detailsLoading?: boolean;
  messages?: AssessmentCorrespondence[];
  messagesLoading?: boolean;
  history?: ManagedVendorAssessmentHistory[];
  historyLoading?: boolean;
}

export const newManagedAssessmentDetailsState =
  (): ManagedAssessmentDetailsState => ({
    detailsLoading: true,
    messages: [],
    messagesLoading: true,
    history: [],
    historyLoading: true,
  });

export const SET_MANAGED_ASSESSMENT_DETAILS = "SET_MANAGED_ASSESSMENT_DETAILS";
export const setManagedAssessmentDetails = (
  requestId: number,
  details: Partial<ManagedAssessmentDetailsState>
) => ({
  type: SET_MANAGED_ASSESSMENT_DETAILS,
  requestId,
  details,
});

export interface CreateVendorRequestMeta {
  name?: string;
  title?: string;
  email?: string;
  meta: ManagedVendorAssessmentMeta;
}
export const createVendorManagementRequestV2 =
  (
    vendorId: number,
    tier: number,
    serviceLevel: ManagedVendorServiceLevel,
    meta: CreateVendorRequestMeta,
    contactId?: number,
    labelIds?: number[]
  ): DefaultAction<number> =>
  async (dispatch, getState) => {
    let json: { newId: number };
    try {
      json = await FetchCyberRiskUrl(
        "managedvendors/v2",
        {
          datastore_vendor_id: vendorId,
          existing_contact_id: contactId,
          vendor_label_ids: labelIds,
          tier: tier,
          service_level: serviceLevel,
        },
        { method: "POST", body: JSON.stringify(meta) },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error creating new managed vendor assessment request", e);

      throw e;
    }

    dispatch(getManagedVendorAssessmentsV2(undefined, undefined, true));

    // Reset any cached watchlist data since we might have a new watched vendor
    dispatch(
      setCustomerData({
        vendors: _cloneDeep(cyberRiskInitialState.customerData.vendors),
      })
    );

    return json.newId;
  };

export const getSingleManagedVendorAssessment =
  (
    id: number,
    force = false
  ): DefaultAction<DisplayableManagedVendorAssessment> =>
  async (dispatch, getState) => {
    const assessmentState =
      getState().cyberRisk.orgManagedAssessments.assessmentDetails[id];
    if (!force && assessmentState && assessmentState.details) {
      return assessmentState.details;
    }

    dispatch(
      setManagedAssessmentDetails(id, {
        detailsLoading: true,
      })
    );

    let json: { result: DisplayableManagedVendorAssessment };

    try {
      json = await FetchCyberRiskUrl(
        "managedvendors/assessment/v2",
        {
          request_id: id,
        },
        null,
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error retrieving managed vendor assessment details", e);
      throw e;
    }

    dispatch(
      setManagedAssessmentDetails(id, {
        detailsLoading: false,
        details: json.result,
      })
    );

    return json.result;
  };

export const changeServiceLevel =
  (
    request_id: number,
    service_level: ManagedVendorServiceLevel
  ): DefaultAction =>
  async (dispatch, getState) => {
    try {
      await FetchCyberRiskUrl(
        "managedvendors/edit/level/v2",
        {
          request_id,
          service_level,
        },
        { method: "PUT" },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error changing vendor assessment request service level", e);

      throw e;
    }

    const assessment =
      getState().cyberRisk.orgManagedAssessments.assessmentDetails[request_id];
    if (assessment && assessment.details) {
      const newDetails: DisplayableManagedVendorAssessment = {
        ...assessment.details,
        serviceLevel: service_level,
      };
      dispatch(
        setManagedAssessmentDetails(request_id, { details: newDetails })
      );
    }

    // the licence details will have also changed to refetch them
    dispatch(getManagedVendorLicenses(true));
  };

export interface EditManagementContactReq {
  name?: string;
  title?: string;
  email?: string;
  existingId?: number;
}

export const editManagementContact =
  (request_id: number, contactReq: EditManagementContactReq): DefaultAction =>
  async (dispatch, getState) => {
    try {
      await FetchCyberRiskUrl(
        "managedvendors/edit/contact/v2",
        {
          request_id,
        },
        { method: "PUT", body: JSON.stringify(contactReq) },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error changing vendor assessment management contact", e);

      throw e;
    }

    dispatch(getSingleManagedVendorAssessment(request_id, true));
  };

export const updateMetaAnswers =
  (request_id: number, meta: ManagedVendorAssessmentMeta): DefaultAction =>
  async (dispatch, getState) => {
    try {
      await FetchCyberRiskUrl(
        "managedvendors/meta/v2",
        {
          request_id,
        },
        { method: "PUT", body: JSON.stringify({ meta }) },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error changing updating meta answers", e);

      throw e;
    }

    const assessment =
      getState().cyberRisk.orgManagedAssessments.assessmentDetails[request_id];
    if (assessment && assessment.details) {
      const newDetails: DisplayableManagedVendorAssessment = {
        ...assessment.details,
        meta,
      };
      dispatch(
        setManagedAssessmentDetails(request_id, { details: newDetails })
      );
    }
  };

export const deleteManagedVendorAssessment =
  (request_id: number): DefaultAction =>
  async (dispatch, getState) => {
    try {
      await FetchCyberRiskUrl(
        "managedvendors/v2",
        {
          request_id,
        },
        { method: "DELETE" },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error changing vendor assessment management contact", e);

      throw e;
    }

    dispatch(getManagedVendorAssessmentsV2(undefined, undefined, true));
  };

export const SET_MANAGED_VENDOR_LICENSES = "SET_MANAGED_VENDOR_LICENSES";
export const setManagedVendorLicenses = (
  loading: boolean,
  licenses?: ManagedVendorLicenseUsed[]
) => ({
  type: SET_MANAGED_VENDOR_LICENSES,
  loading,
  licenses,
});

export const getManagedVendorLicenses =
  (force?: boolean): DefaultAction =>
  async (dispatch, getState) => {
    const licenses =
      getState().cyberRisk.orgManagedAssessments.licenses.licenses;
    if (!force && licenses.length > 0) {
      return;
    }

    dispatch(setManagedVendorLicenses(true));

    let json: { licenses: ManagedVendorLicenseUsed[] };

    try {
      json = await FetchCyberRiskUrl(
        "managedvendors/licenses/v2",
        null,
        null,
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error getting managed vendor licenses", e);

      throw e;
    }

    dispatch(setManagedVendorLicenses(false, json.licenses));
  };

export const SET_MANAGED_VENDOR_ASSESSMENTS_LIST =
  "SET_MANAGED_VENDOR_ASSESSMENTS_LIST";
export const setManagedVendorAssessmentsList = (
  loading: boolean,
  data?: {
    managedAssessments?: number[];
    paging?: IManagedVendorPageOpts;
    filterText?: string;
  }
) => ({
  type: SET_MANAGED_VENDOR_ASSESSMENTS_LIST,
  loading,
  data: data ?? {},
});

export const getManagedVendorAssessmentsV2 =
  (
    newPaging?: IManagedVendorPageOpts,
    namePrefix?: string,
    force = false
  ): DefaultAction =>
  async (dispatch, getState) => {
    let json: {
      managedAssessments: DisplayableManagedVendorAssessment[];
      paging: IManagedVendorPageOpts;
      filterText: string;
    };

    const paging =
      getState().cyberRisk.orgManagedAssessments.assessmentList.paging;
    const filterText =
      getState().cyberRisk.orgManagedAssessments.assessmentList.filterText;

    if (
      !force &&
      newPaging?.pageNum == paging.pageNum &&
      newPaging.pageSize == paging.pageSize &&
      newPaging.sortDir == paging.sortDir &&
      newPaging.sortBy == paging.sortBy &&
      namePrefix == filterText
    ) {
      return;
    }

    dispatch(setManagedVendorAssessmentsList(true));

    paging.pageNum = newPaging?.pageNum ?? paging.pageNum;
    paging.pageSize = newPaging?.pageSize ?? paging.pageSize;
    paging.sortBy = newPaging?.sortBy ?? paging.sortBy;
    paging.sortDir = newPaging?.sortDir ?? paging.sortDir;

    try {
      json = await FetchCyberRiskUrl(
        "managedvendors/v2",
        {
          page_num: paging.pageNum,
          page_size: paging.pageSize,
          sort_by: paging.sortBy,
          sort_dir: paging.sortDir,
          name_prefix: namePrefix,
        },
        null,
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error gettingmanaged vendor list", e);

      throw e;
    }

    if (!json) {
      // json will be `false` if there is no auth.
      return;
    }

    dispatch(
      setManagedVendorAssessmentsList(false, {
        managedAssessments: json.managedAssessments.map((m) => m.id),
        paging: json.paging,
        filterText: json.filterText,
      })
    );

    // cache the details for each request by id
    json.managedAssessments.forEach((d) => {
      dispatch(
        setManagedAssessmentDetails(d.id, {
          details: d,
          detailsLoading: false,
        })
      );
    });
  };

export const SET_MANAGED_VENDOR_META_QUESTIONS =
  "SET_MANAGED_VENDOR_META_QUESTIONS";
export const setMetaQuestions = (meta: AssessmentMetaQuestions) => ({
  type: SET_MANAGED_VENDOR_META_QUESTIONS,
  meta,
});

export const getManagedAssessmentMetaQuestions =
  (): DefaultAction => async (dispatch, getState) => {
    let json: AssessmentMetaQuestions;
    try {
      json = await FetchCyberRiskUrl(
        "managedvendors/meta/v2",
        null,
        null,
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error getting meta questions", e);

      throw e;
    }

    dispatch(setMetaQuestions(json));
  };

export const SET_ASSESSMENT_REQUEST_VENDOR_INFO =
  "SET_ASSESSMENT_REQUEST_VENDOR_INFO";
export const setAssessmentRequestVendorInfo = (
  info: ManagedVendorAssessmentRequestVendorInfo
) => ({
  type: SET_ASSESSMENT_REQUEST_VENDOR_INFO,
  info,
});

export const getAssessmentRequestMetaInfo =
  (vendorId: number): DefaultAction =>
  async (dispatch, getState) => {
    dispatch(setAssessmentRequestVendorInfo({ loading: true }));

    let json: ManagedVendorAssessmentRequestVendorInfo;
    try {
      json = await FetchCyberRiskUrl(
        "managedvendors/vendorinfo/v2",
        { vendor_id: vendorId },
        null,
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error getting vendor info", e);

      throw e;
    }

    dispatch(setAssessmentRequestVendorInfo({ ...json, loading: false }));
  };

// Below functions will be part of a future release and are not yet tested
// TODO - delete this (unused, no endpoint)
export const postManagedAssessmentCorrespondence =
  (
    request_id: number,
    content: number,
    isPrivate: boolean,
    parent_id?: number
  ): DefaultAction =>
  async (dispatch, getState) => {
    try {
      await FetchCyberRiskUrl(
        "managedvendors/mesasage/v2",
        {
          request_id,
          content,
          ["private"]: isPrivate,
          parent_id,
        },
        { method: "POST" },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error posting correspondence", e);

      throw e;
    }

    // TODO - update redux
  };

// TODO - delete this (unused, no endpoint)
export const editManagedAssessmentCorrespondence =
  (request_id: number, message_id: number, content: string): DefaultAction =>
  async (dispatch, getState) => {
    try {
      await FetchCyberRiskUrl(
        "managedvendors/mesasage/v2",
        {
          request_id,
          content,
          message_id,
        },
        { method: "PUT" },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error editing correspondence", e);

      throw e;
    }

    // TODO - update redux - or state someohow (see how surveyDetails does it?
  };

// TODO - delete this (unused, no endpoint)
export const getManagedAssessmentCorrespondence =
  (request_id: number): DefaultAction =>
  async (dispatch, getState) => {
    dispatch(
      setManagedAssessmentDetails(request_id, { messagesLoading: true })
    );

    let json: { messages: AssessmentCorrespondence[] };
    try {
      json = await FetchCyberRiskUrl(
        "managedvendors/mesasage/v2",
        {
          request_id,
        },
        null,
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error getting correspondence", e);

      throw e;
    }

    // TODO - update redux
    dispatch(
      setManagedAssessmentDetails(request_id, {
        messagesLoading: false,
        messages: json.messages,
      })
    );
  };
