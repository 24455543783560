import { stripLeadingDisplayId } from "../../surveyViewer.helpers";
import { Node, NodeType } from "../../../survey_builder/types/types";
import classnames from "classnames";
import { FC, useEffect, useRef } from "react";

// Adds a click listener to ensure any external links open in a new tab.
export const useEnsureExternalLinksInNewTab = () => {
  const element = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const listener = (e: MouseEvent) => {
      if (
        e.target instanceof HTMLAnchorElement &&
        e.target.href &&
        e.target.href !== "#"
      ) {
        e.preventDefault();
        window.open(e.target.href, "_blank", "noopener,noreferrer");
      }
    };

    element.current?.addEventListener("click", listener);

    return () => {
      element.current?.removeEventListener("click", listener);
    };
  }, []);

  return element;
};

interface NodeHeadingProps {
  node: Node;
  isDiffMode: boolean;
}

const NodeHeading: FC<NodeHeadingProps> = ({ node, isDiffMode }) => {
  let mainText: string | undefined, desc: string | undefined;

  switch (node.type) {
    case NodeType.Section:
      mainText = node.title;
      break;
    case NodeType.Risk:
      mainText = node.nameSanitised ?? node.name;
      break;
    case NodeType.Info:
      mainText = undefined;
      break;
    default:
      mainText = node.mainText;
  }

  switch (node.type) {
    case NodeType.Section:
      desc =
        node.mainText && node.mainText != ""
          ? node.mainText
          : "Please complete the questions in this section to the best of your knowledge.";
      break;
    case NodeType.Info:
    case NodeType.Risk:
      desc = node.mainText;
      break;
  }

  // if we are in diff mode and this is a risk node we also want to show the compensating controls text in the left panel
  const whyNode =
    isDiffMode && node.type == NodeType.Risk ? (
      <div className={"desc"} dangerouslySetInnerHTML={{ __html: node.why }} />
    ) : undefined;

  // Intercept clicks within this heading to ensure any links are opened in a new tab.
  const mainEl = useEnsureExternalLinksInNewTab();

  return (
    <div className={"node-heading"} ref={mainEl}>
      {mainText && (
        <div
          className={classnames("node-name", {
            "section-node-name": node.type == NodeType.Section,
          })}
          dangerouslySetInnerHTML={{
            __html: stripLeadingDisplayId(mainText),
          }}
        />
      )}
      {desc && (
        <div
          className={"desc"}
          dangerouslySetInnerHTML={{
            __html: desc,
          }}
        />
      )}
      {whyNode}
    </div>
  );
};

export default NodeHeading;
