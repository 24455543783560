import { Component } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import Button from "../../../_common/components/core/Button";

import { closeModal } from "../../../_common/reducers/commonActions";

import { validateEmail } from "../../../_common/helpers";
import ColorCheckbox from "../ColorCheckbox";
import { addOrgUsers } from "../../reducers/cyberRiskActions";
import { trackEvent } from "../../../_common/tracking";
import "../../style/components/NewUserModal.scss";
import {
  addDefaultSuccessAlert,
  addDefaultUnknownErrorAlert,
  addSimpleErrorAlert,
} from "../../../_common/reducers/messageAlerts.actions";

export const NewUserModalName = "NewUserModalName";

export function roleContent() {
  return `Select Read-Only mode to restrict your user from making updates to your
            account. All other users will be able to access all standard CyberRisk
            functionality. Select additional permissions for these users below.`;
}

export function roleText() {
  return <div className="roleText">{roleContent()}</div>;
}

export default class NewUserModal extends Component {
  static propTypes = {
    modalData: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    const { userRole } = this.props.modalData;

    // if a user role is supplied as part of the modalData, then we dont need to prompt
    // the user for it.

    if (userRole) {
      this.state = {
        loading: false,
        highlighted: { [userRole]: true },
      };
    } else {
      this.state = {
        loading: false,
        highlighted: {
          Admin: false,
          BreachSight: true,
          ReadOnly: false,
          User: true,
        },
      };
    }
  }

  onRoleChange = (id) =>
    this.setState((state) => {
      const thisRoleHighlighted = state.highlighted[id];
      const newHighlighted = {
        [id]: !thisRoleHighlighted,
      };

      // if we are setting read-only, then we dont want the User role (and conversely)
      if (id === "ReadOnly") {
        newHighlighted.User = thisRoleHighlighted;
      }

      if (id === "Admin" && !thisRoleHighlighted) {
        // Enable the Risk Assessor role by default when enabling Admin.
        newHighlighted.VendorAssessor = true;
        newHighlighted.QuestionnaireCreator = true;
      }

      return {
        highlighted: {
          ...state.highlighted,
          ...newHighlighted,
        },
      };
    });

  onSubmit = (evt) => {
    evt.preventDefault();

    const email = this.email.value.trim().toLowerCase();
    const roles = this.state.highlighted;
    let finalRoles = [];
    if (roles["ReadOnly"]) {
      finalRoles = ["ReadOnly"];
    } else {
      Object.keys(roles).forEach(function (id) {
        if (roles[id]) {
          finalRoles.push(id);
        }
      });
    }
    if (!validateEmail(email)) {
      this.props.dispatch(
        addSimpleErrorAlert("Must enter a valid email address for the user")
      );
      return;
    }

    if (finalRoles.length < 1) {
      this.props.dispatch(
        addSimpleErrorAlert("Must select a role for the user")
      );
      return;
    }

    this.setState({ loading: true });
    this.props
      .dispatch(addOrgUsers([email], finalRoles, [], null))
      .then(() => {
        this.props.dispatch(closeModal());
        this.props.dispatch(addDefaultSuccessAlert("User invited"));

        trackEvent("invited a user", {
          userEmail: email,
          roles: finalRoles,
        });
      })
      .catch((err) => {
        this.props.dispatch(addDefaultUnknownErrorAlert(err.message));
      })
      .then(() => {
        this.setState({ loading: false });
      });
  };

  render() {
    const {
      orgName,
      userRole,
      orgHasBreachSightEnabled,
      orgHasVendorRiskEnabled,
      orgHasVendorAssessmentsEnabled,
      orgHasThirdPartyVendorManagementEnabled,
      orgHasQuestionnaireBuilderEnabled,
    } = this.props.modalData;

    const possibleRoles = [
      {
        id: "ReadOnly",
        displayName: "Read-Only",
        desc: "Access the system in read-only mode.",
        disabled: false,
        checked: this.state.highlighted["ReadOnly"],
        gapbelow: false,
      },
      {
        id: "Admin",
        displayName: "Account Admin",
        desc: "Manage users.",
        disabled: this.state.highlighted["ReadOnly"],
        checked:
          this.state.highlighted["Admin"] &&
          !this.state.highlighted["ReadOnly"],
        gapbelow: false,
      },
      {
        id: "BreachSight",
        displayName: "Data Leaks",
        desc: "View and manage data leaks.",
        hidden: !orgHasBreachSightEnabled,
        disabled: this.state.highlighted["ReadOnly"],
        checked:
          this.state.highlighted["BreachSight"] &&
          !this.state.highlighted["ReadOnly"],
        gapbelow: false,
      },
      {
        id: "QuestionnaireCreator",
        displayName: "Questionnaire Editor",
        desc: "Create and edit custom questionnaires.",
        hidden: !orgHasVendorRiskEnabled || !orgHasQuestionnaireBuilderEnabled,
        disabled: this.state.highlighted["ReadOnly"],
        checked:
          this.state.highlighted["QuestionnaireCreator"] &&
          !this.state.highlighted["ReadOnly"],
        gapbelow: false,
      },
      {
        id: "VendorAssessor",
        displayName: "Risk Assessor",
        desc: "View and manage risk assessments.",
        hidden: !orgHasVendorAssessmentsEnabled,
        disabled: this.state.highlighted["ReadOnly"],
        checked:
          this.state.highlighted["VendorAssessor"] &&
          !this.state.highlighted["ReadOnly"],
        gapbelow: false,
      },
      {
        id: "VendorsUnderManagementAdmin",
        displayName: "Vendor Management Admin",
        desc: "View and manage third party vendor management.",
        hidden: !orgHasThirdPartyVendorManagementEnabled,
        disabled: this.state.highlighted["ReadOnly"],
        checked:
          this.state.highlighted["VendorsUnderManagementAdmin"] &&
          !this.state.highlighted["ReadOnly"],
        gapbelow: false,
      },
    ];

    return (
      <div className="modal-content new-user-modal">
        <h2>Invite a user to {orgName}</h2>
        <p />
        <form onSubmit={this.onSubmit}>
          <label htmlFor="invitee_email">Email</label>
          <input
            type="text"
            name="invitee_email"
            placeholder="user@domain.com"
            required
            ref={(ref) => (this.email = ref)}
          />
          {!userRole && roleText()}
          {!userRole && <label htmlFor="roles">Additional Permissions</label>}
          {!userRole &&
            possibleRoles.map((r) => {
              if (r.hidden) {
                return null;
              }
              return (
                <div
                  key={r.id}
                  className={classnames("roleOption", { gapBelow: r.gapbelow })}
                >
                  <ColorCheckbox
                    label={r.displayName}
                    onClick={() => {
                      this.onRoleChange(r.id);
                    }}
                    checked={r.checked}
                    disabled={r.disabled}
                  />
                  <span
                    className={classnames("roleDesc", { disabled: r.disabled })}
                  >
                    {r.desc}
                  </span>
                </div>
              );
            })}
          <Button
            type="submit"
            filledPrimary
            loading={this.state.loading}
            disabled={this.state.loading}
          >
            Send invite
          </Button>
        </form>
      </div>
    );
  }
}
