export const getQualifiedBreachsightSubsidiariesReportName = (
  reportType: string,
  subsidiariesOnly: boolean
): string => {
  if (!subsidiariesOnly) {
    return reportType;
  }
  return reportType + "SubsOnly";
};

export const unQualifyBreachsightSubsidiariesReportName = (
  reportType: string
): string => {
  if (reportType.endsWith("SubsOnly")) {
    reportType = reportType.substr(0, reportType.length - 8);
  }
  return reportType;
};
