import { Dispatch } from "redux";
import { DefaultRootState } from "react-redux";
import { FetchCyberRiskUrl } from "../../_common/api";
import { ReportType } from "../../_common/types/exportReport";
import { setReportsData, setVendorData } from "./cyberRiskActions";
import {
  Filters,
  getQueryFromVendorLabelFilters,
  VendorLabelFilters,
} from "../components/filter/types";
import { LogError } from "../../_common/helpers";
import { getQualifiedBreachsightSubsidiariesReportName } from "../helpers/reportTypes";
import * as ReportTypes from "../constants/reportTypes";
import hash from "object-hash";
import { ILabel } from "../../_common/types/label";

export interface countryWithVendors {
  countryCode: string;
  country: string;
  percentage: number;
  vendors: {
    vendorId: number;
    name: string;
    primaryHostname: string;
  }[];
}

export interface countryForMap {
  country: string;
  countryCode: string;
  lat: number;
  long: number;
  // Each country should be represented by a float between 0 and 1, which will
  // determine its circle size between a minimum and maximum
  percentage: number;
  // Total shown in hover tooltips
  totalItems: number;
}

export interface fetchVendorsGeolocationReportDataResp {
  total: number;
  countriesWithVendors: countryWithVendors[];
  countriesForMap: {
    [countryId: string]: countryForMap;
  };
}

export const fetchVendorsGeolocationReport = (
  force: boolean,
  filters: VendorLabelFilters | undefined
) => {
  return async (
    dispatch: Dispatch,
    getState: () => DefaultRootState
  ): Promise<fetchVendorsGeolocationReportDataResp> => {
    if (
      !force &&
      getState().cyberRisk.reports?.[ReportType.ReportTypeVendorRiskGeolocation]
    ) {
      return getState().cyberRisk.reports[
        ReportType.ReportTypeVendorRiskGeolocation
      ] as fetchVendorsGeolocationReportDataResp;
    }

    dispatch(
      setReportsData(ReportType.ReportTypeVendorRiskGeolocation, undefined)
    );

    let resp: fetchVendorsGeolocationReportDataResp;
    try {
      resp = await FetchCyberRiskUrl(
        "geolocation/allvendors/v1/",
        {
          ...getQueryFromVendorLabelFilters(filters),
        },
        undefined,
        dispatch,
        getState
      );
    } catch (e) {
      LogError(`Error fetching geolocation report`, e);
      throw e;
    }

    dispatch(setReportsData(ReportType.ReportTypeVendorRiskGeolocation, resp));

    return resp;
  };
};

export const SET_GEOLOCATION_VENDORS_BY_COUNTRY =
  "SET_GEOLOCATION_VENDORS_BY_COUNTRY";

export interface watchedVendorWithIPData {
  id: number;
  numIPs: number;
  services: string[];
  name: string;
  latestScore: number;
  primaryHostname: string;
  labels: ILabel[];
}

export interface geolocationVendorsByCountryResp {
  country: string;
  countryCode: string;
  vendors: watchedVendorWithIPData[];
}

export const setGeolocationVendorsByCountry = (
  countryCode: string,
  result: geolocationVendorsByCountryResp
) => ({
  type: SET_GEOLOCATION_VENDORS_BY_COUNTRY,
  countryCode,
  result,
});

export const getGeolocationVendorsByCountryFilterHash = (
  filters: VendorLabelFilters | undefined,
  countryCode: string
) => `${filters ? hash(filters) + "/" : ""}${countryCode}`;

export const fetchGeolocationVendorsByCountry = (
  force: boolean,
  countryCode: string,
  filters: VendorLabelFilters | undefined
) => {
  return async (
    dispatch: Dispatch,
    getState: () => DefaultRootState
  ): Promise<geolocationVendorsByCountryResp> => {
    const filtersHash = getGeolocationVendorsByCountryFilterHash(
      filters,
      countryCode
    );
    if (
      !force &&
      getState().cyberRisk.vendorGeolocationByCountry[filtersHash]
    ) {
      return getState().cyberRisk.vendorGeolocationByCountry[
        filtersHash
      ] as geolocationVendorsByCountryResp;
    }

    let resp: geolocationVendorsByCountryResp;
    try {
      resp = await FetchCyberRiskUrl(
        "geolocation/allvendors/country/v1/",
        {
          country: countryCode,
          ...getQueryFromVendorLabelFilters(filters),
        },
        undefined,
        dispatch,
        getState
      );
    } catch (e) {
      LogError(`Error fetching geolocation vendors by country`, e);
      throw e;
    }

    dispatch(setGeolocationVendorsByCountry(filtersHash, resp));

    return resp;
  };
};

export interface countryWithNumIPs {
  country: string;
  countryCode: string;
  numIPs: number;
  percentage: number;
  services?: string[];
}

export interface singleVendorGeolocationResp {
  countriesWithIPs: countryWithNumIPs[];
  countriesForMap: {
    [countryId: string]: countryForMap;
  };
}

export const fetchSingleVendorGeolocationData = (
  force: boolean,
  isSubsidiary: boolean,
  vendorId: number,
  filters: Filters
) => {
  return async (
    dispatch: Dispatch,
    getState: () => DefaultRootState
  ): Promise<singleVendorGeolocationResp> => {
    if (!force && getState().cyberRisk.vendors[vendorId]?.geolocationData) {
      return getState().cyberRisk.vendors[vendorId]
        ?.geolocationData as singleVendorGeolocationResp;
    }

    let resp: singleVendorGeolocationResp;
    try {
      resp = await FetchCyberRiskUrl(
        "geolocation/vendor/v1/",
        {
          vendor_id: vendorId,
          website_label_ids: filters.websiteLabelIds,
          is_subsidiary: isSubsidiary,
          website_label_ids_match_all: filters.websiteLabelIdsMatchAll,
          website_label_ids_do_not_match: filters.websiteLabelIdsDoNotMatch,
          website_include_unlabeled: filters.websiteIncludeUnlabeled,
        },
        undefined,
        dispatch,
        getState
      );
    } catch (e) {
      LogError(`Error fetching geolocation data for single vendor`, e);
      throw e;
    }

    dispatch(
      setVendorData(
        vendorId,
        {
          geolocationData: resp,
        },
        isSubsidiary
      )
    );

    return resp;
  };
};

export interface customerGeolocationResp {
  countriesWithIPs: countryWithNumIPs[];
  countriesForMap: {
    [countryId: string]: countryForMap;
  };
}

export const fetchCustomerGeolocationReport = (
  force: boolean,
  filters: Filters
) => {
  return async (
    dispatch: Dispatch,
    getState: () => DefaultRootState
  ): Promise<customerGeolocationResp> => {
    if (
      !force &&
      getState().cyberRisk.reports?.[
        ReportType.ReportTypeBreachSightGeolocation
      ]
    ) {
      return getState().cyberRisk.reports[
        ReportType.ReportTypeBreachSightGeolocation
      ] as customerGeolocationResp;
    }

    dispatch(
      setReportsData(ReportType.ReportTypeBreachSightGeolocation, undefined)
    );

    let resp: customerGeolocationResp;
    try {
      resp = await FetchCyberRiskUrl(
        "geolocation/customer/v1/",
        {
          website_label_ids: filters.websiteLabelIds,
          website_portfolio_ids: filters.domainPortfolioIds,
        },
        undefined,
        dispatch,
        getState
      );
    } catch (e) {
      LogError(`Error fetching customer geolocation report`, e);
      throw e;
    }

    dispatch(setReportsData(ReportType.ReportTypeBreachSightGeolocation, resp));

    return resp;
  };
};

export interface customerSubsidiariesGeolocationResp {
  countriesWithSubsidiaries: countryWithVendors[];
  countriesForMap: {
    [countryId: string]: countryForMap;
  };
}

export const fetchCustomerWithSubsidiariesGeolocationReport = (
  force: boolean,
  filters: Filters,
  isSubsidiariesOnly: boolean
) => {
  return async (
    dispatch: Dispatch,
    getState: () => DefaultRootState
  ): Promise<customerSubsidiariesGeolocationResp> => {
    const reportType = getQualifiedBreachsightSubsidiariesReportName(
      ReportTypes.BreachSightSubsidiariesGeolocation,
      isSubsidiariesOnly
    );

    if (!force && (getState().cyberRisk.reports as any)?.[reportType]) {
      return (getState().cyberRisk.reports as any)[
        reportType
      ] as customerSubsidiariesGeolocationResp;
    }

    dispatch(setReportsData(reportType, undefined));

    let resp: customerSubsidiariesGeolocationResp;
    try {
      resp = await FetchCyberRiskUrl(
        "geolocation/subsidiaries/v1/",
        {
          website_label_ids: filters.websiteLabelIds,
          website_portfolio_ids: filters.domainPortfolioIds,
          subsidiary_label_ids: filters.subsidiaryLabelIds,
          subsidiary_ids: filters.subsidiaryIds,
          min_score: filters.subsidiaryMinScore,
          max_score: filters.subsidiaryMaxScore,
          subsidiaries_only: isSubsidiariesOnly,
        },
        undefined,
        dispatch,
        getState
      );
    } catch (e) {
      LogError(`Error fetching customer geolocation report`, e);
      throw e;
    }

    dispatch(setReportsData(reportType, resp));

    return resp;
  };
};
