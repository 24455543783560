import { AppForEdit, Scope } from "../../userbase/api/types";
import BaseAPI from "../rtkQueryApi";
import { FeatureFlag } from "../types/features";

export enum AdminTagTypes {
  featureFlags = "featureFlags",
  userRiskScopes = "userRiskScopes",
  userRiskApps = "userRiskApps",
}

export interface GetFeatureFlagsV1Response {
  features: FeatureFlag[];
}

export interface GetFeatureFlagsV1Params {
  orgId: number;
}

export interface GetUserRiskScopesV1Response {
  scopes: Scope[];
}

export interface UserRiskScopeUpdate {
  externalId: string;
  riskLevel: number;
  category: string | null;
  isReadWrite: boolean | null;
}

export interface UpdateUserRiskScopesV1Req {
  updates: UserRiskScopeUpdate[];
}

export interface GetUserRiskAppsV1Response {
  apps: AppForEdit[];
}

export interface UserRiskAppUpdate {
  name: string;
  domain: string | null;
  summary: string;
  description: string;
}

export interface UpdateUserRiskAppsV1Req {
  updates: UserRiskAppUpdate[];
}

export const AdminAPI = BaseAPI.enhanceEndpoints({
  addTagTypes: [...Object.values(AdminTagTypes)],
}).injectEndpoints({
  endpoints: (builder) => ({
    getFeatureFlagsV1: builder.query<
      GetFeatureFlagsV1Response,
      GetFeatureFlagsV1Params
    >({
      providesTags: [AdminTagTypes.featureFlags],
      query: ({ orgId }) => ({
        url: "/admin/features/v1/",
        method: "GET",
        params: {
          org_id: orgId,
        },
      }),
    }),

    // Get all the UserRisk scopes so we can display for possible edits
    getUserRiskScopesV1: builder.query<GetUserRiskScopesV1Response, unknown>({
      providesTags: [AdminTagTypes.userRiskScopes],
      query: () => ({
        url: "/admin/userrisk/scopes/v1/",
        method: "GET",
      }),
    }),

    // Update UserRisk's scope information
    updateUserRiskScopesV1: builder.mutation<void, UpdateUserRiskScopesV1Req>({
      query: (req) => ({
        url: "/admin/userrisk/scopes/v1/",
        method: "PUT",
        body: JSON.stringify(req),
      }),
    }),

    // Get all the UserRisk apps so we can display for possible edits
    getUserRiskAppsV1: builder.query<GetUserRiskAppsV1Response, unknown>({
      providesTags: [AdminTagTypes.userRiskApps],
      query: () => ({
        url: "/admin/userrisk/apps/v1/",
        method: "GET",
      }),
    }),

    // Update UserRisk's app information
    updateUserRiskAppsV1: builder.mutation<void, UpdateUserRiskAppsV1Req>({
      query: (req) => ({
        url: "/admin/userrisk/apps/v1/",
        method: "PUT",
        body: JSON.stringify(req),
      }),
    }),
  }),
});
