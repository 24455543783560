import BaseAPI from "../rtkQueryApi";

enum OAuthTagTypes {}

export interface ProcessOAuth2ClientCredentialsV1Req {
  tenantId: string;
  clientId: string;
  clientSecret: string;
  // we only support client credentials flow for microsoft 365 for now
  service: "Microsoft365ClientCredentials";
  isUserRisk: boolean;
}

const OAuthAPI = BaseAPI.enhanceEndpoints({
  addTagTypes: [...Object.values(OAuthTagTypes)],
}).injectEndpoints({
  endpoints: (builder) => ({
    processOAuth2ClientCredentialsV1: builder.mutation<
      void,
      ProcessOAuth2ClientCredentialsV1Req
    >({
      query: (req) => ({
        url: "/oauth2/client_credentials",
        method: "POST",
        body: JSON.stringify(req),
      }),
    }),
  }),
});

export default OAuthAPI;
