import { FC } from "react";
import UserBaseAPI from "../api/userbase.api";
import { AppRouteParams } from "../UserBaseNavItems";
import { useRouteMatch } from "react-router-dom";
import ReportCard from "../../_common/components/ReportCard";
import LoadingBanner from "../../_common/components/core/LoadingBanner";
import Button from "../../_common/components/core/Button";
import { appSummaryUrl, appUsersUrl } from "../UserBaseAppRouter";
import { useDefaultHistory } from "../../_common/types/router";
import "./AppUsersOverview.scss";
import Doughnut from "./Doughnut";

export enum UserAppStatusColor {
  Approved = "#63C78E", // $green400
  NotApproved = "#F15F5F", // $red400
  Waived = "#CCCFE0", // $grey900
}

const AppUsersOverview: FC = ({}) => {
  const match = useRouteMatch<AppRouteParams>();
  const history = useDefaultHistory();

  const appName = match.params.appName;

  const { data, isLoading } = UserBaseAPI.useGetUserBaseAppSummaryV1Query({
    app: appName,
  });

  const onViewUsers = () => {
    history.push(appUsersUrl(appName), {
      backContext: {
        backTo: appSummaryUrl(appName),
        backToText: `Back to ${appName}`,
      },
    });
  };

  if (!isLoading && !data?.app) {
    return <></>;
  }

  const doughnutData = data
    ? [
        {
          label: "Approved",
          count:
            data.app.numCurrentUsers -
            data.app.numUnapprovedUsers -
            data.app.numWaivedUsers,
          hexColor: UserAppStatusColor.Approved,
        },
        {
          label: "Not approved",
          count: data.app.numUnapprovedUsers,
          hexColor: UserAppStatusColor.NotApproved,
        },
        {
          label: "Waived",
          count: data.app.numWaivedUsers,
          hexColor: UserAppStatusColor.Waived,
        },
      ]
    : [];

  return (
    <ReportCard newStyles className="userbase-app-users-overview">
      <div className="header">
        <span>Users</span>
        <div className={"header-link"}>
          <Button tertiary onClick={onViewUsers}>
            View all <div className="cr-icon-arrow-right" />
          </Button>
        </div>
      </div>
      {isLoading && <LoadingBanner />}
      {!isLoading && data && (
        <div className={"content"}>
          <Doughnut data={doughnutData} totalLabel={"Total users"} />
        </div>
      )}
    </ReportCard>
  );
};

export default AppUsersOverview;
