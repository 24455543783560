import { Suspense } from "react";
import PropTypes from "prop-types";
import { Router, Switch, Route, Redirect } from "react-router-dom";
import Modal from "../components/Modal";
import lazyLoad from "../lazyload";
import { canAccessSystemAdminFromUserData } from "../selectors/permissions";
import LoadingBanner from "../components/core/LoadingBanner";
import { OrgAccessAppGuard } from "../permissions";
import UserBaseAppRouter from "../../userbase/UserBaseAppRouter";
import { OrgAccessUserBase } from "../permissions";
import VendorQuestionnaireAnswers from "../../vendor_portal/views/VendorQuestionnaireAnswers";
import {
  getUserRemediationRequests,
  getUserRiskAcceptanceTasks,
  getUserVendorRiskWaiverTasks,
} from "../helpers/userOrg.helpers";
import {
  breachRiskTaskUrlPrefix,
  tasksRouterUrlPrefix,
  trustExchangeTaskUrlPrefix,
  userRiskTaskUrlPrefix,
  vendorRiskTaskUrlPrefix,
} from "../routers/TasksRouter";
import { WaiverType } from "../types/vendorRiskWaivers";
import { RiskAssetType } from "../../vendorrisk/reducers/customerAcceptedRisks.actions";
import { RemediationType } from "../types/remediation";

// Lazy imports to support code splitting
const DistributorRouter = lazyLoad(
  () => import("../../admin_portal/DistributorRouter")
);
const VendorAppRouterWrapper = lazyLoad(
  () => import("../../vendor_portal/VendorAppRouter")
);
const AdminRouter = lazyLoad(() => import("../../admin_portal/AdminRouter"));
const AnalystAppRouter = lazyLoad(
  () => import("../../analyst_portal/AnalystAppRouter")
);

const UserSettings = lazyLoad(() => import("../components/UserSettings"));
const NonCustomerSharedAssessmentsList = lazyLoad(
  () => import("../views/NonCustomerSharedAssessmentsList")
);
const NonCustomerSingleSharedProfile = lazyLoad(
  () => import("../views/NonCustomerSingleSharedProfile")
);
const SharedProfileSurveyDetails = lazyLoad(
  () => import("../views/SharedProfileSurveyDetails")
);
const PublicQuestionnaireAnswers = lazyLoad(
  () => import("../../vendorrisk/views/PublicQuestionnaireAnswers")
);
const CyberRiskRouter = lazyLoad(
  () => import("../../vendorrisk/CyberRiskRouter")
);
const ReportExportsV2 = lazyLoad(
  () => import("../../vendorrisk/views/reporting/ReportExportsV2")
);
const ResendRequestedInvite = lazyLoad(
  () => import("../../vendorrisk/components/ResendRequestedInvite")
);
const QuickStart = lazyLoad(() => import("../../_common/views/QuickStart"));
const DisabledUserFallback = lazyLoad(() => import("./DisabledUserFallback"));
const AppGuardAppRouterWrapper = lazyLoad(
  () => import("../../appguard/AppGuardAppRouter")
);

const QuestionnaireCollaboration = lazyLoad(
  () => import("../../vendorrisk/views/QuestionnaireCollaboration.view")
);
const SurveyDetails = lazyLoad(() => import("../views/SurveyDetails"));
const ViewQuestionnaires = lazyLoad(
  () => import("../../vendorrisk/views/OrgVendorQuestionnaireAnswers")
);

const TasksRouter = lazyLoad(() => import("../routers/TasksRouter"));

// CommonRouter is an overarching router encompassing all views for the new sidebar
// style of navigation. Sub-routers are included when user roles/permissions should
// allow access to their screens. The CommonRouter is mounted when the initial auth
// and fetch of user data is completed.
const CommonRouter = ({
  history,
  userData,
  permissions,
  dispatch,
  userTaskData,
}) => {
  const {
    currentOrgRoles,
    hasSurveyCollaboration,
    hasAdditionalEvidenceRequests,
    hasSharedAssessmentsAccess,
    isAnalyst,
    orgList,
    hasRelationshipQuestionnaires,
  } = userData;

  const hasSecurityQuestionnaires = userData.hasAssessments;

  const isVendorMgtAnalyst =
    !!userData.system_roles &&
    userData.system_roles.includes("VendorManagementAnalyst");

  const routes = [];

  routes.push(
    <Route
      key="resend-invite"
      path="/resend-invite/:inviteId"
      render={(routeProps) => <ResendRequestedInvite {...routeProps} />}
    />
  );

  // if the user's current role in it's current org is distributor then limit the user to the distributors
  // route only.
  if (currentOrgRoles && currentOrgRoles.includes("Distributor")) {
    routes.push(
      <Route
        key="distributor"
        path="/distributor"
        component={DistributorRouter}
      />
    );
  } else {
    routes.push(<Redirect key="distributor" from="/distributor" to="/" />);
  }

  routes.push(
    <Route
      key="vendor_portal"
      path="/vendors"
      component={VendorAppRouterWrapper}
    />
  );

  if (userData.orgPermissions.includes(OrgAccessAppGuard)) {
    routes.push(
      <Route
        key="appguard"
        path="/appguard"
        component={AppGuardAppRouterWrapper}
      />
    );
  }

  if (userData.orgPermissions.includes(OrgAccessUserBase)) {
    routes.push(
      <Route key="userbase" path="/userbase" component={UserBaseAppRouter} />
    );
  }

  if (canAccessSystemAdminFromUserData(userData)) {
    routes.push(<Route key="admin" path="/admin" component={AdminRouter} />);
  } else {
    routes.push(<Redirect key="admin" from="/admin" to="/" />);
  }

  if (isAnalyst || isVendorMgtAnalyst) {
    routes.push(
      <Route key="analysts" path="/analysts" component={AnalystAppRouter} />
    );
  } else {
    routes.push(<Redirect key="analysts" from="/analysts" to="/" />);
  }

  routes.push(<Route key="profile" path="/profile" component={UserSettings} />);

  routes.push(
    <Route key="quickstart" path="/quickstart" component={QuickStart} />
  );

  routes.push(
    <Route
      key="tasks"
      path={`/${tasksRouterUrlPrefix}`}
      component={TasksRouter}
    />
  );

  if (currentOrgRoles && currentOrgRoles.includes("Distributor")) {
    routes.push(<Redirect key="distrib_redirect" from="/" to="/distributor" />);
  } else if (orgList && orgList.length > 0) {
    routes.push(
      <Route
        key="cyber_risk"
        render={(routeProps) => (
          <CyberRiskRouter
            dispatch={dispatch}
            userData={userData}
            permissions={permissions}
            {...routeProps}
          />
        )}
      />
    );
  } else {
    if (userData.currentOrgID === 0) {
      routes.push(
        <Route
          key="reports_tab"
          path="/reportexports/:currentTab"
          component={ReportExportsV2}
        />
      );
      routes.push(
        <Route
          key="reports"
          path="/reportexports"
          component={ReportExportsV2}
        />
      );

      // if they don't have an org but are added as a collaborator to a survey make sure the route is included

      if (hasSurveyCollaboration) {
        routes.push(
          <Route
            path={`/surveycollaboration/:id/answers`}
            component={ViewQuestionnaires}
          />
        );
        routes.push(
          <Route
            path={"/surveycollaboration/:id"}
            render={(routeProps) => (
              <SurveyDetails
                isVendorPortal={false}
                isCollaboratorPortal
                {...routeProps}
              />
            )}
          />
        );
        routes.push(
          <Route
            path={"/surveycollaboration"}
            component={QuestionnaireCollaboration}
          />
        );
      }
    }

    // Add a special route for non-customers to access shared assessments.
    routes.push(
      <Route
        key="sharedassessmentsurveyanswers"
        path="/sharedassessments/:vendorId/surveys/:id/answers"
        component={PublicQuestionnaireAnswers}
      />,
      <Route
        key="sharedassessmentsurvey"
        path="/sharedassessments/:vendorId/surveys/:id"
        component={SharedProfileSurveyDetails}
      />,
      <Route
        key="sharedassessment"
        path="/sharedassessments/:vendorId"
        component={NonCustomerSingleSharedProfile}
      />,
      <Route
        key="sharedassessmentslist"
        path="/sharedassessments"
        component={NonCustomerSharedAssessmentsList}
      />
    );

    // Check if we have any remediation requests linked to the user
    const breachRiskRemediation = getUserRemediationRequests(
      userTaskData ?? {},
      true,
      true,
      [RemediationType.Self, RemediationType.Subsidiary],
      [RemediationType.Self, RemediationType.Subsidiary]
    );

    const hasBreachRiskRemediation =
      breachRiskRemediation?.currentOrgRequestIDs?.length > 0 ||
      breachRiskRemediation?.currentOrgRequestIDs?.length > 0;

    const vendorRiskRemediation = getUserRemediationRequests(
      userTaskData ?? {},
      true,
      true,
      [RemediationType.Vendor],
      [RemediationType.Vendor]
    );

    const hasVendorRiskRemediation =
      vendorRiskRemediation?.currentOrgRequestIDs?.length > 0 ||
      vendorRiskRemediation?.otherOrgRequestIDs?.length > 0;

    const riskAcceptanceApprovals = getUserRiskAcceptanceTasks(
      userTaskData ?? {},
      true,
      true,
      [RiskAssetType.userbaseUser, RiskAssetType.cloudscan],
      [RiskAssetType.userbaseUser, RiskAssetType.cloudscan]
    );

    const riskWaiverApprovals = getUserVendorRiskWaiverTasks(
      userTaskData ?? {},
      true,
      true,
      [WaiverType.RiskWaiver],
      [WaiverType.RiskWaiver]
    );

    const breachRiskRiskAcceptance = getUserRiskAcceptanceTasks(
      userTaskData ?? {},
      true,
      true,
      [RiskAssetType.cloudscan],
      [RiskAssetType.cloudscan]
    );

    const userRiskRiskAcceptance = getUserRiskAcceptanceTasks(
      userTaskData ?? {},
      true,
      true,
      [RiskAssetType.userbaseUser],
      [RiskAssetType.userbaseUser]
    );

    const hasBreachRiskRiskAcceptance =
      breachRiskRiskAcceptance?.currentOrgRiskAcceptanceRiskIDs?.length > 0 ||
      breachRiskRiskAcceptance?.otherOrgRiskAcceptanceRiskIDs?.length > 0;
    const hasUserRiskRiskAcceptance =
      userRiskRiskAcceptance?.currentOrgRiskAcceptanceRiskIDs?.length > 0 ||
      userRiskRiskAcceptance?.otherOrgRiskAcceptanceRiskIDs?.length > 0;
    const hasVendorRiskRiskWaivers =
      riskWaiverApprovals?.currentOrgRiskWaiverIDs?.length > 0 ||
      riskWaiverApprovals?.otherOrgRiskWaiverIDs?.length > 0;

    const riskAdjustmentApprovals = getUserVendorRiskWaiverTasks(
      userTaskData ?? {},
      true,
      true,
      [WaiverType.SeverityAdjustment],
      [WaiverType.SeverityAdjustment]
    );

    const hasAdjustedRisks =
      riskAdjustmentApprovals?.currentOrgRiskWaiverIDs?.length > 0 ||
      riskAdjustmentApprovals?.otherOrgRiskWaiverIDs?.length > 0;

    // User is most likely a vendor user, but they might just be a super admin or an analyst without any org.
    let redirectTo;
    if (hasSecurityQuestionnaires || hasRelationshipQuestionnaires) {
      // Send them to TE questionnaires if they have them, otherwise to VR relationship questionnaires.
      if (hasSecurityQuestionnaires) {
        redirectTo = `/${tasksRouterUrlPrefix}/${trustExchangeTaskUrlPrefix}/questionnaires`;
      } else {
        redirectTo = `/${tasksRouterUrlPrefix}/${vendorRiskTaskUrlPrefix}/questionnaires`;
      }
    } else if (hasSurveyCollaboration) {
      redirectTo = "/surveycollaboration";
    } else if (hasBreachRiskRemediation) {
      redirectTo = `/${tasksRouterUrlPrefix}/${breachRiskTaskUrlPrefix}/remediation`;
    } else if (hasVendorRiskRemediation) {
      redirectTo = `/${tasksRouterUrlPrefix}/${trustExchangeTaskUrlPrefix}/remediation`;
    } else if (hasVendorRiskRiskWaivers) {
      redirectTo = `/${tasksRouterUrlPrefix}/${vendorRiskTaskUrlPrefix}/riskwaiverapprovals`;
    } else if (hasBreachRiskRiskAcceptance) {
      redirectTo = `/${tasksRouterUrlPrefix}/${breachRiskTaskUrlPrefix}/riskwaiverapprovals`;
    } else if (hasUserRiskRiskAcceptance) {
      redirectTo = `/${tasksRouterUrlPrefix}/${userRiskTaskUrlPrefix}/riskwaiverapprovals`;
    } else if (hasSharedAssessmentsAccess) {
      redirectTo = "/sharedassessments";
    } else if (hasAdditionalEvidenceRequests) {
      redirectTo = `/${tasksRouterUrlPrefix}/${trustExchangeTaskUrlPrefix}/additionalevidence`;
    } else if (hasAdjustedRisks) {
      redirectTo = `/${tasksRouterUrlPrefix}/${vendorRiskTaskUrlPrefix}/riskadjustmentapprovals`;
    } else if (isAnalyst) {
      redirectTo = "/analysts/dataleaks/activecustomers";
    } else if (userData.system_roles && userData.system_roles.length > 0) {
      // They're some kind of staff user with access to the admin screen.
      redirectTo = "/admin";
    } else {
      // This is a user with no org and no access to anything special. Most likely a user who was in a now-disabled account.
      // Take them to a special screen for nobodies.
      routes.push(
        <Route
          key="disabledfallback"
          path="/disabled"
          component={DisabledUserFallback}
        />
      );
      redirectTo = "/disabled";
    }

    routes.push(<Redirect key="cyber_risk_root" from="/" to={redirectTo} />);
  }

  return (
    <>
      <Suspense fallback={<LoadingBanner />}>
        <Switch>{routes}</Switch>
      </Suspense>
      <Modal history={history} />
    </>
  );
};

CommonRouter.propTypes = {
  history: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  userData: PropTypes.object.isRequired,
  userTaskData: PropTypes.object,
};

export default CommonRouter;
