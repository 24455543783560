import BaseAPI from "../../_common/rtkQueryApi";
import { UserEmailAddress } from "../../_common/types/user";

enum ImpersonationTagTypes {
  impersonateUsers = "impersonateUsers",
}

interface ListImpersonateOrgUsersV1Params {
  orgId: number;
}

export interface ListImpersonateOrgUsersV1Response {
  users: UserEmailAddress[];
}

const ImpersonationAPI = BaseAPI.enhanceEndpoints({
  addTagTypes: [...Object.values(ImpersonationTagTypes)],
}).injectEndpoints({
  endpoints: (builder) => ({
    listImpersonateOrgUsersV1: builder.query<
      ListImpersonateOrgUsersV1Response,
      ListImpersonateOrgUsersV1Params
    >({
      providesTags: [ImpersonationTagTypes.impersonateUsers],
      query: ({ orgId }) => ({
        url: "/accounts/impersonateorg/users/v1/",
        method: "GET",
        params: {
          new_org_id: orgId,
        },
      }),
    }),
  }),
});

export default ImpersonationAPI;
