import { usePagination } from "../../_common/hooks";
import ReportCard from "../../_common/components/ReportCard";
import { formatDateAsLocal, formatTimeAsLocal } from "../../_common/helpers";
import XTable, {
  IXTableRow,
  XTableCell,
} from "../../_common/components/core/XTable";
import Icon from "../../_common/components/core/Icon";

import { UserRouteParams } from "../UserBaseNavItems";
import UserBaseAPI from "../api/userbase.api";
import { AppStatus, UserEvent, UserEventType } from "../api/types";
import "./Timeline.scss";

import { useRouteMatch } from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { FC, useState } from "react";
import _ from "lodash";

const UserTimelineCell: FC<UserEvent> = ({
  eventType,
  metadata,
  triggeringName,
}) => {
  let action: string = "";
  switch (eventType) {
    case UserEventType.UserFirstDetected: {
      action = "User was first detected";
      break;
    }

    case UserEventType.UserAppStatusChange: {
      switch (metadata.status) {
        case AppStatus.ApprovedAppStatus: {
          action = `${triggeringName} approved ${metadata.appName}`;
          break;
        }
        case AppStatus.NotApprovedAppStatus: {
          action = `${triggeringName} marked ${metadata.appName} as not approved`;
          break;
        }
        case AppStatus.InReviewAppStatus: {
          break;
        }
        case AppStatus.NeedsReviewAppStatus: {
          break;
        }
      }

      break;
    }

    case UserEventType.UserRiskWaiverRequested: {
      action = `Risk waiver requested by ${triggeringName} for app ${metadata.appName}`;
      const reason = metadata.requesterReason;
      if (reason) {
        action += ` with reason: ${reason}`;
      }
      break;
    }

    case UserEventType.UserRiskWaiverApproved: {
      action = `Risk waiver approved by ${triggeringName} for app ${metadata.appName}`;
      const reason = metadata.approverReason;
      if (reason) {
        action += ` with reason: ${reason}`;
      }
      break;
    }

    case UserEventType.UserNewApp: {
      action = `Started using app ${metadata.appName}`;
      break;
    }
  }

  return <XTableCell key={"action"}>{action}</XTableCell>;
};

const uniqueKey = (e: UserEvent): string => {
  switch (e.eventType) {
    case UserEventType.UserFirstDetected: {
      return `firstDetected-${e.at}`;
    }

    case UserEventType.UserAppStatusChange: {
      return `appStatusChange-${e.at}-${e.metadata.status}-${e.metadata.appName}`;
    }

    case UserEventType.UserNewApp: {
      return `userNewApp-${e.at}-${e.metadata.appName}`;
    }

    default: {
      return e.at;
    }
  }
};

const UserTimeline: FC = ({}) => {
  const match = useRouteMatch<UserRouteParams>();
  const [isCollapsed, setIsCollapsed] = useState(true);

  const { data, isLoading } = UserBaseAPI.useGetUserBaseUserTimelineV1Query({
    userUUID: match.params.userUUID,
  });

  const [currentPage, currentPageNumber, totalPages, setCurrentPage] =
    usePagination(
      (data?.events ?? []).slice().sort((a, b) => (a.at < b.at ? 1 : -1)),
      10
    );

  const intoXTableRow = (e: UserEvent): IXTableRow<string> => {
    return {
      id: uniqueKey(e),
      cells: [
        <XTableCell key={"date"}>{formatDateAsLocal(e.at)}</XTableCell>,
        <XTableCell key={"time"}>{formatTimeAsLocal(e.at)}</XTableCell>,
        UserTimelineCell(e),
      ],
    };
  };

  if (!isLoading && currentPage.length == 0) {
    return <></>;
  }

  return (
    <ReportCard newStyles className="userbase-event-timeline">
      <div className="header" onClick={() => setIsCollapsed(!isCollapsed)}>
        Timeline
        <div className={"header-chevron"}>
          <Icon name="chevron" direction={isCollapsed ? 180 : 0} />
        </div>
      </div>
      <div className={"timeline-container"}>
        <TransitionGroup component={null}>
          {!isCollapsed && (
            <CSSTransition
              key={"expand-timeline"}
              timeout={250}
              classNames="expand"
            >
              <XTable
                stickyColumnHeaders={false}
                numLoadingRows={3}
                className={"event-timeline-table"}
                columnHeaders={[
                  {
                    id: "date",
                    text: "Date",
                    sortable: false,
                    className: "date-col",
                  },
                  {
                    id: "time",
                    text: "Time",
                    sortable: false,
                    className: "time-col",
                  },
                  {
                    id: "action",
                    text: "Action",
                    sortable: false,
                  },
                ]}
                rows={currentPage.map(intoXTableRow)}
                loading={isLoading}
                pagination={{
                  currentPage: currentPageNumber,
                  totalPages,
                  onPageChange: setCurrentPage,
                  hidePaginationIfSinglePage: true,
                }}
              />
            </CSSTransition>
          )}
        </TransitionGroup>
      </div>
    </ReportCard>
  );
};

export default UserTimeline;
