import { FC, ReactNode, useEffect, useMemo, useState } from "react";
import LoadingBanner from "../../../_common/components/core/LoadingBanner";
import ReportCard from "../../../_common/components/ReportCard";
import {
  IAnswerValue,
  IAutomationQuestion,
  IAutomationRecipe,
  IAutomationSimpleRule,
  IAutomationTest,
  IAutomationTestOperator,
  IAutomationTreeNode,
  IAutomationTreeNodeOperator,
} from "../../types/automation";
import {
  AutomationAttributeTypes,
  DataEntryErrorAttribute,
  EditQuestionnairePages,
  QuestionTypes,
} from "../../views/EditQuestionnaireAutomation";
import EmptyCardWithAction from "../../../_common/components/EmptyCardWithAction";
import LightningSVG from "../../images/automation_lightning_icon.svg";
import "../../style/components/automation/DefineRulesStep.scss";
import PillLabel from "../PillLabel";
import {
  ILabel,
  LabelClassification,
  LabelColor,
} from "../../../_common/types/label";
import Icon from "../../../_common/components/core/Icon";
import DropdownV2, {
  DropdownItem,
} from "../../../_common/components/core/DropdownV2";
import AddRuleButton from "./AddRuleButton";
import VendorTierBadge from "../vendor_tiers/VendorTierBadge";
import {
  fetchVendorTiers,
  VendorTier,
} from "../../reducers/vendorTiers.actions";
import { DefaultThunkDispatchProp } from "../../../_common/types/redux";

import {
  fetchVendorPortfolios,
  Portfolio,
} from "../../reducers/portfolios.actions";
import { sortLabels } from "../labels/LabelChoiceSet";
import { fetchAvailableLabels } from "../../reducers/cyberRiskActions";
import classNames from "classnames";
import classnames from "classnames";
import {
  fetchVendorAttributeDefinitions,
  VendorAttributeDefinition,
  VendorAttributeDefinitionType,
} from "../../reducers/vendorAttributes.actions";
import { useConfirmationModalV2 } from "../../../_common/components/modals/ConfirmationModalV2";
import { SelectV2 } from "../../../_common/components/SelectV2";
import { SidePopupV2 } from "../../../_common/components/DismissablePopup";
import ColorCheckbox from "../ColorCheckbox";
import TextField from "../../../_common/components/TextField";
import { CSSTransition } from "react-transition-group";
import Button from "../../../_common/components/core/Button";
import { appConnect } from "../../../_common/types/reduxHooks";

const NOANSWERID = "__NO_ANSWER__";
const SMALLSCREEN_THRESHOLD = 1100;
const SMALLSCREEN_TEXT_PROMPT_THRESHOLD = 1413;
const SMALLSCREEN_TEXT_PROMPT_INDENTED_THRESHOLD = 1610;

export const operatorIsForStrings = (op: IAutomationTestOperator): boolean => {
  return (
    op == IAutomationTestOperator.CONTAINS ||
    op == IAutomationTestOperator.PREFIX ||
    op == IAutomationTestOperator.SUFFIX
  );
};

interface IDefineRulesStepOwnProps {
  loading: boolean;
  isEditing: boolean;
  surveyTypeId: number;
  automation?: IAutomationRecipe;
  questions?: IAutomationQuestion[];
  setDataEntryErrorFunc: (
    page: number,
    id: string,
    attribute: DataEntryErrorAttribute,
    msg: string
  ) => void;
  deleteDataEntryErrorFunc: (
    page: number,
    id: string,
    attribute: DataEntryErrorAttribute
  ) => void;
  retrieveDataEntryErrorFunc: (
    page: number,
    id: string,
    attribute: DataEntryErrorAttribute
  ) => string | undefined;
  userHasWriteAutomationEnabled: boolean;
  addNewRuleAfter: (idx: number) => void;
  removeRule: (uuid: string) => void;
  setOperatorForBranch: (
    ruleUUID: string,
    branchUUID: string,
    op: IAutomationTreeNodeOperator
  ) => void;
  setTargetValueForRule: (
    ruleUUID: string,
    targetValue: string,
    isNumeric: boolean
  ) => void;
  setTargetValueUsesAnswer: (ruleUUID: string, useAnswer: boolean) => void;
  setTargetValueAnswerId: (ruleUUID: string, answerId: string) => void;
  setQuestionForTest: (
    ruleUUID: string,
    testUUID: string,
    questionId: string,
    isMultiSelect: boolean
  ) => void;
  setOperatorForTest: (
    ruleUUID: string,
    testUUID: string,
    operator: IAutomationTestOperator
  ) => void;
  setValueForTest: (
    ruleUUID: string,
    testUUID: string,
    value: string,
    notAnswered: boolean
  ) => void;
  addTestToRuleNode: (ruleUUID: string, nodeUUID: string) => void;
  addBranchToRule: (ruleUUID: string, nodeUUID: string) => void;
  removeBranchForRule: (ruleUUID: string, branchUUID: string) => void;
  removeTestForRule: (ruleUUID: string, testUUID: string) => void;
  moveTestFromNodeToNode: (
    testUUID: string,
    fromRuleUUID: string,
    toRuleUUID: string,
    toNodeUUID: string
  ) => void;
  moveBranchFromNodeToNode: (
    branchUUID: string,
    fromRuleUUID: string,
    toRuleUUID: string,
    toNodeUUID: string
  ) => void;
  moveRule: (fromIdx: number, toIdx: number) => void;
  automationCustomAttribute?: VendorAttributeDefinition;
}

interface IDefineRulesStepConnectedProps {
  loading: boolean;
  error?: IError | null;
  tiers?: VendorTier[];
  portfolios?: Portfolio[];
  labels?: ILabel[];
  vendorAttributeDefinitions?: VendorAttributeDefinition[];
}

type IDefineRulesStepProps = DefaultThunkDispatchProp &
  IDefineRulesStepOwnProps &
  IDefineRulesStepConnectedProps;

const DefineRulesStep: FC<IDefineRulesStepProps> = ({
  dispatch,
  loading,
  isEditing,
  questions,
  automation,
  tiers,
  portfolios,
  labels,
  userHasWriteAutomationEnabled,
  addNewRuleAfter,
  setDataEntryErrorFunc,
  deleteDataEntryErrorFunc,
  retrieveDataEntryErrorFunc,
  removeRule,
  setOperatorForBranch,
  setTargetValueForRule,
  setQuestionForTest,
  setOperatorForTest,
  setValueForTest,
  addTestToRuleNode,
  addBranchToRule,
  removeBranchForRule,
  removeTestForRule,
  moveTestFromNodeToNode,
  moveBranchFromNodeToNode,
  moveRule,
  automationCustomAttribute,
  setTargetValueUsesAnswer,
  setTargetValueAnswerId,
}) => {
  const [isAddingLogic, setIsAddingLogic] = useState(false);
  const [isRemovingLogic, setIsRemovingLogic] = useState(false);
  const [dragUUID, setDragUUID] = useState("");
  const [dragType, setDragType] = useState("");
  const [dragOverRule, setDragOverRule] = useState("");
  const [draggingFrom, setDraggingFrom] = useState("");
  const [draggingFromRule, setDraggingFromRule] = useState("");
  const [dragRuleIdx, setDragRuleIdx] = useState(-1);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [openConfirmationModal, confirmationModal] = useConfirmationModalV2();

  const resize = () => {
    setScreenWidth(window.innerWidth);
  };

  useEffect(() => {
    dispatch(fetchVendorTiers());
    dispatch(fetchVendorPortfolios());
    dispatch(fetchAvailableLabels());
    dispatch(fetchVendorAttributeDefinitions());

    window.addEventListener("resize", resize.bind(this));
  }, []);
  const questionCache = useMemo(() => {
    const cache = {} as {
      [qId: string]: IAutomationQuestion;
    };
    questions?.map((q) => {
      cache[q.id] = q;
    });
    return cache;
  }, [questions]);

  const assignActionLabel = useMemo(() => {
    switch (automation?.targetAttribute) {
      case AutomationAttributeTypes.Tier:
        return "Assign vendor tier*";
        break;
      case AutomationAttributeTypes.Portfolios:
        return "Assign vendor portfolio*";
        break;
      case AutomationAttributeTypes.Labels:
        return "Assign vendor label*";
        break;
      case AutomationAttributeTypes.Custom:
        return `Assign vendor attribute: ${
          automationCustomAttribute?.name || ""
        }*`;
        break;
    }
    return "unknown";
  }, [automationCustomAttribute, automation]);

  const deleteActionLabel = useMemo(() => {
    switch (automation?.targetAttribute) {
      case AutomationAttributeTypes.Tier:
        return "vendor tier assignment";
        break;
      case AutomationAttributeTypes.Portfolios:
        return "vendor portfolio assignment";
        break;
      case AutomationAttributeTypes.Labels:
        return "vendor label assignment";
        break;
      case AutomationAttributeTypes.Custom:
        return `vendor attribute assignment`;
        break;
    }
    return "unknown";
  }, [automationCustomAttribute, automation]);

  const addRuleAfter = async (idx: number) => {
    setIsAddingLogic(true);
    addNewRuleAfter(idx);
    setIsAddingLogic(false);
  };

  const deleteRule = async (ruleUUID: string) => {
    // get the action description we're deleting..
    openConfirmationModal({
      title: `Delete action - ${deleteActionLabel}`,
      description:
        "This action will be permanently deleted, and no longer available.",
      dangerousAction: true,
      cancelText: "Cancel",
      buttonText: "Delete",
      buttonAction: async () => {
        setIsRemovingLogic(true);
        removeRule(ruleUUID);
        setIsRemovingLogic(false);
      },
    });
  };

  const addBranchToNode = async (ruleUUID: string, nodeUUID: string) => {
    setIsAddingLogic(true);
    addBranchToRule(ruleUUID, nodeUUID);
    setIsAddingLogic(false);
  };

  const deleteBranchForRule = (
    ruleUUID: string,
    nodeUUID: string,
    confirm: boolean
  ) => {
    if (confirm) {
      openConfirmationModal({
        title: `Delete nested rule?`,
        description:
          "The rule will be permanently deleted, including all its contents, and will no longer available.",
        dangerousAction: true,
        cancelText: "Cancel",
        buttonText: "Delete",
        buttonAction: async () => {
          setIsRemovingLogic(true);
          removeBranchForRule(ruleUUID, nodeUUID);
          setIsRemovingLogic(false);
        },
      });
      return;
    }
    setIsRemovingLogic(true);
    removeBranchForRule(ruleUUID, nodeUUID);
    setIsRemovingLogic(false);
  };

  const deleteTestForRule = (ruleUUID: string, testUUID: string) => {
    setIsRemovingLogic(true);
    removeTestForRule(ruleUUID, testUUID);
    setIsRemovingLogic(false);
  };

  const setSelectedQuestion = async (
    ruleUUID: string,
    testUUID: string,
    questionId: string,
    isMultiSelect: boolean
  ) => {
    if (questionId == "") {
      setDataEntryErrorFunc(
        EditQuestionnairePages.EditAutomationRules,
        testUUID,
        DataEntryErrorAttribute.Question,
        "A question is required"
      );
      return;
    } else {
      deleteDataEntryErrorFunc(
        EditQuestionnairePages.EditAutomationRules,
        testUUID,
        DataEntryErrorAttribute.Question
      );
    }
    setQuestionForTest(ruleUUID, testUUID, questionId, isMultiSelect);
  };

  const setSelectedOperator = async (
    ruleUUID: string,
    testUUID: string,
    operator: IAutomationTestOperator
  ) => {
    if (operator.length == 0) {
      setDataEntryErrorFunc(
        EditQuestionnairePages.EditAutomationRules,
        testUUID,
        DataEntryErrorAttribute.Operator,
        "An operator must be selected"
      );
      return;
    } else {
      deleteDataEntryErrorFunc(
        EditQuestionnairePages.EditAutomationRules,
        testUUID,
        DataEntryErrorAttribute.Operator
      );
    }
    setOperatorForTest(ruleUUID, testUUID, operator);
  };
  const setSelectedAnswer = async (
    ruleUUID: string,
    testUUID: string,
    value: string
  ) => {
    const notAnswered = value === NOANSWERID;
    setValueForTest(ruleUUID, testUUID, notAnswered ? "" : value, notAnswered);
  };

  const setSelectedActionValue = (
    ruleUUID: string,
    actionValue: string,
    isNumeric: boolean
  ) => {
    setTargetValueForRule(ruleUUID, actionValue, isNumeric);
  };

  const convertHtml = (str: string): string => {
    const divContainer = document.createElement("div");
    divContainer.innerHTML = str;
    return divContainer.textContent || divContainer.innerText || "";
  };

  // canSetFromAnswer
  // Determine if a specific rule (based on the target attribute type) supports setting the attribute value from
  // the answer to a specific question in the questionnaire. Specifically, attributes that are text-based are supported here.
  // This includes LABELS, and custom attributes that are FREE-TEXT and NUMERIC.
  const canSetFromAnswer = (): boolean => {
    switch (automation?.targetAttribute) {
      case AutomationAttributeTypes.Labels:
        return true;
      case AutomationAttributeTypes.Custom:
        if (
          automationCustomAttribute &&
          (automationCustomAttribute.type ==
            VendorAttributeDefinitionType.Text ||
            automationCustomAttribute.type ==
              VendorAttributeDefinitionType.Numeric)
        ) {
          return true;
        }
    }
    return false;
  };

  const renderAnswerInput = (
    ruleUUID: string,
    test: IAutomationTest,
    level: number
  ) => {
    const qn = questionCache[test.questionId] ?? {};

    const dataEntryError =
      retrieveDataEntryErrorFunc(
        EditQuestionnairePages.EditAutomationRules,
        test.uuid,
        DataEntryErrorAttribute.Answer
      ) ?? "";

    if (qn?.questionType === QuestionTypes.FreeText) {
      const widthThreshold =
        level == 1
          ? SMALLSCREEN_TEXT_PROMPT_THRESHOLD
          : SMALLSCREEN_TEXT_PROMPT_INDENTED_THRESHOLD;
      return (
        <div className={"input-container"}>
          <TextField
            className={"text-field"}
            key={test.uuid + "_ans"}
            value={test.notAnswered ? "" : test.value}
            onChanged={(val) => {
              // to stop the call when all we do is flick the no answer checkbox. go figure..
              if (val != "" || !test.notAnswered) {
                setSelectedAnswer(ruleUUID, test.uuid, val);
              }
            }}
            readonly={!isEditing || !userHasWriteAutomationEnabled}
            placeholder={screenWidth > widthThreshold ? "Enter answer" : ""}
            errorTexts={dataEntryError ? [dataEntryError] : undefined}
            renderErrorsOnStart
          />
          {((isEditing &&
            test.value.length == 0 &&
            !operatorIsForStrings(test.operator)) ||
            test.notAnswered) && (
            <ColorCheckbox
              className={classnames("noanswer-check", {
                "noanswer-check-readonly": !isEditing,
              })}
              label={"Unanswered"}
              disabled={!isEditing || !userHasWriteAutomationEnabled}
              checked={test.notAnswered}
              onClick={() => {
                test.notAnswered
                  ? setSelectedAnswer(ruleUUID, test.uuid, "")
                  : setSelectedAnswer(ruleUUID, test.uuid, NOANSWERID);
                if (operatorIsForStrings(test.operator)) {
                  setSelectedOperator(
                    ruleUUID,
                    test.uuid,
                    IAutomationTestOperator.EQ
                  );
                }
              }}
            />
          )}
        </div>
      );
    } else if (
      qn?.questionType === QuestionTypes.MultiSelect ||
      qn?.questionType === QuestionTypes.SingleSelect
    ) {
      let selectedAnswer: IAnswerValue | undefined;
      if (test.notAnswered) {
        selectedAnswer = {
          answerId: NOANSWERID,
          value: "Unanswered",
        };
      } else {
        qn.answers?.map((a) => {
          if (a.answerId == test.value) {
            selectedAnswer = a;
          }
        });
      }

      const options = [
        ...(qn?.answers?.map((a) => ({
          value: a.answerId,
          label: convertHtml(a.value),
        })) ?? []),
      ];

      options.push({
        value: NOANSWERID,
        label: "Unanswered",
      });

      return (
        <>
          <SelectV2
            className={classnames("answer-select", {
              "select-error": !!dataEntryError,
            })}
            stopPropagation
            isClearable={false}
            placeholder={"Select answer"}
            isDisabled={!isEditing || !userHasWriteAutomationEnabled}
            options={options}
            value={
              selectedAnswer
                ? {
                    value: selectedAnswer.answerId,
                    label: convertHtml(selectedAnswer.value),
                  }
                : null
            }
            onChange={(selected) => {
              if (selected) {
                setSelectedAnswer(ruleUUID, test.uuid, selected.value);
              }
            }}
          />
          {dataEntryError && (
            <CSSTransition
              key={"select-error"}
              timeout={250}
              classNames="fade-transition"
            >
              <div className={"error-text"}>
                <i className={"icon-info"} />
                {dataEntryError}
              </div>
            </CSSTransition>
          )}
        </>
      );
    } else if (qn?.questionType === QuestionTypes.Upload) {
      const FILESUPPLIED = "File supplied";
      const FILENOTSUPPLIED = "File not supplied";
      const _GOT = "exists";
      const _NOT = "(NOT_ANSWERED)";

      return (
        <>
          <SelectV2
            className={classnames("answer-select", {
              "select-error": !!dataEntryError,
            })}
            stopPropagation
            isClearable={false}
            placeholder={"Select upload state"}
            isDisabled={!isEditing || !userHasWriteAutomationEnabled}
            options={[
              { value: _GOT, label: FILESUPPLIED },
              { value: _NOT, label: FILENOTSUPPLIED },
            ]}
            value={
              test.value == _GOT
                ? { value: _GOT, label: FILESUPPLIED }
                : test.value == _NOT
                  ? { value: _NOT, label: FILENOTSUPPLIED }
                  : null
            }
            onChange={(selected) => {
              if (selected) {
                setSelectedAnswer(ruleUUID, test.uuid, selected.value);
              }
            }}
          />
          {dataEntryError && (
            <CSSTransition
              key={"select-error"}
              timeout={250}
              classNames="fade-transition"
            >
              <div className={"error-text"}>
                <i className={"icon-info"} />
                {dataEntryError}
              </div>
            </CSSTransition>
          )}
        </>
      );
    }
    return <></>;
  };

  const renderTierActionSelector = (actionValue: string, ruleUUID: string) => {
    let selectedTier: VendorTier | undefined;
    tiers?.map((t) => {
      if (t.tier.toString(10) == actionValue) {
        selectedTier = t;
      }
    });

    const dataEntryError =
      retrieveDataEntryErrorFunc(
        EditQuestionnairePages.EditAutomationRules,
        ruleUUID,
        DataEntryErrorAttribute.Action
      ) ?? "";

    return (
      <>
        <DropdownV2
          className={classnames("tier-dropdown", {
            error: !!dataEntryError,
          })}
          stopPropagation
          disabled={!isEditing || !userHasWriteAutomationEnabled}
          popupItem={
            <div className={"drop-button"}>
              <div className={"tier-name"}>
                <VendorTierBadge
                  tier={selectedTier ? selectedTier.tier : 0}
                  noPopup
                />
                <div className={"name"}>
                  {selectedTier ? selectedTier.name : "Select a tier"}
                </div>
              </div>
              <div className="cr-icon-chevron rot90" />
            </div>
          }
        >
          <DropdownItem
            key={"0"}
            selected={actionValue == "0" || actionValue == ""}
            onClick={() => {
              setSelectedActionValue(ruleUUID, "0", false);
            }}
          >
            {
              <div className={"tier-name"}>
                <VendorTierBadge tier={0} noPopup />
                <div className={"name"}>{"Untiered"}</div>
              </div>
            }
          </DropdownItem>
          {tiers
            ?.filter((t) => t.tier != 0 && t.isActive && !t.isArchived)
            .map((t) => {
              return (
                <DropdownItem
                  key={t.id}
                  selected={actionValue == t.tier.toString(10)}
                  onClick={() => {
                    setSelectedActionValue(
                      ruleUUID,
                      t.tier.toString(10),
                      false
                    );
                  }}
                >
                  {
                    <div className={"tier-name"}>
                      <VendorTierBadge tier={t.tier} noPopup />
                      <div className={"name"}>{t.name}</div>
                    </div>
                  }
                </DropdownItem>
              );
            })}
        </DropdownV2>
        {dataEntryError && (
          <CSSTransition
            key={"select-error"}
            timeout={250}
            classNames="fade-transition"
          >
            <div className={"error-text"}>
              <i className={"icon-info"} />
              {dataEntryError}
            </div>
          </CSSTransition>
        )}
      </>
    );
  };

  const renderPortfolioActionSelector = (
    actionValue: string,
    ruleUUID: string
  ) => {
    let selectedPortfolio: Portfolio | undefined;
    portfolios?.map((p) => {
      if (p.id.toString(10) == actionValue) {
        selectedPortfolio = p;
      }
    });

    const dataEntryError =
      retrieveDataEntryErrorFunc(
        EditQuestionnairePages.EditAutomationRules,
        ruleUUID,
        DataEntryErrorAttribute.Action
      ) ?? "";

    return (
      <>
        <SelectV2
          placeholder={"Select portfolio"}
          className={classnames("portfolio-select", {
            "select-error": !!dataEntryError,
          })}
          isClearable={false}
          stopPropagation
          isDisabled={!isEditing || !userHasWriteAutomationEnabled}
          options={[
            ...(portfolios?.map((p) => ({
              value: p.id.toString(10),
              label: convertHtml(p.name),
            })) ?? []),
          ]}
          value={
            selectedPortfolio
              ? {
                  value: selectedPortfolio.id.toString(10),
                  label: convertHtml(selectedPortfolio.name),
                }
              : null
          }
          onChange={(selected) => {
            if (selected) {
              setSelectedActionValue(ruleUUID, selected.value, false);
            }
          }}
        />
        {dataEntryError && (
          <CSSTransition
            key={"select-error"}
            timeout={250}
            classNames="fade-transition"
          >
            <div className={"error-text"}>
              <i className={"icon-info"} />
              {dataEntryError}
            </div>
          </CSSTransition>
        )}
      </>
    );
  };

  const renderLabelActionSelector = (
    actionValue: string,
    ruleUUID: string,
    useQuestionValue: boolean,
    questionId: string
  ) => {
    const selectedLabel = labels?.find((l) => l.name == actionValue);

    const canUseAnswer = canSetFromAnswer();

    const renderLabelSelect = () => {
      const dataEntryError =
        retrieveDataEntryErrorFunc(
          EditQuestionnairePages.EditAutomationRules,
          ruleUUID,
          DataEntryErrorAttribute.Action
        ) ?? "";

      return (
        <>
          <SelectV2
            placeholder={"Select label"}
            className={classnames("portfolio-select", {
              "select-error": !!dataEntryError,
            })}
            stopPropagation
            isClearable={false}
            isDisabled={!isEditing || !userHasWriteAutomationEnabled}
            options={[
              ...(labels?.map((l) => ({
                value: l.name,
                label: convertHtml(l.name),
              })) ?? []),
            ]}
            value={
              selectedLabel
                ? {
                    value: selectedLabel.name,
                    label: convertHtml(selectedLabel.name),
                  }
                : null
            }
            onChange={(selected) => {
              if (selected) {
                setSelectedActionValue(ruleUUID, selected.value, false);
              }
            }}
          />
          {dataEntryError && (
            <CSSTransition
              key={"select-error"}
              timeout={250}
              classNames="fade-transition"
            >
              <div className={"error-text"}>
                <i className={"icon-info"} />
                {dataEntryError}
              </div>
            </CSSTransition>
          )}
        </>
      );
    };

    if (!canUseAnswer) {
      return <>{renderLabelSelect()}</>;
    } else {
      return (
        <div className={"radioed-target"}>
          <ColorCheckbox
            radio
            onClick={() => {
              setTargetValueUsesAnswer(ruleUUID, false);
            }}
            checked={!useQuestionValue}
            label={"Select label value"}
          />
          {!useQuestionValue && renderLabelSelect()}
          <ColorCheckbox
            radio
            onClick={() => {
              setTargetValueUsesAnswer(ruleUUID, true);
            }}
            checked={useQuestionValue}
            label={
              <div className={"qn-help"}>
                {"Use the answer from a question"}
                <SidePopupV2
                  className="help-icon"
                  position={"top"}
                  width={200}
                  text={
                    "Use an answer supplied in the questionnaire as the value for your label. Note that if the question is not answered, no label value will be assigned."
                  }
                >
                  <Icon name="info" />
                </SidePopupV2>
              </div>
            }
          />
          {useQuestionValue &&
            renderTargetQuestionSelector(ruleUUID, questionId)}
        </div>
      );
    }
  };

  // renderTargetQuestionSelector
  // Renders a dropdown input list that provides the ability to select a question to be used as the source of an attribute target
  // value. Note that the set of questions provided for this purpose is limited to those supporting freetext only (not select, multiselect etc)
  const renderTargetQuestionSelector = (
    ruleUUID: string,
    selectedQuestionId: string
  ) => {
    const dataEntryError =
      retrieveDataEntryErrorFunc(
        EditQuestionnairePages.EditAutomationRules,
        ruleUUID,
        DataEntryErrorAttribute.ActionQuestion
      ) ?? "";

    return (
      <>
        <SelectV2
          className={classnames("question-select", {
            "select-error": !!dataEntryError,
          })}
          stopPropagation
          placeholder={"Select question..."}
          isClearable={false}
          isDisabled={!isEditing || !userHasWriteAutomationEnabled}
          options={[
            ...(questions
              ?.filter((q) => q.questionType == QuestionTypes.FreeText)
              .map((q) => ({
                value: q.id,
                label: convertHtml(q.questionText),
              })) ?? []),
          ]}
          value={
            questionCache?.[selectedQuestionId]
              ? {
                  value: questionCache?.[selectedQuestionId].id,
                  label: convertHtml(
                    questionCache?.[selectedQuestionId].questionText
                  ),
                }
              : null
          }
          onChange={(selected) => {
            if (selected) {
              setTargetValueAnswerId(ruleUUID, selected.value);
            }
          }}
        />
        {dataEntryError && (
          <CSSTransition
            key={"select-error"}
            timeout={250}
            classNames="fade-transition"
          >
            <div className={"error-text"}>
              <i className={"icon-info"} />
              {dataEntryError}
            </div>
          </CSSTransition>
        )}
      </>
    );
  };

  const renderCustomAttributeActionSelector = (
    actionValue: string,
    ruleUUID: string,
    useQuestionValue: boolean,
    questionId: string
  ) => {
    const dataEntryError =
      retrieveDataEntryErrorFunc(
        EditQuestionnairePages.EditAutomationRules,
        ruleUUID,
        DataEntryErrorAttribute.Action
      ) ?? "";

    const canUseAnswer = canSetFromAnswer();

    const renderError = () => {
      return (
        <CSSTransition
          key={"select-error"}
          timeout={250}
          classNames="fade-transition"
        >
          <div className={"error-text"}>
            <i className={"icon-info"} />
            {dataEntryError}
          </div>
        </CSSTransition>
      );
    };

    const renderDataEntry = () => {
      if (!automationCustomAttribute) {
        return <></>;
      } else {
        return (
          <TextField
            className={
              automationCustomAttribute.type ==
              VendorAttributeDefinitionType.Numeric
                ? "numeric-field"
                : "text-field"
            }
            key={"action"}
            hideMessageTexts={!dataEntryError}
            value={actionValue}
            onChanged={(val) =>
              setSelectedActionValue(
                ruleUUID,
                val,
                automationCustomAttribute.type ==
                  VendorAttributeDefinitionType.Numeric
              )
            }
            readonly={!isEditing || !userHasWriteAutomationEnabled}
            placeholder={"Enter value"}
            errorTexts={dataEntryError ? [dataEntryError] : undefined}
            renderErrorsOnStart
          />
        );
      }
    };

    if (!automationCustomAttribute) {
      return <></>;
    } else if (
      automationCustomAttribute.type == VendorAttributeDefinitionType.Set ||
      automationCustomAttribute.type == VendorAttributeDefinitionType.MultiSet
    ) {
      let selectedValue: string | undefined;
      automationCustomAttribute?.metadata?.allowedValues?.map((v) => {
        if (v == actionValue) {
          selectedValue = v;
        }
      });

      return (
        <>
          <SelectV2
            placeholder={`Select value for '${automationCustomAttribute?.name}'`}
            className={classnames("portfolio-select", {
              "select-error": !!dataEntryError,
            })}
            stopPropagation
            isClearable={false}
            isDisabled={!isEditing || !userHasWriteAutomationEnabled}
            options={[
              ...(automationCustomAttribute?.metadata?.allowedValues?.map(
                (val) => ({
                  value: val,
                  label: convertHtml(val),
                })
              ) ?? []),
            ]}
            value={
              selectedValue
                ? {
                    value: selectedValue,
                    label: convertHtml(selectedValue),
                  }
                : null
            }
            onChange={(selected) => {
              if (selected) {
                setSelectedActionValue(ruleUUID, selected.value, false);
              }
            }}
          />
          {dataEntryError && renderError()}
        </>
      );
    } else if (
      automationCustomAttribute.type == VendorAttributeDefinitionType.Numeric ||
      automationCustomAttribute.type == VendorAttributeDefinitionType.Text
    ) {
      if (!canUseAnswer) {
        return (
          <>
            {renderDataEntry()}
            {dataEntryError && renderError()}
          </>
        );
      } else {
        return (
          <div className={"radioed-target"}>
            <ColorCheckbox
              radio
              onClick={() => {
                setTargetValueUsesAnswer(ruleUUID, false);
              }}
              checked={!useQuestionValue}
              label={"Enter attribute value"}
            />
            {!useQuestionValue && renderDataEntry()}
            <ColorCheckbox
              radio
              onClick={() => {
                setTargetValueUsesAnswer(ruleUUID, true);
              }}
              checked={useQuestionValue}
              label={
                <div className={"qn-help"}>
                  {"Use the answer from a question"}
                  <SidePopupV2
                    className="help-icon"
                    position={"top"}
                    width={200}
                    text={`Use an answer supplied in the questionnaire as the value for attribute '${automationCustomAttribute?.name}'. Note that if the question is not answered, no value will be assigned`}
                  >
                    <Icon name="info" />
                  </SidePopupV2>
                </div>
              }
            />
            {useQuestionValue &&
              renderTargetQuestionSelector(ruleUUID, questionId)}
          </div>
        );
      }
    }
    return <div>{"Task not supported."}</div>;
  };

  const renderQuestionSelector = (ruleUUID: string, test: IAutomationTest) => {
    const dataEntryError =
      retrieveDataEntryErrorFunc(
        EditQuestionnairePages.EditAutomationRules,
        test.uuid,
        DataEntryErrorAttribute.Question
      ) ?? "";

    return (
      <>
        <SelectV2
          className={classnames("question-select", {
            "select-error": !!dataEntryError,
          })}
          stopPropagation
          isClearable={false}
          isDisabled={!isEditing || !userHasWriteAutomationEnabled}
          options={[
            ...(questions
              ?.filter((q) => q.questionType != QuestionTypes.Section)
              .map((q) => ({
                value: q.id,
                label: convertHtml(q.questionText),
              })) ?? []),
          ]}
          value={
            questionCache?.[test.questionId]
              ? {
                  value: questionCache?.[test.questionId].id,
                  label: convertHtml(
                    questionCache?.[test.questionId].questionText
                  ),
                }
              : null
          }
          onChange={(selected) => {
            if (selected) {
              const question = questionCache[selected.value];
              setSelectedQuestion(
                ruleUUID,
                test.uuid,
                selected.value,
                question && question.questionType == QuestionTypes.MultiSelect
              );
            }
          }}
        />
        {dataEntryError && (
          <CSSTransition
            key={"select-error"}
            timeout={250}
            classNames="fade-transition"
          >
            <div className={"error-text"}>
              <i className={"icon-info"} />
              {dataEntryError}
            </div>
          </CSSTransition>
        )}
      </>
    );
  };

  const getOperatorIcon = (op: IAutomationTestOperator): string => {
    switch (op) {
      case IAutomationTestOperator.EQ:
        return "cr-icon-equals";
      case IAutomationTestOperator.NEQ:
        return "cr-icon-notequal";
      case IAutomationTestOperator.CONTAINS:
        return "cr-icon-contains";
      case IAutomationTestOperator.PREFIX:
        return "cr-icon-prefix";
      case IAutomationTestOperator.SUFFIX:
        return "cr-icon-suffix";
    }
  };

  const getOperatorText = (op: IAutomationTestOperator): string => {
    switch (op) {
      case IAutomationTestOperator.EQ:
        return "is equal to";
      case IAutomationTestOperator.NEQ:
        return "not equal to";
      case IAutomationTestOperator.CONTAINS:
        return "contains";
      case IAutomationTestOperator.PREFIX:
        return "starts-with";
      case IAutomationTestOperator.SUFFIX:
        return "ends-with";
    }
  };

  const renderOperatorSelector = (ruleUUID: string, test: IAutomationTest) => {
    const dataEntryError =
      retrieveDataEntryErrorFunc(
        EditQuestionnairePages.EditAutomationRules,
        test.uuid,
        DataEntryErrorAttribute.Operator
      ) ?? "";

    const questionType = questionCache?.[test.questionId]?.questionType;

    const options = [
      {
        value: IAutomationTestOperator.EQ,
        label: getOperatorText(IAutomationTestOperator.EQ),
      },
      {
        value: IAutomationTestOperator.NEQ,
        label: getOperatorText(IAutomationTestOperator.NEQ),
      },
    ];

    if (questionType == QuestionTypes.FreeText) {
      options.push({
        value: IAutomationTestOperator.CONTAINS,
        label: getOperatorText(IAutomationTestOperator.CONTAINS),
      });
      options.push({
        value: IAutomationTestOperator.PREFIX,
        label: getOperatorText(IAutomationTestOperator.PREFIX),
      });
      options.push({
        value: IAutomationTestOperator.SUFFIX,
        label: getOperatorText(IAutomationTestOperator.SUFFIX),
      });
    }

    return (
      <>
        <SelectV2
          className={classnames("op-select", {
            "select-error": !!dataEntryError,
          })}
          stopPropagation
          isClearable={false}
          isSearchable={false}
          isDisabled={!isEditing || !userHasWriteAutomationEnabled}
          options={options}
          value={
            test.operator
              ? {
                  value: test.operator,
                  label: "",
                }
              : null
          }
          onChange={(selected) => {
            if (selected) {
              setSelectedOperator(
                ruleUUID,
                test.uuid,
                selected.value as IAutomationTestOperator
              );
            }
          }}
          formatOptionLabel={(opt, ctx) => {
            // Is this formatting for a selected option in the dropdown menu?
            const selected =
              ctx.context === "menu" &&
              Array.isArray(ctx.selectValue) &&
              ctx.selectValue.length > 0 &&
              ctx.selectValue[0].value === opt.value;
            return (
              <div
                className={classnames("op-option", {
                  selected,
                })}
              >
                <div
                  className={classnames(getOperatorIcon(opt.value), {
                    selected,
                  })}
                />
                {getOperatorText(opt.value)}
              </div>
            );
          }}
        />
        {dataEntryError && (
          <CSSTransition
            key={"select-error"}
            timeout={250}
            classNames="fade-transition"
          >
            <div className={"error-text"}>
              <i className={"icon-info"} />
              {dataEntryError}
            </div>
          </CSSTransition>
        )}
      </>
    );
  };

  const renderTreeNodeRows = (
    node: IAutomationTreeNode,
    ruleUUID: string,
    level: number,
    includeTrash: boolean
  ) => {
    const rows: ReactNode[] = [];
    node.tests.map((test) => {
      rows.push(
        <div
          key={test.uuid}
          className={classnames(level === 1 ? "row" : "subrow", {})}
          draggable
          onDragStart={(event) => {
            event.stopPropagation();
            setDragUUID(test.uuid);
            setDraggingFrom(node.uuid);
            setDraggingFromRule(ruleUUID);
            setDragType("test");
            setDragOverRule("");
          }}
          onDragEnd={() => {
            setDragUUID("");
            setDraggingFrom("");
            setDraggingFromRule("");
          }}
        >
          <div className={"col1"}>
            <div className="cr-icon-drag-handle" />
          </div>
          <div className={"col2 normal"}>
            {renderQuestionSelector(ruleUUID, test)}
          </div>
          <div className={"col3 normal"}>
            {renderOperatorSelector(ruleUUID, test)}
          </div>
          <div className={"col4 normal"}>
            {renderAnswerInput(ruleUUID, test, level)}
          </div>
          <div
            className={classnames("col5", {
              normal: level === 1,
              noline: level > 1 || screenWidth < SMALLSCREEN_THRESHOLD,
            })}
          >
            {includeTrash && (
              <div
                className={classNames("trash", {
                  clickable:
                    isEditing &&
                    userHasWriteAutomationEnabled &&
                    !isRemovingLogic,
                })}
                onClick={() => deleteTestForRule(ruleUUID, test.uuid)}
              >
                <SidePopupV2 text={"Remove rule"} position={"top"} noWrap>
                  <div className={"cr-icon-trash-2"}></div>
                </SidePopupV2>
              </div>
            )}
          </div>
        </div>
      );
    });
    return rows;
  };

  const renderAction = (
    ruleTargetValue: string,
    ruleUUID: string,
    useQuestionValue: boolean,
    questionId: string
  ) => {
    let selector: ReactNode;
    switch (automation?.targetAttribute) {
      case AutomationAttributeTypes.Tier:
        selector = renderTierActionSelector(ruleTargetValue, ruleUUID);
        break;
      case AutomationAttributeTypes.Portfolios:
        selector = renderPortfolioActionSelector(ruleTargetValue, ruleUUID);
        break;
      case AutomationAttributeTypes.Labels:
        selector = renderLabelActionSelector(
          ruleTargetValue,
          ruleUUID,
          useQuestionValue,
          questionId
        );
        break;
      case AutomationAttributeTypes.Custom:
        selector = renderCustomAttributeActionSelector(
          ruleTargetValue,
          ruleUUID,
          useQuestionValue,
          questionId
        );
        break;
      default:
        selector = <>{"Unknown"}</>;
    }
    return (
      <div className={"action"}>
        <div className={"col1 heading trunc"}>{assignActionLabel}</div>
        <div className={"normal"}>{selector}</div>
      </div>
    );
  };

  const renderBranch = (
    ruleUUID: string,
    node: IAutomationTreeNode,
    assignActionLabel: string,
    ruleTargetValue: string,
    useQuestionValue: boolean,
    questionId: string,
    level: number,
    idx: number
  ) => {
    const dropTargetInvisible =
      dragUUID == "" ||
      dragOverRule != ruleUUID ||
      dragType != "test" ||
      draggingFrom == node.uuid;
    const numChildren =
      (node.tests?.length || 0) +
      (node.branches?.length || 0) +
      (dropTargetInvisible ? 0 : 1);
    return (
      <div
        key={node.uuid}
        className={classnames("rule", { subrule: level > 1 })}
        onDragOver={() => {
          setDragOverRule(ruleUUID);
        }}
      >
        {level === 1 && <div className={"rule-index"}>{`${idx + 1}.`}</div>}
        <div
          className={classnames("inner", {
            "subrule-inner": level > 1 || screenWidth < SMALLSCREEN_THRESHOLD,
          })}
        >
          {/* only display the logical operation if there are more than one item to combine with it */}
          {numChildren > 1 ? (
            <div
              className={classnames("rule-operator", {
                "rule-operator-sub": level > 1,
              })}
            >
              <div className={"half"}></div>
              <div className={"half box"}></div>
              <div className={"rule-operator-overlay"}>
                <DropdownV2
                  disabled={!isEditing}
                  popupItem={
                    <PillLabel
                      color={LabelColor.Blue}
                      className={isEditing ? "clickable" : undefined}
                    >
                      {node.operator && node.operator.length > 0
                        ? node.operator
                        : IAutomationTreeNodeOperator.AND}{" "}
                      <Icon name="chevron" direction={180} />
                    </PillLabel>
                  }
                >
                  <DropdownItem
                    key={"and"}
                    onClick={() => {
                      setOperatorForBranch(
                        ruleUUID,
                        node.uuid,

                        IAutomationTreeNodeOperator.AND
                      );
                    }}
                  >
                    {"AND"}
                  </DropdownItem>
                  <DropdownItem
                    key={"or"}
                    onClick={() => {
                      setOperatorForBranch(
                        ruleUUID,
                        node.uuid,
                        IAutomationTreeNodeOperator.OR
                      );
                    }}
                  >
                    {"OR"}
                  </DropdownItem>
                </DropdownV2>
              </div>
            </div>
          ) : (
            <div className={"rule-operator"} />
          )}

          <div className={classnames("rows", { rowsline: level === 1 })}>
            {level === 1 && (
              <div className={"row"} key={ruleUUID + "_head"}>
                {/* header row */}
                <div className={"col2 heading"}>If this question...*</div>
                <div className={"col3 heading"}> </div>
                <div className={"col4 heading"}>answer value...*</div>
              </div>
            )}
            {/* tests for this rule, including sub-groups */}
            {renderTreeNodeRows(node, ruleUUID, level, numChildren > 1)}
            <div
              key={node.uuid + "_top"}
              className={classnames("drop-target", {
                invisible: dropTargetInvisible,
              })}
              onDrop={() => {
                const testUUID = dragUUID;
                setDragUUID("");
                moveTestFromNodeToNode(
                  testUUID,
                  draggingFromRule,
                  ruleUUID,
                  node.uuid
                );
              }}
              onDragOver={(event) => {
                event.preventDefault();
              }}
            >
              <div>{"Drag rules into this group"}</div>
            </div>
            {node.branches?.map((n, idx) => {
              return (
                <div
                  className={"row branchrow"}
                  key={n.uuid}
                  draggable
                  onDragStart={(event) => {
                    event.stopPropagation();
                    setDragUUID(n.uuid);
                    setDraggingFrom(node.uuid);
                    setDraggingFromRule(ruleUUID);
                    setDragType("branch");
                    setDragOverRule("");
                  }}
                  onDragEnd={() => {
                    setDragUUID("");
                    setDraggingFrom("");
                    setDraggingFromRule("");
                  }}
                  onDragOver={() => {
                    setDragOverRule(ruleUUID);
                  }}
                >
                  <div className={"col1 col1-branch"}>
                    <div className="cr-icon-drag-handle" />
                  </div>
                  <div className={"all-cols"}>
                    {renderBranch(
                      ruleUUID,
                      n,
                      assignActionLabel,
                      "",
                      false,
                      "",
                      level + 1,
                      idx
                    )}
                  </div>
                  {numChildren > 1 && (
                    <div
                      className={classNames("trash col5 lowered", {
                        clickable:
                          isEditing &&
                          userHasWriteAutomationEnabled &&
                          !isRemovingLogic,
                        noline: screenWidth < SMALLSCREEN_THRESHOLD,
                      })}
                      onClick={() => {
                        if (
                          isEditing &&
                          userHasWriteAutomationEnabled &&
                          !isRemovingLogic
                        ) {
                          deleteBranchForRule(
                            ruleUUID,
                            n.uuid,
                            (n.tests?.length ?? 0) + (n.branches?.length ?? 0) >
                              1
                          );
                        }
                      }}
                    >
                      <SidePopupV2
                        text={"Remove nested rule"}
                        position={"top"}
                        noWrap
                      >
                        <div className={"cr-icon-trash-2"}></div>
                      </SidePopupV2>
                    </div>
                  )}
                </div>
              );
            })}
            {level === 1 && (
              <div
                key={node.uuid + "_bott"}
                className={classnames("drop-target drop-target-branch", {
                  invisible:
                    dragOverRule != ruleUUID ||
                    dragType != "branch" ||
                    dragUUID == "" ||
                    draggingFrom == node.uuid,
                })}
                onDrop={() => {
                  const branchUUID = dragUUID;
                  setDragUUID("");
                  moveBranchFromNodeToNode(
                    branchUUID,
                    draggingFromRule,
                    ruleUUID,
                    node.uuid
                  );
                }}
                onDragOver={(event) => {
                  event.preventDefault();
                }}
              >
                <div>{"Drag rules into this group"}</div>
              </div>
            )}
          </div>
          {level == 1 &&
            screenWidth >= SMALLSCREEN_THRESHOLD &&
            renderAction(
              ruleTargetValue,
              ruleUUID,
              useQuestionValue,
              questionId
            )}
        </div>
        {screenWidth < SMALLSCREEN_THRESHOLD && level == 1 && (
          <div className={"action-extra"}>
            {renderAction(
              ruleTargetValue,
              ruleUUID,
              useQuestionValue,
              questionId
            )}
          </div>
        )}
        <div className={"buttons"}>
          {isEditing && userHasWriteAutomationEnabled && (
            <AddRuleButton
              includeSubRule={level == 1}
              onSelectOption={(option: string) => {
                if (option == "add-test") {
                  addTestToRuleNode(ruleUUID, node.uuid);
                } else {
                  addBranchToNode(ruleUUID, node.uuid);
                }
              }}
            />
          )}
        </div>
      </div>
    );
  };

  const renderRule = (
    rule: IAutomationSimpleRule,
    idx: number,
    total: number,
    assignActionLabel: string
  ) => {
    const draggable = total > 1;
    return (
      <div key={rule.uuid + "_rulediv"}>
        <div
          key={rule.uuid + "_updrop"}
          className={classnames("drop-target", {
            invisible:
              idx == dragRuleIdx ||
              idx == dragRuleIdx + 1 ||
              dragType != "rule" ||
              dragUUID == "" ||
              dragOverRule != rule.uuid,
          })}
          onDrop={() => {
            moveRule(dragRuleIdx, idx);
            setDragRuleIdx(-1);
            setDragUUID("");
          }}
          onDragOver={(event) => {
            event.preventDefault();
          }}
        >
          <div>{"Drag action here to reorder"}</div>
        </div>
        <div
          className={"rule-outer"}
          key={rule.uuid}
          draggable={draggable}
          onDragStart={(event) => {
            event.stopPropagation();
            setDragUUID(rule.uuid);
            setDraggingFrom("");
            setDraggingFromRule("");
            setDragType("rule");
            setDragOverRule("");
            setDragRuleIdx(idx);
          }}
          onDragEnd={() => {
            setDragUUID("");
            setDragRuleIdx(-1);
          }}
          onDragOver={() => {
            setDragOverRule(rule.uuid);
          }}
        >
          {draggable && (
            <div className={"drag-handle"}>
              <div className="cr-icon-drag-handle" />
            </div>
          )}
          {renderBranch(
            rule?.uuid,
            rule?.logic,
            assignActionLabel,
            rule.targetValue,
            rule.setTargetFromAnswer,
            rule.setTargetFromAnswerId || "",
            1,
            idx
          )}
          {isEditing && userHasWriteAutomationEnabled && total > 1 && (
            <SidePopupV2
              key={rule.uuid + "_pop"}
              text={"Delete action"}
              position={"top"}
              noWrap
            >
              <div
                className={classNames("trash", {
                  clickable:
                    isEditing &&
                    userHasWriteAutomationEnabled &&
                    !isRemovingLogic,
                })}
                onClick={() => {
                  if (
                    isEditing &&
                    userHasWriteAutomationEnabled &&
                    !isRemovingLogic
                  ) {
                    deleteRule(rule?.uuid);
                  }
                }}
              >
                <div className={"cr-icon-trash-2"}></div>
              </div>
            </SidePopupV2>
          )}
        </div>
        <div
          key={rule.uuid + "_lowdrop"}
          className={classnames("drop-target", {
            invisible:
              idx == dragRuleIdx ||
              idx == dragRuleIdx - 1 ||
              dragType != "rule" ||
              dragUUID == "" ||
              dragOverRule != rule.uuid,
          })}
          onDrop={() => {
            moveRule(dragRuleIdx, idx);
            setDragRuleIdx(-1);
            setDragUUID("");
          }}
          onDragOver={(event) => {
            event.preventDefault();
          }}
        >
          <div>{"Drag action here to reorder"}</div>
        </div>
      </div>
    );
  };

  const renderRules = (
    rules: IAutomationSimpleRule[],
    assignActionLabel: string
  ) => {
    return rules.map((r, idx: number) => {
      return renderRule(r, idx, rules.length, assignActionLabel);
    });
  };

  if (loading) {
    return <LoadingBanner />;
  }
  if (
    !automation?.userConstructs?.simpleRules ||
    automation?.userConstructs?.simpleRules?.length == 0
  ) {
    return (
      <div className="simple-logic-rules-step">
        <ReportCard newStyles className="report-card">
          <EmptyCardWithAction
            iconSrc={LightningSVG}
            actionDisabled={!userHasWriteAutomationEnabled}
            emptyText={"No automation logic defined"}
            emptySubText={
              isEditing && userHasWriteAutomationEnabled
                ? "No logical operations have been added for this automation rule. Click on the '+ Add logic' button to add your first logical operator"
                : "No logical operations steps have been added for this automation rule."
            }
            actionButtonText={isEditing ? "Add logic" : undefined}
            onActionClick={
              isEditing
                ? async () => {
                    addRuleAfter(-1);
                  }
                : undefined
            }
            actionButtonLoading={isAddingLogic}
            actionButtonIconClass={isEditing ? "cr-icon-plus" : undefined}
          />
        </ReportCard>
      </div>
    );
  }

  const InfoSection = () => {
    return (
      <>
        <div className={"info-section"}>
          Map your rule conditions to an action. Multiple rules can be created,
          grouped and nested to support more complex logic conditions.
        </div>
        <div className={"info-section"}>
          <Button
            tertiary
            onClick={() =>
              window.open(
                "https://help.upguard.com/en/articles/8073950-how-to-use-automation-to-apply-tiers-labels-portfolios-and-custom-attributes-to-your-vendors",
                "_blank"
              )
            }
          >
            Learn more about automation <div className="cr-icon-arrow-right" />
          </Button>
        </div>
      </>
    );
  };

  return (
    <div className="simple-logic-rules-step">
      <ReportCard newStyles className="report-card">
        <div className={"header"}>
          <div className={"header-title"}>Configure rule logic</div>
          <InfoSection />
        </div>
        <div className={"body"}>
          {renderRules(
            automation?.userConstructs.simpleRules,
            assignActionLabel
          )}
          {isEditing && userHasWriteAutomationEnabled && (
            <div
              className={classnames("new-action", {
                wider: automation?.userConstructs?.simpleRules?.length <= 1,
              })}
              onClick={() => {
                if (isEditing && userHasWriteAutomationEnabled) {
                  addRuleAfter(
                    automation?.userConstructs?.simpleRules?.length ?? 0 - 1
                  );
                }
              }}
            >
              <div className={"cr-icon-plus"} /> <div>New Action</div>
            </div>
          )}
        </div>
      </ReportCard>
      {confirmationModal}
    </div>
  );
};

export default appConnect<
  IDefineRulesStepConnectedProps,
  never,
  IDefineRulesStepOwnProps
>((state, props) => {
  const tiers = state.cyberRisk.vendorTiers;
  const portfolios = state.cyberRisk.vendorPortfolios?.portfolios;

  const systemLabels = state.cyberRisk.availableLabels
    ? state.cyberRisk.availableLabels.filter(
        (l) => l.classification === LabelClassification.SystemLabel
      )
    : [];

  const classificationCustomLabels = state.cyberRisk.availableLabels
    ? state.cyberRisk.availableLabels.filter(
        (l) => l.classification === LabelClassification.VendorLabel
      )
    : [];

  const allLabels = sortLabels([
    ...systemLabels,
    ...classificationCustomLabels,
  ]);

  const vendorAttributeDefinitions = state.cyberRisk.vendorAttributeDefinitions;

  const connectedProps: IDefineRulesStepConnectedProps = {
    loading: props.loading,
    tiers: tiers,
    portfolios: portfolios,
    labels: allLabels,
    vendorAttributeDefinitions: vendorAttributeDefinitions,
  };
  return connectedProps;
})(DefineRulesStep);
