import { FC } from "react";
import ListFilter from "./ListFilter";
import { AppStatus } from "../../api/types";
import { statusFilterDisplayText } from "../../helpers/apps";
import {
  OrgAccessUserBase,
  UserUserBaseEnabled,
} from "../../../_common/permissions";
import withRequireEntitlementAndPermission from "../../../_common/hocs/WithRequireEntitlementAndPermission";

interface UserRisksAppStatusesFilterProps {
  selectedAppStatuses: AppStatus[];
  excluded: boolean;
  onChange: (selectedAppStatus: AppStatus[], exclude: boolean) => void;
}

const UserRisksAppStatusesFilter: FC<UserRisksAppStatusesFilterProps> = ({
  selectedAppStatuses,
  excluded,
  onChange,
}) => {
  return (
    <ListFilter
      title={"Status"}
      isLoading={false}
      options={Object.values(AppStatus).map((status) => ({
        value: status as AppStatus,
        label: statusFilterDisplayText(status),
      }))}
      selectedValues={selectedAppStatuses}
      excluded={excluded}
      onChange={(selectedValues, exclude) => onChange(selectedValues, exclude)}
    />
  );
};

export default withRequireEntitlementAndPermission(
  UserRisksAppStatusesFilter,
  OrgAccessUserBase,
  UserUserBaseEnabled
);
