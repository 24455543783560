export const NO_NODES = "NO_NODES";
export const NO_CSTAR = "NO_CSTAR";
export const INSUFFICIENT_NODE_SCANS = "INSUFFICIENT_NODE_SCANS";
export const NO_VULN_SCANS = "NO_VULN_SCANS";
export const VULNS_NOT_ENABLED = "VULNS_NOT_ENABLED";
export const NO_POLICIES = "NO_POLICIES";
export const NO_POLICIES_SCANS = "NO_POLICIES_SCANS";
export const NO_VENDOR_FOUND = "NOVENDOR";
export const NO_SCORE_DATA = "NOSCORES";
export const DUPLICATE_CLOUDSCAN_EXISTS = "DUPLICATE_CLOUDSCAN_EXISTS";
