import { Component } from "react";
import PropTypes from "prop-types";

import Button from "../../../_common/components/core/Button";
import { closeModal } from "../../../_common/reducers/commonActions";
import { publishFindings } from "../../reducers/actions";
import { FindingEntry } from "../UnpublishedFindings";

import "../../style/ReviewPublishModal.scss";
import "../../style/Findings.scss";
import {
  addDefaultSuccessAlert,
  addDefaultUnknownErrorAlert,
} from "../../../_common/reducers/messageAlerts.actions";

export const ReviewPublishModalName = "ReviewPublishModal";

class ReviewPublishModal extends Component {
  static propTypes = {
    modalData: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    const { findings } = this.props.modalData;
    const { orgId } = this.props.modalData;

    this.state = {
      findings,
      orgId,
      loading: false,
    };
  }

  onSubmit = () => {
    this.setState({ loading: true });
    const findings = this.state.findings.map((finding) => {
      return finding.finding_id;
    });
    this.props
      .dispatch(publishFindings(this.state.orgId, findings))
      .then(() => {
        this.props.dispatch(addDefaultSuccessAlert("All findings published"));
        this.props.dispatch(closeModal());
      })
      .catch(() => {
        this.props.dispatch(
          addDefaultUnknownErrorAlert("Error publishing findings")
        );
        this.setState({ loading: false });
      });
  };

  onCancel = () => {
    this.props.dispatch(closeModal());
  };

  nullHandler = () => {};

  render() {
    return (
      <div className="review-publish-modal">
        <div className="left-hand">
          <div className="findings-scrolling-block">
            <div className="analyst-findings">
              <div className="findings">
                {this.state.findings.map((finding) => (
                  <FindingEntry
                    key={finding.finding_id}
                    dispatch={this.props.dispatch}
                    orgId={this.state.orgId}
                    selected={
                      this.state && this.state.selectedFinding
                        ? this.state.selectedFinding.finding_id ===
                          finding.finding_id
                        : false
                    }
                    finding={finding}
                    decodeFindingType={this.props.modalData.decodeFindingType}
                    previewOnly
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="right-hand">
          <span className="button-header">Take one last look!</span>
          <Button filledPrimary onClick={this.onSubmit}>
            Proceed with publish
          </Button>
          <Button onClick={this.onCancel}>Cancel</Button>
        </div>
      </div>
    );
  }
}

export default ReviewPublishModal;
