import {
  IVendorRiskWaiver,
  VendorRiskWaiverStatusType,
  VendorRiskWaiverRiskType,
  VendorRiskWaiverRiskTypeToVendorSummaryRiskType,
  WaiverType,
} from "../../../_common/types/vendorRiskWaivers";
import ExpandableItem from "../../../_common/components/ExpandableItem";
import { SurveyDetail } from "../../../_common/types/vendor";
import SlidePanel from "../../../_common/components/SlidePanel";
import Button, { TooltipButton } from "../../../_common/components/core/Button";
import UserDisplay from "../UserDisplay";
import { formatDateAsLocal } from "../../../_common/helpers";
import CommentDisplay, { CommentDisplayStyling } from "../CommentDisplay";
import { getRiskWaiverStatusPill } from "../VendorRiskWaiversCard";
import { IVendorWords } from "../../../_common/constants";
import InfoTable, {
  InfoTableRow,
  InfoTableStyling,
} from "../../../_common/components/InfoTable";
import QuestionnaireSelection from "./QuestionnaireSelection";
import { SeverityAsString } from "../../../_common/types/severity";
import { SeverityChangeDisplay } from "../../../_common/components/SeverityIcon";
import ScrollableDiv from "../ScrollableDiv";
import { CheckItemSlidePanelSection } from "../filter/SlidePanelSection";
import { RiskDescription } from "../RiskSummaryDetails";

interface VendorRiskWaiverPanelProps {
  isShowing: boolean;
  waiver?: IVendorRiskWaiver;
  allowEdit?: boolean;
  allowApprove?: boolean;
  onClose: () => void;
  onEdit: () => void;
  onDelete: () => void;
  onApprove: (approve: boolean) => void;
  datastoreVendorId: number;
  vendorName: string;
  vendorWords: IVendorWords;
}

const VendorRiskWaiverPanel = (props: VendorRiskWaiverPanelProps) => {
  let waivedForDisplay = undefined;

  if (props.waiver?.isAllDomains) {
    waivedForDisplay = <span>All domains & IPs</span>;
  } else if (props.waiver?.domains?.length === 1) {
    waivedForDisplay = <span>{props.waiver?.domains[0]}</span>;
  } else if (props.waiver?.domains && props.waiver.domains.length > 1) {
    waivedForDisplay = (
      <ExpandableItem header={`${props.waiver?.domains.length} domains & IPs`}>
        <ul className={"website-list"}>
          {props.waiver?.domains.map((w) => (
            <li key={w} title={w}>
              {w}
            </li>
          ))}
        </ul>
      </ExpandableItem>
    );
  } else if (props.waiver?.riskType === VendorRiskWaiverRiskType.Evidence) {
    waivedForDisplay = <span>All documents</span>;
  }

  const privateWaivedSurveys = props.waiver?.surveysDetails.map((sd) => {
    return {
      surveyId: sd.id,
      surveyName: sd.name,
      why: sd.why,
      explanation: sd.explanation,
      avatar: sd.avatar,
      author: sd.author,
      sharedAt: sd.sharedAt,
      surveyLastSent: sd.surveyLastSent,
      surveyLastSentBy: sd.surveyLastSentBy,
    } as SurveyDetail;
  });
  const publicWaivedSurveys = props.waiver?.publicSurveysDetails.map((sd) => {
    return {
      surveyId: sd.id,
      surveyName: sd.name,
      why: sd.why,
      explanation: sd.explanation,
      avatar: sd.avatar,
      author: sd.author,
      sharedAt: sd.sharedAt,
      surveyLastSent: sd.surveyLastSent,
      surveyLastSentBy: sd.surveyLastSentBy,
      publicSurvey: true,
    } as SurveyDetail;
  });
  const waivedSurveys = [
    ...(privateWaivedSurveys || []),
    ...(publicWaivedSurveys || []),
  ];

  const isActivePublic =
    !!props.waiver?.publicWaiverId &&
    props.waiver?.status === VendorRiskWaiverStatusType.Active;

  const deleteText = isActivePublic ? "Reject waiver" : "Delete";
  const deleteIconClass = isActivePublic
    ? "cr-icon-minus-circle"
    : "cr-icon-trash-2";
  const tooltipContent = isActivePublic ? (
    <>
      Rejecting this waiver will <b>add</b> this risk to the{" "}
      {props.vendorWords.possessive} risk profile
    </>
  ) : undefined;

  const waiverHasApprover = !!props.waiver?.approverEmail;
  const waiverWasApprovedByApprover =
    waiverHasApprover &&
    props.waiver?.status === VendorRiskWaiverStatusType.Active;
  const waiverWasRejectedByApprover =
    waiverHasApprover &&
    props.waiver?.status === VendorRiskWaiverStatusType.Rejected;
  const waiverApproverHasDecided =
    waiverWasApprovedByApprover || waiverWasRejectedByApprover;
  const waiverCreatorJustification = props.waiver?.justification;
  const waiverApproverJustification = props.waiver?.approverReason;

  return (
    <SlidePanel
      dimContent
      onClose={props.onClose}
      active={props.isShowing}
      className={"view-vendor-risk-waiver-panel"}
      newStyles
    >
      <>
        <div className="title-container">
          <h2 className="title">{props.waiver?.riskName}</h2>
          <div className="button-container">
            {props.allowEdit && (
              <>
                {props.waiver?.status !== VendorRiskWaiverStatusType.Rejected &&
                  !props.waiver?.publicWaiverId && (
                    <Button onClick={props.onEdit}>
                      <i className={"cr-icon-pencil"} />
                      Edit
                    </Button>
                  )}
                <TooltipButton
                  danger
                  onClick={props.onDelete}
                  popupPosition={"bottom"}
                  tooltipContent={tooltipContent}
                >
                  <i className={deleteIconClass} />
                  {deleteText}
                </TooltipButton>
              </>
            )}
            {props.allowApprove &&
              props.waiver?.status ===
                VendorRiskWaiverStatusType.AwaitingApproval && (
                <>
                  <Button green onClick={() => props.onApprove(true)}>
                    <i className={"cr-icon-accepted"} />
                    Approve
                  </Button>
                  <Button danger onClick={() => props.onApprove(false)}>
                    <i className={"cr-icon-exclamation"} />
                    Reject
                  </Button>
                </>
              )}
          </div>
        </div>
        <div className="content-container">
          <ScrollableDiv newStyles>
            <CheckItemSlidePanelSection
              title={
                props.waiver?.waiverType !== WaiverType.SeverityAdjustment
                  ? "Waiver details"
                  : "Adjustment details"
              }
              startExpanded={true}
            >
              <InfoTable styling={InfoTableStyling.New} bordered>
                <InfoTableRow
                  hide={
                    props.waiver?.waiverType !== WaiverType.SeverityAdjustment
                  }
                  label={"Severity Change"}
                  value={
                    <SeverityChangeDisplay
                      baseSeverity={SeverityAsString(
                        props.waiver?.riskSeverity ?? 0
                      )}
                      severity={
                        props.waiver?.adjustedSeverity ?? SeverityAsString(0)
                      }
                    />
                  }
                />
                <InfoTableRow
                  label={
                    props.waiver?.waiverType !== WaiverType.SeverityAdjustment
                      ? "WAIVER STATUS"
                      : "ADJUSTMENT STATUS"
                  }
                  value={
                    props.waiver ? (
                      getRiskWaiverStatusPill(props.waiver, props.vendorName)
                    ) : (
                      <></>
                    )
                  }
                />
                <InfoTableRow
                  label={"EXPIRY"}
                  value={
                    props.waiver?.expiresAt
                      ? formatDateAsLocal(props.waiver?.expiresAt)
                      : "Never"
                  }
                />
                <InfoTableRow
                  label={"DATE CREATED"}
                  value={formatDateAsLocal(props.waiver?.createdAt)}
                />
                <InfoTableRow
                  label={"CREATOR"}
                  value={
                    <UserDisplay
                      avatar={props.waiver?.createdByAvatar}
                      name={props.waiver?.createdByName}
                      email={props.waiver?.createdByEmail}
                    />
                  }
                />
                <InfoTableRow
                  label={
                    waiverHasApprover
                      ? "JUSTIFICATION (CREATOR)"
                      : "JUSTIFICATION"
                  }
                  value={
                    <CommentDisplay
                      styling={CommentDisplayStyling.Shaded}
                      avatar={
                        props.waiver?.publicWaiverId
                          ? undefined
                          : props.waiver?.createdByAvatar
                      }
                      name={
                        props.waiver?.publicWaiverId
                          ? props.vendorName
                          : props.waiver?.createdByName
                      }
                      date={props.waiver?.createdAt || props.waiver?.updatedAt}
                      comment={waiverCreatorJustification}
                    />
                  }
                />
                <InfoTableRow
                  hide={!waiverHasApprover}
                  label={"APPROVER"}
                  value={
                    <UserDisplay
                      avatar={props.waiver?.approverAvatar}
                      name={props.waiver?.approverName}
                      email={props.waiver?.approverEmail}
                    />
                  }
                />
                <InfoTableRow
                  hide={!waiverApproverHasDecided}
                  label={
                    waiverWasRejectedByApprover
                      ? "REASON FOR REJECTION (APPROVER)"
                      : "JUSTIFICATION (APPROVER)"
                  }
                  value={
                    <CommentDisplay
                      styling={CommentDisplayStyling.Shaded}
                      avatar={props.waiver?.approverAvatar}
                      name={props.waiver?.approverName}
                      date={props.waiver?.activeAt}
                      comment={waiverApproverJustification}
                    />
                  }
                />
                <InfoTableRow
                  label={
                    props.waiver?.waiverType !== WaiverType.SeverityAdjustment
                      ? "WAIVED FOR"
                      : "ADJUSTED FOR"
                  }
                  value={
                    <QuestionnaireSelection
                      questionnaires={waivedSurveys}
                      selectedQuestionnaireIds={waivedSurveys
                        .filter((s) => !s.publicSurvey)
                        .map((s) => s.surveyId)}
                      selectedPublicQuestionnaireIds={waivedSurveys
                        .filter((s) => s.publicSurvey)
                        .map((s) => s.surveyId)}
                      viewMode={true}
                      datastoreVendorId={props.datastoreVendorId}
                      showLinks
                    />
                  }
                  hide={!(waivedSurveys && waivedSurveys.length > 0)}
                />
                <InfoTableRow
                  label={
                    props.waiver?.waiverType !== WaiverType.SeverityAdjustment
                      ? "WAIVED FOR"
                      : "ADJUSTED FOR"
                  }
                  value={waivedForDisplay || ""}
                  hide={!waivedForDisplay}
                />
              </InfoTable>
            </CheckItemSlidePanelSection>
            <CheckItemSlidePanelSection
              title="Risk information"
              startExpanded={true}
            >
              <InfoTable styling={InfoTableStyling.New} bordered>
                <InfoTableRow
                  label={"SUMMARY"}
                  value={
                    props.waiver && (
                      <RiskDescription
                        risk={{
                          riskType:
                            VendorRiskWaiverRiskTypeToVendorSummaryRiskType(
                              props.waiver.riskType
                            ),
                          description: props.waiver.riskText,
                          summary: props.waiver.riskSummary,
                        }}
                      />
                    )
                  }
                />
                <InfoTableRow
                  label={"RISK DETAILS"}
                  value={<p>{props.waiver?.riskDetails}</p>}
                  hide={!props.waiver?.riskDetails}
                />
                <InfoTableRow
                  label={"RECOMMENDED REMEDIATION"}
                  value={<p>{props.waiver?.riskRecommendedRemediation}</p>}
                  hide={!props.waiver?.riskRecommendedRemediation}
                />
              </InfoTable>
            </CheckItemSlidePanelSection>
          </ScrollableDiv>
        </div>
      </>
    </SlidePanel>
  );
};

export default VendorRiskWaiverPanel;
