import { FC } from "react";
import PillLabel from "../../vendorrisk/components/PillLabel";
import { LabelColor } from "../../_common/types/label";
import LabelList from "../../vendorrisk/components/LabelList";

interface OrgScopeCategoriesProps {
  categories: string[];
  maxWidth?: number;
}

const OrgScopeCategories: FC<OrgScopeCategoriesProps> = ({
  maxWidth,
  categories,
}) => {
  return (
    <div>
      {categories.length === 0 ? (
        <PillLabel color={LabelColor.Grey}>Unknown</PillLabel>
      ) : (
        <LabelList
          maxWidth={maxWidth}
          labels={categories.slice().map((cat) => ({
            id: cat,
            name: cat,
            color: LabelColor.Grey,
          }))}
        />
      )}
    </div>
  );
};

export default OrgScopeCategories;
