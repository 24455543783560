import { DefaultThunkDispatchProp } from "../../../_common/types/redux";
import {
  FC,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

import { get as _get } from "lodash";
import {
  OrgAccessCustomReportingTemplates,
  OrgAccessVendorAssessments,
  OrgAccessVendors,
  UserVendorRiskEnabled,
  UserWriteCustomReportingTemplates,
  UserWriteOwnOrganisation,
} from "../../../_common/permissions";
import { useModalV2 } from "../../../_common/components/ModalV2";
import ExecutiveReportingModal, {
  ExecutiveReportingMode,
} from "../../../_common/components/modals/ExecutiveReportingModal";
import {
  BadgeImageRefs,
  BadgeModule,
  CustomImageRef,
  ExecutiveReportType,
  getReportButtonDefinition,
  IButtonDef,
  imageRef,
} from "./ReportTypeBadge";
import { History } from "history";
import { AssuranceType } from "../../../_common/types/organisations";
import {
  CannedExportConfig,
  CustomCannedExportConfig,
  DefaultCannedExportConfig,
  StarredExportType,
} from "../../../_common/types/exportConfig";
import { addDefaultUnknownErrorAlert } from "../../../_common/reducers/messageAlerts.actions";
import ReportsLibraryModal from "./ReportsLibraryModal";
import { ExportType } from "../../../_common/types/exportReport";
import LoadingBanner from "../../../_common/components/core/LoadingBanner";
import { getVendorWords } from "../../../_common/constants";
import { locationState } from "../../../_common/types/router";
import {
  deleteCannedExportConfig,
  fetchCannedExportConfigs,
  setStarredExportType,
} from "../../reducers/cannedExports.actions";
import { sortBy as _sortBy } from "lodash";
import { IReportGenerateLocationState } from "../../views/reporting/ReportGenerate";
import { IReportTemplateCreateLocationState } from "../../views/reporting/ReportTemplateCreate";
import { useConfirmationModalV2 } from "../../../_common/components/modals/ConfirmationModalV2";
import ReportCard from "../../../_common/components/ReportCard";
import TabButtons from "../../../_common/components/TabButtons";
import ReportsLibraryGrid from "./ReportsLibraryGrid";
import { usePrevious } from "../../helpers/util";
import SearchBox from "../../../_common/components/SearchBox";
import "../../style/components/reporting/ReportsLibrary.scss";
import Button from "../../../_common/components/core/Button";
import {
  useLocalStorageState,
  useSelectableArray,
} from "../../../_common/hooks";
import MultiSelectionButton, {
  IOption,
} from "../../../_common/components/MultiSelectionButton";
import ReportsLibraryTable from "./ReportsLibraryTable";
import { appConnect } from "../../../_common/types/reduxHooks";

type tabID = "grid" | "list";

export interface IReportLibraryEntryOld {
  id: string;
  exportType?: ExportType;
  reportDef: IButtonDef;
  onClickGenerate: () => void;
  onClickQuickExport?: () => void;
}

export interface IReportsLibraryItem {
  id: string;
  defaultCannedConfig?: DefaultCannedExportConfig;
  customCannedConfig?: CustomCannedExportConfig;
  otherReportID?: string;
  adhocGenerateAction?: () => void;
  reportType: string;
  module: BadgeModule;
  name: string;
  description: ReactNode;
  badgeImageRef: imageRef;
  starred: boolean;
  originallyStarred: boolean;
  isNew?: boolean;
}

interface IReportsLibraryOwnProps {
  history: History;
}

interface IReportsLibraryConnectedProps {
  defaultCannedExportConfigs?: DefaultCannedExportConfig[];
  customCannedExportConfigs?: CustomCannedExportConfig[];
  starredExportTypes?: StarredExportType[];

  orgHasVendorRiskEnabled: boolean;
  orgHasAssessmentsEnabled: boolean;
  orgHasCustomReportingTemplatesEnabled: boolean;

  userHasGlobalVendorRiskPermission: boolean;
  userHasPortfolioSpecificVendorRiskPermission: boolean;
  userHasWriteOwnOrganisationPermission: boolean;
  userCanWriteCustomTemplates: boolean;

  currentUserId: number;
  assuranceType: AssuranceType;
}

type IReportsLibraryProps = IReportsLibraryOwnProps &
  IReportsLibraryConnectedProps &
  DefaultThunkDispatchProp;

const badgeModulesInOrder = [
  BadgeModule.Upguard,
  BadgeModule.Breachsight,
  BadgeModule.VendorRisk,
];

const ReportsLibrary: FC<IReportsLibraryProps> = ({
  history,
  dispatch,
  assuranceType,
  defaultCannedExportConfigs,
  customCannedExportConfigs,
  starredExportTypes,
  orgHasVendorRiskEnabled,
  orgHasAssessmentsEnabled,
  orgHasCustomReportingTemplatesEnabled,
  userHasGlobalVendorRiskPermission,
  userHasPortfolioSpecificVendorRiskPermission,
  userHasWriteOwnOrganisationPermission,
  userCanWriteCustomTemplates,
  currentUserId,
}) => {
  const [openExecutiveReportingModal, executiveReportingModal] = useModalV2(
    ExecutiveReportingModal
  );
  const [openReportsLibraryModal, reportsLibraryModal] =
    useModalV2(ReportsLibraryModal);
  const [openConfirmationModal, confirmationModal] = useConfirmationModalV2();
  const [currentTab, setCurrentTab] = useLocalStorageState<tabID>(
    "reportsLibraryTab",
    "grid"
  );
  const [searchText, setSearchText] = useState("");

  // Modules to filter by - select all by default
  const [filteredModules, setFilteredModule, setFilteredModulesArray] =
    useSelectableArray<string>();
  const [
    filteredReportTypes,
    setFilteredReportType,
    setFilteredReportTypesArray,
  ] = useSelectableArray<string>();

  // Keep track of which items were originally starred at mount. When the user
  // stars/unstars items we don't want them to jump around in the sort order immediately.
  const [starredForSorting, setStarredForSorting] =
    useState(starredExportTypes);

  const prevStarred = usePrevious(starredExportTypes);

  // When we load the starred export types for the first time, set starredForSorting
  useEffect(() => {
    if (!prevStarred && starredExportTypes) {
      setStarredForSorting(starredExportTypes);
    }
  }, [prevStarred, starredExportTypes]);

  useEffect(() => {
    if (!defaultCannedExportConfigs) {
      dispatch(fetchCannedExportConfigs(false)).catch((e) => {
        console.error(e);
        dispatch(
          addDefaultUnknownErrorAlert(
            "Error fetching report types. Please contact UpGuard Support."
          )
        );
      });
    }
  }, [dispatch, defaultCannedExportConfigs]);

  const onCreateNewTemplate = useCallback(
    () =>
      history.push("/reportexports/newtemplate", {
        backContext: {
          goBack: true,
          backToText: "Back to Templates",
        },
      }),
    [history]
  );

  const onCustomizeAndGenerate = useCallback(
    (conf: CannedExportConfig) => {
      const baseDefaultConfig = conf.isDefault ? conf : conf.baseDefaultConfig;

      (history as History<locationState & IReportGenerateLocationState>).push(
        "/reportexports/create",
        {
          reportType: baseDefaultConfig.exportType,
          filetype: baseDefaultConfig.filetype,
          baseDefaultID: conf.isDefault ? conf.defaultID : undefined,
          baseUUID: conf.isDefault ? undefined : conf.uuid,
          createdBy: conf.isDefault ? undefined : conf.createdBy,
          cannedConfig: conf.config,
          cannedReportName: conf.name,
          cannedReportDescription: conf.description,
          cannedReportBadgeID: baseDefaultConfig.badgeImageID,
          vendorIdRequired: baseDefaultConfig.vendorIDRequired,
          backContext: {
            goBack: true,
            backToText: "Back to Templates",
          },
        }
      );
    },
    [history]
  );

  const onQuickGenerate = useCallback(
    (conf: CannedExportConfig) =>
      openReportsLibraryModal({
        history: history,
        initialSelectedCannedExportConfig: conf,
      }),
    [openReportsLibraryModal, history]
  );

  const onCloneTemplate = useCallback(
    (conf: CannedExportConfig) =>
      (history as History<IReportTemplateCreateLocationState>).push(
        "/reportexports/newtemplate",
        {
          baseTemplate: conf,
        }
      ),
    [history]
  );

  const onEditTemplate = useCallback(
    (conf: CustomCannedExportConfig) =>
      (history as History<IReportTemplateCreateLocationState>).push(
        "/reportexports/newtemplate",
        {
          baseTemplate: conf,
          editingUUID: conf.uuid,
        }
      ),
    [history]
  );

  const onDeleteTemplate = useCallback(
    (conf: CustomCannedExportConfig) =>
      openConfirmationModal({
        title: "Are you sure you want to delete this template?",
        description: `The report template "${conf.name}" will no longer be available for use in your organization.`,
        buttonText: "Delete",
        dangerousAction: true,
        cancelText: "Cancel",
        buttonAction: async () => {
          try {
            await dispatch(deleteCannedExportConfig(conf.uuid));
            await dispatch(fetchCannedExportConfigs(true));
          } catch (e) {
            console.error(e);
            addDefaultUnknownErrorAlert("Error deleting template");
            throw e;
          }
        },
      }),
    [openConfirmationModal, dispatch]
  );

  const onStarTemplate = useCallback(
    async (starredExportType: StarredExportType, starred: boolean) => {
      try {
        dispatch(setStarredExportType(starredExportType, starred));
      } catch (e) {
        console.error(e);
        addDefaultUnknownErrorAlert("Error setting star status of template");
      }
    },
    [dispatch]
  );

  const onResetFilteredModules = useCallback(
    () => setFilteredModulesArray([]),
    [setFilteredModulesArray]
  );
  const onResetFilteredReportTypes = useCallback(
    () => setFilteredReportTypesArray([]),
    [setFilteredReportTypesArray]
  );

  const otherReportLibraryEntries = useMemo(() => {
    const vendorWords = getVendorWords(assuranceType);

    const userHasGlobalVendorRisk =
      orgHasVendorRiskEnabled && userHasGlobalVendorRiskPermission;
    const userHasAnyVendorRisk =
      userHasGlobalVendorRisk ||
      (orgHasVendorRiskEnabled && userHasPortfolioSpecificVendorRiskPermission);

    const otherReportLibraryEntries: IReportLibraryEntryOld[] = [];

    if (userHasAnyVendorRisk) {
      if (orgHasAssessmentsEnabled) {
        otherReportLibraryEntries.push({
          id: ExecutiveReportType.RiskAssessmentSummary,
          reportDef: getReportButtonDefinition(
            ExecutiveReportType.RiskAssessmentSummary,
            vendorWords,
            true,
            orgHasAssessmentsEnabled
          ),
          onClickGenerate: () => {
            openExecutiveReportingModal({
              preSelectedReportType: ExecutiveReportType.RiskAssessmentSummary,
              mode: ExecutiveReportingMode.VendorRiskReporting,
              history: history,
            });
          },
        });
      }

      otherReportLibraryEntries.push({
        id: ExecutiveReportType.VendorRiskExecSummaryReport,
        reportDef: getReportButtonDefinition(
          ExecutiveReportType.VendorRiskExecSummaryReport,
          vendorWords,
          true,
          orgHasAssessmentsEnabled
        ),
        onClickGenerate: () => {
          openExecutiveReportingModal({
            preSelectedReportType:
              ExecutiveReportType.VendorRiskExecSummaryReport,
            mode: ExecutiveReportingMode.VendorRiskReporting,
            history: history,
          });
        },
      });

      otherReportLibraryEntries.push({
        id: ExecutiveReportType.VendorComparisonReport,
        reportDef: getReportButtonDefinition(
          ExecutiveReportType.VendorComparisonReport,
          vendorWords,
          true,
          orgHasAssessmentsEnabled
        ),
        onClickGenerate: () => {
          openExecutiveReportingModal({
            preSelectedReportType: ExecutiveReportType.VendorComparisonReport,
            mode: ExecutiveReportingMode.VendorRiskReporting,
            history: history,
          });
        },
      });
    }

    return otherReportLibraryEntries;
  }, [
    assuranceType,
    openExecutiveReportingModal,
    orgHasAssessmentsEnabled,
    orgHasVendorRiskEnabled,
    userHasGlobalVendorRiskPermission,
    userHasPortfolioSpecificVendorRiskPermission,
  ]);

  // We need to combine our three types of available reports and sort them for our grid layout.
  const [libraryItems, unfilteredLibraryItems] = useMemo(() => {
    if (
      !defaultCannedExportConfigs ||
      !customCannedExportConfigs ||
      !starredExportTypes ||
      !starredForSorting
    ) {
      return [undefined, undefined];
    }

    let unfilteredLibraryItems: IReportsLibraryItem[] =
      defaultCannedExportConfigs.map((conf) => {
        return {
          id: `default_${conf.defaultID}`,
          defaultCannedConfig: conf,
          reportType: conf.reportTypeName,
          name: conf.name,
          description: conf.description,
          badgeImageRef: BadgeImageRefs[conf.badgeImageID],
          module: conf.module,
          starred: !!starredExportTypes.find(
            (e) => e.defaultCannedConfigID === conf.defaultID
          ),
          originallyStarred: !!starredForSorting.find(
            (e) => e.defaultCannedConfigID === conf.defaultID
          ),
        };
      });

    unfilteredLibraryItems.push(
      ...customCannedExportConfigs.map((conf) => {
        return {
          id: `custom_${conf.uuid}`,
          customCannedConfig: conf,
          reportType: conf.baseDefaultConfig.reportTypeName,
          name: conf.name,
          description: conf.description,
          badgeImageRef: CustomImageRef(conf.baseDefaultConfig.badgeImageID),
          module: conf.baseDefaultConfig.module,
          starred: !!starredExportTypes.find(
            (e) => e.customCannedExportUUID === conf.uuid
          ),
          originallyStarred: !!starredForSorting.find(
            (e) => e.customCannedExportUUID === conf.uuid
          ),
        };
      })
    );

    unfilteredLibraryItems.push(
      ...otherReportLibraryEntries.map((entry) => {
        return {
          id: `nontemplated_${entry.id}`,
          otherReportID: entry.id,
          adhocGenerateAction: entry.onClickGenerate,
          reportType: entry.reportDef.title,
          name: entry.reportDef.title,
          description: entry.reportDef.subtext,
          badgeImageRef: entry.reportDef.imageRef,
          module: entry.reportDef.module,
          starred: !!starredExportTypes.find(
            (e) => e.otherReportID === entry.id
          ),
          originallyStarred: !!starredForSorting.find(
            (e) => e.otherReportID === entry.id
          ),
        };
      })
    );

    // Sort the items
    unfilteredLibraryItems = _sortBy(
      unfilteredLibraryItems,
      (item) => !item.originallyStarred,
      (item) => badgeModulesInOrder.indexOf(item.module)
    );

    // Now filter them
    const searchTextLower = searchText.toLowerCase().trim();
    const libraryItems = unfilteredLibraryItems.filter(
      (item) =>
        // Filter by module
        (filteredModules.length === 0 ||
          filteredModules.includes(item.module)) &&
        // Filter by report type
        (filteredReportTypes.length === 0 ||
          filteredReportTypes.includes(item.reportType)) &&
        // Filter by search text
        (!searchText ||
          item.name.toLowerCase().includes(searchTextLower) ||
          item.reportType.toLowerCase().includes(searchTextLower) ||
          (typeof item.description === "string" &&
            item.description.toLowerCase().includes(searchTextLower)))
    );

    return [libraryItems, unfilteredLibraryItems];
  }, [
    defaultCannedExportConfigs,
    customCannedExportConfigs,
    otherReportLibraryEntries,
    starredForSorting,
    starredExportTypes,
    searchText,
    filteredModules,
    filteredReportTypes,
  ]);

  const moduleFilterOptions = useMemo(() => {
    // Include an option for each module that can be selected.
    const optionGroups: IOption[][] = [
      badgeModulesInOrder
        .filter(
          (module) =>
            !!(unfilteredLibraryItems ?? []).find(
              (item) => item.module === module
            )
        )
        .map((module) => ({
          label: module,
          value: module,
          selected: filteredModules.includes(module),
        })),
    ];

    return optionGroups;
  }, [unfilteredLibraryItems, filteredModules]);

  const reportTypeFilterOptions = useMemo(() => {
    // Include an option for each unique report type that can be selected.
    const reportTypes = new Set<string>();

    // Re-sort the library items by module only
    const resortedLibraryItems = _sortBy(
      [...(unfilteredLibraryItems ?? [])],
      (item) => badgeModulesInOrder.indexOf(item.module)
    );

    resortedLibraryItems.map((item) => reportTypes.add(item.reportType));

    const optionGroups: IOption[][] = [
      Array.from(reportTypes).map((reportType) => ({
        label: reportType,
        value: reportType,
        selected: filteredReportTypes.includes(reportType),
      })),
    ];

    return optionGroups;
  }, [unfilteredLibraryItems, filteredReportTypes]);

  if (!libraryItems) {
    return <LoadingBanner />;
  }

  return (
    <>
      <ReportCard newStyles className="reports-library">
        <div className="header">
          Templates
          <div className="header-right">
            {orgHasCustomReportingTemplatesEnabled &&
              userCanWriteCustomTemplates && (
                <Button onClick={onCreateNewTemplate}>
                  <div className="cr-icon-plus" /> Custom template
                </Button>
              )}
          </div>
        </div>
        <div className="search-filter">
          <SearchBox onChanged={setSearchText} value={searchText} />
          <MultiSelectionButton
            primary={false}
            text="Module"
            optionGroups={moduleFilterOptions}
            onSelectionChangedByValue={setFilteredModule}
            showCount={filteredModules.length > 0}
            onResetDefault={onResetFilteredModules}
            autoWidth
          />
          <MultiSelectionButton
            primary={false}
            text="Report type"
            optionGroups={reportTypeFilterOptions}
            onSelectionChangedByValue={setFilteredReportType}
            showCount={filteredReportTypes.length > 0}
            onResetDefault={onResetFilteredReportTypes}
            autoWidth
          />
          <TabButtons
            tabs={[
              {
                id: "grid",
                text: <div className="cr-icon-grid" />,
                popup: "Grid View",
                popupPosition: "top",
                popupDelay: 600,
                popupMicro: true,
              },
              {
                id: "list",
                text: <div className="cr-icon-list" />,
                popup: "List View",
                popupPosition: "top",
                popupDelay: 600,
                popupMicro: true,
              },
            ]}
            activeTabId={currentTab}
            onChangeTab={(tab) => {
              setCurrentTab(tab);

              // Also reset the starredForSorting cache when the tab changes
              setStarredForSorting(starredExportTypes);
            }}
            icons
          />
        </div>
        {currentTab === "grid" ? (
          <ReportsLibraryGrid
            libraryItems={libraryItems}
            onCustomizeAndGenerate={onCustomizeAndGenerate}
            onQuickGenerate={onQuickGenerate}
            onCloneTemplate={onCloneTemplate}
            onEditTemplate={onEditTemplate}
            onDeleteTemplate={onDeleteTemplate}
            onStarTemplate={onStarTemplate}
            canCreateEditOwnTemplates={
              orgHasCustomReportingTemplatesEnabled &&
              userCanWriteCustomTemplates
            }
            canCreateEditOrgTemplates={
              orgHasCustomReportingTemplatesEnabled &&
              userCanWriteCustomTemplates &&
              userHasWriteOwnOrganisationPermission
            }
            currentUserId={currentUserId}
          />
        ) : (
          <ReportsLibraryTable
            libraryItems={libraryItems}
            onCustomizeAndGenerate={onCustomizeAndGenerate}
            onQuickGenerate={onQuickGenerate}
            onCloneTemplate={onCloneTemplate}
            onEditTemplate={onEditTemplate}
            onDeleteTemplate={onDeleteTemplate}
            onStarTemplate={onStarTemplate}
            canCreateEditOwnTemplates={
              orgHasCustomReportingTemplatesEnabled &&
              userCanWriteCustomTemplates
            }
            canCreateEditOrgTemplates={
              orgHasCustomReportingTemplatesEnabled &&
              userCanWriteCustomTemplates &&
              userHasWriteOwnOrganisationPermission
            }
            orgSupportsCustomTemplates={orgHasCustomReportingTemplatesEnabled}
            currentUserId={currentUserId}
          />
        )}
      </ReportCard>
      {executiveReportingModal}
      {reportsLibraryModal}
      {confirmationModal}
    </>
  );
};

export default appConnect<
  IReportsLibraryConnectedProps,
  never,
  IReportsLibraryOwnProps
>((state) => {
  const newProps: IReportsLibraryConnectedProps = {
    assuranceType: state.common.userData.assuranceType,
    defaultCannedExportConfigs: state.cyberRisk.cannedExportConfigs?.default,
    customCannedExportConfigs: state.cyberRisk.cannedExportConfigs?.custom,
    starredExportTypes: state.cyberRisk.starredExportTypes,
    orgHasVendorRiskEnabled:
      state.common.userData.orgPermissions.includes(OrgAccessVendors),
    orgHasAssessmentsEnabled: state.common.userData.orgPermissions.includes(
      OrgAccessVendorAssessments
    ),
    orgHasCustomReportingTemplatesEnabled:
      state.common.userData.orgPermissions.includes(
        OrgAccessCustomReportingTemplates
      ),
    userCanWriteCustomTemplates:
      state.common.userData.userPermissions.includes(
        UserWriteCustomReportingTemplates
      ) ||
      !!Object.values(
        state.common.userData.vendorPortfolioSpecificPermissions
      ).find((perms) => perms?.includes(UserWriteCustomReportingTemplates)),
    userHasGlobalVendorRiskPermission:
      state.common.userData.userPermissions.includes(UserVendorRiskEnabled),
    userHasPortfolioSpecificVendorRiskPermission: !!Object.values(
      state.common.userData.vendorPortfolioSpecificPermissions
    ).find((perms) => perms?.includes(UserVendorRiskEnabled)),
    userHasWriteOwnOrganisationPermission:
      state.common.userData.userPermissions.includes(UserWriteOwnOrganisation),
    currentUserId: state.common.userData.id,
  };

  return newProps;
})(ReportsLibrary);
