import { useEffect, useState } from "react";
import TextAreaWithPills, {
  IToken,
} from "../../../_common/components/TextAreaWithPills";
import { validateEmail } from "../../../_common/helpers";
import { LabelColor } from "../../../_common/types/label";
import Button from "../../../_common/components/core/Button";
import Modal from "../../../_common/components/ModalV2";
import { setVipAccounts } from "../../reducers/cyberRiskActions";
import {
  addDefaultSuccessAlert,
  addDefaultUnknownErrorAlert,
  addSimpleErrorAlert,
} from "../../../_common/reducers/messageAlerts.actions";
import { DefaultThunkDispatchProp } from "../../../_common/types/redux";
import "../../style/components/AddVIPEmailsModal.scss";

interface IAddVIPEmailsModalProps extends DefaultThunkDispatchProp {
  active: boolean;
  onClose: () => void;
  reloadData: () => void;
}

export const AddVIPEmailsModal = ({
  active,
  onClose,
  dispatch,
  reloadData,
}: IAddVIPEmailsModalProps): JSX.Element => {
  const [isAdding, setIsAdding] = useState(false);
  const [emails, setEmails] = useState<string[]>([]);

  useEffect(() => {
    if (active) {
      setEmails([]);
    }
  }, [active]);

  const add = () => {
    setIsAdding(true);

    dispatch(setVipAccounts(emails, true))
      .then(() => {
        dispatch(addDefaultSuccessAlert("Emails have been tagged as VIP"));
        setIsAdding(false);
      })
      .then(() => {
        reloadData();
        onClose();
      })
      .catch((e) => {
        console.log(e);
        if (e.response.status === 422) {
          dispatch(addSimpleErrorAlert("Invalid email", [e.message]));
        } else {
          dispatch(
            addDefaultUnknownErrorAlert(
              "An error occurred tagging the emails",
              [e.message]
            )
          );
        }

        setIsAdding(false);
      });
  };

  const newEmails = (val: string[]) => {
    // Set incoming email values to lowercase before we add them
    const lowerCaseEmails = val.map((email) => email.toLowerCase());
    setEmails([...emails, ...lowerCaseEmails]);
  };

  const removedEmail = (idx: number) => {
    const newEmails = [...emails];
    newEmails.splice(idx, 1);
    setEmails(newEmails);
  };

  let isEmailsValid = true;
  const tokens = emails.reduce<IToken[]>((acc, email) => {
    const isValid =
      validateEmail(email) &&
      acc.findIndex((token) => token.value === email) === -1;
    let errMsg = "";
    if (!validateEmail(email)) {
      errMsg = "Invalid email address";
    } else if (acc.findIndex((token) => token.value === email) !== -1) {
      errMsg = "Duplicate address";
    }
    isEmailsValid = isEmailsValid && isValid;
    return [
      ...acc,
      {
        value: email,
        color: isValid ? LabelColor.Blue : LabelColor.Red,
        popupMsg: errMsg,
      },
    ];
  }, []);

  if (emails.length === 0) {
    isEmailsValid = false;
  }

  return (
    <Modal
      headerClassName={"add-vip-emails-modal-header"}
      headerContent={
        <>
          <h2>Add emails to the VIP list for identity breaches</h2>
          <p>
            Enter the important email addresses below. You can type them
            individually or paste a list separated by spaces, commas or tabbed.
          </p>
        </>
      }
      className={"add-vip-emails-modal"}
      active={active}
      onClose={onClose}
      footerContent={
        <div className={"btn-group"}>
          <Button tertiary onClick={() => onClose()} disabled={isAdding}>
            Cancel
          </Button>
          <Button
            primary
            onClick={add}
            loading={isAdding}
            disabled={isAdding || !isEmailsValid}
          >
            Add emails
          </Button>
        </div>
      }
    >
      <div>
        <div className={"emails-label"}>Enter email addresses</div>
        <TextAreaWithPills
          onNewTokens={newEmails}
          onRemovedToken={removedEmail}
          tokens={tokens}
          defaultColor={LabelColor.Blue}
          delimiters={[",", " ", "\n"]}
        />
      </div>
    </Modal>
  );
};
