import { FC } from "react";
import { OrgAccessAppGuard } from "../../../_common/permissions";
import SlidePanelSection from "../../../vendorrisk/components/filter/SlidePanelSection";
import appguardApi from "../../api/appguard.api";
import { SelectV2Multi } from "../../../_common/components/SelectV2";
import withRequireEntitlement from "../../../_common/hocs/WithRequireEntitlement";

interface CloudConnectionFilterProps {
  selectedUUIDs: string[];
  onChange: (selectedUUIDs: string[]) => void;
}

const CloudConnectionFilter: FC<CloudConnectionFilterProps> = ({
  selectedUUIDs,
  onChange,
}) => {
  const { data, isLoading } = appguardApi.useGetCloudConnectionsV1Query();

  return (
    <SlidePanelSection title={"Cloud Connections"}>
      <SelectV2Multi
        isLoading={isLoading}
        isMulti
        options={
          data?.cloudConnections.map((c) => {
            return {
              value: c.uuid,
              label: c.nickname,
            };
          }) ?? []
        }
        menuPlacement={"top"}
        value={
          data?.cloudConnections
            .filter((c) => selectedUUIDs.find((fc) => fc === c.uuid))
            .map((c) => {
              return {
                value: c.uuid,
                label: c.nickname,
              };
            }) ?? []
        }
        onChange={(values) => {
          onChange(values ? values.map((v) => v.value) : []);
        }}
      />
    </SlidePanelSection>
  );
};

export default withRequireEntitlement(CloudConnectionFilter, OrgAccessAppGuard);
