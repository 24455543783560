import { IUserTaskData } from "../types/user";

export const SET_USER_TASK_DATA = "SET_USER_TASK_DATA";

export const setUserTaskData = (userTaskData: IUserTaskData) => {
  return {
    type: SET_USER_TASK_DATA,
    userTaskData,
  };
};
