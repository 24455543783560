import { FC } from "react";
import "../style/MessageIcon.scss";
import classnames from "classnames";
import {
  ICorrespondenceMessage,
  SurveyQuestionMessageMeta,
} from "../../_common/types/correspondenceMessage";
import SurveyViewerIconButton from "./SurveyViewerIconButton";

interface MessageIconProps {
  highlight?: boolean;
  messageCount: number;
  onClick?: (ev?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  disabled?: boolean;
}

// A simple component for displaying a clickable icon button with a message count
const MessageIcon: FC<MessageIconProps> = ({
  highlight,
  messageCount,
  onClick,
  disabled,
}) => {
  return (
    <SurveyViewerIconButton
      highlight={highlight}
      onClick={onClick}
      tooltipContent={"Messages"}
      disabled={disabled}
      popupNoWrap
      className={classnames("message-icon", {
        "has-messages": messageCount > 0,
      })}
    >
      {messageCount > 0 && <div className={"count"}>{messageCount}</div>}
      <i className={"cr-icon-chat-messages"} />
    </SurveyViewerIconButton>
  );
};

export default MessageIcon;

// Recursively count individual messages in a set of messages
export const countSurveyMessages = (
  messages?: ICorrespondenceMessage<SurveyQuestionMessageMeta>[],
  ignoreReplies = false
): number => {
  if (!messages) {
    return 0;
  }

  let count = 0;

  messages.forEach((m) => {
    if (!m.meta || !m.meta.isQuestionAnswer) {
      count += 1;
    }

    if (!ignoreReplies && m.children && m.children.length > 0) {
      count = count + countSurveyMessages(m.children);
    }
  });

  return count;
};
