import Button from "../core/Button";
import "../../style/components/FreeTrialNavContent.scss";
import { ReactNode, useEffect, useState } from "react";
import ModalV2 from "../ModalV2";
import { IFreeTrialEligibility } from "../../types/freeTrial";
import Badge_AttackSurface from "../../images/free-trial/badge-attack-surface.svg";
import Badge_Compliance from "../../images/free-trial/badge-compliance.svg";
import Badge_Exclaim from "../../images/free-trial/badge-exclaim.svg";
import Badge_Metrics from "../../images/free-trial/badge-metrics.svg";
import Badge_ManualProcess from "../../images/free-trial/badge-manual-process.svg";
import Badge_Other from "../../images/free-trial/badge-other.svg";
import classnames from "classnames";
import Icon from "../core/Icon";
import { addDefaultUnknownErrorAlert } from "../../reducers/messageAlerts.actions";
import {
  acceptFreeTrialOffer,
  retrieveOnboardingCalendarForUser,
} from "../../reducers/freeTrial.actions";
import {
  reloadCompleteActivityStream,
  fetchUserData,
} from "../../reducers/commonActions";
import { useAppDispatch, useAppSelector } from "../../types/reduxHooks";
import { useDefaultHistory } from "../../types/router";

interface IFreeTrialUseCasesProps {
  initialSelections: string;
  initialOther: string;
  onSetOther: (txt: string) => void;
  onSetUseCases: (txt: string) => void;
}

export const FreeTrialUseCases = (props: IFreeTrialUseCasesProps) => {
  const { onSetOther, onSetUseCases, initialSelections, initialOther } = props;
  const [otherText, setOtherText] = useState(initialOther);

  const use = {} as {
    [str: string]: boolean;
  };

  if (initialSelections && initialSelections.length > 0) {
    const sel = initialSelections.split(";");
    sel.map((s) => {
      use[s] = true;
    });
  }
  const [useCases, setUseCases] = useState(use);
  useEffect(() => {
    // tell out owner about the new selection
    const selectedUseCases = [] as string[];
    for (let x = 0; x < Object.keys(useCases).length; x++) {
      const key = Object.keys(useCases)[x];
      if (useCases[key]) {
        selectedUseCases.push(key);
      }
    }
    const cases = selectedUseCases.join(";");
    onSetUseCases(cases);
  }, [useCases]);
  useEffect(() => {
    onSetOther(otherText);
  }, [otherText]);

  const useCaseOptions = [
    "Vendor risk management",
    "Attack surface management",
    "Security metrics and reporting",
    "Compliance",
    "Automate manual processes",
    "None of these",
  ];

  const useCaseBadges = [
    Badge_Exclaim,
    Badge_AttackSurface,
    Badge_Metrics,
    Badge_Compliance,
    Badge_ManualProcess,
    Badge_Other,
  ];

  const selectCase = (useCase: string, selected: boolean) => {
    if (useCase == "None of these" && selected) {
      setUseCases(() => ({
        [useCase]: selected,
      }));
      return;
    }
    if (useCase != "None of these" && selected) {
      setUseCases((prevState) => ({
        ...prevState,
        ["None of these"]: false,
        [useCase]: selected,
      }));
      setOtherText("");
      return;
    }
    setUseCases((prevState) => ({
      ...prevState,
      [useCase]: selected,
    }));
    setOtherText("");
  };

  const buttons: ReactNode[] = [];
  useCaseOptions.map((useCase, idx) => {
    buttons.push(
      <div
        key={`${idx}`}
        className={classnames("button", {
          selected: !!useCases[useCase],
        })}
        onClick={() => selectCase(useCase, !!!useCases[useCase])}
      >
        <div className={"icon-container"}>
          <div className={"icon"}>
            <img src={useCaseBadges[idx]} />
          </div>
        </div>
        <div className={"label-container"}>
          <div className={"label"}>{useCase}</div>
        </div>
      </div>
    );
  });

  return (
    <>
      <div className={"free-trial-use-cases-button-grid"}>{buttons}</div>
      {!!useCases["None of these"] && (
        <div className={"free-trial-use-cases-other-container"}>
          <div className={"other-title"}>
            Could you describe your cybersecurity goals?
          </div>
          <div className={"data"}>
            <textarea
              onChange={(e) => setOtherText(e.target.value)}
              placeholder="Describe your goals here"
              value={otherText}
              maxLength={200}
            ></textarea>
          </div>
        </div>
      )}
    </>
  );
};

interface IFreeTrialSignupModalProps {
  active: boolean;
  onClose: () => void;
  eligibility: IFreeTrialEligibility;
}

export const FreeTrialSignupModal = (props: IFreeTrialSignupModalProps) => {
  const { onClose, active, eligibility } = props;
  const dispatch = useAppDispatch();
  const userData = useAppSelector((state) => state.common.userData);
  const history = useDefaultHistory();

  useEffect(() => {
    setNewFirstName(userData.firstName);
    setNewLastName(userData.lastName);
    setNewJobTitle(userData.jobTitle);
  }, [userData]);

  const [newFirstName, setNewFirstName] = useState(userData.firstName);
  const [newLastName, setNewLastName] = useState(userData.lastName);
  const [newJobTitle, setNewJobTitle] = useState(userData.jobTitle);
  const [newPhoneNumber, setNewPhoneNumber] = useState("");
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [useCases, setUseCases] = useState("");
  const [otherText, setOtherText] = useState("");

  const mandatoryCheck = (): boolean => {
    if (page == 1) {
      return !!(newFirstName && newLastName && newJobTitle);
    }
    if (page == 2) {
      return useCases.length > 0;
    }
    return true;
  };

  // make sure phone number only accepts certain characters
  const setPhoneNumber = (val: string) => {
    if (/^[0-9\s\+]*$/.test(val)) {
      setNewPhoneNumber(val);
    }
  };

  const onAcceptOffer = async () => {
    try {
      await dispatch(
        acceptFreeTrialOffer(
          newFirstName,
          newLastName,
          eligibility.country,
          newJobTitle,
          newPhoneNumber,
          useCases,
          otherText
        )
      );
      dispatch(reloadCompleteActivityStream(false));
      await dispatch(fetchUserData());
      await dispatch(retrieveOnboardingCalendarForUser(eligibility.userId));
      history.push("/home");
    } catch (e) {
      console.log(`${e}`);
      dispatch(
        addDefaultUnknownErrorAlert(
          `Failed to sign-up for free trial offer. Please contact support@upguard.com.`
        )
      );
    }
    setLoading(false);
  };

  return (
    <ModalV2
      className={"free-trial-signup-modal"}
      active={active}
      onClose={() => {
        onClose();
        setPage(1);
      }}
      headerContent="Start your free trial"
      footerContent={
        <div className={"free-trial-signup-modal-footer"}>
          <div className="btn-group left free-trial-buttons">
            {page > 1 && !loading && (
              <Button onClick={() => setPage(page - 1)}>
                <Icon id="rarrow" name="arrow" direction={270} />
                {"Back"}
              </Button>
            )}
            {page == 2 && loading && (
              <span className={"progress-message"}>
                Setting up your free trial...
              </span>
            )}
          </div>
          <div className="btn-group free-trial-buttons">
            {page == 1 && (
              <Button
                className={"next"}
                disabled={!mandatoryCheck()}
                primary
                onClick={async () => {
                  setPage(page + 1);
                }}
              >
                Next <Icon name="arrow" direction={90} />
              </Button>
            )}
            {page == 2 && (
              <Button
                disabled={!mandatoryCheck()}
                primary
                loading={loading}
                onClick={async () => {
                  setLoading(true);
                  await onAcceptOffer();
                  onClose();
                }}
              >
                Start Free Trial
              </Button>
            )}
          </div>
        </div>
      }
    >
      {page == 1 && (
        <div className="simple-modal-form">
          <div className={"section-title"}>
            You’re only a moment away from getting full access to the UpGuard
            platform for 14 days.
          </div>
          <div className={"section-title-subtext"}>
            First, let’s confirm your details.
          </div>
          <div className={"value-list"}>
            <div className={"value-row"}>
              <div className={"label"}>{"Email"}</div>
              <div className={"read-only-data"}>{eligibility.userEmail}</div>
            </div>
            <div className={"value-row"}>
              <div className={"label"}>{"First Name*"}</div>
              <div className={"data"}>
                <input
                  type="text"
                  value={newFirstName}
                  onChange={(e) => setNewFirstName(e.target.value)}
                  placeholder="Enter first name"
                />
              </div>
            </div>
            <div className={"value-row"}>
              <div className={"label"}>{"Last Name*"}</div>
              <div className={"data"}>
                <input
                  type="text"
                  value={newLastName}
                  onChange={(e) => setNewLastName(e.target.value)}
                  placeholder="Enter last name"
                />
              </div>
            </div>
            <div className={"value-row"}>
              <div className={"label"}>{"Title*"}</div>
              <div className={"data"}>
                <input
                  type="text"
                  value={newJobTitle}
                  onChange={(e) => setNewJobTitle(e.target.value)}
                  placeholder="Enter your current job title"
                />
              </div>
            </div>
            <div className={"value-row"}>
              <div className={"label"}>{"Mobile Phone"}</div>
              <div className={"data"}>
                <input
                  type="tel"
                  value={newPhoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  placeholder="Enter your phone number"
                />
              </div>
            </div>
          </div>
        </div>
      )}
      {page == 2 && (
        <div className="simple-modal-form">
          <div className={"section-title bold"}>
            What are your organization&apos;s cybersecurity goals?
          </div>
          <div className={"section-title-subtext"}>
            We&apos;ll use this information to help you make the most of your
            trial. Select all that apply.
          </div>
          <FreeTrialUseCases
            onSetOther={setOtherText}
            onSetUseCases={setUseCases}
            initialSelections={useCases}
            initialOther={otherText}
          />
        </div>
      )}
    </ModalV2>
  );
};

export interface IFreeTrialNavContentProps {
  eligibility: IFreeTrialEligibility;
}

const FreeTrialNavContent = (props: IFreeTrialNavContentProps) => {
  const [modalVisible, setModalVisible] = useState(false);

  const startTrial = async () => {
    setModalVisible(true);
  };

  return (
    <>
      <div className={"free-trial-nav-content-container"}>
        <div className={"container-header"}>
          Continuously monitor your organization and vendors with UpGuard
        </div>
        <div className={"container-content"}>
          <p>
            Want to see your security performance? UpGuard automates vendor risk
            management, keeping your company protected from supply chain
            attacks.
          </p>
          <div className={"checklist-list"}>
            <div>Improve your own security performance</div>
            <div>Continuously monitor your vendors</div>
            <div>Remediate your cyber risks</div>
          </div>
          <Button arrow onClick={startTrial} disabled={modalVisible}>
            Try for free
          </Button>
          <div className={"button-subtext"}>
            {"Get started immediately, no credit card required"}
          </div>
        </div>
      </div>
      <FreeTrialSignupModal
        active={modalVisible}
        onClose={() => {
          setModalVisible(false);
        }}
        eligibility={props.eligibility}
      />
    </>
  );
};

export default FreeTrialNavContent;
