import { MouseEvent } from "react";
import classnames from "classnames";

import "../../style/components/core/ToggleSwitch.scss";
import { isClickLikeKeypress } from "../../../vendorrisk/components/ColorCheckbox";

export interface IToggleSwitchProps {
  disabled?: boolean;
  name: string;
  onChange?: () => void;
  onClick?: () => void;
  selected?: boolean;
  stopPropagation?: boolean;
}

const stopPropagationFunc = (e: MouseEvent) => {
  e.stopPropagation();
};

const ToggleSwitch = (props: IToggleSwitchProps) => {
  const { disabled, name, onChange, onClick, selected } = props;
  const cls = classnames("toggle-switch", { disabled });

  return (
    <div
      tabIndex={0}
      className={cls}
      onClick={props.stopPropagation ? stopPropagationFunc : undefined}
      onKeyPress={
        !disabled && onClick
          ? (ev) => {
              const isClickLike = isClickLikeKeypress(ev, true);
              if (isClickLike) {
                onClick();
              }
            }
          : undefined
      }
    >
      <input
        tabIndex={-1}
        type="checkbox"
        id={name}
        checked={selected}
        onChange={onChange}
      />
      <label onClick={onClick} htmlFor={name} tabIndex={undefined}>
        <div className="toggle-bit" />
      </label>
    </div>
  );
};

ToggleSwitch.defaultProps = {
  disabled: false,
  selected: false,
  onChange: () => undefined,
  onClick: () => undefined,
  stopPropagation: false,
};

export default ToggleSwitch;
