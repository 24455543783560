import { DefaultThunkDispatch } from "../../_common/types/redux";
import { FetchCyberRiskUrl } from "../../_common/api";
import { LogError } from "../../_common/helpers";
import {
  addDefaultSuccessAlert,
  addDefaultUnknownErrorAlert,
  addDefaultWarningAlert,
  addSimpleErrorAlert,
} from "../../_common/reducers/messageAlerts.actions";
import { DefaultRootState } from "react-redux";
import {
  Job,
  JobStatus,
  VendorImportJobMeta,
  VendorImportJobResult,
} from "../types/jobs";
import { fetchCustomerVendorsData, setCustomerData } from "./cyberRiskActions";

export const SET_VENDOR_IMPORT = "SET_VENDOR_IMPORT";
export const setVendorImport = (
  importDetails:
    | Job<VendorImportJobMeta, VendorImportJobResult>
    | null
    | undefined
) => {
  return {
    type: SET_VENDOR_IMPORT,
    importDetails,
  };
};

// Store a reference to an ongoing promise that we can return to consumers
let prom: Promise<GetImportVendorsV1Resp> | undefined = undefined;
let promOrgId: number | undefined = undefined;

interface GetImportVendorsV1Resp {
  currentImport?: Job<VendorImportJobMeta, VendorImportJobResult>;
}

// Fetch the current vendor import (if exists) for the current user/org
export const fetchVendorImport = (reset = false, poll = true) => {
  return async (
    dispatch: DefaultThunkDispatch,
    getState: () => DefaultRootState
  ) => {
    if (reset || getState().common?.userData?.currentOrgID !== promOrgId) {
      promOrgId = getState().common?.userData?.currentOrgID;
      prom = undefined;
    }
    if (prom) {
      return prom;
    }

    let resp: GetImportVendorsV1Resp;
    try {
      prom = FetchCyberRiskUrl<GetImportVendorsV1Resp>(
        "vendors/import/v1/",
        {},
        { method: "GET" },
        dispatch,
        getState
      );

      resp = await prom;
    } catch (e) {
      LogError("error retrieving vendor import details", e);
      dispatch(
        addDefaultUnknownErrorAlert("Error retrieving vendor import details")
      );

      throw e;
    }

    dispatch(setVendorImport(resp.currentImport ?? null));

    if (
      poll &&
      resp.currentImport?.status !== undefined &&
      resp.currentImport?.status < 2
    ) {
      dispatch(pollForImportResults());
    }

    return resp;
  };
};

export const pollForImportResults = () => {
  return async (dispatch: DefaultThunkDispatch) => {
    const intervalId = setInterval(() => {
      dispatch(fetchVendorImport(true, false))
        .then((resp) => {
          if (
            resp.currentImport?.status !== undefined &&
            resp.currentImport?.status > 1
          ) {
            if (resp.currentImport?.status === JobStatus.Complete) {
              dispatch(addDefaultSuccessAlert("Vendor import completed"));

              // Re-get the watched vendor list
              dispatch(fetchCustomerVendorsData(true));
            } else if (resp.currentImport.status === JobStatus.Error) {
              dispatch(
                addDefaultWarningAlert(
                  "Vendor import did not complete",
                  [
                    "An error occurred while processing your vendor import.",
                    "Please re-submit or contact support",
                  ],
                  0
                )
              );
            }
            clearInterval(intervalId);
          }
        })
        .catch(() => {
          clearInterval(intervalId);
        });
    }, 10000);
  };
};

interface ImportVendorsV1Body {
  domains: string[];
  completeExisting: boolean;
}

// Submit a vendor import for domain names for the current user/org
export const importVendorsByDomain = (
  domains: string[],
  completeExisting = false
) => {
  return async (
    dispatch: DefaultThunkDispatch,
    getState: () => DefaultRootState
  ) => {
    const body: ImportVendorsV1Body = {
      domains: domains.map((d) => d.toLocaleLowerCase()),
      completeExisting,
    };

    try {
      await FetchCyberRiskUrl(
        "vendors/import/v1/",
        {},
        { method: "POST", body: JSON.stringify(body) },
        dispatch,
        getState
      );
    } catch (e: any) {
      if (e?.response?.status === 422 && e?.json?.error) {
        dispatch(addSimpleErrorAlert(e.json.error));
      } else {
        LogError("error starting vendor import", e);
        dispatch(addDefaultUnknownErrorAlert("Error starting vendor import"));
      }
      throw e;
    }

    // Trigger the get
    dispatch(setVendorImport(undefined));
    dispatch(fetchVendorImport(true));
  };
};

export const importVendorByFile = (
  file: File,
  overwriteExistingPortfolios = false,
  overwriteExistingLabels = false,
  overwriteExistingTiers = false,
  overwriteExistingAttributes = false,
  completeExisting = false
) => {
  return async (
    dispatch: DefaultThunkDispatch,
    getState: () => DefaultRootState
  ) => {
    const data = new FormData();
    data.append("file", file);

    try {
      await FetchCyberRiskUrl(
        "vendors/import/file/v1/",
        {
          overwrite_existing_portfolios: overwriteExistingPortfolios,
          overwrite_existing_labels: overwriteExistingLabels,
          overwrite_existing_tiers: overwriteExistingTiers,
          overwrite_existing_attributes: overwriteExistingAttributes,
          complete_existing: completeExisting,
        },
        { method: "POST", body: data },
        dispatch,
        getState
      );
    } catch (e: any) {
      if (e?.response?.status === 422 && e?.json?.error) {
        dispatch(addSimpleErrorAlert(e.json.error));
      } else {
        LogError("error starting vendor import", e);
        dispatch(addDefaultUnknownErrorAlert("Error starting vendor import"));
      }
      throw e;
    }

    // Trigger the get
    dispatch(setVendorImport(undefined));
    dispatch(fetchVendorImport(true));
  };
};

interface UpdateImportVendorsV1Body {
  vendorsToDelete: { [vendorId: number]: boolean };
}

interface UpdateImportVendorsV1Resp {
  isDismissed: boolean;
  jobResult: VendorImportJobResult;
}

// Update the vendor import with any post-job changes
export const updateVendorImport = (
  vendorsToDelete: { [vendorId: number]: boolean } = {}
) => {
  return async (
    dispatch: DefaultThunkDispatch,
    getState: () => DefaultRootState
  ) => {
    const body: UpdateImportVendorsV1Body = {
      vendorsToDelete,
    };

    let resp: UpdateImportVendorsV1Resp;

    try {
      resp = await FetchCyberRiskUrl<UpdateImportVendorsV1Resp>(
        "vendors/import/v1/",
        {},
        { method: "PUT", body: JSON.stringify(body) },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("error updating vendor import", e);
      dispatch(addDefaultUnknownErrorAlert("Error updating vendor import"));

      throw e;
    }

    // Merge the changes in with the current import changes we have in redux
    const currentImport = { ...getState().cyberRisk.vendorImport } as Job<
      VendorImportJobMeta,
      VendorImportJobResult
    >;
    if (currentImport && currentImport.result) {
      if (!currentImport.result?.postCompletionChanges) {
        currentImport.result.postCompletionChanges = {
          vendorsDeleted: vendorsToDelete,
        };
      } else {
        currentImport.result.postCompletionChanges.vendorsDeleted = {
          ...currentImport.result.postCompletionChanges.vendorsDeleted,
          ...vendorsToDelete,
        };
      }
      if (resp.isDismissed) {
        if (!currentImport.dismissed) {
          dispatch(addDefaultSuccessAlert("Import completed"));
        }

        currentImport.dismissed = resp.isDismissed;
      }

      let loadedVendors = getState().cyberRisk.customerData.vendors.result;
      if (loadedVendors) {
        loadedVendors = loadedVendors.filter((v) => !vendorsToDelete[v.id]);
        dispatch(
          setCustomerData({
            vendors: {
              ...getState().cyberRisk.customerData.vendors,
              result: loadedVendors,
            },
          })
        );
      }

      dispatch(setVendorImport(currentImport));
    }
  };
};

export const completeVendorImport = () => {
  return async (
    dispatch: DefaultThunkDispatch,
    getState: () => DefaultRootState
  ) => {
    try {
      await FetchCyberRiskUrl(
        "vendors/import/complete/v1/",
        {},
        { method: "PUT" },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("error finalising vendor import", e);
      dispatch(addDefaultUnknownErrorAlert("Error finalising vendor import"));

      throw e;
    }

    const vendorImport = getState().cyberRisk.vendorImport;
    if (vendorImport?.status === JobStatus.Error) {
      dispatch(
        setVendorImport({
          ...vendorImport,
          dismissed: true,
        })
      );
    } else {
      dispatch(setVendorImport(null));
    }
  };
};
