import { Component } from "react";
import PropTypes from "prop-types";

import Button from "../../../_common/components/core/Button";
import { closeModal } from "../../../_common/reducers/commonActions";
import { uploadCyberEyeData } from "../../reducers/adminActions";
import {
  addDefaultSuccessAlert,
  addSimpleErrorAlert,
  clearMessageAlert,
} from "../../../_common/reducers/messageAlerts.actions";

export const AddCyberEyeModalName = "AddCyberEyeModalName";

class AddCyberEyeModal extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    modalData: PropTypes.object.isRequired,
  };

  state = { loading: false };

  onSubmit = (evt) => {
    evt.preventDefault();
    const file = this.fileInput.files[0];
    if (!file) {
      this.props.dispatch(
        addSimpleErrorAlert("File must be selected", [], "file-error")
      );
      return;
    }

    this.props.dispatch(clearMessageAlert("file-error"));
    this.setState({ loading: true });

    this.props
      .dispatch(uploadCyberEyeData(this.props.modalData.orgId, file))
      .then(() => {
        this.props.dispatch(addDefaultSuccessAlert("Updated BreachSight data"));
        this.props.dispatch(closeModal());
      })
      .catch((e) => {
        this.props.dispatch(
          addSimpleErrorAlert(`Error uploading BreachSight data: ${e.message}`)
        );
        this.setState({ loading: false });
      });
  };

  render() {
    return (
      <div id="new-org-modal">
        <div className="modal-content">
          <h2>Add BreachSight data</h2>
          <p>
            Adding BreachSight data to this org will make the &quot;BreachSight
            &quot; tab visible to the customer, displaying the latest JSON data
            that you upload here
          </p>
          <form onSubmit={this.onSubmit}>
            <label htmlFor="file">Select JSON file</label>
            <input
              type="file"
              name="file"
              required
              ref={(ref) => (this.fileInput = ref)}
            />

            <Button
              type="submit"
              primary
              loading={this.state.loading}
              disabled={this.state.loading}
            >
              Save
            </Button>
          </form>
        </div>
      </div>
    );
  }
}

export default AddCyberEyeModal;
