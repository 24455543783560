export type IntegrationTypeString =
  | "webhook"
  | "zapier"
  | "slack"
  | "jira"
  | "email";

export type IntegrationType = 1 | 2 | 3 | 4 | 5;

export const IntegrationTypes: {
  [key in IntegrationType]: IntegrationTypeString;
} = {
  1: "webhook",
  2: "zapier",
  3: "slack",
  4: "jira",
  5: "email",
};

export const IntegrationTypeStrings: {
  [key in IntegrationTypeString]: IntegrationType;
} = {
  webhook: 1,
  zapier: 2,
  slack: 3,
  jira: 4,
  email: 5,
};

// if the type string is valid that string is returned, otherwise default 'webhook' is returned
export const verifyIntegrationTypeString = (name: IntegrationTypeString) => {
  if (name in IntegrationTypeStrings) {
    return name;
  } else {
    return "webhook";
  }
};

export interface IStringMap {
  [name: string]: string | undefined;
}
export interface INameValuePair {
  name: string;
  value: string;
}

export interface IBasicAuth {
  username: string;
  password: string;
}

export type JiraFields = { [key: string]: { [key: string]: string } };
export type Param = { [key: string]: string };

export interface JiraProjectsList {
  projects?: {
    id: string;
    key: string;
    name: string;
  }[];
  error?: string | undefined;
  loading?: boolean;
}

export interface WebhookResponse {
  url?: string;
  status: string;
  status_code: number | string;
  proto: string;
  body?: string;
}

export interface IWebhook {
  id: number;
  integration_id: number;
  url: string;
  ignoreSSLCertCheck: false;
  createdAt: string;
  updatedAt: string;
  headerParams: Param;
  urlParams: Param;
  bodyParams: Param;
  basicAuthParams: IBasicAuth;
}

export interface IIntegration {
  id: number;
  uniqueId: string;
  description: string;
  organisationId: number;
  type: IntegrationType;
  typeName: string;
  url: string;
  uuids: string[];
  enabled: boolean;
  enabledText?: string;
  createdAt: string;
  updatedAt: string;
  webhooks: IWebhook[];
  payloadTemplates: IStringMap;
  messagingChannel?: string;
  jiraProjectName?: string;
  jiraProjectId?: string;
  jiraIssueType: string;
  jiraFields?: JiraFields;
  emailDestination?: IIntegrationEmailDestination;
  emailSubjects?: IStringMap;
  lastFired?: string;
}

export interface DisplayableIntegration extends IIntegration {
  typeName: string;
  url: string;
}

export type EmailDestinationDisabledReason =
  | "inactive_destination"
  | "domain_changed";

export const getEmailDestinationDisabledText = (
  reason?: EmailDestinationDisabledReason
) => {
  if (!reason) {
    return "Unknown error";
  }
  switch (reason) {
    case "domain_changed":
      return "The custom domain this integration was configured to send from has been disabled or deleted, edit the from address to re-enable it.";
    case "inactive_destination":
      return "We were unable to send an email to the to address configured on this integration, to re-enable it please edit the to address.";
  }
};

export interface IIntegrationEmailDestination {
  id: number;
  integrationId: number;
  toAddress: string;
  fromName: string;
  fromAddress?: string;
  disabledReason?: EmailDestinationDisabledReason;
  createdAt: string;
  updatedAt: string;
}

export interface IIntegrationPayloadTemplate {
  id: number;
  integrationID: number;
  uuid: string;
  template: string;
  createdAt: string;
  updatedAt: string;
}
