import React, { useState } from "react";
import {
  GetStatusFilterAttributeDisplayName,
  GetStatusFilterDisplayName,
  SurveyAttribute,
  SurveyStatus,
} from "../../../_common/types/survey";
import SlidePanelSection from "./SlidePanelSection";
import ColorCheckbox from "../ColorCheckbox";

interface ISurveyStatusFilterProps {
  selectedStatuses: SurveyStatus[];
  selectedAttributes: SurveyAttribute[];
  toggleStatus: (status: SurveyStatus) => void;
  toggleAttribute: (attribute: SurveyAttribute) => void;
  title?: string;
  startExpanded?: boolean;
}

const SurveyStatusFilter: React.VFC<ISurveyStatusFilterProps> = (props) => {
  const [expanded, setExpanded] = useState(
    props.startExpanded ||
      props.selectedStatuses.length > 0 ||
      props.selectedAttributes.length > 0
  );

  return (
    <SlidePanelSection
      title={props.title ? props.title : "Filter by questionnaire status"}
      toggleExpand={() => setExpanded(!expanded)}
      expanded={expanded}
    >
      {/* Show all statuses, except inProgress and Published */}
      {Object.values(SurveyStatus)
        .filter(
          (s) => s != SurveyStatus.InProgress && s != SurveyStatus.Published
        )
        .map((s) => (
          <ColorCheckbox
            key={s}
            checked={props.selectedStatuses.includes(s as SurveyStatus)}
            onClick={() => props.toggleStatus(s as SurveyStatus)}
            label={GetStatusFilterDisplayName(s)}
          />
        ))}
      {/* We will include non-status attributes - these are treated as statuses in the Survey lists */}
      {/* For now, we'll exclude Overdue */}
      {Object.values(SurveyAttribute)
        .filter((a) => a != SurveyAttribute.Overdue)
        .map((attribute) => (
          <ColorCheckbox
            key={attribute}
            checked={props.selectedAttributes.includes(
              attribute as SurveyAttribute
            )}
            onClick={() => props.toggleAttribute(attribute as SurveyAttribute)}
            label={GetStatusFilterAttributeDisplayName(attribute)}
          />
        ))}
    </SlidePanelSection>
  );
};

export default SurveyStatusFilter;
