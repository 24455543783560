import React, { useEffect, useState } from "react";
import { Filters, FilterTypes } from "../../vendorrisk/components/filter/types";
import { DefaultRootState } from "react-redux";
import { DefaultThunkDispatchProp } from "../../_common/types/redux";
import { isFilterActive } from "../../vendorrisk/components/filter";
import XTable, {
  IXTableColumnHeader,
  IXTableRow,
  XTableCell,
} from "../../_common/components/core/XTable";
import ColorGrade, {
  ColorGradeSize,
} from "../../vendorrisk/components/executive_summary/ColorGrade";
import { debounce as _debounce } from "lodash";
import SearchBox from "../../_common/components/SearchBox";

import "../style/components/ReportVendorSelector.scss";
import { getFiltersFromState } from "../../vendorrisk/reducers/filters.actions";
import { addDefaultUnknownErrorAlert } from "../../_common/reducers/messageAlerts.actions";
import Score from "../../vendorrisk/components/Score";
import Button from "./core/Button";
import {
  fetchOrgVendors,
  OrgVendorItem,
} from "../../vendorrisk/reducers/orgVendors.apiActions";
import { OrgVendorTypePill } from "../../vendorrisk/components/modals/VendorComparisonSelectModal";
import { IWatchlistPaging } from "../types/vendor";
import EmptyCardWithAction from "./EmptyCardWithAction";
import Search from "../images/search.svg";
import { IVendorWords } from "../constants";
import { appConnect } from "../types/reduxHooks";

const supportedFilters = [
  FilterTypes.VENDOR_ASSESSMENT_STATUS,
  FilterTypes.VENDOR_ASSESSMENT_AUTHOR,
  FilterTypes.VENDOR_REASSESSMENT_DATE,
  FilterTypes.VENDOR_TIER,
  FilterTypes.VENDOR_LABEL,
  FilterTypes.SCORE,
];

const MAX_MULTISELECT_VENDORS = 4;
const DEFAULT_PAGESIZE = 5;

interface IReportComparisonVendorSelectorOwnProps {
  selectedVendorIDs: number[];
  onSelectVendor: (vendorID: number, selected: boolean) => void;
  onClearSelection?: () => void;
  allowMultiSelect?: boolean;
  className?: string;
  setFilterText?: (filterText: string) => void;
  loading?: boolean;
  setFiltersActive?: (active: boolean) => void;
  pageSize?: number;
  defaultFilterText?: string;
  vendorWords: IVendorWords;
  selectedVendorCache: any[];
  setSelectedVendorCache: (vendors: any[]) => void;
}

interface IReportComparisonVendorSelectorConnectedProps {
  filters?: Filters;
}

type IReportComparisonVendorSelectorProps =
  IReportComparisonVendorSelectorOwnProps &
    IReportComparisonVendorSelectorConnectedProps &
    DefaultThunkDispatchProp;

//
// ReportComparisonVendorSelectorTable
// Used by the report configuration modal (launched by the reports library page) to allow the user to select multiple
// vendors for vendor comparison (from watched, subsidiries and reports).
//
// Note: showSelectedOnly mode.
// Currently, this is only supported when 0 < n <= pageSize vendors have been selected.
// This is because the paging implications are tedious, and we really only want < 5 vendors selected anyway.
//
export const ReportComparisonVendorSelectorTable = (
  props: IReportComparisonVendorSelectorProps
) => {
  const [filterText, setFilterText] = useState(
    props.defaultFilterText ? props.defaultFilterText : ""
  );
  const [pageOpts, setPageOpts] = useState<Partial<IWatchlistPaging>>({
    pageNum: 1,
    pageSize: props.pageSize ? props.pageSize : 30,
  });
  const [vendorsLoading, setVendorsLoading] = useState(false);
  const [vendorResult, setVendorResult] = useState([] as OrgVendorItem[]);
  const [showSelectedOnly, setShowSelectedOnly] = useState(false);

  const fetchVendors = async (opts?: {
    namePrefix?: string;
    pageOpts?: Partial<IWatchlistPaging>;
  }) => {
    if (
      opts &&
      opts.namePrefix &&
      opts.namePrefix.length > 0 &&
      opts.namePrefix.length <= 2
    ) {
      return;
    }
    setVendorsLoading(true);
    const pageNum = opts && opts.pageOpts ? opts.pageOpts.pageNum : 1;
    props
      .dispatch(fetchOrgVendors(opts?.namePrefix, pageNum, pageSize))
      .then((vendorResult) => {
        if (vendorResult) {
          const totalPages =
            Math.trunc(vendorResult.total / pageSize) +
            (vendorResult.total % pageSize > 0 ? 1 : 0);
          setPageOpts({
            pageNum: pageNum,
            pageSize: pageSize,
            totalPages: totalPages,
            totalItems: vendorResult.total,
          } as IWatchlistPaging);
          setVendorResult(vendorResult.vendors);
        }
      })
      .catch(() => {
        props.dispatch(
          addDefaultUnknownErrorAlert(
            `error fetching ${props.vendorWords.plural}`
          )
        );
      })
      .finally(() => setVendorsLoading(false));
  };

  const fetchPreselectedVendorDetails = async (vendorIds: number[]) => {
    if (!vendorIds || vendorIds.length == 0) {
      return;
    }
    props
      .dispatch(
        fetchOrgVendors(undefined, undefined, undefined, true, true, vendorIds)
      )
      .then((vendorResult) => {
        const cache = [] as OrgVendorItem[];
        vendorIds.forEach((s) => {
          vendorResult.vendors?.forEach((v) => {
            if (v.datastoreVendorID == s && !cache.includes(v)) {
              cache.push(v);
            }
          });
        });
        props.setSelectedVendorCache(cache);
      })
      .catch(() => {
        props.dispatch(
          addDefaultUnknownErrorAlert(
            `error fetching preselected ${props.vendorWords.plural}`
          )
        );
      });
  };

  // load vendors on both load and filter change
  useEffect(() => {
    fetchPreselectedVendorDetails(props.selectedVendorIDs);
  }, [props.selectedVendorIDs]);

  useEffect(() => {
    fetchVendors({ namePrefix: filterText });
  }, [filterText]);

  const headers: IXTableColumnHeader[] = [
    {
      id: "name",
      text: props.vendorWords.singularTitleCase,
      sortable: false,
    },
    {
      id: "score",
      text: "Score",
      sortable: false,
    },
    {
      id: "type",
      text: "Type",
      sortable: false,
    },
  ];

  const getRows = (vendorWords: IVendorWords) => {
    const rows = [] as IXTableRow[];

    const addRow = (c: OrgVendorItem) => {
      const cells: React.ReactNode[] = [
        <XTableCell key={"name"} className={"vendor-cell"}>
          <div className={"name"}>{c.vendorName}</div>
          <div className={"domain"}>{c.vendorPrimaryHostname}</div>
        </XTableCell>,
        <XTableCell key={"score"} className={"score-cell"}>
          <div className={"score-div"}>
            <ColorGrade score={c.vendorScore} size={ColorGradeSize.Small} />
            <Score colored small score={c.vendorScore} />
          </div>
        </XTableCell>,
        <XTableCell key={"type"} className={"type-cell"}>
          <OrgVendorTypePill {...c} />
        </XTableCell>,
      ];

      const selected = props.selectedVendorIDs.includes(c.datastoreVendorID);

      const selectionDisabled =
        !showSelectedOnly && !selected && props.selectedVendorIDs.length >= 4;

      rows.push({
        id: c.datastoreVendorID,
        onClick: !selectionDisabled
          ? () => props.onSelectVendor(c.datastoreVendorID, !selected)
          : undefined,
        cells: cells,
        selected: selected,
        selectionDisabled: selectionDisabled,
        selectionDisabledHelpText: selectionDisabled
          ? `You've already selected 4 ${vendorWords.plural} for comparison`
          : undefined,
      });
    };

    if (showSelectedOnly) {
      props.selectedVendorIDs.map((v) => {
        for (let i = 0; i < props.selectedVendorCache.length; i++) {
          if (props.selectedVendorCache[i].datastoreVendorID == v) {
            if (
              !filterText ||
              filterText == "" ||
              props.selectedVendorCache[i].vendorName
                .toLowerCase()
                .startsWith(filterText.toLowerCase())
            ) {
              addRow(props.selectedVendorCache[i]);
              break;
            }
          }
        }
      });
    } else {
      vendorResult.map((v) => {
        addRow(v);
      });
    }
    return rows;
  };

  let currentPage = pageOpts.pageNum;
  let totalPages = pageOpts.totalPages;
  const pageSize = pageOpts.pageSize ? pageOpts.pageSize : DEFAULT_PAGESIZE;
  if (showSelectedOnly) {
    totalPages = 1;
    currentPage = 1;
  }

  const onPageChange = (pageNum: number) => {
    setPageOpts((opts) => ({ ...opts, pageNum }));
    const newOpts = { ...pageOpts, pageNum };
    fetchVendors({ pageOpts: newOpts, namePrefix: filterText });
  };

  const _onSearchChange = _debounce((searchString: string) =>
    fetchVendors({ namePrefix: searchString })
  );

  const onSearchChange = (searchString: string) => {
    setFilterText(searchString);
    props.setFilterText && props.setFilterText(searchString);
    _onSearchChange(searchString);
  };

  useEffect(() => {
    props.setFiltersActive &&
      props.filters &&
      props.setFiltersActive(isFilterActive(props.filters, supportedFilters));
  }, [props.filters]);

  const rows = getRows(props.vendorWords);
  const loading = vendorsLoading || props.loading;
  return (
    <div id={"report-vendor-selector"}>
      <div className={"search"}>
        <SearchBox
          onChanged={onSearchChange}
          value={filterText}
          placeholder={`Search for a ${props.vendorWords.singular} by name or URL`}
        />
        {props.allowMultiSelect && (
          <Button
            className={"show-button"}
            onClick={() => setShowSelectedOnly(!showSelectedOnly)}
            disabled={
              loading ||
              (!showSelectedOnly &&
                (props.selectedVendorIDs.length == 0 ||
                  props.selectedVendorIDs.length > pageSize))
            }
          >
            {showSelectedOnly
              ? "Show all"
              : `Show selected ${props.selectedVendorIDs.length}/${MAX_MULTISELECT_VENDORS}`}
          </Button>
        )}
      </div>
      {showSelectedOnly && rows.length === 0 && !loading && (
        <EmptyCardWithAction
          emptyText={
            !filterText
              ? `You have no more ${props.vendorWords.plural} selected`
              : `None of your selected ${props.vendorWords.plural} match your criteria`
          }
          emptySubText={
            !filterText
              ? `Close this 'selected only' view to return to the ${props.vendorWords.singular} selection list`
              : `Change or clear your filter text to see your selected ${props.vendorWords.plural}`
          }
          iconSrc={Search}
          actionButtonRenderOverride={
            <Button
              onClick={() => {
                if (!filterText) {
                  setShowSelectedOnly(!showSelectedOnly);
                } else {
                  onSearchChange("");
                }
              }}
            >
              {!filterText ? "Return to Selection List" : "Clear Filter"}
            </Button>
          }
        />
      )}
      {!showSelectedOnly && rows.length === 0 && !loading && (
        <EmptyCardWithAction
          emptyText={
            !filterText
              ? `You have no ${props.vendorWords.plural} added to your account`
              : `None of your ${props.vendorWords.plural} match your criteria`
          }
          emptySubText={
            !filterText
              ? `Add ${props.vendorWords.plural} to your account to generate a ${props.vendorWords.singular} comparison report.`
              : `Change or clear your filter text to uncover more ${props.vendorWords.plural}.`
          }
          iconSrc={Search}
          actionButtonRenderOverride={
            filterText ? (
              <Button
                onClick={() => {
                  onSearchChange("");
                }}
              >
                {"Clear Filter"}
              </Button>
            ) : undefined
          }
        />
      )}
      {(rows.length > 0 || loading) && (
        <XTable
          rows={rows}
          columnHeaders={headers}
          selectable
          onSelectClick={(id, newSelectedState) => {
            props.onSelectVendor(
              id as number,
              newSelectedState || !props.allowMultiSelect
            );
            if (newSelectedState) {
              const cache = [...props.selectedVendorCache];
              vendorResult.map((v) => {
                if (v.datastoreVendorID == id && !cache.includes(v)) {
                  cache.push(v);
                }
              });
              props.setSelectedVendorCache(cache);
            }
          }}
          radioSelector={!props.allowMultiSelect}
          loading={loading}
          pagination={{
            totalPages: totalPages ?? 0,
            currentPage: currentPage ?? 1,
            onPageChange: onPageChange,
            hidePaginationIfSinglePage: true,
          }}
        />
      )}
    </div>
  );
};

export default appConnect<
  IReportComparisonVendorSelectorConnectedProps,
  never,
  IReportComparisonVendorSelectorOwnProps
>((state: DefaultRootState) => {
  return {
    filters: getFiltersFromState(state),
  };
})(ReportComparisonVendorSelectorTable);
