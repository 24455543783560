import { VendorSummaryRisk } from "../../types/vendorSummary";
import ReportCard from "../ReportCard";
import InfoBanner, {
  BannerType,
} from "../../../vendorrisk/components/InfoBanner";
import XTable, { SortDirection, XTableCell } from "../core/XTable";
import Button from "../core/Button";
import "../../style/components/SurveyDetailsRemediationCard.scss";
import { AdjustedSeverityIcon } from "../SeverityIcon";
import { useState } from "react";
import { usePagination, useSorting } from "../../hooks";
import { severityMap } from "../../helpers";
import { LocationDescriptor } from "history";
import { Link } from "react-router-dom";
import { SeverityAsString } from "../../types/severity";

export interface ISurveyDetailsRemediationCardProps {
  surveyId: number;
  canEditSurvey: boolean;
  risks: VendorSummaryRisk[];
  getSurveyPath: (
    editMode: boolean,
    surveyId?: number,
    questionId?: string
  ) => LocationDescriptor;
}

const SurveyDetailsRemediationCard = (
  props: ISurveyDetailsRemediationCardProps
) => {
  const { surveyId, canEditSurvey, risks } = props;
  const [openRows, setOpenRows] = useState<{
    [rowId: string]: true;
  }>({});

  const toggleRow = (rowId: string | number) =>
    setOpenRows((openRows) => {
      if (openRows[rowId]) {
        const newOpenRows = { ...openRows };
        delete newOpenRows[rowId];
        return newOpenRows;
      }

      return {
        ...openRows,
        [rowId as string]: true,
      };
    });

  const assessmentRisks = risks.reduce((prev: VendorSummaryRisk[], next) => {
    const surveyRisk = next.surveys?.length === 1 ? next.surveys[0] : undefined;
    if (!surveyRisk) {
      // TODO throw error if not single instance?
      return prev;
    }

    if (surveyRisk.inRemediation) {
      prev.push(next);
    }

    return prev;
  }, []);

  const [sortedAssessmentRisks, sortedBy, onSortChange] = useSorting<
    VendorSummaryRisk,
    "severity" | "finding"
  >(assessmentRisks, "severity", SortDirection.DESC, {
    severity: {
      orderFuncs: [
        (r) => severityMap[SeverityAsString(r.severity)].num,
        (r) => r.title,
      ],
      sortDirsDesc: ["desc", "asc"],
      sortDirsAsc: ["asc", "desc"],
    },
    finding: {
      orderFuncs: [(r) => r.title],
      sortDirsDesc: ["desc"],
      sortDirsAsc: ["asc"],
    },
  });

  const [currentPage, currentPageNumber, totalPages, setCurrentPage] =
    usePagination(sortedAssessmentRisks, 5);

  return (
    <ReportCard newStyles className="survey-details-remediation-card">
      <div className="header">Remediation requests</div>
      <div className="info-banner-container">
        <InfoBanner
          type={BannerType.INFO}
          message="The following risks have been flagged as potential security concerns from your questionnaire answers. Please review these risks and update your answers if necessary. These risks contribute to your overall questionnaire score."
        />
      </div>
      <XTable
        columnHeaders={[
          { id: "severity", text: "Severity", sortable: true },
          { id: "finding", text: "Finding", sortable: true },
          { id: "action", text: "", sortable: false },
        ]}
        expandableRows
        onExpandToggle={toggleRow}
        pagination={{
          currentPage: currentPageNumber,
          totalPages,
          onPageChange: setCurrentPage,
          hidePaginationIfSinglePage: true,
        }}
        sortedBy={sortedBy}
        onSortChange={onSortChange}
        rows={currentPage.map((r) => {
          return {
            id: r.id,
            expanded: openRows[r.id],
            expandContent: (
              <div
                className="expanded-risk-details"
                dangerouslySetInnerHTML={{ __html: r.description }}
              />
            ),
            cells: [
              <XTableCell key="sev" className="shrink-cell sev-cell">
                <AdjustedSeverityIcon
                  severity={SeverityAsString(r.severity)}
                  baseSeverity={
                    r.baseSeverity
                      ? SeverityAsString(r.baseSeverity)
                      : undefined
                  }
                  label
                />
              </XTableCell>,
              <XTableCell key="finding" onClick={() => toggleRow(r.id)}>
                {r.title}
              </XTableCell>,
              <XTableCell key="action" className="shrink-cell">
                <Link to={props.getSurveyPath(canEditSurvey, surveyId, r.id)}>
                  <Button tertiary arrow>
                    Review
                  </Button>
                </Link>
              </XTableCell>,
            ],
          };
        })}
      />
    </ReportCard>
  );
};

export default SurveyDetailsRemediationCard;
