import {
  SET_BUCKET_ALIASES,
  SET_ORGANISATION_GROUPS,
  SET_ORGS,
  SET_PARENT_ORGS,
  SET_REGISTERED_DOMAINS,
  SET_REGISTERED_DOMAINS_WORKING,
  SET_SURVEY_TYPES,
  SET_SYSTEM_SURVEYS_SECTIONS,
  SET_VENDOR_ATTRIBUTE_OVERRIDES,
  SET_VENDOR_MOVES,
  SET_VENDOR_ONBOARDING_JOB,
  SET_VENDOR_ONBOARDING_JOBS,
} from "./adminActions";
import { SET_MANAGED_VENDOR_LICENSES } from "./managedVendor.actions";
import { IAdminState } from "../../_common/types/redux";
import { IOrganisation, IOrgFlags } from "../../_common/types/organisations";
import { AttributeOverrides } from "../types/attributeOverrides";
import { ManagedVendorLicense } from "../../_common/types/thirdPartyMangedVendors";
import { surveyType } from "../../_common/types/surveyTypes";
import { SET_SSO_ENTRY, SET_SSO_LIST } from "./sso.actions";
import { SSODetails } from "../../_common/types/sso";

export const defaultState: IAdminState = {
  cyberRiskAuth: {},
  vendorSummaries: {
    loading: true,
    error: null,
    data: null,
  },
  vendorMoves: {
    loading: true,
    error: null,
    data: null,
  },
  vendorAttributeOverrides: {
    loading: true,
  },
  orgs: {
    loading: true,
  },
  orgFlags: {},
  orgManagedVendorLicenses: {},
  parentOrgs: {
    loading: true,
    error: null,
    data: null,
  },
  bucketAliases: [],
  registeredDomains: {
    registeredDomains: [],
    unregisteredDomains: [],
    error: null,
    loading: true,
    working: false,
  },
  systemSurveySections: {
    data: [],
    loading: true,
    error: null,
  },
  vendorOnboarding: {
    jobs: null,
  },
  groupsData: null,
  surveyTypes: [],
  ssoList: {
    loading: true,
    data: [],
  },
};

export type adminReducerAction =
  | {
      type: typeof SET_ORGS;
      orgs: IOrganisation[];
      orgFlags: Record<number, IOrgFlags>;
    }
  | {
      type: typeof SET_PARENT_ORGS;
      orgs: unknown;
      flags: unknown;
    }
  | { type: typeof SET_VENDOR_MOVES; vendorMoves: unknown }
  | {
      type: typeof SET_VENDOR_ATTRIBUTE_OVERRIDES;
      vendorAttributeOverrides: AttributeOverrides;
    }
  | { type: typeof SET_SURVEY_TYPES; types: surveyType[] }
  | { type: typeof SET_BUCKET_ALIASES; aliases: unknown }
  | {
      type: typeof SET_REGISTERED_DOMAINS;
      registeredDomains: unknown;
      unregisteredDomains: unknown;
      loading: boolean;
      error: IError;
    }
  | { type: typeof SET_REGISTERED_DOMAINS_WORKING; working: unknown }
  | {
      type: typeof SET_SYSTEM_SURVEYS_SECTIONS;
      loading: boolean;
      error: IError;
      data: unknown;
    }
  | { type: typeof SET_VENDOR_ONBOARDING_JOBS; jobs: unknown }
  | { type: typeof SET_VENDOR_ONBOARDING_JOB; job: any; jobs: any }
  | { type: typeof SET_ORGANISATION_GROUPS; groupsData: unknown }
  | {
      type: typeof SET_MANAGED_VENDOR_LICENSES;
      orgId: number;
      licenses: ManagedVendorLicense;
    }
  | {
      type: typeof SET_SSO_LIST;
      loading: boolean;
      error?: IError;
      data?: SSODetails[];
    }
  | {
      type: typeof SET_SSO_ENTRY;
      domain: string;
      enabled: boolean;
      supportsLogout: boolean;
    };

function adminReducer(state = defaultState, action: adminReducerAction) {
  switch (action.type) {
    case SET_ORGS:
      return {
        ...state,
        orgs: action.orgs,
        orgFlags: action.orgFlags,
      };
    case SET_PARENT_ORGS:
      return {
        ...state,
        parentOrgs: action.orgs,
        parentOrgFlags: action.flags,
      };
    case SET_VENDOR_MOVES:
      return {
        ...state,
        vendorMoves: action.vendorMoves,
      };
    case SET_VENDOR_ATTRIBUTE_OVERRIDES:
      return {
        ...state,
        vendorAttributeOverrides: {
          ...state.vendorAttributeOverrides,
          ...action.vendorAttributeOverrides,
        },
      };
    case SET_SURVEY_TYPES:
      return {
        ...state,
        surveyTypes: action.types,
      };
    case SET_BUCKET_ALIASES:
      return {
        ...state,
        bucketAliases: action.aliases,
      };
    case SET_REGISTERED_DOMAINS:
      return {
        ...state,
        registeredDomains: {
          registeredDomains: action.registeredDomains,
          unregisteredDomains: action.unregisteredDomains,
          loading: action.loading,
          error: action.error,
        },
      };
    case SET_REGISTERED_DOMAINS_WORKING:
      return {
        ...state,
        registeredDomains: {
          ...state.registeredDomains,
          working: action.working,
        },
      };
    case SET_SYSTEM_SURVEYS_SECTIONS:
      return {
        ...state,
        systemSurveySections: {
          ...state.systemSurveySections,
          loading: action.loading,
          error: action.error,
          data: action.data
            ? action.data
            : state.systemSurveySections
              ? state.systemSurveySections.data
              : null,
        },
      };
    case SET_VENDOR_ONBOARDING_JOBS:
      return {
        ...state,
        vendorOnboarding: {
          ...state.vendorOnboarding,
          jobs: action.jobs,
        },
      };
    case SET_VENDOR_ONBOARDING_JOB:
      const jobs = [...state.vendorOnboarding.jobs];
      let found = false;
      for (let i = 0; i < state.vendorOnboarding.jobs.length; i++) {
        if (state.vendorOnboarding.jobs[i].id === action.job.id) {
          state.vendorOnboarding.jobs[i] = action.job;
          found = true;
          break;
        }
      }
      if (!found) {
        jobs.unshift(action.job);
      }

      return {
        ...state,
        vendorOnboarding: {
          ...state.vendorOnboarding,
          jobs: action.jobs,
        },
      };
    case SET_ORGANISATION_GROUPS:
      return {
        ...state,
        organisationGroups: action.groupsData,
      };

    case SET_MANAGED_VENDOR_LICENSES:
      return {
        ...state,
        orgManagedVendorLicenses: {
          ...state.orgManagedVendorLicenses,
          [action.orgId]: action.licenses,
        },
      };

    case SET_SSO_LIST:
      return {
        ...state,
        ssoList: {
          loading: action.loading,
          error: action.error,
          data: action.data ?? state.ssoList.data,
        },
      };

    case SET_SSO_ENTRY:
      const list = [...state.ssoList.data];
      const idx = list.findIndex((s) => s.domain == action.domain);

      if (idx > -1) {
        list[idx].enabled = action.enabled;
        list[idx].supportsLogout = action.supportsLogout;
      }

      return {
        ...state,
        ssoList: {
          ...state.ssoList,
          data: list,
        },
      };

    default:
      return { ...state };
  }
}

export default adminReducer;
