import { DefaultThunkDispatch } from "../../_common/types/redux";
import { RootState } from "../../_common/types/reduxStore";
import { FetchCyberRiskUrl } from "../../_common/api";
import { addDefaultUnknownErrorAlert } from "../../_common/reducers/messageAlerts.actions";

export const downloadSurveyImportExcelFile = (
  surveyImportUUID: string,
  useNewAnswers: boolean
) => {
  return async (dispatch: DefaultThunkDispatch, getState: () => RootState) => {
    try {
      await FetchCyberRiskUrl(
        "surveyimport/excel/v1",
        { uuid: surveyImportUUID, use_new_answers: useNewAnswers },
        null,
        dispatch,
        getState
      );
    } catch (e) {
      console.error(e);
      dispatch(addDefaultUnknownErrorAlert("Error downloading document"));
    }
  };
};
