import { DefaultRootState } from "react-redux";
import {
  OrgAccessSubsidiaries,
  UserSystemRoleVendorManagementAnalyst,
} from "../permissions";
import { IAuthInfo, ICyberRiskState, IUserData } from "../types/redux";
import {
  IUserOrganisation,
  organisationAccountType,
} from "../types/organisations";
import { RemediationType } from "../types/remediation";
import { IUserTaskData } from "../types/user";
import { RiskAssetType } from "../../vendorrisk/reducers/customerAcceptedRisks.actions";
import { WaiverType } from "../types/vendorRiskWaivers";

export const getCurrentOrgFromUserData = (
  userData?: IUserData
): IUserOrganisation | null =>
  userData?.orgList?.find((o) => o.id === userData.currentOrgID) ?? null;

export const getCustomerOrgNameFromState = (
  authInfo: IAuthInfo,
  userData: IUserData,
  customerData: ICyberRiskState["customerData"]
): string | null => {
  const orgNameFromUserData = getCurrentOrgFromUserData(userData)?.name;
  if (orgNameFromUserData) return orgNameFromUserData;

  if (authInfo.orgName) return authInfo.orgName;

  return customerData?.summary?.result?.business?.display_name ?? null;
};

export const isExpiredTrial = (currentOrg?: IUserOrganisation | null) => {
  if (currentOrg?.accountType === organisationAccountType.trial) {
    // @ts-ignore
    return new Date(currentOrg.trialExpiresOn) - new Date() <= 0;
  }

  return false;
};

export const userOrgHasSubsidiaries = (state: DefaultRootState): boolean =>
  !!(
    state.common.permissions?.org?.[OrgAccessSubsidiaries] &&
    getCurrentOrgFromUserData(state.common.userData)?.hasSubsidiaries
  );

export const userOrgIsFreeOrg = (
  currentOrg?: IUserOrganisation | null
): boolean => {
  return currentOrg?.accountType === organisationAccountType.free;
};

export const userIsVendorManagementAnalyst = (userData: IUserData): boolean => {
  return (
    !!userData.system_roles &&
    userData.system_roles?.includes(UserSystemRoleVendorManagementAnalyst)
  );
};

export const userCurrentOrgIsDistributor = (userData: IUserData): boolean => {
  return (
    userData.currentOrgRoles && userData.currentOrgRoles.includes("Distributor")
  );
};

export const userIsOrgless = (user: IUserData): boolean => {
  return user.currentOrgID == 0;
};

export const userCurrentOrgHasSubsidiaries = (
  currentOrg?: IUserOrganisation
) => {
  return !!currentOrg?.hasSubsidiaries;
};

// userHasRemediationRequests - returns true if remediation requests of any type have been shared with the user
export const userHasRemediationRequests = (
  userTaskData: IUserTaskData
): boolean => {
  const hasCurrentOrgRemediationRequests = Object.values(
    userTaskData?.currentOrgRemediationRequestIDsByTypeMap ?? {}
  ).some((reqIDs) => reqIDs.length > 0);

  const hasOtherOrgRemediationRequests = Object.values(
    userTaskData?.otherOrgRemediationRequestIDsByTypeMap ?? {}
  ).some((reqIDs) => reqIDs.length > 0);

  return hasCurrentOrgRemediationRequests || hasOtherOrgRemediationRequests;
};

// getUserRemediationRequests - returns all remediation requests shared with the user, of the requested types
export const getUserRemediationRequests = (
  userTaskData: IUserTaskData,
  includeCurrentOrgRequests: boolean,
  includeOtherOrgRequests: boolean,
  currentOrgTypes?: RemediationType[],
  otherOrgTypes?: RemediationType[]
): { currentOrgRequestIDs?: number[]; otherOrgRequestIDs?: number[] } => {
  const currentOrgRequestIDs: number[] = [];

  if (
    includeCurrentOrgRequests &&
    currentOrgTypes &&
    userTaskData.currentOrgRemediationRequestIDsByTypeMap
  ) {
    Object.keys(userTaskData.currentOrgRemediationRequestIDsByTypeMap)?.forEach(
      (type) => {
        // Add request for all specified types
        if (currentOrgTypes.includes(type as RemediationType)) {
          userTaskData.currentOrgRemediationRequestIDsByTypeMap?.[
            type as RemediationType
          ]?.forEach((id) => {
            currentOrgRequestIDs.push(id);
          });
        }
      }
    );
  }

  const otherOrgRequestIDs: number[] = [];
  if (
    includeOtherOrgRequests &&
    otherOrgTypes &&
    userTaskData.otherOrgRemediationRequestIDsByTypeMap
  ) {
    Object.keys(userTaskData.otherOrgRemediationRequestIDsByTypeMap)?.forEach(
      (type) => {
        // Add request for all specified types
        if (otherOrgTypes.includes(type as RemediationType)) {
          userTaskData.otherOrgRemediationRequestIDsByTypeMap?.[
            type as RemediationType
          ]?.forEach((id) => {
            otherOrgRequestIDs.push(id);
          });
        }
      }
    );
  }

  return { currentOrgRequestIDs, otherOrgRequestIDs };
};

// getUserRiskAcceptanceTasks - returns all risk acceptance requests shared with the user, of the requested types
export const getUserRiskAcceptanceTasks = (
  userTaskData: IUserTaskData,
  includeCurrentOrgRequests: boolean,
  includeOtherOrgRequests: boolean,
  currentOrgTypes?: RiskAssetType[],
  otherOrgTypes?: RiskAssetType[]
): {
  currentOrgRiskAcceptanceRiskIDs?: number[];
  otherOrgRiskAcceptanceRiskIDs?: number[];
} => {
  const currentOrgRiskAcceptanceRiskIDs: number[] = [];

  if (
    includeCurrentOrgRequests &&
    currentOrgTypes &&
    userTaskData.currentOrgRiskAcceptanceIDsByTypeMap
  ) {
    Object.keys(userTaskData.currentOrgRiskAcceptanceIDsByTypeMap)?.forEach(
      (type) => {
        // Add request for all specified types
        if (currentOrgTypes.includes(type as RiskAssetType)) {
          userTaskData.currentOrgRiskAcceptanceIDsByTypeMap?.[
            type as RiskAssetType
          ]?.forEach((id) => {
            currentOrgRiskAcceptanceRiskIDs.push(id);
          });
        }
      }
    );
  }

  const otherOrgRiskAcceptanceRiskIDs: number[] = [];
  if (
    includeOtherOrgRequests &&
    otherOrgTypes &&
    userTaskData.otherOrgRiskAcceptanceIDsByTypeMap
  ) {
    Object.keys(userTaskData.otherOrgRiskAcceptanceIDsByTypeMap)?.forEach(
      (type) => {
        // Add request for all specified types
        if (otherOrgTypes.includes(type as RiskAssetType)) {
          userTaskData.otherOrgRiskAcceptanceIDsByTypeMap?.[
            type as RiskAssetType
          ]?.forEach((id) => {
            otherOrgRiskAcceptanceRiskIDs.push(id);
          });
        }
      }
    );
  }

  return { currentOrgRiskAcceptanceRiskIDs, otherOrgRiskAcceptanceRiskIDs };
};

export const getUserVendorRiskWaiverTasks = (
  userTaskData: IUserTaskData,
  includeCurrentOrgRequests: boolean,
  includeOtherOrgRequests: boolean,
  currentOrgTypes?: WaiverType[],
  otherOrgTypes?: WaiverType[]
): {
  currentOrgRiskWaiverIDs?: number[];
  otherOrgRiskWaiverIDs?: number[];
} => {
  const currentOrgRiskWaiverIDs: number[] = [];

  if (
    includeCurrentOrgRequests &&
    currentOrgTypes &&
    userTaskData.currentOrgVendorRiskWaiverIDsByTypeMap
  ) {
    Object.keys(userTaskData.currentOrgVendorRiskWaiverIDsByTypeMap)?.forEach(
      (type) => {
        // Add request for all specified types
        if (currentOrgTypes.includes(type as WaiverType)) {
          userTaskData.currentOrgVendorRiskWaiverIDsByTypeMap?.[
            type as WaiverType
          ]?.forEach((id) => {
            currentOrgRiskWaiverIDs.push(id);
          });
        }
      }
    );
  }

  const otherOrgRiskWaiverIDs: number[] = [];
  if (
    includeOtherOrgRequests &&
    otherOrgTypes &&
    userTaskData.otherOrgVendorRiskWaiverIDsByTypeMap
  ) {
    Object.keys(userTaskData.otherOrgVendorRiskWaiverIDsByTypeMap)?.forEach(
      (type) => {
        // Add request for all specified types
        if (otherOrgTypes.includes(type as WaiverType)) {
          userTaskData.otherOrgVendorRiskWaiverIDsByTypeMap?.[
            type as WaiverType
          ]?.forEach((id) => {
            otherOrgRiskWaiverIDs.push(id);
          });
        }
      }
    );
  }

  return { currentOrgRiskWaiverIDs, otherOrgRiskWaiverIDs };
};
