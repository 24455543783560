import { DefaultThunkDispatch } from "../../../_common/types/redux";
import { FC, useState } from "react";
import {
  getSharedAssetsForOrgAndVendor,
  requestSharedAccessForOrgAndVendor,
} from "../../reducers/vendorSharedAssets.actions";
import ModalV2 from "../../../_common/components/ModalV2";
import Button from "../../../_common/components/core/Button";

import "../../style/components/VendorSharedAssetsRequestModal.scss";
import {
  addDefaultSuccessAlert,
  addDefaultUnknownErrorAlert,
} from "../../../_common/reducers/messageAlerts.actions";

export interface IRequestAccessModalProps {
  dispatch: DefaultThunkDispatch;
  active: boolean;
  onClose: () => void;
  vendorId: number;
  vendorName: string;
  orgId: number;
  orgName: string;
}

const RequestAccessModal: FC<IRequestAccessModalProps> = (
  props: IRequestAccessModalProps
) => {
  const [loading, setLoading] = useState(false);

  const requestAccess = () => {
    setLoading(true);
    props
      .dispatch(requestSharedAccessForOrgAndVendor(props.vendorId, props.orgId))
      .then(() =>
        props.dispatch(
          getSharedAssetsForOrgAndVendor(props.vendorId, props.orgId, true)
        )
      )
      .then(() => {
        setLoading(false);
        props.onClose();
        props.dispatch(
          addDefaultSuccessAlert("Successfully requested access", [
            "You will be notified when there is a response to your request",
          ])
        );
      })
      .catch((e) => {
        setLoading(false);
        console.error(e);
        props.dispatch(addDefaultUnknownErrorAlert("Error requesting access"));
      });
  };

  return (
    <ModalV2
      className="vendor-shared-assets-request-modal"
      active={props.active}
      onClose={props.onClose}
      headerContent={`You are requesting access to ${props.orgName}'s assets for ${props.vendorName}`}
      footerContent={
        <div className="btn-group">
          <Button tertiary onClick={props.onClose}>
            Cancel
          </Button>
          <Button loading={loading} onClick={requestAccess}>
            Request access
          </Button>
        </div>
      }
    >
      <div className="desc-section">
        <p>These assets can include:</p>
        <ul>
          <li>Risk Assessments</li>
          <li>Questionnaires</li>
          <li>Additional Evidence</li>
        </ul>
        <p>
          Learn more about{" "}
          <a
            href="https://help.upguard.com/en/articles/5094336-what-are-shared-assets"
            target="_blank"
            rel="nofollow noreferrer"
          >
            sharing vendor assets here
          </a>
          .
        </p>
      </div>
    </ModalV2>
  ) as React.ReactElement;
};

export default RequestAccessModal;
