import BaseAPI from "../../_common/rtkQueryApi";
import { fetchDomainsParams } from "./domains.actions";
import { Domain } from "../../_common/types/domains";
import { hashCode } from "../../_common/helpers";

// Note: This value should be the same as surveydb.maxVendorDomains
export const maxDisplayableDomains = 15000;

export const DomainTagType = "domains" as const;
export const DomainCountTagType = "domainsCount" as const;

export type domainsSortBy = "url" | "score" | "scanned_at";

export interface fetchDomainsV2Params extends fetchDomainsParams {
  search_string: string;
}

interface domainsV2Resp {
  domains: Domain[];
}

const DomainsAPI = BaseAPI.enhanceEndpoints({
  addTagTypes: [DomainTagType, DomainCountTagType],
}).injectEndpoints({
  endpoints: (builder) => ({
    getDomains: builder.query<domainsV2Resp, fetchDomainsV2Params>({
      query: (args) => ({
        url: "vendor/domains/v2/",
        method: "GET",
        params: { ...args },
      }),
      providesTags: (_, __, params) => [
        {
          type: DomainTagType,
          id: getDomainsParamsKey(params),
        },
      ],
    }),

    getDomainsCount: builder.query<
      { active: number; inactive: number },
      {
        vendor_id?: number;
        is_customer?: boolean;
        is_subsidiary?: boolean;
      }
    >({
      query: (args) => ({
        url: "vendor/domains_count/v1/",
        method: "GET",
        params: { ...args },
      }),
      providesTags: (_, __, params) => [
        {
          type: DomainTagType,
          id: `${params.vendor_id}${params.is_customer}${params.is_subsidiary}`,
        },
      ],
    }),
  }),
});

export const getDomainsParamsKey = (inParams: fetchDomainsV2Params) => {
  // sort labels and object keys before stringifying for a more stable hash
  const params = { ...inParams };
  if (params.website_label_ids) {
    params.website_label_ids = [...params.website_label_ids];
    params.website_label_ids.sort();
  } else {
    params.website_label_ids = [];
  }
  if (params.website_portfolio_ids) {
    params.website_portfolio_ids = [...params.website_portfolio_ids];
    params.website_portfolio_ids.sort();
  } else {
    params.website_portfolio_ids = [];
  }

  const j = JSON.stringify(params, Object.keys(params).sort());
  return hashCode(j).toString();
};

export default DomainsAPI;
