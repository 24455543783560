import DropdownButton, {
  DropdownItemContentProps,
} from "../../_common/components/DropdownButton";
import { FC } from "react";
import { App, AppStatus } from "../api/types";
import LoadingIcon from "../../_common/components/core/LoadingIcon";
import "./ManageAppButton.scss";

interface ManageAppButtonProps {
  disable?: boolean;
  dropdownPopup?: React.ReactNode;
  isLoading?: boolean;

  app: App;

  onManageAppAccess?: () => void;
  onDoNotApproveApp?: () => void;
  onMarkAsInReview?: () => void;
}

const ManageAppButton: FC<ManageAppButtonProps> = ({
  disable,
  dropdownPopup,
  isLoading = false,
  app,
  onManageAppAccess,
  onDoNotApproveApp,
  onMarkAsInReview,
}) => {
  if (isLoading) {
    return <LoadingIcon size={22}></LoadingIcon>;
  }

  const items: DropdownItemContentProps[] = [
    {
      headerText:
        app.status === AppStatus.ApprovedAppStatus
          ? "Manage application access"
          : "Approve application",
      description: "Grant access for all or selected teams",
      hide: false,
      onClick: onManageAppAccess,
    },
    {
      headerText: "Mark as ‘in review’",
      description: "Review this application",
      hide: app.status === AppStatus.InReviewAppStatus,
      onClick: onMarkAsInReview,
    },
    {
      headerText: "Do not approve application",
      description: "Deny access for this application",
      hide: app.status === AppStatus.NotApprovedAppStatus,
      onClick: onDoNotApproveApp,
    },
  ];

  return (
    <DropdownButton
      title={"Manage access"}
      dropdownPopup={dropdownPopup}
      disable={disable}
      autoCloseOnMouseLeave={true}
      classname={"manage-app-button"}
      items={items}
    ></DropdownButton>
  );
};

export default ManageAppButton;
