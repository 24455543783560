import { FC } from "react";
import { pluralise } from "../../../_common/helpers";
import { SidePopupV2 } from "../../../_common/components/DismissablePopup";
import { VerifiedVendorPublicScore } from "../../../_common/types/vendor";
import "../../style/components/risk_waivers/PublicRiskWaiverWarningIcon.scss";

interface PublicRiskWaiverWarningIconProps {
  numPendingPublicRiskWaivers?: number;
  verifiedVendorPublicScore?: VerifiedVendorPublicScore;
  adjustedScore?: number;
  watching: boolean;
  vendorName: string;
  onGoToRiskWaivers: () => void;
}

const PublicRiskWaiverWarningIcon: FC<PublicRiskWaiverWarningIconProps> = (
  props
) => {
  if (
    props.numPendingPublicRiskWaivers &&
    props.numPendingPublicRiskWaivers > 0 &&
    props.verifiedVendorPublicScore
  ) {
    return (
      <SidePopupV2
        popupHoverable
        text={<PublicRiskWaiverWarningIconContent {...props} />}
        className={"public-risk-waiver-warning-icon"}
      >
        <i className={"cr-icon-risk"} />
      </SidePopupV2>
    );
  } else {
    return <></>;
  }
};

export const PublicRiskWaiverWarningIconContent: FC<
  PublicRiskWaiverWarningIconProps
> = ({
  numPendingPublicRiskWaivers,
  verifiedVendorPublicScore,
  watching,
  vendorName,
  onGoToRiskWaivers,
  adjustedScore,
}) => {
  // Use the highest of the two scores as the 'minimum' that a public waiver can take the score up to
  // This is due to the public score not taking into account private waivers, questionnaires score merge etc.
  const scoreToUse = Math.max(
    verifiedVendorPublicScore?.publicScore ?? 0,
    adjustedScore ?? 0
  );

  return (
    <div>
      {numPendingPublicRiskWaivers} shared{" "}
      {pluralise(numPendingPublicRiskWaivers, "waiver is", "waivers are")}{" "}
      available for review, potentially increasing the security rating to at
      least {scoreToUse}.
      {!watching && (
        <>
          <br />
          <br />
          Monitor {vendorName} to review risk waivers.
        </>
      )}
      {watching && (
        <>
          <br />
          <a onClick={onGoToRiskWaivers}>
            Review risk waivers
            <i className={"cr-icon-arrow-right"} />
          </a>
        </>
      )}
    </div>
  );
};

export default PublicRiskWaiverWarningIcon;
