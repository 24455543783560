import { FetchCyberRiskUrl, ResponseError } from "./api";
import { BaseQueryFn, createApi } from "@reduxjs/toolkit/query/react";

export interface QueryArgs {
  url: string;
  method: string;
  body?: unknown;
  file?: File;
  params?: Record<
    string,
    string | boolean | number | string[] | number[] | undefined
  >;
  headers?: Record<string, string>;
}

export interface QueryError {
  message: string;
  status?: number;
  body?: string;
}

// fetchCyberRiskBaseQuery provides our BaseAPI with a function to transform QueryArgs
// into an authenticated request against the CR API.
const fetchCyberRiskBaseQuery: BaseQueryFn<
  QueryArgs,
  unknown,
  QueryError
> = async (args, api, _extraOptions) => {
  const url = args.url.startsWith("/") ? args.url.slice(1) : args.url;

  let body;
  if (args.body) {
    body = args.body;
  } else if (args.file) {
    body = new FormData();
    body.append("file", args.file);
  }

  try {
    const result = await FetchCyberRiskUrl(
      url,
      args.params,
      {
        method: args.method,
        headers: args.headers,
        body,
      },
      api.dispatch,
      api.getState
    );
    return {
      data: result,
    };
  } catch (e) {
    if (e instanceof ResponseError) {
      return {
        error: {
          message: e.message,
          status: e.response.status,
          body: e.json,
        },
      };
    }

    return {
      error: {
        message: (e as any).message,
      },
    };
  }
};

// vendorDataTag should be used as tag whenever cached data will be specific to a vendor
// For the ID use the datastoreVendorID (ie the one that would appear in the URL)
// all caches that use this tag are cleared automatically when switching in/out of managed vendor analyst mode
// or changing the customer that you are manging vendors for
export const vendorDataTag = "VendorData" as const;

// BaseAPI is our root API slice. Endpoints are added to this API in separate files by using enhanceEndpoints and
// injectEndpoints. This allows us to logically separate API definitions (such as by API router in the backend),
// whilst having all cache managed by this single API slice.
const BaseAPI = createApi({
  reducerPath: "baseapi",
  baseQuery: fetchCyberRiskBaseQuery,
  keepUnusedDataFor: 600, // Default to keeping cached data for 10 mins after all subscriptions removed
  endpoints: () => ({}),
  tagTypes: [vendorDataTag],
});

// clearAllVendorData helper function to clear all vendor data from the cache
export const clearAllVendorData = () =>
  BaseAPI.util.invalidateTags([vendorDataTag]);

// clearDataForVendor clear all data for a specific vendor from the cache
export const clearDataForVendor = (vendorID: number) =>
  BaseAPI.util.invalidateTags([{ type: vendorDataTag, id: vendorID }]);

export default BaseAPI;
