import {
  VendorAssessmentStatus,
  VendorAssessmentStatusPill,
} from "../../types/vendorAssessments";
import { FC, memo } from "react";
import PillLabel from "../PillLabel";
import { UserAvatarAndName } from "../../../_common/components/UserAvatar";
import { formatDateAsLocal } from "../../../_common/helpers";
import IconButton, {
  HoverLocation,
} from "../../../_common/components/IconButton";
import "../../style/components/vendor_assessment/VendorAssessmentV3SummaryBar.scss";
import classNames from "classnames";
import classnames from "classnames";
import Button from "../../../_common/components/core/Button";
import { isReassessmentOverdue } from "../vendor_summary/RiskManagementTile";
import { LabelColor } from "../../../_common/types/label";
import InfoBanner, { BannerType } from "../InfoBanner";
import CompanyLogo from "../../../_common/components/CompanyLogo";
import { SharedVendorAssessmentListItem } from "../../reducers/vendorAssessmentAPI";
import { ManagedVendorAssessmentPill } from "../ManagedVendorAssessmentPill";

interface VendorAssessmentV3SummaryBarProps {
  status: VendorAssessmentStatus;
  archived: boolean;
  authorName: string;
  authorEmail: string;
  authorAvatar: string;
  assessmentDate?: string;
  publishedDate?: string;
  onEditAssessmentDate: () => void;
  onDelete: () => void;
  onOpenVersionHistory?: () => void;
  isOldVersion?: boolean;
  vendorName: string;
  sharedByOrg?: SharedVendorAssessmentListItem;
  isEditable?: boolean;
  isManaged?: boolean;
}

const VendorAssessmentV3SummaryBar: FC<VendorAssessmentV3SummaryBarProps> = (
  props
) => {
  const { impendingReassessment, overdueAssessment } = isReassessmentOverdue(
    props.assessmentDate ?? ""
  );

  return (
    <div
      className={classNames("vendor-assessment-v3-summary", {
        draft: props.status == VendorAssessmentStatus.Draft,
      })}
    >
      <div className={"top-section"}>
        <div className={"summary-section"}>
          <div className={"title"}>status</div>
          <div className={"content"}>
            <PillLabel color={VendorAssessmentStatusPill[props.status].color}>
              {VendorAssessmentStatusPill[props.status].text}
            </PillLabel>
            {props.archived && (
              <PillLabel color={LabelColor.Red}>Archived</PillLabel>
            )}
            {props.isManaged && ManagedVendorAssessmentPill()}
          </div>
        </div>
        <div className={"summary-section"}>
          <div className={"title"}>author</div>
          <div className={"content"}>
            <UserAvatarAndName
              avatar={props.authorAvatar}
              name={props.authorName}
              email={props.authorEmail}
            />
          </div>
        </div>
        {!!props.sharedByOrg && (
          <div className={"summary-section"}>
            <div className={"title"}>organization</div>
            <div className={"content"}>
              <CompanyLogo
                name={props.sharedByOrg.sharedOrgName}
                domain={props.sharedByOrg.sharedOrgHostname}
              />
            </div>
          </div>
        )}

        {props.publishedDate && (
          <div className={"summary-section"}>
            <div className={"title"}>date published</div>
            <div className={"content date"}>
              <>
                <div className={classnames("reassessment-date-inner")}>
                  {formatDateAsLocal(props.publishedDate)}{" "}
                </div>
              </>
            </div>
          </div>
        )}
        {!props.sharedByOrg && (!!props.assessmentDate || props.isEditable) && (
          <div className={"summary-section"}>
            <div className={"title"}>reassessment date</div>
            <div className={"content date"}>
              {!!props.assessmentDate ? (
                <>
                  <div
                    className={classnames("reassessment-date-inner", {
                      red: overdueAssessment || impendingReassessment,
                      overdue: overdueAssessment,
                    })}
                  >
                    {formatDateAsLocal(props.assessmentDate)}{" "}
                    {overdueAssessment && (
                      <PillLabel color={LabelColor.Red}>Overdue</PillLabel>
                    )}
                  </div>
                  {props.isEditable && !props.isOldVersion && (
                    <IconButton
                      icon={<i className="cr-icon-pencil" />}
                      onClick={props.onEditAssessmentDate}
                    />
                  )}
                </>
              ) : (
                props.isEditable &&
                !props.isOldVersion && (
                  <Button tertiary onClick={props.onEditAssessmentDate}>
                    <i className={"cr-icon-plus"} />
                    Add date
                  </Button>
                )
              )}
            </div>
          </div>
        )}
        <div
          className={classnames("right-section", {
            empty:
              props.status !== VendorAssessmentStatus.Published &&
              !props.isEditable,
          })}
        >
          {props.status == VendorAssessmentStatus.Published ? (
            <>
              {props.onOpenVersionHistory && (
                <Button onClick={props.onOpenVersionHistory}>
                  <i className={"cr-icon-timer"} />
                  {props.sharedByOrg ? "My versions" : "Version history"}
                </Button>
              )}
              {!props.sharedByOrg && props.isEditable && !props.isManaged && (
                <Button onClick={props.onDelete} tertiary>
                  <i className={"cr-icon-trash"} />
                  Delete version
                </Button>
              )}
            </>
          ) : (
            props.isEditable &&
            !props.isManaged && (
              <IconButton
                hoverText={"Delete version"}
                hoverLocation={HoverLocation.Top}
                hoverMicro
                icon={<i className={"cr-icon-trash"} />}
                onClick={props.onDelete}
              />
            )
          )}
        </div>
      </div>
      {props.isOldVersion && (
        <InfoBanner
          message={"You are viewing an old version"}
          type={BannerType.WARNING}
          overrideIcon={BannerType.INFO}
          centered
          className={"summary-info-bar"}
        />
      )}
      {props.sharedByOrg && (
        <InfoBanner
          message={`You are viewing ${props.sharedByOrg.sharedOrgName}'s risk assessment of ${props.vendorName}`}
          type={BannerType.WARNING}
          overrideIcon={BannerType.INFO}
          centered
          className={"summary-info-bar"}
        />
      )}
    </div>
  );
};

export default memo(VendorAssessmentV3SummaryBar);
