import React, { FC, useEffect, useRef, useState } from "react";
import { OptionType, SelectV2Multi } from "../../_common/components/SelectV2";
import {
  OptionProps,
  OptionsType,
  ValueType,
  components,
  MenuListComponentProps,
} from "react-select";
import "./CheckboxMultiSelect_UB.scss";
import classnames from "classnames";
import ColorCheckbox from "../../vendorrisk/components/ColorCheckbox";
import { MultiValueGenericProps } from "react-select/src/components/MultiValue";
import PillLabel from "../../vendorrisk/components/PillLabel";
import { LabelColor } from "../../_common/types/label";

export const clearValueOption = "Clear selection";

const Option = (props: OptionProps<OptionType, true>) => {
  if (props.data.value === clearValueOption) {
    return (
      <div
        className={classnames(
          props.className,
          "checkbox-option",
          "no-value-option"
        )}
        key={props.label}
        onClick={(e) => props.innerProps.onClick(e)}
      >
        {clearValueOption}
      </div>
    );
  }

  return (
    <ColorCheckbox
      className={classnames(props.className, "checkbox-option")}
      key={props.label}
      checked={props.isSelected}
      label={props.label}
      onClick={(e) => props.innerProps.onClick(e)}
    />
  );
};

const MultiValueContainer = (props: MultiValueGenericProps<OptionType>) => {
  const maxToShow = 3;
  const valueIdx = props.selectProps.value.findIndex(
    (v: OptionType) => v.value === props.data.value
  );
  const value = props.data.label;

  const pillColorFunc =
    props.selectProps.pillColorFunction ??
    (() => {
      // If there's no color function, always bet on blue
      return LabelColor.Blue;
    });

  if (valueIdx < maxToShow) {
    return (
      <PillLabel
        key={value}
        color={pillColorFunc(props.data.label)}
        capitalized={true}
      >
        {props.data.label}
      </PillLabel>
    );
  } else if (valueIdx === maxToShow) {
    const numOverflow = props.selectProps.value.length - maxToShow;
    return (
      <PillLabel key={"overflow"} color={LabelColor.Grey} capitalized={false}>
        + {numOverflow} more
      </PillLabel>
    );
  } else {
    return null;
  }
};

const MenuList = (props: MenuListComponentProps<OptionType, true>) => {
  const { onInputChange, inputValue, onMenuInputFocus, searchPlaceholder } =
    props.selectProps;

  return (
    <div>
      <input
        style={{
          width: "96%",
          boxSizing: "border-box",
          padding: 10,
          margin: "8px",
          border: "1px solid #cccfe0",
        }}
        autoCorrect="off"
        autoComplete="off"
        spellCheck="false"
        type="text"
        value={inputValue}
        onChange={(e) => {
          if (onInputChange) {
            onInputChange(e.currentTarget.value, {
              action: "input-change",
            });
          }
        }}
        // we type the event as React.MouseEvent to avoid conflict/import
        // with the DOM MouseEvent which is used below
        onMouseDown={(e: React.MouseEvent<HTMLInputElement>) => {
          e.stopPropagation();
          (e.target as HTMLInputElement).focus();
        }}
        onFocus={onMenuInputFocus}
        placeholder={searchPlaceholder ?? "Search..."}
      />
      <components.MenuList {...props} />
    </div>
  );
};

export interface ICheckboxOption {
  label: string;
  value: string;
  selected: boolean;
}

interface ICheckboxMultiSelectProps {
  // options to display in the select
  options: ICheckboxOption[];

  // callback for user selections
  onSelectionChanged: (selectedValues: string[]) => void;

  // optional class name for the component
  className?: string;

  // Placeholder text for the search box
  searchPlaceholder?: string;

  // Placeholder text for the select box
  selectPlaceholder?: string;

  // When creating pills, use this function to determine the color of the pill. Defaults to blue.
  pillColorFunction?: (s: string) => LabelColor;
}

// CheckboxMultiSelect is a dropdown of many options, with the ability to select multiple options.
//
// It's currently sitting here in the userbase directory until it's more stable and any issues have
// been ironed out before rolling it into the _common/components directory. It's also sufficiently
// different enough from the other vendorrisk CheckboxMultiSelect component that it needed its own
// component. This can be considered tech debt to be paid down... one day.
const CheckboxMultiSelect: FC<ICheckboxMultiSelectProps> = (props) => {
  const containerRef = useRef<HTMLDivElement>(null!);
  const [isFocused, setIsFocused] = useState(false);
  const [inputValue, setInputValue] = useState("");

  const onDomClick = (e: MouseEvent) => {
    if (containerRef.current === null) return;
    const menu = containerRef.current.querySelector(".select__menu");

    if (
      !containerRef.current.contains(e.target as Node) ||
      !menu ||
      !menu.contains(e.target as Node)
    ) {
      setIsFocused(false);
      setInputValue("");
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", onDomClick);

    return () => {
      document.removeEventListener("mousedown", onDomClick);
    };
  }, []);

  // we want to sort the selected values in alphabetical order so that the comma separated list is more readable
  const selectedValues = props.options
    .filter((o) => o.selected)
    .map((o) => ({ label: o.label, value: o.value }));

  selectedValues.sort((v1, v2) => {
    if (v1.label < v2.label) return -1;
    if (v1.label > v2.label) return 1;
    return 0;
  });

  const options: OptionsType<OptionType> = [
    { label: clearValueOption, value: clearValueOption },
    ...props.options.sort((o1, o2) => o1.label.localeCompare(o2.label)),
  ];

  const onChange = (values: ValueType<OptionType, true>) => {
    if (values?.some((v) => v.value === clearValueOption)) {
      props.onSelectionChanged([]);
      return;
    }

    const newSelectedValues = (values ?? [])
      .map(({ value }) => value as string)
      .sort();

    props.onSelectionChanged(newSelectedValues);
    setInputValue(inputValue);
  };

  return (
    <div ref={containerRef}>
      <SelectV2Multi
        className={classnames("checkbox-multi-select-ub", props.className)}
        isMulti
        components={{
          Option,
          MultiValueContainer,
          MenuList,
        }}
        options={options}
        onChange={onChange}
        value={selectedValues}
        placeholder={props.selectPlaceholder}
        closeMenuOnSelect={false}
        isClearable={false}
        isSearchable={false}
        hideSelectedOptions={false}
        inputValue={inputValue}
        onInputChange={(val) => setInputValue(val)}
        onMenuInputFocus={() => {
          setIsFocused(true);

          // This shouldn't be necessary, but when we don't have this, inputValue keeps getting
          // cleared when we re-click on the search box.
          setInputValue(inputValue);
        }}
        onFocus={() => setIsFocused(true)}
        captureMenuScroll={false}
        {...{
          menuIsOpen: isFocused || undefined,
          isFocused: isFocused || undefined,
          searchPlaceholder: props.searchPlaceholder,
          pillColorFunction: props.pillColorFunction,
        }}
      />
    </div>
  );
};

export default CheckboxMultiSelect;
