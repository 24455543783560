import {
  Comparator,
  Expression,
  Operator,
  SingleAnswerComparison,
} from "../types/conditionalLogic";

// Splits a vsaq condition string into tokens for easier parsing.
const splitVsaqConditionalIntoTokens = (cond: string): string[] => {
  let currentToken = "";
  const toks = [];

  for (let i = 0; i < cond.length; i++) {
    const char = cond[i];

    switch (char) {
      case "(":
      case ")":
        // Parentheses should be their own token
        if (currentToken) {
          toks.push(currentToken);
        }

        toks.push(char);
        currentToken = "";
        break;

      case " ":
        // End the current token
        if (currentToken) {
          toks.push(currentToken);
        }
        currentToken = "";
        break;

      default:
        currentToken += char;
    }
  }

  if (currentToken) {
    toks.push(currentToken);
  }

  return toks;
};

export const convertVsaqConditional = (
  answerIDsToNodeIds: { [answerId: string]: string },
  cond: string
): Expression[] => {
  const toks = splitVsaqConditionalIntoTokens(cond);
  const stack = new exprStack();

  let invertNextParenthetical = false;

  for (let i = 0; i < toks.length; i++) {
    let tok = toks[i];

    switch (tok) {
      case "(":
        // Start a new level of parentheticals
        stack.push([]);

        if (stack.itemsInStack.length > 2) {
          console.error("got a condition with more than 2 levels of nesting");
        }

        break;

      case ")":
        // End the current level of parentheticals.
        // We need to pop the last parenthetical we were editing in the stack,
        // and append it to the previous item.
        const lastParenthetical = stack.pop();
        if (!lastParenthetical) {
          console.error("could not pop off stack");
          break;
        }

        invertNextParenthetical = false;

        stack.appendToCurrent(lastParenthetical);

        break;

      case "!":
        // An exclamation mark can appear on its own when outside parentheses, eg !(a || b).
        // NOTs are not supported in the UI.
        // So we deal with this by negating the logic inside the parentheses (De Morgan's law).
        // !(a || b) becomes (!a && !b)
        // !(a && b) becomes (!a || !b)
        invertNextParenthetical = true;

        break;

      case "&&":
        if (invertNextParenthetical) {
          stack.appendToCurrent(Operator.Or);
        } else {
          stack.appendToCurrent(Operator.And);
        }
        break;

      case "||":
        if (invertNextParenthetical) {
          stack.appendToCurrent(Operator.And);
        } else {
          stack.appendToCurrent(Operator.Or);
        }
        break;

      default:
        // Check if there is a "not"
        let isNot = false;
        if (tok.startsWith("!")) {
          isNot = true;
          tok = tok.slice(1);
        }

        if (invertNextParenthetical) {
          isNot = !isNot;
        }

        // Check if this is looking at visibility
        let visibilityOp = false;
        if (tok.startsWith("^")) {
          visibilityOp = true;
          tok = tok.slice(1);
        }

        let nodeId = "";
        let answerId = "";
        let comparator = Comparator.Equals;
        if (visibilityOp) {
          nodeId = tok;

          if (isNot) {
            comparator = Comparator.NotVisible;
          } else {
            comparator = Comparator.Visible;
          }
        } else {
          answerId = tok;
          nodeId = answerIDsToNodeIds[tok] || "";
          if (!nodeId) {
            console.error(
              `answer ID ${tok} has no corresponding nodeId (cond: ${cond})`
            );
          }

          if (isNot) {
            comparator = Comparator.NotEquals;
          } else {
            comparator = Comparator.Equals;
          }
        }

        stack.appendToCurrent({
          nodeId,
          comparator,
          answerId,
        });
        break;
    }
  }

  const exprs = stack.pop() || [];

  // Make sure everything in the top level is wrapped in arrays to support adding to parentheticals
  for (let i = 0; i < exprs.length; i++) {
    if (typeof exprs[i] === "object" && !Array.isArray(exprs[i])) {
      exprs[i] = [exprs[i]];
    }
  }

  return exprs;
};

// Simple stack for managing expressions in the convertVsaqConditional function
class exprStack {
  itemsInStack: Expression[][] = [[]];

  appendToCurrent(expr: Expression) {
    this.itemsInStack[this.itemsInStack.length - 1].push(expr);
  }

  push(exprs: Expression[]) {
    this.itemsInStack.push(exprs);
  }

  pop(): Expression[] | undefined {
    return this.itemsInStack.pop();
  }
}

export const convertConditionalToVsaqCond = (exprs: Expression[]): string => {
  let cond = "";

  for (let i = 0; i < exprs.length; i++) {
    const expr = exprs[i];
    if (i > 0) {
      cond += " ";
    }

    if (Array.isArray(expr)) {
      if (expr.length === 1) {
        cond += convertConditionalToVsaqCond(expr as Expression[]);
      } else {
        // Nested condition should be surrounded in parens
        cond += "(" + convertConditionalToVsaqCond(expr as Expression[]) + ")";
      }
    } else if (typeof expr === "object") {
      const singleAnswerComp = expr as SingleAnswerComparison;
      const isNot =
        singleAnswerComp.comparator === Comparator.NotEquals ||
        singleAnswerComp.comparator === Comparator.NotVisible;

      if (isNot) {
        cond += "!";
      }

      if (
        singleAnswerComp.comparator === Comparator.Visible ||
        singleAnswerComp.comparator === Comparator.NotVisible
      ) {
        // For visibility checks, we need to add the ^ character and the NODE ID.
        cond += "^" + singleAnswerComp.nodeId;
      } else {
        // For equality checks, we just need to add the ANSWER ID.
        cond += singleAnswerComp.answerId;
      }
    } else {
      // We're looking at an operator.
      const op = expr as Operator;
      cond += op;
    }
  }

  return cond;
};
