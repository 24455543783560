import { Component } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import Button from "../../../_common/components/core/Button";

import { closeModal } from "../../../_common/reducers/commonActions";

import { editOrgUsers } from "../../reducers/cyberRiskActions";
import ColorCheckbox from "../ColorCheckbox";
import { roleText } from "./NewUserModal";

import "../../style/components/NewUserModal.scss";
import { fetchUserData } from "../../../_common/reducers/commonActions";
import {
  addDefaultSuccessAlert,
  addSimpleErrorAlert,
} from "../../../_common/reducers/messageAlerts.actions";

export const EditUserModalName = "EditUserModalName";

export default class EditUserModal extends Component {
  static propTypes = {
    modalData: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.roles = { User: true };
    const { roles = ["Admin"] } = props.modalData.user;
    const highlighted = {};
    roles.forEach((r) => {
      highlighted[r] = true;
    });
    this.state = {
      loading: false,
      highlighted: highlighted,
    };
  }

  onRoleChange = (id) =>
    this.setState((state) => {
      const thisHighlighted = state.highlighted[id];
      const newHighlighted = {
        [id]: !thisHighlighted,
      };

      // if we are setting read-only, then we dont want the User role (and conversely)
      if (id === "ReadOnly") {
        newHighlighted.User = thisHighlighted;
      }

      if (id === "Admin" && !thisHighlighted) {
        // Turn on the Risk Assessor and Questionnaire Creator roles by default if Admin is selected
        newHighlighted.VendorAssessor = true;
        newHighlighted.QuestionnaireCreator = true;
      }

      return {
        highlighted: {
          ...state.highlighted,
          ...newHighlighted,
        },
      };
    });

  onSubmit = (evt) => {
    evt.preventDefault();
    const roles = this.state.highlighted;
    let finalRoles = [];
    if (roles["ReadOnly"]) {
      finalRoles = ["ReadOnly"];
    } else {
      Object.keys(roles).forEach(function (id) {
        if (roles[id]) {
          finalRoles.push(id);
        }
      });
    }
    this.setState({ loading: true });
    this.props
      .dispatch(editOrgUsers([this.props.modalData.user.id], finalRoles, []))
      .then(() => {
        if (this.props.modalData.isCurrentUser) {
          // Refresh the user's permissions
          return this.props.dispatch(fetchUserData());
        }
      })
      .then(() => {
        this.props.dispatch(closeModal());
        this.props.dispatch(addDefaultSuccessAlert("User updated"));
      })
      .catch((err) => {
        this.props.dispatch(addSimpleErrorAlert("err.message"));
      })
      .then(() => {
        this.setState({ loading: false });
      });
  };

  render() {
    const {
      orgHasBreachSightEnabled,
      orgHasVendorRiskEnabled,
      orgHasVendorAssessmentsEnabled,
      orgHasThirdPartyVendorManagementEnabled,
      orgHasQuestionnaireBuilderEnabled,
    } = this.props.modalData;

    const possibleRoles = [
      {
        id: "ReadOnly",
        displayName: "Read-Only",
        desc: "Access the system in read-only mode.",
        disabled: this.props.modalData.isCurrentUser,
        checked: this.state.highlighted["ReadOnly"],
        gapbelow: false,
      },
      {
        id: "Admin",
        displayName: "Account Admin",
        desc: "Manage users.",
        disabled:
          this.state.highlighted["ReadOnly"] ||
          this.props.modalData.isCurrentUser,
        checked:
          this.state.highlighted["Admin"] &&
          !this.state.highlighted["ReadOnly"],
        gapbelow: false,
      },
      {
        id: "BreachSight",
        displayName: "Data Leaks",
        desc: "View and manage data leaks.",
        hidden: !orgHasBreachSightEnabled,
        disabled: this.state.highlighted["ReadOnly"],
        checked:
          this.state.highlighted["BreachSight"] &&
          !this.state.highlighted["ReadOnly"],
        gapbelow: false,
      },
      {
        id: "QuestionnaireCreator",
        displayName: "Questionnaire Editor",
        desc: "Create and edit custom questionnaires.",
        hidden: !orgHasVendorRiskEnabled || !orgHasQuestionnaireBuilderEnabled,
        disabled: this.state.highlighted["ReadOnly"],
        checked:
          this.state.highlighted["QuestionnaireCreator"] &&
          !this.state.highlighted["ReadOnly"],
        gapbelow: false,
      },
      {
        id: "VendorAssessor",
        displayName: "Risk Assessor",
        desc: "View and manage risk assessments.",
        hidden: !orgHasVendorAssessmentsEnabled,
        disabled: this.state.highlighted["ReadOnly"],
        checked:
          this.state.highlighted["VendorAssessor"] &&
          !this.state.highlighted["ReadOnly"],
        gapbelow: true,
      },
      {
        id: "VendorsUnderManagementAdmin",
        displayName: "Vendor Management Admin",
        desc: "View and manage third party vendor management.",
        hidden: !orgHasThirdPartyVendorManagementEnabled,
        disabled: this.state.highlighted["ReadOnly"],
        checked:
          this.state.highlighted["VendorsUnderManagementAdmin"] &&
          !this.state.highlighted["ReadOnly"],
        gapbelow: false,
      },
    ];

    return (
      <div className="modal-content edit-user-modal">
        <h2>Update User</h2>
        {roleText()}
        <form onSubmit={this.onSubmit}>
          <label htmlFor="roles">Additional permissions</label>
          {possibleRoles.map((r) => {
            if (r.hidden) {
              return null;
            }
            return (
              <div
                key={r.id}
                className={classnames("roleOption", { gapBelow: r.gapbelow })}
              >
                <ColorCheckbox
                  label={r.displayName}
                  onClick={() => {
                    this.onRoleChange(r.id);
                  }}
                  checked={r.checked}
                  disabled={r.disabled}
                />
                <span
                  className={classnames("roleDesc", { disabled: r.disabled })}
                >
                  {r.desc}
                </span>
              </div>
            );
          })}
          <Button
            type="submit"
            primary
            loading={this.state.loading}
            disabled={this.state.loading}
          >
            Save
          </Button>
        </form>
      </div>
    );
  }
}
