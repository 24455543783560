import { Component } from "react";
import PropTypes from "prop-types";

import { XTableCell } from "../../../_common/components/core/XTable";
import XTable from "../../../_common/components/core/XTable";
import Button from "../../../_common/components/core/Button";
import LoadingIcon from "../../../_common/components/core/LoadingIcon";
import {
  addNewLabel,
  renameLabel,
  deleteLabel,
  countVendorsForLabel,
  fetchAvailableLabels,
  refreshFilteredCustomerData,
  countWebsitesForLabel,
} from "../../reducers/cyberRiskActions";

import "../../style/components/ManageLabelsModal.scss";
import { LabelClassifications } from "../../../_common/constants";
import {
  addDefaultUnknownErrorAlert,
  addSimpleErrorAlert,
} from "../../../_common/reducers/messageAlerts.actions";

const LABEL_NAME_MAXLENGTH = 100;
const MAX_LABEL_DISPLAY_LENGTH = 80;

export const ManageLabelsModalName = "ManageLabelsModalName";

/**
 * <Circle />
 * Simple circle component that can display with an outline, background color, and optional icon.
 */
const Circle = ({ bg, className, onClick }) => (
  <div className={`circle ${bg} ${className}`} onClick={onClick} />
);

Circle.propTypes = {
  bg: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
};

Circle.defaultProps = {
  bg: "",
  className: "",
  onClick: null,
};

/**
 * @deprecated Use ManageLabelSet
 */

class ManageLabelsModal extends Component {
  static propTypes = {
    modalData: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
  };

  static onCloseFunc(dispatch) {
    dispatch(refreshFilteredCustomerData());
  }

  state = {
    loading: false,
    userLabelList: [],
    renamingLabel: false,
    renamingLabelID: 0,
    deletingLabelID: 0,
    addingLabel: false,
    addingLabelForWebsite: false,
    addLoading: false,
    rowLoading: false,
    editingLabelText: "",
    deleteCounting: false,
    deleteCount: 0,
  };

  onClickAddLabel = (isWebsiteLabel) => {
    this.cancelCurrentOperation();
    this.setState({
      addingLabel: true,
      addingLabelForWebsite: isWebsiteLabel,
      editingLabelText: "",
    });
  };

  onClickDeleteLabel = (label) => {
    this.cancelCurrentOperation();
    this.setState({
      deletingLabelID: label.id,
      deleteCounting: true,
    });
    this.getCountOfExistingItemsForLabel(label);
  };

  onClickRenameLabel = (label) => {
    this.cancelCurrentOperation();
    this.setState({
      renamingLabel: true,
      editingLabelText: label.name,
      renamingLabelID: label.id,
    });
  };

  cancelCurrentOperation = () => {
    this.setState({
      renamingLabel: false,
      addingLabel: false,
      editingLabelText: "",
      renamingLabelID: 0,
      deletingLabelID: 0,
      rowLoading: false,
      addLoading: false,
    });
  };

  setEditingText = () => {
    const newText = this.textEdit.value;
    this.setState({ editingLabelText: newText });
  };

  handleKeyDown = (e) => {
    const ENTER_KEY = 13;
    const ESC_KEY = 27;
    if (e.keyCode === ENTER_KEY) {
      if (this.state.addingLabel) {
        this.commitSaveNewLabel();
      } else if (this.state.renamingLabel) {
        this.commitRenameLabel();
      }
    } else if (e.keyCode === ESC_KEY) {
      this.cancelCurrentOperation();
      e.stopPropagation();
    }
  };

  onDoubleClick = (label) => {
    if (
      !this.state.addLoading &&
      !this.state.rowLoading &&
      !this.state.deleteCounting
    ) {
      this.onClickRenameLabel(label);
    }
  };

  validateEnteredLabelName = () => {
    const newLabelName = this.state.editingLabelText.trim();
    let error = "";
    if (newLabelName.length < 3) {
      error = "Label names must be at least 3 characters long";
    } else if (newLabelName.length > LABEL_NAME_MAXLENGTH) {
      error = `Label names must be at most ${LABEL_NAME_MAXLENGTH} characters long`;
    }
    if (error !== "") {
      this.props.dispatch(addSimpleErrorAlert(error));
      return false;
    }
    return true;
  };

  commitSaveNewLabel = () => {
    if (this.validateEnteredLabelName()) {
      this.addNewLabel();
    }
  };

  commitRenameLabel = () => {
    if (this.validateEnteredLabelName()) {
      this.renameExistingLabel();
    }
  };

  commitDeleteLabel = (label) => {
    this.deleteExistingLabel(label.id);
  };

  addNewLabel = async () => {
    this.setState({ addLoading: true });
    let result;
    const newLabelName = this.state.editingLabelText.trim();

    try {
      result = await this.props.dispatch(
        addNewLabel(
          newLabelName,
          this.state.addingLabelForWebsite
            ? LabelClassifications.Website
            : LabelClassifications.Vendor
        )
      );
    } catch (e) {
      this.props.dispatch(addDefaultUnknownErrorAlert(e.message));
      this.setState({ addLoading: false });
      return;
    }

    if (result) {
      const newLabels = this.insertLabelSorted(
        this.state.userLabelList,
        result
      );
      this.setState({
        userLabelList: newLabels,
        addLoading: false,
        addingLabel: false,
        editingLabelText: "",
      });
    }
  };

  renameExistingLabel = async () => {
    const newLabelName = this.state.editingLabelText.trim();
    const labelID = this.state.renamingLabelID;
    this.setState({ rowLoading: true });
    let result;

    try {
      result = await this.props.dispatch(renameLabel(labelID, newLabelName));
    } catch (e) {
      this.props.dispatch(addDefaultUnknownErrorAlert(e.message));
      this.setState({ rowLoading: false });
      return;
    }

    if (result) {
      // Add the new checked label, clearing the search term
      const newLabels = this.renameLabelInPlace(
        this.state.userLabelList,
        labelID,
        newLabelName
      );
      this.setState({
        userLabelList: newLabels,
        rowLoading: false,
        renamingLabel: false,
        renamingLabelID: 0,
        editingLabelText: "",
      });
    }
  };

  deleteExistingLabel = async (labelID) => {
    this.setState({
      deletingLabelID: labelID,
      rowLoading: true,
    });
    let result;

    try {
      result = await this.props.dispatch(deleteLabel(labelID));
    } catch (e) {
      this.props.dispatch(addDefaultUnknownErrorAlert(e.message));
      this.setState({
        deletingLabelID: 0,
        rowLoading: false,
      });
      return;
    }
    const newLabels = this.removeLabelInPlace(
      this.state.userLabelList,
      labelID
    );
    this.setState({
      userLabelList: newLabels,
      rowLoading: false,
      deletingLabelID: 0,
    });
  };

  fetchAllAvailableLabels = async () => {
    this.setState({ loading: true });
    let result;

    try {
      result = await this.props.dispatch(fetchAvailableLabels());
    } catch (e) {
      this.props.dispatch(
        addDefaultUnknownErrorAlert(
          "Failed to load current label set for account"
        )
      );
      this.setState({
        loading: false,
        userLabelList: [],
      });
      return;
    }
    this.setState({
      loading: false,
      userLabelList: this.filterLabelList(result),
    });
  };

  getCountOfExistingItemsForLabel = async (label) => {
    this.setState({
      deletingLabelID: label.id,
      deleteCounting: true,
    });
    let result;

    try {
      if (label.classification === LabelClassifications.Vendor) {
        result = await this.props.dispatch(countVendorsForLabel(label.id));
      } else {
        result = await this.props.dispatch(countWebsitesForLabel(label.id));
      }
    } catch (e) {
      this.props.dispatch(addDefaultUnknownErrorAlert(e.message));
      this.setState({
        deletingLabelID: 0,
        deleteCounting: false,
      });
      return;
    }
    this.setState({
      deleteCounting: false,
      deleteCount: result,
    });
    // if the count is 0 then just motivate the delete
    if (result === 0) {
      this.setState({
        rowLoading: false,
        deleteCount: result,
      });
      this.commitDeleteLabel(label);
    }
  };

  UNSAFE_componentWillMount() {
    // filter out the system labels from the label list so we are just managing user-defined labels
    // set the user defined label list in state
    const userLabelList = this.filterLabelList(
      this.props.modalData.availableLabels
    );
    this.setState({ userLabelList });
  }

  componentDidUpdate() {
    // if there is an edit control visible, then make sure it has focus.
    if (this.textEdit) {
      this.textEdit.focus();
    }
  }

  filterLabelList = (labelList) => {
    const userLabelList = [];
    labelList.map((label) => {
      if (label.classification !== LabelClassifications.System) {
        userLabelList.push(label);
      }
      return null;
    });
    return userLabelList;
  };

  insertLabelSorted = (existingLabelList, newLabel) => {
    const newArray = [];
    let inserted = false;
    existingLabelList.map((existing) => {
      if (!inserted && existing.name > newLabel.name) {
        newArray.push(newLabel);
        inserted = true;
      }
      newArray.push(existing);
      return null;
    });
    if (!inserted) {
      newArray.push(newLabel);
    }
    return newArray;
  };

  renameLabelInPlace = (existingLabelList, labelID, newName) => {
    let newArray = [];
    let newData = null;
    existingLabelList.map((existing) => {
      if (existing.id === labelID) {
        newData = existing;
        newData.name = newName;
      } else {
        newArray.push(existing);
      }
      return null;
    });
    // insert new data into alpha order
    newArray = this.insertLabelSorted(newArray, newData);
    return newArray;
  };

  removeLabelInPlace = (existingLabelList, labelID) => {
    const newArray = [];
    existingLabelList.map((existing) => {
      if (existing.id !== labelID) {
        newArray.push(existing);
      }
      return null;
    });
    return newArray;
  };

  getRows = () => {
    const rows = [];

    // first determine if we need an 'add label' row at the top of the table
    if (this.state.addingLabel) {
      const meatballOptions = [];
      let disabled = false;
      let inputClass = "text-edit-add";
      if (this.state.addLoading) {
        disabled = true;
        inputClass += " grayed";
      }
      rows.push({
        id: "adding",
        meatballOptions,
        meatballDisabled: false,
        cells: [
          <XTableCell key="add_edit">
            <div className="center-horizontal">
              <input
                type="text"
                name="textEdit"
                maxLength={LABEL_NAME_MAXLENGTH}
                disabled={disabled}
                className={inputClass}
                placeholder={
                  this.state.addingLabelForWebsite
                    ? "Enter a new domain label name"
                    : "Enter a new vendor label name"
                }
                required
                defaultValue={this.state.editingLabelText}
                onChange={this.setEditingText}
                onKeyDown={this.handleKeyDown}
                ref={(ref) => (this.textEdit = ref)}
              />
              {this.state.addLoading && (
                <LoadingIcon size={30} className="padded-loading" />
              )}
              {!this.state.addLoading && (
                <div className="center-horizontal">
                  <span
                    className="pointer padded-prompts"
                    onClick={() => this.cancelCurrentOperation()}
                  >
                    Cancel
                  </span>
                  <span
                    className="pointer padded-prompts"
                    onClick={() => this.commitSaveNewLabel()}
                  >
                    Save
                  </span>
                </div>
              )}
            </div>
          </XTableCell>,
        ],
      });
    }

    const vendorLabels = [];
    const websiteLabels = [];

    for (let i = 0; i < this.state.userLabelList.length; i++) {
      if (
        this.state.userLabelList[i].classification ===
        LabelClassifications.Vendor
      ) {
        vendorLabels.push(this.state.userLabelList[i]);
      } else if (
        this.state.userLabelList[i].classification ===
        LabelClassifications.Website
      ) {
        websiteLabels.push(this.state.userLabelList[i]);
      }
    }

    vendorLabels.sort((a, b) => a.name.localeCompare(b.name));
    websiteLabels.sort((a, b) => a.name.localeCompare(b.name));

    // now process all the actual label rows. note that one of them may be editing (renaming)
    const addLabelRow = (label) => {
      const circleIcon = <Circle bg={label.colour} />;
      const meatballOptions = [
        {
          id: "delete-label",
          text: "Delete",
          onClick: () => this.onClickDeleteLabel(label),
        },
        {
          id: "rename-label",
          text: "Rename",
          onClick: () => this.onClickRenameLabel(label),
        },
      ];

      // is the label row being edited? if so, render it with an edit control
      if (this.state.renamingLabel && label.id === this.state.renamingLabelID) {
        let disabled = false;
        let inputClass = "text-edit";
        const meatballDisabled = true;
        if (this.state.rowLoading) {
          disabled = true;
          inputClass += " grayed";
        }

        rows.push({
          id: label.id,
          meatballOptions,
          meatballDisabled,
          cells: [
            <XTableCell key="label_name">
              <div className="center-horizontal">
                {circleIcon}
                <input
                  type="text"
                  maxLength={LABEL_NAME_MAXLENGTH}
                  disabled={disabled}
                  className={inputClass}
                  name="textEdit"
                  placeholder="Enter a label name"
                  required
                  defaultValue={this.state.editingLabelText}
                  onChange={this.setEditingText}
                  onKeyDown={this.handleKeyDown}
                  ref={(ref) => (this.textEdit = ref)}
                />
                {this.state.rowLoading && (
                  <LoadingIcon size={30} className="padded-loading" />
                )}
                {!this.state.rowLoading && (
                  <div className="center-horizontal">
                    <span
                      className="pointer padded-prompts"
                      onClick={() => this.cancelCurrentOperation()}
                    >
                      Cancel
                    </span>
                    <span
                      className="pointer padded-prompts"
                      onClick={() => this.commitRenameLabel()}
                    >
                      Save
                    </span>
                  </div>
                )}
              </div>
            </XTableCell>,
          ],
        });
      } else if (
        label.id === this.state.deletingLabelID &&
        (this.state.rowLoading || this.state.deleteCounting)
      ) {
        // in the process of deleting;
        const textClass = "center-horizontal text-struck max-width";
        const meatballDisabled = true;
        const { name } = label;

        rows.push({
          id: label.id,
          meatballOptions,
          meatballDisabled,
          cells: [
            <XTableCell key="label_name">
              <div className={textClass}>
                {circleIcon}
                {name}
                <LoadingIcon size={25} className="padded-loading" />
              </div>
            </XTableCell>,
          ],
        });
      } else if (
        label.id === this.state.deletingLabelID &&
        this.state.deleteCount > 0
      ) {
        // in the process of deleting;
        const textClass = "center-horizontal text-struck max-width";
        const meatballDisabled = true;
        let promptText = ` used for 1 ${
          label.classification === LabelClassifications.Vendor
            ? "vendor"
            : "domain/IPs"
        } `;
        if (this.state.deleteCount > 1) {
          promptText = ` used for ${this.state.deleteCount} ${
            label.classification === LabelClassifications.Vendor
              ? "vendors"
              : "domain/IPs"
          } `;
        }
        const { name } = label;
        rows.push({
          id: label.id,
          meatballOptions,
          meatballDisabled,
          cells: [
            <XTableCell key="label_name">
              <div className="center-horizontal">
                <div className={textClass}>
                  {circleIcon}
                  {name}
                </div>
                <div className="center-horizontal">
                  <span className="padded-prompts confirm-text">
                    {promptText}
                  </span>
                  <div className="center-horizontal">
                    <span
                      className="pointer padded-prompts"
                      onClick={() => this.cancelCurrentOperation()}
                    >
                      Cancel
                    </span>
                    <span
                      className="pointer padded-prompts"
                      onClick={() => this.commitDeleteLabel(label)}
                    >
                      Confirm
                    </span>
                  </div>
                </div>
              </div>
            </XTableCell>,
          ],
        });
      } else {
        // just a standard label row with meatball;
        const meatballDisabled =
          this.state.loading ||
          this.state.rowLoading ||
          this.state.addLoading ||
          this.state.deleteCounting;
        const { name } = label;
        rows.push({
          id: label.id,
          meatballOptions,
          meatballDisabled,
          cells: [
            <XTableCell key="label_name">
              <div
                className="center-horizontal max-width"
                onDoubleClick={() => this.onDoubleClick(label)}
              >
                {circleIcon}
                {name}
              </div>
            </XTableCell>,
          ],
        });
      }
      return null;
    };

    if (
      !this.props.modalData.mode ||
      this.props.modalData.mode === LabelClassifications.Vendor
    ) {
      rows.push({
        id: "vendors-heading",
        className: "heading-row",
        cells: [<XTableCell key="heading">Vendor Labels</XTableCell>],
      });

      vendorLabels.map(addLabelRow);
    }

    if (
      !this.props.modalData.mode ||
      this.props.modalData.mode === LabelClassifications.Website
    ) {
      rows.push({
        id: "websites-heading",
        className: "heading-row",
        cells: [<XTableCell key="heading">Domain/IP Labels</XTableCell>],
      });

      websiteLabels.map(addLabelRow);
    }

    return rows;
  };

  render() {
    const availableLabels = this.state.userLabelList;
    let content = (
      <p className="text-only">
        You do not have any labels defined for your account. Click &quot;New
        Label&quot; to get started.
      </p>
    );
    if (
      availableLabels.length > 0 ||
      this.state.addingLabel ||
      this.state.loading
    ) {
      // get the table of labels to display
      const { loading } = this.state;
      const numLoadingRows = 3;
      const columnHeaders = [{ id: "labelname", text: "", sortable: false }];

      content = (
        <XTable
          loading={loading}
          numLoadingRows={numLoadingRows}
          meatballs
          columnHeaders={columnHeaders}
          rows={this.getRows()}
        />
      );
    }
    return (
      <div id="manage-labels-modal" className="modal-content">
        <div className="header">
          <div className="heading-left">
            <h2>Manage Custom Labels</h2>
          </div>
          <div className="buttons-right">
            {(!this.props.modalData.mode ||
              this.props.modalData.mode === LabelClassifications.Vendor) && (
              <Button
                disabled={
                  this.state.addingLabel ||
                  this.state.loading ||
                  this.state.addLoading ||
                  this.state.rowLoading ||
                  this.state.deleteCounting
                }
                onClick={() => this.onClickAddLabel(false)}
              >
                New Vendor Label
              </Button>
            )}
            {(!this.props.modalData.mode ||
              this.props.modalData.mode === LabelClassifications.Website) && (
              <Button
                disabled={
                  this.state.addingLabel ||
                  this.state.loading ||
                  this.state.addLoading ||
                  this.state.rowLoading ||
                  this.state.deleteCounting
                }
                onClick={() => this.onClickAddLabel(true)}
              >
                New Domain Label
              </Button>
            )}
          </div>
        </div>
        {content}
      </div>
    );
  }
}

export default ManageLabelsModal;
