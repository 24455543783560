import moment from "moment";
import { TimeUnit } from "chart.js";

export const DataLeaksClassificationNames = {
  finding: "Finding",
  "dismissed-blacklist": "Auto dismissed",
  "dismissed-analyst": "Dismissed by analyst",
};

export enum DataLeaksClassificationsType {
  CLASSIFICATION_FINDINGS = "finding",
  CLASSIFICATION_DISMISSED_ANALYST = "dismissed-analyst",
  CLASSIFICATION_DISMISSED_BLACKLIST = "dismissed-blacklist",
}

export enum DataLeaksFindingStatusType {
  Disclosed = "Disclosed",
  Acknowledged = "Acknowledged",
  Investigating = "Investigating",
  Remediating = "Remediating",
  ClosedNoIssue = "ClosedNoIssue",
  ClosedRiskAccepted = "ClosedRiskAccepted",
  ClosedFixedUnverified = "ClosedFixedUnverified",
  ClosedFixedVerified = "ClosedFixedVerified",
}

export enum DataLeaksGroupingType {
  Keyword = "keyword",
  Service = "service",
}

export interface ClassifiedScanResultTotals {
  key: string;
  total: number;
  totals: { [key in DataLeaksClassificationsType]: number };
}

// DataLeaksStatisticsBlock - statistics for data leaks keyword results
// Contains overall totals, keyword totals, and keyword level results for the most recent
// x days, where x is stored in the days property.
export interface DataLeaksStatisticsBlock {
  loading?: boolean;
  error?: any;
  days?: number;
  results: {
    scan_counts: {
      byKey: ClassifiedScanResultTotals[];
      totals: { [key in DataLeaksClassificationsType]: number };
    };
  };
}

// Top-level type for storing dataLeaksStatistics in Redux state (state.cyberRisk.dataLeakStatistics
export interface DataLeaksStatistics {
  [key: number]: DataLeaksStatisticsBlock;
  tab?: string;
}

// Get total results from a DataLeaksStatisticsBlock
export const GetTotalsForStatsBlock = (stats?: DataLeaksStatisticsBlock) => {
  if (stats?.results?.scan_counts?.totals === undefined || stats.loading) {
    return undefined;
  }

  let totalResults = 0;

  for (const total of Object.values(stats.results.scan_counts.totals)) {
    totalResults += total;
  }

  return totalResults;
};

export interface DataLeaksBreachSightFinding {
  finding_id: number;
  id: string;
  uuid: string;
  organisation_id: number;
  group_id: number;
  datastoreSearchResultId: number;
  name: string;
  severity: number;
  status: DataLeaksFindingStatusType;
  date_detected: string;
  last_updated_at: string;
  last_update_desc: string;
}

// Top-level type for storing data leaks findings in Redux state (state.cyberRisk.customerData.breachsight)
export interface DataLeaksBreachSightFindings {
  loading?: boolean;
  error?: any;
  data?: {
    findings: DataLeaksBreachSightFinding[];
    status: string;
  };
  counts?: {
    monthlyCounts: {
      monthStart: string;
      totalIgnored: number;
      totalFindings: number;
    }[];
    status: string;
    totalCurrentlyUnreviewed: number;
  };
}

// Store the result from a call to the DataLeaksSummary endpoint
export interface DataLeaksSummary {
  loading?: boolean;
  error?: any;
  categoryTotals?: {
    category: string;
    total: number;
  }[];
  results?: {
    startDate: string;
    endDate: string;
    categoryType: "keyword" | "service";
    groups: {
      startDate: string;
      endDate: string;
      categoryCounts: {
        [category: string]: number;
      };
    }[];
  };
}

// Top level state object to store any summaries we have retrieved
export interface DataLeaksSummaries {
  keyword?: {
    unfiltered?: {
      [dateKeys: string]: DataLeaksSummary;
    };
    filtered?: {
      [dateKeys: string]: DataLeaksSummary;
    };
  };
  service?: {
    unfiltered?: {
      [dateKeys: string]: DataLeaksSummary;
    };
    filtered?: {
      [dateKeys: string]: DataLeaksSummary;
    };
  };
}

export interface DataLeaksClassifiedScanResult {
  ID: number;
  DataleaksHitId: number;
  Classification: string;
  Service: string;
  OrgID: number;
  Keyword: string;
  Url: string;
  Snippet: string;
  UrlRedacted: boolean;
  SnippetRedacted: boolean;
  ParentId: number;
  FindingId: number;
  FindingStatus: string;
  CreatedAt: string;
}

// GetDateRangeFromTabName - determine the date range based on the tab name - (1_months, 3_months or 1_years)
export const GetDateRangeFromTabName = (
  tabName: string
): { startDate: moment.Moment; endDate: moment.Moment } | undefined => {
  if (tabName === "1_months") {
    return {
      startDate: moment().subtract(1, "months").add(1, "day"),
      endDate: moment(),
    };
  } else if (tabName === "3_months") {
    return {
      startDate: moment().subtract(3, "months").add(1, "day"),
      endDate: moment(),
    };
  } else if (tabName === "1_years") {
    return {
      startDate: moment().subtract(1, "year").add(1, "day"),
      endDate: moment(),
    };
  }

  // We don't know the tab name passed in - don't specify a date range
  return undefined;
};

export const GetTimeUnitFromDateRange = (
  startDate: moment.Moment,
  endDate: moment.Moment
): TimeUnit => {
  // If the date range is 1 month or less, group by day
  // If the date range is 6 months or less, group by week
  // If the date range is greater than 6 months, group by month
  const dateRangeDays = endDate.diff(startDate, "months");
  if (dateRangeDays < 1) {
    return "day";
  } else if (dateRangeDays < 6) {
    return "week";
  } else {
    return "month";
  }
};
