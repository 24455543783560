import { DefaultThunkDispatchProp } from "../../../_common/types/redux";
import { FC, useState } from "react";
import { SlidePanelSection } from "./SlidePanelSection";
import {
  OptionType,
  SelectV2Multi,
} from "../../../_common/components/SelectV2";
import LabelList from "../LabelList";
import { WatchlistVendorMini } from "../../../_common/types/vendor";
import { ValueType } from "react-select";
import { appConnect } from "../../../_common/types/reduxHooks";

interface IVendorFilterOwnProps {
  selectedOptions?: number[];
  onChange: (opts: number[]) => void;
  title?: string;
  startExpanded?: boolean;
}

interface IVendorFilterConnectedProps {
  loading: boolean;
  vendors?: WatchlistVendorMini[];
}

type IVendorFilterProps = IVendorFilterOwnProps &
  IVendorFilterConnectedProps &
  DefaultThunkDispatchProp;

const VendorFilter: FC<IVendorFilterProps> = ({
  selectedOptions: _selectedOptions = [],
  onChange,
  loading,
  vendors,
  title = "Filter by vendor",
  startExpanded = false,
}) => {
  const selectedOptions = vendors ? _selectedOptions : [];
  const [expanded, setExpanded] = useState(
    startExpanded || selectedOptions.length > 0
  );

  const selectOnChange = (selectedOptions: ValueType<OptionType, true>) => {
    onChange(
      selectedOptions ? selectedOptions.map(({ value }) => value as number) : []
    );
  };

  const options = (vendors || []).map(({ id, name }) => ({
    value: id,
    label: name,
  }));

  const selectValue = selectedOptions
    .map((id) => options.find(({ value }) => value === id) as OptionType)
    .filter((o) => !!o);

  const displayLabels = selectValue.map(({ value, label }) => ({
    id: value,
    name: label,
    color: "blue",
    removeable: true,
    large: true,
    constrained: true,
    onRemoveClick: () =>
      selectOnChange(selectValue.filter((option) => value !== option.value)),
  }));

  return (
    <SlidePanelSection
      title={title}
      expanded={expanded}
      toggleExpand={() => setExpanded(!expanded)}
    >
      <SelectV2Multi
        placeholder="Type to search vendors"
        options={options}
        value={selectValue}
        onChange={selectOnChange}
        isLoading={loading}
        controlShouldRenderValue={false}
      />
      <LabelList labels={displayLabels} />
    </SlidePanelSection>
  );
};

export default appConnect<
  IVendorFilterConnectedProps,
  never,
  IVendorFilterOwnProps
>((state) => {
  return {
    loading: state.cyberRisk.allVendors.loading,
    vendors: state.cyberRisk.allVendors.data?.vendors,
  };
})(VendorFilter);
