import { ISearchVendorsResp, VendorMini } from "../../_common/types/vendor";
import { FetchCyberRiskUrl } from "../../_common/api";
import { DefaultThunkDispatch } from "../../_common/types/redux";
import { LogError } from "../../_common/helpers";
import { DefaultRootState } from "react-redux";

export const fetchConcentrationRiskVendors = (vendorIds: number[]) => {
  return async (
    dispatch: DefaultThunkDispatch,
    getState: () => DefaultRootState
  ) => {
    // Check if we have the vendors cached
    const vendorMinis = getState().cyberRisk.vendorsMini;
    if (vendorMinis) {
      let allInCache = true;
      const vendors: VendorMini[] = [];
      for (const vId of vendorIds) {
        const vendor = vendorMinis[vId];

        if (!vendor) {
          allInCache = false;
          break;
        }

        vendors.push(vendor);
      }

      if (allInCache) {
        return vendors;
      }
    }

    // Not cached so lets retrieve them

    let vendors: VendorMini[];
    try {
      vendors = await FetchCyberRiskUrl(
        "concentrationrisk/vendors/v1/",
        {},
        {
          method: "POST",
          body: vendorIds.join(","),
        },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error fetching concentration risk vendors", e);

      throw e;
    }

    // Merge retrieved vendors into cached set
    const cachedVendors = getState().cyberRisk.vendorsMini || {};
    for (const v of vendors) {
      cachedVendors[v.vendor_id] = v;
    }

    // Update redux state with new set of cached vendors
    dispatch(setVendorsMini(cachedVendors));

    return vendors;
  };
};

export const fetchVendorMetaForFourthParty = (vendorId: number) => {
  return async (
    dispatch: DefaultThunkDispatch,
    getState: () => DefaultRootState
  ) => {
    // Check if we have the vendors cached
    const vendorMinis = getState().cyberRisk.vendorsMini;
    if (vendorMinis && vendorMinis[vendorId]) {
      return vendorMinis[vendorId];
    }

    // Not cached so lets retrieve them

    let vendor: VendorMini;
    try {
      vendor = await FetchCyberRiskUrl(
        "vendor/v1/",
        {
          vendor_id: vendorId,
        },
        {
          method: "GET",
        },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error fetching vendor meta for fourth party", e);

      throw e;
    }

    // Merge retrieved vendors into cached set
    const cachedVendors = getState().cyberRisk.vendorsMini || {};
    cachedVendors[vendor.vendor_id] = vendor;

    // Update redux state with new set of cached vendors
    dispatch(setVendorsMini(cachedVendors));

    return vendor;
  };
};

export const SET_VENDORS_MINI = "SET_VENDORS_MINI";
export const setVendorsMini = (vendors: { [vendorId: number]: VendorMini }) => {
  return {
    type: SET_VENDORS_MINI,
    vendors,
  };
};

export const searchVendorsForFourthParty = (searchTerm: string) => {
  return async (
    dispatch: DefaultThunkDispatch,
    getState: any
  ): Promise<ISearchVendorsResp> => {
    let resp: ISearchVendorsResp;
    try {
      resp = await FetchCyberRiskUrl<ISearchVendorsResp>(
        "vendorsearch/v1/",
        {
          srch: searchTerm,
          limit: 6,
          include_org_custom_vendors: true,
          consider_empty_cloudscan_vendors: true,
        },
        null,
        dispatch,
        getState,
        undefined,
        undefined
      );
    } catch (e) {
      console.error(e);
      throw e;
    }

    return resp;
  };
};

export interface VendorProduct {
  uuid: string;
  orgId?: number;
  tech_name: string;
  description: string;
  link: string;
  tag: string;
  vendor_id: number;
  used_by_vendor_ids?: number[];
  custom_used_by_vendor_ids?: number[];
}

export const fetchVendorProducts = (vendorId: number) => {
  return async (
    dispatch: DefaultThunkDispatch,
    getState: any
  ): Promise<VendorProduct[]> => {
    let resp: VendorProduct[];
    try {
      resp = await FetchCyberRiskUrl<VendorProduct[]>(
        "technology/v1/",
        {
          vendor_id: vendorId,
        },
        null,
        dispatch,
        getState,
        undefined,
        undefined
      );
    } catch (e) {
      console.error(e);
      throw e;
    }

    return resp;
  };
};

interface addOrgVendorTechnologyV1Body {
  vendorId: number;
  name: string;
  tag: string;
  description: string;
  link: string;
}

export const addCustomVendorProduct = (
  vendorId: number,
  name: string,
  tag: string,
  description = "",
  link = ""
) => {
  return async (
    dispatch: DefaultThunkDispatch,
    getState: any
  ): Promise<VendorProduct> => {
    let resp: VendorProduct;
    try {
      resp = await FetchCyberRiskUrl<VendorProduct>(
        "technology/v1/",
        {},
        {
          method: "POST",
          body: JSON.stringify({
            vendorId,
            name,
            tag,
            description,
            link,
          } as addOrgVendorTechnologyV1Body),
        },
        dispatch,
        getState,
        undefined,
        undefined
      );
    } catch (e) {
      console.error(e);
      throw e;
    }

    return resp;
  };
};

interface updateOrgVendorTechnologyV1Body {
  vendorId: number;
  techUUID: string;
  name: string;
  tag: string;
  description: string;
  link: string;
}

export const updateCustomVendorProduct = (
  vendorId: number,
  techUUID: string,
  name: string,
  tag: string,
  description = "",
  link = ""
) => {
  return async (
    dispatch: DefaultThunkDispatch,
    getState: any
  ): Promise<VendorProduct> => {
    let resp: VendorProduct;
    try {
      resp = await FetchCyberRiskUrl<VendorProduct>(
        "technology/v1/",
        {},
        {
          method: "PUT",
          body: JSON.stringify({
            vendorId,
            techUUID,
            name,
            tag,
            description,
            link,
          } as updateOrgVendorTechnologyV1Body),
        },
        dispatch,
        getState,
        undefined,
        undefined
      );
    } catch (e) {
      console.error(e);
      throw e;
    }

    return resp;
  };
};

export const deleteCustomVendorProduct = (techUUID: string) => {
  return async (
    dispatch: DefaultThunkDispatch,
    getState: any
  ): Promise<void> => {
    try {
      await FetchCyberRiskUrl(
        "technology/v1/",
        {
          tech_uuid: techUUID,
        },
        {
          method: "DELETE",
        },
        dispatch,
        getState,
        undefined,
        undefined
      );
    } catch (e) {
      console.error(e);
      throw e;
    }
  };
};

interface assignOrgVendorTechnologyV1 {
  vendorId: number;
  owningVendorId: number;
  techUsages: {
    uuid: string;
    isCustom: boolean;
  }[];
}

export const assignOrgVendorTechnology = (
  vendorId: number,
  techUsages: {
    uuid: string;
    isCustom: boolean;
  }[],
  owningVendorId: number
) => {
  return async (
    dispatch: DefaultThunkDispatch,
    getState: any
  ): Promise<void> => {
    try {
      await FetchCyberRiskUrl(
        "vendor/technology/v1/",
        {},
        {
          method: "PUT",
          body: JSON.stringify({
            vendorId,
            techUsages,
            owningVendorId,
          } as assignOrgVendorTechnologyV1),
        },
        dispatch,
        getState,
        undefined,
        undefined
      );
    } catch (e) {
      console.error(e);
      throw e;
    }
  };
};

export const unassignOrgVendorTechnology = (
  vendorId: number,
  techUUID: string,
  isCustom: boolean,
  owningVendorId: number
) => {
  return async (
    dispatch: DefaultThunkDispatch,
    getState: any
  ): Promise<void> => {
    try {
      await FetchCyberRiskUrl(
        "vendor/technology/v1/",
        {
          vendor_id: vendorId,
          tech_uuid: techUUID,
          is_custom: isCustom,
          owning_vendor_id: owningVendorId,
        },
        {
          method: "DELETE",
        },
        dispatch,
        getState,
        undefined,
        undefined
      );
    } catch (e) {
      console.error(e);
      throw e;
    }
  };
};
