import { FC, useState } from "react";
import moment from "moment";

import { SlidePanelSection } from "./SlidePanelSection";
import DateRangeSlider from "../../../_common/components/DateRangeSlider";

interface IDataLeaksReportedDateFilterProps {
  startDate?: moment.Moment;
  endDate?: moment.Moment;
  title?: string;
  onChange: (obj?: {
    startDate: moment.Moment;
    endDate: moment.Moment;
  }) => void;
  startExpanded?: boolean;
}

const DataLeaksReportedDateFilter: FC<IDataLeaksReportedDateFilterProps> = (
  props
) => {
  const [expanded, setExpanded] = useState(
    props.startExpanded || !!props.startDate || !!props.endDate
  );

  const maxDate = moment();
  const minDate = moment(maxDate).subtract(1, "year").add(1, "day");

  return (
    <SlidePanelSection
      title={props.title ? props.title : "Filter by created date"}
      expanded={expanded}
      toggleExpand={() => setExpanded(!expanded)}
    >
      <p className="explanation-text">
        Drag the handles below to set a range of dates. Only data leaks reported
        within the specified range will be included.
      </p>
      <DateRangeSlider
        maxDate={maxDate}
        minDate={minDate}
        onChange={(opts) => {
          props.onChange(
            opts.startDate && opts.endDate
              ? { startDate: opts.startDate, endDate: opts.endDate }
              : undefined
          );
        }}
        startDate={props.startDate}
        endDate={props.endDate}
        enforceRange={true}
      ></DateRangeSlider>
    </SlidePanelSection>
  );
};

export default DataLeaksReportedDateFilter;
