import { FC } from "react";
import { IVerifiedVendorAccessItemNda } from "../../types/sharedAssessment";
import {
  ButtonWithDropdownV2,
  DropdownItem,
} from "../../../_common/components/core/DropdownV2";
import { formatDateAsLocal } from "../../../_common/helpers";
import { getCyberRiskAuth } from "../../../_common/session";
import Button from "../../../_common/components/core/Button";

export const getDownloadNdaLink = (ndaId: number) => {
  const { apiKey, token } = getCyberRiskAuth();
  const url = `/api/sharedassessment/ndas/v1/?apikey=${encodeURIComponent(
    apiKey
  )}&token=${encodeURIComponent(token)}&signed_nda_id=${ndaId}`;
  return url;
};

interface NdasButtonProps {
  signedNdas?: IVerifiedVendorAccessItemNda[];
}

const NdasButton: FC<NdasButtonProps> = ({ signedNdas }) => {
  const downloadNda = (ndaId: number) => {
    window.open(getDownloadNdaLink(ndaId), "_blank");
  };

  if (!signedNdas || signedNdas.length === 0) {
    return <></>;
  } else if (signedNdas.length === 1) {
    // Just display as single button
    return (
      <Button tertiary onClick={() => downloadNda(signedNdas[0].id)}>
        <i className={"cr-icon-export"} />
        {signedNdas[0].revokedAt ? "Download previous" : "Download"}
      </Button>
    );
  }

  const items = signedNdas.map((i) => (
    <DropdownItem key={i.id} onClick={() => downloadNda(i.id)}>
      {formatDateAsLocal(i.signedAt)}
      {i.revokedAt ? " (revoked)" : ""}
    </DropdownItem>
  ));

  return (
    <ButtonWithDropdownV2
      icon={<i className={"cr-icon-export"} />}
      text={"View history"}
    >
      {items}
    </ButtonWithDropdownV2>
  );
};

export default NdasButton;
