import { Portfolio, PortfolioType } from "../../reducers/portfolios.actions";
import { DefaultThunkDispatchProp } from "../../../_common/types/redux";
import { FC, useMemo, useState } from "react";
import { SlidePanelSection } from "./SlidePanelSection";
import LoadingIcon from "../../../_common/components/core/LoadingIcon";
import PortfolioChoiceSet from "../portfolios/PortfolioChoiceSet";
import { appConnect } from "../../../_common/types/reduxHooks";

interface PortfolioFilterOwnProps {
  portfolioType: PortfolioType;
  selectedPortfolioIds: number[];
  disabledPortfolioIds?: number[];
  onChange: (portfolioIds: number[]) => void;
  title?: string;
  startExpanded?: boolean;
}

interface PortfolioFilterConnectedProps {
  portfolios?: Portfolio[];
}

type PortfolioFilterProps = PortfolioFilterOwnProps &
  PortfolioFilterConnectedProps &
  DefaultThunkDispatchProp;

const PortfolioFilter: FC<PortfolioFilterProps> = ({
  selectedPortfolioIds: selectedPortfolioIdsProp,
  disabledPortfolioIds: disabledPortfolioIdsProp,
  onChange,
  portfolios,
  title = "Filter by portfolio",
  startExpanded = false,
}) => {
  const [expanded, setExpanded] = useState(
    startExpanded || selectedPortfolioIdsProp.length > 0
  );

  const [selectedPortfolioIds, disabledPortfolioIds] = useMemo(() => {
    // When only one portfolio exists (or the user only has access to one portfolio),
    // just always show that as selected and disabled.
    if (portfolios?.length === 1) {
      return [[portfolios[0].id], [portfolios[0].id]];
    }

    return [selectedPortfolioIdsProp, disabledPortfolioIdsProp];
  }, [selectedPortfolioIdsProp, disabledPortfolioIdsProp, portfolios]);

  return (
    <SlidePanelSection
      title={title}
      toggleExpand={() => setExpanded(!expanded)}
      expanded={expanded}
    >
      {portfolios ? (
        <PortfolioChoiceSet
          multiSelect
          portfolios={portfolios}
          selectedPortfolioIds={selectedPortfolioIds}
          disabledPortfolioIds={disabledPortfolioIds}
          onSelectionChange={onChange}
        />
      ) : (
        <div className={"loading"}>
          <LoadingIcon size={32} />
        </div>
      )}
    </SlidePanelSection>
  );
};

export default appConnect<
  PortfolioFilterConnectedProps,
  never,
  PortfolioFilterOwnProps
>((state, props) => {
  return {
    portfolios:
      props.portfolioType === PortfolioType.Vendor
        ? state.cyberRisk.vendorPortfolios?.portfolios
        : props.portfolioType === PortfolioType.Domain
          ? state.cyberRisk.domainPortfolios?.portfolios
          : undefined,
  };
})(PortfolioFilter);
