import { Component, Fragment } from "react";
import { DefaultThunkDispatch } from "../../../_common/types/redux";
import { History } from "history";
import LoadingBanner from "../../../_common/components/core/LoadingBanner";
import IconButton from "../../../_common/components/IconButton";
import { IWebHook } from "../../../_common/types/webhooks";
import { WebhookParamType } from "../../views/CreateIntegration";

interface ISelectWebhookDestinationStepProps {
  history: History;
  dispatch: DefaultThunkDispatch;
  loading: boolean;
  description?: string;
  webhook?: IWebHook;
  webhookErrorsFound: boolean;
  setDescription: (name: string) => void;
  setWebhookURL: (url: string) => void;
  setWebhookParam: (
    type: WebhookParamType,
    idx: number,
    key: string,
    value: string
  ) => void;
  deleteWebhookParam: (type: WebhookParamType, idx: number) => void;
  newWebhookParam: (type: WebhookParamType, key: string, value: string) => void;
  setBasicAuthParam: (name: string, value: string) => void;
  deleteBasicAuth: () => void;
}

interface ISelectWebhookDestinationStepState {
  currentlyEditedElement: string;
}
class SelectWebhookDestinationStep extends Component<
  ISelectWebhookDestinationStepProps,
  ISelectWebhookDestinationStepState
> {
  static defaultProps = {
    description: "",
    webhook: {} as IWebHook,
  };

  constructor(props: ISelectWebhookDestinationStepProps) {
    super(props);
    this.state = {
      currentlyEditedElement: "",
    } as ISelectWebhookDestinationStepState;
  }

  componentDidUpdate() {
    if (this.state.currentlyEditedElement != "") {
      // if we were editing a field, focus on it and remove the reference from the state
      (
        this[
          this.state
            .currentlyEditedElement as keyof SelectWebhookDestinationStep
        ] as any
      ).focus();
      this.setState({ currentlyEditedElement: "" });
    }
  }

  shouldComponentUpdate(
    _nextProps: ISelectWebhookDestinationStepProps,
    nextState: ISelectWebhookDestinationStepState
  ) {
    return !(
      this.state.currentlyEditedElement && !nextState.currentlyEditedElement
    );
  }

  newWebhookParam = (type: WebhookParamType, key: string, value: string) => {
    // find the index of the last element we added and focus on it
    let newIdx = 0;
    if (this.props.webhook && this.props.webhook) {
      switch (type) {
        case "urlParams":
          newIdx = this.props.webhook.urlParams
            ? this.props.webhook.urlParams.length
            : 0;
          break;
        case "headerParams":
          newIdx = this.props.webhook.headerParams
            ? this.props.webhook.headerParams.length
            : 0;
          break;
      }
    }
    this.setState({
      currentlyEditedElement: `${type}-${newIdx}-${key}`,
    });
    this.props.newWebhookParam(type, key, value);
  };

  render() {
    return (
      <div className="section-step">
        <div id={"destination"}>
          {this.props.loading && <LoadingBanner />}
          {!this.props.loading && (
            <>
              <div className={"title-bar"}>
                <div className="header">Name & destination</div>
              </div>
              <div className={"body"}>
                <div className={"field"}>
                  <div className="field-desc">
                    Name of the integration*
                    <p>Please provide a name for this integration.</p>
                  </div>
                  <div className="field-input">
                    <input
                      type="text"
                      name="description"
                      value={this.props.description}
                      placeholder="Name of integration"
                      onChange={(e) => {
                        this.props.setDescription(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className={"field"}>
                  <div className="field-desc">
                    Webhook URL*
                    <p>Please enter the URL of the webhook.</p>
                  </div>
                  <div className="field-input">
                    <input
                      type="text"
                      name="webhook_url"
                      value={
                        this.props.webhook ? this.props.webhook.webhookURL : ""
                      }
                      placeholder="Webhook URL"
                      onChange={(e) => {
                        this.props.setWebhookURL(e.target.value);
                      }}
                    />
                  </div>
                </div>
                {this.props.webhook && (
                  <>
                    {[
                      {
                        type: "headerParams" as WebhookParamType,
                        header: "HTTP headers",
                        desc: "Configure any HTTP Header values required to connect to your URL.",
                        params: this.props.webhook.headerParams,
                      },
                      {
                        type: "urlParams" as WebhookParamType,
                        header: "HTTP URL parameters",
                        desc: "Configure any URL parameter values required to connect to your URL.",
                        params: this.props.webhook.urlParams,
                      },
                    ].map(({ type, header, desc, params }) => {
                      return (
                        <Fragment key={type}>
                          <div className={"field"}>
                            <div className="field-desc">
                              {header}
                              <p>{desc}</p>
                            </div>
                            <div className="field-input">
                              <div className="attributes-group">
                                {params &&
                                  params.map((data, idx) => (
                                    <div className={"attribute-row"} key={idx}>
                                      <div className={"name"}>
                                        <input
                                          type="text"
                                          value={data.name}
                                          placeholder="Name"
                                          className={
                                            this.props.webhookErrorsFound &&
                                            !data.name
                                              ? "invalid"
                                              : ""
                                          }
                                          onChange={(e) =>
                                            this.props.setWebhookParam(
                                              type,
                                              idx,
                                              "name",
                                              e.target.value
                                            )
                                          }
                                          ref={(input) =>
                                            // @ts-ignore
                                            (this[`${type}-${idx}-name`] =
                                              input)
                                          }
                                        />
                                        {this.props.webhookErrorsFound &&
                                          !data.name && (
                                            <span
                                              className={"validation-error"}
                                            >
                                              <i
                                                className={
                                                  "cr-icon-exclamation"
                                                }
                                              />
                                              Please enter a name
                                            </span>
                                          )}
                                      </div>
                                      <div className={"value second"}>
                                        <input
                                          type="text"
                                          value={data.value}
                                          placeholder="Value"
                                          className={
                                            this.props.webhookErrorsFound &&
                                            !data.value
                                              ? "invalid"
                                              : ""
                                          }
                                          onChange={(e) =>
                                            this.props.setWebhookParam(
                                              type,
                                              idx,
                                              "value",
                                              e.target.value
                                            )
                                          }
                                          ref={(input) =>
                                            // @ts-ignore
                                            (this[`${type}-${idx}-value`] =
                                              input)
                                          }
                                        />
                                        {this.props.webhookErrorsFound &&
                                          !data.value && (
                                            <span
                                              className={"validation-error"}
                                            >
                                              <i
                                                className={
                                                  "cr-icon-exclamation"
                                                }
                                              />
                                              Please enter a value
                                            </span>
                                          )}
                                      </div>
                                      <IconButton
                                        onClick={() =>
                                          this.props.deleteWebhookParam(
                                            type,
                                            idx
                                          )
                                        }
                                        icon={<i className="cr-icon-trash" />}
                                      />
                                    </div>
                                  ))}
                                <div className="attribute-row" key={type}>
                                  <div className={"name"}>
                                    <input
                                      type="text"
                                      value={""}
                                      placeholder="Name"
                                      onChange={(e) =>
                                        this.newWebhookParam(
                                          type,
                                          "name",
                                          e.target.value
                                        )
                                      }
                                      ref={(input) => {
                                        const idx =
                                          this.props.webhook &&
                                          this.props.webhook[
                                            type as keyof IWebHook
                                          ]
                                            ? (
                                                this.props.webhook[
                                                  type as keyof IWebHook
                                                ] as []
                                              ).length
                                            : 0;
                                        // @ts-ignore
                                        this[`${type}-${idx}-name`] = input;
                                      }}
                                    />
                                  </div>
                                  <div className={"value second"}>
                                    <input
                                      type="text"
                                      value={""}
                                      placeholder="Value"
                                      onChange={(e) =>
                                        this.newWebhookParam(
                                          type,
                                          "value",
                                          e.target.value
                                        )
                                      }
                                      ref={(input) => {
                                        const idx =
                                          this.props.webhook &&
                                          this.props.webhook[
                                            type as keyof IWebHook
                                          ]
                                            ? (
                                                this.props.webhook[
                                                  type as keyof IWebHook
                                                ] as []
                                              ).length
                                            : 0;

                                        // @ts-ignore
                                        this[`${type}-${idx}-value`] = input;
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Fragment>
                      );
                    })}
                  </>
                )}

                <div className={"field"}>
                  <div className="field-desc">
                    Basic authentication
                    <p>
                      If your URL implements HTTP Basic Authentication,
                      configure a username/password here.
                    </p>
                  </div>
                  <div className="field-input">
                    <div className="attributes-group">
                      <div className="attribute-row">
                        <input
                          type="text"
                          value={
                            this.props.webhook?.basicAuth?.username
                              ? this.props.webhook.basicAuth.username
                              : ""
                          }
                          placeholder="Username"
                          onChange={(e) =>
                            this.props.setBasicAuthParam(
                              "username",
                              e.target.value
                            )
                          }
                        />
                        <input
                          type="password"
                          className="second"
                          value={
                            this.props.webhook?.basicAuth?.password
                              ? this.props.webhook.basicAuth.password
                              : ""
                          }
                          placeholder="Password"
                          onChange={(e) =>
                            this.props.setBasicAuthParam(
                              "password",
                              e.target.value
                            )
                          }
                        />
                        {this.props.webhook?.basicAuth && (
                          <IconButton
                            onClick={() => this.props.deleteBasicAuth()}
                            icon={<i className="cr-icon-trash" />}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    );
  }
}

export default SelectWebhookDestinationStep;
