import { useBack } from "../../_common/types/router";
import { FC, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import PageHeader from "../../_common/components/PageHeader";
import userbaseApi from "../api/userbase.api";
import XTable, {
  IXTableColumnHeader,
  IXTableRow,
  SortDirection,
  XTableCell,
} from "../../_common/components/core/XTable";
import { useSortingWithPagination } from "../../_common/hooks";
import { IdentityBreach } from "../api/types";
import IdentityBreachSeverityIcon from "../components/IdentityBreaches/IdentityBreachSeverity";
import IdentityBreachDate from "../components/IdentityBreaches/IdentityBreachDate";
import IdentityBreachDetails from "../components/IdentityBreaches/IdentityBreachDetails";
import IdentityBreachDataClasses from "../components/IdentityBreaches/IdentityBreachDataClasses";
import DateTimeFormat from "../../_common/components/core/DateTimeFormat";
import SearchBox from "../../_common/components/SearchBox";
import SearchEmptyCard from "../../_common/components/SearchEmptyCard";
import ReportCard from "../../_common/components/ReportCard";
import PillLabel from "../../vendorrisk/components/PillLabel";
import { LabelColor } from "../../_common/types/label";
import "./IdentityBreachesView.scss";
import { identityBreachesUrl, identityBreachUrl } from "../UserBaseAppRouter";
import EmptyCard from "../../vendorrisk/components/EmptyCard";
import LoadingBanner from "../../_common/components/core/LoadingBanner";

const PAGE_SIZE = 100;

const SEVERITY_COL = "severity_col";
const BREACH_DATE_COL = "breach_date_col";
const PUBLISHED_DATE_COL = "published_date_col";
const BREACH_TYPE_COL = "breach_type_col";
const DETAILS_COL = "details_col";
const DATA_CLASSES_COL = "data_classes_col";
const STATUS_COL = "status_col";

const IdentityBreachesView: FC = ({}) => {
  const history = useHistory();
  const { backAction, backText } = useBack();
  const { pathname } = useLocation();

  const [searchText, setSearchText] = useState("");

  const { data, isLoading } =
    userbaseApi.useGetUserBaseIdentityBreachesV1Query();

  const breaches = data?.breaches ?? [];
  const filteredBreaches = useMemo(() => {
    if (!searchText) {
      return breaches;
    }
    return breaches.filter((b) => {
      const lowerSearchText = searchText.toLocaleLowerCase();
      return (
        b.name.toLocaleLowerCase().includes(lowerSearchText) ||
        b.title.toLocaleLowerCase().includes(lowerSearchText) ||
        b.description.toLocaleLowerCase().includes(lowerSearchText) ||
        b.dataClasses
          ?.map((dt) => dt.Class.toLocaleLowerCase())
          .join(",")
          .includes(lowerSearchText)
      );
    });
  }, [breaches, searchText]);

  const columnHeaders: IXTableColumnHeader[] = [
    {
      id: SEVERITY_COL,
      text: "Severity",
      sortable: true,
      className: "severity-col",
    },
    {
      id: BREACH_DATE_COL,
      text: "Date of breach",
      sortable: true,
      className: "breach-date-col",
    },
    {
      id: PUBLISHED_DATE_COL,
      text: "Published date",
      sortable: true,
      className: "published-date-col",
    },
    {
      id: BREACH_TYPE_COL,
      text: "Breach type",
      sortable: true,
      className: "breach-type-col",
    },
    {
      id: DETAILS_COL,
      text: "Details",
      sortable: true,
      className: "details-col",
    },
    {
      id: DATA_CLASSES_COL,
      text: "Data exposed",
      sortable: true,
      className: "data-classes-col",
    },
    {
      id: STATUS_COL,
      text: "Status",
      sortable: true,
      className: "status-col",
    },
  ];

  const [
    sortedBreaches,
    sortedBy,
    onSortChange,
    currentPage,
    totalPages,
    onPageChange,
  ] = useSortingWithPagination<
    IdentityBreach,
    | typeof SEVERITY_COL
    | typeof BREACH_DATE_COL
    | typeof PUBLISHED_DATE_COL
    | typeof BREACH_TYPE_COL
    | typeof DETAILS_COL
    | typeof DATA_CLASSES_COL
    | typeof STATUS_COL
  >(
    filteredBreaches,
    PUBLISHED_DATE_COL,
    SortDirection.ASC,
    {
      [SEVERITY_COL]: {
        orderFuncs: [
          (b) => (b.archived ? -2 : b.isDarkWebBreach ? -1 : b.riskScore),
          (b) => b.name,
        ],
        sortDirsDesc: [SortDirection.DESC, SortDirection.ASC],
        sortDirsAsc: [SortDirection.ASC, SortDirection.ASC],
      },
      [BREACH_DATE_COL]: {
        orderFuncs: [(b) => b.breachDate],
        sortDirsAsc: [SortDirection.ASC],
        sortDirsDesc: [SortDirection.DESC],
      },
      [PUBLISHED_DATE_COL]: {
        orderFuncs: [(b) => b.publishedDate],
        sortDirsAsc: [SortDirection.ASC],
        sortDirsDesc: [SortDirection.DESC],
      },
      [BREACH_TYPE_COL]: {
        orderFuncs: [(b) => b.breachType],
        sortDirsAsc: [SortDirection.ASC],
        sortDirsDesc: [SortDirection.DESC],
      },
      [DETAILS_COL]: {
        orderFuncs: [(b) => b.title.toLocaleLowerCase()],
        sortDirsAsc: [SortDirection.ASC],
        sortDirsDesc: [SortDirection.DESC],
      },
      [DATA_CLASSES_COL]: {
        orderFuncs: [
          (b) =>
            b.dataClasses
              ?.map((dt) => dt.Class.toLocaleLowerCase())
              .sort((a, b) => a.localeCompare(b))
              .join(","),
        ],
        sortDirsAsc: [SortDirection.ASC],
        sortDirsDesc: [SortDirection.DESC],
      },
      [STATUS_COL]: {
        orderFuncs: [(b) => (b.archived ? -1 : 1)],
        sortDirsAsc: [SortDirection.ASC],
        sortDirsDesc: [SortDirection.DESC],
      },
    },
    PAGE_SIZE,
    undefined,
    pathname
  );

  const goToIdentityBreachPage = (breach: IdentityBreach) => {
    history.push(identityBreachUrl(breach.id), {
      from: "identity breaches",
      backContext: {
        backTo: identityBreachesUrl,
        backToText: "Back to Identity Breaches",
      },
    });
  };

  const rows = sortedBreaches.map<IXTableRow>((breach) => {
    return {
      id: breach.name,
      onClick: () => goToIdentityBreachPage(breach),
      cells: [
        <XTableCell key={SEVERITY_COL} className="breach-severity">
          <IdentityBreachSeverityIcon breach={breach} />
        </XTableCell>,
        <XTableCell key={BREACH_DATE_COL} className="breach-date">
          <IdentityBreachDate breach={breach} />
        </XTableCell>,
        <XTableCell key={PUBLISHED_DATE_COL} className="breach-published-date">
          <DateTimeFormat dateTime={breach.publishedDate} dateOnly />
        </XTableCell>,
        <XTableCell key={BREACH_DATE_COL} className="breach-type">
          {breach.breachType}
        </XTableCell>,
        <XTableCell key={DETAILS_COL} className="breach-details">
          <IdentityBreachDetails breach={breach} />
        </XTableCell>,
        <XTableCell key={DATA_CLASSES_COL} className="breach-data-classes">
          <IdentityBreachDataClasses breach={breach} maxWidth={250} />
        </XTableCell>,
        <XTableCell key={STATUS_COL} className="breach-status">
          {breach.archived ? (
            <PillLabel color={LabelColor.Grey}>Archived</PillLabel>
          ) : (
            <PillLabel color={LabelColor.Green}>Active</PillLabel>
          )}
        </XTableCell>,
      ],
    };
  });

  const showEmptySearchCard =
    !isLoading && searchText.length > 0 && sortedBreaches.length === 0;
  const showEmptyCard =
    !isLoading && searchText.length === 0 && sortedBreaches.length === 0;

  const showTable = !isLoading && sortedBreaches.length > 0;

  return (
    <div className={"userbase-identity-breaches"}>
      <PageHeader
        history={history}
        title="Identity Breaches"
        backAction={backAction}
        backText={backText}
        infoSectionPageKey={"userbaseIdentityBreaches"}
        infoSection={
          <p>
            Identity breaches provides information about third-party data
            breaches that exposed your employees&apos; credentials. When we find
            a match, we&apos;ll let you know and allow you to notify any
            impacted employees. The severity of a breach depends on the type and
            amount of data exposed.
          </p>
        }
      />
      <ReportCard newStyles>
        <SearchBox
          onChanged={(v) => {
            onPageChange(1);
            setSearchText(v);
          }}
          value={searchText}
          disabled={isLoading}
        />
        {isLoading && <LoadingBanner />}
        {showEmptySearchCard && (
          <SearchEmptyCard
            onClear={() => setSearchText("")}
            searchItemText={"identity breaches"}
          />
        )}
        {showEmptyCard && (
          <EmptyCard text="There are no identity breaches to display." />
        )}
        {showTable && (
          <XTable
            stickyColumnHeaders={false}
            numLoadingRows={3}
            className={"identity-breaches-container"}
            columnHeaders={columnHeaders}
            rows={rows}
            loading={isLoading}
            iconOptions
            sortedBy={sortedBy}
            onSortChange={onSortChange}
            pagination={{
              currentPage: currentPage,
              totalPages: totalPages,
              onPageChange: onPageChange,
              hidePaginationIfSinglePage: true,
            }}
          />
        )}
      </ReportCard>
    </div>
  );
};

export default IdentityBreachesView;
