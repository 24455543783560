import { DefaultThunkDispatch } from "../../../_common/types/redux";
import { INotificationConfigCategory } from "../../../_common/types/notifications";
import SelectableSidebarTriggersList from "./SelectableSidebarTriggersList";
import { liquidSyntaxGuideURL } from "../../views/CreateIntegration";
import Button from "../../../_common/components/core/Button";
import {
  addDefaultSuccessAlert,
  addDefaultUnknownErrorAlert,
  addDefaultWarningAlert,
} from "../../../_common/reducers/messageAlerts.actions";
import { GetExampleMessageVariables } from "./ConfigureSlackMessageFormatsStep";
import MessageCustomizationHelp from "./MessageCustomizationHelp";
import TextField from "../../../_common/components/TextField";
import { useState } from "react";
import { executeEmailTest } from "../../reducers/integrations.actions";
import LoadingBanner from "../../../_common/components/core/LoadingBanner";
import { IOrgAlertDefinition } from "../../reducers/org.actions";

interface IEmailPreviewProps {
  useCustomLogo: boolean;
  customLogoUrl?: string;
  message: string;
  createdByEmail: string;
}

const EmailPreview = ({
  createdByEmail,
  customLogoUrl,
  message,
  useCustomLogo,
}: IEmailPreviewProps) =>
  useCustomLogo && customLogoUrl === undefined ? (
    <LoadingBanner />
  ) : (
    <div className={"email-preview"}>
      <div className={"email-preview-container"}>
        <div className={"logo"}>
          <img
            className={"logo-preview"}
            src={
              useCustomLogo && customLogoUrl !== ""
                ? customLogoUrl
                : "https://storage.googleapis.com/cyber-risk-static/upguard-logo.svg"
            }
          />
        </div>
        <div
          className={"message"}
          dangerouslySetInnerHTML={{ __html: message }}
        />
        <div className={"sub-message"}>
          This email was triggered by an automated integration created by{" "}
          {createdByEmail}. If this email was unexpected, please contact the
          creator or email support@upguard.com.
        </div>
        <div className={"line"} />
        <div className={"disclaimer"}>
          <p>
            UpGuard is a complete third-party risk and attack surface management
            platform.
          </p>
          <p>
            If you would like more information on the UpGuard platform, please
            visit upguard.com.
          </p>
        </div>
      </div>
    </div>
  );

interface IConfigureEmailPayloadsStepProps {
  dispatch: DefaultThunkDispatch;
  notifications: INotificationConfigCategory<IOrgAlertDefinition>[];
  notificationsByUUID: Record<string, IOrgAlertDefinition | undefined>;
  selectedIntegrationID: number;
  selectedUUIDs: {
    [uuid: string]: boolean;
  };
  selectedTestUUID: string;
  setSelectedTestNotification: (notification: IOrgAlertDefinition) => void;
  resetPayloadTemplateToDefault: () => void;
  onChangePayloadTemplate: (template: string) => void;
  selectedTestJSON: any;
  selectedPayloadTemplate: string;
  selectedEmailSubject: string;
  setSelectedEmailSubject: (subject: string) => void;
  resetSelectedEmailSubjectToDefault: () => void;
  validateEmailTarget: () => { valid: boolean; msg: string };
  getEmailExampleMessage: () => { message: string; error: string | undefined };
  orgLogoUrl?: string;
  useOrgLogo: boolean;
  currentUserEmail: string;
}

const ConfigureEmailPayloadsStep = ({
  dispatch,
  notifications,
  notificationsByUUID,
  selectedIntegrationID,
  selectedTestUUID,
  selectedUUIDs,
  setSelectedTestNotification,
  resetPayloadTemplateToDefault,
  onChangePayloadTemplate,
  selectedTestJSON,
  selectedPayloadTemplate,
  selectedEmailSubject,
  setSelectedEmailSubject,
  resetSelectedEmailSubjectToDefault,
  validateEmailTarget,
  getEmailExampleMessage,
  orgLogoUrl,
  useOrgLogo,
  currentUserEmail,
}: IConfigureEmailPayloadsStepProps) => {
  const [testingEmail, setTestingEmail] = useState(false);
  const testEmail = () => {
    setTestingEmail(true);
    dispatch(
      executeEmailTest(
        selectedIntegrationID,
        selectedTestUUID,
        selectedPayloadTemplate,
        selectedEmailSubject
      )
    )
      .then((status) => {
        if (status == "ok") {
          dispatch(
            addDefaultSuccessAlert("Test email sent successfully", [
              "Check the inbox of the destination for the test email",
            ])
          );
        } else if (status == "INACTIVE_EMAIL") {
          dispatch(
            addDefaultWarningAlert("Email destination inactive", [
              "We've determined that the destination email address is inactive. Please check that it is set up to receive emails and try again",
            ])
          );
        }
      })
      .catch((e) => {
        if (e.response.status === 422) {
          dispatch(addDefaultWarningAlert(e.json.error, [], 0));
        } else {
          dispatch(addDefaultUnknownErrorAlert("Error sending test email"));
        }
      })
      .finally(() => setTestingEmail(false));
  };

  let messageVariables = GetExampleMessageVariables(
    notificationsByUUID,
    selectedTestUUID
  );

  const arrayVariables = messageVariables.filter((v) => {
    return v.example === "array";
  });
  messageVariables = messageVariables.filter((v) => {
    return v.example !== "array";
  });
  messageVariables.push(...arrayVariables);

  const { valid } = validateEmailTarget();

  let { message } = getEmailExampleMessage();
  message = message ? message.replace(/(^[\s\u200b]$|[\s\u200b]$)/, "") : "";

  return (
    <div className={"section-step"}>
      <div className={"section-step nobackground"}>
        <div id={"payload"}>
          <div className={"title-bar"}>
            <div className={"header"}>
              Review email subject and content by trigger type
            </div>
          </div>
          <div className={"body"}>
            {(!selectedUUIDs || Object.keys(selectedUUIDs).length == 0) && (
              <div className={"no-triggers"}>
                <span>
                  No triggers have been selected. Please return to the
                  &aposTriggers&apos section and select at least one
                  notification trigger for this integration.
                </span>
              </div>
            )}
            {selectedUUIDs && Object.keys(selectedUUIDs).length > 0 && (
              <>
                <SelectableSidebarTriggersList
                  notifications={notifications}
                  selectedUUIDs={selectedUUIDs}
                  selectedTestUUID={selectedTestUUID}
                  setSelectedTestNotification={setSelectedTestNotification}
                />
                <div className={"payload-content no-left-padding"}>
                  <div className={"section-title section-left-padding"}>
                    <div className="main-title">Setup this notification</div>
                  </div>
                  <div className={"section-title noline noline-top"}>
                    <div className="title left-padding">Email subject</div>
                  </div>
                  <div className={"payload-line"}>
                    <TextField
                      value={selectedEmailSubject}
                      onChanged={setSelectedEmailSubject}
                      minLength={1}
                    />
                    <div className={"button-row"}>
                      <Button
                        tertiary
                        className={"reset-button"}
                        onClick={resetSelectedEmailSubjectToDefault}
                      >
                        <div className="cr-icon-redo" />
                        {"Reset subject line to default"}
                      </Button>
                    </div>
                  </div>
                  <div className={"section-title noline"}>
                    <div className="title  left-padding">Message preview</div>
                  </div>
                  <div className={"payload-line"}>
                    <EmailPreview
                      useCustomLogo={useOrgLogo}
                      message={message}
                      createdByEmail={currentUserEmail}
                      customLogoUrl={orgLogoUrl}
                    />
                  </div>
                  <div className={"section-title noline"}>
                    <div className="title  left-padding">Edit message</div>
                  </div>
                  <div className={"payload-line"}>
                    <div className={"instruction-text"}>
                      <>
                        {"The template syntax is Liquid-format (see the "}
                        <a
                          href={`${liquidSyntaxGuideURL}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {"support article"}
                        </a>
                        {" for details)."}
                      </>
                    </div>
                    <div className={"messageedit"}>
                      <textarea
                        id={"payload-template"}
                        onChange={(e) =>
                          onChangePayloadTemplate(e.target.value)
                        }
                        value={selectedPayloadTemplate}
                      />
                    </div>
                    <div className={"button-row"}>
                      <Button
                        tertiary
                        className={"reset-button"}
                        onClick={() => resetPayloadTemplateToDefault()}
                      >
                        <div className="cr-icon-redo" />
                        {"Reset message template to default"}
                      </Button>
                      <Button
                        className={"send-button"}
                        onClick={testEmail}
                        loading={testingEmail}
                        disabled={testingEmail || !valid || !selectedTestUUID}
                      >
                        <div className="cr-icon-message" /> {"Send test email"}
                      </Button>
                    </div>
                  </div>
                  <MessageCustomizationHelp
                    selectedTestJSON={selectedTestJSON}
                    selectedTestUUID={selectedTestUUID}
                    notificationsByUUID={notificationsByUUID}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfigureEmailPayloadsStep;
