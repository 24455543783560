import { lazy } from "react";

import reload from "./reload";

// Wrapper around React.lazy to handle errors fetching chunks.
// This can happen when we deploy the app when a user has already loaded the page,
// then fetches a new chunk that no longer exists.
// To handle this case, we'll simply reload the page.

const lazyLoad = (importFn) =>
  lazy(() => {
    return new Promise((resolve, reject) => {
      importFn()
        .then(resolve)
        .catch((e) => {
          console.error("error fetching chunk", e);
          reload();
          reject(e);
        });
    });
  });

export default lazyLoad;
