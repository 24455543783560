import { Component } from "react";
import PropTypes from "prop-types";
import { ErrorCardWithAction } from "./EmptyCardWithAction";
import ReportCard from "./ReportCard";

class ErrorBoundary extends Component {
  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  static propTypes = {
    pathname: PropTypes.string.isRequired,
    children: PropTypes.any.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    // Clear error state if the nav route changes
    if (prevState.hasError && prevProps.pathname !== this.props.pathname) {
      this.setState({ hasError: false });
    }
  }

  render() {
    if (this.state.hasError) {
      return (
        <ReportCard newStyles>
          <ErrorCardWithAction
            errorText={`An error occurred while loading this page`}
            errorSubText={
              "Please try reloading the page and if the issue persists contact support."
            }
          />
        </ReportCard>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
