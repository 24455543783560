import { FC, useState } from "react";
import { getVendorWords } from "../../../_common/constants";
import { SlidePanelSection } from "./SlidePanelSection";
import RangeSlider from "./RangeSlider";
import { AssuranceType } from "../../../_common/types/organisations";

const MIN_VALUE = 0;
const MAX_VALUE = 950;

interface IScoreFilterProps {
  minScore?: number;
  maxScore?: number;
  assuranceType: AssuranceType;
  isSubsidiaries?: boolean;
  onChange: (obj: { minScore?: number; maxScore?: number }) => void;
  title?: string;
  startExpanded?: boolean;
}

const ScoreFilter: FC<IScoreFilterProps> = ({
  minScore,
  maxScore,
  assuranceType,
  isSubsidiaries = false,
  onChange,
  title,
  startExpanded = false,
}) => {
  const [expanded, setExpanded] = useState(
    startExpanded || !!minScore || !!maxScore
  );
  const vendorWords = getVendorWords(assuranceType);

  return (
    <SlidePanelSection
      title={title ?? `Filter by ${vendorWords.singular} score`}
      expanded={expanded}
      toggleExpand={() => setExpanded(!expanded)}
    >
      <p className="explanation-text">
        Drag the handles below to set a range of scores. Only{" "}
        {isSubsidiaries ? "subsidiaries" : vendorWords.plural} with a score
        within the specified range will be included.
      </p>
      <RangeSlider
        lowerVal={minScore}
        upperVal={maxScore}
        onChange={({
          lowerVal,
          upperVal,
        }: {
          lowerVal?: number;
          upperVal?: number;
        }) => onChange({ minScore: lowerVal, maxScore: upperVal })}
        min={MIN_VALUE}
        max={MAX_VALUE}
      />
    </SlidePanelSection>
  );
};

export default ScoreFilter;
