import { BaseModalProps } from "../../../_common/components/ModalV2";
import { FC, useLayoutEffect, useMemo, useRef, useState } from "react";
import GenericAddRiskModal, {
  GenericRiskEditParams,
  GenericRiskSubmitParams,
} from "./GenericAddRiskModal";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../_common/types/reduxHooks";
import {
  getNodeTreeFlattenedMap,
  NodeSummaryAndNode,
} from "../../../survey_viewer/surveyViewer.helpers";
import {
  getClassForNodeType,
  getContentComponentTypeForNodeType,
} from "../../../survey_viewer/components/nodes/QuestionAnswerNodeWrapper";
import ManualRisksAPI from "../../reducers/manualRisksAPI";
import {
  addNewRiskNodeToTree,
  editManualRiskNode,
} from "../../../survey_viewer/reducers/actions";
import {
  addDefaultSuccessAlert,
  addDefaultUnknownErrorAlert,
} from "../../../_common/reducers/messageAlerts.actions";
import classNames from "classnames";
import "../../style/components/SurveyAddRiskModal.scss";
import SurveyViewerItemIcon from "../../../survey_viewer/components/SurveyViewerItemIcon";
import NodeHeading from "../../../survey_viewer/components/nodes/NodeHeading";
import { NodeType } from "../../../survey_builder/types/types";
import { useManagedOrgID } from "../../../_common/hooks";

interface ExpandableQuestionNodeProps {
  node?: NodeSummaryAndNode;
}

const MaxUnexpanded = 160; // the max height of the unexpanded container

const ExpandableQuestionNode: FC<ExpandableQuestionNodeProps> = ({ node }) => {
  // we start with the node expanded so we can measure it
  const [expanded, setExpanded] = useState(false);
  const [showExpand, setShowExpand] = useState(false);

  // we need to calculate the height of the question node unexpanded to see if we even need to even show the expander
  const innerRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (innerRef.current !== null) {
      setTimeout(() => {
        if (
          (innerRef.current?.getBoundingClientRect().height ?? 0) >
          MaxUnexpanded
        ) {
          setShowExpand(true);
        }
      });
    }
  }, [innerRef.current?.getBoundingClientRect().height]);

  const ContentDisplayTypeComponent = getContentComponentTypeForNodeType(
    node?.node.type ?? NodeType.Risk
  );

  const answer = useAppSelector(
    (state) => state.surveyViewer.answers[node?.nodeId ?? ""]
  );

  if (!node) {
    return <></>;
  }

  return (
    <div className={"expandable-question-node"}>
      <div className={classNames("question-inner", { expanded })}>
        <div
          ref={innerRef}
          className={`question-answer-node ${getClassForNodeType(
            node.node.type
          )}`}
        >
          <div className={"question-container"}>
            <SurveyViewerItemIcon nodeSummary={node} />
            <div className={"heading-and-content"}>
              <NodeHeading node={node.node} isDiffMode={false} />
              <ContentDisplayTypeComponent
                node={node.node}
                answer={answer}
                disabled
                isPublicSurvey={false}
                onAnswerChanged={() => {
                  // no op
                }}
                otherAnswer={undefined}
                nodeHiddenFromRecipient={false}
              />
            </div>
          </div>
        </div>
      </div>
      {showExpand && (
        <div className={"expander"} onClick={() => setExpanded((val) => !val)}>
          {expanded ? "Collapse" : "Expand"}{" "}
          <i
            className={`cr-icon-chevron ${
              expanded ? "rotate-270" : "rotate-90"
            }`}
          />
        </div>
      )}
    </div>
  );
};

interface SurveyAddRiskModalProps extends BaseModalProps {
  parentID: string;
  surveyID: number;
  editRiskID?: number;
  editNodeID?: string;
  existingRiskIDs: number[];
  onRiskAdded: (id: number) => void;
}

const SurveyAddRiskModal: FC<SurveyAddRiskModalProps> = (props) => {
  const dispatch = useAppDispatch();

  // find the node we are looking at to show in the header
  const nodeTree = useAppSelector((state) => state.surveyViewer.nodeTree);
  const nodesFlattened = nodeTree
    ? getNodeTreeFlattenedMap(nodeTree)
    : undefined;
  const node = nodesFlattened?.[props.parentID];

  const { data: _availableRisks } = ManualRisksAPI.useGetAllManualRisksQuery();
  const availableRisks = useMemo(() => {
    return _availableRisks?.filter(
      (r) => !!props.editRiskID || !props.existingRiskIDs.includes(r.id)
    );
  }, [props.existingRiskIDs, props.editRiskID, _availableRisks]);

  const [addRisk] = ManualRisksAPI.useAddManualRiskToSurveyMutation();

  const managedOrgId = useManagedOrgID();
  const showCategories = !!managedOrgId;

  const onSubmit = (params: GenericRiskSubmitParams) => {
    return addRisk({
      surveyID: props.surveyID,
      parentNodeID: props.parentID,
      riskID: params.existingRisk,
      newFindingImpact: params.newFindingImpact,
      newFindingName: params.newFindingName,
      newFindingSeverity: params.newFindingSeverity,
      newFindingClassification: showCategories
        ? params.newFindingClassification
        : undefined,
    })
      .unwrap()
      .then(({ newRiskNode }) => {
        props.onRiskAdded(newRiskNode.customRiskId ?? 0);
        dispatch(addNewRiskNodeToTree(newRiskNode));
      })
      .then(() =>
        dispatch(addDefaultSuccessAlert("Risk added to questionnaire"))
      )
      .catch((e) => {
        console.error(e);
        dispatch(
          addDefaultUnknownErrorAlert("Error adding risk to questionnaire")
        );
      });
  };

  const [editRisk] = ManualRisksAPI.useEditManualRiskMutation();
  const onEdit = (params: GenericRiskEditParams) => {
    return editRisk({
      riskID: params.existingRisk,
      findingImpact: params.newFindingImpact,
      findingName: params.newFindingName,
      findingSeverity: params.newFindingSeverity,
      findingClassification: showCategories
        ? params.newFindingClassification
        : undefined,
    })
      .unwrap()
      .then(({ newRiskId }) =>
        dispatch(
          editManualRiskNode(
            props.editNodeID ?? "",
            newRiskId,
            params.newFindingName,
            params.newFindingImpact,
            params.newFindingSeverity
          )
        )
      )
      .then(() => dispatch(addDefaultSuccessAlert("Risk edited")))
      .catch(() => dispatch(addDefaultUnknownErrorAlert("Error editing risk")));
  };

  return (
    <GenericAddRiskModal
      availableRisks={availableRisks ?? []}
      allExistingRiskNames={
        _availableRisks ? _availableRisks.map((r) => r.name) : []
      }
      onSubmit={onSubmit}
      onEdit={onEdit}
      active={props.active}
      onClose={props.onClose}
      showManualRiskWarning
      editRiskId={props.editRiskID}
      showClassifications={showCategories}
      headerNode={<ExpandableQuestionNode node={node} />}
    />
  );
};

export default SurveyAddRiskModal;
