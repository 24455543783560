import { DefaultThunkDispatchProp } from "../../../_common/types/redux";
import { ILabel, LabelClassification } from "../../../_common/types/label";
import { useEffect, useState } from "react";
import Modal from "../../../_common/components/ModalV2";
import Button from "../../../_common/components/core/Button";
import SearchBox from "../../../_common/components/SearchBox";

import { addNewLabel } from "../../reducers/cyberRiskActions";
import { addDefaultUnknownErrorAlert } from "../../../_common/reducers/messageAlerts.actions";
import PivotTabs, { PivotTab } from "../../../_common/components/PivotTabs";
import {
  IWithPermissionsProps,
  withPermissions,
} from "../../../_common/permissions";
import "../../style/components/MonitorNewVendorModal.scss";
import { SelectV2 } from "../../../_common/components/SelectV2";
import {
  fetchVendorTiers,
  VendorTier,
} from "../../reducers/vendorTiers.actions";
import VendorTierBadge from "../vendor_tiers/VendorTierBadge";
import LabelChoiceSet, { sortLabels } from "../labels/LabelChoiceSet";
import { getVendorWords, IVendorWords } from "../../../_common/constants";
import { AssuranceType } from "../../../_common/types/organisations";
import { appConnect } from "../../../_common/types/reduxHooks";

interface MonitorNewVendorModalConnectedProps {
  availableLabels: ILabel[];
  assuranceType: AssuranceType;
  tiers: VendorTier[];
}

interface MonitorNewVendorModalOwnProps {
  isShowing: boolean;
  vendorName: string;
  onMonitor: (addedLabelIds: number[], tier?: number) => Promise<void>;
  onClose: () => void;
}

type MonitorNewVendorModalProps = MonitorNewVendorModalOwnProps &
  MonitorNewVendorModalConnectedProps &
  DefaultThunkDispatchProp &
  IWithPermissionsProps;

const MonitorNewVendorModal = (props: MonitorNewVendorModalProps) => {
  const [isSaving, setIsSaving] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [addedLabelIds, setAddedLabelIds] = useState<number[]>([]);
  const [selectedTier, setSelectedTier] = useState<number | undefined>(
    undefined
  );

  const vendorWords = getVendorWords(props.assuranceType);

  // Clean up when hiding
  useEffect(() => {
    if (!props.isShowing) {
      setAddedLabelIds([]);
      setSelectedTier(undefined);
    }
  }, [props.isShowing]);

  if (props.tiers.length === 0) {
    props.dispatch(fetchVendorTiers());
  }

  const save = () => {
    setIsSaving(true);

    props.onMonitor(addedLabelIds, selectedTier).catch(() => {
      props.dispatch(
        addDefaultUnknownErrorAlert(`Error monitoring ${vendorWords.singular}`)
      );
      setIsSaving(false);
    });
  };

  const addLabel = (val: string) => {
    setIsAdding(true);

    return props
      .dispatch(addNewLabel(val, LabelClassification.VendorLabel))
      .then((label: ILabel) => {
        setIsAdding(false);
        setAddedLabelIds([...addedLabelIds, label.id]);
      })
      .catch(() => {
        setIsAdding(false);
        props.dispatch(addDefaultUnknownErrorAlert("Error adding new label"));
      });
  };

  const labelSelected = (id: number, isSelected: boolean) => {
    if (isSelected) {
      setAddedLabelIds([...addedLabelIds, id]);
    } else {
      setAddedLabelIds(addedLabelIds.filter((a) => a !== id));
    }
  };

  return (
    <Modal
      verticalCenter
      headerContent={`Start monitoring ${props.vendorName}`}
      headerClassName={"monitor-new-vendor-modal-header"}
      subHeaderContent={`Select the relationships and add labels that apply to this ${vendorWords.singular}`}
      className={"monitor-new-vendor-modal"}
      active={props.isShowing}
      onClose={() => props.onClose()}
      footerContent={
        <div className={"btn-group"}>
          <Button
            tertiary
            onClick={() => props.onClose()}
            disabled={isSaving || isAdding}
          >
            Back
          </Button>
          <Button
            filledPrimary
            onClick={save}
            loading={isSaving}
            disabled={isAdding}
          >
            {`Monitor ${vendorWords.singular}`}
          </Button>
        </div>
      }
    >
      <LabelSelection
        availableLabels={props.availableLabels}
        tierSelectionContent={
          <VendorTierSelection
            vendorWords={vendorWords}
            tiers={props.tiers}
            selectedTier={selectedTier}
            onTierSelected={(tier) => setSelectedTier(tier)}
          />
        }
        selectedLabels={addedLabelIds}
        onAddLabel={addLabel}
        onLabelSelected={labelSelected}
        disableAddLabel={isSaving}
        isAdding={isAdding}
      />
    </Modal>
  );
};

interface LabelSelectionProps {
  availableLabels: ILabel[];
  tierSelectionContent?: JSX.Element;
  selectedLabels: number[];
  onAddLabel: (val: string) => Promise<void>;
  onLabelSelected: (id: number, isSelected: boolean) => void;
  disableAddLabel: boolean;
  isAdding: boolean;
}

export const LabelSelection = (props: LabelSelectionProps) => {
  const [searchValue, setSearchValue] = useState("");

  // Update for label management changes and search changes
  useEffect(() => {
    setFilteredLabels(
      props.availableLabels.filter(
        (l) =>
          l.name
            .toLocaleLowerCase()
            .indexOf(searchValue.toLocaleLowerCase()) !== -1
      )
    );
  }, [searchValue, props.availableLabels]);

  const [filteredLabels, setFilteredLabels] = useState<ILabel[]>([
    ...props.availableLabels,
  ]);

  const systemLabels = filteredLabels.filter(
    (l) => l.classification === LabelClassification.SystemLabel
  );

  const vendorLabels = filteredLabels.filter(
    (l) => l.classification !== LabelClassification.SystemLabel
  );

  const isNoExactLabelMatch =
    searchValue.length > 0 &&
    filteredLabels.find(
      (l) => l.name.toLocaleLowerCase() === searchValue.toLocaleLowerCase()
    ) === undefined;

  return (
    <div className={"label-selection"}>
      <PivotTabs>
        <PivotTab headerText={"Relationships"} id={"relationships"}>
          <LabelChoiceSet
            labels={systemLabels}
            selectedLabels={props.selectedLabels}
            onLabelSelected={props.onLabelSelected}
          />
          {props.tierSelectionContent}
        </PivotTab>
        <PivotTab headerText={"Custom labels"} id={"custom-labels"}>
          <>
            <SearchBox
              onChanged={(val) => setSearchValue(val)}
              value={searchValue}
              placeholder={"Type to search or add a custom label"}
              maxLength={100}
            />
            {isNoExactLabelMatch && (
              <Button
                tertiary
                disabled={props.disableAddLabel}
                loading={props.isAdding}
                onClick={() => {
                  props.onAddLabel(searchValue).then(() => {
                    setSearchValue("");
                  });
                }}
                className={"add-label-btn"}
              >
                + Create new label {`'${searchValue}'`}
              </Button>
            )}
            <LabelChoiceSet
              labels={vendorLabels}
              selectedLabels={props.selectedLabels}
              onLabelSelected={props.onLabelSelected}
            />
          </>
        </PivotTab>
      </PivotTabs>
    </div>
  );
};

interface VendorTierSelectionProps {
  vendorWords: IVendorWords;
  tiers: VendorTier[];
  selectedTier?: number;
  onTierSelected: (tier: number) => void;
}

export const VendorTierSelection = (props: VendorTierSelectionProps) => {
  const selectOptions = [
    ...props.tiers
      .filter((t) => t.isActive)
      .map((t) => {
        return {
          label: t.name,
          value: t.tier,
        };
      }),
  ];

  const selectedTierOption = selectOptions.find(
    (o) => o.value === props.selectedTier
  );

  return (
    <div className={"vendor-tier-selection"}>
      <label>Select {props.vendorWords.singular} tier</label>
      <SelectV2
        className="vendor-tier-selector"
        placeholder={`Select a tier for this ${props.vendorWords.singular}`}
        options={selectOptions}
        value={selectedTierOption}
        isLoading={props.tiers.length === 0}
        onChange={(op) => {
          if (op) {
            props.onTierSelected(op.value as number);
          }
        }}
        isSearchable={false}
        formatOptionLabel={(opt) => {
          return (
            <VendorTierBadge
              tier={opt.value as number}
              name={opt.label}
              showName
            />
          );
        }}
      />
    </div>
  );
};

export default appConnect<
  MonitorNewVendorModalConnectedProps,
  never,
  MonitorNewVendorModalOwnProps
>((state) => {
  const systemLabels = state.cyberRisk.availableLabels
    ? state.cyberRisk.availableLabels.filter(
        (l) => l.classification === LabelClassification.SystemLabel
      )
    : [];

  const classificationCustomLabels = state.cyberRisk.availableLabels
    ? state.cyberRisk.availableLabels.filter(
        (l) => l.classification === LabelClassification.VendorLabel
      )
    : [];

  const allLabels = sortLabels([
    ...systemLabels,
    ...classificationCustomLabels,
  ]);

  return {
    availableLabels: allLabels,
    assuranceType: state.common.userData.assuranceType,
    tiers: state.cyberRisk.vendorTiers ?? [],
  };
})(withPermissions(MonitorNewVendorModal));
