import { AppStatus } from "./types";

// The base type that all events inherit from
interface BaseUserEvent {
  at: string;

  triggeringName?: string;
  triggeringEmailAddress?: string;
}

// All of the event types. Should mimic what's in userbase/events_user.go
export enum UserEventType {
  UserFirstDetected = "first_detected",
  UserAppStatusChange = "app_status_changed",
  UserRiskWaiverRequested = "risk_waiver_requested",
  UserRiskWaiverApproved = "risk_waiver_approved",
  UserNewApp = "new_app",
}

interface UserFirstDetectedMetadata {
  createdAt: string;
}

export interface UserFirstDetectedEvent extends BaseUserEvent {
  eventType: UserEventType.UserFirstDetected;
  metadata: UserFirstDetectedMetadata;
}

interface UserAppStatusChangeMetadata {
  appName: string;
  status: AppStatus;
}

export interface UserAppStatusChangeEvent extends BaseUserEvent {
  eventType: UserEventType.UserAppStatusChange;
  metadata: UserAppStatusChangeMetadata;
}

interface UserRiskWaiverRequestedMetadata {
  riskId: string;
  appName: string;
  requesterReason?: string;
  expiresAt?: string;
}

export interface UserRiskWaiverRequestedEvent extends BaseUserEvent {
  eventType: UserEventType.UserRiskWaiverRequested;
  metadata: UserRiskWaiverRequestedMetadata;
}

interface UserRiskWaiverApprovedMetadata {
  riskId: string;
  appName: string;
  approverReason?: string;
  expiresAt?: string;
}

export interface UserRiskWaiverApprovedEvent extends BaseUserEvent {
  eventType: UserEventType.UserRiskWaiverApproved;
  metadata: UserRiskWaiverApprovedMetadata;
}

interface UserNewAppMetadata {
  appName: string;
}

export interface UserNewAppEvent extends BaseUserEvent {
  eventType: UserEventType.UserNewApp;
  metadata: UserNewAppMetadata;
}

export type UserEvent =
  | UserFirstDetectedEvent
  | UserAppStatusChangeEvent
  | UserRiskWaiverRequestedEvent
  | UserRiskWaiverApprovedEvent
  | UserNewAppEvent;
