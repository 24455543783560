import { DefaultThunkDispatch } from "../../types/redux";
import { FC, useState } from "react";
import ModalV2, { BaseModalProps } from "../ModalV2";
import moment from "moment/moment";
import ColorCheckbox from "../../../vendorrisk/components/ColorCheckbox";
import DatePicker from "../DatePicker";

import "../../../vendorrisk/style/components/ModifyResendDateModal.scss";
import Button from "../core/Button";
import { updateVendorSurveyResendSchedule } from "../../../vendorrisk/reducers/cyberRiskActions";
import { fetchSurveyDetails } from "../../reducers/surveyDetails.actions";
import {
  addDefaultSuccessAlert,
  addDefaultUnknownErrorAlert,
} from "../../reducers/messageAlerts.actions";

export interface IModifyResendDateModalProps extends BaseModalProps {
  dispatch: DefaultThunkDispatch;
  surveyId: number;
  vendorId: number;
  resendDate?: string;
}

const ModifyResendDateModal: FC<IModifyResendDateModalProps> = ({
  dispatch,
  surveyId,
  vendorId,
  resendDate: originalResendDate,
  onClose,
  active,
}) => {
  const [resendEnabled, setResendEnabled] = useState(!!originalResendDate);
  const [resendDate, setResendDate] = useState(
    originalResendDate
      ? moment(originalResendDate).format("YYYY-MM-DD")
      : moment().add(1, "year").format("YYYY-MM-DD")
  );
  const [loading, setLoading] = useState(false);

  const submit = async () => {
    setLoading(true);

    try {
      await dispatch(
        updateVendorSurveyResendSchedule(
          surveyId,
          vendorId,
          resendEnabled,
          resendDate,
          12
        )
      );
      await dispatch(fetchSurveyDetails(surveyId, true));
      dispatch(addDefaultSuccessAlert("Resend reminder date updated"));
      onClose();
    } catch (e) {
      console.error(e);
      setLoading(false);
      dispatch(addDefaultUnknownErrorAlert("Error updating resend date"));
    }
  };

  return (
    <ModalV2
      active={active}
      onClose={onClose}
      className="modify-resend-date-modal"
      headerContent="Modify resend reminder"
      footerContent={
        <div className="btn-group">
          <Button tertiary onClick={onClose} disabled={loading}>
            Cancel
          </Button>
          <Button loading={loading} onClick={submit}>
            Update
          </Button>
        </div>
      }
    >
      <div>
        <p>
          To maintain up-to-date information about your vendors, you should
          resend security questionnaires at least once a year. If you set a
          reminder you will be alerted as this date approaches, at which point
          you can resend the questionnaire.
        </p>
        <ColorCheckbox
          label="Please remind me to resend this questionnaire on:"
          checked={resendEnabled}
          onClick={() => setResendEnabled(!resendEnabled)}
        />
        <DatePicker
          value={resendDate}
          onChange={(e) => setResendDate(e.target.value)}
          min={moment().add(1, "day").format("YYYY-MM-DD")}
          disabled={!resendEnabled}
        />
      </div>
    </ModalV2>
  );
};

export default ModifyResendDateModal;
