import { FC } from "react";
import { NumberWithCommas, pluralise } from "../../../_common/helpers";
import "./DisplayingUsers.scss";

interface DisplayingUsersProps {
  count: number;
}

const DisplayingUsers: FC<DisplayingUsersProps> = ({ count }) => {
  return (
    <div className={"displaying-users"}>
      <div className={`cr-icon-users-empty`} />
      {`Displaying ${NumberWithCommas(count)} ${pluralise(
        count,
        "user",
        "users"
      )}`}
    </div>
  );
};

export default DisplayingUsers;
