import { FC, useEffect } from "react";
import ModalV2, { BaseModalProps } from "../../../_common/components/ModalV2";
import Button from "../../../_common/components/core/Button";
import "../../style/components/modals/SurveyViewerToolsModal.scss";
import { trackEvent } from "../../../_common/tracking";

interface ToolsSectionProps {
  icon: React.ReactNode;
  headerText: string;
  helpUrl: string;
  text: string;
  buttons?: React.ReactNode;
}

const ToolsSection: FC<ToolsSectionProps> = ({
  buttons,
  headerText,
  helpUrl,
  icon,
  text,
}) => (
  <div className={"tools-section"}>
    <div className={"left-side"}>{icon}</div>
    <div className={"right-side"}>
      <div className={"header"}>
        <span className={"header-text"}>{headerText}</span>
        <Button link arrow onClick={() => window.open(helpUrl, "_blank")}>
          Learn more
        </Button>
      </div>
      <div className={"main-text"}>{text}</div>
      <div className={"buttons"}>{buttons}</div>
    </div>
  </div>
);

interface SurveyViewerToolsModalProps extends BaseModalProps {
  shouldDisplayChatGPT: boolean;
  onClickAutofill: () => void;
  onClickExport: () => void;
  onClickImport: () => void;
  isImportedSurvey?: boolean;
}

const SurveyViewerToolsModal: FC<SurveyViewerToolsModalProps> = ({
  shouldDisplayChatGPT,
  onClickAutofill,
  onClickExport,
  onClickImport,
  active,
  onClose,
  isImportedSurvey = false,
}) => {
  const closeAnd = (f: () => void) => () => {
    onClose();
    f();
  };

  useEffect(() => {
    if (active) {
      trackEvent("SurveyViewer/toolsModalOpened");
    }
  }, [active]);

  return (
    <ModalV2
      active={active}
      onClose={onClose}
      headerContent={"Questionnaire answering tools"}
      className={"survey-tools-modal"}
    >
      {shouldDisplayChatGPT && (
        <>
          <ToolsSection
            icon={<i className={"cr-icon-stars"} />}
            headerText={"Get AI-generated answers"}
            helpUrl={
              "https://help.upguard.com/en/articles/8359996-how-to-use-ai-autofill-for-questionnaires"
            }
            text={`Got a SOC 2 report, information security policy or past questionnaire? Use those documents to answer this questionnaire automatically. You can review suggested answers before submitting.`}
            buttons={
              <>
                <Button onClick={closeAnd(onClickAutofill)}>
                  Run autofill
                </Button>
              </>
            }
          />
          <ToolsSection
            icon={<i className={"cr-icon-magic-wand"} />}
            headerText={"AI Enhance written responses"}
            helpUrl={
              "https://help.upguard.com/en/articles/7862922-how-to-use-ai-enhance-to-improve-your-questionnaire-responses"
            }
            text={
              "Turn short bullet points or rough draft notes into complete responses with the click of a button. Try it out to correct grammatical mistakes and improve responses instantly."
            }
          />
        </>
      )}
      {!isImportedSurvey && (
        <ToolsSection
          icon={<i className={"cr-icon-export-thin"} />}
          headerText={"Export and answer this questionnaire offline"}
          helpUrl={
            "https://help.upguard.com/en/articles/7002550-how-to-answer-a-questionnaire-offline"
          }
          text={
            "Export this questionnaire as an Excel file and complete it offline, then import the completed version to return it to the sender."
          }
          buttons={
            <>
              <Button onClick={closeAnd(onClickExport)}>
                Export questionnaire
              </Button>
              <Button onClick={closeAnd(onClickImport)}>
                Import questionnaire
              </Button>
            </>
          }
        />
      )}
    </ModalV2>
  );
};

export default SurveyViewerToolsModal;
