import FileTypeIconPDF from "../../_common/images/file-type-icon-pdf.svg";
import FileTypeIconWord from "../../_common/images/file-type-icon-word.svg";
import FileTypeIconExcel from "../../_common/images/file-type-icon-excel.svg";
import FileTypeIconJPG from "../../_common/images/file-type-icon-image.svg";
import FileTypeIconJPEG from "../../_common/images/file-type-icon-image.svg";
import FileTypeIconPNG from "../../_common/images/file-type-icon-image.svg";
import FileTypeIconPowerPoint from "../../_common/images/file-type-icon-pptx.svg";

const fileExtensionIcons: Record<string, any> = {
  pdf: FileTypeIconPDF,
  docx: FileTypeIconWord,
  doc: FileTypeIconWord,
  csv: FileTypeIconExcel,
  xls: FileTypeIconExcel,
  xlsx: FileTypeIconExcel,
  jpg: FileTypeIconJPG,
  jpeg: FileTypeIconJPEG,
  png: FileTypeIconPNG,
  pptx: FileTypeIconPowerPoint,
  txt: FileTypeIconWord,
};

export const GetIconForFilename = (
  filename: string,
  fallback = FileTypeIconPDF
) => {
  const fileExtension = filename.split(".").pop() || "";

  if (fileExtensionIcons[fileExtension]) {
    return fileExtensionIcons[fileExtension];
  }

  return fallback;
};
