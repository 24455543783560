import { DefaultThunkDispatch } from "../../_common/types/redux";
import { FetchCyberRiskUrl } from "../../_common/api";
import {
  fetchVendorSummaryAndCloudscans,
  setCustomerData,
  setVendorData,
} from "./cyberRiskActions";
import {
  DomainScore,
  IPAddress,
  IPAddressDetails,
  IPRange,
  ipRangeKey,
} from "../../_common/types/ipAddresses";
import {
  conditionalRefreshActivityStreamForOrgUser,
  grabTPVMSession,
} from "../../_common/reducers/commonActions";
import { addDefaultUnknownErrorAlert } from "../../_common/reducers/messageAlerts.actions";
import { DefaultRootState } from "react-redux";
import { get as _get } from "lodash";
import { fetchBreachsightSubsidiaries } from "./breachsightSubsidiaries.actions";
import { fetchCustomerSummaryAndCloudscans } from "./customer.actions";

export const SET_VENDOR_IP_DETAILS = "SET_VENDOR_IP_DETAILS";
export const setVendorIPDetails = (
  vendorId: number,
  isSubsidiary: boolean,
  ipDetails: IPAddressDetails
) => {
  return {
    type: SET_VENDOR_IP_DETAILS,
    vendorId,
    isSubsidiary,
    ipDetails,
  };
};

export const SET_CUSTOMER_IP_DETAILS = "SET_CUSTOMER_IP_DETAILS";
export const setCustomerIPDetails = (ipDetails: IPAddressDetails) => {
  return {
    type: SET_CUSTOMER_IP_DETAILS,
    ipDetails,
  };
};

export const SET_VENDOR_IP_RANGE_DOMAINS = "SET_VENDOR_IP_RANGE_DOMAINS";
export const setVendorIPRangeDomains = (
  vendorId: number,
  isSubsidiary: boolean,
  rangeKey: string,
  domains: DomainScore[]
) => {
  return {
    type: SET_VENDOR_IP_RANGE_DOMAINS,
    vendorId,
    isSubsidiary,
    rangeKey,
    domains,
  };
};

export const SET_CUSTOMER_IP_RANGE_DOMAINS = "SET_CUSTOMER_IP_RANGE_DOMAINS";
export const setCustomerIPRangeDomains = (
  rangeKey: string,
  domains: DomainScore[]
) => {
  return {
    type: SET_CUSTOMER_IP_RANGE_DOMAINS,
    rangeKey,
    domains,
  };
};

export interface ipAddressesState {
  loading?: boolean;
  error?: any;
  ipAddresses?: IPAddress[];
  ipRanges?: IPRange[];
}

interface fetchIPAddressesParams {
  vendor_id?: number;
  is_customer?: boolean;
  is_subsidiary?: boolean;
}

interface fetchIPAddressesResponse {
  status: string;
  result: {
    ipAddresses?: IPAddress[];
    ipRanges?: IPRange[];
  };
}

export const fetchVendorIPAddresses = (
  vendorId: number,
  isSubsidiary = false
) => {
  return async (
    dispatch: DefaultThunkDispatch,
    getState: any
  ): Promise<void> => {
    const currentState: ipAddressesState = isSubsidiary
      ? getState().cyberRisk.subsidiaries[vendorId]?.ipAddresses
      : getState().cyberRisk.vendors[vendorId]?.ipAddresses;

    if (currentState) {
      // We have cached data for this vendor, don't fetch again
      return;
    }

    const tpvmSession = grabTPVMSession(getState);

    dispatch(
      setVendorData(vendorId, { ipAddresses: { loading: true } }, isSubsidiary)
    );

    const params: fetchIPAddressesParams = {
      vendor_id: vendorId,
      is_customer: false,
      is_subsidiary: isSubsidiary,
    };

    let json: fetchIPAddressesResponse;

    try {
      json = await FetchCyberRiskUrl(
        "vendor/ip_addresses/v1/",
        params,
        null,
        dispatch,
        getState
      );
    } catch (e) {
      dispatch(addDefaultUnknownErrorAlert("Error fetching IP addresses"));
      console.error(e);
      throw e;
    }

    dispatch(
      setVendorData(
        vendorId,
        {
          ipAddresses: { ...json.result, loading: false },
        },
        isSubsidiary,
        tpvmSession as any
      )
    );

    return;
  };
};

export const fetchCustomerIPAddresses = () => {
  return async (
    dispatch: DefaultThunkDispatch,
    getState: any
  ): Promise<void> => {
    const currentState: ipAddressesState =
      getState().cyberRisk.customerData.ipAddresses;

    if (currentState) {
      // We have cached data for this customer, don't fetch again
      return;
    }

    dispatch(setCustomerData({ ipAddresses: { loading: true } }));

    const params: fetchIPAddressesParams = { is_customer: true };

    let json: fetchIPAddressesResponse;

    try {
      json = await FetchCyberRiskUrl(
        "vendor/ip_addresses/v1/",
        params,
        null,
        dispatch,
        getState
      );
    } catch (e) {
      dispatch(addDefaultUnknownErrorAlert("Error fetching IP addresses"));
      console.error(e);
      throw e;
    }

    dispatch(
      setCustomerData({
        ipAddresses: json.result,
      })
    );

    return;
  };
};

export const clearCustomerIPAddresses = () => {
  return async (dispatch: DefaultThunkDispatch): Promise<void> => {
    dispatch(setCustomerData({ ipAddresses: undefined }));
  };
};

export const clearVendorIPAddresses = (vendorId: number) => {
  return async (dispatch: DefaultThunkDispatch): Promise<void> => {
    // wipe both subsidiary/non-subsidiary for simplicity
    dispatch(setVendorData(vendorId, { ipAddresses: undefined }, true));
    dispatch(setVendorData(vendorId, { ipAddresses: undefined }, false));
  };
};

interface fetchIPAddressDetailsParams {
  ip: string;
  vendor_id?: number;
  is_customer?: boolean;
  is_subsidiary?: boolean;
}

interface fetchIPAddressDetailsResponse {
  status: string;
  result: IPAddressDetails;
}

export const fetchVendorIPAddressDetails = (
  ip: string,
  vendorId: number,
  isSubsidiary = false
) => {
  return async (
    dispatch: DefaultThunkDispatch,
    getState: any
  ): Promise<void> => {
    const params: fetchIPAddressDetailsParams = {
      ip: ip,
      vendor_id: vendorId,
      is_customer: false,
      is_subsidiary: isSubsidiary,
    };

    let json: fetchIPAddressDetailsResponse;

    try {
      json = await FetchCyberRiskUrl(
        "vendor/ip_address/v1/",
        params,
        null,
        dispatch,
        getState
      );
    } catch (e) {
      dispatch(addDefaultUnknownErrorAlert("Error fetching IP address"));
      console.error(e);
      throw e;
    }

    dispatch(setVendorIPDetails(vendorId, isSubsidiary, json.result));

    return;
  };
};

export const fetchCustomerIPAddressDetails = (ip: string) => {
  return async (
    dispatch: DefaultThunkDispatch,
    getState: any
  ): Promise<void> => {
    const params: fetchIPAddressDetailsParams = {
      ip: ip,
      is_customer: true,
    };

    let json: fetchIPAddressDetailsResponse;

    try {
      json = await FetchCyberRiskUrl(
        "vendor/ip_address/v1/",
        params,
        null,
        dispatch,
        getState
      );
    } catch (e) {
      dispatch(addDefaultUnknownErrorAlert("Error fetching IP address"));
      console.error(e);
      throw e;
    }

    dispatch(setCustomerIPDetails(json.result));

    return;
  };
};

interface fetchIPRangeDomainsParams {
  ip_range_id?: number;
  custom_ip_range_id?: number;
  vendor_id?: number;
  is_customer: boolean;
  is_subsidiary?: boolean;
}

interface fetchIPRangeDomainsResponse {
  status: string;
  result: { domains: DomainScore[] };
}

export const fetchVendorIPRangeDomains = (
  ipRange: IPRange,
  vendorId: number,
  isSubsidiary = false
) => {
  return async (
    dispatch: DefaultThunkDispatch,
    getState: any
  ): Promise<void> => {
    const params: fetchIPRangeDomainsParams = {
      ip_range_id: ipRange.id,
      custom_ip_range_id: ipRange.customID,
      vendor_id: vendorId,
      is_customer: false,
      is_subsidiary: isSubsidiary,
    };

    let json: fetchIPRangeDomainsResponse;

    try {
      json = await FetchCyberRiskUrl(
        "vendor/ip_range/domains/v1/",
        params,
        null,
        dispatch,
        getState
      );
    } catch (e) {
      dispatch(addDefaultUnknownErrorAlert("Error fetching IP range domains"));
      console.error(e);
      throw e;
    }

    dispatch(
      setVendorIPRangeDomains(
        vendorId,
        isSubsidiary,
        ipRangeKey(ipRange),
        json.result.domains || []
      )
    );

    return;
  };
};

export const fetchCustomerIPRangeDomains = (ipRange: IPRange) => {
  return async (
    dispatch: DefaultThunkDispatch,
    getState: any
  ): Promise<void> => {
    const params: fetchIPRangeDomainsParams = {
      ip_range_id: ipRange.id,
      custom_ip_range_id: ipRange.customID,
      is_customer: true,
    };

    let json: fetchIPRangeDomainsResponse;

    try {
      json = await FetchCyberRiskUrl(
        "vendor/ip_range/domains/v1/",
        params,
        null,
        dispatch,
        getState
      );
    } catch (e) {
      dispatch(addDefaultUnknownErrorAlert("Error fetching IP range domains"));
      console.error(e);
      throw e;
    }

    dispatch(
      setCustomerIPRangeDomains(ipRangeKey(ipRange), json.result.domains || [])
    );

    return;
  };
};

interface customIPRangeParams {
  start_ip: string;
  end_ip: string;
  vendor_id?: number;
}

export const addCustomIPRange = (
  startIP: string,
  endIP: string,
  vendorId?: number
) => {
  return async (
    dispatch: DefaultThunkDispatch,
    getState: any
  ): Promise<void> => {
    const params: customIPRangeParams = {
      start_ip: startIP,
      end_ip: endIP,
    };

    if (vendorId) {
      params.vendor_id = vendorId;
    }

    try {
      await FetchCyberRiskUrl(
        "customer/ip_range/add/v1/",
        params,
        { method: "POST" },
        dispatch,
        getState
      );
    } catch (e) {
      console.error(e);
      throw e;
    }

    if (vendorId) {
      dispatch(clearVendorIPAddresses(vendorId));
      dispatch(fetchVendorSummaryAndCloudscans(vendorId, true));
    } else {
      dispatch(clearCustomerIPAddresses());
      dispatch(fetchCustomerSummaryAndCloudscans(true));
      dispatch(fetchBreachsightSubsidiaries(true, true));
    }

    // kick off call to update the activity stream
    dispatch(conditionalRefreshActivityStreamForOrgUser());
  };
};

export const removeCustomIPRange = (
  startIP: string,
  endIP: string,
  vendorId?: number
) => {
  return async (
    dispatch: DefaultThunkDispatch,
    getState: any
  ): Promise<void> => {
    const params: customIPRangeParams = {
      start_ip: startIP,
      end_ip: endIP,
    };

    if (vendorId) {
      params.vendor_id = vendorId;
    }

    try {
      await FetchCyberRiskUrl(
        "customer/ip_range/remove/v1/",
        params,
        { method: "POST" },
        dispatch,
        getState
      );
    } catch (e) {
      console.error(e);
      throw e;
    }

    if (vendorId) {
      dispatch(clearVendorIPAddresses(vendorId));
      dispatch(fetchVendorSummaryAndCloudscans(vendorId, true));
    } else {
      dispatch(clearCustomerIPAddresses());
      dispatch(fetchCustomerSummaryAndCloudscans(true));
      dispatch(fetchBreachsightSubsidiaries(true, true));
    }

    // kick off call to update the activity stream
    dispatch(conditionalRefreshActivityStreamForOrgUser());
  };
};

export const editCustomIPRange = (
  customRangeID: number,
  startIP: string,
  endIP: string,
  vendorId?: number
) => {
  return async (
    dispatch: DefaultThunkDispatch,
    getState: any
  ): Promise<void> => {
    const params: any = {
      custom_range_id: customRangeID,
      start_ip: startIP,
      end_ip: endIP,
    };

    if (vendorId) {
      params.vendor_id = vendorId;
    }

    try {
      await FetchCyberRiskUrl(
        "customer/ip_range/edit/v1/",
        params,
        { method: "POST" },
        dispatch,
        getState
      );
    } catch (e) {
      console.error(e);
      throw e;
    }

    if (vendorId) {
      dispatch(clearVendorIPAddresses(vendorId));
      dispatch(fetchVendorSummaryAndCloudscans(vendorId, true));
    } else {
      dispatch(clearCustomerIPAddresses());
      dispatch(fetchCustomerSummaryAndCloudscans(true));
      dispatch(fetchBreachsightSubsidiaries(true, true));
    }

    // kick off call to update the activity stream
    dispatch(conditionalRefreshActivityStreamForOrgUser());
  };
};

export const getIPAddressDetailsFromState = (
  state: DefaultRootState,
  ip: string,
  vendorId: number,
  isSubsidiary = false,
  isVendorPortal = false
) => {
  let ipDetails;

  if (isVendorPortal) {
    ipDetails = state.vendorPortal.ipAddressDetails[ip];
  } else if (isSubsidiary && vendorId) {
    ipDetails = _get(
      state.cyberRisk.subsidiaries,
      `[${vendorId}].ipAddressDetails['${ip}']`
    );
  } else if (vendorId) {
    ipDetails = _get(
      state.cyberRisk.vendors,
      `[${vendorId}].ipAddressDetails['${ip}']`
    );
  } else {
    ipDetails = state.cyberRisk.customerData.ipAddressDetails[ip];
  }
  return ipDetails;
};
