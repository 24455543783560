import { HTMLProps, ReactNode } from "react";
import classnames from "classnames";

import "../style/components/InfoTable.scss";

interface IInfoTableRowProps {
  onClick?: () => void;
  rowClass?: string;
  label?: ReactNode;
  labelClass?: string;
  labelMiddleAlign?: boolean;
  valueMiddleAlign?: boolean;
  value: ReactNode;
  valueClass?: string;
  thirdCol?: ReactNode;
  thirdColClass?: string;
  hide?: boolean;
  singleCol?: boolean;
  additionalDetails?: ReactNode;
}

export const InfoTableRow = (props: IInfoTableRowProps) => {
  if (props.hide) {
    return <></>;
  }

  let truncateExtraColumns = false;

  if (!props.thirdCol && !props.onClick) {
    truncateExtraColumns = true;
  }

  if (props.singleCol) {
    return (
      <tr
        className={classnames("info-table-row", props.rowClass || "", {
          clickable: !!props.onClick,
          "label-middle": props.labelMiddleAlign,
        })}
        onClick={props.onClick}
      >
        <td
          className={classnames("single-col", props.labelClass || "")}
          colSpan={!truncateExtraColumns ? 3 : 2}
        >
          <div className={"single-col-container"}>
            {props.label && (
              <div className={classnames("row-label", props.labelClass || "")}>
                {props.label}
              </div>
            )}
            <div className={classnames("row-value", props.valueClass || "")}>
              {props.value}
            </div>
          </div>
        </td>
      </tr>
    );
  } else {
    return (
      <>
        <tr
          className={classnames("info-table-row", props.rowClass || "", {
            clickable: !!props.onClick,
            "label-middle": props.labelMiddleAlign,
          })}
          onClick={props.onClick}
        >
          {props.label && (
            <td className={classnames("row-label", props.labelClass || "")}>
              {props.label}
            </td>
          )}
          <td
            className={classnames("row-value-cell", {
              middle: props.valueMiddleAlign,
            })}
            colSpan={truncateExtraColumns ? 3 : undefined}
          >
            <div
              className={classnames("row-value", props.valueClass || "", {
                middle: props.valueMiddleAlign,
              })}
            >
              {props.value}
            </div>
          </td>
          {!truncateExtraColumns && (
            <>
              <td
                className={classnames("third-col", props.thirdColClass, {
                  "top-align": !props.valueMiddleAlign,
                })}
              >
                {props.thirdCol}
              </td>
              <td className="row-chevron">
                {!!props.onClick && <div className="cr-icon-chevron" />}
              </td>
            </>
          )}
        </tr>
        {props.additionalDetails && (
          <tr className={"info-table-additional"}>
            <td colSpan={3}>{props.additionalDetails}</td>
          </tr>
        )}
      </>
    );
  }
};

export enum InfoTableStyling {
  Default,
  New,
  BoldLabels,
}

export interface IInfoTableProps extends HTMLProps<HTMLTableElement> {
  bordered?: boolean;
  borderedFirstRow?: boolean;
  styling?: InfoTableStyling;
}

const InfoTable = (props: IInfoTableProps) => {
  const {
    children,
    className,
    bordered = false,
    borderedFirstRow = false,
    styling = InfoTableStyling.Default,
    ...rest
  } = props;

  const classes = classnames("info-table", className, {
    bordered: bordered,
    "bordered-first-row": borderedFirstRow,
    "new-styling": styling === InfoTableStyling.New,
    "bold-labels": styling === InfoTableStyling.BoldLabels,
  });

  return (
    <table className={classes} {...rest}>
      <tbody>{children}</tbody>
    </table>
  );
};

export default InfoTable;
