import {
  Breach,
  HistoryItem,
  HistoryItemType,
} from "../../../_common/types/emailExposures";
import { IUserMiniMap } from "../../../_common/types/user";
import { DefaultThunkDispatchProp } from "../../../_common/types/redux";
import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";

import {
  addCommentToEmailExposureBreach,
  EmailExposureHistory,
  fetchEmailExposureHistory,
} from "../../reducers/emailExposures.actions";
import { UserWriteEmailExposures } from "../../../_common/permissions";
import "../../style/components/email_exposures/BreachHistoryPanel.scss";
import ActivityFeed, {
  ActivityFeedItemStatusChange,
  IActivityFeedItem,
} from "../../../_common/components/ActivityFeed";
import { pluralise } from "../../../_common/helpers";
import PillLabel from "../PillLabel";
import { LabelColor } from "../../../_common/types/label";
import LoadingBanner from "../../../_common/components/core/LoadingBanner";
import SlidePanel from "../../../_common/components/SlidePanel";
import MessageInput from "../../../_common/components/MessageInput";
import { addDefaultUnknownErrorAlert } from "../../../_common/reducers/messageAlerts.actions";
import moment from "moment/moment";
import UserAvatar from "../../../_common/components/UserAvatar";
import { appConnect } from "../../../_common/types/reduxHooks";

interface IBreachHistoryPanelOwnProps {
  breachID: number;
  onClose: () => void;
  active: boolean;
}

interface IBreachHistoryPanelConnectedProps {
  breachTitle?: string;
  breachDate?: string;
  breachPublishedAt?: string;
  historyItems?: HistoryItem[];
  sharedUsers?: IUserMiniMap;
  userIDsToBeNotified?: number[];
  userCanWriteComments: boolean;
}

type IBreachHistoryPanelProps = DefaultThunkDispatchProp &
  IBreachHistoryPanelOwnProps &
  IBreachHistoryPanelConnectedProps;

const BreachHistoryPanel: FC<IBreachHistoryPanelProps> = ({
  breachID,
  breachTitle,
  breachDate,
  breachPublishedAt,
  historyItems,
  sharedUsers,
  userIDsToBeNotified,
  userCanWriteComments,
  dispatch,
  onClose,
  active,
}) => {
  const activityFeedRef = useRef<HTMLDivElement | null>(null);
  const [messageContent, setMessageContent] = useState("");
  const [sendingMessage, setSendingMessage] = useState(false);

  useEffect(() => {
    if (!historyItems) {
      dispatch(fetchEmailExposureHistory(breachID));
    }
  }, [dispatch, breachID, historyItems]);

  const onSendMessage = useCallback(async () => {
    setSendingMessage(true);

    try {
      await dispatch(addCommentToEmailExposureBreach(breachID, messageContent));
      setMessageContent("");

      // Scroll the activity feed back to the bottom if possible
      if (activityFeedRef.current) {
        activityFeedRef.current.scrollTop = 0;
      }
    } catch (e) {
      console.error(e);
      dispatch(
        addDefaultUnknownErrorAlert(
          "An error occurred whilst saving your comment. Please contact UpGuard Support."
        )
      );
    }

    setSendingMessage(false);
  }, [dispatch, breachID, messageContent]);

  const activityFeedItems = useMemo(() => {
    if (!historyItems || !sharedUsers) {
      return undefined;
    }

    return historyItems.map((historyItem) => {
      const feedItem: IActivityFeedItem = {
        id: historyItem.id,
        createdBy: historyItem.userID
          ? sharedUsers[historyItem.userID]
          : undefined,
        activity: "",
        createdAt: historyItem.createdAt,
      };

      switch (historyItem.itemType) {
        case HistoryItemType.Comment:
          feedItem.activity = "commented";
          feedItem.text = historyItem.itemMeta.text;
          break;
        case HistoryItemType.NewAssignee:
          if (historyItem.itemMeta.userID) {
            const assignedUser = sharedUsers[historyItem.itemMeta.userID];
            feedItem.activity = `assigned to ${
              assignedUser ? assignedUser.name || assignedUser.email : ""
            }`;
          } else {
            feedItem.activity = "removed the assignee";
          }
          break;
        case HistoryItemType.VIPsTagged:
          feedItem.activity = `${
            historyItem.itemMeta.vip ? "tagged" : "untagged"
          } ${historyItem.itemMeta.num} ${pluralise(
            historyItem.itemMeta.num,
            "account",
            "accounts"
          )} as VIP`;
          break;
        case HistoryItemType.AccountsNotified:
          feedItem.activity = `notified ${historyItem.itemMeta.num} ${pluralise(
            historyItem.itemMeta.num,
            "account",
            "accounts"
          )}`;
          break;
        case HistoryItemType.AccountsIgnored:
          feedItem.activity = `${
            historyItem.itemMeta.ignored ? "ignored" : "unignored"
          } ${historyItem.itemMeta.num} ${pluralise(
            historyItem.itemMeta.num,
            "account",
            "accounts"
          )}`;
          break;
        case HistoryItemType.ArchiveStatusChanged:
          feedItem.activity = "changed the status";
          feedItem.additionalInfo = (
            <ActivityFeedItemStatusChange
              prevStatus={
                historyItem.itemMeta.archived ? (
                  <PillLabel color={LabelColor.Blue}>Active</PillLabel>
                ) : (
                  <PillLabel color={LabelColor.Grey}>Archived</PillLabel>
                )
              }
              currentStatus={
                !historyItem.itemMeta.archived ? (
                  <PillLabel color={LabelColor.Blue}>Active</PillLabel>
                ) : (
                  <PillLabel color={LabelColor.Grey}>Archived</PillLabel>
                )
              }
            />
          );
          break;
      }

      return feedItem;
    });
  }, [historyItems, sharedUsers]);

  return (
    <SlidePanel
      className="breach-history-panel-container"
      newStyles
      onClose={onClose}
      active={active}
      dimContent
      title={`Identity Breach: ${breachTitle}`}
    >
      <div className="breach-history-panel">
        {activityFeedItems ? (
          <>
            <ActivityFeed
              ref={activityFeedRef}
              items={activityFeedItems}
              preContent={
                <>
                  {breachDate && (
                    <div>Date of breach: {moment(breachDate).format("ll")}</div>
                  )}
                  {breachPublishedAt && (
                    <div>
                      Date published: {moment(breachPublishedAt).format("ll")}
                    </div>
                  )}
                </>
              }
            />
          </>
        ) : (
          <LoadingBanner />
        )}
        {userCanWriteComments && (
          <div className="message-input-section">
            <MessageInput
              value={messageContent}
              onChange={(val: string) => setMessageContent(val)}
              onSubmit={onSendMessage}
              onSubmitText="Comment"
              loading={sendingMessage}
              placeholder="Leave a comment"
              showActions={messageContent.trim().length > 0}
              buttonsLeftContent={
                userIDsToBeNotified && sharedUsers ? (
                  <>
                    {userIDsToBeNotified.map((userID) => {
                      const user = sharedUsers[userID];
                      if (!user) {
                        return null;
                      }
                      return (
                        <UserAvatar
                          key={userID}
                          avatar={user.avatar}
                          name={user.name}
                          hoverPopup={user.name || user.email}
                        />
                      );
                    })}
                    <div className="notify-users-text">
                      {userIDsToBeNotified.length}{" "}
                      {pluralise(
                        userIDsToBeNotified.length,
                        "person",
                        "people"
                      )}{" "}
                      will be notified
                    </div>
                  </>
                ) : (
                  <></>
                )
              }
            />
          </div>
        )}
      </div>
    </SlidePanel>
  );
};

export default appConnect<
  IBreachHistoryPanelConnectedProps,
  never,
  IBreachHistoryPanelOwnProps
>((state, props) => {
  const breachData: Breach | undefined =
    state.cyberRisk.customerData.exposures.breach_data?.find(
      (b: Breach) => b.ID === props.breachID
    );
  const breachHistory: EmailExposureHistory | undefined =
    state.cyberRisk.customerData.exposures.breachHistory[props.breachID];

  const newProps: IBreachHistoryPanelConnectedProps = {
    breachTitle: breachData?.Title,
    breachDate: breachData?.BreachDate,
    breachPublishedAt: breachData?.PublishedDate,
    historyItems: breachHistory?.historyItems,
    sharedUsers: breachHistory?.sharedUsers,
    userIDsToBeNotified: breachHistory?.usersIDsToBeNotified,
    userCanWriteComments: state.common.userData.userPermissions.includes(
      UserWriteEmailExposures
    ),
  };
  return newProps;
})(BreachHistoryPanel);
