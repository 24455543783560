import { FetchCyberRiskUrl } from "../../_common/api";
import { LogError } from "../../_common/helpers";
import {
  fetchVendorMgtDocuments,
  getVendorData,
  setAdditionalEvidenceForVendor,
  setVendorManagementDocuments,
} from "./cyberRiskActions";
import {
  conditionalRefreshActivityStreamForOrgUser,
  grabTPVMSession,
} from "../../_common/reducers/commonActions";
import { get as _get } from "lodash";
import {
  DefaultAction,
  DefaultThunkDispatch,
  ISingleVendorData,
} from "../../_common/types/redux";
import { DefaultRootState } from "react-redux";
import { AdditionalEvidence } from "../types/additionalEvidence";
import { fetchAdditionalEvidenceForVendor } from "./additionalEvidence.actions";

export enum VendorDocumentType {
  General = "GeneralDocument",
  QuestionnaireAttachment = "QuestionnaireAttachment",
  AdditionalEvidence = "AdditionalEvidence",
}

export interface IVendorDocumentSummary {
  id: number;
  organisationID: number;
  datastoreVendorID: number;
  gcsObjectName?: string;
  gcsBucketAlias?: string;
  filename?: string;
  description?: string;
  virusScanned?: boolean;
  virusSafe?: boolean;
  virusScanResults?: string;
  uploadedAt?: string;
  uploadedBy: string;
  uploadedByAvatar: string;
  documentType: VendorDocumentType;
  documentOwnerID: number;
  documentName: string;
  convertedFromType?: VendorDocumentType;
  parentName?: string;
  sourceDocumentOwnerID?: string;

  // Client-side only
  deleting?: boolean;
}

interface UploadVendorDocumentV1Resp {
  status: string;
  document: IVendorDocumentSummary;
}

interface GetVendorDocumentListV1 {
  status: string;
  allDocuments: IVendorDocumentSummary[];
}

interface ConvertDocumentToAdditionalEvidenceV1Resp {
  status: string;
  evidence: AdditionalEvidence;
}

// refreshVendorDocumentData silently fetches the data for a specific vendor document instance
export const refreshVendorDocumentData = (
  vendorId: number,
  docId: number
): DefaultAction<IVendorDocumentSummary | undefined> => {
  return async (dispatch, getState) => {
    let resp: GetVendorDocumentListV1 | undefined;

    try {
      resp = await FetchCyberRiskUrl<GetVendorDocumentListV1>(
        "watchedvendor/documents/v1/",
        {
          datastore_vendor_id: vendorId,
          document_id: docId,
        },
        {},
        dispatch,
        getState
      );
    } catch (e) {
      resp = undefined;
      LogError("Error retrieving vendor document instance", e);
    }

    if (!resp || resp.status !== "OK") {
      return undefined;
    }

    const json = resp;

    // update the document in the cache
    let refreshedDoc: IVendorDocumentSummary | undefined;
    const tpvmSession = grabTPVMSession(getState);
    const vendor = getVendorData(getState, vendorId, false, tpvmSession);
    const documents = _get(vendor, "mgtlists.allDocuments.result", null);
    if (documents) {
      const docs = documents.map((document: IVendorDocumentSummary) => {
        if (
          document.id === docId &&
          document.documentType === "GeneralDocument"
        ) {
          refreshedDoc = json.allDocuments[0];
          return json.allDocuments[0];
        }
        return document;
      });
      dispatch(
        setVendorManagementDocuments(
          vendorId,
          {
            documents: {
              loading: false,
              error: null,
              result: docs,
            },
          },
          tpvmSession
        )
      );
    }
    return refreshedDoc;
  };
};

export const uploadNewVendorDocument = (
  file: File | undefined,
  vendorId: number,
  description: string,
  title: string
) => {
  return async (dispatch: DefaultThunkDispatch, getState: any) => {
    // set a placeholder for the new file in the document list
    const data = new FormData();

    if (file) {
      data.append("file", file);
    }

    // Now try uploading the file
    let json: UploadVendorDocumentV1Resp;

    try {
      json = await FetchCyberRiskUrl<UploadVendorDocumentV1Resp>(
        "watchedvendor/document/v1/",
        {
          datastore_vendor_id: vendorId,
          description,
          title,
        },
        {
          method: "POST",
          body: data,
        },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("error posting new vendor document file", e);

      throw e;
    }

    // add the new document into the documents list
    const tpvmSession = grabTPVMSession(getState);
    const vendor = getVendorData(getState, vendorId, false, tpvmSession);
    const documents =
      (vendor as ISingleVendorData).mgtlists?.allDocuments?.result ?? [];
    documents.push(json.document);

    dispatch(
      setVendorManagementDocuments(
        vendorId,
        {
          documents: {
            loading: false,
            error: null,
            result: documents,
          },
        },
        tpvmSession
      )
    );

    // kick off call to update the activity stream
    dispatch(conditionalRefreshActivityStreamForOrgUser());

    // this async action should return the new document instance so we can edit it
    return json.document;
  };
};

export const updateVendorDocument = (
  docId: number,
  vendorId: number,
  title: string,
  description: string,
  file?: File,
  deleteExistingFile?: boolean
) => {
  return async (dispatch: DefaultThunkDispatch, getState: any) => {
    const data = new FormData();

    if (file) {
      data.append("file", file);
    }

    try {
      await FetchCyberRiskUrl(
        "watchedvendor/document/v1/",
        {
          document_id: docId,
          title: title,
          description,
          delete_existing_file: deleteExistingFile,
        },
        { method: "PUT", body: data },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("error updating existing vendor document file", e);

      throw e;
    }

    // update the document in the cache
    const tpvmSession = grabTPVMSession(getState);
    const vendor = getVendorData(getState, vendorId, false, tpvmSession);
    const documents = _get(vendor, "mgtlists.allDocuments.result", null);

    const doc = documents.map((document: IVendorDocumentSummary) => {
      if (document.id === docId) {
        const newdoc = document;
        newdoc.documentName = title;
        newdoc.description = description;

        if (file) {
          const d = new Date();
          const tempGCSName = `${d.getFullYear()}${d.getMonth()}${d.getDay()}${d.getHours()}${d.getMinutes()}${d.getSeconds()}${d.getMilliseconds()}`;
          newdoc.gcsObjectName = tempGCSName;
          newdoc.filename = file.name;
        } else if (deleteExistingFile) {
          newdoc.gcsObjectName = undefined;
          newdoc.gcsBucketAlias = undefined;
        }

        return newdoc;
      }
      return document;
    });

    dispatch(
      setVendorManagementDocuments(
        vendorId,
        {
          documents: {
            loading: false,
            error: null,
            result: doc,
          },
        },
        tpvmSession
      )
    );
  };
};

export const setVendorDocumentDeleted = (vendorId: number, docId: number) => {
  return async (dispatch: DefaultThunkDispatch, getState: any) => {
    const tpvmSession = grabTPVMSession(getState);
    let vendor = getVendorData(getState, vendorId, false, tpvmSession);
    let documents = _get(vendor, "mgtlists.allDocuments.result", null);

    let i = 0;
    for (i = 0; i < documents.length; i++) {
      if (documents[i].id === docId) {
        documents[i].deleting = true;
        break;
      }
    }
    dispatch(
      setVendorManagementDocuments(
        vendorId,
        {
          documents: {
            loading: false,
            error: null,
            result: documents,
          },
        },
        tpvmSession
      )
    );

    try {
      await FetchCyberRiskUrl(
        "watchedvendor/document/v1/",
        {
          document_id: docId,
        },
        { method: "DELETE" },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("error deleting existing vendor document file", e);

      for (i = 0; i < documents.length; i++) {
        if (documents[i].id === docId) {
          documents[i].deleting = false;
          break;
        }
      }
      throw e;
    }

    // delete the document from the documents list
    vendor = getVendorData(getState, vendorId, false, tpvmSession);
    documents = _get(vendor, "mgtlists.allDocuments.result", null);
    for (i = 0; i < documents.length; i++) {
      if (documents[i].id === docId) {
        if (documents.length > 1) {
          documents.splice(i, 1);
        } else {
          documents = null;
        }
        break;
      }
    }
    dispatch(
      setVendorManagementDocuments(
        vendorId,
        {
          documents: {
            loading: false,
            error: null,
            result: documents,
          },
        },
        tpvmSession
      )
    );

    // kick off call to update the activity stream
    dispatch(conditionalRefreshActivityStreamForOrgUser());
  };
};

// convertVendorDocumentToAdditionalEvidence - create a new additional evidence record from a source document or questionnaire attachment
export const convertVendorDocumentToAdditionalEvidence = (
  vendorID: number,
  entityID: string,
  entityType: string,
  additionalEvidenceName?: string,
  additionalEvidenceType?: number,
  expiryDate?: string,
  comments?: string
) => {
  return async (
    dispatch: DefaultThunkDispatch,
    getState: () => DefaultRootState
  ) => {
    let response: ConvertDocumentToAdditionalEvidenceV1Resp | undefined;

    try {
      response = await FetchCyberRiskUrl(
        "vendor/evidence/create_from/v1",
        {
          vendor_id: vendorID,
          entity_type: entityType,
          entity_id: entityID,
          evidence_name: additionalEvidenceName,
          evidence_type_id: additionalEvidenceType,
          expiry_date: expiryDate,
          comments: comments,
        },
        { method: "POST" },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("error converting vendor document to additional evidence", e);
      throw e;
    }

    // Refresh the document list
    dispatch(fetchVendorMgtDocuments(vendorID, true));
    return response?.evidence;
  };
};

export const archiveSharedDocumentForVendor = (
  vendorId: number,
  object: string,
  archive: boolean
): DefaultAction => {
  return async (dispatch, getState) => {
    const tpvmSession = grabTPVMSession(getState);
    dispatch(
      setAdditionalEvidenceForVendor(
        true,
        vendorId,
        null,
        null,
        null,
        tpvmSession
      )
    );

    try {
      await FetchCyberRiskUrl(
        "vendor/shareddocument/archive/v1",
        {
          vendor_id: vendorId,
          object: object,
          archive: archive,
        },
        { method: "PUT" },
        dispatch,
        getState
      );
    } catch (e) {
      LogError(`Error archiving/unarchiving additional evidence for vendor`, e);
      throw e;
    } finally {
      // we set the evidence as loading up to so always re-fetch the evidence,
      // even in case of failure, so that the table reload properly
      dispatch(fetchAdditionalEvidenceForVendor(vendorId, true));
    }

    // kick off call to update the activity stream
    dispatch(conditionalRefreshActivityStreamForOrgUser());
  };
};
