import { remove as _remove, get as _get } from "lodash";
import {
  CLEARALLDATA,
  SET_CUSTOMERS_LOADING,
  SET_CUSTOMERS,
  SET_SEARCH_RESULTS,
  SET_SEARCH_RESULT_COUNTS,
  SET_HIGHLIGHTED_SEARCH_RESULTS,
  REMOVE_SEARCH_RESULT_BY_ID,
  SET_PUBLISHED_FINDINGS,
  SET_UNPUBLISHED_FINDINGS,
  SET_SCRATCH_REQUESTS,
  SET_SCRATCH_REQUESTS_LOADING,
  SET_SEARCH_REQUEST_RESULT_COUNTS,
  SET_SCRATCH_SEARCH_RESULTS,
  SET_SCRATCH_SEARCH_RESULT_COUNTS,
  SET_SCRATCH_HIGHLIGHTED_SEARCH_RESULTS,
  REMOVE_SCRATCH_SEARCH_RESULT_BY_ID,
  SET_UNPUBLISHED_FINDINGS_FOR_GROUP,
  SET_DOMAINS_WITH_FINDINGS,
  SET_DOMAIN_UNPUBLISHED_FINDINGS,
  CLEAR_DOMAIN_UNPUBLISHED_FINDINGS,
  SET_VENDOR_SEARCH,
} from "./actions";

import {
  SET_ALL_LICENSES,
  SET_MANAGED_SERVICE_CUSTOMER_NAMES,
  SET_MANAGED_SERVICE_FILTERS,
  SET_MANAGED_SERVICE_LIST,
  SET_ORG_LICENSES,
  SET_SINGLE_REQUEST,
} from "./analystManagedVendors.actions";
import { initialState } from "./reducer.initialState";

function reducer(state = initialState, action) {
  switch (action.type) {
    case CLEARALLDATA:
      // Keep filters when clearing the state
      return {
        ...initialState,
      };
    case SET_CUSTOMERS_LOADING:
      return {
        ...state,
        customers: {
          ...state.customers,
          loading: action.loading,
        },
      };
    case SET_CUSTOMERS:
      return {
        ...state,
        customers: {
          ...state.customers,
          ...action.results,
        },
        searchEnabled: action.searchEnabled,
      };
    case SET_SCRATCH_REQUESTS_LOADING:
      return {
        ...state,
        scratchRequestGroups: {
          ...state.scratchRequestGroups,
          loading: action.loading,
        },
      };
    case SET_SCRATCH_REQUESTS:
      return {
        ...state,
        scratchRequestGroups: {
          ...state.scratchRequestGroups,
          ...action.results,
        },
      };
    case SET_SEARCH_RESULTS:
      return {
        ...state,
        searchResults: {
          ...state.searchResults,
          [action.orgId]: {
            ...state.searchResults[action.orgId],
            ...action.searchResults,
          },
        },
      };
    case SET_SCRATCH_SEARCH_RESULTS:
      return {
        ...state,
        scratchSearchResults: {
          ...state.scratchSearchResults,
          [action.groupId]: {
            ...state.scratchSearchResults[action.groupId],
            ...action.searchResults,
          },
        },
      };
    case SET_SEARCH_RESULT_COUNTS:
      return {
        ...state,
        searchResultCounts: {
          ...state.searchResultCounts,
          [action.orgId]: action.searchResultCounts,
        },
      };
    case SET_SCRATCH_SEARCH_RESULT_COUNTS:
      return {
        ...state,
        scratchSearchResultCounts: {
          ...state.scratchSearchResultCounts,
          [action.groupId]: action.searchResultCounts,
        },
      };
    case SET_HIGHLIGHTED_SEARCH_RESULTS:
      return {
        ...state,
        highlightedSearchResults: {
          ...state.highlightedSearchResults,
          [action.orgId]: {
            ...state.highlightedSearchResults[action.orgId],
            ...action.searchResults,
          },
        },
      };
    case SET_SCRATCH_HIGHLIGHTED_SEARCH_RESULTS:
      return {
        ...state,
        scratchHighlightedSearchResults: {
          ...state.scratchHighlightedSearchResults,
          [action.groupId]: {
            ...state.scratchHighlightedSearchResults[action.groupId],
            ...action.searchResults,
          },
        },
      };
    case REMOVE_SEARCH_RESULT_BY_ID: {
      const searchResults = [
        ..._get(state.searchResults[action.orgId], "results", []),
      ];
      const highlightedSearchResults = [
        ..._get(state.highlightedSearchResults[action.orgId], "results", []),
      ];
      // remove just the search result with this id
      _remove(searchResults, (r) => r.searchResultId === action.searchResultId);
      _remove(
        highlightedSearchResults,
        (r) => r.searchResultId === action.searchResultId
      );
      return {
        ...state,
        searchResults: {
          ...state.searchResults,
          [action.orgId]: {
            ...state.searchResults[action.orgId],
            results: searchResults,
          },
        },
        highlightedSearchResults: {
          ...state.highlightedSearchResults,
          [action.orgId]: {
            ...state.highlightedSearchResults[action.orgId],
            results: highlightedSearchResults,
          },
        },
      };
    }
    case REMOVE_SCRATCH_SEARCH_RESULT_BY_ID: {
      const searchResults = [
        ..._get(state.scratchSearchResults[action.groupId], "results", []),
      ];
      const highlightedSearchResults = [
        ..._get(
          state.scratchHighlightedSearchResults[action.groupId],
          "results",
          []
        ),
      ];

      // remove just the search result with this id
      _remove(searchResults, (r) => r.searchResultId === action.searchResultId);
      _remove(
        highlightedSearchResults,
        (r) => r.searchResultId === action.searchResultId
      );
      return {
        ...state,
        scratchSearchResults: {
          ...state.scratchSearchResults,
          [action.groupId]: {
            ...state.scratchSearchResults[action.groupId],
            results: searchResults,
          },
        },
        scratchHighlightedSearchResults: {
          ...state.scratchHighlightedSearchResults,
          [action.groupId]: {
            ...state.scratchHighlightedSearchResults[action.groupId],
            results: highlightedSearchResults,
          },
        },
      };
    }
    case SET_PUBLISHED_FINDINGS:
      return {
        ...state,
        findings: {
          ...state.findings,
          [action.orgId]: {
            ...state.findings[action.orgId],
            published: action.findings,
          },
        },
      };
    case SET_UNPUBLISHED_FINDINGS:
      return {
        ...state,
        findings: {
          ...state.findings,
          [action.orgId]: {
            ...state.findings[action.orgId],
            unpublished: action.findings,
          },
        },
      };
    case SET_SEARCH_REQUEST_RESULT_COUNTS:
      return {
        ...state,
        scratchRequestCounts: {
          ...state.scratchRequestCounts,
          [action.requestID]: action.results,
        },
      };
    case SET_UNPUBLISHED_FINDINGS_FOR_GROUP:
      return {
        ...state,
        scratchFindings: {
          ...state.scratchFindings,
          [action.groupId]: {
            ...state.scratchFindings[action.groupId],
            unpublished: action.findings,
          },
        },
      };

    case SET_DOMAINS_WITH_FINDINGS:
      return {
        ...state,
        domainsWithFindings: {
          ...state.domainsWithFindings,
          ...action.data,
        },
      };
    case SET_DOMAIN_UNPUBLISHED_FINDINGS:
      return {
        ...state,
        domainFindings: {
          ...state.domainFindings,
          [action.domainName]: {
            ...state.domainFindings[action.domainName],
            unpublished: action.findings,
          },
        },
      };
    case CLEAR_DOMAIN_UNPUBLISHED_FINDINGS:
      return {
        ...state,
        domainFindings: {},
      };
    case SET_VENDOR_SEARCH:
      return {
        ...state,
        vendorSearch: {
          ...state.vendorSearch,
          ...action.data,
        },
      };
    case SET_MANAGED_SERVICE_CUSTOMER_NAMES:
      return {
        ...state,
        managedServiceCustomerNames: action.names,
      };
    case SET_MANAGED_SERVICE_LIST:
      // cache the requests by ID and just keep a list of IDs as the main list
      const list = action.requests?.map((r) => r.id);
      const requests = action.requests?.reduce((prev, r) => {
        prev[r.id] = {
          details: r,
          detailsLoading: false,
        };
        return prev;
      }, {});

      return {
        ...state,
        managedServiceRequests: {
          ...state.managedServiceRequests,
          requestList: list ?? state.managedServiceRequests.requestList,
          loading: action.loading,
          requests: {
            ...state.managedServiceRequests.requests,
            ...requests,
          },
          error: action.error,
          paging: action.paging ?? state.managedServiceRequests.paging,
        },
      };
    case SET_MANAGED_SERVICE_FILTERS:
      return {
        ...state,
        managedServiceRequests: {
          ...state.managedServiceRequests,
          filter: action.filter,
        },
      };
    case SET_SINGLE_REQUEST:
      return {
        ...state,
        managedServiceRequests: {
          ...state.managedServiceRequests,
          requests: {
            ...state.managedServiceRequests.requests,
            ...action.data,
          },
        },
      };
    case SET_ORG_LICENSES:
      const licenseInfo = state.licenses[action.orgID] ?? {};
      return {
        ...state,
        licenses: {
          [action.orgId]: {
            loading: action.loading,
            licenses: action.licenses ?? licenseInfo.licenses ?? [],
          },
        },
      };
    case SET_ALL_LICENSES:
      return {
        ...state,
        allLicenses: {
          loading: action.loading,
          licenses: action.licenses ?? state.allLicenses.licenses ?? [],
        },
      };
    default:
      return state;
  }
}

export default reducer;
