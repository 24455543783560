import { throttle as _throttle } from "lodash";
import { FC, ReactNode, useEffect, useRef, useState } from "react";
import * as resizeDetector from "resize-detector";
import "../../style/components/navigation/HeighAdjustableContainer.scss";
import classnames from "classnames";

interface HeightAdjustableContainerProps {
  children: ReactNode;
  adjustFooter?: boolean;
  disableFader?: boolean;
}

const HeightAdjustableContainer: FC<HeightAdjustableContainerProps> = (
  props
) => {
  const [topFaderOpacity, setTopFaderOpacity] = useState(0);
  const [bottomFaderOpacity, setBottomFaderOpacity] = useState(0);

  const heightAdjustableInner = useRef<HTMLDivElement>(null);
  const actualHeightWatcher = useRef<HTMLDivElement>(null);

  useEffect(() => {
    heightAdjustableScrollWatcher();

    if (!!heightAdjustableInner.current) {
      resizeDetector.addListener(
        heightAdjustableInner.current,
        heightAdjustableScrollWatcher
      );

      heightAdjustableInner.current.addEventListener(
        "scroll",
        heightAdjustableScrollWatcher
      );
    }

    if (!!actualHeightWatcher.current) {
      resizeDetector.addListener(
        actualHeightWatcher.current,
        heightAdjustableScrollWatcher
      );
    }

    // Update the footer element to adjust for navbar width
    if (props.adjustFooter) {
      const footerElement = document.getElementById("foot");
      if (footerElement) {
        footerElement.classList.add("navbar-offset");
      }
    }

    return () => {
      if (!!heightAdjustableInner.current) {
        resizeDetector.removeListener(
          heightAdjustableInner.current,
          heightAdjustableScrollWatcher
        );

        heightAdjustableInner.current.removeEventListener(
          "scroll",
          heightAdjustableScrollWatcher
        );
      }

      if (!!actualHeightWatcher.current) {
        resizeDetector.removeListener(
          actualHeightWatcher.current,
          heightAdjustableScrollWatcher
        );
      }

      // Update the footer element to adjust for no navbar
      if (props.adjustFooter) {
        const footerElement = document.getElementById("foot");
        if (footerElement) {
          footerElement.classList.remove("navbar-offset");
        }
      }
    };
  }, []);

  const heightAdjustableScrollWatcher = _throttle(() => {
    if (!heightAdjustableInner?.current) {
      return;
    }

    const topObscuredBy = heightAdjustableInner.current.scrollTop;
    const bottomObscuredBy =
      heightAdjustableInner.current.scrollHeight -
      heightAdjustableInner.current.scrollTop -
      heightAdjustableInner.current.clientHeight;

    let topFaderOpacity = 0;
    if (topObscuredBy > 0 && topObscuredBy < 100) {
      topFaderOpacity = topObscuredBy / 100;
    } else if (topObscuredBy >= 100) {
      topFaderOpacity = 1;
    }

    let bottomFaderOpacity = 0;
    if (bottomObscuredBy > 0 && bottomObscuredBy < 100) {
      bottomFaderOpacity = bottomObscuredBy / 100;
    } else if (bottomObscuredBy >= 100) {
      bottomFaderOpacity = 1;
    }

    setTopFaderOpacity(topFaderOpacity);
    setBottomFaderOpacity(bottomFaderOpacity);
  }, 50);

  return (
    <div className="height-adjustable">
      <div
        className={classnames("scroll-fader", "top-fader", {
          disabled: props.disableFader,
        })}
        style={{ opacity: topFaderOpacity }}
      />
      <div className="height-adjustable-inner" ref={heightAdjustableInner}>
        <div className="height-adjustable-watcher" ref={actualHeightWatcher}>
          {props.children}
        </div>
      </div>
      <div
        className={classnames("scroll-fader", "bottom-fader", {
          disabled: props.disableFader,
        })}
        style={{ opacity: bottomFaderOpacity }}
      />
    </div>
  );
};

export default HeightAdjustableContainer;
