import { Portfolio } from "../../reducers/portfolios.actions";
import { FC } from "react";
import ColorCheckbox from "../ColorCheckbox";

import "../../style/components/portfolios/PortfolioChoiceSet.scss";

interface PortfolioChoiceProps {
  portfolio: Portfolio;
  selected: boolean;
  onSelect: (isSelected: boolean) => void;
  multiSelect: boolean;
  disabled?: boolean;
}

const PortfolioChoice: FC<PortfolioChoiceProps> = ({
  portfolio,
  selected,
  onSelect,
  multiSelect,
  disabled,
}) => (
  <div className="portfolio-choice">
    <ColorCheckbox
      radio={!multiSelect}
      checked={selected}
      disabled={disabled}
      onClick={() => {
        if (selected && multiSelect) {
          onSelect(false);
        } else if (!selected) {
          onSelect(true);
        }
      }}
      label={portfolio.name}
    />
  </div>
);

interface PortfolioChoiceSetProps {
  portfolios: Portfolio[];
  selectedPortfolioIds: number[];
  disabledPortfolioIds?: number[];
  onSelectionChange: (selected: number[]) => void;
  multiSelect?: boolean;
}

const PortfolioChoiceSet: FC<PortfolioChoiceSetProps> = ({
  portfolios,
  selectedPortfolioIds,
  disabledPortfolioIds = [],
  onSelectionChange,
  multiSelect = false,
}) => {
  return (
    <div className="portfolio-choices">
      {portfolios.map((p) => (
        <PortfolioChoice
          key={p.id}
          portfolio={p}
          selected={selectedPortfolioIds.includes(p.id)}
          disabled={disabledPortfolioIds.includes(p.id)}
          onSelect={(selected) => {
            if (selected) {
              if (multiSelect) {
                onSelectionChange([...selectedPortfolioIds, p.id]);
              } else {
                onSelectionChange([p.id]);
              }
            } else {
              onSelectionChange(
                selectedPortfolioIds.filter((pID) => pID !== p.id)
              );
            }
          }}
          multiSelect={multiSelect}
        />
      ))}
    </div>
  );
};

export default PortfolioChoiceSet;
