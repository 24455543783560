import {
  FC,
  PropsWithChildren,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import "../style/MaxHeightExpandableSection.scss";
import classNames from "classnames";

interface IMaxHeightExpandableSectionProps extends PropsWithChildren {
  maxHeight: number;
  className?: string;
}

const MaxHeightExpandableSection: FC<IMaxHeightExpandableSectionProps> = ({
  maxHeight,
  className,
  children,
}) => {
  const innerRef = useRef<HTMLDivElement>(null);
  const [contentExceedsMaxHeight, setContentExceedsMaxHeight] = useState(false);
  const [collapsed, setCollapsed] = useState(true);

  useLayoutEffect(() => {
    if (innerRef.current) {
      setContentExceedsMaxHeight(
        innerRef.current?.getBoundingClientRect().height > maxHeight
      );
    }
  }, [maxHeight, children]);

  return (
    <div
      className={classNames("max-height-expandable")}
      style={
        collapsed
          ? {
              maxHeight: `${maxHeight}px`,
            }
          : undefined
      }
    >
      <div ref={innerRef} className={className}>
        {children}
      </div>
      {contentExceedsMaxHeight && (
        <div
          className="expand-collapse"
          onClick={() => setCollapsed(!collapsed)}
        >
          {collapsed ? "...More" : "...Less"}
        </div>
      )}
    </div>
  );
};

export default MaxHeightExpandableSection;
