import LoadingBanner from "../../../_common/components/core/LoadingBanner";
import ScrollableDiv from "../ScrollableDiv";
import "../../style/components/securityprofile/ControlCheckPanel.scss";
import BackArrow from "../../../_common/components/BackArrow";
import VendorSecurityProfileAPI from "../../reducers/vendorSecurityProfileAPI";
import { CheckItemSlidePanelSection } from "../filter/SlidePanelSection";
import InfoTable, { InfoTableRow } from "../../../_common/components/InfoTable";
import { LabelColor } from "../../../_common/types/label";
import PillLabel from "../PillLabel";
import SeverityIcon, {
  AdjustedSeverityIcon,
} from "../../../_common/components/SeverityIcon";
import { SeverityAsString } from "../../../_common/types/severity";
import { sortBy, upperFirst } from "lodash";
import moment from "moment";
import XTable, { XTableCell } from "../../../_common/components/core/XTable";
import { HoverLocation } from "../../../_common/components/IconButton";
import { DropdownItem } from "../../../_common/components/core/DropdownV2";
import vendorSecurityProfileAPI from "../../reducers/vendorSecurityProfileAPI";
import { ControlState } from "../../types/securityProfile";
import RiskVendorDomains from "../RiskVendorDomains";
import { useHistory } from "react-router-dom";
import { OpenSidePanel } from "../DomainsAndIPsPanelGroup";
import { useDefaultLocation } from "../../../_common/types/router";
import {
  useBasicPermissions,
  UserManageVendorRiskWaivers,
  UserVendorRiskWrite,
} from "../../../_common/permissions";
import { useVendorURLPrefix } from "../../../_common/hooks";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../_common/types/reduxHooks";
import { useEffect, useMemo } from "react";
import {
  fetchRiskVendorWebsites,
  getRiskVendorWebsites,
} from "../../reducers/vendorRiskPortfolio.actions";

interface IControlCheckPanelProps {
  onClickBack?: () => void;
  vendorId: number;
  checkId: string;
}

const timeFormat = "D MMM YYYY HH:mm";

const ControlCheckPanel = ({
  checkId,
  onClickBack,
  vendorId,
}: IControlCheckPanelProps) => {
  const history = useHistory();
  const location = useDefaultLocation();
  const dispatch = useAppDispatch();

  const controlId = checkId.substring(0, 8);

  const { data: securityProfile } =
    vendorSecurityProfileAPI.useGetSecurityProfileQuery({
      vendorId: vendorId,
    });

  const { data: documents } =
    VendorSecurityProfileAPI.useGetSecurityDocumentsQuery({ vendorId });

  const { data: controlData, isLoading: controlLoading } =
    VendorSecurityProfileAPI.useGetVendorControlDataQuery({
      vendorId: vendorId,
      controlId: controlId,
    });
  const control = controlData?.control;

  // find the check for this and then find the relevant evidence which will either be
  // a scanning risk or document evidence
  const check = controlData?.control.checks.find((c) => c.id == checkId);
  const checkState = securityProfile?.controlStates[checkId];
  const scanningRisk = securityProfile?.scanningChecks[checkId];
  const documentCheck = securityProfile?.documentChecks[checkId];

  // fetch data
  useEffect(() => {
    if (scanningRisk) {
      dispatch(
        fetchRiskVendorWebsites(scanningRisk.id, vendorId, false, false, true)
      );
    }
  }, [scanningRisk, vendorId]);

  const riskVendorWebsites = useAppSelector((state) =>
    scanningRisk
      ? getRiskVendorWebsites(
          state,
          scanningRisk.id,
          vendorId,
          false,
          false,
          undefined
          // TODO - do we need to take filters into accoun there?
        )
      : undefined
  );

  const scannedAt = useMemo(() => {
    if (scanningRisk) {
      if (
        riskVendorWebsites &&
        riskVendorWebsites.data?.riskDetailsByHostname
      ) {
        const scanDates = sortBy(
          Object.values(riskVendorWebsites.data.riskDetailsByHostname),
          [(r) => r.scannedAt]
        );

        if (scanDates.length == 0) {
          return undefined;
        }

        return scanDates[0].scannedAt;
      }

      return undefined;
    } else if (documentCheck) {
      const sorted = sortBy(documentCheck, [(d) => d.scannedAt]);
      if (sorted.length == 0) {
        return undefined;
      }

      return sorted[0].scannedAt;
    }

    return undefined;
  }, [scanningRisk, documentCheck, riskVendorWebsites]);

  const urlPrefix = useVendorURLPrefix(vendorId);

  const permissions = useBasicPermissions();
  const userHasWriteWaiversPermission =
    permissions.userPermissions[UserManageVendorRiskWaivers];
  const userHasWriteRemediationPermission =
    permissions.userPermissions[UserVendorRiskWrite];

  if (controlLoading || !control || !check) {
    return (
      <div className="loading-overlay show">
        <LoadingBanner />
      </div>
    );
  }

  return (
    <div className="check-panel">
      {!!onClickBack && <BackArrow popup={"Back"} onClick={onClickBack} />}
      {scanningRisk ? <h2>{scanningRisk.title}</h2> : <h2>{check.text}</h2>}
      <ScrollableDiv newStyles>
        <CheckItemSlidePanelSection title="Check details" startExpanded={true}>
          <div className="detail-table">
            <InfoTable>
              <InfoTableRow
                label="RESULT"
                rowClass="detail-row"
                valueClass="status-value"
                value={
                  <>
                    {checkState === ControlState.Passed && (
                      <PillLabel color={LabelColor.Green}>
                        Check passed
                      </PillLabel>
                    )}
                    {checkState === ControlState.Failed && (
                      <PillLabel color={LabelColor.Grey}>
                        Risk detected
                      </PillLabel>
                    )}
                    {checkState === ControlState.Unknown && (
                      <PillLabel color={LabelColor.Grey}>
                        Evidence required
                      </PillLabel>
                    )}
                  </>
                }
              />
              {checkState == ControlState.Failed && (
                <InfoTableRow
                  rowClass="detail-row"
                  label="RISK SEVERITY"
                  value={
                    scanningRisk ? (
                      <>
                        <AdjustedSeverityIcon
                          severity={SeverityAsString(scanningRisk.severity)}
                          baseSeverity={
                            scanningRisk.baseSeverity
                              ? SeverityAsString(scanningRisk.baseSeverity)
                              : undefined
                          }
                        />
                        {upperFirst(SeverityAsString(scanningRisk.severity))}
                      </>
                    ) : (
                      <SeverityIcon
                        severity={SeverityAsString(check.severity)}
                      />
                    )
                  }
                />
              )}
              <InfoTableRow
                rowClass="detail-row"
                label="LAST SCANNED"
                value={scannedAt ? moment(scannedAt).format(timeFormat) : "-"}
              />
            </InfoTable>
          </div>
        </CheckItemSlidePanelSection>
        {scanningRisk?.summary && (
          <CheckItemSlidePanelSection title="Summary" startExpanded={true}>
            <p>{scanningRisk.summary}</p>
          </CheckItemSlidePanelSection>
        )}
        {scanningRisk?.riskDetails && (
          <CheckItemSlidePanelSection title="Risk details" startExpanded={true}>
            <p>{scanningRisk.riskDetails}</p>
          </CheckItemSlidePanelSection>
        )}
        {scanningRisk?.recommendedRemediation && (
          <CheckItemSlidePanelSection
            title="Recommended remediation"
            startExpanded={false}
          >
            <p>{scanningRisk.recommendedRemediation}</p>
          </CheckItemSlidePanelSection>
        )}
        {scanningRisk && (
          <CheckItemSlidePanelSection
            title={"Assets affected"}
            startExpanded={false}
          >
            <RiskVendorDomains
              riskId={scanningRisk.id}
              vendorId={vendorId}
              onClickDomain={(
                _riskId: string,
                _vendorId: number | undefined,
                hostname: string
              ) => {
                OpenSidePanel(
                  history,
                  {
                    scan: hostname,
                  },
                  check.text,
                  location
                );
              }}
              userHasWriteRiskWaiversPermission={userHasWriteWaiversPermission}
              onRequestRemediation={
                userHasWriteRemediationPermission
                  ? (hostname) => {
                      history.push(`${urlPrefix}remediation/add`, {
                        backContext: {
                          goBack: true,
                          backToText: "Back to security profile",
                        },
                        filterForWebsite: hostname,
                      });
                    }
                  : undefined
              }
              onCreateWaiver={
                userHasWriteWaiversPermission
                  ? (hostname) => {
                      history.push(
                        `${urlPrefix}riskwaivers/add?initialRiskId=${scanningRisk.id}&hostname=${hostname}`,
                        {
                          backContext: {
                            goBack: true,
                            backToText: "Back to security profile",
                          },
                        }
                      );
                    }
                  : undefined
              }
              showWaived={false}
              isWaived={false}
              isSubsidiary={false}
            />
          </CheckItemSlidePanelSection>
        )}
        {documentCheck && documentCheck.length > 0 && (
          <CheckItemSlidePanelSection
            title={`Citations (${documentCheck.length})`}
            startExpanded={true}
          >
            <div className="citations-table">
              <XTable
                iconOptions
                rows={documentCheck.map((check) => {
                  // find the document from our security profile documents
                  const document = documents?.availableDocuments.find(
                    (d) => check.documentID == d.id
                  );

                  return {
                    id: `${check.documentID}`,
                    cells: [
                      <XTableCell key="citation" className="citation">
                        <div className="document">
                          <a className="name">{document?.name}</a>
                          <div className="source">{`Uploaded by ${document?.author}, ${moment(
                            document?.lastUpdated
                          ).format(timeFormat)}`}</div>
                        </div>
                        {check.citations.map((c) => (
                          <div key={c.text} className={"extract"}>
                            {c.text}
                          </div>
                        ))}
                        {/*{check.question && check.answer && (*/}
                        {/*  <>*/}
                        {/*    <div className="question">*/}
                        {/*      <span className="label">Q</span>*/}
                        {/*      <span className="text">{check.question}</span>*/}
                        {/*    </div>*/}
                        {/*    <div className="answer">*/}
                        {/*      <span className="label">A</span>*/}
                        {/*      <span className="text">{check.answer}</span>*/}
                        {/*    </div>*/}
                        {/*  </>*/}
                        {/*)}*/}
                      </XTableCell>,
                    ],
                    iconOptions: [
                      {
                        id: `${check.id}-action`,
                        icon: <i className={"cr-icon-dots-menu"} />,
                        dropdownItems: [
                          <DropdownItem key="reject-citation">
                            <div className="dropdown-with-text">
                              <div className="dropdown-title">
                                Reject citation
                              </div>
                              <div className="dropdown-text">
                                Excludes this specific citation from scanning
                              </div>
                            </div>
                          </DropdownItem>,
                          <DropdownItem key="archive-document">
                            <div className="dropdown-with-text">
                              <div className="dropdown-title">
                                Archive document
                              </div>
                              <div className="dropdown-text">
                                Excludes entire document from scanning
                              </div>
                            </div>
                          </DropdownItem>,
                        ],
                        hoverText: "Manage this risk",
                        hoverLocation: HoverLocation.Left,
                      },
                    ],
                  };
                })}
              />
            </div>
          </CheckItemSlidePanelSection>
        )}
      </ScrollableDiv>
    </div>
  );
};

export default ControlCheckPanel;
