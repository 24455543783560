import React, { FC, ReactNode } from "react";
import "../../style/components/pageHeader/MainSection.scss";
import ButtonGroup from "../ButtonGroup";
import Button from "../core/Button";
import { SidePopupV2 } from "../DismissablePopup";
import DropdownV2 from "../core/DropdownV2";
import classnames from "classnames";
import DropdownButton, { DropdownItemContentProps } from "../DropdownButton";

const stopClickPropagation = (
  e: React.MouseEvent<HTMLDivElement, MouseEvent>
) => e.stopPropagation();

export interface BaseHeaderAction {
  // whether to optionally disable the action. Default: false
  disabled?: boolean;

  // optional class to add to the action button
  className?: string;

  // optionally wrap the action button in a side popup with the given text and class
  popupText?: string;
  popupClassName?: string;
}

// HeaderAction represents an action button in the header right hand side
export interface HeaderAction extends BaseHeaderAction {
  // text displayed in the action button
  text: string;

  // the callback for the action
  onClick: () => void;
}

const HeaderActionsInner: FC<HeaderAction> = ({
  text,
  onClick,
  disabled,
  className,
  popupText,
  popupClassName,
}) => {
  const button = (
    <Button
      key={text}
      onClick={onClick}
      disabled={disabled}
      className={className}
    >
      {text}
    </Button>
  );
  return popupText ? (
    <SidePopupV2
      position={"bottom"}
      className={popupClassName}
      text={popupText}
      noWrap
    >
      {button}
    </SidePopupV2>
  ) : (
    button
  );
};

// HeaderDotMenuAction is action to be display under a dot menu in the header right hand side
export interface HeaderDotMenuAction {
  // text for the action
  text: string;

  // action to perform on click
  onClick?: () => void;

  // optional icon to be displayed for the action
  icon?: string;

  // optional class for the action in the dropdown menu
  className?: string;
}

export interface SimpleHeaderActionsGroup {
  kind: "simple";

  // action buttons to show as part of the group
  actions: HeaderAction[];

  // extra actions to be displayed as part of a dropdown menu under a dots button
  // the dot menu button will always be the last button shown in an header button group
  dotMenuActions: HeaderDotMenuAction[];
}

const SimpleHeaderActionsGroupInner: FC<SimpleHeaderActionsGroup> = ({
  actions,
  dotMenuActions,
}) => {
  return (
    <ButtonGroup>
      {actions.map((a) => (
        <HeaderActionsInner key={a.text} {...a} />
      ))}
      {dotMenuActions.length > 0 && (
        <DropdownV2
          className="dot-menu"
          popupItem={
            <Button>
              <div className="cr-icon-dots-menu" />
            </Button>
          }
        >
          <div className="dot-menu-section" onClick={stopClickPropagation}>
            {dotMenuActions.map((a) => {
              return (
                <div
                  key={a.text}
                  className={classnames("dot-menu-item", a.className)}
                  onClick={a.onClick}
                >
                  {!!a.icon && (
                    <div className={classnames("menu-icon", a.icon)} />
                  )}
                  {a.text}
                </div>
              );
            })}
          </div>
        </DropdownV2>
      )}
    </ButtonGroup>
  );
};

export interface DropdownHeaderActionGroup extends BaseHeaderAction {
  kind: "dropdown";

  // text displayed in the action button
  text: string;

  // options for the dropdown
  options: {
    text: string;
    description?: string;
    onClick: () => void;
  }[];
}

const DropdownHeaderActionGroupInner: FC<DropdownHeaderActionGroup> = ({
  text,
  disabled,
  className,
  options,
}) => {
  const items: DropdownItemContentProps[] = options.map((o) => {
    return {
      headerText: o.text,
      description: o.description ?? "",
      hide: false,
      onClick: o.onClick,
    };
  });

  return (
    <>
      <DropdownButton
        title={text}
        disable={disabled}
        autoCloseOnMouseLeave={true}
        classname={className}
        items={items}
      ></DropdownButton>
    </>
  );
};

export type HeaderActionGroup =
  | SimpleHeaderActionsGroup
  | DropdownHeaderActionGroup;

export interface MainSectionProps {
  // the name to show in the header. This could be the vendor name, the org name or the app name.
  name?: string;

  // the domain to show in the header. This will generate a clickable link.
  domain?: string;

  // nodes to show beside the name in the header, e.g. verified badge or vendor score
  nameWidgets?: ReactNode[];

  // list of actions to be added to the right hand side of the main header section
  actionGroups: HeaderActionGroup[];
}

const MainSection: FC<MainSectionProps> = ({
  name = "",
  domain = "",
  nameWidgets,
  actionGroups,
}) => {
  return (
    <div className="header-main-section">
      <div className="name">
        {name}{" "}
        {domain && (
          <span className="domain">
            (
            <a
              href={`https://${domain}`}
              target="_blank"
              rel="nofollow noreferrer"
            >
              {domain}
            </a>
            )
          </span>
        )}
      </div>
      {nameWidgets?.map((w, idx) => (
        <div className={"name-widget"} key={idx}>
          {w}
        </div>
      ))}
      <div className="actions">
        {actionGroups.map((ag, idx) => {
          switch (ag.kind) {
            case "simple":
              // eslint-disable-next-line react/no-array-index-key
              return <SimpleHeaderActionsGroupInner key={idx} {...ag} />;
            case "dropdown":
              // eslint-disable-next-line react/no-array-index-key
              return <DropdownHeaderActionGroupInner key={idx} {...ag} />;
          }
        })}
      </div>
    </div>
  );
};

export default MainSection;
