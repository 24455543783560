import { FC } from "react";
import PillLabel from "../../vendorrisk/components/PillLabel";
import { LabelColor } from "../../_common/types/label";
import "./UserApprovalPill.scss";

export const userApprovalStatus = (approved: boolean, waived: boolean) => {
  if (waived) {
    return "Waived";
  } else if (approved) {
    return "Approved";
  }
  return "Not approved";
};

export const userApprovalColor = (approved: boolean, waived: boolean) => {
  if (waived) {
    return LabelColor.Grey;
  } else if (approved) {
    return LabelColor.Green;
  }
  return LabelColor.Red;
};

export const userApprovalIcon = (approved: boolean, waived: boolean) => {
  if (waived || approved) {
    return "cr-icon-accepted";
  }
  return "cr-icon-cancel";
};

const UserApprovalPill: FC<{ approved: boolean; waived: boolean }> = ({
  approved,
  waived,
}) => {
  const { pillText, pillIcon, pillColor } = userApprovalPillInfo(
    approved,
    waived
  );
  return (
    <PillLabel color={pillColor}>
      <div className="user-approval">
        <div className={pillIcon} />
        <div>{pillText}</div>
      </div>
    </PillLabel>
  );
};

// Export this function to assist with sorting for user approval pills when in a table
export function userApprovalPillInfo(
  approved: boolean,
  waived: boolean
): {
  pillText: string;
  pillIcon: string;
  pillColor: LabelColor;
} {
  if (waived) {
    return {
      pillText: "Waived",
      pillIcon: "cr-icon-accepted",
      pillColor: LabelColor.Grey,
    };
  }

  if (approved) {
    return {
      pillText: "Approved",
      pillIcon: "cr-icon-accepted",
      pillColor: LabelColor.Green,
    };
  }

  return {
    pillText: "Not approved",
    pillIcon: "cr-icon-cancel",
    pillColor: LabelColor.Red,
  };
}
export default UserApprovalPill;
