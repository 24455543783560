import { FC, useCallback, useState } from "react";
import Button from "../../_common/components/core/Button";
import "../style/GptSuggestionBox.scss";
import { addDefaultUnknownErrorAlert } from "../../_common/reducers/messageAlerts.actions";
import { trackEvent } from "../../_common/tracking";
import { appConnect, useAppDispatch } from "../../_common/types/reduxHooks";
import { SidePopupV2 } from "../../_common/components/DismissablePopup";

interface GptSuggestionBoxOwnProps {
  onAcceptReject: (accept: boolean) => Promise<void>;
  onClickDetails: () => void;
}

interface GptSuggestionBoxConnectedProps {
  isLocked: boolean;
}

type GptSuggestionBoxProps = GptSuggestionBoxOwnProps &
  GptSuggestionBoxConnectedProps;

const GptSuggestionBox: FC<GptSuggestionBoxProps> = ({
  onAcceptReject,
  onClickDetails,
  isLocked,
}) => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState<"accept" | "reject">();

  const acceptReject = useCallback(
    (accept: boolean) => {
      setLoading(accept ? "accept" : "reject");
      trackEvent("AiAutofillSuggestionUsed", {
        action: accept ? "accept" : "reject",
      });
      onAcceptReject(accept)
        .catch(() =>
          dispatch(addDefaultUnknownErrorAlert("error accepting suggestion"))
        )
        .finally(() => setLoading(undefined));
    },
    [dispatch, onAcceptReject, setLoading]
  );

  return (
    <div className={"gpt-suggestion-box"}>
      <SidePopupV2
        text={"Click here to view information about this suggestion."}
        position={"top"}
      >
        <div className={"header"} onClick={onClickDetails}>
          <i className={"cr-icon-stars"} />
          <div className={"header-text"}>suggestion</div>
          <i className={"cr-icon-chevron"} />
        </div>
      </SidePopupV2>
      <div className={"buttons"}>
        <Button
          loading={loading == "reject"}
          disabled={loading == "accept" || isLocked}
          danger
          onClick={() => acceptReject(false)}
          tight
        >
          <i className={"icon-x"} />
          Reject
        </Button>
        <Button
          loading={loading == "accept"}
          disabled={loading == "reject" || isLocked}
          green
          onClick={() => acceptReject(true)}
          tight
        >
          <i className={"cr-icon-check"} />
          Accept
        </Button>
      </div>
    </div>
  );
};

export default appConnect<
  GptSuggestionBoxConnectedProps,
  never,
  GptSuggestionBoxOwnProps
>((state) => {
  return {
    isLocked: state.surveyViewer.lock.isLocked,
  };
})(GptSuggestionBox);
