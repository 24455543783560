import { DefaultThunkDispatch } from "../../_common/types/redux";
import { DefaultRootState } from "react-redux";
import { FetchCyberRiskUrl } from "../../_common/api";
import { LogError } from "../../_common/helpers";
import { addDefaultUnknownErrorAlert } from "../../_common/reducers/messageAlerts.actions";

export interface OrgDefaultSenderEmailAddress {
  orgId: number;
  fromEmailAddress: string;
  fromName: string;
  useUserEmailAddresses: boolean;
  isActive: boolean;
}

export interface PostmarkDomainDetail {
  SPFVerified: boolean;
  SPFHost: string;
  SPFTextValue: string;
  DKIMVerified: boolean;
  WeakDKIM: boolean;
  DKIMHost: string;
  DKIMTextValue: string;
  DKIMPendingHost: string;
  DKIMPendingTextValue: string;
  DKIMRevokedHost: string;
  DKIMRevokedTextValue: string;
  SafeToRemoveRevokedKeyFromDNS: boolean;
  DKIMUpdateStatus: string;
  ReturnPathDomain: string;
  ReturnPathDomainVerified: boolean;
  ReturnPathDomainCNAMEValue: string;
  ID: number;
}

export interface OrgSenderEmailDomain {
  orgId: number;
  postmarkDomainId: number;
  emailDomain: string;
  isVerified: boolean;
  latestPostmarkApiDomainDetail: PostmarkDomainDetail;
}

export interface OrganisationSenderEmailV1Resp {
  currentDefaultSenderEmailAddress?: OrgDefaultSenderEmailAddress;
  emailDomains?: OrgSenderEmailDomain[];
}

export enum VerificationType {
  DKIM,
  ReturnPath,
}

export const fetchOrgSenderEmail = () => {
  return async (
    dispatch: DefaultThunkDispatch,
    getState: () => DefaultRootState
  ) => {
    let resp: OrganisationSenderEmailV1Resp;
    try {
      resp = await FetchCyberRiskUrl<OrganisationSenderEmailV1Resp>(
        "account/org_sender_email/v1/",
        {},
        { method: "GET" },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("error retrieving organisation custom email", e);
      dispatch(
        addDefaultUnknownErrorAlert(
          "Error retrieving organization custom email"
        )
      );

      throw e;
    }

    return resp;
  };
};

export const updateOrgSenderEmail = (
  fromEmailAddress: string,
  fromName: string,
  useUserEmailAddress: boolean,
  emailDomains: string[],
  isActive: boolean
) => {
  return async (
    dispatch: DefaultThunkDispatch,
    getState: () => DefaultRootState
  ) => {
    let resp: OrganisationSenderEmailV1Resp;
    try {
      resp = await FetchCyberRiskUrl<OrganisationSenderEmailV1Resp>(
        "account/org_sender_email/v1/",
        {},
        {
          method: "PUT",
          body: JSON.stringify({
            fromEmailAddress,
            fromName,
            useUserEmailAddress,
            emailDomains,
            isActive,
          }),
        },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("error updating organisation custom email", e);
      dispatch(
        addDefaultUnknownErrorAlert("Error updating organization custom email")
      );

      throw e;
    }

    return resp;
  };
};

export const verifyOrgEmailDomain = (
  domain: string,
  vType: VerificationType
) => {
  return async (
    dispatch: DefaultThunkDispatch,
    getState: () => DefaultRootState
  ) => {
    let resp: OrganisationSenderEmailV1Resp;
    const verifyUrl =
      vType === VerificationType.DKIM
        ? "account/org_sender_email/verify_dkim/v1/"
        : "account/org_sender_email/verify_return_path/v1/";
    try {
      resp = await FetchCyberRiskUrl<OrganisationSenderEmailV1Resp>(
        verifyUrl,
        {
          domain,
        },
        {
          method: "PUT",
        },
        dispatch,
        getState
      );
    } catch (e) {
      LogError(
        "error checking verification status for organisation custom email",
        e
      );
      dispatch(
        addDefaultUnknownErrorAlert(
          "Error checking verification status for organization custom email"
        )
      );

      throw e;
    }

    return resp;
  };
};

export const deleteOrgSenderEmail = () => {
  return async (
    dispatch: DefaultThunkDispatch,
    getState: () => DefaultRootState
  ) => {
    try {
      await FetchCyberRiskUrl(
        "account/org_sender_email/v1/",
        {},
        {
          method: "DELETE",
        },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("error deleting organisation custom email", e);
      dispatch(
        addDefaultUnknownErrorAlert("Error deleting organization custom email")
      );

      throw e;
    }
  };
};

export const updateOrgSenderEmailReturnPath = (
  domain: string,
  returnPath: string
) => {
  return async (
    dispatch: DefaultThunkDispatch,
    getState: () => DefaultRootState
  ) => {
    let resp: OrganisationSenderEmailV1Resp;
    try {
      resp = await FetchCyberRiskUrl<OrganisationSenderEmailV1Resp>(
        "account/org_sender_email/return_path/v1/",
        {
          domain,
          return_path: returnPath,
        },
        {
          method: "PUT",
        },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("error updating organisation custom email return path", e);
      dispatch(
        addDefaultUnknownErrorAlert(
          "Error updating organization custom email return path"
        )
      );

      throw e;
    }

    return resp;
  };
};

export const rotateDkimOrgSenderEmail = (domain: string) => {
  return async (
    dispatch: DefaultThunkDispatch,
    getState: () => DefaultRootState
  ) => {
    let resp: OrganisationSenderEmailV1Resp;
    try {
      resp = await FetchCyberRiskUrl<OrganisationSenderEmailV1Resp>(
        "account/org_sender_email/dkim_rollover/v1/",
        {
          domain,
        },
        {
          method: "POST",
        },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("error rotating dkim for organisation custom email", e);
      dispatch(
        addDefaultUnknownErrorAlert(
          "Error rotating DKIM for organization custom email"
        )
      );

      throw e;
    }

    return resp;
  };
};
