import { Component } from "react";
import PropTypes from "prop-types";

import Button from "../../../_common/components/core/Button";

import { closeModal } from "../../../_common/reducers/commonActions";
import { setCompanyDetails } from "../../reducers/cyberRiskActions";
import {
  addDefaultUnknownErrorAlert,
  addSimpleErrorAlert,
  clearMessageAlert,
} from "../../../_common/reducers/messageAlerts.actions";

export const EditCompanyDetailsModalName = "EditCompanyDetailsModalName";

// Very very basic URL validator that just checks that there is a dot present and no spaces
const basicUrlValidate = (url) => {
  if (!url.length) return false;

  if (url.indexOf(".") === -1) return false;

  if (url.indexOf(" ") > -1) return false;

  return true;
};

/**
 * <EditCompanyDetailsModal />
 * Modal form popped up from a vendor or customer page to allow a user to edit the name and primary hostname of their company.
 */
class EditCompanyDetailsModal extends Component {
  static propTypes = {
    modalData: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
  };

  state = { loading: false };

  onSubmit = (evt) => {
    evt.preventDefault();
    let newUrl = this.urlInput.value;

    if (!basicUrlValidate(newUrl)) {
      this.props.dispatch(
        addSimpleErrorAlert("Invalid URL entered", [], "company-edit-error")
      );
      return;
    }

    if (newUrl === this.props.modalData.mainHostname) {
      // null it out so we don't have to refresh all the data again
      newUrl = null;
    }

    this.props.dispatch(clearMessageAlert("company-edit-error"));

    this.setState({ loading: true });

    this.props
      .dispatch(setCompanyDetails(this.nameInput.value, newUrl))
      .then(() => this.props.dispatch(closeModal()))
      .catch((err) => {
        this.setState({ loading: false });
        this.props.dispatch(
          addDefaultUnknownErrorAlert("Error updating company details", [
            err.message,
          ])
        );
      });
  };

  render() {
    return (
      <div className="modal-content">
        <h2>Edit company details</h2>
        <p>
          Edit your company name and the domain of your company&apos;s main
          website. This will affect the list of websites and company
          information.
        </p>
        <form onSubmit={this.onSubmit}>
          <label htmlFor="name">Company name</label>
          <input
            name="name"
            type="text"
            ref={(ref) => (this.nameInput = ref)}
            required
            defaultValue={this.props.modalData.orgName}
            placeholder="Company Name Inc."
          />
          <label htmlFor="url">Company domain</label>
          <input
            name="url"
            type="text"
            ref={(ref) => (this.urlInput = ref)}
            required
            defaultValue={this.props.modalData.mainHostname}
            placeholder="your-main-business-url.com"
          />
          <div className="btn-group">
            <Button
              type="submit"
              primary
              loading={this.state.loading}
              disabled={this.state.loading}
            >
              Save
            </Button>
          </div>
        </form>
      </div>
    );
  }
}

export default EditCompanyDetailsModal;
