import { DefaultThunkDispatchProp } from "../../_common/types/redux";
import { FC, useEffect, useState } from "react";
import {
  addSurveyMessage,
  editSurveyMessage,
  fetchSurveyMessages,
} from "../../_common/reducers/surveyDetails.actions";
import MessagesPanel, {
  MessageGroup,
} from "../../_common/components/MessagesPanel";
import { IUserMiniMap } from "../../_common/types/user";
import {
  FlattenedNodeSummaryMap,
  getDefaultTextForNodeType,
  NodeSummaryAndNode,
} from "../surveyViewer.helpers";
import { addDefaultUnknownErrorAlert } from "../../_common/reducers/messageAlerts.actions";
import "../style/SurveyViewerComments.scss";
import {
  setCurrentCommentQuestion,
  setQuestionComments,
  setScrollTargetNode,
} from "../reducers/actions";
import {
  ICorrespondenceMessage,
  SurveyQuestionMessageMeta,
} from "../../_common/types/correspondenceMessage";
import {
  countUnreadMessages,
  setViewedInUI,
  updateMessageContent,
} from "../../_common/components/correspondence/correspondence.helpers";
import classnames from "classnames";
import Button, { TooltipButton } from "../../_common/components/core/Button";
import {
  GENERAL_GROUP_ID,
  getGroupsForMessages,
} from "../../_common/components/surveydetails/surveyDetails.helpers";
import Icon from "../../_common/components/core/Icon";
import NodeTypeIcon, {
  NodeTypeIconType,
} from "../../survey_builder/components/NodeTypeIcon";
import { separateQuestionNumberFromQuestion } from "../../_common/components/surveydetails/SurveyDetailsUnansweredQuestionsCard";
import { SurveyViewerRightPanelHeader } from "./SurveyViewerRightPanel";

interface SurveyViewerCommentsProps extends DefaultThunkDispatchProp {
  panelActive: boolean;
  questionId: string | undefined;
  surveyId: number;
  otherVendorName: string;
  flattenedNodeMap: FlattenedNodeSummaryMap;
  privateMessages: boolean;
  hasWritePermission: boolean;
  orgName: string;
  currentUserId: number;
  isQuestionVisible: boolean;
  diffModeLocked: boolean;
  privateMessagesOnly: boolean;
  isCollaborator?: boolean;
}

// Component for rendering comments for a particular question in the survey viewer
const SurveyViewerComments: FC<SurveyViewerCommentsProps> = ({
  dispatch,
  panelActive,
  surveyId,
  otherVendorName,
  flattenedNodeMap,
  questionId,
  currentUserId,
  hasWritePermission,
  privateMessages,
  orgName,
  isQuestionVisible,
  diffModeLocked,
  privateMessagesOnly,
  isCollaborator,
}) => {
  const [users, setUsers] = useState<IUserMiniMap | undefined>(undefined);
  const [messages, setMessages] = useState<
    ICorrespondenceMessage<SurveyQuestionMessageMeta>[] | undefined
  >(undefined);

  // Add a new message, add it to the local message state and mark it as read on the backend
  const addNewSurveyMessage = (
    parentId: number | undefined,
    content: string,
    isPrivate: boolean,
    questionId?: string
  ) => {
    return dispatch(
      addSurveyMessage(surveyId, parentId, content, isPrivate, questionId)
    )
      .then((resp) => {
        const existingMessages = messages ? [...messages] : [];

        if (!parentId) {
          existingMessages.unshift(resp.message);
        } else {
          const parent = existingMessages.find((p) => p.id === parentId);
          if (parent) {
            const children = parent.children ?? [];
            children.unshift(resp.message);
            parent.children = children;
          }
        }

        // Update changed message/users state, refreshing the comment panel
        setMessages(existingMessages);
        if (!users || users[resp.user.id] === undefined) {
          const currentUsers = users ?? {};
          setUsers({
            ...currentUsers,
            [resp.user.id]: resp.user,
          });
        }

        // Fire off a fetch to mark as read
        dispatch(
          fetchSurveyMessages(
            surveyId,
            true,
            false,
            questionId,
            questionId === undefined,
            true
          )
        );
      })
      .catch(() =>
        dispatch(addDefaultUnknownErrorAlert("Error adding new message"))
      );
  };

  const [groups, setGroups] = useState<MessageGroup[] | undefined>(
    messages ? getGroupsForMessages(messages, addNewSurveyMessage) : undefined
  );

  // On initial mount, lets get all comments for the survey
  useEffect(() => {
    if (surveyId && messages === undefined) {
      dispatch(
        fetchSurveyMessages(surveyId, true, true, undefined, undefined, true)
      )
        .then((resp) => {
          setMessages(resp.messages);
          setUsers(resp.users);

          if (countUnreadMessages(messages) > 0 && !questionId) {
            dispatch(setCurrentCommentQuestion(undefined, true));
          }
        })
        .catch(() => {
          dispatch(
            addDefaultUnknownErrorAlert(
              "Unable to retrieve questionnaire comments"
            )
          );
        });
    }
  }, [surveyId, messages]);

  // Clear messages on unmount so we re-get on load
  // Also, hide panel and reset current questionId
  useEffect(() => {
    return () => {
      setMessages(undefined);
      // dispatch(setCurrentCommentQuestion(undefined, false));
    };
  }, []);

  // Adjust groups if messages changes. Also update the viewer redux cache so the comment numbers update in the main ui.
  useEffect(() => {
    if (messages) {
      setGroups(getGroupsForMessages(messages, addNewSurveyMessage));

      // Send to redux for main survey viewer area
      dispatch(setQuestionComments(messages));
    }
  }, [messages]);

  // Check if we need to create a new group on incoming question id
  useEffect(() => {
    const groupForQuestion = groups?.find((g) => g.id === questionId);
    const questionNode = questionId ? flattenedNodeMap[questionId] : undefined;

    if (
      questionId &&
      questionNode &&
      groups &&
      !groupForQuestion &&
      flattenedNodeMap
    ) {
      const questionNode = flattenedNodeMap[questionId];
      const newGroup = getNewGroupForQuestionNode(
        questionNode,
        addNewSurveyMessage
      );
      setGroups([...groups, newGroup]);
    }
  }, [questionId, groups, flattenedNodeMap]);

  // Clear any empty groups if group is not current (panel might be triggered from elsewhere)
  useEffect(() => {
    if (panelActive) {
      setGroups((groups) => {
        return (
          groups?.filter((g) => g.messages.length > 0 || g.id === questionId) ??
          []
        );
      });
    }
  }, [panelActive, questionId]);

  const navigateToQuestion = (questionId: string) => {
    dispatch(setScrollTargetNode(questionId, true));
  };

  // Get the survey viewer question data for the message group (if question group)
  const questionNode = questionId ? flattenedNodeMap[questionId] : undefined;

  const currentGroup = questionId
    ? groups?.find((g) => g.id === questionId)
    : undefined;

  const emptyContent = getEmptyContent(
    orgName,
    hasWritePermission,
    diffModeLocked,
    privateMessages,
    privateMessagesOnly
  );

  return (
    <>
      <SurveyViewerRightPanelHeader>
        <div className={"controls"}>
          <div className={"title-content"}>
            {currentGroup && (
              <div className={"group-back"}>
                <Button
                  tertiary
                  onClick={() => {
                    dispatch(setCurrentCommentQuestion(undefined, true));

                    // Clear any temporary group(s)
                    if (groups) {
                      const newGroups = groups.filter(
                        (g) => g.messages.length > 0
                      );
                      setGroups(newGroups);

                      // If there are no groups then hide the panel
                      if (newGroups.length === 0) {
                        dispatch(setCurrentCommentQuestion(questionId, false));
                      }
                    }
                  }}
                >
                  <i className={"cr-icon-chevron rot180"} /> Messages /{" "}
                  {getGroupNumForQuestionNode(questionNode)}
                </Button>
              </div>
            )}
            {!currentGroup && <>Messages</>}
          </div>
          <Button
            tertiary
            onClick={() =>
              dispatch(setCurrentCommentQuestion(questionId, false))
            }
          >
            <Icon name="x" className="close" />
          </Button>
        </div>
        {currentGroup && questionNode && (
          <div className={"group-title"}>
            {getGroupTitleForQuestionNode(
              questionNode,
              navigateToQuestion,
              isQuestionVisible
            )}
          </div>
        )}
      </SurveyViewerRightPanelHeader>
      <MessagesPanel
        className="survey-viewer-comments-messages-panel"
        currentUserId={currentUserId}
        hasWritePermission={
          (hasWritePermission || !!isCollaborator) && !diffModeLocked
        }
        otherVendorName={otherVendorName}
        allowedPrivateMessages={privateMessages}
        privateMessagesOnly={privateMessagesOnly || !!isCollaborator}
        publicReplyHelp={
          "Public messages will be visible to members of your account as well as any users added to this questionnaire."
        }
        editMessage={(messageId, newContent) => {
          return dispatch(editSurveyMessage(messageId, newContent))
            .then(() => {
              const existingMessages = messages ? [...messages] : [];

              updateMessageContent(existingMessages, messageId, newContent);

              setMessages(existingMessages);
            })
            .catch(() =>
              dispatch(addDefaultUnknownErrorAlert("Error editing message"))
            );
        }}
        addMessage={(parentId, content, isPrivate) =>
          addNewSurveyMessage(parentId, content, isPrivate)
        }
        users={users}
        emptyContent={emptyContent}
        getClassForMessage={(
          message: ICorrespondenceMessage<SurveyQuestionMessageMeta>
        ) =>
          classnames({
            "system-generated": message.meta?.isQuestionAnswer,
          })
        }
        groups={groups}
        currentGroup={currentGroup}
        onGroupSelect={(group) => {
          if (group?.id !== currentGroup?.id) {
            dispatch(setCurrentCommentQuestion(group?.id, true));

            if (
              group &&
              group.id !== GENERAL_GROUP_ID &&
              group.messages.length > 0
            ) {
              dispatch(
                setScrollTargetNode(group.messages[0].meta.questionId, true)
              );
            }
          }

          if (group && countUnreadMessages(group.messages) > 0) {
            // Mark groups messages as read
            dispatch(
              fetchSurveyMessages(
                surveyId,
                true,
                false,
                group.id !== GENERAL_GROUP_ID ? group.id : undefined,
                group.id === GENERAL_GROUP_ID,
                true
              )
            ).then((resp) => {
              // Update seen state
              const allMessages = messages ? [...messages] : [];
              setViewedInUI(allMessages, resp.messages);
              setMessages(allMessages);
            });
          }
        }}
      />
    </>
  );
};

export default SurveyViewerComments;

const getNewGroupForQuestionNode = (
  questionNode: NodeSummaryAndNode,
  addNewSurveyMessage: (
    parentId: number | undefined,
    content: string,
    isPrivate: boolean,
    questionId?: string
  ) => Promise<any>
) => {
  const [num, title] = separateQuestionNumberFromQuestion(
    questionNode.titleOrMainText
  );

  const group: MessageGroup = {
    id: questionNode.nodeId,
    hideReply: true,
    messages: [],
    headerContent: (
      <NodeTypeIcon
        nodeType={questionNode.nodeType}
        questionNumber={
          questionNode.nodeType === NodeTypeIconType.Risk ? "Risk" : num
        }
      />
    ),
    content: <div dangerouslySetInnerHTML={{ __html: title }} />,
    onAddGroupMessage: (parentId, content, isPrivate) =>
      addNewSurveyMessage(parentId, content, isPrivate, questionNode.nodeId),
  };

  return group;
};

const getGroupTitleForQuestionNode = (
  questionNode: NodeSummaryAndNode | undefined,
  navigateToQuestion: (questionId: string) => void,
  isQuestionVisible: boolean
) => {
  if (!questionNode) {
    return undefined;
  } else {
    const [_, title] = separateQuestionNumberFromQuestion(
      questionNode.node.mainTextSanitised ?? questionNode.node.mainText
    );

    let titleContent = title;
    if (!titleContent) {
      titleContent = getDefaultTextForNodeType(questionNode.node.type);
    }

    return isQuestionVisible ? (
      <Button
        className={"question-link-btn"}
        link
        onClick={() => navigateToQuestion(questionNode.nodeId)}
      >
        <div dangerouslySetInnerHTML={{ __html: titleContent }} />
      </Button>
    ) : (
      <TooltipButton
        className={"question-link-btn"}
        disabled
        link
        popupPosition={"bottom"}
        tooltipContent={
          "This question is no longer visible in the questionnaire"
        }
      >
        <div dangerouslySetInnerHTML={{ __html: titleContent }} />
      </TooltipButton>
    );
  }
};

const getGroupNumForQuestionNode = (questionNode?: NodeSummaryAndNode) => {
  if (!questionNode) {
    return "General";
  } else if (questionNode.nodeType == NodeTypeIconType.Risk) {
    return "Risk";
  } else {
    let [num] = separateQuestionNumberFromQuestion(
      questionNode.titleOrMainText
    );

    if (!num) {
      num = getDefaultTextForNodeType(questionNode.node.type);
    }

    return num;
  }
};

const getEmptyContent = (
  orgName: string,
  hasWritePermission: boolean,
  diffModeLocked: boolean,
  privateMessagesAllowed: boolean,
  privateMessagesOnly: boolean
): React.ReactNode => {
  if (!hasWritePermission) {
    return undefined;
  }

  if (diffModeLocked) {
    return (
      <>
        <p>There are no messages here yet</p>
        <p>
          Sending messages is disabled in changes view unless you are comparing
          to the latest version
        </p>
      </>
    );
  } else if (privateMessagesAllowed) {
    if (privateMessagesOnly) {
      return (
        <>
          <p>There are no messages here yet.</p>
          <p>
            This risk is not shown to recipients and cannot have public
            messages.
          </p>
        </>
      );
    } else {
      return (
        <>
          <p>
            There are no messages here yet. Use the box below to send a message
            regarding this question. Any recipients of the questionnaire will be
            notified.
          </p>
          <p>
            Alternatively, you can add an internal note that can only be seen by
            you and members of your organization.
          </p>
        </>
      );
    }
  } else {
    return (
      <>
        <p>
          There are no messages here yet. Use the box below to send a message
          regarding this question.
        </p>
        <p>
          The people at {orgName} who sent you this questionnaire will be
          notified.
        </p>
      </>
    );
  }
};
