import { memo, useEffect, useRef, useState } from "react";
import classnames from "classnames";

import "../../style/components/automation/AddRuleButton.scss";

interface IAddRuleButtonProps {
  includeSubRule: boolean;
  onSelectOption: (option: string) => void;
}

const NEWTEST = "Add rule";
const NEWSUBRULE = "Add nested rule";

// AddRuleButton renders a button that can be used to add a new rule to a simple automation
const AddRuleButton = (props: IAddRuleButtonProps) => {
  const [popupOpen, setPopupOpen] = useState(false);
  const [hoveringOverType, setHoveringOverType] = useState<string | undefined>(
    undefined
  );

  // Use a ref to store our click handler so we can maintain a reference to it between renders.
  const clickHandlerRef = useRef<EventListener>();

  const togglePopup = (open: boolean) => {
    if (open) {
      // Add an event listener on the document that will set the popup to closed
      clickHandlerRef.current = (ev: Event) => {
        // Don't do anything if we've clicked inside the popup.
        if ((ev.target as Element).closest(".add-btn-popup")) {
          return;
        }

        togglePopup(false);
      };
      document.addEventListener("click", clickHandlerRef.current);
      setPopupOpen(true);
    } else {
      // Remove the click handler on the document
      if (clickHandlerRef.current) {
        document.removeEventListener(
          "click",
          clickHandlerRef.current as EventListener
        );
      }
      setPopupOpen(false);
    }
  };

  useEffect(() => {
    // Clean up the click handler on unmount
    return () => {
      if (clickHandlerRef.current) {
        document.removeEventListener(
          "click",
          clickHandlerRef.current as EventListener
        );
      }
    };
  }, []);

  return (
    <div className={"add-btn-wrapper"}>
      <div
        className="add-btn"
        onClick={(e) => {
          e.stopPropagation();
          togglePopup(!popupOpen);
        }}
      />
      {popupOpen && (
        <div className="add-btn-popup">
          <div
            className="add-btn-popup-main"
            onMouseLeave={() => setHoveringOverType(undefined)}
          >
            <div
              onClick={(e) => {
                e.stopPropagation();
                togglePopup(false);
                props.onSelectOption("add-test");
              }}
              onMouseEnter={() => setHoveringOverType("add-test")}
            >
              {NEWTEST}
            </div>
            {props.includeSubRule && (
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  togglePopup(false);
                  props.onSelectOption("add-subrule");
                }}
                onMouseEnter={() => setHoveringOverType("add-subrule")}
              >
                {NEWSUBRULE}
              </div>
            )}
          </div>
          {hoveringOverType !== undefined && (
            <div
              className={classnames("add-btn-popup-help", {
                "first-hover": hoveringOverType === "add-test",
                "last-hover": hoveringOverType === "add-subrule",
              })}
            >
              {hoveringOverType === "add-test" && (
                <>
                  <div className="heading">{NEWTEST}</div>A rule tests that a
                  specific question has a specific answer.
                </>
              )}
              {hoveringOverType === "add-subrule" && (
                <>
                  <div className="heading">{NEWSUBRULE}</div>A nested rule
                  allows the answers to several questions to be logically
                  combined.
                </>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default memo(AddRuleButton);
