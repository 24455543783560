import { Component } from "react";
import PropTypes from "prop-types";
import { isNumber as _isNumber } from "lodash";
import { XTableCell } from "../../../_common/components/core/XTable";
import XTable from "../../../_common/components/core/XTable";
import DateTimeFormat from "../../../_common/components/core/DateTimeFormat";
import moment from "moment";
import classnames from "classnames";

import {
  fetchCustomerLookupsData,
  updateVendorWatchStatus,
  updateVendorScoredStatus,
} from "../../reducers/cyberRiskActions";
import { closeModal } from "../../../_common/reducers/commonActions";
import { TruncateLargeNumber } from "../../../_common/helpers";
import CSTARIcon from "../CSTARIcon";
import {
  GetCSTARScoreColorClass,
  DateStringToUnixDateWithoutTimezone,
} from "../../../_common/helpers";
import CyberTrendScore from "../CyberTrendScore";

import "../../style/components/VendorLookupsModal.scss";
import {
  addDefaultSuccessAlert,
  addDefaultUnknownErrorAlert,
} from "../../../_common/reducers/messageAlerts.actions";

export const VendorLookupsModalName = "VenddorLookupsModalName";

class VendorLookupsModal extends Component {
  static propTypes = {
    modalData: PropTypes.shape({
      history: PropTypes.object.isRequired,
      vendorLookupLimit: PropTypes.number.isRequired,
      vendorLookupCount: PropTypes.number.isRequired,
      billingPeriodEndDate: PropTypes.string.isRequired,
    }).isRequired,
    dispatch: PropTypes.func.isRequired,
  };

  state = {
    loading: true,
    lookups: [],
  };

  UNSAFE_componentWillMount() {
    this.props
      .dispatch(fetchCustomerLookupsData())
      .then((lookups) => this.setState({ lookups, loading: false }))
      .catch(() => {
        this.props.dispatch(
          addDefaultUnknownErrorAlert("Error fetching list of snapshots")
        );
        this.props.dispatch(closeModal());
      });
  }

  goToVendorPage = (vendorId) => {
    this.props.modalData.history.push(`/vendor/${vendorId}`, {
      from: "portfolio",
    });
    this.props.dispatch(closeModal());
  };

  watchOrUseVendor = (vendorId, name, used) => {
    const prom = used
      ? this.props.dispatch(updateVendorScoredStatus(vendorId, true))
      : this.props.dispatch(updateVendorWatchStatus(vendorId, true));

    prom.then((success) => {
      if (success) {
        // Remove the vendor from this table and show a success whisper
        this.setState(({ lookups }) => {
          const newLookups = [];
          for (let i = 0; i < lookups.length; i++) {
            if (lookups[i].vendorId !== vendorId) {
              newLookups.push(lookups[i]);
            }
          }
          return { lookups: newLookups };
        });

        this.props.dispatch(
          addDefaultSuccessAlert(`Started following ${name}`)
        );
      }
    });
  };

  getRows = () => {
    if (!this.state.lookups || this.state.lookups.length === 0) {
      return [];
    }

    let maxRange = 0;

    const formattedLookups = this.state.lookups.map((lookup) => {
      let min = 1000;
      let max = 0;

      const scores = lookup.scores.map((score) => {
        // Find the max and min scores so we can calculate the range
        if (_isNumber(score.overall_score) && score.overall_score < min) {
          min = score.overall_score;
        }

        if (_isNumber(score.overall_score) && score.overall_score > max) {
          max = score.overall_score;
        }

        // Return scores in a format digestible by ChartJS
        return {
          x: DateStringToUnixDateWithoutTimezone(score.time),
          y: score.overall_score,
        };
      });

      const range = max - min;
      if (range > maxRange) {
        maxRange = range;
      }

      // Also store the midpoint
      const scoresMidpoint = (max + min) / 2;

      return {
        ...lookup,
        scoresMidpoint,
        scores,
      };
    });

    let range = maxRange / 2; // Divide by two as we'll be adding/subtracting from midpoints
    range = Math.round(range * 1.1); // Give it 10% padding to avoid charts getting cut off

    const now = moment();

    return formattedLookups.map((lookup) => {
      const daysLeft = Math.ceil(
        moment(lookup.lookupDate).add(30, "days").diff(now, "days", true)
      );
      return {
        id: lookup.vendorId,
        meatballOptions: [
          {
            id: "start-following",
            text: "Follow this vendor",
            onClick: () =>
              this.watchOrUseVendor(lookup.vendorId, lookup.name, false),
          },
        ],
        onClick: () => this.goToVendorPage(lookup.vendorId),
        cells: [
          <XTableCell key="name">{lookup.name}</XTableCell>,
          <XTableCell key="hostname">{lookup.primaryDomain}</XTableCell>,
          <XTableCell key="score" className="cstar-score-cell">
            {lookup.latestScore >= 0 && (
              <CSTARIcon
                className={GetCSTARScoreColorClass(lookup.latestScore)}
              />
            )}
            {lookup.latestScore >= 0 && lookup.latestScore}
          </XTableCell>,
          <XTableCell key="trend" className="history-cell">
            <div className="cyber-trend">
              <CyberTrendScore trend={lookup.trend} />
            </div>
          </XTableCell>,
          <XTableCell key="days_left" className="days-left-cell">
            {daysLeft}&nbsp;
            {daysLeft === 1 ? "day left to view" : "days left to view"}
          </XTableCell>,
        ],
      };
    });
  };

  render() {
    const vendorLookupsRemaining = Math.max(
      this.props.modalData.vendorLookupLimit -
        this.props.modalData.vendorLookupCount,
      0
    );
    const lookupThresholdBreach = vendorLookupsRemaining <= 0;
    const instantReportsAvailable = this.state.lookups.length;

    return (
      <div className="vendor-lookups-modal">
        <h2>Snapshots</h2>
        <div className="instant-reports-usage">
          <div className="instant-reports-active">
            <div>
              <span className="focus-number">{instantReportsAvailable}</span>
              &nbsp;
              {instantReportsAvailable === 1
                ? "INSTANT REPORT"
                : "INSTANT REPORTS"}{" "}
              ACTIVE
            </div>
          </div>
          <div className="instant-reports-remaining">
            <div>
              <span
                className={classnames("focus-number", {
                  red: lookupThresholdBreach,
                })}
              >
                {TruncateLargeNumber(vendorLookupsRemaining)}
              </span>
              &nbsp;/{" "}
              {TruncateLargeNumber(this.props.modalData.vendorLookupLimit)}{" "}
              &nbsp;INSTANT REPORTS REMAINING
            </div>
            {!this.props.modalData.vendorLookupIsOneTime && (
              <div className="instant-reports-renewal-date">
                RENEWS ON
                <span
                  className={classnames("focus-number", {
                    red: lookupThresholdBreach,
                  })}
                >
                  &nbsp;
                  <span className="focus-number">
                    <DateTimeFormat
                      dateTime={DateStringToUnixDateWithoutTimezone(
                        this.props.modalData.billingPeriodEndDate
                      )}
                      dateOnly
                    />
                  </span>
                </span>
              </div>
            )}
          </div>
        </div>
        <XTable
          className="vendor-list"
          meatballs
          loading={this.state.loading}
          loadingRows={5}
          columnHeaders={[
            { id: "name", text: "Vendor", sortable: false },
            { id: "hostname", text: "Main website", sortable: false },
            { id: "score", text: "Score", sortable: false },
            { id: "trend", text: "Trend", sortable: false },
            { id: "days_left", text: "", sortable: false },
          ]}
          rows={this.getRows()}
        />
      </div>
    );
  }
}

export default VendorLookupsModal;
