import { Component } from "react";

import PropTypes from "prop-types";
import moment from "moment";
import Card from "../../_common/components/core/Card";
import LoadingBanner from "../../_common/components/core/LoadingBanner";
import {
  cvssToText,
  cvssToTextCapitalized,
  formatDate,
  severityMap,
} from "../../_common/helpers";
import { fetchVuln } from "../reducers/cyberRiskActions";
import ScrollableDiv from "./ScrollableDiv";
import { CPELabel } from "./CPELabel";

import "../style/components/VulnPanel.scss";
import { SlidePanelSection } from "./filter/SlidePanelSection";
import BackArrow from "../../_common/components/BackArrow";
import Button from "../../_common/components/core/Button";
import InfoBanner, { BannerType } from "./InfoBanner";
import KnownExploitedVulnPill from "./KnownExploitedVulnPill";
import PillLabel from "./PillLabel";
import { LabelColor } from "../../_common/types/label";
import { appConnect } from "../../_common/types/reduxHooks";
import classNames from "classnames";
import VulnAssetsAffected from "./VulnAssetsAffected";

export const VulnDataToVulnPanelTitle = (vulnData) => {
  return (
    vulnData.cve_name +
    (vulnData.friendly_name ? ` (${vulnData.friendly_name})` : "")
  );
};

class VulnPanel extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    error: PropTypes.object,
    cveName: PropTypes.string.isRequired,
    vulnData: PropTypes.object,
    onClickBack: PropTypes.func,
    backButtonLabel: PropTypes.string,
    onRequestRemediationForVuln: PropTypes.func,
    tpvmSession: PropTypes.object,
    vendorId: PropTypes.number,
    isSubsidiary: PropTypes.bool,
    verified: PropTypes.string,
    isManagedVendorAnalyst: PropTypes.bool,
    managedOrgId: PropTypes.number,
    onClickAsset: PropTypes.func, // (currentPanelTitle: string, asset: string) => void;
  };

  static defaultProps = {
    loading: true,
    error: null,
    onClickBack: null,
    onRequestRemediationForVuln: null,
    backButtonLabel: "",
    vulnData: {},
    tpvmSession: {},
    verified: "false",
  };

  constructor(props) {
    super(props);

    if (props.cveName) {
      props.dispatch(fetchVuln(props.cveName));
    }
  }

  state = {
    summaryExpanded: true,
    riskDetailsExpanded: false,
    recommendedRemediationExpanded: false,
    cveDetailsExpanded: true,
    referencesExpanded: true,
    versionsExpanded: true,
    affectedAssetsExpanded: true,
    affectedAssetsCount: 0,
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.cveName !== this.props.cveName) {
      this.props.dispatch(fetchVuln(this.props.cveName));
    }
  }

  render() {
    const { loading, error, vulnData } = this.props;
    if (error) {
      const { errorText, actionText, actionOnClick } = error;
      return (
        <Card error className="vuln-slide-panel-error-card">
          <div className="card-content">
            <div>{errorText}</div>
            {actionText && (
              <a className="btn btn-default" onClick={actionOnClick}>
                {actionText}
              </a>
            )}
          </div>
        </Card>
      );
    }

    if (loading) {
      return (
        <div className="loading-overlay show">
          <LoadingBanner />
        </div>
      );
    }

    const {
      cve_name,
      friendly_name,
      cvss,
      cvss_version,
      summary,
      data,
      epss,
      exploitedDateAdded,
      exploitedDateRemoved,
      risk_details,
      recommended_remediation,
    } = vulnData;

    if (!cve_name) {
      return null;
    }

    // label the CVSS score as v2 or v3 (might not be set for a short period
    // after next release while all existing CVEs have the CVSS version set)
    let cvssLabel = "CVSS";
    if (cvss_version === "v2") {
      cvssLabel = "CVSS V2";
    } else if (cvss_version === "v3") {
      cvssLabel = "CVSS V3";
    }

    const { published, references, vulnerable_cpes } = data;
    return (
      <div className="vuln-panel">
        {!!this.props.onClickBack && (
          <BackArrow
            popup={this.props.backButtonLabel || "Back"}
            onClick={this.props.onClickBack}
          />
        )}
        <div className="vuln-title">
          <div className={"title-block"}>
            <h3>{VulnDataToVulnPanelTitle(vulnData)}</h3>
          </div>
          {!!this.props.onRequestRemediationForVuln &&
            (!this.props.tpvmSession || !this.props.tpvmSession.tpvm) && (
              <Button
                onClick={() =>
                  this.props.onRequestRemediationForVuln(
                    cve_name,
                    this.props.vendorId
                  )
                }
              >
                Request remediation
              </Button>
            )}
        </div>
        <ScrollableDiv newStyles>
          <>
            <SlidePanelSection
              title="Summary"
              toggleExpand={() =>
                this.setState((state) => ({
                  summaryExpanded: !state.summaryExpanded,
                }))
              }
              expanded={this.state.summaryExpanded}
            >
              <div className="vuln-summary">
                {vulnData.isActiveKnownExploited && (
                  <>
                    <div className={"kev-info-banner"}>
                      <InfoBanner
                        type={BannerType.WARNING}
                        message={
                          "This vulnerability is currently in the CISA catalog of known exploited vulnerabilities."
                        }
                        subItems={[
                          <p key={"a"} className={"subtext"}>
                            {`Date added: ${formatDate(
                              vulnData.exploitedDateAdded
                            )}. `}
                            Visit the{" "}
                            <a
                              className={"link"}
                              href={
                                "https://www.cisa.gov/known-exploited-vulnerabilities-catalog"
                              }
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              CISA website
                            </a>{" "}
                            for more information.
                          </p>,
                        ]}
                      />
                    </div>
                    <>{summary}</>
                  </>
                )}
                {!vulnData.isActiveKnownExploited &&
                  vulnData.exploitedDateRemoved && (
                    <>
                      <div className={"kev-info-banner"}>
                        <InfoBanner
                          type={BannerType.INFO}
                          message={
                            "This vulnerability is no longer in the CISA catalog of Known Exploited Vulnerabilities."
                          }
                          subItems={[
                            <p key={"a"} className={"subtext"}>
                              {`Date added: ${formatDate(
                                vulnData.exploitedDateAdded
                              )}. `}
                            </p>,
                            <p key={"a"} className={"subtext"}>
                              {`Date removed: ${formatDate(
                                vulnData.exploitedDateRemoved
                              )}. `}
                            </p>,
                          ]}
                        />
                      </div>
                    </>
                  )}
                {!vulnData.isActiveKnownExploited && <>{summary}</>}
              </div>
            </SlidePanelSection>
            {risk_details && (
              <SlidePanelSection
                title="Risk details"
                toggleExpand={() =>
                  this.setState((state) => ({
                    riskDetailsExpanded: !state.riskDetailsExpanded,
                  }))
                }
                expanded={this.state.riskDetailsExpanded}
              >
                <div className="vuln-summary">{risk_details}</div>
              </SlidePanelSection>
            )}
            {recommended_remediation && (
              <SlidePanelSection
                title="Recommended remediation"
                toggleExpand={() =>
                  this.setState((state) => ({
                    recommendedRemediationExpanded:
                      !state.recommendedRemediationExpanded,
                  }))
                }
                expanded={this.state.recommendedRemediationExpanded}
              >
                <div className="vuln-summary">{recommended_remediation}</div>
              </SlidePanelSection>
            )}
          </>

          <SlidePanelSection
            title="CVE details"
            toggleExpand={() =>
              this.setState((state) => ({
                cveDetailsExpanded: !state.cveDetailsExpanded,
              }))
            }
            expanded={this.state.cveDetailsExpanded}
          >
            <>
              <div className="vuln-info-row">
                <div className="vuln-info-label">STATUS</div>
                <div className="vuln-info-value vuln-info-pills-value">
                  {this.props.verified == "true" && (
                    <PillLabel color={LabelColor.Blue}>{"Verified"}</PillLabel>
                  )}
                  {this.props.verified != "true" && (
                    <PillLabel>{"Unverified"}</PillLabel>
                  )}
                  {vulnData.isActiveKnownExploited && (
                    <KnownExploitedVulnPill longText />
                  )}
                </div>
              </div>
              {cvss > 0 && (
                <div className="vuln-info-row">
                  <div className="vuln-info-label">{cvssLabel} SEVERITY</div>
                  <div className="vuln-info-value">
                    {severityMap[cvssToText(cvss)].icon} {cvss}{" "}
                    {cvssToTextCapitalized(cvss)}
                  </div>
                </div>
              )}
              {epss > 0 && (
                <div className="vuln-info-row">
                  <div className="vuln-info-label">EPSS</div>
                  <div className="vuln-info-value">
                    {(epss * 100).toFixed(2)}%
                  </div>
                </div>
              )}
              <div className="vuln-info-row">
                <div className="vuln-info-label">PUBLISHED</div>
                <div className="vuln-info-value">
                  {published && moment(published).format("ll")}
                </div>
              </div>
              {exploitedDateAdded && !exploitedDateRemoved && (
                <div className="vuln-info-row">
                  <div className="vuln-info-label">KNOWN EXPLOITABLE FROM</div>
                  <div className="vuln-info-value">
                    {moment(exploitedDateAdded).format("ll")}
                  </div>
                </div>
              )}
            </>
          </SlidePanelSection>

          <SlidePanelSection
            title="References"
            toggleExpand={() =>
              this.setState((state) => ({
                referencesExpanded: !state.referencesExpanded,
              }))
            }
            expanded={this.state.referencesExpanded}
          >
            {references &&
              references.map((ref) => (
                <div key={ref} className="reference">
                  <a href={ref} target="_blank" rel="noopener noreferrer">
                    {ref}
                  </a>
                  <span className="cr-icon-external-link" />
                </div>
              ))}
          </SlidePanelSection>

          <SlidePanelSection
            title="Versions"
            toggleExpand={() =>
              this.setState((state) => ({
                versionsExpanded: !state.versionsExpanded,
              }))
            }
            expanded={this.state.versionsExpanded}
          >
            {vulnerable_cpes ? (
              vulnerable_cpes.map((cpe) => (
                <CPELabel key={cpe.name} cpe={cpe} />
              ))
            ) : (
              <></>
            )}
          </SlidePanelSection>

          {this.props.onClickAsset && (
            <SlidePanelSection
              title={
                "Assets affected" +
                (this.state.affectedAssetsCount
                  ? " (" + this.state.affectedAssetsCount + ")"
                  : "")
              }
              toggleExpand={() =>
                this.setState((state) => ({
                  affectedAssetsExpanded: !state.affectedAssetsExpanded,
                }))
              }
              expanded={this.state.affectedAssetsExpanded}
            >
              <VulnAssetsAffected
                cveName={this.props.cveName}
                vendorId={this.props.vendorId}
                isSubsidiary={this.props.isSubsidiary}
                isManagedVendorAnalyst={this.props.isManagedVendorAnalyst}
                managedOrgId={this.props.managedOrgId}
                onClickAsset={this.props.onClickAsset}
                onAssetsCountChanged={(assetsCount) => {
                  this.setState((state) => ({
                    affectedAssetsCount: assetsCount,
                  }));
                }}
              />
            </SlidePanelSection>
          )}
        </ScrollableDiv>
      </div>
    );
  }
}

export default appConnect((state, props) => {
  const vulnState = state.cyberRisk.vulns[props.cveName] || {};
  const tpvmSession = state.common.tpvmSession;
  const { loading, error, vulnData } = vulnState;
  return { loading, error, vulnData, tpvmSession };
})(VulnPanel);
