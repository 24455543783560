import { stringify as queryStringify } from "querystring";
import { History } from "history";

// Given a query string, eg. "?thing=good&bad=true",
// extract all values into an object
export const GetQueryParams = (query: string): Record<string, any> => {
  query = query.replace(/^[?#]/, "");
  if (!query) {
    return {};
  }

  const paramEntries = [...new URLSearchParams(query).entries()];
  const out: Record<string, any> = {};
  paramEntries.forEach(([key, value]) => {
    // handle array notation like `?foo[]=1&foo[]=2`
    const isArray = key.endsWith("[]") || key in out;
    key = key.replace(/\[]$/, "");
    if (isArray) {
      if (Array.isArray(out[key])) {
        out[key].push(value);
      } else if (out[key] !== undefined) {
        out[key] = [out[key], value];
      } else {
        out[key] = [value];
      }
    } else {
      out[key] = value;
    }
  });

  return out;
};

// Given a History object, find the values of some params and remove them from the URL without reloading the page.
export const ConsumeQueryParams = (history: History, paramNames: string[]) => {
  const curParams = GetQueryParams(history.location.search);
  const ret: Record<string, any> = {};
  for (let i = 0; i < paramNames.length; i++) {
    if (Object.prototype.hasOwnProperty.call(curParams, paramNames[i])) {
      ret[paramNames[i]] = curParams[paramNames[i]];
      delete curParams[paramNames[i]];
    }
  }

  const newParamString = queryStringify(curParams);
  history.replace({
    pathname: history.location.pathname,
    search: Object.keys(curParams).length === 0 ? "" : `?${newParamString}`,
    hash: history.location.hash,
  });

  return ret;
};

// parseQueryParamValue parses a string value into its own type, string, boolean or number.
export const parseQueryParamValue = (
  value?: string
): string | boolean | number | undefined => {
  if (value === undefined) {
    return undefined;
  }
  const str = decodeURIComponent(value);
  if (str === "false") return false;
  if (str === "true") return true;
  const isNumber = str && !isNaN(+str);
  return isNumber ? +str : str;
};

// parseBooleanQueryParamValue parses a string value into a boolean or
// returns undefined if the value could not be parsed into a boolean
export const parseBooleanQueryParamValue = (
  value?: string
): boolean | undefined => {
  const parsed = parseQueryParamValue(value);
  return typeof parsed === "boolean" ? parsed : undefined;
};

// parseNumberQueryParamValue parses a string value into a number or
// returns undefined if the value could not be parsed into a number
export const parseNumberQueryParamValue = (
  value?: string
): number | undefined => {
  const parsed = parseQueryParamValue(value);
  return typeof parsed === "number" ? parsed : undefined;
};
