import * as React from "react";
import classnames from "classnames";
import "../style/components/PillLabel.scss";
import Icon from "../../_common/components/core/Icon";
import { LabelColor } from "../../_common/types/label";
import { SidePopupV2 } from "../../_common/components/DismissablePopup";

export enum PillContentAlign {
  Center = "align-center",
}

export interface IPillLabelProps {
  align?: PillContentAlign;
  className: string;
  color: LabelColor;
  bordered: boolean;
  filled: boolean;
  removeable: boolean;
  large: boolean;
  constrained: boolean;
  capitalized: boolean;
  children: React.ReactNode;
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  onRemoveClick?: () => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  popupContent?: React.ReactNode;
  popupClassName?: string;
  id?: string;
  popupPosition?: "top" | "right" | "left" | "bottom";
  popupHoverable?: boolean;
  popupNoWrap?: boolean;
}

const PillLabel = (props: IPillLabelProps) => {
  const {
    className,
    align,
    color,
    bordered,
    filled,
    children,
    onClick,
    removeable,
    onRemoveClick,
    onMouseEnter,
    onMouseLeave,
    large,
    constrained,
    capitalized,
    popupContent,
    id,
    popupPosition,
    popupHoverable,
    popupNoWrap,
  } = props;

  const pill = (
    <div
      className={classnames("pill-label", className, color, align, {
        bordered,
        filled,
        clickable: !!onClick,
        removeable,
        large,
        constrained,
        capitalized,
      })}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      id={id}
    >
      <div
        title={constrained && typeof children === "string" ? children : ""}
        className="pill-label-content"
      >
        {children}
      </div>
      {removeable && (
        <Icon name="x" className="remove-x" onClick={onRemoveClick} />
      )}
    </div>
  );

  if (!!popupContent) {
    return (
      <SidePopupV2
        className={"pill-popup"}
        text={popupContent}
        position={popupPosition}
        popupClassName={classnames(props.popupClassName, "pill-popup-content")}
        width={undefined}
        noWrap={popupNoWrap}
        popupHoverable={popupHoverable}
      >
        {pill}
      </SidePopupV2>
    );
  } else {
    return pill;
  }
};

PillLabel.defaultProps = {
  className: "",
  color: LabelColor.Grey,
  bordered: false,
  filled: false,
  removeable: false,
  large: false,
  constrained: false,
  capitalized: false,
};

export default PillLabel;

/**
 * Determines the color of the label based on the key. You can configure the available colors as an optional parameter.
 * @param key
 * @param availableColors
 * @returns LabelColor
 */
export const getColorForKey = (
  key: string,
  availableColors = colors
): LabelColor => {
  // If no available colors - return Blue
  if (availableColors.length === 0) {
    return LabelColor.Blue;
  }

  // Only one available color? Return it
  if (availableColors.length === 1) {
    return availableColors[0];
  }

  // Hash the key to get a random number
  let hash = 0;
  let i;
  let chr;
  for (i = 0; i < key.length; i++) {
    chr = key.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }
  // Use Math.abs to ensure that the hash is always positive
  const idx = Math.abs(hash) % availableColors.length;
  const colorToReturn = availableColors[idx];

  return colorToReturn;
};

// Default colors for Pill Labels
const colors: LabelColor[] = [
  LabelColor.Blue,
  LabelColor.LightBlue,
  LabelColor.Green,
  LabelColor.Yellow,
  LabelColor.Gimblet,
  LabelColor.PersianGreen,
  LabelColor.Violet,
  LabelColor.Fuchsia,
  LabelColor.DarkGrey,
  LabelColor.TrendyPink,
  LabelColor.PictonBlue,
  LabelColor.MountainMeadow,
  LabelColor.Bahia,
];
