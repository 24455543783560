import { Component } from "react";
import PropTypes from "prop-types";
import { range as _range } from "lodash";
import classnames from "classnames";
import Icon from "./Icon";

import "../../style/components/core/Pagination.scss";

class Pagination extends Component {
  static propTypes = {
    currentPage: PropTypes.number,
    totalPages: PropTypes.number,
    onPageChange: PropTypes.func.isRequired,
  };

  static defaultProps = {
    currentPage: 1,
    totalPages: 1,
  };

  onPageChange = (evt, num) => {
    evt.preventDefault();
    this.props.onPageChange(num);
  };

  getLeftNumbers = () => {
    const { totalPages, currentPage } = this.props;

    if (totalPages <= 5) {
      return _range(1, totalPages + 1);
    }

    if (currentPage < 4) {
      return _range(1, 5);
    }

    return _range(1, 2);
  };

  getMiddleNumbers = () => {
    const { totalPages, currentPage } = this.props;

    if (totalPages <= 5) {
      return [];
    }

    let size = 0;
    let upper;

    if (currentPage >= 4) {
      // We need 1 number on either side of current, or the 2 before it.
      upper = Math.min(currentPage + 1, totalPages);
      size = upper - 2;
    }

    // We show the last 4 like we did the first four
    if (totalPages - currentPage === 2) {
      upper += 1;
    }
    if (totalPages - currentPage <= 1) {
      size -= 1;
    }

    if (size) {
      return _range(size, upper + 1);
    }

    // Just return the last page.
    return [totalPages];
  };

  getRightNumbers = () => {
    const middleNumbers = this.getMiddleNumbers();
    const { totalPages } = this.props;
    if (!middleNumbers.length) return [];

    if (middleNumbers[middleNumbers.length - 1] + 1 < totalPages) {
      return [totalPages];
    }

    return [];
  };

  isFirstPage = () => this.props.currentPage === 1;

  isLastPage = () => this.props.currentPage === this.props.totalPages;

  goNextPage = (evt) => {
    evt.preventDefault();
    if (!this.isLastPage()) this.props.onPageChange(this.props.currentPage + 1);
  };

  goPrevPage = (evt) => {
    evt.preventDefault();
    if (!this.isFirstPage())
      this.props.onPageChange(this.props.currentPage - 1);
  };

  renderPageNumber = (num, current) => {
    const cls = classnames("page-number", {
      current,
    });
    return (
      <div
        className={cls}
        onClick={(evt) => this.onPageChange(evt, num)}
        key={num}
      >
        {num}
      </div>
    );
  };

  render() {
    const leftNumbers = this.getLeftNumbers();
    const middleNumbers = this.getMiddleNumbers();
    const rightNumbers = this.getRightNumbers();

    //
    // GT: dudes! As per a request from design, I have changed the 'disabled' style for a 'hidden' style for the
    // previous and next buttons when on the first and last pages respectively. Controversial? Maybe. If you want
    // them back simple rename 'hidden' => 'disabled' below.
    //
    return (
      <div className="ug-paginate">
        <div
          className={`dir-link point-left ${
            this.isFirstPage() ? "disabled" : ""
          }`}
          onClick={this.goPrevPage}
        >
          <Icon name="chevron" direction={270} />
        </div>
        {leftNumbers.map((num) =>
          this.renderPageNumber(num, num === this.props.currentPage)
        )}
        {!!middleNumbers.length && <span className="elipsis">...</span>}
        {middleNumbers.map((num) =>
          this.renderPageNumber(num, num === this.props.currentPage)
        )}
        {!!rightNumbers.length && <span className="elipsis">...</span>}
        {rightNumbers.map((num) =>
          this.renderPageNumber(num, num === this.props.currentPage)
        )}
        <div
          className={`dir-link point-right ${
            this.isLastPage() ? "disabled" : ""
          }`}
          onClick={this.goNextPage}
        >
          <Icon name="chevron" direction={90} />
        </div>
      </div>
    );
  }
}

export default Pagination;
