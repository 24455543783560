import { FC, useEffect, useState } from "react";
import { sortBy as _sortBy } from "lodash";
import Button from "../../_common/components/core/Button";
import PageHeader from "../../_common/components/PageHeader";
import { DefaultThunkDispatchProp } from "../../_common/types/redux";
import { History } from "history";
import "../style/VendorSurveys.scss";
import XTable, {
  IIconOption,
  ISortedBy,
  IXTableRow,
  SortDirection,
  XTableCell,
} from "../../_common/components/core/XTable";
import {
  AdditionalEvidenceRequestPriority,
  AdditionalEvidenceRequestStatus,
  AdditionalEvidenceSummary,
  getAdditionalEvidenceSummaryStatus,
} from "../../vendorrisk/types/additionalEvidence";
import { formatDateAsLocal } from "../../_common/helpers";
import { AdditionalEvidenceStatusPill } from "../../vendorrisk/components/AdditionalEvidenceListTable";
import ReportCard from "../../_common/components/ReportCard";
import PillLabel from "../../vendorrisk/components/PillLabel";
import { LabelColor } from "../../_common/types/label";
import { useModalV2 } from "../../_common/components/ModalV2";
import "../style/views/AdditionalEvidenceRequests.scss";
import { fetchVendorPortalAdditionalEvidenceRequestSummaries } from "../reducers/additionalEvidenceRequest.actions";
import { useInterval } from "../../_common/hooks";
import { appConnect } from "../../_common/types/reduxHooks";
import AdditionalEvidenceUpload from "./AdditionalEvidenceUpload";
import { HoverLocation } from "../../_common/components/IconButton";
import {
  tasksRouterUrlPrefix,
  trustExchangeTaskUrlPrefix,
} from "../../_common/routers/TasksRouter";

interface AdditionalEvidenceRequestsOwnProps {
  history: History;
}

interface AdditionalEvidenceRequestsConnectedProps {
  evidenceRequests: AdditionalEvidenceSummary[];
  loading: boolean;
}

type AdditionalEvidenceRequestsProps = AdditionalEvidenceRequestsOwnProps &
  AdditionalEvidenceRequestsConnectedProps &
  DefaultThunkDispatchProp;

const AdditionalEvidenceRequests: FC<AdditionalEvidenceRequestsProps> = ({
  evidenceRequests,
  history,
  loading,
  dispatch,
}) => {
  const [openAdditionalEvidenceUploadModal, additionalEvidenceUploadModal] =
    useModalV2(AdditionalEvidenceUpload);
  const [sortedBy, setSortedBy] = useState<ISortedBy>({
    columnId: "dateRequested",
    direction: SortDirection.DESC,
  });

  // Ensure we have loaded evidence requests
  useEffect(() => {
    if (evidenceRequests === undefined) {
      dispatch(fetchVendorPortalAdditionalEvidenceRequestSummaries());
    }
  }, []);

  // While we have evidence being scanned, continuously refresh the evidence request summaries
  useInterval(() => {
    const scanningEvidence = (evidenceRequests ?? []).filter(
      (e) => !e.virusScanned
    );
    if (scanningEvidence.length) {
      dispatch(fetchVendorPortalAdditionalEvidenceRequestSummaries());
    }
  }, 5000);

  const getStatus = (evidence: AdditionalEvidenceSummary) => {
    const status = getAdditionalEvidenceSummaryStatus(evidence, true);
    return <AdditionalEvidenceStatusPill status={status} />;
  };

  const getSortedDocumentRequests = () => {
    let sortedDocumentRequests: AdditionalEvidenceSummary[] = [];
    switch (sortedBy.columnId) {
      case "documentType":
        sortedDocumentRequests = _sortBy(evidenceRequests, [
          "documentTypeName",
        ]);
        break;
      case "requestedBy":
        sortedDocumentRequests = _sortBy(evidenceRequests, [
          "organisationName",
        ]);
        break;
      case "priority":
        sortedDocumentRequests = _sortBy(evidenceRequests, ["priority"]);
        break;
      case "dateRequested":
        sortedDocumentRequests = _sortBy(
          evidenceRequests,
          (e) => new Date(e.createdAt)
        );
        break;
      default:
        sortedDocumentRequests = [...sortedDocumentRequests];
        break;
    }

    if (sortedBy.direction !== "asc") {
      sortedDocumentRequests.reverse();
    }
    return sortedDocumentRequests;
  };

  const goToEvidenceDetails = (id: number) => {
    history.push(
      `/${tasksRouterUrlPrefix}/${trustExchangeTaskUrlPrefix}/additionalevidence/${id}`
    );
  };

  const getRows = (): IXTableRow[] => {
    return getSortedDocumentRequests().map((evidenceRequest) => {
      const cells = [];
      const iconOptions: IIconOption[] = [
        {
          id: "request_details",
          hoverText: "Details",
          icon: <div className="cr-icon-chevron" />,
          hoverLocation: HoverLocation.Top,
          hoverMicro: true,
          onClick: () => goToEvidenceDetails(evidenceRequest.id),
        },
      ];
      if (
        evidenceRequest.requestStatus ===
        AdditionalEvidenceRequestStatus.Requested
      ) {
        iconOptions.unshift({
          id: "attach_document",
          hoverText: "Add document",
          icon: <div className="cr-icon-plus" />,
          hoverLocation: HoverLocation.Top,
          hoverMicro: true,
          onClick: () =>
            openAdditionalEvidenceUploadModal({
              evidenceId: evidenceRequest.id,
              documentType: evidenceRequest.documentTypeName,
              requestingOrg: evidenceRequest.organisationName,
            }),
        });
      } else if (
        evidenceRequest.requestStatus === AdditionalEvidenceRequestStatus.Review
      ) {
        iconOptions.unshift({
          id: "new_document_version",
          hoverText: "Attach new version",
          icon: <div className="icon-cycle" />,
          hoverLocation: HoverLocation.Top,
          hoverMicro: true,
          onClick: () =>
            openAdditionalEvidenceUploadModal({
              evidenceId: evidenceRequest.id,
              documentType: evidenceRequest.documentTypeName,
              requestingOrg: evidenceRequest.organisationName,
            }),
        });
      }

      cells.push(
        <XTableCell key={"requestedBy"} className={"name-text"}>
          {evidenceRequest.organisationName}
        </XTableCell>,
        <XTableCell key={"document_type"} className={"document-type"}>
          {evidenceRequest.documentTypeName}
        </XTableCell>,
        <XTableCell key={"priority"}>
          {evidenceRequest.priority ===
            AdditionalEvidenceRequestPriority.High && (
            <PillLabel color={LabelColor.Red}>High</PillLabel>
          )}
        </XTableCell>,
        <XTableCell key={"status"} className={"status"}>
          {getStatus(evidenceRequest)}
        </XTableCell>,
        <XTableCell key={"date_sent"} className="date-due">
          {formatDateAsLocal(evidenceRequest.createdAt)}
        </XTableCell>
      );

      return {
        id: `${evidenceRequest.id}`,
        iconOptions: iconOptions,
        onClick: () => goToEvidenceDetails(evidenceRequest.id),
        cells: cells,
      };
    });
  };

  return (
    <div id="additional_evidence_requests_view">
      {additionalEvidenceUploadModal}
      <PageHeader
        history={history}
        title="Document Requests"
        infoSectionPageKey="infoSection_vendorriskportalAttestations"
        infoSection={
          <p>
            These documents have been requested by your customers to help them
            with their risk assessments. Upload the requested documents to help
            establish your security posture.
          </p>
        }
        infoSectionButtons={
          <Button
            tertiary
            onClick={() =>
              window.open(
                "https://help.upguard.com/en/articles/8507424-how-to-respond-to-requests-for-documentation"
              )
            }
          >
            How to respond to requests for documentation{" "}
            <div className="cr-icon-arrow-right" />
          </Button>
        }
      />
      <ReportCard newStyles>
        <XTable
          loading={loading}
          sortedBy={sortedBy}
          onSortChange={(columnId, direction) =>
            setSortedBy({ columnId, direction })
          }
          columnHeaders={[
            {
              id: "requestedBy",
              text: "Requested by",
              sortable: true,
            },
            {
              id: "documentType",
              text: "Document type",
              sortable: true,
            },
            {
              id: "priority",
              text: "Priority",
              sortable: true,
            },
            {
              id: "status",
              text: "Status",
            },
            {
              id: "dateRequested",
              text: "Date requested",
              sortable: true,
            },
          ]}
          iconOptions
          rows={getRows()}
        ></XTable>
      </ReportCard>
    </div>
  );
};

export default appConnect<
  AdditionalEvidenceRequestsConnectedProps,
  never,
  AdditionalEvidenceRequestsOwnProps
>((state) => {
  return {
    loading: state.vendorPortal.additionalEvidenceRequests.loading ?? true,
    evidenceRequests:
      state.vendorPortal.additionalEvidenceRequests?.evidenceRequestSummaries ??
      [],
  };
})(AdditionalEvidenceRequests);
