import { FC, Fragment } from "react";
import { Link } from "react-router-dom";
import { LocationDescriptor } from "history";

import "../style/components/Breadcrumbs.scss";
import { getVendorWords } from "../constants";
import { AssuranceType } from "../types/organisations";

export interface crumb {
  text: string;
  to?: LocationDescriptor;
}

export interface IBreadcrumbsProps {
  breadcrumbs: crumb[];
}

const Breadcrumbs: FC<IBreadcrumbsProps> = ({ breadcrumbs }) => (
  <div className="breadcrumbs">
    {breadcrumbs.map((crumb, i) => (
      <Fragment key={i}>
        {i > 0 && <span className="cr-icon-chevron" />}
        {crumb.to ? (
          <Link to={crumb.to}>{crumb.text}</Link>
        ) : (
          <div>{crumb.text}</div>
        )}
      </Fragment>
    ))}
  </div>
);

export default Breadcrumbs;

export const getVendorPageBreadcrumbs = (
  vendorId: number,
  vendorName: string,
  assuranceType: AssuranceType
): crumb[] => [
  {
    text: getVendorWords(assuranceType).vendorsPageTitle,
    to: "/vendorlist",
  },
  { text: vendorName, to: `/vendor/${vendorId}` },
];

export const getSubsidiaryPageBreadcrumbs = (
  vendorId: number,
  vendorName: string
): crumb[] => [
  { text: "Subsidiaries", to: "/subsidiaries" },
  { text: vendorName, to: `/subsidiaries/${vendorId}` },
];
