import classnames from "classnames";
import { severityMap } from "../../_common/helpers";
import { OptionType, SelectV2 } from "../../_common/components/SelectV2";
import { Severity } from "../../_common/types/severity";
import { without as _without } from "lodash";

import "../style/components/SeveritySelector.scss";

export interface ISeveritySelectorProps {
  severity?: Severity;
  isDisabled?: boolean;
  onChange: (newSeverity: Severity) => void;
  placeholder?: string;
  hideSeverities?: Severity[];
  usedFixedPosition?: boolean;
}

const SeveritySelector = (props: ISeveritySelectorProps) => (
  <SelectV2
    className="severity-selector"
    placeholder={props.placeholder ?? "Select severity of this risk"}
    options={getOptions(props.hideSeverities)}
    value={
      props.severity
        ? { value: props.severity, label: props.severity }
        : undefined
    }
    onChange={(newVal) => {
      if (newVal && !Array.isArray(newVal)) {
        props.onChange((newVal as OptionType).value as Severity);
      }
    }}
    isDisabled={props.isDisabled}
    isSearchable={false}
    menuPosition={props.usedFixedPosition ? "fixed" : undefined}
    formatOptionLabel={(opt, ctx) => {
      // Is this formatting for a selected option in the dropdown menu?
      const selected =
        ctx.context === "menu" &&
        Array.isArray(ctx.selectValue) &&
        ctx.selectValue.length > 0 &&
        ctx.selectValue[0].value === opt.value;
      return (
        <div className={classnames("severity-opt", { selected })}>
          {(severityMap as any)[opt.value].icon} {opt.value}
        </div>
      );
    }}
  />
);

const getOptions = (hideSeverities?: Severity[]) => {
  const possibleSeverities = [
    Severity.Critical,
    Severity.High,
    Severity.Medium,
    Severity.Low,
    Severity.Info,
  ];

  const displayableSeverities = _without(
    possibleSeverities,
    ...(hideSeverities ?? [])
  );

  return displayableSeverities.map((s) => {
    return { value: s, label: s };
  });
};

export default SeveritySelector;
