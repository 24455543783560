import { Severity } from "../../_common/types/severity";

export enum vsaqQuestionType {
  Block = "block",
  Info = "info",
  Tip = "tip",
  RadioGroup = "radiogroup",
  CheckGroup = "checkgroup",
  Line = "line",
  Box = "box",
  Upload = "upload",
}

export interface vsaqQuestion {
  id: string;
  customNumber?: string;
  text: string;
  textSanitised?: string;
  type: vsaqQuestionType;
  items?: vsaqQuestion[];
  controls?: string[];
  cond?: string;

  // For radiogroup and checkgroup
  choices?: { [choiceId: string]: string }[];
  allowNotes?: boolean;

  // Tip stuff
  why?: string;
  name?: string;
  nameSanitised?: string;
  passName?: string;
  warn?: "yes" | "no";
  severity?: Severity;
  riskCategory?: string;
  impact?: string;

  skipNumbering?: boolean;

  // Custom risk stuff
  customRiskId?: number;
  hasCustomRisk?: boolean;
  customRiskParentId?: string;
}
