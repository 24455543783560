import {
  GetQuestionnaireStatusItem,
  ISurveyListItemResponse,
  SurveyStatus,
} from "../../../_common/types/survey";
import { FC } from "react";
import PillLabel from "../PillLabel";
import { LabelColor } from "../../../_common/types/label";
import ProgressBar from "../../../_common/components/ProgressBar";
import "../../style/components/surveys/SurveyStatusDisplay.scss";
import { formatDateAsLocal } from "../../../_common/helpers";
import LoadingIcon from "../../../_common/components/core/LoadingIcon";
import { JobStatus } from "../../types/jobs";

interface SurveyStatusDisplayProps {
  survey: {
    dueDate?: string;
    status: SurveyStatus;
    numQuestions: number;
    numAnswers: number;
    risksInRemediation?: number;
  };
  isVendorUser?: boolean;
  showPillInsteadOfProgress?: boolean;
  displaySharedPill?: boolean;
  hideStatusPill?: boolean;
}

const SurveyStatusDisplay: FC<SurveyStatusDisplayProps> = ({
  survey,
  isVendorUser,
  showPillInsteadOfProgress,
  displaySharedPill,
  hideStatusPill,
}) => {
  const statusItem = GetQuestionnaireStatusItem(
    survey.status,
    survey.dueDate,
    isVendorUser
  );

  return (
    <div className={"survey-status-display"}>
      {statusItem.text !== "Overdue" &&
      survey.status === SurveyStatus.InProgress &&
      !showPillInsteadOfProgress ? (
        <SurveyProgress survey={survey} />
      ) : (
        <>
          {/* Show Shared pill if required*/}
          {displaySharedPill && (
            <PillLabel color={LabelColor.Fuchsia}>Shared</PillLabel>
          )}
          {/* Show the status, unless hiding specifically */}
          {hideStatusPill !== true && (
            <PillLabel color={statusItem.labelColor}>
              {statusItem.text}
            </PillLabel>
          )}
          {/* Show an additional inRemediation pill if required*/}
          {!!survey.risksInRemediation && survey.risksInRemediation > 0 && (
            <PillLabel color={LabelColor.Green}>In Remediation</PillLabel>
          )}
        </>
      )}
    </div>
  );
};

interface LargeSurveyStatusDisplayProps {
  survey: ISurveyListItemResponse;
  isVendorUser?: boolean;
}

export const LargeSurveyStatusDisplay: FC<LargeSurveyStatusDisplayProps> = ({
  survey,
  isVendorUser,
}) => {
  const statusItem = GetQuestionnaireStatusItem(
    survey.status,
    survey.dueDate,
    isVendorUser
  );

  const recipientText =
    survey.sharedWith && survey.sharedWith.length === 1
      ? survey.sharedWith[0].name
      : "Multiple";

  return (
    <div className={"large-survey-status-display"}>
      <div className={"display-item"}>
        <div className={"display-header"}>Recipient</div>
        <div className={"display-content"}>{recipientText}</div>
      </div>
      <div className={"display-item"}>
        <div className={"display-header"}>Last updated</div>
        <div className={"display-content"}>
          {formatDateAsLocal(survey.dateSubmitted ?? survey.dateCreated)}
        </div>
      </div>
      <div className={"display-item"}>
        <div className={"display-header"}>Status</div>
        <div className={"display-content"}>
          <PillLabel color={statusItem.labelColor}>{statusItem.text}</PillLabel>
        </div>
      </div>
      <div className={"display-item"}>
        <div className={"display-header"}>Progress</div>
        <div className={"display-content"}>
          <SurveyProgress survey={survey} />
        </div>
      </div>
    </div>
  );
};

interface SurveyProgressProps {
  survey: {
    numQuestions: number;
    numAnswers: number;
  };
}

export const SurveyProgress: FC<SurveyProgressProps> = ({ survey }) => {
  let percentageComplete = 0;
  if (survey.numAnswers > 0) {
    percentageComplete = Math.floor(
      (survey.numAnswers / survey.numQuestions) * 100
    );
  }

  return (
    <div className={"survey-progress"}>
      <ProgressBar progress={percentageComplete / 100} />
      <div className={"progress-percentage"}>{`${percentageComplete}%`}</div>
    </div>
  );
};

interface SurveyAutofillProgressProps {
  survey: {
    autofillInProgress: boolean;
    numQuestions: number;
    numAnswers: number;
    numSuggestions: number;
    progressPercentage: number;
    autofillJobStatus: JobStatus;
  };
  loading?: boolean;
}

// SurveyAutofillProgress
// Used to display the status of the analyst autofill both during the autofill itself, and then immediately
// after the autofill is complete and the analyst hasn't started reviewing yet.
export const SurveyAutofillProgress: FC<SurveyAutofillProgressProps> = ({
  survey,
  loading,
}) => {
  return (
    <div className={"autofill-progress"}>
      <div className={"progress-top-line"}>
        <div>{`Autofill ${Math.trunc(
          survey.progressPercentage * 100
        )}% complete`}</div>
        <div className={"top-right"}>{`${survey?.numSuggestions ?? 0} of ${
          (survey?.numQuestions ?? 0) - (survey?.numAnswers ?? 0)
        } suggested`}</div>
      </div>
      <ProgressBar progress={survey.progressPercentage} className={"orange"} />
      <div className="progress-bottom-line">
        {survey.autofillInProgress
          ? `Check back later to see suggested answers.`
          : `${survey.numSuggestions} autofilled suggestions and ${
              survey.numQuestions - survey.numSuggestions
            } unanswered questions are ready for review.`}
      </div>
      {loading && <LoadingIcon size={16} />}
    </div>
  );
};

export default SurveyStatusDisplay;
