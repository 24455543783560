import { Component } from "react";
import moment from "moment";
// @ts-ignore
import { toHTML } from "slack-markdown";
import { DefaultThunkDispatch } from "../../../_common/types/redux";
import { History } from "history";
import Button from "../../../_common/components/core/Button";
import { BannerType } from "../InfoBanner";
import SelectableSidebarTriggersList from "./SelectableSidebarTriggersList";
import { INotificationConfigCategory } from "../../../_common/types/notifications";
import { IBasicAuthParams } from "../../../_common/types/webhooks";
import ExclaimLabel from "../ExclaimLabel";
import UpGuardIcon from "../../../_common/images/upguard-logo-icon.svg";
import { postToOAuth2MessagingServiceChannel } from "../../reducers/oauth.actions";
import {
  liquidSyntaxGuideURL,
  MESSAGING_SERVICE_SLACK,
} from "../../views/CreateIntegration";
import {
  addDefaultSuccessAlert,
  addMessageAlert,
} from "../../../_common/reducers/messageAlerts.actions";
import { IStringMap } from "../../../_common/types/integration";
import MessageCustomizationHelp from "./MessageCustomizationHelp";
import { IOrgAlertDefinition } from "../../reducers/org.actions";
import { SidePopupV2 } from "../../../_common/components/DismissablePopup";

interface IConfigureSlackPayloadsStepProps {
  history: History;
  dispatch: DefaultThunkDispatch;
  loading: boolean;
  selectedChannel: string;
  notifications: INotificationConfigCategory<IOrgAlertDefinition>[];
  notificationsByUUID: Record<string, IOrgAlertDefinition | undefined>;
  selectedUUIDs: {
    [uuid: string]: boolean;
  };
  selectedTestUUID: string;
  selectedTestJSON: any;
  selectedPayloadTemplate: string;
  setSelectedTestNotification: (notification: IOrgAlertDefinition) => void;
  validateSlackTarget: () => {
    valid: boolean;
    msg: string;
  };
  prepareIntegrationParams: () => {
    id?: number;
    webhookID?: number;
    integrationType: number;
    uuids: string[];
    httpHeaders: IStringMap;
    urlParams: IStringMap;
    basicAuth: IBasicAuthParams;
    enabled: boolean;
    selectedChannel?: string;
  };
  resetPayloadTemplateToDefault: () => void;
  onChangePayloadTemplate: (template: string) => void;
  getSlackExampleMessage: () => { message: string; error: string | undefined };
}

interface IConfigureSlackPayloadsStepState {
  testSlackLoading: boolean;
}

export const ToClipboard = (text: string) => {
  navigator.clipboard.writeText(text);
};

export const GetExampleMessageVariables = (
  notificationsByUUID: Record<string, IOrgAlertDefinition | undefined>,
  uuid: string
) => {
  let vars: {
    [varname: string]: { name: string; path: string; example: string };
  } = {};

  const notification = notificationsByUUID[uuid];
  if (notification) {
    vars = notification.templateVariablesList ?? {};
  }

  let list = Object.keys(vars).map((key) => {
    return {
      name: key,
      path: vars[key].path,
      example: vars[key].example,
    };
  });

  const arrayVariables = list.filter((v) => {
    return v.example === "array";
  });
  list = list.filter((v) => {
    return v.example !== "array";
  });
  list.push(...arrayVariables);
  return list;
};

class ConfigureSlackPayloadsStep extends Component<
  IConfigureSlackPayloadsStepProps,
  IConfigureSlackPayloadsStepState
> {
  static defaultProps = {
    webhookTestResponse: null,
  };

  constructor(props: IConfigureSlackPayloadsStepProps) {
    super(props);
    this.state = {
      testSlackLoading: false,
    } as IConfigureSlackPayloadsStepState;
  }

  testSlackMessage = () => {
    this.setState({ testSlackLoading: true });
    const { selectedChannel } = this.props.prepareIntegrationParams();
    const testUUID = this.props.selectedTestUUID;
    this.props
      .dispatch(
        postToOAuth2MessagingServiceChannel(
          MESSAGING_SERVICE_SLACK,
          selectedChannel ?? "",
          this.props.selectedPayloadTemplate,
          testUUID
        )
      )
      .then((e) => {
        this.setState({ testSlackLoading: false });
        if (e) {
          this.props.dispatch(
            addMessageAlert({
              message: `Test slack post failed: "${e}"`,
              type: BannerType.WARNING,
            })
          );
        } else {
          this.props.dispatch(
            addDefaultSuccessAlert(
              "Successfully posted test message to Slack channel"
            )
          );
        }
      });
  };

  render() {
    {
      const { valid } = this.props.validateSlackTarget();

      let messageVariables = GetExampleMessageVariables(
        this.props.notificationsByUUID,
        this.props.selectedTestUUID
      );

      const arrayVariables = messageVariables.filter((v) => {
        return v.example === "array";
      });
      messageVariables = messageVariables.filter((v) => {
        return v.example !== "array";
      });
      messageVariables.push(...arrayVariables);

      let slackExampleMessage = "";
      let slackExampleError = null;
      let { message, error } = this.props.getSlackExampleMessage();
      message = message
        ? message.replace(/(^[\s\u200b]$|[\s\u200b]$)/, "")
        : "";
      error = error;
      slackExampleMessage = toHTML(message, {});
      slackExampleError = error;
      if (slackExampleError != null && slackExampleError.length > 0) {
        slackExampleError = slackExampleError
          .replace("Liquid error:", "")
          .trim();

        if (slackExampleError.length > 0) {
          slackExampleError =
            slackExampleError.charAt(0).toUpperCase() +
            slackExampleError.slice(1);
        }
      }

      return (
        <div className="section-step">
          <div className="section-step nobackground">
            <div id={"payload"}>
              <div className={"title-bar"}>
                <div className="header">
                  Review Slack message by trigger type
                </div>
              </div>
              <div className={"body"}>
                {(!this.props.selectedUUIDs ||
                  Object.keys(this.props.selectedUUIDs).length == 0) && (
                  <div className={"no-triggers"}>
                    <span>
                      {
                        "No triggers have been selected. Please return to the 'Triggers' section and select at least one notification trigger for this integration."
                      }
                    </span>
                  </div>
                )}
                {this.props.selectedUUIDs &&
                  Object.keys(this.props.selectedUUIDs).length > 0 && (
                    <>
                      <SelectableSidebarTriggersList
                        notifications={this.props.notifications}
                        selectedUUIDs={this.props.selectedUUIDs}
                        selectedTestUUID={this.props.selectedTestUUID}
                        setSelectedTestNotification={
                          this.props.setSelectedTestNotification
                        }
                      />
                      <div className={"payload-content no-left-padding"}>
                        <div className={"section-title section-left-padding"}>
                          <div className="main-title">
                            Setup this notification
                          </div>
                        </div>
                        <div className={"section-title noline noline-top"}>
                          <div className="title left-padding">
                            Target Channel
                          </div>
                        </div>
                        <div className={"payload-line"}>
                          <div className={"message-channel"}>
                            {`#${this.props.selectedChannel}`}
                          </div>
                        </div>
                        <div className={"section-title noline"}>
                          <div className="title  left-padding">
                            Message preview
                          </div>
                        </div>
                        <div className={"payload-line"}>
                          <div className={"slack-data"}>
                            <div className={"slack-snippet"}>
                              <>
                                <div className={"icon"}>
                                  <img
                                    src={UpGuardIcon}
                                    alt="UpGuard Logo"
                                    className="ug-logo"
                                  />
                                </div>
                                <div className={"slack-text"}>
                                  <div className={"title-row"}>
                                    <div className={"title"}>{"UpGuard"}</div>
                                    <div className={"app-badge"}>APP</div>
                                    <div className={"date"}>
                                      {moment(new Date()).format("H:mm A")}
                                    </div>
                                  </div>
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: slackExampleMessage,
                                    }}
                                  />
                                </div>
                                {slackExampleError && (
                                  <>
                                    <div className={"icon"}>
                                      <SidePopupV2
                                        className="locked-button-popup"
                                        text={`${slackExampleError}`}
                                      >
                                        <ExclaimLabel triangle />
                                      </SidePopupV2>
                                    </div>
                                  </>
                                )}
                              </>
                            </div>
                          </div>
                        </div>

                        <div className={"section-title noline"}>
                          <div className="title left-padding">
                            {"Edit message"}
                          </div>
                        </div>

                        <div className={"payload-line"}>
                          <div className={"instruction-text"}>
                            <>
                              {
                                "Use the “Post test message” button to post a notification to the nomiated channel indicated above."
                              }
                              {"The template syntax is Liquid-format (see the "}
                              <a
                                href={`${liquidSyntaxGuideURL}`}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {"support article"}
                              </a>
                              {" for details)."}
                            </>
                          </div>
                          <div className={"messageedit"}>
                            <textarea
                              id={"payload-template"}
                              onChange={(e) =>
                                this.props.onChangePayloadTemplate(
                                  e.target.value
                                )
                              }
                              value={this.props.selectedPayloadTemplate}
                            />
                          </div>
                          <div className={"button-row"}>
                            <Button
                              tertiary
                              className={"reset-button"}
                              onClick={() =>
                                this.props.resetPayloadTemplateToDefault()
                              }
                            >
                              <div className="cr-icon-redo" />
                              {"Reset message template to default"}
                            </Button>
                            <Button
                              className={"send-button"}
                              onClick={this.testSlackMessage}
                              loading={this.state.testSlackLoading}
                              disabled={
                                this.state.testSlackLoading ||
                                !valid ||
                                !this.props.selectedTestUUID
                              }
                            >
                              <div className="cr-icon-message" />{" "}
                              {"Post test message"}
                            </Button>
                          </div>
                        </div>
                        <MessageCustomizationHelp
                          selectedTestJSON={this.props.selectedTestJSON}
                          selectedTestUUID={this.props.selectedTestUUID}
                          notificationsByUUID={this.props.notificationsByUUID}
                        />
                      </div>
                    </>
                  )}
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}

export default ConfigureSlackPayloadsStep;
