import { getVendorData } from "./cyberRiskActions";
import { grabTPVMSession } from "../../_common/reducers/commonActions";
import { get as _get } from "lodash";
import { FetchCyberRiskUrl } from "../../_common/api";
import { ITpvmSession } from "../../_common/types/thirdPartyMangedVendors";
import { LogError } from "../../_common/helpers";
import { IVendorContactResponse } from "../../_common/types/vendorContact";
import { DefaultThunkDispatch } from "../../_common/types/redux";
import { DefaultRootState } from "react-redux";

export interface IVendorManagementContactsData {
  contacts: {
    error?: IError;
    loading: boolean;
    result: IVendorContactResponse[] | null;
  };
}

export const SET_VENDOR_MGT_CONTACTS = "CYBERRISK_SET_VENDOR_MGT_CONTACTS";

export const setVendorManagementContacts = (
  vendorId: number,
  data: IVendorManagementContactsData,
  tpvmSession: ITpvmSession | null = null
) => {
  return {
    type: SET_VENDOR_MGT_CONTACTS,
    vendorId,
    data,
    isManagementAnalystSession: tpvmSession && tpvmSession.tpvm,
    managedOrgId: tpvmSession ? tpvmSession.tpvm_o : 0,
  };
};

interface vendorContactsV1Resp {
  status: "OK" | "Error";
  contacts: IVendorContactResponse[];
}

// fetchVendorMgtContacts fetches all the contacts associated with a vendor.
export const fetchVendorMgtContacts = (
  vendorId: number,
  forceRefresh = false
) => {
  return async (
    dispatch: DefaultThunkDispatch,
    getState: () => DefaultRootState
  ) => {
    const tpvmSession = grabTPVMSession(getState);
    const vendor = getVendorData(getState, vendorId, false, tpvmSession);
    const mgtlists = _get(vendor, "mgtlists", null);

    let json;
    if (!forceRefresh && mgtlists && !mgtlists.loading && mgtlists.contacts) {
      // cached, dont fetch again
      return mgtlists.contacts;
    }

    if (!mgtlists || !mgtlists.contacts) {
      dispatch(
        setVendorManagementContacts(
          vendorId,
          {
            contacts: {
              loading: true,
              error: undefined,
              result: null,
            },
          },
          tpvmSession
        )
      );
    }

    try {
      json = await FetchCyberRiskUrl<vendorContactsV1Resp>(
        "watchedvendor/contacts/v1/",
        {
          datastore_vendor_id: vendorId,
        },
        {},
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error retrieving vendor management lists - contacts", e);
    }

    if (!json || json.status !== "OK") {
      const contacts = {
        loading: false,
        error: {
          errorText: "Error fetching contacts for vendor",
          actionText: "Retry",
          actionOnClick: () => dispatch(fetchVendorMgtContacts(vendorId, true)),
        },
        result: null,
      };
      dispatch(
        setVendorManagementContacts(
          vendorId,
          { contacts: contacts },
          tpvmSession
        )
      );
      return contacts;
    }

    const contacts = {
      loading: false,
      error: undefined,
      result: json.contacts,
    };
    dispatch(
      setVendorManagementContacts(vendorId, { contacts: contacts }, tpvmSession)
    );
    return contacts;
  };
};
