import { Component } from "react";
import PropTypes from "prop-types";
import Button from "../../../_common/components/core/Button";
import LoadingBanner from "../../../_common/components/core/LoadingBanner";
import { closeModal } from "../../../_common/reducers/commonActions";
import {
  fetchDistributors,
  fetchOrgs,
  fetchOrgsForParent,
  setDistributor,
} from "../../reducers/adminActions";
import Select from "../../../_common/components/Select";

import "../../styles/NewOrganisationModal.scss";
import {
  addDefaultUnknownErrorAlert,
  addSimpleErrorAlert,
} from "../../../_common/reducers/messageAlerts.actions";

export const ChangeDistributorModalName = "ChangeDistributorModalName";

export const getDistributor = (id, distributors) =>
  distributors.find((distributor) => distributor.id === id);

class ChangeDistributorModal extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    modalData: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
    };
  }

  componentDidMount() {
    const {
      dispatch,
      modalData: { org },
    } = this.props;

    dispatch(fetchDistributors())
      .then((distributors) => {
        const newDistributor =
          getDistributor(org.parentID, distributors) || distributors[0];
        this.setState({
          newDistributor,
          distributors,
          loading: false,
          willLoseAccess: this.willLoseAccess(newDistributor.id),
        });
      })
      .catch((err) => {
        dispatch(closeModal());
        dispatch(
          addDefaultUnknownErrorAlert("Error occurred fetching distributors", [
            err,
          ])
        );
      });
  }

  willLoseAccess = (orgId) => {
    const { userData } = this.props.modalData;
    if (userData.isSuperAdmin) {
      return false;
    }
    const org = userData.orgList.find((org) => org.id === orgId);
    if (!org) {
      return true;
    }
    return !org.roles.includes("Distributor");
  };

  onSubmit = (event) => {
    event.preventDefault();
    this.setState({ loading: true });
    this.props
      .dispatch(
        setDistributor(
          this.props.modalData.org.id,
          this.state.newDistributor.id
        )
      )
      .then(() => {
        this.props.dispatch(
          this.props.modalData.inDistributorView
            ? fetchOrgsForParent(
                this.props.modalData.accountsFilter.showEnabled,
                this.props.modalData.accountsFilter.showDisabled,
                this.props.modalData.accountsFilter.showAccountTypes
              )
            : fetchOrgs(
                this.props.modalData.accountsFilter.showEnabled,
                this.props.modalData.accountsFilter.showDisabled,
                this.props.modalData.accountsFilter.showAccountTypes
              )
        );
        this.props.dispatch(closeModal());
      })
      .catch((err) => {
        this.setState({ loading: false });
        this.props.dispatch(addSimpleErrorAlert(err.message));
      });
  };

  onChangeDistributor = (e) => {
    const { distributors } = this.state;
    const id = parseInt(e.target.value, 10);

    this.setState({
      newDistributor: getDistributor(id, distributors) || { id },
      willLoseAccess: this.willLoseAccess(id),
    });
  };

  render() {
    const { loading, newDistributor, distributors, willLoseAccess } =
      this.state;
    const { hasParent, org } = this.props.modalData;

    if (this.state.loading) {
      return (
        <div id="change-distributor-modal">
          <div className="modal-content">
            <LoadingBanner tight />
          </div>
        </div>
      );
    }

    const sortedDistributors = [...distributors].sort((a, b) =>
      a.name.localeCompare(b.name)
    );

    return (
      <div id="change-distributor-modal">
        <div className="modal-content">
          <h2>{hasParent ? "Change distributor" : "Add distributor"}</h2>
          <form onSubmit={this.onSubmit}>
            <label htmlFor="distributor">New distributor</label>
            <Select
              name="distributor"
              value={newDistributor.id || ""}
              onChange={this.onChangeDistributor}
              required
            >
              {hasParent && (
                <option key="remove" value={-1}>
                  --- Remove Distributor ---
                </option>
              )}
              {sortedDistributors.map(({ id, name }) => (
                <option key={id} value={id}>
                  {name}
                </option>
              ))}
            </Select>
            {willLoseAccess && (
              <p>
                {newDistributor.id > 0
                  ? `You do not have access to ${newDistributor.name}`
                  : "You are removing the distributor"}
                , so will no longer be able to access <b>{org.name}</b>.
              </p>
            )}
            <div className="vertspacer" />
            <Button
              type="submit"
              primary
              loading={loading}
              disabled={loading || newDistributor.id === org.parentID}
            >
              Save
            </Button>
          </form>
        </div>
      </div>
    );
  }
}

export default ChangeDistributorModal;
