import {
  DefaultThunkDispatch,
  IVendorFreeTrialData,
} from "../../_common/types/redux";
import { FetchCyberRiskUrl } from "../../_common/api";
import { LogError } from "../../_common/helpers";
import { DefaultRootState } from "react-redux";

interface VendorFreeTrialInviteV1 {
  emailText: string;
  datastoreVendorID: number;
  contactEmail: string;
  contactName: string;
  contactTitle?: string;
}

export const sendVendorFreeTrialPreviewEmail = (
  req: VendorFreeTrialInviteV1
) => {
  return async (
    dispatch: DefaultThunkDispatch,
    getState: () => DefaultRootState
  ) => {
    try {
      await FetchCyberRiskUrl(
        "vendor_free_trial/email_preview/v1",
        {},
        { method: "POST", body: JSON.stringify(req) },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("error sending vendor free trial preview email", e);

      throw e;
    }
  };
};

export const sendVendorFreeTrialInvite = (req: VendorFreeTrialInviteV1) => {
  return async (
    dispatch: DefaultThunkDispatch,
    getState: () => DefaultRootState
  ) => {
    try {
      await FetchCyberRiskUrl(
        "vendor_free_trial/invite/v1",
        {},
        { method: "POST", body: JSON.stringify(req) },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("error sending vendor free trial invite", e);

      throw e;
    }
  };
};

export const SET_VENDOR_FREE_TRIAL_INFO =
  "CYBERRISK_SET_VENDOR_FREE_TRIAL_INFO";

export const setVendorFreeTrialInfo = (
  vendorId: number,
  loading?: boolean,
  error?: any,
  data?: IVendorFreeTrialData
) => {
  return {
    type: SET_VENDOR_FREE_TRIAL_INFO,
    vendorId,
    loading,
    error,
    data,
  };
};

export const getVendorFreeTrialInfo = (
  vendorId: number,
  invitedUserEmail?: string,
  forced = false
) => {
  return async (
    dispatch: DefaultThunkDispatch,
    getState: () => DefaultRootState
  ) => {
    const vendorFreeTrialInfo = getState().cyberRisk.vendorsFreeTrial[vendorId];
    if (!forced && vendorFreeTrialInfo) return vendorFreeTrialInfo;

    dispatch(setVendorFreeTrialInfo(vendorId, true, undefined, undefined));

    let json;

    try {
      json = await FetchCyberRiskUrl<IVendorFreeTrialData>(
        "vendor_free_trial/info/v1",
        {
          datastore_vendor_id: vendorId,
          invited_user_email: invitedUserEmail,
        },
        { method: "GET" },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("error getting getting free trial info for vendor", e);

      dispatch(setVendorFreeTrialInfo(vendorId, false, e, undefined));
      throw e;
    }

    dispatch(setVendorFreeTrialInfo(vendorId, false, undefined, json));

    return json;
  };
};
