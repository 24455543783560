import { UserEmailAddress } from "../../../_common/types/user";
import { IVendorContactResponse } from "../../../_common/types/vendorContact";
import CustomSelect, {
  Option,
  SelectItemType,
} from "../../../_common/components/CustomSelect";
import { FC, useEffect, useRef, useState, useCallback } from "react";
import UserDisplay from "../UserDisplay";
import { IUserData } from "../../../_common/types/redux";
import IconButton from "../../../_common/components/IconButton";
import "../../style/components/ContactSelect.scss";
import Button from "../../../_common/components/core/Button";
import NewRecipientSurface from "./NewRecipientSurface";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { checkVendorDomains } from "../../reducers/vendors.actions";
import { addDefaultUnknownErrorAlert } from "../../../_common/reducers/messageAlerts.actions";
import { useAppDispatch } from "../../../_common/types/reduxHooks";
import { debounce as _debounce } from "lodash";
import { getDomainForEmailAddress } from "../remediation/VerifiedVendorContactSelector";
import classNames from "classnames";
import LoadingIcon from "../../../_common/components/core/LoadingIcon";
import { SidePopupV2 } from "../../../_common/components/DismissablePopup";

type EmailDomainChecks = { [domain: string]: boolean | undefined };

// effect that will allow you to check if email domains are valid for a given set of domains
export const useVerifiedVendorVerifier = (vendorId: number) => {
  const dispatch = useAppDispatch();
  const [emailDomains, setEmailDomains] = useState<EmailDomainChecks>({});

  const isCheckingDomains = useRef(false);

  const checkDomains = (emailDomainsToCheck: EmailDomainChecks) => {
    const domainsToCheck: string[] = [];

    // Get any domains we still need to check
    for (const d in emailDomainsToCheck) {
      if (emailDomainsToCheck[d] === undefined) {
        domainsToCheck.push(d);
      }
    }

    if (domainsToCheck.length > 0) {
      if (isCheckingDomains.current) {
        return; // We'll retry after the current request is done
      } else {
        isCheckingDomains.current = true;
      }

      dispatch(checkVendorDomains(vendorId, domainsToCheck))
        .then((checkResults) => {
          if (checkResults?.domainChecks) {
            setEmailDomains({
              ...emailDomainsToCheck,
              ...checkResults?.domainChecks,
            });
          }
        })
        .catch(() => {
          dispatch(
            addDefaultUnknownErrorAlert("Error checking contact domains")
          );
        })
        .finally(() => {
          isCheckingDomains.current = false;
        });
    }
  };

  // Debounce the check call to the API while the user is changing stuff
  const debouncedCheckDomains = useCallback(
    _debounce(
      (emailDomains: EmailDomainChecks) => checkDomains(emailDomains),
      1000
    ),
    []
  );

  // If the email domains change, check any domains not yet checked
  useEffect(() => {
    debouncedCheckDomains(emailDomains);
  }, [emailDomains]);

  const addDomain = useCallback(
    (domain: string) => {
      if (!(domain in emailDomains)) {
        setEmailDomains((current) => ({
          [domain]: undefined, // we intentionally override this with any existing state for that domain
          ...current,
        }));
      }
    },
    [setEmailDomains, emailDomains]
  );

  return [addDomain, emailDomains] as const;
};

interface ContactSelectProps {
  currentUser?: IUserData;
  orgUsers?: UserEmailAddress[];
  vendorContacts?: IVendorContactResponse[];
  onSelectionChanged: (contacts: ContactDisplay[]) => void;
  maxSelectedContacts?: number;
  hideExistingContacts?: boolean;

  // validateNewContact should return an error string
  // if the contact is invalid or undefined if valid
  validateNewContactEmail?: (emailAddress?: string) => string | undefined;

  // any validation error that should be shown by the component should be passed here
  // this is to be used for when the selected contact is somehow not suitable for the action
  validationError?: string;
  initiallySelectedEmailAddresses?: string[];
  initialNewContacts?: ContactDisplay[];

  // used to validate verified vendor contacts
  // if defined all new contacts are validated against this ID
  validateVerifiedVendorID?: number;
  vendorName?: string;

  addText?: string;
}

export interface ContactDisplayBase {
  name: string;
  emailAddress: string;
  title?: string;
  notes?: string;
  phoneNumber?: string;
  avatar: string;
  isVendorContact?: boolean;
  isChecking?: boolean;
  emailDomainNotAllowed?: boolean;
}

export interface ContactDisplayNew extends ContactDisplayBase {
  isNewContact: true;
}

export interface ContactDisplayExisting extends ContactDisplayBase {
  isNewContact: false;
  existingId: number;
}

export type ContactDisplay = ContactDisplayNew | ContactDisplayExisting;

const ContactSelect: FC<ContactSelectProps> = ({
  currentUser,
  orgUsers,
  vendorContacts,
  onSelectionChanged,
  maxSelectedContacts,
  validateNewContactEmail,
  validationError,
  initiallySelectedEmailAddresses,
  validateVerifiedVendorID,
  vendorName,
  initialNewContacts,
  hideExistingContacts,
  addText,
}) => {
  const [searchText, setSearchText] = useState("");
  const [selectedContacts, setSelectedContacts] = useState<ContactDisplay[]>(
    []
  );
  const [surfaceActive, setSurfaceActive] = useState(false);
  const [newContacts, setNewContacts] = useState<ContactDisplay[]>(
    initialNewContacts ?? []
  );

  // TODO prune duplicate email addresses

  // if we are in verified vendor validation mode we need to validate any added/existing users
  const [addDomain, checkedDomains] = useVerifiedVendorVerifier(
    validateVerifiedVendorID ?? 0
  );
  useEffect(() => {
    if (!!validateVerifiedVendorID) {
      selectedContacts.forEach((c) => {
        const domain = getDomainForEmailAddress(c.emailAddress);
        if (domain) {
          addDomain(domain);
        }
      });

      newContacts.forEach((c) => {
        const domain = getDomainForEmailAddress(c.emailAddress);
        if (domain) {
          addDomain(domain);
        }
      });
    }
  }, [validateVerifiedVendorID, addDomain, selectedContacts, newContacts]);

  // keep our current and selected contacts up to date
  useEffect(() => {
    if (!!validateVerifiedVendorID) {
      setNewContacts((current) =>
        current.map((c) => {
          const domain = getDomainForEmailAddress(c.emailAddress);
          return {
            ...c,
            isChecking: domain
              ? checkedDomains[domain] === undefined
              : undefined,
            emailDomainNotAllowed: domain
              ? checkedDomains[domain] === false
              : undefined,
          };
        })
      );

      setSelectedContacts((current) =>
        current.map((c) => {
          const domain = getDomainForEmailAddress(c.emailAddress);
          return {
            ...c,
            isChecking: domain
              ? checkedDomains[domain] === undefined
              : undefined,
            emailDomainNotAllowed: domain
              ? checkedDomains[domain] === false
              : undefined,
          };
        })
      );
    }
  }, [checkedDomains]);

  const emailAddresses: string[] = [];

  if (currentUser) {
    emailAddresses.push(currentUser.emailAddress);
  }

  if (orgUsers) {
    emailAddresses.push(...orgUsers.map((ou) => ou.emailAddress));
  }

  if (vendorContacts) {
    emailAddresses.push(...vendorContacts.map((vc) => vc.emailAddress ?? ""));
  }

  // Check if we should pre-select any of the contacts onload
  useEffect(() => {
    if (initiallySelectedEmailAddresses) {
      const initiallySelectedContacts: ContactDisplay[] = [];

      initiallySelectedEmailAddresses.forEach((emailAddress) => {
        const orgUser = orgUsers?.find(
          (ou) => ou.emailAddress === emailAddress
        );
        const vendorContact = vendorContacts?.find(
          (vu) => vu.emailAddress === emailAddress
        );

        if (orgUser) {
          initiallySelectedContacts.push({
            name: orgUser.name,
            emailAddress: orgUser.emailAddress,
            avatar: orgUser.avatar,
            isNewContact: false,
            existingId: orgUser.id,
          });
        } else if (vendorContact) {
          initiallySelectedContacts.push({
            name: vendorContact.name ?? "",
            emailAddress: vendorContact.emailAddress ?? "",
            isVendorContact: true,
            avatar: "",
            isNewContact: false,
            existingId: vendorContact.id,
          });
        }
      });

      setSelectedContacts(initiallySelectedContacts);
    }
  }, []);

  const selectContact = (
    name: string,
    emailAddress: string,
    isVendorContact: boolean,
    avatar: string,
    existingId: number
  ) => {
    const newContact: ContactDisplay = {
      name,
      emailAddress,
      isVendorContact,
      avatar,
      existingId,
      isNewContact: false,
    };

    if (!!validateVerifiedVendorID) {
      const domain = getDomainForEmailAddress(emailAddress);
      if (domain) {
        newContact.isChecking = checkedDomains[domain] === undefined;
        newContact.emailDomainNotAllowed = checkedDomains[domain] === false;
      }
    }

    setSelectedContacts([...selectedContacts, newContact]);
  };

  const deselectContact = (emailAddress: string) =>
    setSelectedContacts(
      selectedContacts.filter((sc) => sc.emailAddress !== emailAddress)
    );

  const removeNewContact = (emailAddress: string) =>
    setNewContacts(
      newContacts.filter((nc) => nc.emailAddress !== emailAddress)
    );

  useEffect(() => {
    onSelectionChanged([...selectedContacts, ...newContacts]);
  }, [selectedContacts, newContacts]);

  const searchTextLower = searchText.toLocaleLowerCase();
  const selectedEmailAddresses = selectedContacts.map((sc) => sc.emailAddress);

  const shouldShowContact = (name: string, emailAddress: string) =>
    (!searchText ||
      name.toLocaleLowerCase().indexOf(searchTextLower) !== -1 ||
      emailAddress.toLocaleLowerCase().indexOf(searchTextLower) !== -1) &&
    selectedEmailAddresses.indexOf(emailAddress) === -1;

  const options: Option[] = [];

  if (currentUser) {
    const currentUserName = `${currentUser.firstName} ${currentUser.lastName} (you)`;
    const isCurrentUserSearchMatch = shouldShowContact(
      currentUserName,
      currentUser.emailAddress
    );

    if (isCurrentUserSearchMatch) {
      const orgUserForCurrentUser = orgUsers?.find(
        (ou) => ou.emailAddress === currentUser.emailAddress
      );

      options.push({
        id: `o_${currentUser.id}`,
        type: SelectItemType.Option,
        content: (
          <UserDisplay
            name={currentUserName}
            email={currentUser.emailAddress}
            avatar={orgUserForCurrentUser?.avatar ?? ""}
            largeAvatar
          />
        ),
        onClick: () =>
          selectContact(
            currentUserName,
            currentUser.emailAddress,
            false,
            orgUserForCurrentUser?.avatar ?? "",
            currentUser.id
          ),
      });
    }
  }

  const orgUsersToShow = orgUsers
    ?.filter(
      (ou) => !currentUser || ou.emailAddress !== currentUser.emailAddress
    )
    .filter((ou) => shouldShowContact(ou.name, ou.emailAddress));

  if (orgUsersToShow && orgUsersToShow.length > 0) {
    options.push({
      type: SelectItemType.Group,
      id: "org_user_divider",
      title: "Organization Contacts",
      description: "Select any existing users in your organization",
      hideIfEmpty: true,
      items: orgUsersToShow.map((ou) => ({
        id: `o_${ou.id}`,
        type: SelectItemType.Option,
        content: (
          <UserDisplay
            name={ou.name}
            email={ou.emailAddress}
            avatar={ou.avatar}
            largeAvatar
          />
        ),
        onClick: () =>
          selectContact(ou.name, ou.emailAddress, false, ou.avatar, ou.id),
      })),
    });
  }

  const vendorContactsToShow = vendorContacts?.filter((vc) =>
    shouldShowContact(vc.name ?? "", vc.emailAddress ?? "")
  );

  if (vendorContactsToShow && vendorContactsToShow.length > 0) {
    options.push({
      type: SelectItemType.Group,
      id: "vendor_contact_divider",
      title: "Vendor Contacts",
      description: "Select any existing vendor contacts",
      hideIfEmpty: true,
      items: vendorContactsToShow.map((vc) => ({
        id: `v_${vc.id}`,
        type: SelectItemType.Option,
        content: (
          <UserDisplay
            name={vc.name ?? ""}
            email={vc.emailAddress ?? ""}
            largeAvatar
          />
        ),
        onClick: () =>
          selectContact(vc.name ?? "", vc.emailAddress ?? "", true, "", vc.id),
      })),
    });
  }

  const numChosenContacts = selectedContacts.length + newContacts.length;
  const disabled =
    !!maxSelectedContacts && numChosenContacts >= maxSelectedContacts;

  const vendorContactElements = selectedContacts.map((sc) => ({
    element: (
      <div
        className={classNames("selected-contact", {
          invalid: sc.emailDomainNotAllowed,
        })}
      >
        {sc.isChecking ? (
          <LoadingIcon size={14} />
        ) : (
          <IconButton
            icon={<i className={"icon-x"} />}
            onClick={() => deselectContact(sc.emailAddress)}
          />
        )}
        <UserDisplay
          name={sc.name}
          email={sc.emailAddress}
          avatar={sc.avatar}
          largeAvatar
        />
      </div>
    ),
    invalid: sc.emailDomainNotAllowed,
    emailAddress: sc.emailAddress,
  }));

  return (
    <div className={"contact-select"}>
      <div className={"add-contacts"}>
        {!hideExistingContacts && (
          <CustomSelect
            placeholder={"Select a contact"}
            onSearchChange={setSearchText}
            items={options}
            disabled={disabled}
            errorText={validationError}
            emptyText={"No contacts available"}
          />
        )}
        <div className={"new-recipient-container"}>
          <Button
            primary={surfaceActive}
            onClick={() => setSurfaceActive(!surfaceActive)}
            disabled={disabled}
          >
            <i className={"icon-x rotate-45"} /> {addText ?? "New Recipient"}
          </Button>
          <NewRecipientSurface
            emailAddressesInUse={emailAddresses}
            active={surfaceActive}
            onClickOutside={() => setSurfaceActive(false)}
            validateNewContactEmail={validateNewContactEmail}
            onContactAdd={(name, title, emailAddress, isVendorContact) => {
              const newContact: ContactDisplay = {
                name: name,
                title,
                emailAddress,
                isVendorContact,
                avatar: "",
                isNewContact: true,
              };

              if (!!validateVerifiedVendorID) {
                const domain = getDomainForEmailAddress(emailAddress);
                if (domain) {
                  newContact.isChecking = checkedDomains[domain] === undefined;
                  newContact.emailDomainNotAllowed =
                    checkedDomains[domain] === false;
                }
              }

              setNewContacts([...newContacts, newContact]);
            }}
          />
        </div>
      </div>
      <div className={"selected-contacts"}>
        <TransitionGroup component={null}>
          {vendorContactElements.map((sc) => (
            <CSSTransition
              key={sc.emailAddress}
              classNames={"fade-transition"}
              timeout={250}
            >
              {sc.invalid ? (
                <SidePopupV2
                  text={`Email domain must be one of ${vendorName}'s domains`}
                  position={"right"}
                >
                  {sc.element}
                </SidePopupV2>
              ) : (
                sc.element
              )}
            </CSSTransition>
          ))}
          {newContacts.map((nc) => (
            <CSSTransition
              key={nc.emailAddress}
              classNames={"fade-transition"}
              timeout={250}
            >
              <div
                className={classNames("selected-contact", {
                  invalid: nc.emailDomainNotAllowed,
                })}
              >
                {nc.isChecking ? (
                  <LoadingIcon size={14} />
                ) : (
                  <IconButton
                    icon={<i className={"icon-x"} />}
                    onClick={() => removeNewContact(nc.emailAddress)}
                  />
                )}
                <UserDisplay
                  name={nc.name}
                  email={nc.emailAddress}
                  largeAvatar
                />
              </div>
            </CSSTransition>
          ))}
        </TransitionGroup>
      </div>
    </div>
  );
};

export default ContactSelect;
