import { Dispatch } from "redux";
import { FetchCyberRiskUrl } from "../../_common/api";
import { IVendorDataLeak } from "../../_common/types/vendorDataLeaks";
import { DefaultRootState } from "react-redux";

export const SET_VENDOR_DATA_LEAKS_RESULT =
  "CYBER_RISK_SET_VENDOR_DATA_LEAKS_RESULT";

export const SET_SINGLE_VENDOR_DATA_LEAKS_RESULT =
  "CYBER_RISK_SET_SINGLE_VENDOR_DATA_LEAKS_RESULT";

const setVendorDataLeaksResult = (result: IGetVendorDataLeaksResp) => ({
  type: SET_VENDOR_DATA_LEAKS_RESULT,
  result,
});

const setSingleVendorDataLeakResult = (result: IVendorDataLeak) => ({
  type: SET_SINGLE_VENDOR_DATA_LEAKS_RESULT,
  result,
});

interface IGetVendorDataLeaksResp {
  contractStartDate?: string;
  numMonitored: number;
  monitoredVendorLimit: number;
  vendorDataLeaks: IVendorDataLeak[];
  totalResults: number;
  search: string;
  limit: number;
  offset: number;
}

export const getVendorDataLeaks = (
  limit: number,
  offset: number,
  searchTerm: string,
  force: boolean
) => {
  return async (
    dispatch: Dispatch,
    getState: () => DefaultRootState
  ): Promise<undefined> => {
    if (!force) {
      // Check if we already have the result cached.
      const existingResult = getState().cyberRisk.vendorDataLeaks;
      if (
        existingResult &&
        existingResult.searchTerm === searchTerm &&
        existingResult.pageOffset === offset &&
        existingResult.pageLimit
      ) {
        return;
      }
    }

    let result: IGetVendorDataLeaksResp;
    try {
      result = (await FetchCyberRiskUrl(
        "cyberresearch/vendordataleaks/v1/",
        { limit, offset, search: searchTerm },
        null,
        dispatch,
        getState,
        undefined,
        undefined
      )) as IGetVendorDataLeaksResp;
    } catch (e) {
      console.error(e);
      throw e;
    }

    dispatch(setVendorDataLeaksResult(result));

    return;
  };
};

export const getSingleVendorDataLeak = (findingId: number, force: boolean) => {
  return async (
    dispatch: Dispatch,
    getState: () => DefaultRootState
  ): Promise<IVendorDataLeak> => {
    if (!force) {
      // Check if we already have the result cached.
      const existingResult =
        getState().cyberRisk.vendorDataLeaks?.allFindings[findingId];
      if (existingResult) {
        return existingResult;
      }
    }

    let result: IVendorDataLeak;
    try {
      result = (await FetchCyberRiskUrl(
        "cyberresearch/vendordataleaks/single/v1/",
        { finding_id: findingId },
        null,
        dispatch,
        getState,
        undefined,
        undefined
      )) as IVendorDataLeak;
    } catch (e) {
      console.error(e);
      throw e;
    }

    dispatch(setSingleVendorDataLeakResult(result));

    return result;
  };
};
