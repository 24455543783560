import Button from "../core/Button";
import "../../style/components/FreeTrialUpsellNavContent.scss";
import CalendarDay from "../CalendarDay";

interface IFreeTrialUpsellNavContentProps {
  daysRemaining: number;
  messageText?: string;
  buttonText?: string;
  buttonAction?: () => void;
}

const FreeTrialUpsellNavContent = (props: IFreeTrialUpsellNavContentProps) => {
  const { daysRemaining, messageText, buttonText, buttonAction } = props;
  return (
    <div className={"free-trial-upsell-nav-content-container"}>
      <div className={"container-header"}>
        <CalendarDay dayOfMonth={daysRemaining} className="header-icon" />
        Trial days remaining
      </div>
      <div className={"container-content"}>
        {messageText && <p>{messageText}</p>}
        {buttonText && buttonAction && (
          <Button onClick={buttonAction}>{buttonText}</Button>
        )}
      </div>
    </div>
  );
};

export default FreeTrialUpsellNavContent;
