import { FC, useState } from "react";
import { StylesConfig, ValueType } from "react-select";
import {
  OptionType,
  SelectV2Multi,
} from "../../../_common/components/SelectV2";
import { SlidePanelSection } from "./SlidePanelSection";
import ColorCheckbox from "../ColorCheckbox";
import Icon from "../../../_common/components/core/Icon";
import LabelList from "../LabelList";
import { SidePopupV2 } from "../../../_common/components/DismissablePopup";

const supportedTypes = [
  { id: "original", name: "original" },
  { id: "addition", name: "addition" },
  { id: "bitsquatting", name: "bitsquatting" },
  { id: "homoglyph", name: "homoglyph" },
  { id: "hyphenation", name: "hyphenation" },
  { id: "insertion", name: "insertion" },
  { id: "omission", name: "omission" },
  { id: "repetition", name: "repetition" },
  { id: "replacement", name: "replacement" },
  { id: "subdomain", name: "subdomain" },
  { id: "transposition", name: "transposition" },
  { id: "various", name: "various" },
  { id: "dictionary", name: "dictionary" },
  { id: "vowel-swap", name: "vowel-swap" },
  { id: "tld-swap", name: "tld-swap" },
  { id: "cyrillic", name: "cyrillic" },
  { id: "plural", name: "plural" },
];

interface ITyposquatTypesFilterOwnProps {
  selectedOptions: string[];
  onChange: (changeObj: {
    selectedOptions?: string[];
    doNotMatch?: boolean;
  }) => void;
  doNotMatch?: boolean;
  title?: string;
  startExpanded?: boolean;
}

const TyposquatTypesFilter: FC<ITyposquatTypesFilterOwnProps> = ({
  selectedOptions,
  onChange,
  doNotMatch = false,
  title = "Filter by permutation type",
  startExpanded = false,
}) => {
  const [expanded, setExpanded] = useState(
    startExpanded || selectedOptions.length > 0
  );

  const activeRadioButton = doNotMatch ? "not" : "any";

  const selectOnChange = (selectedOptions: ValueType<OptionType, true>) => {
    const changeObj = {
      selectedOptions: selectedOptions
        ? selectedOptions.map(({ value }) => value as string)
        : [],
    };
    onChange(changeObj);
  };

  const options = supportedTypes
    .map(({ id, name }) => ({
      value: id,
      label: name,
    }))
    .sort((a, b) => {
      return a.label.toLowerCase().localeCompare(b.label.toLowerCase());
    });

  const selectValue: OptionType[] = [
    ...selectedOptions.map(
      (id) => options.find(({ value }) => value === id) as OptionType
    ),
  ];

  const selectedTypes = selectValue.map(({ value }) => ({
    ...[...supportedTypes].find((label) => value === label.id),
    removeable: true,
    large: true,
    constrained: true,
    onRemoveClick: () =>
      selectOnChange(selectValue.filter((option) => value !== option.value)),
  }));

  const customStyles: StylesConfig<OptionType, true> = {
    option: (provided, _state) => {
      return { ...provided };
    },
  };

  return (
    <SlidePanelSection
      title={title}
      expanded={expanded}
      toggleExpand={() => setExpanded(!expanded)}
    >
      <div className="filter-label-options">
        <div className="label-filter-checkbox">
          <ColorCheckbox
            radio
            label="Match Any"
            helpPopup="Filter by matching any of the selected permutation types"
            onClick={() => onChange({ doNotMatch: false })}
            checked={activeRadioButton === "any"}
          />
          <SidePopupV2
            className="help-icon"
            text="Show typosquats that are of any of these specific permutation types"
            position={"left"}
          >
            <Icon name="info" />
          </SidePopupV2>
        </div>
        <div className="label-filter-checkbox">
          <ColorCheckbox
            radio
            label="Do Not Include"
            onClick={() => onChange({ doNotMatch: true })}
            checked={activeRadioButton === "not"}
          />
          <SidePopupV2
            className="help-icon"
            text="Show typosquats that are not of any of these specific permutation types"
            position={"left"}
          >
            <Icon name="info" />
          </SidePopupV2>
        </div>
      </div>
      <SelectV2Multi
        placeholder="Type to search types"
        options={options}
        value={selectValue}
        onChange={selectOnChange}
        controlShouldRenderValue={false}
        captureMenuScroll={false}
        styles={customStyles}
      />
      <LabelList labels={selectedTypes} />
    </SlidePanelSection>
  );
};

export default TyposquatTypesFilter;
