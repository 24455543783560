import { FC, useState } from "react";
import { SelectV2Multi } from "../../../_common/components/SelectV2";
import { SlidePanelSection } from "./SlidePanelSection";

interface IIPCountryFilterProps {
  ipCountries?: string[];
  selectedCountries: string[];
  onChange: (selectedCountries: string[]) => void;
  title?: string;
  startExpanded?: boolean;
}

const IIPCountryFilter: FC<IIPCountryFilterProps> = ({
  ipCountries = [],
  selectedCountries = [],
  onChange,
  title = "Filter by IP country",
  startExpanded = false,
}) => {
  const [expanded, setExpanded] = useState(
    startExpanded || selectedCountries.length > 0
  );

  return (
    <SlidePanelSection
      title={title}
      toggleExpand={() => setExpanded(!expanded)}
      expanded={expanded}
    >
      <SelectV2Multi
        placeholder="Type to search for country"
        options={ipCountries.map((c) => {
          return { label: c, value: c };
        })}
        value={selectedCountries.map((c) => {
          return { label: c, value: c };
        })}
        onChange={(selectedOptions) => {
          onChange(
            selectedOptions ? selectedOptions.map((o) => o.value as string) : []
          );
        }}
      />
    </SlidePanelSection>
  );
};

export default IIPCountryFilter;
