import { ReactNode, useEffect, useRef, useState } from "react";
import "../style/components/ProgressBar.scss";
import classNames from "classnames";

export interface IProgressBarProps {
  progress: number; // Float between 0 and 1
  secondaryProgress?: number;
  leftText?: ReactNode;
  rightText?: ReactNode;
  className?: string;
  progressMilestones?: ProgressMilestone[];
}

// ProgressMilestones allow you to define condinditional coloring of the progress bar
// For example, default color to 50% then yellow to 90% then red.
// progressThreshold represents the percentage from which the class defined in
// className should be applied. For example:
// {
// progressThreshold: 0.9
// className: "red";
// }
// would apply the class red when the progress is greater than 0.9
export type ProgressMilestone = {
  progressThreshold: number; // Float between 0 and 1
  className: string;
};

const ProgressBar = (props: IProgressBarProps) => {
  const {
    progress,
    leftText,
    rightText,
    className,
    secondaryProgress,
    progressMilestones,
  } = props;
  const primaryPct = `${Math.min(progress, 1) * 100}%`;
  // handle the case where secondaryProgress is > 100%
  const _secondaryProgress = Math.min(secondaryProgress ?? 0, 1);
  // pre-generate the percentage string
  const secondaryPct = `${Math.min(_secondaryProgress - progress, 1) * 100}%`;

  const progressClass = () => {
    let progressClassName = "";
    // We keep track of the highest threshold applied so we don't end up
    // coloring the bar with a lower threshold if they are given out of order
    let highestThreshold = 0;
    progressMilestones?.forEach((progressMilestone) => {
      if (
        progress > progressMilestone.progressThreshold &&
        progressMilestone.progressThreshold > highestThreshold
      ) {
        highestThreshold = progressMilestone.progressThreshold;
        progressClassName = progressMilestone.className;
      }
    });
    return progressClassName;
  };

  return (
    <div className={classNames("progress-bar", className)}>
      {(leftText || rightText) && (
        <div className="top-text">
          {leftText && <div>{leftText}</div>}
          {rightText && <div className="right-text">{rightText}</div>}
        </div>
      )}
      <div className="bar">
        <div
          className={classNames(
            "bar-inner",
            {
              ["has-secondary"]: secondaryProgress,
            },
            progressClass()
          )}
          style={{ width: primaryPct }}
        />
        {secondaryProgress && (
          <div
            className={"secondary-bar-inner"}
            style={{
              width: secondaryPct,
              left: primaryPct,
            }}
          />
        )}
      </div>
    </div>
  );
};

export default ProgressBar;

interface ITimerProgressBarProps {
  // Start/stop the timer progress bar
  isRunning: boolean;

  // Optionally set the available time
  availableTime: number;

  // Optionally set the time update split increments
  timeUpdateSplit: number;

  // Optionally randomise progress per tick, can't be changed after loaded
  randomiseProgressPerTick: boolean;

  // Optionally complete the progress bar when stopped (if stopped early)
  shouldCompleteOnStop: boolean;

  // Optionally restart (from 0) the progress bar on next run after completion
  shouldRestartAfterCompletion: boolean;

  initialProgress?: number;
}

// Timed version of progress bar
export const TimerProgressBar = (props: ITimerProgressBarProps) => {
  const [progress, setProgress] = useState(() =>
    props.initialProgress ? props.initialProgress / 100 : 0
  );

  const progressRef = useRef(
    props.initialProgress ? props.initialProgress / 100 : 0
  );
  const isRunningRef = useRef(props.isRunning);
  const iterations = useRef(0);

  const getProgressIncrement = () => {
    if (!props.randomiseProgressPerTick) {
      return 1 / (props.availableTime / props.timeUpdateSplit);
    }

    // Random incrementing with front-loading progress
    const remaining = 1 - progressRef.current;
    const minChunk = (props.timeUpdateSplit / props.availableTime) * 6;
    return remaining * Math.min(Math.random() / 5, minChunk);
  };

  const checkProgress = () => {
    if (isRunningRef.current && progress < 1) {
      const newProgressPoint = Math.min(
        progressRef.current + getProgressIncrement(),
        1
      );
      progressRef.current = newProgressPoint;
      iterations.current = iterations.current + 1;
      setProgress(newProgressPoint);
    }
  };

  // Start/stop the timer
  useEffect(() => {
    isRunningRef.current = props.isRunning;
    if (props.isRunning) {
      checkProgress();
    }

    if (
      !props.isRunning &&
      progress > (props.initialProgress ? props.initialProgress / 100 : 0) &&
      props.shouldCompleteOnStop
    ) {
      progressRef.current = 1;
      setProgress(1);
    } else if (
      props.isRunning &&
      progress === 1 &&
      props.shouldRestartAfterCompletion
    ) {
      progressRef.current = 0;
      setProgress(0);
    }
  }, [
    props.isRunning,
    props.shouldCompleteOnStop,
    props.shouldRestartAfterCompletion,
  ]);

  // Create our timer on load, and clear it when unloaded
  useEffect(() => {
    const timerId = setInterval(checkProgress, props.timeUpdateSplit);
    return () => {
      clearInterval(timerId);
    };
  }, []);
  return <ProgressBar progress={progress} />;
};

TimerProgressBar.defaultProps = {
  availableTime: 60000,
  timeUpdateSplit: 1500,
  randomiseProgressPerTick: false,
  shouldCompleteOnStop: false,
  shouldRestartAfterCompletion: false,
};
