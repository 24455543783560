import { FC, useState } from "react";
import { AppRouteParams } from "../UserBaseNavItems";
import { useRouteMatch } from "react-router-dom";
import UserBaseAPI from "../api/userbase.api";
import ReportCard from "../../_common/components/ReportCard";
import XTable, {
  IXTableColumnHeader,
  IXTableRow,
  XTableCell,
} from "../../_common/components/core/XTable";
import { AppEvent, AppEventType, AppStatus } from "../api/types";
import { formatDateAsLocal, formatTimeAsLocal } from "../../_common/helpers";
import { usePagination } from "../../_common/hooks";
import Icon from "../../_common/components/core/Icon";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import "./Timeline.scss";

const AppEventCell: FC<AppEvent> = ({
  eventType,
  metadata,
  userName,
  userEmail,
}) => {
  let action: string;
  const noun = userName || userEmail;

  switch (eventType) {
    case AppEventType.ReviewedAppUpdateEvent:
      let verb: string;
      switch (metadata.status) {
        case AppStatus.ApprovedAppStatus:
          verb = "approved the app";
          if (metadata.approvedForAll) {
            verb += " for all";
          } else if (
            metadata.approvedTeams &&
            metadata.approvedTeams.length > 0
          ) {
            verb += ` for teams: ${metadata.approvedTeams.join(", ")}`;
          } else if (
            metadata.approvedRoles &&
            metadata.approvedRoles.length > 0
          ) {
            verb += ` for roles: ${metadata.approvedRoles.join(", ")}`;
          }
          break;
        case AppStatus.NotApprovedAppStatus:
          verb = "marked the app as not approved";
          break;
        case AppStatus.InReviewAppStatus:
          verb = "marked the app as in review";
          break;
        case AppStatus.NeedsReviewAppStatus:
          throw Error("App event can't have needs review status");
      }
      action = `${noun} ${verb}`;
      if (metadata.note) {
        action += ` with note: ${metadata.note}`;
      }
      break;
    case AppEventType.AppFirstDetectedEvent:
      action = "App first detected";
      break;
  }

  return <XTableCell key={"action"}>{action}</XTableCell>;
};

const AppTimeline: FC = ({}) => {
  const match = useRouteMatch<AppRouteParams>();
  const [isCollapsed, setIsCollapsed] = useState(true);

  const { data, isLoading } = UserBaseAPI.useGetUserBaseAppTimelineV1Query({
    app: match.params.appName,
  });

  const columnHeaders: IXTableColumnHeader[] = [
    {
      id: "date",
      text: "Date",
      sortable: false,
      className: "date-col",
    },
    {
      id: "time",
      text: "Time",
      sortable: false,
      className: "time-col",
    },
    {
      id: "action",
      text: "Action",
      sortable: false,
    },
  ];

  const [currentPage, currentPageNumber, totalPages, setCurrentPage] =
    usePagination(
      (data?.events ?? []).slice().sort((a, b) => (a.at < b.at ? 1 : -1)),
      10
    );

  const rows = currentPage.map<IXTableRow>((e) => {
    return {
      id: e.at,
      cells: [
        <XTableCell key={"date"}>{formatDateAsLocal(e.at)}</XTableCell>,
        <XTableCell key={"time"}>{formatTimeAsLocal(e.at)}</XTableCell>,
        AppEventCell(e),
      ],
    };
  });

  if (!isLoading && rows.length === 0) {
    return <></>;
  }

  return (
    <ReportCard newStyles className="userbase-event-timeline">
      <div className="header" onClick={() => setIsCollapsed(!isCollapsed)}>
        Timeline
        <div className={"header-chevron"}>
          <Icon name="chevron" direction={isCollapsed ? 180 : 0} />
        </div>
      </div>
      <div className={"timeline-container"}>
        <TransitionGroup component={null}>
          {!isCollapsed && (
            <CSSTransition
              key={"expand-timeline"}
              timeout={250}
              classNames="expand"
            >
              <XTable
                stickyColumnHeaders={false}
                numLoadingRows={3}
                className={"event-timeline-table"}
                columnHeaders={columnHeaders}
                rows={rows}
                loading={isLoading}
                pagination={{
                  currentPage: currentPageNumber,
                  totalPages,
                  onPageChange: setCurrentPage,
                  hidePaginationIfSinglePage: true,
                }}
              />
            </CSSTransition>
          )}
        </TransitionGroup>
      </div>
    </ReportCard>
  );
};

export default AppTimeline;
