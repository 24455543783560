import { useCallback, useRef } from "react";
import {
  ContentLibraryDocument,
  GetDocumentsSortByCol,
} from "./contentLibrary.types";
import { useAppDispatch } from "../../_common/types/reduxHooks";
import { useConfirmationModalV2 } from "../../_common/components/modals/ConfirmationModalV2";
import ContentLibraryAPI, {
  getDocumentsListV1Resp,
} from "../api/contentLibraryAPI";
import { LogError, pluralise } from "../../_common/helpers";
import InfoBanner, { BannerType } from "../../vendorrisk/components/InfoBanner";
import {
  addDefaultSuccessAlert,
  addDefaultUnknownErrorAlert,
} from "../../_common/reducers/messageAlerts.actions";
import md5 from "md5";

// Returns a function that determines if any of the specified document UUIDs are included in the shared profile.
export const useAnyDocumentUUIDsInSharedProfile = (
  documents?: ContentLibraryDocument[] | ContentLibraryDocument
) => {
  return useCallback(
    (uuids: string[]) => {
      if (!documents) {
        return false;
      }

      return (
        (Array.isArray(documents) ? documents : [documents]).findIndex(
          (d) => d.includeInSharedProfile && uuids.includes(d.uuid)
        ) > -1
      );
    },
    [documents]
  );
};

// Returns a function to set a series of documents to archived (or unarchived), with a confirmation modal.
export const useSetDocumentsArchived = (
  documents?: ContentLibraryDocument[] | ContentLibraryDocument
) => {
  const dispatch = useAppDispatch();
  const anyDocumentUUIDsInSharedProfile =
    useAnyDocumentUUIDsInSharedProfile(documents);
  const [openConfirmationModal, confirmationModal] = useConfirmationModalV2();
  const [archiveDocuments] =
    ContentLibraryAPI.useArchiveContentLibraryDocumentsMutation();

  const setDocumentsArchived = useCallback(
    (uuids: string[], setArchived: boolean) => {
      const appearsInSharedProfile = anyDocumentUUIDsInSharedProfile(uuids);

      const description = (
        <>
          <p>
            {setArchived
              ? `Archiving ${pluralise(
                  uuids.length,
                  "this document",
                  "these documents"
                )} will mean ${
                  uuids.length > 1 ? "they" : "it"
                } will no longer show in your Content Library. You will still be able to unarchive ${pluralise(
                  uuids.length,
                  "it",
                  "them"
                )} later.`
              : `Unarchiving ${pluralise(
                  uuids.length,
                  "this document",
                  "these documents"
                )} will mean ${pluralise(
                  uuids.length,
                  "it",
                  "they"
                )} will start showing in the Content Library.`}
          </p>
          {appearsInSharedProfile && (
            <InfoBanner
              type={BannerType.WARNING}
              message={`${pluralise(
                uuids.length,
                "This document currently appears",
                "One or more documents currently appear"
              )} in the Trust Page. Archiving will remove ${pluralise(
                uuids.length,
                "it",
                "them"
              )} from the Trust Page immediately.`}
            />
          )}
        </>
      );

      openConfirmationModal({
        title: `${setArchived ? "Archive" : "Unarchive"} ${
          uuids.length === 1 ? "this document" : `${uuids.length} documents`
        }`,
        description,
        buttonText: setArchived ? "Archive" : "Unarchive",
        buttonAction: async () => {
          try {
            await archiveDocuments({
              uuids,
              setArchived,
            }).unwrap();
            dispatch(
              addDefaultSuccessAlert(
                `Successfully ${
                  setArchived ? "archived" : "unarchived"
                } ${pluralise(uuids.length, "document", "documents")}`
              )
            );
          } catch (e) {
            console.error(e);
            dispatch(
              addDefaultUnknownErrorAlert(
                `Error ${setArchived ? "archiving" : "unarchiving"} ${pluralise(
                  uuids.length,
                  "document",
                  "documents"
                )}`
              )
            );
            throw e;
          }
        },
      });
    },
    [
      archiveDocuments,
      dispatch,
      openConfirmationModal,
      anyDocumentUUIDsInSharedProfile,
    ]
  );

  return [setDocumentsArchived, confirmationModal] as const;
};

// useDeleteDocuments returns a modal and callback to set one or more documents to deleted.
export const useDeleteDocuments = (onDelete?: () => void) => {
  const dispatch = useAppDispatch();
  const [openConfirmationModal, confirmationModal] = useConfirmationModalV2();
  const [deleteDocumentsMutation] =
    ContentLibraryAPI.useDeleteContentLibraryDocumentsMutation();

  // Keep track of whether we're in the process of deleting so we can tell RTK query
  // not to try and refetch.
  const isDeleting = useRef(false);

  const deleteDocuments = useCallback(
    (uuids: string[]) => {
      const description = (
        <>
          <p>
            This will delete the{" "}
            {pluralise(uuids.length, "document", "documents")} from the Content
            Library and cannot be undone.
          </p>
        </>
      );

      openConfirmationModal({
        title: `Are you sure?`,
        description,
        buttonText: "Delete",
        iconClass: "cr-icon-trash",
        dangerousAction: true,
        buttonAction: async () => {
          try {
            isDeleting.current = true;
            await deleteDocumentsMutation({
              uuids,
            }).unwrap();
            onDelete?.();
            dispatch(
              addDefaultSuccessAlert(
                `Successfully deleted ${pluralise(
                  uuids.length,
                  "document",
                  "documents"
                )}`
              )
            );
          } catch (e) {
            isDeleting.current = false;
            console.error(e);
            dispatch(
              addDefaultUnknownErrorAlert(
                `Error deleting ${pluralise(
                  uuids.length,
                  "document",
                  "documents"
                )}`
              )
            );
            throw e;
          }
        },
      });
    },
    [dispatch, openConfirmationModal, deleteDocumentsMutation, onDelete]
  );

  return [deleteDocuments, confirmationModal, isDeleting] as const;
};

// useFindDuplicateDocumentsForFile takes a selected file and returns a callback that can retrieve
// a list of unarchived documents with the same MD5 hash. Errors are logged but not rethrown.
// If no file is selected, it will return an empty result.
export const useFindDuplicateDocumentsForFile = (files?: File[]) => {
  const [getDocuments] =
    ContentLibraryAPI.useLazyGetContentLibraryDocumentsListQuery();

  return useCallback(async () => {
    const emptyResult: getDocumentsListV1Resp = {
      documents: [],
      totalResults: 0,
      sharedUsers: {},
    };

    if (!files || files.length < 1) {
      return emptyResult;
    }

    try {
      const fileMD5 = [] as string[];
      for (const file of files) {
        fileMD5.push(md5(new Uint8Array(await file.arrayBuffer())));
      }

      return await getDocuments({
        sortBy: {
          col: GetDocumentsSortByCol.Updated,
          desc: true,
        },
        filterBy: {
          md5: fileMD5,
          documentTypes: [],
          sharedProfileOnly: false,
          archived: false,
        },
        limit: 5,
        offset: 0,
      }).unwrap();
    } catch (e) {
      LogError("error finding duplicate documents for files", e);
      const emptyResult: getDocumentsListV1Resp = {
        documents: [],
        totalResults: 0,
        sharedUsers: {},
      };
      return emptyResult;
    }
  }, [files, getDocuments]);
};
