import { FC } from "react";
import classnames from "classnames";
import "../style/components/CheckMark.scss";

interface CheckMarkProps {
  disabled?: boolean;

  filled?: boolean;

  // If undefined, will be treated as ambivalent
  checked?: boolean;

  // Default to 24px
  size?: number;
}

const CheckMark: FC<CheckMarkProps> = ({
  disabled,
  filled,
  checked,
  size = 20,
}) => {
  const checkInner = checked ? (
    <i className={"cr-icon-check"} />
  ) : checked !== false ? (
    <div className={"ambivalent"}>-</div>
  ) : (
    <></>
  );

  const classes = classnames("check-mark", {
    filled: filled,
    disabled: disabled,
    checked: checked,
  });

  return (
    <div
      className={classes}
      style={{ height: size, width: size, borderRadius: size / 2 }}
    >
      {checkInner}
    </div>
  );
};

export default CheckMark;
