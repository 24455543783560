import {
  DropdownAutoscroller,
  DropdownItem,
  DropdownSeparator,
  DropdownSubheader,
} from "../core/DropdownV2";
import { FC } from "react";
import { useHistory } from "react-router-dom";
import { IUserData } from "../../types/redux";
import { useAppSelector } from "../../types/reduxHooks";
import { canAccessSystemAdmin } from "../../selectors/permissions";
import { OrgAccessAuditLog, UserManageAuditLog } from "../../permissions";
import "../../style/components/navigation/MultiProductUserMenu.scss";
import { IUserOrganisation } from "../../types/organisations";

interface MultiProductUserMenuProps {
  userData: IUserData;
  onLogoutClick: () => void;
  onSwitchOrgClick: (orgId: number, name: string) => void;
}

export const MultiProductUserMenu: FC<MultiProductUserMenuProps> = (props) => {
  const history = useHistory();

  const showSystemAdmin = useAppSelector((state) =>
    canAccessSystemAdmin(state)
  );

  const showAuditLog =
    props.userData.userPermissions.includes(UserManageAuditLog) &&
    props.userData.orgPermissions.includes(OrgAccessAuditLog);

  // Add a dropdown item for each org the user has access to, sorted by name
  const sortedOrgs: IUserOrganisation[] = [];
  let impersonatingOrg;

  if (props.userData.orgList) {
    for (let i = 0; i < props.userData.orgList.length; i++) {
      if (
        props.userData.impersonatingOrgID > 0 &&
        props.userData.impersonatingOrgID === props.userData.orgList[i].id
      ) {
        impersonatingOrg = props.userData.orgList[i];
        continue;
      }
      sortedOrgs.push(props.userData.orgList[i]);
    }
  }

  sortedOrgs.sort((a, b) => a.name.localeCompare(b.name));

  return (
    <DropdownAutoscroller className="multi-product-user-menu" popsUpFromBottom>
      <DropdownItem
        className={"user-info"}
        onClick={() => history.push("/profile")}
      >
        <div className="user-name">{props.userData.name}</div>
        <div className="user-email">{props.userData.emailAddress}</div>
      </DropdownItem>
      {(showSystemAdmin || showAuditLog) && (
        <>
          <DropdownSeparator />
          {showAuditLog && (
            <DropdownItem onClick={() => history.push("/audit_log")}>
              Audit Log
            </DropdownItem>
          )}
          {showSystemAdmin && (
            <DropdownItem onClick={() => history.push("/admin")}>
              System Admin
            </DropdownItem>
          )}
        </>
      )}
      {props.userData.impersonatingOrgID > 0 && (
        <>
          <DropdownSubheader>Impersonating</DropdownSubheader>
          <DropdownItem>{impersonatingOrg?.name}</DropdownItem>
          <DropdownSeparator />
        </>
      )}
      {sortedOrgs.length > 0 && (
        <>
          <DropdownSeparator />
          <DropdownSubheader>Switch account</DropdownSubheader>
          {sortedOrgs.map((o) => (
            <DropdownItem
              key={o.id}
              className={`org-list-dropdown-item ${
                props.userData.currentOrgID === o.id ? "active-org" : ""
              }`}
              onClick={
                props.userData.currentOrgID === o.id
                  ? undefined
                  : () => props.onSwitchOrgClick(o.id, o.name)
              }
            >
              <div>{o.name}</div>
              {props.userData.currentOrgID === o.id && (
                <div className="cr-icon-check active-check" />
              )}
            </DropdownItem>
          ))}
          <DropdownSeparator />
        </>
      )}
      <DropdownItem onClick={props.onLogoutClick}>Sign out</DropdownItem>
    </DropdownAutoscroller>
  );
};
