import { Node, NodeType } from "../types/types";
import {
  SurveyBuilderActions,
  ADD_CHILD_NODE,
  ADD_CONDITIONAL_EXPRESSION,
  ADD_SELECT_NODE_ANSWER,
  CLEAR_HISTORY,
  DELETE_CONDITIONAL_EXPRESSION,
  DELETE_NODE,
  DELETE_SELECT_NODE_ANSWER,
  REMOVE_SURVEY_VALIDATION_ERRORS_FOR_NODE,
  REORDER_TREE,
  SET_CURRENT_FOCUSED_NODEID,
  SET_EDIT_RISK_MODAL_NODE_ID,
  SET_FRAMEWORK,
  SET_FULL_SURVEY,
  SET_INCLUDE_TOC,
  SET_CUSTOM_NUMBERING,
  SET_SURVEY_DRAFT_IN_PROGRESS,
  SET_SURVEY_LOCKED_BY,
  SET_SURVEY_SAVING,
  SET_SURVEY_VALIDATION_ERRORS,
  UPDATE_CONDITIONAL_EXPRESSION,
  UPDATE_NODE,
  UPDATE_SELECT_NODE_ANSWER,
  SET_SURVEY_SAVE_ERROR,
} from "./actions";
import { Expression } from "../types/conditionalLogic";
import { produce } from "immer";
import { createUUID, NodeSummary } from "../helpers";
import { SurveyFramework } from "../types/frameworks";
import { ValidationError, ValidationErrors } from "../types/validation";
import { IUserMini } from "../../_common/types/user";
import { nodeTypeIconTypeToNodeType } from "../components/NodeTypeIcon";
import { SurveyUsageType } from "../../_common/types/surveyTypes";
import { Reducer } from "redux";

// The functionality of this reducer is mirrored on the backend in survey/surveybuilder/actions.go.
// Whenever making any changes to actions or the reducer, make sure you check the backend to
// see if any changes need to be made there too.

export interface Survey {
  surveyId: string;
  name: string;
  rootNodeId: string;
  nodes: {
    [nodeId: string]: Node | undefined;
  };
  framework: SurveyFramework;
  includeTOC: boolean;
  customNumbering: boolean;
  saving: boolean;
  validationErrors: ValidationErrors;
  isPublished: boolean;
  draftInProgress: boolean;
  enabled: boolean;
  lockedBy?: IUserMini; // Set if currently locked for editing by another user
  currentFocusedNodeId?: string; // Keep track of the node ID that has the user's focus
  editRiskModalNodeId?: string;

  // If trackHistory is true, we keep track of every action dispatched
  // so they can be used as partial updates on autosave hooks.
  trackHistory: boolean;
  actionHistory: SurveyBuilderActions[];
  actionHistoryUpdatedAt: number; // Set this to the current ms whenever we update actionHistory. This helps to work out when we need to run an autosave.
  usageType: SurveyUsageType;
  sourceTypeId?: number;

  saveError?: boolean;
}

export function createBlankSurvey(
  surveyId: string,
  trackHistory: boolean
): Survey {
  const rootNodeId = createUUID();
  return {
    surveyId,
    usageType: SurveyUsageType.Security,
    name: "",
    framework: SurveyFramework.NONE,
    includeTOC: true,
    customNumbering: false,
    saving: false,
    validationErrors: {
      invalidNodeIds: [],
      errors: {},
    },
    isPublished: false,
    draftInProgress: false,
    enabled: false,
    trackHistory,
    actionHistory: [],
    actionHistoryUpdatedAt: 0,
    rootNodeId,
    nodes: {
      [rootNodeId]: {
        type: NodeType.Section,
        nodeId: rootNodeId,
        title: "Untitled Questionnaire Template",
        mainText:
          "Thank you for taking the time to complete this security questionnaire.",
        childNodeIds: [],
      },
    },
  };
}

export interface SurveyBuilderState {
  surveys: {
    [surveyId: string]: Survey;
  };
}

export const surveyBuilderInitialState: SurveyBuilderState = {
  surveys: {},
};

export const surveyBuilderReducer: Reducer<SurveyBuilderState> = (
  state: SurveyBuilderState = surveyBuilderInitialState,
  untypedAction
): SurveyBuilderState => {
  const action = untypedAction as unknown as SurveyBuilderActions;
  switch (action.type) {
    case SET_FULL_SURVEY: {
      // Blat the entire state with a new one.
      return {
        ...state,
        surveys: {
          ...state.surveys,
          [action.surveyId]: action.survey,
        },
      };
    }
    case CLEAR_HISTORY: {
      return {
        ...state,
        surveys: {
          ...state.surveys,
          [action.surveyId]: {
            ...state.surveys[action.surveyId],
            actionHistory: [],
          },
        },
      };
    }
    case SET_SURVEY_SAVING: {
      return {
        ...state,
        surveys: {
          ...state.surveys,
          [action.surveyId]: {
            ...state.surveys[action.surveyId],
            saving: action.saving,
          },
        },
      };
    }
    case SET_SURVEY_DRAFT_IN_PROGRESS: {
      return {
        ...state,
        surveys: {
          ...state.surveys,
          [action.surveyId]: {
            ...state.surveys[action.surveyId],
            draftInProgress: action.draftInProgress,
          },
        },
      };
    }
    case SET_SURVEY_VALIDATION_ERRORS: {
      return {
        ...state,
        surveys: {
          ...state.surveys,
          [action.surveyId]: {
            ...state.surveys[action.surveyId],
            validationErrors: action.validationErrors,
          },
        },
      };
    }
    case REMOVE_SURVEY_VALIDATION_ERRORS_FOR_NODE: {
      return {
        ...state,
        surveys: {
          ...state.surveys,
          [action.surveyId]: {
            ...state.surveys[action.surveyId],
            validationErrors: removeValidationErrorsForNodeId(
              state.surveys[action.surveyId].validationErrors,
              action.nodeId,
              action.validationErrors
            ),
          },
        },
      };
    }
    case SET_SURVEY_LOCKED_BY: {
      return {
        ...state,
        surveys: {
          ...state.surveys,
          [action.surveyId]: {
            ...state.surveys[action.surveyId],
            lockedBy: action.lockedBy,
          },
        },
      };
    }
    case SET_CURRENT_FOCUSED_NODEID: {
      return {
        ...state,
        surveys: {
          ...state.surveys,
          [action.surveyId]: {
            ...state.surveys[action.surveyId],
            currentFocusedNodeId: action.nodeId,
          },
        },
      };
    }
    case SET_EDIT_RISK_MODAL_NODE_ID: {
      return {
        ...state,
        surveys: {
          ...state.surveys,
          [action.surveyId]: {
            ...state.surveys[action.surveyId],
            editRiskModalNodeId: action.nodeId,
          },
        },
      };
    }
    case SET_FRAMEWORK: {
      return {
        ...state,
        surveys: {
          ...state.surveys,
          [action.surveyId]: {
            ...state.surveys[action.surveyId],
            actionHistory: state.surveys[action.surveyId].trackHistory
              ? updateActionHistory(
                  state.surveys[action.surveyId].actionHistory,
                  action
                )
              : [],
            actionHistoryUpdatedAt: new Date().getTime(),
            framework: action.framework,
          },
        },
      };
    }
    case SET_INCLUDE_TOC: {
      return {
        ...state,
        surveys: {
          ...state.surveys,
          [action.surveyId]: {
            ...state.surveys[action.surveyId],
            actionHistory: state.surveys[action.surveyId].trackHistory
              ? updateActionHistory(
                  state.surveys[action.surveyId].actionHistory,
                  action
                )
              : [],
            actionHistoryUpdatedAt: new Date().getTime(),
            includeTOC: action.includeTOC,
          },
        },
      };
    }
    case SET_CUSTOM_NUMBERING: {
      return {
        ...state,
        surveys: {
          ...state.surveys,
          [action.surveyId]: {
            ...state.surveys[action.surveyId],
            actionHistory: state.surveys[action.surveyId].trackHistory
              ? updateActionHistory(
                  state.surveys[action.surveyId].actionHistory,
                  action
                )
              : [],
            actionHistoryUpdatedAt: new Date().getTime(),
            customNumbering: action.customNumbering,
          },
        },
      };
    }
    case ADD_CHILD_NODE: {
      // Try and find the parent we need to update.
      const parentNode =
        state.surveys[action.surveyId].nodes[action.newNode.parentId || ""];
      if (!parentNode || parentNode.type !== NodeType.Section) {
        console.error(
          "attempted to add a child node to a nonexistent or non-Section parent"
        );
        return state;
      }

      // Add the new node ID to the list of child nodes on its parent.
      const childNodeIds = [...parentNode.childNodeIds];
      if (childNodeIds.length < action.addAtIndex) {
        console.error("attempted to add a child node at an invalid index");
        return state;
      }

      childNodeIds.splice(action.addAtIndex, 0, {
        id: action.newNode.nodeId,
        type: action.newNode.type,
      });

      return {
        ...state,
        surveys: {
          ...state.surveys,
          [action.surveyId]: {
            ...state.surveys[action.surveyId],
            actionHistory: state.surveys[action.surveyId].trackHistory
              ? updateActionHistory(
                  state.surveys[action.surveyId].actionHistory,
                  action
                )
              : [],
            actionHistoryUpdatedAt: new Date().getTime(),
            nodes: {
              ...state.surveys[action.surveyId].nodes,
              [parentNode.nodeId]: {
                ...parentNode,
                childNodeIds,
              },
              [action.newNode.nodeId]: action.newNode,
            },
          },
        },
      };
    }

    case UPDATE_NODE: {
      // Make sure the node exists in the tree
      const node = state.surveys[action.surveyId].nodes[action.nodeId];
      if (!node) {
        console.error(
          `attempted to update a node (id "${action.nodeId}") that doesn't exist`
        );
        return state;
      }

      const newNode = {
        ...node,
        ...action.fieldsToUpdate,
      } as Node;

      // Determine whether this update should fix up any validation errors.
      const nodeValidationErrors =
        state.surveys[action.surveyId].validationErrors.errors[action.nodeId];
      const errorsToRemove: ValidationError[] = [];

      if (nodeValidationErrors) {
        // If this is a section node with a missing text error, resolve the error if we've updated
        // the title or the main text.
        if (
          nodeValidationErrors[ValidationError.sectionMissingText] &&
          node.type === NodeType.Section &&
          (node.mainText !== "" || node.title !== "")
        ) {
          errorsToRemove.push(ValidationError.sectionMissingText);
        }

        // Resolve the missing number error if needed.
        if (
          nodeValidationErrors[ValidationError.nodeMissingNumber] &&
          (node.customNumber !== "" ||
            !state.surveys[action.surveyId].customNumbering)
        ) {
          errorsToRemove.push(ValidationError.nodeMissingNumber);
        }

        // Resolve the non unique number.
        if (nodeValidationErrors[ValidationError.nodeNonUniqueNumber]) {
          errorsToRemove.push(ValidationError.nodeNonUniqueNumber);
        }

        // Resolve risk errors if need be
        if (
          nodeValidationErrors[ValidationError.riskMissingName] &&
          node.type === NodeType.Risk &&
          node.name !== ""
        ) {
          errorsToRemove.push(ValidationError.riskMissingName);
        }
        if (
          nodeValidationErrors[ValidationError.riskMissingPassName] &&
          node.type === NodeType.Risk &&
          node.passName !== ""
        ) {
          errorsToRemove.push(ValidationError.riskMissingPassName);
        }
        if (
          nodeValidationErrors[ValidationError.riskMissingMainText] &&
          node.type === NodeType.Risk &&
          node.mainText !== ""
        ) {
          errorsToRemove.push(ValidationError.riskMissingMainText);
        }
        if (
          nodeValidationErrors[ValidationError.riskMissingCategory] &&
          node.type === NodeType.Risk &&
          node.impact !== ""
        ) {
          errorsToRemove.push(ValidationError.riskMissingCategory);
        }
        if (
          nodeValidationErrors[ValidationError.riskMissingWhy] &&
          node.type === NodeType.Risk &&
          (!node.askForCompensatingControls || node.why !== "")
        ) {
          errorsToRemove.push(ValidationError.riskMissingWhy);
        }
      }

      return {
        ...state,
        surveys: {
          ...state.surveys,
          [action.surveyId]: {
            ...state.surveys[action.surveyId],
            validationErrors: removeValidationErrorsForNodeId(
              state.surveys[action.surveyId].validationErrors,
              action.nodeId,
              errorsToRemove
            ),
            actionHistory: state.surveys[action.surveyId].trackHistory
              ? updateActionHistory(
                  state.surveys[action.surveyId].actionHistory,
                  action
                )
              : [],
            actionHistoryUpdatedAt: new Date().getTime(),
            nodes: {
              ...state.surveys[action.surveyId].nodes,
              [action.nodeId]: newNode,
            },
          },
        },
      };
    }

    case DELETE_NODE: {
      // We need to delete the specified node and all its children
      // all the way down the tree.
      const newNodes = { ...state.surveys[action.surveyId].nodes };

      const deleteNode = (node: Node) => {
        if (node.type === NodeType.Section) {
          // Run through and delete each of the child nodes first.
          for (let i = 0; i < node.childNodeIds.length; i++) {
            const childNode =
              state.surveys[action.surveyId].nodes[node.childNodeIds[i].id];
            if (childNode) {
              deleteNode(childNode);
            }
          }
        }

        // Then delete this node
        delete newNodes[node.nodeId];
      };

      const nodeToDelete = state.surveys[action.surveyId].nodes[action.nodeId];
      if (!nodeToDelete) {
        console.error(
          `attempted to delete a node (id ${action.nodeId}) that doesn't exist`
        );
        return state;
      }

      // Get the parent so we can update the parent's childNodeIds list.
      const parentNode =
        state.surveys[action.surveyId].nodes[nodeToDelete.parentId || ""];
      if (!parentNode || parentNode.type !== NodeType.Section) {
        console.error(
          `attempted to delete a node (id ${action.nodeId}) that has no parent`
        );
        return state;
      }

      const newChildIds = [...parentNode.childNodeIds];
      newChildIds.splice(
        newChildIds.findIndex((c) => c.id === nodeToDelete.nodeId),
        1
      );

      // Update the parent in the new nodes
      newNodes[parentNode.nodeId] = {
        ...parentNode,
        childNodeIds: newChildIds,
      };

      // Finally delete the node tree
      deleteNode(nodeToDelete);

      let validationErrors = state.surveys[action.surveyId].validationErrors;
      if (validationErrors.errors[action.nodeId]) {
        // Remove all errors for this node Id
        const newErrors = { ...validationErrors.errors };
        delete newErrors[action.nodeId];
        const newNodeIds = [...validationErrors.invalidNodeIds];
        newNodeIds.splice(newNodeIds.indexOf(action.nodeId), 1);

        validationErrors = {
          errors: newErrors,
          invalidNodeIds: newNodeIds,
        };
      }

      return {
        ...state,
        surveys: {
          ...state.surveys,
          [action.surveyId]: {
            ...state.surveys[action.surveyId],
            validationErrors,
            actionHistory: state.surveys[action.surveyId].trackHistory
              ? updateActionHistory(
                  state.surveys[action.surveyId].actionHistory,
                  action
                )
              : [],
            actionHistoryUpdatedAt: new Date().getTime(),
            nodes: newNodes,
          },
        },
      };
    }

    case ADD_SELECT_NODE_ANSWER: {
      // First find the node we need to update
      const node = state.surveys[action.surveyId].nodes[action.nodeId];
      if (!node || node.type !== NodeType.Select) {
        console.error(
          "attempted to add an answer to a nonexisted or non-Select node"
        );
        return state;
      }

      const newAnswers = [
        ...node.answers,
        {
          // New blank answer
          id: action.newAnswerId,
          text: "",
        },
      ];

      // Do we need to resolve any validation errors?
      const nodeValidationErrors =
        state.surveys[action.surveyId].validationErrors.errors[action.nodeId];
      const errorsToRemove: ValidationError[] = [];

      if (
        nodeValidationErrors &&
        nodeValidationErrors[ValidationError.selectNeedsMoreAnswers]
      ) {
        if (
          (node.radio && newAnswers.length >= 2) ||
          (!node.radio && newAnswers.length >= 1)
        ) {
          errorsToRemove.push(ValidationError.selectNeedsMoreAnswers);
        }
      }

      return {
        ...state,
        surveys: {
          ...state.surveys,
          [action.surveyId]: {
            ...state.surveys[action.surveyId],
            validationErrors: removeValidationErrorsForNodeId(
              state.surveys[action.surveyId].validationErrors,
              action.nodeId,
              errorsToRemove
            ),
            actionHistory: state.surveys[action.surveyId].trackHistory
              ? updateActionHistory(
                  state.surveys[action.surveyId].actionHistory,
                  action
                )
              : [],
            actionHistoryUpdatedAt: new Date().getTime(),
            nodes: {
              ...state.surveys[action.surveyId].nodes,
              [action.nodeId]: {
                ...node,
                answers: newAnswers,
              },
            },
          },
        },
      };
    }

    case UPDATE_SELECT_NODE_ANSWER: {
      // First find the node we need to update
      const node = state.surveys[action.surveyId].nodes[action.nodeId];
      if (!node || node.type !== NodeType.Select) {
        console.error(
          "attempted to update an answer on a nonexisted or non-Select node"
        );
        return state;
      }

      if (node.answers.length - 1 < action.updateIndex) {
        console.error("attempted to update an answer with an invalid index");
        return state;
      }

      const newAnswers = [...node.answers];

      newAnswers[action.updateIndex] = {
        ...newAnswers[action.updateIndex],
        text: action.text,
      };

      // Do we need to resolve any validation errors?
      const nodeValidationErrors =
        state.surveys[action.surveyId].validationErrors.errors[action.nodeId];
      const errorsToRemove: ValidationError[] = [];

      if (
        nodeValidationErrors &&
        nodeValidationErrors[ValidationError.selectAnswerMissingText]
      ) {
        // If there are no longer any answers with no text, we can resolve this error.
        let anyBlanks = false;
        for (let i = 0; i < newAnswers.length; i++) {
          if (newAnswers[i].text === "") {
            anyBlanks = true;
            break;
          }
        }

        if (!anyBlanks) {
          errorsToRemove.push(ValidationError.selectAnswerMissingText);
        }
      }

      return {
        ...state,
        surveys: {
          ...state.surveys,
          [action.surveyId]: {
            ...state.surveys[action.surveyId],
            validationErrors: removeValidationErrorsForNodeId(
              state.surveys[action.surveyId].validationErrors,
              action.nodeId,
              errorsToRemove
            ),
            actionHistory: state.surveys[action.surveyId].trackHistory
              ? updateActionHistory(
                  state.surveys[action.surveyId].actionHistory,
                  action
                )
              : [],
            actionHistoryUpdatedAt: new Date().getTime(),
            nodes: {
              ...state.surveys[action.surveyId].nodes,
              [action.nodeId]: {
                ...node,
                answers: newAnswers,
              },
            },
          },
        },
      };
    }

    case DELETE_SELECT_NODE_ANSWER: {
      // First find the node we need to update
      const node = state.surveys[action.surveyId].nodes[action.nodeId];
      if (!node || node.type !== NodeType.Select) {
        console.error(
          "attempted to delete an answer on a nonexistent or non-Select node"
        );
        return state;
      }

      if (node.answers.length - 1 < action.deleteAtIndex) {
        console.error("attempted to delete an answer with an invalid index");
        return state;
      }

      const newAnswers = [...node.answers];
      newAnswers.splice(action.deleteAtIndex, 1);

      // Do we need to resolve any validation errors?
      const nodeValidationErrors =
        state.surveys[action.surveyId].validationErrors.errors[action.nodeId];
      const errorsToRemove: ValidationError[] = [];

      if (
        nodeValidationErrors &&
        nodeValidationErrors[ValidationError.selectAnswerMissingText]
      ) {
        // If there are no longer any answers with no text, we can resolve this error.
        let anyBlanks = false;
        for (let i = 0; i < newAnswers.length; i++) {
          if (newAnswers[i].text === "") {
            anyBlanks = true;
            break;
          }
        }

        if (!anyBlanks) {
          errorsToRemove.push(ValidationError.selectAnswerMissingText);
        }
      }

      return {
        ...state,
        surveys: {
          ...state.surveys,
          [action.surveyId]: {
            ...state.surveys[action.surveyId],
            validationErrors: removeValidationErrorsForNodeId(
              state.surveys[action.surveyId].validationErrors,
              action.nodeId,
              errorsToRemove
            ),
            actionHistory: state.surveys[action.surveyId].trackHistory
              ? updateActionHistory(
                  state.surveys[action.surveyId].actionHistory,
                  action
                )
              : [],
            actionHistoryUpdatedAt: new Date().getTime(),
            nodes: {
              ...state.surveys[action.surveyId].nodes,
              [action.nodeId]: {
                ...node,
                answers: newAnswers,
              },
            },
          },
        },
      };
    }

    case ADD_CONDITIONAL_EXPRESSION: {
      // First find the node we need to update
      const node = state.surveys[action.surveyId].nodes[action.nodeId];
      if (!node) {
        console.error(
          "attempted to add a conditional expression to a nonexistent node"
        );
        return state;
      }

      let conditionalExpr: Expression[] = node.conditionalExpression || [];

      // immer's produce function lets us mutate a proxy of our data structure
      // and immutably apply those changes.
      conditionalExpr = produce(
        conditionalExpr,
        (conditionalExpressionDraft: Expression[]) => {
          // Dig down in the draft to where we want to add our expressions
          let curExpr: Expression[] = conditionalExpressionDraft;
          try {
            curExpr = findConditionalExpressionLayerInDraft(
              curExpr,
              action.addAtIndex
            );
          } catch (e) {
            console.error(e);
            return;
          }

          // Now add the new expressions at the specified index
          curExpr.splice(
            action.addAtIndex[action.addAtIndex.length - 1],
            0,
            ...action.expressionsToAdd
          );
        }
      );

      return {
        ...state,
        surveys: {
          ...state.surveys,
          [action.surveyId]: {
            ...state.surveys[action.surveyId],
            actionHistory: state.surveys[action.surveyId].trackHistory
              ? updateActionHistory(
                  state.surveys[action.surveyId].actionHistory,
                  action
                )
              : [],
            actionHistoryUpdatedAt: new Date().getTime(),
            nodes: {
              ...state.surveys[action.surveyId].nodes,
              [action.nodeId]: {
                ...node,
                conditionalExpression: conditionalExpr,
              },
            },
          },
        },
      };
    }

    case UPDATE_CONDITIONAL_EXPRESSION: {
      // First find the node we need to update
      const node = state.surveys[action.surveyId].nodes[action.nodeId];
      if (!node) {
        console.error(
          "attempted to add a conditional expression to a nonexistent node"
        );
        return state;
      }

      // immer's produce function lets us mutate a proxy of our data structure
      // and immutably apply those changes.
      const conditionalExpr = produce(
        node.conditionalExpression,
        (conditionalExpressionDraft: Expression[]) => {
          // Dig down in the draft to where we want to update our expressions
          let curExpr: Expression[] = conditionalExpressionDraft;
          try {
            curExpr = findConditionalExpressionLayerInDraft(
              curExpr,
              action.updateIndex
            );
          } catch (e) {
            console.error(e);
            return;
          }

          // Now run our update
          const finalIndex = action.updateIndex[action.updateIndex.length - 1];

          if (typeof curExpr[finalIndex] === "object") {
            curExpr[finalIndex] = {
              // @ts-ignore
              ...curExpr[finalIndex],
              // @ts-ignore
              ...action.updateExpression,
            };
          } else {
            // @ts-ignore
            curExpr[finalIndex] = action.updateExpression;
          }
        }
      );

      return {
        ...state,
        surveys: {
          ...state.surveys,
          [action.surveyId]: {
            ...state.surveys[action.surveyId],
            actionHistory: state.surveys[action.surveyId].trackHistory
              ? updateActionHistory(
                  state.surveys[action.surveyId].actionHistory,
                  action
                )
              : [],
            actionHistoryUpdatedAt: new Date().getTime(),
            nodes: {
              ...state.surveys[action.surveyId].nodes,
              [action.nodeId]: {
                ...node,
                conditionalExpression: conditionalExpr,
              },
            },
          },
        },
      };
    }

    case DELETE_CONDITIONAL_EXPRESSION: {
      // First find the node we need to update
      const node = state.surveys[action.surveyId].nodes[action.nodeId];
      if (!node) {
        console.error(
          "attempted to add a conditional expression to a nonexistent node"
        );
        return state;
      }

      // immer's produce function lets us mutate a proxy of our data structure
      // and immutably apply those changes.
      const conditionalExpr = produce(
        node.conditionalExpression,
        (conditionalExpressionDraft: Expression[]) => {
          let curExpr: Expression[] = conditionalExpressionDraft;
          try {
            curExpr = findConditionalExpressionLayerInDraft(
              curExpr,
              action.startIndex
            );
          } catch (e) {
            console.error(e);
            return;
          }

          // Now run our delete
          const startIndex = action.startIndex[action.startIndex.length - 1];

          curExpr.splice(startIndex, action.numToDelete);
        }
      );

      return {
        ...state,
        surveys: {
          ...state.surveys,
          [action.surveyId]: {
            ...state.surveys[action.surveyId],
            actionHistory: state.surveys[action.surveyId].trackHistory
              ? updateActionHistory(
                  state.surveys[action.surveyId].actionHistory,
                  action
                )
              : [],
            actionHistoryUpdatedAt: new Date().getTime(),
            nodes: {
              ...state.surveys[action.surveyId].nodes,
              [action.nodeId]: {
                ...node,
                conditionalExpression: conditionalExpr,
              },
            },
          },
        },
      };
    }

    case REORDER_TREE: {
      // We're going to reorder the whole tree from the root node down.
      const nodes = produce(
        state.surveys[action.surveyId].nodes,
        (nodesDraft) => {
          const replaceNodesInTree = (
            parentNodeId: string,
            theseNodes: NodeSummary[]
          ) => {
            const newParentNodeChildIds = [];
            for (let i = 0; i < theseNodes.length; i++) {
              const node = theseNodes[i];
              const thisNodeInDraft = nodesDraft[node.nodeId];
              if (!thisNodeInDraft) {
                continue;
              }

              newParentNodeChildIds.push({
                id: node.nodeId,
                type: nodeTypeIconTypeToNodeType(node.nodeType),
              });

              // Update the parent ID of the current node
              thisNodeInDraft.parentId = parentNodeId;

              // And replace nodes in the tree for any children, if necessary
              if (node.children) {
                replaceNodesInTree(node.nodeId, node.children);
              }
            }

            // Update the child IDs of the parent
            const parentNode = nodesDraft[parentNodeId];
            if (parentNode && parentNode.type === NodeType.Section) {
              parentNode.childNodeIds = newParentNodeChildIds;
            }
          };

          replaceNodesInTree(
            state.surveys[action.surveyId].rootNodeId,
            action.allNodes
          );
        }
      );

      return {
        ...state,
        surveys: {
          ...state.surveys,
          [action.surveyId]: {
            ...state.surveys[action.surveyId],
            actionHistory: state.surveys[action.surveyId].trackHistory
              ? updateActionHistory(
                  state.surveys[action.surveyId].actionHistory,
                  action
                )
              : [],
            actionHistoryUpdatedAt: new Date().getTime(),
            nodes,
          },
        },
      };
    }

    case SET_SURVEY_SAVE_ERROR:
      return {
        ...state,
        surveys: {
          ...state.surveys,
          [action.surveyId]: {
            ...state.surveys[action.surveyId],
            saveError: action.errorState,
          },
        },
      };

    default:
      return state;
  }
};

// Update the history of actions, merging into the previous action if able.
const updateActionHistory = (
  history: SurveyBuilderActions[],
  action: SurveyBuilderActions
): SurveyBuilderActions[] => {
  const newActions = [...history];

  // Sometimes we can just update the previous action, rather than adding a new one. For example, if
  // we update the same field more than once in a row.
  if (newActions.length > 0) {
    let updatePreviousAction = false;
    const previousAction = newActions[newActions.length - 1];

    if (
      previousAction.type === UPDATE_NODE &&
      action.type === UPDATE_NODE &&
      previousAction.nodeId === action.nodeId
    ) {
      // If both actions only operated on the same keys, we can just take the last update.
      if (
        areSetsEqual(
          new Set(Object.keys(previousAction.fieldsToUpdate)),
          new Set(Object.keys(action.fieldsToUpdate))
        )
      ) {
        updatePreviousAction = true;
      }
    } else if (
      previousAction.type === UPDATE_SELECT_NODE_ANSWER &&
      action.type === UPDATE_SELECT_NODE_ANSWER &&
      previousAction.nodeId === action.nodeId &&
      previousAction.updateIndex === action.updateIndex
    ) {
      updatePreviousAction = true;
    }

    if (updatePreviousAction) {
      newActions[newActions.length - 1] = action;
      return newActions;
    }
  }

  newActions.push(action);
  return newActions;
};

const removeValidationErrorsForNodeId = (
  validationErrors: ValidationErrors,
  nodeId: string,
  errorsToRemove: ValidationError[]
): ValidationErrors => {
  if (errorsToRemove.length === 0) {
    return validationErrors;
  }

  // Update the node's validation errors object
  const nodeValidationErrors = { ...validationErrors.errors[nodeId] };
  for (let i = 0; i < errorsToRemove.length; i++) {
    delete nodeValidationErrors[errorsToRemove[i]];
  }

  const newErrors = { ...validationErrors.errors };
  let newNodeIDs = validationErrors.invalidNodeIds;

  if (Object.keys(nodeValidationErrors).length === 0) {
    // We should remove this nodeId from the errors object entirely, and also from the nodeIDs
    delete newErrors[nodeId];

    newNodeIDs = [...newNodeIDs];
    const indexOfNodeId = newNodeIDs.indexOf(nodeId);
    if (indexOfNodeId > -1) {
      newNodeIDs.splice(indexOfNodeId, 1);
    }
  } else {
    newErrors[nodeId] = nodeValidationErrors;
  }

  return {
    errors: newErrors,
    invalidNodeIds: newNodeIDs,
  };
};

const areSetsEqual = <T>(a: Set<T>, b: Set<T>): boolean => {
  return a.size === b.size && [...a].every((val) => b.has(val));
};

const findConditionalExpressionLayerInDraft = (
  conditionalExpressionDraft: Expression[],
  index: number[]
): Expression[] => {
  // Dig down in the draft to where we want to delete our expressions
  let curExpr: Expression[] = conditionalExpressionDraft;

  // Go through all but the last index
  for (let i = 0; i < index.length - 1; i++) {
    if (curExpr.length - 1 < index[i]) {
      throw new Error(
        "attempted to update a conditional expression at an invalid index: " +
          index[i]
      );
    }

    if (!Array.isArray(curExpr[index[i]])) {
      throw new Error(
        "attempted to update a conditional expression through an index that was not an array: " +
          index[i]
      );
    }

    curExpr = curExpr[index[i]] as Expression[];
  }

  return curExpr;
};
