import { Component } from "react";
import PropTypes from "prop-types";
import "../style/components/ScoreProjection.scss";
import ColorGrade from "../../vendorrisk/components/executive_summary/ColorGrade";
import Score from "../../vendorrisk/components/Score";
import { SidePopupV2 } from "./DismissablePopup";

export default class ScoreProjection extends Component {
  static propTypes = {
    stacked: PropTypes.bool,
    isSelfRemediation: PropTypes.bool.isRequired,
    isVendorPortal: PropTypes.bool.isRequired,
    isQuestionnaire: PropTypes.bool.isRequired,
    vendorName: PropTypes.string.isRequired,
    initialScore: PropTypes.number.isRequired,
    currentScore: PropTypes.number.isRequired,
    projectedScore: PropTypes.number.isRequired,
  };

  supportArticleLink =
    "https://help.upguard.com/en/articles/3765166-what-are-security-ratings";

  render() {
    const {
      stacked,
      initialScore,
      currentScore,
      projectedScore,
      vendorName,
      isSelfRemediation,
      isQuestionnaire,
      isVendorPortal,
    } = this.props;
    const diff = projectedScore - currentScore;

    const infoTextsCustomer = [
      "This was your security rating when this request was created. ",
      "This is your current security rating.",
      "This is your projected security rating if all identified risks in this request are remediated.",
    ];

    const infoTextsVendor = [
      `This was ${vendorName}'s security rating when this request was created.`,
      `This is ${vendorName}'s current security rating.`,
      `This is ${vendorName}'s projected security rating if all identified risks in this request are remediated.`,
    ];

    const infoTextsVendorQn = [
      `This was ${vendorName}'s security rating when remediation was first requested.`,
      `This is ${vendorName}'s current security rating.`,
      `This is ${vendorName}'s projected security rating if all identified risks under remediation are remediated.`,
    ];

    const bodyTextCustomer = `
      Your security rating is based on the analysis of hundreds of individual checks across ten risk categories: Website, IP/Domain Reputation,
      Encryption, Vulnerability Management, Attack Surface, Network, Email, Data Leakage, DNS, and Brand Reputation. The resultant score provides a data-driven, 
      objective, and dynamic measurement of your organization’s cybersecurity performance. A higher rating means a stronger security posture.`;

    const bodyTextVendor = `
      ${vendorName}'s security rating is based on the analysis of hundreds of individual checks across eleven risk categories: Questionnaire Risks, 
      Website, IP/Domain Reputation, Encryption, Vulnerability Management, Attack Surface, Network, Email, Data Leakage, DNS, and Brand Reputation. The 
      resultant score provides a data-driven, objective, and dynamic measurement of ${vendorName}'s cybersecurity performance. A higher rating means 
      a stronger security posture.`;

    const bodyTitleCustomer =
      "How do these ratings relate to my security posture?";
    const bodyTitleVendor = `How do these ratings relate to ${vendorName}'s security posture?`;

    return (
      <div className="score-projection">
        <div className={stacked ? "scores-row-stacked" : "scores-row"}>
          <div className={"score"}>
            <div className={"description"}>
              Initial rating
              <SidePopupV2
                position={"top"}
                width={270}
                text={
                  isSelfRemediation
                    ? infoTextsCustomer[0]
                    : isQuestionnaire
                      ? infoTextsVendorQn[0]
                      : infoTextsVendor[0]
                }
              >
                <span className="cr-icon-info" />
              </SidePopupV2>
            </div>
            <div className={"score-value"}>
              <ColorGrade score={initialScore} />
              <Score colored large score={initialScore} />
            </div>
          </div>
          {!stacked && (
            <div className={"score"}>
              <div className={"description"} />
              <div className={"score-value"}>
                <div className={"arrow cr-icon-arrow-right"} />
              </div>
            </div>
          )}
          <div className={"score"}>
            <div className={"description"}>
              Current rating
              <SidePopupV2
                position={"top"}
                width={260}
                text={
                  isSelfRemediation
                    ? infoTextsCustomer[1]
                    : isQuestionnaire
                      ? infoTextsVendorQn[1]
                      : infoTextsVendor[1]
                }
              >
                <span className="cr-icon-info" />
              </SidePopupV2>
            </div>
            <div className={"score-value"}>
              <ColorGrade score={currentScore} />
              <Score colored large score={currentScore} />
            </div>
          </div>
          {!stacked && (
            <div className={"score"}>
              <div className={"description"} />
              <div className={"score-value"}>
                <div className={"arrow cr-icon-arrow-right"} />
              </div>
            </div>
          )}
          <div className={"score"}>
            <div className={"description"}>
              Projected rating
              <SidePopupV2
                position={"top"}
                width={270}
                text={
                  isSelfRemediation
                    ? infoTextsCustomer[2]
                    : isQuestionnaire
                      ? infoTextsVendorQn[2]
                      : infoTextsVendor[2]
                }
              >
                <span className="cr-icon-info" />
              </SidePopupV2>
            </div>
            <div className={"score-value"}>
              <ColorGrade score={projectedScore} />
              <Score colored large score={projectedScore} />
              <div className={"score-text-addendum"}>{`(${
                diff >= 0 ? "+" : ""
              }${diff})`}</div>
            </div>
          </div>
        </div>
        <div className={"description-row"}>
          <div className={"heading"}>
            {isSelfRemediation ? bodyTitleCustomer : bodyTitleVendor}
          </div>
          <div className={"body"}>
            {isSelfRemediation ? bodyTextCustomer : bodyTextVendor}
          </div>
          <div className={"body-link"}>
            <a
              href={this.supportArticleLink}
              target="_blank"
              rel="noreferrer noopener"
            >
              {"Learn more"} <i className="btn-arrow icon-arrow rotate-90" />
            </a>
          </div>
        </div>
      </div>
    );
  }
}
