import { FC } from "react";
import {
  RepoManifestRisk,
  VendorSummaryRisk,
} from "../../_common/types/vendorSummary";
import XTable, {
  IXTableColumnHeader,
  IXTableRow,
  SortDirection,
  XTableCell,
} from "../../_common/components/core/XTable";
import { formatDateAsLocal } from "../../_common/helpers";
import EmptyCardWithAction from "../../_common/components/EmptyCardWithAction";
import { useSorting } from "../../_common/hooks";
import { Link } from "react-router-dom";
import ManageRiskButton from "../../vendorrisk/components/risk_profile/ManageRiskButton";
import { WaivedAssets } from "../../vendorrisk/reducers/risks.actions";
import PillLabel from "../../vendorrisk/components/PillLabel";
import { LabelColor } from "../../_common/types/label";
import { getRepoManifestLocation } from "../api/manifestLocation.helper";

interface RiskAffectedManifestsProps {
  risk: VendorSummaryRisk;
  hideRepositoryColumn?: boolean;
  showWaived?: boolean;
  waivedAssets?: WaivedAssets;
}

const RiskAffectedManifests: FC<RiskAffectedManifestsProps> = ({
  risk,
  hideRepositoryColumn,
  showWaived,
  waivedAssets,
}) => {
  const columnHeaders: IXTableColumnHeader[] = [
    {
      id: "repo",
      text: "Repository",
      sortable: true,
      hide: hideRepositoryColumn,
    },
    {
      id: "manifest",
      text: "Manifest",
      sortable: true,
    },
    {
      id: "dep",
      text: "Dependency",
      sortable: true,
    },
    {
      id: "first_detected",
      text: "First Detected",
      sortable: true,
    },
    {
      id: "status",
      text: "Status",
      sortable: false,
    },
    {
      id: "actions",
      text: "",
      sortable: false,
    },
  ];

  const filteredManifests =
    risk.manifests?.filter((m) => {
      if (showWaived) {
        return true;
      }

      return (
        waivedAssets?.assetsWaived[
          `${getRepoManifestLocation(m.repoName, m.manifestLocation)}`
        ] === undefined
      );
    }) ?? [];

  const [sortedDependencies, sortedBy, onSortChange] = useSorting<
    RepoManifestRisk,
    "repo" | "manifest" | "first_detected"
  >(filteredManifests, "repo", SortDirection.DESC, {
    repo: {
      orderFuncs: [(d) => d.repoName.toLocaleLowerCase()],
      sortDirsAsc: [SortDirection.ASC],
      sortDirsDesc: [SortDirection.DESC],
    },
    manifest: {
      orderFuncs: [(d) => d.manifestLocation],
      sortDirsAsc: [SortDirection.ASC],
      sortDirsDesc: [SortDirection.DESC],
    },
    first_detected: {
      orderFuncs: [(d) => d.createdAt],
      sortDirsAsc: [SortDirection.ASC],
      sortDirsDesc: [SortDirection.DESC],
    },
  });

  const getRepoOverviewPath = (uuid: string) =>
    `/appguard/repositories/${uuid}/overview`;

  const getRepoManifestsPath = (uuid: string) =>
    `/appguard/repositories/${uuid}/software-inventories`;

  const rows: IXTableRow[] = sortedDependencies.map((m) => {
    const isWaived =
      waivedAssets?.assetsWaived[
        `${getRepoManifestLocation(m.repoName, m.manifestLocation)}`
      ] !== undefined;
    const isPendingWaived =
      waivedAssets?.assetsPendingWaiver[
        `${getRepoManifestLocation(m.repoName, m.manifestLocation)}`
      ] !== undefined;

    return {
      id: m.manifestUUID,
      cells: [
        <XTableCell key="repo" hide={hideRepositoryColumn}>
          <Link to={getRepoOverviewPath(m.repoUUID)}>{m.repoName}</Link>
        </XTableCell>,
        <XTableCell key="manifest">
          <Link to={getRepoManifestsPath(m.repoUUID)}>
            {m.manifestLocation}
          </Link>
        </XTableCell>,
        <XTableCell key="dep">{m.packageVersionUniqueNames}</XTableCell>,
        <XTableCell key="first_detected">
          {formatDateAsLocal(m.createdAt)}
        </XTableCell>,
        <XTableCell key="status">
          {isWaived && <PillLabel>Waived</PillLabel>}
          {isPendingWaived && (
            <PillLabel color={LabelColor.Orange}>Waiver pending</PillLabel>
          )}
        </XTableCell>,
        <XTableCell key={"actions"} showContentOnlyOnHover>
          <ManageRiskButton
            onRemediate={() => {
              // TODO just show dummy button for now
            }}
            onWaive={() => {
              // TODO just show dummy button for now
            }}
            onAdjust={() => {
              // TODO just show dummy button for now
            }}
          />
        </XTableCell>,
      ],
    };
  });

  return (
    <XTable
      className={"risk-affected-dependencies"}
      columnHeaders={columnHeaders}
      rows={rows}
      emptyContent={<EmptyCardWithAction emptyText={"No dependencies"} />}
      sortedBy={sortedBy}
      onSortChange={onSortChange}
    />
  );
};

export default RiskAffectedManifests;
