import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import classnames from "classnames";
import { throttle } from "lodash";

import "../style/components/TabButtons.scss";
import { PopupPosition, SidePopupV2 } from "./DismissablePopup";
import BetaLabel from "./BetaLabel";
import ButtonGroup from "./ButtonGroup";
import Button from "./core/Button";

export interface tabButton<TabId extends string = string> {
  id: TabId;
  text: React.ReactNode;
  popup?: React.ReactNode;
  popupPosition?: PopupPosition;
  popupDelay?: number;
  popupMicro?: boolean;
  popupNoWrap?: boolean; // Defaults to true
  popupWidth?: number;
  disabled?: boolean;
  beta?: boolean;
}

export enum tabButtonBackgroundColor {
  White = "white",
  Grey = "grey",
}

export enum tabButtonsStylingType {
  Original = "original",
  FullWidthBanner = "fullWidthBanner",
  Flat = "flat",
}

export interface ITabButtonsProps<TabId extends string = string> {
  className?: string;
  wrapperClassName?: string;
  tabs: tabButton<TabId>[];
  activeTabId?: string;
  onChangeTab: (tabId: TabId) => void;
  disabled?: boolean;
  noDisabledStyles?: boolean;
  backgroundColor?: tabButtonBackgroundColor;
  fullWidth?: boolean; // Show with no scroll
  newScroller?: boolean;
  icons?: boolean; // Used when all tabs are just icons
  styling?: tabButtonsStylingType;
}

const TabButtons = <TabId extends string = string>(
  props: ITabButtonsProps<TabId>
) => {
  const {
    tabs,
    activeTabId,
    onChangeTab,
    className = "",
    wrapperClassName = "",
    disabled,
    noDisabledStyles,
    fullWidth,
    newScroller,
    backgroundColor = tabButtonBackgroundColor.White,
    icons,
    styling = tabButtonsStylingType.Original,
  } = props;

  const btnsRef = useRef<HTMLDivElement>(null);
  const [scrollbarHeight, setScrollbarHeight] = useState("0px");
  const [scrolledFromLeft, setScrolledFromLeft] = useState(0);
  const [scrolledFromRight, setScrolledFromRight] = useState(0);
  const [tabsAreHidden, setTabsAreHidden] = useState(false);

  const isScrollable =
    !fullWidth && styling === tabButtonsStylingType.Original && !icons;

  const scrollStart = () =>
    btnsRef.current?.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });

  const scrollEnd = () =>
    btnsRef.current?.scrollTo({
      top: 0,
      left: btnsRef.current?.scrollWidth,
      behavior: "smooth",
    });

  // Respond to events that should update the scroll position of the tab button bar
  const scrollListenerThrottled = useCallback(
    throttle(() => {
      const el = btnsRef.current;
      if (!el) {
        return;
      }

      setScrolledFromLeft(el.scrollLeft);
      setScrolledFromRight(el.scrollWidth - el.offsetWidth - el.scrollLeft);
    }, 100),
    [btnsRef.current]
  );

  // Respond to element resize
  useLayoutEffect(() => {
    let observer: ResizeObserver;
    if (btnsRef.current) {
      observer = new ResizeObserver((entries) => {
        if (entries.length > 0) {
          if (btnsRef && btnsRef.current) {
            setTabsAreHidden(
              btnsRef.current.scrollWidth > btnsRef.current.clientWidth
            );
          }
        }
      });

      // And refresh the scrolled state too
      scrollListenerThrottled();

      observer.observe(btnsRef.current, {});
    }

    return () => {
      if (observer) {
        observer.disconnect();
      }
    };
  }, []);

  // Get all the tab names in text form for useEffect dependencies
  const tabsText = tabs.map((t) => t.text).join("");

  useEffect(
    function configureTabScrolling() {
      // This function will run on initial mount and when any tab text changes.
      // Only run this if using original stlying,. Other styling options are not horizontally scrollable.
      if (!isScrollable || !btnsRef.current) {
        return;
      }

      // First compute the height of the bottom scrollbar and set the internal padding to hide it.
      setScrollbarHeight(
        `${btnsRef.current.offsetHeight - btnsRef.current.clientHeight}px`
      );

      // Next test if there are any hidden tabs
      setTabsAreHidden(
        btnsRef.current.scrollWidth > btnsRef.current.clientWidth
      );

      // Set the scroll amount from the right
      setScrolledFromRight(
        btnsRef.current.scrollWidth -
          btnsRef.current.offsetWidth -
          btnsRef.current.scrollLeft
      );

      // Next add a debounced scroll listener on the scrollable element
      btnsRef.current.addEventListener("scroll", scrollListenerThrottled);

      // Also need to reset scroll on window resize
      window.addEventListener("resize", scrollListenerThrottled);

      return function cleanup() {
        btnsRef.current?.removeEventListener("scroll", scrollListenerThrottled);
        window.removeEventListener("resize", scrollListenerThrottled);
      };
    },
    [
      isScrollable,
      tabsText, // Only re-compute if any tab text changes
    ]
  );

  useEffect(
    function keepActiveTabInView() {
      // This effect runs whenever the active tab changes, to ensure we scroll to the right position.
      if (!isScrollable || !btnsRef.current) {
        return;
      }

      const tabIndex = tabs.findIndex((t) => t.id === activeTabId);

      const tabEl = btnsRef.current.querySelector<HTMLDivElement>(
        `#tab-${tabIndex}`
      );
      if (!tabEl) {
        return;
      }

      const { left, width } = tabEl.getBoundingClientRect();
      const { left: parentLeft, width: parentWidth } =
        btnsRef.current.getBoundingClientRect();

      // Shave off an extra 80px to account for the button that appears
      const offPageLeft = left - parentLeft - 80 < 0;
      if (offPageLeft) {
        // Scroll just into view
        btnsRef.current.scrollTo({
          top: 0,
          left: tabEl.offsetLeft - 80,
          behavior: "smooth",
        });
        return;
      }

      const offPageRight = left - parentLeft + width + 80 > parentWidth;
      if (offPageRight) {
        // Scroll just into view
        btnsRef.current.scrollTo({
          top: 0,
          left: tabEl.offsetLeft - 80,
          behavior: "smooth",
        });
      }
    },
    [
      isScrollable,
      tabsText, // Only re-compute if any tab text changes
      activeTabId, // And if the active tab changes
    ]
  );

  const btns = (
    <div
      className={classnames("tab-buttons", className, {
        "tab-icons": icons,
        "styling-original": styling === tabButtonsStylingType.Original,
        "tab-buttons-banner": styling === tabButtonsStylingType.FullWidthBanner,
        "styling-flat": styling === tabButtonsStylingType.Flat,
      })}
    >
      {tabs.map((t, i) => {
        const btn = (
          <div
            key={t.id}
            id={`tab-${i}`}
            className={classnames("tab", {
              active: activeTabId === t.id,
              disabled: !noDisabledStyles && (!!t.disabled || disabled),
            })}
            onClick={
              !!t.disabled || disabled ? undefined : () => onChangeTab(t.id)
            }
          >
            {t.text}
            {t.beta && <BetaLabel />}
          </div>
        );

        return t.popup ? (
          <SidePopupV2
            key={t.id}
            className="tab-popup-wrapper"
            text={t.popup}
            position={t.popupPosition}
            popupDelay={t.popupDelay}
            micro={t.popupMicro}
            noWrap={t.popupNoWrap ?? true}
            width={t.popupWidth}
          >
            {btn}
          </SidePopupV2>
        ) : (
          btn
        );
      })}
    </div>
  );

  if (!isScrollable) {
    return btns;
  }

  return (
    <div
      className={classnames("tab-buttons-wrapper", wrapperClassName, {
        "with-new-scroller": newScroller,
        "tabs-hidden": tabsAreHidden,
      })}
    >
      {!newScroller && tabsAreHidden && scrolledFromLeft > 0 && (
        <div
          className={classnames("scroll-indicator", "left", {
            "bg-white": backgroundColor === tabButtonBackgroundColor.White,
            "bg-grey": backgroundColor === tabButtonBackgroundColor.Grey,
          })}
          style={{
            opacity: scrolledFromLeft > 100 ? 1 : scrolledFromLeft / 100 + 0.2,
          }}
        >
          <div className="arrow-hitbox" onClick={scrollStart}>
            <span className="cr-icon-arrow-right rot180" />
          </div>
        </div>
      )}
      <div
        className="tab-buttons-scroller"
        style={{ paddingBottom: scrollbarHeight }}
        ref={btnsRef}
      >
        {btns}
      </div>
      {!newScroller && tabsAreHidden && scrolledFromRight > 0 && (
        <div
          className={classnames("scroll-indicator", "right", {
            "bg-white": backgroundColor === tabButtonBackgroundColor.White,
            "bg-grey": backgroundColor === tabButtonBackgroundColor.Grey,
          })}
          style={{
            opacity:
              scrolledFromRight > 100 ? 1 : scrolledFromRight / 100 + 0.2,
          }}
        >
          <div className="arrow-hitbox" onClick={scrollEnd}>
            <span className="cr-icon-arrow-right" />
          </div>
        </div>
      )}
      {newScroller && tabsAreHidden && (
        <ButtonGroup className="new-scroll-indicator">
          <Button onClick={scrollStart}>
            <div className="cr-icon-chevron rot180" />
          </Button>
          <Button onClick={scrollEnd}>
            <div className="cr-icon-chevron" />
          </Button>
        </ButtonGroup>
      )}
    </div>
  );
};

export default TabButtons;
