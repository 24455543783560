import hash from "object-hash";
import { hasTruthyValue } from "../../_common/helpers";
import { getFiltersFromState } from "./filters.actions";

const defaultData = {
  loading: false,
  error: null,
  data: null,
};

export const getFiltersHash = (
  state,
  includeFilters,
  exportOptions,
  vendorId,
  isSubsidiary,
  overrideFilters = {}
) => {
  let obj = { ...exportOptions };
  if (includeFilters) {
    obj = {
      ...obj,
      ...getFiltersFromState(state, vendorId, isSubsidiary),
      ...overrideFilters,
    };
  }
  return hasTruthyValue(obj) ? hash(obj) : null;
};

export const getExportSectionsKey = (
  exportType,
  vendorId,
  isSubsidiary,
  filtersHash
) =>
  `${exportType}/${vendorId || 0}/${isSubsidiary || false}/${
    filtersHash ? filtersHash : ""
  }`;

export const getExportSections = (
  state,
  exportType,
  includeFilters,
  exportOptions,
  vendorId,
  isSubsidiary,
  isOnlySubsidiaries,
  overrideFilters = {}
) => {
  const filtersHash = getFiltersHash(
    state,
    includeFilters,
    exportOptions,
    vendorId,
    isSubsidiary,
    overrideFilters
  );

  const key = getExportSectionsKey(
    exportType,
    vendorId,
    isSubsidiary,
    filtersHash
  );

  return state.cyberRisk.exportSections[key] || defaultData;
};
