import { Component } from "react";
import PropTypes from "prop-types";

import Button from "../../../_common/components/core/Button";

import { closeModal } from "../../../_common/reducers/commonActions";
import {
  fetchVendorSummaryAndCloudscans,
  setVendorAttributeOverrides,
  resetVendorAttributeOverrides,
} from "../../reducers/cyberRiskActions";
import {
  addDefaultUnknownErrorAlert,
  addSimpleErrorAlert,
  clearMessageAlert,
} from "../../../_common/reducers/messageAlerts.actions";

export const RenameVendorModalName = "RenameVendorModalName";

/**
 * <RenameVendorModal />
 * Modal form popped up from a vendor or customer page to allow a user to rename the company.
 */

class RenameVendorModal extends Component {
  static propTypes = {
    modalData: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
  };

  state = {
    loading: false,
    resetLoading: false,
  };

  onSubmit = (evt) => {
    evt.preventDefault();
    const { vendorId } = this.props.modalData;

    const newVal = this.nameInput.value;
    let validationError;

    if (newVal.length <= 2) {
      validationError = "Company name must be longer than 2 characters";
    } else if (newVal.length > 150) {
      validationError = "Company name must 150 characters or less";
    }

    if (validationError) {
      this.props.dispatch(
        addSimpleErrorAlert(validationError, [], "vendor-name-edit-error")
      );
      return;
    }

    this.props.dispatch(clearMessageAlert("vendor-name-edit-error"));

    this.setState({ loading: true });

    this.props
      .dispatch(setVendorAttributeOverrides(vendorId, false, { Name: newVal }))
      .then(() => {
        this.props.dispatch(
          fetchVendorSummaryAndCloudscans(vendorId, true, false)
        );
        this.props.dispatch(closeModal());
      })
      .catch(() => {
        this.setState({ loading: false });
        this.props.dispatch(
          addDefaultUnknownErrorAlert("Error setting vendor name")
        );
      });
  };

  onReset = () => {
    this.setState({ resetLoading: true });

    this.props
      .dispatch(
        resetVendorAttributeOverrides(this.props.modalData.vendorId, false, [
          "Name",
        ])
      )
      .then(() => {
        this.props.dispatch(
          fetchVendorSummaryAndCloudscans(
            this.props.modalData.vendorId,
            true,
            false
          )
        );
        this.props.dispatch(closeModal());
      })
      .catch(() => {
        this.setState({ loading: false });
        this.props.dispatch(
          addDefaultUnknownErrorAlert("Error resetting vendor name")
        );
      });
  };

  render() {
    return (
      <div className="modal-content">
        <h2>Edit company name</h2>
        <p>
          Set a custom name for this company. This change will be visible to all
          users in your account.
        </p>
        <form onSubmit={this.onSubmit}>
          <label htmlFor="name">Company name</label>
          <input
            name="name"
            type="text"
            ref={(ref) => (this.nameInput = ref)}
            defaultValue={this.props.modalData.orgName}
            placeholder="Company name"
          />
          <div className="btn-group">
            <Button
              id="edit_vendor_name_submit"
              type="submit"
              primary
              loading={this.state.loading}
              disabled={this.state.loading}
            >
              Save
            </Button>
            <Button
              id="edit_vendor_name_reset"
              loading={this.state.resetLoading}
              disabled={this.state.resetLoading}
              onClick={this.onReset}
            >
              Reset
            </Button>
          </div>
        </form>
      </div>
    );
  }
}

export default RenameVendorModal;
