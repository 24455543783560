import ReportCard from "../../_common/components/ReportCard";
import XTable, {
  SortDirection,
  XTableCell,
  IXTableColumnHeader,
} from "../../_common/components/core/XTable";
import { useSortingWithPagination } from "../../_common/hooks";

import ColorGrade, {
  ColorGradeSize,
} from "../../vendorrisk/components/executive_summary/ColorGrade";
import Score from "../../vendorrisk/components/Score";

import { Scope, UserWithStats } from "../api/types";
import UserBaseAPI from "../api/userbase.api";

import { FC, useState } from "react";
import TeamsPills from "./TeamsPills";

import classNames from "classnames";
import SlidePanel from "../../_common/components/SlidePanel";
import UserAppsForSpecificScopePanel from "./UserAppsForSpecificScopePanel";
import "./UsersTableForScope.scss";

const PAGE_SIZE = 50;

const NAME_COL = "name_col";
const TEAM_COL = "team_col";
const ROLE_COL = "role_col";
const SECURITY_RATING_COL = "rating_col";

interface UserTableForScopeProps {
  // The uids of the users to display
  userUUIDs: string[];

  // The scope the users above have granted access to
  scope: Scope;

  // Allow for custom class names
  className?: string;
}
const UserTableForScope: FC<UserTableForScopeProps> = ({
  userUUIDs,
  scope,
  className,
}) => {
  const { data, isLoading } = UserBaseAPI.useGetUserBaseUsersV1Query({
    userUUIDs: userUUIDs,
  });

  const usersToUse = data?.users ?? [];

  const [slidePanelUser, setSlidePanelUser] = useState<
    UserWithStats | undefined
  >(undefined);

  const [
    sortedUsers,
    sortedBy,
    onSortChange,
    currentPage,
    totalPages,
    onPageChange,
  ] = useSortingWithPagination<
    UserWithStats,
    | typeof NAME_COL
    | typeof TEAM_COL
    | typeof ROLE_COL
    | typeof SECURITY_RATING_COL
  >(
    usersToUse,
    NAME_COL,
    SortDirection.ASC,
    {
      [NAME_COL]: {
        orderFuncs: [(u) => u.name.toLocaleLowerCase()],
        sortDirsAsc: [SortDirection.ASC],
        sortDirsDesc: [SortDirection.DESC],
      },
      [TEAM_COL]: {
        orderFuncs: [
          (u) =>
            (u.teams ?? [])
              .map((t) => t.toLocaleLowerCase())
              .sort((a, b) => a.localeCompare(b))
              .join(","),
        ],
        sortDirsAsc: [SortDirection.ASC],
        sortDirsDesc: [SortDirection.DESC],
      },
      [ROLE_COL]: {
        orderFuncs: [
          (u) =>
            (u.roles ?? [])
              .map((t) => t.toLocaleLowerCase())
              .sort((a, b) => a.localeCompare(b))
              .join(","),
        ],
        sortDirsAsc: [SortDirection.ASC],
        sortDirsDesc: [SortDirection.DESC],
      },
      [SECURITY_RATING_COL]: {
        orderFuncs: [(u) => u.adjustedRating],
        sortDirsAsc: [SortDirection.ASC],
        sortDirsDesc: [SortDirection.DESC],
      },
    },
    PAGE_SIZE
  );

  const columnHeaders: IXTableColumnHeader[] = [
    { id: NAME_COL, text: "Name", sortable: true, className: "user-name-col" },
    { id: ROLE_COL, text: "Role", sortable: true, className: "user-role-col" },
    { id: TEAM_COL, text: "Team", sortable: true, className: "user-team-col" },
    {
      id: SECURITY_RATING_COL,
      text: "Security rating",
      sortable: true,
      className: "user-rating-col",
    },
  ];

  const onViewUser = (user: UserWithStats) => {
    setSlidePanelUser(user);
  };

  const rows = sortedUsers.map((u: UserWithStats) => {
    return {
      id: u.uuid,
      className: "user-row",
      onClick: () => onViewUser(u),
      cells: [
        <XTableCell key={NAME_COL}>{u.name}</XTableCell>,
        <XTableCell key={ROLE_COL}>{u.roles ? u.roles[0] : ""}</XTableCell>,
        <XTableCell key={TEAM_COL}>
          {u.teams ? <TeamsPills teams={u.teams} /> : " "}
        </XTableCell>,
        <XTableCell key={SECURITY_RATING_COL}>
          <div className="score-container">
            <ColorGrade score={u.adjustedRating} size={ColorGradeSize.Small} />
            <Score score={u.adjustedRating} small />
          </div>
        </XTableCell>,
      ],
      iconOptions: [
        {
          id: "click",
          icon: <div className={"cr-icon-chevron"} />,
          disabled: false,
          onClick: () => onViewUser(u),
        },
      ],
    };
  });

  return (
    <div className={classNames("userbase-users-table", className)}>
      <ReportCard newStyles className={"users"}>
        <XTable
          className={"users-list"}
          loading={isLoading}
          sortedBy={sortedBy}
          onSortChange={onSortChange}
          iconOptions
          pagination={{
            currentPage: currentPage,
            totalPages: totalPages,
            onPageChange: onPageChange,
            hidePaginationIfSinglePage: true,
          }}
          columnHeaders={columnHeaders}
          rows={rows}
        />
      </ReportCard>

      <SlidePanel
        newStyles
        dimContent
        active={!!slidePanelUser}
        className={"user-apps-slide-panel"}
        onClose={() => {
          setSlidePanelUser(undefined);
        }}
      >
        {!!slidePanelUser && (
          <UserAppsForSpecificScopePanel user={slidePanelUser} scope={scope} />
        )}
      </SlidePanel>
    </div>
  );
};

export default UserTableForScope;
