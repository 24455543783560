import { IdAndName } from "../../../_common/types/vendor";
import { FC, useEffect } from "react";
import SlidePanelSection from "./SlidePanelSection";
import {
  OptionType,
  SelectV2Multi,
} from "../../../_common/components/SelectV2";
import { ValueType } from "react-select";
import LabelList from "../LabelList";
import { getColorForKey } from "../PillLabel";

interface IdAndNameFilterProps {
  title: string;
  getValues: () => void;
  values?: IdAndName[];
  selected: number[];
  onChanged: (selectedIds: number[]) => void;
}

const IdAndNameFilter: FC<IdAndNameFilterProps> = ({
  title,
  getValues,
  values,
  selected,
  onChanged,
}) => {
  // On initial render ensure we have the values
  useEffect(() => {
    getValues();
  }, []);

  const availableValues: OptionType[] = [];
  const selectedValues: OptionType[] = [];

  (values ?? []).forEach((v) => {
    if (selected.indexOf(v.id) !== -1) {
      selectedValues.push({ value: v.id, label: v.name });
    } else {
      availableValues.push({ value: v.id, label: v.name });
    }
  });

  const selectOnChange = (selectedValues: ValueType<OptionType, true>) => {
    const selected: number[] = selectedValues
      ? selectedValues.map(({ value }) => value as number)
      : [];

    onChanged(selected);
  };

  const removeClick = (value: number) => {
    onChanged(selected.filter((v) => v !== value));
  };

  const labels = selectedValues
    .sort((a, b) => (a.label > b.label ? 1 : -1))
    .map((st) => ({
      id: st.value,
      name: st.label,
      color: getColorForKey(st.label),
      removeable: true,
      constrained: true,
      onRemoveClick: () => removeClick(st.value as number),
    }));

  return (
    <SlidePanelSection title={title} expanded={selectedValues.length > 0}>
      <SelectV2Multi
        isMulti
        placeholder={`Type to search...`}
        options={availableValues}
        value={selectedValues}
        onChange={selectOnChange}
        controlShouldRenderValue={false}
        captureMenuScroll={false}
        isLoading={values === undefined}
      />
      <LabelList labels={labels} maxNumber={6} popupPosition={"left"} />
    </SlidePanelSection>
  );
};

export default IdAndNameFilter;
