import { DefaultAction } from "../../_common/types/redux";
import { FetchCyberRiskUrl } from "../../_common/api";
import { LogError } from "../../_common/helpers";
import { addDefaultUnknownErrorAlert } from "../../_common/reducers/messageAlerts.actions";
import { adminReducerAction } from "./adminReducer";
import { SSODetails } from "../../_common/types/sso";

export const SET_SSO_LIST = "ADMIN_SET_SSO_LIST";

export const setSSOList = (
  loading: boolean,
  data?: SSODetails[],
  error?: IError
): adminReducerAction => ({
  type: SET_SSO_LIST,
  loading,
  data,
  error,
});

export const FetchSSOList =
  (force = false): DefaultAction<SSODetails[]> =>
  async (dispatch, getState) => {
    if (
      !force &&
      !getState().admin.ssoList.loading &&
      !getState().admin.ssoList.error
    ) {
      return getState().admin.ssoList?.data ?? [];
    }

    dispatch(setSSOList(true));

    let json: { status: string; ssoList: SSODetails[] };
    try {
      json = await FetchCyberRiskUrl(
        "admin/sso/v1",
        {},
        null,
        dispatch,
        getState
      );
    } catch (e) {
      LogError(e);
      dispatch(addDefaultUnknownErrorAlert("error getting SSO list"));
      dispatch(
        setSSOList(false, undefined, {
          actionText: "Reload",
          errorText: "Error loading SSO list",
          actionOnClick: () => dispatch(FetchSSOList(true)),
        })
      );
      throw e;
    }

    dispatch(setSSOList(false, json.ssoList));

    return json.ssoList;
  };

export const SET_SSO_ENTRY = "ADMIN_SET_SSO_ENTRY";

export const setSSOEntry = (
  domain: string,
  enabled: boolean,
  supportsLogout: boolean
): adminReducerAction => ({
  type: SET_SSO_ENTRY,
  domain,
  enabled,
  supportsLogout,
});

export const ToggleSSOEntry =
  (
    domain: string,
    enabled: boolean,
    supportsLogout: boolean,
    isNew = false
  ): DefaultAction =>
  async (dispatch, getState) => {
    dispatch(setSSOList(true));

    try {
      await FetchCyberRiskUrl(
        "admin/sso/v1",
        { domain, enabled, supports_logout: supportsLogout },
        { method: "PUT" },
        dispatch,
        getState
      );
    } catch (e) {
      LogError(e);
      dispatch(
        addDefaultUnknownErrorAlert(
          "error adding or updating SSO status for domain"
        )
      );
      throw e;
    } finally {
      if (!isNew) {
        dispatch(setSSOList(false));
      }
    }

    if (isNew) {
      dispatch(FetchSSOList(true));
    } else {
      dispatch(setSSOEntry(domain, enabled, supportsLogout));
    }
  };
