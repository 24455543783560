import { IUserMini } from "../types/user";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import "../style/components/UserAssignment.scss";
import classnames from "classnames";
import UserAvatar, { UserAvatarAndName } from "./UserAvatar";
import IconButton from "./IconButton";
import { OptionType, SelectV2, SelectV2Multi } from "./SelectV2";
import UserAvatarList from "./UserAvatarList";

interface IUserAssignmentProps {
  assignedUsers: IUserMini[];
  allowMultiple?: boolean;
  currentUserCanEdit: boolean;
  availableUsers?: IUserMini[];
  onChangeAssignees: (newAssignees: IUserMini[]) => Promise<void>;
  onClickInviteMore?: () => void;
  requestUnassign?: (onConfirm: () => void) => void;
  placeHolder?: string;
}

interface selectUserOptionType extends OptionType {
  sharedUser?: IUserMini;
}

const UserAssignment: FC<IUserAssignmentProps> = ({
  assignedUsers: assignedUsersProp,
  allowMultiple = false,
  currentUserCanEdit,
  availableUsers,
  onChangeAssignees: onChangeAssigneesProp,
  onClickInviteMore,
  requestUnassign,
  placeHolder,
}) => {
  // Store the assigned user in state so we can optimistically update it without waiting
  const [assignedUsers, setAssignedUsers] = useState(assignedUsersProp);
  const [saveInProgress, setSaveInProgress] = useState(false);
  const [selectAssigneeMode, setSelectAssigneeMode] = useState(false);

  useEffect(() => {
    // Always respect the passed in assigned users if they changed
    setAssignedUsers(assignedUsersProp);
  }, [assignedUsersProp]);

  const onChangeAssignee = useCallback(
    async (newAssignees: IUserMini[]) => {
      const previousAssignee = assignedUsers;
      setAssignedUsers(newAssignees);
      setSaveInProgress(true);

      if (!allowMultiple) {
        setSelectAssigneeMode(false);
      }
      try {
        await onChangeAssigneesProp(newAssignees);
      } catch (e) {
        setAssignedUsers(previousAssignee);
      }
      setSaveInProgress(false);
    },
    [assignedUsers, onChangeAssigneesProp, allowMultiple]
  );

  const handleAssigneeChange = async (newAssignees: IUserMini[]) => {
    if (newAssignees.length === 0 && requestUnassign) {
      requestUnassign(() => onChangeAssignee(newAssignees));
    } else {
      await onChangeAssignee(newAssignees);
    }
  };

  const selectOptions: selectUserOptionType[] | undefined = useMemo(() => {
    if (!availableUsers) {
      return undefined;
    }

    const options: selectUserOptionType[] = [...availableUsers]
      .sort((a, b) => (a.name || a.email).localeCompare(b.name || b.email))
      .map((u) => ({
        label: u.name || u.email,
        value: u.id,
        sharedUser: u,
      }));

    if (onClickInviteMore) {
      options.push({
        label: "Invite more people to your account",
        value: -1,
      });
    }

    return options;
  }, [availableUsers, onClickInviteMore]);

  const formatOptionlabel = useCallback(
    (opt: selectUserOptionType) =>
      opt.sharedUser ? (
        <div className="assignee-select-option">
          <UserAvatar avatar={opt.sharedUser.avatar} />
          {opt.sharedUser.name || opt.sharedUser.email}
        </div>
      ) : (
        <div className="invite-more-link">+ {opt.label}</div>
      ),
    []
  );

  return (
    <div className={classnames("user-assignment")}>
      {selectAssigneeMode ? (
        <div className="assignee-select">
          {allowMultiple ? (
            <SelectV2Multi<selectUserOptionType>
              isLoading={!selectOptions}
              options={selectOptions}
              value={selectOptions?.filter(
                (opt) =>
                  !!assignedUsers.find((u) => u.id === opt.sharedUser?.id)
              )}
              placeholder={placeHolder ? placeHolder : "Select assignees"}
              autoFocus
              openMenuOnFocus
              isClearable={false}
              isSearchable={!onClickInviteMore}
              onBlur={() => setSelectAssigneeMode(false)}
              formatOptionLabel={formatOptionlabel}
              onChange={(val) => {
                if (!val) {
                  handleAssigneeChange([]);
                  return;
                }

                const sharedUsers: IUserMini[] = [];
                for (let i = 0; i < val.length; i++) {
                  if (val[i].sharedUser) {
                    sharedUsers.push(val[i].sharedUser!);
                  } else {
                    // When clicking the "invite more" link, exit edit mode
                    // and call the callback
                    onClickInviteMore?.();
                    setSelectAssigneeMode(false);
                    return;
                  }
                }

                handleAssigneeChange(sharedUsers);
              }}
            />
          ) : (
            <SelectV2<false, selectUserOptionType>
              isLoading={!selectOptions}
              controlShouldRenderValue={false}
              options={selectOptions}
              placeholder={placeHolder ? placeHolder : "Select an assignee"}
              autoFocus
              openMenuOnFocus
              isSearchable={!onClickInviteMore}
              onMenuClose={() => setSelectAssigneeMode(false)}
              formatOptionLabel={formatOptionlabel}
              onChange={(val) => {
                if (val && !val.sharedUser) {
                  // When clicking the "invite more" link, exit edit mode
                  // and call the callback
                  onClickInviteMore?.();
                  return;
                }
                handleAssigneeChange(val ? [val.sharedUser!] : []);
              }}
            />
          )}
        </div>
      ) : assignedUsers.length > 0 ? (
        <div className="assignee">
          {assignedUsers.length === 1 ? (
            <UserAvatarAndName
              name={assignedUsers[0].name}
              email={assignedUsers[0].email}
              avatar={assignedUsers[0].avatar}
            />
          ) : (
            <UserAvatarList users={assignedUsers} maxShown={3} />
          )}
          {currentUserCanEdit &&
            (allowMultiple ? (
              <IconButton
                className="edit-btn"
                icon={<div className="cr-icon-pencil-2" />}
                disabled={saveInProgress}
                onClick={() => setSelectAssigneeMode(true)}
              />
            ) : (
              <IconButton
                className="edit-btn"
                icon={<div className="icon-x" />}
                disabled={saveInProgress}
                onClick={() => handleAssigneeChange([])}
              />
            ))}
        </div>
      ) : (
        <div
          className={classnames("no-assignee", {
            editable: currentUserCanEdit && !saveInProgress,
          })}
          onClick={
            currentUserCanEdit && !saveInProgress
              ? () => setSelectAssigneeMode(true)
              : undefined
          }
        >
          {noAssigneeSVG}
          No {allowMultiple ? "Assignees" : "Assignee"}
        </div>
      )}
    </div>
  );
};

export const noAssigneeSVG = (
  <svg
    width="26"
    height="26"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19 19V18.4C19 16.5222 17.5729 15 15.8125 15H10.1875C8.42709 15 7 16.5222 7 18.4V19"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle
      cx="13"
      cy="9"
      r="3"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect
      x="0.5"
      y="0.5"
      width="25"
      height="25"
      rx="12.5"
      strokeDasharray="2 2"
    />
  </svg>
);

export default UserAssignment;
