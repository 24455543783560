import { PropsWithChildren, useEffect } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import Icon from "./core/Icon";
import classnames from "classnames";

import "../style/components/SlidePanel.scss";

export interface IBaseSlidePanelProps {
  active: boolean;
  onClose: () => void;
}

interface ISlidePanelProps extends PropsWithChildren<IBaseSlidePanelProps> {
  title?: React.ReactNode;
  titleOnClick?: () => void;
  actions?: {
    name: string;
    onClick: () => void;
  }[];
  dimContent?: boolean;
  className?: string;
  newStyles?: boolean;
}

/**
 * <SlidePanel />
 * Creates a fixed panel that slides in from the right. This component should always be mounted by its
 * parent, and have its active state controlled via the "active" prop.
 */
const SlidePanel: React.FC<ISlidePanelProps> = (props) => {
  const {
    active,
    title,
    titleOnClick,
    actions = [],
    dimContent = false,
    onClose,
    className = "",
    newStyles,
    children,
  } = props;

  useEffect(() => {
    // If rendering in modal-ish fashion, disable background scroll
    if (dimContent && active) {
      document.body.classList.add("no-scroll-slide-panel");
      return () => {
        document.body.classList.remove("no-scroll-slide-panel");
      };
    }

    return;
  }, [dimContent, active]);

  return (
    <TransitionGroup>
      {active && (
        <CSSTransition key="slidepanel" timeout={250} classNames="slide-left">
          <div className={`slide-panel-container ${className}`}>
            {dimContent && <div className="dimmer" onClick={() => onClose()} />}
            <div
              className={classnames("slide-panel", { "new-styles": newStyles })}
            >
              <div className="title">
                {typeof title === "string" ? (
                  <h2
                    onClick={titleOnClick}
                    className={titleOnClick ? "clickable" : ""}
                  >
                    {title}
                  </h2>
                ) : (
                  title
                )}
                {actions.map((action) => (
                  <div
                    className="action"
                    onClick={action.onClick}
                    key={action.name}
                  >
                    {action.name}
                  </div>
                ))}
                <Icon name="x" className="close" onClick={() => onClose()} />
              </div>
              <div className="container">{children}</div>
            </div>
          </div>
        </CSSTransition>
      )}
    </TransitionGroup>
  );
};

SlidePanel.defaultProps = {
  dimContent: false,
  className: "",
  actions: [],
  newStyles: false,
};

export default SlidePanel;
