import {
  Comparator,
  Expression,
  Operator,
  SingleAnswerComparison,
} from "../survey_builder/types/conditionalLogic";
import {
  Node,
  NodeType,
  RiskNode,
  SectionNode,
  SelectNode,
} from "../survey_builder/types/types";
import { isNaN } from "lodash";
import { getAllNodesAsTree, NodeSummary } from "../survey_builder/helpers";
import { vsaqQuestion } from "../survey_builder/vsaq/vsaqTypes";
import {
  convertVsaqStructureToSurvey,
  processQuestion,
} from "../survey_builder/vsaq/vsaqConvert";
import { Answers } from "../vendorrisk/reducers/questionnaireAnswers.actions";
import {
  getAllAnswersFromGptSuggestions,
  GptAutofillCacheStatus,
  GptAutofillSuggestion,
  GptSuggestions,
} from "./reducers/autofill.actions";
import { SurveyViewFilterType } from "./reducers/reducer";

export interface NodeSummaryAndNode extends NodeSummary {
  node: Node;
  children?: NodeSummaryAndNode[];

  // All parent node ids (in order) of the node, except the root node
  parentIdMap: { [nodeId: string]: Record<string, unknown> };

  // All child node ids (in order, all levels, depth first) of the node
  childIdMap: { [nodeId: string]: Record<string, unknown> };
}

// Convert vsaq structure to a nested structure used by the Survey Viewer
export const getNodeSummaryTreeWithNodes = (
  surveyId: string,
  vsaq: vsaqQuestion
) => {
  const survey = convertVsaqStructureToSurvey(surveyId, vsaq, false);

  const nodeSummaries = getAllNodesAsTree(survey);

  const rootNode = survey.nodes[survey.rootNodeId];

  const root = {
    nodeId: rootNode?.nodeId,
    children: nodeSummaries as NodeSummaryAndNode[],
  } as NodeSummaryAndNode;

  // Merge in the full node object as we'll need extra things in the Survey Viewer
  const rootNodeId = rootNode?.nodeId;
  let parentIds: string[] = [];
  const mergeNodes = (nodeSummary: NodeSummaryAndNode) => {
    const node = survey.nodes[nodeSummary.nodeId];

    // We can skip the root node
    if (nodeSummary.parentId) {
      if (
        parentIds.indexOf(nodeSummary.parentId) === -1 &&
        nodeSummary.parentId !== rootNodeId
      ) {
        parentIds.push(nodeSummary.parentId);
      }
    }

    if (node) {
      nodeSummary.node = node;

      // Fill in parent map
      nodeSummary.parentIdMap = {};
      parentIds.forEach((n) => (nodeSummary.parentIdMap[n] = {}));

      // Fill in child map
      nodeSummary.childIdMap = getChildNodeIdMap(nodeSummary);
    }

    nodeSummary.children?.forEach((n) => mergeNodes(n));

    parentIds = parentIds.filter((pId) => pId !== nodeSummary.parentId);
  };

  mergeNodes(root);

  return root;
};

// Check if an expression or an array of expressions are true
export const isConditionalExpressionMet = (
  expr: Expression | undefined,
  allAnswers: AnswersForNodes,
  visibleNodeIds: VisiblityForNodes
): boolean => {
  // No expression is always valid
  if (!expr) {
    return true;
  }

  if (Array.isArray(expr)) {
    // Single length array so we can just run it as-is and return the result
    if (expr.length === 1) {
      return isConditionalExpressionMet(expr[0], allAnswers, visibleNodeIds);
    }

    // Otherwise, multiple elements at this level so work through the conditionals, AND's, then OR's

    // Expressions left to process
    // We'll also store already-processed conditionals in here as booleans to apply
    // to later operators is needed
    const remainingParts: (Expression | boolean)[] = [...expr];
    let currentIdx = 0;
    let result = false;

    // We'll loop through twice; once to process AND's, then again to process any OR's
    let andsDone = false;

    while (remainingParts.length > 0) {
      if (remainingParts.length === 0) {
        throw new Error("Error processing array conditions: empty array");
      } else if (
        remainingParts.length === 1 &&
        typeof remainingParts[0] === "boolean"
      ) {
        // Only 1 item remaining, and it is an already processed result, so return it and break out
        result = remainingParts[0];
        break;
      } else if (currentIdx > remainingParts.length) {
        throw new Error("Error processing array conditions: out of bounds");
      }

      const v = expr[currentIdx];

      if (typeof v === "string") {
        // Found an operator, so check if we should process it yet
        const op = v as Operator;
        if (op === Operator.And || (op === Operator.Or && andsDone)) {
          const parts = remainingParts.splice(currentIdx - 1, 3);
          currentIdx = currentIdx - 1;

          // Process conditionals if needed
          const leftSideOutput =
            typeof parts[0] === "boolean"
              ? parts[0]
              : isConditionalExpressionMet(
                  parts[0],
                  allAnswers,
                  visibleNodeIds
                );
          const rightSideOutput =
            typeof parts[2] === "boolean"
              ? parts[2]
              : isConditionalExpressionMet(
                  parts[2],
                  allAnswers,
                  visibleNodeIds
                );

          // Apply operator to the results
          let isCurrentSetValid = false;
          if (parts[1] === Operator.And) {
            isCurrentSetValid = leftSideOutput && rightSideOutput;
          } else if (parts[1] === Operator.Or) {
            isCurrentSetValid = leftSideOutput || rightSideOutput;
          }

          // Push the result back into the remaining parts to be processed if needed
          remainingParts.splice(currentIdx, 0, isCurrentSetValid);
        }
      }

      if (currentIdx === remainingParts.length - 1 && !andsDone) {
        // End, so loop back through and finish up anything we didn't process yet (e.g. OR's)
        currentIdx = 0;
        andsDone = true;
      } else {
        currentIdx = currentIdx + 1;
      }
    }

    return result;
  } else if (typeof expr === "object") {
    return resolveExpression(expr, allAnswers, visibleNodeIds);
  } else {
    throw new Error(`expr ${expr} unsupported here`);
  }
};

// Determine if a single conditional expression is true
const resolveExpression = (
  expr: SingleAnswerComparison,
  allAnswers: AnswersForNodes,
  visibleNodeIds: VisiblityForNodes
) => {
  const answer = allAnswers[expr.nodeId];
  let isAnswerMatch = false;

  if (answer === undefined) {
    // No answer provided yet
  } else if (typeof answer === "string") {
    // Answer set is single answer node, so simply check
    isAnswerMatch = answer === expr.answerId;
  } else {
    // Answer set is for multi answer node, so check if the required answer is checked
    const answerAsMulti = answer as SelectAnswers;
    isAnswerMatch = answerAsMulti[expr.answerId] === "checked";
  }

  const isTargetNodeIdVisible =
    visibleNodeIds[expr.nodeId] !== undefined &&
    visibleNodeIds[expr.nodeId] !== false;

  // Treat invisible nodes as unanswered
  if (!isTargetNodeIdVisible) {
    isAnswerMatch = false;
  }

  switch (expr.comparator) {
    case Comparator.Equals:
      return isAnswerMatch;
    case Comparator.NotEquals:
      return !isAnswerMatch;
    case Comparator.Visible:
      return isTargetNodeIdVisible;
    case Comparator.NotVisible:
      return !isTargetNodeIdVisible;
    default:
      throw new Error(`Comparator ${expr.comparator} not supported`);
  }
};

export interface SelectAnswers {
  [answerId: string]: string;
}

export interface AnswersForNodes {
  [nodeId: string]: undefined | string | SelectAnswers;
}

export interface AnswersChanged {
  answers: {
    [nodeId: string]: boolean;
  };
  totalChanged: number;
}

export type FilteredNodes = string[];
export type FilteredNodesIdx = {
  [nodeId: string]: number;
};

export interface FilteredNodeData {
  activeFilter: SurveyViewFilterType;
  currentSelectedItem: number;
  numQuestions: number;
  nodeSets: {
    [filterType: string]: FilteredNodes;
  };
  setIndexes: {
    [filterType: string]: FilteredNodesIdx;
  };
}

// Convert a flat set of question answers into a per-node structure
export const getAnswersForNodes = (
  answers: Answers,
  vsaqStructure: vsaqQuestion
): AnswersForNodes => {
  const answersForNodes: AnswersForNodes = {};

  const answerIdsToNodeIds: { [answerId: string]: string } = {};
  const questionsMap: { [qId: string]: vsaqQuestion | undefined } = {};
  const allNodes: { [nodeId: string]: Node | undefined } = {};

  // Get all the answersIds for nodeIds
  processQuestion(vsaqStructure, answerIdsToNodeIds, questionsMap, allNodes);

  // Spin through all the ids for answers, which can be either a nodeId OR an answerId
  for (const nodeOrAnswerId in answers) {
    const nodeIdForAnswerId = answerIdsToNodeIds[nodeOrAnswerId];

    if (nodeIdForAnswerId === undefined) {
      // Answer is for a non-select question, so just set answer as string
      answersForNodes[nodeOrAnswerId] = answers[nodeOrAnswerId];
    } else {
      // Answer is for a select question, so add answer to set for nodeId
      let answersForMulti = answersForNodes[nodeIdForAnswerId] as SelectAnswers;
      if (answersForMulti === undefined) {
        answersForMulti = {} as SelectAnswers;
      } else if (typeof answersForMulti === "string") {
        // This should not happen - but if it does, lets attempt to recover gracefully
        console.warn(
          `Invalid type for select answer ${nodeOrAnswerId}: ${answersForMulti}`
        );
        answersForMulti = {} as SelectAnswers;
      }
      answersForMulti[nodeOrAnswerId] = answers[nodeOrAnswerId];
      answersForNodes[nodeIdForAnswerId] = answersForMulti;
    }
  }

  return answersForNodes;
};

// Convert an answer per-node structure into a flat structure for saving
export const getAnswersFlattened = (answers: AnswersForNodes): Answers => {
  const answersFlattened: Answers = {};

  for (const k in answers) {
    const answer = answers[k];
    if (typeof answer === "object") {
      for (const ak in answer) {
        answersFlattened[ak] = answer[ak] ?? "";
      }
    } else {
      answersFlattened[k] = answer ?? "";
    }
  }

  return answersFlattened;
};

export interface VisiblityForNodes {
  [nodeId: string]: undefined | boolean;
}

export const mergeSuggestedAnswers = (
  answers: AnswersForNodes,
  suggestedAnswers: AnswersForNodes,
  override: boolean
): AnswersForNodes => {
  if (override) {
    return {
      ...answers,
      ...suggestedAnswers,
    };
  } else {
    return {
      ...suggestedAnswers,
      ...answers,
    };
  }
};

// Recurse through the node tree to check if any node has a conditional
export const checkIfAnyConditional = (
  nodeTree: NodeSummaryAndNode | undefined
): boolean => {
  if (!nodeTree) {
    return false;
  }

  if (nodeTree.node.conditionalExpression) {
    return true;
  }

  if (nodeTree.children) {
    for (const c of nodeTree.children) {
      if (!c) {
        continue;
      }

      const hasConditional = checkIfAnyConditional(c);
      if (hasConditional) {
        return true;
      }
    }
  }

  return false;
};

// Get node visibility states for a nodeTree
export const getNodeVisibilities = (
  nodeTree: NodeSummaryAndNode | undefined,
  answers: AnswersForNodes,
  gptCache?: GptAutofillCacheStatus
): VisiblityForNodes => {
  const visibilityForNodes: VisiblityForNodes = {};

  if (nodeTree !== undefined) {
    // if we have a suggestion cache we need to merge it in with the answers for visibility calculation
    if (gptCache) {
      const suggestedAnswers = getAllAnswersFromGptSuggestions(
        gptCache.suggestions,
        nodeTree
      );
      answers = mergeSuggestedAnswers(
        answers,
        suggestedAnswers,
        gptCache.overrideAnswers
      );
    }

    getNodeVisibility(nodeTree, answers, visibilityForNodes, true);
  }

  return visibilityForNodes;
};

// Recurse through a node tree and determine visibility states
const getNodeVisibility = (
  nodeSummary: NodeSummaryAndNode,
  answers: AnswersForNodes,
  visibilityForNodes: VisiblityForNodes,
  isParentVisible: boolean
) => {
  const isVisible =
    isParentVisible &&
    isConditionalExpressionMet(
      nodeSummary.node.conditionalExpression,
      answers,
      visibilityForNodes
    );

  visibilityForNodes[nodeSummary.nodeId] = isVisible;

  // Check if we need to check any child nodes
  if (nodeSummary.node.type === NodeType.Section) {
    nodeSummary.children?.forEach((n) =>
      getNodeVisibility(n, answers, visibilityForNodes, isVisible)
    );
  }
};

export const isQuestionNode = (nodeSummary: NodeSummaryAndNode) => {
  return (
    nodeSummary.node.type === NodeType.InputText ||
    nodeSummary.node.type === NodeType.Select ||
    nodeSummary.node.type === NodeType.Upload
  );
};

export const isQuestionOrRiskNode = (nodeSummary: NodeSummaryAndNode) =>
  isQuestionNode(nodeSummary) || nodeSummary.node.type == NodeType.Risk;

export interface SectionNodeAnswerState {
  questionCount: number;
  answeredCount: number;
  optionalCount: number;
  optionalAnsweredCount: number;
  suggested: number;
  optionalSuggested: number;
}

export const getNumSuggested = (s: SectionNodeAnswerState) =>
  s.suggested + s.optionalSuggested;
export const getNumUnanswered = (s: SectionNodeAnswerState) =>
  s.questionCount +
  s.optionalSuggested -
  s.answeredCount +
  s.optionalAnsweredCount;

export interface NodeChildrenAnswerState {
  [nodeId: string]: SectionNodeAnswerState;
}

// Get section node answered states for a node tree
export const getAllSectionNodeChildrenAnswered = (
  nodeTree: NodeSummaryAndNode | undefined,
  answers: AnswersForNodes,
  visibilities: VisiblityForNodes,
  gptSuggestions?: GptSuggestions
): NodeChildrenAnswerState => {
  const answerStates: NodeChildrenAnswerState = {};

  if (nodeTree !== undefined) {
    isSectionNodeChildrenAnswered(
      nodeTree,
      answers,
      visibilities,
      answerStates,
      gptSuggestions
    );
  }

  return answerStates;
};

// Recurse through the tree and derive if each section node has had all it's children answered
const isSectionNodeChildrenAnswered = (
  nodeSummary: NodeSummaryAndNode,
  answers: AnswersForNodes,
  visibilities: VisiblityForNodes,
  state: NodeChildrenAnswerState,
  gptSuggestions?: GptSuggestions
) => {
  if (
    visibilities[nodeSummary.nodeId] === false ||
    visibilities[nodeSummary.nodeId] === undefined
  ) {
    return undefined; // Ignore if not visible
  } else if (
    nodeSummary.node.type === NodeType.Risk ||
    nodeSummary.node.type === NodeType.Info
  ) {
    return undefined; // Ignore if it is a risk question
  } else if (nodeSummary.node.type !== NodeType.Section) {
    return isQuestionAnswered(nodeSummary, answers, gptSuggestions);
  }

  let numQuestions = 0;
  let numAnswered = 0;
  let numOptional = 0;
  let numOptionalAnswered = 0;
  let numSuggested = 0;
  let numOptionalSuggested = 0;

  nodeSummary.children?.forEach((n) => {
    const answerState = isSectionNodeChildrenAnswered(
      n,
      answers,
      visibilities,
      state,
      gptSuggestions
    );

    if (typeof answerState === "object") {
      numQuestions = numQuestions + answerState.questionCount;
      numAnswered = numAnswered + answerState.answeredCount;
      numOptional = numOptional + answerState.optionalCount;
      numOptionalAnswered =
        numOptionalAnswered + answerState.optionalAnsweredCount;
      numSuggested = numSuggested + answerState.suggested;
      numOptionalSuggested =
        numOptionalSuggested + answerState.optionalSuggested;
    } else if (typeof answerState === "number") {
      if (answerState === QuestionAnswerState.Answered) {
        numQuestions = numQuestions + 1;
        numAnswered = numAnswered + 1;
      } else if (answerState === QuestionAnswerState.Optional) {
        numOptional = numOptional + 1;
      } else if (answerState === QuestionAnswerState.OptionalAnswered) {
        numOptional = numOptional + 1;
        numOptionalAnswered = numOptionalAnswered + 1;
      } else if (answerState == QuestionAnswerState.Unanswered) {
        numQuestions = numQuestions + 1;
      } else if (answerState == QuestionAnswerState.OptionalSuggested) {
        numOptionalSuggested += 1;
        numQuestions += 1;
      } else {
        numQuestions += 1;
        numSuggested += 1;
      }
    }
  });

  const stateForSection: SectionNodeAnswerState = {
    questionCount: numQuestions,
    answeredCount: numAnswered,
    optionalCount: numOptional,
    optionalAnsweredCount: numOptionalAnswered,
    suggested: numSuggested,
    optionalSuggested: numOptionalSuggested,
  };

  state[nodeSummary.nodeId] = stateForSection;

  return stateForSection;
};

export enum QuestionAnswerState {
  Answered,
  Unanswered,
  Optional,
  OptionalAnswered,
  Suggested,
  OptionalSuggested,
}

// Check if a question is answered
export const isQuestionAnswered = (
  nodeSummary: NodeSummaryAndNode,
  answers: AnswersForNodes,
  suggestions?: GptSuggestions
): QuestionAnswerState => {
  const node = nodeSummary.node;

  if (
    (node.type === NodeType.Risk && !node.why) ||
    node.type === NodeType.Info
  ) {
    return QuestionAnswerState.Answered; // Info only
  }

  const answer = answers[node.nodeId];
  const suggestion = suggestions?.[node.nodeId];
  const hasSuggestion =
    suggestion &&
    !suggestion.noSuggestion &&
    !suggestion.used &&
    !suggestion.rejected;

  if (answer === undefined) {
    if (node.type === NodeType.Select && !node.radio) {
      return hasSuggestion
        ? QuestionAnswerState.OptionalSuggested
        : QuestionAnswerState.Optional;
    } else {
      return hasSuggestion
        ? QuestionAnswerState.Suggested
        : QuestionAnswerState.Unanswered;
    }
  } else if (typeof answer === "string") {
    if (node.type === NodeType.Upload) {
      // Parse file answer
      const fileAnswers = getAnswerFilesFromFileAnswer(answer);
      return fileAnswers.length > 0
        ? QuestionAnswerState.Answered
        : QuestionAnswerState.Unanswered;
    } else {
      // if we have a suggestion then our state is always suggested, never answered
      return hasSuggestion
        ? QuestionAnswerState.Suggested
        : answer.length > 0
          ? QuestionAnswerState.Answered
          : QuestionAnswerState.Unanswered;
    }
  } else {
    // Answer set is for multi answer node, so check is one of answers checked
    let isSelectAnswered = false;

    const answerAsMulti = answer as SelectAnswers;
    for (const k in answerAsMulti) {
      if (answerAsMulti[k] === "checked") {
        isSelectAnswered = true;
      }
    }

    // We treat multi-select nodes (checkboxes) as optional questions
    if (!(node as SelectNode).radio) {
      return hasSuggestion
        ? QuestionAnswerState.OptionalSuggested
        : isSelectAnswered
          ? QuestionAnswerState.OptionalAnswered
          : QuestionAnswerState.Optional;
    }

    return hasSuggestion
      ? QuestionAnswerState.Suggested
      : isSelectAnswered
        ? QuestionAnswerState.Answered
        : QuestionAnswerState.Unanswered;
  }
};

interface AnswerFile {
  gcsObjName: string;
  filename: string;
  description: string;
  contentLibraryUUID?: string;
  contentLibraryVersion?: number;
  documentName?: string;
}

export const getAnswerFilesFromFileAnswer = (
  answer: undefined | string | SelectAnswers
): AnswerFile[] => {
  const fileAnswers: AnswerFile[] = [];

  if (answer && typeof answer === "string") {
    // These are either:
    // - (new style) stored as a JSON structure e.g [{ "gcsObjName": "gcs_obj_name", filename: "filename", description: "desc"}]
    // - (old style) stored in answers in the format "objectname1|filename1,objectname2|filename2".

    // First, lets try the new style
    try {
      const newStyleFileAnswers: AnswerFile[] = JSON.parse(answer);
      return newStyleFileAnswers;
    } catch {
      // Nothing to do here
    }

    // Otherwise, lets try the old style
    const fileEntries = answer.split(",");
    fileEntries.forEach((f) => {
      const gcsObjectNameAndFilename = f.split("|");
      if (gcsObjectNameAndFilename.length === 2) {
        fileAnswers.push({
          gcsObjName: gcsObjectNameAndFilename[0],
          filename: gcsObjectNameAndFilename[1],
          description: "",
        });
      }
    });
  }

  return fileAnswers;
};

export const fileAnswersToAnswer = (
  fileAnswers: AnswerFile[]
): string | undefined => {
  return fileAnswers.length > 0 ? JSON.stringify(fileAnswers) : undefined;
};

// String leading displayId e.g. '1.2.3.4. My question' = 'My question'
export const stripLeadingDisplayId = (val: string) => {
  let startPos = 0;
  let idx = 0;

  if (!val) {
    return val;
  }

  // Check if the val is HTML, if so start searching from inside first tag
  if (val[0] === "<") {
    idx = val.indexOf(">") + 1;
    startPos = idx;
  }

  let lastCharWasDot = false;
  while (idx < val.length) {
    const c = val[idx];
    const valAsInt = parseInt(c, 10);

    if (c !== "." && isNaN(valAsInt)) {
      if (!lastCharWasDot) {
        // We should only do this stripping when the number sequence ends in a dot character
        idx = 0;
      }

      break;
    }

    lastCharWasDot = c === ".";

    idx = idx + 1;
  }

  if (idx === val.length) {
    return val;
  } else {
    const toRemove = val.substring(startPos, idx);
    return val.replace(toRemove, "").trim();
  }
};

export const getNodeTitleDisplay = (
  nodeSummary: NodeSummaryAndNode,
  preferSanitised: boolean,
  useDefaultIfEmpty = false
) => {
  const node = nodeSummary.node;
  let titleText = "";
  let shouldStripLeadingDisplayId = true;
  switch (node.type) {
    case NodeType.Section:
      const nodeAsSection = node as SectionNode;
      titleText =
        preferSanitised && nodeAsSection.titleSanitised
          ? nodeAsSection.titleSanitised
          : nodeAsSection.title;
      break;
    case NodeType.Risk:
      const nodeAsRisk = node as RiskNode;
      shouldStripLeadingDisplayId = false;
      titleText =
        preferSanitised && node.nameSanitised
          ? node.nameSanitised
          : nodeAsRisk.name;
      break;
    default:
      titleText =
        preferSanitised && node.mainTextSanitised
          ? node.mainTextSanitised
          : node.mainText;
  }

  if (shouldStripLeadingDisplayId) {
    titleText = stripLeadingDisplayId(titleText);
  }

  if ((!titleText || titleText.length === 0) && useDefaultIfEmpty) {
    return getDefaultTextForNodeType(node.type);
  } else {
    return titleText;
  }
};

export type FlattenedNodeSummaryMap = { [nodeId: string]: NodeSummaryAndNode };

export const getNodeTreeFlattenedMap = (
  rootNode: NodeSummaryAndNode
): FlattenedNodeSummaryMap => {
  const nodeMap: FlattenedNodeSummaryMap = {};

  const addNodeAndChildren = (node: NodeSummaryAndNode) => {
    nodeMap[node.nodeId] = node;

    node.children?.forEach((n) => {
      addNodeAndChildren(n);
    });
  };

  addNodeAndChildren(rootNode);

  return nodeMap;
};

export const getNextUnansweredQuestion = (
  rootNode: NodeSummaryAndNode,
  answers: AnswersForNodes,
  visibilities: VisiblityForNodes,
  returnLast = false,
  suggestions?: GptSuggestions
) => {
  const treeFlattened = getNodeTreeFlattenedMap(rootNode);
  let latestNodeId = "";

  for (const [nodeId, n] of Object.entries(treeFlattened)) {
    latestNodeId = nodeId;

    if (isQuestionOrRiskNode(n) && visibilities[nodeId]) {
      const answerState = isQuestionAnswered(n, answers, suggestions);
      if (
        answerState == QuestionAnswerState.Suggested ||
        answerState == QuestionAnswerState.OptionalSuggested ||
        (answerState !== QuestionAnswerState.Answered &&
          answerState !== QuestionAnswerState.OptionalAnswered)
      ) {
        return n.nodeId;
      }
    }
  }

  return returnLast ? latestNodeId : "";
};

// getNumUnansweredQuestions excluding any that have a suggestion
export const getNumUnansweredQuestions = (
  rootNodeState: SectionNodeAnswerState
): number =>
  rootNodeState.questionCount +
  rootNodeState.optionalCount -
  (rootNodeState.answeredCount +
    rootNodeState.optionalAnsweredCount +
    rootNodeState.suggested +
    rootNodeState.optionalSuggested);

export const findNodeByID = (rootNode: NodeSummaryAndNode, nodeId: string) => {
  const findNode = (
    node: NodeSummaryAndNode
  ): NodeSummaryAndNode | undefined => {
    if (node.nodeId === nodeId) {
      return node;
    }

    if (node.children) {
      for (let i = 0; i < node.children.length; i++) {
        const found = findNode(node.children[i]);
        if (found) {
          return found;
        }
      }
    }

    return undefined;
  };

  return findNode(rootNode);
};

// findNodeAndParentByID
// can be used when you want to find a node, modify it and update the redux state to force a redraw of that node
export type nodeAndParent = {
  node: NodeSummaryAndNode;
  parent?: NodeSummaryAndNode;
  nodeIdx?: number;
};
export const findNodeAndParentByID = (
  rootNode: NodeSummaryAndNode,
  nodeId: string
) => {
  const findNode = (
    node: NodeSummaryAndNode,
    parent?: NodeSummaryAndNode,
    nodeIdx?: number
  ): nodeAndParent | undefined => {
    if (node.nodeId == nodeId) {
      return { node, parent, nodeIdx };
    }

    if (node.children) {
      for (let i = 0; i < node.children.length; i++) {
        const res = findNode(node.children[i], node, i);
        if (res) {
          return res;
        }
      }
    }
    return undefined;
  };

  return findNode(rootNode);
};

const getChildNodeIdMap = (node: NodeSummaryAndNode) => {
  const childNodeIdMap: { [nodeId: string]: Record<string, unknown> } = {};

  const addChildNodeIds = (n: NodeSummaryAndNode) => {
    n.children?.forEach((cn) => {
      childNodeIdMap[cn.nodeId] = {};
      addChildNodeIds(cn);
    });
  };

  addChildNodeIds(node);

  return childNodeIdMap;
};

export const getDefaultTextForNodeType = (type: NodeType) => {
  switch (type) {
    case NodeType.InputText:
    case NodeType.Select:
    case NodeType.Upload:
      return "Question";
    case NodeType.Risk:
      return "Risk";
    case NodeType.Section:
      return "Section";
    case NodeType.Info:
      return "Info";
    default:
      return "Unknown";
  }
};

// debugAnswersChanged - traverses the node tree and prints info about answers changed
// should be used for debugging only
const debugAnswersChanged = (
  nodeTree: NodeSummaryAndNode,
  changes: AnswersChanged
) => {
  if (changes.answers[nodeTree.nodeId]) {
    console.log(
      `${nodeTree.questionNumber} - ${nodeTree.nodeId} marked as changed`
    );
  }

  if (nodeTree.children) {
    nodeTree.children.forEach((c) => debugAnswersChanged(c, changes));
  }
};

export const getAnswersChanged = (
  nodeTree: NodeSummaryAndNode | undefined,
  leftAnswers: AnswersForNodes,
  rightAnswers: AnswersForNodes,
  leftVisibilities: VisiblityForNodes,
  rightVisibilities: VisiblityForNodes
): AnswersChanged => {
  const changes: AnswersChanged = { totalChanged: 0, answers: {} };

  // if our left answers are empty (ie not in diff mode) shortcut this
  if (Object.keys(leftAnswers).length == 0 || !nodeTree) {
    return changes;
  }

  getNodeAnswerChanged(
    nodeTree,
    leftAnswers,
    rightAnswers,
    changes,
    leftVisibilities,
    rightVisibilities
  );

  // count the number of answers changed before we mark sections as changed
  changes.totalChanged = Object.values(changes.answers).filter((a) => a).length;

  // the root node is always a section, check for sections with changes answers starting from the root
  changes.answers[nodeTree.nodeId] = getSectionNodesChildrenChanged(
    nodeTree,
    changes
  );

  return changes;
};

// note: since answers may be undefined we always need to coalesce them to strings
const getNodeAnswerChanged = (
  nodeSummary: NodeSummaryAndNode,
  leftAnswers: AnswersForNodes,
  rightAnswers: AnswersForNodes,
  answersChanged: AnswersChanged,
  leftVisibilities: VisiblityForNodes,
  rightVisibilities: VisiblityForNodes
) => {
  // if the node is invisible on both sides we know it's not changed and none of its children are
  // We need to do this because a node can be invisible and have an answer
  if (
    !leftVisibilities[nodeSummary.nodeId] &&
    !rightVisibilities[nodeSummary.nodeId]
  ) {
    answersChanged.answers[nodeSummary.nodeId] = false;
    return;
  }

  switch (nodeSummary.node.type) {
    // the first 3 cases all use the same code, @ts-ignore is used because ts complains about this
    case NodeType.InputText:
    // @ts-ignore
    case NodeType.Risk:
    case NodeType.Upload:
      answersChanged.answers[nodeSummary.nodeId] =
        (leftAnswers[nodeSummary.nodeId] ?? "") !=
        (rightAnswers[nodeSummary.nodeId] ?? "");
      break;
    case NodeType.Select:
      const leftAnswer =
        (leftAnswers[nodeSummary.nodeId] as SelectAnswers) ?? {};
      const rightAnswer =
        (rightAnswers[nodeSummary.nodeId] as SelectAnswers) ?? {};
      // first we check if the select choices changed
      const choicesChanged = nodeSummary.node.answers.some(
        (a) => (leftAnswer[a.id] ?? "") != (rightAnswer[a.id] ?? "")
      );
      // now, if notes are allowed, check if the notes changed
      let notesChanged = false;
      if (nodeSummary.node.allowNotes) {
        const notesAnswerID = nodeSummary.nodeId + "-notes";
        notesChanged = leftAnswer[notesAnswerID] != rightAnswer[notesAnswerID];
      }
      answersChanged.answers[nodeSummary.nodeId] =
        choicesChanged || notesChanged;
      break;
    default:
      answersChanged.answers[nodeSummary.nodeId] = false;
  }

  if (nodeSummary.node.type == NodeType.Section) {
    nodeSummary.children?.forEach((n) =>
      getNodeAnswerChanged(
        n,
        leftAnswers,
        rightAnswers,
        answersChanged,
        leftVisibilities,
        rightVisibilities
      )
    );
  }
};

// getSectionNodesChildrenChanged - traverses the node tree and sets changes to true for all section nodes if any children are changed
const getSectionNodesChildrenChanged = (
  nodeSummary: NodeSummaryAndNode,
  answersChanged: AnswersChanged
): boolean => {
  nodeSummary.children
    ?.filter((n) => n.node.type == NodeType.Section)
    .forEach(
      (n) =>
        (answersChanged.answers[n.nodeId] = getSectionNodesChildrenChanged(
          n,
          answersChanged
        ))
    );

  return (
    nodeSummary.children?.some((n) => answersChanged.answers[n.nodeId]) ?? false
  );
};

// countChildrenChanged given a node summary and a map of changed answers
// count the number of non-section children that have changed divided in
// answers and risks changes
export const countChildrenChanged = (
  nodeSummary: NodeSummaryAndNode,
  changes: AnswersChanged
): [number, number] => {
  return (
    nodeSummary.children?.reduce(
      (numChanged, child) => {
        if (child.node.type !== NodeType.Section) {
          const isRiskNode = child.node.type === NodeType.Risk;
          const answerChange =
            !isRiskNode && changes.answers[child.nodeId] ? 1 : 0;
          const riskChange =
            isRiskNode && changes.answers[child.nodeId] ? 1 : 0;
          numChanged[0] += answerChange;
          numChanged[1] += riskChange;
          return numChanged;
        }

        const [answerChange, riskChange] = countChildrenChanged(child, changes);
        numChanged[0] += answerChange;
        numChanged[1] += riskChange;
        return numChanged;
      },
      [0, 0]
    ) ?? [0, 0]
  );
};

export const countSuggestions = (
  gptSummary: GptAutofillCacheStatus,
  nodeTree: NodeSummaryAndNode
): number => {
  return (
    nodeTree.children?.reduce((count, child) => {
      return (
        count +
        (gptSummary.suggestions[child.nodeId] &&
        !gptSummary.suggestions[child.nodeId].used &&
        !gptSummary.suggestions[child.nodeId].noSuggestion
          ? 1
          : 0) +
        countSuggestions(gptSummary, child)
      );
    }, 0) ?? 0
  );
};

// insertNodeIntoTree
// inserts a new node into the tree after the parentNodeID
export const insertNodeIntoTree = (
  nodeTree: NodeSummaryAndNode,
  newNode: NodeSummaryAndNode,
  parentNodeID: string
): boolean => {
  const idx = nodeTree.children?.findIndex((c) => c.nodeId == parentNodeID);
  if (idx != undefined && idx > -1) {
    newNode.parentId = nodeTree.nodeId;
    newNode.node.parentId = nodeTree.nodeId;
    nodeTree.children?.splice(idx + 1, 0, newNode);
    console.log("inserted node into ", nodeTree.nodeId, " at ", idx);
    return true;
  }

  if (nodeTree.children) {
    for (const child of nodeTree.children) {
      if (insertNodeIntoTree(child, newNode, parentNodeID)) {
        return true;
      }
    }
  }

  return false;
};

export const deleteNodeFromTree = (
  nodeTree: NodeSummaryAndNode,
  nodeID: string
): boolean => {
  const idx = nodeTree.children?.findIndex((c) => c.nodeId == nodeID);
  if (idx && idx > -1) {
    // also need to remove the has risk marker from the parent
    if (idx > 0 && nodeTree.children?.[idx - 1]) {
      nodeTree.children[idx - 1].node.hasCustomRisk = false;
    }

    nodeTree.children?.splice(idx, 1);
    return true;
  }

  if (nodeTree.children) {
    for (const child of nodeTree.children) {
      if (deleteNodeFromTree(child, nodeID)) {
        return true;
      }
    }
  }

  return false;
};

// Filter out suggestions that have a current answer
export const filterAnsweredGptSuggestions = (
  suggestions: Record<string, GptAutofillSuggestion> | undefined,
  answers: AnswersForNodes
): Record<string, GptAutofillSuggestion> | undefined => {
  if (!suggestions) {
    return undefined;
  }

  const filteredSuggestions: Record<string, GptAutofillSuggestion> = {};

  for (const nodeId in suggestions) {
    const ans = answers[nodeId];
    if (!ans || suggestions[nodeId].used) {
      filteredSuggestions[nodeId] = suggestions[nodeId];
    }
  }

  return filteredSuggestions;
};
