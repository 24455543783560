import RiskWaiversTable from "../../vendorrisk/components/RiskWaiversTable";
import VendorRiskWaiversCard, {
  mergeWaiverArrays,
} from "../../vendorrisk/components/VendorRiskWaiversCard";
import {
  ApproverAcceptedRisks,
  DefaultThunkDispatchProp,
  IUserData,
} from "../../_common/types/redux";
import {
  IVendorRiskWaiver,
  WaiverType,
} from "../../_common/types/vendorRiskWaivers";
import { DefaultRouteProps } from "../../_common/types/router";
import PivotTabs, { PivotTab } from "../../_common/components/PivotTabs";
import { getSearchParamAsNumber } from "../../_common/helpers/search.helpers";

import "../style/views/RiskWaiverApprovalsList.scss";
import PageHeader from "../../_common/components/PageHeader";
import { AssuranceType } from "../../_common/types/organisations";
import { useVendorWords } from "../../_common/hooks";
import { appConnect } from "../../_common/types/reduxHooks";
import { useCurrentOrg } from "../../_common/selectors/commonSelectors";
import { OrganisationAcceptedRisk } from "../../vendorrisk/reducers/customerAcceptedRisks.actions";
import { useMemo } from "react";

export type RiskWaiverApprovalsType =
  | "risk-waivers"
  | "vendor-risk-waivers"
  | "userbase-risk-waivers";

interface IRiskWaiverApprovalsListOwnProps extends DefaultRouteProps {
  includeOwnOrgWaivers: boolean;
  includeOtherOrgRiskWaivers: boolean;
  waiverType?: RiskWaiverApprovalsType;
}

interface IRiskWaiverApprovalsListConnectedProps {
  vendorRiskWaivers?: IVendorRiskWaiver[];
  acceptedRisks: ApproverAcceptedRisks | null;
  currentUser: IUserData;
  assuranceType: AssuranceType;
}

type RiskWaiverApprovalsListProps = IRiskWaiverApprovalsListOwnProps &
  IRiskWaiverApprovalsListConnectedProps &
  DefaultThunkDispatchProp;

interface RiskWaiverApprovalsListInitialSelectionState {
  riskWaiverId?: number;
  userBaseRiskWaiverId?: number;
  vendorRiskWaiverId?: number;
  tab?: string;
}

const RiskWaiverApprovalsList = (props: RiskWaiverApprovalsListProps) => {
  const currentOrg = useCurrentOrg();

  const vendorWords = useVendorWords();

  const filteredBreachRiskWaivers = useMemo(() => {
    const waivers: OrganisationAcceptedRisk[] = [];

    const includeBreachRiskWaivers =
      !props.waiverType || props.waiverType === "risk-waivers";

    if (includeBreachRiskWaivers) {
      props.acceptedRisks?.acceptedRisks?.forEach((waiver) => {
        if (
          (props.includeOwnOrgWaivers &&
            waiver.organisationId === currentOrg?.id) ||
          (props.includeOtherOrgRiskWaivers &&
            waiver.organisationId !== currentOrg?.id)
        ) {
          waivers.push(waiver);
        }
      });
    }

    return waivers;
  }, [
    props.acceptedRisks?.acceptedRisks,
    props.includeOtherOrgRiskWaivers,
    props.includeOwnOrgWaivers,
    props.waiverType,
  ]);

  const filteredVendorRiskWaivers = useMemo(() => {
    const waivers: IVendorRiskWaiver[] = [];

    const includeVendorRiskWaivers =
      !props.waiverType || props.waiverType === "vendor-risk-waivers";

    if (includeVendorRiskWaivers) {
      props.vendorRiskWaivers?.forEach((waiver) => {
        if (
          (props.includeOwnOrgWaivers &&
            waiver.organisationID === currentOrg?.id) ||
          (props.includeOtherOrgRiskWaivers &&
            waiver.organisationID !== currentOrg?.id)
        ) {
          waivers.push(waiver);
        }
      });
    }

    return waivers;
  }, [
    props.includeOtherOrgRiskWaivers,
    props.includeOwnOrgWaivers,
    props.vendorRiskWaivers,
    props.waiverType,
  ]);

  const filteredUserRiskWaivers = useMemo(() => {
    const waivers: OrganisationAcceptedRisk[] = [];

    const includeUserRiskWaivers =
      !props.waiverType || props.waiverType === "userbase-risk-waivers";

    if (includeUserRiskWaivers) {
      props.acceptedRisks?.userBaseAcceptedRisks?.forEach((waiver) => {
        if (
          (props.includeOwnOrgWaivers &&
            waiver.organisationId === currentOrg?.id) ||
          (props.includeOtherOrgRiskWaivers &&
            waiver.organisationId !== currentOrg?.id)
        ) {
          waivers.push(waiver);
        }
      });
    }

    return waivers;
  }, [
    props.includeOtherOrgRiskWaivers,
    props.includeOwnOrgWaivers,
    props.acceptedRisks?.userBaseAcceptedRisks,
    props.waiverType,
  ]);

  const breachRiskAcceptedRiskCount = filteredBreachRiskWaivers.length;
  const userBaseAcceptedRisksCount = filteredUserRiskWaivers.length;
  const vendorRiskWaiversCount = filteredVendorRiskWaivers.length;

  const initialSelection = getInitialSelectionState(
    props.location.search,
    breachRiskAcceptedRiskCount,
    vendorRiskWaiversCount,
    userBaseAcceptedRisksCount,
    props.waiverType
  );

  const acceptedRisksCountLabel =
    breachRiskAcceptedRiskCount !== undefined
      ? ` (${breachRiskAcceptedRiskCount})`
      : "";
  const userBaseAcceptedRisksCountLabel =
    userBaseAcceptedRisksCount !== undefined
      ? ` (${userBaseAcceptedRisksCount})`
      : "";
  const vendorWaiversCountLabel =
    vendorRiskWaiversCount !== undefined ? ` (${vendorRiskWaiversCount})` : "";

  return (
    <div id="risk_waiver_approvals_view">
      <PageHeader
        history={props.history}
        title="Risk Waiver Approvals"
        backAction={
          props.location.state?.backContext?.goBack
            ? props.history.goBack
            : props.location.state && props.location.state.backContext
              ? () => {
                  props.history.push(
                    props.location.state.backContext?.backTo || ""
                  );
                }
              : undefined
        }
        backText={
          props.location.state && props.location.state.backContext
            ? props.location.state.backContext.backToText
            : undefined
        }
        infoSectionPageKey="infoSection_vendorPortalRiskWaivers"
        infoSection={
          <>
            <p>
              Risk waivers let you waive risks identified by automated scanning,
              security questionnaires, and additional evidence for you and your
              vendors.
            </p>
            <p>
              You were nominated as the approver for the risk waivers below.
              Click on a risk waiver for more information then use the Actions
              button to approve or reject it. Approved risk waivers will impact
              the security rating that is displayed to users of your UpGuard
              account for your company or its vendors.
            </p>
          </>
        }
      />
      <PivotTabs selectedTab={initialSelection.tab} hideSingleTabButton={true}>
        {(!props.waiverType || props.waiverType === "risk-waivers") && (
          <PivotTab
            headerText={"Company" + acceptedRisksCountLabel}
            id={"risk-waivers"}
            disabled={breachRiskAcceptedRiskCount === 0}
          >
            <RiskWaiversTable
              acceptedRisks={filteredBreachRiskWaivers}
              users={props.acceptedRisks ? props.acceptedRisks.users : []}
              loading={!props.acceptedRisks}
              currentUser={props.currentUser}
              userHasWriteAccess={false}
              initiallySelectedId={initialSelection.riskWaiverId}
              hideJustification
              canUsePublicWaivers={true}
            />
          </PivotTab>
        )}
        {(!props.waiverType || props.waiverType === "vendor-risk-waivers") && (
          <PivotTab
            headerText={"Vendors" + vendorWaiversCountLabel}
            id={"vendor-risk-waivers"}
            disabled={
              vendorRiskWaiversCount === 0 &&
              !initialSelection.vendorRiskWaiverId
            }
          >
            <VendorRiskWaiversCard
              headerTextOverride={`Risk Waivers for ${vendorWords.pluralTitleCase}`}
              hideSearch
              dispatch={props.dispatch}
              history={props.history}
              riskWaivers={mergeWaiverArrays(filteredVendorRiskWaivers)}
              showVendorName={true}
              allowApprove={true}
              allowCreate={false}
              initiallySelectedId={initialSelection.vendorRiskWaiverId}
              assuranceType={props.assuranceType}
              waiverType={WaiverType.RiskWaiver}
            />
          </PivotTab>
        )}
        {(props.waiverType === "userbase-risk-waivers" ||
          (!!userBaseAcceptedRisksCount && !props.waiverType)) && (
          <PivotTab
            headerText={"UserRisk" + userBaseAcceptedRisksCountLabel}
            id={"userbase-risk-waivers"}
            disabled={userBaseAcceptedRisksCount === 0}
          >
            <RiskWaiversTable
              acceptedRisks={filteredUserRiskWaivers}
              users={props.acceptedRisks ? props.acceptedRisks.users : []}
              loading={!props.acceptedRisks}
              currentUser={props.currentUser}
              userHasWriteAccess={false}
              initiallySelectedId={initialSelection.userBaseRiskWaiverId}
              hideJustification
              canUsePublicWaivers={false}
            />
          </PivotTab>
        )}
      </PivotTabs>
    </div>
  );
};

RiskWaiverApprovalsList.defaultProps = {
  acceptedRisks: null,
  assuranceType: AssuranceType.None,
};

export default appConnect<
  IRiskWaiverApprovalsListConnectedProps,
  never,
  IRiskWaiverApprovalsListOwnProps
>((state) => {
  return {
    acceptedRisks: state.vendorPortal.acceptedRisks,
    vendorRiskWaivers: state.vendorPortal.vendorRiskWaivers,
    assuranceType: state.common.userData.assuranceType,
    currentUser: state.common.userData,
  };
})(RiskWaiverApprovalsList);

const getInitialSelectionState = (
  search: string,
  acceptedRisksCount: number | undefined,
  vendorWaiversCount: number | undefined,
  userBaseAcceptedRisksCount: number | undefined,
  waiverTypeTab?: RiskWaiverApprovalsType
): RiskWaiverApprovalsListInitialSelectionState => {
  const riskWaiverId = getSearchParamAsNumber(search, "riskWaiverId");
  const vendorRiskWaiverId = getSearchParamAsNumber(
    search,
    "vendorRiskWaiverId"
  );
  const userBaseRiskWaiverId = getSearchParamAsNumber(
    search,
    "userBaseRiskWaiverId"
  );

  let tab: string | undefined = undefined;
  if (riskWaiverId) {
    tab = "risk-waivers";
  } else if (userBaseRiskWaiverId) {
    tab = "userbase-risk-waivers";
  } else if (vendorRiskWaiverId) {
    tab = "vendor-risk-waivers";
  } else if (acceptedRisksCount === 0 && (vendorWaiversCount ?? 0) > 0) {
    tab = "vendor-risk-waivers";
  } else if (waiverTypeTab) {
    // Use waiverIDs in preference to specific tab setting
    tab = waiverTypeTab;
  } else if (
    acceptedRisksCount === 0 &&
    (vendorWaiversCount ?? 0) === 0 &&
    (userBaseAcceptedRisksCount ?? 0) > 0
  ) {
    tab = "userbase-risk-waivers";
  }

  return {
    riskWaiverId,
    vendorRiskWaiverId,
    userBaseRiskWaiverId,
    tab,
  };
};
