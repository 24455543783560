import classnames from "classnames";
import "../../_common/style/components/CalendarDay.scss";

interface ICalendarDayProps {
  dayOfMonth: number;
  className?: string;
}

const CalendarDay = (props: ICalendarDayProps) => {
  const { dayOfMonth, className } = props;
  const classes = classnames("calendar-day-container", className);

  return (
    <div className={classes}>
      <div className="container-header"></div>
      <div className="container-content">{dayOfMonth}</div>
    </div>
  );
};

export default CalendarDay;
