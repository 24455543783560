import { DefaultAction, DefaultThunkDispatch } from "../../_common/types/redux";
import { FetchCyberRiskUrl } from "../../_common/api";
import { LogError } from "../../_common/helpers";
import { trackEvent } from "../../_common/tracking";
import {
  IWatchedVendorSearchResult,
  IWatchlistPaging,
} from "../../_common/types/vendor";
import { WatchlistFilter } from "../components/filter/types";
import { DefaultRootState } from "react-redux";
import {
  fetchVendorSummaryAndCloudscans,
  fetchVendorWatchStatus,
  getVendorData,
  refreshFilteredCustomerData,
  setVendorData,
} from "./cyberRiskActions";
import { MergeTag } from "../../_common/types/orgDefaultTexts";
import { getTPMVSession } from "../../_common/reducers/commonActions";

export interface VendorSearchMini {
  name: string;
  id: number;
}

/*
Fetches all vendor names and ids using the current set of filters
*/
export const fetchAllFilteredVendors =
  (namePrefix?: string): DefaultAction<VendorSearchMini[]> =>
  async (dispatch, getState) => {
    const { filters } = getState().cyberRisk.customerData;

    let json: {
      status: string;
      vendors: VendorSearchMini[];
    };

    try {
      json = await FetchCyberRiskUrl(
        "watchlist/all/v1",
        {
          vendor_label_ids: filters.vendorLabelIds,
          vendor_label_ids_match_all: filters.vendorLabelIdsMatchAll,
          vendor_label_ids_do_not_match: filters.vendorLabelIdsDoNotMatch,
          include_unlabeled: filters.includeUnlabeled,
          min_score: filters.minScore,
          max_score: filters.maxScore,
          vendor_tiers: filters.vendorTiers,
          vendor_assessment_classifications:
            filters.vendorAssessmentClassifications,
          vendor_assessment_authors: filters.vendorAssessmentAuthors,
          vendor_assessment_authors_not:
            filters.vendorAssessmentAuthorDoNotMatch,
          vendor_reassessment_start: filters.vendorReassessmentStartDate,
          vendor_reassessment_end: filters.vendorReassessmentEndDate,
          vendor_reassessment_before: filters.vendorReassessmentDateBefore,
          vendor_reassessment_between: filters.vendorReassessmentDateBetween,
          portfolio_ids: filters.portfolioIds,
          // since the attribute filters are complex we serialise them to json
          attributes: JSON.stringify(filters.selectedAttributes),
          name_prefix: namePrefix,
          survey_type_ids: filters.vendorSurveyTypes,
          evidence_type_ids: filters.vendorEvidenceTypes,
          fourth_party_product_uuids: filters.fourthPartyProductUUIDs,
        },
        null,
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error getting all vendor ids", e);
      throw e;
    }

    return json.vendors;
  };

export interface WatchedVendorResult {
  status: string;
  vendors: IWatchedVendorSearchResult[];
  paging: IWatchlistPaging;
  filters: WatchlistFilter;
}

export const fetchVendorsNoCache = (
  paging: Partial<IWatchlistPaging>,
  filters: Partial<WatchlistFilter> | undefined = undefined
): DefaultAction<WatchedVendorResult | null> => {
  return async (dispatch, getState) => {
    const { filters: currentFilters } = getState().cyberRisk.customerData;
    let json: WatchedVendorResult;

    const newFilters = filters || currentFilters;
    try {
      json = await FetchCyberRiskUrl(
        "watchlist/v1/",
        {
          num_points: 30,
          page_num: paging.pageNum,
          page_size: paging.pageSize,
          sort_by: paging.sortBy,
          sort_dir: paging.sortDir,
          vendor_label_ids: newFilters.vendorLabelIds,
          vendor_label_ids_match_all: newFilters.vendorLabelIdsMatchAll,
          vendor_label_ids_do_not_match: newFilters.vendorLabelIdsDoNotMatch,
          include_unlabeled: newFilters.includeUnlabeled,
          min_score: newFilters.minScore,
          max_score: newFilters.maxScore,
          name_prefix: newFilters.namePrefix,
          vendor_tiers: newFilters.vendorTiers,
          vendor_assessment_classifications:
            newFilters.vendorAssessmentClassifications,
          vendor_assessment_authors: newFilters.vendorAssessmentAuthors,
          vendor_assessment_authors_not:
            newFilters.vendorAssessmentAuthorDoNotMatch,
          vendor_reassessment_start: newFilters.vendorReassessmentStartDate,
          vendor_reassessment_end: newFilters.vendorReassessmentEndDate,
          vendor_reassessment_before: newFilters.vendorReassessmentDateBefore,
          vendor_reassessment_between: newFilters.vendorReassessmentDateBetween,
          portfolio_ids: newFilters.portfolioIds,
          assessed_vendors_only: newFilters.assessedVendorsOnly,
          // since the attribute filters are complex we serialise them to json
          attributes: JSON.stringify(newFilters.selectedAttributes),
          survey_type_ids: newFilters.vendorSurveyTypes,
          evidence_type_ids: newFilters.vendorEvidenceTypes,
          fourth_party_product_uuids: newFilters.fourthPartyProductUUIDs,
        },
        null,
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error fetching customer vendors data", e);

      return null;
    }

    return json;
  };
};

export interface checkVendorDomainsRespV1 {
  vendorId: number;
  domainChecks: { [domain: string]: boolean };
}

export const checkVendorDomains = (
  vendorId: number | undefined,
  domains: string[]
): DefaultAction<checkVendorDomainsRespV1> => {
  return async (dispatch, getState) => {
    let json: checkVendorDomainsRespV1;

    try {
      json = await FetchCyberRiskUrl(
        "vendor/check_domains/v1/",
        {},
        {
          method: "PUT",
          body: JSON.stringify({
            isForUserOrg: vendorId === undefined,
            vendorId,
            domains,
          }),
        },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error checking vendor domains", e);

      throw e;
    }

    return json;
  };
};

// refreshVendorListsAfterChange refreshes vendor metadata in various places in the app
// after a change, such as updating tiers or portfolios.
export const refreshVendorListsAfterChange =
  (vendorIds: number[]) =>
  async (dispatch: DefaultThunkDispatch, getState: () => DefaultRootState) => {
    // If needed, re-get per-vendor data
    const dispatchesToWaitFor: Promise<void>[] = [];
    vendorIds.forEach((vId) => {
      if (getState().cyberRisk.vendors[vId]?.summary?.result) {
        dispatch(fetchVendorSummaryAndCloudscans(vId, true));
      }
      if (getState().cyberRisk.vendors[vId]?.watching?.result) {
        dispatchesToWaitFor.push(dispatch(fetchVendorWatchStatus(vId, true)));
      }
    });

    // Refresh any filtered customer data for vendor risk screens
    dispatch(
      refreshFilteredCustomerData(
        getState().cyberRisk.customerData.filters,
        false,
        true
      )
    );

    // Only wait for watch status to be fetched if there is one vendorId in the request.
    if (vendorIds.length === 1) {
      return Promise.all(dispatchesToWaitFor);
    }

    return;
  };

interface ApplyVendorNoteRequestV1 {
  note: string | null;
  vendorId: number;
}

export const applyVendorNote = (
  apply: ApplyVendorNoteRequestV1,
  refreshVendorLists = true
) => {
  return async (
    dispatch: DefaultThunkDispatch,
    getState: () => DefaultRootState
  ) => {
    try {
      await FetchCyberRiskUrl(
        "vendornotes/v1/",
        {},
        { method: "PUT", body: JSON.stringify(apply) },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("error applying vendor notes", e);

      throw e;
    }

    trackEvent("VendorRisk_VendorNotesApplied");

    if (refreshVendorLists) {
      await dispatch(refreshVendorListsAfterChange([apply.vendorId]));
    }
  };
};

export const fetchVendorMergeTags =
  (
    vendor_id: number,
    baseOnDraftAssessment_id: number,
    isSubsidiary = false,
    force = false
  ): DefaultAction =>
  async (dispatch, getState) => {
    const tpvm = getTPMVSession(getState);
    const vendor = getVendorData(getState, vendor_id, isSubsidiary, tpvm);

    if (!force && vendor?.vendorMergeTags) {
      return;
    }

    let json: MergeTag[];
    try {
      json = await FetchCyberRiskUrl(
        "vendor/merge_tags/v1",
        {
          vendor_id: vendor_id,
          base_on_draft_assessment_id: baseOnDraftAssessment_id,
        },
        {},
        dispatch,
        getState
      );
    } catch (e) {
      LogError("error getting vendor merge tag values", e);
      throw e;
    }

    dispatch(
      setVendorData(vendor_id, { vendorMergeTags: json }, isSubsidiary, tpvm)
    );
  };
