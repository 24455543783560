import { FC } from "react";

import "../style/components/CircleImg.scss";
import classnames from "classnames";

export const getFlagImgUrl = (countryCode: string) => {
  if (countryCode && countryCode.length) {
    return `https://cdn.cyber-risk.upguard.com/country-flags/svg/${countryCode.toLowerCase()}.svg`; // eslint-disable-line
  }
  return "";
};

interface ICircleImgProps {
  imgSrc?: string;
  className?: string;
}

export const CircleImg: FC<ICircleImgProps> = ({ imgSrc, className }) => {
  if (!imgSrc) return null;
  const style = { backgroundImage: `url("${imgSrc}")` };
  return (
    <div className={classnames("circle-image", className)} style={style} />
  );
};
