import { IPAddressDetails } from "../../_common/types/ipAddresses";
import { DefaultThunkDispatch } from "../../_common/types/redux";
import { FetchCyberRiskUrl } from "../../_common/api";
import { addDefaultUnknownErrorAlert } from "../../_common/reducers/messageAlerts.actions";
import { DefaultRootState } from "react-redux";

export const SET_VENDOR_PORTAL_REMEDIATION_REQUEST_IP_DETAILS =
  "SET_VENDOR_PORTAL_REMEDIATION_REQUEST_IP_DETAILS";
export const setVendorPortalIPDetails = (ipDetails: IPAddressDetails) => {
  return {
    type: SET_VENDOR_PORTAL_REMEDIATION_REQUEST_IP_DETAILS,
    ipDetails,
  };
};

interface fetchIPAddressDetailsResponse {
  status: string;
  result: IPAddressDetails;
}

// Fetch details for an IP address to be shown in the vendor portal for a remediation request
// NOTE: Does not retrieve any org-specific data about the IP address
export const fetchIpAddressDetailsForVendorPortalRemediationRequest = (
  ip: string,
  vendorPortalRemediationRequestId: number
) => {
  return async (
    dispatch: DefaultThunkDispatch,
    getState: () => DefaultRootState
  ): Promise<void> => {
    const params = {
      ip: ip,
      remediation_request_id: vendorPortalRemediationRequestId,
    };

    let json: fetchIPAddressDetailsResponse;

    try {
      json = await FetchCyberRiskUrl(
        "vendor/ip_address/v1/",
        params,
        null,
        dispatch,
        getState
      );
    } catch (e) {
      dispatch(addDefaultUnknownErrorAlert("Error fetching IP address"));
      console.error(e);
      throw e;
    }

    dispatch(setVendorPortalIPDetails(json.result));

    return;
  };
};
