import { SurveyDetail } from "../../../_common/types/vendor";
import PillLabel from "../PillLabel";
import { LabelColor } from "../../../_common/types/label";
import ColorCheckbox from "../ColorCheckbox";
import classNames from "classnames";
import {
  DateStringToUnixDateWithoutTimezone,
  LocalisedDayMonthFormat,
} from "../../../_common/helpers";
import CompensatingControlInfo from "../questionnaires/CompensatingControlInfo";
import "../../style/components/risk_waivers/QuestionnaireSelection.scss";

interface IQuestionnaireSelectionProps {
  viewMode?: boolean;
  readonly?: boolean;
  questionnaires: SurveyDetail[];
  selectedQuestionnaireIds: number[];
  selectedPublicQuestionnaireIds: number[];
  onQuestionnairesSelected?: (ids: number[], publicSurvey: boolean) => void;

  showIfRemediation?: boolean;

  // Only needed if we want to show a link to the questionnaire as link to open in new window
  showLinks?: boolean;
  isVendorManagementAnalyst?: boolean;
  managedOrgId?: number;
  datastoreVendorId?: number;

  smallBox?: boolean;
  // disable the selection of items that are already under remediation and shows them as selected
  lockRemediation?: boolean;

  // are we dealing with surveys for a passed check?
  passed?: boolean;

  showWaived?: boolean;
}

const QuestionnaireSelection = (
  props: IQuestionnaireSelectionProps
): JSX.Element => {
  const surveySelected = (surveyId: number, publicSurvey: boolean) => {
    const currentlySelectedQuestionnaires = publicSurvey
      ? props.selectedPublicQuestionnaireIds
      : props.selectedQuestionnaireIds;
    let newSelectedQuestionnaires: number[] = [];
    if (currentlySelectedQuestionnaires.indexOf(surveyId) !== -1) {
      newSelectedQuestionnaires = currentlySelectedQuestionnaires.filter(
        (s) => s !== surveyId
      );
    } else {
      newSelectedQuestionnaires = [
        ...currentlySelectedQuestionnaires,
        surveyId,
      ];
    }

    if (props.onQuestionnairesSelected) {
      props.onQuestionnairesSelected(newSelectedQuestionnaires, publicSurvey);
    }
  };

  const privateQuestionnairesToDisplay = props.questionnaires
    .filter((s) => !s.publicSurvey && (props.showWaived || !s.isWaived))
    .filter(
      (s) =>
        !props.viewMode ||
        props.selectedQuestionnaireIds.indexOf(s.surveyId) !== -1
    );
  const publicQuestionnairesToDisplay = props.questionnaires
    .filter((s) => s.publicSurvey && (props.showWaived || !s.isWaived))
    .filter(
      (s) =>
        !props.viewMode ||
        props.selectedPublicQuestionnaireIds.indexOf(s.surveyId) !== -1
    );

  const questionnairesToDisplay = [
    ...privateQuestionnairesToDisplay,
    ...publicQuestionnairesToDisplay,
  ];

  const questionnaireDisplays = questionnairesToDisplay.map((s) => {
    const isSelected = s.publicSurvey
      ? props.selectedPublicQuestionnaireIds.indexOf(s.surveyId) !== -1
      : props.selectedQuestionnaireIds.indexOf(s.surveyId) !== -1;

    const questionnaireTitle = props.viewMode ? (
      <div className={"questionnaire-title"}>
        {s.surveyName}
        {props.showIfRemediation && s.inRemediation ? (
          <PillLabel color={LabelColor.Blue}>In Remediation</PillLabel>
        ) : (
          ""
        )}
        {s.publicSurvey && (
          <PillLabel
            color={LabelColor.Orange}
            className={"shared-questionnaire"}
          >
            Shared Questionnaire
          </PillLabel>
        )}
      </div>
    ) : (
      <ColorCheckbox
        color="blue"
        label={
          <>
            {s.surveyName}
            {s.publicSurvey && (
              <PillLabel
                color={LabelColor.Orange}
                className={"shared-questionnaire"}
              >
                Shared Questionnaire
              </PillLabel>
            )}
          </>
        }
        checked={isSelected || (props.lockRemediation && s.inRemediation)}
        onClick={() => surveySelected(s.surveyId, s.publicSurvey)}
        disabled={props.readonly || (props.lockRemediation && s.inRemediation)}
      />
    );

    const classes = classNames("questionnaire-item", {
      "is-editing": !props.viewMode,
    });

    const sentOrPublishedVerb = s.publicSurvey ? "Published" : "Sent";
    const displayDateStr = s.publicSurvey ? s.sharedAt : s.surveyLastSent;

    return (
      <div key={s.surveyId} className={classes}>
        <div className={"questionnaire-item-header"}>
          {questionnaireTitle}
          {props.showLinks && !!props.datastoreVendorId && (
            <a
              className={"questionnaire-link"}
              target={"_blank"}
              rel={"noreferrer"}
              href={getQuestionnaireLink(
                s,
                props.isVendorManagementAnalyst,
                props.datastoreVendorId,
                props.managedOrgId
              )}
            >
              View Questionnaire
            </a>
          )}
        </div>
        <div className={"sent-by-text"}>
          {`${sentOrPublishedVerb} ${
            s.surveyLastSentBy ? `by ${s.surveyLastSentBy}` : ""
          } on ${LocalisedDayMonthFormat(
            DateStringToUnixDateWithoutTimezone(displayDateStr),
            true
          )}`}
        </div>
        {!props.passed && (
          <div className={"compensating-control-info-container"}>
            <div className={"compensating-control-info-title"}>
              Compensating Control Info
            </div>
            <CompensatingControlInfo risk={s} />
          </div>
        )}
      </div>
    );
  });

  return <div className="questionnaire-selection">{questionnaireDisplays}</div>;
};

QuestionnaireSelection.defaultProps = {
  viewMode: false,
  readonly: false,
  onQuestionnairesSelected: undefined,
  showLinks: false,
  isVendorManagementAnalyst: false,
  managedOrgId: undefined,
  datastoreVendorId: undefined,
  showIfRemediation: false,
  smallBox: true,
  lockRemediation: false,
};

const getQuestionnaireLink = (
  questionnaire: SurveyDetail,
  isVendorManagementAnalyst: boolean | undefined,
  datastoreVendorId: number | undefined,
  managedOrgId?: number
) => {
  const routePrefix = isVendorManagementAnalyst
    ? `/analysts/tpvm/${managedOrgId}/${datastoreVendorId}/`
    : questionnaire.publicSurvey
      ? `/vendor/${datastoreVendorId}/sharedassessment/`
      : `/vendor/${datastoreVendorId}/`;

  return `${routePrefix}/surveys/${questionnaire.surveyId}`;
};

export default QuestionnaireSelection;
