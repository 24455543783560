import { FC, useState } from "react";
import "./UserRiskRecipients.scss";
import Icon from "../../../_common/components/core/Icon";

const defaultNumRecipientsShown = 3;

interface IUserRiskRecipientsProps {
  recipients: { name: string; emailAddress: string }[];
}

const UserRiskRecipients: FC<IUserRiskRecipientsProps> = ({ recipients }) => {
  const [showMore, setShowMore] = useState(false);

  const recipientsShown = showMore
    ? recipients
    : recipients.slice(0, defaultNumRecipientsShown);

  return (
    <div className={"userrisk-recipients-list"}>
      {recipientsShown.map((recipient) => (
        <div key={recipient.emailAddress} className={"recipient"}>
          <div className={"recipient-name"}>{recipient.name}</div>
          <div className={"recipient-email"}>{recipient.emailAddress}</div>
        </div>
      ))}
      {recipients.length > defaultNumRecipientsShown && (
        <div className={"show-toggle"} onClick={() => setShowMore(!showMore)}>
          <div>{`Show ${showMore ? "less" : "more"}`}</div>
          <Icon name="chevron" direction={showMore ? 0 : 180} />
        </div>
      )}
    </div>
  );
};

export default UserRiskRecipients;
