import { IAnalystPortalState } from "../../_common/types/redux";
import { DefaultManagedVendorFilterV2 } from "../components/filter/TprmsFilter.helpers";

export const initialState: IAnalystPortalState = {
  customers: { loading: true },
  managedServiceCustomerNames: [],
  searchResults: {},
  searchResultCounts: {},
  highlightedSearchResults: {},
  findings: {},
  scratchRequestGroups: {},
  scratchSearchResults: {},
  scratchSearchResultCounts: {},
  scratchHighlightedSearchResults: {},
  scratchFindings: {},
  domainsWithFindings: { loading: true, error: null, domains: [] },
  domainFindings: {},
  vendorSearch: {
    query: "",
    loading: false,
    results: {},
  },
  currentManagedVendorRequest: {},
  managedServiceRequests: {
    requestList: [],
    loading: false,
    requests: {},
    filter: DefaultManagedVendorFilterV2,
  },
  licenses: {},
  allLicenses: {
    loading: false,
    licenses: [],
  },
};
