import { DefaultThunkDispatch } from "../../_common/types/redux";
import { DefaultRootState } from "react-redux";
import { FetchCyberRiskUrl } from "../../_common/api";
import { IUserMini, IUserMiniMap } from "../../_common/types/user";
import { HistoryItem } from "../../_common/types/emailExposures";

export const SET_ASSIGNEE_FOR_EMAIL_EXPOSURES_BREACH =
  "SET_ASSIGNEE_FOR_EMAIL_EXPOSURES_BREACH";
const setAssigneeForEmailExposuresBreachAction = (
  breachId: number,
  assigneeUser: IUserMini | undefined
) => ({
  type: SET_ASSIGNEE_FOR_EMAIL_EXPOSURES_BREACH,
  breachId,
  assigneeUser,
});

export const SET_EXPOSURES_HISTORY = "SET_EXPOSURES_HISTORY";
const setExposuresHistoryAction = (
  breachId: number,
  history: EmailExposureHistory
) => ({
  type: SET_EXPOSURES_HISTORY,
  breachId,
  history,
});

export const INCREMENT_COMMENT_COUNT_ON_BREACH =
  "INCREMENT_COMMENT_COUNT_ON_BREACH";
const incrementCommentCountOnBreachAction = (breachId: number) => ({
  type: INCREMENT_COMMENT_COUNT_ON_BREACH,
  breachId,
});

export const CLEAR_EXPOSURES_HISTORY = "CLEAR_EXPOSURES_HISTORY";
export const clearExposuresHistoryAction = () => ({
  type: CLEAR_EXPOSURES_HISTORY,
});

export const setAssigneeForEmailExposuresBreach =
  (breachId: number, assigneeUser: IUserMini | undefined) =>
  async (dispatch: DefaultThunkDispatch, getState: () => DefaultRootState) => {
    const opts: {
      breach_id: number;
      assignee_user_id?: number;
    } = {
      breach_id: breachId,
    };

    if (assigneeUser) {
      opts.assignee_user_id = assigneeUser.id;
    }

    try {
      await FetchCyberRiskUrl(
        "emailexposures/accounts/assignee/v1",
        opts,
        { method: "PUT" },
        dispatch,
        getState
      );

      dispatch(
        setAssigneeForEmailExposuresBreachAction(breachId, assigneeUser)
      );

      dispatch(clearExposuresHistoryAction());
    } catch (e) {
      console.error(e);
      throw e;
    }
  };

export interface EmailExposureHistory {
  historyItems: HistoryItem[];
  sharedUsers: IUserMiniMap;
  usersIDsToBeNotified: number[];
}

export const fetchEmailExposureHistory =
  (breachId: number, force = false) =>
  async (dispatch: DefaultThunkDispatch, getState: () => DefaultRootState) => {
    const existingHistory =
      getState().cyberRisk.customerData.exposures.breachHistory[breachId];
    if (existingHistory && !force) {
      return existingHistory as EmailExposureHistory;
    }

    try {
      const resp = await FetchCyberRiskUrl<EmailExposureHistory>(
        "emailexposures/accounts/history/v1",
        { breach_id: breachId },
        { method: "GET" },
        dispatch,
        getState
      );

      dispatch(setExposuresHistoryAction(breachId, resp));

      return resp;
    } catch (e) {
      console.error(e);
      throw e;
    }
  };

export const addCommentToEmailExposureBreach =
  (breachId: number, comment: string) =>
  async (dispatch: DefaultThunkDispatch, getState: () => DefaultRootState) => {
    try {
      await FetchCyberRiskUrl<EmailExposureHistory>(
        "emailexposures/accounts/comment/v1",
        { breach_id: breachId, comment },
        { method: "POST" },
        dispatch,
        getState
      );

      // Refresh our history
      await dispatch(fetchEmailExposureHistory(breachId, true));

      // And make sure this is reflected in the comments count on the breach
      dispatch(incrementCommentCountOnBreachAction(breachId));
    } catch (e) {
      console.error(e);
      throw e;
    }
  };
