import PropTypes from "prop-types";

import classnames from "classnames";

import ToggleSwitch from "../../_common/components/core/ToggleSwitch";
import "../style/components/ToggleWithLabel.scss";

/**
 * <ToggleWithLabel />
 * Simple stateless wrapper around ToggleSwitch to facilitate a label. Takes a callback function.
 * Selected/disabled state must be handled outside of this component.
 */
const ToggleWithLabel = ({
  name,
  className,
  selected,
  disabled,
  onClick,
  label,
}) => {
  const classes = classnames("toggle-with-label-outer", className, {
    greyed: disabled,
  });

  return (
    <div className={classes} onClick={!disabled ? onClick : undefined}>
      <div className="toggle-with-label">
        <ToggleSwitch
          name={name}
          selected={selected}
          disabled={disabled}
          onClick={!disabled ? onClick : undefined}
          stopPropagation
        />
        <div className={`toggle-label ${disabled ? "disabled" : ""}`}>
          {label}
        </div>
      </div>
    </div>
  );
};

ToggleWithLabel.propTypes = {
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  selected: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  label: PropTypes.element,
};

ToggleWithLabel.defaultProps = {
  className: "",
  selected: false,
  disabled: false,
  label: "",
};

export default ToggleWithLabel;
