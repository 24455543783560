import { useEffect, useState } from "react";
import { SlidePanelSection } from "./SlidePanelSection";
import {
  fetchVendorTiers,
  VendorTier,
} from "../../reducers/vendorTiers.actions";
import LoadingIcon from "../../../_common/components/core/LoadingIcon";
import VendorTierChoiceSet from "../vendor_tiers/VendorTierChoiceSet";
import { DefaultThunkDispatchProp } from "../../../_common/types/redux";

import { getVendorWords } from "../../../_common/constants";
import { AssuranceType } from "../../../_common/types/organisations";
import { appConnect } from "../../../_common/types/reduxHooks";

interface VendorTierFilterOwnProps {
  assuranceType: AssuranceType;
  selectedTiers: number[];
  onChange: (tiers: number[]) => void;
  title?: string;
  startExpanded?: boolean;
}

interface VendorTierFilterConnectedProps {
  tiers: VendorTier[];
}

type VendorTierFilterProps = VendorTierFilterOwnProps &
  VendorTierFilterConnectedProps &
  DefaultThunkDispatchProp;

const VendorTierFilter = (props: VendorTierFilterProps) => {
  const [expanded, setExpanded] = useState(
    props.startExpanded || props.selectedTiers.length > 0
  );

  // Initial load
  useEffect(() => {
    if (props.tiers.length === 0) {
      props.dispatch(fetchVendorTiers()).catch(() => {
        // Handled elsewhere
      });
    }
  }, []);

  const vendorWords = getVendorWords(props.assuranceType);

  return (
    <SlidePanelSection
      title={
        props.title ? props.title : `Filter by ${vendorWords.singular} tier`
      }
      toggleExpand={() => setExpanded(!expanded)}
      expanded={expanded}
    >
      {props.tiers.length === 0 && (
        <div className={"loading"}>
          <LoadingIcon size={32} />
        </div>
      )}
      {props.tiers.length > 0 && (
        <VendorTierChoiceSet
          multiSelect
          tiers={props.tiers}
          selectedTeirs={props.selectedTiers}
          onSelectionChange={(selected) => props.onChange(selected)}
        />
      )}
    </SlidePanelSection>
  );
};

export default appConnect<
  VendorTierFilterConnectedProps,
  never,
  VendorTierFilterOwnProps
>((state) => {
  return {
    tiers: state.cyberRisk.vendorTiers ?? [],
  };
})(VendorTierFilter);
