// When making changes to this map please ensure to update the names and the colors in funcs.go
import WebsiteSecIcon from "../vendorrisk/images/report-icons/website-sec.svg";
import { LabelColor } from "./types/label";
import NetworkSecIcon from "../vendorrisk/images/report-icons/network-sec.svg";
import BrandProtectionIcon from "../vendorrisk/images/report-icons/brand-protection.svg";
import EmailSecIcon from "../vendorrisk/images/report-icons/email-sec.svg";
import PhishingIcon from "../vendorrisk/images/report-icons/phishing.svg";
import VulnsIcon from "../vendorrisk/images/report-icons/vulns.svg";
import IdentityExposuresIcon from "../vendorrisk/images/report-icons/identity-exposures.svg";
import DataLeaksIcon from "../vendorrisk/images/report-icons/data-leaks.svg";
import QuestionnaireRisksIcon from "../vendorrisk/images/report-icons/questionnaire-risks-icon.svg";
import AdditionalEvidenceRisksIcon from "../vendorrisk/images/report-icons/additional-evidence.svg";

export const factCategories = {
  WebsiteSec: "website_sec",
  EmailSec: "email_sec",
  NetworkSec: "network_sec",
  Phishing: "phishing",
  BrandProtect: "brand_protect",
  Vulns: "vulns",
  EmailExposures: "emailexposures",
  DataLeaks: "dataleaks",
  QuestionnaireRisks: "questionnaire_risks",
  AdditionalEvidenceRisks: "additional_evidence",
  SaaSRisks: "saas",
  AppguardPackageVulns: "appguard_package_vuln",
  AppguardRepoConfig: "appguard_repo_config",
  Website: "website_sec_v2",
  Email: "email_sec_v2",
  Network: "network_sec_v2",
  BrandReputation: "brand_protect_v2",
  IPDomainReputation: "ip_domain_reputation",
  Encryption: "encryption",
  DNS: "dns",
  DataLeakage: "data_leakage",
  VulnerabilityManagement: "patch_management",
  AttackSurface: "attack_surface",
};
// oldCategories are categories that may be displayed
// until an org/vendor is rescored with the new category algorithm.
export const oldCategories = [
  factCategories.WebsiteSec,
  factCategories.NetworkSec,
  factCategories.BrandProtect,
  factCategories.EmailSec,
  factCategories.Phishing,
];
// defaultShownCategories are categories introduced
// in the v5 scoring algorithm.
export const defaultShownCategories = [
  factCategories.Website,
  factCategories.IPDomainReputation,
  factCategories.Encryption,
  factCategories.VulnerabilityManagement,
  factCategories.AttackSurface,
  factCategories.Network,
  factCategories.Email,
  factCategories.DataLeakage,
  factCategories.DNS,
  factCategories.BrandReputation,
];
// taken from categories.go
export const defaultCategoryWeights = {
  [factCategories.Website]: 19,
  [factCategories.IPDomainReputation]: 19,
  [factCategories.Encryption]: 17,
  [factCategories.VulnerabilityManagement]: 13,
  [factCategories.AttackSurface]: 11,
  [factCategories.Network]: 8,
  [factCategories.Email]: 7,
  [factCategories.DataLeakage]: 3,
  [factCategories.DNS]: 2,
  [factCategories.BrandReputation]: 1,
};
// oldCategoryMeta contains the meta for categories that are no longer in use
const riskCategoryMeta = {
  [factCategories.WebsiteSec]: {
    name: "Website Security",
    icon: WebsiteSecIcon,
    showInFilter: true,
    viewAllText: "View all Website Security risks",
    explanation:
      "Website security identifies potential attack vectors like vulnerabilities, cross-site scripting, susceptibility to man-in-the-middle attacks, and other exploits. Any successful exploit can impact business, customers, and regulatory compliance.",
    helpLink:
      "https://help.upguard.com/en/articles/3765184-how-are-upguard-s-security-ratings-calculated",
    color: "#1757C2",
    pillColor: LabelColor.LightBlue,
  },
  [factCategories.NetworkSec]: {
    name: "Network Security",
    icon: NetworkSecIcon,
    showInFilter: true,
    viewAllText: "View all Network Security risks",
    explanation:
      "Network security identifies externally-facing, insecure network settings that can enable man-in-the-middle attacks, and aid in the spread of self-replicating computer worms such as WannaCry. These worms exploit known vulnerabilities in the services that run behind open ports to spread. By fixing network issues, there is reduced risk of successful exploitation and spread.",
    helpLink:
      "https://help.upguard.com/en/articles/3765184-how-are-upguard-s-security-ratings-calculated",
    color: "#5D89D4",
    pillColor: LabelColor.Green,
  },
  [factCategories.BrandProtect]: {
    name: "Brand & Reputation Risk",
    icon: BrandProtectionIcon,
    showInFilter: true,
    viewAllText: "View all Brand & Reputation risks",
    explanation:
      "Brand protection highlights situations where a domain could be hijacked, expired, or deleted at the domain name registrar or domain name registry. By fixing these issues, there is a reduced risk of domains being tampered with via social engineering and other cyber attacks.",
    helpLink:
      "https://help.upguard.com/en/articles/3765184-how-are-upguard-s-security-ratings-calculated",
    color: "#8BABE1",
    pillColor: LabelColor.Blue,
  },
  [factCategories.EmailSec]: {
    name: "Email Security",
    icon: EmailSecIcon,
    showInFilter: true,
    viewAllText: "View all Email Security risks",
    explanation:
      "Email security is an important part of enterprise risk management. Email is a popular medium for spreading malware and conducting social engineering attacks. Inadequate email security makes it easy for attackers to send malicious email on a domain’s behalf, increasing the efficacy of phishing and other business email compromise attacks.",
    helpLink:
      "https://help.upguard.com/en/articles/3765184-how-are-upguard-s-security-ratings-calculated",
    color: "#CAD9F1",
    pillColor: LabelColor.Yellow,
  },
  [factCategories.Phishing]: {
    name: "Phishing & Malware",
    icon: PhishingIcon,
    showInFilter: true,
    viewAllText: "View all Phishing & Malware risks",
    explanation:
      "Phishing & malware outlines websites that are suspected of hosting malware, unwanted software, or phishing pages. Left unchecked these pages damage your brand, infect customers, and lead to costly data breaches.",
    helpLink:
      "https://help.upguard.com/en/articles/3765184-how-are-upguard-s-security-ratings-calculated",
    color: "#E8EEF9",
    pillColor: LabelColor.Orange,
  },
  [""]: {
    // default so we don't get errors if this is missing
    name: "",
    icon: WebsiteSecIcon,
    showInFilter: false,
    viewAllText: "",
    explanation: "",
    helpLink: "",
    pillColor: LabelColor.Blue,
    color: "",
  },
};

const newRiskCategoryMeta = {
  [factCategories.Website]: {
    name: "Website",
    showInFilter: true,
    viewAllText: "View all Website risks",
    explanation:
      "Website security identifies issues with the controls that allow websites to safely be exposed to the untrusted network of the internet. Many of these controls are server security headers that help ensure a site is only serving trusted content.",
    helpLink:
      "https://help.upguard.com/en/articles/3765184-how-are-upguard-s-security-ratings-calculated",
    color: "#081F44",
    pillColor: LabelColor.Grey,
  },
  [factCategories.IPDomainReputation]: {
    name: "IP/Domain Reputation",
    showInFilter: true,
    viewAllText: "View all IP/Domain Reputation risks",
    explanation:
      "IP/Domain reputation indicates that a site is suspected of hosting malware, unwanted software, or phishing pages. These risks can indicate that a system has been compromised and may result in the site being blocklisted by other security administrators. ",
    helpLink:
      "https://help.upguard.com/en/articles/3765184-how-are-upguard-s-security-ratings-calculated",
    color: "#0C2F69",
    pillColor: LabelColor.Grey,
  },
  [factCategories.Encryption]: {
    name: "Encryption",
    showInFilter: true,
    viewAllText: "View all Encryption risks",
    explanation:
      "Encryption of data in transit is a security measure that prevents adversary-in-the-middle attacks. Data in transit over the internet should always be encrypted, and the encryption methods should be kept up to date to avoid adversarial decruption techniques.",
    helpLink:
      "https://help.upguard.com/en/articles/3765184-how-are-upguard-s-security-ratings-calculated",
    color: "#113F8D",
    pillColor: LabelColor.Grey,
  },
  [factCategories.VulnerabilityManagement]: {
    name: "Vulnerability Management",
    showInFilter: true,
    viewAllText: "View all Vulnerability Management risks",
    explanation:
      "Vulnerability management requires identifying software vulnerabilities and upgrading to secure versions on a timeline appropriate to the severity of the vulnerability. Software with actively exploited vulnerability should be updated as quickly as possible. End of life software no longer receives security updates, and should be decommissioned or updated to an actively maintained version.",
    helpLink:
      "https://help.upguard.com/en/articles/3765184-how-are-upguard-s-security-ratings-calculated",
    color: "#144CA9",
    pillColor: LabelColor.Grey,
  },
  [factCategories.AttackSurface]: {
    name: "Attack Surface",
    showInFilter: true,
    viewAllText: "View all Attack Surface risks",
    explanation:
      "Attack surface risks present attackers with additional points on an organization's externally accessible boundaries for them to target. Attack surface reduction is the practice of increasing security by removing possible targets, either by decommissioning assets or moving them behind a network layer protection like a VPN.",
    helpLink:
      "https://help.upguard.com/en/articles/3765184-how-are-upguard-s-security-ratings-calculated",
    color: "#1757C2",
    pillColor: LabelColor.Grey,
  },
  [factCategories.Network]: {
    name: "Network",
    showInFilter: true,
    viewAllText: "View all Network risks",
    explanation:
      "Network layer security means restricting access to the services running on an IP address to only those expected to be internet-facing. Services identified as network risks are those intended to run inside a trusted network or where direct access allows attackers excessive opportunity for abuse.",
    helpLink:
      "https://help.upguard.com/en/articles/3765184-how-are-upguard-s-security-ratings-calculated",
    color: "#5D89D4",
    pillColor: LabelColor.Grey,
  },
  [factCategories.Email]: {
    name: "Email",
    showInFilter: true,
    viewAllText: "View all Email risks",
    explanation:
      "Email security measures prevent malicious actors from impersonating the sender of an email message. These controls help prevent phishing campaigns against an organization, its vendors, and its customers. Email is a commonly abused method for attempting to gain access to a user's account, and effective email security can reduce this risk.",
    helpLink:
      "https://help.upguard.com/en/articles/3765184-how-are-upguard-s-security-ratings-calculated",
    color: "#8BABE1",
    pillColor: LabelColor.Grey,
  },
  [factCategories.DataLeakage]: {
    name: "Data Leakage",
    showInFilter: true,
    viewAllText: "View all Data Leakage risks",
    explanation:
      "Data leakage is the unintentional exposure of potentially sensitive information, including credentials, personal information, and business secrets. Data leakage risks can indicate lapses in best practice that could result in sensitive data being exposed in the future, as well as active exposures of sensitive data.",
    helpLink:
      "https://help.upguard.com/en/articles/3765184-how-are-upguard-s-security-ratings-calculated",
    color: "#CAD9F1",
    pillColor: LabelColor.Grey,
  },
  [factCategories.DNS]: {
    name: "DNS",
    showInFilter: true,
    viewAllText: "View all DNS risks",
    explanation:
      "DNS security entails the management of settings in DNS records to ensure that organizations remain in control of their domains and the content served on their domains. Poor DNS security can allow attackers to gain control of domains and serve malicious content on sites that appear to belong to the victim organization.",
    helpLink:
      "https://help.upguard.com/en/articles/3765184-how-are-upguard-s-security-ratings-calculated",
    color: "#E8EEF9",
    pillColor: LabelColor.Grey,
  },
  [factCategories.BrandReputation]: {
    name: "Brand Reputation",
    showInFilter: true,
    viewAllText: "View all Brand Reputation risks",
    explanation:
      "Brand reputation highlights factors that indicate a company has suffered adverse media and may have risks associated with them as a business partner.",
    helpLink:
      "https://help.upguard.com/en/articles/3765184-how-are-upguard-s-security-ratings-calculated",
    color: "#F3F7FC",
    pillColor: LabelColor.Grey,
  },
  [""]: {
    // default so we don't get errors if this is missing
    name: "",
    showInFilter: false,
    viewAllText: "",
    explanation: "",
    helpLink: "",
    pillColor: LabelColor.Grey,
    color: "",
  },
};

const commonFactCategoryMeta = {
  [factCategories.Vulns]: {
    name: "Vulnerabilities",
    icon: VulnsIcon,
    showInFilter: false,
    viewAllText: "View all Vulnerabilities risks",
    explanation: "",
    helpLink:
      "https://help.upguard.com/en/articles/3765184-how-are-upguard-s-security-ratings-calculated",
    pillColor: LabelColor.Gimblet,
  },
  [factCategories.EmailExposures]: {
    name: "Identity Exposures",
    icon: IdentityExposuresIcon,
    showInFilter: false,
    viewAllText: "View all Identity Exposures risks",
    explanation: "",
    helpLink:
      "https://help.upguard.com/en/articles/3765184-how-are-upguard-s-security-ratings-calculated",
    pillColor: LabelColor.Violet,
  },
  [factCategories.DataLeaks]: {
    name: "Data Leaks",
    icon: DataLeaksIcon,
    showInFilter: false,
    viewAllText: "View all Data Leaks risks",
    explanation: "",
    helpLink:
      "https://help.upguard.com/en/articles/3765184-how-are-upguard-s-security-ratings-calculated",
    pillColor: LabelColor.Fuchsia,
  },
  [factCategories.QuestionnaireRisks]: {
    name: "Questionnaire Risks",
    icon: QuestionnaireRisksIcon,
    showInFilter: true,
    viewAllText: "View all Questionnaire risks",
    explanation: "",
    helpLink:
      "https://help.upguard.com/en/articles/3765184-how-are-upguard-s-security-ratings-calculated",
    pillColor: LabelColor.Grey,
  },
  [factCategories.AdditionalEvidenceRisks]: {
    name: "Additional Evidence Risks",
    icon: AdditionalEvidenceRisksIcon,
    showInFilter: true,
    viewAllText: "View all Additional Evidence risks",
    explanation: "",
    helpLink:
      "https://help.upguard.com/en/articles/3765184-how-are-upguard-s-security-ratings-calculated",
    pillColor: LabelColor.Grey,
  },
  [factCategories.SaaSRisks]: {
    name: "SaaS usage",
    icon: DataLeaksIcon,
    showInFilter: false,
    viewAllText: "View all SaaS usage",
    explanation: "",
    color: "#1757C2",
    pillColor: LabelColor.MountainMeadow,
  },
  [factCategories.AppguardPackageVulns]: {
    name: "Dependency Vulnerability",
    icon: DataLeaksIcon,
    showInFilter: false,
    viewAllText: "View all dependency vulnerabilities",
    explanation: "",
    pillColor: LabelColor.MountainMeadow,
  },
  [factCategories.AppguardRepoConfig]: {
    name: "Repository Configuration",
    icon: DataLeaksIcon,
    showInFilter: false,
    viewAllText: "View all Repository Config risks",
    explanation: "",
    pillColor: LabelColor.TrendyPink,
  },
  [""]: {
    // default so we don't get errors if this is missing
    name: "",
    showInFilter: false,
    viewAllText: "",
    explanation: "",
    helpLink: "",
    pillColor: LabelColor.Blue,
    color: "",
  },
};

export const factCategoryMeta = {
  ...riskCategoryMeta,
  ...commonFactCategoryMeta,
};

export const newFactCategoryMeta = {
  ...commonFactCategoryMeta,
  ...newRiskCategoryMeta,
};
