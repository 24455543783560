import { ChangeEvent, FC } from "react";
import classnames from "classnames";
import moment from "moment";
import DatePicker from "./DatePicker";
import "../style/components/DateRangeSlider.scss";

interface IDateRangeSliderProps {
  startDate?: moment.Moment;
  endDate?: moment.Moment;
  onChange: (opts: {
    startDate?: moment.Moment;
    endDate?: moment.Moment;
  }) => void;
  minDate: moment.Moment;
  maxDate: moment.Moment;
  enforceRange?: boolean; // Ensures both startDate and endDate are set, if one is set
}

const DateRangeSlider: FC<IDateRangeSliderProps> = (props) => {
  const onChangeLower = (e: ChangeEvent<HTMLInputElement>) => {
    const newLowerVal = parseInt(e.target.value);
    const upper = props.endDate
      ? props.endDate.diff(props.minDate, "days")
      : props.maxDate.diff(props.minDate, "days");

    if (newLowerVal >= upper) {
      return;
    }

    props.onChange({
      startDate: moment(props.minDate).add(newLowerVal, "days"),
      endDate: props.enforceRange
        ? props.endDate ?? props.maxDate
        : props.endDate,
    });
  };

  const onChangeUpper = (e: ChangeEvent<HTMLInputElement>) => {
    const newUpperVal = parseInt(e.target.value);
    const lower = props.startDate
      ? props.startDate.diff(props.minDate, "days")
      : 0;
    if (newUpperVal <= lower) {
      return;
    }

    props.onChange({
      startDate: props.enforceRange
        ? props.startDate ?? props.minDate
        : props.startDate,
      endDate: moment(props.minDate).add(newUpperVal, "days"),
    });
  };

  const reset = () =>
    props.onChange({
      startDate: undefined,
      endDate: undefined,
    });

  let lowerValPct = 0;
  let upperValPct = 100;

  if (props.startDate !== undefined) {
    lowerValPct =
      (props.startDate.diff(props.minDate, "days") /
        props.maxDate.diff(props.minDate, "days")) *
      100;
  }

  if (props.endDate !== undefined) {
    upperValPct =
      (props.endDate.diff(props.minDate, "days") /
        props.maxDate.diff(props.minDate, "days")) *
      100;
  }

  return (
    <div className="range-slider-container date-range-slider">
      <div className="range-slider">
        <div className="bg-line" />
        {(props.startDate || props.endDate) && (
          <div
            className="bg-line highlight"
            style={{
              left: `${lowerValPct}%`,
              right: `${100 - upperValPct}%`,
            }}
          />
        )}
        <input
          type="range"
          value={props.startDate?.diff(props.minDate, "days") ?? 0}
          min={0}
          max={props.maxDate.diff(props.minDate, "days")}
          onInput={onChangeLower}
          onChange={onChangeLower}
        />
        <input
          type="range"
          value={
            props.endDate?.diff(props.minDate, "days") ??
            props.maxDate.diff(props.minDate, "days")
          }
          min={0}
          max={props.maxDate.diff(props.minDate, "days")}
          onInput={onChangeUpper}
          onChange={onChangeUpper}
        />
      </div>
      <div className="date-displays">
        <div
          className={classnames("date-display", {
            highlight: !!props.startDate,
          })}
        >
          <DatePicker
            min={props.minDate.format("YYYY-MM-DD")}
            max={
              props.endDate
                ? props.endDate.format("YYYY-MM-DD")
                : props.maxDate.format("YYYY-MM-DD")
            }
            placeholder={"All dates"}
            onChange={(e: any) =>
              props.onChange({
                startDate: moment(e.target.value),
                endDate: props.enforceRange
                  ? props.endDate ?? props.maxDate
                  : props.endDate,
              })
            }
            value={props.startDate?.format("YYYY-MM-DD")}
          />
        </div>
        {(props.startDate || props.endDate) && (
          <div className="reset-link" onClick={reset}>
            Reset
          </div>
        )}
        <div
          className={classnames("date-display", {
            highlight: !!props.endDate,
          })}
        >
          <DatePicker
            min={
              props.startDate
                ? props.startDate.format("YYYY-MM-DD")
                : props.minDate.format("YYYY-MM-DD")
            }
            max={props.maxDate.format("YYYY-MM-DD")}
            placeholder={"All dates"}
            onChange={(e: any) => {
              props.onChange({
                startDate: props.enforceRange
                  ? props.startDate ?? props.minDate
                  : props.startDate,
                endDate: moment(e.target.value),
              });
            }}
            value={props.endDate?.format("YYYY-MM-DD")}
          />
        </div>
      </div>
    </div>
  );
};

export default DateRangeSlider;
