import { FetchCyberRiskUrl } from "../../_common/api";
import { LogError } from "../../_common/helpers";
import {
  setSurveyData,
  setRemediationRequestData,
  conditionalRefreshActivityStreamForOrgUser,
} from "../../_common/reducers/commonActions";
import { SurveyUsageType } from "../../_common/types/surveyTypes";
import { SurveyStatus } from "../../_common/types/survey";

export const SET_CLEARALLDATA = "VENDORAPP_SET_CLEARALLDATA";
export const SET_SURVEYS_LOADING = "VENDORAPP_SET_SURVEYS_LOADING";
export const SET_SURVEYLIST = "VENDORAPP_SET_SURVEYLIST";
export const SET_REMEDIATION_REQUEST_LIST =
  "VENDORAPP_SET_REMEDIATION_REQUEST_LIST";
export const SET_ACCEPTED_RISK_LIST = "VENDORAPP_SET_ACCEPTED_RISK_LIST";

export const setVendorAppSurveysLoading = (loading) => ({
  type: SET_SURVEYS_LOADING,
  loading,
});

export const setVendorAppSurveyList = (results) => ({
  type: SET_SURVEYLIST,
  results,
});

export const setVendorAppRemediationRequestList = (
  remediationRequestList,
  remediationRequestIDsLoading
) => ({
  type: SET_REMEDIATION_REQUEST_LIST,
  remediationRequestList,
  remediationRequestIDsLoading,
});

export const setVendorAppAcceptedRiskList = (acceptedRisks) => ({
  type: SET_ACCEPTED_RISK_LIST,
  acceptedRisks,
});

export const clearVendorAppState = () => ({ type: SET_CLEARALLDATA });

export const fetchVendorAppSurveyList = (
  forceRefresh = false,
  background = false
) => {
  return async (dispatch, getState) => {
    const state = getState();
    const surveys = state.vendorPortal.vendorAppSurveys;
    let json;

    if (!forceRefresh && surveys && !surveys.loading && surveys.result) {
      return null;
    }
    if (background === false) {
      dispatch(setVendorAppSurveysLoading(true));
    }

    // make our request

    try {
      json = await FetchCyberRiskUrl(
        "vendorapp/surveylist/v1/",
        null,
        null,
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error retrieving vendorApp surveys", e);
      throw e;
    }

    if (json === false) {
      //
      // no auth token - we coudn't auth, or the session has expired. either way we need to login again.
      // so sit back, relax and let the  re-login and associated state changes take effect
      //
      await dispatch(setVendorAppSurveysLoading(false));
      return false;
    } else if (!json || json.status !== "OK") {
      dispatch(
        setVendorAppSurveyList({
          loading: false,
          error: {
            errorText: "Error fetching questionnaire list",
            actionText: "Retry",
            actionOnClick: () =>
              dispatch(fetchVendorAppSurveyList(forceRefresh)),
          },
          surveys: undefined,
          surveyCounts: undefined,
        })
      );
      return null;
    }

    let foundSurveys = null;
    if (json && json.surveys) {
      foundSurveys = json.surveys;
    }

    // update the common cache of surveys by id
    const surveyCounts = {};

    for (let i = 0; i < foundSurveys.length; i++) {
      dispatch(setSurveyData(foundSurveys[i].id, { survey: foundSurveys[i] }));

      const usageType = foundSurveys[i].usageType;
      const isPending =
        foundSurveys[i].status === SurveyStatus.Sent ||
        foundSurveys[i].status === SurveyStatus.InProgress;

      if (!surveyCounts[usageType]) {
        surveyCounts[usageType] = {
          total: 1,
          active: isPending ? 1 : 0,
          surveyIds: [foundSurveys[i].id],
        };
      } else {
        surveyCounts[usageType] = {
          total: surveyCounts[usageType].total + 1,
          active: isPending
            ? surveyCounts[usageType].active + 1
            : surveyCounts[usageType].active,
          surveyIds: [...surveyCounts[usageType].surveyIds, foundSurveys[i].id],
        };
      }
    }

    dispatch(
      setVendorAppSurveyList({
        loading: false,
        error: null,
        surveyCounts,
      })
    );
    return null;
  };
};

// fetch user details from the DB using the CR token to identify the user. this endpoint is used
// from VendorApp start if a token already exists in the session.
export const unshareUserFromSurvey = (emailAddress, surveyID) => {
  return async (dispatch, getState) => {
    let json;

    // make our request

    try {
      const params = {
        survey_id: surveyID,
        email_address: emailAddress,
      };
      json = await FetchCyberRiskUrl(
        "disconnectsurvey/v1/",
        params,
        { method: "POST" },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error unsharing user from questionnaire: ", e);

      throw new Error(
        "Error unsharing the questionnaire. Please try again later."
      );
    }

    if (json === false) {
      return false;
    } else if (!json || json.status !== "OK") {
      LogError("Error unsharing vendor questionnaire");
      throw new Error(
        "Error unsharing the questionnaire. Please try again later."
      );
    }

    return null;
  };
};

//
// cancelInviteForSurvey
export const cancelInviteForSurvey = (inviteID) => {
  return async (dispatch, getState) => {
    let json;

    // make our request

    try {
      const params = {
        invite_id: inviteID,
      };
      json = await FetchCyberRiskUrl(
        "cancelsurveyinvite/v1/",
        params,
        { method: "POST" },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error cancelling survey invite: ", e);
      throw new Error("Error cancelling the invite. Please try again later.");
    }

    if (json === false) {
      return false;
    } else if (!json || json.status !== "OK") {
      LogError("Error cancelling survey invite");

      throw new Error("Error cancelling the invite. Please try again later.");
    }

    return [];
  };
};

export const fetchVendorAppRemediationRequestList = (force = false) => {
  return async (dispatch, getState) => {
    // Don't fetch if currently loading or we're not forcing
    if (!force && getState().vendorPortal.remediationRequestList) {
      return getState().vendorPortal.remediationRequestList;
    }

    let requests;

    dispatch(setVendorAppRemediationRequestList(undefined, true));

    try {
      requests = await FetchCyberRiskUrl(
        "remediationrequest/listforvendor/v1/",
        null,
        null,
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error retrieving vendor app request list: ", e);

      dispatch(setVendorAppRemediationRequestList(undefined, false));
      throw new Error("Error retrieving list of remediation requests.");
    }

    // For each request, update the risks in common state first
    const requestIds = [];
    for (let i = 0; i < requests.length; i++) {
      const requestId = requests[i].id;
      requestIds.push(requestId);
      dispatch(
        setRemediationRequestData(requestId, {
          details: requests[i],
        })
      );
    }

    dispatch(setVendorAppRemediationRequestList(requestIds, false));

    return requestIds;
  };
};

export const fetchVendorAppAcceptedRisks = (force = false) => {
  return async (dispatch, getState) => {
    // Don't fetch if currently loading or we're not forcing
    if (!force && getState().vendorPortal.acceptedRisks) {
      return getState().vendorPortal.acceptedRisks;
    }

    let acceptedRisks;

    try {
      acceptedRisks = await FetchCyberRiskUrl(
        "riskacceptance/approver/v1/",
        null,
        null,
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error retrieving vendor app accepted risks", e);

      throw e;
    }

    dispatch(setVendorAppAcceptedRiskList(acceptedRisks));
  };
};

export const approveRejectAcceptedRisk = (id, approve, reason) => {
  return async (dispatch, getState) => {
    try {
      await FetchCyberRiskUrl(
        "riskacceptance/approve/v1/",
        { id, approve, reason },
        { method: "PUT" },
        dispatch,
        getState
      );

      // kick off call to update the activity stream
      dispatch(conditionalRefreshActivityStreamForOrgUser());
    } catch (e) {
      LogError(
        `Error ${
          approve ? "approving" : "rejecting"
        } vendor app accepted risks`,
        e
      );

      throw e;
    }
  };
};
