import { isNumber } from "lodash";
import { GetCSTARScoreColorClass } from "../../_common/helpers";

import "../style/components/Score.scss";

interface IScoreProps {
  className?: string;
  colored?: boolean;
  score: number | undefined;
  outOf?: any; // number | string??
  qualifierText?: string;
  large?: boolean;
  label?: string;
  small?: boolean;
}

const Score = (props: IScoreProps) => {
  const {
    className,
    colored,
    score,
    outOf,
    qualifierText,
    large,
    label,
    small,
  } = props;

  const outerClassNames = ["score"];
  const innerClassNames = ["cstar-text"];

  if (className) {
    outerClassNames.push(className);
  }

  if (large) outerClassNames.push("large");
  if (small) outerClassNames.push("small");
  if (colored) innerClassNames.push(GetCSTARScoreColorClass(score));

  const isScore = isNumber(score) && score >= 0;

  return (
    <div className={outerClassNames.join(" ")}>
      <p className={innerClassNames.join(" ")}>
        <span className="score-text">{isScore ? score : "N/A"}</span>
        {isScore && outOf != "" && (
          <span className="score-out-of"> / {outOf}</span>
        )}
        {qualifierText != "" && (
          <span className="score-out-of">&nbsp;&nbsp;{qualifierText}</span>
        )}
      </p>
      {!large && <p className="label-text">{label}</p>}
    </div>
  );
};

Score.defaultProps = {
  className: "",
  colored: false,
  large: false,
  label: "",
  outOf: "",
  qualifierText: "",
  small: false,
};

export default Score;
