import { FC, ReactNode, useCallback, useEffect, useState } from "react";
import ModalV2, { BaseModalProps } from "../ModalV2";
import Button from "../core/Button";
import "../../style/components/FeatureIntroModal.scss";
import classNames from "classnames";
import { trackEvent } from "../../tracking";

export interface FeatureIntroModalStep {
  title: string;
  description: ReactNode;
  image: string;
}

interface IFeatureIntroModalProps extends BaseModalProps {
  steps: FeatureIntroModalStep[];
  onComplete?: () => void;
  trackingName?: string;
}

const FeatureIntroModal: FC<IFeatureIntroModalProps> = ({
  active,
  onClose: modalOnClose,
  onComplete,
  steps,
  trackingName,
}) => {
  const [currentStep, setCurrentStep] = useState(0);

  const step = steps[currentStep];

  const onClose = useCallback(() => {
    onComplete?.();
    modalOnClose();

    if (trackingName) {
      if (currentStep === steps.length - 1) {
        trackEvent(`FeatureIntroModalComplete`, { name: trackingName });
      } else {
        trackEvent(`FeatureIntroModalSkip`, { name: trackingName });
      }
    }
  }, [modalOnClose, onComplete, trackingName, currentStep, steps]);

  const setStep = useCallback(
    (stepIndex: number) => {
      setCurrentStep(stepIndex);
      if (trackingName) {
        trackEvent(`FeatureIntroModalAdvanceStep`, {
          name: trackingName,
          step: stepIndex + 1,
        });
      }
    },
    [trackingName]
  );

  useEffect(() => {
    if (trackingName && active) {
      trackEvent(`FeatureIntroModalOpened`, { name: trackingName });
    }
  }, [trackingName, active]);

  return (
    <ModalV2
      className="feature-intro-modal"
      containerClassName="feature-intro-modal-container"
      active={active}
      onClose={onClose}
      headerClassName="feature-intro-modal-header"
      headerContent={
        <img
          className="feature-header-img"
          alt="Feature header image"
          src={step.image}
        />
      }
      footerClassName="feature-intro-modal-footer"
      footerContent={
        <>
          <div className="step-dots">
            {steps.map((_, index) => (
              <div
                key={index}
                className={classNames("step-dot", {
                  active: index === currentStep,
                })}
                onClick={() => setStep(index)}
              />
            ))}
          </div>
          {currentStep === steps.length - 1 ? (
            <>
              <Button filledPrimary onClick={onClose}>
                Close
              </Button>
            </>
          ) : (
            <>
              <Button tertiary onClick={onClose}>
                Skip intro
              </Button>
              <Button filledPrimary onClick={() => setStep(currentStep + 1)}>
                Next
              </Button>
            </>
          )}
        </>
      }
    >
      <div className="feature-intro-description">
        <h3>{step.title}</h3>
        {step.description}
      </div>
    </ModalV2>
  );
};

export default FeatureIntroModal;
