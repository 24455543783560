import * as React from "react";
import Modal from "../ModalV2";
import Button from "../core/Button";
import {
  DisplayableExportSchedule,
  DisplayableScheduledReportRecipient,
  ExportType,
} from "../../types/exportReport";
import moment from "moment";
import {
  calculateFirstRun,
  ExportReportDelivery,
  getSubscribedEmails,
  ScheduleFrequencyPicker,
} from "../../../vendorrisk/components/modals/ExportReportModal";

import "../../style/components/EditReportScheduleModal.scss";
import _ from "lodash";
import { ILabel, LabelColor } from "../../types/label";
import PillLabel from "../../../vendorrisk/components/PillLabel";
import { DefaultThunkDispatch } from "../../types/redux";
import {
  deleteScheduledExport,
  editScheduledExport,
  getScheduledExports,
} from "../../../vendorrisk/reducers/export.actions";
import { severityMap, userToName } from "../../helpers";
import {
  HostnameVulnsFilter,
  IPAddressFilters,
  TyposquattingExportFilters,
  WebsiteStatusFilter,
} from "../../../vendorrisk/components/filter/types";
import ColorCheckbox from "../../../vendorrisk/components/ColorCheckbox";
import { UserEmailAddress } from "../../types/user";

import { ValueType } from "react-select";
import { OptionType } from "../SelectV2";
import { fetchOrgUserEmailAddresses } from "../../../vendorrisk/reducers/org.actions";
import { SeverityInt } from "../../types/severity";
import { fetchAuditTrailEventTypesAndUsers } from "../../../vendorrisk/reducers/cyberRiskActions";
import { AuditLogEventTypesAndUsers } from "../../types/auditLog";
import { IWithPermissionsProps, withPermissions } from "../../permissions";
import {
  addDefaultSuccessAlert,
  addDefaultUnknownErrorAlert,
} from "../../reducers/messageAlerts.actions";
import { IVendorHierarchy } from "../../types/vendor";
import { getSubsidiaryName } from "../../../vendorrisk/reducers/subsidiary.actions";
import { appConnect } from "../../types/reduxHooks";

interface IFilterDisplayProps {
  filterText: string;
  filterElement: JSX.Element;
}

const FilterDisplay: React.FunctionComponent<IFilterDisplayProps> = ({
  filterText,
  filterElement,
}) => (
  <>
    <div className={"left grid-section"}>
      <h3>{`Filters by ${filterText}`}</h3>
    </div>
    <div className={"right grid-section"}>{filterElement}</div>
  </>
);

interface IEditReportScheduleModalOwnProps {
  schedule?: DisplayableExportSchedule;
  active: boolean;
  onClose: () => void;
  availableLabels: ILabel[];
  subsidiaries?: IVendorHierarchy;
  dispatch: DefaultThunkDispatch;
}

interface IEditReportScheduleModalConnectedProps {
  orgUserEmailAddresses: {
    loading: boolean;
    data?: UserEmailAddress[];
  };
}

type IEditReportScheduleModalProps = IEditReportScheduleModalOwnProps &
  IEditReportScheduleModalConnectedProps &
  IWithPermissionsProps;

interface IEditReportScheduleModalState {
  selectedInterval: string;
  loading: boolean;
  selectedDate: string;
  selectedDayOfWeek: string;
  selectedTime: number;
  emailRecipients: DisplayableScheduledReportRecipient[];
  emailReport: boolean;
  auditLogEventTypesAndUsers: AuditLogEventTypesAndUsers | null;
}

class EditReportScheduleModal extends React.Component<
  IEditReportScheduleModalProps,
  IEditReportScheduleModalState
> {
  constructor(props: IEditReportScheduleModalProps) {
    super(props);

    if (!!props.schedule) {
      const runMoment = moment(props.schedule.nextRun);
      const time = runMoment.hour() + runMoment.minute() / 60;

      this.setState({
        selectedInterval: props.schedule.interval,
        loading: false,
        selectedDate: runMoment.format("YYYY-MM-DD"),
        selectedDayOfWeek: runMoment.format("dddd"),
        selectedTime: time,
        emailReport:
          !!props.schedule.emailRecipients?.length &&
          props.schedule.emailRecipients.length > 0,
        emailRecipients: !!props.schedule.emailRecipients
          ? props.schedule.emailRecipients
          : [],
      });
    } else {
      this.state = {
        selectedInterval: "d",
        loading: false,
        selectedDate: "",
        selectedDayOfWeek: "",
        selectedTime: 0,
        emailReport: false,
        emailRecipients: [],
        auditLogEventTypesAndUsers: null,
      };
    }
    this.fetchOrgEmails();
    if (this.props.canManageAuditLog) {
      this.fetchEventTypes();
    }
  }

  setStateFromSchedule = (s: DisplayableExportSchedule) => {
    const runMoment = moment(s.nextRun);
    const time = runMoment.hour() + runMoment.minute() / 60;

    console.log("HERE");

    this.setState({
      selectedInterval: s.interval,
      loading: false,
      selectedDate: runMoment.format("YYYY-MM-DD"),
      selectedDayOfWeek: runMoment.format("dddd"),
      selectedTime: time,
      emailReport: !!s.emailRecipients?.length && s.emailRecipients.length > 0,
      emailRecipients: !!s.emailRecipients ? s.emailRecipients : [],
    });
  };

  componentDidUpdate(prevProps: Readonly<IEditReportScheduleModalProps>) {
    if (prevProps.schedule !== this.props.schedule && !!this.props.schedule) {
      this.setStateFromSchedule(this.props.schedule);
    }
  }

  fetchOrgEmails = () => {
    this.props.dispatch(fetchOrgUserEmailAddresses());
  };

  fetchEventTypes = () => {
    this.props
      .dispatch(fetchAuditTrailEventTypesAndUsers())
      .then((eventTypesAndUsers) => {
        this.setState({ auditLogEventTypesAndUsers: eventTypesAndUsers });
      })
      .catch((e) => {
        console.error(e);
      });
  };

  onSubmit = () => {
    if (!this.props.schedule) {
      return;
    }

    this.setState({ loading: true });

    const first_run = calculateFirstRun(
      this.state.selectedInterval,
      this.state.selectedDate,
      this.state.selectedTime,
      this.state.selectedDayOfWeek
    );

    const { emailRecipients, emailReport } = this.state;

    this.props
      .dispatch(
        editScheduledExport(
          this.props.schedule.scheduleID,
          first_run,
          this.state.selectedInterval,
          1, // So far we don't allow other periods
          emailReport ? getSubscribedEmails(emailRecipients) : []
        )
      )
      .then(() => {
        this.props.dispatch(addDefaultSuccessAlert("Report schedule updated"));
      })
      .then(this.props.onClose)
      .then(() => this.props.dispatch(getScheduledExports()))
      .catch((e) => {
        console.error(e);
        this.props.dispatch(
          addDefaultUnknownErrorAlert("Error modifying scheduled export")
        );
      })
      .finally(() => this.setState({ loading: false }));
  };

  onDelete = () => {
    if (!this.props.schedule) {
      return;
    }

    this.setState({ loading: true });

    this.props
      .dispatch(deleteScheduledExport(this.props.schedule.scheduleID))
      .then(() => {
        this.props.dispatch(addDefaultSuccessAlert("Report schedule deleted"));
      })
      .then(this.props.onClose)
      .then(() => this.props.dispatch(getScheduledExports()))
      .catch((e) => {
        console.error(e);
        this.props.dispatch(
          addDefaultUnknownErrorAlert("Error deleting scheduled export")
        );
      })
      .finally(() => this.setState({ loading: false }));
  };

  getFilterElements = (): JSX.Element[] => {
    const elements: JSX.Element[] = [];
    const { schedule } = this.props;

    if (!schedule) {
      return [];
    }

    const meta = schedule.exportMeta;

    const websiteStatus: WebsiteStatusFilter = _.get(
      meta,
      "statusFilter",
      null
    );
    if (websiteStatus) {
      elements.push(
        <FilterDisplay
          key={"website-status"}
          filterText={"status"}
          filterElement={
            <span>
              <PillLabel key={websiteStatus} color={LabelColor.Blue}>
                {websiteStatus}
              </PillLabel>
            </span>
          }
        />
      );
    }

    const vendorLabelIds: number[] = _.get(meta, "vendorLabelIds", []);
    if (vendorLabelIds.length > 0) {
      elements.push(
        <FilterDisplay
          key={"labels"}
          filterText={"vendor labels"}
          filterElement={
            <span>
              {this.props.availableLabels
                .filter((label) => vendorLabelIds.indexOf(label.id) !== -1)
                .map((label) => (
                  <PillLabel key={label.id} color={label.colour}>
                    {label.name}
                  </PillLabel>
                ))}
            </span>
          }
        />
      );
    }

    const websiteLabelIds: number[] = _.get(meta, "websiteLabelIds", []);
    if (websiteLabelIds.length > 0) {
      elements.push(
        <FilterDisplay
          key={"website-labels"}
          filterText={"website labels"}
          filterElement={
            <span>
              {this.props.availableLabels
                .filter((label) => websiteLabelIds.indexOf(label.id) !== -1)
                .map((label) => (
                  <PillLabel key={label.id} color={label.colour}>
                    {label.name}
                  </PillLabel>
                ))}
            </span>
          }
        />
      );
    }

    let minScore: number = _.get(meta, "minScore", null);
    let maxScore: number = _.get(meta, "maxScore", null);
    if (minScore || maxScore) {
      // we may only have one filter so correct the other
      minScore = !minScore ? 0 : minScore;
      maxScore = !maxScore ? 950 : maxScore;

      elements.push(
        <FilterDisplay
          key={"scores"}
          filterText={"vendor score"}
          filterElement={<span>{`${minScore}-${maxScore}`}</span>}
        />
      );
    }

    // hostname filters can be in multiple places
    let hostnameFilters: HostnameVulnsFilter = _.get(meta, "filters", null);
    if (!hostnameFilters) {
      hostnameFilters = _.get(meta, "vulnsFilter", null);
    }
    if (hostnameFilters) {
      const CVENames: string[] | null = _.get(
        hostnameFilters,
        "CVENames",
        null
      );
      if (CVENames && CVENames.length > 0) {
        elements.push(
          <FilterDisplay
            key={"cveid"}
            filterText={"CVE ID"}
            filterElement={
              <span>
                {CVENames.map((name) => (
                  <PillLabel key={name} color={LabelColor.Blue}>
                    {name}
                  </PillLabel>
                ))}
              </span>
            }
          />
        );
      }

      const cpeProductNames: string[] = _.get(
        schedule,
        "cpeProductNames",
        null
      );
      if (cpeProductNames) {
        elements.push(
          <FilterDisplay
            key={"cpeid"}
            filterText={"software"}
            filterElement={
              <span>
                {cpeProductNames.map((name) => (
                  <PillLabel key={name} color={LabelColor.Grey}>
                    {name}
                  </PillLabel>
                ))}
              </span>
            }
          />
        );
      }

      const excludedCVSSSeverities: SeverityInt[] | null = _.get(
        hostnameFilters,
        "ExcludedCVSSSeverities",
        null
      );
      const empty =
        !excludedCVSSSeverities || excludedCVSSSeverities.length == 0;

      if (excludedCVSSSeverities && !empty) {
        excludedCVSSSeverities.push(0, 1); // always exclude pass and info
        elements.push(
          <FilterDisplay
            key={"CVSSexcluded"}
            filterText={"CVSS severity"}
            filterElement={
              <div className={"severity-icons"}>
                {Object.values(severityMap)
                  .filter((s) => excludedCVSSSeverities.indexOf(s.num) === -1)
                  .map((s) => s.label)}
              </div>
            }
          />
        );
      }

      const vulnsEPSSScores = hostnameFilters.VulnsEPSSScores ?? null;
      if (!!vulnsEPSSScores?.lowerVal || !!vulnsEPSSScores?.upperVal) {
        const minScore = !vulnsEPSSScores.lowerVal
          ? 0
          : vulnsEPSSScores.lowerVal;
        const maxScore = !vulnsEPSSScores.upperVal
          ? 100
          : vulnsEPSSScores.upperVal;

        elements.push(
          <FilterDisplay
            key={"EPSSScore"}
            filterText={"EPSS score"}
            filterElement={<span>{`${minScore}-${maxScore}`}</span>}
          />
        );
      }

      const excludeVerified: boolean = _.get(
        hostnameFilters,
        "ExcludeVerified",
        false
      );
      const excludeUnverified: boolean = _.get(
        hostnameFilters,
        "ExcludeUnverified",
        false
      );
      if (excludeUnverified || excludeVerified) {
        elements.push(
          <FilterDisplay
            key={"verified_status"}
            filterText={"verified status"}
            filterElement={
              <span>
                {excludeUnverified
                  ? "Unverified vulnerabilities"
                  : "Verified vulnerabilities"}
              </span>
            }
          />
        );
      }
    }

    // IP address filters
    if (schedule.exportType === ExportType.IPsReport) {
      const IPFilters: IPAddressFilters = _.get(meta, "filters", null);
      if (IPFilters) {
        Object.entries(IPFilters)
          .filter(([_, value]) => value && value.length > 0)
          .forEach(([key, value]) =>
            elements.push(
              <FilterDisplay
                key={key}
                filterText={key}
                filterElement={
                  <span>
                    {value.map((s: string) => (
                      <PillLabel key={s} color={LabelColor.Grey}>
                        {s}
                      </PillLabel>
                    ))}
                  </span>
                }
              />
            )
          );
      }
    }

    // typosquatting filters
    if (schedule.exportType === ExportType.TyposquatPermutationsReport) {
      const filters: TyposquattingExportFilters = _.get(meta, "filter", null);
      if (filters) {
        elements.push(
          <FilterDisplay
            key={"typo-includes"}
            filterText={"includes"}
            filterElement={
              <div>
                <ColorCheckbox
                  checked={filters.include_registered}
                  disabled
                  label={"Include Registered"}
                />
                <ColorCheckbox
                  checked={filters.include_unregistered}
                  disabled
                  label={"Include Unregistered"}
                />
                <ColorCheckbox
                  checked={filters.include_ignored}
                  disabled
                  label={"Include Ignored"}
                />
              </div>
            }
          />
        );

        if (filters.permutation_types.length > 0) {
          const text =
            "permutation types " +
            (filters.permutation_types_not_in ? "not in" : "includes");
          elements.push(
            <FilterDisplay
              key={"typo_permutation"}
              filterText={text}
              filterElement={
                <span>
                  {filters.permutation_types.map((p) => (
                    <PillLabel key={p} color={LabelColor.Grey}>
                      {p}
                    </PillLabel>
                  ))}
                </span>
              }
            />
          );
        }

        if (filters.risk_status_types.length > 0) {
          const text =
            "risk status " +
            (filters.risk_status_types_not_in ? "not in" : "includes");
          elements.push(
            <FilterDisplay
              key={"typo_risk_status"}
              filterText={text}
              filterElement={
                <span>
                  {filters.risk_status_types.map((r) => (
                    <PillLabel key={r} color={LabelColor.Grey}>
                      {r}
                    </PillLabel>
                  ))}
                </span>
              }
            />
          );
        }

        if (filters.malicious_activities.length > 0) {
          const text =
            "malicious activities " +
            (filters.malicious_activities_not_in ? "not in" : "includes");
          elements.push(
            <FilterDisplay
              key={"typo_malicious_activities"}
              filterText={text}
              filterElement={
                <span>
                  {filters.malicious_activities.map((b) => (
                    <PillLabel key={b} color={LabelColor.Grey}>
                      {b}
                    </PillLabel>
                  ))}
                </span>
              }
            />
          );
        }
      }
    }

    if (schedule.exportType === ExportType.AuditLogReport) {
      const userIDs: number[] = _.get(meta, "filters.UserIDs", null);
      if (userIDs && userIDs.length > 0) {
        elements.push(
          <FilterDisplay
            key={"type-audit-log-user"}
            filterText={"users"}
            filterElement={
              <span>
                {this.state.auditLogEventTypesAndUsers?.users
                  .filter((user) => userIDs.indexOf(user.id) !== -1)
                  .map((user) => (
                    <PillLabel key={user.id}>
                      {userToName(user, true)}
                    </PillLabel>
                  ))}
              </span>
            }
          />
        );
      }

      const eventTypes: string[] = _.get(meta, "filters.EventTypes", null);
      if (eventTypes && eventTypes.length > 0) {
        elements.push(
          <FilterDisplay
            key={"typo-audit-log-event-types"}
            filterText={"event types"}
            filterElement={
              <span>
                {this.state.auditLogEventTypesAndUsers?.eventTypes
                  .filter(
                    (eventType) => eventTypes.indexOf(eventType.type) !== -1
                  )
                  .map((eventType) => (
                    <PillLabel key={eventType.type}>{eventType.name}</PillLabel>
                  ))}
              </span>
            }
          />
        );
      }

      const startDate: string = _.get(meta, "filters.StartDate", null);
      if (startDate) {
        elements.push(
          <FilterDisplay
            key={"typo-audit-log-start-date"}
            filterText={"start date"}
            filterElement={<span>{moment(startDate).format("ll")}</span>}
          />
        );
      }

      const endDate: string = _.get(meta, "filters.EndDate", null);
      if (endDate) {
        elements.push(
          <FilterDisplay
            key={"typo-audit-log-end-date"}
            filterText={"end date"}
            filterElement={<span>{moment(endDate).format("ll")}</span>}
          />
        );
      }

      const durationDays: number = _.get(meta, "durationDays", null);
      if (durationDays) {
        const text =
          durationDays > 0 ? `Last ${durationDays} days` : `All events`;
        elements.push(
          <FilterDisplay
            key={"typo-audit-log-duration"}
            filterText={"duration"}
            filterElement={<span>{text}</span>}
          />
        );
      }
    }

    /*
      "subsidiariesFilter": {
    "orgId": 428,
    "labelIds": [],
    "maxScore": null,
    "minScore": null,
    "unlabeled": false,
    "subsidiaryIds": [],
    "labelIdsMatchAll": false,
    "subsidiariesOnly": true,
    "labelIdsDoNotMatch": false,
    "dontFilterOrgWithIds": false
  },
  "isIncludingSubsidiaries": true
     */

    const subsidiariesFilter = _.get(meta, "subsidiariesFilter", null);
    if (subsidiariesFilter) {
      let minScore: number = _.get(subsidiariesFilter, "minScore", null);
      let maxScore: number = _.get(subsidiariesFilter, "maxScore", null);
      if (minScore || maxScore) {
        // we may only have one filter so correct the other
        minScore = !minScore ? 0 : minScore;
        maxScore = !maxScore ? 950 : maxScore;

        elements.push(
          <FilterDisplay
            key={"subsidiary-scores"}
            filterText={"subsidiary score"}
            filterElement={<span>{`${minScore}-${maxScore}`}</span>}
          />
        );
      }
      const subsidiaryIds = _.get(subsidiariesFilter, "subsidiaryIds", null);
      if (subsidiaryIds.length > 0) {
        const pills = [];
        for (let i = 0; i < subsidiaryIds.length; i++) {
          const name = getSubsidiaryName(
            subsidiaryIds[i],
            this.props.subsidiaries
          );
          if (name) {
            pills.push(<PillLabel key={subsidiaryIds[i]}>{name}</PillLabel>);
          }
        }
        elements.push(
          <FilterDisplay
            key={"subsidiaries"}
            filterText={"subsidiaries"}
            filterElement={<span>{pills}</span>}
          />
        );
      }
      const labelIds = _.get(subsidiariesFilter, "labelIds", []);
      if (labelIds.length > 0) {
        elements.push(
          <FilterDisplay
            key={"labels"}
            filterText={"subsidiary labels"}
            filterElement={
              <span>
                {this.props.availableLabels
                  .filter((label) => labelIds.indexOf(label.id) !== -1)
                  .map((label) => (
                    <PillLabel key={label.id} color={label.colour}>
                      {label.name}
                    </PillLabel>
                  ))}
              </span>
            }
          />
        );
      }
    }
    return elements;
  };

  onSelectEmail = (selectedOptions: ValueType<OptionType, true>) => {
    if (selectedOptions) {
      // If there is already an email recipient in props use that, otherwise create a new one
      this.setState({
        emailRecipients: selectedOptions.map((o) => {
          let emailRecipient: DisplayableScheduledReportRecipient | undefined;
          emailRecipient = this.props.schedule?.emailRecipients?.find(
            (e) => e.emailAddress === o.value
          );
          if (!emailRecipient) {
            emailRecipient = {
              emailAddress: o.value as string,
              unsubscribed: false,
              unsubscribedAll: false,
            };
          } else {
            // If there is an existing one then re-subscribe it
            emailRecipient.unsubscribed = false;
          }
          return emailRecipient;
        }),
      });
    } else {
      this.setState({ emailRecipients: [] });
    }
  };

  render() {
    return (
      <Modal
        className={"edit-report-schedule-modal"}
        active={this.props.active && !!this.props.schedule}
        onClose={this.props.onClose}
        headerContent={"Edit recurring report schedule"}
        footerClassName={"edit-report-schedule-modal-footer"}
        footerContent={
          <>
            <Button
              className={"delete-button"}
              danger
              onClick={this.onDelete}
              loading={this.state.loading}
            >
              <i className="cr-icon-trash" />
              Delete recurring report
            </Button>
            <Button tertiary onClick={this.props.onClose}>
              Cancel
            </Button>
            <Button
              primary
              onClick={this.onSubmit}
              loading={this.state.loading}
            >
              Save changes
            </Button>
          </>
        }
      >
        <div className={"form-grid"}>
          {this.getFilterElements()}
          <div className={"left grid-section"}>
            <h3>Frequency</h3>
            <p>How often do you want to receive this report?</p>
          </div>
          <div className={"right grid-section"}>
            <ScheduleFrequencyPicker
              interval={this.state.selectedInterval}
              onChangeInterval={(interval) =>
                this.setState({ selectedInterval: interval })
              }
              dayOfWeek={this.state.selectedDayOfWeek}
              onChangeDayOfWeek={(day) =>
                this.setState({ selectedDayOfWeek: day })
              }
              date={this.state.selectedDate}
              onChangeDate={(date) => this.setState({ selectedDate: date })}
              time={this.state.selectedTime}
              onChangeTime={(time) => this.setState({ selectedTime: time })}
            />
          </div>
          <ExportReportDelivery
            orgUserEmailAddresses={this.props.orgUserEmailAddresses}
            emailRecipients={this.state.emailRecipients}
            onSelectEmail={this.onSelectEmail}
            emailReport={this.state.emailReport}
            onEmailReportChange={(b) => this.setState({ emailReport: b })}
            scheduleExport
            onRemoveEmail={(email) =>
              this.setState({
                emailRecipients: this.state.emailRecipients.filter(
                  (e) => e !== email
                ),
              })
            }
          />
        </div>
      </Modal>
    );
  }
}

export default withPermissions(
  appConnect<
    IEditReportScheduleModalConnectedProps,
    never,
    IEditReportScheduleModalOwnProps
  >((state, _props) => {
    return {
      orgUserEmailAddresses: state.cyberRisk.orgUserEmailAddresses,
    };
  })(EditReportScheduleModal)
);
