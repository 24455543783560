import ScoresChart from "./ScoresChart";
import userbaseApi from "../api/userbase.api";

import { FC } from "react";

interface UserScoresChartProps {
  userUUID: string;
}

const UserScoresChart: FC<UserScoresChartProps> = ({ userUUID }) => {
  const { data, isLoading } = userbaseApi.useGetUserBaseUserSummaryV1Query({
    userUUID,
  });

  return <ScoresChart data={data?.user} isLoading={isLoading} />;
};

export default UserScoresChart;
