import { FC, useMemo } from "react";
import PageHeader from "../../_common/components/PageHeader";
import VendorRiskWaiversCard, {
  mergeWaiverArrays,
} from "../../vendorrisk/components/VendorRiskWaiversCard";
import { useHistory } from "react-router-dom";
import { DefaultRouteProps } from "../../_common/types/router";
import { useAppDispatch, useAppSelector } from "../../_common/types/reduxHooks";
import { useAssuranceType, useVendorWords } from "../../_common/hooks";
import {
  IVendorRiskWaiver,
  WaiverType,
} from "../../_common/types/vendorRiskWaivers";
import { getSearchParamAsNumber } from "../../_common/helpers/search.helpers";
import { useCurrentOrg } from "../../_common/selectors/commonSelectors";

interface IRiskAdjustmentApprovalsListProps extends DefaultRouteProps {
  includeOwnOrgWaivers: boolean;
  includeOtherOrgRiskWaivers: boolean;
}

const RiskAdjustmentApprovalsList: FC<IRiskAdjustmentApprovalsListProps> = (
  props
) => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const adjustments = useAppSelector(
    (state) => state.vendorPortal.vendorRiskAdjustments
  );
  const assuranceType = useAssuranceType();
  const vendorWords = useVendorWords();
  const currentOrg = useCurrentOrg();

  const initiallySelectedId = getInitialSelectedId(location.search);

  const filteredRiskAdjustments = useMemo(() => {
    const filteredAdjustments: IVendorRiskWaiver[] = [];

    adjustments?.forEach((adjustment) => {
      if (
        (props.includeOwnOrgWaivers &&
          adjustment.organisationID === currentOrg?.id) ||
        (props.includeOtherOrgRiskWaivers &&
          adjustment.organisationID !== currentOrg?.id)
      ) {
        filteredAdjustments.push(adjustment);
      }
    });

    return filteredAdjustments;
  }, [
    currentOrg?.id,
    adjustments,
    props.includeOtherOrgRiskWaivers,
    props.includeOwnOrgWaivers,
  ]);

  return (
    <div id="risk_adjustment_approvals_view">
      <PageHeader
        history={history}
        title="Risk Adjustment Approvals"
        backAction={
          props.location.state?.backContext?.goBack
            ? history.goBack
            : props.location.state && props.location.state.backContext
              ? () => {
                  history.push(props.location.state.backContext?.backTo || "");
                }
              : undefined
        }
        backText={
          props.location.state && props.location.state.backContext
            ? props.location.state.backContext.backToText
            : undefined
        }
        infoSectionPageKey="infoSection_vendorPortalRiskAdjustments"
        infoSection={
          <>
            <p>
              You were nominated as the approver for the risk adjustments below.
              Click on a risk adjustment for more information then use the
              Actions button to approve or reject it. Approved risk adjustments
              will impact the security rating that is displayed to users of your
              UpGuard account for your company or its vendors.
            </p>
          </>
        }
      />

      <VendorRiskWaiversCard
        headerTextOverride={`Risk Adjustments for ${vendorWords.pluralTitleCase}`}
        hideSearch
        dispatch={dispatch}
        history={history}
        riskWaivers={
          adjustments ? mergeWaiverArrays(filteredRiskAdjustments) : undefined
        }
        showVendorName={true}
        allowApprove={true}
        allowCreate={false}
        initiallySelectedId={initiallySelectedId}
        assuranceType={assuranceType}
        waiverType={WaiverType.SeverityAdjustment}
      />
    </div>
  );
};

const getInitialSelectedId = (search: string) => {
  return getSearchParamAsNumber(search, "vendorRiskWaiverId");
};

export default RiskAdjustmentApprovalsList;
