import { FC } from "react";

import ColorGrade, {
  ColorGradeSize,
} from "../../vendorrisk/components/executive_summary/ColorGrade";
import Score from "../../vendorrisk/components/Score";

import "./SecurityRatingDisplay.scss";

export enum RatingSize {
  Small = "small",
  Medium = "medium",
}

const SecurityRatingDisplay: FC<{ rating: number; size: RatingSize }> = ({
  rating,
  size,
}) => {
  if (size === RatingSize.Small) {
    return (
      <div className="rating">
        <ColorGrade score={rating} size={ColorGradeSize.Small} />
        <Score score={rating} small />
      </div>
    );
  }

  return (
    <div className="rating">
      <ColorGrade score={rating} size={ColorGradeSize.Medium} />
      <Score score={rating} large />
    </div>
  );
};

export default SecurityRatingDisplay;
