import { FC } from "react";
import { OnboardingStepName } from "../types/onboardingStepsMeta";
import classnames from "classnames";
import UpGuardLogo from "../images/upguard-logo-black.svg";
import { breachSightProductDisplayName } from "../types/products";

interface OnboardingNavEntry {
  text: string;
  iconClass: string;
  preventNavigate?: boolean;
}

export interface IOnboardingStepsNavEntryProps extends OnboardingNavEntry {
  selected: boolean;
  completed: boolean;
  stepNumber: number;
  onClick?: () => void;
}

const OnboardingStepsNavEntry: FC<IOnboardingStepsNavEntryProps> = (props) => (
  <div className={"onboarding-nav-entry"}>
    <div
      className={classnames("onboarding-nav-entry-container", {
        selected: props.selected,
        clickable: !!props.onClick,
      })}
      onClick={props.onClick}
    >
      <div className={"nav-entry"}>
        <span className={`nav-entry-icon ${props.iconClass}`} />
        <span className={"nav-entry-text"}>{props.text}</span>
      </div>
    </div>
    <div
      className={classnames("nav-number", {
        selected: props.selected,
        completed: props.completed,
      })}
    >
      <span className={classnames({ ["cr-icon-check"]: props.completed })}>
        {props.completed ? undefined : props.stepNumber}
      </span>
    </div>
  </div>
);

export interface IOnboardingStepsNavProps {
  steps: OnboardingStepName[];
  canNavigate: boolean;
  currentStepIdx: number;
  setCurrentStep: (name: string) => void;
}

const OnbordingStepsNav = (props: IOnboardingStepsNavProps) => {
  // It's assumed this component has only been mounted if there is at least one relevant step.
  const { currentStepIdx, setCurrentStep } = props;

  interface OnboardingNavEntryList extends OnboardingNavEntry {
    name: OnboardingStepName;
  }
  const steps: OnboardingNavEntryList[] = [
    {
      iconClass: "cr-icon-q-builder-flag",
      text: "Welcome",
      name: OnboardingStepName.SetFirstLastName,
      // preventNavigate: true,
    },
    {
      iconClass: "cr-icon-breachsight coloured",
      text: `${breachSightProductDisplayName}`,
      name: OnboardingStepName.BreachSight,
    },
    {
      iconClass: "cr-icon-vendor-risk coloured",
      text: "Vendor Risk",
      name: OnboardingStepName.VendorRisk,
    },
    {
      iconClass: "cr-icon-trust-exchange coloured",
      text: "Trust Exchange",
      name: OnboardingStepName.TrustExchange,
    },
    {
      iconClass: "cr-icon-people",
      text: "Invite your team",
      name: OnboardingStepName.InviteUsersToOrg,
      preventNavigate: true,
    },
  ];

  const filteredSteps = steps.filter((s) => props.steps.includes(s.name));

  return (
    <div className={"onboarding-steps-nav"}>
      <div className={"logo"}>
        <img src={UpGuardLogo} alt="UpGuard Logo" className="ug-logo" />
      </div>
      <div className={"steps"}>
        {filteredSteps.map((step, i) => (
          <OnboardingStepsNavEntry
            key={i}
            selected={currentStepIdx === i}
            completed={i < currentStepIdx}
            stepNumber={i + 1}
            text={step.text}
            iconClass={step.iconClass}
            onClick={
              props.canNavigate && !step.preventNavigate
                ? () => setCurrentStep(step.name)
                : undefined
            }
          />
        ))}
      </div>
    </div>
  );
};

export default OnbordingStepsNav;
