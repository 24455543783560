import { Fragment, memo } from "react";
import {
  eventToText,
  groupItemsIntoDays,
  ITimelineEvent,
} from "../types/apiTimeline";
import { IUserMini, IUserMiniMap } from "../types/user";
import ReportCard from "./ReportCard";
import XTable, { XTableCell } from "./core/XTable";
import moment from "moment";
import UserAvatar from "./UserAvatar";
import PillLabel from "../../vendorrisk/components/PillLabel";
import "../style/components/Timeline.scss";
import { LabelColor } from "../types/label";

interface ITimelineProps {
  items: ITimelineEvent[];
  users: IUserMiniMap;
  noun: string;
  showRisksAdded?: boolean;
  showRisksRemoved?: boolean;
  showRisksUpdated?: boolean;
  showUsersAdded?: boolean;
  showUsersRemoved?: boolean;
  showDocumentsAdded?: boolean;
  showDocumentsRemoved?: boolean;
  isImportedSurvey?: boolean;
  showEventType?: boolean;
}

const Timeline = (props: ITimelineProps) => {
  const itemsByDay = groupItemsIntoDays(props.items);
  const usersByEmail: { [user: string]: IUserMini } = Object.values(
    props.users
  ).reduce((prev, user) => ({ ...prev, [user?.email ?? "unknown"]: user }), {});

  return (
    <ReportCard newStyles className={"timeline-card"}>
      <div className={"header"}>Timeline</div>
      {itemsByDay.map((d) => (
        <XTable
          key={d.date}
          columnHeaders={[{ id: "day", text: moment(d.date).format("ll") }]}
          rows={d.items.map((item, i) => {
            let user;
            if (item.userId) {
              user = props.users[item.userId];
            }

            return {
              id: `${item.at}${i}`,
              cells: [
                <XTableCell key="main">
                  <div className="timeline-item">
                    <div className="time">
                      {moment(item.at).format("h:mm a")}
                    </div>
                    <div className="item-data">
                      <div>
                        {user && (
                          <UserAvatar avatar={user.avatar} name={user.name} />
                        )}{" "}
                        {user && user.name + " "}
                        {item.actionText}
                      </div>
                      {item.statusUpdate && (
                        <div className="status-update">
                          <div className={"status"}>
                            {" "}
                            {item.statusUpdate.prevStatus.text != "" && (
                              <>
                                <PillLabel
                                  color={
                                    item.statusUpdate.prevStatus.labelColor
                                  }
                                >
                                  {props.isImportedSurvey &&
                                  item.statusUpdate.prevStatus
                                    .importedSurveyText
                                    ? item.statusUpdate.prevStatus
                                        .importedSurveyText
                                    : item.statusUpdate.prevStatus.text}
                                </PillLabel>
                                <div className="cr-icon-arrow-right" />
                              </>
                            )}
                            <PillLabel
                              color={item.statusUpdate.newStatus.labelColor}
                            >
                              {props.isImportedSurvey &&
                              item.statusUpdate.newStatus.importedSurveyText
                                ? item.statusUpdate.newStatus.importedSurveyText
                                : item.statusUpdate.newStatus.text}
                            </PillLabel>
                          </div>
                          {item.comment && (
                            <div className={"justification"}>
                              Justification: <span>{item.comment}</span>
                            </div>
                          )}
                        </div>
                      )}
                      {item.riskNames && item.riskNames.length > 0 && (
                        <ul>
                          {item.riskNames.map((r) => (
                            <li key={r}>{r}</li>
                          ))}
                        </ul>
                      )}
                      {props.showRisksAdded &&
                        item.risksAdded &&
                        item.risksAdded.length > 0 && (
                          <div className={"risks-added"}>
                            <h3>
                              {`Additional risks were added to this ${props.noun}`}
                            </h3>
                            <ul>
                              {item.risksAdded.map((r) => (
                                <li key={r}>{r}</li>
                              ))}
                            </ul>
                          </div>
                        )}
                      {props.showRisksRemoved &&
                        item.risksRemoved &&
                        item.risksRemoved.length > 0 && (
                          <div className={"risks-added"}>
                            <h3>
                              {`Risks were removed from this ${props.noun}`}
                            </h3>
                            <ul>
                              {item.risksRemoved.map((r) => (
                                <li key={r}>{r}</li>
                              ))}
                            </ul>
                          </div>
                        )}
                      {props.showRisksUpdated &&
                        item.risksUpdated &&
                        item.risksUpdated.length > 0 && (
                          <div className={"risks-added"}>
                            <h3>
                              {`Risks were updated for this ${props.noun}`}
                            </h3>
                            <ul>
                              {item.risksUpdated.map((r) => (
                                <li key={r}>{r}</li>
                              ))}
                            </ul>
                          </div>
                        )}
                      {props.showUsersAdded &&
                        item.usersAdded &&
                        item.usersAdded.length > 0 && (
                          <ul>
                            {item.usersAdded.map((u) =>
                              usersByEmail[u] ? (
                                <li key={u}>
                                  <UserAvatar avatar={usersByEmail[u].avatar} />
                                  {`${usersByEmail[u].name} was added to this ${props.noun}`}
                                </li>
                              ) : (
                                <Fragment key={u} />
                              )
                            )}
                          </ul>
                        )}
                      {props.showUsersRemoved &&
                        item.usersRemoved &&
                        item.usersRemoved.length > 0 && (
                          <ul>
                            {item.usersRemoved.map((u) =>
                              usersByEmail[u] ? (
                                <li key={u}>
                                  <UserAvatar avatar={usersByEmail[u].avatar} />
                                  {`${usersByEmail[u].name} was added to this ${props.noun}`}
                                </li>
                              ) : (
                                <Fragment key={u} />
                              )
                            )}
                          </ul>
                        )}
                      {props.showDocumentsAdded &&
                        item.documentsAdded &&
                        item.documentsAdded.length > 0 && (
                          <div className={"documents-added"}>
                            <ul>
                              {item.documentsAdded.map((r) => (
                                <li key={r}>{r}</li>
                              ))}
                            </ul>
                          </div>
                        )}
                      {props.showDocumentsRemoved &&
                        item.documentsRemoved &&
                        item.documentsRemoved.length > 0 && (
                          <div className={"documents-removed"}>
                            <ul>
                              {item.documentsRemoved.map((r) => (
                                <li key={r}>{r}</li>
                              ))}
                            </ul>
                          </div>
                        )}
                    </div>

                    {props.showEventType && (
                      <div className={"event-type"}>
                        <PillLabel color={LabelColor.Grey}>
                          {eventToText(item.eventType)}
                        </PillLabel>
                      </div>
                    )}
                  </div>
                </XTableCell>,
              ],
            };
          })}
        />
      ))}
    </ReportCard>
  );
};

Timeline.defaultProps = {
  showRisksAdded: false,
  showRisksRemoved: false,
  showRisksUpdated: false,
  showUsersAdded: false,
  showUsersRemoved: false,
  showDocumentsAdded: false,
  showDocumentsRemoved: false,
};

export default memo(Timeline);
