import * as React from "react";
import { BaseModalProps } from "../ModalV2";
import { DefaultThunkDispatch } from "../../types/redux";
import {
  fetchVendorSummaryAndCloudscans,
  togglePrefilledSurveyInclusionInScoring,
} from "../../../vendorrisk/reducers/cyberRiskActions";
import {
  addDefaultSuccessAlert,
  addDefaultUnknownErrorAlert,
} from "../../reducers/messageAlerts.actions";
import { fetchPrefilledSurveyDetails } from "../../reducers/commonActions";
import { getSurveyListV2 } from "../../../vendorrisk/reducers/survey.actions";
import { fetchVendorPortfolioRiskProfile } from "../../../vendorrisk/reducers/vendorRiskPortfolio.actions";
import ConfirmationModalV2 from "./ConfirmationModalV2";

export interface IExcludeSharedSurveyFromRiskProfileModalProps
  extends BaseModalProps {
  exclude: boolean;
  dispatch: DefaultThunkDispatch;
  surveyId: number;
  vendorId: number;
}

const ExcludeSharedProfileFromRiskProfileModal: React.VFC<
  IExcludeSharedSurveyFromRiskProfileModalProps
> = ({ active, dispatch, onClose, exclude, surveyId, vendorId }) => {
  const toggleInclusionInVendorScore = async () => {
    // capture the value of inclusion before it changes due to the survey details refresh
    const currentlyIncluded = !exclude;
    try {
      await dispatch(
        togglePrefilledSurveyInclusionInScoring(surveyId, !currentlyIncluded)
      );

      dispatch(getSurveyListV2(true, vendorId));
      dispatch(fetchVendorSummaryAndCloudscans(vendorId, true));
      dispatch(fetchVendorPortfolioRiskProfile());

      await dispatch(fetchPrefilledSurveyDetails(surveyId, true, true)).then(
        () => {
          dispatch(
            addDefaultSuccessAlert(
              !currentlyIncluded
                ? "Successfully included questionnaire in Risk Profile."
                : "Successfully excluded questionnaire from Risk Profile."
            )
          );
          onClose();
        }
      );
    } catch (e) {
      dispatch(
        addDefaultUnknownErrorAlert("Error updating risk profile exclusion")
      );
      throw e;
    }
  };

  return (
    <ConfirmationModalV2
      title={
        exclude ? "Include in Risk Profile?" : "Exclude from Risk Profile?"
      }
      description={
        exclude
          ? `The questionnaire's risks will be factored into this vendor's Risk Profile and your overall Portfolio Risk Profile. Also, its score will contribute to this vendor's questionnaire score.`
          : `The questionnaire's risks will no longer be factored into this vendor's Risk Profile and your overall Portfolio Risk Profile. Also, its score will no longer contribute to this vendor's questionnaire score.`
      }
      buttonText="Confirm"
      cancelText="Cancel"
      buttonAction={async () => {
        await toggleInclusionInVendorScore();
      }}
      active={active}
      onClose={onClose}
    />
  );
};

export default ExcludeSharedProfileFromRiskProfileModal;
