import { FC } from "react";
import classNames from "classnames";

import "./SingleStat.scss";
import { NumberWithCommas } from "../../../_common/helpers";
import LoadingBanner from "../../../_common/components/core/LoadingBanner";
import Button from "../../../_common/components/core/Button";
import { LabelColor } from "../../../_common/types/label";
import PillLabel from "../../../vendorrisk/components/PillLabel";

export enum SingleStatSize {
  Small = "small",
  Large = "large",
}
interface SingleStatProps {
  isLoading: boolean;
  statNum: number;
  statDescription: string;
  statLinkText?: string;
  onClick?: () => void;
  className?: string;
  size?: SingleStatSize;
}

// Shows a single stat with a description and a link to another page if clicked
// Support two modes of display - Large (default) and Small
const SingleStat: FC<SingleStatProps> = ({
  isLoading,
  statNum,
  statDescription,
  statLinkText,
  onClick,
  className,
  size = SingleStatSize.Large,
}) => {
  const statNumberWithCommas = NumberWithCommas(statNum);

  // Determine the control to use based on the size
  const controlToShow =
    size === SingleStatSize.Large ? (
      <div className="large-stat">
        {isLoading && <LoadingBanner />}
        {!isLoading && (
          <div className={"content"}>
            <div className="count">{statNumberWithCommas}</div>
            <div className="subtext">{statDescription}</div>
            {statLinkText && onClick && (
              <div>
                <div className="link-button">
                  <Button tertiary onClick={onClick}>
                    {statLinkText}
                  </Button>
                  <div className="cr-icon-arrow-right" />
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    ) : (
      <div className="small-stat" onClick={!isLoading ? onClick : undefined}>
        {!isLoading && (
          <PillLabel color={LabelColor.Blue}>
            {statNumberWithCommas} {statDescription}
          </PillLabel>
        )}
      </div>
    );

  // Show the control
  return (
    <div className={classNames("ub-single-stat", className)}>
      {controlToShow}
    </div>
  );
};

export default SingleStat;
