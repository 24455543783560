import { Moment } from "moment";
import { Severity } from "./severity";
import { VendorSummaryRiskType } from "./vendorSummary";

export enum VendorRiskWaiverStatusType {
  Inactive = 0,
  Active = 1,
  AwaitingApproval = 2,
  Expired = 3,
  Cancelled = 4,
  Rejected = 5,
}

export enum VendorRiskWaiverRiskType {
  Cloudscan = "cloudscan",
  Survey = "survey",
  Evidence = "evidence",
}

export const VendorRiskWaiverRiskTypeToVendorSummaryRiskType = (
  riskType: VendorRiskWaiverRiskType
) => {
  switch (riskType) {
    case VendorRiskWaiverRiskType.Cloudscan:
      return VendorSummaryRiskType.Cloudscan;
    case VendorRiskWaiverRiskType.Survey:
      return VendorSummaryRiskType.Survey;
    case VendorRiskWaiverRiskType.Evidence:
      return VendorSummaryRiskType.Evidence;
  }
};

export interface IVendorRiskWaiverSurveyDetail {
  id: number;
  name: string;
  why?: string;
  explanation?: string;
  author: string;
  sharedAt: string;
  avatar: string;
  surveyLastSent: string;
  surveyLastSentBy?: string;
}

export interface IVendorRiskWaiver {
  kind: "vendorRiskWaiver";
  id: number;
  organisationID: number;
  datastoreVendorID: number;
  vendorName: string;
  riskID: string;
  riskType: VendorRiskWaiverRiskType;
  isAllDomains: boolean;
  domains: string[];
  isAllSurveys: boolean;
  surveys: number[];
  publicSurveys: number[];
  isActive: boolean;
  status: VendorRiskWaiverStatusType;
  createdBy: number;
  createdByEmail: string;
  createdByAvatar: string;
  createdByName: string;
  justification: string;
  approverEmail?: string;
  approverAvatar?: string;
  approverName?: string;
  approverReason?: string;
  expiresAt?: string;
  activeAt?: string;
  inactiveAt?: string;
  createdAt: string;
  updatedAt: string;
  deletedAt?: string;
  riskName: string;
  riskText: string;
  riskSummary: string;
  riskDetails: string;
  riskRecommendedRemediation: string;
  riskCategory: string;
  riskCategoryText: string;
  riskSeverity: number;
  riskScoreCategory: string;
  surveysDetails: IVendorRiskWaiverSurveyDetail[];
  publicSurveysDetails: IVendorRiskWaiverSurveyDetail[];
  publicWaiverId?: number;
  vendorDomain: string;
  RiskSeverityStr: string;
  watchlistId: number;
  originalId: number;
  adjustedSeverity?: Severity;
  waiverType: WaiverType;
}

export interface IVendorRiskWaiverCreateRequest {
  datastoreVendorID: number;
  riskID: string;
  riskType: string;
  isAllDomains: boolean;
  domains: string[];
  surveys: number[];
  publicSurveys: number[];
  justification: string;
  approverEmail?: string;
  expiresAt?: string | Moment;
  adjustedSeverity?: Severity;
}

export interface IVendorRiskWaiverUpdateRequest {
  ID: number;
  expiresAt?: string;
  approverEmail?: string;
  justification: string;
  isAllDomains: boolean;
  domains: string[];
  surveys: number[];
  publicSurveys: number[];
  adjustedSeverity?: Severity;
}

export interface IVendorRiskWaiverApproveRequest {
  ID: number;
  approve: boolean;
  justification: string;
}

export interface IAssessmentRiskWaivers {
  assessmentId: number;
  assessmentPublishDate: string;
  riskWaivers: IVendorRiskWaiver[];
}

export enum WaiverType {
  RiskWaiver = "waiver",
  SeverityAdjustment = "severityAdjustment",
}
