const delimeter = "|";

// getRepoManifestLocation returns a cross-repo unique manifest location
// e.g. myRepo, test.txt => mRepo|text.txt
export const getRepoManifestLocation = (
  repoName: string,
  manifestLocation: string
) => {
  return `${repoName}${delimeter}${manifestLocation}`;
};

// splitRepoManifestLocation returns the repo name + manifest location parts of a repoManifestLocation
// NOTE: returns blank if invalid
// e.g. mRepo|text.txt => myRepo, test.txt
export const splitRepoManifestLocation = (
  repoManifestLocation: string
): [string, string] => {
  const splits = repoManifestLocation.split(delimeter);

  if (splits.length !== 2) {
    return ["", ""];
  }

  return [splits[0], splits[1]];
};
