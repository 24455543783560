import { FC } from "react";
import classNames from "classnames";
import EllipsizedText from "../../_common/components/EllipsizedText";
import UserAvatar from "../../_common/components/UserAvatar";
import IconButton from "../../_common/components/IconButton";
import "../style/components/UserDisplayV2.scss";

interface UserDisplayV2Props {
  name?: string;
  email: string;
  avatar?: string;

  detailed?: boolean;
  title?: string;
  phoneNumber?: string;
  notes?: string;

  disabled?: boolean;
  onDelete?: () => void;
}

const UserDisplayV2: FC<UserDisplayV2Props> = (props) => {
  const showNameFields = !!props.name || (props.detailed && props.title);

  return (
    <div
      className={classNames("user-display-v2", { detailed: props.detailed })}
    >
      <UserAvatar avatar={props.avatar} name={props.name} />
      {showNameFields && (
        <div
          className={classNames("user-fields", "name-fields", {
            detailed: props.detailed,
          })}
        >
          <div className={"user-name"}>{props.name}</div>
          {props.detailed && <div className={"light"}>{props.title}</div>}
        </div>
      )}
      <div
        className={classNames("user-fields", "email-fields", {
          detailed: props.detailed,
        })}
      >
        <EllipsizedText text={props.email}>
          <div className="light">{props.email}</div>
        </EllipsizedText>
        {props.detailed && <div className={"light"}>{props.phoneNumber}</div>}
      </div>
      {props.detailed && <div className={"notes"}>{props.notes}</div>}
      {props.onDelete && (
        <IconButton
          disabled={props.disabled}
          icon={<i className={"cr-icon-trash"} />}
          onClick={props.onDelete}
        />
      )}
    </div>
  );
};

export default UserDisplayV2;
