import { useBack } from "../../_common/types/router";
import { UserRouteParams } from "../UserBaseNavItems";
import { FC } from "react";
import userbaseApi from "../api/userbase.api";
import UserBaseUserHeader from "../components/header/UserBaseUserHeader";
import { userApplicationsUrl, userUrl, usersUrl } from "../UserBaseAppRouter";
import { useRouteMatch } from "react-router-dom";
import UserApps from "../components/UserApps";

const UserApplicationsView: FC = () => {
  const match = useRouteMatch<UserRouteParams>();
  const { backAction, backText } = useBack();

  const userUUID = match.params.userUUID;

  const { data } = userbaseApi.useGetUserBaseUserSummaryV1Query({ userUUID });

  return (
    <div className="user-profile">
      <UserBaseUserHeader
        breadcrumbs={[
          { text: "Users", to: usersUrl },
          {
            text: data?.user.name ?? "User",
            to: userUrl(userUUID),
          },
          {
            text: "Applications",
            to: userApplicationsUrl(userUUID),
          },
        ]}
        backAction={backAction}
        backText={backText}
        title={"Applications"}
      />

      <UserApps />
    </div>
  );
};

export default UserApplicationsView;
