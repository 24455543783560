import { PayloadAction, createSelector, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../_common/types/reduxStore";

type PollState = Record<string, Record<string, Date>>;
interface AppguardState {
  pollState: PollState;
}

type PollKey = { type: string; key: string };

// getPollStateForType is a helper function for returning the poll state for a given type and key.
export function getPollStateForType(
  state: PollState,
  type: string,
  key: string
): Date | undefined {
  return state[type]?.[key];
}

const initialState: AppguardState = {
  pollState: {},
} satisfies AppguardState;

// The appguard slice is an RTK slice for managing appguard specific state.
// Currently, it only manages polling state.
const appguardSlice = createSlice({
  name: "appguard",
  reducerPath: "appguard",
  initialState,
  reducers: {
    // startPolling adds a key to the polling state.
    startPolling: (state, action: PayloadAction<PollKey>) => {
      if (!state.pollState[action.payload.type]) {
        state.pollState[action.payload.type] = {};
      }
      state.pollState[action.payload.type][action.payload.key] = new Date();
    },
    // stopPolling removes a key from the polling state.
    stopPolling: (state, action: PayloadAction<PollKey>) => {
      delete state.pollState[action.payload.type]?.[action.payload.key];
      if (Object.keys(state.pollState[action.payload.type]).length === 0) {
        delete state.pollState[action.payload.type];
      }
    },
  },
});

export const selectPollState = createSelector(
  [(state: RootState) => appguardSlice.selectSlice(state)],
  (slice: AppguardState) => slice.pollState
);
// selectPollingByType returns a selector that returns the keys for a given type.
// NOTE we're using createSelector here to handle memoisation.
export const selectPollingByType = createSelector(
  [
    (state: RootState) => appguardSlice.selectSlice(state),
    (_: RootState, type: string) => type,
  ],
  (slice: AppguardState, type: string) => {
    const typeState = slice.pollState[type];
    if (!typeState) {
      return [];
    }

    return Object.keys(typeState);
  }
);

export default appguardSlice;
