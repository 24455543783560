import { Component } from "react";
import PropTypes from "prop-types";

import ColorCheckbox from "../../../vendorrisk/components/ColorCheckbox";
import Button from "../../../_common/components/core/Button";
import { pickBy as _pickBy, keys as _keys } from "lodash";

import { closeModal } from "../../../_common/reducers/commonActions";

import { generateOrgApi } from "../../reducers/cyberRiskActions";
import * as Permissions from "../../../_common/permissions";

import "../../style/components/OrganisationAPIKeyModal.scss";
import {
  addDefaultSuccessAlert,
  addDefaultUnknownErrorAlert,
  addSimpleErrorAlert,
} from "../../../_common/reducers/messageAlerts.actions";
import TextField from "../../../_common/components/TextField";
import { appConnect } from "../../../_common/types/reduxHooks";

export const OrganisationAPIKeyModalName = "OrganisationAPIKeyModal";

class OrganisationAPIKeyModal extends Component {
  static propTypes = {
    modalData: PropTypes.shape({}).isRequired,
    dispatch: PropTypes.func.isRequired,
    orgPermissions: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    if (!props.modalData.isV2Layout) {
      throw new Error("OrganisationAPIKeyModal requires V2 modal");
    }

    this.state = {
      loading: false,
      name: "",
      permissionsMap: { Platform: true }, // platform is the default permission
      writeAccess: false,
    };
  }

  getAllowedPermissions() {
    const perms = [];
    if (this.props.orgPermissions[Permissions.OrgAccessBreachSight]) {
      perms.push("DataLeaks");
    }
    if (this.props.orgPermissions[Permissions.OrgAccessCustomerInfo]) {
      perms.push("BreachSight");
    }
    if (this.props.orgPermissions[Permissions.OrgAccessEmailExposures]) {
      perms.push("IdentityBreaches");
    }
    if (this.props.orgPermissions[Permissions.OrgAccessVendors]) {
      perms.push("VendorRisk");
    }
    perms.push("Admin");
    return perms;
  }

  submit = async () => {
    const { name, permissionsMap } = this.state;

    if (!name) {
      this.props.dispatch(
        addSimpleErrorAlert("Please enter a name for the key")
      );
      return;
    }

    let permissionsList = _keys(_pickBy(permissionsMap)).join(",");
    if (this.state.writeAccess) {
      permissionsList += ",ReadWrite";
    }

    this.setState({ loading: true });

    this.props
      .dispatch(generateOrgApi(name, permissionsList))
      .then(() =>
        this.props.dispatch(
          addDefaultSuccessAlert("New API key created", [name])
        )
      )
      .catch((e) => {
        console.error(e);
        this.props.dispatch(
          addDefaultUnknownErrorAlert("Error creating new API key")
        );
      });
    this.props.dispatch(closeModal());
    this.setState({ loading: false });
  };

  togglePermission(permission) {
    this.setState((state) => ({
      permissionsMap: {
        ...state.permissionsMap,
        [permission]: !state.permissionsMap[permission],
      },
    }));
  }

  render() {
    const allowedPermissions = this.getAllowedPermissions();
    return (
      <form>
        <div className={"modal-header"}>
          <h2>Create new API key</h2>
        </div>
        <div className="modal-content create-api-key-modal">
          <p>Enter a name for the API key:</p>
          <TextField
            required
            value={this.state.name}
            placeholder="API key name"
            onChanged={(v) => this.setState({ name: v })}
          />
          {allowedPermissions.length > 0 && (
            <>
              <div className="spacer" />
              <p>Select permissions for this key:</p>
              {allowedPermissions.map((permission) => (
                <div key={permission}>
                  <ColorCheckbox
                    label={permission}
                    checked={!!this.state.permissionsMap[permission]}
                    onClick={() => this.togglePermission(permission)}
                  />
                  <div className="spacer" />
                </div>
              ))}
            </>
          )}
          <div className={"spacer"} />
          <p>Allow write access for this key?</p>
          <ColorCheckbox
            label={"Allow write"}
            checked={this.state.writeAccess}
            onClick={() =>
              this.setState({ writeAccess: !this.state.writeAccess })
            }
          />
        </div>
        <div className={"modal-footer"}>
          <Button
            disabled={
              this.state.loading ||
              Object.values(this.state.permissionsMap).every((p) => !p) ||
              this.state.name === ""
            }
            loading={this.state.loading}
            primary
            onClick={this.submit}
          >
            Create API Key
          </Button>
        </div>
      </form>
    );
  }
}

export default appConnect((state) => {
  const orgPermissions = {};
  if (state.common.userData.orgPermissions) {
    for (const perm of state.common.userData.orgPermissions) {
      orgPermissions[perm] = true;
    }
  }

  return { orgPermissions };
})(OrganisationAPIKeyModal);
