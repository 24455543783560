import {
  GetAdditionalEvidenceEvidence,
  GetAssessmentWaiverText,
  GetSurveyEvidence,
  VendorAssessment,
  VendorAssessmentEvidence,
} from "../../types/vendorAssessments";
import { FC, ReactNode, useCallback, useState } from "react";
import ReportCard from "../../../_common/components/ReportCard";
import ColorCheckbox from "../ColorCheckbox";
import IconButton from "../../../_common/components/IconButton";
import "../../style/components/vendor_assessment/VendorAssessmentV3SelectEvidenceStep.scss";
import classNames from "classnames";
import { useHistory } from "react-router-dom";
import Button from "../../../_common/components/core/Button";
import VendorAssessmentV3SurveysTable from "./VendorAssessmentV3SurveysTable";
import VendorAssessmentV3AdditionalEvidenceTable from "./VendorAssessmentV3AdditionalEvidenceTable";
import VendorAssessmentV3PagesTable from "./VendorAssessmentV3PagesTable";
import { useModalV2 } from "../../../_common/components/ModalV2";
import SendSurveyModal from "../modals/SendSurveyModal";
import UploadAdditionalEvidenceModal from "../modals/UploadAdditionalEvidenceModal";
import { VendorAssessmentUpdate } from "../../views/VendorAssessmentV3";
import AddEvidencePageModal from "../modals/AddEvidencePageModal";
import { AssuranceType } from "../../../_common/types/organisations";
import {
  ISharedAssessment,
  SharedAssessmentAccessResult,
} from "../../types/sharedAssessment";
import VendorAssessmentAPI, {
  invalidateVendorAssessmentRisks,
} from "../../reducers/vendorAssessmentAPI";
import { useAppDispatch } from "../../../_common/types/reduxHooks";

interface SmallEvidenceCardProps {
  selected: boolean;
  toggleSelected: () => void;
  header: string;
  text: string;
  subText?: string;
  onClick: () => void;
  disabled?: boolean;
  disabledHoverText?: string;
  showChevron?: boolean;
  chevronLabel?: string;
  actionButton?: ReactNode;
}

const SmallEvidenceCard: FC<SmallEvidenceCardProps> = (props) => (
  <ReportCard
    newStyles
    className={classNames("evidence-card small", { selected: props.selected })}
  >
    <div className={"check-box"}>
      <ColorCheckbox
        disabled={props.disabled}
        checked={props.selected}
        onClick={props.toggleSelected}
        helpPopup={props.disabledHoverText}
        helpPopupPosition={"top"}
        helpPopupMicroFormat={true}
        helpPopupWidth={240}
      />
      <span className={"title"}>{props.header}</span>
    </div>
    <span className={"text"}>{props.text}</span>
    {props.showChevron && (
      <div className={"chevron-div"}>
        <span className={"chevron-label"}>{props.chevronLabel}</span>
        <IconButton
          onClick={props.onClick}
          icon={<i className={"cr-icon-chevron"} />}
        />
      </div>
    )}
    {props.actionButton}

    {props.subText && <div className={"sub-text"}>{props.subText}</div>}
  </ReportCard>
);

interface VendorAssessmentV3SelectEvidenceStepOwnProps {
  evidence: VendorAssessmentEvidence;
  assessment: VendorAssessment;
  isManagedAnalystSession?: boolean;
  managedOrgId?: number;
  vendorName: string;
  vendorId: number;
  updateAssessment: (update: VendorAssessmentUpdate) => void;
  goToRiskProfile: () => void;
  goToWaivers: () => void;
  goToSurvey: (id: number, isPublic: boolean) => void;
  goToAdditionalEvidence: (id: number, isPublic: boolean) => void;
  goToEvidencePage: (url: string, category: string) => void;
  goToPages: () => void;
  orgHasAdditionalEvidenceAccess: boolean;
  orgHasSurveyAccess: boolean;
  orgHasSurveyScoresEnabled: boolean;
  assuranceType: AssuranceType;
  sharedAssessment?: ISharedAssessment;
  setRequestAccessModalOpen?: (open: boolean) => void;
  setNDAModalOpen?: (open: boolean) => void;
  openDomainsModal: () => void;
  calculatingRisks: boolean;
}

type VendorAssessmentV3SelectEvidenceStepProps =
  VendorAssessmentV3SelectEvidenceStepOwnProps;

const VendorAssessmentV3SelectEvidenceStep: FC<
  VendorAssessmentV3SelectEvidenceStepProps
> = (props) => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  const [openSurveyModal, surveyModal] = useModalV2(SendSurveyModal);
  const [openAdditionalEvidenceModal, additionalEvidenceModal] = useModalV2(
    UploadAdditionalEvidenceModal
  );

  const [openEvidencePageModal, evidencePageModal] =
    useModalV2(AddEvidencePageModal);

  const [surveyUpdateRunning, setSurveyUpdateRunning] = useState(false);
  const [additionelEvidenceUpdateRunning, setAdditionalEvidenceUpdateRunning] =
    useState(false);
  const [evidencePagesUpdateRunning, setEvidencePagesUpdateRunning] =
    useState(false);

  const surveys = GetSurveyEvidence(
    props.evidence,
    !!props.assessment?.includeWaivers
  );

  const noSurveys = surveys.length == 0;
  const someSurveysSelected =
    surveys.length > 0 && surveys.some((s) => s.selected);
  const allSurveysSelected = surveys.every((s) => s.selected);

  const evidence = GetAdditionalEvidenceEvidence(
    props.evidence,
    !!props.assessment?.includeWaivers
  );

  const noEvidence = evidence.length == 0;
  const someEvidenceSelected =
    evidence.length > 0 && evidence.some((e) => e.selected);
  const allEvidenceSelected =
    evidence.length == evidence.filter((e) => e.selected).length;

  const pages = props.evidence.pages ?? [];
  const noTrust = pages.length == 0;
  const someTrustSelected = pages.length > 0 && pages.some((t) => t.selected);
  const allTrustSelected =
    pages.length == pages.filter((t) => t.selected).length;

  const waiverText = GetAssessmentWaiverText(
    props.evidence.numActiveRiskWaivers,
    props.evidence.numUnapprovedRiskWaivers,
    props.evidence.numPendingPublicRiskWaivers
  );

  const sharedAccessRequest = useCallback(() => {
    if (
      props.sharedAssessment?.orgLevelStatus ===
        SharedAssessmentAccessResult.MustRequest &&
      props.setRequestAccessModalOpen
    ) {
      return props.setRequestAccessModalOpen(true);
    } else if (
      props.sharedAssessment?.orgLevelStatus ===
        SharedAssessmentAccessResult.NdaRequired &&
      props.setNDAModalOpen
    ) {
      return props.setNDAModalOpen(true);
    }
    return undefined;
  }, [
    props.sharedAssessment,
    props.setNDAModalOpen,
    props.setRequestAccessModalOpen,
  ]);

  const [refreshEvidenceMutation] =
    VendorAssessmentAPI.useRefreshEvidenceForAssessmentMutation();
  const [toggleEvidenceTypeMutation] =
    VendorAssessmentAPI.useToggleAssessmentDraftEvidenceTypeMutation();
  const [toggleSurveyMutation] =
    VendorAssessmentAPI.useToggleAssessmentDraftSurveyMutation();
  const [toggleEvidenceMutation] =
    VendorAssessmentAPI.useToggleAssessmentDraftEvidenceMutation();
  const [togglePageMutation] =
    VendorAssessmentAPI.useToggleAssessmentDraftPageMutation();

  return (
    <div className={"vendor-assessment-v3-evidence-step"}>
      <SmallEvidenceCard
        selected={
          props.assessment.includeWebsiteRisks &&
          props.evidence.numScanningHosts > 0
        }
        disabled={props.evidence.numScanningHosts == 0}
        toggleSelected={() =>
          props.updateAssessment({
            type: "toggle_scanning",
          })
        }
        header={`Automated scanning (${
          (props.evidence.includedHostnames?.length ??
            props.evidence.numScanningHosts) + "/"
        }${props.evidence.numScanningHosts} domains and IPs)`}
        text={
          !props.calculatingRisks
            ? `${props.evidence.numScanningRisks} risks`
            : "Calculating risks"
        }
        subText={
          "Include risks identified through scanning of owned assets (domains and IP addresses)."
        }
        onClick={props.goToRiskProfile}
        disabledHoverText={
          props.evidence.numScanningHosts == 0
            ? "Add domains or IPs to include automated scanning evidence in your assessment"
            : ""
        }
        showChevron={false}
        actionButton={
          props.evidence.numScanningHosts > 0 ? (
            <Button onClick={props.openDomainsModal}>Change selection</Button>
          ) : undefined
        }
      />
      <SmallEvidenceCard
        selected={props.assessment.includeWaivers}
        disabled={false}
        toggleSelected={() =>
          props.updateAssessment({
            type: "toggle_waivers",
          })
        }
        header={"Risk modifications"}
        text={waiverText}
        subText={
          "Active waivers and adjustments will be accounted for in your final risk assessment score. You can add new modifications during the assessment."
        }
        onClick={props.goToWaivers}
        showChevron={true}
      />
      {props.orgHasSurveyAccess && (
        <ReportCard
          newStyles
          className={classNames("evidence-card", {
            selected: someSurveysSelected,
          })}
        >
          <div className={"evidence-header"}>
            <div className={"top"}>
              <div className={"check-box"}>
                <ColorCheckbox
                  checked={someSurveysSelected}
                  indeterminate={someSurveysSelected && !allSurveysSelected}
                  disabled={noSurveys || surveyUpdateRunning}
                  onClick={() => {
                    setSurveyUpdateRunning(true);
                    toggleEvidenceTypeMutation({
                      vendorID: props.assessment.datastoreVendorID,
                      assessmentID: props.assessment.id,
                      evidenceType: "questionnaires",
                      toggleState: !allSurveysSelected && !someSurveysSelected,
                    }).then(() => {
                      props.updateAssessment({
                        type: "toggle_evidence_type",
                        evidenceType: "questionnaires",
                      });
                      setSurveyUpdateRunning(false);
                    });
                  }}
                  helpPopup={
                    noSurveys
                      ? "Send a questionnaire to include it in your assessment"
                      : ""
                  }
                  helpPopupPosition={"top"}
                  helpPopupMicroFormat={true}
                  helpPopupWidth={240}
                />
                <span className={"title"}>
                  Questionnaires
                  {!noSurveys && (
                    <>
                      {" "}
                      ({surveys.filter((s) => s.selected).length}/
                      {surveys.length})
                    </>
                  )}
                </span>
              </div>
              <Button
                onClick={() =>
                  openSurveyModal({
                    history: history,
                    isManagementAnalystSession:
                      props.isManagedAnalystSession ?? false,
                    managedOrgId: props.managedOrgId,
                    refreshData: (surveyId: number) =>
                      refreshEvidenceMutation({
                        vendorID: props.assessment.datastoreVendorID,
                        versionID: props.assessment.id,
                      })
                        .unwrap()
                        .then(() =>
                          toggleSurveyMutation({
                            vendorID: props.assessment.datastoreVendorID,
                            assessmentID: props.assessment.id,
                            shared: false,
                            surveyID: surveyId,
                            state: true,
                          })
                        ),
                    vendorId: props.assessment.datastoreVendorID,
                    vendorName: props.vendorName,
                  })
                }
              >
                Send questionnaire
              </Button>
            </div>
            <div className={"sub-text"}>
              Questionnaires can help identify potential weaknesses in this
              vendor that aren&apos;t accessible through automated scanning.
            </div>
          </div>
          {surveys.length +
            (props.sharedAssessment?.questionnaires?.length ?? 0) >
            0 && (
            <VendorAssessmentV3SurveysTable
              selectable
              onClick={props.goToSurvey}
              surveys={surveys}
              onSelect={(id, shared) =>
                props.updateAssessment({
                  type: "toggle_survey",
                  id,
                  shared,
                })
              }
              sharedAssessment={props.sharedAssessment}
              sharedAccessRequest={sharedAccessRequest}
              orgHasSurveyScoresEnabled={props.orgHasSurveyScoresEnabled}
            />
          )}
        </ReportCard>
      )}
      {props.orgHasAdditionalEvidenceAccess && (
        <ReportCard
          newStyles
          className={classNames("evidence-card", {
            selected: someEvidenceSelected,
          })}
        >
          <div className={"evidence-header"}>
            <div className={"top"}>
              <div className={"check-box"}>
                <ColorCheckbox
                  checked={someEvidenceSelected}
                  indeterminate={someEvidenceSelected && !allEvidenceSelected}
                  disabled={noEvidence || additionelEvidenceUpdateRunning}
                  onClick={() => {
                    setAdditionalEvidenceUpdateRunning(true);
                    toggleEvidenceTypeMutation({
                      vendorID: props.assessment.datastoreVendorID,
                      assessmentID: props.assessment.id,
                      evidenceType: "additionalEvidence",
                      toggleState:
                        !allEvidenceSelected && !someEvidenceSelected,
                    }).then(() => {
                      props.updateAssessment({
                        type: "toggle_evidence_type",
                        evidenceType: "additionalEvidence",
                      });
                      setAdditionalEvidenceUpdateRunning(false);
                    });
                  }}
                  helpPopup={
                    noEvidence
                      ? "Add additional evidence to include it in your assessment"
                      : ""
                  }
                  helpPopupPosition={"top"}
                  helpPopupMicroFormat={true}
                  helpPopupWidth={240}
                />
                <span className={"title"}>
                  Additional evidence
                  {!noEvidence && (
                    <>
                      {" "}
                      ({evidence.filter((e) => e.selected).length}/
                      {evidence.length})
                    </>
                  )}
                </span>
              </div>
              <Button
                onClick={() =>
                  openAdditionalEvidenceModal({
                    refreshData: async (id) =>
                      refreshEvidenceMutation({
                        vendorID: props.assessment.datastoreVendorID,
                        versionID: props.assessment.id,
                      })
                        .then(() =>
                          toggleEvidenceMutation({
                            vendorID: props.assessment.datastoreVendorID,
                            assessmentID: props.assessment.id,
                            evidenceID: id,
                            shared: false,
                            state: true,
                          })
                        )
                        .then(() =>
                          dispatch(
                            invalidateVendorAssessmentRisks(props.assessment.id)
                          )
                        ),
                    vendorID: props.assessment.datastoreVendorID,
                    managedOrgId: props.managedOrgId,
                    isManagementAnalystSession:
                      props.isManagedAnalystSession ?? false,
                  })
                }
              >
                Upload additional evidence
              </Button>
            </div>
            <div className={"sub-text"}>
              Upload documents like audit reports or security questionnaires to
              capture identified risks to use in your risk assessment.
            </div>
          </div>
          {evidence.length +
            (props.sharedAssessment?.contentLibraryDocuments ?? []).filter(
              (doc) => !doc.archived
            ).length >
            0 && (
            <VendorAssessmentV3AdditionalEvidenceTable
              selectable
              onSelect={(id, shared) =>
                props.updateAssessment({
                  type: "toggle_evidence",
                  id,
                  shared,
                })
              }
              onClick={props.goToAdditionalEvidence}
              evidence={evidence}
              sharedAssessment={props.sharedAssessment}
              sharedAccessRequest={sharedAccessRequest}
            />
          )}
        </ReportCard>
      )}
      {props.orgHasAdditionalEvidenceAccess && (
        <ReportCard
          newStyles
          className={classNames("evidence-card", {
            selected: someTrustSelected,
          })}
        >
          <div className={"evidence-header"}>
            <div className={"top"}>
              <div className={"check-box"}>
                <ColorCheckbox
                  checked={someTrustSelected}
                  indeterminate={someTrustSelected && !allTrustSelected}
                  disabled={noTrust || evidencePagesUpdateRunning}
                  onClick={() => {
                    setEvidencePagesUpdateRunning(true);
                    toggleEvidenceTypeMutation({
                      vendorID: props.assessment.datastoreVendorID,
                      assessmentID: props.assessment.id,
                      evidenceType: "evidencePages",
                      toggleState: !allTrustSelected && !someTrustSelected,
                    }).then(() => {
                      props.updateAssessment({
                        type: "toggle_evidence_type",
                        evidenceType: "evidencePages",
                      });
                      setEvidencePagesUpdateRunning(false);
                    });
                  }}
                  helpPopup={
                    noTrust
                      ? "Add a security and privacy page to include it in your assessment"
                      : ""
                  }
                  helpPopupPosition={"top"}
                  helpPopupMicroFormat={true}
                  helpPopupWidth={240}
                />
                <span className={"title"}>
                  Security and Privacy Pages
                  {!noTrust && (
                    <>
                      {" "}
                      ({pages.filter((p) => p.selected).length}/{pages.length})
                    </>
                  )}
                </span>
              </div>
              <Button
                onClick={() =>
                  openEvidencePageModal({
                    assuranceType: props.assuranceType,
                    vendorId: props.vendorId,
                    evidencePages: props.evidence.pages,
                    dispatch: dispatch,
                    onAddSuccess: (category, url) =>
                      refreshEvidenceMutation({
                        vendorID: props.assessment.datastoreVendorID,
                        versionID: props.assessment.id,
                      }).then(() =>
                        togglePageMutation({
                          vendorID: props.assessment.datastoreVendorID,
                          assessmentID: props.assessment.id,
                          category,
                          url,
                          state: true,
                        })
                      ),
                  })
                }
              >
                Add security and privacy page
              </Button>
            </div>
            <div className={"sub-text"}>
              Security and privacy pages can be used to assess publicly
              available security information to help with your risk assessment.
            </div>
          </div>
          {!noTrust && pages && (
            <VendorAssessmentV3PagesTable
              selectable
              onClick={props.goToEvidencePage}
              onSelect={(category: string, url: string) =>
                props.updateAssessment({
                  type: "toggle_page",
                  url,
                  category,
                })
              }
              pages={pages}
            />
          )}
        </ReportCard>
      )}
      {props.orgHasSurveyAccess && surveyModal}
      {props.orgHasAdditionalEvidenceAccess && additionalEvidenceModal}
      {props.orgHasAdditionalEvidenceAccess && evidencePageModal}
    </div>
  );
};

export default VendorAssessmentV3SelectEvidenceStep;
