import { DropzoneInputProps, DropzoneRootProps } from "react-dropzone";
import { FC, PropsWithChildren } from "react";
import classNames from "classnames";

import "../style/components/FileDropzone.scss";
import LoadingBanner from "./core/LoadingBanner";

type IFileDropzoneProps = PropsWithChildren<{
  className?: string;
  dropFileMessage?: string;
  uploading?: boolean;
  getRootProps(props?: DropzoneRootProps): DropzoneRootProps;
  getInputProps(props?: DropzoneInputProps): DropzoneInputProps;
  isDragActive: boolean;
}>;

const FileDropzone: FC<IFileDropzoneProps> = ({
  className,
  dropFileMessage = "Drop file to upload",
  getRootProps,
  getInputProps,
  isDragActive,
  uploading = false,
  children,
}) => {
  return (
    <div
      className={classNames("file-dropzone", className, {
        visible: isDragActive || uploading,
        uploading,
      })}
      {...getRootProps()}
    >
      {children}

      <div className="dropzone-overlay">
        {uploading ? (
          <LoadingBanner tight />
        ) : (
          <div className="drop-files-msg">{dropFileMessage}</div>
        )}
      </div>
      <input {...getInputProps()} />
    </div>
  );
};

export default FileDropzone;
