import {
  ContentLibraryDocument,
  ContentLibraryDocumentHistoryItem,
  DocumentType,
  GetDocumentsFilter,
  GetDocumentsSortBy,
} from "../types/contentLibrary.types";
import { IUserMiniMap } from "../../_common/types/user";
import BaseAPI, { QueryArgs } from "../../_common/rtkQueryApi";
import { fetchOwnSharedAssessment } from "../../vendorrisk/reducers/cyberRiskActions";
import SurveyViewerAPI, {
  GptSourceListTag,
} from "../../survey_viewer/reducers/SurveyViewerAPI";

export const contentLibraryDocumentTypesCacheTag =
  "ContentLibraryDocumentTypes" as const;
export const contentLibraryDocumentCacheTag = "ContentLibraryDocument" as const;
export const contentLibraryDocumentHistoryCacheTag =
  "ContentLibraryDocumentHistory" as const;
export const contentLibraryOnboardingStatusCacheTag =
  "ContentLibraryOnboardingStatus" as const;

export const contentLibraryPartialListID = "PARTIAL-LIST";

export interface getDocumentTypesListV1Resp {
  types: DocumentType[];
}

interface getDocumentsListV1Req {
  sortBy: GetDocumentsSortBy;
  filterBy: GetDocumentsFilter;
  limit: number;
  offset: number;
}

export interface getDocumentsListV1Resp {
  documents: ContentLibraryDocument[];
  sharedUsers: IUserMiniMap;
  totalResults: number;
}

interface getDocumentSingleV1Req {
  uuid: string;
}

interface getDocumentSingleV1Resp {
  document: ContentLibraryDocument;
  sharedUsers: IUserMiniMap;
}

interface getDocumentHistoryV1Req {
  uuid: string;
}

interface getDocumentHistoryV1Resp {
  history: ContentLibraryDocumentHistoryItem[];
  sharedUsers: IUserMiniMap;
  accessibleSurveyIDs: Record<number, true | undefined>;
}

interface createDocumentV1Req {
  name: string;
  description?: string;
  type: string;
  file: File;
}

export interface createDocumentV1Resp {
  document: ContentLibraryDocument;
}

export interface updateDocumentV1Req {
  uuid: string;
  name?: string;
  description?: string;
  internalNotes?: string;
  documentType?: string;
  expiresAt?: string;
  clearExpiresAt?: boolean;
}

interface uploadDocumentVersionV1Req {
  uuid: string;
  file: File;
}

interface archiveDocumentsV1Req {
  uuids: string[];
  setArchived: boolean;
}

interface deleteDocumentsV1Req {
  uuids: string[];
}

export interface surveyAttachmentToMigrate {
  surveyAttachmentID: number;
  surveyAttachmentName: string;
  gcsObjectName: string;
  filename: string;
  surveyID: number;
  surveyName: string;
  usedAt: string;
}

interface getOnboardingStatusV1Resp {
  introModalCompleted: boolean;
  sharedProfileDocsMessage: {
    dismissed: boolean;
    numDocs: number;
  };
  docMigration: {
    status: "notstarted" | "dismissed" | "inprogress" | "completed";
    jobProgress: number;
    surveyAttachmentsToMigrate?: surveyAttachmentToMigrate[];
  };
}

export interface startOnboardingSurveyDocumentMigrationV1Req {
  docsToMigrate: {
    surveyAttachmentID: number;
    newDocumentName: string;
    newDocumentType: string;
  }[];
}

const ContentLibraryAPI = BaseAPI
  // Add our Content Library cache tag types to the BaseAPI definition
  .enhanceEndpoints({
    addTagTypes: [
      contentLibraryDocumentTypesCacheTag,
      contentLibraryDocumentCacheTag,
      contentLibraryDocumentHistoryCacheTag,
      contentLibraryOnboardingStatusCacheTag,
    ],
  })
  // Add all our Content Library endpoints
  .injectEndpoints({
    endpoints: (builder) => ({
      // getContentLibraryDocumentTypesList gets a list of all custom and default document types
      // available to an org.
      getContentLibraryDocumentTypesList: builder.query<
        getDocumentTypesListV1Resp,
        void
      >({
        query: () => ({
          url: "/contentlibrary/types/v1",
          method: "GET",
        }),
        providesTags: [contentLibraryDocumentTypesCacheTag],
      }),

      // createContentLibraryDocumentType creates a new custom document type.
      createContentLibraryDocumentType: builder.mutation<
        void,
        {
          name: string;
        }
      >({
        query: ({ name }) => ({
          url: "/contentlibrary/types/v1",
          method: "POST",
          params: {
            name,
          },
        }),
        onQueryStarted({ name }, { dispatch, queryFulfilled }) {
          const patchResult = dispatch(
            ContentLibraryAPI.util.updateQueryData(
              "getContentLibraryDocumentTypesList",
              undefined,
              (draft) => {
                draft.types.push({ name, isCustom: true, numDocuments: 0 });
              }
            )
          );

          // If the query fails, roll back our patch
          queryFulfilled.catch(patchResult.undo);
        },
        invalidatesTags: [contentLibraryDocumentTypesCacheTag],
      }),

      // multiUpdateContentLibraryDocumentTypes allows doing multiple updates to document types
      // in a single batch, before invalidating the cache.
      multiUpdateContentLibraryDocumentTypes: builder.mutation<
        null,
        {
          deletedDocumentTypeNames: string[];
          updatedDocumentTypes: {
            newTypeName: string;
            originalTypeName: string;
          }[];
          createdDocumentTypeNames: string[];
        }
      >({
        queryFn: async (
          {
            deletedDocumentTypeNames,
            updatedDocumentTypes,
            createdDocumentTypeNames,
          },
          _api,
          _extra,
          baseQuery
        ) => {
          try {
            // First delete stuff
            for (let i = 0; i < deletedDocumentTypeNames.length; i++) {
              await baseQuery({
                url: "/contentlibrary/types/v1",
                method: "DELETE",
                params: {
                  name: deletedDocumentTypeNames[i],
                },
              });
            }

            // Update documents before creating new ones in case of name conflicts
            for (let i = 0; i < updatedDocumentTypes.length; i++) {
              await baseQuery({
                url: "/contentlibrary/types/v1",
                method: "PUT",
                params: {
                  name: updatedDocumentTypes[i].originalTypeName,
                  new_name: updatedDocumentTypes[i].newTypeName,
                },
              });
            }

            for (let i = 0; i < createdDocumentTypeNames.length; i++) {
              await baseQuery({
                url: "/contentlibrary/types/v1",
                method: "POST",
                params: {
                  name: createdDocumentTypeNames[i],
                },
              });
            }

            return { data: null };
          } catch (e) {
            return {
              error: {
                message: (e as any).message,
              },
            };
          }
        },
        invalidatesTags: [
          contentLibraryDocumentTypesCacheTag,
          contentLibraryDocumentCacheTag,
        ],
      }),

      // getContentLibraryDocumentsList retrieves a list of all documents a user has access to.
      getContentLibraryDocumentsList: builder.query<
        getDocumentsListV1Resp,
        getDocumentsListV1Req
      >({
        query: ({ sortBy, filterBy, limit, offset }) => ({
          url: "/contentlibrary/list/v1",
          method: "GET",
          params: {
            sort_by: sortBy.col,
            sort_desc: sortBy.desc,
            filter_name: filterBy.name,
            filter_search_query: filterBy.searchQuery,
            filter_document_types: filterBy.documentTypes,
            filter_archived: filterBy.archived,
            filter_shared_profile: filterBy.sharedProfileOnly,
            filter_md5: filterBy.md5,
            limit: limit,
            offset: offset,
          },
        }),
        // Provide a tag for each document in the current page,
        // as well as the 'PARTIAL-LIST' tag.
        // This allows us to invalidate any cached list queries when we add or delete documents from the library.
        // See https://redux-toolkit.js.org/rtk-query/usage/pagination#automated-re-fetching-of-paginated-queries
        providesTags: (result) =>
          result
            ? [
                ...result.documents.map(({ uuid: id }) => ({
                  type: contentLibraryDocumentCacheTag,
                  id,
                })),
                {
                  type: contentLibraryDocumentCacheTag,
                  id: contentLibraryPartialListID,
                },
              ]
            : [
                {
                  type: contentLibraryDocumentCacheTag,
                  id: contentLibraryPartialListID,
                },
              ],
      }),

      // getContentLibraryDocument retrieves a specific document by UUID.
      getContentLibraryDocument: builder.query<
        getDocumentSingleV1Resp,
        getDocumentSingleV1Req
      >({
        query: ({ uuid }) => ({
          url: "/contentlibrary/single/v1",
          method: "GET",
          params: {
            uuid,
          },
        }),
        providesTags: (result, _, { uuid }) =>
          result
            ? [
                {
                  type: contentLibraryDocumentCacheTag,
                  id: uuid,
                },
              ]
            : [],
      }),

      // getContentLibraryDocumentHistory gets the history for a specific document by UUID.
      getContentLibraryDocumentHistory: builder.query<
        getDocumentHistoryV1Resp,
        getDocumentHistoryV1Req
      >({
        query: ({ uuid }) => ({
          url: "/contentlibrary/history/v1",
          method: "GET",
          params: {
            uuid,
          },
        }),
        providesTags: (result, _, { uuid }) =>
          result
            ? [
                {
                  type: contentLibraryDocumentHistoryCacheTag,
                  id: uuid,
                },
              ]
            : [],
      }),

      // createContentLibraryDocument creates a new document in the content library, including uploading
      // the first version as an attachment.
      createContentLibraryDocument: builder.mutation<
        createDocumentV1Resp,
        createDocumentV1Req
      >({
        query: ({ name, description, type, file }) => ({
          url: "/contentlibrary/create/v1",
          method: "POST",
          params: {
            document_name: name,
            document_type: type,
            document_description: description,
          },
          file,
        }),
        invalidatesTags: [
          // Invalidate any list views as the new document could move other documents through pages
          {
            type: contentLibraryDocumentCacheTag,
            id: contentLibraryPartialListID,
          },
          // Invalidate the types list as the numDocuments field will change
          contentLibraryDocumentTypesCacheTag,
        ],
      }),

      // updateContentLibraryDocument updates a series of fields on a document.
      // Only non-undefined fields are updated.
      updateContentLibraryDocument: builder.mutation<void, updateDocumentV1Req>(
        {
          query: (req) => ({
            url: "/contentlibrary/update/v1",
            method: "PUT",
            body: JSON.stringify(req),
          }),
          onQueryStarted(req, { dispatch, queryFulfilled }) {
            // Optimistically update properties of the document
            let documentIsInSharedProfile = false;
            const patchResult = dispatch(
              ContentLibraryAPI.util.updateQueryData(
                "getContentLibraryDocument",
                { uuid: req.uuid },
                (draft) => {
                  if (typeof req.name !== "undefined") {
                    draft.document.name = req.name;
                  }
                  if (typeof req.description !== "undefined") {
                    draft.document.description = req.description;
                  }
                  if (typeof req.internalNotes !== "undefined") {
                    draft.document.internalNotes = req.internalNotes;
                  }
                  if (typeof req.documentType !== "undefined") {
                    draft.document.documentType = req.documentType;
                  }

                  if (req.clearExpiresAt) {
                    draft.document.expiresAt = undefined;
                  } else if (req.expiresAt) {
                    draft.document.expiresAt = req.expiresAt;
                  }

                  if (draft.document.includeInSharedProfile) {
                    documentIsInSharedProfile = true;
                  }
                }
              )
            );

            // If the query fails, roll back our patch
            queryFulfilled
              .catch(patchResult.undo)
              // Refresh the shared profile if necessary
              .then(() => {
                if (documentIsInSharedProfile) {
                  dispatch(fetchOwnSharedAssessment(true, true));
                }
              });
          },
          invalidatesTags: (_, err, { uuid, documentType }) =>
            !err
              ? [
                  {
                    type: contentLibraryDocumentCacheTag,
                    id: uuid,
                  },
                  // Invalidate any cached history for this UUID as they'll have a new entry now
                  {
                    type: contentLibraryDocumentHistoryCacheTag,
                    id: uuid,
                  },
                  // Invalidate any cached lists
                  {
                    type: contentLibraryDocumentCacheTag,
                    id: contentLibraryPartialListID,
                  },
                  // Invalidate the types list if the document type changed as the numDocuments field will change
                  ...(documentType
                    ? [
                        {
                          type: contentLibraryDocumentTypesCacheTag,
                        },
                      ]
                    : []),
                ]
              : [],
        }
      ),

      // updateContentLibraryDocumentSharedProfile updates the includeInSharedProfile flag on a document.
      updateContentLibraryDocumentSharedProfile: builder.mutation<
        void,
        {
          uuid: string;
          includeInSharedProfile: boolean;
        }
      >({
        query: ({ uuid, includeInSharedProfile }) => ({
          url: "/contentlibrary/sharedprofile/v1",
          method: "PUT",
          params: {
            uuid,
            include_in_shared_profile: includeInSharedProfile,
          },
        }),
        onQueryStarted: (req, { dispatch, queryFulfilled }) => {
          // Optimistically update properties of the document
          const patchResult = dispatch(
            ContentLibraryAPI.util.updateQueryData(
              "getContentLibraryDocument",
              { uuid: req.uuid },
              (draft) => {
                draft.document.includeInSharedProfile =
                  req.includeInSharedProfile;
              }
            )
          );

          queryFulfilled
            // If the query fails, roll back our patch
            .catch(patchResult.undo)
            // Refresh the shared profile
            .then(() => dispatch(fetchOwnSharedAssessment(true, true)));
        },
        invalidatesTags: (_, err, { uuid }) =>
          !err
            ? [
                {
                  type: contentLibraryDocumentCacheTag,
                  id: uuid,
                },
                // Invalidate any cached history for this UUID as they'll have a new entry now
                {
                  type: contentLibraryDocumentHistoryCacheTag,
                  id: uuid,
                },
                // Invalidate any cached lists
                {
                  type: contentLibraryDocumentCacheTag,
                  id: contentLibraryPartialListID,
                },
              ]
            : [],
      }),

      // uploadContentLibraryDocumentVersion uploads an attachment as a new version to a document
      uploadContentLibraryDocumentVersion: builder.mutation<
        void,
        uploadDocumentVersionV1Req
      >({
        query: (req) => ({
          url: "/contentlibrary/uploadversion/v1",
          method: "POST",
          params: {
            uuid: req.uuid,
          },
          file: req.file,
        }),
        invalidatesTags: (_, err, { uuid }) =>
          !err
            ? [
                // Invalidate the document itself and its history
                {
                  type: contentLibraryDocumentCacheTag,
                  id: uuid,
                },
                {
                  type: contentLibraryDocumentHistoryCacheTag,
                  id: uuid,
                },
                // Invalidate any cached lists
                {
                  type: contentLibraryDocumentCacheTag,
                  id: contentLibraryPartialListID,
                },
              ]
            : [],
      }),

      // archiveContentLibraryDocuments archives/unarchives a set of documents in the content library
      archiveContentLibraryDocuments: builder.mutation<
        void,
        archiveDocumentsV1Req
      >({
        query: ({ uuids, setArchived }) => ({
          url: "/contentlibrary/archive/v1",
          method: "PUT",
          params: {
            uuids,
            set_archived: setArchived,
          },
        }),
        onQueryStarted(
          { uuids, setArchived },
          { dispatch, queryFulfilled }
        ): Promise<void> | void {
          // Optimistically update any cached single documents
          let shouldRefreshSharedProfile = false;
          const patchResults = [
            ...uuids.map((uuid) =>
              dispatch(
                ContentLibraryAPI.util.updateQueryData(
                  "getContentLibraryDocument",
                  { uuid },
                  (draft) => {
                    draft.document.archived = setArchived;

                    if (setArchived && draft.document.includeInSharedProfile) {
                      shouldRefreshSharedProfile = true;
                      draft.document.includeInSharedProfile = false;
                    }
                  }
                )
              )
            ),
          ];

          // If the query fails, roll back our patches
          queryFulfilled
            .catch(() =>
              patchResults.forEach((patchResult) => patchResult.undo())
            )
            .then(() => {
              if (shouldRefreshSharedProfile) {
                dispatch(fetchOwnSharedAssessment(true, true));
              }
            });
        },
        invalidatesTags: (_, err, { uuids }) =>
          !err
            ? [
                // We do not invalidate individual document cache tags as we're mutating those optimistically.
                // Invalidate any cached history for these UUIDs as they'll have a new entry now
                ...uuids.map((uuid) => ({
                  type: contentLibraryDocumentHistoryCacheTag,
                  id: uuid,
                })),
                // Invalidate any cached lists as filtered results will change
                {
                  type: contentLibraryDocumentCacheTag,
                  id: contentLibraryPartialListID,
                },
              ]
            : [],
      }),

      deleteContentLibraryDocuments: builder.mutation<
        void,
        deleteDocumentsV1Req
      >({
        query: ({ uuids }) => ({
          url: "/contentlibrary/delete/v1",
          method: "DELETE",
          params: {
            uuids,
          },
        }),
        invalidatesTags: (_, err, { uuids }) =>
          !err
            ? [
                ...uuids.map((uuid) => ({
                  type: contentLibraryDocumentCacheTag,
                  id: uuid,
                })),
                ...uuids.map((uuid) => ({
                  type: contentLibraryDocumentHistoryCacheTag,
                  id: uuid,
                })),
                // Invalidate any cached lists as filtered results will change
                {
                  type: contentLibraryDocumentCacheTag,
                  id: contentLibraryPartialListID,
                },
              ]
            : [],
        onQueryStarted: (_args, { dispatch, queryFulfilled }) => {
          queryFulfilled.then(() =>
            dispatch(
              SurveyViewerAPI.util.invalidateTags([{ type: GptSourceListTag }])
            )
          );
        },
      }),

      // getContentLibraryOnboardingStatus gets the status of onboarding tasks and modals for the content library
      getContentLibraryOnboardingStatus: builder.query<
        getOnboardingStatusV1Resp,
        void
      >({
        query: () => ({
          url: "/contentlibrary/onboarding/v1",
          method: "GET",
        }),
        providesTags: [contentLibraryOnboardingStatusCacheTag],
      }),

      // setContentLibraryOnboardingStatus sets the status of onboarding tasks and modals for the content library
      setContentLibraryOnboardingStatus: builder.mutation<
        void,
        {
          dismissIntroModal?: true;
          dismissSharedProfileDocs?: true;
          dismissDocMigration?: true;
        }
      >({
        query: ({
          dismissIntroModal,
          dismissSharedProfileDocs,
          dismissDocMigration,
        }) => {
          const params: QueryArgs["params"] = {};
          if (dismissIntroModal) {
            params["dismiss_intro_modal"] = true;
          }
          if (dismissSharedProfileDocs) {
            params["dismiss_shared_profile_docs"] = true;
          }
          if (dismissDocMigration) {
            params["dismiss_doc_migration"] = true;
          }

          return {
            url: "/contentlibrary/onboarding/v1",
            method: "PUT",
            params,
          };
        },
        onQueryStarted: (
          { dismissIntroModal, dismissSharedProfileDocs, dismissDocMigration },
          { dispatch, queryFulfilled }
        ) => {
          const patchResult = dispatch(
            ContentLibraryAPI.util.updateQueryData(
              "getContentLibraryOnboardingStatus",
              undefined,
              (draft) => {
                if (dismissIntroModal) {
                  draft.introModalCompleted = true;
                }
                if (dismissSharedProfileDocs) {
                  draft.sharedProfileDocsMessage.dismissed = true;
                }
                if (dismissDocMigration) {
                  draft.docMigration.status = "dismissed";
                }
              }
            )
          );

          queryFulfilled.catch(patchResult.undo);
        },
        invalidatesTags: [contentLibraryOnboardingStatusCacheTag],
      }),

      // startContentLibrarySurveyDocumentMigration begins a document migration job for a set of survey attachments
      startContentLibrarySurveyDocumentMigration: builder.mutation<
        void,
        startOnboardingSurveyDocumentMigrationV1Req
      >({
        query: (req) => ({
          url: "/contentlibrary/onboarding/migration/v1",
          method: "POST",
          body: JSON.stringify(req),
        }),
        onQueryStarted: (_req, { dispatch, queryFulfilled }) => {
          // Set the migration to in progress immediately
          const patchResult = dispatch(
            ContentLibraryAPI.util.updateQueryData(
              "getContentLibraryOnboardingStatus",
              undefined,
              (draft) => {
                draft.docMigration.status = "inprogress";
              }
            )
          );

          queryFulfilled.catch(patchResult.undo);
        },
        invalidatesTags: [contentLibraryOnboardingStatusCacheTag],
      }),
    }),
  });

export default ContentLibraryAPI;
