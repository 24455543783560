import { DefaultThunkDispatchProp } from "../../_common/types/redux";
import { ILabel, LabelClassification } from "../../_common/types/label";
import { FC, useCallback, useMemo } from "react";

import ActionBar from "../../_common/components/ActionBar";
import Button from "../../_common/components/core/Button";
import UpdateLabelsModal from "./labels/UpdateLabelsModal";
import { OrgAccessDomainPortfolios } from "../../_common/permissions";
import { setLabelsForWebsites } from "../reducers/domainLabels.actions";
import { Domain } from "../../_common/types/domains";
import "../style/components/DomainIPActionBar.scss";
import { useModalV2 } from "../../_common/components/ModalV2";
import DomainIPPortfolioSelectionModal, {
  DomainsOrIPs,
} from "./portfolios/DomainIPPortfolioSelectionModal";
import { IPAddress, IPRange, IPSource } from "../../_common/types/ipAddresses";
import {
  IpRangeToUpdate,
  setIpAddressAndIpAddressDetailState,
  setIpAddressesAndRangesState,
  updateIpLabels,
} from "../reducers/ipLabels.actions";
import { adjustLabels } from "../reducers/domains.actions";
import { portfolioUpdateItemIDForIPRange } from "../reducers/portfolios.actions";
import { SidePopupV2 } from "../../_common/components/DismissablePopup";
import { appConnect } from "../../_common/types/reduxHooks";

interface IDomainIPActionBarOwnProps {
  vendorId?: number;
  isSubsidiary?: boolean;
  selectedDomains?: Domain[];
  selectedIPAddresses?: IPAddress[];
  selectedIPRanges?: IPRange[];
  allIPRanges?: IPRange[]; // Required for updating state when IP ranges are used
  onCancel: () => void;
  onDeselectAll: () => void;
}

interface IDomainIPActionBarConnectedProps {
  orgHasDomainPortfoliosEnabled: boolean;
}

type IDomainIPActionBarProps = IDomainIPActionBarOwnProps &
  IDomainIPActionBarConnectedProps &
  DefaultThunkDispatchProp;

const DomainIPActionBarInner: FC<IDomainIPActionBarProps> = ({
  dispatch,
  vendorId,
  isSubsidiary,
  selectedDomains,
  selectedIPAddresses,
  selectedIPRanges,
  allIPRanges,
  onCancel,
  onDeselectAll,
  orgHasDomainPortfoliosEnabled,
}) => {
  const isCustomer = !vendorId && !isSubsidiary;
  let canEditPortfolios = isCustomer && orgHasDomainPortfoliosEnabled;

  if (selectedIPAddresses || selectedIPRanges) {
    // For now, we do not support setting portfolios for IP addresses or ranges. This may change in future.
    canEditPortfolios = false;
  }

  const [openLabelsModal, labelsModal] = useModalV2(UpdateLabelsModal);

  const onUpdateLabels = useCallback(
    async (
      allLabels: ILabel[],
      addedLabelIds: number[],
      removedLabelIds: number[]
    ) => {
      if (selectedDomains) {
        await dispatch(
          setLabelsForWebsites(
            selectedDomains.map((i) => i.hostname),
            addedLabelIds,
            removedLabelIds,
            vendorId,
            isSubsidiary
          )
        );
      } else if (selectedIPAddresses || selectedIPRanges) {
        const ipRangesToUpdate: IpRangeToUpdate[] = [];
        if (selectedIPAddresses) {
          ipRangesToUpdate.push(
            ...selectedIPAddresses.map((ip) => ({
              start: ip.ip,
              end: ip.ip,
            }))
          );
        } else if (selectedIPRanges) {
          ipRangesToUpdate.push(
            ...selectedIPRanges.map((ip) => ({
              start: ip.start,
              end: ip.end,
            }))
          );
        }

        await dispatch(
          updateIpLabels({
            labelsToAdd: addedLabelIds,
            labelsToRemove: removedLabelIds,
            ipRanges: ipRangesToUpdate,
            datastoreVendorId: vendorId,
            isSubsidiary: isSubsidiary,
          })
        );

        if (selectedIPAddresses) {
          const newIpAddresses = adjustLabels(
            selectedIPAddresses,
            () => true,
            allLabels,
            addedLabelIds,
            removedLabelIds
          );

          // Update both the ip address state and the ip address details state objects for each change
          newIpAddresses.forEach((ip) =>
            dispatch(
              setIpAddressAndIpAddressDetailState(ip, vendorId, isSubsidiary)
            )
          );
        } else if (selectedIPRanges && allIPRanges) {
          const newRanges = adjustLabels(
            allIPRanges,
            (i) =>
              selectedIPRanges.find(
                (j) => j.start === i.start && j.end === i.end
              ) !== undefined,
            allLabels,
            addedLabelIds,
            removedLabelIds
          );

          dispatch(
            setIpAddressesAndRangesState(
              vendorId,
              isSubsidiary,
              newRanges,
              undefined
            )
          );
        }
      }

      onDeselectAll();
    },
    [
      dispatch,
      selectedDomains,
      selectedIPAddresses,
      selectedIPRanges,
      allIPRanges,
      vendorId,
      isSubsidiary,
      onDeselectAll,
    ]
  );

  const dnsSourcedIPsSelected = useMemo(
    () =>
      !!selectedIPAddresses?.find((ip) => ip.sources.includes(IPSource.DNS)),
    [selectedIPAddresses]
  );

  let numItems = 0;
  let itemName = "";
  let itemPlural = "";
  let singleItem = "";

  if (selectedDomains) {
    numItems = selectedDomains.length;
    itemName = "domain";
    itemPlural = "domains";
    singleItem =
      selectedDomains.length === 1 ? selectedDomains[0].hostname : "";
  } else if (selectedIPAddresses) {
    numItems = selectedIPAddresses.length;
    itemName = "IP address";
    itemPlural = "IP addresses";
    singleItem =
      selectedIPAddresses.length === 1 ? selectedIPAddresses[0].ip : "";
  } else if (selectedIPRanges) {
    numItems = selectedIPRanges.length;
    itemName = "IP range";
    itemPlural = "IP ranges";
    singleItem =
      selectedIPRanges.length === 1
        ? selectedIPRanges[0].start + " -> " + selectedIPRanges[0].end
        : "";
  }

  const labelsModalHeader = `Update labels for ${
    singleItem ? singleItem : `${numItems} ${itemPlural}`
  }`;

  const onOpenLabelsModal = useCallback(
    () =>
      openLabelsModal({
        classification: LabelClassification.WebsiteLabel,
        selectedItems:
          selectedDomains ?? selectedIPAddresses ?? selectedIPRanges ?? [],
        onUpdate: onUpdateLabels,
        header: labelsModalHeader,
      }),
    [
      openLabelsModal,
      selectedDomains,
      selectedIPAddresses,
      selectedIPRanges,
      onUpdateLabels,
      labelsModalHeader,
    ]
  );

  const [openPortfoliosModal, portfoliosModal] = useModalV2(
    DomainIPPortfolioSelectionModal
  );

  const onOpenPortfoliosModal = useCallback(
    () =>
      openPortfoliosModal({
        selectedItems: selectedDomains
          ? selectedDomains.map((d) => ({
              id: d.hostname,
              name: d.hostname,
              portfolios: d.portfolios,
            }))
          : selectedIPAddresses
            ? selectedIPAddresses.map((ip) => ({
                id: portfolioUpdateItemIDForIPRange({
                  start: ip.ip,
                  end: ip.ip,
                }),
                name: ip.ip,
                portfolios: ip.portfolios,
              }))
            : selectedIPRanges
              ? selectedIPRanges.map((ipRange) => ({
                  id: portfolioUpdateItemIDForIPRange({
                    start: ipRange.start,
                    end: ipRange.end,
                  }),
                  name: `${ipRange.start} -> ${ipRange.end}`,
                  portfolios: ipRange.portfolios,
                }))
              : [],
        onClearSelectedItems: onDeselectAll,
        domainsOrIPs: selectedDomains ? DomainsOrIPs.Domains : DomainsOrIPs.IPs,
      }),
    [
      selectedDomains,
      selectedIPAddresses,
      selectedIPRanges,
      onDeselectAll,
      openPortfoliosModal,
    ]
  );

  return (
    <ActionBar active={numItems > 0} className="domain-ip-action-bar">
      <div>
        You have selected {numItems} {numItems === 1 ? itemName : itemPlural}
      </div>
      <div className={"btn-group"}>
        <Button tertiary onClick={onCancel}>
          Cancel
        </Button>
        {canEditPortfolios && (
          <SidePopupV2
            text={
              dnsSourcedIPsSelected
                ? "DNS-sourced IP addresses inherit portfolios from associated domains, so cannot be assigned to portfolios directly. Deselect any DNS IPs to enable portfolio editing."
                : ""
            }
          >
            <Button
              disabled={dnsSourcedIPsSelected}
              onClick={onOpenPortfoliosModal}
            >
              Edit portfolios
            </Button>
          </SidePopupV2>
        )}
        <Button onClick={onOpenLabelsModal}>Edit labels</Button>
      </div>
      {labelsModal}
      {portfoliosModal}
    </ActionBar>
  );
};

export default appConnect<
  IDomainIPActionBarConnectedProps,
  never,
  IDomainIPActionBarOwnProps
>((state, _props) => {
  return {
    orgHasDomainPortfoliosEnabled:
      state.common.userData.orgPermissions.includes(OrgAccessDomainPortfolios),
  };
})(DomainIPActionBarInner);
