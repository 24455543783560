import { DefaultAction } from "../types/redux";
import { IActivityStreamEvent } from "../../vendorrisk/types/activityStream";
import { FetchCyberRiskUrl } from "../api";
import { LogError } from "../helpers";

export const SET_BATCHED_ACTIVITY_STREAM_EVENTS_FOR_EVENT =
  "SET_BATCHED_ACTIVITY_STREAM_EVENTS_FOR_EVENT";
export const setBatchedActivityStreamEventsForEvent = (
  eventId: number,
  events: IActivityStreamEvent[] | undefined
) => {
  return {
    type: SET_BATCHED_ACTIVITY_STREAM_EVENTS_FOR_EVENT,
    eventId,
    events,
  };
};

export const SET_BATCHED_ACTIVITY_STREAM_EVENT_EXPANDED =
  "SET_BATCHED_ACTIVITY_STREAM_EVENT_EXPANDED";
export const setBatchedActivityStreamEventExpanded = (
  eventId: number,
  isExpanded: boolean
) => {
  return {
    type: SET_BATCHED_ACTIVITY_STREAM_EVENT_EXPANDED,
    eventId,
    isExpanded,
  };
};

// Retrieves and stores in redux events for a parent batched event
export const getActivityStreamEventsByIDs = (
  parentId: number,
  ids: number[],
  refresh = false
): DefaultAction<IActivityStreamEvent[] | undefined> => {
  return async (dispatch, getState) => {
    if (refresh) {
      // Clear current articles
      dispatch(setBatchedActivityStreamEventsForEvent(parentId, undefined));
    } else if (getState().common.activityStreamBatchedEvents[parentId]) {
      return getState().common.activityStreamBatchedEvents[parentId];
    }

    let resp: IActivityStreamEvent[] | undefined;

    try {
      resp = await FetchCyberRiskUrl<IActivityStreamEvent[]>(
        "activitystreamapi/events/v1/",
        {},
        {
          method: "POST",
          body: JSON.stringify({
            ids,
          }),
        },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("error retrieving activity stream events for event", e);

      throw e;
    }

    dispatch(setBatchedActivityStreamEventsForEvent(parentId, resp ?? []));

    return resp;
  };
};
