import { DefaultAction } from "../types/redux";
import { get as _get } from "lodash";
import { FetchCyberRiskUrl } from "../api";
import { LogError } from "../helpers";
import {
  setFreeTrialEligibility,
  setFreeTrialOnboardingCalendar,
  setOnboardingMeetingBooked,
} from "./commonActions";
import { IFreeTrialEligibility } from "../types/freeTrial";

//
// GT: I'm keeping the calls to this function in place just in case we want to start using a client-side source
// again as opposed to server-side from the HttpRequest.
//
export const getCountryInfo = async () => {
  return "";
  /*
    const country = "";
    try {
      const response = await fetch("https://ipapi.co/json/", { method: "GET" });
      const json = await response.json();
      country = json && json.country_name ? json.country_name : "";
    } catch (e) {
      LogError(
        "Error determining country for client ip address using ipapi.co",
        e
      );
      console.log(e);
    }
    return country;
   */
};

export const fetchOrgFreeTrialEligibility = (
  userId: number,
  orgId: number,
  force = false
): DefaultAction<IFreeTrialEligibility> => {
  return async (dispatch, getState) => {
    let eligibility = _get(
      getState().common,
      `freeTrialEligibility[${userId}][${orgId}]`,
      null
    );
    if (!force && eligibility && !eligibility.error) {
      return eligibility;
    }

    // grab the country of the user and override the company country if we can get it
    const userCountry = await getCountryInfo();

    let json: {
      status: string;
      userId: number;
      orgId: number;
      reason: string;
      userEmail: string;
      companyName: string;
      companyDomain: string;
      country: string;
      firstName: string;
      lastName: string;
      jobTitle: string;
    };

    try {
      json = await FetchCyberRiskUrl(
        "plg/v1/trial_eligability",
        { country: userCountry },
        null,
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error getting free trial eligibility", e);
      eligibility = {
        error: e,
        userId: userId,
        orgId: orgId,
      };
      dispatch(setFreeTrialEligibility(userId, orgId, eligibility));
      throw new Error("Error retrieving free trial eligibility.");
    }

    if (userCountry) {
      json.country = userCountry;
    }

    eligibility = {
      orgId: json.orgId,
      userId: json.userId,
      eligible: json.status === "ELIGIBLE",
      reason: json.reason,
      userEmail: json.userEmail,
      companyName: json.companyName,
      companyDomain: json.companyDomain,
      country: json.country,
      firstName: json.firstName,
      lastName: json.lastName,
      jobTitle: json.jobTitle,
    } as IFreeTrialEligibility;
    dispatch(setFreeTrialEligibility(json.userId, json.orgId, eligibility));
    return eligibility;
  };
};

export const acceptFreeTrialOffer = (
  firstName: string,
  lastName: string,
  country: string,
  jobTitle: string,
  phoneNumber: string,
  useCase: string,
  useCaseOther: string,
  howDidYouHearAbout = ""
): DefaultAction<void> => {
  return async (dispatch, getState) => {
    try {
      await FetchCyberRiskUrl(
        "plg/v1/trial",
        {
          first_name: firstName,
          last_name: lastName,
          country: country,
          jobtitle: jobTitle,
          phone: phoneNumber,
          use_case: useCase,
          use_case_other: useCaseOther,
          how: howDidYouHearAbout ? howDidYouHearAbout : null,
        },
        { method: "POST" },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error accepting free trial offer", e);
      throw new Error(
        "Failed to accept free trial offer. Please contact support or try again."
      );
    }
  };
};

export const markOnboardingMeetingBooked = (): DefaultAction<void> => {
  return async (dispatch, getState) => {
    try {
      await FetchCyberRiskUrl(
        "plg/v1/onboarding_call",
        {},
        { method: "PUT" },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error marking free trial onboarding call booked", e);
      throw new Error("Failed to mark onboarding call as booked.");
    }

    dispatch(setOnboardingMeetingBooked());
  };
};

// requestSlackNotification
// handles any (free-trial related) user interface events that we'd like to report to slack
export const requestSlackNotification = (
  eventType: string
): DefaultAction<void> => {
  return async (dispatch, getState) => {
    try {
      await FetchCyberRiskUrl(
        "plg/slackmsg/v1",
        { event_type: eventType },
        { method: "POST" },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error sending event to slack", e);
    }
  };
};

export const retrieveOnboardingCalendarForUser = (
  userId: number
): DefaultAction<void> => {
  return async (dispatch, getState) => {
    const country = await getCountryInfo();

    try {
      const json: { status: string; region: string; calendar: string } =
        await FetchCyberRiskUrl(
          "plg/v1/onboarding_calendar",
          { country: country },
          { method: "GET" },
          dispatch,
          getState
        );
      dispatch(setFreeTrialOnboardingCalendar(userId, json.calendar));
    } catch (e) {
      LogError("Error retrieving onboarding calendar for user", e);
      throw new Error("Failed to retrieve onboarding calendar for user.");
      dispatch(setFreeTrialOnboardingCalendar(userId, undefined));
    }
  };
};
