import { FC } from "react";
import ColorCheckbox, {
  IColorCheckboxProps,
} from "../../../vendorrisk/components/ColorCheckbox";
import "./CheckboxList.scss";
import classNames from "classnames";

interface CheckboxListItem
  extends Omit<IColorCheckboxProps, "onClick" | "checked"> {
  id: string | number;
}

interface CheckboxListProps {
  items: CheckboxListItem[];
  selectedItems: (string | number)[];
  onChange: (selectedItems: (string | number)[]) => void;
  readonly?: boolean;
}

const CheckboxList: FC<CheckboxListProps> = ({
  items,
  selectedItems,
  onChange,
  readonly = false,
}) => {
  const listItems = items.map((item) => {
    const { id, ...otherProps } = item;

    const checked = selectedItems.indexOf(id) !== -1;

    return (
      <li key={item.id}>
        {readonly && item.label}
        {!readonly && (
          <ColorCheckbox
            checked={checked}
            onClick={() => {
              if (checked) {
                // Remove from selection
                onChange([...selectedItems.filter((s) => s !== id)]);
              } else {
                // Add to selection
                onChange([...selectedItems, id]);
              }
            }}
            {...otherProps}
          />
        )}
      </li>
    );
  });

  return (
    <div className={classNames("checkbox-list", { "read-only": readonly })}>
      <ul>{listItems}</ul>
    </div>
  );
};

export default CheckboxList;
