import { RepoManifestRisk } from "../../../_common/types/vendorSummary";
import { FC } from "react";
import ColorCheckbox from "../ColorCheckbox";
import CheckboxList from "../../../_common/components/core/CheckboxList";

export interface ManifestRiskAssetSelection {
  isAllSelected: boolean;
  selectedUuids: string[];
}

interface ManifestRiskAssetSelectorProps {
  manifests: RepoManifestRisk[];
  selection: ManifestRiskAssetSelection;
  onChange: (selection: ManifestRiskAssetSelection) => void;
}

const ManifestRiskAssetSelector: FC<ManifestRiskAssetSelectorProps> = ({
  manifests,
  selection,
  onChange,
}) => {
  return (
    <div className={"manifest-risk-asset-selector asset-selector"}>
      <div className={"all-assets-selection"}>
        <h4 className={"overview-header"}>Select Software Inventories</h4>
        <ColorCheckbox
          radio
          color="blue"
          checked={selection.isAllSelected}
          label="All inventories"
          onClick={() =>
            onChange({
              isAllSelected: true,
              selectedUuids: manifests.map((r) => r.manifestUUID),
            })
          }
        />
        <ColorCheckbox
          radio
          color="blue"
          checked={!selection.isAllSelected}
          label="Selected inventories"
          onClick={() =>
            onChange({
              isAllSelected: false,
              selectedUuids: [],
            })
          }
        />
      </div>
      <div className={"asset-selection"}>
        <h4 className={"overview-header"}>Inventories</h4>
        <CheckboxList
          items={manifests.map((r) => {
            return {
              id: r.manifestUUID,
              label: `${r.repoName} - ${r.manifestLocation}`,
            };
          })}
          readonly={selection.isAllSelected}
          selectedItems={selection.selectedUuids}
          onChange={(selection) =>
            onChange({
              isAllSelected: false,
              selectedUuids: selection as string[],
            })
          }
        />
      </div>
    </div>
  );
};

export default ManifestRiskAssetSelector;
