import { Component } from "react";
import PropTypes from "prop-types";
import Button from "../../../_common/components/core/Button";
import { closeModal } from "../../../_common/reducers/commonActions";
import TextField from "../../../_common/components/TextField";
import {
  addDefaultSuccessAlert,
  addDefaultUnknownErrorAlert,
} from "../../../_common/reducers/messageAlerts.actions";
import { denyVerifiedVendorUserAccessRequest } from "../../reducers/verifiedVendors.actions";

export const VerifiedVendorDenyRequestModalName =
  "VerifiedVendorDenyRequestModalName";

class VerifiedVendorDenyRequestModal extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    modalData: PropTypes.shape({
      requestId: PropTypes.number.isRequired,
      userEmail: PropTypes.string.isRequired,
      orgName: PropTypes.string,
      onSuccess: PropTypes.func,
    }).isRequired,
  };

  state = {
    loading: false,
    reason: "",
    isValid: false,
  };

  onSubmit = (e) => {
    e.preventDefault();

    const reason = this.state.reason.trim();

    this.setState({ loading: true });

    this.props
      .dispatch(
        denyVerifiedVendorUserAccessRequest(
          this.props.modalData.requestId,
          reason
        )
      )
      .then(() =>
        this.props.dispatch(addDefaultSuccessAlert("Denied access request"))
      )
      .then(() => this.props.dispatch(closeModal()))
      .then(() => {
        if (this.props.modalData.onSuccess) {
          this.props.modalData.onSuccess();
        }
      })
      .catch((e) => {
        console.error(e);
        this.props.dispatch(
          addDefaultUnknownErrorAlert("Error denying request")
        );
      })
      .finally(() => this.setState({ loading: false }));
  };

  render() {
    return (
      <div className="modal-content verified-vendor-deny-modal">
        <h2>Deny access request</h2>
        <p>
          The request from <em>{this.props.modalData.userEmail}</em>
          {this.props.modalData.orgName && (
            <> (from {this.props.modalData.orgName})</>
          )}{" "}
          to access your Trust Page will be denied. Please provide a reason that
          will be sent to the requesting user.
        </p>
        <form onSubmit={this.onSubmit}>
          <TextField
            multiLine
            maxLength={4000}
            allowTextOverflow
            disabled={this.state.loading}
            required
            placeholder="Provide a reason for denying this request"
            value={this.state.reason}
            onChanged={(v, isValid) =>
              this.setState({ reason: v, isValid: isValid })
            }
          />
          <Button
            type="submit"
            primary
            loading={this.state.loading}
            disabled={this.state.loading | !this.state.isValid}
          >
            Deny request
          </Button>
        </form>
      </div>
    );
  }
}

export default VerifiedVendorDenyRequestModal;
