import ModalV2, { BaseModalProps } from "./ModalV2";
import { DefaultThunkDispatchProp } from "../types/redux";
import { BadgeInfo, ScoreBadgeTheme } from "../../vendorrisk/types/scoreBadge";
import { useEffect, useState } from "react";
import { getScoreBadgeInfo } from "../../vendorrisk/reducers/verifiedVendors.actions";
import { addDefaultUnknownErrorAlert } from "../reducers/messageAlerts.actions";
import LoadingIcon from "./core/LoadingIcon";
import Button from "./core/Button";
import "../style/components/ScoreBadgeModal.scss";
import classnames from "classnames";

export const scoreBadgeHelpArticleUrl =
  "https://help.upguard.com/en/articles/7199142-how-to-add-your-security-rating-badge-to-your-website";

type IVendorFreeTrialModalProps = BaseModalProps & DefaultThunkDispatchProp;

const ScoreBadgeModal = ({
  active,
  onClose,
  dispatch,
}: IVendorFreeTrialModalProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [badges, setBadges] = useState<BadgeInfo[]>([]);
  const [chosenTheme, setChosenTheme] = useState<ScoreBadgeTheme | undefined>(
    undefined
  );
  const [hasCopied, setHasCopied] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    dispatch(getScoreBadgeInfo())
      .then((resp) => {
        setBadges(resp);
        if (resp && resp.length) {
          setChosenTheme(resp[0].theme);
        }
      })
      .catch(() =>
        dispatch(addDefaultUnknownErrorAlert("Failed to get information"))
      )
      .finally(() => setIsLoading(false));
  }, []);

  const badgeBase64Content = badges.find((badge) => badge.theme === chosenTheme)
    ?.base64Data;
  const snippet = badges.find((badge) => badge.theme === chosenTheme)?.snippet;

  const onCopy = () => {
    if (!snippet) return;

    navigator.clipboard.writeText(snippet);
    setHasCopied(true);
    setTimeout(() => {
      setHasCopied(false);
    }, 3000);
  };

  return (
    <>
      <ModalV2
        active={active}
        onClose={onClose}
        className={"score-badge-modal"}
        headerContent={`Share your rating`}
        footerClassName={"score-badge-modal-footer"}
        footerContent={
          <>
            {!hasCopied && (
              <Button
                tertiary
                loading={isLoading}
                disabled={isLoading}
                onClick={onClose}
              >
                Cancel
              </Button>
            )}
            <Button
              filledPrimary
              className={"copy-button"}
              onClick={onCopy}
              loading={isLoading}
              disabled={isLoading}
            >
              {hasCopied ? (
                <>
                  {" "}
                  <div className="cr-icon-check" /> Copied
                </>
              ) : (
                <>
                  {" "}
                  <div className="cr-icon-copy" /> Copy code
                </>
              )}
            </Button>
          </>
        }
      >
        <div className={"description"}>
          <p>
            Embed this code on your website to display your rating. Visit our{" "}
            <a
              href={scoreBadgeHelpArticleUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              support article
            </a>{" "}
            to read our recommendations for embedding this badge on your
            website.
          </p>
        </div>
        {isLoading && <LoadingIcon />}
        {!isLoading && badges && (
          <div className={"score-badge-content"}>
            <div className={"score-badge-themes"}>
              <div className={"label"}>Badge color</div>
              {badges.map((badge) => {
                return (
                  <div
                    key={badge.theme}
                    className={classnames("theme", badge.theme, {
                      active: badge.theme === chosenTheme,
                    })}
                    onClick={() => setChosenTheme(badge.theme)}
                  ></div>
                );
              })}
            </div>
            <div className={"score-badge-preview"}>
              {badgeBase64Content && (
                <img
                  src={`data:image/svg+xml;base64,${badgeBase64Content}`}
                  alt="Score badge"
                  width="184"
                  height="138"
                />
              )}
            </div>
            <div className={"score-badge-code"}>
              {snippet && <pre>{snippet}</pre>}
            </div>
          </div>
        )}
      </ModalV2>
    </>
  );
};

export default ScoreBadgeModal;
