import ModalV2, {
  BaseModalProps,
  useModalV2,
} from "../../_common/components/ModalV2";
import { FC, FormEventHandler, useCallback, useEffect, useState } from "react";
import ContentLibraryAPI, {
  getDocumentsListV1Resp,
} from "../api/contentLibraryAPI";
import {
  AcceptedFileTypes,
  MaxFileSizeB,
} from "../../_common/types/fileRestrictions";
import { useAppDispatch, useAppSelector } from "../../_common/types/reduxHooks";
import {
  addDefaultSuccessAlert,
  addDefaultUnknownErrorAlert,
  addDefaultWarningAlert,
} from "../../_common/reducers/messageAlerts.actions";
import DragDropUpload from "../../_common/components/DragDropUpload";

import "../styles/AddDocumentToContentLibraryModal.scss";
import TextField, {
  useTextWithValid,
} from "../../_common/components/TextField";
import { useDefaultHistory } from "../../_common/types/router";
import Button from "../../_common/components/core/Button";
import { ContentLibraryDocumentLocationState } from "../views/ContentLibraryDocument";
import { chatGPTAccessSelector } from "../../_common/chatgpt";
import {
  ContentLibraryQuestionnaireDocumentType,
  fileEligibleForQAColumnStep,
} from "../types/contentLibrary.types";
import InfoBanner, { BannerType } from "../../vendorrisk/components/InfoBanner";
import DocumentTypeSelector from "./DocumentTypeSelector";
import { useFindDuplicateDocumentsForFile } from "../types/contentLibraryHooks";
import { LogError } from "../../_common/helpers";
import { trimFileExtension } from "../../_common/helpers/string.helpers";

interface IDuplicateDocumentConfirmationModalProps extends BaseModalProps {
  onSubmit: () => Promise<void>;
  duplicateDocuments: getDocumentsListV1Resp;
  closeAfterSubmit?: boolean;
}

export const DuplicateDocumentConfirmationModal: FC<
  IDuplicateDocumentConfirmationModalProps
> = ({ active, onClose, onSubmit, duplicateDocuments, closeAfterSubmit }) => {
  const history = useDefaultHistory();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);

  const onAddDocumentAnyway = useCallback(async () => {
    setLoading(true);

    try {
      await onSubmit();
    } catch (e) {
      LogError("error uploading document to content library", e);
      dispatch(addDefaultUnknownErrorAlert("Error uploading document"));
      setLoading(false);
    } finally {
      if (closeAfterSubmit) {
        onClose();
      }
    }
  }, [dispatch, onSubmit]);

  const onGoToDocument = useCallback(() => {
    if (duplicateDocuments.documents.length === 0) {
      // Shouldn't happen, we should only show this modal if there are duplicates
      return;
    }

    // For now, just go to the first one in the list (the last one to be updated)
    history.push(
      `/contentlibrary/document/${duplicateDocuments.documents[0].uuid}`,
      {
        backContext: {
          backToText: "Back to Content Library",
          goBack: true,
        },
      }
    );
  }, [history, duplicateDocuments]);

  return (
    <ModalV2
      active={active}
      onClose={onClose}
      width={600}
      headerContent="This document already exists in your Content Library. Adding this document again will create a duplicate."
      footerContent={
        <>
          <div className="btn-group footer-left">
            <Button tertiary onClick={onClose}>
              Cancel
            </Button>
          </div>
          <div className="btn-group">
            <Button loading={loading} onClick={onAddDocumentAnyway}>
              Add document anyway
            </Button>
            <Button filledPrimary onClick={onGoToDocument}>
              Go to document
            </Button>
          </div>
        </>
      }
    />
  );
};

interface IAddDocumentToContentLibraryModalProps extends BaseModalProps {
  file?: File;
}

const AddDocumentToContentLibraryModal: FC<
  IAddDocumentToContentLibraryModalProps
> = ({ active, onClose, file }) => {
  const dispatch = useAppDispatch();
  const history = useDefaultHistory<ContentLibraryDocumentLocationState>();
  const { shouldDisplayChatGPT } = useAppSelector(chatGPTAccessSelector);

  const [createDocument] =
    ContentLibraryAPI.useCreateContentLibraryDocumentMutation();

  const [selectedFile, setSelectedFile] = useState(file);
  const [documentName, documentNameValid, setDocumentName] = useTextWithValid();
  const [documentNameSetManually, setDocumentNameSetManually] = useState(false);
  const [documentTypeSetManually, setDocumentTypeSetManually] = useState(false);
  const [selectedDocumentType, setSelectedDocumentType] = useState("");

  const textFieldSetDocumentName = useCallback(
    (docName: string, valid: boolean) => {
      setDocumentName(docName, valid);
      if (docName !== documentName) {
        setDocumentNameSetManually(true);
      }
    },
    [setDocumentName, documentName]
  );

  const [createLoading, setCreateLoading] = useState(false);

  useEffect(() => {
    if (selectedFile && !documentNameSetManually) {
      // Prefill the document name when a file is added
      setDocumentName(trimFileExtension(selectedFile.name), true);
    }
  }, [selectedFile, documentNameSetManually, setDocumentName]);

  useEffect(() => {
    if (
      selectedFile &&
      !documentTypeSetManually &&
      fileEligibleForQAColumnStep(selectedFile.name)
    ) {
      // Set the document type to Questionnaire if we've detected an excel file
      setSelectedDocumentType(ContentLibraryQuestionnaireDocumentType);
    }
  }, [selectedFile, documentTypeSetManually]);

  const onFileRejected = useCallback(
    (_file: File) => {
      dispatch(
        addDefaultWarningAlert("File not supported", [
          "The file may be too big or an unsupported file type.",
        ])
      );
    },
    [dispatch]
  );

  const canSubmit =
    !!selectedFile && !!selectedDocumentType && documentNameValid;

  const findDuplicateDocuments = useFindDuplicateDocumentsForFile(
    selectedFile ? [selectedFile] : []
  );
  const onDuplicateDocsModalClose = useCallback(
    () => setCreateLoading(false),
    []
  );
  const [openDuplicateDocModal, duplicateDocModal] = useModalV2(
    DuplicateDocumentConfirmationModal,
    onDuplicateDocsModalClose
  );

  const onSubmit = useCallback(async () => {
    if (!selectedFile) {
      return;
    }

    const resp = await createDocument({
      file: selectedFile,
      name: documentName,
      type: selectedDocumentType,
    }).unwrap();

    dispatch(
      addDefaultSuccessAlert(`"${documentName}" added to Content Library`)
    );

    history.push(`/contentlibrary/document/${resp.document.uuid}`, {
      isNewDocument: true,
      backContext: {
        backToText: "Back to Content Library",
        goBack: true,
      },
    });
  }, [
    selectedFile,
    documentName,
    selectedDocumentType,
    createDocument,
    history,
    dispatch,
  ]);

  const onClickNext = useCallback(async () => {
    if (!canSubmit) {
      return;
    }

    setCreateLoading(true);

    try {
      // First, check if there are any matching files in the content library
      const duplicateDocuments = await findDuplicateDocuments();
      if (duplicateDocuments.documents.length > 0) {
        openDuplicateDocModal({
          duplicateDocuments,
          onSubmit,
        });
        return;
      }

      await onSubmit();
    } catch (e) {
      LogError("error uploading document to content library", e);
      dispatch(addDefaultUnknownErrorAlert("Error uploading document"));
      setCreateLoading(false);
    }
  }, [
    canSubmit,
    dispatch,
    onSubmit,
    findDuplicateDocuments,
    openDuplicateDocModal,
  ]);

  const onFormSubmit: FormEventHandler = useCallback(
    (e) => {
      e.preventDefault();
      onClickNext();
    },
    [onClickNext]
  );

  const willTriggerSetQAColumns =
    selectedFile &&
    shouldDisplayChatGPT &&
    fileEligibleForQAColumnStep(selectedFile.name) &&
    selectedDocumentType === ContentLibraryQuestionnaireDocumentType;

  const documentTypeSelectorOnChange = useCallback(
    (docType: string) => {
      setSelectedDocumentType(docType);
      setDocumentTypeSetManually(true);
    },
    [setSelectedDocumentType]
  );

  return (
    <>
      <ModalV2
        active={active}
        onClose={onClose}
        className="add-content-library-doc-modal"
        onFormDefaultSubmit={onFormSubmit}
        headerContent="Upload document"
        footerContent={
          <>
            <Button
              filledPrimary
              loading={createLoading}
              disabled={!canSubmit}
              onClick={onClickNext}
            >
              {willTriggerSetQAColumns
                ? "Upload document and set Q&A columns"
                : "Upload document"}
            </Button>
          </>
        }
      >
        <DragDropUpload
          maxFileSize={MaxFileSizeB}
          acceptedFileTypeFilters={AcceptedFileTypes}
          onFileSelected={setSelectedFile}
          onFileRejected={onFileRejected}
          selectedFile={selectedFile}
        />

        <label htmlFor="name">Name</label>
        <TextField
          name="name"
          value={documentName}
          onChanged={textFieldSetDocumentName}
          placeholder="Document name"
          required
          minLength={2}
          maxLength={500}
        />

        <label htmlFor="docType">Type</label>
        <DocumentTypeSelector
          selectedDocumentType={selectedDocumentType}
          setSelectedDocumentType={documentTypeSelectorOnChange}
        />
        {willTriggerSetQAColumns && (
          <InfoBanner
            type={BannerType.INFO}
            message="Questionnaire documents in Excel format can be used as a source of answers when answering questionnaires. In the next step, you can select the columns to use as questions and answers."
          />
        )}
      </ModalV2>
      {duplicateDocModal}
    </>
  );
};

export default AddDocumentToContentLibraryModal;
