import { Component } from "react";
import PropTypes from "prop-types";
import DatePicker from "../../../_common/components/DatePicker";
import Button from "../../../_common/components/core/Button";
import moment from "moment";
import { closeModal } from "../../../_common/reducers/commonActions";
import {
  fetchSurveyDetails,
  fetchSurveyTimeline,
  updateSurveyDueDate,
} from "../../../_common/reducers/surveyDetails.actions";
import {
  addDefaultSuccessAlert,
  addDefaultUnknownErrorAlert,
} from "../../../_common/reducers/messageAlerts.actions";

export const ModifyDueDateModalName = "ModifyDueDateModalName";

class ModifyDueDateModal extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    modalData: PropTypes.shape({
      surveyId: PropTypes.number.isRequired,
      currentDueDate: PropTypes.string.isRequired,
    }).isRequired,
  };

  state = {
    dueDate: "",
  };

  onSave = async (e) => {
    e.preventDefault();

    this.setState({ loading: true });
    try {
      await this.props.dispatch(
        updateSurveyDueDate(this.props.modalData.surveyId, this.state.dueDate)
      );
      await Promise.all([
        this.props.dispatch(
          fetchSurveyDetails(this.props.modalData.surveyId, true)
        ),
        this.props.dispatch(
          fetchSurveyTimeline(this.props.modalData.surveyId, true)
        ),
      ]);
      this.props.dispatch(
        addDefaultSuccessAlert("Due date successfully changed")
      );
      this.props.dispatch(closeModal());
    } catch (e) {
      console.error(e);
      this.setState({ loading: false });
      this.props.dispatch(
        addDefaultUnknownErrorAlert("An error occurred updating the due date")
      );
    }
  };

  render() {
    return (
      <div className="modal-content">
        <h2>Set questionnaire due date</h2>
        <p className="descriptionText">
          Users collaborating on this questionnaire will be sent an email
          notification of this change.
          <br />
          <br />
          Current due date:{" "}
          {moment(this.props.modalData.currentDueDate).format("YYYY-MM-DD")}
        </p>
        <form onSubmit={this.onSave}>
          <DatePicker
            name="due_date"
            min={moment().add(1, "d").format("YYYY-MM-DD")}
            value={this.state.dueDate}
            onChange={(e) => this.setState({ dueDate: e.target.value })}
            required
          />
          <Button
            type="submit"
            primary
            loading={this.state.loading}
            disabled={this.state.loading || !this.state.dueDate}
          >
            Save
          </Button>
          <Button tertiary onClick={() => this.props.dispatch(closeModal())}>
            Cancel
          </Button>
        </form>
      </div>
    );
  }
}

export default ModifyDueDateModal;
