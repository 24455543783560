import PropTypes from "prop-types";
import { openModal } from "../reducers/commonActions";
import {
  fetchSurveyDetails,
  fetchSurveyTimeline,
  updateSurveyStatus,
} from "../reducers/surveyDetails.actions";
import { ConfirmationModalName } from "./modals/ConfirmationModal";
import {
  fetchAlertsOrActivityStreamForOrgUser,
  fetchVendorSurveyCounts,
  deleteSurvey,
} from "../../vendorrisk/reducers/cyberRiskActions";

import "../style/components/QuestionnaireStatusCard.scss";
import { surveyListPageLimit } from "../../vendorrisk/views/Questionnaires";
import {
  addDefaultSuccessAlert,
  addDefaultUnknownErrorAlert,
  addSimpleErrorAlert,
} from "../reducers/messageAlerts.actions";
import { getSurveyListV2 } from "../../vendorrisk/reducers/survey.actions";
import { refreshVendorListsAfterChange } from "../../vendorrisk/reducers/vendors.actions";
import { invalidateVendorAssessmentDraftsForVendor } from "../../vendorrisk/reducers/vendorAssessmentAPI";
import { GetQuestionnaireStatusItem, SurveyStatus } from "../types/survey";
import PillLabel from "../../vendorrisk/components/PillLabel";
import { LogError } from "../helpers";

// restoreCancelledQuestionnaire
// For a particular questionnaire instance, restores the questionnaire back to the status it was before it was cancelled.
// The previous status needs to be supplied in the `previousStatus` attribute of the survey object, and this is derived from the
// survey status history at the back-end.
export const restoreCancelledQuestionnaire = (
  dispatch,
  surveyId,
  vendorId,
  previousState,
  dueDate,
  isManagementAnalystSession = false
) => {
  if (previousState == SurveyStatus.InProgress) {
    // The InProgress status is only recorded in the survey history, not set as state on the survey
    previousState = SurveyStatus.Sent;
  }

  const statusItem = GetQuestionnaireStatusItem(previousState, dueDate, false);
  dispatch(
    openModal(
      ConfirmationModalName,
      {
        title: `Restore cancelled questionnaire`,
        description: (
          <p>
            {"This will restore the questionnaire to its previous status "}
            <PillLabel
              color={statusItem.labelColor}
            >{`${statusItem.text}`}</PillLabel>
            {" before it was cancelled."}
          </p>
        ),
        buttonText: "Confirm",
        buttonAction: async () => {
          try {
            await dispatch(
              updateSurveyStatus(
                surveyId,
                previousState,
                true,
                isManagementAnalystSession,
                vendorId
              )
            );
            const proms = [
              dispatch(fetchSurveyDetails(surveyId, true)),
              dispatch(fetchSurveyTimeline(surveyId, true)),
            ];

            // Fetch activity stream in background
            dispatch(fetchAlertsOrActivityStreamForOrgUser(true, true));

            if (vendorId) {
              proms.push(dispatch(refreshVendorListsAfterChange([vendorId])));

              // Fetch vendor assessment data in background
              dispatch(invalidateVendorAssessmentDraftsForVendor(vendorId));
            }
            await Promise.all(proms);
          } catch (e) {
            LogError("error restoring", e);
            dispatch(
              addDefaultUnknownErrorAlert(
                "An error occurred updating the questionnaire status"
              )
            );
            throw e;
          }
        },
      },
      true
    )
  );
};

export const deleteQuestionnaire = (dispatch, surveyID, vendorId) => {
  dispatch(
    openModal(
      ConfirmationModalName,
      {
        title: "Are you sure you want to delete this questionnaire?",
        description: (
          <p>
            Any recipients of this questionnaire may still have read-only access
            to it. This action cannot be undone.
          </p>
        ),
        buttonText: "Delete",
        dangerousAction: true,
        buttonAction: async () => {
          try {
            await dispatch(deleteSurvey(surveyID));
            const proms = [
              dispatch(
                getSurveyListV2(
                  true,
                  undefined,
                  0,
                  surveyListPageLimit,
                  "date_due",
                  "desc",
                  true
                )
              ),
            ];
            if (vendorId) {
              proms.push(
                dispatch(
                  getSurveyListV2(
                    true,
                    vendorId,
                    0,
                    surveyListPageLimit,
                    "date_due",
                    "desc",
                    true
                  )
                )
              );
              proms.push(dispatch(fetchVendorSurveyCounts(vendorId, true)));
            }

            await Promise.all(proms);
          } catch (e) {
            dispatch(addSimpleErrorAlert(e.message));
            throw e;
          }

          dispatch(
            addDefaultSuccessAlert("Successfully deleted questionnaire")
          );
        },
      },
      true
    )
  );
};

export const goToRequestRemediationFlow = (history, vendorId, surveyId) => {
  history.push(`/vendor/${vendorId}/remediation/add`, { surveyId });
};

export const QuestionnaireStatusCardStyles = ({ children }) => children;

QuestionnaireStatusCardStyles.propTypes = {
  children: PropTypes.any.isRequired,
};
