import "../../style/components/core/LoadingIcon.scss";

interface ILoadingIconProps {
  /* Pixel width and height of element */
  size?: number;
  color?: string;
  className?: string;
}

/* Generates a spinning loader. */
const LoadingIcon = (props: ILoadingIconProps) => (
  <svg
    className={`loader-icon ${props.color} ${props.className}`}
    width={props.size}
    height={props.size}
    viewBox="0 0 100 100"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path // Path generated from Sketch.app from a circle with a modified path.
      strokeWidth="8"
      fill="none"
      strokeLinecap="round"
      d="M50,96 C75.4050985,96 96,75.4050985 96,50 C96,24.5949015 75.4050985,4 50,4 C24.5949015,4 4,24.5949015 4,50"
    />
  </svg>
);

LoadingIcon.defaultProps = {
  size: 50,
  color: "blue",
  className: "",
};

export default LoadingIcon;
