import LoadingBanner from "../../../_common/components/core/LoadingBanner";
import ColorCheckbox from "../ColorCheckbox";
import { RiskHostnameDetailMap } from "../../../_common/types/risks";
import { DefaultThunkDispatchProp } from "../../../_common/types/redux";
import PillLabel from "../PillLabel";
import { LabelColor } from "../../../_common/types/label";
import { Component } from "react";
import "../../style/components/risk_waivers/BreachsightDomainSelection.scss";
import { isEqual as _isEqual } from "lodash";
import { getCustomerRiskHostnames } from "../../reducers/risks.actions";
import { fetchCustomerRiskHostnames } from "../../reducers/domains.actions";
import { appConnect } from "../../../_common/types/reduxHooks";
import { RiskHostnameSelection } from "./RiskHostnameSelector";

const maxSitesShown = 10;

interface BreachSightDomainSelectionOwnProps {
  riskId: string;
  selection: RiskHostnameSelection;
  onChange: (selection: RiskHostnameSelection) => void;
  orgSupportsDomainPortfolios?: boolean;
  editableDomainPortfolioIds: number[];
  skipWaiverId?: number;
}

interface BreachSightDomainSelectionConnectedProps {
  websites?: string[];
  riskDetailsByHostname?: RiskHostnameDetailMap;
  loading: boolean;
}

type BreachSightDomainSelectionProps = DefaultThunkDispatchProp &
  BreachSightDomainSelectionOwnProps &
  BreachSightDomainSelectionConnectedProps;

interface BreachSightDomainSelectionState {
  hostnamesListExpanded: boolean;
}

class BreachSightDomainSelection extends Component<
  BreachSightDomainSelectionProps,
  BreachSightDomainSelectionState
> {
  constructor(props: BreachSightDomainSelectionProps) {
    super(props);
    this.state = {
      hostnamesListExpanded: false,
    };
  }

  componentDidMount() {
    this.props.dispatch(
      fetchCustomerRiskHostnames(
        this.props.riskId,
        undefined,
        this.props.skipWaiverId
      )
    );
  }

  componentDidUpdate(prevProps: Readonly<BreachSightDomainSelectionProps>) {
    // fetch if websites state is emptied
    if (prevProps.websites && !this.props.websites) {
      this.props.dispatch(
        fetchCustomerRiskHostnames(
          this.props.riskId,
          undefined,
          this.props.skipWaiverId
        )
      );
    }

    // Check if any of the passed selected websites are custom domains and if that differs from what we already know
    // OR we now know the full website list AND wanted to select all websites
    // - If so bubble change event
    if (
      prevProps.selection !== this.props.selection ||
      prevProps.riskDetailsByHostname !== this.props.riskDetailsByHostname ||
      this.props.selection.customDomains === undefined
    ) {
      const customDomains: string[] = [];
      const allDomains: string[] = [];

      this.props.selection.selected.forEach((w) => {
        if (
          this.props.riskDetailsByHostname &&
          this.props.riskDetailsByHostname[w]?.isOrgCustom
        ) {
          customDomains.push(w);
        }
      });

      if (this.props.riskDetailsByHostname) {
        allDomains.push(
          ...Object.keys(this.props.riskDetailsByHostname).map((w) => w)
        );
      }

      if (
        !_isEqual(customDomains, this.props.selection.customDomains) ||
        (this.props.selection.allSelected &&
          !_isEqual(allDomains, this.props.selection.selected))
      ) {
        this.props.onChange({
          ...this.props.selection,
          selected: this.props.selection.allSelected
            ? allDomains
            : this.props.selection.selected,
          customDomains: customDomains,
        });
      }
    }
  }

  onExpandHostnames = () => this.setState({ hostnamesListExpanded: true });

  render() {
    const { loading, websites, riskDetailsByHostname, selection, onChange } =
      this.props;
    const { hostnamesListExpanded } = this.state;

    if (loading || websites === undefined || !riskDetailsByHostname) {
      return <LoadingBanner tight />;
    }

    const shownCloudscans = websites?.slice(0, maxSitesShown) ?? [];
    const moreCloudscans = websites?.length - shownCloudscans.length;

    const selectedWebsitesMap: { [hostname: string]: any } = {};
    selection.selected.forEach((w) => {
      selectedWebsitesMap[w] = {};
    });

    const userHasPortfolioSpecificAccess =
      this.props.orgSupportsDomainPortfolios &&
      this.props.editableDomainPortfolioIds.length > 0;

    const renderCloudscans = (scans: string[]) =>
      scans.map((host: string) => {
        const hostDetail = riskDetailsByHostname[host];
        const canSelect =
          !userHasPortfolioSpecificAccess ||
          !!hostDetail.portfolios?.find((p) =>
            this.props.editableDomainPortfolioIds.includes(p.id)
          );

        return (
          <li key={host}>
            {selection.allSelected ? (
              <>
                {host}
                {hostDetail.isOrgCustom && (
                  <PillLabel color={LabelColor.Yellow}>Custom</PillLabel>
                )}
              </>
            ) : (
              <ColorCheckbox
                color="blue"
                label={
                  <div>
                    {host}{" "}
                    {hostDetail.isOrgCustom && (
                      <PillLabel color={LabelColor.Yellow}>Custom</PillLabel>
                    )}
                  </div>
                }
                checked={!!selectedWebsitesMap[host]}
                disabled={!canSelect}
                helpPopup={
                  !canSelect
                    ? "Selection disabled as you have read-only access to this domain."
                    : ""
                }
                onClick={() => {
                  let websites = [...selection.selected];
                  let customDomains = [...(selection.customDomains ?? [])];

                  if (selectedWebsitesMap[host]) {
                    websites = websites.filter((h) => h !== host);
                    customDomains = customDomains.filter((h) => h !== host);
                  } else {
                    websites.push(host);
                    if (hostDetail.isOrgCustom) {
                      customDomains.push(host);
                    }
                  }

                  onChange({
                    allSelected: false,
                    includeFuture: false,
                    selected: websites,
                    customDomains,
                  });
                }}
              />
            )}
          </li>
        );
      });

    return (
      <div className={"breachsight-domain-selection"}>
        <div className="all-websites-selection-container">
          <div className={"overview-header"}>Select domains & IPs</div>
          <div className={"all-websites-selection-container"}>
            <div className={"all-websites-selection"}>
              <ColorCheckbox
                radio
                color="blue"
                checked={selection.allSelected}
                label="All domains & IPs"
                onClick={() => {
                  onChange({
                    allSelected: true,
                    includeFuture: false,
                    selected: Object.values(
                      this.props.riskDetailsByHostname || []
                    ).map((w) => w.hostname),
                    customDomains: Object.values(
                      this.props.riskDetailsByHostname || []
                    )
                      .filter((w) => w.isOrgCustom)
                      .map((w) => w.hostname),
                  });
                }}
              />
              <ColorCheckbox
                radio
                color="blue"
                checked={!selection.allSelected}
                label="Selected domains & IPs"
                onClick={() => {
                  onChange({
                    allSelected: false,
                    includeFuture: false,
                    selected: [],
                    customDomains: [],
                  });
                }}
              />
            </div>
            {selection.allSelected ? (
              <>
                <div className="all-websites-selection-description">
                  This risk will be waived for all currently known domains & IPs
                </div>
                <ColorCheckbox
                  className={"future-chk"}
                  color="blue"
                  checked={selection.includeFuture}
                  label="Include new domains & IPs we detect in the future"
                  onClick={() => {
                    const newVal = !selection.includeFuture;
                    onChange({
                      allSelected: true,
                      includeFuture: newVal,
                      selected: !newVal
                        ? Object.values(
                            this.props.riskDetailsByHostname || []
                          ).map((w) => w.hostname)
                        : [],
                      customDomains: !newVal
                        ? Object.values(this.props.riskDetailsByHostname || [])
                            .filter((w) => w.isOrgCustom)
                            .map((w) => w.hostname)
                        : [],
                    });
                  }}
                />
              </>
            ) : (
              <div className="all-websites-selection-description">
                This risk will only ever be waived for the selected specific
                domains & IPs.
              </div>
            )}
          </div>
        </div>
        <div className={"domain-selection"}>
          <div className={"overview-header"}>Domains & IPs</div>
          <ul>
            {renderCloudscans(shownCloudscans)}
            {moreCloudscans > 0 && (
              <>
                {hostnamesListExpanded ? (
                  renderCloudscans(websites.slice(maxSitesShown))
                ) : (
                  <div className="show-more" onClick={this.onExpandHostnames}>
                    Show {moreCloudscans} more...
                  </div>
                )}
              </>
            )}
          </ul>
        </div>
      </div>
    );
  }
}

export default appConnect<
  BreachSightDomainSelectionConnectedProps,
  never,
  BreachSightDomainSelectionOwnProps
>((state, props) => {
  const { loading, data } = getCustomerRiskHostnames(
    state,
    props.riskId,
    undefined,
    props.skipWaiverId
  );

  return {
    loading,
    websites: data?.hostnamesWithRisk,
    riskDetailsByHostname: data?.riskDetailsByHostname,
  };
})(BreachSightDomainSelection);
