import { Component } from "react";
import PropTypes from "prop-types";

import Button from "../../../_common/components/core/Button";

import { updateVendorAttributeOverride } from "../../reducers/adminActions";
import { closeModal } from "../../../_common/reducers/commonActions";

import "../../styles/AddEditVendorAttributeOverrideModal.scss";
import { addSimpleErrorAlert } from "../../../_common/reducers/messageAlerts.actions";
import { appConnect } from "../../../_common/types/reduxHooks";

export const AddEditVendorAttributeOverrideModalName =
  "AddEditVendorAttributeOverrideModalName";

class AddEditVendorAttributeOverrideModal extends Component {
  static propTypes = {
    modalData: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    industries: PropTypes.array.isRequired,
  };

  static fields = [
    {
      id: "Name",
      label: "Name",
    },
    {
      id: "LegalName",
      label: "Legal Name",
    },
    {
      id: "CEO.Name",
      label: "CEO - Name",
    },
    {
      id: "CEO.Image",
      label: "CEO - Image",
    },
    {
      id: "CEO.ApprovalPercentage",
      label: "CEO - Approval Percentage",
    },
    {
      id: "Location.City",
      label: "Location - City",
    },
    {
      id: "Location.Postcode",
      label: "Location - Postcode",
    },
    {
      id: "Location.Country",
      label: "Location - Country",
    },
    {
      id: "EmployeeCount",
      label: "Employeee Count",
    },
    {
      id: "AnnualRevenue",
      label: "Annual Revenue",
    },
    {
      id: "MarketCap",
      label: "Market Cap",
    },
  ];

  constructor(props) {
    super(props);

    const fields = props.modalData.isAdding
      ? {}
      : {
          PrimaryHostname: props.modalData.primaryHostname,
          ...props.modalData.overrides,
        };

    if (props.modalData.name) {
      fields.Name = props.modalData.name;
    }
    if (props.modalData.primaryHostname) {
      fields.PrimaryHostname = props.modalData.primaryHostname;
    }
    if (props.modalData.industrySector && props.modalData.industryGroup) {
      fields.IndustrySector = props.modalData.industrySector;
      fields.IndustryGroup = props.modalData.industryGroup;
    }

    this.state = {
      loading: false,
      fields,
    };
  }

  setField = (field, value) =>
    this.setState(({ fields }) => ({
      fields: {
        ...fields,
        [field]: value,
      },
    }));

  onChangeIndustry = (evt) => {
    const val = evt.target.value;
    if (val === "") {
      this.setField("IndustrySector", "");
      this.setField("IndustryGroup", "");
    } else {
      const [industrySector, industryGroup] = val.split("|");
      this.setField("IndustrySector", industrySector);
      this.setField("IndustryGroup", industryGroup);
    }
  };

  onSubmit = (evt) => {
    evt.preventDefault();

    const primaryHostname = this.state.fields.PrimaryHostname;
    const attributes = { ...this.state.fields };
    delete attributes.PrimaryHostname;

    this.setState({ loading: true });
    this.props
      .dispatch(
        updateVendorAttributeOverride(
          primaryHostname,
          attributes,
          this.props.modalData.isAdding
        )
      )
      .then(() => this.props.dispatch(closeModal()))
      .catch((e) => {
        this.props.dispatch(addSimpleErrorAlert(e.message));
        this.setState({ loading: false });
      });
  };

  render() {
    const { isAdding } = this.props.modalData;

    return (
      <div className="modal-content add-edit-vendor-attribute-override-modal">
        <h2>{isAdding ? "Add" : "Update"} vendor override</h2>
        <br />
        <form onSubmit={this.onSubmit}>
          <div className="field">
            <label htmlFor="PrimaryHostname">
              Primary hostname to override
            </label>
            <input
              type="text"
              name="PrimaryHostname"
              required
              readOnly={!isAdding}
              value={this.state.fields.PrimaryHostname || ""}
              onChange={(evt) =>
                this.setField("PrimaryHostname", evt.target.value)
              }
            />
          </div>
          <div className="field">
            <label htmlFor="Industry">Industry</label>
            <select
              name="Industry"
              value={
                this.state.fields.IndustrySector
                  ? this.state.fields.IndustrySector +
                    "|" +
                    this.state.fields.IndustryGroup
                  : ""
              }
              onChange={this.onChangeIndustry}
            >
              <option name="" />
              {this.props.industries.map((industry) => (
                <option
                  key={industry.sector + industry.group}
                  value={industry.sector + "|" + industry.group}
                >
                  {industry.sector} - {industry.group}
                </option>
              ))}
            </select>
          </div>
          {AddEditVendorAttributeOverrideModal.fields.map((field) => (
            <div className="field" key={field.id}>
              <label htmlFor={field.id}>{field.label}</label>
              <input
                type="text"
                name={field.id}
                value={this.state.fields[field.id] || ""}
                onChange={(evt) => this.setField(field.id, evt.target.value)}
              />
            </div>
          ))}
          <Button
            type="submit"
            primary
            loading={this.state.loading}
            disabled={this.state.loading}
          >
            Save
          </Button>
        </form>
      </div>
    );
  }
}

export default appConnect((state) => ({ industries: state.common.industries }))(
  AddEditVendorAttributeOverrideModal
);
