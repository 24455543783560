import { memo, useEffect, useState } from "react";
import {
  isBlankNewContact,
  newContact,
  validateContactSelector,
} from "../../../vendorrisk/components/ContactSelector";
import ModalV2 from "../ModalV2";
import Button from "../core/Button";
import PillLabel from "../../../vendorrisk/components/PillLabel";
import { LabelColor } from "../../types/label";
import TextField from "../TextField";
import IconButton from "../IconButton";
import "../../style/components/AddRecipientsModal.scss";
import { IVendorContactResponse } from "../../types/vendorContact";
import { SelectV2Multi } from "../SelectV2";
import LoadingIcon from "../core/LoadingIcon";
import VerifiedVendorContactSelector from "../../../vendorrisk/components/remediation/VerifiedVendorContactSelector";
import RichTextEditV2 from "../RichTextEditV2";

export interface IAddRecipientsModalProps {
  existingRecipientEmails: string[];
  emailsToRemove: string[];
  setEmailsToRemove: (emails: string[]) => void;
  vendorContacts?: IVendorContactResponse[];
  newlySelectedVendorContactEmails?: string[];
  setSelectedVendorContactEmails: (emails: string[]) => void;
  newContacts: newContact[];
  addNewContact: () => void;
  updateNewContact: (tempId: string, fields: Partial<newContact>) => void;
  deleteNewContact: (tempId: string) => void;
  onSubmit: () => any | Promise<any>;
  onClose: () => void;
  active: boolean;
  message: string;
  onMessageChange: (v: string) => void;
  emailOnly: boolean;
  hasPublicSurveys: boolean;
  vendorId?: number;
  vendorName?: string;
  isVendorPortal?: boolean;
}

// Now a manage recipients modal but re-naming things is hard
const AddRecipientsModal = (props: IAddRecipientsModalProps) => {
  //always create one new recipient if there are none
  const { vendorContacts, newlySelectedVendorContactEmails } = props;
  useEffect(() => {
    if (props.newContacts.length === 0) {
      props.addNewContact();
    }
  }, [props.newContacts]);

  const [loading, setLoading] = useState(false);

  const nonBlackNewContacts = props.newContacts.filter(
    (c) => !isBlankNewContact(c)
  );
  const hasContacts =
    nonBlackNewContacts.length > 0 ||
    (newlySelectedVendorContactEmails ?? []).length > 0;
  const hasValidNewContacts =
    props.newContacts.length === 0 ||
    props.newContacts.every((c) => isBlankNewContact(c)) ||
    validateContactSelector([], props.newContacts, props.emailOnly);
  const hasValidContact = hasContacts && hasValidNewContacts;

  const onClickSubmit = () => {
    setLoading(true);
    props
      .onSubmit()
      .then(() => {
        props.onClose();
        setLoading(false);
      })
      .catch((e: any) => {
        // On error returned from promise, take us out of loading state but keep modal open.
        setLoading(false);
        throw e;
      });
  };

  const onSelectVendorContact = (emailAddress: string) => {
    if (
      props.newlySelectedVendorContactEmails &&
      newlySelectedVendorContactEmails
    ) {
      const emails = [...newlySelectedVendorContactEmails];
      const idx = emails.indexOf(emailAddress);
      if (idx === -1) {
        emails.push(emailAddress);
      } else {
        emails.splice(idx, 1);
      }
      props.setSelectedVendorContactEmails(emails);
    }
  };

  const canRemoveEmails =
    props.existingRecipientEmails.length - props.emailsToRemove.length > 1;

  const removableEmails = props.existingRecipientEmails?.filter(
    (e) => !props.emailsToRemove.includes(e)
  );

  const useVerifiedVendorContactSelector =
    props.hasPublicSurveys && !props.isVendorPortal;

  return (
    <ModalV2
      className={"add-recipients-modal"}
      active={props.active}
      onClose={props.onClose}
      headerContent={"Manage collaborators"}
      footerContent={
        <>
          <Button onClick={props.onClose} tertiary>
            Cancel
          </Button>
          <Button
            onClick={onClickSubmit}
            disabled={!hasValidContact && props.emailsToRemove.length === 0}
            loading={loading}
          >
            Update collaborators
          </Button>
        </>
      }
    >
      <div className={"form-grid"}>
        <div className={"original-recipients"}>
          <h3>Current collaborators</h3>
          <p>
            These people have been invited to manage this remediation request.
          </p>
        </div>
        <div className={"original-emails"}>
          {props.existingRecipientEmails.length === 0 && (
            <LoadingIcon size={30} />
          )}
          {removableEmails.map((e) => (
            <PillLabel
              key={e}
              color={LabelColor.Blue}
              removeable={canRemoveEmails}
              onRemoveClick={() =>
                props.setEmailsToRemove([...props.emailsToRemove, e])
              }
              onClick={
                canRemoveEmails
                  ? () => props.setEmailsToRemove([...props.emailsToRemove, e])
                  : undefined
              }
              popupContent={
                canRemoveEmails
                  ? "Click to remove this email address from this remediation request"
                  : undefined
              }
            >
              {e}
            </PillLabel>
          ))}
        </div>
        {vendorContacts &&
          vendorContacts.length > 0 &&
          newlySelectedVendorContactEmails &&
          props.setSelectedVendorContactEmails && (
            <>
              <div className={"vendor-contacts-labels"}>
                <h3>Select existing vendor contacts</h3>
                <p>{"Type to select any existing vendor contacts"}</p>
              </div>
              <div className={"add-vendor-contacts"}>
                <div className={"label-list"}>
                  {props.newlySelectedVendorContactEmails?.map((e) => (
                    <PillLabel
                      key={e}
                      color={LabelColor.Blue}
                      removeable
                      constrained
                      capitalized={false}
                      onRemoveClick={() => onSelectVendorContact(e)}
                    >
                      {" "}
                      {e}
                    </PillLabel>
                  ))}
                </div>
                <SelectV2Multi
                  className={"vendor-contact-select"}
                  options={vendorContacts
                    .filter(
                      (c) =>
                        (!props.hasPublicSurveys ||
                          c.emailDomainPartOfVendor) &&
                        c.emailAddress &&
                        !props.existingRecipientEmails.includes(c.emailAddress)
                    )
                    .map((c) => ({
                      value: c.emailAddress as string,
                      label: c.emailAddress as string,
                    }))}
                  value={newlySelectedVendorContactEmails.map((e) => ({
                    value: e,
                    label: e,
                  }))}
                  controlShouldRenderValue={false}
                  isMulti
                  isSearchable
                  onChange={(options) => {
                    if (options) {
                      props.setSelectedVendorContactEmails(
                        options.map((o) => o.value as string)
                      );
                    }
                  }}
                />
              </div>
            </>
          )}
        <div className={"new-recipients-labels"}>
          <h3>Add new collaborators</h3>
          <p>
            Enter the details of the collaborator and we’ll add them to this
            remediation request.
          </p>
        </div>
        <div className={"new-recipients"}>
          {!useVerifiedVendorContactSelector && (
            <>
              {props.newContacts.map((c) => (
                <div className={"add-recipient"} key={c.tempId}>
                  <div className="form-contents" key={c.tempId}>
                    {!props.emailOnly && (
                      <>
                        <div className={"name-fields"}>
                          <TextField
                            type="text"
                            className="half-input"
                            value={c.firstName ?? ""}
                            required
                            placeholder="First name"
                            onChanged={(val) =>
                              props.updateNewContact(c.tempId, {
                                firstName: val,
                              })
                            }
                          />
                          <TextField
                            type="text"
                            className="half-input"
                            value={c.lastName ?? ""}
                            required
                            placeholder="Last name"
                            onChanged={(val) =>
                              props.updateNewContact(c.tempId, {
                                lastName: val,
                              })
                            }
                          />
                        </div>
                        <TextField
                          type="text"
                          placeholder="Title (optional)"
                          value={c.title ?? ""}
                          onChanged={(val) =>
                            props.updateNewContact(c.tempId, {
                              title: val,
                            })
                          }
                        />
                      </>
                    )}
                    <TextField
                      type="email"
                      value={c.email}
                      placeholder="Email address"
                      required
                      onChanged={(val) =>
                        props.updateNewContact(c.tempId, {
                          email: val,
                        })
                      }
                    />
                  </div>
                  <IconButton
                    icon={<span className="cr-icon-trash" />}
                    onClick={() => props.deleteNewContact(c.tempId)}
                    disabled={props.newContacts.length < 2}
                  />
                </div>
              ))}
              <Button tertiary onClick={props.addNewContact}>
                + Add another recipient
              </Button>
            </>
          )}
          {useVerifiedVendorContactSelector && (
            <VerifiedVendorContactSelector
              canDeselectNew={false}
              hideDescription
              hideCheckboxes
              disableDeleteWhenSingleNewContact
              vendorId={props.vendorId ?? 0}
              vendorName={props.vendorName ?? ""}
              newContacts={props.newContacts}
              addNewContact={props.addNewContact}
              updateNewContact={props.updateNewContact}
              deleteNewContact={props.deleteNewContact}
              userSelectionDisabledMessage={`As there are one or more risks from shared questionnaires selected, remediation can only be requested from a person with an email domain belonging to ${props.vendorName}.`}
            />
          )}
        </div>
        <div className={"message-labels"}>
          <h3>Add a message for new collaborators</h3>
          <p>
            This message will be sent to new collaborators of this remediation
            request.
          </p>
        </div>
        <div className={"message"}>
          <RichTextEditV2
            value={props.message}
            onChange={props.onMessageChange}
          />
        </div>
      </div>
    </ModalV2>
  );
};

export default memo(AddRecipientsModal);
