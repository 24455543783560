import { Component } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import UserAvatar from "../../../_common/components/UserAvatar";
import Gravatar from "../../../_common/components/core/Gravatar";

import Button from "../../../_common/components/core/Button";
import { closeModal } from "../../../_common/reducers/commonActions";

import {
  addRemediationRequestUsers,
  fetchRemediationRequestUsers,
} from "../../../_common/reducers/remediationRequest.actions";

import { createVendorContact } from "../../reducers/cyberRiskActions";
import { validateEmail } from "../../../_common/helpers";

import "../../style/components/RemediationAddUserModal.scss";
import Icon from "../../../_common/components/core/Icon";
import {
  addDefaultUnknownErrorAlert,
  addSimpleErrorAlert,
} from "../../../_common/reducers/messageAlerts.actions";
import { fetchVendorMgtContacts } from "../../reducers/vendorContacts.actions";

export const RemediationAddUserModalName = "RemediationAddUserModal";

export class RemediationAddUserForm extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    remediationRequestId: PropTypes.number.isRequired,
    vendorId: PropTypes.number,
    isSubsidiary: PropTypes.bool.isRequired,
    vendorName: PropTypes.string.isRequired,
    users: PropTypes.array.isRequired,
    invites: PropTypes.array.isRequired,
    contacts: PropTypes.array.isRequired,
    isValidCallback: PropTypes.func.isRequired,
    isVendorPortal: PropTypes.bool.isRequired,
    canSelectNone: PropTypes.bool,
  };

  static defaultProps = {
    vendorId: null,
    canSelectNone: false,
  };

  state = {
    selectedEmail: null,
    creatingNewUser: false,
    newUserName: "",
    newUserTitle: "",
    newUserEmail: "",
  };

  componentDidMount() {
    this.props.isValidCallback(this.isValid());
  }

  getExistingEmails = () => {
    return this.props.users
      .map((u) => u.email)
      .concat(this.props.invites.map((u) => u.email));
  };

  onSelectUser = (email) => {
    if (email === this.state.selectedEmail) {
      this.setState({ selectedEmail: null });
      this.props.isValidCallback(this.props.canSelectNone);
    } else {
      this.setState({ selectedEmail: email, creatingNewUser: false });
      this.props.isValidCallback(true);
    }
  };

  isValid = () => {
    if (this.props.canSelectNone && !this.state.creatingNewUser) {
      return true;
    }

    return (
      this.state.selectedEmail !== null ||
      (this.state.creatingNewUser === true &&
        this.state.newUserName !== "" &&
        this.state.newUserEmail !== "") ||
      ((this.props.isVendorPortal ||
        !this.props.vendorId ||
        this.props.isSubsidiary) &&
        this.state.newUserEmail !== "")
    );
  };

  setStateAndUpdateValidation = (newState) => {
    this.setState(newState, () => {
      this.props.isValidCallback(this.isValid());
    });
  };

  // Does a full validation and returns a form validation error if anything is wrong.
  fullValidate = () => {
    if (!this.isValid()) {
      return new Error("Form fields missing.");
    }

    if (!this.state.creatingNewUser) {
      return null;
    }

    if (!validateEmail(this.state.newUserEmail)) {
      return new Error(
        `${this.state.newUserEmail} is not a valid email address.`
      );
    }

    // Ensure this email isn't already in the remediation plan
    const existingEmails = this.getExistingEmails();
    if (existingEmails.indexOf(this.state.newUserEmail) > -1) {
      return new Error(`${this.state.newUserEmail} is already added.`);
    }

    return null;
  };

  // Submit can be called by the parent component. It will return a promise that will
  // throw if an error occurs, or resolve if the add user process is successful.
  submit = () => {
    if (
      this.props.isVendorPortal ||
      !this.props.vendorId ||
      this.props.isSubsidiary ||
      this.state.creatingNewUser
    ) {
      let prom = Promise.resolve();

      if (
        !this.props.isVendorPortal &&
        this.props.vendorId &&
        !this.props.isSubsidiary
      ) {
        prom = this.props
          .dispatch(
            createVendorContact(this.props.vendorId, {
              name: this.state.newUserName,
              title: this.state.newUserTitle,
              emailAddress: this.state.newUserEmail,
            })
          )
          .then(() => {
            // Fetch the contacts again in the background
            this.props.dispatch(
              fetchVendorMgtContacts(this.props.vendorId, true)
            );
          });
      }

      return prom
        .then(() =>
          this.props.dispatch(
            addRemediationRequestUsers(this.props.remediationRequestId, [
              this.state.newUserEmail,
            ])
          )
        )
        .then(() =>
          this.props.dispatch(
            fetchRemediationRequestUsers(this.props.remediationRequestId, true)
          )
        );
    }

    if (!this.state.selectedEmail && this.props.canSelectNone) {
      return Promise.resolve();
    }

    return this.props
      .dispatch(
        addRemediationRequestUsers(this.props.remediationRequestId, [
          this.state.selectedEmail,
        ])
      )
      .then(() =>
        this.props.dispatch(
          fetchRemediationRequestUsers(this.props.remediationRequestId, true)
        )
      );
  };

  getAddedUser = () => {
    if (this.state.creatingNewUser) {
      return {
        name: this.state.newUserName,
        email: this.state.newUserEmail,
      };
    }

    return { email: this.state.selectedEmail };
  };

  render() {
    const existingEmails = this.getExistingEmails();
    const filteredContacts = this.props.contacts.filter(
      (c) =>
        existingEmails.indexOf(c.emailAddress) === -1 &&
        this.props.invites.indexOf(c.emailAddress) === -1
    );

    return (
      <div className="remediation-add-user-form">
        {existingEmails.length > 0 && <h3>Users already added</h3>}
        {existingEmails.length > 0 && (
          <div className="user-rows">
            {this.props.users.map((u) => (
              <div className="user-row" key={u.email}>
                <UserAvatar avatar={u.avatar} />
                {u.name} ({u.email})
              </div>
            ))}
            {this.props.invites.map((email) => (
              <div className="user-row invite" key={email}>
                <div className="img-placeholder" />
                {email} (pending)
              </div>
            ))}
          </div>
        )}
        {filteredContacts.length > 0 && (
          <>
            <h3>
              Choose recipient
              <div className="popup-description">
                <Icon name="info" />
                <div className="content">
                  Choose the representative from {this.props.vendorName} that
                  you want to request remediation from.
                </div>
              </div>
            </h3>
            <div className="user-rows">
              {filteredContacts.map((c) => (
                <div
                  key={c.emailAddress}
                  className={classnames("user-row", "selectable", {
                    selected: this.state.selectedEmail === c.emailAddress,
                  })}
                  onClick={() => this.onSelectUser(c.emailAddress)}
                >
                  <div className="user-avatar">
                    <Gravatar className="avatar" email={c.emailAddress} />
                  </div>
                  {c.name} ({c.emailAddress})
                </div>
              ))}
            </div>
          </>
        )}
        {this.props.isVendorPortal ||
        !this.props.vendorId ||
        this.props.isSubsidiary ||
        this.state.creatingNewUser ? (
          <form
            className="new-user-form"
            onSubmit={(evt) => evt.preventDefault()}
          >
            {!this.props.isVendorPortal &&
              this.props.vendorId &&
              !this.props.isSubsidiary && (
                <>
                  <input
                    type="text"
                    value={this.state.newUserName}
                    onChange={(e) =>
                      this.setStateAndUpdateValidation({
                        newUserName: e.target.value,
                      })
                    }
                    placeholder="Full name"
                    required
                  />
                  <input
                    type="text"
                    value={this.state.newUserTitle}
                    onChange={(e) =>
                      this.setStateAndUpdateValidation({
                        newUserTitle: e.target.value,
                      })
                    }
                    placeholder="Title (optional)"
                  />
                </>
              )}
            <input
              type="email"
              value={this.state.newUserEmail}
              onChange={(e) =>
                this.setStateAndUpdateValidation({
                  newUserEmail: e.target.value,
                })
              }
              placeholder="Email"
              required
            />
          </form>
        ) : (
          <div
            className="send-to-new"
            onClick={() =>
              this.setStateAndUpdateValidation({
                selectedEmail: null,
                creatingNewUser: true,
              })
            }
          >
            + Send to new contact
          </div>
        )}
      </div>
    );
  }
}

class RemediationAddUserModal extends Component {
  static propTypes = {
    modalData: PropTypes.shape({
      remediationRequestId: PropTypes.number.isRequired,
      vendorId: PropTypes.number,
      isSubsidiary: PropTypes.bool.isRequired,
      vendorName: PropTypes.string.isRequired,
      users: PropTypes.array.isRequired,
      invites: PropTypes.array.isRequired,
      contacts: PropTypes.array.isRequired,
      isVendorPortal: PropTypes.bool.isRequired,
    }).isRequired,
    dispatch: PropTypes.func.isRequired,
  };

  state = {
    loading: false,
    formValid: false,
  };

  onClickAddUser = () => {
    // First try and validate
    const validationErr = this.remediationForm.fullValidate();
    if (validationErr) {
      this.props.dispatch(addSimpleErrorAlert(validationErr.message));
      return;
    }

    this.setState({ loading: true });

    this.remediationForm
      .submit()
      .then(() => this.props.dispatch(closeModal()))
      .catch((e) => {
        this.props.dispatch(addDefaultUnknownErrorAlert(e.message));
      });
  };

  render() {
    return (
      <div className="modal-content remediation-add-user-modal">
        <h2>Add user to remediation request</h2>
        <RemediationAddUserForm
          dispatch={this.props.dispatch}
          remediationRequestId={this.props.modalData.remediationRequestId}
          vendorId={this.props.modalData.vendorId}
          isSubsidiary={this.props.modalData.isSubsidiary}
          vendorName={this.props.modalData.vendorName}
          contacts={this.props.modalData.contacts}
          users={this.props.modalData.users}
          invites={this.props.modalData.invites}
          isVendorPortal={this.props.modalData.isVendorPortal}
          isValidCallback={(formValid) => this.setState({ formValid })}
          ref={(ref) => (this.remediationForm = ref)}
        />
        <div className="btn-group">
          <Button
            primary
            disabled={this.state.loading || !this.state.formValid}
            loading={this.state.loading}
            onClick={this.onClickAddUser}
          >
            Add user
          </Button>
          <Button
            disabled={this.state.loading}
            onClick={() => this.props.dispatch(closeModal())}
          >
            Cancel
          </Button>
        </div>
      </div>
    );
  }
}

export default RemediationAddUserModal;
