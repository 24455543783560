import { Component } from "react";
import PropTypes from "prop-types";
import Button from "../../../_common/components/core/Button";
import ColorCheckbox from "../ColorCheckbox";
import PillLabel from "../PillLabel";
import { closeModal } from "../../../_common/reducers/commonActions";
import {
  changeOrgSquatFlags,
  fetchSquatDetails,
} from "../../reducers/cyberRiskActions";
import "../../style/components/TyposquattingIgnoreModal.scss";
import { trackEvent } from "../../../_common/tracking";
import { addSimpleErrorAlert } from "../../../_common/reducers/messageAlerts.actions";
import { SidePopupV2 } from "../../../_common/components/DismissablePopup";

export const TyposquattingIgnoreModalName = "TyposquattingIgnoreModalName";

class TyposquattingIgnoreModal extends Component {
  static propTypes = {
    modalData: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
  };

  state = {
    loading: false,
    selectedRisk:
      this.props.modalData.allowedOperations &&
      !this.props.modalData.allowedOperations.includes("not_risk") &&
      !this.props.modalData.allowedOperations.includes("owned_by_us")
        ? "risk_accepted"
        : this.props.modalData.existingState,
  };

  onSubmit = (evt) => {
    const reason = this.state.selectedRisk;
    evt.preventDefault();
    this.setState({
      loading: true,
    });

    trackEvent("IgnoreTyposquatDomain", { reason });

    this.props
      .dispatch(
        changeOrgSquatFlags(
          this.props.modalData.ownershipIDs,
          this.props.modalData.rootHostname,
          reason
        )
      )
      .then(() => {
        this.setState({
          loading: false,
        });

        if (this.props.modalData.clearSelection) {
          this.props.modalData.clearSelection();
        }

        if (this.props.modalData.runRefreshData) {
          this.props.dispatch(
            fetchSquatDetails(
              this.props.modalData.rootHostnameID,
              this.props.modalData.rootHostname,
              true
            )
          );
        }
        this.props.dispatch(closeModal());
      })
      .catch((e) => {
        this.props.dispatch(addSimpleErrorAlert(e.message));
      });
  };

  render() {
    return this.props.modalData.isV2Layout ? this.renderV2() : this.renderV1();
  }

  // Render as a floating surface
  renderV2 = () => {
    let allowedReasons = this.props.modalData.allowedOperations;

    let titleText = "Ignore Risks";

    let promptText = `If you ignore the risks associated with '${this.props.modalData.squatHostname}', you will no longer receive notifications when activity occurs on this domain. Please select the reason for ignoring the risk.`;

    if (this.props.modalData.ignoreReasonChanging) {
      titleText = "Update Reason";
      promptText = `Risks for '${this.props.modalData.squatHostname}' are already ignored. Select the new reason for ignoring these risks.`;
    }

    if (this.props.modalData.squatHostname == "") {
      promptText = `If you ignore the risks associated with these domains, you will no longer receive notifications when activity occurs on them. Please select the reason for ignoring the risks.`;
      if (this.props.modalData.ignoreReasonChanging) {
        titleText = "Update Reasons";
        promptText =
          "Select the new reason for ignoring risks for these domains";
      }
    }
    return (
      <div className={"typosquat-ignore-modal"}>
        <div className={"modal-header"}>
          <h2>{titleText}</h2>
        </div>
        <div className={"modal-content desc-text"} style={{ minWidth: 450 }}>
          {promptText}
        </div>
        <div className={"desc-body"}>
          <div className={"desc-left"}>
            <div className={"heading"}>{"Reason for ignoring"}</div>
            <div className={"body"}>
              {"Please select the reason for ignoring the risk."}
            </div>
          </div>
          <div className={"desc-right"}>
            <div className={"option"}>
              <ColorCheckbox
                radio
                color="blue"
                checked={this.state.selectedRisk == "owned_by_us"}
                onClick={() => this.setState({ selectedRisk: "owned_by_us" })}
                disabled={!allowedReasons.includes("owned_by_us")}
              />
              <span
                className={"label"}
                onClick={() => {
                  if (allowedReasons.includes("owned_by_us")) {
                    this.setState({ selectedRisk: "owned_by_us" });
                  }
                }}
              >
                {"Owned by us"}
              </span>
              <SidePopupV2
                key={"owned"}
                text={
                  "This domain has been registered by us, and is therefore not a risk."
                }
              >
                <div className={"cr-icon-info"} />
              </SidePopupV2>
            </div>
            <div className={"option"}>
              <ColorCheckbox
                radio
                color="blue"
                checked={this.state.selectedRisk == "not_risk"}
                onClick={() => this.setState({ selectedRisk: "not_risk" })}
                disabled={!allowedReasons.includes("not_risk")}
              />
              <span
                className={"label"}
                onClick={() => {
                  if (allowedReasons.includes("not_risk")) {
                    this.setState({ selectedRisk: "not_risk" });
                  }
                }}
              >
                {"Legitimate website"}
              </span>
              <SidePopupV2
                key={"website"}
                text={"This is a legitimate website for another organization."}
              >
                <div className={"cr-icon-info"} />
              </SidePopupV2>
            </div>
            <div className={"option"}>
              <ColorCheckbox
                radio
                color="blue"
                checked={this.state.selectedRisk == "risk_accepted"}
                onClick={() => this.setState({ selectedRisk: "risk_accepted" })}
                disabled={!allowedReasons.includes("risk_accepted")}
              />
              <span
                className={"label"}
                onClick={() => {
                  if (allowedReasons.includes("risk_accepted")) {
                    this.setState({ selectedRisk: "risk_accepted" });
                  }
                }}
              >
                {"Risk accepted"}
              </span>
              <SidePopupV2
                key={"accepted"}
                text={
                  "We do not believe this permutation is likely to be used to impersonate our organization, and therefore accept the risk."
                }
              >
                <div className={"cr-icon-info"} />
              </SidePopupV2>
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <Button
            disabled={this.state.loading}
            onClick={() => {
              this.props.dispatch(closeModal());
            }}
            tertiary
          >
            {"Cancel"}
          </Button>
          <Button
            danger={false}
            loading={this.state.loading}
            disabled={
              this.state.loading || this.state.selectedRisk === "not_set"
            }
            onClick={this.onSubmit}
          >
            <div className={"cr-icon-noeye"} />
            {"Ignore"}
          </Button>
        </div>
      </div>
    );
  };

  // Render as full screen with modal area
  renderV1 = () => {
    let allowedReasons = this.props.modalData.allowedOperations;

    let titleText = "Ignore Risks";

    let promptText = `If you ignore the risks associated with '${this.props.modalData.squatHostname}', you will no longer receive notifications when activity occurs on this domain. Please select the reason for ignoring the risk.`;

    if (this.props.modalData.ignoreReasonChanging) {
      titleText = "Update Reason";
      promptText = `Risks for '${this.props.modalData.squatHostname}' are already ignored. Select the new reason for ignoring these risks.`;
    }

    if (this.props.modalData.squatHostname == "") {
      promptText = `If you ignore the risks associated with these domains, you will no longer receive notifications when activity occurs on them. Please select the reason for ignoring the risks.`;
      if (this.props.modalData.ignoreReasonChanging) {
        titleText = "Update Reasons";
        promptText =
          "Select the new reason for ignoring risks for these domains";
      }
    }

    return (
      <div className="modal-content typosquat-ignore-modal">
        <h3 className="center-text">{titleText}</h3>
        <p>{promptText}</p>
        <form onSubmit={this.onSubmit}>
          <div className="cols">
            <div className="update-radio">
              <ColorCheckbox
                color="blue"
                radio
                disabled={!allowedReasons.includes("owned_by_us")}
                checked={this.state.selectedRisk == "owned_by_us"}
                onClick={() => this.setState({ selectedRisk: "owned_by_us" })}
                label={
                  <div>
                    <PillLabel key="reason_pill_B" color="trendypink">
                      {"Owned By Us"}
                    </PillLabel>
                    <div className="pill-desc">
                      This domain has been registered by us, and is therefore
                      not a risk.
                    </div>
                  </div>
                }
              />
              <ColorCheckbox
                color="blue"
                radio
                disabled={!allowedReasons.includes("not_risk")}
                checked={this.state.selectedRisk == "not_risk"}
                onClick={() => this.setState({ selectedRisk: "not_risk" })}
                label={
                  <div>
                    <PillLabel key="reason_pill_C" color="yellow">
                      {"Legitimate Website"}
                    </PillLabel>
                    <div className="pill-desc">
                      This is a legitimate website for another organisation.
                    </div>
                  </div>
                }
              />
              <ColorCheckbox
                color="blue"
                radio
                disabled={!allowedReasons.includes("risk_accepted")}
                checked={this.state.selectedRisk == "risk_accepted"}
                onClick={() => this.setState({ selectedRisk: "risk_accepted" })}
                label={
                  <div>
                    <PillLabel key="reason_pill_A" color="mountainmeadow">
                      {"Risk Accepted"}
                    </PillLabel>
                    <div className="pill-desc">
                      We do not believe this permutation is likely to be used to
                      impersonate our organisation, and therefore accept the
                      risk.
                    </div>
                  </div>
                }
              />
              <Button
                className="apply-button"
                filledPrimary
                onClick={this.onSubmit}
                disabled={
                  this.state.loading ||
                  (this.state.selectedRisk != "risk_accepted" &&
                    this.state.selectedRisk != "owned_by_us" &&
                    this.state.selectedRisk != "not_risk")
                }
                loading={this.state.loading}
              >
                Apply
              </Button>
            </div>
          </div>
        </form>
      </div>
    );
  };
}

export default TyposquattingIgnoreModal;
