import { FC, useCallback, useEffect, useState } from "react";

import Button from "../../../_common/components/core/Button";
import {
  createVendorContact,
  updateVendorContact,
} from "../../reducers/cyberRiskActions";

import "../../style/components/EditVendorContactDetailsModal.scss";
import {
  addDefaultSuccessAlert,
  addDefaultUnknownErrorAlert,
} from "../../../_common/reducers/messageAlerts.actions";
import { fetchVendorMgtContacts } from "../../reducers/vendorContacts.actions";
import TextField, {
  MaxLengthType,
  MinLengthType,
  useTextWithValid,
} from "../../../_common/components/TextField";
import ModalV2, { BaseModalProps } from "../../../_common/components/ModalV2";
import { IVendorContactResponse } from "../../../_common/types/vendorContact";
import { useAppDispatch } from "../../../_common/types/reduxHooks";

export type newContact = {
  emailAddress: string;
  notes?: string;
  phoneMobile?: string;
  name: string;
  title?: string;
};

/**
 * <EditVendorContactDetailsModal />
 */
interface EditVendorContactDetailsModalProps extends BaseModalProps {
  vendorId: number;
  contact?: IVendorContactResponse;
  managedOrgId?: number;
  dontSubmit?: boolean; // don't call the backend to create a new contact
  onNewContact?: (c: newContact) => void; // returns a new contact on submit
}

const EditVendorContactDetailsModal: FC<EditVendorContactDetailsModalProps> = (
  props
) => {
  const [loading, setLoading] = useState(false);

  // contact state
  const [name, nameValid, setName] = useTextWithValid(
    props.contact?.name ?? "",
    !!props.contact?.name
  );
  const [title, _titleValid, setTitle] = useTextWithValid(
    props.contact?.title ?? "",
    !!props.contact?.title
  );
  const [notes, _notesValid, setNotes] = useTextWithValid(
    props.contact?.notes ?? "",
    !!props.contact?.notes
  );
  const [emailAddress, emailAddressValid, setEmailAddress] = useTextWithValid(
    props.contact?.emailAddress ?? "",
    !!props.contact?.emailAddress
  );
  const [phoneNumber, _phoneNumberValid, setPhoneNumber] = useTextWithValid(
    props.contact?.phoneMobile ?? "",
    !!props.contact?.phoneMobile
  );

  useEffect(() => {
    setName(props.contact?.name ?? "", !!props.contact?.name);
    setTitle(props.contact?.title ?? "", !!props.contact?.title);
    setNotes(props.contact?.notes ?? "", !!props.contact?.notes);
    setEmailAddress(
      props.contact?.emailAddress ?? "",
      !!props.contact?.emailAddress
    );
    setPhoneNumber(
      props.contact?.phoneMobile ?? "",
      !!props.contact?.phoneMobile
    );
  }, [props.contact]);

  const doClose = () => {
    setName("", false);
    setTitle("", false);
    setNotes("", false);
    setEmailAddress("", false);
    setPhoneNumber("", false);
    props.onClose();
  };

  const dispatch = useAppDispatch();

  const doSubmit = useCallback(() => {
    setLoading(true);

    const contact: {
      emailAddress: string;
      notes: string;
      phoneMobile: string;
      name: string;
      id: number | undefined;
      title: string;
    } = {
      name,
      title,
      emailAddress,
      phoneMobile: phoneNumber,
      notes,
      id: props.contact ? props.contact.id : undefined,
    };

    if (props.onNewContact) {
      props.onNewContact(contact);
    }

    if (props.dontSubmit || !props.vendorId) {
      doClose();
      return;
    }

    let promise: Promise<any>;

    if (!!props.contact) {
      promise = dispatch(updateVendorContact(contact));
    } else {
      promise = dispatch(createVendorContact(props.vendorId, contact));
    }

    promise
      .then(() => {
        dispatch(
          addDefaultSuccessAlert(
            `Successfully ${
              props.contact ? "updated" : "created"
            } vendor contact`
          )
        );
        dispatch(fetchVendorMgtContacts(props.vendorId, true));
        doClose();
      })
      .catch(() => {
        dispatch(
          addDefaultUnknownErrorAlert(
            `Error ${props.contact ? "updating" : "creating"} vendor contact`
          )
        );
      })
      .finally(() => {
        setLoading(false);
      });
  }, [
    !!props.contact,
    props.vendorId,
    props.onNewContact,
    props.dontSubmit,
    name,
    title,
    notes,
    emailAddress,
    phoneNumber,
  ]);

  return (
    <ModalV2
      active={props.active}
      onClose={doClose}
      headerContent={props.contact ? "Edit contact" : "Add new contact"}
      className={"edit-vendor-contact-details"}
      footerContent={
        <>
          <Button tertiary onClick={doClose} disabled={loading}>
            Cancel
          </Button>
          <Button
            filledPrimary
            loading={loading}
            disabled={!nameValid || !emailAddressValid}
            onClick={doSubmit}
          >
            {props.contact ? "Edit contact" : "Save contact"}
          </Button>
        </>
      }
    >
      <div className={"contact-form"}>
        <div className={"label required"}>Name</div>
        <TextField
          value={name}
          onChanged={setName}
          required
          maxLength={MaxLengthType.contactName}
          minLength={MinLengthType.contactName}
          placeholder={"E.g. John Doe"}
        />
        <div className={"label required"}>Email</div>
        <TextField
          value={emailAddress}
          onChanged={setEmailAddress}
          required
          maxLength={MaxLengthType.label}
          placeholder={"E.g. name@example.com"}
          type={"email"}
        />
        <div className={"label"}>Position</div>
        <TextField
          value={title}
          onChanged={setTitle}
          maxLength={MaxLengthType.label}
          placeholder={"E.g. Business owner"}
        />
        <div className={"label"}>Contact number</div>
        <TextField
          value={phoneNumber}
          onChanged={setPhoneNumber}
          maxLength={MaxLengthType.label}
          placeholder={"Phone or mobile number"}
          type={"phone"}
        />
        <div className={"label"}>Notes</div>
        <TextField
          value={notes}
          onChanged={setNotes}
          maxLength={MaxLengthType.reasonableLength}
          multiLine
          placeholder={"Other information"}
        />
      </div>
    </ModalV2>
  );
};

export default EditVendorContactDetailsModal;
