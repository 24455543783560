import { FC, useMemo } from "react";
import "./RiskVendorAdditionalEvidences.scss";
import { AdditionalEvidenceRiskEvidence } from "../../../_common/types/vendorSummary";
import XTable, {
  IXTableRow,
  XTableCell,
  SortDirection,
} from "../../../_common/components/core/XTable";
import { columnSortDef, useSorting } from "../../../_common/hooks";
import { Link } from "react-router-dom";

interface RiskVendorAdditionalEvidenceProps {
  additionalEvidences: AdditionalEvidenceRiskEvidence[];
  getUrlPrefix: () => string;
  forSpecificAssessmentID?: number | undefined;
  isForPublishedAssessment?: boolean | undefined;
}

const RiskVendorAdditionalEvidences: FC<RiskVendorAdditionalEvidenceProps> = ({
  additionalEvidences,
  getUrlPrefix,
  forSpecificAssessmentID,
  isForPublishedAssessment,
}) => {
  const tableSortingFuncs = useTableSortingFuncs();
  const [sortedAdditionalEvidences, sortedBy, onSortChange] = useSorting<
    AdditionalEvidenceRiskEvidence,
    tableColumnId
  >(additionalEvidences, "name", SortDirection.ASC, tableSortingFuncs);

  const rows: IXTableRow[] = [];
  sortedAdditionalEvidences.forEach((additionalEvidence) => {
    let evidenceLink = `${getUrlPrefix()}/evidence/details/${
      additionalEvidence.id
    }`;
    if (forSpecificAssessmentID && isForPublishedAssessment) {
      evidenceLink += `/byassessment/${forSpecificAssessmentID}`;
    }

    rows.push({
      id: additionalEvidence.id,
      cells: [
        <XTableCell key={"name"}>
          <div className="link-container">
            <Link to={evidenceLink} target="_blank" rel="noopener noreferrer">
              {additionalEvidence.name}
            </Link>
            <span className="cr-icon-external-link" />
          </div>
        </XTableCell>,
      ],
    });
  });

  return (
    <XTable
      className="risk-vendor-addtional-evidences"
      columnHeaders={[
        {
          id: "name",
          text: "Additional evidence name",
          sortable: true,
        },
      ]}
      rows={rows}
      sortedBy={sortedBy}
      onSortChange={onSortChange}
    />
  );
};

type tableColumnId = "name";

const useTableSortingFuncs = () =>
  useMemo<Record<tableColumnId, columnSortDef<AdditionalEvidenceRiskEvidence>>>(
    () => ({
      name: {
        orderFuncs: [(additionalEvidence) => additionalEvidence.name],
        sortDirsAsc: ["asc"],
        sortDirsDesc: ["desc"],
      },
    }),
    []
  );

export default RiskVendorAdditionalEvidences;
