import moment from "moment/moment";
import {
  getLocalStorageItemString,
  setLocalStorageItemString,
} from "../session";
import { FC, useEffect, useState } from "react";
import { LabelColor } from "../types/label";
import PillLabel from "../../vendorrisk/components/PillLabel";

export enum NewFeatureType {
  EvidencePages = "EvidencePages",
}

const localStorageKey = (featureType: NewFeatureType) =>
  `newFeatureHidden_${featureType}`;

// maximum time for which a new feature pill should be visible for a given feature type
const featureVisibilityTime: Record<NewFeatureType, string> = {
  [NewFeatureType.EvidencePages]: "2023-03-26T12:00:00Z",
};

const shouldShowFeature = (featureType: NewFeatureType) => {
  if (
    featureVisibilityTime[featureType] &&
    moment().isAfter(moment(featureVisibilityTime[featureType]))
  ) {
    return false;
  }

  return !getLocalStorageItemString(localStorageKey(featureType));
};

// hideNewFeature can be used to hide the pill for a new feature permanently for the current user
// it should be called when handling events as a consequence of user actions that should result
// in a specific feature pill being removed
export const hideNewFeature = (featureType: NewFeatureType) =>
  setLocalStorageItemString(localStorageKey(featureType), "true");

interface INewFeaturePillProps {
  featureType: NewFeatureType;
}

// Show a New Feature blue pill for a given feature type
// The pill will not be shown if the feature has been marked as hidden in the
// local storage using the hideNewFeature function or if the feature visibility
// time has expired
const NewFeaturePill: FC<INewFeaturePillProps> = ({ featureType }) => {
  const [shouldShow, setShouldShow] = useState(false);

  useEffect(() => {
    setShouldShow(shouldShowFeature(featureType));
  }, [featureType]);

  return shouldShow ? (
    <PillLabel color={LabelColor.Blue} className={"new-feature-pill"}>
      New feature
    </PillLabel>
  ) : (
    <></>
  );
};

export default NewFeaturePill;
