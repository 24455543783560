import { FC } from "react";
import SlidePanelSection from "../../../vendorrisk/components/filter/SlidePanelSection";
import CloudProviderLogo from "../CloudProviderLogo";
import ColorCheckbox from "../../../vendorrisk/components/ColorCheckbox";
import { CloudProviderType, CloudProviderTypeNiceNames } from "../../api/types";
import { OrgAccessAppGuard } from "../../../_common/permissions";
import "./CloudProviderFilter.scss";
import withRequireEntitlement from "../../../_common/hocs/WithRequireEntitlement";

interface CloudProviderFilterProps {
  selected: CloudProviderType[];
  onChange: (selectedValues: CloudProviderType[]) => void;
}

const CloudProviderFilter: FC<CloudProviderFilterProps> = ({
  selected,
  onChange,
}) => {
  // TODO should we only show cloud providers we know they have? Or always allow selecting all 3?

  return (
    <SlidePanelSection
      title={"Cloud Providers"}
      classname={"cloud-provider-filter"}
    >
      <CloudProviderFilterValue
        providerType={"aws"}
        selected={selected}
        onChange={onChange}
      />
      <CloudProviderFilterValue
        providerType={"gcp"}
        selected={selected}
        onChange={onChange}
      />
      <CloudProviderFilterValue
        providerType={"azure"}
        selected={selected}
        onChange={onChange}
      />
    </SlidePanelSection>
  );
};

interface CloudProviderFilterValueProps {
  providerType: CloudProviderType;
  selected: CloudProviderType[];
  onChange: (selectedValues: CloudProviderType[]) => void;
}

const CloudProviderFilterValue: FC<CloudProviderFilterValueProps> = ({
  providerType,
  selected,
  onChange,
}) => {
  const isProviderSelected = selected.some((c) => c === providerType);

  return (
    <ColorCheckbox
      className={"cloud-provider-filter-value"}
      checked={isProviderSelected}
      label={
        <>
          <CloudProviderLogo provider={providerType} />{" "}
          {CloudProviderTypeNiceNames[providerType]}
        </>
      }
      onClick={() => {
        let selectedValues: CloudProviderType[];
        if (isProviderSelected) {
          selectedValues = selected.filter((c) => c !== providerType);
        } else {
          selectedValues = [...selected, providerType];
        }
        onChange(selectedValues);
      }}
    />
  );
};

export default withRequireEntitlement(CloudProviderFilter, OrgAccessAppGuard);
