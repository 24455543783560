import { FC, useEffect, useRef, useState } from "react";
import { useLocation, useRouteMatch } from "react-router-dom";
import {
  trackPageViewBackend,
  trackTimeOnPageBackend,
} from "../../_common/reducers/analytics";
import { useAppDispatch } from "../../_common/types/reduxHooks";

// check if the tab is currently in focus
const hasFocus = () => typeof document !== "undefined" && document.hasFocus();

// Any path starting with the following strings will be ignored.
export const ignoredPathsForPageviews = ["/admin", "/analysts"];

const shouldTrackPage = (path: string) =>
  !ignoredPathsForPageviews.some((checkPath) => path.startsWith(checkPath));

// For some paths, we want to replace params in the string with their actual values.
// NOTE: we're looking for paths that START WITH the below path matches, not exact matches.
export const pathReplacers: [string, (path: string, params: any) => string][] =
  [
    [
      "/reportexports/:currentTab",
      (path, params) => path.replace(":currentTab", params.currentTab),
    ],
    [
      "/home/:selectedTab",
      (path, params) => path.replace(":selectedTab", params.selectedTab),
    ],
    [
      "/vendorlist/:currentTab",
      (path, params) => path.replace(":currentTab", params.currentTab),
    ],
    [
      "/summaryreport/:module/:currentTab",
      (path, params) =>
        path
          .replace(":module", params.module)
          .replace(":currentTab", params.currentTab),
    ],
    [
      "/summaryreport/:module",
      (path, params) => path.replace(":module", params.module),
    ],
    [
      "/typosquatting/:domainId/:tab",
      (path, params) => path.replace(":tab", params.tab),
    ],
    [
      "/vendor/:vendorId/evidence/:currentTab",
      (path, params) => path.replace(":currentTab", params.currentTab),
    ],
    ["/settings/:tab", (path, params) => path.replace(":tab", params.tab)],
    [
      "/settings/create_integration/:type",
      (path, params) => path.replace(":type", params.type),
    ],
    [
      "/dataleaks/reporting/details/:currentTab",
      (path, params) => path.replace(":currentTab", params.currentTab),
    ],
    [
      "/dataleaks/reporting/overview/:currentTab",
      (path, params) => path.replace(":currentTab", params.currentTab),
    ],
    [
      "/breachnewsfeed/:currentTab",
      (path, params) => path.replace(":currentTab", params.currentTab),
    ],
    ["/trial", (path) => path.replace("/trial", "/registration/trial")],
  ];

const replacePath = (path: string, params: any) => {
  let replacedPath = path;
  if (params) {
    // See if we should replace the path with any of the path replacers
    for (let i = 0; i < pathReplacers.length; i++) {
      const [pathMatch, replaceFunc] = pathReplacers[i];
      if (path.startsWith(pathMatch)) {
        replacedPath = replaceFunc(path, params);
        break;
      }
    }
  }
  return replacedPath;
};

// PageviewTracker should be included once in any 'view'. It tracks a pageview
// to segment whenever the path changes. We use the route match path to strip away
// any variables (such as vendorId) from tracked paths.
// We include this in the PageHeader component so it's included on the vast majority
// of views by default.
//
// It will also track the amount of time the user spent on a particular page in increments of 500ms.
// It will not track time when the tab was not in focus.
// Note that the time on page event will not fire for users closing the tab
// as there is no reliable way to make the browser wait for the API call
const PageviewTracker: FC = () => {
  const dispatch = useAppDispatch();
  const { path, params } = useRouteMatch();
  const { hash, search } = useLocation();
  const referrer = document.referrer;
  const url = document.URL;

  const [focused, setFocused] = useState(hasFocus);
  const [milliseconds, setMilliseconds] = useState(0);
  const millisecondsRef = useRef(milliseconds);

  // subscribe to tab focus/blur events and update our focused status
  useEffect(() => {
    setFocused(hasFocus()); // Focus for additional renders

    const onFocus = () => setFocused(true);
    const onBlur = () => setFocused(false);

    window.addEventListener("focus", onFocus);
    window.addEventListener("blur", onBlur);

    return () => {
      window.removeEventListener("focus", onFocus);
      window.removeEventListener("blur", onBlur);
    };
  }, []);
  useEffect(() => {
    millisecondsRef.current = milliseconds;
  });

  // subscribe to change in focused state and use it to start/stop our timer
  useEffect(() => {
    // we only want to increment our time if the tab is in focus
    if (!focused) return;
    const intervalId = setInterval(
      () => setMilliseconds(milliseconds + 500),
      500
    );
    return () => clearInterval(intervalId);
  }, [focused, milliseconds]);

  // send the page view event every time the user lands on a page
  useEffect(
    () => {
      // First check if this is an ignored path
      if (!shouldTrackPage(path)) {
        return;
      }

      const replacedPath = replacePath(path, params);

      dispatch(trackPageViewBackend(replacedPath, url, referrer, hash, search));
    },
    // We're purposely not including params in the dependency array because it changes
    // signature on every render. Including "url" ensures new page views are tracked when the params change.
    [path, url, referrer, hash, search]
  );

  // send the time on page event every time the user change page
  useEffect(() => {
    // create a cleanup function that will be called with
    // the old path and send the time on page event
    return () => {
      if (millisecondsRef.current === 0) return;

      if (!shouldTrackPage(path)) {
        return;
      }

      const replacedPath = replacePath(path, params);

      dispatch(
        trackTimeOnPageBackend(replacedPath, url, millisecondsRef.current)
      );

      // reset the time
      setMilliseconds(0);
    };
  }, [path]);

  return null;
};

export default PageviewTracker;
