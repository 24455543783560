import classnames from "classnames";
import WaivedBar from "../images/waived-bar.svg";
import PassCheckmark from "../images/pass-checkmark.svg";
import SeverityInfo from "../images/severity-info.svg";
import SeverityLow from "../images/severity-low.svg";
import SeverityMedium from "../images/severity-medium.svg";
import SeverityHigh from "../images/severity-high.svg";
import SeverityCritical from "../images/severity-critical.svg";
import SeverityLowGreyed from "../images/severity-low-greyed.svg";
import SeverityMediumGreyed from "../images/severity-medium-greyed.svg";
import SeverityHighGreyed from "../images/severity-high-greyed.svg";
import SeverityCriticalGreyed from "../images/severity-critical-greyed.svg";
import PassCheckmarkGreyed from "../images/pass-checkmark-greyed.svg";
import SeverityInfoGreyed from "../images/severity-info-greyed.svg";
import "../style/components/SeverityIcon.scss";
import { Severity } from "../types/severity";
import { FC } from "react";

const severitySvgs = {
  waived: WaivedBar,
  pass: PassCheckmark,
  info: SeverityInfo,
  low: SeverityLow,
  medium: SeverityMedium,
  high: SeverityHigh,
  critical: SeverityCritical,
};

const greyedSeveritySvgs = {
  waived: WaivedBar,
  pass: PassCheckmarkGreyed,
  info: SeverityInfoGreyed,
  low: SeverityLowGreyed,
  medium: SeverityMediumGreyed,
  high: SeverityHighGreyed,
  critical: SeverityCriticalGreyed,
};

interface SeverityIconProps {
  severity: Severity;
  label?: boolean;
  className?: string;
  onClick?: () => void;
  greyed?: boolean;
}

const SeverityIcon: FC<SeverityIconProps> = ({
  severity,
  label,
  className,
  onClick,
  greyed,
}) => (
  <div
    className={classnames("severity-icon", className, { "is-label": label })}
    title={severity !== "pass" ? `Severity: ${severity}` : ""}
    onClick={onClick}
  >
    <img
      src={greyed ? greyedSeveritySvgs[severity] : severitySvgs[severity]}
      alt={severity}
    />
    {label && <span>{severity}</span>}
  </div>
);

export default SeverityIcon;

interface AdjustedSeverityIconProps
  extends Omit<SeverityIconProps, "className"> {
  baseSeverity?: Severity;
}

// AdjustedSeverityIcon shows a severity and optionally an adjusted severity
export const AdjustedSeverityIcon: FC<AdjustedSeverityIconProps> = ({
  severity,
  baseSeverity,
  label,
  onClick,
}) => {
  if (!baseSeverity || baseSeverity === severity) {
    // Treat as standard non-adjusted risk
    return (
      <div
        className={"adjusted-severity-icon"}
        title={`${severity}`}
        onClick={onClick}
      >
        <img
          src={severitySvgs[severity]}
          alt={severity}
          width={24}
          className={"single-severity"}
        />
        {label && <SeverityChangeDisplay severity={severity} hideIcons />}
      </div>
    );
  }

  return (
    <div
      className={"adjusted-severity-icon"}
      title={
        severity !== "pass"
          ? `Severity adjusted: ${baseSeverity} -> ${severity}`
          : ""
      }
      onClick={onClick}
    >
      <img
        src={severitySvgs[baseSeverity]}
        alt={baseSeverity}
        width={16}
        className={"orig-severity"}
      />
      <img
        src={severitySvgs[severity]}
        alt={severity}
        width={24}
        className={"adjusted-severity"}
      />
      {label && (
        <SeverityChangeDisplay
          severity={severity}
          baseSeverity={baseSeverity}
          hideIcons
        />
      )}
    </div>
  );
};

interface SeverityChangeDisplayProps {
  severity?: Severity;
  baseSeverity?: Severity;
  hideIcons?: boolean;
  hideText?: boolean;
  alwaysShowArrow?: boolean;
}

export const SeverityChangeDisplay: FC<SeverityChangeDisplayProps> = ({
  severity,
  baseSeverity,
  hideIcons,
  hideText,
  alwaysShowArrow,
}) => {
  const showBaseSeverity = baseSeverity && baseSeverity !== severity;

  return (
    <div className={"severity-change-display"}>
      {showBaseSeverity && (
        <>
          {!hideIcons && <SeverityIcon severity={baseSeverity} />}
          {!hideText && baseSeverity}
        </>
      )}
      {((severity && showBaseSeverity) || alwaysShowArrow) && (
        <i className={"cr-icon-arrow-right"} />
      )}
      {severity && (
        <>
          {!hideIcons && <SeverityIcon severity={severity} />}
          {!hideText && severity}
        </>
      )}
    </div>
  );
};
