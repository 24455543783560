import { FC, ReactNode } from "react";
import PageMovedSVG from "../images/page-moved.svg";
import ContentLibrarySVG from "../images/content-library.svg";
import ImportedSurveysSVG from "../images/imported-surveys.svg";

import "../style/components/EmptyStateCircle.scss";

const EmptyStateCircleImgs = {
  PageMoved: {
    className: "page-moved-img",
    src: PageMovedSVG,
  },
  ContentLibrary: {
    className: "content-library-img",
    src: ContentLibrarySVG,
  },
  ImportedSurveys: {
    className: "imported-surveys-img",
    src: ImportedSurveysSVG,
  },
};

interface IEmptyStateCircleProps {
  title: string;
  subtext?: ReactNode;
  imgName: keyof typeof EmptyStateCircleImgs;
}

const EmptyStateCircle: FC<IEmptyStateCircleProps> = ({
  title,
  subtext,
  imgName,
}) => {
  return (
    <div className="empty-state-circle">
      <img
        src={EmptyStateCircleImgs[imgName].src}
        className={`empty-state-img ${EmptyStateCircleImgs[imgName].className}`}
        alt={title}
      />
      <h5>{title}</h5>
      {subtext && <div className="subtext">{subtext}</div>}
    </div>
  );
};

export default EmptyStateCircle;
