import React, { FC, useEffect, useRef, useState } from "react";
import classnames from "classnames";
import LoadingBanner from "./core/LoadingBanner";
import "../style/components/ReportCard.scss";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import Button from "./core/Button";
import Icon from "./core/Icon";
import { usePrevious } from "../../vendorrisk/helpers/util";

export interface ExpandableReportCardProps extends IReportCardProps {
  // Header content
  headerContent: React.ReactNode;

  // Optional. Use to control expansion state from parents. Parent must maintain state.
  // Defaults to expanded.
  isExpanded?: boolean;

  // Optional. Callback for expansion change (can be used in conjunction with isExpanded to partially manage expansion state)
  onExpandToggle?: (expanded: boolean) => void;

  // Optional. Removed the click event triggering expand/collapse to the header container div.
  disableExpandOnHeaderClick?: boolean;

  hideExpander?: boolean;

  // Provide a custom expander. Requires also wiring up isExpanded and onExpandToggle.
  customExpander?: React.ReactNode;

  // Provide initial expansion state. If provided, overrides isExpanded on initial render.
  // Defaults to expanded.
  isInitiallyExpanded?: boolean;

  // Makes the card header sticky
  stickyHeader?: boolean;

  // Disables the transition animation on expansion
  noTransition?: boolean;
}

export const ExpandableReportCard = (props: ExpandableReportCardProps) => {
  const {
    className,
    style,
    loading,
    children,
    newStyles,
    customExpander,
    hideExpander,
    stickyHeader,
    noTransition,
  } = props;

  const reportCardRef = useRef<HTMLDivElement>(null);
  const [isExpandedInternal, setIsExpandedInternal] = useState(() =>
    props.isInitiallyExpanded !== undefined
      ? props.isInitiallyExpanded
      : props.isExpanded ?? true
  );

  // Respond to external expansion change
  useEffect(() => {
    if (props.isExpanded !== null && props.isExpanded !== undefined) {
      setIsExpandedInternal(props.isExpanded);
    }
  }, [props.isExpanded]);

  // Notify of internal expansion change
  useEffect(() => {
    if (props.onExpandToggle && props.isExpanded !== isExpandedInternal) {
      props.onExpandToggle(isExpandedInternal);
    }
  }, [isExpandedInternal]);

  // If this uses a sticky header, scroll to the header when it becomes closed.
  const prevExpandedInternal = usePrevious(isExpandedInternal);
  useEffect(() => {
    if (
      prevExpandedInternal &&
      !isExpandedInternal &&
      stickyHeader &&
      reportCardRef.current
    ) {
      const distanceFromTopOfViewport =
        reportCardRef.current.getBoundingClientRect().top;
      if (distanceFromTopOfViewport < 0) {
        const distanceFromTop =
          reportCardRef.current.getBoundingClientRect().top + window.scrollY;
        window.scrollTo({
          top: distanceFromTop,
        });
      }
    }
  }, [prevExpandedInternal, isExpandedInternal, stickyHeader]);

  const headerContainerClasses = classnames("header-container", {
    interactive: !props.disableExpandOnHeaderClick,
  });

  return (
    <div
      className={classnames("report-card expandable", className, {
        loading,
        "new-styles": newStyles,
        expanded: isExpandedInternal,
        sticky: stickyHeader,
      })}
      style={style}
      ref={reportCardRef}
    >
      <div
        className={headerContainerClasses}
        onClick={
          !props.disableExpandOnHeaderClick
            ? () => setIsExpandedInternal(!isExpandedInternal)
            : undefined
        }
      >
        <div className={"header"}>{props.headerContent}</div>
        {!hideExpander && (
          <div className={"expand-toggle"}>
            {customExpander && customExpander}
            {!customExpander && (
              <Button
                tertiary
                onClick={() => setIsExpandedInternal(!isExpandedInternal)}
              >
                <Icon
                  name={"chevron"}
                  direction={!isExpandedInternal ? 180 : 0}
                />
              </Button>
            )}
          </div>
        )}
      </div>
      {noTransition ? (
        <>
          {isExpandedInternal && (
            <div className={"expanded-content"}>
              {loading && (
                <div className="loading-overlay">
                  <LoadingBanner tight />
                </div>
              )}
              {children}
            </div>
          )}
        </>
      ) : (
        <TransitionGroup component={null}>
          {isExpandedInternal && (
            <CSSTransition timeout={250} classNames="expand">
              <div className={"expanded-content"}>
                {loading && (
                  <div className="loading-overlay">
                    <LoadingBanner tight />
                  </div>
                )}
                {children}
              </div>
            </CSSTransition>
          )}
        </TransitionGroup>
      )}
    </div>
  );
};

export interface IReportCardProps {
  id?: string;
  className?: string;
  children: React.ReactNode;
  loading?: boolean;
  style?: React.CSSProperties;
  newStyles?: boolean;
  scrollToMeOnMount?: boolean;
}

export const ReportCard: FC<IReportCardProps> = ({
  id,
  className,
  style,
  loading,
  children,
  newStyles,
  scrollToMeOnMount = false,
}) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (scrollToMeOnMount && ref.current) {
      ref.current.scrollIntoView();
    }
  }, [scrollToMeOnMount]);

  return (
    <div
      id={id}
      ref={ref}
      className={classnames("report-card", className, {
        loading,
        "new-styles": newStyles,
      })}
      style={style}
    >
      {loading && (
        <div className="loading-overlay">
          <LoadingBanner tight />
        </div>
      )}
      {children}
    </div>
  );
};

ReportCard.defaultProps = {
  className: "",
  loading: false,
  newStyles: false,
};

export default ReportCard;
