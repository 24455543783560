import { useEffect, useRef, useState } from "react";
import InfoBanner from "../../vendorrisk/components/InfoBanner";

import { DefaultThunkDispatch } from "../types/redux";
import "../style/components/MessageAlerts.scss";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { createPortal } from "react-dom";
import {
  clearMessageAlert,
  EMessageAlertPosition,
  IMessageAlert,
} from "../reducers/messageAlerts.actions";
import { appConnect } from "../types/reduxHooks";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IAlertsOwnProps {}

interface IAlertsConnectedProps {
  messageAlerts?: IMessageAlert[];
}

interface IAlertsDispatchProps {
  clearAlert: (alertId: string) => void;
}

type IAlertsProps = IAlertsOwnProps &
  IAlertsConnectedProps &
  IAlertsDispatchProps;

// Global component for managing the display of any app-wide alerts
const MessageAlerts = (props: IAlertsProps) => {
  const mountElement = useRef(document.createElement("div"));

  const [currentAlerts, setCurrentAlerts] = useState(props.messageAlerts ?? []);

  const dismissAlert = (alert: IMessageAlert) => {
    props.clearAlert(alert.id as string);
    alert.onDismiss?.();
  };

  // Initial mount
  useEffect(() => {
    document.body.appendChild(mountElement.current);

    return () => {
      document.body.removeChild(mountElement.current);
    };
  });

  // Respond to new alerts
  useEffect(() => {
    const newAlerts = props.messageAlerts
      ? props.messageAlerts.filter((a) => currentAlerts.indexOf(a) === -1)
      : [];

    // Check if any of the new alerts have a hide delay, and if so schedule a hide
    newAlerts.forEach((a) => {
      if (a.hideDelay) {
        setTimeout(() => {
          dismissAlert(a);
        }, a.hideDelay);
      }
    });

    const existingActiveAlerts = currentAlerts.filter(
      (a) => props.messageAlerts?.indexOf(a) !== -1
    );

    setCurrentAlerts([...existingActiveAlerts, ...newAlerts]);
  }, [props.messageAlerts]);

  const buildAlertBanner = (alert: IMessageAlert) => (
    <CSSTransition key={alert.id} timeout={250} classNames="expand">
      <div className={"alert-banner"}>
        <InfoBanner
          type={alert.type}
          message={alert.message}
          subItems={alert.subMessages}
          linkText={alert.linkText}
          linkURL={alert.linkUrl}
          onDismiss={() => dismissAlert(alert)}
        />
      </div>
    </CSSTransition>
  );

  const topAlerts = currentAlerts
    .filter((a) => a.position === EMessageAlertPosition.Top)
    .reverse()
    .map((a) => buildAlertBanner(a));
  const bottomAlerts = currentAlerts
    .filter((a) => a.position === EMessageAlertPosition.Bottom)
    .map((a) => buildAlertBanner(a));

  const alertsContainer = (
    <div className={"alerts"}>
      <div className={"alerts-top"}>
        <TransitionGroup>{topAlerts}</TransitionGroup>
      </div>
      <div className={"alerts-bottom"}>
        <TransitionGroup>{bottomAlerts}</TransitionGroup>
      </div>
    </div>
  );

  return createPortal(alertsContainer, mountElement.current);
};

export default appConnect<
  IAlertsConnectedProps,
  IAlertsDispatchProps,
  IAlertsOwnProps
>(
  (state) => {
    return {
      messageAlerts: state.common.messageAlerts,
    };
  },
  (dispatch: DefaultThunkDispatch) => {
    return {
      clearAlert: (alertId: string) => dispatch(clearMessageAlert(alertId)),
    };
  }
)(MessageAlerts);
