import {
  AttributeState,
  DateAttributeOperator,
  DateAttributeState,
  NotificationConditionalLogicAction,
  NotificationConditionalLogicState,
  NotificationConditionMode,
  NotificationConditionOperator,
  NotificationModeOperator,
} from "../../_common/types/notifications";
import { ILabel, LabelClassification } from "../../_common/types/label";
import { VendorTier } from "../reducers/vendorTiers.actions";
import { Portfolio } from "../reducers/portfolios.actions";
import { Dispatch, FC, useEffect, useState } from "react";

import "../style/components/SetNotificationConditions.scss";
import Button from "../../_common/components/core/Button";
import Icon from "../../_common/components/core/Icon";
import DropdownV2, {
  DropdownItem,
} from "../../_common/components/core/DropdownV2";
import ColorCheckbox from "./ColorCheckbox";
import { OptionType, SelectV2Multi } from "../../_common/components/SelectV2";
import VendorTierBadge from "./vendor_tiers/VendorTierBadge";
import {
  searchAvailableAttributeValues,
  VendorAttributeDefinition,
  VendorAttributeDefinitionType,
} from "../reducers/vendorAttributes.actions";
import { DefaultThunkDispatch } from "../../_common/types/redux";
import DatePicker from "../../_common/components/DatePicker";
import moment from "moment";
import classnames from "classnames";

interface IAttributeDefinitionConditionsProps {
  attributeDefinition: VendorAttributeDefinition;
  enabled: boolean;
  currentState: AttributeState;
  dispatch: DefaultThunkDispatch;
  onToggleAttribute: () => void;
  onChange: (newState: AttributeState) => void;
}

const AttributeDefinitionConditions: FC<
  IAttributeDefinitionConditionsProps
> = ({
  attributeDefinition,
  enabled,
  dispatch,
  currentState,
  onToggleAttribute,
  onChange,
}) => {
  const [availableValues, setAvailableValues] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (
      attributeDefinition.type === VendorAttributeDefinitionType.Set ||
      attributeDefinition.type === VendorAttributeDefinitionType.MultiSet
    ) {
      setAvailableValues(attributeDefinition.metadata?.allowedValues ?? []);
      return;
    }

    setIsLoading(true);
    dispatch(searchAvailableAttributeValues(attributeDefinition.id, "")).then(
      (values) => {
        setAvailableValues(values);
        setIsLoading(false);
      }
    );
  }, []);

  const selectedValues =
    currentState.selectedValues?.map((v) => ({
      value: v,
      label: v,
    })) ?? [];
  if (currentState.includesEmpty)
    selectedValues.push({
      label: `No value`,
      value: "",
    });

  return (
    <>
      <ColorCheckbox
        label={attributeDefinition.name}
        onClick={onToggleAttribute}
        checked={enabled}
        className={"attribute-condition"}
      />
      <div className={`matches ${!enabled ? "disabled" : ""}`}>
        matches{" "}
        <DropdownV2
          className="any-all-option"
          popupItem={
            currentState.matchesNone ? (
              <span>none of</span>
            ) : currentState.matchesAll ? (
              <span>all</span>
            ) : (
              <span>any</span>
            )
          }
        >
          <DropdownItem
            onClick={() => {
              const newState = {
                ...currentState,
                matchesNone: false,
                matchesAll: false,
              };
              onChange(newState);
            }}
          >
            Any
          </DropdownItem>
          {attributeDefinition.type ===
            VendorAttributeDefinitionType.MultiSet && (
            <DropdownItem
              onClick={
                !currentState.includesEmpty
                  ? () => {
                      const newState = {
                        ...currentState,
                        matchesNone: false,
                        matchesAll: true,
                      };
                      onChange(newState);
                    }
                  : undefined
              }
              className={classnames({ disabled: currentState.includesEmpty })}
              // we can't have a match all condition with the empty value
              disabled={currentState.includesEmpty}
            >
              All
            </DropdownItem>
          )}
          <DropdownItem
            onClick={() => {
              const newState = {
                ...currentState,
                matchesNone: true,
                matchesAll: false,
              };
              onChange(newState);
            }}
          >
            None of
          </DropdownItem>
        </DropdownV2>
      </div>
      <SelectV2Multi
        placeholder="Type to search values"
        options={[
          {
            value: "",
            label: `No value`,
          },
          ...availableValues.map((v) => ({
            label: v,
            value: v,
          })),
        ]}
        isLoading={isLoading}
        value={selectedValues}
        isSearchable
        isClearable={false}
        isDisabled={!enabled}
        onChange={(values) => {
          if (Array.isArray(values)) {
            const selectedValues = values.map(({ value }) => value);
            const includesEmpty = selectedValues.includes("");
            const newState = {
              ...currentState,
              includesEmpty,
              // we can't have a match all condition with the empty value
              // so revert back to matches any if the empty value was selected
              matchesAll: currentState.matchesAll && !includesEmpty,
              selectedValues: selectedValues.filter((v) => v !== ""),
            };
            onChange(newState);
          } else {
            onChange({
              ...currentState,
              includesEmpty: false,
              selectedValues: [],
            });
          }
        }}
      />
    </>
  );
};

interface IDateAttributeDefinitionConditionsProps {
  attributeDefinition: VendorAttributeDefinition;
  enabled: boolean;
  currentState: DateAttributeState;
  onToggleAttribute: () => void;
  onChange: (newState: DateAttributeState) => void;
}

const DateAttributeDefinitionConditions: FC<
  IDateAttributeDefinitionConditionsProps
> = ({
  attributeDefinition,
  enabled,
  currentState,
  onToggleAttribute,
  onChange,
}) => {
  const activeOperator =
    currentState.operator === DateAttributeOperator.Before
      ? DateAttributeOperator.Before
      : currentState.operator === DateAttributeOperator.Between
        ? DateAttributeOperator.Between
        : DateAttributeOperator.After;

  return (
    <>
      <ColorCheckbox
        label={attributeDefinition.name}
        onClick={onToggleAttribute}
        checked={enabled}
        className={"attribute-condition"}
      />
      <div className={`matches ${!enabled ? "disabled" : ""}`}>
        is{" "}
        <DropdownV2
          className="any-all-option"
          popupItem={
            activeOperator === DateAttributeOperator.Before ? (
              <span>before</span>
            ) : activeOperator === DateAttributeOperator.Between ? (
              <span>between</span>
            ) : (
              <span>after</span>
            )
          }
        >
          <DropdownItem
            onClick={() => {
              const newState = {
                ...currentState,
                operator: DateAttributeOperator.Before,
                startDate: undefined,
                endDate: undefined,
              };
              onChange(newState);
            }}
          >
            before
          </DropdownItem>
          <DropdownItem
            onClick={() => {
              const newState = {
                ...currentState,
                operator: DateAttributeOperator.Between,
                startDate: undefined,
                endDate: undefined,
              };
              onChange(newState);
            }}
          >
            between
          </DropdownItem>
          <DropdownItem
            onClick={() => {
              const newState = {
                ...currentState,
                operator: DateAttributeOperator.After,
                startDate: undefined,
                endDate: undefined,
              };
              onChange(newState);
            }}
          >
            after
          </DropdownItem>
        </DropdownV2>
      </div>
      {activeOperator === DateAttributeOperator.Before && (
        <DatePicker
          placeholder={"yyyy-mm-dd"}
          disabled={!enabled}
          onChange={(e: any) => {
            const newState = {
              ...currentState,
              endDate: e.target.value
                ? moment(e.target.value).format("YYYY-MM-DD")
                : undefined,
              startDate: undefined,
              operator: DateAttributeOperator.Before,
            };
            onChange(newState);
          }}
          value={currentState.endDate ?? ""}
        />
      )}
      {activeOperator === DateAttributeOperator.After && (
        <DatePicker
          placeholder={"yyyy-mm-dd"}
          disabled={!enabled}
          onChange={(e: any) => {
            const newState = {
              ...currentState,
              endDate: undefined,
              startDate: e.target.value
                ? moment(e.target.value).format("YYYY-MM-DD")
                : undefined,
              operator: DateAttributeOperator.After,
            };
            onChange(newState);
          }}
          value={currentState.startDate ?? ""}
        />
      )}
      {activeOperator === DateAttributeOperator.Between && (
        <>
          <div className={"between-condition"}>
            <DatePicker
              placeholder={"yyyy-mm-dd"}
              disabled={!enabled}
              onChange={(e: any) => {
                const newState = {
                  ...currentState,
                  startDate: e.target.value
                    ? moment(e.target.value).format("YYYY-MM-DD")
                    : undefined,
                  operator: DateAttributeOperator.Between,
                };
                onChange(newState);
              }}
              value={currentState.startDate ?? ""}
            />
            and
            <DatePicker
              placeholder={"yyyy-mm-dd"}
              disabled={!enabled}
              min={currentState.startDate}
              onChange={(e: any) => {
                const newState = {
                  ...currentState,
                  endDate: e.target.value
                    ? moment(e.target.value).format("YYYY-MM-DD")
                    : undefined,
                  operator: DateAttributeOperator.Between,
                };
                onChange(newState);
              }}
              value={currentState.endDate ?? ""}
            />
          </div>
        </>
      )}
    </>
  );
};

interface ISetNotificationConditionsProps {
  conditions: NotificationConditionalLogicState;
  conditionsDispatch: Dispatch<NotificationConditionalLogicAction>;
  supportedConditionalModes: NotificationConditionMode[];
  supportedLabelClassifications?: LabelClassification[];
  availableLabels: ILabel[];
  vendorTiers: VendorTier[];
  domainPortfolios: Portfolio[];
  vendorPortfolios: Portfolio[];
  attributeDefinitions: VendorAttributeDefinition[];
  dispatch: DefaultThunkDispatch;
}

interface tierOpt extends OptionType {
  value: number;
  tier: number;
}

const SetNotificationConditions: FC<ISetNotificationConditionsProps> = ({
  conditions,
  conditionsDispatch,
  supportedConditionalModes,
  supportedLabelClassifications,
  availableLabels,
  vendorTiers,
  domainPortfolios,
  vendorPortfolios,
  attributeDefinitions,
  dispatch,
}) => {
  const [showConditionalOptions, setShowConditionalOptions] = useState(
    !conditions.cleared
  );

  useEffect(() => {
    setShowConditionalOptions(!conditions.cleared);
  }, [conditions.cleared]);

  useEffect(() => {
    // Handle edge case where we show conditional options
    // but have no mode operator set by setting the operator
    // to the value we're showing (Or/label:any)
    if (showConditionalOptions && !conditions.modeOperator) {
      conditionsDispatch({
        type: "setModeOperator",
        operator: NotificationModeOperator.Or,
      });
    }
  }, []);

  if (supportedConditionalModes.length === 0) {
    return null;
  }

  const toggleMode = (mode: NotificationConditionMode) => {
    const idx = conditions.modes.indexOf(mode);
    const newModes = [...conditions.modes];
    if (idx === -1) {
      newModes.push(mode);
    } else {
      newModes.splice(idx, 1);
    }

    conditionsDispatch({
      type: "setModes",
      modes: newModes,
    });
  };

  const tiersEnabled = conditions.modes.includes(
    NotificationConditionMode.Tiers
  );
  const labelsEnabled = conditions.modes.includes(
    NotificationConditionMode.Labels
  );
  const domainPortfoliosEnabled = conditions.modes.includes(
    NotificationConditionMode.DomainPortfolios
  );
  const vendorPortfoliosEnabled = conditions.modes.includes(
    NotificationConditionMode.VendorPortfolios
  );

  const selectableTiers = vendorTiers.filter(
    (t) => !t.isArchived && t.isActive && t.id !== 0
  );
  const selectTierOptions: tierOpt[] = selectableTiers.map((t) => ({
    label: t.name,
    value: t.id,
    tier: t.tier,
  }));

  const selectTierValues = selectTierOptions.filter(
    (v) => conditions.tiers?.includes(v.value)
  );

  const selectableLabels = availableLabels.filter(
    (l) =>
      !!supportedLabelClassifications &&
      supportedLabelClassifications.includes(l.classification)
  );
  selectableLabels.sort((a, b) => a.name.localeCompare(b.name));

  const selectLabelOptions = selectableLabels.map((l) => ({
    label: l.name,
    value: l.id,
  }));
  const selectLabelValues = selectLabelOptions.filter(
    (v) => conditions.labels?.includes(v.value)
  );

  const selectDomainPortfolioOptions = domainPortfolios.map((l) => ({
    label: l.name,
    value: l.id,
  }));
  const selectDomainPortfolioValues = selectDomainPortfolioOptions.filter(
    (v) => conditions.domainPortfolioIDs?.includes(v.value)
  );

  const selectVendorPortfolioOptions = vendorPortfolios.map((l) => ({
    label: l.name,
    value: l.id,
  }));
  const selectVendorPortfolioValues = selectVendorPortfolioOptions.filter(
    (v) => conditions.portfolioIDs?.includes(v.value)
  );

  const toggleAttribute = (attrDefId: number) => {
    const idx = conditions.enabledAttributes.indexOf(attrDefId);
    const newEnabledAttributes = [...conditions.enabledAttributes];
    if (idx === -1) {
      newEnabledAttributes.push(attrDefId);
    } else {
      newEnabledAttributes.splice(idx, 1);
    }

    conditionsDispatch({
      type: "setEnabledAttributes",
      enabledAttributes: newEnabledAttributes,
    });
  };

  return (
    <div className="set-notification-conditions">
      {showConditionalOptions ? (
        <>
          <div className="conditions-content">
            <div className="conditions-header">
              And when{" "}
              <DropdownV2
                className="any-all-option"
                popupItem={
                  conditions.modeOperator === NotificationModeOperator.And ? (
                    <span>all</span>
                  ) : (
                    <span>any</span>
                  )
                }
              >
                <DropdownItem
                  onClick={() =>
                    conditionsDispatch({
                      type: "setModeOperator",
                      operator: NotificationModeOperator.And,
                    })
                  }
                >
                  All
                </DropdownItem>
                <DropdownItem
                  onClick={() =>
                    conditionsDispatch({
                      type: "setModeOperator",
                      operator: NotificationModeOperator.Or,
                    })
                  }
                >
                  Any
                </DropdownItem>
              </DropdownV2>{" "}
              of the following are true:
            </div>
            <div className="conditions-grid">
              {supportedConditionalModes.includes(
                NotificationConditionMode.Tiers
              ) && (
                <>
                  <ColorCheckbox
                    label="Tier"
                    checked={tiersEnabled}
                    onClick={() => toggleMode(NotificationConditionMode.Tiers)}
                  />
                  <div className={`matches ${!tiersEnabled ? "disabled" : ""}`}>
                    matches any
                  </div>
                  <SelectV2Multi
                    placeholder="Type to search tiers"
                    options={selectTierOptions}
                    value={selectTierValues}
                    isSearchable
                    isClearable={false}
                    isDisabled={!tiersEnabled}
                    formatOptionLabel={(opt) => {
                      const tierOpt = opt as tierOpt;
                      return (
                        <div className={"tier-option"}>
                          <VendorTierBadge tier={tierOpt.tier as number} />
                          {tierOpt.label}
                        </div>
                      );
                    }}
                    onChange={(values) => {
                      if (Array.isArray(values)) {
                        conditionsDispatch({
                          type: "setTiers",
                          tiers: values.map((v) => v.value),
                        });
                      } else {
                        conditionsDispatch({
                          type: "setTiers",
                          tiers: [],
                        });
                      }
                    }}
                  />
                </>
              )}
              {supportedConditionalModes.includes(
                NotificationConditionMode.Labels
              ) && (
                <>
                  <ColorCheckbox
                    label="Labels"
                    checked={labelsEnabled}
                    onClick={() => toggleMode(NotificationConditionMode.Labels)}
                  />
                  <div
                    className={`matches ${!labelsEnabled ? "disabled" : ""}`}
                  >
                    matches{" "}
                    <DropdownV2
                      className="any-all-option"
                      popupItem={
                        conditions.labelOperator ===
                        NotificationConditionOperator.All ? (
                          <span>all</span>
                        ) : (
                          <span>any</span>
                        )
                      }
                    >
                      <DropdownItem
                        onClick={() =>
                          conditionsDispatch({
                            type: "setLabelOperator",
                            operator: NotificationConditionOperator.All,
                          })
                        }
                      >
                        All
                      </DropdownItem>
                      <DropdownItem
                        onClick={() =>
                          conditionsDispatch({
                            type: "setLabelOperator",
                            operator: NotificationConditionOperator.Any,
                          })
                        }
                      >
                        Any
                      </DropdownItem>
                    </DropdownV2>
                  </div>
                  <SelectV2Multi
                    placeholder="Type to search labels"
                    options={selectLabelOptions}
                    value={selectLabelValues}
                    isSearchable
                    isClearable={false}
                    isDisabled={!labelsEnabled}
                    onChange={(values) => {
                      if (Array.isArray(values)) {
                        conditionsDispatch({
                          type: "setLabels",
                          labels: values.map((v) => v.value),
                        });
                      } else {
                        conditionsDispatch({
                          type: "setLabels",
                          labels: [],
                        });
                      }
                    }}
                  />
                </>
              )}
              {supportedConditionalModes.includes(
                NotificationConditionMode.DomainPortfolios
              ) && (
                <>
                  <ColorCheckbox
                    label="Portfolios"
                    checked={domainPortfoliosEnabled}
                    onClick={() =>
                      toggleMode(NotificationConditionMode.DomainPortfolios)
                    }
                  />
                  <div
                    className={`matches ${
                      !domainPortfoliosEnabled ? "disabled" : ""
                    }`}
                  >
                    matches any
                  </div>
                  <SelectV2Multi
                    placeholder="Type to search portfolios"
                    options={selectDomainPortfolioOptions}
                    value={selectDomainPortfolioValues}
                    isSearchable
                    isClearable={false}
                    isDisabled={!domainPortfoliosEnabled}
                    onChange={(values) => {
                      if (Array.isArray(values)) {
                        conditionsDispatch({
                          type: "setDomainPortfolioIDs",
                          portfolioIDs: values.map((v) => v.value),
                        });
                      } else {
                        conditionsDispatch({
                          type: "setDomainPortfolioIDs",
                          portfolioIDs: [],
                        });
                      }
                    }}
                  />
                </>
              )}
              {supportedConditionalModes.includes(
                NotificationConditionMode.VendorPortfolios
              ) && (
                <>
                  <ColorCheckbox
                    label="Portfolios"
                    checked={vendorPortfoliosEnabled}
                    onClick={() =>
                      toggleMode(NotificationConditionMode.VendorPortfolios)
                    }
                  />
                  <div
                    className={`matches ${
                      !vendorPortfoliosEnabled ? "disabled" : ""
                    }`}
                  >
                    matches any
                  </div>
                  <SelectV2Multi
                    placeholder="Type to search portfolios"
                    options={selectVendorPortfolioOptions}
                    value={selectVendorPortfolioValues}
                    isSearchable
                    isClearable={false}
                    isDisabled={!vendorPortfoliosEnabled}
                    onChange={(values) => {
                      if (Array.isArray(values)) {
                        conditionsDispatch({
                          type: "setVendorPortfolioIDs",
                          portfolioIDs: values.map((v) => v.value),
                        });
                      } else {
                        conditionsDispatch({
                          type: "setVendorPortfolioIDs",
                          portfolioIDs: [],
                        });
                      }
                    }}
                  />
                </>
              )}
              {supportedConditionalModes.includes(
                NotificationConditionMode.Attributes
              ) &&
                attributeDefinitions.map((attrDef) => {
                  const enabled = conditions.enabledAttributes.includes(
                    attrDef.id
                  );
                  if (attrDef.type === VendorAttributeDefinitionType.Date) {
                    return (
                      <DateAttributeDefinitionConditions
                        key={attrDef.id}
                        attributeDefinition={attrDef}
                        enabled={enabled}
                        currentState={
                          !!conditions.dateAttributes &&
                          conditions.dateAttributes[attrDef.id]
                            ? conditions.dateAttributes[attrDef.id]
                            : {}
                        }
                        onToggleAttribute={() => toggleAttribute(attrDef.id)}
                        onChange={(newState: DateAttributeState) =>
                          conditionsDispatch({
                            type: "setDateAttributeState",
                            attrDefId: attrDef.id,
                            newState: newState,
                          })
                        }
                      />
                    );
                  }
                  return (
                    <AttributeDefinitionConditions
                      key={attrDef.id}
                      attributeDefinition={attrDef}
                      enabled={enabled}
                      currentState={
                        !!conditions.attributes &&
                        conditions.attributes[attrDef.id]
                          ? conditions.attributes[attrDef.id]
                          : {}
                      }
                      dispatch={dispatch}
                      onToggleAttribute={() => toggleAttribute(attrDef.id)}
                      onChange={(newState: AttributeState) =>
                        conditionsDispatch({
                          type: "setAttributeState",
                          attrDefId: attrDef.id,
                          newState: newState,
                        })
                      }
                    />
                  );
                })}
            </div>
          </div>
          <Button
            tertiary
            onClick={() => {
              conditionsDispatch({ type: "clear" });
              setShowConditionalOptions(false);
            }}
          >
            <Icon name={"x"} />
            Remove conditional logic
          </Button>
        </>
      ) : (
        <Button tertiary onClick={() => setShowConditionalOptions(true)}>
          <span className={"cr-icon-plus"} />
          Add conditional logic
        </Button>
      )}
    </div>
  );
};

export default SetNotificationConditions;
