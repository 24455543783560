import "../style/components/SkeletonText.scss";

interface SkeletonTextProps {
  numRows?: number;
}

const SkeletonText = ({ numRows = 6 }: SkeletonTextProps) => {
  return (
    <div className={"skeleton-text"}>
      {[...Array(numRows).keys()].map((_, i) => (
        <div className={"skeleton-text-row"} key={i} />
      ))}
    </div>
  );
};

export default SkeletonText;
