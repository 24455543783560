import { useHasOrgEntitlement } from "../permissions";
import { ComponentType, FC } from "react";

// withRequireEntitlement stops a component being included in the React-DOM if the current org
// does not have the specified entitlements.
const withRequireEntitlement = <BaseProps extends object>(
  BaseComponent: ComponentType<BaseProps>,
  requiredEntitlement: string | string[]
): ComponentType<BaseProps> => {
  const ComponentHidden: FC<BaseProps> = (props: BaseProps) => {
    const hasEntitlement = useHasOrgEntitlement(requiredEntitlement);

    if (!hasEntitlement) {
      return <></>;
    } else {
      return <BaseComponent {...props} />;
    }
  };

  return ComponentHidden;
};

export default withRequireEntitlement;
