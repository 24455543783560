import React, {
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import {
  InitialConfigType,
  LexicalComposer,
} from "@lexical/react/LexicalComposer";
import { $convertToMarkdownString, TRANSFORMERS } from "@lexical/markdown";
import { MarkdownShortcutPlugin } from "@lexical/react/LexicalMarkdownShortcutPlugin";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import {
  $getRoot,
  EditorState,
  EditorThemeClasses,
  LexicalEditor,
} from "lexical";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import { OnChangePlugin } from "@lexical/react/LexicalOnChangePlugin";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import { ListItemNode, ListNode } from "@lexical/list";
import { LinkNode } from "@lexical/link";
import "../style/components/RichTextEditV2.scss";
import classnames from "classnames";
import RichTextEditV2Toolbar from "./RichTextEditV2Toolbar";
import { LinkPlugin } from "@lexical/react/LexicalLinkPlugin";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import {
  $convertFromMarkdownStringNoTrim,
  IS_SAFARI,
} from "./RichTextEditV2.lexical-markdown";
import { HeadingNode, QuoteNode } from "@lexical/rich-text";
import { CodeNode } from "@lexical/code";
import { HorizontalRuleNode } from "@lexical/react/LexicalHorizontalRuleNode";
import { TabIndentationPlugin } from "@lexical/react/LexicalTabIndentationPlugin";
import { ClearEditorPlugin } from "@lexical/react/LexicalClearEditorPlugin";

interface RichTextEditV2Props extends LexicalWrapperProps {
  value?: string;
}

// RichTextEditV2 is a markdown editor using Lexical (https://github.com/facebook/lexical) text manipulation framework
// Heavily based on their examples:
// - https://github.com/facebook/lexical/tree/main/packages/lexical-playground
// - https://codesandbox.io/s/many-lexical-autofocus-bfqtmu
const RichTextEditV2: React.FC<RichTextEditV2Props> = ({
  value,
  placeholder,
  readOnly,
  onChange,
  disallowLinks,
  className,
  disabled,
}) => {
  // Store the initial value to limit re-renders
  // TODO we can probably clean this up a bit: https://github.com/ScriptRock/vendor_risk/pull/4863#discussion_r1594936814
  const initialEditorValue = useRef(value);
  const initialCallback = useRef(true);

  const lastChangeValue = useRef("");
  const wrapperRef = useRef<LexicalWrapperHandle | null>(null);

  // Listen for external change
  // - If detected, fire the ref callback to force a change
  useEffect(() => {
    if (value !== lastChangeValue.current) {
      if (wrapperRef.current) {
        wrapperRef.current?.setValue(value ?? "");
      }
    }
  }, [value]);

  // Store the latest value in a ref
  // - only bubble change if it is different
  const handleOnChange = useCallback(
    (value: string) => {
      if (value !== lastChangeValue.current) {
        lastChangeValue.current = value;

        // Check if this is the initial callback with no change
        // - if so, don't bubble (this can happen due to transform applied to the markup before rendering in the editor)
        if (
          onChange &&
          (!initialCallback.current || value !== initialEditorValue.current)
        ) {
          onChange(value);
        }
      }

      initialCallback.current = false;
    },
    [onChange]
  );

  return (
    <LexicalWrapper
      ref={wrapperRef}
      placeholder={placeholder}
      readOnly={readOnly}
      onChange={handleOnChange}
      disallowLinks={disallowLinks}
      className={className}
      disabled={disabled}
    />
  );
};

// Map Lexical internal representations of node types/attributes to CSS classes for styling
const theme: EditorThemeClasses = {
  ltr: "ltr",
  rtl: "rtl",
  placeholder: "editor-placeholder",
  paragraph: "editor-paragraph",
  quote: "editor-quote",
  heading: {
    h1: "editor-heading-h1",
    h2: "editor-heading-h2",
    h3: "editor-heading-h3",
    h4: "editor-heading-h4",
    h5: "editor-heading-h5",
  },
  list: {
    nested: {
      listitem: "editor-nested-listitem",
    },
    ol: "editor-list-ol",
    ul: "editor-list-ul",
    listitem: "editor-listitem",
  },
  image: "editor-image",
  link: "editor-link",
  text: {
    bold: "editor-text-bold",
    italic: "editor-text-italic",
    underline: "editor-text-underline",
    strikethrough: "editor-text-strikethrough",
    underlineStrikethrough: "editor-text-underlineStrikethrough",
    code: "editor-text-code",
  },
  code: "editor-code",
  codeHighlight: {
    atrule: "editor-tokenAttr",
    attr: "editor-tokenAttr",
    boolean: "editor-tokenProperty",
    builtin: "editor-tokenSelector",
    cdata: "editor-tokenComment",
    char: "editor-tokenSelector",
    class: "editor-tokenFunction",
    "class-name": "editor-tokenFunction",
    comment: "editor-tokenComment",
    constant: "editor-tokenProperty",
    deleted: "editor-tokenProperty",
    doctype: "editor-tokenComment",
    entity: "editor-tokenOperator",
    function: "editor-tokenFunction",
    important: "editor-tokenVariable",
    inserted: "editor-tokenSelector",
    keyword: "editor-tokenAttr",
    namespace: "editor-tokenVariable",
    number: "editor-tokenProperty",
    operator: "editor-tokenOperator",
    prolog: "editor-tokenComment",
    property: "editor-tokenProperty",
    punctuation: "editor-tokenPunctuation",
    regex: "editor-tokenVariable",
    selector: "editor-tokenSelector",
    string: "editor-tokenSelector",
    symbol: "editor-tokenProperty",
    tag: "editor-tokenProperty",
    url: "editor-tokenOperator",
    variable: "editor-tokenVariable",
  },
};

interface LexicalWrapperHandle {
  setValue: (val: string) => void;
}

interface LexicalWrapperProps {
  placeholder?: string;
  readOnly?: boolean;
  onChange?: (value: string) => void;
  disallowLinks?: boolean;
  className?: string;
  disabled?: boolean;
}

// LexicalWrapper manages all the Lexical <stuff>
// - Exposes a ref to set the current value programatically
const LexicalWrapper = memo(
  forwardRef<LexicalWrapperHandle, LexicalWrapperProps>(
    (
      { placeholder, readOnly, onChange, disallowLinks, className, disabled },
      ref
    ) => {
      const [initialConfig] = useState<InitialConfigType>({
        namespace: "MyEditor",
        editable: false, // NOTE: this will get set via the plugin due to auto-focus jank
        editorState: undefined, // NOTE: this get set via the plugin due to auto-focus jank
        theme,
        onError: (error) => {
          throw error;
        },
        nodes: [
          ListNode,
          ListItemNode,
          LinkNode,
          HeadingNode,
          QuoteNode,
          CodeNode,
          HorizontalRuleNode,
        ],
      });

      const editorChanged = useCallback(
        (editorState: EditorState, _: LexicalEditor, __: Set<string>) => {
          if (onChange) {
            editorState.read(() => {
              const markdown = $convertToMarkdownString(TRANSFORMERS);
              onChange(markdown);
            });
          }
        },
        [onChange]
      );

      const editorClasses = classnames(className ?? "", "rich-text-edit-v2", {
        readonly: readOnly,
        disabled: disabled,
      });

      return (
        <LexicalComposer initialConfig={initialConfig}>
          <div className={editorClasses}>
            {!readOnly && (
              <RichTextEditV2Toolbar
                disallowLinks={disallowLinks}
                disabled={disabled}
              />
            )}
            <div className={"editor-inner"}>
              <RichTextPlugin
                contentEditable={
                  <ContentEditable
                    disabled={disabled}
                    // Lexical on Safari has a race whereby spellcheck updates can trigger an infinite update loop.
                    spellCheck={!IS_SAFARI}
                    className={"content-editable"}
                    style={{ outline: "none" }}
                  />
                }
                placeholder={
                  readOnly ? null : (
                    <div className="editor-placeholder">
                      {placeholder ?? "Enter some text..."}
                    </div>
                  )
                }
                ErrorBoundary={LexicalErrorBoundary}
              />
              <ListPlugin />
              <LinkPlugin />
              <HistoryPlugin />
              <OnChangePlugin onChange={editorChanged} ignoreSelectionChange />
              <SetEditablePlugin editable={!readOnly && !disabled} />
              <SetEditorStatePlugin ref={ref} />
              {readOnly && <LinkNodePlugin />}
              <MarkdownShortcutPlugin transformers={TRANSFORMERS} />
              <TabIndentationPlugin />
              <ClearEditorPlugin />
            </div>
          </div>
        </LexicalComposer>
      );
    }
  )
);
LexicalWrapper.displayName = "LexicalWrapper";

// SetEditablePlugin manages the editable state of a Lexical context
const SetEditablePlugin: React.FC<{ editable: boolean }> = memo(
  ({ editable }) => {
    const [editor] = useLexicalComposerContext();

    useEffect(() => {
      editor.setEditable(editable);
    }, [editable]);

    return null;
  }
);
SetEditablePlugin.displayName = "SetEditablePlugin";

// SetEditorStatePlugin sets the editable state of a Lexical context via ref callback
const SetEditorStatePlugin = memo(
  forwardRef<LexicalWrapperHandle>((_, ref) => {
    const [editor] = useLexicalComposerContext();

    useImperativeHandle(ref, () => ({
      setValue: (val) => {
        editor.update(
          () => {
            const root = $getRoot();
            root.clear();

            // NOTE: Using our own version here to preserve leading + trailing spaces
            $convertFromMarkdownStringNoTrim(val ?? "", TRANSFORMERS, root);
          },
          {
            discrete: true,
          }
        );
      },
    }));

    return null;
  })
);
SetEditorStatePlugin.displayName = "SetEditorStatePlugin";

// LinkNodePlugin transforms link nodes to:
// - Always open in a new tab
// - Set nofollow noreferrer
const LinkNodePlugin = () => {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    let removeTransform: () => void;

    // No idea exactly why this needs to be run after the initial render
    // - Possibly due to us using a separate plugin to set the content?
    setTimeout(() => {
      removeTransform = editor.registerNodeTransform(LinkNode, (node) => {
        if (!node) {
          return;
        }

        const dom = editor.getElementByKey(node.__key);
        if (!dom) {
          return;
        }

        dom.setAttribute("target", "_blank");
        dom.setAttribute("rel", "nofollow noreferrer");
      });
    }, 1);
    return () => {
      if (removeTransform) {
        removeTransform();
      }
    };
  }, [editor]);

  return null;
};

export default RichTextEditV2;
