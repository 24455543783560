import { NodeType } from "../../survey_builder/types/types";
import "../style/SurveyViewerItemIcon.scss";
import SeverityIcon, {
  AdjustedSeverityIcon,
} from "../../_common/components/SeverityIcon";
import { NodeSummaryAndNode } from "../surveyViewer.helpers";
import { separateQuestionNumberFromQuestion } from "../../_common/components/surveydetails/SurveyDetailsUnansweredQuestionsCard";
import classNames from "classnames";
import { useAppSelector } from "../../_common/types/reduxHooks";
import { Severity } from "../../_common/types/severity";

interface SurveyViewerItemIconProps {
  nodeSummary: NodeSummaryAndNode;
  hasSuggestion?: boolean;
}

const SurveyViewerItemIcon = ({
  nodeSummary,
  hasSuggestion,
}: SurveyViewerItemIconProps) => {
  let questionNumber: string;

  // Check if we should hide the numbering for this question
  if (nodeSummary.skipNumbering) {
    questionNumber = "";
  } else if (nodeSummary.node.customNumber) {
    questionNumber = nodeSummary.node.customNumber;
  } else {
    [questionNumber] = separateQuestionNumberFromQuestion(
      nodeSummary.titleOrMainText
    );
    if (!questionNumber) {
      questionNumber = nodeSummary.questionNumber;
    }
  }

  const displayId =
    nodeSummary.node.type === NodeType.Risk ? "Risk" : questionNumber;

  const adjustment = useAppSelector(
    (state) => state.surveyViewer.adjustmentsForRisks[nodeSummary.nodeId]
  );

  const icon = getIconForNode(nodeSummary, adjustment?.adjustedSeverity);

  return (
    <div
      className={classNames("survey-viewer-icon", {
        "has-suggestion": hasSuggestion,
      })}
    >
      {icon && <div className={"icon"}>{icon}</div>}
      {displayId && <div className={"display-id"}>{displayId}</div>}
    </div>
  );
};

export default SurveyViewerItemIcon;

const getIconForNode = (
  nodeSummary: NodeSummaryAndNode,
  adjustedSeverity?: Severity
) => {
  const node = nodeSummary.node;

  switch (node.type) {
    case NodeType.Section:
      return <i className={"cr-icon-q-builder-flag"} />;
    case NodeType.InputText:
      return <i className={"cr-icon-q-builder-document"} />;
    case NodeType.Select:
      return node.radio ? (
        <i className={"cr-icon-q-builder-radio"} />
      ) : (
        <i className={"cr-icon-q-builder-checkbox"} />
      );
    case NodeType.Upload:
      return <i className={"cr-icon-q-builder-attachment"} />;
    case NodeType.Risk:
      if (node.severity) {
        if (adjustedSeverity) {
          return (
            <AdjustedSeverityIcon
              baseSeverity={node.severity}
              severity={adjustedSeverity}
            />
          );
        } else {
          return <SeverityIcon severity={node.severity} />;
        }
      } else {
        return undefined;
      }
    case NodeType.Info:
      return <i className={"cr-icon-info"} />;
    default:
      return <i className={"cr-icon-q-builder-flag"} />;
  }
};
