import PropTypes from "prop-types";
import { keys as _keys, omit as _omit } from "lodash";
import { getGravatarURL } from "../../helpers";

const Gravatar = (props) => {
  const { alt } = props;

  return (
    <img
      {..._omit(props, _keys(Gravatar.propTypes))}
      src={getGravatarURL(props.email)}
      alt={alt}
    />
  );
};

Gravatar.propTypes = {
  email: PropTypes.string,
  alt: PropTypes.string,
};

Gravatar.defaultProps = {
  email: "",
  alt: "Gravatar",
};

export default Gravatar;
