import { CSSProperties, ReactNode, useMemo, useState } from "react";
import { useDropzone } from "react-dropzone";
import "../style/components/DragDropUpload.scss";
import LoadingIcon from "./core/LoadingIcon";
import classnames from "classnames";
import { useAppDispatch } from "../types/reduxHooks";
import { addDefaultWarningAlert } from "../reducers/messageAlerts.actions";

export enum EDragDropUploadStyle {
  Default,
  Compact,
}

export interface DragDropUploadBaseProps {
  onFileSelected?: (file: File) => void;
  onFileRejected?: (file: File) => void;
  maxFileSize: number;
  acceptedFileTypeFilters?: string[];
  // If true, use onSelectionChange
  multiple?: boolean;
  onSelectionChange?: (acceptedFiles: File[], rejectedFiles: File[]) => void;
  loading?: boolean;
  disabled?: boolean;
  additionalText?: ReactNode;
}

const baseStyle: CSSProperties = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  borderWidth: 1,
  borderRadius: 8,
  borderColor: "#ECEDF3",
  borderStyle: "solid",
  backgroundColor: "#ffffff",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .2s ease-in-out, background-color .2s ease-in-out",
  minHeight: "86px",
};

const activeStyle: CSSProperties = {
  borderColor: "#1757C2",
  backgroundColor: "#F3F7FC",
};

const acceptStyle: CSSProperties = {
  borderColor: "#1757C2",
};

const rejectStyle: CSSProperties = {
  borderColor: "#ff1744",
};

interface IDragDropUploadProps extends DragDropUploadBaseProps {
  clickText?: string;
  selectedFile?: File;
  doNotKeepState?: boolean;
}

const DragDropUpload = ({
  onFileSelected,
  onFileRejected,
  maxFileSize,
  acceptedFileTypeFilters,
  clickText = "Click to upload",
  selectedFile: initialSelectedFile,
  doNotKeepState = false,
  loading = false,
  disabled = false,
  multiple = false,
  onSelectionChange,
  additionalText,
}: IDragDropUploadProps) => {
  const [selectedFile, setSelectedFile] = useState(initialSelectedFile);
  const dispatch = useAppDispatch();

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    multiple: multiple,
    maxSize: maxFileSize,
    accept: acceptedFileTypeFilters,
    onDrop: (acceptedFiles, rejectedFiles) => {
      if (!multiple && acceptedFiles.length + rejectedFiles.length > 1) {
        dispatch(addDefaultWarningAlert("Please upload one file at a time."));
        return;
      }

      if (acceptedFiles.length > 0) {
        if (!doNotKeepState) {
          setSelectedFile(acceptedFiles[0]);
        }
        if (onFileSelected) onFileSelected(acceptedFiles[0]);
      } else if (rejectedFiles.length > 0) {
        if (onFileRejected) onFileRejected(rejectedFiles[0]);
      }

      if (onSelectionChange) onSelectionChange(acceptedFiles, rejectedFiles);
    },
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragAccept, isDragReject]
  );

  const classes = classnames("container dragdropupload", {
    disabled: disabled || loading,
  });

  return (
    <div className={classes}>
      <div className="drop-target" {...getRootProps({ style })}>
        {!loading && (
          <>
            <input {...getInputProps()} />
            <div className="desc-text-container">
              <span className="cr-icon-q-builder-attachment" />
              <div className="desc-text">
                {selectedFile?.name ?? initialSelectedFile?.name ?? (
                  <>
                    <span className="link-style">{clickText}</span>or drag and
                    drop here
                  </>
                )}
              </div>
            </div>
          </>
        )}
        {loading && <LoadingIcon size={44} />}
      </div>
      {additionalText && (
        <div className="additional-text">{additionalText}</div>
      )}
    </div>
  );
};

export default DragDropUpload;
