import { DefaultThunkDispatch } from "../../_common/types/redux";
import { DefaultRootState } from "react-redux";
import { FetchCyberRiskUrl } from "../../_common/api";
import { LogError } from "../../_common/helpers";
import {
  getJiraComponentsData,
  getJiraIssueTypesData,
  getJiraProjectData,
  getOAuthConnectionData,
} from "./oauth.selectors";
import {
  setJiraComponentsData,
  setJiraIssueSendingStatus,
  setJiraIssueTypesData,
  setJiraProjectData,
  setMessageSendingStatus,
  setMessagingChannelsData,
  setOAuthConnectionData,
  setOAuthConnectionLoading,
  setOAuthError,
  setOAuthRequestURL,
  setPopulatedMessagingData,
  setPopulatedMessagingExpected,
} from "./cyberRiskActions";
import {
  getOAuth2MessagingChannelData,
  getPopulatedMessagingExpected,
} from "./oauth.selectors";

import {
  IOAuthToken,
  IConnectionCacheData,
  IMessagingChannel,
} from "../../_common/types/oauth";
import {
  IJiraIssue,
  IJiraIssuesResponse,
  IJiraGroup,
  IJiraGroupsResponse,
  IJiraUser,
  IJiraUsersResponse,
  IJiraVersion,
  IJiraVersionsResponse,
  IJiraComponent,
} from "../../_common/types/jira";
import { MESSAGING_SERVICE_EMAIL } from "../views/CreateIntegration";

// OAuth2 service names
export const SLACK_SERVICE = "Slack";
export const JIRA_SERVICE = "Jira";
export const GOOGLE_SERVICE = "Google";
export const MICROSOFT_SERVICE = "Microsoft365";
export const MICROSOFT_CLIENT_CREDENTIALS_SERVICE =
  "Microsoft365ClientCredentials";

//
// fetchExistingOAuthConnections
// retrieves the current set of active connections that have been set up for a specific oauth service (eg. Slack)
// if a request uuid is also provided, then errors that have been detected for that connection request are also
// retrieved.
//
export const fetchExistingOAuthConnections = (
  service: string,
  uuid = "",
  includeInactive = false,
  quietly = false,
  forced = false
) => {
  return async (
    dispatch: DefaultThunkDispatch,
    getState: () => DefaultRootState
  ) => {
    if (!forced) {
      const data = getOAuthConnectionData(getState(), service);
      if (data && !data.loading && !data.error && data.connections) {
        // We have cached oauth connection data for this service
        return {
          connections: data.connections,
          errors: [],
        };
      }
    }

    let json: IConnectionCacheData;
    try {
      dispatch(setOAuthConnectionLoading(service, !quietly));
      json = await FetchCyberRiskUrl(
        "oauth/cache/",
        {
          service: service,
          uuid: uuid ? uuid : null,
          include_inactive: includeInactive,
        },
        { method: "GET" },
        dispatch,
        getState
      );

      dispatch(setOAuthConnectionData(service, json.tokens, false, null));
    } catch (e) {
      LogError(
        `Error obtaining existing oauth connections for service ${service}`,
        e
      );

      dispatch(setOAuthConnectionData(service, null, false, e));
      return null;
    }
    return { connections: json.tokens, errors: json.errors };
  };
};

//
// DeactivateCurrentOAuthConnection
// ...
//
export const deactivateCurrentOAuthConnection = (service: string) => {
  return async (
    dispatch: DefaultThunkDispatch,
    getState: () => DefaultRootState
  ) => {
    const data = getOAuthConnectionData(getState(), service);
    if (
      !data ||
      data.loading ||
      data.error ||
      !data.connections ||
      data.connections.length == 0
    ) {
      throw "No active connection found";
    }

    let json: {
      status: string;
      tokens: IOAuthToken[];
    };
    try {
      dispatch(setOAuthConnectionLoading(service, true));
      json = await FetchCyberRiskUrl(
        "oauth/cache/",
        {
          service: service,
        },
        { method: "DELETE" },
        dispatch,
        getState
      );
      dispatch(setOAuthConnectionData(service, json.tokens, false, null));
    } catch (e) {
      LogError(
        `Error deactivating existing oauth connections for service ${service}`,
        e
      );

      throw e;
    }
  };
};

export interface fetchNewOAuth2RequestURLResult {
  url: string;
  uuid: string;
}

//
// fetchNewOAuth2RequestURL
// retrieves a new redirect URL to request an oauth2 connection to a service (eg Slack). The URL is returned
// along with a UUID representing the unique identifier of the connection request. Any errors detected with the connection
// process will be stored against this id, as will the final connection instance.
//
export const fetchNewOAuth2RequestURL = (service: string) => {
  return async (
    dispatch: DefaultThunkDispatch,
    getState: () => DefaultRootState
  ) => {
    dispatch(setOAuthError(service, null));

    let json;
    try {
      json = await FetchCyberRiskUrl<fetchNewOAuth2RequestURLResult>(
        "oauth2/request/",
        { service: service },
        { method: "GET" },
        dispatch,
        getState
      );
      dispatch(setOAuthRequestURL(service, json.url, json.uuid, null));
    } catch (e: any) {
      LogError("Error obtaining new oauth2 access request url", e);

      throw new Error(
        "Error obtaining new oauth2 access request url: " + e.message
      );
    }
    return json;
  };
};

//
// postOAuth2TemporaryCodeCallbackRequest
// forwards an oauth2 temporary connection code to the back-end for processing. The code will be used to obtain
// the final oauth2 access token from the authorising service (eg Slack)
//
//
export const postOAuth2TemporaryCodeCallbackRequest = (
  code: string,
  service: string
) => {
  return async (
    dispatch: DefaultThunkDispatch,
    getState: () => DefaultRootState
  ) => {
    dispatch(setOAuthConnectionLoading(service, true));
    dispatch(setOAuthError(service, null));

    let json: {
      status: string;
      service: string;
      error: string;
    };
    try {
      json = await FetchCyberRiskUrl(
        "oauth2/code/",
        {
          code: code,
          service: service,
        },
        { method: "POST" },
        dispatch,
        getState
      );

      dispatch(setMessageSendingStatus(service, false, null));

      if (json.status == "FAILED") {
        LogError("Error processing oauth2 temporary code", json.error);
        dispatch(setOAuthError(service, json.error));
      }
      dispatch(setOAuthConnectionLoading(service, false));
    } catch (e: any) {
      dispatch(setOAuthError(service, e.json.error));
      dispatch(setOAuthConnectionLoading(service, false));
      LogError("Error processing oauth2 temporary code", e);
    }
  };
};

//
// fetchOAuth2MessagingServiceChannelList
// retrieves the current list of channels supported by a specific messaging service.
//
// It is important for the caller to ensure that state.cyberRisk.oauthConnections[service]
// is populated before calling this.
//
// TODO: make this constraint less brittle.
//
export const fetchOAuth2MessagingServiceChannelList = (
  service: string,
  quietly: boolean,
  forced = false
) => {
  return async (
    dispatch: DefaultThunkDispatch,
    getState: () => DefaultRootState
  ) => {
    const { channels, loading, error } = getOAuth2MessagingChannelData(
      getState(),
      service
    );

    if (loading) {
      return;
    }

    if (!forced && !error && channels) {
      return channels;
    }

    dispatch(setMessagingChannelsData(service, null, true, null));

    let json:
      | {
          status: string;
          service: string;
          channels: IMessagingChannel[];
        }
      | undefined;
    try {
      json = await FetchCyberRiskUrl<{
        status: string;
        service: string;
        channels: IMessagingChannel[];
      }>(
        "oauth/messaging/channels/",
        { service: service },
        { method: "GET" },
        dispatch,
        getState
      );
      dispatch(setMessagingChannelsData(service, json.channels, false, null));
    } catch (e: any) {
      dispatch(setMessagingChannelsData(service, null, false, e));

      if (!quietly) {
        LogError("Error obtaining channel list:", e);
        if (e.response && e.response.status == 403) {
          dispatch(
            fetchExistingOAuthConnections("Slack", "", true, true, true)
          );
          dispatch(fetchNewOAuth2RequestURL("Slack"));
          e.message = "connection to the workspace has been de-activated.";
        }
        throw new Error("Error obtaining channel list: " + e.message);
      }
    }

    return json ? json.channels : null;
  };
};

//
// postToOAuth2MessagingServiceChannel
// posts a message to a specific channel of a messaging service.
//
export const postToOAuth2MessagingServiceChannel = (
  service: string,
  channel: string,
  message: string,
  uuid: string
) => {
  return async (
    dispatch: DefaultThunkDispatch,
    getState: () => DefaultRootState
  ) => {
    dispatch(setMessageSendingStatus(service, true, null));

    let json: {
      status: string;
      service: string;
      channel: string;
      message: string;
    };
    try {
      json = await FetchCyberRiskUrl(
        "oauth/messaging/post/",
        {
          service: service,
          channel: channel,
          message: message,
          liquid_test_uuid: uuid,
        },
        { method: "POST" },
        dispatch,
        getState
      );
      dispatch(setMessageSendingStatus(json.service, false, null));
    } catch (e: any) {
      LogError("Error posting message to channel", e);
      dispatch(setMessageSendingStatus(service, false, e));
      // check to see if the current service token was invalidated
      if (e.response && e.response.status == 403) {
        dispatch(fetchExistingOAuthConnections(service, "", true, true, true));
        dispatch(fetchNewOAuth2RequestURL(service));
        e.message = "connection to the workspace has been de-activated.";
      }
      return "Error posting message to channel: " + e.message;
    }
    return "";
  };
};

//
// populateLiquidMessageForNotificationUUID
// takes a liquid message template for a specific (slack) integration, and a specific notification (uuid) and
// populates the template with test data based on the notification metadata definition.
//
export const populateLiquidMessageForNotificationUUID = (
  service: string,
  integrationId: string,
  message: string,
  uuid: string
) => {
  return async (
    dispatch: DefaultThunkDispatch,
    getState: () => DefaultRootState
  ) => {
    let json: {
      status: string;
      message: string;
    };
    const timestamp: number = new Date().getTime();
    try {
      dispatch(
        setPopulatedMessagingExpected(service, integrationId, uuid, timestamp)
      );
      json = await FetchCyberRiskUrl(
        "oauth/messaging/populate/",
        {
          message: message,
          uuid: uuid,
          include_newlines: service == MESSAGING_SERVICE_EMAIL,
        },
        { method: "GET" },
        dispatch,
        getState
      );
    } catch (e: any) {
      const expected = getPopulatedMessagingExpected(
        getState(),
        service,
        integrationId,
        uuid
      );
      if (expected === timestamp) {
        LogError("Error generating liquid message: ", e.json.error);
        dispatch(
          setPopulatedMessagingData(
            service,
            integrationId,
            uuid,
            message,
            e.json.error
          )
        );
      }
      return;
    }
    const expected = getPopulatedMessagingExpected(
      getState(),
      service,
      integrationId,
      uuid
    );
    if (expected === timestamp) {
      dispatch(
        setPopulatedMessagingData(
          service,
          integrationId,
          uuid,
          json.message,
          null
        )
      );
    }
  };
};

//
// fetchNewOAuthRequestURL
// retrieves a new redirect URL to request an oauth connection to a service (eg Jira). The URL is returned
// along with a UUID representing the unique identifier of the connection request. Any errors detected with the connection
// process will be stored against this id, as will the final connection instance.
//
export const fetchNewOAuth1RequestURL = (service: string, context: string) => {
  return async (
    dispatch: DefaultThunkDispatch,
    getState: () => DefaultRootState
  ) => {
    dispatch(setOAuthError(service, null));

    let json: {
      status: string;
      url: string;
      uuid: string;
    };
    try {
      json = await FetchCyberRiskUrl(
        "oauth1/request",
        { service: service, context: context },
        { method: "GET" },
        dispatch,
        getState
      );
      dispatch(setOAuthRequestURL(service, json.url, json.uuid, null));
    } catch (e: any) {
      LogError("Error obtaining new oauth access request url", e);

      throw new Error(
        "Error obtaining new oauth access request url: " + e.message
      );
    }
    return { url: json.url, uuid: json.uuid };
  };
};

//
// fetchJiraProjectList
// retrieves the current list of projects supported by the currently connected jira instance.
//
export const fetchJiraProjectList = (quietly = false, forced = false) => {
  return async (
    dispatch: DefaultThunkDispatch,
    getState: () => DefaultRootState
  ) => {
    if (!forced) {
      const { projects, loading, error } = getJiraProjectData(getState());
      if (!loading && !error && projects) {
        return projects;
      }
    }
    dispatch(setJiraProjectData(null, true, null));

    let json:
      | {
          status: string;
          service: string;
          projects: any;
        }
      | undefined;
    try {
      json = await FetchCyberRiskUrl<{
        status: string;
        service: string;
        projects: any;
      }>("oauth/jira/projects/", {}, { method: "GET" }, dispatch, getState);
      dispatch(setJiraProjectData(json.projects, false, null));
    } catch (e: any) {
      dispatch(setJiraProjectData(null, false, e));

      // check to see if the current service token was invalidated
      if (e.response && e.response.status == 403) {
        console.log(
          `fetchJiraProjectList: received 403 so refreshing connections list.`
        );
        dispatch(
          fetchExistingOAuthConnections(JIRA_SERVICE, "", true, true, true)
        );
        e.message = "connection to the workspace has been de-activated.";
      }
      if (!quietly) {
        LogError("Error obtaining jira projects list:", e);
        throw new Error("Failed to obtain jira projects list: " + e.message);
      }
    }
    return json ? json.projects : null;
  };
};

//
// reprioritiseJiraGrant
// takes the hostname of a specific Jira Cloud account, and makes sure that this account appears at the head of the list
// of sites for which the token grants access. As such, it represents the jira site that the user actually wants to interact
// with (for our purposes there can be only one!)
//
export const reprioritiseJiraGrant = (accountHostname: string) => {
  return async (
    dispatch: DefaultThunkDispatch,
    getState: () => DefaultRootState
  ) => {
    let json: {
      tokens: IOAuthToken[];
    };

    try {
      json = await FetchCyberRiskUrl(
        "oauth/jira/reprioritise/",
        { grant: accountHostname },
        { method: "PUT" },
        dispatch,
        getState
      );

      dispatch(setOAuthConnectionData(JIRA_SERVICE, json.tokens, false, null));
    } catch (e: any) {
      LogError("Error reprioritising jira grant:", e);

      throw new Error(
        "Failed to set the required Jira Cloud account: " + e.message
      );
    }
    return json.tokens;
  };
};

//
// fetchJiraIssueTypesList
// retrieves the current list of creatable issue types supported by a specific jira project.
//
export const fetchJiraIssueTypesList = (projectID: string, forced: boolean) => {
  return async (
    dispatch: DefaultThunkDispatch,
    getState: () => DefaultRootState
  ) => {
    if (!forced) {
      const { issueTypes, loading, error } = getJiraIssueTypesData(
        getState(),
        projectID
      );
      if (!loading && !error && issueTypes) {
        return issueTypes;
      }
    }

    dispatch(setJiraIssueTypesData(projectID, null, true, null));

    let json: {
      status: string;
      service: string;
      issueTypes: any;
    };
    try {
      json = await FetchCyberRiskUrl(
        "oauth/jira/issue_types/",
        { project_id: projectID },
        { method: "GET" },
        dispatch,
        getState
      );
      dispatch(setJiraIssueTypesData(projectID, json.issueTypes, false, null));
    } catch (e: any) {
      LogError("Error obtaining jira projects list:", e);

      // check to see if the current service token was invalidated
      if (e.response && e.response.status == 403) {
        console.log(
          `fetchJiraProjectList: received 403 so refreshing connections list.`
        );
        dispatch(setJiraProjectData(null, false, null));
        dispatch(
          fetchExistingOAuthConnections(JIRA_SERVICE, "", true, true, true)
        );
        e.message = "connection to the workspace has been de-activated.";
      }
      dispatch(setJiraIssueTypesData(projectID, null, false, e));

      throw new Error("Failed to obtain jira projects list: " + e.message);
    }
    return json.issueTypes;
  };
};

//
// searchAssignableUsers
// Used to support the selection list for assignable users (specific jira issue field type).
// Retrieves the list of users from the Jira API but does not store them in redux.
//
export const searchAssignableUsers = (
  projectID: string,
  searchTerm: string
) => {
  return async (
    dispatch: DefaultThunkDispatch,
    getState: any
  ): Promise<IJiraUser[]> => {
    let resp: IJiraUsersResponse;
    try {
      resp = await FetchCyberRiskUrl<IJiraUsersResponse>(
        "oauth/jira/assignable_users/",
        { username_prefix: searchTerm, project_id: projectID },
        null,
        dispatch,
        getState,
        undefined,
        undefined
      );
    } catch (e) {
      console.error(e);
      throw e;
    }
    return resp.users;
  };
};

//
// getProjectComponents
// Used to support the selection list for components.
// Retrieves the list of components from the Jira API but does not store them in redux.
//
export const getProjectComponents = (projectID: string, forced: boolean) => {
  return async (
    dispatch: DefaultThunkDispatch,
    getState: any
  ): Promise<IJiraComponent[]> => {
    if (!forced) {
      const { components, loading, error } = getJiraComponentsData(
        getState(),
        projectID
      );
      if (!loading && !error && components) {
        return components;
      }
    }

    dispatch(setJiraComponentsData(projectID, null, true, null));

    let json: {
      status: string;
      service: string;
      components: IJiraComponent[];
    };
    try {
      json = await FetchCyberRiskUrl(
        "oauth/jira/components/",
        { project_id: projectID },
        { method: "GET" },
        dispatch,
        getState
      );
      dispatch(setJiraComponentsData(projectID, json.components, false, null));
    } catch (e: any) {
      LogError("Error obtaining jira components list:", e);

      // check to see if the current service token was invalidated
      if (e.response && e.response.status == 403) {
        console.log(
          `getProjectComponents: received 403 so refreshing connections list.`
        );
        dispatch(setJiraProjectData(null, false, null));
        dispatch(
          fetchExistingOAuthConnections(JIRA_SERVICE, "", true, true, true)
        );
        e.message = "connection to the workspace has been de-activated.";
      }
      dispatch(setJiraComponentsData(projectID, null, false, e));

      throw new Error("Failed to obtain jira components list: " + e.message);
    }
    return json.components;
  };
};

//
// searchAccountUsers
// Used to support the selection list for users (specific jira issue field type).
// Retrieves the list of users from the Jira API but does not store them in redux.
//
export const searchAccountUsers = (searchTerm: string) => {
  return async (
    dispatch: DefaultThunkDispatch,
    getState: any
  ): Promise<IJiraUser[]> => {
    let resp: IJiraUsersResponse;
    try {
      resp = await FetchCyberRiskUrl<IJiraUsersResponse>(
        "oauth/jira/account_users/",
        { username_prefix: searchTerm },
        null,
        dispatch,
        getState,
        undefined,
        undefined
      );
    } catch (e) {
      console.error(e);
      throw e;
    }
    return resp.users;
  };
};

//
// searchAccountIssues
// Used to support the selection list for issue-links (specific jira issue field type). Operates in either 'epic' or
// 'non-epic' mode. Retrieves the list of issues from the Jira API but does not store them in redux.
//
export const searchAccountIssues = (
  projectID: string,
  epics: boolean,
  searchStr: string
) => {
  return async (
    dispatch: DefaultThunkDispatch,
    getState: any
  ): Promise<IJiraIssue[]> => {
    let resp: IJiraIssuesResponse;
    try {
      resp = await FetchCyberRiskUrl<IJiraIssuesResponse>(
        "oauth/jira/issues/",
        { project_id: projectID, epics: epics, search: searchStr },
        null,
        dispatch,
        getState,
        undefined,
        undefined
      );
    } catch (e) {
      console.error(e);
      throw e;
    }
    return resp.issues;
  };
};

//
// searchAccountUserGroups
// Used to support the selection list for user groups (specific jira issue field type).
// Retrieves the list of groups from the Jira API but does not store them in redux.
//
export const searchAccountUserGroups = (searchTerm: string) => {
  return async (
    dispatch: DefaultThunkDispatch,
    getState: any
  ): Promise<IJiraGroup[]> => {
    let resp: IJiraGroupsResponse;
    try {
      resp = await FetchCyberRiskUrl<IJiraGroupsResponse>(
        "oauth/jira/user_groups/",
        { group_prefix: searchTerm },
        null,
        dispatch,
        getState,
        undefined,
        undefined
      );
    } catch (e) {
      console.error(e);
      throw e;
    }
    return resp.groups;
  };
};

//
// searchProjectVersions
// Used to support the selection list for project versions (specific jira issue field type).
// Retrieves the list of versions from the Jira API but does not store them in redux.
//
export const searchProjectVersions = (
  projectID: string,
  searchTerm: string
) => {
  return async (
    dispatch: DefaultThunkDispatch,
    getState: any
  ): Promise<IJiraVersion[]> => {
    let resp: IJiraVersionsResponse;
    try {
      resp = await FetchCyberRiskUrl<IJiraVersionsResponse>(
        "oauth/jira/versions/",
        { project_id: projectID, version_prefix: searchTerm },
        null,
        dispatch,
        getState,
        undefined,
        undefined
      );
    } catch (e) {
      console.error(e);
      throw e;
    }
    return resp.versions;
  };
};

export const postIssueToOAuth2Jira = (
  uuid: string,
  projectId: string,
  fieldValues: { [key: string]: string }
) => {
  return async (
    dispatch: DefaultThunkDispatch,
    getState: () => DefaultRootState
  ) => {
    dispatch(setJiraIssueSendingStatus(true, null));

    const request: {
      fields: { [key: string]: string };
    } = {
      fields: fieldValues,
    };

    try {
      await FetchCyberRiskUrl(
        "oauth/jira/post/",
        {
          uuid: uuid,
          project_id: projectId,
        },
        { method: "POST", body: JSON.stringify(request) },
        dispatch,
        getState
      );
      dispatch(setJiraIssueSendingStatus(false, null));
    } catch (e: any) {
      LogError("Error posting message to channel", e);
      dispatch(setJiraIssueSendingStatus(false, e));
      // check to see if the current service token was invalidated
      if (e.response && e.response.status == 403) {
        dispatch(setJiraProjectData(null, false, null));
        dispatch(
          fetchExistingOAuthConnections(JIRA_SERVICE, "", true, true, true)
        );
        e.message = "connection to the workspace has been de-activated.";
      }
      throw "Error posting issue to jira project: " + e.message;
    }
  };
};
