import { FC } from "react";
import classnames from "classnames";
import PillLabel from "./PillLabel";
import LoadingIcon from "../../_common/components/core/LoadingIcon";
import "../style/views/AdditionalEvidenceDetails.scss";
import { formatDateAsLocal } from "../../_common/helpers";
import "../style/components/AdditionalEvidenceDocument.scss";
import { GetIconForFilename } from "../helpers/icons";
import { LabelColor } from "../../_common/types/label";
import IconButton, { HoverLocation } from "../../_common/components/IconButton";

interface AdditionalEvidenceDocumentProps {
  id: number;
  idx: number;
  name: string;
  filename: string;
  uploadedBy: string;
  uploadedAt: string;
  virusScanned: boolean;
  virusSafe: boolean;
  editable?: boolean;
  deletable?: boolean;
  downloadable: boolean;
  onEdit?: () => void;
  onOpen?: () => void;
  onDeleteDocument?: (
    idx: number,
    id: number,
    name: string,
    filename: string
  ) => void;
  onDownloadDocument: (
    id: number,
    virusScanned: boolean,
    virusSafe: boolean
  ) => void;
  userHasWriteAdditionalEvidencePermission: boolean;
}

const AdditionalEvidenceDocument: FC<AdditionalEvidenceDocumentProps> = (
  props
) => {
  return (
    <div
      className={classnames("evidence-document", {
        red: props.virusScanned && !props.virusSafe,
      })}
    >
      <img alt="File type icon" src={GetIconForFilename(props.filename)} />
      <div className={"details"}>
        {(!props.virusScanned || props.virusSafe) && (
          <div className={"name-row"}>
            <a
              onClick={() => {
                props.onDownloadDocument(
                  props.id,
                  props.virusScanned,
                  props.virusSafe
                );
              }}
            >
              {props.name}
            </a>
          </div>
        )}
        <div className={"uploaded-by"}>
          {`Attached by ${props.uploadedBy} on ${formatDateAsLocal(
            props.uploadedAt
          )}`}
        </div>
      </div>
      <div className={"icons"}>
        {!props.virusScanned && (
          <PillLabel color={LabelColor.Grey}>
            <div className={"scanning"}>
              <LoadingIcon />
              {"Virus scanning"}
            </div>
          </PillLabel>
        )}
        {props.virusScanned && !props.virusSafe && (
          <PillLabel color={LabelColor.Red} bordered>
            {"Virus found"}
          </PillLabel>
        )}
        {props.editable && props.onEdit && (
          <IconButton
            icon={<div className="icon-cycle" />}
            onClick={() => {
              props.onEdit?.();
            }}
            hoverText="Attach new version"
            popupDelay={0}
            hoverLocation={HoverLocation.Top}
            hoverMicro
          />
        )}
        {props.virusScanned && props.virusSafe && props.downloadable && (
          <IconButton
            icon={<div className="cr-icon-export" />}
            onClick={() => {
              props.onDownloadDocument(
                props.id,
                props.virusScanned,
                props.virusSafe
              );
            }}
            hoverText="Download"
            popupDelay={0}
            hoverLocation={HoverLocation.Top}
            hoverMicro
          />
        )}
        {props.onOpen && (
          <IconButton
            icon={<div className="cr-icon-external-link" />}
            onClick={() => {
              props.onOpen?.();
            }}
            hoverText="Open in new tab"
            popupDelay={0}
            hoverLocation={HoverLocation.Top}
            hoverMicro
          />
        )}
        {props.deletable &&
          props.onDeleteDocument &&
          props.userHasWriteAdditionalEvidencePermission && (
            <IconButton
              icon={<div className="cr-icon-trash" />}
              onClick={() => {
                props.onDeleteDocument?.(
                  props.idx,
                  props.id,
                  props.name,
                  props.filename
                );
              }}
              hoverText="Delete"
              popupDelay={0}
              hoverLocation={HoverLocation.Top}
              hoverMicro
            />
          )}
      </div>
    </div>
  );
};

export default AdditionalEvidenceDocument;
