import {
  IAdditionalEvidenceCheckToAdd,
  IAdditionalEvidenceDocumentToAdd,
} from "../../reducers/remediation.actions";
import { Reducer } from "react";

export type AdditionalEvidenceCheckAction =
  | { type: "init" }
  | { type: "removeCheckID"; riskId: string }
  | { type: "addCheckID"; riskId: string };

export type IAdditionalEvidenceCheckReducer = Reducer<
  Record<string, IAdditionalEvidenceCheckToAdd>,
  AdditionalEvidenceCheckAction
>;

export const AdditionalEvidenceCheckReducer: IAdditionalEvidenceCheckReducer = (
  state: Record<string, IAdditionalEvidenceCheckToAdd>,
  action: AdditionalEvidenceCheckAction
): Record<string, IAdditionalEvidenceCheckToAdd> => {
  const newState = { ...state };
  switch (action.type) {
    case "init":
      return {};
    case "addCheckID":
      newState[action.riskId] = { riskID: action.riskId };
      return newState;
    case "removeCheckID":
      delete newState[action.riskId];
      return newState;
    default:
      return newState;
  }
};

export type AdditionalEvidenceDocumentAction =
  | { type: "init" }
  | { type: "removeEvidenceID"; evidenceId: number }
  | { type: "addEvidenceID"; evidenceId: number };

export type IAdditionalEvidenceDocumentReducer = Reducer<
  Record<number, IAdditionalEvidenceDocumentToAdd>,
  AdditionalEvidenceDocumentAction
>;

export const AdditionalEvidenceDocumentReducer: IAdditionalEvidenceDocumentReducer =
  (
    state: Record<number, IAdditionalEvidenceDocumentToAdd>,
    action: AdditionalEvidenceDocumentAction
  ): Record<number, IAdditionalEvidenceDocumentToAdd> => {
    const newState = { ...state };
    switch (action.type) {
      case "init":
        return {};
      case "addEvidenceID":
        newState[action.evidenceId] = { evidenceId: action.evidenceId };
        return newState;
      case "removeEvidenceID":
        delete newState[action.evidenceId];
        return newState;
      default:
        return newState;
    }
  };
