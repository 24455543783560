import { useState } from "react";

import "../style/components/CheckboxBig.scss";
import ColorCheckbox from "../../vendorrisk/components/ColorCheckbox";

export const CheckboxBig = (props: {
  checked: boolean;
  setChecked: (checked: boolean) => void;
  label: string;
  iconImage: string;
  tooltipText: string;
}) => {
  const [tooltipVisible, setTooltipVisible] = useState(false);

  return (
    <div>
      <button
        className={"checkbox-big"}
        onClick={() => {
          props.setChecked(!props.checked);
        }}
        onMouseEnter={() => setTooltipVisible(true)}
        onMouseLeave={() => setTooltipVisible(false)}
      >
        <div className={"checkbox-big-head"}>
          <ColorCheckbox
            checked={props.checked}
            className={"checkbox-big-checkbox"}
          ></ColorCheckbox>
        </div>
        <div className={"checkbox-big-icon"}>
          <div className={"checkbox-big-icon-circle"}>
            <img src={props.iconImage} alt={props.label} />
          </div>
        </div>
        <div className={"checkbox-big-text"}>{props.label}</div>
      </button>
      <div
        className={`checkbox-big-tooltip ${
          tooltipVisible && !!props.tooltipText ? "visible" : ""
        }`}
      >
        <div className={"triangle-up"} />
        {props.tooltipText}
      </div>
    </div>
  );
};
