import React, { useState, useEffect } from "react";
import ModalV2 from "../ModalV2";
import Button from "../core/Button";
import ColorCheckbox from "../../../vendorrisk/components/ColorCheckbox";
import moment from "moment";
import DatePicker from "../DatePicker";
import "../../style/components/UpdateDueDateModal.scss";

const fmt = "YYYY-MM-DD";
const datePickerFmt = "Y-m-d";

export type reminderSelectionType = "none" | "7b" | "30b" | "custom";

export const useSchedule = (
  currentDueDate?: string,
  currentReminderDate?: string
): [
  string | undefined,
  string | undefined,
  reminderSelectionType,
  (dateStr?: string) => void,
  React.Dispatch<React.SetStateAction<string | undefined>>,
  (selection: reminderSelectionType) => void,
  (dueDate?: string, reminderDate?: string) => reminderSelectionType,
] => {
  const [dueDate, _setDueDate] = useState(() => {
    if (!!currentDueDate) {
      return moment(currentDueDate).format(fmt);
    }
    return undefined;
  });
  const [reminderDate, setReminderDate] = useState(() => {
    if (!!currentReminderDate) {
      return moment(currentReminderDate).format(fmt);
    }
    return undefined;
  });

  const getReminderSelection = (dueDate?: string, reminderDate?: string) => {
    let numDays = 0;
    if (!!dueDate && !!reminderDate) {
      numDays = moment(dueDate).diff(moment(reminderDate), "days");
    }
    if (!dueDate || !reminderDate) {
      return "none";
    }
    if (numDays === 7) {
      return "7b";
    }
    if (numDays === 30) {
      return "30b";
    }

    return "custom";
  };
  const [reminderSelection, _setReminderSelection] =
    useState<reminderSelectionType>(() => {
      return getReminderSelection(dueDate, reminderDate);
    });

  const setDueDate = (dateStr?: string) => {
    _setDueDate(dateStr);
    if (!dateStr) {
      setReminderDate(undefined);
      _setReminderSelection("none");
    } else if (reminderSelection === "7b") {
      setReminderDate(moment(dateStr).subtract(7, "days").format(fmt));
    } else if (reminderSelection === "30b") {
      setReminderDate(moment(dateStr).subtract(30, "days").format(fmt));
    } else if (
      reminderSelection === "custom" &&
      moment(dateStr).isBefore(moment(reminderDate))
    ) {
      // if the date is a custom date and the due date is before the reminder reset the reminder to the due date
      setReminderDate(dateStr);
    }
  };

  const setReminderSelection = (selection: reminderSelectionType) => {
    if (selection === reminderSelection) {
      return;
    }

    _setReminderSelection(selection);
    if (selection === "none") {
      setReminderDate(undefined);
    } else if (selection === "7b") {
      setReminderDate(moment(dueDate).subtract(7, "days").format(fmt));
    } else if (selection === "30b") {
      setReminderDate(moment(dueDate).subtract(30, "days").format(fmt));
    } else {
      setReminderDate(dueDate);
    }
  };

  return [
    dueDate,
    reminderDate,
    reminderSelection,
    setDueDate,
    setReminderDate,
    setReminderSelection,
    getReminderSelection,
  ];
};

interface IUpdateDueDateModalProps {
  dueDate?: string;
  reminderDate?: string;
  active: boolean;
  onClose: () => void;
  updateDueDate: (dueDate?: string, reminderDate?: string) => Promise<any>;
}

const UpdateDueDateModal = (props: IUpdateDueDateModalProps) => {
  const [loading, setLoading] = useState(false);
  const [
    newDueDate,
    newReminderDate,
    reminderSelection,
    setDueDate,
    setReminderDate,
    setReminderSelection,
    getReminderSelection,
  ] = useSchedule(props.dueDate, props.reminderDate);

  const doSave = async () => {
    setLoading(true);
    try {
      await props.updateDueDate(newDueDate, newReminderDate);
      props.onClose();
    } catch (e) {
      throw e;
    }
    setLoading(false);
  };

  useEffect(() => {
    // Reset state values to props
    setReminderSelection(
      getReminderSelection(props.dueDate, props.reminderDate)
    );
    if (!!props.reminderDate) {
      setReminderDate(moment(props.reminderDate).format(fmt));
    }
    if (!!props.dueDate) {
      setDueDate(moment(props.dueDate).format(fmt));
    }
  }, [props.active]);

  return (
    <ModalV2
      className={"update-due-date-modal"}
      active={props.active}
      headerContent={
        <>
          <h2>Due dates and reminders</h2>
          <p>Edit the due dates and reminders for this remediation request</p>
        </>
      }
      footerContent={
        <>
          <Button tertiary onClick={props.onClose} disabled={loading}>
            Cancel
          </Button>
          <Button filledPrimary onClick={doSave} loading={loading}>
            <span className={"cr-icon-check"} /> Save changes
          </Button>
        </>
      }
      onClose={props.onClose}
    >
      <div className={"form-grid"}>
        <div className={"left"}>
          <h3>Due date</h3>
          <p>
            Set a due date if you would like this request to be completed by a
            specified date.
          </p>
        </div>
        <div className={"right"}>
          <ColorCheckbox
            radio
            checked={!newDueDate}
            onClick={() => setDueDate(undefined)}
            label={"No due date"}
          />
          <ColorCheckbox
            radio
            checked={!!newDueDate}
            onClick={() => {
              if (!newDueDate) {
                setDueDate(moment().add(60, "days").format(fmt));
              }
            }}
            label={"Remediation request is due on"}
          />
          <DatePicker
            onChange={(evt) => setDueDate(evt.target.value)}
            min={moment().format(fmt)}
            disabled={!newDueDate}
            value={newDueDate}
            placeholder={newDueDate}
            displayFormat={datePickerFmt}
          />
        </div>
        <div className={"left"}>
          <h3>Reminder</h3>
          <p>
            If you choose to set a reminder, we’ll remind the recipients of your
            request on the specified date.
          </p>
        </div>
        <div className={"right"}>
          <ColorCheckbox
            radio
            checked={reminderSelection === "none"}
            onClick={() => setReminderSelection("none")}
            label={"No reminder"}
            disabled={!newDueDate}
          />
          <ColorCheckbox
            radio
            checked={reminderSelection === "7b"}
            onClick={() => setReminderSelection("7b")}
            label={"7 days before due date"}
            disabled={!newDueDate}
          />
          <ColorCheckbox
            radio
            checked={reminderSelection === "30b"}
            onClick={() => setReminderSelection("30b")}
            label={"30 days before due date"}
            disabled={!newDueDate}
          />
          <ColorCheckbox
            radio
            checked={reminderSelection === "custom"}
            onClick={() => setReminderSelection("custom")}
            label={"Select custom date"}
            disabled={!newDueDate}
          />
          <DatePicker
            onChange={(evt) => setReminderDate(evt.target.value)}
            min={moment().format(fmt)}
            disabled={reminderSelection !== "custom"}
            value={newReminderDate}
            placeholder={reminderSelection === "none" ? undefined : newDueDate}
            displayFormat={datePickerFmt}
          />
        </div>
      </div>
    </ModalV2>
  );
};

export default UpdateDueDateModal;
