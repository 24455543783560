import { DefaultThunkDispatchProp } from "../../_common/types/redux";
import Button from "../../_common/components/core/Button";
import {
  clearAnswersPendingSave,
  setLockState,
  setScrollTargetNode,
} from "../reducers/actions";
import {
  AnswersForNodes,
  FlattenedNodeSummaryMap,
  isQuestionNode,
  NodeSummaryAndNode,
  VisiblityForNodes,
} from "../surveyViewer.helpers";
import { useCallback, useEffect, useLayoutEffect, useState } from "react";
import { debounce as _debounce, isEqual as _isEqual } from "lodash";
import { updateSurveyAnswers } from "../reducers/apiActions";
import {
  addDefaultUnknownErrorAlert,
  addDefaultWarningAlert,
} from "../../_common/reducers/messageAlerts.actions";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { ExtraAttachmentsNodeId } from "./SurveyViewerExtraAttachments";
import classNames from "classnames";
import { appConnect } from "../../_common/types/reduxHooks";

interface SurveyViewerControlsOwnProps {
  surveyId?: number;
  nodeTree: NodeSummaryAndNode;
  isPublicSurvey: boolean;
  flattenedNodeMap: FlattenedNodeSummaryMap;
  sidebarCollapsed: boolean;
}

interface SurveyViewerControlsConnectedProps {
  activeNodeId?: string;
  nodeVisibilities: VisiblityForNodes;
  answersPendingSave: AnswersForNodes;
  targetNodeId?: string;
}

type SurveyViewerControlsProps = SurveyViewerControlsOwnProps &
  SurveyViewerControlsConnectedProps &
  DefaultThunkDispatchProp;

// Also responsible for auto-saving
const SurveyViewerControls = (props: SurveyViewerControlsProps) => {
  const [isSaving, setIsSaving] = useState(false);
  const [didJustSave, setDidJustSave] = useState(false);
  const [lastNodeId, setLastNodeId] = useState<string>(props.nodeTree.nodeId);

  const setSavedStateDisplay = () => {
    setDidJustSave(true);
    setTimeout(() => {
      setDidJustSave(false);
    }, 2000);
  };
  const debouncedSetSavedStateDisplay = useCallback(
    _debounce(setSavedStateDisplay, 500),
    []
  );

  // Update last node id if required
  useEffect(() => {
    const newNodeId =
      props.targetNodeId ?? props.activeNodeId ?? props.nodeTree.nodeId;
    if (newNodeId !== lastNodeId) {
      setLastNodeId(
        props.targetNodeId ?? props.activeNodeId ?? props.nodeTree.nodeId
      );
    }
  }, [props.activeNodeId, props.targetNodeId]);

  // Save any pending answers, debounced to 3s
  const saveAnswers = (answers: AnswersForNodes) => {
    if (props.surveyId && !_isEqual(answers, {})) {
      setIsSaving(true);
      props
        .dispatch(
          updateSurveyAnswers(
            props.surveyId,
            answers,
            false,
            true,
            props.isPublicSurvey
          )
        )
        .then((resp) => {
          if (resp.status === "LOCKED") {
            props.dispatch(
              setLockState({
                isLocked: true,
                lockedBy: resp.lockedBy,
              })
            );
            props.dispatch(
              addDefaultWarningAlert(
                `This questionnaire has been locked by ${resp.lockedBy}.`,
                [
                  "It will become available after they are inactive for 5 minutes",
                ]
              )
            );
            setIsSaving(false);
          } else {
            // Clear the answers we just saved
            props.dispatch(clearAnswersPendingSave(answers));
            debouncedSetSavedStateDisplay();
            setIsSaving(false);
          }
        })
        .catch(() => {
          props.dispatch(
            addDefaultUnknownErrorAlert("Error saving questionnaire answers")
          );
          setIsSaving(false);
        });
    }
  };

  const debouncedSave = useCallback(_debounce(saveAnswers, 2000), []);

  // 'Listen' for a partial save to trigger
  useLayoutEffect(() => {
    if (props.answersPendingSave) {
      debouncedSave(props.answersPendingSave);
    }
  }, [props.answersPendingSave]);

  const findNextNode = () => {
    let isNext = false;
    let nextNodeId = "";

    for (const [nodeId, n] of Object.entries(props.flattenedNodeMap)) {
      if (
        props.nodeVisibilities[nodeId] !== true &&
        n.nodeId !== ExtraAttachmentsNodeId
      ) {
        continue;
      }

      if (isNext && isQuestionNode(n)) {
        nextNodeId = n.nodeId;
        break;
      }

      if (n.nodeId === lastNodeId) {
        isNext = true;
      }
    }

    if (nextNodeId) {
      setLastNodeId(nextNodeId);
      props.dispatch(setScrollTargetNode(nextNodeId, true));
    }
  };

  const findPreviousNode = () => {
    let previousNodeId = "";

    for (const [nodeId, n] of Object.entries(props.flattenedNodeMap)) {
      if (props.nodeVisibilities[nodeId] !== true) {
        continue;
      }

      if (n.nodeId === lastNodeId) {
        break;
      } else if (isQuestionNode(n)) {
        previousNodeId = n.nodeId;
      }
    }

    if (previousNodeId) {
      setLastNodeId(previousNodeId);
      props.dispatch(setScrollTargetNode(previousNodeId, true));
    }
  };

  // Note: next/prev buttons are hidden for now
  return (
    <div
      className={classNames("survey-viewer-controls", {
        "sidebar-collapsed": props.sidebarCollapsed,
      })}
    >
      <Button tertiary style={{ display: "none" }}>
        <i
          className={"cr-icon-arrow-right rotate-270"}
          onClick={findPreviousNode}
        />
      </Button>
      <Button tertiary onClick={findNextNode} style={{ display: "none" }}>
        <i className={"cr-icon-arrow-right rotate-90"} />
      </Button>
      <TransitionGroup component={null}>
        {isSaving && (
          <CSSTransition
            key={"saving"}
            timeout={250}
            classNames="fade-transition"
          >
            <div className={"saving-text"}>
              <i className={"cr-icon-redo"} />
              <div>Saving progress...</div>
            </div>
          </CSSTransition>
        )}
        {didJustSave && (
          <CSSTransition
            key={"saved"}
            timeout={250}
            classNames="fade-transition"
          >
            <div className={"saved-text"}>
              <i className={"cr-icon-accepted"} />
              <div>Progress saved</div>
            </div>
          </CSSTransition>
        )}
      </TransitionGroup>
    </div>
  );
};

export default appConnect<
  SurveyViewerControlsConnectedProps,
  never,
  SurveyViewerControlsOwnProps
>((state) => {
  return {
    activeNodeId: state.surveyViewer.activeNodeId,
    nodeVisibilities: state.surveyViewer.nodeVisibilities,
    answersPendingSave: state.surveyViewer.answersPendingSaving,
    targetNodeId: state.surveyViewer.scrollTargetNodeId,
  };
})(SurveyViewerControls);
