import ReactMarkdown from "react-markdown";

const suppressedSrcImg = ({ alt }: { alt: string }) => <img alt={alt} />;

export interface IMarkdownProps {
  content: string;
}

/*
strip the src from an img element to avoid attacker embedding
images into markdown that would trigger requests from the user
machine to the attacker website
*/
const Markdown = ({ content }: IMarkdownProps) => (
  <ReactMarkdown
    source={content}
    renderers={{
      image: suppressedSrcImg,
    }}
  />
);

export default Markdown;
