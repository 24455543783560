import { FC, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { UserWithStats } from "../api/types";
import UserBaseAPI from "../api/userbase.api";
import { userUrl } from "../UserBaseAppRouter";
import TeamsPills from "./TeamsPills";

import ColorGrade, {
  ColorGradeSize,
} from "../../vendorrisk/components/executive_summary/ColorGrade";
import Score from "../../vendorrisk/components/Score";
import CyberTrendScore from "../../vendorrisk/components/CyberTrendScore";
import {
  addDefaultSuccessAlert,
  addDefaultUnknownErrorAlert,
} from "../../_common/reducers/messageAlerts.actions";
import { DropdownItem } from "../../_common/components/core/DropdownV2";
import { useAppDispatch } from "../../_common/types/reduxHooks";
import { useDefaultHistory } from "../../_common/types/router";
import {
  useLocalStorageState,
  useSortingWithPagination,
} from "../../_common/hooks";
import { useFilters } from "../../vendorrisk/reducers/filters.actions";
import { useConfirmationModalV2 } from "../../_common/components/modals/ConfirmationModalV2";
import {
  FilterTypes,
  isFilterActive,
} from "../../vendorrisk/components/filter";
import { parseUserRiskFilterFromQuery } from "../helpers/filters";
import { getClearedFilters } from "../../vendorrisk/components/filter/FilterPanel";
import { Filters } from "../../vendorrisk/components/filter/types";
import { setCustomerDataFilters } from "../../vendorrisk/reducers/cyberRiskActions";

import SearchBox from "../../_common/components/SearchBox";
import XTable, {
  IIconOption,
  IXTableColumnHeader,
  IXTableRow,
  SortDirection,
  XTableCell,
} from "../../_common/components/core/XTable";
import SearchEmptyCard from "../../_common/components/SearchEmptyCard";
import EmptyCardWithAction from "../../_common/components/EmptyCardWithAction";
import UserIcon from "../../_common/images/remediation-icon.svg";
import ActionBar from "../../_common/components/ActionBar";
import Button from "../../_common/components/core/Button";
import {
  useHasUserOrPortfolioPermissions,
  UserUserBaseWrite,
} from "../../_common/permissions";
import ToggleWithLabel from "../../vendorrisk/components/ToggleWithLabel";
import "./MonitoredUsers.scss";
import DisplayingUsers from "./users/DisplayingUsers";

const userIsAtRisk = (u: UserWithStats): boolean => {
  return u.adjustedRating < 950;
};

const PAGE_SIZE = 100;

const NAME_COL = "name_col";
const ROLE_COL = "role_col";
const TEAM_COL = "team_col";
const SECURITY_RATING_COL = "security_rating_col";
const TREND_COL = "trend_col";
const TOTAL_APPS_COL = "total_apps_col";
const NOT_APPROVED_APPS_COL = "not_approved_apps_col";
const WAIVED_APPS_COL = "waived_apps_col";

const supportedFilters = [
  FilterTypes.USERRISK_USER_UUIDS,
  FilterTypes.USERRISK_APPS,
  FilterTypes.USERRISK_TEAMS,
  FilterTypes.USERRISK_ROLES,
  FilterTypes.USERRISK_USER_SCORES,
];

const MonitoredUsers: FC = () => {
  const history = useDefaultHistory();
  const { pathname } = useLocation();
  const dispatch = useAppDispatch();

  const [searchText, setSearchText] = useState<string>("");
  const [selectedRows, setSelectedRows] = useState<Set<string>>(new Set());
  const [showOnlyRiskyUsers, setShowOnlyRiskyUsers] = useLocalStorageState(
    "userrisk-monitored-risky-users",
    false
  );

  const userHasUserBaseWrite =
    useHasUserOrPortfolioPermissions(UserUserBaseWrite);

  const filters = useFilters();
  const filtersActive = isFilterActive(filters, supportedFilters);

  const { data: monitoredUsers, isFetching: monitoredIsFetching } =
    UserBaseAPI.useGetUserBaseUsersV1Query({
      userUUIDs: filters.userRiskUserUUIDs,
      excludeUsers: filters.userRiskExcludeUsers,
      appNames: filters.userRiskAppNames,
      excludeApps: filters.userRiskExcludeApps,
      teams: filters.userRiskTeams,
      excludeTeams: filters.userRiskExcludeTeams,
      roles: filters.userRiskRoles,
      excludeRoles: filters.userRiskExcludeRoles,
      minScore: filters.userRiskUserMinScore,
      maxScore: filters.userRiskUserMaxScore,
    });

  useEffect(() => {
    const queryParamFilters = {
      ...getClearedFilters(supportedFilters),
      ...parseUserRiskFilterFromQuery(history),
    } as Filters;
    if (isFilterActive(queryParamFilters, supportedFilters)) {
      dispatch(setCustomerDataFilters(queryParamFilters));
    }
  }, []);

  const [bulkUnmonitorInProgress, setBulkUnmonitorInProgress] =
    useState<boolean>(false);

  const [openUnmonitorConfirmationModal, unmonitorConfirmationModal] =
    useConfirmationModalV2();

  // Get the users to display from the API
  const usersToUse = (monitoredUsers?.users ?? []).filter((u) =>
    showOnlyRiskyUsers ? userIsAtRisk(u) : true
  );

  // Filter the users by the search text
  const bySearchText = (user: UserWithStats): boolean => {
    if (searchText.length == 0) {
      return true;
    }

    const toFind = searchText.toLocaleLowerCase();

    if (
      user.roles &&
      user.roles.some((r) => r.toLocaleLowerCase().includes(toFind))
    ) {
      return true;
    }

    if (
      user.teams &&
      user.teams.some((t) => t.toLocaleLowerCase().includes(toFind))
    ) {
      return true;
    }

    return user.name.toLocaleLowerCase().indexOf(toFind) >= 0;
  };

  const filteredUsers = usersToUse.filter(bySearchText);

  // Sort and Paginate the users
  const [
    usersToDisplay,
    sortedBy,
    onSortChange,
    currentPage,
    totalPages,
    onPageChange,
  ] = useSortingWithPagination<
    UserWithStats,
    | typeof NAME_COL
    | typeof ROLE_COL
    | typeof TEAM_COL
    | typeof SECURITY_RATING_COL
    | typeof TOTAL_APPS_COL
    | typeof NOT_APPROVED_APPS_COL
    | typeof WAIVED_APPS_COL
    | typeof TREND_COL
  >(
    filteredUsers,
    NAME_COL,
    SortDirection.ASC,
    {
      [NAME_COL]: {
        orderFuncs: [(u) => u.name.toLocaleLowerCase()],
        sortDirsAsc: [SortDirection.ASC],
        sortDirsDesc: [SortDirection.DESC],
      },
      [ROLE_COL]: {
        orderFuncs: [
          (u) =>
            (u.roles ?? [])
              .map((t) => t.toLocaleLowerCase())
              .sort((a, b) => a.localeCompare(b))
              .join(","),
        ],
        sortDirsAsc: [SortDirection.ASC],
        sortDirsDesc: [SortDirection.DESC],
      },
      [TEAM_COL]: {
        orderFuncs: [
          (u) =>
            (u.teams ?? [])
              .map((t) => t.toLocaleLowerCase())
              .sort((a, b) => a.localeCompare(b))
              .join(","),
        ],
        sortDirsAsc: [SortDirection.ASC],
        sortDirsDesc: [SortDirection.DESC],
      },
      [SECURITY_RATING_COL]: {
        orderFuncs: [(u) => u.adjustedRating],
        sortDirsAsc: [SortDirection.ASC],
        sortDirsDesc: [SortDirection.DESC],
      },
      [TOTAL_APPS_COL]: {
        orderFuncs: [(u) => u.totalApps],
        sortDirsAsc: [SortDirection.ASC],
        sortDirsDesc: [SortDirection.DESC],
      },
      [NOT_APPROVED_APPS_COL]: {
        orderFuncs: [(u) => u.notApprovedApps],
        sortDirsAsc: [SortDirection.ASC],
        sortDirsDesc: [SortDirection.DESC],
      },
      [WAIVED_APPS_COL]: {
        orderFuncs: [(u) => u.waivedApps],
        sortDirsAsc: [SortDirection.ASC],
        sortDirsDesc: [SortDirection.DESC],
      },
      [TREND_COL]: {
        orderFuncs: [(u) => u.trend],
        sortDirsAsc: [SortDirection.ASC],
        sortDirsDesc: [SortDirection.DESC],
      },
    },
    PAGE_SIZE,
    undefined,
    pathname
  );

  const columnHeaders: IXTableColumnHeader[] = [
    { id: NAME_COL, text: "Name", sortable: true },
    { id: ROLE_COL, text: "Role", sortable: true },
    { id: TEAM_COL, text: "Team", sortable: true },
    { id: SECURITY_RATING_COL, text: "Security rating", sortable: true },
    { id: TREND_COL, text: "Trend", sortable: true },
    { id: TOTAL_APPS_COL, text: "Total apps", sortable: true },
    {
      id: NOT_APPROVED_APPS_COL,
      text: "Not approved apps",
      sortable: true,
    },
    {
      id: WAIVED_APPS_COL,
      text: "Waived apps",
      sortable: true,
    },
  ];

  const [unmonitorUsersV1] = UserBaseAPI.useUnmonitorUsersV1Mutation();

  const unmonitorUsers = (userUUIDs: Set<string>) => {
    const numSelected = userUUIDs.size;
    openUnmonitorConfirmationModal({
      title: `Confirm disable monitoring`,
      description: (
        <>
          <p>
            Disabling monitoring will remove{" "}
            {numSelected == 1 ? "this user" : "these users"} and any associated
            risks from your risk profile, which will no longer impact your
            overall UserRisk score.
          </p>
          <p>
            You will also lose access to{" "}
            {numSelected == 1 ? "this user's profile" : "these users' profiles"}
            , including their SaaS app usage and permissions.
          </p>
        </>
      ),
      dangerousAction: true,
      cancelText: "Cancel",
      buttonText: "Disable monitoring",
      buttonAction: () => {
        setBulkUnmonitorInProgress(true);
        unmonitorUsersV1({
          user_uuids: Array.from(userUUIDs.values()),
        })
          .unwrap()
          .then(() => {
            // If the user's we're unmonitoring don't match the selected rows, then remove the
            // users from the selected rows
            const diff = new Set<string>();
            for (const v of selectedRows) {
              if (!userUUIDs.has(v)) {
                diff.add(v);
              }
            }
            setSelectedRows(diff);
            dispatch(
              addDefaultSuccessAlert(
                `${
                  numSelected == 1 ? "1 user is" : `${numSelected} users are`
                } no longer monitored`
              )
            );
          })
          .catch(() => {
            dispatch(
              addDefaultUnknownErrorAlert(
                `Unable to unmonitor ${numSelected} users`
              )
            );
          })
          .finally(() => {
            setBulkUnmonitorInProgress(false);
          });
      },
    });
  };

  const rows: IXTableRow<string>[] = usersToDisplay.map((u) => {
    const options: IIconOption[] = [];
    if (userHasUserBaseWrite) {
      options.push({
        id: "meatball",
        icon: <div className={"cr-icon-dots-menu"} />,
        disabled: false,
        dropdownItems: [
          <DropdownItem
            key="unmomitor"
            stopPropagation
            className="unmonitor-meatball"
            onClick={() => unmonitorUsers(new Set([u.uuid]))}
          >
            <div className="unmonitor-label">
              <i className="cr-icon-minus-circle" />
              <div>Disable monitoring</div>
            </div>
            <div className="unmonitor-sublabel">
              Do not include in the risk profile
            </div>
          </DropdownItem>,
        ],
      });
    }
    options.push({
      id: "click",
      icon: <div className={"cr-icon-chevron"} />,
      disabled: false,
      onClick: () => {
        history.push(userUrl(u.uuid));
      },
    });

    return {
      id: u.uuid,
      onClick: () => {
        history.push(userUrl(u.uuid));
      },
      cells: [
        <XTableCell key={NAME_COL}>{u.name}</XTableCell>,
        <XTableCell key={ROLE_COL}>{u.roles ? u.roles[0] : ""}</XTableCell>,
        <XTableCell key={TEAM_COL}>
          {u.teams ? <TeamsPills teams={u.teams} /> : " "}
        </XTableCell>,
        <XTableCell key={SECURITY_RATING_COL}>
          <div className="score-container">
            <ColorGrade score={u.adjustedRating} size={ColorGradeSize.Small} />
            <Score score={u.adjustedRating} small />
          </div>
        </XTableCell>,
        <XTableCell key={TREND_COL}>
          <div className="cyber-trend">
            <CyberTrendScore trend={u.trend} />
          </div>
        </XTableCell>,
        <XTableCell key={TOTAL_APPS_COL}>{u.totalApps}</XTableCell>,
        <XTableCell key={NOT_APPROVED_APPS_COL}>
          {u.notApprovedApps}
        </XTableCell>,
        <XTableCell key={WAIVED_APPS_COL}>{u.waivedApps}</XTableCell>,
      ],
      iconOptions: options,
    };
  });

  return (
    <div className={"userbase-monitored-users-list"}>
      <div className={"users-filters"}>
        <SearchBox
          placeholder={"Search users"}
          onChanged={(q) => {
            onPageChange(1);
            setSearchText(q);
          }}
          value={searchText}
        />
        <ToggleWithLabel
          onClick={() => setShowOnlyRiskyUsers(!showOnlyRiskyUsers)}
          selected={showOnlyRiskyUsers}
          name={"risky-users"}
          label={"Show only users with risks"}
        />
        <DisplayingUsers count={filteredUsers.length} />
      </div>

      <XTable
        className={"userbase-users-list"}
        loading={monitoredIsFetching}
        sortedBy={sortedBy}
        onSortChange={onSortChange}
        iconOptions
        pagination={{
          currentPage: currentPage,
          totalPages: totalPages,
          onPageChange: onPageChange,
          hidePaginationIfSinglePage: true,
        }}
        columnHeaders={columnHeaders}
        selectable
        onSelectClick={(rowId, newSelectedState) => {
          const newSelectedRows = new Set(selectedRows.values());
          if (newSelectedState) {
            newSelectedRows.add(rowId);
          } else {
            newSelectedRows.delete(rowId);
          }
          setSelectedRows(newSelectedRows);
        }}
        onSelectToggle={(shouldSelectAll) => {
          if (shouldSelectAll) {
            setSelectedRows(new Set(filteredUsers.map((u) => u.uuid)));
          } else {
            setSelectedRows(new Set());
          }
        }}
        onSelectAllClick={() => {
          setSelectedRows(new Set(filteredUsers.map((u) => u.uuid)));
        }}
        onSelectNoneClick={() => {
          setSelectedRows(new Set());
        }}
        rows={rows.map((r) => ({
          ...r,
          selected: selectedRows.has(r.id),
        }))}
      />

      {searchText.length == 0 &&
        !filtersActive &&
        rows.length == 0 &&
        !monitoredIsFetching && (
          <EmptyCardWithAction
            emptyText="No current users monitored"
            emptySubText="You haven’t monitored any users yet. When you start monitoring a user, they will appear here."
            iconSrc={UserIcon}
          />
        )}

      {(searchText.length > 0 || filtersActive) &&
        rows.length == 0 &&
        !monitoredIsFetching && (
          <SearchEmptyCard
            clearText={"Clear filter"}
            onClear={() => {
              setSearchText("");
              dispatch(
                setCustomerDataFilters(getClearedFilters(supportedFilters))
              );
            }}
            searchItemText={"monitored users"}
          />
        )}

      {unmonitorConfirmationModal}

      <ActionBar className="bulk-action-bar" active={selectedRows.size > 0}>
        <div className="bulk-actions">
          <div className="num-selected">
            {selectedRows.size == 1
              ? "1 user selected"
              : `${selectedRows.size} users selected`}
          </div>
          <div className="bulk-action-buttons">
            <Button tertiary onClick={() => setSelectedRows(new Set())}>
              Cancel
            </Button>
            <Button
              className="disable-monitoring"
              onClick={() => unmonitorUsers(selectedRows)}
              loading={bulkUnmonitorInProgress}
              danger
            >
              <i className="cr-icon-minus-circle" />
              Disable monitoring
            </Button>
          </div>
        </div>
      </ActionBar>
    </div>
  );
};

export default MonitoredUsers;
