import { useModalV2 } from "../../../_common/components/ModalV2";
import { TipsModal } from "../../../_common/components/modals/TipsModal";
import { contactSupport } from "../../../_common/helpers";
import messages from "../../../_common/images/survey-tips/messages.svg";
import enhance from "../../../_common/images/survey-tips/enhance.svg";
import autofill from "../../../_common/images/survey-tips/autofill.svg";
import autofillNoExport from "../../../_common/images/survey-tips/autofill-no-export.svg";
import { SurveyUsageType } from "../../../_common/types/surveyTypes";

export const useSurveyViewerTipsModal = (
  orgName: string,
  qnType: SurveyUsageType,
  isImportedSurvey: boolean
) => {
  const [_openModal, modal] = useModalV2(TipsModal);

  const openModal = () =>
    _openModal({
      introText:
        "Find out about useful features and tools to complete your questionnaire faster.",
      headerText: "Tips for answering questionnaires",
      footerContent: (
        <>
          If you encounter any technical issues while completing your
          questionnaire{" "}
          <a className={"support-link"} onClick={contactSupport}>
            message us
          </a>
          .
        </>
      ),
      tips: [
        {
          id: "progress",
          icon: <i className={"cr-icon-accepted"} />,
          title: "Progress is automatically saved",
          content: (
            <>
              Your answers will be auto-saved as you go, so you can exit and
              resume editing over multiple sessions.
              {!isImportedSurvey && (
                <>
                  <br />
                  <br />
                  Draft responses will not be visible to {orgName} until you
                  select <b>Submit</b>.
                </>
              )}
            </>
          ),
          startExpanded: true,
        },
        ...(qnType == SurveyUsageType.Security
          ? [
              {
                id: "autofill",
                icon: <i className={"cr-icon-autofill"} />,
                title: "Draft responses faster using Autofill",
                content: isImportedSurvey
                  ? "Autofill your questionnaire in minutes using past answers and external sources."
                  : "Autofill your questionnaire in minutes using past answers and external sources. Or, export and fill out the questionnaire offline if preferred.",
                image: isImportedSurvey ? autofillNoExport : autofill,
              },
              {
                id: "magic",
                icon: <i className={"cr-icon-magic-wand"} />,
                title: "Finish off your responses with AI Enhance",
                content:
                  "Turn short bullet points or rough draft notes into complete responses with the click of a button. Try it out to correct grammatical mistakes, fix typos and improve responses instantly.",
                image: enhance,
              },
            ]
          : []),
        ...(!isImportedSurvey
          ? [
              {
                id: "chat",
                icon: <i className={"cr-icon-chat-messages"} />,
                title: "Communicate easily with messages",
                content:
                  "Have a specific question? Leave a message and the sender will see it contextually after you submit the questionnaire.",
                image: messages,
              },
            ]
          : []),
      ],
    });

  return [openModal, modal] as const;
};

export default useSurveyViewerTipsModal;
