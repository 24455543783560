import { SurveyRiskVisibility } from "../../../_common/types/survey";
import { OptionType, SelectV2 } from "../../../_common/components/SelectV2";
import { FC } from "react";
import InfoBanner, { BannerType, SubItemType } from "../InfoBanner";
import { LogError } from "../../../_common/helpers";

interface QuestionnaireRiskVisibilitySelectorProps {
  value: SurveyRiskVisibility;
  onChange: (newValue: SurveyRiskVisibility) => void;
  disabled?: boolean;
}

const QuestionnaireRiskVisibilitySelector: FC<
  QuestionnaireRiskVisibilitySelectorProps
> = ({ value, onChange, disabled }) => {
  const options: OptionType[] = [
    {
      value: SurveyRiskVisibility.Visible,
      label: getDisplayForQuestionnaireRiskVisibility(
        SurveyRiskVisibility.Visible
      ),
    },
    {
      value: SurveyRiskVisibility.HideSeverity,
      label: getDisplayForQuestionnaireRiskVisibility(
        SurveyRiskVisibility.HideSeverity
      ),
    },
    {
      value: SurveyRiskVisibility.Hidden,
      label: getDisplayForQuestionnaireRiskVisibility(
        SurveyRiskVisibility.Hidden
      ),
    },
  ];

  const selectedOption = options.find((o) => o.value === value) ?? options[0];

  return (
    <SelectV2
      options={options}
      value={selectedOption}
      onChange={(value) => {
        onChange(
          (value?.value as SurveyRiskVisibility) ?? SurveyRiskVisibility.Visible
        );
      }}
      isDisabled={disabled}
    ></SelectV2>
  );
};

export const getDisplayForQuestionnaireRiskVisibility = (
  value: SurveyRiskVisibility
): string => {
  switch (value) {
    case SurveyRiskVisibility.Visible:
      return "Show all risk information";
    case SurveyRiskVisibility.HideSeverity:
      return "Hide severity";
    case SurveyRiskVisibility.Hidden:
      return "Hide all risk information";
    default:
      LogError(`Invalid SurveyRiskVisibility: ${value}`);
      return "";
  }
};

export const getMessageForQuestionnaireRiskVisibilityInfoBanner = (
  value: SurveyRiskVisibility
) => {
  switch (value) {
    case SurveyRiskVisibility.HideSeverity:
      return (
        <InfoBanner
          message={"Risk severity will be hidden from recipients"}
          subItemType={SubItemType.BULLET}
          subItems={[
            "Recipients will still be able to provide compensating controls",
          ]}
        />
      );
    case SurveyRiskVisibility.Hidden:
      return (
        <InfoBanner
          type={BannerType.WARNING}
          message={"All risk information will be hidden from recipients"}
          subItemType={SubItemType.BULLET}
          subItems={[
            "Risks under remediation will be visible to recipients",
            "Recipients will not be able to provide compensating controls if a risk is not under remediation",
          ]}
        />
      );
    default:
      return null;
  }
};

export default QuestionnaireRiskVisibilitySelector;
