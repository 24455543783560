import PropTypes from "prop-types";
import "../style/components/InfoBar.scss";

export const InfoBar = ({ message }) => (
  <div id="info-bar">
    <div className="message-text">{message}</div>
  </div>
);

InfoBar.propTypes = {
  message: PropTypes.string.isRequired,
};

InfoBar.defaultProps = {};
