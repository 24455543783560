import { FC, useCallback, useEffect, useState } from "react";
import ContentLibraryAPI, {
  contentLibraryDocumentCacheTag,
} from "../api/contentLibraryAPI";
import { useContentLibraryFeatureIntroModal } from "./ContentLibraryFeatureIntroModal";
import InfoCard from "../../_common/components/InfoCard";
import FilledIcon from "../../_common/components/core/FilledIcon";
import { pluralise } from "../../_common/helpers";
import { useModalV2 } from "../../_common/components/ModalV2";
import MigrateSurveyAttachmentsModal from "./MigrateSurveyAttachmentsModal";
import { usePrevious } from "../../vendorrisk/helpers/util";
import { useAppDispatch } from "../../_common/types/reduxHooks";
import Button from "../../_common/components/core/Button";
import {
  useBasicPermissions,
  UserWriteContentLibrary,
} from "../../_common/permissions";

const ContentLibraryOnboardingTasks: FC = () => {
  const dispatch = useAppDispatch();
  const { userPermissions } = useBasicPermissions();
  const userHasWritePerm = !!userPermissions[UserWriteContentLibrary];
  const [pollingInterval, setPollingInterval] = useState<number | undefined>(
    undefined
  );
  const { data: onboardingStatusData } =
    ContentLibraryAPI.useGetContentLibraryOnboardingStatusQuery(undefined, {
      pollingInterval,
    });

  const [setOnboardingStatus] =
    ContentLibraryAPI.useSetContentLibraryOnboardingStatusMutation();
  const dismissIntroModal = useCallback(() => {
    setOnboardingStatus({
      dismissIntroModal: true,
    });
  }, [setOnboardingStatus]);
  const dismissSharedProfileDocsMessage = useCallback(() => {
    setOnboardingStatus({
      dismissSharedProfileDocs: true,
    });
  }, [setOnboardingStatus]);
  const dismissDocMigrationMessage = useCallback(() => {
    setOnboardingStatus({
      dismissDocMigration: true,
    });
  }, [setOnboardingStatus]);

  const [openFeatureIntroModal, featureIntroModal] =
    useContentLibraryFeatureIntroModal(dismissIntroModal);
  const [openMigrateSurveyAttachmentsModal, migrateSurveyAttachmentsModal] =
    useModalV2(MigrateSurveyAttachmentsModal);

  const prevDocMigrationStatus = usePrevious(
    onboardingStatusData?.docMigration.status
  );
  useEffect(() => {
    if (onboardingStatusData?.introModalCompleted === false) {
      // Open the feature intro immediately if it hasn't been completed yet
      openFeatureIntroModal();
    }
  }, [onboardingStatusData?.introModalCompleted, openFeatureIntroModal]);

  useEffect(() => {
    // Whilst a job is in progress, make sure we poll for updates
    if (onboardingStatusData?.docMigration.status === "inprogress") {
      setPollingInterval(5000);
    } else {
      setPollingInterval(undefined);
    }

    // If the document migration was previously in progress, and now it's complete, refresh the content library list
    if (
      prevDocMigrationStatus === "inprogress" &&
      onboardingStatusData?.docMigration.status === "completed"
    ) {
      dispatch(
        ContentLibraryAPI.util.invalidateTags([contentLibraryDocumentCacheTag])
      );
    }
  }, [
    prevDocMigrationStatus,
    onboardingStatusData?.docMigration.status,
    dispatch,
  ]);

  if (!onboardingStatusData) {
    return null;
  }

  return (
    <>
      {onboardingStatusData.docMigration.status === "notstarted" ? (
        <InfoCard
          icon={
            <FilledIcon iconClass="cr-icon-q-builder-document-attachment" />
          }
          heading={`${
            onboardingStatusData.docMigration.surveyAttachmentsToMigrate
              ?.length ?? 0
          } previously uploaded ${pluralise(
            onboardingStatusData.docMigration.surveyAttachmentsToMigrate
              ?.length ?? 0,
            "document",
            "documents"
          )} found`}
          description={
            <>
              Review previously uploaded files and add them to your Content
              Library.
            </>
          }
          action={
            <Button
              arrow
              onClick={() =>
                openMigrateSurveyAttachmentsModal({
                  surveyAttachmentsToMigrate:
                    onboardingStatusData?.docMigration
                      .surveyAttachmentsToMigrate ?? [],
                })
              }
            >
              Review and add
            </Button>
          }
          onDismiss={dismissDocMigrationMessage}
        />
      ) : onboardingStatusData.docMigration.status === "inprogress" ? (
        <InfoCard
          icon={
            <FilledIcon iconClass="cr-icon-q-builder-document-attachment" />
          }
          heading={`Previously uploaded documents are currently being imported`}
          description={
            <>
              They will appear in your content library as the import progresses.
            </>
          }
        />
      ) : onboardingStatusData.docMigration.status === "completed" ? (
        <InfoCard
          icon={
            <FilledIcon iconClass="cr-icon-q-builder-document-attachment" />
          }
          heading={`Previously uploaded documents were successfully imported`}
          description={
            userHasWritePerm ? (
              <>Select a document to make changes.</>
            ) : (
              <>Select a document to view.</>
            )
          }
          onDismiss={dismissDocMigrationMessage}
        />
      ) : null}

      {!onboardingStatusData.sharedProfileDocsMessage.dismissed &&
        onboardingStatusData.sharedProfileDocsMessage.numDocs > 0 && (
          <InfoCard
            icon={<FilledIcon iconClass="cr-icon-shared-assessment" />}
            heading={`${
              onboardingStatusData.sharedProfileDocsMessage.numDocs
            } ${pluralise(
              onboardingStatusData.sharedProfileDocsMessage.numDocs,
              "document",
              "documents"
            )} from your Trust Page ${pluralise(
              onboardingStatusData.sharedProfileDocsMessage.numDocs,
              "was",
              "were"
            )} automatically added`}
            description={
              userHasWritePerm ? (
                <>Select a document to make changes.</>
              ) : (
                <>Select a document to view.</>
              )
            }
            onDismiss={dismissSharedProfileDocsMessage}
          />
        )}
      {featureIntroModal}
      {migrateSurveyAttachmentsModal}
    </>
  );
};

export default ContentLibraryOnboardingTasks;
