import {
  filterNotificationConfigCategories,
  INotificationConfigCategory,
} from "../../../_common/types/notifications";
import { IOrgAlertDefinition } from "../../reducers/org.actions";
import { FC, useMemo } from "react";
import LoadingBanner from "../../../_common/components/core/LoadingBanner";
import SearchBox from "../../../_common/components/SearchBox";
import SearchEmptyCard from "../../../_common/components/SearchEmptyCard";
import XTable, { XTableCell } from "../../../_common/components/core/XTable";
import { ConditionsPill } from "../OrgNotificationConfigV2";
import ToggleWithLabel from "../ToggleWithLabel";

interface ISelectTriggersStepProps {
  loading: boolean;
  notifications: INotificationConfigCategory<IOrgAlertDefinition>[];
  selectedUUIDs: Record<string, boolean | undefined>;
  toggleSelectedUUID: (uuid: string) => void;
  setTriggerSearchText: (txt: string) => void;
  searchText: string;
}

interface categoryWithDefs {
  category: string;
  subcategory: string;
  options: IOrgAlertDefinition[];
}

const SelectTriggersStep: FC<ISelectTriggersStepProps> = ({
  loading,
  notifications,
  selectedUUIDs,
  toggleSelectedUUID,
  setTriggerSearchText,
  searchText,
}) => {
  const searchTextLower = searchText.toLowerCase();
  const flatNotifications = useMemo(() => {
    // First filter down our categories to fit the search term
    const { result } = filterNotificationConfigCategories(
      notifications,
      (n) => {
        return [
          !searchTextLower ||
            n.headlineVarsReplaced.toLowerCase().indexOf(searchTextLower) >
              -1 ||
            n.descriptionVarsReplaced.toLowerCase().indexOf(searchTextLower) >
              -1,
          "result",
        ];
      }
    );

    // Next flatten our result down to the subcategory layer
    const flat: categoryWithDefs[] = [];
    result?.forEach((cat) =>
      cat.subcategories.forEach((subcat) =>
        flat.push({
          category: cat.category,
          subcategory: subcat.subcategory,
          options: subcat.options,
        })
      )
    );

    return flat;
  }, [notifications, searchTextLower]);

  return (
    <div className="section-step">
      <div id="triggers">
        {loading || !notifications ? (
          <LoadingBanner />
        ) : (
          <>
            <div className="title-bar">
              <div className="header">Select triggers</div>
            </div>
            <div className="body">
              <div className="search-container">
                <SearchBox
                  placeholder={"Search for a trigger by name"}
                  onChanged={(q) => setTriggerSearchText(q)}
                  value={searchText}
                />
              </div>
              {flatNotifications.length > 0 ? (
                flatNotifications.map((n) => (
                  <div key={`${n.category}${n.subcategory}`} className="field">
                    <div className="field-desc">
                      {n.category}
                      <p>{n.subcategory}</p>
                    </div>
                    <div className="field-input">
                      <XTable
                        className={"triggers-table"}
                        columnHeaders={[
                          {
                            id: "trigger",
                            text: "Trigger",
                            sortable: false,
                          },
                          {
                            id: "custom-pills",
                            text: "",
                            sortable: false,
                          },
                          {
                            id: "enabled",
                            text: "Enabled",
                            sortable: false,
                          },
                        ]}
                        rows={n.options.map((opt) => ({
                          id: opt.uuid,
                          className: "trigger-row",
                          onClick: () => toggleSelectedUUID(opt.uuid),
                          cells: [
                            <XTableCell
                              key="trigger"
                              className={"trigger-cell"}
                            >
                              {opt.headlineVarsReplaced}
                              <p>{opt.descriptionVarsReplaced}</p>
                            </XTableCell>,
                            <XTableCell
                              key={"custom-pills"}
                              className={"custom-cell"}
                            >
                              {opt.conditionalLogic && (
                                <ConditionsPill
                                  conditions={opt.conditionalLogic}
                                />
                              )}
                            </XTableCell>,
                            <XTableCell key="enable" className={"enable-cell"}>
                              <ToggleWithLabel
                                name={opt.uuid}
                                selected={!!selectedUUIDs[opt.uuid]}
                                onClick={() => undefined}
                              />
                            </XTableCell>,
                          ],
                        }))}
                      />
                    </div>
                  </div>
                ))
              ) : (
                <SearchEmptyCard
                  onClear={() => setTriggerSearchText("")}
                  searchItemText="triggers"
                />
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default SelectTriggersStep;
