import { PureComponent, Component } from "react";
import PropTypes from "prop-types";
import Score from "../Score";
import { Line } from "react-chartjs-2";
import {
  CalculatePercentage,
  ConvertHexToRGBA,
} from "../../../_common/helpers";
import ColorGrade from "./ColorGrade";
import classnames from "classnames";
import moment from "moment";

import "../../style/components/executive_summary/RatingChart.scss";
import { clamp } from "../../../_common/helpers/number.helpers";

export const RatingChartStyles = ({ children }) => children;
RatingChartStyles.propTypes = {
  children: PropTypes.any.isRequired,
};

export const chartBorderColors = [
  "#1757C2",
  "#5F3285",
  "#6B6F74",
  "#14703C",
  "#A31000",
  "#B26B00",
  "#874ABA",
  "#ABADAF",
  "#20AF5D",
  "#EF1700",
  "#FF9901",
  "#0D316D",
  "#47B7FF",
];

const dayInputFormat = "YYYY-MM-DD";
const monthInputFormat = "YYYY-MM";
const dayOutputFormat = "DD MMM 'YY";
const monthOutputFormat = "MMM 'YY";

// parseStringToTime converts a string to a date based on  the time unit
// for day units:
//   the input date represent the midnight in  the user local timezone before the day score
//   e.g. an input date of 2021-05-21 represent the range 2021-05-21T00:00:00 - 2021-05-22T00:00:00
// for month units (backwards compatibility):
//   Our month strings represent the end of the month i.e. the actual time relating to scores for
//   2020-07 is 2020-08-01 00:00:00 (or the last score of the month of July, in other words)
//   If we are in the current month, we return the current time. This ensures the points on the graph are accurately spaced.
export const parseStringToTime = (str, unit, startOfMonth) => {
  let time = null;
  switch (unit) {
    case "days":
      time = moment(str, dayInputFormat);
      break;
    case "months":
      time = moment(str, monthInputFormat);
      if (!startOfMonth) {
        time = moment(str, monthInputFormat).add(1, "month");
      }
      break;
    case "weeks":
      time = moment(str, dayInputFormat).add(7, "day");
      break;
    default:
      throw `Unknown time unit ${unit}`;
  }

  if (time.isAfter(moment())) {
    return moment();
  }
  return time;
};

// formatTime converts a date into a string to display
export const formatTime = (str, unit) => {
  let time = null;
  let outputFormat = null;
  switch (unit) {
    case "days":
      time = moment(str, dayInputFormat);
      outputFormat = dayOutputFormat;
      break;
    case "months":
      time = moment(str, monthInputFormat).add(1, "month");
      outputFormat = monthOutputFormat;
      break;
    case "weeks":
      time = moment(str, dayInputFormat).add(7, "day");
      outputFormat = monthOutputFormat;
      break;
    default:
      throw `Unknown time unit ${unit}`;
  }

  if (time.isAfter(moment())) {
    return "Current";
  }

  return time.format(outputFormat);
};

// format a time for the tooltip. Time labels for a tooltip are in RFC 2822
// so we'll parse from that and convert to the desired format
const formatTooltipTime = (str, unit) => {
  const time = moment(str);
  if (time.isAfter(moment())) {
    return "Current";
  }

  switch (unit) {
    case "days":
      return time.format(dayOutputFormat);
    case "months":
      return time.format(monthOutputFormat);
    case "weeks":
      return time.format(monthOutputFormat);
    default:
      throw `Unknown time unit ${unit}`;
  }
};

const defaultChartOptions = {
  legend: { display: false },
  scales: {
    yAxes: [
      {
        display: true,
        ticks: {
          min: 0,
          max: 950,
          stepSize: 200,
          fontColor: "#6B6F74",
          fontSize: 11,
          fontFamily: 'Inter, "Inter-Fallback", Helvetica, Arial, sans-serif',
        },
        gridLines: {
          color: "#F4F5F5",
          zeroLineColor: "#F4F5F5",
        },
      },
    ],
    xAxes: [
      {
        type: "time",
        distribution: "linear",
        position: "bottom",
        display: true,
        ticks: {
          fontColor: "#6B6F74",
          fontSize: 11,
          fontFamily: 'Inter, "Inter-Fallback", Helvetica, Arial, sans-serif',
          maxRotation: 0,
          autoSkip: true,
          autoSkipPadding: 10,
        },
        gridLines: {
          color: "#F4F5F5",
          zeroLineColor: "#F4F5F5",
          drawOnChartArea: false,
        },
        time: {
          unit: "day",
          displayFormats: {
            month: "MMM 'YY",
          },
        },
      },
    ],
  },
  maintainAspectRatio: false,
  responsive: true,
  layout: {
    padding: {
      // need extra right padding to allow extra space for the x-axis labels in some cases
      right: 20,
    },
  },
};

class RatingChartTooltip extends PureComponent {
  static propTypes = {
    unit: PropTypes.oneOf(["days", "months", "weeks"]).isRequired,
    datasetLabels: PropTypes.arrayOf(PropTypes.string).isRequired,
    industryAverageData: PropTypes.arrayOf(PropTypes.number),
    noScore: PropTypes.bool,
  };

  static defaultProps = {
    industryAverageData: null,
    noScore: false,
  };

  state = {
    show: false,
    xPos: 0,
    yPos: 0,
    xValue: "",
    score: 0,
    label: "",
    labelColor: "",
    industryAverageScore: null,
  };

  onTooltipCallback = (tooltipModel) => {
    // Only show tooltips for datasets that are not the industry avg
    if (
      tooltipModel.dataPoints &&
      tooltipModel.dataPoints[0].datasetIndex >= this.props.datasetLabels.length
    ) {
      return;
    }

    // Hide if no tooltip
    if (tooltipModel.opacity === 0) {
      this.setState({ show: false });
      return;
    }

    if (!tooltipModel.dataPoints) {
      return;
    }

    const pt = tooltipModel.dataPoints[0];
    const labelColor =
      tooltipModel.labelColors && tooltipModel.labelColors.length > 0
        ? tooltipModel.labelColors[0].borderColor
        : "";

    let industryAverageScore = null;
    if (
      this.props.industryAverageData &&
      pt.index < this.props.industryAverageData.length
    ) {
      industryAverageScore = this.props.industryAverageData[pt.index];
    }

    this.setState({
      show: true,
      xPos: tooltipModel.caretX,
      yPos: tooltipModel.caretY + 20,
      // we use the xLabel here instead of the xIndex because the component
      // discards entries without a score, e.g. -1 score, for each dataset.
      // This means the index for a dataset does not match to the index of
      // the xValues
      xValue: formatTooltipTime(pt.xLabel, this.props.unit),
      score: pt.yLabel,
      label: this.props.datasetLabels[pt.datasetIndex],
      labelColor,
      industryAverageScore,
    });
  };

  render() {
    if (!this.state.show) {
      return false;
    }

    return (
      <div
        className="rating-chart-tooltip"
        style={{ left: `${this.state.xPos}px`, top: `${this.state.yPos}px` }}
      >
        <div className="x-value">{this.state.xValue}</div>
        <div
          className={classnames("label-and-score", {
            multiple: !!this.state.industryAverageScore,
          })}
        >
          <div
            className="color-circle"
            style={{ backgroundColor: this.state.labelColor }}
          />
          {this.props.noScore && (
            <div className="label">
              {this.state.label}: {this.state.score}
            </div>
          )}
          {!this.props.noScore && (
            <>
              <div className="label">{this.state.label}</div>
              <div className="score-container">
                <ColorGrade score={this.state.score} />
                <Score colored outOf={950} score={this.state.score} />
              </div>
            </>
          )}
        </div>
        {this.state.industryAverageScore ? (
          <div className="label-and-score multiple">
            <div className="industry-avg-line" />
            <div className="label">Industry Average</div>
            <div className="score-container">
              <ColorGrade score={this.state.industryAverageScore} />
              <Score
                colored
                outOf={950}
                score={this.state.industryAverageScore}
              />
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

class RatingChart extends Component {
  static propTypes = {
    xValues: PropTypes.arrayOf(PropTypes.string).isRequired,
    unit: PropTypes.oneOf(["days", "months", "weeks"]).isRequired,
    startofMonth: PropTypes.bool,
    datasets: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        color: PropTypes.string.isRequired,
        data: PropTypes.arrayOf(PropTypes.number).isRequired,
        nopoints: PropTypes.bool,
      })
    ).isRequired,
    scoringChanges: PropTypes.array, // IScoringChange[]
    industryAverageData: PropTypes.arrayOf(PropTypes.number),
    heightOverride: PropTypes.number,
    minOverride: PropTypes.number,
    maxOverride: PropTypes.number,
    slidingScale: PropTypes.bool,
    chartUnit: PropTypes.string,
  };

  static defaultProps = {
    industryAverageData: null,
    scoringChanges: [],
    heightOverride: 0,
    minOverride: null,
    maxOverride: null,
    slidingScale: false,
    chartUnit: null,
    startofMonth: false,
  };

  static minChartHeight = 180;
  static chartHeightPerRow = 45;

  tooltipCallback = (tooltipModel) => {
    if (this.ratingChartTooltip) {
      this.ratingChartTooltip.onTooltipCallback(tooltipModel);
    }
  };

  /**
   *
   * @param {moment.Moment} date
   * @returns {number|null}
   */
  getScoringChangePositionFromDate(date) {
    const { xValues, unit, startofMonth } = this.props;
    if (!xValues.length) {
      return null;
    }

    const startDate = parseStringToTime(xValues[0], unit, startofMonth);
    const endDate = parseStringToTime(
      xValues[xValues.length - 1],
      unit,
      startofMonth
    );

    const percentPosition = CalculatePercentage(
      date.unix() - startDate.unix(),
      endDate.unix() - startDate.unix()
    );
    return clamp(percentPosition, 0.0, 100.0);
  }

  calculateSlidingScale = () => {
    let min = this.props.datasets[0].data ? this.props.datasets[0].data[0] : 0;
    let max = this.props.datasets[0].data ? this.props.datasets[0].data[0] : 0;

    if (this.props.datasets[0].data) {
      for (let i = 0; i < this.props.datasets[0].data.length; i++) {
        if (this.props.datasets[0].data[i] > max) {
          max = this.props.datasets[0].data[i];
        }
        if (this.props.datasets[0].data[i] < min) {
          min = this.props.datasets[0].data[i];
        }
      }
    }

    // pad max/min and calculate y-axis step
    if (max % 20 > 0) {
      max = max + 20 - (max % 20);
    }
    if (min % 20 > 0) {
      min = min - (min % 20);
    }
    if (min < 0) {
      min = 0;
    }

    let stepSize = max - min;
    stepSize = stepSize / 4;
    if (stepSize < 1) {
      stepSize = 1;
    }
    stepSize = Math.trunc(stepSize);

    const yAxes = {
      ...defaultChartOptions.scales.yAxes[0],
    };
    yAxes.ticks = {
      ...yAxes.ticks,
      stepSize: stepSize,
      min: min,
      max: max,
    };

    const opts = {
      ...defaultChartOptions,
      scales: {
        ...defaultChartOptions.scales,
        yAxes: [yAxes],
      },
      tooltips: {
        mode: "nearest",
        intersect: false,
        enabled: false,
        custom: this.tooltipCallback,
      },
    };
    return opts;
  };

  render() {
    const { xValues, unit, datasets, industryAverageData, startofMonth } =
      this.props;
    const chartHeight = Math.max(
      this.props.heightOverride,
      RatingChart.minChartHeight
    );

    let useFillGradient = false;
    if (datasets.length < 4) {
      useFillGradient = true;
    }

    const scoringChanges = [];
    if (this.props.scoringChanges) {
      this.props.scoringChanges.forEach((sc) => {
        if (!sc.DisplayOnGraph) {
          return;
        }

        const scoringChangePosition = this.getScoringChangePositionFromDate(
          moment(sc.CreatedAt)
        );
        if (scoringChangePosition > 0) {
          scoringChanges.push({
            id: sc.ID,
            description: sc.Description,
            position: `${scoringChangePosition}%`,
          });
        }
      });
    }

    let opts = {};
    if (this.props.slidingScale) {
      opts = this.calculateSlidingScale();
    } else {
      opts = {
        ...defaultChartOptions,
        tooltips: {
          mode: "nearest",
          intersect: false,
          enabled: false,
          custom: this.tooltipCallback,
        },
      };
    }

    // if showing more than 3 months data use a month time unit for the chart
    const chartUnit = this.props.chartUnit
      ? this.props.chartUnit
      : xValues.length > 100
        ? "month"
        : "day";
    opts.scales.xAxes = [
      {
        ...opts.scales.xAxes[0],
        time: {
          unit: chartUnit,
          displayFormats: {
            month: "MMM 'YY",
          },
        },
      },
    ];

    return (
      <div className="rating-chart" style={{ height: `${chartHeight}px` }}>
        <RatingChartTooltip
          datasetLabels={datasets.map((d) => d.label)}
          unit={unit}
          industryAverageData={industryAverageData}
          ref={(ref) => (this.ratingChartTooltip = ref)}
          noScore={this.props.slidingScale}
        />
        <Line
          // height={chartHeight}
          data={(canvas) => {
            const ctx = canvas.getContext("2d");

            const chartDatasets = datasets.map((dataset) => {
              let backgroundColor = undefined;
              if (useFillGradient) {
                backgroundColor = ctx.createLinearGradient(
                  0,
                  0,
                  0,
                  chartHeight
                );
                backgroundColor.addColorStop(
                  0,
                  ConvertHexToRGBA(dataset.color, 10)
                );
                backgroundColor.addColorStop(
                  1,
                  ConvertHexToRGBA(dataset.color, 0)
                );
              }

              const data = dataset.data
                .map((d, i) => {
                  return {
                    x: parseStringToTime(
                      xValues[i],
                      unit,
                      startofMonth
                    ).toDate(),
                    y: d,
                  };
                })
                .filter((d) => d.y !== -1);

              return {
                label: dataset.label,
                data: data,
                pointRadius: 0,
                borderColor: dataset.color,
                fill: !!backgroundColor,
                backgroundColor,
                borderWidth: 2,
                pointBorderWidth: 2,
              };
            });

            if (industryAverageData) {
              chartDatasets.push({
                label: "Industry Average",
                data: industryAverageData,
                spanGaps: true,
                fill: false,
                borderDash: [2],
                borderWidth: 2,
                borderColor: "#5D89D4",
                pointRadius: 0,
                pointHoverRadius: 0,
              });
            }

            return {
              datasets: chartDatasets,
            };
          }}
          options={opts}
        />
        {scoringChanges.length > 0 && (
          <div className="scoring-changes">
            {scoringChanges.map((scoringChange) => (
              <div key={scoringChange.id}>
                <div
                  className="scoring-change-label"
                  style={{
                    left: scoringChange.position,
                  }}
                >
                  <p className="label-text">
                    {(() => {
                      switch (scoringChange.description) {
                        case "Provisional risks scored":
                          return (
                            <>
                              PROVISIONAL
                              <br />
                              RISKS SCORED
                            </>
                          );
                        case "Categories added":
                          return (
                            <>
                              CATEGORIES
                              <br />
                              ADDED
                            </>
                          );
                        default:
                          return (
                            <>
                              ALGORITHM
                              <br />
                              CHANGE
                            </>
                          );
                      }
                    })()}
                  </p>
                </div>
                <div
                  className="scoring-change-line"
                  style={{
                    left: scoringChange.position,
                  }}
                >
                  <div className="scoring-change-caret" />
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    );
  }
}

export default RatingChart;
