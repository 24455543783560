import { FC, useState } from "react";
import {
  OptionType,
  SelectV2Multi,
} from "../../../_common/components/SelectV2";
import { ValueType } from "react-select";
import { SlidePanelSection } from "./SlidePanelSection";
import LabelList from "../LabelList";

interface IVulnsCVENameFilterProps {
  selectedOptions: string[];
  availableOptions?: string[];
  loading?: boolean;
  onChange: (opts: string[]) => void;
  title?: string;
  startExpanded?: boolean;
}

const VulnsCVENameFilter: FC<IVulnsCVENameFilterProps> = ({
  selectedOptions: _selectedOptions = [],
  availableOptions = [],
  loading = false,
  onChange,
  title = "Filter by CVE ID",
  startExpanded = false,
}) => {
  const selectedOptions = loading ? [] : _selectedOptions;
  const [expanded, setExpanded] = useState(
    startExpanded || selectedOptions.length > 0
  );

  const selectOnChange = (selectedOptions: ValueType<OptionType, true>) => {
    onChange(
      selectedOptions ? selectedOptions.map(({ value }) => value as string) : []
    );
  };

  const options = availableOptions.map((cveName) => ({
    value: cveName,
    label: cveName,
  }));

  const selectValue = selectedOptions
    .map((id) => options.find(({ value }) => value === id) as OptionType)
    .filter((o) => !!o);

  const displayLabels = selectValue.map(({ value, label }) => ({
    id: value,
    name: label,
    color: "blue",
    removeable: true,
    large: true,
    constrained: true,
    onRemoveClick: () =>
      selectOnChange(selectValue.filter((option) => value !== option.value)),
  }));

  return (
    <SlidePanelSection
      title={title}
      expanded={expanded}
      toggleExpand={() => setExpanded(!expanded)}
    >
      <SelectV2Multi
        placeholder="Type to search CVE IDs"
        options={options}
        value={selectValue}
        onChange={selectOnChange}
        isLoading={loading}
        controlShouldRenderValue={false}
      />
      <LabelList labels={displayLabels} />
    </SlidePanelSection>
  );
};

export default VulnsCVENameFilter;
