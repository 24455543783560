import { FetchCyberRiskUrl } from "../../_common/api";
import {
  LogError,
  MapArrayToUnixDateWithoutTimezone,
} from "../../_common/helpers";
import * as ReportTypes from "../constants/reportTypes";
import { setReportsConfig, setReportsData } from "./cyberRiskActions";
import { getQueryFromVendorLabelFilters } from "../components/filter/types";
import { getQualifiedBreachsightSubsidiariesReportName } from "../helpers/reportTypes";

export const fetchReportsConfig = (force = false) => {
  return async (dispatch, getState) => {
    if (!force && getState().cyberRisk.reportConfig) {
      return getState().cyberRisk.reportConfig;
    }

    let reportsConfig;
    try {
      reportsConfig = await FetchCyberRiskUrl(
        "reports/config/v1/",
        {},
        null,
        dispatch,
        getState
      );
    } catch (e) {
      LogError(`Error fetching reports config for org`, e);

      return;
    }

    dispatch(setReportsConfig(reportsConfig));

    return reportsConfig;
  };
};

export const updateReportsConfig = (data) => {
  return async (dispatch, getState) => {
    try {
      await FetchCyberRiskUrl(
        "reports/config/v1/",
        {},
        {
          method: "PUT",
          body: JSON.stringify(data),
        },
        dispatch,
        getState
      );
    } catch (e) {
      LogError(`Error updating reports config for org`, e);

      throw e;
    }

    // Merge with the existing reports data
    const reportConfig = getState().cyberRisk.reportConfig || {};
    Object.keys(data).forEach(
      (reportType) => (reportConfig[reportType] = data[reportType])
    );
    dispatch(setReportsConfig(reportConfig));
  };
};

export const fetchBreachSightOverviewReport = (force = false, filters = {}) => {
  return async (dispatch, getState) => {
    if (
      !force &&
      getState().cyberRisk.reports &&
      getState().cyberRisk.reports[ReportTypes.BreachSightOverview]
    ) {
      return getState().cyberRisk.reports[ReportTypes.BreachSightOverview].data;
    }

    dispatch(setReportsData(ReportTypes.BreachSightOverview, null));
    let json;
    try {
      json = await FetchCyberRiskUrl(
        "reports/breachsight/overview/v1/",
        {
          website_label_ids: filters ? filters.websiteLabelIds : null,
          website_label_ids_match_all: filters
            ? filters.websiteLabelIdsMatchAll
            : null,
          website_label_ids_do_not_match: filters
            ? filters.websiteLabelIdsDoNotMatch
            : null,
          website_include_unlabeled: filters
            ? filters.websiteIncludeUnlabeled
            : null,
          website_portfolio_ids: filters ? filters.domainPortfolioIds : null,
          appguard_cloud_providers: filters
            ? filters.cloudConnectionProviderTypes
            : null,
          appguard_cloud_connection_uuids: filters
            ? filters.cloudConnectionUUIDs
            : null,
        },
        null,
        dispatch,
        getState
      );
    } catch (e) {
      LogError(`Error fetching breachsight overview report`, e);

      throw e;
    }

    if (json.dailyBaseVendorScores) {
      json.dailyBaseVendorScores = MapArrayToUnixDateWithoutTimezone(
        json.dailyBaseVendorScores
      );
    }
    if (json.dailyOverallScores) {
      json.dailyOverallScores = MapArrayToUnixDateWithoutTimezone(
        json.dailyOverallScores
      );
    }
    if (json.scoringChanges) {
      json.scoringChanges = MapArrayToUnixDateWithoutTimezone(
        json.scoringChanges
      );
    }

    dispatch(setReportsData(ReportTypes.BreachSightOverview, json));
    return json;
  };
};

export const fetchBreachSightRiskBreakdownReport = (
  force = false,
  filters = {}
) => {
  return async (dispatch, getState) => {
    if (
      !force &&
      getState().cyberRisk.reports &&
      getState().cyberRisk.reports[ReportTypes.BreachSightRiskBreakdown]
    ) {
      return getState().cyberRisk.reports[ReportTypes.BreachSightRiskBreakdown];
    }

    dispatch(setReportsData(ReportTypes.BreachSightRiskBreakdown, null));
    let json;
    try {
      json = await FetchCyberRiskUrl(
        "reports/breachsight/breakdown/v1/",
        {
          website_label_ids: filters ? filters.websiteLabelIds : null,
          website_portfolio_ids: filters ? filters.domainPortfolioIds : null,
          appguard_cloud_providers: filters
            ? filters.cloudConnectionProviderTypes
            : null,
          appguard_cloud_connection_uuids: filters
            ? filters.cloudConnectionUUIDs
            : null,
        },
        null,
        dispatch,
        getState
      );
    } catch (e) {
      LogError(`Error fetching breachsight breakdown report`, e);

      throw e;
    }

    for (let category in json.categorySummaries) {
      if (json.categorySummaries[category].dailyScores) {
        json.categorySummaries[category].dailyScores =
          MapArrayToUnixDateWithoutTimezone(
            json.categorySummaries[category].dailyScores
          );
        json.categorySummaries[category].scoringChanges =
          MapArrayToUnixDateWithoutTimezone(
            json.categorySummaries[category].scoringChanges
          );
      }
    }

    dispatch(setReportsData(ReportTypes.BreachSightRiskBreakdown, json));

    return json;
  };
};

export const fetchBreachSightCompetitorAnalysisReport = (
  force = false,
  filters = {}
) => {
  return async (dispatch, getState) => {
    const reportType = ReportTypes.BreachSightCompetitorAnalysis;

    if (
      !force &&
      getState().cyberRisk.reports &&
      getState().cyberRisk.reports[reportType]
    ) {
      return getState().cyberRisk.reports[reportType];
    }

    dispatch(setReportsData(reportType, null));
    let json;
    try {
      json = await FetchCyberRiskUrl(
        "reports/breachsight/comparison/v1/",
        {
          website_label_ids: filters ? filters.websiteLabelIds : null,
          website_portfolio_ids: filters ? filters.domainPortfolioIds : null,
          appguard_cloud_providers: filters
            ? filters.cloudConnectionProviderTypes
            : null,
          appguard_cloud_connection_uuids: filters
            ? filters.cloudConnectionUUIDs
            : null,
        },
        null,
        dispatch,
        getState
      );
    } catch (e) {
      LogError(`Error fetching breachsight competitor analysis report`, e);

      throw e;
    }

    if (json.scoringChanges) {
      json.scoringChanges = MapArrayToUnixDateWithoutTimezone(
        json.scoringChanges
      );
    }

    dispatch(setReportsData(reportType, json));
    return json;
  };
};

export const fetchBreachSightSubsidiariesOrganisationAnalysisReport = (
  force = false,
  filters = {},
  context = "subsidiary" // subsidiary || subsidiaries_only
) => {
  return async (dispatch, getState) => {
    const reportType = getQualifiedBreachsightSubsidiariesReportName(
      ReportTypes.BreachSightSubsidiariesAnalysis,
      context === "subsidiaries_only"
    );

    if (
      !force &&
      getState().cyberRisk.reports &&
      getState().cyberRisk.reports[reportType]
    ) {
      return getState().cyberRisk.reports[reportType];
    }

    dispatch(setReportsData(reportType, null));
    let json;
    try {
      json = await FetchCyberRiskUrl(
        "reports/breachsight/subsidiaries/analysis/v1/",
        {
          subsidiary_ids: filters ? filters.subsidiaryIds : null,
          subsidiary_label_ids: filters ? filters.subsidiaryLabelIds : null,
          subsidiary_min_score: filters ? filters.subsidiaryMinScore : null,
          subsidiary_max_score: filters ? filters.subsidiaryMaxScore : null,
          subsidiaries_only: context === "subsidiaries_only",
          context: context,
        },
        null,
        dispatch,
        getState
      );
    } catch (e) {
      LogError(`Error fetching breachsight competitor analysis report`, e);

      throw e;
    }

    if (json.scoringChanges) {
      json.scoringChanges = MapArrayToUnixDateWithoutTimezone(
        json.scoringChanges
      );
    }

    dispatch(setReportsData(reportType, json));
    return json;
  };
};

export const fetchBreachSightSubsidiariesCompetitorReport = (
  force = false,
  filters = {},
  context = "subsidiary"
) => {
  return async (dispatch, getState) => {
    const reportType = getQualifiedBreachsightSubsidiariesReportName(
      ReportTypes.BreachSightSubsidiariesCompetitors,
      context === "subsidiaries_only"
    );

    if (
      !force &&
      getState().cyberRisk.reports &&
      getState().cyberRisk.reports[reportType]
    ) {
      return getState().cyberRisk.reports[reportType];
    }

    dispatch(setReportsData(reportType, null));
    let json;
    try {
      json = await FetchCyberRiskUrl(
        "reports/breachsight/subsidiaries/competitors/v1/",
        {
          subsidiaries_only: context === "subsidiaries_only",
          subsidiary_ids: filters ? filters.subsidiaryIds : null,
          subsidiary_label_ids: filters ? filters.subsidiaryLabelIds : null,
          subsidiary_min_score: filters ? filters.subsidiaryMinScore : null,
          subsidiary_max_score: filters ? filters.subsidiaryMaxScore : null,
          context: context,
        },
        null,
        dispatch,
        getState
      );
    } catch (e) {
      LogError(`Error fetching breachsight subsidiaries competitors report`, e);

      throw e;
    }

    if (json.scoringChanges) {
      json.scoringChanges = MapArrayToUnixDateWithoutTimezone(
        json.scoringChanges
      );
    }

    dispatch(setReportsData(reportType, json));
    return json;
  };
};

export const fetchBreachSightSubsidiariesScoreDistributionReport = (
  force = false,
  filters = {},
  isSubsidiariesOnly = false
) => {
  return async (dispatch, getState) => {
    const reportType = getQualifiedBreachsightSubsidiariesReportName(
      ReportTypes.BreachSightSubsidiariesScoreDistribution,
      isSubsidiariesOnly
    );

    if (
      !force &&
      getState().cyberRisk.reports &&
      getState().cyberRisk.reports[reportType]
    ) {
      return getState().cyberRisk.reports[reportType];
    }

    dispatch(setReportsData(reportType, null));
    let json;
    try {
      json = await FetchCyberRiskUrl(
        "reports/breachsight/subsidiaries/distribution/v1/",
        {
          subsidiary_ids: filters.subsidiaryIds,
          subsidiary_label_ids: filters.subsidiaryLabelIds,
          min_score: filters.subsidiaryMinScore,
          max_score: filters.subsidiaryMaxScore,
          subsidiaries_only: isSubsidiariesOnly,
        },
        null,
        dispatch,
        getState
      );
    } catch (e) {
      LogError(`Error fetching breachsight subsidiary overview report`, e);

      throw e;
    }

    if (json.scoringChanges) {
      json.scoringChanges = MapArrayToUnixDateWithoutTimezone(
        json.scoringChanges
      );
    }

    dispatch(setReportsData(reportType, json));

    return json;
  };
};

export const fetchBreachSightSubsidiaryScoreRatingsReport = (
  force = false,
  filters = {},
  isSubsidiariesOnly = false
) => {
  return async (dispatch, getState) => {
    const reportType = getQualifiedBreachsightSubsidiariesReportName(
      ReportTypes.BreachSightSubsidiariesRatings,
      isSubsidiariesOnly
    );

    if (
      !force &&
      getState().cyberRisk.reports &&
      getState().cyberRisk.reports[reportType]
    ) {
      return getState().cyberRisk.reports[reportType];
    }

    dispatch(setReportsData(reportType, null));
    let json;
    try {
      json = await FetchCyberRiskUrl(
        "reports/breachsight/subsidiaries/ratings/v1/",
        {
          subsidiary_ids: filters.subsidiaryIds,
          subsidiary_label_ids: filters.subsidiaryLabelIds,
          min_score: filters.subsidiaryMinScore,
          max_score: filters.subsidiaryMaxScore,
          subsidiaries_only: isSubsidiariesOnly,
        },
        null,
        dispatch,
        getState
      );
    } catch (e) {
      LogError(`Error fetching breachsight subsidiary breakdown report`, e);

      throw e;
    }

    dispatch(setReportsData(reportType, json));

    return json;
  };
};

export const fetchBreachSightSubsidiariesOverviewReport = (
  force = false,
  filters = {},
  isSubsidiariesOnly = false
) => {
  return async (dispatch, getState) => {
    const reportType = getQualifiedBreachsightSubsidiariesReportName(
      ReportTypes.BreachSightSubsidiariesOverview,
      isSubsidiariesOnly
    );

    if (
      !force &&
      getState().cyberRisk.reports &&
      getState().cyberRisk.reports[reportType]
    ) {
      return getState().cyberRisk.reports[reportType].data;
    }

    dispatch(setReportsData(reportType, null));
    let json;
    try {
      json = await FetchCyberRiskUrl(
        "reports/breachsight/subsidiaries/overview/v1/",
        {
          subsidiary_ids: filters.subsidiaryIds,
          subsidiary_label_ids: filters.subsidiaryLabelIds,
          min_score: filters.subsidiaryMinScore,
          max_score: filters.subsidiaryMaxScore,
          subsidiaries_only: isSubsidiariesOnly,
        },
        null,
        dispatch,
        getState
      );
    } catch (e) {
      LogError(`Error fetching breachsight subsidiaries overview report`, e);

      throw e;
    }

    if (json.dailyBaseVendorScores) {
      json.dailyBaseVendorScores = MapArrayToUnixDateWithoutTimezone(
        json.dailyBaseVendorScores
      );
    }
    if (json.dailyOverallScores) {
      json.dailyOverallScores = MapArrayToUnixDateWithoutTimezone(
        json.dailyOverallScores
      );
    }
    if (json.scoringChanges) {
      json.scoringChanges = MapArrayToUnixDateWithoutTimezone(
        json.scoringChanges
      );
    }

    dispatch(setReportsData(reportType, json));
    return json;
  };
};

export const fetchBreachSightSubsidiariesRiskBreakdownReport = (
  force = false,
  filters = {},
  isSubsidiariesOnly = false
) => {
  return async (dispatch, getState) => {
    const reportType = getQualifiedBreachsightSubsidiariesReportName(
      ReportTypes.BreachSightSubsidiariesRiskBreakdown,
      isSubsidiariesOnly
    );

    if (
      !force &&
      getState().cyberRisk.reports &&
      getState().cyberRisk.reports[reportType]
    ) {
      return getState().cyberRisk.reports[reportType];
    }

    dispatch(setReportsData(reportType, null));
    let json;
    try {
      json = await FetchCyberRiskUrl(
        "reports/breachsight/subsidiaries/breakdown/v1/",
        {
          subsidiary_ids: filters.subsidiaryIds,
          subsidiary_label_ids: filters.subsidiaryLabelIds,
          min_score: filters.subsidiaryMinScore,
          max_score: filters.subsidiaryMaxScore,
          subsidiaries_only: isSubsidiariesOnly,
        },
        null,
        dispatch,
        getState
      );
    } catch (e) {
      LogError(`Error fetching breachsight breakdown report`, e);

      throw e;
    }

    for (let category in json.categorySummaries) {
      if (json.categorySummaries[category].dailyScores) {
        json.categorySummaries[category].dailyScores =
          MapArrayToUnixDateWithoutTimezone(
            json.categorySummaries[category].dailyScores
          );
        json.categorySummaries[category].scoringChanges =
          MapArrayToUnixDateWithoutTimezone(
            json.categorySummaries[category].scoringChanges
          );
      }
    }

    dispatch(setReportsData(reportType, json));

    return json;
  };
};

export const addBreachSightCompetitorAnalysisVendorId = (
  vendorId,
  context = "vendor"
) => {
  return async (dispatch, getState) => {
    try {
      await FetchCyberRiskUrl(
        "reports/breachsight/comparison/v1/",
        { datastore_vendor_id: vendorId, context: context },
        {
          method: "PUT",
        },
        dispatch,
        getState
      );
    } catch (e) {
      LogError(`Error adding breachsight competitor analysis vendorID`, e);

      throw e;
    }
  };
};

export const removeBreachSightCompetitorAnalysisVendorId = (
  vendorId,
  context = "vendor"
) => {
  return async (dispatch, getState) => {
    try {
      await FetchCyberRiskUrl(
        "reports/breachsight/comparison/v1/",
        { datastore_vendor_id: vendorId, context: context },
        {
          method: "DELETE",
        },
        dispatch,
        getState
      );
    } catch (e) {
      LogError(`Error removing breachsight competitor analysis vendorID`, e);

      throw e;
    }
  };
};

export const runVendorSearchForAddCompetitorModal = (
  searchTerm,
  subsidiariesOnly = false
) => {
  return async (dispatch, getState) => {
    let results;

    try {
      results = await FetchCyberRiskUrl(
        "vendorsearch/v1/",
        {
          srch: searchTerm,
          limit: subsidiariesOnly ? 0 : 30,
          subsidiaries: subsidiariesOnly,
        },
        null,
        dispatch,
        getState
      );
    } catch (e) {
      LogError(`Error running vendor search`, e);

      throw e;
    }

    return results.vendors || [];
  };
};

export const fetchVendorRiskOverviewReport = (force = false, filters = {}) => {
  return async (dispatch, getState) => {
    if (
      !force &&
      getState().cyberRisk.reports &&
      getState().cyberRisk.reports[ReportTypes.VendorRiskOverview]
    ) {
      return getState().cyberRisk.reports[ReportTypes.VendorRiskOverview];
    }

    dispatch(setReportsData(ReportTypes.VendorRiskOverview, null));
    let json;
    try {
      json = await FetchCyberRiskUrl(
        "reports/vendorrisk/overview/v1/",
        {
          ...getQueryFromVendorLabelFilters(filters),
        },
        null,
        dispatch,
        getState
      );
    } catch (e) {
      LogError(`Error fetching vendorrisk overview report`, e);

      throw e;
    }

    if (json.scoringChanges) {
      json.scoringChanges = MapArrayToUnixDateWithoutTimezone(
        json.scoringChanges
      );
    }

    dispatch(setReportsData(ReportTypes.VendorRiskOverview, json));

    return json;
  };
};

export const fetchVendorRiskBreakdownReport = (force = false, filters = {}) => {
  return async (dispatch, getState) => {
    if (
      !force &&
      getState().cyberRisk.reports &&
      getState().cyberRisk.reports[ReportTypes.VendorRiskRatingsBreakdown]
    ) {
      return getState().cyberRisk.reports[
        ReportTypes.VendorRiskRatingsBreakdown
      ];
    }

    dispatch(setReportsData(ReportTypes.VendorRiskRatingsBreakdown, null));
    let json;
    try {
      json = await FetchCyberRiskUrl(
        "reports/vendorrisk/breakdown/v1/",
        {
          ...getQueryFromVendorLabelFilters(filters),
        },
        null,
        dispatch,
        getState
      );
    } catch (e) {
      LogError(`Error fetching vendorrisk breakdown report`, e);

      throw e;
    }

    dispatch(setReportsData(ReportTypes.VendorRiskRatingsBreakdown, json));

    return json;
  };
};

export const fetchVendorRiskSupplyChainReport = (
  force = false,
  filters = {}
) => {
  return async (dispatch, getState) => {
    if (
      !force &&
      getState().cyberRisk.reports &&
      getState().cyberRisk.reports[ReportTypes.VendorRiskSupplyChain]
    ) {
      return getState().cyberRisk.reports[ReportTypes.VendorRiskSupplyChain];
    }

    dispatch(setReportsData(ReportTypes.VendorRiskSupplyChain, null));
    let json;
    try {
      json = await FetchCyberRiskUrl(
        "reports/vendorrisk/supplychainrisk/v1/",
        {
          group_by: "product",
          ...getQueryFromVendorLabelFilters(filters),
        }, // Our component renders assuming a product grouping for now.
        null,
        dispatch,
        getState
      );
    } catch (e) {
      LogError(`Error fetching vendorrisk supply chain report`, e);

      throw e;
    }

    dispatch(setReportsData(ReportTypes.VendorRiskSupplyChain, json));
    return json;
  };
};

export const fetchVendorRiskRiskMatrixReport =
  (force = false, filters = {}) =>
  async (dispatch, getState) => {
    if (
      !force &&
      getState().cyberRisk.reports &&
      getState().cyberRisk.reports[ReportTypes.VendorRiskRiskMatrix]
    ) {
      return getState().cyberRisk.reports[ReportTypes.VendorRiskRiskMatrix];
    }

    dispatch(setReportsData(ReportTypes.VendorRiskRiskMatrix, null));
    let json;
    try {
      json = await FetchCyberRiskUrl(
        "reports/vendorrisk/riskmatrix/v1/",
        {
          ...getQueryFromVendorLabelFilters(filters),
        },
        null,
        dispatch,
        getState
      );
    } catch (e) {
      LogError(`Error fetching vendorrisk risk matrix report`, e);

      throw e;
    }

    dispatch(setReportsData(ReportTypes.VendorRiskRiskMatrix, json));
    return json;
  };

// if the vendor risk matrix is loaded this force refreshes it
export const refreshVendorRiskMatrix = () => async (dispatch, getState) => {
  if (
    getState().cyberRisk.reports &&
    getState().cyberRisk.reports[ReportTypes.VendorRiskRiskMatrix]
  ) {
    const filters = getState().cyberRisk.customerData.filters;
    dispatch(fetchVendorRiskRiskMatrixReport(true, filters));
  }
};
