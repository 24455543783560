import { IUserMini } from "./user";

export interface surveySection {
  id: number;
  name: string;
  displayName: string;
  comment: string;
  structure: string;
  requiredAttachments: string;
  framework: string;
  updatedAt?: string;
}

export interface surveyType {
  id: number;
  usageType: SurveyUsageType;
  organisationId?: number;
  name: string;
  overrideName?: string;
  description: string;
  enabled: boolean;
  sections: surveySection[];
  numberingEnabled: boolean;
  categoriseRisksInSummary: boolean;
  selected: boolean;
  createdAt: string;
  updatedAt: string;
  lastUpdatedBy?: number;
  lastUpdatedByUser?: IUserMini;
  hasStructure: boolean;
  hasDraftSurvey: boolean;
  isTPRMWorkflowQuestionnaire: boolean;
}

export enum SurveyUsageType {
  Security = "security",
  Relationship = "relationship",
}

export const SurveyUsageTypeDisplayName = {
  [SurveyUsageType.Security]: "Security Questionnaires",
  [SurveyUsageType.Relationship]: "Relationship Questionnaires",
};

export const SurveyUsageTypeDisplayNameSingular = {
  [SurveyUsageType.Security]: "Security Questionnaire",
  [SurveyUsageType.Relationship]: "Relationship Questionnaire",
};

export const SurveyUsageTypeBreadcrumb = {
  [SurveyUsageType.Security]: "Security Questionnaire",
  [SurveyUsageType.Relationship]: "Relationship Questionnaire",
};
