import { Component } from "react";
import PropTypes from "prop-types";

import Button from "../../../_common/components/core/Button";
import {
  closeModal,
  updatePrefilledSurveyDetails,
} from "../../../_common/reducers/commonActions";
import { fetchOwnSharedAssessment } from "../../reducers/cyberRiskActions";
import TextField from "../../../_common/components/TextField";
import { addDefaultUnknownErrorAlert } from "../../../_common/reducers/messageAlerts.actions";

export const EditPublicSurveyDetailsModalName = "EditPublicSurveyDetailsModal";

class EditPublicSurveyDetailsModal extends Component {
  static propTypes = {
    modalData: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      name: props.modalData.name || "",
      description: props.modalData.description || "",
      isDescriptionValid: true,
    };
  }

  onSubmit = (evt) => {
    evt.preventDefault();

    this.setState({ loading: true });

    this.props
      .dispatch(
        updatePrefilledSurveyDetails(
          this.props.modalData.surveyId,
          this.state.name,
          this.state.description
        )
      )
      .then(() => this.props.dispatch(fetchOwnSharedAssessment(true)))
      .then(() => this.props.dispatch(closeModal()))
      .catch((e) => {
        this.setState({ loading: false });
        this.props.dispatch(
          addDefaultUnknownErrorAlert("Error updating questionnaire details")
        );
      });
  };

  render() {
    return (
      <form onSubmit={this.onSubmit}>
        <div className="modal-header">
          <h2>Edit questionnaire details</h2>
        </div>
        <div className="modal-content" style={{ width: 600 }}>
          <label htmlFor="name">Questionnaire name</label>
          <TextField
            name="name"
            required
            maxLength={300}
            value={this.state.name}
            onChanged={(v) => this.setState({ name: v })}
            placeholder="Questionnaire name"
          />
          <label htmlFor="description">
            Questionnaire description (optional)
          </label>
          <TextField
            name="description"
            multiLine
            allowTextOverflow
            maxLength={4000}
            value={this.state.description}
            onChanged={(v, isValid) =>
              this.setState({ description: v, isDescriptionValid: isValid })
            }
            placeholder="Questionnaire description"
          />
        </div>
        <div className="modal-footer">
          <Button onClick={() => this.props.dispatch(closeModal())} tertiary>
            Cancel
          </Button>
          <Button
            type="submit"
            loading={this.state.loading}
            disabled={
              this.state.loading ||
              !this.state.name ||
              !this.state.isDescriptionValid
            }
          >
            Save
          </Button>
        </div>
      </form>
    );
  }
}

export default EditPublicSurveyDetailsModal;
