import {
  ConfiguredExportConfig,
  CustomCannedExportConfig,
  DefaultCannedExportConfig,
  StarredExportType,
} from "../../_common/types/exportConfig";
import { DefaultThunkDispatch } from "../../_common/types/redux";
import { DefaultRootState } from "react-redux";
import { FetchCyberRiskUrl } from "../../_common/api";

export const SET_CANNED_EXPORT_CONFIGS = "SET_CANNED_EXPORT_CONFIGS";

const setCannedExportConfigs = (
  defaultCannedConfigs: DefaultCannedExportConfig[],
  customCannedConfigs: CustomCannedExportConfig[]
) => ({
  type: SET_CANNED_EXPORT_CONFIGS,
  defaultCannedConfigs,
  customCannedConfigs,
});

export const SET_STARRED_EXPORT_TYPES = "SET_STARRED_EXPORT_TYPES";

const setStarredExportTypesAction = (
  starredExportTypes: StarredExportType[]
) => ({
  type: SET_STARRED_EXPORT_TYPES,
  starredExportTypes,
});

export const SET_STARRED_EXPORT_TYPE = "SET_STARRED_EXPORT_TYPE";

const setStarredExportTypeAction = (
  starredExportType: StarredExportType,
  starred: boolean
) => ({
  type: SET_STARRED_EXPORT_TYPE,
  starredExportType,
  starred,
});

export const fetchCannedExportConfigs =
  (force = false) =>
  async (dispatch: DefaultThunkDispatch, getState: () => DefaultRootState) => {
    if (getState().cyberRisk.cannedExportConfigs && !force) {
      return;
    }

    const resp = await FetchCyberRiskUrl<{
      defaultCannedExportConfigs: DefaultCannedExportConfig[];
      customCannedExportConfigs: CustomCannedExportConfig[];
      starredExportTypes: StarredExportType[];
    }>("cannedexports/v1", {}, { method: "GET" }, dispatch, getState);

    dispatch(
      setCannedExportConfigs(
        resp.defaultCannedExportConfigs,
        resp.customCannedExportConfigs
      )
    );

    dispatch(setStarredExportTypesAction(resp.starredExportTypes));
  };

export interface createCannedExportConfigRequest {
  baseDefaultID?: string;
  baseUUID?: string;
  name: string;
  description: string;
  config: ConfiguredExportConfig;
}

export const createCannedExportConfig =
  (req: createCannedExportConfigRequest) =>
  async (dispatch: DefaultThunkDispatch, getState: () => DefaultRootState) => {
    await FetchCyberRiskUrl(
      "cannedexports/v1",
      {},
      {
        method: "POST",
        body: JSON.stringify(req),
      },
      dispatch,
      getState
    );
  };

export interface updateCannedExportConfigRequest {
  uuid: string;
  name: string;
  description: string;
  config: ConfiguredExportConfig;
}

export const updateCannedExportConfig =
  (req: updateCannedExportConfigRequest) =>
  async (dispatch: DefaultThunkDispatch, getState: () => DefaultRootState) => {
    await FetchCyberRiskUrl(
      "cannedexports/v1",
      {},
      {
        method: "PUT",
        body: JSON.stringify(req),
      },
      dispatch,
      getState
    );
  };

export const deleteCannedExportConfig =
  (uuid: string) =>
  async (dispatch: DefaultThunkDispatch, getState: () => DefaultRootState) => {
    await FetchCyberRiskUrl(
      "cannedexports/v1",
      {
        uuid,
      },
      {
        method: "DELETE",
      },
      dispatch,
      getState
    );
  };

export const setStarredExportType =
  (starredExportType: StarredExportType, starred: boolean) =>
  async (dispatch: DefaultThunkDispatch, getState: () => DefaultRootState) => {
    // Optimistically set the change in redux
    dispatch(setStarredExportTypeAction(starredExportType, starred));

    try {
      await FetchCyberRiskUrl(
        "cannedexports/starred/v1",
        {
          starred,
          custom_canned_config_uuid: starredExportType.customCannedExportUUID,
          default_canned_config_id: starredExportType.defaultCannedConfigID,
          other_report_id: starredExportType.otherReportID,
        },
        {
          method: "PUT",
        },
        dispatch,
        getState
      );
    } catch (e) {
      // Set it back the way it was before rethrowing
      dispatch(setStarredExportTypeAction(starredExportType, !starred));

      throw e;
    }
  };
