import { DefaultThunkDispatch } from "../types/redux";
import { DefaultRootState } from "react-redux";
import { FetchCyberRiskUrl } from "../api";
import { LogError } from "../helpers";
import { Dispatch } from "redux";
import { ISearchVendorsResp, IVendorSearchVendor } from "../types/vendor";
import {
  DateRangeSelection,
  DateRangeSelectorType,
} from "../components/DateRangeSelector";
import { LabelColor } from "../types/label";
import { SortDirection } from "../components/core/XTable";
import { Severity } from "../types/severity";

export enum BreachNewsFeedKnownTags {
  // News
  Advisory = "Advisory",
  MergersAndAcquisitions = "Mergers and Acquisitions",

  // Incidents
  DarkWeb = "Dark Web",
  Ransomware = "Ransomware",
  Breach = "Breach",
  DataLeak = "Data Leak",
  Phishing = "Phishing",
  NaturalDisaster = "Natural Disaster",
}

export const BreachNewsFeedTagColourMap = new Map<
  BreachNewsFeedKnownTags,
  LabelColor
>([
  // News
  [BreachNewsFeedKnownTags.Advisory, LabelColor.Green],
  [BreachNewsFeedKnownTags.MergersAndAcquisitions, LabelColor.Grey],

  // Incidents
  [BreachNewsFeedKnownTags.DarkWeb, LabelColor.Violet],
  [BreachNewsFeedKnownTags.Ransomware, LabelColor.Grey],
  [BreachNewsFeedKnownTags.Breach, LabelColor.Grey],
  [BreachNewsFeedKnownTags.DataLeak, LabelColor.Grey],
  [BreachNewsFeedKnownTags.Phishing, LabelColor.Grey],
  [BreachNewsFeedKnownTags.NaturalDisaster, LabelColor.Grey],
]);

export enum BreachNewsFeedArticleType {
  News = "news",
  Incident = "incident",

  Advisory = "advisory", // Derived
  DarkWeb = "darkweb", // Derived
}

export interface BreachNewsFeedArticle {
  id: number;
  datastoreVendorId?: number;
  vendorName?: string;
  articleDate?: string;
  createdAt: string;
  updatedAt?: string;
  publishDate: string;
  articleType: BreachNewsFeedArticleType;
  severity: Severity;
  title: string;
  text: string;
  sources: BreachNewsArticleSource[];
  cost?: number;
  numRecords?: number;
  tagBreakdown: BreachNewsFeedArticleTagBreakdown;
  affectedVendors: BreachNewsArticleAffectedVendor[];
  threatActors: BreachNewsArticleThreatActor[];
  isWatched: boolean;
}

export interface BreachNewsFeedArticleTagBreakdown {
  dataTypes: string[];
  other: string[];
}

export interface BreachNewsArticleAffectedVendor {
  datastoreVendorId: number;
  vendorName: string;
  isWatched: boolean;
}

export interface BreachNewsArticleThreatActor {
  threatActor?: string;
  location?: string;
}

export interface BreachNewsArticleSource {
  url: string;
  title: string;
}

export enum BreachNewsFeedFilterTabType {
  All = "all",
  Incidents = "incidents",
  News = "news",
  Vendors = "vendors",
}

export interface BreachNewsFeedFilter {
  currentTab: BreachNewsFeedFilterTabType;
  vendors?: { label: string; value: string }[];
  dateSelection?: DateRangeSelection;
  threatActors?: string[];
  threatActorLocations?: string[];
  dataTypes?: string[];
  tags?: string[];
}

export const defaultFilter: BreachNewsFeedFilter = {
  currentTab: BreachNewsFeedFilterTabType.All,
  dateSelection: {
    type: DateRangeSelectorType.year,
  },
};

export const SET_BREACH_NEWS_ARTICLES = "SET_BREACH_NEWS_ARTICLES";
export const setBreachNewsFeedArticles = (
  articles?: BreachNewsFeedArticle[]
) => {
  return {
    type: SET_BREACH_NEWS_ARTICLES,
    articles,
  };
};

export const SET_BREACH_NEWS_FILTER = "SET_BREACH_NEWS_FILTER";
export const setBreachNewsFeedFilter = (
  filter: BreachNewsFeedFilter | null
) => {
  return {
    type: SET_BREACH_NEWS_FILTER,
    filter: filter || defaultFilter,
  };
};

export const defaultSortDir = SortDirection.DESC;
export const SET_BREACH_NEWS_SORT_DIR = "SET_BREACH_NEWS_SORT_DIR";
export const setBreachNewsFeedSortDir = (sortDir: SortDirection | null) => {
  return {
    type: SET_BREACH_NEWS_SORT_DIR,
    sortDir: sortDir || defaultSortDir,
  };
};

export const defaultSortCol = "date";
export const SET_BREACH_NEWS_SORT_COL = "SET_BREACH_NEWS_SORT_COL";
export const setBreachNewsFeedSortCol = (sortCol: string | null) => {
  return {
    type: SET_BREACH_NEWS_SORT_COL,
    sortCol: sortCol || defaultSortCol,
  };
};

export const SET_BREACH_NEWS_AVAILABLE_FILTERS =
  "SET_BREACH_NEWS_AVAILABLE_FILTERS";
export const setBreachNewsFeedAvailableFilters = (
  filters: BreachNewsFeedAvailableFilters
) => {
  return {
    type: SET_BREACH_NEWS_AVAILABLE_FILTERS,
    filters,
  };
};

export const SET_BREACH_NEWS_PAGE = "SET_BREACH_NEWS_PAGE";
export const setBreachNewsFeedPage = (page: number) => {
  return {
    type: SET_BREACH_NEWS_PAGE,
    page,
  };
};

export const getBreachNewsFeedArticles = (refresh = false) => {
  let resp: BreachNewsFeedArticle[] | undefined;

  return async (
    dispatch: DefaultThunkDispatch,
    getState: () => DefaultRootState
  ) => {
    if (refresh) {
      // Clear current articles
      dispatch(setBreachNewsFeedArticles(undefined));
    } else if (getState().common.breachNewsFeed.articles) {
      return getState().common.breachNewsFeed.articles;
    }

    // Get current filter from redux store (or use the default filter)
    const filter = getState().common.breachNewsFeed.filter || defaultFilter;

    const paramOptions: any = {};
    if (filter.vendors && filter.vendors.length > 0) {
      paramOptions.vendor_ids = filter.vendors.map((v) => v.value);
    }
    if (filter.dateSelection) {
      if (filter.dateSelection.type !== DateRangeSelectorType.custom) {
        paramOptions.num_days = filter.dateSelection.type;
      } else {
        if (filter.dateSelection.startCustomDate) {
          paramOptions.start_date =
            filter.dateSelection.startCustomDate.toISOString(true);
        }
        if (filter.dateSelection.endCustomDate) {
          paramOptions.end_date = filter.dateSelection.endCustomDate
            .endOf("day")
            .toISOString(true);
        }
      }
    }
    if (filter.threatActors && filter.threatActors.length > 0) {
      paramOptions.threat_actors = filter.threatActors;
    }
    if (filter.threatActorLocations && filter.threatActorLocations.length > 0) {
      paramOptions.threat_actor_locations = filter.threatActorLocations;
    }
    if (filter.dataTypes && filter.dataTypes.length > 0) {
      paramOptions.data_types = filter.dataTypes;
    }
    if (filter.tags && filter.tags.length > 0) {
      paramOptions.tags = filter.tags;
    }

    try {
      resp = await FetchCyberRiskUrl<BreachNewsFeedArticle[]>(
        "breachnewsfeed/v1",
        paramOptions,
        {
          method: "GET",
        },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("error retrieving breach news feed article", e);

      throw e;
    }

    dispatch(setBreachNewsFeedArticles(resp ?? []));

    return resp;
  };
};

export const getBreachNewsFeedArticle = (id: number) => {
  let resp: BreachNewsFeedArticle[] | undefined;

  return async (
    dispatch: DefaultThunkDispatch,
    getState: () => DefaultRootState
  ) => {
    try {
      resp = await FetchCyberRiskUrl<BreachNewsFeedArticle[]>(
        "breachnewsfeed/v1",
        {
          article_id: id,
        },
        {
          method: "GET",
        },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("error retrieving breach news feed article", e);

      throw e;
    }

    return resp[0];
  };
};

export interface BreachNewsFeedAvailableFilters {
  tagBreakdown: BreachNewsFeedArticleTagBreakdown;
  threatActors: string[];
  threatActorLocations: string[];
}

export const getBreachNewsFeedAvailableFilters = () => {
  let resp: BreachNewsFeedAvailableFilters | undefined;

  return async (
    dispatch: DefaultThunkDispatch,
    getState: () => DefaultRootState
  ) => {
    // check if we have already loaded filters
    if (getState().common.breachNewsFeed.availableFilters) {
      return getState().common.breachNewsFeed.availableFilters;
    }

    try {
      resp = await FetchCyberRiskUrl<BreachNewsFeedAvailableFilters>(
        "breachnewsfeed/filters/v1",
        {},
        {
          method: "GET",
        },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("error retrieving breach news feed available filters", e);

      throw e;
    }

    dispatch(setBreachNewsFeedAvailableFilters(resp));

    return resp;
  };
};

export const searchVendors = (searchTerm: string) => {
  return async (
    dispatch: Dispatch,
    getState: any
  ): Promise<IVendorSearchVendor[]> => {
    let resp: ISearchVendorsResp;
    try {
      resp = await FetchCyberRiskUrl<ISearchVendorsResp>(
        "vendorsearch/v1/",
        {
          srch: searchTerm,
          limit: 10,
          ignore_org_specific: true,
          consider_empty_cloudscan_vendors: true,
        },
        null,
        dispatch,
        getState,
        undefined,
        undefined
      );
    } catch (e) {
      console.error(e);
      throw e;
    }

    return resp.vendors;
  };
};
