import ReportCard from "../../../_common/components/ReportCard";
import TextField from "../../../_common/components/TextField";
import RichTextEditV2 from "../../../_common/components/RichTextEditV2";
import "./SettingsStep.scss";
import UserRiskRecipients from "./UserRiskRecipients";
import { pluralise } from "../../../_common/helpers";

interface SettingsStepOwnProps {
  emailMessage: string;
  setEmailMessage: (message: string) => void;
  title: string;
  setTitle: (title: string) => void;

  recipients: { name: string; emailAddress: string }[];
  onEditRecipients: () => void;
}

export const SettingsStep = ({
  emailMessage,
  setEmailMessage,
  setTitle,
  title,
  recipients,
  onEditRecipients,
}: SettingsStepOwnProps) => {
  return (
    <div>
      <ReportCard newStyles className={"userrisk-settings-card"}>
        <div className="header">Settings</div>
        <div className="report-card-sections">
          <div className="report-card-section request-name-section">
            <div className="section-label">Request name</div>
            <div className="section-content">
              <TextField
                value={title}
                onChanged={setTitle}
                required
                requiredErrorText="Enter a request name"
              />
            </div>
          </div>
          <div className="report-card-section">
            <div className="section-label">Recipients</div>
            <div className="section-content">
              <div className={"recipients-list-header"}>
                <div>{`Sending to ${recipients.length} ${pluralise(
                  recipients.length,
                  "recipient",
                  "recipients"
                )}`}</div>
                <div className="cr-icon-pencil-2" onClick={onEditRecipients} />
              </div>
              <UserRiskRecipients recipients={recipients} />
            </div>
          </div>
          <div className="report-card-section">
            <div className="section-label">Message</div>
            <div className="section-content">
              <div className="description">
                Include a custom message with your request. We also recommend
                telling the recipients a request is coming before it’s sent.
              </div>
              <RichTextEditV2
                className="description-editor"
                value={emailMessage}
                onChange={setEmailMessage}
              />
            </div>
          </div>
        </div>
      </ReportCard>
    </div>
  );
};
