import classnames from "classnames";
import classNames from "classnames";
import React, { FC, useCallback, useEffect, useState } from "react";

import { NodeType } from "../../survey_builder/types/types";
import { DefaultThunkDispatchProp } from "../../_common/types/redux";
import {
  setCurrentFilteredItem,
  setCurrentSelectedFilter,
  setFilterNodeSetDefinitions,
  setScrollTargetNode,
  setSidebarExpandedState,
} from "../reducers/actions";
import {
  AnswersForNodes,
  countChildrenChanged,
  FilteredNodes,
  FilteredNodesIdx,
  FlattenedNodeSummaryMap,
  getNodeTitleDisplay,
  isQuestionAnswered,
  NodeSummaryAndNode,
  QuestionAnswerState,
  SectionNodeAnswerState,
  VisiblityForNodes,
} from "../surveyViewer.helpers";
import SurveyViewerItemIcon from "./SurveyViewerItemIcon";
import {
  ExtraAttachmentsLabel,
  ExtraAttachmentsNodeId,
} from "./SurveyViewerExtraAttachments";
import PillLabel from "../../vendorrisk/components/PillLabel";
import { LabelColor } from "../../_common/types/label";
import CheckMark from "../../_common/components/CheckMark";
import IconButton from "../../_common/components/IconButton";
import Icon from "../../_common/components/core/Icon";
import ToggleWithLabel from "../../vendorrisk/components/ToggleWithLabel";
import {
  getLocalStorageItem,
  setLocalStorageItem,
} from "../../_common/session";
import { trackEvent } from "../../_common/tracking";
import DropdownV2, {
  DropdownItem,
} from "../../_common/components/core/DropdownV2";
import { SurveyViewerType } from "./SurveyViewer";
import Dot from "../../_common/components/Dot";
import { NodeTypeIconType } from "../../survey_builder/components/NodeTypeIcon";
import { appConnect, useAppDispatch } from "../../_common/types/reduxHooks";
import { SidePopupV2 } from "../../_common/components/DismissablePopup";
import { GptSuggestions } from "../reducers/autofill.actions";
import { SelectV2, OptionType } from "../../_common/components/SelectV2";
import Button from "../../_common/components/core/Button";
import { extractQuestionIdentifiers } from "../../survey_builder/types/conditionalLogic";
import { SurveyViewFilterType } from "../reducers/reducer";
import { useDebounce } from "../../_common/hooks";

interface SurveyViewerSidebarOwnProps {
  nodeTree: NodeSummaryAndNode;
  hasRequiredAttachments: boolean;
  flattenedNodeMap: FlattenedNodeSummaryMap;
  collapsed: boolean;
  onClickCollapse: () => void;
  changesOnly: boolean;
  viewerType: SurveyViewerType;
}

const locStorageKeyAutoExpandCollapse = "SurveyViewer_AutoExpandCollapse";

const SurveyViewerSidebar = (
  props: SurveyViewerSidebarOwnProps & DefaultThunkDispatchProp
) => {
  const [autoExpandCollapse, setAutoExpandCollapse] = useState(
    getLocalStorageItem(locStorageKeyAutoExpandCollapse, true) ?? false
  );
  const [isAllExpanded, setIsAllExpanded] = useState(
    !getLocalStorageItem(locStorageKeyAutoExpandCollapse, true) ?? true
  );

  // On load, expand all if autoExpandCollapse is not active
  useEffect(() => {
    const autoExpandInitialState =
      getLocalStorageItem(locStorageKeyAutoExpandCollapse, true) ?? false;
    if (autoExpandInitialState === false) {
      props.dispatch(
        setSidebarExpandedState(Object.keys(props.flattenedNodeMap), true)
      );
    }

    trackEvent("QuestionnaireViewer_autoExpandToggle", {
      value: autoExpandInitialState,
    });
  }, [props.flattenedNodeMap]);

  const items: JSX.Element[] = [];
  const topLevelIds: string[] = [];

  const getSidebarItemDisplay = (
    nodeSummary: NodeSummaryAndNode,
    level = 0
  ) => {
    // We can skip the root node
    if (nodeSummary.parentId) {
      if (
        Object.entries(nodeSummary.parentIdMap).length === 0 &&
        topLevelIds.indexOf(nodeSummary.nodeId) === -1
      ) {
        topLevelIds.push(nodeSummary.nodeId);
      }

      const item = (
        <SidebarItemConnected
          key={nodeSummary.nodeId}
          nodeSummary={nodeSummary}
          level={level}
          autoExpandCollapse={autoExpandCollapse}
          changesOnly={props.changesOnly}
          viewerType={props.viewerType}
        />
      );

      // this sucker is done. now do the children!
      items.push(item);
    }

    nodeSummary.children?.forEach((n) =>
      getSidebarItemDisplay(n, nodeSummary.parentId ? level + 1 : level)
    );
  };

  getSidebarItemDisplay(props.nodeTree);

  if (props.hasRequiredAttachments) {
    items.push(
      <AttachmentsSidebarItemConnected key={ExtraAttachmentsNodeId} />
    );
  }

  return (
    <div
      className={classnames("survey-viewer-sidebar", {
        hidden: props.collapsed,
        disabled: props.viewerType == SurveyViewerType.AutofillLoading,
      })}
      onClick={props.collapsed ? props.onClickCollapse : undefined}
    >
      <div className={"sidebar-main"}>
        <div className="sidebar-header">
          <div className="header-title">Contents</div>
          <DropdownV2
            className="toggle-dropdown"
            noCloseOnClickInside
            popupItem={
              <IconButton icon={<div className="cr-icon-dots-menu" />} />
            }
          >
            <ToggleWithLabel
              onClick={() => {
                setAutoExpandCollapse(!autoExpandCollapse);
                setLocalStorageItem(
                  locStorageKeyAutoExpandCollapse,
                  !autoExpandCollapse
                );

                trackEvent("QuestionnaireViewer_autoExpandToggle", {
                  value: !autoExpandCollapse,
                });

                // Collapse all the nodes (the correct ones will re-open)
                if (!autoExpandCollapse) {
                  props.dispatch(
                    setSidebarExpandedState(
                      Object.keys(props.flattenedNodeMap),
                      false
                    )
                  );
                }
              }}
              name={"tglAutoExpand"}
              selected={autoExpandCollapse}
              label={"Auto-expand sections"}
            />
          </DropdownV2>
          <SidePopupV2
            noWrap
            text={isAllExpanded ? "Collapse all" : "Expand all"}
            position={"left"}
          >
            <IconButton
              icon={<div className="cr-icon-expand-collapse" />}
              onClick={() => {
                setIsAllExpanded(!isAllExpanded);
                if (isAllExpanded) {
                  props.dispatch(
                    setSidebarExpandedState(
                      Object.keys(props.flattenedNodeMap),
                      !isAllExpanded
                    )
                  );
                } else {
                  props.dispatch(
                    setSidebarExpandedState(
                      Object.keys(props.flattenedNodeMap),
                      !isAllExpanded
                    )
                  );
                }
              }}
            />
          </SidePopupV2>
          <SidePopupV2 noWrap text={"Hide sidebar"} position={"left"}>
            <IconButton
              icon={<i className={"cr-icon-chevron rotate-180"} />}
              onClick={props.onClickCollapse}
            />
          </SidePopupV2>
        </div>
        <SurveyViewerSidebarFilterConnected
          flattenedNodeMap={props.flattenedNodeMap}
        />
        {items}
      </div>
      <div className={"sidebar-collapsed"}>
        <i className={"cr-icon-chevron rot-270"} />
        <div className={"contents"}>contents</div>
      </div>
    </div>
  );
};

export default appConnect()(SurveyViewerSidebar);

interface SurveyViewerSidebarItemOwnProps {
  nodeSummary: NodeSummaryAndNode;
  level: number;
  autoExpandCollapse: boolean;
  changesOnly: boolean;
  viewerType: SurveyViewerType;
}

interface SurveyViewerSidebarItemConnectedProps {
  isVisible: boolean;
  isAnswered: boolean;
  hasSuggestion: boolean;
  gptAutofillHasRun: boolean;
  childrenAnswerState?: SectionNodeAnswerState;
  isActive: boolean;
  isExpanded: boolean;
  isChildActive: boolean;
  isChanged: boolean;
  numAnswersChanged: number;
  numRisksChanged: number;
  filtersActive: boolean;
  filterIndex?: number; // the index of this sidebar item in the current filter
}

type SurveyViewerSidebarItemProps = SurveyViewerSidebarItemOwnProps &
  SurveyViewerSidebarItemConnectedProps &
  DefaultThunkDispatchProp;

const SidebarItem = ({
  dispatch,
  nodeSummary,
  isVisible,
  isAnswered,
  hasSuggestion,
  gptAutofillHasRun,
  childrenAnswerState,
  isActive,
  level,
  isExpanded,
  isChildActive,
  autoExpandCollapse,
  isChanged,
  changesOnly,
  viewerType,
  numAnswersChanged,
  numRisksChanged,
  filtersActive,
  filterIndex,
}: SurveyViewerSidebarItemProps) => {
  // Auto expand/collapse nodes as they become active

  const [isManualExpandCollapseOverride, setIsManualExpandCollapseOverride] =
    useState(false);

  useEffect(() => {
    if (autoExpandCollapse && !isManualExpandCollapseOverride) {
      if ((isChildActive || isActive) && !isExpanded) {
        dispatch(setSidebarExpandedState([nodeSummary.nodeId], true));
      }
    }
  }, [
    isActive,
    isChildActive,
    autoExpandCollapse,
    isExpanded,
    isManualExpandCollapseOverride,
  ]);

  useEffect(() => {
    if (autoExpandCollapse) {
      setIsManualExpandCollapseOverride(false);
    }
  }, [autoExpandCollapse]);

  let areAllChildrenAnswered = false;
  let totalQuestions = 0;
  let totalAnswers = 0;

  if (childrenAnswerState !== undefined) {
    totalQuestions =
      childrenAnswerState.questionCount + childrenAnswerState.optionalCount;
    totalAnswers =
      childrenAnswerState.answeredCount +
      childrenAnswerState.optionalAnsweredCount;

    areAllChildrenAnswered = totalQuestions === totalAnswers;
  }

  // Ignore section nodes that have no children
  // Also ignore info nodes
  let isNodeVisible = isVisible;
  if (
    nodeSummary.node.type === NodeType.Section &&
    !nodeSummary.children?.length
  ) {
    isNodeVisible = false;
  } else if (nodeSummary.node.type === NodeType.Info) {
    isNodeVisible = false;
  }

  // Don't show risks in the sidebar
  if (nodeSummary.node.type === NodeType.Risk) {
    isNodeVisible = false;
  }

  const classes = classnames("sidebar-item", {
    answered: (isAnswered && !hasSuggestion) || areAllChildrenAnswered,
    active: isActive || (isChildActive && !isExpanded),
    section: nodeSummary.node.type === NodeType.Section,
    changed: isChanged,
    filtered: filtersActive && (filterIndex ?? -1) >= 0,
    "changes-view": viewerType === SurveyViewerType.Diff,
    "has-suggestion": hasSuggestion,
    "not-filtered":
      filtersActive &&
      nodeSummary.node.type !== NodeType.Section &&
      (filterIndex ?? -1) < 0,
  });

  const itemDisplayText = getNodeTitleDisplay(nodeSummary, true, true);

  let changeText = "";
  if (numAnswersChanged > 0 && numRisksChanged > 0) {
    changeText = `${numAnswersChanged} answer${
      numAnswersChanged > 1 ? "s" : ""
    } and ${numRisksChanged} risk${numRisksChanged > 1 ? "s" : ""} changed`;
  } else if (numAnswersChanged > 0) {
    changeText = `${numAnswersChanged} answer${
      numAnswersChanged > 1 ? "s" : ""
    } changed`;
  } else if (numRisksChanged) {
    changeText = `${numRisksChanged} risk${
      numRisksChanged > 1 ? "s" : ""
    } changed`;
  }

  return isNodeVisible ? (
    <div className={"sidebar-item-container"}>
      {changesOnly && !isChanged ? (
        <React.Fragment />
      ) : (
        <div
          data-node-id={nodeSummary.nodeId}
          className={classes}
          style={{ marginLeft: 24 * (level + 1) }}
          onClick={() => {
            dispatch(setScrollTargetNode(nodeSummary.nodeId, true));
            if (filtersActive && (filterIndex ?? -1) >= 1) {
              dispatch(setCurrentFilteredItem((filterIndex ?? 1) - 1));
            }
          }}
        >
          <SurveyViewerItemIcon
            nodeSummary={nodeSummary}
            hasSuggestion={hasSuggestion}
          />
          <div className={"node-column"}>
            <div className={"node-info"}>
              <div
                className={"node-name"}
                dangerouslySetInnerHTML={{ __html: itemDisplayText }}
              />
              <SidebarItemRightContent
                node={nodeSummary}
                isAnswered={isAnswered || areAllChildrenAnswered}
                hasSuggestion={hasSuggestion}
                gptAutofillHasRun={gptAutofillHasRun}
                totalQuestions={totalQuestions}
                totalAnswers={totalAnswers}
                expanded={isExpanded}
                onExpandToggle={(expand) => {
                  setIsManualExpandCollapseOverride(true);
                  dispatch(
                    setSidebarExpandedState([nodeSummary.nodeId], expand)
                  );
                }}
              />
            </div>
            {isChanged && (
              <div className={"changed-label"}>
                <Dot color="green" />
                {nodeSummary.nodeType === NodeTypeIconType.Section
                  ? changeText
                  : "Answer changed"}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  ) : (
    <React.Fragment />
  );
};

const SidebarItemConnected = appConnect<
  SurveyViewerSidebarItemConnectedProps,
  never,
  SurveyViewerSidebarItemOwnProps
>((state, props) => {
  const answerState = isQuestionAnswered(
    props.nodeSummary,
    state.surveyViewer.answers,
    state.surveyViewer.gptAutofill?.suggestions
  );

  const isChildActive =
    props.nodeSummary.node.type === NodeType.Section &&
    !!state.surveyViewer.activeNodeId &&
    props.nodeSummary.childIdMap[state.surveyViewer.activeNodeId] !== undefined;

  let areParentsExpanded = true;

  Object.keys(props.nodeSummary.parentIdMap).forEach((pId) => {
    const isParentExpanded = !!state.surveyViewer.expandedNodes[pId];

    areParentsExpanded = areParentsExpanded && isParentExpanded;
  });

  const isNodeVisible =
    state.surveyViewer.nodeVisibilities[props.nodeSummary.nodeId] ?? false;

  const [numAnswersChanged, numRisksChanged] = countChildrenChanged(
    props.nodeSummary,
    state.surveyViewer.answersChanged
  );

  // get the active filter (if applicable) and the index of this node within that filter
  const activeFilter =
    state.surveyViewer.filteredNodeData?.activeFilter ??
    SurveyViewFilterType.None;
  const filterIndex =
    state.surveyViewer.filteredNodeData?.setIndexes[activeFilter]?.[
      props.nodeSummary.nodeId
    ] ?? -1;

  const conProps: SurveyViewerSidebarItemConnectedProps = {
    isAnswered:
      answerState === QuestionAnswerState.Answered ||
      answerState === QuestionAnswerState.OptionalAnswered,
    childrenAnswerState:
      state.surveyViewer.nodeChildrenAnswered[props.nodeSummary.nodeId],
    isVisible:
      (props.level === 0 || areParentsExpanded) &&
      (isNodeVisible || isChildActive),
    isActive: state.surveyViewer.activeNodeId === props.nodeSummary.nodeId,
    isExpanded: !!state.surveyViewer.expandedNodes[props.nodeSummary.nodeId],
    isChildActive: isChildActive || false,
    isChanged:
      state.surveyViewer.answersChanged.answers[props.nodeSummary.nodeId] ??
      false,
    numAnswersChanged: numAnswersChanged,
    numRisksChanged: numRisksChanged,
    hasSuggestion:
      answerState == QuestionAnswerState.Suggested ||
      answerState == QuestionAnswerState.OptionalSuggested,
    gptAutofillHasRun: !!state.surveyViewer.gptAutofill?.jobFinished,
    filtersActive: activeFilter !== SurveyViewFilterType.None,
    filterIndex: filterIndex,
  };
  return conProps;
})(SidebarItem);

interface AttachmentsSidebarItemConnectedProps {
  isActive: boolean;
}

type AttachmentsSidebarItemProps = AttachmentsSidebarItemConnectedProps &
  DefaultThunkDispatchProp;

const AttachmentsSidebarItem = (props: AttachmentsSidebarItemProps) => {
  const classes = classnames("sidebar-item", {
    active: props.isActive,
  });

  const attachmentNodeSummary = {
    questionNumber: ExtraAttachmentsLabel,
    node: {
      type: NodeType.Upload,
    } as any as Node,
  } as any as NodeSummaryAndNode;

  return (
    <div className={"sidebar-item-container"}>
      <div
        className={classes}
        style={{ marginLeft: 16 }}
        onClick={() => {
          props.dispatch(setScrollTargetNode(ExtraAttachmentsNodeId, true));
        }}
      >
        <SurveyViewerItemIcon nodeSummary={attachmentNodeSummary} />
        <div className={"node-name"}>Attachments</div>
      </div>
    </div>
  );
};

const AttachmentsSidebarItemConnected = appConnect<
  AttachmentsSidebarItemConnectedProps,
  never,
  any
>((state) => {
  return {
    isActive: state.surveyViewer.activeNodeId === ExtraAttachmentsNodeId,
  };
})(AttachmentsSidebarItem);

interface SidebarItemRightContentProps {
  node: NodeSummaryAndNode;
  isAnswered: boolean;
  hasSuggestion?: boolean;
  gptAutofillHasRun: boolean;
  totalQuestions: number;
  totalAnswers: number;
  expanded: boolean;
  onExpandToggle: (expand: boolean) => void;
}

const SidebarItemRightContent: FC<SidebarItemRightContentProps> = ({
  node,
  isAnswered,
  hasSuggestion,
  gptAutofillHasRun,
  totalQuestions,
  totalAnswers,
  expanded,
  onExpandToggle,
}) => {
  let questionCountDisplay: React.ReactNode;
  let expander: React.ReactNode = <></>;

  if (node.node.type === NodeType.Section) {
    questionCountDisplay = (
      <PillLabel color={LabelColor.Grey}>
        {totalAnswers} / {totalQuestions}
      </PillLabel>
    );

    const expanderClasses = classnames("expander", {
      expanded: expanded,
      answered: totalQuestions === totalAnswers,
    });

    expander = (
      <IconButton
        className={expanderClasses}
        icon={<Icon name={"chevron"} direction={!expanded ? 180 : 0} />}
        onClick={() => onExpandToggle(!expanded)}
      />
    );
  } else if (isAnswered && !hasSuggestion) {
    questionCountDisplay = <CheckMark checked={isAnswered} />;
  } else if (gptAutofillHasRun && hasSuggestion) {
    questionCountDisplay = (
      <i
        className={classNames("cr-icon-question-alert", {
          "has-suggestion": hasSuggestion,
        })}
      />
    );
  }

  const classes = classnames("sidebar-item-right-content", {
    section: node.node.type === NodeType.Section,
  });

  return (
    <div className={classes}>
      {questionCountDisplay}
      {expander}
    </div>
  );
};

// ----------------

interface SurveyViewerSidebarFilterOwnProps {
  flattenedNodeMap: FlattenedNodeSummaryMap;
}

interface SurveyViewerSidebarFilterConnectedProps {
  // Inputs for filter re-calc
  nodeTree?: NodeSummaryAndNode;
  answers?: AnswersForNodes;
  suggestions?: GptSuggestions;
  nodeVisibilities?: VisiblityForNodes;

  activeFilter: SurveyViewFilterType;
  currentSelectedFilteredItem: number;
  filterSet?: FilteredNodes;

  currentSelectedItem: number;
  numQuestions: number;
  unanswered: FilteredNodes;
  withSuggestions: FilteredNodes;
  withRisks: FilteredNodes;
}

type SurveyViewerSidebarFilterProps = SurveyViewerSidebarFilterOwnProps &
  SurveyViewerSidebarFilterConnectedProps &
  DefaultThunkDispatchProp;

// SurveyViewerSidebarFilter
// Provides an additional header line at the top of the SurveyViewer left-hand sidebar, allowing the user to filter the
// set of questions (nodes) based on particular pre-defined criteria, and then navigate up and down between
// highlighted results.
const SurveyViewerSidebarFilter = ({
  flattenedNodeMap,
  nodeTree,
  answers,
  suggestions,
  nodeVisibilities,

  activeFilter,
  currentSelectedFilteredItem,
  filterSet,

  numQuestions,
  unanswered,
  withSuggestions,
  withRisks,

  currentSelectedItem,
}: SurveyViewerSidebarFilterProps) => {
  const dispatch = useAppDispatch();

  // _buildNodeFilters()
  // Recursively process each of the nodes in the nodeTree in turn, build up a list of nodes that match each of our filter
  // options. At the same time, build an index to allow us to map from nodeId to list index. Once the node sets and indexes
  // have been calculted (updated) determine if the currently selected node is still in the filter set, or if its index in
  // the filter set has changed. If it has, then update the selected node to the new index. If it's not in the set anymore, then
  // we dont want to select another (it messes with the user's view too much).
  const _buildNodeFilters = useCallback(() => {
    if (!nodeTree) {
      return;
    }

    let numQuestions = 0;
    const nodeSets: {
      [filterType: string]: FilteredNodes;
    } = {
      [SurveyViewFilterType.Autofilled]: [],
      [SurveyViewFilterType.Unanswered]: [],
      [SurveyViewFilterType.RisksRaised]: [],
    };
    const setIndexes: {
      [filterType: string]: FilteredNodesIdx;
    } = {
      [SurveyViewFilterType.Autofilled]: {},
      [SurveyViewFilterType.Unanswered]: {},
      [SurveyViewFilterType.RisksRaised]: {},
    };

    const determineFilterNodeSets = (
      summary: NodeSummaryAndNode,
      level = 0
    ) => {
      // We can skip the root node
      if (summary.parentId) {
        const isVisible =
          (nodeVisibilities?.[summary.nodeId] ?? false) &&
          summary.node.type !== NodeType.Risk;

        if (isVisible) {
          const answerState = isQuestionAnswered(
            summary,
            answers ?? ({} as AnswersForNodes),
            suggestions
          );
          const isAnswered =
            answerState === QuestionAnswerState.Answered ||
            answerState === QuestionAnswerState.OptionalAnswered;

          const isSuggested =
            answerState === QuestionAnswerState.Suggested ||
            answerState === QuestionAnswerState.OptionalSuggested;

          if (summary.node.type !== NodeType.Section) {
            numQuestions = numQuestions + 1;
            if (isSuggested) {
              nodeSets[SurveyViewFilterType.Autofilled].push(summary.nodeId);
              setIndexes[SurveyViewFilterType.Autofilled][summary.nodeId] =
                nodeSets[SurveyViewFilterType.Autofilled].length;
            } else if (!isAnswered) {
              nodeSets[SurveyViewFilterType.Unanswered].push(summary.nodeId);
              setIndexes[SurveyViewFilterType.Unanswered][summary.nodeId] =
                nodeSets[SurveyViewFilterType.Unanswered].length;
            }
          }
        }
        if (
          summary.node.type === NodeType.Risk &&
          nodeVisibilities?.[summary.nodeId]
        ) {
          // visible risk - can we derive the cause node?
          let identifiers: string[] = [];
          identifiers = extractQuestionIdentifiers(
            summary.node.conditionalExpression ?? [],
            identifiers
          );
          // also check if this is node is a manual risk and provide the link to it's parent
          if (summary.node.customRiskID && summary.node.customRiskParentId) {
            identifiers.push(summary.node.customRiskParentId);
          }
          identifiers.forEach((nodeId) => {
            if (!setIndexes[SurveyViewFilterType.RisksRaised][nodeId]) {
              nodeSets[SurveyViewFilterType.RisksRaised].push(nodeId);
              setIndexes[SurveyViewFilterType.RisksRaised][nodeId] =
                nodeSets[SurveyViewFilterType.RisksRaised].length;
            }
          });
        }
      }

      summary.children?.forEach((n) =>
        determineFilterNodeSets(n, summary.parentId ? level + 1 : level)
      );
    };

    // get the nodeId of the currently selected filter item
    const getSelectedNodeInActiveFilter = (index: number) => {
      return filterSet?.[index] ?? "";
    };

    // get the index of a node in the new filter set definition (just calculated)
    const getNodeIndexInNewFilterDef = (nodeId: string): number => {
      const storedIndex = setIndexes[activeFilter]?.[nodeId] ?? -1;
      if (storedIndex > 0) {
        return storedIndex - 1;
      }
      return -1;
    };

    // before we calculate the new filtered node sets, first get the index and identity (nodeId) of the currently
    // selected node
    const oldSelectedIndex = currentSelectedFilteredItem;
    const oldSelectedNode = getSelectedNodeInActiveFilter(oldSelectedIndex);

    // now determine our new filter set definitions
    determineFilterNodeSets(nodeTree);

    // set our new definitions in redux for later use by subcomponents
    dispatch(setFilterNodeSetDefinitions(numQuestions, nodeSets, setIndexes));

    // now determine the location of our selected nodeId in the newly calculated filter set
    const indexOfOldSelectedNodeInNewFilter =
      getNodeIndexInNewFilterDef(oldSelectedNode);

    // if the location of our selected node has changed in the newly calculated filter set, then we'll need to
    // update the index we have stored for the current selected node.
    if (
      indexOfOldSelectedNodeInNewFilter >= 0 &&
      indexOfOldSelectedNodeInNewFilter !== oldSelectedIndex
    ) {
      // filters have changed, with the selected node being at a different index - select it!
      dispatch(setCurrentFilteredItem(indexOfOldSelectedNodeInNewFilter));
    } else if (oldSelectedIndex >= 0 && indexOfOldSelectedNodeInNewFilter < 0) {
      // ok so the old selected node isn't in the filter anymore! what should we do?
      // We tried selecting the node before the old one in the filter. Unfortunately it moved the user's view around.
      // This is especially a pain when the filter is the 'unanswered' filter and the user has simply hit a single key.
      // The conclusion is to not try and change the selected node (and certainly dont scroll to it)
      const newIdx = Math.max(oldSelectedIndex - 1, -1);
      if (
        activeFilter != SurveyViewFilterType.None &&
        newIdx >= -1 &&
        nodeSets[activeFilter]?.length > 0
      ) {
        dispatch(setCurrentFilteredItem(newIdx));
      }
    }
  }, [
    dispatch,
    nodeTree,
    nodeVisibilities,
    answers,
    suggestions,
    activeFilter,
    currentSelectedFilteredItem,
  ]);

  const debouncedBuildNodeFilters = useDebounce(_buildNodeFilters, 2000);

  // Effect is triggered when answers change to recalculate the filter sets if required.
  useEffect(() => {
    debouncedBuildNodeFilters();
  }, [dispatch, nodeTree, nodeVisibilities, answers, suggestions]);

  // for a particular question (by nodeId), extract the parent nodes in the hierarchy from the node back up to the root
  const getParentsOfNode = (nodeId: string, parents: string[]): string[] => {
    const parent = flattenedNodeMap[nodeId]?.parentId ?? "";
    if (parent != "") {
      parents.push(parent);
      return getParentsOfNode(parent, parents);
    }
    return parents;
  };

  // given a particular question (by nodeId), expand the path in the view from the root down to that node
  const expandToNode = (nodeId: string | undefined) => {
    if (nodeId) {
      let nodeIds: string[] = [];
      nodeIds = getParentsOfNode(nodeId, nodeIds);
      dispatch(setSidebarExpandedState([nodeId, ...nodeIds], true));
    }
  };

  // given a navigation filter type and an index within that filtered node set, find the corresponding nodeId, select it, and then
  // navigate to it in the view
  const gotoFilteredItem = (
    bySubset: SurveyViewFilterType,
    idx: number
  ): string | undefined => {
    let nodeId = "";
    switch (bySubset) {
      case SurveyViewFilterType.Autofilled:
        nodeId = withSuggestions[idx];
        break;
      case SurveyViewFilterType.Unanswered:
        nodeId = unanswered[idx];
        break;
      case SurveyViewFilterType.RisksRaised:
        nodeId = withRisks[idx];
        break;
    }
    dispatch(setCurrentSelectedFilter(bySubset));
    dispatch(setCurrentFilteredItem(idx));

    if (nodeId != "") {
      dispatch(setScrollTargetNode(nodeId, true));
      expandToNode(nodeId);
      return nodeId;
    }
    return undefined;
  };

  // given the currently selected navigation filter, return the number of items in the node subset for that filter
  const numFilteredItems = (): number => {
    switch (activeFilter) {
      case SurveyViewFilterType.Autofilled:
        return withSuggestions.length;
      case SurveyViewFilterType.Unanswered:
        return unanswered.length;
      case SurveyViewFilterType.RisksRaised:
        return withRisks.length;
    }
    return -1;
  };

  // given the index of a question in the current filter, navigate to and select the corresponding node in the view
  const gotoNavigableItem = (idx: number): string | undefined => {
    return gotoFilteredItem(activeFilter, idx);
  };

  const options: OptionType[] = [];
  let activeLabel = "";
  if (activeFilter !== SurveyViewFilterType.None) {
    switch (activeFilter) {
      case SurveyViewFilterType.Autofilled:
        activeLabel = "Autofilled";
        break;
      case SurveyViewFilterType.Unanswered:
        activeLabel = "Unanswered";
        break;
      case SurveyViewFilterType.RisksRaised:
        activeLabel = "Risks raised";
        break;
    }

    if (withSuggestions.length > 0) {
      options.push({
        label: `Autofilled (${Object.keys(withSuggestions).length})`,
        value: SurveyViewFilterType.Autofilled,
      });
    }
    if (unanswered.length > 0) {
      options.push({
        label: `Unanswered (${Object.keys(unanswered).length})`,
        value: SurveyViewFilterType.Unanswered,
      });
    }
    if (withRisks.length > 0) {
      options.push({
        label: `Risks raised (${Object.keys(withRisks).length})`,
        value: SurveyViewFilterType.RisksRaised,
      });
    }
  }

  return (
    <>
      {(unanswered.length > 0 ||
        withSuggestions.length > 0 ||
        withRisks.length > 0) && (
        <div className="sidebar-filter">
          <div className="header-filter">
            {activeFilter !== SurveyViewFilterType.None && (
              <SelectV2
                isClearable
                className={classnames("category-selector", {
                  "filter-active": true,
                })}
                value={{ label: activeLabel, value: activeFilter }}
                onChange={(e: any) => {
                  if (e) {
                    gotoFilteredItem(e.value, 0);
                  } else {
                    dispatch(
                      setCurrentSelectedFilter(SurveyViewFilterType.None)
                    );
                  }
                }}
                disabled={numQuestions === 0}
                options={options}
              ></SelectV2>
            )}
            {activeFilter === SurveyViewFilterType.None && (
              <DropdownV2
                popupItem={
                  <Button tertiary className={"filter-button"}>
                    <i className={"cr-icon-filter"} />
                    {"Filter"}
                    <i className={"cr-icon-chevron rotate-90"} />
                  </Button>
                }
              >
                {withSuggestions.length > 0 && (
                  <DropdownItem
                    className={"filter-option"}
                    stopPropagation
                    onClick={() => {
                      gotoFilteredItem(SurveyViewFilterType.Autofilled, 0);
                    }}
                  >
                    {`Autofilled (${Object.keys(withSuggestions).length})`}
                  </DropdownItem>
                )}
                {unanswered.length > 0 && (
                  <DropdownItem
                    className={"filter-option"}
                    stopPropagation
                    onClick={() => {
                      gotoFilteredItem(SurveyViewFilterType.Unanswered, 0);
                    }}
                  >
                    {`Unanswered (${Object.keys(unanswered).length})`}
                  </DropdownItem>
                )}
                {withRisks.length > 0 && (
                  <DropdownItem
                    className={"filter-option"}
                    stopPropagation
                    onClick={() => {
                      gotoFilteredItem(SurveyViewFilterType.RisksRaised, 0);
                    }}
                  >
                    {`Risks raised (${Object.keys(withRisks).length})`}
                  </DropdownItem>
                )}
              </DropdownV2>
            )}
            {activeFilter !== SurveyViewFilterType.None && (
              <div className={"filter-buttons"}>
                <div className={"counts"}>{`${
                  Math.max(currentSelectedItem, 0) + 1
                }/${
                  activeFilter == SurveyViewFilterType.Autofilled
                    ? Object.keys(withSuggestions).length
                    : activeFilter == SurveyViewFilterType.Unanswered
                      ? Object.keys(unanswered).length
                      : activeFilter == SurveyViewFilterType.RisksRaised
                        ? Object.keys(withRisks).length
                        : 0
                }`}</div>
                <div className="sep" />
                <div
                  className={classnames("nav-btn", {
                    disabled: currentSelectedItem <= 0,
                  })}
                  onClick={() => {
                    if (currentSelectedItem > 0) {
                      gotoNavigableItem(Math.max(currentSelectedItem - 1, 0));
                    }
                  }}
                >
                  <i
                    className={classnames("cr-icon-chevron rotate-270", {
                      disabled: currentSelectedItem <= 0,
                    })}
                  />
                </div>
                <div
                  className={classnames("nav-btn", {
                    disabled: currentSelectedItem >= numFilteredItems() - 1,
                  })}
                  onClick={() => {
                    if (currentSelectedItem < numFilteredItems() - 1) {
                      gotoNavigableItem(
                        Math.min(
                          currentSelectedItem + 1,
                          numFilteredItems() - 1
                        )
                      );
                    }
                  }}
                >
                  <i
                    className={classnames("cr-icon-chevron rotate-90", {
                      disabled: currentSelectedItem >= numFilteredItems() - 1,
                    })}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

const emptyNodesFilter: FilteredNodes = [];

const SurveyViewerSidebarFilterConnected = appConnect<
  SurveyViewerSidebarFilterConnectedProps,
  never,
  SurveyViewerSidebarFilterOwnProps
>((state) => {
  const filterData = state.surveyViewer.filteredNodeData ?? undefined;
  const activeFilter = filterData?.activeFilter ?? SurveyViewFilterType.None;

  const conProps: SurveyViewerSidebarFilterConnectedProps = {
    nodeTree: state.surveyViewer.nodeTree,
    answers: state.surveyViewer.answers,
    suggestions: state.surveyViewer.gptAutofill?.suggestions,
    nodeVisibilities: state.surveyViewer.nodeVisibilities,

    currentSelectedFilteredItem:
      state.surveyViewer.filteredNodeData.currentSelectedItem ?? 0,
    filterSet:
      state.surveyViewer.filteredNodeData.nodeSets[activeFilter] ?? undefined,

    activeFilter: activeFilter,
    currentSelectedItem: filterData?.currentSelectedItem ?? 0,
    numQuestions: filterData?.numQuestions ?? 0,
    unanswered:
      filterData?.nodeSets?.[SurveyViewFilterType.Unanswered] ??
      emptyNodesFilter,
    withRisks:
      filterData?.nodeSets?.[SurveyViewFilterType.RisksRaised] ??
      emptyNodesFilter,
    withSuggestions:
      filterData?.nodeSets?.[SurveyViewFilterType.Autofilled] ??
      emptyNodesFilter,
  };

  return conProps;
})(SurveyViewerSidebarFilter);
