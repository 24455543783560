import { FC } from "react";
import userbaseApi from "../../api/userbase.api";
import ListFilter from "./ListFilter";
import {
  OrgAccessUserBase,
  UserUserBaseEnabled,
} from "../../../_common/permissions";
import withRequireEntitlementAndPermission from "../../../_common/hocs/WithRequireEntitlementAndPermission";

interface UserRisksUsersFilterProps {
  selectedUUIDs: string[];
  excluded: boolean;
  onChange: (selectedUUIDs: string[], exclude: boolean) => void;
}

const UserRisksUsersFilter: FC<UserRisksUsersFilterProps> = ({
  selectedUUIDs,
  excluded,
  onChange,
}) => {
  const { data, isLoading } = userbaseApi.useGetUserBaseUsersV1Query({});

  return (
    <ListFilter
      title={"Users"}
      isLoading={isLoading}
      options={
        data?.users.map((user) => {
          return {
            value: user.uuid,
            label: user.name || user.email,
          };
        }) ?? []
      }
      selectedValues={selectedUUIDs}
      excluded={excluded}
      onChange={(selectedValues, exclude) => onChange(selectedValues, exclude)}
    />
  );
};

export default withRequireEntitlementAndPermission(
  UserRisksUsersFilter,
  OrgAccessUserBase,
  UserUserBaseEnabled
);
