import { AppRouteParams } from "../UserBaseNavItems";
import { useDefaultHistory } from "../../_common/types/router";
import UserBaseAPI from "../api/userbase.api";
import ReportCard from "../../_common/components/ReportCard";
import Button from "../../_common/components/core/Button";
import LoadingBanner from "../../_common/components/core/LoadingBanner";
import { FC } from "react";
import { useRouteMatch } from "react-router-dom";
import { appSummaryUrl, riskProfileUrl } from "../UserBaseAppRouter";
import SeverityIcon from "../../_common/components/SeverityIcon";
import "./AppRisksOverview.scss";
import { pluralise } from "../../_common/helpers";

const AppRisksOverview: FC = ({}) => {
  const match = useRouteMatch<AppRouteParams>();
  const history = useDefaultHistory();

  const appName = match.params.appName;

  const { data, isLoading } =
    UserBaseAPI.useGetUserBaseAppRisksBreakdownV1Query({
      app: appName,
    });

  const onViewAll = () => {
    history.push(riskProfileUrl, {
      backContext: {
        backTo: appSummaryUrl(appName),
        backToText: `Back to ${appName}`,
      },
    });
  };

  if (!isLoading && !data?.breakdown) {
    return <></>;
  }

  const sortedRisks =
    data?.breakdown.slice().sort((a, b) => b.severityNum - a.severityNum) ?? [];

  return (
    <ReportCard newStyles className="userbase-app-risks-overview">
      <div className="header">
        <span>Risks</span>
        <div className={"header-link"}>
          <Button tertiary onClick={onViewAll}>
            View all <div className="cr-icon-arrow-right" />
          </Button>
        </div>
      </div>
      {isLoading && <LoadingBanner />}
      {!isLoading && (
        <div className={"content"}>
          <div className={"subtitle"}>Current risks by severity</div>
          <div className={"breakdown"}>
            {sortedRisks.map((r) => {
              const numberOfRisksToDisplay =
                r.numRisks === 0
                  ? "-"
                  : `${r.numRisks} ${pluralise(r.numRisks, "risk", "risks")}`;

              return (
                <div key={r.severityNum} className={"risk-severity"}>
                  <SeverityIcon severity={r.severity} label />
                  {numberOfRisksToDisplay}
                </div>
              );
            })}
          </div>
        </div>
      )}
    </ReportCard>
  );
};

export default AppRisksOverview;
