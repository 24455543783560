import { DataLeaksFilterOptionType } from "./types";
import { FC, useState } from "react";
import { SlidePanelSection } from "./SlidePanelSection";
import {
  OptionType,
  SelectV2Multi,
} from "../../../_common/components/SelectV2";
import LabelList from "../LabelList";
import { ValueType } from "react-select";

interface IDataLeaksSourceFilterProps {
  selected?: DataLeaksFilterOptionType[];
  onChange: (opts: DataLeaksFilterOptionType[]) => void;
  availableSources?: Record<string, string>;
  title?: string;
  startExpanded?: boolean;
}

const DataLeaksSourceFilter: FC<IDataLeaksSourceFilterProps> = ({
  selected = [],
  onChange,
  availableSources = {},
  title = "Filter by Source",
  startExpanded = false,
}) => {
  const [expanded, setExpanded] = useState(
    startExpanded || selected.length > 0
  );

  const selectOnChange = (selectedOptions: ValueType<OptionType, true>) => {
    onChange((selectedOptions ?? []) as DataLeaksFilterOptionType[]);
  };

  const options = Object.entries(availableSources).map(([id, name]) => ({
    value: id,
    label: name,
  }));

  const selectValue = [
    ...selected.map(
      (sel) => options.find(({ value }) => value === sel.value) as OptionType
    ),
  ];

  const selectedSources = selectValue.map(({ value, label }) => ({
    id: value,
    name: label,
    removeable: true,
    large: true,
    constrained: true,
    onRemoveClick: () =>
      selectOnChange(selectValue.filter((option) => value !== option.value)),
  }));

  return (
    <SlidePanelSection
      title={title}
      expanded={expanded}
      toggleExpand={() => setExpanded(!expanded)}
    >
      <SelectV2Multi
        placeholder="Type to search sources"
        options={options}
        value={selectValue}
        onChange={selectOnChange}
        controlShouldRenderValue={false}
      />
      <LabelList labels={selectedSources} />
    </SlidePanelSection>
  );
};

export default DataLeaksSourceFilter;
