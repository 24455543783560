import {
  hubSpotIsAvailable,
  openHubSpot,
} from "./components/HubspotWidgetWrapper";

export default function contactSupport() {
  const canUseHubSpot = hubSpotIsAvailable();

  if (!canUseHubSpot) {
    // Use email instead:
    window.open("mailto:support@upguard.com");
    return;
  }
  openHubSpot();
}
