import BaseAPI, { vendorDataTag } from "../../_common/rtkQueryApi";
import { SeverityInt } from "../../_common/types/severity";
import { vsaqQuestion } from "../../survey_builder/vsaq/vsaqTypes";
import { RiskClassification } from "../../_common/types/risk/classification";
import { fetchSurveyDetails } from "../../_common/reducers/surveyDetails.actions";
import { fetchQuestionnaire } from "./questionnaireAnswers.actions";

export interface ManualRisk {
  id: number;
  organisationId: number;
  name: string;
  impact: string;
  category: string;
  severity: SeverityInt;
  classification: RiskClassification;
  createdBy: number;
  createdAt: string;
  updatedAt: string;
  editable: boolean;
  baseSeverity?: SeverityInt;
}

export const ManualRiskListTag = "ManualRiskListTag" as const;

const ManualRisksAPI = BaseAPI.enhanceEndpoints({
  addTagTypes: [ManualRiskListTag],
}).injectEndpoints({
  endpoints: (builder) => ({
    getAllManualRisks: builder.query<ManualRisk[], void>({
      query: () => ({
        url: "vendor/manual_risk/v1/",
        method: "GET",
      }),
      providesTags: [ManualRiskListTag, vendorDataTag],
    }),

    addManualRiskToSurvey: builder.mutation<
      { newRiskNode: vsaqQuestion },
      {
        surveyID: number;
        parentNodeID: string;
        riskID?: number;
        newFindingName?: string;
        newFindingImpact?: string;
        newFindingSeverity?: string;
        newFindingClassification?: RiskClassification;
      }
    >({
      query: (args) => ({
        url: "surveys/manual_risk/v1",
        method: "POST",
        body: JSON.stringify(args),
      }),
      invalidatesTags: [ManualRiskListTag],
      onQueryStarted: (arg, { dispatch, queryFulfilled }) => {
        queryFulfilled.then(() => {
          dispatch(fetchSurveyDetails(arg.surveyID, true));
        });
      },
    }),

    deleteManualRiskFromSurvey: builder.mutation<
      void,
      { surveyID: number; riskID: string }
    >({
      query: (args) => ({
        url: "surveys/manual_risk/v1",
        method: "DELETE",
        params: {
          survey_id: args.surveyID,
          risk_id: args.riskID,
        },
      }),
      invalidatesTags: [ManualRiskListTag],
      onQueryStarted: (arg, { dispatch, queryFulfilled }) => {
        queryFulfilled.then(() => {
          dispatch(fetchSurveyDetails(arg.surveyID, true));
          dispatch(fetchQuestionnaire(arg.surveyID, true, 0, true));
        });
      },
    }),

    editManualRisk: builder.mutation<
      { newRiskId: number },
      {
        riskID: number;
        findingName: string;
        findingImpact: string;
        findingSeverity: string;
        findingClassification?: RiskClassification;
      }
    >({
      query: (args) => ({
        url: "vendor/manual_risk/v1",
        method: "PUT",
        params: {
          risk_id: args.riskID,
          risk_name: args.findingName,
          risk_impact: args.findingImpact,
          risk_severity: args.findingSeverity,
          risk_classification: args.findingClassification,
        },
      }),
      invalidatesTags: [ManualRiskListTag],
      // TODO - this also needs to refresh additional evidence as that may have changed
    }),
  }),
});

export default ManualRisksAPI;
