import { useCallback, useMemo, useState } from "react";
import SlidePanelSection from "./SlidePanelSection";
import {
  OptionType,
  SelectV2Multi,
} from "../../../_common/components/SelectV2";
import { ValueType } from "react-select";

interface ISelectFilterProps<IDType extends string | number> {
  title: string;
  startExpanded?: boolean;
  options: OptionType<IDType>[];
  selectedOptionIDs: IDType[];
  onChange: (optionIDs: IDType[]) => void;
  placeholder?: string;
}

const SelectFilter = <IDType extends string | number = string | number>({
  title,
  startExpanded,
  options,
  selectedOptionIDs,
  onChange,
  placeholder,
}: ISelectFilterProps<IDType>) => {
  const [expanded, setExpanded] = useState(
    startExpanded || selectedOptionIDs.length > 0
  );

  const selectedOptions = useMemo(
    () => options.filter((opt) => selectedOptionIDs.includes(opt.value)),
    [options, selectedOptionIDs]
  );

  const onSelectChange = useCallback(
    (opts: ValueType<OptionType<IDType>, true>) => {
      onChange((opts ?? []).map((opt) => opt.value));
    },
    [onChange]
  );

  return (
    <SlidePanelSection
      title={title}
      expanded={expanded}
      toggleExpand={() => setExpanded(!expanded)}
    >
      <SelectV2Multi
        placeholder={placeholder}
        options={options}
        value={selectedOptions}
        onChange={onSelectChange}
      />
    </SlidePanelSection>
  );
};

export default SelectFilter;
