import { FC, useCallback, useMemo } from "react";
import { InfoTableRow } from "../../_common/components/InfoTable";
import {
  useBasicPermissions,
  UserWriteSurveyImportExport,
  UserWriteUsers,
} from "../../_common/permissions";
import SurveyImportAPI from "../api/surveyImportAPI";
import "../style/ImportedSurveyCollaborators.scss";
import { IUserMini } from "../../_common/types/user";
import UserAssignment from "../../_common/components/UserAssignment";
import { useAppDispatch } from "../../_common/types/reduxHooks";
import { addDefaultUnknownErrorAlert } from "../../_common/reducers/messageAlerts.actions";
import { useConfirmationModalV2 } from "../../_common/components/modals/ConfirmationModalV2";
import { useModalV2 } from "../../_common/components/ModalV2";
import NamedRoleInviteModal from "../../vendorrisk/components/modals/NamedRoleInviteModal";
import { CannedTypes } from "../../vendorrisk/helpers/roles";

interface IImportedSurveyCollaboratorsProps {
  surveyID: number;
  surveyImportUUID: string;
  assigneeUserIDs: number[];
}

const ImportedSurveyCollaborators: FC<IImportedSurveyCollaboratorsProps> = ({
  surveyID,
  surveyImportUUID,
  assigneeUserIDs,
}) => {
  const dispatch = useAppDispatch();
  const perms = useBasicPermissions();
  const hasSurveyImportWrite =
    !!perms.userPermissions[UserWriteSurveyImportExport];
  const hasUsersWrite = !!perms.userPermissions[UserWriteUsers];

  const [openConfirmationModal, confirmationModal] = useConfirmationModalV2();
  const [openInviteUsersModal, inviteUsersModal] =
    useModalV2(NamedRoleInviteModal);

  const { data: orgUsersWithWritePerm } =
    SurveyImportAPI.useGetImportedSurveyEligibleOrgUsersQuery();

  const users = useMemo(() => {
    if (!orgUsersWithWritePerm) {
      return [];
    }

    const users: IUserMini[] = [];

    assigneeUserIDs.forEach((uID) => {
      const user = orgUsersWithWritePerm.users.find((u) => u.id === uID);
      if (user) {
        users.push(user);
      }
    });

    return users;
  }, [assigneeUserIDs, orgUsersWithWritePerm]);

  const [updateImportedSurveyAssignees] =
    SurveyImportAPI.useUpdateImportedSurveyAssigneesMutation();
  const onChangeAssignees = useCallback(
    async (newAssignees: IUserMini[]) => {
      updateImportedSurveyAssignees({
        surveyID,
        uuid: surveyImportUUID,
        assignees: newAssignees.map((u) => u.id),
      })
        .unwrap()
        .catch((e) => {
          console.error(e);
          dispatch(addDefaultUnknownErrorAlert("Error updating collaborators"));
        });
    },
    [updateImportedSurveyAssignees, surveyImportUUID, surveyID, dispatch]
  );

  const onClickInviteMore = useCallback(() => {
    if (hasUsersWrite) {
      openInviteUsersModal({
        selectCannedRole: CannedTypes.TrustExchangeStandard,
        returnTo: {
          goBack: true,
          backToText: "Back to questionnaire",
        },
      });
    } else {
      openConfirmationModal({
        title: "Ask an administrator to invite more people",
        description: (
          <p>
            You do not have permission to add additional users to your account.
            Ask an account administrator to invite your colleagues. When they
            sign up, they&apos;ll be available as assignees.
          </p>
        ),
        buttonAction: () => undefined,
        hideCancel: true,
      });
    }
  }, [hasUsersWrite, openConfirmationModal, openInviteUsersModal]);

  if (!orgUsersWithWritePerm) {
    return null;
  }

  return (
    <>
      <InfoTableRow
        label="Assignees"
        rowClass="imported-survey-collaborators"
        value={
          <>
            <UserAssignment
              assignedUsers={users}
              allowMultiple
              currentUserCanEdit={hasSurveyImportWrite}
              availableUsers={orgUsersWithWritePerm.users}
              onChangeAssignees={onChangeAssignees}
              onClickInviteMore={onClickInviteMore}
            />
          </>
        }
      />
      {confirmationModal}
      {inviteUsersModal}
    </>
  );
};

export default ImportedSurveyCollaborators;
