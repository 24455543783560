import { FC, PropsWithChildren, useCallback, useEffect, useRef } from "react";
import classnames from "classnames";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import "../style/components/Surface.scss";

export enum SurfacePosition {
  Top,
  Right,
  Bottom,
  Left,
}

export interface SurfaceProps extends PropsWithChildren {
  active: boolean;
  classNames?: string;
  onClickOutside?: () => void;
  skipForOutsideCheckSelector?: string;
  position?: SurfacePosition;
  width?: number;
}

// Simple floating surface
// Requires parent to be position: relative for positioning
export const Surface: FC<SurfaceProps> = ({
  active,
  children,
  classNames,
  onClickOutside,
  skipForOutsideCheckSelector,
  position,
  width,
}) => {
  const classes = classnames("surface", classNames, {
    top: position === SurfacePosition.Top,
    right: position === SurfacePosition.Right,
    bottom: position === undefined || position === SurfacePosition.Bottom,
    left: position === SurfacePosition.Left,
  });
  const divRef = useRef<HTMLDivElement>(null);

  const clickWatcher = useCallback(
    (ev: any) => {
      if (
        !onClickOutside ||
        ev.target.closest(".surface") === divRef.current ||
        (skipForOutsideCheckSelector &&
          ev.target.closest(`${skipForOutsideCheckSelector}`))
      ) {
        return;
      }

      onClickOutside();
    },
    [onClickOutside, skipForOutsideCheckSelector]
  );

  useEffect(() => {
    if (active) {
      setTimeout(() => document.addEventListener("click", clickWatcher), 0);
      return () => document.removeEventListener("click", clickWatcher);
    } else {
      return undefined;
    }
  }, [active]);

  return (
    <TransitionGroup component={null}>
      {active && (
        <CSSTransition
          key={"surface"}
          timeout={250}
          classNames={"fade-transition"}
        >
          <div ref={divRef} className={classes} style={{ width }}>
            {children}
          </div>
        </CSSTransition>
      )}
    </TransitionGroup>
  );
};
