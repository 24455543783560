import { FC } from "react";
import ReportCard from "../../../_common/components/ReportCard";
import { IdentityBreachRouteParams } from "../../UserBaseNavItems";
import UserBaseAPI from "../../api/userbase.api";
import { useRouteMatch } from "react-router-dom";
import LoadingBanner from "../../../_common/components/core/LoadingBanner";
import IdentityBreachDataClasses from "./IdentityBreachDataClasses";
import "./IdentityBreachDataExposedSummary.scss";

const IdentityBreachDataExposedSummary: FC = ({}) => {
  const match = useRouteMatch<IdentityBreachRouteParams>();

  const breachID = Number(match.params.breachID);

  const { isLoading, data } =
    UserBaseAPI.useGetUserBaseIdentityBreachSummaryV1Query({
      breachID,
    });

  const breach = data?.breach;
  return (
    <div className="ib-data-exposed-summary">
      <ReportCard newStyles className="userbase-ib-data-exposed-summary">
        <div className="header">
          <span>Data Exposed</span>
        </div>
        {isLoading && <LoadingBanner />}
        {!isLoading && breach && (
          <div className="data-list">
            <IdentityBreachDataClasses breach={breach} />
          </div>
        )}
      </ReportCard>
    </div>
  );
};

export default IdentityBreachDataExposedSummary;
