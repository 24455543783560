import { DataLeaksFilterOptionType } from "./types";
import {
  OptionType,
  SelectV2Multi,
} from "../../../_common/components/SelectV2";
import { SlidePanelSection } from "./SlidePanelSection";
import LabelList from "../LabelList";
import { FC, useState } from "react";
import { ValueType } from "react-select";

interface IDataLeaksKeywordFilterProps {
  selected?: DataLeaksFilterOptionType[];
  onChange: (opts: DataLeaksFilterOptionType[]) => void;
  availableKeywords?: string[];
  title?: string;
  startExpanded?: boolean;
}

const DataLeaksKeywordFilter: FC<IDataLeaksKeywordFilterProps> = ({
  selected = [],
  onChange,
  availableKeywords = [],
  title = "Filter by Keyword",
  startExpanded = false,
}) => {
  const [expanded, setExpanded] = useState(
    startExpanded || selected.length > 0
  );

  const selectOnChange = (selectedOptions: ValueType<OptionType, true>) => {
    onChange((selectedOptions ?? []) as DataLeaksFilterOptionType[]);
  };

  const options = availableKeywords.map((keyword) => ({
    value: keyword,
    label: keyword,
  }));

  const selectValue = [
    ...selected.map(
      (sel) => options.find(({ value }) => value === sel.value) as OptionType
    ),
  ];

  const selectedKeywords = selectValue.map(({ value, label }) => ({
    id: value,
    name: label,
    removeable: true,
    large: true,
    constrained: true,
    onRemoveClick: () =>
      selectOnChange(selectValue.filter((option) => value !== option.value)),
  }));

  return (
    <SlidePanelSection
      title={title}
      expanded={expanded}
      toggleExpand={() => setExpanded(!expanded)}
    >
      <SelectV2Multi
        placeholder="Type to search keywords"
        options={options}
        value={selectValue}
        onChange={selectOnChange}
        controlShouldRenderValue={false}
      />
      <LabelList labels={selectedKeywords} />
    </SlidePanelSection>
  );
};

export default DataLeaksKeywordFilter;
