import { DefaultRootState } from "react-redux";
import {
  DefaultThunkDispatch,
  ISingleVendorData,
} from "../../_common/types/redux";
import { FetchCyberRiskUrl } from "../../_common/api";
import { getVendorData, setVendorData } from "./cyberRiskActions";
import { grabTPVMSession } from "../../_common/reducers/commonActions";
import { VendorAssessmentSummary } from "../types/vendorAssessments";

export interface OrganisationWithSharedAssetsForVendor {
  orgId: number;
  orgName: string;
  requested: boolean;
  granted: boolean;
  rejected: boolean;
  riskAssessmentDraft: number;
  riskAssessmentPublished: number;
  numQuestionnaires: number;
  numAdditionalEvidence: number;
  isSharedProfile: boolean;
  isNdaRequired: boolean;
}

export const getOrganisationsSharingVendorAssets = (
  datastoreVendorId: number,
  forceRefresh?: boolean
) => {
  return async (
    dispatch: DefaultThunkDispatch,
    getState: () => DefaultRootState
  ): Promise<OrganisationWithSharedAssetsForVendor[]> => {
    const tpvmSession = grabTPVMSession(getState);
    if (!forceRefresh) {
      const vendorData = getVendorData(
        getState,
        datastoreVendorId,
        false,
        tpvmSession
      ) as ISingleVendorData | undefined;
      if (vendorData?.organisationsWithSharedAssets) {
        return vendorData.organisationsWithSharedAssets;
      }
    }

    let result: OrganisationWithSharedAssetsForVendor[];

    try {
      const resp = await FetchCyberRiskUrl<{
        organisationsWithSharedAssets?: OrganisationWithSharedAssetsForVendor[];
      }>(
        "vendor/sharedassets/orgs/v1",
        {
          datastore_vendor_id: datastoreVendorId,
        },
        null,
        dispatch,
        getState
      );

      result = resp.organisationsWithSharedAssets || [];
    } catch (e) {
      console.error(e);
      throw e;
    }

    dispatch(
      setVendorData(
        datastoreVendorId,
        { organisationsWithSharedAssets: result },
        false,
        tpvmSession as any
      )
    );

    return result;
  };
};

export interface SharedAssetsForOrgAndVendor {
  orgId: number;
  orgName: string;
  requested: boolean;
  granted: boolean;
  rejected: boolean;
  surveys: {
    id: number;
    name: string;
    status: string;
  }[];
  additionalEvidence: {
    id: number;
    name: string;
    documentType: string;
    totalRisks: number;
    createdAt: string;
    expiry?: string;
  }[];
  publishedRiskAssessments?: VendorAssessmentSummary[];
  draftRiskAssessmentExists: boolean;
}

export const getSharedAssetsForOrgAndVendor = (
  datastoreVendorId: number,
  orgId: number,
  forceRefresh?: boolean
) => {
  return async (
    dispatch: DefaultThunkDispatch,
    getState: () => DefaultRootState
  ): Promise<SharedAssetsForOrgAndVendor> => {
    const tpvmSession = grabTPVMSession(getState);
    if (!forceRefresh) {
      const vendorData = getVendorData(
        getState,
        datastoreVendorId,
        false,
        tpvmSession
      ) as ISingleVendorData | undefined;
      if (
        vendorData?.sharedAssetsByOrgId &&
        vendorData.sharedAssetsByOrgId[orgId]
      ) {
        return vendorData.sharedAssetsByOrgId[
          orgId
        ] as SharedAssetsForOrgAndVendor;
      }
    }

    let result: SharedAssetsForOrgAndVendor;

    try {
      result = await FetchCyberRiskUrl<SharedAssetsForOrgAndVendor>(
        "vendor/sharedassets/singleorg/v1",
        {
          datastore_vendor_id: datastoreVendorId,
          org_id: orgId,
        },
        null,
        dispatch,
        getState
      );
    } catch (e) {
      console.error(e);
      throw e;
    }

    dispatch(
      setVendorData(
        datastoreVendorId,
        {
          sharedAssetsByOrgId: {
            ...(
              getVendorData(getState, datastoreVendorId, false, tpvmSession) as
                | ISingleVendorData
                | undefined
            )?.sharedAssetsByOrgId,
            [orgId]: result,
          },
        },
        false,
        tpvmSession as any
      )
    );

    return result;
  };
};

export const requestSharedAccessForOrgAndVendor = (
  datastoreVendorId: number,
  orgId: number
) => {
  return async (
    dispatch: DefaultThunkDispatch,
    getState: () => DefaultRootState
  ): Promise<void> => {
    try {
      await FetchCyberRiskUrl(
        "vendor/sharedassets/request/v1",
        {
          datastore_vendor_id: datastoreVendorId,
          org_id: orgId,
        },
        { method: "POST" },
        dispatch,
        getState
      );
    } catch (e) {
      console.error(e);
      throw e;
    }
  };
};
