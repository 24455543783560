import BaseAPI from "../rtkQueryApi";
import { OrgFlagType } from "../types/organisations";
import OrganisationFlagsAPI, {
  OrganisationFlagsTags,
} from "./organisationFlagsAPI";

export const enum NominatedApproverFeature {
  BreachSightRiskWaivers = "BreachSightRiskWaivers",
  VendorRiskRiskWaivers = "VendorRiskRiskWaivers",
  VendorRiskRiskAdjustments = "VendorRiskRiskAdjustments",
}

export interface Approver {
  id: number;
  feature: string;
  email: string;
  userId?: number;
  inviteId?: number;
  name?: string;
  avatar?: string;
}

export interface ListApproversV1Response {
  approvers: Approver[];
}

export interface AddApproverV1Params {
  user_id?: number;
  invite_id?: number;
  features: NominatedApproverFeature[];
  email: string;
  name?: string;
  avatar?: string;
}

export interface BulkAddApproversV1Params {
  approvers: AddApproverV1Params[];
}

export interface DeleteApproversV1Params {
  approver_ids: number[];
}

export enum ApproversTagTypes {
  approvers = "approvers",
}

const ApproversAPI = BaseAPI.enhanceEndpoints({
  addTagTypes: [
    ...Object.values(ApproversTagTypes),
    ...Object.values(OrganisationFlagsTags),
  ],
}).injectEndpoints({
  endpoints: (builder) => ({
    listApproversV1: builder.query<ListApproversV1Response, void>({
      providesTags: [ApproversTagTypes.approvers],
      query: () => ({
        url: "/account/approvers/v1/",
        method: "GET",
      }),
    }),
    addApproverV1: builder.mutation<void, AddApproverV1Params>({
      invalidatesTags: [
        ApproversTagTypes.approvers,
        // Adding approver can turn on the BreachSightNominateApprovers
        // or VendorRiskNominateApprovers flags
        OrganisationFlagsTags.orgFlags,
      ],
      query: ({ features, user_id, invite_id }) => ({
        url: "/account/approvers/v1/",
        method: "PUT",
        params: { features, user_id, invite_id },
      }),
      onQueryStarted(
        { features, ...userOrInvite },
        { dispatch, queryFulfilled }
      ) {
        const patchApprovers = dispatch(
          ApproversAPI.util.updateQueryData(
            "listApproversV1",
            undefined,
            (draft) => {
              draft.approvers.push(
                ...features.map((feature, index) => ({
                  ...userOrInvite,
                  id: -index, // Don't use any real IDs here
                  feature,
                }))
              );
            }
          )
        );

        const patchOrgFlags = dispatch(
          OrganisationFlagsAPI.util.updateQueryData(
            "getOrganisationFlagsV1",
            undefined,
            (draft) => {
              // Only patch org flags that are known to be touched by this request
              if (
                features.includes(
                  NominatedApproverFeature.BreachSightRiskWaivers
                )
              ) {
                draft[OrgFlagType.BreachSightNominateApprovers] = true;
              }

              if (
                features.includes(
                  NominatedApproverFeature.VendorRiskRiskAdjustments
                ) ||
                features.includes(
                  NominatedApproverFeature.VendorRiskRiskWaivers
                )
              ) {
                draft[OrgFlagType.VendorRiskNominateApprovers] = true;
              }
            }
          )
        );

        queryFulfilled.catch(() => {
          patchApprovers.undo();
          patchOrgFlags.undo();
        });
      },
    }),
    bulkAddApproversV1: builder.mutation<void, BulkAddApproversV1Params>({
      invalidatesTags: [
        ApproversTagTypes.approvers,
        // Adding approver can turn on the BreachSightNominateApprovers
        // or VendorRiskNominateApprovers flags
        OrganisationFlagsTags.orgFlags,
      ],
      query: ({ approvers }) => ({
        url: "/account/approvers/bulk/v1",
        method: "PUT",
        // Deliberately remove the extraneous properties from the request
        // The API itself only technically requires these few details.
        // The other properties that are included as part of the params
        // are to support optimistic update of the cache state to improve
        // the user experience.
        body: JSON.stringify({
          approvers: approvers.map(({ user_id, invite_id, features }) => ({
            user_id,
            invite_id,
            features,
          })),
        }),
      }),
      onQueryStarted({ approvers }, { dispatch, queryFulfilled }) {
        const patchApprovers = dispatch(
          ApproversAPI.util.updateQueryData(
            "listApproversV1",
            undefined,
            (draft) => {
              approvers.forEach(
                ({ features, ...userOrInvite }, approverIndex) => {
                  draft.approvers.push(
                    ...features.map((feature, featureIndex) => ({
                      ...userOrInvite,
                      id: -approverIndex - featureIndex, // Don't use any real IDs here, but still ensure uniqueness between approver and feature
                      feature,
                    }))
                  );
                }
              );
            }
          )
        );

        const patchOrgFlags = dispatch(
          OrganisationFlagsAPI.util.updateQueryData(
            "getOrganisationFlagsV1",
            undefined,
            (draft) => {
              // Only patch org flags that are known to be touched by this request
              if (
                approvers.some((a) =>
                  a.features.includes(
                    NominatedApproverFeature.BreachSightRiskWaivers
                  )
                )
              ) {
                draft[OrgFlagType.BreachSightNominateApprovers] = true;
              }

              if (
                approvers.some(
                  (a) =>
                    a.features.includes(
                      NominatedApproverFeature.VendorRiskRiskAdjustments
                    ) ||
                    a.features.includes(
                      NominatedApproverFeature.VendorRiskRiskWaivers
                    )
                )
              ) {
                draft[OrgFlagType.VendorRiskNominateApprovers] = true;
              }
            }
          )
        );

        queryFulfilled.catch(() => {
          patchApprovers.undo();
          patchOrgFlags.undo();
        });
      },
    }),
    deleteApproversV1: builder.mutation<void, DeleteApproversV1Params>({
      invalidatesTags: [
        ApproversTagTypes.approvers,
        // Deleting an approver can turn off the BreachSightNominateApprovers
        // or VendorRiskNominateApprovers flags
        OrganisationFlagsTags.orgFlags,
      ],
      query: ({ approver_ids }) => ({
        url: "/account/approvers/v1/",
        method: "DELETE",
        params: { approver_ids },
      }),
      onQueryStarted({ approver_ids }, { dispatch, queryFulfilled }) {
        let turnOffBreachSightNominateApproversFlag = false;
        let turnOffVendorRiskNominateApproversFlag = false;

        const patchApprovers = dispatch(
          ApproversAPI.util.updateQueryData(
            "listApproversV1",
            undefined,
            (draft) => {
              approver_ids.forEach((id) => {
                const index = draft.approvers.findIndex((a) => a.id === id);
                if (index >= 0) draft.approvers.splice(index, 1);
              });

              turnOffBreachSightNominateApproversFlag = !draft.approvers.some(
                (a) =>
                  a.feature === NominatedApproverFeature.BreachSightRiskWaivers
              );

              turnOffVendorRiskNominateApproversFlag = !draft.approvers.some(
                (a) =>
                  a.feature ===
                    NominatedApproverFeature.VendorRiskRiskAdjustments ||
                  a.feature === NominatedApproverFeature.VendorRiskRiskWaivers
              );
            }
          )
        );

        const patchOrgFlags = dispatch(
          OrganisationFlagsAPI.util.updateQueryData(
            "getOrganisationFlagsV1",
            undefined,
            (draft) => {
              // Only patch org flags that are known to be touched by this request
              if (turnOffBreachSightNominateApproversFlag) {
                draft[OrgFlagType.BreachSightNominateApprovers] = true;
              }

              if (turnOffVendorRiskNominateApproversFlag) {
                draft[OrgFlagType.VendorRiskNominateApprovers] = true;
              }
            }
          )
        );

        queryFulfilled.catch(() => {
          patchApprovers.undo();
          patchOrgFlags.undo();
        });
      },
    }),
  }),
});

export default ApproversAPI;
