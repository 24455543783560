import { FC, useEffect, useState } from "react";
import { sortBy as _sortBy } from "lodash";
import Button from "../../_common/components/core/Button";
import "../style/VendorSurveys.scss";
import ModalV2 from "../../_common/components/ModalV2";
import DragDropUpload from "../../_common/components/DragDropUpload";
import "../style/views/AdditionalEvidenceUpload.scss";
import DatePicker from "../../_common/components/DatePicker";
import {
  fetchVendorPortalAdditionalEvidenceRequestDetail,
  fetchVendorPortalAdditionalEvidenceRequestSummaries,
  vendorPortalUpdateAdditionalEvidenceRequest,
} from "../reducers/additionalEvidenceRequest.actions";
import {
  addDefaultSuccessAlert,
  addDefaultUnknownErrorAlert,
} from "../../_common/reducers/messageAlerts.actions";
import { AcceptedEvidenceFileExtensionsList } from "../../vendorrisk/views/AdditionalEvidenceDocumentsTab";
import moment from "moment";
import { useAppDispatch } from "../../_common/types/reduxHooks";
import {
  UserReadContentLibrary,
  useBasicPermissions,
} from "../../_common/permissions";
import ContentLibraryBrowserModal from "../../contentlibrary/components/ContentLibraryBrowserModal";
import { ContentLibraryDocument } from "../../contentlibrary/types/contentLibrary.types";
import { GetIconForFilename } from "../../vendorrisk/helpers/icons";
import IconButton, { HoverLocation } from "../../_common/components/IconButton";
import ContentLibraryAPI, {
  contentLibraryDocumentCacheTag,
  contentLibraryDocumentHistoryCacheTag,
} from "../../contentlibrary/api/contentLibraryAPI";
import {
  AcceptedDocumentFileExtensions,
  AcceptedDocumentMimeTypes,
  MaxFileSizeB,
  MaxFileSizeDisplay,
} from "../../_common/types/fileRestrictions";

interface AdditionalEvidenceUploadProps {
  active: boolean;
  onClose(didUpload?: boolean): void;
  evidenceId: number;
  documentType: string;
  requestingOrg: string;
}

const AdditionalEvidenceUpload: FC<AdditionalEvidenceUploadProps> = ({
  active,
  onClose,
  evidenceId,
  documentType,
  requestingOrg,
}) => {
  const perms = useBasicPermissions();
  const contentLibraryEnabled = !!perms.userPermissions[UserReadContentLibrary];
  const [fileToUpload, setFileToUpload] = useState<File | undefined>();
  const [contentLibraryDoc, setContentLibraryDoc] = useState<
    ContentLibraryDocument | undefined
  >();
  const [evidenceExpiryDate, setEvidenceExpiryDate] = useState<string>();
  const [saving, setSaving] = useState(false);
  const dispatch = useAppDispatch();

  const [submitModalOpen, setSubmitModalOpen] = useState(false);
  const [contentLibraryOpen, setContentLibraryOpen] = useState(false);

  useEffect(() => {
    if (active) {
      if (contentLibraryEnabled) {
        setContentLibraryOpen(true);
      } else {
        setSubmitModalOpen(true);
      }
    } else {
      setContentLibraryOpen(false);
      setSubmitModalOpen(false);
    }
  }, [active]);

  useEffect(() => {
    if (active && !contentLibraryOpen && !submitModalOpen) {
      onClose();
    }
  }, [contentLibraryOpen, submitModalOpen]);

  const fileUploadSubmit = () => {
    if (!fileToUpload && !contentLibraryDoc) return;
    setSaving(true);
    dispatch(
      vendorPortalUpdateAdditionalEvidenceRequest(
        evidenceId,
        evidenceExpiryDate ? moment(evidenceExpiryDate).format() : "",
        fileToUpload ?? null,
        contentLibraryDoc ?? null
      )
    )
      .then(() => {
        onClose(true);
        dispatch(
          addDefaultSuccessAlert(
            `${documentType ? documentType : "Document"} submitted for review`
          )
        );
        if (contentLibraryDoc) {
          dispatch(
            ContentLibraryAPI.util.invalidateTags([
              {
                type: contentLibraryDocumentCacheTag,
                id: contentLibraryDoc.uuid,
              },
              {
                type: contentLibraryDocumentHistoryCacheTag,
                id: contentLibraryDoc.uuid,
              },
            ])
          );
        }
        dispatch(fetchVendorPortalAdditionalEvidenceRequestSummaries());
        dispatch(fetchVendorPortalAdditionalEvidenceRequestDetail(evidenceId));

        setSaving(false);
        setFileToUpload(undefined);
      })
      .catch((e) => {
        console.error(e);
        dispatch(
          addDefaultUnknownErrorAlert(
            `An error has occurred while uploading your file.`
          )
        );
      })
      .finally(() => {
        setSaving(false);
      });
  };

  return (
    <>
      <ModalV2
        active={submitModalOpen}
        onClose={() => {
          setSubmitModalOpen(false);
        }}
        className={"attach-additional-evidence-document-modal"}
        headerContent={"Add document"}
        subHeaderContent={`Clicking "Submit for review" shares this document with users in ${
          requestingOrg ? requestingOrg : "the organization that requested it"
        }.`}
        footerContent={
          <>
            <Button
              tertiary
              onClick={() => {
                setFileToUpload(undefined);
                setSubmitModalOpen(false);
              }}
            >
              Cancel
            </Button>
            <Button
              filledPrimary
              disabled={!fileToUpload && !contentLibraryDoc}
              loading={saving}
              onClick={fileUploadSubmit}
            >
              Submit for review
            </Button>
          </>
        }
      >
        {!contentLibraryDoc && (
          <>
            <DragDropUpload
              selectedFile={fileToUpload}
              onFileSelected={(file) => {
                setFileToUpload(file);
              }}
              acceptedFileTypeFilters={[
                ...AcceptedDocumentFileExtensions,
                ...AcceptedDocumentMimeTypes,
              ]}
              clickText={"Click to import documents"}
              maxFileSize={MaxFileSizeB}
            ></DragDropUpload>
            <div className={"infoSection"}>
              <p>{`File requirements: Max ${MaxFileSizeDisplay}. ${AcceptedEvidenceFileExtensionsList}`}</p>
            </div>
          </>
        )}

        {contentLibraryDoc && (
          <>
            <div className={"content-library-doc"}>
              <div className={"type-icon"}>
                <img src={GetIconForFilename(contentLibraryDoc.name)} />
              </div>
              <div className="filename">{contentLibraryDoc.name}</div>
              <div className="actions">
                <IconButton
                  icon={<div className="icon-cycle" />}
                  onClick={() => {
                    setContentLibraryOpen(true);
                    setSubmitModalOpen(false);
                  }}
                  hoverText="Attach new version"
                  popupDelay={0}
                  hoverLocation={HoverLocation.Top}
                  hoverMicro
                />
              </div>
            </div>
          </>
        )}

        {(fileToUpload || contentLibraryDoc) && (
          <div className="form-inputs">
            <div className="form-input">
              <label htmlFor="documentType">Document type</label>
              <div className={"document-type"}>
                <input
                  type="text"
                  id="documentType"
                  value={documentType}
                  disabled
                />
                <IconButton
                  hoverText="This is the document type as specified by the organization making this document request. It cannot be changed."
                  icon={<div className="cr-icon-info" />}
                  hoverLocation={HoverLocation.Top}
                  popupWrap
                  onClick={() => {}}
                  className="type-info-icon"
                  disabled
                  popupDelay={0}
                />
              </div>
            </div>
            <div className="form-input">
              <label htmlFor="additionalEvidenceExpiry">
                Expiry date (optional)
              </label>
              <div className={"datePicker"}>
                <DatePicker
                  id={"additionalEvidenceExpiry"}
                  value={
                    evidenceExpiryDate
                      ? moment(evidenceExpiryDate).format("YYYY-MM-DD")
                      : ""
                  }
                  placeholder={"YYYY-MM-DD"}
                  onChange={(e) => setEvidenceExpiryDate(e.target.value)}
                  canClear
                />
              </div>
            </div>
          </div>
        )}
      </ModalV2>
      <ContentLibraryBrowserModal
        active={contentLibraryOpen}
        onClose={() => {
          setContentLibraryOpen(false);
        }}
        addedDocumentUUIDs={[]}
        onAddDocument={(doc: ContentLibraryDocument) => {
          setContentLibraryDoc(doc);
          setFileToUpload(undefined);
          setSubmitModalOpen(true);
          setContentLibraryOpen(false);
        }}
        onUploadDocumentWithoutWritePerm={async (file: File) => {
          setFileToUpload(file);
          setContentLibraryDoc(undefined);
          setSubmitModalOpen(true);
          setContentLibraryOpen(false);
        }}
      />
    </>
  );
};

export default AdditionalEvidenceUpload;
