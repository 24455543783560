import * as React from "react";
import ColorCheckbox from "./ColorCheckbox";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import "../style/components/RemediationDetailsExportOptions.scss";
import { ExportFiletype } from "../../_common/types/exportReport";

export type RemediationDetailsExportOptionsAction =
  | "risks"
  | "risk_details"
  | "assets"
  | "unremediated"
  | "partial"
  | "waived"
  | "remediated"
  | "timeline";

export interface RemediationDetailsExportOptions {
  include_risks: boolean;
  include_risk_details: boolean;
  include_asset_details: boolean;
  include_unremediated: boolean;
  include_partial: boolean;
  include_waived: boolean;
  include_remediated: boolean;
  include_timeline: boolean;
}

export const InitRemediationDetailsExportOptions =
  (): RemediationDetailsExportOptions => ({
    include_risks: true,
    include_risk_details: true,
    include_asset_details: false,
    include_unremediated: true,
    include_partial: true,
    include_waived: true,
    include_remediated: true,
    include_timeline: false,
  });

export const ReduceRemediationDetailsExportOptions = (
  state: RemediationDetailsExportOptions,
  action: RemediationDetailsExportOptionsAction
): RemediationDetailsExportOptions => {
  const options = { ...state };
  switch (action) {
    case "risks":
      options.include_risks = !options.include_risks;
      break;
    case "risk_details":
      options.include_risk_details = !options.include_risk_details;
      break;
    case "assets":
      options.include_asset_details = !options.include_asset_details;
      break;
    case "unremediated":
      options.include_unremediated = !options.include_unremediated;
      break;
    case "partial":
      options.include_partial = !options.include_partial;
      break;
    case "waived":
      options.include_waived = !options.include_waived;
      break;
    case "remediated":
      options.include_remediated = !options.include_remediated;
      break;
    case "timeline":
      options.include_timeline = !options.include_timeline;
      break;
  }
  return options;
};

interface RemediationDetailsExportOptionsProps {
  state: RemediationDetailsExportOptions;
  dispatch: React.Dispatch<RemediationDetailsExportOptionsAction>;
  selectedFiletype: ExportFiletype;
}

const RemediationDetailsExportOptions: React.VFC<
  RemediationDetailsExportOptionsProps
> = ({ state, dispatch, selectedFiletype }) => {
  const updateState = (action: RemediationDetailsExportOptionsAction) => () =>
    dispatch(action);

  return (
    <>
      <div className={"left grid-section"}>
        <h3>Select which sections you would like to export</h3>
        <p>What sections of the remediation report do you want to export?</p>
      </div>
      <div className={"right grid-section remediation-details-export-options"}>
        <ColorCheckbox
          checked={state.include_risks}
          onClick={updateState("risks")}
          label={"Include risks"}
        />
        <TransitionGroup component={null}>
          {state.include_risks && (
            <CSSTransition key={"risks"} classNames={"expand"} timeout={250}>
              <div className={"risks-subsection"}>
                <ColorCheckbox
                  checked={state.include_unremediated}
                  onClick={updateState("unremediated")}
                  label={"Unremediated"}
                />
                <ColorCheckbox
                  checked={state.include_partial}
                  onClick={updateState("partial")}
                  label={"Partially remediated"}
                />
                <ColorCheckbox
                  checked={state.include_waived}
                  onClick={updateState("waived")}
                  label={"Waived"}
                />
                <ColorCheckbox
                  checked={state.include_remediated}
                  onClick={updateState("remediated")}
                  label={"Remediated"}
                />
                <div className={"seperator"} />
                {selectedFiletype === ExportFiletype.PDF ? (
                  <>
                    <ColorCheckbox
                      checked={state.include_risk_details}
                      onClick={updateState("risk_details")}
                      label={"Include risk long description"}
                    />
                    <ColorCheckbox
                      checked={
                        state.include_asset_details &&
                        state.include_risk_details
                      }
                      onClick={updateState("assets")}
                      label={"Include asset details for each risk"}
                      disabled={!state.include_risk_details}
                    />
                  </>
                ) : (
                  <>
                    <ColorCheckbox
                      checked={state.include_asset_details}
                      onClick={updateState("assets")}
                      label={"Include asset details for each risk"}
                    />
                  </>
                )}
              </div>
            </CSSTransition>
          )}
        </TransitionGroup>
        {selectedFiletype === ExportFiletype.PDF && (
          <ColorCheckbox
            checked={state.include_timeline}
            onClick={updateState("timeline")}
            label={"Timeline"}
          />
        )}
      </div>
    </>
  );
};

export default RemediationDetailsExportOptions;
