import { FC, ReactNode, useLayoutEffect, useRef, useState } from "react";
import MainSection, { MainSectionProps } from "./MainSection";
import MetadataSection, { MetadataSectionProps } from "./MetadataSection";
import InfoBarSection, { InfoBarSectionProps } from "./InfoBarSection";
import PageviewTracker from "../../../vendorrisk/components/PageviewTracker";
import "../../style/components/pageHeader/PageHeaderV2.scss";
import PageTitleSection, { PageTitleSectionProps } from "./PageTitleSection";

interface PageHeaderV2OwnProps {
  // optional class to apply to the header
  className?: string;

  // Optional component to mount above the main page heading
  aboveHeadingOverride?: ReactNode;
}

export type PageHeaderV2Props = PageHeaderV2OwnProps &
  MainSectionProps &
  MetadataSectionProps &
  InfoBarSectionProps &
  PageTitleSectionProps;

const PageHeaderV2: FC<PageHeaderV2Props> = ({
  className = "",
  name = "",
  domain = "",
  nameWidgets,
  actionGroups,
  metadataClassName,
  metadataWidgets,
  infoBarText,
  infoBarColour,
  aboveHeadingOverride,
  breadcrumbs,
  title,
  backAction,
  backText,
  rightSection,
  infoSection,
  hideInfoSectionButtons,
  infoSectionPageKey,
  infoSectionButtons,
}) => {
  const pageHeaderRef = useRef<HTMLDivElement>(null);
  const [headerHeight, setHeaderHeight] = useState(0);

  useLayoutEffect(() => {
    let observer: ResizeObserver;
    if (pageHeaderRef.current) {
      setHeaderHeight(pageHeaderRef.current.getBoundingClientRect().height);

      observer = new ResizeObserver((entries) => {
        if (entries.length > 0) {
          const height = entries[0].contentRect.height;
          setHeaderHeight(height);
        }
      });

      observer.observe(pageHeaderRef.current, {});
    }

    return () => {
      if (observer) {
        observer.disconnect();
      }
    };
  }, []);

  return (
    <div
      className="ug-page-header-outer"
      style={{ height: `${headerHeight}px` }}
    >
      <div className={`ug-page-header ${className}`} ref={pageHeaderRef}>
        {name && (
          <div className="header-section">
            <div className="header-section-inner">
              <MainSection
                name={name}
                domain={domain}
                nameWidgets={nameWidgets}
                actionGroups={actionGroups}
              />
              {!!metadataWidgets && (
                <MetadataSection
                  metadataClassName={metadataClassName}
                  metadataWidgets={metadataWidgets}
                />
              )}
            </div>
          </div>
        )}
        <InfoBarSection
          infoBarText={infoBarText}
          infoBarColour={infoBarColour}
        />
        {aboveHeadingOverride}
        <PageTitleSection
          breadcrumbs={breadcrumbs}
          title={title}
          backAction={backAction}
          backText={backText}
          rightSection={rightSection}
          infoSection={infoSection}
          infoSectionButtons={infoSectionButtons}
          hideInfoSectionButtons={hideInfoSectionButtons}
          infoSectionPageKey={infoSectionPageKey}
        />
      </div>
      <PageviewTracker />
    </div>
  );
};

export default PageHeaderV2;
