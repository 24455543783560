import {
  DefaultThunkDispatch,
  ICyberRiskState,
} from "../../_common/types/redux";
import { DefaultRootState } from "react-redux";
import { FetchCyberRiskUrl } from "../../_common/api";
import { LogError } from "../../_common/helpers";
import { IPAddress, IPRange } from "../../_common/types/ipAddresses";
import {
  ipAddressesState,
  setCustomerIPDetails,
  setVendorIPDetails,
} from "./ipAddresses.actions";
import { setCustomerData, setVendorData } from "./cyberRiskActions";
import { conditionalRefreshActivityStreamForOrgUser } from "../../_common/reducers/commonActions";

export interface IpRangeToUpdate {
  start: string;
  end: string;
}

export interface IpLabelUpdateRequest {
  labelsToAdd?: number[];
  labelsToRemove?: number[];
  ipRanges: IpRangeToUpdate[];
  datastoreVendorId?: number;
  isSubsidiary?: boolean;
}

export const updateIpLabels = (updateRequest: IpLabelUpdateRequest) => {
  return async (
    dispatch: DefaultThunkDispatch,
    getState: () => DefaultRootState
  ): Promise<void> => {
    try {
      await FetchCyberRiskUrl<void>(
        "iplabels/v1/",
        {},
        {
          method: "POST",
          body: JSON.stringify(updateRequest),
        },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("error creating vendor risk waiver", e);

      throw e;
    }

    // kick off call to update the activity stream
    dispatch(conditionalRefreshActivityStreamForOrgUser());
  };
};

export const setIpAddressAndIpAddressDetailState = (
  ipAddress: IPAddress,
  vendorId: number | undefined,
  isSubsidiary = false
) => {
  return (
    dispatch: DefaultThunkDispatch,
    getState: () => DefaultRootState
  ): void => {
    // First update the addresses state
    const currentState = getCurrentIpAddressesAndRangesState(
      getState().cyberRisk,
      vendorId,
      isSubsidiary
    );

    // Get all addresses except the updated one
    let ipAddresses = currentState?.ipAddresses?.filter(
      (ip) => ip.ip !== ipAddress.ip
    );

    if (!ipAddresses) {
      ipAddresses = [];
    }

    ipAddresses.push(ipAddress);
    dispatch(
      setIpAddressesAndRangesState(
        vendorId,
        isSubsidiary,
        undefined,
        ipAddresses
      )
    );

    // Now, update the addresses detail state
    const currentDetailsState = getCurrentIpAddressDetailState(
      getState().cyberRisk,
      vendorId,
      isSubsidiary
    );

    if (!currentDetailsState) {
      return;
    }

    const ipAddressDetail = currentDetailsState[ipAddress.ip];
    const newDetails = {
      ...ipAddressDetail,
      ...ipAddress,
    };

    if (!vendorId) {
      dispatch(setCustomerIPDetails(newDetails));
    } else {
      dispatch(setVendorIPDetails(vendorId, isSubsidiary, newDetails));
    }
  };
};

export const setIpRangeState = (
  ipRange: IPRange,
  vendorId: number | undefined,
  isSubsidiary = false
) => {
  return (
    dispatch: DefaultThunkDispatch,
    getState: () => DefaultRootState
  ): void => {
    const currentState = getCurrentIpAddressesAndRangesState(
      getState().cyberRisk,
      vendorId,
      isSubsidiary
    );

    // Get all except the updated range
    let ipRanges = currentState?.ipRanges?.filter((r) =>
      ipRange.customID ? r.customID !== ipRange.customID : r.id !== ipRange.id
    );

    if (!ipRanges) {
      ipRanges = [];
    }

    ipRanges.push(ipRange);
    dispatch(setIpAddressesAndRangesState(vendorId, isSubsidiary, ipRanges));
  };
};

export const setIpAddressesAndRangesState = (
  vendorId: number | undefined = undefined,
  isSubsidiary = false,
  ipRanges: IPRange[] | undefined = undefined,
  ipAddresses: IPAddress[] | undefined = undefined
) => {
  return (
    dispatch: DefaultThunkDispatch,
    getState: () => DefaultRootState
  ): void => {
    const newState = buildNewIpAddressesState(
      getState().cyberRisk,
      vendorId,
      isSubsidiary,
      ipRanges,
      ipAddresses
    );

    if (vendorId) {
      dispatch(
        setVendorData(
          vendorId,
          {
            ipAddresses: newState,
          },
          isSubsidiary
        )
      );
    } else {
      dispatch(
        setCustomerData({
          ipAddresses: newState,
        })
      );
    }
  };
};

// Build a new derived ip address and ip ranges state object
const buildNewIpAddressesState = (
  state: ICyberRiskState,
  vendorId: number | undefined = undefined,
  isSubsidiary = false,
  ipRanges: IPRange[] | undefined = undefined,
  ipAddresses: IPAddress[] | undefined = undefined
) => {
  const currentState = getCurrentIpAddressesAndRangesState(
    state,
    vendorId,
    isSubsidiary
  );

  if (!currentState) {
    return currentState;
  }

  const newRanges = ipRanges ?? currentState.ipRanges;
  const newAddresses = ipAddresses ?? currentState.ipAddresses;

  return {
    ...currentState,
    ipRanges: newRanges,
    ipAddresses: newAddresses,
  } as ipAddressesState;
};

// Get the derived ip address and ip ranges state object
const getCurrentIpAddressesAndRangesState = (
  state: ICyberRiskState,
  vendorId: number | undefined = undefined,
  isSubsidiary = false
) => {
  return vendorId && isSubsidiary
    ? state.subsidiaries[vendorId]?.ipAddresses
    : vendorId
      ? state.vendors[vendorId]?.ipAddresses
      : state.customerData.ipAddresses;
};

// Get the ip details state objects
const getCurrentIpAddressDetailState = (
  state: ICyberRiskState,
  vendorId: number | undefined = undefined,
  isSubsidiary = false
) => {
  return vendorId && isSubsidiary
    ? state.subsidiaries[vendorId]?.ipAddressDetails
    : vendorId
      ? state.vendors[vendorId]?.ipAddressDetails
      : state.customerData.ipAddressDetails;
};
