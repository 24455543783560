import { FC } from "react";
import userbaseApi from "../api/userbase.api";
import XTable, {
  IXTableColumnHeader,
  IXTableRow,
  SortDirection,
  XTableCell,
} from "../../_common/components/core/XTable";
import { useSortingWithPagination } from "../../_common/hooks";
import { formatDateAsLocal } from "../../_common/helpers";
import { SaaSUser } from "../api/types";
import TeamsPills from "./TeamsPills";
import { WaivedAssets } from "../../vendorrisk/reducers/risks.actions";
import PillLabel from "../../vendorrisk/components/PillLabel";
import { LabelColor } from "../../_common/types/label";
import { matchPath, useLocation, useHistory } from "react-router-dom";
import { userUrlPrefix } from "../UserBaseAppRouter";
import { UserRouteParams } from "../UserBaseNavItems";
import "./RiskSaaSUsers.scss";

const PAGE_SIZE = 100;

const NAME_COL = "name";
const ROLE_COL = "role";
const TEAM_COL = "team";
const FIRST_DETECTED_COL = "first_detected";
const STATUS_COL = "status";

interface RiskSaaSUsersProps {
  riskId: string;
  showWaived?: boolean;
  waivedAssets?: WaivedAssets;
  uuidsOfInRemediationUsers?: string[];
}

const RiskSaaSUsers: FC<RiskSaaSUsersProps> = ({
  riskId,
  showWaived,
  waivedAssets,
  uuidsOfInRemediationUsers,
}) => {
  const { data, isLoading } = userbaseApi.useGetUserBaseRiskUsersV1Query({
    riskId,
  });

  const history = useHistory();

  const { pathname, search } = useLocation();
  const selectedUserUUID = matchPath<UserRouteParams>(
    pathname,
    `${userUrlPrefix}/:userUUID/risk_profile`
  )?.params.userUUID;

  const columnHeaders: IXTableColumnHeader[] = [
    {
      id: NAME_COL,
      text: "Name",
      sortable: true,
    },
    {
      id: ROLE_COL,
      text: "Role",
      sortable: true,
    },
    {
      id: TEAM_COL,
      text: "Team",
      sortable: true,
    },
    {
      id: FIRST_DETECTED_COL,
      text: "First Detected",
      sortable: true,
    },
    {
      id: STATUS_COL,
      text: "Status",
      sortable: false,
    },
  ];

  const filteredUsers =
    data?.users
      .filter((u) => !selectedUserUUID || u.uuid === selectedUserUUID)
      .filter((u) => {
        if (showWaived) {
          return true;
        }

        return waivedAssets?.assetsWaived[u.uuid] === undefined;
      }) ?? [];

  const [
    usersToDisplay,
    sortedBy,
    onSortChange,
    currentPage,
    totalPages,
    onPageChange,
  ] = useSortingWithPagination<
    SaaSUser,
    | typeof NAME_COL
    | typeof ROLE_COL
    | typeof TEAM_COL
    | typeof FIRST_DETECTED_COL
  >(
    filteredUsers,
    NAME_COL,
    SortDirection.ASC,
    {
      [NAME_COL]: {
        orderFuncs: [
          (u) =>
            u.name ? u.name.toLocaleLowerCase() : u.email.toLocaleLowerCase(),
        ],
        sortDirsAsc: [SortDirection.ASC],
        sortDirsDesc: [SortDirection.DESC],
      },
      [ROLE_COL]: {
        orderFuncs: [
          (u) =>
            (u.roles ?? [])
              .map((r) => r.toLocaleLowerCase())
              .sort((a, b) => a.localeCompare(b))
              .join(","),
        ],
        sortDirsAsc: [SortDirection.ASC],
        sortDirsDesc: [SortDirection.DESC],
      },
      [TEAM_COL]: {
        orderFuncs: [
          (u) =>
            (u.teams ?? [])
              .map((t) => t.toLocaleLowerCase())
              .sort((a, b) => a.localeCompare(b))
              .join(","),
        ],
        sortDirsAsc: [SortDirection.ASC],
        sortDirsDesc: [SortDirection.DESC],
      },
      [FIRST_DETECTED_COL]: {
        orderFuncs: [(u) => u.firstDetected],
        sortDirsAsc: [SortDirection.ASC],
        sortDirsDesc: [SortDirection.DESC],
      },
    },
    PAGE_SIZE,
    undefined,
    pathname
  );

  const saasUserLinkUrl = (uuid: string): string => {
    const queryParams = search.replace(/^\?/, "").split("&");
    queryParams.push(`saasUser=${uuid}`);
    return `${pathname}?${queryParams.join("&")}`;
  };

  const rows = usersToDisplay.map<IXTableRow>((u) => {
    const isWaived = waivedAssets?.assetsWaived[u.uuid] !== undefined;
    const isPendingWaived =
      waivedAssets?.assetsPendingWaiver[u.uuid] !== undefined;
    const isInRemediation =
      uuidsOfInRemediationUsers && uuidsOfInRemediationUsers.includes(u.uuid);
    return {
      id: u.email,
      cells: [
        <XTableCell key={NAME_COL}>
          <span
            className={"saas-user-link"}
            onClick={() => {
              history.push(saasUserLinkUrl(u.uuid), {
                sidePanelBackText: "Back to risk details",
              });
            }}
          >
            {u.name || u.email}
          </span>
        </XTableCell>,
        <XTableCell key={ROLE_COL}>
          {u.roles ? u.roles.join(",") : "-"}
        </XTableCell>,
        <XTableCell key={TEAM_COL}>
          {u.teams ? <TeamsPills teams={u.teams} /> : "-"}
        </XTableCell>,
        <XTableCell key={FIRST_DETECTED_COL}>
          {u.firstDetected ? formatDateAsLocal(u.firstDetected) : "-"}
        </XTableCell>,
        <XTableCell key={STATUS_COL}>
          {isWaived && <PillLabel>Waived</PillLabel>}
          {isPendingWaived && (
            <PillLabel color={LabelColor.Orange}>Waiver pending</PillLabel>
          )}
          {isInRemediation && (
            <PillLabel color={LabelColor.Blue}>In Remediation</PillLabel>
          )}
        </XTableCell>,
      ],
    };
  });

  return (
    <>
      <XTable
        stickyColumnHeaders={false}
        numLoadingRows={3}
        className={"saas-users-container"}
        columnHeaders={columnHeaders}
        rows={rows}
        loading={isLoading}
        iconOptions
        sortedBy={sortedBy}
        onSortChange={onSortChange}
        pagination={{
          currentPage: currentPage,
          totalPages: totalPages,
          onPageChange: onPageChange,
          hidePaginationIfSinglePage: true,
        }}
      />
    </>
  );
};

export default RiskSaaSUsers;
