import { ICloudscanCheckAndWebsite } from "../../reducers/remediation.actions";
import { Reducer } from "react";

export type CloudscanCheckAndWebsiteAction =
  | { type: "init" }
  | { type: "selectAll"; checkId: string; selectAll: boolean }
  | { type: "removeCheckID"; checkId: string }
  | { type: "setCheckWebsites"; checkId: string; websites: string[] }
  | {
      type: "setSelectedCVEs";
      checkId: string;
      selectedCVEs: string[] | null;
      websites?: string[];
      selectAll?: boolean;
    };

export type ICloudscanCheckAndWebsiteReducer = Reducer<
  Record<string, ICloudscanCheckAndWebsite>,
  CloudscanCheckAndWebsiteAction
>;

export const SelectCloudscanCheckAndWebsite = (
  store: Record<string, ICloudscanCheckAndWebsite>,
  id: string
): ICloudscanCheckAndWebsite => {
  return (
    store[id] ?? {
      checkId: "",
      websites: [],
      selectAll: false,
    }
  );
};

export const CloudscanCheckAndWebsiteReducer = (
  state: Record<string, ICloudscanCheckAndWebsite>,
  action: CloudscanCheckAndWebsiteAction
): Record<string, ICloudscanCheckAndWebsite> => {
  const newState = { ...state };
  switch (action.type) {
    case "init":
      return {};
    case "selectAll":
      if (action.selectAll) {
        if (!newState[action.checkId]) {
          newState[action.checkId] = {
            checkId: action.checkId,
            websites: [],
            selectAll: true,
          };
        } else {
          newState[action.checkId] = {
            ...newState[action.checkId],
            selectAll: true,
            websites: [],
          };
        }
      } else {
        delete newState[action.checkId];
      }
      return newState;
    case "setCheckWebsites":
      if (!newState[action.checkId]) {
        newState[action.checkId] = {
          checkId: action.checkId,
          websites: action.websites,
          selectAll: false,
        };
      } else {
        newState[action.checkId] = {
          ...newState[action.checkId],
          selectAll: false,
          websites: action.websites,
        };
      }

      // Only remove the cehck if there is no CVEs selected
      if (action.websites.length === 0 && !newState[action.checkId].metadata) {
        delete newState[action.checkId];
      }

      return newState;
    case "removeCheckID":
      delete newState[action.checkId];
      return newState;
    case "setSelectedCVEs":
      if (!newState[action.checkId]) {
        newState[action.checkId] = {
          checkId: action.checkId,
          websites: action.websites ?? [],
          selectAll: action.selectAll ?? false,
          metadata: {
            CVENames: action.selectedCVEs,
          },
        };
      } else {
        newState[action.checkId] = {
          ...newState[action.checkId],
          metadata:
            !action.selectedCVEs || action.selectedCVEs.length !== 0
              ? {
                  CVENames: !action.selectedCVEs ? null : action.selectedCVEs,
                }
              : undefined,
          websites: action.websites ?? newState[action.checkId].websites,
          selectAll: action.selectAll ?? newState[action.checkId].selectAll,
        };
      }
      return newState;
    default:
      return newState;
  }
};
