import { VendorAssessmentListItem } from "../../reducers/vendorAssessmentAPI";
import { FC } from "react";
import SlidePanel from "../../../_common/components/SlidePanel";
import ScrollableDiv from "../ScrollableDiv";
import IconButton, {
  HoverLocation,
} from "../../../_common/components/IconButton";
import { formatDate } from "../../../_common/helpers";
import classnames from "classnames";

interface VendorAssessmentsV3VersionsSideBarProps {
  assessmentList: VendorAssessmentListItem[];
  active: boolean;
  onClose: () => void;
  onOpenVersion: (streamId: number, versionId: number) => void;
  onDeleteVersion?: (versionId: number) => void;
  selectedVersion?: number;
}

const VendorAssessmentsV3VersionsSideBar: FC<
  VendorAssessmentsV3VersionsSideBarProps
> = (props) => {
  return (
    <SlidePanel
      onClose={props.onClose}
      active={props.active}
      className={"versions-slide-panel-3"}
      newStyles
      dimContent
      title={"Version history"}
    >
      <ScrollableDiv newStyles>
        {props.assessmentList.map((item) => (
          <div
            className={classnames("assessment-version", {
              selected: item.id == props.selectedVersion,
            })}
            key={item.id}
          >
            <IconButton
              onClick={() => props.onOpenVersion(item.streamID, item.id)}
              icon={<i className={"cr-icon-chevron rotate-180"} />}
              hoverText={"View"}
              hoverLocation={HoverLocation.Left}
              hoverMicro
            />
            <div className={"center-col"}>
              <div className={"assessment-name"}>{item.name}</div>
              <div className={"date"}>
                Version {item.version} •{" "}
                {item.publishedAt ? formatDate(item.publishedAt) : "Draft"}
              </div>
              <div className={"author-name"}>{item.authorName}</div>
            </div>
            {!!props.onDeleteVersion && !item.managedAssessmentID && (
              <IconButton
                className={"delete-button"}
                icon={<i className={"cr-icon-trash"} />}
                onClick={() => {
                  if (props.onDeleteVersion) {
                    props.onDeleteVersion(item.id);
                  }
                }}
              />
            )}
          </div>
        ))}
      </ScrollableDiv>
    </SlidePanel>
  );
};

export default VendorAssessmentsV3VersionsSideBar;
