import { IPSource } from "../../../_common/types/ipAddresses";
import { FC } from "react";
import IpSourceLabel from "./IpSourceLabel";

interface IpSourceLabelListlProps {
  sources: IPSource[];
  isCustomer: boolean;
  isRanges?: boolean;
}

const IpSourceLabelList: FC<IpSourceLabelListlProps> = ({
  sources,
  isCustomer,
  isRanges,
}) => {
  return (
    <div className={"ip-source-label-list"}>
      {sources.map((s) => (
        <IpSourceLabel
          key={s}
          source={s}
          isCustomer={isCustomer}
          isRange={isRanges}
        />
      ))}
    </div>
  );
};

export default IpSourceLabelList;
