import { History } from "history";
import { useState } from "react";
import { XTableCell } from "../../../_common/components/core/XTable";
import { IXTableColumnHeader } from "../../../_common/components/core/XTable";
import { OpenSidePanel } from "../DomainsAndIPsPanelGroup";
import { DetectedProduct } from "../../../_common/types/detectedProducts";
import moment from "moment";
import PaginatedTable from "../PaginatedTable";

interface IDetectedProductInfoTableProps {
  history: History;
  product: DetectedProduct;
  className?: string;
}

const DetectedProductInfoTable = (props: IDetectedProductInfoTableProps) => {
  const pageSize = 10;
  const [currentPage, setCurrentPage] = useState(0);
  const columnHeaders: IXTableColumnHeader[] = [
    {
      id: "domains",
      text: "Domains & IPs",
      className: "title-header",
    },
    {
      id: "version",
      text: "Version",
      className: "column-header",
    },
    {
      id: "detected",
      text: "First detected",
      className: "column-header",
    },
  ];

  return (
    <PaginatedTable
      columnHeaders={columnHeaders}
      className={props.className}
      pageSize={pageSize}
      page={currentPage}
      onPageChange={(p: number) => setCurrentPage(p - 1)}
      rows={
        props.product?.hosts
          ? props.product.hosts.map((host) => {
              return {
                id: host.hostname_id,
                onClick: () =>
                  OpenSidePanel(
                    props.history,
                    {
                      scan: host.hostname,
                    },
                    "Product Info"
                  ),
                cells: [
                  <XTableCell className={"domain-cell"} key="hostname">
                    {host.hostname}
                  </XTableCell>,
                  <XTableCell className={"domain-cell"} key="version">
                    {host.version}
                  </XTableCell>,
                  <XTableCell className={"domain-cell"} key="detected">
                    {moment(host.created_at).format("DD MMM YYYY")}
                  </XTableCell>,
                  <XTableCell className={"chevron-cell"} key={"chevron"}>
                    <span className={"cr-icon-chevron"} />
                  </XTableCell>,
                ],
              };
            })
          : []
      }
    />
  );
};

export default DetectedProductInfoTable;
