import { debounce as _debounce } from "lodash";
import Button from "../../_common/components/core/Button";
import { Component } from "react";

import { trackEvent } from "../../_common/tracking";

import {
  fetchOrgIntegrationSettings,
  fetchAvailableLabels,
  deleteIntegration,
  fetchOrgLogo,
  fetchOrganisationFlags,
  setOrgLogo,
} from "../reducers/cyberRiskActions";
import {
  updateIntegration,
  enableIntegration,
  createIntegration,
  TestWebhookV1Resp,
} from "../reducers/integrations.actions";
import {
  fetchOAuth2MessagingServiceChannelList,
  populateLiquidMessageForNotificationUUID,
  fetchJiraProjectList,
  fetchExistingOAuthConnections,
  fetchJiraIssueTypesList,
} from "../reducers/oauth.actions";
import {
  getJiraAllIssueTypesData,
  getJiraProjectData,
  getMessagingIntegrationPopulatedTemplates,
  getOAuth2MessagingChannelData,
  getOAuthConnectionData,
  IntegrationPopulatedMessages,
  JiraIssueTypes,
} from "../reducers/oauth.selectors";

import "../style/views/CreateIntegration.scss";
import { openModal } from "../../_common/reducers/commonActions";
import StepsWithSections, {
  Steps,
} from "../../_common/components/StepsWithSections";
import Icon from "../../_common/components/core/Icon";
import InfoBanner, { BannerType } from "../components/InfoBanner";
import { ConfirmationModalName } from "../../_common/components/modals/ConfirmationModal";
import {
  addDefaultSuccessAlert,
  addDefaultUnknownErrorAlert,
  addMessageAlert,
} from "../../_common/reducers/messageAlerts.actions";
import { fetchVendorTiers, VendorTier } from "../reducers/vendorTiers.actions";
import PageHeader from "../../_common/components/PageHeader";

// integration workflow steps
import SelectTriggersStep from "../components/integrations/SelectTriggersStep";
import SelectWebhookDestinationStep from "../components/integrations/SelectWebhookDestinationStep";
import EnableIntegrationStep from "../components/integrations/EnableIntegrationStep";
import ConfigureWebhookPayloadsStep from "../components/integrations/ConfigureWebhookPayloadsStep";

import {
  SLACK_INTEGRATION_TYPE,
  WEBHOOK_INTEGRATION_TYPE,
  JIRA_INTEGRATION_TYPE,
} from "../components/OrgIntegrationsConfig";
import LoadingIcon from "../../_common/components/core/LoadingIcon";
import ConfirmationModalV2 from "../../_common/components/modals/ConfirmationModalV2";
import SelectSlackDestinationStep, {
  ISlackChannelData,
} from "../components/integrations/SelectSlackDestinationStep";
import ConfigureSlackPayloadsStep from "../components/integrations/ConfigureSlackMessageFormatsStep";
import SelectJiraDestinationStep from "../components/integrations/SelectJiraDestinationStep";
import ConfigureJiraIssueFieldsStep, {
  DESCRIPTION_FIELD,
  ISSUE_TYPE_FIELD,
  SUMMARY_FIELD,
} from "../components/integrations/ConfigureJiraIssueFieldsStep";

import {
  hasActiveConnection,
  getJiraTokenSiteName,
} from "../components/OrgIntegrationsConfig";

import { JIRA_SERVICE, SLACK_SERVICE } from "./OAuth2Callback";
import {
  DeserialiseJiraFieldValue,
  IJiraField,
  IJiraIssueType,
  SerialiseJiraFieldValue,
} from "../../_common/types/jira";
import { ILabel } from "../../_common/types/label";
import { DefaultRouteProps } from "../../_common/types/router";
import {
  IBasicAuth,
  IIntegration,
  INameValuePair,
  IntegrationTypes,
  IntegrationTypeString,
  IntegrationTypeStrings,
  IStringMap,
  JiraProjectsList,
  verifyIntegrationTypeString,
} from "../../_common/types/integration";
import { DefaultThunkDispatchProp } from "../../_common/types/redux";
import { INotificationConfigCategory } from "../../_common/types/notifications";
import { OptionType } from "../../_common/components/SelectV2";
import { OAuthState } from "../../_common/types/oauth";
import SelectEmailIntegrationStep from "../components/integrations/SelectEmailIntegrationStep";
import {
  OrgAccessVendors,
  OrgCustomLogo,
  UserVendorRiskEnabled,
} from "../../_common/permissions";
import {
  fetchOrgSenderEmail,
  OrganisationSenderEmailV1Resp,
} from "../reducers/orgEmailSenderSettings.actions";
import { validateEmail } from "../../_common/helpers";
import ConfigureEmailPayloadsStep from "../components/integrations/ConfigureEmailPayloadsStep";
import {
  fetchAllOrgNotifications,
  fetchOrgNotificationSettings,
  IOrgAlertDefinition,
} from "../reducers/org.actions";
import { AssuranceType } from "../../_common/types/organisations";
import { fetchVendorAttributeDefinitions } from "../reducers/vendorAttributes.actions";
import { appConnect } from "../../_common/types/reduxHooks";
import { validateUrl } from "../helpers/util";

const MINUTES_SESSION_TIMEOUT_WARNING = 27;
export const MESSAGING_SERVICE_SLACK = "Slack";
export const MESSAGING_SERVICE_EMAIL = "Email";

export const liquidSyntaxGuideURL =
  "https://help.upguard.com/en/articles/5777453-using-liquid-syntax-to-customize-your-integration";

export const supportArticleURL =
  "https://help.upguard.com/en/articles/4205928-how-to-integrate-upguard-with-other-services-using-webhooks";

interface collectedIntegrationAttributes {
  webhookID: number;
  webhookURL: string;
  ignoreSSLCertCheck: boolean;
  headerParams: INameValuePair[];
  urlParams: INameValuePair[];
  basicAuthParams?: IBasicAuth;
  zapierConnected: boolean;
  selectedUUIDs: { [key: string]: boolean };
  selectedChannel?: string;
  jiraProject?: { name: string; id: string };
  jiraIssueValues: { [uuid: string]: JiraIssueValue };
  emailDestination?: {
    toAddress: string;
    fromName: string;
    fromAddress?: string;
  };
}

export type WebhookParamType = "urlParams" | "headerParams";

interface WebhookSubState {
  basicAuth?: IBasicAuth;
  ignoreSSLCertCheck: boolean;
  webhookID: number;
  webhookURL: string;
}

type WebhookSubStateWithParams = {
  [key in WebhookParamType]: INameValuePair[];
} & WebhookSubState;

type WebhookState = {
  [key in IntegrationTypeString]: WebhookSubStateWithParams;
};

interface EmailSubState {
  toAddress: string;
  fromName: string;
  fromAddress?: string;
}

type EmailState = {
  [key in IntegrationTypeString]: EmailSubState;
};

interface MessagingServiceSubState {
  channel: string;
}

type MessagingServiceState = {
  [key in IntegrationTypeString]: MessagingServiceSubState;
};

interface IJiraIssueValue {
  IssueType: string;
  assignee: string;
  description?: string;
  reporter: string;
  summary: string;
}

type JiraIssueValue = { [customField: string]: string } & IJiraIssueValue;

interface CreateIntegrationState {
  id?: number;
  currentStep: number;
  integrationType: IntegrationTypeString; // TODO
  description?: string;
  uniqueId?: string;
  enabled: boolean;
  selectedUUIDs: { [key: string]: boolean };
  zapierConnected: boolean;
  payloadTemplates: IStringMap; // TODO
  submitLoading: boolean;
  selectedTestUUID: string;
  selectedTestDescription: string;
  updated: boolean;
  enableRunning: boolean;
  testJSON?: Record<string, any>;
  searchText: string;
  showParamsErrors: boolean;
  webhooks: WebhookState; // todo
  emails: EmailState;
  messagingServices: MessagingServiceState; // todo
  isNewIntegration: boolean;
  newIntegrationEnabledFlag: boolean;
  showCancelConfirmation: boolean;
  waitingForSave: boolean;
  waitingFoClose: boolean;
  updatedTemplate: boolean;
  backgroundSaveRunning: boolean;
  lastChangeAt?: number;
  jiraProject?: {
    name: string;
    id: string;
  };
  jiraIssueValues?: {
    [uuid: string]: JiraIssueValue;
  };
  customEmailsLoading: boolean;
  orgSenderEmail?: OrganisationSenderEmailV1Resp;
  emailSubjects: IStringMap;
}

interface CreateIntegrationConnectedProps {
  loading: boolean;
  newIntegrationType: IntegrationTypeString;
  integrationId: number;
  webhookTestResponse?: TestWebhookV1Resp;
  notifications?: INotificationConfigCategory<IOrgAlertDefinition>[];
  notificationsByUUID?: Record<string, IOrgAlertDefinition | undefined>;
  integrations?: IIntegration[];
  assuranceType: AssuranceType;
  availableLabels: ILabel[];
  vendorTiers: VendorTier[];
  slackChannelData: ISlackChannelData;
  populatedMessagePayloads: IntegrationPopulatedMessages;
  populatedEmailMessagePayloads: IntegrationPopulatedMessages;
  jiraConnections?: OAuthState;
  jiraProjectsList: JiraProjectsList;
  jiraIssueTypes: JiraIssueTypes;
  canAccessCustomEmailDomain: boolean;
  userCanAccessVendorRisk: boolean;
  orgLogoUrl?: string;
  useOrgLogoForEmail: boolean;
  currentUserEmail: string;
}

type CreateIntegrationRouteProps = DefaultRouteProps<{
  integrationId?: string;
  type?: string;
}>;

type CreateIntegrationProps = CreateIntegrationConnectedProps &
  CreateIntegrationRouteProps &
  DefaultThunkDispatchProp;

class CreateIntegration extends Component<
  CreateIntegrationProps,
  CreateIntegrationState
> {
  static MSG_ZAP_CONNECTED = "A Zap! is currently connected.";
  static MSG_ZAP_DISCONNECTED = "A Zap! is not yet connected.";
  static ZAPIER_ENABLED = false;

  backgroundSaveTimer: ReturnType<Window["setInterval"]> | undefined;

  constructor(props: CreateIntegrationProps) {
    super(props);

    // create a random uniqueId for the new integration
    const uniqueId = this.createRandomId();

    // if this is an existing integration instance we are updating, determine the integration instance
    const integration = props.integrations?.find(
      (i) => i.id == props.integrationId
    );

    // if this is a new integration instance we are creating, determine the target integration type
    let newIType: IntegrationTypeString | undefined;
    if (this.props.newIntegrationType) {
      newIType = verifyIntegrationTypeString(this.props.newIntegrationType);
    }

    const values = this.collectAttributesFromIntegrationInstance(integration);
    const iType: IntegrationTypeString = newIType
      ? newIType
      : integration
        ? IntegrationTypes[integration.type]
        : "webhook";

    const webhooks: Partial<WebhookState> = {
      webhook: {
        webhookURL: "",
        ignoreSSLCertCheck: false,
        headerParams: [],
        urlParams: [],
        webhookID: 0,
      },
    };

    const messagingServices = {
      slack: {
        channel: "",
      },
    };

    const emailState = {
      email: {
        toAddress: "",
        fromName: "",
      },
      jira: {
        toAddress: "",
        fromName: "",
      },
      webhook: {
        toAddress: "",
        fromName: "",
      },
      zapier: {
        toAddress: "",
        fromName: "",
      },
      slack: {
        toAddress: "",
        fromName: "",
      },
    };

    let selectedTestUUID;
    let testJSON;
    if (Object.keys(values.selectedUUIDs).length > 0) {
      selectedTestUUID = Object.keys(values.selectedUUIDs)[0];
      const notification = props.notificationsByUUID?.[selectedTestUUID];
      if (notification) {
        testJSON = notification.testJSON;
      }
    }

    //
    // RE: state.newIntegrationEnabledFlag
    // we need to cater for the enabled flag of NEW integrations separately from the normal update cycle that uses state.enabled = integration.enabled.
    // When we create a new integration, the enabled flag is necessarily kept to FALSE until the very last step. We dont want a partially defined
    // integration to be stored as enabled in the database and be fired by the notifications engine.
    //

    this.state = {
      id: integration ? integration.id : undefined,
      currentStep: 1,
      integrationType: iType,
      description: integration != null ? integration.description : "",
      uniqueId: integration != null ? integration.uniqueId : uniqueId,
      enabled: integration != null ? integration.enabled : false,
      selectedUUIDs: values.selectedUUIDs,
      zapierConnected: values.zapierConnected,
      payloadTemplates: integration ? integration.payloadTemplates : {},
      submitLoading: false,
      selectedTestUUID: selectedTestUUID as string,
      selectedTestDescription: "",
      updated: false,
      enableRunning: false,
      testJSON: testJSON,
      searchText: "",
      showParamsErrors: false,
      webhooks: webhooks as WebhookState,
      emails: emailState,
      messagingServices: messagingServices as MessagingServiceState,
      isNewIntegration: !!newIType,
      newIntegrationEnabledFlag: true,
      showCancelConfirmation: false,
      waitingForSave: false,
      waitingFoClose: false,
      backgroundSaveRunning: false,
      updatedTemplate: false,
      customEmailsLoading: false,
      emailSubjects: integration?.emailSubjects ?? {},
    };

    // special state for webhook integrations
    if (iType == "webhook") {
      this.state = {
        ...this.state,
        webhooks: {
          ...this.state.webhooks,
          [iType]: {
            webhookID: values.webhookID,
            webhookURL: integration != null ? integration.url : "",
            ignoreSSLCertCheck: values.ignoreSSLCertCheck,
            headerParams: values.headerParams,
            urlParams: values.urlParams,
            basicAuth: values.basicAuthParams,
          },
        },
      };
    }
    // special state for messaging integrations
    if (iType == "slack") {
      this.state = {
        ...this.state,
        messagingServices: {
          ...this.state.messagingServices,
          [iType]: {
            channel: values.selectedChannel as string,
          },
        },
      };
    }

    // special state for jira integrations
    if (iType == "jira") {
      this.state = {
        ...this.state,
        jiraProject: values.jiraProject,
        jiraIssueValues: values.jiraIssueValues,
      };
    }

    // special state for email integrations
    if (iType == "email" && values.emailDestination) {
      this.state = {
        ...this.state,
        emails: {
          ...this.state.emails,
          [iType]: {
            toAddress: values.emailDestination?.toAddress,
            fromAddress: values.emailDestination?.fromAddress,
            fromName: values.emailDestination?.fromName,
          },
        },
      };
    }

    // if we dont have a user-defined payload for a selected uuid, then grab the default
    // from the notifications meta.
    if (integration) {
      for (let i = 0; i < Object.keys(values.selectedUUIDs).length; i++) {
        const uuid = Object.keys(values.selectedUUIDs)[i];
        this.state.payloadTemplates[uuid] = integration.payloadTemplates[uuid];
        // no payload template defined by the integration? grab default from meta
        if (!this.state.payloadTemplates[uuid]) {
          this.state.payloadTemplates[uuid] =
            this.state.integrationType == "slack" ||
            this.state.integrationType == "email"
              ? props.notificationsByUUID?.[uuid]?.exampleLiquidTextMessage
              : props.notificationsByUUID?.[uuid]?.defaultPayloadScript;
        }
        // if slack or email, make sure all of the initial example uuids have their initial liquid message populated
        if (iType == "slack" || iType == "email") {
          props.dispatch(
            populateLiquidMessageForNotificationUUID(
              this.state.integrationType == "slack"
                ? MESSAGING_SERVICE_SLACK
                : MESSAGING_SERVICE_EMAIL,
              (this.state.uniqueId ?? "").trim(),
              this.state.payloadTemplates[uuid] ?? "",
              uuid
            )
          );
        }
      }
    }

    // lookup data for all integration types
    props.dispatch(fetchAllOrgNotifications());
    props.dispatch(fetchOrgIntegrationSettings());
    props.dispatch(fetchAvailableLabels());
    if (props.userCanAccessVendorRisk) {
      props.dispatch(fetchVendorTiers());
      props.dispatch(fetchVendorAttributeDefinitions());
    }
    this.getOrgSenderEmail();
    if (this.props.canAccessCustomEmailDomain && !props.orgLogoUrl) {
      props
        .dispatch(fetchOrgLogo())
        .then(({ url }) => props.dispatch(setOrgLogo(url)));
    }
    props.dispatch(fetchOrganisationFlags());

    // lookup data for slack integrations
    if (iType == "slack") {
      props
        .dispatch(fetchExistingOAuthConnections(SLACK_SERVICE))
        .then((result) => {
          if (result && result.connections.length > 0) {
            props
              .dispatch(
                fetchOAuth2MessagingServiceChannelList(SLACK_SERVICE, false)
              )
              .catch((e) => {
                console.error(`Failed to get slack service channel list: ${e}`);
              });
          }
        });
    }

    // lookup data for jira integrations
    if (iType == "jira") {
      props.dispatch(fetchExistingOAuthConnections(JIRA_SERVICE));
      props.dispatch(fetchJiraProjectList(false, false));
      if (integration && integration.jiraProjectId) {
        props.dispatch(
          fetchJiraIssueTypesList(integration.jiraProjectId, true)
        );
      }
    }
  }

  componentDidUpdate(prevProps: Readonly<CreateIntegrationProps>) {
    if (
      prevProps.integrationId != this.props.integrationId ||
      ((prevProps.integrations?.length ?? 0) == 0 &&
        (this.props.integrations?.length ?? 0) > 0)
    ) {
      let integration: IIntegration | undefined;
      if (this.props.integrationId > 0 && this.props.integrations) {
        this.props.integrations.map((i) => {
          if (i.id == this.props.integrationId) {
            integration = i;
          }
        });
      }

      if (integration) {
        const values =
          this.collectAttributesFromIntegrationInstance(integration);

        const iType = IntegrationTypes[integration.type];
        let { selectedTestUUID } = this.state;
        if (Object.keys(values.selectedUUIDs).length > 0) {
          selectedTestUUID = Object.keys(values.selectedUUIDs)[0];
        }

        let newState: Partial<CreateIntegrationState> = {
          id: integration.id,
          integrationType: iType,
          description: integration.description,
          uniqueId: integration.uniqueId,
          enabled: integration.enabled,
          selectedUUIDs: values.selectedUUIDs,
          zapierConnected: values.zapierConnected,
          payloadTemplates: integration.payloadTemplates,
          selectedTestUUID: selectedTestUUID,
          emailSubjects: integration.emailSubjects ?? {},
        };

        // special state for webhook integrations
        if (iType == "webhook") {
          newState = {
            ...newState,
            webhooks: {
              ...this.state.webhooks,
              [iType]: {
                webhookID: values.webhookID,
                webhookURL: integration.url ? integration.url : "",
                ignoreSSLCertCheck: values.ignoreSSLCertCheck,
                headerParams: values.headerParams,
                urlParams: values.urlParams,
                basicAuth: values.basicAuthParams,
              },
            },
          };
        }
        // special state for messaging integrations
        if (iType == "slack") {
          newState = {
            ...newState,
            messagingServices: {
              ...this.state.messagingServices,
              [iType]: {
                channel: values.selectedChannel ?? "",
              },
            },
          };
        }

        // special state for jira integrations
        if (iType == "jira") {
          newState = {
            ...newState,
            jiraProject: values.jiraProject,
            jiraIssueValues: values.jiraIssueValues,
          };
        }

        // special state for email integrations
        if (iType == "email" && values.emailDestination) {
          newState = {
            ...newState,
            emails: {
              ...this.state.emails,
              [iType]: {
                toAddress: values.emailDestination?.toAddress,
                fromAddress: values.emailDestination?.fromAddress,
                fromName: values.emailDestination?.fromName,
              },
            },
          };
        }

        // if we dont have a user-defined payload for a selected uuid, then grab the default
        // from the notifications meta
        for (let i = 0; i < Object.keys(values.selectedUUIDs).length; i++) {
          const uuid = Object.keys(values.selectedUUIDs)[i];
          const payloadTemplate = integration.payloadTemplates[uuid];
          // no payload template defined by the integration? grab default from meta
          if (!payloadTemplate) {
            newState.payloadTemplates![uuid] =
              this.state.integrationType == "slack" ||
              this.state.integrationType == "email"
                ? this.props.notificationsByUUID?.[uuid]
                    ?.exampleLiquidTextMessage
                : this.props.notificationsByUUID?.[uuid]?.defaultPayloadScript;
          }
          // if slack or email, make sure all of the initial example payloads have their initial liquid message populated
          if (iType == "slack" || iType == "email") {
            this.props.dispatch(
              populateLiquidMessageForNotificationUUID(
                this.state.integrationType == "slack"
                  ? MESSAGING_SERVICE_SLACK
                  : MESSAGING_SERVICE_EMAIL,
                (newState.uniqueId ?? "").trim(),
                newState.payloadTemplates![uuid] ?? "",
                uuid
              )
            );
          }

          if (uuid === selectedTestUUID) {
            newState.testJSON =
              this.props.notificationsByUUID?.[uuid]?.testJSON;
          }
        }

        this.setState(newState as CreateIntegrationState);

        //
        // run any additional lookup data fetches if we just found out what kind of integration we are
        // (we loaded the integration using the url-supplied id)
        //

        if (
          iType == "slack" &&
          (!this.props.slackChannelData.channels ||
            this.props.slackChannelData.channels.length == 0)
        ) {
          this.props.dispatch(
            fetchOAuth2MessagingServiceChannelList(
              MESSAGING_SERVICE_SLACK,
              false,
              false
            )
          );
        }

        if (iType == "jira") {
          if (
            !this.props.jiraProjectsList.projects ||
            this.props.jiraProjectsList.projects.length == 0
          ) {
            this.props.dispatch(fetchExistingOAuthConnections(JIRA_SERVICE));
            this.props.dispatch(fetchJiraProjectList(false, false));
          }
          if (integration && integration.jiraProjectId) {
            this.props.dispatch(
              fetchJiraIssueTypesList(integration.jiraProjectId, true)
            );
          }
        }
      }
    }
  }

  componentDidMount() {
    this.startBackgroundSave();
  }

  componentWillUnmount() {
    this.stopBackgroundSave();
  }

  getOrgSenderEmail = async () => {
    if (this.props.canAccessCustomEmailDomain) {
      this.setState({ customEmailsLoading: true });
      this.props
        .dispatch(fetchOrgSenderEmail())
        .then((resp) => this.setState({ orgSenderEmail: resp }))
        .catch(() =>
          this.props.dispatch(
            addDefaultUnknownErrorAlert(
              "error fetching custom email domain details"
            )
          )
        )
        .finally(() => this.setState({ customEmailsLoading: false }));
    }
  };

  //
  // collectAttributesFromIntegrationInstance
  // grab a bunch of useful attributes for an existing integration instance
  // see constructor() and componentDidUpdate()
  //
  collectAttributesFromIntegrationInstance = (
    integration?: IIntegration
  ): collectedIntegrationAttributes => {
    let webhookURL = "";
    let ignoreSSLCertCheck = false;
    const headerParams = [] as INameValuePair[];
    const urlParams = [] as INameValuePair[];
    let basicAuthParams;
    const zapierConnected = false;
    let webhookID = 0;
    let selectedChannel;
    let jiraProject;
    let jiraIssueValues = {};
    const selectedUUIDs = {} as { [key: string]: boolean };
    const { notificationsByUUID } = this.props;
    let emailDestination;

    if (integration != null) {
      if (notificationsByUUID) {
        Object.values(notificationsByUUID).forEach((n) => {
          const notification = n as IOrgAlertDefinition;
          if (integration.uuids.includes(notification.uuid)) {
            selectedUUIDs[notification.uuid] = true;
          }
        });
      }

      if (integration.type === SLACK_INTEGRATION_TYPE) {
        selectedChannel = integration.messagingChannel;
      }

      if (integration.type === JIRA_INTEGRATION_TYPE) {
        jiraProject =
          integration &&
          integration.jiraProjectId &&
          integration.jiraProjectName
            ? {
                name: integration.jiraProjectName,
                id: integration.jiraProjectId,
              }
            : undefined;
        jiraIssueValues = integration.jiraFields ? integration.jiraFields : {};
      }

      if (integration.webhooks != null && integration.webhooks.length > 0) {
        const webhook = integration.webhooks[0];

        webhookURL = webhook ? webhook.url : "";
        webhookID = webhook ? webhook.id : 0;
        ignoreSSLCertCheck = webhook ? webhook.ignoreSSLCertCheck : false;

        // extract headers and url parameters from the webhook definition
        if (webhook.headerParams) {
          Object.entries(webhook.headerParams).map(([name, value], i) => {
            headerParams[i] = { name, value };
          });
        }
        if (webhook.urlParams) {
          Object.entries(webhook.urlParams).map(([name, value], i) => {
            urlParams[i] = { name, value };
          });
        }
        if (webhook.basicAuthParams) {
          basicAuthParams = webhook.basicAuthParams;
        }
      }

      if (integration.emailDestination) {
        emailDestination = {
          toAddress: integration.emailDestination.toAddress,
          fromName: integration.emailDestination.fromName,
          fromAddress: integration.emailDestination.fromAddress,
        };
      }
    }
    return {
      webhookID: webhookID,
      webhookURL: webhookURL,
      ignoreSSLCertCheck: ignoreSSLCertCheck,
      headerParams: headerParams,
      urlParams: urlParams,
      basicAuthParams: basicAuthParams,
      zapierConnected: zapierConnected,
      selectedUUIDs: selectedUUIDs,
      selectedChannel: selectedChannel,
      jiraProject: jiraProject,
      jiraIssueValues: jiraIssueValues,
      emailDestination,
    };
  };

  //
  // createRandomId
  // Generate a unique identifier for a new integration instance
  //
  createRandomId = () => {
    let text = "";
    const possibleCHAR = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const possibleDIGIT = "0123456789";

    for (let i = 0; i < 4; i++)
      text += possibleCHAR.charAt(
        Math.floor(Math.random() * possibleCHAR.length)
      );
    for (let i = 0; i < 4; i++)
      text += possibleDIGIT.charAt(
        Math.floor(Math.random() * possibleDIGIT.length)
      );
    return text;
  };

  //
  // setWebhookParam
  // Callback from the WebhookDestinationStep that updates a specific parameter for an integration of type webhook
  //
  setWebhookParam = (
    type: WebhookParamType,
    idx: number,
    key: string,
    value: string
  ) => {
    this.setState((state) => {
      const params = [...state.webhooks[state.integrationType][type]];
      params[idx] = {
        ...params[idx],
        [key]: value,
      };

      return {
        webhooks: {
          ...state.webhooks,
          [state.integrationType]: {
            ...state.webhooks[state.integrationType],
            [type]: params,
          },
        },
      };
    });
    this.setState({ updated: true });
  };

  //
  // newWebhookParam
  // Callback from the WebhookDestinationStep that adds a new (specific) parameter for an integration of type webhook
  //
  newWebhookParam = (type: WebhookParamType, key: string, value: string) => {
    const newParam = {
      name: "",
      value: "",
      key: Date.now(),
      [key]: value,
    };

    this.setState((state) => {
      return {
        webhooks: {
          ...state.webhooks,
          [state.integrationType]: {
            ...state.webhooks[state.integrationType],
            [type]: [...state.webhooks[state.integrationType][type], newParam],
          },
        },
        updated: true,
      };
    });
  };

  //
  // deleteWebhookParam
  // Callback from the WebhookDestinationStep that removes a specific parameter for an integration of type webhook
  //
  deleteWebhookParam = (type: WebhookParamType, idx: number) => {
    this.setState((state) => {
      const params = [...state.webhooks[state.integrationType][type]];
      params.splice(idx, 1);
      return {
        webhooks: {
          ...state.webhooks,
          [state.integrationType]: {
            ...state.webhooks[state.integrationType],
            [type]: params,
          },
        },
      };
    });
    this.setState({ updated: true });
  };

  //
  // deleteWebhookParam
  // Callback from the WebhookDestinationStep that sets a basic authentication parameter value (username/password)
  // for an integration of type webhook
  //
  setWebhookBasicAuth = (field: string, value: string) => {
    this.setState((state) => {
      let existing: Partial<IBasicAuth> | undefined =
        state.webhooks[state.integrationType].basicAuth;
      if (!existing) {
        existing = {};
      }

      return {
        webhooks: {
          ...state.webhooks,
          [state.integrationType]: {
            ...state.webhooks[state.integrationType],
            basicAuth: {
              ...existing,
              [field]: value,
            },
          },
        },
      };
    });
    this.setState({ updated: true });
  };

  //
  // deleteWebhookParam
  // Callback from the WebhookDestinationStep that removes a basic authentication parameter value (username/password)
  // for an integration of type webhook
  //
  deleteWebhooksBasicAuth = () => {
    this.setState((state) => {
      return {
        webhooks: {
          ...state.webhooks,
          [state.integrationType]: {
            ...state.webhooks[state.integrationType],
            basicAuth: null,
          },
        },
      };
    });
    this.setState({ updated: true });
  };

  //
  // setDescription
  // Callback from each of the DestinationSteps (specific to each integration type) that set's the description (name) of the
  // integration instance.
  //
  setDescription = (desc: string) => {
    this.setState({
      description: desc,
      updated: true,
    });
  };

  //
  // setSelectedJiraProject
  // Callback from SelectJiraDestinationStep to set the jira project that is the target of a jira integration.
  //
  setSelectedJiraProject = (project: string, id: string) => {
    if (this.state.jiraProject && id != this.state.jiraProject.id) {
      // iterate over existing uuids and unselect issue types
      for (let i = 0; i < Object.keys(this.state.selectedUUIDs).length; i++) {
        const uuid = Object.keys(this.state.selectedUUIDs)[i];
        this.setSelectedJiraFieldValue(uuid, "", ISSUE_TYPE_FIELD, "");
      }
    }
    this.setState({
      jiraProject: {
        name: project,
        id: id,
      },
      updated: true,
    });

    this.props.dispatch(fetchJiraIssueTypesList(id, true));
  };

  //
  // setSelectedJiraFieldValue
  // Callback from ConfigureJiraIssueFieldsStep to set the value of a specific jira issue field. Note that the set of fields
  // that the user is asked to set for a jira integration are dependent on the specific jira issue type selected. Also note that
  // when an issueType is selected, if the summary or the description have not already been set by the user, the default liquid templates
  // for these fields are applied to the integration to function as useful examples.
  //
  setSelectedJiraFieldValue = (
    uuid: string,
    type: string,
    key: string,
    value: string
  ) => {
    if (uuid) {
      if (value) {
        this.setState((state) => {
          const newValues = {
            ...state.jiraIssueValues?.[uuid],
            [key]: SerialiseJiraFieldValue(type, value),
          } as JiraIssueValue;

          // setting the issue type for the first time? then apply any default field values we have
          if (key == ISSUE_TYPE_FIELD) {
            newValues[SUMMARY_FIELD] = this.getSummaryJiraFieldValue(uuid);
            newValues[DESCRIPTION_FIELD] =
              this.getDescriptionJiraFieldValue(uuid);
          }

          return {
            jiraIssueValues: {
              ...state.jiraIssueValues,
              [uuid]: newValues,
            },
            updated: true,
          };
        });
      } else {
        this.setState((state) => {
          if (
            state.jiraIssueValues &&
            state.jiraIssueValues[uuid] &&
            state.jiraIssueValues[uuid]
          ) {
            delete state.jiraIssueValues[uuid][key];
          }
          return {
            ...state,
            updated: true,
          };
        });
      }
    }
  };

  //
  // getDescriptionJiraFieldValue
  // Determines the currently entered issue description for a specific trigger.
  // If no description has been entered, then the default messaging liquid template is returned instead.
  // @see setSelectedJiraFieldValue()
  //
  getDescriptionJiraFieldValue = (uuid: string) => {
    let desc =
      this.state.jiraIssueValues &&
      this.state.jiraIssueValues[uuid] &&
      this.state.jiraIssueValues[uuid][DESCRIPTION_FIELD] != undefined
        ? this.state.jiraIssueValues[uuid][DESCRIPTION_FIELD]
        : undefined;

    if (!desc) {
      const n = this.props.notificationsByUUID?.[this.state.selectedTestUUID];
      if (n) {
        desc = "string|" + n.exampleLiquidTextMessage;
      }
    }
    return desc;
  };

  //
  // getSummaryJiraFieldValue
  // Determines the currently entered issue summary for a specific trigger.
  // If no description has been entered, then the default messaging liquid template is returned instead.
  // @see setSelectedJiraFieldValue()
  //
  getSummaryJiraFieldValue = (uuid: string) => {
    return this.state.jiraIssueValues &&
      this.state.jiraIssueValues[uuid] &&
      this.state.jiraIssueValues[uuid][SUMMARY_FIELD] != undefined
      ? this.state.jiraIssueValues[uuid][SUMMARY_FIELD]
      : "string|{{ notification.description }}";
  };

  //
  // setWebhookURL
  // Callback from SelectWebhookDestinationStep to set the target URL for a specific webhook integration instance
  //
  setWebhookURL = (url: string) => {
    this.setState({
      webhooks: {
        ...this.state.webhooks,
        [this.state.integrationType]: {
          ...this.state.webhooks[this.state.integrationType],
          webhookURL: url,
        },
      },
      updated: true,
    });
  };

  //
  // validateDefineWebhookTarget
  // Used by this component when rendering the NEXT button from the destination step, as well as ConfigureWebhookPayloadsStep, to determine if the
  // prerequisites for correctly specifying the target for a webhook integration have been met.
  //
  validateDefineWebhookTarget = () => {
    // if webhook make sure we have a URL set
    if (
      !this.state.description ||
      (this.state.integrationType == "webhook" &&
        (!this.state.webhooks[this.state.integrationType].webhookURL ||
          this.state.webhooks[this.state.integrationType].webhookURL.trim()
            .length === 0 ||
          !validateUrl(
            this.state.webhooks[this.state.integrationType].webhookURL.trim()
          )))
    ) {
      return {
        valid: false,
        msg: "Please enter a name and a webhook URL for your integration",
      };
    }

    const basicAuth = this.state.webhooks[this.state.integrationType].basicAuth;

    if (basicAuth) {
      const noUsername =
        !basicAuth.username || basicAuth.username.trim() === "";

      let noPassword = !basicAuth.password || basicAuth.password.trim() === "";

      // nopassword check only applies to new webhooks (we wont have the password on the backend at the moment).
      noPassword =
        noPassword &&
        !this.state.webhooks[this.state.integrationType].webhookID;

      // show error only if user set username and no password or vice-versa
      if ((!noUsername && noPassword) || (noUsername && !noPassword)) {
        return {
          valid: false,
          msg: `Please enter a username and optional password for basic auth`,
        };
      }
    }
    return { valid: true, msg: "" };
  };

  //
  // validateSlackTarget
  // Used by this component when rendering the NEXT button from the destination step, as well as ConfigureSlackPayloadsStep, to determine if the
  // prerequisites for correctly specifying the target of a slack integration have been met.
  //
  validateSlackTarget = () => {
    // if slack, make sure we have a name (description) set and a channel selected
    if (
      !this.state.description ||
      (this.state.integrationType == "slack" &&
        !this.state.messagingServices[this.state.integrationType].channel)
    ) {
      return {
        valid: false,
        msg: "Please enter a name, and select a Slack channel for your integration",
      };
    }
    return { valid: true, msg: "" };
  };

  //
  // validateJiraTarget
  // Used by this component when rendering the NEXT button from the destination step, as well as ConfigureJiraIssueFieldsSte, to determine if the
  // prerequisites for correctly specifying the target of a slack integration have been met.
  //
  validateJiraTarget = () => {
    // if jira, make sure we have a name (description) only
    if (!this.state.description) {
      return {
        valid: false,
        msg: "Please enter a name for your integration",
      };
    }
    if (!this.state.jiraProject || !this.state.jiraProject.id) {
      return {
        valid: false,
        msg: "Please select a Jira project",
      };
    }
    return { valid: true, msg: "" };
  };

  //
  // validateEmailTarget
  // Used to determine if an email target is valid, just have a description, from name, and if
  // a custom email target is used a valid prefix
  validateEmailTarget = () => {
    if (!this.state.description) {
      return {
        valid: false,
        msg: "Please enter a name for your integration",
      };
    }

    const emailTarget = this.state.emails[this.state.integrationType];
    if (!validateEmail(emailTarget.toAddress)) {
      return {
        valid: false,
        msg: "Please enter a valid to address for your integration",
      };
    }

    if (emailTarget.fromName === "") {
      return {
        valid: false,
        msg: "Please enter a name the emails will be sent from",
      };
    }

    if (emailTarget.fromAddress && !validateEmail(emailTarget.fromAddress)) {
      return {
        valid: false,
        msg: "Please enter a valid from address",
      };
    }

    return { valid: true, msg: "" };
  };

  //
  // validateEmailPayloads
  // determines if all the payloads for an email target are valid
  validateEmailPayloads = () => {
    // make sure all the subjects are filled in
    if (Object.values(this.state.emailSubjects).some((s) => s === "")) {
      return {
        valid: false,
        msg: "Please make sure each trigger has a valid subject line",
      };
    }

    return { valid: true, msg: "" };
  };

  //
  // getSlackExampleMessage
  // Used by ConfigureSlackPayloadsStep to retrieve the liquid-populated example payload for the currently selected
  // notification trigger. Basically this represents the view of the slack message as displayed in slack itself.
  //
  getSlackExampleMessage = () => {
    let message = "...";
    let error;

    if (
      this.props.populatedMessagePayloads &&
      this.state.selectedTestUUID &&
      this.state.uniqueId &&
      this.props.populatedMessagePayloads[this.state.uniqueId] &&
      this.props.populatedMessagePayloads[this.state.uniqueId][
        this.state.selectedTestUUID
      ]
    ) {
      message =
        this.props.populatedMessagePayloads[this.state.uniqueId][
          this.state.selectedTestUUID
        ].text;
      error =
        this.props.populatedMessagePayloads[this.state.uniqueId][
          this.state.selectedTestUUID
        ].error;
    }
    return { message, error };
  };

  //
  // getEmailExampleMessage
  // Used by ConfigureEmailPayloadsStep to retrieve the liquid-populated example payload for the currently selected
  // notification trigger. Basically this represents the view of the email message as displayed in and email itself.
  //
  getEmailExampleMessage = () => {
    let message = "...";
    let error;

    if (
      this.props.populatedEmailMessagePayloads &&
      this.state.selectedTestUUID &&
      this.state.uniqueId &&
      this.props.populatedEmailMessagePayloads[this.state.uniqueId] &&
      this.props.populatedEmailMessagePayloads[this.state.uniqueId][
        this.state.selectedTestUUID
      ]
    ) {
      message =
        this.props.populatedEmailMessagePayloads[this.state.uniqueId][
          this.state.selectedTestUUID
        ].text;
      error =
        this.props.populatedEmailMessagePayloads[this.state.uniqueId][
          this.state.selectedTestUUID
        ].error;
    }
    return { message, error };
  };

  //
  // validateJiraFields
  // Used by this component when rendering the NEXT button from the field values step, to determine if the
  // mandatory field prerequisites have been met for jira issue creation.
  //
  validateJiraFields = () => {
    // check all issue types first
    const issueTypes = {} as { [key: string]: OptionType | string };
    for (let i = 0; i < Object.keys(this.state.selectedUUIDs).length; i++) {
      const uuid = Object.keys(this.state.selectedUUIDs)[i];
      let issueType = undefined;

      // grab the issueType field value
      if (this.state.jiraIssueValues && this.state.jiraIssueValues[uuid]) {
        Object.keys(this.state.jiraIssueValues[uuid]).map((id) => {
          if (id == ISSUE_TYPE_FIELD) {
            issueType = DeserialiseJiraFieldValue(
              this.state.jiraIssueValues![uuid][id]
            );
          }
        });
      }

      if (!issueType) {
        return {
          valid: false,
          msg: "No issue type selected for one or more triggers",
        };
      }
      issueTypes[uuid] = issueType;
    }

    // now check mandatory fields
    if (
      this.state.jiraProject &&
      this.props.jiraIssueTypes &&
      this.props.jiraIssueTypes[this.state.jiraProject.id] &&
      this.props.jiraIssueTypes[this.state.jiraProject.id].issueTypes
    ) {
      for (let i = 0; i < Object.keys(this.state.selectedUUIDs).length; i++) {
        const uuid = Object.keys(this.state.selectedUUIDs)[i];
        let issueFields = [] as IJiraField[];
        let issueTypeName = undefined;
        this.props.jiraIssueTypes?.[this.state.jiraProject.id]?.issueTypes?.map(
          (t: IJiraIssueType) => {
            if (t.id == issueTypes[uuid]) {
              issueFields = t.fields;
              issueTypeName = t.name;
            }
          }
        );
        for (let i = 0; i < issueFields.length; i++) {
          if (issueFields[i].details.required) {
            let fieldValue = undefined;
            // grab the field value
            fieldValue = DeserialiseJiraFieldValue(
              this.state.jiraIssueValues?.[uuid]?.[
                issueFields[i].details.key
              ] ?? ""
            );
            if (!fieldValue) {
              return {
                valid: false,
                msg: `Field '${issueFields[i].details.name}' is mandatory for issues of type ${issueTypeName}`,
              };
            }
          }
        }
      }
    }
    return { valid: true, msg: "" };
  };

  setEmailToAddress = (toAddress: string) => {
    this.setState({
      emails: {
        ...this.state.emails,
        [this.state.integrationType]: {
          ...this.state.emails[this.state.integrationType],
          toAddress,
        },
      },
      updated: true,
    });
  };

  setEmailFromName = (fromName: string) => {
    this.setState({
      emails: {
        ...this.state.emails,
        [this.state.integrationType]: {
          ...this.state.emails[this.state.integrationType],
          fromName,
        },
      },
      updated: true,
    });
  };

  setEmailFromAddress = (fromAddress?: string) => {
    this.setState({
      emails: {
        ...this.state.emails,
        [this.state.integrationType]: {
          ...this.state.emails[this.state.integrationType],
          fromAddress,
        },
      },
      updated: true,
    });
  };

  //
  // setSelectedTestNotification
  // Callback from the left hand trigger selection component of all steps to set the current trigger of focus.
  //
  setSelectedTestNotification = (n: IOrgAlertDefinition) => {
    this.setState({
      selectedTestUUID: n.uuid,
      selectedTestDescription: n.headline,
      testJSON: n.testJSON,
    });
  };

  //
  // toggleSelectedUUID
  // Callback from the SelectTriggersStep to toggle the selection of a specific notifications trigger for the current
  // integration instance.
  //
  toggleSelectedUUID = (uuid: string) => {
    this.setState((state) => {
      // deleting the selection?
      if (state.selectedUUIDs[uuid]) {
        const newUUIDs = { ...state.selectedUUIDs };
        delete newUUIDs[uuid];

        const emailSubjects = { ...state.emailSubjects };
        if (uuid in emailSubjects) {
          delete emailSubjects[uuid];
        }

        // removing the current selection
        let { selectedTestUUID, testJSON } = state;
        if (selectedTestUUID === uuid) {
          selectedTestUUID = "";
          testJSON = {};

          // Find another uuid to select
          const newUUIDKeys = Object.keys(newUUIDs);
          for (let i = 0; i < newUUIDKeys.length; i++) {
            const notification =
              this.props.notificationsByUUID?.[newUUIDKeys[i]];
            if (notification) {
              selectedTestUUID = notification.uuid;
              testJSON = notification.testJSON;
              break;
            }
          }
        }

        return {
          selectedUUIDs: newUUIDs,
          selectedTestUUID: selectedTestUUID,
          testJSON: testJSON,
          emailSubjects,
        } as CreateIntegrationState;
      }

      // adding the selection?
      let payloadTemplate;
      if (
        this.state.integrationType == "slack" ||
        this.state.integrationType === "email"
      ) {
        payloadTemplate = this.getDefaultMessageTemplate(uuid);
        this.props.dispatch(
          populateLiquidMessageForNotificationUUID(
            this.state.integrationType == "slack"
              ? MESSAGING_SERVICE_SLACK
              : MESSAGING_SERVICE_EMAIL,
            this.state.uniqueId?.trim() ?? "",
            payloadTemplate,
            uuid
          )
        );
      } else {
        payloadTemplate = this.getDefaultTemplate(uuid);
      }
      if (this.state.payloadTemplates[uuid]) {
        payloadTemplate = this.state.payloadTemplates[uuid];
      }
      let { selectedTestUUID, testJSON } = this.state;
      if (!selectedTestUUID) {
        selectedTestUUID = uuid;

        const notification = this.props.notificationsByUUID?.[uuid];
        if (notification) {
          testJSON = notification.testJSON;
        }
      }

      const emailSubjects = { ...state.emailSubjects };
      if (
        this.state.integrationType === "email" &&
        !this.state.emailSubjects[uuid]
      ) {
        emailSubjects[uuid] = this.getDefaultSubjectLine(uuid);
      }

      return {
        selectedTestUUID: selectedTestUUID,
        testJSON: testJSON,
        selectedUUIDs: {
          ...state.selectedUUIDs,
          [uuid]: true,
        },
        payloadTemplates: {
          ...state.payloadTemplates,
          [uuid]: payloadTemplate,
        },
        emailSubjects,
      };
    });

    this.setState({ updated: true });
  };

  webhooksParamsErrors = () => {
    let errorsFound = false;
    for (
      let i = 0;
      i < this.state.webhooks[this.state.integrationType].headerParams.length;
      i++
    ) {
      if (
        this.state.webhooks[this.state.integrationType].headerParams[
          i
        ].name.trim() === "" ||
        this.state.webhooks[this.state.integrationType].headerParams[
          i
        ].value.trim() === ""
      ) {
        errorsFound = true;
      }
    }
    for (
      let i = 0;
      i < this.state.webhooks[this.state.integrationType].urlParams.length;
      i++
    ) {
      if (
        this.state.webhooks[this.state.integrationType].urlParams[
          i
        ].name.trim() === "" ||
        this.state.webhooks[this.state.integrationType].urlParams[
          i
        ].value.trim() === ""
      ) {
        errorsFound = true;
      }
    }

    this.setState({ showParamsErrors: errorsFound });
    return errorsFound;
  };

  //
  // messagingParamsErrors
  // Used by this component as a pre-condition to the submit() call when clicking the next button from
  // the destination step for slack integrations
  //
  messagingParamsErrors = () => {
    let errorsFound = false;
    if (!this.state.messagingServices[this.state.integrationType].channel) {
      errorsFound = true;
    }
    this.setState({ showParamsErrors: errorsFound });
    return errorsFound;
  };

  showCancelConfirmationModal = () => {
    this.setState({ showCancelConfirmation: true });
  };

  onClickCloseCancel = async () => {
    if (
      this.state.isNewIntegration &&
      (this.state.updated || (this.state.id && this.state.id > 0))
    ) {
      this.showCancelConfirmationModal();
      return;
    } else if (this.state.id && this.state.id > 0) {
      this.setState({ waitingForSave: true });
      await this.runBackgroundSave();
      this.setState({ waitingForSave: false });
    }
    this.props.history.push("/settings/integrations");
  };

  //
  // jumpToStep
  // Used by the navigation (step) header to jump directly to a specific step.
  //
  jumpToStep = (step: number) => {
    if (this.state.id && this.state.id > 0) {
      this.setState({ currentStep: step });
    }
  };

  //
  // getSteps
  // Builds the definitions of the steps required for each of the supported integration types. These definitions
  // are passed to the Steps component during rendering.
  //
  getSteps = () => {
    const stepClickEnabled =
      this.state.id &&
      this.state.id > 0 &&
      !this.state.enableRunning &&
      !this.state.waitingForSave;

    const steps = [
      {
        id: "triggers",
        text: "Triggers",
        onClick: () => this.jumpToStep(1),
        disabled: !stepClickEnabled,
      },
      {
        id: "destination",
        text: "Name and destination",
        onClick: () => this.jumpToStep(2),
        disabled: !stepClickEnabled,
      },
    ];

    if (this.state.integrationType == "slack") {
      steps.push({
        id: "payload",
        text: "Review message",
        onClick: () => this.jumpToStep(3),
        disabled: !stepClickEnabled,
      });
    }
    if (this.state.integrationType == "jira") {
      steps.push({
        id: "payload",
        text: "Review content",
        onClick: () => this.jumpToStep(3),
        disabled: !stepClickEnabled,
      });
    }
    if (this.state.integrationType == "webhook") {
      steps.push({
        id: "payload",
        text: "Review payload",
        onClick: () => this.jumpToStep(3),
        disabled: !stepClickEnabled,
      });
    }
    if (this.state.integrationType === "email") {
      steps.push({
        id: "payload",
        text: "Review email",
        onClick: () => this.jumpToStep(3),
        disabled: !stepClickEnabled,
      });
    }
    steps.push({
      id: "enabled",
      text: "Enable/disable",
      onClick: () => this.jumpToStep(4),
      disabled: !stepClickEnabled,
    });
    return steps;
  };

  //
  // getPrevButton
  // Renders a previous button for the page footer, applying the disabled state based on various ongoing activities
  //
  getPrevButton = (newStep: number) => (
    <Button
      tertiary
      disabled={
        this.state.waitingForSave ||
        this.state.waitingFoClose ||
        this.state.submitLoading
      }
      onClick={() => this.setState({ currentStep: newStep })}
      className={"next"}
    >
      <Icon name="arrow" direction={270} />
      <span>Go back</span>
    </Button>
  );

  //
  // getCancelCloseButton
  // Renders a cancel/close button for the page footer, applying the disabled state based on various ongoing activities. Cancel
  // is used when the integration is *new* and indicates that the user can effectively abort the creation of the integration instance.
  //
  getCancelCloseButton = () => (
    <>
      {this.state.waitingForSave && <LoadingIcon size={12} />}
      {!this.state.waitingForSave && (
        <Button
          tertiary
          loading={this.state.waitingForSave}
          disabled={this.state.waitingFoClose || this.state.submitLoading}
          onClick={this.onClickCloseCancel}
          className={"cancel"}
        >
          {this.state.isNewIntegration ? "Cancel" : "Close"}
        </Button>
      )}
    </>
  );

  //
  // renderActions
  // Based on the current step in the workflow for the selected integration type, renders the footer of the page
  //
  renderActions = () => {
    let prevButton = null;
    let nextButton = null;

    switch (this.state.currentStep) {
      case 1: {
        if (Object.keys(this.state.selectedUUIDs).length === 0) {
          prevButton = (
            <div className={"error-msg"}>Please select at least 1 trigger</div>
          );
        }
        nextButton = (
          <>
            {this.getCancelCloseButton()}
            <Button
              className="next"
              filledPrimary
              disabled={
                Object.keys(this.state.selectedUUIDs).length === 0 ||
                this.state.waitingForSave
              }
              onClick={() => this.setState({ currentStep: 2 })}
            >
              Next <Icon name={"arrow"} direction={90} />
            </Button>
          </>
        );
        break;
      }
      case 2: {
        if (this.state.integrationType === "webhook") {
          const buttonDefs = this.validateDefineWebhookTarget();
          prevButton = (
            <>
              {this.getPrevButton(1)}
              <div className={"error-msg"}>{buttonDefs.msg}</div>
            </>
          );
          nextButton = (
            <>
              {this.getCancelCloseButton()}
              <Button
                className="next"
                filledPrimary
                loading={this.state.submitLoading}
                disabled={
                  this.state.submitLoading ||
                  Object.keys(this.state.selectedUUIDs).length === 0 ||
                  !buttonDefs.valid ||
                  this.state.waitingForSave
                }
                onClick={() => {
                  if (!this.webhooksParamsErrors()) this.submit(3);
                }}
              >
                Next <Icon name={"arrow"} direction={90} />
              </Button>
            </>
          );
        } else if (this.state.integrationType === "slack") {
          const buttonDefs = this.validateSlackTarget();
          prevButton = (
            <>
              {this.getPrevButton(1)}
              <div className={"error-msg"}>{buttonDefs.msg}</div>
            </>
          );
          nextButton = (
            <>
              {this.getCancelCloseButton()}
              <Button
                className="next"
                filledPrimary
                loading={this.state.submitLoading}
                disabled={
                  this.state.submitLoading ||
                  Object.keys(this.state.selectedUUIDs).length === 0 ||
                  !buttonDefs.valid ||
                  this.state.waitingForSave
                }
                onClick={() => {
                  if (!this.messagingParamsErrors()) this.submit(3);
                }}
              >
                Next <Icon name={"arrow"} direction={90} />
              </Button>
            </>
          );
        } else if (this.state.integrationType === "jira") {
          const buttonDefs = this.validateJiraTarget();
          prevButton = (
            <>
              {this.getPrevButton(1)}
              <div className={"error-msg"}>{buttonDefs.msg}</div>
            </>
          );
          nextButton = (
            <>
              {this.getCancelCloseButton()}
              <Button
                className="next"
                filledPrimary
                loading={this.state.submitLoading}
                disabled={
                  this.state.submitLoading ||
                  Object.keys(this.state.selectedUUIDs).length === 0 ||
                  !buttonDefs.valid ||
                  this.state.waitingForSave
                }
                onClick={() => {
                  this.submit(3);
                }}
              >
                Next <Icon name={"arrow"} direction={90} />
              </Button>
            </>
          );
        } else if (this.state.integrationType === "email") {
          const buttonDefs = this.validateEmailTarget();
          prevButton = (
            <>
              {this.getPrevButton(1)}
              <div className={"error-msg"}>{buttonDefs.msg}</div>
            </>
          );
          nextButton = (
            <>
              {this.getCancelCloseButton()}
              <Button
                className={"next"}
                filledPrimary
                loading={this.state.submitLoading}
                disabled={
                  this.state.submitLoading ||
                  Object.keys(this.state.selectedUUIDs).length === 0 ||
                  !buttonDefs.valid ||
                  this.state.waitingForSave
                }
                onClick={() => this.submit(3)}
                arrow
              >
                Next
              </Button>
            </>
          );
        }
        break;
      }
      case 3: {
        let disabled =
          this.state.submitLoading ||
          Object.keys(this.state.selectedUUIDs).length === 0 ||
          this.state.waitingForSave;

        let errorMsg = "";
        if (!disabled) {
          // Ensure none of the UUID templates are blank;
          const selectedUUIDs = Object.keys(this.state.selectedUUIDs);
          for (let i = 0; i < selectedUUIDs.length; i++) {
            if (
              this.state.selectedUUIDs[selectedUUIDs[i]] &&
              !this.state.payloadTemplates[selectedUUIDs[i]]
            ) {
              disabled = true;
              break;
            }
          }

          // check all appropriate Jira issue field values
          if (this.state.integrationType === "jira") {
            const { valid, msg } = this.validateJiraFields();
            disabled = !valid;
            errorMsg = msg;
          } else if (this.state.integrationType == "email") {
            const { valid, msg } = this.validateEmailPayloads();
            disabled = !valid;
            errorMsg = msg;
          }
        }

        prevButton = (
          <>
            {this.getPrevButton(2)}
            <div className={"error-msg"}>{errorMsg}</div>
          </>
        );

        nextButton = (
          <>
            {this.getCancelCloseButton()}
            <Button
              className="next"
              filledPrimary
              disabled={disabled}
              loading={this.state.submitLoading}
              onClick={() => {
                this.submit(4);
              }}
            >
              Next
              <Icon name={"arrow"} direction={90} />
            </Button>
          </>
        );
        break;
      }
      case 4: {
        prevButton = this.getPrevButton(3);
        nextButton = (
          <>
            {this.getCancelCloseButton()}
            <Button
              className="next"
              filledPrimary
              disabled={
                this.state.submitLoading ||
                Object.keys(this.state.selectedUUIDs).length === 0 ||
                !this.state.integrationType ||
                (this.state.description &&
                  this.state.description.trim() === "") ||
                (this.state.uniqueId && this.state.uniqueId.trim() === "") ||
                this.state.enableRunning
              }
              loading={this.state.waitingFoClose}
              onClick={this.close}
            >
              {"Finish"}
            </Button>
          </>
        );
        break;
      }
      case 5: {
        prevButton = this.getPrevButton(4);
        nextButton = (
          <>
            {this.getCancelCloseButton()}
            <Button
              className="next"
              filledPrimary
              disabled={
                this.state.submitLoading ||
                Object.keys(this.state.selectedUUIDs).length === 0 ||
                !this.state.integrationType ||
                (this.state.description &&
                  this.state.description.trim() === "") ||
                (this.state.uniqueId && this.state.uniqueId.trim() === "") ||
                this.state.enableRunning
              }
              loading={this.state.waitingFoClose}
              onClick={() => this.close()}
            >
              Finish
            </Button>
          </>
        );
        break;
      }
      default:
    }

    return (
      <>
        <div className="left">{prevButton}</div>
        <div className="right">{nextButton}</div>
      </>
    );
  };

  //
  // isMessagingIntegrationType
  // indicates that the currently selected integration type is an implementation of the messaging interface
  //
  isMessagingIntegrationType = (type: IntegrationTypeString) => {
    return type === "slack";
  };

  //
  // prepareIntegrationParams
  // Extracts a bunch of parameters from the integration instance ready to be submitted for saving to the backend.
  // @see submit()
  //
  prepareIntegrationParams = () => {
    const integrationType = IntegrationTypeStrings[this.state.integrationType];
    const { id } = this.state;
    const { enabled } = this.state;
    const uuids = Object.keys(this.state.selectedUUIDs);
    const httpHeaders = {} as IStringMap;
    if (
      this.state.webhooks[this.state.integrationType] &&
      this.state.webhooks[this.state.integrationType].headerParams
    ) {
      this.state.webhooks[this.state.integrationType].headerParams.map((p) => {
        httpHeaders[p.name.trim()] = p.value.trim();
      });
    }
    const urlParams = {} as IStringMap;
    if (
      this.state.webhooks[this.state.integrationType] &&
      this.state.webhooks[this.state.integrationType].urlParams
    ) {
      this.state.webhooks[this.state.integrationType].urlParams.map((p) => {
        urlParams[p.name.trim()] = p.value.trim();
      });
    }
    const basicAuth =
      this.state.webhooks[this.state.integrationType] &&
      this.state.webhooks[this.state.integrationType].basicAuth
        ? { ...this.state.webhooks[this.state.integrationType].basicAuth }
        : {};
    const { webhookID } = this.state.webhooks[this.state.integrationType]
      ? this.state.webhooks[this.state.integrationType]
      : { webhookID: undefined };

    if (basicAuth.username) {
      basicAuth.username = basicAuth.username.trim();
    }
    if (basicAuth.password) {
      // encode the password as base64 for the trip back to the server
      basicAuth.password = basicAuth.password.trim();
      basicAuth.password = btoa(basicAuth.password);
    }

    let selectedChannel;
    if (this.isMessagingIntegrationType(this.state.integrationType)) {
      selectedChannel =
        this.state.messagingServices[this.state.integrationType].channel;
    }
    let selectedJiraProject;
    let selectedJiraProjectId;
    if (this.state.integrationType === "jira" && this.state.jiraProject) {
      selectedJiraProject = this.state.jiraProject.name;
      selectedJiraProjectId = this.state.jiraProject.id;
    }

    const { toAddress, fromAddress, fromName } =
      this.state.emails[this.state.integrationType] ?? {};

    return {
      id,
      webhookID,
      integrationType,
      uuids,
      httpHeaders,
      urlParams,
      basicAuth,
      enabled,
      selectedChannel,
      selectedJiraProject,
      selectedJiraProjectId,
      toAddress,
      fromName,
      fromAddress,
    };
  };

  //
  // submit
  // Takes the current attributes of the integration instance and writes the to the back-end.
  //
  submit = (next?: number) => {
    const nextTab = next ? next : 4;
    const {
      id,
      integrationType,
      uuids,
      httpHeaders,
      urlParams,
      basicAuth,
      enabled,
      selectedChannel,
      selectedJiraProject,
      selectedJiraProjectId,
      toAddress,
      fromName,
      fromAddress,
    } = this.prepareIntegrationParams();

    if (id && id > 0 && this.state.updated) {
      this.setState({ submitLoading: true });
      this.props
        .dispatch(
          updateIntegration(
            id,
            integrationType,
            this.state.uniqueId?.trim() ?? "",
            this.state.description?.trim() ?? "",
            uuids,
            this.state.webhooks[this.state.integrationType]
              ? this.state.webhooks[this.state.integrationType].webhookURL
              : "",
            httpHeaders,
            urlParams,
            basicAuth,
            enabled,
            this.state.payloadTemplates,
            this.state.webhooks[this.state.integrationType]
              ? this.state.webhooks[this.state.integrationType]
                  .ignoreSSLCertCheck
              : undefined,
            selectedChannel,
            selectedJiraProject,
            selectedJiraProjectId,
            this.state.jiraIssueValues,
            toAddress,
            fromName,
            fromAddress,
            this.state.emailSubjects
          )
        )
        .then(() => {
          trackEvent("Integration - Edited", {
            id: id,
            type: this.state.integrationType,
            url: this.state.webhooks[this.state.integrationType]
              ? this.state.webhooks[this.state.integrationType].webhookURL
              : null,
            channel: this.state.messagingServices[this.state.integrationType]
              ? this.state.messagingServices[this.state.integrationType].channel
              : null,
          });
          this.setState({
            submitLoading: false,
            currentStep: nextTab,
            updated: false,
          });
          // reload our notifications so we can see which ones have this integration attached
          this.props.dispatch(fetchOrgNotificationSettings(true));
        })
        .catch(() => {
          this.setState({ submitLoading: false });
        });
    } else if (!id || id <= 0) {
      this.setState({ submitLoading: true });
      this.props
        .dispatch(
          createIntegration(
            integrationType,
            this.state.uniqueId?.trim() ?? "",
            this.state.description?.trim() ?? "",
            uuids,
            this.state.webhooks[this.state.integrationType]
              ? this.state.webhooks[this.state.integrationType].webhookURL
              : "",
            httpHeaders,
            urlParams,
            basicAuth,
            this.state.payloadTemplates,
            this.state.webhooks[this.state.integrationType]
              ? this.state.webhooks[this.state.integrationType]
                  .ignoreSSLCertCheck
              : undefined,
            this.state.messagingServices[this.state.integrationType]
              ? this.state.messagingServices[this.state.integrationType].channel
              : undefined,
            selectedJiraProject,
            selectedJiraProjectId,
            this.state.jiraIssueValues,
            toAddress,
            fromName,
            fromAddress,
            this.state.emailSubjects
          )
        )
        .then((id) => {
          trackEvent("Integration - Created", {
            id: id,
            type: this.state.integrationType,
            url: this.state.webhooks[this.state.integrationType]
              ? this.state.webhooks[this.state.integrationType].webhookURL
              : null,
            channel: this.state.messagingServices[this.state.integrationType]
              ? this.state.messagingServices[this.state.integrationType].channel
              : null,
          });
          this.setState({
            id: id,
            submitLoading: false,
            currentStep: nextTab,
            updated: false,
          });
          // reload our notifications so we can see which ones have this integration attached
          this.props.dispatch(fetchOrgNotificationSettings(true));
        })
        .catch(() => {
          this.setState({ submitLoading: false });
        });
    } else {
      this.setState({ submitLoading: false, currentStep: nextTab });
    }
  };

  //
  // enableDisableNewIntegration
  // Callback used by the EnableIntegrationStep to set the enabled state if a brand new integration.
  //
  enableDisableNewIntegration = () => {
    const { newIntegrationEnabledFlag, isNewIntegration } = this.state;
    if (isNewIntegration) {
      this.setState({ newIntegrationEnabledFlag: !newIntegrationEnabledFlag });
    }
  };

  //
  // enableDisableNewIntegration
  // Callback used by the EnableIntegrationStep to set the enabled state if an existing integration.
  //
  enableDisable = async () => {
    if (!this.state.id) {
      return;
    }
    const { newIntegrationEnabledFlag, isNewIntegration } = this.state;
    let { enabled } = this.state;
    if (isNewIntegration) {
      enabled = newIntegrationEnabledFlag;
    }
    this.setState({ enableRunning: true });
    try {
      await this.runBackgroundSave();
      this.props
        .dispatch(enableIntegration(this.state.id, !enabled))
        .then((id) => {
          this.setState({ enabled: !enabled, enableRunning: false });
          this.props.dispatch(
            addDefaultSuccessAlert(
              "Successfully " +
                (enabled ? "disabled" : "enabled") +
                " the integration."
            )
          );
          if (!enabled) {
            trackEvent("Integration - Enabled", {
              id: id,
              type: this.state.integrationType,
              url:
                this.state.integrationType != "slack"
                  ? this.state.webhooks[this.state.integrationType].webhookURL
                  : "",
            });
          } else {
            trackEvent("Integration - Disabled", {
              id: id,
              type: this.state.integrationType,
              url:
                this.state.integrationType != "slack"
                  ? this.state.webhooks[this.state.integrationType].webhookURL
                  : "",
            });
          }
        });
    } catch (e) {
      this.setState({ enableRunning: false });
      this.props.dispatch(
        addDefaultUnknownErrorAlert(
          "Failed to " +
            (enabled ? "disable" : "enable") +
            " the integration: " +
            e
        )
      );
    }
  };

  //
  // close
  // Represents the final action in the integration create/edit workflow. The integration will be saved, and if required
  // enabled by this step, particularly if it is new.
  //
  close = async () => {
    const {
      id,
      integrationType,
      uuids,
      httpHeaders,
      urlParams,
      basicAuth,
      enabled,
      selectedChannel,
      selectedJiraProject,
      selectedJiraProjectId,
      toAddress,
      fromName,
      fromAddress,
    } = this.prepareIntegrationParams();
    if (
      id &&
      id > 0 &&
      (this.state.updated === true ||
        (this.state.isNewIntegration && this.state.newIntegrationEnabledFlag))
    ) {
      //
      // we need to cater for the enabled flag of NEW integrations separately from the normal update cycle.
      // when we create a new integration, the enabled flag is necessarily kept to FALSE until this last Finish step.
      // it is now that we need to apply this enablement flag if selected
      //

      let setEnabled = enabled;
      if (this.state.isNewIntegration) {
        setEnabled = this.state.newIntegrationEnabledFlag;
      }
      this.setState({ waitingFoClose: true });
      try {
        await this.props
          .dispatch(
            updateIntegration(
              id,
              integrationType,
              this.state.uniqueId?.trim() ?? "",
              this.state.description?.trim() ?? "",
              uuids,
              this.state.webhooks[this.state.integrationType]
                ? this.state.webhooks[this.state.integrationType].webhookURL
                : "",
              httpHeaders,
              urlParams,
              basicAuth,
              setEnabled,
              this.state.payloadTemplates,
              this.state.webhooks[this.state.integrationType]
                ? this.state.webhooks[this.state.integrationType]
                    .ignoreSSLCertCheck
                : undefined,
              selectedChannel,
              selectedJiraProject,
              selectedJiraProjectId,
              this.state.jiraIssueValues,
              toAddress,
              fromName,
              fromAddress,
              this.state.emailSubjects
            )
          )
          .then(() => {
            trackEvent("Integration - Edited", {
              id: id,
              type: this.state.integrationType,
              url:
                integrationType == WEBHOOK_INTEGRATION_TYPE
                  ? this.state.webhooks[this.state.integrationType].webhookURL
                  : null,
            });
          });
        this.props.history.push("/settings/integrations");
        this.setState({ waitingFoClose: false });
      } catch (e) {
        this.setState({ waitingFoClose: false });
        this.props.dispatch(
          addDefaultUnknownErrorAlert("Failed to save integration: " + e)
        );
      }
    } else {
      this.props.history.push("/settings/integrations");
    }
  };

  //
  // debouncedLiquidMessageForNotificationUUID
  // Provides a debounced wrapper around a call to function populateLiquidMessageForNotificationUUID(). This function is
  // used to send the current slack message template to the backend so that it can be liquidized for fisplay to the user.
  // It is called as the user is typing changes to the message template (and hence the debounce).
  // @see onChangePayloadTemplate()
  //
  debouncedLiquidMessageForNotificationUUID = _debounce(
    (service: string, integrationId: string, template: string, uuid: string) =>
      this.props.dispatch(
        populateLiquidMessageForNotificationUUID(
          service,
          integrationId,
          template,
          uuid
        )
      ),
    500
  );

  //
  // onChangePayloadTemplate
  // Callback from ConfigureWebhookPayloadsStep, ConfigureSlackPayloadsStep and ConfigureEmailPayloadsStep to update
  // the payload template for the currently selected trigger. If the current integration type is slack, then the change
  // is debounced through a call to the back-end function populateLiquidMessageForNotificationUUID()
  // to liquidize the new template for display to the user.
  //
  onChangePayloadTemplate = (template: string) => {
    this.setState((state) => {
      return {
        ...state,
        payloadTemplates: {
          ...state.payloadTemplates,
          [this.state.selectedTestUUID]: template,
        },
        updated: true,
        updatedTemplate: true,
      };
    });
    if (
      this.state.integrationType == "slack" ||
      this.state.integrationType == "email"
    ) {
      this.debouncedLiquidMessageForNotificationUUID(
        this.state.integrationType == "slack"
          ? MESSAGING_SERVICE_SLACK
          : MESSAGING_SERVICE_EMAIL,
        this.state.uniqueId?.trim() ?? "",
        template,
        this.state.selectedTestUUID
      );
    }
  };

  //
  // getDefaultTemplate
  // Retrieves the default json payload defined for the currently selected trigger. This is used to initialise (or re-initialise)
  // the json payload for webhook integration types.
  //
  getDefaultTemplate = (uuid: string) => {
    let defaultTemplate = "";
    const notification = this.props.notificationsByUUID?.[uuid];
    if (notification) {
      defaultTemplate = notification.defaultPayloadScript;
    }

    return defaultTemplate;
  };

  //
  // getDefaultMessageTemplate
  // Retrieves the default liquid message template defined for the currently selected trigger. This is used to initialise (or re-initialise)
  // the liquid message body for slack integration types.
  //
  getDefaultMessageTemplate = (uuid: string) => {
    let defaultMessage = "";

    const notification = this.props.notificationsByUUID?.[uuid];
    if (notification) {
      defaultMessage = notification.exampleLiquidTextMessage;
    }

    return defaultMessage;
  };

  //
  // getDefaultSubjectLine
  // Gets the default subject line for an email integration trigger.
  //
  getDefaultSubjectLine = (uuid: string) => {
    let subject = "";

    const notification = this.props.notificationsByUUID?.[uuid];
    if (notification) {
      subject = "UpGuard: " + notification.headlineVarsReplaced;
    }

    return subject;
  };

  setSubjectLine = (subject: string) =>
    this.setState((state) => ({
      emailSubjects: {
        ...state.emailSubjects,
        [state.selectedTestUUID]: subject,
      },
      updated: true,
    }));

  resetSelectedEmailSubjectToDefault = () =>
    this.setState((state) => ({
      emailSubjects: {
        ...state.emailSubjects,
        [state.selectedTestUUID]: this.getDefaultSubjectLine(
          state.selectedTestUUID
        ),
      },
      updated: true,
    }));

  //
  // resetPayloadTemplateToDefault
  // Callback used by ConfigureSlackPayloadsStep and ConfigureWebhookPayloadsStep to notify the user's intention to reset either
  // the json payload for a webhook integration or the liquid message for a slack integration (for the currently selected trigger).
  // The function uses a confirmation dialog to confirm the operation and then re-applies the defaults.
  // @see getDefaultTemplate, getDefaultMessageTemplate
  //
  resetPayloadTemplateToDefault = () => {
    this.props.dispatch(
      openModal(ConfirmationModalName, {
        title: `Reset template`,
        buttonText: "Reset",
        description:
          this.state.integrationType == "slack" ||
          this.state.integrationType === "email"
            ? `Are you sure you would like to reset your message template back to the default? All changes to the message will be lost`
            : `Are you sure you would like to reset your payload template back to the default? All changes to the template will be lost`,
        buttonAction: async () => {
          this.setState((state) => {
            let defaultTemplate = "";
            let updated;
            if (this.state.integrationType == "webhook") {
              defaultTemplate = this.getDefaultTemplate(
                this.state.selectedTestUUID
              );
              updated =
                defaultTemplate !==
                this.state.payloadTemplates[this.state.selectedTestUUID];
            } else if (
              this.state.integrationType == "slack" ||
              this.state.integrationType === "email"
            ) {
              defaultTemplate = this.getDefaultMessageTemplate(
                this.state.selectedTestUUID
              );
              updated =
                defaultTemplate !==
                this.state.payloadTemplates[this.state.selectedTestUUID];

              if (updated) {
                this.props.dispatch(
                  populateLiquidMessageForNotificationUUID(
                    this.state.integrationType === "slack"
                      ? MESSAGING_SERVICE_SLACK
                      : MESSAGING_SERVICE_EMAIL,
                    this.state.uniqueId?.trim() ?? "",
                    defaultTemplate,
                    this.state.selectedTestUUID
                  )
                );
              }
            }
            const newState = {
              ...state,
              payloadTemplates: {
                ...state.payloadTemplates,
                [this.state.selectedTestUUID]: defaultTemplate,
              },
            };
            if (updated) {
              newState.updated = true;
              newState.updatedTemplate = true;
            }
            return newState;
          });
        },
      })
    );

    ConfirmationModalName;
  };

  //
  // setBackgroundSaveTimer
  // Initiates the background save timer for existine integration instances. This is just a ping that calls runBackgroundSave
  // to do the actual saving of progress.
  //
  setBackgroundSaveTimer = () => {
    this.backgroundSaveTimer = window.setInterval(async () => {
      if (
        !this.state.lastChangeAt ||
        this.state.lastChangeAt >
          new Date().getTime() - MINUTES_SESSION_TIMEOUT_WARNING * 60 * 1000
      ) {
        await this.runBackgroundSave();
      } else if (
        this.state.lastChangeAt <=
        new Date().getTime() - MINUTES_SESSION_TIMEOUT_WARNING * 60 * 1000
      ) {
        this.props.dispatch(
          addMessageAlert({
            message:
              "Your session is about to expire. Any unsaved edits will be lost",
            type: BannerType.WARNING,
            subMessages: [
              "Please save your changes or continue to update the integration details.",
            ],
          })
        );
      }
    }, 10000);
  };

  //
  // startBackgroundSave
  // Uses setBackgroundSaveTimer to initiate the background save process. This is initiated immediately on component mount, and
  // it is the logic in runBackgroundSave() that determines if anything needs to be done each tick.
  //
  startBackgroundSave = () => {
    if (!this.backgroundSaveTimer) {
      this.setBackgroundSaveTimer();
    }
  };

  //
  // stopBackgroundSave
  // Kills the background save timer.
  //
  stopBackgroundSave = () => {
    if (this.backgroundSaveTimer) {
      clearInterval(this.backgroundSaveTimer);
      this.backgroundSaveTimer = undefined;
      this.setState({
        lastChangeAt: undefined,
      });
    }
  };

  //
  // runBackgroundSave
  // Thsi function is called for every tick of the background save timer. If the current integration instance has an id set
  // (either it was existing or it is a new instance that has been saved at least once) and there have been attrubutes updated
  // since the last save, then the integration attributes are packaged up and sent to the bck end for saving.
  //
  runBackgroundSave = async () => {
    if (this.state.id && this.state.id > 0 && this.state.updated) {
      let success = true;
      try {
        this.setState({
          backgroundSaveRunning: true,
        });
        const {
          id,
          integrationType,
          uuids,
          httpHeaders,
          urlParams,
          basicAuth,
          enabled,
          selectedChannel,
          selectedJiraProject,
          selectedJiraProjectId,
          toAddress,
          fromName,
          fromAddress,
        } = this.prepareIntegrationParams();

        await this.props.dispatch(
          updateIntegration(
            id as number,
            integrationType,
            this.state.uniqueId?.trim() ?? "",
            this.state.description?.trim() ?? "",
            uuids,
            this.state.webhooks[this.state.integrationType]
              ? this.state.webhooks[this.state.integrationType].webhookURL
              : "",
            httpHeaders,
            urlParams,
            basicAuth,
            enabled,
            this.state.payloadTemplates,
            this.state.webhooks[this.state.integrationType]
              ? this.state.webhooks[this.state.integrationType]
                  .ignoreSSLCertCheck
              : undefined,
            selectedChannel,
            selectedJiraProject,
            selectedJiraProjectId,
            this.state.jiraIssueValues,
            toAddress,
            fromName,
            fromAddress,
            this.state.emailSubjects
          )
        );
      } catch (e) {
        // Failed to perform background save - quietly
        success = false;
      }
      this.setState({
        backgroundSaveRunning: false,
        updated: !success,
      });
    }
  };

  //
  // setTriggerSearchText
  // Callback from SelectTriggersStep to set the current search string for the triggers liost
  //
  setTriggerSearchText = (value: string) => {
    this.setState({
      searchText: value,
    });
  };

  //
  // setSelectedMessagingChannel
  // Callback from SelectSlackDestinationStep to set the slack channel being targeted by the current slack integration
  //
  setSelectedMessagingChannel = (channel: string) => {
    this.setState((state) => {
      return {
        messagingServices: {
          ...state.messagingServices,
          [state.integrationType]: {
            ...state.messagingServices[state.integrationType],
            channel: channel,
          },
        },
        updated: true,
      };
    });
  };

  render() {
    const integration = this.props.integrations?.find(
      (i) => i.id == this.props.integrationId
    );

    return (
      <>
        <StepsWithSections id="create_integration">
          <PageHeader
            history={this.props.history}
            breadcrumbs={[
              { text: "Integration Settings", to: "/settings/integrations" },
              {
                text:
                  this.props.integrationId > 0
                    ? "Update Integration"
                    : "Create Integration",
              },
            ]}
            title={
              this.props.integrationId > 0
                ? "Update Integration"
                : "Create Integration"
            }
            backAction={() => {
              this.onClickCloseCancel();
            }}
            backText="Back to Settings"
          />
          <Steps steps={this.getSteps()} currentStep={this.state.currentStep} />
          {!this.state.isNewIntegration && this.state.enabled && (
            <div className={"section"}>
              <InfoBanner
                type={BannerType.WARNING}
                message={
                  "Attention: This integration is currently active. Any changes you make will be saved automatically."
                }
              />
            </div>
          )}
          <div className="section">
            {this.state.currentStep === 1 && (
              <SelectTriggersStep
                loading={this.props.loading}
                notifications={this.props.notifications ?? []}
                toggleSelectedUUID={this.toggleSelectedUUID}
                setTriggerSearchText={this.setTriggerSearchText}
                selectedUUIDs={this.state.selectedUUIDs || {}}
                searchText={this.state.searchText || ""}
              />
            )}
            {this.state.currentStep === 2 && (
              <>
                {this.state.integrationType == "webhook" && (
                  <SelectWebhookDestinationStep
                    history={this.props.history}
                    dispatch={this.props.dispatch}
                    loading={this.props.loading}
                    webhook={this.state.webhooks["webhook"]}
                    description={this.state.description}
                    webhookErrorsFound={this.state.showParamsErrors}
                    setDescription={this.setDescription}
                    setWebhookURL={this.setWebhookURL}
                    deleteWebhookParam={this.deleteWebhookParam}
                    setWebhookParam={this.setWebhookParam}
                    newWebhookParam={this.newWebhookParam}
                    setBasicAuthParam={this.setWebhookBasicAuth}
                    deleteBasicAuth={this.deleteWebhooksBasicAuth}
                  />
                )}
                {this.state.integrationType == "slack" && (
                  <SelectSlackDestinationStep
                    history={this.props.history}
                    loading={this.props.loading}
                    dispatch={this.props.dispatch}
                    slackChannelData={this.props.slackChannelData}
                    description={this.state.description}
                    setDescription={this.setDescription}
                    selectedChannel={
                      this.state.messagingServices["slack"]
                        ? this.state.messagingServices["slack"].channel
                        : undefined
                    }
                    setSelectedChannel={this.setSelectedMessagingChannel}
                  />
                )}
                {this.state.integrationType == "jira" && (
                  <SelectJiraDestinationStep
                    history={this.props.history}
                    loading={this.props.loading}
                    dispatch={this.props.dispatch}
                    description={this.state.description}
                    setDescription={this.setDescription}
                    jiraProjectsList={this.props.jiraProjectsList}
                    selectedJiraAccount={
                      hasActiveConnection(
                        this.props.jiraConnections || ({} as OAuthState)
                      )
                        ? getJiraTokenSiteName(
                            this.props.jiraConnections?.connections[0]
                              .teamName || ""
                          )
                        : this.props.jiraConnections?.loading
                          ? "loading"
                          : "no account selected"
                    }
                    selectedJiraProject={this.state.jiraProject?.name ?? ""}
                    selectedJiraProjectId={this.state.jiraProject?.id ?? ""}
                    setSelectedProject={this.setSelectedJiraProject}
                  />
                )}
                {this.state.integrationType == "email" && (
                  <SelectEmailIntegrationStep
                    loading={this.props.loading}
                    toEmailAddress={this.state.emails["email"].toAddress}
                    setToEmailAddress={this.setEmailToAddress}
                    fromName={this.state.emails["email"].fromName}
                    setFromName={this.setEmailFromName}
                    fromEmailAddress={this.state.emails["email"].fromAddress}
                    setFromEmailAddress={this.setEmailFromAddress}
                    description={this.state.description}
                    setDescription={this.setDescription}
                    orgAccessCustomEmailDomain={
                      this.props.canAccessCustomEmailDomain
                    }
                    customEmailDomains={this.state.orgSenderEmail}
                    customEmailDomainsLoading={this.state.customEmailsLoading}
                  />
                )}
              </>
            )}
            {this.state.currentStep === 3 && (
              <>
                {this.state.integrationType == "webhook" && (
                  <ConfigureWebhookPayloadsStep
                    history={this.props.history}
                    dispatch={this.props.dispatch}
                    loading={this.props.loading}
                    notifications={this.props.notifications ?? []}
                    selectedUUIDs={this.state.selectedUUIDs}
                    webhook={this.state.webhooks["webhook"]}
                    selectedTestUUID={this.state.selectedTestUUID}
                    selectedTestJSON={this.state.testJSON}
                    selectedPayloadTemplate={
                      this.state.payloadTemplates?.[
                        this.state.selectedTestUUID
                      ] ?? ""
                    }
                    setSelectedTestNotification={
                      this.setSelectedTestNotification
                    }
                    validateDefineWebhookTarget={
                      this.validateDefineWebhookTarget
                    }
                    webhookTestResponse={this.props.webhookTestResponse}
                    prepareIntegrationParams={this.prepareIntegrationParams}
                    resetPayloadTemplateToDefault={
                      this.resetPayloadTemplateToDefault
                    }
                    onChangePayloadTemplate={this.onChangePayloadTemplate}
                  />
                )}
                {this.state.integrationType == "slack" && (
                  <ConfigureSlackPayloadsStep
                    history={this.props.history}
                    dispatch={this.props.dispatch}
                    loading={this.props.loading}
                    selectedChannel={
                      this.state.messagingServices["slack"].channel
                    }
                    notifications={this.props.notifications ?? []}
                    notificationsByUUID={this.props.notificationsByUUID ?? {}}
                    selectedUUIDs={this.state.selectedUUIDs}
                    selectedTestUUID={this.state.selectedTestUUID}
                    selectedTestJSON={this.state.testJSON}
                    selectedPayloadTemplate={
                      this.state.payloadTemplates?.[
                        this.state.selectedTestUUID
                      ] ?? ""
                    }
                    setSelectedTestNotification={
                      this.setSelectedTestNotification
                    }
                    validateSlackTarget={this.validateSlackTarget}
                    prepareIntegrationParams={this.prepareIntegrationParams}
                    resetPayloadTemplateToDefault={
                      this.resetPayloadTemplateToDefault
                    }
                    onChangePayloadTemplate={this.onChangePayloadTemplate}
                    getSlackExampleMessage={this.getSlackExampleMessage}
                  />
                )}
                {this.state.integrationType == "jira" && (
                  <ConfigureJiraIssueFieldsStep
                    history={this.props.history}
                    dispatch={this.props.dispatch}
                    loading={this.props.loading}
                    selectedProjectId={this.state.jiraProject?.id ?? ""}
                    selectedProjectName={this.state.jiraProject?.name ?? ""}
                    issueFieldValues={this.state.jiraIssueValues}
                    issueTypes={
                      this.props.jiraIssueTypes &&
                      this.props.jiraIssueTypes[
                        this.state.jiraProject?.id ?? ""
                      ]
                        ? this.props.jiraIssueTypes[
                            this.state.jiraProject?.id ?? ""
                          ]
                        : { loading: true }
                    }
                    setSelectedFieldValue={this.setSelectedJiraFieldValue}
                    notifications={this.props.notifications ?? []}
                    notificationsByUUID={this.props.notificationsByUUID ?? {}}
                    selectedUUIDs={this.state.selectedUUIDs}
                    selectedTestUUID={this.state.selectedTestUUID}
                    selectedTestJSON={this.state.testJSON}
                    setSelectedTestNotification={
                      this.setSelectedTestNotification
                    }
                    validateJiraTarget={this.validateJiraTarget}
                  />
                )}
                {this.state.integrationType === "email" && (
                  <ConfigureEmailPayloadsStep
                    dispatch={this.props.dispatch}
                    notifications={this.props.notifications ?? []}
                    notificationsByUUID={this.props.notificationsByUUID ?? {}}
                    selectedIntegrationID={
                      (this.state.isNewIntegration
                        ? this.state.id
                        : integration?.id) ?? 0
                    }
                    selectedUUIDs={this.state.selectedUUIDs}
                    selectedTestUUID={this.state.selectedTestUUID}
                    setSelectedTestNotification={
                      this.setSelectedTestNotification
                    }
                    resetPayloadTemplateToDefault={
                      this.resetPayloadTemplateToDefault
                    }
                    selectedPayloadTemplate={
                      this.state.payloadTemplates?.[
                        this.state.selectedTestUUID
                      ] ?? ""
                    }
                    selectedTestJSON={this.state.testJSON}
                    onChangePayloadTemplate={this.onChangePayloadTemplate}
                    selectedEmailSubject={
                      this.state.emailSubjects[this.state.selectedTestUUID] ??
                      ""
                    }
                    setSelectedEmailSubject={this.setSubjectLine}
                    resetSelectedEmailSubjectToDefault={
                      this.resetSelectedEmailSubjectToDefault
                    }
                    validateEmailTarget={this.validateEmailPayloads}
                    getEmailExampleMessage={this.getEmailExampleMessage}
                    useOrgLogo={
                      this.props.canAccessCustomEmailDomain &&
                      this.props.useOrgLogoForEmail
                    }
                    orgLogoUrl={this.props.orgLogoUrl}
                    currentUserEmail={this.props.currentUserEmail}
                  />
                )}
              </>
            )}
            {this.state.currentStep === 4 && (
              <EnableIntegrationStep
                history={this.props.history}
                loading={this.props.loading}
                dispatch={this.props.dispatch}
                isNewIntegration={this.state.isNewIntegration}
                newIntegrationEnabledFlag={this.state.newIntegrationEnabledFlag}
                enabled={this.state.enabled}
                enableRunning={this.state.enableRunning}
                enableDisable={this.enableDisable}
                enableDisableNewIntegration={this.enableDisableNewIntegration}
              />
            )}
            <div className="action-bar">
              <div className={"container"}>{this.renderActions()}</div>
            </div>
          </div>
        </StepsWithSections>
        <ConfirmationModalV2
          active={this.state.showCancelConfirmation}
          verticalCenter
          descriptionClassName={"vendor-report-confirmation-modal"}
          title={<h2>Discard new integration</h2>}
          description={`Your work on this new integration will be discarded. Are you sure you want to cancel?`}
          buttonText={"Discard"}
          cancelText={"Keep editing"}
          buttonAction={async () => {
            if (this.state.id && this.state.id > 0) {
              try {
                await this.props
                  .dispatch(deleteIntegration(this.state.id))
                  .then(() => {
                    // reload our notifications so we can see which ones have this integration attached
                    this.props.dispatch(fetchOrgNotificationSettings(true));
                  });
              } catch (e) {
                this.props.dispatch(
                  addDefaultUnknownErrorAlert(
                    "Failed to cancel the new integration: " + e
                  )
                );
                return false;
              }
            }
            this.props.history.push("/settings/integrations");
            return true;
          }}
          onClose={() => {
            this.setState({ showCancelConfirmation: false });
          }}
        />
      </>
    );
  }
}

export default appConnect<
  CreateIntegrationConnectedProps,
  unknown,
  CreateIntegrationRouteProps
>((state, props) => {
  const integrationId = parseInt(props.match.params?.integrationId ?? "");
  const newIntegrationType = props.match.params.type;
  const assuranceType = state.common.userData.assuranceType;
  const { orgIntegrationSettings, allOrgNotifications } = state.cyberRisk;
  const slackChannelData = getOAuth2MessagingChannelData(
    state,
    MESSAGING_SERVICE_SLACK
  );
  const populatedMessagePayloads = getMessagingIntegrationPopulatedTemplates(
    state,
    MESSAGING_SERVICE_SLACK
  );
  const populatedEmailMessagePayloads =
    getMessagingIntegrationPopulatedTemplates(state, MESSAGING_SERVICE_EMAIL);
  const jiraConnections = getOAuthConnectionData(state, JIRA_SERVICE);
  const jiraProjectsList = getJiraProjectData(state);
  const jiraAllIssuesTypesList = getJiraAllIssueTypesData(state);

  const canAccessCustomEmailDomain =
    state.common.userData.orgPermissions.includes(OrgCustomLogo);

  const currentUserEmail = state.common.userData.emailAddress;

  const userCanAccessVendorRisk =
    state.common.userData.orgPermissions.includes(OrgAccessVendors) &&
    state.common.userData.userPermissions.includes(UserVendorRiskEnabled);

  return {
    loading: !orgIntegrationSettings || !allOrgNotifications,
    newIntegrationType: newIntegrationType as IntegrationTypeString,
    integrationId,
    webhookTestResponse: state.cyberRisk.webhookTestResponse,
    notifications: allOrgNotifications?.definitions,
    notificationsByUUID: allOrgNotifications?.definitionsByUUID,
    integrations: orgIntegrationSettings?.integrations,
    assuranceType: assuranceType,
    availableLabels: state.cyberRisk.availableLabels ?? [],
    vendorTiers: state.cyberRisk.vendorTiers ?? [],
    slackChannelData: (slackChannelData ?? {
      loading: true,
    }) as ISlackChannelData,
    populatedMessagePayloads: populatedMessagePayloads ?? {},
    populatedEmailMessagePayloads: populatedEmailMessagePayloads ?? {},
    jiraConnections: jiraConnections,
    jiraProjectsList: jiraProjectsList as JiraProjectsList,
    jiraIssueTypes: jiraAllIssuesTypesList,
    canAccessCustomEmailDomain,
    orgLogoUrl: state.cyberRisk.orgLogoUrl,
    useOrgLogoForEmail: !!state.cyberRisk.orgFlags?.EnableLogoUsageTypeEmails,
    currentUserEmail,
    userCanAccessVendorRisk,
  };
})(CreateIntegration);
