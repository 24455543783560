import BaseAPI from "../rtkQueryApi";
import {
  IOrgUserInvite,
  IOrgUserWithFree,
  OrgCollaboratorUser,
  UserEmailAddress,
} from "../types/user";
import { Role } from "../../vendorrisk/types/roles";

export interface ListOrganisationUserEmailAddressesV1Response {
  userEmails: UserEmailAddress[];
}

export interface ListOrganisationUserInvitesV1Response {
  invites: IOrgUserInvite[];
}

export enum UsersTagTypes {
  orgUserEmailAddresses = "orgUserEmailAddresses",
  orgUserInvites = "orgUserInvites",
  orgUsers = "orgUsers",
  orgCollaborator = "orgCollaborators",
}

const UsersAPI = BaseAPI.enhanceEndpoints({
  addTagTypes: [...Object.values(UsersTagTypes)],
}).injectEndpoints({
  endpoints: (builder) => ({
    listOrganisationUserEmailAddressesV1: builder.query<
      ListOrganisationUserEmailAddressesV1Response,
      void
    >({
      providesTags: [UsersTagTypes.orgUserEmailAddresses],
      query: () => ({
        url: "/organisation/users/emails/v1",
        method: "GET",
      }),
    }),
    listOrganisationUserInvitesV1: builder.query<
      ListOrganisationUserInvitesV1Response,
      void
    >({
      providesTags: [UsersTagTypes.orgUserInvites],
      query: () => ({
        url: "/organisation/invites/v1/",
        method: "GET",
      }),
    }),

    listOrganisationUsersV1: builder.query<
      {
        users: IOrgUserWithFree[];
        freeRoles: Role[];
      },
      void
    >({
      query: () => ({
        url: "/organisation/users/v1",
        method: "GET",
      }),
      providesTags: [UsersTagTypes.orgUsers],
    }),

    getOrgCollaborators: builder.query<OrgCollaboratorUser[], void>({
      query: () => ({
        url: "organisation/collaborator/v1",
        method: "GET",
      }),
      providesTags: [UsersTagTypes.orgCollaborator],
    }),

    removeOrgCollaborator: builder.mutation<void, { userID: number }>({
      query: ({ userID }) => ({
        url: "organisation/collaborator/v1",
        method: "DELETE",
        params: {
          user: userID,
        },
      }),
      invalidatesTags: [UsersTagTypes.orgCollaborator],
    }),
  }),
});

export default UsersAPI;
