import { FC } from "react";
import ReportCard from "../../_common/components/ReportCard";
import { UserRouteParams } from "../UserBaseNavItems";
import { useRouteMatch } from "react-router-dom";
import UserBaseAPI from "../api/userbase.api";
import LoadingBanner from "../../_common/components/core/LoadingBanner";
import InfoTable, { InfoTableRow } from "../../_common/components/InfoTable";
import "./AboutUser.scss";
import TeamsPills from "./TeamsPills";
import SecurityRatingDisplay, { RatingSize } from "./SecurityRatingDisplay";

const AboutUser: FC = ({}) => {
  const match = useRouteMatch<UserRouteParams>();

  const userUUID = match.params.userUUID;

  const { data, isLoading } = UserBaseAPI.useGetUserBaseUserSummaryV1Query({
    userUUID: userUUID,
  });
  return (
    <ReportCard newStyles className="userbase-about-user">
      <div className="header">
        <span>User information</span>
      </div>

      {isLoading && <LoadingBanner />}

      {!isLoading && data && (
        <div className={"content"}>
          <InfoTable className="info-table">
            <InfoTableRow
              label="Name"
              rowClass={"no-max-height bottom-border"}
              value={data.user.name}
            />
            <InfoTableRow
              label="Email"
              rowClass={"no-max-height bottom-border"}
              value={data.user.email}
            />
            <InfoTableRow
              label="Role"
              rowClass={"no-max-height bottom-border"}
              value={data.user.roles.length > 0 ? data.user.roles[0] : ""}
            />
            <InfoTableRow
              label="Team"
              rowClass={"no-max-height bottom-border"}
              value={TeamsPills({ teams: data.user.teams })}
            />
            <InfoTableRow
              label="Rating"
              rowClass={"no-max-height bottom-border"}
              value={SecurityRatingDisplay({
                rating: data.user.rating,
                size: RatingSize.Small,
              })}
            />
          </InfoTable>
        </div>
      )}
    </ReportCard>
  );
};

export default AboutUser;
