import { FC } from "react";
import {
  IReportCardProps,
  ReportCard,
} from "../../../_common/components/ReportCard";

import "../../style/components/settings/ManageSettingsCard.scss";

const ManageSettingsCard: FC<IReportCardProps> = ({
  children,
  className,
  ...otherProps
}) => (
  <ReportCard
    {...otherProps}
    newStyles
    className={`manage-settings-card ${className ?? ""}`}
  >
    {children}
  </ReportCard>
);

export default ManageSettingsCard;
