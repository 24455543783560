import { useEffect, useState } from "react";
import {
  DefaultThunkDispatchProp,
  IUserData,
  IVendorFreeTrial,
} from "../types/redux";
import "../style/components/VendorFreeTrialIcon.scss";
import ModalV2, { BaseModalProps } from "./ModalV2";
import Button from "./core/Button";
import ContactSelect, {
  ContactDisplay,
} from "../../vendorrisk/components/contacts/ContactSelect";
import { IVendorContactResponse } from "../types/vendorContact";
import {
  getVendorFreeTrialInfo,
  sendVendorFreeTrialInvite,
  sendVendorFreeTrialPreviewEmail,
} from "../../vendorrisk/reducers/vendorFreeTrial.actions";
import {
  addDefaultSuccessAlert,
  addDefaultUnknownErrorAlert,
  addSimpleErrorAlert,
} from "../reducers/messageAlerts.actions";
import { trackEvent } from "../tracking";
import { ResponseError } from "../api";
import { SidePopupV2 } from "./DismissablePopup";
import { getVendorWords } from "../constants";
import { AssuranceType } from "../types/organisations";
import { fetchVendorMgtContacts } from "../../vendorrisk/reducers/vendorContacts.actions";
import { escapeRegExp } from "../helpers/string.helpers";
import RichTextEditV2 from "./RichTextEditV2";
import { appConnect } from "../types/reduxHooks";

const canInviteVendorToFreeTrial = (
  freeTrialInfo?: IVendorFreeTrial
): boolean => {
  if (!freeTrialInfo || freeTrialInfo.loading || freeTrialInfo.error)
    return false;
  return (
    freeTrialInfo.data.status !== VendorFreeTrialStatus.Customer &&
    freeTrialInfo.data.status !== VendorFreeTrialStatus.Trial
  );
};

const canInviteUserToFreeTrial = (
  freeTrialInfo?: IVendorFreeTrial
): boolean => {
  if (!freeTrialInfo || !canInviteVendorToFreeTrial(freeTrialInfo))
    return false;

  return (
    !freeTrialInfo.data.userClaimedTrialBefore &&
    !!freeTrialInfo.data.emailDomainPartOfVendor
  );
};

const defaultMessage = `Hi {{ReceiverName}},

I’m {{SenderName}} from {{SenderCompany}}. As part of our commitment to improving data security and vendor risk management, we are utilizing a Cyber Vendor Risk Management product called UpGuard to gain a better understanding of the external security posture of our third-party vendors.

As you are one of our vendors, I’m able to provide you with 14 days of full access to the UpGuard platform to review your entire security profile and proactively address any critical security issues. 

We appreciate any time you are able to invest into this area, as it could reduce risk for both our organizations and ultimately create a more secure business partnership. If you have any questions or concerns, please do not hesitate to reach out. 

Thank you,

{{SenderName}}`;

export enum VendorFreeTrialStatus {
  Eligible = "eligible",
  Customer = "customer",
  Trial = "trial",
}

interface IVendorFreeTrialModalOwnProps extends BaseModalProps {
  vendorId: number;
  vendorName: string;
  assuranceType: AssuranceType;
  freeTrialInfo?: IVendorFreeTrial;
}

interface IVendorFreeTrialModalConnectedProps {
  vendorContacts?: IVendorContactResponse[];
  currentUser: IUserData;
}

type IVendorFreeTrialModalProps = IVendorFreeTrialModalOwnProps &
  IVendorFreeTrialModalConnectedProps &
  DefaultThunkDispatchProp;

const VendorFreeTrialModal = ({
  active,
  onClose,
  vendorId,
  vendorName,
  assuranceType,
  vendorContacts,
  currentUser,
  freeTrialInfo,
  dispatch,
}: IVendorFreeTrialModalProps) => {
  let currentOrgName = "";
  if (currentUser.orgList && currentUser.currentOrgID > 0) {
    for (let i = 0; i < currentUser.orgList.length; i++) {
      if (currentUser.orgList[i].id === currentUser.currentOrgID) {
        currentOrgName = currentUser.orgList[i].name;
      }
    }
  }

  const initialMessage = defaultMessage
    .replace(
      /\{\{SenderName\}\}/g,
      currentUser.firstName + " " + currentUser.lastName
    )
    .replace(
      / from \{\{SenderCompany\}\}/g,
      currentOrgName ? " from " + currentOrgName : ""
    );

  const [message, setMessage] = useState(initialMessage);
  const [inviting, setInviting] = useState(false);
  const [selectedContacts, setSelectedContacts] = useState<ContactDisplay[]>(
    []
  );
  const [sendingPreview, setSendingPreview] = useState(false);

  useEffect(() => {
    if (!vendorContacts) {
      dispatch(fetchVendorMgtContacts(vendorId, true));
    }
  }, []);

  const onInvite = () => {
    setInviting(true);
    dispatch(
      sendVendorFreeTrialInvite({
        datastoreVendorID: vendorId,
        emailText: message,
        contactEmail: selectedContacts[0].emailAddress,
        contactName: selectedContacts[0].name,
        contactTitle: selectedContacts[0].title,
      })
    )
      .then(() => dispatch(addDefaultSuccessAlert("Invite email sent")))
      .then(() => onClose())
      .then(() => setInviting(false))
      .catch((e) => {
        setInviting(false);
        if (
          e instanceof ResponseError &&
          e.response.status === 422 &&
          e.json.error
        ) {
          dispatch(addSimpleErrorAlert("Error - " + e.json.error));
        } else {
          dispatch(
            addDefaultUnknownErrorAlert("Error inviting user to free trial")
          );
        }
      });
  };

  const onSendPreview = () => {
    setSendingPreview(true);
    dispatch(
      sendVendorFreeTrialPreviewEmail({
        datastoreVendorID: vendorId,
        emailText: message,
        contactEmail: selectedContacts[0].emailAddress,
        contactName: selectedContacts[0].name,
        contactTitle: selectedContacts[0].title,
      })
    )
      .then(() => setSendingPreview(false))
      .then(() => dispatch(addDefaultSuccessAlert("Preview email sent")))
      .catch(() => {
        setSendingPreview(false);
        dispatch(addDefaultUnknownErrorAlert("Error sending preview email"));
      });
  };

  const selectContacts = (contacts: ContactDisplay[]) => {
    // try to automatically replace the name of the contact in the message
    if (contacts.length > 0) {
      const contactName = contacts[0].name;

      // try replacing the contact name placeholder first
      let newMessage = message.replace(/\{\{ReceiverName\}\}/g, contactName);

      // then try to replace any occurrences of the previously selected contact name (if any) with the new name
      if (selectedContacts.length > 0) {
        const previousContactName = selectedContacts[0].name;
        newMessage = newMessage.replace(
          new RegExp(escapeRegExp(previousContactName), "g"),
          contactName
        );
      }

      if (newMessage !== message) setMessage(newMessage);
    } else if (selectedContacts.length > 0) {
      // if the user removed the selected contact and there was a previously selected contact
      // we want to try and replace the name of the previously selected contact with the placeholder again
      const previousContactName = selectedContacts[0].name;

      const newMessage = message.replace(
        new RegExp(escapeRegExp(previousContactName), "g"),
        "{{ReceiverName}}"
      );
      if (newMessage !== message) setMessage(newMessage);
    }

    // refresh the free trial info on every selection change
    if (contacts.length > 0) {
      const contactEmail = contacts[0].emailAddress;
      dispatch(getVendorFreeTrialInfo(vendorId, contactEmail, true));
    } else {
      dispatch(getVendorFreeTrialInfo(vendorId, undefined, true));
    }

    setSelectedContacts(contacts);
  };

  const vendorWords = getVendorWords(assuranceType);

  // invitations for free trials can only be sent to users with an email
  // domain matching the vendor primary domain so filter the vendor contacts
  const allowedContacts = vendorContacts?.filter(
    (c) => c.emailDomainPartOfVendor
  );

  let contactValidationError: string | undefined = undefined;
  if (selectedContacts.length > 0 && !!freeTrialInfo && !!freeTrialInfo.data) {
    contactValidationError = freeTrialInfo.data.userClaimedTrialBefore
      ? "This contact has already redeemed their free UpGuard access"
      : !freeTrialInfo.data.emailDomainPartOfVendor
        ? `User email domain does not belong to ${vendorWords.singular}`
        : undefined;
  }

  const isLoadingState = (!!freeTrialInfo && freeTrialInfo.loading) || inviting;
  const isDisabled =
    inviting ||
    !selectedContacts.length ||
    !canInviteUserToFreeTrial(freeTrialInfo);

  return (
    <>
      <ModalV2
        active={active}
        onClose={onClose}
        className="vendor-free-trial-modal"
        footerClassName="vendor-free-trial-modal-foot"
        headerClassName="vendor-free-trial-modal-head"
        headerContent={`Invite ${vendorName} to proactively review their full security profile`}
        footerContent={
          <>
            <Button
              filledPrimary
              className={"send-button"}
              onClick={onInvite}
              loading={isLoadingState}
              disabled={isDisabled}
            >
              Send Invitation <div className="cr-icon-message" />
            </Button>
          </>
        }
      >
        <div className={"introduction"}>
          <p>
            {`As an UpGuard customer, you can grant ${vendorName} 14 days of free access to the UpGuard platform to proactively manage their external attack surface.`}{" "}
            <br />
            <br />
            Simply customize the email message below, add a contact from the
            vendor’s organization and hit send.
          </p>
        </div>
        <div className={"recipient"}>
          <div className={"label"}>Recipient</div>
          <ContactSelect
            vendorContacts={allowedContacts}
            onSelectionChanged={selectContacts}
            maxSelectedContacts={1}
            validationError={contactValidationError}
          />
        </div>
        <div className={"message"}>
          <div className={"label"}>Message for recipient</div>
          <RichTextEditV2 value={message} onChange={setMessage} />
          <SidePopupV2
            className={"preview-button"}
            text={`A preview will be sent to your email ${currentUser.emailAddress}`}
            position={"left"}
          >
            <Button
              loading={sendingPreview}
              disabled={sendingPreview || !selectedContacts.length}
              onClick={onSendPreview}
            >
              Send preview to myself
            </Button>
          </SidePopupV2>
        </div>
      </ModalV2>
    </>
  );
};

const ConnectedVendorFreeTrialModal = appConnect<
  IVendorFreeTrialModalConnectedProps,
  never,
  IVendorFreeTrialModalOwnProps
>((state, props) => {
  return {
    vendorContacts:
      state.cyberRisk.vendors[props.vendorId]?.mgtlists?.contacts?.result,
    currentUser: state.common.userData,
  };
})(VendorFreeTrialModal);

interface IVendorFreeTrialIconOwnProps {
  vendorId: number;
  vendorName: string;
  vendorPrimaryDomain: string;
  assuranceType: AssuranceType;
  freeTrialInfo?: IVendorFreeTrial;
}

type IVendorFreeTrialIconProps = IVendorFreeTrialIconOwnProps &
  DefaultThunkDispatchProp;

const VendorFreeTrialIcon = ({
  vendorId,
  vendorName,
  vendorPrimaryDomain,
  freeTrialInfo,
  assuranceType,
  dispatch,
}: IVendorFreeTrialIconProps) => {
  const [vendorFreeTrialModalOpen, setVendorFreeTrialModalOpen] =
    useState(false);

  useEffect(() => {
    dispatch(getVendorFreeTrialInfo(vendorId, undefined));
  }, [dispatch, vendorId]);

  const infoAvailable =
    !!freeTrialInfo && !freeTrialInfo.loading && !freeTrialInfo.error;

  const disabled = !canInviteVendorToFreeTrial(freeTrialInfo);

  const vendorWords = getVendorWords(assuranceType);

  let message = "";
  if (infoAvailable) {
    switch (freeTrialInfo.data.status) {
      case VendorFreeTrialStatus.Customer:
        message = `Cannot invite ${vendorName} to free trial - they already have UpGuard access`;
        break;
      case VendorFreeTrialStatus.Trial:
        message = `${vendorName} already has free trial access to the UpGuard platform`;
        break;
      case VendorFreeTrialStatus.Eligible:
        message = `Invite ${vendorName} to access their full security profile`;
        break;
    }
  }

  return (
    <div
      onMouseEnter={() =>
        trackEvent("VendorFreeTrial_Hover", {
          vendorId: vendorId,
          vendorName: vendorName,
          vendorPrimaryDomain: vendorPrimaryDomain,
          freeTrialStatus: freeTrialInfo?.data.status,
        })
      }
    >
      <SidePopupV2 text={message} position="bottom">
        <Button
          className="free-vendor-trial"
          tertiary
          disabled={disabled}
          onClick={() => setVendorFreeTrialModalOpen(!vendorFreeTrialModalOpen)}
        >
          <div className={"cr-icon-mail"} />
          {!disabled && <> Invite {vendorWords.singular}</>}
        </Button>
      </SidePopupV2>
      <ConnectedVendorFreeTrialModal
        vendorId={vendorId}
        vendorName={vendorName}
        assuranceType={assuranceType}
        freeTrialInfo={freeTrialInfo}
        active={vendorFreeTrialModalOpen}
        onClose={() => setVendorFreeTrialModalOpen(false)}
      />
    </div>
  );
};

export default VendorFreeTrialIcon;
