import { useEffect, useState } from "react";
import SearchBox from "../../../_common/components/SearchBox";
import PaginatedTable from "../PaginatedTable";
import SearchEmptyCard from "../../../_common/components/SearchEmptyCard";
import EmptyCardWithAction from "../../../_common/components/EmptyCardWithAction";
import SearchSVG from "../../../_common/images/search.svg";
import ModalV2 from "../../../_common/components/ModalV2";
import {
  IXTableRow,
  XTableCell,
} from "../../../_common/components/core/XTable";
import ColorGrade, { ColorGradeSize } from "../executive_summary/ColorGrade";
import Button from "../../../_common/components/core/Button";
import { DefaultThunkDispatchProp } from "../../../_common/types/redux";
import Score from "../Score";
import "../../style/components/VendorComparisonSelectModal.scss";
import {
  fetchOrgVendors,
  ListVendorsV1Resp,
  OrgVendorItem,
} from "../../reducers/orgVendors.apiActions";
import PillLabel from "../PillLabel";
import { LabelColor } from "../../../_common/types/label";
import { getVendorWords } from "../../../_common/constants";
import { AssuranceType } from "../../../_common/types/organisations";
import { appConnect } from "../../../_common/types/reduxHooks";

interface VendorComparisonSelectModalOwnProps {
  active: boolean;
  onClose: (vendorId?: number) => void;
}

interface VendorComparisonSelectModalConnectedProps {
  assuranceType: AssuranceType;
  alreadySelectedIds: number[];
}

type VendorComparisonSelectModalProps = VendorComparisonSelectModalOwnProps &
  VendorComparisonSelectModalConnectedProps &
  DefaultThunkDispatchProp;

const VENDORS_PER_UI_PAGE = 7;
const UI_PAGES_PER_API_PAGE = 60;

const getApiPageForUiPage = (uiPage: number) => {
  return Math.ceil(uiPage / UI_PAGES_PER_API_PAGE);
};

const getBlankResponse = () => {
  return {
    total: 0,
    vendors: [],
    filters: {
      namePrefix: "",
      lookupsIncluded: true,
      subsidiariesIncluded: true,
    },
  } as ListVendorsV1Resp;
};

const VendorComparisonSelectModal = (
  props: VendorComparisonSelectModalProps
) => {
  const vendorWords = getVendorWords(props.assuranceType);
  const [selectedIds, setSelectedIds] = useState<number[]>(
    props.alreadySelectedIds
  );
  const [isSearching, setIsSearching] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [vendorResp, setVendorResp] =
    useState<ListVendorsV1Resp>(getBlankResponse());

  // Cache the initial response so we can reset quickly
  const [initialVendorResp, setInitialVendorResp] =
    useState<ListVendorsV1Resp>(getBlankResponse());
  const [currentPage, setCurrentPage] = useState(1);
  const [currentApiPage, setCurrentApiPage] = useState(1);

  // Respond to changes external
  useEffect(() => {
    setSelectedIds([...props.alreadySelectedIds]);
  }, [props.alreadySelectedIds]);

  const searchVendors = () => {
    setIsSearching(true);
    props
      .dispatch(
        fetchOrgVendors(
          searchText,
          currentApiPage,
          UI_PAGES_PER_API_PAGE * VENDORS_PER_UI_PAGE
        )
      )
      .then((resp) => {
        if (resp.filters.namePrefix === searchText) {
          setVendorResp(resp);
          setIsSearching(false);
        }
      })
      .catch(() => {
        setIsSearching(false);
      });
  };

  // On hide, reset modal
  // On first show, get starting vendor list
  useEffect(() => {
    if (!props.active) {
      setSearchText("");
      setVendorResp(getBlankResponse());
      setIsSearching(false);
      setCurrentPage(1);
      setCurrentApiPage(1);
    } else if (initialVendorResp.vendors.length === 0) {
      setIsSearching(true);
      props
        .dispatch(
          fetchOrgVendors("", 1, UI_PAGES_PER_API_PAGE * VENDORS_PER_UI_PAGE)
        )
        .then((resp) => {
          setInitialVendorResp(resp);
          setIsSearching(false);
        })
        .catch(() => {
          setIsSearching(false);
        });
    }
  }, [props.active]);

  // Check whether a fresh search is required when searchText or currentApiPage change
  useEffect(() => {
    if (searchText.length > 2 || currentApiPage > 1) {
      searchVendors();
    } else {
      setVendorResp(getBlankResponse());
    }
  }, [searchText, currentApiPage]);

  // If search text has changed, reset pages
  useEffect(() => {
    setCurrentPage(1);
    setCurrentApiPage(1);
  }, [searchText]);

  const addVendor = (id: number) => {
    props.onClose(id);
  };

  const resp =
    searchText.length > 2 || currentApiPage > 1
      ? vendorResp
      : initialVendorResp;

  const getRows = () => {
    const rows: IXTableRow[] = [];

    if (isSearching) {
      return rows;
    }

    const vendorsInPage: OrgVendorItem[] = [];

    const uiPageStart =
      (Math.max(currentPage - 1, 0) % UI_PAGES_PER_API_PAGE) *
      VENDORS_PER_UI_PAGE;
    for (
      let i = uiPageStart;
      i < uiPageStart + VENDORS_PER_UI_PAGE && i < resp.vendors.length;
      i++
    ) {
      vendorsInPage.push(resp.vendors[i]);
    }

    vendorsInPage.forEach((v) => {
      rows.push({
        id: v.datastoreVendorID,
        onClick: !selectedIds.includes(v.datastoreVendorID)
          ? () => addVendor(v.datastoreVendorID)
          : undefined,
        cells: [
          <XTableCell key="vendor">{v.vendorName}</XTableCell>,
          <XTableCell key="website">{v.vendorPrimaryHostname}</XTableCell>,
          <XTableCell key="vendor_type">
            <OrgVendorTypePill {...v} />
          </XTableCell>,
          <XTableCell key="score">
            <div className={"score-cell"}>
              <ColorGrade size={ColorGradeSize.Small} score={v.vendorScore} />
              <Score score={v.vendorScore} small />
            </div>
          </XTableCell>,
          <XTableCell key="action" className="action-cell">
            <Button
              onClick={() => addVendor(v.datastoreVendorID)}
              disabled={selectedIds.includes(v.datastoreVendorID)}
              arrow
            >
              {`Add ${vendorWords.singular}`}
            </Button>
          </XTableCell>,
        ],
      });
    });

    return rows;
  };

  return (
    <ModalV2
      onFormDefaultSubmit={(e) => e.preventDefault()}
      active={props.active}
      className={"org-vendor-search-modal"}
      onClose={() => props.onClose(undefined)}
      headerClassName={"org-vendor-search-modal-header"}
      headerContent={
        <div>
          <h2>Add a {vendorWords.singular} to compare</h2>
          <div className={"description"}>
            Select a {vendorWords.singular} to add it to the comparison
          </div>
        </div>
      }
    >
      <div className={"content"}>
        <div className="search-bar">
          <SearchBox
            onChanged={(val) => {
              setSearchText(val);
            }}
            value={searchText}
            placeholder={`Search for a ${vendorWords.singular} by a name or URL`}
            autoFocus
          />
        </div>
        <PaginatedTable
          loading={isSearching}
          prePagedRows
          pageSize={VENDORS_PER_UI_PAGE}
          page={currentPage - 1}
          onPageChange={(pg) => {
            setCurrentPage(pg);

            const apiPage = getApiPageForUiPage(pg);

            // Check if we need to get new data from the API for this UI page
            if (apiPage !== currentApiPage) {
              setCurrentApiPage(apiPage);
            }
          }}
          columnHeaders={[
            {
              id: "vendor",
              text: vendorWords.singularTitleCase,
              sortable: false,
            },
            { id: "website", text: "Website", sortable: false },
            { id: "vendor_type", text: "Type", sortable: false },
            { id: "score", text: "Score", sortable: false },
            { id: "action", text: "", sortable: false },
          ]}
          rows={getRows()}
          totalRows={resp.total}
        />
        {resp.vendors.length === 0 &&
          searchText &&
          searchText.length > 0 &&
          !isSearching && (
            <SearchEmptyCard
              onClear={() => setSearchText("")}
              searchItemText={vendorWords.singular}
            />
          )}
        {resp.vendors.length === 0 &&
          !isSearching &&
          (!searchText || searchText.length === 0) && (
            <EmptyCardWithAction
              emptyText={`Search for a ${vendorWords.singular}`}
              emptySubText={`Search for the ${vendorWords.singular} you’d like to add to the comparison by their name or primary URL.`}
              iconSrc={SearchSVG}
            />
          )}
      </div>
    </ModalV2>
  );
};

export const OrgVendorTypePill = (vendor: {
  isLookup: boolean;
  isWatched: boolean;
  isSubsidiary: boolean;
}) => {
  if (vendor.isWatched) {
    return <PillLabel color={LabelColor.Blue}>Monitored</PillLabel>;
  } else if (vendor.isLookup) {
    return <PillLabel color={LabelColor.Yellow}>Snapshot</PillLabel>;
  } else if (vendor.isSubsidiary) {
    return (
      <PillLabel
        color={LabelColor.Green}
        popupContent={"Subsidiary of a monitored vendor"}
      >
        Subsidiary
      </PillLabel>
    );
  }

  return <></>;
};

export default appConnect<
  VendorComparisonSelectModalConnectedProps,
  never,
  VendorComparisonSelectModalOwnProps
>((state) => {
  return {
    assuranceType: state.common.userData.assuranceType,
    alreadySelectedIds: state.cyberRisk.vendorComparisonVendorIds ?? [],
  };
})(VendorComparisonSelectModal);
