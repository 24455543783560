import { ICorrespondenceMessage } from "../../types/correspondenceMessage";
import { useMemo } from "react";

// Recursively count the unread messages in a set of messages
export const countUnreadMessages = (
  messages?: ICorrespondenceMessage[]
): number => {
  if (!messages) {
    return 0;
  }

  let count = 0;

  messages.forEach((m) => {
    if (m.unread && !m.isSeenInUI) {
      count += 1;
    }

    if (m.children && m.children.length > 0) {
      count = count + countUnreadMessages(m.children);
    }
  });

  return count;
};

// Recursively count individual messages in a set of messages
export const countMessages = (
  messages?: ICorrespondenceMessage[],
  ignoreReplies = false
): number => {
  if (!messages) {
    return 0;
  }

  let count = 0;

  messages.forEach((m) => {
    count += 1;

    if (!ignoreReplies && m.children && m.children.length > 0) {
      count = count + countMessages(m.children);
    }
  });

  return count;
};

export const useMessageCounts = (
  messages?: ICorrespondenceMessage[]
): { totalMessages: number; unreadMessages: number } =>
  useMemo(
    () => ({
      totalMessages: countMessages(messages),
      unreadMessages: countUnreadMessages(messages),
    }),
    [messages]
  );

// Recurse through a set of message and update content for one that matches the id
export const updateMessageContent = (
  messages: ICorrespondenceMessage[],
  messageId: number,
  newContent: string
): boolean => {
  let found = false;

  for (const m of messages) {
    if (m.id === messageId) {
      m.content = newContent;
      m.updatedAt = new Date().toISOString();
      return true;
    } else if (m.children && m.children.length > 0) {
      found = updateMessageContent(m.children, messageId, newContent);
      if (found) {
        break;
      }
    }
  }

  return found;
};

// Recurse a message collection to find a message by ID
export const getMessageById = <TMeta>(
  messages: ICorrespondenceMessage<TMeta>[] | undefined,
  id: number
): ICorrespondenceMessage<TMeta> | undefined => {
  if (!messages) {
    return undefined;
  }

  for (const m of messages) {
    if (m.id === id) {
      return m;
    } else if (m.children && m.children.length > 0) {
      const found = getMessageById(m.children, id);
      if (found) {
        return found;
      }
    }
  }

  return undefined;
};

// Recurse through a set of message and set viewed state for a list of viewed messages
export const setViewedInUI = (
  allMessages: ICorrespondenceMessage[],
  viewedMessages: ICorrespondenceMessage[]
) => {
  // Get a map of viewed ids
  const viewedMessagesIdMap: { [messageId: number]: boolean } = {};

  const getReadIds = (messages: ICorrespondenceMessage[]) => {
    for (const m of messages) {
      viewedMessagesIdMap[m.id] = true;
      if (m.children && m.children.length > 0) {
        getReadIds(m.children);
      }
    }
  };

  getReadIds(viewedMessages);

  const setViewedInUIInner = (messages: ICorrespondenceMessage[]) => {
    for (const m of messages) {
      if (viewedMessagesIdMap[m.id]) {
        m.isSeenInUI = true;
      }

      if (m.children && m.children.length > 0) {
        setViewedInUIInner(m.children);
      }
    }
  };

  setViewedInUIInner(allMessages);
};
