import { FC, ReactNode } from "react";
import LoadingIcon from "./core/LoadingIcon";

import "../style/components/LoadingOverlay.scss";

interface ILoadingOverlayProps {
  loading: boolean;
  children: ReactNode;
}

const LoadingOverlay: FC<ILoadingOverlayProps> = ({ loading, children }) => {
  return (
    <div className="loading-overlay-container">
      {children}
      <div className={`loading-overlay-inner ${loading ? "active" : ""}`}>
        <LoadingIcon size={50} />
      </div>
    </div>
  );
};

export default LoadingOverlay;
