import ModalV2, { BaseModalProps } from "../../../_common/components/ModalV2";
import PortfolioSelection, {
  IPortfolioSelectionSelectedItem,
} from "./PortfolioSelection";
import {
  fetchDomainPortfolios,
  INewPortfolio,
  IPortfolioUpdateItem,
  Portfolio,
  PortfolioType,
  setPortfoliosForDomains,
  setPortfoliosForIPRanges,
} from "../../reducers/portfolios.actions";
import { DefaultThunkDispatchProp } from "../../../_common/types/redux";
import { FC, memo, useCallback, useEffect, useState } from "react";
import {
  domainPortfolioHelpURL,
  getDisabledDomainPortfolioIds,
} from "./helpers";
import {
  UserBreachsightWrite,
  UserWriteOwnOrganisation,
} from "../../../_common/permissions";

import {
  addDefaultSuccessAlert,
  addDefaultUnknownErrorAlert,
} from "../../../_common/reducers/messageAlerts.actions";
import Button from "../../../_common/components/core/Button";
import LoadingBanner from "../../../_common/components/core/LoadingBanner";
import { useHistory } from "react-router-dom";
import "../../style/components/portfolios/DomainPortfolioSelectionModal.scss";
import ColorCheckbox from "../ColorCheckbox";
import { appConnect } from "../../../_common/types/reduxHooks";

export enum DomainsOrIPs {
  Domains,
  IPs,
}

interface IDomainIPPortfolioSelectionModalOwnProps extends BaseModalProps {
  selectedItems: IPortfolioSelectionSelectedItem[];
  onClearSelectedItems?: () => void;
  domainsOrIPs: DomainsOrIPs;
}

interface IDomainIPPortfolioSelectionModalConnectedProps {
  portfolios?: Portfolio[];
  limit: number;
  disabledPortfolioIds: number[];
  canCreatePortfolios: boolean;
}

type IDomainIPPortfolioSelectionModalProps =
  IDomainIPPortfolioSelectionModalOwnProps &
    IDomainIPPortfolioSelectionModalConnectedProps &
    DefaultThunkDispatchProp;

const DomainIPPortfolioSelectionModal: FC<
  IDomainIPPortfolioSelectionModalProps
> = ({
  dispatch,
  active,
  onClose,
  onClearSelectedItems,
  selectedItems,
  portfolios,
  limit,
  disabledPortfolioIds,
  canCreatePortfolios,
  domainsOrIPs,
}) => {
  const history = useHistory();
  const [saving, setSaving] = useState(false);
  const [portfolioUpdateState, setPortfolioUpdateState] = useState({
    portfolioUpdateItems: [] as IPortfolioUpdateItem[],
    newPortfolios: [] as INewPortfolio[],
    valid: false,
  });
  const [applyToSubdomains, setApplyToSubdomains] = useState(false);

  // Reset state on active change
  useEffect(() => {
    setSaving(false);
    setPortfolioUpdateState({
      portfolioUpdateItems: [],
      newPortfolios: [],
      valid: false,
    });
  }, [active]);

  const onSave = useCallback(async () => {
    if (!portfolioUpdateState.valid) {
      return;
    }

    setSaving(true);

    try {
      if (domainsOrIPs === DomainsOrIPs.Domains) {
        await dispatch(
          setPortfoliosForDomains(
            portfolioUpdateState.portfolioUpdateItems,
            portfolioUpdateState.newPortfolios,
            applyToSubdomains
          )
        );
      } else if (domainsOrIPs === DomainsOrIPs.IPs) {
        await dispatch(
          setPortfoliosForIPRanges(
            portfolioUpdateState.portfolioUpdateItems,
            portfolioUpdateState.newPortfolios
          )
        );
      }

      await dispatch(fetchDomainPortfolios(true));
    } catch (e) {
      console.error(e);
      dispatch(
        addDefaultUnknownErrorAlert(
          "Error setting portfolios. Please contact UpGuard Support."
        )
      );
      setSaving(false);
      return;
    }

    dispatch(addDefaultSuccessAlert("Portfolios updated."));
    onClose();
    if (onClearSelectedItems) {
      onClearSelectedItems();
    }
  }, [
    portfolioUpdateState,
    dispatch,
    onClearSelectedItems,
    onClose,
    applyToSubdomains,
    domainsOrIPs,
  ]);

  const onSetUpdateItems = useCallback(
    (
      portfolioUpdateItems: IPortfolioUpdateItem[],
      newPortfolios: INewPortfolio[],
      valid: boolean
    ) => {
      setPortfolioUpdateState({
        portfolioUpdateItems,
        newPortfolios,
        valid,
      });
    },
    []
  );

  if (selectedItems.length === 0) {
    return null;
  }

  const itemPlural =
    domainsOrIPs === DomainsOrIPs.Domains ? "domains" : "IPs and ranges";

  return (
    <>
      <ModalV2
        active={active}
        onClose={onClose}
        className="domain-portfolio-selection-modal"
        footerClassName="domain-portfolio-selection-modal-foot"
        headerContent={`Select portfolios for ${
          selectedItems.length === 1
            ? selectedItems[0].name
            : `${selectedItems.length} ${itemPlural}`
        }`}
        footerContent={
          <>
            {canCreatePortfolios && (
              <Button
                tertiary
                className="btn-align-left"
                onClick={() =>
                  history.push("/settings/domains", {
                    backTo: history.location.pathname,
                    backToText: "Back",
                  })
                }
              >
                <div className="cr-icon-cog2" /> Manage Portfolios
              </Button>
            )}
            <Button tertiary onClick={onClose} disabled={saving}>
              Cancel
            </Button>
            <Button
              primary
              onClick={onSave}
              loading={saving}
              disabled={saving || !portfolioUpdateState.valid}
            >
              Save changes
            </Button>
          </>
        }
      >
        {portfolios ? (
          <>
            <p>
              Use portfolios to separate {itemPlural} into lists. Portfolios can
              be helpful for maintaining separate lists of domains by
              department.
            </p>
            <p>
              To learn more about how to use portfolios, and how to set up
              role-based access for specific portfolios,{" "}
              <a
                href={domainPortfolioHelpURL}
                target="_blank"
                rel="nofollow noreferrer"
              >
                visit our help page
              </a>
              .
            </p>
            <div className="form-grid">
              <div className="grid-line" />
              <div>
                <h3>Select portfolios</h3>
              </div>
              <div>
                <PortfolioSelection
                  portfolioType={PortfolioType.Domain}
                  portfolios={portfolios}
                  portfolioLimit={limit}
                  canCreatePortfolios={canCreatePortfolios}
                  selectedItems={selectedItems}
                  disabledPortfolioIds={disabledPortfolioIds}
                  onSetUpdateItems={onSetUpdateItems}
                />
              </div>
              {domainsOrIPs === DomainsOrIPs.Domains && (
                <>
                  <div className="grid-line" />
                  <div>
                    <h3>Include children?</h3>
                  </div>
                  <div>
                    <ColorCheckbox
                      radio
                      checked={!applyToSubdomains}
                      onClick={() => setApplyToSubdomains(false)}
                      label={`Set portfolios for just ${
                        selectedItems.length === 1
                          ? "this domain"
                          : "these domains"
                      }`}
                    />
                    <ColorCheckbox
                      radio
                      checked={applyToSubdomains}
                      onClick={() => setApplyToSubdomains(true)}
                      label={`Set portfolios for ${
                        selectedItems.length === 1
                          ? "this domain and all its children"
                          : "these domains and all their children"
                      }`}
                    />
                  </div>
                </>
              )}
            </div>
          </>
        ) : (
          <LoadingBanner />
        )}
      </ModalV2>
    </>
  );
};

export default appConnect<
  IDomainIPPortfolioSelectionModalConnectedProps,
  never,
  IDomainIPPortfolioSelectionModalOwnProps
>((state) => {
  // Portfolios that should be visible but disabled are those that the current user only has read access to.
  const disabledPortfolioIds = getDisabledDomainPortfolioIds(
    state.common.userData.domainPortfolioSpecificPermissions
  );

  return {
    portfolios: state.cyberRisk.domainPortfolios?.portfolios,
    limit: state.cyberRisk.domainPortfolios?.limit ?? 0,
    disabledPortfolioIds,
    canCreatePortfolios:
      state.common.userData.userPermissions.includes(UserBreachsightWrite) &&
      state.common.userData.userPermissions.includes(UserWriteOwnOrganisation),
  };
})(memo(DomainIPPortfolioSelectionModal));
