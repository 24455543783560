import ContactSelector, {
  existingUser,
  IContactSelectorProps,
  newContact,
} from "../ContactSelector";
import { DefaultThunkDispatchProp } from "../../../_common/types/redux";

import { useCallback, useEffect, useRef, useState } from "react";
import { checkVendorDomains } from "../../reducers/vendors.actions";
import { addDefaultUnknownErrorAlert } from "../../../_common/reducers/messageAlerts.actions";
import { validateDomainName, validateEmail } from "../../../_common/helpers";
import { debounce as _debounce } from "lodash";
import { appConnect } from "../../../_common/types/reduxHooks";

interface VerifiedVendorContactSelectorOwnProps extends IContactSelectorProps {
  vendorId: number;
  vendorName: string;
  userSelectionDisabledMessage: string;
}

type VerifiedVendorContactSelectorProps =
  VerifiedVendorContactSelectorOwnProps & DefaultThunkDispatchProp;

type EmailDomainChecks = { [domain: string]: boolean | undefined };

export const getDomainForEmailAddress = (
  emailAddress?: string
): string | undefined => {
  if (!emailAddress || !validateEmail(emailAddress)) {
    return undefined;
  }

  const splits = emailAddress.split("@");

  if (splits.length !== 2 || !validateDomainName(splits[1])) {
    return undefined;
  }

  return splits[1];
};

const getEmailDomainsFromNewAndExistingUsers = (
  checkedDomains: EmailDomainChecks,
  existingUsers: existingUser[],
  newContacts: newContact[]
): EmailDomainChecks => {
  const allDomains: EmailDomainChecks = {};

  if (existingUsers) {
    existingUsers.forEach((e) => {
      const emailDomain = getDomainForEmailAddress(e.email);

      if (emailDomain) {
        allDomains[emailDomain] = undefined;
      }
    });
  }

  newContacts.forEach((u) => {
    const emailDomain = getDomainForEmailAddress(u.email);
    if (emailDomain) {
      allDomains[emailDomain] = undefined;
    }
  });

  return { ...allDomains, ...checkedDomains };
};

// VerifiedVendorContactSelector is a wrapper for the contact selector for when we want to
// limit selectable contacts to valid email domains for a verified vendor
const VerifiedVendorContactSelector = ({
  dispatch,
  vendorId,
  vendorName,
  entityName,
  existingUsers,
  newContacts,
  setExistingUserSelected,
  addNewContact,
  updateNewContact,
  deleteNewContact,
  canDeselectExisting,
  selectEmailOnly,
  radioSelector,
  limitNewContacts,
  canDeselectNew,
  onSelectNew,
  hideDescription,
  hideCheckboxes,
  disableDeleteWhenSingleNewContact,
  showTitles,
  userSelectionDisabledMessage,
}: VerifiedVendorContactSelectorProps) => {
  const [emailDomains, setEmailDomains] = useState<EmailDomainChecks>({});

  // Make sure we don't spam the API
  const isCheckingDomains = useRef(false);

  const checkDomains = (emailDomainsToCheck: EmailDomainChecks) => {
    const domainsToCheck: string[] = [];

    // Get any domains we still need to check
    for (const d in emailDomainsToCheck) {
      if (emailDomainsToCheck[d] === undefined) {
        domainsToCheck.push(d);
      }
    }

    if (domainsToCheck.length > 0) {
      if (isCheckingDomains.current) {
        return; // We'll retry after the current request is done
      } else {
        isCheckingDomains.current = true;
      }

      dispatch(checkVendorDomains(vendorId, domainsToCheck))
        .then((checkResults) => {
          if (checkResults?.domainChecks) {
            setEmailDomains({
              ...emailDomainsToCheck,
              ...checkResults?.domainChecks,
            });

            isCheckingDomains.current = false;
          }
        })
        .catch(() => {
          dispatch(
            addDefaultUnknownErrorAlert("Error checking contact domains")
          );
        });
    }
  };

  // Debounce the check call to the API while the user is changing stuff
  const debouncedCheckDomains = useCallback(
    _debounce(
      (emailDomains: EmailDomainChecks) => checkDomains(emailDomains),
      1000
    ),
    []
  );

  // Update the email domains to check if contacts change
  useEffect(() => {
    const domains = getEmailDomainsFromNewAndExistingUsers(
      emailDomains,
      existingUsers ?? [],
      newContacts
    );

    let changed = false;
    for (const d in domains) {
      changed = changed || domains[d] === undefined;
    }

    if (changed) {
      setEmailDomains(domains);
    }
  }, [existingUsers, newContacts]);

  // If the email domains change, check any domains not yet checked
  useEffect(() => {
    debouncedCheckDomains(emailDomains);
  }, [emailDomains]);

  const contactSelectorProps = {
    entityName,
    existingUsers,
    newContacts,
    setExistingUserSelected,
    addNewContact,
    deleteNewContact,
    canDeselectExisting,
    selectEmailOnly,
    radioSelector,
    limitNewContacts,
    canDeselectNew,
    onSelectNew,
    hideDescription,
    showTitles,
    hideCheckboxes,
    disableDeleteWhenSingleNewContact,
  };

  if (contactSelectorProps.existingUsers) {
    contactSelectorProps.existingUsers.forEach((ec) => {
      const emailDomain = getDomainForEmailAddress(ec.email);
      if (emailDomain) {
        const isDomainValid = emailDomains[emailDomain];
        ec.isChecking = isDomainValid === undefined;
        ec.isSelectionDisabled = !isDomainValid;
        ec.selectionDisabledMessage = userSelectionDisabledMessage;
      }
    });
  }

  if (contactSelectorProps.newContacts) {
    contactSelectorProps.newContacts.forEach((nc) => {
      const emailDomain = getDomainForEmailAddress(nc.email);
      if (emailDomain) {
        const isDomainValid = emailDomains[emailDomain];
        nc.isChecking = isDomainValid === undefined;
        nc.isEmailDomainNotAllowed = isDomainValid === false;
      }
    });
  }

  return (
    <ContactSelector
      {...contactSelectorProps}
      updateNewContact={(tempId, fields) => {
        // Intercept to check additional constraints for verified vendor contacts
        const emailDomain = getDomainForEmailAddress(fields.email);
        const isDomainValid = emailDomain && emailDomains[emailDomain];
        fields.isChecking =
          emailDomain !== undefined && isDomainValid === undefined;
        fields.isEmailDomainNotAllowed = isDomainValid === false;
        updateNewContact(tempId, fields);
      }}
      emailDomainNotAllowedTextError={`Email domain must be one of ${vendorName}'s domains`}
    />
  );
};

export default appConnect()(VerifiedVendorContactSelector);
