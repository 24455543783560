import { DefaultThunkDispatch } from "../../_common/types/redux";
import { DefaultRootState } from "react-redux";
import { FetchCyberRiskUrl } from "../../_common/api";
import { LogError } from "../../_common/helpers";
import { setCustomerDataFiltersAndRefreshData } from "./cyberRiskActions";
import { refreshVendorListsAfterChange } from "./vendors.actions";
import {
  clearDomains,
  updateDomainsStateAfterPortfoliosChange,
} from "./domains.actions";
import {
  IpRangeToUpdate,
  setIpAddressAndIpAddressDetailState,
  setIpRangeState,
} from "./ipLabels.actions";
import { conditionalRefreshActivityStreamForOrgUser } from "../../_common/reducers/commonActions";
import { fetchCustomerSummaryAndCloudscans } from "./customer.actions";

export const currentVendorPortfolioLocalStorageKey = "currentPortfolio";
export const currentDomainPortfolioLocalStorageKey = "currentDomainPortfolio";

export enum PortfolioType {
  Vendor = "vendor",
  Domain = "domain",
}

export interface Portfolio {
  id: number;
  type: PortfolioType;
  name: string;
  isDefault: boolean;
  numItems: number;
}

export const SET_VENDOR_PORTFOLIOS = "SET_VENDOR_PORTFOLIOS";
export const setVendorPortfolios = (
  portfolios: Portfolio[],
  grandTotalItems: number,
  limit: number
) => ({
  type: SET_VENDOR_PORTFOLIOS,
  portfolios,
  grandTotalItems,
  limit,
});

export const SET_DOMAIN_PORTFOLIOS = "SET_DOMAIN_PORTFOLIOS";
export const setDomainPortfolios = (
  portfolios: Portfolio[],
  grandTotalItems: number,
  limit: number
) => ({
  type: SET_DOMAIN_PORTFOLIOS,
  portfolios,
  grandTotalItems,
  limit,
});

export const fetchVendorPortfolios = (force = false) => {
  return async (
    dispatch: DefaultThunkDispatch,
    getState: () => DefaultRootState
  ) => {
    if (!force) {
      const vendorPortfolios = getState().cyberRisk.vendorPortfolios;
      if (vendorPortfolios) {
        return vendorPortfolios;
      }
    }

    let resp: {
      portfolios: Portfolio[];
      grandTotalItems: number;
      limit: number;
    };
    try {
      resp = await FetchCyberRiskUrl(
        "portfolios/vendor/v1",
        undefined,
        { method: "GET" },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("error fetching vendor portfolios", e);
      throw e;
    }

    dispatch(
      setVendorPortfolios(resp.portfolios, resp.grandTotalItems, resp.limit)
    );

    // Just check that the user has access to all the portfolios in the current filter.
    // Because we save/rehydrate the portfolios filter to local storage, it may be possible
    // to have a portfolio ID in the filter that the user doesn't have access to (such as
    // from a different org).
    const filteredPortfolioIds =
      getState().cyberRisk.customerData.filters.portfolioIds || [];
    const newFilteredPortfolioIds = [];
    let portfolioFilterChanged = false;
    for (let i = 0; i < filteredPortfolioIds.length; i++) {
      if (resp.portfolios.find((p) => p.id === filteredPortfolioIds[i])) {
        newFilteredPortfolioIds.push(filteredPortfolioIds[i]);
      } else {
        portfolioFilterChanged = true;
      }
    }

    if (portfolioFilterChanged) {
      dispatch(
        setCustomerDataFiltersAndRefreshData({
          portfolioIds: newFilteredPortfolioIds,
        })
      );
    }

    return resp;
  };
};

// Creates a new portfolio and re-fetches the full
// list of portfolios.
export const createVendorPortfolio = (name: string) => {
  return async (
    dispatch: DefaultThunkDispatch,
    getState: () => DefaultRootState
  ): Promise<number> => {
    try {
      const resp = await FetchCyberRiskUrl<{
        portfolioId: number;
      }>(
        "portfolios/vendor/v1",
        { name },
        { method: "POST" },
        dispatch,
        getState
      );

      return resp.portfolioId;
    } catch (e) {
      LogError("error creating vendor portfolio", e);
      throw e;
    }
  };
};

// Updates a portfolio and re-fetches the full list
// or portfolios.
export const updateVendorPortfolio = (id: number, newName: string) => {
  return async (
    dispatch: DefaultThunkDispatch,
    getState: () => DefaultRootState
  ) => {
    try {
      await FetchCyberRiskUrl(
        "portfolios/vendor/v1",
        { portfolio_id: id, name: newName },
        { method: "PUT" },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("error updating portfolio", e);
      throw e;
    }
  };
};

// Deletes a portfolio and re-fetches the full list
// or portfolios.
export const deleteVendorPortfolio = (id: number) => {
  return async (
    dispatch: DefaultThunkDispatch,
    getState: () => DefaultRootState
  ) => {
    try {
      await FetchCyberRiskUrl(
        "portfolios/vendor/v1",
        { portfolio_id: id },
        { method: "DELETE" },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("error deleting portfolio", e);
      throw e;
    }
  };
};

export interface INewPortfolio {
  tempId: number;
  name: string;
}

export interface IPortfolioUpdateItem {
  itemIds: (number | string)[];
  portfolioIds: number[];
}

export const portfolioUpdateItemIDForIPRange = (range: IpRangeToUpdate) =>
  `${range.start}:${range.end}`;

export const ipRangeFromPortfolioUpdateItemID = (
  itemID: string
): IpRangeToUpdate => {
  const [start, end] = itemID.split(":");
  return {
    start,
    end,
  };
};

// Sets the portfolio IDs for some sets of vendors.
export const setPortfoliosForVendors = (
  updateItems: IPortfolioUpdateItem[],
  newPortfolios: INewPortfolio[] = []
) => {
  return async (
    dispatch: DefaultThunkDispatch,
    getState: () => DefaultRootState
  ) => {
    try {
      // First lets create any new portfolios. Keep a reference from our temporary IDs
      // to the new portfolio IDs.
      const newPortfolioIdMap: Record<number, number | undefined> = {};

      for (let i = 0; i < newPortfolios.length; i++) {
        const { tempId, name } = newPortfolios[i];
        newPortfolioIdMap[tempId] = await dispatch(createVendorPortfolio(name));
      }

      for (let i = 0; i < updateItems.length; i++) {
        const { portfolioIds: origPortfolioIds, itemIds } = updateItems[i];

        // Replace any temporary portfolio IDs with newly created ones
        const portfolioIds = [...origPortfolioIds];
        for (let j = 0; j < portfolioIds.length; j++) {
          const newPortfolioId = newPortfolioIdMap[portfolioIds[j]];
          if (newPortfolioId) {
            portfolioIds[j] = newPortfolioId;
          }
        }

        await FetchCyberRiskUrl(
          "portfolios/set_for_vendors/v1",
          undefined,
          {
            method: "PUT",
            body: JSON.stringify({
              vendorIds: itemIds,
              portfolioIds,
            }),
          },
          dispatch,
          getState
        );
      }
    } catch (e) {
      LogError("error setting portfolios for vendors", e);
      throw e;
    }
  };
};

// runUpdateVendorPortfolioActions runs update, create and delete actions against portfolios in the current org.
export const runUpdateVendorPortfolioActions = (
  updatedPortfolios: Portfolio[],
  createdPortfolioNames: string[],
  deletedPortfolioIds: number[]
) => {
  return async (
    dispatch: DefaultThunkDispatch,
    getState: () => DefaultRootState
  ) => {
    for (let i = 0; i < deletedPortfolioIds.length; i++) {
      await dispatch(deleteVendorPortfolio(deletedPortfolioIds[i]));
    }

    // If we deleted any portfolios, make sure we refresh data for any watched vendors that were in any deleted portfolios
    if (deletedPortfolioIds.length > 0) {
      const vendorIdsToRefresh: number[] = [];

      const allVendors = getState().cyberRisk.vendors;
      for (const vendorIdStr in allVendors) {
        const vendorPortfolios =
          allVendors[vendorIdStr]?.watching?.result?.vendorPortfolios;
        let hasDeletedPortfolio = false;
        if (vendorPortfolios) {
          for (let i = 0; i < vendorPortfolios.length; i++) {
            if (deletedPortfolioIds.includes(vendorPortfolios[i].id)) {
              hasDeletedPortfolio = true;
              break;
            }
          }
        }

        if (hasDeletedPortfolio) {
          vendorIdsToRefresh.push(parseInt(vendorIdStr));
        }
      }

      // No need to wait for this refresh, just do it in the background
      dispatch(refreshVendorListsAfterChange(vendorIdsToRefresh));
    }

    for (let i = 0; i < updatedPortfolios.length; i++) {
      // Update portfolios before creating new ones in case of name conflicts
      await dispatch(
        updateVendorPortfolio(
          updatedPortfolios[i].id,
          updatedPortfolios[i].name
        )
      );
    }

    for (let i = 0; i < createdPortfolioNames.length; i++) {
      await dispatch(createVendorPortfolio(createdPortfolioNames[i]));
    }

    await dispatch(fetchVendorPortfolios(true));
  };
};

export const fetchDomainPortfolios = (force = false) => {
  return async (
    dispatch: DefaultThunkDispatch,
    getState: () => DefaultRootState
  ) => {
    if (!force) {
      const domainPortfolios = getState().cyberRisk.domainPortfolios;
      if (domainPortfolios) {
        return domainPortfolios;
      }
    }

    let resp: {
      portfolios: Portfolio[];
      grandTotalItems: number;
      limit: number;
    };
    try {
      resp = await FetchCyberRiskUrl(
        "portfolios/domain/v1",
        undefined,
        { method: "GET" },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("error fetching domain portfolios", e);
      throw e;
    }

    dispatch(
      setDomainPortfolios(resp.portfolios, resp.grandTotalItems, resp.limit)
    );

    // Just check that the user has access to all the portfolios in the current filter.
    // Because we save/rehydrate the portfolios filter to local storage, it may be possible
    // to have a portfolio ID in the filter that the user doesn't have access to (such as
    // from a different org).
    const filteredPortfolioIds =
      getState().cyberRisk.customerData.filters.domainPortfolioIds || [];
    const newFilteredPortfolioIds = [];
    let portfolioFilterChanged = false;
    for (let i = 0; i < filteredPortfolioIds.length; i++) {
      if (resp.portfolios.find((p) => p.id === filteredPortfolioIds[i])) {
        newFilteredPortfolioIds.push(filteredPortfolioIds[i]);
      } else {
        portfolioFilterChanged = true;
      }
    }

    if (portfolioFilterChanged) {
      dispatch(
        setCustomerDataFiltersAndRefreshData({
          domainPortfolioIds: newFilteredPortfolioIds,
        })
      );
    }

    return resp;
  };
};

// Creates a new portfolio.
export const createDomainPortfolio = (name: string) => {
  return async (
    dispatch: DefaultThunkDispatch,
    getState: () => DefaultRootState
  ): Promise<number> => {
    try {
      const resp = await FetchCyberRiskUrl<{
        portfolioId: number;
      }>(
        "portfolios/domain/v1",
        { name },
        { method: "POST" },
        dispatch,
        getState
      );

      return resp.portfolioId;
    } catch (e) {
      LogError("error creating domain portfolio", e);
      throw e;
    }
  };
};

// Updates a portfolio.
export const updateDomainPortfolio = (id: number, newName: string) => {
  return async (
    dispatch: DefaultThunkDispatch,
    getState: () => DefaultRootState
  ) => {
    try {
      await FetchCyberRiskUrl(
        "portfolios/domain/v1",
        { portfolio_id: id, name: newName },
        { method: "PUT" },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("error updating domain portfolio", e);
      throw e;
    }
  };
};

// Deletes a portfolio.
export const deleteDomainPortfolio = (id: number) => {
  return async (
    dispatch: DefaultThunkDispatch,
    getState: () => DefaultRootState
  ) => {
    try {
      await FetchCyberRiskUrl(
        "portfolios/domain/v1",
        { portfolio_id: id },
        { method: "DELETE" },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("error deleting portfolio", e);
      throw e;
    }
  };
};

// runUpdateDomainPortfolioActions runs update, create and delete actions against domain portfolios in the current org.
export const runUpdateDomainPortfolioActions = (
  updatedPortfolios: Portfolio[],
  createdPortfolioNames: string[],
  deletedPortfolioIds: number[]
) => {
  return async (
    dispatch: DefaultThunkDispatch
    // getState: () => DefaultRootState
  ) => {
    for (let i = 0; i < deletedPortfolioIds.length; i++) {
      await dispatch(deleteDomainPortfolio(deletedPortfolioIds[i]));
    }

    for (let i = 0; i < updatedPortfolios.length; i++) {
      // Update portfolios before creating new ones in case of name conflicts
      await dispatch(
        updateDomainPortfolio(
          updatedPortfolios[i].id,
          updatedPortfolios[i].name
        )
      );
    }

    for (let i = 0; i < createdPortfolioNames.length; i++) {
      await dispatch(createDomainPortfolio(createdPortfolioNames[i]));
    }

    await dispatch(fetchDomainPortfolios(true));

    // If any portfolios were deleted or updated, we need to make sure breachsight data is up to date
    if (deletedPortfolioIds.length > 0 || updatedPortfolios.length > 0) {
      dispatch(clearDomains());
      dispatch(fetchCustomerSummaryAndCloudscans(true));
      dispatch(conditionalRefreshActivityStreamForOrgUser());
    }
  };
};

// Set new portfolios for sets of hostnames.
export const setPortfoliosForDomains = (
  updateItems: IPortfolioUpdateItem[],
  newPortfolios: INewPortfolio[] = [],
  applyToSubdomains: boolean
) => {
  return async (
    dispatch: DefaultThunkDispatch,
    getState: () => DefaultRootState
  ) => {
    const allPortfolios = [
      ...(getState().cyberRisk.domainPortfolios?.portfolios ?? []),
    ];

    try {
      // First lets create any new portfolios. Keep a reference from our temporary IDs
      // to the new portfolio IDs.
      const newPortfolioIdMap: Record<number, number | undefined> = {};

      for (let i = 0; i < newPortfolios.length; i++) {
        const { tempId, name } = newPortfolios[i];
        const newPortfolioId = await dispatch(createDomainPortfolio(name));
        newPortfolioIdMap[tempId] = newPortfolioId;
        allPortfolios.push({
          id: newPortfolioId,
          type: PortfolioType.Domain,
          name,
          isDefault: false,
          numItems: 0,
        });
      }

      for (let i = 0; i < updateItems.length; i++) {
        const { portfolioIds: origPortfolioIds, itemIds } = updateItems[i];

        // Replace any temporary portfolio IDs with newly created ones
        const portfolioIds = [...origPortfolioIds];
        for (let j = 0; j < portfolioIds.length; j++) {
          const newPortfolioId = newPortfolioIdMap[portfolioIds[j]];
          if (newPortfolioId) {
            portfolioIds[j] = newPortfolioId;
          }
        }

        updateItems[i].portfolioIds = portfolioIds;

        await FetchCyberRiskUrl(
          "portfolios/set_for_domains/v1",
          undefined,
          {
            method: "PUT",
            body: JSON.stringify({
              hostnames: itemIds,
              portfolioIds,
              applyToSubdomains,
            }),
          },
          dispatch,
          getState
        );
      }
    } catch (e) {
      LogError("error setting portfolios for domains", e);
      throw e;
    }

    await updateDomainsStateAfterPortfoliosChange(
      dispatch,
      getState(),
      updateItems,
      allPortfolios,
      applyToSubdomains
    );
  };
};

// Set new portfolios for sets of IPs and/or ranges.
// NOTE: this is not currently used.
export const setPortfoliosForIPRanges = (
  updateItems: IPortfolioUpdateItem[],
  newPortfolios: INewPortfolio[] = []
) => {
  return async (
    dispatch: DefaultThunkDispatch,
    getState: () => DefaultRootState
  ) => {
    const allPortfolios = [
      ...(getState().cyberRisk.domainPortfolios?.portfolios ?? []),
    ];

    const existingIPAddresses = getState().cyberRisk.customerData?.ipAddresses;

    try {
      // First lets create any new portfolios. Keep a reference from our temporary IDs
      // to the new portfolio IDs.
      const newPortfolioIdMap: Record<number, number | undefined> = {};

      for (let i = 0; i < newPortfolios.length; i++) {
        const { tempId, name } = newPortfolios[i];
        const newPortfolioId = await dispatch(createDomainPortfolio(name));
        newPortfolioIdMap[tempId] = newPortfolioId;
        allPortfolios.push({
          id: newPortfolioId,
          type: PortfolioType.Domain,
          name,
          isDefault: false,
          numItems: 0,
        });
      }

      for (let i = 0; i < updateItems.length; i++) {
        const { portfolioIds: origPortfolioIds, itemIds } = updateItems[i];

        // Replace any temporary portfolio IDs with newly created ones
        const portfolioIds = [...origPortfolioIds];
        for (let j = 0; j < portfolioIds.length; j++) {
          const newPortfolioId = newPortfolioIdMap[portfolioIds[j]];
          if (newPortfolioId) {
            portfolioIds[j] = newPortfolioId;
          }
        }

        const ipRanges = itemIds.map((itemId) =>
          ipRangeFromPortfolioUpdateItemID(itemId as string)
        );

        await FetchCyberRiskUrl(
          "portfolios/set_for_ips/v1",
          undefined,
          {
            method: "PUT",
            body: JSON.stringify({
              ipRanges,
              portfolioIds,
            }),
          },
          dispatch,
          getState
        );

        // Now update the redux state for any changed IPs and ranges.
        const newPortfolios = allPortfolios.filter((p) =>
          portfolioIds.includes(p.id)
        );

        for (let j = 0; j < ipRanges.length; j++) {
          const ipRange = ipRanges[j];

          if (ipRange.start === ipRange.end) {
            const matchingIPAddress = existingIPAddresses?.ipAddresses?.find(
              (ip) => ip.ip === ipRange.start
            );
            if (matchingIPAddress) {
              dispatch(
                setIpAddressAndIpAddressDetailState(
                  {
                    ...matchingIPAddress,
                    portfolios: newPortfolios,
                  },
                  undefined,
                  false
                )
              );
            }
          } else {
            const matchingRange = existingIPAddresses?.ipRanges?.find(
              (r) => r.start === ipRange.start && r.end === ipRange.end
            );
            if (matchingRange) {
              dispatch(
                setIpRangeState(
                  {
                    ...matchingRange,
                    portfolios: newPortfolios,
                  },
                  undefined,
                  false
                )
              );
            }
          }
        }
      }
    } catch (e) {
      LogError("error setting portfolios for IPs", e);
      throw e;
    }
  };
};
