import classnames from "classnames";
import React from "react";
import { severityMap } from "../../_common/helpers";
import { SelectV2 } from "../../_common/components/SelectV2";
import {
  CVSSInt,
  CVSSToSeverity,
  CVSSToString,
} from "../../_common/types/cvss";

import "../style/components/SeveritySelector.scss";

export interface ICVSSSelectorProps {
  cvss?: CVSSInt;
  isDisabled?: boolean;
  onChange: (newCVSS?: CVSSInt) => void;
  placeholder?: string;
}

function CVSSSelector({
  cvss,
  isDisabled = false,
  onChange,
  placeholder = "Select CVSS of this vulnerability",
}: ICVSSSelectorProps): React.ReactNode {
  return (
    <SelectV2
      className="severity-selector"
      placeholder={placeholder}
      options={Object.values(CVSSInt)
        .filter((v) => typeof v === "number")
        .map((v) => Number(v))
        .sort((a, b) => a - b)
        .reverse()
        .map((c) => ({
          label: c.toString(),
          value: c,
        }))}
      value={
        typeof cvss === "number"
          ? { label: CVSSToString(cvss), value: cvss }
          : undefined
      }
      onChange={(v) => onChange(v?.value as CVSSInt)}
      isDisabled={isDisabled}
      isSearchable={false}
      formatOptionLabel={(opt, ctx) => {
        // Is this formatting for a selected option in the dropdown menu?
        const selected =
          ctx.context === "menu" &&
          Array.isArray(ctx.selectValue) &&
          ctx.selectValue.length > 0 &&
          ctx.selectValue[0].value === opt.value;
        const severity = severityMap[CVSSToSeverity(opt.value as CVSSInt)];
        return (
          <div className={classnames("severity-opt", { selected })}>
            {severity.icon} {CVSSToString(opt.value as CVSSInt)} (
            {severity.name})
          </div>
        );
      }}
    />
  );
}

export default CVSSSelector;
