import { IdentityBreach } from "../../api/types";
import { FC } from "react";
import LabelList from "../../../vendorrisk/components/LabelList";
import { emailExposureSeverityNumberToLabelColor } from "../../../vendorrisk/components/email_exposures/IdentityBreachesCard";
import PillLabel from "../../../vendorrisk/components/PillLabel";
import { LabelColor } from "../../../_common/types/label";

interface IdentityBreachDataClassesProps {
  breach: IdentityBreach;
  maxWidth?: number;
}

const IdentityBreachDataClasses: FC<IdentityBreachDataClassesProps> = ({
  breach,
  maxWidth,
}) => {
  if (breach.dataClasses && breach.dataClasses.length) {
    return (
      <LabelList
        maxWidth={maxWidth}
        labels={breach.dataClasses
          .slice()
          .sort((a, b) =>
            a.Severity !== b.Severity
              ? b.Severity - a.Severity
              : b.Class.length - a.Class.length
          )
          .map((dc) => ({
            id: dc.Class,
            name: dc.Class,
            color: emailExposureSeverityNumberToLabelColor[dc.Severity],
          }))}
      />
    );
  }

  return <PillLabel color={LabelColor.Grey}>Unknown</PillLabel>;
};

export default IdentityBreachDataClasses;
