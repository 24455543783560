import {
  AvailableExportConfig,
  AvailableOption,
  ConfiguredExportConfig,
  ConfiguredOption,
  ConfiguredSection,
  getAvailableOptionByID,
  getRequiredExtraStepsFromConfig,
  RequiredExtraStep,
  validateConfiguredExportConfig,
} from "../../../_common/types/exportConfig";
import { produce } from "immer";
import { Dispatch, useEffect, useReducer, useRef } from "react";
import { union as _union } from "lodash";

export type configuredExportConfigReducerAction =
  | {
      type: "UPDATE_AVAILABLE_CONFIG";
      availableConfig: AvailableExportConfig;
      configuredConfig?: ConfiguredExportConfig; // Only set initially, if we have config we want to use as a starting point.
    }
  | {
      type: "SET_OPTION";
      id: string;
      isRadio: boolean;
      valueBool: boolean;
      valueText: string;
    }
  | {
      type: "SET_SECTION";
      id: string;
      value: boolean;
    }
  | {
      type: "MOVE_SECTION";
      index: number;
      newIndex: number;
    };

export interface ConfiguredExportConfigState {
  availableExportConfig: AvailableExportConfig;
  configuredExportConfig: ConfiguredExportConfig;
  initialised: boolean;
  valid: boolean;
  globalOptsValid: boolean;
  sectionsValid: boolean;
  validationErr?: string;
  globalOptsValidationErr?: string;
  sectionsValidationErr?: string;
  requiredExtraSteps: RequiredExtraStep[];
  hasChanged: boolean;
}

const configuredExportConfigReducer = (
  state: ConfiguredExportConfigState,
  action: configuredExportConfigReducerAction
) =>
  produce(state, (draftState) => {
    switch (action.type) {
      // UPDATE_AVAILABLE_CONFIG is called initially or when AvailableExportConfig changes.
      // Ensure all existing config adheres to the new config, and
      // ensure all defaults exist.
      case "UPDATE_AVAILABLE_CONFIG": {
        // Resets the configured config to a desired initial state.
        if (action.configuredConfig) {
          draftState.configuredExportConfig = { ...action.configuredConfig };
        }

        const newGlobalOptions: Record<string, ConfiguredOption | undefined> =
          {};

        const processOption = (
          opt: AvailableOption,
          existingOptions:
            | Record<string, ConfiguredOption | undefined>
            | undefined,
          setOpt: (newOpt: ConfiguredOption) => void
        ) => {
          const existingOpt = existingOptions?.[opt.id];

          const newOpt: ConfiguredOption = {
            id: opt.id,
            valueBool:
              !!opt.optionSectionOpts ||
              (opt.checkboxOpts?.defaultSelected ??
                opt.radioOpts?.defaultSelected) ||
              false,
            valueText: "",
          };

          if (
            existingOpt &&
            !opt.checkboxOpts?.disabled &&
            !opt.radioOpts?.disabled
          ) {
            newOpt.valueBool = existingOpt.valueBool;
            newOpt.valueText = existingOpt.valueText;
          }

          (
            opt.optionSectionOpts?.children ??
            opt.checkboxOpts?.children ??
            []
          ).forEach((childOpt) =>
            processOption(childOpt, existingOptions, setOpt)
          );

          setOpt(newOpt);
        };

        action.availableConfig.globalOptions?.forEach((opt) =>
          processOption(
            opt,
            draftState.configuredExportConfig.globalOptions,
            (newOpt) => (newGlobalOptions[newOpt.id] = newOpt)
          )
        );

        draftState.configuredExportConfig.globalOptions = {
          ...newGlobalOptions,
        };

        // Next, run through the sections.
        const newSections: ConfiguredSection[] = (
          action.availableConfig.sections ?? []
        ).map((section) => {
          const existingSection =
            draftState.configuredExportConfig.sections.find(
              (s) => s.id === section.id
            );

          const newSection: ConfiguredSection = {
            id: section.id,
            value: section.defaultSelected,
            options: {},
          };

          // Use the value from the previously found section if available;
          if (!section.disabled && existingSection) {
            newSection.value = existingSection.value;
          }

          // Reconcile all options within this section
          section.options?.forEach((opt) =>
            processOption(
              opt,
              existingSection?.options,
              (newOpt) => (newSection.options[newOpt.id] = newOpt)
            )
          );

          return newSection;
        });

        // Now ensure consistent ordering. Only do this if the previously arranged sections
        // exactly match the new ones by IDs.
        if (
          newSections.length ===
            draftState.configuredExportConfig.sections.length &&
          _union(
            newSections.map((s) => s.id),
            draftState.configuredExportConfig.sections.map((s) => s.id)
          ).length === newSections.length
        ) {
          const reorderedSections: ConfiguredSection[] = [];

          for (
            let i = 0;
            i < draftState.configuredExportConfig.sections.length;
            i++
          ) {
            const newSection = newSections.find(
              (s) => s.id === draftState.configuredExportConfig.sections[i].id
            );
            if (newSection) {
              reorderedSections[i] = newSection;
            }
          }
          draftState.configuredExportConfig.sections = reorderedSections;
        } else {
          draftState.configuredExportConfig.sections = newSections;
        }

        // And store the availableExportConfig in the state too.
        draftState.availableExportConfig = action.availableConfig;
        draftState.initialised = true;
        break;
      }
      // SET_OPTION is called whenever an option (within globalOptions or within a section)
      // has its value updated.
      case "SET_OPTION": {
        const findOptionAndContainer = (
          opts: Record<string, ConfiguredOption | undefined> | undefined,
          id: string
        ):
          | [Record<string, ConfiguredOption | undefined>, ConfiguredOption]
          | undefined => {
          if (!opts) {
            return undefined;
          }

          const foundOpt = opts[id];
          if (foundOpt) {
            return [opts, foundOpt];
          }

          return undefined;
        };

        let optAndContainer = findOptionAndContainer(
          draftState.configuredExportConfig.globalOptions,
          action.id
        );
        if (!optAndContainer) {
          for (
            let i = 0;
            i < draftState.configuredExportConfig.sections.length;
            i++
          ) {
            optAndContainer = findOptionAndContainer(
              draftState.configuredExportConfig.sections[i].options,
              action.id
            );
            if (optAndContainer) {
              break;
            }
          }
        }

        if (optAndContainer) {
          const [container, foundOpt] = optAndContainer;

          if (action.isRadio) {
            // For the radio type, we want to switch all the other radios in the group off.
            for (const k in container) {
              const opt = container[k];
              if (!opt) {
                continue;
              }

              // If not a radio, ignore
              const availableOpt = getAvailableOptionByID(
                k,
                draftState.availableExportConfig
              );
              if (!availableOpt?.radioOpts) {
                continue;
              }

              opt.valueBool = opt.id === action.id;
            }
          } else {
            foundOpt.valueBool = action.valueBool;
            foundOpt.valueText = action.valueText;
          }
        }

        draftState.hasChanged = true;
        break;
      }

      // SET_SECTION is called whenever a section has its value set.
      case "SET_SECTION": {
        for (
          let i = 0;
          i < draftState.configuredExportConfig.sections.length;
          i++
        ) {
          if (draftState.configuredExportConfig.sections[i].id === action.id) {
            draftState.configuredExportConfig.sections[i].value = action.value;
            break;
          }
        }

        draftState.hasChanged = true;
        break;
      }

      // MOVE_SECTION is called whenever a section is moved from one index to another
      case "MOVE_SECTION": {
        const [removed] = draftState.configuredExportConfig.sections.splice(
          action.index,
          1
        );
        draftState.configuredExportConfig.sections.splice(
          action.newIndex,
          0,
          removed
        );

        draftState.hasChanged = true;
        break;
      }
    }

    // After any changes are applied from the action, re-validate the configured structure.
    const validationResult = validateConfiguredExportConfig(
      draftState.availableExportConfig,
      draftState.configuredExportConfig
    );
    draftState.valid =
      validationResult.globalOptsValid && validationResult.sectionsValid;
    draftState.globalOptsValid = validationResult.globalOptsValid;
    draftState.sectionsValid = validationResult.sectionsValid;
    draftState.validationErr =
      validationResult.globalOptsValidationErr ||
      validationResult.sectionsValidationErr;
    draftState.globalOptsValidationErr =
      validationResult.globalOptsValidationErr;
    draftState.sectionsValidationErr = validationResult.sectionsValidationErr;

    // Find any required extra steps based on the selected sections and options
    draftState.requiredExtraSteps = getRequiredExtraStepsFromConfig(
      draftState.configuredExportConfig,
      draftState.availableExportConfig
    );
  });

export const useConfiguredExportConfigReducer = (
  availableExportConfig: AvailableExportConfig | undefined,
  initialConfigured: ConfiguredExportConfig | undefined
): [
  ConfiguredExportConfigState,
  Dispatch<configuredExportConfigReducerAction>,
] => {
  const [state, dispatch] = useReducer(configuredExportConfigReducer, {
    availableExportConfig: {
      requireGlobalOptionsOnQuickGenerate: false,
    },
    configuredExportConfig: {
      vendorIDRequired: false,
      globalOptions: {},
      sections: [],
    },
    valid: false,
    globalOptsValid: false,
    sectionsValid: false,
    requiredExtraSteps: [],
    initialised: false,
    hasChanged: false,
  });

  // Ensure the reducer's state is reconciled whenever the availableExportConfig is updated
  const initialised = useRef(false);
  useEffect(() => {
    if (availableExportConfig) {
      dispatch({
        type: "UPDATE_AVAILABLE_CONFIG",
        availableConfig: availableExportConfig,
        configuredConfig: initialised.current ? undefined : initialConfigured,
      });

      initialised.current = true;
    }
  }, [
    dispatch,
    availableExportConfig,
    // Purposely omitting initialConfigured as we only want to use it on init
  ]);

  return [state, dispatch];
};
