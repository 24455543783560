export interface IAutomationRecipe {
  id: number;
  uuid: string;
  organisationId: number;
  surveyTypeId: number;
  isWeighted: boolean;
  name: string;
  description: string;
  trigger: string;
  targetAttribute: string;
  targetAttributeOverwrite: boolean;
  customAttributeId?: number;
  customAttributeName?: string;
  userConstructs: IAutomationUserConstructs;
  weights?: IAutomationWeights | null;
  mapping: IAutomationOutputMapping;
  isNew: boolean;
  enabled: boolean;
  editsExist: boolean;
  sortOrder: number;
  draft: boolean;
  parent?: number;
  updatedBy: number;
  updatedByName: string;
  updatedByEmail: string;
  updatedByAvatar: string;
  createdAt: string;
  updatedAt: string;
}

export interface IAutomationWeights {
  [questionId: string]: IAutomationWeight;
}

export interface IAutomationWeight {
  questionText: string;
  answerWeights: IAnswerWeights;
  function: string;
  questionType: string;
  questionIDAlias: string;
  weightNone: number;
}

export interface IAnswerWeights {
  [answerId: string]: number;
}

export interface IAnswerValue {
  answerId: string;
  value: string;
}

export interface IAutomationQuestion {
  id: string;
  questionType: string;
  questionText: string;
  multiValue: boolean;
  answers?: IAnswerValue[];
}

export interface IAutomationRangeRule {
  min: string;
  max: string;
  output: string;
}

export interface IAutomationDiscreteRule {
  expression: string;
  output: string;
}

export interface IAutomationOutputMapping {
  isDirect: boolean;
  discreteRules: IAutomationDiscreteRule[];
  rangeRules: IAutomationRangeRule[];
  default: string;
}

export interface IRangeMinMax {
  min: number;
  max: number;
}

export interface IAutomationUserConstructs {
  masterExpression: string;
  usingCustomExpression: boolean;
  usingSimpleRules: boolean;
  simpleRules?: IAutomationSimpleRule[];
}

export interface IAutomationSimpleRule {
  uuid: string;
  targetValue: string;
  logic: IAutomationTreeNode;
  setTargetFromAnswer: boolean;
  setTargetFromAnswerId?: string;
}

export interface IAutomationTreeNode {
  uuid: string;
  operator: IAutomationTreeNodeOperator;
  tests: IAutomationTest[];
  branches: IAutomationTreeNode[];
}

export enum IAutomationTreeNodeOperator {
  OR = "or",
  AND = "and",
}

export interface IAutomationTest {
  uuid: string;
  questionId: string;
  operator: IAutomationTestOperator;
  value: string;
  notAnswered?: boolean;
  isMultiSelect?: boolean;
}

export enum IAutomationTestOperator {
  EQ = "eq",
  NEQ = "neq",
  CONTAINS = "contains",
  PREFIX = "prefix",
  SUFFIX = "suffix",
}
