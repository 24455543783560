import ColorCheckbox from "../ColorCheckbox";
import VendorTierBadge from "./VendorTierBadge";
import { VendorTier } from "../../reducers/vendorTiers.actions";

import "../../style/components/VendorTierChoiceSet.scss";

interface VendorTierChoiceSetProps {
  tiers: VendorTier[];
  selectedTeirs: number[];
  onSelectionChange: (selected: number[]) => void;
  includeDisabledTiers?: boolean;
  multiSelect?: boolean;
}

const VendorTierChoiceSet = ({
  tiers,
  selectedTeirs,
  onSelectionChange,
  includeDisabledTiers,
  multiSelect,
}: VendorTierChoiceSetProps) => {
  const tierDisplays = tiers
    .filter((t) => t.isActive || includeDisabledTiers)
    .map((t) => (
      <VendorTierChoice
        key={t.tier}
        multiSelect={multiSelect}
        tier={t}
        isSelected={selectedTeirs.indexOf(t.tier) !== -1}
        onSelected={(isSelected) => {
          if (isSelected) {
            const existingSelected = multiSelect ? [...selectedTeirs] : [];
            onSelectionChange([...existingSelected, t.tier]);
          } else {
            onSelectionChange(
              selectedTeirs.filter((tLevel) => tLevel !== t.tier)
            );
          }
        }}
      />
    ));

  return <div className={"vendor-tier-choice-set"}>{tierDisplays}</div>;
};

export default VendorTierChoiceSet;

interface VendorTierChoiceProps {
  tier: VendorTier;
  isSelected: boolean;
  onSelected: (isSelected: boolean) => void;
  multiSelect?: boolean;
}

const VendorTierChoice = ({
  tier,
  isSelected,
  onSelected,
  multiSelect,
}: VendorTierChoiceProps) => {
  return (
    <div className={"vendor-tier"} key={tier.id}>
      <ColorCheckbox
        radio={!multiSelect}
        checked={isSelected}
        onClick={() => onSelected(!isSelected)}
        label={<VendorTierBadge tier={tier.tier} name={tier.name} showName />}
      />
    </div>
  );
};
