import "../style/components/ContactSelector.scss";
import ModalForm from "./modals/ModalForm";
import ColorCheckbox from "./ColorCheckbox";
import { LabelColor } from "../../_common/types/label";
import UserAvatar from "../../_common/components/UserAvatar";
import Button from "../../_common/components/core/Button";
import IconButton from "../../_common/components/IconButton";
import { getGravatarURL, validateEmail } from "../../_common/helpers";
import TextField from "../../_common/components/TextField";
import { FC, useState } from "react";
import { IVendorContactResponse } from "../../_common/types/vendorContact";
import { toInteger } from "lodash";
import classNames from "classnames";
import LoadingIcon from "../../_common/components/core/LoadingIcon";
import classnames from "classnames";

export interface existingUser {
  name: string;
  email: string;
  isInvite?: boolean;
  alreadyAdded?: boolean;
  selected: boolean;
  isChecking?: boolean;
  isSelectionDisabled?: boolean;
  selectionDisabledMessage?: string;
}

export interface newContact {
  tempId: string; // Temporary id to use as a key in react, must be unique
  firstName?: string;
  lastName?: string;
  email: string;
  title?: string;
  selected?: boolean;
  isChecking?: boolean;
  isEmailDomainNotAllowed?: boolean;
}

export const getBlankNewContact = (): newContact => {
  return {
    tempId:
      new Date().valueOf().toString() +
      Math.floor(Math.random() * 100000).toString(),
    firstName: "",
    lastName: "",
    email: "",
    title: "",
  };
};

export const isBlankNewContact = (c: newContact) => {
  return !c.firstName && !c.lastName && !c.email && !c.title;
};

export const useNewRecipients = (
  existingNewRecipients?: newContact[]
): [
  newContact[],
  () => void,
  (tempId: string) => void,
  (tempId: string, fields: Partial<newContact>) => void,
  () => void,
] => {
  const [newRecipients, setNewRecipients] = useState(
    existingNewRecipients ?? ([] as newContact[])
  );

  const addNewRecipient = () =>
    setNewRecipients((old) => [...old, getBlankNewContact()]);

  const deleteNewRecipinet = (tempId: string) =>
    setNewRecipients((old) => {
      const idx = old.findIndex((r) => r.tempId === tempId);
      if (idx > -1) {
        old.splice(idx, 1);
      }
      return [...old];
    });

  const updateNewRecipient = (tempId: string, fields: Partial<newContact>) =>
    setNewRecipients((old) => {
      const newRecipients = [...old];
      const idx = newRecipients.findIndex((r) => r.tempId === tempId);
      if (idx > -1) {
        newRecipients[idx] = { ...newRecipients[idx], ...fields };
        if (isBlankNewContact(newRecipients[idx])) {
          newRecipients.splice(idx, 1);
        }
      }
      return newRecipients;
    });

  const clearNewRecipients = () => setNewRecipients([]);

  return [
    newRecipients,
    addNewRecipient,
    deleteNewRecipinet,
    updateNewRecipient,
    clearNewRecipients,
  ];
};

interface IVendorContactsSelection {
  vendorId: number;
  existingContacts: existingUser[];
  newContacts: newContact[];
}

export const useVendorContacts: () => [
  Record<number, IVendorContactsSelection>,
  (vendorContacts: Record<number, IVendorContactResponse[]>) => void,
  (vendorId: number, email: string, selected: boolean) => void,
  (vendorId: number) => void,
  (vendorId: number, tempId: string) => void,
  (vendorId: number, tempId: string, fields: Partial<newContact>) => void,
] = () => {
  const [vendorContacts, setVendorContacts] = useState(
    {} as Record<number, IVendorContactsSelection>
  );

  // Should be called when new contacts are loaded for vendors
  // Adds entries for new vendors and removes old ones
  const onContactsLoaded = (
    vendorContacts: Record<number, IVendorContactResponse[]>
  ) =>
    setVendorContacts((contacts) => {
      const newContacts = { ...contacts };

      Object.entries(vendorContacts).forEach(([id, thisVendorContacts]) => {
        const vendorId = toInteger(id);
        if (!newContacts[vendorId] && thisVendorContacts) {
          newContacts[vendorId] = {
            vendorId,
            newContacts: [],
            existingContacts: thisVendorContacts.map((c, i) => ({
              name: c.name ?? "",
              email: c.emailAddress ?? "",
              alreadyAdded: false,
              isInvite: false,
              selected: i === 0, // select the first contact by default
            })),
          };
        }
      });

      Object.keys(newContacts).forEach((id) => {
        const vendorId = toInteger(id);
        if (!vendorContacts[vendorId]) {
          delete newContacts[vendorId];
        }
      });

      return newContacts;
    });

  const selectExistingContact = (
    vendorId: number,
    email: string,
    selected: boolean
  ) =>
    setVendorContacts((contacts) => {
      const newContacts = { ...contacts };
      const users = newContacts[vendorId];
      if (users) {
        const idx = users.existingContacts.findIndex((u) => u.email === email);
        const existingContacts = [...users.existingContacts];
        if (idx !== -1) {
          existingContacts[idx].selected = selected;
          newContacts[vendorId].existingContacts = existingContacts;
        }
      }
      return newContacts;
    });

  const addNewRecipient = (vendorId: number) =>
    setVendorContacts((contacts) => ({
      ...contacts,
      [vendorId]: {
        ...contacts[vendorId],
        newContacts: [...contacts[vendorId].newContacts, getBlankNewContact()],
      },
    }));

  const deleteNewRecipient = (vendorId: number, tempId: string) =>
    setVendorContacts((contacts) => {
      const newContacts = { ...contacts };
      const users = newContacts[vendorId];

      if (users) {
        const idx = users.newContacts.findIndex((u) => u.tempId === tempId);
        const nc = [...users.newContacts];
        if (idx !== -1) {
          nc.splice(idx);
          newContacts[vendorId].newContacts = nc;
        }
      }

      return newContacts;
    });

  const updateNewRecipient = (
    vendorId: number,
    tempId: string,
    fields: Partial<newContact>
  ) =>
    setVendorContacts((contacts) => {
      const newContacts = { ...contacts };
      const users = newContacts[vendorId];

      if (users) {
        const idx = users.newContacts.findIndex((u) => u.tempId === tempId);
        const nc = [...users.newContacts];
        if (idx !== -1) {
          nc[idx] = {
            ...newContacts[vendorId].newContacts[idx],
            ...fields,
          };
          newContacts[vendorId].newContacts = nc;
        }
      }

      return newContacts;
    });

  return [
    vendorContacts,
    onContactsLoaded,
    selectExistingContact,
    addNewRecipient,
    deleteNewRecipient,
    updateNewRecipient,
  ];
};

// If there are any new contacts specified that
//  - aren't fully filled in
//  - are in the process of checking the email domain
//  - OR the email domain is not allowed
//  - THEN it's not valid.
export const validateNewContact = (
  contact?: newContact,
  selectEmailOnly = false
) => {
  if (!contact) {
    return false;
  }
  return !(
    (!selectEmailOnly &&
      (!contact.firstName?.trim() || !contact.lastName?.trim())) ||
    !contact.email ||
    !validateEmail(contact.email) ||
    contact.isChecking ||
    contact.isEmailDomainNotAllowed
  );
};
export const validateContactSelector = (
  existingUsers: existingUser[],
  newContacts: newContact[],
  selectEmailOnly: boolean,
  skipMinLengthCheck = false
): boolean => {
  if (!newContacts.every((nc) => validateNewContact(nc, selectEmailOnly))) {
    return false;
  }

  if (skipMinLengthCheck) {
    // Don't need to check if there is at least 1 contact
    return true;
  }

  if (newContacts.length > 0) {
    // New contacts have been added and they're all valid.
    return true;
  }

  // Now we need to check that at least one existing contact has been added.
  for (let i = 0; i < existingUsers.length; i++) {
    if (existingUsers[i].selected && !existingUsers[i].isSelectionDisabled) {
      return true;
    }
  }

  return false;
};

export interface IContactSelectorProps {
  entityName?: string; // The type of entity we're selecting contacts for, such as "questionnaire" or "remediation request".
  existingUsers?: existingUser[];
  newContacts: newContact[];
  setExistingUserSelected?: (email: string, selected: boolean) => void;
  addNewContact: () => void;
  updateNewContact: (tempId: string, fields: Partial<newContact>) => void;
  deleteNewContact: (tempId: string) => void;
  canDeselectExisting?: boolean;
  selectEmailOnly?: boolean;
  radioSelector?: boolean; // makes the selector a radio selector, enables selecting/deselecting of new contacts
  limitNewContacts?: boolean; // limits the creation of new contacts to one
  canDeselectNew?: boolean;
  onSelectNew?: (tempId: string, selected: boolean) => void;
  hideDescription?: boolean; // if false the description on the side will be hidden
  showTitles?: boolean; // if true titles will be shown for both sections
  emailDomainNotAllowedTextError?: string; // Message to show if an email domain for a contact is not allowed
  hideCheckboxes?: boolean; // Hide selection checkboxes
  disableDeleteWhenSingleNewContact?: boolean; // Disable delete button for single new contact (essentially minimum 1)
}

const ContactSelector = (props: IContactSelectorProps) => {
  const alreadyAddedUsers: existingUser[] = [];
  const otherExistingUsers: existingUser[] = [];

  if (props.existingUsers) {
    for (let i = 0; i < props.existingUsers.length; i++) {
      if (props.existingUsers[i].alreadyAdded) {
        alreadyAddedUsers.push(props.existingUsers[i]);
      } else {
        otherExistingUsers.push(props.existingUsers[i]);
      }
    }
  }

  const renderUser = (u: existingUser) => (
    <SelectableUser
      key={u.email}
      user={u}
      canDeselectExisting={props.canDeselectExisting}
      radioSelector={props.radioSelector}
      onSelect={() =>
        props.setExistingUserSelected &&
        props.setExistingUserSelected(u.email, !u.selected)
      }
    />
  );

  return (
    <ModalForm className="contact-selector">
      {alreadyAddedUsers.length > 0 && (
        <div className="form-section">
          {!props.hideDescription && (
            <div className="form-section-desc">
              <span>Users already added</span>
              <p>
                These users have already received this {props.entityName}.
                {props.canDeselectExisting && " You can remove them."}
              </p>
            </div>
          )}
          <div className="form-section-input">
            {alreadyAddedUsers.map(renderUser)}
          </div>
        </div>
      )}
      <div className="form-section">
        {!props.hideDescription && (
          <div className="form-section-desc">
            {alreadyAddedUsers.length > 0 ? (
              <>
                <span>Choose new recipients</span>
                <p>
                  If you would like to add additional recipients to this{" "}
                  {props.entityName}, add them here.
                </p>
              </>
            ) : (
              <span>
                {otherExistingUsers.length > 0
                  ? "Choose recipients"
                  : "Enter recipients"}
              </span>
            )}
          </div>
        )}
        <div className="form-section-input">
          {(otherExistingUsers.length > 0 || props.showTitles) && (
            <div
              className={classNames("existing-contacts-section", {
                ["modal-section-box"]: props.showTitles,
              })}
            >
              {props.showTitles && <h3>{`Select ${props.entityName}/s`}</h3>}
              {props.showTitles && otherExistingUsers.length == 0 && (
                <p>{`No existing ${props.entityName} for this vendor.`}</p>
              )}
              {otherExistingUsers.map(renderUser)}
            </div>
          )}
          <div
            className={classNames("new-contacts-section", {
              ["modal-section-box"]: props.showTitles,
            })}
          >
            {props.showTitles && <h3>{`Add new ${props.entityName}`}</h3>}
            {props.newContacts.map((c) => (
              <NewContact
                key={c.tempId}
                detail={c}
                canDeselectNew={props.canDeselectNew}
                radioSelector={props.radioSelector}
                selectEmailOnly={props.selectEmailOnly}
                emailDomainNotAllowedTextError={
                  props.emailDomainNotAllowedTextError
                }
                hideCheckbox={props.hideCheckboxes}
                disableDelete={
                  props.disableDeleteWhenSingleNewContact &&
                  props.newContacts.length === 1
                }
                onSelect={props.onSelectNew}
                onUpdate={(tempId, details) =>
                  props.updateNewContact(tempId, details)
                }
                onDelete={(tempId) => props.deleteNewContact(tempId)}
              />
            ))}
            {(!props.limitNewContacts || props.newContacts.length < 1) && (
              <Button tertiary onClick={props.addNewContact}>
                + Add a recipient
              </Button>
            )}
          </div>
        </div>
      </div>
    </ModalForm>
  );
};

interface SelectableUserProps {
  user: existingUser;

  canDeselectExisting?: boolean;
  radioSelector?: boolean;

  onSelect?: (val: boolean) => void;
}

export const SelectableUser: FC<SelectableUserProps> = ({
  user,
  canDeselectExisting,
  radioSelector,
  onSelect,
}) => {
  const userNameTrimmed = user.name ? user.name.trim() : "";

  const isSelectionDisabled =
    (user.alreadyAdded && !canDeselectExisting) ||
    user.isSelectionDisabled ||
    user.isChecking ||
    !onSelect;

  const classes = classnames("selectable-user", {
    "selection-disabled": isSelectionDisabled,
  });

  return (
    <div
      key={user.email}
      className={classes}
      onClick={
        !isSelectionDisabled && onSelect
          ? () => onSelect(!user.selected)
          : undefined
      }
    >
      {onSelect && (
        <ColorCheckbox
          checked={user.selected}
          disabled={isSelectionDisabled}
          helpPopup={
            user.isSelectionDisabled &&
            !user.isChecking &&
            user.selectionDisabledMessage
              ? user.selectionDisabledMessage
              : undefined
          }
          color={LabelColor.Blue}
          radio={radioSelector}
        />
      )}
      <UserAvatar avatar={getGravatarURL(user.email)} />
      <div className="name-and-email">
        {userNameTrimmed && (
          <>
            <span>{userNameTrimmed}</span>
            <br />
          </>
        )}
        {user.email}
      </div>
      {user.isChecking && <LoadingIcon size={24} />}
    </div>
  );
};

interface NewContactProps {
  detail: newContact;

  canDeselectNew?: boolean;
  radioSelector?: boolean;
  selectEmailOnly?: boolean;
  emailDomainNotAllowedTextError?: string;
  hideCheckbox?: boolean;
  disableDelete?: boolean;

  onSelect?: (tempId: string, val: boolean) => void;
  onUpdate: (tempId: string, detail: newContact) => void;
  onDelete: (tempId: string) => void;
}

export const NewContact: FC<NewContactProps> = ({
  detail,
  canDeselectNew,
  radioSelector,
  selectEmailOnly,
  emailDomainNotAllowedTextError,
  hideCheckbox,
  disableDelete,
  onSelect,
  onUpdate,
  onDelete,
}) => {
  const isSelectionDisabled =
    !canDeselectNew || detail.isEmailDomainNotAllowed || detail.isChecking;

  return (
    <div key={detail.tempId} className="new-contact-form">
      {!hideCheckbox && (
        <ColorCheckbox
          checked={!canDeselectNew || detail.selected}
          disabled={isSelectionDisabled}
          radio={radioSelector}
          onClick={() => onSelect && onSelect(detail.tempId, true)}
        />
      )}

      <div className="form-contents">
        {!selectEmailOnly && (
          <>
            <TextField
              type="text"
              className="half-input"
              value={detail.firstName ?? ""}
              required
              placeholder="First name"
              onChanged={(val) =>
                onUpdate(detail.tempId, {
                  ...detail,
                  firstName: val,
                })
              }
            />
            <TextField
              type="text"
              className="half-input"
              value={detail.lastName ?? ""}
              required
              placeholder="Last name"
              onChanged={(val) =>
                onUpdate(detail.tempId, {
                  ...detail,
                  lastName: val,
                })
              }
            />
            <TextField
              type="text"
              placeholder="Title (optional)"
              value={detail.title ?? ""}
              onChanged={(val) =>
                onUpdate(detail.tempId, {
                  ...detail,
                  title: val,
                })
              }
            />
          </>
        )}
        <TextField
          type="email"
          value={detail.email}
          placeholder="Email address"
          required
          onChanged={(val) =>
            onUpdate(detail.tempId, {
              ...detail,
              email: val,
            })
          }
          errorTexts={
            detail.isEmailDomainNotAllowed && emailDomainNotAllowedTextError
              ? [emailDomainNotAllowedTextError]
              : undefined
          }
        />
      </div>
      <div className={"controls"}>
        <IconButton
          icon={<span className="cr-icon-trash" />}
          disabled={disableDelete}
          onClick={() => onDelete(detail.tempId)}
        />
        {detail.isChecking && <LoadingIcon size={24} />}
      </div>
    </div>
  );
};

export default ContactSelector;
