import { useEffect, useState } from "react";
import ModalV2 from "../../../_common/components/ModalV2";
import Button from "../../../_common/components/core/Button";
import { Steps } from "../../../_common/components/StepsWithSections";
import { IVendorContactResponse } from "../../../_common/types/vendorContact";

import { ISingleVendorData } from "../../../_common/types/redux";
import {
  createVendorContact,
  createVendorSurvey,
  fetchResendableSurveysForVendor,
  fetchSurveyTypes,
} from "../../reducers/cyberRiskActions";
import ColorCheckbox from "../ColorCheckbox";
import "../../style/components/SendSurveyModal.scss";
import {
  surveyType,
  SurveyUsageType,
} from "../../../_common/types/surveyTypes";
import { SelectV2 } from "../../../_common/components/SelectV2";
import {
  ISurveyMini,
  SurveyRiskVisibility,
} from "../../../_common/types/survey";
import { formatDateAsLocal } from "../../../_common/helpers";
import TextField from "../../../_common/components/TextField";
import DatePicker from "../../../_common/components/DatePicker";
import moment from "moment";
import ContactSelector, {
  existingUser,
  newContact,
  useNewRecipients,
  validateContactSelector,
} from "../ContactSelector";
import { trackEvent } from "../../../_common/tracking";
import {
  addDefaultSuccessAlert,
  addDefaultUnknownErrorAlert,
} from "../../../_common/reducers/messageAlerts.actions";
import { getSurveyEditorPath } from "../../../_common/views/SurveyDetails";
import classNames from "classnames";
import { createPath, History } from "history";
import { Link } from "react-router-dom";
import { reopenSurvey } from "../../reducers/survey.actions";
import { fetchVendorMgtContacts } from "../../reducers/vendorContacts.actions";
import { TitleCharLimit } from "../../views/CreateQuestionnaireBulk";
import {
  DefaultTextType,
  OrgDefaultTexts,
  replaceVariablesInDefaultTexts,
} from "../../../_common/types/orgDefaultTexts";
import { fetchOrganisationDefaultTexts } from "../../reducers/orgDefaultTexts.actions";
import QuestionnaireRiskVisibilitySelector, {
  getMessageForQuestionnaireRiskVisibilityInfoBanner,
} from "../surveys/QuestionnaireRiskVisibilitySelector";
import RichTextEditV2 from "../../../_common/components/RichTextEditV2";
import { appConnect, useAppDispatch } from "../../../_common/types/reduxHooks";
import { uniqBy as _uniqBy } from "lodash";

interface ISelectSurveyDueDateProps {
  dueDate?: string;
  setDueDate: (date: string | undefined) => void;
  reminderDate?: string;
  setReminderDate: (date: string | undefined) => void;
  resendDate?: string;
  setResendDate: (date: string | undefined) => void;
}

export const SelectSurveyDueDateStep = (props: ISelectSurveyDueDateProps) => {
  const today = moment();
  const todaysDate = today.format("YYYY-MM-DD");

  return (
    <div className={"modal-section-box survey-due-date"}>
      <div className={"due-date-label"}>
        <h3>Set a due date</h3>
        <p>Set a date you would like this questionnaire to be completed by.</p>
      </div>
      <DatePicker
        onChange={(e) => props.setDueDate(e.target.value)}
        value={props.dueDate}
        placeholder={"Select due date"}
        min={todaysDate}
      />
      <ColorCheckbox
        checked={props.reminderDate != undefined}
        onClick={() =>
          props.setReminderDate(
            props.reminderDate != undefined ? undefined : ""
          )
        }
        label={
          <>
            <h3>Add a reminder</h3>
            <p>
              If you choose to set a reminder, we’ll remind the recipients of
              your request on the specified date.
            </p>
          </>
        }
      />
      {props.reminderDate != undefined && (
        <DatePicker
          onChange={(e) => props.setReminderDate(e.target.value)}
          value={props.reminderDate}
          placeholder={"Select reminder date"}
          min={todaysDate}
          className="date-picker-indent"
        />
      )}
      <ColorCheckbox
        checked={props.resendDate != undefined}
        onClick={() =>
          props.setResendDate(props.resendDate != undefined ? undefined : "")
        }
        label={
          <>
            <h3>Resend questionnaire</h3>
            <p>
              Do you want a reminder to resend this questionnaire at a later
              date?
            </p>
          </>
        }
      />
      {props.resendDate != undefined && (
        <DatePicker
          onChange={(e) => props.setResendDate(e.target.value)}
          value={props.resendDate}
          placeholder={"Select resend date"}
          min={todaysDate}
          className="date-picker-indent"
        />
      )}
    </div>
  );
};

interface ISelectSurveyRecipientsProps {
  existingUsers: existingUser[];
  newContacts: newContact[];
  setExistingUserSelected: (email: string, selected: boolean) => void;
  addNewContact: () => void;
  updateNewContact: (tempId: string, fields: Partial<newContact>) => void;
  deleteNewContact: (tempId: string) => void;
  title: string;
  setTitle: (title: string) => void;
  message: string;
  setMessage: (title: string) => void;
}

export const SelectSurveyRecipientStep = (
  props: ISelectSurveyRecipientsProps
) => {
  return (
    <div className={"select-survey-recipients"}>
      <div className={"modal-section-box recipients-box"}>
        <h3>Who do you want to send this to?</h3>
        <ContactSelector
          entityName={"Questionnaire"}
          existingUsers={props.existingUsers}
          newContacts={props.newContacts}
          setExistingUserSelected={props.setExistingUserSelected}
          addNewContact={props.addNewContact}
          updateNewContact={props.updateNewContact}
          deleteNewContact={props.deleteNewContact}
          canDeselectExisting
          selectEmailOnly={false}
          hideDescription
        />
      </div>
      <div className={"modal-section-box title-box"}>
        <h3>Add a title</h3>
        <TextField
          value={props.title}
          onChanged={(val) => props.setTitle(val)}
          minLength={3}
          maxLength={TitleCharLimit}
          placeholder={"Add title"}
        />
        <h3>Add a message</h3>
        <RichTextEditV2
          value={props.message}
          onChange={(v) => props.setMessage(v)}
        />
      </div>
    </div>
  );
};

export type sendSurveyMode = "resend" | "new";

interface ISelectSurveyStepProps {
  surveyMode: sendSurveyMode;
  setSurveyMode?: (mode: sendSurveyMode) => void;
  surveyTypes: surveyType[];
  selectedSurveyType: number;
  setSelectedSurveyType: (idx: number) => void;
  selectedSurveySections?: boolean[];
  toggleSelectedSurveySections?: (idx: number) => void;
  allSurveySectionsSelected?: boolean;
  toggleAllSurveySectionsSelected?: () => void;
  isManagementAnalystSession: boolean;
  managedOrgId?: number;
  resendableSurveys?: ISurveyMini[];
  selectedResendableSurvey?: number;
  setSelectedResenableSurvey?: (idx: number) => void;
  history: History;
  isMulti?: boolean;
  riskVisibility: SurveyRiskVisibility;
  setRiskVisibility: (newValue: SurveyRiskVisibility) => void;
  analystWorkflowQuestionnaireOnly?: boolean;
}

export const SelectSurveyStep = (props: ISelectSurveyStepProps) => {
  const selectedSurveyType =
    props.selectedSurveyType > -1
      ? props.surveyTypes[props.selectedSurveyType]
      : undefined;

  // const selectedResenableSurvey =
  //   props.selectedResendableSurvey > -1
  //     ? props.resendableSurveys[props.selectedResendableSurvey]
  //     : undefined;

  const tprmURLPrefix = props.isManagementAnalystSession
    ? `/analysts/tpvm/${props.managedOrgId}/0/` // Our TPRM URLs expect a vendor ID so we're just passing 0 here as a workaround
    : "";

  const getPreviewLink = (surveyTypeId: number) => {
    return `${tprmURLPrefix}/questionnairepreview?type_id=${surveyTypeId}&risk_visibility=${props.riskVisibility}`;
  };

  const getSectionPreviewLink = (sectionId: number, sectionName: string) => {
    return `${tprmURLPrefix}/questionnairepreview?section_id=${sectionId}&section_name=${sectionName}&risk_visibility=${props.riskVisibility}`;
  };

  return (
    <div className={"select-survey-step"}>
      {props.setSurveyMode && !props.analystWorkflowQuestionnaireOnly && (
        <div className={"modal-section-box survey-type-select"}>
          <h3>Do you want to send a new questionnaire?</h3>
          <ColorCheckbox
            onClick={() => props.setSurveyMode && props.setSurveyMode("resend")}
            checked={props.surveyMode === "resend"}
            radio
            label={"Send a questionnaire I’ve sent before"}
            disabled={!!props.isMulti}
            helpPopup={
              props.isMulti
                ? "Questionnaires cannot be resent when multiple vendors are selected"
                : undefined
            }
          />
          <ColorCheckbox
            onClick={() => props.setSurveyMode && props.setSurveyMode("new")}
            checked={props.surveyMode === "new"}
            radio
            label={"Send a new blank questionnaire"}
          />
        </div>
      )}
      {props.surveyMode === "new" && (
        <div className={"modal-section-box new-survey-select"}>
          <h3>What type of questionnaire would you like to send?</h3>
          <p>
            Select from our list of questionnaires below. Preview, manage, and
            create questionnaire types via the{" "}
            <Link to={"/surveybuilder/library"}>
              <Button link>questionnaire library</Button>
            </Link>
            .
          </p>
          <SelectV2
            placeholder={"Select a questionnaire"}
            options={props.surveyTypes
              .filter((s) => s.selected)
              .map((s) => ({
                value: s.id,
                label: s.overrideName ?? s.name,
              }))}
            value={
              selectedSurveyType
                ? {
                    value: selectedSurveyType.id,
                    label:
                      selectedSurveyType.overrideName ??
                      selectedSurveyType.name,
                  }
                : undefined
            }
            onChange={(value) =>
              props.setSelectedSurveyType(
                props.surveyTypes.findIndex((s) => s.id === value?.value)
              )
            }
          />
          {selectedSurveyType && (
            <div
              className={classNames("modal-section-box", "survey-sections", {
                ["no-sections"]: selectedSurveyType.sections.length === 1,
              })}
            >
              <div className={"name-preview"}>
                <h3>
                  {selectedSurveyType.overrideName ?? selectedSurveyType.name}
                </h3>
                {selectedSurveyType.sections.length === 1 && (
                  <Button
                    tertiary
                    onClick={() =>
                      window.open(
                        getPreviewLink(selectedSurveyType.id),
                        "_blank"
                      )
                    }
                  >
                    Preview
                  </Button>
                )}
              </div>
              {selectedSurveyType.description !== "" && (
                <p>{selectedSurveyType.description}</p>
              )}
              {selectedSurveyType.sections.length > 1 && (
                <>
                  <ColorCheckbox
                    onClick={props.toggleAllSurveySectionsSelected}
                    checked={props.allSurveySectionsSelected}
                    label={
                      <div className={"survey-section"}>
                        <h3>All sections</h3>
                        <Button
                          tertiary
                          onClick={(evt) => {
                            evt.stopPropagation();
                            window.open(
                              getPreviewLink(selectedSurveyType.id),
                              "_blank"
                            );
                          }}
                        >
                          Preview
                        </Button>
                      </div>
                    }
                  />
                  <div className={"horz-line"} />
                  {selectedSurveyType.sections.map((s, i) => (
                    <ColorCheckbox
                      key={s.id}
                      onClick={() =>
                        props.toggleSelectedSurveySections
                          ? props.toggleSelectedSurveySections(i)
                          : undefined
                      }
                      checked={
                        (props.selectedSurveySections &&
                          props.selectedSurveySections[i]) ||
                        props.allSurveySectionsSelected
                      }
                      label={
                        <div className={"survey-section"}>
                          <h3>{s.displayName}</h3>
                          <Button
                            onClick={(evt) => {
                              evt.stopPropagation();
                              window.open(
                                getSectionPreviewLink(s.id, s.name),
                                "_blank"
                              );
                            }}
                            tertiary
                          >
                            Preview
                          </Button>
                        </div>
                      }
                      disabled={props.allSurveySectionsSelected}
                    />
                  ))}
                </>
              )}
            </div>
          )}
        </div>
      )}
      {props.surveyMode === "new" && (
        <div className={"modal-section-box risk-visibility-select"}>
          <h3>Risk information visibility</h3>
          <QuestionnaireRiskVisibilitySelector
            value={props.riskVisibility}
            onChange={props.setRiskVisibility}
          />
          <br />
          {getMessageForQuestionnaireRiskVisibilityInfoBanner(
            props.riskVisibility
          )}
        </div>
      )}
      {props.surveyMode === "resend" && (
        <div className={"modal-section-box resend-survey-select"}>
          <h3>Select existing questionnaire</h3>
          <p>
            {props.resendableSurveys?.length
              ? "These are the questionnaires you have sent previously."
              : "There are no Completed or Cancelled questionnaires which would be eligible for resending to this vendor."}
          </p>
          {props.resendableSurveys?.map((r, i) => (
            <ColorCheckbox
              key={r.id}
              radio
              checked={props.selectedResendableSurvey === i}
              onClick={() =>
                props.setSelectedResenableSurvey
                  ? props.setSelectedResenableSurvey(i)
                  : undefined
              }
              label={
                <div className={"resendable-survey"}>
                  <div className={"name-label"}>
                    <h3 className={"resend-name"} title={r.name}>
                      {r.name}
                    </h3>
                    <p>{`Sent on ${formatDateAsLocal(
                      r.dateCompleted ?? r.dateCreated
                    )}`}</p>
                  </div>
                  <Button
                    onClick={(e) => {
                      e.stopPropagation();
                      const surveyPath = getSurveyEditorPath({
                        surveyId: r.id,
                        editMode: false,
                        isManagementAnalystSession:
                          props.isManagementAnalystSession,
                        managedOrgId: props.managedOrgId,
                        location: props.history.location,
                      });

                      // We want to force this to open in a new tab so much convert the LocationDescriptorObject
                      // to a string path.
                      window.open(createPath(surveyPath), "_blank");
                    }}
                    tertiary
                  >
                    View questionnaire
                  </Button>
                </div>
              }
            />
          ))}
        </div>
      )}
    </div>
  );
};

interface ISendSurveyModalOwnProps {
  active: boolean;
  onClose: () => void;
  isManagementAnalystSession: boolean;
  managedOrgId?: number;
  vendorId: number;
  vendorName: string;
  refreshData: (surveyId: number) => Promise<unknown>;
  history: History;
}

interface ISendSurveyModalConnectedProps {
  vendorContacts: IVendorContactResponse[];
  vendorContactsLoading: boolean;
  surveyTypes: surveyType[];
  resendableSurveys: ISurveyMini[];
  orgName: string;
  orgDefaultTexts: OrgDefaultTexts;
  surveyRiskVisibility?: SurveyRiskVisibility;
}

type ISendSurveyModalProps = ISendSurveyModalOwnProps &
  ISendSurveyModalConnectedProps;

const SendSurveyModal = (props: ISendSurveyModalProps) => {
  // fetch data
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(fetchVendorMgtContacts(props.vendorId));
    dispatch(fetchResendableSurveysForVendor(props.vendorId, false));
    dispatch(fetchSurveyTypes("external", false));
    dispatch(
      fetchOrganisationDefaultTexts(
        false,
        props.isManagementAnalystSession,
        props.managedOrgId
      )
    );
  }, [
    dispatch,
    props.isManagementAnalystSession,
    props.managedOrgId,
    props.vendorId,
  ]);

  const [currentStep, setCurrentStep] = useState(1);
  const [doingSend, setDoingSend] = useState(false);
  const [surveyMode, setSurveyMode] = useState<sendSurveyMode>("new");

  const [selectedSurveyTypeIdx, setSelectedSurveyTypeIdx] = useState(-1);
  const [selectedSurveySections, setSelectedSurveySections] = useState(
    [] as boolean[]
  );
  const [allSurveySectionsSelected, setAllSurveySectionsSelected] =
    useState(false);
  const [selectedResendableSurveyIdx, setSelectedResendableSurveyIdx] =
    useState(-1);

  const [selectedRiskVisibility, setSelectedRiskVisibility] = useState(
    props.surveyRiskVisibility
  );
  useEffect(() => {
    if (!!props.surveyRiskVisibility && !selectedRiskVisibility) {
      setSelectedRiskVisibility(props.surveyRiskVisibility);
    }
  }, [selectedRiskVisibility, props.surveyRiskVisibility]);

  const surveyTypes = props.surveyTypes.filter(
    (st) => st.usageType === SurveyUsageType.Security
  );

  const selectSurveyMode = (mode: sendSurveyMode) => {
    if (mode === "resend") {
      setSelectedSurveySections([]);
      setAllSurveySectionsSelected(false);
      setSelectedSurveyTypeIdx(-1);
    } else {
      setSelectedResendableSurveyIdx(-1);
    }
    setSurveyMode(mode);
  };

  const selectNewSurvey = (idx: number) => {
    if (idx > -1 && idx < surveyTypes.length) {
      setSelectedSurveySections(surveyTypes[idx].sections.map(() => false));
      setAllSurveySectionsSelected(true);
      setSelectedSurveyTypeIdx(idx);
    } else if (idx === -1) {
      setAllSurveySectionsSelected(false);
      setSelectedSurveyTypeIdx(idx);
    }
  };

  const [
    newRecipients,
    addNewRecipient,
    deleteNewRecipient,
    updateNewRecipient,
    clearNewRecipients,
  ] = useNewRecipients();
  const [existingUsers, setExistingUsers] = useState([] as existingUser[]);
  useEffect(() => {
    // when vendor contacts are loaded turn then into existing users
    if (props.vendorContacts.length > 0) {
      setExistingUsers(
        _uniqBy(props.vendorContacts, (c) => c.emailAddress).map((c) => ({
          name: c.name ?? "",
          email: c.emailAddress ?? "",
          alreadyAdded: false,
          isInvite: false,
          selected: false,
        }))
      );
    }
  }, [props.vendorContacts]);
  const setExistingUserSelected = (email: string, selected: boolean) =>
    setExistingUsers((eUsers) => {
      const users = [...eUsers];
      const idx = users.findIndex((u) => u.email === email);
      if (idx !== -1) {
        users[idx].selected = selected;
      }
      return users;
    });

  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");
  // if there are default texts prefill them
  useEffect(() => {
    if (selectedSurveyTypeIdx === -1 && selectedResendableSurveyIdx === -1) {
      return;
    }

    let defaultTitle =
      props.orgDefaultTexts[DefaultTextType.QuestionnaireTitle]?.defaultText ||
      "";
    let defaultEmailMessage =
      props.orgDefaultTexts[DefaultTextType.QuestionnaireMessage]
        ?.defaultText || "";

    let surveyName = "";
    if (surveyTypes.length > 0 && selectedSurveyTypeIdx > -1) {
      surveyName =
        surveyTypes[selectedSurveyTypeIdx].overrideName ??
        surveyTypes[selectedSurveyTypeIdx].name;
    } else if (
      props.resendableSurveys.length > 0 &&
      selectedResendableSurveyIdx > -1
    ) {
      surveyName = props.resendableSurveys[selectedResendableSurveyIdx].name;
    }

    [defaultTitle, defaultEmailMessage] = replaceVariablesInDefaultTexts(
      [defaultTitle, defaultEmailMessage],
      {
        "{{VendorName}}": props.vendorName,
        "{{AccountName}}": props.orgName,
        "{{QuestionnaireType}}": surveyName,
      }
    );

    setTitle(defaultTitle);
    setMessage(defaultEmailMessage);
  }, [
    dispatch,
    props.orgDefaultTexts,
    selectedResendableSurveyIdx,
    selectedSurveyTypeIdx,
    props.surveyTypes,
    props.resendableSurveys,
  ]);

  const [dueDate, setDueDate] = useState<string | undefined>(undefined);
  const [reminderDate, setReminderDate] = useState<string | undefined>(
    undefined
  );
  const [resendDate, setResendDate] = useState<string | undefined>(undefined);

  const doClose = () => {
    // reset state and close
    setCurrentStep(1);
    setSurveyMode("new");
    setSelectedSurveyTypeIdx(-1);
    setSelectedSurveySections([]);
    setAllSurveySectionsSelected(false);
    setDueDate(undefined);
    setReminderDate(undefined);
    setResendDate(undefined);
    clearNewRecipients();
    existingUsers.forEach((e) => setExistingUserSelected(e.email, false));
    props.onClose();
  };

  const doSend = async () => {
    setDoingSend(true);
    // first if we have any new contacts lets add them
    let prom: Promise<any> = Promise.resolve();
    if (newRecipients.length > 0) {
      prom = prom.then(() =>
        Promise.all(
          newRecipients.map((c) =>
            dispatch(
              createVendorContact(props.vendorId, {
                name: c.firstName + " " + c.lastName,
                title: c.title,
                emailAddress: c.email,
              })
            )
          )
        ).then(() => {
          dispatch(fetchVendorMgtContacts(props.vendorId, true));
        })
      );
    }

    const toEmails = [
      ...newRecipients.map((r) => r.email),
      ...existingUsers.filter((e) => e.selected).map((e) => e.email),
    ];
    const toFirstNames = [
      ...newRecipients.map((r) => r.firstName),
      ...existingUsers
        .filter((e) => e.selected)
        .map((e) => e.name.split(" ")[0]),
    ];

    if (surveyMode === "new") {
      const selectedSurvey = surveyTypes[selectedSurveyTypeIdx];
      const sections = selectedSurvey.sections
        .filter(
          (_s, i) => allSurveySectionsSelected || selectedSurveySections[i]
        )
        .map((s) => s.id);
      prom = prom.then(() =>
        dispatch(
          createVendorSurvey(
            title,
            props.vendorId,
            toEmails,
            toFirstNames,
            message,
            selectedSurvey.id,
            sections,
            dueDate,
            0,
            resendDate,
            reminderDate,
            props.isManagementAnalystSession,
            props.managedOrgId,
            selectedRiskVisibility
          )
        )
      );
    } else {
      const selectedSurvey =
        props.resendableSurveys[selectedResendableSurveyIdx];
      prom = prom.then(() =>
        dispatch(
          reopenSurvey(
            selectedSurvey.id,
            message,
            true,
            toEmails,
            toFirstNames,
            dueDate,
            reminderDate
          )
        )
      );
    }

    try {
      let surveyName = "";
      if (surveyTypes.length > 0 && selectedSurveyTypeIdx > -1) {
        surveyName =
          surveyTypes[selectedSurveyTypeIdx].overrideName ??
          surveyTypes[selectedSurveyTypeIdx].name;
      } else if (
        props.resendableSurveys.length > 0 &&
        selectedResendableSurveyIdx > -1
      ) {
        surveyName = props.resendableSurveys[selectedResendableSurveyIdx].type;
      }

      await prom
        .then(
          (questionnaireId) => {
            trackEvent("Send Questionnaire Modal - completed", {
              reopened: surveyMode === "resend",
              questionnaireId,
              vendorId: props.vendorId,
              questionaireType: surveyName,
            });
            props.refreshData(questionnaireId);
          }

          // TODO - it should also add it to the vendor assessment as evidence
        )
        .then(() => {
          dispatch(
            addDefaultSuccessAlert(
              `The questionnaire "${title}" has been sent to ${props.vendorName}`
            )
          );
          setDoingSend(false);
          doClose();
        });
    } catch (e) {
      console.error(e);
      setDoingSend(false);
      dispatch(addDefaultUnknownErrorAlert("Error sending questionnaire"));
    }
  };

  const canGoNext = (): boolean => {
    switch (currentStep) {
      case 1:
        return (
          (surveyMode === "new" && selectedSurveyTypeIdx >= 0) ||
          (surveyMode === "resend" && selectedResendableSurveyIdx >= 0)
        );
      case 2:
        return (
          validateContactSelector(existingUsers, newRecipients, false) &&
          title.length > 3
        );
      case 3:
        return (
          !!dueDate &&
          (reminderDate == undefined || !!reminderDate) &&
          (resendDate == undefined || !!resendDate)
        );
      default:
        return true;
    }
  };

  return (
    <ModalV2
      className={"send-survey-modal"}
      active={props.active}
      onClose={doClose}
      disallowClose={doingSend}
      headerClassName={"send-survey-modal-header"}
      headerContent={
        <>
          <h2>{`Send ${props.vendorName} a questionnaire`}</h2>
          <p>
            Security questionnaires are designed to help you identify potential
            weaknesses in vendors that aren’t accessible through automated
            scanning.
          </p>
        </>
      }
      footerClassName={"send-survey-modal-footer"}
      footerContent={
        <>
          {currentStep != 1 && (
            <Button
              className={"left-button back-button"}
              disabled={doingSend}
              leftArrow
              onClick={() => setCurrentStep(currentStep - 1)}
            >
              Previous
            </Button>
          )}
          <Button tertiary disabled={doingSend} onClick={doClose}>
            Cancel
          </Button>
          <Button
            filledPrimary
            arrow={currentStep !== 3}
            onClick={() =>
              currentStep !== 3 ? setCurrentStep(currentStep + 1) : doSend()
            }
            loading={doingSend}
            disabled={!canGoNext()}
          >
            {currentStep === 3 ? "Send questionnaire " : "Next "}
          </Button>
        </>
      }
    >
      <Steps
        steps={[
          {
            id: "questionnaire",
            text: "Questionnaire",
          },
          {
            id: "recipients",
            text: "Recipients",
          },
          {
            id: "dates",
            text: "Due dates",
          },
        ]}
        currentStep={currentStep}
      />
      {currentStep === 1 && (
        <SelectSurveyStep
          isManagementAnalystSession={props.isManagementAnalystSession}
          managedOrgId={props.managedOrgId}
          surveyMode={surveyMode}
          setSurveyMode={selectSurveyMode}
          surveyTypes={surveyTypes}
          selectedSurveyType={selectedSurveyTypeIdx}
          setSelectedSurveyType={selectNewSurvey}
          selectedSurveySections={selectedSurveySections}
          allSurveySectionsSelected={allSurveySectionsSelected}
          toggleAllSurveySectionsSelected={() =>
            setAllSurveySectionsSelected((s) => !s)
          }
          toggleSelectedSurveySections={(idx) =>
            setSelectedSurveySections((s) => {
              const result = [...s];
              result[idx] = !result[idx];
              return result;
            })
          }
          resendableSurveys={props.resendableSurveys}
          selectedResendableSurvey={selectedResendableSurveyIdx}
          setSelectedResenableSurvey={setSelectedResendableSurveyIdx}
          history={props.history}
          riskVisibility={
            selectedRiskVisibility ?? SurveyRiskVisibility.Visible
          }
          setRiskVisibility={setSelectedRiskVisibility}
        />
      )}
      {currentStep === 2 && (
        <SelectSurveyRecipientStep
          deleteNewContact={deleteNewRecipient}
          addNewContact={addNewRecipient}
          setExistingUserSelected={setExistingUserSelected}
          updateNewContact={updateNewRecipient}
          existingUsers={existingUsers}
          newContacts={newRecipients}
          title={title}
          setTitle={setTitle}
          message={message}
          setMessage={setMessage}
        />
      )}
      {currentStep === 3 && (
        <SelectSurveyDueDateStep
          dueDate={dueDate}
          setDueDate={setDueDate}
          reminderDate={reminderDate}
          setReminderDate={setReminderDate}
          resendDate={resendDate}
          setResendDate={setResendDate}
        />
      )}
    </ModalV2>
  );
};

export default appConnect<
  ISendSurveyModalConnectedProps,
  unknown,
  ISendSurveyModalOwnProps
>((state, props) => {
  let vendor: ISingleVendorData | undefined;
  let surveyTypes: surveyType[];
  let surveyRiskVisibility: SurveyRiskVisibility | undefined;

  let orgName;
  let orgDefaultTexts;
  if (props.isManagementAnalystSession && props.managedOrgId) {
    vendor =
      state.cyberRisk.managedVendorData[props.managedOrgId][props.vendorId];
    surveyTypes =
      state.cyberRisk.managedVendorData[props.managedOrgId]?.surveyTypes
        ?.external ?? [];
    orgName = state.cyberRisk.managedVendorData[props.managedOrgId]?.name ?? "";
    orgDefaultTexts =
      state.cyberRisk.managedVendorData[props.managedOrgId]?.orgDefaultTexts ??
      {};
    surveyRiskVisibility =
      state.cyberRisk.managedVendorData[props.managedOrgId]
        .surveyRiskVisibility;
  } else {
    vendor = state.cyberRisk.vendors[props.vendorId];
    surveyTypes = state.cyberRisk.customerData.surveyTypes?.external ?? [];
    orgName =
      state.common.userData.orgList?.find(
        (o) => o.id === state.common.userData.currentOrgID
      )?.name ?? "";
    orgDefaultTexts = state.cyberRisk.orgDefaultTexts ?? {};
    surveyRiskVisibility = state.cyberRisk.customerData.surveyRiskVisibility;
  }

  return {
    vendorContacts: vendor?.mgtlists?.contacts?.result ?? [],
    vendorContactsLoading: vendor?.mgtlists?.contacts?.loading ?? true,
    surveyTypes,
    resendableSurveys: vendor?.resendableSurveys ?? [],
    orgDefaultTexts,
    orgName,
    surveyRiskVisibility,
  };
})(SendSurveyModal);
