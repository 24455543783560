import { Component } from "react";
import PropTypes from "prop-types";

import Button from "../../_common/components/core/Button";
import { closeModal } from "../../_common/reducers/commonActions";
import {
  shareSurveyWithUser,
  resendShareInviteForSurvey,
} from "../../_common/reducers/surveyDetails.actions";
import {
  addDefaultSuccessAlert,
  addDefaultUnknownErrorAlert,
  addSimpleErrorAlert,
} from "../../_common/reducers/messageAlerts.actions";
import { handleKnownErrors } from "../../vendorrisk/reducers/errors.actions";

export const ShareAssessmentModalName = "ShareAssessmentModal";

/**
 * <ShareAssessmentModal />
 * Modal form popped up from the vendor portal to allow a user to share an assessment with a colleague.
 * Uses the invitation services to create a new invite for the nominated email address and send the invitation email.
 */
class ShareAssessmentModal extends Component {
  static propTypes = {
    modalData: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = { loading: false };
  }

  onSubmit = (evt) => {
    evt.preventDefault();
    const firstName = this.name.value;
    const email = this.email.value;
    const message = this.message.value;

    if (
      this.props.modalData.sharedWith &&
      (this.props.modalData.sendToEmailAddress == null ||
        this.props.modalData.sendToEmailAddress == "")
    ) {
      let duplicateEmail = "";
      for (let i = 0; i < this.props.modalData.sharedWith.length; i++) {
        if (this.props.modalData.sharedWith[i].email.trim() === email.trim()) {
          duplicateEmail = `This questionnaire is already shared with ${email}`;
          break;
        }
      }
      for (let i = 0; i < this.props.modalData.openInvites.length; i++) {
        if (
          this.props.modalData.openInvites[i].recipientEmail.trim() ===
          email.trim()
        ) {
          duplicateEmail = `User ${email} has already been invited to collaborate on this questionnaire.`;
          break;
        }
      }
      if (
        this.props.modalData.user &&
        this.props.modalData.user.email.trim() === email.trim()
      ) {
        duplicateEmail = `User ${email} has already been invited to collaborate on this questionnaire.`;
      }
      if (duplicateEmail !== "") {
        this.props.dispatch(addSimpleErrorAlert(duplicateEmail));
        return;
      }
    }

    this.setState({ loading: true });

    const { surveyID } = this.props.modalData;
    const companyName = this.props.modalData.fromCompany;

    // is this a resend to a known email address?
    if (
      this.props.modalData.sendToEmailAddress != null &&
      this.props.modalData.sendToEmailAddress !== ""
    ) {
      this.props
        .dispatch(
          resendShareInviteForSurvey(surveyID, email, message, companyName)
        )
        .then(() => {
          this.props.dispatch(closeModal());
          this.props.dispatch(
            addDefaultSuccessAlert(
              `The invitation to collaborate on the vendor questionnaire from ${this.props.modalData.fromCompany} has been re-sent to user ${email}`
            )
          );
        })
        .catch((err) => {
          this.setState({ loading: false });
          if (!this.props.dispatch(handleKnownErrors(err))) {
            this.props.dispatch(addDefaultUnknownErrorAlert(err.message));
          }
        })
        .then(() => {
          this.setState({ loading: false });
        });
    } else {
      this.props
        .dispatch(
          shareSurveyWithUser(surveyID, email, firstName, message, companyName)
        )
        .then(() => {
          this.props.dispatch(closeModal());
          this.props.dispatch(
            addDefaultSuccessAlert(
              `An invitation to collaborate on the vendor questionnaire from ${this.props.modalData.fromCompany} has been sent to user ${email}`
            )
          );
        })
        .catch((err) => {
          this.setState({ loading: false });
          if (!this.props.dispatch(handleKnownErrors(err))) {
            this.props.dispatch(
              addDefaultUnknownErrorAlert("Error sharing the questionnaire")
            );
          }
        })
        .then(() => {
          this.setState({ loading: false });
        });
    }
  };

  render() {
    let title = "Share Vendor Questionnaire";
    let paragraph = (
      <p>
        Share this questionnaire, requested by{" "}
        {this.props.modalData.fromCompany}, with a collaborating CyberRisk user.
        Enter the email address of the user, and optionally a brief message.
        They will receive an email allowing them to register with CyberRisk (if
        they haven&apos;t already), and requesting that they contribute to the
        questionnaire responses.
      </p>
    );

    if (this.props.modalData.isResendInvite) {
      title = "Resend Collaboration Email";
      paragraph = (
        <p>
          Re-send the email requesting collaboration on this questionnaire,
          (sent by {this.props.modalData.fromCompany}), A new email will be
          sent, allowing them to register with CyberRisk (if they haven&apos;t
          already), and requesting that they contribute to the questionnaire
          responses.
        </p>
      );
    }
    return (
      <div className="modal-content">
        <h2>{title}</h2>
        {paragraph}
        <form onSubmit={this.onSubmit}>
          {!this.props.modalData.sendToEmailAddress && (
            <>
              <label htmlFor="name">First name</label>
              <input
                type="text"
                name="name"
                placeholder="First name of recipient"
                ref={(ref) => (this.name = ref)}
              />
            </>
          )}
          <label htmlFor="email">Email</label>
          <input
            type="email"
            name="email"
            placeholder="Email of recipient"
            required
            ref={(ref) => (this.email = ref)}
            disabled={
              this.props.modalData.sendToEmailAddress != null &&
              this.props.modalData.sendToEmailAddress !== ""
            }
            value={this.props.modalData.sendToEmailAddress}
          />
          <label htmlFor="message">Message for your collaborator</label>
          <textarea
            name="message"
            ref={(ref) => (this.message = ref)}
            maxLength={1000}
            defaultValue={`As discussed, please contribute to the completion of this security questionnaire from ${this.props.modalData.fromCompany}. Thanks.`}
          />
          <Button
            type="submit"
            primary
            loading={this.state.loading}
            disabled={this.state.loading}
          >
            Send
          </Button>
        </form>
      </div>
    );
  }
}

export default ShareAssessmentModal;
