import moment from "moment";
import { filter as _filter, sortBy as _sortBy } from "lodash";
import { IVendorContactResponse } from "../../_common/types/vendorContact";
import { ManualRisk } from "../reducers/manualRisksAPI";

export interface AdditionalEvidence {
  id: number;
  organisationID: number;
  organisationName: string;
  DocumentTypeID: number;
  documentTypeName: string;
  vendorWatchListID: number;
  datastoreVendorID: number;
  name: string;
  evidenceTypeID: number;
  evidenceTypeName: string;
  commentary: string;
  documents: IAdditionalEvidenceDocument[];
  risks: AdditionalEvidenceVendorRisk[];
  score: number;
  deletable: boolean;
  archived: boolean;
  includeInRiskProfile: boolean;
  isNew: boolean;
  createdBy: number;
  createdAt: string;
  initialDocumentUploadedAt?: string;
  createdByName: string;
  createdByAvatar: string;
  updatedAt: string;
  expiry?: string | null;
  readOnly?: boolean;
  byAssessmentId?: boolean;
  assessmentPublishedAt: any;
  usersById: any;
  sourceEntityType?: AdditionalEvidenceSourceEntityType;
  sourceEntityID?: string;
  isSharedEvidence: boolean;
  requestStatus?: AdditionalEvidenceRequestStatus;
  priority?: AdditionalEvidenceRequestPriority;
}

export interface IAdditionalEvidenceDocument {
  id: number;
  additionalEvidenceID: number;
  name: string;
  gcsObjectName: string;
  gcsBucketAlias: string;
  filename: string;
  virusScanned: boolean;
  virusSafe: boolean;
  contentLibraryUUID: string;
  contentLibraryVersion: number;
  uploadedBy: number;
  uploadedByName: string;
  uploadedByAvatar: string;
  uploadedAt: string;
  updatedAt: string;
}

export interface AdditionalEvidenceRequestData {
  vendorID: number;
  requestedDocuments: IDocumentRequest[];
  requestRecipients: IVendorContactResponse[];
}

export enum AdditionalEvidenceRequestPriority {
  High = "high",
}

export enum AdditionalEvidenceRequestStatus {
  Requested = "requested",
  Review = "review",
  Active = "active",
}

export interface IDocumentRequest {
  evidenceType: number;
  isHighPriority: boolean;
}

export interface AdditionalEvidenceVendorRisk extends ManualRisk {
  inRemediation: boolean;
  waived: boolean;
}

export interface AdditionalEvidenceSummary {
  kind: "evidence";
  id: number;
  name: string;
  datastoreVendorID: number;
  vendorHostname: string;
  organisationName: string;
  DocumentTypeID: number;
  documentTypeName: string;
  documentName: string;
  documentID: number;
  filename: string;
  gcsObjectName: string;
  gcsBucketAlias: string;
  virusScanned: boolean;
  virusSafe: boolean;
  totalRisks: number;
  numWaivedRisks: number;
  riskIds: number[];
  commentary: string;
  deletable: boolean;
  archived: boolean;
  includeInRiskProfile: boolean;
  createdAt: string;
  updatedAt: string;
  initialDocumentUploadedAt?: string;
  uploadedByName: string;
  uploadedByAvatar: string;
  uploadedByEmail: string;
  selected: boolean;
  expiry?: string;
  requestStatus?: string;
  priority?: string;
}

export interface SharedAdditionalEvidence
  extends Omit<AdditionalEvidenceSummary, "kind"> {
  kind: "shared_evidence";
  isSharedEvidence: boolean;
  isSharedProfileSupportingDocumentation: boolean;
  orgId: number;
  orgName: string;
  mustRequest: boolean;
}

export interface PublicAdditionalEvidenceSummary
  extends AdditionalEvidenceSummary {
  accessible: boolean;
}

export interface CurrentAdditionalEvidenceList {
  active: AdditionalEvidenceSummary[];
  archived: AdditionalEvidenceSummary[];
}

export interface CurrentSharedAdditionalEvidenceList {
  active: SharedAdditionalEvidence[];
  archived: SharedAdditionalEvidence[];
}

export interface IAdditionalEvidenceRiskEvidence {
  id: number;
  name: string;
  vendorId: number;
  filename: string;
  commentary: string;
  updatedAt: string;
}

export interface AdditionalEvidenceType {
  id: number;
  name: string;
  orgID?: number;
  isDefault: boolean;
  usageCount: number;
}

// Types used when creating an additional evidence record from an existing entity
// Supports general documents, and questionnaire file attachments
export enum AdditionalEvidenceSourceEntityType {
  Document = "document",
  QuestionnaireAttachment = "questionnaire_attachment",
}

export interface AdditionalEvidenceHistory {
  id: number;
  additionalEvidenceID: number;
  type: string;
  userID: number;
  createdAt: string;
  metadata?: any;
}

export interface IAdditionalEvidenceHistoryDocument {
  id: number;
  filename: string;
  isDeleted: boolean;
}

export interface IAdditionalEvidenceHistoryDocumentMap {
  [documentID: number]: IAdditionalEvidenceHistoryDocument | undefined;
}

export type AdditionalEvidenceStatus =
  | "unknown"
  | "virus"
  | "scanning"
  | "archived"
  | "active"
  | "expired"
  | "requested"
  | "ready to review"
  | "complete"
  | "in review";

export const isAdditionalEvidenceExpired = (
  evidence: AdditionalEvidenceSummary | AdditionalEvidence
) => {
  const today = moment();
  return evidence.expiry && moment(evidence.expiry).isBefore(today);
};

const calculateAdditionalEvidenceStatus = (
  isExpired: boolean,
  isVirusScanned: boolean,
  isVirusSafe: boolean,
  isArchived: boolean,
  isOpenRequest: boolean,
  isRequestInReview: boolean,
  isRequestComplete: boolean,
  isForVendorPortal?: boolean
) => {
  if (isExpired) {
    return "expired";
  } else if (isOpenRequest) {
    return "requested";
  } else if (isVirusScanned && !isVirusSafe) {
    return "virus";
  } else if (!isVirusScanned) {
    return "scanning";
  } else if (isArchived) {
    return "archived";
  } else if (isRequestInReview) {
    if (isForVendorPortal) return "in review";
    return "ready to review";
  } else if (isRequestComplete && isForVendorPortal) {
    return "complete";
  } else {
    return "active";
  }
};

export const getAdditionalEvidenceStatus = (
  evidence: AdditionalEvidence,
  isForVendorPortal?: boolean
) => {
  // Evidence should have an attached document, unless it is an open additional evidence request
  if (!evidence.documents || evidence.documents.length == 0) {
    const isOpenEvidenceRequest =
      evidence.requestStatus === AdditionalEvidenceRequestStatus.Requested;
    if (isOpenEvidenceRequest && evidence.archived) return "archived";
    if (isOpenEvidenceRequest) return "requested";
    // No document attached to additional evidence - something has gone wrong here
    return "unknown";
  }

  const isExpired = !!isAdditionalEvidenceExpired(evidence);
  const virusScanned = evidence.documents[0].virusScanned ?? false;
  const virusSafe = evidence.documents[0].virusSafe ?? false;
  const isRequestInReview =
    evidence.requestStatus === AdditionalEvidenceRequestStatus.Review;
  const isRequestComplete =
    evidence.requestStatus === AdditionalEvidenceRequestStatus.Active;

  return calculateAdditionalEvidenceStatus(
    isExpired,
    virusScanned,
    virusSafe,
    evidence.archived,
    false,
    isRequestInReview,
    isRequestComplete,
    isForVendorPortal
  );
};

export const getAdditionalEvidenceSummaryStatus = (
  summary: AdditionalEvidenceSummary,
  isForVendorPortal?: boolean
) => {
  const isExpired = !!isAdditionalEvidenceExpired(summary);
  return calculateAdditionalEvidenceStatus(
    isExpired,
    summary.virusScanned,
    summary.virusSafe,
    summary.archived,
    summary.requestStatus === AdditionalEvidenceRequestStatus.Requested,
    summary.requestStatus === AdditionalEvidenceRequestStatus.Review,
    summary.requestStatus === AdditionalEvidenceRequestStatus.Active,
    isForVendorPortal
  );
};

const NonSpecificAdditionalEvidenceTypes: Set<string> = new Set([
  "Unknown",
  "Other",
]);

export const SortAdditionalEvidenceTypes = <t extends { name: string }>(
  types: t[]
): t[] =>
  // sort by name ensuring that "other" and "unknown" are always last and first in the list
  _sortBy(types, [
    (t) => {
      if (t.name == "Other") {
        return "zzzzzz";
      } else if (t.name == "Unknown") {
        return "aaaaaa";
      } else {
        return t.name.toLocaleLowerCase();
      }
    },
  ]);

export const StripNonSpecificAdditionalEvidenceTypes = (
  types: AdditionalEvidenceType[]
) => {
  return _filter(types, (t) => {
    return !(t.isDefault && NonSpecificAdditionalEvidenceTypes.has(t.name));
  });
};

export const additionalEvidenceRiskIDPrefix = "assessment_risk_";

export const evidenceRiskIdToCheckId = (riskID: number) =>
  `${additionalEvidenceRiskIDPrefix}${riskID}`;

export enum AdditionalEvidenceHistoryType {
  CREATED = "created",
  UPDATED = "updated",
  SOURCE_CREATED = "source_created",
  DOCUMENT_ADDED = "document_added",
  DOCUMENT_DELETED = "document_deleted",
  ARCHIVED = "archived",
  UNARCHIVED = "unarchived",
  REQUESTED = "requested",
}

export const getAdditionalEvidenceHistoryTypeDisplayString = (
  historyType: string,
  sourceEntityType?: AdditionalEvidenceSourceEntityType
) => {
  switch (historyType) {
    case AdditionalEvidenceHistoryType.CREATED:
      if (sourceEntityType) {
        return "converted to additional evidence";
      } else {
        return "created";
      }
    case AdditionalEvidenceHistoryType.UPDATED:
      return "updated";
    case AdditionalEvidenceHistoryType.SOURCE_CREATED:
      let displayValue = "created";
      if (
        sourceEntityType &&
        sourceEntityType ===
          AdditionalEvidenceSourceEntityType.QuestionnaireAttachment
      ) {
        displayValue += " questionnaire attachment";
      } else if (
        sourceEntityType &&
        sourceEntityType === AdditionalEvidenceSourceEntityType.Document
      ) {
        displayValue += " document";
      }
      return displayValue;
    case AdditionalEvidenceHistoryType.DOCUMENT_ADDED:
      return "added a document";
    case AdditionalEvidenceHistoryType.DOCUMENT_DELETED:
      return "deleted a document";
    case AdditionalEvidenceHistoryType.ARCHIVED:
      return "archived";
    case AdditionalEvidenceHistoryType.UNARCHIVED:
      return "unarchived";
    case AdditionalEvidenceHistoryType.REQUESTED:
      return "requested from ";
    default:
      return historyType;
  }
};

export interface AdditionalEvidenceUser {
  userId?: number;
  name?: string;
  emailAddress: string;
  avatar?: string;
  createdAt: string;
  hasCurrentInvite: boolean;
}
