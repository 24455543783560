import { SeverityInt } from "../../_common/types/severity";

export enum ControlState {
  Unknown = "unknown",
  Passed = "passed",
  Failed = "failed",
  Partial = "partial",
  NA = "NA",
}

export const ControlStateIcon: Record<ControlState, string> = {
  [ControlState.Unknown]: "cr-icon-question", // TODO - replace with real icon
  [ControlState.Failed]: "cr-icon-cross",
  [ControlState.Partial]: "cr-icon-dash",
  [ControlState.Passed]: "cr-icon-check",
  [ControlState.NA]: "cr-icon-question", // TODO - replace with real icon
};
export const ControlStateText: Record<ControlState, string> = {
  [ControlState.Passed]: "Control met",
  [ControlState.Failed]: "Risks detected",
  [ControlState.Partial]: "Control partially met",
  [ControlState.Unknown]: "Evidence required",
  [ControlState.NA]: "Not applicable",
};

export interface RiskControl {
  controlFamily: string;
  id: string;
  text: string;
}

export interface ControlFamily {
  domain: string;
  id: string;
  name: string;
  controls: RiskControl[];
}

export interface RiskDomain {
  id: string;
  name: string;
  controlFamilies: ControlFamily[];
}

export interface ControlTotals {
  passedCount: number;
  failedCount: number;
  partiallyMetCount: number;
  unknownCount: number;
}

export interface ControlCheck {
  id: string;
  text: string;
  riskId?: string;
  severity: SeverityInt;
}

export interface CheckWithSource extends SecurityProfileCheck {
  documentID: SecurityProfileDocumentID;
  scannedAt: string;
}

export interface SecurityProfileCitation {
  text: string;
}

export interface SecurityProfileCheck {
  id: string;
  pass: boolean;
  citations: SecurityProfileCitation[];
}

export interface SharedVendorDocument {
  uuid: string;
  vendorId: number;
  name?: string;
  filename: string;
  createdAt: string;
  updatedAt: string;
}

export interface ControlDetail {
  id: string;
  text: string;
  objective: string;
  lastScannedAt: string;
  checks: ControlCheck[];
}

export type SecurityProfileDocumentSource =
  | "PrivateSurvey"
  | "AdditionalEvidence"
  | "UpguardDoc"
  | "PublicSurvey"
  | "PublicPDF";

export type SecurityProfileDocumentID = string;

export interface SecurityProfileDocument {
  id: SecurityProfileDocumentID;
  type: SecurityProfileDocumentSource; // TODO - this should probably be a string
  name: string;
  author: string;
  lastUpdated: string;
  linkUrl: string;
}

export interface DocumentScanProgress {
  progress: number;
  updatedAt: string;
}
