import {
  FC,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import SurveyImportAPI from "../api/surveyImportAPI";
import { SurveyListTab } from "./VendorSurveyList";
import XTable, {
  IIconOption,
  IXTableColumnHeader,
  IXTableRow,
  SortDirection,
  XTableCell,
} from "../../_common/components/core/XTable";
import { usePagination, useSorting } from "../../_common/hooks";
import {
  GetQuestionnaireStatusItem,
  ISurveyListItemResponse,
  SurveyStatus,
} from "../../_common/types/survey";
import SurveyName from "../../vendorrisk/components/surveys/SurveyName";
import PillLabel from "../../vendorrisk/components/PillLabel";
import { LabelColor } from "../../_common/types/label";
import moment from "moment/moment";
import { useDefaultHistory } from "../../_common/types/router";
import EmptyCardWithAction from "../../_common/components/EmptyCardWithAction";
import { UserAvatarAndName } from "../../_common/components/UserAvatar";
import { addDefaultWarningAlert } from "../../_common/reducers/messageAlerts.actions";
import {
  MaxFileSizeB,
  MaxFileSizeDisplay,
} from "../../_common/types/fileRestrictions";
import { DropzoneOptions, useDropzone } from "react-dropzone";
import {
  useBasicPermissions,
  UserWriteSurveyImportExport,
} from "../../_common/permissions";
import { useAppDispatch } from "../../_common/types/reduxHooks";
import { useModalV2 } from "../../_common/components/ModalV2";
import ImportQuestionnaireModal, {
  ImportQuestionnaireAcceptedFileTypes,
} from "./ImportQuestionnaireModal";
import FileDropzone from "../../_common/components/FileDropzone";
import EmptyStateCircle from "../../_common/components/EmptyStateCircle";

import "../style/ImportedSurveyList.scss";
import { trackEvent } from "../../_common/tracking";
import TabButtons from "../../_common/components/TabButtons";
import { DropdownItem } from "../../_common/components/core/DropdownV2";
import { LogError } from "../../_common/helpers";
import { useDeleteImportedSurvey } from "../hooks/importedSurveys.hooks";
import { IUserMini } from "../../_common/types/user";
import UserAvatarList from "../../_common/components/UserAvatarList";

const columnHeaders: IXTableColumnHeader[] = [
  {
    id: "from",
    text: "Requested by",
    sortable: true,
    startingSortDir: SortDirection.ASC,
  },
  {
    id: "name",
    text: "Name",
    sortable: true,
    startingSortDir: SortDirection.ASC,
  },
  {
    id: "status",
    text: "Status",
    sortable: true,
    startingSortDir: SortDirection.ASC,
  },
  { id: "progress", text: "Progress", sortable: false },
  {
    id: "created",
    text: "Date added",
    sortable: true,
    startingSortDir: SortDirection.DESC,
  },
  { id: "addedby", text: "Added by", sortable: false },
  { id: "assignees", text: "Assignees", sortable: false },
];

const ImportedSurveyList: FC = () => {
  const history = useDefaultHistory();
  const perms = useBasicPermissions();
  const dispatch = useAppDispatch();

  const userHasWritePerm = !!perms.userPermissions[UserWriteSurveyImportExport];

  const { data: importedSurveysData, isFetching } =
    SurveyImportAPI.useGetImportedSurveysListQuery();
  const {
    data: orgCollaboratorUsersData,
    isFetching: orgCollaboratorsFetching,
  } = SurveyImportAPI.useGetImportedSurveyEligibleOrgUsersQuery();
  const [setSurveyArchived] =
    SurveyImportAPI.useSetImportedSurveyArchivedMutation();

  const [activeTabId, setActiveTabId] = useState<SurveyListTab>("active");

  const [activeSurveys, archivedSurveys] = useMemo(() => {
    if (!importedSurveysData?.surveys || !orgCollaboratorUsersData) {
      // Don't show any data until we've loaded the surveys and collaborator users data
      return [undefined, undefined];
    }
    return [
      importedSurveysData?.surveys.filter((s) => !s.archived),
      importedSurveysData?.surveys.filter((s) => s.archived),
    ];
  }, [importedSurveysData, orgCollaboratorUsersData]);

  useEffect(() => {
    if (!activeSurveys || !archivedSurveys) {
      return;
    }

    // switch automatically to the archived tab if there are only archived surveys
    if (activeSurveys.length === 0 && archivedSurveys.length > 0) {
      setActiveTabId("archived");
    }
  }, [activeSurveys, archivedSurveys]);

  const [sortedSurveys, sortedBy, onSortChange] = useSorting<
    ISurveyListItemResponse,
    "from" | "name" | "status" | "created"
  >(
    (activeTabId === "active" ? activeSurveys : archivedSurveys) ?? [],
    "created",
    SortDirection.DESC,
    {
      from: {
        orderFuncs: [(s) => s.fromCompany.toLowerCase(), (s) => s.dueDate],
        sortDirsAsc: ["asc", "desc"],
        sortDirsDesc: ["desc", "desc"],
      },
      name: {
        orderFuncs: [(s) => s.name?.toLowerCase(), (s) => s.dueDate],
        sortDirsAsc: ["asc", "desc"],
        sortDirsDesc: ["desc", "desc"],
      },
      status: {
        orderFuncs: [
          (s) => GetQuestionnaireStatusItem(s.status, s.dueDate, true).text,
          (s) => s.dueDate,
        ],
        sortDirsAsc: ["asc", "desc"],
        sortDirsDesc: ["desc", "desc"],
      },
      created: {
        orderFuncs: [(s) => s.dateCreated],
        sortDirsAsc: ["asc"],
        sortDirsDesc: ["desc"],
      },
    }
  );

  const [currentPageSurveys, currentPage, totalPages, onPageChange] =
    usePagination(sortedSurveys, 20);

  const [deleteImportedSurvey, deleteImportedSurveyModal] =
    useDeleteImportedSurvey();

  const rows: IXTableRow[] = useMemo(
    () =>
      currentPageSurveys.map((s) => {
        const statusItem = GetQuestionnaireStatusItem(
          s.status,
          s.dueDate,
          true
        );

        const goToSurvey = () =>
          history.push(`/vendors/surveys/${s.id}`, {
            backContext: {
              goBack: true,
              backToText: "Back to Questionnaires",
            },
          });

        const rowDropdownItems: ReactNode[] = [];

        if (userHasWritePerm) {
          const setArchived = async () => {
            try {
              await setSurveyArchived({
                surveyID: s.id,
                archived: !s.archived,
              }).unwrap();
            } catch (e) {
              LogError("error archiving/unarchiving survey", e);
              dispatch(
                addDefaultWarningAlert(
                  s.archived ? "Failed to unarchive" : "Failed to archive"
                )
              );
            }
          };

          rowDropdownItems.push(
            <DropdownItem key="archive" stopPropagation onClick={setArchived}>
              <div className="cr-icon-archive" />
              {s.archived ? "Unarchive" : "Archive"}
            </DropdownItem>
          );

          if (s.archived) {
            rowDropdownItems.push(
              <DropdownItem
                key="delete"
                stopPropagation
                onClick={() => deleteImportedSurvey(s.id)}
              >
                <div className="cr-icon-trash" />
                Delete
              </DropdownItem>
            );
          }
        }

        const iconOpts: IIconOption[] = [];
        if (rowDropdownItems.length > 0) {
          iconOpts.push({
            id: "actions",
            icon: <i className={"cr-icon-dots-menu"} />,
            dropdownItems: rowDropdownItems,
          });
        }
        iconOpts.push({
          id: "chevron",
          icon: <i className="cr-icon-chevron" />,
          onClick: goToSurvey,
        });

        const assigneeUsers: IUserMini[] = [];
        const assigneeUserIDs =
          importedSurveysData?.surveyIDsToAssigneeUserIDs?.[s.id];
        if (assigneeUserIDs) {
          assigneeUserIDs.forEach((uID) => {
            const sharedUser = orgCollaboratorUsersData?.users.find(
              (u) => u.id === uID
            );
            if (sharedUser) {
              assigneeUsers.push(sharedUser);
            }
          });
        }

        return {
          id: s.id,
          onClick: goToSurvey,
          iconOptions: iconOpts,
          cells: [
            <XTableCell key="fromCompany">{s.fromCompany}</XTableCell>,
            <XTableCell key="name">
              <SurveyName survey={s} />
            </XTableCell>,
            <XTableCell key="status">
              <PillLabel color={statusItem.labelColor}>
                {statusItem.importedSurveyText ?? statusItem.text}
              </PillLabel>
              {s.archived && (
                <PillLabel color={LabelColor.Red}>Archived</PillLabel>
              )}
            </XTableCell>,
            <XTableCell key="progress">
              {s.status === SurveyStatus.Sent
                ? "0%"
                : s.numQuestions > 0
                  ? `${Math.floor((s.numAnswers / s.numQuestions) * 100)}%`
                  : ""}
            </XTableCell>,
            <XTableCell key="created">
              {moment(s.dateCreated).format("ll")}
            </XTableCell>,
            <XTableCell key="addedby">
              {s.fromUser && (
                <UserAvatarAndName
                  name={s.fromUser.name}
                  avatar={s.fromUser.avatar}
                  email={s.fromUser.email}
                />
              )}
            </XTableCell>,
            <XTableCell key="assignees">
              <UserAvatarList users={assigneeUsers} maxShown={3} />
            </XTableCell>,
          ],
        };
      }),
    [
      currentPageSurveys,
      history,
      deleteImportedSurvey,
      dispatch,
      setSurveyArchived,
      userHasWritePerm,
      importedSurveysData?.surveyIDsToAssigneeUserIDs,
      orgCollaboratorUsersData,
    ]
  );

  const [openImportQuestionnaireModal, importQuestionnaireModal] = useModalV2(
    ImportQuestionnaireModal
  );

  const onFileDrop: DropzoneOptions["onDrop"] = useCallback(
    async (acceptedFiles: File[]) => {
      if (acceptedFiles.length === 1) {
        trackEvent("QuestionnaireImportExport_FileDropped");
        openImportQuestionnaireModal({
          file: acceptedFiles[0],
        });
      } else if (acceptedFiles.length > 1) {
        dispatch(addDefaultWarningAlert("Please upload one file at a time."));
      } else {
        dispatch(
          addDefaultWarningAlert(
            `Only .xlsx files under ${MaxFileSizeDisplay} in size are accepted.`
          )
        );
      }
    },
    [dispatch, openImportQuestionnaireModal]
  );

  // Set up a dropzone for uploading files anywhere in the card.
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    maxSize: MaxFileSizeB,
    accept: ImportQuestionnaireAcceptedFileTypes,
    onDrop: onFileDrop,
    disabled: !userHasWritePerm,
    noClick: true,
  });

  return (
    <>
      <FileDropzone
        className="imported-survey-list"
        getRootProps={getRootProps}
        getInputProps={getInputProps}
        isDragActive={isDragActive}
      >
        <TabButtons
          tabs={[
            {
              id: "active",
              text: `Active${
                activeSurveys ? ` (${activeSurveys.length})` : ""
              }`,
            },
            {
              id: "archived",
              text: `Archived${
                archivedSurveys ? ` (${archivedSurveys.length})` : ""
              }`,
            },
          ]}
          activeTabId={activeTabId}
          onChangeTab={(tabId) => setActiveTabId(tabId)}
        />
        {isFetching ||
        orgCollaboratorsFetching ||
        rows.length > 0 ||
        activeTabId === "archived" ? (
          <XTable
            columnHeaders={columnHeaders}
            loading={isFetching || orgCollaboratorsFetching}
            sortedBy={sortedBy}
            onSortChange={onSortChange}
            rows={rows}
            pagination={{
              currentPage,
              totalPages,
              onPageChange,
              hidePaginationIfSinglePage: true,
            }}
            emptyContent={
              activeTabId === "archived" ? (
                <EmptyCardWithAction
                  emptyText="No archived questionnaires"
                  emptySubText="When you archive an imported questionnaire, it will appear here."
                />
              ) : undefined
            }
            iconOptions
          />
        ) : userHasWritePerm ? (
          <EmptyStateCircle
            imgName="ImportedSurveys"
            title="Drop .xlsx files here"
            subtext={<>or hit the &quot;Import questionnaire&quot; button.</>}
          />
        ) : (
          <EmptyStateCircle
            imgName="ImportedSurveys"
            title="No imported questionnaires"
            subtext={
              <>
                Questionnaires imported by anyone in your organization will be
                shown here.
              </>
            }
          />
        )}
      </FileDropzone>
      {importQuestionnaireModal}
      {deleteImportedSurveyModal}
    </>
  );
};

export default ImportedSurveyList;
