import moment from "moment";
import { useEffect, useRef, useState } from "react";
import LoadingIcon from "../../../_common/components/core/LoadingIcon";

import "../../style/components/automation/Autosave.scss";

export interface IAutosaveProps {
  recipeUUID: string;
  saving: boolean;
  error?: string;
  showIcon: boolean;
}

// This component manages autosaving for a survey instance. It will monitor the redux store for updates
// and queue them
const Autosave = (props: IAutosaveProps) => {
  const { recipeUUID, saving, error, showIcon } = props;
  const [lastSavedAt, setLastSavedAt] = useState(0);
  const prevSaving = useRef(false);

  // Update the lastSavedAt time whenever saving becomes false
  useEffect(() => {
    if (prevSaving.current && !saving) {
      setLastSavedAt(new Date().valueOf());
    }
    prevSaving.current = saving;
  }, [saving]);

  useEffect(() => {
    setLastSavedAt(0);
  }, [recipeUUID]);

  // Make sure we update the time text every 20 seconds or so, or it'll always say "Last saved a few seconds ago"
  const [, setForceUpdate] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      setForceUpdate(new Date().valueOf());
    }, 20000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  if (!showIcon) {
    return null;
  }

  return (
    <div className="recipe-autosave-container">
      {saving && (
        <>
          <LoadingIcon />
          <div className="last-saved-text">{"saving"}</div>
        </>
      )}
      {error && <div className="last-saved-text error">{`${error}`}</div>}
      {!error && !saving && (
        <div className="last-saved-text">
          {lastSavedAt ? `Last saved ${moment(lastSavedAt).fromNow()}` : null}
        </div>
      )}
    </div>
  );
};

export default Autosave;
