import { Component } from "react";
import {
  getLocalStorageItemString,
  setLocalStorageItemString,
} from "../session";
import InfoBanner, {
  IInfoBannerProps,
} from "../../vendorrisk/components/InfoBanner";

export const dismissableBannerLocalStorageKey = (key: string) =>
  `dismissableHelpBanner-${key}-active`;

export interface IDismissableBannerProps extends IInfoBannerProps {
  localStorageKey?: string;
  isDisableOverride?: boolean;
  isActiveOverride?: boolean;
}

interface IDismissableBannerState {
  isActive: boolean;
}

export default class DismissableBanner extends Component<
  IDismissableBannerProps,
  IDismissableBannerState
> {
  constructor(props: IDismissableBannerProps) {
    super(props);

    // Default to open unless it's been explicitly disabled or dismissed
    let isActive = "true";
    if (props.isDisableOverride) {
      if (props.localStorageKey) {
        setLocalStorageItemString(
          dismissableBannerLocalStorageKey(props.localStorageKey),
          "true"
        );
      }
      isActive = "false";
    } else if (props.localStorageKey) {
      isActive =
        getLocalStorageItemString(
          dismissableBannerLocalStorageKey(props.localStorageKey)
        ) ?? "true";
    }

    if (props.isActiveOverride && !isActive) {
      if (props.localStorageKey) {
        setLocalStorageItemString(
          dismissableBannerLocalStorageKey(props.localStorageKey),
          "true"
        );
      }
      isActive = "true";
    }

    this.state = { isActive: isActive !== "false" };
  }

  componentDidUpdate(prevProps: Readonly<IDismissableBannerProps>) {
    if (this.props.isDisableOverride && !prevProps.isDisableOverride) {
      // forced into a disabled state
      if (this.props.localStorageKey) {
        setLocalStorageItemString(
          dismissableBannerLocalStorageKey(this.props.localStorageKey),
          "true"
        );
      }
      this.setState({ isActive: false });
    } else if (this.props.isActiveOverride && !prevProps.isActiveOverride) {
      // forced into an active state, reset the storage key and set active true
      if (this.props.localStorageKey) {
        setLocalStorageItemString(
          dismissableBannerLocalStorageKey(this.props.localStorageKey),
          "true"
        );
      }
      this.setState({ isActive: true });
    } else if (
      (prevProps.localStorageKey !== this.props.localStorageKey ||
        prevProps.isDisableOverride !== this.props.isDisableOverride) &&
      this.props.localStorageKey
    ) {
      // fall through to the stored flag
      const isActive =
        getLocalStorageItemString(
          dismissableBannerLocalStorageKey(this.props.localStorageKey)
        ) ?? "true";
      this.setState({ isActive: isActive !== "false" });
    }
  }

  onDismiss = () => {
    this.setState({
      isActive: false,
    });
    // Set this property in local storage so it's remembered between sessions
    if (this.props.localStorageKey) {
      setLocalStorageItemString(
        dismissableBannerLocalStorageKey(this.props.localStorageKey),
        "false"
      );
    }

    if (this.props.onDismiss) {
      this.props.onDismiss();
    }
  };

  render() {
    if (!this.state.isActive) {
      return null;
    }

    const infoBannerProps = { ...this.props };
    delete infoBannerProps.localStorageKey;

    return <InfoBanner {...infoBannerProps} onDismiss={this.onDismiss} />;
  }
}
