import { Component } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import ColorCheckbox from "../ColorCheckbox";
import ToggleSwitch from "../../../_common/components/core/ToggleSwitch";
import Button from "../../../_common/components/core/Button";
import { closeModal } from "../../../_common/reducers/commonActions";
import {
  updateIntegration,
  executeWebhookTest,
} from "../../reducers/integrations.actions";
import "../../style/components/IntegrationDetailsModal.scss";
import Icon from "../../../_common/components/core/Icon";
import {
  addDefaultSuccessAlert,
  addDefaultUnknownErrorAlert,
  addSimpleErrorAlert,
} from "../../../_common/reducers/messageAlerts.actions";

export const IntegrationDetailsModalName = "IntegrationDetailsModalName";

const WEBHOOK_TYPE = 1;
const ZAPIER_TYPE = 2;
const BASICAUTH_USERNAMEPARAM = "username";
const BASICAUTH_PASSWORDPARAM = "password";

const MSG_ZAP_CONNECTED = "A Zap! is currently connected.";
const MSG_ZAP_DISCONNECTED = "A Zap! is not yet connected.";

class IntegrationDetailsModal extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    modalData: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    let webhookURL = "";
    let headerParams = [];
    let urlParams = [];
    let basicAuthParams = {};
    let zapierConnected = false;

    const { notifications } = props.modalData;

    if (props.modalData.integration.type == ZAPIER_TYPE) {
      webhookURL = MSG_ZAP_DISCONNECTED;
    }

    if (
      props.modalData.integration.webhooks != null &&
      props.modalData.integration.webhooks.length > 0
    ) {
      const webhook = props.modalData.integration.webhooks[0];
      webhookURL = webhook ? webhook.url : "";

      if (props.modalData.integration.type == ZAPIER_TYPE) {
        if (webhookURL && webhookURL.indexOf("zapier") > 0) {
          zapierConnected = true;
          webhookURL = MSG_ZAP_CONNECTED;
        } else {
          webhookURL = MSG_ZAP_DISCONNECTED;
        }
      }

      for (let i = 0; i < notifications.length; i++) {
        notifications[i].selected = false;
        for (let j = 0; j < props.modalData.integration.uuids.length; j++) {
          if (props.modalData.integration.uuids[j] == notifications[i].uuid) {
            notifications[i].selected = true;
          }
        }
      }

      // extract headers and url parameters from the webhook definition
      if (webhook.headerParams) {
        let idx = 0;
        Object.keys(webhook.headerParams).map((name) => {
          const value = webhook.headerParams[name];
          headerParams[idx] = { name, value };
          idx = idx + 1;
        });
      }
      if (webhook.urlParams) {
        let idx = 0;
        Object.keys(webhook.urlParams).map((name) => {
          const value = webhook.urlParams[name];
          urlParams[idx] = { name, value };
          idx = idx + 1;
        });
      }
      if (webhook.basicAuthParams) {
        basicAuthParams = webhook.basicAuthParams;
        basicAuthParams.enabled = false;
        Object.keys(webhook.basicAuthParams).map((name) => {
          const value = webhook.basicAuthParams[name];
          if (value) {
            basicAuthParams.enabled = true;
          }
        });
      }
    }

    const state = {
      loading: false,
      testWebhookLoading: false,
      id: props.modalData.integration.id,
      identifier: props.modalData.integration.uniqueID,
      description: props.modalData.integration.description,
      originalIntegrationType:
        props.modalData.integration.type == 1 ? "webhook" : "zapier",
      integrationType:
        props.modalData.integration.type == 1 ? "webhook" : "zapier",
      enabled: props.modalData.integration.enabled,
      webhookURL: webhookURL,
      headerParams: headerParams,
      urlParams: urlParams,
      basicAuthParams: basicAuthParams,
      notifications: notifications,
      zapierConnected,
    };
    this.state = state;
  }

  onEditVar = (evt) => {
    var field = evt.target.name;
    var { value } = evt.target;
    this.setState({ field: value });
  };
  onEditHeaderName = (evt) => {
    var st = evt.target.name.lastIndexOf("_");
    var idx = parseInt(evt.target.name.substring(st + 1));
    var { headerParams } = this.state;
    headerParams[idx].name = evt.target.value;
    this.setState({ headerParams });
  };
  onEditHeaderValue = (evt) => {
    var st = evt.target.name.lastIndexOf("_");
    var idx = parseInt(evt.target.name.substring(st + 1));
    var { headerParams } = this.state;
    headerParams[idx].value = evt.target.value;
    this.setState({ headerParams });
  };
  onEditURLParamName = (evt) => {
    var st = evt.target.name.lastIndexOf("_");
    var idx = parseInt(evt.target.name.substring(st + 1));
    var { urlParams } = this.state;
    urlParams[idx].name = evt.target.value;
    this.setState({ urlParams });
  };
  onEditURLParamValue = (evt) => {
    var st = evt.target.name.lastIndexOf("_");
    var idx = parseInt(evt.target.name.substring(st + 1));
    var { urlParams } = this.state;
    urlParams[idx].value = evt.target.value;
    this.setState({ urlParams });
  };

  onDeleteHeader = (idx) => {
    var { headerParams } = this.state;
    headerParams.splice(idx, 1);
    this.setState({ headerParams });
  };
  onDeleteURLParam = (idx) => {
    var { urlParams } = this.state;
    urlParams.splice(idx, 1);
    this.setState({ urlParams });
  };

  onSetWebhook = (evt) => {
    evt.preventDefault();
    let webhookURL = "";
    if (
      this.props.modalData.integration.webhooks &&
      this.props.modalData.integration.webhooks[0]
    ) {
      webhookURL = this.props.modalData.integration.webhooks[0].url;
      if (webhookURL.indexOf("zapier") > 0) {
        webhookURL = "";
      }
    }
    this.setState({ integrationType: "webhook", webhookURL, enabled: false });
  };
  onSetZapier = (evt) => {
    evt.preventDefault();
    let webhookURL = "";
    if (this.props.modalData.integration.webhooks) {
      webhookURL = this.props.modalData.integration.webhooks[0].url;
      if (webhookURL.indexOf("zapier") <= 0) {
        webhookURL = "";
      }
    }
    let zapierConnected = false;
    if (webhookURL) {
      zapierConnected = true;
      webhookURL = MSG_ZAP_CONNECTED;
    } else {
      webhookURL = MSG_ZAP_DISCONNECTED;
    }
    this.setState({
      integrationType: "zapier",
      zapierConnected,
      webhookURL,
      enabled: false,
    });
  };
  onToggleEnabled = (evt) => {
    evt.preventDefault();
    this.setState({ enabled: !this.state.enabled });
  };

  onAddHeader = (evt) => {
    evt.preventDefault();
    var { headerParams } = this.state;
    if (
      headerParams.length == 0 ||
      headerParams[headerParams.length - 1].name ||
      headerParams[headerParams.length - 1].value
    ) {
      headerParams.push({ name: "", value: "" });
      this.setState({ headerParams });
    }
  };

  onAddURLParameter = (evt) => {
    evt.preventDefault();
    var { urlParams } = this.state;
    if (
      urlParams.length == 0 ||
      urlParams[urlParams.length - 1].name ||
      urlParams[urlParams.length - 1].value
    ) {
      urlParams.push({ name: "", value: "" });
      this.setState({ urlParams });
    }
  };

  onAddBasicAuth = (evt) => {
    evt.preventDefault();
    this.setState({
      basicAuthParams: {
        enabled: true,
        BASICAUTH_USERNAMEPARAM: "",
        BASICAUTH_PASSWORDPARAM: "",
      },
    });
  };

  onDeleteBasicAuth = (evt) => {
    evt.preventDefault();
    this.setState({
      basicAuthParams: {
        enabled: false,
      },
    });
  };

  onEditBasicAuth = (name) => (evt) => {
    evt.preventDefault();
    const { basicAuthParams } = this.state;
    basicAuthParams[name] = evt.target.value;
    this.setState({ basicAuthParams });
  };

  onToggleNotification = (idx) => (evt) => {
    const { notifications } = this.state;
    notifications[idx].selected = !notifications[idx].selected;
    this.setState({ notifications });
  };

  getFormData = (validate) => {
    const isZapier = this.state.integrationType == "zapier";
    const { id } = this.state;
    const integrationType = isZapier ? ZAPIER_TYPE : WEBHOOK_TYPE;
    const description = this.description.value.trim();
    const identifier = this.identifier.value.trim();
    const httpHeaders = {};
    const urlParams = {};
    const basicAuthParams = {};
    let webhookURL = "";
    let uuidsList = [];
    const { enabled } = this.state;

    if (!isZapier) {
      webhookURL = this.webhookURL.value;
    }

    // check easy mandatory variables
    if (
      validate &&
      (!identifier || !description || (!isZapier && !webhookURL))
    ) {
      this.props.dispatch(
        addSimpleErrorAlert("One of the mandatory fields is empty")
      );
      return null;
    }

    if (validate && identifier.length < 8) {
      this.props.dispatch(
        addSimpleErrorAlert(
          "Unique identifier for integration must be at least 8 characters long"
        )
      );
      return null;
    }

    // gather our selected uuids from the notifications list
    for (let i = 0; i < this.state.notifications.length; i++) {
      if (this.state.notifications[i].selected) {
        uuidsList.push(this.state.notifications[i].uuid);
      }
    }

    if (!isZapier) {
      // gather our header variables
      for (let i = 0; i < this.state.headerParams.length; i++) {
        if (
          this.state.headerParams[i].name &&
          this.state.headerParams[i].value
        ) {
          httpHeaders[this.state.headerParams[i].name] =
            this.state.headerParams[i].value;
        }
      }

      // gather our url parameter variables
      for (let i = 0; i < this.state.urlParams.length; i++) {
        if (this.state.urlParams[i].name && this.state.urlParams[i].value) {
          urlParams[this.state.urlParams[i].name] =
            this.state.urlParams[i].value;
        }
      }

      // gather our basic auth parameter variables
      if (this.state.basicAuthParams.enabled) {
        basicAuthParams[BASICAUTH_USERNAMEPARAM] = this.basicUsername.value;
        basicAuthParams[BASICAUTH_PASSWORDPARAM] = this.basicPassword.value;
      }
    }

    return {
      id,
      integrationType,
      identifier,
      description,
      uuidsList,
      webhookURL,
      httpHeaders,
      urlParams,
      basicAuthParams,
      enabled,
    };
  };

  onSubmit = (evt) => {
    evt.preventDefault();
    const data = this.getFormData(true);
    if (data == null) {
      return;
    }

    // dispatch the sucker
    this.setState({ loading: true });
    this.props
      .dispatch(
        updateIntegration(
          data.id,
          data.integrationType,
          data.identifier,
          data.description,
          data.uuidsList,
          data.webhookURL,
          data.httpHeaders,
          data.urlParams,
          data.basicAuthParams,
          data.enabled
        )
      )
      .then(() => {
        this.props.dispatch(closeModal());
        this.props.dispatch(
          addDefaultSuccessAlert("Integration definition updated")
        );
      })
      .catch((err) => {
        this.setState({ loading: false });
        this.props.dispatch(addDefaultUnknownErrorAlert(err.message));
      });
  };

  onRunTest = (evt) => {
    evt.preventDefault();
    const data = this.getFormData(false);

    // dispatch the sucker
    this.setState({ testWebhookLoading: true });
    this.props
      .dispatch(
        executeWebhookTest(
          data.uuidsList,
          data.webhookURL,
          data.httpHeaders,
          data.urlParams,
          data.basicAuthParams
        )
      )
      .then(() => {
        this.setState({ testWebhookLoading: true });
      });
  };

  createRandomId = () => {
    var text = "";
    const possibleCHAR = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const possibleDIGIT = "0123456789";

    for (let i = 0; i < 4; i++)
      text += possibleCHAR.charAt(
        Math.floor(Math.random() * possibleCHAR.length)
      );
    for (let i = 0; i < 4; i++)
      text += possibleDIGIT.charAt(
        Math.floor(Math.random() * possibleDIGIT.length)
      );
    return text;
  };

  render() {
    const isZapier = this.state.integrationType == "zapier";
    const columnHeaders = [];
    columnHeaders.push(
      { id: "notification", text: "Notification", sortable: false },
      { id: "selected", text: "Selected", sortable: false }
    );

    return (
      <div id="integration-details-modal">
        <div className="modal-content">
          <h2>Update Integration Details</h2>
          <p />
          <form onSubmit={this.onSubmit}>
            <label htmlFor="integrationType">Integration Type</label>
            <div className="radiolist">
              <ColorCheckbox
                color="blue"
                checked={this.state.integrationType != "zapier"}
                onClick={this.onSetWebhook}
                label="Generic Webhook"
              />
              <div className="spacer" />
              <ColorCheckbox
                color="blue"
                checked={this.state.integrationType == "zapier"}
                onClick={this.onSetZapier}
                label="Zapier"
              />
            </div>
            <div className="vertspacer" />
            {this.state.originalIntegrationType != this.state.integrationType &&
              this.state.integrationType == "zapier" && (
                <>
                  <div className="warning">
                    Please Note: changing the integration type to{" "}
                    <b>
                      <i>Zapier</i>
                    </b>{" "}
                    will remove any previously configured API endpoint.
                  </div>
                  <div className="vertspacer" />
                </>
              )}
            {this.state.originalIntegrationType != this.state.integrationType &&
              this.state.integrationType == "webhook" && (
                <>
                  <div className="warning">
                    Please note: changing the integration type to a{" "}
                    <b>
                      <i>Generic Webhook</i>
                    </b>{" "}
                    will disconnect any existing Zap!.
                  </div>
                  <div className="vertspacer" />
                </>
              )}

            <label htmlFor="identifier">Identifier</label>
            <input
              type="text"
              name="identifier"
              required
              ref={(ref) => (this.identifier = ref)}
              defaultValue={this.state.identifier}
              onChange={this.onEditVar}
            />

            <label htmlFor="description">Description</label>
            <input
              type="text"
              name="description"
              required
              ref={(ref) => (this.description = ref)}
              defaultValue={this.state.description}
              onChange={this.onEditVar}
            />

            {!isZapier && (
              <div className="webhook-area">
                <label htmlFor="webhookURL">Webhook API URL</label>
                <input
                  type="text"
                  name="webhookURL"
                  required
                  ref={(ref) => (this.webhookURL = ref)}
                  defaultValue={this.state.webhookURL}
                  onChange={this.onEditVar}
                />

                <label htmlFor="headers">HTTP Headers</label>
                <div className="attrib-table">
                  {this.state.headerParams &&
                    this.state.headerParams.map((data, idx) => (
                      <div className="attribute-row" key={`header_"${idx}`}>
                        <input
                          type="text"
                          id={`hn_${idx}`}
                          className="attrib-name"
                          name={`header_name_${idx}`}
                          required
                          ref={(ref) => (this.name = ref)}
                          value={data.name}
                          onChange={this.onEditHeaderName}
                        />
                        <input
                          type="text"
                          id={`hv_${idx}`}
                          className="attrib-value"
                          name={`header_value_${idx}`}
                          required
                          ref={(ref) => (this.name = ref)}
                          value={data.value}
                          onChange={this.onEditHeaderValue}
                        />
                        <Icon
                          name="trash"
                          className="pointer"
                          onClick={() => this.onDeleteHeader(idx)}
                        />
                      </div>
                    ))}
                  <div className="attribute-row">
                    <a onClick={this.onAddHeader}>(+) Add a new HTTP Header</a>
                  </div>
                </div>

                <div className="vertspacer" />
                <label htmlFor="urlParams">HTTP URL Parameters</label>
                <div className="attrib-table">
                  {this.state.urlParams &&
                    this.state.urlParams.map((data, idx) => (
                      <div className="attribute-row" key={`url_${idx}`}>
                        <input
                          type="text"
                          id={`un_${idx}`}
                          className="attrib-name"
                          name={`url_name_${idx}`}
                          required
                          ref={(ref) => (this.name = ref)}
                          value={data.name}
                          onChange={this.onEditURLParamName}
                        />
                        <input
                          type="text"
                          id={`uv_${idx}`}
                          className="attrib-value"
                          name={`url_value_${idx}`}
                          required
                          ref={(ref) => (this.name = ref)}
                          value={data.value}
                          onChange={this.onEditURLParamValue}
                        />
                        <Icon
                          name="trash"
                          className="pointer"
                          onClick={() => this.onDeleteURLParam(idx)}
                        />
                      </div>
                    ))}
                  <div className="attribute-row">
                    <a onClick={this.onAddURLParameter}>
                      (+) Add a new URL Parameter
                    </a>
                  </div>
                </div>

                <div className="vertspacer" />
                <label htmlFor="basicAuthParams">
                  Basic Authentication
                  {this.state.basicAuthParams.enabled && (
                    <Icon
                      name="trash"
                      className="pointer"
                      onClick={this.onDeleteBasicAuth}
                    />
                  )}
                </label>
                {this.state.basicAuthParams.enabled && (
                  <div className="attrib-table">
                    <div className="attribute-row">
                      <input
                        type="text"
                        className="attrib-name"
                        readOnly
                        disabled
                        ref={(ref) => (this.name = ref)}
                        value={BASICAUTH_USERNAMEPARAM}
                      />
                      <input
                        type="text"
                        className="attrib-value"
                        required
                        ref={(ref) => (this.basicUsername = ref)}
                        value={
                          this.state.basicAuthParams[BASICAUTH_USERNAMEPARAM]
                        }
                        onChange={this.onEditBasicAuth(BASICAUTH_USERNAMEPARAM)}
                      />
                    </div>
                    <div className="attribute-row">
                      <input
                        type="text"
                        className="attrib-name"
                        readOnly
                        disabled
                        ref={(ref) => (this.name = ref)}
                        value={BASICAUTH_PASSWORDPARAM}
                      />
                      <input
                        type="password"
                        className="attrib-value"
                        required
                        ref={(ref) => (this.basicPassword = ref)}
                        value={
                          this.state.basicAuthParams[BASICAUTH_PASSWORDPARAM]
                        }
                        onChange={this.onEditBasicAuth(BASICAUTH_PASSWORDPARAM)}
                      />
                    </div>
                  </div>
                )}
                {!this.state.basicAuthParams.enabled && (
                  <div className="attrib-table">
                    <div className="attribute-row">
                      <a onClick={this.onAddBasicAuth}>
                        (+) Add Basic Authentication
                      </a>
                    </div>
                  </div>
                )}
                {!isZapier && (
                  <div className="test-area">
                    <Button
                      primary
                      id="webhook-btn"
                      className="webhook-btn"
                      loading={this.state.testWebhookLoading}
                      onClick={this.onRunTest}
                    >
                      Test Webhook
                    </Button>
                  </div>
                )}
                {!isZapier &&
                  this.props.modalData.webhookTestResponse != null &&
                  this.props.modalData.webhookTestResponse.response != null && (
                    <div className="results-area">
                      <pre>{`Status: ${this.props.modalData.webhookTestResponse.response.status}\nStatusCode: ${this.props.modalData.webhookTestResponse.response.status_code}\nProto: ${this.props.modalData.webhookTestResponse.response.proto}\n`}</pre>
                    </div>
                  )}
              </div>
            )}

            {isZapier && (
              <>
                <label htmlFor="name">Zapier Integration</label>
                <input
                  type="text"
                  className={classnames({
                    "zapier-text": this.state.zapierConnected,
                    "no-zapier-text": !this.state.zapierConnected,
                  })}
                  name="webhook"
                  ref={(ref) => (this.name = ref)}
                  value={this.state.webhookURL}
                />
              </>
            )}

            <div className="vertspacer" />
            <div className="vertspacer" />
            <label htmlFor="name">Selected Notifications</label>
            <table className="notification-selector-table">
              <tbody>
                {this.state.notifications.map((s, idx) => (
                  <tr key={s.uuid} className="notification-row">
                    <td className="notification-cell">
                      {s.configDescription}
                      {s.parameters.Threshold && (
                        <>
                          {" "}
                          <strong>{s.parameters.Threshold}</strong>
                        </>
                      )}
                      {s.parameters.InDays && (
                        <>
                          {" "}
                          in the last <strong>
                            {s.parameters.InDays}
                          </strong>{" "}
                          days
                        </>
                      )}
                    </td>
                    <td className="toggle-cell">
                      <ToggleSwitch
                        className="toggle-switch"
                        id={`${s.uuid}-select`}
                        name={`toggle_${idx}`}
                        selected={s.selected}
                        onClick={this.onToggleNotification(idx)}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            <div className="vertspacer" />
            <div className="vertspacer" />
            <label htmlFor="enabled">Enabled</label>
            <ToggleSwitch
              className="toggle-switch"
              id="enabled"
              name="enabled"
              selected={this.state.enabled}
              onClick={this.onToggleEnabled}
            />

            <div className="vertspacer" />
            <Button
              type="submit"
              id="update-btn"
              primary
              loading={this.state.loading}
              disabled={this.state.loading}
            >
              Update
            </Button>
          </form>
        </div>
      </div>
    );
  }
}

export default IntegrationDetailsModal;
