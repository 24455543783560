import { FC } from "react";
import {
  TooltipButton,
  TooltipButtonProps,
} from "../../_common/components/core/Button";
import classnames from "classnames";

import "../style/SurveyViewerIconButton.scss";

interface ISurveyViewerIconButtonProps
  extends Omit<TooltipButtonProps, "icon"> {
  highlight?: boolean;
  tooltipContent?: React.ReactNode;
  disabled?: boolean;
  className?: string;
}

const SurveyViewerIconButton: FC<ISurveyViewerIconButtonProps> = (props) => {
  const { highlight, className, children, disabled, ...tooltipProps } = props;

  return (
    <div className={"survey-viewer-icon-button-container"}>
      <TooltipButton
        tertiary
        className={classnames("survey-viewer-icon-button", className, {
          highlight: highlight,
          disabled: disabled,
        })}
        {...tooltipProps}
      >
        {children}
      </TooltipButton>
    </div>
  );
};

export default SurveyViewerIconButton;
