export const breachSightStatusMap = {
  // eslint-disable-line
  Disclosed: {
    id: "Disclosed",
    open: true,
    text: "Disclosed",
    labelColor: "fuchsia",
    step: 0,
  },
  Acknowledged: {
    id: "Acknowledged",
    open: true,
    text: "Acknowledged",
    labelColor: "lightblue",
    step: 1,
  },
  Investigating: {
    id: "Investigating",
    open: true,
    text: "Investigating",
    labelColor: "lightblue",
    step: 2,
  },
  Remediating: {
    id: "Remediating",
    open: true,
    text: "Remediating",
    labelColor: "blue",
    step: 3,
  },
  ClosedNoIssue: {
    id: "ClosedNoIssue",
    open: false,
    text: "Closed - No Issue",
    labelColor: "grey",
    step: 4,
    desc: "This finding is not relevant",
  },
  ClosedRiskAccepted: {
    id: "ClosedRiskAccepted",
    open: false,
    text: "Closed - Risk Accepted",
    labelColor: "blue",
    step: 4,
    desc: "Accept the risk this finding poses",
  },
  ClosedFixedUnverified: {
    id: "ClosedFixedUnverified",
    open: false,
    text: "Closed - Fixed",
    labelColor: "mountainmeadow",
    step: 4,
    desc: "This issue has been corrected",
  },
  ClosedFixedVerified: {
    id: "ClosedFixedVerified",
    open: false,
    text: "Closed - Fixed (Verified)",
    labelColor: "mountainmeadow",
    step: 4,
  },
};

export const breachSightHistoryTypeMap = {
  RiskCreated: {
    actionText: "Case created",
    actionTextWithUser: "created this.",
    actionTextWithAPI: "Case was created via the API",
  },
  StatusUpdated: {
    actionText: "Status updated",
    actionTextWithUser: "changed the status.",
    actionTextWithAPI: "Status was changed via the API.",
  },
  CommentAdded: {
    actionText: "Comment added",
    actionTextWithUser: "added a comment.",
    actionTextWithAPI: "Comment was added via the API",
  },
};
