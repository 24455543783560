import "../../style/components/FilledIcon.scss";

interface FilledIconProps {
  iconClass: string;
}

const FilledIcon = ({ iconClass }: FilledIconProps) => (
  <div className={"filled-icon"}>
    <i className={iconClass} />
  </div>
);

export default FilledIcon;
