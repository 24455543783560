import { AdditionalEvidenceEvidence } from "../../types/vendorAssessments";
import { FC, memo } from "react";
import XTable, {
  IXTableRow,
  XTableCell,
} from "../../../_common/components/core/XTable";
import PillLabel from "../PillLabel";
import { formatDate } from "../../../_common/helpers";
import { LabelColor } from "../../../_common/types/label";
import "../../style/components/vendor_assessment/VendorAssessmentV3AdditionalEvidenceTable.scss";
import { usePagination } from "../../../_common/hooks";
import {
  ISharedAssessment,
  SharedAssessmentAccessResult,
} from "../../types/sharedAssessment";
import Button from "../../../_common/components/core/Button";

interface VendorAssessmentV3AdditionalEvidenceTableProps {
  evidence: AdditionalEvidenceEvidence[];
  selectable: boolean;
  onSelect?: (id: number, isPublic: boolean) => void;
  onClick: (id: number, isPublic: boolean) => void;
  sharedAssessment?: ISharedAssessment;
  sharedAccessRequest?: () => void;
}

const VendorAssessmentsV3EvidenceTable: FC<
  VendorAssessmentV3AdditionalEvidenceTableProps
> = (props) => {
  const [items, page, numPages, setPage] = usePagination(props.evidence, 10);

  const getRows = (): IXTableRow[] => {
    const rows: IXTableRow[] = items.map((e) => ({
      id: `${e.id}:${e.shared}`,
      selected: props.selectable && e.selected,
      iconOptions: [
        {
          icon: <i className={"cr-icon-chevron"} />,
          id: "chevron",
          onClick: () => props.onClick(e.id, e.shared),
        },
      ],
      onClick: () => props.onClick(e.id, e.shared),
      cells: [
        <XTableCell key={"name"} className={"name-cell"}>
          {e.name}
          {e.shared && <PillLabel color={LabelColor.Fuchsia}>Shared</PillLabel>}
        </XTableCell>,
        <XTableCell key={"risks"}>
          {e.numRisks > 0
            ? `${e.numRisks} ${
                e.numWaived > 0 ? "(" + e.numWaived + " waived)" : ""
              }`
            : "-"}
        </XTableCell>,
        <XTableCell key={"date"}>
          {e.dateAdded ? formatDate(e.dateAdded) : "-"}
        </XTableCell>,
        <XTableCell key={"access"}></XTableCell>,
      ],
    }));

    // Include shared questionnaires that require an access request, or have a pending access request
    if (
      props.sharedAssessment &&
      props.sharedAssessment.contentLibraryDocuments &&
      (props.sharedAssessment.orgLevelStatus ===
        SharedAssessmentAccessResult.MustRequest ||
        props.sharedAssessment?.orgLevelStatus ===
          SharedAssessmentAccessResult.NdaRequired ||
        props.sharedAssessment.orgLevelStatus ===
          SharedAssessmentAccessResult.PendingRequest)
    ) {
      const accessPending =
        props.sharedAssessment.orgLevelStatus ===
        SharedAssessmentAccessResult.PendingRequest;

      const unarchivedDocs =
        props.sharedAssessment.contentLibraryDocuments.filter(
          (doc) => !doc.archived
        );

      rows.push(
        ...unarchivedDocs.map((d): IXTableRow => {
          return {
            id: d.uuid,
            selectionDisabled: true,
            cells: [
              <XTableCell key={"name"} className={"name-cell"}>
                {d.name}{" "}
                <PillLabel className="status-pill" color={LabelColor.Fuchsia}>
                  Shared
                </PillLabel>
              </XTableCell>,
              <XTableCell key={"risks"}>-</XTableCell>,
              <XTableCell key={"date"}>-</XTableCell>,
              <XTableCell key={"access"} className={"access-cell"}>
                {" "}
                {accessPending ? (
                  "Access pending"
                ) : (
                  <Button tertiary onClick={props.sharedAccessRequest}>
                    <span className={"cr-icon-lock"} />
                    {props.sharedAssessment?.orgLevelStatus ===
                      SharedAssessmentAccessResult.NdaRequired && "View NDA"}
                    {props.sharedAssessment?.orgLevelStatus ===
                      SharedAssessmentAccessResult.MustRequest &&
                      "Request access"}
                  </Button>
                )}
              </XTableCell>,
            ],
          };
        })
      );
    }

    return rows;
  };

  return (
    <XTable
      className={"vendor-assessment-additional-table"}
      columnHeaders={[
        {
          id: "name",
          text: "Name",
        },
        { id: "risks", text: "Risks" },
        { id: "date", text: "Date added" },
        { id: "access", text: "" },
      ]}
      selectable={props.selectable}
      onSelectClick={
        props.onSelect
          ? (rowId) => {
              const parts = (rowId as string).split(":");
              const id = parseInt(parts[0]);
              const isPublic = parts[1] == "true";
              props.onSelect?.(id, isPublic);
            }
          : undefined
      }
      iconOptions
      pagination={{
        totalPages: numPages,
        onPageChange: setPage,
        hidePaginationIfSinglePage: true,
        currentPage: page,
      }}
      rows={getRows()}
    />
  );
};

export default memo(VendorAssessmentsV3EvidenceTable);
