import { get as _get } from "lodash";
import { FetchCyberRiskUrl } from "../../_common/api";
import { LogError } from "../../_common/helpers";
import {
  setDataLeakClassifiedScanResults,
  setDataLeaksSummary,
} from "./cyberRiskActions";
import { DefaultThunkDispatch } from "../../_common/types/redux";
import { DefaultRootState } from "react-redux";
import { Filters } from "../components/filter/types";
import {
  DataLeaksClassifiedScanResult,
  DataLeaksGroupingType,
} from "../../_common/types/dataLeaks";

export const SET_DATA_LEAKS_SUMMARY_TAB =
  "CYBERRISK_SET_DATA_LEAKS_SUMMARY_TAB";

export const SET_DATA_LEAKS_SCANRESULTS_CUSTOM_DATE_RANGE =
  "CYBERRISK_SET_DATA_LEAKS_SCANRESULTS_CUSTOM_DATE_RANGE";

export const fetchDataLeaksSummary = (
  isFiltered: boolean,
  isByService: boolean,
  startDate: string,
  endDate: string,
  timeGroup: string
) => {
  return async (
    dispatch: DefaultThunkDispatch,
    getState: () => DefaultRootState
  ) => {
    const dateKey = startDate.concat("_", endDate);
    dispatch(
      setDataLeaksSummary(isFiltered, isByService, dateKey, {
        loading: true,
        error: null,
      })
    );

    let json;

    try {
      json = await FetchCyberRiskUrl(
        "cyberresearch/dataleakssummary/v1/",

        {
          start_date: startDate,
          end_date: endDate,
          by_service: isByService,
          group_type: timeGroup,
        },
        null,
        dispatch,
        getState
      );
    } catch (e) {
      LogError(`Error fetching data leaks summary`, e);
      dispatch(
        setDataLeaksSummary(isFiltered, isByService, dateKey, {
          loading: false,
          error: { errorText: "Could not load summary" },
        })
      );
      throw e;
    }

    if (!json) {
      dispatch(
        setDataLeaksSummary(isFiltered, isByService, dateKey, {
          loading: false,
          error: { errorText: "No data returned" },
        })
      );
      return;
    }

    dispatch(setDataLeaksSummary(isFiltered, isByService, dateKey, json));
  };
};

export const fetchDataLeakClassifiedResultsForOrgByDateRange = (
  force: boolean,
  startDate: string,
  endDate: string,
  filters: Filters
) => {
  return async (
    dispatch: DefaultThunkDispatch,
    getState: () => DefaultRootState
  ) => {
    if (!force) {
      const existing = _get(getState().cyberRisk, `dataLeakScanResults.custom`);
      if (existing && !existing.error) {
        return;
      }
    }

    const classifications: string[] = [];
    if (filters && filters.dataLeaksClassifications) {
      filters.dataLeaksClassifications.forEach(({ value }) => {
        classifications.push(value);
      });
    }
    const services: string[] = [];
    if (filters && filters.dataLeaksSources) {
      filters.dataLeaksSources.forEach(({ value }) => {
        services.push(value);
      });
    }
    const keywords: string[] = [];
    if (filters && filters.dataLeaksKeywords) {
      filters.dataLeaksKeywords.forEach(({ value }) => {
        keywords.push(value);
      });
    }

    dispatch(setDataLeakClassifiedScanResults(true, "custom", null, null));
    let json: {
      startDate: string;
      endDate: string;
      scans: DataLeaksClassifiedScanResult[];
    };
    try {
      json = await FetchCyberRiskUrl(
        "cyberresearch/scans/v2/",
        {
          start_date: startDate,
          end_date: endDate,
          keywords: keywords ? keywords : null,
          classifications: classifications ? classifications : null,
          services: services ? services : null,
        },
        null,
        dispatch,
        getState
      );
    } catch (e) {
      LogError(
        `Error fetching summary statistics for custom date range ${startDate} to ${endDate}`,
        e
      );
      dispatch(
        setDataLeakClassifiedScanResults(
          false,
          "custom",
          {
            errorText: `Error fetching classified search results for the selected period ${startDate} to ${endDate}.`,
            actionText: "Try again",
            actionOnClick: () =>
              dispatch(
                fetchDataLeakClassifiedResultsForOrgByDateRange(
                  true,
                  startDate,
                  endDate,
                  filters
                )
              ),
          },
          null
        )
      );
      return;
    }

    if (!json) {
      dispatch(
        setDataLeakClassifiedScanResults(
          false,
          "custom",
          { errorText: "no data" },
          null
        )
      );
      return;
    }

    dispatch(
      setDataLeakClassifiedScanResults(false, "custom", null, {
        scans: json.scans,
      })
    );
  };
};

export const setDataLeaksScanResultsCustomDateRange = (
  startDate: string,
  endDate: string
) => {
  return {
    type: SET_DATA_LEAKS_SCANRESULTS_CUSTOM_DATE_RANGE,
    data: {
      startDate: startDate,
      endDate: endDate,
    },
  };
};

export const setDataLeaksSummaryTab = (
  tabType: DataLeaksGroupingType,
  tab: string
) => {
  return {
    type: SET_DATA_LEAKS_SUMMARY_TAB,
    data: {
      tabType: tabType,
      tab: tab,
    },
  };
};
