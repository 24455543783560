import { FC, useEffect, useState } from "react";

import VendorSurveyList from "../components/VendorSurveyList";
import Button from "../../_common/components/core/Button";
import PageHeader from "../../_common/components/PageHeader";
import { SurveyCount } from "../../_common/types/redux";
import { History } from "history";
import {
  SurveyUsageType,
  SurveyUsageTypeDisplayName,
} from "../../_common/types/surveyTypes";
import LoadingBanner from "../../_common/components/core/LoadingBanner";
import PivotTabs, { PivotTab } from "../../_common/components/PivotTabs";
import ReportCard from "../../_common/components/ReportCard";
import "../style/VendorSurveys.scss";
import { useAppSelector } from "../../_common/types/reduxHooks";
import TrackedButton from "../../_common/components/TrackedButton";
import ImportQuestionnaireModal from "../components/ImportQuestionnaireModal";
import { useModalV2 } from "../../_common/components/ModalV2";
import { DefaultRouteProps } from "../../_common/types/router";
import {
  useBasicPermissions,
  UserReadSurveyImportExport,
  UserWriteSurveyImportExport,
} from "../../_common/permissions";
import SurveyImportAPI from "../api/surveyImportAPI";
import ImportedSurveyList from "../components/ImportedSurveyList";
import { useLocationStateUpdater } from "../../_common/hooks";
import { SidePopupV2 } from "../../_common/components/DismissablePopup";
import { trackEvent } from "../../_common/tracking";

type vendorSurveysTab = "security" | "relationship" | "imported";

export interface VendorSurveysRouteState {
  currentTab?: vendorSurveysTab;
  openImportModal?: boolean;
}
interface IVendorSurveysProps
  extends DefaultRouteProps<Record<string, never>, VendorSurveysRouteState> {
  hiddenTabs?: vendorSurveysTab[];
}

const VendorSurveys: FC<IVendorSurveysProps> = ({
  history,
  location,
  hiddenTabs,
}) => {
  const perms = useBasicPermissions();
  const vendorAppSurveys = useAppSelector(
    (state) => state.vendorPortal.vendorAppSurveys
  );
  const loading = vendorAppSurveys?.loading ?? true;
  const surveyCounts = vendorAppSurveys?.surveyCounts;

  // Grab imported questionnaire counts if this org/user has permission
  const canViewImportedSurveys =
    !!perms.userPermissions[UserReadSurveyImportExport];
  const canEditImportedSurveys =
    canViewImportedSurveys &&
    !!perms.userPermissions[UserWriteSurveyImportExport];
  const { data: importedSurveysData } =
    SurveyImportAPI.useGetImportedSurveysListQuery(undefined, {
      skip: !canViewImportedSurveys,
    });

  const [openImportQuestionnaireModal, importQuestionnaireModal] = useModalV2(
    ImportQuestionnaireModal
  );

  useEffect(() => {
    if (location.state?.openImportModal) {
      openImportQuestionnaireModal({});
    }
  }, []);

  const [currentTab, setCurrentTab] = useState<vendorSurveysTab>(
    location.state?.currentTab ?? "security"
  );
  useLocationStateUpdater({
    currentTab,
  });

  useEffect(() => {
    if (!surveyCounts) {
      return;
    }

    if (
      (currentTab === "security" &&
        (surveyCounts.security?.total ?? 0) === 0) ||
      (currentTab === "relationship" &&
        (surveyCounts.relationship?.total ?? 0) === 0) ||
      hiddenTabs?.includes(currentTab)
    ) {
      // The current tab is invalid - we should switch to another default one
      if (
        (surveyCounts.security?.total ?? 0) > 0 &&
        !hiddenTabs?.includes("security")
      ) {
        setCurrentTab("security");
      } else if (
        (surveyCounts.relationship?.total ?? 0) > 0 &&
        !hiddenTabs?.includes("relationship")
      ) {
        setCurrentTab("relationship");
      } else if (canViewImportedSurveys && !hiddenTabs?.includes("imported")) {
        setCurrentTab("imported");
      }
    }
  }, [canViewImportedSurveys, currentTab, surveyCounts]);

  useEffect(() => {
    if (currentTab === "imported") {
      trackEvent("QuestionnaireImportExport_TabViewed");
    }
  }, [currentTab]);

  return (
    <div id="vendor_surveys_view">
      <PageHeader
        history={history}
        title="Answer Questionnaires"
        infoSectionPageKey="infoSection_vendorriskportalAttestations"
        infoSection={
          currentTab === "imported" ? (
            <p>
              Import any questionnaire you&apos;ve been sent in Excel format and
              take advantage of UpGuard tools like AI Autofill to complete it
              quickly. Then export it out in .xlsx or .pdf format.
            </p>
          ) : (
            <p>
              Complete the assigned questionnaires to help establish your
              security posture.
            </p>
          )
        }
        infoSectionButtons={
          <Button
            tertiary
            onClick={() =>
              window.open(
                currentTab === "imported"
                  ? "https://help.upguard.com/en/articles/9034980-how-to-import-and-complete-security-questionnaires"
                  : "https://help.upguard.com/en/articles/4340740-how-to-answer-a-security-questionnaire-with-upguard"
              )
            }
          >
            Learn more about completing questionnaires{" "}
            <div className="cr-icon-arrow-right" />
          </Button>
        }
        rightSection={
          <>
            {canEditImportedSurveys && !hiddenTabs?.includes("imported") && (
              <SidePopupV2 text="Answer imported questionnaires using UpGuard's Autofill tools.">
                <TrackedButton
                  filledPrimary
                  className={"import-questionnaire-button"}
                  eventName={"QuestionnaireImportExport_ImportButtonClicked"}
                  onClick={() => {
                    openImportQuestionnaireModal({});
                  }}
                >
                  <div className="cr-icon-export" />
                  Import questionnaire
                </TrackedButton>
              </SidePopupV2>
            )}
            {importQuestionnaireModal}
          </>
        }
      />
      {loading && <LoadingBanner />}
      {!loading && (
        <PivotTabs
          hideSingleTabButton
          selectedTab={currentTab}
          onTabChange={(newTab) => {
            setCurrentTab(newTab as vendorSurveysTab);
          }}
        >
          {surveyCounts?.security !== undefined &&
            !hiddenTabs?.includes("security") &&
            getSurveyUsageTypeTab(
              history,
              SurveyUsageType.Security,
              surveyCounts.security
            )}
          {surveyCounts?.relationship !== undefined &&
            !hiddenTabs?.includes("relationship") &&
            getSurveyUsageTypeTab(
              history,
              SurveyUsageType.Relationship,
              surveyCounts.relationship
            )}
          {canViewImportedSurveys && !hiddenTabs?.includes("imported") && (
            <PivotTab
              headerText={`Imported Questionnaires${
                importedSurveysData?.surveys &&
                importedSurveysData.surveys.length > 0
                  ? ` (${importedSurveysData.surveys.length})`
                  : ""
              }`}
              id="imported"
            >
              <ReportCard newStyles>
                <div className={"header"}>Imported Questionnaires</div>
                <ImportedSurveyList />
              </ReportCard>
            </PivotTab>
          )}
        </PivotTabs>
      )}
    </div>
  );
};

const getSurveyUsageTypeTab = (
  history: History,
  usageType: SurveyUsageType,
  surveyCount: SurveyCount
) => {
  let headerText = SurveyUsageTypeDisplayName[usageType];
  let tabText = headerText;

  if (surveyCount.total) {
    headerText += ` assigned to you (${surveyCount.total})`;
    tabText += ` (${surveyCount.total})`;
  }

  return (
    <PivotTab headerText={tabText} id={usageType}>
      <ReportCard newStyles>
        <div className={"header"}>{headerText}</div>
        <VendorSurveyList
          history={history}
          surveyIds={surveyCount.surveyIds}
          showVendorName={usageType === SurveyUsageType.Relationship}
        />
      </ReportCard>
    </PivotTab>
  );
};

export default VendorSurveys;
