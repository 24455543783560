import { FC } from "react";

import "./AppVendorMonitored.scss";
import FilledIcon from "../../_common/components/core/FilledIcon";
import { useHistory } from "react-router-dom";
import { vendorSummaryUrl } from "../UserBaseAppRouter";

export interface AppVendorMonitoredProps {
  isMonitored: boolean;
  showText: boolean;
  vendorName?: string;
  vendorId?: number | null;
}
const AppVendorMonitored: FC<AppVendorMonitoredProps> = ({
  vendorName,
  vendorId,
  isMonitored,
  showText,
}) => {
  const history = useHistory();

  const tickVersion = isMonitored ? (
    <div className="monitored">
      <FilledIcon iconClass="cr-icon-check" />
    </div>
  ) : (
    <div className="not-monitored">
      <FilledIcon iconClass="icon-x" />
    </div>
  );

  // If we are showing the text, and the vendor is monitored we want to make the vendor name clickable
  const textVersion = isMonitored ? (
    <span
      className={"vendor-link"}
      onClick={() => {
        if (vendorId) {
          history.push(vendorSummaryUrl(vendorId), {
            backContext: {
              backTo: history.location.pathname,
              backToText: `Back to application`,
            },
          });
        }
      }}
    >
      {vendorName ?? "-"}
    </span>
  ) : (
    <span> - </span>
  );

  return (
    <div className="app-vendor-monitored">
      {showText ? textVersion : tickVersion}
    </div>
  );
};

export default AppVendorMonitored;
