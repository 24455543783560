import { FC } from "react";
import InfoTable, {
  InfoTableRow,
  InfoTableStyling,
} from "../../../_common/components/InfoTable";
import {
  cloudscanSeverityNumberToText,
  severityMap,
} from "../../../_common/helpers";
import { SidePopupV2 } from "../../../_common/components/DismissablePopup";
import { ApproverType, ExpireType } from "./BreachSightWaiverDetailsStep";
import PillLabel from "../PillLabel";
import { LabelColor } from "../../../_common/types/label";
import {
  VendorSummaryRisk,
  VendorSummaryRiskType,
} from "../../../_common/types/vendorSummary";
import moment from "moment";
import { useAppSelector } from "../../../_common/types/reduxHooks";
import { AssetSelection } from "./SelectRiskTable";
import "./BreachSightWaiverConfirmStep.scss";

interface BreachSightWaiverConfirmStepProps {
  selectedRisk?: VendorSummaryRisk;
  assetSelection: AssetSelection;
  approverType: ApproverType;
  approverEmail: string;
  justification: string;
  canUsePublicWaivers: boolean;
  isPublic: boolean;
  expireType: ExpireType;
  expireDate: string;
}

const BreachSightWaiverConfirmStep: FC<BreachSightWaiverConfirmStepProps> = ({
  selectedRisk,
  assetSelection,
  approverType,
  approverEmail,
  justification,
  canUsePublicWaivers,
  isPublic,
  expireType,
  expireDate,
}) => {
  const currentUserEmail = useAppSelector(
    (state) => state.common.userData.emailAddress
  );

  const getWaivedRiskDisplay = () => {
    return (
      <div className={"risk-to-waive"}>
        {
          severityMap[cloudscanSeverityNumberToText(selectedRisk?.severity)]
            .icon
        }
        {selectedRisk?.title}
        {selectedRisk?.riskType === VendorSummaryRiskType.SaaS ? (
          <span className={"category-title"}>
            {" "}
            {selectedRisk?.categoryTitle}
          </span>
        ) : (
          <></>
        )}
      </div>
    );
  };

  const getWaivedAssetDisplay = () => {
    switch (selectedRisk?.riskType) {
      case VendorSummaryRiskType.Cloudscan:
        if (assetSelection.hostnameSelection.includeFuture) {
          return <>All domains & IPs (including future detections)</>;
        } else {
          return (
            <SidePopupV2
              text={assetSelection.hostnameSelection.selected.join(", ")}
            >
              <span>
                {assetSelection.hostnameSelection.selected.length}{" "}
                {assetSelection.hostnameSelection.selected.length === 1
                  ? "domain/IP"
                  : "domains & IPs"}
              </span>
            </SidePopupV2>
          );
        }
      case VendorSummaryRiskType.AppguardPackageVuln:
        // TODO fix for manifest locations
        return (
          <SidePopupV2
            text={assetSelection.manifestSelection?.selectedUuids.join(", ")}
          >
            <span>
              {assetSelection.manifestSelection?.selectedUuids.length}{" "}
              {assetSelection.manifestSelection?.selectedUuids.length === 1
                ? "software inventory"
                : "software inventories"}
            </span>
          </SidePopupV2>
        );
      case VendorSummaryRiskType.AppguardRepoConfig:
        // TODO fix for repo names
        return (
          <SidePopupV2
            text={assetSelection.repositorySelection?.selectedUuids.join(", ")}
          >
            <span>
              {assetSelection.repositorySelection?.selectedUuids.length}{" "}
              {assetSelection.repositorySelection?.selectedUuids.length === 1
                ? "repository"
                : "repositories"}
            </span>
          </SidePopupV2>
        );
      case VendorSummaryRiskType.SaaS:
        return (
          <span>
            {assetSelection.saasSelection?.selectedUuids.length}{" "}
            {assetSelection.saasSelection?.selectedUuids.length === 1
              ? "user"
              : "users"}
          </span>
        );
      default:
        throw new Error(`unsupported risk type: ${selectedRisk?.riskType}`);
    }
  };

  return (
    <InfoTable
      bordered
      styling={InfoTableStyling.New}
      className={"breachsight-waiver-confirm-step"}
    >
      <InfoTableRow
        label={"Risk to be waived"}
        value={getWaivedRiskDisplay()}
      />
      <InfoTableRow label={"Waived for"} value={getWaivedAssetDisplay()} />
      <InfoTableRow
        label={"To be approved by"}
        value={
          approverType === ApproverType.Self ? (
            <>You ({currentUserEmail})</>
          ) : (
            approverEmail
          )
        }
      />
      <InfoTableRow label={"Justification"} value={justification} />
      {canUsePublicWaivers && (
        <InfoTableRow
          label={"Visibility"}
          value={
            <>
              {isPublic && (
                <>
                  <PillLabel color={LabelColor.Fuchsia}>Public</PillLabel>
                  Anyone viewing your page in Vendor Risk will see your waiver
                  and the justification you’ve provided.
                </>
              )}
              {!isPublic && (
                <>
                  <PillLabel>Private</PillLabel>
                  Only people in your organization can see your waiver and the
                  justification you’ve provided.
                </>
              )}
            </>
          }
        />
      )}
      <InfoTableRow
        label={"Expires"}
        value={
          expireType === ExpireType.Never ? (
            <>Never</>
          ) : (
            moment(expireDate, "YYYY-MM-DD").format("MMM Do YYYY")
          )
        }
      />
    </InfoTable>
  );
};

export default BreachSightWaiverConfirmStep;
