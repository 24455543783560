import { FormEventHandler, useCallback, useMemo, useState } from "react";
import Button from "../../../_common/components/core/Button";

import "../../styles/ImpersonateOrgModal.scss";
import {
  closeModal,
  impersonateUserOrg,
} from "../../../_common/reducers/commonActions";
import InfoBanner, {
  BannerType,
} from "../../../vendorrisk/components/InfoBanner";
import { useDefaultHistory } from "../../../_common/types/router";
import { useAppDispatch } from "../../../_common/types/reduxHooks";
import ImpersonateUserSelect, {
  ImpersonateUserSelectProps,
} from "../ImpersonateUserSelect";
import { AdminAPI } from "../../../_common/api/adminAPI";
import LoadingBanner from "../../../_common/components/core/LoadingBanner";
import { OrgAccessImpersonateUser } from "../../../_common/permissions";
import { organisationAccountType } from "../../../_common/types/organisations";

export const ImpersonateOrgModalName = "ImpersonateOrgModal";

export interface ImpersonateOrgModalProps {
  modalData: {
    orgId: number;
    orgName: string;
    accountType: organisationAccountType;
  };
}

const reasons = [
  "Account Configuration",
  "Audit",
  "Customer Meeting",
  "Feature",
  "Issue Investigation",
  "Other",
];

const ImpersonateOrgModal = ({ modalData }: ImpersonateOrgModalProps) => {
  const dispatch = useAppDispatch();
  const history = useDefaultHistory();
  const [userId, setUserId] = useState<number | undefined>();
  const [reason, setReason] = useState(reasons[0]);
  const [customReason, setCustomReason] = useState<string>();
  const { currentData: { features } = {}, isFetching } =
    AdminAPI.useGetFeatureFlagsV1Query(
      {
        orgId: modalData.orgId,
      },
      {
        // Always refetch this when we open the modal
        // just in case the dev toggle gets flipped
        // or another org is chosen to impersonate
        refetchOnMountOrArgChange: true,
      }
    );

  const onSubmit = useCallback<FormEventHandler>(
    (e) => {
      e.preventDefault();
      const actualReason = reason === "Other" ? customReason : reason;

      dispatch(impersonateUserOrg(modalData.orgId, actualReason, userId)).then(
        (currentOrgRoles) => {
          dispatch(closeModal());
          if (currentOrgRoles.includes("Distributor")) {
            history.push("/distributor/accounts");
          } else {
            history.push("/");
          }
        }
      );
    },
    [reason, customReason, userId]
  );

  const onSelectUserToImpersonate = useCallback<
    ImpersonateUserSelectProps["onSelect"]
  >((userId) => {
    setUserId(userId);
  }, []);

  const canImpersonateUser = useMemo(
    () =>
      modalData.accountType === organisationAccountType.paid &&
      features?.some((f) => f.name === OrgAccessImpersonateUser && f.enabled),
    [isFetching, features, modalData.accountType]
  );

  return (
    <div className="modal-content" id="impersonate-org-modal">
      <h2>Log in to {modalData.orgName}</h2>
      <p>
        You are about to log into a customer&apos;s account. Are you sure you
        want to proceed?
      </p>
      <InfoBanner
        type={BannerType.WARNING}
        message="REMEMBER: this action AND your justification will be visible in the customer's audit log."
      />
      <br />
      <form onSubmit={onSubmit}>
        <label htmlFor="reason">Please provide a justification</label>
        <select
          className="select-reason"
          name="reason"
          value={reason}
          onChange={(e) => setReason(e.target.value)}
        >
          {reasons.map((r) => (
            <option value={r} key={r}>
              {r}
            </option>
          ))}
        </select>
        {reason === "Other" && (
          <input
            className="custom"
            type="text"
            value={customReason}
            onChange={(e) => setCustomReason(e.target.value)}
            placeholder="Detail your justification here"
          />
        )}

        {isFetching ? (
          <LoadingBanner />
        ) : (
          canImpersonateUser && (
            <>
              <label htmlFor="select-user">
                (Optional) Copy permissions from a user
              </label>
              <ImpersonateUserSelect
                className="select-user"
                name="select-user"
                orgId={modalData.orgId}
                value={userId}
                onSelect={onSelectUserToImpersonate}
              />
            </>
          )
        )}

        <div className="btn-group">
          <Button tertiary onClick={() => dispatch(closeModal())}>
            Cancel
          </Button>
          <Button
            type="submit"
            primary
            disabled={reason === "Other" && !customReason}
          >
            Log in
          </Button>
        </div>
      </form>
    </div>
  );
};

export default ImpersonateOrgModal;
