import { FC, useState } from "react";
import { OptionType, SelectV2 } from "../../../_common/components/SelectV2";
import { StylesConfig, ValueType } from "react-select";
import { SlidePanelSection } from "./SlidePanelSection";
import ColorCheckbox from "../ColorCheckbox";
import Icon from "../../../_common/components/core/Icon";
import LabelList from "../LabelList";
import { SidePopupV2 } from "../../../_common/components/DismissablePopup";

const supportedReasons = [
  { id: "owned_by_us", name: "Owned By Us" },
  { id: "risk_accepted", name: "Risk Accepted" },
  { id: "not_risk", name: "Legitimate Website" },
];

interface ITyposquatReasonsFilterProps {
  selectedOptions: string[];
  onChange: (changeObj: {
    selectedOptions?: string[];
    doNotMatch?: boolean;
  }) => void;
  doNotMatch?: boolean;
  title?: string;
  startExpanded?: boolean;
}

const TyposquatReasonsFilter: FC<ITyposquatReasonsFilterProps> = ({
  selectedOptions,
  onChange,
  doNotMatch = false,
  title = "Filter by ignored reason",
  startExpanded = false,
}) => {
  const [expanded, setExpanded] = useState(
    startExpanded || selectedOptions.length > 0
  );

  const selectOnChange = (selectedOptions: ValueType<OptionType, true>) => {
    const changeObj = {
      selectedOptions: selectedOptions
        ? selectedOptions.map(({ value }) => value as string)
        : [],
    };
    onChange(changeObj);
  };

  const options: OptionType[] = supportedReasons
    .map(({ id, name }) => ({
      value: id,
      label: name,
    }))
    .sort((a, b) => {
      return a.label.toLowerCase().localeCompare(b.label.toLowerCase());
    });

  const selectValue = [
    ...selectedOptions.map(
      (id) => options.find(({ value }) => value === id) as OptionType
    ),
  ];

  const selectedReasons = selectValue.map(({ value }) => ({
    ...[...supportedReasons].find((label) => value === label.id),
    removeable: true,
    large: true,
    constrained: true,
    onRemoveClick: () =>
      selectOnChange(selectValue.filter((option) => value !== option.value)),
  }));

  const customStyles: StylesConfig<OptionType, true> = {
    option: (provided, _state) => {
      return { ...provided };
    },
  };

  const activeRadioButton = doNotMatch ? "not" : "any";

  return (
    <SlidePanelSection
      title={title}
      expanded={expanded}
      toggleExpand={() => setExpanded(!expanded)}
    >
      <div className="filter-label-options">
        <div className="label-filter-checkbox">
          <ColorCheckbox
            radio
            label="Match Any"
            helpPopup="Filter by matching any of the selected reasons"
            onClick={() => onChange({ doNotMatch: false })}
            checked={activeRadioButton === "any"}
          />
          <SidePopupV2
            className="help-icon"
            text="Show typosquats that include any of these specific reasons"
            position={"left"}
          >
            <Icon name="info" />
          </SidePopupV2>
        </div>
        <div className="label-filter-checkbox">
          <ColorCheckbox
            radio
            label="Do Not Include"
            onClick={() => onChange({ doNotMatch: true })}
            checked={activeRadioButton === "not"}
          />
          <SidePopupV2
            className="help-icon"
            text="Show typosquats that do not include these specific reasons"
            position={"left"}
          >
            <Icon name="info" />
          </SidePopupV2>
        </div>
      </div>
      <SelectV2
        placeholder="Type to search reasons"
        options={options}
        value={selectValue}
        onChange={selectOnChange}
        controlShouldRenderValue={false}
        captureMenuScroll={false}
        isMulti
        styles={customStyles}
      />
      <LabelList labels={selectedReasons} />
    </SlidePanelSection>
  );
};

export default TyposquatReasonsFilter;
