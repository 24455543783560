import ModalV2, { BaseModalProps } from "../../../_common/components/ModalV2";
import { FC, useEffect, useMemo, useState } from "react";
import Button from "../../../_common/components/core/Button";
import TextField from "../../../_common/components/TextField";
import { DefaultThunkDispatchProp } from "../../../_common/types/redux";
import {
  createDomainPortfolio,
  createVendorPortfolio,
  fetchDomainPortfolios,
  fetchVendorPortfolios,
  PortfolioType,
} from "../../reducers/portfolios.actions";
import {
  addDefaultUnknownErrorAlert,
  addDefaultWarningAlert,
} from "../../../_common/reducers/messageAlerts.actions";

import "../../style/components/portfolios/CreateNewPortfolioModal.scss";
import { appConnect } from "../../../_common/types/reduxHooks";
import { validatePortfolios, portfolioNameMaxLen } from "./helpers";

interface ICreateNewPortfolioModalOwnProps extends BaseModalProps {
  portfolioType: PortfolioType;
}

type ICreateNewPortfolioModalProps = ICreateNewPortfolioModalOwnProps &
  DefaultThunkDispatchProp;

const CreateNewPortfolioModal: FC<ICreateNewPortfolioModalProps> = ({
  active,
  onClose,
  dispatch,
  portfolioType,
}) => {
  const [portfolioName, setPortfolioName] = useState("");
  const [saving, setSaving] = useState(false);

  // Reset state on active change
  useEffect(() => {
    setPortfolioName("");
    setSaving(false);
  }, [active]);

  const validationErrors = useMemo(
    () =>
      validatePortfolios({
        id: -1,
        isDefault: false,
        numItems: 0,
        name: portfolioName,
        type: portfolioType,
      }),
    [portfolioName, portfolioType]
  );

  const onSave = async () => {
    if (!portfolioName) {
      return;
    }

    try {
      setSaving(true);
      if (portfolioType === PortfolioType.Vendor) {
        await dispatch(createVendorPortfolio(portfolioName));
        await dispatch(fetchVendorPortfolios(true));
      } else if (portfolioType === PortfolioType.Domain) {
        await dispatch(createDomainPortfolio(portfolioName));
        await dispatch(fetchDomainPortfolios(true));
      }
      onClose();
    } catch (e: any) {
      setSaving(false);
      if (e.response && e.response.status === 422) {
        dispatch(
          addDefaultWarningAlert("Could not create portfolio. " + e.json.error)
        );
      } else {
        console.error(e);
        dispatch(
          addDefaultUnknownErrorAlert(
            "Error creating new portfolio. Please contact UpGuard Support."
          )
        );
      }
    }
  };

  return (
    <ModalV2
      active={active}
      onClose={onClose}
      className="create-portfolio-modal"
      headerContent="Create new portfolio"
      footerContent={
        <>
          <Button tertiary onClick={onClose} disabled={saving}>
            Cancel
          </Button>
          <Button
            primary
            onClick={onSave}
            loading={saving}
            disabled={saving || validationErrors.length !== 0}
          >
            Create portfolio
          </Button>
        </>
      }
    >
      <p>
        Enter a name for your new portfolio below. You&apos;ll be able to edit
        this later in Settings.
      </p>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          onSave();
        }}
      >
        <TextField
          placeholder="Portfolio name"
          value={portfolioName}
          onChanged={setPortfolioName}
          maxLength={portfolioNameMaxLen}
        />
      </form>
    </ModalV2>
  );
};

export default appConnect()(CreateNewPortfolioModal);
