import { DefaultAction, DefaultThunkDispatch } from "../../_common/types/redux";
import { IAttachment, IAttachmentGroup } from "../../_common/types/attachment";
import { DefaultRootState } from "react-redux";
import { FetchCyberRiskUrl, ResponseError } from "../../_common/api";
import { AnswersForNodes, getAnswersFlattened } from "../surveyViewer.helpers";
import { LogError } from "../../_common/helpers";
import { Answers } from "../../vendorrisk/reducers/questionnaireAnswers.actions";
import ContentLibraryAPI, {
  contentLibraryDocumentCacheTag,
  contentLibraryDocumentHistoryCacheTag,
} from "../../contentlibrary/api/contentLibraryAPI";
import SurveyImportAPI, {
  SurveyImportCacheTag,
} from "../../vendor_portal/api/surveyImportAPI";
import { FetchGptAutofillStatus } from "./autofill.actions";

interface UploadSurveyFileV1Resp {
  status: string;
  attachment: IAttachment;
}

export const uploadSurveyFile = (
  surveyId: number,
  questionId: string,
  file: File,
  isPublicSurvey: boolean,
  questionNumber: string,
  questionText: string
) => {
  return async (
    dispatch: DefaultThunkDispatch,
    getState: () => DefaultRootState
  ): Promise<UploadSurveyFileV1Resp> => {
    try {
      const data = new FormData();
      data.append("file", file);

      const resp = await FetchCyberRiskUrl<UploadSurveyFileV1Resp>(
        "survey/attachment/v1",
        {
          survey_id: surveyId,
          attachment_name: questionId,
          question_number: questionNumber,
          question_text: questionText,
          public: isPublicSurvey,
        },
        {
          body: data,
          method: "POST",
        },
        dispatch,
        getState,
        undefined,
        undefined
      );

      if (resp.status !== "OK") {
        throw new Error(`Non-OK status received: ${resp.status}`);
      }
      return resp;
    } catch (e) {
      console.error(e);
      throw e;
    }
  };
};

interface UpdateSurveyAnswersV1Resp {
  status: string;
  lockedBy: string;
}

interface UpdateSurveyAnswersV1Body {
  surveyId: number;
  share: boolean;
  partial: boolean;
  answers: string;
  createRisksOnNoShare: boolean;
}

export const updateSurveyAnswers = (
  surveyId: number,
  answers: AnswersForNodes,
  share: boolean,
  partial: boolean,
  isPublicSurvey: boolean,
  // we arent sharing the answers just yet, but we'd like the risks to be created for this answer set anyway
  // motivation: we require the risks to be shown in the survey details after autofill, but before the vendor
  // submits the thing.
  createRisksOnNoShare = false
) => {
  return async (
    dispatch: DefaultThunkDispatch,
    getState: () => DefaultRootState
  ): Promise<UpdateSurveyAnswersV1Resp> => {
    try {
      const resp = await FetchCyberRiskUrl<UpdateSurveyAnswersV1Resp>(
        isPublicSurvey ? "prefilledsurvey/v1" : "survey/v1",
        {},
        {
          method: "PUT",
          body: JSON.stringify({
            surveyId,
            share,
            partial,
            createRisksOnNoShare,
            answers: JSON.stringify(getAnswersFlattened(answers)),
          } as UpdateSurveyAnswersV1Body),
        },
        dispatch,
        getState,
        undefined,
        undefined
      );

      if (resp.status !== "OK" && resp.status !== "LOCKED") {
        throw new Error(`Non-OK status received: ${resp.status}`);
      }

      if (share) {
        // Reset any cached data in the content library in case documents were attached
        dispatch(
          ContentLibraryAPI.util.invalidateTags([
            contentLibraryDocumentCacheTag,
            contentLibraryDocumentHistoryCacheTag,
          ])
        );

        // And reset the imported surveys cache
        dispatch(
          SurveyImportAPI.util.invalidateTags([
            SurveyImportCacheTag.ImportedSurveys,
          ])
        );
      }

      return resp;
    } catch (e) {
      console.error(e);
      throw e;
    }
  };
};

export interface SurveyAttachmentsV1Response {
  attachmentGroups: IAttachmentGroup[];
  readOnly: boolean;
  status: string;
}

// Deprecated - remove when required attachments no longer needed
export const fetchSurveyAttachments = (
  surveyId: number,
  isPublicSurvey: boolean
) => {
  return async (
    dispatch: DefaultThunkDispatch,
    getState: () => DefaultRootState
  ): Promise<SurveyAttachmentsV1Response> => {
    let resp: SurveyAttachmentsV1Response;
    try {
      resp = await FetchCyberRiskUrl(
        "survey/attachmentlist/v1",
        {
          survey_id: surveyId,
          public: isPublicSurvey,
        },
        undefined,
        dispatch,
        getState
      );
    } catch (e) {
      LogError(`Error fetching attachment list`, e);
      throw e;
    }

    return resp;
  };
};

// Deprecated - relates to old required attachments, which can be removed once everything is migrated over.
export const updateSurveyFileDescription = (
  surveyId: number,
  gcsObjectName: string,
  groupName: string,
  description: string,
  isPublicSurvey: boolean
) => {
  return async (
    dispatch: DefaultThunkDispatch,
    getState: () => DefaultRootState
  ): Promise<any> => {
    try {
      await FetchCyberRiskUrl(
        "survey/attachment/v1",
        {
          survey_id: surveyId,
          object: gcsObjectName,
          attachment_name: groupName,
          description: description,
          public: isPublicSurvey,
        },
        {
          method: "PUT",
        },
        dispatch,
        getState,
        undefined,
        undefined
      );
    } catch (e) {
      console.error(e);
      throw e;
    }
  };
};

export const deleteSurveyFile = (
  surveyId: number,
  gcsObjectName: string,
  groupName: string,
  isPublicSurvey: boolean
) => {
  return async (
    dispatch: DefaultThunkDispatch,
    getState: () => DefaultRootState
  ): Promise<any> => {
    try {
      await FetchCyberRiskUrl(
        "survey/attachment/v1",
        {
          survey_id: surveyId,
          object: gcsObjectName,
          attachment_name: groupName,
          public: isPublicSurvey,
        },
        {
          method: "DELETE",
        },
        dispatch,
        getState,
        undefined,
        undefined
      );
    } catch (e) {
      console.error(e);
      throw e;
    }
  };
};

export const attachExistingFileToSurvey = (
  surveyId: number,
  isPublic: boolean,
  originalSurveyId: number,
  originalSurveyIsPublic: boolean,
  gcsObjectName: string,
  attachmentName: string,
  originalAttachmentName: string,
  questionText: string
) => {
  return async (
    dispatch: DefaultThunkDispatch,
    getState: () => DefaultRootState
  ): Promise<void> => {
    try {
      await FetchCyberRiskUrl(
        "survey/attachment/existing/v1",
        {
          survey_id: surveyId,
          public: isPublic,
          original_survey_id: originalSurveyId,
          original_survey_public: originalSurveyIsPublic,
          object: gcsObjectName,
          attachment_name: attachmentName,
          original_attachment_name: originalAttachmentName,
          question_text: questionText,
        },
        {
          method: "POST",
        },
        dispatch,
        getState,
        undefined,
        undefined
      );
    } catch (e) {
      console.error(e);
      throw e;
    }
  };
};

// survey_lock_status_get_v1.go - getSurveyLockStatusV1Resp
export interface SurveyLockState {
  isLocked: boolean;
  lockedBy: string;
}

export const fetchSurveyLockStatus = (surveyId: number, isPublic: boolean) => {
  return async (
    dispatch: DefaultThunkDispatch,
    getState: () => DefaultRootState
  ): Promise<SurveyLockState> => {
    let resp: SurveyLockState;
    try {
      resp = await FetchCyberRiskUrl(
        "survey/lockstatus/v1",
        {
          survey_id: surveyId,
          is_public: isPublic,
        },
        undefined,
        dispatch,
        getState
      );
    } catch (e) {
      LogError(`Error fetching lock status`, e);
      throw e;
    }

    return resp;
  };
};

export const fetchSurveyPreviewAttachments = (
  typeId?: string,
  sectionId?: string
) => {
  return async (
    dispatch: DefaultThunkDispatch,
    getState: () => DefaultRootState
  ): Promise<IAttachmentGroup[]> => {
    let resp: IAttachmentGroup[];
    try {
      resp = await FetchCyberRiskUrl(
        "previewsurveyattachments/v1",
        {
          type_id: typeId,
          section_id: sectionId,
        },
        undefined,
        dispatch,
        getState
      );
    } catch (e) {
      LogError(`Error fetching preview attachment list`, e);
      throw e;
    }

    return resp;
  };
};

export const downloadSurveyExcelFile =
  (surveyID: number, isPublic: boolean): DefaultAction =>
  async (dispatch, getState) => {
    try {
      await FetchCyberRiskUrl(
        "survey/excel/v1",
        {
          survey_id: surveyID,
          is_public: isPublic,
        },
        undefined,
        dispatch,
        getState
      );
    } catch (e) {
      LogError("error downloading survey", e);
      throw e;
    }
  };

export const uploadSurveyExcelFile =
  (surveyID: number, isPublic: boolean, file: File): DefaultAction<Answers> =>
  async (dispatch, getState) => {
    let json: { answers: Answers };

    try {
      const data = new FormData();
      data.append("file", file);

      json = await FetchCyberRiskUrl(
        "survey/excel/v1",
        {
          survey_id: surveyID,
          is_public: isPublic,
        },
        {
          body: data,
          method: "POST",
        },
        dispatch,
        getState
      );

      // Refresh the gpt autofill state too as it was likely invalidated
      await dispatch(FetchGptAutofillStatus(surveyID, isPublic, true));
    } catch (e) {
      const error = e as ResponseError;
      if (
        !error.message.includes("[INCCORECT WORKBOOK]") ||
        !error.message.includes("[WORKBOOK MANAGLED]")
      ) {
        LogError("error with file upload", e);
      }
      throw e;
    }

    return json.answers;
  };

interface EnhanceSurveyAnswerV1Resp {
  answer: string;
}

export const enhanceSurveyAnswer = (
  surveyId: number,
  isPublic: boolean,
  questionId: string,
  answer: string
) => {
  return async (
    dispatch: DefaultThunkDispatch,
    getState: () => DefaultRootState
  ): Promise<EnhanceSurveyAnswerV1Resp> => {
    try {
      return await FetchCyberRiskUrl<EnhanceSurveyAnswerV1Resp>(
        "survey/enhance/v1/",
        {},
        {
          method: "POST",
          body: JSON.stringify({
            surveyId,
            isPublic,
            questionId,
            answer,
          }),
        },
        dispatch,
        getState,
        undefined,
        undefined
      );
    } catch (e) {
      const error = e as ResponseError;
      if (!error.message.includes("[QUOTA EXCEEDED]")) {
        LogError("error with answer enhance", e);
      }
      throw e;
    }
  };
};
