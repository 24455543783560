import {
  applyVendorTiers,
  fetchVendorTiers,
  VendorTier,
} from "../../reducers/vendorTiers.actions";

import Button from "../../../_common/components/core/Button";
import Modal from "../../../_common/components/ModalV2";
import { useEffect, useState } from "react";
import { DefaultThunkDispatchProp } from "../../../_common/types/redux";
import LoadingIcon from "../../../_common/components/core/LoadingIcon";

import "../../style/components/UpdateVendorTierModal.scss";
import InfoBanner, { BannerType } from "../InfoBanner";
import { pluralise } from "../../../_common/helpers";
import VendorTierChoiceSet from "./VendorTierChoiceSet";
import { getVendorWords } from "../../../_common/constants";
import { AssuranceType } from "../../../_common/types/organisations";
import { appConnect } from "../../../_common/types/reduxHooks";
import { ConfettiFullPage } from "../../../_common/components/ConfettiFullPage";
import { setPLGTaskCompleteIfIncomplete } from "../../reducers/plgOnboardingChecklistActions";

interface UpdateVendorTierModalOwnProps {
  active: boolean;
  onClose: () => void;

  vendorIds: number[];
  selectedTier?: number;
  showWarning?: boolean;
  setNewTier?: (tier?: number) => void;
  noBackendSave?: boolean; // don't save to the backend, only update the parent
}

interface UpdateVendorTierModalConnectedProps {
  assuranceType: AssuranceType;
  tiers: VendorTier[];
}

type UpdateVendorTierModalProps = UpdateVendorTierModalOwnProps &
  UpdateVendorTierModalConnectedProps &
  DefaultThunkDispatchProp;

const UpdateVendorTierModal = (props: UpdateVendorTierModalProps) => {
  const [isSaving, setIsSaving] = useState(false);
  const [selectedTier, setSelectedTier] = useState(props.selectedTier ?? 0);
  const [fireConfetti, setFireConfetti] = useState(false);

  // Initial load
  useEffect(() => {
    if (props.tiers.length === 0) {
      props.dispatch(fetchVendorTiers()).catch(() => {
        // Handled elsewhere
      });
    }
  }, []);

  // Respond to prop changes
  useEffect(() => {
    setSelectedTier(props.selectedTier ?? 0);
  }, [props.selectedTier, props.active]);

  const save = () => {
    if (props.noBackendSave) {
      if (props.setNewTier) {
        props.setNewTier(selectedTier ?? undefined);
      }
      props.onClose();
      return;
    }

    setIsSaving(true);

    props
      .dispatch(
        applyVendorTiers(
          {
            vendorIds: props.vendorIds,
            tier: selectedTier || undefined,
          },
          true
        )
      )
      .then(() => {
        props
          .dispatch(setPLGTaskCompleteIfIncomplete("Checklist_VendorRisk_Tier"))
          .then((result) => {
            if (result) {
              setFireConfetti(true);
            }
          });
      })
      .then(() => {
        setIsSaving(false);
      })
      .catch(() => {
        setIsSaving(false);
      });
    props.onClose();
  };

  const vendorWords = getVendorWords(props.assuranceType);

  const headerThis = pluralise(props.vendorIds.length, "this", "these");

  const headerVendorCount =
    props.vendorIds.length > 1 ? ` (${props.vendorIds.length})` : "";

  return (
    <>
      {fireConfetti && <ConfettiFullPage />}
      <Modal
        headerContent={`Select a tier for ${headerThis} ${
          props.vendorIds.length === 1
            ? vendorWords.singular
            : vendorWords.plural
        }${headerVendorCount}`}
        className={"update-vendor-tiers-modal"}
        active={props.active}
        onClose={props.onClose}
        footerContent={
          <>
            <Button
              tertiary
              onClick={() => props.onClose()}
              disabled={isSaving}
            >
              Cancel
            </Button>
            <Button
              filledPrimary
              onClick={save}
              loading={isSaving}
              disabled={isSaving}
            >
              Select
            </Button>
          </>
        }
      >
        <p>
          A key component of a successful third-party risk management program is
          tiering. We highly recommend tiering all your {vendorWords.plural} as
          it makes it easier to align risk management activities based on
          inherent risk.
        </p>
        <p>You can edit the names of the tiers on the Settings page.</p>
        <p>
          <Button
            arrow
            tertiary
            onClick={() => {
              window.open(
                "https://help.upguard.com/en/articles/5489482-how-to-tier-your-vendors-in-upguard"
              );
            }}
          >
            View support article
          </Button>
        </p>
        {props.tiers.length === 0 && <LoadingIcon />}
        <VendorTierChoiceSet
          tiers={props.tiers}
          selectedTeirs={[selectedTier]}
          onSelectionChange={(selected) =>
            setSelectedTier(selected.length > 0 ? selected[0] : 0)
          }
        />
        {props.showWarning && (
          <InfoBanner
            type={BannerType.WARNING}
            message={
              "Selecting a tier will update any previous selections you have made for these vendors"
            }
          />
        )}
      </Modal>
    </>
  );
};

export default appConnect<
  UpdateVendorTierModalConnectedProps,
  never,
  UpdateVendorTierModalOwnProps
>((state) => {
  return {
    assuranceType: state.common.userData.assuranceType,
    tiers: state.cyberRisk.vendorTiers ?? [],
  };
})(UpdateVendorTierModal);
