import { LabelColor } from "./label";
import moment from "moment";

interface timelineEventStatusUpdate {
  text: string;
  importedSurveyText?: string;
  labelColor: LabelColor;
}

export type timelineEventType =
  | "survey"
  | "remediation"
  | "assessment"
  | "service_request";

export const eventToText = (ev: timelineEventType): string => {
  switch (ev) {
    case "assessment":
      return "Risk assessment";
    case "remediation":
      return "Remediation request";
    case "service_request":
      return "Managed assessment";
    case "survey":
      return "Questionnaire";
  }
};

export interface apiTimelineEvent {
  at: string;
  actionText: string;
  userId?: number;
  comment: string;
  statusUpdate?: {
    prevStatus: string | timelineEventStatusUpdate;
    newStatus: string | timelineEventStatusUpdate;
  };
  riskNames?: string[];
  risksAdded?: string[];
  risksRemoved?: string[];
  risksUpdated?: string[];
  usersAdded?: string[];
  usersRemoved?: string[];
  documentsAdded?: string[];
  documentsRemoved?: string[];

  eventType: timelineEventType;
}

export interface ITimelineEvent extends apiTimelineEvent {
  statusUpdate?: {
    prevStatus: timelineEventStatusUpdate;
    newStatus: timelineEventStatusUpdate;
  };
}

// Given an array of objects with a "date" field, sorted by date, group those into objects by day.
export const groupItemsIntoDays = (
  items: ITimelineEvent[]
): {
  date: string;
  items: ITimelineEvent[];
}[] => {
  let curGroup;

  const groups = [];
  for (let i = 0; i < items.length; i++) {
    const item = items[i];
    const mo = moment(item.at);

    if (curGroup && curGroup.date === mo.format("YYYY-MM-DD")) {
      curGroup.items.push(item);
    } else if (curGroup) {
      // We have a new date. Push the current group to groups and reset curGroup
      groups.push(curGroup);
      curGroup = null;
    }

    if (!curGroup) {
      curGroup = {
        date: mo.format("YYYY-MM-DD"),
        items: [item],
      };
    }
  }

  if (curGroup) {
    groups.push(curGroup);
  }

  return groups;
};
