import { FC, useState } from "react";
import ColorCheckbox from "../ColorCheckbox";
import DatePicker from "../../../_common/components/DatePicker";
import SlidePanelSection from "./SlidePanelSection";
import classnames from "classnames";
import moment from "moment/moment";

import "../../style/components/filter/DateFilter.scss";

export const isDateFilterActive = (filterState?: IDateFilterState) => {
  if (
    !filterState ||
    (filterState.between && (!filterState.startDate || !filterState.endDate)) ||
    (filterState.before && !filterState.endDate) ||
    (!filterState.between && !filterState.before && !filterState.startDate)
  ) {
    return false;
  }

  return true;
};

export const dateMatchesFilter = (
  date: string,
  filterState?: IDateFilterState
) => {
  if (!filterState || !isDateFilterActive(filterState)) {
    // No filter applied, should always return true
    return true;
  }

  const dateMo = moment(date);
  const startDateMo = filterState.startDate
    ? moment(filterState.startDate)
    : undefined;
  const endDateMo = filterState.endDate
    ? moment(filterState.endDate)
    : undefined;

  if (filterState.between) {
    return dateMo.isAfter(startDateMo) && dateMo.isBefore(endDateMo);
  } else if (filterState.before) {
    return dateMo.isBefore(endDateMo);
  } else {
    return dateMo.isAfter(startDateMo);
  }
};

export interface IDateFilterState {
  startDate?: string;
  endDate?: string;
  before?: boolean;
  between?: boolean;
}

export interface IDateFilterProps {
  title: string;
  state?: IDateFilterState;
  onChange: (newState: IDateFilterState) => void;
  startExpanded?: boolean;
  noSidePanel?: boolean;
}

const DateFilter: FC<IDateFilterProps> = ({
  title,
  state = {},
  onChange,
  startExpanded = false,
  noSidePanel = false,
}) => {
  const [expanded, setExpanded] = useState(
    startExpanded || !!state.startDate || !!state.endDate
  );
  const activeRadioButton = state.before
    ? "before"
    : state.between
      ? "between"
      : "after";

  const selectedDates = (startDate: string, endDate: string) => {
    const changeObj: IDateFilterState = {
      startDate: startDate ? startDate : undefined,
      endDate: endDate ? endDate : undefined,
      before: state.before,
      between: state.between,
    };

    onChange(changeObj);
  };

  const innerSection = (
    <div className={"date-filter"}>
      <div className={"filter-date-options"}>
        <ColorCheckbox
          radio
          label="Before"
          onClick={() =>
            onChange({
              before: true,
              between: false,
              startDate: undefined,
              endDate: undefined,
            })
          }
          checked={activeRadioButton === "before"}
        />
        <ColorCheckbox
          radio
          label="Between"
          onClick={() =>
            onChange({
              before: false,
              between: true,
              startDate: undefined,
              endDate: undefined,
            })
          }
          checked={activeRadioButton === "between"}
        />
        <ColorCheckbox
          radio
          label="After"
          onClick={() =>
            onChange({
              before: false,
              between: false,
              startDate: undefined,
              endDate: undefined,
            })
          }
          checked={activeRadioButton === "after"}
        />
      </div>
      <div className={"filter-date-options"}>
        {activeRadioButton === "before" && (
          <div className={classnames("date-picker-container", "single")}>
            <DatePicker
              placeholder={"yyyy-mm-dd"}
              onChange={(e: any) => {
                selectedDates(
                  "",
                  e.target.value ? moment(e.target.value).toISOString() : ""
                );
              }}
              value={state.endDate ?? ""}
              canClear
            />
          </div>
        )}
        {activeRadioButton === "after" && (
          <div className={classnames("date-picker-container", "single")}>
            <DatePicker
              placeholder={"yyyy-mm-dd"}
              onChange={(e: any) => {
                selectedDates(
                  e.target.value ? moment(e.target.value).toISOString() : "",
                  ""
                );
              }}
              value={state.startDate ?? ""}
              canClear
            />
          </div>
        )}
        {activeRadioButton === "between" && (
          <>
            <div className={classnames("date-picker-container", "double")}>
              <DatePicker
                placeholder={"yyyy-mm-dd"}
                onChange={(e: any) => {
                  selectedDates(
                    e.target.value ? moment(e.target.value).toISOString() : "",
                    state.endDate ?? ""
                  );
                }}
                value={state.startDate ?? ""}
                max={state.endDate ? state.endDate : undefined}
                canClear
              />
            </div>
            <div className={classnames("date-picker-container", "double")}>
              <DatePicker
                placeholder={"yyyy-mm-dd"}
                onChange={(e: any) => {
                  selectedDates(
                    state.startDate ?? "",
                    e.target.value ? moment(e.target.value).toISOString() : ""
                  );
                }}
                value={state.endDate ?? ""}
                min={state.startDate ? state.startDate : undefined}
                canClear
              />
            </div>
          </>
        )}
      </div>
    </div>
  );

  if (noSidePanel) {
    return innerSection;
  }

  return (
    <SlidePanelSection
      title={title}
      expanded={expanded}
      classname={"filter-date"}
      toggleExpand={() => setExpanded(!expanded)}
    >
      {innerSection}
    </SlidePanelSection>
  );
};

export default DateFilter;
