import { PortfolioType } from "../reducers/portfolios.actions";

export interface RoleFeatures {
  // BreachSight features
  breachsightEnabled: boolean;
  breachsightCoreFeaturesFullAccess: boolean;
  breachsightIdentityEnabled: boolean;
  breachsightTyposquattingEnabled: boolean;

  breachsightPortfolioSpecificAccess: boolean;
  // BreachSight features for specific portfolios. Indexed by portfolio ID.
  breachsightPortfolioSpecificFeatures: Record<
    number,
    BreachSightPortfolioSpecificFeatures
  >;

  // Vendor Risk features
  vendorRiskEnabled: boolean;
  vendorRiskPortfolioSpecificAccess: boolean;

  // Vendor Risk features for specific portfolios. Indexed by portfolio ID.
  vendorRiskPortfolioSpecificFeatures: Record<
    number,
    VendorPortfolioSpecificFeatures
  >;

  // Vendor Risk features for all portfolios
  vendorRiskCoreFeaturesFullAccess: boolean;
  vendorRiskCreateAssessmentEnabled: boolean;

  // Vendor Risk portfolio-agnostic features
  vendorRiskQuestionnaireBuilderEnabled: boolean;

  // CyberResearch features
  cyberResearchEnabled: boolean;
  cyberResearchDataLeaksEnabled: boolean;
  cyberResearchManagedVendorsEnabled: boolean;

  // Vendor portal features
  breachsightSharedProfileEnabled: boolean;
  breachsightSharedProfileAccessManagementEnabled: boolean;
  breachsightSharedProfileSurveyManagementEnabled: boolean;
  contentLibraryEnabled: boolean;
  contentLibraryWrite: boolean;
  surveyImportExportEnabled: boolean;
  surveyImportExportWrite: boolean;
  surveyShareAnswersWithOrg: boolean;

  // UserBase features
  userBaseEnabled: boolean;
  userBaseCoreFeaturesFullAccess: boolean;

  // Collaboration features
  collaborationSurveys: boolean;
}

export interface BreachSightPortfolioSpecificFeatures {
  breachsightCoreFeaturesFullAccess: boolean;
}

export const defaultBreachSightPortfolioSpecificFeatures: BreachSightPortfolioSpecificFeatures =
  {
    breachsightCoreFeaturesFullAccess: false,
  };

export interface VendorPortfolioSpecificFeatures {
  vendorRiskCoreFeaturesFullAccess: boolean;
  vendorRiskCreateAssessmentEnabled: boolean;
}

export const defaultVendorPortfolioSpecificFeatures: VendorPortfolioSpecificFeatures =
  {
    vendorRiskCoreFeaturesFullAccess: false,
    vendorRiskCreateAssessmentEnabled: false,
  };

export interface Role {
  isAdmin: boolean;
  features: RoleFeatures;
}

export interface PortfolioSpecificRole {
  p: number; // portfolio ID
  t?: PortfolioType; // portfolio type - if empty, should default to Vendor
  r: string[]; // roles list
}

export const splitPortfolioSpecificRoles = (
  portfolioSpecificRoles: PortfolioSpecificRole[]
) => {
  const vendorPortfolioSpecificRoles: PortfolioSpecificRole[] = [];
  const domainPortfolioSpecificRoles: PortfolioSpecificRole[] = [];

  for (let i = 0; i < portfolioSpecificRoles.length; i++) {
    switch (portfolioSpecificRoles[i].t) {
      case PortfolioType.Domain:
        domainPortfolioSpecificRoles.push(portfolioSpecificRoles[i]);
        break;
      default:
        // If type is not set, default to vendor
        vendorPortfolioSpecificRoles.push(portfolioSpecificRoles[i]);
    }
  }

  return {
    vendorPortfolioSpecificRoles,
    domainPortfolioSpecificRoles,
  };
};

export type PortfolioSpecificPermissions = Record<number, string[] | undefined>; // Map of portfolioId to permissions
