import { ISurveyListItemResponse, SurveyStatus } from "../../types/survey";
import { ReactNode } from "react";
import DropdownV2, {
  DropdownItem,
  DropdownSeparator,
} from "../core/DropdownV2";

import "../../style/components/SurveyDetailsStatusDropdown.scss";
import {
  useConfirmationModalV2,
  useConfirmationModalV2Props,
} from "../modals/ConfirmationModalV2";
import { DefaultThunkDispatch } from "../../types/redux";
import {
  cancelSurvey as cancelSurveyAction,
  fetchAlertsOrActivityStreamForOrgUser,
  fetchVendorSummaryAndCloudscans,
  fetchVendorSurveyCounts,
  setSurveyArchived,
} from "../../../vendorrisk/reducers/cyberRiskActions";
import { surveyListPageLimit } from "../../../vendorrisk/views/Questionnaires";
import {
  addDefaultSuccessAlert,
  addDefaultUnknownErrorAlert,
  addSimpleErrorAlert,
} from "../../reducers/messageAlerts.actions";
import {
  fetchSurveyDetails,
  fetchSurveyTimeline,
  updateSurveyStatus,
} from "../../reducers/surveyDetails.actions";
import { remediationLocationState } from "../../../vendorrisk/views/RequestRemediationV2";
import { History } from "history";
import { LogError, vendorUrlPrefix } from "../../helpers";
import { refreshEvidenceForLatestAssessment } from "../../../vendorrisk/reducers/vendorAssessment.actions";
import {
  fetchVendorRelationshipSurveys,
  getSurveyListV2,
} from "../../../vendorrisk/reducers/survey.actions";
import { SurveyUsageType } from "../../types/surveyTypes";
import { CreateQuestionnaireBulkLocationState } from "../../../vendorrisk/views/CreateQuestionnaireBulk";
import { CreateRelationshipQuestionnaireLocationState } from "../../../vendorrisk/views/CreateVendorRelationshipQuestionnaire";
import { refreshVendorListsAfterChange } from "../../../vendorrisk/reducers/vendors.actions";
import { invalidateVendorAssessmentDraftsForVendor } from "../../../vendorrisk/reducers/vendorAssessmentAPI";
import { restoreCancelledQuestionnaire } from "../QuestionnaireStatusCard";
import { clearQuestionnaire } from "../../../vendorrisk/reducers/questionnaireAnswers.actions";

export const archiveOrUnarchiveSurvey = (
  dispatch: DefaultThunkDispatch,
  openConfirmationModal: (props: useConfirmationModalV2Props) => void,
  survey: {
    id: number;
    usageType: SurveyUsageType;
    vendorId: number;
    risksInRemediation: number;
  },
  archive: boolean
) => {
  let riskText =
    survey.usageType === SurveyUsageType.Security
      ? "This questionnaire's risks will no longer appear in the summary or contribute to this vendor's score. "
      : "";

  if (!archive) {
    riskText =
      survey.usageType === SurveyUsageType.Security
        ? "This questionnaire's risks will now appear in the summary and contribute to this vendor's score, if it is not cancelled."
        : "This questionnaire will be available in this vendor's profile.";
  }

  openConfirmationModal({
    width: 600,
    title: archive
      ? "Archive this questionnaire"
      : "Unarchive this questionnaire",
    description: archive ? (
      survey.risksInRemediation === 0 ? (
        <p>
          {riskText}If the questionnaire is in progress, it will be cancelled.
        </p>
      ) : (
        <p>
          This questionnaire&apos;s risks will no longer appear in the summary
          or contribute to this vendor&apos;s score. Risks that are part of a
          remediation request will also be marked as archived. If the
          questionnaire is in progress, it will be cancelled and risks that are
          part of a remediation request will be removed from the request.
        </p>
      )
    ) : (
      <p>{riskText}</p>
    ),
    buttonText: archive ? "Archive" : "Unarchive",
    filledPrimaryAction: true,
    cancelText: "Cancel",
    buttonAction: async () => {
      try {
        await dispatch(setSurveyArchived(survey.id, archive));
        // kick off call to update the activity stream
        dispatch(fetchAlertsOrActivityStreamForOrgUser(true, true));
        const proms: Promise<any>[] = [
          dispatch(
            getSurveyListV2(
              true,
              undefined,
              0,
              surveyListPageLimit,
              "date_due",
              "desc",
              !archive
            )
          ),
        ];
        if (survey.vendorId) {
          proms.push(
            dispatch(
              getSurveyListV2(
                true,
                survey.vendorId,
                0,
                surveyListPageLimit,
                "date_due",
                "desc",
                !archive
              )
            )
          );

          proms.push(
            dispatch(refreshEvidenceForLatestAssessment(survey.vendorId))
          );

          proms.push(dispatch(fetchVendorSurveyCounts(survey.vendorId, true)));

          if (survey.usageType === SurveyUsageType.Relationship) {
            proms.push(
              dispatch(
                fetchVendorRelationshipSurveys(
                  survey.vendorId,
                  20,
                  0,
                  undefined,
                  undefined,
                  undefined,
                  false,
                  undefined,
                  undefined
                )
              )
            );
          }
        }

        await Promise.all(proms);

        dispatch(fetchAlertsOrActivityStreamForOrgUser(true, true));
        dispatch(fetchVendorSummaryAndCloudscans(survey.vendorId, true));
      } catch (e: any) {
        dispatch(addSimpleErrorAlert(e.message));
        throw e;
      }

      dispatch(
        addDefaultSuccessAlert(
          archive
            ? "Successfully archived questionnaire."
            : "Successfully unarchived questionnaire"
        )
      );
    },
  });
};

export const cancelSurvey = (
  dispatch: DefaultThunkDispatch,
  openConfirmationModal: (props: useConfirmationModalV2Props) => void,
  survey: {
    id: number;
    vendorId: number;
    risksInRemediation: number;
  }
) =>
  openConfirmationModal({
    width: 600,
    title: "Cancel this questionnaire",
    description:
      survey.risksInRemediation > 1 ? (
        <p>
          There are active remediation requests associated with this
          questionnaire. Cancelling it remove the risks from these remediation
          requests. An email will be sent to the vendor, notifying them of the
          cancellation.
        </p>
      ) : survey.risksInRemediation === 1 ? (
        <p>
          There is an active remediation request associated with this
          questionnaire. Cancelling it will remove the risks from this
          remediation request. An email will be sent to the vendor, notifying
          them of the cancellation.
        </p>
      ) : (
        <p>
          Cancelling a questionnaire will send a notification email to the
          vendor and cancel the current revision.
        </p>
      ),
    buttonText: "Yes, cancel",
    cancelText: "No, keep it",
    dangerousAction: true,
    buttonAction: async () => {
      try {
        await dispatch(cancelSurveyAction(survey.id, survey.vendorId));
      } catch (e) {
        console.error(e);
        dispatch(
          addDefaultUnknownErrorAlert("Error cancelling this questionnaire")
        );
        throw e;
      }

      dispatch(addDefaultSuccessAlert("Questionnaire cancelled"));

      await dispatch(fetchSurveyDetails(survey.id, true));
      dispatch(fetchAlertsOrActivityStreamForOrgUser(true, true));
    },
  });

export const markAsInReview = (
  dispatch: DefaultThunkDispatch,
  openConfirmationModal: (props: useConfirmationModalV2Props) => void,
  survey: {
    id: number;
    vendorId: number;
  },
  isRevert: boolean,
  isManagementAnalystSession: boolean
) =>
  openConfirmationModal({
    width: 600,
    title: "Mark questionnaire as 'In Review'",
    description: (
      <p>
        This will let other users in your account know that the questionnaire is
        being reviewed. Mark it as &quot;Complete&quot; once you&apos;ve
        finished the review, or assign it back to &quot;Awaiting Review&quot; at
        any point.
      </p>
    ),
    buttonText: "Confirm",
    filledPrimaryAction: true,
    cancelText: "Cancel",
    buttonAction: async () => {
      try {
        await dispatch(
          updateSurveyStatus(
            survey.id,
            SurveyStatus.InReview,
            isRevert,
            isManagementAnalystSession,
            survey.vendorId
          )
        );
        await Promise.all([
          dispatch(fetchSurveyDetails(survey.id, true)),
          dispatch(fetchSurveyTimeline(survey.id, true)),
          dispatch(fetchAlertsOrActivityStreamForOrgUser(true, true)),
          dispatch(clearQuestionnaire(survey.id)),
        ]);

        dispatch(addDefaultSuccessAlert("Questionnaire marked as 'In Review'"));
      } catch (e) {
        LogError("error marking as in review", e);
        dispatch(
          addDefaultUnknownErrorAlert(
            "An error occurred updating the questionnaire status"
          )
        );
        throw e;
      }
    },
  });

export const markAsAwaitingReview = (
  dispatch: DefaultThunkDispatch,
  openConfirmationModal: (props: useConfirmationModalV2Props) => void,
  survey: {
    id: number;
    vendorId: number;
  },
  isRevert: boolean,
  isManagementAnalystSession: boolean
) =>
  openConfirmationModal({
    width: 600,
    title: "Set this questionnaire back to 'Awaiting Review'",
    description: (
      <p>
        This will let other users in your account know that the questionnaire is
        awaiting review. Mark it as &quot;Complete&quot; once you&apos;ve
        finished the review, or assign it back to &quot;In Review&quot; at any
        point.
      </p>
    ),
    buttonText: "Confirm",
    filledPrimaryAction: true,
    cancelText: "Cancel",
    buttonAction: async () => {
      try {
        await dispatch(
          updateSurveyStatus(
            survey.id,
            SurveyStatus.AwaitingReview,
            isRevert,
            isManagementAnalystSession,
            survey.vendorId
          )
        );
        await Promise.all([
          dispatch(fetchSurveyDetails(survey.id, true)),
          dispatch(fetchSurveyTimeline(survey.id, true)),
          dispatch(fetchAlertsOrActivityStreamForOrgUser(true, true)),
          dispatch(clearQuestionnaire(survey.id)),
        ]);

        dispatch(
          addDefaultSuccessAlert("Questionnaire set to 'Awaiting Review'")
        );
      } catch (e) {
        LogError("error marking as awaiting review", e);
        dispatch(
          addDefaultUnknownErrorAlert(
            "An error occurred updating the questionnaire status"
          )
        );
        throw e;
      }
    },
  });

export const markAsComplete = (
  dispatch: DefaultThunkDispatch,
  openConfirmationModal: (props: useConfirmationModalV2Props) => void,
  survey: {
    id: number;
    vendorId: number;
  },
  isManagementAnalystSession: boolean
) => {
  openConfirmationModal({
    width: 600,
    title: "Mark questionnaire as 'Complete'",
    description: (
      <p>
        This will mark the questionnaire as completed and remove any remediation
        requests for risks present in the questionnaire.
      </p>
    ),
    buttonText: "Confirm",
    filledPrimaryAction: true,
    cancelText: "Cancel",
    buttonAction: async () => {
      try {
        await dispatch(
          updateSurveyStatus(
            survey.id,
            SurveyStatus.Complete,
            false,
            isManagementAnalystSession,
            survey.vendorId
          )
        );

        const proms: Promise<any>[] = [
          dispatch(fetchSurveyDetails(survey.id, true)),
          dispatch(fetchSurveyTimeline(survey.id, true)),
          dispatch(clearQuestionnaire(survey.id)),
        ];

        // Update activity stream in the background
        dispatch(fetchAlertsOrActivityStreamForOrgUser(true, true));

        if (survey.vendorId) {
          proms.push(
            dispatch(refreshVendorListsAfterChange([survey.vendorId]))
          );

          dispatch(invalidateVendorAssessmentDraftsForVendor(survey.vendorId));
        }

        await Promise.all(proms);

        dispatch(addDefaultSuccessAlert("Questionnaire marked as 'Complete'"));
      } catch (e) {
        LogError("error marking as complete", e);
        dispatch(
          addDefaultUnknownErrorAlert(
            "An error occurred updating the questionnaire status"
          )
        );
        throw e;
      }
    },
  });
};

export const surveyStatusIn = (
  status: SurveyStatus,
  statusIn: SurveyStatus[]
): boolean => statusIn.indexOf(status) > -1;

interface ISurveyDetailsStatusDropdownProps {
  dispatch: DefaultThunkDispatch;
  history: History;
  survey: ISurveyListItemResponse;
  popupItem: ReactNode;
  isManagementAnalystSession: boolean;
  managedOrgId: number;
  disableArchiveUnArchive?: boolean;
  orgHasExtraQuestionnaireStatusChanges?: boolean;
}

// This component provides a dropdown menu for taking actions on a survey.
// It is assumed this is never mounted in the vendor portal, or for a user without write permissions.
const SurveyDetailsStatusDropdown = ({
  dispatch,
  history,
  popupItem,
  survey,
  isManagementAnalystSession,
  managedOrgId,
  disableArchiveUnArchive,
  orgHasExtraQuestionnaireStatusChanges,
}: ISurveyDetailsStatusDropdownProps) => {
  const [openConfirmationModal, confirmationModalComponent] =
    useConfirmationModalV2();

  return (
    <>
      <DropdownV2
        className="survey-details-status-dropdown"
        popupItem={popupItem}
      >
        {survey.archived ? (
          <DropdownItem
            onClick={() =>
              archiveOrUnarchiveSurvey(
                dispatch,
                openConfirmationModal,
                survey,
                false
              )
            }
            disabled={disableArchiveUnArchive}
          >
            <div className="cr-icon-archive" /> Unarchive questionnaire
          </DropdownItem>
        ) : (
          <>
            {survey.status === SurveyStatus.AwaitingReview && (
              <DropdownItem
                onClick={() =>
                  markAsInReview(
                    dispatch,
                    openConfirmationModal,
                    survey,
                    false,
                    isManagementAnalystSession
                  )
                }
              >
                Mark as &apos;In Review&apos;
              </DropdownItem>
            )}
            {survey.status === SurveyStatus.InReview && (
              <DropdownItem
                onClick={() =>
                  markAsAwaitingReview(
                    dispatch,
                    openConfirmationModal,
                    survey,
                    false,
                    isManagementAnalystSession
                  )
                }
              >
                Mark as &apos;Awaiting Review&apos;
              </DropdownItem>
            )}
            {surveyStatusIn(survey.status, [
              SurveyStatus.AwaitingReview,
              SurveyStatus.InReview,
            ]) && (
              <DropdownItem
                onClick={() =>
                  markAsComplete(
                    dispatch,
                    openConfirmationModal,
                    survey,
                    isManagementAnalystSession
                  )
                }
              >
                Mark as &apos;Complete&apos;
              </DropdownItem>
            )}
            {surveyStatusIn(survey.status, [
              SurveyStatus.AwaitingReview,
              SurveyStatus.InReview,
            ]) &&
              survey.risks &&
              survey.risks.length > 0 &&
              !isManagementAnalystSession && (
                <DropdownItem
                  onClick={() =>
                    history.push(
                      `${vendorUrlPrefix(
                        survey.vendorId,
                        isManagementAnalystSession,
                        managedOrgId
                      )}/remediation/add`,
                      {
                        surveyId: survey.id,
                        backContext: {
                          backTo: history.location.pathname,
                          backToText: "Back to questionnaire",
                        },
                      } as remediationLocationState
                    )
                  }
                >
                  Request remediation
                </DropdownItem>
              )}
            {surveyStatusIn(survey.status, [
              SurveyStatus.AwaitingReview,
              SurveyStatus.InReview,
            ]) && <DropdownSeparator />}
            {surveyStatusIn(survey.status, [SurveyStatus.Complete]) &&
              orgHasExtraQuestionnaireStatusChanges && (
                <>
                  <DropdownItem
                    onClick={() =>
                      markAsInReview(
                        dispatch,
                        openConfirmationModal,
                        survey,
                        true,
                        isManagementAnalystSession
                      )
                    }
                  >
                    <i className={"cr-icon-timer"} />
                    Reopen as &apos;In Review&apos;
                  </DropdownItem>
                  <DropdownItem
                    onClick={() =>
                      markAsAwaitingReview(
                        dispatch,
                        openConfirmationModal,
                        survey,
                        true,
                        isManagementAnalystSession
                      )
                    }
                  >
                    <i className={"cr-icon-timer"} />
                    Reopen as &apos;Awaiting Review&apos;
                  </DropdownItem>
                </>
              )}
            {surveyStatusIn(survey.status, [SurveyStatus.Cancelled]) &&
              orgHasExtraQuestionnaireStatusChanges && (
                <DropdownItem
                  onClick={() => {
                    restoreCancelledQuestionnaire(
                      dispatch,
                      survey.id,
                      survey.vendorId,
                      survey.previousStatus,
                      survey.dueDate,
                      isManagementAnalystSession
                    );
                  }}
                >
                  <i className={"cr-icon-timer"} />
                  Restore questionnaire
                </DropdownItem>
              )}
            {surveyStatusIn(survey.status, [
              SurveyStatus.Complete,
              SurveyStatus.Cancelled,
            ]) && (
              <DropdownItem
                onClick={() => {
                  if (survey.usageType === SurveyUsageType.Relationship) {
                    history.push(
                      `${vendorUrlPrefix(
                        survey.vendorId,
                        isManagementAnalystSession,
                        managedOrgId
                      )}/summary/relationship/create`,
                      {
                        resendSurvey: survey,
                        backContext: {
                          backTo: history.location.pathname,
                          backToText: "Back to questionnaire",
                        },
                      } as CreateRelationshipQuestionnaireLocationState
                    );
                  } else {
                    history.push(
                      `${vendorUrlPrefix(
                        survey.vendorId,
                        isManagementAnalystSession,
                        managedOrgId
                      )}/surveys/create`,
                      {
                        resendSurvey: survey,
                        backContext: {
                          backTo: history.location.pathname,
                          backToText: "Back to questionnaire",
                        },
                      } as CreateQuestionnaireBulkLocationState
                    );
                  }
                }}
              >
                <i className={"cr-icon-message"} />
                Resend questionnaire
              </DropdownItem>
            )}
            <DropdownItem
              onClick={() =>
                archiveOrUnarchiveSurvey(
                  dispatch,
                  openConfirmationModal,
                  survey,
                  true
                )
              }
            >
              <div className="cr-icon-archive" /> Archive questionnaire
            </DropdownItem>
            {survey.status !== SurveyStatus.Cancelled &&
              survey.usageType !== SurveyUsageType.Relationship && (
                <DropdownItem
                  onClick={() =>
                    cancelSurvey(dispatch, openConfirmationModal, survey)
                  }
                >
                  <div className="cr-icon-cancel" /> Cancel questionnaire
                </DropdownItem>
              )}
          </>
        )}
      </DropdownV2>
      {confirmationModalComponent}
    </>
  );
};

export default SurveyDetailsStatusDropdown;
