import { useState } from "react";
import "../../style/components/ContactUsModal.scss";
import CircledIcon from "../../../_common/components/CircledIcon";
import Button from "../../../_common/components/core/Button";
import ModalV2 from "../../../_common/components/ModalV2";
import { sendSalesNotifyRequest } from "../../../_common/reducers/commonActions";
import { useAppDispatch } from "../../../_common/types/reduxHooks";

export type salesTopic = "sales" | "expand";

interface IContactUsModalProps {
  active: boolean;
  onClose: () => void;
  onContactSent?: () => void;
  title: string;
  message: string;
  team: string;
  onwardTopic: salesTopic;
  onwardMessage: string;
}

export const ContactUsModal = (props: IContactUsModalProps) => {
  const dispatch = useAppDispatch();
  const {
    onClose,
    onContactSent,
    active,
    title,
    message,
    team,
    onwardTopic,
    onwardMessage,
  } = props;
  const [contactUsComplete, setContactUsComplete] = useState(false);
  const [contactUsLoading, setContactUsLoading] = useState(false);

  const handleContactUs = () => {
    setContactUsLoading(true);
    dispatch(sendSalesNotifyRequest(onwardMessage, onwardTopic))
      .catch((e) => console.error(e))
      .then(() => {
        setContactUsLoading(false);
        setContactUsComplete(true);
        onContactSent && onContactSent();
      });
  };

  const handleClose = () => {
    setContactUsComplete(false);
    onClose();
  };

  return (
    <ModalV2
      active={active}
      onClose={handleClose}
      className="contact-us-modal"
      footerClassName="contact-us-modal-footer"
      headerClassName="contact-us-modal-header"
      headerContent={
        contactUsComplete ? (
          <>
            <CircledIcon iconClass={"cr-icon-message"} />
            {"We'll be in touch"}
          </>
        ) : (
          <>
            <CircledIcon iconClass={"cr-icon-upguard-logo-pick"} />
            {title}
          </>
        )
      }
      footerContent={
        !contactUsComplete ? (
          <>
            <Button
              filledPrimary
              className={"send-button"}
              onClick={handleContactUs}
              loading={contactUsLoading}
            >
              Reach out to me
            </Button>
          </>
        ) : undefined
      }
    >
      <div className="contact-us-body-content">
        {contactUsComplete
          ? "Someone from our " + team + " team will be in touch with you soon."
          : message}
      </div>
    </ModalV2>
  );
};
