import { IItemWithLabelsAndPortfolios } from "../../_common/types/labelsAndPortfolios";

export interface CloudscanItemProperty {
  property: string;
  value: string;
}

export enum NoneReasonType {
  BlockedByWAF = "waf_blocked",
  BlockedByCaptcha = "captcha_blocked",
}

export interface CheckResult {
  id: string;
  pass: boolean;
  meta: string;
  vendorOnly: boolean;
  severity: number;
  cloudscanCategory: string; // TODO - should be enum
  title: string;
  description: string;
  checkedAt: string;
  expected: CloudscanItemProperty[];
  actual: CloudscanItemProperty[];
  sources: string[];
  none?: boolean;
  noneReason?: string;
}

interface webscanResultResult {
  hostname: string;
  scannedAt: string;
  checkResults: CheckResult[];
  waivedCheckResults: CheckResult[];
  checkResultsSkipped: CheckResult[];
  scanPending: boolean;
  scanError?: string;
  scanErroDate?: string;
  isRedirect: boolean;
  industrySector: string;
  industryGroup: string;
  cstarScore: number;
  penalty: number;
  noResult: boolean;
  vendorId: number;
  vendorName: string;
  scanMeta: WebscanResultMeta;
  lastEnabledDate?: string;
}

export type WebscanResultResult = webscanResultResult &
  IItemWithLabelsAndPortfolios;

export interface WebscanResult {
  loading: boolean;
  error: any; // TODO
  rescanning: false;
  result: WebscanResultResult;
}

interface WebscanResultMeta {
  aRecords?: string[];
  wwwActive?: boolean;
  wwwARecords?: string[];
  certs?: WebscanCert[];
}

interface WebscanCert {
  ports: number[];
  // Fingerprint (sha256)
  fingerprint: string;
  common_name: string;
  san_domains: string[];
  expires: string;
}
