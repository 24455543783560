import * as React from "react";
import classnames from "classnames";

import "../../vendorrisk/style/components/StepsWithSections.scss";

const rightArrowSVG = (
  <svg
    className="step-right-arrow"
    width="17"
    height="56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="m-0.02634,0.5c3,0 5.712,1.78798 6.893,4.54561l8.572,19.99999c0.808,1.8866 0.808,4.0222 0,5.9088l-8.572,20c-1.181,2.7576 -3.893,4.5456 -6.893,4.5456" />
  </svg>
);

export interface IStep<StepID = string> {
  id: StepID;
  text: React.ReactNode;
  onClick?: React.MouseEventHandler;
  disabled?: boolean;
  hidden?: boolean;
}

interface IStepProps<StepID = string> {
  step: IStep<StepID>;
  idx: number;
  number: number;
  currentStep: number;
}

const Step = <StepID = string,>(props: IStepProps<StepID>) => {
  const { step, idx, currentStep, number } = props;
  const completed = idx + 1 <= currentStep;
  const current = idx + 1 === currentStep;
  const clickable = !!step.onClick;
  const { disabled } = step;

  return (
    <div
      className={classnames("step", {
        completed,
        disabled,
        clickable,
      })}
      onClick={step.onClick}
    >
      <div className="step-num">
        {completed && !current ? <div className="cr-icon-check" /> : number}
      </div>
      {step.text}
      {rightArrowSVG}
    </div>
  );
};

export interface IStepsProps<StepID = string> {
  steps: IStep<StepID>[];
  currentStep: number;
}

export const Steps = <StepID = string,>(props: IStepsProps<StepID>) => {
  const { steps, currentStep } = props;

  let number = 0;
  const stepsRender = steps.map((s, idx) => {
    if (!s.hidden) {
      number = number + 1;
    }

    return (
      <React.Fragment key={String(s.id)}>
        {!s.hidden && (
          <Step idx={idx} number={number} step={s} currentStep={currentStep} />
        )}
      </React.Fragment>
    );
  });

  return <div className="multi-steps">{stepsRender}</div>;
};

export interface IStepsWithSectionsProps {
  className: string;
  id: string;
  children: React.ReactNode;
}

const StepsWithSections = (props: IStepsWithSectionsProps) => {
  const { id, className, children } = props;
  return (
    <div className={`steps-with-sections ${className}`} id={id}>
      {children}
    </div>
  );
};

StepsWithSections.defaultProps = {
  className: "",
  id: "",
};

export default StepsWithSections;
