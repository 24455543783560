import { LabelColor } from "../../../_common/types/label";
import { FC, useEffect, useState } from "react";
import {
  OptionType,
  SelectV2Multi,
} from "../../../_common/components/SelectV2";
import { SlidePanelSection } from "./SlidePanelSection";
import { ValueType } from "react-select";
import PillLabel from "../PillLabel";
import { ConcentrationRiskData } from "../../../_common/types/redux";
import { fetchConcentrationRisk } from "../../reducers/cyberRiskActions";
import { VendorProduct } from "../../reducers/vendorFourthParties.actions";
import { useAppDispatch } from "../../../_common/types/reduxHooks";
import { addDefaultUnknownErrorAlert } from "../../../_common/reducers/messageAlerts.actions";

interface IFourthPartyProductFilterProps {
  title?: string;
  selectedValues: string[];
  onChange: (selected: string[]) => void;
  startExpanded?: boolean;
}

const FourthPartyProductFilter: FC<IFourthPartyProductFilterProps> = ({
  title = "Fourth Party product",
  onChange,
  selectedValues = [],
  startExpanded = false,
}) => {
  const dispatch = useAppDispatch();

  const [expanded, setExpanded] = useState(
    startExpanded || selectedValues.length > 0
  );
  const [availableValues, setAvailableValues] = useState<VendorProduct[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [valueMap, setValueMap] = useState<{
    [uuid: string]: string;
  }>({});

  useEffect(() => {
    if (expanded) {
      setIsLoading(true);
      dispatch(fetchConcentrationRisk())
        .then((concentrationData: ConcentrationRiskData) => {
          setAvailableValues(
            concentrationData.byVendor.reduce(
              (acc, curr) => [
                ...acc,
                ...curr.products.filter((vp) => vp.orgId === null),
              ],
              [] as VendorProduct[]
            )
          );
          const map = {} as { [uuid: string]: string };
          concentrationData.byVendor.forEach((v) =>
            v.products
              .filter((vp) => vp.orgId === null)
              .forEach((vp) => {
                map[vp.uuid] = vp.tech_name;
              })
          );
          setValueMap(map);
        })
        .catch((e) => {
          console.error(e);
          dispatch(
            addDefaultUnknownErrorAlert(
              "An error occurred fetching the available fourth party products"
            )
          );
        })
        .finally(() => setIsLoading(false));
    }
  }, [dispatch, expanded]);

  const selectOnChange = (selectedValues: ValueType<OptionType, true>) => {
    onChange(
      selectedValues ? selectedValues.map(({ value }) => value as string) : []
    );
  };

  const removeClick = (value: string) => {
    onChange(selectedValues.filter((v) => v !== value));
  };

  const selectValue: OptionType[] = [
    ...selectedValues.map((v) => {
      const label = valueMap[v];
      return { value: v, label: label };
    }),
  ];

  return (
    <SlidePanelSection
      title={title}
      expanded={expanded}
      toggleExpand={() => setExpanded(!expanded)}
    >
      <SelectV2Multi
        isMulti
        placeholder={`Type to search...`}
        options={[
          ...availableValues.map((value) => ({
            label: value.tech_name,
            value: value.uuid,
          })),
        ]}
        value={selectValue}
        onChange={selectOnChange}
        controlShouldRenderValue={false}
        captureMenuScroll={false}
        isLoading={isLoading}
      />
      <div className={"value-list"}>
        {selectedValues.map((v) => (
          <PillLabel
            key={v}
            color={LabelColor.Blue}
            removeable
            onRemoveClick={() => removeClick(v)}
            className={"attribute-value"}
            capitalized={false}
          >
            {valueMap[v]}
          </PillLabel>
        ))}
      </div>
    </SlidePanelSection>
  );
};

export default FourthPartyProductFilter;
