import { SurveyBulkCreatResp } from "../../reducers/survey.actions";
import ModalV2 from "../../../_common/components/ModalV2";
import Button from "../../../_common/components/core/Button";
import XTable, { XTableCell } from "../../../_common/components/core/XTable";
import { toInteger } from "lodash";
import "../../style/components/BulkQuestionnaireStatusModal.scss";

interface IBulkQuestionnaireStatusModalProps {
  active: boolean;
  result?: SurveyBulkCreatResp;
  onClose: () => void;
  vendorNames: Record<number, string>;
}

const BulkSendQuestionnaireStatusModal = ({
  active,
  onClose,
  result,
  vendorNames,
}: IBulkQuestionnaireStatusModalProps) => (
  <ModalV2
    className={"bulk-send-survey-modal"}
    active={active}
    onClose={onClose}
    disallowClose={!result}
    headerContent={!result ? "Sending questionnaires" : "Questionnaires sent"}
    footerContent={
      <Button loading={!result} onClick={onClose}>
        Close
      </Button>
    }
  >
    {!result ? (
      <div className={"bulk-loading"}>This may take a few moments.</div>
    ) : (
      <div className={"bulk-results"}>
        {result.total === Object.keys(result.successful).length ? (
          <p>All questionnaires have been sent successfully.</p>
        ) : (
          <>
            <p>
              We have experienced errors processing some questionnaires. Please
              review the information below and contact support if the problem
              persists
            </p>
            <XTable
              columnHeaders={[
                {
                  id: "vendorName",
                  text: "Vendor name",
                },
                {
                  id: "reason",
                  text: "Reason",
                },
              ]}
              rows={Object.entries(result.failed).map(([id, reason]) => {
                const vendorId = toInteger(id);

                return {
                  id: vendorId,
                  cells: [
                    <XTableCell key={"name"}>
                      {vendorNames[vendorId]}
                    </XTableCell>,
                    <XTableCell key={"reason"} className={"reason-cell"}>
                      {reason}
                    </XTableCell>,
                  ],
                };
              })}
            />
          </>
        )}
      </div>
    )}
  </ModalV2>
);

export default BulkSendQuestionnaireStatusModal;
