import { DefaultThunkDispatch } from "../../_common/types/redux";
import { IVendorHierarchy } from "../../_common/types/vendor";
import { FetchCyberRiskUrl } from "../../_common/api";
import { setCustomerData, setVendorData } from "./cyberRiskActions";
import { VendorSummaryRisk } from "../../_common/types/vendorSummary";

export const fetchBreachsightSubsidiaries = (
  force = false,
  replaceExistingOnly = false
) => {
  return async (
    dispatch: DefaultThunkDispatch,
    getState: any
  ): Promise<IVendorHierarchy> => {
    if (!force && getState().cyberRisk.customerData.subsidiaries) {
      return getState().cyberRisk.customerData.subsidiaries as IVendorHierarchy;
    }

    // if request was to replace only if existing, check if data exists already
    if (
      replaceExistingOnly &&
      !getState().cyberRisk.customerData.subsidiaries
    ) {
      return {} as IVendorHierarchy;
    }

    dispatch(
      setCustomerData({
        subsidiaries_loading: true,
        subsidiaries_error: null,
      })
    );

    let result: IVendorHierarchy;

    try {
      result = (await FetchCyberRiskUrl(
        "customer/subsidiaries/v1/",
        null,
        null,
        dispatch,
        getState
      )) as IVendorHierarchy;
    } catch (e) {
      setCustomerData({
        subsidiaries_loading: false,
        subsidiaries_error: e,
      });
      console.error(e);
      throw e;
    }

    dispatch(
      setCustomerData({
        subsidiaries: result,
        subsidiaries_loading: false,
      })
    );

    return result;
  };
};

export interface fetchRiskProfileIncludingSubsidiariesResult {
  topLevelVendorId: number;
  risks: VendorSummaryRisk[];
  subsidiaryNamesByVendorId: { [vendorId: number]: string };
  vulnFilterMetadata: {
    cve_names: string[];
    risks_by_cve: { [cveName: string]: string[] | undefined };
  };
  allIPCountries: string[];
}

export const fetchBreachsightRiskProfileIncludingSubsidiaries = (
  force = false,
  vendorId?: number
) => {
  return async (
    dispatch: DefaultThunkDispatch,
    getState: any
  ): Promise<fetchRiskProfileIncludingSubsidiariesResult> => {
    if (!force) {
      if (
        !vendorId &&
        getState().cyberRisk.customerData.riskProfileIncludingSubsidiaries
      ) {
        return getState().cyberRisk.customerData
          .riskProfileIncludingSubsidiaries as fetchRiskProfileIncludingSubsidiariesResult;
      }

      if (
        vendorId &&
        getState().cyberRisk.subsidiaries[vendorId]
          ?.riskProfileIncludingSubsidiaries
      ) {
        return getState().cyberRisk.subsidiaries[vendorId]
          .riskProfileIncludingSubsidiaries as fetchRiskProfileIncludingSubsidiariesResult;
      }
    }

    let result: fetchRiskProfileIncludingSubsidiariesResult;

    const params: { vendor_id?: number } = {};

    if (vendorId) {
      params.vendor_id = vendorId;
    }

    try {
      result = (await FetchCyberRiskUrl(
        "customer/subsidiaries/risks/v1/",
        params,
        null,
        dispatch,
        getState
      )) as fetchRiskProfileIncludingSubsidiariesResult;
    } catch (e) {
      console.error(e);
      throw e;
    }

    if (vendorId) {
      dispatch(
        setVendorData(
          vendorId,
          {
            riskProfileIncludingSubsidiaries: result,
          },
          true
        )
      );
    } else {
      dispatch(
        setCustomerData({
          riskProfileIncludingSubsidiaries: result,
        })
      );
    }

    return result;
  };
};

export const fetchBreachsightRiskProfileIncludingSubsidiariesDomains = (
  vendorId: number,
  riskId: string
) => {
  return async (
    dispatch: DefaultThunkDispatch,
    getState: any
  ): Promise<string[]> => {
    let result: string[];

    const params: { vendor_id: number; risk_id: string } = {
      vendor_id: vendorId,
      risk_id: riskId,
    };

    try {
      result = (await FetchCyberRiskUrl(
        "customer/subsidiaries/risks/domains/v1/",
        params,
        null,
        dispatch,
        getState
      )) as string[];
    } catch (e) {
      console.error(e);
      throw e;
    }

    return result;
  };
};
