export const capitalizeFirstLetter = (v: string): string => {
  return v.charAt(0).toUpperCase() + v.slice(1);
};

// escape special characters from a string to make it suitable for a regexp
// taken from https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions#using_special_characters
export const escapeRegExp = (s: string) => {
  return s.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
};

export const trimFileExtension = (s: string) => {
  const parts = s.split(".");
  return parts.length > 1 ? parts.slice(0, -1).join(".") : s;
};

export const getSubtitleDisplay = (subtitle: string, length: number = 13) => {
  if (!subtitle) {
    return subtitle;
  }

  const firstWord = subtitle.split(" ")[0];
  if (firstWord.length > length) {
    // Truncate as this is likely a long url
    const subtitleTruncated = subtitle.substring(0, length - 1);
    return `${subtitleTruncated}...`;
  } else {
    return subtitle;
  }
};
