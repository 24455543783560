import { DefaultThunkDispatchProp } from "../../_common/types/redux";
import { fetchVendorWatchStatus } from "../reducers/cyberRiskActions";
import { addDefaultUnknownErrorAlert } from "../../_common/reducers/messageAlerts.actions";
import addVendorIconSrc from "../images/add-vendor-icon.svg";
import Button from "../../_common/components/core/Button";
import EmptyCardWithAction from "../../_common/components/EmptyCardWithAction";
import { useEffect } from "react";
import ReportCard from "../../_common/components/ReportCard";
import {
  userHasPermissionForVendorFromState,
  UserVendorRiskWrite,
} from "../../_common/permissions";
import { AssuranceType } from "../../_common/types/organisations";
import { getVendorWords } from "../../_common/constants";
import { appConnect } from "../../_common/types/reduxHooks";
import { useHistory } from "react-router-dom";

interface UnwatchedCardConnectedProps {
  watchStateLoading?: boolean;
  couldWatch: boolean;
  assuranceType: AssuranceType;
}

interface UnwatchedCardOwnProps {
  title?: string;
  text?: string;
  vendorId: number;
  vendorName: string;
  vendorPrimaryHostname: string;
  onWatched?: () => void;
}

type UnwatchedCardProps = UnwatchedCardOwnProps &
  UnwatchedCardConnectedProps &
  DefaultThunkDispatchProp;

const UnwatchedVendorCard = (props: UnwatchedCardProps) => {
  const history = useHistory();

  useEffect(() => {
    if (props.vendorId && props.watchStateLoading === undefined) {
      // We haven't yet started fetching the watch state so lets do that
      props.dispatch(fetchVendorWatchStatus(props.vendorId)).catch(() => {
        props.dispatch(
          addDefaultUnknownErrorAlert("Error retrieving vendor watched status")
        );
      });
    }
  }, [props.vendorId, props.watchStateLoading]);

  const watchVendor = () => history.push(`/vendor/${props.vendorId}/edit`);

  const vendorWords = getVendorWords(props.assuranceType);

  return (
    <ReportCard newStyles loading={props.watchStateLoading}>
      {props.title && <div className="header">{props.title}</div>}
      <EmptyCardWithAction
        iconSrc={addVendorIconSrc}
        emptyText={`${vendorWords.singularTitleCase} not currently monitored`}
        emptySubText={
          props.text ??
          `This ${vendorWords.singular} must be monitored to see any further information.`
        }
        actionButtonRenderOverride={
          <Button disabled={!props.couldWatch} onClick={watchVendor}>
            {`Monitor this ${vendorWords.singular}`}
          </Button>
        }
      />
    </ReportCard>
  );
};

export default appConnect<
  UnwatchedCardConnectedProps,
  never,
  UnwatchedCardOwnProps
>((state, props) => {
  let watchStateLoading: boolean | undefined = undefined;
  let couldWatch = false;

  if (props.vendorId) {
    const watchState = state.cyberRisk.vendors[props.vendorId]?.watching;

    if (watchState) {
      watchStateLoading = watchState.loading;
      if (watchState.result?.couldWatch) {
        couldWatch = true;
      }
    }
  }

  const userHasWriteVendorInfoPermission =
    state.common.userData.userPermissions.includes(UserVendorRiskWrite) ||
    userHasPermissionForVendorFromState(
      state,
      UserVendorRiskWrite,
      props.vendorId || 0
    );

  return {
    watchStateLoading,
    couldWatch: couldWatch && userHasWriteVendorInfoPermission,
    assuranceType: state.common.userData.assuranceType,
  };
})(UnwatchedVendorCard);
