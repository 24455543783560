import PageHeader from "../../_common/components/PageHeader";
import ReportCard, {
  ExpandableReportCard,
} from "../../_common/components/ReportCard";
import { FC, useCallback, useEffect, useRef, useState } from "react";

import {
  AdditionalEvidence,
  AdditionalEvidenceRequestPriority,
  AdditionalEvidenceRequestStatus,
  getAdditionalEvidenceStatus,
  IAdditionalEvidenceDocument,
} from "../../vendorrisk/types/additionalEvidence";
import { DefaultRouteProps } from "../../_common/types/router";
import { fetchVendorPortalAdditionalEvidenceRequestDetail } from "../reducers/additionalEvidenceRequest.actions";
import PillLabel from "../../vendorrisk/components/PillLabel";
import { LabelColor } from "../../_common/types/label";
import "../style/views/AdditionalEvidenceDetails.scss";
import { AdditionalEvidenceStatusPill } from "../../vendorrisk/components/AdditionalEvidenceListTable";
import AdditionalEvidenceDocument from "../../vendorrisk/components/AdditionalEvidenceDocument";
import Button from "../../_common/components/core/Button";
import { DefaultThunkDispatchProp } from "../../_common/types/redux";
import { addSimpleErrorAlert } from "../../_common/reducers/messageAlerts.actions";
import LoadingBanner from "../../_common/components/core/LoadingBanner";
import { getAuthFromLocalStorage } from "../../_common/reducers/commonActions";
import { appConnect, useAppDispatch } from "../../_common/types/reduxHooks";
import classNames from "classnames";
import VerticalProgress, {
  IVerticalProgressStep,
} from "../../_common/components/VerticalProgress";
import { formatDateAsLocal } from "../../_common/helpers";
import AdditionalEvidenceUpload from "./AdditionalEvidenceUpload";
import {
  useBasicPermissions,
  UserReadContentLibrary,
} from "../../_common/permissions";
import CircleExclaimSvg from "../../vendorrisk/images/circle_exclaim.svg";
import EmptyCardWithAction from "../../_common/components/EmptyCardWithAction";
import {
  tasksRouterUrlPrefix,
  trustExchangeTaskUrlPrefix,
} from "../../_common/routers/TasksRouter";

interface MatchParams {
  additionalEvidenceId: string;
}

interface VendorPortalAdditionalEvidenceDetailsConnectedProps {
  evidenceRequest: AdditionalEvidence | null;
  additionalEvidenceId: number;
  isLoading: boolean;
}

interface VendorPortalAdditionalEvidenceDetailsOwnProps
  extends DefaultRouteProps<MatchParams> {
  additionalEvidenceId: number;
}

type VendorPortalAdditionalEvidenceRequestsProps =
  VendorPortalAdditionalEvidenceDetailsOwnProps &
    VendorPortalAdditionalEvidenceDetailsConnectedProps &
    DefaultThunkDispatchProp;

const VendorPortalAdditionalEvidenceDetails: FC<
  VendorPortalAdditionalEvidenceRequestsProps
> = ({ history, additionalEvidenceId, evidenceRequest, isLoading }) => {
  const dispatch = useAppDispatch();
  const perms = useBasicPermissions();
  const userHasReadPerm = !!perms.userPermissions[UserReadContentLibrary];
  const [isUploadDocumentsModalOpen, setIsUploadDocumentsModalOpen] =
    useState<boolean>(false);
  const [evidenceDocuments, setEvidenceDocuments] =
    useState<IAdditionalEvidenceDocument[]>();

  const backAction = useCallback(() => {
    history.location.state?.backContext?.goBack
      ? history.goBack()
      : history.push(
          history.location.state?.backContext?.backTo ??
            `/${tasksRouterUrlPrefix}/${trustExchangeTaskUrlPrefix}/additionalevidence`
        );
  }, [history]);

  // Load the additional evidence detail
  useEffect(() => {
    if (additionalEvidenceId) {
      dispatch(
        fetchVendorPortalAdditionalEvidenceRequestDetail(additionalEvidenceId)
      );
    }
  }, [additionalEvidenceId]);

  // If there are any documents being virus scanned, repeatedly refresh them until virus scan is complete
  useEffect(() => {
    const autoRefresh = setInterval(async () => {
      const scanningEvidence = evidenceDocumentsRef.current.filter(
        (e) => !e.virusScanned
      );
      if (scanningEvidence.length)
        dispatch(
          fetchVendorPortalAdditionalEvidenceRequestDetail(additionalEvidenceId)
        );
    }, 5000);
    return () => clearInterval(autoRefresh);
  }, []);

  const evidenceDocumentsRef = useRef(evidenceRequest?.documents ?? []);
  useEffect(() => {
    if (evidenceRequest) {
      setEvidenceDocuments(evidenceRequest.documents ?? []);
      evidenceDocumentsRef.current = evidenceRequest.documents ?? [];
    }
  }, [evidenceRequest]);

  const progress: IVerticalProgressStep[] = [
    {
      id: "requested",
      name: "Requested",
      stepDescription: (
        <div className="step-description">
          {formatDateAsLocal(evidenceRequest?.createdAt)}
        </div>
      ),
      current: true,
    },
    {
      id: "review",
      name: "In Review",
      stepDescription: evidenceDocuments && evidenceDocuments.length > 0 && (
        <div className="step-description">
          {formatDateAsLocal(
            evidenceDocuments[evidenceDocuments.length - 1].uploadedAt
          )}
        </div>
      ),
      current:
        evidenceRequest?.requestStatus ===
          AdditionalEvidenceRequestStatus.Review ||
        evidenceRequest?.requestStatus ===
          AdditionalEvidenceRequestStatus.Active,
    },
    {
      id: "completed",
      name: "Complete",
      stepDescription: evidenceRequest?.requestStatus ===
        AdditionalEvidenceRequestStatus.Active && (
        <div className="step-description">
          {formatDateAsLocal(evidenceRequest?.updatedAt)}
        </div>
      ),
      current:
        evidenceRequest?.requestStatus ===
        AdditionalEvidenceRequestStatus.Active,
    },
  ];
  const onDownloadDocument = (
    id: number,
    virusScanned: boolean,
    virusSafe: boolean
  ) => {
    if (virusScanned && virusSafe) {
      const auth = getAuthFromLocalStorage();
      const url = `/api/vendorportal/evidence/download/v1/?apikey=${encodeURIComponent(
        auth.apiKey
      )}&token=${encodeURIComponent(auth.token)}&document_id=${id}`;
      window.open(url, "_blank");
    } else if (!virusScanned) {
      dispatch(
        addSimpleErrorAlert(
          "Virus scanning for this file has not yet completed"
        )
      );
    } else {
      dispatch(
        addSimpleErrorAlert(
          "Virus scanning detected an issues with this file. It cannot be downloaded"
        )
      );
    }
  };

  return (
    <div id="additional_evidence_details_vendor_view">
      <PageHeader
        title="Document Request"
        history={history}
        backAction={backAction}
        backText={
          history.location.state?.backContext?.backToText ??
          "Back to document requests"
        }
      />
      {!evidenceRequest && isLoading && (
        <ReportCard>
          <LoadingBanner />
        </ReportCard>
      )}
      {!evidenceRequest && !isLoading && (
        <ReportCard newStyles>
          <EmptyCardWithAction
            iconSrc={CircleExclaimSvg}
            emptyText={"Document request not found"}
            emptySubText={
              "This document request has been deleted or you do not have permissions to see it."
            }
            actionButtonRenderOverride={
              <Button leftArrow onClick={() => backAction()}>
                Go back
              </Button>
            }
          />
        </ReportCard>
      )}
      {evidenceRequest && (
        <>
          <ReportCard
            newStyles
            className={classNames("add-documents-card", {
              "has-versions": evidenceDocuments && evidenceDocuments.length > 1,
            })}
          >
            <div className="body">
              {evidenceDocuments && evidenceDocuments.length === 0 && (
                <div className="add-new-document">
                  <Button
                    filledPrimary
                    onClick={() => setIsUploadDocumentsModalOpen(true)}
                  >
                    <div className="cr-icon-plus" /> Add document
                  </Button>
                </div>
              )}
              {evidenceDocuments && evidenceDocuments.length > 0 && (
                <AdditionalEvidenceDocument
                  idx={0}
                  key={evidenceDocuments[0].id}
                  id={evidenceDocuments[0].id}
                  name={evidenceDocuments[0].name}
                  filename={evidenceDocuments[0].filename}
                  uploadedBy={evidenceDocuments[0].uploadedByName}
                  uploadedAt={evidenceDocuments[0].uploadedAt}
                  virusScanned={evidenceDocuments[0].virusScanned}
                  virusSafe={evidenceDocuments[0].virusSafe}
                  editable={
                    evidenceRequest.requestStatus !==
                    AdditionalEvidenceRequestStatus.Active
                  }
                  onEdit={() => setIsUploadDocumentsModalOpen(true)}
                  onOpen={
                    evidenceDocuments[0].contentLibraryUUID && userHasReadPerm
                      ? () =>
                          window.open(
                            `/contentlibrary/document/${evidenceDocuments[0].contentLibraryUUID}`
                          )
                      : undefined
                  }
                  downloadable={
                    evidenceDocuments[0].virusScanned &&
                    evidenceDocuments[0].virusSafe
                  }
                  onDownloadDocument={() =>
                    onDownloadDocument(
                      evidenceDocuments[0].id,
                      evidenceDocuments[0].virusScanned,
                      evidenceDocuments[0].virusSafe
                    )
                  }
                  userHasWriteAdditionalEvidencePermission={true}
                />
              )}
            </div>
          </ReportCard>
          {evidenceDocuments && evidenceDocuments.length > 1 && (
            <ExpandableReportCard
              className="document-versions"
              headerContent="Versions"
              isExpanded={false}
            >
              <div className="document-versions">
                {evidenceDocuments.map((document, idx) => {
                  if (idx > 0) {
                    return (
                      <AdditionalEvidenceDocument
                        idx={idx}
                        key={document.id}
                        id={document.id}
                        name={document.name}
                        filename={document.filename}
                        uploadedBy={document.uploadedByName}
                        uploadedAt={document.uploadedAt}
                        virusScanned={document.virusScanned}
                        virusSafe={document.virusSafe}
                        editable={false}
                        downloadable={
                          document.virusScanned && document.virusSafe
                        }
                        onDownloadDocument={() =>
                          onDownloadDocument(
                            document.id,
                            document.virusScanned,
                            document.virusSafe
                          )
                        }
                        onOpen={
                          document.contentLibraryUUID && userHasReadPerm
                            ? () =>
                                window.open(
                                  `/contentlibrary/document/${document.contentLibraryUUID}`
                                )
                            : undefined
                        }
                        userHasWriteAdditionalEvidencePermission={true}
                      />
                    );
                  } else {
                    return <></>;
                  }
                })}
              </div>
            </ExpandableReportCard>
          )}
          <div className="columns">
            <div className="column">
              <ReportCard newStyles className="add-documents-request-card">
                <div className="header">
                  <div className="title">Request details</div>
                </div>
                <div className="body">
                  <div className="panel">
                    <table>
                      <tbody>
                        <tr>
                          <td className={"field"}>Requested by</td>
                          <td className={"value avatar-name"}>
                            {evidenceRequest.organisationName +
                              " on " +
                              formatDateAsLocal(evidenceRequest.createdAt)}
                          </td>
                        </tr>
                        <tr>
                          <td className={"field"}>Type</td>
                          <td className={"value"}>
                            {evidenceRequest.evidenceTypeName}
                          </td>
                        </tr>
                        <tr>
                          <td className="field">Priority</td>
                          <td className="value">
                            {evidenceRequest.priority ===
                            AdditionalEvidenceRequestPriority.High ? (
                              <PillLabel color={LabelColor.Red}>High</PillLabel>
                            ) : (
                              <>-</>
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td className="field">Status</td>
                          <td className="value">
                            {
                              <AdditionalEvidenceStatusPill
                                status={getAdditionalEvidenceStatus(
                                  evidenceRequest,
                                  true
                                )}
                              />
                            }
                          </td>
                        </tr>
                        <tr>
                          <td className="field">Expiry Date</td>
                          <td className="value">
                            {evidenceRequest.expiry
                              ? formatDateAsLocal(evidenceRequest.expiry)
                              : "-"}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </ReportCard>
            </div>
            <div className="column">
              <ReportCard newStyles className="add-documents-request-card">
                <div className="header">
                  <div className="title">Progress</div>
                </div>
                <div className="body">
                  <div className="panel">
                    <VerticalProgress steps={progress} />
                  </div>
                </div>
              </ReportCard>
            </div>
          </div>
        </>
      )}
      {evidenceRequest && (
        <AdditionalEvidenceUpload
          active={isUploadDocumentsModalOpen}
          evidenceId={evidenceRequest.id}
          documentType={evidenceRequest.evidenceTypeName}
          requestingOrg={evidenceRequest.organisationName}
          onClose={() => {
            setIsUploadDocumentsModalOpen(false);
          }}
        />
      )}
    </div>
  );
};

export default appConnect<
  VendorPortalAdditionalEvidenceDetailsConnectedProps,
  never,
  VendorPortalAdditionalEvidenceDetailsOwnProps
>((state, props) => {
  // Read the evidence ID from the URL, and if available, the evidence detail from state, and pass to our view.
  const evidenceRequestId = parseInt(props.match.params.additionalEvidenceId);
  const additionalEvidenceDetails = evidenceRequestId
    ? state.vendorPortal.additionalEvidenceRequests.detailedEvidenceRequests[
        evidenceRequestId
      ]
    : null;
  return {
    additionalEvidenceId: evidenceRequestId,
    evidenceRequest: additionalEvidenceDetails,
    isLoading: !!state.vendorPortal.additionalEvidenceRequests.loading,
  };
})(VendorPortalAdditionalEvidenceDetails);
