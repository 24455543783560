import { DefaultRootState } from "react-redux";
import { IJiraComponent, IJiraIssueType } from "../../_common/types/jira";
import { IMessagingChannel, OAuthState } from "../../_common/types/oauth";

export const getOAuthConnectionData = (
  state: DefaultRootState,
  service: string
): OAuthState | undefined => {
  const cache = state.cyberRisk.oauthConnections;
  if (cache && cache[service]) {
    return cache[service];
  }
  return undefined;
};

export interface OAuth2RequestUrlData {
  url: string;
  uuid: string;
}

export const getOAuth2RequestURLData = (
  state: DefaultRootState,
  service: string
): OAuth2RequestUrlData | null => {
  const cache = state.cyberRisk.oauthConnections;
  if (cache && cache[service]) {
    return { url: cache[service].requestUrl, uuid: cache[service].requestUUID };
  }
  return null;
};

export interface OAuth2MessagingChannelData {
  channels?: IMessagingChannel[];
  loading: boolean;
  error?: string;
}

export const getOAuth2MessagingChannelData = (
  state: DefaultRootState,
  service: string
): OAuth2MessagingChannelData => {
  const cache = state.cyberRisk.oauthConnections;
  if (cache && cache[service]) {
    return {
      channels: cache[service].channels,
      loading: cache[service].channels_loading,
      error: cache[service].error,
    };
  }
  return { loading: true };
};

export const getOAuth2MessagePostingStatus = (
  state: DefaultRootState,
  service: string
) => {
  const cache = state.cyberRisk.oauthConnections;
  if (cache && cache[service]) {
    return {
      posting: cache[service].posting,
      error: cache[service].postingError,
    };
  }
  return {};
};

export interface IntegrationPopulatedMessages {
  [integrationId: string]: {
    [uuid: string]: {
      text: string;
      error: string;
      expected: number;
    };
  };
}

export const getMessagingIntegrationPopulatedTemplates = (
  state: DefaultRootState,
  service: string
) => {
  const cache = state.cyberRisk.oauthConnections;
  if (cache && cache[service]) {
    return cache[service].populatedMessages;
  }
  return {};
};

export const getPopulatedMessagingExpected = (
  state: DefaultRootState,
  service: string,
  integrationId: string,
  uuid: string
): number | undefined => {
  const cache = state.cyberRisk.oauthConnections;
  if (
    cache &&
    cache[service] &&
    cache[service].populatedMessages &&
    cache[service].populatedMessages[integrationId]
  ) {
    return cache[service].populatedMessages[integrationId][uuid].expected;
  }
  return undefined;
};

export interface JiraProjectsData {
  projects?: IMessagingChannel[];
  loading: boolean;
  error?: string;
}

//
// getJiraProjectData
// Grabs the jira project list from redux for the currently connected Jira account
//
export const getJiraProjectData = (
  state: DefaultRootState
): JiraProjectsData => {
  const cache = state.cyberRisk.oauthConnections;
  if (cache && cache["Jira"]) {
    return {
      projects: cache["Jira"].projects,
      loading: cache["Jira"].projects_loading,
      error: cache["Jira"].error,
    };
  }
  return { loading: true };
};

export interface JiraIssueTypes {
  [projectID: string]: {
    issueTypes: IJiraIssueType[] | null;
    loading: boolean;
    error: string | null;
  };
}

export interface JiraComponents {
  [projectID: string]: {
    components: IJiraComponent[] | null;
    loading: boolean;
    error: string | null;
  };
}

//
// getJiraAllIssueTypesData
// Grabs the list of supported issue types for all known jira projects from redux for the currently connected Jira account
//
export const getJiraAllIssueTypesData = (
  state: DefaultRootState
): JiraIssueTypes => {
  const cache = state.cyberRisk.oauthConnections;
  if (cache && cache["Jira"] && cache["Jira"].issueTypes) {
    return cache["Jira"].issueTypes;
  }
  return {};
};

//
// getJiraIssueTypesData
// Grabs the list of supported issue types for a specific jira project from redux (if available)
//
export const getJiraIssueTypesData = (
  state: DefaultRootState,
  projectID: string
): { issueTypes: any[] | null; loading: boolean; error: string | null } => {
  const cache = state.cyberRisk.oauthConnections;
  if (
    cache &&
    cache["Jira"] &&
    cache["Jira"].issueTypes &&
    cache["Jira"].issueTypes[projectID]
  ) {
    return cache["Jira"].issueTypes[projectID];
  }
  return { issueTypes: null, loading: true, error: null };
};

//
// getJiraComponentsData
// Grabs the list of components for a specific jira project from redux (if available)
//
export const getJiraComponentsData = (
  state: DefaultRootState,
  projectID: string
): { components: any[] | null; loading: boolean; error: string | null } => {
  const cache = state.cyberRisk.oauthConnections;
  if (
    cache &&
    cache["Jira"] &&
    cache["Jira"].components &&
    cache["Jira"].components[projectID]
  ) {
    return cache["Jira"].components[projectID];
  }
  return { components: null, loading: true, error: null };
};
