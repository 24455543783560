import { DefaultAction } from "../../_common/types/redux";
import { ManagedVendorLicense } from "../../_common/types/thirdPartyMangedVendors";
import { FetchCyberRiskUrl } from "../../_common/api";
import { LogError } from "../../_common/helpers";

export const SET_MANAGED_VENDOR_LICENSES = "ADMIN_SET_MANAGED_VENDOR_LICENSES";
export const setManagedVendorLicenses = (
  orgId: number,
  licenses: ManagedVendorLicense[]
) => ({
  type: SET_MANAGED_VENDOR_LICENSES,
  orgId,
  licenses,
});

export const getManagedVendorLicensesForOrg =
  (org_id: number): DefaultAction<ManagedVendorLicense[]> =>
  async (dispatch, getState) => {
    let json: { licenses: ManagedVendorLicense[] };
    try {
      json = await FetchCyberRiskUrl(
        "admin/organisations/mvlicense/v1/",
        { org_id },
        null,
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error getting managed vendor licenses for org", e);
      throw e;
    }

    dispatch(setManagedVendorLicenses(org_id, json.licenses));

    return json.licenses;
  };

export const deleteManagedVendorLicense =
  (license_id: number): DefaultAction =>
  async (dispatch, getState) => {
    try {
      await FetchCyberRiskUrl(
        "admin/organisations/mvlicense/v1/",
        { license_id },
        { method: "DELETE" },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("error deleting managed vendor license");
      throw e;
    }
  };

export const editManagedVendorLicense =
  (license: ManagedVendorLicense): DefaultAction =>
  async (dispatch, getState) => {
    try {
      await FetchCyberRiskUrl(
        "admin/organisations/mvlicense/v1/",
        null,
        { method: "PUT", body: JSON.stringify(license) },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("error editing managed vendor license");
      throw e;
    }

    dispatch(getManagedVendorLicensesForOrg(license.orgId));
  };

export const addManagedVendorLicense =
  (
    license: Omit<ManagedVendorLicense, "id">
  ): DefaultAction<ManagedVendorLicense> =>
  async (dispatch, getState) => {
    let json: { license: ManagedVendorLicense };

    try {
      json = await FetchCyberRiskUrl(
        "admin/organisations/mvlicense/v1/",
        null,
        { method: "POST", body: JSON.stringify(license) },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("error adding managed vendor license");
      throw e;
    }

    dispatch(getManagedVendorLicensesForOrg(json.license.orgId));

    return json.license;
  };
