import {
  ImpersonateAllOrganisations,
  UserManageTrialOrganisations,
  UserReadAllOrganisations,
  UserWriteAllOrganisations,
  UserManageHostnameOverridesPermission,
  UserRunWhoisReverseLookupPermission,
  UserManageGlobalCustomVendors,
  UserWriteHostnameOverridesPermission,
} from "../permissions";

// Non-Redux selectors
export const hasPermissionFromUserData = (userData, permission) =>
  userData &&
  userData.userPermissions &&
  userData.userPermissions.includes(permission);

export const canAccessSystemAdminFromUserData = (userData) =>
  hasPermissionFromUserData(userData, UserReadAllOrganisations);

export const canReadOrganisationsFromUserData = (userData) =>
  hasPermissionFromUserData(userData, UserReadAllOrganisations);

export const canWriteOrganisationsFromUserData = (userData) =>
  hasPermissionFromUserData(userData, UserWriteAllOrganisations);

export const canManageTrialOrganisationsFromUserData = (userData) =>
  hasPermissionFromUserData(userData, UserManageTrialOrganisations);

export const canImpersonateAllOrganisationsFromUserData = (userData) =>
  hasPermissionFromUserData(userData, ImpersonateAllOrganisations);

// Redux selectors
export const hasPermission = (state, permission) =>
  state &&
  state.common &&
  hasPermissionFromUserData(state.common.userData, permission);

// TODO(chris.morgan) Include other admin permissions in check
export const canAccessSystemAdmin = (state) =>
  hasPermission(state, UserReadAllOrganisations) ||
  hasPermission(state, UserManageHostnameOverridesPermission);

export const canReadOrganisations = (state) =>
  hasPermission(state, UserReadAllOrganisations);

export const canWriteOrganisations = (state) =>
  hasPermission(state, UserWriteAllOrganisations);

export const canManageTrialOrganisations = (state) =>
  hasPermission(state, UserManageTrialOrganisations);

export const canManipulateHostnameOverrides = (state) =>
  hasPermission(state, UserManageHostnameOverridesPermission);

export const userCanRunWhoisReverseLookup = (state) =>
  hasPermission(state, UserRunWhoisReverseLookupPermission);

export const userCanCreateGlobalCustomVendors = (state) =>
  hasPermission(state, UserManageGlobalCustomVendors);

export const userCanWriteHostnameOverrides = (state) =>
  hasPermission(state, UserWriteHostnameOverridesPermission);
