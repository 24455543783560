import { FC } from "react";
import "../style/components/VerticalSteps.scss";
import classnames from "classnames";
import { CSSTransition, TransitionGroup } from "react-transition-group";

interface VerticalStepProps {
  stepNumber: number;
  name: string;
  description?: string;
  children: React.ReactNode;
  hidden?: boolean;
}

export const VerticalStep: FC<VerticalStepProps> = ({
  stepNumber,
  name,
  description,
  children,
}) => {
  return (
    <div className={"vertical-step"}>
      <div className={"vertical-step-label-and-line"}>
        <div className={"vertical-step-label"}>
          <span>{`Step ${stepNumber}`}</span>
        </div>
        <div className={"vertical-step-line"} />
      </div>
      <div className={"vertical-step-content-container"}>
        <div className={"name"}>{name}</div>
        <div className={"description"}>{description}</div>
        <div className={"vertical-step-content"}>{children}</div>
      </div>
    </div>
  );
};

interface VerticalStepsProps {
  // Steps to display
  children:
    | React.ReactElement<VerticalStepProps>
    | React.ReactElement<VerticalStepProps>[];
  classname?: string;
}

export const VerticalSteps = ({ children, classname }: VerticalStepsProps) => {
  const steps = Array.isArray(children) ? children : [children];
  const classes = classnames("vertical-steps", classname);

  return (
    <div className={classes}>
      <TransitionGroup component={null}>
        {steps
          .filter((s) => !s.props.hidden)
          .map((s) => (
            <CSSTransition
              key={s.props.stepNumber}
              timeout={250}
              classNames="expand"
            >
              {s}
            </CSSTransition>
          ))}
      </TransitionGroup>
    </div>
  );
};
