import { DefaultThunkDispatch } from "../../_common/types/redux";
import { DefaultRootState } from "react-redux";
import { trackEvent } from "../../_common/tracking";
import { FetchCyberRiskUrl, ResponseError } from "../../_common/api";
import { LogError } from "../../_common/helpers";
import {
  addDefaultUnknownErrorAlert,
  addSimpleErrorAlert,
} from "../../_common/reducers/messageAlerts.actions";
import { refreshVendorListsAfterChange } from "./vendors.actions";
import { refreshVendorRiskMatrix } from "./reportsActions";
import { Filters } from "../components/filter/types";

export interface VendorTier {
  id: number;
  orgID?: number;
  tier: number;
  name: string;
  isActive: boolean;
  isArchived: boolean;
  usageCount: number;
}

interface UpdateVendorTiersRequestV1 {
  tiers: VendorTier[];
}

interface ApplyVendorTiersRequestV1 {
  tier?: number;
  vendorIds: number[];
}

export const SET_VENDOR_TIERS = "SET_VENDOR_TIERS";
export const setVendorTiers = (tiers: VendorTier[]) => {
  return {
    type: SET_VENDOR_TIERS,
    tiers,
  };
};

// Store a reference to an ongoing promise that we can return to consumers
let prom: Promise<VendorTier[]> | undefined = undefined;
let promOrgId: number | undefined = undefined;

export const fetchVendorTiers = (reset = false, filters?: Filters) => {
  return async (
    dispatch: DefaultThunkDispatch,
    getState: () => DefaultRootState
  ) => {
    if (reset || getState().common?.userData?.currentOrgID !== promOrgId) {
      promOrgId = getState().common?.userData?.currentOrgID;
      prom = undefined;
    }
    if (prom) {
      return prom;
    }

    const { filters: currentFilters } = getState().cyberRisk.customerData;
    const newFilters = filters || currentFilters;

    let json: VendorTier[];

    try {
      prom = FetchCyberRiskUrl<VendorTier[]>(
        "vendortiers/v1/",
        {
          vendor_label_ids: newFilters.vendorLabelIds,
          vendor_label_ids_match_all: newFilters.vendorLabelIdsMatchAll,
          vendor_label_ids_do_not_match: newFilters.vendorLabelIdsDoNotMatch,
          include_unlabeled: newFilters.includeUnlabeled,
          min_score: newFilters.minScore,
          max_score: newFilters.maxScore,
          name_prefix: newFilters.namePrefix,
          // We exclude tier filters from this on purpose
          // vendor_tiers: newFilters.vendorTiers,
          vendor_assessment_classifications:
            newFilters.vendorAssessmentClassifications,
          vendor_assessment_authors: newFilters.vendorAssessmentAuthors,
          vendor_assessment_authors_not:
            newFilters.vendorAssessmentAuthorDoNotMatch,
          vendor_reassessment_start: newFilters.vendorReassessmentStartDate,
          vendor_reassessment_end: newFilters.vendorReassessmentEndDate,
          vendor_reassessment_before: newFilters.vendorReassessmentDateBefore,
          vendor_reassessment_between: newFilters.vendorReassessmentDateBetween,
          portfolio_ids: newFilters.portfolioIds,
          // since the attribute filters are complex we serialise them to json
          attributes: JSON.stringify(newFilters.selectedAttributes),
          survey_type_ids: newFilters.vendorSurveyTypes,
          evidence_type_ids: newFilters.vendorEvidenceTypes,
          fourth_party_product_uuids: newFilters.fourthPartyProductUUIDs,
        },
        { method: "GET" },
        dispatch,
        getState
      );

      json = await prom;
    } catch (e) {
      LogError("error fetching vendor tiers", e);
      dispatch(addDefaultUnknownErrorAlert("Error retrieving vendor tiers"));

      throw e;
    }

    // Set redux state
    dispatch(setVendorTiers(json));

    return json;
  };
};

export const updateVendorTiers = (update: UpdateVendorTiersRequestV1) => {
  return async (
    dispatch: DefaultThunkDispatch,
    getState: () => DefaultRootState
  ) => {
    try {
      await FetchCyberRiskUrl<VendorTier[]>(
        "vendortiers/v1/",
        {},
        { method: "POST", body: JSON.stringify(update) },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("error updating vendor tiers", e);
      if (
        e instanceof ResponseError &&
        e.response.status === 422 &&
        e.json.error
      ) {
        dispatch(addSimpleErrorAlert("Error - " + e.json.error));
      } else {
        dispatch(addDefaultUnknownErrorAlert("Error updating vendor tiers"));
      }

      throw e;
    }

    dispatch(setVendorTiers(update.tiers));
    dispatch(refreshVendorRiskMatrix());
  };
};

export const applyVendorTiers = (
  apply: ApplyVendorTiersRequestV1,
  refreshVendorLists = true
) => {
  return async (
    dispatch: DefaultThunkDispatch,
    getState: () => DefaultRootState
  ) => {
    try {
      await FetchCyberRiskUrl<VendorTier[]>(
        "vendortiers/v1/",
        {},
        { method: "PUT", body: JSON.stringify(apply) },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("error applying vendor tiers", e);
      dispatch(
        addDefaultUnknownErrorAlert("Error applying changes to vendor tiers")
      );

      throw e;
    }

    // Re-get vendor tiers for counts
    dispatch(fetchVendorTiers(true));

    trackEvent("VendorRisk_VendorTierApplied");

    if (refreshVendorLists) {
      await dispatch(refreshVendorListsAfterChange(apply.vendorIds));
      dispatch(refreshVendorRiskMatrix());
    }
  };
};
