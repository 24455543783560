import { Component } from "react";
import { DefaultThunkDispatchProp } from "../../_common/types/redux";

import { IVendorHierarchy } from "../../_common/types/vendor";
import TreeTable, { ITreeTableRow } from "./TreeTable";
import { fetchBreachsightSubsidiaries } from "../reducers/breachsightSubsidiaries.actions";
import { XTableCell } from "../../_common/components/core/XTable";

import "../style/components/SubsidiarySelector.scss";
import { appConnect } from "../../_common/types/reduxHooks";

export interface ISubsidiarySelectorOwnProps {
  selectedVendorId?: number;
  setSelectedVendorId: (vendorId: number) => void;
  topLevelSelectionDisabled: boolean; // If true, the top level item cannot be selected
  suppliedSubsidiaries?: IVendorHierarchy;
  allRowsOpenInitially?: boolean;
  readOnlyMode?: boolean;
}

interface ISubsidiarySelectorConnectedProps {
  loadedSubsidiaries?: IVendorHierarchy;
}

type ISubsidiarySelectorProps = ISubsidiarySelectorOwnProps &
  ISubsidiarySelectorConnectedProps &
  DefaultThunkDispatchProp;

interface ISubsidiarySelectorState {
  openRows: { [id: string]: boolean | undefined };
}

class SubsidiarySelector extends Component<
  ISubsidiarySelectorProps,
  ISubsidiarySelectorState
> {
  static defaultProps = {
    topLevelSelectionDisabled: false,
    suppliedSubsidiaries: undefined,
    allRowsOpen: false,
    readOnlyMode: false,
  };

  static columnHeaders = [
    { id: "company", text: "Company" },
    { id: "website", text: "Website" },
  ];

  state = {
    openRows: {} as { [id: string]: boolean | undefined },
  };

  componentDidMount() {
    if (!this.props.suppliedSubsidiaries) {
      this.props.dispatch(fetchBreachsightSubsidiaries(false)).then((sub) => {
        // Ensure the topmost row defaults to open
        this.setState({
          openRows: { ...this.state.openRows, [sub.vendorId]: true },
        });
      });
    }

    if (this.props.suppliedSubsidiaries && this.props.allRowsOpenInitially) {
      this.setInitialOpenRows(this.props.suppliedSubsidiaries);
    }
  }

  setInitialOpenRows(sub: IVendorHierarchy) {
    if (sub.children) {
      for (let i = 0; i < sub.children.length; i++) {
        this.setInitialOpenRows(sub.children[i]);
      }
    }
    this.setState((state) => ({
      openRows: {
        ...state.openRows,
        [sub.vendorId]: true,
      },
    }));
  }

  generateRows = (rows: ITreeTableRow[], sub: IVendorHierarchy) => {
    const children: ITreeTableRow[] = [];

    const subsidiaries = this.props.suppliedSubsidiaries
      ? this.props.suppliedSubsidiaries
      : this.props.loadedSubsidiaries;

    if (sub.children) {
      for (let i = 0; i < sub.children.length; i++) {
        this.generateRows(children, sub.children[i]);
      }
    }

    const selectionDisabled =
      this.props.topLevelSelectionDisabled &&
      subsidiaries?.vendorId === sub.vendorId;

    const openRow = !!this.state.openRows[sub.vendorId];

    rows.push({
      id: sub.vendorId,
      selectionDisabled,
      selected: this.props.selectedVendorId === sub.vendorId,
      open: openRow,
      onClick: !selectionDisabled
        ? () => this.props.setSelectedVendorId(sub.vendorId)
        : undefined,
      children,
      cells: [
        <XTableCell key="company">{sub.name}</XTableCell>,
        <XTableCell key="website" className="website-col">
          {sub.primaryHostname}
        </XTableCell>,
      ],
    } as ITreeTableRow);
  };

  render() {
    const rows: ITreeTableRow[] = [];

    const subsidiaries = this.props.suppliedSubsidiaries
      ? this.props.suppliedSubsidiaries
      : this.props.loadedSubsidiaries;

    if (subsidiaries) {
      this.generateRows(rows, subsidiaries);
    }

    return (
      <TreeTable
        className="subsidiary-selector"
        loading={!subsidiaries}
        rowsTree={rows}
        selectable={!this.props.readOnlyMode}
        radioSelector
        onSelectClick={(rowId) =>
          this.props.setSelectedVendorId(rowId as number)
        }
        columnHeaders={SubsidiarySelector.columnHeaders}
        subTableColumnHeaders={SubsidiarySelector.columnHeaders}
        includeTreeDiagram
        onClickTreeDiagram={(rowId) =>
          this.setState(({ openRows }) => {
            if (openRows[rowId]) {
              const newOpenRows = { ...openRows };
              delete newOpenRows[rowId];
              return { openRows: newOpenRows };
            }

            return {
              openRows: {
                ...openRows,
                [rowId]: true,
              },
            };
          })
        }
      />
    );
  }
}

export default appConnect<
  ISubsidiarySelectorConnectedProps,
  never,
  ISubsidiarySelectorOwnProps
>((state) => {
  return {
    loadedSubsidiaries: state.cyberRisk.customerData.subsidiaries,
  };
})(SubsidiarySelector);
