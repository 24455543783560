import { UploadNode } from "../../../survey_builder/types/types";
import DragDropUpload from "../../../_common/components/DragDropUpload";
import { AcceptedDocumentFileExtensionsList } from "../../../vendorrisk/components/modals/DocumentModal";
import {
  addDefaultUnknownErrorAlert,
  addSimpleErrorAlert,
} from "../../../_common/reducers/messageAlerts.actions";
import { uploadSurveyFile } from "../../reducers/apiActions";
import {
  fileAnswersToAnswer,
  getAnswerFilesFromFileAnswer,
  stripLeadingDisplayId,
} from "../../surveyViewer.helpers";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import React, { FC, useCallback, useMemo, useState } from "react";
import { BaseNodeContentProps } from "./baseNode";
import SurveyViewerDocument, {
  getSurveyFileDownloadUrl,
} from "../SurveyViewerDocument";
import { getTextDiff } from "./InputNodeContent";
import { useAppDispatch } from "../../../_common/types/reduxHooks";
import {
  useBasicPermissions,
  UserReadContentLibrary,
} from "../../../_common/permissions";
import ContentLibraryBrowserModal from "../../../contentlibrary/components/ContentLibraryBrowserModal";
import Button from "../../../_common/components/core/Button";
import { ContentLibraryDocument } from "../../../contentlibrary/types/contentLibrary.types";
import SurveyViewerContentLibraryDocument from "../SurveyViewerContentLibraryDocument";
import { LogError } from "../../../_common/helpers";
import ContentLibrarySuggestedDocuments from "../../../contentlibrary/components/ContentLibrarySuggestedDocuments";
import { SurveyUsageType } from "../../../_common/types/surveyTypes";
import {
  AcceptedDocumentFileExtensions,
  AcceptedDocumentMimeTypes,
} from "../../../_common/types/fileRestrictions";

interface UploadNodeContentProps extends BaseNodeContentProps {
  node: UploadNode;
}

const UploadNodeContent: FC<UploadNodeContentProps> = ({
  surveyId,
  surveyUsageType,
  disabled,
  answer,
  otherAnswer,
  onAnswerChanged,
  node,
  diffSide,
  isPublicSurvey,
}) => {
  const dispatch = useAppDispatch();
  const perms = useBasicPermissions();
  const contentLibraryEnabled =
    !!perms.userPermissions[UserReadContentLibrary] &&
    surveyUsageType === SurveyUsageType.Security;

  const [isUploading, setIsUploading] = useState(false);

  // Also disable when we have no surveyId (e.g. previewing)
  const shouldDisable = !surveyId || disabled;

  // Get the filename of any files that have already been uploaded
  const fileAnswers = useMemo(
    () => getAnswerFilesFromFileAnswer(answer),
    [answer]
  );
  // Get the other answer for if we are diffing
  const otherFileAnswers = useMemo(
    () => getAnswerFilesFromFileAnswer(otherAnswer),
    [otherAnswer]
  );

  const [contentLibraryModalOpen, setContentLibraryModalOpen] = useState(false);
  const addedDocumentUUIDs = useMemo(() => {
    const addedDocumentUUIDs: string[] = [];
    for (let i = 0; i < fileAnswers.length; i++) {
      const uuid = fileAnswers[i].contentLibraryUUID;
      if (uuid) {
        addedDocumentUUIDs.push(uuid);
      }
    }
    return addedDocumentUUIDs;
  }, [fileAnswers]);
  const onAddContentLibraryDocument = useCallback(
    (doc: ContentLibraryDocument) => {
      const newFileAnswers = [...fileAnswers];
      newFileAnswers.push({
        gcsObjName: "",
        filename: doc.versions[0].filename,
        description: doc.description,
        contentLibraryUUID: doc.uuid,
        contentLibraryVersion: doc.versions[0].version,
        documentName: doc.name,
      });
      onAnswerChanged(node.nodeId, fileAnswersToAnswer(newFileAnswers));
    },
    [fileAnswers, node.nodeId, onAnswerChanged]
  );
  const onRemoveContentLibraryDocument = useCallback(
    (uuid: string) => {
      const newFileAnswers = fileAnswers.filter(
        (nf) => nf.contentLibraryUUID !== uuid
      );
      onAnswerChanged(node.nodeId, fileAnswersToAnswer(newFileAnswers));
    },
    [fileAnswers, onAnswerChanged, node.nodeId]
  );

  const onFileUploadedWithoutContentLibrary = useCallback(
    async (file: File) => {
      setIsUploading(true);

      try {
        const resp = await dispatch(
          uploadSurveyFile(
            surveyId ?? 0,
            node.nodeId,
            file,
            isPublicSurvey,
            node.customNumber ?? "",
            node.mainTextSanitised ?? ""
          )
        );

        const newFileAnswers = [...fileAnswers];
        newFileAnswers.push({
          gcsObjName: resp.attachment.gcsObjectName,
          filename: resp.attachment.filename,
          description: "",
        });
        onAnswerChanged(node.nodeId, fileAnswersToAnswer(newFileAnswers));
        setIsUploading(false);
      } catch (e) {
        LogError("error uploading file", e);
        dispatch(addDefaultUnknownErrorAlert("Error uploading file"));
        setIsUploading(false);
      }
    },
    [
      dispatch,
      fileAnswers,
      isPublicSurvey,
      node.nodeId,
      node.customNumber,
      node.mainTextSanitised,
      onAnswerChanged,
      surveyId,
    ]
  );

  // when diffing we want to do the following:
  // * if the whole file is added/removed change the background color
  // * if the description name has changed use diff highlighting

  const uploadedFileDisplays = fileAnswers.map((f) => {
    const otherFile = otherFileAnswers.find(
      (of) =>
        (of.gcsObjName && of.gcsObjName === f.gcsObjName) ||
        (of.contentLibraryUUID &&
          of.contentLibraryUUID === f.contentLibraryUUID)
    );

    const description: React.ReactNode =
      !diffSide || !otherFile
        ? f.description
        : getTextDiff(f.description, otherFile.description, diffSide);

    return (
      <CSSTransition
        key={f.gcsObjName || f.contentLibraryUUID}
        timeout={350}
        classNames="expand-350"
      >
        <div className={"file-answer"}>
          {f.contentLibraryUUID ? (
            <SurveyViewerContentLibraryDocument
              uuid={f.contentLibraryUUID}
              documentName={f.documentName ?? ""}
              documentVersion={f.contentLibraryVersion ?? 0}
              filename={f.filename}
              description={f.description}
              disabled={shouldDisable}
              onDelete={() =>
                onRemoveContentLibraryDocument(f.contentLibraryUUID!)
              }
              diffSide={!!diffSide && !otherFile ? diffSide : undefined}
              surveyId={surveyId}
              publicSurvey={isPublicSurvey}
            />
          ) : (
            <SurveyViewerDocument
              filename={f.filename}
              description={description}
              disabled={shouldDisable}
              diffSide={!!diffSide && !otherFile ? diffSide : undefined}
              onDescriptionEdited={(newDesc) => {
                const currentFileAnswers = getAnswerFilesFromFileAnswer(answer);

                const fileAnswerToUpdate = currentFileAnswers.find(
                  (fa) => fa.gcsObjName === f.gcsObjName
                );
                if (fileAnswerToUpdate) {
                  fileAnswerToUpdate.description = newDesc;
                  onAnswerChanged(
                    node.nodeId,
                    fileAnswersToAnswer(currentFileAnswers)
                  );
                }

                // We can resolve this immediately, saving is taken care of asynchronously in the main answer save
                return Promise.resolve();
              }}
              onDelete={() => {
                const currentFileAnswers = getAnswerFilesFromFileAnswer(answer);

                const newFileAnswers = currentFileAnswers.filter(
                  (nf) => nf.gcsObjName !== f.gcsObjName
                );
                onAnswerChanged(
                  node.nodeId,
                  fileAnswersToAnswer(newFileAnswers)
                );
                return Promise.resolve();
              }}
              onDownload={() => {
                window.open(
                  getSurveyFileDownloadUrl(
                    surveyId ?? 0,
                    f.gcsObjName,
                    node.nodeId,
                    isPublicSurvey
                  )
                );
              }}
            />
          )}
        </div>
      </CSSTransition>
    );
  });

  return (
    <div className={"question-answer-node-content"}>
      <div className={"current-files-uploaded"}>
        <TransitionGroup component={null}>
          {uploadedFileDisplays}
        </TransitionGroup>
      </div>
      {surveyId && disabled && uploadedFileDisplays.length === 0 && (
        <div className={"no-answer"}>No file(s) provided.</div>
      )}
      {(!surveyId || !shouldDisable) && (
        <>
          {contentLibraryEnabled ? (
            <>
              <Button
                filledPrimary
                disabled={shouldDisable}
                onClick={() => setContentLibraryModalOpen(true)}
              >
                <div className="cr-icon-plus" /> Add document
              </Button>
              {!shouldDisable && (
                <>
                  <ContentLibrarySuggestedDocuments
                    addedDocumentUUIDs={addedDocumentUUIDs}
                    onAddDocument={onAddContentLibraryDocument}
                    searchQuery={
                      node.mainTextSanitised
                        ? stripLeadingDisplayId(node.mainTextSanitised)
                        : ""
                    }
                    onlyFetchWhenVisible
                  />
                  <ContentLibraryBrowserModal
                    addedDocumentUUIDs={addedDocumentUUIDs}
                    onAddDocument={onAddContentLibraryDocument}
                    onRemoveDocument={onRemoveContentLibraryDocument}
                    onUploadDocumentWithoutWritePerm={
                      onFileUploadedWithoutContentLibrary
                    }
                    active={contentLibraryModalOpen}
                    onClose={() => setContentLibraryModalOpen(false)}
                  />
                </>
              )}
            </>
          ) : (
            <DragDropUpload
              loading={isUploading}
              disabled={shouldDisable}
              doNotKeepState
              maxFileSize={50000000}
              acceptedFileTypeFilters={[
                ...AcceptedDocumentFileExtensions,
                ...AcceptedDocumentMimeTypes,
              ]}
              onFileRejected={() => {
                dispatch(
                  addSimpleErrorAlert(
                    "File must be under 50 MB and be one of the following types: " +
                      AcceptedDocumentFileExtensionsList
                  )
                );
              }}
              onFileSelected={onFileUploadedWithoutContentLibrary}
            />
          )}
        </>
      )}
    </div>
  );
};

export default UploadNodeContent;
