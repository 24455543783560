import { Severity } from "../../_common/types/severity";
import { FC } from "react";
import {
  ButtonWithDropdownV2,
  DropdownItem,
} from "../../_common/components/core/DropdownV2";
import { severityMap } from "../../_common/helpers";

export interface SeveritySelectorButtonProps {
  severity?: Severity;
  onChange: (newSeverity?: Severity) => void;
  placeholder?: string;
}

export const SeveritySelectorButton: FC<SeveritySelectorButtonProps> = ({
  severity,
  onChange,
  placeholder,
}) => (
  <ButtonWithDropdownV2
    buttonProps={{
      tertiary: false,
      primary: !!severity,
    }}
    text={!severity ? placeholder ?? "Severity" : severityMap[severity].label}
    showChevron
  >
    <DropdownItem selected={!severity} onClick={() => onChange(undefined)}>
      All
    </DropdownItem>
    {Object.values(Severity)
      .filter((s) => severityMap[s].num > 0)
      .reverse()
      .map((s) => (
        <DropdownItem
          key={s}
          selected={s === severity}
          onClick={() => onChange(s === severity ? undefined : s)}
        >
          {severityMap[s].label}
        </DropdownItem>
      ))}
  </ButtonWithDropdownV2>
);
