import { FetchCyberRiskUrl } from "../../_common/api";
import { LogError } from "../../_common/helpers";
import {
  loadUserOrg,
  setOrgLoading,
} from "../../_common/reducers/commonActions";
import moment from "moment";
import { getCyberRiskAuth } from "../../_common/session";
import {
  addDefaultSuccessAlert,
  addDefaultUnknownErrorAlert,
  addSimpleErrorAlert,
} from "../../_common/reducers/messageAlerts.actions";
import { fetchDomains } from "../../vendorrisk/reducers/domains.actions";
import { fetchAvailableLabels } from "../../vendorrisk/reducers/cyberRiskActions";

export const SET_ORGS = "ADMIN_SET_ORGS";
export const SET_PARENT_ORGS = "ADMIN_SET_PARENT_ORGS";
export const SET_SURVEY_TYPES = "ADMIN_SET_SURVEY_TYPE_LIST";
export const SET_VENDOR_MOVES = "ADMIN_SET_VENDOR_MOVES";
export const SET_VENDOR_ATTRIBUTE_OVERRIDES =
  "ADMIN_SET_VENDOR_ATTRIBUTE_OVERRIDES";
export const SET_BUCKET_ALIASES = "ADMIN_SET_BUCKET_ALIASES";
export const SET_REGISTERED_DOMAINS = "ADMIN_SET_REGISTERED_DOMAINS";
export const SET_REGISTERED_DOMAINS_WORKING =
  "ADMIN_SET_REGISTERED_DOMAINS_WORKING";
export const SET_SYSTEM_SURVEYS_SECTIONS = "ADMIN_SET_SYSTEM_SURVEYS_SECTIONS";
export const SET_VENDOR_ONBOARDING_JOBS = "ADMIN_SET_VENDOR_ONBOARDING_JOBS";
export const SET_VENDOR_ONBOARDING_JOB = "ADMIN_SET_VENDOR_ONBOARDING_JOB";
export const SET_ORGANISATION_GROUPS = "ADMIN_SET_ORGANISATION_GROUPS";

export const setOrgs = (orgs, orgFlags) => {
  return {
    type: SET_ORGS,
    orgs,
    orgFlags,
  };
};

export const setParentOrgs = (orgs, flags) => {
  return {
    type: SET_PARENT_ORGS,
    orgs,
    flags,
  };
};

export const setSurveyTypes = (types) => {
  return {
    type: SET_SURVEY_TYPES,
    types,
  };
};

export const setVendorMoves = (vendorMoves) => {
  return {
    type: SET_VENDOR_MOVES,
    vendorMoves,
  };
};

export const setVendorAttributeOverrides = (vendorAttributeOverrides) => {
  return {
    type: SET_VENDOR_ATTRIBUTE_OVERRIDES,
    vendorAttributeOverrides,
  };
};

export const setBucketAliases = (aliases) => {
  return {
    type: SET_BUCKET_ALIASES,
    aliases,
  };
};

export const setHIBPRegisteredDomains = (
  registeredDomains,
  unregisteredDomains,
  loading,
  error
) => {
  return {
    type: SET_REGISTERED_DOMAINS,
    registeredDomains,
    unregisteredDomains,
    loading,
    error,
  };
};

export const setHIBPRegisteredDomainsWorking = (working) => {
  return {
    type: SET_REGISTERED_DOMAINS_WORKING,
    working,
  };
};

export const setSystemSurveySectionsNames = (loading, error, data) => {
  return {
    type: SET_SYSTEM_SURVEYS_SECTIONS,
    loading,
    error,
    data,
  };
};

export const setVendorOnboardingJobs = (jobs) => {
  return {
    type: SET_VENDOR_ONBOARDING_JOBS,
    jobs,
  };
};

export const setVendorOnboardingJob = (job) => {
  return {
    type: SET_VENDOR_ONBOARDING_JOB,
    job,
  };
};

export const setOrganisationGroups = (groupsData) => {
  return {
    type: SET_ORGANISATION_GROUPS,
    groupsData,
  };
};

/** ADMIN ASYNC ACTION CREATORS * */

const connectToApi = (connectedAction) => {
  return async (dispatch, getState) => {
    const callApi = async (endpoint, options, fetchOptions) => {
      return FetchCyberRiskUrl(
        endpoint,
        options,
        fetchOptions,
        dispatch,
        getState
      );
    };
    return connectedAction(dispatch, getState, callApi);
  };
};

export const fetchVendorMoves = () => {
  return connectToApi(async (dispatch, getState, callApi) => {
    let json;

    dispatch(
      setVendorMoves({
        loading: true,
        error: null,
        data: null,
      })
    );

    try {
      json = await callApi("admin/vendor_moves/v1/", null, null);
    } catch (e) {
      LogError("Error fetching vendor moves", e);

      dispatch(
        setVendorMoves({
          loading: false,
          error: e.message,
          data: null,
        })
      );
      return;
    }

    dispatch(
      setVendorMoves({
        loading: false,
        error: null,
        data: json.moves,
      })
    );
  });
};

export const fetchAdminVendorSummaryByHostname = (hostname) => {
  return connectToApi(async (dispatch, getState, callApi) => {
    let json;
    try {
      json = await callApi("admin/vendor_summary/v1/", {
        hostname,
      });
    } catch (e) {
      LogError("Error fetching admin vendor summary", e);
      throw e;
    }
    return json;
  });
};

export const mergeVendors = (
  sourceDatastoreVendorId,
  destinationDatastoreVendorId
) => {
  return connectToApi(async (dispatch, getState, callApi) => {
    try {
      await callApi(
        "admin/merge_vendors/v1/",
        {
          source_datastore_vendor_id: sourceDatastoreVendorId,
          destination_datastore_vendor_id: destinationDatastoreVendorId,
        },
        { method: "POST" }
      );
    } catch (e) {
      LogError("Error merging vendors", e);
      throw e;
    }

    dispatch(fetchVendorMoves());
  });
};

export const moveDomainsToExistingVendor = (
  domains,
  sourceDatastoreVendorId,
  destinationDatastoreVendorId
) => {
  return connectToApi(async (dispatch, getState, callApi) => {
    try {
      await callApi(
        "admin/move_domains/existing_vendor/v1/",
        {
          domains,
          source_datastore_vendor_id: sourceDatastoreVendorId,
          destination_datastore_vendor_id: destinationDatastoreVendorId,
        },
        { method: "POST" }
      );
    } catch (e) {
      LogError("Error moving domains", e);
      throw e;
    }

    dispatch(fetchVendorMoves());
  });
};

export const moveDomainsToNewVendor = (
  sourceDatastoreVendorId,
  newVendorName,
  newVendorPrimaryDomain,
  additionalDomains
) => {
  return connectToApi(async (dispatch, getState, callApi) => {
    try {
      await callApi(
        "admin/move_domains/new_vendor/v1/",
        {
          source_datastore_vendor_id: sourceDatastoreVendorId,
          new_vendor_primary_domain: newVendorPrimaryDomain,
          new_vendor_name: newVendorName,
          additional_domains: additionalDomains,
        },
        { method: "POST" }
      );
    } catch (e) {
      LogError("Error moving domains to new vendor", e);
      throw e;
    }

    dispatch(fetchVendorMoves());
  });
};

export const fetchVendorAttributeOverrides = () => {
  return connectToApi(async (dispatch, getState, callApi) => {
    let json;

    setVendorAttributeOverrides({
      loading: true,
      error: null,
    });

    try {
      json = await callApi("admin/vendor_attribute_overrides/v1/", null, null);
    } catch (e) {
      LogError("Error fetching vendor attribute overrides", e);
      setVendorAttributeOverrides({
        loading: false,
        error: e.message,
        data: null,
      });
      return;
    }

    dispatch(
      setVendorAttributeOverrides({
        loading: false,
        error: null,
        data: json.overrides,
      })
    );
  });
};

export const updateVendorAttributeOverride = (
  primaryHostname,
  attributes,
  isNew
) => {
  return connectToApi(async (dispatch, getState, callApi) => {
    try {
      await callApi(
        "admin/vendor_attribute_overrides/v1/",
        {
          PrimaryHostname: primaryHostname,
          ...attributes,
        },
        { method: isNew ? "POST" : "PUT" }
      );
    } catch (e) {
      LogError("Error updating vendor attribute override", e);
      throw e;
    }

    dispatch(fetchVendorAttributeOverrides());
  });
};

export const deleteVendorAttributeOverride = (primaryHostname) => {
  return connectToApi(async (dispatch, getState, callApi) => {
    try {
      await callApi(
        "admin/vendor_attribute_overrides/v1/",
        { PrimaryHostname: primaryHostname },
        { method: "DELETE" }
      );
    } catch (e) {
      LogError("Error updating vendor attribute override", e);
      throw e;
    }

    const overrides = [...getState().admin.vendorAttributeOverrides.data];
    const newOverrides = [];
    for (let i = 0; i < overrides.length; i++) {
      if (overrides[i].primaryHostname !== primaryHostname) {
        newOverrides.push(overrides[i]);
      }
    }

    dispatch(setVendorAttributeOverrides({ data: newOverrides }));
  });
};

export const fetchOrgs = (
  include_active,
  include_inactive,
  account_types,
  account_plans
) => {
  return connectToApi(async (dispatch, getState, callApi) => {
    let json;

    let include_no_plan = !account_plans || account_plans.length == 0;
    const plans = [];
    if (include_active !== false && include_active !== true) {
      include_active = true;
      include_inactive = false;
      account_types = [];
      account_plans = [];
    }

    if (!!account_plans) {
      account_plans.map((plan) => {
        if (plan == "(empty)") {
          include_no_plan = true;
        } else {
          plans.push(plan);
        }
      });
    }

    dispatch(
      setOrgs(
        {
          loading: true,
          error: null,
          data: null,
        },
        false,
        {}
      )
    );

    try {
      json = await callApi(
        "admin/organisations/v1/",
        {
          include_active: include_active,
          include_inactive: include_inactive,
          account_types: account_types,
          account_plans: plans,
          include_no_plan: include_no_plan,
        },
        null
      );
    } catch (e) {
      LogError("Error fetching orgs", e);

      dispatch(
        setOrgs(
          {
            loading: false,
            error: e.message,
            data: null,
          },
          false,
          {}
        )
      );
      return;
    }

    dispatch(
      setOrgs(
        {
          loading: false,
          error: null,
          data: json.orgs,
        },
        json.orgFlags
      )
    );
    return json.orgs;
  });
};

export const fetchOrgsForParent = (
  include_active,
  include_inactive,
  account_types,
  account_plans
) => {
  return connectToApi(async (dispatch, getState, callApi) => {
    let json;

    let include_no_plan = !account_plans || account_plans.length == 0;
    const plans = [];
    if (!!account_plans) {
      account_plans.map((plan) => {
        if (plan == "(empty)") {
          include_no_plan = true;
        } else {
          plans.push(plan);
        }
      });
    }

    dispatch(
      setParentOrgs(
        {
          loading: true,
          error: null,
          data: null,
          disabledData: null,
        },
        false
      )
    );

    try {
      json = await callApi(
        "admin/childorganisations/v1/",
        {
          include_active: include_active,
          include_inactive: include_inactive,
          account_types: account_types,
          account_plans: plans,
          include_no_plan: include_no_plan,
        },
        null
      );
    } catch (e) {
      LogError("Error fetching orgs for parent", e);

      dispatch(
        setParentOrgs(
          {
            loading: false,
            error: e.message,
            data: null,
            disabledData: null,
          },
          false
        )
      );
      return;
    }

    dispatch(
      setParentOrgs(
        {
          loading: false,
          error: null,
          data: json.orgs,
          disabledData: json.disabledOrgs,
        },
        json.flags
      )
    );
  });
};

export const newOrg = (
  name,
  customerEmail,
  selectedBucketAlias,
  createUnderDistributor,
  productFeatures,
  accountType,
  accountPlan,
  trialExpiresOn,
  mfaEnabled
) => {
  const email = customerEmail.trim().toLowerCase();
  return connectToApi(async (dispatch, getState, callApi) => {
    let json;

    try {
      json = await callApi(
        "admin/organisations/v1/",
        {
          name,
          customerEmail: email,
          createUnderDistributor,
          selectedBucketAlias,
          accountType,
          accountPlan,
          trialExpiresOn,
          mfa_enabled: mfaEnabled,
        },
        { method: "POST", body: JSON.stringify(productFeatures) }
      );
    } catch (e) {
      LogError("Error creating org", e);
      throw new Error(`error creating new org: ${e.message}`);
    }

    if (json && json.status === "ERROR" && json.desc) {
      throw new Error(`Error creating org: ${json.desc}`);
    }

    if (createUnderDistributor) {
      dispatch(setOrgLoading(true));
    }

    dispatch(loadUserOrg(getState().common.userData.currentOrgID));
  });
};

export const getEntitlements = (orgID) => {
  return connectToApi(async (dispatch, getState, callApi) => {
    let json;
    try {
      json = await callApi("admin/features/v1/", { org_id: orgID }, {});
    } catch (e) {
      LogError("Error getting entitlements", e);
      throw new Error(`error getting entitlements: ${e.message}`);
    }

    if (!json || (json && json.status !== "OK")) {
      throw new Error("error getting entitlements");
    }

    return json;
  });
};

export const getConfig = (orgID) => {
  return connectToApi(async (dispatch, getState, callApi) => {
    let json;
    try {
      json = await callApi("admin/org_config/v1/", { org_id: orgID }, {});
    } catch (e) {
      LogError("Error getting entitlements", e);
      throw new Error(`error getting entitlements: ${e.message}`);
    }

    if (!json) {
      throw new Error("error getting entitlements");
    }

    return json;
  });
};

export const updateEntitlements = (orgID, permission, enabled) => {
  return connectToApi(async (dispatch, getState, callApi) => {
    let json;
    try {
      json = await callApi(
        "admin/feature/v1/",
        {
          org_id: orgID,
          feature: permission,
          enabled,
        },
        { method: "PUT" }
      );
    } catch (e) {
      LogError("Error updating entitlements", e);
      throw new Error(`error updating entitlements: ${e.message}`);
    }

    if (!json || (json && json.status !== "OK")) {
      throw new Error("error updating entitlements");
    }
  });
};

export const editOrg = (id, productFeatures, overrideParentRemoval) => {
  return connectToApi(async (dispatch, getState, callApi) => {
    try {
      await callApi(
        "admin/organisations/v2/",
        {
          id,
          overrideParentRemoval,
        },
        { method: "PUT", body: JSON.stringify(productFeatures) }
      );
    } catch (e) {
      LogError("Error editing org", e);
      throw new Error(`error editing org: ${e.message}`);
    }
  });
};

export const disableOrg = (id) => {
  return connectToApi(async (dispatch, getState, callApi) => {
    try {
      await callApi("admin/organisations/v1/", { id }, { method: "DELETE" });
    } catch (e) {
      LogError("Error disabling org", e);
      throw new Error(`error disabling org: ${e.message}`);
    }
  });
};

export const reenableOrg = (id) => {
  return connectToApi(async (dispatch, getState, callApi) => {
    try {
      await callApi(
        "admin/organisations/reenable/v1/",
        { id },
        { method: "PUT" }
      );
    } catch (e) {
      LogError("Error re-enabling org", e);
      throw new Error(`error re-enabling org: ${e.message}`);
    }
  });
};

export const fetchAllSurveyTypes = () => {
  return connectToApi(async (dispatch, getState, callApi) => {
    let json;
    try {
      json = await callApi("survey/types/v1/", null, null);
    } catch (e) {
      LogError("Error fetching survey types", e);
      dispatch(setSurveyTypes(null));
      return;
    }

    dispatch(setSurveyTypes(json.surveyTypes));
  });
};

export const uploadCyberEyeData = (orgId, file) => {
  return async (dispatch, getState) => {
    try {
      await FetchCyberRiskUrl(
        "admin/breachsight/v1",
        { id: orgId },
        { method: "POST", body: file },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error uploading breachsight data", e);
      throw e;
    }
  };
};

export const fetchRegionalBucketAliases = (refresh) => {
  return connectToApi(async (dispatch, getState, callApi) => {
    let json;
    try {
      json = await callApi("admin/bucketaliases/v1/", { refresh }, {});
    } catch (e) {
      LogError("Error fetching survey types", e);
      dispatch(setBucketAliases({}));
      return;
    }

    dispatch(setBucketAliases(json.aliases));
  });
};

export const fetchHIBPRegisteredEmailDomains = (refresh) => {
  return connectToApi(async (dispatch, getState, callApi) => {
    let json;
    dispatch(setHIBPRegisteredDomains([], [], true, null));
    try {
      json = await callApi("emaildomainslist/v1/", { refresh }, {});
    } catch (e) {
      LogError("Error fetching registered domain list", e);
      dispatch(setHIBPRegisteredDomains([], [], false, e));
      return;
    }
    dispatch(
      setHIBPRegisteredDomains(
        json.registeredDomains,
        json.unregisteredDomains,
        false,
        null
      )
    );
  });
};

export const registerHIBPDomain = (domain) => {
  return connectToApi(async (dispatch, getState, callApi) => {
    let json;
    try {
      json = await callApi(
        "emaildomain/v1/",
        { domain_name: domain },
        { method: "PUT" }
      );
    } catch (e) {
      LogError("Error registering missing domain name", e);
      return;
    }
    dispatch(fetchHIBPRegisteredEmailDomains(true));
  });
};

export const validateBreachesForHIBPDomain = (domain) => {
  return connectToApi(async (dispatch, getState, callApi) => {
    let json;
    dispatch(setHIBPRegisteredDomainsWorking(true));
    try {
      json = await callApi(
        "emaildomain/validate/v1/",
        { domain_name: domain },
        { method: "GET" }
      );
    } catch (e) {
      LogError("Error validating breaches for domain", e);
      dispatch(setHIBPRegisteredDomainsWorking(false));
      return;
    }

    if (json.missingBreaches.length > 0) {
      dispatch(
        addSimpleErrorAlert(
          `Breaches exist for domain '${domain}' that were not found in CyberRisk. A request to retrieve the details of these breaches has been registered with the HIBP API`,
          [...json.missingBreaches]
        )
      );
      dispatch(setHIBPRegisteredDomainsWorking(false));
      return;
    }

    if (json.breaches.length == 0) {
      dispatch(
        addDefaultUnknownErrorAlert(
          `There are no breaches listed at all for domain '${domain}' on HIBP`
        )
      );
      return;
    }

    const breachName = json.breaches[0].Name;
    const breachDate = moment(json.breaches[0].BreachDate).format("YYYY-MM-DD");
    dispatch(
      addDefaultSuccessAlert(
        `There are no missing breaches for domain '${domain}'`,
        [`The last breach was ${breachName} on ${breachDate}.`]
      )
    );
    dispatch(setHIBPRegisteredDomainsWorking(false));
  });
};

export const fetchSystemLabelsForOrg = (orgId) => {
  return connectToApi(async (dispatch, getState, callApi) => {
    let json;
    try {
      json = await callApi("admin/labels/v1/", { org_id: orgId }, {});
    } catch (e) {
      LogError("error fetching system labels for org", e);
      throw e;
    }

    return json;
  });
};

export const updateSystemLabelsForOrg = (
  orgId,
  addedLabelIds,
  removedLabelIds
) => {
  return connectToApi(async (dispatch, getState, callApi) => {
    try {
      await callApi(
        "admin/labels/v1/",
        {
          org_id: orgId,
          added_label_ids: addedLabelIds,
          removed_label_ids: removedLabelIds,
        },
        { method: "PUT" }
      );
    } catch (e) {
      LogError("error fetching system labels for org", e);
      throw e;
    }
  });
};

// Async action to upload new survey CSV files (layout and questions definition)
export const uploadSurveySectionDefinition = (
  layoutFile,
  questionsFile,
  framework,
  surveyID,
  sectionID,
  allQuestionsCovered,
  writeSectionUpdate,
  surveyName,
  sectionName
) => {
  return async (dispatch, getState) => {
    // Now try uploading the files
    const data = new FormData();
    data.append("layout_file", layoutFile);
    data.append("questions_file", questionsFile);

    const { apiKey, token } = getCyberRiskAuth();

    let url = `/api/admin/surveydata/v1?survey_id=${surveyID}`;
    url = url + `&section_id=${sectionID}`;
    url = url + `&all_questions_covered=${allQuestionsCovered}`;
    url = url + `&update_section=${writeSectionUpdate}`;
    url = url + `&framework=${framework}`;
    if (surveyName) {
      url = url + `&survey_name=${surveyName}`;
    }
    if (sectionName) {
      url = url + `&section_name=${sectionName}`;
    }
    url = url + `&apikey=${encodeURIComponent(apiKey)}`;
    url = url + `&token=${encodeURIComponent(token)}`;

    let json;
    try {
      const req = new Request(url, {
        method: "POST",
        body: data,
      });
      const response = await fetch(req);
      if (response.status !== 200) {
        throw new Error("Error uploading section definitions file");
      }
      json = await response.json();
      if (json.status === "ERROR") {
        dispatch(
          addSimpleErrorAlert(
            "Validation of section structure failed. Please see validation log"
          )
        );
        return json;
      }
    } catch (e) {
      LogError("Error uploading new section definition", e);
      dispatch(
        addDefaultUnknownErrorAlert("Error uploading new section definition")
      );
      return;
    }
    return json;
  };
};

export const fetchSystemSurveyAndSectionNames = () => {
  return connectToApi(async (dispatch, getState, callApi) => {
    dispatch(setSystemSurveySectionsNames(true));
    let json;
    try {
      json = await callApi("admin/sectionlist/v1/", {}, {});
    } catch (e) {
      dispatch(
        setSystemSurveySectionsNames(false, "Failed to load survey types")
      );
      LogError("error fetching system survey types+sections", e);
      return null;
    }

    dispatch(setSystemSurveySectionsNames(false, null, json.types));
    return json.types;
  });
};

export const setDistributor = (orgId, distributorOrgId) => {
  return connectToApi(async (dispatch, getState, callApi) => {
    try {
      await callApi(
        "admin/organisations/distributor/v1/",
        {},
        {
          method: "POST",
          body: JSON.stringify({
            orgId: parseInt(orgId, 10),
            distributorOrgId: parseInt(distributorOrgId, 10),
          }),
        }
      );
    } catch (e) {
      LogError("Error setting distributor", e);
      throw new Error(`error setting distributor: ${e.message}`);
    }
  });
};

export const fetchDistributors = () => {
  return connectToApi(async (dispatch, getState, callApi) => {
    let json;
    try {
      json = await callApi(
        "admin/organisations/v1/",
        {
          include_active: true,
          include_inactive: false,
          account_types: "distributor",
        },
        null
      );
    } catch (e) {
      LogError("Error fetching distributors", e);
    }
    return json.orgs;
  });
};

export const getOrgAuthDetails = (orgID) => {
  return connectToApi(async (dispatch, getState, callApi) => {
    let json;
    try {
      json = await callApi(
        "admin/authsettings/v1/",
        {
          id: orgID,
        },
        null
      );
    } catch (e) {
      LogError("Error fetching org auth details", e);
      throw new Error(`error getting auth details: ${e.message}`);
    }
    return json.details;
  });
};
export const updateOrgAuthDetails = (orgID, details) => {
  return async (dispatch, getState) => {
    let json;
    const { ssoEnabled, ssoSupportsLogout, ssoDomains, mfaEnabled } = details;
    try {
      json = await FetchCyberRiskUrl(
        "admin/authsettings/v1/",
        {
          id: orgID,
          sso_enabled: ssoEnabled,
          mfa_enabled: mfaEnabled,
          sso_supports_logout: ssoSupportsLogout,
          sso_domains: ssoDomains,
        },
        { method: "POST" },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error updating auth details for org", e);
      throw new Error("Error updating auth details for org");
    }
    if (json.status == "DUPLICATE") {
      throw new Error(
        `domain '${json.domain}' is already registered for sso by another account`
      );
    }
  };
};

export const vendorOnboardingGetJobs = () => {
  return async (dispatch, getState) => {
    let jobs;
    try {
      jobs = await FetchCyberRiskUrl(
        "admin/vendor_onboard/jobs",
        null,
        null,
        dispatch,
        getState
      );
    } catch (e) {
      LogError("error getting vendor onboarding jobs", e);
      throw e;
    }

    dispatch(setVendorOnboardingJobs(jobs));

    return jobs;
  };
};

export const vendorOnboardingFindVendor = (hostname) => {
  return async (dispatch, getState) => {
    let result;
    try {
      result = await FetchCyberRiskUrl(
        "admin/vendor_onboard/find_vendor",
        { hostname },
        null,
        dispatch,
        getState
      );
    } catch (e) {
      LogError("error finding vendor by hostname", e);
      throw e;
    }

    return result;
  };
};

export const vendorOnboardingTriggerRescan = (vendorPrimaryDomain, domains) => {
  return async (dispatch, getState) => {
    try {
      await FetchCyberRiskUrl(
        "admin/vendor_onboard/trigger_rescan",
        null,
        {
          method: "POST",
          body: {
            vendor_primary_hostname: vendorPrimaryDomain,
            domains,
          },
        },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("error triggering vendor onboarding rescan", e);
      throw e;
    }
  };
};

export const vendorOnboardingGetRequiredOperations = (
  vendorPrimaryHostname,
  domains
) => {
  return async (dispatch, getState) => {
    let result;
    try {
      result = await FetchCyberRiskUrl(
        "admin/vendor_onboard/get_required_operations",
        null,
        {
          method: "POST",
          body: { vendor_primary_hostname: vendorPrimaryHostname, domains },
        },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("error triggering vendor onboarding rescan", e);
      throw e;
    }

    return result;
  };
};

export const vendorOnboardingTriggerMerges = (
  parentJobId,
  vendorId,
  originalDomains,
  moveDomains,
  mergePrimaryHostnames
) => {
  return async (dispatch, getState) => {
    try {
      await FetchCyberRiskUrl(
        "admin/vendor_onboard/trigger_merges",
        null,
        {
          method: "POST",
          body: {
            parent_job_id: parentJobId,
            target_vendor_id: vendorId,
            hostnames: originalDomains,
            move_domains: moveDomains,
            merge_primary_hostnames: mergePrimaryHostnames,
          },
        },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("error triggering vendor onboarding merges", e);
      throw e;
    }
  };
};

export const bulkAccountUpdatesValidateDomains = (file, orgId) => {
  return async (dispatch, getState) => {
    let json;
    try {
      const data = new FormData();
      data.append("file", file);

      json = await FetchCyberRiskUrl(
        "admin/bulkupdates/validate/v1",
        { org_id: orgId },
        { method: "POST", body: data },
        dispatch,
        getState
      );
    } catch (e) {
      console.error(e);
      throw e;
    }

    return json;
  };
};

export const bulkAccountUpdatesApplyBreachsightLabels = (
  overwriteExistingLabels,
  overwriteExistingPortfolios,
  domainsToUpdate,
  orgId
) => {
  return async (dispatch, getState) => {
    try {
      await FetchCyberRiskUrl(
        "admin/bulkupdates/breachsightlabels/v1",
        null,
        {
          method: "PUT",
          body: JSON.stringify({
            overwriteExistingLabels,
            overwriteExistingPortfolios,
            domainsToUpdate,
            orgId,
          }),
        },
        dispatch,
        getState
      );
    } catch (e) {
      console.error(e);
      throw e;
    }

    // re-fetch labels and domains
    // Note: the params have to exactly match what the view would use or the hash won't be completed correctly and this won't work
    // See domains.actions.ts getDomainParamsKey
    dispatch(fetchAvailableLabels());
    dispatch(
      fetchDomains(
        {
          is_customer: true,
          is_subsidiary: false,
          website_label_ids: [],
          website_label_ids_match_all: false,
          website_label_ids_do_not_match: false,
          website_include_unlabeled: false,
          website_portfolio_ids: [],
          risk_ids: [],
          appguard_cloud_providers: [],
          appguard_cloud_connection_uuids: [],
          active: true,
        },
        true
      )
    );
    dispatch(
      fetchDomains(
        {
          is_customer: true,
          is_subsidiary: false,
          website_label_ids: [],
          website_label_ids_match_all: false,
          website_label_ids_do_not_match: false,
          website_include_unlabeled: false,
          website_portfolio_ids: [],
          risk_ids: [],
          appguard_cloud_providers: [],
          appguard_cloud_connection_uuids: [],
          active: false,
        },
        true
      )
    );
  };
};

export const bulkAccountUpdatesGetVendorLabellingJobs = (orgId) => {
  return async (dispatch, getState) => {
    let jobs;
    try {
      jobs = await FetchCyberRiskUrl(
        "admin/bulkupdates/vendorlabels/jobs/v1",
        { org_id: orgId },
        null,
        dispatch,
        getState
      );
    } catch (e) {
      console.error(e);
      throw e;
    }

    return jobs;
  };
};

export const bulkAccountUpdatesDismissJob = (jobId) => {
  return async (dispatch, getState) => {
    try {
      await FetchCyberRiskUrl(
        "admin/bulkupdates/vendorlabels/jobs/v1",
        { job_id: jobId },
        { method: "DELETE" },
        dispatch,
        getState
      );
    } catch (e) {
      console.error(e);
      throw e;
    }
  };
};

export const bulkUploadCustomDomains = (orgId, file) => {
  return async (dispatch, getState) => {
    const data = new FormData();
    data.append("file", file);

    try {
      await FetchCyberRiskUrl(
        "admin/bulkupload/customdomains/v1/",
        {
          org_id: orgId,
        },
        { method: "POST", body: data },
        dispatch,
        getState
      );
    } catch (e) {
      if (e?.response?.status === 422 && e?.json?.error) {
        dispatch(addSimpleErrorAlert(e.json.error));
      } else {
        LogError("error starting bulk custom domains upload", e);
        dispatch(
          addDefaultUnknownErrorAlert(
            "Error starting bulk custom domains upload"
          )
        );
      }
      throw e;
    }
  };
};

export const bulkUploadCustomDomainsGetJobs = () => {
  return async (dispatch, getState) => {
    let jobs;
    try {
      jobs = await FetchCyberRiskUrl(
        "admin/bulkupload/customdomains/jobs/v1/",
        {},
        null,
        dispatch,
        getState
      );
    } catch (e) {
      console.error(e);
      throw e;
    }

    return jobs;
  };
};

export const bulkUploadCustomDomainsDismissJob = (jobId) => {
  return async (dispatch, getState) => {
    try {
      await FetchCyberRiskUrl(
        "admin/bulkupload/customdomains/jobs/v1/",
        { job_id: jobId },
        { method: "DELETE" },
        dispatch,
        getState
      );
    } catch (e) {
      console.error(e);
      throw e;
    }
  };
};
