import { FC } from "react";
import userbaseApi from "../../api/userbase.api";
import ListFilter from "./ListFilter";
import {
  OrgAccessUserBase,
  UserUserBaseEnabled,
} from "../../../_common/permissions";
import withRequireEntitlementAndPermission from "../../../_common/hocs/WithRequireEntitlementAndPermission";

interface UserRisksRolesFilterProps {
  selectedRoles: string[];
  excluded: boolean;
  onChange: (selectedRoles: string[], exclude: boolean) => void;
  title?: string;
}

const UserRisksRolesFilter: FC<UserRisksRolesFilterProps> = ({
  selectedRoles,
  excluded,
  onChange,
  title = "Roles",
}) => {
  const { data, isLoading } = userbaseApi.useGetUserBaseRolesV1Query();

  return (
    <ListFilter
      title={title}
      isLoading={isLoading}
      options={
        data?.roles.map((role) => {
          return {
            value: role.name.toLowerCase(),
            label: role.name,
          };
        }) ?? []
      }
      selectedValues={selectedRoles}
      excluded={excluded}
      onChange={(selectedValues, exclude) => onChange(selectedValues, exclude)}
    />
  );
};

export default withRequireEntitlementAndPermission(
  UserRisksRolesFilter,
  OrgAccessUserBase,
  UserUserBaseEnabled
);
