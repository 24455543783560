import classnames from "classnames";
import { FC, ReactNode } from "react";
import { BannerType } from "../../../vendorrisk/components/InfoBanner";
import "../../style/components/pageHeader/InfoBarSection.scss";

export interface InfoBarSectionProps {
  // if defined will appear in an info bar above the breadcrumbs section
  infoBarText?: ReactNode;

  // override the default colour of the info bar
  infoBarColour?: BannerType;
}

const InfoBarSection: FC<InfoBarSectionProps> = ({
  infoBarText,
  infoBarColour,
}) => {
  if (!infoBarText) {
    return <></>;
  }

  return (
    <div className={classnames("info-bar-section", infoBarColour)}>
      <div className="info-bar-section-inner">
        <div className="info-bar">{infoBarText}</div>
      </div>
    </div>
  );
};

export default InfoBarSection;
