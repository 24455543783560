import "../style/components/CorrespondenceIconButton.scss";
import IconButton from "../../_common/components/IconButton";
import { FC, useState } from "react";
import classNames from "classnames";

interface CorrespondenceIconButtonProps {
  totalMessages: number;
  haveUnreadMessages: boolean;
  onClick: () => void;
  disabled?: boolean;
}

export const CorrespondenceIconButton: FC<CorrespondenceIconButtonProps> = ({
  totalMessages,
  haveUnreadMessages,
  onClick,
  disabled,
}) => {
  const [clicked, setClicked] = useState(false);
  return (
    <IconButton
      className={classNames("correspondence-icon-btn", {
        "have-unread": haveUnreadMessages,
      })}
      icon={<div className="cr-icon-chat-messages" />}
      text={
        totalMessages > 0 ? (
          <div className="message-count">{totalMessages}</div>
        ) : undefined
      }
      attentionLeft={haveUnreadMessages && !clicked}
      disabled={disabled}
      onClick={() => {
        setClicked(true);
        onClick();
      }}
    />
  );
};
