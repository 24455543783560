import { Component } from "react";
import PropTypes from "prop-types";

import Button from "../../../_common/components/core/Button";

import {
  fetchAdminVendorSummaryByHostname,
  mergeVendors,
  moveDomainsToExistingVendor,
  moveDomainsToNewVendor,
} from "../../reducers/adminActions";

import "../../styles/MoveDomainsModal.scss";
import ColorCheckbox from "../../../vendorrisk/components/ColorCheckbox";
import { scanHostname } from "../../../vendorrisk/reducers/cyberRiskActions";
import { GetHostnameFromUrl, isIPAddress } from "../../../_common/helpers";
import { closeModal } from "../../../_common/reducers/commonActions";
import classnames from "classnames";
import {
  addDefaultSuccessAlert,
  addSimpleErrorAlert,
} from "../../../_common/reducers/messageAlerts.actions";

export const MoveDomainsModalName = "MoveDomainsModal";

class MoveDomainsModal extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
  };

  state = {
    sourceDomain: "",
    sourceVendorSummary: null,
    destinationDomain: "",
    destinationVendorSummary: null,
    mergeWholeVendor: null,
    createNewVendor: null,
    selectedSourceDomains: {},
    newVendorName: "",
    loading: false,
    error: null,
  };

  submit = (action) => {
    this.setState({ loading: true });

    this.props
      .dispatch(action)
      .then(() => {
        this.props.dispatch(closeModal());
        this.props.dispatch(
          addDefaultSuccessAlert("Domains have been moved successfully")
        );
      })
      .catch((e) => {
        this.setState({ loading: false });
        this.props.dispatch(addSimpleErrorAlert(e.message));
      });
  };

  toggleSelectSourceDomain = (domain) => {
    this.setState((state) => ({
      selectedSourceDomains: {
        ...state.selectedSourceDomains,
        [domain]: !state.selectedSourceDomains[domain],
      },
    }));
  };

  onMoveDomainsToExistingVendor = () => {
    const selectedDomains =
      this.state.sourceVendorSummary.parent_domains.filter(
        (d) => this.state.selectedSourceDomains[d]
      );

    this.submit(
      moveDomainsToExistingVendor(
        [this.state.sourceDomain, ...selectedDomains],
        this.state.sourceVendorSummary.datastore_vendor_id,
        this.state.destinationVendorSummary.datastore_vendor_id
      )
    );
  };

  onMoveDomainsToNewVendor = () => {
    const selectedDomains =
      this.state.sourceVendorSummary.parent_domains.filter(
        (d) => this.state.selectedSourceDomains[d]
      );

    this.submit(
      moveDomainsToNewVendor(
        this.state.sourceVendorSummary.datastore_vendor_id,
        this.state.newVendorName,
        this.state.sourceDomain,
        selectedDomains
      )
    );
  };

  onMergeVendors = () => {
    this.submit(
      mergeVendors(
        this.state.sourceVendorSummary.datastore_vendor_id,
        this.state.destinationVendorSummary.datastore_vendor_id
      )
    );
  };

  render() {
    let sourceDomain = null,
      sourceVendor = null,
      sourceDomainIsPrimary = null,
      destinationVendor = null;

    // work out which parts of the form to show
    let showSourceVendorSearch = true,
      showWholeVendorMustBeMergedMessage = false,
      showWholeOrPartialMoveCheckboxes = false,
      showSourceVendorDomainSelectableList = false,
      showSourceVendorDomainNonSelectableList = false,
      showNewOrExistingVendorCheckboxes = false,
      showNewVendorForm = false,
      showDestinationVendorSearch = false,
      showMergeVendorsButton = false,
      showMoveDomainsButton = false,
      showCreateNewVendorButton = false,
      showCancelSubmitButtons = false;

    if (this.state.sourceVendorSummary) {
      sourceDomain = this.state.sourceDomain;
      sourceVendor = this.state.sourceVendorSummary;
      destinationVendor = this.state.destinationVendorSummary;
      sourceDomainIsPrimary = sourceDomain === sourceVendor.primary_hostname;

      if (sourceDomainIsPrimary) {
        showWholeVendorMustBeMergedMessage = true;
        showSourceVendorDomainNonSelectableList = true;
        showDestinationVendorSearch = true;
        if (destinationVendor) {
          showMergeVendorsButton = true;
        }
      } else {
        showWholeOrPartialMoveCheckboxes = true;

        if (this.state.mergeWholeVendor === true) {
          showSourceVendorDomainNonSelectableList = true;
          showDestinationVendorSearch = true;
          if (destinationVendor) {
            showMergeVendorsButton = true;
          }
        }
        if (this.state.mergeWholeVendor === false) {
          showSourceVendorDomainSelectableList = true;
          showNewOrExistingVendorCheckboxes = true;

          if (this.state.createNewVendor === true) {
            showNewVendorForm = true;
            showCreateNewVendorButton = true;
          }
          if (this.state.createNewVendor === false) {
            showDestinationVendorSearch = true;
            if (destinationVendor) {
              showMoveDomainsButton = true;
            }
          }
        }
      }
    }

    if (
      showCreateNewVendorButton ||
      showMoveDomainsButton ||
      showMergeVendorsButton
    ) {
      showCancelSubmitButtons = true;
    }

    return (
      <div className="modal-content move-domains-modal">
        <h2>Move Domains</h2>
        {showSourceVendorSearch && (
          <VendorSearch
            dispatch={this.props.dispatch}
            domain={this.state.sourceDomain}
            vendorSummary={sourceVendor}
            onChangeDomain={(domain) => this.setState({ sourceDomain: domain })}
            onChangeVendor={(vendorSummary) => {
              this.setState({
                sourceVendorSummary: vendorSummary,
                // clear the selected domain state
                selectedSourceDomains: {},
              });
            }}
            domainLabel={"Enter a domain to move:"}
            vendorLabel={"Source vendor"}
          />
        )}
        {showWholeVendorMustBeMergedMessage && (
          <p>
            <b>{sourceDomain}</b> is the primary domain of{" "}
            <b>{getVendorDisplayName(sourceVendor)}</b>. All domains in the
            vendor must be merged together with another vendor.
          </p>
        )}
        {showWholeOrPartialMoveCheckboxes && (
          <div className="input-group radios">
            <ColorCheckbox
              color="blue"
              radio
              label={
                "Move all domains in " +
                getVendorDisplayName(sourceVendor) +
                " into another vendor"
              }
              checked={this.state.mergeWholeVendor === true}
              className={classnames({
                unselected: this.state.mergeWholeVendor === false,
              })}
              onClick={() => this.setState({ mergeWholeVendor: true })}
            />
            <ColorCheckbox
              color="blue"
              radio
              label={
                "Move " +
                sourceDomain +
                " out of " +
                getVendorDisplayName(sourceVendor) +
                ", along with any other selected domains"
              }
              checked={this.state.mergeWholeVendor === false}
              className={classnames({
                unselected: this.state.mergeWholeVendor === true,
              })}
              onClick={() => this.setState({ mergeWholeVendor: false })}
            />
          </div>
        )}
        {showSourceVendorDomainSelectableList && (
          <VendorDomainSelectableList
            onSelectDomain={(domain) => this.toggleSelectSourceDomain(domain)}
            selectedDomains={this.state.selectedSourceDomains}
            vendorSummary={sourceVendor}
            sourceDomain={sourceDomain}
          />
        )}
        {showSourceVendorDomainNonSelectableList && (
          <VendorDomainNonSelectableList
            vendorSummary={sourceVendor}
            sourceDomain={sourceDomain}
          />
        )}
        {showNewOrExistingVendorCheckboxes && (
          <div className="input-group radios">
            <ColorCheckbox
              color="blue"
              radio
              label={
                "Create a new vendor from the selected domains with " +
                sourceDomain +
                " as the primary domain"
              }
              checked={this.state.createNewVendor === true}
              className={classnames({
                unselected: this.state.createNewVendor === false,
              })}
              onClick={() => this.setState({ createNewVendor: true })}
            />
            <ColorCheckbox
              color="blue"
              radio
              label={"Move selected domains into an existing vendor"}
              checked={this.state.createNewVendor === false}
              className={classnames({
                unselected: this.state.createNewVendor === true,
              })}
              onClick={() => this.setState({ createNewVendor: false })}
            />
          </div>
        )}
        {showDestinationVendorSearch && (
          <VendorSearch
            dispatch={this.props.dispatch}
            domain={this.state.destinationDomain}
            vendorSummary={destinationVendor}
            onChangeDomain={(domain) =>
              this.setState({ destinationDomain: domain })
            }
            onChangeVendor={(vendorSummary) => {
              this.setState({
                destinationVendorSummary: vendorSummary,
              });
            }}
            domainLabel={"Enter a domain to select the destination vendor:"}
            vendorLabel={"Destination vendor"}
          />
        )}
        {showNewVendorForm && (
          <form>
            <label>New vendor primary domain:</label>
            <input type="text" value={sourceDomain} disabled />
            <label>New vendor name:</label>
            <input
              type="text"
              name="vendor_name"
              value={this.state.newVendorName}
              onChange={(e) =>
                this.setState({
                  newVendorName: e.target.value,
                })
              }
            />
          </form>
        )}
        {showCancelSubmitButtons && (
          <form>
            <Button onClick={() => this.props.dispatch(closeModal())}>
              Cancel
            </Button>
            {showCreateNewVendorButton && (
              <Button
                primary
                loading={this.state.loading}
                disabled={
                  !this.state.newVendorName.trim() || this.state.loading
                }
                onClick={() => this.onMoveDomainsToNewVendor()}
              >
                Create New Vendor
              </Button>
            )}
            {showMoveDomainsButton && (
              <Button
                primary
                loading={this.state.loading}
                disabled={!destinationVendor || this.state.loading}
                onClick={() => this.onMoveDomainsToExistingVendor()}
              >
                Move Domains
              </Button>
            )}
            {showMergeVendorsButton && (
              <Button
                primary
                loading={this.state.loading}
                disabled={!destinationVendor || this.state.loading}
                onClick={() => this.onMergeVendors()}
              >
                Merge Vendors
              </Button>
            )}
          </form>
        )}
      </div>
    );
  }
}

class VendorSearch extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    domain: PropTypes.string,
    vendorSummary: PropTypes.object,
    onChangeVendor: PropTypes.func.isRequired,
    onChangeDomain: PropTypes.func.isRequired,
    domainLabel: PropTypes.string.isRequired,
    vendorLabel: PropTypes.string.isRequired,
  };

  static defaultProps = {
    domain: "",
    vendorSummary: null,
  };

  state = {
    loading: false,
    error: null,
  };

  onLoadDomainVendor = async (evt) => {
    evt.preventDefault();

    let domain = this.props.domain.trim();
    if (!domain) {
      return;
    }

    const parsedHostname = GetHostnameFromUrl(domain);

    if (!parsedHostname) {
      this.setState({
        error: { errorText: domain + " is not a valid domain" },
      });
      return;
    }

    if (isIPAddress(parsedHostname)) {
      this.setState({
        error: {
          errorText:
            "IP addresses can't be moved yet, please contact the product team",
        },
      });
      return;
    }

    domain = parsedHostname.replace(/^www\./, "");

    this.props.onChangeDomain(domain);

    this.setState({ loading: true, error: null });

    try {
      const vendorSummary = await this.props.dispatch(
        fetchAdminVendorSummaryByHostname(domain)
      );

      if (vendorSummary.status === "NOT_FOUND") {
        this.setState({
          error: {
            errorText:
              "No vendor found. Scan " + domain + " to find its vendor.",
            actionText: "Scan domain",
            actionOnClick: async () => {
              this.setState({ loading: true });
              await this.props.dispatch(scanHostname(this.props.domain));
              this.onLoadDomainVendor(evt);
            },
          },
        });
      } else {
        this.props.onChangeVendor(vendorSummary.result);
      }
    } catch (e) {
      this.setState({
        error: {
          errorText: "Error finding vendor: " + e,
          actionText: "Retry",
          actionOnClick: async () => {
            this.onLoadDomainVendor(evt);
          },
        },
      });
    }
    this.setState({ loading: false });
  };

  render() {
    let showError, showFindButton, showVendorSummary;

    if (this.state.error) {
      showError = true;
    } else if (this.props.vendorSummary) {
      showVendorSummary = true;
    } else {
      showFindButton = true;
    }
    return (
      <form onSubmit={this.onLoadDomainVendor}>
        <label htmlFor="domain">{this.props.domainLabel}</label>
        <input
          type="text"
          name="domain"
          placeholder="domain.com"
          required
          value={this.props.domain}
          onChange={(e) => {
            this.setState({
              error: null,
            });
            this.props.onChangeDomain(e.target.value);
            // when the domain changes, clear the selected vendor
            this.props.onChangeVendor(null);
          }}
        />
        {showError && (
          <>
            <label>{this.state.error.errorText}</label>
            {this.state.error.actionText && (
              <Button
                className="btn btn-default"
                onClick={this.state.error.actionOnClick}
                loading={this.state.loading}
                disabled={this.state.loading}
              >
                {this.state.error.actionText}
              </Button>
            )}
          </>
        )}
        {showFindButton && (
          <Button
            type="submit"
            primary
            loading={this.state.loading}
            disabled={this.state.loading}
          >
            Find Vendor
          </Button>
        )}
        {showVendorSummary && (
          <>
            <label>{this.props.vendorLabel}:</label>
            <p>{getVendorDisplayName(this.props.vendorSummary)}</p>
          </>
        )}
      </form>
    );
  }
}

VendorDomainSelectableList.propTypes = {
  onSelectDomain: PropTypes.func.isRequired,
  sourceDomain: PropTypes.string.isRequired,
  selectedDomains: PropTypes.object.isRequired,
  vendorSummary: PropTypes.object.isRequired,
};

function VendorDomainSelectableList(props) {
  let domainList = props.vendorSummary.parent_domains.filter(
    (d) =>
      d !== props.sourceDomain && d !== props.vendorSummary.primary_hostname
  );
  return (
    <div className="input-group">
      <ColorCheckbox
        color="blue"
        label={
          props.vendorSummary.primary_hostname +
          " (primary domain - can not be moved)"
        }
        disabled
      />
      <ColorCheckbox
        color="blue"
        label={props.sourceDomain}
        checked={true}
        disabled
      />
      {domainList.map((domain) => (
        <ColorCheckbox
          key={domain}
          color="blue"
          label={domain}
          checked={props.selectedDomains[domain]}
          onClick={() => props.onSelectDomain(domain)}
        />
      ))}
    </div>
  );
}

VendorDomainNonSelectableList.propTypes = {
  sourceDomain: PropTypes.string.isRequired,
  vendorSummary: PropTypes.object.isRequired,
};

function VendorDomainNonSelectableList(props) {
  const primaryHostname = props.vendorSummary.primary_hostname;
  const domainList = props.vendorSummary.parent_domains;

  // move the primary hostname and source domain to the start of the list
  domainList.sort((a, b) => {
    if (a === primaryHostname) {
      return -1;
    }
    if (b === primaryHostname) {
      return 1;
    }
    if (a === props.sourceDomain) {
      return -1;
    }
    if (b === props.sourceDomain) {
      return 1;
    }
    return a < b ? -1 : 1;
  });

  return (
    <div className="input-group">
      {domainList.map((domain) => (
        <ColorCheckbox
          key={domain}
          color="blue"
          label={domain}
          checked
          disabled
        />
      ))}
    </div>
  );
}

function getVendorDisplayName(vendorSummary) {
  if (
    vendorSummary.name &&
    vendorSummary.name !== vendorSummary.primary_hostname
  ) {
    return vendorSummary.name + " (" + vendorSummary.primary_hostname + ")";
  }
  return vendorSummary.primary_hostname;
}

export default MoveDomainsModal;
