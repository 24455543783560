import { FC, useMemo } from "react";
import "../style/components/TextFieldWithActions.scss";
import classnames from "classnames";
import Button from "./core/Button";
import LoadingIcon from "./core/LoadingIcon";
import DismissablePopup, { PopupPosition } from "./DismissablePopup";
import TextFieldPerformant, {
  TextFieldPerformantProps,
} from "./TextFieldPerformant";
import TextField from "./TextField";

export interface IActionButtonProps {
  // the optional icon to display in the button
  actionIcon?: string;

  // the optional text to display in the button
  actionText?: string;

  // callback for clicks on the button
  onClick?: () => void;

  // popup message for the action
  popupMessage?: string;

  // classname for the button popup component
  popupClassName?: string;

  // optional width of the button popup
  popupWidth?: number;

  // the position for the popup if any. Defaults to top
  popupPosition?: PopupPosition;

  // toggle to disable the button
  disabled?: boolean;

  // flag to indicate the action is in progress. It will show the button as loading spinner
  isExecutingAction?: boolean;

  // class to assign to the outer element, for popup wrapping the entire component
  outerClassName?: string;

  // class to assign to the inner button
  innerClassName?: string;

  // Automatically dismiss after this, in ms. If explicitly set to 0, popup will start dismissed.
  autoDismissAfter?: number;
}

const ActionButton: FC<IActionButtonProps> = ({
  actionIcon,
  actionText,
  outerClassName,
  innerClassName,
  onClick,
  popupMessage,
  popupClassName,
  popupWidth,
  popupPosition = "top",
  disabled,
  isExecutingAction,
  autoDismissAfter,
}) => {
  if (!actionIcon && !actionText) return <></>;

  const innerDisabled = !onClick || disabled;

  return (
    <DismissablePopup
      text={popupMessage}
      position={popupPosition}
      popupHoverable={true}
      className={classnames("action-button", outerClassName, {
        disabled: innerDisabled,
      })}
      popupClassName={popupClassName}
      width={popupWidth}
      popupOnHoverToo
      autoDismissAfter={autoDismissAfter}
    >
      <Button
        tertiary
        onClick={!isExecutingAction ? onClick : undefined}
        disabled={innerDisabled}
        className={classnames(innerClassName)}
      >
        {isExecutingAction && (
          <LoadingIcon size={12} className={"executing-action"} />
        )}
        {!!actionIcon && (
          <div
            className={classnames(actionIcon, {
              hidden: isExecutingAction,
              "with-text": !!actionText,
            })}
          ></div>
        )}
        {!!actionText && (
          <span className={classnames({ hidden: isExecutingAction })}>
            {actionText}
          </span>
        )}
      </Button>
    </DismissablePopup>
  );
};

interface ITextFieldWithActionProps
  extends Omit<TextFieldPerformantProps, "className" | "height"> {
  // Optionally use the more performant text field
  usePerformantTextField?: boolean;

  // action buttons to display below the text field
  actionButtons?: IActionButtonProps[];
}

const TextFieldWithActions: FC<ITextFieldWithActionProps> = ({
  actionButtons,
  usePerformantTextField,
  ...otherProps
}) => {
  const actions = useMemo(() => {
    return (actionButtons ?? []).map((actionButton, idx) => {
      return <ActionButton key={idx} {...actionButton} />;
    });
  }, [actionButtons]);

  const textFieldProps = { ...otherProps };

  const classname = classnames({
    "with-buttons": !!actionButtons && actionButtons.length > 0,
  });

  return (
    <div className={"text-field-with-action"}>
      {usePerformantTextField && (
        <TextFieldPerformant className={classname} {...textFieldProps} />
      )}
      {!usePerformantTextField && (
        <TextField
          className={classname}
          {...textFieldProps}
          useStandardTextArea
        />
      )}
      {!!actionButtons && actionButtons.length > 0 && (
        <div
          className={classnames("actions", { multiline: otherProps.multiLine })}
        >
          {actions}
        </div>
      )}
    </div>
  );
};

export default TextFieldWithActions;
