import { useEffect, useState } from "react";
import RemediationRequestsAPI, {
  RemediationRequestRiskHostnameDetails,
} from "../../api/remediationRequestsAPI";
import { useAppDispatch } from "../../types/reduxHooks";
import { useSorting } from "../../hooks";
import XTable, {
  IXTableColumnHeader,
  IXTableRow,
  SortDirection,
  XTableCell,
} from "../core/XTable";
import PillLabel from "../../../vendorrisk/components/PillLabel";
import { LabelColor } from "../../types/label";
import LoadingBanner from "../core/LoadingBanner";
import { addDefaultUnknownErrorAlert } from "../../reducers/messageAlerts.actions";

/**
 * If the risk is collapsed and then re-expanded then this component will have been re-mounted.
 *
 * In that case - we'll use a threshold to decide if we should refetch the risk hostnames.
 *
 * The main reason we want to handle this is in case the user opens the domain side-panel
 * and clicks the re-scan button - so something between 10 seconds up to 30 seconds could be a
 * good threshold level. Just a little shorter than a re-scan should be.
 */
const expandRefetchThresholdSeconds = 10;

export interface IRemediationDetailsExpandedRiskTablePropsV2 {
  requestId: number;
  checkId: string;
  onClickRow: (id: string, risk?: string) => void;
  onAssetsCountChanged?: (assetsCount: number | undefined) => void;
}

export default function RemediationDetailsExpandedRiskTableV2({
  requestId,
  checkId,
  onClickRow,
  onAssetsCountChanged,
}: IRemediationDetailsExpandedRiskTablePropsV2) {
  const dispatch = useAppDispatch();
  const { data, isFetching, isError, error } =
    RemediationRequestsAPI.useGetVendorHostnamesWithRiskV1Query(
      {
        requestId,
        riskId: checkId,
      },
      {
        refetchOnMountOrArgChange: expandRefetchThresholdSeconds,
      }
    );

  useEffect(
    function handleErrorWhileFetchingRiskHostnames() {
      if (isError) {
        console.error(error);
        dispatch(addDefaultUnknownErrorAlert("Error getting list of domains"));
      }
    },
    [isError, error]
  );

  const [prevRiskHostnameDetailsLength, setPrevRiskHostnameDetailsLength] =
    useState<number | undefined>(undefined);
  const [prevIsFetching, setPrevIsFetching] = useState<boolean>(false);

  if (onAssetsCountChanged) {
    if (prevIsFetching != isFetching) {
      setPrevIsFetching(isFetching);

      if (isFetching) {
        onAssetsCountChanged(undefined);
      } else {
        setPrevRiskHostnameDetailsLength(data?.riskHostnameDetails.length);

        onAssetsCountChanged(data?.riskHostnameDetails.length);
      }
    } else if (
      prevRiskHostnameDetailsLength != data?.riskHostnameDetails.length
    ) {
      setPrevRiskHostnameDetailsLength(data?.riskHostnameDetails.length);

      onAssetsCountChanged(data?.riskHostnameDetails.length);
    }
  }

  const [sortedData, sortedBy, onSortChange] = useSorting<
    RemediationRequestRiskHostnameDetails,
    "domains" | "status"
  >(data?.riskHostnameDetails || [], "domains", SortDirection.DESC, {
    domains: {
      orderFuncs: [(d) => d.website],
      sortDirsAsc: ["desc"],
      sortDirsDesc: ["asc"],
    },
    status: {
      orderFuncs: [(d) => d.remediated],
      sortDirsAsc: ["asc"],
      sortDirsDesc: ["desc"],
    },
  });

  const columns: IXTableColumnHeader[] = [
    {
      id: "domains",
      text: "Associated assets",
      sortable: true,
      startingSortDir: SortDirection.DESC,
      helpText:
        "These assets were identified as part of this remediation request",
    },
    {
      id: "status",
      text: "Remediation status",
      sortable: true,
    },
  ];

  const rows: IXTableRow[] = sortedData.map((d) => {
    const cells = [
      <XTableCell className={"domains-cell"} key={"domains"}>
        {d.website}
      </XTableCell>,
      <XTableCell className={"status-cell shrink-cell"} key={"status"}>
        {d.remediated ? (
          <PillLabel color={LabelColor.Green}>Remediated</PillLabel>
        ) : d.moved ? (
          <PillLabel color={LabelColor.Grey}>Descoped</PillLabel>
        ) : d.waived ? (
          <PillLabel color={LabelColor.Green}>Waived</PillLabel>
        ) : (
          <PillLabel color={LabelColor.Red}>Not remediated</PillLabel>
        )}
      </XTableCell>,
    ];

    return {
      cells,
      id: d.website,
      onClick: () => onClickRow(d.website, checkId),
    };
  });

  if (isFetching) {
    return <LoadingBanner tight />;
  }

  return (
    <XTable
      rows={rows}
      columnHeaders={columns}
      sortedBy={sortedBy}
      onSortChange={onSortChange}
    />
  );
}
