import classnames from "classnames";
import TabButtons from "./TabButtons";
import TextField from "./TextField";
import Button from "./core/Button";
import { ReactNode, useEffect, useRef, useState } from "react";
import "../style/components/MessageInput.scss";

interface IMessageInputProps {
  value: string;
  showActions: boolean;
  onChange: (val: string) => void;
  onCancel?: () => void;
  onSubmit: () => void;
  onSubmitText: string;
  onPrivate?: () => void;
  isPrivate?: boolean;
  loading: boolean;
  placeholder: string;
  privatePlaceholder?: string;
  autofocus?: boolean;
  publicReplyHelp?: string;
  internalNoteHelp?: string;
  buttonsLeftContent?: ReactNode;
  privateMessagesOnly?: boolean;
}

const MessageInput = ({
  value,
  showActions,
  onChange,
  onCancel,
  onSubmit,
  onSubmitText,
  onPrivate = undefined,
  isPrivate = false,
  privatePlaceholder = "",
  loading,
  placeholder,
  autofocus = false,
  publicReplyHelp = "",
  internalNoteHelp = "",
  buttonsLeftContent,
  privateMessagesOnly,
}: IMessageInputProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (autofocus) {
      // Defer this to outside the current call stack, or it is unreliable
      setTimeout(() => {
        if (containerRef.current) {
          containerRef.current.scrollIntoView({
            behavior: "smooth",
            block: "end",
          });
        }

        if (inputRef.current) {
          inputRef.current.focus();
        }
      }, 0);
    }
  }, [autofocus]);

  const [privateMode, setPrivateMode] = useState(
    isPrivate || privateMessagesOnly
  );

  useEffect(() => {
    setPrivateMode(isPrivate || privateMessagesOnly);
  }, [isPrivate, privateMessagesOnly]);

  const [isValid, setIsValid] = useState(false);

  const canSetPrivate = !!onPrivate && !isPrivate;

  return (
    <div className={`message-input new-styles`} ref={containerRef}>
      {canSetPrivate && !privateMessagesOnly && (
        <TabButtons
          onChangeTab={(tabId) => setPrivateMode(tabId === "private")}
          tabs={[
            {
              id: "public",
              text: "Public message",
              popup: publicReplyHelp,
              popupNoWrap: false,
            },
            {
              id: "private",
              text: "Internal note",
              popup: internalNoteHelp,
              popupNoWrap: false,
            },
          ]}
          activeTabId={privateMode ? "private" : "public"}
        />
      )}
      <TextField
        ref={inputRef}
        value={value}
        onChanged={(v, valid) => {
          setIsValid(valid);
          onChange(v);
        }}
        multiLine
        inputClassname={privateMode ? "private" : ""}
        disabled={loading}
        placeholder={privateMode ? privatePlaceholder : placeholder}
        maxLength={4000}
        maxRows={50}
        allowTextOverflow={true}
      />
      <div
        className={classnames("message-input-actions", {
          active: !loading && showActions,
        })}
      >
        {onCancel && (
          <div className="secondary" onClick={() => onCancel()}>
            Cancel
          </div>
        )}
        <div
          className={!isValid || loading ? "secondary disabled" : undefined}
          onClick={privateMode ? onPrivate : onSubmit}
        >
          {onSubmitText}
        </div>
      </div>
      <div className="message-input-buttons">
        {buttonsLeftContent && (
          <div className="buttons-left-content">{buttonsLeftContent}</div>
        )}
        {onCancel && (
          <Button tertiary disabled={!showActions} onClick={onCancel}>
            Cancel
          </Button>
        )}
        <Button
          filledPrimary
          loading={loading}
          disabled={!showActions || loading}
          onClick={privateMode ? onPrivate : onSubmit}
        >
          {onSubmitText}
        </Button>
      </div>
    </div>
  );
};

export default MessageInput;
