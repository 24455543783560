import { DefaultThunkDispatch } from "../../_common/types/redux";
import { addSimpleErrorAlert } from "../../_common/reducers/messageAlerts.actions";

export const handleKnownErrors = (e: any) => {
  return (dispatch: DefaultThunkDispatch) => {
    if (e?.json?.error) {
      if (e.json.error.startsWith("email address inactive")) {
        checkAndHandleEmailInactiveError(dispatch, e);
        return true;
      } else if (
        e.json.error === "custom vendor with that name already exists"
      ) {
        dispatch(
          addSimpleErrorAlert("That name is already in use", [
            "Please use a different name.",
          ])
        );

        return true;
      } else if (e.json.error.startsWith("deprecated risk: ")) {
        const errorMessage = e.json.error.replace("deprecated risk: ", "");
        dispatch(
          addSimpleErrorAlert(errorMessage, [
            "Please wait for the next scan of the affected asset(s) to continue.",
          ])
        );
        return true;
      } else {
        return false;
      }
    }

    return false;
  };
};

const checkAndHandleEmailInactiveError = (
  dispatch: DefaultThunkDispatch,
  e: any
) => {
  const emailAddressesSplit: string[] = e?.json?.error?.split(":");
  const emailAddress =
    emailAddressesSplit.length > 1 ? emailAddressesSplit[1] : "";

  dispatch(
    addSimpleErrorAlert("Email address appears to be inactive", [
      emailAddress
        ? `Please check the email address "${emailAddress}" and try again.`
        : "Please check the email address and try again.",
    ])
  );
};
