import { FC } from "react";
import PageHeader from "../../_common/components/PageHeader";
import { useHistory } from "react-router-dom";
import { useBack } from "../../_common/types/router";
import { useAppDispatch } from "../../_common/types/reduxHooks";
import {
  useHasUserOrPortfolioPermissions,
  UserUserBaseWrite,
} from "../../_common/permissions";
import {
  RemediationRequestTableV2Mode,
  RemediationRequestTableV2,
} from "../../vendorrisk/components/RemediationRequestTableV2";
import UserBaseAPI from "../api/userbase.api";

const RemediationsView: FC = () => {
  const history = useHistory();
  const { backAction, backText } = useBack();
  const dispatch = useAppDispatch();

  const userHasUserBaseWrite =
    useHasUserOrPortfolioPermissions(UserUserBaseWrite);

  const { data, isFetching } = UserBaseAPI.useGetUserBaseRemediationsV1Query();

  return (
    <div id="userrisk-remediations">
      <PageHeader
        history={history}
        title="Remediation"
        backAction={backAction}
        backText={backText}
        infoSectionPageKey={"userriskRemediations"}
      />
      <RemediationRequestTableV2
        dispatch={dispatch}
        remediationRequests={data?.requests.map((r) => ({ details: r })) ?? []}
        remediationRequestIdsLoading={isFetching}
        userHasWriteRemediationPermission={userHasUserBaseWrite}
        mode={RemediationRequestTableV2Mode.userrisk}
      />
    </div>
  );
};

export default RemediationsView;
