import ModalV2 from "../ModalV2";
import "../../style/components/QuestionnaireSharedListModal.scss";
import { Invite, IUserMini } from "../../types/user";
import { DefaultThunkDispatch } from "../../types/redux";
import UserAvatar, { UserAvatarAndName } from "../UserAvatar";
import IconButton from "../IconButton";
import { useState } from "react";
import {
  cancelInviteForSurvey,
  unshareUserFromSurvey,
} from "../../../vendor_portal/reducers/actions";
import {
  fetchSurveyDetails,
  resendShareInviteForSurvey,
} from "../../reducers/surveyDetails.actions";
import PillLabel from "../../../vendorrisk/components/PillLabel";
import { LabelColor } from "../../types/label";
import { getGravatarURL } from "../../helpers";
import {
  addDefaultSuccessAlert,
  addDefaultUnknownErrorAlert,
} from "../../reducers/messageAlerts.actions";
import { handleKnownErrors } from "../../../vendorrisk/reducers/errors.actions";
import Button from "../core/Button";

export interface IQuestionnaireSharedListModalProps {
  dispatch: DefaultThunkDispatch;
  active: boolean;
  onClose: () => void;
  surveyId: number;
  currentUserId: number;
  sharedWith: IUserMini[];
  openInvites: Invite[];
  fromCompany: string;
  isReadOnly: boolean;
  openInviteCollaboratorModal: () => void;
}

// Modal popped up from a QuestionnaireDetails view to display the users that this questionnaire is shared with.
const QuestionnaireSharedListModal = (
  props: IQuestionnaireSharedListModalProps
) => {
  const [actionLoading, setActionLoading] = useState(false);

  const removeUser = (user: IUserMini) => {
    if (
      !confirm(
        `Are you sure you would like to remove ${user.name} (${user.email}) as a collaborator for this questionnaire?`
      )
    ) {
      return;
    }

    setActionLoading(true);

    props
      .dispatch(unshareUserFromSurvey(user.email, props.surveyId))
      .then(() => props.dispatch(fetchSurveyDetails(props.surveyId, true)))
      .then(() => {
        props.onClose();
        props.dispatch(
          addDefaultSuccessAlert(
            `${user.name} has been removed from this questionnaire.`
          )
        );
      })
      .catch((e) => {
        console.error(e);
        props.dispatch(
          addDefaultUnknownErrorAlert(
            "An error occurred removing the user from this questionnaire"
          )
        );
      })
      .then(() => {
        setActionLoading(false);
      });
  };

  const removeInvite = (invite: Invite) => {
    if (
      !confirm(
        `Are you sure you would like to cancel the invite to ${invite.recipientEmail}?`
      )
    ) {
      return;
    }

    setActionLoading(true);

    props
      .dispatch(cancelInviteForSurvey(invite.id))
      .then(() => props.dispatch(fetchSurveyDetails(props.surveyId, true)))
      .then(() => {
        props.onClose();
        props.dispatch(
          addDefaultSuccessAlert(
            `The invite to ${invite.recipientEmail} has been cancelled.`
          )
        );
      })
      .catch((e) => {
        console.error(e);
        props.dispatch(
          addDefaultUnknownErrorAlert("An error occurred cancelling the invite")
        );
      })
      .then(() => {
        setActionLoading(false);
      });
  };

  const resendInvite = (invite: Invite) => {
    setActionLoading(true);

    props
      .dispatch(
        resendShareInviteForSurvey(
          props.surveyId,
          "",
          invite.recipientEmail,
          "",
          props.fromCompany
        )
      )
      .then(() => {
        props.dispatch(
          addDefaultSuccessAlert(
            `The invite to ${invite.recipientEmail} has been resent`
          )
        );
      })
      .catch((e) => {
        console.error(e);
        if (!props.dispatch(handleKnownErrors(e))) {
          props.dispatch(
            addDefaultUnknownErrorAlert("An error occurred sending the invite")
          );
        }
      })
      .then(() => {
        setActionLoading(false);
      });
  };

  return (
    <ModalV2
      active={props.active}
      className="q-shared-list-modal"
      onClose={props.onClose}
      footerContent={
        <div className="btn-group">
          <Button tertiary onClick={props.onClose}>
            Close
          </Button>
          <Button
            filledPrimary
            onClick={() => {
              props.openInviteCollaboratorModal();
              props.onClose();
            }}
          >
            Invite recipient
          </Button>
        </div>
      }
    >
      <div>
        {props.sharedWith.length > 0 && (
          <>
            <h3>Recipients shared with</h3>
            {props.sharedWith.map((user) => (
              <div key={user.id} className="shared-with-user">
                <UserAvatarAndName
                  avatar={user.avatar}
                  name={user.name}
                  email={user.email}
                />
                {user.id !== props.currentUserId && !props.isReadOnly && (
                  <>
                    <IconButton
                      hoverText="Remove user"
                      disabled={actionLoading}
                      onClick={() => removeUser(user)}
                      icon={<div className="cr-icon-trash" />}
                    />
                  </>
                )}
              </div>
            ))}
          </>
        )}
        {props.openInvites.length > 0 && (
          <>
            <h3>Pending invites</h3>
            {props.openInvites.map((invite) => (
              <div key={invite.id} className="shared-with-user invite">
                <UserAvatar avatar={getGravatarURL(invite.recipientEmail)} />
                <div className="name-and-email">
                  {invite.recipientEmail}{" "}
                  {!!invite.isExpired && (
                    <PillLabel color={LabelColor.Red}>Expired</PillLabel>
                  )}
                </div>
                {!props.isReadOnly && (
                  <>
                    <IconButton
                      hoverText="Resend invite email"
                      disabled={actionLoading}
                      onClick={() => resendInvite(invite)}
                      icon={<div className="cr-icon-redo" />}
                    />
                    {!invite.isExpired && (
                      <IconButton
                        hoverText="Cancel invite"
                        disabled={actionLoading}
                        onClick={() => removeInvite(invite)}
                        icon={<div className="cr-icon-trash" />}
                      />
                    )}
                  </>
                )}
              </div>
            ))}
          </>
        )}
      </div>
    </ModalV2>
  );
};

export default QuestionnaireSharedListModal;
