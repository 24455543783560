import "../style/components/CircledIcon.scss";

interface ICircledIconProps {
  iconClass: string;
}

const CircledIcon = ({ iconClass }: ICircledIconProps) => (
  <div className="circled-icon">
    <div className={iconClass} />
  </div>
);

export default CircledIcon;
