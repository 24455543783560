import { DefaultThunkDispatchProp } from "../../../_common/types/redux";
import "../../style/components/SharedProfileEditEvidenceModal.scss";
import {
  Evidence,
  EvidenceCategory,
  EvidenceSource,
} from "../../types/evidence";
import { useEffect, useState } from "react";
import ModalV2 from "../../../_common/components/ModalV2";
import Button from "../../../_common/components/core/Button";
import IconButton, { HoverColor } from "../../../_common/components/IconButton";
import { SelectV2 } from "../../../_common/components/SelectV2";
import { evidenceCategoryToStr } from "./SharedProfileEvidencePagesTable";
import { validateUrlWithProtocol } from "../../../_common/helpers";
import {
  addDefaultSuccessAlert,
  addDefaultUnknownErrorAlert,
} from "../../../_common/reducers/messageAlerts.actions";
import { updateVerifiedVendorEvidence } from "../../reducers/verifiedVendors.actions";
import TextField from "../../../_common/components/TextField";
import { cloneDeep as _cloneDeep } from "lodash";

export const vendorEvidencePagesSupportUrl =
  "https://help.upguard.com/en/articles/7051467-how-to-use-security-and-privacy-pages-in-vendor-risk";

interface SharedProfileEditEvidenceModalProps {
  active: boolean;
  onClose: () => void;
  evidencePages: Evidence[];
}

const SharedProfileEditEvidenceModal = ({
  active,
  onClose,
  evidencePages,
  dispatch,
}: SharedProfileEditEvidenceModalProps & DefaultThunkDispatchProp) => {
  const [currentEvidencePages, setCurrentEvidencePages] = useState(
    _cloneDeep(evidencePages)
  );
  const [isEdited, setIsEdited] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  // Reset state on active change
  useEffect(() => {
    setCurrentEvidencePages(_cloneDeep(evidencePages));
  }, [active]);

  const saveEdit = () => {
    setIsSaving(true);

    dispatch(updateVerifiedVendorEvidence(currentEvidencePages))
      .then(() =>
        dispatch(
          addDefaultSuccessAlert(
            "Successfully updated security and privacy pages"
          )
        )
      )
      .then(() => onClose())
      .catch(() => {
        dispatch(
          addDefaultUnknownErrorAlert(
            "Error updating security and privacy pages"
          )
        );
      })
      .finally(() => setIsSaving(false));
  };

  const onEditCategory = (idx: number, newCategory: EvidenceCategory) => {
    if (idx < 0 || idx >= currentEvidencePages.length) return;

    const newEvidence = [...currentEvidencePages];
    newEvidence[idx].category = newCategory;
    setCurrentEvidencePages(newEvidence);
    setIsEdited(true);
  };

  const onEditUrl = (idx: number, newUrl: string) => {
    if (idx < 0 || idx >= currentEvidencePages.length) return;

    const newEvidence = [...currentEvidencePages];
    newEvidence[idx].url = newUrl;
    setCurrentEvidencePages(newEvidence);
    setIsEdited(true);
  };

  const onDeleteEvidence = (idx: number) => {
    if (idx < 0 || idx >= currentEvidencePages.length) return;

    const newEvidence = [...currentEvidencePages];
    newEvidence.splice(idx, 1);
    setCurrentEvidencePages(newEvidence);
    setIsEdited(true);
  };

  const onAddEvidence = () => {
    const newEvidences = [
      ...currentEvidencePages,
      {
        category: EvidenceCategory.Security,
        url: "",
        source: EvidenceSource.VendorRisk,
      },
    ];
    setCurrentEvidencePages(newEvidences);
    setIsEdited(true);
  };

  // check if the current set is valid by checking if there is any duplicate pairs of category and URL
  const hasDuplicates =
    new Set(currentEvidencePages.map((ev) => `${ev.category}:${ev.url}`))
      .size != currentEvidencePages.length;
  const hasInvalidUrls = currentEvidencePages.some(
    (ev) => !validateUrlWithProtocol(ev.url)
  );
  const isValid = !hasDuplicates && !hasInvalidUrls;

  return (
    <ModalV2
      className="shared-profile-edit-evidence-modal"
      active={active}
      onClose={onClose}
      headerContent="Edit security and privacy pages"
      footerContent={
        <>
          <Button tertiary onClick={onClose}>
            Cancel
          </Button>
          <Button
            filledPrimary
            onClick={saveEdit}
            disabled={!isValid || !isEdited}
            loading={isSaving}
          >
            Save changes
          </Button>
        </>
      }
    >
      <div className={"description"}>
        <p>
          Any links visible here will be publicly available on your Trust Page
          and on your Vendor Summary Page.
        </p>
        <p>
          These links can be used as supplementary evidence for others
          performing vendor risk assessments.
        </p>
        <Button
          tertiary
          onClick={() => window.open(vendorEvidencePagesSupportUrl)}
        >
          View support article <div className="cr-icon-arrow-right" />
        </Button>
      </div>
      <div className={"evidences edit-grid"}>
        <div className="edit-grid-row header-row">
          <div className="category-col">Page category</div>
          <div className="url-col">URL</div>
          <div className="actions-col"></div>
        </div>
        {currentEvidencePages.map((ev, idx) => {
          const errorMsgs: string[] = [];

          const isDuplicate =
            currentEvidencePages.findIndex(
              (o) =>
                o.category === ev.category &&
                o.url.toLowerCase() === ev.url.toLowerCase()
            ) !== idx;
          if (isDuplicate) errorMsgs.push("Duplicate page");
          if (!validateUrlWithProtocol(ev.url)) {
            if (!ev.url.startsWith("http")) {
              errorMsgs.push("URL must start with http or https");
            } else {
              errorMsgs.push("Invalid URL");
            }
          }

          return (
            <div className="edit-grid-row" key={idx}>
              <div className="category-col">
                <SelectV2
                  options={Object.values(EvidenceCategory).map((c) => ({
                    value: c,
                    label: evidenceCategoryToStr(c),
                  }))}
                  onChange={(val) =>
                    onEditCategory(idx, val?.value as EvidenceCategory)
                  }
                  isDisabled={isSaving}
                  value={{
                    value: ev.category,
                    label: evidenceCategoryToStr(ev.category),
                  }}
                />
              </div>
              <div className="url-col">
                <TextField
                  className={"url-text-field"}
                  value={ev.url}
                  placeholder={"URL"}
                  onChanged={(val) => onEditUrl(idx, val)}
                  errorTexts={errorMsgs}
                  disabled={isSaving}
                  errorsTimeout={0}
                />
              </div>
              <div className="actions-col">
                <IconButton
                  icon={<div className={"cr-icon-trash"} />}
                  hoverColor={HoverColor.Red}
                  onClick={() => onDeleteEvidence(idx)}
                />
              </div>
            </div>
          );
        })}
        <div className="edit-grid-row">
          <div className="add-evidence-btn">
            <Button
              className={"add-evidence"}
              filledPrimary
              onClick={onAddEvidence}
            >
              <span className="cr-icon-plus" />
              Add page
            </Button>
          </div>
        </div>
      </div>
    </ModalV2>
  );
};

export default SharedProfileEditEvidenceModal;
