import { FC } from "react";
import { DomainSource } from "../../../_common/types/domains";
import PillLabel from "../PillLabel";
import { LabelColor } from "../../../_common/types/label";
import "./DomainSourceLabelList.scss";

interface DomainSourceLabelListProps {
  sources?: DomainSource[];
}

const DomainSourceLabelList: FC<DomainSourceLabelListProps> = ({ sources }) => {
  if (!sources) {
    return <></>;
  }

  return (
    <div className={"domain-source-label-list"}>
      {sources.map((s) => (
        <DomainSourceLabel key={s} source={s} />
      ))}
    </div>
  );
};

const DomainSourceLabel: FC<{ source: DomainSource }> = ({ source }) => {
  switch (source) {
    case "custom":
      return (
        <PillLabel
          color={LabelColor.Orange}
          bordered
          popupContent={"This domain was manually added"}
        >
          Custom domain
        </PillLabel>
      );
    case "cloud":
      return (
        <PillLabel
          color={LabelColor.Violet}
          bordered
          popupContent={
            "This domain was discovered in one or more of your cloud environments"
          }
        >
          Cloud
        </PillLabel>
      );
    default:
      return <></>;
  }
};

export default DomainSourceLabelList;
