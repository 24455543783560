import React, { useCallback, useEffect, useState } from "react";
import { debounce as _debounce } from "lodash";

export interface NumberFieldError {
  fieldName: string;
  message: string;
}

export interface INumberEditProps {
  className?: string;
  value?: number;
  name: string;

  onChanged: (val: number | undefined, valid: boolean) => void;
  onError: (error: NumberFieldError, active: boolean) => void;

  placeholder?: string;
  max?: number;
  min?: number;

  debounceTime?: number;
}

// Simple number field that will make sure the input is within some bounds
// Doesn't use the 'number' type since that causes arrows to appear on the input that we do not want
// No error display for now
// @deprecated: see TextField with type="number"
const NumberField = React.forwardRef(function NumberField(
  {
    value,
    name,
    onChanged,
    onError,
    placeholder,
    max = Infinity,
    min = -Infinity,
    debounceTime = 0,
    className = "",
  }: INumberEditProps,
  ref: React.Ref<any>
) {
  const [val, setVal] = useState(value);
  // if a new value is passed though props also update this
  useEffect(() => {
    setVal(value);
  }, [value]);

  const [valid, setValid] = useState(value && value <= max && value >= min);

  const [isEdited] = useState(false);

  const debouncedNotify = useCallback(
    _debounce(onChanged, debounceTime, {
      leading: true,
    }),
    [debounceTime]
  );

  const doNotify = (value: number | undefined, valid: boolean) => {
    if (debounceTime > 0) {
      debouncedNotify(value, valid);
    } else {
      onChanged(value, valid);
    }
  };

  const changed = (newVal: string) => {
    // Internal validation

    // if it's blank it's not valid, but not an error either
    if (newVal === "") {
      setValid(false);
      setVal(undefined);
      onError({ fieldName: name, message: `${name} is required` }, true);
      doNotify(val, false);
      return;
    }

    // Only allow number entry, if any thing else is entered don't update the field
    const asNumber = parseInt(newVal);
    if (isNaN(asNumber)) {
      return;
    }

    const isControlEdited = isEdited || asNumber !== val;

    if (isControlEdited) {
      setVal(asNumber);
      if (asNumber > max || asNumber < min) {
        setValid(false);
        onError(
          {
            fieldName: name,
            message: `${name} must be between ${min} and ${max}`,
          },
          true
        );
        doNotify(value, false);
        return;
      }

      if (!valid) {
        onError({ fieldName: name, message: "" }, false);
      }
      setValid(true);
      doNotify(asNumber, true);
    }
  };

  return (
    <div className={`number-field ${className || ""}`}>
      <input
        className={valid ? "" : "error"}
        ref={ref}
        placeholder={placeholder}
        onChange={(e) => changed(e.target.value)}
        value={val}
        name={name}
        type={"text"}
      />
    </div>
  );
});

export default NumberField;
