import { Component } from "react";
import PropTypes from "prop-types";

import { XTableCell } from "../../../_common/components/core/XTable";
import XTable from "../../../_common/components/core/XTable";
import Button from "../../../_common/components/core/Button";
import {
  chunk as _chunk,
  debounce as _debounce,
  sortBy as _sortBy,
} from "lodash";

import { closeModal } from "../../../_common/reducers/commonActions";

import {
  enableSquatDomain,
  fetchCustomerTyposquatSummary,
  scanTyposquatDomain,
} from "../../reducers/cyberRiskActions";
import "../../style/components/AddTyposquatDomainModal.scss";
import SearchBox from "../../../_common/components/SearchBox";
import LabelList from "../LabelList";
import ColorGrade, { ColorGradeSize } from "../executive_summary/ColorGrade";
import {
  fetchDomains,
  getDomainParamsKey,
} from "../../reducers/domains.actions";
import SearchEmptyCard from "../../../_common/components/SearchEmptyCard";
import {
  addDefaultSuccessAlert,
  addDefaultUnknownErrorAlert,
} from "../../../_common/reducers/messageAlerts.actions";
import { appConnect } from "../../../_common/types/reduxHooks";

export const AddTyposquatDomainModalName = "AddTyposquatDomainModal";

const domainsParams = {
  is_customer: true,
  active: true,
  website_label_ids_match_all: false,
  website_label_ids_do_not_match: false,
  website_include_unlabeled: false,
};

class AddTyposquatDomainModal extends Component {
  static propTypes = {
    modalData: PropTypes.shape({
      scannedDomains: PropTypes.array.isRequired,
      domainSelectedCallback: PropTypes.func.isRequired,
    }).isRequired,
    dispatch: PropTypes.func.isRequired,
    domains: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      sortedBy: {
        columnId: "hostname",
        direction: "asc",
      },
      filter: "",
      currentPage: 1,
      selectedDomain: null,
      loading: false,
    };

    this.props.dispatch(fetchDomains(domainsParams));
  }

  selectDomain = (hostname) => {
    this.setState({ loading: true, loading_hostname: hostname });
    let newTyposquatSummary;

    this.props
      .dispatch(scanTyposquatDomain(hostname))
      .then(() => {
        return this.props.dispatch(enableSquatDomain(hostname));
      })
      .then(async () => {
        // Once that's done, refresh our typosquat summary
        newTyposquatSummary = await this.props.dispatch(
          fetchCustomerTyposquatSummary(true)
        );
      })
      .then(() => {
        this.props.dispatch(closeModal());
        //check if we need to call the polling function
        if (
          !newTyposquatSummary.results ||
          !newTyposquatSummary.results.domains ||
          newTyposquatSummary.results.domains.find(
            (domain) => domain.hostname === hostname && !domain.last_scanned_at
          )
        ) {
          this.props.modalData.domainSelectedCallback(hostname);
        }
      })
      .then(() => {
        this.props.dispatch(
          addDefaultSuccessAlert(`${hostname} Added`, [
            "Scan will take 5 minutes, the page will update when done.",
          ])
        );
      })
      .catch((e) => {
        console.error(e);
        this.props.dispatch(
          addDefaultUnknownErrorAlert("Error adding new typosquatting domain")
        );
        this.setState({ loading: false, loading_hostname: "" });
      });
  };

  setFilterText = _debounce(
    (filterText) => this.setState({ filter: filterText, pageNumber: 0 }),
    200
  );

  onClearSearchClick = () => {
    this.setState({ filter: "", pageNumber: 0 });
  };

  renderEmptyFilterMessage = () => {
    return (
      <SearchEmptyCard
        searchItemText={"domains"}
        onClear={this.onClearSearchClick}
      />
    );
  };

  render() {
    let totalPages = 1;
    let { currentPage } = this.state;
    const rows = [];

    const domainsData = this.props.domains[getDomainParamsKey(domainsParams)];

    if (domainsData && domainsData.domains) {
      const filter = this.state.filter.toLowerCase().trim();

      // Make a map of domains that are already scanned so we can remove them from the list
      const scannedDomainsMap = this.props.modalData.scannedDomains.reduce(
        (prev, cur) => ({
          ...prev,
          [cur.hostname]: true,
        }),
        {}
      );

      let sortedScans;
      const filteredScans = domainsData.domains.filter((c) => {
        return !filter || (filter && c.hostname.indexOf(filter) == 0);
      });

      switch (this.state.sortedBy.columnId) {
        case "score":
          sortedScans = _sortBy(filteredScans, ["score"]);
          if (this.state.sortedBy.direction === "desc") {
            sortedScans.reverse();
          }
          break;
        case "hostname":
        default:
          filteredScans.sort(
            this.state.sortedBy.direction === "asc"
              ? (a, b) => a.hostname.localeCompare(b.hostname)
              : (a, b) => b.hostname.localeCompare(a.hostname)
          );
          sortedScans = filteredScans;
          break;
      }

      // Make the paged chunks
      const chunkedScans = _chunk(sortedScans, 6);

      if (chunkedScans.length > 0) {
        totalPages = chunkedScans.length;
        if (currentPage > totalPages) {
          currentPage = totalPages;
        }
        const data = chunkedScans[currentPage - 1];

        for (let i = 0; i < data.length; i++) {
          const displayLabels = data[i].labels
            ? data[i].labels.map((lab) => ({
                id: lab.id,
                name: lab.name,
                color: lab.colour,
                removeable: false,
                large: true,
                constrained: true,
              }))
            : [];

          rows.push({
            id: data[i].hostname,
            cells: [
              <XTableCell key="hostname" className={"url-cell"}>
                <div className={"url"}>
                  <div>{data[i].hostname}</div>
                </div>
              </XTableCell>,
              <XTableCell key="score" className={"score-cell"}>
                {data[i].score >= 0 && (
                  <div className={"score"}>
                    <ColorGrade
                      score={data[i].score}
                      size={ColorGradeSize.Small}
                    />
                    {data[i].score}
                  </div>
                )}
              </XTableCell>,
              <XTableCell key="labels" className={"labels-cell"}>
                <LabelList labels={displayLabels} />
              </XTableCell>,
              <XTableCell key="action" className="button-cell">
                {!!scannedDomainsMap[data[i].hostname] && (
                  <Button disabled={true}>
                    <div className={"cr-icon-check"} /> {"Already added"}
                  </Button>
                )}
                {!!!scannedDomainsMap[data[i].hostname] && (
                  <Button
                    disabled={this.state.loading}
                    loading={
                      this.state.loading &&
                      this.state.loading_hostname == data[i].hostname
                    }
                    primary
                    onClick={() => {
                      this.selectDomain(data[i].hostname);
                    }}
                  >
                    + Add domain
                  </Button>
                )}
              </XTableCell>,
            ],
          });
        }
      }
    }

    return (
      <div className="modal-content add-typosquat-modal">
        <h2>Add domain</h2>
        <div className="vulns-search-box">
          <SearchBox
            value={this.state.filter}
            onChanged={this.setFilterText}
            placeholder={"Search for a domain by name or URL"}
          />
        </div>

        <XTable
          loading={!domainsData || domainsData.loading}
          sortedBy={this.state.sortedBy}
          onSortChange={(columnId, direction) =>
            this.setState({
              sortedBy: {
                columnId,
                direction,
              },
            })
          }
          columnHeaders={[
            { id: "hostname", text: "Hostname", sortable: true },
            { id: "score", text: "Score", sortable: true },
            { id: "labels", text: "Labels", sortable: false },
            { id: "action", text: "", sortable: false },
          ]}
          rows={rows}
          onSelectClick={(hostname) =>
            this.setState({ selectedDomain: hostname })
          }
          pagination={
            totalPages > 1
              ? {
                  currentPage,
                  totalPages,
                  onPageChange: (currentPage) => this.setState({ currentPage }),
                }
              : null
          }
        />

        {domainsData &&
          domainsData.domains &&
          rows.length == 0 &&
          this.renderEmptyFilterMessage()}
      </div>
    );
  }
}

export default appConnect((state) => {
  return { domains: state.cyberRisk.domains };
})(AddTyposquatDomainModal);
