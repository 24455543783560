import { FC } from "react";
import ReportCard from "../../_common/components/ReportCard";
import ScopeRiskLevelDisplay from "./ScopeRiskLevelDisplay";
import { AppRouteParams } from "../UserBaseNavItems";
import UserBaseAPI from "../api/userbase.api";
import { useRouteMatch } from "react-router-dom";
import LoadingBanner from "../../_common/components/core/LoadingBanner";
import {
  BasicRiskLevelString,
  ElevatedRiskLevelString,
  isElevatedRiskLevel,
  RiskLevelColor,
} from "../helpers/scopes";
import Doughnut from "./Doughnut";
import Button from "../../_common/components/core/Button";
import { useDefaultHistory } from "../../_common/types/router";
import { appPermissionsUrl, appSummaryUrl } from "../UserBaseAppRouter";
import { pluralise } from "../../_common/helpers";
import "./AppPermissionsOverview.scss";

const AppPermissionsOverview: FC = ({}) => {
  const match = useRouteMatch<AppRouteParams>();
  const history = useDefaultHistory();

  const appName = match.params.appName;

  const { data: summaryData } = UserBaseAPI.useGetUserBaseAppSummaryV1Query({
    app: appName,
  });

  const { data: scopesData, isLoading } =
    UserBaseAPI.useGetUserBaseAppScopesV1Query({
      app: appName,
    });

  const onViewPermissions = () => {
    history.push(appPermissionsUrl(appName), {
      backContext: {
        backTo: appSummaryUrl(appName),
        backToText: `Back to ${appName}`,
      },
    });
  };

  const scopesToUse = scopesData?.scopes ?? [];

  const data = [
    {
      label: BasicRiskLevelString,
      count: scopesToUse.filter((s) => !isElevatedRiskLevel(s.riskLevel))
        .length,
      hexColor: RiskLevelColor.Basic,
    },
    {
      label: ElevatedRiskLevelString,
      count: scopesToUse.filter((s) => isElevatedRiskLevel(s.riskLevel)).length,
      hexColor: RiskLevelColor.Elevated,
    },
  ].filter((d) => d.count > 0); // only display non-empty entries

  return (
    <ReportCard newStyles className="userbase-app-permissions-overview">
      <div className="header">
        <span>Permissions</span>
        <div className={"header-link"}>
          <Button tertiary onClick={onViewPermissions}>
            View all <div className="cr-icon-arrow-right" />
          </Button>
        </div>
      </div>
      {isLoading && <LoadingBanner />}
      {!isLoading && (
        <div className={"content"}>
          <div className={"subtitle"}>
            <div>Breakdown</div>
            {summaryData?.app.highestScopeRiskLevel !== undefined && (
              <div className={"exposure-level"}>
                Exposure level:{" "}
                <ScopeRiskLevelDisplay
                  riskLevel={summaryData.app.highestScopeRiskLevel}
                />
              </div>
            )}
          </div>
          <Doughnut
            data={data}
            totalLabel={pluralise(
              scopesToUse.length,
              "Permission",
              "Permissions"
            )}
          />
        </div>
      )}
    </ReportCard>
  );
};

export default AppPermissionsOverview;
