import React, { useEffect, useState } from "react";
import TabButtons, { tabButton, tabButtonsStylingType } from "./TabButtons";
import classNames from "classnames";

import "../style/components/PivotTabs.scss";

interface IPivotTabProps {
  id: string;
  headerText: React.ReactNode;
  disabled: boolean;
  children: React.ReactNode;
}

// Component for housing a tabs content
export const PivotTab = (props: IPivotTabProps): JSX.Element => {
  return <>{props.children}</>;
};

PivotTab.defaultProps = {
  disabled: false,
};

interface IPivotTabsProps {
  // Tabs to display
  children:
    | (React.ReactElement<IPivotTabProps> | boolean)
    | (React.ReactElement<IPivotTabProps> | boolean)[];

  // Optionally control selected tab from parent
  selectedTab: string;

  // Optionally respond to a tab change
  onTabChange?: (newTab: string) => void;

  // Optionally, hide tab buttons when only a single tab
  hideSingleTabButton?: boolean;

  styling?: tabButtonsStylingType;
}

// Component that displays pivot tabs with a button selector. State of individual tabs is maintained on hide/show.
const PivotTabs = (props: IPivotTabsProps): JSX.Element => {
  const allTabs = Array.isArray(props.children)
    ? props.children
    : [props.children];

  const tabs = allTabs.filter(
    (t) => t !== false
  ) as React.ReactElement<IPivotTabProps>[];

  if (tabs.length === 0) {
    throw new Error("Must pass at least 1 tab");
  } else if (tabs.some((t) => !t.props.id || !t.props.headerText)) {
    throw new Error("Must pass id and headerText props for tabs");
  }

  const [activeTab, setActiveTab] = useState(
    props.selectedTab ?? getInitialActiveTab(tabs)
  );

  // Respond if parent changes the selected tab
  useEffect(() => {
    if (props.selectedTab && props.selectedTab.length > 0) {
      setActiveTab(props.selectedTab);
    } else {
      setActiveTab(getInitialActiveTab(tabs));
    }
  }, [props.selectedTab]);

  const tabsToDisplay = getTabDisplays(activeTab, tabs);

  return (
    <div className={"pivot-tabs"}>
      {(!props.hideSingleTabButton || tabs.length > 1) && (
        <TabButtons
          styling={props.styling}
          wrapperClassName={"pivot-tabs-buttons-wrapper"}
          tabs={getTabHeaders(tabs)}
          activeTabId={activeTab}
          onChangeTab={(tabId) => {
            setActiveTab(tabId);
            if (props.onTabChange) {
              props.onTabChange(tabId);
            }
          }}
        />
      )}
      {tabsToDisplay}
    </div>
  );
};

PivotTabs.defaultProps = {
  selectedTab: "",
};

const getInitialActiveTab = (tabs: React.ReactElement<IPivotTabProps>[]) => {
  return tabs[0].props.id;
};

const getTabDisplays = (
  activeTabId: string,
  tabs: React.ReactElement<IPivotTabProps>[]
) => {
  return tabs
    .filter((tab) => !tab.props.disabled)
    .map((tab) => (
      <div
        key={tab.props.id}
        className={classNames("pivot-tab-panel", {
          active: tab.props.id === activeTabId,
        })}
      >
        {tab}
      </div>
    ));
};

const getTabHeaders = (tabs: React.ReactElement<IPivotTabProps>[]) => {
  return tabs.map((t) => {
    return {
      id: t.props.id,
      text: t.props.headerText,
      disabled: t.props.disabled,
    } as tabButton;
  });
};

export default PivotTabs;
