import {
  IVendorRiskWaiver,
  IVendorRiskWaiverApproveRequest,
  VendorRiskWaiverStatusType,
  WaiverType,
} from "../../_common/types/vendorRiskWaivers";
import { Dispatch } from "redux";
import { DefaultRootState } from "react-redux";
import { FetchCyberRiskUrl } from "../../_common/api";
import { LogError } from "../../_common/helpers";
import { PublicRiskWaiver } from "../../vendorrisk/types/sharedAssessment";
import { produce } from "immer";

export const SET_VENDOR_RISK_WAIVERS_AND_ADJUSTMENTS_TO_APPROVE =
  "SET_VENDOR_RISK_WAIVERS_AND_ADJUSTMENTS_TO_APPROVE";
export const setVendorRiskWaiversToApprove = (
  waivers: IVendorRiskWaiver[] | undefined,
  adjustments: IVendorRiskWaiver[] | undefined
) => {
  return {
    type: SET_VENDOR_RISK_WAIVERS_AND_ADJUSTMENTS_TO_APPROVE,
    waivers,
    adjustments,
  };
};

export interface GetVendorRiskWaiversV1Resp {
  privateWaivers: IVendorRiskWaiver[];
  publicWaivers: PublicRiskWaiver[];
}

export const getVendorRiskWaiversAndAdjustmentsToApprove = () => {
  return async (dispatch: Dispatch, getState: () => DefaultRootState) => {
    let resp: GetVendorRiskWaiversV1Resp | undefined;
    dispatch(setVendorRiskWaiversToApprove(undefined, undefined));

    try {
      resp = await FetchCyberRiskUrl<GetVendorRiskWaiversV1Resp>(
        "vendor/riskwaivers/v1/",
        {
          approvals_only: true,
        },
        {
          method: "GET",
        },
        dispatch,
        getState
      );
    } catch (e) {
      LogError(
        "error in VendorApp_getVendorRiskWaiversAndAdjustmentsToApprove",
        e
      );

      throw e;
    }

    // Split into waivers and adjustments
    const waivers =
      resp?.privateWaivers?.filter(
        (w) => w.waiverType === WaiverType.RiskWaiver
      ) ?? [];
    const adjustments =
      resp?.privateWaivers?.filter(
        (w) => w.waiverType === WaiverType.SeverityAdjustment
      ) ?? [];

    dispatch(setVendorRiskWaiversToApprove(waivers, adjustments));

    return resp;
  };
};

export const approveVendorRiskWaiver = (
  toUpdate: IVendorRiskWaiverApproveRequest
) => {
  return async (dispatch: Dispatch, getState: () => DefaultRootState) => {
    try {
      await FetchCyberRiskUrl<void>(
        "vendor/riskwaivers/approve/v1/",
        {},
        {
          method: "PUT",
          body: JSON.stringify(toUpdate),
        },
        dispatch,
        getState
      );
    } catch (e) {
      LogError(
        `error ${
          toUpdate.approve ? "approving" : "rejecting"
        } vendor risk waiver`,
        e
      );

      throw e;
    }

    // Update VendorApp state
    const waivers = getState().vendorPortal.vendorRiskWaivers;
    const adjustments = getState().vendorPortal.vendorRiskAdjustments;

    const waiversAndAdjustments = [...(waivers ?? []), ...(adjustments ?? [])];
    const waiverOrAdjustment = waiversAndAdjustments?.find(
      (w) => w.id === toUpdate.ID
    );

    if (
      adjustments &&
      waiverOrAdjustment?.waiverType === WaiverType.SeverityAdjustment
    ) {
      const newAdjustments = produce(adjustments, (draft) => {
        const adjustment = draft?.find((w) => w.id === toUpdate.ID);
        if (adjustment) {
          adjustment.status = toUpdate.approve
            ? VendorRiskWaiverStatusType.Active
            : VendorRiskWaiverStatusType.Rejected;
          adjustment.approverReason = toUpdate.justification;
        }
      });
      dispatch(
        setVendorRiskWaiversToApprove(
          getState().vendorPortal.vendorRiskWaivers,
          newAdjustments
        )
      );
    } else if (
      waivers &&
      waiverOrAdjustment?.waiverType === WaiverType.RiskWaiver
    ) {
      const newWaivers = produce(waivers, (draft) => {
        const waiver = draft?.find((w) => w.id === toUpdate.ID);
        if (waiver) {
          waiver.status = toUpdate.approve
            ? VendorRiskWaiverStatusType.Active
            : VendorRiskWaiverStatusType.Rejected;
          waiver.approverReason = toUpdate.justification;
        }
      });

      dispatch(
        setVendorRiskWaiversToApprove(
          newWaivers,
          getState().vendorPortal.vendorRiskAdjustments
        )
      );
    }
  };
};
