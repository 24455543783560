import {
  conditionalRefreshActivityStreamForOrgUser,
  grabTPVMSession,
} from "../../_common/reducers/commonActions";
import { FetchCyberRiskUrl } from "../../_common/api";
import { LogError } from "../../_common/helpers";
import { getVendorData } from "./cyberRiskActions";
import { get as _get } from "lodash";
import { EvidenceCategory, VendorEvidencePage } from "../types/evidence";
import { ITpvmSession } from "../../_common/types/thirdPartyMangedVendors";
import { DefaultThunkDispatch } from "../../_common/types/redux";
import { DefaultRootState } from "react-redux";

export const SET_VENDOR_EVIDENCE_PAGES = "SET_VENDOR_EVIDENCE_PAGES";
export const setEvidencePagesForVendor = (
  loading: boolean,
  vendorId: number,
  error: any,
  evidencePages: VendorEvidencePage[] | null,
  tpvmSession: ITpvmSession
) => {
  return {
    type: SET_VENDOR_EVIDENCE_PAGES,
    data: {
      loading: loading,
      vendorId: vendorId,
      error: error,
      evidencePages: evidencePages,
    },
    isManagementAnalystSession: tpvmSession && tpvmSession.tpvm,
    managedOrgId: tpvmSession ? tpvmSession.tpvm_o : 0,
  };
};

interface getEvidencePagesForVendorResp {
  evidencePages: VendorEvidencePage[];
}

export const fetchEvidencePagesForVendor = (
  vendorId: number,
  force = false
) => {
  return async (
    dispatch: DefaultThunkDispatch,
    getState: () => DefaultRootState
  ) => {
    const tpvmSession = grabTPVMSession(getState);
    const vendor = getVendorData(getState, vendorId, false, tpvmSession);

    if (!force) {
      const existing = _get(vendor, "evidence_pages");
      if (existing && !existing.error) {
        return;
      }
    }

    dispatch(
      setEvidencePagesForVendor(true, vendorId, null, null, tpvmSession)
    );
    let json;
    try {
      json = await FetchCyberRiskUrl<getEvidencePagesForVendorResp>(
        "watchedvendor/evidence_pages/v1/",
        {
          vendor_id: vendorId,
        },
        null,
        dispatch,
        getState
      );
    } catch (e) {
      LogError(`Error fetching evidence pages for vendor`, e);
      dispatch(
        setEvidencePagesForVendor(
          false,
          vendorId,
          {
            errorText: `Error fetching evidence pages for vendor.`,
            actionText: "Try again",
            actionOnClick: () =>
              dispatch(fetchEvidencePagesForVendor(vendorId, true)),
          },
          null,
          tpvmSession
        )
      );
      return;
    }

    if (!json) {
      dispatch(
        setEvidencePagesForVendor(
          false,
          vendorId,
          {
            errorText: `Error fetching evidence pages for vendor.`,
            actionText: "Try again",
            actionOnClick: () =>
              dispatch(fetchEvidencePagesForVendor(vendorId, true)),
          },
          null,
          tpvmSession
        )
      );
      return;
    }
    dispatch(
      setEvidencePagesForVendor(
        false,
        vendorId,
        null,
        json.evidencePages,
        tpvmSession
      )
    );

    // kick off call to update the activity stream
    dispatch(conditionalRefreshActivityStreamForOrgUser());
  };
};

export interface updateEvidencePageRequest {
  vendorId: number;
  oldCategory: EvidenceCategory;
  oldUrl: string;
  newCategory: EvidenceCategory;
  newUrl: string;
  newNote: string;
}

export const updateEvidencePage = (request: updateEvidencePageRequest) => {
  return async (
    dispatch: DefaultThunkDispatch,
    getState: () => DefaultRootState
  ) => {
    try {
      await FetchCyberRiskUrl(
        "watchedvendor/evidence_pages/v1/",
        {},
        { method: "PUT", body: JSON.stringify(request) },
        dispatch,
        getState
      );
    } catch (e) {
      LogError(`Error saving evidence page`, e);
      throw e;
    }

    // refresh the pages as we just changed something
    dispatch(fetchEvidencePagesForVendor(request.vendorId, true));

    // kick off call to update the activity stream
    dispatch(conditionalRefreshActivityStreamForOrgUser());
  };
};

export interface deleteEvidencePageRequest {
  vendorId: number;
  category: EvidenceCategory;
  url: string;
}

export const deleteEvidencePage = (request: deleteEvidencePageRequest) => {
  return async (
    dispatch: DefaultThunkDispatch,
    getState: () => DefaultRootState
  ) => {
    try {
      await FetchCyberRiskUrl(
        "watchedvendor/evidence_pages/v1/",
        {},
        { method: "DELETE", body: JSON.stringify(request) },
        dispatch,
        getState
      );
    } catch (e) {
      LogError(`Error deleting evidence page`, e);
      return;
    }

    // refresh the pages as we just changed something
    dispatch(fetchEvidencePagesForVendor(request.vendorId, true));

    // kick off call to update the activity stream
    dispatch(conditionalRefreshActivityStreamForOrgUser());
  };
};
