import ModalV2, { BaseModalProps } from "../ModalV2";
import { ISurveyReminder } from "../../types/survey";
import { FC, useState } from "react";
import "../../style/components/SurveyDetailsReminderModal.scss";
import Button from "../core/Button";
import ModalForm from "../../../vendorrisk/components/modals/ModalForm";
import moment from "moment/moment";
import PillLabel from "../../../vendorrisk/components/PillLabel";
import { LabelColor } from "../../types/label";
import DatePicker from "../DatePicker";
import { DefaultThunkDispatch } from "../../types/redux";
import { addDefaultUnknownErrorAlert } from "../../reducers/messageAlerts.actions";
import {
  cancelSurveyReminder,
  fetchSurveyReminders,
  fetchSurveyTimeline,
  scheduleSurveyReminder,
  sendSurveyReminder,
} from "../../reducers/surveyDetails.actions";
import ManagedVendorsAPI, {
  managedVendorRequestTag,
} from "../../../vendorrisk/reducers/managedVendorsAPI";

interface ISurveyDetailsRemindersModalProps extends BaseModalProps {
  dispatch: DefaultThunkDispatch;
  surveyId: number;
  reminders: ISurveyReminder[];
  shouldNotBeChangingStatusEtc?: boolean;
}

const sortReminders = (reminders: ISurveyReminder[]): ISurveyReminder[] =>
  reminders.sort((a, b) => (moment(a.when).isBefore(moment(b.when)) ? -1 : 1));

const SurveyDetailsReminderModal: FC<ISurveyDetailsRemindersModalProps> = ({
  dispatch,
  surveyId,
  reminders: origReminders,
  shouldNotBeChangingStatusEtc,
  active,
  onClose,
}) => {
  const today = moment().format("YYYY-MM-DD");
  const [loading, setLoading] = useState(false);
  const [cancelLoading, setCancelLoading] = useState(false);
  const [inputDate, setInputDate] = useState(today);
  const [reminders, setReminders] = useState(sortReminders(origReminders));

  const sendReminder = async () => {
    setLoading(true);

    try {
      await dispatch(sendSurveyReminder(surveyId));

      // Kick off a timeline update in the background
      dispatch(fetchSurveyTimeline(surveyId, true));
      const newReminders = await dispatch(fetchSurveyReminders(surveyId, true));
      setReminders(sortReminders(newReminders));
      //refresh MVA history timeline
      dispatch(
        ManagedVendorsAPI.util.invalidateTags([
          {
            type: managedVendorRequestTag,
          },
        ])
      );
    } catch (e) {
      console.error(e);
      dispatch(addDefaultUnknownErrorAlert("Error sending reminder"));
    } finally {
      setLoading(false);
    }
  };

  const scheduleReminder = async () => {
    setLoading(true);

    try {
      await dispatch(scheduleSurveyReminder(surveyId, inputDate));

      // Kick off a timeline update in the background
      dispatch(fetchSurveyTimeline(surveyId, true));
      const newReminders = await dispatch(fetchSurveyReminders(surveyId, true));
      setReminders(sortReminders(newReminders));
    } catch (e) {
      console.error(e);
      dispatch(addDefaultUnknownErrorAlert("Error sending reminder"));
    } finally {
      setLoading(false);
    }
  };

  const cancelReminder = async (reminderId: number) => {
    setCancelLoading(true);

    try {
      await dispatch(cancelSurveyReminder(reminderId));

      // Kick off a timeline update in the background
      dispatch(fetchSurveyTimeline(surveyId, true));
      const newReminders = await dispatch(fetchSurveyReminders(surveyId, true));
      setReminders(sortReminders(newReminders));
    } catch (e) {
      console.error(e);
      dispatch(addDefaultUnknownErrorAlert("Error sending reminder"));
    } finally {
      setCancelLoading(false);
    }
  };

  return (
    <ModalV2
      active={active}
      onClose={onClose}
      className="survey-reminder-modal"
      headerContent="View / schedule reminders"
      footerContent={<Button onClick={onClose}>Close</Button>}
    >
      <ModalForm>
        {reminders.length > 0 && (
          <div className="form-section">
            <div className="form-section-desc">
              <span>Reminders</span>
            </div>
            <div className="form-section-input">
              {reminders.map((r) => (
                <div className="reminder" key={r.id}>
                  <div className="reminder-date">
                    {moment(r.when).format("ll")}
                  </div>
                  <PillLabel
                    color={
                      r.status === "scheduled"
                        ? LabelColor.LightBlue
                        : LabelColor.Green
                    }
                  >
                    {r.status}
                  </PillLabel>
                  {r.status === "scheduled" &&
                    !shouldNotBeChangingStatusEtc && (
                      <Button
                        tertiary
                        disabled={cancelLoading}
                        onClick={() => cancelReminder(r.id)}
                      >
                        Cancel
                      </Button>
                    )}
                </div>
              ))}
            </div>
          </div>
        )}
        {!shouldNotBeChangingStatusEtc && (
          <div className="form-section">
            <div className="form-section-desc">
              <span>Schedule or send</span>
              <p>
                Select a date in the future to schedule a reminder, or select
                today&apos;s date to send one now.
              </p>
            </div>
            <div className="form-section-input schedule-section">
              <DatePicker
                disabled={loading || cancelLoading}
                value={inputDate}
                onChange={(e) => setInputDate(e.target.value)}
                min={today}
              />
              {inputDate === today ? (
                <Button
                  loading={loading}
                  disabled={cancelLoading}
                  onClick={sendReminder}
                >
                  Send reminder now
                </Button>
              ) : (
                <Button
                  loading={loading}
                  disabled={cancelLoading}
                  onClick={scheduleReminder}
                >
                  Schedule reminder
                </Button>
              )}
            </div>
          </div>
        )}
      </ModalForm>
    </ModalV2>
  );
};

export default SurveyDetailsReminderModal;
