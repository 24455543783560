import { FetchCyberRiskUrl } from "../../_common/api";
import {
  DefaultThunkDispatch,
  PLGOnboardingProgress,
} from "../../_common/types/redux";
import { DefaultRootState } from "react-redux";
import { LogError } from "../../_common/helpers";
import { trackEvent } from "../../_common/tracking";
import {
  getLocalStorageItem,
  setLocalStorageItem,
} from "../../_common/session";

import {
  OnboardingTaskMap,
  PLGOnboardingChecklistContent,
} from "../constants/plgOnboardingChecklistContent";
import { setUserData } from "../../_common/reducers/commonActions";

export const isChecklistComplete = (
  checklistName: string,
  completedTasks: string[]
) => {
  for (let i = 0; i < PLGOnboardingChecklistContent.length; i++) {
    if (PLGOnboardingChecklistContent[i]["checklistName"] === checklistName) {
      const tasks = PLGOnboardingChecklistContent[i]["tasks"];
      for (let taskIdx = 0; taskIdx < tasks.length; taskIdx++) {
        if (!completedTasks) {
          // if no completed tasks at all, don't try and render
          return true;
        }
        if (completedTasks.indexOf(tasks[taskIdx]["id"]) === -1) {
          return false;
        }
      }
    }
  }
  return true;
};

export const isTaskIncomplete = (
  taskName: string,
  plgOnboarding?: PLGOnboardingProgress
) => {
  // check to see if the given checklist is relevant to the user
  // and whether or not the task name is in their completed tasks list
  // returns true if checklist is relevant to user and task is not on completedTasks list
  // returns false if user has no checklists or tasks, or if the task is relevant and completed

  if (!plgOnboarding) {
    return false;
  }

  if (!plgOnboarding.completedTasks || !plgOnboarding.applicableChecklists) {
    // task is not incomplete because we don't have tasks.
    return false;
  }

  if (plgOnboarding.completedTasks.indexOf(taskName) > -1) {
    // task is not incomplete because it's been completed already.
    return false;
  }

  // iterate through all checklist content to see if this task is on any of user's applicable checklists
  for (let i = 0; i < PLGOnboardingChecklistContent.length; i++) {
    if (
      plgOnboarding.applicableChecklists.indexOf(
        PLGOnboardingChecklistContent[i]["checklistName"]
      ) > -1
    ) {
      // user has a checklist with this task on it!
      // now if taskName is not in their completedTasks, it's a true
      return plgOnboarding.completedTasks.indexOf(taskName) == -1;
    }
  }
  return false;
};

export const getIncompleteChecklists = (
  applicableChecklists: string[],
  completedTasks: string[]
) => {
  const incompleteChecklists: string[] = [];
  for (let i = 0; i < applicableChecklists.length; i++) {
    if (!isChecklistComplete(applicableChecklists[i], completedTasks)) {
      incompleteChecklists.push(applicableChecklists[i]);
    }
  }
  return incompleteChecklists;
};

export const getTasksForChecklist = (checklistName: string) => {
  const checklist = PLGOnboardingChecklistContent.find(
    (content) => content.checklistName === checklistName
  );
  if (checklist?.tasks) {
    return checklist.tasks;
  } else {
    return [] as OnboardingTaskMap[];
  }
};

export const getChecklistNameForTask = (
  taskName: string,
  plgOnboarding: PLGOnboardingProgress
) => {
  // return the name of the checklist this task is on for the user
  // this is necessary bc a task can appear on different checklists
  for (let i = 0; i < plgOnboarding.applicableChecklists.length; i++) {
    const tasks = getTasksForChecklist(plgOnboarding.applicableChecklists[i]);
    for (let j = 0; j < tasks.length; j++) {
      if (tasks[j]["id"] == taskName) {
        return plgOnboarding.applicableChecklists[i];
      }
    }
  }
  return "";
};

export const getTasksOnIncompleteChecklists = (
  incompleteChecklists: string[],
  completedTasks: string[]
) => {
  // return completedTasks string[] and allTasks string[]
  const newCompletedTasks: string[] = [];
  const allTasks: string[] = [];
  for (let i = 0; i < PLGOnboardingChecklistContent.length; i++) {
    if (
      incompleteChecklists.indexOf(
        PLGOnboardingChecklistContent[i]["checklistName"]
      ) > -1
    ) {
      // this checklist is in incompleteChecklists
      const tasks = PLGOnboardingChecklistContent[i]["tasks"];
      for (let taskIdx = 0; taskIdx < tasks.length; taskIdx++) {
        if (completedTasks.indexOf(tasks[taskIdx]["id"]) > -1) {
          // this is a completed task on an incomplete checklist. we keep it
          newCompletedTasks.push(tasks[taskIdx]["id"]);
        }
        allTasks.push(tasks[taskIdx]["id"]);
      }
    }
  }
  return [newCompletedTasks, allTasks];
};

export const getPLGTaskCounts = (
  applicableChecklists: string[],
  completedTasks: string[]
) => {
  const [newCompletedTasks, allTasks] = getTasksOnIncompleteChecklists(
    getIncompleteChecklists(applicableChecklists, completedTasks),
    completedTasks
  );
  return [newCompletedTasks.length, allTasks.length];
};

export const getPLGTasksIncompleteCount = (
  applicableChecklists: string[],
  completedTasks: string[]
) => {
  const [completedTasksCount, allTasksCount] = getPLGTaskCounts(
    applicableChecklists,
    completedTasks
  );
  return allTasksCount - completedTasksCount;
};

export const setPLGTaskCompleteIfIncomplete = (task_name: string) => {
  return async function setPLGOnboardingTaskCompleteThunk(
    dispatch: DefaultThunkDispatch,
    getState: () => DefaultRootState
  ): Promise<boolean> {
    if (!getState().common.userData.plgOnboarding?.completedTasks) {
      return false;
    }
    if (
      getState().common.userData.plgOnboarding.completedTasks.indexOf(
        task_name
      ) > -1
    ) {
      return false;
    }
    try {
      await FetchCyberRiskUrl(
        "accounts/user/onboardingchecklist/v1/",
        { task_name },
        { method: "POST" },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error setting PLG onboarding progress", e);
      throw e;
    }
    const userData = getLocalStorageItem("userData", true);
    if (typeof userData === "undefined") {
      return false;
    }
    if (userData && userData.plgOnboarding) {
      userData.plgOnboarding.completedTasks.push(task_name);
      dispatch(setUserData(userData));
    }
    trackEvent(task_name, { action: "completed" });
    return true;
  };
};

export const setPLGApplicableChecklists = (
  applicable_checklists: string[],
  other_use_case: string
) => {
  return async function setPLGApplicableChecklistsThunk(
    dispatch: DefaultThunkDispatch,
    getState: () => DefaultRootState
  ): Promise<void> {
    if (applicable_checklists == null) {
      return;
    }
    try {
      await FetchCyberRiskUrl(
        "accounts/user/onboardingchecklist/v1/enrol",
        { applicable_checklists, other_use_case },
        { method: "POST" },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error setting PLG checklists", e);
      throw e;
    }
    trackEvent("Checklist_enrol", { checklists: applicable_checklists });

    const userData = getLocalStorageItem("userData", true);
    if (typeof userData === "undefined") {
      return;
    }
    if (userData && userData.plgOnboarding) {
      userData.plgOnboarding.applicableChecklists = [...applicable_checklists];
      userData.plgOnboarding.completedTasks = [
        "Checklist_BreachSight_Add_Domains",
      ];
      setLocalStorageItem("userData", userData);
    }
  };
};

export function getTask(taskId: string) {
  const p = PLGOnboardingChecklistContent;
  for (let i = 0; i < p.length; i++) {
    for (let taskIdx = 0; taskIdx < p[i].tasks.length; taskIdx++) {
      if (p[i].tasks[taskIdx].id == taskId) {
        return p[i].tasks[taskIdx];
      }
    }
  }
  return {} as OnboardingTaskMap;
}
