import {
  ISurveyListItemResponse,
  ISurveyVersion,
  SurveyStatus,
} from "../../types/survey";
import ReportCard from "../ReportCard";

import "../../style/components/SurveyDetailsHistoryCard.scss";
import XTable, { IXTableRow, XTableCell } from "../core/XTable";
import moment from "moment/moment";
import PillLabel from "../../../vendorrisk/components/PillLabel";
import { LabelColor } from "../../types/label";
import IconButton, { HoverLocation } from "../IconButton";

export interface ISurveyDetailsHistoryCardProps {
  survey: ISurveyListItemResponse;
  canEditSurvey: boolean;
  openQuestionnaireEditor: (
    editMode: boolean,
    answerId: number,
    compareAnswerId?: number
  ) => void;
  redlinesEnabled: boolean;
}

const SurveyDetailsHistoryCard = (props: ISurveyDetailsHistoryCardProps) => {
  const { survey, canEditSurvey, openQuestionnaireEditor } = props;
  const versions = [...(survey.versions || [])];

  if (versions.length === 0) {
    // This survey hasn't been started yet. Create a dummy version in here
    versions.push({
      version: 0,
      createdAt: survey.dateLastSent,
      lastUpdated: survey.dateLastSent,
      shared: survey.status !== SurveyStatus.Sent,
      answerId: 0,
    });
  } else {
    // The first version (last item in versions array) should have its created date sent to
    // when the survey was originally created, not when the answer record was created.
    versions[versions.length - 1] = {
      ...versions[versions.length - 1],
      createdAt: survey.dateCreated,
    };
  }

  const [currentVersion, ...previousVersions] = versions;

  // we only show a compare icon if we have at least two versions
  const onCompareClick =
    currentVersion.shared && previousVersions.length > 0
      ? () =>
          openQuestionnaireEditor(
            false,
            currentVersion.answerId,
            previousVersions[0].answerId
          )
      : undefined;

  const getRow = (
    version: ISurveyVersion,
    isCurrentVersion: boolean
  ): IXTableRow => {
    const onClick =
      version.shared || (canEditSurvey && isCurrentVersion)
        ? () =>
            openQuestionnaireEditor(
              canEditSurvey && isCurrentVersion,
              isCurrentVersion ? 0 : version.answerId
            )
        : undefined;
    const text =
      version.version == 0
        ? "New version"
        : `Version ${version.version} - ${moment(version.lastUpdated).format(
            "ll"
          )}`;

    return {
      id: version.version,
      onClick,
      cells: [
        <XTableCell key="version">
          {version.shared ? (
            <>{text}</>
          ) : (
            <>
              {text}
              <PillLabel color={LabelColor.Blue}>Pending</PillLabel>
            </>
          )}
        </XTableCell>,
        <XTableCell key={"compare"} className={"shrink-cell"}>
          {!!onCompareClick && isCurrentVersion && props.redlinesEnabled && (
            <IconButton
              icon={<i className={"cr-icon-compare"} />}
              onClick={onCompareClick}
              hoverText={"Compare with Previous Version"}
              hoverLocation={HoverLocation.Top}
            />
          )}
        </XTableCell>,
        <XTableCell key="chevron" className="shrink-cell">
          {!!onClick && <div className="cr-icon-chevron" />}
        </XTableCell>,
      ],
    };
  };

  return (
    <ReportCard newStyles className="survey-details-history-card">
      <div className="header">History</div>
      <XTable
        columnHeaders={[
          { id: "current", text: "Current version", sortable: false },
          { id: "compare", text: "", sortable: false },
          { id: "chevron", text: "", sortable: false },
        ]}
        rows={[getRow(currentVersion, true)]}
      />
      {previousVersions.length > 0 && (
        <XTable
          columnHeaders={[
            { id: "previous", text: "Previous versions", sortable: false },
            { id: "compare", text: "", sortable: false },
            { id: "chevron", text: "", sortable: false },
          ]}
          rows={previousVersions.map((v) => getRow(v, false))}
        />
      )}
    </ReportCard>
  );
};

export default SurveyDetailsHistoryCard;
