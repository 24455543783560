import { FC, useState, useMemo, ReactElement } from "react";
import Button from "./core/Button";
import classnames from "classnames";
import ColorCheckbox from "../../vendorrisk/components/ColorCheckbox";
import "../style/components/MultiSelectionButton.scss";
import { flatten as _flatten } from "lodash";
import ToggleWithLabel from "../../vendorrisk/components/ToggleWithLabel";
import { useClickAway } from "../hooks";

export interface IOption {
  label: React.ReactNode;
  value: string;
  selected: boolean;
  disabled?: boolean;
  disabledText?: string;
}

export interface IOptionGroupConfig {
  column: number; // Column number this group should be displayed in
  heading?: string; // Optional heading to show above the optionGroup
}

interface IMultiSelectionButtonProps {
  className?: string;
  primary: boolean;
  text: string;
  icon?: string;
  optionGroups: IOption[][];
  // one of the below should be passed into subscribe to changes
  onSelectionChanged?: (selections: boolean[][]) => void;
  onSelectionChangedByValue?: (value: string, selected: boolean) => void;
  onResetDefault?: () => void;
  resetLabel?: string;
  toggleText?: string;
  onToggleChanged?: (selection: boolean) => void;
  toggleChecked?: boolean;
  showCount?: boolean;
  autoWidth?: boolean; // If true, expands to fit the content rather than a fixed width
  optionGroupsConfig?: IOptionGroupConfig[]; // Provides optional header and column for the optionGroup. There must be a corresponding optionGroupConfig for all optionGroups, or config will be ignored
  disabled?: boolean;
}

const MultiSelectionButton: FC<IMultiSelectionButtonProps> = ({
  className,
  primary,
  text,
  icon,
  optionGroups,
  onSelectionChanged,
  onSelectionChangedByValue,
  onResetDefault,
  resetLabel,
  toggleText,
  onToggleChanged,
  toggleChecked,
  showCount = true,
  autoWidth = false,
  optionGroupsConfig,
  disabled = false,
}) => {
  const [expanded, setExpanded] = useState(false);

  const numSelected = _flatten(optionGroups).filter((o) => o.selected).length;
  const numAvailable = _flatten(optionGroups).length;
  const buttonText = showCount
    ? `${text} (${numSelected}/${numAvailable})`
    : text;

  const selectionChanged = (groupIdx: number, optionIdx: number) => {
    if (onSelectionChanged) {
      const selections = optionGroups.map((group) =>
        group.map((option) => option.selected)
      );
      selections[groupIdx][optionIdx] = !selections[groupIdx][optionIdx];
      onSelectionChanged(selections);
    }
  };

  const ref = useClickAway(() => setExpanded(false));

  const checkBoxes = useMemo(() => {
    // If we have a group config, layout the checkboxes as defined
    if (
      optionGroupsConfig &&
      optionGroupsConfig.length === optionGroups.length
    ) {
      const tableContents: ReactElement[] = [];
      let columnNumber = 1;
      let columnContents: ReactElement[] = [];
      optionGroups.forEach((optionGroup, groupIdx) => {
        const config = optionGroupsConfig[groupIdx];

        let isNewGroup = groupIdx !== 0; // Show a divider bar to delineate between option groups, except for the first one
        if (columnNumber !== config.column) {
          // This is a new column, so add the previous one to the tableContents
          tableContents.push(
            <td className="option-column" key={columnNumber}>
              {columnContents}
            </td>
          );
          columnContents = [];
          columnNumber = config.column;
          isNewGroup = false; // New column - don't show the group divider bar
        }

        columnContents.push(
          <div
            className={classnames("option-group-header", {
              "new-group": isNewGroup,
              empty: !config.heading,
            })}
            key={"option-group-header" + groupIdx}
          >
            {config.heading}
          </div>
        );

        columnContents.push(
          ...optionGroup.map((option, optionIdx) => {
            return (
              <ColorCheckbox
                key={option.value}
                checked={option.selected}
                label={option.label}
                onClick={() => {
                  onSelectionChanged && selectionChanged(groupIdx, optionIdx);
                  onSelectionChangedByValue &&
                    onSelectionChangedByValue(option.value, !option.selected);
                }}
                disabled={option.disabled}
                helpPopup={option.disabledText}
                helpPopupMicroFormat={true}
                helpPopupWidth={200}
              />
            );
          })
        );
      });

      if (columnContents) {
        // Add the final column to the contents
        tableContents.push(
          <td className="option-column" key={columnNumber}>
            {columnContents}
          </td>
        );
      }

      return (
        <table>
          <tbody>
            <tr>{tableContents}</tr>
          </tbody>
        </table>
      );
    }

    // Otherwise use the default approach
    return _flatten(
      optionGroups.map((group, groupIdx) =>
        group.map((option, optionIdx) => (
          <ColorCheckbox
            className={groupIdx !== 0 && optionIdx === 0 ? "new-group" : ""}
            key={option.value}
            checked={option.selected}
            label={option.label}
            onClick={() => {
              onSelectionChanged && selectionChanged(groupIdx, optionIdx);
              onSelectionChangedByValue &&
                onSelectionChangedByValue(option.value, !option.selected);
            }}
            disabled={option.disabled}
            helpPopup={option.disabledText}
            helpPopupMicroFormat={true}
            helpPopupWidth={200}
          />
        ))
      )
    );
  }, [optionGroups, optionGroupsConfig]);

  return (
    <div
      className={classnames("multi-selection-button", className, {
        "auto-width": autoWidth,
      })}
      ref={ref}
    >
      <Button
        primary={primary}
        onClick={() => setExpanded(!expanded)}
        disabled={disabled}
      >
        {icon ? (
          <>
            <div className={icon} />
            {buttonText}
          </>
        ) : (
          <>
            {buttonText}
            <div
              className={`cr-icon-chevron ${expanded ? "rot270" : "rot90"}`}
            />
          </>
        )}
      </Button>
      {expanded && (
        <div className={"multi-selection-button options"}>
          <>
            {onResetDefault && (
              <p className={"reset-button"} onClick={onResetDefault}>
                {resetLabel ? resetLabel : "Reset to default"}
              </p>
            )}
            {toggleText && onToggleChanged && (
              <div className={"toggle"}>
                <ToggleWithLabel
                  onClick={() => {
                    onToggleChanged(!toggleChecked);
                  }}
                  selected={toggleChecked}
                  name={"toggle"}
                  label={toggleText}
                />
              </div>
            )}
          </>
          {checkBoxes}
        </div>
      )}
    </div>
  );
};

export default MultiSelectionButton;
