import { IBaseSingleVendorData, ISingleVendorData } from "../types/redux";
import { DefaultRootState } from "react-redux";
import { get as _get } from "lodash";
import { useAppSelector } from "../types/reduxHooks";

export const getVendorObject = (
  state: DefaultRootState,
  vendorID: number,
  isSubsidiary = false,
  managedOrgId?: number
): IBaseSingleVendorData | null => {
  if (isSubsidiary) {
    return state.cyberRisk.subsidiaries[vendorID];
  } else if (!!managedOrgId) {
    return _get(
      state.cyberRisk.managedVendorData,
      `[${managedOrgId}][${vendorID}]`,
      null
    );
  } else {
    return state.cyberRisk.vendors[vendorID] ?? null;
  }
};

export const getSingleVendorObject = (
  state: DefaultRootState,
  vendorID: number,
  managedOrgId?: number
): ISingleVendorData => {
  if (!!managedOrgId) {
    return _get(
      state.cyberRisk.managedVendorData,
      `[${managedOrgId}][${vendorID}]`,
      null
    );
  } else {
    return state.cyberRisk.vendors[vendorID] ?? null;
  }
};

export const useSingleVendorObject = (
  vendorID: number,
  managedOrgId?: number
) =>
  useAppSelector((state) =>
    getSingleVendorObject(state, vendorID, managedOrgId)
  );

export const useVendorDataSelector = <T>(
  selectorFunc: (vendorData: ISingleVendorData) => T,
  vendorID: number
): T | undefined => {
  return useAppSelector((state) => {
    // default managedOrgID to tpvm_o if not provided
    let managedOrgID: number | undefined;
    if (state.common.tpvmSession?.tpvm_o !== undefined) {
      const parsedOrgID = parseInt(state.common.tpvmSession.tpvm_o, 10);
      managedOrgID = isNaN(parsedOrgID) ? undefined : parsedOrgID;
    }

    let vendor: ISingleVendorData;
    if (managedOrgID) {
      vendor = state.cyberRisk?.managedVendorData?.[managedOrgID]?.[vendorID];
    } else {
      vendor = state.cyberRisk?.vendors?.[vendorID];
    }
    if (!vendor) {
      return undefined;
    }
    return selectorFunc(vendor);
  });
};

export const useBaseVendorData = (
  vendorID?: number,
  isSubsidiary = false,
  managedOrgID?: number
): IBaseSingleVendorData | undefined => {
  return useAppSelector((state) => {
    if (!vendorID) {
      return undefined;
    } else if (isSubsidiary) {
      return state.cyberRisk.subsidiaries[vendorID];
    } else if (managedOrgID) {
      return state.cyberRisk.managedVendorData[managedOrgID][vendorID];
    } else {
      return state.cyberRisk.vendors[vendorID];
    }
  });
};
