import { FC, useState } from "react";
import { SlidePanelSection } from "./SlidePanelSection";
import ColorCheckbox from "../ColorCheckbox";
import "../../style/components/filter/VulnsVerifiedStatusFilter.scss";

interface IVulnsVerifiedStatusFilterProps {
  excludeVerified: boolean;
  excludeUnverified: boolean;
  onlyKEV: boolean;
  onChange: (excludeVerified: boolean, excludeUnverified: boolean) => void;
  onChangeKEV: (onlyKEV: boolean) => void;
  title?: string;
  startExpanded?: boolean;
}

const VulnsVerifiedStatusFilter: FC<IVulnsVerifiedStatusFilterProps> = ({
  excludeUnverified,
  excludeVerified,
  onlyKEV,
  onChange,
  onChangeKEV,
  title = "Filter by verified status",
  startExpanded = false,
}) => {
  const [expanded, setExpanded] = useState(
    startExpanded || excludeUnverified || excludeVerified || onlyKEV
  );

  return (
    <SlidePanelSection
      classname="vulns-verified-status-filter"
      title={title}
      expanded={expanded}
      toggleExpand={() => setExpanded(!expanded)}
    >
      <ColorCheckbox
        label="Show all"
        onClick={() => {
          onChange(false, false);
          onChangeKEV(false);
        }}
        checked={!excludeVerified && !excludeUnverified && !onlyKEV}
      />
      <ColorCheckbox
        label="Verified vulnerabilities"
        onClick={() => onChange(false, !excludeUnverified)}
        checked={excludeUnverified}
      />
      <ColorCheckbox
        label="Unverified vulnerabilities"
        onClick={() => onChange(!excludeVerified, false)}
        checked={excludeVerified}
      />
      <ColorCheckbox
        label="Known exploited vulnerabilities"
        onClick={() => onChangeKEV(!onlyKEV)}
        checked={onlyKEV}
      />
    </SlidePanelSection>
  );
};

export default VulnsVerifiedStatusFilter;
