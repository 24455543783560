import * as React from "react";
import classnames from "classnames";
import "../style/components/IconButton.scss";
import LoadingIcon from "./core/LoadingIcon";
import { SidePopupV2 } from "./DismissablePopup";

export enum HoverColor {
  Red,
  Green,
  Blue,
}

export enum HoverLocation {
  Top,
  Left,
  Bottom,
}

export interface IIconButtonProps {
  className?: string;
  /** Icon element */
  icon: React.ReactNode;
  /** Text to show next to the icon */
  text?: React.ReactNode;
  /** Text to show on hover */
  hoverText?: React.ReactNode;
  /** function when clicked */
  onClick?: (ev?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  /** optional color on hover */
  hoverColor?: HoverColor;
  /** whether to style icon as primary action */
  isPrimaryAction?: boolean;
  /** whether action is currently disabled */
  disabled?: boolean;
  /** if true, don't stop propagation on click */
  keepPropagation?: boolean;
  /** if true, show loading spinner */
  loading?: boolean;
  /** if true, don't display */
  hidden?: boolean;
  /** optional location of the popup on hover **/
  hoverLocation?: HoverLocation;
  /** optional show a red dot in the bottom right corner **/
  attention?: boolean;
  /** optional show a red dot to the left of the icon **/
  attentionLeft?: boolean;
  hoverMicro?: boolean;
  /** wrap popup text more than one line. See popupWidth as well. */
  popupWrap?: boolean;
  popupWidth?: number;
  active?: boolean; // Sets same style as hover
  popupDelay?: number;
  "aria-label"?: string;
}

const IconButton = ({
  className,
  icon,
  text,
  hoverText,
  onClick,
  hoverColor,
  isPrimaryAction,
  disabled,
  keepPropagation,
  loading,
  hidden,
  hoverLocation = HoverLocation.Left,
  attention = false,
  attentionLeft = false,
  hoverMicro = false,
  popupWidth,
  active,
  popupWrap,
  popupDelay = 600,
  "aria-label": ariaLabel = "",
}: IIconButtonProps) => {
  if (hidden) {
    return <></>;
  }

  const iconElement = (
    <button
      type={"button"}
      aria-label={ariaLabel}
      className={classnames("btn-icon", {
        "hover-red": hoverColor === HoverColor.Red,
        "hover-green": hoverColor === HoverColor.Green,
        "hover-blue": hoverColor === HoverColor.Blue,
        primary: isPrimaryAction,
        disabled: disabled,
        loading: loading,
        "has-text": !!text,
        active: active,
      })}
      onClick={
        !disabled && !loading
          ? (e) => {
              if (onClick) {
                if (!keepPropagation) {
                  e.stopPropagation();
                  e.preventDefault();
                }

                onClick();
              }
            }
          : undefined
      }
    >
      {loading && <LoadingIcon size={24} />}
      {!loading && attentionLeft && (
        <div className="attention-icon attention-icon-left" />
      )}
      {!loading && icon}
      {!loading && attention && (
        <div className="attention-icon attention-icon-right" />
      )}
      {text && <div className="btn-text">{text}</div>}
    </button>
  );

  return (
    <SidePopupV2
      className={classnames("btn-icon-popup", className)}
      text={!loading ? hoverText : ""}
      position={
        hoverLocation == HoverLocation.Left
          ? "left"
          : hoverLocation == HoverLocation.Top
            ? "top"
            : hoverLocation == HoverLocation.Bottom
              ? "bottom"
              : "right"
      }
      micro={hoverMicro}
      width={popupWidth}
      noWrap={!popupWrap}
      popupDelay={popupDelay}
    >
      {iconElement}
    </SidePopupV2>
  );
};

export default React.memo(IconButton);
