import {
  AvailableSection,
  ConfiguredExportConfig,
  ConfiguredSection,
} from "../../../_common/types/exportConfig";
import { Dispatch, FC, useCallback, useMemo, useRef } from "react";
import ColorCheckbox from "../ColorCheckbox";
import IconButton from "../../../_common/components/IconButton";
import classnames from "classnames";
import { configuredExportConfigReducerAction } from "./exportConfigReducer";
import { DndProvider, useDrag, useDrop, XYCoord } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

interface IExportConfigurationSectionsListSectionProps {
  index: number;
  moveSection: (dragIndex: number, hoverIndex: number) => void;
  movable: boolean;
  sectionGroup: number;
  configuredSection: ConfiguredSection;
  availableSection: AvailableSection;
  selected: boolean;
  setSelectedSectionID: (sectionID: string) => void;
  configuredExportConfigDispatch: Dispatch<configuredExportConfigReducerAction>;
}

interface DragItem {
  index: number;
  id: string;
  type: string;
}

const ExportConfigurationSectionsListSection: FC<
  IExportConfigurationSectionsListSectionProps
> = ({
  index,
  moveSection,
  movable,
  sectionGroup,
  configuredSection,
  availableSection,
  selected,
  setSelectedSectionID,
  configuredExportConfigDispatch,
}) => {
  const sectionItemType = `section_${sectionGroup}`;

  const ref = useRef<HTMLDivElement>(null);
  const [dropProps, drop] = useDrop<
    DragItem,
    never,
    {
      handlerId: string | symbol | null;
    }
  >({
    accept: sectionItemType,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: DragItem, monitor) {
      if (!ref.current) {
        return;
      }

      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      // Time to actually perform the action
      moveSection(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: sectionItemType,
    item: { id: configuredSection.id, index, type: sectionItemType },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0 : 1;
  if (movable) {
    drag(drop(ref));
  }

  return (
    <div
      ref={ref}
      style={{ opacity }}
      data-handler-id={dropProps.handlerId}
      className={classnames("sections-list-section", "clickable", {
        selected,
      })}
      onClick={() => setSelectedSectionID(configuredSection.id)}
    >
      <div className={classnames("drag-handle", { movable })}>
        {movable && <div className="cr-icon-drag-handle" />}
      </div>
      <ColorCheckbox
        color="blue"
        checked={configuredSection.value}
        disabled={availableSection.disabled}
        onClick={(e) => {
          e.stopPropagation();
          configuredExportConfigDispatch({
            type: "SET_SECTION",
            id: configuredSection.id,
            value: !configuredSection.value,
          });

          // Set the selected section at the same time
          setSelectedSectionID(configuredSection.id);
        }}
      />
      <div className="section-title">{availableSection.title}</div>
      <IconButton icon={<div className="cr-icon-chevron" />} />
    </div>
  );
};

interface IExportConfigurationSectionsListProps {
  availableSectionsMap: Record<string, AvailableSection | undefined>;
  configuredExportConfig: ConfiguredExportConfig;
  configuredExportConfigDispatch: Dispatch<configuredExportConfigReducerAction>;
  selectedSectionID?: string;
  setSelectedSectionID: (sectionID: string) => void;
}

const ExportConfigurationSectionsList: FC<
  IExportConfigurationSectionsListProps
> = ({
  availableSectionsMap,
  configuredExportConfig,
  configuredExportConfigDispatch,
  selectedSectionID,
  setSelectedSectionID,
}) => {
  const sectionsWithAvailable = useMemo(() => {
    const sectionsWithAvailable: {
      sectionGroup: number;
      configuredSection: ConfiguredSection;
      availableSection: AvailableSection;
    }[] = [];

    // Keep track of a section group to ensure movable sections are only draggable to movable sections
    // in the same group.
    let sectionGroup = 0;
    let prevMovable = false;

    configuredExportConfig.sections.forEach((configuredSection) => {
      const availableSection = availableSectionsMap[configuredSection.id];
      if (!availableSection) {
        return;
      }

      if (availableSection.movable !== prevMovable) {
        sectionGroup += 1;
      }

      prevMovable = availableSection.movable;

      sectionsWithAvailable.push({
        sectionGroup,
        configuredSection,
        availableSection,
      });
    });

    return sectionsWithAvailable;
  }, [configuredExportConfig, availableSectionsMap]);

  const moveSection = useCallback(
    (dragIndex: number, hoverIndex: number) =>
      configuredExportConfigDispatch({
        type: "MOVE_SECTION",
        index: dragIndex,
        newIndex: hoverIndex,
      }),
    [configuredExportConfigDispatch]
  );

  return (
    <DndProvider backend={HTML5Backend}>
      <div className="sections-list">
        {sectionsWithAvailable.map(
          ({ configuredSection, availableSection, sectionGroup }, i) => (
            <ExportConfigurationSectionsListSection
              key={configuredSection.id}
              index={i}
              moveSection={moveSection}
              movable={availableSection.movable}
              sectionGroup={sectionGroup}
              configuredSection={configuredSection}
              availableSection={availableSection}
              selected={selectedSectionID === configuredSection.id}
              setSelectedSectionID={setSelectedSectionID}
              configuredExportConfigDispatch={configuredExportConfigDispatch}
            />
          )
        )}
      </div>
    </DndProvider>
  );
};

export default ExportConfigurationSectionsList;
