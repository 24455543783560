import BaseAPI from "../../_common/rtkQueryApi";
import {
  DisplayableManagedVendorAssessment,
  IManagedVendorPageOpts,
  ManagedVendorLicenseUsed,
  ManagedVendorServiceStatusType,
} from "../../_common/types/thirdPartyMangedVendors";
import { IUserMini, IUserMiniMap } from "../../_common/types/user";
import moment from "moment";
import { ICorrespondenceMessage } from "../../_common/types/correspondenceMessage";
import {
  fetchAdditionalEvidenceForVendor,
  fetchAdditionalEvidenceForVendorResp,
} from "../../vendorrisk/reducers/additionalEvidence.actions";
import { produce } from "immer";
import { setManagedServiceCustomerNames } from "./analystManagedVendors.actions";

export const analystManagedVendorLicensesTag =
  "analystManagedVendorLicensesTag";
export const managedVendorAnalystsTag = "managedVendorAnalystsTag" as const;
export const analystManagedVendorsListTag =
  "analystManagedVendorsListTag" as const;
export const analystManagedVendorRequestTag =
  "analystManagedVendorRequest" as const;
export const analystManagedVendorMessagesTag =
  "analystManagedVendorMessagesTag" as const;
export const analystManagedVendorAssessmentEvidenceTag =
  "analystManagedVendorAssessmentEvidenceTag" as const;

export interface analystManagedVendorListReq {
  page_num?: number;
  page_size?: number;
  sort_by?: string;
  sort_dir?: string;
  name_prefix?: string;
  customer_names?: string[];
  excluded_service_levels?: string[];
  included_service_statuses?: string[];
  analyst_users?: string[];
  org_id?: number;
  vendor_id?: number;
  tab?: "completed" | "inProgress" | "legacy";
}

export interface analystManagedVendorListResp {
  managedAssessments: DisplayableManagedVendorAssessment[];
  customerNames: string[];
  paging: IManagedVendorPageOpts;
  filterText: string;
  totals: {
    all: number;
    inProgress: number;
    completed: number;
    legacy: number;
  };
}

const AnalystManagedVendorsAPI = BaseAPI.enhanceEndpoints({
  addTagTypes: [
    analystManagedVendorLicensesTag,
    managedVendorAnalystsTag,
    analystManagedVendorsListTag,
    analystManagedVendorRequestTag,
    analystManagedVendorMessagesTag,
    analystManagedVendorAssessmentEvidenceTag,
  ],
}).injectEndpoints({
  endpoints: (builder) => ({
    getAnalystUsers: builder.query<{ analystUsers: IUserMini[] }, void>({
      query: () => ({
        url: "/cyberresearch/managedvendor/analysts",
        method: "GET",
      }),
      providesTags: (result) =>
        result ? [{ type: managedVendorAnalystsTag }] : [],
    }),

    getAnalystOrgLicenses: builder.query<
      { licenses: ManagedVendorLicenseUsed[] },
      { org_id: number }
    >({
      query: (args) => ({
        url: "/cyberresearch/managedvendor/license/v2",
        method: "GET",
        params: {
          ...args,
        },
      }),
      providesTags: (result) =>
        result ? [{ type: analystManagedVendorLicensesTag }] : [],
    }),

    getAnalystManagedVendorsList: builder.query<
      analystManagedVendorListResp,
      analystManagedVendorListReq
    >({
      query: (args) => ({
        url: "cyberresearch/managedvendors/v3",
        method: "GET",
        params: {
          ...args,
        },
      }),
      providesTags: (result, _error, args) =>
        result
          ? [
              ...result.managedAssessments.map((ma) => ({
                type: analystManagedVendorRequestTag,
                id: ma.id,
              })),
              { type: analystManagedVendorsListTag, id: JSON.stringify(args) },
              { type: analystManagedVendorsListTag, id: "PARTIAL-LIST" },
              { type: analystManagedVendorsListTag },
            ]
          : [],
      onQueryStarted: (_arg, { dispatch, queryFulfilled }) => {
        // upsert all our managed assessments
        queryFulfilled.then((result) => {
          result.data.managedAssessments.forEach((ma) => {
            dispatch(
              AnalystManagedVendorsAPI.util.upsertQueryData(
                "getAnalystManagedVendorAssessmentDetails",
                { requestID: ma.id },
                { result: ma }
              )
            );
          });
          dispatch(setManagedServiceCustomerNames(result.data.customerNames));
        });
      },
    }),

    getAnalystManagedVendorAssessmentDetails: builder.query<
      { result: DisplayableManagedVendorAssessment },
      { requestID: number }
    >({
      query: (args) => ({
        url: "cyberresearch/managedvendor/request/v2",
        method: "GET",
        params: {
          request_id: args.requestID,
        },
      }),
      providesTags: (result, _error, args) =>
        result
          ? [{ type: analystManagedVendorRequestTag, id: args.requestID }]
          : [],
    }),

    updateAnalystSelectedEvidence: builder.mutation<
      void,
      {
        requestID: number;
        evidenceIDs: number[];
        publicEvidenceIDs: number[];
      }
    >({
      query: (args) => ({
        url: "cyberresearch/managedvendor/evidence/v3",
        method: "PUT",
        body: JSON.stringify(args),
      }),
      onQueryStarted: (arg, { dispatch, queryFulfilled }) => {
        const patch = dispatch(
          AnalystManagedVendorsAPI.util.updateQueryData(
            "getAnalystManagedVendorAssessmentDetails",
            { requestID: arg.requestID },
            (draft) => {
              draft.result.publicEvidenceIDs = arg.publicEvidenceIDs;
              draft.result.additionalEvidenceIDs = arg.evidenceIDs;
              draft.result.lastUpdated = moment().format();
            }
          )
        );

        queryFulfilled.catch(() => {
          patch.undo();
        });
      },
    }),

    updateServiceStatus: builder.mutation<
      void,
      {
        requestID: number;
        serviceStatus: ManagedVendorServiceStatusType;
      }
    >({
      query: (args) => ({
        url: "cyberresearch/managedvendor/update/v1",
        method: "PUT",
        body: JSON.stringify(args),
      }),
      onQueryStarted: (args, { dispatch, queryFulfilled }) => {
        const patch = dispatch(
          AnalystManagedVendorsAPI.util.updateQueryData(
            "getAnalystManagedVendorAssessmentDetails",
            { requestID: args.requestID },
            (draft) => {
              draft.result.serviceStatus = args.serviceStatus;
              draft.result.lastUpdated = moment().format();
            }
          )
        );

        queryFulfilled.catch(() => {
          patch.undo();
        });
      },
      invalidatesTags: (result) =>
        result ? [{ type: analystManagedVendorsListTag }] : [],
    }),

    updateAnalystNote: builder.mutation<
      void,
      {
        requestID: number;
        analystNote: string;
      }
    >({
      query: (args) => ({
        url: "cyberresearch/managedvendor/update/v1",
        method: "PUT",
        body: JSON.stringify(args),
      }),
      onQueryStarted: (args, { dispatch, queryFulfilled }) => {
        const patch = dispatch(
          AnalystManagedVendorsAPI.util.updateQueryData(
            "getAnalystManagedVendorAssessmentDetails",
            { requestID: args.requestID },
            (draft) => {
              draft.result.analystNote = args.analystNote;
              draft.result.lastUpdated = moment().format();
            }
          )
        );

        queryFulfilled.catch(() => {
          patch.undo();
        });
      },
      invalidatesTags: (result) =>
        result ? [{ type: analystManagedVendorsListTag }] : [],
    }),

    updateAssignedAnalyst: builder.mutation<
      void,
      { requestID: number; analyst: IUserMini | undefined }
    >({
      query: (args) => ({
        url: "cyberresearch/managedvendor/analyst/v3",
        method: "PUT",
        params: {
          request_id: args.requestID,
          analyst_id: args.analyst?.id ?? 0,
        },
      }),
      invalidatesTags: (result, _, args) =>
        result
          ? [
              { type: analystManagedVendorsListTag },
              { type: analystManagedVendorRequestTag, id: args.requestID },
            ]
          : [],
    }),

    getMessagesForService: builder.query<
      { messages: ICorrespondenceMessage[]; users: IUserMiniMap },
      { requestID: number }
    >({
      query: (args) => ({
        url: "cyberresearch/managedvendor/message/v2",
        method: "GET",
        params: {
          request_id: args.requestID,
        },
      }),
      providesTags: (result, _, args) =>
        result
          ? [{ type: analystManagedVendorMessagesTag, id: args.requestID }]
          : [],
    }),

    postMessageForService: builder.mutation<
      void,
      { requestID: number; content: string; parentID?: number }
    >({
      query: (args) => ({
        url: "cyberresearch/managedvendor/message/v2",
        method: "POST",
        params: {
          request_id: args.requestID,
          content: args.content,
          parent_id: args.parentID,
        },
      }),
      invalidatesTags: (result, _, args) =>
        result
          ? [{ type: analystManagedVendorMessagesTag, id: args.requestID }]
          : [],
    }),

    editMessageForService: builder.mutation<
      void,
      { requestID: number; messageID: number; content: string }
    >({
      query: (args) => ({
        url: "cyberresearch/managedvendor/message/v2",
        method: "PUT",
        params: {
          message_id: args.messageID,
          content: args.content,
        },
      }),
      invalidatesTags: (result, _, args) =>
        result
          ? [{ type: analystManagedVendorMessagesTag, id: args.requestID }]
          : [],
    }),

    // getAnalystAdditionalEvidenceForRequest
    // gets additional evidence for an analyst during the managed assessment flow
    getAnalystAdditionalEvidenceForRequest: builder.query<
      fetchAdditionalEvidenceForVendorResp,
      { vendorID: number }
    >({
      query: (args) => ({
        url: "vendor/evidencelist/v1",
        method: "GET",
        params: {
          vendor_id: args.vendorID,
        },
      }),
      transformResponse: (result: fetchAdditionalEvidenceForVendorResp) => {
        return produce(result, (draft) => {
          draft.evidence.active = draft.evidence.active.map((e) => ({
            ...e,
            kind: "evidence",
          }));
          draft.evidence.archived = draft.evidence.archived.map((e) => ({
            ...e,
            kind: "evidence",
          }));
          draft.sharedAdditionalEvidence.active =
            draft.sharedAdditionalEvidence.active.map((e) => ({
              ...e,
              kind: "shared_evidence",
            }));
          draft.sharedAdditionalEvidence.archived =
            draft.sharedAdditionalEvidence.archived.map((e) => ({
              ...e,
              kind: "shared_evidence",
            }));
        });
      },
      providesTags: (result) =>
        result
          ? [
              {
                type: analystManagedVendorAssessmentEvidenceTag,
                id: result.vendorID,
              },
            ]
          : [],
    }),

    // uploadAnalystAdditionalEvidenceForRequest
    // uploads a new additional evidence file for an analyst as part of the managed vendor request flow
    uploadAnalystAdditionalEvidenceForRequest: builder.mutation<
      { id: number },
      {
        file: File;
        vendorID: number;
        documentTypeID: number;
        commentary: string;
        name: string;
      }
    >({
      query: (args) => {
        const data = new FormData();
        data.append("file", args.file);

        return {
          url: "vendor/evidence/new_document/v1/",
          method: "POST",
          body: data,
          params: {
            vendor_id: args.vendorID,
            document_type_id: args.documentTypeID,
            name: args.name,
            complete: true,
            commentary: args.commentary,
          },
        };
      },
      transformResponse: (result: { newEvidenceId: number }) => {
        return { id: result.newEvidenceId };
      },
      invalidatesTags: (result, _, args) =>
        result
          ? [
              {
                type: analystManagedVendorAssessmentEvidenceTag,
                id: args.vendorID,
              },
            ]
          : [],
      onQueryStarted: (args, { dispatch, queryFulfilled }) => {
        queryFulfilled.then(() => {
          dispatch(fetchAdditionalEvidenceForVendor(args.vendorID, true));
        });
      },
    }),
  }),
});

export default AnalystManagedVendorsAPI;
