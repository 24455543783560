import { FC, ReactElement } from "react";
import { History } from "history";
import moment from "moment";
import ReportCard from "../../../_common/components/ReportCard";
import AssessmentIcon from "../../images/risk-assessment-header-icon-nocircle.svg";
import Button from "../../../_common/components/core/Button";
import "../../style/components/vendor_summary/RiskManagementTile.scss";
import {
  DefaultThunkDispatch,
  VendorDataItemSummary,
} from "../../../_common/types/redux";
import PillLabel, { getColorForKey } from "../PillLabel";
import { LabelColor } from "../../../_common/types/label";
import { IVendorWords } from "../../../_common/constants";
import {
  formatDateAsLocal,
  pluralise,
  vendorUrlPrefix,
} from "../../../_common/helpers";
import CompleteVendorAssessmentModal from "../modals/CompleteVendorAssessmentModal";

import {
  addDefaultSuccessAlert,
  addDefaultUnknownErrorAlert,
} from "../../../_common/reducers/messageAlerts.actions";
import {
  fetchCustomerLookupsData,
  fetchVendorSummaryAndCloudscans,
} from "../../reducers/cyberRiskActions";
import UnwatchedVendorCard from "../UnwatchedVendorCard";
import classnames from "classnames";
import { AssuranceType } from "../../../_common/types/organisations";
import { VerifiedVendorPublicScore } from "../../../_common/types/vendor";
import { SidePopupV2 } from "../../../_common/components/DismissablePopup";
import { VendorEvidencePage } from "../../types/evidence";
import NewFeaturePill, {
  NewFeatureType,
} from "../../../_common/components/NewFeaturePill";
import { useHistory } from "react-router-dom";
import { PublicRiskWaiverWarningIconContent } from "../risk_waivers/PublicRiskWaiverWarningIcon";
import VendorAssessmentAPI, {
  VendorAssessmentStream,
} from "../../reducers/vendorAssessmentAPI";
import {
  VendorAssessmentStatus,
  VendorAssessmentStatusPill,
} from "../../types/vendorAssessments";
import { useModalV2 } from "../../../_common/components/ModalV2";
import NewAssessmentStreamModal from "../VendorAssessment/NewAssessmentStreamModal";
import IconButton, {
  HoverLocation,
} from "../../../_common/components/IconButton";
import VendorAssessmentsV3VersionsSideBar from "../VendorAssessment/VendorAssessmentsV3VersionsSideBar";
import { sortBy } from "lodash";
import { useVendorWords } from "../../../_common/hooks";
import { useAppDispatch } from "../../../_common/types/reduxHooks";
import { downloadUpGuardPublishedAssessment } from "../../reducers/vendorAssessment.actions";
import { trackEvent } from "../../../_common/tracking";
import { ManagedVendorAssessmentPill } from "../ManagedVendorAssessmentPill";
import { TourHighlightFromPLGContent } from "../../../_common/components/TourHighlight";

interface IRiskManagementTileProps {
  history: History;
  dispatch: DefaultThunkDispatch;
  vendorId: number;
  vendorName: string;
  watching: boolean;
  mostRecentQuestionnaire?: VendorDataItemSummary;
  mostRecentRelationshipQuestionnaire?: VendorDataItemSummary;
  mostRecentRemediation?: VendorDataItemSummary;
  mostRecentAdditionalEvidence?: VendorDataItemSummary;
  totalActiveDomains: number;
  numRiskModifications: number;
  numUnapprovedRiskModifications: number;
  userHasVendorAssessmentPermission: boolean;
  userHasVendorAssessmentAuthorPermission: boolean;
  userHasAdditionalEvidencePermission: boolean;
  userHasQuestionnairesPermission: boolean;
  unreadSurveyMessageCount?: number;
  userEmailAddress: string;
  vendorWords: IVendorWords;
  isManagementAnalystSession: boolean;
  managedOrgId?: number;
  assuranceType: AssuranceType;
  numPendingPublicRiskWaivers?: number;
  verifiedVendorPublicScore?: VerifiedVendorPublicScore;
  evidencePages?: VendorEvidencePage[];
  score?: number;
}

type StatColor = "green" | "blue" | "orange" | "grey";

interface IRiskManagementBadgeProps {
  history: History;
  vendorId: number;
  meta: IRiskManagementBadgeMeta;
  vendorWords: IVendorWords;
  isManagementAnalystSession: boolean;
  managedOrgId?: number;
}

interface IRiskManagementStatsMeta {
  key: string;
  color: StatColor;
  value: number;
  label: string;
  show: boolean;
  popup?: React.ReactNode;
}

interface IMultiLink extends Partial<IRiskManagementStatsMeta> {
  url?: string;
  statColor?: StatColor;
}

interface IRiskManagementBadgeMeta {
  icon: string;
  title: string;
  url?: string;
  stats: IRiskManagementStatsMeta[];
  unReadMessages?: number;
  dontShow?: boolean;
  isMultiLinkVariant?: boolean;
  multiLinks?: IMultiLink[];
  className?: string;
  newFeature?: NewFeatureType;
  infoPopup?: React.ReactNode;
}

const jumpToVendorPage = (
  history: History,
  vendorId: number,
  page: string,
  vendorWords: IVendorWords,
  isManagementAnalystSession: boolean,
  managedOrgId?: number
) => {
  let prefix = `/vendor/${vendorId}`;
  if (isManagementAnalystSession) {
    prefix = `/analysts/tpvm/${managedOrgId}/${vendorId}`;
  }
  history.push(`${prefix}/${page}`, {
    backContext: {
      backToText: `Back to ${vendorWords.singularTitleCase} Summary`,
      goBack: true,
    },
  });
};

export const isReassessmentOverdue = (currentReassessmentDate: string) => {
  const reassessment = moment(currentReassessmentDate).toDate();
  const curr = new Date();
  const reassessmentMinus30Days = new Date(reassessment);
  reassessmentMinus30Days.setDate(reassessmentMinus30Days.getDate() - 30);
  const impendingReassessment =
    curr.getTime() > reassessmentMinus30Days.getTime();
  const overdueAssessment = curr.getTime() > reassessment.getTime();

  return {
    impendingReassessment,
    overdueAssessment,
  };
};

interface VendorAssessmentsSummaryBlockProps {
  assessments: VendorAssessmentStream[];
  assessmentsLoading: boolean;
  userHasVendorAssessmentPermission: boolean;
  userHasVendorAssessmentAuthorPermission: boolean;
  vendorWords: IVendorWords;
  goToAssessment: (streamId: number) => void;
  onNewAssessment: () => void;
  onChangeAssessmentDate: (
    streamId: number,
    reassessmentDate?: string
  ) => Promise<unknown>;
  onOpenVersionHistory: (streamId: number) => void;
}

export const VendorAssessmentsSummaryBlock: FC<
  VendorAssessmentsSummaryBlockProps
> = (props) => {
  const noAssessment = props.assessments.length == 0;

  const [openCompleteVendorAssessmentModal, completeVendorAssessmentModal] =
    useModalV2(CompleteVendorAssessmentModal);
  const onOpenAssessmentDateModal = (
    streamId: number,
    reassessmentDate?: string
  ) =>
    openCompleteVendorAssessmentModal({
      newReassessmentDate: reassessmentDate,
      confirm: (reassessmentDate) =>
        props.onChangeAssessmentDate(streamId, reassessmentDate),
      hasUnfinishedSurveys: false,
      newlyPublished: false,
    });

  // if we are still loading just return nothing
  if (props.assessmentsLoading) {
    return <></>;
  }

  // if we only have a single or no assessments use the old style
  if (props.assessments.length < 2) {
    if (noAssessment && !props.userHasVendorAssessmentAuthorPermission) {
      // if there is no assessment and the user can't create
      // one then no point in showing anything
      return <></>;
    }

    const version =
      props.assessments.length == 1 ? props.assessments[0] : undefined;
    const inProgressAssessment =
      version?.status == VendorAssessmentStatus.Draft;
    const completedAssessment =
      version?.status == VendorAssessmentStatus.Published;

    const { impendingReassessment, overdueAssessment } = isReassessmentOverdue(
      version?.reassessmentDate ?? ""
    );

    return (
      <div className={"vendor-assessment-summary-info-block"}>
        <div
          className={classnames("top-row", {
            clickable: props.userHasVendorAssessmentAuthorPermission,
          })}
          onClick={() => {
            if (props.userHasVendorAssessmentAuthorPermission) {
              if (version) {
                props.goToAssessment(version?.id);
              } else {
                props.onNewAssessment();
              }
            }
          }}
        >
          <div className={"img-circle"}>
            <img src={AssessmentIcon} />
          </div>
          <div className={"detail"}>
            {noAssessment && props.userHasVendorAssessmentAuthorPermission && (
              <>
                <div className={"title"}>{"Conduct a risk assessment"}</div>
                <div className={"text"}>
                  {`Start a risk assessment to review, assess and report on the ${props.vendorWords.singular}’s current risk profile.`}
                </div>
              </>
            )}
            {inProgressAssessment && (
              <div className={"multi-header"}>
                <div className={"multi"}>
                  <div className={"title"}>
                    {props.userHasVendorAssessmentAuthorPermission
                      ? "Continue your risk assessment"
                      : "A risk assessment is currently in progress"}
                  </div>
                  {version && version.versionNumber > 1 && (
                    <div
                      className={"version-history"}
                      onClick={(e) => {
                        e.stopPropagation();
                        props.onOpenVersionHistory(version.id);
                      }}
                    >
                      <div className={"cr-icon-timer"} />
                      {"Version history"}
                    </div>
                  )}
                </div>
                {!!version && version.lastPublishedDate && (
                  <div className={"date-tile"}>
                    <div className={"head"}>{"Last Assessed"}</div>
                    <div
                      className={"date date-clickable"}
                      onClick={(e) => {
                        e.stopPropagation();
                        props.goToAssessment(version.id);
                      }}
                    >
                      {formatDateAsLocal(version.lastPublishedDate)}{" "}
                      <div className={"cr-icon-chevron"} />
                    </div>
                  </div>
                )}
              </div>
            )}
            {!inProgressAssessment && completedAssessment && (
              <>
                <div className={"multi-header"}>
                  <div className={"multi"}>
                    <div className={"title"}>
                      {"Risk Assessment"}
                      {overdueAssessment && (
                        <PillLabel color={LabelColor.Red}>Overdue</PillLabel>
                      )}
                      {version && version.versionNumber > 1 && (
                        <div
                          className={"version-history"}
                          onClick={(e) => {
                            e.stopPropagation();
                            props.onOpenVersionHistory(version.id);
                          }}
                        >
                          <div className={"cr-icon-timer"} />
                          {"Version history"}
                        </div>
                      )}
                    </div>
                  </div>
                  {!!version && (
                    <div className={"date-tile"}>
                      <div className={"head"}>{"Last Assessed"}</div>
                      <div
                        className={"date date-clickable"}
                        onClick={(e) => {
                          e.stopPropagation();
                          props.goToAssessment(version?.id);
                        }}
                      >
                        {formatDateAsLocal(version?.publishedDate)}{" "}
                        <div className={"cr-icon-chevron"} />
                      </div>
                    </div>
                  )}
                  <div className={"reassess-tile"}>
                    <div className={"head"}>{"Reassessment Date"}</div>
                    {!version?.reassessmentDate && (
                      <>
                        {props.userHasVendorAssessmentAuthorPermission && (
                          <Button
                            className={"link"}
                            tertiary
                            onClick={(e) => {
                              e.stopPropagation();
                              onOpenAssessmentDateModal(version.id);
                            }}
                          >
                            <div className={"cr-icon-plus"} />
                            {"Add date"}
                          </Button>
                        )}
                        {!props.userHasVendorAssessmentAuthorPermission && (
                          <i>No reassessment date set</i>
                        )}
                      </>
                    )}
                    {!!version?.reassessmentDate && (
                      <>
                        {props.userHasVendorAssessmentAuthorPermission && (
                          <div
                            className={classnames("date date-clickable", {
                              red: impendingReassessment || overdueAssessment,
                            })}
                            onClick={(e) => {
                              e.stopPropagation();
                              onOpenAssessmentDateModal(version.id);
                            }}
                          >
                            {formatDateAsLocal(version.reassessmentDate)}
                            <div
                              className={classnames("cr-icon-pencil", {
                                red: impendingReassessment || overdueAssessment,
                              })}
                              onClick={(e) => {
                                e.stopPropagation();
                                onOpenAssessmentDateModal(version.id);
                              }}
                            />
                          </div>
                        )}
                        {!props.userHasVendorAssessmentAuthorPermission && (
                          <div className={"date"}>
                            {formatDateAsLocal(version.reassessmentDate)}
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </>
            )}
          </div>
          {props.userHasVendorAssessmentAuthorPermission && noAssessment && (
            <TourHighlightFromPLGContent
              onboardingTaskId={"Checklist_VendorRisk_Assessment"}
              highlightIndex={1}
              position={"top"}
              visible={true}
              scrollToMeOnMount={true}
            >
              <Button
                filledPrimary
                onClick={(e) => {
                  e.stopPropagation();
                  props.onNewAssessment();
                }}
              >
                <div className={"cr-icon-plus"} />
                {"Conduct assessment"}
              </Button>
            </TourHighlightFromPLGContent>
          )}
          {props.userHasVendorAssessmentAuthorPermission &&
            inProgressAssessment && (
              <>
                <PillLabel color={LabelColor.Orange}>{"In Progress"}</PillLabel>
                {version?.managedAssessmentID && ManagedVendorAssessmentPill()}
                <div
                  className="cr-icon-chevron"
                  onClick={(e) => {
                    e.stopPropagation();
                    props.goToAssessment(version.id);
                  }}
                />
              </>
            )}
          {props.userHasVendorAssessmentAuthorPermission &&
            !noAssessment &&
            !inProgressAssessment &&
            (impendingReassessment || overdueAssessment) && (
              <Button
                arrow
                filledPrimary
                onClick={(e) => {
                  e.stopPropagation();
                  props.onNewAssessment();
                }}
              >
                Reassess
              </Button>
            )}
          {!!version &&
            !inProgressAssessment &&
            (!(impendingReassessment || overdueAssessment) ||
              !props.userHasVendorAssessmentAuthorPermission) && (
              <Button
                primary
                onClick={(e) => {
                  e.stopPropagation();
                  props.goToAssessment(version.id);
                }}
              >
                {"View assessment"}
                <div className="cr-icon-arrow-right" />
              </Button>
            )}
        </div>
        {completeVendorAssessmentModal}
      </div>
    );
  } else {
    // if we have multiple streams of assessment then we want to show the new styles
    return (
      <div className={"vendor-assessment-summary-info-block-multi"}>
        {sortBy(props.assessments, (value) => moment(value.publishedDate)).map(
          (a) => (
            <div
              className={"assessment-block"}
              key={a.id}
              onClick={() => props.goToAssessment(a.id)}
            >
              <div className={"left-side"}>
                <div className={"left-top detail"}>
                  <div className={"assessment-name"}>{a.name}</div>
                  <div
                    className={classnames("assessment-scope", {
                      "whole-vendor": !a.scope || a.scope.length == 0,
                    })}
                  >
                    {a.scope && a.scope.length > 0
                      ? a.scope.map((s) => (
                          <PillLabel color={getColorForKey(s)} key={s}>
                            {s}
                          </PillLabel>
                        ))
                      : `Whole ${props.vendorWords.singular}`}
                  </div>
                </div>
                <div className={"left-bottom"}>
                  <div
                    onClick={
                      a.versionNumber
                        ? (e) => {
                            e.stopPropagation();
                            props.onOpenVersionHistory(a.id);
                          }
                        : undefined
                    }
                    className={classnames("assessment-version", {
                      clickable: a.versionNumber > 1,
                    })}
                  >
                    {`Version ${a.versionNumber} `}
                    {a.versionNumber > 1 && <i className={"cr-icon-timer"} />}
                  </div>
                  <div className={"last-assessed"}>
                    {a.status != VendorAssessmentStatus.Draft && (
                      <>• Published {moment(a.publishedDate).format("ll")}</>
                    )}
                  </div>
                </div>
              </div>
              <div className={"right-side"}>
                <PillLabel color={VendorAssessmentStatusPill[a.status].color}>
                  {VendorAssessmentStatusPill[a.status].text}
                </PillLabel>
                {a.managedAssessmentID && ManagedVendorAssessmentPill()}
                {props.userHasVendorAssessmentAuthorPermission && (
                  <IconButton
                    icon={<i className={"cr-icon-chevron"} />}
                    onClick={() => props.goToAssessment(a.id)}
                  />
                )}
              </div>
            </div>
          )
        )}
        {completeVendorAssessmentModal}
      </div>
    );
  }
};

const UpGuardPublishedAssessmentBlock: FC<{
  assessment: VendorAssessmentStream;
}> = ({ assessment }) => {
  const vendorWords = useVendorWords();
  const dispatch = useAppDispatch();

  return (
    <div className="vendor-assessment-upguard-published-block">
      <div>
        <div className="assessment-title">
          {assessment.name}
          <span>Whole {vendorWords.singular}</span>
        </div>
        <div className="assessment-title-sub">
          Version {assessment.versionNumber} • Published{" "}
          {moment(assessment.publishedDate).format("ll")}
        </div>
      </div>
      <div className="block-right">
        <PillLabel color={LabelColor.Green}>Complete</PillLabel>
        <SidePopupV2
          popupHoverable
          width={232}
          text={
            <>
              UpGuard used Managed Vendor Assessments to create this Risk
              Assessment.
              <br />
              <a
                href="https://www.upguard.com/services"
                target="_blank"
                rel="nofollow noreferrer"
              >
                Learn more <span className="cr-icon-arrow-right" />
              </a>
            </>
          }
        >
          <PillLabel color={LabelColor.Blue}>
            Prepared by UpGuard <div className="cr-icon-info" />
          </PillLabel>
        </SidePopupV2>
        <IconButton
          icon={<div className="cr-icon-export-thin" />}
          hoverText="Download"
          hoverMicro={true}
          hoverLocation={HoverLocation.Top}
          onClick={() => {
            trackEvent("UpGuardPublishedAssessment_Download", {
              vendorID: assessment.vendorID,
            });
            dispatch(
              downloadUpGuardPublishedAssessment(
                assessment.vendorID,
                assessment.upGuardPublishedAssessmentUUID!
              )
            );
          }}
        />
      </div>
    </div>
  );
};

//
// RiskManagementBadge
// Renders one element or 'badge' on the 'RiskManagementTile' component (see below). Badges are defined for each of the major
// contributors to a vendor risk assessment, and display the core statistics for that area of interest (eg domains, waivers,
// remediation, questionnaires etc). The specific badges displayed for a vendor in the 'RiskManagaementTile' are defined in
// array 'riskManagementBagdeMeta' below.
//
const RiskManagementBadge = (props: IRiskManagementBadgeProps) => {
  // normal single link variant
  if (!props.meta.isMultiLinkVariant) {
    const stats = props.meta.stats
      .filter((s) => s.show)
      .map((stat) => (
        <SidePopupV2 key={stat.key} text={stat.popup} popupHoverable>
          <div className={"stat"}>
            <div className={`ball ${stat.color}`} />
            <div className={"text"}>{`${stat.value.toLocaleString()} ${
              stat.label
            }`}</div>
          </div>
        </SidePopupV2>
      ));

    return (
      <div
        className={classnames(
          "badge",
          { "new-feature": !!props.meta.newFeature },
          props.meta.className
        )}
        onClick={() => {
          if (props.meta.url) {
            jumpToVendorPage(
              props.history,
              props.vendorId,
              props.meta.url,
              props.vendorWords,
              props.isManagementAnalystSession,
              props.managedOrgId
            );
          }
        }}
      >
        <div className={`${props.meta.icon} badge-icon`} />
        <div className={"details"}>
          <div className={"title"}>
            {props.meta.title}
            {props.meta.infoPopup && (
              <SidePopupV2
                text={props.meta.infoPopup}
                position={"top"}
                width={220}
                className={"meta-info-popup"}
              >
                <i className={"cr-icon-risk"} />
              </SidePopupV2>
            )}
          </div>
          {stats.length > 0 && (
            <div className={"stats"}>
              <>
                {stats}
                {!!props.meta.unReadMessages && (
                  <SidePopupV2
                    text={`${props.meta.unReadMessages} unread messages`}
                    position={"top"}
                    noWrap
                  >
                    <>
                      <div className={"cr-icon-chat-messages"} />
                      <div className={"num-messages"}>
                        {props.meta.unReadMessages}
                      </div>
                    </>
                  </SidePopupV2>
                )}
              </>
            </div>
          )}
        </div>
        {!!props.meta.newFeature && (
          <NewFeaturePill featureType={props.meta.newFeature} />
        )}
        <div className={"cr-icon-chevron"} />
      </div>
    );
  }

  const stats: ReactElement[] = [];
  props.meta.multiLinks?.forEach((link) => {
    if (link.label && link.show) {
      stats.push(
        <div
          key={link.label}
          className={classnames("stat", { clickable: !!link.url })}
          onClick={
            link.url
              ? () =>
                  jumpToVendorPage(
                    props.history,
                    props.vendorId,
                    link.url!,
                    props.vendorWords,
                    props.isManagementAnalystSession,
                    props.managedOrgId
                  )
              : undefined
          }
        >
          {link.statColor && (
            <div className={classnames("ball", link.statColor)} />
          )}
          <div className={"text"}>
            {link.value !== undefined ? (
              <>{link.value.toLocaleString()} </>
            ) : null}
            {link.label}
          </div>
          {!!link.url && <div className={"cr-icon-chevron"} />}
        </div>
      );
    }
  });

  // multilink variant (eg Domains & IPs)
  return (
    <div className={"badge nohover"}>
      <div className={`${props.meta.icon} badge-icon`} />
      <div className={"details"}>
        <div className={"title"}>{props.meta.title}</div>
        {stats.length > 0 && (
          <div className={"stats"}>
            <>{stats}</>
          </div>
        )}
      </div>
    </div>
  );
};

//
// RiskManagementTile
// This tile appears on the vendor summary view, replacing the old 'Risk Management' tile that was rendered as part
// of the 'VendorSummaryInformation' component (which is now deprecated). This component along with 'CompanyProfileTile'
// represent the replacements for the deprecated 'VendorSummaryInformation' component, with the Risk Management portion
// (rendered by this component) focussing on vendor assessment and the statistics of interest to this process.
//
const RiskManagementTile = (props: IRiskManagementTileProps) => {
  const history = useHistory();

  const { data: vendorAssessments, isLoading: vendorAssessmentsLoading } =
    VendorAssessmentAPI.useGetVendorAssessmentStreamsV1Query(
      { vendorID: props.vendorId },
      { skip: !props.watching }
    );

  const { data: assessmentLists } =
    VendorAssessmentAPI.useGetVendorAssessmentListQuery(
      { vendorID: props.vendorId },
      { skip: !props.watching }
    );

  const numUnacceptedPublicRiskWaivers = props.numPendingPublicRiskWaivers ?? 0;

  const goToVendorRiskWaiversPage = (vendorId: number) => {
    history.push(`/vendor/${vendorId}/riskwaivers`, {
      backContext: {
        backTo: `/vendor/${vendorId}`,
        backToText: `Back to ${props.vendorWords.singular} summary`,
      },
    });
  };

  const riskManagementBagdeMeta: IRiskManagementBadgeMeta[] = [
    {
      infoPopup: "You can now find risk waivers and adjusted risks here",
      title: "Modified risks",
      icon: "cr-icon-questionnaire",
      url: "riskwaivers",
      stats: [
        {
          key: "numRiskModifications",
          color: props.numRiskModifications > 0 ? "green" : "grey",
          value: props.numRiskModifications,
          label: `${pluralise(
            props.numRiskModifications,
            "risk",
            "risks"
          )} managed`,
          show: true,
        },
        {
          key: "numUnapprovedRiskModifications",
          color: props.numUnapprovedRiskModifications > 0 ? "orange" : "grey",
          value: props.numUnapprovedRiskModifications,
          label: "awaiting approval",
          show: props.numUnapprovedRiskModifications > 0,
        },
        {
          key: "numUnacceptedPublicRiskWaivers",
          color: numUnacceptedPublicRiskWaivers > 0 ? "orange" : "grey",
          value: numUnacceptedPublicRiskWaivers,
          label: "shared",
          show: numUnacceptedPublicRiskWaivers > 0,
          popup: (
            <PublicRiskWaiverWarningIconContent
              numPendingPublicRiskWaivers={numUnacceptedPublicRiskWaivers}
              verifiedVendorPublicScore={props.verifiedVendorPublicScore}
              watching={props.watching}
              vendorName={`this ${props.vendorWords.singular}`}
              onGoToRiskWaivers={() =>
                goToVendorRiskWaiversPage(props.vendorId)
              }
              adjustedScore={props.score}
            />
          ),
        },
      ],
    },
    {
      title: "Remediation",
      icon: "cr-icon-tools",
      url: "remediation",
      stats: [
        {
          key: "mostRecentRemediationComplete",
          color:
            (props.mostRecentRemediation?.counts.complete ?? 0) > 0
              ? "green"
              : "grey",
          value: props.mostRecentRemediation?.counts.complete ?? 0,
          label: "complete",
          show: true,
        },
        {
          key: "mostRecentRemediationIncomplete",
          color:
            (props.mostRecentRemediation?.counts.incomplete ?? 0) > 0
              ? "orange"
              : "grey",
          value: props.mostRecentRemediation?.counts.incomplete ?? 0,
          label: "in progress",
          show: true,
        },
      ],
    },
    {
      title: "Additional Evidence",
      icon: "cr-icon-documents",
      url: "evidence",
      stats: [
        {
          key: "mostRecentAdditionalEvidenceTotal",
          color:
            (props.mostRecentAdditionalEvidence?.counts.total ?? 0) > 0
              ? "green"
              : "grey",
          value: props.mostRecentAdditionalEvidence?.counts.total ?? 0,
          label: "uploaded",
          show: true,
        },
        {
          key: "mostRecentAdditionalEvidenceShared",
          color:
            props.mostRecentAdditionalEvidence?.counts.shared ?? 0
              ? "blue"
              : "grey",
          value: props.mostRecentAdditionalEvidence?.counts.shared ?? 0,
          label: "shared",
          show: true,
        },
        {
          key: "mostRecentAdditionalEvidenceRequested",
          color:
            props.mostRecentAdditionalEvidence?.counts.requested ?? 0
              ? "orange"
              : "grey",
          value: props.mostRecentAdditionalEvidence?.counts.requested ?? 0,
          label: "requested",
          show: true,
        },
      ],
      dontShow: !props.userHasAdditionalEvidencePermission,
    },
    {
      title: "Security and Privacy Pages",
      icon: "cr-icon-documents",
      url: "evidence/pages",
      stats: [
        {
          key: "mostRecentEvidencePagesTotal",
          color: (props.evidencePages?.length ?? 0) > 0 ? "green" : "grey",
          value: props.evidencePages?.length ?? 0,
          label: "available",
          show: true,
        },
      ],
      dontShow: !props.userHasAdditionalEvidencePermission,
      newFeature: NewFeatureType.EvidencePages,
    },
    {
      title: "Domains and IPs",
      icon: "cr-icon-wireframe-globe",
      url: "webscans",
      stats: [],
      isMultiLinkVariant: true,
      multiLinks: [
        {
          value: props.totalActiveDomains,
          label: "active",
          show: true,
          statColor: props.totalActiveDomains > 0 ? "green" : "grey",
        },
        {
          label: "View domains",
          show: true,
          url: `webscans`,
        },
        {
          label: "View IPs",
          show: true,
          url: `ips`,
        },
      ],
    },
    {
      title: "Security Questionnaires",
      icon: "cr-icon-q-builder-radio",
      url: "surveys",
      stats: [
        {
          key: "mostRecentQuestionnaireComplete",
          color:
            (props.mostRecentQuestionnaire?.counts.complete ?? 0) > 0
              ? "green"
              : "grey",
          value: props.mostRecentQuestionnaire?.counts.complete ?? 0,
          label: "complete",
          show: true,
        },
        {
          key: "mostRecentQuestionnaireIncomplete",
          color:
            (props.mostRecentQuestionnaire?.counts.incomplete ?? 0) > 0
              ? "orange"
              : "grey",
          value: props.mostRecentQuestionnaire?.counts.incomplete ?? 0,
          label: "in progress",
          show: true,
        },
        {
          key: "mostRecentQuestionnaireShared",
          color:
            props.mostRecentQuestionnaire?.counts.shared ?? 0 ? "blue" : "grey",
          value: props.mostRecentQuestionnaire?.counts.shared ?? 0,
          label: "shared",
          show: true,
        },
      ],
      dontShow: !props.userHasQuestionnairesPermission,
      unReadMessages: props.unreadSurveyMessageCount ?? 0,
    },
  ];
  const prefix = vendorUrlPrefix(
    props.vendorId,
    props.isManagementAnalystSession,
    props.managedOrgId
  );

  const goToAllAssessments = () => {
    props.history.push(`${prefix}/assessment/`, {
      backContext: {
        backToText: `Back to ${props.vendorWords.singularTitleCase} Summary`,
        goBack: true,
        sp: document.documentElement.scrollTop || document.body.scrollTop,
      },
    });
  };

  const goToAssessment = (streamId: number, versionId?: number) => {
    props.history.push(`${prefix}/assessment/${streamId}`, {
      backContext: {
        backToText: `Back to ${props.vendorWords.singularTitleCase} Summary`,
        goBack: true,
        sp: document.documentElement.scrollTop || document.body.scrollTop,
      },
      selectedVersionById: versionId,
    });
  };

  const [openNewAssessmentModal, newAssessmentModal] = useModalV2(
    NewAssessmentStreamModal
  );

  const onNewAssessment = () =>
    openNewAssessmentModal({
      goToNewAssessment: true,
      urlPrefix: prefix,
      vendorName: props.vendorName,
      vendorId: props.vendorId,
    });

  const [changeReassessmentDate] =
    VendorAssessmentAPI.useUpdateReassessmentDateMutation();

  const onChangeReassessmentDate = async (
    streamId: number,
    newReassessmentDate?: string
  ) => {
    try {
      await changeReassessmentDate({
        vendorId: props.vendorId,
        reassessmentDate: newReassessmentDate
          ? moment(newReassessmentDate).format()
          : undefined,
        streamId,
      })
        .unwrap()
        .then(() => {
          props.dispatch(
            addDefaultSuccessAlert(
              newReassessmentDate
                ? `The reassessment date has been set to “${newReassessmentDate}”`
                : "The reassessment date has been removed"
            )
          );
        });
    } catch {
      props.dispatch(
        addDefaultUnknownErrorAlert("Error updating reassessment date")
      );
    }
  };

  const [openVersionSideBar, versionSideBar] = useModalV2(
    VendorAssessmentsV3VersionsSideBar
  );
  const onOpenVersionsSideBar = (streamId: number) => {
    const versionList = assessmentLists?.assessments[streamId];
    if (!versionList) {
      return;
    }

    openVersionSideBar({
      assessmentList: versionList,
      onOpenVersion: (versionId) => goToAssessment(streamId, versionId),
    });
  };

  const badges = [] as ReactElement[];
  riskManagementBagdeMeta.map((meta) => {
    if (!meta.dontShow) {
      badges.push(
        <RiskManagementBadge
          key={meta.title}
          history={props.history}
          meta={meta}
          vendorId={props.vendorId}
          vendorWords={props.vendorWords}
          isManagementAnalystSession={props.isManagementAnalystSession}
          managedOrgId={props.managedOrgId}
        />
      );
    }
  });
  return (
    <div className={"risk-management-tile"}>
      {props.watching && (
        <ReportCard newStyles>
          <div className={"container"}>
            <div className={"header"}>
              <div className={"left"}>{"Risk Management"}</div>
              <div className={"right"}>
                {(vendorAssessments?.streams.length ?? 0) > 1 ? (
                  <Button link onClick={goToAllAssessments}>
                    View all risk assessments
                    <i className={"cr-icon-chevron"} />
                  </Button>
                ) : (
                  <SidePopupV2
                    text={"View support article"}
                    position={"left"}
                    noWrap
                  >
                    <div
                      className={"cr-icon-info"}
                      onClick={(e) => {
                        e.stopPropagation();
                        window.open(
                          "https://help.upguard.com/en/articles/6504360-using-the-risk-assessment-framework-in-upguard",
                          "_blank"
                        );
                      }}
                    />
                  </SidePopupV2>
                )}
              </div>
            </div>
            <div className={"body"}>
              {(vendorAssessments?.upGuardPublishedAssessments ?? []).map(
                (a) => (
                  <UpGuardPublishedAssessmentBlock
                    key={a.upGuardPublishedAssessmentUUID}
                    assessment={a}
                  />
                )
              )}
              <VendorAssessmentsSummaryBlock
                assessments={vendorAssessments?.streams ?? []}
                assessmentsLoading={vendorAssessmentsLoading}
                userHasVendorAssessmentPermission={
                  props.userHasVendorAssessmentPermission
                }
                userHasVendorAssessmentAuthorPermission={
                  props.userHasVendorAssessmentAuthorPermission
                }
                vendorWords={props.vendorWords}
                goToAssessment={goToAssessment}
                onNewAssessment={onNewAssessment}
                onChangeAssessmentDate={onChangeReassessmentDate}
                onOpenVersionHistory={onOpenVersionsSideBar}
              />
              <div className={"badges"}>{badges}</div>
            </div>
          </div>
        </ReportCard>
      )}
      {!props.watching && (
        <UnwatchedVendorCard
          vendorId={props.vendorId}
          vendorName={""}
          vendorPrimaryHostname={""}
          title={"Risk Management"}
          text={`This ${props.vendorWords.singular} must be monitored in order to access risk management features.`}
          onWatched={() => {
            props.dispatch(
              fetchVendorSummaryAndCloudscans(props.vendorId, true)
            );
            props.dispatch(fetchCustomerLookupsData(true));
          }}
        />
      )}
      {newAssessmentModal}
      {versionSideBar}
    </div>
  );
};

export default RiskManagementTile;
