import { FC } from "react";
import { AdjustedSeverityIcon } from "../../_common/components/SeverityIcon";
import { SeverityAsString, SeverityInt } from "../../_common/types/severity";
import "../style/components/RiskMini.scss";

// this private type makes this component work better with storybook
interface RiskMini {
  severity: SeverityInt;
  baseSeverity?: SeverityInt;
  title: string;
  categoryTitle: string;
}

interface RiskMiniProps {
  risk: RiskMini;
  assetText: string;
}

const RiskMini: FC<RiskMiniProps> = ({ risk, assetText }) => (
  <div className={"risk-mini"}>
    <div className={"risk-header"}>
      <AdjustedSeverityIcon
        severity={SeverityAsString(risk.severity)}
        baseSeverity={
          risk.baseSeverity ? SeverityAsString(risk.baseSeverity) : undefined
        }
      />
      <div className={"risk-text-container"}>
        <div className={"risk-text"}>{risk.title}</div>
        <div className={"risk-cat-title"}>{risk.categoryTitle}</div>
      </div>
      <div className={"asset-count"}>{assetText}</div>
    </div>
  </div>
);

export default RiskMini;
