import XTable, {
  IXTableColumnHeader,
  IXTableRow,
  XTableCell,
} from "../../../_common/components/core/XTable";
import CompanyLogo from "../../../_common/components/CompanyLogo";
import { formatDateAsLocal } from "../../../_common/helpers";
import SlidePanel from "../../../_common/components/SlidePanel";
import "../../style/components/vendor_assessment/VendorAssessmentV2SharedAssessmentSideBar.scss";
import Button from "../../../_common/components/core/Button";
import ScrollableDiv from "../ScrollableDiv";
import { SharedVendorAssessmentListItem } from "../../reducers/vendorAssessmentAPI";

interface IVendorAssessmentV2SharedAssessmentSideBarProps {
  relatedOrgAssessments: SharedVendorAssessmentListItem[];
  onSelect: (id: number, orgID: number) => void;
  active: boolean;
  onClose: () => void;
}

const VendorAssessmentV2SharedAssessmentSideBar = (
  props: IVendorAssessmentV2SharedAssessmentSideBarProps
) => {
  const columns: IXTableColumnHeader[] = [
    {
      id: "author",
      text: "Author",
    },
    {
      id: "completion",
      text: "Completion date",
    },
    {
      id: "access",
      text: "",
    },
  ];

  const rows: IXTableRow[] = props.relatedOrgAssessments
    .filter((sa) => !sa.sharedAccessRejected)
    .map((sa) => ({
      id: sa.organisationID,
      // if the request has already been requested no click action
      onClick:
        !sa.sharedAccessRequested || sa.sharedAccessGranted
          ? () => props.onSelect(sa.id, sa.organisationID)
          : undefined,
      cells: [
        <XTableCell className={"author-cell"} key={"author-cell"}>
          <div className={"author-cell-div"}>
            {(!sa.sharedAccessRequested || sa.sharedAccessGranted) && (
              <i className={"cr-icon-chevron rotate-180"} />
            )}
            <CompanyLogo
              name={sa.sharedOrgName}
              domain={sa.sharedOrgHostname}
            />
          </div>
        </XTableCell>,
        <XTableCell className={"published-at"} key={"published-at"}>
          {formatDateAsLocal(sa.publishedAt)}
        </XTableCell>,
        <XTableCell key={"access"} className={"access-cell"}>
          {!sa.sharedAccessGranted && !sa.sharedAccessRequested && (
            <Button
              tertiary
              onClick={() => props.onSelect(sa.id, sa.organisationID)}
            >
              <i className={"cr-icon-lock"} />
              Request access
            </Button>
          )}
          {sa.sharedAccessRequested &&
            !sa.sharedAccessGranted &&
            "Access pending"}
        </XTableCell>,
      ],
    }));

  return (
    <SlidePanel
      className={"shared-assessment-side-panel"}
      onClose={props.onClose}
      active={props.active}
      newStyles
      title={"Shared assessments"}
      dimContent
    >
      <ScrollableDiv newStyles>
        <XTable
          className={"shared-assessment-table"}
          rows={rows}
          columnHeaders={columns}
        />
      </ScrollableDiv>
    </SlidePanel>
  );
};

export default VendorAssessmentV2SharedAssessmentSideBar;
