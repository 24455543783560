import SlidePanelSection from "../../../vendorrisk/components/filter/SlidePanelSection";
import {
  OrgAccessUserBase,
  UserUserBaseEnabled,
} from "../../../_common/permissions";
import ColorCheckbox from "../../../vendorrisk/components/ColorCheckbox";
import { ScopeRiskLevel } from "../../api/types";
import withRequireEntitlementAndPermission from "../../../_common/hocs/WithRequireEntitlementAndPermission";
import {
  BasicRiskLevelString,
  ElevatedRiskLevelString,
  isElevatedRiskLevel,
} from "../../helpers/scopes";

interface UserRiskExposureLevelFilterProps {
  minRiskLevel: ScopeRiskLevel;
  maxRiskLevel: ScopeRiskLevel;
  onChange: (
    minRiskLevel: ScopeRiskLevel,
    maxRiskLevel: ScopeRiskLevel
  ) => void;
}

const UserRiskExposureLevelFilter = ({
  minRiskLevel,
  maxRiskLevel,
  onChange,
}: UserRiskExposureLevelFilterProps) => {
  const basicChecked = !isElevatedRiskLevel(minRiskLevel);
  const elevatedChecked = isElevatedRiskLevel(maxRiskLevel);

  return (
    <SlidePanelSection title={"Exposure level"}>
      <ColorCheckbox
        checked={basicChecked}
        label={<>{BasicRiskLevelString}</>}
        onClick={() => {
          const newMinRiskLevel = basicChecked
            ? ScopeRiskLevel.MediumScopeRiskLevel
            : ScopeRiskLevel.LowScopeRiskLevel;
          onChange(newMinRiskLevel, maxRiskLevel);
        }}
      />
      <ColorCheckbox
        checked={elevatedChecked}
        label={<>{ElevatedRiskLevelString}</>}
        onClick={() => {
          const newMaxRiskLevel = elevatedChecked
            ? ScopeRiskLevel.MediumLowScopeRiskLevel
            : ScopeRiskLevel.HighScopeRiskLevel;
          onChange(minRiskLevel, newMaxRiskLevel);
        }}
      />
    </SlidePanelSection>
  );
};

export default withRequireEntitlementAndPermission(
  UserRiskExposureLevelFilter,
  OrgAccessUserBase,
  UserUserBaseEnabled
);
