/* eslint-disable global-require */

if (typeof Promise === "undefined") {
  // Rejection tracking prevents a common issue where React gets into an
  // inconsistent state due to an error, but it gets swallowed by a Promise,
  // and the user has no idea what causes React's erratic future behavior.
  require("promise/lib/rejection-tracking").enable();
  window.Promise = require("promise/lib/es6-extensions.js");
}

require("core-js/stable");
require("regenerator-runtime/runtime");
require("whatwg-fetch");
require("element-closest/browser");

// Object.assign() is commonly used with React.
// It will use the native implementation if it's present and isn't buggy.
Object.assign = require("object-assign");

// performance.now() timing polyfill courtesy of https://gist.github.com/paulirish/5438650
if ("performance" in window == false) {
  window.performance = {};
}

Date.now =
  Date.now ||
  function () {
    // thanks IE8
    return new Date().getTime();
  };

if ("now" in window.performance == false) {
  let nowOffset = Date.now();

  if (performance.timing && performance.timing.navigationStart) {
    nowOffset = performance.timing.navigationStart;
  }

  window.performance.now = function now() {
    return Date.now() - nowOffset;
  };
}

// Polyfill for EventSource / Server-Sent Events
const nativeEventSource = require("event-source-polyfill").NativeEventSource;
global.EventSource =
  nativeEventSource || require("event-source-polyfill").EventSourcePolyfill;

// Polyfill for scrollTo (EdgeHTML)
const smoothscroll = require("smoothscroll-polyfill");
smoothscroll.polyfill();
