import { LabelColor } from "../../_common/types/label";
import { getColorForKey } from "../../vendorrisk/components/PillLabel";

// Default colors for Pill Labels for UserBase
// Removed all grey colors from the list
export const UserBasePillColors: LabelColor[] = [
  LabelColor.Blue,
  LabelColor.LightBlue,
  LabelColor.Green,
  LabelColor.Yellow,
  LabelColor.Gimblet,
  LabelColor.PersianGreen,
  LabelColor.Violet,
  LabelColor.Fuchsia,
  LabelColor.TrendyPink,
  LabelColor.PictonBlue,
  LabelColor.MountainMeadow,
  LabelColor.Bahia,
];

// Constrain the category colors to a set of specific colors for UserBase
export function getUserBaseColorForKey(str?: string): LabelColor {
  if (!str) {
    return LabelColor.Grey;
  }

  const colorToReturn = getColorForKey(str, UserBasePillColors);
  return colorToReturn;
}
