import classnames from "classnames";
import "../style/components/ExternalLink.scss";
import { trackEvent } from "../tracking";

export interface IExternalLinkProps {
  url: string;
  text?: string;
  trackInfo?: {
    eventName: string;
    properties?: Record<string, any>;
  };
}

export const ExternalLink = ({ url, text, trackInfo }: IExternalLinkProps) => {
  if (!url) return <></>;

  return (
    <a
      rel="noopener noreferrer"
      target="_blank"
      href={url}
      className="external-link"
      onClick={
        trackInfo
          ? () => {
              trackEvent(trackInfo?.eventName, trackInfo?.properties);
            }
          : undefined
      }
    >
      {text ?? url}
      <div className="cr-icon-external-link" />
    </a>
  );
};

/* FlowingExternalLink is a variant of ExternalLink, used when the link is part of a flowing text */
export const FlowingExternalLink = ({
  url,
  text,
  trackInfo,
}: IExternalLinkProps) => {
  if (!url) return <></>;

  return (
    <a
      rel="noopener noreferrer"
      target="_blank"
      href={url}
      className="flowing-external-link"
      onClick={
        trackInfo
          ? () => {
              trackEvent(trackInfo?.eventName, trackInfo?.properties);
            }
          : undefined
      }
    >
      {text ?? url} <i className="cr-icon-external-link" />
    </a>
  );
};

type FreeFormExternalLinkProps = Omit<IExternalLinkProps, "text"> & {
  className?: string;
  children: React.ReactNode;
};

// FreeFormExternalLink is a variant of ExternalLink, used when you want to provide your own children.
// It's useful for getting consistent link behaviour and tracking in cases where ExternalLink and FreeflowingExternalLink's styling get in the way.
export const FreeFormExternalLink = ({
  url,
  trackInfo,
  children,
  className,
}: FreeFormExternalLinkProps) => {
  if (!url) return <></>;

  return (
    <a
      rel="noopener noreferrer"
      target="_blank"
      href={url}
      className={classnames("free-form-external-link", className)}
      onClick={
        trackInfo
          ? () => {
              trackEvent(trackInfo?.eventName, trackInfo?.properties);
            }
          : undefined
      }
    >
      {children}
    </a>
  );
};
