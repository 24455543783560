import ProgressBar from "../../_common/components/ProgressBar";
import { SectionNodeAnswerState } from "../surveyViewer.helpers";
import { SidePopupV2 } from "../../_common/components/DismissablePopup";
import Dot from "../../_common/components/Dot";
import "../style/QuestionnaireProgressBar.scss";
import { FC } from "react";

export const ProgressSummary: FC<{
  answerCount: number;
  needsReview?: number;
  questionCount: number;
}> = ({ answerCount, needsReview, questionCount }) => (
  <div className={"survey-progress-summary"}>
    <span className={"title"}>progress summary</span>
    <div className={"summary-line"}>
      <Dot color={"blue"} />
      Complete ({answerCount})
    </div>
    {!!needsReview && needsReview > 0 && (
      <div className={"summary-line"}>
        <Dot color={"orange"} />
        Need review ({needsReview})
      </div>
    )}
    <div className={"summary-line"}>
      <Dot color={"grey"} />
      Unanswered ({questionCount - answerCount - (needsReview ?? 0)})
    </div>
  </div>
);

interface QuestionnaireProgressBarProps {
  rootNodeAnswerState: SectionNodeAnswerState;
  verb?: string;
  dontReportSuggestedInTotal?: boolean;
}

const QuestionnaireProgressBar = ({
  rootNodeAnswerState,
  verb,
  dontReportSuggestedInTotal,
}: QuestionnaireProgressBarProps) => {
  // Include the answered optional questions in the total and answered counts
  const answerCount =
    rootNodeAnswerState.answeredCount +
    rootNodeAnswerState.optionalAnsweredCount;
  const questionCount =
    rootNodeAnswerState.questionCount + rootNodeAnswerState.optionalCount;
  const suggestionCount =
    rootNodeAnswerState.suggested + rootNodeAnswerState.optionalSuggested;

  const completeFraction =
    answerCount && questionCount ? answerCount / questionCount : 0;

  const reviewFraction =
    suggestionCount && questionCount ? suggestionCount / questionCount : 0;

  return (
    <ProgressBar
      className={"survey-progress-bar"}
      progress={completeFraction}
      secondaryProgress={reviewFraction > 0 ? reviewFraction : undefined}
      leftText={`${Math.floor(
        (completeFraction + (dontReportSuggestedInTotal ? 0 : reviewFraction)) *
          100
      )}% complete`}
      rightText={
        <div className={"progress-right"}>
          {completeFraction + reviewFraction < 1 && (
            <SidePopupV2
              position={"top"}
              className={"summary"}
              popupStyle={"light"}
              width={172}
              text={
                <ProgressSummary
                  needsReview={suggestionCount}
                  answerCount={answerCount}
                  questionCount={questionCount}
                />
              }
            >
              <i className={"cr-icon-info"} />
            </SidePopupV2>
          )}
          <div className={"count"}>{`${
            answerCount + (dontReportSuggestedInTotal ? 0 : suggestionCount)
          } of ${questionCount ?? 0} ${verb ? verb : "answered"}`}</div>
        </div>
      }
    />
  );
};

export default QuestionnaireProgressBar;
