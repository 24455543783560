import ReportCard from "../ReportCard";
import "../../style/components/SurveyDetailsScoreProjectionCard.scss";
import ScoreProjection from "../ScoreProjection";
import LoadingBanner from "../core/LoadingBanner";

export interface IRemediationDetailsScoreProjectionCardProps {
  isVendorPortal: boolean;
  isSelfRemediation: boolean;
  vendorName?: string;
  initialScore: number;
  currentScore: number;
  projectedScore: number;
  loading: boolean;
}

const RemediationDetailsScoreProjectionCard = (
  props: IRemediationDetailsScoreProjectionCardProps
) => {
  // Work out header text. Vendor name may be undefined if still loading vendor overlay.
  let headerText = "Your Security Posture";
  if (props.isVendorPortal || !props.isSelfRemediation) {
    if (!props.vendorName) {
      headerText = "Security Posture";
    } else {
      headerText = `${props.vendorName}'s Security Posture`;
    }
  }

  return (
    <ReportCard newStyles className="remediation-details-projection-card">
      <div className="header">{headerText}</div>
      <div className={"details-container"}>
        {!props.loading && (
          <ScoreProjection
            stacked={false}
            isSelfRemediation={props.isSelfRemediation}
            isQuestionnaire={false}
            isVendorPortal={props.isVendorPortal}
            vendorName={props.vendorName}
            initialScore={props.initialScore}
            currentScore={props.currentScore}
            projectedScore={props.projectedScore}
          />
        )}
        {props.loading && <LoadingBanner />}
      </div>
    </ReportCard>
  );
};

export default RemediationDetailsScoreProjectionCard;
