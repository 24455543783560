import { useHasUserOrPortfolioPermissions } from "../permissions";
import { ComponentType, FC } from "react";

// withRequirePermission stops a component being included in the React-DOM if the current user
// does not have the specified permissions.
const withRequirePermission = <BaseProps extends object>(
  BaseComponent: ComponentType<BaseProps>,
  requiredPermission: string | string[]
): ComponentType<BaseProps> => {
  const ComponentHidden: FC<BaseProps> = (props: BaseProps) => {
    const hasPermission = useHasUserOrPortfolioPermissions(requiredPermission);

    if (!hasPermission) {
      return <></>;
    } else {
      return <BaseComponent {...props} />;
    }
  };

  return ComponentHidden;
};

export default withRequirePermission;
