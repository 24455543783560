import { FetchCyberRiskUrl } from "../../_common/api";
import { LogError } from "../../_common/helpers";
import { DefaultAction, DefaultThunkDispatch } from "../../_common/types/redux";
import { get as _get } from "lodash";
import { VendorMini } from "../../_common/types/vendor";
import {
  DetectedProduct,
  builtWithCategories,
  isProductBlocked,
} from "../../_common/types/detectedProducts";

export const SET_DETECTED_PRODUCTS = "SET_DETECTED_PRODUCTS";

export interface detectedProductsState {
  loading?: boolean;
  error?: any;
  technologies?: DetectedProduct[];
  vendors?: VendorMini[];
}

export const setDetectedProducts = (
  detectedProducts: detectedProductsState
) => {
  const technologies: DetectedProduct[] = [];
  detectedProducts.technologies?.forEach((tech) => {
    if (isProductBlocked(tech.tech_name)) {
      return;
    }
    // some tags need to be renamed or omitted (empty values)
    tech.tag = builtWithCategories.get(tech.tag) ?? tech.tag;
    if (!tech.tag) {
      return;
    }
    tech.vendor =
      detectedProducts.vendors?.find((v) => {
        return v.vendor_id === tech.vendor_id;
      }) ?? ({} as VendorMini);
    technologies.push(tech);
  });

  return {
    type: SET_DETECTED_PRODUCTS,
    data: {
      loading: detectedProducts.loading,
      error: detectedProducts.error,
      technologies: technologies,
      vendors: detectedProducts.vendors,
    },
  };
};

interface fetchDetectedProductsResp {
  status: string;
  technologies: DetectedProduct[];
  vendors: VendorMini[];
}

export const fetchDetectedProducts = (force: boolean): DefaultAction => {
  return async (dispatch: DefaultThunkDispatch, getState) => {
    const { cyberRisk } = getState();

    if (!force) {
      const existing = _get(cyberRisk, "detectedProducts");
      if (existing && !existing.error) {
        return;
      }
    }
    const filters = cyberRisk.customerData.filters;

    dispatch(setDetectedProducts({ loading: true }));
    let json: fetchDetectedProductsResp;
    try {
      json = await FetchCyberRiskUrl(
        "vendor/products/v1/",
        { website_portfolio_ids: filters.domainPortfolioIds },
        null,
        dispatch,
        getState
      );
    } catch (e) {
      LogError(`Error fetching products for vendor`, e);
      dispatch(
        setDetectedProducts({
          loading: false,
          error: {
            errorText: `Error fetching detected products.`,
            actionText: "Try again",
            actionOnClick: () => dispatch(fetchDetectedProducts(true)),
          },
        })
      );
      return;
    }

    if (!json) {
      dispatch(
        setDetectedProducts({
          loading: false,
          error: {
            errorText: `Error fetching detected products.`,
            actionText: "Try again",
            actionOnClick: () => dispatch(fetchDetectedProducts(true)),
          },
        })
      );
      return;
    }
    dispatch(
      setDetectedProducts({
        loading: false,
        error: null,
        technologies: json.technologies,
        vendors: json.vendors,
      })
    );
  };
};
