import { stringify } from "querystring";
import { isArray, isPlainObject, get as _get } from "lodash";

import reload from "./reload";
import {
  setCyberRiskAuth,
  fetchConfig,
  fetchUserData,
  grabTPVMSession,
  fetchTPVMAnalystToken,
} from "./reducers/commonActions";
import { isValidRegion } from "./helpers/region.helpers";
import { disableSentry } from "./sentry";

export class ResponseError extends Error {
  constructor(message, response, json) {
    super(message);
    this.response = response;
    this.json = json;
  }
}

// wrapper allows us to turn off console logging when appropriate
const logToConsole = (message) => {
  if (window.ENV !== "production") {
    console.log(message);
  }
};

/**
 * Returns a URL-encoded string ensuring arrays are joined with commas
 * @param opts - object containing query string key/values
 * @returns {string} - generated URL
 */
export const stringifyQuery = (opts) => {
  const newOpts = { ...opts };
  Object.keys(newOpts).forEach((opt) => {
    if (isArray(newOpts[opt])) {
      newOpts[opt] = newOpts[opt].join(",");
    }
  });

  return stringify(newOpts);
};

/**
 * Returns full URL to a hannah endpoint depending on dev or prod environment
 * @param endpoint - endpoint name (following /api/)
 * @param options - query string key/values
 * @param protocol - http/https (used for explicitly setting in tests)
 * @param hostname - hostname (used for explicitly setting in tests)
 * @returns {string} - generated URL
 */
export const GenerateHannahUrl = (
  endpoint = "",
  options,
  protocol = window.location.protocol,
  hostname = window.location.hostname
) => {
  const query = stringifyQuery(options);
  if (process.env.NODE_ENV === "development") {
    // Set HANNAH_HOST env var on local dev (including port)
    return `${protocol}//${
      process.env.HANNAH_HOST || hostname
    }/api/${endpoint}?${query}`;
  }

  return `${protocol}//${hostname}/hannah/api/${endpoint}?${query}`;
};

/**
 * Fetches a hannah endpoint. Handles redirect to sign in page on 401 unauthorised response.
 * Successful fetch will return a Promise resolving to the JSON parsed body of the response.
 * @param endpoint - endpoint name (following /api/)
 * @param options - query string key/values
 * @param fetchOptions - optional options for fetch call (such as HTTP method, body)
 * @returns {Promise.<object>} - response body as JSON parsed object
 */
export const FetchHannahUrl = async (endpoint, options, fetchOptions = {}) => {
  const url = GenerateHannahUrl(endpoint, options);

  const response = await fetch(url, {
    method: "GET",
    credentials: "include",
    ...fetchOptions,
  });

  if (response.status === 401) {
    // User's session has likely timed out. Refresh the page to reauthorize with secure.
    // We refresh the page so that secure can handle the session redirect_to properly.
    reload();

    // And just "sleep" here for 10 seconds so whatever requested this fetch doesn't immediately show an error
    // while we wait for the page to reload
    const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
    await sleep(10000);
  }

  if (response.status !== 200) {
    throw new Error(`Failed fetch with status code ${response.status}`);
  }

  return response.json();
};

/**
 * Fetches the Vendor Risk auth token from Hannah API.
 * @param dispatch - if dispatch func provided, vendor risk auth token will be dispatched to the app state.
 * @returns {Promise.<object>} - Auth object or false if unauthed
 */
export const GetCyberRiskAuthToken = async (dispatch) => {
  if (!dispatch)
    throw new Error(
      "dispatch is now required to be passed into GetCyberRiskAuthToken"
    );

  // Retrieve the config object that includes the auth token. this call gets it
  // from hannah
  const config = await dispatch(fetchConfig());

  if (config && config.cyberRiskAuth && config.cyberRiskAuth.status === "OK") {
    return config.cyberRiskAuth;
  }

  return false;
};

/**
 * Returns full URL to a Vendor Risk endpoint depending on dev or prod environment
 * @param endpoint - endpoint name (following /api/)
 * @param options - query string key/values
 * @param auth - vendor risk auth object containing token, apikey, and vr endpoint
 * @param region - the iso 3166-1 alpha-2 code for the target region. If a valid region is provided, it will be prepended to the hostname with a period
 * @param protocol - HTTP/HTTPS. Must end with a colon. If no protocol is provided, the current window protocol is used.
 * @param hostname - base domain used for the API request. If no hostname is provided, the current window hostname is used.
 * @returns {string} - generated URL
 */
export const GenerateCyberRiskUrl = (
  endpoint = "",
  options,
  auth,
  region,
  protocol = window.location.protocol, // location.protocol includes the protocol and trailing colon (e.g. "https:")
  hostname = window.location.hostname
) => {
  const query = stringifyQuery({
    ...options,
    apikey: auth.apiKey,
    token: auth.token,
  });

  // If the region is valid, it will be prepended to the hostname with a period to form a region subdomain (e.g. "us.hostname").
  // If not, the hostname will be used without a region subdomain.
  region = isValidRegion(region) ? `${region}.` : "";

  // Trim leading slashes from the path to prevent any double-ups.
  endpoint = endpoint.replace(/^\//, "");

  // For development environments, use the VR_HOST environment variable to include the port number
  if (process.env.NODE_ENV === "development") {
    return `${protocol}//${region}${
      process.env.VR_HOST || hostname
    }/api/${endpoint}?${query}`;
  }

  return `${protocol}//${region}${hostname}/api/${endpoint}?${query}`;
};

const formatFetchOptions = (fetchOptions) => {
  // If the fetch options include a body that is a plain object, then stringify it and set
  // the content type to what goframework expents in parsing it as form data.
  if (fetchOptions && isPlainObject(fetchOptions.body)) {
    return {
      ...fetchOptions,
      headers: {
        ...fetchOptions.headers,
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
      body: stringifyQuery(fetchOptions.body),
    };
  }

  return fetchOptions;
};

/**
 * Fetch a Cyber Risk API endpoint. Checks if auth is valid, and if not, first gets a new auth token before making the
 * request. If the request returns a 401 Expired error, a new auth token is also fetched. If any other 401 error
 * is returned, a general error state is dispatched that will prevent future fetches.
 * @param endpoint - endpoint name (following /api/)
 * @param options - query string key/values
 * @param fetchOptions - optional options for fetch call (such as HTTP method, body)
 * @param dispatch - function for setting the auth tokens and error state on app state
 * @param getState - function for getting the full app state
 * @param {boolean} [expiredTokenDetected] - try and retrieve a new auth token before making the request
 * @param {boolean} [ignoreAuthUpdate] - don't refresh user data, even if Authorization-Updated=yes
 * @param {string} [regionOverride] - override the region for the request, if not provided the org's designated data region will be used
 * @returns {Promise.<T>} - response body as JSON parsed object
 * @template T
 */
export const FetchCyberRiskUrl = async (
  endpoint,
  options,
  fetchOptions,
  dispatch,
  getState,
  expiredTokenDetected,
  ignoreAuthUpdate,
  regionOverride = ""
) => {
  let auth = getState().common.cyberRiskAuth;

  //
  // are we currently in a tpvm session? grab a special token relative to that session
  // Note: there is a listener in the Navigation.jsx view that listens for changes in the path and calls
  // function setTPVMSessionDetails() to update the tpvm session details stored in redux. This
  // is the session data that is considered here.
  //
  const tpvmSession = grabTPVMSession(getState, endpoint);
  const tpvmSessionLive = tpvmSession && tpvmSession.tpvm;

  // if we are in a tpvm session, then grab the tpvm auth (token) from redux. If there's no viable token
  // then request one first thing.
  let tpvmAuth = tpvmSessionLive
    ? _get(getState().common.tpvmAuth, `[${tpvmSession.tpvm_o}]`, null)
    : null;

  if (tpvmSessionLive) {
    if (!tpvmAuth) {
      // note: this call is recursive but operates in a mode that ignores the tpvmSession at all costs
      tpvmAuth = await dispatch(fetchTPVMAnalystToken(tpvmSession));
      if (tpvmAuth && tpvmAuth.error === true) {
        console.log(
          `### Auth fetch failed for tpvm analyst session - returning false`
        );
        return false;
      }
    }
    auth = tpvmAuth;
  }

  if (auth && auth.error === true) {
    // Previously had an error retrieving auth. Don't keep trying to get it again.
    return false;
  }

  if (!auth || !auth.apiKey || !auth.token || expiredTokenDetected) {
    // We're either unauthed or have expired auth.
    // Wait for a new auth token before performing the fetch.

    if (getState().common.authMode === "AUTH0") {
      // see if we are running in CR stand-alone mode without an appliance, and if so, trigger a re-login
      // to refresh the auth token.
      dispatch(
        setCyberRiskAuth({ forceLogout: true }, false, false, tpvmSession)
      );
      logToConsole("handleExpiredTokenDetected() - clearing cyber risk auth");
      return false;
    }

    logToConsole("Auth state expired, fetching new state...");
    if (tpvmSessionLive) {
      auth = await dispatch(fetchTPVMAnalystToken(tpvmSession));
    } else {
      auth = await GetCyberRiskAuthToken(dispatch);
    }
  }

  if (auth === false) {
    // Issue retrieving auth. Don't keep trying to get it again.
    // Most probably the session has timed out or there is an issue communicating with hannah.
    return false;
  }

  const url = GenerateCyberRiskUrl(
    endpoint,
    options,
    tpvmAuth ? tpvmAuth : auth,
    regionOverride || getState().common?.userData?.currentOrgDataRegion || ""
  );
  const response = await fetch(url, formatFetchOptions(fetchOptions || {}));

  if (
    response.status !== 200 &&
    response.status !== 201 &&
    response.status !== 204
  ) {
    let json;
    try {
      json = await response.json();
    } catch (e) {
      // Fail silently if an error response is not valid json
    }

    if (json && json.error) {
      if (
        json.error.indexOf("[EXPIRE]") > -1 &&
        expiredTokenDetected !== true
      ) {
        // This is different from [LICENSE EXPIRED] as this means the token expired,
        // not the API key and actual license for this appliance
        logToConsole(
          "***** FetchCyberRiskUrl() - Expired token detected. Calling recursively to refresh it."
        );
        return FetchCyberRiskUrl(
          endpoint,
          options,
          fetchOptions,
          dispatch,
          getState,
          true
        );
      } else if (json.error.indexOf("[LICENSE NOT ENABLED]") > -1) {
        const errorText =
          "Cyber Risk license is not enabled. Please contact UpGuard support.";
        if (dispatch)
          dispatch(setCyberRiskAuth(null, { errorText }, false, tpvmSession));
        throw new Error(errorText);
      } else if (json.error.indexOf("[LICENSE EXPIRED]") > -1) {
        const errorText =
          "Cyber Risk license has expired. Please contact UpGuard support.";
        if (dispatch)
          dispatch(setCyberRiskAuth(null, { errorText }, false, tpvmSession));
        throw new Error(errorText);
      } else if (json.error.indexOf("[SESSION EXPIRE]") > -1) {
        if (dispatch) {
          // log the user out
          dispatch(
            setCyberRiskAuth({ forceLogout: true }, false, false, tpvmSession)
          );
          disableSentry(); // as we are logging out the user disable sentry to avoid spammy errors
        }
        throw new ResponseError(
          json ? json.error : "Session expired",
          response,
          json
        );
      }
    }

    if (response.status === 401) {
      // Other 401s not to do with token expiry should take the auth into error state
      const errorText =
        "Error authorizing with Cyber Risk. Try refreshing the page or contact UpGuard support.";
      if (dispatch)
        dispatch(setCyberRiskAuth(null, { errorText }, false, tpvmSession));
      throw new Error(errorText);
    }

    console.info(response);
    throw new ResponseError(
      json ? json.error : "Unknown error",
      response,
      json
    );
  }

  // Check if the orgID used in the request is still valid. If it isn't, we should kick off a new request.
  try {
    const requestOrgID = response.headers.get("Authorization-Orgid");
    if (
      requestOrgID &&
      !tpvmSessionLive &&
      parseInt(requestOrgID, 10) !== getState().common.userData.currentOrgID
    ) {
      return FetchCyberRiskUrl(
        endpoint,
        options,
        fetchOptions,
        dispatch,
        getState,
        false
      );
    } else if (
      requestOrgID &&
      tpvmSessionLive &&
      parseInt(requestOrgID, 10) !== parseInt(tpvmSession.tpvm_o, 10)
    ) {
      logToConsole(
        `FetchCyberRiskUrl: Response from incorrect OrgID (${requestOrgID} not ${tpvmSession.tpvm_o}) for tpmv request. Requesting again...`
      );
      return FetchCyberRiskUrl(
        endpoint,
        options,
        fetchOptions,
        dispatch,
        getState,
        false
      );
    }
  } catch (e) {
    console.info(e);
    throw new Error("Unknown error");
  }

  const authUpdated = response.headers.get("Authorization-Updated");
  const newAuthToken = response.headers.get("Authorization");
  const newExpiry = parseInt(response.headers.get("Authorization-Expires"));
  if (newAuthToken && dispatch) {
    dispatch(
      setCyberRiskAuth(
        {
          ...auth,
          token: newAuthToken,
          expires: newExpiry,
        },
        false,
        false,
        tpvmSession
      )
    );
  }
  if (
    authUpdated &&
    authUpdated.toLowerCase().trim() === "yes" &&
    !ignoreAuthUpdate
  ) {
    // ignoreAuthUpdate here to safeguard against getting into an infinite loop
    dispatch(fetchUserData(true));
  }

  if (
    response.status === 200 &&
    !!response.headers.get("Content-Disposition")
  ) {
    // for files, let's download the data directly.
    // To reliably download data programmatically without getting
    // popup-blocked, we need to create a fake anchor element with
    // the data as a blob URL, then click it. After clicking we
    // revoke the blob object URL.
    const blob = await response.blob();
    const objectURL = await URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = objectURL;
    a.download =
      getFilenameFromContentDispositionHeader(
        response.headers.get("Content-Disposition")
      ) || ""; // Set the download attribute to the one set in the Content-Disposition header, if any.

    const clickHandler = () => {
      setTimeout(() => {
        URL.revokeObjectURL(objectURL);
        a.removeEventListener("click", clickHandler);
      }, 150);
    };

    a.addEventListener("click", clickHandler);
    a.click();

    return {};
  }

  if (response.status !== 204) {
    return response.json();
  }
  return {};
};

/**
 * Returns full URL to a Vendor App endpoint depending on dev or prod environment
 * @param endpoint - endpoint name (following /api/)
 * @param options - query string key/values
 * @param protocol - http/https (used for explicitly setting in tests)
 * @param hostname - hostname (used for explicitly setting in tests)
 * @returns {string} - generated URL
 */
export const GenerateCyberRiskVendorAppUrl = (
  endpoint = "",
  options,
  cyberRiskEndpoint,
  protocol = window.location.protocol,
  hostname = window.location.hostname
) => {
  const query = stringifyQuery({ ...options });

  if (process.env.NODE_ENV === "development") {
    // Set VR_HOST env var on local dev (including port)
    return `${protocol}//${
      process.env.VR_HOST || hostname
    }/api/${endpoint}?${query}`;
  }
  return `${cyberRiskEndpoint}/api/${endpoint}?${query}`;
};

/**
 * FetchCyberRiskVendorAppUrl - Fetch a Cyber Risk API endpoint from the VendorApp portal. Inserts a Authorization header
 * containing the idToken from auth0 and makes the call.
 *
 * @param endpoint - endpoint name (following /api/)
 * @param options - query string key/values
 * @param fetchOptions - optional options for fetch call (such as HTTP method, body)
 * @param idToken - current auth object
 * @param dispatch - function for setting the auth tokens and error state on app state
 * @param stopRecursing - set to true on the first level of recursion to stop an infinite loop
 * @returns {Promise.<object>} - response body as JSON parsed object
 */
export const FetchCyberRiskVendorAppUrl = async (
  endpoint,
  options,
  fetchOptions,
  idToken,
  dispatch,
  cyberRiskEndpoint
) => {
  // check the idToken
  if (!idToken) {
    logToConsole("ERROR: FetchCyberRiskVendorAppUrl() - IDTOKEN IS MISSING");
    return false;
  }

  // construct the authorisation header
  const header = `Bearer  ${idToken}`;
  const theHeaders = new Headers({ Authorization: header });

  const url = GenerateCyberRiskVendorAppUrl(
    endpoint,
    options,
    cyberRiskEndpoint
  );

  // send the request and wait for a response
  const response = await fetch(url, { headers: theHeaders });
  if (response.status !== 200 && response.status !== 201) {
    logToConsole(
      `ERROR: FetchCyberRiskVendorAppUrl() - NON-200 RESPONSE ${response.status}`
    );
    const json = await response.json();

    if (json && json.error) {
      logToConsole(`FetchCyberRiskVendorAppUrl() - JSON ERROR ${json}`);
      //
      // not sure what to do here yet...
      //
      throw new Error("Problem with the VendorApp request");
    }

    if (response.status === 401) {
      // Other 401s not to do with token expiry should take the auth into error state
      const errorText =
        "Error authorizing with Cyber Risk VendorApp. Try refreshing the page or contact UpGuard support.";
      if (dispatch)
        dispatch(
          setCyberRiskAuth(null, { errorText }, false, getState, endpoint)
        );
      throw new Error(errorText);
    }

    throw new Error("Error fetching data");
  }
  return response.json();
};

const contentDispositionRegex = /attachment; filename="(.+)"/;

const getFilenameFromContentDispositionHeader = (header) => {
  const res = contentDispositionRegex.exec(header || "");
  if (res && res.length > 1) {
    return res[1];
  }

  return "";
};
