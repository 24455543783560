import PropTypes from "prop-types";

import "../style/components/CSTARIcon.scss";

/**
 * <CSTARIcon />
 * Outputs SVG for a CSTAR icon.
 * @param className - Apply a class to the SVG, such as for the fill color.
 * @param width - Set explicit width (default 16px)
 * @param height - Set explicit height (default 16px)
 */
const CSTARIcon = ({ className, width, height }) => (
  <svg
    className={`cstar-icon ${className}`}
    width={width}
    height={height}
    viewBox="0 0 16 16"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      transform="translate(-728, -240)"
      d="M743.658958,245.681773 C742.665123,242.393933 739.612128,240 736,240 C731.581722,240 728,243.581722 728,248 C728,252.418278 731.581722,256 736,256 C740.418278,256 744,252.418278 744,248 C744,247.614438 743.972724,247.235246 743.919997,246.864249 L738.520523,248.743769 L739.112308,252 L736.013682,250.462616 L732.915056,252 L733.506841,248.743769 L731,246.437694 L734.464369,245.962616 L736.013682,243 L737.562995,245.962616 L743.658958,245.681773 Z"
    />
  </svg>
);

CSTARIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

CSTARIcon.defaultProps = {
  className: "",
  width: "16px",
  height: "16px",
};

export default CSTARIcon;
