// Make a comma separated list of row numbers, using a range if there are consecutive rows
// eg 1,4,3-6,8.
// invalidRows records are zero-indexed, whereas we want 1-indexed rows in the string.
export const invalidRowsToString = (
  invalidRows: Record<number, true | undefined>
): string => {
  const rowNums = Object.keys(invalidRows)
    .map((n) => Number(n) + 1)
    .sort((a, b) => a - b);
  const ranges: number[][] = [];

  for (let i = 0; i < rowNums.length; i++) {
    const num = rowNums[i];
    if (i > 0 && ranges[ranges.length - 1][1] === num - 1) {
      ranges[ranges.length - 1][1] = num;
    } else {
      ranges.push([num, num]);
    }
  }

  return ranges
    .map(([start, end]) => (start === end ? `${start}` : `${start}-${end}`))
    .join(",");
};

// Matches either a single number, or a range
// eg 123, 123-456, 123 - 456
export const singleRangeRegex = /^([0-9]+)(\s*-\s*([0-9]+))?$/;

// The reverse of invalidRowsToString - take a range string and convert it to a map
// of invalid row numbers.
// Range strings are 1-indexed, but we want to record zero-indexed rows in the map.
export const stringToInvalidRows = (
  str: string
): [Record<number, true | undefined>, boolean] => {
  const invalidRows: Record<number, true | undefined> = {};

  const parts = str.split(",");
  for (let i = 0; i < parts.length; i++) {
    const part = parts[i].trim();
    if (part === "") {
      continue;
    }

    const matches = singleRangeRegex.exec(part);
    if (!matches) {
      return [{}, false];
    }

    const min = Number(matches[1]) - 1;
    let max = min;
    if (matches[3]) {
      max = Number(matches[3]) - 1;
    }

    if (max < min) {
      return [{}, false];
    }

    for (let j = min; j <= max; j++) {
      invalidRows[j] = true;
    }
  }

  return [invalidRows, true];
};
