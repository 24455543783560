import ColorCheckbox from "./ColorCheckbox";
import "../style/components/ExportReportModal.scss";

interface ISurveyDetailsExportOptions {
  excelFormatSelected: boolean;
  includeMessages: boolean;
  toggleIncludeMessages: () => void;
  includeFormattingDetails: boolean;
  toggleIncludeFormattingDetails: () => void;
}

const SurveyDetailsExportOptions = ({
  includeMessages,
  includeFormattingDetails,
  toggleIncludeMessages,
  toggleIncludeFormattingDetails,
  excelFormatSelected,
}: ISurveyDetailsExportOptions): JSX.Element => {
  return (
    <>
      <div className={"left grid-section"}>
        <h3>Optional Content</h3>
      </div>
      <div className={"right grid-section"}>
        <div className={"tuple"}>
          <ColorCheckbox
            checked={includeMessages}
            onClick={() => {
              toggleIncludeMessages();
            }}
            label={"Messages and internal notes"}
          />
          <p className={"indented"}>Include messages and internal notes.</p>
        </div>
        {excelFormatSelected && (
          <div className={"tuple"}>
            <ColorCheckbox
              checked={includeFormattingDetails}
              onClick={() => {
                toggleIncludeFormattingDetails();
              }}
              label={"Formatting details"}
            />
            <p className={"indented"}>
              Fields related to the structure and internal processing of
              questionnaires.
            </p>
          </div>
        )}
      </div>
    </>
  );
};

export default SurveyDetailsExportOptions;
