import { useEffect, useState } from "react";
import BaseAPI, { vendorDataTag } from "../../_common/rtkQueryApi";
import { VendorSummaryRisk } from "../../_common/types/vendorSummary";
import {
  CheckWithSource,
  ControlDetail,
  ControlState,
  ControlTotals,
  DocumentScanProgress,
  RiskDomain,
  SecurityProfileDocument,
  SecurityProfileDocumentID,
} from "../types/securityProfile";
import {
  BulkContactInfo,
  refreshSurveyListsIfNecessary,
} from "./survey.actions";
import { SurveyStatus } from "../../_common/types/survey";

export interface SecurityProfileResp {
  controlTotals: ControlTotals;
  controlStates: Record<string, ControlState>;
  riskCounts: Record<string, number>;
  documentChecks: Record<string, CheckWithSource[]>;
  scanningChecks: Record<string, VendorSummaryRisk>;
}

export interface GetSecurityProfileDocumentsResp {
  availableDocuments: SecurityProfileDocument[];
  selectedDocuments: SecurityProfileDocumentID[];
}

interface getVendorControlDataV1Req {
  vendorId: number;
  controlId: string;
}

interface getVendorControlDataV1Resp {
  control: ControlDetail;
}

export const securityProfileTag = "securityProfileTag" as const;
export const excludedControlsTag = "selectedControlsTag" as const;
export const securityDocumentsTag = "securityDocumentsTag" as const;
export const documentScanProgressTag =
  "securityDocumentsScanProgressTag" as const;
export const gapQuestionnaireStatusTag = "gapQuestionnaireStatusTag" as const;

export interface SendGapQuestionnaireReq {
  vendorId: number;
  emailText: string;
  emailSubject?: string;
  dueDate?: string;
  recipientReminderDate?: string;
  contacts: BulkContactInfo;
}

interface sendGapQuestionnaireResp {
  questionnaireId: number;
}

interface getGapQuestionnaireStatusResp {
  questionnaireId?: number;
  dueDate?: string;
  dateLastSent?: string;
  status: SurveyStatus;
}

const VendorSecurityProfileAPI = BaseAPI.enhanceEndpoints({
  addTagTypes: [
    excludedControlsTag,
    securityDocumentsTag,
    documentScanProgressTag,
    securityProfileTag,
    gapQuestionnaireStatusTag,
  ],
}).injectEndpoints({
  endpoints: (builder) => ({
    getVendorControlData: builder.query<
      getVendorControlDataV1Resp,
      getVendorControlDataV1Req
    >({
      query: ({ vendorId, controlId }) => ({
        url: "/vendor/control/v1",
        method: "GET",
        params: {
          vendor_id: vendorId,
          control_id: controlId,
        },
      }),
      providesTags: (result, _error, { vendorId }) =>
        result
          ? [
              {
                type: vendorDataTag,
                id: vendorId,
              },
              {
                type: securityProfileTag,
                id: vendorId,
              },
            ]
          : [],
    }),

    getSecurityProfile: builder.query<
      SecurityProfileResp,
      { vendorId: number }
    >({
      query: ({ vendorId }) => ({
        url: "/vendor/security_profile/v1/",
        method: "GET",
        params: {
          vendor_id: vendorId,
        },
      }),
      providesTags: (result, _error, { vendorId }) =>
        result
          ? [
              { type: vendorDataTag, id: vendorId },
              {
                type: securityProfileTag,
                id: vendorId,
              },
            ]
          : [],
    }),

    getExcludedControls: builder.query<
      {
        excludedControls: string[];
      },
      { vendorId: number }
    >({
      query: ({ vendorId }) => ({
        url: "/vendor/security_profile/settings/v1/",
        method: "GET",
        params: {
          vendor_id: vendorId,
        },
      }),
      providesTags: (_resp, err, { vendorId }) =>
        err ? [] : [{ type: excludedControlsTag, id: vendorId }],
    }),

    setExcludedControls: builder.mutation<
      void,
      {
        excludedControls: string[];
        vendorId: number;
      }
    >({
      query: (args) => ({
        url: "/vendor/security_profile/settings/v1/",
        method: "PUT",
        body: JSON.stringify(args),
      }),
      onQueryStarted: (arg, { dispatch, queryFulfilled }) => {
        const patch = dispatch(
          VendorSecurityProfileAPI.util.updateQueryData(
            "getExcludedControls",
            { vendorId: arg.vendorId },
            (draft) => {
              draft.excludedControls = arg.excludedControls;
            }
          )
        );

        queryFulfilled.catch(() => {
          patch.undo();
        });
      },
    }),

    getRiskControlData: builder.query<{ domains: RiskDomain[] }, void>({
      query: () => ({
        url: "/controls/v1/",
        method: "GET",
      }),
    }),

    getSecurityDocuments: builder.query<
      GetSecurityProfileDocumentsResp,
      { vendorId: number }
    >({
      query: ({ vendorId }) => ({
        url: "/vendor/security_profile/document/v1",
        method: "GET",
        params: {
          vendor_id: vendorId,
        },
      }),
      providesTags: (_resp, error, { vendorId }) =>
        error ? [] : [{ type: securityDocumentsTag, id: vendorId }],
    }),

    selectDocuments: builder.mutation<
      void,
      { vendorId: number; selectedDocs: SecurityProfileDocumentID[] }
    >({
      query: (args) => ({
        url: "vendor/security_profile/evaluate/v1/",
        method: "PUT",
        body: JSON.stringify(args),
      }),
      invalidatesTags: (result, _error, { vendorId }) =>
        result ? [{ type: documentScanProgressTag, id: vendorId }] : [],
      onQueryStarted: (args, { dispatch, queryFulfilled }) => {
        const patch = dispatch(
          VendorSecurityProfileAPI.util.updateQueryData(
            "getSecurityDocuments",
            { vendorId: args.vendorId },
            (draft) => {
              draft.selectedDocuments = args.selectedDocs;
            }
          )
        );

        queryFulfilled.catch(() => {
          patch.undo();
        });
      },
    }),

    getDocumentScanProgress: builder.query<
      { progress: Record<SecurityProfileDocumentID, DocumentScanProgress> },
      { vendorId: number }
    >({
      query: ({ vendorId }) => ({
        url: "vendor/security_profile/evaluate/v1/",
        method: "GET",
        params: {
          vendor_id: vendorId,
        },
      }),
      providesTags: (result, _error, { vendorId }) =>
        result ? [{ type: documentScanProgressTag, id: vendorId }] : [],
    }),

    // sendGapQuestionnaireV1
    // create and send a new gap questionnaire for the current security profile state
    sendGapQuestionnaireV1: builder.mutation<
      sendGapQuestionnaireResp,
      SendGapQuestionnaireReq
    >({
      query: ({
        vendorId,
        emailText,
        emailSubject,
        dueDate,
        recipientReminderDate,
        contacts,
      }) => ({
        url: "/vendor/securityprofile/questionnaire/v1",
        method: "POST",
        body: JSON.stringify({
          vendor_id: vendorId,
          email_text: emailText,
          email_subject: emailSubject,
          due_date: dueDate,
          recipient_reminder_date: recipientReminderDate,
          contacts: contacts,
        }),
      }),
      invalidatesTags: (result, _error, { vendorId }) =>
        result ? [{ type: gapQuestionnaireStatusTag, id: vendorId }] : [],
      onQueryStarted: (arg, { dispatch, queryFulfilled }) => {
        queryFulfilled.then(() => {
          dispatch(refreshSurveyListsIfNecessary(arg.vendorId));
        });
      },
    }),

    // getGapQuestionnaireStatusV1
    // get the current status of the gap questionnaire
    getGapQuestionnaireStatusV1: builder.query<
      getGapQuestionnaireStatusResp,
      { vendorId: number }
    >({
      query: ({ vendorId }) => ({
        url: "/vendor/securityprofile/questionnaire/status/v1",
        method: "GET",
        params: {
          vendor_id: vendorId,
        },
      }),
      providesTags: (result, _error, { vendorId }) =>
        result ? [{ type: gapQuestionnaireStatusTag, id: vendorId }] : [],
    }),
  }),
});

// polledDocumentScanProgress
// gets the document scan progress with automatic polling based on document status
export const usePolledDocumentScanProgress = (vendorId: number) => {
  const [pollingInterval, setPollingInterval] = useState(0);

  const result = VendorSecurityProfileAPI.useGetDocumentScanProgressQuery(
    {
      vendorId,
    },
    { pollingInterval }
  );

  useEffect(() => {
    // if any of our documents are still in progress trigger a refresh
    if (
      result?.data &&
      Object.values(result.data.progress).some(
        (progress) => progress.progress < 1
      )
    ) {
      setPollingInterval(5000);
    } else {
      setPollingInterval(0);
      // and if we were previously polling and we are stopping then trigger a refresh
      if (pollingInterval > 0) {
        VendorSecurityProfileAPI.util.invalidateTags([
          { type: securityProfileTag, id: vendorId },
        ]);
      }
    }
  }, [result, pollingInterval, vendorId]);

  return result;
};

export default VendorSecurityProfileAPI;
