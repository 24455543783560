import React, { useState } from "react";
import ModalV2 from "../../../_common/components/ModalV2";
import Button from "../../../_common/components/core/Button";
import DatePicker from "../../../_common/components/DatePicker";
import "../../style/components/CompleteVendorAssessmentModal.scss";
import InfoBanner, { BannerType } from "../InfoBanner";
import moment from "moment/moment";
import { getVendorWords } from "../../../_common/constants";
import { useAssuranceType } from "../../../_common/hooks";

interface ICompleteVendorAssessmentModalProps {
  active: boolean;
  onClose: () => void;
  confirm: (reassessmentDate?: string) => Promise<unknown>;
  newReassessmentDate?: string;
  newlyPublished: boolean;
  hasUnfinishedSurveys: boolean;
}

const CompleteVendorAssessmentModal: React.VFC<
  ICompleteVendorAssessmentModalProps
> = (props) => {
  const assuranceType = useAssuranceType();
  const vendorWords = getVendorWords(assuranceType);
  const [loading, setLoading] = useState(false);
  const [reassessmentDate, setReassessmentDate] = useState(
    props.newReassessmentDate
      ? moment(props.newReassessmentDate).format("YYYY-MM-DD")
      : undefined
  );

  const hadDate = !!props.newReassessmentDate;

  return (
    <ModalV2
      className={"complete-vendor-assessment"}
      active={props.active}
      onClose={props.onClose}
      disallowClose={loading}
      headerClassName={"complete-vendor-assessment-modal-header"}
      headerContent={
        <>
          <h2>
            {props.newlyPublished
              ? "Publish assessment"
              : "Set reassessment date"}
          </h2>
          {props.newlyPublished && (
            <>
              {props.hasUnfinishedSurveys && (
                <InfoBanner
                  type={BannerType.WARNING}
                  centered
                  message={
                    "This risk assessment contains incomplete questionnaires. We recommend questionnaires are completed in full to ensure the assessment is accurate."
                  }
                />
              )}
              <p>
                Select <b>Publish assessment</b> to finalize your assessment
                using the evidence and risks at this point in time. Published
                assessments are visible to all users in your account.
              </p>
            </>
          )}
        </>
      }
      footerContent={
        <div className={"complete-vendor-assessment-modal-footer"}>
          <Button tertiary disabled={loading} onClick={props.onClose}>
            Cancel
          </Button>
          <Button
            loading={loading}
            onClick={() => {
              setLoading(true);
              props.confirm(reassessmentDate).then(() => {
                setLoading(false);
                props.onClose();
              });
            }}
            danger={!reassessmentDate && !props.newlyPublished && hadDate}
            disabled={!hadDate && !reassessmentDate && !props.newlyPublished}
            filledPrimary={!!reassessmentDate}
          >
            {props.newlyPublished
              ? "Publish assessment"
              : !reassessmentDate && hadDate
                ? "Remove date"
                : hadDate
                  ? "Change date"
                  : "Set date"}
          </Button>
        </div>
      }
    >
      <div className={"form-grid"}>
        <div className={"left"}>
          {props.newlyPublished && <h3>{`Set reassessment date`}</h3>}
          <p>
            Select a date to be reminded to reassess this {vendorWords.singular}
            .
          </p>
        </div>
        <div className={"right"}>
          <DatePicker
            onChange={(evt) => {
              setReassessmentDate(evt.target.value);
            }}
            value={reassessmentDate}
            placeholder={"Select a reassessment date"}
            min={moment().add(1, "day").format("YYYY-MM-DD")}
          />
          {reassessmentDate != undefined && (
            <Button tertiary onClick={() => setReassessmentDate(undefined)}>
              Remove reassessment date
            </Button>
          )}
        </div>
      </div>
    </ModalV2>
  );
};

export default CompleteVendorAssessmentModal;
