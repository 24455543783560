import { setOrganisationFlags } from "../../vendorrisk/reducers/cyberRiskActions";
import { conditionalRefreshActivityStreamForOrgUser } from "../reducers/commonActions";
import BaseAPI from "../rtkQueryApi";
import { IOrgFlags, OrgFlagType } from "../types/organisations";

export interface OrganisationFlag {
  id: number;
  feature_name: string;
  email: string;
}

// deliberately mask the underlying type to keep references
// separate for future maintenance / refactoring
export type GetOrganisationFlagsV1Response = IOrgFlags;

export interface SetOrganisationFlagV1Params {
  name: OrgFlagType;
  value: string | number | boolean;
}

export enum OrganisationFlagsTags {
  orgFlags = "orgFlags",
}

const OrganisationFlagsAPI = BaseAPI.enhanceEndpoints({
  addTagTypes: [...Object.values(OrganisationFlagsTags)],
}).injectEndpoints({
  endpoints: (builder) => ({
    getOrganisationFlagsV1: builder.query<GetOrganisationFlagsV1Response, void>(
      {
        providesTags: [OrganisationFlagsTags.orgFlags],
        query: () => ({
          url: "/account/flags/v1/",
          method: "GET",
        }),
      }
    ),
    setOrganisationFlagV1: builder.mutation<void, SetOrganisationFlagV1Params>({
      invalidatesTags: [OrganisationFlagsTags.orgFlags],
      query: ({ name, value }) => ({
        url: "/account/flags/v1/",
        method: "PUT",
        params: { name, value },
      }),
      onQueryStarted({ name, value }, { dispatch, queryFulfilled }) {
        // optimistically patch the flags now
        const patch = dispatch(
          OrganisationFlagsAPI.util.updateQueryData(
            "getOrganisationFlagsV1",
            undefined,
            (draft) => ({ ...draft, [name]: value })
          )
        );

        // wait for the org flags update request to complete
        queryFulfilled
          .then(() => {
            // update primary store with org flags from this RTK-Query mutation
            dispatch(setOrganisationFlags({ [name]: value }));
            // kick off call to update the activity stream
            dispatch(conditionalRefreshActivityStreamForOrgUser());
          })
          .catch(patch.undo);
      },
    }),
  }),
});

export default OrganisationFlagsAPI;
