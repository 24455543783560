import {
  SET_CLEARALLDATA,
  SET_SURVEYS_LOADING,
  SET_SURVEYLIST,
  SET_REMEDIATION_REQUEST_LIST,
  SET_ACCEPTED_RISK_LIST,
} from "./actions";
import { SET_VENDOR_RISK_WAIVERS_AND_ADJUSTMENTS_TO_APPROVE } from "./vendorRiskWaiver.actions";
import { SET_VENDOR_PORTAL_REMEDIATION_REQUEST_IP_DETAILS } from "./ipAddress.actions";
import {
  SET_VENDOR_PORTAL_ADDITIONAL_EVIDENCE_REQUEST_DETAILS,
  SET_VENDOR_PORTAL_ADDITIONAL_EVIDENCE_REQUESTS,
  SET_VENDOR_PORTAL_ADDITIONAL_EVIDENCE_REQUESTS_LOADING,
} from "./additionalEvidenceRequest.actions";
import { initialState } from "./reducer.initialState";

/**
 * @param state {IVendorPortalState}
 * @param action {object}
 * */
function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_CLEARALLDATA:
      return initialState;
    case SET_SURVEYS_LOADING:
      return {
        ...state,
        vendorAppSurveys: {
          ...state.vendorAppSurveys,
          loading: action.loading,
        },
      };
    case SET_SURVEYLIST:
      return {
        ...state,
        vendorAppSurveys: action.results,
      };
    case SET_REMEDIATION_REQUEST_LIST:
      return {
        ...state,
        remediationRequestIDsLoading: action.remediationRequestIDsLoading,
        remediationRequestList:
          action.remediationRequestList || state.remediationRequestList,
      };
    case SET_ACCEPTED_RISK_LIST:
      return {
        ...state,
        acceptedRisks: action.acceptedRisks,
      };
    case SET_VENDOR_RISK_WAIVERS_AND_ADJUSTMENTS_TO_APPROVE:
      return {
        ...state,
        vendorRiskWaivers: action.waivers,
        vendorRiskAdjustments: action.adjustments,
      };
    case SET_VENDOR_PORTAL_REMEDIATION_REQUEST_IP_DETAILS:
      return {
        ...state,
        ipAddressDetails: {
          ...state.ipAddressDetails,
          [action.ipDetails.ip]: action.ipDetails,
        },
      };
    case SET_VENDOR_PORTAL_ADDITIONAL_EVIDENCE_REQUESTS_LOADING:
      return {
        ...state,
        additionalEvidenceRequests: {
          ...state.additionalEvidenceRequests,
          loading: action.loading,
        },
      };
    case SET_VENDOR_PORTAL_ADDITIONAL_EVIDENCE_REQUESTS:
      return {
        ...state,
        additionalEvidenceRequests: {
          ...state.additionalEvidenceRequests,
          evidenceRequestSummaries: action.result.evidenceRequests,
          loading: action.result.loading,
        },
      };
    case SET_VENDOR_PORTAL_ADDITIONAL_EVIDENCE_REQUEST_DETAILS:
      return {
        ...state,
        additionalEvidenceRequests: {
          ...state.additionalEvidenceRequests,
          detailedEvidenceRequests: {
            ...state.additionalEvidenceRequests.detailedEvidenceRequests,
            [action.evidenceRequest.id]: action.evidenceRequest,
          },
          loading: false,
        },
      };
    default:
      return state;
  }
}

export default reducer;
