import { FC, useState } from "react";
import { SlidePanelSection } from "../../../vendorrisk/components/filter/SlidePanelSection";
import RangeSlider from "../../../vendorrisk/components/filter/RangeSlider";

const MIN_VALUE = 0;
const MAX_VALUE = 950;

export enum UserRiskScoreFilterNoun {
  User = "user",
  Application = "application",
}

interface IUserRiskScoreFilterProps {
  minScore?: number;
  maxScore?: number;
  onChange: (obj: { minScore?: number; maxScore?: number }) => void;
  title?: string;
  noun: UserRiskScoreFilterNoun;
  startExpanded?: boolean;
}

const UserRiskScoreFilter: FC<IUserRiskScoreFilterProps> = ({
  minScore,
  maxScore,
  onChange,
  title,
  noun = UserRiskScoreFilterNoun.User,
  startExpanded,
}) => {
  const [expanded, setExpanded] = useState(
    startExpanded || !!minScore || !!maxScore
  );
  return (
    <SlidePanelSection
      title={title ?? "Filter by ${noun} score"}
      expanded={expanded}
      toggleExpand={() => setExpanded(!expanded)}
    >
      <p className="explanation-text">
        Drag the handles below to set a range of scores. Only {noun}s with a
        score within the specified range will be included.
      </p>
      <RangeSlider
        lowerVal={minScore}
        upperVal={maxScore}
        onChange={({
          lowerVal,
          upperVal,
        }: {
          lowerVal?: number;
          upperVal?: number;
        }) => onChange({ minScore: lowerVal, maxScore: upperVal })}
        min={MIN_VALUE}
        max={MAX_VALUE}
      />
    </SlidePanelSection>
  );
};

export default UserRiskScoreFilter;
