import { SeverityInt } from "../../../_common/types/severity";
import { OptionType } from "../../../_common/components/SelectV2";
import moment from "moment";
// @ts-ignore
import PropTypes from "prop-types";
import { SurveyAttribute, SurveyStatus } from "../../../_common/types/survey";
import { ManagedVendorFilterV2 } from "../../../analyst_portal/components/filter/TprmsFilter.helpers";
import { IRangeSliderValues } from "./RangeSlider";
import { CloudProviderType } from "../../../appguard/api/types";
import { AppStatus, ScopeRiskLevel } from "../../../userbase/api/types";

// Define new filters here. If you add to this, you should probably also:
//   1. Add the state names to an appropriate interface in this file down below! This state is used
//      to reflect the state of your filter, e.g. if you are filtering by security score, it will be
//      the min score and the max score value for the filter. It is also customary to declare the
//      reset state of your filter in this file too, so that clicking the "Reset" button in the
//      filter panel resets to the correct values.
//   2. Add the filter state to the FilterPanel component in vendorrisk/components/filter/FilterPanel.tsx
//      This will control the state of the filter, i.e. whether checkboxes are checked, etc.
//   3. Also add the rendered representation to the FilterPanel component. This is what will be
//      rendered in the UI.
//   4. Add the state to the isFilterActive function in vendorrisk/components/filter/selectors.ts
//      This will conditions are declared to determine whether or not your filter is "active", thus
//      showing the "Filters active" status bar at the top of the UI.
//   5. Add the default values of your state to vendorrisk/reducers/defaults.ts
//      This will be the initial empty-state of your filter.
//   6. Add your filter type in the appropriate spot in the FilterBar component in vendorrisk/components/filter/FilterBar.tsx
//      This will enable the "Clear filters" button in the filters status bar at the top of the UI
//      to reset your filters' state.
//   7. Integrate your filter into the FilterLabel component in vendorrisk/components/filter/FilterLabel.tsx
//      This component is what displays which filters are active on the "Apply filters" button.
//
// There may be more things to do. Please add them to the list!
export enum FilterTypes {
  SCORE = "SCORE_FILTER",
  WEBSITE_LABEL = "WEBSITE_LABEL_FILTER",
  // WEBSITE_LABEL_ADVANCED includes Match All and Do Not Match options
  // and is mutually exclusive with WEBSITE_LABEL
  WEBSITE_LABEL_ADVANCED = "WEBSITE_LABEL_ADVANCED_FILTER",
  VENDOR_LABEL = "VENDOR_LABEL_FILTER",
  SUBSIDIARY_LABEL = "SUBSIDIARY_LABEL_FILTER",
  SUBSIDIARY_COUNTRY = "SUBSIDIARY_COUNTRY_FILTER",
  SUBSIDIARY = "SUBSDIARY_FILTER",
  SUBSIDIARY_SCORE = "SUBSIDIARY_SCORE_FILTER",
  VENDOR = "VENDOR_FILTER",
  RISK_CATEGORY = "RISK_CATEGORY_FILTER",
  RISK = "RISK_FILTER",
  DATALEAKS_CLASSIFICATION = "DATALEAKS_CLASSIFICATION",
  DATALEAKS_SOURCE = "DATALEAKS_SOURCE",
  DATALEAKS_KEYWORD = "DATALEAKS_KEYWORD",
  DATALEAKS_REPORTED_DATE = "DATALEAKS_REPORTED_DATE",
  DOMAIN_PORTFOLIO = "DOMAIN_PORTFOLIO",
  IP_SOURCE = "IP_SOURCE",
  IP_OWNER = "IP_OWNER",
  IP_ASN = "IP_ASN",
  IP_COUNTRY = "IP_COUNTRY",
  IP_SERVICE = "IP_SERVICE",
  VULNS_CVE_NAME = "VULNS_CVE_NAME",
  VULNS_SOFTWARE = "VULNS_SOFTWARE",
  VULNS_VERIFIED_STATUS = "VULNS_VERIFIED_STATUS",
  VULNS_CVSS_SEVERITY = "VULNS_CVSS_SEVERITY",
  VULNS_EPSS_SEVERITY = "VULNS_EPSS_SEVERITY",
  TIME = "TIME",
  USER = "USER",
  AUDIT_LOG_EVENT = "AUDIT_LOG_EVENT",
  TYPOSQUAT_TYPES = "TYPOSQUAT_TYPES",
  TYPOSQUAT_REASONS = "TYPOSQUAT_REASONS",
  TYPOSQUAT_MALICIOUS_ACTIVITIES = "TYPOSQUAT_MALICIOUS_ACTIVITIES",
  API_KEY = "API_KEY",
  VENDOR_TIER = "VENDOR_TIER",
  VENDOR_ASSESSMENT_STATUS = "VENDOR_ASSESSMENT_STATUS",
  VENDOR_ASSESSMENT_AUTHOR = "VENDOR_ASSESSMENT_AUTHOR",
  VENDOR_REASSESSMENT_DATE = "VENDOR_REASSESSMENT_DATE",
  VENDOR_PORTFOLIO = "VENDOR_PORTFOLIO",
  SURVEY_STATUS = "SURVEY_STATUS",
  VENDOR_ATTRIBUTES = "VENDOR_ATTRIBUTES",
  VENDOR_SURVEY_TYPES = "VENDOR_SURVEY_TYPES",
  VENDOR_EVIDENCE_TYPES = "VENDOR_EVIDENCE_TYPES",
  VENDOR_DATE_ADDED = "VENDOR_DATE_ADDED",
  TPRMS_EXCLUDED_SERVICE_LEVELS = "TPRMS_EXCLUDED_SERVICE_LEVELS",
  TPRMS_ORG_NAMES = "TPRMS_ORG_NAMES",
  TPRMS_INCLUDED_SERVICE_STATUSES = "TPRMS_INCLUDED_SERVICE_STATUSES",
  TPRMS_INCLUDED_ANALYST = "TPRMS_INCLUDED_ANALYST",
  DETECTED_PRODUCT_CATEGORY = "DETECTED_PRODUCT_CATEGORY",
  DETECTED_PRODUCT_VENDOR_MONITORED = "DETECTED_PRODUCT_VENDOR_MONITORED",
  FOURTH_PARTY_PRODUCT = "FOURTH_PARTY_PRODUCT",
  APPGUARD_CLOUD_CONNECTION_PROVIDER_TYPE = "APPGUARD_CLOUD_CONNECTION_PROVIDER_TYPE",
  APPGUARD_CLOUD_CONNECTION_UUID = "APPGUARD_CLOUD_CONNECTION_UUID",
  USERRISK_USER_UUIDS = "USERRISK_USER_UUIDS",
  USERRISK_APPS = "USERRISK_APPS",
  USERRISK_TEAMS = "USERRISK_TEAMS",
  USERRISK_ROLES = "USERRISK_ROLES",
  USERRISK_APP_STATUS = "USERRISK_APP_STATUS",
  USERRISK_APP_APPROVED_TEAMS = "USERRISK_APP_APPROVED_TEAMS",
  USERRISK_APP_APPROVED_ROLES = "USERRISK_APP_APPROVED_ROLES",
  USERRISK_APP_FIRST_DETECTED = "USERRISK_APP_FIRST_DETECTED",
  USERRISK_APP_RISK_LEVEL = "USERRISK_APP_RISK_LEVEL",
  USERRISK_SCOPE_RISK_LEVEL = "USERRISK_SCOPE_RISK_LEVEL",
  USERRISK_SCOPE_READWRITE = "USERRISK_SCOPE_READWRITE",
  USERRISK_USER_SCORES = "USERRISK_USER_SCORES",
  USERRISK_APP_SCORES = "USERRISK_APP_SCORES",
}

export const filterTypesPropType = PropTypes.arrayOf(
  PropTypes.oneOf(Object.values(FilterTypes))
);

export interface VendorAttributeFilter {
  selectedValues?: string[];
  doNotMatch?: boolean;
  matchAll?: boolean;
  startDate?: string;
  endDate?: string;
  before?: boolean;
  between?: boolean;
}

export interface WatchlistFilter {
  namePrefix?: string;
  vendorLabelIds: number[];
  vendorLabelIdsMatchAll: boolean;
  includeUnlabeled: boolean;
  minScore?: number;
  maxScore?: number;
  draftAssessments?: boolean;
  vendorIds: number[];
  vendorLabelIdsDoNotMatch: boolean;
  vendorTiers: number[];
  vendorAssessmentClassifications: number[];
  vendorAssessmentAuthors: number[];
  vendorAssessmentAuthorDoNotMatch: boolean;
  vendorReassessmentStartDate: string;
  vendorReassessmentEndDate: string;
  vendorReassessmentDateBefore: boolean;
  vendorReassessmentDateBetween: boolean;
  portfolioIds: number[];
  assessedVendorsOnly: boolean;
  selectedAttributes: Record<number, VendorAttributeFilter>;
  vendorSurveyTypes: number[];
  vendorEvidenceTypes: number[];
  fourthPartyProductUUIDs: string[];
  vendorDateAddedStartDate: string;
  vendorDateAddedEndDate: string;
  vendorDateAddedDateBefore: boolean;
  vendorDateAddedDateBetween: boolean;
}

export interface VendorLabelFilters {
  vendorLabelIds: number[];
  vendorLabelIdsMatchAll: boolean;
  vendorLabelIdsDoNotMatch: boolean;
  includeUnlabeled: boolean;
  websiteLabelIds: number[];
  websiteLabelIdsDoNotMatch: boolean;
  websiteLabelIdsMatchAll: boolean;
  websiteIncludeUnlabeled: boolean;
  minScore?: number;
  maxScore?: number;
  vendorTiers: number[];
  vendorAssessmentClassifications: number[];
  vendorAssessmentAuthors: number[];
  vendorAssessmentAuthorDoNotMatch: boolean;
  vendorReassessmentStartDate: string;
  vendorReassessmentEndDate: string;
  vendorReassessmentDateBefore: boolean;
  vendorReassessmentDateBetween: boolean;
  portfolioIds: number[];
  selectedAttributes: Record<number, VendorAttributeFilter>;
  vendorSurveyTypes: number[];
  vendorEvidenceTypes: number[];
  fourthPartyProductUUIDs: string[];
  vendorDateAddedStartDate: string;
  vendorDateAddedEndDate: string;
  vendorDateAddedDateBefore: boolean;
  vendorDateAddedDateBetween: boolean;
}

export interface SubsidiariesFilter {
  subsidiariesOnly?: boolean;
}

// Represents the reset state of customer data filters
export const clearedVendorFilters: VendorLabelFilters = {
  vendorLabelIds: [],
  vendorLabelIdsMatchAll: false,
  vendorLabelIdsDoNotMatch: false,
  includeUnlabeled: false,
  websiteLabelIds: [],
  websiteLabelIdsDoNotMatch: false,
  websiteLabelIdsMatchAll: false,
  websiteIncludeUnlabeled: false,
  minScore: undefined,
  maxScore: undefined,
  vendorTiers: [],
  vendorAssessmentClassifications: [],
  vendorAssessmentAuthors: [],
  vendorAssessmentAuthorDoNotMatch: false,
  vendorReassessmentStartDate: "",
  vendorReassessmentEndDate: "",
  vendorReassessmentDateBefore: false,
  vendorReassessmentDateBetween: false,
  portfolioIds: [],
  selectedAttributes: {},
  vendorEvidenceTypes: [],
  vendorSurveyTypes: [],
  fourthPartyProductUUIDs: [],
  vendorDateAddedStartDate: "",
  vendorDateAddedEndDate: "",
  vendorDateAddedDateBefore: false,
  vendorDateAddedDateBetween: false,
};

export const getQueryFromVendorLabelFilters = (
  filters: VendorLabelFilters | undefined
) => {
  if (!filters) {
    return {};
  }

  return {
    vendor_label_ids: filters.vendorLabelIds,
    vendor_label_ids_match_all: filters.vendorLabelIdsMatchAll,
    vendor_label_ids_do_not_match: filters.vendorLabelIdsDoNotMatch,
    include_unlabeled: filters.includeUnlabeled,
    min_score: filters.minScore,
    max_score: filters.maxScore,
    vendor_tiers: filters.vendorTiers,
    vendor_assessment_classifications: filters.vendorAssessmentClassifications,
    vendor_assessment_authors: filters.vendorAssessmentAuthors,
    vendor_assessment_authors_not: filters.vendorAssessmentAuthorDoNotMatch,
    vendor_reassessment_start: filters.vendorReassessmentStartDate,
    vendor_reassessment_end: filters.vendorReassessmentEndDate,
    vendor_reassessment_before: filters.vendorReassessmentDateBefore,
    vendor_reassessment_between: filters.vendorReassessmentDateBetween,
    portfolio_ids: filters.portfolioIds,
    // since the attribute filters are complex we serialise them to json
    attributes: JSON.stringify(filters.selectedAttributes),
    survey_type_ids: filters.vendorSurveyTypes,
    evidence_type_ids: filters.vendorEvidenceTypes,
    fourth_party_product_uuids: filters.fourthPartyProductUUIDs,
    vendor_date_added_start: filters.vendorDateAddedStartDate,
    vendor_date_added_end: filters.vendorDateAddedEndDate,
    vendor_date_added_before: filters.vendorDateAddedDateBefore,
    vendor_date_added_between: filters.vendorDateAddedDateBetween,
  };
};

export interface WebsiteFilters {
  websiteLabelIds: number[];
  websiteLabelIdsDoNotMatch: boolean;
  websiteLabelIdsMatchAll: boolean;
  websiteIncludeUnlabeled: boolean;
  riskIds: string[];
  domainPortfolioIds: number[];
}

export const clearedWebsiteFilters: WebsiteFilters = {
  websiteLabelIds: [],
  websiteLabelIdsDoNotMatch: false,
  websiteLabelIdsMatchAll: false,
  websiteIncludeUnlabeled: false,
  riskIds: [],
  domainPortfolioIds: [],
};

export interface SubsidiaryFilters {
  subsidiaryCountries: string[];
  subsidiaryLabelIds: number[];
  subsidiaryIds: number[];
  subsidiaryMinScore?: number;
  subsidiaryMaxScore?: number;
}

export const clearedSubsidiaryFilters: SubsidiaryFilters = {
  subsidiaryCountries: [],
  subsidiaryLabelIds: [],
  subsidiaryIds: [],
  subsidiaryMinScore: undefined,
  subsidiaryMaxScore: undefined,
};

export interface DataLeaksFilterOptionType extends OptionType {
  value: string;
  label: string;
}

export interface DataLeaksReportedDateFilterType {
  startDate: moment.Moment;
  endDate: moment.Moment;
}

export interface DataLeaksFilters {
  dataLeaksClassifications: DataLeaksFilterOptionType[];
  dataLeaksSources: DataLeaksFilterOptionType[];
  dataLeaksKeywords: DataLeaksFilterOptionType[];
  dataLeaksReportedDate?: DataLeaksReportedDateFilterType;
}

export const clearedDataLeaksFilters: DataLeaksFilters = {
  dataLeaksClassifications: [],
  dataLeaksSources: [],
  dataLeaksKeywords: [],
  dataLeaksReportedDate: undefined,
};

export interface VulnSoftware {
  name: string;
  product: string;
  title: string;
}

export interface VulnsFilters {
  vulnsCveNames: string[];
  vulnsSoftware: VulnSoftware[];
  vulnsExcludeVerified: boolean;
  vulnsExcludeUnverified: boolean;
  vulnsOnlyKEV: boolean;
  vulnsExcludedSeverities: SeverityInt[];
  vulnsEPSSScores: IRangeSliderValues;
}

export const clearedVulnsFilters: VulnsFilters = {
  vulnsCveNames: [],
  vulnsSoftware: [],
  vulnsExcludeVerified: false,
  vulnsExcludeUnverified: false,
  vulnsOnlyKEV: false,
  vulnsExcludedSeverities: [],
  vulnsEPSSScores: {},
};

export interface IPAddressFilters {
  Sources?: string[];
  ASNs?: string[];
  Countries?: string[];
  Services?: string[];
  Owners?: string[];
}

export interface TyposquattingFilters {
  typosquatReasonIds: string[];
  typosquatTypeIds: string[];
  typosquatMaliciousActivities: string[];
  typosquatReasonIdsDoNotMatch: boolean;
  typosquatTypeIdsDoNotMatch: boolean;
  typosquatMaliciousActivitiesDoNotMatch: boolean;
}

export const clearedTyposquatFilters: TyposquattingFilters = {
  typosquatReasonIds: [],
  typosquatTypeIds: [],
  typosquatMaliciousActivities: [],
  typosquatReasonIdsDoNotMatch: false,
  typosquatTypeIdsDoNotMatch: false,
  typosquatMaliciousActivitiesDoNotMatch: false,
};

export enum WebsiteStatusFilter {
  All = "All",
  Active = "Active",
  Inactive = "Inactive",
}

export interface Filters
  extends VendorLabelFilters,
    DataLeaksFilters,
    VulnsFilters,
    WebsiteFilters,
    SubsidiaryFilters,
    TyposquattingFilters,
    WatchlistFilter,
    SurveyStatusFilters,
    DetectedProductFilters,
    ManagedVendorFilterV2,
    CloudConnectionsFilters,
    UserRiskFilters {
  vendorIds: number[];
  riskName?: string;
  riskCategories: string[];
  vulnsExcludeUnignored?: boolean;
  vulnsExcludeIgnored?: boolean;
  riskIds: string[];
  websiteStatus?: WebsiteStatusFilter;
  ips_filter?: string;
}

export interface HostnameVulnsFilter {
  CVENames: string[];
  CPENames: string[];
  AtDate?: string;
  ExcludeVerified: boolean;
  ExcludeUnverified: boolean;
  ExcludedCVSSSeverities: SeverityInt[];
  VulnsEPSSScores: IRangeSliderValues;
  IgnoringOrganisationID: number;
  ExcludeUnignored: boolean;
  ExcludeIgnored: boolean;
}

export interface TyposquattingExportFilters {
  permutation_types: string[];
  permutation_types_not_in: boolean;
  risk_status_types: string[];
  risk_status_types_not_in: boolean;
  include_registered: boolean;
  include_unregistered: boolean;
  include_ignored: boolean;
  malicious_activities: string[];
  malicious_activities_not_in: boolean;
}

export interface SurveyStatusFilters {
  selectedSurveyStatuses: SurveyStatus[];
  selectedSurveyAttributes: SurveyAttribute[];
}
export const clearedSurveyStatusFilters: SurveyStatusFilters = {
  selectedSurveyStatuses: [],
  selectedSurveyAttributes: [],
};

export interface DetectedProductFilters {
  detectedProductCategory: string[];
  detectedProductVendorMonitored: boolean;
  detectedProductVendorUnmonitored: boolean;
}

export interface CloudConnectionsFilters {
  cloudConnectionProviderTypes: CloudProviderType[];
  cloudConnectionUUIDs: string[];
}

export const clearedCloudConnectionsFilters: CloudConnectionsFilters = {
  cloudConnectionProviderTypes: [],
  cloudConnectionUUIDs: [],
};

export interface UserRiskFilters {
  userRiskUserUUIDs: string[];
  userRiskExcludeUsers: boolean;
  userRiskAppNames: string[];
  userRiskExcludeApps: boolean;
  userRiskTeams: string[];
  userRiskExcludeTeams: boolean;
  userRiskRoles: string[];
  userRiskExcludeRoles: boolean;
  userRiskAppStatuses: AppStatus[];
  userRiskExcludeStatuses: boolean;
  userRiskMinFirstDetected?: string;
  userRiskMaxFirstDetected?: string;
  userRiskFirstDetectedBefore: boolean;
  userRiskFirstDetectedBetween: boolean;
  userRiskMinRiskLevel: ScopeRiskLevel;
  userRiskMaxRiskLevel: ScopeRiskLevel;
  userRiskScopeReadOnly: boolean;
  userRiskScopeReadWrite: boolean;
  userRiskScopeMinRiskLevel: ScopeRiskLevel;
  userRiskScopeMaxRiskLevel: ScopeRiskLevel;
  userRiskUserMinScore: number;
  userRiskUserMaxScore: number;
  userRiskAppMinScore: number;
  userRiskAppMaxScore: number;
}

export const clearedUserRiskFilters: UserRiskFilters = {
  userRiskUserUUIDs: [],
  userRiskExcludeUsers: false,
  userRiskAppNames: [],
  userRiskExcludeApps: false,
  userRiskTeams: [],
  userRiskExcludeTeams: false,
  userRiskRoles: [],
  userRiskExcludeRoles: false,
  userRiskAppStatuses: [],
  userRiskExcludeStatuses: false,
  userRiskMinFirstDetected: undefined,
  userRiskMaxFirstDetected: undefined,
  userRiskFirstDetectedBefore: false,
  userRiskFirstDetectedBetween: false,
  userRiskMinRiskLevel: ScopeRiskLevel.LowScopeRiskLevel,
  userRiskMaxRiskLevel: ScopeRiskLevel.HighScopeRiskLevel,
  userRiskScopeReadOnly: true,
  userRiskScopeReadWrite: true,
  userRiskScopeMinRiskLevel: ScopeRiskLevel.LowScopeRiskLevel,
  userRiskScopeMaxRiskLevel: ScopeRiskLevel.HighScopeRiskLevel,
  userRiskUserMinScore: 0,
  userRiskUserMaxScore: 950,
  userRiskAppMinScore: 0,
  userRiskAppMaxScore: 950,
};
