import ColorCheckbox from "../ColorCheckbox";
import "../../style/components/VendorAssessmentClassificationChoiceSet.scss";
import {
  VendorAssessmentClassification,
  VendorAssessmentClassificationMap,
} from "../../types/vendorAssessments";
import { ReactNode } from "react";

interface VendorAssessmentClassificationChoiceSetProps {
  classifications: VendorAssessmentClassificationMap;
  selectedClassifications: number[];
  onSelectionChange: (selected: number[]) => void;
  multiSelect?: boolean;
}

const VendorAssessmentClassificationChoiceSet = ({
  classifications,
  selectedClassifications,
  onSelectionChange,
  multiSelect,
}: VendorAssessmentClassificationChoiceSetProps) => {
  const keys = Object.keys(classifications);
  keys.sort();
  const toDisplay = [] as ReactNode[];
  keys.forEach((c) => {
    const cl = classifications[c];
    toDisplay.push(
      <VendorAssessmentChoice
        key={cl.classification}
        multiSelect={multiSelect}
        classification={cl}
        isSelected={selectedClassifications.indexOf(cl.classification) !== -1}
        onSelected={(isSelected) => {
          if (isSelected) {
            const existingSelected = multiSelect
              ? [...selectedClassifications]
              : [];
            onSelectionChange([...existingSelected, cl.classification]);
          } else {
            onSelectionChange(
              selectedClassifications.filter(
                (level) => level !== cl.classification
              )
            );
          }
        }}
      />
    );
  });
  return (
    <div className={"vendor-assessments-filter-choice-set"}>{toDisplay}</div>
  );
};

export default VendorAssessmentClassificationChoiceSet;

interface VendorAssessmentChoiceProps {
  classification: VendorAssessmentClassification;
  isSelected: boolean;
  onSelected: (isSelected: boolean) => void;
  multiSelect?: boolean;
}

const VendorAssessmentChoice = ({
  classification,
  isSelected,
  onSelected,
  multiSelect,
}: VendorAssessmentChoiceProps) => {
  return (
    <div className={"vendor-assessment"} key={classification.classification}>
      <ColorCheckbox
        radio={!multiSelect}
        checked={isSelected}
        onClick={() => onSelected(!isSelected)}
        label={classification.title}
      />
    </div>
  );
};
