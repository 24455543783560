import { FetchCyberRiskUrl } from "../../_common/api";
import {
  LogError,
  MapArrayToUnixDateWithoutTimezone,
} from "../../_common/helpers";
import { OrgAccessScoringChangeNotification } from "../../_common/permissions";
import {
  fetchCPEKnownExploitedVulnCountsByRiskID,
  fetchCustomerScoringAlgorithmChange,
  setCustomerData,
} from "./cyberRiskActions";
import { Filters } from "../components/filter/types";
import {
  DefaultThunkDispatch,
  IVendorSummary,
} from "../../_common/types/redux";
import { DefaultRootState } from "react-redux";

export const fetchCustomerSummaryAndCloudscans = (
  forced = false,
  filters?: Partial<Filters>,
  noUpdateState = false // If true, don't make any redux state updates
) => {
  return async (
    dispatch: DefaultThunkDispatch,
    getState: () => DefaultRootState
  ) => {
    const { summary, filters: currentFilters } =
      getState().cyberRisk.customerData;
    let json;

    if (!forced && summary && !summary.loading && !summary.error) {
      // We have cached cloudscans and summary data, don't fetch again
      return summary;
    }

    const newFilters = filters || currentFilters;

    if (!noUpdateState) {
      dispatch(setCustomerData({ summary: { ...summary, loading: true } }));
    }

    try {
      json = await FetchCyberRiskUrl<IVendorSummary>(
        "vendor/summary/v2/",
        {
          customer: true,
          num_days: 365,
          vendor_label_ids: newFilters.vendorLabelIds,
          vendor_label_ids_match_all: newFilters.vendorLabelIdsMatchAll,
          vendor_label_ids_do_not_match: newFilters.vendorLabelIdsDoNotMatch,
          website_label_ids: newFilters.websiteLabelIds,
          website_label_ids_match_all: newFilters.websiteLabelIdsMatchAll,
          website_label_ids_do_not_match: newFilters.websiteLabelIdsDoNotMatch,
          website_include_unlabeled: newFilters.websiteIncludeUnlabeled,
          website_portfolio_ids: newFilters.domainPortfolioIds,
          include_unlabeled: newFilters.includeUnlabeled,
          min_score: newFilters.minScore,
          max_score: newFilters.maxScore,
          appguard_cloud_providers: newFilters.cloudConnectionProviderTypes,
          appguard_cloud_connection_uuids: newFilters.cloudConnectionUUIDs,
        },
        null,
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error fetching summary data", e);

      if (noUpdateState) {
        throw e;
      }

      dispatch(
        setCustomerData({
          summary: {
            loading: false,
            error: {
              errorText: "Error fetching data",
              actionText: "Try again",
              actionOnClick: () =>
                dispatch(fetchCustomerSummaryAndCloudscans(true)),
            },
          },
        })
      );

      return summary;
    }

    if (!json.result) {
      if (noUpdateState) {
        throw new Error("No linked vendor.");
      }

      const newSummaryData: IVendorSummary = {
        loading: false,
        error: {
          errorText:
            'You have not linked your company to a vendor yet. Click "Edit company details" in the menu above.',
        },
      };
      dispatch(setCustomerData({ summary: newSummaryData }));
      return newSummaryData;
    }

    const newSummaryData: IVendorSummary = {
      loading: false,
      error: null,
    };

    newSummaryData.result = {
      ...json.result,
      totalFoundTargets: summary?.result?.totalFoundTargets ?? 0,
      overallScores: MapArrayToUnixDateWithoutTimezone(
        json.result.overallScores
      ),
      baseVendorScores: MapArrayToUnixDateWithoutTimezone(
        json.result.baseVendorScores
      ),
      inUseVendorsScores: MapArrayToUnixDateWithoutTimezone(
        json.result.inUseVendorsScores
      ),
      scoringChanges: MapArrayToUnixDateWithoutTimezone(
        json.result.scoringChanges
      ),
      customerPublicScores: MapArrayToUnixDateWithoutTimezone(
        json.result.customerPublicScores
      ),
    };

    const subdomainScansPreviouslyPending =
      summary?.result?.subdomainScansPending ?? false;
    if (
      (subdomainScansPreviouslyPending &&
        !newSummaryData.result.subdomainScansPending) ||
      (newSummaryData.result.totalFoundTargets === 0 &&
        (newSummaryData.result.foundTargetsPending ?? 0) > 0)
    ) {
      // We've transitioned from the first stage of the subdomain scans to the next.
      // Store the original total pending targets so that we can report the percentage complete as we go.
      newSummaryData.result.totalFoundTargets =
        newSummaryData.result.foundTargetsPending;
    }

    if (!noUpdateState) {
      dispatch(setCustomerData({ summary: newSummaryData }));

      const orgPerms = getState().common.userData.orgPermissions
        ? getState().common.userData.orgPermissions
        : [];
      if (orgPerms.includes(OrgAccessScoringChangeNotification)) {
        dispatch(fetchCustomerScoringAlgorithmChange(forced));
      }

      // get the kevs counts that we need for the risk profile
      dispatch(
        fetchCPEKnownExploitedVulnCountsByRiskID(true, false, undefined, forced)
      );
    }

    return newSummaryData;
  };
};
