import { Action } from "redux";
import { FetchCyberRiskUrl } from "../../_common/api";
import { LogError } from "../../_common/helpers";
import {
  AdditionalEvidence,
  AdditionalEvidenceSummary,
} from "../../vendorrisk/types/additionalEvidence";
import { DefaultThunkDispatch } from "../../_common/types/redux";
import { DefaultRootState } from "react-redux";
import { ContentLibraryDocument } from "../../contentlibrary/types/contentLibrary.types";

export const SET_VENDOR_PORTAL_ADDITIONAL_EVIDENCE_REQUESTS =
  "SET_VENDOR_PORTAL_ADDITIONAL_EVIDENCE_REQUESTS";
export const SET_VENDOR_PORTAL_ADDITIONAL_EVIDENCE_REQUESTS_LOADING =
  "SET_VENDOR_PORTAL_ADDITIONAL_EVIDENCE_REQUESTS_LOADING";
export const SET_VENDOR_PORTAL_ADDITIONAL_EVIDENCE_REQUEST_DETAILS =
  "SET_VENDOR_PORTAL_ADDITIONAL_EVIDENCE_REQUEST_DETAILS";

export const fetchVendorPortalAdditionalEvidenceRequestSummaries = () => {
  return async (
    dispatch: DefaultThunkDispatch,
    getState: () => DefaultRootState
  ) => {
    let json;
    try {
      json = await FetchCyberRiskUrl(
        "vendorapp/additionalevidence/requests/v1",
        null,
        null,
        dispatch,
        getState
      );
    } catch (e) {
      LogError(
        "Error retrieving vendor portal additional evidence requests",
        e
      );
      throw e;
    }

    dispatch(
      setVendorPortalAdditionalEvidenceRequests(<
        IAdditionalEvidenceRequestsState
      >{
        loading: false,
        evidenceRequests: json,
      })
    );
  };
};

export const fetchVendorPortalAdditionalEvidenceRequestDetail = (
  additionalEvidenceId: number
) => {
  return async (
    dispatch: DefaultThunkDispatch,
    getState: () => DefaultRootState
  ) => {
    dispatch(setVendorPortalAdditionalEvidenceRequestsLoading(true));

    const params = {
      evidence_id: additionalEvidenceId,
    };

    let json;

    try {
      json = await FetchCyberRiskUrl<AdditionalEvidence>(
        "vendorapp/additionalevidence/requests/detailed/v1",
        params,
        null,
        dispatch,
        getState
      );
    } catch (e) {
      LogError(
        "Error retrieving vendor portal additional evidence request detail",
        e
      );
      dispatch(setVendorPortalAdditionalEvidenceRequestsLoading(false));
      throw e;
    }

    dispatch(setVendorPortalAdditionalEvidenceRequestDetail(json));
  };
};

interface IAdditionalEvidenceRequestsState {
  evidenceRequestSummaries?: AdditionalEvidenceSummary[];
  loading: boolean;
}

const setVendorPortalAdditionalEvidenceRequests = (
  result: IAdditionalEvidenceRequestsState
) => {
  return <Action>{
    type: SET_VENDOR_PORTAL_ADDITIONAL_EVIDENCE_REQUESTS,
    result,
  };
};

const setVendorPortalAdditionalEvidenceRequestsLoading = (loading: boolean) => {
  return <Action>{
    type: SET_VENDOR_PORTAL_ADDITIONAL_EVIDENCE_REQUESTS_LOADING,
    loading,
  };
};

const setVendorPortalAdditionalEvidenceRequestDetail = (
  evidenceRequest: AdditionalEvidence
) => {
  return {
    type: SET_VENDOR_PORTAL_ADDITIONAL_EVIDENCE_REQUEST_DETAILS,
    evidenceRequest,
  };
};

export const vendorPortalUpdateAdditionalEvidenceRequest = (
  evidenceId: number,
  expiryDate: string,
  file: File | null,
  doc: ContentLibraryDocument | null
) => {
  return async (
    dispatch: DefaultThunkDispatch,
    getState: () => DefaultRootState
  ) => {
    const data = new FormData();
    if (file) data.append("file", file);

    try {
      await FetchCyberRiskUrl(
        "vendorapp/additionalevidence/requests/v1",
        {
          evidence_id: evidenceId,
          expiry_date: expiryDate,
          content_library_uuid: doc?.uuid,
        },
        {
          body: data,
          method: "POST",
        },
        dispatch,
        getState,
        false,
        false
      );
    } catch (e) {
      console.error(e);
      throw e;
    }
  };
};
