import * as React from "react";
import Button from "../../../_common/components/core/Button";

import { closeModal } from "../../../_common/reducers/commonActions";
import DragDropUpload from "../../../_common/components/DragDropUpload";
import { IVendorDocumentSummary } from "../../reducers/vendorDocuments.actions";
import AdditionalEvidenceDocument from "../AdditionalEvidenceDocument";
import TextField, {
  MaxLengthType,
} from "../../../_common/components/TextField";
import { DefaultThunkDispatch } from "../../../_common/types/redux";
import {
  addDefaultUnknownErrorAlert,
  addSimpleErrorAlert,
} from "../../../_common/reducers/messageAlerts.actions";
import RichTextEditV2 from "../../../_common/components/RichTextEditV2";
import {
  AcceptedDocumentFileExtensions,
  AcceptedDocumentMimeTypes,
  MaxFileSizeB,
  MaxFileSizeDisplay,
} from "../../../_common/types/fileRestrictions";

export const DocumentModalName = "DocumentModal";

export const AcceptedDocumentFileExtensionsList =
  AcceptedDocumentFileExtensions.map((ext) => ext.split(".").pop()).join(", ");

const stripExtension = (filename: string): string => {
  const parts = filename.split(".");
  if (parts.length <= 1) {
    return filename;
  }

  return parts.slice(0, -1).join(".");
};

export interface IDocumentModalData {
  hideNameField?: boolean;
  isFileOptional?: boolean;
  isRichText?: boolean;
  document?: IVendorDocumentSummary;
  isReadOnly?: boolean;
  file?: File;
  canEdit: boolean;
  onDocumentUpload: (
    name: string,
    description: string,
    file?: File,
    deleteExistingFile?: boolean
  ) => Promise<void>;
  onDocumentDownload: (
    id: number,
    virusScanned: boolean,
    virusSafe: boolean
  ) => void;
  overrideTitle?: string;
}

interface IDocumentModalProps {
  modalData: IDocumentModalData;
  dispatch: DefaultThunkDispatch;
}

interface IDocumentModalState {
  loading: boolean;
  name: string;
  description: string;
  isDescriptionValid: boolean;
  selectedFile?: File;
  isDeleteForExistingFile?: boolean;
  isReadOnly: boolean;
}

class DocumentModal extends React.Component<
  IDocumentModalProps,
  IDocumentModalState
> {
  constructor(props: IDocumentModalProps) {
    super(props);

    this.state = {
      loading: false,
      name: props.modalData.document
        ? props.modalData.document.documentName
        : props.modalData.file
          ? stripExtension(props.modalData.file.name)
          : "",
      description: props.modalData.document
        ? props.modalData.document.description ?? ""
        : "",
      isDescriptionValid: true, // Optional
      selectedFile: props.modalData.file,
      isReadOnly: props.modalData.isReadOnly ?? false,
    };
  }

  submit = async (evt: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    evt.preventDefault();

    // If viewing as readonly, switch to edit
    if (this.state.isReadOnly) {
      this.setState({ isReadOnly: false });
      return;
    }

    if (!this.state.name && !this.props.modalData.hideNameField) {
      this.props.dispatch(addSimpleErrorAlert("Enter a name"));
    }
    if (!this.props.modalData.isFileOptional && !this.state.selectedFile) {
      this.props.dispatch(addSimpleErrorAlert("Select a file"));
    }

    this.setState({ loading: true });

    try {
      await this.props.modalData.onDocumentUpload(
        this.state.name,
        this.state.description,
        this.state.selectedFile,
        this.state.isDeleteForExistingFile
      );
    } catch (e: any) {
      this.props.dispatch(addDefaultUnknownErrorAlert(e.message));
      this.setState({ loading: false });
      return;
    }

    this.props.dispatch(closeModal());
  };

  render() {
    const {
      isRichText,
      isFileOptional,
      document,
      hideNameField,
      canEdit,
      overrideTitle,
    } = this.props.modalData;

    const {
      name,
      description,
      selectedFile,
      isReadOnly,
      loading,
      isDeleteForExistingFile,
      isDescriptionValid,
    } = this.state;

    const titleText = overrideTitle
      ? overrideTitle
      : document
        ? document.documentName
        : "Add note or document";
    const actionButtonText = document
      ? isReadOnly
        ? "Edit"
        : "Save"
      : "Create";

    const isActionButtonDisabled =
      !isReadOnly &&
      (loading ||
        (!hideNameField && !name) ||
        (!isFileOptional && !selectedFile) ||
        !isDescriptionValid);

    const iconClass = document
      ? isReadOnly
        ? "cr-icon-pencil"
        : "cr-icon-check"
      : "cr-icon-check";

    return (
      <form onSubmit={this.submit as any}>
        <div className={"modal-header"}>
          <h2>{titleText}</h2>
        </div>
        <div className={"modal-content"} style={{ minWidth: 800 }}>
          <table>
            <tbody>
              {!this.props.modalData.hideNameField && (
                <tr>
                  <td>
                    <label htmlFor="name">
                      Title {!isReadOnly && <span>*</span>}
                    </label>
                  </td>
                  <td>
                    {isReadOnly && <span>{name}</span>}
                    {!isReadOnly && (
                      <TextField
                        name="name"
                        required
                        maxLength={MaxLengthType.reasonableLength}
                        value={name}
                        onChanged={(v) => this.setState({ name: v })}
                        placeholder="Untitled"
                      />
                    )}
                  </td>
                </tr>
              )}

              <tr>
                <td>
                  <label htmlFor="description">Description</label>
                  {!isReadOnly && (
                    <p className="label-detail">This is optional.</p>
                  )}
                </td>
                <td>
                  {isReadOnly && isRichText && (
                    <RichTextEditV2 readOnly value={description} />
                  )}
                  {isReadOnly && !isRichText && <span>{description}</span>}
                  {!isReadOnly && isRichText && (
                    <RichTextEditV2
                      value={description}
                      placeholder="Add a description."
                      onChange={(markdown: string) =>
                        this.setState({ description: markdown })
                      }
                    />
                  )}
                  {!isReadOnly && !isRichText && (
                    <TextField
                      name="description"
                      value={description}
                      onChanged={(v, isValid) =>
                        this.setState({
                          description: v,
                          isDescriptionValid: isValid,
                        })
                      }
                      multiLine
                      maxLength={MaxLengthType.message}
                      allowTextOverflow
                      placeholder="Add a description."
                    />
                  )}
                </td>
              </tr>
              {(!isReadOnly || (document && document.gcsObjectName)) && (
                <tr>
                  <td>
                    <label htmlFor="file">File</label>
                    {!isReadOnly && (
                      <>
                        {isFileOptional && (
                          <p className="label-detail">This is optional.</p>
                        )}
                        <p className="label-detail">
                          Max {MaxFileSizeDisplay}.{" "}
                          {AcceptedDocumentFileExtensionsList}.
                        </p>
                      </>
                    )}
                  </td>
                  <td>
                    {!isReadOnly &&
                      (!document ||
                        !document.gcsObjectName ||
                        isDeleteForExistingFile ||
                        selectedFile) && (
                        <DragDropUpload
                          selectedFile={selectedFile}
                          maxFileSize={MaxFileSizeB}
                          acceptedFileTypeFilters={[
                            ...AcceptedDocumentFileExtensions,
                            ...AcceptedDocumentMimeTypes,
                          ]}
                          onFileRejected={() => {
                            this.props.dispatch(
                              addSimpleErrorAlert(
                                `File must be under ${MaxFileSizeDisplay} and be one of the following types: ${AcceptedDocumentFileExtensionsList}`
                              )
                            );
                          }}
                          onFileSelected={(file: File) => {
                            this.setState({
                              selectedFile: file,
                              isDeleteForExistingFile: false,
                              name:
                                !this.state.name ||
                                (this.state.selectedFile &&
                                  this.state.name ===
                                    this.state.selectedFile.name)
                                  ? file.name
                                  : this.state.name,
                            });
                          }}
                        />
                      )}
                    {document &&
                      document.gcsObjectName &&
                      !isDeleteForExistingFile &&
                      !selectedFile && (
                        <AdditionalEvidenceDocument
                          onDeleteDocument={() =>
                            this.setState({ isDeleteForExistingFile: true })
                          }
                          filename={document.filename || ""}
                          deletable={!isReadOnly}
                          virusSafe={!!document.virusSafe}
                          id={document.id}
                          onDownloadDocument={
                            this.props.modalData.onDocumentDownload
                          }
                          uploadedAt={document.uploadedAt || ""}
                          uploadedBy={document.uploadedBy}
                          virusScanned={!!document.virusScanned}
                          name={document.filename || ""}
                          idx={0}
                          downloadable={true}
                          userHasWriteAdditionalEvidencePermission={true}
                        />
                      )}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <div className="modal-footer">
          <Button tertiary onClick={() => this.props.dispatch(closeModal())}>
            Cancel
          </Button>
          <Button
            disabled={isActionButtonDisabled || !canEdit}
            loading={this.state.loading}
            type="submit"
            onClick={this.submit}
          >
            <i className={iconClass} />
            {actionButtonText}
          </Button>
        </div>
      </form>
    );
  }
}

export default DocumentModal;
