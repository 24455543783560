import { ISurveyListItemResponse } from "../../types/survey";
import { ReactNode } from "react";
import DropdownV2, { DropdownItem } from "../core/DropdownV2";
import { getVendorWords } from "../../constants";

import "../../style/components/SurveyDetailsScoreDropdown.scss";
import {
  useConfirmationModalV2,
  useConfirmationModalV2Props,
} from "../modals/ConfirmationModalV2";
import { DefaultThunkDispatch } from "../../types/redux";
import {
  fetchAlertsOrActivityStreamForOrgUser,
  fetchVendorSummaryAndCloudscans,
  fetchVendorSurveyCounts,
  excludeSurveyFromScoring,
} from "../../../vendorrisk/reducers/cyberRiskActions";
import { surveyListPageLimit } from "../../../vendorrisk/views/Questionnaires";
import {
  addDefaultSuccessAlert,
  addDefaultUnknownErrorAlert,
} from "../../reducers/messageAlerts.actions";
import { getSurveyListV2 } from "../../../vendorrisk/reducers/survey.actions";
import { AssuranceType } from "../../types/organisations";

const excludeOrIncludeSurvey = (
  dispatch: DefaultThunkDispatch,
  openConfirmationModal: (props: useConfirmationModalV2Props) => void,
  survey: ISurveyListItemResponse,
  vendorId: number | undefined,
  exclude: boolean
) =>
  openConfirmationModal({
    title: exclude
      ? "Are you sure you want to exclude this questionnaire's score?"
      : "Are you sure you want to include this questionnaire's score?",
    description: exclude ? (
      <p>
        This questionnaire&apos;s score will no longer contribute to the overall
        questionnaire score for the vendor.
      </p>
    ) : (
      <p>
        This questionnaire&apos;s score will contribute to the overall
        questionnaire score for the vendor.
      </p>
    ),
    buttonText: exclude ? "Exclude" : "Include",
    cancelText: "Cancel",
    buttonAction: async () => {
      try {
        await dispatch(excludeSurveyFromScoring(survey.id, exclude));
        const proms: Promise<any>[] = [
          dispatch(
            getSurveyListV2(
              true,
              undefined,
              0,
              surveyListPageLimit,
              "date_due",
              "desc"
            )
          ),
        ];
        if (vendorId) {
          proms.push(
            dispatch(
              getSurveyListV2(
                true,
                vendorId,
                0,
                surveyListPageLimit,
                "date_due",
                "desc"
              )
            )
          );
          proms.push(dispatch(fetchVendorSurveyCounts(vendorId, true)));
        }

        await Promise.all(proms);
        dispatch(fetchAlertsOrActivityStreamForOrgUser(true, true));
        dispatch(fetchVendorSummaryAndCloudscans(vendorId ?? 0, true));
      } catch (e) {
        dispatch(addDefaultUnknownErrorAlert((e as any).message));
        throw e;
      }

      dispatch(
        addDefaultSuccessAlert(
          exclude
            ? "Successfully excluded questionnaire from overall score."
            : "Successfully included questionnaire in overall score"
        )
      );
    },
  });

interface ISurveyDetailsScoreDropdownProps {
  dispatch: DefaultThunkDispatch;
  survey: ISurveyListItemResponse;
  vendorId?: number;
  popupItem: ReactNode;
  assuranceType: AssuranceType;
}

const SurveyDetailsScoreDropdown = ({
  dispatch,
  popupItem,
  survey,
  vendorId,
  assuranceType,
}: ISurveyDetailsScoreDropdownProps) => {
  const [openConfirmationModal, confirmationModalComponent] =
    useConfirmationModalV2();

  const vendorWords = getVendorWords(assuranceType);
  return (
    <>
      <DropdownV2
        className="survey-details-score-dropdown"
        popupItem={popupItem}
      >
        <DropdownItem
          onClick={() => {
            excludeOrIncludeSurvey(
              dispatch,
              openConfirmationModal,
              survey,
              vendorId,
              !survey.excludeFromVendorScore
            );
          }}
        >
          {`${
            survey.excludeFromVendorScore ? "Contribute to" : "Exclude from"
          } ${vendorWords.singular} scoring`}
        </DropdownItem>
      </DropdownV2>
      {confirmationModalComponent}
    </>
  );
};

export default SurveyDetailsScoreDropdown;
