import SlidePanelSection from "../../../vendorrisk/components/filter/SlidePanelSection";
import ColorCheckbox from "../../../vendorrisk/components/ColorCheckbox";

import { FC } from "react";

interface UserRiskScopeReadWriteFilterProps {
  readOnly: boolean;
  readWrite: boolean;
  onChange: (readOnly: boolean, readWrite: boolean) => void;
}

const UserRiskScopeReadWriteFilter: FC<UserRiskScopeReadWriteFilterProps> = ({
  readOnly,
  readWrite,
  onChange,
}) => {
  return (
    <SlidePanelSection title={"Read/write"}>
      <ColorCheckbox
        checked={readOnly}
        label={"Read only"}
        onClick={() => {
          onChange(!readOnly, readWrite);
        }}
      />
      <ColorCheckbox
        checked={readWrite}
        label={"Read/write"}
        onClick={() => {
          onChange(readOnly, !readWrite);
        }}
      />
    </SlidePanelSection>
  );
};

export default UserRiskScopeReadWriteFilter;
