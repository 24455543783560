import PropTypes from "prop-types";
import moment from "moment";

import "../../style/components/core/DateTimeFormat.scss";

const DateTimeFormat = ({ dateTime, dateOnly }) => {
  const dt = moment(dateTime);
  const d = dt.format("ll");
  const t = dateOnly ? null : (
    <span className="secondary-text">{dt.format("HH:mm zz")}</span>
  );
  return (
    <span className="date-time-format">
      {d}
      {t}
    </span>
  );
};

DateTimeFormat.propTypes = {
  dateTime: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  dateOnly: PropTypes.bool,
};

DateTimeFormat.defaultProps = {
  dateOnly: false,
};

export default DateTimeFormat;
