import { PureComponent } from "react";
import { DefaultRootState } from "react-redux";
import { History, Location } from "history";
import { get as _get } from "lodash";
import LoadingBanner from "../../_common/components/core/LoadingBanner";
import { DefaultThunkDispatch } from "../../_common/types/redux";
import { postOAuth2TemporaryCodeCallbackRequest } from "../reducers/oauth.actions";
import { locationState } from "../../_common/types/router";
import "../style/views/OAuth2Callback.scss";
import { appConnect } from "../../_common/types/reduxHooks";

// OAuth2 service names
export const SLACK_SERVICE = "Slack";
export const JIRA_SERVICE = "Jira";

interface IOAuth2CallbackProps {
  history: History;
  location: Location<locationState>;
  dispatch: DefaultThunkDispatch;
  loading: boolean;
  loadingError: string | null;
  error: string | null;
  service: string;
  code: string;
}

interface IOAuth2CallbackState {
  something: string;
}

class OAuth2Callback extends PureComponent<
  IOAuth2CallbackProps,
  IOAuth2CallbackState
> {
  constructor(props: IOAuth2CallbackProps) {
    super(props);
    if (!this.props.error && !!this.props.service && !!this.props.code) {
      props.dispatch(
        postOAuth2TemporaryCodeCallbackRequest(props.code, props.service)
      );
    } else {
      let error = this.props.error ? this.props.error : null;
      if (!this.props.code || !this.props.service) {
        error = "incorrect parameters have been supplied";
      }
      this.props.history.push("settings/integrations", {
        oauth2Error: `Failed to process ${this.props.service} workspace integration: ${error}`,
      });
    }
  }

  componentDidUpdate(prevProps: Readonly<IOAuth2CallbackProps>) {
    if (
      prevProps.loading == true &&
      this.props.loading == false &&
      !this.props.loadingError
    ) {
      // loading completed.
      this.props.history.push("/settings/integrations", {
        oauth2Success: `${this.props.service} workspace successfully enabled`,
      });
    }

    if (
      prevProps.loading == true &&
      this.props.loading == false &&
      this.props.loadingError
    ) {
      this.props.history.push("/settings/integrations", {
        oauth2Error: `Failed to process ${this.props.service} workspace integration: ${this.props.loadingError}`,
      });
    }
  }

  messageForService = (service: string) => {
    switch (service) {
      case SLACK_SERVICE:
        return "Adding Slack workspace";
    }
    return `Adding ${service}`;
  };

  render() {
    return (
      <div className={"oauth2-callback"}>
        <div className={"working"}>
          {this.messageForService(this.props.service)}
        </div>
        <LoadingBanner />
      </div>
    );
  }
}

export default appConnect(
  (state: DefaultRootState, props: IOAuth2CallbackProps) => {
    const query = new URLSearchParams(props.location.search);
    const errorStr = query.get("error") || null;
    const oauth2Code = query.get("code") || "";
    const oauthService = query.get("service") || "";

    const loading = _get(
      state.cyberRisk,
      `oauthConnections[${oauthService}].loading`,
      false
    );

    const loadingError = _get(
      state.cyberRisk,
      `oauthConnections[${oauthService}].error`,
      null
    );

    return {
      service: oauthService,
      error: errorStr,
      code: oauth2Code,
      loading: loading,
      loadingError: loadingError,
    };
  }
)(OAuth2Callback);
