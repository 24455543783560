export enum ValidationError {
  rootNodeMissingTitle = "ROOT_NODE_MISSING_TITLE",
  rootNodeTitleTooLong = "ROOT_NODE_TITLE_TOO_LONG",
  nodeMissingNumber = "NODE_MISSING_NUMBER",
  nodeNonUniqueNumber = "NODE_NON_UNIQUE_NUMBER",
  sectionMissingText = "SECTION_MISSING_TEXT",
  selectNeedsMoreAnswers = "SELECT_NEEDS_MORE_ANSWERS",
  selectAnswerMissingText = "SELECT_ANSWER_MISSING_TEXT",
  riskMissingName = "RISK_MISSING_NAME",
  riskMissingPassName = "RISK_MISSING_PASS_NAME",
  riskMissingMainText = "RISK_MISSING_MAIN_TEXT",
  riskMissingCategory = "RISK_MISSING_CATEGORY",
  riskMissingWhy = "RISK_MISSING_WHY",
  conditionalInvalidNodeId = "CONDITIONAL_INVALID_NODE_ID",
  conditionalInvalidAnswerId = "CONDITIONAL_INVALID_ANSWER_ID",
}

export interface ValidationErrors {
  invalidNodeIds: string[];
  errors: {
    [nodeId: string]:
      | {
          [validationError: string]: true; // The keys should map to the ValidationError enum
        }
      | undefined;
  };
}
