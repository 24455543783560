import { DefaultRouteProps, locationState } from "../../_common/types/router";
import { cloneDeep as _cloneDeep, get as _get } from "lodash";
import {
  VendorOverlayInjectedProps,
  wrapInVendorOverlay,
} from "./VendorOverlayWrapper";
import { DefaultThunkDispatchProp } from "../../_common/types/redux";
import { FC, ReactNode, useEffect, useState, VFC } from "react";
import {
  EvidenceCategory,
  EvidenceSource,
  VendorEvidencePage,
} from "../types/evidence";
import {
  getUserPermissionsForVendorFromState,
  UserBreachsightWrite,
  UserSystemRoleVendorManagementAnalyst,
  UserVendorRiskWrite,
  UserWriteAdditionalEvidence,
} from "../../_common/permissions";

import {
  deleteEvidencePage,
  fetchEvidencePagesForVendor,
  updateEvidencePage,
  updateEvidencePageRequest,
} from "../reducers/vendorEvidencePages.actions";
import { getVendorWords } from "../../_common/constants";
import {
  fetchVendorSummaryAndCloudscans,
  fetchVendorWatchStatus,
  updateVendorWatchStatusWithRefresh,
} from "../reducers/cyberRiskActions";
import { InfoBar } from "../../_common/components/InfoBar";
import { getVendorPageBreadcrumbs } from "../../_common/components/Breadcrumbs";
import PageHeader from "../../_common/components/PageHeader";
import ReportCard from "../../_common/components/ReportCard";
import Button from "../../_common/components/core/Button";
import LoadingIcon from "../../_common/components/core/LoadingIcon";
import {
  formatDateAsLocal,
  validateUrlWithProtocol,
} from "../../_common/helpers";
import {
  evidenceCategoryToExampleUrl,
  evidenceCategoryToStr,
} from "../components/shared_profile/SharedProfileEvidencePagesTable";
import "../style/views/EvidencePageDetails.scss";
import InfoTable, { InfoTableRow } from "../../_common/components/InfoTable";
import PillLabel from "../components/PillLabel";
import { LabelColor } from "../../_common/types/label";
import { ExternalLink } from "../../_common/components/ExternalLink";
import UserAvatar from "../../_common/components/UserAvatar";
import CompanyLogo from "../../_common/components/CompanyLogo";
import {
  addDefaultSuccessAlert,
  addDefaultUnknownErrorAlert,
  addSimpleErrorAlert,
} from "../../_common/reducers/messageAlerts.actions";
import { LockedText } from "../../_common/components/LockedText";
import { useConfirmationModalV2 } from "../../_common/components/modals/ConfirmationModalV2";
import { AssuranceType } from "../../_common/types/organisations";
import { SelectV2 } from "../../_common/components/SelectV2";
import TextField from "../../_common/components/TextField";
import Markdown from "../../_common/components/core/Markdown";
import { SidePopupV2 } from "../../_common/components/DismissablePopup";
import InfoBanner, { BannerType } from "../components/InfoBanner";
import RichTextEditV2 from "../../_common/components/RichTextEditV2";
import { appConnect } from "../../_common/types/reduxHooks";
import VendorAssessmentAPI, {
  invalidateVendorAssessmentDraftsForVendor,
} from "../reducers/vendorAssessmentAPI";
import { skipToken } from "@reduxjs/toolkit/query";
import { GetQueryParams } from "../../_common/query";

const findPage = (
  category: EvidenceCategory,
  url: string,
  pages?: VendorEvidencePage[]
) => pages?.find((ev) => ev.category === category && ev.url === url);

const findPageIndex = (
  category: EvidenceCategory,
  url: string,
  pages?: VendorEvidencePage[]
) => pages?.findIndex((ev) => ev.category === category && ev.url === url) ?? -1;

type EvidencePageDetailDisplayMode = "details" | "edit" | "add";

export const evidencePagesPrefix = (
  vendorId: number,
  isAnalystSession?: boolean,
  managedOrgId?: number
) =>
  isAnalystSession
    ? `/analysts/tpvm/${managedOrgId}/${vendorId}/evidence/details`
    : `/vendor/${vendorId}/evidence/pages`;

export const evidencePageUrl = (
  vendorId: number,
  displayMode: EvidencePageDetailDisplayMode,
  isAnalystSession?: boolean,
  managedOrgId?: number,
  evidencePage?: VendorEvidencePage
) => {
  const prefix = evidencePagesPrefix(vendorId, isAnalystSession, managedOrgId);
  const query = `category=${evidencePage?.category}&url=${encodeURIComponent(
    evidencePage?.url || ""
  )}`;
  switch (displayMode) {
    case "add":
      return `${prefix}/add`;
    case "edit":
      return `${prefix}/edit?${query}`;
    case "details":
      return `${prefix}/details?${query}`;
  }
};

const breadcrumbText = (displayMode: EvidencePageDetailDisplayMode) => {
  switch (displayMode) {
    case "edit":
      return "Edit page";
    case "add":
      return "Add page";
    case "details":
      return "Page details";
  }
};

const pageTitle = (
  displayMode: EvidencePageDetailDisplayMode,
  evidencePage?: VendorEvidencePage
) => {
  switch (displayMode) {
    case "details":
      return evidencePage
        ? evidenceCategoryToStr(evidencePage.category)
        : "Page details";
    case "add":
      return "Add page";
    case "edit":
      return "Edit page";
  }
};

export const errorMessagesForUrl = (url: string) => {
  const errorMsgs: string[] = [];
  if (!url) {
    errorMsgs.push("URL is required");
  } else if (!validateUrlWithProtocol(url)) {
    if (!url.startsWith("http")) {
      errorMsgs.push("URL must start with http or https");
    } else {
      errorMsgs.push("Invalid URL");
    }
  }
  return errorMsgs;
};

interface CreatedByProps {
  evidencePage?: VendorEvidencePage;
  vendorName: string;
  vendorPrimaryHostname: string;
}

const CreatedBy: FC<CreatedByProps> = ({
  evidencePage,
  vendorName,
  vendorPrimaryHostname,
}) => {
  if (!evidencePage) return <></>;

  switch (evidencePage.source) {
    case EvidenceSource.VendorRisk:
      return (
        <div className="avatar-name">
          <UserAvatar avatar={evidencePage.createdByAvatar} />
          {evidencePage.createdByName} ({evidencePage.createdByEmail}){" on "}
          {formatDateAsLocal(evidencePage.createdAt)}
        </div>
      );
    case EvidenceSource.VerifiedVendor:
      return (
        <div className="avatar-name">
          <CompanyLogo
            domain={vendorPrimaryHostname}
            name={vendorName}
            displayName={true}
            displayDefault={true}
          />
          {" on "}
          {formatDateAsLocal(evidencePage.createdAt)}
        </div>
      );
    case EvidenceSource.UpGuard:
      return (
        <div className="avatar-name">
          <CompanyLogo
            domain={"upguard.com"}
            name={"UpGuard"}
            displayName={true}
            displayDefault={true}
          />
          {" on "}
          {formatDateAsLocal(evidencePage.createdAt)}
        </div>
      );
  }
};

interface MonitorVendorProps {
  assuranceType: AssuranceType;
  watchedStateLoading: boolean;
  onMonitorVendor: () => void;
}

const MonitorVendor: FC<MonitorVendorProps> = ({
  assuranceType,
  watchedStateLoading,
  onMonitorVendor,
}) => {
  const vendorWords = getVendorWords(assuranceType);

  return (
    <ReportCard newStyles className="evidence-page-details-card">
      <div className="empty-content">
        <div
          className={"text"}
        >{`This ${vendorWords.singular} must be monitored in order to access security and privacy pages.`}</div>
        <Button loading={watchedStateLoading} onClick={onMonitorVendor}>
          Monitor this ${vendorWords.singular}
        </Button>
      </div>
    </ReportCard>
  );
};

const LoadingState = () => (
  <ReportCard newStyles className="evidence-page-details-card-loading">
    <LoadingIcon />
  </ReportCard>
);

interface ErrorProps {
  error: any;
}

const ErrorState: FC<ErrorProps> = ({ error }) => {
  return (
    <ReportCard newStyles className="evidence-page-details-card-loading">
      <div className="empty-content">
        <div className={"text"}>{error.errorText}</div>
        <Button onClick={error.actionOnClick}>{error.actionText}</Button>
      </div>
    </ReportCard>
  );
};

const NoDataState: FC = () => {
  return (
    <ReportCard newStyles className="evidence-page-details-card">
      <div className="empty-content">
        <div className={"text"}>
          {"We have no data for this security and privacy page."}
        </div>
      </div>
    </ReportCard>
  );
};

interface CategoryProps {
  displayMode: EvidencePageDetailDisplayMode;
  evidencePage?: VendorEvidencePage;
  disabled: boolean;
  onCategoryChange?: (newCat: EvidenceCategory) => void;
}

const Category: FC<CategoryProps> = ({
  displayMode,
  evidencePage,
  disabled,
  onCategoryChange,
}) => {
  if (!evidencePage) return <></>;

  switch (displayMode) {
    case "details":
      return (
        <PillLabel color={LabelColor.Blue}>
          {evidenceCategoryToStr(evidencePage.category)}
        </PillLabel>
      );
    case "edit":
    // explict fallthrough
    case "add":
      switch (evidencePage.source) {
        case EvidenceSource.VendorRisk:
          return (
            <SelectV2
              className={"category-selector"}
              options={Object.values(EvidenceCategory).map((c) => ({
                value: c,
                label: evidenceCategoryToStr(c),
              }))}
              onChange={(val) => {
                if (onCategoryChange && val)
                  onCategoryChange(val.value as EvidenceCategory);
              }}
              isDisabled={disabled}
              value={{
                value: evidencePage.category,
                label: evidenceCategoryToStr(evidencePage.category),
              }}
            />
          );
        case EvidenceSource.VerifiedVendor:
        // explict fallthrough
        case EvidenceSource.UpGuard:
          return (
            <LockedText
              text={evidenceCategoryToStr(evidencePage.category)}
              popupText={"This category cannot be edited"}
              className={"locked-category"}
            />
          );
      }
  }
};

interface LinkProps {
  displayMode: EvidencePageDetailDisplayMode;
  evidencePage?: VendorEvidencePage;
  vendorId?: number;
  disabled: boolean;
  onUrlChange?: (newUrl: string) => void;
  category: EvidenceCategory;
}

const Link: FC<LinkProps> = ({
  displayMode,
  evidencePage,
  vendorId,
  disabled,
  onUrlChange,
  category,
}) => {
  if (!evidencePage) return <></>;

  switch (displayMode) {
    case "details":
      return (
        <ExternalLink
          url={evidencePage.url}
          trackInfo={{
            eventName: "VendorEvidencePages_EvidencePageClicked",
            properties: {
              url: evidencePage.url,
              vendorId: vendorId,
            },
          }}
        />
      );
    case "edit":
    // explict fallthrough
    case "add":
      switch (evidencePage.source) {
        case EvidenceSource.VendorRisk:
          return (
            <TextField
              className={"link-field"}
              value={evidencePage.url}
              onChanged={(val) => {
                if (onUrlChange) onUrlChange(val);
              }}
              placeholder="Enter a URL"
              disabled={disabled}
              errorsTimeout={0}
              errorTexts={errorMessagesForUrl(evidencePage.url)}
              infoText={`E.g. ${evidenceCategoryToExampleUrl(category)}`}
            />
          );
        case EvidenceSource.VerifiedVendor:
        // explict fallthrough
        case EvidenceSource.UpGuard:
          return (
            <LockedText
              text={evidencePage.url}
              popupText={"This URL cannot be edited"}
              className={"locked-url"}
            />
          );
      }
  }
};

interface CommentsProps {
  displayMode: EvidencePageDetailDisplayMode;
  note?: string;
  disabled: boolean;
  onNoteChange?: (newNote: string) => void;
}

const Comments: FC<CommentsProps> = ({
  displayMode,
  note,
  disabled,
  onNoteChange,
}) => {
  switch (displayMode) {
    case "details":
      // remove all zero width characters from the string
      // and trim all white space before checking if it's empty
      const trimmedNote = (note || "")
        .replace(/[\u200B-\u200D\uFEFF]/g, "")
        .trim();
      if (trimmedNote === "") {
        return <></>;
      }
      return (
        <div className={"rendered-comments"}>
          <Markdown content={note || ""} />
        </div>
      );
    case "edit":
    case "add":
      return (
        <RichTextEditV2
          value={note || ""}
          onChange={(newNote) => {
            if (onNoteChange) onNoteChange(newNote);
          }}
          disabled={disabled}
          placeholder={"Record your notes and observations about this page"}
        />
      );
  }
};

interface matchParams {
  orgId?: string;
  vendorId: string;
}

interface EvidencePageDetailsOwnProps
  extends DefaultRouteProps<matchParams, locationState> {
  displayMode: EvidencePageDetailDisplayMode;
}

interface EvidencePageDetailsConnectedProps {
  userIsManagedVendorAnalyst?: boolean;
  userHasWriteVendorInfoPermission: boolean;
  userHasWriteAdditionalEvidencePermission: boolean;
  managedOrgId?: number;
  evidencePages?: VendorEvidencePage[];
  assessmentId?: number;
  loading: boolean;
  error: any;
}

type EvidencePageDetailsProps = EvidencePageDetailsOwnProps &
  EvidencePageDetailsConnectedProps &
  VendorOverlayInjectedProps &
  DefaultThunkDispatchProp;

const EvidencePageDetails: VFC<EvidencePageDetailsProps> = ({
  location,
  evidencePages,
  vendorId,
  dispatch,
  history,
  vendorName,
  vendorPrimaryHostname,
  assuranceType,
  isManagementAnalystSession,
  managedOrgId,
  loading,
  error,
  watching,
  userHasWriteAdditionalEvidencePermission,
  displayMode = "details",
  assessmentId,
}) => {
  const params = GetQueryParams(location.search);
  const category = params?.category;
  const url = params?.url ?? "";
  const isShowingAssessmentEvidence =
    params?.assessment_id && params?.assessment_id > 0;

  const [openConfirmationModal, confirmationModal] = useConfirmationModalV2();

  const [watchedStateLoading, setWatchStateLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isEdited, setIsEdited] = useState(false);

  useEffect(() => {
    // reload the data on landing
    if (vendorId) {
      dispatch(fetchVendorWatchStatus(vendorId));
    }

    if (vendorId && watching) {
      dispatch(fetchEvidencePagesForVendor(vendorId, true));

      if (isShowingAssessmentEvidence) {
        // we are watching an evidence from a risk assessment
        // so make sure we fetch the assessment data
        dispatch(invalidateVendorAssessmentDraftsForVendor(vendorId));
      }
    }
  }, [vendorId, watching, isShowingAssessmentEvidence]);

  const isDetailsMode = displayMode === "details";
  const isEditMode = displayMode === "edit";
  const isAddMode = displayMode === "add";

  // we initialise the evidence page with a placeholder evidence
  // if we are not in add mode this will be replaced with an actual evidence page
  // in the useEffect below once the evidence pages finish loading
  const placeHolderEvidence: VendorEvidencePage = {
    category: EvidenceCategory.Security,
    url: "",
    source: EvidenceSource.VendorRisk,
  };

  const [evidencePage, setEvidencePage] = useState<
    VendorEvidencePage | undefined
  >(placeHolderEvidence);
  const [evidencePageIdx, setEvidencePageIdx] = useState<number>(-1);

  const { data: assessment } =
    VendorAssessmentAPI.useGetVendorAssessmentDataQuery(
      assessmentId && vendorId
        ? { vendorID: vendorId, versionID: assessmentId }
        : skipToken
    );

  useEffect(() => {
    if (!isAddMode) {
      if (isShowingAssessmentEvidence && assessment?.evidence.pages) {
        setEvidencePage(
          _cloneDeep(findPage(category, url, assessment.evidence.pages))
        );
        setEvidencePageIdx(
          findPageIndex(category, url, assessment.evidence.pages)
        );
      } else if (evidencePages) {
        setEvidencePage(_cloneDeep(findPage(category, url, evidencePages)));
        setEvidencePageIdx(findPageIndex(category, url, evidencePages));
      }
    }
  }, [evidencePages, assessment?.evidence.pages]);

  const vendorWords = getVendorWords(assuranceType);

  const monitorVendor = () => {
    setWatchStateLoading(true);
    return dispatch(
      updateVendorWatchStatusWithRefresh(
        vendorId,
        true,
        history,
        [],
        vendorName,
        vendorPrimaryHostname
      )
    )
      .then(() => dispatch(fetchEvidencePagesForVendor(vendorId ?? 0, true)))
      .finally(() => setWatchStateLoading(false));
  };

  const isDeletableSource = evidencePage?.source === EvidenceSource.VendorRisk;

  const deletePage = () => {
    if (!vendorId || !evidencePage || !isDeletableSource) return;

    setIsDeleting(true);
    dispatch(
      deleteEvidencePage({
        vendorId: vendorId,
        category: evidencePage?.category,
        url: evidencePage?.url,
      })
    )
      .then(() =>
        dispatch(
          addDefaultSuccessAlert(
            "Successfully deleted security and privacy page"
          )
        )
      )
      .then(() => history.push(`/vendor/${vendorId}/evidence/pages`))
      .then(() => {
        dispatch(fetchVendorSummaryAndCloudscans(vendorId, true));
      })
      .catch(() => {
        dispatch(
          addDefaultUnknownErrorAlert(
            "Error deleting security and privacy page"
          )
        );
      })
      .finally(() => {
        setIsDeleting(false);
      });
  };

  const updateEvidencePageNote = (newNote: string) => {
    if (!evidencePage) return;
    const newEvidencePage = { ...evidencePage };
    newEvidencePage.note = newNote;
    setEvidencePage(newEvidencePage);
    setIsEdited(true);
  };

  const updateEvidencePageCategory = (newCategory: EvidenceCategory) => {
    if (!evidencePage) return;
    const newEvidencePage = { ...evidencePage };
    newEvidencePage.category = newCategory;
    setEvidencePage(newEvidencePage);
    setIsEdited(true);
  };

  const updateEvidencePageUrl = (newUrl: string) => {
    if (!evidencePage) return;
    const newEvidencePage = { ...evidencePage };
    newEvidencePage.url = newUrl;
    setEvidencePage(newEvidencePage);
    setIsEdited(true);
  };

  const navigateToEditPage = () => {
    if (!vendorId || !evidencePage) return;

    const editUrl = evidencePageUrl(
      vendorId,
      "edit",
      isManagementAnalystSession,
      managedOrgId,
      evidencePage
    );
    history.push(editUrl, {
      backContext: {
        backTo: history.location.pathname,
        backToText: `Back to security and privacy pages`,
      },
    });
  };

  const cancelEdit = () => {
    if (!vendorId) return;

    if (isAddMode) {
      history.push(
        evidencePagesPrefix(vendorId, isManagementAnalystSession, managedOrgId)
      );
    } else {
      const detailsUrl = evidencePageUrl(
        vendorId,
        "details",
        isManagementAnalystSession,
        managedOrgId,
        evidencePage
      );
      history.push(detailsUrl);
    }
  };

  const saveEditedPage = () => {
    if (!vendorId || !evidencePage || !isEdited) return;

    if (isAddMode) {
      // if we are in add mode we want to make sure we are not about to
      // add a page that already exists, same category and url of an
      // existing evidence page
      const existingIdx =
        evidencePages?.findIndex(
          (ev) =>
            ev.category === evidencePage.category &&
            ev.url.toLowerCase() === evidencePage.url.toLowerCase()
        ) ?? -1;
      if (existingIdx > -1) {
        dispatch(
          addSimpleErrorAlert(
            "A security and privacy page with that category and url already exists"
          )
        );
        return;
      }
    } else if (isEditMode) {
      // if we are in edit mode we want to make sure we are not changing
      // the page to something that is a duplicate of another existing page
      const existingIdx =
        evidencePages?.findIndex(
          (ev) =>
            ev.category === evidencePage.category &&
            ev.url.toLowerCase() === evidencePage.url.toLowerCase()
        ) ?? -1;
      if (existingIdx > -1 && existingIdx != evidencePageIdx) {
        dispatch(
          addSimpleErrorAlert(
            "A security and privacy page with that category and url already exists"
          )
        );
        return;
      }
    }

    let updateEvidenceRequest: updateEvidencePageRequest;
    if (displayMode === "edit") {
      // if we are in edit mode the user might have changed the category or URL of the page,
      // so we make the request with old category and old url from the query params
      // fetched above so that the backend knows what page to update
      updateEvidenceRequest = {
        vendorId: vendorId,
        oldCategory: category,
        oldUrl: url,
        newCategory: evidencePage.category,
        newUrl: evidencePage.url,
        newNote: evidencePage?.note || "",
      };
    } else {
      // if we are in add mode than there is no old category and old url,
      // so we simply match them to the category and url we are about to add
      updateEvidenceRequest = {
        vendorId: vendorId,
        oldCategory: evidencePage.category,
        oldUrl: evidencePage.url,
        newCategory: evidencePage.category,
        newUrl: evidencePage.url,
        newNote: evidencePage?.note || "",
      };
    }

    setIsSaving(true);
    dispatch(updateEvidencePage(updateEvidenceRequest))
      .then(() =>
        dispatch(
          addDefaultSuccessAlert("Successfully saved security and privacy page")
        )
      )
      .then(() => {
        // navigate back to the details page for the evidence we just saved
        const detailsUrl = evidencePageUrl(
          vendorId,
          "details",
          isManagementAnalystSession,
          managedOrgId,
          evidencePage
        );
        history.push(detailsUrl);
      })
      .then(() => {
        dispatch(fetchVendorSummaryAndCloudscans(vendorId, true));
        dispatch(invalidateVendorAssessmentDraftsForVendor(vendorId));
      })
      .catch(() => {
        dispatch(
          addDefaultUnknownErrorAlert("Error saving security and privacy page")
        );
      })
      .finally(() => {
        setIsSaving(false);
      });
  };

  const isMonitoredVendor = vendorId && !watchedStateLoading && watching;
  const isLoadingPages = isMonitoredVendor && loading;
  const isErrorWhileLoading = isMonitoredVendor && !loading && error;
  const isFailedToFindPage =
    isMonitoredVendor && !loading && !error && !evidencePage;
  const hasSuccessfullyLoaded =
    isMonitoredVendor && !loading && !error && evidencePage;

  let infoBanner: ReactNode | undefined = undefined;
  if (isShowingAssessmentEvidence) {
    const underlyingEvidencePage = (evidencePages ?? []).find(
      (ev) =>
        ev.category === category && ev.url.toLowerCase() === url.toLowerCase()
    );

    const isOutdatedEvidencePage =
      underlyingEvidencePage &&
      (underlyingEvidencePage?.updatedAt ?? "") >
        (evidencePage?.updatedAt ?? "");
    const onClick = () => {
      const detailsUrl = evidencePageUrl(
        vendorId ?? 0,
        "details",
        isManagementAnalystSession,
        managedOrgId,
        evidencePage
      );
      history.push(detailsUrl, {
        backContext: {
          backTo: `${location.pathname}${location.search || ""}`,
          backToText: "Back to evidence page details",
        },
      });
    };
    infoBanner = (
      <InfoBanner
        className={"old-version-banner"}
        type={BannerType.WARNING}
        overrideIcon={BannerType.INFO}
        message={`This is a snapshot of the evidence${
          assessment?.assessment.publishedAt
            ? " on " + formatDateAsLocal(assessment?.assessment.publishedAt)
            : ""
        }`}
        button={
          isOutdatedEvidencePage ? (
            <div onClick={onClick}>
              View latest version{" "}
              <span className={"cr-icon-arrow-right"}></span>
            </div>
          ) : undefined
        }
      />
    );
  }

  const showDeleteButton =
    userHasWriteAdditionalEvidencePermission &&
    isDeletableSource &&
    !isShowingAssessmentEvidence &&
    isDetailsMode;
  const showEditButton =
    userHasWriteAdditionalEvidencePermission &&
    !isShowingAssessmentEvidence &&
    isDetailsMode;
  const showCancelButton =
    userHasWriteAdditionalEvidencePermission && (isEditMode || isAddMode);
  const showSaveChangesButton =
    userHasWriteAdditionalEvidencePermission && (isEditMode || isAddMode);

  const evidenceIsValid =
    evidencePage && validateUrlWithProtocol(evidencePage.url);

  return (
    <div id="evidence-page-details-view">
      {isManagementAnalystSession && (
        <InfoBar
          message={
            "You are viewing a vendor’s profile as an Analyst (Managed Vendor Assessment)"
          }
        />
      )}
      <PageHeader
        history={history}
        title="Security and privacy pages"
        vendorId={vendorId}
        breadcrumbs={[
          ...getVendorPageBreadcrumbs(
            vendorId ?? 0,
            vendorName || vendorWords.singularTitleCase,
            assuranceType
          ),
          {
            text: "Security and privacy pages",
            to: `/vendor/${vendorId}/evidence/pages`,
          },
          { text: breadcrumbText(displayMode) },
        ]}
        backAction={
          location.state?.backContext?.goBack
            ? history.goBack
            : location.state && location.state.backContext
              ? () =>
                  history.push(
                    location.state.backContext?.backTo || "",
                    location.state?.backContext?.backToContext
                  )
              : () =>
                  history.push(
                    evidencePagesPrefix(
                      vendorId || 0,
                      isManagementAnalystSession,
                      managedOrgId
                    )
                  )
        }
        backText={
          location.state && location.state.backContext
            ? location.state.backContext.backToText
            : "Security and privacy pages"
        }
        isManagementAnalystSession={isManagementAnalystSession}
        managedOrgId={managedOrgId}
      />
      {!isMonitoredVendor && (
        <MonitorVendor
          assuranceType={assuranceType}
          onMonitorVendor={monitorVendor}
          watchedStateLoading={watchedStateLoading}
        />
      )}
      {isLoadingPages && <LoadingState />}
      {isErrorWhileLoading && <ErrorState error={error} />}
      {!isAddMode && isFailedToFindPage && <NoDataState />}
      {hasSuccessfullyLoaded && (
        <>
          {infoBanner}
          <ReportCard newStyles className="evidence-page-details-card">
            <div className="header">
              <div className={"title"}>
                {pageTitle(displayMode, evidencePage)}
              </div>
              {showDeleteButton && (
                <Button
                  onClick={() =>
                    openConfirmationModal({
                      title: `Delete security and privacy page`,
                      description:
                        "Are you sure you want to delete this security and privacy page? It will be completely removed for the vendor.",
                      dangerousAction: true,
                      cancelText: "Cancel",
                      buttonText: "Yes, delete",
                      buttonAction: deletePage,
                    })
                  }
                  disabled={isDeleting}
                  loading={isDeleting}
                  danger
                >
                  <div className="cr-icon-trash" /> Delete
                </Button>
              )}
              {showEditButton && (
                <Button
                  onClick={() => navigateToEditPage()}
                  disabled={isDeleting}
                  loading={isDeleting}
                >
                  <div className="cr-icon-pencil" /> Edit
                </Button>
              )}
              {showCancelButton && (
                <Button
                  onClick={cancelEdit}
                  disabled={isSaving}
                  loading={isSaving}
                >
                  Cancel
                </Button>
              )}
              {showSaveChangesButton && (
                <Button
                  onClick={saveEditedPage}
                  disabled={isSaving || !isEdited || !evidenceIsValid}
                  loading={isSaving}
                  filledPrimary
                >
                  <div className="cr-icon-check" /> Save changes
                </Button>
              )}
            </div>
            <div className={"content"}>
              <InfoTable className={"details-table"} bordered>
                <InfoTableRow
                  label={"Page category"}
                  value={
                    <Category
                      displayMode={displayMode}
                      disabled={isSaving}
                      evidencePage={evidencePage}
                      onCategoryChange={updateEvidencePageCategory}
                    />
                  }
                  rowClass={"category-row"}
                />
                <InfoTableRow
                  label={displayMode === "details" ? "Link" : "URL"}
                  value={
                    <Link
                      displayMode={displayMode}
                      evidencePage={evidencePage}
                      disabled={isSaving}
                      vendorId={vendorId}
                      onUrlChange={updateEvidencePageUrl}
                      category={evidencePage.category}
                    />
                  }
                  rowClass={"link-row"}
                />
                <InfoTableRow
                  label={
                    <>
                      <span>Comments</span>
                      {displayMode !== "details" && (
                        <SidePopupV2
                          position={"right"}
                          text={`Record your notes and observations about the content of the page. Note anything specific that might relate to your risk assessment of this ${vendorWords.singular}.`}
                        >
                          <span className="cr-icon-info" />
                        </SidePopupV2>
                      )}
                    </>
                  }
                  value={
                    <Comments
                      displayMode={displayMode}
                      note={evidencePage.note}
                      disabled={isSaving}
                      onNoteChange={updateEvidencePageNote}
                    />
                  }
                  rowClass={"comments-row"}
                />
                {isDetailsMode && !!evidencePage.updatedByName && (
                  <InfoTableRow
                    label={"Last updated by"}
                    value={
                      <div className="avatar-name">
                        <UserAvatar avatar={evidencePage.updatedByAvatar} />
                        {evidencePage.updatedByName} (
                        {evidencePage.updatedByEmail}){" on "}
                        {formatDateAsLocal(evidencePage.updatedAt)}
                      </div>
                    }
                    rowClass={"updated-row"}
                  />
                )}
                {isDetailsMode && (
                  <InfoTableRow
                    label={"Created by"}
                    value={
                      <CreatedBy
                        evidencePage={evidencePage}
                        vendorName={vendorName}
                        vendorPrimaryHostname={vendorPrimaryHostname}
                      />
                    }
                    rowClass={"created-row"}
                  />
                )}
              </InfoTable>
            </div>
          </ReportCard>
        </>
      )}
      {confirmationModal}
    </div>
  );
};

export default wrapInVendorOverlay(
  appConnect<
    EvidencePageDetailsConnectedProps,
    never,
    EvidencePageDetailsOwnProps & VendorOverlayInjectedProps
  >((state, props) => {
    const userSystemRoles = state.common.userData.system_roles.reduce(
      (prev, perm) => {
        prev[perm] = true;
        return prev;
      },
      {} as Record<string, boolean>
    );

    const userIsManagedVendorAnalyst =
      !!userSystemRoles[UserSystemRoleVendorManagementAnalyst];

    const isManagedVendorAnalyst =
      userIsManagedVendorAnalyst &&
      props.match.path.startsWith("/analysts/tpvm") &&
      !!props.managedOrgId &&
      props.managedOrgId > 0;

    let data;
    if (props.vendorId) {
      if (isManagedVendorAnalyst) {
        data = _get(
          state.cyberRisk.managedVendorData,
          `[${props.managedOrgId}][${props.vendorId}]`,
          {}
        );
      } else {
        data = _get(state.cyberRisk, `vendors.${props.vendorId}`, {});
      }
    } else {
      data = state.cyberRisk.customerData;
    }

    const evidencePages = _get(data, "evidencePages.data", []);

    const isSubsidiary = props.match.path.startsWith("/subsidiaries");
    const isBreachSight = !props.vendorId || isSubsidiary;
    const isVendor = !!props.vendorId && !isSubsidiary;
    const userPerms = getUserPermissionsForVendorFromState(
      state,
      isVendor ? props.vendorId || 0 : 0
    );

    const userHasWriteVendorInfoPermission =
      (isBreachSight && !!userPerms[UserBreachsightWrite]) ||
      (isVendor && !!userPerms[UserVendorRiskWrite]);

    let managedOrgId;
    if (isManagedVendorAnalyst) {
      managedOrgId = parseInt(props.match.params.orgId || "");
    }

    const params = GetQueryParams(location.search);
    const assessmentId = parseInt(params?.assessment_id ?? "0");

    return {
      evidencePages: evidencePages,
      assessmentId,
      loading: _get(data, "evidencePages.loading", true),
      error: _get(data, "evidencePages.error", null),
      userIsManagedVendorAnalyst: userIsManagedVendorAnalyst,
      userHasWriteVendorInfoPermission: userHasWriteVendorInfoPermission,
      userHasWriteAdditionalEvidencePermission:
        !!userPerms[UserWriteAdditionalEvidence] || isManagedVendorAnalyst,
      managedOrgId: managedOrgId,
    };
  })(EvidencePageDetails)
);
