import { FC, useEffect } from "react";
import { Route, Switch, useHistory } from "react-router-dom";
import RemediationRequestDetailsV2 from "../views/RemediationRequestDetailsV2";
import { TaskRemediationRequestList } from "../../vendor_portal/views/TaskRemediationRequestList";
import { locationState } from "../types/router";
import { History, Location } from "history";
import { useAppDispatch, useAppSelector } from "../types/reduxHooks";
import {
  fetchVendorAppAcceptedRisks,
  fetchVendorAppRemediationRequestList,
  fetchVendorAppSurveyList,
} from "../../vendor_portal/reducers/actions";
import { RemediationType } from "../types/remediation";
import { getVendorRiskWaiversAndAdjustmentsToApprove } from "../../vendor_portal/reducers/vendorRiskWaiver.actions";
import RiskWaiverApprovalsList from "../../vendor_portal/views/RiskWaiverApprovalsList";
import RiskAdjustmentApprovalsList from "../../vendor_portal/views/RiskAdjustmentApprovalsList";
import VendorSurveys from "../../vendor_portal/views/VendorSurveys";
import {
  fetchOwnSharedAssessmentAccessIfNecessary,
  fetchOwnSharedAssessmentIfNecessary,
} from "../../vendorrisk/reducers/cyberRiskActions";
import { getUserLastActive } from "../session";
import AdditionalEvidenceRequests from "../../vendor_portal/views/AdditionalEvidenceRequests";
import VendorPortalAdditionalEvidenceDetails from "../../vendor_portal/views/AdditionalEvidenceDetails";
import { fetchVendorPortalAdditionalEvidenceRequestSummaries } from "../../vendor_portal/reducers/additionalEvidenceRequest.actions";
import SurveyDetails from "../views/SurveyDetails";

export const tasksRouterUrlPrefix = "tasks";
export const trustExchangeTaskUrlPrefix = "trustexchange";
export const breachRiskTaskUrlPrefix = "breachrisk";
export const vendorRiskTaskUrlPrefix = "vendorrisk";
export const userRiskTaskUrlPrefix = "userrisk";

// TasksRouter - handles routes related to a user's tasks
const TasksRouter: FC = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  const handleVisibilityChange = () => {
    if (!document.hidden) {
      refreshSurveyList();
      dispatch(fetchOwnSharedAssessmentIfNecessary());
      dispatch(fetchOwnSharedAssessmentAccessIfNecessary());
    }
  };

  const refreshSurveyList = () => {
    dispatch(fetchVendorAppSurveyList(true, true));
  };

  useEffect(() => {
    dispatch(fetchVendorAppSurveyList());
    dispatch(fetchVendorAppRemediationRequestList(false));
    dispatch(fetchVendorAppAcceptedRisks(false));
    dispatch(getVendorRiskWaiversAndAdjustmentsToApprove());
    dispatch(fetchVendorPortalAdditionalEvidenceRequestSummaries());

    document.addEventListener("visibilitychange", handleVisibilityChange);

    // Every 5 minutes, auto refresh the survey list to keep things up to date.
    // Only do it if the user was active in the last 5 minutes though.
    const autoRefreshEvery = 5 * 60 * 1000;

    const autoRefreshInterval = setInterval(() => {
      const userLastActive = getUserLastActive();
      if (userLastActive > new Date().getTime() - autoRefreshEvery) {
        refreshSurveyList();
      }
    }, autoRefreshEvery);

    return () => {
      // Cleanup
      document.removeEventListener("visibilitychange", handleVisibilityChange);
      clearInterval(autoRefreshInterval);
    };
  }, []);

  const remediationRequestIdsLoading = useAppSelector(
    (state) => state.vendorPortal.remediationRequestIDsLoading
  );

  return (
    <div>
      <Switch>
        {/* TrustExchange Remediation Request tasks (these are vendor remediation requests) */}
        <Route
          exact
          path={`/${tasksRouterUrlPrefix}/${trustExchangeTaskUrlPrefix}/remediation`}
          render={() => (
            <TaskRemediationRequestList
              dispatch={dispatch}
              history={history}
              includeOwnOrgRemediationRequests={true}
              includeOtherOrgRemediationRequests={true}
              ownOrgRemediationRequestTypes={[RemediationType.Vendor]}
              otherOrgRemediationRequestTypes={[RemediationType.Vendor]}
              remediationRequestIdsLoading={remediationRequestIdsLoading}
              remediationRequestRouteOverride={`/${tasksRouterUrlPrefix}/${trustExchangeTaskUrlPrefix}/remediation`}
            />
          )}
        />
        <Route
          exact
          path={`/${tasksRouterUrlPrefix}/${trustExchangeTaskUrlPrefix}/remediation/:requestId`}
          render={(routeProps) => (
            <RemediationRequestDetailsV2
              isVendorPortal={true}
              {...routeProps}
              // Convince typescript to use the correct type for history and location (per UserBaseAppRouter.tsx)
              history={
                routeProps.history as History<
                  locationState & { openComments?: boolean }
                >
              }
              location={
                routeProps.location as Location<
                  locationState & { openComments?: boolean }
                >
              }
              remediationTaskListBasePathOverride={`/${tasksRouterUrlPrefix}/${trustExchangeTaskUrlPrefix}/remediation`}
            />
          )}
        />
        {/* Breach Risk remediation request tasks */}
        <Route
          exact
          path={`/${tasksRouterUrlPrefix}/${breachRiskTaskUrlPrefix}/remediation`}
          render={() => (
            <TaskRemediationRequestList
              dispatch={dispatch}
              history={history}
              includeOwnOrgRemediationRequests={true}
              includeOtherOrgRemediationRequests={true}
              ownOrgRemediationRequestTypes={[
                RemediationType.Self,
                RemediationType.Subsidiary,
              ]}
              otherOrgRemediationRequestTypes={[
                RemediationType.Self,
                RemediationType.Subsidiary,
              ]}
              remediationRequestIdsLoading={remediationRequestIdsLoading}
              remediationRequestRouteOverride={`/${tasksRouterUrlPrefix}/${breachRiskTaskUrlPrefix}/remediation`}
            />
          )}
        />
        <Route
          exact
          path={`/${tasksRouterUrlPrefix}/${breachRiskTaskUrlPrefix}/remediation/:requestId`}
          render={(routeProps) => (
            <RemediationRequestDetailsV2
              isVendorPortal={true}
              {...routeProps}
              history={
                routeProps.history as History<
                  locationState & { openComments?: boolean }
                >
              }
              location={
                routeProps.location as Location<
                  locationState & { openComments?: boolean }
                >
              }
              remediationTaskListBasePathOverride={`/${tasksRouterUrlPrefix}/${breachRiskTaskUrlPrefix}/remediation`}
            />
          )}
        />
        {/* Breach Risk Risk Waiver Approvals */}
        <Route
          path={`/${tasksRouterUrlPrefix}/${breachRiskTaskUrlPrefix}/riskwaiverapprovals`}
          render={(routeProps) => (
            <RiskWaiverApprovalsList
              waiverType={"risk-waivers"}
              includeOwnOrgWaivers={true}
              includeOtherOrgRiskWaivers={true}
              {...routeProps}
              location={routeProps.location as Location<locationState>}
              history={routeProps.history as History<locationState>}
            />
          )}
        />
        {/* Vendor Risk Risk Waiver Approvals */}
        <Route
          path={`/${tasksRouterUrlPrefix}/${vendorRiskTaskUrlPrefix}/riskwaiverapprovals`}
          render={(routeProps) => (
            <RiskWaiverApprovalsList
              waiverType={"vendor-risk-waivers"}
              includeOwnOrgWaivers={true}
              includeOtherOrgRiskWaivers={true}
              {...routeProps}
              location={routeProps.location as Location<locationState>}
              history={routeProps.history as History<locationState>}
            />
          )}
        />
        {/* Vendor Risk Risk Adjustment Approvals */}
        <Route
          path={`/${tasksRouterUrlPrefix}/${vendorRiskTaskUrlPrefix}/riskadjustmentapprovals`}
          render={(routeProps) => (
            <RiskAdjustmentApprovalsList
              includeOwnOrgWaivers={true}
              includeOtherOrgRiskWaivers={true}
              {...routeProps}
              location={routeProps.location as Location<locationState>}
              history={routeProps.history as History<locationState>}
            />
          )}
        />
        {/* User Risk Risk Waiver Approvals */}
        <Route
          path={`/${tasksRouterUrlPrefix}/${userRiskTaskUrlPrefix}/riskwaiverapprovals`}
          render={(routeProps) => (
            <RiskWaiverApprovalsList
              waiverType={"userbase-risk-waivers"}
              includeOwnOrgWaivers={true}
              includeOtherOrgRiskWaivers={true}
              {...routeProps}
              location={routeProps.location as Location<locationState>}
              history={routeProps.history as History<locationState>}
            />
          )}
        />
        {/* Trust Exchange Answer Questionnaires - show security and imported questionnaire tabs*/}
        <Route
          exact
          path={`/${tasksRouterUrlPrefix}/${trustExchangeTaskUrlPrefix}/questionnaires`}
          render={(routeProps) => (
            <VendorSurveys
              hiddenTabs={["relationship"]}
              {...routeProps}
              location={routeProps.location as Location<locationState>}
              history={routeProps.history as History<locationState>}
            />
          )}
        />
        <Route
          exact
          path={`/${tasksRouterUrlPrefix}/${trustExchangeTaskUrlPrefix}/questionnaires/:id`}
          render={(routeProps) => (
            <SurveyDetails
              isVendorPortal
              {...routeProps}
              location={routeProps.location as Location<locationState>}
              history={routeProps.history as History<locationState>}
            />
          )}
        />
        {/* Vendor Risk Answer Questionnaires - show relationship tab only */}
        <Route
          exact
          path={`/${tasksRouterUrlPrefix}/${vendorRiskTaskUrlPrefix}/questionnaires`}
          render={(routeProps) => (
            <VendorSurveys
              hiddenTabs={["security", "imported"]}
              {...routeProps}
              location={routeProps.location as Location<locationState>}
              history={routeProps.history as History<locationState>}
            />
          )}
        />
        <Route
          exact
          path={`/${tasksRouterUrlPrefix}/${vendorRiskTaskUrlPrefix}/questionnaires/:id`}
          render={(routeProps) => (
            <SurveyDetails
              isVendorPortal
              {...routeProps}
              location={routeProps.location as Location<locationState>}
              history={routeProps.history as History<locationState>}
            />
          )}
        />
        {/* Trust Exchange Additional Evidence Requests */}
        <Route
          exact
          path={`/${tasksRouterUrlPrefix}/${trustExchangeTaskUrlPrefix}/additionalevidence`}
          component={AdditionalEvidenceRequests}
        />
        <Route
          exact
          path={`/${tasksRouterUrlPrefix}/${trustExchangeTaskUrlPrefix}/additionalevidence/:additionalEvidenceId`}
          component={VendorPortalAdditionalEvidenceDetails}
        />
      </Switch>
    </div>
  );
};

export default TasksRouter;
