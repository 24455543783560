import { IVendorPortalState } from "../../_common/types/redux";

export const initialState: IVendorPortalState = {
  vendorAppSurveys: {},
  remediationRequestList: undefined,
  remediationRequestIDsLoading: false,
  acceptedRisks: null,
  vendorRiskWaivers: undefined,
  ipAddressDetails: {},
  additionalEvidenceRequests: {
    loading: true,
    evidenceRequestSummaries: [],
    detailedEvidenceRequests: [],
  },
};
