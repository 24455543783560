import { IdentityBreach } from "../../api/types";
import { FC } from "react";
import DateTimeFormat from "../../../_common/components/core/DateTimeFormat";
import { LabelColor } from "../../../_common/types/label";
import PillLabel from "../../../vendorrisk/components/PillLabel";

interface IdentityBreachDateProps {
  breach: IdentityBreach;
}

const IdentityBreachDate: FC<IdentityBreachDateProps> = ({ breach }) => {
  if (breach.breachDate) {
    return <DateTimeFormat dateTime={breach.breachDate} dateOnly />;
  }
  return <PillLabel color={LabelColor.Grey}>Unknown</PillLabel>;
};

export default IdentityBreachDate;
