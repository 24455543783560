import Flatpickr from "react-flatpickr";
import { FC } from "react";
import CalendarIcon from "../images/calendar-icon.svg";
import "../style/components/DatePicker.scss";
import classNames from "classnames";

interface IDatePickerProps {
  id?: string; // Sets the ID on the underlying input field. Useful for associating fields with labels
  className?: string;
  name?: string;
  min?: string;
  max?: string;
  disabled?: boolean;
  placeholder?: string;
  value?: string;
  inline?: boolean; // If true, always show the calendar in an open state, without the input
  onChange: (e: { target: { value: string } }) => void;
  displayFormat?: string; // The Flatpickr format string to display in the box
  canClear?: boolean;
  staticEditable?: boolean; // If true, display the field as if it's a static div, but editable on hover.
  showCalendarIcon?: boolean;
}

const DatePicker: FC<IDatePickerProps> = ({
  id = undefined,
  className = "",
  name = "",
  min = undefined,
  max = undefined,
  disabled = false,
  placeholder = null,
  value = "",
  inline = false,
  onChange,
  displayFormat = "d M, Y",
  canClear = false,
  staticEditable = false,
  showCalendarIcon = false,
}) => {
  return (
    <div
      className={classNames("date-picker", className, {
        inline,
        "static-editable": staticEditable,
      })}
    >
      <Flatpickr
        id={id}
        name={name}
        disabled={disabled}
        value={value}
        placeholder={placeholder === null ? "YYYY-MM-DD" : placeholder}
        options={{
          minDate: min,
          maxDate: max,
          mode: "single",
          inline,
          altFormat: displayFormat,
        }}
        onChange={(_, datesStr) => {
          // Emulate an onChange event
          const evt = {
            target: {
              value: datesStr,
            },
          };
          onChange(evt);
        }}
      />
      {canClear && !disabled && !!value ? (
        <div
          className="clear-btn icon-x"
          onMouseDown={() => {
            onChange({
              target: {
                value: "",
              },
            });
          }}
        />
      ) : showCalendarIcon ? (
        <img alt="calendar" className="calendar-icn" src={CalendarIcon} />
      ) : (
        staticEditable && (
          <div className="static-editable-edit cr-icon-pencil-2" />
        )
      )}
    </div>
  );
};

export default DatePicker;
