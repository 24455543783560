import React, { DetailedHTMLProps } from "react";
import classnames from "classnames";
import LoadingIcon from "./LoadingIcon";
import "../../style/components/core/Button.scss";
import { PopupPosition, SidePopupV2 } from "../DismissablePopup";

export interface IButtonProps
  extends DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  // Display loading spinner in the button.
  loading?: boolean;
  overrideLoadingColor?: string;

  // Show progress indicator as a background to the button. Must be float between 0 and 1.
  progress?: number;

  filledPrimary?: boolean;
  filledSecondary?: boolean;
  filledDanger?: boolean;
  primary?: boolean;
  tertiary?: boolean;
  danger?: boolean;
  green?: boolean;
  link?: boolean;
  active?: boolean; // if true will use the same style as hover
  add?: boolean;
  iconOnly?: boolean; // Set to true if this button only contains an icon
  tight?: boolean; // reduced the padding

  arrow?: boolean;
  leftArrow?: boolean;
  downArrow?: boolean;

  unreadCount?: number;

  /* Pass children to provide custom content for the button
   *  Overrides buttonText, iconClass and arrow props.
   */
  children?: React.ReactNode;
}

/* A standard button. By default it appears as a secondary button.
 * For custom content, use the children prop.
 */
const Button = ({
  children,
  className,
  danger = false,
  filledDanger = false,
  loading = false,
  filledPrimary = false,
  primary = false,
  filledSecondary = false,
  tertiary = false,
  green = false,
  link = false,
  active = false,
  add = false,
  iconOnly = false,
  tight = false,
  arrow,
  leftArrow,
  downArrow,
  type = "button",
  unreadCount,
  overrideLoadingColor,
  ...otherProps
}: IButtonProps) => {
  const progress =
    otherProps.progress !== undefined && otherProps.progress !== null
      ? otherProps.progress
      : null;

  const cls = classnames(
    "btn",
    {
      "btn-filled-primary": filledPrimary,
      "btn-filled-secondary": filledSecondary,
      "btn-filled-danger": filledDanger,
      "btn-primary": primary,
      "btn-tertiary": tertiary,
      "btn-danger": danger,
      "btn-green": green,
      "btn-link": link,
      "btn-active": active,
      "btn-add": add,
      "btn-icon-only": iconOnly,
      progress: progress !== null && progress < 1,
      loading,
      tight,
    },
    className ?? ""
  );

  return (
    <button type={type} {...otherProps} className={cls}>
      {loading && (
        <div className="loading-mask">
          <LoadingIcon
            color={
              overrideLoadingColor ??
              (filledPrimary ? "white" : danger ? "red" : "blue")
            }
          />
        </div>
      )}
      <div className="btn-inner">
        {leftArrow && <i className="btn-arrow-left icon-arrow rotate-270" />}
        {children}
        {arrow && <i className="btn-arrow icon-arrow rotate-90" />}
        {downArrow && <i className="btn-arrow icon-arrow rotate-180" />}
      </div>
      {progress !== null && (
        <div className="progress-bg" style={{ width: `${progress * 100}%` }} />
      )}
      {!!unreadCount && unreadCount > 0 && (
        <div className="unread-circle">
          <div className="count">{Math.min(99, unreadCount)}</div>
        </div>
      )}
    </button>
  );
};

export interface ExpanderButtonProps extends IButtonProps {
  expanded?: boolean;
  useCaret?: boolean;
}

export const ExpanderButton = (props: ExpanderButtonProps) => {
  const buttonProps = { ...props };

  // Remove props we don't want to pass to the <Button> component
  delete buttonProps.children;
  delete buttonProps.expanded;
  delete buttonProps.useCaret;

  const iconArrowClasses = classnames("btn-arrow icon-arrow expander", {
    "rotate-180": !props.expanded,
  });
  const iconCaretClasses = `cr-icon-chevron ${
    props.expanded ? "rot270" : "rot90"
  }`;

  return (
    <Button {...buttonProps}>
      {props.children}
      {props.useCaret ? (
        <div className={iconCaretClasses} />
      ) : (
        <i className={iconArrowClasses} />
      )}
    </Button>
  );
};

export interface TooltipButtonProps extends IButtonProps {
  tooltipContent?: React.ReactNode;
  popupNoWrap?: boolean;
  popupPosition?: PopupPosition;
  popupWidth?: number;
}

export const TooltipButton = ({
  tooltipContent,
  popupNoWrap,
  popupPosition,
  popupWidth,
  ...buttonProps
}: TooltipButtonProps) => {
  return (
    <SidePopupV2
      text={tooltipContent}
      position={popupPosition ?? "top"}
      width={popupWidth}
      noWrap={popupNoWrap}
      className={"tooltip-button"}
    >
      <Button {...buttonProps} />
    </SidePopupV2>
  );
};

export default Button;
