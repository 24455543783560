import { FC } from "react";
import PillLabel from "../PillLabel";
import { LabelColor } from "../../../_common/types/label";
import "../../style/components/risk_profile/RiskStatusCounts.scss";

interface RiskStatusCountsProps {
  numInRemediation: number;
  numWaived: number;
  isAllWaived?: boolean;
  numPendingWaived: number;
  numAdjusted: number;
  numPendingAdjusted: number;
  hideWaived?: boolean;
}

const RiskStatusCounts: FC<RiskStatusCountsProps> = ({
  numInRemediation,
  numWaived,
  isAllWaived,
  numPendingWaived,
  numAdjusted,
  numPendingAdjusted,
  hideWaived = false,
}) => {
  return (
    <div className={"risk-status-counts"}>
      {isAllWaived && (
        <PillLabel color={LabelColor.Grey}>Waived (all)</PillLabel>
      )}
      {numInRemediation > 0 && (
        <PillLabel color={LabelColor.Blue}>
          In Remediation ({numInRemediation})
        </PillLabel>
      )}
      {!isAllWaived && numWaived > 0 && !hideWaived && (
        <PillLabel color={LabelColor.Grey}>Waived ({numWaived})</PillLabel>
      )}
      {!isAllWaived && numPendingWaived > 0 && (
        <PillLabel color={LabelColor.Orange}>
          Waiver Pending ({numPendingWaived})
        </PillLabel>
      )}
      {numAdjusted > 0 && (
        <PillLabel color={LabelColor.TrendyPink}>
          Adjusted ({numAdjusted})
        </PillLabel>
      )}
      {numPendingAdjusted > 0 && (
        <PillLabel color={LabelColor.Violet}>
          Adjustments pending ({numPendingAdjusted})
        </PillLabel>
      )}
    </div>
  );
};

export default RiskStatusCounts;
