import {
  FC,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  GetDocumentsFilter,
  GetDocumentsSortBy,
  GetDocumentsSortByCol,
} from "../types/contentLibrary.types";
import XTable, {
  IIconOption,
  IPagination,
  ISortedBy,
  IXTableColumnHeader,
  IXTableRow,
  OnSortChange,
  SortDirection,
  XTableCell,
} from "../../_common/components/core/XTable";
import ContentLibraryAPI from "../api/contentLibraryAPI";
import TabButtons, { tabButton } from "../../_common/components/TabButtons";
import ReportCard from "../../_common/components/ReportCard";
import SearchBox from "../../_common/components/SearchBox";
import EmptyCardWithAction, {
  ErrorCardWithAction,
} from "../../_common/components/EmptyCardWithAction";
import SearchEmptyCard from "../../_common/components/SearchEmptyCard";
import EmptyStateCircle from "../../_common/components/EmptyStateCircle";
import moment from "moment/moment";
import {
  useLocationStateUpdater,
  useSelectableRows,
} from "../../_common/hooks";
import { useDefaultHistory } from "../../_common/types/router";
import "../styles/ContentLibraryListCard.scss";
import {
  AcceptedFileTypes,
  MaxFileSizeB,
} from "../../_common/types/fileRestrictions";
import ActionBar from "../../_common/components/ActionBar";
import { pluralise } from "../../_common/helpers";
import { useAppDispatch } from "../../_common/types/reduxHooks";
import FileDropzone from "../../_common/components/FileDropzone";
import MultiSelectionButton, {
  IOption,
} from "../../_common/components/MultiSelectionButton";
import { downloadContentLibraryDocumentFile } from "../api/downloadDocument";
import { GetIconForFilename } from "../../vendorrisk/helpers/icons";
import { useModalV2 } from "../../_common/components/ModalV2";
import AddDocumentToContentLibraryModal from "./AddDocumentToContentLibraryModal";
import { DropzoneOptions, useDropzone } from "react-dropzone";
import {
  useAnyDocumentUUIDsInSharedProfile,
  useDeleteDocuments,
  useSetDocumentsArchived,
} from "../types/contentLibraryHooks";
import {
  DropdownItem,
  DropdownSeparator,
} from "../../_common/components/core/DropdownV2";
import { SidePopupV2 } from "../../_common/components/DismissablePopup";
import { useCurrentOrg } from "../../_common/selectors/commonSelectors";
import ToggleWithLabel from "../../vendorrisk/components/ToggleWithLabel";
import { useToggleDocumentInSharedProfile } from "../views/ContentLibraryDocument";
import { addDefaultWarningAlert } from "../../_common/reducers/messageAlerts.actions";
import ContentLibraryOnboardingTasks from "./ContentLibraryOnboardingTasks";
import TrackedButton from "../../_common/components/TrackedButton";

const perPage = 20;

const emptyFilter: GetDocumentsFilter = {
  name: undefined,
  searchQuery: undefined,
  documentTypes: [],
  archived: false,
  sharedProfileOnly: false,
};

const defaultSortBy: GetDocumentsSortBy = {
  col: GetDocumentsSortByCol.Updated,
  desc: true,
};

export const useContentLibraryList = (
  perPage: number,
  initialSortBy: GetDocumentsSortBy = defaultSortBy,
  initialFilterBy: GetDocumentsFilter = emptyFilter,
  initialOffset = 0
) => {
  const [sortBy, setSortBy] = useState<GetDocumentsSortBy>(initialSortBy);

  const xTableSortBy = useMemo<ISortedBy>(
    () => ({
      columnId: sortBy.col,
      direction: sortBy.desc ? SortDirection.DESC : SortDirection.ASC,
    }),
    [sortBy]
  );

  const xTableOnSortChange: OnSortChange = useCallback(
    (columnId: string, newSortDir: SortDirection) => {
      setSortBy({
        col: columnId as GetDocumentsSortByCol,
        desc: newSortDir === SortDirection.DESC,
      });
    },
    []
  );

  const [offset, setOffset] = useState(initialOffset);
  const [filterBy, _setFilterBy] =
    useState<GetDocumentsFilter>(initialFilterBy);

  const isFiltered =
    !!filterBy.name ||
    filterBy.documentTypes.length > 0 ||
    filterBy.sharedProfileOnly;

  const setFilterBy = useCallback((filterBy: Partial<GetDocumentsFilter>) => {
    _setFilterBy((prevFilterBy) => ({
      ...prevFilterBy,
      ...filterBy,
    }));

    // Always reset the page offset when changing the filter
    setOffset(0);
  }, []);

  const clearFilters = useCallback(
    () =>
      setFilterBy({
        ...emptyFilter,
        archived: filterBy.archived,
      }),
    [setFilterBy, filterBy.archived]
  );

  const { data: documentTypeData, isLoading: documentTypesLoading } =
    ContentLibraryAPI.useGetContentLibraryDocumentTypesListQuery();

  const documentTypeFilterOptions: IOption[][] = useMemo(
    () =>
      documentTypeData
        ? [
            documentTypeData.types.map((t) => ({
              label: t.name,
              value: t.name,
              selected: filterBy.documentTypes.includes(t.name),
            })),
          ]
        : [],
    [documentTypeData, filterBy.documentTypes]
  );
  const onSelectDocumentTypeFilter = useCallback(
    (value: string, selected: boolean) => {
      const newSelection = [...filterBy.documentTypes];
      if (selected && !newSelection.includes(value)) {
        newSelection.push(value);
      } else if (!selected && newSelection.includes(value)) {
        newSelection.splice(newSelection.indexOf(value), 1);
      }

      // Keep the selection sorted so it uses consistent cached queries when serialised
      newSelection.sort((a, b) => a.localeCompare(b));

      setFilterBy({
        documentTypes: newSelection,
      });
    },
    [filterBy.documentTypes, setFilterBy]
  );
  const onClearDocumentTypeFilter = useCallback(
    () => setFilterBy({ documentTypes: [] }),
    [setFilterBy]
  );

  const queryData = ContentLibraryAPI.useGetContentLibraryDocumentsListQuery({
    sortBy,
    filterBy,
    limit: perPage,
    offset,
  });

  const xTablePagination: IPagination = useMemo(() => {
    return {
      currentPage: offset / perPage + 1,
      totalPages:
        queryData.data && queryData.data.totalResults > 0
          ? Math.ceil(queryData.data.totalResults / perPage)
          : 1,
      onPageChange: (newPage) => {
        setOffset((newPage - 1) * perPage);
      },
      hidePaginationIfSinglePage: true,
    };
  }, [queryData.data, offset, setOffset, perPage]);

  return {
    sortBy,
    filterBy,
    offset,
    setFilterBy,
    clearFilters,
    isFiltered,

    xTableSortBy,
    xTableOnSortChange,
    xTablePagination,

    documentTypesLoading,
    documentTypeFilterOptions,
    onSelectDocumentTypeFilter,
    onClearDocumentTypeFilter,
    documentTypeData,

    queryData,
  };
};

interface IContentLibraryListCardProps {
  userHasContentLibraryWrite: boolean;
  userHasSharedProfileWrite: boolean;
}

const ContentLibraryListCard: FC<IContentLibraryListCardProps> = ({
  userHasContentLibraryWrite,
  userHasSharedProfileWrite,
}) => {
  const dispatch = useAppDispatch();
  const history = useDefaultHistory<{
    filterBy?: GetDocumentsFilter;
    sortBy?: GetDocumentsSortBy;
    offset?: number;
  }>();
  const currentOrg = useCurrentOrg();

  const {
    sortBy,
    filterBy,
    offset,
    setFilterBy,
    clearFilters,
    isFiltered,

    xTableSortBy,
    xTableOnSortChange,
    xTablePagination,

    documentTypesLoading,
    documentTypeFilterOptions,
    onSelectDocumentTypeFilter,
    onClearDocumentTypeFilter,

    queryData: { data, error, isFetching, refetch },
  } = useContentLibraryList(
    20,
    history.location.state?.sortBy,
    history.location.state?.filterBy,
    history.location.state?.offset
  );

  // Keep our filters up to date in location state
  useLocationStateUpdater({
    filterBy,
    sortBy,
    offset,
  });

  const { data: unfilteredActiveDocumentsData } =
    ContentLibraryAPI.useGetContentLibraryDocumentsListQuery({
      sortBy: defaultSortBy,
      filterBy: emptyFilter,
      limit: perPage,
      offset: 0,
    });
  const { data: unfilteredArchivedDocumentsData } =
    ContentLibraryAPI.useGetContentLibraryDocumentsListQuery({
      sortBy: defaultSortBy,
      filterBy: { ...emptyFilter, archived: true },
      limit: perPage,
      offset: 0,
    });

  const anyDocumentUUIDsInSharedProfile = useAnyDocumentUUIDsInSharedProfile(
    data?.documents
  );

  const [setDocumentsArchived, setDocumentsArchivedModal] =
    useSetDocumentsArchived(data?.documents);
  const [deleteDocuments, deleteDocumentsModal] = useDeleteDocuments();

  const toggleDocInSharedProfile = useToggleDocumentInSharedProfile();

  const [selectedUUIDs, setSelectedUUIDs] = useState(new Set<string>());
  const rows: IXTableRow<string>[] = useMemo(() => {
    if (!data) {
      return [];
    }

    return data.documents.map((doc) => {
      const rowDropdownItems: ReactNode[] = [];

      const goToDoc = () =>
        history.push(`/contentlibrary/document/${doc.uuid}`, {
          backContext: {
            backToText: "Back to Content Library",
            goBack: true,
          },
        });

      const updatedBy = data.sharedUsers[doc.updatedBy];

      if (currentOrg?.isVerifiedVendor) {
        rowDropdownItems.push(
          <DropdownItem
            key="shared_profile"
            stopPropagation
            className="include-shared-toggle"
          >
            <ToggleWithLabel
              name={`${doc.uuid}-shared`}
              label="Include in Trust Page"
              selected={doc.includeInSharedProfile}
              disabled={
                !userHasContentLibraryWrite ||
                !userHasSharedProfileWrite ||
                doc.archived
              }
              onClick={(e: MouseEvent) => {
                e.stopPropagation();
                toggleDocInSharedProfile(doc);
              }}
            />
          </DropdownItem>,
          <DropdownSeparator key="sep" />
        );
      }

      if (doc.versions[0].virusSafe) {
        rowDropdownItems.push(
          <DropdownItem
            key="download"
            stopPropagation
            onClick={() =>
              dispatch(downloadContentLibraryDocumentFile(doc.uuid))
            }
          >
            <div className="cr-icon-export-thin" />
            Download
          </DropdownItem>
        );
      }

      rowDropdownItems.push(
        <DropdownItem key="properties" stopPropagation onClick={goToDoc}>
          <div className="cr-icon-info" />
          Content properties
        </DropdownItem>
      );

      if (
        userHasContentLibraryWrite &&
        (userHasSharedProfileWrite || !doc.includeInSharedProfile)
      ) {
        rowDropdownItems.push(
          <DropdownSeparator key="sep2" />,
          <DropdownItem
            key="archive"
            stopPropagation
            onClick={() => setDocumentsArchived([doc.uuid], !doc.archived)}
          >
            <div className="cr-icon-archive" />
            {doc.archived ? "Unarchive" : "Archive"}
          </DropdownItem>
        );
      }

      const iconOpts: IIconOption[] = [];
      if (rowDropdownItems.length > 0) {
        iconOpts.push({
          id: "actions",
          icon: <i className={"cr-icon-dots-menu"} />,
          dropdownItems: rowDropdownItems,
        });
      }
      iconOpts.push({
        id: "chevron",
        icon: <i className="cr-icon-chevron" />,
        onClick: goToDoc,
      });

      return {
        id: doc.uuid,
        selected: selectedUUIDs.has(doc.uuid),
        onClick: goToDoc,
        iconOptions: iconOpts,
        cells: [
          <XTableCell key="name" className="name-cell">
            <div className="name-cell-inner">
              <img
                className="file-type"
                alt="File type icon"
                src={GetIconForFilename(doc.versions[0].filename)}
              />
              {doc.name}
            </div>
          </XTableCell>,
          <XTableCell key="shared" className="shrink-cell">
            {doc.includeInSharedProfile && (
              <SidePopupV2
                text="Included in Trust Page"
                micro
                noWrap
                position="top"
              >
                <div className="i-shared-profile cr-icon-shared-assessment" />
              </SidePopupV2>
            )}
          </XTableCell>,
          <XTableCell key="type" className="doc-type-cell">
            {doc.documentType}
          </XTableCell>,
          <XTableCell key="updated" className="updated-cell">
            <SidePopupV2
              text={moment(doc.updatedAt).format("LLL")}
              inline
              micro
              noWrap
              position="top"
            >
              {moment(doc.updatedAt).format("ll")}
            </SidePopupV2>
            {updatedBy?.name ? `, ${updatedBy.name}` : ""}
          </XTableCell>,
        ],
      };
    });
  }, [
    data,
    history,
    selectedUUIDs,
    dispatch,
    userHasContentLibraryWrite,
    userHasSharedProfileWrite,
    setDocumentsArchived,
    currentOrg?.isVerifiedVendor,
    toggleDocInSharedProfile,
  ]);

  const xTableSelectableProps = useSelectableRows(
    selectedUUIDs,
    setSelectedUUIDs,
    rows
  );

  useEffect(() => {
    // Reset the row selection whenever the displayed data changes
    setSelectedUUIDs(new Set());
  }, [data]);

  const columnHeaders: IXTableColumnHeader[] = useMemo(
    () => [
      {
        id: GetDocumentsSortByCol.Name,
        text: "Document",
        sortable: true,
        startingSortDir: SortDirection.ASC,
      },
      {
        id: "shared",
        text: "",
        sortable: false,
      },
      {
        id: GetDocumentsSortByCol.Type,
        text: "Type",
        sortable: true,
        startingSortDir: SortDirection.ASC,
      },
      {
        id: GetDocumentsSortByCol.Updated,
        text: "Last updated",
        sortable: true,
        startingSortDir: SortDirection.DESC,
      },
    ],
    []
  );

  const tabs: tabButton[] = useMemo(() => {
    if (unfilteredActiveDocumentsData && unfilteredArchivedDocumentsData) {
      return [
        {
          id: "current",
          text: `Current (${unfilteredActiveDocumentsData.totalResults})`,
        },
        {
          id: "archived",
          text: `Archived (${unfilteredArchivedDocumentsData.totalResults})`,
        },
      ];
    }

    return [
      {
        id: "current",
        text: "Current",
      },
      {
        id: "archived",
        text: "Archived",
      },
    ];
  }, [unfilteredActiveDocumentsData, unfilteredArchivedDocumentsData]);

  const onChangeTab = useCallback(
    (newTab: string) =>
      setFilterBy({
        archived: newTab === "archived",
      }),
    [setFilterBy]
  );

  const [openAddDocumentModal, addDocumentModal] = useModalV2(
    AddDocumentToContentLibraryModal
  );

  const onFileDrop: DropzoneOptions["onDrop"] = useCallback(
    async (acceptedFiles: File[], _rejectedFiles: File[]) => {
      if (acceptedFiles.length === 1) {
        openAddDocumentModal({
          file: acceptedFiles[0],
        });
      } else {
        dispatch(addDefaultWarningAlert("Please upload one file at a time."));
      }
    },
    [openAddDocumentModal, dispatch]
  );

  // Set up a dropzone for uploading files anywhere in the card.
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    maxSize: MaxFileSizeB,
    accept: AcceptedFileTypes,
    onDrop: onFileDrop,
    disabled: !userHasContentLibraryWrite,
    noClick: true,
  });

  // Check if the onboarding status is still loading
  const { isLoading: onboardingStatusLoading } =
    ContentLibraryAPI.useGetContentLibraryOnboardingStatusQuery();

  // Check valid bulk actions
  const { canSetSelectedDocumentsArchived, canDeleteSelectedDocuments } =
    useMemo(() => {
      return {
        canSetSelectedDocumentsArchived:
          userHasSharedProfileWrite ||
          !anyDocumentUUIDsInSharedProfile(Array.from(selectedUUIDs)),
        canDeleteSelectedDocuments: !data?.documents.some(
          (doc) => selectedUUIDs.has(doc.uuid) && doc.usageCount > 0
        ),
      };
    }, [
      data?.documents,
      userHasSharedProfileWrite,
      anyDocumentUUIDsInSharedProfile,
      selectedUUIDs,
    ]);

  return (
    <ReportCard newStyles className="content-library-list">
      <div className="header">
        <div className="header-left">
          <TabButtons
            activeTabId={filterBy.archived ? "archived" : "current"}
            tabs={tabs}
            onChangeTab={onChangeTab}
            fullWidth
          />
        </div>
        <div className="header-right">
          {currentOrg?.isVerifiedVendor && (
            <ToggleWithLabel
              name="shared-profile-filter"
              label="Trust Page only"
              selected={filterBy.sharedProfileOnly}
              onClick={() =>
                setFilterBy({
                  sharedProfileOnly: !filterBy.sharedProfileOnly,
                })
              }
            />
          )}
          <MultiSelectionButton
            primary={false}
            text="Document type"
            optionGroups={documentTypeFilterOptions}
            disabled={documentTypesLoading}
            showCount={filterBy.documentTypes.length > 0}
            onSelectionChangedByValue={onSelectDocumentTypeFilter}
            onResetDefault={onClearDocumentTypeFilter}
            autoWidth
          />
        </div>
      </div>
      {!isFetching && error ? (
        <ErrorCardWithAction action={refetch} actionText="Try again" />
      ) : (
        <FileDropzone
          className="content-library-list-main"
          getRootProps={getRootProps}
          getInputProps={getInputProps}
          isDragActive={isDragActive}
        >
          <ContentLibraryOnboardingTasks />
          <SearchBox
            placeholder="Search documents"
            onChanged={(val) => setFilterBy({ name: val || undefined })}
            value={filterBy.name ?? ""}
            minLength={3}
          />
          {isFetching || onboardingStatusLoading || rows.length > 0 ? (
            <XTable<string>
              // Treat XTable as still loading until we have the onboarding status data
              rows={!onboardingStatusLoading ? rows : []}
              columnHeaders={columnHeaders}
              loading={isFetching || onboardingStatusLoading}
              sortedBy={xTableSortBy}
              onSortChange={xTableOnSortChange}
              pagination={xTablePagination}
              selectable={userHasContentLibraryWrite}
              iconOptions
              {...xTableSelectableProps}
            />
          ) : isFiltered ? (
            <SearchEmptyCard
              searchItemText="documents"
              searchString={filterBy.name}
              onClear={clearFilters}
              clearText="Clear search and filters"
            />
          ) : filterBy.archived ? (
            <EmptyCardWithAction
              emptyText="No archived documents found"
              emptySubText="When you archive documents on the Active tab, they will appear here."
            />
          ) : userHasContentLibraryWrite ? (
            <EmptyStateCircle
              imgName="ContentLibrary"
              title="Drop files here"
              subtext={<>or hit the &quot;Upload document&quot; button.</>}
            />
          ) : (
            <EmptyStateCircle
              imgName="ContentLibrary"
              title="Your Content Library is empty"
              subtext={
                <>
                  Documents uploaded by anyone in your organization will be
                  shown here.
                </>
              }
            />
          )}
        </FileDropzone>
      )}
      {addDocumentModal}

      <ActionBar newStyles active={selectedUUIDs.size > 0}>
        <div className="left-content">
          {selectedUUIDs.size}{" "}
          {pluralise(selectedUUIDs.size, "document", "documents")} selected
        </div>
        <div className="right-content">
          <TrackedButton
            eventName="ContentLibraryBulkArchive"
            disabled={!canSetSelectedDocumentsArchived}
            onClick={() =>
              setDocumentsArchived(
                Array.from(selectedUUIDs),
                !filterBy.archived
              )
            }
          >
            {filterBy.archived ? "Unarchive" : "Archive"}
          </TrackedButton>
          <SidePopupV2
            text={
              !canDeleteSelectedDocuments
                ? "Documents used in a questionnaire or Trust Page can't be deleted."
                : undefined
            }
          >
            <TrackedButton
              eventName="ContentLibraryBulkDelete"
              danger
              disabled={!canDeleteSelectedDocuments}
              onClick={() => deleteDocuments(Array.from(selectedUUIDs))}
            >
              <div className="cr-icon-trash" /> Delete
            </TrackedButton>
          </SidePopupV2>
        </div>
      </ActionBar>
      {setDocumentsArchivedModal}
      {deleteDocumentsModal}
    </ReportCard>
  );
};

export default ContentLibraryListCard;
