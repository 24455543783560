import { defaultFilter } from "./breachNewsFeed.actions";
import { ICommonState } from "../types/redux";
import { getLocalStorageItem } from "../session";
import { arrayToPermissionSet } from "../permissions";

export const getHydratedCommonInitialState = (
  authMode: "APPLIANCE" | "AUTH0"
) => {
  if (authMode !== "AUTH0") {
    return {
      ...commonInitialState,
      authMode,
    };
  }

  // For AUTH0, try and get stuff from localStorage if it isn't expired
  const initialState = { ...commonInitialState, authMode };

  const cyberRiskAuth = getLocalStorageItem("cyberRiskAuth");
  if (cyberRiskAuth && cyberRiskAuth.expires) {
    if (new Date().getTime() < cyberRiskAuth.expires) {
      initialState.cyberRiskAuth = {
        ...cyberRiskAuth,
        loading: false,
        error: false,
        forceLogout: false,
        userLogout: false,
      };

      // Grab the user data if we have cyber risk auth too
      const userData = getLocalStorageItem("userData");
      if (userData && userData.profile) {
        initialState.userData = userData;
        initialState.permissions = {
          user: arrayToPermissionSet(userData.userPermissions),
          org: arrayToPermissionSet(userData.orgPermissions),
        };
      }
    } else {
      // We have auth but it's expired. Mark it in state so the auth0 wrapper knows to log us out completely.
      initialState.cyberRiskAuth.ssoWithLogout = cyberRiskAuth.ssoWithLogout;
      initialState.cyberRiskAuth.forceLogout = true;
    }
  }

  return initialState;
};

export const commonInitialState: ICommonState = {
  authMode: "APPLIANCE", // "APPLIANCE" or "AUTH0"
  cyberRiskAuth: {
    apiKey: null,
    token: null,
    expires: null,
    loading: false,
    error: false,
    ssoWithLogout: false,
    forceLogout: false,
    userLogout: false,
  },
  // NOTE: was originally null so ideally this should be optional/undefined but there are just too many places to fix
  // @ts-ignore
  userData: null,
  cyberRiskEndpoint: "",
  hannahEnv: "",
  orgInfo: {},
  authInfo: {},
  openModal: undefined,
  modalData: {},
  orgLoading: false,
  permissions: {
    user: {},
    org: {},
  },
  industries: [],
  surveys: {},
  prefilledSurveys: {},
  assessmentSurveys: {},
  assessmentPrefilledSurveys: {},
  remediationRequests: {},
  sharedAssessments: {},
  sharedAssessmentsList: null,
  webscans: {},
  viewingVendorId: undefined,
  exportItems: {
    data: [],
    loading: false,
  },
  exportItemsArchived: {
    data: [],
    loading: false,
  },
  exportItemsPolling: [],
  scheduledExports: {
    loading: false,
    data: [],
  },
  exportsForSchedule: {},
  viewingManagedVendorForOrgId: {
    orgId: 0,
    vendorId: 0,
  },
  tpvmSession: {
    tpvm: false,
    tpvm_o: "",
    tpvm_v: "",
  },
  tpvmAuth: {},
  activityStream: {
    loading: true,
    error: null,
    events: [],
    tasks: [],
    complete: false,
    page: -1,
    releaseEvent: null,
    importantEvents: [],
    actionRunning: false,
    backgroundRefreshing: false,
    reloading: false,
  },
  breachNewsFeed: {
    filter: defaultFilter,
    currentPage: 1,
  },
  namedRoles: {
    loading: true,
  },
  messageAlerts: [],
  securityFrameworks: {},
  freeTrialEligibility: {},
  managedAssessmentMetaQuestions: {},
  activityStreamBatchedEvents: {},
  activityStreamBatchedEventExpanded: {},
  projection: {
    requestTimestamps: {},
  },
};
