import {
  FC,
  KeyboardEventHandler,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import "../style/components/CustomSelect.scss";
import { Surface } from "./Surface";
import SearchEmptyCard from "./SearchEmptyCard";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import classNames from "classnames";

export enum SelectItemType {
  Option,
  Group,
}

interface SelectItem {
  id: string | number;
  className?: string;
}

export interface OptionItem extends SelectItem {
  content: React.ReactNode;
  onClick?: () => void;
  type: SelectItemType.Option;
}

export interface GroupItem extends SelectItem {
  title: string;
  description?: string;
  items?: OptionItem[];
  hideIfEmpty?: boolean;
  type: SelectItemType.Group;
}

export type Option = OptionItem | GroupItem;

interface CustomSelectProps {
  className?: string;
  items: Option[];
  placeholder?: string;
  onSearchChange?: (val: string) => void;
  onClearOptions?: () => void;
  disabled?: boolean;
  errorText?: string;
  dontCloseOnSelect?: boolean;
  emptyText?: string;
  selectedOption?: string | number;
}

const CustomSelect: FC<CustomSelectProps> = ({
  className,
  placeholder,
  onSearchChange,
  onClearOptions,
  items,
  disabled = false,
  errorText,
  dontCloseOnSelect,
  emptyText = "No items to display",
  selectedOption,
}) => {
  const [searchVal, setSearchVal] = useState("");
  const [showSurface, setShowSurface] = useState(false);
  const [itemsToDisplay, setItemsToDisplay] = useState<Option[]>([]);

  // Delay flags to reduce animation jank
  const [showEmpty, setShowEmpty] = useState(items.length === 0);
  const [showItems, setShowItems] = useState(items.length > 0);

  const inputRef = useRef<HTMLDivElement>(null);
  const [inputWidth, setInputWidth] = useState(100);
  // watch the width of the input so we can set the width of the surface
  const width = inputRef.current?.getBoundingClientRect().width;
  useLayoutEffect(() => {
    if (inputRef.current) {
      setInputWidth(inputRef.current.getBoundingClientRect().width);
    }
  }, [width]);

  // Notify for a search value change
  useEffect(() => {
    if (onSearchChange) {
      onSearchChange(searchVal);
    }
  }, [searchVal]);

  // Respond to internal changes
  useEffect(() => {
    if (showItems && itemsToDisplay.length === 0) {
      if (!searchVal) {
        setShowSurface(false);
      }

      setShowItems(false);

      // Delay empty state display
      setTimeout(() => {
        setShowEmpty(true);
      }, 256);
    } else if (showEmpty && itemsToDisplay.length > 0) {
      setShowEmpty(false);

      // Delay showing items state again
      setTimeout(() => {
        setShowItems(true);
      }, 256);
    }
  }, [itemsToDisplay, showEmpty, showItems, searchVal]);

  // Respond to item array changes
  useEffect(() => {
    // Flatten the items and hide empty groups if required
    const newItemsToDisplay: Option[] = [];
    items.forEach((i) => {
      if (i.type === SelectItemType.Group) {
        const iAsGroup = i as GroupItem;

        if (
          (iAsGroup.items && iAsGroup.items.length > 0) ||
          !iAsGroup.hideIfEmpty
        ) {
          newItemsToDisplay.push(iAsGroup);
          if (iAsGroup.items) {
            newItemsToDisplay.push(...iAsGroup.items);
          }
        }
      } else {
        newItemsToDisplay.push(i);
      }
    });

    setItemsToDisplay(newItemsToDisplay);
  }, [items]);

  // TODO set min width/height to the empty state?

  const optionClicked = useCallback(() => {
    if (!dontCloseOnSelect) {
      setShowSurface(false);
    }
    setSearchVal("");
  }, [showSurface, dontCloseOnSelect]);

  const handleKeyDown = useCallback<KeyboardEventHandler<HTMLDivElement>>(
    (e) => {
      switch (e.key) {
        case "Escape": {
          setShowSurface(false);
          break;
        }
        // TODO Add support for keyboard arrows + selection
      }
    },
    []
  );

  const selectedOptionItem = itemsToDisplay.find(
    (i) => i.id == selectedOption && selectedOption != undefined
  );

  return (
    <div
      className={classNames(className, "custom-select")}
      onKeyDown={handleKeyDown}
    >
      <div className={"input-container"} ref={inputRef}>
        <input
          type={"text"}
          placeholder={
            !selectedOptionItem ? placeholder || "Search..." : undefined
          }
          value={searchVal}
          onChange={(ev) => {
            setSearchVal(ev.target.value);
            if (!showSurface) setShowSurface(true);
          }}
          onClick={() => setShowSurface(true)}
          onFocus={() => setShowSurface(true)}
          disabled={disabled}
        />
        {selectedOptionItem && !searchVal && (
          <div className={"selected-option"}>
            {getItemDisplay(selectedOptionItem)}
          </div>
        )}
        {selectedOptionItem && onClearOptions && (
          <i className={"icon-x"} onClick={onClearOptions} />
        )}
        <i
          className={classNames("cr-icon-chevron", {
            "rotate-270": showSurface,
            disabled,
          })}
          onClick={() => setShowSurface(!showSurface)}
        />
      </div>
      {errorText && (
        <CSSTransition
          key={errorText}
          timeout={250}
          classNames="fade-transition"
        >
          <div className={"error-text"}>
            <i className={"icon-info"} />
            {errorText}
          </div>
        </CSSTransition>
      )}
      <Surface
        classNames={"options-container"}
        active={showSurface}
        onClickOutside={() => setShowSurface(false)}
        skipForOutsideCheckSelector={".input-container"}
        width={inputWidth}
      >
        <TransitionGroup component={null}>
          {itemsToDisplay.length === 0 && searchVal != "" && showEmpty && (
            <CSSTransition
              key={"empty-search"}
              classNames={"expand"}
              timeout={250}
            >
              <SearchEmptyCard />
            </CSSTransition>
          )}
          {itemsToDisplay.length === 0 && searchVal == "" && showEmpty && (
            <CSSTransition key={"empty"} classNames={"expand"} timeout={250}>
              <div className={"empty"}>{emptyText}</div>
            </CSSTransition>
          )}
          {showItems &&
            itemsToDisplay.length > 0 &&
            itemsToDisplay.map((i) => (
              <CSSTransition key={i.id} classNames={"expand"} timeout={250}>
                {getItemDisplay(i, optionClicked)}
              </CSSTransition>
            ))}
        </TransitionGroup>
      </Surface>
    </div>
  );
};

const getItemDisplay = (item: Option, optionClickedCallback?: () => void) => {
  switch (item.type) {
    case SelectItemType.Option:
      const asOption = item as OptionItem;
      const onClick = asOption.onClick;
      return (
        <OptionDisplay
          content={asOption.content}
          onClick={
            onClick
              ? () => {
                  optionClickedCallback && optionClickedCallback();
                  onClick();
                }
              : undefined
          }
          className={asOption.className}
        />
      );
    case SelectItemType.Group:
      const asDivider = item as GroupItem;
      return (
        <DividerDisplay
          title={asDivider.title}
          description={asDivider.description}
          className={asDivider.className}
        />
      );
  }
};

interface OptionProps {
  content: React.ReactNode;
  onClick?: () => void;
  className?: string;
}

const OptionDisplay: FC<OptionProps> = ({ content, onClick, className }) => {
  return (
    <div onClick={onClick} className={classNames("option", className)}>
      {content}
    </div>
  );
};

interface DividerProps {
  title: string;
  description?: string;
  className?: string;
}

const DividerDisplay: FC<DividerProps> = ({
  title,
  description,
  className,
}) => {
  return (
    <div className={classNames("divider", className)}>
      <div className={"title"}>{title}</div>
      {description && <div className={"description"}>{description}</div>}
    </div>
  );
};

export default CustomSelect;
