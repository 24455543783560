import { FC, useState } from "react";
import {
  OptionType,
  SelectV2Multi,
} from "../../../_common/components/SelectV2";
import { getColorForKey } from "../PillLabel";
import { SlidePanelSection } from "./SlidePanelSection";
import LabelList from "../LabelList";
import { VulnSoftware } from "./types";
import { ValueType } from "react-select";

interface IVulnsSoftwareFilterProps {
  selectedOptions?: VulnSoftware[];
  availableOptions?: VulnSoftware[];
  loading?: boolean;
  onChange: (opts: VulnSoftware[]) => void;
  title?: string;
  startExpanded?: boolean;
}

interface VulnsSoftwareFilterOptionType extends OptionType {
  value: string;
  color: string;
}

const VulnsSoftwareFilter: FC<IVulnsSoftwareFilterProps> = ({
  selectedOptions = [],
  availableOptions = [],
  loading = false,
  onChange,
  title = "Filter by software",
  startExpanded = false,
}) => {
  const [expanded, setExpanded] = useState(
    startExpanded || selectedOptions.length > 0
  );

  const selectOnChange = (selectedOptions: ValueType<OptionType, true>) => {
    onChange(
      selectedOptions
        ? selectedOptions.map(
            ({ value }) =>
              availableOptions.find((cpe) => cpe.name === value) as VulnSoftware
          )
        : []
    );
  };

  const options = availableOptions.map((cpe) => ({
    value: cpe.name,
    color: getColorForKey(cpe.product),
    label: cpe.title,
  }));

  const selectValue = selectedOptions.map(
    (cpe) =>
      options.find(
        ({ value }) => value === cpe.name
      ) as VulnsSoftwareFilterOptionType
  );

  const displayLabels = selectValue.map(({ value, label, color }) => ({
    id: value,
    name: label,
    color: color,
    removeable: true,
    large: true,
    constrained: true,
    onRemoveClick: () =>
      selectOnChange(selectValue.filter((option) => value !== option.value)),
  }));

  return (
    <SlidePanelSection
      title={title}
      expanded={expanded}
      toggleExpand={() => setExpanded(!expanded)}
    >
      <SelectV2Multi
        placeholder="Type to search software versions"
        options={options}
        value={selectValue}
        onChange={selectOnChange}
        isLoading={loading}
        controlShouldRenderValue={false}
      />
      <LabelList labels={displayLabels} />
    </SlidePanelSection>
  );
};

export default VulnsSoftwareFilter;
