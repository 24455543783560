import { FC, useEffect } from "react";
import "../style/components/VulnAssetsAffected.scss";
import { LabelColor } from "../../_common/types/label";
import { makeFilter } from "../reducers/defaults";
import XTable, {
  IXTableRow,
  SortDirection,
  XTableCell,
} from "../../_common/components/core/XTable";
import PillLabel from "./PillLabel";
import { columnSortDef, useSorting } from "../../_common/hooks";
import { formatDateAsLocal } from "../../_common/helpers";
import VendorVulnAPI, { toVendorVulnsParams } from "../reducers/vendorVulnAPI";

interface TableRowData {
  asset: string;
  firstDetected: string;
  remediationStatus: "In remediation" | "Not in remediation";
}

interface VulnAssetsAffectedProps {
  cveName: string;
  vendorId?: number;
  isSubsidiary: boolean;
  isManagedVendorAnalyst?: boolean;
  managedOrgId?: number;
  onClickAsset?: (currentPanelTitle: string, asset: string) => void;
  onAssetsCountChanged?: (assetsCount: number | undefined) => void;
}

const VulnAssetsAffected: FC<VulnAssetsAffectedProps> = ({
  cveName,
  vendorId,
  isSubsidiary,
  onClickAsset,
  onAssetsCountChanged,
}) => {
  const { data, isLoading } = VendorVulnAPI.useGetVendorVulnsV1Query(
    toVendorVulnsParams(
      { vendorId, isSubsidiary },
      makeFilter({ vulnsCveNames: [cveName] })
    )
  );

  const vulnsByHostname = data?.result?.vulnsByHostname;

  let tableRowsData: TableRowData[] = [];
  if (!isLoading && vulnsByHostname) {
    tableRowsData = vulnsByHostname.map((vulnByHostname): TableRowData => {
      return {
        asset: vulnByHostname.hostname,
        firstDetected: vulnByHostname.created_at,
        remediationStatus:
          vulnByHostname.remediation_request_ids?.length > 0
            ? "In remediation"
            : "Not in remediation",
      };
    });
  }

  // An effect is needed here as onAssetsCountChanged may do things that shouldn't
  // be done during rendering and this effect will run outside rendering.
  useEffect(() => {
    if (onAssetsCountChanged) {
      if (isLoading) {
        onAssetsCountChanged(undefined);
      } else {
        onAssetsCountChanged(tableRowsData.length);
      }
    }
  }, [isLoading, tableRowsData.length]);

  const tableSortingFuncs: Record<string, columnSortDef<TableRowData>> = {
    assets: {
      orderFuncs: [(tableRowData) => tableRowData.asset],
      sortDirsAsc: ["asc"],
      sortDirsDesc: ["desc"],
    },
    first_detected: {
      orderFuncs: [
        (tableRowData) => tableRowData.firstDetected,
        (tableRowData) => tableRowData.asset,
      ],
      sortDirsAsc: ["asc", "asc"],
      sortDirsDesc: ["desc", "asc"],
    },
    remediation_status: {
      orderFuncs: [
        (tableRowData) => tableRowData.remediationStatus,
        (tableRowData) => tableRowData.asset,
      ],
      sortDirsAsc: ["asc", "asc"],
      sortDirsDesc: ["desc", "asc"],
    },
  };

  const [sortedTableRowData, sortedBy, onSortChange] = useSorting<
    TableRowData,
    string
  >(tableRowsData, "assets", SortDirection.ASC, tableSortingFuncs);

  return (
    <div className={"vuln-assets-affected-container"}>
      <XTable
        stickyColumnHeaders={false}
        loading={isLoading}
        numLoadingRows={3}
        sortedBy={sortedBy}
        onSortChange={onSortChange}
        columnHeaders={[
          {
            id: "assets",
            text: "Assets",
            sortable: true,
            startingSortDir: SortDirection.ASC,
          },
          {
            id: "first_detected",
            text: "First detected",
            sortable: true,
            startingSortDir: SortDirection.ASC,
          },
          {
            id: "remediation_status",
            text: "Remediation status",
            sortable: true,
            startingSortDir: SortDirection.ASC,
          },
        ]}
        rows={sortedTableRowData.map((tableRowData, i): IXTableRow => {
          return {
            id: `${tableRowData.asset}-${i}`,
            cells: [
              <XTableCell
                key={tableRowData.asset}
                className="asset-cell"
                onClick={
                  onClickAsset
                    ? () => onClickAsset(cveName, tableRowData.asset)
                    : undefined
                }
              >
                {tableRowData.asset}
              </XTableCell>,
              <XTableCell key="first_detected">
                {formatDateAsLocal(tableRowData.firstDetected)}
              </XTableCell>,
              <XTableCell key="remediation_status">
                {tableRowData.remediationStatus === "In remediation" ? (
                  <PillLabel color={LabelColor.Blue}>In remediation</PillLabel>
                ) : (
                  <PillLabel color={LabelColor.Grey}>
                    Not in remediation
                  </PillLabel>
                )}
              </XTableCell>,
            ],
          };
        })}
      />
    </div>
  );
};

export default VulnAssetsAffected;
