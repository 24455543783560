import { ISurveyListItemResponse } from "../../types/survey";
import { ReactNode } from "react";
import DropdownV2, { DropdownItem } from "../core/DropdownV2";

import "../../style/components/SurveyDetailsStatusDropdown.scss";
import {
  useConfirmationModalV2,
  useConfirmationModalV2Props,
} from "../modals/ConfirmationModalV2";
import { DefaultThunkDispatch } from "../../types/redux";
import {
  fetchAlertsOrActivityStreamForOrgUser,
  fetchVendorSurveyCounts,
} from "../../../vendorrisk/reducers/cyberRiskActions";
import {
  getSurveyListV2,
  setSharedSurveyArchived,
} from "../../../vendorrisk/reducers/survey.actions";
import { surveyListPageLimit } from "../../../vendorrisk/views/Questionnaires";
import {
  addDefaultSuccessAlert,
  addSimpleErrorAlert,
} from "../../reducers/messageAlerts.actions";

export const archiveOrUnarchiveSharedSurvey = (
  dispatch: DefaultThunkDispatch,
  openConfirmationModal: (props: useConfirmationModalV2Props) => void,
  survey: {
    id: number;
    isPrefilledSurvey: boolean;
    vendorId: number;
  },
  archive: boolean
) => {
  const description = archive
    ? "This questionnaire will no longer appear on your list of active questionnaires."
    : "This questionnaire will return to your list of active questionnaires.";

  openConfirmationModal({
    title: archive
      ? "Are you sure you want to archive this questionnaire?"
      : "Are you sure you want to unarchive this questionnaire?",
    description: <p>{description}</p>,
    buttonText: archive ? "Archive" : "Unarchive",
    cancelText: "Cancel",
    buttonAction: async () => {
      try {
        await dispatch(
          setSharedSurveyArchived(
            survey.id,
            survey.isPrefilledSurvey,
            archive,
            survey.vendorId
          )
        );
        // kick off call to update the activity stream
        dispatch(fetchAlertsOrActivityStreamForOrgUser(true, true));
        const proms: Promise<any>[] = [
          dispatch(
            getSurveyListV2(
              true,
              undefined,
              0,
              surveyListPageLimit,
              "date_due",
              "desc",
              !archive
            )
          ),
        ];
        if (survey.vendorId) {
          proms.push(
            dispatch(
              getSurveyListV2(
                true,
                survey.vendorId,
                0,
                surveyListPageLimit,
                "date_due",
                "desc",
                !archive
              )
            )
          );

          proms.push(dispatch(fetchVendorSurveyCounts(survey.vendorId, true)));
        }

        await Promise.all(proms);
      } catch (e: any) {
        dispatch(addSimpleErrorAlert(e.message));
        throw e;
      }

      dispatch(
        addDefaultSuccessAlert(
          archive
            ? "Successfully archived questionnaire."
            : "Successfully unarchived questionnaire"
        )
      );
    },
  });
};

interface ISharedSurveyDetailsStatusDropdownProps {
  dispatch: DefaultThunkDispatch;
  survey: ISurveyListItemResponse;
  popupItem: ReactNode;
}

// This component provides a dropdown menu for taking actions on a shared survey.
// It is assumed this is never mounted in the vendor portal, or for a user without write permissions.
const SharedSurveyDetailsStatusDropdown = ({
  dispatch,
  popupItem,
  survey,
}: ISharedSurveyDetailsStatusDropdownProps) => {
  const [openConfirmationModal, confirmationModalComponent] =
    useConfirmationModalV2();

  return (
    <>
      <DropdownV2
        className="survey-details-status-dropdown"
        popupItem={popupItem}
      >
        {survey.archived ? (
          <DropdownItem
            onClick={() =>
              archiveOrUnarchiveSharedSurvey(
                dispatch,
                openConfirmationModal,
                survey,
                false
              )
            }
          >
            <div className="cr-icon-archive" /> Unarchive questionnaire
          </DropdownItem>
        ) : (
          <DropdownItem
            onClick={() =>
              archiveOrUnarchiveSharedSurvey(
                dispatch,
                openConfirmationModal,
                survey,
                true
              )
            }
          >
            <div className="cr-icon-archive" /> Archive questionnaire
          </DropdownItem>
        )}
      </DropdownV2>
      {confirmationModalComponent}
    </>
  );
};

export default SharedSurveyDetailsStatusDropdown;
