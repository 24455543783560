import "../style/components/KnownExploitedVulnPill.scss";
import { SidePopupV2 } from "../../_common/components/DismissablePopup";
import PillLabel from "./PillLabel";
import { LabelColor } from "../../_common/types/label";

export interface IKnownExploitedVulnPillProps {
  longText?: boolean;
  count?: number;
  left?: boolean;
}

const KnownExploitedVulnPill = (props: IKnownExploitedVulnPillProps) => {
  const { longText, count, left } = props;

  const shortText = (count && count > 0 ? `${count} ` : "") + "Known";

  return (
    <SidePopupV2
      position={left ? "left" : "top"}
      popupHoverable={true}
      popupClassName={"known-exploited-vuln-popup"}
      text={
        <div className={"known-exploited-vuln"}>
          <div>
            This vulnerability has been documented by CISA as a Known Exploited
            Vulnerability (KEV)
          </div>
          <div className={"link"}>
            <a
              href={
                "https://help.upguard.com/en/articles/6603248-what-are-known-exploited-vulnerabilities"
              }
              target="_blank"
              rel="noreferrer noopener"
            >
              Learn more
              <i className="btn-arrow icon-arrow rotate-90" />
            </a>
          </div>
        </div>
      }
    >
      <PillLabel color={LabelColor.Red}>
        {longText ? "Known Exploited Vulnerability" : shortText}
      </PillLabel>
    </SidePopupV2>
  );
};

export default KnownExploitedVulnPill;
