import classnames from "classnames";
import {
  IXTableColumnHeader,
  IXTableProps,
  IXTableRow,
  XTableCell,
} from "../../_common/components/core/XTable";

import XTable from "../../_common/components/core/XTable";

import "../style/components/TreeTable.scss";
import Icon from "../../_common/components/core/Icon";
import DropDown from "../../_common/components/core/DropDown";
import React, { ReactNode } from "react";

// Any cell using the ShowHideChildrenButton needs to wrap its contents in this component
// to support absolute positioning within a table cell.
export const ShowHideChildrenButtonCellWrapper = (props: {
  children: React.ReactNode;
}) => {
  const { children } = props;
  return <div className="show-hide-children-cell">{children}</div>;
};

interface IShowHideChildrenButtonProps {
  indentLevel: number;
  parentCellId: string;
  onToggleOpen: () => void;
  isOpen: boolean;
  showText: string;
  hideText: string;
}

const scrollToParentRow = (indentLevel: number, rowId: string) => {
  // See if we can find the parent element
  const parent = document.getElementById(rowId);
  if (parent) {
    // Scroll to it if it's out of view
    const parentTop = parent.getBoundingClientRect().top;
    const stickyRowsHeight = 60 + indentLevel * 72;

    if (parentTop < stickyRowsHeight) {
      window.scroll(0, parentTop + window.scrollY - stickyRowsHeight);
    }
  }
};

const ShowHideChildrenButton = (props: IShowHideChildrenButtonProps) => {
  const {
    indentLevel,
    parentCellId,
    onToggleOpen,
    isOpen,
    showText,
    hideText,
  } = props;

  return (
    <div className="show-hide-children">
      {new Array(indentLevel).fill(null).map((_, i) => (
        <div key={i} className="indent" />
      ))}
      <div
        className="show-hide-children-inner"
        onClick={(e) => {
          e.stopPropagation();
          onToggleOpen();

          if (isOpen) {
            scrollToParentRow(indentLevel, parentCellId);
          }
        }}
      >
        <Icon name="chevron" direction={isOpen ? 0 : 180} />
        {isOpen ? hideText : showText}
      </div>
    </div>
  );
};

export const ShowHideChildrenButtonMemo = React.memo(ShowHideChildrenButton);

const indentClass = (indentLevel: number) =>
  `indent-${Math.min(4, indentLevel)}`;

export interface ITreeTableRow extends IXTableRow {
  /** If true, show the defined children */
  open: boolean;
  /** Any children to be included below this element in the structure */
  children: ITreeTableRow[];
  /** Any expandable content to be included below this element in the structure */
  childContent?: React.ReactNode;
  /** Optional to provide if children are not included in ITreeTableRow structure (e.g. for performance) */
  hasChildren?: boolean;
}

export interface ITreeTableProps extends Omit<IXTableProps, "rows"> {
  /** Column headers that will be used for any sub-tables */
  subTableColumnHeaders: IXTableColumnHeader[];
  /** Tree structure of rows to include in the table */
  rowsTree: ITreeTableRow[];
  /** If true, include a tree diagram at the left of the table. */
  includeTreeDiagram: boolean;
  /** If true, rows will not be a fixed height. NOTE: This is incompatible with stickyColumnHeaders **/
  variableHeightRows: boolean;

  /** Optional render override for the tree diagram connector cell. Requires includeTreeDiagram = true*/
  treeDiagramNodeRenderOverride?: (indentLevel: number) => React.ReactNode;

  /** Function to call if a row in the tree diagram is clicked (to expand/contract) */
  onClickTreeDiagram?: (rowId: string) => void;
  /** If possible, include the maximum number of icon options that can possibly be next to a row in the tree.
   * This will ensure the table does not jump around when rows and opened and closed.
   */
  maxPossibleIconOptions?: number;
  /* Optional children selection. Will render checkbox with a dropdown. */
  childrenSelection?: {
    selectAllText: string;
    selectNoneText: string;
    onSelectChildrenClick: (
      parentRow: ITreeTableRow,
      selected: boolean
    ) => void;
  };
}

/**
 * <TreeTable /> mostly takes the same props as XTable, but supports nested levels within a tree structure.
 */
const TreeTable = (props: ITreeTableProps) => {
  const {
    className,
    loading,
    meatballs,
    iconOptions,
    columnHeaders,
    subTableColumnHeaders,
    rowsTree,
    includeTreeDiagram,
    onClickTreeDiagram,
    maxPossibleIconOptions,
    selectable,
    onSelectClick,
    radioSelector,
    sortedBy,
    onSortChange,
    pagination,
    selectableColIdx,
    treeDiagramNodeRenderOverride,
    childrenSelection,
    stickyColumnHeaders,
    variableHeightRows,
    emptyContent,
  } = props;

  const generateRows = (
    rows: IXTableRow[],
    thisRow: ITreeTableRow,
    indentLevel: number,
    upperLineArr: boolean[],
    lowerLineArr: boolean[]
  ) => {
    if (includeTreeDiagram) {
      const treeAnchorId = `tree_anchor_${thisRow.id}`;
      let treeToggleCell: React.ReactNode;
      if (!treeDiagramNodeRenderOverride) {
        treeToggleCell = (
          <XTableCell
            key="tree-toggle"
            className="tree-toggle-cell"
            id={treeAnchorId}
          >
            {indentLevel > 0 && (
              <>
                <div className="indent-blank half" />
                {new Array(indentLevel).fill(null).map((_v, i) => (
                  <React.Fragment key={i}>
                    <div className="line-container">
                      {upperLineArr[i] && <div className="upper-line" />}
                      {lowerLineArr[i] && <div className="lower-line" />}
                    </div>
                    {i === indentLevel - 1 ? (
                      <div className="indent-line" />
                    ) : (
                      <>
                        <div className="indent-blank" />
                        <div className="indent-blank half" />
                      </>
                    )}
                  </React.Fragment>
                ))}
              </>
            )}
            <div
              className={`toggle-circle ${
                onClickTreeDiagram &&
                ((thisRow.children && thisRow.children.length > 0) ||
                  thisRow.childContent)
                  ? "clickable"
                  : ""
              }`}
              onClick={
                onClickTreeDiagram &&
                ((thisRow.children && thisRow.children.length > 0) ||
                  thisRow.childContent)
                  ? (e) => {
                      e.stopPropagation();
                      onClickTreeDiagram(thisRow.id.toString());

                      if (thisRow.open) {
                        scrollToParentRow(indentLevel, treeAnchorId);
                      }
                    }
                  : undefined
              }
            >
              <div className="toggle-circle-circle">
                {((thisRow.children && thisRow.children.length > 0) ||
                  thisRow.childContent) && (
                  <div className="toggle-circle-text">
                    {thisRow.open ? "-" : "+"}
                  </div>
                )}
              </div>
              {thisRow.children &&
                thisRow.children.length > 0 &&
                thisRow.open && (
                  <div className="line-container">
                    <div className="lower-line" />
                  </div>
                )}
            </div>
          </XTableCell>
        );
      } else {
        treeToggleCell = (
          <XTableCell
            key="tree-toggle"
            className="tree-toggle-cell"
            id={treeAnchorId}
          >
            {treeDiagramNodeRenderOverride(indentLevel)}
          </XTableCell>
        );
      }

      thisRow.cells = [treeToggleCell, ...thisRow.cells];
    }

    // If we know what the max number of iconOptions could be, fill out some blank icons on each row that is below that amount.
    if (maxPossibleIconOptions) {
      thisRow.iconOptions = [...(thisRow.iconOptions || [])];
      for (
        let i = 0;
        i < maxPossibleIconOptions - thisRow.iconOptions.length;
        i++
      ) {
        thisRow.iconOptions.push({
          id: `icon-option-placeholder-${i}`,
          icon: <span className="cr-icon-placeholder" />,
        });
      }
    }

    thisRow.checkboxRenderOverride =
      childrenSelection &&
      ((thisRow.children && thisRow.children.length > 0) ||
        thisRow.hasChildren ||
        thisRow.childContent)
        ? (defaultCheckbox) => {
            return (
              <DropDown
                right
                stopPropagationOnOpen
                action={
                  <div className="selection-dropdown">
                    {defaultCheckbox}
                    <Icon name="chevron" />
                  </div>
                }
              >
                <div
                  className={"actions-opt"}
                  key={"select-all"}
                  onClick={() =>
                    childrenSelection.onSelectChildrenClick(thisRow, true)
                  }
                >
                  {childrenSelection.selectAllText}
                </div>
                <div
                  className={"actions-opt"}
                  key={"select-none"}
                  onClick={() =>
                    childrenSelection.onSelectChildrenClick(thisRow, false)
                  }
                >
                  {childrenSelection.selectNoneText}
                </div>
              </DropDown>
            );
          }
        : undefined;

    // First push the actual row content.
    rows.push({
      ...thisRow,
      className: thisRow.className || "" + " tree-row",
    });

    // Next, process all its children with an additional indent level
    if (
      thisRow.open &&
      ((thisRow.children && thisRow.children.length > 0) ||
        thisRow.childContent)
    ) {
      const newRows: IXTableRow[] = [];

      if (thisRow.childContent) {
        const newUpperLineArr = [...upperLineArr];
        const newLowerLineArr = [...lowerLineArr];
        if (
          indentLevel > 0 &&
          newUpperLineArr[indentLevel - 1] &&
          !newLowerLineArr[indentLevel - 1]
        ) {
          newUpperLineArr[indentLevel - 1] = false;
        }
        newUpperLineArr.push(false);
        newLowerLineArr.push(false);

        generateChildContentRow(
          newRows,
          `con_${thisRow.id}`,
          thisRow.childContent,
          indentLevel + 1,
          newUpperLineArr,
          newLowerLineArr
        );
      } else if (thisRow.children) {
        for (let i = 0; i < thisRow.children.length; i++) {
          const newUpperLineArr = [...upperLineArr];
          const newLowerLineArr = [...lowerLineArr];

          if (
            indentLevel > 0 &&
            newUpperLineArr[indentLevel - 1] &&
            !newLowerLineArr[indentLevel - 1]
          ) {
            newUpperLineArr[indentLevel - 1] = false;
          }

          // Children will always start off with a line going up to their parent.
          newUpperLineArr.push(true);

          // This will only have a lower line if it's not the last sibling at this level.
          if (i < thisRow.children.length - 1) {
            newLowerLineArr.push(true);
          } else {
            newLowerLineArr.push(false);
          }

          generateRows(
            newRows,
            { ...thisRow.children[i] },
            indentLevel + 1,
            newUpperLineArr,
            newLowerLineArr
          );
        }
      }

      let colSpan = thisRow.cells.length;

      if (meatballs || iconOptions) {
        colSpan += 1;
      }

      if (selectable) {
        colSpan += 1;
      }

      let subTableColSpan = subTableColumnHeaders.length;

      if (includeTreeDiagram) {
        subTableColSpan += 1;
      }

      if (selectable) {
        subTableColSpan += 1;
      }

      rows.push({
        id: thisRow.id + "-sub-table-row",
        className: "sub-table-row",
        cells: [
          <XTableCell key="sub-table" colSpan={colSpan} style={{ padding: 0 }}>
            {stickyColumnHeaders && (
              <XTable
                className={`tree-table sub-table-header ${indentClass(
                  indentLevel
                )}`}
                selectable={selectable}
                onSelectClick={onSelectClick}
                radioSelector={radioSelector}
                meatballs={meatballs}
                iconOptions={iconOptions}
                numColumns={colSpan}
                rows={[thisRow]}
                selectableColIdx={selectableColIdx}
              />
            )}
            <XTable
              className={classnames(`tree-table`, {
                "variable-height-rows":
                  !stickyColumnHeaders && variableHeightRows,
              })}
              selectable={selectable}
              onSelectClick={onSelectClick}
              radioSelector={radioSelector}
              meatballs={meatballs}
              iconOptions={iconOptions}
              numColumns={subTableColSpan}
              rows={newRows}
              selectableColIdx={selectableColIdx}
            />
          </XTableCell>,
        ],
      });
    }
  };

  const generateChildContentRow = (
    rows: IXTableRow[],
    id: string,
    childContent: ReactNode,
    indentLevel: number,
    upperLineArr: boolean[],
    lowerLineArr: boolean[]
  ) => {
    rows.push({
      id: id,
      cells: [
        <XTableCell key="toggle" className="tree-toggle-child-content-cell">
          <>
            <div className="indent-blank half" />
            {new Array(indentLevel).fill(null).map((_v, i) => (
              <React.Fragment key={i}>
                <div className="line-container">
                  {upperLineArr[i] && <div className="upper-line" />}
                  {lowerLineArr[i] && <div className="lower-line" />}
                </div>
                <div className="indent-blank" />
                {i !== indentLevel - 1 && <div className="indent-blank half" />}
              </React.Fragment>
            ))}
          </>
        </XTableCell>,
        <XTableCell key="child-content" className="tree-child-content-cell">
          <div className={"child-content"}>{childContent}</div>
        </XTableCell>,
      ],
    } as IXTableRow);
  };

  const rows: IXTableRow[] = [];
  for (let i = 0; i < rowsTree.length; i++) {
    generateRows(rows, { ...rowsTree[i] }, 0, [], []);
  }

  return (
    <XTable
      className={classnames("tree-table", className, {
        "include-tree-diagram": includeTreeDiagram,
        "children-selection":
          childrenSelection !== undefined && selectableColIdx === 0,
        "variable-height-rows": !stickyColumnHeaders && variableHeightRows,
      })}
      loading={loading}
      selectable={selectable}
      onSelectClick={onSelectClick}
      radioSelector={radioSelector}
      meatballs={meatballs}
      iconOptions={iconOptions}
      stickyColumnHeaders={stickyColumnHeaders}
      columnHeaders={
        includeTreeDiagram
          ? [
              {
                id: "tree-toggle",
                className: "tree-toggle-cell",
                text: "",
                sortable: false,
              },
              ...(columnHeaders || []),
            ]
          : columnHeaders
      }
      rows={rows}
      sortedBy={sortedBy}
      onSortChange={onSortChange}
      pagination={pagination}
      selectableColIdx={selectableColIdx}
      emptyContent={emptyContent}
    />
  );
};

TreeTable.defaultProps = {
  className: "",
  loading: false,
  meatballs: false,
  includeTreeDiagram: false,
  numLoadingRows: 5,
  selectable: false,
  radioSelector: false,
  expandableRows: false,
  iconOptions: false,
  stickyColumnHeaders: true,
  variableHeightRows: false,
};

export default TreeTable;

export const getChildrenRowIds = (
  parentRow: ITreeTableRow
): (string | number)[] => {
  const childrenIds: (string | number)[] = [];

  parentRow.children.forEach((c) => {
    childrenIds.push(c.id);

    if (c.children && c.children.length > 0) {
      childrenIds.push(...getChildrenRowIds(c));
    }
  });

  return childrenIds;
};
