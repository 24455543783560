import Icon from "./core/Icon";
import "../style/components/BackArrow.scss";
import { SidePopupV2 } from "./DismissablePopup";
import { ComponentProps } from "react";

export interface BackArrowProps
  extends Partial<ComponentProps<typeof SidePopupV2>> {
  onClick: () => void;
  popup?: React.ReactNode;
}

const BackArrow = ({
  onClick,
  popup,
  position = "right",
  ...sidePopupV2Props
}: BackArrowProps) => {
  const backArrow = (
    <div className="back-arrow" onClick={onClick}>
      <Icon name="chevron" direction={270} className="back-arrow-icon" />
    </div>
  );

  return popup ? (
    <SidePopupV2
      text={popup}
      position={position}
      width={500}
      noWrap
      {...sidePopupV2Props}
    >
      {backArrow}
    </SidePopupV2>
  ) : (
    backArrow
  );
};

export default BackArrow;
