import { FC } from "react";
import classnames from "classnames";

import "../style/components/Dot.scss";

interface IDotProps {
  className?: string;
  color: "green" | "orange" | "red" | "blue" | "grey";
}

const Dot: FC<IDotProps> = ({ className, color }) => (
  <div className={classnames("cr-dot", className, color)} />
);

export default Dot;
