import { FC } from "react";
import { DefaultThunkDispatch } from "../../_common/types/redux";
import { History } from "history";
import RemediationRequestTableV2Wrapped, {
  RemediationRequestTableV2Mode,
} from "../../vendorrisk/components/RemediationRequestTableV2";
import PageHeader from "../../_common/components/PageHeader";
import Button from "../../_common/components/core/Button";
import { getUserRemediationRequests } from "../../_common/helpers/userOrg.helpers";
import { RemediationType } from "../../_common/types/remediation";
import { useCurrentUserTasks } from "../../_common/selectors/commonSelectors";

interface ITaskRemediationRequestListProps {
  dispatch: DefaultThunkDispatch;
  history: History;
  remediationRequestIdsLoading: boolean;
  includeOwnOrgRemediationRequests: boolean;
  includeOtherOrgRemediationRequests: boolean;
  ownOrgRemediationRequestTypes?: RemediationType[]; // if undefined, all types are included
  otherOrgRemediationRequestTypes?: RemediationType[]; // if undefined, all types are included
  remediationRequestRouteOverride?: string;
}

// TaskRemediationRequestList - displays a list of remediation requests that have been shared with the current user
export const TaskRemediationRequestList: FC<
  ITaskRemediationRequestListProps
> = (props) => {
  const userTaskData = useCurrentUserTasks();

  const { currentOrgRequestIDs, otherOrgRequestIDs } =
    getUserRemediationRequests(
      userTaskData ?? {},
      props.includeOwnOrgRemediationRequests,
      props.includeOtherOrgRemediationRequests,
      props.ownOrgRemediationRequestTypes,
      props.otherOrgRemediationRequestTypes
    );

  // Combine current + other org requests into a single list
  const remediationRequestIDs = [
    ...(currentOrgRequestIDs ?? []),
    ...(otherOrgRequestIDs ?? []),
  ];

  return (
    <div id="vendor_remediation_view">
      <PageHeader
        history={props.history}
        title="Remediation Requests"
        infoSectionPageKey="infoSection_vendorriskportalRemediation"
        infoSection={
          <>
            <p>
              UpGuard makes risk remediation faster, smarter, and easier to
              complete.
            </p>
            <p>
              Click into a remediation request to get started. If you need help,
              please use the chat in the lower right corner of your screen or
              read our help article.
            </p>
          </>
        }
        infoSectionButtons={
          <Button
            tertiary
            onClick={() =>
              window.open(
                "https://help.upguard.com/en/articles/4340759-how-to-respond-to-a-remediation-request"
              )
            }
          >
            View support article <div className="cr-icon-arrow-right" />
          </Button>
        }
      />
      <RemediationRequestTableV2Wrapped
        dispatch={props.dispatch}
        remediationRequestIds={remediationRequestIDs}
        mode={RemediationRequestTableV2Mode.tasks}
        userHasWriteRemediationPermission
        remediationRequestIdsLoading={props.remediationRequestIdsLoading}
        taskRouteOverride={props.remediationRequestRouteOverride}
      />
    </div>
  );
};
