import { useState } from "react";
import PropTypes from "prop-types";

import UpGuardLogoSVG from "../../../verifiedvendorwidget/upguard-logo-white.svg";

export const MySharedProfileEmbedModalName = "MySharedProfileEmbedModal";

import "../../style/components/MySharedProfileEmbedModal.scss";

const clipboardAPISupported = !!navigator.clipboard;

const WithCopyLink = ({ copyText, children }) => {
  const [copied, setCopied] = useState(false);

  return (
    <div className="with-copy-link">
      {children}
      {copied && <div className="copy-link copied">Copied</div>}
      {!copied && clipboardAPISupported && (
        <div
          className="copy-link"
          onClick={() => {
            navigator.clipboard
              .writeText(copyText)
              .then(() => setCopied(true))
              .catch((e) => console.error("error copying to clipboard:", e));
          }}
        >
          Copy
        </div>
      )}
    </div>
  );
};

const MySharedProfileEmbedModal = ({ modalData: { uuid } }) => {
  const firstScript = `<script async defer src="${window.CR_CDN_URL}/widget.js"></script>`;
  const secondScript = `<span class="upguard-btn" data-uuid="${uuid}"></span>`;
  return (
    <div className="modal-content" id="shared-profile-embed-modal">
      <h3>Embed a link to your Trust Page on your website</h3>
      <p>
        Follow the instructions below to embed a button on your website that
        will allow visitors to view your Trust Page. Pressing this button will
        prompt the person to set up a CyberRisk account and grant them access to
        your Trust Page.
      </p>
      <h4>Step 1</h4>
      <p>
        Place the following code on your page, preferably just after the opening{" "}
        <pre>&lt;body&gt;</pre> tag:
      </p>
      <WithCopyLink copyText={firstScript}>
        <textarea
          rows={2}
          className="preformatted"
          value={firstScript}
          readOnly
        />
      </WithCopyLink>
      <h4>Step 2</h4>
      <p>
        Insert this HTML inline wherever you want the UpGuard button (preview
        below) to appear on your page.
        <br />
        <br />
        <div className="upguard-btn-sample">
          <img src={UpGuardLogoSVG} />
          <span className="ug-divider" />
          View our Security Profile
        </div>
      </p>
      <WithCopyLink copyText={secondScript}>
        <textarea
          rows={2}
          className="preformatted"
          value={secondScript}
          readOnly
        />
      </WithCopyLink>
    </div>
  );
};

MySharedProfileEmbedModal.propTypes = {
  dispatch: PropTypes.func.isRequired,
  modalData: PropTypes.object.isRequired,
};

export default MySharedProfileEmbedModal;
