import { OptionType, SelectV2 } from "../../../_common/components/SelectV2";
import ReportCard from "../../../_common/components/ReportCard";
import SearchBox from "../../../_common/components/SearchBox";
import SearchEmptyCard from "../../../_common/components/SearchEmptyCard";
import { useEffect, useState } from "react";
import { RiskDetail } from "../../../_common/types/vendor";
import SelectRiskTable, { ISelectRiskTableProps } from "./SelectRiskTable";
import {
  defaultCategoryWeights,
  factCategories,
  newFactCategoryMeta,
} from "../../../_common/factCategoryHelpers";
import { WaiverType } from "../../../_common/types/vendorRiskWaivers";

interface ISelectRiskStepProps extends ISelectRiskTableProps {
  isLoading: boolean;
  isNoRisks: boolean;
  vendorId: number;
}

const SelectRiskStep = (props: ISelectRiskStepProps) => {
  const [searchText, setSearchText] = useState("");
  const [filteredRisks, setFilteredRisks] = useState(props.risks || []);
  const [options, setOptions] = useState<OptionType[]>([]);
  const [selectedOption, setSelectedOption] = useState<OptionType | undefined>(
    undefined
  );

  useEffect(() => {
    if (props.risks) {
      const filteredRisks = props.risks.filter((r) => {
        if (r.isWaived) {
          return false; // Can't create a new waiver or adjustment for a waived risk
        } else if (
          props.waiverType === WaiverType.SeverityAdjustment &&
          r.baseSeverity
        ) {
          return false; // Can't create a new adjustment for an already adjusted risk
        } else if (
          searchText &&
          r.title
            .toLocaleLowerCase()
            .indexOf(searchText.toLocaleLowerCase()) === -1
        ) {
          return false;
        } else if (
          selectedOption &&
          selectedOption.value !== "all" &&
          r.factCategory !== selectedOption.value
        ) {
          return false;
        }
        return true;
      });

      setFilteredRisks(filteredRisks);

      setOptions(getOptions(filteredRisks));
    }
  }, [props.waiverType, searchText, props.risks, selectedOption]);

  return (
    <ReportCard newStyles>
      <div className="header">
        <div className="header-title">Select Risk</div>
      </div>
      <div className={"select-risk-filters"}>
        <SelectV2
          isDisabled={props.isLoading}
          options={options}
          onChange={(op: any) => setSelectedOption(op)}
          value={selectedOption || options[0]}
        />
        <SearchBox
          disabled={props.isLoading}
          value={searchText}
          onChanged={(v) => setSearchText(v)}
        />
      </div>
      <div className={"select-risk-table-container"}>
        {(props.isLoading || filteredRisks.length > 0) && (
          <SelectRiskTable {...props} risks={filteredRisks} />
        )}
        {!props.isLoading && filteredRisks?.length === 0 && (
          <SearchEmptyCard
            searchItemText={"risks"}
            onClear={() => {
              setSearchText("");
              setSelectedOption(options[0]);
            }}
            hideSearch={props.isNoRisks}
          />
        )}
      </div>
    </ReportCard>
  );
};

const getOptionCounts = (risks: RiskDetail[]): Map<string, number> => {
  const optionCounts = new Map<string, number>();

  risks.forEach((r) => {
    const currVal = optionCounts.get(r.factCategory) ?? 0;
    optionCounts.set(r.factCategory, currVal + 1);
  });

  return optionCounts;
};

export const getOptions = (risks: RiskDetail[]): OptionType[] => {
  // Show all should always appear first, followed by Questionnaire and
  // Additional Evidence, if included
  const optionTypes: OptionType[] = [
    {
      label: `Show all (${risks.length})`,
      value: "all",
    },
  ];

  const automatedScanningCategories: OptionType[] = [];

  getOptionCounts(risks).forEach((opCount, key) => {
    const entry = {
      label: `${newFactCategoryMeta[key].name} (${opCount})`,
      value: key,
    };

    if (
      key == factCategories.QuestionnaireRisks ||
      key == factCategories.AdditionalEvidenceRisks
    ) {
      optionTypes.push(entry);
    } else {
      automatedScanningCategories.push(entry);
    }
  });

  optionTypes.sort((a, b) => {
    if (a.value === "all") {
      return -1;
    } else if (b.value === "all") {
      return 1;
    }

    return b.value.toString().localeCompare(a.value.toString());
  });

  automatedScanningCategories.sort((a, b) => {
    const firstWeight = defaultCategoryWeights[a.value] ?? 0;
    const secondWeight = defaultCategoryWeights[b.value] ?? 0;
    if (firstWeight === secondWeight) {
      return b.value.toString().localeCompare(a.value.toString());
    } else if (firstWeight < secondWeight) {
      return 1;
    }

    return -1;
  });

  optionTypes.push(...automatedScanningCategories);

  return optionTypes;
};

export default SelectRiskStep;
